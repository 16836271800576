// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as Utils_Api$DvmAdminFrontendFr from "../../common/utils/Utils_Api.bs.js";
import * as Energy_Api$DvmAdminFrontendFr from "./Energy_Api.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../context/user/User_Context.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../context/router/Context_Router.bs.js";
import * as Messages_Energy$DvmAdminFrontendFr from "../../intl/messages/energy/Messages_Energy.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../common/types/App_Types_Result.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../common/styles/App_Styles_Common.bs.js";
import * as Energy_Search_Form$DvmAdminFrontendFr from "./Energy_Search_Form.bs.js";
import * as Energy_Search_Table$DvmAdminFrontendFr from "./Energy_Search_Table.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../common/components/input/App_AutocompleteFormInput.bs.js";

function Energy_Search_Filter(Props) {
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var modalOpen = Props.modalOpen;
  var match = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match[1];
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setEnergiesList = match$3[1];
  var energiesList = match$3[0];
  var form = Energy_Search_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.getWithDefault(match[0].energyFilter, Energy_Search_Form$DvmAdminFrontendFr.initialInput), (function (param, submissionCallbacks) {
          Curry._2(fetchData, submissionCallbacks, {
                page: 0,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: fetchRequest.headers,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                              hd: param.originalEngineType,
                              tl: {
                                hd: param.identificationEngineType,
                                tl: /* [] */0
                              }
                            }, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  React.useEffect((function () {
          Curry._1(form.submit, undefined);
        }), []);
  React.useEffect((function () {
          Energy_Api$DvmAdminFrontendFr.fetchBrandsSearch(undefined, userManager, setEnergiesList, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                    page: 0,
                    rowsPerPage: 500,
                    headers: Energy_Search_Table$DvmAdminFrontendFr.headersConfiguration,
                    filterQueryParams: "",
                    additionalQueryParams: /* [] */0
                  }), selectedRole);
        }), [modalOpen]);
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(dispatch, {
                        TAG: /* StoreEnergyFilter */27,
                        _0: form.input
                      });
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true,
                  wrap: "nowrap"
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Energy$DvmAdminFrontendFr.originalValue,
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.originalEngineType,
                                onChange: Curry._1(form.updateOriginalEngineType, (function (input, originalEngineType) {
                                        return {
                                                originalEngineType: originalEngineType,
                                                identificationEngineType: input.identificationEngineType
                                              };
                                      }))
                              }
                            },
                            options: {
                              TAG: /* Unlabeled */0,
                              _0: Belt_List.sort(Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.keep(App_Types_Result$DvmAdminFrontendFr.mapWithDefault(energiesList, /* [] */0, (function (energiesList) {
                                                          return Belt_List.map(energiesList.content, (function (x) {
                                                                        return x.originalEngineType;
                                                                      }));
                                                        })), (function (x) {
                                                      return x.length > 0;
                                                    }))))), Caml.string_compare)
                            },
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Energy$DvmAdminFrontendFr.attachedValue,
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.identificationEngineType,
                                onChange: Curry._1(form.updateIdentificationEngineType, (function (input, identificationEngineType) {
                                        return {
                                                originalEngineType: input.originalEngineType,
                                                identificationEngineType: identificationEngineType
                                              };
                                      }))
                              }
                            },
                            options: {
                              TAG: /* Unlabeled */0,
                              _0: Belt_List.sort(Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.keep(App_Types_Result$DvmAdminFrontendFr.mapWithDefault(energiesList, /* [] */0, (function (energiesList) {
                                                          return Belt_List.keepMap(energiesList.content, (function (x) {
                                                                        return x.identificationEngineType;
                                                                      }));
                                                        })), (function (x) {
                                                      return x.length > 0;
                                                    }))))), Caml.string_compare)
                            },
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var InputStyles;

var Form;

var Api;

var Types;

var make = Energy_Search_Filter;

export {
  InputStyles ,
  Form ,
  Api ,
  Types ,
  make ,
}
/* react Not a pure module */
