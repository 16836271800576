// Generated by ReScript, PLEASE EDIT WITH CARE


function whatCountry(userRole) {
  if (typeof userRole === "number") {
    return /* France */0;
  } else {
    return userRole._0;
  }
}

function hasAdminCountry(userRole, country) {
  if (typeof userRole === "number" || userRole.TAG !== /* Administrator */0) {
    return false;
  } else {
    return userRole._0 === country;
  }
}

function isAdmin(userRole) {
  if (typeof userRole === "number" || userRole.TAG !== /* Administrator */0) {
    return false;
  } else {
    return true;
  }
}

function isAdminDealer(userRole) {
  if (typeof userRole === "number" || userRole.TAG !== /* ADMINDEALER */1) {
    return false;
  } else {
    return true;
  }
}

function copareRoles(userRoleA, userRoleB) {
  if (typeof userRoleA !== "number") {
    if (userRoleA.TAG === /* Administrator */0) {
      if (typeof userRoleB === "number" || userRoleB.TAG !== /* Administrator */0) {
        return false;
      } else {
        return userRoleA._0 === userRoleB._0;
      }
    } else if (typeof userRoleB === "number" || userRoleB.TAG !== /* ADMINDEALER */1) {
      return false;
    } else {
      return userRoleA._0 === userRoleB._0;
    }
  }
  switch (userRoleA) {
    case /* Advisor */0 :
        return userRoleB === /* Advisor */0;
    case /* PreDirector */1 :
        return userRoleB === /* PreDirector */1;
    case /* CompanyManager */2 :
        return userRoleB === /* CompanyManager */2;
    case /* SeniorCompanyManager */3 :
        return userRoleB === /* SeniorCompanyManager */3;
    case /* AcountManager */4 :
        return userRoleB === /* AcountManager */4;
    case /* LldManager */5 :
        return userRoleB === /* LldManager */5;
    case /* BigAccountManager */6 :
        return userRoleB === /* BigAccountManager */6;
    case /* Dealer */7 :
        return userRoleB === /* Dealer */7;
    case /* MRR */8 :
        return userRoleB === /* MRR */8;
    case /* NoRole */9 :
        return userRoleB === /* NoRole */9;
    
  }
}

function userRoleFromString(userRole) {
  switch (userRole) {
    case "AcountManager" :
        return /* AcountManager */4;
    case "AdminDealer" :
    case "AdminDealerFrance" :
        return {
                TAG: /* ADMINDEALER */1,
                _0: /* France */0
              };
    case "AdminDealerSpain" :
        return {
                TAG: /* ADMINDEALER */1,
                _0: /* Spain */1
              };
    case "Administrator" :
    case "AdministratorFrance" :
        return {
                TAG: /* Administrator */0,
                _0: /* France */0
              };
    case "AdministratorSpain" :
        return {
                TAG: /* Administrator */0,
                _0: /* Spain */1
              };
    case "Advisor" :
        return /* Advisor */0;
    case "BigAccountManager" :
        return /* BigAccountManager */6;
    case "CompanyManager" :
        return /* CompanyManager */2;
    case "Dealer" :
        return /* Dealer */7;
    case "LldManager" :
        return /* LldManager */5;
    case "MRR" :
        return /* MRR */8;
    case "PreDirector" :
        return /* PreDirector */1;
    case "SeniorCompanyManager" :
        return /* SeniorCompanyManager */3;
    default:
      return /* NoRole */9;
  }
}

function userRoleToString(userRole) {
  if (typeof userRole !== "number") {
    if (userRole.TAG === /* Administrator */0) {
      if (userRole._0) {
        return "AdministratorSpain";
      } else {
        return "AdministratorFrance";
      }
    } else if (userRole._0) {
      return "AdminDealerSpain";
    } else {
      return "AdminDealerFrance";
    }
  }
  switch (userRole) {
    case /* Advisor */0 :
        return "Advisor";
    case /* PreDirector */1 :
        return "PreDirector";
    case /* CompanyManager */2 :
        return "CompanyManager";
    case /* SeniorCompanyManager */3 :
        return "SeniorCompanyManager";
    case /* AcountManager */4 :
        return "AcountManager";
    case /* LldManager */5 :
        return "LldManager";
    case /* BigAccountManager */6 :
        return "BigAccountManager";
    case /* Dealer */7 :
        return "Dealer";
    case /* MRR */8 :
        return "MRR";
    case /* NoRole */9 :
        return "";
    
  }
}

export {
  whatCountry ,
  hasAdminCountry ,
  isAdmin ,
  isAdminDealer ,
  copareRoles ,
  userRoleFromString ,
  userRoleToString ,
}
/* No side effect */
