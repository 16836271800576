// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as User_Context$DvmAdminFrontendFr from "../../context/user/User_Context.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../context/router/Context_Router.bs.js";
import * as Flash_Edit_Api$DvmAdminFrontendFr from "../flash/edit/Flash_Edit_Api.bs.js";
import * as Messages_Brands$DvmAdminFrontendFr from "../../intl/messages/brands/Messages_Brands.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../intl/messages/Messages_Common.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../common/styles/App_Styles_Common.bs.js";
import * as Brands_Search_Form$DvmAdminFrontendFr from "./Brands_Search_Form.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../common/components/input/App_DateInputFormField.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../common/components/input/App_AutocompleteFormInput.bs.js";

function Brands_Search_Filter(Props) {
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var alert = ReactAlert.useAlert();
  var match = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match[1];
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  var match$3 = React.useState(function () {
        return /* [] */0;
      });
  var setBrandsList = match$3[1];
  var brandsList = match$3[0];
  var form = Brands_Search_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.getWithDefault(match[0].brandsFilter, Brands_Search_Form$DvmAdminFrontendFr.initialInput), (function (param, submissionCallbacks) {
          Curry._2(fetchData, submissionCallbacks, {
                page: 0,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: fetchRequest.headers,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                              hd: param.brand,
                              tl: {
                                hd: param.brandLabel,
                                tl: {
                                  hd: param.startDate,
                                  tl: /* [] */0
                                }
                              }
                            }, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  React.useEffect((function () {
          Flash_Edit_Api$DvmAdminFrontendFr.queryForBrands(alert, userManager, setBrandsList, selectedRole);
          Curry._1(form.submit, undefined);
        }), []);
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    brand: input.brand,
                    brandLabel: input.brandLabel,
                    startDate: startDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
  };
  if (form.startDateResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(dispatch, {
                        TAG: /* StoreBrandsFilter */26,
                        _0: form.input
                      });
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true,
                  wrap: "nowrap"
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Brands$DvmAdminFrontendFr.brandCode,
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.brand,
                                onChange: Curry._1(form.updateBrand, (function (input, brand) {
                                        return {
                                                brand: brand,
                                                brandLabel: input.brandLabel,
                                                startDate: input.startDate
                                              };
                                      }))
                              }
                            },
                            options: {
                              TAG: /* Unlabeled */0,
                              _0: Belt_List.map(brandsList, (function (x) {
                                      return x.brandCode;
                                    }))
                            },
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Brands$DvmAdminFrontendFr.brandLabel,
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.brandLabel,
                                onChange: Curry._1(form.updateBrandLabel, (function (input, brandLabel) {
                                        return {
                                                brand: input.brand,
                                                brandLabel: brandLabel,
                                                startDate: input.startDate
                                              };
                                      }))
                              }
                            },
                            options: {
                              TAG: /* Unlabeled */0,
                              _0: Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(brandsList, (function (x) {
                                                  return x.brandName;
                                                })))))
                            },
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var InputStyles;

var Form;

var Api;

var Types;

var make = Brands_Search_Filter;

export {
  InputStyles ,
  Form ,
  Api ,
  Types ,
  make ,
}
/* react Not a pure module */
