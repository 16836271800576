// Generated by ReScript, PLEASE EDIT WITH CARE


var title = {
  id: "d984a43d-e7df-4363-9ccc-3a1045f3ee79",
  defaultMessage: "Rechercher une commande"
};

var immatVn = {
  id: "f5eb12a2-0737-4971-8825-fd394589580f",
  defaultMessage: "Immatriculation VN"
};

var searchForOrder = {
  id: "1658eae3-5256-438f-8318-659d38d17964",
  defaultMessage: "Indiquez vos éléments de recherche pour afficher les commandes souhaitées"
};

var orders = {
  id: "0e63861e-c1ca-4e4e-b19f-7de3dbf69834",
  defaultMessage: "commandes"
};

var customerOrderNum = {
  id: "ac5c53e9-0c88-4d21-b09c-d23d7362275b",
  defaultMessage: "N° commande"
};

var carOrderNum = {
  id: "a25bf354-97a0-45eb-bdf7-5d0ef02086de",
  defaultMessage: "N° ordre"
};

var orderDate = {
  id: "70f12dc5-8919-418c-9050-41b814dd921a",
  defaultMessage: "Date cde"
};

var deliveryDate = {
  id: "094f6540-c4a5-45bd-a243-00b8343cded9",
  defaultMessage: "Date Liv."
};

var showArchived = {
  id: "cca2bf05-2208-4d8e-abc1-4f8d699297ba",
  defaultMessage: "Montrer les commandes archivées"
};

var hintShowEmpty = {
  id: "e7ff6642-7feb-4067-bd09-7a5c4695c4ea",
  defaultMessage: "Rechercher infos VO manquantes"
};

var stateBudgetWithoutAnulee = {
  id: "52337a7a-4149-475e-9782-8eb118cd3953",
  defaultMessage: "Statut Budget (Tout sauf annulée)"
};

var stateWithoutAnulee = {
  id: "80ef47df-d917-4b6b-9883-d6dcb24ef48e",
  defaultMessage: "Statut (Tout sauf annulée)"
};

var orderStatusLabel = {
  id: "297ac229-9430-46fe-a71e-7f61ef23d3ea",
  defaultMessage: "Statut VN"
};

var orderStatusWithoutCanceledLabel = {
  id: "a9afcd15-4b20-4b36-84d6-3233d6df6f55",
  defaultMessage: "Statut VN (Tout sauf annulée)"
};

var orderStatusWithoutCanceledAndReparceledLabel = {
  id: "9ba6b22c-3af6-4271-8fb9-bef9d5035f85",
  defaultMessage: "Statut VN (Tout sauf annulée et remembrée)"
};

var usedCarStatusLabel = {
  id: "c78f87f0-9083-43ef-86c6-189a05f00c1d",
  defaultMessage: "Statut VO"
};

var statusComplete = {
  id: "9020edf1-2cbf-4c00-b54c-4d5296bdae52",
  defaultMessage: "Complète"
};

var statusIncomplete = {
  id: "91a86b93-e4d9-4c68-8ffe-6e7a042b4b62",
  defaultMessage: "Incomplète"
};

var statusCanceled = {
  id: "7b8852e0-2a9b-401b-b90c-4f2e867a642a",
  defaultMessage: "Annulée"
};

var statusNoVO = {
  id: "34c9296a-7a80-4aed-a6ae-7d3d7fe84841",
  defaultMessage: "Pas de VO"
};

var statusReparceled = {
  id: "17a8439b-f589-4375-aeca-963cf1c24e64",
  defaultMessage: "Remembrée"
};

var parcPending = {
  id: "5e618f07-468b-4792-aa0b-14e5ab01484c",
  defaultMessage: "Attente entrée PARC"
};

export {
  title ,
  immatVn ,
  searchForOrder ,
  orders ,
  customerOrderNum ,
  carOrderNum ,
  orderDate ,
  deliveryDate ,
  showArchived ,
  hintShowEmpty ,
  stateBudgetWithoutAnulee ,
  stateWithoutAnulee ,
  orderStatusLabel ,
  orderStatusWithoutCanceledLabel ,
  orderStatusWithoutCanceledAndReparceledLabel ,
  usedCarStatusLabel ,
  statusComplete ,
  statusIncomplete ,
  statusCanceled ,
  statusNoVO ,
  statusReparceled ,
  parcPending ,
}
/* No side effect */
