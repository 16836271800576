// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../common/types/App_Types_Date.bs.js";

var Fields = {
  budget: "budget",
  avsMarginGridID: "avsMarginGridID",
  categoryID: "categoryID",
  model: "model",
  version: "version",
  discount: "discount",
  margin: "margin",
  previousEndDate: "previousEndDate",
  startDate: "startDate",
  endDate: "endDate",
  followingStartDate: "followingStartDate",
  acquisitionId: "acquisitionId",
  categoryName: "categoryName",
  startYear: "startYear",
  endYear: "endYear"
};

function marginRow_encode(v) {
  return Js_dict.fromArray([
              [
                "budget",
                Decco.optionToJson(Decco.stringToJson, v.budget)
              ],
              [
                "model",
                Decco.optionToJson(Decco.stringToJson, v.model)
              ],
              [
                "version",
                Decco.optionToJson(Decco.stringToJson, v.version)
              ],
              [
                "avsMarginGridID",
                Decco.optionToJson(Decco.stringToJson, v.avsMarginGridID)
              ],
              [
                "categoryID",
                Decco.optionToJson(Decco.stringToJson, v.categoryID)
              ],
              [
                "discount",
                Decco.floatToJson(v.discount)
              ],
              [
                "margin",
                Decco.optionToJson(Decco.floatToJson, v.margin)
              ],
              [
                "previousEndDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.previousEndDate)
              ],
              [
                "startDate",
                Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ],
              [
                "hasExceptions",
                Decco.optionToJson(Decco.boolToJson, v.hasExceptions)
              ],
              [
                "followingStartDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.followingStartDate)
              ],
              [
                "acquisitionType",
                Decco.optionToJson(Decco.stringToJson, v.acquisitionType)
              ],
              [
                "contractID",
                Decco.optionToJson(Decco.stringToJson, v.contractID)
              ],
              [
                "categoryName",
                Decco.optionToJson(Decco.stringToJson, v.categoryName)
              ]
            ]);
}

function marginRow_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var budget = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "budget"), null));
  if (budget.TAG === /* Ok */0) {
    var model = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "model"), null));
    if (model.TAG === /* Ok */0) {
      var version = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "version"), null));
      if (version.TAG === /* Ok */0) {
        var avsMarginGridID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "avsMarginGridID"), null));
        if (avsMarginGridID.TAG === /* Ok */0) {
          var categoryID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "categoryID"), null));
          if (categoryID.TAG === /* Ok */0) {
            var discount = Decco.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "discount"), null));
            if (discount.TAG === /* Ok */0) {
              var margin = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "margin"), null));
              if (margin.TAG === /* Ok */0) {
                var previousEndDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "previousEndDate"), null));
                if (previousEndDate.TAG === /* Ok */0) {
                  var startDate = Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
                  if (startDate.TAG === /* Ok */0) {
                    var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
                    if (endDate.TAG === /* Ok */0) {
                      var hasExceptions = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "hasExceptions"), null));
                      if (hasExceptions.TAG === /* Ok */0) {
                        var followingStartDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "followingStartDate"), null));
                        if (followingStartDate.TAG === /* Ok */0) {
                          var acquisitionType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "acquisitionType"), null));
                          if (acquisitionType.TAG === /* Ok */0) {
                            var contractID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractID"), null));
                            if (contractID.TAG === /* Ok */0) {
                              var categoryName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "categoryName"), null));
                              if (categoryName.TAG === /* Ok */0) {
                                return {
                                        TAG: /* Ok */0,
                                        _0: {
                                          budget: budget._0,
                                          model: model._0,
                                          version: version._0,
                                          avsMarginGridID: avsMarginGridID._0,
                                          categoryID: categoryID._0,
                                          discount: discount._0,
                                          margin: margin._0,
                                          previousEndDate: previousEndDate._0,
                                          startDate: startDate._0,
                                          endDate: endDate._0,
                                          hasExceptions: hasExceptions._0,
                                          followingStartDate: followingStartDate._0,
                                          acquisitionType: acquisitionType._0,
                                          contractID: contractID._0,
                                          categoryName: categoryName._0
                                        }
                                      };
                              }
                              var e = categoryName._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".categoryName" + e.path,
                                        message: e.message,
                                        value: e.value
                                      }
                                    };
                            }
                            var e$1 = contractID._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".contractID" + e$1.path,
                                      message: e$1.message,
                                      value: e$1.value
                                    }
                                  };
                          }
                          var e$2 = acquisitionType._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".acquisitionType" + e$2.path,
                                    message: e$2.message,
                                    value: e$2.value
                                  }
                                };
                        }
                        var e$3 = followingStartDate._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".followingStartDate" + e$3.path,
                                  message: e$3.message,
                                  value: e$3.value
                                }
                              };
                      }
                      var e$4 = hasExceptions._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".hasExceptions" + e$4.path,
                                message: e$4.message,
                                value: e$4.value
                              }
                            };
                    }
                    var e$5 = endDate._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".endDate" + e$5.path,
                              message: e$5.message,
                              value: e$5.value
                            }
                          };
                  }
                  var e$6 = startDate._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".startDate" + e$6.path,
                            message: e$6.message,
                            value: e$6.value
                          }
                        };
                }
                var e$7 = previousEndDate._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".previousEndDate" + e$7.path,
                          message: e$7.message,
                          value: e$7.value
                        }
                      };
              }
              var e$8 = margin._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".margin" + e$8.path,
                        message: e$8.message,
                        value: e$8.value
                      }
                    };
            }
            var e$9 = discount._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".discount" + e$9.path,
                      message: e$9.message,
                      value: e$9.value
                    }
                  };
          }
          var e$10 = categoryID._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".categoryID" + e$10.path,
                    message: e$10.message,
                    value: e$10.value
                  }
                };
        }
        var e$11 = avsMarginGridID._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".avsMarginGridID" + e$11.path,
                  message: e$11.message,
                  value: e$11.value
                }
              };
      }
      var e$12 = version._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".version" + e$12.path,
                message: e$12.message,
                value: e$12.value
              }
            };
    }
    var e$13 = model._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".model" + e$13.path,
              message: e$13.message,
              value: e$13.value
            }
          };
  }
  var e$14 = budget._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".budget" + e$14.path,
            message: e$14.message,
            value: e$14.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(marginRow_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(marginRow_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function editList_encode(v) {
  return Decco.listToJson(marginRow_encode, v);
}

function editList_decode(v) {
  return Decco.listFromJson(marginRow_decode, v);
}

function editPair_encode(v) {
  return Js_dict.fromArray([
              [
                "original",
                marginRow_encode(v.original)
              ],
              [
                "new",
                marginRow_encode(v.new)
              ]
            ]);
}

function editPair_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var original = marginRow_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "original"), null));
  if (original.TAG === /* Ok */0) {
    var $$new = marginRow_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "new"), null));
    if ($$new.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                original: original._0,
                new: $$new._0
              }
            };
    }
    var e = $$new._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".new" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = original._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".original" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function editListPair_encode(v) {
  return Decco.listToJson(editPair_encode, v);
}

function editListPair_decode(v) {
  return Decco.listFromJson(editPair_decode, v);
}

var Margins = {
  marginRow_encode: marginRow_encode,
  marginRow_decode: marginRow_decode,
  searchResult_encode: searchResult_encode,
  searchResult_decode: searchResult_decode,
  editList_encode: editList_encode,
  editList_decode: editList_decode,
  editPair_encode: editPair_encode,
  editPair_decode: editPair_decode,
  editListPair_encode: editListPair_encode,
  editListPair_decode: editListPair_decode
};

function row_encode(v) {
  return Js_dict.fromArray([
              [
                "avsMarginGridID",
                Decco.stringToJson(v.avsMarginGridID)
              ],
              [
                "categoryID",
                Decco.stringToJson(v.categoryID)
              ]
            ]);
}

function row_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var avsMarginGridID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "avsMarginGridID"), null));
  if (avsMarginGridID.TAG === /* Ok */0) {
    var categoryID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "categoryID"), null));
    if (categoryID.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                avsMarginGridID: avsMarginGridID._0,
                categoryID: categoryID._0
              }
            };
    }
    var e = categoryID._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".categoryID" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = avsMarginGridID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".avsMarginGridID" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function searchResult_encode$1(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(row_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode$1(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(row_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var AVSSelects = {
  row_encode: row_encode,
  row_decode: row_decode,
  searchResult_encode: searchResult_encode$1,
  searchResult_decode: searchResult_decode$1
};

function element_encode(v) {
  return Js_dict.fromArray([[
                "data",
                Decco.floatToJson(v.data)
              ]]);
}

function element_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var data = Decco.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "data"), null));
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              data: data._0
            }
          };
  }
  var e = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var Discount = {
  element_encode: element_encode,
  element_decode: element_decode
};

function element_encode$1(v) {
  return Js_dict.fromArray([
              [
                "data",
                Decco.stringToJson(v.data)
              ],
              [
                "children",
                Decco.listToJson(element_encode, v.children)
              ]
            ]);
}

function element_decode$1(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var data = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "data"), null));
  if (data.TAG === /* Ok */0) {
    var children = Decco.listFromJson(element_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "children"), null));
    if (children.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                data: data._0,
                children: children._0
              }
            };
    }
    var e = children._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".children" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var Category = {
  element_encode: element_encode$1,
  element_decode: element_decode$1
};

function element_encode$2(v) {
  return Js_dict.fromArray([
              [
                "data",
                Decco.stringToJson(v.data)
              ],
              [
                "children",
                Decco.listToJson(element_encode$1, v.children)
              ]
            ]);
}

function element_decode$2(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var data = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "data"), null));
  if (data.TAG === /* Ok */0) {
    var children = Decco.listFromJson(element_decode$1, Belt_Option.getWithDefault(Js_dict.get(dict$1, "children"), null));
    if (children.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                data: data._0,
                children: children._0
              }
            };
    }
    var e = children._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".children" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var MarginGrid = {
  element_encode: element_encode$2,
  element_decode: element_decode$2
};

function gridTreeList_encode(v) {
  return Decco.listToJson(element_encode$2, v);
}

function gridTreeList_decode(v) {
  return Decco.listFromJson(element_decode$2, v);
}

var modelGridEndpoint = "modelgrid";

var versionGridEndpoint = "versiongrid";

export {
  Fields ,
  Margins ,
  AVSSelects ,
  Discount ,
  Category ,
  MarginGrid ,
  gridTreeList_encode ,
  gridTreeList_decode ,
  modelGridEndpoint ,
  versionGridEndpoint ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
