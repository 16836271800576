// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jss from "jss";
import * as Css from "@emotion/css";
import * as Styles from "@material-ui/core/styles";
import * as Constants$DvmAdminFrontendFr from "./Constants.bs.js";

function createJss(param) {
  return Jss.create(Object.assign(Styles.jssPreset(), {
                  insertionPoint: document.getElementById("mui-insertion-point")
                }));
}

var spacingXs = "8px";

var spacingSm = "16px";

var spacingMd = "24px";

var spacingLg = "32px";

var spacingXl = "40px";

var spacingXxl = "72px";

var spacingXxxl = "104px";

var marginAllXs = Css.css({
      margin: spacingXs
    });

var marginAllSm = Css.css({
      margin: spacingSm
    });

var marginAllMd = Css.css({
      margin: spacingMd
    });

var marginAllLg = Css.css({
      margin: spacingLg
    });

var marginAllXl = Css.css({
      margin: spacingXl
    });

var marginAllXxl = Css.css({
      margin: spacingXxl
    });

var marginAllXxxl = Css.css({
      margin: spacingXxxl
    });

var marginTopXs = Css.css({
      marginTop: spacingXs
    });

var marginTopSm = Css.css({
      marginTop: spacingSm
    });

var marginTopMd = Css.css({
      marginTop: spacingMd
    });

var marginTopLg = Css.css({
      marginTop: spacingLg
    });

var marginTopXl = Css.css({
      marginTop: spacingXl
    });

var marginTopXxl = Css.css({
      marginTop: spacingXxl
    });

var marginTopXxxl = Css.css({
      marginTop: spacingXxxl
    });

var marginRightXs = Css.css({
      marginRight: spacingXs
    });

var marginRightSm = Css.css({
      marginRight: spacingSm
    });

var marginRightMd = Css.css({
      marginRight: spacingMd
    });

var marginRightLg = Css.css({
      marginRight: spacingLg
    });

var marginRightXl = Css.css({
      marginRight: spacingXl
    });

var marginRightXxl = Css.css({
      marginRight: spacingXxl
    });

var marginRightXxxl = Css.css({
      marginRight: spacingXxxl
    });

var marginBottomXs = Css.css({
      marginBottom: spacingXs
    });

var marginBottomSm = Css.css({
      marginBottom: spacingSm
    });

var marginBottomMd = Css.css({
      marginBottom: spacingMd
    });

var marginBottomLg = Css.css({
      marginBottom: spacingLg
    });

var marginBottomXl = Css.css({
      marginBottom: spacingXl
    });

var marginBottomXxl = Css.css({
      marginBottom: spacingXxl
    });

var marginBottomXxxl = Css.css({
      marginBottom: spacingXxxl
    });

var marginLeftXs = Css.css({
      marginLeft: spacingXs
    });

var marginLeftSm = Css.css({
      marginLeft: spacingSm
    });

var marginLeftMd = Css.css({
      marginLeft: spacingMd
    });

var marginLeftLg = Css.css({
      marginLeft: spacingLg
    });

var marginLeftXl = Css.css({
      marginLeft: spacingXl
    });

var marginLeftXxl = Css.css({
      marginLeft: spacingXxl
    });

var marginLeftXxxl = Css.css({
      marginLeft: spacingXxxl
    });

var paddingAllXs = Css.css({
      padding: spacingXs
    });

var paddingAllSm = Css.css({
      padding: spacingSm
    });

var paddingAllMd = Css.css({
      padding: spacingMd
    });

var paddingAllLg = Css.css({
      padding: spacingLg
    });

var paddingAllXl = Css.css({
      padding: spacingXl
    });

var paddingAllXxl = Css.css({
      padding: spacingXxl
    });

var paddingAllXxxl = Css.css({
      padding: spacingXxxl
    });

var paddingTopXs = Css.css({
      paddingTop: spacingXs
    });

var paddingTopSm = Css.css({
      paddingTop: spacingSm
    });

var paddingTopMd = Css.css({
      paddingTop: spacingMd
    });

var paddingTopLg = Css.css({
      paddingTop: spacingLg
    });

var paddingTopXl = Css.css({
      paddingTop: spacingXl
    });

var paddingTopXxl = Css.css({
      paddingTop: spacingXxl
    });

var paddingTopXxxl = Css.css({
      paddingTop: spacingXxxl
    });

var paddingRightXs = Css.css({
      paddingRight: spacingXs
    });

var paddingRightSm = Css.css({
      paddingRight: spacingSm
    });

var paddingRightMd = Css.css({
      paddingRight: spacingMd
    });

var paddingRightLg = Css.css({
      paddingRight: spacingLg
    });

var paddingRightXl = Css.css({
      paddingRight: spacingXl
    });

var paddingRightXxl = Css.css({
      paddingRight: spacingXxl
    });

var paddingRightXxxl = Css.css({
      paddingRight: spacingXxxl
    });

var paddingBottomXs = Css.css({
      paddingBottom: spacingXs
    });

var paddingBottomSm = Css.css({
      paddingBottom: spacingSm
    });

var paddingBottomMd = Css.css({
      paddingBottom: spacingMd
    });

var paddingBottomLg = Css.css({
      paddingBottom: spacingLg
    });

var paddingBottomXl = Css.css({
      paddingBottom: spacingXl
    });

var paddingBottomXxl = Css.css({
      paddingBottom: spacingXxl
    });

var paddingBottomXxxl = Css.css({
      paddingBottom: spacingXxxl
    });

var paddingLeftXs = Css.css({
      paddingLeft: spacingXs
    });

var paddingLeftSm = Css.css({
      paddingLeft: spacingSm
    });

var paddingLeftMd = Css.css({
      paddingLeft: spacingMd
    });

var paddingLeftLg = Css.css({
      paddingLeft: spacingLg
    });

var paddingLeftXl = Css.css({
      paddingLeft: spacingXl
    });

var paddingLeftXxl = Css.css({
      paddingLeft: spacingXxl
    });

var paddingLeftXxxl = Css.css({
      paddingLeft: spacingXxxl
    });

var centeredText = Css.css({
      textAlign: "center"
    });

var removed = Css.css({
      display: "none"
    });

var hidden = Css.css({
      visibility: "hidden"
    });

var fitContentVertically = Css.css({
      height: "100%"
    });

var fitContentHorizontally = Css.css({
      width: "100%"
    });

var flexGrow1 = Css.css({
      flexGrow: "1"
    });

var flexGrow0 = Css.css({
      flexGrow: "0"
    });

var nonBreakingSpaces = Css.css({
      whiteSpace: "nowrap"
    });

var leftText = Css.css({
      textAlign: "left"
    });

var rightText = Css.css({
      textAlign: "right"
    });

var light = Css.css({
      fontWeight: "200 !important"
    });

var book = Css.css({
      fontWeight: "300 !important"
    });

var regular = Css.css({
      fontWeight: "400 !important"
    });

var semiBold = Css.css({
      fontWeight: "500 !important"
    });

var bold = Css.css({
      fontWeight: "600 !important"
    });

var extraBold = Css.css({
      fontWeight: "700 !important"
    });

var italic = Css.css({
      fontStyle: "italic"
    });

var overflowX = Css.css({
      overflowX: "auto"
    });

var overflowY = Css.css({
      overflowY: "auto"
    });

var positionRelative = Css.css({
      position: "relative"
    });

var wordBreak = Css.css({
      wordBreak: "break-word"
    });

var stickyBar = Css.css({
      backgroundColor: "transparent",
      position: "sticky",
      top: Constants$DvmAdminFrontendFr.topMenuHeight,
      zIndex: "3",
      boxShadow: "none"
    });

var footerBar = Css.css({
      backgroundColor: "transparent",
      boxShadow: "none",
      position: "sticky",
      zIndex: "3",
      bottom: "0"
    });

function brownSemibold(theme) {
  return Css.css({
              color: theme.palette.primary.dark,
              fontWeight: "600"
            });
}

var alignSelfLeft = Css.css({
      alignSelf: "flex-end"
    });

var spacing = "10px";

var spacingLine = "1px";

var spacingAppMinWidth = "300px";

export {
  createJss ,
  spacingXs ,
  spacingSm ,
  spacingMd ,
  spacingLg ,
  spacingXl ,
  spacingXxl ,
  spacingXxxl ,
  spacing ,
  spacingLine ,
  spacingAppMinWidth ,
  marginAllXs ,
  marginAllSm ,
  marginAllMd ,
  marginAllLg ,
  marginAllXl ,
  marginAllXxl ,
  marginAllXxxl ,
  marginTopXs ,
  marginTopSm ,
  marginTopMd ,
  marginTopLg ,
  marginTopXl ,
  marginTopXxl ,
  marginTopXxxl ,
  marginRightXs ,
  marginRightSm ,
  marginRightMd ,
  marginRightLg ,
  marginRightXl ,
  marginRightXxl ,
  marginRightXxxl ,
  marginBottomXs ,
  marginBottomSm ,
  marginBottomMd ,
  marginBottomLg ,
  marginBottomXl ,
  marginBottomXxl ,
  marginBottomXxxl ,
  marginLeftXs ,
  marginLeftSm ,
  marginLeftMd ,
  marginLeftLg ,
  marginLeftXl ,
  marginLeftXxl ,
  marginLeftXxxl ,
  paddingAllXs ,
  paddingAllSm ,
  paddingAllMd ,
  paddingAllLg ,
  paddingAllXl ,
  paddingAllXxl ,
  paddingAllXxxl ,
  paddingTopXs ,
  paddingTopSm ,
  paddingTopMd ,
  paddingTopLg ,
  paddingTopXl ,
  paddingTopXxl ,
  paddingTopXxxl ,
  paddingRightXs ,
  paddingRightSm ,
  paddingRightMd ,
  paddingRightLg ,
  paddingRightXl ,
  paddingRightXxl ,
  paddingRightXxxl ,
  paddingBottomXs ,
  paddingBottomSm ,
  paddingBottomMd ,
  paddingBottomLg ,
  paddingBottomXl ,
  paddingBottomXxl ,
  paddingBottomXxxl ,
  paddingLeftXs ,
  paddingLeftSm ,
  paddingLeftMd ,
  paddingLeftLg ,
  paddingLeftXl ,
  paddingLeftXxl ,
  paddingLeftXxxl ,
  centeredText ,
  removed ,
  hidden ,
  fitContentVertically ,
  fitContentHorizontally ,
  flexGrow1 ,
  flexGrow0 ,
  nonBreakingSpaces ,
  leftText ,
  rightText ,
  light ,
  book ,
  regular ,
  semiBold ,
  bold ,
  extraBold ,
  italic ,
  overflowX ,
  overflowY ,
  positionRelative ,
  wordBreak ,
  stickyBar ,
  footerBar ,
  brownSemibold ,
  alignSelfLeft ,
}
/* marginAllXs Not a pure module */
