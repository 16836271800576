// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../common/components/button/App_Button.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../intl/Intl_Utils.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Alert.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../common/components/App_OverlaySpinner.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_Orders_Bypass$DvmAdminFrontendFr from "../../../../../intl/messages/orders/Messages_Orders_Bypass.bs.js";
import * as Order_Detail_Budget_Bypass_Api$DvmAdminFrontendFr from "./Order_Detail_Budget_Bypass_Api.bs.js";
import * as Order_Detail_Budget_Bypass_Form$DvmAdminFrontendFr from "./Order_Detail_Budget_Bypass_Form.bs.js";
import * as Order_Detail_Bonus_Derogate_Order_Info$DvmAdminFrontendFr from "../derogate/create/Order_Detail_Bonus_Derogate_Order_Info.bs.js";
import * as Order_Detail_Bonus_Derogate_Action_Info$DvmAdminFrontendFr from "../derogate/create/Order_Detail_Bonus_Derogate_Action_Info.bs.js";

function Order_Detail_Budget_Bypass_Modal(Props) {
  var order = Props.order;
  var orderId = Props.orderId;
  var action = Props.action;
  var onClose = Props.onClose;
  var onOk = Props.onOk;
  var discount = Props.discount;
  var gridDiscount = Props.gridDiscount;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var alert = ReactAlert.useAlert();
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match$2[1];
  var saveResult = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var form = Order_Detail_Budget_Bypass_Form$DvmAdminFrontendFr.Form.useForm(Order_Detail_Budget_Bypass_Form$DvmAdminFrontendFr.initialInput, (function (param, submissionCallbacks) {
          Order_Detail_Budget_Bypass_Api$DvmAdminFrontendFr.bypassOrder(userManager, setSaveResult, orderId, submissionCallbacks, selectedRole, param.comment);
        }));
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Curry._1(onOk, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  var tmp = {
    onChange: Curry._1(form.updateComment, (function (param, comment) {
            return {
                    comment: comment
                  };
          })),
    value: form.input.comment,
    fullWidth: true,
    label: Messages_Common$DvmAdminFrontendFr.comment,
    required: /* OnlyLabel */1
  };
  if (form.commentResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.commentResult);
  }
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Orders_Bypass$DvmAdminFrontendFr.derogateTitle, {
                    id: order.orderNumberCustomer
                  }),
              minWidth: "868px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult),
                    children: React.createElement("form", {
                          onSubmit: (function ($$event) {
                              $$event.preventDefault();
                              Curry._1(form.submit, undefined);
                            })
                        }, React.createElement(Core.Box, {
                              children: null,
                              marginX: 1
                            }, React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  direction: "column",
                                  spacing: 3
                                }, React.createElement(Order_Detail_Bonus_Derogate_Order_Info$DvmAdminFrontendFr.make, {
                                      order: order
                                    }), React.createElement(Order_Detail_Bonus_Derogate_Action_Info$DvmAdminFrontendFr.make, {
                                      action: action,
                                      actionType: Belt_Option.mapWithDefault(action, "", (function (x) {
                                              return x.actionType;
                                            })),
                                      actionId: Belt_Option.mapWithDefault(action, "", (function (x) {
                                              return x.actionId;
                                            })),
                                      orderType: /* SO */0,
                                      actionFetch: match$3[0],
                                      setActionFetch: match$3[1],
                                      displayAmounts: true
                                    }), React.createElement(Core.Grid, {
                                      children: null,
                                      container: true,
                                      item: true,
                                      justifyContent: "space-between",
                                      spacing: 4
                                    }, React.createElement(Core.Grid, {
                                          children: null,
                                          container: true,
                                          item: true,
                                          spacing: 2,
                                          wrap: "nowrap",
                                          xs: Grid$Mui.Xs[4]
                                        }, React.createElement(Core.Grid, {
                                              children: React.createElement(Core.Box, {
                                                    children: null
                                                  }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                        style: {
                                                          color: Theme_Colors$DvmAdminFrontendFr.mineralDarker
                                                        },
                                                        children: intl.formatMessage(Messages_Orders_Bypass$DvmAdminFrontendFr.discountPercentTitle),
                                                        variant: "subtitle"
                                                      }), React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                        style: {
                                                          fontWeight: "600"
                                                        },
                                                        children: Belt_Option.mapWithDefault(discount, "-", (function (param) {
                                                                return Intl_Utils$DvmAdminFrontendFr.formatPercent(intl, param);
                                                              })),
                                                        variant: "h5"
                                                      })),
                                              item: true
                                            }), React.createElement(Core.Grid, {
                                              children: React.createElement(Core.Box, {
                                                    children: null
                                                  }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                        style: {
                                                          color: Theme_Colors$DvmAdminFrontendFr.mineralDarker
                                                        },
                                                        children: intl.formatMessage(Messages_Orders_Bypass$DvmAdminFrontendFr.discountCondiditonGridTitle),
                                                        variant: "subtitle"
                                                      }), React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                        style: {
                                                          fontWeight: "600"
                                                        },
                                                        children: Belt_Option.mapWithDefault(gridDiscount, "-", (function (param) {
                                                                return Intl_Utils$DvmAdminFrontendFr.formatPercent(intl, param);
                                                              })),
                                                        variant: "h5"
                                                      })),
                                              item: true
                                            })), React.createElement(Core.Grid, {
                                          children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp),
                                          item: true,
                                          xs: Grid$Mui.Xs[8]
                                        }))), React.createElement(Core.Box, {
                                  children: React.createElement(Core.Grid, {
                                        children: React.createElement(Core.Grid, {
                                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                                    size: "medium",
                                                    type_: "submit"
                                                  }),
                                              item: true
                                            }),
                                        container: true,
                                        justify: "flex-end"
                                      }),
                                  mt: 2
                                })))
                  })
            });
}

var Api;

var Types;

var Form;

var make = Order_Detail_Budget_Bypass_Modal;

export {
  Api ,
  Types ,
  Form ,
  make ,
}
/* react Not a pure module */
