// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_SetInt from "rescript/lib/es6/belt_SetInt.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Button.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Contract_Attachment_Add$DvmAdminFrontendFr from "./Contract_Attachment_Add.bs.js";
import * as Contract_Attachments_Api$DvmAdminFrontendFr from "../Contract_Attachments_Api.bs.js";
import * as Messages_Contract_Create$DvmAdminFrontendFr from "../../../../../intl/messages/contract/Messages_Contract_Create.bs.js";
import * as Contract_Attachments_Amount$DvmAdminFrontendFr from "./Contract_Attachments_Amount.bs.js";
import * as Contract_Attachments_Add_Form$DvmAdminFrontendFr from "./Contract_Attachments_Add_Form.bs.js";

function Contract_Attachments_Add(Props) {
  var onClose = Props.onClose;
  var contract = Props.contract;
  var onOk = Props.onOk;
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match[1];
  var saveResult = match[0];
  var match$1 = React.useState(function () {
        return /* NotStarted */0;
      });
  var sirenNameResult = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setVerifyingSiren = match$2[1];
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match$3 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$4 = match$3[0];
  var userManager = match$4.userManager;
  var selectedRole = match$4.selectedRole;
  var match$5 = React.useState(function () {
        return Belt_SetInt.fromArray([]);
      });
  var setAttachmentErrors = match$5[1];
  var match$6 = React.useState(function () {
        return false;
      });
  var setSirenCheckPending = match$6[1];
  var match$7 = React.useState(function () {
        return Belt_MapString.fromArray([]);
      });
  var setSirenNamesMap = match$7[1];
  var sirenNamesMap = match$7[0];
  var saveAttachments = function (param) {
    var attachments = param.attachments;
    if (Belt_Array.keep(attachments, (function (x) {
              return x.siren.length > 0;
            })).length !== 0) {
      return Contract_Attachments_Api$DvmAdminFrontendFr.saveAttachments(setSaveResult, userManager, selectedRole, Belt_List.fromArray(Belt_Array.map(Belt_Array.keep(attachments, (function (x) {
                                return x.siren.length > 0;
                              })), (function (param) {
                            return {
                                    contractID: contract.contractID,
                                    siren: param.siren,
                                    sirenName: undefined,
                                    startDate: param.startDate,
                                    endDate: param.endDate
                                  };
                          }))));
    }
    
  };
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(onOk, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  React.useEffect((function () {
          if (typeof sirenNameResult === "number") {
            sirenNameResult === /* NotStarted */0;
          } else if (sirenNameResult.TAG === /* Ok */0) {
            var match = sirenNameResult._0;
            var name = match[1];
            var siren = match[0];
            Curry._1(setSirenNamesMap, (function (sirenNamesMap) {
                    return Belt_MapString.set(sirenNamesMap, siren, name);
                  }));
          }
          
        }), [sirenNameResult]);
  var form = Contract_Attachments_Add_Form$DvmAdminFrontendFr.AttachmentsForm.useForm(Contract_Attachments_Add_Form$DvmAdminFrontendFr.initialInput, {
        contractId: contract.contractID,
        contractSIREN: contract.contractSIREN,
        contractStartDate: contract.startDate,
        contractEndDate: contract.endDate,
        userManager: userManager,
        selectedRole: selectedRole,
        setSirenNameResult: match$1[1]
      }, (function (output, param) {
          saveAttachments(output);
          Curry._1(param.notifyOnSuccess, undefined);
        }));
  React.useEffect((function () {
          Curry._1(setVerifyingSiren, (function (param) {
                  return Belt_Array.keepMap(Belt_Array.mapWithIndex(form.input.attachments, (function (at, param) {
                                    return Curry._1(form.attachmentSirenResult, at);
                                  })), (function (__x) {
                                return Belt_Option.flatMap(__x, (function (result) {
                                              if (result.TAG === /* Validating */0) {
                                                return Caml_option.some(undefined);
                                              }
                                              
                                            }));
                              })).length !== 0;
                }));
        }), [form]);
  var handleAmountChange = function (amount) {
    for(var _for = 1; _for <= amount; ++_for){
      Curry._1(form.addAttachment, Contract_Attachments_Add_Form$DvmAdminFrontendFr.initialAttachmentInput);
    }
  };
  React.useEffect((function () {
          handleAmountChange(1);
        }), []);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Contract_Create$DvmAdminFrontendFr.addAttachmentsModalLabel) + " " + contract.contractLabel,
              minWidth: "1300px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult),
                    children: React.createElement(Core.Grid, {
                          alignItems: "stretch",
                          children: null,
                          container: true,
                          direction: "column",
                          spacing: 2
                        }, React.createElement(Contract_Attachments_Amount$DvmAdminFrontendFr.make, {
                              handleAmountChange: handleAmountChange,
                              formAttachments: form
                            }), React.createElement("form", {
                              onSubmit: (function ($$event) {
                                  $$event.preventDefault();
                                  Curry._1(form.submit, undefined);
                                })
                            }, React.createElement(Core.Grid, {
                                  children: React.createElement(Core.Box, {
                                        style: {
                                          margin: "20px 0px",
                                          maxHeight: "70vh",
                                          overflowX: "hidden",
                                          overflowY: "auto"
                                        },
                                        children: Belt_Array.mapWithIndex(form.input.attachments, (function (at, attachment) {
                                                return React.createElement(Contract_Attachment_Add$DvmAdminFrontendFr.make, {
                                                            attachment: attachment,
                                                            form: form,
                                                            at: at,
                                                            sirenNamesMap: sirenNamesMap,
                                                            sirenNameResult: sirenNameResult,
                                                            setAttachmentErrors: setAttachmentErrors,
                                                            setSirenCheckPending: setSirenCheckPending,
                                                            contract: contract,
                                                            key: "contract-attachment-item-" + String(at) + ""
                                                          });
                                              }))
                                      }),
                                  item: true
                                }), React.createElement(Core.Grid, {
                                  children: React.createElement(Core.Grid, {
                                        children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                              show: match$2[0] || match$6[0],
                                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                                    size: "medium",
                                                    disabled: Belt_SetInt.size(match$5[0]) > 0,
                                                    type_: "submit"
                                                  })
                                            }),
                                        item: true
                                      }),
                                  container: true,
                                  item: true,
                                  justifyContent: "flex-end"
                                })))
                  })
            });
}

var Form;

var Attachment;

var Amount;

var Api;

var make = Contract_Attachments_Add;

export {
  Form ,
  Attachment ,
  Amount ,
  Api ,
  make ,
}
/* react Not a pure module */
