// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Flash_Types$DvmAdminFrontendFr from "../../../Flash_Types.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_RadioFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_RadioFormInput.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Flash_Edit_Details_Dates$DvmAdminFrontendFr from "../Flash_Edit_Details_Dates.bs.js";
import * as Flash_Edit_Value_Percentage_Check$DvmAdminFrontendFr from "../Flash_Edit_Value_Percentage_Check.bs.js";

function Flash_Edit_Details_REPR(Props) {
  var form = Props.form;
  var formGeneralDates = Props.formGeneralDates;
  var children = Props.children;
  var budgets = Props.budgets;
  var isStepOpen = Props.isStepOpen;
  var tmp = {
    label: Messages_Flash_Edit$DvmAdminFrontendFr.bonusAmount,
    value: Belt_Option.mapWithDefault(form.input.numberType, "", Flash_Types$DvmAdminFrontendFr.ValueType.toString),
    options: {
      hd: {
        label: {
          TAG: /* Message */0,
          _0: Messages_Flash_Edit$DvmAdminFrontendFr.inPercent
        },
        value: "PERCENTAGE"
      },
      tl: {
        hd: {
          label: {
            TAG: /* Message */0,
            _0: Messages_Flash_Edit$DvmAdminFrontendFr.inEuro
          },
          value: "AMOUNT"
        },
        tl: /* [] */0
      }
    },
    onChange: (function (value) {
        Curry._2(form.updateNumberType, (function (input, numberType) {
                var newrecord = Caml_obj.obj_dup(input);
                newrecord.numberType = numberType;
                return newrecord;
              }), Flash_Types$DvmAdminFrontendFr.ValueType.fromString(value));
      }),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.extraLongInput
  };
  if (form.numberTypeResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.numberTypeResult);
  }
  var tmp$1 = {
    onChange: Curry._1(form.updateValue, (function (input, value) {
            var newrecord = Caml_obj.obj_dup(input);
            newrecord.value = value;
            return newrecord;
          })),
    value: form.input.value,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Flash_Edit$DvmAdminFrontendFr.bonusAmount,
    required: /* OnlyLabel */1,
    inputType: "number"
  };
  if (form.valueResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.valueResult);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  spacing: 4
                }, React.createElement(Flash_Edit_Details_Dates$DvmAdminFrontendFr.make, {
                      form: form,
                      formGeneralDates: formGeneralDates,
                      budgets: budgets
                    }), React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      direction: "column",
                      item: true
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, tmp),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(Flash_Edit_Value_Percentage_Check$DvmAdminFrontendFr.make, {
                                children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$1),
                                form: form,
                                isStepOpen: isStepOpen
                              }),
                          container: true,
                          item: true
                        })), React.createElement(Core.Grid, {
                      children: children,
                      container: true,
                      item: true,
                      justifyContent: "center"
                    })));
}

var Form;

var FormGeneralDates;

var Types;

var InputStyles;

var make = Flash_Edit_Details_REPR;

export {
  Form ,
  FormGeneralDates ,
  Types ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
