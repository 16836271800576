// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as EnvVar$DvmAdminFrontendFr from "../../../bindings/global/EnvVar.bs.js";
import * as App_Api$DvmAdminFrontendFr from "../../../common/App_Api.bs.js";
import * as Constants$DvmAdminFrontendFr from "../../../common/Constants.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as App_TopMenu$DvmAdminFrontendFr from "../../../common/components/topMenu/App_TopMenu.bs.js";
import * as Auth_Router$DvmAdminFrontendFr from "../Auth_Router.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../../context/router/Context_Router.bs.js";
import * as Messages_Roles$DvmAdminFrontendFr from "../../../intl/messages/roles/Messages_Roles.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as App_RadioFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_RadioFormInput.bs.js";
import * as Utils_Authentication$DvmAdminFrontendFr from "../../../common/utils/Utils_Authentication.bs.js";

var labelStyle = Css.css({
      color: Theme_Colors$DvmAdminFrontendFr.black,
      fontWeight: "bold"
    });

function hasAdminDealer(dealers, roles) {
  if (Belt_Option.isSome(Belt_List.getBy(roles, User_Types_Utils$DvmAdminFrontendFr.isAdmin))) {
    return App_Types_Result$DvmAdminFrontendFr.mapWithDefault(dealers, false, (function (x) {
                  return Belt_List.length(Belt_List.concatMany(Belt_List.toArray(Belt_List.map(x, (function (x) {
                                            return x.child;
                                          }))))) > 0;
                }));
  } else {
    return false;
  }
}

function Auth_Select_Role_Page(Props) {
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatchUser = match[1];
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var roles = match$1.roles;
  var match$2 = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match$2[1];
  var match$3 = React.useState(function () {
        return Utils_Authentication$DvmAdminFrontendFr.roleToString(selectedRole);
      });
  var setNewRole = match$3[1];
  var newRole = match$3[0];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDrAndDealersFr = match$4[1];
  var drAndDealersFr = match$4[0];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDrAndDealersEs = match$5[1];
  var drAndDealersEs = match$5[0];
  var selectRole = function (r) {
    Curry._1(dispatchUser, {
          TAG: /* StoreRole */3,
          _0: r
        });
    sessionStorage.setItem(Constants$DvmAdminFrontendFr.dvmeRoleSessionStorageKey, User_Types_Utils$DvmAdminFrontendFr.userRoleToString(r));
    var redirect = sessionStorage.getItem(Constants$DvmAdminFrontendFr.redirectUrlSessionStorageKey);
    Belt_Option.mapWithDefault((redirect == null) ? undefined : Caml_option.some(redirect), Auth_Router$DvmAdminFrontendFr.routeToMainPage(undefined), RescriptReactRouter.push);
  };
  React.useEffect((function () {
          if (Belt_List.length(Belt_List.keep(roles, User_Types_Utils$DvmAdminFrontendFr.isAdmin)) > 0) {
            if (Belt_List.length(Belt_List.keep(roles, (function (x) {
                          return User_Types_Utils$DvmAdminFrontendFr.hasAdminCountry(x, /* France */0);
                        }))) > 0) {
              App_Api$DvmAdminFrontendFr.queryForDRAndDealers(alert, userManager, setDrAndDealersFr, true, {
                    TAG: /* ADMINDEALER */1,
                    _0: /* France */0
                  });
            }
            if (!EnvVar$DvmAdminFrontendFr.currentEnvironmentProd && Belt_List.length(Belt_List.keep(roles, (function (x) {
                          return User_Types_Utils$DvmAdminFrontendFr.hasAdminCountry(x, /* Spain */1);
                        }))) > 0) {
              App_Api$DvmAdminFrontendFr.queryForDRAndDealers(alert, userManager, setDrAndDealersEs, true, {
                    TAG: /* ADMINDEALER */1,
                    _0: /* Spain */1
                  });
            }
            
          } else if (Belt_List.length(roles) === 1) {
            selectRole(Belt_List.getExn(roles, 0));
          }
          
        }), []);
  var roleOptions = function (param) {
    var rolesModified = Belt_Option.isSome(Belt_List.getBy(roles, User_Types_Utils$DvmAdminFrontendFr.isAdmin)) ? Belt_List.concat(Belt_List.concat(Belt_List.length(Belt_List.keep(roles, (function (x) {
                          return User_Types_Utils$DvmAdminFrontendFr.hasAdminCountry(x, /* France */0);
                        }))) > 0 ? ({
                    hd: {
                      TAG: /* ADMINDEALER */1,
                      _0: /* France */0
                    },
                    tl: /* [] */0
                  }) : /* [] */0, Belt_List.length(Belt_List.keep(roles, (function (x) {
                          return User_Types_Utils$DvmAdminFrontendFr.hasAdminCountry(x, /* Spain */1);
                        }))) > 0 ? ({
                    hd: {
                      TAG: /* ADMINDEALER */1,
                      _0: /* Spain */1
                    },
                    tl: /* [] */0
                  }) : /* [] */0), roles) : roles;
    var rolesClean = EnvVar$DvmAdminFrontendFr.currentEnvironmentProd ? Belt_List.keep(rolesModified, (function (x) {
              return User_Types_Utils$DvmAdminFrontendFr.whatCountry(x) === /* France */0;
            })) : rolesModified;
    return Belt_List.map(rolesClean, (function (role) {
                  return {
                          label: {
                            TAG: /* String */3,
                            _0: intl.formatMessage(Utils_Authentication$DvmAdminFrontendFr.getRoleTranslation(role))
                          },
                          value: Utils_Authentication$DvmAdminFrontendFr.roleToString(role)
                        };
                }));
  };
  var getDisabledOptions = function (param) {
    return Belt_List.map(roleOptions(undefined), (function (x) {
                  var match = x.value;
                  switch (match) {
                    case "dvme_es_admindealer" :
                        return !hasAdminDealer(drAndDealersEs, roles);
                    case "dvme_fr_admindealer" :
                        return !hasAdminDealer(drAndDealersFr, roles);
                    default:
                      return false;
                  }
                }));
  };
  return React.createElement(React.Fragment, undefined, selectedRole === /* NoRole */9 ? React.createElement(App_TopMenu$DvmAdminFrontendFr.make, {}) : null, React.createElement(Core.Grid, {
                  alignItems: "center",
                  children: null,
                  container: true,
                  direction: "column",
                  spacing: 2
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Roles$DvmAdminFrontendFr.selectRole,
                            value: newRole,
                            options: roleOptions(undefined),
                            optionsDisabled: getDisabledOptions(undefined),
                            onChange: (function (v) {
                                Curry._1(setNewRole, (function (param) {
                                        return v;
                                      }));
                                if (v === "dvme_fr_admindealer" || v === "dvme_es_admindealer") {
                                  Curry._1(dispatch, {
                                        TAG: /* StoreOrdersFilter */3,
                                        _0: undefined
                                      });
                                  Curry._1(dispatch, {
                                        TAG: /* StoreOrdersModalFilter */4,
                                        _0: undefined
                                      });
                                  Curry._1(dispatch, {
                                        TAG: /* StoreOrdersModalAdditionalFilter */5,
                                        _0: undefined
                                      });
                                  Curry._1(dispatch, {
                                        TAG: /* StoreOrdersModalVOFilter */6,
                                        _0: undefined
                                      });
                                  Curry._1(dispatch, {
                                        TAG: /* StoreBonusesPERFFilter */20,
                                        _0: undefined
                                      });
                                  Curry._1(dispatch, {
                                        TAG: /* StoreBonusesFilter */15,
                                        _0: undefined
                                      });
                                  Curry._1(dispatch, {
                                        TAG: /* StoreBonusesModalAdditionalFilter */17,
                                        _0: undefined
                                      });
                                  Curry._1(dispatch, {
                                        TAG: /* StoreBonusesModalVOFilter */19,
                                        _0: undefined
                                      });
                                  Curry._1(dispatch, {
                                        TAG: /* StoreBonusesModalFilter */16,
                                        _0: undefined
                                      });
                                  return Curry._1(dispatch, {
                                              TAG: /* StoreQuotaViewFilter */23,
                                              _0: undefined
                                            });
                                }
                                
                              }),
                            classNameTitle: labelStyle,
                            row: false
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                            onClick: (function (param) {
                                selectRole(Utils_Authentication$DvmAdminFrontendFr.roleFromString(newRole));
                              }),
                            label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                            size: "medium"
                          }),
                      item: true
                    })));
}

var make = Auth_Select_Role_Page;

export {
  labelStyle ,
  hasAdminDealer ,
  make ,
}
/* labelStyle Not a pure module */
