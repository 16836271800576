// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as EnvVar$DvmAdminFrontendFr from "../../../../bindings/global/EnvVar.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../../../route/Route_Paths.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../../../context/router/Context_Router.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../../context/user/User_Types_Utils.bs.js";
import * as Bonuses_Search_Form$DvmAdminFrontendFr from "../../../bonusesList/Bonuses_Search_Form.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Order_Detail_Budget_Box$DvmAdminFrontendFr from "./Order_Detail_Budget_Box.bs.js";
import * as Order_Detail_Bonus_Box_Fleet$DvmAdminFrontendFr from "./Order_Detail_Bonus_Box_Fleet.bs.js";
import * as Order_Detail_Bonus_Box_NonFleet$DvmAdminFrontendFr from "./Order_Detail_Bonus_Box_NonFleet.bs.js";
import * as Order_Detail_Bonus_Derogate_Fleet$DvmAdminFrontendFr from "./derogate/view/Order_Detail_Bonus_Derogate_Fleet.bs.js";
import * as Order_Detail_Bonus_Derogate_Create$DvmAdminFrontendFr from "./derogate/create/Order_Detail_Bonus_Derogate_Create.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Api$DvmAdminFrontendFr from "./dealerRequest/Order_Detail_Bonus_DealerRequest_Api.bs.js";
import * as Order_Detail_Bonus_Derogate_NonFleet$DvmAdminFrontendFr from "./derogate/view/Order_Detail_Bonus_Derogate_NonFleet.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Create$DvmAdminFrontendFr from "./dealerRequest/create/Order_Detail_Bonus_DealerRequest_Create.bs.js";
import * as Order_Detail_Bonus_Derogate_Create_Types$DvmAdminFrontendFr from "./derogate/create/Order_Detail_Bonus_Derogate_Create_Types.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Documents_Api$DvmAdminFrontendFr from "./dealerRequest/viewDocuments/Order_Detail_Bonus_DealerRequest_Documents_Api.bs.js";

function Order_Detail_Bonuses(Props) {
  var order = Props.order;
  var orderId = Props.orderId;
  var reloadPage = Props.reloadPage;
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDocumentsResult = match$2[1];
  var documentsResult = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDealerRequests = match$3[1];
  var dealerRequests = match$3[0];
  var match$4 = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match$4[1];
  var intl = ReactIntl.useIntl();
  var match$5 = React.useState(function () {
        return null;
      });
  var setModal = match$5[1];
  React.useEffect((function () {
          if (!EnvVar$DvmAdminFrontendFr.currentEnvironmentProd) {
            Order_Detail_Bonus_DealerRequest_Documents_Api$DvmAdminFrontendFr.fetchDocuments(userManager, setDocumentsResult, selectedRole, orderId);
            Order_Detail_Bonus_DealerRequest_Api$DvmAdminFrontendFr.fetchDealerRequests(userManager, setDealerRequests, selectedRole, orderId);
          }
          
        }), []);
  var openModalDerogate = function (action, editedDero, usedAction, param) {
    Belt_Option.mapWithDefault(order.orderType, undefined, (function (orderType) {
            Curry._1(setModal, (function (param) {
                    return React.createElement(Order_Detail_Bonus_Derogate_Create$DvmAdminFrontendFr.make, {
                                order: order,
                                orderId: orderId,
                                action: action,
                                onClose: (function (param) {
                                    Curry._1(setModal, (function (param) {
                                            return null;
                                          }));
                                  }),
                                onOk: (function (param) {
                                    Curry._1(setModal, (function (param) {
                                            return null;
                                          }));
                                    Curry._1(reloadPage, undefined);
                                  }),
                                orderType: orderType,
                                editedDero: editedDero,
                                usedAction: usedAction
                              });
                  }));
          }));
  };
  return React.createElement(Core.Box, {
              children: null,
              marginTop: 3
            }, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column"
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                            color: "primary",
                            style: {
                              fontWeight: "600"
                            },
                            children: "" + intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.orderBonuses) + " " + Belt_Option.getWithDefault(order.orderNumberCustomer, "-") + "",
                            variant: "h3"
                          }),
                      item: true,
                      key: "0"
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                            color: "textSecondary",
                            children: React.createElement(Core.Link, {
                                  children: intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.orderBonusesSubtitle),
                                  style: {
                                    cursor: "pointer"
                                  },
                                  onClick: (function ($$event) {
                                      Curry._1(dispatch, {
                                            TAG: /* StoreBonusesFilter */15,
                                            _0: {
                                              dr: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.dr,
                                              dealerId: {
                                                hd: Belt_Option.getWithDefault(order.dealerID, ""),
                                                tl: /* [] */0
                                              },
                                              orderId: Belt_Option.getWithDefault(order.orderNumberCustomer, ""),
                                              budgetType: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.budgetType,
                                              actionType: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.actionType,
                                              actionId: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.actionId,
                                              status: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.status,
                                              model: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.model,
                                              version: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.version,
                                              budgetYear: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.budgetYear,
                                              showBonusesPlusHistory: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.showBonusesPlusHistory,
                                              maxAmount: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.maxAmount,
                                              isOverMaximum: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.isOverMaximum,
                                              orderType: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.orderType,
                                              siretPERF: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.siretPERF,
                                              showDealerHistory: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.showDealerHistory,
                                              clientTypes: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.clientTypes,
                                              saleTypes: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.saleTypes
                                            }
                                          });
                                      Curry._1(dispatch, {
                                            TAG: /* StoreBonusesModalFilter */16,
                                            _0: undefined
                                          });
                                      Curry._1(dispatch, {
                                            TAG: /* StoreBonusesModalAdditionalFilter */17,
                                            _0: undefined
                                          });
                                      Curry._1(dispatch, {
                                            TAG: /* StoreBonusesModalAdditionalSecondFilter */18,
                                            _0: undefined
                                          });
                                      Curry._1(dispatch, {
                                            TAG: /* StoreBonusesModalVOFilter */19,
                                            _0: undefined
                                          });
                                      RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rView + Route_Paths$DvmAdminFrontendFr.rBonuses);
                                    })
                                }),
                            variant: "subtitle"
                          }),
                      item: true,
                      key: "1"
                    }), User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) && !Belt_Option.getWithDefault(order.archived, true) ? React.createElement(Core.Grid, {
                        children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                              color: "textSecondary",
                              children: React.createElement(Core.Link, {
                                    children: intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.addUnexistActionButton),
                                    style: {
                                      cursor: "pointer"
                                    },
                                    onClick: (function ($$event) {
                                        openModalDerogate(undefined, undefined, undefined, undefined);
                                      })
                                  }),
                              variant: "subtitle"
                            }),
                        item: true,
                        key: "2"
                      }) : null, !Belt_Option.getWithDefault(order.archived, true) && (Belt_Option.getWithDefault(order.orderType, /* NOTSET */7) === /* SO */0 || Belt_Option.getWithDefault(order.orderType, /* NOTSET */7) === /* CL */1) && !EnvVar$DvmAdminFrontendFr.currentEnvironmentProd ? React.createElement(Core.Grid, {
                        children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                              color: "textSecondary",
                              children: React.createElement(Core.Link, {
                                    children: intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.addDealerRequest),
                                    style: {
                                      cursor: "pointer"
                                    },
                                    onClick: (function ($$event) {
                                        var action;
                                        Belt_Option.mapWithDefault(order.orderType, undefined, (function (orderType) {
                                                Curry._1(setModal, (function (param) {
                                                        return React.createElement(Order_Detail_Bonus_DealerRequest_Create$DvmAdminFrontendFr.make, {
                                                                    order: order,
                                                                    orderId: orderId,
                                                                    action: action,
                                                                    onClose: (function (param) {
                                                                        Curry._1(setModal, (function (param) {
                                                                                return null;
                                                                              }));
                                                                      }),
                                                                    onOk: (function (param) {
                                                                        Curry._1(setModal, (function (param) {
                                                                                return null;
                                                                              }));
                                                                        Curry._1(reloadPage, undefined);
                                                                      }),
                                                                    orderType: orderType,
                                                                    savedDocuments: /* [] */0,
                                                                    reloadPage: reloadPage
                                                                  });
                                                      }));
                                              }));
                                      })
                                  }),
                              variant: "subtitle"
                            }),
                        item: true,
                        key: "2"
                      }) : null, Belt_Option.mapWithDefault(order.orderType, null, (function (orderType) {
                        if (orderType !== 0) {
                          return null;
                        } else {
                          return React.createElement(React.Fragment, undefined, React.createElement(Order_Detail_Budget_Box$DvmAdminFrontendFr.make, {
                                          color: "blue",
                                          openModal: (function ($$event) {
                                              openModalDerogate({
                                                    actionType: "budget",
                                                    actionId: "",
                                                    actionIdLabel: "",
                                                    actionBudget: order.detail.budget,
                                                    bonus: Belt_Option.flatMap(order.budget, (function (budget) {
                                                            return budget.bonus;
                                                          })),
                                                    amountPercent: undefined,
                                                    amountEuro: undefined,
                                                    bonusValue: undefined,
                                                    calcMethod: undefined
                                                  }, undefined, undefined, undefined);
                                            }),
                                          order: order,
                                          orderId: orderId,
                                          reloadPage: reloadPage,
                                          key: orderId
                                        }), Belt_Array.map(Belt_Option.getWithDefault(Belt_Option.flatMap(order.budget, (function (budget) {
                                                    return budget.deros;
                                                  })), []), (function (dero) {
                                            if (orderType !== 0) {
                                              return React.createElement(Order_Detail_Bonus_Derogate_NonFleet$DvmAdminFrontendFr.make, {
                                                          dero: dero,
                                                          color: "blue",
                                                          order: order,
                                                          orderId: orderId,
                                                          reloadPage: reloadPage,
                                                          openEditModal: (function ($$event) {
                                                              openModalDerogate(undefined, dero, "nonfleet", undefined);
                                                            }),
                                                          key: dero.derogateId
                                                        });
                                            } else {
                                              return React.createElement(Order_Detail_Bonus_Derogate_Fleet$DvmAdminFrontendFr.make, {
                                                          dero: dero,
                                                          color: "blue",
                                                          order: order,
                                                          orderId: orderId,
                                                          reloadPage: reloadPage,
                                                          openEditModal: (function ($$event) {
                                                              openModalDerogate(undefined, dero, "budget", undefined);
                                                            }),
                                                          key: dero.derogateId
                                                        });
                                            }
                                          })));
                        }
                      })), Belt_Array.map(Belt_Option.getWithDefault(order.stoc, []), (function (stoc) {
                        return React.createElement(React.Fragment, undefined, React.createElement(Order_Detail_Bonus_Box_Fleet$DvmAdminFrontendFr.make, {
                                        bonus: stoc,
                                        color: "#622717",
                                        openModal: (function ($$event) {
                                            openModalDerogate({
                                                  actionType: "stoc",
                                                  actionId: Belt_Option.getWithDefault(stoc.stringActionID, ""),
                                                  actionIdLabel: stoc.actionLabel,
                                                  actionBudget: stoc.budgetType,
                                                  bonus: stoc.bonus,
                                                  amountPercent: stoc.amountPercent,
                                                  amountEuro: stoc.amountEuro,
                                                  bonusValue: undefined,
                                                  calcMethod: undefined
                                                }, undefined, undefined, undefined);
                                          }),
                                        archived: Belt_Option.getWithDefault(order.archived, true),
                                        bonusType: "STOC",
                                        order: order,
                                        documentsResult: documentsResult,
                                        dealerRequests: dealerRequests,
                                        orderId: orderId,
                                        reloadPage: reloadPage,
                                        key: Belt_Option.getWithDefault(stoc.stringActionID, "")
                                      }), Belt_Array.map(Belt_Option.getWithDefault(stoc.deros, []), (function (dero) {
                                          var match = Belt_Option.getWithDefault(order.orderType, /* NOTSET */7);
                                          if (match !== 0) {
                                            return React.createElement(Order_Detail_Bonus_Derogate_NonFleet$DvmAdminFrontendFr.make, {
                                                        dero: dero,
                                                        color: "#622717",
                                                        order: order,
                                                        orderId: orderId,
                                                        reloadPage: reloadPage,
                                                        openEditModal: (function ($$event) {
                                                            openModalDerogate(undefined, dero, "nonfleet", undefined);
                                                          }),
                                                        key: dero.derogateId
                                                      });
                                          } else {
                                            return React.createElement(Order_Detail_Bonus_Derogate_Fleet$DvmAdminFrontendFr.make, {
                                                        dero: dero,
                                                        color: "#622717",
                                                        order: order,
                                                        orderId: orderId,
                                                        reloadPage: reloadPage,
                                                        openEditModal: (function ($$event) {
                                                            openModalDerogate(undefined, dero, "stoc", undefined);
                                                          }),
                                                        key: dero.derogateId
                                                      });
                                          }
                                        })));
                      })), Belt_Array.map(Belt_Option.getWithDefault(order.perf, []), (function (perf) {
                        return React.createElement(React.Fragment, undefined, React.createElement(Order_Detail_Bonus_Box_Fleet$DvmAdminFrontendFr.make, {
                                        bonus: perf,
                                        color: "#ffbc69",
                                        openModal: (function ($$event) {
                                            openModalDerogate({
                                                  actionType: "perf",
                                                  actionId: Belt_Option.getWithDefault(perf.stringActionID, ""),
                                                  actionIdLabel: perf.actionLabel,
                                                  actionBudget: perf.budgetType,
                                                  bonus: perf.bonus,
                                                  amountPercent: perf.amountPercent,
                                                  amountEuro: perf.amountEuro,
                                                  bonusValue: undefined,
                                                  calcMethod: undefined
                                                }, undefined, undefined, undefined);
                                          }),
                                        archived: Belt_Option.getWithDefault(order.archived, true),
                                        bonusType: "PERF",
                                        order: order,
                                        documentsResult: documentsResult,
                                        dealerRequests: dealerRequests,
                                        orderId: orderId,
                                        reloadPage: reloadPage,
                                        key: Belt_Option.getWithDefault(perf.stringActionID, "")
                                      }), Belt_Array.map(Belt_Option.getWithDefault(perf.deros, []), (function (dero) {
                                          var match = Belt_Option.getWithDefault(order.orderType, /* NOTSET */7);
                                          if (match !== 0) {
                                            return React.createElement(Order_Detail_Bonus_Derogate_NonFleet$DvmAdminFrontendFr.make, {
                                                        dero: dero,
                                                        color: "#ffbc69",
                                                        order: order,
                                                        orderId: orderId,
                                                        reloadPage: reloadPage,
                                                        openEditModal: (function ($$event) {
                                                            openModalDerogate(undefined, dero, "nonfleet", undefined);
                                                          }),
                                                        key: dero.derogateId
                                                      });
                                          } else {
                                            return React.createElement(Order_Detail_Bonus_Derogate_Fleet$DvmAdminFrontendFr.make, {
                                                        dero: dero,
                                                        color: "#ffbc69",
                                                        order: order,
                                                        orderId: orderId,
                                                        reloadPage: reloadPage,
                                                        openEditModal: (function ($$event) {
                                                            openModalDerogate(undefined, dero, "perf", undefined);
                                                          }),
                                                        key: dero.derogateId
                                                      });
                                          }
                                        })));
                      })), Belt_Array.map(Belt_Option.getWithDefault(order.repr, []), (function (repr) {
                        return React.createElement(React.Fragment, undefined, React.createElement(Order_Detail_Bonus_Box_Fleet$DvmAdminFrontendFr.make, {
                                        bonus: repr,
                                        color: "green",
                                        openModal: (function ($$event) {
                                            openModalDerogate({
                                                  actionType: "repr",
                                                  actionId: Belt_Option.getWithDefault(repr.stringActionID, ""),
                                                  actionIdLabel: repr.actionLabel,
                                                  actionBudget: repr.budgetType,
                                                  bonus: repr.bonus,
                                                  amountPercent: repr.amountPercent,
                                                  amountEuro: repr.amountEuro,
                                                  bonusValue: undefined,
                                                  calcMethod: undefined
                                                }, undefined, undefined, undefined);
                                          }),
                                        archived: Belt_Option.getWithDefault(order.archived, true),
                                        bonusType: "REPR",
                                        order: order,
                                        documentsResult: documentsResult,
                                        dealerRequests: dealerRequests,
                                        orderId: orderId,
                                        reloadPage: reloadPage,
                                        key: Belt_Option.getWithDefault(repr.stringActionID, "")
                                      }), Belt_Array.map(Belt_Option.getWithDefault(repr.deros, []), (function (dero) {
                                          var match = Belt_Option.getWithDefault(order.orderType, /* NOTSET */7);
                                          if (match !== 0) {
                                            return React.createElement(Order_Detail_Bonus_Derogate_NonFleet$DvmAdminFrontendFr.make, {
                                                        dero: dero,
                                                        color: "green",
                                                        order: order,
                                                        orderId: orderId,
                                                        reloadPage: reloadPage,
                                                        openEditModal: (function ($$event) {
                                                            openModalDerogate(undefined, dero, "nonfleet", undefined);
                                                          }),
                                                        key: dero.derogateId
                                                      });
                                          } else {
                                            return React.createElement(Order_Detail_Bonus_Derogate_Fleet$DvmAdminFrontendFr.make, {
                                                        dero: dero,
                                                        color: "green",
                                                        order: order,
                                                        orderId: orderId,
                                                        reloadPage: reloadPage,
                                                        openEditModal: (function ($$event) {
                                                            openModalDerogate(undefined, dero, "repr", undefined);
                                                          }),
                                                        key: dero.derogateId
                                                      });
                                          }
                                        })));
                      })), Belt_Array.map(Belt_Option.getWithDefault(order.dero, []), (function (dero) {
                        var match = Belt_Option.getWithDefault(order.orderType, /* NOTSET */7);
                        if (match !== 0) {
                          return React.createElement(Order_Detail_Bonus_Derogate_NonFleet$DvmAdminFrontendFr.make, {
                                      dero: dero,
                                      color: "black",
                                      order: order,
                                      orderId: orderId,
                                      reloadPage: reloadPage,
                                      openEditModal: (function ($$event) {
                                          openModalDerogate(undefined, dero, "nonfleet", undefined);
                                        }),
                                      key: dero.derogateId
                                    });
                        } else {
                          return React.createElement(Order_Detail_Bonus_Derogate_Fleet$DvmAdminFrontendFr.make, {
                                      dero: dero,
                                      color: "black",
                                      order: order,
                                      orderId: orderId,
                                      reloadPage: reloadPage,
                                      openEditModal: (function ($$event) {
                                          openModalDerogate(undefined, dero, Order_Detail_Bonus_Derogate_Create_Types$DvmAdminFrontendFr.usedActionFromStr(dero.actionType), undefined);
                                        }),
                                      key: dero.derogateId
                                    });
                        }
                      })), Belt_Option.mapWithDefault(order.orderType, null, (function (orderType) {
                        if (orderType !== 0) {
                          return Belt_Array.map(Belt_Option.getWithDefault(order.actions, []), (function (action) {
                                        return React.createElement(React.Fragment, undefined, React.createElement(Order_Detail_Bonus_Box_NonFleet$DvmAdminFrontendFr.make, {
                                                        bonus: action,
                                                        color: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
                                                        openModal: (function ($$event) {
                                                            openModalDerogate({
                                                                  actionType: "nonfleet",
                                                                  actionId: Belt_Option.getWithDefault(action.stringActionID, ""),
                                                                  actionIdLabel: action.actionLabel,
                                                                  actionBudget: action.budgetType,
                                                                  bonus: action.bonusPaid,
                                                                  amountPercent: action.amountPercent,
                                                                  amountEuro: action.amountEuro,
                                                                  bonusValue: undefined,
                                                                  calcMethod: undefined
                                                                }, undefined, undefined, undefined);
                                                          }),
                                                        archived: Belt_Option.getWithDefault(order.archived, true),
                                                        bonusType: Belt_Option.getWithDefault(action.actionType, ""),
                                                        order: order,
                                                        documentsResult: documentsResult,
                                                        dealerRequests: dealerRequests,
                                                        orderId: orderId,
                                                        reloadPage: reloadPage,
                                                        key: Belt_Option.getWithDefault(action.stringActionID, "")
                                                      }), Belt_Array.map(Belt_Option.getWithDefault(action.deros, []), (function (dero) {
                                                          return React.createElement(Order_Detail_Bonus_Derogate_NonFleet$DvmAdminFrontendFr.make, {
                                                                      dero: dero,
                                                                      color: "FF5544",
                                                                      order: order,
                                                                      orderId: orderId,
                                                                      reloadPage: reloadPage,
                                                                      openEditModal: (function ($$event) {
                                                                          openModalDerogate(undefined, dero, "nonfleet", undefined);
                                                                        }),
                                                                      key: dero.derogateId
                                                                    });
                                                        })));
                                      }));
                        } else {
                          return null;
                        }
                      }))), match$5[0]);
}

var make = Order_Detail_Bonuses;

export {
  make ,
}
/* react Not a pure module */
