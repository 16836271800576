// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../api/RestApi.bs.js";
import * as Order_Detail_Dealer_Edits_Types$DvmAdminFrontendFr from "./Order_Detail_Dealer_Edits_Types.bs.js";

function postDealerEditCorrection(userManager, setResult, orderId, submissionCallbacks, selectedRole, dealerEditsCorrection) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
          TAG: /* Json */0,
          _0: Order_Detail_Dealer_Edits_Types$DvmAdminFrontendFr.dealerEditsCorrection_encode(dealerEditsCorrection)
        }, userManager, selectedRole, "/orders/correction/dealer/" + orderId + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToUpdateOldVehicle */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function postParcEndDateCorrection(userManager, setResult, orderId, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", undefined, userManager, selectedRole, "/orders/correction/dealer/parc/" + orderId + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var CustomerDiscountForm;

var Types;

export {
  CustomerDiscountForm ,
  Types ,
  postDealerEditCorrection ,
  postParcEndDateCorrection ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
