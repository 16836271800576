// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as ReadExcelFile from "read-excel-file";
import ReadExcelFile$1 from "read-excel-file";
import * as Core from "@material-ui/core";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../../theme/Theme_Colors.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../../common/styles/App_Styles_Common.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Flash_Edit_NonFleet_New_Vehicle_Version_Excel_Modal$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_New_Vehicle_Version_Excel_Modal.bs.js";

var button = Css.css(Object.assign({}, {
          backgroundColor: Theme_Colors$DvmAdminFrontendFr.black,
          border: "none",
          color: Theme_Colors$DvmAdminFrontendFr.white,
          cursor: "pointer",
          display: "flex",
          textTransform: "uppercase",
          width: "250px",
          alignItems: "center",
          justifyContent: "center"
        }, {
          "&:hover": {
            backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayMain
          }
        }));

var medium = Css.css({
      fontSize: "14px",
      fontWeight: "600",
      minHeight: "45px",
      minWidth: "128px",
      padding: "7px 14px"
    });

var secondary = Css.css({
      backgroundColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown
    });

function Flash_Edit_NonFleet_New_Vehicle_Version_Excel(Props) {
  var availableVersionsTyped = Props.availableVersionsTyped;
  var form = Props.form;
  var newVehicleExpanded = Props.newVehicleExpanded;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return null;
      });
  var setModal = match[1];
  var readExcel = async function (file) {
    var fileSheets = await ReadExcelFile.readSheetNames(file);
    var allSheets = Belt_Array.map(fileSheets, (function (x) {
            return ReadExcelFile$1(file, {
                        sheet: x
                      });
          }));
    var excelData = await Promise.all(allSheets);
    if (excelData.length !== 0) {
      return Curry._1(setModal, (function (param) {
                    return React.createElement(Flash_Edit_NonFleet_New_Vehicle_Version_Excel_Modal$DvmAdminFrontendFr.make, {
                                onClose: (function (param) {
                                    Curry._1(setModal, (function (param) {
                                            return null;
                                          }));
                                  }),
                                excelData: Belt_MapString.fromArray(Belt_Array.zip(fileSheets, excelData)),
                                availableVersionsTyped: availableVersionsTyped,
                                form: form
                              });
                  }));
    }
    
  };
  return React.createElement(React.Fragment, undefined, React.createElement("input", {
                  id: "contained-button-file",
                  style: {
                    display: "none"
                  },
                  accept: ".xlsx",
                  disabled: Belt_List.length(form.input.models) === 0,
                  type: "file",
                  value: "",
                  onChange: (function ($$event) {
                      var file = Belt_Array.get($$event.currentTarget.files, 0);
                      if (file !== undefined) {
                        readExcel(Caml_option.valFromOption(file));
                        return ;
                      }
                      
                    })
                }), React.createElement("label", {
                  htmlFor: "contained-button-file"
                }, React.createElement(Core.Tooltip, {
                      arrow: true,
                      children: React.createElement(Core.Button, {
                            children: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.uploadVersions),
                            className: Css.cx([
                                  button,
                                  medium,
                                  secondary
                                ]),
                            color: "primary",
                            component: "span",
                            disabled: Belt_List.length(form.input.models) === 0,
                            size: "small",
                            variant: "contained"
                          }),
                      classes: {
                        tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
                      },
                      open: Belt_List.length(form.input.models) === 0 && newVehicleExpanded,
                      placement: "right",
                      title: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.selectModelFirst)
                    })), match[0]);
}

var Api;

var make = Flash_Edit_NonFleet_New_Vehicle_Version_Excel;

export {
  Api ,
  button ,
  medium ,
  secondary ,
  make ,
}
/* button Not a pure module */
