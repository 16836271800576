// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../../route/Route_Paths.bs.js";

function routeToPage(managerID, param) {
  RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rContractManagerEdit + Belt_Option.mapWithDefault(managerID, "", (function (managerID) {
              return "/" + managerID;
            })));
}

export {
  routeToPage ,
}
/* RescriptReactRouter Not a pure module */
