// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../common/utils/Utils_Form.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Error_Inputs.bs.js";

var validators_planPerfID3 = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var planPerfID3 = param.planPerfID3;
      if (planPerfID3 === "") {
        return {
                TAG: /* Ok */0,
                _0: ""
              };
      } else if (planPerfID3 === param.planPerfID2 || planPerfID3 === param.planPerfID) {
        return {
                TAG: /* Error */1,
                _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: planPerfID3
              };
      }
    })
};

var validators_planPerfID2 = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var planPerfID2 = param.planPerfID2;
      if (planPerfID2 === "") {
        return {
                TAG: /* Ok */0,
                _0: ""
              };
      } else if (param.planPerfID === planPerfID2) {
        return {
                TAG: /* Error */1,
                _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: planPerfID2
              };
      }
    })
};

var validators_planPerfID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.planPerfID
            };
    })
};

var validators_planPerfAmount3 = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateFloatPositive(param.planPerfAmount3);
    })
};

var validators_planPerfAmount2 = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateFloatPositive(param.planPerfAmount2);
    })
};

var validators_planPerfAmount = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateFloatPositive(param.planPerfAmount);
    })
};

var validators_bonusActionTypePERF3 = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.bonusActionTypePERF3
            };
    })
};

var validators_bonusActionTypePERF2 = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.bonusActionTypePERF2
            };
    })
};

var validators_bonusActionTypePERF = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.bonusActionTypePERF
            };
    })
};

var validators = {
  planPerfID3: validators_planPerfID3,
  planPerfID2: validators_planPerfID2,
  planPerfID: validators_planPerfID,
  planPerfAmount3: validators_planPerfAmount3,
  planPerfAmount2: validators_planPerfAmount2,
  planPerfAmount: validators_planPerfAmount,
  bonusActionTypePERF3: validators_bonusActionTypePERF3,
  bonusActionTypePERF2: validators_bonusActionTypePERF2,
  bonusActionTypePERF: validators_bonusActionTypePERF
};

function initialFieldsStatuses(_input) {
  return {
          planPerfID3: /* Pristine */0,
          planPerfID2: /* Pristine */0,
          planPerfID: /* Pristine */0,
          planPerfAmount3: /* Pristine */0,
          planPerfAmount2: /* Pristine */0,
          planPerfAmount: /* Pristine */0,
          bonusActionTypePERF3: /* Pristine */0,
          bonusActionTypePERF2: /* Pristine */0,
          bonusActionTypePERF: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.planPerfID3;
  var match_0 = match ? match._0 : Curry._1(validators.planPerfID3.validate, input);
  var match$1 = fieldsStatuses.planPerfID2;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.planPerfID2.validate, input);
  var match$2 = fieldsStatuses.planPerfID;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.planPerfID.validate, input);
  var match$3 = fieldsStatuses.planPerfAmount3;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.planPerfAmount3.validate, input);
  var match$4 = fieldsStatuses.planPerfAmount2;
  var match_0$4 = match$4 ? match$4._0 : Curry._1(validators.planPerfAmount2.validate, input);
  var match$5 = fieldsStatuses.planPerfAmount;
  var match_0$5 = match$5 ? match$5._0 : Curry._1(validators.planPerfAmount.validate, input);
  var match$6 = fieldsStatuses.bonusActionTypePERF3;
  var match_0$6 = match$6 ? match$6._0 : Curry._1(validators.bonusActionTypePERF3.validate, input);
  var match$7 = fieldsStatuses.bonusActionTypePERF2;
  var match_0$7 = match$7 ? match$7._0 : Curry._1(validators.bonusActionTypePERF2.validate, input);
  var match$8 = fieldsStatuses.bonusActionTypePERF;
  var match_0$8 = match$8 ? match$8._0 : Curry._1(validators.bonusActionTypePERF.validate, input);
  var planPerfID3Result = match_0;
  var planPerfID3Result$1;
  if (planPerfID3Result.TAG === /* Ok */0) {
    var planPerfID2Result = match_0$1;
    if (planPerfID2Result.TAG === /* Ok */0) {
      var planPerfIDResult = match_0$2;
      if (planPerfIDResult.TAG === /* Ok */0) {
        var planPerfAmount3Result = match_0$3;
        if (planPerfAmount3Result.TAG === /* Ok */0) {
          var planPerfAmount2Result = match_0$4;
          if (planPerfAmount2Result.TAG === /* Ok */0) {
            var planPerfAmountResult = match_0$5;
            if (planPerfAmountResult.TAG === /* Ok */0) {
              var bonusActionTypePERF3Result = match_0$6;
              if (bonusActionTypePERF3Result.TAG === /* Ok */0) {
                var bonusActionTypePERF2Result = match_0$7;
                if (bonusActionTypePERF2Result.TAG === /* Ok */0) {
                  var bonusActionTypePERFResult = match_0$8;
                  if (bonusActionTypePERFResult.TAG === /* Ok */0) {
                    return {
                            TAG: /* Valid */0,
                            output: {
                              bonusActionTypePERF: bonusActionTypePERFResult._0,
                              bonusActionTypePERF2: bonusActionTypePERF2Result._0,
                              bonusActionTypePERF3: bonusActionTypePERF3Result._0,
                              planPerfAmount: planPerfAmountResult._0,
                              planPerfAmount2: planPerfAmount2Result._0,
                              planPerfAmount3: planPerfAmount3Result._0,
                              planPerfID: planPerfIDResult._0,
                              planPerfID2: planPerfID2Result._0,
                              planPerfID3: planPerfID3Result._0
                            },
                            fieldsStatuses: {
                              planPerfID3: /* Dirty */{
                                _0: planPerfID3Result,
                                _1: /* Shown */0
                              },
                              planPerfID2: /* Dirty */{
                                _0: planPerfID2Result,
                                _1: /* Shown */0
                              },
                              planPerfID: /* Dirty */{
                                _0: planPerfIDResult,
                                _1: /* Shown */0
                              },
                              planPerfAmount3: /* Dirty */{
                                _0: planPerfAmount3Result,
                                _1: /* Shown */0
                              },
                              planPerfAmount2: /* Dirty */{
                                _0: planPerfAmount2Result,
                                _1: /* Shown */0
                              },
                              planPerfAmount: /* Dirty */{
                                _0: planPerfAmountResult,
                                _1: /* Shown */0
                              },
                              bonusActionTypePERF3: /* Dirty */{
                                _0: bonusActionTypePERF3Result,
                                _1: /* Shown */0
                              },
                              bonusActionTypePERF2: /* Dirty */{
                                _0: bonusActionTypePERF2Result,
                                _1: /* Shown */0
                              },
                              bonusActionTypePERF: /* Dirty */{
                                _0: bonusActionTypePERFResult,
                                _1: /* Shown */0
                              }
                            },
                            collectionsStatuses: undefined
                          };
                  }
                  planPerfID3Result$1 = planPerfID3Result;
                } else {
                  planPerfID3Result$1 = planPerfID3Result;
                }
              } else {
                planPerfID3Result$1 = planPerfID3Result;
              }
            } else {
              planPerfID3Result$1 = planPerfID3Result;
            }
          } else {
            planPerfID3Result$1 = planPerfID3Result;
          }
        } else {
          planPerfID3Result$1 = planPerfID3Result;
        }
      } else {
        planPerfID3Result$1 = planPerfID3Result;
      }
    } else {
      planPerfID3Result$1 = planPerfID3Result;
    }
  } else {
    planPerfID3Result$1 = planPerfID3Result;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            planPerfID3: /* Dirty */{
              _0: planPerfID3Result$1,
              _1: /* Shown */0
            },
            planPerfID2: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            planPerfID: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            planPerfAmount3: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            planPerfAmount2: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            planPerfAmount: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            bonusActionTypePERF3: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            bonusActionTypePERF2: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            },
            bonusActionTypePERF: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurPlanPerfID3Field */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.planPerfID3, validators_planPerfID3, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  planPerfID3: status,
                                  planPerfID2: init.planPerfID2,
                                  planPerfID: init.planPerfID,
                                  planPerfAmount3: init.planPerfAmount3,
                                  planPerfAmount2: init.planPerfAmount2,
                                  planPerfAmount: init.planPerfAmount,
                                  bonusActionTypePERF3: init.bonusActionTypePERF3,
                                  bonusActionTypePERF2: init.bonusActionTypePERF2,
                                  bonusActionTypePERF: init.bonusActionTypePERF
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPlanPerfID2Field */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.planPerfID2, validators_planPerfID2, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  planPerfID3: init.planPerfID3,
                                  planPerfID2: status,
                                  planPerfID: init.planPerfID,
                                  planPerfAmount3: init.planPerfAmount3,
                                  planPerfAmount2: init.planPerfAmount2,
                                  planPerfAmount: init.planPerfAmount,
                                  bonusActionTypePERF3: init.bonusActionTypePERF3,
                                  bonusActionTypePERF2: init.bonusActionTypePERF2,
                                  bonusActionTypePERF: init.bonusActionTypePERF
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPlanPerfIDField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.planPerfID, validators_planPerfID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  planPerfID3: init.planPerfID3,
                                  planPerfID2: init.planPerfID2,
                                  planPerfID: status,
                                  planPerfAmount3: init.planPerfAmount3,
                                  planPerfAmount2: init.planPerfAmount2,
                                  planPerfAmount: init.planPerfAmount,
                                  bonusActionTypePERF3: init.bonusActionTypePERF3,
                                  bonusActionTypePERF2: init.bonusActionTypePERF2,
                                  bonusActionTypePERF: init.bonusActionTypePERF
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPlanPerfAmount3Field */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.planPerfAmount3, validators_planPerfAmount3, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  planPerfID3: init.planPerfID3,
                                  planPerfID2: init.planPerfID2,
                                  planPerfID: init.planPerfID,
                                  planPerfAmount3: status,
                                  planPerfAmount2: init.planPerfAmount2,
                                  planPerfAmount: init.planPerfAmount,
                                  bonusActionTypePERF3: init.bonusActionTypePERF3,
                                  bonusActionTypePERF2: init.bonusActionTypePERF2,
                                  bonusActionTypePERF: init.bonusActionTypePERF
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPlanPerfAmount2Field */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.planPerfAmount2, validators_planPerfAmount2, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  planPerfID3: init.planPerfID3,
                                  planPerfID2: init.planPerfID2,
                                  planPerfID: init.planPerfID,
                                  planPerfAmount3: init.planPerfAmount3,
                                  planPerfAmount2: status,
                                  planPerfAmount: init.planPerfAmount,
                                  bonusActionTypePERF3: init.bonusActionTypePERF3,
                                  bonusActionTypePERF2: init.bonusActionTypePERF2,
                                  bonusActionTypePERF: init.bonusActionTypePERF
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPlanPerfAmountField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.planPerfAmount, validators_planPerfAmount, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  planPerfID3: init.planPerfID3,
                                  planPerfID2: init.planPerfID2,
                                  planPerfID: init.planPerfID,
                                  planPerfAmount3: init.planPerfAmount3,
                                  planPerfAmount2: init.planPerfAmount2,
                                  planPerfAmount: status,
                                  bonusActionTypePERF3: init.bonusActionTypePERF3,
                                  bonusActionTypePERF2: init.bonusActionTypePERF2,
                                  bonusActionTypePERF: init.bonusActionTypePERF
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBonusActionTypePERF3Field */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.bonusActionTypePERF3, validators_bonusActionTypePERF3, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  planPerfID3: init.planPerfID3,
                                  planPerfID2: init.planPerfID2,
                                  planPerfID: init.planPerfID,
                                  planPerfAmount3: init.planPerfAmount3,
                                  planPerfAmount2: init.planPerfAmount2,
                                  planPerfAmount: init.planPerfAmount,
                                  bonusActionTypePERF3: status,
                                  bonusActionTypePERF2: init.bonusActionTypePERF2,
                                  bonusActionTypePERF: init.bonusActionTypePERF
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBonusActionTypePERF2Field */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.bonusActionTypePERF2, validators_bonusActionTypePERF2, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  planPerfID3: init.planPerfID3,
                                  planPerfID2: init.planPerfID2,
                                  planPerfID: init.planPerfID,
                                  planPerfAmount3: init.planPerfAmount3,
                                  planPerfAmount2: init.planPerfAmount2,
                                  planPerfAmount: init.planPerfAmount,
                                  bonusActionTypePERF3: init.bonusActionTypePERF3,
                                  bonusActionTypePERF2: status,
                                  bonusActionTypePERF: init.bonusActionTypePERF
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBonusActionTypePERFField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.bonusActionTypePERF, validators_bonusActionTypePERF, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  planPerfID3: init.planPerfID3,
                                  planPerfID2: init.planPerfID2,
                                  planPerfID: init.planPerfID,
                                  planPerfAmount3: init.planPerfAmount3,
                                  planPerfAmount2: init.planPerfAmount2,
                                  planPerfAmount: init.planPerfAmount,
                                  bonusActionTypePERF3: init.bonusActionTypePERF3,
                                  bonusActionTypePERF2: init.bonusActionTypePERF2,
                                  bonusActionTypePERF: status
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */9 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */9,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */10,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */12);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */11);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */10 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */11 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */12 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdatePlanPerfID3Field */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.planPerfID3, state.submissionStatus, validators_planPerfID3, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            planPerfID3: status,
                                            planPerfID2: init.planPerfID2,
                                            planPerfID: init.planPerfID,
                                            planPerfAmount3: init.planPerfAmount3,
                                            planPerfAmount2: init.planPerfAmount2,
                                            planPerfAmount: init.planPerfAmount,
                                            bonusActionTypePERF3: init.bonusActionTypePERF3,
                                            bonusActionTypePERF2: init.bonusActionTypePERF2,
                                            bonusActionTypePERF: init.bonusActionTypePERF
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePlanPerfID2Field */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.planPerfID2, state.submissionStatus, validators_planPerfID2, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            planPerfID3: init.planPerfID3,
                                            planPerfID2: status,
                                            planPerfID: init.planPerfID,
                                            planPerfAmount3: init.planPerfAmount3,
                                            planPerfAmount2: init.planPerfAmount2,
                                            planPerfAmount: init.planPerfAmount,
                                            bonusActionTypePERF3: init.bonusActionTypePERF3,
                                            bonusActionTypePERF2: init.bonusActionTypePERF2,
                                            bonusActionTypePERF: init.bonusActionTypePERF
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePlanPerfIDField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.planPerfID, state.submissionStatus, validators_planPerfID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            planPerfID3: init.planPerfID3,
                                            planPerfID2: init.planPerfID2,
                                            planPerfID: status,
                                            planPerfAmount3: init.planPerfAmount3,
                                            planPerfAmount2: init.planPerfAmount2,
                                            planPerfAmount: init.planPerfAmount,
                                            bonusActionTypePERF3: init.bonusActionTypePERF3,
                                            bonusActionTypePERF2: init.bonusActionTypePERF2,
                                            bonusActionTypePERF: init.bonusActionTypePERF
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePlanPerfAmount3Field */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.planPerfAmount3, state.submissionStatus, validators_planPerfAmount3, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            planPerfID3: init.planPerfID3,
                                            planPerfID2: init.planPerfID2,
                                            planPerfID: init.planPerfID,
                                            planPerfAmount3: status,
                                            planPerfAmount2: init.planPerfAmount2,
                                            planPerfAmount: init.planPerfAmount,
                                            bonusActionTypePERF3: init.bonusActionTypePERF3,
                                            bonusActionTypePERF2: init.bonusActionTypePERF2,
                                            bonusActionTypePERF: init.bonusActionTypePERF
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePlanPerfAmount2Field */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.planPerfAmount2, state.submissionStatus, validators_planPerfAmount2, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            planPerfID3: init.planPerfID3,
                                            planPerfID2: init.planPerfID2,
                                            planPerfID: init.planPerfID,
                                            planPerfAmount3: init.planPerfAmount3,
                                            planPerfAmount2: status,
                                            planPerfAmount: init.planPerfAmount,
                                            bonusActionTypePERF3: init.bonusActionTypePERF3,
                                            bonusActionTypePERF2: init.bonusActionTypePERF2,
                                            bonusActionTypePERF: init.bonusActionTypePERF
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePlanPerfAmountField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.planPerfAmount, state.submissionStatus, validators_planPerfAmount, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            planPerfID3: init.planPerfID3,
                                            planPerfID2: init.planPerfID2,
                                            planPerfID: init.planPerfID,
                                            planPerfAmount3: init.planPerfAmount3,
                                            planPerfAmount2: init.planPerfAmount2,
                                            planPerfAmount: status,
                                            bonusActionTypePERF3: init.bonusActionTypePERF3,
                                            bonusActionTypePERF2: init.bonusActionTypePERF2,
                                            bonusActionTypePERF: init.bonusActionTypePERF
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBonusActionTypePERF3Field */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.bonusActionTypePERF3, state.submissionStatus, validators_bonusActionTypePERF3, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            planPerfID3: init.planPerfID3,
                                            planPerfID2: init.planPerfID2,
                                            planPerfID: init.planPerfID,
                                            planPerfAmount3: init.planPerfAmount3,
                                            planPerfAmount2: init.planPerfAmount2,
                                            planPerfAmount: init.planPerfAmount,
                                            bonusActionTypePERF3: status,
                                            bonusActionTypePERF2: init.bonusActionTypePERF2,
                                            bonusActionTypePERF: init.bonusActionTypePERF
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBonusActionTypePERF2Field */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$7, state.fieldsStatuses.bonusActionTypePERF2, state.submissionStatus, validators_bonusActionTypePERF2, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            planPerfID3: init.planPerfID3,
                                            planPerfID2: init.planPerfID2,
                                            planPerfID: init.planPerfID,
                                            planPerfAmount3: init.planPerfAmount3,
                                            planPerfAmount2: init.planPerfAmount2,
                                            planPerfAmount: init.planPerfAmount,
                                            bonusActionTypePERF3: init.bonusActionTypePERF3,
                                            bonusActionTypePERF2: status,
                                            bonusActionTypePERF: init.bonusActionTypePERF
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBonusActionTypePERFField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$8, state.fieldsStatuses.bonusActionTypePERF, state.submissionStatus, validators_bonusActionTypePERF, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            planPerfID3: init.planPerfID3,
                                            planPerfID2: init.planPerfID2,
                                            planPerfID: init.planPerfID,
                                            planPerfAmount3: init.planPerfAmount3,
                                            planPerfAmount2: init.planPerfAmount2,
                                            planPerfAmount: init.planPerfAmount,
                                            bonusActionTypePERF3: init.bonusActionTypePERF3,
                                            bonusActionTypePERF2: init.bonusActionTypePERF2,
                                            bonusActionTypePERF: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */9 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */10 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */11 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updatePlanPerfID3: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePlanPerfID3Field */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updatePlanPerfID2: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePlanPerfID2Field */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updatePlanPerfID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePlanPerfIDField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updatePlanPerfAmount3: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePlanPerfAmount3Field */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updatePlanPerfAmount2: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePlanPerfAmount2Field */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updatePlanPerfAmount: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePlanPerfAmountField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBonusActionTypePERF3: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBonusActionTypePERF3Field */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBonusActionTypePERF2: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBonusActionTypePERF2Field */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBonusActionTypePERF: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBonusActionTypePERFField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurPlanPerfID3: (function (param) {
              Curry._1(dispatch, /* BlurPlanPerfID3Field */0);
            }),
          blurPlanPerfID2: (function (param) {
              Curry._1(dispatch, /* BlurPlanPerfID2Field */1);
            }),
          blurPlanPerfID: (function (param) {
              Curry._1(dispatch, /* BlurPlanPerfIDField */2);
            }),
          blurPlanPerfAmount3: (function (param) {
              Curry._1(dispatch, /* BlurPlanPerfAmount3Field */3);
            }),
          blurPlanPerfAmount2: (function (param) {
              Curry._1(dispatch, /* BlurPlanPerfAmount2Field */4);
            }),
          blurPlanPerfAmount: (function (param) {
              Curry._1(dispatch, /* BlurPlanPerfAmountField */5);
            }),
          blurBonusActionTypePERF3: (function (param) {
              Curry._1(dispatch, /* BlurBonusActionTypePERF3Field */6);
            }),
          blurBonusActionTypePERF2: (function (param) {
              Curry._1(dispatch, /* BlurBonusActionTypePERF2Field */7);
            }),
          blurBonusActionTypePERF: (function (param) {
              Curry._1(dispatch, /* BlurBonusActionTypePERFField */8);
            }),
          planPerfID3Result: Formality.exposeFieldResult(state.fieldsStatuses.planPerfID3),
          planPerfID2Result: Formality.exposeFieldResult(state.fieldsStatuses.planPerfID2),
          planPerfIDResult: Formality.exposeFieldResult(state.fieldsStatuses.planPerfID),
          planPerfAmount3Result: Formality.exposeFieldResult(state.fieldsStatuses.planPerfAmount3),
          planPerfAmount2Result: Formality.exposeFieldResult(state.fieldsStatuses.planPerfAmount2),
          planPerfAmountResult: Formality.exposeFieldResult(state.fieldsStatuses.planPerfAmount),
          bonusActionTypePERF3Result: Formality.exposeFieldResult(state.fieldsStatuses.bonusActionTypePERF3),
          bonusActionTypePERF2Result: Formality.exposeFieldResult(state.fieldsStatuses.bonusActionTypePERF2),
          bonusActionTypePERFResult: Formality.exposeFieldResult(state.fieldsStatuses.bonusActionTypePERF),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.planPerfID3 || match.planPerfID2 || match.planPerfID || match.planPerfAmount3 || match.planPerfAmount2 || match.planPerfAmount || match.bonusActionTypePERF3 || match.bonusActionTypePERF2 || match.bonusActionTypePERF) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */9);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */10);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */11);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */11,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */12);
            })
        };
}

var CorrectionForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var Types;

var initialState$1 = {
  bonusActionTypePERF: "",
  bonusActionTypePERF2: "",
  bonusActionTypePERF3: "",
  planPerfAmount: "",
  planPerfAmount2: "",
  planPerfAmount3: "",
  planPerfID: "",
  planPerfID2: "",
  planPerfID3: ""
};

export {
  Types ,
  CorrectionForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
