// Generated by ReScript, PLEASE EDIT WITH CARE


var selectRole = {
  id: "a0502347-9668-4ccf-b444-f9fabd234645",
  defaultMessage: "Choisissez un rôle"
};

var administratorFrance = {
  id: "66086f8a-eb4c-4872-978c-a76dcb30b8b7",
  defaultMessage: "Pilote (FR)"
};

var administratorFranceProd = {
  id: "bc0198eb-ec11-4b47-8542-8786b942596e",
  defaultMessage: "Pilote"
};

var administratorSpain = {
  id: "be3494a0-01a1-462a-b279-bfe112f266c7",
  defaultMessage: "Pilote (ES)"
};

var advisor = {
  id: "f52d5116-f621-42da-9c16-36b15c8f7a5b",
  defaultMessage: "Conseillers Tarification"
};

var preDirector = {
  id: "2b7f318f-a114-45b8-99dc-975ae242d3dc",
  defaultMessage: "Direction RPE (DVS, DVF, DVFRCI)"
};

var companyManager = {
  id: "d3e41a32-286f-43f6-92c0-978cfc441a86",
  defaultMessage: "ME"
};

var seniorCompanyManager = {
  id: "8bd9f65c-8f2f-4c64-844a-46c7388bfc47",
  defaultMessage: "SME + SM LCD + Assistant ME"
};

var acountManager = {
  id: "1653dc99-5e21-443f-857a-fe2e0df04951",
  defaultMessage: "MGC Assistante + Senior MGC +  MGC Junior"
};

var lldManager = {
  id: "14040f61-f1c2-45d9-be00-845c8c37e415",
  defaultMessage: "Seniors Managers LLD + Managers LLD"
};

var bigAccountManager = {
  id: "a76ca43e-16db-465b-810a-5d5fb6e97fbd",
  defaultMessage: "Manager Grand Compte"
};

var dealer = {
  id: "55517275-85ff-4ef0-88c8-7b778a194873",
  defaultMessage: "Affaire"
};

var noRole = {
  id: "e825102d-c62b-4d95-9046-a5cc458b8615",
  defaultMessage: "Aucun rôle"
};

var adminDealerFR = {
  id: "57ef4140-9eda-45df-b365-f8cc803d51e0",
  defaultMessage: "Affaire FR (Pour pilote uniquement)"
};

var adminDealerES = {
  id: "98a59e5b-ce08-4655-84fb-662a7e981ca4",
  defaultMessage: "Affaire ES (Pour pilote uniquement)"
};

var mrr = {
  id: "c86bbe21-4692-4046-91fa-326a8215e7f5",
  defaultMessage: "Manager Réseau"
};

export {
  selectRole ,
  administratorFrance ,
  administratorFranceProd ,
  administratorSpain ,
  advisor ,
  preDirector ,
  companyManager ,
  seniorCompanyManager ,
  acountManager ,
  lldManager ,
  bigAccountManager ,
  dealer ,
  noRole ,
  adminDealerFR ,
  adminDealerES ,
  mrr ,
}
/* No side effect */
