// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../../../../common/components/App_LabeledText.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as Messages_Quotas$DvmAdminFrontendFr from "../../../../../../intl/messages/quotas/Messages_Quotas.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../../common/components/App_OverlaySpinner.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../../../common/components/input/App_DateInputFormField.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as Quota_View_Exclusion_Update_Api$DvmAdminFrontendFr from "./Quota_View_Exclusion_Update_Api.bs.js";
import * as Quota_View_Exclusion_Update_Form$DvmAdminFrontendFr from "./Quota_View_Exclusion_Update_Form.bs.js";

function Quota_View_Exclusion_Update(Props) {
  var contractId = Props.contractId;
  var vehicleType = Props.vehicleType;
  var model = Props.model;
  var modelVersion = Props.modelVersion;
  var startDate = Props.startDate;
  var endDate = Props.endDate;
  var onClose = Props.onClose;
  var onOk = Props.onOk;
  var tableType = Props.tableType;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match[1];
  var saveResult = match[0];
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  var form = Quota_View_Exclusion_Update_Form$DvmAdminFrontendFr.QuotaVersionUpdateForm.useForm({
        startDate: startDate,
        endDate: endDate
      }, (function (output, submissionCallbacks) {
          Quota_View_Exclusion_Update_Api$DvmAdminFrontendFr.saveQuotaExclusion(setSaveResult, userManager, submissionCallbacks, tableType, {
                contractId: contractId,
                vehicleType: vehicleType,
                model: model,
                modelVersion: modelVersion,
                modelName: undefined,
                versionName: undefined,
                startDate: startDate,
                endDate: endDate
              }, selectedRole, {
                contractId: contractId,
                vehicleType: vehicleType,
                model: model,
                modelVersion: modelVersion,
                modelName: undefined,
                versionName: undefined,
                startDate: output.startDate,
                endDate: output.endDate
              });
        }));
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(onOk, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    startDate: startDate,
                    endDate: input.endDate
                  };
          })),
    onBlur: (function (param) {
        Curry._1(form.blurStartDate, undefined);
      })
  };
  if (form.startDateResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  var tmp$1 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    startDate: input.startDate,
                    endDate: endDate
                  };
          })),
    onBlur: (function (param) {
        Curry._1(form.blurEndDate, undefined);
      })
  };
  if (form.endDateResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.addExclusion),
              minWidth: "360px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult),
                    children: React.createElement("form", {
                          onSubmit: (function ($$event) {
                              $$event.preventDefault();
                              Curry._1(form.submit, undefined);
                            })
                        }, React.createElement(Core.Grid, {
                              alignItems: "flex-start",
                              children: null,
                              container: true,
                              direction: "column",
                              spacing: 3
                            }, React.createElement(Core.Grid, {
                                  alignItems: "flex-start",
                                  children: null,
                                  container: true,
                                  item: true,
                                  spacing: 2
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                            label: Messages_Quotas$DvmAdminFrontendFr.contractId,
                                            children: contractId
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                            label: Messages_Quotas_Detail$DvmAdminFrontendFr.vehicleType,
                                            children: vehicleType
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                            label: tableType === /* Model */0 ? Messages_Common$DvmAdminFrontendFr.model : Messages_Common$DvmAdminFrontendFr.version,
                                            children: Belt_Option.getWithDefault(tableType === /* Model */0 ? model : modelVersion, "")
                                          }),
                                      item: true
                                    })), React.createElement(Core.Grid, {
                                  children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp),
                                  item: true
                                }), React.createElement(Core.Grid, {
                                  children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$1),
                                  item: true
                                }), React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  item: true,
                                  justifyContent: "space-between"
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                            onClick: (function (param) {
                                                Curry._1(onClose, undefined);
                                              }),
                                            label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.cancel),
                                            color: "primaryBorder",
                                            size: "medium",
                                            type_: "button"
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                            label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                            size: "medium",
                                            type_: "submit"
                                          }),
                                      item: true
                                    }))))
                  })
            });
}

var Msg;

var Form;

var Api;

var make = Quota_View_Exclusion_Update;

export {
  Msg ,
  Form ,
  Api ,
  make ,
}
/* react Not a pure module */
