// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../common/App_Api.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../common/components/App_Checkbox.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../context/user/User_Context.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../context/router/Context_Router.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../common/types/App_Types_Result.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../common/styles/App_Styles_Common.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../common/components/input/App_SelectFormInput.bs.js";
import * as Messages_ModelVersion$DvmAdminFrontendFr from "../../intl/messages/modelVersion/Messages_ModelVersion.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Flash_Edit_NonFleet_Api$DvmAdminFrontendFr from "../flash/edit/steps/NonFleet/Flash_Edit_NonFleet_Api.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as App_DoubleSelectFormInput$DvmAdminFrontendFr from "../../common/components/input/App_DoubleSelectFormInput.bs.js";

function Model_Version_Filters(Props) {
  var form = Props.form;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match$2[1];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelVersions = match$3[1];
  var modelVersions = match$3[0];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setMotorizations = match$4[1];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelsByBrand = match$5[1];
  var modelsByBrand = match$5[0];
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchModelVersions(setModelVersions, userManager, selectedRole, undefined, undefined, undefined);
          App_Api$DvmAdminFrontendFr.fetchMotorisations(setMotorizations, userManager, selectedRole);
        }), []);
  React.useEffect((function () {
          if (form.input.brand.length > 0) {
            Flash_Edit_NonFleet_Api$DvmAdminFrontendFr.fetchModelsByBrand(setModelsByBrand, userManager, selectedRole, {
                  hd: form.input.brand,
                  tl: /* [] */0
                }, /* VPVU */2);
          }
          
        }), [form.input.brand]);
  var filteredModelVersions = function (param) {
    return App_Types_Result$DvmAdminFrontendFr.mapWithDefault(modelVersions, /* [] */0, (function (x) {
                  if (form.input.brand.length > 0) {
                    return App_Types_Result$DvmAdminFrontendFr.mapWithDefault(modelsByBrand, x, (function (models) {
                                  return Belt_List.keep(x, (function (x) {
                                                return Belt_List.has(models, x, (function (a, b) {
                                                              return a === b.parent;
                                                            }));
                                              }));
                                }));
                  } else {
                    return x;
                  }
                }));
  };
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(dispatch, {
                        TAG: /* StoreModelVersionFilter */2,
                        _0: form.input
                      });
                  if (form.input.version.length > 0 || form.input.versionLabel.length > 0 || form.input.motorization.length > 0 || form.input.emptyMotorization || Belt_Option.isSome(form.input.additionDate)) {
                    Curry._2(form.updateTableType, (function (input, tableType) {
                            return {
                                    brand: input.brand,
                                    model: input.model,
                                    version: input.version,
                                    phase: input.phase,
                                    modelLabel: input.modelLabel,
                                    versionLabel: input.versionLabel,
                                    motorization: input.motorization,
                                    emptyMotorization: input.emptyMotorization,
                                    active: input.active,
                                    tableType: tableType,
                                    additionDate: input.additionDate
                                  };
                          }), /* Version */1);
                  }
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Common$DvmAdminFrontendFr.brand,
                            options: {
                              hd: {
                                label: {
                                  TAG: /* Message */0,
                                  _0: Messages_Common$DvmAdminFrontendFr.everything
                                },
                                value: ""
                              },
                              tl: {
                                hd: {
                                  label: {
                                    TAG: /* String */3,
                                    _0: "Renault"
                                  },
                                  value: "Renault"
                                },
                                tl: {
                                  hd: {
                                    label: {
                                      TAG: /* String */3,
                                      _0: "Dacia"
                                    },
                                    value: "Dacia"
                                  },
                                  tl: {
                                    hd: {
                                      label: {
                                        TAG: /* String */3,
                                        _0: "Alpine"
                                      },
                                      value: "Alpine"
                                    },
                                    tl: /* [] */0
                                  }
                                }
                              }
                            },
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.brand,
                                onChange: Curry._1(form.updateBrand, (function (input, brand) {
                                        return {
                                                brand: brand,
                                                model: input.model,
                                                version: input.version,
                                                phase: input.phase,
                                                modelLabel: input.modelLabel,
                                                versionLabel: input.versionLabel,
                                                motorization: input.motorization,
                                                emptyMotorization: input.emptyMotorization,
                                                active: input.active,
                                                tableType: input.tableType,
                                                additionDate: input.additionDate
                                              };
                                      }))
                              }
                            },
                            id: "brand",
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DoubleSelectFormInput$DvmAdminFrontendFr.make, {
                            labelParent: Messages_Common$DvmAdminFrontendFr.model,
                            labelChild: Messages_Common$DvmAdminFrontendFr.version,
                            valueParent: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.model,
                                onChange: Curry._1(form.updateModel, (function (input, model) {
                                        return {
                                                brand: input.brand,
                                                model: model,
                                                version: "",
                                                phase: input.phase,
                                                modelLabel: input.modelLabel,
                                                versionLabel: input.versionLabel,
                                                motorization: input.motorization,
                                                emptyMotorization: input.emptyMotorization,
                                                active: input.active,
                                                tableType: input.tableType,
                                                additionDate: input.additionDate
                                              };
                                      }))
                              }
                            },
                            valueChild: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.version,
                                onChange: Curry._1(form.updateVersion, (function (input, version) {
                                        return {
                                                brand: input.brand,
                                                model: input.model,
                                                version: version,
                                                phase: input.phase,
                                                modelLabel: input.modelLabel,
                                                versionLabel: input.versionLabel,
                                                motorization: input.motorization,
                                                emptyMotorization: input.emptyMotorization,
                                                active: input.active,
                                                tableType: input.tableType,
                                                additionDate: input.additionDate
                                              };
                                      }))
                              }
                            },
                            options: filteredModelVersions(undefined),
                            classNameParent: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
                            classNameChild: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Common$DvmAdminFrontendFr.phase,
                            options: {
                              hd: {
                                label: {
                                  TAG: /* Message */0,
                                  _0: Messages_Common$DvmAdminFrontendFr.everything
                                },
                                value: ""
                              },
                              tl: {
                                hd: {
                                  label: {
                                    TAG: /* String */3,
                                    _0: "1"
                                  },
                                  value: "1"
                                },
                                tl: {
                                  hd: {
                                    label: {
                                      TAG: /* String */3,
                                      _0: "2"
                                    },
                                    value: "2"
                                  },
                                  tl: /* [] */0
                                }
                              }
                            },
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.phase,
                                onChange: Curry._1(form.updatePhase, (function (input, phase) {
                                        return {
                                                brand: input.brand,
                                                model: input.model,
                                                version: input.version,
                                                phase: phase,
                                                modelLabel: input.modelLabel,
                                                versionLabel: input.versionLabel,
                                                motorization: input.motorization,
                                                emptyMotorization: input.emptyMotorization,
                                                active: input.active,
                                                tableType: input.tableType,
                                                additionDate: input.additionDate
                                              };
                                      }))
                              }
                            },
                            id: "phase",
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry._1(form.updateModelLabel, (function (input, modelLabel) {
                                    return {
                                            brand: input.brand,
                                            model: input.model,
                                            version: input.version,
                                            phase: input.phase,
                                            modelLabel: modelLabel,
                                            versionLabel: input.versionLabel,
                                            motorization: input.motorization,
                                            emptyMotorization: input.emptyMotorization,
                                            active: input.active,
                                            tableType: input.tableType,
                                            additionDate: input.additionDate
                                          };
                                  })),
                            value: form.input.modelLabel,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
                            label: Messages_ModelVersion$DvmAdminFrontendFr.labelModel
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry._1(form.updateVersionLabel, (function (input, versionLabel) {
                                    return {
                                            brand: input.brand,
                                            model: input.model,
                                            version: input.version,
                                            phase: input.phase,
                                            modelLabel: input.modelLabel,
                                            versionLabel: versionLabel,
                                            motorization: input.motorization,
                                            emptyMotorization: input.emptyMotorization,
                                            active: input.active,
                                            tableType: input.tableType,
                                            additionDate: input.additionDate
                                          };
                                  })),
                            value: form.input.versionLabel,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
                            label: Messages_ModelVersion$DvmAdminFrontendFr.labelVersion
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      direction: "column",
                      item: true,
                      style: {
                        width: "fit-content"
                      }
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                label: Messages_ModelVersion$DvmAdminFrontendFr.motorization,
                                value: {
                                  TAG: /* Single */0,
                                  _0: {
                                    value: form.input.motorization,
                                    onChange: Curry._1(form.updateMotorization, (function (input, motorization) {
                                            return {
                                                    brand: input.brand,
                                                    model: input.model,
                                                    version: input.version,
                                                    phase: input.phase,
                                                    modelLabel: input.modelLabel,
                                                    versionLabel: input.versionLabel,
                                                    motorization: motorization,
                                                    emptyMotorization: input.emptyMotorization,
                                                    active: input.active,
                                                    tableType: input.tableType,
                                                    additionDate: input.additionDate
                                                  };
                                          }))
                                  }
                                },
                                options: {
                                  TAG: /* Unlabeled */0,
                                  _0: Belt_List.sort(App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$4[0], /* [] */0), Caml.string_compare)
                                },
                                disabled: form.input.emptyMotorization,
                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                              }),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(Core.FormControlLabel, {
                                classes: {
                                  label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                },
                                control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                      checked: form.input.emptyMotorization,
                                      onClick: (function ($$event) {
                                          Curry._2(form.updateEmptyMotorization, (function (input, emptyMotorization) {
                                                  return {
                                                          brand: input.brand,
                                                          model: input.model,
                                                          version: input.version,
                                                          phase: input.phase,
                                                          modelLabel: input.modelLabel,
                                                          versionLabel: input.versionLabel,
                                                          motorization: input.motorization,
                                                          emptyMotorization: emptyMotorization,
                                                          active: input.active,
                                                          tableType: input.tableType,
                                                          additionDate: input.additionDate
                                                        };
                                                }), $$event.target.checked);
                                        })
                                    }),
                                label: intl.formatMessage(Messages_ModelVersion$DvmAdminFrontendFr.emptyMotorizationsCheckbox)
                              }),
                          item: true
                        })), React.createElement(Core.Grid, {
                      children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_ModelVersion$DvmAdminFrontendFr.active,
                            options: {
                              hd: {
                                label: {
                                  TAG: /* Message */0,
                                  _0: Messages_Common$DvmAdminFrontendFr.everything
                                },
                                value: ""
                              },
                              tl: {
                                hd: {
                                  label: {
                                    TAG: /* Message */0,
                                    _0: Messages_Common$DvmAdminFrontendFr.yes
                                  },
                                  value: "TRUE"
                                },
                                tl: {
                                  hd: {
                                    label: {
                                      TAG: /* Message */0,
                                      _0: Messages_Common$DvmAdminFrontendFr.no
                                    },
                                    value: "FALSE"
                                  },
                                  tl: /* [] */0
                                }
                              }
                            },
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.active,
                                onChange: Curry._1(form.updateActive, (function (input, active) {
                                        return {
                                                brand: input.brand,
                                                model: input.model,
                                                version: input.version,
                                                phase: input.phase,
                                                modelLabel: input.modelLabel,
                                                versionLabel: input.versionLabel,
                                                motorization: input.motorization,
                                                emptyMotorization: input.emptyMotorization,
                                                active: active,
                                                tableType: input.tableType,
                                                additionDate: input.additionDate
                                              };
                                      }))
                              }
                            },
                            id: "active",
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, {
                            label: Messages_ModelVersion$DvmAdminFrontendFr.additionDateLabel,
                            value: form.input.additionDate,
                            onChange: Curry._1(form.updateAdditionDate, (function (input, additionDate) {
                                    return {
                                            brand: input.brand,
                                            model: input.model,
                                            version: input.version,
                                            phase: input.phase,
                                            modelLabel: input.modelLabel,
                                            versionLabel: input.versionLabel,
                                            motorization: input.motorization,
                                            emptyMotorization: input.emptyMotorization,
                                            active: input.active,
                                            tableType: input.tableType,
                                            additionDate: additionDate
                                          };
                                  })),
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var InputStyles;

var Form;

var make = Model_Version_Filters;

export {
  InputStyles ,
  Form ,
  make ,
}
/* react Not a pure module */
