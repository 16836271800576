// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Button$DvmAdminFrontendFr from "../../../../../../common/components/button/App_Button.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../../../../common/components/App_Checkbox.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../../common/styles/App_Styles_Common.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as App_TransferListFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_TransferListFormInput.bs.js";
import * as Quota_View_Exclusion_Add_Api$DvmAdminFrontendFr from "./Quota_View_Exclusion_Add_Api.bs.js";
import * as Quota_View_Exclusion_Add_Form$DvmAdminFrontendFr from "./Quota_View_Exclusion_Add_Form.bs.js";

function Quota_View_Exclusion_Add_Selection(Props) {
  var filteredOptions = Props.filteredOptions;
  var setSaveResult = Props.setSaveResult;
  var tableType = Props.tableType;
  var primaryOutputs = Props.primaryOutputs;
  var primaryOutputsError = Props.primaryOutputsError;
  var withClosedModels = Props.withClosedModels;
  var setWithClosedModels = Props.setWithClosedModels;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var form = Quota_View_Exclusion_Add_Form$DvmAdminFrontendFr.ExclusionSelectForm.useForm(Quota_View_Exclusion_Add_Form$DvmAdminFrontendFr.initialStateVersions, (function (output, submissionCallbacks) {
          Belt_Option.mapWithDefault(primaryOutputs, undefined, (function (primaryOutputs) {
                  Quota_View_Exclusion_Add_Api$DvmAdminFrontendFr.editMultipleVersions(/* Create */0, userManager, setSaveResult, submissionCallbacks, tableType, selectedRole, Belt_List.map(output.selections, (function (x) {
                              return {
                                      contractId: primaryOutputs.contractId,
                                      vehicleType: primaryOutputs.vehicleType,
                                      model: tableType === /* Model */0 ? x.value : undefined,
                                      modelVersion: tableType === /* Version */1 ? x.value : undefined,
                                      modelName: undefined,
                                      versionName: undefined,
                                      startDate: Caml_option.some(primaryOutputs.startDate),
                                      endDate: primaryOutputs.endDate
                                    };
                            })));
                }));
        }));
  var tmp = {
    values: form.input.selections,
    options: Belt_List.map(filteredOptions, (function (x) {
            return {
                    label: tableType === /* Model */0 ? ({
                          TAG: /* String */3,
                          _0: x.modelVersionID
                        }) : ({
                          TAG: /* String */3,
                          _0: "" + x.version + " (" + Belt_Option.getWithDefault(x.versionName, "") + ")"
                        }),
                    value: x.modelVersionID
                  };
          })),
    onChange: Curry._1(form.updateSelections, (function (param, selections) {
            return {
                    selections: selections
                  };
          })),
    boxHeight: 300,
    boxLabels: {
      left: {
        TAG: /* String */3,
        _0: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.allVersions, {
              count: Belt_List.length(Belt_List.keep(filteredOptions, (function (element) {
                          return !Belt_List.some(form.input.selections, (function (x) {
                                        return x.value === element.modelVersionID;
                                      }));
                        })))
            })
      },
      right: {
        TAG: /* String */3,
        _0: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.selectedVersions, {
              count: Belt_List.length(form.input.selections)
            })
      }
    },
    search: false
  };
  if (form.selectionsResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.selectionsResult);
  }
  return React.createElement(React.Fragment, undefined, React.createElement("form", {
                  onSubmit: (function ($$event) {
                      $$event.preventDefault();
                      Curry._1(form.submit, undefined);
                    })
                }, React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      spacing: 2
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(Core.FormControlLabel, {
                                classes: {
                                  label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                },
                                control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                      checked: withClosedModels,
                                      onClick: (function ($$event) {
                                          Curry._1(setWithClosedModels, (function (param) {
                                                  return $$event.target.checked;
                                                }));
                                        })
                                    }),
                                label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.includeClosedCars)
                              }),
                          item: true,
                          sm: Grid$Mui.Sm[12]
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(App_TransferListFormInput$DvmAdminFrontendFr.make, tmp),
                          item: true,
                          sm: Grid$Mui.Sm[12]
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                size: "medium",
                                disabled: primaryOutputsError,
                                type_: "submit"
                              }),
                          container: true,
                          item: true,
                          justifyContent: "flex-end"
                        }))));
}

var Form;

var Api;

var Types;

var InputStyles;

var make = Quota_View_Exclusion_Add_Selection;

export {
  Form ,
  Api ,
  Types ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
