// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Css from "@emotion/css";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Core from "@material-ui/core";
import * as App_Typography$DvmAdminFrontendFr from "../../App_Typography.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../styles/App_Styles_Common.bs.js";
import * as Messages_File_Upload$DvmAdminFrontendFr from "../../../intl/messages/Messages_File_Upload.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../components/input/App_TextFieldFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../components/input/App_AutocompleteFormInput.bs.js";

var tooltip = Css.css({
      backgroundColor: "white",
      border: "1px solid black",
      whiteSpace: "pre-line"
    });

var columnEquivalents = Belt_MapString.fromArray([
      [
        "N Affaire",
        "DealerID"
      ],
      [
        "Type de commande",
        "OrderType"
      ],
      [
        "Modele",
        "ModelID"
      ],
      [
        "Date de debut",
        "StartDate"
      ],
      [
        "Date de fin",
        "EndDate"
      ],
      [
        "Energie",
        "EngineType"
      ],
      [
        "Volume",
        "Volume"
      ]
    ]);

function compareCols(tableCol, key) {
  if (tableCol === key) {
    return true;
  } else {
    return tableCol === Belt_Option.getWithDefault(Belt_MapString.get(columnEquivalents, key), "");
  }
}

function File_Upload_Table_Mapping(Props) {
  var excelInfo = Props.excelInfo;
  var mapped = Props.mapped;
  var setMapped = Props.setMapped;
  var setNextDisabled = Props.setNextDisabled;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return excelInfo.tableColumns;
      });
  var setAvailableOptions = match[1];
  var availableOptions = match[0];
  var getValue = function (excelHeader) {
    return Belt_Option.getWithDefault(Belt_MapString.get(mapped, excelHeader), "");
  };
  var automatch = function (param) {
    var tempMap = Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(excelInfo.excelColumns, (function (x) {
                    return [
                            x,
                            ""
                          ];
                  }))));
    Curry._1(setMapped, (function (param) {
            return Belt_MapString.mapWithKey(tempMap, (function (key, param) {
                          return Belt_Option.getWithDefault(Belt_List.getBy(excelInfo.tableColumns, (function (tableColumn) {
                                            return compareCols(tableColumn, key);
                                          })), "");
                        }));
          }));
  };
  var mappingNotComplete = Belt_Array.keep(Belt_MapString.valuesToArray(mapped), (function (x) {
          return x.length > 0;
        })).length !== Belt_List.length(excelInfo.tableColumns);
  React.useEffect((function () {
          Curry._1(setNextDisabled, (function (param) {
                  return mappingNotComplete;
                }));
          Curry._1(setAvailableOptions, (function (param) {
                  return Belt_List.keep(excelInfo.tableColumns, (function (x) {
                                return Belt_Option.isNone(Belt_Array.getBy(Belt_MapString.valuesToArray(mapped), (function (map) {
                                                  return map === x;
                                                })));
                              }));
                }));
        }), [mapped]);
  React.useEffect((function () {
          if (mappingNotComplete) {
            automatch(undefined);
          }
          
        }), []);
  return React.createElement(Core.Grid, {
              alignItems: "center",
              children: null,
              container: true,
              direction: "column",
              item: true,
              spacing: 1
            }, React.createElement(Core.Grid, {
                  children: React.createElement(Core.Tooltip, {
                        arrow: true,
                        children: React.createElement("div", undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                  color: "textPrimary",
                                  gutterBottom: true,
                                  style: {
                                    textDecoration: "underline"
                                  },
                                  children: intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.importantMessage),
                                  variant: "bodyBig"
                                })),
                        classes: {
                          tooltip: tooltip
                        },
                        placement: "top",
                        title: intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.infoAboutDates)
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  alignItems: "center",
                  children: Belt_List.toArray(Belt_List.mapWithIndex(excelInfo.excelColumns, (function (rowIndex, row) {
                              return React.createElement(Core.Grid, {
                                          children: null,
                                          container: true,
                                          item: true,
                                          justifyContent: "center",
                                          spacing: 1,
                                          key: "row" + String(rowIndex)
                                        }, React.createElement(Core.Grid, {
                                              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                                    value: row,
                                                    disabled: true,
                                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                                                  }),
                                              item: true
                                            }), React.createElement(Core.Grid, {
                                              children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                                    value: {
                                                      TAG: /* Single */0,
                                                      _0: {
                                                        value: getValue(row),
                                                        onChange: (function (val) {
                                                            Curry._1(setMapped, (function (mapped) {
                                                                    return Belt_MapString.set(mapped, row, val);
                                                                  }));
                                                          })
                                                      }
                                                    },
                                                    options: {
                                                      TAG: /* Unlabeled */0,
                                                      _0: Belt_Option.mapWithDefault(Belt_MapString.get(mapped, row), availableOptions, (function (x) {
                                                              if (x.length > 0) {
                                                                return Belt_List.add(availableOptions, x);
                                                              } else {
                                                                return availableOptions;
                                                              }
                                                            }))
                                                    },
                                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                                                  }),
                                              item: true
                                            }));
                            }))),
                  container: true,
                  direction: "column",
                  item: true
                }));
}

var Types;

var InputStyles;

var make = File_Upload_Table_Mapping;

export {
  Types ,
  InputStyles ,
  tooltip ,
  columnEquivalents ,
  compareCols ,
  make ,
}
/* tooltip Not a pure module */
