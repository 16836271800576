// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as Company_Detail_Styles$DvmAdminFrontendFr from "./Company_Detail_Styles.bs.js";
import * as Messages_Company_Detail$DvmAdminFrontendFr from "../../../intl/messages/company/Messages_Company_Detail.bs.js";

function Company_Detail_Main_Info(Props) {
  var companyDetail = Props.companyDetail;
  var intl = ReactIntl.useIntl();
  var street = Belt_Option.getWithDefault(companyDetail.streetNbr, "") + " " + Belt_Option.getWithDefault(companyDetail.street, "");
  var adressDetail = Belt_Option.getWithDefault(companyDetail.additionalAddressInfo, "");
  var city = Belt_Option.getWithDefault(companyDetail.zipCode, "") + " " + Belt_Option.getWithDefault(companyDetail.city, "");
  var countryCode = Belt_Option.getWithDefault(companyDetail.countryCode, "");
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: React.createElement(Core.Grid, {
                        children: null,
                        container: true,
                        item: true
                      }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                            color: "primary",
                            children: intl.formatMessage(Messages_Company_Detail$DvmAdminFrontendFr.titleDetail),
                            variant: "h3"
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(Core.Grid, {
                                  children: null,
                                  className: Company_Detail_Styles$DvmAdminFrontendFr.addressBox,
                                  container: true,
                                  direction: "column",
                                  item: true
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                            gutterBottom: true,
                                            style: {
                                              fontWeight: "600"
                                            },
                                            children: intl.formatMessage(Messages_Company_Detail$DvmAdminFrontendFr.address),
                                            variant: "bodyBig"
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: null,
                                      item: true
                                    }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                          children: Belt_Array.joinWith(Belt_Array.keep([
                                                    street,
                                                    adressDetail
                                                  ], (function (x) {
                                                      return x.trim().length > 0;
                                                    })), " , ", (function (x) {
                                                  return x;
                                                })),
                                          variant: "bodyBig"
                                        }), React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                          children: Belt_Array.joinWith(Belt_Array.keep([
                                                    city,
                                                    countryCode
                                                  ], (function (x) {
                                                      return x.trim().length > 0;
                                                    })), " , ", (function (x) {
                                                  return x;
                                                })),
                                          variant: "bodyBig"
                                        }))),
                            className: Company_Detail_Styles$DvmAdminFrontendFr.detailBox,
                            container: true,
                            direction: "column"
                          })),
                  container: true,
                  direction: "column",
                  spacing: 5
                }));
}

var make = Company_Detail_Main_Info;

export {
  make ,
}
/* react Not a pure module */
