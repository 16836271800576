// Generated by ReScript, PLEASE EDIT WITH CARE


var title = {
  id: "b477b9bc-58d1-4046-937f-b2c55be34466",
  defaultMessage: "Référentiel des VMEs"
};

var resultInfo = {
  id: "0f618f8a-4daf-4932-b294-97a701f85cfe",
  defaultMessage: "référentiels"
};

var createReferential = {
  id: "21c93950-0133-4347-b1fc-4cdb9551156a",
  defaultMessage: "Créer un référentiel"
};

var modifyReferential = {
  id: "34209de7-ab08-4d6d-8a79-c2f5a8748437",
  defaultMessage: "Modifier le référentiel"
};

var countryCode = {
  id: "f5f621c2-a9b7-44cc-bd2d-9f10ac28b95a",
  defaultMessage: "Country code"
};

var localActionTypeID = {
  id: "dea676dd-a4b5-4770-a40b-6d9abdd13fb4",
  defaultMessage: "Local action type id"
};

var localActionTypeLabel = {
  id: "1c802422-50b0-499d-9495-dc677f6997b4",
  defaultMessage: "Local action type label"
};

var localActionDescription = {
  id: "636ed703-ce55-42e4-987a-7ef5849ff9e8",
  defaultMessage: "Local action description"
};

var localActionTrigger = {
  id: "4d6f906a-f4dc-4874-9968-2ab7c59a5d4f",
  defaultMessage: "Local action trigger"
};

var appCorpChannel = {
  id: "e4595957-0f20-499d-ae0f-bc7eea1ae17b",
  defaultMessage: "Application corp channel"
};

var distributionMode = {
  id: "4b982363-4014-489c-bf9a-5588ae56703c",
  defaultMessage: "Distribution mode"
};

var applicationClientChannel = {
  id: "8814334b-3fbe-4248-a0c3-49f957dde44b",
  defaultMessage: "Application client channel"
};

var appSubChannel = {
  id: "225c04d3-7fa3-4aa0-80c6-a8e3e4475257",
  defaultMessage: "Application sub channel"
};

var marketingCorpCategory = {
  id: "9688c7c6-838c-4579-a246-c2d61e9a1152",
  defaultMessage: "Marketing corp category"
};

var marketingCorpType = {
  id: "095aed9b-b484-42cc-9119-ac59f2bcb456",
  defaultMessage: "Marketing corp type"
};

var vmeDestination = {
  id: "ac2fd3fc-9e0c-4369-b4c1-03bffbace22f",
  defaultMessage: "VME destination"
};

var controllingCorpTypeID = {
  id: "3cb6865a-4372-45c5-b095-61f9c893dd5e",
  defaultMessage: "Controlling corp type ID"
};

var controllingCorpTypeLabel = {
  id: "798650ee-9df3-4aab-af9c-ad2e409a49db",
  defaultMessage: "Controlling corp type label"
};

var definition = {
  id: "3a472751-1b7c-43d8-8709-1333e947b1de",
  defaultMessage: "Definition"
};

var paidTo = {
  id: "4ed514ab-cbd9-4c87-936c-283591ee22f4",
  defaultMessage: "Paid to"
};

var controllingAppStartDate = {
  id: "04dd08ef-daa5-42f0-8b6c-ce1df9275948",
  defaultMessage: "Controlling app start date"
};

var controllingAppEndDate = {
  id: "a880683e-ec73-4c2a-83dc-db51da2c088e",
  defaultMessage: "Controlling app end date"
};

var deleteReferentialTitle = {
  id: "e3dd7110-999b-4ef7-96ed-b9d89849765d",
  defaultMessage: "Supprimer un référentiel"
};

var deleteMessage = {
  id: "456c58ef-22b1-46d0-b780-375685a5817d",
  defaultMessage: "Confirmez-vous la suppression du référentiel sélectionné ?"
};

var brandNoFrench = {
  id: "5cb2fc98-8b7f-46af-b8b8-114ac4f247be",
  defaultMessage: "Brand"
};

export {
  title ,
  resultInfo ,
  createReferential ,
  modifyReferential ,
  countryCode ,
  localActionTypeID ,
  localActionTypeLabel ,
  localActionDescription ,
  localActionTrigger ,
  appCorpChannel ,
  distributionMode ,
  applicationClientChannel ,
  appSubChannel ,
  marketingCorpCategory ,
  marketingCorpType ,
  vmeDestination ,
  controllingCorpTypeID ,
  controllingCorpTypeLabel ,
  definition ,
  paidTo ,
  controllingAppStartDate ,
  controllingAppEndDate ,
  deleteReferentialTitle ,
  deleteMessage ,
  brandNoFrench ,
}
/* No side effect */
