// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

var FilterFields = {
  contractId: "contractID",
  budget: "budget"
};

var Columns = {
  contractId: "contractID",
  contractLabel: "contractLabel",
  budgetType: "budget"
};

function row_encode(v) {
  return Js_dict.fromArray([
              [
                "contractId",
                Decco.stringToJson(v.contractId)
              ],
              [
                "contractLabel",
                Decco.optionToJson(Decco.stringToJson, v.contractLabel)
              ],
              [
                "budgetType",
                Decco.stringToJson(v.budgetType)
              ]
            ]);
}

function row_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var contractId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractId"), null));
  if (contractId.TAG === /* Ok */0) {
    var contractLabel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractLabel"), null));
    if (contractLabel.TAG === /* Ok */0) {
      var budgetType = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "budgetType"), null));
      if (budgetType.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  contractId: contractId._0,
                  contractLabel: contractLabel._0,
                  budgetType: budgetType._0
                }
              };
      }
      var e = budgetType._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".budgetType" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = contractLabel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".contractLabel" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = contractId._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".contractId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(row_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(row_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var MVGrid = {
  row_encode: row_encode,
  row_decode: row_decode,
  searchResult_encode: searchResult_encode,
  searchResult_decode: searchResult_decode
};

function saveMVGrid_encode(v) {
  return Js_dict.fromArray([
              [
                "budgetType",
                Decco.stringToJson(v.budgetType)
              ],
              [
                "contractID",
                Decco.stringToJson(v.contractID)
              ]
            ]);
}

function saveMVGrid_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var budgetType = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "budgetType"), null));
  if (budgetType.TAG === /* Ok */0) {
    var contractID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractID"), null));
    if (contractID.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                budgetType: budgetType._0,
                contractID: contractID._0
              }
            };
    }
    var e = contractID._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".contractID" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = budgetType._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".budgetType" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function contracts_encode(v) {
  return Js_dict.fromArray([
              [
                "budget",
                Decco.optionToJson(Decco.stringToJson, v.budget)
              ],
              [
                "contractLabel",
                Decco.optionToJson(Decco.stringToJson, v.contractLabel)
              ],
              [
                "contractID",
                Decco.stringToJson(v.contractID)
              ]
            ]);
}

function contracts_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var budget = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "budget"), null));
  if (budget.TAG === /* Ok */0) {
    var contractLabel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractLabel"), null));
    if (contractLabel.TAG === /* Ok */0) {
      var contractID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractID"), null));
      if (contractID.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  budget: budget._0,
                  contractLabel: contractLabel._0,
                  contractID: contractID._0
                }
              };
      }
      var e = contractID._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".contractID" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = contractLabel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".contractLabel" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = budget._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".budget" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function searchResult_encode$1(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(contracts_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode$1(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(contracts_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var ContractSelect = {
  contracts_encode: contracts_encode,
  contracts_decode: contracts_decode,
  searchResult_encode: searchResult_encode$1,
  searchResult_decode: searchResult_decode$1
};

export {
  FilterFields ,
  Columns ,
  MVGrid ,
  saveMVGrid_encode ,
  saveMVGrid_decode ,
  ContractSelect ,
}
/* No side effect */
