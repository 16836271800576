// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Table$DvmAdminFrontendFr from "../../../../../../../../common/components/table/App_Table.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../../../../intl/Intl_Utils.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../../../../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../../../../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../../../common/types/App_Types_Result.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../../../../../common/components/table/App_Table_Pagination.bs.js";

var headersConfigurationEdit_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell("modelVersion", undefined, undefined, undefined, undefined, undefined, Messages_Flash_Edit$DvmAdminFrontendFr.modelVersion);

var headersConfigurationEdit_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell("versionLabelCommercial", undefined, undefined, undefined, undefined, undefined, Messages_Flash_Edit$DvmAdminFrontendFr.versionLabelCommercial),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell("amount", undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.amount),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell("startDate", undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.startDate),
      tl: {
        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell("endDate", undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.endDate),
        tl: /* [] */0
      }
    }
  }
};

var headersConfigurationEdit = {
  hd: headersConfigurationEdit_0,
  tl: headersConfigurationEdit_1
};

function Flash_Edit_Version_Perf_Edit_Table(Props) {
  var selectedVersionsListFilteredPaged = Props.selectedVersionsListFilteredPaged;
  var page = Props.page;
  var setPage = Props.setPage;
  var rowsPerPage = Props.rowsPerPage;
  var totalFilteredRows = Props.totalFilteredRows;
  var intl = ReactIntl.useIntl();
  var convertTable = function (param) {
    return App_Types_Result$DvmAdminFrontendFr.map({
                TAG: /* Ok */0,
                _0: undefined
              }, (function (param) {
                  return {
                          content: Belt_List.map(selectedVersionsListFilteredPaged, (function (version) {
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, undefined, undefined, Belt_List.mapWithIndex({
                                                  hd: version.modelVersionID,
                                                  tl: {
                                                    hd: Belt_Option.getWithDefault(version.versionName, ""),
                                                    tl: {
                                                      hd: Belt_Option.mapWithDefault(version.maxBonus, "", (function (eta) {
                                                              return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, eta);
                                                            })),
                                                      tl: {
                                                        hd: Belt_Option.mapWithDefault(version.startDate, "", (function (param) {
                                                                return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                              })),
                                                        tl: {
                                                          hd: Belt_Option.mapWithDefault(version.endDate, "", (function (param) {
                                                                  return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                })),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfigurationEdit, index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: page,
                          totalElements: totalFilteredRows
                        };
                }));
  };
  var handleChangePage = function (page) {
    Curry._1(setPage, (function (param) {
            return page;
          }));
  };
  return React.createElement(App_Table$DvmAdminFrontendFr.make, {
              headers: headersConfigurationEdit,
              tablePage: convertTable(undefined),
              paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(/* BOTTOM */1, rowsPerPage, handleChangePage, (function (param) {
                      
                    }), undefined),
              width: "100%"
            });
}

var Types;

var make = Flash_Edit_Version_Perf_Edit_Table;

export {
  Types ,
  headersConfigurationEdit ,
  make ,
}
/* headersConfigurationEdit Not a pure module */
