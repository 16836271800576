// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Button$DvmAdminFrontendFr from "../../../../../../../../common/components/button/App_Button.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../../../intl/messages/Messages_Common.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Flash_Edit_Version_Perf_Edit_Api$DvmAdminFrontendFr from "./Flash_Edit_Version_Perf_Edit_Api.bs.js";
import * as Flash_Edit_Version_Perf_Edit_Forms$DvmAdminFrontendFr from "./Flash_Edit_Version_Perf_Edit_Forms.bs.js";

function Flash_Edit_Version_Perf_Edit_Amount(Props) {
  var flash = Props.flash;
  var selectedVersionsListFiltered = Props.selectedVersionsListFiltered;
  var setResultEdit = Props.setResultEdit;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var form = Flash_Edit_Version_Perf_Edit_Forms$DvmAdminFrontendFr.EditVersionsForm.useForm(Flash_Edit_Version_Perf_Edit_Forms$DvmAdminFrontendFr.initialState, {
        operation: "Amount",
        flashStartDate: undefined,
        flashEndDate: undefined,
        highestStartDate: undefined
      }, (function (param, submissionCallbacks) {
          var amount = param.amount;
          Flash_Edit_Version_Perf_Edit_Api$DvmAdminFrontendFr.perfEditVersion(setResultEdit, userManager, submissionCallbacks, Belt_List.map(selectedVersionsListFiltered, (function (x) {
                      return {
                              actionID: flash.actionId,
                              modelVersionID: x.modelVersionID,
                              oldStartDate: undefined,
                              startDate: x.startDate,
                              endDate: x.endDate,
                              maxBonus: amount
                            };
                    })), flash.actionId, "PUT", selectedRole);
        }));
  var tmp = {
    onChange: Curry._1(form.updateAmount, (function (input, amount) {
            return {
                    amount: amount,
                    date: input.date
                  };
          })),
    value: form.input.amount,
    label: Messages_Common$DvmAdminFrontendFr.amount,
    InputProps: {
      startAdornment: React.createElement(Core.InputAdornment, {
            children: "€",
            position: "start"
          })
    },
    inputType: "number"
  };
  if (form.amountResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.amountResult);
  }
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              direction: "column",
              item: true
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        gutterBottom: true,
                        style: {
                          fontWeight: "600"
                        },
                        children: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.editMaxAmount),
                        variant: "bodyBig"
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement("form", {
                        onSubmit: (function ($$event) {
                            $$event.preventDefault();
                            Curry._1(form.submit, undefined);
                          })
                      }, React.createElement(Core.Grid, {
                            alignItems: "flex-start",
                            children: null,
                            container: true,
                            spacing: 3
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                      label: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.applyToResults),
                                      color: "secondary",
                                      size: "medium",
                                      type_: "submit"
                                    }),
                                item: true
                              }))),
                  item: true
                }));
}

var Form;

var Api;

var Types;

var make = Flash_Edit_Version_Perf_Edit_Amount;

export {
  Form ,
  Api ,
  Types ,
  make ,
}
/* react Not a pure module */
