// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../common/types/App_Types_Date.bs.js";

var Columns = {
  version: "version",
  contractId: "contractId",
  vehicleType: "vehicleType",
  modelId: "modelId",
  modelVersionId: "modelVersionId",
  modelName: "modelName",
  versionName: "versionName",
  startDate: "startDate",
  endDate: "endDate",
  withClosedModels: "withClosedModels"
};

function exclusionRow_encode(v) {
  return Js_dict.fromArray([
              [
                "contractId",
                Decco.stringToJson(v.contractId)
              ],
              [
                "vehicleType",
                Decco.stringToJson(v.vehicleType)
              ],
              [
                "model",
                Decco.optionToJson(Decco.stringToJson, v.model)
              ],
              [
                "modelVersion",
                Decco.optionToJson(Decco.stringToJson, v.modelVersion)
              ],
              [
                "modelName",
                Decco.optionToJson(Decco.stringToJson, v.modelName)
              ],
              [
                "versionName",
                Decco.optionToJson(Decco.stringToJson, v.versionName)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function exclusionRow_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var contractId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractId"), null));
  if (contractId.TAG === /* Ok */0) {
    var vehicleType = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleType"), null));
    if (vehicleType.TAG === /* Ok */0) {
      var model = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "model"), null));
      if (model.TAG === /* Ok */0) {
        var modelVersion = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelVersion"), null));
        if (modelVersion.TAG === /* Ok */0) {
          var modelName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelName"), null));
          if (modelName.TAG === /* Ok */0) {
            var versionName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "versionName"), null));
            if (versionName.TAG === /* Ok */0) {
              var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
              if (startDate.TAG === /* Ok */0) {
                var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
                if (endDate.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: {
                            contractId: contractId._0,
                            vehicleType: vehicleType._0,
                            model: model._0,
                            modelVersion: modelVersion._0,
                            modelName: modelName._0,
                            versionName: versionName._0,
                            startDate: startDate._0,
                            endDate: endDate._0
                          }
                        };
                }
                var e = endDate._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".endDate" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = startDate._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".startDate" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = versionName._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".versionName" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = modelName._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".modelName" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = modelVersion._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".modelVersion" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = model._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".model" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = vehicleType._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".vehicleType" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = contractId._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".contractId" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(exclusionRow_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(exclusionRow_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function exclusionsSaveRequest_encode(v) {
  return Decco.listToJson(exclusionRow_encode, v);
}

function exclusionsSaveRequest_decode(v) {
  return Decco.listFromJson(exclusionRow_decode, v);
}

var Exclusion = {
  exclusionRow_encode: exclusionRow_encode,
  exclusionRow_decode: exclusionRow_decode,
  searchResult_encode: searchResult_encode,
  searchResult_decode: searchResult_decode,
  exclusionsSaveRequest_encode: exclusionsSaveRequest_encode,
  exclusionsSaveRequest_decode: exclusionsSaveRequest_decode
};

export {
  Columns ,
  Exclusion ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
