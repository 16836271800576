// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../common/utils/Utils_Form.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../common/types/App_Types_Date.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Error_Inputs.bs.js";

function validateRequiredDateCompareTest(smallerDate, largerDate, thisDate, param) {
  var match = thisDate === "smaller" ? [
      smallerDate,
      largerDate
    ] : [
      largerDate,
      smallerDate
    ];
  var that = match[1];
  return Belt_Option.mapWithDefault(match[0], {
              TAG: /* Error */1,
              _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
            }, (function ($$this) {
                if (App_Types_Date$DvmAdminFrontendFr.isValid($$this)) {
                  return Belt_Option.mapWithDefault(that, {
                              TAG: /* Error */1,
                              _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherDateNotValid
                            }, (function (that) {
                                if (App_Types_Date$DvmAdminFrontendFr.isValid(that)) {
                                  if (thisDate === "smaller") {
                                    if ($$this.valueOf() > that.valueOf()) {
                                      return {
                                              TAG: /* Error */1,
                                              _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateTooLarge
                                            };
                                    } else {
                                      return {
                                              TAG: /* Ok */0,
                                              _0: $$this
                                            };
                                    }
                                  } else if ($$this.valueOf() < that.valueOf()) {
                                    return {
                                            TAG: /* Error */1,
                                            _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateTooSmall
                                          };
                                  } else {
                                    return {
                                            TAG: /* Ok */0,
                                            _0: $$this
                                          };
                                  }
                                } else {
                                  return {
                                          TAG: /* Error */1,
                                          _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherDateNotValid
                                        };
                                }
                              }));
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                        };
                }
              }));
}

var validators_hasExceptions = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      if (param$1.whatGrid === /* ModelVersionGrid */0 && param$1.whatTable === /* Model */0) {
        return {
                TAG: /* Ok */0,
                _0: param.hasExceptions
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: false
              };
      }
    })
};

var validators_acquisitionType = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var budget = param$1.budget;
      if (param$1.whatGrid === /* ModelVersionGrid */0 && (budget === "LCD" || budget === "PROT")) {
        return Utils_Form$DvmAdminFrontendFr.validateRequired(param.acquisitionType);
      } else {
        return {
                TAG: /* Ok */0,
                _0: ""
              };
      }
    })
};

var validators_endDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return validateRequiredDateCompareTest(param.startDate, param.endDate, "larger", undefined);
    })
};

var validators_startDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return validateRequiredDateCompareTest(param.startDate, param.endDate, "smaller", undefined);
    })
};

var validators_margin = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      if (param$1.whatGrid !== /* MarginGrid */1) {
        return {
                TAG: /* Ok */0,
                _0: 0.0
              };
      }
      var margin = param.margin;
      if (margin !== undefined) {
        if (margin >= 0) {
          return {
                  TAG: /* Ok */0,
                  _0: margin
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldPositive
                };
        }
      } else {
        return {
                TAG: /* Error */1,
                _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
              };
      }
    })
};

var validators_discount = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var discount = param.discount;
      if (discount !== undefined) {
        if (discount >= 0) {
          return {
                  TAG: /* Ok */0,
                  _0: discount
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldPositive
                };
        }
      } else {
        return {
                TAG: /* Error */1,
                _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
              };
      }
    })
};

var validators_categoryCode = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      if (param$1.whatTable === /* GridID */2 || param$1.operation === /* AddCategory */2) {
        return Utils_Form$DvmAdminFrontendFr.validateRequired(param.categoryCode);
      } else {
        return {
                TAG: /* Ok */0,
                _0: ""
              };
      }
    })
};

var validators_version = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      if (param$1.whatTable === /* Version */1 && param$1.operation !== /* AddCategory */2) {
        return Utils_Form$DvmAdminFrontendFr.validateRequired(param.version);
      } else {
        return {
                TAG: /* Ok */0,
                _0: ""
              };
      }
    })
};

var validators_model = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      if (param$1.whatTable !== /* GridID */2) {
        return Utils_Form$DvmAdminFrontendFr.validateRequired(param.model);
      } else {
        return {
                TAG: /* Ok */0,
                _0: ""
              };
      }
    })
};

var validators = {
  categoryStartDate: undefined,
  hasExceptions: validators_hasExceptions,
  acquisitionType: validators_acquisitionType,
  endDate: validators_endDate,
  startDate: validators_startDate,
  margin: validators_margin,
  discount: validators_discount,
  categoryCode: validators_categoryCode,
  version: validators_version,
  model: validators_model
};

function initialFieldsStatuses(_input) {
  return {
          categoryStartDate: /* Pristine */0,
          hasExceptions: /* Pristine */0,
          acquisitionType: /* Pristine */0,
          endDate: /* Pristine */0,
          startDate: /* Pristine */0,
          margin: /* Pristine */0,
          discount: /* Pristine */0,
          categoryCode: /* Pristine */0,
          version: /* Pristine */0,
          model: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match_0 = {
    TAG: /* Ok */0,
    _0: input.categoryStartDate
  };
  var match = fieldsStatuses.hasExceptions;
  var match_0$1 = match ? match._0 : Curry._2(validators.hasExceptions.validate, input, metadata);
  var match$1 = fieldsStatuses.acquisitionType;
  var match_0$2 = match$1 ? match$1._0 : Curry._2(validators.acquisitionType.validate, input, metadata);
  var match$2 = fieldsStatuses.endDate;
  var match_0$3 = match$2 ? match$2._0 : Curry._2(validators.endDate.validate, input, metadata);
  var match$3 = fieldsStatuses.startDate;
  var match_0$4 = match$3 ? match$3._0 : Curry._2(validators.startDate.validate, input, metadata);
  var match$4 = fieldsStatuses.margin;
  var match_0$5 = match$4 ? match$4._0 : Curry._2(validators.margin.validate, input, metadata);
  var match$5 = fieldsStatuses.discount;
  var match_0$6 = match$5 ? match$5._0 : Curry._2(validators.discount.validate, input, metadata);
  var match$6 = fieldsStatuses.categoryCode;
  var match_0$7 = match$6 ? match$6._0 : Curry._2(validators.categoryCode.validate, input, metadata);
  var match$7 = fieldsStatuses.version;
  var match_0$8 = match$7 ? match$7._0 : Curry._2(validators.version.validate, input, metadata);
  var match$8 = fieldsStatuses.model;
  var match_0$9 = match$8 ? match$8._0 : Curry._2(validators.model.validate, input, metadata);
  var categoryStartDateResult = match_0;
  var categoryStartDateResult$1;
  if (categoryStartDateResult.TAG === /* Ok */0) {
    var hasExceptionsResult = match_0$1;
    if (hasExceptionsResult.TAG === /* Ok */0) {
      var acquisitionTypeResult = match_0$2;
      if (acquisitionTypeResult.TAG === /* Ok */0) {
        var endDateResult = match_0$3;
        if (endDateResult.TAG === /* Ok */0) {
          var startDateResult = match_0$4;
          if (startDateResult.TAG === /* Ok */0) {
            var marginResult = match_0$5;
            if (marginResult.TAG === /* Ok */0) {
              var discountResult = match_0$6;
              if (discountResult.TAG === /* Ok */0) {
                var categoryCodeResult = match_0$7;
                if (categoryCodeResult.TAG === /* Ok */0) {
                  var versionResult = match_0$8;
                  if (versionResult.TAG === /* Ok */0) {
                    var modelResult = match_0$9;
                    if (modelResult.TAG === /* Ok */0) {
                      return {
                              TAG: /* Valid */0,
                              output: {
                                model: modelResult._0,
                                version: versionResult._0,
                                categoryCode: categoryCodeResult._0,
                                discount: discountResult._0,
                                margin: marginResult._0,
                                startDate: startDateResult._0,
                                endDate: endDateResult._0,
                                acquisitionType: acquisitionTypeResult._0,
                                hasExceptions: hasExceptionsResult._0,
                                categoryStartDate: categoryStartDateResult._0
                              },
                              fieldsStatuses: {
                                categoryStartDate: /* Dirty */{
                                  _0: categoryStartDateResult,
                                  _1: /* Hidden */1
                                },
                                hasExceptions: /* Dirty */{
                                  _0: hasExceptionsResult,
                                  _1: /* Shown */0
                                },
                                acquisitionType: /* Dirty */{
                                  _0: acquisitionTypeResult,
                                  _1: /* Shown */0
                                },
                                endDate: /* Dirty */{
                                  _0: endDateResult,
                                  _1: /* Shown */0
                                },
                                startDate: /* Dirty */{
                                  _0: startDateResult,
                                  _1: /* Shown */0
                                },
                                margin: /* Dirty */{
                                  _0: marginResult,
                                  _1: /* Shown */0
                                },
                                discount: /* Dirty */{
                                  _0: discountResult,
                                  _1: /* Shown */0
                                },
                                categoryCode: /* Dirty */{
                                  _0: categoryCodeResult,
                                  _1: /* Shown */0
                                },
                                version: /* Dirty */{
                                  _0: versionResult,
                                  _1: /* Shown */0
                                },
                                model: /* Dirty */{
                                  _0: modelResult,
                                  _1: /* Shown */0
                                }
                              },
                              collectionsStatuses: undefined
                            };
                    }
                    categoryStartDateResult$1 = categoryStartDateResult;
                  } else {
                    categoryStartDateResult$1 = categoryStartDateResult;
                  }
                } else {
                  categoryStartDateResult$1 = categoryStartDateResult;
                }
              } else {
                categoryStartDateResult$1 = categoryStartDateResult;
              }
            } else {
              categoryStartDateResult$1 = categoryStartDateResult;
            }
          } else {
            categoryStartDateResult$1 = categoryStartDateResult;
          }
        } else {
          categoryStartDateResult$1 = categoryStartDateResult;
        }
      } else {
        categoryStartDateResult$1 = categoryStartDateResult;
      }
    } else {
      categoryStartDateResult$1 = categoryStartDateResult;
    }
  } else {
    categoryStartDateResult$1 = categoryStartDateResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            categoryStartDate: /* Dirty */{
              _0: categoryStartDateResult$1,
              _1: /* Hidden */1
            },
            hasExceptions: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            acquisitionType: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            endDate: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            startDate: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            margin: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            discount: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            categoryCode: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            },
            version: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            },
            model: /* Dirty */{
              _0: match_0$9,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurCategoryStartDateField */0 :
                  var result = Formality.validateFieldOnBlurWithoutValidator(state.input.categoryStartDate, state.fieldsStatuses.categoryStartDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  categoryStartDate: status,
                                  hasExceptions: init.hasExceptions,
                                  acquisitionType: init.acquisitionType,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  margin: init.margin,
                                  discount: init.discount,
                                  categoryCode: init.categoryCode,
                                  version: init.version,
                                  model: init.model
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurHasExceptionsField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.hasExceptions, validators_hasExceptions, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  categoryStartDate: init.categoryStartDate,
                                  hasExceptions: status,
                                  acquisitionType: init.acquisitionType,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  margin: init.margin,
                                  discount: init.discount,
                                  categoryCode: init.categoryCode,
                                  version: init.version,
                                  model: init.model
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAcquisitionTypeField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.acquisitionType, validators_acquisitionType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  categoryStartDate: init.categoryStartDate,
                                  hasExceptions: init.hasExceptions,
                                  acquisitionType: status,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  margin: init.margin,
                                  discount: init.discount,
                                  categoryCode: init.categoryCode,
                                  version: init.version,
                                  model: init.model
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEndDateField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.endDate, validators_endDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  categoryStartDate: init.categoryStartDate,
                                  hasExceptions: init.hasExceptions,
                                  acquisitionType: init.acquisitionType,
                                  endDate: status,
                                  startDate: init.startDate,
                                  margin: init.margin,
                                  discount: init.discount,
                                  categoryCode: init.categoryCode,
                                  version: init.version,
                                  model: init.model
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurStartDateField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.startDate, validators_startDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  categoryStartDate: init.categoryStartDate,
                                  hasExceptions: init.hasExceptions,
                                  acquisitionType: init.acquisitionType,
                                  endDate: init.endDate,
                                  startDate: status,
                                  margin: init.margin,
                                  discount: init.discount,
                                  categoryCode: init.categoryCode,
                                  version: init.version,
                                  model: init.model
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurMarginField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.margin, validators_margin, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  categoryStartDate: init.categoryStartDate,
                                  hasExceptions: init.hasExceptions,
                                  acquisitionType: init.acquisitionType,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  margin: status,
                                  discount: init.discount,
                                  categoryCode: init.categoryCode,
                                  version: init.version,
                                  model: init.model
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDiscountField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.discount, validators_discount, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  categoryStartDate: init.categoryStartDate,
                                  hasExceptions: init.hasExceptions,
                                  acquisitionType: init.acquisitionType,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  margin: init.margin,
                                  discount: status,
                                  categoryCode: init.categoryCode,
                                  version: init.version,
                                  model: init.model
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCategoryCodeField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.categoryCode, validators_categoryCode, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  categoryStartDate: init.categoryStartDate,
                                  hasExceptions: init.hasExceptions,
                                  acquisitionType: init.acquisitionType,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  margin: init.margin,
                                  discount: init.discount,
                                  categoryCode: status,
                                  version: init.version,
                                  model: init.model
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.version, validators_version, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  categoryStartDate: init.categoryStartDate,
                                  hasExceptions: init.hasExceptions,
                                  acquisitionType: init.acquisitionType,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  margin: init.margin,
                                  discount: init.discount,
                                  categoryCode: init.categoryCode,
                                  version: status,
                                  model: init.model
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurModelField */9 :
                  var result$9 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.model, validators_model, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  categoryStartDate: init.categoryStartDate,
                                  hasExceptions: init.hasExceptions,
                                  acquisitionType: init.acquisitionType,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  margin: init.margin,
                                  discount: init.discount,
                                  categoryCode: init.categoryCode,
                                  version: init.version,
                                  model: status
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */10 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */10,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */11,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */13);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */12);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */11 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */12 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */13 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateCategoryStartDateField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput.categoryStartDate, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            categoryStartDate: status,
                                            hasExceptions: init.hasExceptions,
                                            acquisitionType: init.acquisitionType,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            margin: init.margin,
                                            discount: init.discount,
                                            categoryCode: init.categoryCode,
                                            version: init.version,
                                            model: init.model
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateHasExceptionsField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$1, metadata, state.fieldsStatuses.hasExceptions, state.submissionStatus, validators_hasExceptions, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            categoryStartDate: init.categoryStartDate,
                                            hasExceptions: status,
                                            acquisitionType: init.acquisitionType,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            margin: init.margin,
                                            discount: init.discount,
                                            categoryCode: init.categoryCode,
                                            version: init.version,
                                            model: init.model
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAcquisitionTypeField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$2, metadata, state.fieldsStatuses.acquisitionType, state.submissionStatus, validators_acquisitionType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            categoryStartDate: init.categoryStartDate,
                                            hasExceptions: init.hasExceptions,
                                            acquisitionType: status,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            margin: init.margin,
                                            discount: init.discount,
                                            categoryCode: init.categoryCode,
                                            version: init.version,
                                            model: init.model
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEndDateField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$10 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$3, metadata, nextFieldsStatuses.contents.startDate, validators_startDate, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  categoryStartDate: init.categoryStartDate,
                                  hasExceptions: init.hasExceptions,
                                  acquisitionType: init.acquisitionType,
                                  endDate: init.endDate,
                                  startDate: status,
                                  margin: init.margin,
                                  discount: init.discount,
                                  categoryCode: init.categoryCode,
                                  version: init.version,
                                  model: init.model
                                };
                        }));
                  if (result$10 !== undefined) {
                    nextFieldsStatuses.contents = result$10;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$3, metadata, nextFieldsStatuses.contents.endDate, state.submissionStatus, validators_endDate, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            categoryStartDate: init.categoryStartDate,
                                            hasExceptions: init.hasExceptions,
                                            acquisitionType: init.acquisitionType,
                                            endDate: status,
                                            startDate: init.startDate,
                                            margin: init.margin,
                                            discount: init.discount,
                                            categoryCode: init.categoryCode,
                                            version: init.version,
                                            model: init.model
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateStartDateField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$11 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$4, metadata, nextFieldsStatuses$1.contents.endDate, validators_endDate, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  categoryStartDate: init.categoryStartDate,
                                  hasExceptions: init.hasExceptions,
                                  acquisitionType: init.acquisitionType,
                                  endDate: status,
                                  startDate: init.startDate,
                                  margin: init.margin,
                                  discount: init.discount,
                                  categoryCode: init.categoryCode,
                                  version: init.version,
                                  model: init.model
                                };
                        }));
                  if (result$11 !== undefined) {
                    nextFieldsStatuses$1.contents = result$11;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$4, metadata, nextFieldsStatuses$1.contents.startDate, state.submissionStatus, validators_startDate, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            categoryStartDate: init.categoryStartDate,
                                            hasExceptions: init.hasExceptions,
                                            acquisitionType: init.acquisitionType,
                                            endDate: init.endDate,
                                            startDate: status,
                                            margin: init.margin,
                                            discount: init.discount,
                                            categoryCode: init.categoryCode,
                                            version: init.version,
                                            model: init.model
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateMarginField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$5, metadata, state.fieldsStatuses.margin, state.submissionStatus, validators_margin, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            categoryStartDate: init.categoryStartDate,
                                            hasExceptions: init.hasExceptions,
                                            acquisitionType: init.acquisitionType,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            margin: status,
                                            discount: init.discount,
                                            categoryCode: init.categoryCode,
                                            version: init.version,
                                            model: init.model
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDiscountField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$6, metadata, state.fieldsStatuses.discount, state.submissionStatus, validators_discount, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            categoryStartDate: init.categoryStartDate,
                                            hasExceptions: init.hasExceptions,
                                            acquisitionType: init.acquisitionType,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            margin: init.margin,
                                            discount: status,
                                            categoryCode: init.categoryCode,
                                            version: init.version,
                                            model: init.model
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCategoryCodeField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$7, metadata, state.fieldsStatuses.categoryCode, state.submissionStatus, validators_categoryCode, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            categoryStartDate: init.categoryStartDate,
                                            hasExceptions: init.hasExceptions,
                                            acquisitionType: init.acquisitionType,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            margin: init.margin,
                                            discount: init.discount,
                                            categoryCode: status,
                                            version: init.version,
                                            model: init.model
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$2 = {
                    contents: state.fieldsStatuses
                  };
                  var result$12 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$8, metadata, nextFieldsStatuses$2.contents.model, validators_model, (function (status) {
                          var init = nextFieldsStatuses$2.contents;
                          return {
                                  categoryStartDate: init.categoryStartDate,
                                  hasExceptions: init.hasExceptions,
                                  acquisitionType: init.acquisitionType,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  margin: init.margin,
                                  discount: init.discount,
                                  categoryCode: init.categoryCode,
                                  version: init.version,
                                  model: status
                                };
                        }));
                  if (result$12 !== undefined) {
                    nextFieldsStatuses$2.contents = result$12;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$8, metadata, nextFieldsStatuses$2.contents.version, state.submissionStatus, validators_version, (function (status) {
                                    var init = nextFieldsStatuses$2.contents;
                                    return {
                                            categoryStartDate: init.categoryStartDate,
                                            hasExceptions: init.hasExceptions,
                                            acquisitionType: init.acquisitionType,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            margin: init.margin,
                                            discount: init.discount,
                                            categoryCode: init.categoryCode,
                                            version: status,
                                            model: init.model
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateModelField */9 :
                  var nextInput$9 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$3 = {
                    contents: state.fieldsStatuses
                  };
                  var result$13 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$9, metadata, nextFieldsStatuses$3.contents.version, validators_version, (function (status) {
                          var init = nextFieldsStatuses$3.contents;
                          return {
                                  categoryStartDate: init.categoryStartDate,
                                  hasExceptions: init.hasExceptions,
                                  acquisitionType: init.acquisitionType,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  margin: init.margin,
                                  discount: init.discount,
                                  categoryCode: init.categoryCode,
                                  version: status,
                                  model: init.model
                                };
                        }));
                  if (result$13 !== undefined) {
                    nextFieldsStatuses$3.contents = result$13;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$9, metadata, nextFieldsStatuses$3.contents.model, state.submissionStatus, validators_model, (function (status) {
                                    var init = nextFieldsStatuses$3.contents;
                                    return {
                                            categoryStartDate: init.categoryStartDate,
                                            hasExceptions: init.hasExceptions,
                                            acquisitionType: init.acquisitionType,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            margin: init.margin,
                                            discount: init.discount,
                                            categoryCode: init.categoryCode,
                                            version: init.version,
                                            model: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */10 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */11 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */12 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateCategoryStartDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCategoryStartDateField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateHasExceptions: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateHasExceptionsField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateAcquisitionType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAcquisitionTypeField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEndDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEndDateField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateStartDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateStartDateField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateMargin: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateMarginField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDiscount: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDiscountField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCategoryCode: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCategoryCodeField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVersion: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateModel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurCategoryStartDate: (function (param) {
              Curry._1(dispatch, /* BlurCategoryStartDateField */0);
            }),
          blurHasExceptions: (function (param) {
              Curry._1(dispatch, /* BlurHasExceptionsField */1);
            }),
          blurAcquisitionType: (function (param) {
              Curry._1(dispatch, /* BlurAcquisitionTypeField */2);
            }),
          blurEndDate: (function (param) {
              Curry._1(dispatch, /* BlurEndDateField */3);
            }),
          blurStartDate: (function (param) {
              Curry._1(dispatch, /* BlurStartDateField */4);
            }),
          blurMargin: (function (param) {
              Curry._1(dispatch, /* BlurMarginField */5);
            }),
          blurDiscount: (function (param) {
              Curry._1(dispatch, /* BlurDiscountField */6);
            }),
          blurCategoryCode: (function (param) {
              Curry._1(dispatch, /* BlurCategoryCodeField */7);
            }),
          blurVersion: (function (param) {
              Curry._1(dispatch, /* BlurVersionField */8);
            }),
          blurModel: (function (param) {
              Curry._1(dispatch, /* BlurModelField */9);
            }),
          categoryStartDateResult: Formality.exposeFieldResult(state.fieldsStatuses.categoryStartDate),
          hasExceptionsResult: Formality.exposeFieldResult(state.fieldsStatuses.hasExceptions),
          acquisitionTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.acquisitionType),
          endDateResult: Formality.exposeFieldResult(state.fieldsStatuses.endDate),
          startDateResult: Formality.exposeFieldResult(state.fieldsStatuses.startDate),
          marginResult: Formality.exposeFieldResult(state.fieldsStatuses.margin),
          discountResult: Formality.exposeFieldResult(state.fieldsStatuses.discount),
          categoryCodeResult: Formality.exposeFieldResult(state.fieldsStatuses.categoryCode),
          versionResult: Formality.exposeFieldResult(state.fieldsStatuses.version),
          modelResult: Formality.exposeFieldResult(state.fieldsStatuses.model),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.categoryStartDate || match.hasExceptions || match.acquisitionType || match.endDate || match.startDate || match.margin || match.discount || match.categoryCode || match.version || match.model) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */10);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */11);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */12);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */12,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */13);
            })
        };
}

var Form = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var Types;

var initialInput = {
  model: "",
  version: "",
  categoryCode: "",
  discount: undefined,
  margin: undefined,
  startDate: undefined,
  endDate: undefined,
  acquisitionType: "",
  hasExceptions: false,
  categoryStartDate: ""
};

export {
  Types ,
  validateRequiredDateCompareTest ,
  Form ,
  initialInput ,
}
/* react Not a pure module */
