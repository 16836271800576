// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_AutocompleteFormInput.bs.js";

function Order_Search_Modal_Car_ModelVersionInput(Props) {
  var allModelVersions = Props.allModelVersions;
  var modelVersions = Props.modelVersions;
  var form = Props.form;
  var motorizations = Props.motorizations;
  var motorizationsString = Props.motorizationsString;
  var match = React.useState(function () {
        return /* [] */0;
      });
  var setAvailableMotorizations = match[1];
  var availableMotorizations = match[0];
  var childOptions = function (param) {
    return Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.flatten(Belt_List.map(Belt_List.keep(Belt_List.keep(allModelVersions, (function (x) {
                                          return App_Types_Result$DvmAdminFrontendFr.mapWithDefault(modelVersions, true, (function (modelVersions) {
                                                        return Belt_List.has(modelVersions, x.parent, (function (a, b) {
                                                                      return a === b;
                                                                    }));
                                                      }));
                                        })), (function (x) {
                                      if (Belt_List.length(form.input.modelID) > 0) {
                                        return Belt_List.has(form.input.modelID, x.parent, (function (a, b) {
                                                      return a === b;
                                                    }));
                                      } else {
                                        return true;
                                      }
                                    })), (function (x) {
                                  return x.child;
                                }))))));
  };
  React.useEffect((function () {
          Curry._1(setAvailableMotorizations, (function (param) {
                  if (Belt_List.length(form.input.modelID) > 0) {
                    return Belt_List.sort(Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.keep(Belt_List.concatMany(Belt_List.toArray(Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(motorizations, /* [] */0), (function (x) {
                                                              return x.child;
                                                            })))), (function (x) {
                                                  return x.length > 0;
                                                }))))), Caml.string_compare);
                  } else {
                    return Belt_List.sort(Belt_List.keep(App_Types_Result$DvmAdminFrontendFr.getWithDefault(motorizationsString, /* [] */0), (function (x) {
                                      return x.length > 0;
                                    })), Caml.string_compare);
                  }
                }));
        }), [
        motorizationsString,
        motorizations,
        form.input.modelID,
        form.input.brand,
        form.input.vehicleType
      ]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.mapWithDefault(modelVersions, undefined, (function (modelVersions) {
                  if (!(form.input.brand.length > 0 || form.input.vehicleType === "VP" || form.input.vehicleType === "VU")) {
                    return ;
                  }
                  var toKeep = Belt_List.keep(form.input.modelID, (function (x) {
                          return Belt_List.has(modelVersions, x, (function (a, b) {
                                        return a === b;
                                      }));
                        }));
                  Curry._2(form.updateModelID, (function (input, modelID) {
                          var newrecord = Caml_obj.obj_dup(input);
                          newrecord.modelID = modelID;
                          return newrecord;
                        }), toKeep);
                }));
        }), [modelVersions]);
  React.useEffect((function () {
          if (App_Types_Result$DvmAdminFrontendFr.isSuccess(modelVersions) && Belt_List.length(allModelVersions) > 0) {
            var toKeep = Belt_List.keep(form.input.versionID, (function (x) {
                    return Belt_List.has(childOptions(undefined), x, (function (a, b) {
                                  return a === b;
                                }));
                  }));
            Curry._2(form.updateVersionID, (function (input, versionID) {
                    var newrecord = Caml_obj.obj_dup(input);
                    newrecord.versionID = versionID;
                    return newrecord;
                  }), toKeep);
          }
          
        }), [
        modelVersions,
        form.input.modelID
      ]);
  React.useEffect((function () {
          if (App_Types_Result$DvmAdminFrontendFr.isSuccess(motorizations) && App_Types_Result$DvmAdminFrontendFr.isSuccess(motorizationsString)) {
            var toKeep = Belt_List.keep(form.input.engineType, (function (x) {
                    return Belt_List.has(availableMotorizations, x, (function (a, b) {
                                  return a === b;
                                }));
                  }));
            Curry._2(form.updateEngineType, (function (input, engineType) {
                    var newrecord = Caml_obj.obj_dup(input);
                    newrecord.engineType = engineType;
                    return newrecord;
                  }), toKeep);
          }
          
        }), [availableMotorizations]);
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                        label: Messages_Common$DvmAdminFrontendFr.model,
                        value: {
                          TAG: /* Multiple */1,
                          _0: {
                            value: form.input.modelID,
                            onChange: Curry._1(form.updateModelID, (function (input, modelID) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.modelID = modelID;
                                    return newrecord;
                                  }))
                          }
                        },
                        options: {
                          TAG: /* Unlabeled */0,
                          _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(modelVersions, /* [] */0)
                        },
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                        smallChips: true
                      }),
                  item: true,
                  sm: Grid$Mui.Sm[9]
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                        label: Messages_Common$DvmAdminFrontendFr.phase,
                        value: {
                          TAG: /* Single */0,
                          _0: {
                            value: form.input.phase,
                            onChange: Curry._1(form.updatePhase, (function (input, phase) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.phase = phase;
                                    return newrecord;
                                  }))
                          }
                        },
                        options: {
                          TAG: /* Unlabeled */0,
                          _0: {
                            hd: "1",
                            tl: {
                              hd: "2",
                              tl: /* [] */0
                            }
                          }
                        },
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                      }),
                  item: true,
                  sm: Grid$Mui.Sm[3]
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                        show: App_Types_Result$DvmAdminFrontendFr.isPending(motorizations) || App_Types_Result$DvmAdminFrontendFr.isPending(motorizationsString),
                        children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                              label: Messages_Flash_Edit$DvmAdminFrontendFr.energyDropdown,
                              value: {
                                TAG: /* Multiple */1,
                                _0: {
                                  value: form.input.engineType,
                                  onChange: Curry._1(form.updateEngineType, (function (input, engineType) {
                                          var newrecord = Caml_obj.obj_dup(input);
                                          newrecord.engineType = engineType;
                                          return newrecord;
                                        }))
                                }
                              },
                              options: {
                                TAG: /* Unlabeled */0,
                                _0: availableMotorizations
                              },
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                              smallChips: true
                            })
                      }),
                  item: true,
                  sm: Grid$Mui.Sm[12]
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                        label: Messages_Common$DvmAdminFrontendFr.version,
                        value: {
                          TAG: /* Multiple */1,
                          _0: {
                            value: form.input.versionID,
                            onChange: Curry._1(form.updateVersionID, (function (input, versionID) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.versionID = versionID;
                                    return newrecord;
                                  }))
                          }
                        },
                        options: {
                          TAG: /* Unlabeled */0,
                          _0: childOptions(undefined)
                        },
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                        smallChips: true
                      }),
                  item: true,
                  sm: Grid$Mui.Sm[12]
                }));
}

var InputStyles;

var make = Order_Search_Modal_Car_ModelVersionInput;

export {
  InputStyles ,
  make ,
}
/* react Not a pure module */
