// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../common/components/button/App_Button.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as Contract_Edit_Api$DvmAdminFrontendFr from "../../Contract_Edit_Api.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../common/components/App_OverlaySpinner.bs.js";
import * as App_UserTypeCheckBox$DvmAdminFrontendFr from "../../../../../common/components/input/App_UserTypeCheckBox.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../../common/components/input/App_DateInputFormField.bs.js";
import * as Messages_Contract_Create$DvmAdminFrontendFr from "../../../../../intl/messages/contract/Messages_Contract_Create.bs.js";
import * as App_DatesEdit_DateWithCheckbox$DvmAdminFrontendFr from "../../../../../common/components/dates/App_DatesEdit_DateWithCheckbox.bs.js";
import * as Contract_Attachment_Single_Form$DvmAdminFrontendFr from "./Contract_Attachment_Single_Form.bs.js";

function Contract_Attachment_Single_Modal(Props) {
  var openOpt = Props.open;
  var originalStartDate = Props.originalStartDate;
  var originalEndDate = Props.originalEndDate;
  var parentStartDate = Props.parentStartDate;
  var parentEndDate = Props.parentEndDate;
  var titleOpt = Props.title;
  var asParentLabel = Props.asParentLabel;
  var showSpinnerOpt = Props.showSpinner;
  var onClose = Props.onClose;
  var onSubmit = Props.onSubmit;
  var blockOldStartDateOpt = Props.blockOldStartDate;
  var siren = Props.siren;
  var contract = Props.contract;
  var open = openOpt !== undefined ? openOpt : true;
  var title = titleOpt !== undefined ? titleOpt : Messages_Common$DvmAdminFrontendFr.editDates;
  var showSpinner = showSpinnerOpt !== undefined ? showSpinnerOpt : false;
  var blockOldStartDate = blockOldStartDateOpt !== undefined ? blockOldStartDateOpt : true;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setCheckSiren = match$2[1];
  var checkSiren = match$2[0];
  var form = Contract_Attachment_Single_Form$DvmAdminFrontendFr.DatesEditForm.useForm(Contract_Attachment_Single_Form$DvmAdminFrontendFr.initialInput, {
        parentStartDate: parentStartDate,
        parentEndDate: parentEndDate
      }, Curry.__2(onSubmit));
  React.useEffect((function () {
          Curry._2(form.updateStartDate, (function (input, startDate) {
                  return {
                          startDate: startDate,
                          endDate: input.endDate,
                          endDateAsParent: input.endDateAsParent
                        };
                }), originalStartDate);
          Curry._2(form.updateEndDate, (function (input, endDate) {
                  return {
                          startDate: input.startDate,
                          endDate: endDate,
                          endDateAsParent: input.endDateAsParent
                        };
                }), originalEndDate);
          if (Belt_Option.isNone(originalEndDate)) {
            Curry._2(form.updateEndDateAsParent, (function (input, endDateAsParent) {
                    return {
                            startDate: input.startDate,
                            endDate: input.endDate,
                            endDateAsParent: endDateAsParent
                          };
                  }), true);
          }
          
        }), [
        originalStartDate,
        originalEndDate
      ]);
  React.useEffect((function () {
          var start = form.startDateResult;
          if (start !== undefined && Belt_Result.isOk(start) && (
              form.input.endDateAsParent ? true : Belt_Option.mapWithDefault(form.endDateResult, false, Belt_Result.isOk)
            )) {
            Contract_Edit_Api$DvmAdminFrontendFr.checkIfCanAddSiren(siren, form.input.startDate, form.input.endDateAsParent ? contract.endDate : form.input.endDate, setCheckSiren, userManager, contract.contractID, selectedRole, "edit", undefined);
          }
          
        }), [
        form.input.startDate,
        form.input.endDate,
        form.input.endDateAsParent
      ]);
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    startDate: startDate,
                    endDate: input.endDate,
                    endDateAsParent: input.endDateAsParent
                  };
          })),
    disabled: blockOldStartDate ? Belt_Option.mapWithDefault(originalStartDate, false, ReDate.isPast) : false,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
    onBlur: (function (param) {
        Curry._1(form.blurStartDate, undefined);
      })
  };
  if (form.startDateResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  var tmp$1 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    startDate: input.startDate,
                    endDate: endDate,
                    endDateAsParent: input.endDateAsParent
                  };
          })),
    disabled: form.input.endDateAsParent,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
    onBlur: (function (param) {
        Curry._1(form.blurEndDate, undefined);
      })
  };
  if (form.endDateResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: open,
              onClose: onClose,
              title: intl.formatMessage(title),
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: showSpinner,
                    children: React.createElement("form", {
                          onSubmit: (function ($$event) {
                              $$event.preventDefault();
                              Curry._1(form.submit, undefined);
                            })
                        }, React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              direction: "column",
                              style: {
                                gridRowGap: "24px"
                              }
                            }, React.createElement(Core.Grid, {
                                  children: React.createElement(Core.FormLabel, {
                                        children: siren
                                      }),
                                  item: true
                                }), React.createElement(Core.Grid, {
                                  children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp),
                                  item: true
                                }), React.createElement(Core.Grid, {
                                  children: React.createElement(App_DatesEdit_DateWithCheckbox$DvmAdminFrontendFr.make, {
                                        checkboxElement: React.createElement(App_UserTypeCheckBox$DvmAdminFrontendFr.make, {
                                              label: intl.formatMessage(asParentLabel),
                                              checked: form.input.endDateAsParent,
                                              onClick: (function (param) {
                                                  Curry._2(form.updateEndDateAsParent, (function (input, endDateAsParent) {
                                                          return {
                                                                  startDate: input.startDate,
                                                                  endDate: input.endDate,
                                                                  endDateAsParent: endDateAsParent
                                                                };
                                                        }), !form.input.endDateAsParent);
                                                })
                                            }),
                                        children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$1)
                                      }),
                                  item: true
                                }), App_Types_Result$DvmAdminFrontendFr.isError(checkSiren) ? React.createElement(Core.Grid, {
                                    children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                          color: "error",
                                          style: {
                                            fontWeight: "600"
                                          },
                                          children: intl.formatMessage(Messages_Contract_Create$DvmAdminFrontendFr.attachmentOverlapError),
                                          variant: "body"
                                        }),
                                    item: true
                                  }) : null, React.createElement(Core.Grid, {
                                  children: React.createElement(Core.Grid, {
                                        children: React.createElement(Core.Grid, {
                                              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                                    show: App_Types_Result$DvmAdminFrontendFr.isPending(checkSiren),
                                                    children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                          label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                                          size: "medium",
                                                          type_: "submit"
                                                        })
                                                  }),
                                              item: true
                                            }),
                                        container: true,
                                        justify: "flex-end"
                                      }),
                                  item: true
                                })))
                  })
            });
}

var Form;

var DateWithCheckbox;

var Api;

var InputStyles;

var make = Contract_Attachment_Single_Modal;

export {
  Form ,
  DateWithCheckbox ,
  Api ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
