// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as App_Table$DvmAdminFrontendFr from "../../../common/components/table/App_Table.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../intl/Intl_Utils.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Pagination.bs.js";
import * as App_Table_Export_Menu$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Export_Menu.bs.js";
import * as Messages_PCDAgreement$DvmAdminFrontendFr from "../../../intl/messages/pcdAgreement/Messages_PCDAgreement.bs.js";
import * as PCDAgreement_Delete_Modal$DvmAdminFrontendFr from "../modal/PCDAgreement_Delete_Modal.bs.js";
import * as PCDAgreement_Search_Types$DvmAdminFrontendFr from "./PCDAgreement_Search_Types.bs.js";
import * as PCDAgreement_Update_Modal$DvmAdminFrontendFr from "../modal/PCDAgreement_Update_Modal.bs.js";

var popMenuItem = Css.css(Object.assign({}, {
          backgroundColor: Theme_Colors$DvmAdminFrontendFr.black,
          color: Theme_Colors$DvmAdminFrontendFr.white
        }, {
          "&:hover": {
            backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayDark4
          }
        }));

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(PCDAgreement_Search_Types$DvmAdminFrontendFr.Fields.dealerID, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.dealerID);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(PCDAgreement_Search_Types$DvmAdminFrontendFr.Fields.siren, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.siren),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(PCDAgreement_Search_Types$DvmAdminFrontendFr.Fields.modelID, undefined, undefined, undefined, undefined, undefined, Messages_PCDAgreement$DvmAdminFrontendFr.modelID),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(PCDAgreement_Search_Types$DvmAdminFrontendFr.Fields.categoryID, undefined, undefined, undefined, undefined, undefined, Messages_PCDAgreement$DvmAdminFrontendFr.categoryIDShort),
      tl: {
        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(PCDAgreement_Search_Types$DvmAdminFrontendFr.Fields.acquisitionType, undefined, undefined, undefined, undefined, undefined, Messages_PCDAgreement$DvmAdminFrontendFr.acquisitionType),
        tl: {
          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(PCDAgreement_Search_Types$DvmAdminFrontendFr.Fields.contractID, undefined, undefined, undefined, undefined, undefined, Messages_PCDAgreement$DvmAdminFrontendFr.contractID),
          tl: {
            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(PCDAgreement_Search_Types$DvmAdminFrontendFr.Fields.avsMarginGridId, undefined, undefined, undefined, undefined, undefined, Messages_PCDAgreement$DvmAdminFrontendFr.avsMarginGridId),
            tl: {
              hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(PCDAgreement_Search_Types$DvmAdminFrontendFr.Fields.companyDiscountPercentage, undefined, undefined, "right", undefined, undefined, Messages_PCDAgreement$DvmAdminFrontendFr.companyDiscountPercentage),
              tl: {
                hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(PCDAgreement_Search_Types$DvmAdminFrontendFr.Fields.volume, undefined, undefined, "right", undefined, undefined, Messages_PCDAgreement$DvmAdminFrontendFr.volume),
                tl: {
                  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(PCDAgreement_Search_Types$DvmAdminFrontendFr.Fields.responseNumber, undefined, undefined, undefined, undefined, undefined, Messages_PCDAgreement$DvmAdminFrontendFr.responseNumber),
                  tl: {
                    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(PCDAgreement_Search_Types$DvmAdminFrontendFr.Fields.yearBudget, /* Desc */1, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.year),
                    tl: {
                      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(PCDAgreement_Search_Types$DvmAdminFrontendFr.Fields.processingDate, undefined, undefined, undefined, undefined, undefined, Messages_PCDAgreement$DvmAdminFrontendFr.processingDate),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function strNoneToDash(str) {
  switch (str) {
    case "" :
    case "NONE" :
        return "-";
    default:
      return str;
  }
}

function PCDAgreement_Search_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var operation = Props.operation;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = React.useState(function () {
        return null;
      });
  var setModal = match$1[1];
  var onCloseModal = function (param) {
    Curry._1(setModal, (function (param) {
            return null;
          }));
    Curry._2(fetchData, undefined, fetchRequest);
  };
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (row) {
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, operation === /* Manage */1 ? Caml_option.some(React.createElement(Core.Grid, {
                                                        children: null,
                                                        container: true,
                                                        justifyContent: "flex-start",
                                                        wrap: "nowrap"
                                                      }, React.createElement(Core.Grid, {
                                                            children: React.createElement(Core.Button, {
                                                                  onClick: (function (param) {
                                                                      Curry._1(setModal, (function (param) {
                                                                              return React.createElement(PCDAgreement_Update_Modal$DvmAdminFrontendFr.make, {
                                                                                          onClose: (function (param) {
                                                                                              onCloseModal(undefined);
                                                                                            }),
                                                                                          row: row,
                                                                                          onOk: (function (param) {
                                                                                              onCloseModal(undefined);
                                                                                            })
                                                                                        });
                                                                            }));
                                                                    }),
                                                                  children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                        size: /* Large */3,
                                                                        type_: /* Edit */60
                                                                      }),
                                                                  key: "edit"
                                                                }),
                                                            item: true
                                                          }), React.createElement(Core.Grid, {
                                                            children: React.createElement(Core.Button, {
                                                                  onClick: (function (param) {
                                                                      Curry._1(setModal, (function (param) {
                                                                              return React.createElement(PCDAgreement_Delete_Modal$DvmAdminFrontendFr.make, {
                                                                                          onClose: (function (param) {
                                                                                              onCloseModal(undefined);
                                                                                            }),
                                                                                          row: row,
                                                                                          onOk: (function (param) {
                                                                                              onCloseModal(undefined);
                                                                                            })
                                                                                        });
                                                                            }));
                                                                    }),
                                                                  children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                        size: /* Large */3,
                                                                        type_: /* Trash */38,
                                                                        color: "red"
                                                                      }),
                                                                  key: "delete"
                                                                }),
                                                            item: true
                                                          }))) : undefined, undefined, Belt_List.mapWithIndex({
                                                  hd: row.dealerID,
                                                  tl: {
                                                    hd: strNoneToDash(Belt_Option.getWithDefault(row.siren, "")),
                                                    tl: {
                                                      hd: strNoneToDash(Belt_Option.getWithDefault(row.modelID, "")),
                                                      tl: {
                                                        hd: strNoneToDash(Belt_Option.getWithDefault(row.categoryID, "")),
                                                        tl: {
                                                          hd: strNoneToDash(Belt_Option.getWithDefault(row.acquisitionType, "")),
                                                          tl: {
                                                            hd: strNoneToDash(Belt_Option.getWithDefault(row.contractID, "")),
                                                            tl: {
                                                              hd: strNoneToDash(Belt_Option.getWithDefault(row.avsMarginGridId, "")),
                                                              tl: {
                                                                hd: Belt_Option.mapWithDefault(row.companyDiscountPercentage, "", (function (param) {
                                                                        return Intl_Utils$DvmAdminFrontendFr.formatPercent(intl, param);
                                                                      })),
                                                                tl: {
                                                                  hd: Belt_Option.mapWithDefault(row.volume, "", (function (x) {
                                                                          if (x < 0) {
                                                                            return intl.formatMessage(Messages_PCDAgreement$DvmAdminFrontendFr.unlimited);
                                                                          } else {
                                                                            return String(x);
                                                                          }
                                                                        })),
                                                                  tl: {
                                                                    hd: Belt_Option.getWithDefault(row.responseNumber, ""),
                                                                    tl: {
                                                                      hd: Belt_Option.mapWithDefault(row.yearBudget, "", (function (prim) {
                                                                              return String(prim);
                                                                            })),
                                                                      tl: {
                                                                        hd: Belt_Option.getWithDefault(row.processingDate, ""),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, Caml_option.some(Belt_Option.getWithDefault(row.isReplaced, false) && Belt_Option.mapWithDefault(row.volume, false, (function (x) {
                                                                          return x < 0;
                                                                        })) ? Theme_Colors$DvmAdminFrontendFr.accentRedHover : undefined), content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._2(fetchData, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._2(fetchData, undefined, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var tmp = {
    headers: fetchRequest.headers,
    handleSorting: handleSorting,
    tablePage: convertTable(tablePage),
    paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined),
    exportMenu: React.createElement(App_Table_Export_Menu$DvmAdminFrontendFr.make, {
          query: Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                page: 0,
                rowsPerPage: 150000,
                headers: fetchRequest.headers,
                filterQueryParams: fetchRequest.filterQueryParams,
                additionalQueryParams: fetchRequest.additionalQueryParams
              }),
          endpoint: "agreements"
        })
  };
  var tmp$1 = User_Types_Utils$DvmAdminFrontendFr.isAdmin(match[0].selectedRole) && operation === /* Manage */1 ? ({
        TAG: /* Left */0,
        _0: /* EmptyCell */0
      }) : undefined;
  if (tmp$1 !== undefined) {
    tmp.rowButton = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, tmp), match$1[0]);
}

var make = PCDAgreement_Search_Table;

export {
  popMenuItem ,
  headersConfiguration ,
  strNoneToDash ,
  make ,
}
/* popMenuItem Not a pure module */
