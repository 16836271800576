// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Table$DvmAdminFrontendFr from "../../../../common/components/table/App_Table.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Pagination.bs.js";
import * as Company_Detail_Types$DvmAdminFrontendFr from "../Company_Detail_Types.bs.js";
import * as Messages_Company_Detail$DvmAdminFrontendFr from "../../../../intl/messages/company/Messages_Company_Detail.bs.js";

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Company_Detail_Types$DvmAdminFrontendFr.Fields.siren, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.siren);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Company_Detail_Types$DvmAdminFrontendFr.Fields.companyName, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.companyName),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Company_Detail_Types$DvmAdminFrontendFr.Fields.budgetType, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.budget),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Company_Detail_Types$DvmAdminFrontendFr.Fields.contractID, undefined, undefined, undefined, undefined, undefined, Messages_Company_Detail$DvmAdminFrontendFr.contractID),
      tl: {
        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Company_Detail_Types$DvmAdminFrontendFr.Fields.startDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.startDate),
        tl: {
          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Company_Detail_Types$DvmAdminFrontendFr.Fields.endDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.endDate),
          tl: /* [] */0
        }
      }
    }
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function Company_Detail_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var intl = ReactIntl.useIntl();
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (row) {
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, undefined, undefined, Belt_List.mapWithIndex({
                                                  hd: row.siren,
                                                  tl: {
                                                    hd: Belt_Option.getWithDefault(row.companyName, ""),
                                                    tl: {
                                                      hd: Belt_Option.getWithDefault(row.budget, ""),
                                                      tl: {
                                                        hd: Belt_Option.getWithDefault(row.contractID, ""),
                                                        tl: {
                                                          hd: Belt_Option.mapWithDefault(row.startDate, "", (function (param) {
                                                                  return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                })),
                                                          tl: {
                                                            hd: Belt_Option.mapWithDefault(row.endDate, "", (function (param) {
                                                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                  })),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._2(fetchData, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._2(fetchData, undefined, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, {
                  headers: fetchRequest.headers,
                  handleSorting: handleSorting,
                  tablePage: convertTable(tablePage),
                  paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(/* BOTTOM */1, 10, handleChangePage, handleRowsPerPageChange, undefined)
                }));
}

var make = Company_Detail_Table;

export {
  headersConfiguration ,
  make ,
}
/* headersConfiguration Not a pure module */
