// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as FileSaver from "file-saver";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../../common/utils/Utils_Date.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../../../common/types/App_Types_Input.bs.js";
import * as Quota_PARC_Manage_Types$DvmAdminFrontendFr from "./Quota_PARC_Manage_Types.bs.js";
import * as Quota_PARC_Manage_Utils$DvmAdminFrontendFr from "./Quota_PARC_Manage_Utils.bs.js";

function requestText_encode(v) {
  return Js_dict.fromArray([[
                "requestText",
                Decco.stringToJson(v.requestText)
              ]]);
}

function requestText_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var requestText = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "requestText"), null));
  if (requestText.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              requestText: requestText._0
            }
          };
  }
  var e = requestText._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".requestText" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function savePARCQuota(operation, userManager, setResult, submissionCallbacks, selectedRole, id, conflicts, quota) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var quotaFromOutput = function (operation) {
    return {
            id: id,
            orderType: quota.orderType,
            dealerId: quota.dealerId,
            modelId: quota.modelId,
            phase: quota.phase,
            engineType: quota.engineType,
            volumeInitial: operation ? undefined : quota.volume,
            volume: quota.volume,
            startDate: quota.startDate,
            endDate: quota.endDate
          };
  };
  var saveQuotas = function (operation) {
    RestApi$DvmAdminFrontendFr.WithEmptyResponse.call(operation ? "PUT" : "POST", {
            TAG: /* Json */0,
            _0: Quota_PARC_Manage_Types$DvmAdminFrontendFr.quotaPARC_encode(quotaFromOutput(operation))
          }, userManager, selectedRole, "/parc/quotas").then(function (result) {
          if (result.TAG === /* OkResult */0) {
            Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
            return Curry._1(setResult, (function (param) {
                          return {
                                  TAG: /* Ok */0,
                                  _0: undefined
                                };
                        }));
          }
          var error = result._0;
          Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToSaveQuota */0);
          Curry._1(setResult, (function (param) {
                  return {
                          TAG: /* Error */1,
                          _0: error
                        };
                }));
        });
  };
  var cleanedConflicts;
  if (typeof conflicts === "number" || conflicts.TAG !== /* Ok */0) {
    cleanedConflicts = /* [] */0;
  } else {
    var cleanedConflicts$1 = Quota_PARC_Manage_Utils$DvmAdminFrontendFr.conflictsCleanOutput(conflicts._0, quota, operation);
    cleanedConflicts = Belt_List.keepMap(cleanedConflicts$1, (function (x) {
            return x.id;
          }));
  }
  if (Belt_List.length(cleanedConflicts) > 0) {
    RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", {
            TAG: /* Json */0,
            _0: requestText_encode({
                  requestText: Belt_Array.joinWith(Belt_List.toArray(cleanedConflicts), "\\,", (function (x) {
                          return x;
                        }))
                })
          }, userManager, selectedRole, "/parc/quotas/ids").then(function (result) {
          if (result.TAG === /* OkResult */0) {
            var tmp;
            tmp = typeof conflicts === "number" || !(conflicts.TAG === /* Ok */0 && Belt_List.length(Quota_PARC_Manage_Utils$DvmAdminFrontendFr.conflictsHasSelfOutput(conflicts._0, quota)) > 0) ? /* Create */0 : operation;
            return saveQuotas(tmp);
          }
          var error = result._0;
          Curry._1(setResult, (function (param) {
                  return {
                          TAG: /* Error */1,
                          _0: error
                        };
                }));
        });
    return ;
  } else {
    return saveQuotas(operation);
  }
}

function fetchTemplate(userManager, setResult, selectedRole, alert) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithBlobResponse.call("GET", undefined, userManager, selectedRole, "/parc/quotas/excel/template").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          FileSaver.saveAs(result._0, "fichier_template.xlsx");
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchExcel(userManager, setResult, selectedRole, alert, filename, endpoint) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithBlobResponse.call("GET", undefined, userManager, selectedRole, "/" + endpoint + "/excel?limit=150000&offset=0").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          FileSaver.saveAs(result._0, filename + " ⚠️Restricted B.xlsx");
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function deleteQuota(userManager, setResult, selectedRole, tableRow) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var quotaFromOutput_id = tableRow.id;
  var quotaFromOutput = {
    id: quotaFromOutput_id,
    orderType: undefined,
    dealerId: undefined,
    modelId: undefined,
    phase: undefined,
    engineType: undefined,
    volumeInitial: undefined,
    volume: undefined,
    startDate: undefined,
    endDate: undefined
  };
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", {
          TAG: /* Json */0,
          _0: Quota_PARC_Manage_Types$DvmAdminFrontendFr.quotaPARC_encode(quotaFromOutput)
        }, userManager, selectedRole, "/parc/quotas").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchDealerName(userManager, setResult, selectedRole, dealerId) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.twoParamList_decode, undefined, userManager, selectedRole, "/dealers/location/name?filter=dealerId=" + dealerId + ";agentCode=000").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var dealerName = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: Belt_Option.flatMap(Belt_List.head(dealerName), (function (x) {
                                        return x.paramTwo;
                                      }))
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function getParcQuotaConflicts(userManager, setResult, selectedRole, form) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var date = Utils_Date$DvmAdminFrontendFr.toISODateTimeString(undefined, form.input.startDate);
  var date$1 = Utils_Date$DvmAdminFrontendFr.toISODateTimeString(undefined, form.input.endDate);
  var filters = Belt_Array.joinWith(Belt_List.toArray(Belt_List.keep({
                hd: form.input.dealerId.length > 0 ? "dealerId=" + form.input.dealerId + "" : "",
                tl: {
                  hd: form.input.orderType.length > 0 ? "orderType=" + form.input.orderType + "" : "",
                  tl: {
                    hd: form.input.modelId.length > 0 ? "modelId=" + form.input.modelId + "" : "",
                    tl: {
                      hd: form.input.phase.length > 0 ? "phase=" + form.input.phase + "" : "",
                      tl: {
                        hd: form.input.engineType.length > 0 ? "engineType=" + form.input.engineType + "" : "",
                        tl: {
                          hd: date !== undefined && date.length !== 0 ? "startDate=" + date + "" : "",
                          tl: {
                            hd: date$1 !== undefined && date$1.length !== 0 ? "endDate=" + date$1 + "" : "",
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }, (function (x) {
                  return x.length > 0;
                }))), "&", (function (x) {
          return x;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Quota_PARC_Manage_Types$DvmAdminFrontendFr.quotaPARCList_decode, undefined, userManager, selectedRole, "/parc/quotas/conflicts" + (
          filters.length > 0 ? "?" + filters : ""
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var overlaps = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: overlaps
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

export {
  Form ,
  requestText_encode ,
  requestText_decode ,
  savePARCQuota ,
  fetchTemplate ,
  fetchExcel ,
  deleteQuota ,
  fetchDealerName ,
  getParcQuotaConflicts ,
}
/* react Not a pure module */
