// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as RestApi$DvmAdminFrontendFr from "../../api/RestApi.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../common/components/icons/App_Icon.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../route/Route_Paths.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../context/user/User_Context.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../context/router/Context_Router.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../common/styles/App_Styles_Common.bs.js";
import * as Model_Version_Api$DvmAdminFrontendFr from "./Model_Version_Api.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../common/components/App_OverlaySpinner.bs.js";
import * as Model_Version_Form$DvmAdminFrontendFr from "./Model_Version_Form.bs.js";
import * as Messages_ModelVersion$DvmAdminFrontendFr from "../../intl/messages/modelVersion/Messages_ModelVersion.bs.js";

function dismissMessage(userManager, setResult, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", undefined, userManager, selectedRole, "/modelversion/noted").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function isNoted_encode(v) {
  return Js_dict.fromArray([[
                "response",
                Decco.boolToJson(v.response)
              ]]);
}

function isNoted_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var response = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "response"), null));
  if (response.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              response: response._0
            }
          };
  }
  var e = response._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".response" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function fetchIfNoted(userManager, setResult, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", isNoted_decode, undefined, userManager, selectedRole, "/modelversion/noted").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function Model_Version_NewVersions_Menu_Icon(Props) {
  var alert = ReactAlert.useAlert();
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setNotedResult = match[1];
  var notedResult = match[0];
  var match$1 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDismissResult = match$1[1];
  var dismissResult = match$1[0];
  var match$2 = React.useState(function () {
        return {
                TAG: /* ModelPage */0,
                _0: /* NotStarted */0
              };
      });
  var setTablePage = match$2[1];
  var tablePage = match$2[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match$3 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$4 = match$3[0];
  var userManager = match$4.userManager;
  var selectedRole = match$4.selectedRole;
  var match$5 = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match$5[1];
  React.useEffect((function () {
          if (tablePage.TAG !== /* ModelPage */0) {
            var result = tablePage._0;
            if (typeof result === "number") {
              result === /* NotStarted */0;
            } else if (result.TAG === /* Ok */0) {
              Belt_Option.mapWithDefault(Belt_List.head(result._0.content), undefined, (function (version) {
                      Curry._1(dispatch, {
                            TAG: /* StoreModelVersionFilter */2,
                            _0: {
                              brand: Model_Version_Form$DvmAdminFrontendFr.initialState.brand,
                              model: Model_Version_Form$DvmAdminFrontendFr.initialState.model,
                              version: Model_Version_Form$DvmAdminFrontendFr.initialState.version,
                              phase: Model_Version_Form$DvmAdminFrontendFr.initialState.phase,
                              modelLabel: Model_Version_Form$DvmAdminFrontendFr.initialState.modelLabel,
                              versionLabel: Model_Version_Form$DvmAdminFrontendFr.initialState.versionLabel,
                              motorization: Model_Version_Form$DvmAdminFrontendFr.initialState.motorization,
                              emptyMotorization: Model_Version_Form$DvmAdminFrontendFr.initialState.emptyMotorization,
                              active: Model_Version_Form$DvmAdminFrontendFr.initialState.active,
                              tableType: /* Version */1,
                              additionDate: version.additionDate
                            }
                          });
                      RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rModelVersionList);
                    }));
            } else {
              Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                        message: {
                          TAG: /* RestError */2,
                          _0: result._0
                        }
                      }));
            }
          }
          
        }), [tablePage]);
  React.useEffect((function () {
          fetchIfNoted(userManager, setNotedResult, selectedRole);
        }), [url]);
  React.useEffect((function () {
          if (typeof dismissResult === "number") {
            dismissResult === /* NotStarted */0;
          } else if (dismissResult.TAG === /* Ok */0) {
            fetchIfNoted(userManager, setNotedResult, selectedRole);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: dismissResult._0
                      }
                    }));
          }
        }), [dismissResult]);
  if (typeof notedResult === "number" || !(notedResult.TAG === /* Ok */0 && !notedResult._0.response)) {
    return null;
  } else {
    return React.createElement(Core.Box, {
                children: React.createElement(Core.Tooltip, {
                      arrow: true,
                      children: React.createElement("div", undefined, React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                show: App_Types_Result$DvmAdminFrontendFr.isPending(dismissResult) || App_Types_Result$DvmAdminFrontendFr.isPending(tablePage._0),
                                children: React.createElement(Core.Button, {
                                      onClick: (function (param) {
                                          dismissMessage(userManager, setDismissResult, selectedRole);
                                          Model_Version_Api$DvmAdminFrontendFr.fetchVersionSearch(undefined, userManager, setTablePage, "?limit=1&offset=0&orderBy=-additionDate", selectedRole);
                                        }),
                                      children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                            size: /* XLarge */4,
                                            type_: /* CarPark */7,
                                            color: "orange"
                                          })
                                    })
                              })),
                      classes: {
                        tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
                      },
                      placement: "bottom",
                      title: React.createElement("p", undefined, intl.formatMessage(Messages_ModelVersion$DvmAdminFrontendFr.newVersionsIconNotification))
                    })
              });
  }
}

var make = Model_Version_NewVersions_Menu_Icon;

export {
  dismissMessage ,
  isNoted_encode ,
  isNoted_decode ,
  fetchIfNoted ,
  make ,
}
/* react Not a pure module */
