// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Constants$DvmAdminFrontendFr from "../Constants.bs.js";
import * as ReactAlert$DvmAdminFrontendFr from "../../bindings/alert/ReactAlert.bs.js";

function error(headerMsg, alert, component) {
  alert.error(component, ReactAlert$DvmAdminFrontendFr.makeOptions(undefined, undefined, undefined, undefined, Caml_option.some(headerMsg), undefined));
}

function infoWithReturn(headerMsg, onClose, alert, component) {
  return alert.info(component, ReactAlert$DvmAdminFrontendFr.makeOptions(undefined, undefined, undefined, Caml_option.some(onClose), Caml_option.some(headerMsg), undefined));
}

function info(headerMsg, alert, component) {
  alert.info(component, ReactAlert$DvmAdminFrontendFr.makeOptions(undefined, undefined, undefined, undefined, Caml_option.some(headerMsg), undefined));
}

function success(headerMsg, alert, component) {
  alert.success(component, ReactAlert$DvmAdminFrontendFr.makeOptions(Caml_option.some(Constants$DvmAdminFrontendFr.defaultSuccessAlertTimeout), undefined, undefined, undefined, Caml_option.some(headerMsg), undefined));
}

export {
  error ,
  infoWithReturn ,
  info ,
  success ,
}
/* No side effect */
