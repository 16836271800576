// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Table$DvmAdminFrontendFr from "../../../common/components/table/App_Table.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../intl/Intl_Utils.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Types.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Flash_Publish_Types$DvmAdminFrontendFr from "./Flash_Publish_Types.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Pagination.bs.js";
import * as Messages_Flash_Publish$DvmAdminFrontendFr from "../../../intl/messages/flash/Messages_Flash_Publish.bs.js";
import * as App_Table_Create_Button$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Create_Button.bs.js";
import * as Flash_Publish_Export_Modal$DvmAdminFrontendFr from "./Flash_Publish_Export_Modal.bs.js";

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Publish_Types$DvmAdminFrontendFr.Fields.direction, undefined, undefined, undefined, undefined, undefined, Messages_Flash_Publish$DvmAdminFrontendFr.direction);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Publish_Types$DvmAdminFrontendFr.Fields.application, undefined, undefined, undefined, undefined, undefined, Messages_Flash_Publish$DvmAdminFrontendFr.application),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Publish_Types$DvmAdminFrontendFr.Fields.fileTypeLocal, undefined, undefined, undefined, undefined, undefined, Messages_Flash_Publish$DvmAdminFrontendFr.fileType),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Publish_Types$DvmAdminFrontendFr.Fields.batchResult, undefined, undefined, undefined, undefined, undefined, Messages_Flash_Publish$DvmAdminFrontendFr.batchResult),
      tl: {
        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Publish_Types$DvmAdminFrontendFr.Fields.start, undefined, undefined, "right", undefined, undefined, Messages_Flash_Publish$DvmAdminFrontendFr.start),
        tl: {
          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Publish_Types$DvmAdminFrontendFr.Fields.end, /* Desc */1, undefined, "right", undefined, undefined, Messages_Flash_Publish$DvmAdminFrontendFr.end),
          tl: /* [] */0
        }
      }
    }
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function Flash_Publish_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return null;
      });
  var setMoveModal = match[1];
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (row) {
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, undefined, undefined, Belt_List.mapWithIndex({
                                                  hd: row.direction,
                                                  tl: {
                                                    hd: row.application,
                                                    tl: {
                                                      hd: row.fileTypeLocal,
                                                      tl: {
                                                        hd: row.batchResult,
                                                        tl: {
                                                          hd: Belt_Option.mapWithDefault(row.start, "", (function (eta) {
                                                                  var param;
                                                                  var param$1;
                                                                  var param$2;
                                                                  return Intl_Utils$DvmAdminFrontendFr.formatDateWithTime(intl, param, param$1, param$2, eta);
                                                                })),
                                                          tl: {
                                                            hd: Belt_Option.mapWithDefault(row.end, "", (function (eta) {
                                                                    var param;
                                                                    var param$1;
                                                                    var param$2;
                                                                    return Intl_Utils$DvmAdminFrontendFr.formatDateWithTime(intl, param, param$1, param$2, eta);
                                                                  })),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, Belt_Option.getWithDefault(content, ""));
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._1(fetchData, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._1(fetchData, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._1(fetchData, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, {
                  headers: fetchRequest.headers,
                  handleSorting: handleSorting,
                  tablePage: convertTable(tablePage),
                  paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined),
                  exportMenu: React.createElement(Core.Grid, {
                        children: React.createElement(Core.Grid, {
                              children: React.createElement(App_Table_Create_Button$DvmAdminFrontendFr.make, {
                                    onClick: (function (param) {
                                        Curry._1(setMoveModal, (function (param) {
                                                return React.createElement(Flash_Publish_Export_Modal$DvmAdminFrontendFr.make, {
                                                            onClose: (function (param) {
                                                                Curry._1(setMoveModal, (function (param) {
                                                                        return null;
                                                                      }));
                                                              })
                                                          });
                                              }));
                                      }),
                                    icon: /* Box */70,
                                    message: Messages_Flash_Publish$DvmAdminFrontendFr.actionPublishTitle
                                  }),
                              item: true
                            }),
                        container: true,
                        item: true,
                        justifyContent: "flex-end"
                      })
                }), match[0]);
}

var make = Flash_Publish_Table;

export {
  headersConfiguration ,
  make ,
}
/* headersConfiguration Not a pure module */
