// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Flash_Types$DvmAdminFrontendFr from "../../Flash_Types.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../common/types/App_Types_Date.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../../../../common/types/App_Types_Input.bs.js";

function dates_encode(v) {
  return Js_dict.fromArray([
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function dates_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
  if (startDate.TAG === /* Ok */0) {
    var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
    if (endDate.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                startDate: startDate._0,
                endDate: endDate._0
              }
            };
    }
    var e = endDate._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".endDate" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = startDate._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".startDate" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function conditionsAge_encode(v) {
  return Js_dict.fromArray([
              [
                "atOrder",
                Decco.optionToJson(Decco.intToJson, v.atOrder)
              ],
              [
                "atDelivery",
                Decco.optionToJson(Decco.intToJson, v.atDelivery)
              ]
            ]);
}

function conditionsAge_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var atOrder = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "atOrder"), null));
  if (atOrder.TAG === /* Ok */0) {
    var atDelivery = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "atDelivery"), null));
    if (atDelivery.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                atOrder: atOrder._0,
                atDelivery: atDelivery._0
              }
            };
    }
    var e = atDelivery._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".atDelivery" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = atOrder._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".atOrder" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function brandsArray_encode(v) {
  return Js_dict.fromArray([
              [
                "ie",
                Decco.optionToJson(Flash_Types$DvmAdminFrontendFr.Acceptance.Decco.t_encode, v.ie)
              ],
              [
                "brands",
                Decco.optionToJson((function (param) {
                        return Decco.arrayToJson((function (param) {
                                      return Decco.optionToJson(Decco.stringToJson, param);
                                    }), param);
                      }), v.brands)
              ]
            ]);
}

function brandsArray_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var ie = Decco.optionFromJson(Flash_Types$DvmAdminFrontendFr.Acceptance.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "ie"), null));
  if (ie.TAG === /* Ok */0) {
    var brands = Decco.optionFromJson((function (param) {
            return Decco.arrayFromJson((function (param) {
                          return Decco.optionFromJson(Decco.stringFromJson, param);
                        }), param);
          }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "brands"), null));
    if (brands.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                ie: ie._0,
                brands: brands._0
              }
            };
    }
    var e = brands._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".brands" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = ie._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".ie" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function conditionsType_encode(v) {
  return Js_dict.fromArray([
              [
                "carAge",
                conditionsAge_encode(v.carAge)
              ],
              [
                "carOwnershipAge",
                conditionsAge_encode(v.carOwnershipAge)
              ],
              [
                "brands",
                Decco.optionToJson(brandsArray_encode, v.brands)
              ]
            ]);
}

function conditionsType_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var carAge = conditionsAge_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "carAge"), null));
  if (carAge.TAG === /* Ok */0) {
    var carOwnershipAge = conditionsAge_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "carOwnershipAge"), null));
    if (carOwnershipAge.TAG === /* Ok */0) {
      var brands = Decco.optionFromJson(brandsArray_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "brands"), null));
      if (brands.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  carAge: carAge._0,
                  carOwnershipAge: carOwnershipAge._0,
                  brands: brands._0
                }
              };
      }
      var e = brands._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".brands" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = carOwnershipAge._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".carOwnershipAge" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = carAge._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".carAge" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function bonusAmountType_encode(v) {
  return Js_dict.fromArray([
              [
                "numberType",
                Curry._1(Flash_Types$DvmAdminFrontendFr.ValueType.Decco.t_encode, v.numberType)
              ],
              [
                "value",
                Decco.optionToJson(Decco.floatToJson, v.value)
              ]
            ]);
}

function bonusAmountType_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var numberType = Curry._1(Flash_Types$DvmAdminFrontendFr.ValueType.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "numberType"), null));
  if (numberType.TAG === /* Ok */0) {
    var value = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "value"), null));
    if (value.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                numberType: numberType._0,
                value: value._0
              }
            };
    }
    var e = value._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".value" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = numberType._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".numberType" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function modelVersion_encode(v) {
  return Js_dict.fromArray([
              [
                "model",
                Decco.stringToJson(v.model)
              ],
              [
                "versions",
                Decco.arrayToJson(Decco.stringToJson, v.versions)
              ]
            ]);
}

function modelVersion_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var model = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "model"), null));
  if (model.TAG === /* Ok */0) {
    var versions = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "versions"), null));
    if (versions.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                model: model._0,
                versions: versions._0
              }
            };
    }
    var e = versions._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".versions" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = model._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".model" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function flashAction_encode(v) {
  return Js_dict.fromArray([
              [
                "actionType",
                Decco.stringToJson(v.actionType)
              ],
              [
                "actionId",
                Decco.stringToJson(v.actionId)
              ],
              [
                "actionLabel",
                Decco.optionToJson(Decco.stringToJson, v.actionLabel)
              ],
              [
                "amount",
                Decco.optionToJson(bonusAmountType_encode, v.amount)
              ],
              [
                "published",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.published)
              ],
              [
                "orderPeriod",
                Decco.optionToJson(dates_encode, v.orderPeriod)
              ],
              [
                "deliveryPeriod",
                Decco.optionToJson(dates_encode, v.deliveryPeriod)
              ],
              [
                "registrationPeriod",
                Decco.optionToJson(dates_encode, v.registrationPeriod)
              ],
              [
                "madaPeriod",
                Decco.optionToJson(dates_encode, v.madaPeriod)
              ],
              [
                "madcPeriod",
                Decco.optionToJson(dates_encode, v.madcPeriod)
              ],
              [
                "cumulative",
                Decco.optionToJson(Decco.boolToJson, v.cumulative)
              ],
              [
                "budget",
                Decco.optionToJson((function (param) {
                        return Decco.arrayToJson(Decco.stringToJson, param);
                      }), v.budget)
              ],
              [
                "conditions",
                Decco.optionToJson(conditionsType_encode, v.conditions)
              ],
              [
                "modelVersions",
                App_Types_Input$DvmAdminFrontendFr.parentChildList_encode(v.modelVersions)
              ],
              [
                "orderType",
                Decco.optionToJson((function (param) {
                        return Decco.arrayToJson(Decco.stringToJson, param);
                      }), v.orderType)
              ],
              [
                "usedCarModels",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(Decco.stringToJson, param);
                      }), v.usedCarModels)
              ],
              [
                "entryParcDatePeriodStart",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.entryParcDatePeriodStart)
              ],
              [
                "entryParcDatePeriodEnd",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.entryParcDatePeriodEnd)
              ],
              [
                "exitParcDatePeriodStart",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.exitParcDatePeriodStart)
              ],
              [
                "exitParcDatePeriodEnd",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.exitParcDatePeriodEnd)
              ],
              [
                "priceType",
                Decco.optionToJson(Decco.stringToJson, v.priceType)
              ],
              [
                "detentionMin",
                Decco.optionToJson(Decco.intToJson, v.detentionMin)
              ],
              [
                "detentionMax",
                Decco.optionToJson(Decco.intToJson, v.detentionMax)
              ],
              [
                "isPARCExit",
                Decco.optionToJson(Decco.boolToJson, v.isPARCExit)
              ],
              [
                "hasCustomAmount",
                Decco.optionToJson(Decco.boolToJson, v.hasCustomAmount)
              ],
              [
                "dealerIds",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(Decco.stringToJson, param);
                      }), v.dealerIds)
              ],
              [
                "dealerWithAgents",
                Decco.optionToJson(App_Types_Input$DvmAdminFrontendFr.parentChildList_encode, v.dealerWithAgents)
              ],
              [
                "requestType",
                Decco.optionToJson(Decco.stringToJson, v.requestType)
              ],
              [
                "clientTypes",
                Decco.optionToJson((function (param) {
                        return Decco.arrayToJson(Decco.stringToJson, param);
                      }), v.clientTypes)
              ],
              [
                "saleTypes",
                Decco.optionToJson((function (param) {
                        return Decco.arrayToJson(Decco.stringToJson, param);
                      }), v.saleTypes)
              ]
            ]);
}

function flashAction_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var actionType = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionType"), null));
  if (actionType.TAG === /* Ok */0) {
    var actionId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionId"), null));
    if (actionId.TAG === /* Ok */0) {
      var actionLabel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionLabel"), null));
      if (actionLabel.TAG === /* Ok */0) {
        var amount = Decco.optionFromJson(bonusAmountType_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "amount"), null));
        if (amount.TAG === /* Ok */0) {
          var published = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "published"), null));
          if (published.TAG === /* Ok */0) {
            var orderPeriod = Decco.optionFromJson(dates_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderPeriod"), null));
            if (orderPeriod.TAG === /* Ok */0) {
              var deliveryPeriod = Decco.optionFromJson(dates_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "deliveryPeriod"), null));
              if (deliveryPeriod.TAG === /* Ok */0) {
                var registrationPeriod = Decco.optionFromJson(dates_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "registrationPeriod"), null));
                if (registrationPeriod.TAG === /* Ok */0) {
                  var madaPeriod = Decco.optionFromJson(dates_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "madaPeriod"), null));
                  if (madaPeriod.TAG === /* Ok */0) {
                    var madcPeriod = Decco.optionFromJson(dates_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "madcPeriod"), null));
                    if (madcPeriod.TAG === /* Ok */0) {
                      var cumulative = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "cumulative"), null));
                      if (cumulative.TAG === /* Ok */0) {
                        var budget = Decco.optionFromJson((function (param) {
                                return Decco.arrayFromJson(Decco.stringFromJson, param);
                              }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "budget"), null));
                        if (budget.TAG === /* Ok */0) {
                          var conditions = Decco.optionFromJson(conditionsType_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "conditions"), null));
                          if (conditions.TAG === /* Ok */0) {
                            var modelVersions = App_Types_Input$DvmAdminFrontendFr.parentChildList_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelVersions"), null));
                            if (modelVersions.TAG === /* Ok */0) {
                              var orderType = Decco.optionFromJson((function (param) {
                                      return Decco.arrayFromJson(Decco.stringFromJson, param);
                                    }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderType"), null));
                              if (orderType.TAG === /* Ok */0) {
                                var usedCarModels = Decco.optionFromJson((function (param) {
                                        return Decco.listFromJson(Decco.stringFromJson, param);
                                      }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "usedCarModels"), null));
                                if (usedCarModels.TAG === /* Ok */0) {
                                  var entryParcDatePeriodStart = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "entryParcDatePeriodStart"), null));
                                  if (entryParcDatePeriodStart.TAG === /* Ok */0) {
                                    var entryParcDatePeriodEnd = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "entryParcDatePeriodEnd"), null));
                                    if (entryParcDatePeriodEnd.TAG === /* Ok */0) {
                                      var exitParcDatePeriodStart = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "exitParcDatePeriodStart"), null));
                                      if (exitParcDatePeriodStart.TAG === /* Ok */0) {
                                        var exitParcDatePeriodEnd = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "exitParcDatePeriodEnd"), null));
                                        if (exitParcDatePeriodEnd.TAG === /* Ok */0) {
                                          var priceType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "priceType"), null));
                                          if (priceType.TAG === /* Ok */0) {
                                            var detentionMin = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "detentionMin"), null));
                                            if (detentionMin.TAG === /* Ok */0) {
                                              var detentionMax = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "detentionMax"), null));
                                              if (detentionMax.TAG === /* Ok */0) {
                                                var isPARCExit = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "isPARCExit"), null));
                                                if (isPARCExit.TAG === /* Ok */0) {
                                                  var hasCustomAmount = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "hasCustomAmount"), null));
                                                  if (hasCustomAmount.TAG === /* Ok */0) {
                                                    var dealerIds = Decco.optionFromJson((function (param) {
                                                            return Decco.listFromJson(Decco.stringFromJson, param);
                                                          }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerIds"), null));
                                                    if (dealerIds.TAG === /* Ok */0) {
                                                      var dealerWithAgents = Decco.optionFromJson(App_Types_Input$DvmAdminFrontendFr.parentChildList_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerWithAgents"), null));
                                                      if (dealerWithAgents.TAG === /* Ok */0) {
                                                        var requestType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "requestType"), null));
                                                        if (requestType.TAG === /* Ok */0) {
                                                          var clientTypes = Decco.optionFromJson((function (param) {
                                                                  return Decco.arrayFromJson(Decco.stringFromJson, param);
                                                                }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "clientTypes"), null));
                                                          if (clientTypes.TAG === /* Ok */0) {
                                                            var saleTypes = Decco.optionFromJson((function (param) {
                                                                    return Decco.arrayFromJson(Decco.stringFromJson, param);
                                                                  }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "saleTypes"), null));
                                                            if (saleTypes.TAG === /* Ok */0) {
                                                              return {
                                                                      TAG: /* Ok */0,
                                                                      _0: {
                                                                        actionType: actionType._0,
                                                                        actionId: actionId._0,
                                                                        actionLabel: actionLabel._0,
                                                                        amount: amount._0,
                                                                        published: published._0,
                                                                        orderPeriod: orderPeriod._0,
                                                                        deliveryPeriod: deliveryPeriod._0,
                                                                        registrationPeriod: registrationPeriod._0,
                                                                        madaPeriod: madaPeriod._0,
                                                                        madcPeriod: madcPeriod._0,
                                                                        cumulative: cumulative._0,
                                                                        budget: budget._0,
                                                                        conditions: conditions._0,
                                                                        modelVersions: modelVersions._0,
                                                                        orderType: orderType._0,
                                                                        usedCarModels: usedCarModels._0,
                                                                        entryParcDatePeriodStart: entryParcDatePeriodStart._0,
                                                                        entryParcDatePeriodEnd: entryParcDatePeriodEnd._0,
                                                                        exitParcDatePeriodStart: exitParcDatePeriodStart._0,
                                                                        exitParcDatePeriodEnd: exitParcDatePeriodEnd._0,
                                                                        priceType: priceType._0,
                                                                        detentionMin: detentionMin._0,
                                                                        detentionMax: detentionMax._0,
                                                                        isPARCExit: isPARCExit._0,
                                                                        hasCustomAmount: hasCustomAmount._0,
                                                                        dealerIds: dealerIds._0,
                                                                        dealerWithAgents: dealerWithAgents._0,
                                                                        requestType: requestType._0,
                                                                        clientTypes: clientTypes._0,
                                                                        saleTypes: saleTypes._0
                                                                      }
                                                                    };
                                                            }
                                                            var e = saleTypes._0;
                                                            return {
                                                                    TAG: /* Error */1,
                                                                    _0: {
                                                                      path: ".saleTypes" + e.path,
                                                                      message: e.message,
                                                                      value: e.value
                                                                    }
                                                                  };
                                                          }
                                                          var e$1 = clientTypes._0;
                                                          return {
                                                                  TAG: /* Error */1,
                                                                  _0: {
                                                                    path: ".clientTypes" + e$1.path,
                                                                    message: e$1.message,
                                                                    value: e$1.value
                                                                  }
                                                                };
                                                        }
                                                        var e$2 = requestType._0;
                                                        return {
                                                                TAG: /* Error */1,
                                                                _0: {
                                                                  path: ".requestType" + e$2.path,
                                                                  message: e$2.message,
                                                                  value: e$2.value
                                                                }
                                                              };
                                                      }
                                                      var e$3 = dealerWithAgents._0;
                                                      return {
                                                              TAG: /* Error */1,
                                                              _0: {
                                                                path: ".dealerWithAgents" + e$3.path,
                                                                message: e$3.message,
                                                                value: e$3.value
                                                              }
                                                            };
                                                    }
                                                    var e$4 = dealerIds._0;
                                                    return {
                                                            TAG: /* Error */1,
                                                            _0: {
                                                              path: ".dealerIds" + e$4.path,
                                                              message: e$4.message,
                                                              value: e$4.value
                                                            }
                                                          };
                                                  }
                                                  var e$5 = hasCustomAmount._0;
                                                  return {
                                                          TAG: /* Error */1,
                                                          _0: {
                                                            path: ".hasCustomAmount" + e$5.path,
                                                            message: e$5.message,
                                                            value: e$5.value
                                                          }
                                                        };
                                                }
                                                var e$6 = isPARCExit._0;
                                                return {
                                                        TAG: /* Error */1,
                                                        _0: {
                                                          path: ".isPARCExit" + e$6.path,
                                                          message: e$6.message,
                                                          value: e$6.value
                                                        }
                                                      };
                                              }
                                              var e$7 = detentionMax._0;
                                              return {
                                                      TAG: /* Error */1,
                                                      _0: {
                                                        path: ".detentionMax" + e$7.path,
                                                        message: e$7.message,
                                                        value: e$7.value
                                                      }
                                                    };
                                            }
                                            var e$8 = detentionMin._0;
                                            return {
                                                    TAG: /* Error */1,
                                                    _0: {
                                                      path: ".detentionMin" + e$8.path,
                                                      message: e$8.message,
                                                      value: e$8.value
                                                    }
                                                  };
                                          }
                                          var e$9 = priceType._0;
                                          return {
                                                  TAG: /* Error */1,
                                                  _0: {
                                                    path: ".priceType" + e$9.path,
                                                    message: e$9.message,
                                                    value: e$9.value
                                                  }
                                                };
                                        }
                                        var e$10 = exitParcDatePeriodEnd._0;
                                        return {
                                                TAG: /* Error */1,
                                                _0: {
                                                  path: ".exitParcDatePeriodEnd" + e$10.path,
                                                  message: e$10.message,
                                                  value: e$10.value
                                                }
                                              };
                                      }
                                      var e$11 = exitParcDatePeriodStart._0;
                                      return {
                                              TAG: /* Error */1,
                                              _0: {
                                                path: ".exitParcDatePeriodStart" + e$11.path,
                                                message: e$11.message,
                                                value: e$11.value
                                              }
                                            };
                                    }
                                    var e$12 = entryParcDatePeriodEnd._0;
                                    return {
                                            TAG: /* Error */1,
                                            _0: {
                                              path: ".entryParcDatePeriodEnd" + e$12.path,
                                              message: e$12.message,
                                              value: e$12.value
                                            }
                                          };
                                  }
                                  var e$13 = entryParcDatePeriodStart._0;
                                  return {
                                          TAG: /* Error */1,
                                          _0: {
                                            path: ".entryParcDatePeriodStart" + e$13.path,
                                            message: e$13.message,
                                            value: e$13.value
                                          }
                                        };
                                }
                                var e$14 = usedCarModels._0;
                                return {
                                        TAG: /* Error */1,
                                        _0: {
                                          path: ".usedCarModels" + e$14.path,
                                          message: e$14.message,
                                          value: e$14.value
                                        }
                                      };
                              }
                              var e$15 = orderType._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".orderType" + e$15.path,
                                        message: e$15.message,
                                        value: e$15.value
                                      }
                                    };
                            }
                            var e$16 = modelVersions._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".modelVersions" + e$16.path,
                                      message: e$16.message,
                                      value: e$16.value
                                    }
                                  };
                          }
                          var e$17 = conditions._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".conditions" + e$17.path,
                                    message: e$17.message,
                                    value: e$17.value
                                  }
                                };
                        }
                        var e$18 = budget._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".budget" + e$18.path,
                                  message: e$18.message,
                                  value: e$18.value
                                }
                              };
                      }
                      var e$19 = cumulative._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".cumulative" + e$19.path,
                                message: e$19.message,
                                value: e$19.value
                              }
                            };
                    }
                    var e$20 = madcPeriod._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".madcPeriod" + e$20.path,
                              message: e$20.message,
                              value: e$20.value
                            }
                          };
                  }
                  var e$21 = madaPeriod._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".madaPeriod" + e$21.path,
                            message: e$21.message,
                            value: e$21.value
                          }
                        };
                }
                var e$22 = registrationPeriod._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".registrationPeriod" + e$22.path,
                          message: e$22.message,
                          value: e$22.value
                        }
                      };
              }
              var e$23 = deliveryPeriod._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".deliveryPeriod" + e$23.path,
                        message: e$23.message,
                        value: e$23.value
                      }
                    };
            }
            var e$24 = orderPeriod._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".orderPeriod" + e$24.path,
                      message: e$24.message,
                      value: e$24.value
                    }
                  };
          }
          var e$25 = published._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".published" + e$25.path,
                    message: e$25.message,
                    value: e$25.value
                  }
                };
        }
        var e$26 = amount._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".amount" + e$26.path,
                  message: e$26.message,
                  value: e$26.value
                }
              };
      }
      var e$27 = actionLabel._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".actionLabel" + e$27.path,
                message: e$27.message,
                value: e$27.value
              }
            };
    }
    var e$28 = actionId._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".actionId" + e$28.path,
              message: e$28.message,
              value: e$28.value
            }
          };
  }
  var e$29 = actionType._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".actionType" + e$29.path,
            message: e$29.message,
            value: e$29.value
          }
        };
}

export {
  dates_encode ,
  dates_decode ,
  conditionsAge_encode ,
  conditionsAge_decode ,
  brandsArray_encode ,
  brandsArray_decode ,
  conditionsType_encode ,
  conditionsType_decode ,
  bonusAmountType_encode ,
  bonusAmountType_decode ,
  modelVersion_encode ,
  modelVersion_decode ,
  flashAction_encode ,
  flashAction_decode ,
}
/* Flash_Types-DvmAdminFrontendFr Not a pure module */
