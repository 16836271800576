// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as App_Types_Result$DvmAdminFrontendFr from "./App_Types_Result.bs.js";

function toString(intl, message) {
  switch (message.TAG | 0) {
    case /* Message */0 :
        return intl.formatMessage(message._0);
    case /* MessageWithArguments */1 :
        var match = message._0;
        return intl.formatMessage(match[0], match[1]);
    case /* RestError */2 :
        var match$1 = App_Types_Result$DvmAdminFrontendFr.Rest.toMessage(message._0);
        var $$arguments = match$1[1];
        var message$1 = match$1[0];
        if ($$arguments !== undefined) {
          return intl.formatMessage(message$1, Caml_option.valFromOption($$arguments));
        } else {
          return intl.formatMessage(message$1);
        }
    case /* String */3 :
        return message._0;
    
  }
}

export {
  toString ,
}
/* No side effect */
