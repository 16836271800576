// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../common/components/App_OverlaySpinner.bs.js";
import * as Contract_Edit_Main_Info_Edit$DvmAdminFrontendFr from "./Contract_Edit_Main_Info_Edit.bs.js";
import * as Contract_Edit_Main_Info_View$DvmAdminFrontendFr from "./Contract_Edit_Main_Info_View.bs.js";

function Contract_Edit_Main_Info(Props) {
  var contract = Props.contract;
  var managerIds = Props.managerIds;
  var saveResult = Props.saveResult;
  var setSaveResult = Props.setSaveResult;
  var reload = Props.reload;
  var operation = Props.operation;
  return React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
              show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult),
              children: operation === /* Manage */1 ? React.createElement(Contract_Edit_Main_Info_Edit$DvmAdminFrontendFr.make, {
                      contract: contract,
                      managerIds: managerIds,
                      saveResult: saveResult,
                      setSaveResult: setSaveResult,
                      reload: reload
                    }) : React.createElement(Contract_Edit_Main_Info_View$DvmAdminFrontendFr.make, {
                      contract: contract
                    })
            });
}

var Types;

var make = Contract_Edit_Main_Info;

export {
  Types ,
  make ,
}
/* react Not a pure module */
