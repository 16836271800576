// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as EnvVar$DvmAdminFrontendFr from "../bindings/global/EnvVar.bs.js";
import * as Messages_Energy$DvmAdminFrontendFr from "../intl/messages/energy/Messages_Energy.bs.js";
import * as Messages_Quotas$DvmAdminFrontendFr from "../intl/messages/quotas/Messages_Quotas.bs.js";
import * as Messages_TopMenu$DvmAdminFrontendFr from "../intl/messages/topMenu/Messages_TopMenu.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../context/user/User_Types_Utils.bs.js";
import * as Messages_MarginGrid$DvmAdminFrontendFr from "../intl/messages/marginGrid/Messages_MarginGrid.bs.js";
import * as Messages_Quotas_PARC$DvmAdminFrontendFr from "../intl/messages/quotas/Messages_Quotas_PARC.bs.js";
import * as Messages_Flash_Publish$DvmAdminFrontendFr from "../intl/messages/flash/Messages_Flash_Publish.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as Messages_Bonuses_Anomalies$DvmAdminFrontendFr from "../intl/messages/bonuses/Messages_Bonuses_Anomalies.bs.js";
import * as Messages_Action_Referential$DvmAdminFrontendFr from "../intl/messages/actionReferential/Messages_Action_Referential.bs.js";

var rHome = "/";

var rView = "/view";

var rManage = "/manage";

var rOrder = "/order";

var rFlash = "/flash";

var rContractManager = "/contractManager";

var rQuota = "/quota";

var rQuotaDetailCreate = "/quotaDetailCreate";

var rModelVersionGrid = "/modelVersionGrid";

var rMarginGrid = "/marginGrid";

var rModelVersionList = "/models";

var rBonuses = "/bonuses";

var rContract = "/contracts";

var rAttachment = "/attachments";

var rPCDAgreement = "/pcdAgreement";

var rCategory = "/category";

var rCompany = "/company";

var rCompanyVirtual = "/companyVirtual";

var rDealer = "/dealer";

var rMarginMinimum = "/marginMinimum";

var rVisibilityProfiles = "/visibilityProfiles";

var rGemo = "/gemo";

var rSubmarket = "/submarket";

var rQuotaExclusions = "/quotaExclusions";

var rUpload = "/upload";

var rContractErrors = "/contractErrors";

var rFinancialCode = "/financialCode";

var rBrands = "/brands";

var rEnergies = "/energies";

var rFlashPublish = "/flashPublish";

var rQuotaPARC = "/quotaPARC";

var rAnomalies = "/anomalies";

var rActionReferential = "/actionReferential";

function getMenuRoutes(selectedRole, intl) {
  var home_menu = [];
  var home = {
    path: rHome,
    icon: /* Home */9,
    translation: Messages_TopMenu$DvmAdminFrontendFr.home,
    menu: home_menu
  };
  var view_menu = [];
  var view = {
    path: rView,
    icon: /* Show */59,
    translation: Messages_TopMenu$DvmAdminFrontendFr.view,
    menu: view_menu
  };
  var viewResultsSubmenu_items = [];
  var viewResultsSubmenu = {
    translation: Messages_TopMenu$DvmAdminFrontendFr.submenuResults,
    items: viewResultsSubmenu_items
  };
  var viewBudgetsSubmenu_items = [];
  var viewBudgetsSubmenu = {
    translation: Messages_TopMenu$DvmAdminFrontendFr.submenuBudgets,
    items: viewBudgetsSubmenu_items
  };
  var viewActionsSubmenu_items = [];
  var viewActionsSubmenu = {
    translation: Messages_TopMenu$DvmAdminFrontendFr.submenuActions,
    items: viewActionsSubmenu_items
  };
  var viewClientsSubmenu_items = [];
  var viewClientsSubmenu = {
    translation: Messages_TopMenu$DvmAdminFrontendFr.submenuClients,
    items: viewClientsSubmenu_items
  };
  var viewOthersSubmenu_items = [];
  var viewOthersSubmenu = {
    translation: Messages_TopMenu$DvmAdminFrontendFr.submenuOthers,
    items: viewOthersSubmenu_items
  };
  var manage_menu = [];
  var manage = {
    path: rManage,
    icon: /* Gear */39,
    translation: Messages_TopMenu$DvmAdminFrontendFr.manage,
    menu: manage_menu
  };
  var manageBudgetsSubmenu_items = [];
  var manageBudgetsSubmenu = {
    translation: Messages_TopMenu$DvmAdminFrontendFr.submenuBudgets,
    items: manageBudgetsSubmenu_items
  };
  var manageActionsSubmenu_items = [];
  var manageActionsSubmenu = {
    translation: Messages_TopMenu$DvmAdminFrontendFr.submenuActions,
    items: manageActionsSubmenu_items
  };
  var manageContractsSubmenu_items = [];
  var manageContractsSubmenu = {
    translation: Messages_TopMenu$DvmAdminFrontendFr.submenuContracts,
    items: manageContractsSubmenu_items
  };
  var manageOthersSubmenu_items = [];
  var manageOthersSubmenu = {
    translation: Messages_TopMenu$DvmAdminFrontendFr.submenuOthers,
    items: manageOthersSubmenu_items
  };
  var getOrderItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rOrder,
          translation: Messages_TopMenu$DvmAdminFrontendFr.order
        });
  };
  var getFlashItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rFlash,
          translation: Messages_TopMenu$DvmAdminFrontendFr.flash
        });
  };
  var getContractManagerItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rContractManager,
          translation: Messages_TopMenu$DvmAdminFrontendFr.contractManager
        });
  };
  var getQuotasItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rQuota,
          translation: Messages_TopMenu$DvmAdminFrontendFr.quotas
        });
  };
  var getQuotasAdminItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rQuota,
          translation: Messages_Quotas$DvmAdminFrontendFr.title
        });
  };
  var getQuotaDetailEditItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rQuotaDetailCreate,
          translation: Messages_Quotas_Detail$DvmAdminFrontendFr.createTitle
        });
  };
  var getModelVersionGridItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rModelVersionGrid,
          translation: Messages_TopMenu$DvmAdminFrontendFr.modelVersionGrid
        });
  };
  var getMarginGridItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rMarginGrid,
          translation: Messages_TopMenu$DvmAdminFrontendFr.margingrid
        });
  };
  var getMarginGridManageItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rMarginGrid,
          translation: Messages_MarginGrid$DvmAdminFrontendFr.manageMarginGridTitle
        });
  };
  var getModelVersionListItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rModelVersionList,
          translation: Messages_TopMenu$DvmAdminFrontendFr.modelVersionList
        });
  };
  var getBonusesItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rBonuses,
          translation: Messages_TopMenu$DvmAdminFrontendFr.bonusesView
        });
  };
  var getBonusesManageItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rBonuses,
          translation: Messages_TopMenu$DvmAdminFrontendFr.bonusesEdit
        });
  };
  var getContractItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rContract,
          translation: Messages_TopMenu$DvmAdminFrontendFr.contracts
        });
  };
  var getAttachmentItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rAttachment,
          translation: Messages_TopMenu$DvmAdminFrontendFr.attachments
        });
  };
  var getPCDAgreementItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rPCDAgreement,
          translation: Messages_TopMenu$DvmAdminFrontendFr.pcdAgreement
        });
  };
  var getCategoriesViewItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rCategory,
          translation: Messages_TopMenu$DvmAdminFrontendFr.categories
        });
  };
  var getCategoriesEditItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rCategory,
          translation: Messages_TopMenu$DvmAdminFrontendFr.createCategory
        });
  };
  var getDealerItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rDealer,
          translation: Messages_TopMenu$DvmAdminFrontendFr.dealerList
        });
  };
  var getCompanyItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rCompany,
          translation: Messages_TopMenu$DvmAdminFrontendFr.companyList
        });
  };
  var getCompanyManageItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rCompany,
          translation: Messages_TopMenu$DvmAdminFrontendFr.companyManage
        });
  };
  var getCompanyVirtualEditItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rCompanyVirtual,
          translation: Messages_TopMenu$DvmAdminFrontendFr.companyVirtualEdit
        });
  };
  var getCompanyVirtualViewItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rCompanyVirtual,
          translation: Messages_TopMenu$DvmAdminFrontendFr.companyVirtualView
        });
  };
  var getMarginMinimumItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rMarginMinimum,
          translation: Messages_TopMenu$DvmAdminFrontendFr.marginMinimum
        });
  };
  var getVisibilityProfilesItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rVisibilityProfiles,
          translation: Messages_TopMenu$DvmAdminFrontendFr.visibilityProfiles
        });
  };
  var getGemoItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rGemo,
          translation: Messages_TopMenu$DvmAdminFrontendFr.gemo
        });
  };
  var getSubmarketItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rSubmarket,
          translation: Messages_TopMenu$DvmAdminFrontendFr.submarkets
        });
  };
  var getQuotaExclusionsItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rQuotaExclusions,
          translation: Messages_Quotas_Detail$DvmAdminFrontendFr.exclusionTitle
        });
  };
  var getUploadItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rUpload,
          translation: Messages_TopMenu$DvmAdminFrontendFr.upload
        });
  };
  var getContractErrorsItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rContractErrors,
          translation: Messages_TopMenu$DvmAdminFrontendFr.contractErrors
        });
  };
  var getFinancialCodeItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rFinancialCode,
          translation: Messages_TopMenu$DvmAdminFrontendFr.financialCode
        });
  };
  var getBrandsItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rBrands,
          translation: Messages_TopMenu$DvmAdminFrontendFr.brandsManagementPage
        });
  };
  var getEnergiesItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rEnergies,
          translation: Messages_Energy$DvmAdminFrontendFr.title
        });
  };
  var getFlashPublishItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rFlashPublish,
          translation: Messages_Flash_Publish$DvmAdminFrontendFr.actionPublishTitle
        });
  };
  var getQuotaPARCItem = function (submenu, route) {
    submenu.items.push({
          path: route.path + rQuotaPARC,
          translation: Messages_Quotas_PARC$DvmAdminFrontendFr.title
        });
  };
  var getBonusAnomalies = function (submenu, route) {
    submenu.items.push({
          path: route.path + rAnomalies,
          translation: Messages_Bonuses_Anomalies$DvmAdminFrontendFr.title
        });
  };
  var getActionReferentials = function (submenu, route) {
    submenu.items.push({
          path: route.path + rActionReferential,
          translation: Messages_Action_Referential$DvmAdminFrontendFr.title
        });
  };
  getOrderItem(viewResultsSubmenu, view);
  getBonusesItem(viewResultsSubmenu, view);
  getModelVersionListItem(viewOthersSubmenu, view);
  getCategoriesViewItem(viewOthersSubmenu, view);
  getFlashItem(viewActionsSubmenu, view);
  getDealerItem(viewOthersSubmenu, view);
  if (EnvVar$DvmAdminFrontendFr.currentEnvironmentProd) {
    
  } else {
    getActionReferentials(viewActionsSubmenu, view);
  }
  if (User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0) {
    getBonusAnomalies(viewResultsSubmenu, view);
    getQuotasItem(viewBudgetsSubmenu, view);
  }
  if (selectedRole !== /* MRR */8 && User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0) {
    getPCDAgreementItem(viewBudgetsSubmenu, view);
    getModelVersionGridItem(viewBudgetsSubmenu, view);
    getCompanyItem(viewClientsSubmenu, view);
    getCompanyVirtualViewItem(viewClientsSubmenu, view);
    getAttachmentItem(viewClientsSubmenu, view);
    getContractItem(viewClientsSubmenu, view);
    getContractManagerItem(viewClientsSubmenu, view);
    getQuotaPARCItem(viewActionsSubmenu, view);
  }
  if (User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole)) {
    getSubmarketItem(manageOthersSubmenu, manage);
    getFlashItem(manageActionsSubmenu, manage);
    getFinancialCodeItem(manageActionsSubmenu, manage);
    getVisibilityProfilesItem(manageOthersSubmenu, manage);
    getGemoItem(manageOthersSubmenu, manage);
    getCategoriesEditItem(manageOthersSubmenu, manage);
    getBrandsItem(manageOthersSubmenu, manage);
    getEnergiesItem(manageOthersSubmenu, manage);
    getModelVersionListItem(manageOthersSubmenu, manage);
    getFlashPublishItem(manageActionsSubmenu, manage);
    if (EnvVar$DvmAdminFrontendFr.currentEnvironmentProd) {
      
    } else {
      getActionReferentials(manageActionsSubmenu, manage);
    }
    if (User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0) {
      getContractErrorsItem(manageOthersSubmenu, manage);
      getMarginGridItem(viewBudgetsSubmenu, view);
      getMarginGridManageItem(manageBudgetsSubmenu, manage);
      getModelVersionGridItem(manageBudgetsSubmenu, manage);
      getQuotaDetailEditItem(manageBudgetsSubmenu, manage);
      getQuotasAdminItem(manageBudgetsSubmenu, manage);
      getQuotaExclusionsItem(manageBudgetsSubmenu, manage);
      getMarginMinimumItem(manageBudgetsSubmenu, manage);
      getPCDAgreementItem(manageBudgetsSubmenu, manage);
      getBonusesManageItem(manageActionsSubmenu, manage);
      getContractItem(manageContractsSubmenu, manage);
      getContractManagerItem(manageContractsSubmenu, manage);
      getCompanyVirtualEditItem(manageContractsSubmenu, manage);
      getUploadItem(manageContractsSubmenu, manage);
      getCompanyManageItem(manageContractsSubmenu, manage);
      getQuotaPARCItem(manageActionsSubmenu, manage);
    }
    
  }
  if (selectedRole === /* AcountManager */4) {
    getModelVersionGridItem(manageBudgetsSubmenu, manage);
  }
  if (selectedRole === /* CompanyManager */2 || selectedRole === /* LldManager */5 || selectedRole === /* BigAccountManager */6) {
    getBonusesManageItem(manageActionsSubmenu, manage);
  }
  var byTranslation = function (s1, s2) {
    return intl.formatMessage(s1.translation).localeCompare(intl.formatMessage(s2.translation)) | 0;
  };
  Belt_List.map({
        hd: {
          translation: Messages_TopMenu$DvmAdminFrontendFr.submenuResults,
          items: viewResultsSubmenu_items.sort(byTranslation)
        },
        tl: {
          hd: {
            translation: Messages_TopMenu$DvmAdminFrontendFr.submenuBudgets,
            items: viewBudgetsSubmenu_items.sort(byTranslation)
          },
          tl: {
            hd: {
              translation: Messages_TopMenu$DvmAdminFrontendFr.submenuActions,
              items: viewActionsSubmenu_items.sort(byTranslation)
            },
            tl: {
              hd: {
                translation: Messages_TopMenu$DvmAdminFrontendFr.submenuClients,
                items: viewClientsSubmenu_items.sort(byTranslation)
              },
              tl: {
                hd: {
                  translation: Messages_TopMenu$DvmAdminFrontendFr.submenuOthers,
                  items: viewOthersSubmenu_items.sort(byTranslation)
                },
                tl: /* [] */0
              }
            }
          }
        }
      }, (function (x) {
          if (x.items.length > 0) {
            view_menu.push(x);
            return ;
          }
          
        }));
  Belt_List.map({
        hd: {
          translation: Messages_TopMenu$DvmAdminFrontendFr.submenuBudgets,
          items: manageBudgetsSubmenu_items.sort(byTranslation)
        },
        tl: {
          hd: {
            translation: Messages_TopMenu$DvmAdminFrontendFr.submenuActions,
            items: manageActionsSubmenu_items.sort(byTranslation)
          },
          tl: {
            hd: {
              translation: Messages_TopMenu$DvmAdminFrontendFr.submenuContracts,
              items: manageContractsSubmenu_items.sort(byTranslation)
            },
            tl: {
              hd: {
                translation: Messages_TopMenu$DvmAdminFrontendFr.submenuOthers,
                items: manageOthersSubmenu_items.sort(byTranslation)
              },
              tl: /* [] */0
            }
          }
        }
      }, (function (x) {
          if (x.items.length > 0) {
            manage_menu.push(x);
            return ;
          }
          
        }));
  var menu = [home];
  if (view_menu.length > 0) {
    menu.push(view);
  }
  if (manage_menu.length > 0) {
    menu.push(manage);
  }
  return menu;
}

var rLogout = "/logout";

var rSelectRole = "/selectRole";

var rFlashEdit = "/flashEdit";

var rContractManagerEdit = "/contractManagerEdit";

var rContractView = "/contract";

var rContractEdit = "/contractEdit";

var rQuotaDetailEdit = "/quotaDetailEdit";

var rModelVersionGridEdit = "/modelVersionGridEdit";

var rCategoryEdit = "/categoryEdit";

var rCorrect = "/correct";

var rHistory = "/history";

export {
  rHome ,
  rView ,
  rManage ,
  rLogout ,
  rSelectRole ,
  rOrder ,
  rFlash ,
  rFlashEdit ,
  rContractManager ,
  rContractManagerEdit ,
  rContractView ,
  rContractEdit ,
  rQuota ,
  rQuotaDetailEdit ,
  rQuotaDetailCreate ,
  rModelVersionGrid ,
  rModelVersionGridEdit ,
  rMarginGrid ,
  rModelVersionList ,
  rBonuses ,
  rContract ,
  rAttachment ,
  rPCDAgreement ,
  rCategory ,
  rCategoryEdit ,
  rCorrect ,
  rCompany ,
  rCompanyVirtual ,
  rDealer ,
  rHistory ,
  rMarginMinimum ,
  rVisibilityProfiles ,
  rGemo ,
  rSubmarket ,
  rQuotaExclusions ,
  rUpload ,
  rContractErrors ,
  rFinancialCode ,
  rBrands ,
  rEnergies ,
  rFlashPublish ,
  rQuotaPARC ,
  rAnomalies ,
  rActionReferential ,
  getMenuRoutes ,
}
/* EnvVar-DvmAdminFrontendFr Not a pure module */
