// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../../../common/components/icons/App_Icon.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../../intl/Intl_Utils.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../../theme/Theme_Colors.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../../../../common/components/App_LabeledText.bs.js";
import * as App_Types_Status$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Status.bs.js";
import * as App_Styles_Status$DvmAdminFrontendFr from "../../../../../../common/styles/App_Styles_Status.bs.js";
import * as Order_Detail_Styles$DvmAdminFrontendFr from "../../../Order_Detail_Styles.bs.js";
import * as Messages_Flash_Search$DvmAdminFrontendFr from "../../../../../../intl/messages/flash/Messages_Flash_Search.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Messages_Orders_Derogate$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_Derogate.bs.js";
import * as Order_Detail_Bonus_Derogate_Footer$DvmAdminFrontendFr from "./Order_Detail_Bonus_Derogate_Footer.bs.js";
import * as Order_Detail_Bonus_Derogate_Edit_Buttons$DvmAdminFrontendFr from "./Order_Detail_Bonus_Derogate_Edit_Buttons.bs.js";

function Order_Detail_Bonus_Derogate_Fleet(Props) {
  var dero = Props.dero;
  var color = Props.color;
  var order = Props.order;
  var orderId = Props.orderId;
  var reloadPage = Props.reloadPage;
  var openEditModal = Props.openEditModal;
  var intl = ReactIntl.useIntl();
  return React.createElement(Core.Grid, {
              children: null,
              className: Order_Detail_Styles$DvmAdminFrontendFr.helpersBoxNew,
              container: true,
              direction: "column",
              spacing: 1,
              style: {
                borderLeft: "4px solid " + color + ""
              }
            }, React.createElement(Core.Grid, {
                  children: React.createElement(Core.Grid, {
                        children: null,
                        container: true,
                        item: true,
                        wrap: "nowrap"
                      }, React.createElement(Core.Grid, {
                            alignItems: "flex-start",
                            children: React.createElement(Core.Grid, {
                                  alignItems: "center",
                                  children: null,
                                  container: true,
                                  item: true,
                                  spacing: 1
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Box, {
                                            className: App_Styles_Status$DvmAdminFrontendFr.oval,
                                            bgcolor: App_Styles_Status$DvmAdminFrontendFr.stateColors(Belt_Option.getWithDefault(dero.state, /* EMPTYSTATUS */10))
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: App_Types_Status$DvmAdminFrontendFr.toString(Belt_Option.getWithDefault(dero.state, /* EMPTYSTATUS */10)),
                                      item: true
                                    })),
                            className: Order_Detail_Styles$DvmAdminFrontendFr.bonusesItemNew,
                            container: true,
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                  label: Messages_Orders_Detail$DvmAdminFrontendFr.actionAmount,
                                  children: "DERO"
                                }),
                            className: Order_Detail_Styles$DvmAdminFrontendFr.bonusesItemNew,
                            container: true,
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                  label: Messages_Flash_Search$DvmAdminFrontendFr.flashMarketingActionID,
                                  children: dero.derogateId
                                }),
                            className: Order_Detail_Styles$DvmAdminFrontendFr.bonusesItemNew,
                            container: true,
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                  label: Messages_Flash_Search$DvmAdminFrontendFr.bonusLabel,
                                  children: Belt_Option.getWithDefault(dero.derogateLabel, "-")
                                }),
                            className: Order_Detail_Styles$DvmAdminFrontendFr.bonusesItemDoubleWide,
                            container: true,
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                  label: Messages_Orders_Derogate$DvmAdminFrontendFr.validation,
                                  children: Belt_Option.mapWithDefault(dero.validation, "-", (function (x) {
                                          if (x.length === 0) {
                                            return "-";
                                          } else {
                                            return x;
                                          }
                                        }))
                                }),
                            className: Order_Detail_Styles$DvmAdminFrontendFr.bonusesItemNew,
                            container: true,
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                  label: Messages_Orders_Detail$DvmAdminFrontendFr.preinvoiceDate,
                                  children: Belt_Option.mapWithDefault(dero.preinvoiceDate, "-", (function (param) {
                                          return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                        }))
                                }),
                            className: Order_Detail_Styles$DvmAdminFrontendFr.bonusesItemNew,
                            container: true,
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                  label: Messages_Orders_Detail$DvmAdminFrontendFr.preinvoiceNumber,
                                  children: Belt_Option.mapWithDefault(dero.preinvoiceNumber, "-", (function (x) {
                                          if (x.length === 0) {
                                            return "-";
                                          } else {
                                            return x;
                                          }
                                        }))
                                }),
                            className: Order_Detail_Styles$DvmAdminFrontendFr.bonusesItemNew,
                            container: true,
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                  label: Messages_Orders_Detail$DvmAdminFrontendFr.bapInvoiceDate,
                                  children: Belt_Option.mapWithDefault(dero.invoiceDate, "-", (function (param) {
                                          return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                        }))
                                }),
                            className: Order_Detail_Styles$DvmAdminFrontendFr.bonusesItemNew,
                            container: true,
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                  label: Messages_Orders_Detail$DvmAdminFrontendFr.actionAmount,
                                  children: React.createElement(Core.Grid, {
                                        alignItems: "flex-start",
                                        children: null,
                                        container: true,
                                        item: true,
                                        spacing: 1,
                                        wrap: "nowrap"
                                      }, React.createElement(Core.Grid, {
                                            children: Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, Belt_Option.mapWithDefault(dero.amount, 0, (function (x) {
                                                        if (dero.negative) {
                                                          return - x;
                                                        } else {
                                                          return x;
                                                        }
                                                      }))),
                                            item: true
                                          }), Belt_Option.mapWithDefault(dero.state, false, (function (x) {
                                              if (x === /* PREINVOICED */5 || x === /* NOBONUS */3) {
                                                return true;
                                              } else {
                                                return x === /* PAID */4;
                                              }
                                            })) ? React.createElement(Core.Grid, {
                                              children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                    size: /* Large */3,
                                                    type_: /* Tick */1,
                                                    color: Theme_Colors$DvmAdminFrontendFr.accentGreen
                                                  }),
                                              item: true
                                            }) : null)
                                }),
                            className: Order_Detail_Styles$DvmAdminFrontendFr.bonusesItemNew,
                            container: true,
                            item: true
                          })),
                  container: true,
                  direction: "column",
                  item: true
                }), React.createElement(Order_Detail_Bonus_Derogate_Footer$DvmAdminFrontendFr.make, {
                  dero: dero,
                  order: order
                }), React.createElement(Order_Detail_Bonus_Derogate_Edit_Buttons$DvmAdminFrontendFr.make, {
                  dero: dero,
                  order: order,
                  orderId: orderId,
                  reloadPage: reloadPage,
                  openEditModal: openEditModal
                }));
}

var Styles;

var make = Order_Detail_Bonus_Derogate_Fleet;

export {
  Styles ,
  make ,
}
/* react Not a pure module */
