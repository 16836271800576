// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../../common/components/button/App_Button.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../../../../common/components/App_Checkbox.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_SelectFormInput.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Flash_Edit_NonFleet_New_Vehicle_Version_Excel_Preview$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_New_Vehicle_Version_Excel_Preview.bs.js";

function getVersionFromAvailable(version, availableVersionsTyped) {
  return Belt_List.getBy(availableVersionsTyped, (function (available) {
                if (available.modelVersion === version) {
                  return true;
                } else {
                  return Belt_Option.getWithDefault(Belt_Array.get(available.modelVersion.split(" - "), 1), "") === version;
                }
              }));
}

function solveDefaultColumn(excelData) {
  var keys = Belt_MapString.keysToArray(excelData);
  if (keys.length === 1) {
    return Belt_Option.mapWithDefault(Belt_Array.get(keys, 0), undefined, (function (x) {
                  return x;
                }));
  }
  
}

function Flash_Edit_NonFleet_New_Vehicle_Version_Excel_Modal(Props) {
  var onClose = Props.onClose;
  var excelData = Props.excelData;
  var availableVersionsTyped = Props.availableVersionsTyped;
  var form = Props.form;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return false;
      });
  var setSaveOnlyNewOnes = match[1];
  var saveOnlyNewOnes = match[0];
  var match$1 = React.useState(function () {
        return solveDefaultColumn(excelData);
      });
  var setSelectedSheet = match$1[1];
  var selectedSheet = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setSelectedColumn = match$2[1];
  var selectedColumn = match$2[0];
  var match$3 = React.useState(function () {
        return /* [] */0;
      });
  var setColumnValues = match$3[1];
  var columnValues = match$3[0];
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var setSelectedVersionsNotFound = match$4[1];
  var selectedVersionsNotFound = match$4[0];
  var match$5 = React.useState(function () {
        return /* [] */0;
      });
  var setVersionsFound = match$5[1];
  var versionsFound = match$5[0];
  React.useEffect((function () {
          Curry._1(setSelectedColumn, (function (param) {
                  
                }));
          Curry._1(setColumnValues, (function (param) {
                  return /* [] */0;
                }));
          Curry._1(setSelectedVersionsNotFound, (function (param) {
                  return /* [] */0;
                }));
          Curry._1(setVersionsFound, (function (param) {
                  return /* [] */0;
                }));
        }), [selectedSheet]);
  React.useEffect((function () {
          var versionsNotFound = Belt_List.keep(columnValues, (function (version) {
                  return Belt_Option.isNone(getVersionFromAvailable(version, availableVersionsTyped));
                }));
          var versionsFound = Belt_List.keepMap(columnValues, (function (version) {
                  return Belt_Option.mapWithDefault(getVersionFromAvailable(version, availableVersionsTyped), undefined, (function (x) {
                                return x;
                              }));
                }));
          Curry._1(setSelectedVersionsNotFound, (function (param) {
                  return versionsNotFound;
                }));
          Curry._1(setVersionsFound, (function (param) {
                  return versionsFound;
                }));
        }), [columnValues]);
  React.useEffect((function () {
          Belt_Option.mapWithDefault(selectedColumn, undefined, (function (column) {
                  Belt_Option.mapWithDefault(Belt_Int.fromString(column), undefined, (function (columnInt) {
                          Belt_Option.mapWithDefault(selectedSheet, undefined, (function (x) {
                                  Belt_Option.mapWithDefault(Belt_MapString.get(excelData, x), undefined, (function (excelTable) {
                                          Curry._1(setColumnValues, (function (param) {
                                                  return Belt_List.fromArray(Belt_Array.map(Belt_Array.keepMap(excelTable, (function (row) {
                                                                        return Belt_Array.get(row, columnInt);
                                                                      })), (function (val) {
                                                                    return Belt_Option.getWithDefault(Js_json.decodeString(val), "undecoded");
                                                                  })));
                                                }));
                                        }));
                                }));
                        }));
                }));
        }), [selectedColumn]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.uploadVersions),
              minWidth: "80vw",
              children: React.createElement(Core.Grid, {
                    children: null,
                    container: true,
                    direction: "column",
                    spacing: 3
                  }, React.createElement(Core.Grid, {
                        children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, {
                              label: Messages_Flash_Edit$DvmAdminFrontendFr.excelSheetSelect,
                              options: Belt_List.fromArray(Belt_Array.map(Belt_MapString.keysToArray(excelData), (function (x) {
                                          return {
                                                  label: {
                                                    TAG: /* String */3,
                                                    _0: x
                                                  },
                                                  value: x
                                                };
                                        }))),
                              value: {
                                TAG: /* Single */0,
                                _0: {
                                  value: Belt_Option.getWithDefault(selectedSheet, ""),
                                  onChange: (function (val) {
                                      Curry._1(setSelectedSheet, (function (param) {
                                              return val;
                                            }));
                                    })
                                }
                              },
                              id: "selectedSheet",
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                            }),
                        item: true
                      }), Belt_Option.mapWithDefault(selectedSheet, null, (function (x) {
                          return Belt_Option.mapWithDefault(Belt_MapString.get(excelData, x), null, (function (excelTable) {
                                        return React.createElement(React.Fragment, undefined, React.createElement(Flash_Edit_NonFleet_New_Vehicle_Version_Excel_Preview$DvmAdminFrontendFr.make, {
                                                        excelTable: excelTable,
                                                        selectedColumn: selectedColumn,
                                                        selectedVersionsNotFound: selectedVersionsNotFound
                                                      }), React.createElement(Core.Grid, {
                                                        children: null,
                                                        container: true,
                                                        direction: "row",
                                                        item: true,
                                                        justifyContent: "space-between",
                                                        wrap: "nowrap"
                                                      }, React.createElement(Core.Grid, {
                                                            children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, {
                                                                  label: Messages_Flash_Edit$DvmAdminFrontendFr.excelColumn,
                                                                  options: Belt_List.fromArray(Belt_Array.map(Belt_Array.mapWithIndex(Belt_Option.getWithDefault(Belt_Array.get(excelTable, 0), []), (function (index, param) {
                                                                                  return String(index);
                                                                                })), (function (x) {
                                                                              return {
                                                                                      label: {
                                                                                        TAG: /* String */3,
                                                                                        _0: String(Belt_Option.getWithDefault(Belt_Int.fromString(x), 0) + 1 | 0)
                                                                                      },
                                                                                      value: x
                                                                                    };
                                                                            }))),
                                                                  value: {
                                                                    TAG: /* Single */0,
                                                                    _0: {
                                                                      value: Belt_Option.getWithDefault(selectedColumn, ""),
                                                                      onChange: (function (val) {
                                                                          Curry._1(setSelectedColumn, (function (param) {
                                                                                  return val;
                                                                                }));
                                                                        })
                                                                    }
                                                                  },
                                                                  id: "selectedColumn",
                                                                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                                                                }),
                                                            item: true
                                                          }), React.createElement(Core.Grid, {
                                                            alignItems: "flex-end",
                                                            children: null,
                                                            container: true,
                                                            direction: "column",
                                                            item: true
                                                          }, Belt_List.length(selectedVersionsNotFound) > 0 ? React.createElement(Core.Grid, {
                                                                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                                        color: "error",
                                                                        style: {
                                                                          fontWeight: "600"
                                                                        },
                                                                        children: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.versionsNotFoundAndNotSaved, {
                                                                              count: Belt_List.length(selectedVersionsNotFound)
                                                                            }),
                                                                        variant: "body"
                                                                      }),
                                                                  item: true
                                                                }) : null, React.createElement(Core.Grid, {
                                                                children: React.createElement(Core.FormControlLabel, {
                                                                      classes: {
                                                                        label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                                                      },
                                                                      control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                                                            checked: saveOnlyNewOnes,
                                                                            onClick: (function ($$event) {
                                                                                Curry._1(setSaveOnlyNewOnes, (function (param) {
                                                                                        return $$event.target.checked;
                                                                                      }));
                                                                              })
                                                                          }),
                                                                      label: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.excelRemoveAlreadySelected)
                                                                    }),
                                                                item: true
                                                              }), React.createElement(Core.Grid, {
                                                                children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                                      onClick: (function (param) {
                                                                          var mappedOnlyNew = function (param) {
                                                                            return Belt_List.map(Belt_MapString.toList(Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(Belt_List.map(versionsFound, (function (x) {
                                                                                                              return {
                                                                                                                      label: {
                                                                                                                        TAG: /* String */3,
                                                                                                                        _0: x.modelVersion + (
                                                                                                                          x.modelVersionName.length > 0 ? " (" + x.modelVersionName + ")" : ""
                                                                                                                        )
                                                                                                                      },
                                                                                                                      value: x.modelVersion
                                                                                                                    };
                                                                                                            })), (function (x) {
                                                                                                          return [
                                                                                                                  x.value,
                                                                                                                  x.label
                                                                                                                ];
                                                                                                        }))))), (function (param) {
                                                                                          return {
                                                                                                  label: param[1],
                                                                                                  value: param[0]
                                                                                                };
                                                                                        }));
                                                                          };
                                                                          var mapWithOld = function (param) {
                                                                            return Belt_List.map(Belt_MapString.toList(Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(Belt_List.concat(mappedOnlyNew(undefined), form.input.versions), (function (x) {
                                                                                                          return [
                                                                                                                  x.value,
                                                                                                                  x.label
                                                                                                                ];
                                                                                                        }))))), (function (param) {
                                                                                          return {
                                                                                                  label: param[1],
                                                                                                  value: param[0]
                                                                                                };
                                                                                        }));
                                                                          };
                                                                          Curry._2(form.updateVersions, (function (input, versions) {
                                                                                  return {
                                                                                          models: input.models,
                                                                                          modelPhases: input.modelPhases,
                                                                                          mappingDirection: input.mappingDirection,
                                                                                          engineTypes: input.engineTypes,
                                                                                          versions: versions,
                                                                                          brandsNewCar: input.brandsNewCar,
                                                                                          vehicleType: input.vehicleType,
                                                                                          includeAllModels: input.includeAllModels
                                                                                        };
                                                                                }), saveOnlyNewOnes ? mappedOnlyNew(undefined) : mapWithOld(undefined));
                                                                          Curry._1(onClose, undefined);
                                                                        }),
                                                                      label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                                                      size: "medium"
                                                                    }),
                                                                item: true
                                                              }))));
                                      }));
                        })))
            });
}

var InputStyles;

var Types;

var Form;

var make = Flash_Edit_NonFleet_New_Vehicle_Version_Excel_Modal;

export {
  InputStyles ,
  Types ,
  Form ,
  getVersionFromAvailable ,
  solveDefaultColumn ,
  make ,
}
/* react Not a pure module */
