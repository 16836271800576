// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as FileSaver from "file-saver";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../../common/utils/Utils_Date.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";

function Order_Search_Export_Menu(Props) {
  var query = Props.query;
  var disabled = Props.disabled;
  var showHistory = Props.showHistory;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var alert = ReactAlert.useAlert();
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$2[1];
  var tmp = {
    onClick: (function (param) {
        Curry._1(setResult, (function (param) {
                return /* Pending */1;
              }));
        RestApi$DvmAdminFrontendFr.WithBlobResponse.call("GET", undefined, userManager, selectedRole, "/orders/commandes/excel" + query + (
                showHistory ? "&history=true" : "&history=false"
              )).then(function (result) {
              if (result.TAG === /* OkResult */0) {
                FileSaver.saveAs(result._0, "commandes" + ("" + (
                        showHistory ? "_avec_historique" : ""
                      ) + "_") + Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(Caml_option.some(new Date(Date.now()))), "") + " ⚠️ Restricted B.xlsx");
                return Curry._1(setResult, (function (param) {
                              return {
                                      TAG: /* Ok */0,
                                      _0: undefined
                                    };
                            }));
              }
              var error = result._0;
              Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                        message: {
                          TAG: /* RestError */2,
                          _0: error
                        }
                      }));
              Curry._1(setResult, (function (param) {
                      return {
                              TAG: /* Error */1,
                              _0: error
                            };
                    }));
            });
      }),
    children: React.createElement(Core.Grid, {
          children: null,
          container: true,
          wrap: "nowrap"
        }, React.createElement(Core.Grid, {
              alignItems: "center",
              children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                    size: /* Large */3,
                    type_: /* Export */62,
                    color: Theme_Colors$DvmAdminFrontendFr.secondaryBlue
                  }),
              container: true,
              item: true,
              style: {
                paddingRight: "3px"
              }
            }), React.createElement(Core.Grid, {
              children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                    noWrap: true,
                    style: {
                      color: Theme_Colors$DvmAdminFrontendFr.secondaryBlue,
                      fontWeight: "600"
                    },
                    children: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.exportButton),
                    variant: "subtitle"
                  }),
              item: true
            }))
  };
  if (disabled !== undefined) {
    tmp.disabled = disabled;
  }
  return React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
              show: App_Types_Result$DvmAdminFrontendFr.isPending(match$2[0]),
              children: React.createElement(Core.Button, tmp)
            });
}

var make = Order_Search_Export_Menu;

export {
  make ,
}
/* react Not a pure module */
