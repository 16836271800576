// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../../common/components/button/App_Button.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../../intl/Intl_Utils.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Orders_Derogate$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_Derogate.bs.js";
import * as Messages_Orders_DealerRequest$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_DealerRequest.bs.js";

function Order_Detail_Bonus_DealerRequest_Confirm(Props) {
  var amount = Props.amount;
  var onClose = Props.onClose;
  var onOk = Props.onOk;
  var modification = Props.modification;
  var intl = ReactIntl.useIntl();
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(modification ? Messages_Orders_DealerRequest$DvmAdminFrontendFr.modifyConfirmTitle : Messages_Orders_Derogate$DvmAdminFrontendFr.derogateConfirmTitle),
              minWidth: "400px",
              children: null
            }, React.createElement(Core.Box, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        gutterBottom: true,
                        style: {
                          fontWeight: "600"
                        },
                        children: modification ? intl.formatMessage(Messages_Orders_DealerRequest$DvmAdminFrontendFr.modifyConfirmInfo) : intl.formatMessage(Messages_Orders_Derogate$DvmAdminFrontendFr.derogateConfirmInfo, {
                                amount: Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, 0, amount)
                              }),
                        variant: "h5"
                      }),
                  mb: 3
                }), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  justify: "flex-end",
                  spacing: 2
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                            onClick: onClose,
                            label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.cancel),
                            color: "primaryBorder",
                            size: "small"
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                            onClick: onOk,
                            label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                            size: "small"
                          }),
                      item: true
                    })));
}

var make = Order_Detail_Bonus_DealerRequest_Confirm;

export {
  make ,
}
/* react Not a pure module */
