// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";

function contractRow_encode(v) {
  return Js_dict.fromArray([
              [
                "contractID",
                Decco.stringToJson(v.contractID)
              ],
              [
                "siren",
                Decco.optionToJson(Decco.stringToJson, v.siren)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ],
              [
                "managerID",
                Decco.optionToJson(Decco.stringToJson, v.managerID)
              ],
              [
                "managerName",
                Decco.optionToJson(Decco.stringToJson, v.managerName)
              ],
              [
                "budget",
                Decco.optionToJson(Decco.stringToJson, v.budget)
              ],
              [
                "contractLabel",
                Decco.optionToJson(Decco.stringToJson, v.contractLabel)
              ],
              [
                "referenceLLD",
                Decco.optionToJson(Decco.stringToJson, v.referenceLLD)
              ],
              [
                "overallEndDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.overallEndDate)
              ],
              [
                "newStartDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.newStartDate)
              ],
              [
                "editingStartDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.editingStartDate)
              ]
            ]);
}

function contractRow_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var contractID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractID"), null));
  if (contractID.TAG === /* Ok */0) {
    var siren = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "siren"), null));
    if (siren.TAG === /* Ok */0) {
      var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
      if (startDate.TAG === /* Ok */0) {
        var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
        if (endDate.TAG === /* Ok */0) {
          var managerID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "managerID"), null));
          if (managerID.TAG === /* Ok */0) {
            var managerName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "managerName"), null));
            if (managerName.TAG === /* Ok */0) {
              var budget = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "budget"), null));
              if (budget.TAG === /* Ok */0) {
                var contractLabel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractLabel"), null));
                if (contractLabel.TAG === /* Ok */0) {
                  var referenceLLD = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "referenceLLD"), null));
                  if (referenceLLD.TAG === /* Ok */0) {
                    var overallEndDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "overallEndDate"), null));
                    if (overallEndDate.TAG === /* Ok */0) {
                      var newStartDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "newStartDate"), null));
                      if (newStartDate.TAG === /* Ok */0) {
                        var editingStartDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "editingStartDate"), null));
                        if (editingStartDate.TAG === /* Ok */0) {
                          return {
                                  TAG: /* Ok */0,
                                  _0: {
                                    contractID: contractID._0,
                                    siren: siren._0,
                                    startDate: startDate._0,
                                    endDate: endDate._0,
                                    managerID: managerID._0,
                                    managerName: managerName._0,
                                    budget: budget._0,
                                    contractLabel: contractLabel._0,
                                    referenceLLD: referenceLLD._0,
                                    overallEndDate: overallEndDate._0,
                                    newStartDate: newStartDate._0,
                                    editingStartDate: editingStartDate._0
                                  }
                                };
                        }
                        var e = editingStartDate._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".editingStartDate" + e.path,
                                  message: e.message,
                                  value: e.value
                                }
                              };
                      }
                      var e$1 = newStartDate._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".newStartDate" + e$1.path,
                                message: e$1.message,
                                value: e$1.value
                              }
                            };
                    }
                    var e$2 = overallEndDate._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".overallEndDate" + e$2.path,
                              message: e$2.message,
                              value: e$2.value
                            }
                          };
                  }
                  var e$3 = referenceLLD._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".referenceLLD" + e$3.path,
                            message: e$3.message,
                            value: e$3.value
                          }
                        };
                }
                var e$4 = contractLabel._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".contractLabel" + e$4.path,
                          message: e$4.message,
                          value: e$4.value
                        }
                      };
              }
              var e$5 = budget._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".budget" + e$5.path,
                        message: e$5.message,
                        value: e$5.value
                      }
                    };
            }
            var e$6 = managerName._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".managerName" + e$6.path,
                      message: e$6.message,
                      value: e$6.value
                    }
                  };
          }
          var e$7 = managerID._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".managerID" + e$7.path,
                    message: e$7.message,
                    value: e$7.value
                  }
                };
        }
        var e$8 = endDate._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".endDate" + e$8.path,
                  message: e$8.message,
                  value: e$8.value
                }
              };
      }
      var e$9 = startDate._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".startDate" + e$9.path,
                message: e$9.message,
                value: e$9.value
              }
            };
    }
    var e$10 = siren._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".siren" + e$10.path,
              message: e$10.message,
              value: e$10.value
            }
          };
  }
  var e$11 = contractID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".contractID" + e$11.path,
            message: e$11.message,
            value: e$11.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(contractRow_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(contractRow_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var Contract = {
  contractRow_encode: contractRow_encode,
  contractRow_decode: contractRow_decode,
  searchResult_encode: searchResult_encode,
  searchResult_decode: searchResult_decode
};

var Fields_budget = [
  "budget",
  "Budget"
];

var Fields_contractID = [
  "contractID",
  "ID_Prot_Loueur"
];

var Fields_contractLabel = [
  "contractLabel",
  "Nom_du_contrat"
];

var Fields_siren = [
  "siren",
  "siren"
];

var Fields_sirenName = [
  "sirenName",
  "Nom_de_SIREN"
];

var Fields_managerID = [
  "managerID",
  "Intervenant_ID"
];

var Fields_managerName = [
  "managerName",
  "Nom_Intervenant"
];

var Fields_startDate = [
  "startDate",
  "Date_debut"
];

var Fields_endDate = [
  "endDate",
  "Date_fin"
];

var Fields = {
  budget: Fields_budget,
  contractID: Fields_contractID,
  contractLabel: Fields_contractLabel,
  siren: Fields_siren,
  sirenName: Fields_sirenName,
  managerID: Fields_managerID,
  managerName: Fields_managerName,
  startDate: Fields_startDate,
  endDate: Fields_endDate
};

function t_encode(v) {
  return Js_dict.fromArray([
              [
                "managerID",
                Decco.stringToJson(v.managerID)
              ],
              [
                "managerName",
                Decco.optionToJson(Decco.stringToJson, v.managerName)
              ],
              [
                "budgets",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(Decco.stringToJson, param);
                      }), v.budgets)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var managerID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "managerID"), null));
  if (managerID.TAG === /* Ok */0) {
    var managerName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "managerName"), null));
    if (managerName.TAG === /* Ok */0) {
      var budgets = Decco.optionFromJson((function (param) {
              return Decco.listFromJson(Decco.stringFromJson, param);
            }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "budgets"), null));
      if (budgets.TAG === /* Ok */0) {
        var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
        if (startDate.TAG === /* Ok */0) {
          var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
          if (endDate.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      managerID: managerID._0,
                      managerName: managerName._0,
                      budgets: budgets._0,
                      startDate: startDate._0,
                      endDate: endDate._0
                    }
                  };
          }
          var e = endDate._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".endDate" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = startDate._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".startDate" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = budgets._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".budgets" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = managerName._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".managerName" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = managerID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".managerID" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function managers_encode(v) {
  return Js_dict.fromArray([[
                "content",
                Decco.listToJson(t_encode, v.content)
              ]]);
}

function managers_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var content = Decco.listFromJson(t_decode, Belt_Option.getWithDefault(Js_dict.get(dict._0, "content"), null));
  if (content.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              content: content._0
            }
          };
  }
  var e = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var Manager = {
  t_encode: t_encode,
  t_decode: t_decode,
  managers_encode: managers_encode,
  managers_decode: managers_decode
};

function contractIdItem_encode(v) {
  return Js_dict.fromArray([[
                "contractID",
                Decco.stringToJson(v.contractID)
              ]]);
}

function contractIdItem_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var contractID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "contractID"), null));
  if (contractID.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              contractID: contractID._0
            }
          };
  }
  var e = contractID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".contractID" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function contractsIdsList_encode(v) {
  return Decco.listToJson(contractIdItem_encode, v);
}

function contractsIdsList_decode(v) {
  return Decco.listFromJson(contractIdItem_decode, v);
}

export {
  Contract ,
  Fields ,
  Manager ,
  contractIdItem_encode ,
  contractIdItem_decode ,
  contractsIdsList_encode ,
  contractsIdsList_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
