// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../api/RestApi.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../../../common/utils/Utils_Date.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Error_Inputs.bs.js";
import * as Messages_Contract_Create$DvmAdminFrontendFr from "../../../../intl/messages/contract/Messages_Contract_Create.bs.js";
import * as Contract_Attachments_Types$DvmAdminFrontendFr from "./Contract_Attachments_Types.bs.js";

function fetchSiren(userManager, contractId, setSirenNameResult, selectedRole, contractStartDate, contractEndDate, siren) {
  Curry._1(setSirenNameResult, (function (param) {
          return /* Pending */1;
        }));
  var contractStartDateStr = Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(contractStartDate), "");
  var contractEndDateStr = Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(contractEndDate), "");
  var attachmentOverlapsCheck = function (param) {
    return RestApi$DvmAdminFrontendFr.call("GET", Contract_Attachments_Types$DvmAdminFrontendFr.contractAttachments_decode, undefined, userManager, selectedRole, "/contract/attachments?limit=10&offset=0&filter=contractId=" + contractId + ";siren=" + siren + ";startDate=%5B" + contractStartDateStr + "%5C," + contractEndDateStr + "%5D").then(function (result) {
                if (result.TAG === /* OkResult */0) {
                  var match = result._0.total;
                  if (match !== 0) {
                    return Promise.resolve({
                                TAG: /* Error */1,
                                _0: Messages_Contract_Create$DvmAdminFrontendFr.attachmentExists
                              });
                  } else {
                    return RestApi$DvmAdminFrontendFr.call("GET", Contract_Attachments_Types$DvmAdminFrontendFr.SirenName.siren_decode, undefined, userManager, selectedRole, "/contract/siren?siren=" + siren + "").then(function (result) {
                                if (result.TAG === /* OkResult */0) {
                                  var sirenName = result._0;
                                  Curry._1(setSirenNameResult, (function (param) {
                                          return {
                                                  TAG: /* Ok */0,
                                                  _0: [
                                                    siren,
                                                    sirenName.response
                                                  ]
                                                };
                                        }));
                                  return {
                                          TAG: /* Ok */0,
                                          _0: siren
                                        };
                                }
                                var error = result._0;
                                Curry._1(setSirenNameResult, (function (param) {
                                        return {
                                                TAG: /* Error */1,
                                                _0: error
                                              };
                                      }));
                                return {
                                        TAG: /* Error */1,
                                        _0: Messages_Error_Inputs$DvmAdminFrontendFr.sirenNotFound
                                      };
                              });
                  }
                }
                var error = result._0;
                Curry._1(setSirenNameResult, (function (param) {
                        return {
                                TAG: /* Error */1,
                                _0: error
                              };
                      }));
                return Promise.resolve({
                            TAG: /* Error */1,
                            _0: App_Types_Result$DvmAdminFrontendFr.Rest.toMessage(error)[0]
                          });
              });
  };
  return RestApi$DvmAdminFrontendFr.call("GET", Contract_Attachments_Types$DvmAdminFrontendFr.ClosedSiren.closedSiren_decode, undefined, userManager, selectedRole, "/sirets/siren/live?siren=" + siren + "").then(function (result) {
              if (result.TAG === /* OkResult */0) {
                return Belt_Option.mapWithDefault(result._0.response, attachmentOverlapsCheck(undefined), (function (foundEndDate) {
                              var contractStart = Belt_Option.getWithDefault(contractStartDate, new Date(0.0));
                              if (ReDate.isBefore(foundEndDate, contractStart)) {
                                return Promise.resolve({
                                            TAG: /* Error */1,
                                            _0: Messages_Contract_Create$DvmAdminFrontendFr.attachmentSIRENendDateTooLow
                                          });
                              } else {
                                return attachmentOverlapsCheck(undefined);
                              }
                            }));
              }
              var error = result._0;
              Curry._1(setSirenNameResult, (function (param) {
                      return {
                              TAG: /* Error */1,
                              _0: error
                            };
                    }));
              return Promise.resolve({
                          TAG: /* Error */1,
                          _0: App_Types_Result$DvmAdminFrontendFr.Rest.toMessage(error)[0]
                        });
            });
}

function saveAttachments(setResult, userManager, selectedRole, attachments) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
          TAG: /* Json */0,
          _0: Contract_Attachments_Types$DvmAdminFrontendFr.attachmentsSaveRequest_encode(attachments)
        }, userManager, selectedRole, "/contract/attachment").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function updateOrDeleteAttachment(setResult, userManager, operation, currentAttachment, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", {
          TAG: /* Json */0,
          _0: Contract_Attachments_Types$DvmAdminFrontendFr.attachment_encode(currentAttachment)
        }, userManager, selectedRole, "/contract/attachment").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          if (operation) {
            RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
                    TAG: /* Json */0,
                    _0: Contract_Attachments_Types$DvmAdminFrontendFr.attachmentsSaveRequest_encode({
                          hd: operation._0,
                          tl: /* [] */0
                        })
                  }, userManager, selectedRole, "/contract/attachment").then(function (result) {
                  if (result.TAG === /* OkResult */0) {
                    return Curry._1(setResult, (function (param) {
                                  return {
                                          TAG: /* Ok */0,
                                          _0: undefined
                                        };
                                }));
                  }
                  var error = result._0;
                  Curry._1(setResult, (function (param) {
                          return {
                                  TAG: /* Error */1,
                                  _0: error
                                };
                        }));
                });
            return ;
          } else {
            return Curry._1(setResult, (function (param) {
                          return {
                                  TAG: /* Ok */0,
                                  _0: undefined
                                };
                        }));
          }
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchAttachments(queryParams, setResult, userManager, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Contract_Attachments_Types$DvmAdminFrontendFr.contractAttachments_decode, undefined, userManager, selectedRole, "/contract/attachments" + queryParams + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var attachments = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: attachments
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

export {
  fetchSiren ,
  saveAttachments ,
  updateOrDeleteAttachment ,
  fetchAttachments ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
