// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../common/utils/Utils_Api.bs.js";
import * as App_Spinner$DvmAdminFrontendFr from "../../../../common/components/App_Spinner.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as App_Table_Result$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Result.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Pagination.bs.js";
import * as Action_Referential_Api$DvmAdminFrontendFr from "./Action_Referential_Api.bs.js";
import * as Action_Referential_Table$DvmAdminFrontendFr from "./Action_Referential_Table.bs.js";
import * as Action_Referential_Filter$DvmAdminFrontendFr from "./Action_Referential_Filter.bs.js";
import * as Messages_Action_Referential$DvmAdminFrontendFr from "../../../../intl/messages/actionReferential/Messages_Action_Referential.bs.js";

function Action_Referential_Page(Props) {
  var operation = Props.operation;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match[1];
  var tablePage = match[0];
  var match$1 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: Caml_array.get(App_Table_Pagination$DvmAdminFrontendFr.options, 0),
                headers: Action_Referential_Table$DvmAdminFrontendFr.headersConfiguration,
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$1[1];
  var fetchRequest = match$1[0];
  var alert = ReactAlert.useAlert();
  var match$2 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$3 = match$2[0];
  var userManager = match$3.userManager;
  var selectedRole = match$3.selectedRole;
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var fetchData = function (submissionCallbacks, fetchRequest) {
    Action_Referential_Api$DvmAdminFrontendFr.fetchActionReferentials(submissionCallbacks, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, fetchRequest), selectedRole);
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.forError(tablePage, (function (error) {
                  Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                            message: {
                              TAG: /* RestError */2,
                              _0: error
                            }
                          }));
                }));
        }), [tablePage]);
  var tmp;
  tmp = typeof tablePage === "number" ? (
      tablePage === /* NotStarted */0 ? null : React.createElement(App_Spinner$DvmAdminFrontendFr.make, {})
    ) : (
      tablePage.TAG === /* Ok */0 ? React.createElement(App_Table_Result$DvmAdminFrontendFr.make, {
              number: tablePage._0.total,
              message: Messages_Action_Referential$DvmAdminFrontendFr.resultInfo
            }) : null
    );
  return React.createElement(React.Fragment, undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  style: {
                    fontWeight: "600"
                  },
                  children: intl.formatMessage(Messages_Action_Referential$DvmAdminFrontendFr.title),
                  variant: "h3"
                }), React.createElement(Action_Referential_Filter$DvmAdminFrontendFr.make, {
                  fetchData: fetchData,
                  fetchRequest: fetchRequest,
                  setParamsList: match$4[1]
                }), React.createElement(Core.Box, {
                  children: null
                }, tmp, React.createElement(Action_Referential_Table$DvmAdminFrontendFr.make, {
                      tablePage: tablePage,
                      fetchRequest: fetchRequest,
                      fetchData: fetchData,
                      operation: operation,
                      paramsList: match$4[0]
                    })));
}

var Api;

var Table;

var Filter;

var make = Action_Referential_Page;

export {
  Api ,
  Table ,
  Filter ,
  make ,
}
/* react Not a pure module */
