// Generated by ReScript, PLEASE EDIT WITH CARE


var renaultGroup = "#FFCC33";

var renaultGroupLight = "#FFF8E6";

var renaultGroupHover = "#FFDB75";

var alliance = "#FF5F00";

var allianceLight = "#FFEFE5";

var allianceHover = "#FF7933";

var black = "#000000";

var white = "#FFFFFF";

var whiteBackground = "#FFFFFF";

var accentGreen = "#73C366";

var accentGreenLight = "#A7FB9A";

var accentGreenHover = "#88DE7F";

var accentGreenDark = "#4EA147";

var accentOrange = "#FF8200";

var accentOrangeLight = "#FFC14C";

var accentOrangeHover = "#FFA51C";

var accentOrangeDark = "#EB6500";

var accentRed = "#CB333B";

var accentRedLight = "#FF6E6A";

var accentRedHover = "#E85252";

var accentRedDark = "#8C0012";

var primaryBrown = "#978b7f";

var primaryBrown2 = "rgba(151, 139, 127, 0.5)";

var secondaryDarkGreen = "#009b77";

var secondaryDarkGreenLight = "#7CF7CF";

var secondaryDarkGreenHover = "#55D2AC";

var secondaryDarkGreenDark = "#006747";

var secondaryBlueDuck = "#009CA6";

var secondaryBlueDuckLight = "#77EEF8";

var secondaryBlueDuckHover = "#58D1DC";

var secondaryBlueDuckDark = "#006772";

var secondaryBlue = "#0077C8";

var secondaryBlueLight = "#7AC5FF";

var secondaryBlueHover = "#5BAAFF";

var secondaryBlueDark = "#0048A0";

var secondaryDarkBlue = "#0033A0";

var secondaryDarkBlueLight = "#006ED6";

var secondaryDarkBlueHover = "#1749C4";

var secondaryDarkBlueDark = "#002280";

var secondaryDarkPurple = "#5C068C";

var secondaryDarkPurpleLight = "#AD5DDE";

var secondaryDarkPurpleHover = "#9143C2";

var secondaryDarkPurpleDark = "#430068";

var secondaryPurple = "#9E007E";

var secondaryPurpleLight = "#D750B3";

var secondaryPurpleHover = "#BA3198";

var secondaryPurpleDark = "#810065";

var grayLight = "#FBFBFB";

var grayLight1 = "#FAF9F8";

var grayLight2 = "#F1F1F2";

var grayLight3 = "#d9d8d6";

var grayLight4 = "#BBBBBB";

var grayBackground = "#EEEEEE";

var grayHover = "#BBBCBC";

var grayHover2 = "rgba(0, 0, 0, 0.04)";

var grayMain = "#999999";

var grayDark1 = "#888B8D";

var grayDark2 = "#53565A";

var grayDark3 = "#333333";

var grayDark4 = "#222222";

var red = "#cb4b32";

var mineralDarker = "#60504a";

export {
  renaultGroup ,
  renaultGroupLight ,
  renaultGroupHover ,
  alliance ,
  allianceLight ,
  allianceHover ,
  black ,
  white ,
  whiteBackground ,
  accentGreen ,
  accentGreenLight ,
  accentGreenHover ,
  accentGreenDark ,
  accentOrange ,
  accentOrangeLight ,
  accentOrangeHover ,
  accentOrangeDark ,
  accentRed ,
  accentRedLight ,
  accentRedHover ,
  accentRedDark ,
  primaryBrown ,
  primaryBrown2 ,
  secondaryDarkGreen ,
  secondaryDarkGreenLight ,
  secondaryDarkGreenHover ,
  secondaryDarkGreenDark ,
  secondaryBlueDuck ,
  secondaryBlueDuckLight ,
  secondaryBlueDuckHover ,
  secondaryBlueDuckDark ,
  secondaryBlue ,
  secondaryBlueLight ,
  secondaryBlueHover ,
  secondaryBlueDark ,
  secondaryDarkBlue ,
  secondaryDarkBlueLight ,
  secondaryDarkBlueHover ,
  secondaryDarkBlueDark ,
  secondaryDarkPurple ,
  secondaryDarkPurpleLight ,
  secondaryDarkPurpleHover ,
  secondaryDarkPurpleDark ,
  secondaryPurple ,
  secondaryPurpleLight ,
  secondaryPurpleHover ,
  secondaryPurpleDark ,
  grayLight ,
  grayLight1 ,
  grayLight2 ,
  grayLight3 ,
  grayLight4 ,
  grayBackground ,
  grayHover ,
  grayHover2 ,
  grayMain ,
  grayDark1 ,
  grayDark2 ,
  grayDark3 ,
  grayDark4 ,
  red ,
  mineralDarker ,
}
/* No side effect */
