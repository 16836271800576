// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../../../../common/App_Api.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_SelectFormInput.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Order_Detail_Correct_Box$DvmAdminFrontendFr from "../Order_Detail_Correct_Box.bs.js";
import * as Messages_Orders_Correction$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_Correction.bs.js";
import * as Order_Detail_Correct_Utils$DvmAdminFrontendFr from "../../Order_Detail_Correct_Utils.bs.js";
import * as Order_Detail_Types_Order_Type$DvmAdminFrontendFr from "../../../Order_Detail_Types_Order_Type.bs.js";

function Order_Detail_Correct_Order_New_Fleet(Props) {
  var form = Props.form;
  var formAdditional = Props.formAdditional;
  var order = Props.order;
  var tooltipSpacingRef = Props.tooltipSpacingRef;
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var intl = ReactIntl.useIntl();
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setBudgets = match$2[1];
  var allowMainOrderInfoCorrection = Belt_Option.getWithDefault(order.detail.delivered, false) && !Belt_Option.getWithDefault(order.archived, true) && Belt_Option.mapWithDefault(order.budget, true, (function (x) {
          return Belt_Option.mapWithDefault(x.state, false, (function (x) {
                        if (x === /* BLOCKED */0) {
                          return true;
                        } else {
                          return x === /* CALCULABLE */1;
                        }
                      }));
        }));
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchBudgets(userManager, setBudgets, selectedRole);
        }), []);
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.orderType,
    options: {
      hd: {
        label: {
          TAG: /* String */3,
          _0: "SO"
        },
        value: "SO"
      },
      tl: {
        hd: {
          label: {
            TAG: /* String */3,
            _0: "CL"
          },
          value: "CL"
        },
        tl: {
          hd: {
            label: {
              TAG: /* String */3,
              _0: "VD"
            },
            value: "VD"
          },
          tl: {
            hd: {
              label: {
                TAG: /* String */3,
                _0: "VE"
              },
              value: "VE"
            },
            tl: /* [] */0
          }
        }
      }
    },
    value: {
      TAG: /* Single */0,
      _0: {
        value: Order_Detail_Types_Order_Type$DvmAdminFrontendFr.toString(form.input.orderType),
        onChange: (function (val) {
            Curry._2(form.updateOrderType, (function (input, orderType) {
                    var newrecord = Caml_obj.obj_dup(input);
                    newrecord.orderType = orderType;
                    return newrecord;
                  }), Belt_Option.getWithDefault(Order_Detail_Types_Order_Type$DvmAdminFrontendFr.fromString(val), /* NOTSET */7));
          })
      }
    },
    id: "orderType",
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    disabled: !allowMainOrderInfoCorrection
  };
  if (form.orderTypeResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.orderTypeResult);
  }
  var tmp$1 = {
    label: Messages_Orders_Detail$DvmAdminFrontendFr.orderDateV2,
    value: form.input.orderDate,
    onChange: Curry._1(form.updateOrderDate, (function (input, orderDate) {
            var newrecord = Caml_obj.obj_dup(input);
            newrecord.orderDate = orderDate;
            return newrecord;
          })),
    disabled: !allowMainOrderInfoCorrection,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.orderDateResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.orderDateResult);
  }
  var tmp$2 = {
    onChange: Curry._1(form.updateDiscount, (function (input, discount) {
            var newrecord = Caml_obj.obj_dup(input);
            newrecord.discount = discount;
            return newrecord;
          })),
    value: form.input.discount,
    disabled: !allowMainOrderInfoCorrection,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Common$DvmAdminFrontendFr.discount,
    InputProps: {
      startAdornment: React.createElement(Core.InputAdornment, {
            children: "%",
            position: "start"
          })
    },
    inputType: "number"
  };
  if (form.discountResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.discountResult);
  }
  var tmp$3 = {
    label: Messages_Orders_Detail$DvmAdminFrontendFr.aquisitionType,
    options: {
      hd: {
        label: {
          TAG: /* Message */0,
          _0: Messages_Common$DvmAdminFrontendFr.nothing
        },
        value: ""
      },
      tl: {
        hd: {
          label: {
            TAG: /* String */3,
            _0: "ACPT"
          },
          value: "ACPT"
        },
        tl: {
          hd: {
            label: {
              TAG: /* String */3,
              _0: "DLOC"
            },
            value: "DLOC"
          },
          tl: /* [] */0
        }
      }
    },
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.acquisitionType,
        onChange: Curry._1(form.updateAcquisitionType, (function (input, acquisitionType) {
                var newrecord = Caml_obj.obj_dup(input);
                newrecord.acquisitionType = acquisitionType;
                return newrecord;
              }))
      }
    },
    id: "acquisitionType",
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    disabled: !allowMainOrderInfoCorrection
  };
  if (form.acquisitionTypeResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.acquisitionTypeResult);
  }
  var tmp$4 = {
    label: Messages_Common$DvmAdminFrontendFr.budget,
    options: Belt_List.add(Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$2[0], /* [] */0), (function (x) {
                return {
                        label: {
                          TAG: /* String */3,
                          _0: x
                        },
                        value: x
                      };
              })), {
          label: {
            TAG: /* Message */0,
            _0: Messages_Common$DvmAdminFrontendFr.nothing
          },
          value: ""
        }),
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.budget,
        onChange: Curry._1(form.updateBudget, (function (input, budget) {
                var newrecord = Caml_obj.obj_dup(input);
                newrecord.budget = budget;
                return newrecord;
              }))
      }
    },
    id: "budget",
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    disabled: !allowMainOrderInfoCorrection
  };
  if (form.budgetResult !== undefined) {
    tmp$4.validationResult = Caml_option.valFromOption(form.budgetResult);
  }
  var tmp$5 = {
    label: Messages_Orders_Detail$DvmAdminFrontendFr.registrationDate,
    value: form.input.registrationDate,
    onChange: Curry._1(form.updateRegistrationDate, (function (input, registrationDate) {
            var newrecord = Caml_obj.obj_dup(input);
            newrecord.registrationDate = registrationDate;
            return newrecord;
          })),
    disabled: !Order_Detail_Correct_Utils$DvmAdminFrontendFr.orderDeliveredForSevenDays(order) || !allowMainOrderInfoCorrection,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.registrationDateResult !== undefined) {
    tmp$5.validationResult = Caml_option.valFromOption(form.registrationDateResult);
  }
  var tmp$6 = {
    onChange: Curry._1(form.updateRegistrationNumber, (function (input, registrationNumber) {
            var newrecord = Caml_obj.obj_dup(input);
            newrecord.registrationNumber = registrationNumber;
            return newrecord;
          })),
    value: form.input.registrationNumber,
    disabled: !Order_Detail_Correct_Utils$DvmAdminFrontendFr.orderDeliveredForSevenDays(order) || !allowMainOrderInfoCorrection,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Orders_Detail$DvmAdminFrontendFr.registrationNumber
  };
  if (form.registrationNumberResult !== undefined) {
    tmp$6.validationResult = Caml_option.valFromOption(form.registrationNumberResult);
  }
  var tmp$7 = {
    label: Messages_Orders_Detail$DvmAdminFrontendFr.invoiceClient,
    value: form.input.invoiceClient,
    onChange: Curry._1(form.updateInvoiceClient, (function (input, invoiceClient) {
            var newrecord = Caml_obj.obj_dup(input);
            newrecord.invoiceClient = invoiceClient;
            return newrecord;
          })),
    disabled: !Order_Detail_Correct_Utils$DvmAdminFrontendFr.orderDeliveredForSevenDays(order) || !allowMainOrderInfoCorrection,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.invoiceClientResult !== undefined) {
    tmp$7.validationResult = Caml_option.valFromOption(form.invoiceClientResult);
  }
  var tmp$8 = {
    onChange: Curry._1(form.updateInvoiceNumber, (function (input, invoiceNumber) {
            var newrecord = Caml_obj.obj_dup(input);
            newrecord.invoiceNumber = invoiceNumber;
            return newrecord;
          })),
    value: form.input.invoiceNumber,
    disabled: !Order_Detail_Correct_Utils$DvmAdminFrontendFr.orderDeliveredForSevenDays(order) || !allowMainOrderInfoCorrection,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Orders_Detail$DvmAdminFrontendFr.invoiceNumber
  };
  if (form.invoiceNumberResult !== undefined) {
    tmp$8.validationResult = Caml_option.valFromOption(form.invoiceNumberResult);
  }
  var tmp$9 = {
    label: Messages_Orders_Detail$DvmAdminFrontendFr.invoiceDateV2,
    value: form.input.renaultInvoiceDate,
    onChange: Curry._1(form.updateRenaultInvoiceDate, (function (input, renaultInvoiceDate) {
            var newrecord = Caml_obj.obj_dup(input);
            newrecord.renaultInvoiceDate = renaultInvoiceDate;
            return newrecord;
          })),
    disabled: !Order_Detail_Correct_Utils$DvmAdminFrontendFr.orderDeliveredForSevenDays(order) || !allowMainOrderInfoCorrection,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.renaultInvoiceDateResult !== undefined) {
    tmp$9.validationResult = Caml_option.valFromOption(form.renaultInvoiceDateResult);
  }
  var tmp$10 = {
    label: Messages_Orders_Detail$DvmAdminFrontendFr.madaDate,
    value: form.input.carSaleAvailibilityDateMADA,
    onChange: Curry._1(form.updateCarSaleAvailibilityDateMADA, (function (input, carSaleAvailibilityDateMADA) {
            var newrecord = Caml_obj.obj_dup(input);
            newrecord.carSaleAvailibilityDateMADA = carSaleAvailibilityDateMADA;
            return newrecord;
          })),
    disabled: !Order_Detail_Correct_Utils$DvmAdminFrontendFr.orderDeliveredForSevenDays(order) || !allowMainOrderInfoCorrection,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.carSaleAvailibilityDateMADAResult !== undefined) {
    tmp$10.validationResult = Caml_option.valFromOption(form.carSaleAvailibilityDateMADAResult);
  }
  var tmp$11 = {
    label: Messages_Orders_Detail$DvmAdminFrontendFr.madcDate,
    value: form.input.carSaleAvailibilityDateMADC,
    onChange: Curry._1(form.updateCarSaleAvailibilityDateMADC, (function (input, carSaleAvailibilityDateMADC) {
            var newrecord = Caml_obj.obj_dup(input);
            newrecord.carSaleAvailibilityDateMADC = carSaleAvailibilityDateMADC;
            return newrecord;
          })),
    disabled: !Order_Detail_Correct_Utils$DvmAdminFrontendFr.orderDeliveredForSevenDays(order) || !allowMainOrderInfoCorrection,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.carSaleAvailibilityDateMADCResult !== undefined) {
    tmp$11.validationResult = Caml_option.valFromOption(form.carSaleAvailibilityDateMADCResult);
  }
  var tmp$12 = {
    onChange: Curry._1(formAdditional.updateModelID, (function (input, modelID) {
            return {
                    modelID: modelID,
                    versionID: input.versionID,
                    phase: input.phase,
                    salesmanName: input.salesmanName,
                    agentCode: input.agentCode
                  };
          })),
    value: formAdditional.input.modelID,
    disabled: !allowMainOrderInfoCorrection,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Common$DvmAdminFrontendFr.model
  };
  if (formAdditional.modelIDResult !== undefined) {
    tmp$12.validationResult = Caml_option.valFromOption(formAdditional.modelIDResult);
  }
  var tmp$13 = {
    onChange: Curry._1(formAdditional.updateVersionID, (function (input, versionID) {
            return {
                    modelID: input.modelID,
                    versionID: versionID,
                    phase: input.phase,
                    salesmanName: input.salesmanName,
                    agentCode: input.agentCode
                  };
          })),
    value: formAdditional.input.versionID,
    disabled: !allowMainOrderInfoCorrection,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Common$DvmAdminFrontendFr.version
  };
  if (formAdditional.versionIDResult !== undefined) {
    tmp$13.validationResult = Caml_option.valFromOption(formAdditional.versionIDResult);
  }
  var tmp$14 = {
    onChange: Curry._1(formAdditional.updatePhase, (function (input, phase) {
            return {
                    modelID: input.modelID,
                    versionID: input.versionID,
                    phase: phase,
                    salesmanName: input.salesmanName,
                    agentCode: input.agentCode
                  };
          })),
    value: formAdditional.input.phase,
    disabled: !allowMainOrderInfoCorrection,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Common$DvmAdminFrontendFr.phase
  };
  if (formAdditional.phaseResult !== undefined) {
    tmp$14.validationResult = Caml_option.valFromOption(formAdditional.phaseResult);
  }
  var tmp$15 = {
    onChange: Curry._1(formAdditional.updateSalesmanName, (function (input, salesmanName) {
            return {
                    modelID: input.modelID,
                    versionID: input.versionID,
                    phase: input.phase,
                    salesmanName: salesmanName,
                    agentCode: input.agentCode
                  };
          })),
    value: formAdditional.input.salesmanName,
    disabled: !Order_Detail_Correct_Utils$DvmAdminFrontendFr.orderDeliveredForSevenDays(order) || !allowMainOrderInfoCorrection,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Common$DvmAdminFrontendFr.seller
  };
  if (formAdditional.salesmanNameResult !== undefined) {
    tmp$15.validationResult = Caml_option.valFromOption(formAdditional.salesmanNameResult);
  }
  var tmp$16 = {
    onChange: Curry._1(formAdditional.updateAgentCode, (function (input, agentCode) {
            return {
                    modelID: input.modelID,
                    versionID: input.versionID,
                    phase: input.phase,
                    salesmanName: input.salesmanName,
                    agentCode: agentCode
                  };
          })),
    value: formAdditional.input.agentCode,
    disabled: !Order_Detail_Correct_Utils$DvmAdminFrontendFr.orderDeliveredForSevenDays(order) || !allowMainOrderInfoCorrection,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Common$DvmAdminFrontendFr.agent
  };
  if (formAdditional.agentCodeResult !== undefined) {
    tmp$16.validationResult = Caml_option.valFromOption(formAdditional.agentCodeResult);
  }
  return React.createElement(Order_Detail_Correct_Box$DvmAdminFrontendFr.make, {
              content: Belt_Array.keepMap([
                    allowMainOrderInfoCorrection && Order_Detail_Correct_Utils$DvmAdminFrontendFr.orderDeliveredForSevenDays(order) ? undefined : Caml_option.some(React.createElement("div", {
                                ref: tooltipSpacingRef
                              }, intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.correctionBlockedTooltip))),
                    Caml_option.some(React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, tmp)),
                    Caml_option.some(React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$1)),
                    Caml_option.some(React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                              onChange: Curry._1(form.updateSiret, (function (input, siret) {
                                      var newrecord = Caml_obj.obj_dup(input);
                                      newrecord.siret = siret;
                                      return newrecord;
                                    })),
                              value: form.input.siret,
                              disabled: !allowMainOrderInfoCorrection,
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                              label: Messages_Common$DvmAdminFrontendFr.siret
                            })),
                    Caml_option.some(React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                              onChange: Curry._1(form.updateFinalCustomer, (function (input, finalCustomer) {
                                      var newrecord = Caml_obj.obj_dup(input);
                                      newrecord.finalCustomer = finalCustomer;
                                      return newrecord;
                                    })),
                              value: form.input.finalCustomer,
                              disabled: !allowMainOrderInfoCorrection,
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.finalCustomer
                            })),
                    Caml_option.some(React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$2)),
                    Caml_option.some(React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, tmp$3)),
                    Caml_option.some(React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, tmp$4)),
                    Caml_option.some(React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$5)),
                    Caml_option.some(React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$6)),
                    Caml_option.some(React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$7)),
                    Caml_option.some(React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$8)),
                    Caml_option.some(React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$9)),
                    Caml_option.some(React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$10)),
                    Caml_option.some(React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$11)),
                    Caml_option.some(React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$12)),
                    Caml_option.some(React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$13)),
                    Caml_option.some(React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$14)),
                    Caml_option.some(React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$15)),
                    Caml_option.some(React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$16))
                  ], (function (x) {
                      return x;
                    })),
              bgcolor: Theme_Colors$DvmAdminFrontendFr.grayLight1,
              color: Theme_Colors$DvmAdminFrontendFr.secondaryDarkGreenDark,
              title: intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.correctionValues)
            });
}

var Form;

var FormAdditional;

var InputStyles;

var Utils;

var make = Order_Detail_Correct_Order_New_Fleet;

export {
  Form ,
  FormAdditional ,
  InputStyles ,
  Utils ,
  make ,
}
/* react Not a pure module */
