// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Table$DvmAdminFrontendFr from "../../../../../../../../common/components/table/App_Table.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../../../../intl/Intl_Utils.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../../../../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../../../../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Table_Simple$DvmAdminFrontendFr from "../../../../../../../../common/components/table/App_Table_Simple.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../../../common/types/App_Types_Result.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../../../../../common/components/table/App_Table_Pagination.bs.js";

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell("modelVersion", undefined, undefined, undefined, undefined, undefined, Messages_Flash_Edit$DvmAdminFrontendFr.modelVersion);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell("versionLabelCommercial", undefined, undefined, undefined, undefined, undefined, Messages_Flash_Edit$DvmAdminFrontendFr.versionLabelCommercial),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell("amount", undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.amount),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell("startDate", undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.startDate),
      tl: /* [] */0
    }
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function Flash_Edit_Version_Perf_New_Table(Props) {
  var selectedVersionsListFiltered = Props.selectedVersionsListFiltered;
  var selectedVersionsListFilteredPaged = Props.selectedVersionsListFilteredPaged;
  var setSelectedVersionsListFilteredPaged = Props.setSelectedVersionsListFilteredPaged;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return 0;
      });
  var setPage = match[1];
  var page = match[0];
  var convertTable = function (param) {
    return App_Types_Result$DvmAdminFrontendFr.map({
                TAG: /* Ok */0,
                _0: undefined
              }, (function (param) {
                  return Belt_List.map(selectedVersionsListFilteredPaged, (function (version) {
                                return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, undefined, undefined, Belt_List.mapWithIndex({
                                                hd: version.modelVersionID,
                                                tl: {
                                                  hd: Belt_Option.getWithDefault(version.versionName, ""),
                                                  tl: {
                                                    hd: Belt_Option.mapWithDefault(version.maxBonus, "", (function (eta) {
                                                            return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, eta);
                                                          })),
                                                    tl: {
                                                      hd: Belt_Option.mapWithDefault(version.startDate, "", (function (param) {
                                                              return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                            })),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }, (function (index, content) {
                                                  return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                    return param.align;
                                                                  })), undefined, undefined, content);
                                                })));
                              }));
                }));
  };
  React.useEffect((function () {
          Curry._1(setSelectedVersionsListFilteredPaged, (function (param) {
                  return Belt_List.fromArray(Belt_Array.slice(Belt_List.toArray(selectedVersionsListFiltered), Math.imul(page, 50), 50));
                }));
          Curry._1(setPage, (function (param) {
                  return 0;
                }));
        }), [selectedVersionsListFiltered]);
  React.useEffect((function () {
          Curry._1(setSelectedVersionsListFilteredPaged, (function (param) {
                  return Belt_List.fromArray(Belt_Array.slice(Belt_List.toArray(selectedVersionsListFiltered), Math.imul(page, 50), 50));
                }));
        }), [page]);
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table_Pagination$DvmAdminFrontendFr.make, {
                  totalElements: Belt_List.length(selectedVersionsListFiltered),
                  rowsPerPage: 50,
                  currentPage: page,
                  handleChangePage: (function (val) {
                      Curry._1(setPage, (function (param) {
                              return val;
                            }));
                    }),
                  handleRowsPerPageChange: (function (param) {
                      
                    }),
                  variant: /* SMALL */1,
                  disablePaginator: false
                }), React.createElement(App_Table_Simple$DvmAdminFrontendFr.make, {
                  headers: headersConfiguration,
                  content: convertTable(undefined),
                  width: "100%",
                  height: "55vh"
                }));
}

var Types;

var rowsPerPage = 50;

var make = Flash_Edit_Version_Perf_New_Table;

export {
  Types ,
  headersConfiguration ,
  rowsPerPage ,
  make ,
}
/* headersConfiguration Not a pure module */
