// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Theme_Colors$DvmAdminFrontendFr from "../../theme/Theme_Colors.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../App_Typography.bs.js";

function App_LabeledText(Props) {
  var label = Props.label;
  var children = Props.children;
  var colorLabelOpt = Props.colorLabel;
  var colorChildOpt = Props.colorChild;
  var variantLabelOpt = Props.variantLabel;
  var variantChildOpt = Props.variantChild;
  var colorLabel = colorLabelOpt !== undefined ? colorLabelOpt : ({
        TAG: /* String */1,
        _0: Theme_Colors$DvmAdminFrontendFr.mineralDarker
      });
  var colorChild = colorChildOpt !== undefined ? colorChildOpt : "textPrimary";
  var variantLabel = variantLabelOpt !== undefined ? variantLabelOpt : "body";
  var variantChild = variantChildOpt !== undefined ? variantChildOpt : "body";
  var intl = ReactIntl.useIntl();
  var tmp;
  tmp = colorLabel.TAG === /* Typography */0 ? colorLabel._0 : undefined;
  var tmp$1;
  tmp$1 = colorLabel.TAG === /* Typography */0 ? "" : colorLabel._0;
  var tmp$2 = {
    style: {
      color: tmp$1
    },
    children: intl.formatMessage(label),
    variant: variantLabel
  };
  if (tmp !== undefined) {
    tmp$2.color = Caml_option.valFromOption(tmp);
  }
  return React.createElement(Core.Box, {
              children: null
            }, React.createElement(App_Typography$DvmAdminFrontendFr.make, tmp$2), React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  color: colorChild,
                  style: {
                    fontWeight: "600"
                  },
                  children: children,
                  variant: variantChild
                }));
}

var make = App_LabeledText;

export {
  make ,
}
/* react Not a pure module */
