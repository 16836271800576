// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as Flash_Edit_NonFleet_Api$DvmAdminFrontendFr from "../../../edit/steps/NonFleet/Flash_Edit_NonFleet_Api.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_AutocompleteFormInput.bs.js";

function Flash_Search_Modal_Car_Filters(Props) {
  var form = Props.form;
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelVersions = match$2[1];
  var modelVersions = match$2[0];
  React.useEffect((function () {
          Flash_Edit_NonFleet_Api$DvmAdminFrontendFr.fetchModelsByBrand(setModelVersions, userManager, selectedRole, form.input.brand.length > 0 ? ({
                    hd: form.input.brand,
                    tl: /* [] */0
                  }) : ({
                    hd: "RENAULT",
                    tl: {
                      hd: "DACIA",
                      tl: {
                        hd: "ALPINE",
                        tl: /* [] */0
                      }
                    }
                  }), /* VPVU */2);
        }), [form.input.brand]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.forError(modelVersions, (function (error) {
                  Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                            message: {
                              TAG: /* RestError */2,
                              _0: error
                            }
                          }));
                }));
        }), [modelVersions]);
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              item: true,
              spacing: 3
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                        label: Messages_Common$DvmAdminFrontendFr.brand,
                        value: {
                          TAG: /* Single */0,
                          _0: {
                            value: form.input.brand,
                            onChange: Curry._1(form.updateBrand, (function (input, brand) {
                                    return {
                                            brand: brand,
                                            modelID: input.modelID,
                                            phase: input.phase
                                          };
                                  }))
                          }
                        },
                        options: {
                          TAG: /* Labeled */1,
                          _0: {
                            hd: {
                              label: {
                                TAG: /* String */3,
                                _0: "Renault"
                              },
                              value: "RENAULT"
                            },
                            tl: {
                              hd: {
                                label: {
                                  TAG: /* String */3,
                                  _0: "Dacia"
                                },
                                value: "DACIA"
                              },
                              tl: {
                                hd: {
                                  label: {
                                    TAG: /* String */3,
                                    _0: "Alpine"
                                  },
                                  value: "ALPINE"
                                },
                                tl: /* [] */0
                              }
                            }
                          }
                        },
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                      }),
                  item: true,
                  sm: Grid$Mui.Sm[6]
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                        label: Messages_Common$DvmAdminFrontendFr.phase,
                        value: {
                          TAG: /* Single */0,
                          _0: {
                            value: form.input.phase,
                            onChange: Curry._1(form.updatePhase, (function (input, phase) {
                                    return {
                                            brand: input.brand,
                                            modelID: input.modelID,
                                            phase: phase
                                          };
                                  }))
                          }
                        },
                        options: {
                          TAG: /* Unlabeled */0,
                          _0: {
                            hd: "1",
                            tl: {
                              hd: "2",
                              tl: /* [] */0
                            }
                          }
                        },
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                      }),
                  item: true,
                  sm: Grid$Mui.Sm[6]
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                        label: Messages_Common$DvmAdminFrontendFr.model,
                        value: {
                          TAG: /* Multiple */1,
                          _0: {
                            value: form.input.modelID,
                            onChange: Curry._1(form.updateModelID, (function (input, modelID) {
                                    return {
                                            brand: input.brand,
                                            modelID: modelID,
                                            phase: input.phase
                                          };
                                  }))
                          }
                        },
                        options: {
                          TAG: /* Unlabeled */0,
                          _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(modelVersions, /* [] */0)
                        },
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                        smallChips: true
                      }),
                  item: true,
                  sm: Grid$Mui.Sm[12]
                }));
}

var InputStyles;

var make = Flash_Search_Modal_Car_Filters;

export {
  InputStyles ,
  make ,
}
/* react Not a pure module */
