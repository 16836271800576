// Generated by ReScript, PLEASE EDIT WITH CARE


var dealersCount = {
  id: "15b2bb62-c388-4359-b454-bcc07cfa16fd",
  defaultMessage: "{count, plural, =0 {0 affaires} one {# affaire} two {# affaires} few {# affaires} many {# affaires} other {# affaires}}"
};

var ipn = {
  id: "01dfa37e-0295-4659-9913-03a1f9ffaa5b",
  defaultMessage: "IPN"
};

var listHeaderDealers = {
  id: "d53d361b-ab60-47a8-beed-0370d9dba4ef",
  defaultMessage: "Affaires"
};

var modalTitleEdit = {
  id: "a8de12f5-1492-41eb-abe3-0ec7a06fae26",
  defaultMessage: "Modifier {ipn}"
};

var modalTitleAdd = {
  id: "2037b5af-84cb-430b-829f-5518d223b266",
  defaultMessage: "Ajouter un profile"
};

var deleteTitle = {
  id: "ec8f9129-dce2-4ce3-85cf-3f561ef94c45",
  defaultMessage: "Supprimer {ipn}?"
};

export {
  dealersCount ,
  ipn ,
  listHeaderDealers ,
  modalTitleEdit ,
  modalTitleAdd ,
  deleteTitle ,
}
/* No side effect */
