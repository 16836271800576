// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../../common/utils/Utils_Date.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../../../common/types/App_Types_Input.bs.js";
import * as Flash_Edit_Types$DvmAdminFrontendFr from "./Flash_Edit_Types.bs.js";
import * as Flash_Edit_NonFleet_Types$DvmAdminFrontendFr from "./steps/NonFleet/Flash_Edit_NonFleet_Types.bs.js";

function fetchFlash(userManager, setResult, selectedRole, flashType, flashId) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Edit_Types$DvmAdminFrontendFr.Flash.t_decode, undefined, userManager, selectedRole, "/flash/action/" + (
          flashType ? "CL/" : "SO/"
        ) + "" + decodeURIComponent(flashId).replace(/\s/g, "%2B") + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var flash = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: flash
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function saveFlash(operation, userManager, setResult, submissionCallbacks, submissionCallbacksGeneralDates, submissionCallbacksVOForm, selectedRole, flash) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call(operation ? "PUT" : "POST", Flash_Edit_Types$DvmAdminFrontendFr.responseType_decode, {
          TAG: /* Json */0,
          _0: Flash_Edit_Types$DvmAdminFrontendFr.Flash.t_encode(flash)
        }, userManager, selectedRole, operation ? "/flash/edit" : "/flash/create").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var flashId = result._0;
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          Curry._1(submissionCallbacksGeneralDates.notifyOnSuccess, undefined);
          Curry._1(submissionCallbacksVOForm.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: [
                                  operation,
                                  operation ? undefined : Belt_Option.mapWithDefault(flashId.response, undefined, (function (x) {
                                            return x;
                                          }))
                                ]
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToSaveFlash */0);
        Curry._1(submissionCallbacksGeneralDates.notifyOnFailure, /* FailedToSaveFlash */0);
        Curry._1(submissionCallbacksVOForm.notifyOnFailure, /* FailedToSaveFlash */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function saveFlashNonFleet(operation, userManager, setResult, submissionCallbacks, submissionCallbacksAdditional, submissionCallbacksGeneralDates, submissionCallbacksNonFleetNewVehicle, submissionCallbacksVOForm, selectedRole, newVehicle, flash) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call(operation ? "PUT" : "POST", Flash_Edit_Types$DvmAdminFrontendFr.responseType_decode, {
          TAG: /* Json */0,
          _0: Flash_Edit_Types$DvmAdminFrontendFr.Flash.t_encode(flash)
        }, userManager, selectedRole, operation ? "/flash/edit" : "/flash/create").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var flashId = result._0;
          RestApi$DvmAdminFrontendFr.WithEmptyResponse.call(operation ? "PUT" : "POST", {
                  TAG: /* Json */0,
                  _0: Flash_Edit_NonFleet_Types$DvmAdminFrontendFr.NewVehicles.t_encode(newVehicle)
                }, userManager, selectedRole, "/flash/map/modular/" + Belt_Option.getWithDefault(flashId.response, flash.actionId) + "").then(function (result) {
                if (result.TAG === /* OkResult */0) {
                  Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
                  Curry._1(submissionCallbacksAdditional.notifyOnSuccess, undefined);
                  Curry._1(submissionCallbacksGeneralDates.notifyOnSuccess, undefined);
                  Curry._1(submissionCallbacksNonFleetNewVehicle.notifyOnSuccess, undefined);
                  Curry._1(submissionCallbacksVOForm.notifyOnSuccess, undefined);
                  return Curry._1(setResult, (function (param) {
                                return {
                                        TAG: /* Ok */0,
                                        _0: [
                                          operation,
                                          operation ? undefined : Belt_Option.mapWithDefault(flashId.response, undefined, (function (x) {
                                                    return x;
                                                  }))
                                        ]
                                      };
                              }));
                }
                var error = result._0;
                Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToSaveFlash */0);
                Curry._1(submissionCallbacksAdditional.notifyOnFailure, /* FailedToSaveFlash */0);
                Curry._1(submissionCallbacksGeneralDates.notifyOnFailure, /* FailedToSaveFlash */0);
                Curry._1(submissionCallbacksNonFleetNewVehicle.notifyOnFailure, /* FailedToSaveFlashNewVehicle */0);
                Curry._1(submissionCallbacksVOForm.notifyOnFailure, /* FailedToSaveFlash */0);
                Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Error */1,
                                _0: error
                              };
                      }));
              });
          return ;
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToSaveFlash */0);
        Curry._1(submissionCallbacksAdditional.notifyOnFailure, /* FailedToSaveFlash */0);
        Curry._1(submissionCallbacksGeneralDates.notifyOnFailure, /* FailedToSaveFlash */0);
        Curry._1(submissionCallbacksNonFleetNewVehicle.notifyOnFailure, /* FailedToSaveFlashNewVehicle */0);
        Curry._1(submissionCallbacksVOForm.notifyOnFailure, /* FailedToSaveFlash */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function queryForBrands(alert, userManager, setBrandsList, selectedRole) {
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Edit_Types$DvmAdminFrontendFr.Brands.t_decode, undefined, userManager, selectedRole, "/dropdown/brands").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var brands = result._0;
          return Curry._1(setBrandsList, (function (param) {
                        return brands;
                      }));
        }
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: result._0
                  }
                }));
        return Curry._1(setBrandsList, (function (param) {
                      return /* [] */0;
                    }));
      });
}

function fetchFlashModelVersions(alert, userManager, setResult, selectedRole, withClosedModels, flashId) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.parentChildList_decode, undefined, userManager, selectedRole, "/dropdown/modelversion/action/" + decodeURIComponent(flashId).replace(/\s/g, "%2B") + "/" + (
          withClosedModels ? "true" : "false"
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var flash = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: flash
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchVAT(userManager, setResult, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Edit_Types$DvmAdminFrontendFr.responseType_decode, undefined, userManager, selectedRole, "/flash/vat").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var vat = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: vat
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function queryForEasypack(userManager, setEasypackList, selectedRole, startDate, endDate) {
  var start = Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(startDate), "1900-01-01");
  var end = Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(endDate), "3000-12-31");
  encodeURIComponent(Belt_Array.joinWith([
              "startDate=[\\," + start + "]&&endDate=[" + start + "\\,]",
              "startDate=[\\," + end + "]&&endDate=[" + end + "\\,]",
              "startDate=[" + start + "\\,]&&endDate=[\\," + end + "]",
              "startDate=[\\," + start + "]&&endDate=[" + end + "\\,]",
              "startDate=[\\," + start + "]&&endDate=NULL"
            ], "||", (function (x) {
                return x;
              }))).replace(/%20/g, "%2B");
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.twoParamList_decode, undefined, userManager, selectedRole, "/dropdown/easypack").then(function (result) {
        if (result.TAG !== /* OkResult */0) {
          return Curry._1(setEasypackList, (function (param) {
                        return /* [] */0;
                      }));
        }
        var brands = result._0;
        return Curry._1(setEasypackList, (function (param) {
                      return brands;
                    }));
      });
}

var Form;

var FormAdditional;

var FormGeneralDates;

var FormNonFleetNewVehicle;

var FormVO;

var Types;

export {
  Form ,
  FormAdditional ,
  FormGeneralDates ,
  FormNonFleetNewVehicle ,
  FormVO ,
  Types ,
  fetchFlash ,
  saveFlash ,
  saveFlashNonFleet ,
  queryForBrands ,
  fetchFlashModelVersions ,
  fetchVAT ,
  queryForEasypack ,
}
/* react Not a pure module */
