// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../../../../common/App_Api.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Quota_View_Exclusion_Add_Form$DvmAdminFrontendFr from "./Quota_View_Exclusion_Add_Form.bs.js";

function Quota_View_Exclusion_Add_Filters(Props) {
  var allVersionOptions = Props.allVersionOptions;
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var setFilteredOptions = Props.setFilteredOptions;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelVersionOptions = match$2[1];
  var match$3 = React.useState(function () {
        return /* [] */0;
      });
  var setVersionOptions = match$3[1];
  var form = Quota_View_Exclusion_Add_Form$DvmAdminFrontendFr.ExclusionFilterForm.useForm(Quota_View_Exclusion_Add_Form$DvmAdminFrontendFr.initialState, (function (param, submissionCallbacks) {
          Curry._2(fetchData, submissionCallbacks, {
                page: fetchRequest.page,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: fetchRequest.headers,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                              hd: param.model,
                              tl: /* [] */0
                            }, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchModelVersions(setModelVersionOptions, userManager, selectedRole, undefined, undefined, undefined);
        }), []);
  React.useEffect((function () {
          Curry._1(form.submit, undefined);
        }), [form.input.model]);
  React.useEffect((function () {
          Curry._2(form.updateCategory, (function (input, category) {
                  return {
                          model: input.model,
                          category: category,
                          version: input.version,
                          versionName: input.versionName
                        };
                }), "");
          Curry._2(form.updateVersion, (function (input, version) {
                  return {
                          model: input.model,
                          category: input.category,
                          version: version,
                          versionName: input.versionName
                        };
                }), "");
          Curry._2(form.updateVersionName, (function (input, versionName) {
                  return {
                          model: input.model,
                          category: input.category,
                          version: input.version,
                          versionName: versionName
                        };
                }), "");
          Curry._1(setVersionOptions, (function (param) {
                  return Belt_List.map(allVersionOptions, (function (x) {
                                return x.version;
                              }));
                }));
          Curry._1(setFilteredOptions, (function (param) {
                  return allVersionOptions;
                }));
        }), [allVersionOptions]);
  React.useEffect((function () {
          Curry._1(setFilteredOptions, (function (param) {
                  return Belt_List.keep(Belt_List.keep(allVersionOptions, (function (x) {
                                    if (form.input.version === "") {
                                      return true;
                                    } else {
                                      return x.version === form.input.version;
                                    }
                                  })), (function (x) {
                                return Belt_Option.mapWithDefault(x.versionName, false, (function (x) {
                                              if (form.input.versionName === "") {
                                                return true;
                                              } else {
                                                return x.toLowerCase().includes(form.input.versionName.toLowerCase());
                                              }
                                            }));
                              }));
                }));
        }), [
        form.input.category,
        form.input.version,
        form.input.versionName
      ]);
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.model,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.model,
        onChange: Curry._1(form.updateModel, (function (input, model) {
                return {
                        model: model,
                        category: input.category,
                        version: input.version,
                        versionName: input.versionName
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$2[0], /* [] */0), (function (x) {
              return x.parent;
            }))
    },
    required: /* OnlyLabel */1,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.modelResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.modelResult);
  }
  var tmp$1 = {
    onChange: Curry._1(form.updateCategory, (function (input, category) {
            return {
                    model: input.model,
                    category: category,
                    version: input.version,
                    versionName: input.versionName
                  };
          })),
    value: form.input.category,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
    label: Messages_Common$DvmAdminFrontendFr.category
  };
  if (form.categoryResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.categoryResult);
  }
  var tmp$2 = {
    label: Messages_Common$DvmAdminFrontendFr.version,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.version,
        onChange: Curry._1(form.updateVersion, (function (input, version) {
                return {
                        model: input.model,
                        category: input.category,
                        version: version,
                        versionName: input.versionName
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: match$3[0]
    },
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.versionResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.versionResult);
  }
  var tmp$3 = {
    onChange: Curry._1(form.updateVersionName, (function (input, versionName) {
            return {
                    model: input.model,
                    category: input.category,
                    version: input.version,
                    versionName: versionName
                  };
          })),
    value: form.input.versionName,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Quotas_Detail$DvmAdminFrontendFr.versionName
  };
  if (form.versionNameResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.versionNameResult);
  }
  return React.createElement("form", undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  style: {
                    fontWeight: "600"
                  },
                  children: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.selectModel),
                  variant: "h4"
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Core.Grid, {
                        children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp),
                        item: true
                      }),
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }), React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  style: {
                    fontWeight: "600"
                  },
                  children: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.selectCategory, {
                        model: form.input.model.length > 0 ? form.input.model : "modèle"
                      }),
                  variant: "h4"
                }), React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true,
                  direction: "row"
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$1),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$2),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$3),
                      item: true
                    })));
}

var Style;

var Form;

var make = Quota_View_Exclusion_Add_Filters;

export {
  Style ,
  Form ,
  make ,
}
/* react Not a pure module */
