// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Container$Mui from "rescript-material-ui/src/Container.bs.js";
import * as Core from "@material-ui/core";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as Company_Detail_Api$DvmAdminFrontendFr from "./Company_Detail_Api.bs.js";
import * as Company_Detail_AppBar$DvmAdminFrontendFr from "./Company_Detail_AppBar.bs.js";
import * as Messages_Company_Detail$DvmAdminFrontendFr from "../../../intl/messages/company/Messages_Company_Detail.bs.js";
import * as Company_Detail_Main_Info$DvmAdminFrontendFr from "./Company_Detail_Main_Info.bs.js";
import * as Company_Detail_Table_Page$DvmAdminFrontendFr from "./table/Company_Detail_Table_Page.bs.js";

function Company_Detail_Page(Props) {
  var siret = Props.siret;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$2[1];
  var result = match$2[0];
  React.useEffect((function () {
          Company_Detail_Api$DvmAdminFrontendFr.fetchCompanyDetail(siret, userManager, setResult, selectedRole);
        }), []);
  if (typeof result === "number") {
    if (result === /* NotStarted */0) {
      return null;
    } else {
      return React.createElement(Core.Grid, {
                  children: React.createElement(Core.CircularProgress, {
                        size: 100
                      }),
                  container: true,
                  justifyContent: "center"
                });
    }
  }
  if (result.TAG !== /* Ok */0) {
    return React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                color: "error",
                children: intl.formatMessage(Messages_Company_Detail$DvmAdminFrontendFr.notFound),
                variant: "h2"
              });
  }
  var companyDetail = result._0;
  return React.createElement(React.Fragment, undefined, React.createElement(Company_Detail_AppBar$DvmAdminFrontendFr.make, {
                  companyDetail: companyDetail
                }), React.createElement(Core.Container, {
                  children: React.createElement(Core.Grid, {
                        children: null,
                        container: true,
                        direction: "column",
                        spacing: 5
                      }, React.createElement(Core.Grid, {
                            children: React.createElement(Company_Detail_Main_Info$DvmAdminFrontendFr.make, {
                                  companyDetail: companyDetail
                                }),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(Company_Detail_Table_Page$DvmAdminFrontendFr.make, {
                                  siret: siret
                                }),
                            item: true
                          })),
                  maxWidth: Container$Mui.MaxWidth.md
                }));
}

var Api;

var make = Company_Detail_Page;

export {
  Api ,
  make ,
}
/* react Not a pure module */
