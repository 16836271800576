// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as Auth_Router$DvmAdminFrontendFr from "../Auth_Router.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";

function Auth_Logout_Page(Props) {
  var intl = ReactIntl.useIntl();
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  alignItems: "center",
                  children: null,
                  container: true,
                  direction: "column",
                  spacing: 2
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                            children: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.logourMessage),
                            variant: "h3"
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                            onClick: (function (param) {
                                Auth_Router$DvmAdminFrontendFr.routeToMainPage(undefined);
                              }),
                            label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.loginBack),
                            size: "medium"
                          }),
                      item: true
                    })));
}

var make = Auth_Logout_Page;

export {
  make ,
}
/* react Not a pure module */
