// Generated by ReScript, PLEASE EDIT WITH CARE


var title = {
  id: "44100b85-df2f-41bb-937c-ca32ed747b8f",
  defaultMessage: "Erreurs de rattachement"
};

var tooltipIconMessage = {
  id: "a330ef03-dca9-45bd-b4aa-b4848312e7b2",
  defaultMessage: "Il y a {total} erreurs avec les protocoles"
};

var goToErrorPage = {
  id: "b0cc2ed4-71ca-422b-b221-6215230834d4",
  defaultMessage: "Aller à la page d’erreur du protocole"
};

export {
  title ,
  tooltipIconMessage ,
  goToErrorPage ,
}
/* No side effect */
