// Generated by ReScript, PLEASE EDIT WITH CARE


var title = {
  id: "89136a2e-8e07-40c3-b2e1-772576c3d279",
  defaultMessage: "Détail de l’action commerciale"
};

var flashType = {
  id: "288a95a1-b3ee-469f-8374-f1a2fcfdfa5d",
  defaultMessage: "Type action"
};

var flashCode = {
  id: "873f08fe-5f54-46b6-ace0-ce37b8daf34a",
  defaultMessage: "Code Action"
};

var flashLabel = {
  id: "6012fb91-1eae-49cc-af57-be95f1a9a71d",
  defaultMessage: "Libellé action"
};

var orderPeriod = {
  id: "de4f12de-bda8-4c7c-a8f2-e904b41d49d2",
  defaultMessage: "Période commande"
};

var deliveryPeriod = {
  id: "234a54eb-eb2d-486e-9c7a-a6b03ab880e3",
  defaultMessage: "Période livraison"
};

var registrationPeriod = {
  id: "a927a0b3-1bc6-4b1f-b941-82dcc735cd5d",
  defaultMessage: "Période d'immatriculation"
};

var madcPeriod = {
  id: "fd3e2e6a-f559-4e49-baa9-3bff10707c5e",
  defaultMessage: "Période MADC"
};

var madaPeriod = {
  id: "415ed118-6e0d-4e86-bf6c-01b82ff38218",
  defaultMessage: "Période MADA"
};

var modularActionMADAorMADCPeriod = {
  id: "ba68ad6f-3546-4c61-8605-6eaa78b60e9f",
  defaultMessage: "Période mise à disposition"
};

var madaMadcNoneSelected = {
  id: "ffadaf35-549e-4308-b209-36d7e15ca79e",
  defaultMessage: "Aucune"
};

var cumulable = {
  id: "3f922768-ed46-4e5d-b6ac-d64a3392ee35",
  defaultMessage: "Cumulable"
};

var modelVersionButtonTitle = {
  id: "81589042-4a93-4cb3-bef1-05f01f869be4",
  defaultMessage: "Modèles/Versions bénéficiant de l’AC"
};

var tableSearchTitle = {
  id: "3e2e3eda-c189-4672-b920-b72930f272f2",
  defaultMessage: "Rechercher dans le tableau"
};

var active = {
  id: "24290a6b-c9ac-4f6f-ad37-64904af8f0c4",
  defaultMessage: "Actives"
};

var inactive = {
  id: "1e8874c6-cb3e-47df-9057-bdaff1c2fcb4",
  defaultMessage: "Inactives"
};

var conditionsTitle = {
  id: "e47b3b2a-99c4-4d4c-a4a7-d3a9fffc46bf",
  defaultMessage: "Conditions VO"
};

var carAge = {
  id: "c87c15ce-72d7-4ea3-bb65-446043170b05",
  defaultMessage: "Âge VO (en année)"
};

var carOwnershipAge = {
  id: "c62a4812-4b3d-477f-9b97-290a8cf43695",
  defaultMessage: "Durée de possession (en mois)"
};

var atOrder = {
  id: "fa3c8d41-39e9-4548-8d1d-40921ca81f7a",
  defaultMessage: "À la commande"
};

var atDelivery = {
  id: "89b8537b-0eb8-43a5-869d-304e2a6901e0",
  defaultMessage: "À la livraison"
};

var eligibleBrands = {
  id: "6b2afba4-ce7c-47f9-8983-e28c4e22fd3b",
  defaultMessage: "Marques de VR éligibles"
};

var eligibleNonFleetVO = {
  id: "3ec08b84-7990-4bfc-a8e6-796e46794eef",
  defaultMessage: "VO Bénéficiant de l'AC"
};

var excludedBrands = {
  id: "76d37899-25a6-4871-a6ed-87b878147f28",
  defaultMessage: "Marques exclues"
};

var includedBrands = {
  id: "61d6771e-4037-4de3-98d5-f8ef13489048",
  defaultMessage: "Marques concernées"
};

var allBrandsIncluded = {
  id: "41f1c485-3aac-4cbf-ac44-9b8fd0fc404e",
  defaultMessage: "Toutes les marques concernées"
};

var flashNotFound = {
  id: "53e3d96c-2b02-4eb3-8dd3-919500082962",
  defaultMessage: "Action commerciale introuvable"
};

var nonCumulableActions = {
  id: "cf0ddd21-c687-46e9-bb76-d707a5d084e2",
  defaultMessage: "Actions non cumulables"
};

var amountPercentOrEuro = {
  id: "6cb3edda-e3d0-44a0-b8d9-ef1313a2e860",
  defaultMessage: "Montant € HT ou %"
};

var amountEuroHT = {
  id: "90e0c7e1-d800-4e8d-8310-6fb1c4e067ff",
  defaultMessage: "Montant € HT"
};

var amountInPercent = {
  id: "dcfd911c-c235-43b9-a91f-61d912a58e93",
  defaultMessage: "Montant en %"
};

var transformationInPercent = {
  id: "4277c352-6c8a-41a0-92e9-ce0449811636",
  defaultMessage: "% de transformation"
};

var dealerInfo = {
  id: "4b68de36-6771-4dca-b1ca-e40d007c1b38",
  defaultMessage: "Informations Affaires"
};

export {
  title ,
  flashType ,
  flashCode ,
  flashLabel ,
  orderPeriod ,
  deliveryPeriod ,
  registrationPeriod ,
  madcPeriod ,
  madaPeriod ,
  modularActionMADAorMADCPeriod ,
  madaMadcNoneSelected ,
  cumulable ,
  modelVersionButtonTitle ,
  tableSearchTitle ,
  active ,
  inactive ,
  conditionsTitle ,
  carAge ,
  carOwnershipAge ,
  atOrder ,
  atDelivery ,
  eligibleBrands ,
  eligibleNonFleetVO ,
  excludedBrands ,
  includedBrands ,
  allBrandsIncluded ,
  flashNotFound ,
  nonCumulableActions ,
  amountPercentOrEuro ,
  amountEuroHT ,
  amountInPercent ,
  transformationInPercent ,
  dealerInfo ,
}
/* No side effect */
