// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as Contract_Manager_Search_Types$DvmAdminFrontendFr from "../search/Contract_Manager_Search_Types.bs.js";
import * as Messages_Contract_Manager_Edit$DvmAdminFrontendFr from "../../../intl/messages/contractManagers/Messages_Contract_Manager_Edit.bs.js";

function saveManager(operation, userManager, setResult, submissionCallbacks, managerID, intl, selectedRole, manager) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("PUT", {
          TAG: /* Json */0,
          _0: Contract_Manager_Search_Types$DvmAdminFrontendFr.editList_encode(manager)
        }, userManager, selectedRole, "/contract/managers/" + managerID + "/edit").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: [
                                  operation,
                                  operation ? intl.formatMessage(Messages_Contract_Manager_Edit$DvmAdminFrontendFr.successEdit) : intl.formatMessage(Messages_Contract_Manager_Edit$DvmAdminFrontendFr.successCreate)
                                ]
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToSaveContractManager */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

var Types;

export {
  Form ,
  Types ,
  saveManager ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
