// Generated by ReScript, PLEASE EDIT WITH CARE


var titleCorrection = {
  id: "6f9b9c34-5758-4b1c-89f4-6fca18f0bee2",
  defaultMessage: "Correction de la commande"
};

var titleCorrectionHistory = {
  id: "ab696dd7-ff18-4678-9f23-9f3a9e3b02e5",
  defaultMessage: "Historique des corrections de la commande"
};

var initialValues = {
  id: "c98865b7-c228-4b9a-a8de-efb30537c8bb",
  defaultMessage: "Valeur initiale"
};

var correctionValues = {
  id: "b50e5ac4-d5f9-466f-9519-d0e216a28f5b",
  defaultMessage: "Correction"
};

var initialValuesPerf = {
  id: "bd624972-40ee-45fb-b210-cdff5a3c278d",
  defaultMessage: "Action initiale"
};

var correctionValuesPerf = {
  id: "206bbba1-7650-4071-b426-0c2ade3c5032",
  defaultMessage: "Correction Action"
};

var initialCarValues = {
  id: "3233becc-b582-48fe-8b7e-12bf0a683411",
  defaultMessage: "Informations VO initiales"
};

var correctionCarValues = {
  id: "994d038e-c355-4021-988c-94ad66cd87cc",
  defaultMessage: "Correction VO"
};

var appendCarInfo = {
  id: "4fa37db5-c1ed-4881-be34-eb0097c0ff4b",
  defaultMessage: "Ajouter une saisie VO"
};

var validateCorrection = {
  id: "06f473dc-ec50-4bc9-9663-6c4c5d189b9b",
  defaultMessage: "Valider la correction"
};

var correctionsSelector = {
  id: "ae9f2904-735e-4251-9e88-6d04828d282d",
  defaultMessage: "Sélectionner une date de correction"
};

var correctionsDateTitle = {
  id: "aa4efdc6-1292-46f2-87c6-0671a17d3110",
  defaultMessage: "Correction du {date}"
};

var correctionCarDateValues = {
  id: "f5d255c4-c585-4e75-969d-2103fa4315a1",
  defaultMessage: "Correction VO du {date}"
};

var successMessage = {
  id: "f3298db5-8eb8-4996-b3f1-d371977e1cc1",
  defaultMessage: "Vos modifications ont bien été enregistrées"
};

var lastUndeployedMod = {
  id: "90f5785d-70c2-478f-92a1-75e6c3ad4d00",
  defaultMessage: "Valeur corrigée le"
};

var orderNotDeliveredIssue = {
  id: "81c763db-6db9-49a9-a5f3-a470c779d8e4",
  defaultMessage: "La commande n’est pas livrée et ne peut être corrigée"
};

var amountPerf = {
  id: "a08abb5f-0455-4560-8710-58a7233d80cc",
  defaultMessage: "Montant Action 1"
};

var amountPerf2 = {
  id: "6d7d4862-c847-40a5-aaff-d66d91db7667",
  defaultMessage: "Montant Action 2"
};

var amountPerf3 = {
  id: "32da1812-3523-4827-a4ea-00381eb571e2",
  defaultMessage: "Montant Action 3"
};

var idPerf = {
  id: "2493f35c-6d28-4621-8d8e-d361472b33ec",
  defaultMessage: "Code Action 1"
};

var idPerf2 = {
  id: "344a0010-6330-49fd-a413-9e6794b6fcac",
  defaultMessage: "Code Action 2"
};

var idPerf3 = {
  id: "3b28ea72-eaff-4b0d-b9f9-50823f40ba33",
  defaultMessage: "Code Action 3"
};

var typePerf = {
  id: "9141eead-d7b8-48a4-9c4f-3deed3a5219b",
  defaultMessage: "Type Action 1"
};

var typePerf2 = {
  id: "95b91114-09ae-43ba-ac56-31d4ccf546d0",
  defaultMessage: "Type Action 2"
};

var typePerf3 = {
  id: "3a640b07-3919-49b1-aea2-27a3ec3d0560",
  defaultMessage: "Type Action 3"
};

var correctionBlockedTooltip = {
  id: "384b5ec8-2880-41c5-86e7-bacad08a49b6",
  defaultMessage: "Ces champs ne peuvent pas être corrigés si la commande est archivée, si elle n'est pas livrée ou si elle est livrée depuis moins de 7 jours"
};

var correctionWillUpdateTomorrow = {
  id: "ad8b7941-5d94-4324-aeca-39c83b88bfcb",
  defaultMessage: "La correction sera effective demain"
};

var onlySevenPlusBlocked = {
  id: "b85a28e6-8c6d-4c06-9490-796dd3fc9008",
  defaultMessage: "Certains champs ne peuvent pas être corrigés si la commande n'est pas livrée depuis plus de 7 jours"
};

export {
  titleCorrection ,
  titleCorrectionHistory ,
  initialValues ,
  correctionValues ,
  initialValuesPerf ,
  correctionValuesPerf ,
  initialCarValues ,
  correctionCarValues ,
  appendCarInfo ,
  validateCorrection ,
  correctionsSelector ,
  correctionsDateTitle ,
  correctionCarDateValues ,
  successMessage ,
  lastUndeployedMod ,
  orderNotDeliveredIssue ,
  amountPerf ,
  amountPerf2 ,
  amountPerf3 ,
  idPerf ,
  idPerf2 ,
  idPerf3 ,
  typePerf ,
  typePerf2 ,
  typePerf3 ,
  correctionBlockedTooltip ,
  correctionWillUpdateTomorrow ,
  onlySevenPlusBlocked ,
}
/* No side effect */
