// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../../common/components/icons/App_Icon.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Alert.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../../../common/components/App_Checkbox.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as Flash_Edit_Types$DvmAdminFrontendFr from "../../Flash_Edit_Types.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../common/components/App_OverlaySpinner.bs.js";
import * as App_RadioFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_RadioFormInput.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as Flash_Edit_NonFleet_Api$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_Api.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Flash_Edit_NonFleet_New_Vehicle_Versions$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_New_Vehicle_Versions.bs.js";
import * as Flash_Edit_NonFleet_New_Vehicle_Duplication$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_New_Vehicle_Duplication.bs.js";
import * as Flash_Edit_NonFleet_New_Vehicle_Version_Utils$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_New_Vehicle_Version_Utils.bs.js";

function Flash_Edit_NonFleet_New_Vehicle_Page(Props) {
  var fetchFlashModelVersionsForCumulables = Props.fetchFlashModelVersionsForCumulables;
  var form = Props.form;
  var formAdditional = Props.formAdditional;
  var formNewVehicle = Props.formNewVehicle;
  var newVehicleResult = Props.newVehicleResult;
  var setNewVehicleResult = Props.setNewVehicleResult;
  var flashId = Props.flashId;
  var flash = Props.flash;
  var setDuplicateActionNewVehicle = Props.setDuplicateActionNewVehicle;
  var newVehicleExpanded = Props.newVehicleExpanded;
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match$2 = React.useState(function () {
        return false;
      });
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var saveResult = match$3[0];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelsByBrand = match$4[1];
  var modelsByBrand = match$4[0];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setVersionsLabels = match$5[1];
  var versionsLabels = match$5[0];
  var match$6 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelPhases = match$6[1];
  var modelPhases = match$6[0];
  var match$7 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelEngines = match$7[1];
  var modelEngines = match$7[0];
  React.useEffect((function () {
          if (formNewVehicle.input.includeAllModels) {
            Curry._2(formNewVehicle.updateModels, (function (input, models) {
                    return {
                            models: models,
                            modelPhases: input.modelPhases,
                            mappingDirection: input.mappingDirection,
                            engineTypes: input.engineTypes,
                            versions: input.versions,
                            brandsNewCar: input.brandsNewCar,
                            vehicleType: input.vehicleType,
                            includeAllModels: input.includeAllModels
                          };
                  }), /* [] */0);
            Curry._2(formNewVehicle.updateMappingDirection, (function (input, mappingDirection) {
                    return {
                            models: input.models,
                            modelPhases: input.modelPhases,
                            mappingDirection: mappingDirection,
                            engineTypes: input.engineTypes,
                            versions: input.versions,
                            brandsNewCar: input.brandsNewCar,
                            vehicleType: input.vehicleType,
                            includeAllModels: input.includeAllModels
                          };
                  }), "ALL");
          }
          
        }), [formNewVehicle.input.includeAllModels]);
  React.useEffect((function () {
          if (Belt_Option.isNone(flash)) {
            var match = form.input.orderType;
            var exit = 0;
            if (!(match !== 1 && match !== 3)) {
              exit = 1;
            }
            if (exit === 1) {
              Curry._2(formNewVehicle.updateVehicleType, (function (input, vehicleType) {
                      return {
                              models: input.models,
                              modelPhases: input.modelPhases,
                              mappingDirection: input.mappingDirection,
                              engineTypes: input.engineTypes,
                              versions: input.versions,
                              brandsNewCar: input.brandsNewCar,
                              vehicleType: vehicleType,
                              includeAllModels: input.includeAllModels
                            };
                    }), /* VP */0);
            }
            
          }
          
        }), []);
  React.useEffect((function () {
          if (App_Types_Result$DvmAdminFrontendFr.isSuccess(newVehicleResult)) {
            Curry._1(formNewVehicle.reset, undefined);
          }
          
        }), [newVehicleResult]);
  var fetchNewVehicleInfo = function (param) {
    Belt_Option.mapWithDefault(flashId, undefined, (function (flashId) {
            Flash_Edit_NonFleet_Api$DvmAdminFrontendFr.fetchNewVehicleInfo(userManager, setNewVehicleResult, flashId, selectedRole);
          }));
  };
  React.useEffect((function () {
          if (Belt_Option.isSome(flashId)) {
            fetchNewVehicleInfo(undefined);
          }
          
        }), [flashId]);
  React.useEffect((function () {
          if (App_Types_Result$DvmAdminFrontendFr.isSuccess(newVehicleResult) || Belt_Option.isNone(flash)) {
            Flash_Edit_NonFleet_Api$DvmAdminFrontendFr.fetchModelsByBrand(setModelsByBrand, userManager, selectedRole, formNewVehicle.input.brandsNewCar, formNewVehicle.input.vehicleType);
          }
          
        }), [
        formNewVehicle.input.vehicleType,
        formNewVehicle.input.brandsNewCar
      ]);
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            fetchNewVehicleInfo(undefined);
            Curry._1(fetchFlashModelVersionsForCumulables, form.input.actionID);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  React.useEffect((function () {
          if (App_Types_Result$DvmAdminFrontendFr.isSuccess(modelsByBrand)) {
            Flash_Edit_NonFleet_Api$DvmAdminFrontendFr.getPhases(userManager, selectedRole, setModelPhases, formNewVehicle.input.models);
            Flash_Edit_NonFleet_Api$DvmAdminFrontendFr.getEngines(userManager, selectedRole, setModelEngines, formNewVehicle.input.models);
          }
          
        }), [formNewVehicle.input.models]);
  React.useEffect((function () {
          if (App_Types_Result$DvmAdminFrontendFr.isSuccess(modelPhases) && App_Types_Result$DvmAdminFrontendFr.isSuccess(modelsByBrand) && (App_Types_Result$DvmAdminFrontendFr.isSuccess(modelEngines) || Belt_List.length(formNewVehicle.input.engineTypes) === 0)) {
            Flash_Edit_NonFleet_Api$DvmAdminFrontendFr.fetchAllVersions(userManager, setVersionsLabels, formNewVehicle, selectedRole);
          }
          
        }), [
        formNewVehicle.input.models,
        formNewVehicle.input.engineTypes,
        formNewVehicle.input.modelPhases
      ]);
  React.useEffect((function () {
          if (typeof modelsByBrand !== "number" && modelsByBrand.TAG === /* Ok */0) {
            var modelsByBrand$1 = modelsByBrand._0;
            Curry._2(formNewVehicle.updateModels, (function (input, models) {
                    return {
                            models: models,
                            modelPhases: input.modelPhases,
                            mappingDirection: input.mappingDirection,
                            engineTypes: input.engineTypes,
                            versions: input.versions,
                            brandsNewCar: input.brandsNewCar,
                            vehicleType: input.vehicleType,
                            includeAllModels: input.includeAllModels
                          };
                  }), Belt_List.keep(formNewVehicle.input.models, (function (formModel) {
                        return Belt_List.has(modelsByBrand$1, formModel, (function (a, b) {
                                      return a === b;
                                    }));
                      })));
          }
          
        }), [modelsByBrand]);
  React.useEffect((function () {
          if (typeof modelEngines !== "number" && modelEngines.TAG === /* Ok */0) {
            Curry._2(formNewVehicle.updateEngineTypes, (function (input, engineTypes) {
                    return {
                            models: input.models,
                            modelPhases: input.modelPhases,
                            mappingDirection: input.mappingDirection,
                            engineTypes: engineTypes,
                            versions: input.versions,
                            brandsNewCar: input.brandsNewCar,
                            vehicleType: input.vehicleType,
                            includeAllModels: input.includeAllModels
                          };
                  }), Belt_List.keep(formNewVehicle.input.engineTypes, (function (engine) {
                        return Belt_List.has(Flash_Edit_NonFleet_New_Vehicle_Version_Utils$DvmAdminFrontendFr.getDistinctEngines(modelEngines), engine, (function (a, b) {
                                      return a === b;
                                    }));
                      })));
          }
          
        }), [modelEngines]);
  React.useEffect((function () {
          Curry._2(formNewVehicle.updateModelPhases, (function (input, modelPhases) {
                  return {
                          models: input.models,
                          modelPhases: modelPhases,
                          mappingDirection: input.mappingDirection,
                          engineTypes: input.engineTypes,
                          versions: input.versions,
                          brandsNewCar: input.brandsNewCar,
                          vehicleType: input.vehicleType,
                          includeAllModels: input.includeAllModels
                        };
                }), Belt_List.map(Flash_Edit_NonFleet_New_Vehicle_Version_Utils$DvmAdminFrontendFr.getDelimitedPhases(formNewVehicle.input.modelPhases, formNewVehicle.input.models, modelPhases), (function (x) {
                      return x.value;
                    })));
        }), [modelPhases]);
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.brands,
    value: {
      TAG: /* Multiple */1,
      _0: {
        value: formNewVehicle.input.brandsNewCar,
        onChange: Curry._1(formNewVehicle.updateBrandsNewCar, (function (input, brandsNewCar) {
                return {
                        models: input.models,
                        modelPhases: input.modelPhases,
                        mappingDirection: input.mappingDirection,
                        engineTypes: input.engineTypes,
                        versions: input.versions,
                        brandsNewCar: brandsNewCar,
                        vehicleType: input.vehicleType,
                        includeAllModels: input.includeAllModels
                      };
              }))
      }
    },
    options: {
      TAG: /* Labeled */1,
      _0: {
        hd: {
          label: {
            TAG: /* String */3,
            _0: "Alpine"
          },
          value: "ALPINE"
        },
        tl: {
          hd: {
            label: {
              TAG: /* String */3,
              _0: "Dacia"
            },
            value: "DACIA"
          },
          tl: {
            hd: {
              label: {
                TAG: /* String */3,
                _0: "Renault"
              },
              value: "RENAULT"
            },
            tl: /* [] */0
          }
        }
      }
    },
    required: /* OnlyLabel */1,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
  };
  if (formNewVehicle.brandsNewCarResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(formNewVehicle.brandsNewCarResult);
  }
  var tmp$1 = {
    label: Messages_Common$DvmAdminFrontendFr.model,
    value: {
      TAG: /* Multiple */1,
      _0: {
        value: formNewVehicle.input.models,
        onChange: Curry._1(formNewVehicle.updateModels, (function (input, models) {
                return {
                        models: models,
                        modelPhases: input.modelPhases,
                        mappingDirection: input.mappingDirection,
                        engineTypes: input.engineTypes,
                        versions: input.versions,
                        brandsNewCar: input.brandsNewCar,
                        vehicleType: input.vehicleType,
                        includeAllModels: input.includeAllModels
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(modelsByBrand, /* [] */0)
    },
    disabled: formNewVehicle.input.includeAllModels,
    required: /* OnlyLabel */1,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    smallChips: true
  };
  if (formNewVehicle.modelsResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(formNewVehicle.modelsResult);
  }
  var tmp$2 = {
    label: Messages_Common$DvmAdminFrontendFr.phase,
    value: {
      TAG: /* Multiple */1,
      _0: {
        value: formNewVehicle.input.modelPhases,
        onChange: Curry._1(formNewVehicle.updateModelPhases, (function (input, modelPhases) {
                return {
                        models: input.models,
                        modelPhases: modelPhases,
                        mappingDirection: input.mappingDirection,
                        engineTypes: input.engineTypes,
                        versions: input.versions,
                        brandsNewCar: input.brandsNewCar,
                        vehicleType: input.vehicleType,
                        includeAllModels: input.includeAllModels
                      };
              }))
      }
    },
    options: {
      TAG: /* Labeled */1,
      _0: Flash_Edit_NonFleet_New_Vehicle_Version_Utils$DvmAdminFrontendFr.getDelimitedPhases(undefined, formNewVehicle.input.models, modelPhases)
    },
    disabled: formNewVehicle.input.includeAllModels,
    required: /* OnlyLabel */1,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    smallChips: true,
    disabledValues: Flash_Edit_NonFleet_New_Vehicle_Version_Utils$DvmAdminFrontendFr.getPhasesNotAllowedToRemove(formNewVehicle)
  };
  if (formNewVehicle.modelPhasesResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(formNewVehicle.modelPhasesResult);
  }
  var tmp$3 = {
    label: Messages_Flash_Edit$DvmAdminFrontendFr.energyDropdown,
    value: {
      TAG: /* Multiple */1,
      _0: {
        value: formNewVehicle.input.engineTypes,
        onChange: Curry._1(formNewVehicle.updateEngineTypes, (function (input, engineTypes) {
                return {
                        models: input.models,
                        modelPhases: input.modelPhases,
                        mappingDirection: input.mappingDirection,
                        engineTypes: engineTypes,
                        versions: input.versions,
                        brandsNewCar: input.brandsNewCar,
                        vehicleType: input.vehicleType,
                        includeAllModels: input.includeAllModels
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: Flash_Edit_NonFleet_New_Vehicle_Version_Utils$DvmAdminFrontendFr.getDistinctEngines(modelEngines)
    },
    disabled: formNewVehicle.input.includeAllModels,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    smallChips: true
  };
  if (formNewVehicle.engineTypesResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(formNewVehicle.engineTypesResult);
  }
  var match$8 = formNewVehicle.input.mappingDirection;
  var tmp$4 = match$8 === "INCLUDE" ? Messages_Flash_Edit$DvmAdminFrontendFr.newVersionsWillNotBeAdded : Messages_Flash_Edit$DvmAdminFrontendFr.newVersionsWillBeAdded;
  return React.createElement("div", {
              style: {
                width: "100%"
              }
            }, React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                  show: App_Types_Result$DvmAdminFrontendFr.isPending(modelsByBrand) || App_Types_Result$DvmAdminFrontendFr.isPending(saveResult) || App_Types_Result$DvmAdminFrontendFr.isPending(newVehicleResult) || App_Types_Result$DvmAdminFrontendFr.isPending(versionsLabels) || match$2[0],
                  children: React.createElement(Core.Grid, {
                        children: null,
                        container: true,
                        direction: "column",
                        spacing: 4
                      }, React.createElement(Core.Grid, {
                            children: React.createElement(Flash_Edit_NonFleet_New_Vehicle_Duplication$DvmAdminFrontendFr.make, {
                                  formNewVehicle: formNewVehicle,
                                  flashId: flashId,
                                  setDuplicationFetchPending: match$2[1],
                                  setDuplicateActionNewVehicle: setDuplicateActionNewVehicle,
                                  orderType: form.input.orderType,
                                  clientType: formAdditional.input.clientType
                                }),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: null,
                            container: true,
                            item: true
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(Core.Box, {
                                      children: React.createElement(Core.Grid, {
                                            children: null,
                                            container: true,
                                            direction: "column",
                                            item: true
                                          }, React.createElement(Core.Grid, {
                                                children: null,
                                                container: true,
                                                item: true,
                                                spacing: 1
                                              }, React.createElement(Core.Grid, {
                                                    children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                          size: /* Large */3,
                                                          type_: /* Renault */56
                                                        }),
                                                    item: true
                                                  }), React.createElement(Core.Grid, {
                                                    children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                          style: {
                                                            fontWeight: "600"
                                                          },
                                                          children: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.brands),
                                                          variant: "bodyBig"
                                                        }),
                                                    item: true
                                                  })), React.createElement(Core.Grid, {
                                                children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp),
                                                item: true
                                              })),
                                      borderColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
                                      borderLeft: 2,
                                      paddingLeft: 1
                                    }),
                                item: true,
                                xs: Grid$Mui.Xs[6]
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(Core.Box, {
                                      children: React.createElement(Core.Grid, {
                                            children: null,
                                            container: true,
                                            direction: "column",
                                            item: true
                                          }, React.createElement(Core.Grid, {
                                                children: null,
                                                container: true,
                                                item: true,
                                                spacing: 1
                                              }, React.createElement(Core.Grid, {
                                                    children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                          size: /* Large */3,
                                                          type_: /* CarPark */7
                                                        }),
                                                    item: true
                                                  }), React.createElement(Core.Grid, {
                                                    children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                          style: {
                                                            fontWeight: "600"
                                                          },
                                                          children: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.vehicleType),
                                                          variant: "bodyBig"
                                                        }),
                                                    item: true
                                                  })), React.createElement(Core.Grid, {
                                                children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, {
                                                      required: /* OnlyLabel */1,
                                                      value: Flash_Edit_Types$DvmAdminFrontendFr.VehicleType.toString(formNewVehicle.input.vehicleType),
                                                      options: {
                                                        hd: {
                                                          label: {
                                                            TAG: /* String */3,
                                                            _0: "VP/VU"
                                                          },
                                                          value: "VPVU"
                                                        },
                                                        tl: {
                                                          hd: {
                                                            label: {
                                                              TAG: /* String */3,
                                                              _0: "VP"
                                                            },
                                                            value: "VP"
                                                          },
                                                          tl: {
                                                            hd: {
                                                              label: {
                                                                TAG: /* String */3,
                                                                _0: "VU"
                                                              },
                                                              value: "VU"
                                                            },
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      },
                                                      onChange: (function (value) {
                                                          Curry._2(formNewVehicle.updateVehicleType, (function (input, vehicleType) {
                                                                  return {
                                                                          models: input.models,
                                                                          modelPhases: input.modelPhases,
                                                                          mappingDirection: input.mappingDirection,
                                                                          engineTypes: input.engineTypes,
                                                                          versions: input.versions,
                                                                          brandsNewCar: input.brandsNewCar,
                                                                          vehicleType: vehicleType,
                                                                          includeAllModels: input.includeAllModels
                                                                        };
                                                                }), Belt_Option.getWithDefault(Flash_Edit_Types$DvmAdminFrontendFr.VehicleType.fromString(value), /* NOTSET */3));
                                                        }),
                                                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.extraLongInput
                                                    }),
                                                item: true
                                              })),
                                      paddingLeft: 5
                                    }),
                                item: true,
                                xs: Grid$Mui.Xs[6]
                              })), React.createElement(Core.Grid, {
                            children: React.createElement(Core.Box, {
                                  children: React.createElement(Core.Grid, {
                                        children: null,
                                        container: true,
                                        direction: "column",
                                        sm: Grid$Mui.Sm[12]
                                      }, React.createElement(Core.Grid, {
                                            children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                  style: {
                                                    fontWeight: "600"
                                                  },
                                                  children: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.includeModelsPhasesEnergyNewVehicle),
                                                  variant: "bodyBig"
                                                }),
                                            item: true
                                          }), React.createElement(Core.Grid, {
                                            children: null,
                                            container: true,
                                            direction: "row",
                                            item: true,
                                            spacing: 1
                                          }, React.createElement(Core.Grid, {
                                                children: React.createElement(Core.FormControlLabel, {
                                                      classes: {
                                                        label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                                      },
                                                      control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                                            checked: formNewVehicle.input.includeAllModels,
                                                            onClick: (function ($$event) {
                                                                Curry._2(formNewVehicle.updateIncludeAllModels, (function (input, includeAllModels) {
                                                                        return {
                                                                                models: input.models,
                                                                                modelPhases: input.modelPhases,
                                                                                mappingDirection: input.mappingDirection,
                                                                                engineTypes: input.engineTypes,
                                                                                versions: input.versions,
                                                                                brandsNewCar: input.brandsNewCar,
                                                                                vehicleType: input.vehicleType,
                                                                                includeAllModels: includeAllModels
                                                                              };
                                                                      }), $$event.target.checked);
                                                              })
                                                          }),
                                                      label: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.selectAllModels)
                                                    }),
                                                item: true,
                                                sm: Grid$Mui.Sm[12]
                                              }), React.createElement(Core.Grid, {
                                                children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$1),
                                                item: true,
                                                sm: Grid$Mui.Sm[12]
                                              }), React.createElement(Core.Grid, {
                                                children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$2),
                                                item: true,
                                                sm: Grid$Mui.Sm[12]
                                              }), React.createElement(Core.Grid, {
                                                children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$3),
                                                item: true,
                                                sm: Grid$Mui.Sm[12]
                                              }))),
                                  borderColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
                                  borderLeft: 2,
                                  paddingLeft: 1
                                }),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(Core.Box, {
                                  children: React.createElement(Core.Grid, {
                                        children: null,
                                        container: true,
                                        direction: "column",
                                        item: true,
                                        spacing: 1
                                      }, React.createElement(Core.Grid, {
                                            children: null,
                                            container: true,
                                            direction: "column",
                                            item: true
                                          }, React.createElement(Core.Grid, {
                                                children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                      style: {
                                                        fontWeight: "600"
                                                      },
                                                      children: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.inclusionMethod),
                                                      variant: "bodyBig"
                                                    }),
                                                item: true
                                              }), React.createElement(Core.Grid, {
                                                children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, {
                                                      value: formNewVehicle.input.mappingDirection,
                                                      options: {
                                                        hd: {
                                                          label: {
                                                            TAG: /* Message */0,
                                                            _0: Messages_Flash_Edit$DvmAdminFrontendFr.includeAll
                                                          },
                                                          value: "ALL"
                                                        },
                                                        tl: {
                                                          hd: {
                                                            label: {
                                                              TAG: /* String */3,
                                                              _0: "Inclusion"
                                                            },
                                                            value: "INCLUDE"
                                                          },
                                                          tl: {
                                                            hd: {
                                                              label: {
                                                                TAG: /* String */3,
                                                                _0: "Exclusion"
                                                              },
                                                              value: "EXCLUDE"
                                                            },
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      },
                                                      onChange: Curry._1(formNewVehicle.updateMappingDirection, (function (input, mappingDirection) {
                                                              return {
                                                                      models: input.models,
                                                                      modelPhases: input.modelPhases,
                                                                      mappingDirection: mappingDirection,
                                                                      engineTypes: input.engineTypes,
                                                                      versions: input.versions,
                                                                      brandsNewCar: input.brandsNewCar,
                                                                      vehicleType: input.vehicleType,
                                                                      includeAllModels: input.includeAllModels
                                                                    };
                                                            })),
                                                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.extraLongInput,
                                                      disabled: formNewVehicle.input.includeAllModels
                                                    }),
                                                item: true
                                              })), React.createElement(Core.Grid, {
                                            children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                  style: {
                                                    fontWeight: "600"
                                                  },
                                                  children: intl.formatMessage(tmp$4)
                                                }),
                                            item: true
                                          })),
                                  borderColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
                                  borderLeft: 2,
                                  paddingLeft: 1
                                }),
                            item: true
                          }), formNewVehicle.input.mappingDirection !== "ALL" ? React.createElement(Core.Grid, {
                              children: React.createElement(Flash_Edit_NonFleet_New_Vehicle_Versions$DvmAdminFrontendFr.make, {
                                    form: formNewVehicle,
                                    versionsLabels: versionsLabels,
                                    newVehicleExpanded: newVehicleExpanded
                                  }),
                              item: true,
                              sm: Grid$Mui.Sm[12]
                            }) : null)
                }));
}

var Api;

var InputStyles;

var Types;

var NonFleetUtils;

var Form;

var FormAdditional;

var FormNewVehicle;

var make = Flash_Edit_NonFleet_New_Vehicle_Page;

export {
  Api ,
  InputStyles ,
  Types ,
  NonFleetUtils ,
  Form ,
  FormAdditional ,
  FormNewVehicle ,
  make ,
}
/* react Not a pure module */
