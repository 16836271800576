// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Css from "@emotion/css";
import * as Container$Mui from "rescript-material-ui/src/Container.bs.js";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as App_Link$DvmAdminFrontendFr from "../../../common/components/link/App_Link.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../../route/Route_Paths.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../../context/router/Context_Router.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Quota_Detail_Info$DvmAdminFrontendFr from "./info/Quota_Detail_Info.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as Quota_Admin_Router$DvmAdminFrontendFr from "../search/admin/Quota_Admin_Router.bs.js";
import * as Quota_Detail_Info_Api$DvmAdminFrontendFr from "./info/Quota_Detail_Info_Api.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as Quota_Detail_Info_Form$DvmAdminFrontendFr from "./info/Quota_Detail_Info_Form.bs.js";
import * as Quota_Detail_Attachment$DvmAdminFrontendFr from "./attachment/Quota_Detail_Attachment.bs.js";
import * as Quota_View_Exclusion_Form$DvmAdminFrontendFr from "../search/exclusion/Quota_View_Exclusion_Form.bs.js";
import * as Quota_Detail_Attachment_Api$DvmAdminFrontendFr from "./attachment/Quota_Detail_Attachment_Api.bs.js";

var centerStyle = Css.css({
      justifyContent: "center"
    });

function Quota_Detail_Page(Props) {
  var quotaInfo = Props.quotaInfo;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setGetResult = match[1];
  var getResult = match[0];
  var match$1 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match$1[1];
  var saveResult = match$1[0];
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setVolumeOfAttachments = match$2[1];
  var volumeOfAttachments = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setQuota = match$3[1];
  var quota = match$3[0];
  var match$4 = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match$4[1];
  var match$5 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$6 = match$5[0];
  var userManager = match$6.userManager;
  var selectedRole = match$6.selectedRole;
  React.useEffect((function () {
          Belt_Option.mapWithDefault(quotaInfo, undefined, (function (quotaInfo) {
                  var stringQuery = "dr=" + quotaInfo.dr + "&contractID=" + quotaInfo.contractID + "&vehicleType=" + quotaInfo.vehicleType + "&startDate=" + quotaInfo.startDate + "";
                  Quota_Detail_Info_Api$DvmAdminFrontendFr.fetchQuotaDetailInfo(alert, userManager, setGetResult, stringQuery, selectedRole);
                }));
        }), []);
  var fetchVolumeTotal = function (q) {
    Quota_Detail_Attachment_Api$DvmAdminFrontendFr.fetchVolumeOfAttachments(userManager, alert, setVolumeOfAttachments, q, selectedRole);
  };
  React.useEffect((function () {
          if (typeof saveResult !== "number" && saveResult.TAG === /* Ok */0) {
            var q = saveResult._0;
            Curry._1(setQuota, (function (param) {
                    return q;
                  }));
            fetchVolumeTotal(q);
            Quota_Admin_Router$DvmAdminFrontendFr.routeToDetailEditUpdate({
                  dr: q.dr,
                  contractId: q.contractId,
                  vehicleType: q.vehicleType,
                  volume: undefined,
                  startDate: new Date(q.startDate.setUTCHours(0.0, 0.0, 0.0, 0.0)),
                  endDate: undefined
                }, undefined);
          }
          
        }), [saveResult]);
  React.useEffect((function () {
          if (typeof getResult !== "number" && getResult.TAG === /* Ok */0) {
            var q = getResult._0;
            Curry._1(setQuota, (function (param) {
                    return q;
                  }));
            fetchVolumeTotal(q);
          }
          
        }), [getResult]);
  return React.createElement(Core.Container, {
              children: null,
              maxWidth: Container$Mui.MaxWidth.md
            }, React.createElement(Core.Box, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        children: intl.formatMessage(Belt_Option.isNone(quota) ? Messages_Quotas_Detail$DvmAdminFrontendFr.createTitle : Messages_Quotas_Detail$DvmAdminFrontendFr.modifyTitle),
                        variant: "h3"
                      }),
                  pb: 3
                }), React.createElement(Core.Box, {
                  children: null
                }, React.createElement(Core.Accordion, {
                      children: null,
                      defaultExpanded: true,
                      square: true,
                      style: {
                        backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight1,
                        padding: "20px",
                        boxShadow: "none"
                      }
                    }, React.createElement(Core.AccordionSummary, {
                          children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                children: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.infoTitle),
                                variant: "h3"
                              }),
                          expandIcon: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                size: /* Regular */2,
                                type_: /* PadDown */11
                              }),
                          id: "detail"
                        }), React.createElement(Core.AccordionDetails, {
                          children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult) || App_Types_Result$DvmAdminFrontendFr.isPending(getResult),
                                children: quota !== undefined ? React.createElement(Quota_Detail_Info$DvmAdminFrontendFr.make, {
                                        setSaveResult: setSaveResult,
                                        getResult: getResult,
                                        initialInput: {
                                          dr: quota.dr,
                                          drName: quota.drName,
                                          contractId: quota.contractId,
                                          contractLabel: quota.contractLabel,
                                          vehicleType: quota.vehicleType,
                                          volume: String(Belt_Option.getWithDefault(quota.volume, 0)),
                                          startDate: Caml_option.some(quota.startDate),
                                          endDate: quota.endDate
                                        },
                                        volumeOfAttachments: volumeOfAttachments,
                                        key: "1"
                                      }) : React.createElement(Quota_Detail_Info$DvmAdminFrontendFr.make, {
                                        setSaveResult: setSaveResult,
                                        getResult: getResult,
                                        initialInput: Quota_Detail_Info_Form$DvmAdminFrontendFr.initialState,
                                        volumeOfAttachments: volumeOfAttachments,
                                        key: "2"
                                      })
                              }),
                          className: centerStyle
                        })), Belt_Option.isSome(quota) ? React.createElement(Core.Box, {
                        children: null
                      }, Belt_Option.mapWithDefault(quota, null, (function (q) {
                              return React.createElement(Core.Box, {
                                          style: {
                                            backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight1,
                                            padding: "20px",
                                            boxShadow: "none"
                                          },
                                          children: React.createElement(App_Link$DvmAdminFrontendFr.make, {
                                                onClick: (function (param) {
                                                    Curry._1(dispatch, {
                                                          TAG: /* StoreQuotaExclusionsFilter */25,
                                                          _0: {
                                                            model: Quota_View_Exclusion_Form$DvmAdminFrontendFr.initialState.model,
                                                            version: Quota_View_Exclusion_Form$DvmAdminFrontendFr.initialState.version,
                                                            contractId: q.contractId,
                                                            vehicleType: q.vehicleType === "VPVU" ? "" : q.vehicleType,
                                                            startDate: Caml_option.some(q.startDate),
                                                            endDate: Quota_View_Exclusion_Form$DvmAdminFrontendFr.initialState.endDate
                                                          }
                                                        });
                                                    RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rQuotaExclusions);
                                                  }),
                                                label: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.seeExclusions),
                                                icon: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                      size: /* Large */3,
                                                      type_: /* Search */49
                                                    }),
                                                style: {
                                                  fontSize: "2rem"
                                                }
                                              }),
                                          mt: 4
                                        });
                            })), React.createElement(Core.Box, {
                            style: {
                              backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight1,
                              padding: "20px",
                              boxShadow: "none"
                            },
                            children: null,
                            mt: 4
                          }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                children: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.attachmentTitle),
                                variant: "h3"
                              }), React.createElement(Core.Box, {
                                children: quota !== undefined ? React.createElement(Quota_Detail_Attachment$DvmAdminFrontendFr.make, {
                                        quota: quota,
                                        volumeOfAttachments: volumeOfAttachments,
                                        fetchVolumeTotal: fetchVolumeTotal
                                      }) : null,
                                pt: 3
                              }))) : null));
}

var Api;

var Form;

var make = Quota_Detail_Page;

export {
  Api ,
  Form ,
  centerStyle ,
  make ,
}
/* centerStyle Not a pure module */
