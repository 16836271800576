// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../../../../common/components/button/App_Button.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Flash_Edit_Version_Perf_Edit_Api$DvmAdminFrontendFr from "./Flash_Edit_Version_Perf_Edit_Api.bs.js";

function Flash_Edit_Version_Perf_Edit_Delete(Props) {
  var selectedVersionsListFiltered = Props.selectedVersionsListFiltered;
  var setResultEdit = Props.setResultEdit;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return false;
      });
  var setDeleteModal = match[1];
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                            gutterBottom: true,
                            style: {
                              fontWeight: "600"
                            },
                            children: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.deleteFilteredPerfVersionsTitle),
                            variant: "bodyBig"
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                            onClick: (function (param) {
                                Curry._1(setDeleteModal, (function (param) {
                                        return true;
                                      }));
                              }),
                            label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.$$delete),
                            color: "delete",
                            size: "medium"
                          }),
                      item: true
                    })), match[0] ? React.createElement(App_Modal$DvmAdminFrontendFr.make, {
                    open: true,
                    onClose: (function (param) {
                        Curry._1(setDeleteModal, (function (param) {
                                return false;
                              }));
                      }),
                    title: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.deletePerfVersionsModalMessage, {
                          count: Belt_List.length(selectedVersionsListFiltered)
                        }),
                    minWidth: "500px",
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          justifyContent: "space-between"
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                    onClick: (function (param) {
                                        Curry._1(setDeleteModal, (function (param) {
                                                return false;
                                              }));
                                      }),
                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.cancel),
                                    color: "primaryBorder",
                                    size: "medium"
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                    onClick: (function (param) {
                                        Flash_Edit_Version_Perf_Edit_Api$DvmAdminFrontendFr.perfDeleteVersions(setResultEdit, userManager, selectedRole, Belt_List.map(selectedVersionsListFiltered, (function (version) {
                                                    return {
                                                            actionID: version.actionID,
                                                            modelVersionID: version.modelVersionID,
                                                            oldStartDate: undefined,
                                                            startDate: version.startDate,
                                                            endDate: version.endDate,
                                                            maxBonus: version.maxBonus
                                                          };
                                                  })));
                                        Curry._1(setDeleteModal, (function (param) {
                                                return false;
                                              }));
                                      }),
                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.$$delete),
                                    color: "delete",
                                    size: "medium"
                                  }),
                              item: true
                            }))
                  }) : null);
}

var Form;

var Api;

var Types;

var make = Flash_Edit_Version_Perf_Edit_Delete;

export {
  Form ,
  Api ,
  Types ,
  make ,
}
/* react Not a pure module */
