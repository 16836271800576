// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as Messages_Quotas$DvmAdminFrontendFr from "../../../../../../intl/messages/quotas/Messages_Quotas.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_SelectFormInput.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../../../common/components/input/App_DateInputFormField.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Quota_View_Exclusion_Add_Form$DvmAdminFrontendFr from "./Quota_View_Exclusion_Add_Form.bs.js";

function Quota_View_Exclusion_Add_Primary_Inputs(Props) {
  var contractOptions = Props.contractOptions;
  var setPrimaryOutputs = Props.setPrimaryOutputs;
  var setPrimaryOutputsError = Props.setPrimaryOutputsError;
  var intl = ReactIntl.useIntl();
  var form = Quota_View_Exclusion_Add_Form$DvmAdminFrontendFr.ExclusionPrimaryForm.useForm(Quota_View_Exclusion_Add_Form$DvmAdminFrontendFr.initialStatePrimary, (function (output, submissionCallbacks) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          Curry._1(setPrimaryOutputs, (function (param) {
                  return output;
                }));
        }));
  React.useEffect((function () {
          if (Curry._1(form.valid, undefined)) {
            Curry._1(setPrimaryOutputsError, (function (param) {
                    return false;
                  }));
            Curry._1(form.submit, undefined);
          } else {
            Curry._1(setPrimaryOutputsError, (function (param) {
                    return true;
                  }));
          }
        }), [
        form.input.contractId,
        form.input.vehicleType,
        form.input.startDate,
        form.input.endDate
      ]);
  var tmp = {
    label: Messages_Quotas$DvmAdminFrontendFr.contractId,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.contractId,
        onChange: Curry._1(form.updateContractId, (function (input, contractId) {
                return {
                        contractId: contractId,
                        vehicleType: input.vehicleType,
                        startDate: input.startDate,
                        endDate: input.endDate
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(contractOptions, (function (item) {
                          return item.paramOne;
                        })))))
    },
    required: /* OnlyLabel */1,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.contractIdResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.contractIdResult);
  }
  var tmp$1 = {
    label: Messages_Quotas_Detail$DvmAdminFrontendFr.vehicleType,
    options: {
      hd: {
        label: {
          TAG: /* Message */0,
          _0: Messages_Common$DvmAdminFrontendFr.nothing
        },
        value: ""
      },
      tl: {
        hd: {
          label: {
            TAG: /* String */3,
            _0: "VP"
          },
          value: "VP"
        },
        tl: {
          hd: {
            label: {
              TAG: /* String */3,
              _0: "VU"
            },
            value: "VU"
          },
          tl: {
            hd: {
              label: {
                TAG: /* String */3,
                _0: "VP/VU"
              },
              value: "VPVU"
            },
            tl: /* [] */0
          }
        }
      }
    },
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.vehicleType,
        onChange: Curry._1(form.updateVehicleType, (function (input, vehicleType) {
                return {
                        contractId: input.contractId,
                        vehicleType: vehicleType,
                        startDate: input.startDate,
                        endDate: input.endDate
                      };
              }))
      }
    },
    id: "vehicleType",
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
    required: /* OnlyLabel */1
  };
  if (form.vehicleTypeResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.vehicleTypeResult);
  }
  var tmp$2 = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    contractId: input.contractId,
                    vehicleType: input.vehicleType,
                    startDate: startDate,
                    endDate: input.endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
    required: /* OnlyLabel */1
  };
  if (form.startDateResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  var tmp$3 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    contractId: input.contractId,
                    vehicleType: input.vehicleType,
                    startDate: input.startDate,
                    endDate: endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
  };
  if (form.endDateResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  style: {
                    fontWeight: "600"
                  },
                  children: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.selectExclusionParameters),
                  variant: "h4"
                }), React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, tmp$1),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$2),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$3),
                      item: true
                    })));
}

var Style;

var Form;

var make = Quota_View_Exclusion_Add_Primary_Inputs;

export {
  Style ,
  Form ,
  make ,
}
/* react Not a pure module */
