// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Table$DvmAdminFrontendFr from "../../../../common/components/table/App_Table.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../common/utils/Utils_Api.bs.js";
import * as Grid_Types$DvmAdminFrontendFr from "../../Grid_Types.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as Messages_MarginGrid$DvmAdminFrontendFr from "../../../../intl/messages/marginGrid/Messages_MarginGrid.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Pagination.bs.js";
import * as App_Table_Export_Menu$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Export_Menu.bs.js";

function getHeadersConfiguration(tableType) {
  var baseList_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.discount, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.discount);
  var baseList_1 = {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.margin, undefined, undefined, undefined, undefined, undefined, Messages_MarginGrid$DvmAdminFrontendFr.margin),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.startDate, /* Desc */1, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.startDate),
      tl: {
        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.endDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.endDate),
        tl: /* [] */0
      }
    }
  };
  var baseList = {
    hd: baseList_0,
    tl: baseList_1
  };
  var tmp;
  if (tableType !== undefined) {
    switch (tableType) {
      case /* Model */0 :
          tmp = {
            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.model, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.model),
            tl: /* [] */0
          };
          break;
      case /* Version */1 :
          tmp = {
            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.model, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.model),
            tl: {
              hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.version, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.version),
              tl: {
                hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.categoryName, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.categoryLabel),
                tl: /* [] */0
              }
            }
          };
          break;
      case /* GridID */2 :
          tmp = {
            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.avsMarginGridID, undefined, undefined, undefined, undefined, undefined, Messages_MarginGrid$DvmAdminFrontendFr.gridID),
            tl: {
              hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.categoryID, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.categoryCode),
              tl: /* [] */0
            }
          };
          break;
      
    }
  } else {
    tmp = /* [] */0;
  }
  return Belt_List.concat(tmp, baseList);
}

function Margin_Grid_Table(Props) {
  var tableType = Props.tableType;
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var withClosedModels = Props.withClosedModels;
  var intl = ReactIntl.useIntl();
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (row) {
                                  var tmp;
                                  switch (tableType) {
                                    case /* Model */0 :
                                        tmp = {
                                          hd: Belt_Option.getWithDefault(row.model, ""),
                                          tl: /* [] */0
                                        };
                                        break;
                                    case /* Version */1 :
                                        tmp = {
                                          hd: Belt_Option.getWithDefault(row.model, ""),
                                          tl: {
                                            hd: Belt_Option.getWithDefault(row.version, ""),
                                            tl: {
                                              hd: Belt_Option.getWithDefault(row.categoryName, ""),
                                              tl: /* [] */0
                                            }
                                          }
                                        };
                                        break;
                                    case /* GridID */2 :
                                        tmp = {
                                          hd: Belt_Option.getWithDefault(row.avsMarginGridID, ""),
                                          tl: {
                                            hd: Belt_Option.getWithDefault(row.categoryID, ""),
                                            tl: /* [] */0
                                          }
                                        };
                                        break;
                                    
                                  }
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, undefined, undefined, Belt_List.mapWithIndex(Belt_List.concat(tmp, {
                                                      hd: Intl_Utils$DvmAdminFrontendFr.formatPercent(intl, row.discount),
                                                      tl: {
                                                        hd: Belt_Option.mapWithDefault(row.margin, "", (function (param) {
                                                                return Intl_Utils$DvmAdminFrontendFr.formatPercent(intl, param);
                                                              })),
                                                        tl: {
                                                          hd: Intl_Utils$DvmAdminFrontendFr.formatDate(intl, row.startDate),
                                                          tl: {
                                                            hd: Belt_Option.mapWithDefault(row.endDate, "", (function (param) {
                                                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                  })),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }), (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(getHeadersConfiguration(tableType), index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._2(fetchData, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._2(fetchData, undefined, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var tmp;
  switch (tableType) {
    case /* Model */0 :
        tmp = "margins/model";
        break;
    case /* Version */1 :
        tmp = "margins/modelversion";
        break;
    case /* GridID */2 :
        tmp = "margins/avs";
        break;
    
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, {
                  headers: fetchRequest.headers,
                  handleSorting: handleSorting,
                  tablePage: convertTable(tablePage),
                  paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined),
                  exportMenu: React.createElement(App_Table_Export_Menu$DvmAdminFrontendFr.make, {
                        query: Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                              page: 0,
                              rowsPerPage: 150000,
                              headers: fetchRequest.headers,
                              filterQueryParams: fetchRequest.filterQueryParams,
                              additionalQueryParams: fetchRequest.additionalQueryParams
                            }) + ("&withClosedModels=" + (
                            withClosedModels ? "true" : "false"
                          ) + ""),
                        endpoint: tmp
                      })
                }));
}

var make = Margin_Grid_Table;

export {
  getHeadersConfiguration ,
  make ,
}
/* react Not a pure module */
