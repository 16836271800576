// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as App_Table$DvmAdminFrontendFr from "../../../../common/components/table/App_Table.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Types.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_CheckBoxFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_CheckBoxFormInput.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../common/components/input/App_DateInputFormField.bs.js";

function makeRow(form, index, budgets, showDelete) {
  var row = Belt_Array.get(form.input.budgetRows, index);
  return Belt_Option.mapWithDefault(row, {
              cells: /* [] */0,
              onClick: undefined,
              rowButton: undefined,
              disabled: undefined
            }, (function (row) {
                var tmp = {
                  values: row.budgets,
                  options: Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(budgets, /* [] */0), (function (x) {
                          return {
                                  label: {
                                    TAG: /* String */3,
                                    _0: x
                                  },
                                  value: x
                                };
                        })),
                  onChange: Curry._2(form.updateBudgetRowBudgets, index, (function (param, budgets) {
                          return {
                                  managerID: param.managerID,
                                  managerName: param.managerName,
                                  budgetRows: Belt_Array.mapWithIndex(param.budgetRows, (function (indexRow, row) {
                                          if (indexRow === index) {
                                            return {
                                                    budgets: budgets,
                                                    startDate: row.startDate,
                                                    endDate: row.endDate
                                                  };
                                          } else {
                                            return row;
                                          }
                                        }))
                                };
                        })),
                  className: App_Styles_Common$DvmAdminFrontendFr.TableInputs.longInput
                };
                var tmp$1 = Curry._1(form.budgetRowBudgetsResult, index);
                if (tmp$1 !== undefined) {
                  tmp.validationResult = Caml_option.valFromOption(tmp$1);
                }
                var tmp$2 = {
                  value: row.startDate,
                  onChange: Curry._2(form.updateBudgetRowStartDate, index, (function (param, startDate) {
                          return {
                                  managerID: param.managerID,
                                  managerName: param.managerName,
                                  budgetRows: Belt_Array.mapWithIndex(param.budgetRows, (function (indexRow, row) {
                                          if (indexRow === index) {
                                            return {
                                                    budgets: row.budgets,
                                                    startDate: startDate,
                                                    endDate: row.endDate
                                                  };
                                          } else {
                                            return row;
                                          }
                                        }))
                                };
                        })),
                  className: App_Styles_Common$DvmAdminFrontendFr.TableInputs.standardInput
                };
                var tmp$3 = Curry._1(form.budgetRowStartDateResult, index);
                if (tmp$3 !== undefined) {
                  tmp$2.validationResult = Caml_option.valFromOption(tmp$3);
                }
                var tmp$4 = {
                  value: row.endDate,
                  onChange: Curry._2(form.updateBudgetRowEndDate, index, (function (param, endDate) {
                          return {
                                  managerID: param.managerID,
                                  managerName: param.managerName,
                                  budgetRows: Belt_Array.mapWithIndex(param.budgetRows, (function (indexRow, row) {
                                          if (indexRow === index) {
                                            return {
                                                    budgets: row.budgets,
                                                    startDate: row.startDate,
                                                    endDate: endDate
                                                  };
                                          } else {
                                            return row;
                                          }
                                        }))
                                };
                        })),
                  className: App_Styles_Common$DvmAdminFrontendFr.TableInputs.standardInput
                };
                var tmp$5 = Curry._1(form.budgetRowEndDateResult, index);
                if (tmp$5 !== undefined) {
                  tmp$4.validationResult = Caml_option.valFromOption(tmp$5);
                }
                return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, Caml_option.some(showDelete ? React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Grid, {
                                            children: React.createElement(Core.Button, {
                                                  onClick: (function (param) {
                                                      Curry._1(form.removeBudgetRow, index);
                                                    }),
                                                  children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                        size: /* Large */3,
                                                        type_: /* Trash */38,
                                                        color: "red"
                                                      }),
                                                  key: "delete"
                                                }),
                                            item: true
                                          }),
                                      container: true
                                    }) : null), undefined, Belt_List.mapWithIndex({
                                hd: React.createElement(App_CheckBoxFormInput$DvmAdminFrontendFr.make, tmp),
                                tl: {
                                  hd: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$2),
                                  tl: {
                                    hd: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$4),
                                    tl: /* [] */0
                                  }
                                }
                              }, (function (param, content) {
                                  return App_Table$DvmAdminFrontendFr.makeCell(undefined, undefined, undefined, content);
                                })));
              }));
}

var Form;

var Styles;

export {
  Form ,
  Styles ,
  makeRow ,
}
/* react Not a pure module */
