// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../../common/App_Api.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../../common/components/App_Checkbox.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_MarginGrid$DvmAdminFrontendFr from "../../../../intl/messages/marginGrid/Messages_MarginGrid.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_FloatFieldFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_FloatFieldFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Grid_Edit_New_Row_Inputs_Model$DvmAdminFrontendFr from "./Grid_Edit_New_Row_Inputs_Model.bs.js";
import * as Grid_Edit_New_Row_Inputs_GridID$DvmAdminFrontendFr from "./Grid_Edit_New_Row_Inputs_GridID.bs.js";
import * as Grid_Edit_New_Row_Inputs_Version$DvmAdminFrontendFr from "./Grid_Edit_New_Row_Inputs_Version.bs.js";

function Grid_Edit_New_Row_Inputs(Props) {
  var whatTable = Props.whatTable;
  var whatGrid = Props.whatGrid;
  var form = Props.form;
  var budgetOrID = Props.budgetOrID;
  var budget = Props.budget;
  var acquisitionOptions = Props.acquisitionOptions;
  var operation = Props.operation;
  var original = Props.original;
  var filterStartDate = Props.filterStartDate;
  var filterEndDate = Props.filterEndDate;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return false;
      });
  var setWithClosedModels = match$2[1];
  var withClosedModels = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelVersions = match$3[1];
  var modelVersions = match$3[0];
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var setModelVersionsList = match$4[1];
  var modelVersionsList = match$4[0];
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchModelVersions(setModelVersions, userManager, selectedRole, withClosedModels, undefined, undefined);
        }), []);
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchModelVersions(setModelVersions, userManager, selectedRole, withClosedModels, undefined, undefined);
        }), [withClosedModels]);
  React.useEffect((function () {
          if (typeof modelVersions === "number") {
            modelVersions === /* NotStarted */0;
          } else if (modelVersions.TAG === /* Ok */0) {
            var modelVersions$1 = modelVersions._0;
            Curry._1(setModelVersionsList, (function (param) {
                    return modelVersions$1;
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: modelVersions._0
                      }
                    }));
          }
        }), [modelVersions]);
  React.useEffect((function () {
          if (operation !== 0 || original === undefined) {
            
          } else {
            Curry._2(form.updateModel, (function (input, model) {
                    return {
                            model: model,
                            version: input.version,
                            categoryCode: input.categoryCode,
                            discount: input.discount,
                            margin: input.margin,
                            startDate: input.startDate,
                            endDate: input.endDate,
                            acquisitionType: input.acquisitionType,
                            hasExceptions: input.hasExceptions,
                            categoryStartDate: input.categoryStartDate
                          };
                  }), Belt_Option.getWithDefault(original.model, ""));
            Curry._2(form.updateVersion, (function (input, version) {
                    return {
                            model: input.model,
                            version: version,
                            categoryCode: input.categoryCode,
                            discount: input.discount,
                            margin: input.margin,
                            startDate: input.startDate,
                            endDate: input.endDate,
                            acquisitionType: input.acquisitionType,
                            hasExceptions: input.hasExceptions,
                            categoryStartDate: input.categoryStartDate
                          };
                  }), Belt_Option.getWithDefault(original.version, ""));
            Curry._2(form.updateCategoryCode, (function (input, categoryCode) {
                    return {
                            model: input.model,
                            version: input.version,
                            categoryCode: categoryCode,
                            discount: input.discount,
                            margin: input.margin,
                            startDate: input.startDate,
                            endDate: input.endDate,
                            acquisitionType: input.acquisitionType,
                            hasExceptions: input.hasExceptions,
                            categoryStartDate: input.categoryStartDate
                          };
                  }), Belt_Option.getWithDefault(original.categoryID, ""));
            Curry._2(form.updateDiscount, (function (input, discount) {
                    return {
                            model: input.model,
                            version: input.version,
                            categoryCode: input.categoryCode,
                            discount: discount,
                            margin: input.margin,
                            startDate: input.startDate,
                            endDate: input.endDate,
                            acquisitionType: input.acquisitionType,
                            hasExceptions: input.hasExceptions,
                            categoryStartDate: input.categoryStartDate
                          };
                  }), original.discount);
            Curry._2(form.updateMargin, (function (input, margin) {
                    return {
                            model: input.model,
                            version: input.version,
                            categoryCode: input.categoryCode,
                            discount: input.discount,
                            margin: margin,
                            startDate: input.startDate,
                            endDate: input.endDate,
                            acquisitionType: input.acquisitionType,
                            hasExceptions: input.hasExceptions,
                            categoryStartDate: input.categoryStartDate
                          };
                  }), original.margin);
            Curry._2(form.updateStartDate, (function (input, startDate) {
                    return {
                            model: input.model,
                            version: input.version,
                            categoryCode: input.categoryCode,
                            discount: input.discount,
                            margin: input.margin,
                            startDate: startDate,
                            endDate: input.endDate,
                            acquisitionType: input.acquisitionType,
                            hasExceptions: input.hasExceptions,
                            categoryStartDate: input.categoryStartDate
                          };
                  }), Caml_option.some(original.startDate));
            Curry._2(form.updateEndDate, (function (input, endDate) {
                    return {
                            model: input.model,
                            version: input.version,
                            categoryCode: input.categoryCode,
                            discount: input.discount,
                            margin: input.margin,
                            startDate: input.startDate,
                            endDate: endDate,
                            acquisitionType: input.acquisitionType,
                            hasExceptions: input.hasExceptions,
                            categoryStartDate: input.categoryStartDate
                          };
                  }), original.endDate);
            Curry._2(form.updateAcquisitionType, (function (input, acquisitionType) {
                    return {
                            model: input.model,
                            version: input.version,
                            categoryCode: input.categoryCode,
                            discount: input.discount,
                            margin: input.margin,
                            startDate: input.startDate,
                            endDate: input.endDate,
                            acquisitionType: acquisitionType,
                            hasExceptions: input.hasExceptions,
                            categoryStartDate: input.categoryStartDate
                          };
                  }), Belt_Option.getWithDefault(original.acquisitionType, ""));
          }
        }), []);
  var wrapWithClosedModelsCheckboxElement = function (children) {
    return React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                show: App_Types_Result$DvmAdminFrontendFr.isPending(modelVersions),
                children: React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      direction: "column",
                      item: true,
                      style: {
                        width: "fit-content"
                      }
                    }, React.createElement(Core.Grid, {
                          children: children,
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(Core.FormControlLabel, {
                                classes: {
                                  label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                },
                                control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                      checked: withClosedModels,
                                      onClick: (function ($$event) {
                                          Curry._1(setWithClosedModels, (function (param) {
                                                  return $$event.target.checked;
                                                }));
                                        })
                                    }),
                                label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.includeClosedCars)
                              }),
                          item: true,
                          style: {
                            whiteSpace: "nowrap",
                            width: "1px"
                          }
                        }))
              });
  };
  var tmp;
  switch (whatTable) {
    case /* Model */0 :
        tmp = React.createElement(Grid_Edit_New_Row_Inputs_Model$DvmAdminFrontendFr.make, {
              whatGrid: whatGrid,
              form: form,
              budgetOrID: budgetOrID,
              modelVersionsList: modelVersionsList,
              wrapWithClosedModelsCheckboxElement: wrapWithClosedModelsCheckboxElement
            });
        break;
    case /* Version */1 :
        tmp = React.createElement(Grid_Edit_New_Row_Inputs_Version$DvmAdminFrontendFr.make, {
              whatGrid: whatGrid,
              form: form,
              budgetOrID: budgetOrID,
              modelVersionsList: modelVersionsList,
              operation: operation,
              wrapWithClosedModelsCheckboxElement: wrapWithClosedModelsCheckboxElement
            });
        break;
    case /* GridID */2 :
        tmp = React.createElement(Grid_Edit_New_Row_Inputs_GridID$DvmAdminFrontendFr.make, {
              form: form,
              budgetOrID: budgetOrID,
              modelVersionsList: modelVersionsList,
              filterStartDate: filterStartDate,
              filterEndDate: filterEndDate
            });
        break;
    
  }
  var tmp$1 = {
    onChange: Curry._1(form.updateDiscount, (function (input, discount) {
            return {
                    model: input.model,
                    version: input.version,
                    categoryCode: input.categoryCode,
                    discount: discount,
                    margin: input.margin,
                    startDate: input.startDate,
                    endDate: input.endDate,
                    acquisitionType: input.acquisitionType,
                    hasExceptions: input.hasExceptions,
                    categoryStartDate: input.categoryStartDate
                  };
          })),
    value: form.input.discount,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Common$DvmAdminFrontendFr.discountWithPercent
  };
  if (form.discountResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.discountResult);
  }
  var tmp$2;
  if (whatGrid === /* MarginGrid */1) {
    var tmp$3 = {
      onChange: Curry._1(form.updateMargin, (function (input, margin) {
              return {
                      model: input.model,
                      version: input.version,
                      categoryCode: input.categoryCode,
                      discount: input.discount,
                      margin: margin,
                      startDate: input.startDate,
                      endDate: input.endDate,
                      acquisitionType: input.acquisitionType,
                      hasExceptions: input.hasExceptions,
                      categoryStartDate: input.categoryStartDate
                    };
            })),
      value: form.input.margin,
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
      label: Messages_MarginGrid$DvmAdminFrontendFr.margin
    };
    if (form.marginResult !== undefined) {
      tmp$3.validationResult = Caml_option.valFromOption(form.marginResult);
    }
    tmp$2 = React.createElement(Core.Grid, {
          children: React.createElement(App_FloatFieldFormInput$DvmAdminFrontendFr.make, tmp$3),
          item: true
        });
  } else {
    tmp$2 = null;
  }
  var tmp$4;
  if (whatGrid === /* ModelVersionGrid */0 && (budget === "LCD" || budget === "PROT")) {
    var tmp$5 = {
      label: Messages_MarginGrid$DvmAdminFrontendFr.acquisitionId,
      value: {
        TAG: /* Single */0,
        _0: {
          value: form.input.acquisitionType,
          onChange: Curry._1(form.updateAcquisitionType, (function (input, acquisitionType) {
                  return {
                          model: input.model,
                          version: input.version,
                          categoryCode: input.categoryCode,
                          discount: input.discount,
                          margin: input.margin,
                          startDate: input.startDate,
                          endDate: input.endDate,
                          acquisitionType: acquisitionType,
                          hasExceptions: input.hasExceptions,
                          categoryStartDate: input.categoryStartDate
                        };
                }))
        }
      },
      options: {
        TAG: /* Unlabeled */0,
        _0: acquisitionOptions
      },
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
    };
    if (form.acquisitionTypeResult !== undefined) {
      tmp$5.validationResult = Caml_option.valFromOption(form.acquisitionTypeResult);
    }
    tmp$4 = React.createElement(Core.Grid, {
          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$5),
          item: true
        });
  } else {
    tmp$4 = null;
  }
  var tmp$6 = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    model: input.model,
                    version: input.version,
                    categoryCode: input.categoryCode,
                    discount: input.discount,
                    margin: input.margin,
                    startDate: startDate,
                    endDate: input.endDate,
                    acquisitionType: input.acquisitionType,
                    hasExceptions: input.hasExceptions,
                    categoryStartDate: input.categoryStartDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.startDateResult !== undefined) {
    tmp$6.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  var tmp$7 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    model: input.model,
                    version: input.version,
                    categoryCode: input.categoryCode,
                    discount: input.discount,
                    margin: input.margin,
                    startDate: input.startDate,
                    endDate: endDate,
                    acquisitionType: input.acquisitionType,
                    hasExceptions: input.hasExceptions,
                    categoryStartDate: input.categoryStartDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.endDateResult !== undefined) {
    tmp$7.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  return React.createElement(Core.Grid, {
              children: React.createElement(Core.Grid, {
                    alignItems: "flex-start",
                    children: null,
                    container: true,
                    direction: "column",
                    item: true,
                    spacing: 1
                  }, tmp, React.createElement(Core.Grid, {
                        children: React.createElement(App_FloatFieldFormInput$DvmAdminFrontendFr.make, tmp$1),
                        item: true
                      }), tmp$2, tmp$4, React.createElement(Core.Grid, {
                        children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$6),
                        item: true
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$7),
                        item: true
                      })),
              container: true,
              direction: "column",
              item: true,
              spacing: 3
            });
}

var Types;

var Form;

var InputStyles;

var make = Grid_Edit_New_Row_Inputs;

export {
  Types ,
  Form ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
