// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Home_Videos$DvmAdminFrontendFr from "./videos/Home_Videos.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../context/user/User_Context.bs.js";
import * as Home_Messages$DvmAdminFrontendFr from "./Home_Messages.bs.js";
import * as Messages_Home$DvmAdminFrontendFr from "../../intl/messages/home/Messages_Home.bs.js";
import * as Home_QuickAccess$DvmAdminFrontendFr from "./Home_QuickAccess.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../context/user/User_Types_Utils.bs.js";

function Home(Props) {
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  return React.createElement("div", undefined, React.createElement("h2", undefined, intl.formatMessage(Messages_Home$DvmAdminFrontendFr.title)), React.createElement(Home_QuickAccess$DvmAdminFrontendFr.make, {}), React.createElement(Home_Messages$DvmAdminFrontendFr.make, {}), User_Types_Utils$DvmAdminFrontendFr.whatCountry(match[0].selectedRole) === /* France */0 ? React.createElement(Home_Videos$DvmAdminFrontendFr.make, {}) : null);
}

var make = Home;

export {
  make ,
}
/* react Not a pure module */
