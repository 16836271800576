// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";

function Order_Detail_Latest_ParcStart(Props) {
  var order = Props.order;
  var lastCorrectionValues = Props.lastCorrectionValues;
  var intl = ReactIntl.useIntl();
  var lastStartDateParc = Belt_Option.mapWithDefault(lastCorrectionValues, order.detail.parcStartCalcDate, (function (x) {
          if (Belt_Option.isSome(x.parcStartDate)) {
            return x.parcStartDate;
          } else {
            return order.detail.parcStartCalcDate;
          }
        }));
  return Belt_Option.getWithDefault(Belt_Option.mapWithDefault(lastStartDateParc, undefined, (function (x) {
                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, x);
                  })), "-");
}

var make = Order_Detail_Latest_ParcStart;

export {
  make ,
}
/* react-intl Not a pure module */
