// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as Theme_Size$DvmAdminFrontendFr from "../theme/Theme_Size.bs.js";
import * as Theme_Typography$DvmAdminFrontendFr from "../theme/Theme_Typography.bs.js";

var hero = Css.css({
      fontSize: Theme_Size$DvmAdminFrontendFr.toRem(Theme_Typography$DvmAdminFrontendFr.FontSize.hero)
    });

var h1_light = Css.css({
      fontWeight: "300"
    });

function App_Typography(Props) {
  var align = Props.align;
  var color = Props.color;
  var className = Props.className;
  var addidionalClasses = Props.classes;
  var component = Props.component;
  var display = Props.display;
  var gutterBottom = Props.gutterBottom;
  var id = Props.id;
  var noWrap = Props.noWrap;
  var paragraph = Props.paragraph;
  var style = Props.style;
  var childrenOpt = Props.children;
  var variant = Props.variant;
  var testIdOpt = Props.testId;
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
  var testId = testIdOpt !== undefined ? testIdOpt : "app-typography";
  var variantToClassName = function (param) {
    return Belt_Option.flatMap(variant, (function (variant) {
                  if (variant === "hero") {
                    return hero;
                  } else if (variant === "h1_light") {
                    return h1_light;
                  } else {
                    return ;
                  }
                }));
  };
  var combineClasses = function (param) {
    var match = variantToClassName(undefined);
    if (className !== undefined) {
      if (match !== undefined) {
        return "" + className + " " + match + "";
      } else {
        return className;
      }
    } else if (match !== undefined) {
      return match;
    } else {
      return ;
    }
  };
  var tmp = {
    children: children,
    variant: Belt_Option.mapWithDefault(variant, "body2", (function (variant) {
            if (variant === "h1_light" || variant === "h1" || variant === "hero") {
              return "h1";
            } else if (variant === "subtitleBig") {
              return "subtitle1";
            } else if (variant === "button") {
              return "button";
            } else if (variant === "subtitle") {
              return "subtitle2";
            } else if (variant === "h2") {
              return "h2";
            } else if (variant === "h3") {
              return "h3";
            } else if (variant === "h4") {
              return "h4";
            } else if (variant === "h5") {
              return "h5";
            } else if (variant === "h6") {
              return "h6";
            } else if (variant === "caption") {
              return "caption";
            } else if (variant === "bodyBig") {
              return "body1";
            } else {
              return "body2";
            }
          }))
  };
  if (align !== undefined) {
    tmp.align = Caml_option.valFromOption(align);
  }
  if (addidionalClasses !== undefined) {
    tmp.classes = Caml_option.valFromOption(addidionalClasses);
  }
  var tmp$1 = combineClasses(undefined);
  if (tmp$1 !== undefined) {
    tmp.className = tmp$1;
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (component !== undefined) {
    tmp.component = Caml_option.valFromOption(component);
  }
  if (display !== undefined) {
    tmp.display = Caml_option.valFromOption(display);
  }
  if (gutterBottom !== undefined) {
    tmp.gutterBottom = gutterBottom;
  }
  if (noWrap !== undefined) {
    tmp.noWrap = noWrap;
  }
  if (paragraph !== undefined) {
    tmp.paragraph = paragraph;
  }
  if (id !== undefined) {
    tmp.id = id;
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  return React.cloneElement(React.createElement(Core.Typography, tmp), {
              "test-id": testId
            });
}

var make = App_Typography;

export {
  hero ,
  h1_light ,
  make ,
}
/* hero Not a pure module */
