// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Api.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Date.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Form.bs.js";
import * as Order_Search_Types$DvmAdminFrontendFr from "../../Order_Search_Types.bs.js";

var todayStr = Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(Caml_option.some(new Date())), "");

function boolFieldToEmpty(fieldId) {
  return {
          en: fieldId[0] + "=",
          fr: fieldId[1] + "="
        };
}

function datePairToQuery(from, to, fieldId) {
  return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(from), Caml_option.some(to), fieldId, "normal");
}

var validators_parcAutomatic = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Belt_List.every(param$1.orderType, (function (x) {
                      if (x === "VE") {
                        return true;
                      } else {
                        return false;
                      }
                    })) ? Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Order_Search_Types$DvmAdminFrontendFr.Fields.parcAutomatic, param.parcAutomatic, false) : undefined
            };
    })
};

var validators_exitDateParcEnd = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderType = param$1.orderType;
      var exitDateParcStart = param.exitDateParcStart;
      if (!(Belt_List.every(orderType, (function (x) {
                  switch (x) {
                    case "RC" :
                    case "RR" :
                    case "VE" :
                        return true;
                    default:
                      return false;
                  }
                })) || Belt_List.length(orderType) === 0)) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      switch (param.parcActive) {
        case "no" :
            break;
        case "yes" :
            return {
                    TAG: /* Ok */0,
                    _0: undefined
                  };
        default:
          
      }
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.exitDateParcEnd, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(exitDateParcStart), Caml_option.some(date), Order_Search_Types$DvmAdminFrontendFr.Fields.exitDateParc, "normal");
                  }));
    })
};

var validators_exitDateParcStart = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderType = param$1.orderType;
      var exitDateParcEnd = param.exitDateParcEnd;
      var exitDateParcStart = param.exitDateParcStart;
      if (!(Belt_List.every(orderType, (function (x) {
                  switch (x) {
                    case "RC" :
                    case "RR" :
                    case "VE" :
                        return true;
                    default:
                      return false;
                  }
                })) || Belt_List.length(orderType) === 0)) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      var match = Order_Search_Types$DvmAdminFrontendFr.Fields.exitDateParc;
      var fr = match[1];
      var en = match[0];
      switch (param.parcActive) {
        case "no" :
            return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(exitDateParcStart, undefined), (function (date) {
                          var resultDate = Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(date), Caml_option.some(exitDateParcEnd), Order_Search_Types$DvmAdminFrontendFr.Fields.exitDateParc, "normal");
                          return Belt_Option.getWithDefault(resultDate, {
                                      en: "" + en + "<>NULL",
                                      fr: "" + fr + "<>NULL"
                                    });
                        }));
        case "yes" :
            return {
                    TAG: /* Ok */0,
                    _0: {
                      en: "" + en + "=%5B" + todayStr + "%5C,%5D%7C%7C" + en + "=NULL",
                      fr: "" + fr + "=%5B" + todayStr + "%5C,%5D%7C%7C" + fr + "=NULL"
                    }
                  };
        default:
          return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(exitDateParcStart, undefined), (function (date) {
                        return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(date), Caml_option.some(exitDateParcEnd), Order_Search_Types$DvmAdminFrontendFr.Fields.exitDateParc, "normal");
                      }));
      }
    })
};

var validators_entryDateParcEnd = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderType = param$1.orderType;
      var entryDateParcStart = param.entryDateParcStart;
      if (Belt_List.every(orderType, (function (x) {
                switch (x) {
                  case "RC" :
                  case "RR" :
                  case "VE" :
                      return true;
                  default:
                    return false;
                }
              })) || Belt_List.length(orderType) === 0) {
        return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.entryDateParcEnd, undefined), (function (date) {
                      return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(entryDateParcStart), Caml_option.some(date), Order_Search_Types$DvmAdminFrontendFr.Fields.entryDateParc, "normal");
                    }));
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    })
};

var validators_entryDateParcStart = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderType = param$1.orderType;
      var entryDateParcEnd = param.entryDateParcEnd;
      var entryDateParcStart = param.entryDateParcStart;
      if (!(Belt_List.every(orderType, (function (x) {
                  switch (x) {
                    case "RC" :
                    case "RR" :
                    case "VE" :
                        return true;
                    default:
                      return false;
                  }
                })) || Belt_List.length(orderType) === 0)) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      var dateValidation = function (param) {
        return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(entryDateParcStart, undefined), (function (date) {
                      return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(date), Caml_option.some(entryDateParcEnd), Order_Search_Types$DvmAdminFrontendFr.Fields.entryDateParc, "normal");
                    }));
      };
      if (param.parcActive !== "yes") {
        return dateValidation(undefined);
      }
      if (entryDateParcStart !== undefined) {
        return dateValidation(undefined);
      }
      if (entryDateParcEnd !== undefined) {
        return dateValidation(undefined);
      }
      var match = Order_Search_Types$DvmAdminFrontendFr.Fields.entryDateParc;
      return {
              TAG: /* Ok */0,
              _0: {
                en: "" + match[0] + "=%5B1990-01-01%5C,%5D",
                fr: "" + match[1] + "=%5B1990-01-01%5C,%5D"
              }
            };
    })
};

var validators_agentCode = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Order_Search_Types$DvmAdminFrontendFr.Fields.agentCode, param.agentCode, false)
            };
    })
};

var validators_salesmanName = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Order_Search_Types$DvmAdminFrontendFr.Fields.salesmanName, param.salesmanName, false)
            };
    })
};

var validators_customerName = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Order_Search_Types$DvmAdminFrontendFr.Fields.customerName, param.customerName, false)
            };
    })
};

var validators_delivered = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Order_Search_Types$DvmAdminFrontendFr.Fields.delivered, param.delivered, false)
            };
    })
};

var validators_renaultInvoiceDateEnd = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var renaultInvoiceDateStart = param.renaultInvoiceDateStart;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.renaultInvoiceDateEnd, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(renaultInvoiceDateStart), Caml_option.some(date), Order_Search_Types$DvmAdminFrontendFr.Fields.renaultInvoiceDate, "normal");
                  }));
    })
};

var validators_renaultInvoiceDateStart = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var renaultInvoiceDateEnd = param.renaultInvoiceDateEnd;
      var renaultInvoiceDateStart = param.renaultInvoiceDateStart;
      switch (param.renaultInvoiceExists) {
        case "no" :
            return {
                    TAG: /* Ok */0,
                    _0: boolFieldToEmpty(Order_Search_Types$DvmAdminFrontendFr.Fields.renaultInvoiceDate)
                  };
        case "yes" :
            if (renaultInvoiceDateStart === undefined && renaultInvoiceDateEnd === undefined) {
              return {
                      TAG: /* Ok */0,
                      _0: datePairToQuery(undefined, Caml_option.some(new Date(new Date().setFullYear(3000.0))), Order_Search_Types$DvmAdminFrontendFr.Fields.renaultInvoiceDate)
                    };
            }
            break;
        default:
          
      }
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(renaultInvoiceDateStart, undefined), (function (date) {
                    return datePairToQuery(date, renaultInvoiceDateEnd, Order_Search_Types$DvmAdminFrontendFr.Fields.renaultInvoiceDate);
                  }));
    })
};

var validators_deliveryDateEnd = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var deliveryDateStart = param.deliveryDateStart;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.deliveryDateEnd, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(deliveryDateStart), Caml_option.some(date), Order_Search_Types$DvmAdminFrontendFr.Fields.deliveryDateBoth, "normal");
                  }));
    })
};

var validators_deliveryDateStart = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var deliveryDateEnd = param.deliveryDateEnd;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.deliveryDateStart, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(date), Caml_option.some(deliveryDateEnd), Order_Search_Types$DvmAdminFrontendFr.Fields.deliveryDateBoth, "normal");
                  }));
    })
};

var validators_customerInvoiceEnd = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var customerInvoiceStart = param.customerInvoiceStart;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.customerInvoiceEnd, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(customerInvoiceStart), Caml_option.some(date), Order_Search_Types$DvmAdminFrontendFr.Fields.customerInvoice, "normal");
                  }));
    })
};

var validators_customerInvoiceStart = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var customerInvoiceEnd = param.customerInvoiceEnd;
      var customerInvoiceStart = param.customerInvoiceStart;
      switch (param.customerInvoiceExists) {
        case "no" :
            return {
                    TAG: /* Ok */0,
                    _0: boolFieldToEmpty(Order_Search_Types$DvmAdminFrontendFr.Fields.customerInvoice)
                  };
        case "yes" :
            if (customerInvoiceStart === undefined && customerInvoiceEnd === undefined) {
              return {
                      TAG: /* Ok */0,
                      _0: datePairToQuery(undefined, Caml_option.some(new Date(new Date().setFullYear(3000.0))), Order_Search_Types$DvmAdminFrontendFr.Fields.customerInvoice)
                    };
            }
            break;
        default:
          
      }
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(customerInvoiceStart, undefined), (function (date) {
                    return datePairToQuery(date, customerInvoiceEnd, Order_Search_Types$DvmAdminFrontendFr.Fields.customerInvoice);
                  }));
    })
};

var validators_registrationDateEnd = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var registrationDateStart = param.registrationDateStart;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.registrationDateEnd, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(registrationDateStart), Caml_option.some(date), Order_Search_Types$DvmAdminFrontendFr.Fields.registrationDate, "normal");
                  }));
    })
};

var validators_registrationDateStart = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var registrationDateEnd = param.registrationDateEnd;
      var registrationDateStart = param.registrationDateStart;
      switch (param.registrationDateExists) {
        case "no" :
            return {
                    TAG: /* Ok */0,
                    _0: boolFieldToEmpty(Order_Search_Types$DvmAdminFrontendFr.Fields.registrationDate)
                  };
        case "yes" :
            if (registrationDateStart === undefined && registrationDateEnd === undefined) {
              return {
                      TAG: /* Ok */0,
                      _0: datePairToQuery(undefined, Caml_option.some(new Date(new Date().setFullYear(3000.0))), Order_Search_Types$DvmAdminFrontendFr.Fields.registrationDate)
                    };
            }
            break;
        default:
          
      }
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(registrationDateStart, undefined), (function (date) {
                    return datePairToQuery(date, registrationDateEnd, Order_Search_Types$DvmAdminFrontendFr.Fields.registrationDate);
                  }));
    })
};

var validators_orderDateEnd = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderDateStart = param.orderDateStart;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.orderDateEnd, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(orderDateStart), Caml_option.some(date), Order_Search_Types$DvmAdminFrontendFr.Fields.orderDate, "normal");
                  }));
    })
};

var validators_orderDateStart = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderDateEnd = param.orderDateEnd;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.orderDateStart, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(date), Caml_option.some(orderDateEnd), Order_Search_Types$DvmAdminFrontendFr.Fields.orderDate, "normal");
                  }));
    })
};

var validators = {
  parcAutomatic: validators_parcAutomatic,
  exitDateParcEnd: validators_exitDateParcEnd,
  exitDateParcStart: validators_exitDateParcStart,
  entryDateParcEnd: validators_entryDateParcEnd,
  entryDateParcStart: validators_entryDateParcStart,
  parcActive: undefined,
  agentCode: validators_agentCode,
  salesmanName: validators_salesmanName,
  customerName: validators_customerName,
  delivered: validators_delivered,
  renaultInvoiceExists: undefined,
  renaultInvoiceDateEnd: validators_renaultInvoiceDateEnd,
  renaultInvoiceDateStart: validators_renaultInvoiceDateStart,
  deliveryDateEnd: validators_deliveryDateEnd,
  deliveryDateStart: validators_deliveryDateStart,
  customerInvoiceExists: undefined,
  customerInvoiceEnd: validators_customerInvoiceEnd,
  customerInvoiceStart: validators_customerInvoiceStart,
  registrationDateExists: undefined,
  registrationDateEnd: validators_registrationDateEnd,
  registrationDateStart: validators_registrationDateStart,
  orderDateEnd: validators_orderDateEnd,
  orderDateStart: validators_orderDateStart
};

function initialFieldsStatuses(_input) {
  return {
          parcAutomatic: /* Pristine */0,
          exitDateParcEnd: /* Pristine */0,
          exitDateParcStart: /* Pristine */0,
          entryDateParcEnd: /* Pristine */0,
          entryDateParcStart: /* Pristine */0,
          parcActive: /* Pristine */0,
          agentCode: /* Pristine */0,
          salesmanName: /* Pristine */0,
          customerName: /* Pristine */0,
          delivered: /* Pristine */0,
          renaultInvoiceExists: /* Pristine */0,
          renaultInvoiceDateEnd: /* Pristine */0,
          renaultInvoiceDateStart: /* Pristine */0,
          deliveryDateEnd: /* Pristine */0,
          deliveryDateStart: /* Pristine */0,
          customerInvoiceExists: /* Pristine */0,
          customerInvoiceEnd: /* Pristine */0,
          customerInvoiceStart: /* Pristine */0,
          registrationDateExists: /* Pristine */0,
          registrationDateEnd: /* Pristine */0,
          registrationDateStart: /* Pristine */0,
          orderDateEnd: /* Pristine */0,
          orderDateStart: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match = fieldsStatuses.parcAutomatic;
  var match_0 = match ? match._0 : Curry._2(validators.parcAutomatic.validate, input, metadata);
  var match$1 = fieldsStatuses.exitDateParcEnd;
  var match_0$1 = match$1 ? match$1._0 : Curry._2(validators.exitDateParcEnd.validate, input, metadata);
  var match$2 = fieldsStatuses.exitDateParcStart;
  var match_0$2 = match$2 ? match$2._0 : Curry._2(validators.exitDateParcStart.validate, input, metadata);
  var match$3 = fieldsStatuses.entryDateParcEnd;
  var match_0$3 = match$3 ? match$3._0 : Curry._2(validators.entryDateParcEnd.validate, input, metadata);
  var match$4 = fieldsStatuses.entryDateParcStart;
  var match_0$4 = match$4 ? match$4._0 : Curry._2(validators.entryDateParcStart.validate, input, metadata);
  var match_0$5 = {
    TAG: /* Ok */0,
    _0: input.parcActive
  };
  var match$5 = fieldsStatuses.agentCode;
  var match_0$6 = match$5 ? match$5._0 : Curry._2(validators.agentCode.validate, input, metadata);
  var match$6 = fieldsStatuses.salesmanName;
  var match_0$7 = match$6 ? match$6._0 : Curry._2(validators.salesmanName.validate, input, metadata);
  var match$7 = fieldsStatuses.customerName;
  var match_0$8 = match$7 ? match$7._0 : Curry._2(validators.customerName.validate, input, metadata);
  var match$8 = fieldsStatuses.delivered;
  var match_0$9 = match$8 ? match$8._0 : Curry._2(validators.delivered.validate, input, metadata);
  var match_0$10 = {
    TAG: /* Ok */0,
    _0: input.renaultInvoiceExists
  };
  var match$9 = fieldsStatuses.renaultInvoiceDateEnd;
  var match_0$11 = match$9 ? match$9._0 : Curry._2(validators.renaultInvoiceDateEnd.validate, input, metadata);
  var match$10 = fieldsStatuses.renaultInvoiceDateStart;
  var match_0$12 = match$10 ? match$10._0 : Curry._2(validators.renaultInvoiceDateStart.validate, input, metadata);
  var match$11 = fieldsStatuses.deliveryDateEnd;
  var match_0$13 = match$11 ? match$11._0 : Curry._2(validators.deliveryDateEnd.validate, input, metadata);
  var match$12 = fieldsStatuses.deliveryDateStart;
  var match_0$14 = match$12 ? match$12._0 : Curry._2(validators.deliveryDateStart.validate, input, metadata);
  var match_0$15 = {
    TAG: /* Ok */0,
    _0: input.customerInvoiceExists
  };
  var match$13 = fieldsStatuses.customerInvoiceEnd;
  var match_0$16 = match$13 ? match$13._0 : Curry._2(validators.customerInvoiceEnd.validate, input, metadata);
  var match$14 = fieldsStatuses.customerInvoiceStart;
  var match_0$17 = match$14 ? match$14._0 : Curry._2(validators.customerInvoiceStart.validate, input, metadata);
  var match_0$18 = {
    TAG: /* Ok */0,
    _0: input.registrationDateExists
  };
  var match$15 = fieldsStatuses.registrationDateEnd;
  var match_0$19 = match$15 ? match$15._0 : Curry._2(validators.registrationDateEnd.validate, input, metadata);
  var match$16 = fieldsStatuses.registrationDateStart;
  var match_0$20 = match$16 ? match$16._0 : Curry._2(validators.registrationDateStart.validate, input, metadata);
  var match$17 = fieldsStatuses.orderDateEnd;
  var match_0$21 = match$17 ? match$17._0 : Curry._2(validators.orderDateEnd.validate, input, metadata);
  var match$18 = fieldsStatuses.orderDateStart;
  var match_0$22 = match$18 ? match$18._0 : Curry._2(validators.orderDateStart.validate, input, metadata);
  var parcAutomaticResult = match_0;
  var parcAutomaticResult$1;
  if (parcAutomaticResult.TAG === /* Ok */0) {
    var exitDateParcEndResult = match_0$1;
    if (exitDateParcEndResult.TAG === /* Ok */0) {
      var exitDateParcStartResult = match_0$2;
      if (exitDateParcStartResult.TAG === /* Ok */0) {
        var entryDateParcEndResult = match_0$3;
        if (entryDateParcEndResult.TAG === /* Ok */0) {
          var entryDateParcStartResult = match_0$4;
          if (entryDateParcStartResult.TAG === /* Ok */0) {
            var parcActiveResult = match_0$5;
            if (parcActiveResult.TAG === /* Ok */0) {
              var agentCodeResult = match_0$6;
              if (agentCodeResult.TAG === /* Ok */0) {
                var salesmanNameResult = match_0$7;
                if (salesmanNameResult.TAG === /* Ok */0) {
                  var customerNameResult = match_0$8;
                  if (customerNameResult.TAG === /* Ok */0) {
                    var deliveredResult = match_0$9;
                    if (deliveredResult.TAG === /* Ok */0) {
                      var renaultInvoiceExistsResult = match_0$10;
                      if (renaultInvoiceExistsResult.TAG === /* Ok */0) {
                        var renaultInvoiceDateEndResult = match_0$11;
                        if (renaultInvoiceDateEndResult.TAG === /* Ok */0) {
                          var renaultInvoiceDateStartResult = match_0$12;
                          if (renaultInvoiceDateStartResult.TAG === /* Ok */0) {
                            var deliveryDateEndResult = match_0$13;
                            if (deliveryDateEndResult.TAG === /* Ok */0) {
                              var deliveryDateStartResult = match_0$14;
                              if (deliveryDateStartResult.TAG === /* Ok */0) {
                                var customerInvoiceExistsResult = match_0$15;
                                if (customerInvoiceExistsResult.TAG === /* Ok */0) {
                                  var customerInvoiceEndResult = match_0$16;
                                  if (customerInvoiceEndResult.TAG === /* Ok */0) {
                                    var customerInvoiceStartResult = match_0$17;
                                    if (customerInvoiceStartResult.TAG === /* Ok */0) {
                                      var registrationDateExistsResult = match_0$18;
                                      if (registrationDateExistsResult.TAG === /* Ok */0) {
                                        var registrationDateEndResult = match_0$19;
                                        if (registrationDateEndResult.TAG === /* Ok */0) {
                                          var registrationDateStartResult = match_0$20;
                                          if (registrationDateStartResult.TAG === /* Ok */0) {
                                            var orderDateEndResult = match_0$21;
                                            if (orderDateEndResult.TAG === /* Ok */0) {
                                              var orderDateStartResult = match_0$22;
                                              if (orderDateStartResult.TAG === /* Ok */0) {
                                                return {
                                                        TAG: /* Valid */0,
                                                        output: {
                                                          orderDateStart: orderDateStartResult._0,
                                                          orderDateEnd: orderDateEndResult._0,
                                                          registrationDateStart: registrationDateStartResult._0,
                                                          registrationDateEnd: registrationDateEndResult._0,
                                                          registrationDateExists: registrationDateExistsResult._0,
                                                          customerInvoiceStart: customerInvoiceStartResult._0,
                                                          customerInvoiceEnd: customerInvoiceEndResult._0,
                                                          customerInvoiceExists: customerInvoiceExistsResult._0,
                                                          deliveryDateStart: deliveryDateStartResult._0,
                                                          deliveryDateEnd: deliveryDateEndResult._0,
                                                          renaultInvoiceDateStart: renaultInvoiceDateStartResult._0,
                                                          renaultInvoiceDateEnd: renaultInvoiceDateEndResult._0,
                                                          renaultInvoiceExists: renaultInvoiceExistsResult._0,
                                                          delivered: deliveredResult._0,
                                                          customerName: customerNameResult._0,
                                                          salesmanName: salesmanNameResult._0,
                                                          agentCode: agentCodeResult._0,
                                                          parcActive: parcActiveResult._0,
                                                          entryDateParcStart: entryDateParcStartResult._0,
                                                          entryDateParcEnd: entryDateParcEndResult._0,
                                                          exitDateParcStart: exitDateParcStartResult._0,
                                                          exitDateParcEnd: exitDateParcEndResult._0,
                                                          parcAutomatic: parcAutomaticResult._0
                                                        },
                                                        fieldsStatuses: {
                                                          parcAutomatic: /* Dirty */{
                                                            _0: parcAutomaticResult,
                                                            _1: /* Shown */0
                                                          },
                                                          exitDateParcEnd: /* Dirty */{
                                                            _0: exitDateParcEndResult,
                                                            _1: /* Shown */0
                                                          },
                                                          exitDateParcStart: /* Dirty */{
                                                            _0: exitDateParcStartResult,
                                                            _1: /* Shown */0
                                                          },
                                                          entryDateParcEnd: /* Dirty */{
                                                            _0: entryDateParcEndResult,
                                                            _1: /* Shown */0
                                                          },
                                                          entryDateParcStart: /* Dirty */{
                                                            _0: entryDateParcStartResult,
                                                            _1: /* Shown */0
                                                          },
                                                          parcActive: /* Dirty */{
                                                            _0: parcActiveResult,
                                                            _1: /* Hidden */1
                                                          },
                                                          agentCode: /* Dirty */{
                                                            _0: agentCodeResult,
                                                            _1: /* Shown */0
                                                          },
                                                          salesmanName: /* Dirty */{
                                                            _0: salesmanNameResult,
                                                            _1: /* Shown */0
                                                          },
                                                          customerName: /* Dirty */{
                                                            _0: customerNameResult,
                                                            _1: /* Shown */0
                                                          },
                                                          delivered: /* Dirty */{
                                                            _0: deliveredResult,
                                                            _1: /* Shown */0
                                                          },
                                                          renaultInvoiceExists: /* Dirty */{
                                                            _0: renaultInvoiceExistsResult,
                                                            _1: /* Hidden */1
                                                          },
                                                          renaultInvoiceDateEnd: /* Dirty */{
                                                            _0: renaultInvoiceDateEndResult,
                                                            _1: /* Shown */0
                                                          },
                                                          renaultInvoiceDateStart: /* Dirty */{
                                                            _0: renaultInvoiceDateStartResult,
                                                            _1: /* Shown */0
                                                          },
                                                          deliveryDateEnd: /* Dirty */{
                                                            _0: deliveryDateEndResult,
                                                            _1: /* Shown */0
                                                          },
                                                          deliveryDateStart: /* Dirty */{
                                                            _0: deliveryDateStartResult,
                                                            _1: /* Shown */0
                                                          },
                                                          customerInvoiceExists: /* Dirty */{
                                                            _0: customerInvoiceExistsResult,
                                                            _1: /* Hidden */1
                                                          },
                                                          customerInvoiceEnd: /* Dirty */{
                                                            _0: customerInvoiceEndResult,
                                                            _1: /* Shown */0
                                                          },
                                                          customerInvoiceStart: /* Dirty */{
                                                            _0: customerInvoiceStartResult,
                                                            _1: /* Shown */0
                                                          },
                                                          registrationDateExists: /* Dirty */{
                                                            _0: registrationDateExistsResult,
                                                            _1: /* Hidden */1
                                                          },
                                                          registrationDateEnd: /* Dirty */{
                                                            _0: registrationDateEndResult,
                                                            _1: /* Shown */0
                                                          },
                                                          registrationDateStart: /* Dirty */{
                                                            _0: registrationDateStartResult,
                                                            _1: /* Shown */0
                                                          },
                                                          orderDateEnd: /* Dirty */{
                                                            _0: orderDateEndResult,
                                                            _1: /* Shown */0
                                                          },
                                                          orderDateStart: /* Dirty */{
                                                            _0: orderDateStartResult,
                                                            _1: /* Shown */0
                                                          }
                                                        },
                                                        collectionsStatuses: undefined
                                                      };
                                              }
                                              parcAutomaticResult$1 = parcAutomaticResult;
                                            } else {
                                              parcAutomaticResult$1 = parcAutomaticResult;
                                            }
                                          } else {
                                            parcAutomaticResult$1 = parcAutomaticResult;
                                          }
                                        } else {
                                          parcAutomaticResult$1 = parcAutomaticResult;
                                        }
                                      } else {
                                        parcAutomaticResult$1 = parcAutomaticResult;
                                      }
                                    } else {
                                      parcAutomaticResult$1 = parcAutomaticResult;
                                    }
                                  } else {
                                    parcAutomaticResult$1 = parcAutomaticResult;
                                  }
                                } else {
                                  parcAutomaticResult$1 = parcAutomaticResult;
                                }
                              } else {
                                parcAutomaticResult$1 = parcAutomaticResult;
                              }
                            } else {
                              parcAutomaticResult$1 = parcAutomaticResult;
                            }
                          } else {
                            parcAutomaticResult$1 = parcAutomaticResult;
                          }
                        } else {
                          parcAutomaticResult$1 = parcAutomaticResult;
                        }
                      } else {
                        parcAutomaticResult$1 = parcAutomaticResult;
                      }
                    } else {
                      parcAutomaticResult$1 = parcAutomaticResult;
                    }
                  } else {
                    parcAutomaticResult$1 = parcAutomaticResult;
                  }
                } else {
                  parcAutomaticResult$1 = parcAutomaticResult;
                }
              } else {
                parcAutomaticResult$1 = parcAutomaticResult;
              }
            } else {
              parcAutomaticResult$1 = parcAutomaticResult;
            }
          } else {
            parcAutomaticResult$1 = parcAutomaticResult;
          }
        } else {
          parcAutomaticResult$1 = parcAutomaticResult;
        }
      } else {
        parcAutomaticResult$1 = parcAutomaticResult;
      }
    } else {
      parcAutomaticResult$1 = parcAutomaticResult;
    }
  } else {
    parcAutomaticResult$1 = parcAutomaticResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            parcAutomatic: /* Dirty */{
              _0: parcAutomaticResult$1,
              _1: /* Shown */0
            },
            exitDateParcEnd: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            exitDateParcStart: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            entryDateParcEnd: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            entryDateParcStart: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            parcActive: /* Dirty */{
              _0: match_0$5,
              _1: /* Hidden */1
            },
            agentCode: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            salesmanName: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            },
            customerName: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            },
            delivered: /* Dirty */{
              _0: match_0$9,
              _1: /* Shown */0
            },
            renaultInvoiceExists: /* Dirty */{
              _0: match_0$10,
              _1: /* Hidden */1
            },
            renaultInvoiceDateEnd: /* Dirty */{
              _0: match_0$11,
              _1: /* Shown */0
            },
            renaultInvoiceDateStart: /* Dirty */{
              _0: match_0$12,
              _1: /* Shown */0
            },
            deliveryDateEnd: /* Dirty */{
              _0: match_0$13,
              _1: /* Shown */0
            },
            deliveryDateStart: /* Dirty */{
              _0: match_0$14,
              _1: /* Shown */0
            },
            customerInvoiceExists: /* Dirty */{
              _0: match_0$15,
              _1: /* Hidden */1
            },
            customerInvoiceEnd: /* Dirty */{
              _0: match_0$16,
              _1: /* Shown */0
            },
            customerInvoiceStart: /* Dirty */{
              _0: match_0$17,
              _1: /* Shown */0
            },
            registrationDateExists: /* Dirty */{
              _0: match_0$18,
              _1: /* Hidden */1
            },
            registrationDateEnd: /* Dirty */{
              _0: match_0$19,
              _1: /* Shown */0
            },
            registrationDateStart: /* Dirty */{
              _0: match_0$20,
              _1: /* Shown */0
            },
            orderDateEnd: /* Dirty */{
              _0: match_0$21,
              _1: /* Shown */0
            },
            orderDateStart: /* Dirty */{
              _0: match_0$22,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurParcAutomaticField */0 :
                  var result = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.parcAutomatic, validators_parcAutomatic, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.parcAutomatic = status;
                          return newrecord;
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurExitDateParcEndField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.exitDateParcEnd, validators_exitDateParcEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.exitDateParcEnd = status;
                          return newrecord;
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurExitDateParcStartField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.exitDateParcStart, validators_exitDateParcStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.exitDateParcStart = status;
                          return newrecord;
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEntryDateParcEndField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.entryDateParcEnd, validators_entryDateParcEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.entryDateParcEnd = status;
                          return newrecord;
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEntryDateParcStartField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.entryDateParcStart, validators_entryDateParcStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.entryDateParcStart = status;
                          return newrecord;
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurParcActiveField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithoutValidator(state.input.parcActive, state.fieldsStatuses.parcActive, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.parcActive = status;
                          return newrecord;
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAgentCodeField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.agentCode, validators_agentCode, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.agentCode = status;
                          return newrecord;
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSalesmanNameField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.salesmanName, validators_salesmanName, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.salesmanName = status;
                          return newrecord;
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCustomerNameField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.customerName, validators_customerName, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.customerName = status;
                          return newrecord;
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDeliveredField */9 :
                  var result$9 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.delivered, validators_delivered, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.delivered = status;
                          return newrecord;
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRenaultInvoiceExistsField */10 :
                  var result$10 = Formality.validateFieldOnBlurWithoutValidator(state.input.renaultInvoiceExists, state.fieldsStatuses.renaultInvoiceExists, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.renaultInvoiceExists = status;
                          return newrecord;
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRenaultInvoiceDateEndField */11 :
                  var result$11 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.renaultInvoiceDateEnd, validators_renaultInvoiceDateEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.renaultInvoiceDateEnd = status;
                          return newrecord;
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRenaultInvoiceDateStartField */12 :
                  var result$12 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.renaultInvoiceDateStart, validators_renaultInvoiceDateStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.renaultInvoiceDateStart = status;
                          return newrecord;
                        }));
                  if (result$12 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$12,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDeliveryDateEndField */13 :
                  var result$13 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.deliveryDateEnd, validators_deliveryDateEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.deliveryDateEnd = status;
                          return newrecord;
                        }));
                  if (result$13 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$13,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDeliveryDateStartField */14 :
                  var result$14 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.deliveryDateStart, validators_deliveryDateStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.deliveryDateStart = status;
                          return newrecord;
                        }));
                  if (result$14 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$14,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCustomerInvoiceExistsField */15 :
                  var result$15 = Formality.validateFieldOnBlurWithoutValidator(state.input.customerInvoiceExists, state.fieldsStatuses.customerInvoiceExists, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.customerInvoiceExists = status;
                          return newrecord;
                        }));
                  if (result$15 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$15,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCustomerInvoiceEndField */16 :
                  var result$16 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.customerInvoiceEnd, validators_customerInvoiceEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.customerInvoiceEnd = status;
                          return newrecord;
                        }));
                  if (result$16 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$16,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCustomerInvoiceStartField */17 :
                  var result$17 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.customerInvoiceStart, validators_customerInvoiceStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.customerInvoiceStart = status;
                          return newrecord;
                        }));
                  if (result$17 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$17,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRegistrationDateExistsField */18 :
                  var result$18 = Formality.validateFieldOnBlurWithoutValidator(state.input.registrationDateExists, state.fieldsStatuses.registrationDateExists, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.registrationDateExists = status;
                          return newrecord;
                        }));
                  if (result$18 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$18,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRegistrationDateEndField */19 :
                  var result$19 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.registrationDateEnd, validators_registrationDateEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.registrationDateEnd = status;
                          return newrecord;
                        }));
                  if (result$19 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$19,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRegistrationDateStartField */20 :
                  var result$20 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.registrationDateStart, validators_registrationDateStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.registrationDateStart = status;
                          return newrecord;
                        }));
                  if (result$20 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$20,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurOrderDateEndField */21 :
                  var result$21 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.orderDateEnd, validators_orderDateEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.orderDateEnd = status;
                          return newrecord;
                        }));
                  if (result$21 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$21,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurOrderDateStartField */22 :
                  var result$22 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.orderDateStart, validators_orderDateStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.orderDateStart = status;
                          return newrecord;
                        }));
                  if (result$22 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$22,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */23 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */23,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */24,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */26);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */25);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */24 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */25 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */26 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateParcAutomaticField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput, metadata, state.fieldsStatuses.parcAutomatic, state.submissionStatus, validators_parcAutomatic, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.parcAutomatic = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateExitDateParcEndField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$23 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$1, metadata, nextFieldsStatuses.contents.exitDateParcStart, validators_exitDateParcStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses.contents);
                          newrecord.exitDateParcStart = status;
                          return newrecord;
                        }));
                  if (result$23 !== undefined) {
                    nextFieldsStatuses.contents = result$23;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$1, metadata, nextFieldsStatuses.contents.exitDateParcEnd, state.submissionStatus, validators_exitDateParcEnd, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses.contents);
                                    newrecord.exitDateParcEnd = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateExitDateParcStartField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$24 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$2, metadata, nextFieldsStatuses$1.contents.exitDateParcEnd, validators_exitDateParcEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$1.contents);
                          newrecord.exitDateParcEnd = status;
                          return newrecord;
                        }));
                  if (result$24 !== undefined) {
                    nextFieldsStatuses$1.contents = result$24;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$2, metadata, nextFieldsStatuses$1.contents.exitDateParcStart, state.submissionStatus, validators_exitDateParcStart, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$1.contents);
                                    newrecord.exitDateParcStart = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEntryDateParcEndField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$2 = {
                    contents: state.fieldsStatuses
                  };
                  var result$25 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$3, metadata, nextFieldsStatuses$2.contents.entryDateParcStart, validators_entryDateParcStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$2.contents);
                          newrecord.entryDateParcStart = status;
                          return newrecord;
                        }));
                  if (result$25 !== undefined) {
                    nextFieldsStatuses$2.contents = result$25;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$3, metadata, nextFieldsStatuses$2.contents.entryDateParcEnd, state.submissionStatus, validators_entryDateParcEnd, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$2.contents);
                                    newrecord.entryDateParcEnd = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEntryDateParcStartField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$3 = {
                    contents: state.fieldsStatuses
                  };
                  var result$26 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$4, metadata, nextFieldsStatuses$3.contents.entryDateParcEnd, validators_entryDateParcEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$3.contents);
                          newrecord.entryDateParcEnd = status;
                          return newrecord;
                        }));
                  if (result$26 !== undefined) {
                    nextFieldsStatuses$3.contents = result$26;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$4, metadata, nextFieldsStatuses$3.contents.entryDateParcStart, state.submissionStatus, validators_entryDateParcStart, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$3.contents);
                                    newrecord.entryDateParcStart = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateParcActiveField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$4 = {
                    contents: state.fieldsStatuses
                  };
                  var result$27 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$5, metadata, nextFieldsStatuses$4.contents.entryDateParcStart, validators_entryDateParcStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$4.contents);
                          newrecord.entryDateParcStart = status;
                          return newrecord;
                        }));
                  if (result$27 !== undefined) {
                    nextFieldsStatuses$4.contents = result$27;
                  }
                  var result$28 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$5, metadata, nextFieldsStatuses$4.contents.entryDateParcEnd, validators_entryDateParcEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$4.contents);
                          newrecord.entryDateParcEnd = status;
                          return newrecord;
                        }));
                  if (result$28 !== undefined) {
                    nextFieldsStatuses$4.contents = result$28;
                  }
                  var result$29 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$5, metadata, nextFieldsStatuses$4.contents.exitDateParcStart, validators_exitDateParcStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$4.contents);
                          newrecord.exitDateParcStart = status;
                          return newrecord;
                        }));
                  if (result$29 !== undefined) {
                    nextFieldsStatuses$4.contents = result$29;
                  }
                  var result$30 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$5, metadata, nextFieldsStatuses$4.contents.exitDateParcEnd, validators_exitDateParcEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$4.contents);
                          newrecord.exitDateParcEnd = status;
                          return newrecord;
                        }));
                  if (result$30 !== undefined) {
                    nextFieldsStatuses$4.contents = result$30;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$5.parcActive, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$4.contents);
                                    newrecord.parcActive = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAgentCodeField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$6, metadata, state.fieldsStatuses.agentCode, state.submissionStatus, validators_agentCode, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.agentCode = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSalesmanNameField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$7, metadata, state.fieldsStatuses.salesmanName, state.submissionStatus, validators_salesmanName, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.salesmanName = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCustomerNameField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$8, metadata, state.fieldsStatuses.customerName, state.submissionStatus, validators_customerName, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.customerName = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDeliveredField */9 :
                  var nextInput$9 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$9, metadata, state.fieldsStatuses.delivered, state.submissionStatus, validators_delivered, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.delivered = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRenaultInvoiceExistsField */10 :
                  var nextInput$10 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$5 = {
                    contents: state.fieldsStatuses
                  };
                  var result$31 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$10, metadata, nextFieldsStatuses$5.contents.renaultInvoiceDateStart, validators_renaultInvoiceDateStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$5.contents);
                          newrecord.renaultInvoiceDateStart = status;
                          return newrecord;
                        }));
                  if (result$31 !== undefined) {
                    nextFieldsStatuses$5.contents = result$31;
                  }
                  var result$32 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$10, metadata, nextFieldsStatuses$5.contents.renaultInvoiceDateEnd, validators_renaultInvoiceDateEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$5.contents);
                          newrecord.renaultInvoiceDateEnd = status;
                          return newrecord;
                        }));
                  if (result$32 !== undefined) {
                    nextFieldsStatuses$5.contents = result$32;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$10.renaultInvoiceExists, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$5.contents);
                                    newrecord.renaultInvoiceExists = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRenaultInvoiceDateEndField */11 :
                  var nextInput$11 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$6 = {
                    contents: state.fieldsStatuses
                  };
                  var result$33 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$11, metadata, nextFieldsStatuses$6.contents.renaultInvoiceDateStart, validators_renaultInvoiceDateStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$6.contents);
                          newrecord.renaultInvoiceDateStart = status;
                          return newrecord;
                        }));
                  if (result$33 !== undefined) {
                    nextFieldsStatuses$6.contents = result$33;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$11, metadata, nextFieldsStatuses$6.contents.renaultInvoiceDateEnd, state.submissionStatus, validators_renaultInvoiceDateEnd, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$6.contents);
                                    newrecord.renaultInvoiceDateEnd = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRenaultInvoiceDateStartField */12 :
                  var nextInput$12 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$7 = {
                    contents: state.fieldsStatuses
                  };
                  var result$34 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$12, metadata, nextFieldsStatuses$7.contents.renaultInvoiceDateEnd, validators_renaultInvoiceDateEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$7.contents);
                          newrecord.renaultInvoiceDateEnd = status;
                          return newrecord;
                        }));
                  if (result$34 !== undefined) {
                    nextFieldsStatuses$7.contents = result$34;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$12, metadata, nextFieldsStatuses$7.contents.renaultInvoiceDateStart, state.submissionStatus, validators_renaultInvoiceDateStart, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$7.contents);
                                    newrecord.renaultInvoiceDateStart = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDeliveryDateEndField */13 :
                  var nextInput$13 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$8 = {
                    contents: state.fieldsStatuses
                  };
                  var result$35 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$13, metadata, nextFieldsStatuses$8.contents.deliveryDateStart, validators_deliveryDateStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$8.contents);
                          newrecord.deliveryDateStart = status;
                          return newrecord;
                        }));
                  if (result$35 !== undefined) {
                    nextFieldsStatuses$8.contents = result$35;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$13,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$13, metadata, nextFieldsStatuses$8.contents.deliveryDateEnd, state.submissionStatus, validators_deliveryDateEnd, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$8.contents);
                                    newrecord.deliveryDateEnd = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDeliveryDateStartField */14 :
                  var nextInput$14 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$9 = {
                    contents: state.fieldsStatuses
                  };
                  var result$36 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$14, metadata, nextFieldsStatuses$9.contents.deliveryDateEnd, validators_deliveryDateEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$9.contents);
                          newrecord.deliveryDateEnd = status;
                          return newrecord;
                        }));
                  if (result$36 !== undefined) {
                    nextFieldsStatuses$9.contents = result$36;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$14,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$14, metadata, nextFieldsStatuses$9.contents.deliveryDateStart, state.submissionStatus, validators_deliveryDateStart, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$9.contents);
                                    newrecord.deliveryDateStart = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCustomerInvoiceExistsField */15 :
                  var nextInput$15 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$10 = {
                    contents: state.fieldsStatuses
                  };
                  var result$37 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$15, metadata, nextFieldsStatuses$10.contents.customerInvoiceStart, validators_customerInvoiceStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$10.contents);
                          newrecord.customerInvoiceStart = status;
                          return newrecord;
                        }));
                  if (result$37 !== undefined) {
                    nextFieldsStatuses$10.contents = result$37;
                  }
                  var result$38 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$15, metadata, nextFieldsStatuses$10.contents.customerInvoiceEnd, validators_customerInvoiceEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$10.contents);
                          newrecord.customerInvoiceEnd = status;
                          return newrecord;
                        }));
                  if (result$38 !== undefined) {
                    nextFieldsStatuses$10.contents = result$38;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$15,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$15.customerInvoiceExists, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$10.contents);
                                    newrecord.customerInvoiceExists = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCustomerInvoiceEndField */16 :
                  var nextInput$16 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$11 = {
                    contents: state.fieldsStatuses
                  };
                  var result$39 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$16, metadata, nextFieldsStatuses$11.contents.customerInvoiceStart, validators_customerInvoiceStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$11.contents);
                          newrecord.customerInvoiceStart = status;
                          return newrecord;
                        }));
                  if (result$39 !== undefined) {
                    nextFieldsStatuses$11.contents = result$39;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$16,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$16, metadata, nextFieldsStatuses$11.contents.customerInvoiceEnd, state.submissionStatus, validators_customerInvoiceEnd, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$11.contents);
                                    newrecord.customerInvoiceEnd = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCustomerInvoiceStartField */17 :
                  var nextInput$17 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$12 = {
                    contents: state.fieldsStatuses
                  };
                  var result$40 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$17, metadata, nextFieldsStatuses$12.contents.customerInvoiceEnd, validators_customerInvoiceEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$12.contents);
                          newrecord.customerInvoiceEnd = status;
                          return newrecord;
                        }));
                  if (result$40 !== undefined) {
                    nextFieldsStatuses$12.contents = result$40;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$17,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$17, metadata, nextFieldsStatuses$12.contents.customerInvoiceStart, state.submissionStatus, validators_customerInvoiceStart, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$12.contents);
                                    newrecord.customerInvoiceStart = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRegistrationDateExistsField */18 :
                  var nextInput$18 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$13 = {
                    contents: state.fieldsStatuses
                  };
                  var result$41 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$18, metadata, nextFieldsStatuses$13.contents.registrationDateStart, validators_registrationDateStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$13.contents);
                          newrecord.registrationDateStart = status;
                          return newrecord;
                        }));
                  if (result$41 !== undefined) {
                    nextFieldsStatuses$13.contents = result$41;
                  }
                  var result$42 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$18, metadata, nextFieldsStatuses$13.contents.registrationDateEnd, validators_registrationDateEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$13.contents);
                          newrecord.registrationDateEnd = status;
                          return newrecord;
                        }));
                  if (result$42 !== undefined) {
                    nextFieldsStatuses$13.contents = result$42;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$18,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$18.registrationDateExists, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$13.contents);
                                    newrecord.registrationDateExists = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRegistrationDateEndField */19 :
                  var nextInput$19 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$14 = {
                    contents: state.fieldsStatuses
                  };
                  var result$43 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$19, metadata, nextFieldsStatuses$14.contents.registrationDateStart, validators_registrationDateStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$14.contents);
                          newrecord.registrationDateStart = status;
                          return newrecord;
                        }));
                  if (result$43 !== undefined) {
                    nextFieldsStatuses$14.contents = result$43;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$19,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$19, metadata, nextFieldsStatuses$14.contents.registrationDateEnd, state.submissionStatus, validators_registrationDateEnd, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$14.contents);
                                    newrecord.registrationDateEnd = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRegistrationDateStartField */20 :
                  var nextInput$20 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$15 = {
                    contents: state.fieldsStatuses
                  };
                  var result$44 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$20, metadata, nextFieldsStatuses$15.contents.registrationDateEnd, validators_registrationDateEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$15.contents);
                          newrecord.registrationDateEnd = status;
                          return newrecord;
                        }));
                  if (result$44 !== undefined) {
                    nextFieldsStatuses$15.contents = result$44;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$20,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$20, metadata, nextFieldsStatuses$15.contents.registrationDateStart, state.submissionStatus, validators_registrationDateStart, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$15.contents);
                                    newrecord.registrationDateStart = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateOrderDateEndField */21 :
                  var nextInput$21 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$16 = {
                    contents: state.fieldsStatuses
                  };
                  var result$45 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$21, metadata, nextFieldsStatuses$16.contents.orderDateStart, validators_orderDateStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$16.contents);
                          newrecord.orderDateStart = status;
                          return newrecord;
                        }));
                  if (result$45 !== undefined) {
                    nextFieldsStatuses$16.contents = result$45;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$21,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$21, metadata, nextFieldsStatuses$16.contents.orderDateEnd, state.submissionStatus, validators_orderDateEnd, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$16.contents);
                                    newrecord.orderDateEnd = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateOrderDateStartField */22 :
                  var nextInput$22 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$17 = {
                    contents: state.fieldsStatuses
                  };
                  var result$46 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$22, metadata, nextFieldsStatuses$17.contents.orderDateEnd, validators_orderDateEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$17.contents);
                          newrecord.orderDateEnd = status;
                          return newrecord;
                        }));
                  if (result$46 !== undefined) {
                    nextFieldsStatuses$17.contents = result$46;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$22,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$22, metadata, nextFieldsStatuses$17.contents.orderDateStart, state.submissionStatus, validators_orderDateStart, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$17.contents);
                                    newrecord.orderDateStart = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */23 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */24 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */25 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateParcAutomatic: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateParcAutomaticField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateExitDateParcEnd: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateExitDateParcEndField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateExitDateParcStart: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateExitDateParcStartField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEntryDateParcEnd: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEntryDateParcEndField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEntryDateParcStart: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEntryDateParcStartField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateParcActive: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateParcActiveField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateAgentCode: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAgentCodeField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateSalesmanName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSalesmanNameField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCustomerName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCustomerNameField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDelivered: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDeliveredField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateRenaultInvoiceExists: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRenaultInvoiceExistsField */10,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateRenaultInvoiceDateEnd: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRenaultInvoiceDateEndField */11,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateRenaultInvoiceDateStart: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRenaultInvoiceDateStartField */12,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDeliveryDateEnd: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDeliveryDateEndField */13,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDeliveryDateStart: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDeliveryDateStartField */14,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCustomerInvoiceExists: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCustomerInvoiceExistsField */15,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCustomerInvoiceEnd: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCustomerInvoiceEndField */16,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCustomerInvoiceStart: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCustomerInvoiceStartField */17,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateRegistrationDateExists: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRegistrationDateExistsField */18,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateRegistrationDateEnd: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRegistrationDateEndField */19,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateRegistrationDateStart: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRegistrationDateStartField */20,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateOrderDateEnd: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrderDateEndField */21,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateOrderDateStart: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrderDateStartField */22,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurParcAutomatic: (function (param) {
              Curry._1(dispatch, /* BlurParcAutomaticField */0);
            }),
          blurExitDateParcEnd: (function (param) {
              Curry._1(dispatch, /* BlurExitDateParcEndField */1);
            }),
          blurExitDateParcStart: (function (param) {
              Curry._1(dispatch, /* BlurExitDateParcStartField */2);
            }),
          blurEntryDateParcEnd: (function (param) {
              Curry._1(dispatch, /* BlurEntryDateParcEndField */3);
            }),
          blurEntryDateParcStart: (function (param) {
              Curry._1(dispatch, /* BlurEntryDateParcStartField */4);
            }),
          blurParcActive: (function (param) {
              Curry._1(dispatch, /* BlurParcActiveField */5);
            }),
          blurAgentCode: (function (param) {
              Curry._1(dispatch, /* BlurAgentCodeField */6);
            }),
          blurSalesmanName: (function (param) {
              Curry._1(dispatch, /* BlurSalesmanNameField */7);
            }),
          blurCustomerName: (function (param) {
              Curry._1(dispatch, /* BlurCustomerNameField */8);
            }),
          blurDelivered: (function (param) {
              Curry._1(dispatch, /* BlurDeliveredField */9);
            }),
          blurRenaultInvoiceExists: (function (param) {
              Curry._1(dispatch, /* BlurRenaultInvoiceExistsField */10);
            }),
          blurRenaultInvoiceDateEnd: (function (param) {
              Curry._1(dispatch, /* BlurRenaultInvoiceDateEndField */11);
            }),
          blurRenaultInvoiceDateStart: (function (param) {
              Curry._1(dispatch, /* BlurRenaultInvoiceDateStartField */12);
            }),
          blurDeliveryDateEnd: (function (param) {
              Curry._1(dispatch, /* BlurDeliveryDateEndField */13);
            }),
          blurDeliveryDateStart: (function (param) {
              Curry._1(dispatch, /* BlurDeliveryDateStartField */14);
            }),
          blurCustomerInvoiceExists: (function (param) {
              Curry._1(dispatch, /* BlurCustomerInvoiceExistsField */15);
            }),
          blurCustomerInvoiceEnd: (function (param) {
              Curry._1(dispatch, /* BlurCustomerInvoiceEndField */16);
            }),
          blurCustomerInvoiceStart: (function (param) {
              Curry._1(dispatch, /* BlurCustomerInvoiceStartField */17);
            }),
          blurRegistrationDateExists: (function (param) {
              Curry._1(dispatch, /* BlurRegistrationDateExistsField */18);
            }),
          blurRegistrationDateEnd: (function (param) {
              Curry._1(dispatch, /* BlurRegistrationDateEndField */19);
            }),
          blurRegistrationDateStart: (function (param) {
              Curry._1(dispatch, /* BlurRegistrationDateStartField */20);
            }),
          blurOrderDateEnd: (function (param) {
              Curry._1(dispatch, /* BlurOrderDateEndField */21);
            }),
          blurOrderDateStart: (function (param) {
              Curry._1(dispatch, /* BlurOrderDateStartField */22);
            }),
          parcAutomaticResult: Formality.exposeFieldResult(state.fieldsStatuses.parcAutomatic),
          exitDateParcEndResult: Formality.exposeFieldResult(state.fieldsStatuses.exitDateParcEnd),
          exitDateParcStartResult: Formality.exposeFieldResult(state.fieldsStatuses.exitDateParcStart),
          entryDateParcEndResult: Formality.exposeFieldResult(state.fieldsStatuses.entryDateParcEnd),
          entryDateParcStartResult: Formality.exposeFieldResult(state.fieldsStatuses.entryDateParcStart),
          parcActiveResult: Formality.exposeFieldResult(state.fieldsStatuses.parcActive),
          agentCodeResult: Formality.exposeFieldResult(state.fieldsStatuses.agentCode),
          salesmanNameResult: Formality.exposeFieldResult(state.fieldsStatuses.salesmanName),
          customerNameResult: Formality.exposeFieldResult(state.fieldsStatuses.customerName),
          deliveredResult: Formality.exposeFieldResult(state.fieldsStatuses.delivered),
          renaultInvoiceExistsResult: Formality.exposeFieldResult(state.fieldsStatuses.renaultInvoiceExists),
          renaultInvoiceDateEndResult: Formality.exposeFieldResult(state.fieldsStatuses.renaultInvoiceDateEnd),
          renaultInvoiceDateStartResult: Formality.exposeFieldResult(state.fieldsStatuses.renaultInvoiceDateStart),
          deliveryDateEndResult: Formality.exposeFieldResult(state.fieldsStatuses.deliveryDateEnd),
          deliveryDateStartResult: Formality.exposeFieldResult(state.fieldsStatuses.deliveryDateStart),
          customerInvoiceExistsResult: Formality.exposeFieldResult(state.fieldsStatuses.customerInvoiceExists),
          customerInvoiceEndResult: Formality.exposeFieldResult(state.fieldsStatuses.customerInvoiceEnd),
          customerInvoiceStartResult: Formality.exposeFieldResult(state.fieldsStatuses.customerInvoiceStart),
          registrationDateExistsResult: Formality.exposeFieldResult(state.fieldsStatuses.registrationDateExists),
          registrationDateEndResult: Formality.exposeFieldResult(state.fieldsStatuses.registrationDateEnd),
          registrationDateStartResult: Formality.exposeFieldResult(state.fieldsStatuses.registrationDateStart),
          orderDateEndResult: Formality.exposeFieldResult(state.fieldsStatuses.orderDateEnd),
          orderDateStartResult: Formality.exposeFieldResult(state.fieldsStatuses.orderDateStart),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              var exit = 0;
              if (match.parcAutomatic || match.exitDateParcEnd || match.exitDateParcStart || match.entryDateParcEnd || match.entryDateParcStart || match.parcActive || match.agentCode || match.salesmanName || match.customerName || match.delivered || match.renaultInvoiceExists || match.renaultInvoiceDateEnd || match.renaultInvoiceDateStart || match.deliveryDateEnd || match.deliveryDateStart || match.customerInvoiceExists || match.customerInvoiceEnd || match.customerInvoiceStart || match.registrationDateExists || match.registrationDateEnd || match.registrationDateStart || match.orderDateEnd) {
                exit = 1;
              } else {
                if (!match.orderDateStart) {
                  return false;
                }
                exit = 1;
              }
              if (exit === 1) {
                var exit$1 = 0;
                exit$1 = 2;
                if (exit$1 === 2) {
                  var exit$2 = 0;
                  exit$2 = 3;
                  if (exit$2 === 3) {
                    var exit$3 = 0;
                    exit$3 = 4;
                    if (exit$3 === 4) {
                      var exit$4 = 0;
                      exit$4 = 5;
                      if (exit$4 === 5) {
                        var exit$5 = 0;
                        exit$5 = 6;
                        if (exit$5 === 6) {
                          var exit$6 = 0;
                          exit$6 = 7;
                          if (exit$6 === 7) {
                            var exit$7 = 0;
                            exit$7 = 8;
                            if (exit$7 === 8) {
                              var exit$8 = 0;
                              exit$8 = 9;
                              if (exit$8 === 9) {
                                var exit$9 = 0;
                                exit$9 = 10;
                                if (exit$9 === 10) {
                                  var exit$10 = 0;
                                  exit$10 = 11;
                                  if (exit$10 === 11) {
                                    var exit$11 = 0;
                                    exit$11 = 12;
                                    if (exit$11 === 12) {
                                      return true;
                                    }
                                    
                                  }
                                  
                                }
                                
                              }
                              
                            }
                            
                          }
                          
                        }
                        
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */23);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */24);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */25);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */25,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */26);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  orderDateStart: undefined,
  orderDateEnd: undefined,
  registrationDateStart: undefined,
  registrationDateEnd: undefined,
  registrationDateExists: "",
  customerInvoiceStart: undefined,
  customerInvoiceEnd: undefined,
  customerInvoiceExists: "",
  deliveryDateStart: undefined,
  deliveryDateEnd: undefined,
  renaultInvoiceDateStart: undefined,
  renaultInvoiceDateEnd: undefined,
  renaultInvoiceExists: "",
  delivered: "",
  customerName: "",
  salesmanName: "",
  agentCode: "",
  parcActive: "",
  entryDateParcStart: undefined,
  entryDateParcEnd: undefined,
  exitDateParcStart: undefined,
  exitDateParcEnd: undefined,
  parcAutomatic: ""
};

export {
  todayStr ,
  boolFieldToEmpty ,
  datePairToQuery ,
  TableFilterForm ,
  initialState$1 as initialState,
}
/* todayStr Not a pure module */
