// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../common/utils/Utils_Form.bs.js";
import * as FinancialCode_Search_Types$DvmAdminFrontendFr from "./FinancialCode_Search_Types.bs.js";

var validators_endDate = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.endDate, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.dateToQueryParam(FinancialCode_Search_Types$DvmAdminFrontendFr.Fields.endDate, date);
                  }));
    })
};

var validators_startDate = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.startDate, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.dateToQueryParam(FinancialCode_Search_Types$DvmAdminFrontendFr.Fields.startDate, date);
                  }));
    })
};

var validators_creationDate = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.creationDate, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.dateToQueryParam(FinancialCode_Search_Types$DvmAdminFrontendFr.Fields.creationDate, date);
                  }));
    })
};

var validators_codeType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: FinancialCode_Search_Types$DvmAdminFrontendFr.Fields.codeType,
                    value: param.codeType
                  })
            };
    })
};

var validators_codeLabel = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: FinancialCode_Search_Types$DvmAdminFrontendFr.Fields.codeLabel,
                    value: param.codeLabel
                  })
            };
    })
};

var validators_codeId = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: FinancialCode_Search_Types$DvmAdminFrontendFr.Fields.codeId,
                    value: param.codeId
                  })
            };
    })
};

var validators = {
  endDate: validators_endDate,
  startDate: validators_startDate,
  creationDate: validators_creationDate,
  codeType: validators_codeType,
  codeLabel: validators_codeLabel,
  codeId: validators_codeId
};

function initialFieldsStatuses(_input) {
  return {
          endDate: /* Pristine */0,
          startDate: /* Pristine */0,
          creationDate: /* Pristine */0,
          codeType: /* Pristine */0,
          codeLabel: /* Pristine */0,
          codeId: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.endDate;
  var match_0 = match ? match._0 : Curry._1(validators.endDate.validate, input);
  var match$1 = fieldsStatuses.startDate;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.startDate.validate, input);
  var match$2 = fieldsStatuses.creationDate;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.creationDate.validate, input);
  var match$3 = fieldsStatuses.codeType;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.codeType.validate, input);
  var match$4 = fieldsStatuses.codeLabel;
  var match_0$4 = match$4 ? match$4._0 : Curry._1(validators.codeLabel.validate, input);
  var match$5 = fieldsStatuses.codeId;
  var match_0$5 = match$5 ? match$5._0 : Curry._1(validators.codeId.validate, input);
  var endDateResult = match_0;
  var endDateResult$1;
  if (endDateResult.TAG === /* Ok */0) {
    var startDateResult = match_0$1;
    if (startDateResult.TAG === /* Ok */0) {
      var creationDateResult = match_0$2;
      if (creationDateResult.TAG === /* Ok */0) {
        var codeTypeResult = match_0$3;
        if (codeTypeResult.TAG === /* Ok */0) {
          var codeLabelResult = match_0$4;
          if (codeLabelResult.TAG === /* Ok */0) {
            var codeIdResult = match_0$5;
            if (codeIdResult.TAG === /* Ok */0) {
              return {
                      TAG: /* Valid */0,
                      output: {
                        codeId: codeIdResult._0,
                        codeLabel: codeLabelResult._0,
                        codeType: codeTypeResult._0,
                        creationDate: creationDateResult._0,
                        startDate: startDateResult._0,
                        endDate: endDateResult._0
                      },
                      fieldsStatuses: {
                        endDate: /* Dirty */{
                          _0: endDateResult,
                          _1: /* Shown */0
                        },
                        startDate: /* Dirty */{
                          _0: startDateResult,
                          _1: /* Shown */0
                        },
                        creationDate: /* Dirty */{
                          _0: creationDateResult,
                          _1: /* Shown */0
                        },
                        codeType: /* Dirty */{
                          _0: codeTypeResult,
                          _1: /* Shown */0
                        },
                        codeLabel: /* Dirty */{
                          _0: codeLabelResult,
                          _1: /* Shown */0
                        },
                        codeId: /* Dirty */{
                          _0: codeIdResult,
                          _1: /* Shown */0
                        }
                      },
                      collectionsStatuses: undefined
                    };
            }
            endDateResult$1 = endDateResult;
          } else {
            endDateResult$1 = endDateResult;
          }
        } else {
          endDateResult$1 = endDateResult;
        }
      } else {
        endDateResult$1 = endDateResult;
      }
    } else {
      endDateResult$1 = endDateResult;
    }
  } else {
    endDateResult$1 = endDateResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            endDate: /* Dirty */{
              _0: endDateResult$1,
              _1: /* Shown */0
            },
            startDate: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            creationDate: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            codeType: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            codeLabel: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            codeId: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurEndDateField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.endDate, validators_endDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: status,
                                  startDate: init.startDate,
                                  creationDate: init.creationDate,
                                  codeType: init.codeType,
                                  codeLabel: init.codeLabel,
                                  codeId: init.codeId
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurStartDateField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.startDate, validators_startDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: init.endDate,
                                  startDate: status,
                                  creationDate: init.creationDate,
                                  codeType: init.codeType,
                                  codeLabel: init.codeLabel,
                                  codeId: init.codeId
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCreationDateField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.creationDate, validators_creationDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  creationDate: status,
                                  codeType: init.codeType,
                                  codeLabel: init.codeLabel,
                                  codeId: init.codeId
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCodeTypeField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.codeType, validators_codeType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  creationDate: init.creationDate,
                                  codeType: status,
                                  codeLabel: init.codeLabel,
                                  codeId: init.codeId
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCodeLabelField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.codeLabel, validators_codeLabel, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  creationDate: init.creationDate,
                                  codeType: init.codeType,
                                  codeLabel: status,
                                  codeId: init.codeId
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCodeIdField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.codeId, validators_codeId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  creationDate: init.creationDate,
                                  codeType: init.codeType,
                                  codeLabel: init.codeLabel,
                                  codeId: status
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */6 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */6,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */7,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */9);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */8);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */7 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */8 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */9 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateEndDateField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.endDate, state.submissionStatus, validators_endDate, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endDate: status,
                                            startDate: init.startDate,
                                            creationDate: init.creationDate,
                                            codeType: init.codeType,
                                            codeLabel: init.codeLabel,
                                            codeId: init.codeId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateStartDateField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.startDate, state.submissionStatus, validators_startDate, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endDate: init.endDate,
                                            startDate: status,
                                            creationDate: init.creationDate,
                                            codeType: init.codeType,
                                            codeLabel: init.codeLabel,
                                            codeId: init.codeId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCreationDateField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.creationDate, state.submissionStatus, validators_creationDate, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            creationDate: status,
                                            codeType: init.codeType,
                                            codeLabel: init.codeLabel,
                                            codeId: init.codeId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCodeTypeField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.codeType, state.submissionStatus, validators_codeType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            creationDate: init.creationDate,
                                            codeType: status,
                                            codeLabel: init.codeLabel,
                                            codeId: init.codeId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCodeLabelField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.codeLabel, state.submissionStatus, validators_codeLabel, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            creationDate: init.creationDate,
                                            codeType: init.codeType,
                                            codeLabel: status,
                                            codeId: init.codeId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCodeIdField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.codeId, state.submissionStatus, validators_codeId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            creationDate: init.creationDate,
                                            codeType: init.codeType,
                                            codeLabel: init.codeLabel,
                                            codeId: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */6 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */7 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */8 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateEndDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEndDateField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateStartDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateStartDateField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCreationDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCreationDateField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCodeType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCodeTypeField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCodeLabel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCodeLabelField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCodeId: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCodeIdField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurEndDate: (function (param) {
              Curry._1(dispatch, /* BlurEndDateField */0);
            }),
          blurStartDate: (function (param) {
              Curry._1(dispatch, /* BlurStartDateField */1);
            }),
          blurCreationDate: (function (param) {
              Curry._1(dispatch, /* BlurCreationDateField */2);
            }),
          blurCodeType: (function (param) {
              Curry._1(dispatch, /* BlurCodeTypeField */3);
            }),
          blurCodeLabel: (function (param) {
              Curry._1(dispatch, /* BlurCodeLabelField */4);
            }),
          blurCodeId: (function (param) {
              Curry._1(dispatch, /* BlurCodeIdField */5);
            }),
          endDateResult: Formality.exposeFieldResult(state.fieldsStatuses.endDate),
          startDateResult: Formality.exposeFieldResult(state.fieldsStatuses.startDate),
          creationDateResult: Formality.exposeFieldResult(state.fieldsStatuses.creationDate),
          codeTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.codeType),
          codeLabelResult: Formality.exposeFieldResult(state.fieldsStatuses.codeLabel),
          codeIdResult: Formality.exposeFieldResult(state.fieldsStatuses.codeId),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.endDate || match.startDate || match.creationDate || match.codeType || match.codeLabel || match.codeId) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */6);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */7);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */8);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */8,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */9);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  codeId: "",
  codeLabel: "",
  codeType: "",
  creationDate: undefined,
  startDate: undefined,
  endDate: undefined
};

export {
  TableFilterForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
