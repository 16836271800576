// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../../common/components/App_Modal.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../../../common/utils/Utils_Api.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../../../common/components/table/App_Table_Pagination.bs.js";
import * as Flash_Edit_Fleet_Version_Modal_Api$DvmAdminFrontendFr from "./Flash_Edit_Fleet_Version_Modal_Api.bs.js";
import * as Flash_Edit_Fleet_Version_Add_Selection$DvmAdminFrontendFr from "./Flash_Edit_Fleet_Version_Add_Selection.bs.js";
import * as Flash_Edit_Fleet_Version_Duplicate_Action$DvmAdminFrontendFr from "./Flash_Edit_Fleet_Version_Duplicate_Action.bs.js";
import * as Flash_Edit_Fleet_Version_Add_Search_Filter$DvmAdminFrontendFr from "./Flash_Edit_Fleet_Version_Add_Search_Filter.bs.js";

function Flash_Edit_Fleet_Version_Add_Modal(Props) {
  var flash = Props.flash;
  var onClose = Props.onClose;
  var onOk = Props.onOk;
  var actionDistinctVersions = Props.actionDistinctVersions;
  var fetchUpdatedModelsForDropdownAndCumulables = Props.fetchUpdatedModelsForDropdownAndCumulables;
  var actionType = Props.actionType;
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var saveResult = match[0];
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match$1 = React.useState(function () {
        return /* [] */0;
      });
  var setAllOptions = match$1[1];
  var match$2 = React.useState(function () {
        return /* [] */0;
      });
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDuplicatedVersions = match$3[1];
  var duplicatedVersions = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var withClosedModels = match$4[0];
  var match$5 = React.useState(function () {
        return false;
      });
  var duplicateActionWithClosedModels = match$5[0];
  var match$6 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match$6[1];
  var tablePage = match$6[0];
  var match$7 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: Caml_array.get(App_Table_Pagination$DvmAdminFrontendFr.options, 0),
                headers: /* [] */0,
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$7[1];
  var match$8 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$9 = match$8[0];
  var userManager = match$9.userManager;
  var selectedRole = match$9.selectedRole;
  var match$10 = React.useState(function () {
        return /* [] */0;
      });
  var setDuplicateVersions = match$10[1];
  var match$11 = React.useState(function () {
        return "";
      });
  var selectedAction = match$11[0];
  var fetchData = function (submissionCallbacks, fetchRequest) {
    Flash_Edit_Fleet_Version_Modal_Api$DvmAdminFrontendFr.fetchAllVersions(submissionCallbacks, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
              page: fetchRequest.page,
              rowsPerPage: 2000,
              headers: fetchRequest.headers,
              filterQueryParams: fetchRequest.filterQueryParams,
              additionalQueryParams: fetchRequest.additionalQueryParams
            }), selectedRole, withClosedModels);
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  React.useEffect((function () {
          if (typeof tablePage === "number") {
            tablePage === /* NotStarted */0;
          } else if (tablePage.TAG === /* Ok */0) {
            var table = tablePage._0;
            Curry._1(setAllOptions, (function (param) {
                    return table.content;
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: tablePage._0
                      }
                    }));
          }
        }), [tablePage]);
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(fetchUpdatedModelsForDropdownAndCumulables, undefined);
            Curry._1(onOk, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  React.useEffect((function () {
          if (typeof duplicatedVersions === "number") {
            duplicatedVersions === /* NotStarted */0;
          } else if (duplicatedVersions.TAG === /* Ok */0) {
            var table = duplicatedVersions._0;
            Curry._1(setDuplicateVersions, (function (param) {
                    return Belt_List.keep(table.content, (function (element) {
                                  return !Belt_List.some(actionDistinctVersions, (function (x) {
                                                return x.version === element.version;
                                              }));
                                }));
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: duplicatedVersions._0
                      }
                    }));
          }
        }), [duplicatedVersions]);
  React.useEffect((function () {
          if (selectedAction === "") {
            Curry._1(setDuplicatedVersions, (function (param) {
                    return {
                            TAG: /* Ok */0,
                            _0: {
                              content: /* [] */0,
                              total: 0
                            }
                          };
                  }));
          } else {
            Flash_Edit_Fleet_Version_Modal_Api$DvmAdminFrontendFr.fetchVersionsOfAction(userManager, setDuplicatedVersions, selectedAction, selectedRole, duplicateActionWithClosedModels);
          }
        }), [
        selectedAction,
        duplicateActionWithClosedModels
      ]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.addVersions),
              minWidth: "1200px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(duplicatedVersions) || App_Types_Result$DvmAdminFrontendFr.isPending(saveResult) || App_Types_Result$DvmAdminFrontendFr.isPending(tablePage),
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          spacing: 5
                        }, React.createElement(Flash_Edit_Fleet_Version_Duplicate_Action$DvmAdminFrontendFr.make, {
                              flash: flash,
                              setSelectedAction: match$11[1],
                              selectedAction: selectedAction,
                              duplicateActionWithClosedModels: duplicateActionWithClosedModels,
                              setDuplicateActionWithClosedModels: match$5[1],
                              actionType: actionType
                            }), React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              direction: "column",
                              item: true,
                              spacing: 1
                            }, React.createElement(Core.Grid, {
                                  children: React.createElement(Flash_Edit_Fleet_Version_Add_Search_Filter$DvmAdminFrontendFr.make, {
                                        allOptions: match$1[0],
                                        fetchData: fetchData,
                                        fetchRequest: match$7[0],
                                        setFilteredOptions: match$2[1],
                                        withClosedModels: withClosedModels,
                                        setWithClosedModels: match$4[1]
                                      }),
                                  item: true
                                }), React.createElement(Core.Grid, {
                                  children: React.createElement(Flash_Edit_Fleet_Version_Add_Selection$DvmAdminFrontendFr.make, {
                                        filteredOptions: match$2[0],
                                        flash: flash,
                                        setSaveResult: match[1],
                                        duplicateVersions: match$10[0],
                                        actionDistinctVersions: actionDistinctVersions
                                      }),
                                  item: true
                                })))
                  })
            });
}

var Api;

var Types;

var make = Flash_Edit_Fleet_Version_Add_Modal;

export {
  Api ,
  Types ,
  make ,
}
/* react Not a pure module */
