// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../common/types/App_Types_Date.bs.js";

function duplicationRow_encode(v) {
  return Js_dict.fromArray([
              [
                "avsMarginGridID",
                Decco.stringToJson(v.avsMarginGridID)
              ],
              [
                "categoryID",
                Decco.stringToJson(v.categoryID)
              ],
              [
                "discount",
                Decco.floatToJson(v.discount)
              ],
              [
                "startDate",
                Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function duplicationRow_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var avsMarginGridID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "avsMarginGridID"), null));
  if (avsMarginGridID.TAG === /* Ok */0) {
    var categoryID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "categoryID"), null));
    if (categoryID.TAG === /* Ok */0) {
      var discount = Decco.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "discount"), null));
      if (discount.TAG === /* Ok */0) {
        var startDate = Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
        if (startDate.TAG === /* Ok */0) {
          var endDate = Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
          if (endDate.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      avsMarginGridID: avsMarginGridID._0,
                      categoryID: categoryID._0,
                      discount: discount._0,
                      startDate: startDate._0,
                      endDate: endDate._0
                    }
                  };
          }
          var e = endDate._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".endDate" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = startDate._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".startDate" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = discount._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".discount" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = categoryID._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".categoryID" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = avsMarginGridID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".avsMarginGridID" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function duplication_encode(v) {
  return Js_dict.fromArray([
              [
                "duplicatedGridID",
                Decco.stringToJson(v.duplicatedGridID)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ],
              [
                "duplications",
                Decco.listToJson(duplicationRow_encode, v.duplications)
              ]
            ]);
}

function duplication_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var duplicatedGridID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "duplicatedGridID"), null));
  if (duplicatedGridID.TAG === /* Ok */0) {
    var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
    if (startDate.TAG === /* Ok */0) {
      var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
      if (endDate.TAG === /* Ok */0) {
        var duplications = Decco.listFromJson(duplicationRow_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "duplications"), null));
        if (duplications.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    duplicatedGridID: duplicatedGridID._0,
                    startDate: startDate._0,
                    endDate: endDate._0,
                    duplications: duplications._0
                  }
                };
        }
        var e = duplications._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".duplications" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = endDate._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".endDate" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = startDate._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".startDate" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = duplicatedGridID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".duplicatedGridID" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

var delimiter = ":::";

export {
  duplicationRow_encode ,
  duplicationRow_decode ,
  duplication_encode ,
  duplication_decode ,
  delimiter ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
