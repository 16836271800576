// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Spinner$DvmAdminFrontendFr from "../../components/App_Spinner.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../App_Typography.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../types/App_Types_Result.bs.js";
import * as Messages_Contract_Errors$DvmAdminFrontendFr from "../../../intl/messages/contract/Messages_Contract_Errors.bs.js";
import * as File_Upload_Contract_Errors_Api$DvmAdminFrontendFr from "./File_Upload_Contract_Errors_Api.bs.js";
import * as File_Upload_Contract_Errors_Page$DvmAdminFrontendFr from "./File_Upload_Contract_Errors_Page.bs.js";

function File_Upload_Contract_Errors_Check(Props) {
  var onClose = Props.onClose;
  var alert = ReactAlert.useAlert();
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match[1];
  var result = match[0];
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  React.useEffect((function () {
          File_Upload_Contract_Errors_Api$DvmAdminFrontendFr.fetchAttachmentUploadErrorsSearch(userManager, setResult, "?limit=1&offset=0", selectedRole);
        }), []);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.mapWithDefault(result, undefined, (function (param) {
                  if (param.total === 0) {
                    Curry._1(onClose, undefined);
                    return Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
                  }
                  
                }));
        }), [result]);
  var tmp;
  if (typeof result === "number") {
    tmp = result === /* NotStarted */0 ? null : React.createElement(App_Spinner$DvmAdminFrontendFr.make, {});
  } else if (result.TAG === /* Ok */0) {
    var total = result._0.total;
    tmp = total > 0 ? React.createElement(Core.Grid, {
            children: null,
            container: true,
            direction: "column",
            item: true
          }, React.createElement(Core.Grid, {
                children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                      gutterBottom: true,
                      style: {
                        fontWeight: "600"
                      },
                      children: intl.formatMessage(Messages_Contract_Errors$DvmAdminFrontendFr.tooltipIconMessage, {
                            total: total
                          }),
                      variant: "h3"
                    }),
                item: true
              }), React.createElement(Core.Grid, {
                children: React.createElement(File_Upload_Contract_Errors_Page$DvmAdminFrontendFr.make, {}),
                item: true
              })) : null;
  } else {
    tmp = null;
  }
  return React.createElement(Core.Grid, {
              children: tmp,
              container: true,
              direction: "column",
              item: true,
              style: {
                height: "80vh",
                width: "60vw"
              }
            });
}

var Api;

var make = File_Upload_Contract_Errors_Check;

export {
  Api ,
  make ,
}
/* react Not a pure module */
