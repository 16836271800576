// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../icons/App_Icon.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../App_Typography.bs.js";

var topMenuItem = Css.css(Object.assign({}, {
          cursor: "pointer",
          display: "flex",
          alignItems: "center"
        }, {
          "&:hover": {
            backgroundColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown
          }
        }));

var topMenuItemPadding = Css.css({
      padding: "2.4rem"
    });

var topMenuItemSelected = Css.css({
      backgroundColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown
    });

var menuList = Css.css({
      backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight2,
      display: "flex",
      padding: "1.6rem 2.4rem"
    });

var menuBox = Css.css(Object.assign({}, {}, {
          "&:not(:last-child)": {
            borderRight: "1px solid " + Theme_Colors$DvmAdminFrontendFr.grayLight3 + "",
            paddingRight: "10px"
          },
          "&:not(:first-child)": {
            paddingLeft: "10px"
          }
        }));

var itemBox = Css.css({
      padding: "0.8rem 0.8rem 0.8rem 0"
    });

var item = Css.css(Object.assign({}, {
          cursor: "pointer"
        }, {
          "&:hover": {
            fontWeight: "600"
          }
        }));

var itemSelected = Css.css({
      color: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
      fontWeight: "600"
    });

var divider = Css.css({
      borderLeft: "0.1rem solid " + Theme_Colors$DvmAdminFrontendFr.grayMain + "",
      margin: "2rem 2.4rem"
    });

var paddingRight = Css.css({
      paddingRight: "1rem"
    });

var Styles = {
  topMenuItem: topMenuItem,
  topMenuItemPadding: topMenuItemPadding,
  topMenuItemSelected: topMenuItemSelected,
  menuList: menuList,
  menuBox: menuBox,
  itemBox: itemBox,
  item: item,
  itemSelected: itemSelected,
  divider: divider,
  paddingRight: paddingRight
};

function sliceIntoChunks(arr, chunkSize) {
  var res = [];
  while(arr.length > 0) {
    var chunk = arr.splice(0, chunkSize);
    res.push(chunk);
  };
  return res;
}

function App_TopMenu_Item(Props) {
  var path = Props.path;
  var route = Props.route;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return false;
      });
  var setOpenMenu = match[1];
  var openMenu = match[0];
  var anchorRef = React.useRef(null);
  var prevOpen = React.useRef(openMenu);
  React.useEffect((function () {
          if (prevOpen.current === true && openMenu === false) {
            Belt_Option.forEach(Caml_option.nullable_to_opt(anchorRef.current), (function (input) {
                    input.focus();
                  }));
          }
          prevOpen.current = openMenu;
        }), [openMenu]);
  var getSelectedClassName = function (p, r) {
    if (r.length > 1) {
      if (p.startsWith(r)) {
        return topMenuItemSelected;
      } else {
        return "";
      }
    } else if (p === r) {
      return topMenuItemSelected;
    } else {
      return "";
    }
  };
  return React.createElement("div", {
              ref: anchorRef,
              className: Css.cx([
                    topMenuItem,
                    topMenuItemPadding,
                    getSelectedClassName(path, route.path)
                  ]),
              onClick: (function (param) {
                  if (route.menu.length === 0) {
                    return RescriptReactRouter.push(route.path);
                  } else {
                    return Curry._1(setOpenMenu, (function (prev) {
                                  return !prev;
                                }));
                  }
                })
            }, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                  size: /* Large */3,
                  type_: route.icon,
                  iconClassName: paddingRight
                }), React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  children: intl.formatMessage(route.translation),
                  variant: "bodyBig"
                }), React.createElement(Core.Popper, {
                  anchorEl: anchorRef.current,
                  children: React.createElement(Core.ClickAwayListener, {
                        children: React.createElement("div", {
                              className: menuList
                            }, Belt_Array.mapWithIndex(route.menu, (function (columnIndex, column) {
                                    return React.createElement("div", {
                                                key: String(columnIndex),
                                                className: menuBox
                                              }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                    color: "secondary",
                                                    gutterBottom: true,
                                                    children: intl.formatMessage(column.translation),
                                                    variant: "h5"
                                                  }), React.createElement("div", undefined, Belt_Array.mapWithIndex(column.items, (function (index, item$1) {
                                                          return React.createElement("div", {
                                                                      key: String(index),
                                                                      className: itemBox
                                                                    }, React.createElement("div", {
                                                                          onClick: (function (param) {
                                                                              RescriptReactRouter.push(item$1.path);
                                                                            })
                                                                        }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                                              className: Css.cx([
                                                                                    path === item$1.path ? itemSelected : "",
                                                                                    item
                                                                                  ]),
                                                                              children: intl.formatMessage(item$1.translation)
                                                                            })));
                                                        }))));
                                  }))),
                        onClickAway: (function ($$event) {
                            if (!(!(anchorRef.current == null) && anchorRef.current.contains($$event.currentTarget))) {
                              return Curry._1(setOpenMenu, (function (param) {
                                            return false;
                                          }));
                            }
                            
                          })
                      }),
                  open: openMenu,
                  placement: "bottom-start",
                  style: {
                    zIndex: "1500"
                  }
                }));
}

var drawerWidth = 236;

var make = App_TopMenu_Item;

export {
  drawerWidth ,
  Styles ,
  sliceIntoChunks ,
  make ,
}
/* topMenuItem Not a pure module */
