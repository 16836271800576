// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/core/styles";

var backdropContainer = Css.css({
      height: "100vh",
      left: "0",
      position: "fixed",
      top: "0",
      width: "100vw"
    });

function backdropSpinner(theme) {
  return Css.css({
              color: theme.palette.common.black,
              left: "50%",
              position: "absolute",
              top: "50%"
            });
}

function pickSize(size) {
  if (typeof size !== "number") {
    return Caml_option.some(size._0);
  }
  switch (size) {
    case /* Small */0 :
        return "15vw";
    case /* Regular */1 :
        return ;
    case /* Large */2 :
        return "25vw";
    
  }
}

function App_Spinner(Props) {
  var type_Opt = Props.type_;
  var classNameOpt = Props.className;
  var sizeOpt = Props.size;
  var id = Props.id;
  var type_ = type_Opt !== undefined ? type_Opt : /* Standard */0;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var size = sizeOpt !== undefined ? sizeOpt : /* Regular */1;
  var theme = Styles.useTheme();
  if (typeof type_ === "number") {
    if (type_ !== 0) {
      var tmp = {
        className: backdropSpinner(theme)
      };
      var tmp$1 = pickSize(size);
      if (tmp$1 !== undefined) {
        tmp.size = Caml_option.valFromOption(tmp$1);
      }
      if (id !== undefined) {
        tmp.id = id;
      }
      return React.cloneElement(React.createElement(Core.Grid, {
                      children: React.createElement(Core.CircularProgress, tmp),
                      className: Css.cx([
                            backdropContainer,
                            className
                          ]),
                      container: true
                    }), {
                  "test-id": "diaSpinner"
                });
    }
    var tmp$2 = {};
    var tmp$3 = pickSize(size);
    if (tmp$3 !== undefined) {
      tmp$2.size = Caml_option.valFromOption(tmp$3);
    }
    if (id !== undefined) {
      tmp$2.id = id;
    }
    return React.cloneElement(React.createElement(Core.Grid, {
                    alignItems: "center",
                    children: React.createElement(Core.CircularProgress, tmp$2),
                    className: className,
                    container: true,
                    direction: "column"
                  }), {
                "test-id": "diaSpinner"
              });
  }
  var tmp$4 = {
    ref: type_._1
  };
  var tmp$5 = pickSize(size);
  if (tmp$5 !== undefined) {
    tmp$4.size = Caml_option.valFromOption(tmp$5);
  }
  if (type_._0 !== undefined) {
    tmp$4.style = Caml_option.valFromOption(type_._0);
  }
  if (id !== undefined) {
    tmp$4.id = id;
  }
  return React.cloneElement(React.createElement(Core.CircularProgress, tmp$4), {
              "test-id": "diaSpinner"
            });
}

var make = App_Spinner;

export {
  backdropContainer ,
  backdropSpinner ,
  pickSize ,
  make ,
}
/* backdropContainer Not a pure module */
