// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../common/utils/Utils_Api.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../common/utils/Utils_Form.bs.js";

var validators_discount = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: "discount",
                    value: param.discount
                  })
            };
    })
};

var validators_period = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.period);
    })
};

var validators_categoryID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(Belt_Option.getWithDefault(Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                          fieldId: "categoryId",
                          value: param.categoryID
                        }), ""));
    })
};

var validators_year = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequiredDate(param.year, undefined);
    })
};

var validators = {
  discount: validators_discount,
  period: validators_period,
  categoryID: validators_categoryID,
  year: validators_year
};

function initialFieldsStatuses(_input) {
  return {
          discount: /* Pristine */0,
          period: /* Pristine */0,
          categoryID: /* Pristine */0,
          year: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            discount: /* Pristine */0,
            period: /* Pristine */0,
            categoryID: /* Pristine */0,
            year: /* Pristine */0
          },
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.discount;
  var match_0 = match ? match._0 : Curry._1(validators.discount.validate, input);
  var match$1 = fieldsStatuses.period;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.period.validate, input);
  var match$2 = fieldsStatuses.categoryID;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.categoryID.validate, input);
  var match$3 = fieldsStatuses.year;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.year.validate, input);
  var discountResult = match_0;
  var discountResult$1;
  if (discountResult.TAG === /* Ok */0) {
    var periodResult = match_0$1;
    if (periodResult.TAG === /* Ok */0) {
      var categoryIDResult = match_0$2;
      if (categoryIDResult.TAG === /* Ok */0) {
        var yearResult = match_0$3;
        if (yearResult.TAG === /* Ok */0) {
          return {
                  TAG: /* Valid */0,
                  output: {
                    year: yearResult._0,
                    categoryID: categoryIDResult._0,
                    period: periodResult._0,
                    discount: discountResult._0
                  },
                  fieldsStatuses: {
                    discount: /* Dirty */{
                      _0: discountResult,
                      _1: /* Shown */0
                    },
                    period: /* Dirty */{
                      _0: periodResult,
                      _1: /* Shown */0
                    },
                    categoryID: /* Dirty */{
                      _0: categoryIDResult,
                      _1: /* Shown */0
                    },
                    year: /* Dirty */{
                      _0: yearResult,
                      _1: /* Shown */0
                    }
                  },
                  collectionsStatuses: undefined
                };
        }
        discountResult$1 = discountResult;
      } else {
        discountResult$1 = discountResult;
      }
    } else {
      discountResult$1 = discountResult;
    }
  } else {
    discountResult$1 = discountResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            discount: /* Dirty */{
              _0: discountResult$1,
              _1: /* Shown */0
            },
            period: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            categoryID: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            year: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurDiscountField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.discount, validators_discount, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  discount: status,
                                  period: init.period,
                                  categoryID: init.categoryID,
                                  year: init.year
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPeriodField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.period, validators_period, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  discount: init.discount,
                                  period: status,
                                  categoryID: init.categoryID,
                                  year: init.year
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCategoryIDField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.categoryID, validators_categoryID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  discount: init.discount,
                                  period: init.period,
                                  categoryID: status,
                                  year: init.year
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurYearField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.year, validators_year, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  discount: init.discount,
                                  period: init.period,
                                  categoryID: init.categoryID,
                                  year: status
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */4 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */4,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */5,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */7);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */6);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */5 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */6 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */7 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateDiscountField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.discount, state.submissionStatus, validators_discount, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            discount: status,
                                            period: init.period,
                                            categoryID: init.categoryID,
                                            year: init.year
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePeriodField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.period, state.submissionStatus, validators_period, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            discount: init.discount,
                                            period: status,
                                            categoryID: init.categoryID,
                                            year: init.year
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCategoryIDField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.categoryID, state.submissionStatus, validators_categoryID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            discount: init.discount,
                                            period: init.period,
                                            categoryID: status,
                                            year: init.year
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateYearField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.year, state.submissionStatus, validators_year, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            discount: init.discount,
                                            period: init.period,
                                            categoryID: init.categoryID,
                                            year: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */4 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                discount: /* Pristine */0,
                                period: /* Pristine */0,
                                categoryID: /* Pristine */0,
                                year: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                discount: /* Pristine */0,
                                period: /* Pristine */0,
                                categoryID: /* Pristine */0,
                                year: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */5 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */6 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateDiscount: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDiscountField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updatePeriod: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePeriodField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCategoryID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCategoryIDField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateYear: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateYearField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurDiscount: (function (param) {
              Curry._1(dispatch, /* BlurDiscountField */0);
            }),
          blurPeriod: (function (param) {
              Curry._1(dispatch, /* BlurPeriodField */1);
            }),
          blurCategoryID: (function (param) {
              Curry._1(dispatch, /* BlurCategoryIDField */2);
            }),
          blurYear: (function (param) {
              Curry._1(dispatch, /* BlurYearField */3);
            }),
          discountResult: Formality.exposeFieldResult(state.fieldsStatuses.discount),
          periodResult: Formality.exposeFieldResult(state.fieldsStatuses.period),
          categoryIDResult: Formality.exposeFieldResult(state.fieldsStatuses.categoryID),
          yearResult: Formality.exposeFieldResult(state.fieldsStatuses.year),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.discount || match.period || match.categoryID || match.year) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */4);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */5);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */6);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */6,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */7);
            })
        };
}

var FilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  year: undefined,
  categoryID: "",
  period: "",
  discount: ""
};

export {
  FilterForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
