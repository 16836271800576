// Generated by ReScript, PLEASE EDIT WITH CARE


var titleSearch = {
  id: "7eee8087-53db-4f15-bdfb-7bdc07ab8e49",
  defaultMessage: "Gérer les codes GEMO"
};

var titleEdit = {
  id: "c21fea4d-6773-4078-a304-d886d413fae5",
  defaultMessage: "Informations principales GEMO"
};

var gemo = {
  id: "84492f98-17ba-4dc1-8aae-a5b9685e10e4",
  defaultMessage: "GEMO"
};

var resultInfo = {
  id: "09561466-7e2e-4b14-8764-b6e49fe9ae99",
  defaultMessage: "codes"
};

var gemoLabel = {
  id: "7b56c0b7-03b6-4dd3-91a7-7eb418ace914",
  defaultMessage: "Libellé GEMO"
};

var editGemo = {
  id: "71959e1a-0cd4-4955-b8ed-a21ac9791805",
  defaultMessage: "Modification des informations principales GEMO"
};

var createGemo = {
  id: "99133a4f-edd6-48b8-848c-a3833732db6c",
  defaultMessage: "Création GEMO"
};

var bonusType = {
  id: "3ffbc777-2442-4067-ad66-12f748338007",
  defaultMessage: "Type d’aide"
};

var submarketLabel = {
  id: "9a92ae85-4971-457b-9cb7-23e65463a50a",
  defaultMessage: "Sous-marché"
};

var newGemo = {
  id: "40029523-2c51-4372-a180-48aa09bec33c",
  defaultMessage: "Création d’un nouveau code GEMO"
};

var actionType = {
  id: "51c5b3d8-62f3-4fea-a543-933cdc904256",
  defaultMessage: "Type d’action"
};

var backButton = {
  id: "b02fb402-d3bc-4733-81a9-60d5fb927a6a",
  defaultMessage: "Retour à la liste des codes GEMO"
};

export {
  titleSearch ,
  titleEdit ,
  gemo ,
  resultInfo ,
  gemoLabel ,
  editGemo ,
  createGemo ,
  bonusType ,
  submarketLabel ,
  newGemo ,
  actionType ,
  backButton ,
}
/* No side effect */
