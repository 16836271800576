// Generated by ReScript, PLEASE EDIT WITH CARE


var dealerRequestTitle = {
  id: "6386286b-18ee-407a-a82d-52d5413d9284",
  defaultMessage: "Demande d'aide spécifique pour la commande {id}"
};

var transformationPrice = {
  id: "877e275c-2cff-48cd-8e31-37f4267a99fc",
  defaultMessage: "Prix de transformation HT"
};

var transformationPriceInformative = {
  id: "cbf26e44-2f43-470a-a4b0-870ec7b9886d",
  defaultMessage: "Prix de transformation TTC (informatif)"
};

var requestAmount = {
  id: "a6a1a7c9-adc9-4619-8884-0d1889165089",
  defaultMessage: "Montant d'aide demandé HT"
};

var requestAmountInformative = {
  id: "5a31e8a6-03b4-4b7c-a483-6f843aa9991e",
  defaultMessage: "Montant d'aide demandé TTC (informatif)"
};

var viewDocuments = {
  id: "eca1f459-8bbc-4161-95a0-4a365a4061c8",
  defaultMessage: "Visualiser"
};

var viewDocumentsModalTitle = {
  id: "3707277c-332f-4bf9-8ec5-b1ce29ac52f7",
  defaultMessage: "Documents justicatifs de l'aide {actionId}"
};

var requestAmountPERF = {
  id: "3a49380d-572e-41d2-a483-d8ab964484f7",
  defaultMessage: "Montant HT d’aide en €"
};

var dealerRequestConfirmDelete = {
  id: "3fc50db9-1637-4c91-88b5-ca9d1be384d6",
  defaultMessage: "Êtes-vous sûr de vouloir supprimer la demande ?"
};

var modifyConfirmTitle = {
  id: "4ffc42df-a9c3-4acc-adc3-c40af91a0092",
  defaultMessage: "Confirmer les modifications"
};

var modifyConfirmInfo = {
  id: "73557766-76a0-4fe9-a268-8f1008211f46",
  defaultMessage: "Êtes-vous sûr de vouloir valider ces modifications ?"
};

var documentsTitle = {
  id: "61814903-823a-4a03-8e0c-eceef6c61ea5",
  defaultMessage: "Documents"
};

var documentTypesTitle = {
  id: "8e5b8e2a-ec55-44a8-ac25-2aba6c8caf9b",
  defaultMessage: "Justificatifs à télécharger"
};

export {
  dealerRequestTitle ,
  transformationPrice ,
  transformationPriceInformative ,
  requestAmount ,
  requestAmountInformative ,
  viewDocuments ,
  viewDocumentsModalTitle ,
  requestAmountPERF ,
  dealerRequestConfirmDelete ,
  modifyConfirmTitle ,
  modifyConfirmInfo ,
  documentsTitle ,
  documentTypesTitle ,
}
/* No side effect */
