// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../intl/Intl_Utils.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_FloatFieldFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_FloatFieldFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_AutocompleteFormInput.bs.js";

function getList(form, index, whatTable, whatGrid, budget, acquisitionOptions, row, intl) {
  var tmp;
  if (whatGrid === /* ModelVersionGrid */0 && (budget === "LCD" || budget === "PROT")) {
    var tmp$1 = {
      value: {
        TAG: /* Single */0,
        _0: {
          value: row.acquisitionType,
          onChange: Curry._2(form.updateSingleRowAcquisitionType, index, (function (param, acquisitionType) {
                  return {
                          marginRows: Belt_Array.mapWithIndex(param.marginRows, (function (indexRow, row) {
                                  if (indexRow !== index) {
                                    return row;
                                  }
                                  var newrecord = Caml_obj.obj_dup(row);
                                  newrecord.acquisitionType = acquisitionType;
                                  return newrecord;
                                }))
                        };
                }))
        }
      },
      options: {
        TAG: /* Unlabeled */0,
        _0: acquisitionOptions
      },
      onBlur: (function (param) {
          Curry._1(form.blurSingleRowAcquisitionType, index);
        }),
      className: App_Styles_Common$DvmAdminFrontendFr.TableInputs.dropdownInput,
      classes: {
        root: App_Styles_Common$DvmAdminFrontendFr.TableInputs.input
      }
    };
    var tmp$2 = Curry._1(form.singleRowAcquisitionTypeResult, index);
    if (tmp$2 !== undefined) {
      tmp$1.validationResult = Caml_option.valFromOption(tmp$2);
    }
    tmp = Caml_option.some(React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$1));
  } else {
    tmp = undefined;
  }
  var tmp$3 = {
    onChange: Curry._2(form.updateSingleRowDiscount, index, (function (param, discount) {
            return {
                    marginRows: Belt_Array.mapWithIndex(param.marginRows, (function (indexRow, row) {
                            if (indexRow !== index) {
                              return row;
                            }
                            var newrecord = Caml_obj.obj_dup(row);
                            newrecord.discount = discount;
                            return newrecord;
                          }))
                  };
          })),
    value: row.discount,
    onBlur: (function (param) {
        Curry._1(form.blurSingleRowDiscount, index);
      }),
    className: App_Styles_Common$DvmAdminFrontendFr.TableInputs.shortInput
  };
  var tmp$4 = Curry._1(form.singleRowDiscountResult, index);
  if (tmp$4 !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(tmp$4);
  }
  var tmp$5;
  if (whatGrid === /* MarginGrid */1) {
    var tmp$6 = {
      onChange: Curry._2(form.updateSingleRowMargin, index, (function (param, margin) {
              return {
                      marginRows: Belt_Array.mapWithIndex(param.marginRows, (function (indexRow, row) {
                              if (indexRow !== index) {
                                return row;
                              }
                              var newrecord = Caml_obj.obj_dup(row);
                              newrecord.margin = margin;
                              return newrecord;
                            }))
                    };
            })),
      value: row.margin,
      onBlur: (function (param) {
          Curry._1(form.blurSingleRowMargin, index);
        }),
      className: App_Styles_Common$DvmAdminFrontendFr.TableInputs.shortInput
    };
    var tmp$7 = Curry._1(form.singleRowMarginResult, index);
    if (tmp$7 !== undefined) {
      tmp$6.validationResult = Caml_option.valFromOption(tmp$7);
    }
    tmp$5 = Caml_option.some(React.createElement(App_FloatFieldFormInput$DvmAdminFrontendFr.make, tmp$6));
  } else {
    tmp$5 = undefined;
  }
  var tmp$8 = {
    value: row.startDate,
    onChange: Curry._2(form.updateSingleRowStartDate, index, (function (param, startDate) {
            return {
                    marginRows: Belt_Array.mapWithIndex(param.marginRows, (function (indexRow, row) {
                            if (indexRow !== index) {
                              return row;
                            }
                            var newrecord = Caml_obj.obj_dup(row);
                            newrecord.startDate = startDate;
                            return newrecord;
                          }))
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.TableInputs.standardInput
  };
  var tmp$9 = Curry._1(form.singleRowStartDateResult, index);
  if (tmp$9 !== undefined) {
    tmp$8.validationResult = Caml_option.valFromOption(tmp$9);
  }
  var tmp$10 = {
    value: row.endDate,
    onChange: Curry._2(form.updateSingleRowEndDate, index, (function (param, endDate) {
            return {
                    marginRows: Belt_Array.mapWithIndex(param.marginRows, (function (indexRow, row) {
                            if (indexRow !== index) {
                              return row;
                            }
                            var newrecord = Caml_obj.obj_dup(row);
                            newrecord.endDate = endDate;
                            return newrecord;
                          }))
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.TableInputs.standardInput
  };
  var tmp$11 = Curry._1(form.singleRowEndDateResult, index);
  if (tmp$11 !== undefined) {
    tmp$10.validationResult = Caml_option.valFromOption(tmp$11);
  }
  var tmp$12;
  if (whatGrid === /* ModelVersionGrid */0 && whatTable === /* Model */0) {
    var tmp$13 = {
      value: {
        TAG: /* Single */0,
        _0: {
          value: row.hasExceptions ? "true" : "false",
          onChange: (function (value) {
              var value$1 = value === "true";
              Curry._3(form.updateSingleRowHasExceptions, index, (function (param, hasExceptions) {
                      return {
                              marginRows: Belt_Array.mapWithIndex(param.marginRows, (function (indexRow, row) {
                                      if (indexRow !== index) {
                                        return row;
                                      }
                                      var newrecord = Caml_obj.obj_dup(row);
                                      newrecord.hasExceptions = hasExceptions;
                                      return newrecord;
                                    }))
                            };
                    }), value$1);
            })
        }
      },
      options: {
        TAG: /* Labeled */1,
        _0: {
          hd: {
            label: {
              TAG: /* Message */0,
              _0: Messages_Common$DvmAdminFrontendFr.yes
            },
            value: "true"
          },
          tl: {
            hd: {
              label: {
                TAG: /* Message */0,
                _0: Messages_Common$DvmAdminFrontendFr.no
              },
              value: "false"
            },
            tl: /* [] */0
          }
        }
      },
      onBlur: (function (param) {
          Curry._1(form.blurSingleRowAcquisitionType, index);
        }),
      className: App_Styles_Common$DvmAdminFrontendFr.TableInputs.dropdownInput,
      classes: {
        root: App_Styles_Common$DvmAdminFrontendFr.TableInputs.input
      }
    };
    var tmp$14 = Curry._1(form.singleRowHasExceptionsResult, index);
    if (tmp$14 !== undefined) {
      tmp$13.validationResult = Caml_option.valFromOption(tmp$14);
    }
    tmp$12 = Caml_option.some(React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$13));
  } else {
    tmp$12 = undefined;
  }
  return Belt_List.keepMap({
              hd: tmp,
              tl: {
                hd: Caml_option.some(React.createElement(App_FloatFieldFormInput$DvmAdminFrontendFr.make, tmp$3)),
                tl: {
                  hd: tmp$5,
                  tl: {
                    hd: Caml_option.some(Belt_Option.mapWithDefault(row.previousEndDate, "", (function (param) {
                                return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                              }))),
                    tl: {
                      hd: Caml_option.some(React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$8)),
                      tl: {
                        hd: Caml_option.some(React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$10)),
                        tl: {
                          hd: Caml_option.some(Belt_Option.mapWithDefault(row.followingStartDate, "", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    }))),
                          tl: {
                            hd: tmp$12,
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }, (function (x) {
                return x;
              }));
}

var Form;

var Types;

var Styles;

export {
  Form ,
  Types ,
  Styles ,
  getList ,
}
/* react Not a pure module */
