// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../intl/Intl_Utils.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../common/components/App_LabeledText.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as App_Types_Status$DvmAdminFrontendFr from "../../../common/types/App_Types_Status.bs.js";
import * as Messages_Bonuses_Manage$DvmAdminFrontendFr from "../../../intl/messages/bonuses/Messages_Bonuses_Manage.bs.js";

function Bonuses_Manage_Status(Props) {
  var rows = Props.rows;
  var manageCall = Props.manageCall;
  var intl = ReactIntl.useIntl();
  var row = Belt_List.get(rows, 0);
  if (row !== undefined) {
    return React.createElement(Core.Grid, {
                children: null,
                container: true,
                justifyContent: "space-between",
                spacing: 1
              }, React.createElement(Core.Grid, {
                    children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                          label: Messages_Bonuses_Manage$DvmAdminFrontendFr.amountFromDealer,
                          children: Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, row.actualAmount),
                          colorChild: row.dealerHasMore ? "error" : "textPrimary"
                        }),
                    item: true
                  }), React.createElement(Core.Grid, {
                    children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                          label: Messages_Bonuses_Manage$DvmAdminFrontendFr.currentStatus,
                          children: App_Types_Status$DvmAdminFrontendFr.toString(row.status)
                        }),
                    item: true
                  }), React.createElement(Core.Grid, {
                    children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                          label: Messages_Bonuses_Manage$DvmAdminFrontendFr.newStatus,
                          children: intl.formatMessage(Messages_Bonuses_Manage$DvmAdminFrontendFr.toValidateStatus)
                        }),
                    item: true
                  }), React.createElement(Core.Grid, {
                    children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                          onClick: (function (param) {
                              if (Belt_Option.isSome(row.approved)) {
                                return Curry._3(manageCall, /* ToValidate */2, undefined, undefined);
                              }
                              
                            }),
                          label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                          size: "medium"
                        }),
                    item: true
                  }));
  } else {
    return null;
  }
}

var Types;

var make = Bonuses_Manage_Status;

export {
  Types ,
  make ,
}
/* react Not a pure module */
