// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../../../common/components/icons/App_Icon.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../../../../common/components/App_Checkbox.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../../common/styles/App_Styles_Common.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Messages_Flash_Search$DvmAdminFrontendFr from "../../../../../../intl/messages/flash/Messages_Flash_Search.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Flash_Edit_Fleet_Version_Modal_Api$DvmAdminFrontendFr from "./Flash_Edit_Fleet_Version_Modal_Api.bs.js";

function Flash_Edit_Fleet_Version_Duplicate_Action(Props) {
  var flash = Props.flash;
  var setSelectedAction = Props.setSelectedAction;
  var selectedAction = Props.selectedAction;
  var duplicateActionWithClosedModels = Props.duplicateActionWithClosedModels;
  var setDuplicateActionWithClosedModels = Props.setDuplicateActionWithClosedModels;
  var actionType = Props.actionType;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* [] */0;
      });
  var setActions = match$2[1];
  React.useEffect((function () {
          Flash_Edit_Fleet_Version_Modal_Api$DvmAdminFrontendFr.getActionsOnActionType(alert, userManager, setActions, selectedRole, actionType);
        }), []);
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true,
                  spacing: 1
                }, React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      item: true
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                size: /* Large */3,
                                type_: /* Export */62
                              }),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                style: {
                                  fontWeight: "600"
                                },
                                children: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.duplicateVersions),
                                variant: "bodyBig"
                              }),
                          item: true
                        })), React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      direction: "column",
                      item: true,
                      style: {
                        width: "fit-content"
                      }
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                label: Messages_Flash_Search$DvmAdminFrontendFr.flashMarketingActionID,
                                value: {
                                  TAG: /* Single */0,
                                  _0: {
                                    value: selectedAction,
                                    onChange: (function (value) {
                                        Curry._1(setSelectedAction, (function (param) {
                                                return value;
                                              }));
                                      })
                                  }
                                },
                                options: {
                                  TAG: /* Labeled */1,
                                  _0: Belt_List.map(Belt_List.keep(match$2[0], (function (x) {
                                              return x.paramOne !== flash.actionId;
                                            })), (function (x) {
                                          return {
                                                  label: {
                                                    TAG: /* String */3,
                                                    _0: "" + x.paramOne + " (" + Belt_Option.getWithDefault(x.paramTwo, "") + ")"
                                                  },
                                                  value: x.paramOne
                                                };
                                        }))
                                },
                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                              }),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(Core.FormControlLabel, {
                                classes: {
                                  label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                },
                                control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                      checked: duplicateActionWithClosedModels,
                                      onClick: (function ($$event) {
                                          Curry._1(setDuplicateActionWithClosedModels, (function (param) {
                                                  return $$event.target.checked;
                                                }));
                                        })
                                    }),
                                label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.includeClosedCars)
                              }),
                          item: true,
                          style: {
                            whiteSpace: "nowrap",
                            width: "1px"
                          }
                        }))));
}

var InputStyles;

var Api;

var make = Flash_Edit_Fleet_Version_Duplicate_Action;

export {
  InputStyles ,
  Api ,
  make ,
}
/* react Not a pure module */
