// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../../common/utils/Utils_Date.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../common/utils/Utils_Form.bs.js";
import * as ModelVersion_Grid_Types$DvmAdminFrontendFr from "./ModelVersion_Grid_Types.bs.js";

var validators_acquisitionType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var budget = param.budget;
      return {
              TAG: /* Ok */0,
              _0: budget === "LCD" || budget === "PROT" ? Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                      fieldId: ModelVersion_Grid_Types$DvmAdminFrontendFr.Fields.acquisitionType,
                      value: param.acquisitionType
                    }) : undefined
            };
    })
};

var validators_year = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.year, undefined), (function (date) {
                    var date$1 = Utils_Date$DvmAdminFrontendFr.toYearString(date);
                    if (date$1 === undefined) {
                      return ;
                    }
                    if (date$1.length === 0) {
                      return ;
                    }
                    var start = "" + ModelVersion_Grid_Types$DvmAdminFrontendFr.FilterFields.startYear + "=%5B%5C," + date$1 + "%5D";
                    var end = "" + ModelVersion_Grid_Types$DvmAdminFrontendFr.FilterFields.endYear + "=%5B" + date$1 + "%5C,%5D;" + ModelVersion_Grid_Types$DvmAdminFrontendFr.FilterFields.endYear + "=NULL";
                    return "" + start + ";" + end + "";
                  }));
    })
};

var validators_discount = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: ModelVersion_Grid_Types$DvmAdminFrontendFr.Fields.discount,
                    value: param.discount
                  })
            };
    })
};

var validators_version = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: ModelVersion_Grid_Types$DvmAdminFrontendFr.FilterFields.version,
                    value: param.version
                  })
            };
    })
};

var validators_model = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: ModelVersion_Grid_Types$DvmAdminFrontendFr.FilterFields.model,
                    value: param.model
                  })
            };
    })
};

var validators_categoryLabel = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(undefined, {
                    fieldId: ModelVersion_Grid_Types$DvmAdminFrontendFr.FilterFields.categoryLabel,
                    value: param.categoryLabel
                  })
            };
    })
};

var validators_contractID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(Belt_Option.mapWithDefault(Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                          fieldId: ModelVersion_Grid_Types$DvmAdminFrontendFr.FilterFields.contractID,
                          value: param.contractID
                        }), "", (function (x) {
                        return x;
                      })));
    })
};

var validators_budget = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.budget);
    })
};

var validators = {
  acquisitionType: validators_acquisitionType,
  year: validators_year,
  discount: validators_discount,
  version: validators_version,
  model: validators_model,
  categoryLabel: validators_categoryLabel,
  contractID: validators_contractID,
  budget: validators_budget
};

function initialFieldsStatuses(_input) {
  return {
          acquisitionType: /* Pristine */0,
          year: /* Pristine */0,
          discount: /* Pristine */0,
          version: /* Pristine */0,
          model: /* Pristine */0,
          categoryLabel: /* Pristine */0,
          contractID: /* Pristine */0,
          budget: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.acquisitionType;
  var match_0 = match ? match._0 : Curry._1(validators.acquisitionType.validate, input);
  var match$1 = fieldsStatuses.year;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.year.validate, input);
  var match$2 = fieldsStatuses.discount;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.discount.validate, input);
  var match$3 = fieldsStatuses.version;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.version.validate, input);
  var match$4 = fieldsStatuses.model;
  var match_0$4 = match$4 ? match$4._0 : Curry._1(validators.model.validate, input);
  var match$5 = fieldsStatuses.categoryLabel;
  var match_0$5 = match$5 ? match$5._0 : Curry._1(validators.categoryLabel.validate, input);
  var match$6 = fieldsStatuses.contractID;
  var match_0$6 = match$6 ? match$6._0 : Curry._1(validators.contractID.validate, input);
  var match$7 = fieldsStatuses.budget;
  var match_0$7 = match$7 ? match$7._0 : Curry._1(validators.budget.validate, input);
  var acquisitionTypeResult = match_0;
  var acquisitionTypeResult$1;
  if (acquisitionTypeResult.TAG === /* Ok */0) {
    var yearResult = match_0$1;
    if (yearResult.TAG === /* Ok */0) {
      var discountResult = match_0$2;
      if (discountResult.TAG === /* Ok */0) {
        var versionResult = match_0$3;
        if (versionResult.TAG === /* Ok */0) {
          var modelResult = match_0$4;
          if (modelResult.TAG === /* Ok */0) {
            var categoryLabelResult = match_0$5;
            if (categoryLabelResult.TAG === /* Ok */0) {
              var contractIDResult = match_0$6;
              if (contractIDResult.TAG === /* Ok */0) {
                var budgetResult = match_0$7;
                if (budgetResult.TAG === /* Ok */0) {
                  return {
                          TAG: /* Valid */0,
                          output: {
                            budget: budgetResult._0,
                            contractID: contractIDResult._0,
                            categoryLabel: categoryLabelResult._0,
                            model: modelResult._0,
                            version: versionResult._0,
                            discount: discountResult._0,
                            year: yearResult._0,
                            acquisitionType: acquisitionTypeResult._0
                          },
                          fieldsStatuses: {
                            acquisitionType: /* Dirty */{
                              _0: acquisitionTypeResult,
                              _1: /* Shown */0
                            },
                            year: /* Dirty */{
                              _0: yearResult,
                              _1: /* Shown */0
                            },
                            discount: /* Dirty */{
                              _0: discountResult,
                              _1: /* Shown */0
                            },
                            version: /* Dirty */{
                              _0: versionResult,
                              _1: /* Shown */0
                            },
                            model: /* Dirty */{
                              _0: modelResult,
                              _1: /* Shown */0
                            },
                            categoryLabel: /* Dirty */{
                              _0: categoryLabelResult,
                              _1: /* Shown */0
                            },
                            contractID: /* Dirty */{
                              _0: contractIDResult,
                              _1: /* Shown */0
                            },
                            budget: /* Dirty */{
                              _0: budgetResult,
                              _1: /* Shown */0
                            }
                          },
                          collectionsStatuses: undefined
                        };
                }
                acquisitionTypeResult$1 = acquisitionTypeResult;
              } else {
                acquisitionTypeResult$1 = acquisitionTypeResult;
              }
            } else {
              acquisitionTypeResult$1 = acquisitionTypeResult;
            }
          } else {
            acquisitionTypeResult$1 = acquisitionTypeResult;
          }
        } else {
          acquisitionTypeResult$1 = acquisitionTypeResult;
        }
      } else {
        acquisitionTypeResult$1 = acquisitionTypeResult;
      }
    } else {
      acquisitionTypeResult$1 = acquisitionTypeResult;
    }
  } else {
    acquisitionTypeResult$1 = acquisitionTypeResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            acquisitionType: /* Dirty */{
              _0: acquisitionTypeResult$1,
              _1: /* Shown */0
            },
            year: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            discount: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            version: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            model: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            categoryLabel: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            contractID: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            budget: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurAcquisitionTypeField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.acquisitionType, validators_acquisitionType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  acquisitionType: status,
                                  year: init.year,
                                  discount: init.discount,
                                  version: init.version,
                                  model: init.model,
                                  categoryLabel: init.categoryLabel,
                                  contractID: init.contractID,
                                  budget: init.budget
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurYearField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.year, validators_year, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  acquisitionType: init.acquisitionType,
                                  year: status,
                                  discount: init.discount,
                                  version: init.version,
                                  model: init.model,
                                  categoryLabel: init.categoryLabel,
                                  contractID: init.contractID,
                                  budget: init.budget
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDiscountField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.discount, validators_discount, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  acquisitionType: init.acquisitionType,
                                  year: init.year,
                                  discount: status,
                                  version: init.version,
                                  model: init.model,
                                  categoryLabel: init.categoryLabel,
                                  contractID: init.contractID,
                                  budget: init.budget
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.version, validators_version, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  acquisitionType: init.acquisitionType,
                                  year: init.year,
                                  discount: init.discount,
                                  version: status,
                                  model: init.model,
                                  categoryLabel: init.categoryLabel,
                                  contractID: init.contractID,
                                  budget: init.budget
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurModelField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.model, validators_model, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  acquisitionType: init.acquisitionType,
                                  year: init.year,
                                  discount: init.discount,
                                  version: init.version,
                                  model: status,
                                  categoryLabel: init.categoryLabel,
                                  contractID: init.contractID,
                                  budget: init.budget
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCategoryLabelField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.categoryLabel, validators_categoryLabel, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  acquisitionType: init.acquisitionType,
                                  year: init.year,
                                  discount: init.discount,
                                  version: init.version,
                                  model: init.model,
                                  categoryLabel: status,
                                  contractID: init.contractID,
                                  budget: init.budget
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurContractIDField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.contractID, validators_contractID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  acquisitionType: init.acquisitionType,
                                  year: init.year,
                                  discount: init.discount,
                                  version: init.version,
                                  model: init.model,
                                  categoryLabel: init.categoryLabel,
                                  contractID: status,
                                  budget: init.budget
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBudgetField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.budget, validators_budget, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  acquisitionType: init.acquisitionType,
                                  year: init.year,
                                  discount: init.discount,
                                  version: init.version,
                                  model: init.model,
                                  categoryLabel: init.categoryLabel,
                                  contractID: init.contractID,
                                  budget: status
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */8 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */8,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */9,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */11);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */10);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */9 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */10 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */11 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateAcquisitionTypeField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.acquisitionType, state.submissionStatus, validators_acquisitionType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            acquisitionType: status,
                                            year: init.year,
                                            discount: init.discount,
                                            version: init.version,
                                            model: init.model,
                                            categoryLabel: init.categoryLabel,
                                            contractID: init.contractID,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateYearField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.year, state.submissionStatus, validators_year, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            acquisitionType: init.acquisitionType,
                                            year: status,
                                            discount: init.discount,
                                            version: init.version,
                                            model: init.model,
                                            categoryLabel: init.categoryLabel,
                                            contractID: init.contractID,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDiscountField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.discount, state.submissionStatus, validators_discount, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            acquisitionType: init.acquisitionType,
                                            year: init.year,
                                            discount: status,
                                            version: init.version,
                                            model: init.model,
                                            categoryLabel: init.categoryLabel,
                                            contractID: init.contractID,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.version, state.submissionStatus, validators_version, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            acquisitionType: init.acquisitionType,
                                            year: init.year,
                                            discount: init.discount,
                                            version: status,
                                            model: init.model,
                                            categoryLabel: init.categoryLabel,
                                            contractID: init.contractID,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateModelField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.model, state.submissionStatus, validators_model, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            acquisitionType: init.acquisitionType,
                                            year: init.year,
                                            discount: init.discount,
                                            version: init.version,
                                            model: status,
                                            categoryLabel: init.categoryLabel,
                                            contractID: init.contractID,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCategoryLabelField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.categoryLabel, state.submissionStatus, validators_categoryLabel, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            acquisitionType: init.acquisitionType,
                                            year: init.year,
                                            discount: init.discount,
                                            version: init.version,
                                            model: init.model,
                                            categoryLabel: status,
                                            contractID: init.contractID,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateContractIDField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.contractID, state.submissionStatus, validators_contractID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            acquisitionType: init.acquisitionType,
                                            year: init.year,
                                            discount: init.discount,
                                            version: init.version,
                                            model: init.model,
                                            categoryLabel: init.categoryLabel,
                                            contractID: status,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBudgetField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$7, state.fieldsStatuses.budget, state.submissionStatus, validators_budget, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            acquisitionType: init.acquisitionType,
                                            year: init.year,
                                            discount: init.discount,
                                            version: init.version,
                                            model: init.model,
                                            categoryLabel: init.categoryLabel,
                                            contractID: init.contractID,
                                            budget: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */8 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */9 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */10 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateAcquisitionType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAcquisitionTypeField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateYear: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateYearField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDiscount: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDiscountField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVersion: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateModel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCategoryLabel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCategoryLabelField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateContractID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateContractIDField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBudget: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBudgetField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurAcquisitionType: (function (param) {
              Curry._1(dispatch, /* BlurAcquisitionTypeField */0);
            }),
          blurYear: (function (param) {
              Curry._1(dispatch, /* BlurYearField */1);
            }),
          blurDiscount: (function (param) {
              Curry._1(dispatch, /* BlurDiscountField */2);
            }),
          blurVersion: (function (param) {
              Curry._1(dispatch, /* BlurVersionField */3);
            }),
          blurModel: (function (param) {
              Curry._1(dispatch, /* BlurModelField */4);
            }),
          blurCategoryLabel: (function (param) {
              Curry._1(dispatch, /* BlurCategoryLabelField */5);
            }),
          blurContractID: (function (param) {
              Curry._1(dispatch, /* BlurContractIDField */6);
            }),
          blurBudget: (function (param) {
              Curry._1(dispatch, /* BlurBudgetField */7);
            }),
          acquisitionTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.acquisitionType),
          yearResult: Formality.exposeFieldResult(state.fieldsStatuses.year),
          discountResult: Formality.exposeFieldResult(state.fieldsStatuses.discount),
          versionResult: Formality.exposeFieldResult(state.fieldsStatuses.version),
          modelResult: Formality.exposeFieldResult(state.fieldsStatuses.model),
          categoryLabelResult: Formality.exposeFieldResult(state.fieldsStatuses.categoryLabel),
          contractIDResult: Formality.exposeFieldResult(state.fieldsStatuses.contractID),
          budgetResult: Formality.exposeFieldResult(state.fieldsStatuses.budget),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.acquisitionType || match.year || match.discount || match.version || match.model || match.categoryLabel || match.contractID || match.budget) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */8);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */9);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */10);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */10,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */11);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  budget: "",
  contractID: "",
  categoryLabel: "",
  model: "",
  version: "",
  discount: "",
  year: undefined,
  acquisitionType: ""
};

export {
  TableFilterForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
