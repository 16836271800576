// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as App_Spinner$DvmAdminFrontendFr from "../../../../common/components/App_Spinner.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Grid_Edit_Api$DvmAdminFrontendFr from "../Grid_Edit_Api.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as Grid_Edit_New_Row_Api$DvmAdminFrontendFr from "./Grid_Edit_New_Row_Api.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as App_DoubleSelectFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_DoubleSelectFormInput.bs.js";

function Grid_Edit_New_Row_Inputs_Version(Props) {
  var whatGrid = Props.whatGrid;
  var form = Props.form;
  var budgetOrID = Props.budgetOrID;
  var modelVersionsList = Props.modelVersionsList;
  var operation = Props.operation;
  var wrapWithClosedModelsCheckboxElement = Props.wrapWithClosedModelsCheckboxElement;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelsWithCategories = match$2[1];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setCategoriesFiltered = match$3[1];
  var categoriesFiltered = match$3[0];
  React.useEffect((function () {
          Grid_Edit_Api$DvmAdminFrontendFr.getModelsWithCategories(userManager, setModelsWithCategories, selectedRole);
        }), []);
  React.useEffect((function () {
          Grid_Edit_New_Row_Api$DvmAdminFrontendFr.fetchCategories(setCategoriesFiltered, userManager, form.input.model, selectedRole);
        }), [form.input.model]);
  var tmp;
  if (operation !== /* AddCategory */2) {
    var tmp$1 = {
      labelParent: Messages_Common$DvmAdminFrontendFr.model,
      labelChild: Messages_Common$DvmAdminFrontendFr.version,
      valueParent: {
        TAG: /* Single */0,
        _0: {
          value: form.input.model,
          onChange: Curry._1(form.updateModel, (function (input, model) {
                  return {
                          model: model,
                          version: input.version,
                          categoryCode: input.categoryCode,
                          discount: input.discount,
                          margin: input.margin,
                          startDate: input.startDate,
                          endDate: input.endDate,
                          acquisitionType: input.acquisitionType,
                          hasExceptions: input.hasExceptions,
                          categoryStartDate: input.categoryStartDate
                        };
                }))
        }
      },
      valueChild: {
        TAG: /* Single */0,
        _0: {
          value: form.input.version,
          onChange: Curry._1(form.updateVersion, (function (input, version) {
                  return {
                          model: input.model,
                          version: version,
                          categoryCode: input.categoryCode,
                          discount: input.discount,
                          margin: input.margin,
                          startDate: input.startDate,
                          endDate: input.endDate,
                          acquisitionType: input.acquisitionType,
                          hasExceptions: input.hasExceptions,
                          categoryStartDate: input.categoryStartDate
                        };
                }))
        }
      },
      options: modelVersionsList,
      classNameParent: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
      classNameChild: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
      direction: "column"
    };
    if (form.modelResult !== undefined) {
      tmp$1.validationResultParent = Caml_option.valFromOption(form.modelResult);
    }
    if (form.versionResult !== undefined) {
      tmp$1.validationResultChild = Caml_option.valFromOption(form.versionResult);
    }
    tmp = Curry._1(wrapWithClosedModelsCheckboxElement, React.createElement(App_DoubleSelectFormInput$DvmAdminFrontendFr.make, tmp$1));
  } else {
    var tmp$2 = {
      label: Messages_Common$DvmAdminFrontendFr.model,
      value: {
        TAG: /* Single */0,
        _0: {
          value: form.input.model,
          onChange: Curry._1(form.updateModel, (function (input, model) {
                  return {
                          model: model,
                          version: input.version,
                          categoryCode: input.categoryCode,
                          discount: input.discount,
                          margin: input.margin,
                          startDate: input.startDate,
                          endDate: input.endDate,
                          acquisitionType: input.acquisitionType,
                          hasExceptions: input.hasExceptions,
                          categoryStartDate: input.categoryStartDate
                        };
                }))
        }
      },
      options: {
        TAG: /* Unlabeled */0,
        _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$2[0], /* [] */0)
      },
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
    };
    if (form.modelResult !== undefined) {
      tmp$2.validationResult = Caml_option.valFromOption(form.modelResult);
    }
    var result = App_Types_Result$DvmAdminFrontendFr.map(categoriesFiltered, (function (x) {
            return Belt_List.map(Belt_List.keep(x.content, (function (x) {
                              return x.categoryCode !== form.input.model;
                            })), (function (x) {
                          return {
                                  label: {
                                    TAG: /* String */3,
                                    _0: "" + x.categoryCode + " (" + intl.formatMessage(Messages_Common$DvmAdminFrontendFr.startDate) + ": " + Belt_Option.mapWithDefault(x.startDate, "", (function (param) {
                                            return Intl_Utils$DvmAdminFrontendFr.formatDateShort(intl, param);
                                          })) + ")"
                                  },
                                  value: x.categoryCode + ":::" + Belt_Option.mapWithDefault(x.startDate, "", (function (x) {
                                          return x.toISOString();
                                        }))
                                };
                        }));
          }));
    var tmp$3;
    tmp$3 = typeof result === "number" || result.TAG !== /* Ok */0 ? /* [] */0 : result._0;
    var tmp$4 = {
      label: Messages_Common$DvmAdminFrontendFr.category,
      value: {
        TAG: /* Single */0,
        _0: {
          value: form.input.categoryCode + ":::" + form.input.categoryStartDate,
          onChange: (function (value) {
              var codeAndDate = value.split(":::");
              var code = Belt_Option.getWithDefault(Belt_Array.get(codeAndDate, 0), "");
              var date = Belt_Option.getWithDefault(Belt_Array.get(codeAndDate, 1), "");
              Curry._2(form.updateCategoryCode, (function (input, categoryCode) {
                      return {
                              model: input.model,
                              version: input.version,
                              categoryCode: categoryCode,
                              discount: input.discount,
                              margin: input.margin,
                              startDate: input.startDate,
                              endDate: input.endDate,
                              acquisitionType: input.acquisitionType,
                              hasExceptions: input.hasExceptions,
                              categoryStartDate: input.categoryStartDate
                            };
                    }), code);
              Curry._2(form.updateCategoryStartDate, (function (input, categoryStartDate) {
                      return {
                              model: input.model,
                              version: input.version,
                              categoryCode: input.categoryCode,
                              discount: input.discount,
                              margin: input.margin,
                              startDate: input.startDate,
                              endDate: input.endDate,
                              acquisitionType: input.acquisitionType,
                              hasExceptions: input.hasExceptions,
                              categoryStartDate: categoryStartDate
                            };
                    }), date);
            })
        }
      },
      options: {
        TAG: /* Labeled */1,
        _0: tmp$3
      },
      disabled: form.input.model === "",
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
      loading: App_Types_Result$DvmAdminFrontendFr.isPending(categoriesFiltered),
      popupIcon: App_Types_Result$DvmAdminFrontendFr.isPending(categoriesFiltered) ? React.createElement(App_Spinner$DvmAdminFrontendFr.make, {
              size: /* Fixed */{
                _0: "20px"
              }
            }) : React.createElement(App_Icon$DvmAdminFrontendFr.make, {
              size: /* Regular */2,
              type_: /* PadDown */11
            })
    };
    if (form.categoryCodeResult !== undefined) {
      tmp$4.validationResult = Caml_option.valFromOption(form.categoryCodeResult);
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
              children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$2),
              item: true
            }), React.createElement(Core.Grid, {
              children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$4),
              item: true
            }));
  }
  return React.createElement(React.Fragment, undefined, whatGrid === /* MarginGrid */1 ? React.createElement(Core.Grid, {
                    children: "" + intl.formatMessage(Messages_Common$DvmAdminFrontendFr.budget) + ": " + budgetOrID + "",
                    item: true
                  }) : null, tmp);
}

var Types;

var Form;

var InputStyles;

var make = Grid_Edit_New_Row_Inputs_Version;

export {
  Types ,
  Form ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
