// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Core from "@material-ui/core";
import * as App_Table$DvmAdminFrontendFr from "../../../../common/components/table/App_Table.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Head.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Category_Types$DvmAdminFrontendFr from "../../Category_Types.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as Messages_Category$DvmAdminFrontendFr from "../../../../intl/messages/category/Messages_Category.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Pagination.bs.js";
import * as Category_Edit_VersionsSelection_Row$DvmAdminFrontendFr from "./Category_Edit_VersionsSelection_Row.bs.js";
import * as Category_Edit_VersionsSelection_Collection_Form$DvmAdminFrontendFr from "./Category_Edit_VersionsSelection_Collection_Form.bs.js";
import * as Category_Edit_VersionsSelection_RefreshCollection$DvmAdminFrontendFr from "./Category_Edit_VersionsSelection_RefreshCollection.bs.js";

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Category_Types$DvmAdminFrontendFr.Fields.version, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.version);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Category_Types$DvmAdminFrontendFr.Fields.versionName, undefined, undefined, undefined, undefined, undefined, Messages_Category$DvmAdminFrontendFr.versionName),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell("startDate", undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.startDate),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell("endDate", undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.endDate),
      tl: /* [] */0
    }
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function Category_Edit_VersionsSelection_Modal_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var takenVersionsList = Props.takenVersionsList;
  var category = Props.category;
  var saveVersions = Props.saveVersions;
  var fetchData = Props.fetchData;
  var formValid = Props.formValid;
  var setFormValid = Props.setFormValid;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        
      });
  var setSelectedVersionsMap = match[1];
  var selectedVersionsMap = match[0];
  var saveGrids = function (param) {
    Curry._1(saveVersions, Belt_List.map(Belt_List.fromArray(Belt_Array.keep(Belt_MapString.valuesToArray(selectedVersionsMap), (function (x) {
                        return x.selected;
                      }))), (function (x) {
                return {
                        version: x.versionID,
                        startDate: x.startDate,
                        endDate: x.endDate
                      };
              })));
  };
  var form = Category_Edit_VersionsSelection_Collection_Form$DvmAdminFrontendFr.VersionsForm.useForm(Category_Edit_VersionsSelection_Collection_Form$DvmAdminFrontendFr.initialInput, {
        categoryStartDate: category.startDate,
        categoryEndDate: category.endDate,
        takenVersionsList: takenVersionsList
      }, (function (param, param$1) {
          if (formValid) {
            saveGrids(undefined);
          }
          Curry._1(param$1.notifyOnSuccess, undefined);
        }));
  var updateSelectedRows = function (version, action) {
    Curry._1(setSelectedVersionsMap, (function (selectedVersionsMap) {
            if (action === "remove") {
              return Belt_MapString.remove(selectedVersionsMap, version.versionID);
            } else {
              return Belt_MapString.set(selectedVersionsMap, version.versionID, version);
            }
          }));
  };
  var convertTable = function (param) {
    return App_Types_Result$DvmAdminFrontendFr.map(tablePage, (function (param) {
                  return {
                          content: Belt_List.mapWithIndex(param.content, (function (index, param) {
                                  return Category_Edit_VersionsSelection_Row$DvmAdminFrontendFr.makeRow(form, index, takenVersionsList, intl, updateSelectedRows);
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var resetForm = function (param) {
    Curry._1(form.reset, undefined);
    if (typeof tablePage !== "number" && tablePage.TAG === /* Ok */0) {
      Belt_List.map(tablePage._0.content, (function (x) {
              var fromMap = Belt_MapString.get(selectedVersionsMap, x.versionCode);
              Curry._1(form.addVersion, {
                    selected: Belt_Option.mapWithDefault(fromMap, false, (function (x) {
                            return x.selected;
                          })),
                    versionID: x.versionCode,
                    versionLabel: Belt_Option.getWithDefault(x.versionLabel, ""),
                    startDate: Belt_Option.mapWithDefault(fromMap, undefined, (function (x) {
                            return x.startDate;
                          })),
                    startDateAsCategory: Belt_Option.mapWithDefault(fromMap, true, (function (x) {
                            return x.startDateAsCategory;
                          })),
                    endDate: Belt_Option.mapWithDefault(fromMap, undefined, (function (x) {
                            return x.endDate;
                          })),
                    endDateAsCategory: Belt_Option.mapWithDefault(fromMap, true, (function (x) {
                            return x.endDateAsCategory;
                          }))
                  });
            }));
    }
    
  };
  React.useEffect((function () {
          var mapped = Belt_List.toArray(Belt_List.keepMap(Belt_List.map(category.versions, (function (x) {
                          return [
                                  x.version,
                                  {
                                    selected: true,
                                    versionID: x.version,
                                    versionLabel: "",
                                    startDate: x.startDate,
                                    startDateAsCategory: Belt_Option.isNone(x.startDate),
                                    endDate: x.endDate,
                                    endDateAsCategory: Belt_Option.isNone(x.endDate)
                                  }
                                ];
                        })), (function (x) {
                      return x;
                    })));
          Curry._1(setSelectedVersionsMap, (function (selectedVersionsMap) {
                  return Belt_MapString.mergeMany(selectedVersionsMap, mapped);
                }));
        }), []);
  React.useEffect((function () {
          resetForm(undefined);
        }), [tablePage]);
  React.useEffect((function () {
          Category_Edit_VersionsSelection_RefreshCollection$DvmAdminFrontendFr.refreshVersions(form, selectedVersionsMap);
        }), [selectedVersionsMap]);
  React.useEffect((function () {
          Curry._1(setFormValid, (function (param) {
                  return Curry._1(form.valid, undefined);
                }));
        }), [form]);
  var handleChangePage = function (page) {
    Curry._1(fetchData, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._1(fetchData, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var tmp = {
    onClick: (function (param) {
        Curry._1(form.submit, undefined);
      }),
    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
    size: "medium"
  };
  var tmp$1 = !Curry._1(form.valid, undefined) && App_Types_Result$DvmAdminFrontendFr.isSuccess(tablePage) ? ({
        border: "2px red solid"
      }) : undefined;
  if (tmp$1 !== undefined) {
    tmp.style = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, {
                  headers: fetchRequest.headers,
                  tablePage: convertTable(undefined),
                  paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(/* BOTTOM */1, 10, handleChangePage, handleRowsPerPageChange, undefined),
                  rowButton: {
                    TAG: /* Left */0,
                    _0: /* EmptyCell */0
                  },
                  height: "70vh",
                  disablePaginator: !Curry._1(form.valid, undefined)
                }), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  item: true,
                  justifyContent: "flex-end",
                  spacing: 2,
                  wrap: "nowrap"
                }, React.createElement(Core.Grid, {
                      alignItems: "flex-end",
                      children: null,
                      container: true,
                      direction: "column",
                      item: true
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                style: {
                                  fontWeight: "600"
                                },
                                children: intl.formatMessage(Messages_Category$DvmAdminFrontendFr.versionsToSave, {
                                      count: Belt_MapString.toArray(selectedVersionsMap).length
                                    }),
                                variant: "body"
                              }),
                          item: true
                        }), !Curry._1(form.valid, undefined) && App_Types_Result$DvmAdminFrontendFr.isSuccess(tablePage) ? React.createElement(Core.Grid, {
                            children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                  color: "error",
                                  style: {
                                    fontWeight: "600"
                                  },
                                  children: intl.formatMessage(Messages_Category$DvmAdminFrontendFr.pageHasErrors),
                                  variant: "body"
                                }),
                            item: true
                          }) : null), React.createElement(Core.Grid, {
                      children: React.createElement(App_Button$DvmAdminFrontendFr.make, tmp),
                      item: true
                    })));
}

var Form;

var Row;

var RefreshCollection;

var make = Category_Edit_VersionsSelection_Modal_Table;

export {
  Form ,
  Row ,
  RefreshCollection ,
  headersConfiguration ,
  make ,
}
/* headersConfiguration Not a pure module */
