// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../intl/Intl_Utils.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as Messages_ModelVersion$DvmAdminFrontendFr from "../../../intl/messages/modelVersion/Messages_ModelVersion.bs.js";

function isConflictSameAsForm(quota, form) {
  if (Belt_Option.getWithDefault(quota.orderType, "") === form.input.orderType && Belt_Option.getWithDefault(quota.dealerId, "") === form.input.dealerId && Belt_Option.getWithDefault(quota.modelId, "") === form.input.modelId && Belt_Option.getWithDefault(quota.phase, "") === form.input.phase) {
    return Belt_Option.getWithDefault(quota.engineType, "") === form.input.engineType;
  } else {
    return false;
  }
}

function makeStringFromConflict(quota, intl) {
  return Belt_Array.joinWith([
              intl.formatMessage(Messages_Common$DvmAdminFrontendFr.orderType) + ": " + Belt_Option.getWithDefault(quota.orderType, ""),
              intl.formatMessage(Messages_Common$DvmAdminFrontendFr.dealerID) + ": " + Belt_Option.getWithDefault(quota.dealerId, ""),
              intl.formatMessage(Messages_Common$DvmAdminFrontendFr.model) + ": " + Belt_Option.getWithDefault(quota.modelId, "-"),
              intl.formatMessage(Messages_Common$DvmAdminFrontendFr.phase) + ": " + Belt_Option.getWithDefault(quota.phase, "-"),
              intl.formatMessage(Messages_ModelVersion$DvmAdminFrontendFr.motorization) + ": " + Belt_Option.getWithDefault(quota.engineType, "-"),
              intl.formatMessage(Messages_Common$DvmAdminFrontendFr.startDate) + ": " + Belt_Option.mapWithDefault(quota.startDate, "-", (function (param) {
                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                    })),
              intl.formatMessage(Messages_Common$DvmAdminFrontendFr.endDate) + ": " + Belt_Option.mapWithDefault(quota.endDate, "-", (function (param) {
                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                    }))
            ], " | ", (function (x) {
                return x;
              }));
}

function conflictsClean(quotas, form, operation) {
  if (operation) {
    return Belt_List.keep(quotas, (function (x) {
                  return !isConflictSameAsForm(x, form);
                }));
  } else {
    return quotas;
  }
}

function isConflictSameAsFormOutput(quota, output) {
  if (Belt_Option.getWithDefault(quota.orderType, "") === output.orderType && Belt_Option.getWithDefault(quota.dealerId, "") === output.dealerId && Belt_Option.getWithDefault(quota.modelId, "") === output.modelId && Belt_Option.getWithDefault(quota.phase, "") === output.phase) {
    return Belt_Option.getWithDefault(quota.engineType, "") === output.engineType;
  } else {
    return false;
  }
}

function conflictsCleanOutput(quotas, output, operation) {
  if (operation) {
    return Belt_List.keep(quotas, (function (x) {
                  return !isConflictSameAsFormOutput(x, output);
                }));
  } else {
    return quotas;
  }
}

function conflictsHasSelfOutput(quotas, output) {
  return Belt_List.keep(quotas, (function (x) {
                return isConflictSameAsFormOutput(x, output);
              }));
}

export {
  isConflictSameAsForm ,
  makeStringFromConflict ,
  conflictsClean ,
  isConflictSameAsFormOutput ,
  conflictsCleanOutput ,
  conflictsHasSelfOutput ,
}
/* No side effect */
