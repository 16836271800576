// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function usedActionFromStr(action) {
  return Belt_Option.mapWithDefault(action, "other", (function (x) {
                var match = x.toLowerCase();
                switch (match) {
                  case "budget" :
                      return "budget";
                  case "perf" :
                      return "perf";
                  case "repr" :
                      return "repr";
                  case "stoc" :
                      return "stoc";
                  default:
                    return "nonfleet";
                }
              }));
}

var maxAmount = 20000.0;

export {
  usedActionFromStr ,
  maxAmount ,
}
/* No side effect */
