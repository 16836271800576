// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as App_DatesEdit_Utils$DvmAdminFrontendFr from "../../../../common/components/dates/App_DatesEdit_Utils.bs.js";
import * as Messages_Contract_Create$DvmAdminFrontendFr from "../../../../intl/messages/contract/Messages_Contract_Create.bs.js";
import * as Category_Edit_VersionsSelection_Utils$DvmAdminFrontendFr from "./Category_Edit_VersionsSelection_Utils.bs.js";

var validators = {
  versions: {
    collection: (function (param, _metadata) {
        if (param.versions.length !== 0) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Messages_Contract_Create$DvmAdminFrontendFr.noAttachment
                };
        }
      }),
    fields: {
      endDateAsCategory: undefined,
      endDate: {
        strategy: /* OnFirstChange */1,
        validate: (function (param, at, param$1) {
            var categoryEndDate = param$1.categoryEndDate;
            var categoryStartDate = param$1.categoryStartDate;
            var endDateFields = Belt_Option.map(Belt_Array.get(param.versions, at), (function (param) {
                    return [
                            param.selected,
                            param.versionID,
                            param.startDate,
                            param.endDate,
                            param.startDateAsCategory,
                            param.endDateAsCategory
                          ];
                  }));
            if (endDateFields === undefined) {
              return {
                      TAG: /* Error */1,
                      _0: Messages_Contract_Create$DvmAdminFrontendFr.noAttachment
                    };
            }
            if (endDateFields[0] !== true) {
              return {
                      TAG: /* Ok */0,
                      _0: undefined
                    };
            }
            var startDate = endDateFields[2];
            var firstCheck = App_DatesEdit_Utils$DvmAdminFrontendFr.validateRequiredDateCompare(startDate, endDateFields[3], categoryStartDate, categoryEndDate, "larger", endDateFields[4], endDateFields[5]);
            return Category_Edit_VersionsSelection_Utils$DvmAdminFrontendFr.compareToTakenVersions(firstCheck, endDateFields[1], param$1.takenVersionsList, "larger", startDate, categoryStartDate, categoryEndDate);
          })
      },
      startDateAsCategory: undefined,
      startDate: {
        strategy: /* OnFirstChange */1,
        validate: (function (param, at, param$1) {
            var categoryEndDate = param$1.categoryEndDate;
            var categoryStartDate = param$1.categoryStartDate;
            var startDateFields = Belt_Option.map(Belt_Array.get(param.versions, at), (function (param) {
                    return [
                            param.selected,
                            param.versionID,
                            param.startDate,
                            param.endDate,
                            param.startDateAsCategory,
                            param.endDateAsCategory
                          ];
                  }));
            if (startDateFields === undefined) {
              return {
                      TAG: /* Error */1,
                      _0: Messages_Contract_Create$DvmAdminFrontendFr.noAttachment
                    };
            }
            if (startDateFields[0] !== true) {
              return {
                      TAG: /* Ok */0,
                      _0: undefined
                    };
            }
            var endDate = startDateFields[3];
            var firstCheck = App_DatesEdit_Utils$DvmAdminFrontendFr.validateRequiredDateCompare(startDateFields[2], endDate, categoryStartDate, categoryEndDate, "smaller", startDateFields[4], startDateFields[5]);
            return Category_Edit_VersionsSelection_Utils$DvmAdminFrontendFr.compareToTakenVersions(firstCheck, startDateFields[1], param$1.takenVersionsList, "smaller", endDate, categoryStartDate, categoryEndDate);
          })
      },
      versionLabel: undefined,
      versionID: undefined,
      selected: undefined
    }
  }
};

function initialFieldsStatuses(input) {
  return {
          versions: Belt_Array.make(input.versions.length, {
                endDateAsCategory: /* Pristine */0,
                endDate: /* Pristine */0,
                startDateAsCategory: /* Pristine */0,
                startDate: /* Pristine */0,
                versionLabel: /* Pristine */0,
                versionID: /* Pristine */0,
                selected: /* Pristine */0
              })
        };
}

var initialCollectionsStatuses = {
  versions: undefined
};

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: initialCollectionsStatuses,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match = Curry._2(validators.versions.collection, input, metadata);
  var match$1 = Belt_Array.reduceWithIndex(fieldsStatuses.versions, [
        {
          TAG: /* Ok */0,
          _0: []
        },
        []
      ], (function (param, fieldStatus, index) {
          var statuses = param[1];
          var output = param[0];
          var match_0 = {
            TAG: /* Ok */0,
            _0: input.versions[index].endDateAsCategory
          };
          var match = fieldStatus.endDate;
          var match_0$1 = match ? match._0 : Curry._3(validators.versions.fields.endDate.validate, input, index, metadata);
          var match_0$2 = {
            TAG: /* Ok */0,
            _0: input.versions[index].startDateAsCategory
          };
          var match$1 = fieldStatus.startDate;
          var match_0$3 = match$1 ? match$1._0 : Curry._3(validators.versions.fields.startDate.validate, input, index, metadata);
          var match_0$4 = {
            TAG: /* Ok */0,
            _0: input.versions[index].versionLabel
          };
          var match_0$5 = {
            TAG: /* Ok */0,
            _0: input.versions[index].versionID
          };
          var match_0$6 = {
            TAG: /* Ok */0,
            _0: input.versions[index].selected
          };
          if (output.TAG === /* Ok */0) {
            var endDateAsCategoryResult = match_0;
            var output$1 = output._0;
            if (endDateAsCategoryResult.TAG === /* Ok */0) {
              var endDateResult = match_0$1;
              if (endDateResult.TAG === /* Ok */0) {
                var startDateAsCategoryResult = match_0$2;
                if (startDateAsCategoryResult.TAG === /* Ok */0) {
                  var startDateResult = match_0$3;
                  if (startDateResult.TAG === /* Ok */0) {
                    var versionLabelResult = match_0$4;
                    if (versionLabelResult.TAG === /* Ok */0) {
                      var versionIDResult = match_0$5;
                      if (versionIDResult.TAG === /* Ok */0) {
                        var selectedResult = match_0$6;
                        if (selectedResult.TAG === /* Ok */0) {
                          output$1.push({
                                selected: selectedResult._0,
                                versionID: versionIDResult._0,
                                versionLabel: versionLabelResult._0,
                                startDate: startDateResult._0,
                                startDateAsCategory: startDateAsCategoryResult._0,
                                endDate: endDateResult._0,
                                endDateAsCategory: endDateAsCategoryResult._0
                              });
                          statuses.push({
                                endDateAsCategory: /* Dirty */{
                                  _0: endDateAsCategoryResult,
                                  _1: /* Hidden */1
                                },
                                endDate: /* Dirty */{
                                  _0: endDateResult,
                                  _1: /* Shown */0
                                },
                                startDateAsCategory: /* Dirty */{
                                  _0: startDateAsCategoryResult,
                                  _1: /* Hidden */1
                                },
                                startDate: /* Dirty */{
                                  _0: startDateResult,
                                  _1: /* Shown */0
                                },
                                versionLabel: /* Dirty */{
                                  _0: versionLabelResult,
                                  _1: /* Hidden */1
                                },
                                versionID: /* Dirty */{
                                  _0: versionIDResult,
                                  _1: /* Hidden */1
                                },
                                selected: /* Dirty */{
                                  _0: selectedResult,
                                  _1: /* Hidden */1
                                }
                              });
                          return [
                                  {
                                    TAG: /* Ok */0,
                                    _0: output$1
                                  },
                                  statuses
                                ];
                        }
                        
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }
            
          }
          statuses.push({
                endDateAsCategory: /* Dirty */{
                  _0: match_0,
                  _1: /* Hidden */1
                },
                endDate: /* Dirty */{
                  _0: match_0$1,
                  _1: /* Shown */0
                },
                startDateAsCategory: /* Dirty */{
                  _0: match_0$2,
                  _1: /* Hidden */1
                },
                startDate: /* Dirty */{
                  _0: match_0$3,
                  _1: /* Shown */0
                },
                versionLabel: /* Dirty */{
                  _0: match_0$4,
                  _1: /* Hidden */1
                },
                versionID: /* Dirty */{
                  _0: match_0$5,
                  _1: /* Hidden */1
                },
                selected: /* Dirty */{
                  _0: match_0$6,
                  _1: /* Hidden */1
                }
              });
          return [
                  {
                    TAG: /* Error */1,
                    _0: undefined
                  },
                  statuses
                ];
        }));
  if (match.TAG === /* Ok */0) {
    var versions = match$1[0];
    if (versions.TAG === /* Ok */0) {
      return {
              TAG: /* Valid */0,
              output: {
                versions: versions._0
              },
              fieldsStatuses: {
                versions: match$1[1]
              },
              collectionsStatuses: {
                versions: match
              }
            };
    }
    
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            versions: match$1[1]
          },
          collectionsStatuses: {
            versions: match
          }
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* Submit */0 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */16,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */17,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */3);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */2);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */1 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */2 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */3 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateVersionEndDateAsCategoryField */0 :
                  var index = action._1;
                  var nextInput = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses.contents.versions, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput, index$p, metadata, item.startDate, validators.versions.fields.startDate, (function (status) {
                                  return {
                                          versions: Belt_Array.mapWithIndex(nextFieldsStatuses.contents.versions, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsCategory: item.endDateAsCategory,
                                                            endDate: item.endDate,
                                                            startDateAsCategory: item.startDateAsCategory,
                                                            startDate: status,
                                                            versionLabel: item.versionLabel,
                                                            versionID: item.versionID,
                                                            selected: item.selected
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses.contents.versions, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput, index$p, metadata, item.endDate, validators.versions.fields.endDate, (function (status) {
                                  return {
                                          versions: Belt_Array.mapWithIndex(nextFieldsStatuses.contents.versions, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsCategory: item.endDateAsCategory,
                                                            endDate: status,
                                                            startDateAsCategory: item.startDateAsCategory,
                                                            startDate: item.startDate,
                                                            versionLabel: item.versionLabel,
                                                            versionID: item.versionID,
                                                            selected: item.selected
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput.versions[index].endDateAsCategory, (function (status) {
                                    return {
                                            versions: Belt_Array.mapWithIndex(nextFieldsStatuses.contents.versions, (function (idx_, item) {
                                                    if (idx_ === index) {
                                                      return {
                                                              endDateAsCategory: status,
                                                              endDate: item.endDate,
                                                              startDateAsCategory: item.startDateAsCategory,
                                                              startDate: item.startDate,
                                                              versionLabel: item.versionLabel,
                                                              versionID: item.versionID,
                                                              selected: item.selected
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionEndDateField */1 :
                  var index$1 = action._1;
                  var nextInput$1 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$1.contents.versions, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$1, index$p, metadata, item.startDate, validators.versions.fields.startDate, (function (status) {
                                  return {
                                          versions: Belt_Array.mapWithIndex(nextFieldsStatuses$1.contents.versions, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsCategory: item.endDateAsCategory,
                                                            endDate: item.endDate,
                                                            startDateAsCategory: item.startDateAsCategory,
                                                            startDate: status,
                                                            versionLabel: item.versionLabel,
                                                            versionID: item.versionID,
                                                            selected: item.selected
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$1.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidatorAndMetadata(nextInput$1, index$1, metadata, nextFieldsStatuses$1.contents.versions[index$1].endDate, state.submissionStatus, validators.versions.fields.endDate, (function (status) {
                                    return {
                                            versions: Belt_Array.mapWithIndex(nextFieldsStatuses$1.contents.versions, (function (idx_, item) {
                                                    if (idx_ === index$1) {
                                                      return {
                                                              endDateAsCategory: item.endDateAsCategory,
                                                              endDate: status,
                                                              startDateAsCategory: item.startDateAsCategory,
                                                              startDate: item.startDate,
                                                              versionLabel: item.versionLabel,
                                                              versionID: item.versionID,
                                                              selected: item.selected
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionStartDateAsCategoryField */2 :
                  var index$2 = action._1;
                  var nextInput$2 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$2 = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$2.contents.versions, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$2, index$p, metadata, item.startDate, validators.versions.fields.startDate, (function (status) {
                                  return {
                                          versions: Belt_Array.mapWithIndex(nextFieldsStatuses$2.contents.versions, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsCategory: item.endDateAsCategory,
                                                            endDate: item.endDate,
                                                            startDateAsCategory: item.startDateAsCategory,
                                                            startDate: status,
                                                            versionLabel: item.versionLabel,
                                                            versionID: item.versionID,
                                                            selected: item.selected
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$2.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$2.contents.versions, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$2, index$p, metadata, item.endDate, validators.versions.fields.endDate, (function (status) {
                                  return {
                                          versions: Belt_Array.mapWithIndex(nextFieldsStatuses$2.contents.versions, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsCategory: item.endDateAsCategory,
                                                            endDate: status,
                                                            startDateAsCategory: item.startDateAsCategory,
                                                            startDate: item.startDate,
                                                            versionLabel: item.versionLabel,
                                                            versionID: item.versionID,
                                                            selected: item.selected
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$2.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$2.versions[index$2].startDateAsCategory, (function (status) {
                                    return {
                                            versions: Belt_Array.mapWithIndex(nextFieldsStatuses$2.contents.versions, (function (idx_, item) {
                                                    if (idx_ === index$2) {
                                                      return {
                                                              endDateAsCategory: item.endDateAsCategory,
                                                              endDate: item.endDate,
                                                              startDateAsCategory: status,
                                                              startDate: item.startDate,
                                                              versionLabel: item.versionLabel,
                                                              versionID: item.versionID,
                                                              selected: item.selected
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionStartDateField */3 :
                  var index$3 = action._1;
                  var nextInput$3 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$3 = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$3.contents.versions, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$3, index$p, metadata, item.endDate, validators.versions.fields.endDate, (function (status) {
                                  return {
                                          versions: Belt_Array.mapWithIndex(nextFieldsStatuses$3.contents.versions, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsCategory: item.endDateAsCategory,
                                                            endDate: status,
                                                            startDateAsCategory: item.startDateAsCategory,
                                                            startDate: item.startDate,
                                                            versionLabel: item.versionLabel,
                                                            versionID: item.versionID,
                                                            selected: item.selected
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$3.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidatorAndMetadata(nextInput$3, index$3, metadata, nextFieldsStatuses$3.contents.versions[index$3].startDate, state.submissionStatus, validators.versions.fields.startDate, (function (status) {
                                    return {
                                            versions: Belt_Array.mapWithIndex(nextFieldsStatuses$3.contents.versions, (function (idx_, item) {
                                                    if (idx_ === index$3) {
                                                      return {
                                                              endDateAsCategory: item.endDateAsCategory,
                                                              endDate: item.endDate,
                                                              startDateAsCategory: item.startDateAsCategory,
                                                              startDate: status,
                                                              versionLabel: item.versionLabel,
                                                              versionID: item.versionID,
                                                              selected: item.selected
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionVersionLabelField */4 :
                  var index$4 = action._1;
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$4.versions[index$4].versionLabel, (function (status) {
                                    return {
                                            versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                                    if (index$p === index$4) {
                                                      return {
                                                              endDateAsCategory: item.endDateAsCategory,
                                                              endDate: item.endDate,
                                                              startDateAsCategory: item.startDateAsCategory,
                                                              startDate: item.startDate,
                                                              versionLabel: status,
                                                              versionID: item.versionID,
                                                              selected: item.selected
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionVersionIDField */5 :
                  var index$5 = action._1;
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$5.versions[index$5].versionID, (function (status) {
                                    return {
                                            versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                                    if (index$p === index$5) {
                                                      return {
                                                              endDateAsCategory: item.endDateAsCategory,
                                                              endDate: item.endDate,
                                                              startDateAsCategory: item.startDateAsCategory,
                                                              startDate: item.startDate,
                                                              versionLabel: item.versionLabel,
                                                              versionID: status,
                                                              selected: item.selected
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionSelectedField */6 :
                  var index$6 = action._1;
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$6.versions[index$6].selected, (function (status) {
                                    return {
                                            versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                                    if (index$p === index$6) {
                                                      return {
                                                              endDateAsCategory: item.endDateAsCategory,
                                                              endDate: item.endDate,
                                                              startDateAsCategory: item.startDateAsCategory,
                                                              startDate: item.startDate,
                                                              versionLabel: item.versionLabel,
                                                              versionID: item.versionID,
                                                              selected: status
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* BlurVersionEndDateAsCategoryField */7 :
                  var index$7 = action._0;
                  var result = Formality.validateFieldOnBlurWithoutValidator(state.input.versions[index$7].endDateAsCategory, state.fieldsStatuses.versions[index$7].endDateAsCategory, (function (status) {
                          return {
                                  versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                          if (index$p === index$7) {
                                            return {
                                                    endDateAsCategory: status,
                                                    endDate: item.endDate,
                                                    startDateAsCategory: item.startDateAsCategory,
                                                    startDate: item.startDate,
                                                    versionLabel: item.versionLabel,
                                                    versionID: item.versionID,
                                                    selected: item.selected
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionEndDateField */8 :
                  var index$8 = action._0;
                  var result$1 = Formality.validateFieldOfCollectionOnBlurWithValidatorAndMetadata(state.input, index$8, metadata, state.fieldsStatuses.versions[index$8].endDate, validators.versions.fields.endDate, (function (status) {
                          return {
                                  versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                          if (index$p === index$8) {
                                            return {
                                                    endDateAsCategory: item.endDateAsCategory,
                                                    endDate: status,
                                                    startDateAsCategory: item.startDateAsCategory,
                                                    startDate: item.startDate,
                                                    versionLabel: item.versionLabel,
                                                    versionID: item.versionID,
                                                    selected: item.selected
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionStartDateAsCategoryField */9 :
                  var index$9 = action._0;
                  var result$2 = Formality.validateFieldOnBlurWithoutValidator(state.input.versions[index$9].startDateAsCategory, state.fieldsStatuses.versions[index$9].startDateAsCategory, (function (status) {
                          return {
                                  versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                          if (index$p === index$9) {
                                            return {
                                                    endDateAsCategory: item.endDateAsCategory,
                                                    endDate: item.endDate,
                                                    startDateAsCategory: status,
                                                    startDate: item.startDate,
                                                    versionLabel: item.versionLabel,
                                                    versionID: item.versionID,
                                                    selected: item.selected
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionStartDateField */10 :
                  var index$10 = action._0;
                  var result$3 = Formality.validateFieldOfCollectionOnBlurWithValidatorAndMetadata(state.input, index$10, metadata, state.fieldsStatuses.versions[index$10].startDate, validators.versions.fields.startDate, (function (status) {
                          return {
                                  versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                          if (index$p === index$10) {
                                            return {
                                                    endDateAsCategory: item.endDateAsCategory,
                                                    endDate: item.endDate,
                                                    startDateAsCategory: item.startDateAsCategory,
                                                    startDate: status,
                                                    versionLabel: item.versionLabel,
                                                    versionID: item.versionID,
                                                    selected: item.selected
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionVersionLabelField */11 :
                  var index$11 = action._0;
                  var result$4 = Formality.validateFieldOnBlurWithoutValidator(state.input.versions[index$11].versionLabel, state.fieldsStatuses.versions[index$11].versionLabel, (function (status) {
                          return {
                                  versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                          if (index$p === index$11) {
                                            return {
                                                    endDateAsCategory: item.endDateAsCategory,
                                                    endDate: item.endDate,
                                                    startDateAsCategory: item.startDateAsCategory,
                                                    startDate: item.startDate,
                                                    versionLabel: status,
                                                    versionID: item.versionID,
                                                    selected: item.selected
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionVersionIDField */12 :
                  var index$12 = action._0;
                  var result$5 = Formality.validateFieldOnBlurWithoutValidator(state.input.versions[index$12].versionID, state.fieldsStatuses.versions[index$12].versionID, (function (status) {
                          return {
                                  versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                          if (index$p === index$12) {
                                            return {
                                                    endDateAsCategory: item.endDateAsCategory,
                                                    endDate: item.endDate,
                                                    startDateAsCategory: item.startDateAsCategory,
                                                    startDate: item.startDate,
                                                    versionLabel: item.versionLabel,
                                                    versionID: status,
                                                    selected: item.selected
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionSelectedField */13 :
                  var index$13 = action._0;
                  var result$6 = Formality.validateFieldOnBlurWithoutValidator(state.input.versions[index$13].selected, state.fieldsStatuses.versions[index$13].selected, (function (status) {
                          return {
                                  versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                          if (index$p === index$13) {
                                            return {
                                                    endDateAsCategory: item.endDateAsCategory,
                                                    endDate: item.endDate,
                                                    startDateAsCategory: item.startDateAsCategory,
                                                    startDate: item.startDate,
                                                    versionLabel: item.versionLabel,
                                                    versionID: item.versionID,
                                                    selected: status
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* AddVersionEntry */14 :
                  var nextInput$7 = {
                    versions: Belt_Array.concat(state.input.versions, [action._0])
                  };
                  var nextFieldsStatuses$4 = {
                    contents: {
                      versions: Belt_Array.concat(state.fieldsStatuses.versions, [{
                              endDateAsCategory: /* Pristine */0,
                              endDate: /* Pristine */0,
                              startDateAsCategory: /* Pristine */0,
                              startDate: /* Pristine */0,
                              versionLabel: /* Pristine */0,
                              versionID: /* Pristine */0,
                              selected: /* Pristine */0
                            }])
                    }
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$4.contents.versions, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$7, index$p, metadata, item.endDate, validators.versions.fields.endDate, (function (status) {
                                  return {
                                          versions: Belt_Array.mapWithIndex(nextFieldsStatuses$4.contents.versions, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsCategory: item.endDateAsCategory,
                                                            endDate: status,
                                                            startDateAsCategory: item.startDateAsCategory,
                                                            startDate: item.startDate,
                                                            versionLabel: item.versionLabel,
                                                            versionID: item.versionID,
                                                            selected: item.selected
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$4.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$4.contents.versions, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$7, index$p, metadata, item.startDate, validators.versions.fields.startDate, (function (status) {
                                  return {
                                          versions: Belt_Array.mapWithIndex(nextFieldsStatuses$4.contents.versions, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsCategory: item.endDateAsCategory,
                                                            endDate: item.endDate,
                                                            startDateAsCategory: item.startDateAsCategory,
                                                            startDate: status,
                                                            versionLabel: item.versionLabel,
                                                            versionID: item.versionID,
                                                            selected: item.selected
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$4.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$4.contents.versions, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$7, index$p, metadata, item.endDate, validators.versions.fields.endDate, (function (status) {
                                  return {
                                          versions: Belt_Array.mapWithIndex(nextFieldsStatuses$4.contents.versions, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsCategory: item.endDateAsCategory,
                                                            endDate: status,
                                                            startDateAsCategory: item.startDateAsCategory,
                                                            startDate: item.startDate,
                                                            versionLabel: item.versionLabel,
                                                            versionID: item.versionID,
                                                            selected: item.selected
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$4.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$4.contents.versions, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$7, index$p, metadata, item.startDate, validators.versions.fields.startDate, (function (status) {
                                  return {
                                          versions: Belt_Array.mapWithIndex(nextFieldsStatuses$4.contents.versions, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsCategory: item.endDateAsCategory,
                                                            endDate: item.endDate,
                                                            startDateAsCategory: item.startDateAsCategory,
                                                            startDate: status,
                                                            versionLabel: item.versionLabel,
                                                            versionID: item.versionID,
                                                            selected: item.selected
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$4.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$4.contents.versions, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$7, index$p, metadata, item.startDate, validators.versions.fields.startDate, (function (status) {
                                  return {
                                          versions: Belt_Array.mapWithIndex(nextFieldsStatuses$4.contents.versions, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsCategory: item.endDateAsCategory,
                                                            endDate: item.endDate,
                                                            startDateAsCategory: item.startDateAsCategory,
                                                            startDate: status,
                                                            versionLabel: item.versionLabel,
                                                            versionID: item.versionID,
                                                            selected: item.selected
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$4.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$4.contents.versions, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$7, index$p, metadata, item.endDate, validators.versions.fields.endDate, (function (status) {
                                  return {
                                          versions: Belt_Array.mapWithIndex(nextFieldsStatuses$4.contents.versions, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsCategory: item.endDateAsCategory,
                                                            endDate: status,
                                                            startDateAsCategory: item.startDateAsCategory,
                                                            startDate: item.startDate,
                                                            versionLabel: item.versionLabel,
                                                            versionID: item.versionID,
                                                            selected: item.selected
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$4.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: nextFieldsStatuses$4.contents,
                            collectionsStatuses: {
                              versions: Curry._2(validators.versions.collection, nextInput$7, metadata)
                            },
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* RemoveVersionEntry */15 :
                  var index$14 = action._0;
                  var nextInput$8 = {
                    versions: Belt_Array.keepWithIndex(state.input.versions, (function (param, i) {
                            return i !== index$14;
                          }))
                  };
                  var nextFieldsStatuses$5 = {
                    contents: {
                      versions: Belt_Array.keepWithIndex(state.fieldsStatuses.versions, (function (param, i) {
                              return i !== index$14;
                            }))
                    }
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$5.contents.versions, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$8, index$p, metadata, item.endDate, validators.versions.fields.endDate, (function (status) {
                                  return {
                                          versions: Belt_Array.mapWithIndex(nextFieldsStatuses$5.contents.versions, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsCategory: item.endDateAsCategory,
                                                            endDate: status,
                                                            startDateAsCategory: item.startDateAsCategory,
                                                            startDate: item.startDate,
                                                            versionLabel: item.versionLabel,
                                                            versionID: item.versionID,
                                                            selected: item.selected
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$5.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$5.contents.versions, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$8, index$p, metadata, item.startDate, validators.versions.fields.startDate, (function (status) {
                                  return {
                                          versions: Belt_Array.mapWithIndex(nextFieldsStatuses$5.contents.versions, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsCategory: item.endDateAsCategory,
                                                            endDate: item.endDate,
                                                            startDateAsCategory: item.startDateAsCategory,
                                                            startDate: status,
                                                            versionLabel: item.versionLabel,
                                                            versionID: item.versionID,
                                                            selected: item.selected
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$5.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$5.contents.versions, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$8, index$p, metadata, item.endDate, validators.versions.fields.endDate, (function (status) {
                                  return {
                                          versions: Belt_Array.mapWithIndex(nextFieldsStatuses$5.contents.versions, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsCategory: item.endDateAsCategory,
                                                            endDate: status,
                                                            startDateAsCategory: item.startDateAsCategory,
                                                            startDate: item.startDate,
                                                            versionLabel: item.versionLabel,
                                                            versionID: item.versionID,
                                                            selected: item.selected
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$5.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$5.contents.versions, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$8, index$p, metadata, item.startDate, validators.versions.fields.startDate, (function (status) {
                                  return {
                                          versions: Belt_Array.mapWithIndex(nextFieldsStatuses$5.contents.versions, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsCategory: item.endDateAsCategory,
                                                            endDate: item.endDate,
                                                            startDateAsCategory: item.startDateAsCategory,
                                                            startDate: status,
                                                            versionLabel: item.versionLabel,
                                                            versionID: item.versionID,
                                                            selected: item.selected
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$5.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$5.contents.versions, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$8, index$p, metadata, item.startDate, validators.versions.fields.startDate, (function (status) {
                                  return {
                                          versions: Belt_Array.mapWithIndex(nextFieldsStatuses$5.contents.versions, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsCategory: item.endDateAsCategory,
                                                            endDate: item.endDate,
                                                            startDateAsCategory: item.startDateAsCategory,
                                                            startDate: status,
                                                            versionLabel: item.versionLabel,
                                                            versionID: item.versionID,
                                                            selected: item.selected
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$5.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$5.contents.versions, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$8, index$p, metadata, item.endDate, validators.versions.fields.endDate, (function (status) {
                                  return {
                                          versions: Belt_Array.mapWithIndex(nextFieldsStatuses$5.contents.versions, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsCategory: item.endDateAsCategory,
                                                            endDate: status,
                                                            startDateAsCategory: item.startDateAsCategory,
                                                            startDate: item.startDate,
                                                            versionLabel: item.versionLabel,
                                                            versionID: item.versionID,
                                                            selected: item.selected
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$5.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: nextFieldsStatuses$5.contents,
                            collectionsStatuses: {
                              versions: Curry._2(validators.versions.collection, nextInput$8, metadata)
                            },
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */16 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */17 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */18 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Caml_option.some(Curry._1(map, Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateVersionEndDateAsCategory: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionEndDateAsCategoryField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateVersionEndDate: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionEndDateField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateVersionStartDateAsCategory: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionStartDateAsCategoryField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateVersionStartDate: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionStartDateField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateVersionVersionLabel: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionVersionLabelField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateVersionVersionID: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionVersionIDField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateVersionSelected: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionSelectedField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          blurVersionEndDateAsCategory: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurVersionEndDateAsCategoryField */7,
                    _0: index
                  });
            }),
          blurVersionEndDate: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurVersionEndDateField */8,
                    _0: index
                  });
            }),
          blurVersionStartDateAsCategory: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurVersionStartDateAsCategoryField */9,
                    _0: index
                  });
            }),
          blurVersionStartDate: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurVersionStartDateField */10,
                    _0: index
                  });
            }),
          blurVersionVersionLabel: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurVersionVersionLabelField */11,
                    _0: index
                  });
            }),
          blurVersionVersionID: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurVersionVersionIDField */12,
                    _0: index
                  });
            }),
          blurVersionSelected: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurVersionSelectedField */13,
                    _0: index
                  });
            }),
          versionEndDateAsCategoryResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.versions[index].endDateAsCategory);
            }),
          versionEndDateResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.versions[index].endDate);
            }),
          versionStartDateAsCategoryResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.versions[index].startDateAsCategory);
            }),
          versionStartDateResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.versions[index].startDate);
            }),
          versionVersionLabelResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.versions[index].versionLabel);
            }),
          versionVersionIDResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.versions[index].versionID);
            }),
          versionSelectedResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.versions[index].selected);
            }),
          addVersion: (function (entry) {
              Curry._1(dispatch, {
                    TAG: /* AddVersionEntry */14,
                    _0: entry
                  });
            }),
          removeVersion: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* RemoveVersionEntry */15,
                    _0: index
                  });
            }),
          versionsResult: state.collectionsStatuses.versions,
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              return Belt_Array.every(state.fieldsStatuses.versions, (function (item) {
                            if (item.endDateAsCategory || item.endDate || item.startDateAsCategory || item.startDate || item.versionLabel || item.versionID || item.selected) {
                              return true;
                            } else {
                              return false;
                            }
                          }));
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */0);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */1);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */2);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */18,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */3);
            })
        };
}

var VersionsForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: initialCollectionsStatuses,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialInput = {
  versions: []
};

var UtilsDates;

var UtilsCategory;

var initialAttachmentInput = {
  selected: false,
  versionID: "",
  versionLabel: "",
  startDate: undefined,
  startDateAsCategory: true,
  endDate: undefined,
  endDateAsCategory: true
};

export {
  UtilsDates ,
  UtilsCategory ,
  VersionsForm ,
  initialInput ,
  initialAttachmentInput ,
}
/* react Not a pure module */
