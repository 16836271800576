// Generated by ReScript, PLEASE EDIT WITH CARE


var dynatraceTagUserKey = "dynatraceTagUser";

function storeDynatraceTagUser(value) {
  sessionStorage.setItem(dynatraceTagUserKey, value);
}

export {
  dynatraceTagUserKey ,
  storeDynatraceTagUser ,
}
/* No side effect */
