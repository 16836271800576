// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as Submarket_Types$DvmAdminFrontendFr from "../submarkets/Submarket_Types.bs.js";
import * as Gemo_Search_Types$DvmAdminFrontendFr from "../search/Gemo_Search_Types.bs.js";

function updateGemo_encode(v) {
  return Js_dict.fromArray([
              [
                "oldObject",
                Gemo_Search_Types$DvmAdminFrontendFr.row_encode(v.oldObject)
              ],
              [
                "newObject",
                Gemo_Search_Types$DvmAdminFrontendFr.row_encode(v.newObject)
              ]
            ]);
}

function updateGemo_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var oldObject = Gemo_Search_Types$DvmAdminFrontendFr.row_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "oldObject"), null));
  if (oldObject.TAG === /* Ok */0) {
    var newObject = Gemo_Search_Types$DvmAdminFrontendFr.row_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "newObject"), null));
    if (newObject.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                oldObject: oldObject._0,
                newObject: newObject._0
              }
            };
    }
    var e = newObject._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".newObject" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = oldObject._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".oldObject" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function saveGemo(operation, userManager, setResult, submissionCallbacks, selectedRole, gemo) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call(operation ? "PUT" : "POST", {
          TAG: /* Json */0,
          _0: operation ? updateGemo_encode({
                  oldObject: operation._0,
                  newObject: gemo
                }) : Gemo_Search_Types$DvmAdminFrontendFr.row_encode(gemo)
        }, userManager, selectedRole, "/gemo").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToSaveGemo */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function deleteGemo(userManager, setResult, selectedRole, gemo) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", {
          TAG: /* Json */0,
          _0: Gemo_Search_Types$DvmAdminFrontendFr.row_encode(gemo)
        }, userManager, selectedRole, "/gemo").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchSubmarkets(userManager, setResult, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Submarket_Types$DvmAdminFrontendFr.searchResult_decode, undefined, userManager, selectedRole, "/gemo/submarketcodes?limit=5000&offset=0").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: Belt_List.map(searchTable.content, (function (x) {
                                        return x.submarketCode;
                                      }))
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

export {
  Form ,
  updateGemo_encode ,
  updateGemo_decode ,
  saveGemo ,
  deleteGemo ,
  fetchSubmarkets ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
