// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as App_Modal$DvmAdminFrontendFr from "../../../../common/components/App_Modal.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Company_Virtual$DvmAdminFrontendFr from "../../../../intl/messages/company/Messages_Company_Virtual.bs.js";
import * as Company_Virtual_Modal_Api$DvmAdminFrontendFr from "./Company_Virtual_Modal_Api.bs.js";
import * as Company_Virtual_Modal_Form$DvmAdminFrontendFr from "./Company_Virtual_Modal_Form.bs.js";
import * as Company_Virtual_Modal_Utils$DvmAdminFrontendFr from "./Company_Virtual_Modal_Utils.bs.js";
import * as Company_Virtual_Modal_Inputs$DvmAdminFrontendFr from "./Company_Virtual_Modal_Inputs.bs.js";

function Company_Virtual_Modal(Props) {
  var operation = Props.operation;
  var onClose = Props.onClose;
  var row = Props.row;
  var refresh = Props.refresh;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return false;
      });
  var setIsContractFilled = match$2[1];
  var isContractFilled = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setAttachmentCreateFailed = match$3[1];
  var attachmentCreateFailed = match$3[0];
  var match$4 = React.useState(function () {
        return "";
      });
  var setSelectedPeriodLabel = match$4[1];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$5[1];
  var result = match$5[0];
  var match$6 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setExistingAttachment = match$6[1];
  var existingAttachment = match$6[0];
  var match$7 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setContractsResult = match$7[1];
  var contractsResult = match$7[0];
  var form = Company_Virtual_Modal_Form$DvmAdminFrontendFr.Form.useForm(Company_Virtual_Modal_Utils$DvmAdminFrontendFr.populateForm(row, existingAttachment), (function (output, submissionCallbacks) {
          Company_Virtual_Modal_Api$DvmAdminFrontendFr.createOrEditSiret(submissionCallbacks, userManager, setResult, attachmentCreateFailed || operation ? "PUT" : "POST", attachmentCreateFailed || !isContractFilled ? "POST" : "PUT", {
                contractID: output.contractID,
                siren: output.siret,
                sirenName: output.name,
                startDate: output.attachmentStartDate,
                endDate: output.endDateAsParent ? undefined : output.attachmentEndDate
              }, {
                contractID: output.contractID,
                siren: output.siret,
                sirenName: output.name,
                startDate: output.origAttachmentStartDate,
                endDate: output.endDateAsParent ? undefined : output.attachmentEndDate
              }, output.addAttachment, setAttachmentCreateFailed, selectedRole, {
                siret: output.siret,
                siren: output.siret,
                name: output.name,
                street: output.street,
                streetNbr: output.streetNbr,
                additionalAddressInfo: output.additionalAddressInfo,
                zipCode: output.zipCode,
                city: output.city,
                countryCode: output.countryCode,
                budgetOne: undefined,
                budgetTwo: undefined,
                endDate: output.endDate
              });
        }));
  var contractsModified = function (param) {
    return Company_Virtual_Modal_Utils$DvmAdminFrontendFr.modifyContractsList(contractsResult, intl);
  };
  var handleDateLabelChange = function (val, contract) {
    Curry._1(setSelectedPeriodLabel, (function (param) {
            return val;
          }));
    Company_Virtual_Modal_Utils$DvmAdminFrontendFr.updateContractDates(val, contract, contractsModified, form);
  };
  React.useEffect((function () {
          if (Belt_Option.mapWithDefault(row, true, (function (param) {
                    return App_Types_Result$DvmAdminFrontendFr.mapWithDefault(existingAttachment, false, (function (x) {
                                  return Belt_Option.isNone(Belt_List.get(x.content, 0)) ? true : false;
                                }));
                  }))) {
            handleDateLabelChange(Belt_Option.mapWithDefault(Belt_List.getBy(Company_Virtual_Modal_Utils$DvmAdminFrontendFr.modifyContractsList(contractsResult, intl), (function (x) {
                            return x.contractID === form.input.contractID;
                          })), "", (function (contract) {
                        if (Belt_List.length(contract.dates) > 1) {
                          return "";
                        } else {
                          return Belt_Option.mapWithDefault(Belt_List.get(contract.dates, 0), "", (function (x) {
                                        return Belt_Option.getWithDefault(x.label, "");
                                      }));
                        }
                      })), undefined);
          }
          
        }), [form.input.contractID]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.mapWithDefault(existingAttachment, undefined, (function (x) {
                  Belt_Option.mapWithDefault(Belt_List.get(x.content, 0), undefined, (function (x) {
                          Curry._1(form.reset, undefined);
                          Belt_Option.mapWithDefault(Company_Virtual_Modal_Utils$DvmAdminFrontendFr.findProperPeriodLabel(contractsModified, x), undefined, (function (val) {
                                  handleDateLabelChange(val, x.contractID);
                                }));
                          Curry._1(setIsContractFilled, (function (param) {
                                  return true;
                                }));
                        }));
                }));
        }), [existingAttachment]);
  React.useEffect((function () {
          if (typeof result === "number") {
            result === /* NotStarted */0;
          } else if (result.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(refresh, undefined);
            Curry._1(onClose, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: result._0
                      }
                    }));
          }
        }), [result]);
  React.useEffect((function () {
          if (typeof contractsResult === "number") {
            contractsResult === /* NotStarted */0;
          } else if (contractsResult.TAG === /* Ok */0) {
            if (row !== undefined) {
              Curry._1(form.reset, undefined);
              Company_Virtual_Modal_Api$DvmAdminFrontendFr.checkIfExistsAsAttachment(userManager, setExistingAttachment, row.siret, selectedRole);
            }
            
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: contractsResult._0
                      }
                    }));
          }
        }), [contractsResult]);
  React.useEffect((function () {
          Company_Virtual_Modal_Api$DvmAdminFrontendFr.fetchContractSearch(userManager, setContractsResult, selectedRole);
        }), []);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: row !== undefined ? intl.formatMessage(Messages_Company_Virtual$DvmAdminFrontendFr.editModalTitle) : intl.formatMessage(Messages_Company_Virtual$DvmAdminFrontendFr.createTitle),
              minWidth: "800px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(result) || App_Types_Result$DvmAdminFrontendFr.isPending(contractsResult) || App_Types_Result$DvmAdminFrontendFr.isPending(existingAttachment),
                    children: React.createElement(Company_Virtual_Modal_Inputs$DvmAdminFrontendFr.make, {
                          form: form,
                          row: row,
                          isContractFilled: isContractFilled,
                          selectedPeriodLabel: match$4[0],
                          handleDateLabelChange: handleDateLabelChange,
                          contractsModified: contractsModified
                        })
                  })
            });
}

var Api;

var Form;

var ModalUtils;

var make = Company_Virtual_Modal;

export {
  Api ,
  Form ,
  ModalUtils ,
  make ,
}
/* react Not a pure module */
