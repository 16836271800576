// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as App_Icon$DvmAdminFrontendFr from "../common/components/icons/App_Icon.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../common/App_Typography.bs.js";

function pickStyle(alertType) {
  if (alertType === "error") {
    return "dsr-toast--danger";
  } else if (alertType === "success") {
    return "dsr-toast--success";
  } else {
    return "";
  }
}

function pickIcon(alertType) {
  if (alertType === "error") {
    return /* HelpWarning */0;
  } else if (alertType === "success") {
    return /* Tick */1;
  } else {
    return /* HelpBubbleUnfocused */21;
  }
}

function renderTemplate(infoHeader, successHeader, errorHeader, param) {
  var options = param.options;
  var intl = ReactIntl.useIntl();
  var createAlertHeading = function (param) {
    var match = options.headerMsg;
    var match$1 = options.type;
    if (match !== undefined) {
      return intl.formatMessage(match);
    } else if (match$1 === "info") {
      return intl.formatMessage(infoHeader);
    } else if (match$1 === "success") {
      return intl.formatMessage(successHeader);
    } else {
      return intl.formatMessage(errorHeader);
    }
  };
  var match = options.type;
  var tmp;
  if (match === "success") {
    tmp = null;
  } else {
    var nowdate = new Date();
    tmp = React.createElement(App_Typography$DvmAdminFrontendFr.make, {
          style: {
            marginTop: "10px"
          },
          children: nowdate.toLocaleString() + " " + Belt_Option.getWithDefault(Caml_option.nullable_to_opt(intl.timeZone), ""),
          variant: "caption"
        });
  }
  return React.createElement("div", {
              style: param.style
            }, React.createElement("div", {
                  className: "dsr-toast dsr-toast--colored " + pickStyle(options.type) + ""
                }, React.createElement("div", {
                      className: "dsr-toast__ico"
                    }, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                          size: /* Large */3,
                          type_: pickIcon(options.type)
                        })), React.createElement("div", {
                      className: "dsr-toast__content"
                    }, React.createElement("div", {
                          className: "dsr-toast__title"
                        }, createAlertHeading(undefined)), React.createElement("div", {
                          className: "dsr-toast__text"
                        }, param.message), tmp), React.createElement("div", {
                      "aria-label": "Close",
                      className: "dsr-toast__close",
                      role: "button",
                      tabIndex: 0,
                      onClick: param.close
                    })));
}

export {
  pickStyle ,
  pickIcon ,
  renderTemplate ,
}
/* react Not a pure module */
