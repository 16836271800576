// Generated by ReScript, PLEASE EDIT WITH CARE


var confirm = {
  id: "a291fe3a-9c9d-415e-b5d0-18922af92641",
  defaultMessage: "Valider"
};

var $$delete = {
  id: "1c9b41aa-b3d5-4f3d-a2e0-4de301d59042",
  defaultMessage: "Supprimer"
};

var noted = {
  id: "e4a7079f-fb53-4bd4-9bfb-328dcf8b9193",
  defaultMessage: "C'est noté"
};

var restart = {
  id: "9be3acf2-716c-4e8e-b271-e03719ea9a05",
  defaultMessage: "Réinitialiser"
};

var create = {
  id: "8d381e98-e9b9-49ea-bd3b-f60dbf51bd8b",
  defaultMessage: "Créer"
};

var next = {
  id: "3f69a08f-abf2-4b34-bcbf-94905b14c87a",
  defaultMessage: "Suivant"
};

var edit = {
  id: "a85684e4-1399-4f1a-a91c-51492ccd7353",
  defaultMessage: "Modifier"
};

var ok = {
  id: "eb1add68-a2db-4c28-b552-72a409a98d2e",
  defaultMessage: "Ok"
};

var cancel = {
  id: "907bee61-0fa8-444a-a4fa-98eb2c2cc80f",
  defaultMessage: "Annuler"
};

var previous = {
  id: "77efa0fb-c68e-4683-97ac-196ce45838ae",
  defaultMessage: "Précédent"
};

var apply = {
  id: "d33d7f12-fb81-4fd9-aa59-4ff5b7296a14",
  defaultMessage: "Appliquer"
};

var close = {
  id: "824c6f5d-5bec-4330-a020-40cf5cf2c174",
  defaultMessage: "Fermer"
};

var reset = {
  id: "d6fa695d-4edd-43b7-a778-38df00b2e7a6",
  defaultMessage: "Retour arrière"
};

var loginBack = {
  id: "f8f678a0-8e15-4c23-8ea7-20aefb449926",
  defaultMessage: "Connectez-vous à l'application"
};

var resetChanges = {
  id: "7d97299b-6915-44c2-8b11-f094d7305cf9",
  defaultMessage: "Revenir aux paramètres précédents"
};

export {
  confirm ,
  $$delete ,
  noted ,
  restart ,
  create ,
  next ,
  edit ,
  ok ,
  cancel ,
  previous ,
  apply ,
  close ,
  reset ,
  loginBack ,
  resetChanges ,
}
/* No side effect */
