// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as App_Spinner$DvmAdminFrontendFr from "../../../common/components/App_Spinner.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Table_Result$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Result.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Contract_Search_Api$DvmAdminFrontendFr from "./Contract_Search_Api.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Pagination.bs.js";
import * as Contract_Search_Table$DvmAdminFrontendFr from "./Contract_Search_Table.bs.js";
import * as Contract_Search_Filter$DvmAdminFrontendFr from "./Contract_Search_Filter.bs.js";
import * as Messages_Contract_List$DvmAdminFrontendFr from "../../../intl/messages/contract/Messages_Contract_List.bs.js";

function Contract_Search_Page(Props) {
  var tableType = Props.tableType;
  var operation = Props.operation;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match[1];
  var tablePage = match[0];
  var match$1 = React.useState(function () {
        return /* [] */0;
      });
  var match$2 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: Caml_array.get(App_Table_Pagination$DvmAdminFrontendFr.options, 0),
                headers: Contract_Search_Table$DvmAdminFrontendFr.headersConfiguration,
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$2[1];
  var fetchRequest = match$2[0];
  var alert = ReactAlert.useAlert();
  var match$3 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$4 = match$3[0];
  var userManager = match$4.userManager;
  var selectedRole = match$4.selectedRole;
  var fetchData = function (submissionCallbacks, fetchRequest) {
    if (tableType) {
      Contract_Search_Api$DvmAdminFrontendFr.fetchAllAttachmentsSearch(submissionCallbacks, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, fetchRequest), selectedRole);
    } else {
      Contract_Search_Api$DvmAdminFrontendFr.fetchContractSearch(submissionCallbacks, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, fetchRequest), selectedRole);
    }
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  React.useEffect((function () {
          fetchData(undefined, fetchRequest);
        }), [tableType]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.forError(tablePage, (function (error) {
                  Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                            message: {
                              TAG: /* RestError */2,
                              _0: error
                            }
                          }));
                }));
        }), [tablePage]);
  var tmp;
  var exit = 0;
  if (typeof tablePage === "number" || tablePage.TAG !== /* Ok */0) {
    exit = 1;
  } else {
    tmp = React.createElement(App_Table_Result$DvmAdminFrontendFr.make, {
          number: tablePage._0.total,
          message: tableType ? Messages_Contract_List$DvmAdminFrontendFr.attachments : Messages_Contract_List$DvmAdminFrontendFr.contracts
        });
  }
  if (exit === 1) {
    tmp = React.createElement(App_Spinner$DvmAdminFrontendFr.make, {});
  }
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              direction: "column",
              spacing: 2
            }, React.createElement(Core.Grid, {
                  children: React.createElement(Core.Typography, {
                        children: intl.formatMessage(tableType ? Messages_Contract_List$DvmAdminFrontendFr.titleAttachment : Messages_Contract_List$DvmAdminFrontendFr.titleContract),
                        variant: "h3"
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Contract_Search_Filter$DvmAdminFrontendFr.make, {
                        fetchData: fetchData,
                        fetchRequest: fetchRequest,
                        setParamsList: match$1[1]
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: null,
                  item: true
                }, tmp, React.createElement(Contract_Search_Table$DvmAdminFrontendFr.make, {
                      tablePage: tablePage,
                      fetchRequest: fetchRequest,
                      fetchData: fetchData,
                      tableType: tableType,
                      paramsList: match$1[0],
                      operation: operation
                    })));
}

var Table;

var Api;

var Filter;

var make = Contract_Search_Page;

export {
  Table ,
  Api ,
  Filter ,
  make ,
}
/* react Not a pure module */
