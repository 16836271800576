// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../common/components/App_LabeledText.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as Dealer_Detail_Styles$DvmAdminFrontendFr from "./Dealer_Detail_Styles.bs.js";
import * as Messages_Dealer_List$DvmAdminFrontendFr from "../../../intl/messages/dealer/Messages_Dealer_List.bs.js";
import * as Messages_Dealer_Detail$DvmAdminFrontendFr from "../../../intl/messages/dealer/Messages_Dealer_Detail.bs.js";

function lineIfEmpty(text) {
  var x = Belt_Option.getWithDefault(text, "-");
  if (x === "") {
    return "-";
  } else {
    return x;
  }
}

function getItem(label, string) {
  return React.createElement(Core.Grid, {
              children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                    label: label,
                    children: lineIfEmpty(string)
                  }),
              item: true,
              md: Grid$Mui.Md[3],
              sm: Grid$Mui.Sm[4]
            });
}

function headerTextElement(label, content, intl) {
  return React.createElement(Core.Box, {
              children: null
            }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  style: {
                    color: Theme_Colors$DvmAdminFrontendFr.mineralDarker
                  },
                  children: intl.formatMessage(label),
                  variant: "subtitle"
                }), React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  color: "textPrimary",
                  style: {
                    fontWeight: "600"
                  },
                  children: content,
                  variant: "h3"
                }));
}

function Dealer_Detail_Main_Info(Props) {
  var dealerDetail = Props.dealerDetail;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var selectedRole = match[0].selectedRole;
  var x = Belt_Option.getWithDefault(dealerDetail.dealerName, "-");
  var tmp = x === "" ? "-" : x;
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              direction: "column",
              spacing: 5
            }, React.createElement(Core.Grid, {
                  children: React.createElement(Core.Grid, {
                        children: React.createElement(Core.Paper, {
                              children: React.createElement(Core.Grid, {
                                    children: null,
                                    container: true,
                                    item: true,
                                    spacing: 8
                                  }, React.createElement(Core.Grid, {
                                        children: headerTextElement(Messages_Common$DvmAdminFrontendFr.dealerID, dealerDetail.dealerID, intl),
                                        item: true
                                      }), React.createElement(Core.Grid, {
                                        children: headerTextElement(Messages_Dealer_List$DvmAdminFrontendFr.dealerName, tmp, intl),
                                        item: true
                                      }), React.createElement(Core.Grid, {
                                        children: headerTextElement(Messages_Common$DvmAdminFrontendFr.territory, dealerDetail.dr, intl),
                                        item: true
                                      })),
                              square: true,
                              style: {
                                padding: "17px"
                              }
                            }),
                        item: true,
                        sm: Grid$Mui.Sm[12]
                      }),
                  container: true,
                  item: true
                }), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  item: true,
                  spacing: 2
                }, React.createElement(Core.Grid, {
                      children: React.createElement(Core.Paper, {
                            children: null,
                            className: Dealer_Detail_Styles$DvmAdminFrontendFr.detailsPaper,
                            square: true
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(Core.Grid, {
                                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                            color: "primary",
                                            gutterBottom: true,
                                            children: intl.formatMessage(Messages_Dealer_Detail$DvmAdminFrontendFr.titleDetail, {
                                                  dealer: dealerDetail.dealerID
                                                }),
                                            variant: "h3"
                                          }),
                                      item: true
                                    }),
                                container: true,
                                item: true,
                                spacing: 1
                              }), React.createElement(Core.Grid, {
                                children: null,
                                container: true,
                                item: true,
                                spacing: 1
                              }, getItem(Messages_Dealer_List$DvmAdminFrontendFr.entityLabel, dealerDetail.entityLabel), getItem(Messages_Dealer_Detail$DvmAdminFrontendFr.dc, dealerDetail.dc), getItem(Messages_Dealer_Detail$DvmAdminFrontendFr.drName, dealerDetail.nameDr), getItem(Messages_Dealer_Detail$DvmAdminFrontendFr.entityCode, dealerDetail.entityCode), getItem(Messages_Dealer_Detail$DvmAdminFrontendFr.alcorID, dealerDetail.alcorID), selectedRole === /* Dealer */7 || User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole) ? getItem(Messages_Dealer_List$DvmAdminFrontendFr.keyAccountManagerName, dealerDetail.keyAccountManagerName) : getItem(Messages_Dealer_Detail$DvmAdminFrontendFr.keyAccountManagerNameDealerDetailWithIPN, Belt_Option.getWithDefault(dealerDetail.keyAccountManagerName, "") + (
                                      Belt_Option.getWithDefault(dealerDetail.keyAccountManagerIpn, "").length > 0 ? " (" + Belt_Option.getWithDefault(dealerDetail.keyAccountManagerIpn, "") + ")" : ""
                                    )), getItem(Messages_Dealer_Detail$DvmAdminFrontendFr.nameR1, dealerDetail.nameR1), getItem(Messages_Dealer_Detail$DvmAdminFrontendFr.nameR2, dealerDetail.nameR2))),
                      item: true,
                      sm: Grid$Mui.Sm[9]
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(Core.Paper, {
                            children: React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  direction: "column",
                                  item: true
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                            color: "primary",
                                            gutterBottom: true,
                                            children: intl.formatMessage(Messages_Dealer_Detail$DvmAdminFrontendFr.address),
                                            variant: "h3"
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                            children: Belt_Option.getWithDefault(dealerDetail.city, ""),
                                            variant: "bodyBig"
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                            children: Belt_Option.getWithDefault(dealerDetail.zipCode, ""),
                                            variant: "bodyBig"
                                          }),
                                      item: true
                                    })),
                            className: Dealer_Detail_Styles$DvmAdminFrontendFr.detailsPaper,
                            square: true
                          }),
                      item: true,
                      sm: Grid$Mui.Sm[3]
                    })));
}

var make = Dealer_Detail_Main_Info;

export {
  lineIfEmpty ,
  getItem ,
  headerTextElement ,
  make ,
}
/* react Not a pure module */
