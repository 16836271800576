// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../common/utils/Utils_Date.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../route/Route_Paths.bs.js";

function routeToMainPage(param) {
  RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rCategory);
}

function routeToEditPage(categoryCode, startDate, param) {
  RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rCategoryEdit + Belt_Option.mapWithDefault(categoryCode, "", (function (categoryCode) {
              return "/" + categoryCode;
            })) + Belt_Option.mapWithDefault(startDate, "", (function (startDate) {
              return "/" + Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(startDate), "");
            })));
}

export {
  routeToMainPage ,
  routeToEditPage ,
}
/* RescriptReactRouter Not a pure module */
