// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../common/components/App_Modal.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Alert.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Messages_Orders_Correction$DvmAdminFrontendFr from "../../../../../intl/messages/orders/Messages_Orders_Correction.bs.js";
import * as Order_Detail_Old_Vehicle_Edits_Inputs$DvmAdminFrontendFr from "./Order_Detail_Old_Vehicle_Edits_Inputs.bs.js";

function Order_Detail_Old_Vehicle_Edits_Modal(Props) {
  var onClose = Props.onClose;
  var orderId = Props.orderId;
  var order = Props.order;
  var reloadPage = Props.reloadPage;
  var newestUsedCarValues = Props.newestUsedCarValues;
  var addNewOpt = Props.addNew;
  var addNew = addNewOpt !== undefined ? addNewOpt : false;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var saveResult = match[0];
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(reloadPage, undefined);
            Curry._1(onClose, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(addNew ? Messages_Orders_Correction$DvmAdminFrontendFr.appendCarInfo : Messages_Orders_Detail$DvmAdminFrontendFr.editVO),
              maxWidth: "1000px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult),
                    children: React.createElement(Order_Detail_Old_Vehicle_Edits_Inputs$DvmAdminFrontendFr.make, {
                          orderId: orderId,
                          setSaveResult: match[1],
                          newestUsedCarValues: newestUsedCarValues,
                          order: order
                        })
                  })
            });
}

var Types;

var make = Order_Detail_Old_Vehicle_Edits_Modal;

export {
  Types ,
  make ,
}
/* react Not a pure module */
