// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../../../common/components/icons/App_Icon.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../../intl/Intl_Utils.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../common/App_Typography.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../../../../context/user/User_Types_Utils.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../../common/styles/App_Styles_Common.bs.js";
import * as Order_Detail_Error_Comment$DvmAdminFrontendFr from "../../Order_Detail_Error_Comment.bs.js";
import * as Order_Detail_Types_Comment$DvmAdminFrontendFr from "../../../Order_Detail_Types_Comment.bs.js";

function Order_Detail_Bonus_Derogate_Footer(Props) {
  var dero = Props.dero;
  var order = Props.order;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var selectedRole = match[0].selectedRole;
  var errors = dero.errors;
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        style: {
                          fontWeight: "600"
                        },
                        children: React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              spacing: 1
                            }, React.createElement(Core.Grid, {
                                  children: Belt_Option.mapWithDefault(dero.date, "", (function (param) {
                                          return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                        })) + (
                                    selectedRole !== /* Dealer */7 || !User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole) ? " - " + Belt_Option.getWithDefault(dero.editor, "") : ""
                                  ),
                                  item: true
                                }), Belt_Option.mapWithDefault(dero.comment, null, (function (x) {
                                    if (x.length > 0) {
                                      return React.createElement(Core.Grid, {
                                                  children: React.createElement(Core.Tooltip, {
                                                        arrow: true,
                                                        children: React.createElement("div", undefined, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                  size: /* Large */3,
                                                                  type_: /* Information */43
                                                                })),
                                                        classes: {
                                                          tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
                                                        },
                                                        placement: "bottom",
                                                        title: Belt_Option.getWithDefault(dero.comment, "-")
                                                      }),
                                                  item: true
                                                });
                                    } else {
                                      return null;
                                    }
                                  }))),
                        variant: "body"
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: errors !== undefined ? Belt_Array.map(Belt_List.toArray(errors), (function (error) {
                            return React.createElement(Order_Detail_Error_Comment$DvmAdminFrontendFr.make, {
                                        comment: error,
                                        order: order,
                                        key: Order_Detail_Types_Comment$DvmAdminFrontendFr.toString(error.errorId)
                                      });
                          })) : null,
                  item: true
                }));
}

var make = Order_Detail_Bonus_Derogate_Footer;

export {
  make ,
}
/* react Not a pure module */
