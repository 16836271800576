// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../api/RestApi.bs.js";
import * as Grid_Types$DvmAdminFrontendFr from "../../Grid_Types.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";

function fetchSearch(submissionCallbacks, userManager, setResult, queryParams, tableType, selectedRole, withClosedModels) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var tmp;
  switch (tableType) {
    case /* Model */0 :
        tmp = "/margins/model" + queryParams + "";
        break;
    case /* Version */1 :
        tmp = "/margins/modelversion" + queryParams + "";
        break;
    case /* GridID */2 :
        tmp = "/margins/avs" + queryParams + "";
        break;
    
  }
  RestApi$DvmAdminFrontendFr.call("GET", Grid_Types$DvmAdminFrontendFr.Margins.searchResult_decode, undefined, userManager, selectedRole, tmp + ("&withClosedModels=" + (
            withClosedModels ? "true" : "false"
          ) + "")).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var modelsTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: modelsTable
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchMarginSearch */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function getCategories(alert, userManager, setCategories, selectedRole) {
  RestApi$DvmAdminFrontendFr.call("GET", Grid_Types$DvmAdminFrontendFr.gridTreeList_decode, undefined, userManager, selectedRole, "/margins/avs/dropdown/categories").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var categories = result._0;
          return Curry._1(setCategories, (function (param) {
                        return categories;
                      }));
        }
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: result._0
                  }
                }));
        return Curry._1(setCategories, (function (param) {
                      return /* [] */0;
                    }));
      });
}

var Form;

export {
  Form ,
  fetchSearch ,
  getCategories ,
}
/* react Not a pure module */
