// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Json$DvmAdminFrontendFr from "../types/App_Types_Json.bs.js";
import * as Messages_File_Upload$DvmAdminFrontendFr from "../../intl/messages/Messages_File_Upload.bs.js";
import * as Quota_PARC_Manage_Types$DvmAdminFrontendFr from "../../pages/quotaPARC/admin/Quota_PARC_Manage_Types.bs.js";

function excelInfo_encode(v) {
  return Js_dict.fromArray([
              [
                "excelColumns",
                Decco.listToJson(Decco.stringToJson, v.excelColumns)
              ],
              [
                "tableColumns",
                Decco.listToJson(Decco.stringToJson, v.tableColumns)
              ],
              [
                "preview",
                Decco.listToJson((function (param) {
                        return Decco.listToJson((function (param) {
                                      return Decco.optionToJson(Decco.stringToJson, param);
                                    }), param);
                      }), v.preview)
              ],
              [
                "sheet",
                Decco.stringToJson(v.sheet)
              ]
            ]);
}

function excelInfo_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var excelColumns = Decco.listFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "excelColumns"), null));
  if (excelColumns.TAG === /* Ok */0) {
    var tableColumns = Decco.listFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "tableColumns"), null));
    if (tableColumns.TAG === /* Ok */0) {
      var preview = Decco.listFromJson((function (param) {
              return Decco.listFromJson((function (param) {
                            return Decco.optionFromJson(Decco.stringFromJson, param);
                          }), param);
            }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "preview"), null));
      if (preview.TAG === /* Ok */0) {
        var sheet = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "sheet"), null));
        if (sheet.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    excelColumns: excelColumns._0,
                    tableColumns: tableColumns._0,
                    preview: preview._0,
                    sheet: sheet._0
                  }
                };
        }
        var e = sheet._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".sheet" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = preview._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".preview" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = tableColumns._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".tableColumns" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = excelColumns._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".excelColumns" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function mappedPairs_encode(v) {
  return Js_dict.fromArray([
              [
                "tableHeader",
                Decco.stringToJson(v.tableHeader)
              ],
              [
                "excelHeader",
                Decco.stringToJson(v.excelHeader)
              ]
            ]);
}

function mappedPairs_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var tableHeader = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "tableHeader"), null));
  if (tableHeader.TAG === /* Ok */0) {
    var excelHeader = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "excelHeader"), null));
    if (excelHeader.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                tableHeader: tableHeader._0,
                excelHeader: excelHeader._0
              }
            };
    }
    var e = excelHeader._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".excelHeader" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = tableHeader._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".tableHeader" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function mappedPairsList_encode(v) {
  return Decco.listToJson(mappedPairs_encode, v);
}

function mappedPairsList_decode(v) {
  return Decco.listFromJson(mappedPairs_decode, v);
}

function toString(status) {
  switch (status) {
    case /* ERROR */0 :
        return "ERROR";
    case /* WARN */1 :
        return "WARN";
    case /* WARN_CONFIRM */2 :
        return "WARN_CONFIRM";
    case /* UNDEFINED */3 :
        return "";
    
  }
}

function fromString(status) {
  switch (status) {
    case "ERROR" :
        return /* ERROR */0;
    case "WARN" :
        return /* WARN */1;
    case "WARN_CONFIRM" :
        return /* WARN_CONFIRM */2;
    default:
      return /* UNDEFINED */3;
  }
}

var Decco$1 = App_Types_Json$DvmAdminFrontendFr.MakeStringableJsonType({
      toString: toString,
      fromString: fromString
    });

var ErrorLevel = {
  toString: toString,
  fromString: fromString,
  Decco: Decco$1
};

function toString$1(status) {
  if (status) {
    return "SP_CONTRACT_ATTACHMENTS";
  } else {
    return "SP_PARC_QUOTAS";
  }
}

function fromString$1(status) {
  switch (status) {
    case "SP_CONTRACT_ATTACHMENTS" :
        return /* ATTACHMENTS */1;
    case "SP_PARC_QUOTAS" :
        return /* QUOTAS */0;
    default:
      return ;
  }
}

var Decco$2 = App_Types_Json$DvmAdminFrontendFr.MakeStringableJsonType({
      toString: toString$1,
      fromString: fromString$1
    });

var SelectedTable = {
  toString: toString$1,
  fromString: fromString$1,
  Decco: Decco$2
};

function errorItem_encode(v) {
  return Js_dict.fromArray([
              [
                "column",
                Decco.optionToJson(Decco.intToJson, v.column)
              ],
              [
                "errorLevel",
                Curry._1(Decco$1.t_encode, v.errorLevel)
              ],
              [
                "errorType",
                Decco.optionToJson(Decco.stringToJson, v.errorType)
              ],
              [
                "value",
                Decco.optionToJson(Decco.stringToJson, v.value)
              ]
            ]);
}

function errorItem_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var column = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "column"), null));
  if (column.TAG === /* Ok */0) {
    var errorLevel = Curry._1(Decco$1.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "errorLevel"), null));
    if (errorLevel.TAG === /* Ok */0) {
      var errorType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "errorType"), null));
      if (errorType.TAG === /* Ok */0) {
        var value = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "value"), null));
        if (value.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    column: column._0,
                    errorLevel: errorLevel._0,
                    errorType: errorType._0,
                    value: value._0
                  }
                };
        }
        var e = value._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".value" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = errorType._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".errorType" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = errorLevel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".errorLevel" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = column._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".column" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function resultRow_encode(v) {
  return Js_dict.fromArray([
              [
                "errors",
                Decco.listToJson(errorItem_encode, v.errors)
              ],
              [
                "rowNumber",
                Decco.intToJson(v.rowNumber)
              ],
              [
                "rowValues",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(Decco.stringToJson, param);
                      }), v.rowValues)
              ]
            ]);
}

function resultRow_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var errors = Decco.listFromJson(errorItem_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "errors"), null));
  if (errors.TAG === /* Ok */0) {
    var rowNumber = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "rowNumber"), null));
    if (rowNumber.TAG === /* Ok */0) {
      var rowValues = Decco.optionFromJson((function (param) {
              return Decco.listFromJson(Decco.stringFromJson, param);
            }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "rowValues"), null));
      if (rowValues.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  errors: errors._0,
                  rowNumber: rowNumber._0,
                  rowValues: rowValues._0
                }
              };
      }
      var e = rowValues._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".rowValues" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = rowNumber._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".rowNumber" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = errors._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".errors" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function primaryKeyItem_encode(v) {
  return Js_dict.fromArray([
              [
                "primaryKeyName",
                Decco.stringToJson(v.primaryKeyName)
              ],
              [
                "values",
                Decco.listToJson(Decco.stringToJson, v.values)
              ]
            ]);
}

function primaryKeyItem_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var primaryKeyName = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "primaryKeyName"), null));
  if (primaryKeyName.TAG === /* Ok */0) {
    var values = Decco.listFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "values"), null));
    if (values.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                primaryKeyName: primaryKeyName._0,
                values: values._0
              }
            };
    }
    var e = values._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".values" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = primaryKeyName._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".primaryKeyName" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function validateResult_encode(v) {
  return Js_dict.fromArray([
              [
                "results",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(resultRow_encode, param);
                      }), v.results)
              ],
              [
                "cellRange",
                Decco.optionToJson(Decco.stringToJson, v.cellRange)
              ],
              [
                "expirationToken",
                Decco.optionToJson(Decco.stringToJson, v.expirationToken)
              ],
              [
                "primaryKeys",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(primaryKeyItem_encode, param);
                      }), v.primaryKeys)
              ],
              [
                "errorDescriptionRows",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(Decco.stringToJson, param);
                      }), v.errorDescriptionRows)
              ],
              [
                "corruptedObjects",
                Decco.optionToJson(Quota_PARC_Manage_Types$DvmAdminFrontendFr.quotaPARCList_encode, v.corruptedObjects)
              ]
            ]);
}

function validateResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var results = Decco.optionFromJson((function (param) {
          return Decco.listFromJson(resultRow_decode, param);
        }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "results"), null));
  if (results.TAG === /* Ok */0) {
    var cellRange = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "cellRange"), null));
    if (cellRange.TAG === /* Ok */0) {
      var expirationToken = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "expirationToken"), null));
      if (expirationToken.TAG === /* Ok */0) {
        var primaryKeys = Decco.optionFromJson((function (param) {
                return Decco.listFromJson(primaryKeyItem_decode, param);
              }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "primaryKeys"), null));
        if (primaryKeys.TAG === /* Ok */0) {
          var errorDescriptionRows = Decco.optionFromJson((function (param) {
                  return Decco.listFromJson(Decco.stringFromJson, param);
                }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "errorDescriptionRows"), null));
          if (errorDescriptionRows.TAG === /* Ok */0) {
            var corruptedObjects = Decco.optionFromJson(Quota_PARC_Manage_Types$DvmAdminFrontendFr.quotaPARCList_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "corruptedObjects"), null));
            if (corruptedObjects.TAG === /* Ok */0) {
              return {
                      TAG: /* Ok */0,
                      _0: {
                        results: results._0,
                        cellRange: cellRange._0,
                        expirationToken: expirationToken._0,
                        primaryKeys: primaryKeys._0,
                        errorDescriptionRows: errorDescriptionRows._0,
                        corruptedObjects: corruptedObjects._0
                      }
                    };
            }
            var e = corruptedObjects._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".corruptedObjects" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = errorDescriptionRows._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".errorDescriptionRows" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = primaryKeys._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".primaryKeys" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = expirationToken._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".expirationToken" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = cellRange._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".cellRange" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = results._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".results" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function confirmBody_encode(v) {
  return Js_dict.fromArray([
              [
                "mapping",
                Decco.listToJson(mappedPairs_encode, v.mapping)
              ],
              [
                "primaryKeys",
                Decco.listToJson(primaryKeyItem_encode, v.primaryKeys)
              ],
              [
                "extraData",
                Decco.optionToJson(Decco.stringToJson, v.extraData)
              ]
            ]);
}

function confirmBody_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var mapping = Decco.listFromJson(mappedPairs_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "mapping"), null));
  if (mapping.TAG === /* Ok */0) {
    var primaryKeys = Decco.listFromJson(primaryKeyItem_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "primaryKeys"), null));
    if (primaryKeys.TAG === /* Ok */0) {
      var extraData = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "extraData"), null));
      if (extraData.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  mapping: mapping._0,
                  primaryKeys: primaryKeys._0,
                  extraData: extraData._0
                }
              };
      }
      var e = extraData._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".extraData" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = primaryKeys._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".primaryKeys" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = mapping._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".mapping" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function statusResult_encode(v) {
  return Js_dict.fromArray([
              [
                "progressId",
                Decco.optionToJson(Decco.stringToJson, v.progressId)
              ],
              [
                "status",
                Decco.stringToJson(v.status)
              ]
            ]);
}

function statusResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var progressId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "progressId"), null));
  if (progressId.TAG === /* Ok */0) {
    var status = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "status"), null));
    if (status.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                progressId: progressId._0,
                status: status._0
              }
            };
    }
    var e = status._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".status" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = progressId._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".progressId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function errorToMessage(status, intl) {
  return Belt_Option.mapWithDefault(status, "", (function (status) {
                var tmp;
                switch (status) {
                  case "EMPTY_FILE" :
                      tmp = Messages_File_Upload$DvmAdminFrontendFr.emptyFile;
                      break;
                  case "ERR_DATES_MISMATCH" :
                      tmp = Messages_File_Upload$DvmAdminFrontendFr.datesMismatch;
                      break;
                  case "ERR_EMPTY_CELL" :
                      tmp = Messages_File_Upload$DvmAdminFrontendFr.emptyCell;
                      break;
                  case "ERR_INVALID_CELL_VALUE" :
                      tmp = Messages_File_Upload$DvmAdminFrontendFr.invalidCellValue;
                      break;
                  case "ERR_PICKLIST_MISSING" :
                      tmp = Messages_File_Upload$DvmAdminFrontendFr.picklistMissing;
                      break;
                  case "ERR_PK_DUPLICITY" :
                      tmp = Messages_File_Upload$DvmAdminFrontendFr.pkDuplicity;
                      break;
                  case "ERR_UNSPECIFIED_UPLOAD" :
                      tmp = Messages_File_Upload$DvmAdminFrontendFr.unspecifiedUpload;
                      break;
                  case "WARN_OUTDATED_RECORD" :
                      tmp = Messages_File_Upload$DvmAdminFrontendFr.parcQuotaDateOlderWarn;
                      break;
                  default:
                    tmp = Messages_File_Upload$DvmAdminFrontendFr.unknownError;
                }
                return intl.formatMessage(tmp);
              }));
}

export {
  excelInfo_encode ,
  excelInfo_decode ,
  mappedPairs_encode ,
  mappedPairs_decode ,
  mappedPairsList_encode ,
  mappedPairsList_decode ,
  ErrorLevel ,
  SelectedTable ,
  errorItem_encode ,
  errorItem_decode ,
  resultRow_encode ,
  resultRow_decode ,
  primaryKeyItem_encode ,
  primaryKeyItem_decode ,
  validateResult_encode ,
  validateResult_decode ,
  confirmBody_encode ,
  confirmBody_decode ,
  statusResult_encode ,
  statusResult_decode ,
  errorToMessage ,
}
/* Decco Not a pure module */
