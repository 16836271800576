// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../common/components/button/App_Button.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../../../common/components/App_LabeledText.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as Messages_Quotas$DvmAdminFrontendFr from "../../../../../intl/messages/quotas/Messages_Quotas.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../common/components/App_OverlaySpinner.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as Quota_Detail_Attachment_Single_Form$DvmAdminFrontendFr from "./Quota_Detail_Attachment_Single_Form.bs.js";

function Quota_Detail_Attachment_Single_Modal(Props) {
  var openOpt = Props.open;
  var parentStartDate = Props.parentStartDate;
  var parentEndDate = Props.parentEndDate;
  var showSpinnerOpt = Props.showSpinner;
  var onClose = Props.onClose;
  var onSubmit = Props.onSubmit;
  var row = Props.row;
  var open = openOpt !== undefined ? openOpt : true;
  var showSpinner = showSpinnerOpt !== undefined ? showSpinnerOpt : false;
  var intl = ReactIntl.useIntl();
  var form = Quota_Detail_Attachment_Single_Form$DvmAdminFrontendFr.DatesEditForm.useForm(Quota_Detail_Attachment_Single_Form$DvmAdminFrontendFr.initialInput, {
        parentStartDate: parentStartDate,
        parentEndDate: parentEndDate,
        consumed: Belt_Option.getWithDefault(row.consumed, 0)
      }, Curry.__2(onSubmit));
  React.useEffect((function () {
          var __x = Caml_option.some(row.attachmentStartDate);
          Curry._2(form.updateStartDate, (function (input, startDate) {
                  return {
                          startDate: startDate,
                          endDate: input.endDate,
                          volume: input.volume
                        };
                }), __x);
          var __x$1 = Caml_option.some(row.attachmentEndDate);
          Curry._2(form.updateEndDate, (function (input, endDate) {
                  return {
                          startDate: input.startDate,
                          endDate: endDate,
                          volume: input.volume
                        };
                }), __x$1);
          var __x$2 = String(row.volume);
          Curry._2(form.updateVolume, (function (input, volume) {
                  return {
                          startDate: input.startDate,
                          endDate: input.endDate,
                          volume: volume
                        };
                }), __x$2);
        }), [
        row.attachmentStartDate,
        row.attachmentEndDate,
        row.volume
      ]);
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    startDate: startDate,
                    endDate: input.endDate,
                    volume: input.volume
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
    onBlur: (function (param) {
        Curry._1(form.blurStartDate, undefined);
      })
  };
  if (form.startDateResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  var tmp$1 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    startDate: input.startDate,
                    endDate: endDate,
                    volume: input.volume
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
    onBlur: (function (param) {
        Curry._1(form.blurEndDate, undefined);
      })
  };
  if (form.endDateResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  var tmp$2 = {
    onChange: Curry._1(form.updateVolume, (function (input, volume) {
            return {
                    startDate: input.startDate,
                    endDate: input.endDate,
                    volume: volume
                  };
          })),
    value: form.input.volume,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
    label: Messages_Quotas_Detail$DvmAdminFrontendFr.volume,
    inputType: "number"
  };
  if (form.volumeResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.volumeResult);
  }
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: open,
              onClose: onClose,
              title: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.quotaEditAttachment),
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: showSpinner,
                    children: React.createElement("form", {
                          onSubmit: (function ($$event) {
                              $$event.preventDefault();
                              Curry._1(form.submit, undefined);
                            })
                        }, React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              direction: "column",
                              spacing: 2
                            }, React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  item: true,
                                  spacing: 2
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                            label: Messages_Common$DvmAdminFrontendFr.siret,
                                            children: row.siret
                                          }),
                                      item: true,
                                      sm: Grid$Mui.Sm[3]
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                            label: Messages_Common$DvmAdminFrontendFr.companyName,
                                            children: Belt_Option.getWithDefault(row.companyName, "")
                                          }),
                                      item: true,
                                      sm: Grid$Mui.Sm[5]
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                            label: Messages_Common$DvmAdminFrontendFr.dealerID,
                                            children: row.dealerId
                                          }),
                                      item: true,
                                      sm: Grid$Mui.Sm[2]
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                            label: Messages_Quotas$DvmAdminFrontendFr.consumedTotal,
                                            children: Belt_Option.getWithDefault(row.consumed, 0)
                                          }),
                                      item: true,
                                      sm: Grid$Mui.Sm[2]
                                    })), React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  item: true,
                                  spacing: 2
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$1),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$2),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Grid, {
                                            children: React.createElement(Core.Grid, {
                                                  children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                        label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                                        size: "medium",
                                                        type_: "submit"
                                                      }),
                                                  item: true
                                                }),
                                            container: true,
                                            justify: "flex-end"
                                          }),
                                      item: true
                                    }))))
                  })
            });
}

var Form;

var DateWithCheckbox;

var make = Quota_Detail_Attachment_Single_Modal;

export {
  Form ,
  DateWithCheckbox ,
  make ,
}
/* react Not a pure module */
