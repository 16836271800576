// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";

var validators_modelPhase = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var modelPhase = param.modelPhase;
      var tmp = modelPhase === "" ? undefined : modelPhase;
      return {
              TAG: /* Ok */0,
              _0: tmp
            };
    })
};

var validators_engineType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var engineType = param.engineType;
      var tmp = engineType === "" ? undefined : engineType;
      return {
              TAG: /* Ok */0,
              _0: tmp
            };
    })
};

var validators_versionLabel = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var versionLabel = param.versionLabel;
      var tmp = versionLabel === "" ? undefined : versionLabel;
      return {
              TAG: /* Ok */0,
              _0: tmp
            };
    })
};

var validators_version = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var version = param.version;
      var tmp = version === "" ? undefined : version;
      return {
              TAG: /* Ok */0,
              _0: tmp
            };
    })
};

var validators_model = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var model = param.model;
      var tmp = model === "" ? undefined : model;
      return {
              TAG: /* Ok */0,
              _0: tmp
            };
    })
};

var validators_brand = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var brand = param.brand;
      var tmp = brand === "" ? undefined : brand;
      return {
              TAG: /* Ok */0,
              _0: tmp
            };
    })
};

var validators = {
  modelPhase: validators_modelPhase,
  engineType: validators_engineType,
  versionLabel: validators_versionLabel,
  version: validators_version,
  model: validators_model,
  brand: validators_brand
};

function initialFieldsStatuses(_input) {
  return {
          modelPhase: /* Pristine */0,
          engineType: /* Pristine */0,
          versionLabel: /* Pristine */0,
          version: /* Pristine */0,
          model: /* Pristine */0,
          brand: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.modelPhase;
  var match_0 = match ? match._0 : Curry._1(validators.modelPhase.validate, input);
  var match$1 = fieldsStatuses.engineType;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.engineType.validate, input);
  var match$2 = fieldsStatuses.versionLabel;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.versionLabel.validate, input);
  var match$3 = fieldsStatuses.version;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.version.validate, input);
  var match$4 = fieldsStatuses.model;
  var match_0$4 = match$4 ? match$4._0 : Curry._1(validators.model.validate, input);
  var match$5 = fieldsStatuses.brand;
  var match_0$5 = match$5 ? match$5._0 : Curry._1(validators.brand.validate, input);
  var modelPhaseResult = match_0;
  var modelPhaseResult$1;
  if (modelPhaseResult.TAG === /* Ok */0) {
    var engineTypeResult = match_0$1;
    if (engineTypeResult.TAG === /* Ok */0) {
      var versionLabelResult = match_0$2;
      if (versionLabelResult.TAG === /* Ok */0) {
        var versionResult = match_0$3;
        if (versionResult.TAG === /* Ok */0) {
          var modelResult = match_0$4;
          if (modelResult.TAG === /* Ok */0) {
            var brandResult = match_0$5;
            if (brandResult.TAG === /* Ok */0) {
              return {
                      TAG: /* Valid */0,
                      output: {
                        brand: brandResult._0,
                        model: modelResult._0,
                        version: versionResult._0,
                        versionLabel: versionLabelResult._0,
                        engineType: engineTypeResult._0,
                        modelPhase: modelPhaseResult._0
                      },
                      fieldsStatuses: {
                        modelPhase: /* Dirty */{
                          _0: modelPhaseResult,
                          _1: /* Shown */0
                        },
                        engineType: /* Dirty */{
                          _0: engineTypeResult,
                          _1: /* Shown */0
                        },
                        versionLabel: /* Dirty */{
                          _0: versionLabelResult,
                          _1: /* Shown */0
                        },
                        version: /* Dirty */{
                          _0: versionResult,
                          _1: /* Shown */0
                        },
                        model: /* Dirty */{
                          _0: modelResult,
                          _1: /* Shown */0
                        },
                        brand: /* Dirty */{
                          _0: brandResult,
                          _1: /* Shown */0
                        }
                      },
                      collectionsStatuses: undefined
                    };
            }
            modelPhaseResult$1 = modelPhaseResult;
          } else {
            modelPhaseResult$1 = modelPhaseResult;
          }
        } else {
          modelPhaseResult$1 = modelPhaseResult;
        }
      } else {
        modelPhaseResult$1 = modelPhaseResult;
      }
    } else {
      modelPhaseResult$1 = modelPhaseResult;
    }
  } else {
    modelPhaseResult$1 = modelPhaseResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            modelPhase: /* Dirty */{
              _0: modelPhaseResult$1,
              _1: /* Shown */0
            },
            engineType: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            versionLabel: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            version: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            model: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            brand: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurModelPhaseField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.modelPhase, validators_modelPhase, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  modelPhase: status,
                                  engineType: init.engineType,
                                  versionLabel: init.versionLabel,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEngineTypeField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.engineType, validators_engineType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  modelPhase: init.modelPhase,
                                  engineType: status,
                                  versionLabel: init.versionLabel,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionLabelField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.versionLabel, validators_versionLabel, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  modelPhase: init.modelPhase,
                                  engineType: init.engineType,
                                  versionLabel: status,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.version, validators_version, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  modelPhase: init.modelPhase,
                                  engineType: init.engineType,
                                  versionLabel: init.versionLabel,
                                  version: status,
                                  model: init.model,
                                  brand: init.brand
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurModelField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.model, validators_model, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  modelPhase: init.modelPhase,
                                  engineType: init.engineType,
                                  versionLabel: init.versionLabel,
                                  version: init.version,
                                  model: status,
                                  brand: init.brand
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBrandField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.brand, validators_brand, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  modelPhase: init.modelPhase,
                                  engineType: init.engineType,
                                  versionLabel: init.versionLabel,
                                  version: init.version,
                                  model: init.model,
                                  brand: status
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */6 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */6,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */7,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */9);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */8);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */7 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */8 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */9 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateModelPhaseField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.modelPhase, state.submissionStatus, validators_modelPhase, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            modelPhase: status,
                                            engineType: init.engineType,
                                            versionLabel: init.versionLabel,
                                            version: init.version,
                                            model: init.model,
                                            brand: init.brand
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEngineTypeField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.engineType, state.submissionStatus, validators_engineType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            modelPhase: init.modelPhase,
                                            engineType: status,
                                            versionLabel: init.versionLabel,
                                            version: init.version,
                                            model: init.model,
                                            brand: init.brand
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionLabelField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.versionLabel, state.submissionStatus, validators_versionLabel, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            modelPhase: init.modelPhase,
                                            engineType: init.engineType,
                                            versionLabel: status,
                                            version: init.version,
                                            model: init.model,
                                            brand: init.brand
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.version, state.submissionStatus, validators_version, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            modelPhase: init.modelPhase,
                                            engineType: init.engineType,
                                            versionLabel: init.versionLabel,
                                            version: status,
                                            model: init.model,
                                            brand: init.brand
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateModelField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.model, state.submissionStatus, validators_model, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            modelPhase: init.modelPhase,
                                            engineType: init.engineType,
                                            versionLabel: init.versionLabel,
                                            version: init.version,
                                            model: status,
                                            brand: init.brand
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBrandField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.brand, state.submissionStatus, validators_brand, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            modelPhase: init.modelPhase,
                                            engineType: init.engineType,
                                            versionLabel: init.versionLabel,
                                            version: init.version,
                                            model: init.model,
                                            brand: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */6 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */7 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */8 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateModelPhase: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelPhaseField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEngineType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEngineTypeField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVersionLabel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionLabelField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVersion: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateModel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBrand: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBrandField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurModelPhase: (function (param) {
              Curry._1(dispatch, /* BlurModelPhaseField */0);
            }),
          blurEngineType: (function (param) {
              Curry._1(dispatch, /* BlurEngineTypeField */1);
            }),
          blurVersionLabel: (function (param) {
              Curry._1(dispatch, /* BlurVersionLabelField */2);
            }),
          blurVersion: (function (param) {
              Curry._1(dispatch, /* BlurVersionField */3);
            }),
          blurModel: (function (param) {
              Curry._1(dispatch, /* BlurModelField */4);
            }),
          blurBrand: (function (param) {
              Curry._1(dispatch, /* BlurBrandField */5);
            }),
          modelPhaseResult: Formality.exposeFieldResult(state.fieldsStatuses.modelPhase),
          engineTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.engineType),
          versionLabelResult: Formality.exposeFieldResult(state.fieldsStatuses.versionLabel),
          versionResult: Formality.exposeFieldResult(state.fieldsStatuses.version),
          modelResult: Formality.exposeFieldResult(state.fieldsStatuses.model),
          brandResult: Formality.exposeFieldResult(state.fieldsStatuses.brand),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.modelPhase || match.engineType || match.versionLabel || match.version || match.model || match.brand) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */6);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */7);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */8);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */8,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */9);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  brand: "",
  model: "",
  version: "",
  versionLabel: "",
  engineType: "",
  modelPhase: ""
};

export {
  TableFilterForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
