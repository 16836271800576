// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as App_Types_Text$DvmAdminFrontendFr from "../common/types/App_Types_Text.bs.js";

function render(intl, message) {
  return React.createElement(Core.Grid, {
              children: React.createElement(Core.Typography, {
                    children: App_Types_Text$DvmAdminFrontendFr.toString(intl, message),
                    variant: "body2"
                  }),
              item: true,
              xs: Grid$Mui.Xs[12]
            });
}

function SimpleAlert(Props) {
  var message = Props.message;
  var intl = ReactIntl.useIntl();
  return React.createElement(Core.Grid, {
              children: message !== undefined ? render(intl, message) : null,
              container: true,
              justifyContent: "center"
            });
}

var make = SimpleAlert;

export {
  render ,
  make ,
}
/* react Not a pure module */
