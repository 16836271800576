// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as App_Table$DvmAdminFrontendFr from "../../../common/components/table/App_Table.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../intl/Intl_Utils.bs.js";
import * as Messages_Gemo$DvmAdminFrontendFr from "../../../intl/messages/gemo/Messages_Gemo.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Gemo_Delete_Modal$DvmAdminFrontendFr from "../edit/Gemo_Delete_Modal.bs.js";
import * as Gemo_Search_Types$DvmAdminFrontendFr from "./Gemo_Search_Types.bs.js";
import * as Gemo_AddEdit_Modal$DvmAdminFrontendFr from "../edit/Gemo_AddEdit_Modal.bs.js";
import * as Messages_Submarket$DvmAdminFrontendFr from "../../../intl/messages/gemo/Messages_Submarket.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Pagination.bs.js";
import * as App_Table_Export_Menu$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Export_Menu.bs.js";
import * as App_Table_Create_Button$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Create_Button.bs.js";

function actionButtons(tableRow, setModal, fetchRequest, fetchData) {
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              wrap: "nowrap"
            }, React.createElement(Core.Grid, {
                  children: React.createElement(Core.Button, {
                        onClick: (function (param) {
                            Curry._1(setModal, (function (param) {
                                    return React.createElement(Gemo_AddEdit_Modal$DvmAdminFrontendFr.make, {
                                                onClose: (function (param) {
                                                    Curry._1(setModal, (function (param) {
                                                            return null;
                                                          }));
                                                  }),
                                                operation: /* Update */{
                                                  _0: tableRow
                                                },
                                                fetchRequest: fetchRequest,
                                                fetchData: fetchData
                                              });
                                  }));
                          }),
                        children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                              size: /* Large */3,
                              type_: /* Edit */60
                            }),
                        key: "edit"
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Core.Button, {
                        onClick: (function (param) {
                            Curry._1(setModal, (function (param) {
                                    return React.createElement(Gemo_Delete_Modal$DvmAdminFrontendFr.make, {
                                                tableRow: tableRow,
                                                onClose: (function (param) {
                                                    Curry._1(setModal, (function (param) {
                                                            return null;
                                                          }));
                                                  }),
                                                fetchRequest: fetchRequest,
                                                fetchData: fetchData
                                              });
                                  }));
                          }),
                        children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                              size: /* Large */3,
                              type_: /* Trash */38,
                              color: "red"
                            }),
                        key: "delete"
                      }),
                  item: true
                }));
}

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Gemo_Search_Types$DvmAdminFrontendFr.Fields.gemoCode, undefined, undefined, undefined, undefined, undefined, Messages_Gemo$DvmAdminFrontendFr.gemo);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Gemo_Search_Types$DvmAdminFrontendFr.Fields.gemoLabel, undefined, undefined, undefined, undefined, undefined, Messages_Gemo$DvmAdminFrontendFr.gemoLabel),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Gemo_Search_Types$DvmAdminFrontendFr.Fields.budgetActionType, undefined, undefined, undefined, undefined, undefined, Messages_Submarket$DvmAdminFrontendFr.budgetActionType),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Gemo_Search_Types$DvmAdminFrontendFr.Fields.modelId, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.model),
      tl: {
        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Gemo_Search_Types$DvmAdminFrontendFr.Fields.submarketCode, undefined, undefined, undefined, undefined, undefined, Messages_Gemo$DvmAdminFrontendFr.submarketLabel),
        tl: {
          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Gemo_Search_Types$DvmAdminFrontendFr.Fields.startDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.startDate),
          tl: {
            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Gemo_Search_Types$DvmAdminFrontendFr.Fields.endDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.endDate),
            tl: /* [] */0
          }
        }
      }
    }
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function Gemo_Search_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return null;
      });
  var setModal = match[1];
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (row) {
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, Caml_option.some(actionButtons(row, setModal, fetchRequest, fetchData)), undefined, Belt_List.mapWithIndex({
                                                  hd: row.gemoCode,
                                                  tl: {
                                                    hd: Belt_Option.getWithDefault(row.gemoLabel, ""),
                                                    tl: {
                                                      hd: Belt_Option.getWithDefault(row.budgetActionType, ""),
                                                      tl: {
                                                        hd: Belt_Option.getWithDefault(row.modelId, ""),
                                                        tl: {
                                                          hd: Belt_Option.getWithDefault(row.submarketCode, ""),
                                                          tl: {
                                                            hd: Belt_Option.mapWithDefault(row.startDate, "", (function (param) {
                                                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                  })),
                                                            tl: {
                                                              hd: Belt_Option.mapWithDefault(row.endDate, "", (function (param) {
                                                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                    })),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._2(fetchData, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._2(fetchData, undefined, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, {
                  headers: fetchRequest.headers,
                  handleSorting: handleSorting,
                  tablePage: convertTable(tablePage),
                  paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined),
                  rowButton: {
                    TAG: /* Left */0,
                    _0: /* EmptyCell */0
                  },
                  exportMenu: React.createElement(Core.Grid, {
                        children: null,
                        container: true,
                        item: true,
                        justifyContent: "flex-end",
                        spacing: 1
                      }, React.createElement(Core.Grid, {
                            children: React.createElement(App_Table_Create_Button$DvmAdminFrontendFr.make, {
                                  onClick: (function (param) {
                                      Curry._1(setModal, (function (param) {
                                              return React.createElement(Gemo_AddEdit_Modal$DvmAdminFrontendFr.make, {
                                                          onClose: (function (param) {
                                                              Curry._1(setModal, (function (param) {
                                                                      return null;
                                                                    }));
                                                            }),
                                                          operation: /* Create */0,
                                                          fetchRequest: fetchRequest,
                                                          fetchData: fetchData
                                                        });
                                            }));
                                    }),
                                  message: Messages_Gemo$DvmAdminFrontendFr.newGemo
                                }),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(App_Table_Export_Menu$DvmAdminFrontendFr.make, {
                                  query: Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                                        page: 0,
                                        rowsPerPage: 150000,
                                        headers: fetchRequest.headers,
                                        filterQueryParams: fetchRequest.filterQueryParams,
                                        additionalQueryParams: fetchRequest.additionalQueryParams
                                      }),
                                  endpoint: "gemo"
                                }),
                            item: true
                          }))
                }), match[0]);
}

var Api;

var make = Gemo_Search_Table;

export {
  Api ,
  actionButtons ,
  headersConfiguration ,
  make ,
}
/* headersConfiguration Not a pure module */
