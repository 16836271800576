// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../common/App_Typography.bs.js";
import * as Flash_Edit_Api$DvmAdminFrontendFr from "../../../../../flash/edit/Flash_Edit_Api.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../../common/components/App_OverlaySpinner.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_SelectFormInput.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as App_RadioFormInput_YesNo$DvmAdminFrontendFr from "../../../../../../common/components/input/App_RadioFormInput_YesNo.bs.js";
import * as Messages_Orders_Derogate$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_Derogate.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Order_Detail_Bonus_Derogate_Utils$DvmAdminFrontendFr from "./Order_Detail_Bonus_Derogate_Utils.bs.js";

function roundFloat($$float, precision) {
  return Belt_Option.getWithDefault(Belt_Float.fromString($$float.toFixed(precision)), 0.0);
}

function Order_Detail_Bonus_Derogate_Inputs_NonFleet(Props) {
  var action = Props.action;
  var form = Props.form;
  var actionIdOptions = Props.actionIdOptions;
  var editedDero = Props.editedDero;
  var actionFetch = Props.actionFetch;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setVATResult = match$2[1];
  var vatResult = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var setAmountWithVAT = match$3[1];
  var amountWithVAT = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var setVatFocus = match$4[1];
  var vatFocus = match$4[0];
  React.useEffect((function () {
          Flash_Edit_Api$DvmAdminFrontendFr.fetchVAT(userManager, setVATResult, selectedRole);
          Order_Detail_Bonus_Derogate_Utils$DvmAdminFrontendFr.deroLabelExistingBonus(editedDero, action, form);
        }), []);
  React.useEffect((function () {
          Order_Detail_Bonus_Derogate_Utils$DvmAdminFrontendFr.deroLabelNewBonus(editedDero, form, actionFetch);
        }), [actionFetch]);
  React.useEffect((function () {
          if (vatFocus) {
            var vatFloat = App_Types_Result$DvmAdminFrontendFr.mapWithDefault(vatResult, undefined, (function (vat) {
                    return Belt_Option.mapWithDefault(vat.response, undefined, Belt_Float.fromString);
                  }));
            Belt_Option.mapWithDefault(vatFloat, undefined, (function (vatFloat) {
                    Curry._2(form.updateAmount, (function (input, amount) {
                            return {
                                    negative: input.negative,
                                    action: input.action,
                                    actionId: input.actionId,
                                    derogateLabel: input.derogateLabel,
                                    amount: amount,
                                    comment: input.comment,
                                    validation: input.validation
                                  };
                          }), String(roundFloat(Belt_Option.getWithDefault(Belt_Float.fromString(amountWithVAT), 0.0) / (vatFloat / 100.0 + 1.0), 2)));
                  }));
          }
          
        }), [amountWithVAT]);
  React.useEffect((function () {
          if (!vatFocus) {
            var vatFloat = App_Types_Result$DvmAdminFrontendFr.mapWithDefault(vatResult, undefined, (function (vat) {
                    return Belt_Option.mapWithDefault(vat.response, undefined, Belt_Float.fromString);
                  }));
            Belt_Option.mapWithDefault(vatFloat, undefined, (function (vatFloat) {
                    Belt_Option.mapWithDefault(Belt_Float.fromString(form.input.amount), undefined, (function (value) {
                            Curry._1(setAmountWithVAT, (function (param) {
                                    return String(roundFloat(value * (vatFloat / 100.0 + 1.0), 2));
                                  }));
                          }));
                  }));
          }
          
        }), [
        form.input.amount,
        vatResult
      ]);
  var tmp;
  if (Belt_Option.isSome(action) || Belt_Option.isSome(editedDero)) {
    tmp = null;
  } else {
    var tmp$1 = {
      label: Messages_Orders_Derogate$DvmAdminFrontendFr.derogateIdAction,
      value: {
        TAG: /* Single */0,
        _0: {
          value: form.input.actionId,
          onChange: Curry._1(form.updateActionId, (function (input, actionId) {
                  return {
                          negative: input.negative,
                          action: input.action,
                          actionId: actionId,
                          derogateLabel: input.derogateLabel,
                          amount: input.amount,
                          comment: input.comment,
                          validation: input.validation
                        };
                }))
        }
      },
      options: {
        TAG: /* Labeled */1,
        _0: Belt_List.map(actionIdOptions, (function (item) {
                return {
                        label: {
                          TAG: /* String */3,
                          _0: "" + item.paramOne + " - " + Belt_Option.getWithDefault(item.paramTwo, "") + ""
                        },
                        value: item.paramOne
                      };
              }))
      },
      disabled: Belt_Option.isSome(action),
      required: /* HtmlRequired */0,
      fullWidth: true
    };
    if (form.actionIdResult !== undefined) {
      tmp$1.validationResult = Caml_option.valFromOption(form.actionIdResult);
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Core.Box, {
              children: React.createElement(Core.Grid, {
                    children: React.createElement(Core.Grid, {
                          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$1),
                          item: true,
                          xs: Grid$Mui.Xs[12]
                        }),
                    container: true,
                    item: true,
                    justifyContent: "space-between",
                    spacing: 4
                  }),
              mt: 2
            }));
  }
  var tmp$2 = {
    onChange: Curry._1(form.updateAmount, (function (input, amount) {
            return {
                    negative: input.negative,
                    action: input.action,
                    actionId: input.actionId,
                    derogateLabel: input.derogateLabel,
                    amount: amount,
                    comment: input.comment,
                    validation: input.validation
                  };
          })),
    value: form.input.amount,
    fullWidth: true,
    label: Messages_Flash_Edit$DvmAdminFrontendFr.bonusAmountHT,
    required: /* HtmlRequired */0,
    InputProps: {
      startAdornment: React.createElement(Core.InputAdornment, {
            children: "€",
            position: "start"
          })
    },
    inputType: "number"
  };
  if (form.amountResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.amountResult);
  }
  var tmp$3 = {
    onChange: Curry._1(form.updateDerogateLabel, (function (input, derogateLabel) {
            return {
                    negative: input.negative,
                    action: input.action,
                    actionId: input.actionId,
                    derogateLabel: derogateLabel,
                    amount: input.amount,
                    comment: input.comment,
                    validation: input.validation
                  };
          })),
    value: form.input.derogateLabel,
    fullWidth: true,
    label: Messages_Orders_Derogate$DvmAdminFrontendFr.derogateIdLabel,
    required: /* HtmlRequired */0
  };
  if (form.derogateLabelResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.derogateLabelResult);
  }
  return React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
              show: App_Types_Result$DvmAdminFrontendFr.isPending(vatResult),
              children: null
            }, React.createElement(Core.Box, {
                  children: React.createElement(App_RadioFormInput_YesNo$DvmAdminFrontendFr.make, {
                        label: Messages_Orders_Derogate$DvmAdminFrontendFr.derogateSignLabel,
                        yesLabel: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.negative),
                        noLabel: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.positive),
                        value: form.input.negative,
                        onChange: Curry._1(form.updateNegative, (function (input, negative) {
                                return {
                                        negative: negative,
                                        action: input.action,
                                        actionId: input.actionId,
                                        derogateLabel: input.derogateLabel,
                                        amount: input.amount,
                                        comment: input.comment,
                                        validation: input.validation
                                      };
                              }))
                      }),
                  mt: 2
                }), tmp, React.createElement(Core.Box, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        gutterBottom: true,
                        style: {
                          fontWeight: "600"
                        },
                        children: intl.formatMessage(Messages_Orders_Derogate$DvmAdminFrontendFr.derogateInfoLabel),
                        variant: "h5"
                      }),
                  mt: 2
                }), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  item: true,
                  justifyContent: "space-between",
                  spacing: 4
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$2),
                      item: true,
                      xs: Grid$Mui.Xs[6]
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: (function (val) {
                                Curry._1(setAmountWithVAT, (function (param) {
                                        return val;
                                      }));
                              }),
                            value: amountWithVAT,
                            onBlur: (function (param) {
                                Curry._1(setVatFocus, (function (param) {
                                        return false;
                                      }));
                              }),
                            fullWidth: true,
                            label: Messages_Flash_Edit$DvmAdminFrontendFr.bonusAmountTTC,
                            InputProps: {
                              startAdornment: React.createElement(Core.InputAdornment, {
                                    children: "€",
                                    position: "start"
                                  })
                            },
                            inputType: "number",
                            onFocus: (function (param) {
                                Curry._1(setVatFocus, (function (param) {
                                        return true;
                                      }));
                              })
                          }),
                      item: true,
                      xs: Grid$Mui.Xs[6]
                    })), React.createElement(Core.Grid, {
                  children: React.createElement(Core.Grid, {
                        children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$3),
                        item: true,
                        xs: Grid$Mui.Xs[12]
                      }),
                  container: true,
                  item: true,
                  justifyContent: "space-between",
                  spacing: 4
                }), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  item: true,
                  justifyContent: "space-between",
                  spacing: 4
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Orders_Derogate$DvmAdminFrontendFr.validation,
                            options: {
                              hd: {
                                label: {
                                  TAG: /* Message */0,
                                  _0: Messages_Common$DvmAdminFrontendFr.nothing
                                },
                                value: ""
                              },
                              tl: {
                                hd: {
                                  label: {
                                    TAG: /* String */3,
                                    _0: "Accord CDG"
                                  },
                                  value: "Accord CDG"
                                },
                                tl: {
                                  hd: {
                                    label: {
                                      TAG: /* String */3,
                                      _0: "Accord DRF"
                                    },
                                    value: "Accord DRF"
                                  },
                                  tl: {
                                    hd: {
                                      label: {
                                        TAG: /* String */3,
                                        _0: "Accord DMF"
                                      },
                                      value: "Accord DMF"
                                    },
                                    tl: /* [] */0
                                  }
                                }
                              }
                            },
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.validation,
                                onChange: Curry._1(form.updateValidation, (function (input, validation) {
                                        return {
                                                negative: input.negative,
                                                action: input.action,
                                                actionId: input.actionId,
                                                derogateLabel: input.derogateLabel,
                                                amount: input.amount,
                                                comment: input.comment,
                                                validation: validation
                                              };
                                      }))
                              }
                            },
                            id: "validation",
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                          }),
                      item: true,
                      xs: Grid$Mui.Xs[3]
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry._1(form.updateComment, (function (input, comment) {
                                    return {
                                            negative: input.negative,
                                            action: input.action,
                                            actionId: input.actionId,
                                            derogateLabel: input.derogateLabel,
                                            amount: input.amount,
                                            comment: comment,
                                            validation: input.validation
                                          };
                                  })),
                            value: form.input.comment,
                            fullWidth: true,
                            label: Messages_Common$DvmAdminFrontendFr.comment
                          }),
                      item: true,
                      xs: Grid$Mui.Xs[9]
                    })));
}

var Form;

var InputStyles;

var make = Order_Detail_Bonus_Derogate_Inputs_NonFleet;

export {
  Form ,
  InputStyles ,
  roundFloat ,
  make ,
}
/* react Not a pure module */
