// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function getCurrentValues(order, lastCorrectionValues) {
  var match = Belt_Option.mapWithDefault(lastCorrectionValues, Belt_Option.mapWithDefault(order.usedCarDetail, [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined
          ], (function (x) {
              return [
                      x.value,
                      x.estimatedValue,
                      x.endOfLife,
                      x.brand,
                      x.model,
                      x.version,
                      x.registrationNumber,
                      x.vin,
                      x.firstReleaseDate,
                      x.registrationDate,
                      x.policeNumber
                    ];
            })), (function (lastCorr) {
          return [
                  lastCorr.realTradeAmount,
                  lastCorr.estimatedTradeAmount,
                  lastCorr.scrapyardIndicator,
                  lastCorr.brandUsedCar,
                  lastCorr.modelUsedCar,
                  lastCorr.versionUsedCar,
                  lastCorr.usedCarRegistrationID,
                  lastCorr.vinUsedCar,
                  lastCorr.usedCarFirstReleaseDate,
                  lastCorr.usedCarLastRegistrationDate,
                  lastCorr.usedCarPoliceNumber
                ];
        }));
  return {
          policeNumber: match[10],
          registrationNumber: match[6],
          registrationDate: match[9],
          brand: match[3],
          model: match[4],
          version: match[5],
          firstReleaseDate: match[8],
          vin: match[7],
          value: match[0],
          estimatedValue: match[1],
          endOfLife: match[2]
        };
}

function textToOption(text) {
  return Belt_Option.mapWithDefault(text, undefined, (function (x) {
                if (x.length === 0) {
                  return ;
                } else {
                  return x;
                }
              }));
}

function isVOEmpty(order, lastCorrectionValues) {
  var currVals = getCurrentValues(order, lastCorrectionValues);
  if (Belt_Option.isNone(textToOption(currVals.brand)) && Belt_Option.isNone(currVals.endOfLife) && Belt_Option.isNone(currVals.firstReleaseDate) && Belt_Option.isNone(textToOption(currVals.model)) && Belt_Option.isNone(textToOption(currVals.policeNumber)) && Belt_Option.isNone(currVals.registrationDate) && Belt_Option.isNone(textToOption(currVals.registrationNumber)) && Belt_Option.isNone(textToOption(currVals.version)) && Belt_Option.isNone(textToOption(currVals.vin)) && Belt_Option.mapWithDefault(currVals.value, true, (function (x) {
            return x === 0;
          }))) {
    return Belt_Option.mapWithDefault(currVals.estimatedValue, true, (function (x) {
                  return x === 0;
                }));
  } else {
    return false;
  }
}

export {
  getCurrentValues ,
  textToOption ,
  isVOEmpty ,
}
/* No side effect */
