// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Styles$DvmAdminFrontendFr from "../../../common/App_Styles.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../intl/Intl_Utils.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../common/components/App_LabeledText.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as Company_Detail_Styles$DvmAdminFrontendFr from "./Company_Detail_Styles.bs.js";
import * as Messages_Company_Detail$DvmAdminFrontendFr from "../../../intl/messages/company/Messages_Company_Detail.bs.js";

function Company_Detail_AppBar(Props) {
  var companyDetail = Props.companyDetail;
  var intl = ReactIntl.useIntl();
  var x = Belt_Option.getWithDefault(companyDetail.siretName, "-");
  var tmp = x === "" ? "-" : x;
  var x$1 = Belt_Option.getWithDefault(companyDetail.codeAPE, "-") + " " + Belt_Option.getWithDefault(companyDetail.descriptionAPE, "-");
  var tmp$1 = x$1 === "" ? "-" : x$1;
  return React.createElement(Core.AppBar, {
              children: React.createElement(Core.Grid, {
                    children: null,
                    className: Company_Detail_Styles$DvmAdminFrontendFr.titleBox,
                    container: true,
                    justifyContent: "space-evenly",
                    spacing: 1
                  }, React.createElement(Core.Grid, {
                        children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.siret,
                              children: companyDetail.siret
                            }),
                        item: true
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.companyName,
                              children: tmp
                            }),
                        item: true
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Company_Detail$DvmAdminFrontendFr.activity,
                              children: tmp$1
                            }),
                        item: true
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.endDate,
                              children: Belt_Option.mapWithDefault(companyDetail.endDate, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    }))
                            }),
                        item: true
                      })),
              className: App_Styles$DvmAdminFrontendFr.stickyBar
            });
}

var make = Company_Detail_AppBar;

export {
  make ,
}
/* react Not a pure module */
