// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";

var button = Css.css(Object.assign({}, {
          backgroundColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
          color: Theme_Colors$DvmAdminFrontendFr.white,
          fontWeight: "600",
          height: "48px",
          width: "60px"
        }, {
          "&:hover": {
            backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayMain
          }
        }));

function App_Filter_Button(Props) {
  var onClick = Props.onClick;
  var label = Props.label;
  var disabledOpt = Props.disabled;
  var type_Opt = Props.type_;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var type_ = type_Opt !== undefined ? type_Opt : "button";
  var tmp = {
    children: label,
    className: button,
    disabled: disabled,
    type: type_
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement(Core.Button, tmp);
}

var make = App_Filter_Button;

export {
  button ,
  make ,
}
/* button Not a pure module */
