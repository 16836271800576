// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Formality__Debouncer from "re-formality/src/Formality__Debouncer.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Contract_Attachments_Api$DvmAdminFrontendFr from "../Contract_Attachments_Api.bs.js";
import * as Messages_Contract_Create$DvmAdminFrontendFr from "../../../../../intl/messages/contract/Messages_Contract_Create.bs.js";
import * as Contract_Attachments_Utils$DvmAdminFrontendFr from "../Contract_Attachments_Utils.bs.js";

var validators = {
  attachments: {
    collection: (function (param, _metadata) {
        if (param.attachments.length !== 0) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Messages_Contract_Create$DvmAdminFrontendFr.noAttachment
                };
        }
      }),
    fields: {
      endDateAsContract: undefined,
      endDate: {
        strategy: /* OnFirstChange */1,
        validate: (function (param, at, param$1) {
            var endDateFields = Belt_Option.map(Belt_Array.get(param.attachments, at), (function (param) {
                    return [
                            param.startDate,
                            param.endDate,
                            param.endDateAsContract
                          ];
                  }));
            if (endDateFields !== undefined) {
              return Contract_Attachments_Utils$DvmAdminFrontendFr.validateAttachmentDates(endDateFields[0], endDateFields[1], param$1.contractStartDate, param$1.contractEndDate, endDateFields[2]);
            } else {
              return {
                      TAG: /* Error */1,
                      _0: Messages_Contract_Create$DvmAdminFrontendFr.noAttachment
                    };
            }
          })
      },
      startDate: {
        strategy: /* OnFirstChange */1,
        validate: (function (param, at, param$1) {
            var startDateFields = Belt_Option.map(Belt_Array.get(param.attachments, at), (function (param) {
                    return [
                            param.startDate,
                            param.siren
                          ];
                  }));
            if (startDateFields !== undefined) {
              return Contract_Attachments_Utils$DvmAdminFrontendFr.validateAttachmentStartDate(startDateFields[0], param$1.contractStartDate, param$1.contractEndDate, startDateFields[1]);
            } else {
              return {
                      TAG: /* Error */1,
                      _0: Messages_Contract_Create$DvmAdminFrontendFr.noAttachment
                    };
            }
          })
      },
      siren: {
        strategy: /* OnFirstChange */1,
        validate: (function (param, at, param$1) {
            var attachments = param.attachments;
            var siren = Belt_Option.map(Belt_Array.get(attachments, at), (function (param) {
                    return param.siren;
                  }));
            var match = Belt_Option.getWithDefault(siren, "");
            var match$1 = Belt_Array.some(Belt_Array.keepWithIndex(attachments, (function (param, index) {
                        return index !== at;
                      })), (function (param) {
                    var otherSiren = param.siren;
                    return Belt_Option.mapWithDefault(siren, false, (function (siren) {
                                  return siren === otherSiren;
                                }));
                  }));
            var match$2 = Belt_Option.mapWithDefault(param$1.contractSIREN, false, (function (x) {
                    return x === Belt_Option.getWithDefault(siren, "");
                  }));
            if (match === "") {
              return {
                      TAG: /* Ok */0,
                      _0: ""
                    };
            } else if (match$1 || match$2) {
              return {
                      TAG: /* Error */1,
                      _0: Messages_Contract_Create$DvmAdminFrontendFr.duplicateSiren
                    };
            } else {
              return {
                      TAG: /* Ok */0,
                      _0: match
                    };
            }
          }),
        validateAsync: Formality__Debouncer.make(700, (function (param) {
                var dispatch = param[3];
                var index = param[1];
                var value = param[0];
                var validate = function (siren, param) {
                  return Contract_Attachments_Api$DvmAdminFrontendFr.fetchSiren(param.userManager, param.contractId, param.setSirenNameResult, param.selectedRole, param.contractStartDate, param.contractEndDate, siren);
                };
                Formality.Async.validateAsyncWithMetadata(value, param[2], validate, (function (res) {
                        Curry._1(dispatch, {
                              TAG: /* ApplyAsyncResultForAttachmentSirenField */8,
                              _0: value,
                              _1: index,
                              _2: res
                            });
                      }));
              })),
        eq: (function (prim0, prim1) {
            return prim0 === prim1;
          })
      }
    }
  }
};

function initialFieldsStatuses(input) {
  return {
          attachments: Belt_Array.make(input.attachments.length, {
                endDateAsContract: /* Pristine */0,
                endDate: /* Pristine */0,
                startDate: /* Pristine */0,
                siren: /* Pristine */0
              })
        };
}

var initialCollectionsStatuses = {
  attachments: undefined
};

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: initialCollectionsStatuses,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match = Curry._2(validators.attachments.collection, input, metadata);
  var match$1 = Belt_Array.reduceWithIndex(fieldsStatuses.attachments, {
        NAME: "FieldsOfCollectionResult",
        VAL: [
          {
            TAG: /* Ok */0,
            _0: []
          },
          []
        ]
      }, (function (result, fieldStatus, index) {
          var match_0 = {
            TAG: /* Ok */0,
            _0: input.attachments[index].endDateAsContract
          };
          var match = fieldStatus.endDate;
          var match_0$1 = match ? match._0 : Curry._3(validators.attachments.fields.endDate.validate, input, index, metadata);
          var match$1 = fieldStatus.startDate;
          var match_0$2 = match$1 ? match$1._0 : Curry._3(validators.attachments.fields.startDate.validate, input, index, metadata);
          var value = fieldStatus.siren;
          var tmp;
          tmp = typeof value === "number" ? ({
                NAME: "Result",
                VAL: Curry._3(validators.attachments.fields.siren.validate, input, index, metadata)
              }) : (
              value.TAG === /* Dirty */0 ? ({
                    NAME: "Result",
                    VAL: value._0
                  }) : ({
                    NAME: "Validating",
                    VAL: value._0
                  })
            );
          var statuses;
          var endDateAsContractResult;
          var endDateAsContractResultVisibility;
          var endDateResult;
          var endDateResultVisibility;
          var startDateResult;
          var startDateResultVisibility;
          var sirenResult;
          var sirenResultVisibility;
          if (result.NAME === "ValidatingFieldsOfCollection") {
            statuses = result.VAL;
            endDateAsContractResult = match_0;
            endDateAsContractResultVisibility = /* Hidden */1;
            endDateResult = match_0$1;
            endDateResultVisibility = /* Shown */0;
            startDateResult = match_0$2;
            startDateResultVisibility = /* Shown */0;
            sirenResult = tmp;
            sirenResultVisibility = /* Shown */0;
          } else {
            var match$2 = result.VAL;
            var output = match$2[0];
            var exit = 0;
            if (output.TAG === /* Ok */0) {
              var endDateAsContractResult$1 = match_0;
              var statuses$1 = match$2[1];
              var output$1 = output._0;
              if (endDateAsContractResult$1.TAG === /* Ok */0) {
                var endDateResult$1 = match_0$1;
                if (endDateResult$1.TAG === /* Ok */0) {
                  var startDateResult$1 = match_0$2;
                  if (startDateResult$1.TAG === /* Ok */0) {
                    var match$3 = tmp;
                    if (typeof match$3 === "object" && match$3.NAME === "Result") {
                      var sirenResult$1 = match$3.VAL;
                      if (sirenResult$1.TAG === /* Ok */0) {
                        output$1.push({
                              siren: sirenResult$1._0,
                              startDate: startDateResult$1._0,
                              endDate: endDateResult$1._0,
                              endDateAsContract: endDateAsContractResult$1._0
                            });
                        statuses$1.push({
                              endDateAsContract: /* Dirty */{
                                _0: endDateAsContractResult$1,
                                _1: /* Hidden */1
                              },
                              endDate: /* Dirty */{
                                _0: endDateResult$1,
                                _1: /* Shown */0
                              },
                              startDate: /* Dirty */{
                                _0: startDateResult$1,
                                _1: /* Shown */0
                              },
                              siren: {
                                TAG: /* Dirty */0,
                                _0: sirenResult$1,
                                _1: /* Shown */0
                              }
                            });
                        return {
                                NAME: "FieldsOfCollectionResult",
                                VAL: [
                                  {
                                    TAG: /* Ok */0,
                                    _0: output$1
                                  },
                                  statuses$1
                                ]
                              };
                      }
                      exit = 2;
                    } else {
                      exit = 2;
                    }
                  } else {
                    exit = 2;
                  }
                } else {
                  exit = 2;
                }
              } else {
                exit = 2;
              }
            } else {
              exit = 2;
            }
            if (exit === 2) {
              var sirenResult$2 = tmp;
              var startDateResult$2 = match_0$2;
              var endDateResult$2 = match_0$1;
              var endDateAsContractResult$2 = match_0;
              var statuses$2 = match$2[1];
              var exit$1 = 0;
              if (typeof sirenResult$2 === "object" && sirenResult$2.NAME === "Validating") {
                statuses = statuses$2;
                endDateAsContractResult = endDateAsContractResult$2;
                endDateAsContractResultVisibility = /* Hidden */1;
                endDateResult = endDateResult$2;
                endDateResultVisibility = /* Shown */0;
                startDateResult = startDateResult$2;
                startDateResultVisibility = /* Shown */0;
                sirenResult = sirenResult$2;
                sirenResultVisibility = /* Shown */0;
              } else {
                exit$1 = 3;
              }
              if (exit$1 === 3) {
                statuses$2.push({
                      endDateAsContract: /* Dirty */{
                        _0: endDateAsContractResult$2,
                        _1: /* Hidden */1
                      },
                      endDate: /* Dirty */{
                        _0: endDateResult$2,
                        _1: /* Shown */0
                      },
                      startDate: /* Dirty */{
                        _0: startDateResult$2,
                        _1: /* Shown */0
                      },
                      siren: sirenResult$2.NAME === "Result" ? ({
                            TAG: /* Dirty */0,
                            _0: sirenResult$2.VAL,
                            _1: /* Shown */0
                          }) : ({
                            TAG: /* Validating */1,
                            _0: sirenResult$2.VAL
                          })
                    });
                return {
                        NAME: "FieldsOfCollectionResult",
                        VAL: [
                          {
                            TAG: /* Error */1,
                            _0: undefined
                          },
                          statuses$2
                        ]
                      };
              }
              
            }
            
          }
          statuses.push({
                endDateAsContract: /* Dirty */{
                  _0: endDateAsContractResult,
                  _1: endDateAsContractResultVisibility
                },
                endDate: /* Dirty */{
                  _0: endDateResult,
                  _1: endDateResultVisibility
                },
                startDate: /* Dirty */{
                  _0: startDateResult,
                  _1: startDateResultVisibility
                },
                siren: sirenResult.NAME === "Result" ? ({
                      TAG: /* Dirty */0,
                      _0: sirenResult.VAL,
                      _1: sirenResultVisibility
                    }) : ({
                      TAG: /* Validating */1,
                      _0: sirenResult.VAL
                    })
              });
          return {
                  NAME: "ValidatingFieldsOfCollection",
                  VAL: statuses
                };
        }));
  var exit = 0;
  if (typeof match$1 === "object") {
    if (match$1.NAME === "ValidatingFieldsOfCollection") {
      return {
              TAG: /* Validating */2,
              fieldsStatuses: {
                attachments: match$1.NAME === "ValidatingFieldsOfCollection" ? match$1.VAL : match$1.VAL[1]
              },
              collectionsStatuses: {
                attachments: match
              }
            };
    }
    exit = 2;
  } else {
    exit = 2;
  }
  if (exit === 2 && match.TAG === /* Ok */0 && typeof match$1 === "object" && match$1.NAME === "FieldsOfCollectionResult") {
    var match$2 = match$1.VAL;
    var attachments = match$2[0];
    if (attachments.TAG === /* Ok */0) {
      return {
              TAG: /* Valid */0,
              output: {
                attachments: attachments._0
              },
              fieldsStatuses: {
                attachments: match$2[1]
              },
              collectionsStatuses: {
                attachments: match
              }
            };
    }
    
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            attachments: match$1.VAL[1]
          },
          collectionsStatuses: {
            attachments: match
          }
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* Submit */0 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  switch (match$1.TAG | 0) {
                    case /* Valid */0 :
                        var output = match$1.output;
                        var error = state.formStatus;
                        var tmp;
                        tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : Caml_option.some(error._0);
                        return {
                                TAG: /* UpdateWithSideEffects */1,
                                _0: {
                                  input: state.input,
                                  fieldsStatuses: match$1.fieldsStatuses,
                                  collectionsStatuses: match$1.collectionsStatuses,
                                  formStatus: {
                                    TAG: /* Submitting */0,
                                    _0: tmp
                                  },
                                  submissionStatus: /* AttemptedToSubmit */1
                                },
                                _1: (function (param) {
                                    var dispatch = param.dispatch;
                                    Curry._2(onSubmit, output, {
                                          notifyOnSuccess: (function (input) {
                                              Curry._1(dispatch, {
                                                    TAG: /* SetSubmittedStatus */11,
                                                    _0: input
                                                  });
                                            }),
                                          notifyOnFailure: (function (error) {
                                              Curry._1(dispatch, {
                                                    TAG: /* SetSubmissionFailedStatus */12,
                                                    _0: error
                                                  });
                                            }),
                                          reset: (function (param) {
                                              Curry._1(dispatch, /* Reset */3);
                                            }),
                                          dismissSubmissionResult: (function (param) {
                                              Curry._1(dispatch, /* DismissSubmissionResult */2);
                                            })
                                        });
                                  })
                              };
                    case /* Invalid */1 :
                        return {
                                TAG: /* Update */0,
                                _0: {
                                  input: state.input,
                                  fieldsStatuses: match$1.fieldsStatuses,
                                  collectionsStatuses: match$1.collectionsStatuses,
                                  formStatus: /* Editing */0,
                                  submissionStatus: /* AttemptedToSubmit */1
                                }
                              };
                    case /* Validating */2 :
                        return {
                                TAG: /* Update */0,
                                _0: {
                                  input: state.input,
                                  fieldsStatuses: match$1.fieldsStatuses,
                                  collectionsStatuses: match$1.collectionsStatuses,
                                  formStatus: state.formStatus,
                                  submissionStatus: state.submissionStatus
                                }
                              };
                    
                  }
                  break;
              case /* DismissSubmissionError */1 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */2 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */3 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateAttachmentEndDateAsContractField */0 :
                  var index = action._1;
                  var nextInput = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses.contents.attachments, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput, index$p, metadata, item.startDate, validators.attachments.fields.startDate, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsContract: item.endDateAsContract,
                                                            endDate: item.endDate,
                                                            startDate: status,
                                                            siren: item.siren
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses.contents.attachments, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput, index$p, metadata, item.endDate, validators.attachments.fields.endDate, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsContract: item.endDateAsContract,
                                                            endDate: status,
                                                            startDate: item.startDate,
                                                            siren: item.siren
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput.attachments[index].endDateAsContract, (function (status) {
                                    return {
                                            attachments: Belt_Array.mapWithIndex(nextFieldsStatuses.contents.attachments, (function (idx_, item) {
                                                    if (idx_ === index) {
                                                      return {
                                                              endDateAsContract: status,
                                                              endDate: item.endDate,
                                                              startDate: item.startDate,
                                                              siren: item.siren
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAttachmentEndDateField */1 :
                  var index$1 = action._1;
                  var nextInput$1 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$1.contents.attachments, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$1, index$p, metadata, item.startDate, validators.attachments.fields.startDate, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$1.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsContract: item.endDateAsContract,
                                                            endDate: item.endDate,
                                                            startDate: status,
                                                            siren: item.siren
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$1.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidatorAndMetadata(nextInput$1, index$1, metadata, nextFieldsStatuses$1.contents.attachments[index$1].endDate, state.submissionStatus, validators.attachments.fields.endDate, (function (status) {
                                    return {
                                            attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$1.contents.attachments, (function (idx_, item) {
                                                    if (idx_ === index$1) {
                                                      return {
                                                              endDateAsContract: item.endDateAsContract,
                                                              endDate: status,
                                                              startDate: item.startDate,
                                                              siren: item.siren
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAttachmentStartDateField */2 :
                  var index$2 = action._1;
                  var nextInput$2 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$2 = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$2.contents.attachments, (function (index$p, item) {
                          var result = Formality.Async.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$2, index$p, metadata, item.siren, validators.attachments.fields.siren, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$2.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsContract: item.endDateAsContract,
                                                            endDate: item.endDate,
                                                            startDate: item.startDate,
                                                            siren: status
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$2.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$2.contents.attachments, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$2, index$p, metadata, item.endDate, validators.attachments.fields.endDate, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$2.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsContract: item.endDateAsContract,
                                                            endDate: status,
                                                            startDate: item.startDate,
                                                            siren: item.siren
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$2.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidatorAndMetadata(nextInput$2, index$2, metadata, nextFieldsStatuses$2.contents.attachments[index$2].startDate, state.submissionStatus, validators.attachments.fields.startDate, (function (status) {
                                    return {
                                            attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$2.contents.attachments, (function (idx_, item) {
                                                    if (idx_ === index$2) {
                                                      return {
                                                              endDateAsContract: item.endDateAsContract,
                                                              endDate: item.endDate,
                                                              startDate: status,
                                                              siren: item.siren
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAttachmentSirenField */3 :
                  var index$3 = action._1;
                  var nextInput$3 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$3 = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$3.contents.attachments, (function (index$p, item) {
                          if (index$3 === index$p) {
                            return ;
                          }
                          var result = Formality.Async.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$3, index$p, metadata, item.siren, validators.attachments.fields.siren, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$3.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsContract: item.endDateAsContract,
                                                            endDate: item.endDate,
                                                            startDate: item.startDate,
                                                            siren: status
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$3.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$3.contents.attachments, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$3, index$p, metadata, item.startDate, validators.attachments.fields.startDate, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$3.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsContract: item.endDateAsContract,
                                                            endDate: item.endDate,
                                                            startDate: status,
                                                            siren: item.siren
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$3.contents = result;
                            return ;
                          }
                          
                        }));
                  var nextFieldsStatuses$4 = Formality.Async.validateFieldOfCollectionOfStringTypeOnChangeInOnChangeModeWithMetadata(nextInput$3, index$3, metadata, nextFieldsStatuses$3.contents.attachments[index$3].siren, state.submissionStatus, validators.attachments.fields.siren, (function (status) {
                          return {
                                  attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$3.contents.attachments, (function (idx_, item) {
                                          if (idx_ === index$3) {
                                            return {
                                                    endDateAsContract: item.endDateAsContract,
                                                    endDate: item.endDate,
                                                    startDate: item.startDate,
                                                    siren: status
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  var value = nextFieldsStatuses$4.attachments[index$3].siren;
                  if (typeof value !== "number" && value.TAG !== /* Dirty */0) {
                    var value$1 = value._0;
                    return {
                            TAG: /* UpdateWithSideEffects */1,
                            _0: {
                              input: nextInput$3,
                              fieldsStatuses: nextFieldsStatuses$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            },
                            _1: (function (param) {
                                Curry._1(validators.attachments.fields.siren.validateAsync, [
                                      value$1,
                                      index$3,
                                      metadata,
                                      param.dispatch
                                    ]);
                              })
                          };
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: nextFieldsStatuses$4,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* BlurAttachmentEndDateAsContractField */4 :
                  var index$4 = action._0;
                  var result = Formality.validateFieldOnBlurWithoutValidator(state.input.attachments[index$4].endDateAsContract, state.fieldsStatuses.attachments[index$4].endDateAsContract, (function (status) {
                          return {
                                  attachments: Belt_Array.mapWithIndex(state.fieldsStatuses.attachments, (function (index$p, item) {
                                          if (index$p === index$4) {
                                            return {
                                                    endDateAsContract: status,
                                                    endDate: item.endDate,
                                                    startDate: item.startDate,
                                                    siren: item.siren
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAttachmentEndDateField */5 :
                  var index$5 = action._0;
                  var result$1 = Formality.validateFieldOfCollectionOnBlurWithValidatorAndMetadata(state.input, index$5, metadata, state.fieldsStatuses.attachments[index$5].endDate, validators.attachments.fields.endDate, (function (status) {
                          return {
                                  attachments: Belt_Array.mapWithIndex(state.fieldsStatuses.attachments, (function (index$p, item) {
                                          if (index$p === index$5) {
                                            return {
                                                    endDateAsContract: item.endDateAsContract,
                                                    endDate: status,
                                                    startDate: item.startDate,
                                                    siren: item.siren
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAttachmentStartDateField */6 :
                  var index$6 = action._0;
                  var result$2 = Formality.validateFieldOfCollectionOnBlurWithValidatorAndMetadata(state.input, index$6, metadata, state.fieldsStatuses.attachments[index$6].startDate, validators.attachments.fields.startDate, (function (status) {
                          return {
                                  attachments: Belt_Array.mapWithIndex(state.fieldsStatuses.attachments, (function (index$p, item) {
                                          if (index$p === index$6) {
                                            return {
                                                    endDateAsContract: item.endDateAsContract,
                                                    endDate: item.endDate,
                                                    startDate: status,
                                                    siren: item.siren
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAttachmentSirenField */7 :
                  var index$7 = action._0;
                  var result$3 = Formality.Async.validateFieldOfCollectionOfStringTypeOnBlurWithMetadata(state.input, index$7, metadata, state.fieldsStatuses.attachments[index$7].siren, validators.attachments.fields.siren, (function (status) {
                          return {
                                  attachments: Belt_Array.mapWithIndex(state.fieldsStatuses.attachments, (function (index$p, item) {
                                          if (index$p === index$7) {
                                            return {
                                                    endDateAsContract: item.endDateAsContract,
                                                    endDate: item.endDate,
                                                    startDate: item.startDate,
                                                    siren: status
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$3 === undefined) {
                    return /* NoUpdate */0;
                  }
                  var value$2 = result$3.attachments[index$7].siren;
                  if (typeof value$2 !== "number" && value$2.TAG !== /* Dirty */0) {
                    var value$3 = value$2._0;
                    return {
                            TAG: /* UpdateWithSideEffects */1,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            },
                            _1: (function (param) {
                                Curry._1(validators.attachments.fields.siren.validateAsync, [
                                      value$3,
                                      index$7,
                                      metadata,
                                      param.dispatch
                                    ]);
                              })
                          };
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: result$3,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
                  break;
              case /* ApplyAsyncResultForAttachmentSirenField */8 :
                  var result$4 = action._2;
                  var index$8 = action._1;
                  var validator = validators.attachments.fields.siren;
                  var x = state.fieldsStatuses.attachments[index$8].siren;
                  if (typeof x === "number" || x.TAG === /* Dirty */0 || !Curry._2(validator.eq, x._0, action._0)) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                attachments: Belt_Array.mapWithIndex(state.fieldsStatuses.attachments, (function (index$p, item) {
                                        if (index$p === index$8) {
                                          return {
                                                  endDateAsContract: item.endDateAsContract,
                                                  endDate: item.endDate,
                                                  startDate: item.startDate,
                                                  siren: {
                                                    TAG: /* Dirty */0,
                                                    _0: result$4,
                                                    _1: /* Shown */0
                                                  }
                                                };
                                        } else {
                                          return item;
                                        }
                                      }))
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* AddAttachmentEntry */9 :
                  var nextInput$4 = {
                    attachments: Belt_Array.concat(state.input.attachments, [action._0])
                  };
                  var nextFieldsStatuses$5 = {
                    contents: {
                      attachments: Belt_Array.concat(state.fieldsStatuses.attachments, [{
                              endDateAsContract: /* Pristine */0,
                              endDate: /* Pristine */0,
                              startDate: /* Pristine */0,
                              siren: /* Pristine */0
                            }])
                    }
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$5.contents.attachments, (function (index$p, item) {
                          var result = Formality.Async.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$4, index$p, metadata, item.siren, validators.attachments.fields.siren, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$5.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsContract: item.endDateAsContract,
                                                            endDate: item.endDate,
                                                            startDate: item.startDate,
                                                            siren: status
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$5.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$5.contents.attachments, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$4, index$p, metadata, item.startDate, validators.attachments.fields.startDate, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$5.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsContract: item.endDateAsContract,
                                                            endDate: item.endDate,
                                                            startDate: status,
                                                            siren: item.siren
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$5.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$5.contents.attachments, (function (index$p, item) {
                          var result = Formality.Async.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$4, index$p, metadata, item.siren, validators.attachments.fields.siren, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$5.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsContract: item.endDateAsContract,
                                                            endDate: item.endDate,
                                                            startDate: item.startDate,
                                                            siren: status
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$5.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$5.contents.attachments, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$4, index$p, metadata, item.endDate, validators.attachments.fields.endDate, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$5.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsContract: item.endDateAsContract,
                                                            endDate: status,
                                                            startDate: item.startDate,
                                                            siren: item.siren
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$5.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$5.contents.attachments, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$4, index$p, metadata, item.startDate, validators.attachments.fields.startDate, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$5.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsContract: item.endDateAsContract,
                                                            endDate: item.endDate,
                                                            startDate: status,
                                                            siren: item.siren
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$5.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$5.contents.attachments, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$4, index$p, metadata, item.startDate, validators.attachments.fields.startDate, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$5.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsContract: item.endDateAsContract,
                                                            endDate: item.endDate,
                                                            startDate: status,
                                                            siren: item.siren
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$5.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$5.contents.attachments, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$4, index$p, metadata, item.endDate, validators.attachments.fields.endDate, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$5.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsContract: item.endDateAsContract,
                                                            endDate: status,
                                                            startDate: item.startDate,
                                                            siren: item.siren
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$5.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: nextFieldsStatuses$5.contents,
                            collectionsStatuses: {
                              attachments: Curry._2(validators.attachments.collection, nextInput$4, metadata)
                            },
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* RemoveAttachmentEntry */10 :
                  var index$9 = action._0;
                  var nextInput$5 = {
                    attachments: Belt_Array.keepWithIndex(state.input.attachments, (function (param, i) {
                            return i !== index$9;
                          }))
                  };
                  var nextFieldsStatuses$6 = {
                    contents: {
                      attachments: Belt_Array.keepWithIndex(state.fieldsStatuses.attachments, (function (param, i) {
                              return i !== index$9;
                            }))
                    }
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$6.contents.attachments, (function (index$p, item) {
                          var result = Formality.Async.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$5, index$p, metadata, item.siren, validators.attachments.fields.siren, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$6.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsContract: item.endDateAsContract,
                                                            endDate: item.endDate,
                                                            startDate: item.startDate,
                                                            siren: status
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$6.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$6.contents.attachments, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$5, index$p, metadata, item.startDate, validators.attachments.fields.startDate, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$6.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsContract: item.endDateAsContract,
                                                            endDate: item.endDate,
                                                            startDate: status,
                                                            siren: item.siren
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$6.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$6.contents.attachments, (function (index$p, item) {
                          var result = Formality.Async.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$5, index$p, metadata, item.siren, validators.attachments.fields.siren, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$6.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsContract: item.endDateAsContract,
                                                            endDate: item.endDate,
                                                            startDate: item.startDate,
                                                            siren: status
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$6.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$6.contents.attachments, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$5, index$p, metadata, item.endDate, validators.attachments.fields.endDate, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$6.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsContract: item.endDateAsContract,
                                                            endDate: status,
                                                            startDate: item.startDate,
                                                            siren: item.siren
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$6.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$6.contents.attachments, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$5, index$p, metadata, item.startDate, validators.attachments.fields.startDate, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$6.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsContract: item.endDateAsContract,
                                                            endDate: item.endDate,
                                                            startDate: status,
                                                            siren: item.siren
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$6.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$6.contents.attachments, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$5, index$p, metadata, item.startDate, validators.attachments.fields.startDate, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$6.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsContract: item.endDateAsContract,
                                                            endDate: item.endDate,
                                                            startDate: status,
                                                            siren: item.siren
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$6.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$6.contents.attachments, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$5, index$p, metadata, item.endDate, validators.attachments.fields.endDate, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$6.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDateAsContract: item.endDateAsContract,
                                                            endDate: status,
                                                            startDate: item.startDate,
                                                            siren: item.siren
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$6.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: nextFieldsStatuses$6.contents,
                            collectionsStatuses: {
                              attachments: Curry._2(validators.attachments.collection, nextInput$5, metadata)
                            },
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */11 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */12 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */13 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Caml_option.some(Curry._1(map, Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateAttachmentEndDateAsContract: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAttachmentEndDateAsContractField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateAttachmentEndDate: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAttachmentEndDateField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateAttachmentStartDate: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAttachmentStartDateField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateAttachmentSiren: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAttachmentSirenField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          blurAttachmentEndDateAsContract: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurAttachmentEndDateAsContractField */4,
                    _0: index
                  });
            }),
          blurAttachmentEndDate: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurAttachmentEndDateField */5,
                    _0: index
                  });
            }),
          blurAttachmentStartDate: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurAttachmentStartDateField */6,
                    _0: index
                  });
            }),
          blurAttachmentSiren: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurAttachmentSirenField */7,
                    _0: index
                  });
            }),
          attachmentEndDateAsContractResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.attachments[index].endDateAsContract);
            }),
          attachmentEndDateResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.attachments[index].endDate);
            }),
          attachmentStartDateResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.attachments[index].startDate);
            }),
          attachmentSirenResult: (function (index) {
              return Formality.Async.exposeFieldResult(state.fieldsStatuses.attachments[index].siren);
            }),
          addAttachment: (function (entry) {
              Curry._1(dispatch, {
                    TAG: /* AddAttachmentEntry */9,
                    _0: entry
                  });
            }),
          removeAttachment: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* RemoveAttachmentEntry */10,
                    _0: index
                  });
            }),
          attachmentsResult: state.collectionsStatuses.attachments,
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              return Belt_Array.every(state.fieldsStatuses.attachments, (function (item) {
                            if (item.endDateAsContract || item.endDate || item.startDate || typeof item.siren !== "number") {
                              return true;
                            } else {
                              return false;
                            }
                          }));
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              switch (match.TAG | 0) {
                case /* Valid */0 :
                    return true;
                case /* Invalid */1 :
                    return false;
                case /* Validating */2 :
                    return ;
                
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */0);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */1);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */2);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */13,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */3);
            })
        };
}

var AttachmentsForm = {
  debounceInterval: 700,
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: initialCollectionsStatuses,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialInput = {
  attachments: []
};

var Api;

var initialAttachmentInput = {
  siren: "",
  startDate: undefined,
  endDate: undefined,
  endDateAsContract: true
};

export {
  Api ,
  AttachmentsForm ,
  initialInput ,
  initialAttachmentInput ,
}
/* validators Not a pure module */
