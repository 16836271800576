// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../common/utils/Utils_Form.bs.js";
import * as Grid_Edit_Utils$DvmAdminFrontendFr from "../Grid_Edit_Utils.bs.js";
import * as Messages_MarginGrid$DvmAdminFrontendFr from "../../../../intl/messages/marginGrid/Messages_MarginGrid.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Error_Inputs.bs.js";

var validators = {
  marginRows: {
    collection: (function (param, _metadata) {
        if (param.marginRows.length !== 0) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Messages_MarginGrid$DvmAdminFrontendFr.noEditRow
                };
        }
      }),
    fields: {
      versionOriginal: undefined,
      modelOriginal: undefined,
      budgetOriginal: undefined,
      toSave: undefined,
      isEdited: undefined,
      previousEndDate: undefined,
      followingStartDate: undefined,
      hasExceptionsOriginal: undefined,
      hasExceptions: {
        strategy: /* OnFirstChange */1,
        validate: (function (param, at, param$1) {
            if (!(param$1.whatGrid === /* ModelVersionGrid */0 && param$1.whatTable === /* Model */0)) {
              return {
                      TAG: /* Ok */0,
                      _0: false
                    };
            }
            var fields = Belt_Option.map(Belt_Array.get(param.marginRows, at), (function (param) {
                    return param.hasExceptions;
                  }));
            if (fields !== undefined) {
              return {
                      TAG: /* Ok */0,
                      _0: fields
                    };
            } else {
              return {
                      TAG: /* Error */1,
                      _0: Messages_MarginGrid$DvmAdminFrontendFr.noEditRow
                    };
            }
          })
      },
      endDateOriginal: undefined,
      endDate: {
        strategy: /* OnFirstChange */1,
        validate: (function (param, at, param$1) {
            var fields = Belt_Option.map(Belt_Array.get(param.marginRows, at), (function (param) {
                    return [
                            param.startDate,
                            param.endDate,
                            param.followingStartDate,
                            param.previousEndDate,
                            param.isEdited
                          ];
                  }));
            if (fields === undefined) {
              return {
                      TAG: /* Error */1,
                      _0: Messages_MarginGrid$DvmAdminFrontendFr.noEditRow
                    };
            }
            var startDate = fields[0];
            var value = Grid_Edit_Utils$DvmAdminFrontendFr.validateMarginDates(startDate, fields[1], fields[2], fields[3], "larger");
            if (value.TAG === /* Ok */0) {
              return {
                      TAG: /* Ok */0,
                      _0: value._0
                    };
            } else if (fields[4]) {
              return {
                      TAG: /* Error */1,
                      _0: value._0
                    };
            } else {
              return {
                      TAG: /* Ok */0,
                      _0: startDate
                    };
            }
          })
      },
      startDateOriginal: undefined,
      startDate: {
        strategy: /* OnFirstChange */1,
        validate: (function (param, at, param$1) {
            var fields = Belt_Option.map(Belt_Array.get(param.marginRows, at), (function (param) {
                    return [
                            param.startDate,
                            param.endDate,
                            param.followingStartDate,
                            param.previousEndDate,
                            param.isEdited
                          ];
                  }));
            if (fields === undefined) {
              return {
                      TAG: /* Error */1,
                      _0: Messages_MarginGrid$DvmAdminFrontendFr.noEditRow
                    };
            }
            var startDate = fields[0];
            var value = Grid_Edit_Utils$DvmAdminFrontendFr.validateMarginDates(startDate, fields[1], fields[2], fields[3], "smaller");
            if (value.TAG === /* Ok */0) {
              return {
                      TAG: /* Ok */0,
                      _0: value._0
                    };
            } else if (fields[4]) {
              return {
                      TAG: /* Error */1,
                      _0: value._0
                    };
            } else {
              return {
                      TAG: /* Ok */0,
                      _0: startDate
                    };
            }
          })
      },
      marginOriginal: undefined,
      margin: {
        strategy: /* OnFirstChange */1,
        validate: (function (param, at, param$1) {
            if (!param$1.whatGrid) {
              return {
                      TAG: /* Ok */0,
                      _0: undefined
                    };
            }
            var fields = Belt_Option.map(Belt_Array.get(param.marginRows, at), (function (param) {
                    return [
                            param.margin,
                            param.isEdited
                          ];
                  }));
            if (fields === undefined) {
              return {
                      TAG: /* Error */1,
                      _0: Messages_MarginGrid$DvmAdminFrontendFr.noEditRow
                    };
            }
            var margin = fields[0];
            if (fields[1]) {
              if (margin !== undefined) {
                if (margin >= 0) {
                  return {
                          TAG: /* Ok */0,
                          _0: margin
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldPositive
                        };
                }
              } else {
                return {
                        TAG: /* Error */1,
                        _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
                      };
              }
            } else {
              return {
                      TAG: /* Ok */0,
                      _0: margin
                    };
            }
          })
      },
      discountOriginal: undefined,
      discount: {
        strategy: /* OnFirstChange */1,
        validate: (function (param, at, param$1) {
            var fields = Belt_Option.map(Belt_Array.get(param.marginRows, at), (function (param) {
                    return [
                            param.discount,
                            param.isEdited
                          ];
                  }));
            if (fields === undefined) {
              return {
                      TAG: /* Error */1,
                      _0: Messages_MarginGrid$DvmAdminFrontendFr.noEditRow
                    };
            }
            var discount = fields[0];
            if (fields[1]) {
              if (discount !== undefined) {
                if (discount >= 0) {
                  return {
                          TAG: /* Ok */0,
                          _0: discount
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldPositive
                        };
                }
              } else {
                return {
                        TAG: /* Error */1,
                        _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
                      };
              }
            } else {
              return {
                      TAG: /* Ok */0,
                      _0: discount
                    };
            }
          })
      },
      acquisitionTypeOriginal: undefined,
      acquisitionType: {
        strategy: /* OnFirstChange */1,
        validate: (function (param, at, param$1) {
            if (param$1.whatGrid) {
              return {
                      TAG: /* Ok */0,
                      _0: ""
                    };
            }
            var fields = Belt_Option.map(Belt_Array.get(param.marginRows, at), (function (param) {
                    return [
                            param.acquisitionType,
                            param.isEdited,
                            param.budgetOriginal
                          ];
                  }));
            if (fields === undefined) {
              return {
                      TAG: /* Error */1,
                      _0: Messages_MarginGrid$DvmAdminFrontendFr.noEditRow
                    };
            }
            var acquisitionType = fields[0];
            switch (fields[2]) {
              case "LCD" :
              case "PROT" :
                  break;
              default:
                return {
                        TAG: /* Ok */0,
                        _0: ""
                      };
            }
            var value = Utils_Form$DvmAdminFrontendFr.validateRequired(acquisitionType);
            if (value.TAG === /* Ok */0) {
              return {
                      TAG: /* Ok */0,
                      _0: value._0
                    };
            } else if (fields[1]) {
              return {
                      TAG: /* Error */1,
                      _0: value._0
                    };
            } else {
              return {
                      TAG: /* Ok */0,
                      _0: acquisitionType
                    };
            }
          })
      },
      someIDOriginal: undefined,
      someID: undefined
    }
  }
};

function initialFieldsStatuses(input) {
  return {
          marginRows: Belt_Array.make(input.marginRows.length, {
                versionOriginal: /* Pristine */0,
                modelOriginal: /* Pristine */0,
                budgetOriginal: /* Pristine */0,
                toSave: /* Pristine */0,
                isEdited: /* Pristine */0,
                previousEndDate: /* Pristine */0,
                followingStartDate: /* Pristine */0,
                hasExceptionsOriginal: /* Pristine */0,
                hasExceptions: /* Pristine */0,
                endDateOriginal: /* Pristine */0,
                endDate: /* Pristine */0,
                startDateOriginal: /* Pristine */0,
                startDate: /* Pristine */0,
                marginOriginal: /* Pristine */0,
                margin: /* Pristine */0,
                discountOriginal: /* Pristine */0,
                discount: /* Pristine */0,
                acquisitionTypeOriginal: /* Pristine */0,
                acquisitionType: /* Pristine */0,
                someIDOriginal: /* Pristine */0,
                someID: /* Pristine */0
              })
        };
}

var initialCollectionsStatuses = {
  marginRows: undefined
};

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: initialCollectionsStatuses,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match = Curry._2(validators.marginRows.collection, input, metadata);
  var match$1 = Belt_Array.reduceWithIndex(fieldsStatuses.marginRows, [
        {
          TAG: /* Ok */0,
          _0: []
        },
        []
      ], (function (param, fieldStatus, index) {
          var statuses = param[1];
          var output = param[0];
          var match_0 = {
            TAG: /* Ok */0,
            _0: input.marginRows[index].versionOriginal
          };
          var match_0$1 = {
            TAG: /* Ok */0,
            _0: input.marginRows[index].modelOriginal
          };
          var match_0$2 = {
            TAG: /* Ok */0,
            _0: input.marginRows[index].budgetOriginal
          };
          var match_0$3 = {
            TAG: /* Ok */0,
            _0: input.marginRows[index].toSave
          };
          var match_0$4 = {
            TAG: /* Ok */0,
            _0: input.marginRows[index].isEdited
          };
          var match_0$5 = {
            TAG: /* Ok */0,
            _0: input.marginRows[index].previousEndDate
          };
          var match_0$6 = {
            TAG: /* Ok */0,
            _0: input.marginRows[index].followingStartDate
          };
          var match_0$7 = {
            TAG: /* Ok */0,
            _0: input.marginRows[index].hasExceptionsOriginal
          };
          var match = fieldStatus.hasExceptions;
          var match_0$8 = match ? match._0 : Curry._3(validators.marginRows.fields.hasExceptions.validate, input, index, metadata);
          var match_0$9 = {
            TAG: /* Ok */0,
            _0: input.marginRows[index].endDateOriginal
          };
          var match$1 = fieldStatus.endDate;
          var match_0$10 = match$1 ? match$1._0 : Curry._3(validators.marginRows.fields.endDate.validate, input, index, metadata);
          var match_0$11 = {
            TAG: /* Ok */0,
            _0: input.marginRows[index].startDateOriginal
          };
          var match$2 = fieldStatus.startDate;
          var match_0$12 = match$2 ? match$2._0 : Curry._3(validators.marginRows.fields.startDate.validate, input, index, metadata);
          var match_0$13 = {
            TAG: /* Ok */0,
            _0: input.marginRows[index].marginOriginal
          };
          var match$3 = fieldStatus.margin;
          var match_0$14 = match$3 ? match$3._0 : Curry._3(validators.marginRows.fields.margin.validate, input, index, metadata);
          var match_0$15 = {
            TAG: /* Ok */0,
            _0: input.marginRows[index].discountOriginal
          };
          var match$4 = fieldStatus.discount;
          var match_0$16 = match$4 ? match$4._0 : Curry._3(validators.marginRows.fields.discount.validate, input, index, metadata);
          var match_0$17 = {
            TAG: /* Ok */0,
            _0: input.marginRows[index].acquisitionTypeOriginal
          };
          var match$5 = fieldStatus.acquisitionType;
          var match_0$18 = match$5 ? match$5._0 : Curry._3(validators.marginRows.fields.acquisitionType.validate, input, index, metadata);
          var match_0$19 = {
            TAG: /* Ok */0,
            _0: input.marginRows[index].someIDOriginal
          };
          var match_0$20 = {
            TAG: /* Ok */0,
            _0: input.marginRows[index].someID
          };
          if (output.TAG === /* Ok */0) {
            var versionOriginalResult = match_0;
            var output$1 = output._0;
            if (versionOriginalResult.TAG === /* Ok */0) {
              var modelOriginalResult = match_0$1;
              if (modelOriginalResult.TAG === /* Ok */0) {
                var budgetOriginalResult = match_0$2;
                if (budgetOriginalResult.TAG === /* Ok */0) {
                  var toSaveResult = match_0$3;
                  if (toSaveResult.TAG === /* Ok */0) {
                    var isEditedResult = match_0$4;
                    if (isEditedResult.TAG === /* Ok */0) {
                      var previousEndDateResult = match_0$5;
                      if (previousEndDateResult.TAG === /* Ok */0) {
                        var followingStartDateResult = match_0$6;
                        if (followingStartDateResult.TAG === /* Ok */0) {
                          var hasExceptionsOriginalResult = match_0$7;
                          if (hasExceptionsOriginalResult.TAG === /* Ok */0) {
                            var hasExceptionsResult = match_0$8;
                            if (hasExceptionsResult.TAG === /* Ok */0) {
                              var endDateOriginalResult = match_0$9;
                              if (endDateOriginalResult.TAG === /* Ok */0) {
                                var endDateResult = match_0$10;
                                if (endDateResult.TAG === /* Ok */0) {
                                  var startDateOriginalResult = match_0$11;
                                  if (startDateOriginalResult.TAG === /* Ok */0) {
                                    var startDateResult = match_0$12;
                                    if (startDateResult.TAG === /* Ok */0) {
                                      var marginOriginalResult = match_0$13;
                                      if (marginOriginalResult.TAG === /* Ok */0) {
                                        var marginResult = match_0$14;
                                        if (marginResult.TAG === /* Ok */0) {
                                          var discountOriginalResult = match_0$15;
                                          if (discountOriginalResult.TAG === /* Ok */0) {
                                            var discountResult = match_0$16;
                                            if (discountResult.TAG === /* Ok */0) {
                                              var acquisitionTypeOriginalResult = match_0$17;
                                              if (acquisitionTypeOriginalResult.TAG === /* Ok */0) {
                                                var acquisitionTypeResult = match_0$18;
                                                if (acquisitionTypeResult.TAG === /* Ok */0) {
                                                  var someIDOriginalResult = match_0$19;
                                                  if (someIDOriginalResult.TAG === /* Ok */0) {
                                                    var someIDResult = match_0$20;
                                                    if (someIDResult.TAG === /* Ok */0) {
                                                      output$1.push({
                                                            someID: someIDResult._0,
                                                            someIDOriginal: someIDOriginalResult._0,
                                                            acquisitionType: acquisitionTypeResult._0,
                                                            acquisitionTypeOriginal: acquisitionTypeOriginalResult._0,
                                                            discount: discountResult._0,
                                                            discountOriginal: discountOriginalResult._0,
                                                            margin: marginResult._0,
                                                            marginOriginal: marginOriginalResult._0,
                                                            startDate: startDateResult._0,
                                                            startDateOriginal: startDateOriginalResult._0,
                                                            endDate: endDateResult._0,
                                                            endDateOriginal: endDateOriginalResult._0,
                                                            hasExceptions: hasExceptionsResult._0,
                                                            hasExceptionsOriginal: hasExceptionsOriginalResult._0,
                                                            followingStartDate: followingStartDateResult._0,
                                                            previousEndDate: previousEndDateResult._0,
                                                            isEdited: isEditedResult._0,
                                                            toSave: toSaveResult._0,
                                                            budgetOriginal: budgetOriginalResult._0,
                                                            modelOriginal: modelOriginalResult._0,
                                                            versionOriginal: versionOriginalResult._0
                                                          });
                                                      statuses.push({
                                                            versionOriginal: /* Dirty */{
                                                              _0: versionOriginalResult,
                                                              _1: /* Hidden */1
                                                            },
                                                            modelOriginal: /* Dirty */{
                                                              _0: modelOriginalResult,
                                                              _1: /* Hidden */1
                                                            },
                                                            budgetOriginal: /* Dirty */{
                                                              _0: budgetOriginalResult,
                                                              _1: /* Hidden */1
                                                            },
                                                            toSave: /* Dirty */{
                                                              _0: toSaveResult,
                                                              _1: /* Hidden */1
                                                            },
                                                            isEdited: /* Dirty */{
                                                              _0: isEditedResult,
                                                              _1: /* Hidden */1
                                                            },
                                                            previousEndDate: /* Dirty */{
                                                              _0: previousEndDateResult,
                                                              _1: /* Hidden */1
                                                            },
                                                            followingStartDate: /* Dirty */{
                                                              _0: followingStartDateResult,
                                                              _1: /* Hidden */1
                                                            },
                                                            hasExceptionsOriginal: /* Dirty */{
                                                              _0: hasExceptionsOriginalResult,
                                                              _1: /* Hidden */1
                                                            },
                                                            hasExceptions: /* Dirty */{
                                                              _0: hasExceptionsResult,
                                                              _1: /* Shown */0
                                                            },
                                                            endDateOriginal: /* Dirty */{
                                                              _0: endDateOriginalResult,
                                                              _1: /* Hidden */1
                                                            },
                                                            endDate: /* Dirty */{
                                                              _0: endDateResult,
                                                              _1: /* Shown */0
                                                            },
                                                            startDateOriginal: /* Dirty */{
                                                              _0: startDateOriginalResult,
                                                              _1: /* Hidden */1
                                                            },
                                                            startDate: /* Dirty */{
                                                              _0: startDateResult,
                                                              _1: /* Shown */0
                                                            },
                                                            marginOriginal: /* Dirty */{
                                                              _0: marginOriginalResult,
                                                              _1: /* Hidden */1
                                                            },
                                                            margin: /* Dirty */{
                                                              _0: marginResult,
                                                              _1: /* Shown */0
                                                            },
                                                            discountOriginal: /* Dirty */{
                                                              _0: discountOriginalResult,
                                                              _1: /* Hidden */1
                                                            },
                                                            discount: /* Dirty */{
                                                              _0: discountResult,
                                                              _1: /* Shown */0
                                                            },
                                                            acquisitionTypeOriginal: /* Dirty */{
                                                              _0: acquisitionTypeOriginalResult,
                                                              _1: /* Hidden */1
                                                            },
                                                            acquisitionType: /* Dirty */{
                                                              _0: acquisitionTypeResult,
                                                              _1: /* Shown */0
                                                            },
                                                            someIDOriginal: /* Dirty */{
                                                              _0: someIDOriginalResult,
                                                              _1: /* Hidden */1
                                                            },
                                                            someID: /* Dirty */{
                                                              _0: someIDResult,
                                                              _1: /* Hidden */1
                                                            }
                                                          });
                                                      return [
                                                              {
                                                                TAG: /* Ok */0,
                                                                _0: output$1
                                                              },
                                                              statuses
                                                            ];
                                                    }
                                                    
                                                  }
                                                  
                                                }
                                                
                                              }
                                              
                                            }
                                            
                                          }
                                          
                                        }
                                        
                                      }
                                      
                                    }
                                    
                                  }
                                  
                                }
                                
                              }
                              
                            }
                            
                          }
                          
                        }
                        
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }
            
          }
          statuses.push({
                versionOriginal: /* Dirty */{
                  _0: match_0,
                  _1: /* Hidden */1
                },
                modelOriginal: /* Dirty */{
                  _0: match_0$1,
                  _1: /* Hidden */1
                },
                budgetOriginal: /* Dirty */{
                  _0: match_0$2,
                  _1: /* Hidden */1
                },
                toSave: /* Dirty */{
                  _0: match_0$3,
                  _1: /* Hidden */1
                },
                isEdited: /* Dirty */{
                  _0: match_0$4,
                  _1: /* Hidden */1
                },
                previousEndDate: /* Dirty */{
                  _0: match_0$5,
                  _1: /* Hidden */1
                },
                followingStartDate: /* Dirty */{
                  _0: match_0$6,
                  _1: /* Hidden */1
                },
                hasExceptionsOriginal: /* Dirty */{
                  _0: match_0$7,
                  _1: /* Hidden */1
                },
                hasExceptions: /* Dirty */{
                  _0: match_0$8,
                  _1: /* Shown */0
                },
                endDateOriginal: /* Dirty */{
                  _0: match_0$9,
                  _1: /* Hidden */1
                },
                endDate: /* Dirty */{
                  _0: match_0$10,
                  _1: /* Shown */0
                },
                startDateOriginal: /* Dirty */{
                  _0: match_0$11,
                  _1: /* Hidden */1
                },
                startDate: /* Dirty */{
                  _0: match_0$12,
                  _1: /* Shown */0
                },
                marginOriginal: /* Dirty */{
                  _0: match_0$13,
                  _1: /* Hidden */1
                },
                margin: /* Dirty */{
                  _0: match_0$14,
                  _1: /* Shown */0
                },
                discountOriginal: /* Dirty */{
                  _0: match_0$15,
                  _1: /* Hidden */1
                },
                discount: /* Dirty */{
                  _0: match_0$16,
                  _1: /* Shown */0
                },
                acquisitionTypeOriginal: /* Dirty */{
                  _0: match_0$17,
                  _1: /* Hidden */1
                },
                acquisitionType: /* Dirty */{
                  _0: match_0$18,
                  _1: /* Shown */0
                },
                someIDOriginal: /* Dirty */{
                  _0: match_0$19,
                  _1: /* Hidden */1
                },
                someID: /* Dirty */{
                  _0: match_0$20,
                  _1: /* Hidden */1
                }
              });
          return [
                  {
                    TAG: /* Error */1,
                    _0: undefined
                  },
                  statuses
                ];
        }));
  if (match.TAG === /* Ok */0) {
    var marginRows = match$1[0];
    if (marginRows.TAG === /* Ok */0) {
      return {
              TAG: /* Valid */0,
              output: {
                marginRows: marginRows._0
              },
              fieldsStatuses: {
                marginRows: match$1[1]
              },
              collectionsStatuses: {
                marginRows: match
              }
            };
    }
    
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            marginRows: match$1[1]
          },
          collectionsStatuses: {
            marginRows: match
          }
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* Submit */0 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */44,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */45,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */3);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */2);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */1 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */2 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */3 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateSingleRowVersionOriginalField */0 :
                  var index = action._1;
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput.marginRows[index].versionOriginal, (function (status) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                                    if (index$p !== index) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.versionOriginal = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSingleRowModelOriginalField */1 :
                  var index$1 = action._1;
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$1.marginRows[index$1].modelOriginal, (function (status) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                                    if (index$p !== index$1) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.modelOriginal = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSingleRowBudgetOriginalField */2 :
                  var index$2 = action._1;
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$2.marginRows[index$2].budgetOriginal, (function (status) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                                    if (index$p !== index$2) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.budgetOriginal = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSingleRowToSaveField */3 :
                  var index$3 = action._1;
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$3.marginRows[index$3].toSave, (function (status) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                                    if (index$p !== index$3) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.toSave = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSingleRowIsEditedField */4 :
                  var index$4 = action._1;
                  var nextInput$4 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses.contents.marginRows, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$4, index$p, metadata, item.margin, validators.marginRows.fields.margin, (function (status) {
                                  return {
                                          marginRows: Belt_Array.mapWithIndex(nextFieldsStatuses.contents.marginRows, (function (idx_, item) {
                                                  if (idx_ !== index$p) {
                                                    return item;
                                                  }
                                                  var newrecord = Caml_obj.obj_dup(item);
                                                  newrecord.margin = status;
                                                  return newrecord;
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses.contents.marginRows, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$4, index$p, metadata, item.startDate, validators.marginRows.fields.startDate, (function (status) {
                                  return {
                                          marginRows: Belt_Array.mapWithIndex(nextFieldsStatuses.contents.marginRows, (function (idx_, item) {
                                                  if (idx_ !== index$p) {
                                                    return item;
                                                  }
                                                  var newrecord = Caml_obj.obj_dup(item);
                                                  newrecord.startDate = status;
                                                  return newrecord;
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses.contents.marginRows, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$4, index$p, metadata, item.endDate, validators.marginRows.fields.endDate, (function (status) {
                                  return {
                                          marginRows: Belt_Array.mapWithIndex(nextFieldsStatuses.contents.marginRows, (function (idx_, item) {
                                                  if (idx_ !== index$p) {
                                                    return item;
                                                  }
                                                  var newrecord = Caml_obj.obj_dup(item);
                                                  newrecord.endDate = status;
                                                  return newrecord;
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$4.marginRows[index$4].isEdited, (function (status) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(nextFieldsStatuses.contents.marginRows, (function (idx_, item) {
                                                    if (idx_ !== index$4) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.isEdited = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSingleRowPreviousEndDateField */5 :
                  var index$5 = action._1;
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$5.marginRows[index$5].previousEndDate, (function (status) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                                    if (index$p !== index$5) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.previousEndDate = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSingleRowFollowingStartDateField */6 :
                  var index$6 = action._1;
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$6.marginRows[index$6].followingStartDate, (function (status) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                                    if (index$p !== index$6) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.followingStartDate = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSingleRowHasExceptionsOriginalField */7 :
                  var index$7 = action._1;
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$7.marginRows[index$7].hasExceptionsOriginal, (function (status) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                                    if (index$p !== index$7) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.hasExceptionsOriginal = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSingleRowHasExceptionsField */8 :
                  var index$8 = action._1;
                  var nextInput$8 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidatorAndMetadata(nextInput$8, index$8, metadata, state.fieldsStatuses.marginRows[index$8].hasExceptions, state.submissionStatus, validators.marginRows.fields.hasExceptions, (function (status) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                                    if (index$p !== index$8) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.hasExceptions = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSingleRowEndDateOriginalField */9 :
                  var index$9 = action._1;
                  var nextInput$9 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$9.marginRows[index$9].endDateOriginal, (function (status) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                                    if (index$p !== index$9) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.endDateOriginal = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSingleRowEndDateField */10 :
                  var index$10 = action._1;
                  var nextInput$10 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$1.contents.marginRows, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$10, index$p, metadata, item.startDate, validators.marginRows.fields.startDate, (function (status) {
                                  return {
                                          marginRows: Belt_Array.mapWithIndex(nextFieldsStatuses$1.contents.marginRows, (function (idx_, item) {
                                                  if (idx_ !== index$p) {
                                                    return item;
                                                  }
                                                  var newrecord = Caml_obj.obj_dup(item);
                                                  newrecord.startDate = status;
                                                  return newrecord;
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$1.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidatorAndMetadata(nextInput$10, index$10, metadata, nextFieldsStatuses$1.contents.marginRows[index$10].endDate, state.submissionStatus, validators.marginRows.fields.endDate, (function (status) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(nextFieldsStatuses$1.contents.marginRows, (function (idx_, item) {
                                                    if (idx_ !== index$10) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.endDate = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSingleRowStartDateOriginalField */11 :
                  var index$11 = action._1;
                  var nextInput$11 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$11.marginRows[index$11].startDateOriginal, (function (status) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                                    if (index$p !== index$11) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.startDateOriginal = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSingleRowStartDateField */12 :
                  var index$12 = action._1;
                  var nextInput$12 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$2 = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$2.contents.marginRows, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$12, index$p, metadata, item.endDate, validators.marginRows.fields.endDate, (function (status) {
                                  return {
                                          marginRows: Belt_Array.mapWithIndex(nextFieldsStatuses$2.contents.marginRows, (function (idx_, item) {
                                                  if (idx_ !== index$p) {
                                                    return item;
                                                  }
                                                  var newrecord = Caml_obj.obj_dup(item);
                                                  newrecord.endDate = status;
                                                  return newrecord;
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$2.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidatorAndMetadata(nextInput$12, index$12, metadata, nextFieldsStatuses$2.contents.marginRows[index$12].startDate, state.submissionStatus, validators.marginRows.fields.startDate, (function (status) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(nextFieldsStatuses$2.contents.marginRows, (function (idx_, item) {
                                                    if (idx_ !== index$12) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.startDate = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSingleRowMarginOriginalField */13 :
                  var index$13 = action._1;
                  var nextInput$13 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$13,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$13.marginRows[index$13].marginOriginal, (function (status) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                                    if (index$p !== index$13) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.marginOriginal = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSingleRowMarginField */14 :
                  var index$14 = action._1;
                  var nextInput$14 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$14,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidatorAndMetadata(nextInput$14, index$14, metadata, state.fieldsStatuses.marginRows[index$14].margin, state.submissionStatus, validators.marginRows.fields.margin, (function (status) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                                    if (index$p !== index$14) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.margin = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSingleRowDiscountOriginalField */15 :
                  var index$15 = action._1;
                  var nextInput$15 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$15,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$15.marginRows[index$15].discountOriginal, (function (status) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                                    if (index$p !== index$15) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.discountOriginal = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSingleRowDiscountField */16 :
                  var index$16 = action._1;
                  var nextInput$16 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$16,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidatorAndMetadata(nextInput$16, index$16, metadata, state.fieldsStatuses.marginRows[index$16].discount, state.submissionStatus, validators.marginRows.fields.discount, (function (status) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                                    if (index$p !== index$16) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.discount = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSingleRowAcquisitionTypeOriginalField */17 :
                  var index$17 = action._1;
                  var nextInput$17 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$17,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$17.marginRows[index$17].acquisitionTypeOriginal, (function (status) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                                    if (index$p !== index$17) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.acquisitionTypeOriginal = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSingleRowAcquisitionTypeField */18 :
                  var index$18 = action._1;
                  var nextInput$18 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$18,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidatorAndMetadata(nextInput$18, index$18, metadata, state.fieldsStatuses.marginRows[index$18].acquisitionType, state.submissionStatus, validators.marginRows.fields.acquisitionType, (function (status) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                                    if (index$p !== index$18) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.acquisitionType = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSingleRowSomeIDOriginalField */19 :
                  var index$19 = action._1;
                  var nextInput$19 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$19,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$19.marginRows[index$19].someIDOriginal, (function (status) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                                    if (index$p !== index$19) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.someIDOriginal = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSingleRowSomeIDField */20 :
                  var index$20 = action._1;
                  var nextInput$20 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$20,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$20.marginRows[index$20].someID, (function (status) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                                    if (index$p !== index$20) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.someID = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* BlurSingleRowVersionOriginalField */21 :
                  var index$21 = action._0;
                  var result = Formality.validateFieldOnBlurWithoutValidator(state.input.marginRows[index$21].versionOriginal, state.fieldsStatuses.marginRows[index$21].versionOriginal, (function (status) {
                          return {
                                  marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                          if (index$p !== index$21) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.versionOriginal = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSingleRowModelOriginalField */22 :
                  var index$22 = action._0;
                  var result$1 = Formality.validateFieldOnBlurWithoutValidator(state.input.marginRows[index$22].modelOriginal, state.fieldsStatuses.marginRows[index$22].modelOriginal, (function (status) {
                          return {
                                  marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                          if (index$p !== index$22) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.modelOriginal = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSingleRowBudgetOriginalField */23 :
                  var index$23 = action._0;
                  var result$2 = Formality.validateFieldOnBlurWithoutValidator(state.input.marginRows[index$23].budgetOriginal, state.fieldsStatuses.marginRows[index$23].budgetOriginal, (function (status) {
                          return {
                                  marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                          if (index$p !== index$23) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.budgetOriginal = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSingleRowToSaveField */24 :
                  var index$24 = action._0;
                  var result$3 = Formality.validateFieldOnBlurWithoutValidator(state.input.marginRows[index$24].toSave, state.fieldsStatuses.marginRows[index$24].toSave, (function (status) {
                          return {
                                  marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                          if (index$p !== index$24) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.toSave = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSingleRowIsEditedField */25 :
                  var index$25 = action._0;
                  var result$4 = Formality.validateFieldOnBlurWithoutValidator(state.input.marginRows[index$25].isEdited, state.fieldsStatuses.marginRows[index$25].isEdited, (function (status) {
                          return {
                                  marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                          if (index$p !== index$25) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.isEdited = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSingleRowPreviousEndDateField */26 :
                  var index$26 = action._0;
                  var result$5 = Formality.validateFieldOnBlurWithoutValidator(state.input.marginRows[index$26].previousEndDate, state.fieldsStatuses.marginRows[index$26].previousEndDate, (function (status) {
                          return {
                                  marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                          if (index$p !== index$26) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.previousEndDate = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSingleRowFollowingStartDateField */27 :
                  var index$27 = action._0;
                  var result$6 = Formality.validateFieldOnBlurWithoutValidator(state.input.marginRows[index$27].followingStartDate, state.fieldsStatuses.marginRows[index$27].followingStartDate, (function (status) {
                          return {
                                  marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                          if (index$p !== index$27) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.followingStartDate = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSingleRowHasExceptionsOriginalField */28 :
                  var index$28 = action._0;
                  var result$7 = Formality.validateFieldOnBlurWithoutValidator(state.input.marginRows[index$28].hasExceptionsOriginal, state.fieldsStatuses.marginRows[index$28].hasExceptionsOriginal, (function (status) {
                          return {
                                  marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                          if (index$p !== index$28) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.hasExceptionsOriginal = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSingleRowHasExceptionsField */29 :
                  var index$29 = action._0;
                  var result$8 = Formality.validateFieldOfCollectionOnBlurWithValidatorAndMetadata(state.input, index$29, metadata, state.fieldsStatuses.marginRows[index$29].hasExceptions, validators.marginRows.fields.hasExceptions, (function (status) {
                          return {
                                  marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                          if (index$p !== index$29) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.hasExceptions = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSingleRowEndDateOriginalField */30 :
                  var index$30 = action._0;
                  var result$9 = Formality.validateFieldOnBlurWithoutValidator(state.input.marginRows[index$30].endDateOriginal, state.fieldsStatuses.marginRows[index$30].endDateOriginal, (function (status) {
                          return {
                                  marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                          if (index$p !== index$30) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.endDateOriginal = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSingleRowEndDateField */31 :
                  var index$31 = action._0;
                  var result$10 = Formality.validateFieldOfCollectionOnBlurWithValidatorAndMetadata(state.input, index$31, metadata, state.fieldsStatuses.marginRows[index$31].endDate, validators.marginRows.fields.endDate, (function (status) {
                          return {
                                  marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                          if (index$p !== index$31) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.endDate = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSingleRowStartDateOriginalField */32 :
                  var index$32 = action._0;
                  var result$11 = Formality.validateFieldOnBlurWithoutValidator(state.input.marginRows[index$32].startDateOriginal, state.fieldsStatuses.marginRows[index$32].startDateOriginal, (function (status) {
                          return {
                                  marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                          if (index$p !== index$32) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.startDateOriginal = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSingleRowStartDateField */33 :
                  var index$33 = action._0;
                  var result$12 = Formality.validateFieldOfCollectionOnBlurWithValidatorAndMetadata(state.input, index$33, metadata, state.fieldsStatuses.marginRows[index$33].startDate, validators.marginRows.fields.startDate, (function (status) {
                          return {
                                  marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                          if (index$p !== index$33) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.startDate = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$12 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$12,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSingleRowMarginOriginalField */34 :
                  var index$34 = action._0;
                  var result$13 = Formality.validateFieldOnBlurWithoutValidator(state.input.marginRows[index$34].marginOriginal, state.fieldsStatuses.marginRows[index$34].marginOriginal, (function (status) {
                          return {
                                  marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                          if (index$p !== index$34) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.marginOriginal = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$13 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$13,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSingleRowMarginField */35 :
                  var index$35 = action._0;
                  var result$14 = Formality.validateFieldOfCollectionOnBlurWithValidatorAndMetadata(state.input, index$35, metadata, state.fieldsStatuses.marginRows[index$35].margin, validators.marginRows.fields.margin, (function (status) {
                          return {
                                  marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                          if (index$p !== index$35) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.margin = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$14 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$14,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSingleRowDiscountOriginalField */36 :
                  var index$36 = action._0;
                  var result$15 = Formality.validateFieldOnBlurWithoutValidator(state.input.marginRows[index$36].discountOriginal, state.fieldsStatuses.marginRows[index$36].discountOriginal, (function (status) {
                          return {
                                  marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                          if (index$p !== index$36) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.discountOriginal = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$15 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$15,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSingleRowDiscountField */37 :
                  var index$37 = action._0;
                  var result$16 = Formality.validateFieldOfCollectionOnBlurWithValidatorAndMetadata(state.input, index$37, metadata, state.fieldsStatuses.marginRows[index$37].discount, validators.marginRows.fields.discount, (function (status) {
                          return {
                                  marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                          if (index$p !== index$37) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.discount = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$16 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$16,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSingleRowAcquisitionTypeOriginalField */38 :
                  var index$38 = action._0;
                  var result$17 = Formality.validateFieldOnBlurWithoutValidator(state.input.marginRows[index$38].acquisitionTypeOriginal, state.fieldsStatuses.marginRows[index$38].acquisitionTypeOriginal, (function (status) {
                          return {
                                  marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                          if (index$p !== index$38) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.acquisitionTypeOriginal = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$17 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$17,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSingleRowAcquisitionTypeField */39 :
                  var index$39 = action._0;
                  var result$18 = Formality.validateFieldOfCollectionOnBlurWithValidatorAndMetadata(state.input, index$39, metadata, state.fieldsStatuses.marginRows[index$39].acquisitionType, validators.marginRows.fields.acquisitionType, (function (status) {
                          return {
                                  marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                          if (index$p !== index$39) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.acquisitionType = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$18 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$18,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSingleRowSomeIDOriginalField */40 :
                  var index$40 = action._0;
                  var result$19 = Formality.validateFieldOnBlurWithoutValidator(state.input.marginRows[index$40].someIDOriginal, state.fieldsStatuses.marginRows[index$40].someIDOriginal, (function (status) {
                          return {
                                  marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                          if (index$p !== index$40) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.someIDOriginal = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$19 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$19,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSingleRowSomeIDField */41 :
                  var index$41 = action._0;
                  var result$20 = Formality.validateFieldOnBlurWithoutValidator(state.input.marginRows[index$41].someID, state.fieldsStatuses.marginRows[index$41].someID, (function (status) {
                          return {
                                  marginRows: Belt_Array.mapWithIndex(state.fieldsStatuses.marginRows, (function (index$p, item) {
                                          if (index$p !== index$41) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.someID = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$20 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$20,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* AddSingleRowEntry */42 :
                  var nextInput$21 = {
                    marginRows: Belt_Array.concat(state.input.marginRows, [action._0])
                  };
                  var nextFieldsStatuses$3 = {
                    contents: {
                      marginRows: Belt_Array.concat(state.fieldsStatuses.marginRows, [{
                              versionOriginal: /* Pristine */0,
                              modelOriginal: /* Pristine */0,
                              budgetOriginal: /* Pristine */0,
                              toSave: /* Pristine */0,
                              isEdited: /* Pristine */0,
                              previousEndDate: /* Pristine */0,
                              followingStartDate: /* Pristine */0,
                              hasExceptionsOriginal: /* Pristine */0,
                              hasExceptions: /* Pristine */0,
                              endDateOriginal: /* Pristine */0,
                              endDate: /* Pristine */0,
                              startDateOriginal: /* Pristine */0,
                              startDate: /* Pristine */0,
                              marginOriginal: /* Pristine */0,
                              margin: /* Pristine */0,
                              discountOriginal: /* Pristine */0,
                              discount: /* Pristine */0,
                              acquisitionTypeOriginal: /* Pristine */0,
                              acquisitionType: /* Pristine */0,
                              someIDOriginal: /* Pristine */0,
                              someID: /* Pristine */0
                            }])
                    }
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$3.contents.marginRows, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$21, index$p, metadata, item.endDate, validators.marginRows.fields.endDate, (function (status) {
                                  return {
                                          marginRows: Belt_Array.mapWithIndex(nextFieldsStatuses$3.contents.marginRows, (function (idx_, item) {
                                                  if (idx_ !== index$p) {
                                                    return item;
                                                  }
                                                  var newrecord = Caml_obj.obj_dup(item);
                                                  newrecord.endDate = status;
                                                  return newrecord;
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$3.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$3.contents.marginRows, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$21, index$p, metadata, item.startDate, validators.marginRows.fields.startDate, (function (status) {
                                  return {
                                          marginRows: Belt_Array.mapWithIndex(nextFieldsStatuses$3.contents.marginRows, (function (idx_, item) {
                                                  if (idx_ !== index$p) {
                                                    return item;
                                                  }
                                                  var newrecord = Caml_obj.obj_dup(item);
                                                  newrecord.startDate = status;
                                                  return newrecord;
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$3.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$3.contents.marginRows, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$21, index$p, metadata, item.margin, validators.marginRows.fields.margin, (function (status) {
                                  return {
                                          marginRows: Belt_Array.mapWithIndex(nextFieldsStatuses$3.contents.marginRows, (function (idx_, item) {
                                                  if (idx_ !== index$p) {
                                                    return item;
                                                  }
                                                  var newrecord = Caml_obj.obj_dup(item);
                                                  newrecord.margin = status;
                                                  return newrecord;
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$3.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$3.contents.marginRows, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$21, index$p, metadata, item.startDate, validators.marginRows.fields.startDate, (function (status) {
                                  return {
                                          marginRows: Belt_Array.mapWithIndex(nextFieldsStatuses$3.contents.marginRows, (function (idx_, item) {
                                                  if (idx_ !== index$p) {
                                                    return item;
                                                  }
                                                  var newrecord = Caml_obj.obj_dup(item);
                                                  newrecord.startDate = status;
                                                  return newrecord;
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$3.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$3.contents.marginRows, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$21, index$p, metadata, item.endDate, validators.marginRows.fields.endDate, (function (status) {
                                  return {
                                          marginRows: Belt_Array.mapWithIndex(nextFieldsStatuses$3.contents.marginRows, (function (idx_, item) {
                                                  if (idx_ !== index$p) {
                                                    return item;
                                                  }
                                                  var newrecord = Caml_obj.obj_dup(item);
                                                  newrecord.endDate = status;
                                                  return newrecord;
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$3.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$21,
                            fieldsStatuses: nextFieldsStatuses$3.contents,
                            collectionsStatuses: {
                              marginRows: Curry._2(validators.marginRows.collection, nextInput$21, metadata)
                            },
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* RemoveSingleRowEntry */43 :
                  var index$42 = action._0;
                  var nextInput$22 = {
                    marginRows: Belt_Array.keepWithIndex(state.input.marginRows, (function (param, i) {
                            return i !== index$42;
                          }))
                  };
                  var nextFieldsStatuses$4 = {
                    contents: {
                      marginRows: Belt_Array.keepWithIndex(state.fieldsStatuses.marginRows, (function (param, i) {
                              return i !== index$42;
                            }))
                    }
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$4.contents.marginRows, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$22, index$p, metadata, item.endDate, validators.marginRows.fields.endDate, (function (status) {
                                  return {
                                          marginRows: Belt_Array.mapWithIndex(nextFieldsStatuses$4.contents.marginRows, (function (idx_, item) {
                                                  if (idx_ !== index$p) {
                                                    return item;
                                                  }
                                                  var newrecord = Caml_obj.obj_dup(item);
                                                  newrecord.endDate = status;
                                                  return newrecord;
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$4.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$4.contents.marginRows, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$22, index$p, metadata, item.startDate, validators.marginRows.fields.startDate, (function (status) {
                                  return {
                                          marginRows: Belt_Array.mapWithIndex(nextFieldsStatuses$4.contents.marginRows, (function (idx_, item) {
                                                  if (idx_ !== index$p) {
                                                    return item;
                                                  }
                                                  var newrecord = Caml_obj.obj_dup(item);
                                                  newrecord.startDate = status;
                                                  return newrecord;
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$4.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$4.contents.marginRows, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$22, index$p, metadata, item.margin, validators.marginRows.fields.margin, (function (status) {
                                  return {
                                          marginRows: Belt_Array.mapWithIndex(nextFieldsStatuses$4.contents.marginRows, (function (idx_, item) {
                                                  if (idx_ !== index$p) {
                                                    return item;
                                                  }
                                                  var newrecord = Caml_obj.obj_dup(item);
                                                  newrecord.margin = status;
                                                  return newrecord;
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$4.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$4.contents.marginRows, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$22, index$p, metadata, item.startDate, validators.marginRows.fields.startDate, (function (status) {
                                  return {
                                          marginRows: Belt_Array.mapWithIndex(nextFieldsStatuses$4.contents.marginRows, (function (idx_, item) {
                                                  if (idx_ !== index$p) {
                                                    return item;
                                                  }
                                                  var newrecord = Caml_obj.obj_dup(item);
                                                  newrecord.startDate = status;
                                                  return newrecord;
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$4.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$4.contents.marginRows, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$22, index$p, metadata, item.endDate, validators.marginRows.fields.endDate, (function (status) {
                                  return {
                                          marginRows: Belt_Array.mapWithIndex(nextFieldsStatuses$4.contents.marginRows, (function (idx_, item) {
                                                  if (idx_ !== index$p) {
                                                    return item;
                                                  }
                                                  var newrecord = Caml_obj.obj_dup(item);
                                                  newrecord.endDate = status;
                                                  return newrecord;
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$4.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$22,
                            fieldsStatuses: nextFieldsStatuses$4.contents,
                            collectionsStatuses: {
                              marginRows: Curry._2(validators.marginRows.collection, nextInput$22, metadata)
                            },
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */44 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */45 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */46 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Caml_option.some(Curry._1(map, Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateSingleRowVersionOriginal: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSingleRowVersionOriginalField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateSingleRowModelOriginal: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSingleRowModelOriginalField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateSingleRowBudgetOriginal: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSingleRowBudgetOriginalField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateSingleRowToSave: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSingleRowToSaveField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateSingleRowIsEdited: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSingleRowIsEditedField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateSingleRowPreviousEndDate: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSingleRowPreviousEndDateField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateSingleRowFollowingStartDate: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSingleRowFollowingStartDateField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateSingleRowHasExceptionsOriginal: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSingleRowHasExceptionsOriginalField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateSingleRowHasExceptions: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSingleRowHasExceptionsField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateSingleRowEndDateOriginal: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSingleRowEndDateOriginalField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateSingleRowEndDate: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSingleRowEndDateField */10,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateSingleRowStartDateOriginal: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSingleRowStartDateOriginalField */11,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateSingleRowStartDate: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSingleRowStartDateField */12,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateSingleRowMarginOriginal: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSingleRowMarginOriginalField */13,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateSingleRowMargin: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSingleRowMarginField */14,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateSingleRowDiscountOriginal: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSingleRowDiscountOriginalField */15,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateSingleRowDiscount: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSingleRowDiscountField */16,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateSingleRowAcquisitionTypeOriginal: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSingleRowAcquisitionTypeOriginalField */17,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateSingleRowAcquisitionType: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSingleRowAcquisitionTypeField */18,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateSingleRowSomeIDOriginal: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSingleRowSomeIDOriginalField */19,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateSingleRowSomeID: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSingleRowSomeIDField */20,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          blurSingleRowVersionOriginal: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurSingleRowVersionOriginalField */21,
                    _0: index
                  });
            }),
          blurSingleRowModelOriginal: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurSingleRowModelOriginalField */22,
                    _0: index
                  });
            }),
          blurSingleRowBudgetOriginal: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurSingleRowBudgetOriginalField */23,
                    _0: index
                  });
            }),
          blurSingleRowToSave: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurSingleRowToSaveField */24,
                    _0: index
                  });
            }),
          blurSingleRowIsEdited: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurSingleRowIsEditedField */25,
                    _0: index
                  });
            }),
          blurSingleRowPreviousEndDate: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurSingleRowPreviousEndDateField */26,
                    _0: index
                  });
            }),
          blurSingleRowFollowingStartDate: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurSingleRowFollowingStartDateField */27,
                    _0: index
                  });
            }),
          blurSingleRowHasExceptionsOriginal: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurSingleRowHasExceptionsOriginalField */28,
                    _0: index
                  });
            }),
          blurSingleRowHasExceptions: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurSingleRowHasExceptionsField */29,
                    _0: index
                  });
            }),
          blurSingleRowEndDateOriginal: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurSingleRowEndDateOriginalField */30,
                    _0: index
                  });
            }),
          blurSingleRowEndDate: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurSingleRowEndDateField */31,
                    _0: index
                  });
            }),
          blurSingleRowStartDateOriginal: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurSingleRowStartDateOriginalField */32,
                    _0: index
                  });
            }),
          blurSingleRowStartDate: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurSingleRowStartDateField */33,
                    _0: index
                  });
            }),
          blurSingleRowMarginOriginal: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurSingleRowMarginOriginalField */34,
                    _0: index
                  });
            }),
          blurSingleRowMargin: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurSingleRowMarginField */35,
                    _0: index
                  });
            }),
          blurSingleRowDiscountOriginal: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurSingleRowDiscountOriginalField */36,
                    _0: index
                  });
            }),
          blurSingleRowDiscount: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurSingleRowDiscountField */37,
                    _0: index
                  });
            }),
          blurSingleRowAcquisitionTypeOriginal: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurSingleRowAcquisitionTypeOriginalField */38,
                    _0: index
                  });
            }),
          blurSingleRowAcquisitionType: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurSingleRowAcquisitionTypeField */39,
                    _0: index
                  });
            }),
          blurSingleRowSomeIDOriginal: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurSingleRowSomeIDOriginalField */40,
                    _0: index
                  });
            }),
          blurSingleRowSomeID: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurSingleRowSomeIDField */41,
                    _0: index
                  });
            }),
          singleRowVersionOriginalResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.marginRows[index].versionOriginal);
            }),
          singleRowModelOriginalResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.marginRows[index].modelOriginal);
            }),
          singleRowBudgetOriginalResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.marginRows[index].budgetOriginal);
            }),
          singleRowToSaveResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.marginRows[index].toSave);
            }),
          singleRowIsEditedResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.marginRows[index].isEdited);
            }),
          singleRowPreviousEndDateResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.marginRows[index].previousEndDate);
            }),
          singleRowFollowingStartDateResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.marginRows[index].followingStartDate);
            }),
          singleRowHasExceptionsOriginalResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.marginRows[index].hasExceptionsOriginal);
            }),
          singleRowHasExceptionsResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.marginRows[index].hasExceptions);
            }),
          singleRowEndDateOriginalResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.marginRows[index].endDateOriginal);
            }),
          singleRowEndDateResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.marginRows[index].endDate);
            }),
          singleRowStartDateOriginalResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.marginRows[index].startDateOriginal);
            }),
          singleRowStartDateResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.marginRows[index].startDate);
            }),
          singleRowMarginOriginalResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.marginRows[index].marginOriginal);
            }),
          singleRowMarginResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.marginRows[index].margin);
            }),
          singleRowDiscountOriginalResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.marginRows[index].discountOriginal);
            }),
          singleRowDiscountResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.marginRows[index].discount);
            }),
          singleRowAcquisitionTypeOriginalResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.marginRows[index].acquisitionTypeOriginal);
            }),
          singleRowAcquisitionTypeResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.marginRows[index].acquisitionType);
            }),
          singleRowSomeIDOriginalResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.marginRows[index].someIDOriginal);
            }),
          singleRowSomeIDResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.marginRows[index].someID);
            }),
          addSingleRow: (function (entry) {
              Curry._1(dispatch, {
                    TAG: /* AddSingleRowEntry */42,
                    _0: entry
                  });
            }),
          removeSingleRow: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* RemoveSingleRowEntry */43,
                    _0: index
                  });
            }),
          marginRowsResult: state.collectionsStatuses.marginRows,
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              return Belt_Array.every(state.fieldsStatuses.marginRows, (function (item) {
                            var exit = 0;
                            if (item.versionOriginal || item.modelOriginal || item.budgetOriginal || item.toSave || item.isEdited || item.previousEndDate || item.followingStartDate || item.hasExceptionsOriginal || item.hasExceptions || item.endDateOriginal || item.endDate || item.startDateOriginal || item.startDate || item.marginOriginal || item.margin || item.discountOriginal || item.discount || item.acquisitionTypeOriginal || item.acquisitionType || item.someIDOriginal) {
                              exit = 1;
                            } else {
                              if (!item.someID) {
                                return false;
                              }
                              exit = 1;
                            }
                            if (exit === 1) {
                              var exit$1 = 0;
                              exit$1 = 2;
                              if (exit$1 === 2) {
                                var exit$2 = 0;
                                exit$2 = 3;
                                if (exit$2 === 3) {
                                  var exit$3 = 0;
                                  exit$3 = 4;
                                  if (exit$3 === 4) {
                                    var exit$4 = 0;
                                    exit$4 = 5;
                                    if (exit$4 === 5) {
                                      var exit$5 = 0;
                                      exit$5 = 6;
                                      if (exit$5 === 6) {
                                        var exit$6 = 0;
                                        exit$6 = 7;
                                        if (exit$6 === 7) {
                                          var exit$7 = 0;
                                          exit$7 = 8;
                                          if (exit$7 === 8) {
                                            var exit$8 = 0;
                                            exit$8 = 9;
                                            if (exit$8 === 9) {
                                              var exit$9 = 0;
                                              exit$9 = 10;
                                              if (exit$9 === 10) {
                                                return true;
                                              }
                                              
                                            }
                                            
                                          }
                                          
                                        }
                                        
                                      }
                                      
                                    }
                                    
                                  }
                                  
                                }
                                
                              }
                              
                            }
                            
                          }));
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */0);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */1);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */2);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */46,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */3);
            })
        };
}

var MarginEditForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: initialCollectionsStatuses,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialInput = {
  marginRows: []
};

var Types;

var initialSingleRowInput = {
  someID: "",
  someIDOriginal: "",
  acquisitionType: "",
  acquisitionTypeOriginal: "",
  discount: undefined,
  discountOriginal: 0.0,
  margin: undefined,
  marginOriginal: 0.0,
  startDate: undefined,
  startDateOriginal: undefined,
  endDate: undefined,
  endDateOriginal: undefined,
  hasExceptions: false,
  hasExceptionsOriginal: false,
  followingStartDate: undefined,
  previousEndDate: undefined,
  isEdited: false,
  toSave: false,
  budgetOriginal: "",
  modelOriginal: "",
  versionOriginal: ""
};

export {
  Types ,
  MarginEditForm ,
  initialInput ,
  initialSingleRowInput ,
}
/* react Not a pure module */
