// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as App_Icon$DvmAdminFrontendFr from "../components/icons/App_Icon.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../theme/Theme_Colors.bs.js";

function File_Upload_Stepper_Icon(Props) {
  var props = Props.props;
  return React.createElement("div", {
              style: {
                backgroundColor: props.active ? Theme_Colors$DvmAdminFrontendFr.black : (
                    props.completed ? Theme_Colors$DvmAdminFrontendFr.primaryBrown : Theme_Colors$DvmAdminFrontendFr.grayLight3
                  ),
                color: "white",
                display: "flex",
                fontWeight: "600",
                height: "32px",
                width: "32px",
                borderRadius: "50%",
                alignItems: "center",
                justifyContent: "center"
              }
            }, props.active ? React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                    size: /* Regular */2,
                    type_: /* Edit */60
                  }) : props.icon);
}

var Types;

var make = File_Upload_Stepper_Icon;

export {
  Types ,
  make ,
}
/* react Not a pure module */
