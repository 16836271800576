// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Margin_Grid_Duplicate_Api$DvmAdminFrontendFr from "./Margin_Grid_Duplicate_Api.bs.js";
import * as Messages_MarginGrid_Duplicate$DvmAdminFrontendFr from "../../../../intl/messages/marginGrid/Messages_MarginGrid_Duplicate.bs.js";
import * as Margin_Grid_Duplicate_Filter_Form$DvmAdminFrontendFr from "./Margin_Grid_Duplicate_Filter_Form.bs.js";
import * as Margin_Grid_Duplicate_Filters_Utils$DvmAdminFrontendFr from "./Margin_Grid_Duplicate_Filters_Utils.bs.js";

function Margin_Grid_Duplicate_Filters(Props) {
  var budgetOrID = Props.budgetOrID;
  var setLabeledCategories = Props.setLabeledCategories;
  var onFilterDateChange = Props.onFilterDateChange;
  var gridAllRowsResult = Props.gridAllRowsResult;
  var availablePeriods = Props.availablePeriods;
  var setAvailablePeriods = Props.setAvailablePeriods;
  var setSelectedPeriod = Props.setSelectedPeriod;
  var setGridAllRowsResult = Props.setGridAllRowsResult;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setFilterResult = match$2[1];
  var filterResult = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setAvailableCategoriesResult = match$3[1];
  var form = Margin_Grid_Duplicate_Filter_Form$DvmAdminFrontendFr.FilterForm.useForm(Margin_Grid_Duplicate_Filter_Form$DvmAdminFrontendFr.initialState, (function (param, submissionCallbacks) {
          Margin_Grid_Duplicate_Api$DvmAdminFrontendFr.getMarginsForDuplication(submissionCallbacks, userManager, setFilterResult, selectedRole, Belt_Array.joinWith(Belt_List.toArray({
                        hd: "avsMarginGridId=" + budgetOrID,
                        tl: {
                          hd: param.categoryID,
                          tl: {
                            hd: param.period,
                            tl: /* [] */0
                          }
                        }
                      }), ";", (function (x) {
                      return x;
                    })));
        }));
  React.useEffect((function () {
          if (typeof filterResult === "number") {
            filterResult === /* NotStarted */0;
          } else if (filterResult.TAG === /* Ok */0) {
            var categories = filterResult._0;
            Curry._1(setLabeledCategories, (function (param) {
                    return Belt_List.keepMap(categories.content, (function (x) {
                                  return Belt_Option.mapWithDefault(x.categoryID, undefined, (function (categoryId) {
                                                return Margin_Grid_Duplicate_Filters_Utils$DvmAdminFrontendFr.toLabelValueRecord(categoryId, x.discount, x.margin);
                                              }));
                                }));
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: filterResult._0
                      }
                    }));
          }
        }), [filterResult]);
  React.useEffect((function () {
          if (typeof filterResult === "number") {
            filterResult === /* NotStarted */0;
          } else if (filterResult.TAG === /* Ok */0) {
            var categories = filterResult._0;
            Curry._1(setLabeledCategories, (function (param) {
                    return Margin_Grid_Duplicate_Filters_Utils$DvmAdminFrontendFr.getLabeledCategoriesForDiscount(categories.content, form.input.discount);
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: filterResult._0
                      }
                    }));
          }
        }), [form.input.discount]);
  React.useEffect((function () {
          Curry._1(onFilterDateChange, undefined);
          Curry._1(setSelectedPeriod, (function (param) {
                  return form.input.period;
                }));
          Curry._2(form.updateCategoryID, (function (input, categoryID) {
                  return {
                          year: input.year,
                          categoryID: categoryID,
                          period: input.period,
                          discount: input.discount
                        };
                }), "");
          Curry._2(form.updateDiscount, (function (input, discount) {
                  return {
                          year: input.year,
                          categoryID: input.categoryID,
                          period: input.period,
                          discount: discount
                        };
                }), "");
          Curry._1(setLabeledCategories, (function (param) {
                  return /* [] */0;
                }));
          if (form.input.period.length > 0) {
            Margin_Grid_Duplicate_Api$DvmAdminFrontendFr.fetchSearchForCategoryIds(userManager, setAvailableCategoriesResult, selectedRole, budgetOrID, form.input.period);
          }
          
        }), [form.input.period]);
  React.useEffect((function () {
          Curry._2(form.updateDiscount, (function (input, discount) {
                  return {
                          year: input.year,
                          categoryID: input.categoryID,
                          period: input.period,
                          discount: discount
                        };
                }), "");
          Curry._1(form.submit, undefined);
        }), [form.input.categoryID]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.mapWithDefault(gridAllRowsResult, undefined, (function (x) {
                  Curry._1(setAvailablePeriods, (function (param) {
                          return Margin_Grid_Duplicate_Filters_Utils$DvmAdminFrontendFr.getDistinctPeriods(intl, x.content);
                        }));
                }));
        }), [gridAllRowsResult]);
  React.useEffect((function () {
          Belt_Option.mapWithDefault(form.input.year, undefined, (function (date) {
                  Margin_Grid_Duplicate_Api$DvmAdminFrontendFr.fetchSearchForMargin(userManager, setGridAllRowsResult, selectedRole, Margin_Grid_Duplicate_Filters_Utils$DvmAdminFrontendFr.yearToCombinations(date, budgetOrID));
                }));
          Curry._2(form.updateCategoryID, (function (input, categoryID) {
                  return {
                          year: input.year,
                          categoryID: categoryID,
                          period: input.period,
                          discount: input.discount
                        };
                }), "");
          Curry._2(form.updateDiscount, (function (input, discount) {
                  return {
                          year: input.year,
                          categoryID: input.categoryID,
                          period: input.period,
                          discount: discount
                        };
                }), "");
          Curry._2(form.updatePeriod, (function (input, period) {
                  return {
                          year: input.year,
                          categoryID: input.categoryID,
                          period: period,
                          discount: input.discount
                        };
                }), "");
          Curry._1(setLabeledCategories, (function (param) {
                  return /* [] */0;
                }));
        }), [form.input.year]);
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  item: true,
                  spacing: 2
                }, React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      item: true
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                children: intl.formatMessage(Messages_MarginGrid_Duplicate$DvmAdminFrontendFr.periodSelection),
                                variant: "bodyBig"
                              }),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          item: true,
                          spacing: 2
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, {
                                    label: Messages_Common$DvmAdminFrontendFr.year,
                                    value: form.input.year,
                                    onChange: Curry._1(form.updateYear, (function (input, year) {
                                            return {
                                                    year: year,
                                                    categoryID: input.categoryID,
                                                    period: input.period,
                                                    discount: input.discount
                                                  };
                                          })),
                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
                                    variant: /* YEAR */0
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                    label: Messages_Common$DvmAdminFrontendFr.period,
                                    value: {
                                      TAG: /* Single */0,
                                      _0: {
                                        value: form.input.period,
                                        onChange: Curry._1(form.updatePeriod, (function (input, period) {
                                                return {
                                                        year: input.year,
                                                        categoryID: input.categoryID,
                                                        period: period,
                                                        discount: input.discount
                                                      };
                                              }))
                                      }
                                    },
                                    options: {
                                      TAG: /* Labeled */1,
                                      _0: Belt_List.map(availablePeriods, (function (x) {
                                              return x.labeledValues;
                                            }))
                                    },
                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                                  }),
                              item: true
                            }))), React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      item: true
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                children: intl.formatMessage(Messages_MarginGrid_Duplicate$DvmAdminFrontendFr.duplicateTransferHeader),
                                variant: "bodyBig"
                              }),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          item: true,
                          spacing: 2
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                    label: Messages_Common$DvmAdminFrontendFr.categoryCode,
                                    value: {
                                      TAG: /* Single */0,
                                      _0: {
                                        value: form.input.categoryID,
                                        onChange: Curry._1(form.updateCategoryID, (function (input, categoryID) {
                                                return {
                                                        year: input.year,
                                                        categoryID: categoryID,
                                                        period: input.period,
                                                        discount: input.discount
                                                      };
                                              }))
                                      }
                                    },
                                    options: {
                                      TAG: /* Unlabeled */0,
                                      _0: Margin_Grid_Duplicate_Filters_Utils$DvmAdminFrontendFr.getAvailableCategories(match$3[0])
                                    },
                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                    label: Messages_Common$DvmAdminFrontendFr.discount,
                                    value: {
                                      TAG: /* Single */0,
                                      _0: {
                                        value: form.input.discount,
                                        onChange: Curry._1(form.updateDiscount, (function (input, discount) {
                                                return {
                                                        year: input.year,
                                                        categoryID: input.categoryID,
                                                        period: input.period,
                                                        discount: discount
                                                      };
                                              }))
                                      }
                                    },
                                    options: {
                                      TAG: /* Unlabeled */0,
                                      _0: Margin_Grid_Duplicate_Filters_Utils$DvmAdminFrontendFr.getAvailableDiscounts(filterResult, form.input.categoryID)
                                    },
                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                                  }),
                              item: true
                            })))));
}

var Api;

var Form;

var Types;

var DuplicateUtils;

var InputStyles;

var make = Margin_Grid_Duplicate_Filters;

export {
  Api ,
  Form ,
  Types ,
  DuplicateUtils ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
