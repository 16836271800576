// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Messages_Error$DvmAdminFrontendFr from "../intl/messages/Messages_Error.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../common/types/App_Types_Result.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../context/user/User_Types_Utils.bs.js";
import * as FetchClient_Response$DvmAdminFrontendFr from "./FetchClient_Response.bs.js";
import * as Utils_Authentication$DvmAdminFrontendFr from "../common/utils/Utils_Authentication.bs.js";

function callWithHeadersAndHandleResponse(httpMethod, headers, body, handleResponse, jsonResponseOpt, selectedRole, url) {
  var jsonResponse = jsonResponseOpt !== undefined ? jsonResponseOpt : "json";
  return new Promise((function (resolve, _reject) {
                var request = new XMLHttpRequest();
                request.addEventListener("load", (function (param) {
                        try {
                          return Curry._2(handleResponse, request, resolve);
                        }
                        catch (raw_exn){
                          var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
                          if (exn.RE_EXN_ID === Js_exn.$$Error) {
                            return resolve({
                                        TAG: /* ErrorResult */1,
                                        _0: App_Types_Result$DvmAdminFrontendFr.Rest.makeError(undefined, undefined, undefined, Messages_Error$DvmAdminFrontendFr.backEndUnexpectedError.id)
                                      });
                          }
                          throw exn;
                        }
                      }));
                request.addEventListener("error", (function (param) {
                        resolve({
                              TAG: /* ErrorResult */1,
                              _0: App_Types_Result$DvmAdminFrontendFr.Rest.makeError(undefined, undefined, undefined, Messages_Error$DvmAdminFrontendFr.backEndUnexpectedError.id)
                            });
                      }));
                request.open(httpMethod, process.env.REACT_APP_API_REST_ENDPOINT + url);
                if (jsonResponse === "json") {
                  request.responseType = "json";
                } else {
                  request.responseType = "blob";
                }
                Belt_List.forEach(headers, (function (param) {
                        request.setRequestHeader(param[0], param[1]);
                      }));
                if (selectedRole === /* Dealer */7 || User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole)) {
                  request.setRequestHeader("x-force-roles", "dealer");
                }
                var tmp;
                tmp = typeof selectedRole === "number" || selectedRole.TAG !== /* ADMINDEALER */1 ? Utils_Authentication$DvmAdminFrontendFr.roleToString(selectedRole) : Utils_Authentication$DvmAdminFrontendFr.roleToString({
                        TAG: /* Administrator */0,
                        _0: selectedRole._0
                      });
                request.setRequestHeader("x-selected-role", tmp);
                if (body.TAG === /* Json */0) {
                  request.setRequestHeader("Content-Type", "application/json");
                  request.send(JSON.stringify(body._0));
                  return ;
                }
                request.send(body._0);
              }));
}

function getAccessTokenHeader(accessToken) {
  return [
          "Authorization",
          "Bearer " + accessToken + ""
        ];
}

function callWithHeaders(httpMethod, headers, body, responseDecoder, selectedRole, url) {
  return callWithHeadersAndHandleResponse(httpMethod, headers, body, (function (request, resolve) {
                FetchClient_Response$DvmAdminFrontendFr.handleJsonResponse(resolve, responseDecoder, request);
              }), undefined, selectedRole, url);
}

function call(httpMethod, bodyOpt, responseDecoder, selectedRole, url, authentication) {
  var body = bodyOpt !== undefined ? bodyOpt : ({
        TAG: /* Json */0,
        _0: {}
      });
  if (authentication) {
    return callWithHeaders(httpMethod, {
                hd: getAccessTokenHeader(authentication._0),
                tl: /* [] */0
              }, body, responseDecoder, selectedRole, url);
  } else {
    return callWithHeaders(httpMethod, /* [] */0, body, responseDecoder, selectedRole, url);
  }
}

function callWithHeaders$1(httpMethod, headers, body, selectedRole, url) {
  return callWithHeadersAndHandleResponse(httpMethod, headers, body, (function (request, resolve) {
                FetchClient_Response$DvmAdminFrontendFr.handleBlobResponse(resolve, request);
              }), "blob", selectedRole, url);
}

function call$1(httpMethod, bodyOpt, selectedRole, url, authentication) {
  var body = bodyOpt !== undefined ? bodyOpt : ({
        TAG: /* Json */0,
        _0: {}
      });
  if (authentication) {
    return callWithHeaders$1(httpMethod, {
                hd: getAccessTokenHeader(authentication._0),
                tl: /* [] */0
              }, body, selectedRole, url);
  } else {
    return callWithHeaders$1(httpMethod, /* [] */0, body, selectedRole, url);
  }
}

function callWithHeaders$2(httpMethod, headers, body, selectedRole, url) {
  return callWithHeadersAndHandleResponse(httpMethod, headers, body, (function (request, resolve) {
                FetchClient_Response$DvmAdminFrontendFr.WithEmptyResponse.handleResponse(resolve, request);
              }), undefined, selectedRole, url);
}

function call$2(httpMethod, bodyOpt, selectedRole, url, authentication) {
  var body = bodyOpt !== undefined ? bodyOpt : ({
        TAG: /* Json */0,
        _0: {}
      });
  if (authentication) {
    return callWithHeaders$2(httpMethod, {
                hd: getAccessTokenHeader(authentication._0),
                tl: /* [] */0
              }, body, selectedRole, url);
  } else {
    return callWithHeaders$2(httpMethod, /* [] */0, body, selectedRole, url);
  }
}

var $$Response;

var WithEmptyResponse = {
  call: call$2
};

var WithBlobResponse = {
  call: call$1
};

export {
  $$Response ,
  call ,
  WithEmptyResponse ,
  WithBlobResponse ,
}
/* Utils_Authentication-DvmAdminFrontendFr Not a pure module */
