// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReDate_utils from "@mobily/rescript-date/src/ReDate_utils.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../common/types/App_Types_Date.bs.js";
import * as Messages_MarginGrid$DvmAdminFrontendFr from "../../../../intl/messages/marginGrid/Messages_MarginGrid.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Error_Inputs.bs.js";

function dayStart(date) {
  return ReDate_utils.makeStartOfDayDate(date).valueOf();
}

function validateEditEndDate(date, highestStartDate, lowestFollowingStartDate) {
  return Belt_Option.mapWithDefault(date, {
              TAG: /* Error */1,
              _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
            }, (function (date) {
                if (!App_Types_Date$DvmAdminFrontendFr.isValid(date)) {
                  return {
                          TAG: /* Error */1,
                          _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                        };
                }
                var isDateOk;
                if (highestStartDate !== undefined) {
                  var highestStart = Caml_option.valFromOption(highestStartDate);
                  if (lowestFollowingStartDate !== undefined) {
                    var lowestFollowingStart = Caml_option.valFromOption(lowestFollowingStartDate);
                    isDateOk = App_Types_Date$DvmAdminFrontendFr.isValid(highestStart) && App_Types_Date$DvmAdminFrontendFr.isValid(lowestFollowingStart) ? ReDate_utils.makeStartOfDayDate(date).valueOf() > ReDate_utils.makeStartOfDayDate(highestStart).valueOf() && ReDate_utils.makeStartOfDayDate(date).valueOf() < ReDate_utils.makeStartOfDayDate(lowestFollowingStart).valueOf() : false;
                  } else {
                    isDateOk = App_Types_Date$DvmAdminFrontendFr.isValid(highestStart) ? ReDate_utils.makeStartOfDayDate(date).valueOf() > ReDate_utils.makeStartOfDayDate(highestStart).valueOf() : false;
                  }
                } else {
                  isDateOk = false;
                }
                if (isDateOk) {
                  return {
                          TAG: /* Ok */0,
                          _0: Caml_option.some(date)
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: Messages_MarginGrid$DvmAdminFrontendFr.dateOutOfAllowedPeriod
                        };
                }
              }));
}

function validateEditStartDate(date, lowestEndDate, highestPreviousEndDate) {
  return Belt_Option.mapWithDefault(date, {
              TAG: /* Error */1,
              _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
            }, (function (date) {
                if (!App_Types_Date$DvmAdminFrontendFr.isValid(date)) {
                  return {
                          TAG: /* Error */1,
                          _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                        };
                }
                var isDateOk;
                if (lowestEndDate !== undefined) {
                  var lowestEnd = Caml_option.valFromOption(lowestEndDate);
                  if (highestPreviousEndDate !== undefined) {
                    var highestPreviousEnd = Caml_option.valFromOption(highestPreviousEndDate);
                    isDateOk = App_Types_Date$DvmAdminFrontendFr.isValid(lowestEnd) && App_Types_Date$DvmAdminFrontendFr.isValid(highestPreviousEnd) ? ReDate_utils.makeStartOfDayDate(date).valueOf() < ReDate_utils.makeStartOfDayDate(lowestEnd).valueOf() && ReDate_utils.makeStartOfDayDate(date).valueOf() > ReDate_utils.makeStartOfDayDate(highestPreviousEnd).valueOf() : false;
                  } else {
                    isDateOk = App_Types_Date$DvmAdminFrontendFr.isValid(lowestEnd) ? ReDate_utils.makeStartOfDayDate(date).valueOf() < ReDate_utils.makeStartOfDayDate(lowestEnd).valueOf() : false;
                  }
                } else if (highestPreviousEndDate !== undefined) {
                  var highestPreviousEnd$1 = Caml_option.valFromOption(highestPreviousEndDate);
                  isDateOk = App_Types_Date$DvmAdminFrontendFr.isValid(highestPreviousEnd$1) ? ReDate_utils.makeStartOfDayDate(date).valueOf() > ReDate_utils.makeStartOfDayDate(highestPreviousEnd$1).valueOf() : false;
                } else {
                  isDateOk = false;
                }
                if (isDateOk) {
                  return {
                          TAG: /* Ok */0,
                          _0: Caml_option.some(date)
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: Messages_MarginGrid$DvmAdminFrontendFr.dateOutOfAllowedPeriod
                        };
                }
              }));
}

export {
  dayStart ,
  validateEditEndDate ,
  validateEditStartDate ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
