// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../../common/App_Api.bs.js";
import * as Quota_Api$DvmAdminFrontendFr from "../Quota_Api.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../../../context/router/Context_Router.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as Messages_Quotas$DvmAdminFrontendFr from "../../../../intl/messages/quotas/Messages_Quotas.bs.js";
import * as Quota_View_Form$DvmAdminFrontendFr from "./Quota_View_Form.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as Quota_View_Table$DvmAdminFrontendFr from "./Quota_View_Table.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as App_DoubleSelectFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_DoubleSelectFormInput.bs.js";

function Quota_View_Filters(Props) {
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var alert = ReactAlert.useAlert();
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match$2[1];
  var quotaViewFilter = match$2[0].quotaViewFilter;
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDrAndDealers = match$3[1];
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var setContractOptions = match$4[1];
  var contractOptions = match$4[0];
  var match$5 = React.useState(function () {
        
      });
  var setContractLabel = match$5[1];
  var form = Quota_View_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.mapWithDefault(quotaViewFilter, Quota_View_Form$DvmAdminFrontendFr.initialState, (function (filter) {
              return filter;
            })), (function (param, submissionCallbacks) {
          Curry._2(fetchData, submissionCallbacks, {
                page: fetchRequest.page,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: Quota_View_Table$DvmAdminFrontendFr.headersConfiguration,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                              hd: param.dr,
                              tl: {
                                hd: param.dealerId,
                                tl: {
                                  hd: param.siret,
                                  tl: {
                                    hd: param.contractId,
                                    tl: {
                                      hd: param.quotaStartYear,
                                      tl: {
                                        hd: param.quotaEndYear,
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  React.useEffect((function () {
          Curry._2(form.updateQuotaStartYear, (function (input, quotaStartYear) {
                  return {
                          dr: input.dr,
                          dealerId: input.dealerId,
                          contractId: input.contractId,
                          contractLabel: input.contractLabel,
                          siret: input.siret,
                          quotaStartYear: quotaStartYear,
                          quotaEndYear: quotaStartYear
                        };
                }), Caml_option.some(new Date(Date.now())));
          App_Api$DvmAdminFrontendFr.queryForDRAndDealers(alert, userManager, setDrAndDealers, false, selectedRole);
          Quota_Api$DvmAdminFrontendFr.queryForContractIdSelect(alert, userManager, setContractOptions, selectedRole);
          if (Belt_Option.isSome(quotaViewFilter)) {
            Curry._1(form.submit, undefined);
          }
          
        }), []);
  React.useEffect((function () {
          Curry._1(setContractLabel, (function (param) {
                  return Belt_Option.getWithDefault(Belt_List.get(Belt_List.keepMap(contractOptions, (function (x) {
                                        if (x.paramOne === form.input.contractId) {
                                          return Caml_option.some(x.paramTwo);
                                        }
                                        
                                      })), 0), undefined);
                }));
        }), [form.input.contractId]);
  var tmp = {
    label: Messages_Quotas$DvmAdminFrontendFr.contractId,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.contractId,
        onChange: Curry._1(form.updateContractId, (function (input, contractId) {
                return {
                        dr: input.dr,
                        dealerId: input.dealerId,
                        contractId: contractId,
                        contractLabel: input.contractLabel,
                        siret: input.siret,
                        quotaStartYear: input.quotaStartYear,
                        quotaEndYear: input.quotaEndYear
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(contractOptions, (function (item) {
                          return item.paramOne;
                        })))))
    },
    required: /* OnlyLabel */1,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.contractIdResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.contractIdResult);
  }
  var tmp$1 = {
    onChange: Curry._1(form.updateSiret, (function (input, siret) {
            return {
                    dr: input.dr,
                    dealerId: input.dealerId,
                    contractId: input.contractId,
                    contractLabel: input.contractLabel,
                    siret: siret,
                    quotaStartYear: input.quotaStartYear,
                    quotaEndYear: input.quotaEndYear
                  };
          })),
    value: form.input.siret,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
    label: Messages_Common$DvmAdminFrontendFr.siret
  };
  if (form.siretResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.siretResult);
  }
  var tmp$2 = {
    label: Messages_Common$DvmAdminFrontendFr.year,
    value: form.input.quotaStartYear,
    onChange: Curry._1(form.updateQuotaStartYear, (function (input, quotaStartYear) {
            return {
                    dr: input.dr,
                    dealerId: input.dealerId,
                    contractId: input.contractId,
                    contractLabel: input.contractLabel,
                    siret: input.siret,
                    quotaStartYear: quotaStartYear,
                    quotaEndYear: quotaStartYear
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
    variant: /* YEAR */0,
    required: /* OnlyLabel */1
  };
  if (form.quotaStartYearResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.quotaStartYearResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(dispatch, {
                        TAG: /* StoreQuotaViewFilter */23,
                        _0: form.input
                      });
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_DoubleSelectFormInput$DvmAdminFrontendFr.make, {
                            labelParent: Messages_Common$DvmAdminFrontendFr.territory,
                            labelChild: Messages_Common$DvmAdminFrontendFr.dealerID,
                            valueParent: {
                              TAG: /* Multiple */1,
                              _0: {
                                value: form.input.dr,
                                onChange: Curry._1(form.updateDr, (function (input, dr) {
                                        return {
                                                dr: dr,
                                                dealerId: input.dealerId,
                                                contractId: input.contractId,
                                                contractLabel: input.contractLabel,
                                                siret: input.siret,
                                                quotaStartYear: input.quotaStartYear,
                                                quotaEndYear: input.quotaEndYear
                                              };
                                      }))
                              }
                            },
                            valueChild: {
                              TAG: /* Multiple */1,
                              _0: {
                                value: form.input.dealerId,
                                onChange: Curry._1(form.updateDealerId, (function (input, dealerId) {
                                        return {
                                                dr: input.dr,
                                                dealerId: dealerId,
                                                contractId: input.contractId,
                                                contractLabel: input.contractLabel,
                                                siret: input.siret,
                                                quotaStartYear: input.quotaStartYear,
                                                quotaEndYear: input.quotaEndYear
                                              };
                                      }))
                              }
                            },
                            options: App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$3[0], /* [] */0),
                            classNameParent: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInput,
                            classNameChild: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInput,
                            limitTagsParent: 2,
                            limitTagsChild: 2,
                            selectParentIfOnlyOne: true,
                            selectChildIfOnlyOne: true
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(Core.TextField, {
                            disabled: true,
                            label: intl.formatMessage(Messages_Quotas$DvmAdminFrontendFr.contractLabel),
                            value: Belt_Option.getWithDefault(match$5[0], "")
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$1),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$2),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.ok),
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var Form;

var Api;

var Table;

var make = Quota_View_Filters;

export {
  Form ,
  Api ,
  Table ,
  make ,
}
/* react Not a pure module */
