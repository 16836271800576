// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../../../route/Route_Paths.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../common/types/App_Types_Date.bs.js";

function routeToDetailEditUpdate(quota, param) {
  RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rQuotaDetailEdit + ("/" + quota.dr + "/" + quota.contractId + "/" + quota.vehicleType + "/" + App_Types_Date$DvmAdminFrontendFr.toString(quota.startDate) + ""));
}

function routeToDetailEditCreate(param) {
  RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rQuotaDetailCreate);
}

export {
  routeToDetailEditUpdate ,
  routeToDetailEditCreate ,
}
/* RescriptReactRouter Not a pure module */
