// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as Dealer_Search_Types$DvmAdminFrontendFr from "./Dealer_Search_Types.bs.js";

var validators_showHistory = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.showHistory
            };
    })
};

var validators_city = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Dealer_Search_Types$DvmAdminFrontendFr.Fields.city,
                    value: param.city
                  })
            };
    })
};

var validators_keyAccountManager = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Dealer_Search_Types$DvmAdminFrontendFr.Fields.ipnKeyAccountManager,
                    value: param.keyAccountManager
                  })
            };
    })
};

var validators_dealerName = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(undefined, {
                    fieldId: Dealer_Search_Types$DvmAdminFrontendFr.Fields.dealerName,
                    value: param.dealerName
                  })
            };
    })
};

var validators_dealerId = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParam(Dealer_Search_Types$DvmAdminFrontendFr.Fields.dealerId, param.dealerId)
            };
    })
};

var validators_dr = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParam(Dealer_Search_Types$DvmAdminFrontendFr.Fields.dr, param.dr)
            };
    })
};

var validators = {
  showHistory: validators_showHistory,
  city: validators_city,
  keyAccountManager: validators_keyAccountManager,
  dealerName: validators_dealerName,
  dealerId: validators_dealerId,
  dr: validators_dr
};

function initialFieldsStatuses(_input) {
  return {
          showHistory: /* Pristine */0,
          city: /* Pristine */0,
          keyAccountManager: /* Pristine */0,
          dealerName: /* Pristine */0,
          dealerId: /* Pristine */0,
          dr: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.showHistory;
  var match_0 = match ? match._0 : Curry._1(validators.showHistory.validate, input);
  var match$1 = fieldsStatuses.city;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.city.validate, input);
  var match$2 = fieldsStatuses.keyAccountManager;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.keyAccountManager.validate, input);
  var match$3 = fieldsStatuses.dealerName;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.dealerName.validate, input);
  var match$4 = fieldsStatuses.dealerId;
  var match_0$4 = match$4 ? match$4._0 : Curry._1(validators.dealerId.validate, input);
  var match$5 = fieldsStatuses.dr;
  var match_0$5 = match$5 ? match$5._0 : Curry._1(validators.dr.validate, input);
  var showHistoryResult = match_0;
  var showHistoryResult$1;
  if (showHistoryResult.TAG === /* Ok */0) {
    var cityResult = match_0$1;
    if (cityResult.TAG === /* Ok */0) {
      var keyAccountManagerResult = match_0$2;
      if (keyAccountManagerResult.TAG === /* Ok */0) {
        var dealerNameResult = match_0$3;
        if (dealerNameResult.TAG === /* Ok */0) {
          var dealerIdResult = match_0$4;
          if (dealerIdResult.TAG === /* Ok */0) {
            var drResult = match_0$5;
            if (drResult.TAG === /* Ok */0) {
              return {
                      TAG: /* Valid */0,
                      output: {
                        dr: drResult._0,
                        dealerId: dealerIdResult._0,
                        dealerName: dealerNameResult._0,
                        keyAccountManager: keyAccountManagerResult._0,
                        city: cityResult._0,
                        showHistory: showHistoryResult._0
                      },
                      fieldsStatuses: {
                        showHistory: /* Dirty */{
                          _0: showHistoryResult,
                          _1: /* Shown */0
                        },
                        city: /* Dirty */{
                          _0: cityResult,
                          _1: /* Shown */0
                        },
                        keyAccountManager: /* Dirty */{
                          _0: keyAccountManagerResult,
                          _1: /* Shown */0
                        },
                        dealerName: /* Dirty */{
                          _0: dealerNameResult,
                          _1: /* Shown */0
                        },
                        dealerId: /* Dirty */{
                          _0: dealerIdResult,
                          _1: /* Shown */0
                        },
                        dr: /* Dirty */{
                          _0: drResult,
                          _1: /* Shown */0
                        }
                      },
                      collectionsStatuses: undefined
                    };
            }
            showHistoryResult$1 = showHistoryResult;
          } else {
            showHistoryResult$1 = showHistoryResult;
          }
        } else {
          showHistoryResult$1 = showHistoryResult;
        }
      } else {
        showHistoryResult$1 = showHistoryResult;
      }
    } else {
      showHistoryResult$1 = showHistoryResult;
    }
  } else {
    showHistoryResult$1 = showHistoryResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            showHistory: /* Dirty */{
              _0: showHistoryResult$1,
              _1: /* Shown */0
            },
            city: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            keyAccountManager: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            dealerName: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            dealerId: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            dr: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurShowHistoryField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.showHistory, validators_showHistory, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showHistory: status,
                                  city: init.city,
                                  keyAccountManager: init.keyAccountManager,
                                  dealerName: init.dealerName,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCityField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.city, validators_city, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showHistory: init.showHistory,
                                  city: status,
                                  keyAccountManager: init.keyAccountManager,
                                  dealerName: init.dealerName,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurKeyAccountManagerField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.keyAccountManager, validators_keyAccountManager, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showHistory: init.showHistory,
                                  city: init.city,
                                  keyAccountManager: status,
                                  dealerName: init.dealerName,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDealerNameField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.dealerName, validators_dealerName, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showHistory: init.showHistory,
                                  city: init.city,
                                  keyAccountManager: init.keyAccountManager,
                                  dealerName: status,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDealerIdField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.dealerId, validators_dealerId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showHistory: init.showHistory,
                                  city: init.city,
                                  keyAccountManager: init.keyAccountManager,
                                  dealerName: init.dealerName,
                                  dealerId: status,
                                  dr: init.dr
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDrField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.dr, validators_dr, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showHistory: init.showHistory,
                                  city: init.city,
                                  keyAccountManager: init.keyAccountManager,
                                  dealerName: init.dealerName,
                                  dealerId: init.dealerId,
                                  dr: status
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */6 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */6,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */7,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */9);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */8);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */7 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */8 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */9 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateShowHistoryField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.showHistory, state.submissionStatus, validators_showHistory, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showHistory: status,
                                            city: init.city,
                                            keyAccountManager: init.keyAccountManager,
                                            dealerName: init.dealerName,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCityField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.city, state.submissionStatus, validators_city, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showHistory: init.showHistory,
                                            city: status,
                                            keyAccountManager: init.keyAccountManager,
                                            dealerName: init.dealerName,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateKeyAccountManagerField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.keyAccountManager, state.submissionStatus, validators_keyAccountManager, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showHistory: init.showHistory,
                                            city: init.city,
                                            keyAccountManager: status,
                                            dealerName: init.dealerName,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDealerNameField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.dealerName, state.submissionStatus, validators_dealerName, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showHistory: init.showHistory,
                                            city: init.city,
                                            keyAccountManager: init.keyAccountManager,
                                            dealerName: status,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDealerIdField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.dealerId, state.submissionStatus, validators_dealerId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showHistory: init.showHistory,
                                            city: init.city,
                                            keyAccountManager: init.keyAccountManager,
                                            dealerName: init.dealerName,
                                            dealerId: status,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDrField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.dr, state.submissionStatus, validators_dr, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showHistory: init.showHistory,
                                            city: init.city,
                                            keyAccountManager: init.keyAccountManager,
                                            dealerName: init.dealerName,
                                            dealerId: init.dealerId,
                                            dr: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */6 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */7 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */8 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateShowHistory: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateShowHistoryField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCity: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCityField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateKeyAccountManager: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateKeyAccountManagerField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDealerName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDealerNameField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDealerId: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDealerIdField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDr: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDrField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurShowHistory: (function (param) {
              Curry._1(dispatch, /* BlurShowHistoryField */0);
            }),
          blurCity: (function (param) {
              Curry._1(dispatch, /* BlurCityField */1);
            }),
          blurKeyAccountManager: (function (param) {
              Curry._1(dispatch, /* BlurKeyAccountManagerField */2);
            }),
          blurDealerName: (function (param) {
              Curry._1(dispatch, /* BlurDealerNameField */3);
            }),
          blurDealerId: (function (param) {
              Curry._1(dispatch, /* BlurDealerIdField */4);
            }),
          blurDr: (function (param) {
              Curry._1(dispatch, /* BlurDrField */5);
            }),
          showHistoryResult: Formality.exposeFieldResult(state.fieldsStatuses.showHistory),
          cityResult: Formality.exposeFieldResult(state.fieldsStatuses.city),
          keyAccountManagerResult: Formality.exposeFieldResult(state.fieldsStatuses.keyAccountManager),
          dealerNameResult: Formality.exposeFieldResult(state.fieldsStatuses.dealerName),
          dealerIdResult: Formality.exposeFieldResult(state.fieldsStatuses.dealerId),
          drResult: Formality.exposeFieldResult(state.fieldsStatuses.dr),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.showHistory || match.city || match.keyAccountManager || match.dealerName || match.dealerId || match.dr) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */6);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */7);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */8);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */8,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */9);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  dr: /* [] */0,
  dealerId: /* [] */0,
  dealerName: "",
  keyAccountManager: "",
  city: "",
  showHistory: false
};

export {
  TableFilterForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
