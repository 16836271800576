// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as ReDate_utils from "@mobily/rescript-date/src/ReDate_utils.bs.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as Quota_PARC_Api$DvmAdminFrontendFr from "../search/Quota_PARC_Api.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../common/components/App_LabeledText.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_SelectFormInput.bs.js";
import * as Messages_File_Upload$DvmAdminFrontendFr from "../../../intl/messages/Messages_File_Upload.bs.js";
import * as Messages_Quotas_PARC$DvmAdminFrontendFr from "../../../intl/messages/quotas/Messages_Quotas_PARC.bs.js";
import * as Messages_ModelVersion$DvmAdminFrontendFr from "../../../intl/messages/modelVersion/Messages_ModelVersion.bs.js";
import * as Quota_PARC_Manage_Api$DvmAdminFrontendFr from "./Quota_PARC_Manage_Api.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as Quota_PARC_Manage_Utils$DvmAdminFrontendFr from "./Quota_PARC_Manage_Utils.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_AutocompleteFormInput.bs.js";

var tooltip = Css.css({
      backgroundColor: "white",
      border: "1px solid black",
      whiteSpace: "pre-line"
    });

function Quota_PARC_Manage_Inputs(Props) {
  var operation = Props.operation;
  var form = Props.form;
  var onClose = Props.onClose;
  var allModels = Props.allModels;
  var drAndDealers = Props.drAndDealers;
  var inputBoxRef = Props.inputBoxRef;
  var conflicts = Props.conflicts;
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var intl = ReactIntl.useIntl();
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDealerNameResult = match$2[1];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setEngines = match$3[1];
  var engines = match$3[0];
  React.useEffect((function () {
          if (form.input.dealerId.length > 0) {
            Quota_PARC_Manage_Api$DvmAdminFrontendFr.fetchDealerName(userManager, setDealerNameResult, selectedRole, form.input.dealerId);
          }
          
        }), [form.input.dealerId]);
  React.useEffect((function () {
          if (form.input.modelId.length > 0) {
            Quota_PARC_Api$DvmAdminFrontendFr.getEngines(userManager, selectedRole, setEngines, form.input.modelId);
          }
          
        }), [form.input.modelId]);
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.orderType,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.orderType,
        onChange: Curry._1(form.updateOrderType, (function (input, orderType) {
                return {
                        orderType: orderType,
                        dealerId: input.dealerId,
                        modelId: input.modelId,
                        phase: input.phase,
                        engineType: input.engineType,
                        volume: input.volume,
                        startDate: input.startDate,
                        endDate: input.endDate
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: {
        hd: "VE",
        tl: {
          hd: "RR",
          tl: {
            hd: "RC",
            tl: /* [] */0
          }
        }
      }
    },
    disabled: operation ? true : false,
    required: /* OnlyLabel */1,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.orderTypeResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.orderTypeResult);
  }
  var tmp$1 = {
    label: Messages_Common$DvmAdminFrontendFr.dealerID,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.dealerId,
        onChange: Curry._1(form.updateDealerId, (function (input, dealerId) {
                return {
                        orderType: input.orderType,
                        dealerId: dealerId,
                        modelId: input.modelId,
                        phase: input.phase,
                        engineType: input.engineType,
                        volume: input.volume,
                        startDate: input.startDate,
                        endDate: input.endDate
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: Belt_List.concatMany(Belt_List.toArray(Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(drAndDealers, /* [] */0), (function (x) {
                      return x.child;
                    }))))
    },
    disabled: operation ? true : false,
    required: /* OnlyLabel */1,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.dealerIdResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.dealerIdResult);
  }
  var tmp$2 = {
    onChange: Curry._1(form.updateVolume, (function (input, volume) {
            return {
                    orderType: input.orderType,
                    dealerId: input.dealerId,
                    modelId: input.modelId,
                    phase: input.phase,
                    engineType: input.engineType,
                    volume: volume,
                    startDate: input.startDate,
                    endDate: input.endDate
                  };
          })),
    value: form.input.volume,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
    label: Messages_Quotas_Detail$DvmAdminFrontendFr.volume,
    required: /* OnlyLabel */1,
    inputType: "number"
  };
  if (form.volumeResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.volumeResult);
  }
  var tmp$3 = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    orderType: input.orderType,
                    dealerId: input.dealerId,
                    modelId: input.modelId,
                    phase: input.phase,
                    engineType: input.engineType,
                    volume: input.volume,
                    startDate: startDate,
                    endDate: input.endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
    required: /* OnlyLabel */1
  };
  if (form.startDateResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  var tmp$4 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    orderType: input.orderType,
                    dealerId: input.dealerId,
                    modelId: input.modelId,
                    phase: input.phase,
                    engineType: input.engineType,
                    volume: input.volume,
                    startDate: input.startDate,
                    endDate: endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
    required: /* OnlyLabel */1
  };
  if (form.endDateResult !== undefined) {
    tmp$4.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  return React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
              show: App_Types_Result$DvmAdminFrontendFr.isPending(allModels),
              children: React.createElement("form", {
                    onSubmit: (function ($$event) {
                        $$event.preventDefault();
                        Curry._1(form.submit, undefined);
                      })
                  }, React.createElement(Core.Grid, {
                        children: null,
                        container: true,
                        direction: "column",
                        item: true,
                        spacing: 2,
                        ref: inputBoxRef
                      }, React.createElement(Core.Grid, {
                            children: null,
                            container: true,
                            direction: "column",
                            item: true,
                            spacing: 3
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: null,
                                container: true,
                                item: true,
                                spacing: 3
                              }, React.createElement(Core.Grid, {
                                    children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$1),
                                    item: true
                                  }), React.createElement(Core.Grid, {
                                    children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                          label: Messages_Quotas_PARC$DvmAdminFrontendFr.dealerName,
                                          children: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(match$2[0], "-", (function (x) {
                                                  return Belt_Option.getWithDefault(x, "-");
                                                }))
                                        }),
                                    item: true
                                  })), React.createElement(Core.Grid, {
                                children: null,
                                container: true,
                                item: true,
                                spacing: 3
                              }, React.createElement(Core.Grid, {
                                    children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                          label: Messages_Common$DvmAdminFrontendFr.model,
                                          value: {
                                            TAG: /* Single */0,
                                            _0: {
                                              value: form.input.modelId,
                                              onChange: Curry._1(form.updateModelId, (function (input, modelId) {
                                                      return {
                                                              orderType: input.orderType,
                                                              dealerId: input.dealerId,
                                                              modelId: modelId,
                                                              phase: input.phase,
                                                              engineType: input.engineType,
                                                              volume: input.volume,
                                                              startDate: input.startDate,
                                                              endDate: input.endDate
                                                            };
                                                    }))
                                            }
                                          },
                                          options: {
                                            TAG: /* Unlabeled */0,
                                            _0: Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(allModels, /* [] */0), (function (x) {
                                                    return x.parent;
                                                  }))
                                          },
                                          className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                                        }),
                                    item: true
                                  }), React.createElement(Core.Grid, {
                                    children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, {
                                          label: Messages_Common$DvmAdminFrontendFr.phase,
                                          options: {
                                            hd: {
                                              label: {
                                                TAG: /* Message */0,
                                                _0: Messages_Common$DvmAdminFrontendFr.everything
                                              },
                                              value: ""
                                            },
                                            tl: {
                                              hd: {
                                                label: {
                                                  TAG: /* String */3,
                                                  _0: "1"
                                                },
                                                value: "1"
                                              },
                                              tl: {
                                                hd: {
                                                  label: {
                                                    TAG: /* String */3,
                                                    _0: "2"
                                                  },
                                                  value: "2"
                                                },
                                                tl: /* [] */0
                                              }
                                            }
                                          },
                                          value: {
                                            TAG: /* Single */0,
                                            _0: {
                                              value: form.input.phase,
                                              onChange: Curry._1(form.updatePhase, (function (input, phase) {
                                                      return {
                                                              orderType: input.orderType,
                                                              dealerId: input.dealerId,
                                                              modelId: input.modelId,
                                                              phase: phase,
                                                              engineType: input.engineType,
                                                              volume: input.volume,
                                                              startDate: input.startDate,
                                                              endDate: input.endDate
                                                            };
                                                    }))
                                            }
                                          },
                                          id: "phase",
                                          className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
                                        }),
                                    item: true
                                  }), React.createElement(Core.Grid, {
                                    children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                          show: App_Types_Result$DvmAdminFrontendFr.isPending(engines),
                                          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                                label: Messages_ModelVersion$DvmAdminFrontendFr.motorization,
                                                value: {
                                                  TAG: /* Single */0,
                                                  _0: {
                                                    value: form.input.engineType,
                                                    onChange: Curry._1(form.updateEngineType, (function (input, engineType) {
                                                            return {
                                                                    orderType: input.orderType,
                                                                    dealerId: input.dealerId,
                                                                    modelId: input.modelId,
                                                                    phase: input.phase,
                                                                    engineType: engineType,
                                                                    volume: input.volume,
                                                                    startDate: input.startDate,
                                                                    endDate: input.endDate
                                                                  };
                                                          }))
                                                  }
                                                },
                                                options: {
                                                  TAG: /* Unlabeled */0,
                                                  _0: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(engines, /* [] */0, (function (x) {
                                                          return Belt_List.sort(Belt_List.keep(Belt_List.concatMany(Belt_List.toArray(Belt_List.map(x, (function (y) {
                                                                                        return y.child;
                                                                                      })))), (function (y) {
                                                                            return y.length > 0;
                                                                          })), Caml.string_compare);
                                                        }))
                                                },
                                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                                              })
                                        }),
                                    item: true
                                  })), React.createElement(Core.Grid, {
                                children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$2),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                      style: {
                                        fontWeight: "600"
                                      },
                                      children: intl.formatMessage(Messages_Quotas_PARC$DvmAdminFrontendFr.quotaValidityTitle),
                                      variant: "subtitle"
                                    }),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: null,
                                container: true,
                                item: true,
                                spacing: 1
                              }, React.createElement(Core.Grid, {
                                    children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$3),
                                    item: true
                                  }), React.createElement(Core.Grid, {
                                    children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$4),
                                    item: true
                                  })), Belt_Option.mapWithDefault(form.input.endDate, null, (function (endDate) {
                                  var today = ReDate_utils.makeStartOfDayDate(new Date());
                                  if (ReDate.isBefore(endDate, today)) {
                                    return React.createElement(Core.Grid, {
                                                children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                      style: {
                                                        color: Theme_Colors$DvmAdminFrontendFr.accentOrangeHover,
                                                        fontWeight: "600"
                                                      },
                                                      children: intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.parcQuotaDateOlderWarn),
                                                      variant: "subtitle"
                                                    }),
                                                item: true
                                              });
                                  } else {
                                    return null;
                                  }
                                })), App_Types_Result$DvmAdminFrontendFr.mapWithDefault(conflicts, null, (function (conflictsList) {
                                  if (Belt_List.length(Quota_PARC_Manage_Utils$DvmAdminFrontendFr.conflictsClean(conflictsList, form, operation)) > 0) {
                                    return React.createElement(Core.Tooltip, {
                                                arrow: true,
                                                children: React.createElement(Core.Grid, {
                                                      alignItems: "center",
                                                      children: null,
                                                      container: true,
                                                      item: true,
                                                      spacing: 1,
                                                      style: {
                                                        width: "fit-content"
                                                      }
                                                    }, React.createElement(Core.Grid, {
                                                          children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                size: /* Large */3,
                                                                type_: /* Information */43,
                                                                color: Theme_Colors$DvmAdminFrontendFr.accentOrangeHover
                                                              }),
                                                          item: true
                                                        }), React.createElement(Core.Grid, {
                                                          children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                                style: {
                                                                  color: Theme_Colors$DvmAdminFrontendFr.accentOrangeHover,
                                                                  fontWeight: "600",
                                                                  maxWidth: "480px"
                                                                },
                                                                children: intl.formatMessage(Messages_Quotas_PARC$DvmAdminFrontendFr.overlapsWillBeDeleted),
                                                                variant: "subtitle"
                                                              }),
                                                          item: true
                                                        })),
                                                classes: {
                                                  tooltip: tooltip
                                                },
                                                placement: "right",
                                                title: Belt_Array.joinWith(Belt_List.toArray(Quota_PARC_Manage_Utils$DvmAdminFrontendFr.conflictsClean(conflictsList, form, operation)), "\n\n", (function (x) {
                                                        return Quota_PARC_Manage_Utils$DvmAdminFrontendFr.makeStringFromConflict(x, intl);
                                                      }))
                                              });
                                  } else {
                                    return null;
                                  }
                                }))), React.createElement(Core.Grid, {
                            children: null,
                            container: true,
                            item: true,
                            justifyContent: "space-between"
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                      onClick: (function (param) {
                                          Curry._1(onClose, undefined);
                                        }),
                                      label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.cancel),
                                      color: "primaryBorder",
                                      size: "medium"
                                    }),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                      show: App_Types_Result$DvmAdminFrontendFr.isPending(conflicts),
                                      children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                            label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                            size: "medium",
                                            disabled: !App_Types_Result$DvmAdminFrontendFr.isSuccess(conflicts),
                                            type_: "submit"
                                          })
                                    }),
                                item: true
                              }))))
            });
}

var Api;

var Form;

var InputStyles;

var Types;

var Utils;

var make = Quota_PARC_Manage_Inputs;

export {
  Api ,
  Form ,
  InputStyles ,
  Types ,
  Utils ,
  tooltip ,
  make ,
}
/* tooltip Not a pure module */
