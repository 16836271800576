// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as App_Modal$DvmAdminFrontendFr from "../../../../common/components/App_Modal.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Company_Manage$DvmAdminFrontendFr from "../../../../intl/messages/company/Messages_Company_Manage.bs.js";
import * as Company_Manage_Modal_Api$DvmAdminFrontendFr from "./Company_Manage_Modal_Api.bs.js";
import * as Company_Manage_Modal_Form$DvmAdminFrontendFr from "./Company_Manage_Modal_Form.bs.js";
import * as Company_Manage_Modal_Utils$DvmAdminFrontendFr from "./Company_Manage_Modal_Utils.bs.js";
import * as Company_Manage_Modal_Inputs$DvmAdminFrontendFr from "./Company_Manage_Modal_Inputs.bs.js";

function Company_Manage_Modal(Props) {
  var operation = Props.operation;
  var onClose = Props.onClose;
  var row = Props.row;
  var refresh = Props.refresh;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$2[1];
  var result = match$2[0];
  var form = Company_Manage_Modal_Form$DvmAdminFrontendFr.Form.useForm(Company_Manage_Modal_Utils$DvmAdminFrontendFr.populateForm(row), (function (output, submissionCallbacks) {
          Company_Manage_Modal_Api$DvmAdminFrontendFr.createOrEditSiret(submissionCallbacks, userManager, setResult, selectedRole, operation, {
                siret: output.siret,
                siren: output.siren,
                name: output.name,
                street: output.street,
                streetNbr: output.streetNbr,
                additionalAddressInfo: output.additionalAddressInfo,
                zipCode: output.zipCode,
                city: output.city,
                countryCode: output.countryCode,
                importDate: new Date().toISOString().replace("Z", "").replace("T", " "),
                budgetLLD: Belt_Option.mapWithDefault(row, undefined, (function (x) {
                        return x.budgetLLD;
                      })),
                budgetLCD: Belt_Option.mapWithDefault(row, undefined, (function (x) {
                        return x.budgetLCD;
                      })),
                budgetAVS: Belt_Option.mapWithDefault(row, undefined, (function (x) {
                        return x.budgetAVS;
                      })),
                budgetPROT: Belt_Option.mapWithDefault(row, undefined, (function (x) {
                        return x.budgetPROT;
                      })),
                hierarchy: output.hierarchy,
                createDate: operation === /* Create */0 ? Caml_option.some(new Date()) : undefined,
                codeApe: output.codeApe,
                virtual: false,
                endDate: output.endDate
              });
        }));
  React.useEffect((function () {
          if (typeof result === "number") {
            result === /* NotStarted */0;
          } else if (result.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(refresh, undefined);
            Curry._1(onClose, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: result._0
                      }
                    }));
          }
        }), [result]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: row !== undefined ? intl.formatMessage(Messages_Company_Manage$DvmAdminFrontendFr.editModalTitle) : intl.formatMessage(Messages_Company_Manage$DvmAdminFrontendFr.createTitle),
              minWidth: "800px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(result),
                    children: React.createElement(Company_Manage_Modal_Inputs$DvmAdminFrontendFr.make, {
                          form: form,
                          row: row
                        })
                  })
            });
}

var Api;

var Form;

var ModalUtils;

var make = Company_Manage_Modal;

export {
  Api ,
  Form ,
  ModalUtils ,
  make ,
}
/* react Not a pure module */
