// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_FinancialCodes$DvmAdminFrontendFr from "../../../intl/messages/easypack/Messages_FinancialCodes.bs.js";
import * as FinancialCode_Search_Form$DvmAdminFrontendFr from "./FinancialCode_Search_Form.bs.js";

function FinancialCode_Search_Filter(Props) {
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var form = FinancialCode_Search_Form$DvmAdminFrontendFr.TableFilterForm.useForm(FinancialCode_Search_Form$DvmAdminFrontendFr.initialState, (function (param, submissionCallbacks) {
          Curry._2(fetchData, submissionCallbacks, {
                page: 0,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: fetchRequest.headers,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                              hd: param.codeId,
                              tl: {
                                hd: param.codeLabel,
                                tl: {
                                  hd: param.codeType,
                                  tl: {
                                    hd: param.creationDate,
                                    tl: {
                                      hd: param.startDate,
                                      tl: {
                                        hd: param.endDate,
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.creationDate,
    value: form.input.creationDate,
    onChange: Curry._1(form.updateCreationDate, (function (input, creationDate) {
            return {
                    codeId: input.codeId,
                    codeLabel: input.codeLabel,
                    codeType: input.codeType,
                    creationDate: creationDate,
                    startDate: input.startDate,
                    endDate: input.endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.creationDateResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.creationDateResult);
  }
  var tmp$1 = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    codeId: input.codeId,
                    codeLabel: input.codeLabel,
                    codeType: input.codeType,
                    creationDate: input.creationDate,
                    startDate: startDate,
                    endDate: input.endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.startDateResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  var tmp$2 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    codeId: input.codeId,
                    codeLabel: input.codeLabel,
                    codeType: input.codeType,
                    creationDate: input.creationDate,
                    startDate: input.startDate,
                    endDate: endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.endDateResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry._1(form.updateCodeId, (function (input, codeId) {
                                    return {
                                            codeId: codeId,
                                            codeLabel: input.codeLabel,
                                            codeType: input.codeType,
                                            creationDate: input.creationDate,
                                            startDate: input.startDate,
                                            endDate: input.endDate
                                          };
                                  })),
                            value: form.input.codeId,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                            label: Messages_FinancialCodes$DvmAdminFrontendFr.codeId
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry._1(form.updateCodeLabel, (function (input, codeLabel) {
                                    return {
                                            codeId: input.codeId,
                                            codeLabel: codeLabel,
                                            codeType: input.codeType,
                                            creationDate: input.creationDate,
                                            startDate: input.startDate,
                                            endDate: input.endDate
                                          };
                                  })),
                            value: form.input.codeLabel,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                            label: Messages_FinancialCodes$DvmAdminFrontendFr.codeLabel
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$1),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$2),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var Style;

var Form;

var Api;

var Msg;

var make = FinancialCode_Search_Filter;

export {
  Style ,
  Form ,
  Api ,
  Msg ,
  make ,
}
/* react Not a pure module */
