// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../common/App_Typography.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Order_Detail_Correct_Api$DvmAdminFrontendFr from "../../Order_Detail_Correct_Api.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Messages_Orders_Correction$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_Correction.bs.js";

function Order_Detail_Correct_Perfs_New(Props) {
  var form = Props.form;
  var order = Props.order;
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var intl = ReactIntl.useIntl();
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setActions = match$2[1];
  var actions = match$2[0];
  React.useEffect((function () {
          Order_Detail_Correct_Api$DvmAdminFrontendFr.fetchPerfActions(userManager, setActions, selectedRole, order);
        }), []);
  var getPerfDisable = function (perfID) {
    if (Belt_Option.getWithDefault(order.archived, false)) {
      return true;
    } else {
      return Belt_Option.mapWithDefault(order.perf, false, (function (x) {
                    return Belt_Option.mapWithDefault(Belt_Array.getBy(x, (function (x) {
                                      return Belt_Option.getWithDefault(x.stringActionID, "") === Belt_Option.mapWithDefault(perfID, "", (function (x) {
                                                    return Belt_Option.getWithDefault(x.planPerfID, "");
                                                  }));
                                    })), false, (function (x) {
                                  if (Belt_Option.getWithDefault(x.state, /* MISSINGSTATUS */11) === /* PREINVOICED */5 || Belt_Option.getWithDefault(x.state, /* MISSINGSTATUS */11) === /* PAID */4) {
                                    return true;
                                  } else {
                                    return Belt_Option.getWithDefault(x.state, /* MISSINGSTATUS */11) === /* NOBONUS */3;
                                  }
                                }));
                  }));
    }
  };
  var disabledPerf1 = getPerfDisable(Belt_List.get(Belt_Option.getWithDefault(order.detail.perfDetails, /* [] */0), 0));
  var disabledPerf2 = getPerfDisable(Belt_List.get(Belt_Option.getWithDefault(order.detail.perfDetails, /* [] */0), 1));
  var disabledPerf3 = getPerfDisable(Belt_List.get(Belt_Option.getWithDefault(order.detail.perfDetails, /* [] */0), 2));
  React.useEffect((function () {
          var match = form.input.planPerfID;
          var __x = match === "" ? "" : "PERF";
          Curry._2(form.updateBonusActionTypePERF, (function (input, bonusActionTypePERF) {
                  return {
                          bonusActionTypePERF: bonusActionTypePERF,
                          bonusActionTypePERF2: input.bonusActionTypePERF2,
                          bonusActionTypePERF3: input.bonusActionTypePERF3,
                          planPerfAmount: input.planPerfAmount,
                          planPerfAmount2: input.planPerfAmount2,
                          planPerfAmount3: input.planPerfAmount3,
                          planPerfID: input.planPerfID,
                          planPerfID2: input.planPerfID2,
                          planPerfID3: input.planPerfID3
                        };
                }), __x);
          var match$1 = form.input.planPerfID2;
          var __x$1 = match$1 === "" ? "" : "PERF";
          Curry._2(form.updateBonusActionTypePERF2, (function (input, bonusActionTypePERF2) {
                  return {
                          bonusActionTypePERF: input.bonusActionTypePERF,
                          bonusActionTypePERF2: bonusActionTypePERF2,
                          bonusActionTypePERF3: input.bonusActionTypePERF3,
                          planPerfAmount: input.planPerfAmount,
                          planPerfAmount2: input.planPerfAmount2,
                          planPerfAmount3: input.planPerfAmount3,
                          planPerfID: input.planPerfID,
                          planPerfID2: input.planPerfID2,
                          planPerfID3: input.planPerfID3
                        };
                }), __x$1);
          var match$2 = form.input.planPerfID3;
          var __x$2 = match$2 === "" ? "" : "PERF";
          Curry._2(form.updateBonusActionTypePERF3, (function (input, bonusActionTypePERF3) {
                  return {
                          bonusActionTypePERF: input.bonusActionTypePERF,
                          bonusActionTypePERF2: input.bonusActionTypePERF2,
                          bonusActionTypePERF3: bonusActionTypePERF3,
                          planPerfAmount: input.planPerfAmount,
                          planPerfAmount2: input.planPerfAmount2,
                          planPerfAmount3: input.planPerfAmount3,
                          planPerfID: input.planPerfID,
                          planPerfID2: input.planPerfID2,
                          planPerfID3: input.planPerfID3
                        };
                }), __x$2);
          if (App_Types_Result$DvmAdminFrontendFr.isSuccess(actions)) {
            var match$3 = form.input.planPerfID;
            if (match$3 === "") {
              Curry._2(form.updatePlanPerfAmount, (function (input, planPerfAmount) {
                      return {
                              bonusActionTypePERF: input.bonusActionTypePERF,
                              bonusActionTypePERF2: input.bonusActionTypePERF2,
                              bonusActionTypePERF3: input.bonusActionTypePERF3,
                              planPerfAmount: planPerfAmount,
                              planPerfAmount2: input.planPerfAmount2,
                              planPerfAmount3: input.planPerfAmount3,
                              planPerfID: input.planPerfID,
                              planPerfID2: input.planPerfID2,
                              planPerfID3: input.planPerfID3
                            };
                    }), "0");
            }
            var match$4 = form.input.planPerfID2;
            if (match$4 === "") {
              Curry._2(form.updatePlanPerfAmount2, (function (input, planPerfAmount2) {
                      return {
                              bonusActionTypePERF: input.bonusActionTypePERF,
                              bonusActionTypePERF2: input.bonusActionTypePERF2,
                              bonusActionTypePERF3: input.bonusActionTypePERF3,
                              planPerfAmount: input.planPerfAmount,
                              planPerfAmount2: planPerfAmount2,
                              planPerfAmount3: input.planPerfAmount3,
                              planPerfID: input.planPerfID,
                              planPerfID2: input.planPerfID2,
                              planPerfID3: input.planPerfID3
                            };
                    }), "0");
            }
            var match$5 = form.input.planPerfID3;
            if (match$5 === "") {
              Curry._2(form.updatePlanPerfAmount3, (function (input, planPerfAmount3) {
                      return {
                              bonusActionTypePERF: input.bonusActionTypePERF,
                              bonusActionTypePERF2: input.bonusActionTypePERF2,
                              bonusActionTypePERF3: input.bonusActionTypePERF3,
                              planPerfAmount: input.planPerfAmount,
                              planPerfAmount2: input.planPerfAmount2,
                              planPerfAmount3: planPerfAmount3,
                              planPerfID: input.planPerfID,
                              planPerfID2: input.planPerfID2,
                              planPerfID3: input.planPerfID3
                            };
                    }), "0");
            }
            
          }
          
        }), [
        form.input.planPerfID,
        form.input.planPerfID2,
        form.input.planPerfID3
      ]);
  var tmp = {
    label: Messages_Orders_Correction$DvmAdminFrontendFr.idPerf,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.planPerfID,
        onChange: Curry._1(form.updatePlanPerfID, (function (input, planPerfID) {
                return {
                        bonusActionTypePERF: input.bonusActionTypePERF,
                        bonusActionTypePERF2: input.bonusActionTypePERF2,
                        bonusActionTypePERF3: input.bonusActionTypePERF3,
                        planPerfAmount: input.planPerfAmount,
                        planPerfAmount2: input.planPerfAmount2,
                        planPerfAmount3: input.planPerfAmount3,
                        planPerfID: planPerfID,
                        planPerfID2: input.planPerfID2,
                        planPerfID3: input.planPerfID3
                      };
              }))
      }
    },
    options: {
      TAG: /* Labeled */1,
      _0: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(actions, /* [] */0, (function (x) {
              return Belt_List.map(x, (function (x) {
                            return {
                                    label: {
                                      TAG: /* String */3,
                                      _0: "" + x.actionId + " (" + Belt_Option.getWithDefault(x.actionLabel, "") + ")"
                                    },
                                    value: x.actionId
                                  };
                          }));
            }))
    },
    disabled: disabledPerf1,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.planPerfIDResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.planPerfIDResult);
  }
  var tmp$1 = {
    label: Messages_Orders_Correction$DvmAdminFrontendFr.typePerf,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.bonusActionTypePERF,
        onChange: Curry._1(form.updateBonusActionTypePERF, (function (input, bonusActionTypePERF) {
                return {
                        bonusActionTypePERF: bonusActionTypePERF,
                        bonusActionTypePERF2: input.bonusActionTypePERF2,
                        bonusActionTypePERF3: input.bonusActionTypePERF3,
                        planPerfAmount: input.planPerfAmount,
                        planPerfAmount2: input.planPerfAmount2,
                        planPerfAmount3: input.planPerfAmount3,
                        planPerfID: input.planPerfID,
                        planPerfID2: input.planPerfID2,
                        planPerfID3: input.planPerfID3
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: {
        hd: "PERF",
        tl: /* [] */0
      }
    },
    disabled: disabledPerf1,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.bonusActionTypePERFResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.bonusActionTypePERFResult);
  }
  var tmp$2 = {
    onChange: Curry._1(form.updatePlanPerfAmount, (function (input, planPerfAmount) {
            return {
                    bonusActionTypePERF: input.bonusActionTypePERF,
                    bonusActionTypePERF2: input.bonusActionTypePERF2,
                    bonusActionTypePERF3: input.bonusActionTypePERF3,
                    planPerfAmount: planPerfAmount,
                    planPerfAmount2: input.planPerfAmount2,
                    planPerfAmount3: input.planPerfAmount3,
                    planPerfID: input.planPerfID,
                    planPerfID2: input.planPerfID2,
                    planPerfID3: input.planPerfID3
                  };
          })),
    value: form.input.planPerfAmount,
    disabled: disabledPerf1,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Orders_Correction$DvmAdminFrontendFr.amountPerf,
    InputProps: {
      startAdornment: React.createElement(Core.InputAdornment, {
            children: "€",
            position: "start"
          })
    },
    inputType: "number"
  };
  if (form.planPerfAmountResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.planPerfAmountResult);
  }
  var tmp$3 = {
    label: Messages_Orders_Correction$DvmAdminFrontendFr.idPerf2,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.planPerfID2,
        onChange: Curry._1(form.updatePlanPerfID2, (function (input, planPerfID2) {
                return {
                        bonusActionTypePERF: input.bonusActionTypePERF,
                        bonusActionTypePERF2: input.bonusActionTypePERF2,
                        bonusActionTypePERF3: input.bonusActionTypePERF3,
                        planPerfAmount: input.planPerfAmount,
                        planPerfAmount2: input.planPerfAmount2,
                        planPerfAmount3: input.planPerfAmount3,
                        planPerfID: input.planPerfID,
                        planPerfID2: planPerfID2,
                        planPerfID3: input.planPerfID3
                      };
              }))
      }
    },
    options: {
      TAG: /* Labeled */1,
      _0: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(actions, /* [] */0, (function (x) {
              return Belt_List.map(x, (function (x) {
                            return {
                                    label: {
                                      TAG: /* String */3,
                                      _0: "" + x.actionId + " (" + Belt_Option.getWithDefault(x.actionLabel, "") + ")"
                                    },
                                    value: x.actionId
                                  };
                          }));
            }))
    },
    disabled: disabledPerf2,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.planPerfID2Result !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.planPerfID2Result);
  }
  var tmp$4 = {
    label: Messages_Orders_Correction$DvmAdminFrontendFr.typePerf2,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.bonusActionTypePERF2,
        onChange: Curry._1(form.updateBonusActionTypePERF2, (function (input, bonusActionTypePERF2) {
                return {
                        bonusActionTypePERF: input.bonusActionTypePERF,
                        bonusActionTypePERF2: bonusActionTypePERF2,
                        bonusActionTypePERF3: input.bonusActionTypePERF3,
                        planPerfAmount: input.planPerfAmount,
                        planPerfAmount2: input.planPerfAmount2,
                        planPerfAmount3: input.planPerfAmount3,
                        planPerfID: input.planPerfID,
                        planPerfID2: input.planPerfID2,
                        planPerfID3: input.planPerfID3
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: {
        hd: "PERF",
        tl: /* [] */0
      }
    },
    disabled: disabledPerf2,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.bonusActionTypePERF2Result !== undefined) {
    tmp$4.validationResult = Caml_option.valFromOption(form.bonusActionTypePERF2Result);
  }
  var tmp$5 = {
    onChange: Curry._1(form.updatePlanPerfAmount2, (function (input, planPerfAmount2) {
            return {
                    bonusActionTypePERF: input.bonusActionTypePERF,
                    bonusActionTypePERF2: input.bonusActionTypePERF2,
                    bonusActionTypePERF3: input.bonusActionTypePERF3,
                    planPerfAmount: input.planPerfAmount,
                    planPerfAmount2: planPerfAmount2,
                    planPerfAmount3: input.planPerfAmount3,
                    planPerfID: input.planPerfID,
                    planPerfID2: input.planPerfID2,
                    planPerfID3: input.planPerfID3
                  };
          })),
    value: form.input.planPerfAmount2,
    disabled: disabledPerf2,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Orders_Correction$DvmAdminFrontendFr.amountPerf2,
    InputProps: {
      startAdornment: React.createElement(Core.InputAdornment, {
            children: "€",
            position: "start"
          })
    },
    inputType: "number"
  };
  if (form.planPerfAmount2Result !== undefined) {
    tmp$5.validationResult = Caml_option.valFromOption(form.planPerfAmount2Result);
  }
  var tmp$6 = {
    label: Messages_Orders_Correction$DvmAdminFrontendFr.idPerf3,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.planPerfID3,
        onChange: Curry._1(form.updatePlanPerfID3, (function (input, planPerfID3) {
                return {
                        bonusActionTypePERF: input.bonusActionTypePERF,
                        bonusActionTypePERF2: input.bonusActionTypePERF2,
                        bonusActionTypePERF3: input.bonusActionTypePERF3,
                        planPerfAmount: input.planPerfAmount,
                        planPerfAmount2: input.planPerfAmount2,
                        planPerfAmount3: input.planPerfAmount3,
                        planPerfID: input.planPerfID,
                        planPerfID2: input.planPerfID2,
                        planPerfID3: planPerfID3
                      };
              }))
      }
    },
    options: {
      TAG: /* Labeled */1,
      _0: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(actions, /* [] */0, (function (x) {
              return Belt_List.map(x, (function (x) {
                            return {
                                    label: {
                                      TAG: /* String */3,
                                      _0: "" + x.actionId + " (" + Belt_Option.getWithDefault(x.actionLabel, "") + ")"
                                    },
                                    value: x.actionId
                                  };
                          }));
            }))
    },
    disabled: disabledPerf3,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.planPerfID3Result !== undefined) {
    tmp$6.validationResult = Caml_option.valFromOption(form.planPerfID3Result);
  }
  var tmp$7 = {
    label: Messages_Orders_Correction$DvmAdminFrontendFr.typePerf3,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.bonusActionTypePERF3,
        onChange: Curry._1(form.updateBonusActionTypePERF3, (function (input, bonusActionTypePERF3) {
                return {
                        bonusActionTypePERF: input.bonusActionTypePERF,
                        bonusActionTypePERF2: input.bonusActionTypePERF2,
                        bonusActionTypePERF3: bonusActionTypePERF3,
                        planPerfAmount: input.planPerfAmount,
                        planPerfAmount2: input.planPerfAmount2,
                        planPerfAmount3: input.planPerfAmount3,
                        planPerfID: input.planPerfID,
                        planPerfID2: input.planPerfID2,
                        planPerfID3: input.planPerfID3
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: {
        hd: "PERF",
        tl: /* [] */0
      }
    },
    disabled: disabledPerf3,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.bonusActionTypePERF3Result !== undefined) {
    tmp$7.validationResult = Caml_option.valFromOption(form.bonusActionTypePERF3Result);
  }
  var tmp$8 = {
    onChange: Curry._1(form.updatePlanPerfAmount3, (function (input, planPerfAmount3) {
            return {
                    bonusActionTypePERF: input.bonusActionTypePERF,
                    bonusActionTypePERF2: input.bonusActionTypePERF2,
                    bonusActionTypePERF3: input.bonusActionTypePERF3,
                    planPerfAmount: input.planPerfAmount,
                    planPerfAmount2: input.planPerfAmount2,
                    planPerfAmount3: planPerfAmount3,
                    planPerfID: input.planPerfID,
                    planPerfID2: input.planPerfID2,
                    planPerfID3: input.planPerfID3
                  };
          })),
    value: form.input.planPerfAmount3,
    disabled: disabledPerf3,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Orders_Correction$DvmAdminFrontendFr.amountPerf3,
    InputProps: {
      startAdornment: React.createElement(Core.InputAdornment, {
            children: "€",
            position: "start"
          })
    },
    inputType: "number"
  };
  if (form.planPerfAmount3Result !== undefined) {
    tmp$8.validationResult = Caml_option.valFromOption(form.planPerfAmount3Result);
  }
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              direction: "column",
              item: true,
              sm: Grid$Mui.Sm[6],
              spacing: 2
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        style: {
                          color: Theme_Colors$DvmAdminFrontendFr.secondaryDarkGreenDark,
                          fontWeight: "600"
                        },
                        children: intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.correctionValues)
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Core.Box, {
                        children: React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              direction: "column",
                              spacing: 5
                            }, React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  direction: "column",
                                  item: true,
                                  spacing: 1
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$1),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$2),
                                      item: true
                                    })), React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  direction: "column",
                                  item: true,
                                  spacing: 1
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$3),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$4),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$5),
                                      item: true
                                    })), React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  direction: "column",
                                  item: true,
                                  spacing: 1
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$6),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$7),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$8),
                                      item: true
                                    }))),
                        bgcolor: Theme_Colors$DvmAdminFrontendFr.grayLight1,
                        borderColor: Theme_Colors$DvmAdminFrontendFr.secondaryDarkGreenDark,
                        borderLeft: 4,
                        boxShadow: "0 2px 7px 1px #eee",
                        padding: "24px 32px"
                      }),
                  item: true
                }));
}

var Form;

var Api;

var InputStyles;

var make = Order_Detail_Correct_Perfs_New;

export {
  Form ,
  Api ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
