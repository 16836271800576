// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as File_Upload_Button$DvmAdminFrontendFr from "../../../common/fileUpload/File_Upload_Button.bs.js";
import * as Messages_Quotas_PARC$DvmAdminFrontendFr from "../../../intl/messages/quotas/Messages_Quotas_PARC.bs.js";
import * as Quota_PARC_Manage_Api$DvmAdminFrontendFr from "./Quota_PARC_Manage_Api.bs.js";

function Quota_PARC_Manage_Excel_Upload(Props) {
  var inputsHeight = Props.inputsHeight;
  var alert = ReactAlert.useAlert();
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDealersResult = match$2[1];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelsResult = match$3[1];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTemplateResult = match$4[1];
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              direction: "column",
              item: true,
              spacing: 5,
              style: {
                height: String(inputsHeight) + "px",
                width: "50%"
              }
            }, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true,
                  spacing: 2
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                            show: App_Types_Result$DvmAdminFrontendFr.isPending(match$2[0]),
                            children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                  onClick: (function (param) {
                                      Quota_PARC_Manage_Api$DvmAdminFrontendFr.fetchExcel(userManager, setDealersResult, selectedRole, alert, "dealers", "dealers/false");
                                    }),
                                  label: intl.formatMessage(Messages_Quotas_PARC$DvmAdminFrontendFr.downloadDealers),
                                  color: "secondary",
                                  size: "medium",
                                  style: {
                                    width: "100%"
                                  }
                                })
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                            show: App_Types_Result$DvmAdminFrontendFr.isPending(match$3[0]),
                            children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                  onClick: (function (param) {
                                      Quota_PARC_Manage_Api$DvmAdminFrontendFr.fetchExcel(userManager, setModelsResult, selectedRole, alert, "modeles", "modelversion/model");
                                    }),
                                  label: intl.formatMessage(Messages_Quotas_PARC$DvmAdminFrontendFr.downloadModels),
                                  color: "secondary",
                                  size: "medium",
                                  style: {
                                    width: "100%"
                                  }
                                })
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                            show: App_Types_Result$DvmAdminFrontendFr.isPending(match$4[0]),
                            children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                  onClick: (function (param) {
                                      Quota_PARC_Manage_Api$DvmAdminFrontendFr.fetchTemplate(userManager, setTemplateResult, selectedRole, alert);
                                    }),
                                  label: intl.formatMessage(Messages_Quotas_PARC$DvmAdminFrontendFr.documentTemplate),
                                  color: "secondary",
                                  size: "medium",
                                  style: {
                                    width: "100%"
                                  }
                                })
                          }),
                      item: true
                    })), React.createElement(Core.Divider, {}), React.createElement(Core.Grid, {
                  children: React.createElement(File_Upload_Button$DvmAdminFrontendFr.make, {
                        title: Messages_Quotas_PARC$DvmAdminFrontendFr.uploadDocument,
                        table: /* QUOTAS */0,
                        fullWidth: true
                      }),
                  item: true
                }));
}

var Api;

var make = Quota_PARC_Manage_Excel_Upload;

export {
  Api ,
  make ,
}
/* react Not a pure module */
