// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Table$DvmAdminFrontendFr from "../../common/components/table/App_Table.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../common/utils/Utils_Api.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../common/types/App_Types_Result.bs.js";
import * as Model_Version_Types$DvmAdminFrontendFr from "./Model_Version_Types.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../common/components/table/App_Table_Pagination.bs.js";
import * as App_Table_Export_Menu$DvmAdminFrontendFr from "../../common/components/table/App_Table_Export_Menu.bs.js";
import * as Messages_ModelVersion$DvmAdminFrontendFr from "../../intl/messages/modelVersion/Messages_ModelVersion.bs.js";

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Model_Version_Types$DvmAdminFrontendFr.Fields.model, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.model);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Model_Version_Types$DvmAdminFrontendFr.Fields.modelLabel, undefined, undefined, undefined, undefined, undefined, Messages_ModelVersion$DvmAdminFrontendFr.labelModel),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Model_Version_Types$DvmAdminFrontendFr.Fields.tariff, /* Desc */1, undefined, undefined, undefined, undefined, Messages_ModelVersion$DvmAdminFrontendFr.tarifNumber),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Model_Version_Types$DvmAdminFrontendFr.Fields.modelVehicleType, undefined, undefined, undefined, undefined, undefined, Messages_ModelVersion$DvmAdminFrontendFr._type),
      tl: {
        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Model_Version_Types$DvmAdminFrontendFr.Fields.modelVehiclePhase, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.phase),
        tl: {
          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Model_Version_Types$DvmAdminFrontendFr.Fields.modelActive, undefined, undefined, undefined, undefined, undefined, Messages_ModelVersion$DvmAdminFrontendFr.active),
          tl: /* [] */0
        }
      }
    }
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function ModelVersion_Model_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var intl = ReactIntl.useIntl();
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (row) {
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, undefined, undefined, Belt_List.mapWithIndex({
                                                  hd: row.model,
                                                  tl: {
                                                    hd: row.modelLabel,
                                                    tl: {
                                                      hd: Belt_Option.getWithDefault(row.tariff, ""),
                                                      tl: {
                                                        hd: Belt_Option.getWithDefault(row.modelVehicleType, ""),
                                                        tl: {
                                                          hd: Belt_Option.getWithDefault(row.modelVehiclePhase, ""),
                                                          tl: {
                                                            hd: Belt_Option.mapWithDefault(row.modelActive, "", (function (x) {
                                                                    return intl.formatMessage(x ? Messages_Common$DvmAdminFrontendFr.yes : Messages_Common$DvmAdminFrontendFr.no);
                                                                  })),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._3(fetchData, undefined, /* Model */0, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._3(fetchData, undefined, /* Model */0, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._3(fetchData, undefined, /* Model */0, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  return React.createElement(App_Table$DvmAdminFrontendFr.make, {
              headers: fetchRequest.headers,
              handleSorting: handleSorting,
              tablePage: convertTable(tablePage),
              paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined),
              exportMenu: React.createElement(App_Table_Export_Menu$DvmAdminFrontendFr.make, {
                    query: Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                          page: 0,
                          rowsPerPage: 150000,
                          headers: fetchRequest.headers,
                          filterQueryParams: fetchRequest.filterQueryParams,
                          additionalQueryParams: fetchRequest.additionalQueryParams
                        }),
                    endpoint: "modelversion/model"
                  })
            });
}

var Types;

var make = ModelVersion_Model_Table;

export {
  Types ,
  headersConfiguration ,
  make ,
}
/* headersConfiguration Not a pure module */
