// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as App_Spinner$DvmAdminFrontendFr from "../../../../common/components/App_Spinner.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../../../route/Route_Paths.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Flash_Detail_Api$DvmAdminFrontendFr from "../Flash_Detail_Api.bs.js";
import * as Flash_Detail_Styles$DvmAdminFrontendFr from "./Flash_Detail_Styles.bs.js";
import * as Messages_Flash_Detail$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Detail.bs.js";

function Flash_Detail_Non_Cumulables(Props) {
  var flash = Props.flash;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$2[1];
  var result = match$2[0];
  React.useEffect((function () {
          Flash_Detail_Api$DvmAdminFrontendFr.getCumulables(alert, userManager, setResult, flash.actionId, selectedRole);
        }), []);
  if (typeof result === "number") {
    result === /* NotStarted */0;
  } else {
    if (result.TAG !== /* Ok */0) {
      return null;
    }
    var nonCumulables = result._0;
    if (Belt_List.length(nonCumulables) > 0) {
      return React.createElement(React.Fragment, undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                      color: "primary",
                      children: intl.formatMessage(Messages_Flash_Detail$DvmAdminFrontendFr.nonCumulableActions),
                      variant: "h3"
                    }), React.createElement(Core.Grid, {
                      children: Belt_List.toArray(Belt_List.mapWithIndex(nonCumulables, (function (index, action) {
                                  return React.createElement(Core.Grid, {
                                              alignItems: "center",
                                              children: null,
                                              className: Flash_Detail_Styles$DvmAdminFrontendFr.detailRow,
                                              container: true,
                                              item: true,
                                              key: String(index)
                                            }, React.createElement(Core.Button, {
                                                  onClick: (function (param) {
                                                      RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rView + Route_Paths$DvmAdminFrontendFr.rFlash + "/nonfleet/" + action.paramOne);
                                                    }),
                                                  children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                        size: /* Large */3,
                                                        type_: /* Search */49
                                                      })
                                                }), React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                  style: {
                                                    fontWeight: "600"
                                                  },
                                                  children: action.paramOne + Belt_Option.mapWithDefault(action.paramTwo, "", (function (x) {
                                                          if (x.length > 0) {
                                                            return " (" + x + ")";
                                                          } else {
                                                            return "";
                                                          }
                                                        }))
                                                }));
                                }))),
                      className: Flash_Detail_Styles$DvmAdminFrontendFr.detailBox,
                      container: true,
                      direction: "column"
                    }));
    } else {
      return null;
    }
  }
  return React.createElement(App_Spinner$DvmAdminFrontendFr.make, {
              size: /* Fixed */{
                _0: "20px"
              }
            });
}

var make = Flash_Detail_Non_Cumulables;

export {
  make ,
}
/* react Not a pure module */
