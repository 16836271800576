// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Api.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Form.bs.js";
import * as Flash_Search_Types$DvmAdminFrontendFr from "../../Flash_Search_Types.bs.js";

var validators_exitParcEndTo = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderType = param$1.orderType;
      var exitParcEndFrom = param.exitParcEndFrom;
      if (Belt_List.every(orderType, (function (x) {
                switch (x) {
                  case "RC" :
                  case "RR" :
                  case "VE" :
                      return true;
                  default:
                    return false;
                }
              })) || Belt_List.length(orderType) === 0) {
        return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.exitParcEndTo, undefined), (function (date) {
                      return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(exitParcEndFrom), Caml_option.some(date), Flash_Search_Types$DvmAdminFrontendFr.Fields.exitDateParcEnd, "normal");
                    }));
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    })
};

var validators_exitParcEndFrom = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderType = param$1.orderType;
      var exitParcEndTo = param.exitParcEndTo;
      if (Belt_List.every(orderType, (function (x) {
                switch (x) {
                  case "RC" :
                  case "RR" :
                  case "VE" :
                      return true;
                  default:
                    return false;
                }
              })) || Belt_List.length(orderType) === 0) {
        return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.exitParcEndFrom, undefined), (function (date) {
                      return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(date), Caml_option.some(exitParcEndTo), Flash_Search_Types$DvmAdminFrontendFr.Fields.exitDateParcEnd, "normal");
                    }));
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    })
};

var validators_exitParcStartTo = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderType = param$1.orderType;
      var exitParcStartFrom = param.exitParcStartFrom;
      if (Belt_List.every(orderType, (function (x) {
                switch (x) {
                  case "RC" :
                  case "RR" :
                  case "VE" :
                      return true;
                  default:
                    return false;
                }
              })) || Belt_List.length(orderType) === 0) {
        return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.exitParcStartTo, undefined), (function (date) {
                      return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(exitParcStartFrom), Caml_option.some(date), Flash_Search_Types$DvmAdminFrontendFr.Fields.exitDateParcStart, "normal");
                    }));
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    })
};

var validators_exitParcStartFrom = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderType = param$1.orderType;
      var exitParcStartTo = param.exitParcStartTo;
      if (Belt_List.every(orderType, (function (x) {
                switch (x) {
                  case "RC" :
                  case "RR" :
                  case "VE" :
                      return true;
                  default:
                    return false;
                }
              })) || Belt_List.length(orderType) === 0) {
        return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.exitParcStartFrom, undefined), (function (date) {
                      return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(date), Caml_option.some(exitParcStartTo), Flash_Search_Types$DvmAdminFrontendFr.Fields.exitDateParcStart, "normal");
                    }));
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    })
};

var validators_entryParcEndTo = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderType = param$1.orderType;
      var entryParcEndFrom = param.entryParcEndFrom;
      if (Belt_List.every(orderType, (function (x) {
                switch (x) {
                  case "RC" :
                  case "RR" :
                  case "VE" :
                      return true;
                  default:
                    return false;
                }
              })) || Belt_List.length(orderType) === 0) {
        return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.entryParcEndTo, undefined), (function (date) {
                      return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(entryParcEndFrom), Caml_option.some(date), Flash_Search_Types$DvmAdminFrontendFr.Fields.entryDateParcEnd, "normal");
                    }));
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    })
};

var validators_entryParcEndFrom = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderType = param$1.orderType;
      var entryParcEndTo = param.entryParcEndTo;
      if (Belt_List.every(orderType, (function (x) {
                switch (x) {
                  case "RC" :
                  case "RR" :
                  case "VE" :
                      return true;
                  default:
                    return false;
                }
              })) || Belt_List.length(orderType) === 0) {
        return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.entryParcEndFrom, undefined), (function (date) {
                      return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(date), Caml_option.some(entryParcEndTo), Flash_Search_Types$DvmAdminFrontendFr.Fields.entryDateParcEnd, "normal");
                    }));
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    })
};

var validators_entryParcStartTo = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderType = param$1.orderType;
      var entryParcStartFrom = param.entryParcStartFrom;
      if (Belt_List.every(orderType, (function (x) {
                switch (x) {
                  case "RC" :
                  case "RR" :
                  case "VE" :
                      return true;
                  default:
                    return false;
                }
              })) || Belt_List.length(orderType) === 0) {
        return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.entryParcStartTo, undefined), (function (date) {
                      return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(entryParcStartFrom), Caml_option.some(date), Flash_Search_Types$DvmAdminFrontendFr.Fields.entryDateParcStart, "normal");
                    }));
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    })
};

var validators_entryParcStartFrom = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderType = param$1.orderType;
      var entryParcStartTo = param.entryParcStartTo;
      if (Belt_List.every(orderType, (function (x) {
                switch (x) {
                  case "RC" :
                  case "RR" :
                  case "VE" :
                      return true;
                  default:
                    return false;
                }
              })) || Belt_List.length(orderType) === 0) {
        return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.entryParcStartFrom, undefined), (function (date) {
                      return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(date), Caml_option.some(entryParcStartTo), Flash_Search_Types$DvmAdminFrontendFr.Fields.entryDateParcStart, "normal");
                    }));
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    })
};

var validators_madcEndTo = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var madcEndFrom = param.madcEndFrom;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.madcEndTo, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(madcEndFrom), Caml_option.some(date), Flash_Search_Types$DvmAdminFrontendFr.Fields.madcDateEnd, "normal");
                  }));
    })
};

var validators_madcEndFrom = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var madcEndTo = param.madcEndTo;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.madcEndFrom, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(date), Caml_option.some(madcEndTo), Flash_Search_Types$DvmAdminFrontendFr.Fields.madcDateEnd, "normal");
                  }));
    })
};

var validators_madcStartTo = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var madcStartFrom = param.madcStartFrom;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.madcStartTo, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(madcStartFrom), Caml_option.some(date), Flash_Search_Types$DvmAdminFrontendFr.Fields.madcDateStart, "normal");
                  }));
    })
};

var validators_madcStartFrom = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var madcStartTo = param.madcStartTo;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.madcStartFrom, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(date), Caml_option.some(madcStartTo), Flash_Search_Types$DvmAdminFrontendFr.Fields.madcDateStart, "normal");
                  }));
    })
};

var validators = {
  exitParcEndTo: validators_exitParcEndTo,
  exitParcEndFrom: validators_exitParcEndFrom,
  exitParcStartTo: validators_exitParcStartTo,
  exitParcStartFrom: validators_exitParcStartFrom,
  entryParcEndTo: validators_entryParcEndTo,
  entryParcEndFrom: validators_entryParcEndFrom,
  entryParcStartTo: validators_entryParcStartTo,
  entryParcStartFrom: validators_entryParcStartFrom,
  madcEndTo: validators_madcEndTo,
  madcEndFrom: validators_madcEndFrom,
  madcStartTo: validators_madcStartTo,
  madcStartFrom: validators_madcStartFrom
};

function initialFieldsStatuses(_input) {
  return {
          exitParcEndTo: /* Pristine */0,
          exitParcEndFrom: /* Pristine */0,
          exitParcStartTo: /* Pristine */0,
          exitParcStartFrom: /* Pristine */0,
          entryParcEndTo: /* Pristine */0,
          entryParcEndFrom: /* Pristine */0,
          entryParcStartTo: /* Pristine */0,
          entryParcStartFrom: /* Pristine */0,
          madcEndTo: /* Pristine */0,
          madcEndFrom: /* Pristine */0,
          madcStartTo: /* Pristine */0,
          madcStartFrom: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match = fieldsStatuses.exitParcEndTo;
  var match_0 = match ? match._0 : Curry._2(validators.exitParcEndTo.validate, input, metadata);
  var match$1 = fieldsStatuses.exitParcEndFrom;
  var match_0$1 = match$1 ? match$1._0 : Curry._2(validators.exitParcEndFrom.validate, input, metadata);
  var match$2 = fieldsStatuses.exitParcStartTo;
  var match_0$2 = match$2 ? match$2._0 : Curry._2(validators.exitParcStartTo.validate, input, metadata);
  var match$3 = fieldsStatuses.exitParcStartFrom;
  var match_0$3 = match$3 ? match$3._0 : Curry._2(validators.exitParcStartFrom.validate, input, metadata);
  var match$4 = fieldsStatuses.entryParcEndTo;
  var match_0$4 = match$4 ? match$4._0 : Curry._2(validators.entryParcEndTo.validate, input, metadata);
  var match$5 = fieldsStatuses.entryParcEndFrom;
  var match_0$5 = match$5 ? match$5._0 : Curry._2(validators.entryParcEndFrom.validate, input, metadata);
  var match$6 = fieldsStatuses.entryParcStartTo;
  var match_0$6 = match$6 ? match$6._0 : Curry._2(validators.entryParcStartTo.validate, input, metadata);
  var match$7 = fieldsStatuses.entryParcStartFrom;
  var match_0$7 = match$7 ? match$7._0 : Curry._2(validators.entryParcStartFrom.validate, input, metadata);
  var match$8 = fieldsStatuses.madcEndTo;
  var match_0$8 = match$8 ? match$8._0 : Curry._2(validators.madcEndTo.validate, input, metadata);
  var match$9 = fieldsStatuses.madcEndFrom;
  var match_0$9 = match$9 ? match$9._0 : Curry._2(validators.madcEndFrom.validate, input, metadata);
  var match$10 = fieldsStatuses.madcStartTo;
  var match_0$10 = match$10 ? match$10._0 : Curry._2(validators.madcStartTo.validate, input, metadata);
  var match$11 = fieldsStatuses.madcStartFrom;
  var match_0$11 = match$11 ? match$11._0 : Curry._2(validators.madcStartFrom.validate, input, metadata);
  var exitParcEndToResult = match_0;
  var exitParcEndToResult$1;
  if (exitParcEndToResult.TAG === /* Ok */0) {
    var exitParcEndFromResult = match_0$1;
    if (exitParcEndFromResult.TAG === /* Ok */0) {
      var exitParcStartToResult = match_0$2;
      if (exitParcStartToResult.TAG === /* Ok */0) {
        var exitParcStartFromResult = match_0$3;
        if (exitParcStartFromResult.TAG === /* Ok */0) {
          var entryParcEndToResult = match_0$4;
          if (entryParcEndToResult.TAG === /* Ok */0) {
            var entryParcEndFromResult = match_0$5;
            if (entryParcEndFromResult.TAG === /* Ok */0) {
              var entryParcStartToResult = match_0$6;
              if (entryParcStartToResult.TAG === /* Ok */0) {
                var entryParcStartFromResult = match_0$7;
                if (entryParcStartFromResult.TAG === /* Ok */0) {
                  var madcEndToResult = match_0$8;
                  if (madcEndToResult.TAG === /* Ok */0) {
                    var madcEndFromResult = match_0$9;
                    if (madcEndFromResult.TAG === /* Ok */0) {
                      var madcStartToResult = match_0$10;
                      if (madcStartToResult.TAG === /* Ok */0) {
                        var madcStartFromResult = match_0$11;
                        if (madcStartFromResult.TAG === /* Ok */0) {
                          return {
                                  TAG: /* Valid */0,
                                  output: {
                                    madcStartFrom: madcStartFromResult._0,
                                    madcStartTo: madcStartToResult._0,
                                    madcEndFrom: madcEndFromResult._0,
                                    madcEndTo: madcEndToResult._0,
                                    entryParcStartFrom: entryParcStartFromResult._0,
                                    entryParcStartTo: entryParcStartToResult._0,
                                    entryParcEndFrom: entryParcEndFromResult._0,
                                    entryParcEndTo: entryParcEndToResult._0,
                                    exitParcStartFrom: exitParcStartFromResult._0,
                                    exitParcStartTo: exitParcStartToResult._0,
                                    exitParcEndFrom: exitParcEndFromResult._0,
                                    exitParcEndTo: exitParcEndToResult._0
                                  },
                                  fieldsStatuses: {
                                    exitParcEndTo: /* Dirty */{
                                      _0: exitParcEndToResult,
                                      _1: /* Shown */0
                                    },
                                    exitParcEndFrom: /* Dirty */{
                                      _0: exitParcEndFromResult,
                                      _1: /* Shown */0
                                    },
                                    exitParcStartTo: /* Dirty */{
                                      _0: exitParcStartToResult,
                                      _1: /* Shown */0
                                    },
                                    exitParcStartFrom: /* Dirty */{
                                      _0: exitParcStartFromResult,
                                      _1: /* Shown */0
                                    },
                                    entryParcEndTo: /* Dirty */{
                                      _0: entryParcEndToResult,
                                      _1: /* Shown */0
                                    },
                                    entryParcEndFrom: /* Dirty */{
                                      _0: entryParcEndFromResult,
                                      _1: /* Shown */0
                                    },
                                    entryParcStartTo: /* Dirty */{
                                      _0: entryParcStartToResult,
                                      _1: /* Shown */0
                                    },
                                    entryParcStartFrom: /* Dirty */{
                                      _0: entryParcStartFromResult,
                                      _1: /* Shown */0
                                    },
                                    madcEndTo: /* Dirty */{
                                      _0: madcEndToResult,
                                      _1: /* Shown */0
                                    },
                                    madcEndFrom: /* Dirty */{
                                      _0: madcEndFromResult,
                                      _1: /* Shown */0
                                    },
                                    madcStartTo: /* Dirty */{
                                      _0: madcStartToResult,
                                      _1: /* Shown */0
                                    },
                                    madcStartFrom: /* Dirty */{
                                      _0: madcStartFromResult,
                                      _1: /* Shown */0
                                    }
                                  },
                                  collectionsStatuses: undefined
                                };
                        }
                        exitParcEndToResult$1 = exitParcEndToResult;
                      } else {
                        exitParcEndToResult$1 = exitParcEndToResult;
                      }
                    } else {
                      exitParcEndToResult$1 = exitParcEndToResult;
                    }
                  } else {
                    exitParcEndToResult$1 = exitParcEndToResult;
                  }
                } else {
                  exitParcEndToResult$1 = exitParcEndToResult;
                }
              } else {
                exitParcEndToResult$1 = exitParcEndToResult;
              }
            } else {
              exitParcEndToResult$1 = exitParcEndToResult;
            }
          } else {
            exitParcEndToResult$1 = exitParcEndToResult;
          }
        } else {
          exitParcEndToResult$1 = exitParcEndToResult;
        }
      } else {
        exitParcEndToResult$1 = exitParcEndToResult;
      }
    } else {
      exitParcEndToResult$1 = exitParcEndToResult;
    }
  } else {
    exitParcEndToResult$1 = exitParcEndToResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            exitParcEndTo: /* Dirty */{
              _0: exitParcEndToResult$1,
              _1: /* Shown */0
            },
            exitParcEndFrom: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            exitParcStartTo: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            exitParcStartFrom: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            entryParcEndTo: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            entryParcEndFrom: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            entryParcStartTo: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            entryParcStartFrom: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            },
            madcEndTo: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            },
            madcEndFrom: /* Dirty */{
              _0: match_0$9,
              _1: /* Shown */0
            },
            madcStartTo: /* Dirty */{
              _0: match_0$10,
              _1: /* Shown */0
            },
            madcStartFrom: /* Dirty */{
              _0: match_0$11,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurExitParcEndToField */0 :
                  var result = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.exitParcEndTo, validators_exitParcEndTo, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  exitParcEndTo: status,
                                  exitParcEndFrom: init.exitParcEndFrom,
                                  exitParcStartTo: init.exitParcStartTo,
                                  exitParcStartFrom: init.exitParcStartFrom,
                                  entryParcEndTo: init.entryParcEndTo,
                                  entryParcEndFrom: init.entryParcEndFrom,
                                  entryParcStartTo: init.entryParcStartTo,
                                  entryParcStartFrom: init.entryParcStartFrom,
                                  madcEndTo: init.madcEndTo,
                                  madcEndFrom: init.madcEndFrom,
                                  madcStartTo: init.madcStartTo,
                                  madcStartFrom: init.madcStartFrom
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurExitParcEndFromField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.exitParcEndFrom, validators_exitParcEndFrom, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  exitParcEndTo: init.exitParcEndTo,
                                  exitParcEndFrom: status,
                                  exitParcStartTo: init.exitParcStartTo,
                                  exitParcStartFrom: init.exitParcStartFrom,
                                  entryParcEndTo: init.entryParcEndTo,
                                  entryParcEndFrom: init.entryParcEndFrom,
                                  entryParcStartTo: init.entryParcStartTo,
                                  entryParcStartFrom: init.entryParcStartFrom,
                                  madcEndTo: init.madcEndTo,
                                  madcEndFrom: init.madcEndFrom,
                                  madcStartTo: init.madcStartTo,
                                  madcStartFrom: init.madcStartFrom
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurExitParcStartToField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.exitParcStartTo, validators_exitParcStartTo, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  exitParcEndTo: init.exitParcEndTo,
                                  exitParcEndFrom: init.exitParcEndFrom,
                                  exitParcStartTo: status,
                                  exitParcStartFrom: init.exitParcStartFrom,
                                  entryParcEndTo: init.entryParcEndTo,
                                  entryParcEndFrom: init.entryParcEndFrom,
                                  entryParcStartTo: init.entryParcStartTo,
                                  entryParcStartFrom: init.entryParcStartFrom,
                                  madcEndTo: init.madcEndTo,
                                  madcEndFrom: init.madcEndFrom,
                                  madcStartTo: init.madcStartTo,
                                  madcStartFrom: init.madcStartFrom
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurExitParcStartFromField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.exitParcStartFrom, validators_exitParcStartFrom, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  exitParcEndTo: init.exitParcEndTo,
                                  exitParcEndFrom: init.exitParcEndFrom,
                                  exitParcStartTo: init.exitParcStartTo,
                                  exitParcStartFrom: status,
                                  entryParcEndTo: init.entryParcEndTo,
                                  entryParcEndFrom: init.entryParcEndFrom,
                                  entryParcStartTo: init.entryParcStartTo,
                                  entryParcStartFrom: init.entryParcStartFrom,
                                  madcEndTo: init.madcEndTo,
                                  madcEndFrom: init.madcEndFrom,
                                  madcStartTo: init.madcStartTo,
                                  madcStartFrom: init.madcStartFrom
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEntryParcEndToField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.entryParcEndTo, validators_entryParcEndTo, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  exitParcEndTo: init.exitParcEndTo,
                                  exitParcEndFrom: init.exitParcEndFrom,
                                  exitParcStartTo: init.exitParcStartTo,
                                  exitParcStartFrom: init.exitParcStartFrom,
                                  entryParcEndTo: status,
                                  entryParcEndFrom: init.entryParcEndFrom,
                                  entryParcStartTo: init.entryParcStartTo,
                                  entryParcStartFrom: init.entryParcStartFrom,
                                  madcEndTo: init.madcEndTo,
                                  madcEndFrom: init.madcEndFrom,
                                  madcStartTo: init.madcStartTo,
                                  madcStartFrom: init.madcStartFrom
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEntryParcEndFromField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.entryParcEndFrom, validators_entryParcEndFrom, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  exitParcEndTo: init.exitParcEndTo,
                                  exitParcEndFrom: init.exitParcEndFrom,
                                  exitParcStartTo: init.exitParcStartTo,
                                  exitParcStartFrom: init.exitParcStartFrom,
                                  entryParcEndTo: init.entryParcEndTo,
                                  entryParcEndFrom: status,
                                  entryParcStartTo: init.entryParcStartTo,
                                  entryParcStartFrom: init.entryParcStartFrom,
                                  madcEndTo: init.madcEndTo,
                                  madcEndFrom: init.madcEndFrom,
                                  madcStartTo: init.madcStartTo,
                                  madcStartFrom: init.madcStartFrom
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEntryParcStartToField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.entryParcStartTo, validators_entryParcStartTo, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  exitParcEndTo: init.exitParcEndTo,
                                  exitParcEndFrom: init.exitParcEndFrom,
                                  exitParcStartTo: init.exitParcStartTo,
                                  exitParcStartFrom: init.exitParcStartFrom,
                                  entryParcEndTo: init.entryParcEndTo,
                                  entryParcEndFrom: init.entryParcEndFrom,
                                  entryParcStartTo: status,
                                  entryParcStartFrom: init.entryParcStartFrom,
                                  madcEndTo: init.madcEndTo,
                                  madcEndFrom: init.madcEndFrom,
                                  madcStartTo: init.madcStartTo,
                                  madcStartFrom: init.madcStartFrom
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEntryParcStartFromField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.entryParcStartFrom, validators_entryParcStartFrom, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  exitParcEndTo: init.exitParcEndTo,
                                  exitParcEndFrom: init.exitParcEndFrom,
                                  exitParcStartTo: init.exitParcStartTo,
                                  exitParcStartFrom: init.exitParcStartFrom,
                                  entryParcEndTo: init.entryParcEndTo,
                                  entryParcEndFrom: init.entryParcEndFrom,
                                  entryParcStartTo: init.entryParcStartTo,
                                  entryParcStartFrom: status,
                                  madcEndTo: init.madcEndTo,
                                  madcEndFrom: init.madcEndFrom,
                                  madcStartTo: init.madcStartTo,
                                  madcStartFrom: init.madcStartFrom
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurMadcEndToField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.madcEndTo, validators_madcEndTo, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  exitParcEndTo: init.exitParcEndTo,
                                  exitParcEndFrom: init.exitParcEndFrom,
                                  exitParcStartTo: init.exitParcStartTo,
                                  exitParcStartFrom: init.exitParcStartFrom,
                                  entryParcEndTo: init.entryParcEndTo,
                                  entryParcEndFrom: init.entryParcEndFrom,
                                  entryParcStartTo: init.entryParcStartTo,
                                  entryParcStartFrom: init.entryParcStartFrom,
                                  madcEndTo: status,
                                  madcEndFrom: init.madcEndFrom,
                                  madcStartTo: init.madcStartTo,
                                  madcStartFrom: init.madcStartFrom
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurMadcEndFromField */9 :
                  var result$9 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.madcEndFrom, validators_madcEndFrom, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  exitParcEndTo: init.exitParcEndTo,
                                  exitParcEndFrom: init.exitParcEndFrom,
                                  exitParcStartTo: init.exitParcStartTo,
                                  exitParcStartFrom: init.exitParcStartFrom,
                                  entryParcEndTo: init.entryParcEndTo,
                                  entryParcEndFrom: init.entryParcEndFrom,
                                  entryParcStartTo: init.entryParcStartTo,
                                  entryParcStartFrom: init.entryParcStartFrom,
                                  madcEndTo: init.madcEndTo,
                                  madcEndFrom: status,
                                  madcStartTo: init.madcStartTo,
                                  madcStartFrom: init.madcStartFrom
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurMadcStartToField */10 :
                  var result$10 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.madcStartTo, validators_madcStartTo, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  exitParcEndTo: init.exitParcEndTo,
                                  exitParcEndFrom: init.exitParcEndFrom,
                                  exitParcStartTo: init.exitParcStartTo,
                                  exitParcStartFrom: init.exitParcStartFrom,
                                  entryParcEndTo: init.entryParcEndTo,
                                  entryParcEndFrom: init.entryParcEndFrom,
                                  entryParcStartTo: init.entryParcStartTo,
                                  entryParcStartFrom: init.entryParcStartFrom,
                                  madcEndTo: init.madcEndTo,
                                  madcEndFrom: init.madcEndFrom,
                                  madcStartTo: status,
                                  madcStartFrom: init.madcStartFrom
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurMadcStartFromField */11 :
                  var result$11 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.madcStartFrom, validators_madcStartFrom, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  exitParcEndTo: init.exitParcEndTo,
                                  exitParcEndFrom: init.exitParcEndFrom,
                                  exitParcStartTo: init.exitParcStartTo,
                                  exitParcStartFrom: init.exitParcStartFrom,
                                  entryParcEndTo: init.entryParcEndTo,
                                  entryParcEndFrom: init.entryParcEndFrom,
                                  entryParcStartTo: init.entryParcStartTo,
                                  entryParcStartFrom: init.entryParcStartFrom,
                                  madcEndTo: init.madcEndTo,
                                  madcEndFrom: init.madcEndFrom,
                                  madcStartTo: init.madcStartTo,
                                  madcStartFrom: status
                                };
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */12 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */12,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */13,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */15);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */14);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */13 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */14 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */15 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateExitParcEndToField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$12 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput, metadata, nextFieldsStatuses.contents.exitParcEndFrom, validators_exitParcEndFrom, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  exitParcEndTo: init.exitParcEndTo,
                                  exitParcEndFrom: status,
                                  exitParcStartTo: init.exitParcStartTo,
                                  exitParcStartFrom: init.exitParcStartFrom,
                                  entryParcEndTo: init.entryParcEndTo,
                                  entryParcEndFrom: init.entryParcEndFrom,
                                  entryParcStartTo: init.entryParcStartTo,
                                  entryParcStartFrom: init.entryParcStartFrom,
                                  madcEndTo: init.madcEndTo,
                                  madcEndFrom: init.madcEndFrom,
                                  madcStartTo: init.madcStartTo,
                                  madcStartFrom: init.madcStartFrom
                                };
                        }));
                  if (result$12 !== undefined) {
                    nextFieldsStatuses.contents = result$12;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput, metadata, nextFieldsStatuses.contents.exitParcEndTo, state.submissionStatus, validators_exitParcEndTo, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            exitParcEndTo: status,
                                            exitParcEndFrom: init.exitParcEndFrom,
                                            exitParcStartTo: init.exitParcStartTo,
                                            exitParcStartFrom: init.exitParcStartFrom,
                                            entryParcEndTo: init.entryParcEndTo,
                                            entryParcEndFrom: init.entryParcEndFrom,
                                            entryParcStartTo: init.entryParcStartTo,
                                            entryParcStartFrom: init.entryParcStartFrom,
                                            madcEndTo: init.madcEndTo,
                                            madcEndFrom: init.madcEndFrom,
                                            madcStartTo: init.madcStartTo,
                                            madcStartFrom: init.madcStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateExitParcEndFromField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$13 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$1, metadata, nextFieldsStatuses$1.contents.exitParcEndTo, validators_exitParcEndTo, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  exitParcEndTo: status,
                                  exitParcEndFrom: init.exitParcEndFrom,
                                  exitParcStartTo: init.exitParcStartTo,
                                  exitParcStartFrom: init.exitParcStartFrom,
                                  entryParcEndTo: init.entryParcEndTo,
                                  entryParcEndFrom: init.entryParcEndFrom,
                                  entryParcStartTo: init.entryParcStartTo,
                                  entryParcStartFrom: init.entryParcStartFrom,
                                  madcEndTo: init.madcEndTo,
                                  madcEndFrom: init.madcEndFrom,
                                  madcStartTo: init.madcStartTo,
                                  madcStartFrom: init.madcStartFrom
                                };
                        }));
                  if (result$13 !== undefined) {
                    nextFieldsStatuses$1.contents = result$13;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$1, metadata, nextFieldsStatuses$1.contents.exitParcEndFrom, state.submissionStatus, validators_exitParcEndFrom, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            exitParcEndTo: init.exitParcEndTo,
                                            exitParcEndFrom: status,
                                            exitParcStartTo: init.exitParcStartTo,
                                            exitParcStartFrom: init.exitParcStartFrom,
                                            entryParcEndTo: init.entryParcEndTo,
                                            entryParcEndFrom: init.entryParcEndFrom,
                                            entryParcStartTo: init.entryParcStartTo,
                                            entryParcStartFrom: init.entryParcStartFrom,
                                            madcEndTo: init.madcEndTo,
                                            madcEndFrom: init.madcEndFrom,
                                            madcStartTo: init.madcStartTo,
                                            madcStartFrom: init.madcStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateExitParcStartToField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$2 = {
                    contents: state.fieldsStatuses
                  };
                  var result$14 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$2, metadata, nextFieldsStatuses$2.contents.exitParcStartFrom, validators_exitParcStartFrom, (function (status) {
                          var init = nextFieldsStatuses$2.contents;
                          return {
                                  exitParcEndTo: init.exitParcEndTo,
                                  exitParcEndFrom: init.exitParcEndFrom,
                                  exitParcStartTo: init.exitParcStartTo,
                                  exitParcStartFrom: status,
                                  entryParcEndTo: init.entryParcEndTo,
                                  entryParcEndFrom: init.entryParcEndFrom,
                                  entryParcStartTo: init.entryParcStartTo,
                                  entryParcStartFrom: init.entryParcStartFrom,
                                  madcEndTo: init.madcEndTo,
                                  madcEndFrom: init.madcEndFrom,
                                  madcStartTo: init.madcStartTo,
                                  madcStartFrom: init.madcStartFrom
                                };
                        }));
                  if (result$14 !== undefined) {
                    nextFieldsStatuses$2.contents = result$14;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$2, metadata, nextFieldsStatuses$2.contents.exitParcStartTo, state.submissionStatus, validators_exitParcStartTo, (function (status) {
                                    var init = nextFieldsStatuses$2.contents;
                                    return {
                                            exitParcEndTo: init.exitParcEndTo,
                                            exitParcEndFrom: init.exitParcEndFrom,
                                            exitParcStartTo: status,
                                            exitParcStartFrom: init.exitParcStartFrom,
                                            entryParcEndTo: init.entryParcEndTo,
                                            entryParcEndFrom: init.entryParcEndFrom,
                                            entryParcStartTo: init.entryParcStartTo,
                                            entryParcStartFrom: init.entryParcStartFrom,
                                            madcEndTo: init.madcEndTo,
                                            madcEndFrom: init.madcEndFrom,
                                            madcStartTo: init.madcStartTo,
                                            madcStartFrom: init.madcStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateExitParcStartFromField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$3 = {
                    contents: state.fieldsStatuses
                  };
                  var result$15 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$3, metadata, nextFieldsStatuses$3.contents.exitParcStartTo, validators_exitParcStartTo, (function (status) {
                          var init = nextFieldsStatuses$3.contents;
                          return {
                                  exitParcEndTo: init.exitParcEndTo,
                                  exitParcEndFrom: init.exitParcEndFrom,
                                  exitParcStartTo: status,
                                  exitParcStartFrom: init.exitParcStartFrom,
                                  entryParcEndTo: init.entryParcEndTo,
                                  entryParcEndFrom: init.entryParcEndFrom,
                                  entryParcStartTo: init.entryParcStartTo,
                                  entryParcStartFrom: init.entryParcStartFrom,
                                  madcEndTo: init.madcEndTo,
                                  madcEndFrom: init.madcEndFrom,
                                  madcStartTo: init.madcStartTo,
                                  madcStartFrom: init.madcStartFrom
                                };
                        }));
                  if (result$15 !== undefined) {
                    nextFieldsStatuses$3.contents = result$15;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$3, metadata, nextFieldsStatuses$3.contents.exitParcStartFrom, state.submissionStatus, validators_exitParcStartFrom, (function (status) {
                                    var init = nextFieldsStatuses$3.contents;
                                    return {
                                            exitParcEndTo: init.exitParcEndTo,
                                            exitParcEndFrom: init.exitParcEndFrom,
                                            exitParcStartTo: init.exitParcStartTo,
                                            exitParcStartFrom: status,
                                            entryParcEndTo: init.entryParcEndTo,
                                            entryParcEndFrom: init.entryParcEndFrom,
                                            entryParcStartTo: init.entryParcStartTo,
                                            entryParcStartFrom: init.entryParcStartFrom,
                                            madcEndTo: init.madcEndTo,
                                            madcEndFrom: init.madcEndFrom,
                                            madcStartTo: init.madcStartTo,
                                            madcStartFrom: init.madcStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEntryParcEndToField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$4 = {
                    contents: state.fieldsStatuses
                  };
                  var result$16 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$4, metadata, nextFieldsStatuses$4.contents.entryParcEndFrom, validators_entryParcEndFrom, (function (status) {
                          var init = nextFieldsStatuses$4.contents;
                          return {
                                  exitParcEndTo: init.exitParcEndTo,
                                  exitParcEndFrom: init.exitParcEndFrom,
                                  exitParcStartTo: init.exitParcStartTo,
                                  exitParcStartFrom: init.exitParcStartFrom,
                                  entryParcEndTo: init.entryParcEndTo,
                                  entryParcEndFrom: status,
                                  entryParcStartTo: init.entryParcStartTo,
                                  entryParcStartFrom: init.entryParcStartFrom,
                                  madcEndTo: init.madcEndTo,
                                  madcEndFrom: init.madcEndFrom,
                                  madcStartTo: init.madcStartTo,
                                  madcStartFrom: init.madcStartFrom
                                };
                        }));
                  if (result$16 !== undefined) {
                    nextFieldsStatuses$4.contents = result$16;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$4, metadata, nextFieldsStatuses$4.contents.entryParcEndTo, state.submissionStatus, validators_entryParcEndTo, (function (status) {
                                    var init = nextFieldsStatuses$4.contents;
                                    return {
                                            exitParcEndTo: init.exitParcEndTo,
                                            exitParcEndFrom: init.exitParcEndFrom,
                                            exitParcStartTo: init.exitParcStartTo,
                                            exitParcStartFrom: init.exitParcStartFrom,
                                            entryParcEndTo: status,
                                            entryParcEndFrom: init.entryParcEndFrom,
                                            entryParcStartTo: init.entryParcStartTo,
                                            entryParcStartFrom: init.entryParcStartFrom,
                                            madcEndTo: init.madcEndTo,
                                            madcEndFrom: init.madcEndFrom,
                                            madcStartTo: init.madcStartTo,
                                            madcStartFrom: init.madcStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEntryParcEndFromField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$5 = {
                    contents: state.fieldsStatuses
                  };
                  var result$17 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$5, metadata, nextFieldsStatuses$5.contents.entryParcEndTo, validators_entryParcEndTo, (function (status) {
                          var init = nextFieldsStatuses$5.contents;
                          return {
                                  exitParcEndTo: init.exitParcEndTo,
                                  exitParcEndFrom: init.exitParcEndFrom,
                                  exitParcStartTo: init.exitParcStartTo,
                                  exitParcStartFrom: init.exitParcStartFrom,
                                  entryParcEndTo: status,
                                  entryParcEndFrom: init.entryParcEndFrom,
                                  entryParcStartTo: init.entryParcStartTo,
                                  entryParcStartFrom: init.entryParcStartFrom,
                                  madcEndTo: init.madcEndTo,
                                  madcEndFrom: init.madcEndFrom,
                                  madcStartTo: init.madcStartTo,
                                  madcStartFrom: init.madcStartFrom
                                };
                        }));
                  if (result$17 !== undefined) {
                    nextFieldsStatuses$5.contents = result$17;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$5, metadata, nextFieldsStatuses$5.contents.entryParcEndFrom, state.submissionStatus, validators_entryParcEndFrom, (function (status) {
                                    var init = nextFieldsStatuses$5.contents;
                                    return {
                                            exitParcEndTo: init.exitParcEndTo,
                                            exitParcEndFrom: init.exitParcEndFrom,
                                            exitParcStartTo: init.exitParcStartTo,
                                            exitParcStartFrom: init.exitParcStartFrom,
                                            entryParcEndTo: init.entryParcEndTo,
                                            entryParcEndFrom: status,
                                            entryParcStartTo: init.entryParcStartTo,
                                            entryParcStartFrom: init.entryParcStartFrom,
                                            madcEndTo: init.madcEndTo,
                                            madcEndFrom: init.madcEndFrom,
                                            madcStartTo: init.madcStartTo,
                                            madcStartFrom: init.madcStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEntryParcStartToField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$6 = {
                    contents: state.fieldsStatuses
                  };
                  var result$18 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$6, metadata, nextFieldsStatuses$6.contents.entryParcStartFrom, validators_entryParcStartFrom, (function (status) {
                          var init = nextFieldsStatuses$6.contents;
                          return {
                                  exitParcEndTo: init.exitParcEndTo,
                                  exitParcEndFrom: init.exitParcEndFrom,
                                  exitParcStartTo: init.exitParcStartTo,
                                  exitParcStartFrom: init.exitParcStartFrom,
                                  entryParcEndTo: init.entryParcEndTo,
                                  entryParcEndFrom: init.entryParcEndFrom,
                                  entryParcStartTo: init.entryParcStartTo,
                                  entryParcStartFrom: status,
                                  madcEndTo: init.madcEndTo,
                                  madcEndFrom: init.madcEndFrom,
                                  madcStartTo: init.madcStartTo,
                                  madcStartFrom: init.madcStartFrom
                                };
                        }));
                  if (result$18 !== undefined) {
                    nextFieldsStatuses$6.contents = result$18;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$6, metadata, nextFieldsStatuses$6.contents.entryParcStartTo, state.submissionStatus, validators_entryParcStartTo, (function (status) {
                                    var init = nextFieldsStatuses$6.contents;
                                    return {
                                            exitParcEndTo: init.exitParcEndTo,
                                            exitParcEndFrom: init.exitParcEndFrom,
                                            exitParcStartTo: init.exitParcStartTo,
                                            exitParcStartFrom: init.exitParcStartFrom,
                                            entryParcEndTo: init.entryParcEndTo,
                                            entryParcEndFrom: init.entryParcEndFrom,
                                            entryParcStartTo: status,
                                            entryParcStartFrom: init.entryParcStartFrom,
                                            madcEndTo: init.madcEndTo,
                                            madcEndFrom: init.madcEndFrom,
                                            madcStartTo: init.madcStartTo,
                                            madcStartFrom: init.madcStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEntryParcStartFromField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$7 = {
                    contents: state.fieldsStatuses
                  };
                  var result$19 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$7, metadata, nextFieldsStatuses$7.contents.entryParcStartTo, validators_entryParcStartTo, (function (status) {
                          var init = nextFieldsStatuses$7.contents;
                          return {
                                  exitParcEndTo: init.exitParcEndTo,
                                  exitParcEndFrom: init.exitParcEndFrom,
                                  exitParcStartTo: init.exitParcStartTo,
                                  exitParcStartFrom: init.exitParcStartFrom,
                                  entryParcEndTo: init.entryParcEndTo,
                                  entryParcEndFrom: init.entryParcEndFrom,
                                  entryParcStartTo: status,
                                  entryParcStartFrom: init.entryParcStartFrom,
                                  madcEndTo: init.madcEndTo,
                                  madcEndFrom: init.madcEndFrom,
                                  madcStartTo: init.madcStartTo,
                                  madcStartFrom: init.madcStartFrom
                                };
                        }));
                  if (result$19 !== undefined) {
                    nextFieldsStatuses$7.contents = result$19;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$7, metadata, nextFieldsStatuses$7.contents.entryParcStartFrom, state.submissionStatus, validators_entryParcStartFrom, (function (status) {
                                    var init = nextFieldsStatuses$7.contents;
                                    return {
                                            exitParcEndTo: init.exitParcEndTo,
                                            exitParcEndFrom: init.exitParcEndFrom,
                                            exitParcStartTo: init.exitParcStartTo,
                                            exitParcStartFrom: init.exitParcStartFrom,
                                            entryParcEndTo: init.entryParcEndTo,
                                            entryParcEndFrom: init.entryParcEndFrom,
                                            entryParcStartTo: init.entryParcStartTo,
                                            entryParcStartFrom: status,
                                            madcEndTo: init.madcEndTo,
                                            madcEndFrom: init.madcEndFrom,
                                            madcStartTo: init.madcStartTo,
                                            madcStartFrom: init.madcStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateMadcEndToField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$8 = {
                    contents: state.fieldsStatuses
                  };
                  var result$20 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$8, metadata, nextFieldsStatuses$8.contents.madcEndFrom, validators_madcEndFrom, (function (status) {
                          var init = nextFieldsStatuses$8.contents;
                          return {
                                  exitParcEndTo: init.exitParcEndTo,
                                  exitParcEndFrom: init.exitParcEndFrom,
                                  exitParcStartTo: init.exitParcStartTo,
                                  exitParcStartFrom: init.exitParcStartFrom,
                                  entryParcEndTo: init.entryParcEndTo,
                                  entryParcEndFrom: init.entryParcEndFrom,
                                  entryParcStartTo: init.entryParcStartTo,
                                  entryParcStartFrom: init.entryParcStartFrom,
                                  madcEndTo: init.madcEndTo,
                                  madcEndFrom: status,
                                  madcStartTo: init.madcStartTo,
                                  madcStartFrom: init.madcStartFrom
                                };
                        }));
                  if (result$20 !== undefined) {
                    nextFieldsStatuses$8.contents = result$20;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$8, metadata, nextFieldsStatuses$8.contents.madcEndTo, state.submissionStatus, validators_madcEndTo, (function (status) {
                                    var init = nextFieldsStatuses$8.contents;
                                    return {
                                            exitParcEndTo: init.exitParcEndTo,
                                            exitParcEndFrom: init.exitParcEndFrom,
                                            exitParcStartTo: init.exitParcStartTo,
                                            exitParcStartFrom: init.exitParcStartFrom,
                                            entryParcEndTo: init.entryParcEndTo,
                                            entryParcEndFrom: init.entryParcEndFrom,
                                            entryParcStartTo: init.entryParcStartTo,
                                            entryParcStartFrom: init.entryParcStartFrom,
                                            madcEndTo: status,
                                            madcEndFrom: init.madcEndFrom,
                                            madcStartTo: init.madcStartTo,
                                            madcStartFrom: init.madcStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateMadcEndFromField */9 :
                  var nextInput$9 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$9 = {
                    contents: state.fieldsStatuses
                  };
                  var result$21 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$9, metadata, nextFieldsStatuses$9.contents.madcEndTo, validators_madcEndTo, (function (status) {
                          var init = nextFieldsStatuses$9.contents;
                          return {
                                  exitParcEndTo: init.exitParcEndTo,
                                  exitParcEndFrom: init.exitParcEndFrom,
                                  exitParcStartTo: init.exitParcStartTo,
                                  exitParcStartFrom: init.exitParcStartFrom,
                                  entryParcEndTo: init.entryParcEndTo,
                                  entryParcEndFrom: init.entryParcEndFrom,
                                  entryParcStartTo: init.entryParcStartTo,
                                  entryParcStartFrom: init.entryParcStartFrom,
                                  madcEndTo: status,
                                  madcEndFrom: init.madcEndFrom,
                                  madcStartTo: init.madcStartTo,
                                  madcStartFrom: init.madcStartFrom
                                };
                        }));
                  if (result$21 !== undefined) {
                    nextFieldsStatuses$9.contents = result$21;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$9, metadata, nextFieldsStatuses$9.contents.madcEndFrom, state.submissionStatus, validators_madcEndFrom, (function (status) {
                                    var init = nextFieldsStatuses$9.contents;
                                    return {
                                            exitParcEndTo: init.exitParcEndTo,
                                            exitParcEndFrom: init.exitParcEndFrom,
                                            exitParcStartTo: init.exitParcStartTo,
                                            exitParcStartFrom: init.exitParcStartFrom,
                                            entryParcEndTo: init.entryParcEndTo,
                                            entryParcEndFrom: init.entryParcEndFrom,
                                            entryParcStartTo: init.entryParcStartTo,
                                            entryParcStartFrom: init.entryParcStartFrom,
                                            madcEndTo: init.madcEndTo,
                                            madcEndFrom: status,
                                            madcStartTo: init.madcStartTo,
                                            madcStartFrom: init.madcStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateMadcStartToField */10 :
                  var nextInput$10 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$10 = {
                    contents: state.fieldsStatuses
                  };
                  var result$22 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$10, metadata, nextFieldsStatuses$10.contents.madcStartFrom, validators_madcStartFrom, (function (status) {
                          var init = nextFieldsStatuses$10.contents;
                          return {
                                  exitParcEndTo: init.exitParcEndTo,
                                  exitParcEndFrom: init.exitParcEndFrom,
                                  exitParcStartTo: init.exitParcStartTo,
                                  exitParcStartFrom: init.exitParcStartFrom,
                                  entryParcEndTo: init.entryParcEndTo,
                                  entryParcEndFrom: init.entryParcEndFrom,
                                  entryParcStartTo: init.entryParcStartTo,
                                  entryParcStartFrom: init.entryParcStartFrom,
                                  madcEndTo: init.madcEndTo,
                                  madcEndFrom: init.madcEndFrom,
                                  madcStartTo: init.madcStartTo,
                                  madcStartFrom: status
                                };
                        }));
                  if (result$22 !== undefined) {
                    nextFieldsStatuses$10.contents = result$22;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$10, metadata, nextFieldsStatuses$10.contents.madcStartTo, state.submissionStatus, validators_madcStartTo, (function (status) {
                                    var init = nextFieldsStatuses$10.contents;
                                    return {
                                            exitParcEndTo: init.exitParcEndTo,
                                            exitParcEndFrom: init.exitParcEndFrom,
                                            exitParcStartTo: init.exitParcStartTo,
                                            exitParcStartFrom: init.exitParcStartFrom,
                                            entryParcEndTo: init.entryParcEndTo,
                                            entryParcEndFrom: init.entryParcEndFrom,
                                            entryParcStartTo: init.entryParcStartTo,
                                            entryParcStartFrom: init.entryParcStartFrom,
                                            madcEndTo: init.madcEndTo,
                                            madcEndFrom: init.madcEndFrom,
                                            madcStartTo: status,
                                            madcStartFrom: init.madcStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateMadcStartFromField */11 :
                  var nextInput$11 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$11 = {
                    contents: state.fieldsStatuses
                  };
                  var result$23 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$11, metadata, nextFieldsStatuses$11.contents.madcStartTo, validators_madcStartTo, (function (status) {
                          var init = nextFieldsStatuses$11.contents;
                          return {
                                  exitParcEndTo: init.exitParcEndTo,
                                  exitParcEndFrom: init.exitParcEndFrom,
                                  exitParcStartTo: init.exitParcStartTo,
                                  exitParcStartFrom: init.exitParcStartFrom,
                                  entryParcEndTo: init.entryParcEndTo,
                                  entryParcEndFrom: init.entryParcEndFrom,
                                  entryParcStartTo: init.entryParcStartTo,
                                  entryParcStartFrom: init.entryParcStartFrom,
                                  madcEndTo: init.madcEndTo,
                                  madcEndFrom: init.madcEndFrom,
                                  madcStartTo: status,
                                  madcStartFrom: init.madcStartFrom
                                };
                        }));
                  if (result$23 !== undefined) {
                    nextFieldsStatuses$11.contents = result$23;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$11, metadata, nextFieldsStatuses$11.contents.madcStartFrom, state.submissionStatus, validators_madcStartFrom, (function (status) {
                                    var init = nextFieldsStatuses$11.contents;
                                    return {
                                            exitParcEndTo: init.exitParcEndTo,
                                            exitParcEndFrom: init.exitParcEndFrom,
                                            exitParcStartTo: init.exitParcStartTo,
                                            exitParcStartFrom: init.exitParcStartFrom,
                                            entryParcEndTo: init.entryParcEndTo,
                                            entryParcEndFrom: init.entryParcEndFrom,
                                            entryParcStartTo: init.entryParcStartTo,
                                            entryParcStartFrom: init.entryParcStartFrom,
                                            madcEndTo: init.madcEndTo,
                                            madcEndFrom: init.madcEndFrom,
                                            madcStartTo: init.madcStartTo,
                                            madcStartFrom: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */12 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */13 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */14 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateExitParcEndTo: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateExitParcEndToField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateExitParcEndFrom: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateExitParcEndFromField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateExitParcStartTo: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateExitParcStartToField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateExitParcStartFrom: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateExitParcStartFromField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEntryParcEndTo: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEntryParcEndToField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEntryParcEndFrom: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEntryParcEndFromField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEntryParcStartTo: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEntryParcStartToField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEntryParcStartFrom: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEntryParcStartFromField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateMadcEndTo: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateMadcEndToField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateMadcEndFrom: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateMadcEndFromField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateMadcStartTo: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateMadcStartToField */10,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateMadcStartFrom: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateMadcStartFromField */11,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurExitParcEndTo: (function (param) {
              Curry._1(dispatch, /* BlurExitParcEndToField */0);
            }),
          blurExitParcEndFrom: (function (param) {
              Curry._1(dispatch, /* BlurExitParcEndFromField */1);
            }),
          blurExitParcStartTo: (function (param) {
              Curry._1(dispatch, /* BlurExitParcStartToField */2);
            }),
          blurExitParcStartFrom: (function (param) {
              Curry._1(dispatch, /* BlurExitParcStartFromField */3);
            }),
          blurEntryParcEndTo: (function (param) {
              Curry._1(dispatch, /* BlurEntryParcEndToField */4);
            }),
          blurEntryParcEndFrom: (function (param) {
              Curry._1(dispatch, /* BlurEntryParcEndFromField */5);
            }),
          blurEntryParcStartTo: (function (param) {
              Curry._1(dispatch, /* BlurEntryParcStartToField */6);
            }),
          blurEntryParcStartFrom: (function (param) {
              Curry._1(dispatch, /* BlurEntryParcStartFromField */7);
            }),
          blurMadcEndTo: (function (param) {
              Curry._1(dispatch, /* BlurMadcEndToField */8);
            }),
          blurMadcEndFrom: (function (param) {
              Curry._1(dispatch, /* BlurMadcEndFromField */9);
            }),
          blurMadcStartTo: (function (param) {
              Curry._1(dispatch, /* BlurMadcStartToField */10);
            }),
          blurMadcStartFrom: (function (param) {
              Curry._1(dispatch, /* BlurMadcStartFromField */11);
            }),
          exitParcEndToResult: Formality.exposeFieldResult(state.fieldsStatuses.exitParcEndTo),
          exitParcEndFromResult: Formality.exposeFieldResult(state.fieldsStatuses.exitParcEndFrom),
          exitParcStartToResult: Formality.exposeFieldResult(state.fieldsStatuses.exitParcStartTo),
          exitParcStartFromResult: Formality.exposeFieldResult(state.fieldsStatuses.exitParcStartFrom),
          entryParcEndToResult: Formality.exposeFieldResult(state.fieldsStatuses.entryParcEndTo),
          entryParcEndFromResult: Formality.exposeFieldResult(state.fieldsStatuses.entryParcEndFrom),
          entryParcStartToResult: Formality.exposeFieldResult(state.fieldsStatuses.entryParcStartTo),
          entryParcStartFromResult: Formality.exposeFieldResult(state.fieldsStatuses.entryParcStartFrom),
          madcEndToResult: Formality.exposeFieldResult(state.fieldsStatuses.madcEndTo),
          madcEndFromResult: Formality.exposeFieldResult(state.fieldsStatuses.madcEndFrom),
          madcStartToResult: Formality.exposeFieldResult(state.fieldsStatuses.madcStartTo),
          madcStartFromResult: Formality.exposeFieldResult(state.fieldsStatuses.madcStartFrom),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (!match.exitParcEndTo && !match.exitParcEndFrom && !match.exitParcStartTo && !match.exitParcStartFrom && !match.entryParcEndTo && !match.entryParcEndFrom && !match.entryParcStartTo && !match.entryParcStartFrom && !match.madcEndTo && !match.madcEndFrom && !match.madcStartTo && !match.madcStartFrom) {
                return false;
              }
              return true;
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */12);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */13);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */14);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */14,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */15);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  madcStartFrom: undefined,
  madcStartTo: undefined,
  madcEndFrom: undefined,
  madcEndTo: undefined,
  entryParcStartFrom: undefined,
  entryParcStartTo: undefined,
  entryParcEndFrom: undefined,
  entryParcEndTo: undefined,
  exitParcStartFrom: undefined,
  exitParcStartTo: undefined,
  exitParcEndFrom: undefined,
  exitParcEndTo: undefined
};

export {
  TableFilterForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
