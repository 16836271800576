// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/core/styles";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as App_Styles$DvmAdminFrontendFr from "../../../common/App_Styles.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Category_Router$DvmAdminFrontendFr from "../Category_Router.bs.js";
import * as Category_Styles$DvmAdminFrontendFr from "../Category_Styles.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Category_Edit_Api$DvmAdminFrontendFr from "./Category_Edit_Api.bs.js";
import * as Messages_Category$DvmAdminFrontendFr from "../../../intl/messages/category/Messages_Category.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as Category_Edit_VersionsSelection_Modal$DvmAdminFrontendFr from "./modal/Category_Edit_VersionsSelection_Modal.bs.js";
import * as Category_Edit_VersionsSelection_Table$DvmAdminFrontendFr from "./Category_Edit_VersionsSelection_Table.bs.js";
import * as Category_Edit_VersionsSelection_Utils$DvmAdminFrontendFr from "./modal/Category_Edit_VersionsSelection_Utils.bs.js";

function toVersionEditType(param) {
  return {
          versionCode: param.versionCode,
          versionLabel: param.versionLabel,
          startDate: undefined,
          endDate: undefined
        };
}

function Category_Edit_VersionsSelection(Props) {
  var category = Props.category;
  var versions = Props.versions;
  var allVersions = Props.allVersions;
  var refresh = Props.refresh;
  var withClosedModels = Props.withClosedModels;
  var setWithClosedModels = Props.setWithClosedModels;
  var theme = Styles.useTheme();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var saveResult = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$3[1];
  var result = match$3[0];
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var setTakenVersionList = match$4[1];
  var takenVersionsList = match$4[0];
  var match$5 = React.useState(function () {
        return null;
      });
  var setModal = match$5[1];
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* Message */0,
                        _0: Messages_Category$DvmAdminFrontendFr.categoryCreated
                      }
                    }));
            Category_Router$DvmAdminFrontendFr.routeToMainPage(undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  React.useEffect((function () {
          if (typeof result === "number") {
            result === /* NotStarted */0;
          } else if (result.TAG === /* Ok */0) {
            var takenVersions = result._0;
            Curry._1(setTakenVersionList, (function (param) {
                    return Category_Edit_VersionsSelection_Utils$DvmAdminFrontendFr.removeFromTakenVersions(takenVersions.content, category);
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: result._0
                      }
                    }));
          }
        }), [result]);
  React.useEffect((function () {
          Category_Edit_Api$DvmAdminFrontendFr.fetchTakenVersions(category.modelID, category.startDate, setResult, userManager, selectedRole);
        }), []);
  return React.createElement(React.Fragment, undefined, React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                  show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult),
                  children: React.createElement(Core.Grid, {
                        children: null,
                        className: Category_Styles$DvmAdminFrontendFr.formSection(theme),
                        container: true,
                        direction: "column",
                        spacing: 3
                      }, React.createElement(Core.Grid, {
                            children: React.createElement(Core.Typography, {
                                  children: intl.formatMessage(Messages_Category$DvmAdminFrontendFr.editVersionsTitle, {
                                        categoryCode: category.categoryID,
                                        categoryName: category.categoryName
                                      }),
                                  className: App_Styles$DvmAdminFrontendFr.brownSemibold(theme),
                                  variant: "h4"
                                }),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                  onClick: (function (param) {
                                      Curry._1(setModal, (function (param) {
                                              return React.createElement(Category_Edit_VersionsSelection_Modal$DvmAdminFrontendFr.make, {
                                                          onClose: (function (param) {
                                                              Curry._1(setModal, (function (param) {
                                                                      return null;
                                                                    }));
                                                            }),
                                                          category: category,
                                                          refresh: refresh,
                                                          takenVersionsList: takenVersionsList
                                                        });
                                            }));
                                    }),
                                  label: intl.formatMessage(Messages_Category$DvmAdminFrontendFr.assignVersions),
                                  color: "secondary",
                                  size: "medium"
                                }),
                            className: App_Styles$DvmAdminFrontendFr.alignSelfLeft,
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(Category_Edit_VersionsSelection_Table$DvmAdminFrontendFr.make, {
                                  versions: versions,
                                  category: category,
                                  allVersions: allVersions,
                                  refresh: refresh,
                                  takenVersionsList: takenVersionsList,
                                  withClosedModels: withClosedModels,
                                  setWithClosedModels: setWithClosedModels
                                }),
                            item: true
                          }))
                }), match$5[0]);
}

var Api;

var make = Category_Edit_VersionsSelection;

export {
  Api ,
  toVersionEditType ,
  make ,
}
/* react Not a pure module */
