// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../../../../../common/components/icons/App_Icon.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../../../common/App_Typography.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Flash_Edit_Version_Perf_Edit_Amount$DvmAdminFrontendFr from "./Flash_Edit_Version_Perf_Edit_Amount.bs.js";
import * as Flash_Edit_Version_Perf_Edit_Delete$DvmAdminFrontendFr from "./Flash_Edit_Version_Perf_Edit_Delete.bs.js";
import * as Flash_Edit_Version_Perf_Edit_EndDate$DvmAdminFrontendFr from "./Flash_Edit_Version_Perf_Edit_EndDate.bs.js";
import * as Flash_Edit_Version_Perf_Edit_New_Period$DvmAdminFrontendFr from "./Flash_Edit_Version_Perf_Edit_New_Period.bs.js";

function Flash_Edit_Version_Perf_Edit_Inputs(Props) {
  var selectedVersionsListFiltered = Props.selectedVersionsListFiltered;
  var flash = Props.flash;
  var refreshAndFetch = Props.refreshAndFetch;
  var resultEdit = Props.resultEdit;
  var setResultEdit = Props.setResultEdit;
  var onlyLatest = Props.onlyLatest;
  var intl = ReactIntl.useIntl();
  React.useEffect((function () {
          if (typeof resultEdit === "number") {
            resultEdit === /* NotStarted */0;
          } else if (resultEdit.TAG === /* Ok */0) {
            Curry._1(refreshAndFetch, undefined);
          }
          
        }), [resultEdit]);
  var tmp;
  tmp = typeof resultEdit === "number" ? null : (
      resultEdit.TAG === /* Ok */0 ? React.createElement(Core.Grid, {
              children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                    size: /* XLarge */4,
                    type_: /* Tick */1
                  }),
              item: true,
              style: {
                color: "green"
              }
            }) : React.createElement(Core.Grid, {
              children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                    size: /* XLarge */4,
                    type_: /* Cross */42
                  }),
              item: true,
              style: {
                color: "red"
              }
            })
    );
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              spacing: 3
            }, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "row",
                  spacing: 2
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                            gutterBottom: true,
                            style: {
                              fontWeight: "600"
                            },
                            children: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.editActionsTitle, {
                                  count: Belt_List.length(selectedVersionsListFiltered)
                                }),
                            variant: "bodyBig"
                          }),
                      item: true
                    }), tmp), React.createElement(Flash_Edit_Version_Perf_Edit_Amount$DvmAdminFrontendFr.make, {
                  flash: flash,
                  selectedVersionsListFiltered: selectedVersionsListFiltered,
                  setResultEdit: setResultEdit
                }), React.createElement(Flash_Edit_Version_Perf_Edit_New_Period$DvmAdminFrontendFr.make, {
                  flash: flash,
                  selectedVersionsListFiltered: selectedVersionsListFiltered,
                  setResultEdit: setResultEdit,
                  onlyLatest: onlyLatest
                }), React.createElement(Flash_Edit_Version_Perf_Edit_EndDate$DvmAdminFrontendFr.make, {
                  flash: flash,
                  selectedVersionsListFiltered: selectedVersionsListFiltered,
                  setResultEdit: setResultEdit,
                  onlyLatest: onlyLatest
                }), React.createElement(Flash_Edit_Version_Perf_Edit_Delete$DvmAdminFrontendFr.make, {
                  selectedVersionsListFiltered: selectedVersionsListFiltered,
                  setResultEdit: setResultEdit
                }));
}

var Types;

var NewPeriod;

var EditAmount;

var EndDate;

var DeleteRows;

var make = Flash_Edit_Version_Perf_Edit_Inputs;

export {
  Types ,
  NewPeriod ,
  EditAmount ,
  EndDate ,
  DeleteRows ,
  make ,
}
/* react Not a pure module */
