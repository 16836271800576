// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/core/styles";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../intl/Intl_Utils.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as Category_Router$DvmAdminFrontendFr from "../Category_Router.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as Messages_Category$DvmAdminFrontendFr from "../../../intl/messages/category/Messages_Category.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";

function focusedListItem(theme) {
  return Css.css({
              backgroundColor: theme.palette.primary.light
            });
}

function listHeader(theme) {
  return Css.css({
              backgroundColor: theme.palette.primary.dark
            });
}

function Category_Search_List(Props) {
  var categories = Props.categories;
  var isPending = Props.isPending;
  var selectedModel = Props.selectedModel;
  var manage = Props.manage;
  var theme = Styles.useTheme();
  var match = React.useState(function () {
        
      });
  var setExpandedItemIndex = match[1];
  var expandedItemIndex = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setListItemRect = match$1[1];
  var listItemRect = match$1[0];
  var intl = ReactIntl.useIntl();
  var categories$1 = Belt_List.sort(categories, (function (a, b) {
          return ReDate.compareAsc(Belt_Option.getWithDefault(b.startDate, new Date(0.0)), Belt_Option.getWithDefault(a.startDate, new Date(0.0)));
        }));
  var measuredListItemRef = React.useCallback((function (element) {
          Belt_Option.forEach((element == null) ? undefined : Caml_option.some(element), (function (element) {
                  Curry._1(setListItemRect, (function (param) {
                          return element.getBoundingClientRect();
                        }));
                }));
        }), []);
  var isItemExpanded = function (expandedItemIndex, index) {
    if (expandedItemIndex !== undefined) {
      return Caml_option.valFromOption(expandedItemIndex) === index;
    } else {
      return false;
    }
  };
  return React.createElement(Core.Grid, {
              children: null,
              container: true
            }, React.createElement(Core.Grid, {
                  children: null,
                  item: true,
                  xs: Grid$Mui.Xs[8]
                }, React.createElement(Core.List, {
                      children: React.createElement(Core.ListItem, {
                            children: React.createElement(Core.ListItemText, {
                                  inset: true,
                                  primary: React.createElement(Core.Typography, {
                                        children: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.category),
                                        style: {
                                          color: Theme_Colors$DvmAdminFrontendFr.white,
                                          fontWeight: "600"
                                        }
                                      })
                                }),
                            className: listHeader(theme)
                          })
                    }), React.createElement(Core.List, {
                      children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                            show: isPending,
                            children: Belt_List.toArray(Belt_List.mapWithIndex(categories$1, (function (index, param) {
                                        var endDate = param.endDate;
                                        var startDate = param.startDate;
                                        var categoryName = param.categoryName;
                                        var categoryCode = param.categoryCode;
                                        var tmp = {
                                          children: null,
                                          divider: true,
                                          key: "category-" + String(index) + "",
                                          ref: measuredListItemRef
                                        };
                                        var tmp$1 = isItemExpanded(expandedItemIndex, index) ? focusedListItem(theme) : undefined;
                                        if (tmp$1 !== undefined) {
                                          tmp.className = tmp$1;
                                        }
                                        return React.createElement(Core.ListItem, tmp, React.createElement(Core.ListItemIcon, {
                                                        children: null
                                                      }, Belt_Option.mapWithDefault(selectedModel, null, (function (x) {
                                                              if (x === categoryCode || !manage) {
                                                                return null;
                                                              } else {
                                                                return React.createElement(Core.IconButton, {
                                                                            onClick: (function (param) {
                                                                                return Category_Router$DvmAdminFrontendFr.routeToEditPage(categoryCode, Caml_option.some(startDate), undefined);
                                                                              }),
                                                                            children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                                  size: /* Large */3,
                                                                                  type_: /* Edit */60
                                                                                })
                                                                          });
                                                              }
                                                            })), null), React.createElement(Core.ListItemText, {
                                                        primary: React.createElement(Core.Typography, {
                                                              children: React.createElement(Core.Grid, {
                                                                    children: null,
                                                                    container: true,
                                                                    item: true,
                                                                    wrap: "nowrap"
                                                                  }, React.createElement(Core.Grid, {
                                                                        children: categoryCode,
                                                                        item: true,
                                                                        sm: Grid$Mui.Sm[3]
                                                                      }), Belt_Option.mapWithDefault(selectedModel, null, (function (x) {
                                                                          if (x === categoryCode) {
                                                                            return null;
                                                                          } else {
                                                                            return React.createElement(Core.Grid, {
                                                                                        children: Belt_Option.getWithDefault(categoryName, "-"),
                                                                                        item: true
                                                                                      });
                                                                          }
                                                                        }))),
                                                              variant: "body2",
                                                              style: {
                                                                fontWeight: "600"
                                                              }
                                                            }),
                                                        secondary: React.createElement(Core.Typography, {
                                                              children: React.createElement(Core.Grid, {
                                                                    children: null,
                                                                    container: true,
                                                                    item: true,
                                                                    wrap: "nowrap"
                                                                  }, React.createElement(Core.Grid, {
                                                                        children: intl.formatMessage(Messages_Category$DvmAdminFrontendFr.versions, {
                                                                              count: param.numOfVersions
                                                                            }),
                                                                        item: true,
                                                                        sm: Grid$Mui.Sm[3]
                                                                      }), Belt_Option.mapWithDefault(selectedModel, null, (function (x) {
                                                                          if (x === categoryCode) {
                                                                            return null;
                                                                          } else {
                                                                            return React.createElement(Core.Grid, {
                                                                                        children: intl.formatMessage(Messages_Category$DvmAdminFrontendFr.applicationDates) + ": " + ("" + Belt_Option.mapWithDefault(startDate, "", (function (param) {
                                                                                                  return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                                                })) + " - " + Belt_Option.mapWithDefault(endDate, "", (function (param) {
                                                                                                  return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                                                })) + ""),
                                                                                        item: true
                                                                                      });
                                                                          }
                                                                        }))),
                                                              color: "textSecondary",
                                                              variant: "body2"
                                                            })
                                                      }), React.createElement(Core.ListItemSecondaryAction, {
                                                        children: React.createElement(Core.IconButton, {
                                                              onClick: (function (param) {
                                                                  return Curry._1(setExpandedItemIndex, (function (expandedItemIndex) {
                                                                                if (isItemExpanded(expandedItemIndex, index)) {
                                                                                  return ;
                                                                                } else {
                                                                                  return index;
                                                                                }
                                                                              }));
                                                                }),
                                                              children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                    size: /* Large */3,
                                                                    type_: isItemExpanded(expandedItemIndex, index) ? /* PadLeft */12 : /* PadRight */13
                                                                  })
                                                            })
                                                      }));
                                      })))
                          }),
                      dense: true
                    })), React.createElement(Core.Grid, {
                  children: null,
                  item: true,
                  xs: Grid$Mui.Xs[4]
                }, React.createElement(Core.List, {
                      children: React.createElement(Core.ListItem, {
                            children: React.createElement(Core.ListItemText, {
                                  primary: React.createElement(Core.Typography, {
                                        children: intl.formatMessage(Messages_Category$DvmAdminFrontendFr.listHeaderVersions),
                                        style: {
                                          color: Theme_Colors$DvmAdminFrontendFr.white,
                                          fontWeight: "600"
                                        }
                                      })
                                }),
                            className: listHeader(theme)
                          })
                    }), Belt_Option.mapWithDefault(expandedItemIndex, null, (function (expandedItemIndex) {
                        return Belt_Option.mapWithDefault(Belt_List.get(categories$1, expandedItemIndex), null, (function (param) {
                                      var endDate = param.endDate;
                                      var startDate = param.startDate;
                                      var categoryCode = param.categoryCode;
                                      return React.createElement(Core.Collapse, {
                                                  children: React.createElement(Core.List, {
                                                        children: Belt_List.toArray(Belt_List.mapWithIndex(Belt_Option.getWithDefault(param.versions, /* [] */0), (function (index, versionRow) {
                                                                    var tmp = {
                                                                      children: React.createElement(Core.ListItemText, {
                                                                            primary: React.createElement(Core.Typography, {
                                                                                  children: Belt_Option.getWithDefault(versionRow.version, ""),
                                                                                  variant: "body2"
                                                                                }),
                                                                            secondary: Belt_Option.mapWithDefault(selectedModel, null, (function (x) {
                                                                                    if (x === categoryCode) {
                                                                                      return null;
                                                                                    } else {
                                                                                      return "" + Belt_Option.mapWithDefault(versionRow.startDate, Belt_Option.mapWithDefault(startDate, "", (function (param) {
                                                                                                        return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                                                      })), (function (param) {
                                                                                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                                                  })) + " - " + Belt_Option.mapWithDefault(versionRow.endDate, Belt_Option.mapWithDefault(endDate, "", (function (param) {
                                                                                                        return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                                                      })), (function (param) {
                                                                                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                                                  })) + "";
                                                                                    }
                                                                                  }))
                                                                          }),
                                                                      divider: true,
                                                                      key: Belt_Option.getWithDefault(versionRow.version, "") + String(index)
                                                                    };
                                                                    var tmp$1 = Belt_Option.map(listItemRect, (function (param) {
                                                                            return {
                                                                                    height: "" + String(param.height) + "px"
                                                                                  };
                                                                          }));
                                                                    if (tmp$1 !== undefined) {
                                                                      tmp.style = Caml_option.valFromOption(tmp$1);
                                                                    }
                                                                    return React.createElement(Core.ListItem, tmp);
                                                                  })))
                                                      }),
                                                  in: true,
                                                  timeout: "auto"
                                                });
                                    }));
                      }))));
}

var Router;

var make = Category_Search_List;

export {
  Router ,
  focusedListItem ,
  listHeader ,
  make ,
}
/* react Not a pure module */
