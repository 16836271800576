// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../common/components/App_Modal.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as Action_Referential_Fields_Table$DvmAdminFrontendFr from "./Action_Referential_Fields_Table.bs.js";
import * as Action_Referential_Fields_Utils$DvmAdminFrontendFr from "./Action_Referential_Fields_Utils.bs.js";
import * as Messages_Action_Referential_Fields$DvmAdminFrontendFr from "../../../intl/messages/actionReferential/Messages_Action_Referential_Fields.bs.js";

function Action_Referential_Fields_Page_Modal(Props) {
  var onClose = Props.onClose;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return Belt_List.get(Action_Referential_Fields_Utils$DvmAdminFrontendFr.availableFields, 0);
      });
  var setSelectedType = match[1];
  var selectedType = match[0];
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Action_Referential_Fields$DvmAdminFrontendFr.title),
              minWidth: "1000px",
              children: React.createElement(Core.Box, {
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          style: {
                            margin: "20px 0px"
                          }
                        }, React.createElement(Core.Grid, {
                              children: Belt_List.toArray(Belt_List.map(Action_Referential_Fields_Utils$DvmAdminFrontendFr.availableFields, (function (fieldType) {
                                          var tmp = {
                                            textAlign: "left",
                                            width: "100%",
                                            justifyContent: "flex-start"
                                          };
                                          var tmp$1 = Belt_Option.mapWithDefault(selectedType, false, (function (x) {
                                                  return x === fieldType;
                                                })) ? "solid 5px " + Theme_Colors$DvmAdminFrontendFr.primaryBrown + "" : undefined;
                                          if (tmp$1 !== undefined) {
                                            tmp.borderBottom = tmp$1;
                                          }
                                          return React.createElement(Core.Grid, {
                                                      children: React.createElement(Core.Button, {
                                                            onClick: (function (param) {
                                                                Curry._1(setSelectedType, (function (param) {
                                                                        return fieldType;
                                                                      }));
                                                              }),
                                                            style: tmp,
                                                            children: intl.formatMessage(Action_Referential_Fields_Utils$DvmAdminFrontendFr.fieldTypeToMessage(fieldType))
                                                          }),
                                                      item: true
                                                    });
                                        }))),
                              container: true,
                              direction: "column",
                              item: true,
                              spacing: 1,
                              xs: Grid$Mui.Xs.auto,
                              style: {
                                width: "fit-content"
                              }
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(Core.Divider, {
                                    light: false,
                                    orientation: "vertical"
                                  })
                            }), Belt_Option.mapWithDefault(selectedType, null, (function (fieldType) {
                                return React.createElement(Core.Grid, {
                                            children: React.createElement(Action_Referential_Fields_Table$DvmAdminFrontendFr.make, {
                                                  fieldType: fieldType
                                                }),
                                            container: true,
                                            item: true,
                                            xs: Grid$Mui.Xs.$$true,
                                            style: {
                                              padding: "0px 20px"
                                            }
                                          });
                              })))
                  })
            });
}

var Utils;

var make = Action_Referential_Fields_Page_Modal;

export {
  Utils ,
  make ,
}
/* react Not a pure module */
