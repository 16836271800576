// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Constants$DvmAdminFrontendFr from "../../../../common/Constants.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../common/utils/Utils_Api.bs.js";
import * as Bonuses_Search_Types$DvmAdminFrontendFr from "../../Bonuses_Search_Types.bs.js";

var validators_firstName = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(false, Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.firstName, param.firstName, false)
            };
    })
};

var validators_customerName = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(false, Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.customerName, param.customerName, false)
            };
    })
};

var validators_codeAPE = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.codeAPE, "normal", param.codeAPE)
            };
    })
};

var validators_brand = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.brand, param.brand, false)
            };
    })
};

var validators_engineType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.engineType, "normal", param.engineType)
            };
    })
};

var validators_vehicleType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.vehicleType, param.vehicleType, false)
            };
    })
};

var validators_phase = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.phase, param.phase, false)
            };
    })
};

var validators_categoryLabel = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.categoryLabel, "normal", param.categoryLabel)
            };
    })
};

var validators_versionID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.versionID, "normal", param.versionID)
            };
    })
};

var validators_modelID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.modelID, "normal", param.modelID)
            };
    })
};

var validators_siren = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var siren = param.siren;
      var spacesEncode = function (value) {
        return value.replace(Constants$DvmAdminFrontendFr.Regex.whitespaces, "%20");
      };
      var match = Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.sirenClientFinalLLD;
      var match$1 = Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.siren;
      var tmp;
      if (siren === "") {
        tmp = undefined;
      } else {
        var finalVal = spacesEncode(siren);
        tmp = {
          en: match[0] + "=" + finalVal + encodeURIComponent("||") + match$1[0] + "=" + finalVal,
          fr: match[1] + "%5E" + finalVal + encodeURIComponent("||") + match$1[1] + "=" + finalVal
        };
      }
      return {
              TAG: /* Ok */0,
              _0: tmp
            };
    })
};

var validators_mainContractSIREN = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.mainContractSIREN, param.mainContractSIREN, false)
            };
    })
};

var validators_contractName = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(false, Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.contractName, param.contractName, false)
            };
    })
};

var validators_contractID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.contractID, param.contractID, false)
            };
    })
};

var validators_loueurName = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(false, Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.loueurName, param.loueurName, false)
            };
    })
};

var validators_loueurID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.loueurID, param.loueurID, false)
            };
    })
};

var validators_sirenClientFinalLLDName = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.sirenClientFinalLLDName, param.sirenClientFinalLLDName, false)
            };
    })
};

var validators_sectorManagerID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.sectorManagerID, param.sectorManagerID, false)
            };
    })
};

var validators_keyAccountManagerID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.keyAccountManagerID, param.keyAccountManagerID, false)
            };
    })
};

var validators_dealerGroup = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.dealerGroup, param.dealerGroup, false)
            };
    })
};

var validators_dealerName = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(false, Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.dealerName, param.dealerName, false)
            };
    })
};

var validators = {
  firstName: validators_firstName,
  customerName: validators_customerName,
  codeAPE: validators_codeAPE,
  brand: validators_brand,
  engineType: validators_engineType,
  vehicleType: validators_vehicleType,
  phase: validators_phase,
  categoryLabel: validators_categoryLabel,
  versionID: validators_versionID,
  modelID: validators_modelID,
  siren: validators_siren,
  mainContractSIREN: validators_mainContractSIREN,
  contractName: validators_contractName,
  contractID: validators_contractID,
  loueurName: validators_loueurName,
  loueurID: validators_loueurID,
  sirenClientFinalLLDName: validators_sirenClientFinalLLDName,
  sectorManagerID: validators_sectorManagerID,
  keyAccountManagerID: validators_keyAccountManagerID,
  dealerGroup: validators_dealerGroup,
  dealerName: validators_dealerName
};

function initialFieldsStatuses(_input) {
  return {
          firstName: /* Pristine */0,
          customerName: /* Pristine */0,
          codeAPE: /* Pristine */0,
          brand: /* Pristine */0,
          engineType: /* Pristine */0,
          vehicleType: /* Pristine */0,
          phase: /* Pristine */0,
          categoryLabel: /* Pristine */0,
          versionID: /* Pristine */0,
          modelID: /* Pristine */0,
          siren: /* Pristine */0,
          mainContractSIREN: /* Pristine */0,
          contractName: /* Pristine */0,
          contractID: /* Pristine */0,
          loueurName: /* Pristine */0,
          loueurID: /* Pristine */0,
          sirenClientFinalLLDName: /* Pristine */0,
          sectorManagerID: /* Pristine */0,
          keyAccountManagerID: /* Pristine */0,
          dealerGroup: /* Pristine */0,
          dealerName: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match = fieldsStatuses.firstName;
  var match_0 = match ? match._0 : Curry._2(validators.firstName.validate, input, metadata);
  var match$1 = fieldsStatuses.customerName;
  var match_0$1 = match$1 ? match$1._0 : Curry._2(validators.customerName.validate, input, metadata);
  var match$2 = fieldsStatuses.codeAPE;
  var match_0$2 = match$2 ? match$2._0 : Curry._2(validators.codeAPE.validate, input, metadata);
  var match$3 = fieldsStatuses.brand;
  var match_0$3 = match$3 ? match$3._0 : Curry._2(validators.brand.validate, input, metadata);
  var match$4 = fieldsStatuses.engineType;
  var match_0$4 = match$4 ? match$4._0 : Curry._2(validators.engineType.validate, input, metadata);
  var match$5 = fieldsStatuses.vehicleType;
  var match_0$5 = match$5 ? match$5._0 : Curry._2(validators.vehicleType.validate, input, metadata);
  var match$6 = fieldsStatuses.phase;
  var match_0$6 = match$6 ? match$6._0 : Curry._2(validators.phase.validate, input, metadata);
  var match$7 = fieldsStatuses.categoryLabel;
  var match_0$7 = match$7 ? match$7._0 : Curry._2(validators.categoryLabel.validate, input, metadata);
  var match$8 = fieldsStatuses.versionID;
  var match_0$8 = match$8 ? match$8._0 : Curry._2(validators.versionID.validate, input, metadata);
  var match$9 = fieldsStatuses.modelID;
  var match_0$9 = match$9 ? match$9._0 : Curry._2(validators.modelID.validate, input, metadata);
  var match$10 = fieldsStatuses.siren;
  var match_0$10 = match$10 ? match$10._0 : Curry._2(validators.siren.validate, input, metadata);
  var match$11 = fieldsStatuses.mainContractSIREN;
  var match_0$11 = match$11 ? match$11._0 : Curry._2(validators.mainContractSIREN.validate, input, metadata);
  var match$12 = fieldsStatuses.contractName;
  var match_0$12 = match$12 ? match$12._0 : Curry._2(validators.contractName.validate, input, metadata);
  var match$13 = fieldsStatuses.contractID;
  var match_0$13 = match$13 ? match$13._0 : Curry._2(validators.contractID.validate, input, metadata);
  var match$14 = fieldsStatuses.loueurName;
  var match_0$14 = match$14 ? match$14._0 : Curry._2(validators.loueurName.validate, input, metadata);
  var match$15 = fieldsStatuses.loueurID;
  var match_0$15 = match$15 ? match$15._0 : Curry._2(validators.loueurID.validate, input, metadata);
  var match$16 = fieldsStatuses.sirenClientFinalLLDName;
  var match_0$16 = match$16 ? match$16._0 : Curry._2(validators.sirenClientFinalLLDName.validate, input, metadata);
  var match$17 = fieldsStatuses.sectorManagerID;
  var match_0$17 = match$17 ? match$17._0 : Curry._2(validators.sectorManagerID.validate, input, metadata);
  var match$18 = fieldsStatuses.keyAccountManagerID;
  var match_0$18 = match$18 ? match$18._0 : Curry._2(validators.keyAccountManagerID.validate, input, metadata);
  var match$19 = fieldsStatuses.dealerGroup;
  var match_0$19 = match$19 ? match$19._0 : Curry._2(validators.dealerGroup.validate, input, metadata);
  var match$20 = fieldsStatuses.dealerName;
  var match_0$20 = match$20 ? match$20._0 : Curry._2(validators.dealerName.validate, input, metadata);
  var firstNameResult = match_0;
  var firstNameResult$1;
  if (firstNameResult.TAG === /* Ok */0) {
    var customerNameResult = match_0$1;
    if (customerNameResult.TAG === /* Ok */0) {
      var codeAPEResult = match_0$2;
      if (codeAPEResult.TAG === /* Ok */0) {
        var brandResult = match_0$3;
        if (brandResult.TAG === /* Ok */0) {
          var engineTypeResult = match_0$4;
          if (engineTypeResult.TAG === /* Ok */0) {
            var vehicleTypeResult = match_0$5;
            if (vehicleTypeResult.TAG === /* Ok */0) {
              var phaseResult = match_0$6;
              if (phaseResult.TAG === /* Ok */0) {
                var categoryLabelResult = match_0$7;
                if (categoryLabelResult.TAG === /* Ok */0) {
                  var versionIDResult = match_0$8;
                  if (versionIDResult.TAG === /* Ok */0) {
                    var modelIDResult = match_0$9;
                    if (modelIDResult.TAG === /* Ok */0) {
                      var sirenResult = match_0$10;
                      if (sirenResult.TAG === /* Ok */0) {
                        var mainContractSIRENResult = match_0$11;
                        if (mainContractSIRENResult.TAG === /* Ok */0) {
                          var contractNameResult = match_0$12;
                          if (contractNameResult.TAG === /* Ok */0) {
                            var contractIDResult = match_0$13;
                            if (contractIDResult.TAG === /* Ok */0) {
                              var loueurNameResult = match_0$14;
                              if (loueurNameResult.TAG === /* Ok */0) {
                                var loueurIDResult = match_0$15;
                                if (loueurIDResult.TAG === /* Ok */0) {
                                  var sirenClientFinalLLDNameResult = match_0$16;
                                  if (sirenClientFinalLLDNameResult.TAG === /* Ok */0) {
                                    var sectorManagerIDResult = match_0$17;
                                    if (sectorManagerIDResult.TAG === /* Ok */0) {
                                      var keyAccountManagerIDResult = match_0$18;
                                      if (keyAccountManagerIDResult.TAG === /* Ok */0) {
                                        var dealerGroupResult = match_0$19;
                                        if (dealerGroupResult.TAG === /* Ok */0) {
                                          var dealerNameResult = match_0$20;
                                          if (dealerNameResult.TAG === /* Ok */0) {
                                            return {
                                                    TAG: /* Valid */0,
                                                    output: {
                                                      dealerName: dealerNameResult._0,
                                                      dealerGroup: dealerGroupResult._0,
                                                      keyAccountManagerID: keyAccountManagerIDResult._0,
                                                      sectorManagerID: sectorManagerIDResult._0,
                                                      sirenClientFinalLLDName: sirenClientFinalLLDNameResult._0,
                                                      loueurID: loueurIDResult._0,
                                                      loueurName: loueurNameResult._0,
                                                      contractID: contractIDResult._0,
                                                      contractName: contractNameResult._0,
                                                      mainContractSIREN: mainContractSIRENResult._0,
                                                      siren: sirenResult._0,
                                                      modelID: modelIDResult._0,
                                                      versionID: versionIDResult._0,
                                                      categoryLabel: categoryLabelResult._0,
                                                      phase: phaseResult._0,
                                                      vehicleType: vehicleTypeResult._0,
                                                      engineType: engineTypeResult._0,
                                                      brand: brandResult._0,
                                                      codeAPE: codeAPEResult._0,
                                                      customerName: customerNameResult._0,
                                                      firstName: firstNameResult._0
                                                    },
                                                    fieldsStatuses: {
                                                      firstName: /* Dirty */{
                                                        _0: firstNameResult,
                                                        _1: /* Shown */0
                                                      },
                                                      customerName: /* Dirty */{
                                                        _0: customerNameResult,
                                                        _1: /* Shown */0
                                                      },
                                                      codeAPE: /* Dirty */{
                                                        _0: codeAPEResult,
                                                        _1: /* Shown */0
                                                      },
                                                      brand: /* Dirty */{
                                                        _0: brandResult,
                                                        _1: /* Shown */0
                                                      },
                                                      engineType: /* Dirty */{
                                                        _0: engineTypeResult,
                                                        _1: /* Shown */0
                                                      },
                                                      vehicleType: /* Dirty */{
                                                        _0: vehicleTypeResult,
                                                        _1: /* Shown */0
                                                      },
                                                      phase: /* Dirty */{
                                                        _0: phaseResult,
                                                        _1: /* Shown */0
                                                      },
                                                      categoryLabel: /* Dirty */{
                                                        _0: categoryLabelResult,
                                                        _1: /* Shown */0
                                                      },
                                                      versionID: /* Dirty */{
                                                        _0: versionIDResult,
                                                        _1: /* Shown */0
                                                      },
                                                      modelID: /* Dirty */{
                                                        _0: modelIDResult,
                                                        _1: /* Shown */0
                                                      },
                                                      siren: /* Dirty */{
                                                        _0: sirenResult,
                                                        _1: /* Shown */0
                                                      },
                                                      mainContractSIREN: /* Dirty */{
                                                        _0: mainContractSIRENResult,
                                                        _1: /* Shown */0
                                                      },
                                                      contractName: /* Dirty */{
                                                        _0: contractNameResult,
                                                        _1: /* Shown */0
                                                      },
                                                      contractID: /* Dirty */{
                                                        _0: contractIDResult,
                                                        _1: /* Shown */0
                                                      },
                                                      loueurName: /* Dirty */{
                                                        _0: loueurNameResult,
                                                        _1: /* Shown */0
                                                      },
                                                      loueurID: /* Dirty */{
                                                        _0: loueurIDResult,
                                                        _1: /* Shown */0
                                                      },
                                                      sirenClientFinalLLDName: /* Dirty */{
                                                        _0: sirenClientFinalLLDNameResult,
                                                        _1: /* Shown */0
                                                      },
                                                      sectorManagerID: /* Dirty */{
                                                        _0: sectorManagerIDResult,
                                                        _1: /* Shown */0
                                                      },
                                                      keyAccountManagerID: /* Dirty */{
                                                        _0: keyAccountManagerIDResult,
                                                        _1: /* Shown */0
                                                      },
                                                      dealerGroup: /* Dirty */{
                                                        _0: dealerGroupResult,
                                                        _1: /* Shown */0
                                                      },
                                                      dealerName: /* Dirty */{
                                                        _0: dealerNameResult,
                                                        _1: /* Shown */0
                                                      }
                                                    },
                                                    collectionsStatuses: undefined
                                                  };
                                          }
                                          firstNameResult$1 = firstNameResult;
                                        } else {
                                          firstNameResult$1 = firstNameResult;
                                        }
                                      } else {
                                        firstNameResult$1 = firstNameResult;
                                      }
                                    } else {
                                      firstNameResult$1 = firstNameResult;
                                    }
                                  } else {
                                    firstNameResult$1 = firstNameResult;
                                  }
                                } else {
                                  firstNameResult$1 = firstNameResult;
                                }
                              } else {
                                firstNameResult$1 = firstNameResult;
                              }
                            } else {
                              firstNameResult$1 = firstNameResult;
                            }
                          } else {
                            firstNameResult$1 = firstNameResult;
                          }
                        } else {
                          firstNameResult$1 = firstNameResult;
                        }
                      } else {
                        firstNameResult$1 = firstNameResult;
                      }
                    } else {
                      firstNameResult$1 = firstNameResult;
                    }
                  } else {
                    firstNameResult$1 = firstNameResult;
                  }
                } else {
                  firstNameResult$1 = firstNameResult;
                }
              } else {
                firstNameResult$1 = firstNameResult;
              }
            } else {
              firstNameResult$1 = firstNameResult;
            }
          } else {
            firstNameResult$1 = firstNameResult;
          }
        } else {
          firstNameResult$1 = firstNameResult;
        }
      } else {
        firstNameResult$1 = firstNameResult;
      }
    } else {
      firstNameResult$1 = firstNameResult;
    }
  } else {
    firstNameResult$1 = firstNameResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            firstName: /* Dirty */{
              _0: firstNameResult$1,
              _1: /* Shown */0
            },
            customerName: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            codeAPE: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            brand: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            engineType: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            vehicleType: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            phase: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            categoryLabel: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            },
            versionID: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            },
            modelID: /* Dirty */{
              _0: match_0$9,
              _1: /* Shown */0
            },
            siren: /* Dirty */{
              _0: match_0$10,
              _1: /* Shown */0
            },
            mainContractSIREN: /* Dirty */{
              _0: match_0$11,
              _1: /* Shown */0
            },
            contractName: /* Dirty */{
              _0: match_0$12,
              _1: /* Shown */0
            },
            contractID: /* Dirty */{
              _0: match_0$13,
              _1: /* Shown */0
            },
            loueurName: /* Dirty */{
              _0: match_0$14,
              _1: /* Shown */0
            },
            loueurID: /* Dirty */{
              _0: match_0$15,
              _1: /* Shown */0
            },
            sirenClientFinalLLDName: /* Dirty */{
              _0: match_0$16,
              _1: /* Shown */0
            },
            sectorManagerID: /* Dirty */{
              _0: match_0$17,
              _1: /* Shown */0
            },
            keyAccountManagerID: /* Dirty */{
              _0: match_0$18,
              _1: /* Shown */0
            },
            dealerGroup: /* Dirty */{
              _0: match_0$19,
              _1: /* Shown */0
            },
            dealerName: /* Dirty */{
              _0: match_0$20,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurFirstNameField */0 :
                  var result = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.firstName, validators_firstName, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.firstName = status;
                          return newrecord;
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCustomerNameField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.customerName, validators_customerName, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.customerName = status;
                          return newrecord;
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCodeAPEField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.codeAPE, validators_codeAPE, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.codeAPE = status;
                          return newrecord;
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBrandField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.brand, validators_brand, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.brand = status;
                          return newrecord;
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEngineTypeField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.engineType, validators_engineType, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.engineType = status;
                          return newrecord;
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVehicleTypeField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.vehicleType, validators_vehicleType, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.vehicleType = status;
                          return newrecord;
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPhaseField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.phase, validators_phase, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.phase = status;
                          return newrecord;
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCategoryLabelField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.categoryLabel, validators_categoryLabel, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.categoryLabel = status;
                          return newrecord;
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionIDField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.versionID, validators_versionID, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.versionID = status;
                          return newrecord;
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurModelIDField */9 :
                  var result$9 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.modelID, validators_modelID, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.modelID = status;
                          return newrecord;
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSirenField */10 :
                  var result$10 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.siren, validators_siren, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.siren = status;
                          return newrecord;
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurMainContractSIRENField */11 :
                  var result$11 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.mainContractSIREN, validators_mainContractSIREN, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.mainContractSIREN = status;
                          return newrecord;
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurContractNameField */12 :
                  var result$12 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.contractName, validators_contractName, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.contractName = status;
                          return newrecord;
                        }));
                  if (result$12 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$12,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurContractIDField */13 :
                  var result$13 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.contractID, validators_contractID, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.contractID = status;
                          return newrecord;
                        }));
                  if (result$13 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$13,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurLoueurNameField */14 :
                  var result$14 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.loueurName, validators_loueurName, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.loueurName = status;
                          return newrecord;
                        }));
                  if (result$14 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$14,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurLoueurIDField */15 :
                  var result$15 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.loueurID, validators_loueurID, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.loueurID = status;
                          return newrecord;
                        }));
                  if (result$15 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$15,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSirenClientFinalLLDNameField */16 :
                  var result$16 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.sirenClientFinalLLDName, validators_sirenClientFinalLLDName, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.sirenClientFinalLLDName = status;
                          return newrecord;
                        }));
                  if (result$16 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$16,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSectorManagerIDField */17 :
                  var result$17 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.sectorManagerID, validators_sectorManagerID, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.sectorManagerID = status;
                          return newrecord;
                        }));
                  if (result$17 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$17,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurKeyAccountManagerIDField */18 :
                  var result$18 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.keyAccountManagerID, validators_keyAccountManagerID, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.keyAccountManagerID = status;
                          return newrecord;
                        }));
                  if (result$18 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$18,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDealerGroupField */19 :
                  var result$19 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.dealerGroup, validators_dealerGroup, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.dealerGroup = status;
                          return newrecord;
                        }));
                  if (result$19 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$19,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDealerNameField */20 :
                  var result$20 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.dealerName, validators_dealerName, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.dealerName = status;
                          return newrecord;
                        }));
                  if (result$20 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$20,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */21 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */21,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */22,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */24);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */23);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */22 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */23 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */24 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateFirstNameField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput, metadata, state.fieldsStatuses.firstName, state.submissionStatus, validators_firstName, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.firstName = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCustomerNameField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$1, metadata, state.fieldsStatuses.customerName, state.submissionStatus, validators_customerName, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.customerName = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCodeAPEField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$2, metadata, state.fieldsStatuses.codeAPE, state.submissionStatus, validators_codeAPE, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.codeAPE = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBrandField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$3, metadata, state.fieldsStatuses.brand, state.submissionStatus, validators_brand, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.brand = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEngineTypeField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$4, metadata, state.fieldsStatuses.engineType, state.submissionStatus, validators_engineType, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.engineType = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVehicleTypeField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$5, metadata, state.fieldsStatuses.vehicleType, state.submissionStatus, validators_vehicleType, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.vehicleType = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePhaseField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$6, metadata, state.fieldsStatuses.phase, state.submissionStatus, validators_phase, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.phase = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCategoryLabelField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$7, metadata, state.fieldsStatuses.categoryLabel, state.submissionStatus, validators_categoryLabel, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.categoryLabel = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionIDField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$21 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$8, metadata, nextFieldsStatuses.contents.modelID, validators_modelID, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses.contents);
                          newrecord.modelID = status;
                          return newrecord;
                        }));
                  if (result$21 !== undefined) {
                    nextFieldsStatuses.contents = result$21;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$8, metadata, nextFieldsStatuses.contents.versionID, state.submissionStatus, validators_versionID, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses.contents);
                                    newrecord.versionID = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateModelIDField */9 :
                  var nextInput$9 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$22 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$9, metadata, nextFieldsStatuses$1.contents.versionID, validators_versionID, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$1.contents);
                          newrecord.versionID = status;
                          return newrecord;
                        }));
                  if (result$22 !== undefined) {
                    nextFieldsStatuses$1.contents = result$22;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$9, metadata, nextFieldsStatuses$1.contents.modelID, state.submissionStatus, validators_modelID, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$1.contents);
                                    newrecord.modelID = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSirenField */10 :
                  var nextInput$10 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$10, metadata, state.fieldsStatuses.siren, state.submissionStatus, validators_siren, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.siren = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateMainContractSIRENField */11 :
                  var nextInput$11 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$11, metadata, state.fieldsStatuses.mainContractSIREN, state.submissionStatus, validators_mainContractSIREN, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.mainContractSIREN = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateContractNameField */12 :
                  var nextInput$12 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$12, metadata, state.fieldsStatuses.contractName, state.submissionStatus, validators_contractName, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.contractName = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateContractIDField */13 :
                  var nextInput$13 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$13,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$13, metadata, state.fieldsStatuses.contractID, state.submissionStatus, validators_contractID, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.contractID = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateLoueurNameField */14 :
                  var nextInput$14 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$14,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$14, metadata, state.fieldsStatuses.loueurName, state.submissionStatus, validators_loueurName, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.loueurName = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateLoueurIDField */15 :
                  var nextInput$15 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$15,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$15, metadata, state.fieldsStatuses.loueurID, state.submissionStatus, validators_loueurID, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.loueurID = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSirenClientFinalLLDNameField */16 :
                  var nextInput$16 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$16,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$16, metadata, state.fieldsStatuses.sirenClientFinalLLDName, state.submissionStatus, validators_sirenClientFinalLLDName, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.sirenClientFinalLLDName = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSectorManagerIDField */17 :
                  var nextInput$17 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$17,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$17, metadata, state.fieldsStatuses.sectorManagerID, state.submissionStatus, validators_sectorManagerID, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.sectorManagerID = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateKeyAccountManagerIDField */18 :
                  var nextInput$18 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$18,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$18, metadata, state.fieldsStatuses.keyAccountManagerID, state.submissionStatus, validators_keyAccountManagerID, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.keyAccountManagerID = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDealerGroupField */19 :
                  var nextInput$19 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$19,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$19, metadata, state.fieldsStatuses.dealerGroup, state.submissionStatus, validators_dealerGroup, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.dealerGroup = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDealerNameField */20 :
                  var nextInput$20 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$20,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$20, metadata, state.fieldsStatuses.dealerName, state.submissionStatus, validators_dealerName, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.dealerName = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */21 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */22 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */23 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateFirstName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateFirstNameField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCustomerName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCustomerNameField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCodeAPE: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCodeAPEField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBrand: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBrandField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEngineType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEngineTypeField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVehicleType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVehicleTypeField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updatePhase: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePhaseField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCategoryLabel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCategoryLabelField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVersionID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionIDField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateModelID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelIDField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateSiren: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSirenField */10,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateMainContractSIREN: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateMainContractSIRENField */11,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateContractName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateContractNameField */12,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateContractID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateContractIDField */13,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateLoueurName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateLoueurNameField */14,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateLoueurID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateLoueurIDField */15,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateSirenClientFinalLLDName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSirenClientFinalLLDNameField */16,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateSectorManagerID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSectorManagerIDField */17,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateKeyAccountManagerID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateKeyAccountManagerIDField */18,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDealerGroup: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDealerGroupField */19,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDealerName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDealerNameField */20,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurFirstName: (function (param) {
              Curry._1(dispatch, /* BlurFirstNameField */0);
            }),
          blurCustomerName: (function (param) {
              Curry._1(dispatch, /* BlurCustomerNameField */1);
            }),
          blurCodeAPE: (function (param) {
              Curry._1(dispatch, /* BlurCodeAPEField */2);
            }),
          blurBrand: (function (param) {
              Curry._1(dispatch, /* BlurBrandField */3);
            }),
          blurEngineType: (function (param) {
              Curry._1(dispatch, /* BlurEngineTypeField */4);
            }),
          blurVehicleType: (function (param) {
              Curry._1(dispatch, /* BlurVehicleTypeField */5);
            }),
          blurPhase: (function (param) {
              Curry._1(dispatch, /* BlurPhaseField */6);
            }),
          blurCategoryLabel: (function (param) {
              Curry._1(dispatch, /* BlurCategoryLabelField */7);
            }),
          blurVersionID: (function (param) {
              Curry._1(dispatch, /* BlurVersionIDField */8);
            }),
          blurModelID: (function (param) {
              Curry._1(dispatch, /* BlurModelIDField */9);
            }),
          blurSiren: (function (param) {
              Curry._1(dispatch, /* BlurSirenField */10);
            }),
          blurMainContractSIREN: (function (param) {
              Curry._1(dispatch, /* BlurMainContractSIRENField */11);
            }),
          blurContractName: (function (param) {
              Curry._1(dispatch, /* BlurContractNameField */12);
            }),
          blurContractID: (function (param) {
              Curry._1(dispatch, /* BlurContractIDField */13);
            }),
          blurLoueurName: (function (param) {
              Curry._1(dispatch, /* BlurLoueurNameField */14);
            }),
          blurLoueurID: (function (param) {
              Curry._1(dispatch, /* BlurLoueurIDField */15);
            }),
          blurSirenClientFinalLLDName: (function (param) {
              Curry._1(dispatch, /* BlurSirenClientFinalLLDNameField */16);
            }),
          blurSectorManagerID: (function (param) {
              Curry._1(dispatch, /* BlurSectorManagerIDField */17);
            }),
          blurKeyAccountManagerID: (function (param) {
              Curry._1(dispatch, /* BlurKeyAccountManagerIDField */18);
            }),
          blurDealerGroup: (function (param) {
              Curry._1(dispatch, /* BlurDealerGroupField */19);
            }),
          blurDealerName: (function (param) {
              Curry._1(dispatch, /* BlurDealerNameField */20);
            }),
          firstNameResult: Formality.exposeFieldResult(state.fieldsStatuses.firstName),
          customerNameResult: Formality.exposeFieldResult(state.fieldsStatuses.customerName),
          codeAPEResult: Formality.exposeFieldResult(state.fieldsStatuses.codeAPE),
          brandResult: Formality.exposeFieldResult(state.fieldsStatuses.brand),
          engineTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.engineType),
          vehicleTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.vehicleType),
          phaseResult: Formality.exposeFieldResult(state.fieldsStatuses.phase),
          categoryLabelResult: Formality.exposeFieldResult(state.fieldsStatuses.categoryLabel),
          versionIDResult: Formality.exposeFieldResult(state.fieldsStatuses.versionID),
          modelIDResult: Formality.exposeFieldResult(state.fieldsStatuses.modelID),
          sirenResult: Formality.exposeFieldResult(state.fieldsStatuses.siren),
          mainContractSIRENResult: Formality.exposeFieldResult(state.fieldsStatuses.mainContractSIREN),
          contractNameResult: Formality.exposeFieldResult(state.fieldsStatuses.contractName),
          contractIDResult: Formality.exposeFieldResult(state.fieldsStatuses.contractID),
          loueurNameResult: Formality.exposeFieldResult(state.fieldsStatuses.loueurName),
          loueurIDResult: Formality.exposeFieldResult(state.fieldsStatuses.loueurID),
          sirenClientFinalLLDNameResult: Formality.exposeFieldResult(state.fieldsStatuses.sirenClientFinalLLDName),
          sectorManagerIDResult: Formality.exposeFieldResult(state.fieldsStatuses.sectorManagerID),
          keyAccountManagerIDResult: Formality.exposeFieldResult(state.fieldsStatuses.keyAccountManagerID),
          dealerGroupResult: Formality.exposeFieldResult(state.fieldsStatuses.dealerGroup),
          dealerNameResult: Formality.exposeFieldResult(state.fieldsStatuses.dealerName),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              var exit = 0;
              if (match.firstName || match.customerName || match.codeAPE || match.brand || match.engineType || match.vehicleType || match.phase || match.categoryLabel || match.versionID || match.modelID || match.siren || match.mainContractSIREN || match.contractName || match.contractID || match.loueurName || match.loueurID || match.sirenClientFinalLLDName || match.sectorManagerID || match.keyAccountManagerID || match.dealerGroup) {
                exit = 1;
              } else {
                if (!match.dealerName) {
                  return false;
                }
                exit = 1;
              }
              if (exit === 1) {
                var exit$1 = 0;
                exit$1 = 2;
                if (exit$1 === 2) {
                  var exit$2 = 0;
                  exit$2 = 3;
                  if (exit$2 === 3) {
                    var exit$3 = 0;
                    exit$3 = 4;
                    if (exit$3 === 4) {
                      var exit$4 = 0;
                      exit$4 = 5;
                      if (exit$4 === 5) {
                        var exit$5 = 0;
                        exit$5 = 6;
                        if (exit$5 === 6) {
                          var exit$6 = 0;
                          exit$6 = 7;
                          if (exit$6 === 7) {
                            var exit$7 = 0;
                            exit$7 = 8;
                            if (exit$7 === 8) {
                              var exit$8 = 0;
                              exit$8 = 9;
                              if (exit$8 === 9) {
                                var exit$9 = 0;
                                exit$9 = 10;
                                if (exit$9 === 10) {
                                  return true;
                                }
                                
                              }
                              
                            }
                            
                          }
                          
                        }
                        
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */21);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */22);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */23);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */23,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */24);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  dealerName: "",
  dealerGroup: "",
  keyAccountManagerID: "",
  sectorManagerID: "",
  sirenClientFinalLLDName: "",
  loueurID: "",
  loueurName: "",
  contractID: "",
  contractName: "",
  mainContractSIREN: "",
  siren: "",
  modelID: /* [] */0,
  versionID: /* [] */0,
  categoryLabel: /* [] */0,
  phase: "",
  vehicleType: "",
  engineType: /* [] */0,
  brand: "",
  codeAPE: /* [] */0,
  customerName: "",
  firstName: ""
};

export {
  TableFilterForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
