// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../../../common/types/App_Types_Input.bs.js";
import * as PCDAgreement_Search_Types$DvmAdminFrontendFr from "./PCDAgreement_Search_Types.bs.js";

function fetchPCDAgreementSearch(submissionCallbacks, userManager, setResult, queryParams, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", PCDAgreement_Search_Types$DvmAdminFrontendFr.PCDRows.searchResult_decode, undefined, userManager, selectedRole, "/agreements" + queryParams + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchPCDAgreementSearch */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function queryForDealersSelect(alert, userManager, setDealers, selectedRole) {
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.stringList_decode, undefined, userManager, selectedRole, "/dropdown/dealers").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var dealers = result._0;
          return Curry._1(setDealers, (function (param) {
                        return dealers;
                      }));
        }
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: result._0
                  }
                }));
        return Curry._1(setDealers, (function (param) {
                      return /* [] */0;
                    }));
      });
}

function createDateBefore(param) {
  return ReDate.subYears(ReDate.startOfYear(new Date()), 2.0).toISOString();
}

function queryForAVSSelects(alert, userManager, setCategoryCodes, selectedRole) {
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.DropdownCategories.categoryList_decode, undefined, userManager, selectedRole, "/dropdown/category/all?filter=startDate=%5B" + createDateBefore(undefined) + "%5C,%5D").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var categories = result._0;
          return Curry._1(setCategoryCodes, (function (param) {
                        return Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(categories, (function (x) {
                                                  return x.categoryID;
                                                })))));
                      }));
        }
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: result._0
                  }
                }));
        return Curry._1(setCategoryCodes, (function (param) {
                      return /* [] */0;
                    }));
      });
}

var Form;

export {
  Form ,
  fetchPCDAgreementSearch ,
  queryForDealersSelect ,
  createDateBefore ,
  queryForAVSSelects ,
}
/* react Not a pure module */
