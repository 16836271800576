// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as App_Table$DvmAdminFrontendFr from "../../../../common/components/table/App_Table.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../common/utils/Utils_Api.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../../../common/utils/Utils_Date.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Pagination.bs.js";
import * as App_Table_Export_Menu$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Export_Menu.bs.js";
import * as Contract_Attachments_Add$DvmAdminFrontendFr from "./edit/Contract_Attachments_Add.bs.js";
import * as Contract_Attachments_Api$DvmAdminFrontendFr from "./Contract_Attachments_Api.bs.js";
import * as Messages_Contract_Create$DvmAdminFrontendFr from "../../../../intl/messages/contract/Messages_Contract_Create.bs.js";
import * as Contract_Attachments_Types$DvmAdminFrontendFr from "./Contract_Attachments_Types.bs.js";
import * as Contract_Attachments_Filter$DvmAdminFrontendFr from "./Contract_Attachments_Filter.bs.js";
import * as Contract_Attachment_Single_Modal$DvmAdminFrontendFr from "./edit/Contract_Attachment_Single_Modal.bs.js";
import * as Contract_Attachments_Delete_Modal$DvmAdminFrontendFr from "./edit/Contract_Attachments_Delete_Modal.bs.js";

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Contract_Attachments_Types$DvmAdminFrontendFr.Fields.siren, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.siren);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Contract_Attachments_Types$DvmAdminFrontendFr.Fields.sirenName, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.companyName),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Contract_Attachments_Types$DvmAdminFrontendFr.Fields.startDate, undefined, undefined, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.startDate),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Contract_Attachments_Types$DvmAdminFrontendFr.Fields.endDate, undefined, undefined, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.endDate),
      tl: /* [] */0
    }
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function Contract_Attachments_Table(Props) {
  var contract = Props.contract;
  var className = Props.className;
  var fetchedContract = Props.fetchedContract;
  var operation = Props.operation;
  var fixedQueryParams = "contractId=" + contract.contractID + "" + Belt_Option.mapWithDefault(Utils_Api$DvmAdminFrontendFr.datePairToQueryParam(Caml_option.some(contract.startDate), Caml_option.some(contract.endDate), "startDate"), "", (function (x) {
          return ";" + x;
        }));
  var match = React.useState(function () {
        return false;
      });
  var setModal = match[1];
  var match$1 = React.useState(function () {
        return null;
      });
  var setDeleteModal = match$1[1];
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match$2[1];
  var match$3 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: Caml_array.get(App_Table_Pagination$DvmAdminFrontendFr.options, 0),
                headers: headersConfiguration,
                filterQueryParams: fixedQueryParams,
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$3[1];
  var fetchRequest = match$3[0];
  var match$4 = React.useState(function () {
        return null;
      });
  var setAddRowsModal = match$4[1];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match$5[1];
  var saveResult = match$5[0];
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match$6 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$7 = match$6[0];
  var userManager = match$7.userManager;
  var selectedRole = match$7.selectedRole;
  var match$8 = React.useState(function () {
        
      });
  var setOriginalStartDate = match$8[1];
  var originalStartDate = match$8[0];
  var match$9 = React.useState(function () {
        
      });
  var setOriginalEndDate = match$9[1];
  var originalEndDate = match$9[0];
  var match$10 = React.useState(function () {
        
      });
  var setSiren = match$10[1];
  var fetchData = function (param, fetchRequest) {
    Contract_Attachments_Api$DvmAdminFrontendFr.fetchAttachments(Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, fetchRequest), setTablePage, userManager, selectedRole);
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  React.useEffect((function () {
          if (App_Types_Result$DvmAdminFrontendFr.isPending(fetchedContract)) {
            
          } else {
            fetchData(undefined, {
                  page: fetchRequest.page,
                  rowsPerPage: fetchRequest.rowsPerPage,
                  headers: fetchRequest.headers,
                  filterQueryParams: "contractId=" + contract.contractID + "" + Belt_Option.mapWithDefault(Utils_Api$DvmAdminFrontendFr.datePairToQueryParam(Caml_option.some(contract.startDate), Caml_option.some(contract.endDate), "startDate"), "", (function (x) {
                          return ";" + x;
                        })),
                  additionalQueryParams: fetchRequest.additionalQueryParams
                });
          }
        }), [contract]);
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(setModal, (function (param) {
                    return false;
                  }));
            fetchData(undefined, fetchRequest);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (param) {
                                  var endDate = param.endDate;
                                  var startDate = param.startDate;
                                  var siren = param.siren;
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, operation === /* Manage */1 ? Caml_option.some(React.createElement(Core.Grid, {
                                                        children: null,
                                                        container: true,
                                                        justifyContent: "flex-start",
                                                        wrap: "nowrap"
                                                      }, React.createElement(Core.Grid, {
                                                            children: React.createElement(Core.Button, {
                                                                  onClick: (function (param) {
                                                                      Curry._1(setOriginalStartDate, (function (param) {
                                                                              return startDate;
                                                                            }));
                                                                      Curry._1(setOriginalEndDate, (function (param) {
                                                                              return endDate;
                                                                            }));
                                                                      Curry._1(setSiren, (function (param) {
                                                                              return siren;
                                                                            }));
                                                                      Curry._1(setModal, (function (param) {
                                                                              return true;
                                                                            }));
                                                                    }),
                                                                  children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                        size: /* Large */3,
                                                                        type_: /* Edit */60
                                                                      }),
                                                                  key: "edit"
                                                                }),
                                                            item: true
                                                          }), React.createElement(Core.Grid, {
                                                            children: React.createElement(Core.Button, {
                                                                  onClick: (function (param) {
                                                                      Curry._1(setDeleteModal, (function (param) {
                                                                              return React.createElement(Contract_Attachments_Delete_Modal$DvmAdminFrontendFr.make, {
                                                                                          attachment: {
                                                                                            contractID: contract.contractID,
                                                                                            siren: siren,
                                                                                            sirenName: undefined,
                                                                                            startDate: startDate,
                                                                                            endDate: endDate
                                                                                          },
                                                                                          onClose: (function (param) {
                                                                                              Curry._1(setDeleteModal, (function (param) {
                                                                                                      return null;
                                                                                                    }));
                                                                                            }),
                                                                                          fetchRequest: fetchRequest,
                                                                                          fetchData: fetchData
                                                                                        });
                                                                            }));
                                                                    }),
                                                                  children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                        size: /* Large */3,
                                                                        type_: /* Trash */38,
                                                                        color: "red"
                                                                      }),
                                                                  key: "delete"
                                                                }),
                                                            item: true
                                                          }))) : undefined, undefined, Belt_List.mapWithIndex({
                                                  hd: Belt_Option.getWithDefault(siren, ""),
                                                  tl: {
                                                    hd: Belt_Option.getWithDefault(param.sirenName, ""),
                                                    tl: {
                                                      hd: Belt_Option.mapWithDefault(startDate, "", (function (param) {
                                                              return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                            })),
                                                      tl: {
                                                        hd: Belt_Option.mapWithDefault(endDate, Belt_Option.mapWithDefault(contract.endDate, "", (function (param) {
                                                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                  })), (function (param) {
                                                                return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                              })),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    fetchData(undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    fetchData(undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    fetchData(undefined, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var tmp = {
    alignItems: "stretch",
    children: null,
    container: true,
    direction: "column"
  };
  if (className !== undefined) {
    tmp.className = className;
  }
  var tmp$1 = {
    headers: fetchRequest.headers,
    handleSorting: handleSorting,
    tablePage: convertTable(match$2[0]),
    paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined),
    exportMenu: React.createElement(App_Table_Export_Menu$DvmAdminFrontendFr.make, {
          query: Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                page: 0,
                rowsPerPage: 150000,
                headers: fetchRequest.headers,
                filterQueryParams: fetchRequest.filterQueryParams,
                additionalQueryParams: fetchRequest.additionalQueryParams
              }) + ("&contractId=" + contract.contractID + "" + Belt_Option.mapWithDefault(contract.startDate, "", (function (x) {
                    return "&contractStartDate=" + x.toISOString() + "";
                  })) + ""),
          endpoint: "contract/attachment",
          filename: "contrat_rattachements_" + contract.contractID + "_" + contract.budget + "_" + Belt_Option.getWithDefault(contract.contractSIREN, "") + "_" + Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(contract.startDate), "") + "_" + Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(contract.endDate), "")
        })
  };
  var tmp$2 = operation === /* Manage */1 ? ({
        TAG: /* Left */0,
        _0: /* EmptyCell */0
      }) : undefined;
  if (tmp$2 !== undefined) {
    tmp$1.rowButton = Caml_option.valFromOption(tmp$2);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, tmp, React.createElement(Core.Grid, {
                      alignItems: "center",
                      children: null,
                      container: true,
                      item: true,
                      justifyContent: "space-between"
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(Contract_Attachments_Filter$DvmAdminFrontendFr.make, {
                                fetchData: fetchData,
                                fetchRequest: fetchRequest,
                                fixedQueryParams: fixedQueryParams
                              }),
                          item: true
                        }), operation === /* Manage */1 ? React.createElement(Core.Grid, {
                            children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                  onClick: (function (param) {
                                      Curry._1(setAddRowsModal, (function (param) {
                                              return React.createElement(Contract_Attachments_Add$DvmAdminFrontendFr.make, {
                                                          onClose: (function (param) {
                                                              Curry._1(setAddRowsModal, (function (param) {
                                                                      return null;
                                                                    }));
                                                            }),
                                                          contract: contract,
                                                          onOk: (function (param) {
                                                              Curry._1(setAddRowsModal, (function (param) {
                                                                      return null;
                                                                    }));
                                                              fetchData(undefined, fetchRequest);
                                                            })
                                                        });
                                            }));
                                    }),
                                  label: intl.formatMessage(Messages_Contract_Create$DvmAdminFrontendFr.addAttachments),
                                  color: "primaryBorder",
                                  size: "medium"
                                }),
                            item: true
                          }) : null), React.createElement(Core.Grid, {
                      children: React.createElement(App_Table$DvmAdminFrontendFr.make, tmp$1),
                      item: true
                    })), match[0] ? Belt_Option.mapWithDefault(match$10[0], null, (function (siren) {
                      var tmp = {
                        originalStartDate: originalStartDate,
                        originalEndDate: originalEndDate,
                        asParentLabel: Messages_Contract_Create$DvmAdminFrontendFr.asContract,
                        showSpinner: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult),
                        onClose: (function (param) {
                            Curry._1(setModal, (function (param) {
                                    return false;
                                  }));
                          }),
                        onSubmit: (function (param, param$1) {
                            return Contract_Attachments_Api$DvmAdminFrontendFr.updateOrDeleteAttachment(setSaveResult, userManager, /* Update */{
                                        _0: {
                                          contractID: contract.contractID,
                                          siren: siren,
                                          sirenName: undefined,
                                          startDate: param.startDate,
                                          endDate: param.endDate
                                        }
                                      }, {
                                        contractID: contract.contractID,
                                        siren: siren,
                                        sirenName: undefined,
                                        startDate: originalStartDate,
                                        endDate: originalEndDate
                                      }, selectedRole);
                          }),
                        blockOldStartDate: false,
                        siren: siren,
                        contract: contract
                      };
                      if (contract.startDate !== undefined) {
                        tmp.parentStartDate = Caml_option.valFromOption(contract.startDate);
                      }
                      if (contract.endDate !== undefined) {
                        tmp.parentEndDate = Caml_option.valFromOption(contract.endDate);
                      }
                      return React.createElement(Contract_Attachment_Single_Modal$DvmAdminFrontendFr.make, tmp);
                    })) : null, match$1[0], match$4[0]);
}

var Attachments_Add;

var Api;

var make = Contract_Attachments_Table;

export {
  Attachments_Add ,
  Api ,
  headersConfiguration ,
  make ,
}
/* headersConfiguration Not a pure module */
