// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Quota_Api$DvmAdminFrontendFr from "../Quota_Api.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../common/utils/Utils_Api.bs.js";
import * as App_Spinner$DvmAdminFrontendFr from "../../../../common/components/App_Spinner.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../../../context/router/Context_Router.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Table_Result$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Result.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../common/components/App_OverlaySpinner.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Pagination.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as App_Table_Create_Button$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Create_Button.bs.js";
import * as Quota_View_Exclusion_Add$DvmAdminFrontendFr from "./modal/selection/Quota_View_Exclusion_Add.bs.js";
import * as Quota_View_Exclusion_Api$DvmAdminFrontendFr from "./Quota_View_Exclusion_Api.bs.js";
import * as Quota_View_Exclusion_Form$DvmAdminFrontendFr from "./Quota_View_Exclusion_Form.bs.js";
import * as Quota_View_Exclusion_Table$DvmAdminFrontendFr from "./Quota_View_Exclusion_Table.bs.js";
import * as Quota_View_Exclusion_Filters$DvmAdminFrontendFr from "./Quota_View_Exclusion_Filters.bs.js";

function Quota_View_Exclusion(Props) {
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match[1];
  var tablePage = match[0];
  var match$1 = React.useState(function () {
        return /* [] */0;
      });
  var setContractOptions = match$1[1];
  var contractOptions = match$1[0];
  var match$2 = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$3 = React.useState(function () {
        return false;
      });
  var withClosedModels = match$3[0];
  var match$4 = React.useState(function () {
        return null;
      });
  var setExcludeModal = match$4[1];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var deleteResult = match$5[0];
  var match$6 = React.useState(function () {
        return null;
      });
  var match$7 = React.useState(function () {
        return /* Model */0;
      });
  var tableType = match$7[0];
  var match$8 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: Caml_array.get(App_Table_Pagination$DvmAdminFrontendFr.options, 0),
                headers: Quota_View_Exclusion_Table$DvmAdminFrontendFr.headersConfiguration(tableType),
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$8[1];
  var fetchRequest = match$8[0];
  var match$9 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$10 = match$9[0];
  var userManager = match$10.userManager;
  var selectedRole = match$10.selectedRole;
  var fetchData = function (submissionCallbacks, fetchRequest) {
    Quota_View_Exclusion_Api$DvmAdminFrontendFr.fetchQuotaDetailExclusionSearch(submissionCallbacks, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
              page: fetchRequest.page,
              rowsPerPage: Caml_array.get(App_Table_Pagination$DvmAdminFrontendFr.options, 0),
              headers: fetchRequest.headers,
              filterQueryParams: fetchRequest.filterQueryParams,
              additionalQueryParams: fetchRequest.additionalQueryParams
            }), tableType, selectedRole, withClosedModels);
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  var form = Quota_View_Exclusion_Form$DvmAdminFrontendFr.TableViewExclusionFilter.useForm(Belt_Option.mapWithDefault(match$2[0].quotaExclusionsFilter, Quota_View_Exclusion_Form$DvmAdminFrontendFr.initialState, (function (filter) {
              return filter;
            })), (function (param, submissionCallbacks) {
          fetchData(submissionCallbacks, {
                page: fetchRequest.page,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: Quota_View_Exclusion_Table$DvmAdminFrontendFr.headersConfiguration(tableType),
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap(Belt_List.keepMap({
                                  hd: Caml_option.some(param.model),
                                  tl: {
                                    hd: tableType === /* Model */0 ? undefined : Caml_option.some(param.version),
                                    tl: {
                                      hd: Caml_option.some(param.contractId),
                                      tl: {
                                        hd: Caml_option.some(param.vehicleType),
                                        tl: {
                                          hd: Caml_option.some(param.startDate),
                                          tl: {
                                            hd: Caml_option.some(param.endDate),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }, (function (x) {
                                    return x;
                                  })), (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  React.useEffect((function () {
          Quota_Api$DvmAdminFrontendFr.queryForContractIdSelect(alert, userManager, setContractOptions, selectedRole);
          Curry._1(form.submit, undefined);
        }), []);
  var refreshVersions = function (param) {
    fetchData(undefined, fetchRequest);
  };
  React.useEffect((function () {
          if (typeof deleteResult === "number") {
            deleteResult === /* NotStarted */0;
          } else if (deleteResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* String */3,
                        _0: deleteResult._0
                      }
                    }));
            fetchData(undefined, fetchRequest);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: deleteResult._0
                      }
                    }));
          }
        }), [deleteResult]);
  React.useEffect((function () {
          Curry._1(form.submit, undefined);
        }), [tableType]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.forError(tablePage, (function (error) {
                  Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                            message: {
                              TAG: /* RestError */2,
                              _0: error
                            }
                          }));
                }));
        }), [tablePage]);
  var tmp;
  var exit = 0;
  if (typeof tablePage === "number") {
    exit = 1;
  } else {
    tmp = tablePage.TAG === /* Ok */0 ? React.createElement(App_Table_Result$DvmAdminFrontendFr.make, {
            number: tablePage._0.total,
            message: tableType ? Messages_Common$DvmAdminFrontendFr.versions : Messages_Common$DvmAdminFrontendFr.models
          }) : null;
  }
  if (exit === 1) {
    tmp = React.createElement(App_Spinner$DvmAdminFrontendFr.make, {});
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  children: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.exclusionTitle),
                  variant: "h3"
                }), React.createElement(Quota_View_Exclusion_Filters$DvmAdminFrontendFr.make, {
                  form: form,
                  tableType: tableType,
                  contractOptions: contractOptions,
                  withClosedModels: withClosedModels,
                  setWithClosedModels: match$3[1]
                }), tmp, React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                  show: App_Types_Result$DvmAdminFrontendFr.isPending(deleteResult),
                  children: React.createElement(Quota_View_Exclusion_Table$DvmAdminFrontendFr.make, {
                        tablePage: tablePage,
                        fetchRequest: fetchRequest,
                        fetchData: fetchData,
                        refreshVersions: refreshVersions,
                        tableType: tableType,
                        excludeElement: React.createElement(App_Table_Create_Button$DvmAdminFrontendFr.make, {
                              onClick: (function (param) {
                                  Curry._1(setExcludeModal, (function (param) {
                                          return React.createElement(Quota_View_Exclusion_Add$DvmAdminFrontendFr.make, {
                                                      onClose: (function (param) {
                                                          Curry._1(setExcludeModal, (function (param) {
                                                                  return null;
                                                                }));
                                                        }),
                                                      onOk: (function (param) {
                                                          Curry._1(setExcludeModal, (function (param) {
                                                                  return null;
                                                                }));
                                                          Curry._1(form.submit, undefined);
                                                        }),
                                                      tableType: tableType,
                                                      contractOptions: contractOptions
                                                    });
                                        }));
                                }),
                              icon: /* Cross */42,
                              message: Messages_Quotas_Detail$DvmAdminFrontendFr.addExclusion
                            })
                      })
                }), match$6[0], match$4[0]);
}

var Api;

var Table;

var Filter;

var Exclude_Modal;

var Form;

var make = Quota_View_Exclusion;

export {
  Api ,
  Table ,
  Filter ,
  Exclude_Modal ,
  Form ,
  make ,
}
/* react Not a pure module */
