// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/core/styles";
import * as App_Table_Head$DvmAdminFrontendFr from "./App_Table_Head.bs.js";
import * as App_Table_Styles$DvmAdminFrontendFr from "./App_Table_Styles.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../App_OverlaySpinner.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "./App_Table_Pagination.bs.js";

function makeCell(alignOpt, backgroundColorOpt, colorOpt, content) {
  var align = alignOpt !== undefined ? alignOpt : "left";
  var backgroundColor = backgroundColorOpt !== undefined ? Caml_option.valFromOption(backgroundColorOpt) : undefined;
  var color = colorOpt !== undefined ? Caml_option.valFromOption(colorOpt) : undefined;
  return {
          content: content,
          align: align,
          backgroundColor: backgroundColor,
          color: color
        };
}

function App_Table(Props) {
  var headers = Props.headers;
  var handleSorting = Props.handleSorting;
  var tablePage = Props.tablePage;
  var paginator = Props.paginator;
  var rowButton = Props.rowButton;
  var exportMenuOpt = Props.exportMenu;
  var width = Props.width;
  var rowHeight = Props.rowHeight;
  var rowPadding = Props.rowPadding;
  var height = Props.height;
  var disablePaginatorOpt = Props.disablePaginator;
  var exportMenu = exportMenuOpt !== undefined ? Caml_option.valFromOption(exportMenuOpt) : null;
  var disablePaginator = disablePaginatorOpt !== undefined ? disablePaginatorOpt : false;
  var theme = Styles.useTheme();
  var match = React.useState(function () {
        
      });
  var setLastLoadedPage = match[1];
  var lastLoadedPage = match[0];
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.forSuccess(tablePage, (function (page) {
                  Curry._1(setLastLoadedPage, (function (param) {
                          return page;
                        }));
                }));
        }), [tablePage]);
  var renderTableContent = function (tableContent) {
    var tmp = {
      headers: headers,
      rowButton: rowButton
    };
    if (handleSorting !== undefined) {
      tmp.handleSorting = Caml_option.valFromOption(handleSorting);
    }
    var tmp$1 = {
      children: React.createElement(Core.Table, {
            children: null,
            stickyHeader: true
          }, React.createElement(App_Table_Head$DvmAdminFrontendFr.make, tmp), React.createElement(Core.TableBody, {
                children: tableContent
              })),
      className: App_Table_Styles$DvmAdminFrontendFr.table
    };
    var tmp$2 = Belt_Option.mapWithDefault(height, undefined, (function (x) {
            return {
                    maxHeight: x
                  };
          }));
    if (tmp$2 !== undefined) {
      tmp$1.style = Caml_option.valFromOption(tmp$2);
    }
    return React.createElement(Core.TableContainer, tmp$1);
  };
  var renderTable = function (tableContent) {
    return renderTableContent(Belt_List.toArray(Belt_List.mapWithIndex(tableContent, (function (rowIndex, row) {
                          var tmp = {
                            children: null,
                            hover: Belt_Option.isSome(row.onClick),
                            key: "row" + String(rowIndex)
                          };
                          var tmp$1 = Belt_Option.getWithDefault(row.disabled, false) ? App_Table_Styles$DvmAdminFrontendFr.tableCellDisabled(theme) : undefined;
                          if (tmp$1 !== undefined) {
                            tmp.className = tmp$1;
                          }
                          var tmp$2 = Belt_Option.getWithDefault(row.disabled, false) || !Belt_Option.isSome(row.onClick) ? undefined : ({
                                cursor: "pointer"
                              });
                          if (tmp$2 !== undefined) {
                            tmp.style = Caml_option.valFromOption(tmp$2);
                          }
                          var match = row.rowButton;
                          var tmp$3;
                          tmp$3 = rowButton !== undefined && rowButton.TAG === /* Left */0 && match !== undefined ? React.createElement(Core.TableCell, {
                                  children: React.createElement("div", {
                                        className: App_Table_Styles$DvmAdminFrontendFr.tableCellContent
                                      }, Caml_option.valFromOption(match)),
                                  className: App_Table_Styles$DvmAdminFrontendFr.tableCell(rowPadding, rowHeight),
                                  key: "editCell"
                                }) : null;
                          var match$1 = row.rowButton;
                          var tmp$4;
                          tmp$4 = rowButton !== undefined && !(rowButton.TAG === /* Left */0 || match$1 === undefined) ? React.createElement(Core.TableCell, {
                                  children: React.createElement("div", {
                                        className: App_Table_Styles$DvmAdminFrontendFr.tableCellContent
                                      }, Caml_option.valFromOption(match$1)),
                                  className: App_Table_Styles$DvmAdminFrontendFr.tableCell(rowPadding, rowHeight),
                                  key: "editCell"
                                }) : null;
                          return React.createElement(Core.TableRow, tmp, tmp$3, Belt_List.toArray(Belt_List.mapWithIndex(row.cells, (function (cellIndex, param) {
                                                var color = param.color;
                                                var backgroundColor = param.backgroundColor;
                                                var match = row.disabled;
                                                var match$1 = row.onClick;
                                                var tmp;
                                                var exit = 0;
                                                if (match !== undefined && match) {
                                                  tmp = undefined;
                                                } else {
                                                  exit = 1;
                                                }
                                                if (exit === 1) {
                                                  tmp = match$1 !== undefined ? Curry.__1(match$1) : undefined;
                                                }
                                                var tmp$1 = {
                                                  className: App_Table_Styles$DvmAdminFrontendFr.tableCellContent
                                                };
                                                if (tmp !== undefined) {
                                                  tmp$1.onClick = Caml_option.valFromOption(tmp);
                                                }
                                                var tmp$2 = {
                                                  align: param.align,
                                                  children: React.createElement("div", tmp$1, param.content),
                                                  className: Css.cx([
                                                        App_Table_Styles$DvmAdminFrontendFr.tableCell(rowPadding, rowHeight),
                                                        Belt_Option.getWithDefault(row.disabled, false) ? App_Table_Styles$DvmAdminFrontendFr.tableCellDisabled(theme) : ""
                                                      ]),
                                                  key: "cell" + String(rowIndex) + String(cellIndex)
                                                };
                                                var tmp$3 = backgroundColor !== undefined ? (
                                                    color !== undefined ? ({
                                                          backgroundColor: backgroundColor,
                                                          color: color
                                                        }) : ({
                                                          backgroundColor: backgroundColor
                                                        })
                                                  ) : (
                                                    color !== undefined ? ({
                                                          color: color
                                                        }) : undefined
                                                  );
                                                if (tmp$3 !== undefined) {
                                                  tmp$2.style = Caml_option.valFromOption(tmp$3);
                                                }
                                                return React.createElement(Core.TableCell, tmp$2);
                                              }))), tmp$4);
                        }))));
  };
  if (lastLoadedPage === undefined) {
    return renderTableContent(null);
  }
  var totalElements = lastLoadedPage.totalElements;
  var currentPage = lastLoadedPage.currentPage;
  var content = lastLoadedPage.content;
  var tmp = {};
  var tmp$1 = Belt_Option.mapWithDefault(width, undefined, (function (x) {
          return {
                  minWidth: x
                };
        }));
  if (tmp$1 !== undefined) {
    tmp.style = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2;
  tmp$2 = typeof tablePage === "number" ? (
      tablePage === /* NotStarted */0 ? null : renderTable(content)
    ) : (
      tablePage.TAG === /* Ok */0 ? renderTable(tablePage._0.content) : renderTable(content)
    );
  return React.createElement("div", tmp, React.createElement(Core.Box, {
                  children: null,
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between"
                }, paginator !== undefined && paginator.position === /* TOP */0 ? React.createElement(App_Table_Pagination$DvmAdminFrontendFr.make, {
                        totalElements: totalElements,
                        rowsPerPage: paginator.rowsPerPage,
                        currentPage: currentPage,
                        handleChangePage: paginator.handleChangePage,
                        handleRowsPerPageChange: paginator.handleRowsPerPageChange,
                        disablePaginator: disablePaginator
                      }) : null, exportMenu), React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                  show: App_Types_Result$DvmAdminFrontendFr.isPending(tablePage),
                  children: tmp$2
                }), paginator !== undefined && paginator.position === /* BOTTOM */1 ? React.createElement(App_Table_Pagination$DvmAdminFrontendFr.make, {
                    totalElements: totalElements,
                    rowsPerPage: paginator.rowsPerPage,
                    currentPage: currentPage,
                    handleChangePage: paginator.handleChangePage,
                    handleRowsPerPageChange: paginator.handleRowsPerPageChange,
                    variant: /* SMALL */1,
                    disablePaginator: disablePaginator
                  }) : null);
}

var Pagination;

var Head;

var Styles$1;

var make = App_Table;

export {
  Pagination ,
  Head ,
  Styles$1 as Styles,
  makeCell ,
  make ,
}
/* react Not a pure module */
