// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function homeMessageGroup_encode(v) {
  return Js_dict.fromArray([
              [
                "renaultSas",
                Decco.boolToJson(v.renaultSas)
              ],
              [
                "network",
                Decco.boolToJson(v.network)
              ]
            ]);
}

function homeMessageGroup_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var renaultSas = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "renaultSas"), null));
  if (renaultSas.TAG === /* Ok */0) {
    var network = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "network"), null));
    if (network.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                renaultSas: renaultSas._0,
                network: network._0
              }
            };
    }
    var e = network._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".network" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = renaultSas._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".renaultSas" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function homeMessageRequest_encode(v) {
  return Js_dict.fromArray([
              [
                "title",
                Decco.stringToJson(v.title)
              ],
              [
                "message",
                Decco.stringToJson(v.message)
              ],
              [
                "group",
                homeMessageGroup_encode(v.group)
              ]
            ]);
}

function homeMessageRequest_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var message = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "message"), null));
    if (message.TAG === /* Ok */0) {
      var group = homeMessageGroup_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "group"), null));
      if (group.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  title: title._0,
                  message: message._0,
                  group: group._0
                }
              };
      }
      var e = group._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".group" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = message._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".message" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function homeMessage_encode(v) {
  return Js_dict.fromArray([
              [
                "uuid",
                Decco.stringToJson(v.uuid)
              ],
              [
                "creator",
                Decco.stringToJson(v.creator)
              ],
              [
                "creatorName",
                Decco.stringToJson(v.creatorName)
              ],
              [
                "createDate",
                Decco.stringToJson(v.createDate)
              ],
              [
                "title",
                Decco.stringToJson(v.title)
              ],
              [
                "message",
                Decco.stringToJson(v.message)
              ],
              [
                "group",
                homeMessageGroup_encode(v.group)
              ],
              [
                "noted",
                Decco.boolToJson(v.noted)
              ],
              [
                "editor",
                Decco.optionToJson(Decco.stringToJson, v.editor)
              ],
              [
                "editDate",
                Decco.optionToJson(Decco.stringToJson, v.editDate)
              ]
            ]);
}

function homeMessage_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var uuid = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "uuid"), null));
  if (uuid.TAG === /* Ok */0) {
    var creator = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "creator"), null));
    if (creator.TAG === /* Ok */0) {
      var creatorName = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "creatorName"), null));
      if (creatorName.TAG === /* Ok */0) {
        var createDate = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "createDate"), null));
        if (createDate.TAG === /* Ok */0) {
          var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
          if (title.TAG === /* Ok */0) {
            var message = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "message"), null));
            if (message.TAG === /* Ok */0) {
              var group = homeMessageGroup_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "group"), null));
              if (group.TAG === /* Ok */0) {
                var noted = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "noted"), null));
                if (noted.TAG === /* Ok */0) {
                  var editor = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "editor"), null));
                  if (editor.TAG === /* Ok */0) {
                    var editDate = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "editDate"), null));
                    if (editDate.TAG === /* Ok */0) {
                      return {
                              TAG: /* Ok */0,
                              _0: {
                                uuid: uuid._0,
                                creator: creator._0,
                                creatorName: creatorName._0,
                                createDate: createDate._0,
                                title: title._0,
                                message: message._0,
                                group: group._0,
                                noted: noted._0,
                                editor: editor._0,
                                editDate: editDate._0
                              }
                            };
                    }
                    var e = editDate._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".editDate" + e.path,
                              message: e.message,
                              value: e.value
                            }
                          };
                  }
                  var e$1 = editor._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".editor" + e$1.path,
                            message: e$1.message,
                            value: e$1.value
                          }
                        };
                }
                var e$2 = noted._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".noted" + e$2.path,
                          message: e$2.message,
                          value: e$2.value
                        }
                      };
              }
              var e$3 = group._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".group" + e$3.path,
                        message: e$3.message,
                        value: e$3.value
                      }
                    };
            }
            var e$4 = message._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".message" + e$4.path,
                      message: e$4.message,
                      value: e$4.value
                    }
                  };
          }
          var e$5 = title._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".title" + e$5.path,
                    message: e$5.message,
                    value: e$5.value
                  }
                };
        }
        var e$6 = createDate._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".createDate" + e$6.path,
                  message: e$6.message,
                  value: e$6.value
                }
              };
      }
      var e$7 = creatorName._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".creatorName" + e$7.path,
                message: e$7.message,
                value: e$7.value
              }
            };
    }
    var e$8 = creator._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".creator" + e$8.path,
              message: e$8.message,
              value: e$8.value
            }
          };
  }
  var e$9 = uuid._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".uuid" + e$9.path,
            message: e$9.message,
            value: e$9.value
          }
        };
}

function homeMessages_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.arrayToJson(homeMessage_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ],
              [
                "unreadMsg",
                Decco.intToJson(v.unreadMsg)
              ]
            ]);
}

function homeMessages_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.arrayFromJson(homeMessage_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      var unreadMsg = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "unreadMsg"), null));
      if (unreadMsg.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  content: content._0,
                  total: total._0,
                  unreadMsg: unreadMsg._0
                }
              };
      }
      var e = unreadMsg._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".unreadMsg" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function quickAccessResponse_encode(v) {
  return Decco.arrayToJson(Decco.stringToJson, v);
}

function quickAccessResponse_decode(v) {
  return Decco.arrayFromJson(Decco.stringFromJson, v);
}

export {
  homeMessageGroup_encode ,
  homeMessageGroup_decode ,
  homeMessageRequest_encode ,
  homeMessageRequest_decode ,
  homeMessage_encode ,
  homeMessage_decode ,
  homeMessages_encode ,
  homeMessages_decode ,
  quickAccessResponse_encode ,
  quickAccessResponse_decode ,
}
/* No side effect */
