// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";

var Fields = {
  jobId: "jobId",
  fileType: "fileType",
  start: "start",
  end: "end",
  batchResult: "batchResult",
  direction: "direction",
  application: "application",
  fileTypeLocal: "fileTypeLocal"
};

function publishRow_encode(v) {
  return Js_dict.fromArray([
              [
                "jobId",
                Decco.optionToJson(Decco.stringToJson, v.jobId)
              ],
              [
                "fileType",
                Decco.optionToJson(Decco.stringToJson, v.fileType)
              ],
              [
                "start",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.start)
              ],
              [
                "end",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.end)
              ],
              [
                "batchResult",
                Decco.optionToJson(Decco.stringToJson, v.batchResult)
              ],
              [
                "direction",
                Decco.optionToJson(Decco.stringToJson, v.direction)
              ],
              [
                "application",
                Decco.optionToJson(Decco.stringToJson, v.application)
              ],
              [
                "fileTypeLocal",
                Decco.optionToJson(Decco.stringToJson, v.fileTypeLocal)
              ]
            ]);
}

function publishRow_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var jobId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "jobId"), null));
  if (jobId.TAG === /* Ok */0) {
    var fileType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "fileType"), null));
    if (fileType.TAG === /* Ok */0) {
      var start = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "start"), null));
      if (start.TAG === /* Ok */0) {
        var end = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "end"), null));
        if (end.TAG === /* Ok */0) {
          var batchResult = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "batchResult"), null));
          if (batchResult.TAG === /* Ok */0) {
            var direction = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "direction"), null));
            if (direction.TAG === /* Ok */0) {
              var application = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "application"), null));
              if (application.TAG === /* Ok */0) {
                var fileTypeLocal = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "fileTypeLocal"), null));
                if (fileTypeLocal.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: {
                            jobId: jobId._0,
                            fileType: fileType._0,
                            start: start._0,
                            end: end._0,
                            batchResult: batchResult._0,
                            direction: direction._0,
                            application: application._0,
                            fileTypeLocal: fileTypeLocal._0
                          }
                        };
                }
                var e = fileTypeLocal._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".fileTypeLocal" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = application._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".application" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = direction._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".direction" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = batchResult._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".batchResult" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = end._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".end" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = start._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".start" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = fileType._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".fileType" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = jobId._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".jobId" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(publishRow_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(publishRow_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var PublishTable = {
  publishRow_encode: publishRow_encode,
  publishRow_decode: publishRow_decode,
  searchResult_encode: searchResult_encode,
  searchResult_decode: searchResult_decode
};

function publishStatus_encode(v) {
  return Js_dict.fromArray([
              [
                "jobName",
                Decco.optionToJson(Decco.stringToJson, v.jobName)
              ],
              [
                "jobStatus",
                Decco.optionToJson(Decco.stringToJson, v.jobStatus)
              ]
            ]);
}

function publishStatus_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var jobName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "jobName"), null));
  if (jobName.TAG === /* Ok */0) {
    var jobStatus = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "jobStatus"), null));
    if (jobStatus.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                jobName: jobName._0,
                jobStatus: jobStatus._0
              }
            };
    }
    var e = jobStatus._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".jobStatus" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = jobName._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".jobName" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function publishStatusList_encode(v) {
  return Decco.listToJson(publishStatus_encode, v);
}

function publishStatusList_decode(v) {
  return Decco.listFromJson(publishStatus_decode, v);
}

export {
  Fields ,
  PublishTable ,
  publishStatus_encode ,
  publishStatus_decode ,
  publishStatusList_encode ,
  publishStatusList_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
