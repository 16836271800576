// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../../../../../../common/App_Api.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../../../../../../common/components/App_Checkbox.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Flash_Edit_Version_Perf_New_Forms$DvmAdminFrontendFr from "./Flash_Edit_Version_Perf_New_Forms.bs.js";

function Flash_Edit_Version_Perf_New_Selection_Filter(Props) {
  var allOptions = Props.allOptions;
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var setFilteredOptions = Props.setFilteredOptions;
  var withClosedModels = Props.withClosedModels;
  var setWithClosedModels = Props.setWithClosedModels;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelVersions = match[1];
  var modelVersions = match[0];
  var match$1 = React.useState(function () {
        return /* [] */0;
      });
  var setVersions = match$1[1];
  var match$2 = React.useState(function () {
        return /* [] */0;
      });
  var setCategories = match$2[1];
  var match$3 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$4 = match$3[0];
  var userManager = match$4.userManager;
  var selectedRole = match$4.selectedRole;
  var form = Flash_Edit_Version_Perf_New_Forms$DvmAdminFrontendFr.TableFilterForm.useForm(Flash_Edit_Version_Perf_New_Forms$DvmAdminFrontendFr.initialState, (function (param, submissionCallbacks) {
          Curry._2(fetchData, submissionCallbacks, {
                page: 0,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: fetchRequest.headers,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                              hd: param.model,
                              tl: /* [] */0
                            }, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchModelVersions(setModelVersions, userManager, selectedRole, withClosedModels, undefined, undefined);
        }), [withClosedModels]);
  React.useEffect((function () {
          var match = form.input.model;
          if (match !== "") {
            Curry._1(form.submit, undefined);
          }
          
        }), [
        form.input.model,
        withClosedModels
      ]);
  React.useEffect((function () {
          Curry._1(setVersions, (function (param) {
                  return Belt_List.map(allOptions, (function (x) {
                                return x.version;
                              }));
                }));
          Curry._2(form.updateVersion, (function (input, version) {
                  return {
                          model: input.model,
                          category: input.category,
                          version: version,
                          versionName: input.versionName
                        };
                }), "");
          Curry._1(setCategories, (function (param) {
                  var options = Belt_List.map(Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.keepMap(Belt_List.map(allOptions, (function (x) {
                                              return x.categoryID;
                                            })), (function (x) {
                                          return x;
                                        }))))), (function (x) {
                          return {
                                  label: {
                                    TAG: /* String */3,
                                    _0: x
                                  },
                                  value: x
                                };
                        }));
                  var model = form.input.model;
                  if (model === "") {
                    return options;
                  } else {
                    return Belt_List.add(options, {
                                label: {
                                  TAG: /* String */3,
                                  _0: model
                                },
                                value: "NULL"
                              });
                  }
                }));
          Curry._2(form.updateCategory, (function (input, category) {
                  return {
                          model: input.model,
                          category: category,
                          version: input.version,
                          versionName: input.versionName
                        };
                }), "");
          Curry._1(setFilteredOptions, (function (param) {
                  return allOptions;
                }));
        }), [allOptions]);
  React.useEffect((function () {
          Curry._1(setFilteredOptions, (function (param) {
                  return Belt_List.keep(Belt_List.keep(Belt_List.keep(allOptions, (function (x) {
                                        if (form.input.version === "") {
                                          return true;
                                        } else {
                                          return x.version === form.input.version;
                                        }
                                      })), (function (x) {
                                    return Belt_Option.mapWithDefault(x.versionName, false, (function (x) {
                                                  if (form.input.versionName === "") {
                                                    return true;
                                                  } else {
                                                    return x.toLowerCase().includes(form.input.versionName.toLowerCase());
                                                  }
                                                }));
                                  })), (function (x) {
                                var match = form.input.category;
                                switch (match) {
                                  case "" :
                                      return true;
                                  case "NULL" :
                                      return Belt_Option.isNone(x.categoryID);
                                  default:
                                    return Belt_Option.mapWithDefault(x.categoryID, false, (function (x) {
                                                  return x === form.input.category;
                                                }));
                                }
                              }));
                }));
        }), [
        form.input.category,
        form.input.version,
        form.input.versionName
      ]);
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.model,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.model,
        onChange: Curry._1(form.updateModel, (function (input, model) {
                return {
                        model: model,
                        category: input.category,
                        version: input.version,
                        versionName: input.versionName
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(modelVersions, /* [] */0), (function (x) {
              return x.parent;
            }))
    },
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.modelResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.modelResult);
  }
  var tmp$1 = {
    label: Messages_Common$DvmAdminFrontendFr.category,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.category,
        onChange: Curry._1(form.updateCategory, (function (input, category) {
                return {
                        model: input.model,
                        category: category,
                        version: input.version,
                        versionName: input.versionName
                      };
              }))
      }
    },
    options: {
      TAG: /* Labeled */1,
      _0: match$2[0]
    },
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.categoryResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.categoryResult);
  }
  var tmp$2 = {
    label: Messages_Common$DvmAdminFrontendFr.version,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.version,
        onChange: Curry._1(form.updateVersion, (function (input, version) {
                return {
                        model: input.model,
                        category: input.category,
                        version: version,
                        versionName: input.versionName
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: match$1[0]
    },
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.versionResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.versionResult);
  }
  var tmp$3 = {
    onChange: Curry._1(form.updateVersionName, (function (input, versionName) {
            return {
                    model: input.model,
                    category: input.category,
                    version: input.version,
                    versionName: versionName
                  };
          })),
    value: form.input.versionName,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Flash_Edit$DvmAdminFrontendFr.versionLabelCommercial
  };
  if (form.versionNameResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.versionNameResult);
  }
  return React.createElement(React.Fragment, undefined, React.createElement("form", undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                      style: {
                        fontWeight: "600"
                      },
                      children: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.selectModel),
                      variant: "bodyBig"
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                            show: App_Types_Result$DvmAdminFrontendFr.isPending(modelVersions),
                            children: React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  direction: "column",
                                  item: true,
                                  style: {
                                    width: "fit-content"
                                  }
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(Core.FormControlLabel, {
                                            classes: {
                                              label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                            },
                                            control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                                  checked: withClosedModels,
                                                  onClick: (function ($$event) {
                                                      Curry._1(setWithClosedModels, (function (param) {
                                                              return $$event.target.checked;
                                                            }));
                                                    })
                                                }),
                                            label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.includeClosedCars)
                                          }),
                                      item: true,
                                      style: {
                                        whiteSpace: "nowrap",
                                        width: "1px"
                                      }
                                    }))
                          }),
                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                      container: true
                    }), React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                      style: {
                        fontWeight: "600"
                      },
                      children: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.selectVersions),
                      variant: "bodyBig"
                    }), React.createElement(Core.Grid, {
                      children: null,
                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                      container: true
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$1),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$2),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$3),
                          item: true
                        }))));
}

var InputStyles;

var Form;

var Api;

var Types;

var make = Flash_Edit_Version_Perf_New_Selection_Filter;

export {
  InputStyles ,
  Form ,
  Api ,
  Types ,
  make ,
}
/* react Not a pure module */
