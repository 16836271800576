// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../../theme/Theme_Colors.bs.js";
import * as App_Table_Styles$DvmAdminFrontendFr from "../../../../../../common/components/table/App_Table_Styles.bs.js";

function Flash_Edit_NonFleet_New_Vehicle_Version_Excel_Preview(Props) {
  var excelTable = Props.excelTable;
  var selectedColumn = Props.selectedColumn;
  var selectedVersionsNotFound = Props.selectedVersionsNotFound;
  var isCellSelected = function (index) {
    return Belt_Option.mapWithDefault(selectedColumn, false, (function (selected) {
                  return Belt_Option.mapWithDefault(Belt_Int.fromString(selected), false, (function (number) {
                                return number === index;
                              }));
                }));
  };
  var isCellNotFound = function (cell) {
    return Belt_List.has(selectedVersionsNotFound, Belt_Option.getWithDefault(Js_json.decodeString(cell), "undecoded"), (function (a, b) {
                  return a === b;
                }));
  };
  return React.createElement(Core.Grid, {
              children: React.createElement(Core.Grid, {
                    children: React.createElement(Core.TableContainer, {
                          children: React.createElement(Core.Table, {
                                children: React.createElement(Core.TableBody, {
                                      children: Belt_Array.mapWithIndex(excelTable, (function (rowIndex, row) {
                                              return React.createElement(Core.TableRow, {
                                                          children: Belt_Array.mapWithIndex(row, (function (cellIndex, cell) {
                                                                  var tmp = {
                                                                    children: React.createElement("div", {
                                                                          className: App_Table_Styles$DvmAdminFrontendFr.tableCellContent
                                                                        }, Belt_Option.getWithDefault(JSON.stringify(cell), "")),
                                                                    className: App_Table_Styles$DvmAdminFrontendFr.tableCell(undefined, undefined),
                                                                    key: "cell" + String(rowIndex) + String(cellIndex)
                                                                  };
                                                                  var tmp$1 = isCellSelected(cellIndex) ? ({
                                                                        backgroundColor: isCellNotFound(cell) ? Theme_Colors$DvmAdminFrontendFr.accentRed : Theme_Colors$DvmAdminFrontendFr.primaryBrown2
                                                                      }) : undefined;
                                                                  if (tmp$1 !== undefined) {
                                                                    tmp.style = Caml_option.valFromOption(tmp$1);
                                                                  }
                                                                  return React.createElement(Core.TableCell, tmp);
                                                                })),
                                                          key: "row" + String(rowIndex)
                                                        });
                                            }))
                                    }),
                                stickyHeader: true
                              }),
                          className: App_Table_Styles$DvmAdminFrontendFr.table
                        }),
                    item: true
                  }),
              container: true,
              direction: "column",
              item: true,
              spacing: 1,
              style: {
                maxHeight: "50vh",
                overflow: "auto"
              }
            });
}

var Types;

var Styles;

var make = Flash_Edit_NonFleet_New_Vehicle_Version_Excel_Preview;

export {
  Types ,
  Styles ,
  make ,
}
/* react Not a pure module */
