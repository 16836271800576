// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Api.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as Flash_Detail_Api$DvmAdminFrontendFr from "../../Flash_Detail_Api.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table_Pagination.bs.js";
import * as Flash_Detail_Table_Types$DvmAdminFrontendFr from "../Flash_Detail_Table_Types.bs.js";
import * as Flash_Detail_Models_Table_Fleet$DvmAdminFrontendFr from "./Flash_Detail_Models_Table_Fleet.bs.js";
import * as Flash_Detail_Models_Filter_Fleet$DvmAdminFrontendFr from "./Flash_Detail_Models_Filter_Fleet.bs.js";

function Flash_Detail_Models_Page_Fleet(Props) {
  var flashId = Props.flashId;
  var flash = Props.flash;
  var flashType = Props.flashType;
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match$2[1];
  var match$3 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: Caml_array.get(App_Table_Pagination$DvmAdminFrontendFr.options, 0),
                headers: flash.actionType === "PERF" ? Flash_Detail_Models_Table_Fleet$DvmAdminFrontendFr.headersConfigurationPerf(selectedRole) : Flash_Detail_Models_Table_Fleet$DvmAdminFrontendFr.headersConfiguration,
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$3[1];
  var fetchRequest = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var withClosedModels = match$4[0];
  var fetchData = function (submissionCallbacks, fetchRequest) {
    if (flash.actionType === "PERF") {
      Flash_Detail_Api$DvmAdminFrontendFr.fetchModelsTablePerf(submissionCallbacks, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                page: fetchRequest.page,
                rowsPerPage: 10,
                headers: fetchRequest.headers,
                filterQueryParams: fetchRequest.filterQueryParams,
                additionalQueryParams: fetchRequest.additionalQueryParams
              }), flash.actionId, selectedRole, withClosedModels);
    } else {
      var filters = fetchRequest.filterQueryParams;
      Flash_Detail_Api$DvmAdminFrontendFr.fetchModelsTable(submissionCallbacks, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                page: fetchRequest.page,
                rowsPerPage: 10,
                headers: fetchRequest.headers,
                filterQueryParams: Flash_Detail_Table_Types$DvmAdminFrontendFr.Fields.actionId + "=" + flashId + (
                  filters.length > 0 ? ";" + filters : ""
                ),
                additionalQueryParams: fetchRequest.additionalQueryParams
              }), selectedRole, withClosedModels, flashType);
    }
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  React.useEffect((function () {
          fetchData(undefined, fetchRequest);
        }), []);
  return React.createElement(React.Fragment, undefined, React.createElement(Flash_Detail_Models_Filter_Fleet$DvmAdminFrontendFr.make, {
                  flash: flash,
                  fetchData: fetchData,
                  fetchRequest: fetchRequest,
                  withClosedModels: withClosedModels,
                  setWithClosedModels: match$4[1]
                }), React.createElement(Flash_Detail_Models_Table_Fleet$DvmAdminFrontendFr.make, {
                  tablePage: match$2[0],
                  fetchRequest: fetchRequest,
                  fetchData: fetchData,
                  flash: flash
                }));
}

var Api;

var Table;

var Filter;

var Types;

var make = Flash_Detail_Models_Page_Fleet;

export {
  Api ,
  Table ,
  Filter ,
  Types ,
  make ,
}
/* react Not a pure module */
