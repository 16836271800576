// Generated by ReScript, PLEASE EDIT WITH CARE


var title = {
  id: "4f03fbf3-74b7-4a26-a3e9-b6808f20ab0a",
  defaultMessage: "Paramétrage marques VO"
};

var brandCode = {
  id: "29001b17-cc11-4178-ad5e-f10239699d15",
  defaultMessage: "Code Marque"
};

var brandLabel = {
  id: "aec88902-5bc5-4048-ab8b-ea51ac3b778c",
  defaultMessage: "Libellé marque"
};

var createBrandCode = {
  id: "db01de4e-f560-4777-8dba-77b656d1d649",
  defaultMessage: "Création code marque"
};

var resultInfo = {
  id: "02ab595f-84cf-43e1-8044-faae4315126e",
  defaultMessage: "marques"
};

export {
  title ,
  brandCode ,
  brandLabel ,
  createBrandCode ,
  resultInfo ,
}
/* No side effect */
