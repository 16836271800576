// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as App_Modal$DvmAdminFrontendFr from "../../../common/components/App_Modal.bs.js";
import * as Constants$DvmAdminFrontendFr from "../../../common/Constants.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as Home_QuickAccess_CheckBox$DvmAdminFrontendFr from "./Home_QuickAccess_CheckBox.bs.js";
import * as Messages_Home_QuickAccess$DvmAdminFrontendFr from "../../../intl/messages/home/Messages_Home_QuickAccess.bs.js";

var checkBoxStyle = Css.css({
      height: "70px",
      padding: "10px !important",
      width: "250px"
    });

var selectedStyle = Css.css({
      color: Theme_Colors$DvmAdminFrontendFr.grayDark1
    });

function Home_QuickAccess_Modal(Props) {
  var onClose = Props.onClose;
  var onOk = Props.onOk;
  var initial = Props.initial;
  var options = Props.options;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return initial;
      });
  var setSelected = match[1];
  var selected = match[0];
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Home_QuickAccess$DvmAdminFrontendFr.quickAccessTitle),
              children: null
            }, React.createElement(Core.Box, {
                  children: intl.formatMessage(Messages_Home_QuickAccess$DvmAdminFrontendFr.quickAccessHint, {
                        maxCount: Constants$DvmAdminFrontendFr.quickAccessMaxCount
                      }),
                  mb: 3
                }), React.createElement(Core.Box, {
                  className: selectedStyle,
                  children: intl.formatMessage(Messages_Home_QuickAccess$DvmAdminFrontendFr.quickAccessSelect, {
                        maxCount: Constants$DvmAdminFrontendFr.quickAccessMaxCount,
                        count: selected.length
                      }),
                  mb: 3
                }), React.createElement(Core.Grid, {
                  children: Belt_Array.mapWithIndex(options, (function (_index, element) {
                          var match = element.location;
                          return React.createElement(Core.Grid, {
                                      children: React.createElement(Home_QuickAccess_CheckBox$DvmAdminFrontendFr.make, {
                                            icon: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                  size: /* Large */3,
                                                  type_: match ? /* Gear */39 : /* Show */59
                                                }),
                                            label: element.label,
                                            checked: Belt_Array.some(selected, (function (i) {
                                                    return i === element.uuid;
                                                  })),
                                            onClick: (function ($$event) {
                                                var id = $$event.target.id;
                                                if (!$$event.target.checked) {
                                                  return Curry._1(setSelected, (function (values) {
                                                                return values.filter(function (item) {
                                                                            return item !== id;
                                                                          });
                                                              }));
                                                }
                                                var newArray = selected.slice(0);
                                                newArray.push(id);
                                                Curry._1(setSelected, (function (param) {
                                                        return newArray;
                                                      }));
                                              }),
                                            disabled: selected.length >= Constants$DvmAdminFrontendFr.quickAccessMaxCount && !Belt_Array.some(selected, (function (i) {
                                                    return i === element.uuid;
                                                  })),
                                            className: checkBoxStyle,
                                            id: element.uuid,
                                            key: element.uuid
                                          }),
                                      item: true
                                    });
                        })),
                  container: true,
                  spacing: 3
                }), React.createElement(Core.Box, {
                  children: null,
                  display: "flex"
                }, React.createElement(Core.Box, {
                      flexGrow: 1
                    }), React.createElement(App_Button$DvmAdminFrontendFr.make, {
                      onClick: (function (param) {
                          Curry._1(setSelected, (function (param) {
                                  return initial;
                                }));
                        }),
                      label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.restart)
                    }), React.createElement(Core.Box, {
                      children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                            onClick: (function (param) {
                                Curry._1(onOk, selected);
                              }),
                            label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm)
                          }),
                      ml: 3
                    })));
}

var make = Home_QuickAccess_Modal;

export {
  checkBoxStyle ,
  selectedStyle ,
  make ,
}
/* checkBoxStyle Not a pure module */
