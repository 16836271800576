// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_AutocompleteFormInput.bs.js";

function Grid_Edit_New_Row_Inputs_Model(Props) {
  var whatGrid = Props.whatGrid;
  var form = Props.form;
  var budgetOrID = Props.budgetOrID;
  var modelVersionsList = Props.modelVersionsList;
  var wrapWithClosedModelsCheckboxElement = Props.wrapWithClosedModelsCheckboxElement;
  var intl = ReactIntl.useIntl();
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.model,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.model,
        onChange: Curry._1(form.updateModel, (function (input, model) {
                return {
                        model: model,
                        version: input.version,
                        categoryCode: input.categoryCode,
                        discount: input.discount,
                        margin: input.margin,
                        startDate: input.startDate,
                        endDate: input.endDate,
                        acquisitionType: input.acquisitionType,
                        hasExceptions: input.hasExceptions,
                        categoryStartDate: input.categoryStartDate
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: Belt_List.map(modelVersionsList, (function (x) {
              return x.parent;
            }))
    },
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.modelResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.modelResult);
  }
  return React.createElement(React.Fragment, undefined, whatGrid ? React.createElement(Core.Grid, {
                    children: "" + intl.formatMessage(Messages_Common$DvmAdminFrontendFr.budget) + ": " + budgetOrID + "",
                    item: true
                  }) : null, Curry._1(wrapWithClosedModelsCheckboxElement, React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp)));
}

var Types;

var Form;

var InputStyles;

var make = Grid_Edit_New_Row_Inputs_Model;

export {
  Types ,
  Form ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
