// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../../../api/RestApi.bs.js";
import * as Quota_View_Exclusion_Types$DvmAdminFrontendFr from "../../Quota_View_Exclusion_Types.bs.js";

function saveQuotaExclusion(setResult, userManager, submissionCallbacks, tableType, oldExclusion, selectedRole, newExclusion) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", {
          TAG: /* Json */0,
          _0: Quota_View_Exclusion_Types$DvmAdminFrontendFr.Exclusion.exclusionRow_encode(oldExclusion)
        }, userManager, selectedRole, "/quotas/exclusions/" + (
          tableType === /* Model */0 ? "model" : "modelversion"
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
                  TAG: /* Json */0,
                  _0: Quota_View_Exclusion_Types$DvmAdminFrontendFr.Exclusion.exclusionsSaveRequest_encode({
                        hd: newExclusion,
                        tl: /* [] */0
                      })
                }, userManager, selectedRole, "/quotas/exclusions/" + (
                  tableType === /* Model */0 ? "model" : "modelversion"
                ) + "").then(function (result) {
                if (result.TAG === /* OkResult */0) {
                  Belt_Option.forEach(submissionCallbacks, (function (param) {
                          Curry._1(param.notifyOnSuccess, undefined);
                        }));
                  return Curry._1(setResult, (function (param) {
                                return {
                                        TAG: /* Ok */0,
                                        _0: undefined
                                      };
                              }));
                }
                var error = result._0;
                Belt_Option.forEach(submissionCallbacks, (function (param) {
                        Curry._1(param.notifyOnFailure, /* FailedToQuotaVersionUpdate */0);
                      }));
                Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Error */1,
                                _0: error
                              };
                      }));
              });
          return ;
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function deleteVersion(userManager, setResult, tableType, selectedRole, exclusion) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", {
          TAG: /* Json */0,
          _0: Quota_View_Exclusion_Types$DvmAdminFrontendFr.Exclusion.exclusionRow_encode(exclusion)
        }, userManager, selectedRole, "/quotas/exclusions/" + (
          tableType === /* Model */0 ? "model" : "modelversion"
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

export {
  Form ,
  saveQuotaExclusion ,
  deleteVersion ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
