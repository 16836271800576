// Generated by ReScript, PLEASE EDIT WITH CARE


var title = {
  id: "458b5baa-30e7-4e82-ba28-b7ff4887ea21",
  defaultMessage: "Interroger une grille d’aide budget"
};

var beforeResult = {
  id: "f826d1b0-6571-451d-ac3a-5b4839d5339c",
  defaultMessage: "Indiquer au moins le budget et l’Code Loueur/Prot pour afficher des résultats*"
};

var acquisitionType = {
  id: "2174ab0c-bfb4-4019-81ae-856d739df8f1",
  defaultMessage: "Type Acq."
};

var versionException = {
  id: "163989e7-9f08-4c83-be6b-cd2e2cecc11b",
  defaultMessage: "Exception Version"
};

var orderID = {
  id: "6dd60ef5-a7a8-4daf-b86c-316ffd6b02eb",
  defaultMessage: "Code Loueur/Prot"
};

var orderLabel = {
  id: "a45835ed-1855-4da0-abc3-040f4e21befa",
  defaultMessage: "Libellé Loueur/Prot"
};

var labelContract = {
  id: "699ab845-2436-466a-8f8b-ad2dfca46314",
  defaultMessage: "Libellé Loueur/Prot"
};

export {
  title ,
  beforeResult ,
  acquisitionType ,
  versionException ,
  orderID ,
  orderLabel ,
  labelContract ,
}
/* No side effect */
