// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Messages_Visibility_Profiles$DvmAdminFrontendFr from "../../../intl/messages/visibilityProfiles/Messages_Visibility_Profiles.bs.js";

function Visibility_Profiles_Filter(Props) {
  var ipn = Props.ipn;
  var setIpn = Props.setIpn;
  var dealers = Props.dealers;
  var setDealers = Props.setDealers;
  var visibilityProfiles = Props.visibilityProfiles;
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              item: true,
              spacing: 2
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                        label: Messages_Visibility_Profiles$DvmAdminFrontendFr.ipn,
                        value: {
                          TAG: /* Multiple */1,
                          _0: {
                            value: ipn,
                            onChange: (function (values) {
                                Curry._1(setIpn, (function (param) {
                                        return values;
                                      }));
                              })
                          }
                        },
                        options: {
                          TAG: /* Unlabeled */0,
                          _0: Belt_List.map(visibilityProfiles, (function (x) {
                                  return x.userID;
                                }))
                        },
                        limitTags: 3,
                        smallChips: true
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                        label: Messages_Visibility_Profiles$DvmAdminFrontendFr.listHeaderDealers,
                        value: {
                          TAG: /* Multiple */1,
                          _0: {
                            value: dealers,
                            onChange: (function (values) {
                                Curry._1(setDealers, (function (param) {
                                        return values;
                                      }));
                              })
                          }
                        },
                        options: {
                          TAG: /* Unlabeled */0,
                          _0: Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(Belt_List.flatten(Belt_List.map(visibilityProfiles, (function (x) {
                                                      return x.dealerCodes;
                                                    }))), (function (x) {
                                              return Belt_Option.getWithDefault(x.dealerID, "");
                                            })))))
                        },
                        limitTags: 3,
                        smallChips: true
                      }),
                  item: true
                }));
}

var Types;

var make = Visibility_Profiles_Filter;

export {
  Types ,
  make ,
}
/* react Not a pure module */
