// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.bs.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../../../common/App_Api.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../../../../common/components/icons/App_Icon.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../common/components/button/App_Button.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../common/components/App_OverlaySpinner.bs.js";
import * as App_RadioFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_RadioFormInput.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Flash_Edit_Accordion$DvmAdminFrontendFr from "../Flash_Edit_Accordion.bs.js";
import * as Messages_Dealer_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/dealer/Messages_Dealer_Detail.bs.js";
import * as Flash_Edit_NonFleet_Api$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_Api.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as App_TransferListFormInput_Virtualized$DvmAdminFrontendFr from "../../../../../common/components/input/App_TransferListFormInput_Virtualized.bs.js";

function makeDealerOption(x) {
  return {
          label: {
            TAG: /* String */3,
            _0: x.dealerId + Belt_Option.mapWithDefault(x.locationCommonName, "", (function (name) {
                    if (name.length > 0) {
                      return " - " + name + "";
                    } else {
                      return "";
                    }
                  }))
          },
          value: x.dealerId
        };
}

function makeAgentOptions(x, franchisedDealers, filterFranchised) {
  return Belt_List.map(Belt_List.keep(x.child, (function (x$1) {
                    var agent = x$1.paramOne;
                    switch (filterFranchised) {
                      case /* Yes */0 :
                          return Belt_Option.mapWithDefault(Belt_List.getBy(App_Types_Result$DvmAdminFrontendFr.getWithDefault(franchisedDealers, /* [] */0), (function (listItem) {
                                            return listItem.parent === x.parent;
                                          })), false, (function (franchised) {
                                        return Belt_List.has(franchised.child, agent, (function (a, b) {
                                                      return a === b;
                                                    }));
                                      }));
                      case /* No */1 :
                          return !Belt_Option.mapWithDefault(Belt_List.getBy(App_Types_Result$DvmAdminFrontendFr.getWithDefault(franchisedDealers, /* [] */0), (function (listItem) {
                                            return listItem.parent === x.parent;
                                          })), false, (function (franchised) {
                                        return Belt_List.has(franchised.child, agent, (function (a, b) {
                                                      return a === b;
                                                    }));
                                      }));
                      case /* All */2 :
                          return true;
                      
                    }
                  })), (function (child) {
                return {
                        label: {
                          TAG: /* String */3,
                          _0: x.parent + " - " + child.paramOne + " - " + Belt_Option.getWithDefault(child.paramTwo, "")
                        },
                        value: x.parent + ":::" + child.paramOne
                      };
              }));
}

function makeAgentsList(x, form, franchisedDealers, filterFranchised) {
  return Belt_List.concatMany(Belt_List.toArray(Belt_List.map(Belt_List.keep(x, (function (x) {
                            return Belt_Option.isSome(Belt_List.getBy(form.input.dealerIds, (function (selectedDealer) {
                                              return selectedDealer.value === x.parent;
                                            })));
                          })), (function (x) {
                        return makeAgentOptions(x, franchisedDealers, filterFranchised);
                      }))));
}

function labelForAgents(selectedAgents, agents) {
  var listResultAgents = App_Types_Result$DvmAdminFrontendFr.getWithDefault(agents, /* [] */0);
  return Belt_List.map(selectedAgents, (function (agent) {
                var dealer = Belt_Option.getWithDefault(Belt_Array.get(agent.value.split(":::"), 0), "");
                var agentStr = Belt_Option.getWithDefault(Belt_Array.get(agent.value.split(":::"), 1), "");
                var foundAgent = Belt_Option.mapWithDefault(Belt_List.getBy(listResultAgents, (function (x) {
                            return x.parent === dealer;
                          })), undefined, (function (x) {
                        return Belt_List.getBy(x.child, (function (x) {
                                      return x.paramOne === agentStr;
                                    }));
                      }));
                return Belt_Option.mapWithDefault(foundAgent, agent, (function (x) {
                              return {
                                      label: {
                                        TAG: /* String */3,
                                        _0: dealer + " - " + x.paramOne + " - " + Belt_Option.getWithDefault(x.paramTwo, "")
                                      },
                                      value: agent.value
                                    };
                            }));
              }));
}

function Flash_Edit_Details_NonFleet_Dealer(Props) {
  var form = Props.form;
  var setDetailREPRExpanded = Props.setDetailREPRExpanded;
  var nonFleetDealerExpanded = Props.nonFleetDealerExpanded;
  var setNonFleetDealerExpanded = Props.setNonFleetDealerExpanded;
  var step = Props.step;
  var setStep = Props.setStep;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* [] */0;
      });
  var setDrs = match$2[1];
  var drs = match$2[0];
  var match$3 = React.useState(function () {
        return /* [] */0;
      });
  var setFilteredDealerGroups = match$3[1];
  var filteredDealerGroups = match$3[0];
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var setFilteredRegionalNames = match$4[1];
  var filteredRegionalNames = match$4[0];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDealers = match$5[1];
  var dealers = match$5[0];
  var match$6 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDrOptions = match$6[1];
  var drOptions = match$6[0];
  var match$7 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setAgents = match$7[1];
  var agents = match$7[0];
  var match$8 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setFranchisedDealers = match$8[1];
  var franchisedDealers = match$8[0];
  var match$9 = React.useState(function () {
        return /* All */2;
      });
  var setFilterFranchised = match$9[1];
  var filterFranchised = match$9[0];
  var debounce = ReactDebounce.useDebounced(500, (function (param) {
          App_Api$DvmAdminFrontendFr.queryForDealerNames(alert, userManager, setDealers, selectedRole, true, param[0], /* [] */0, param[1], param[2]);
        }));
  var match$10 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDealerGroups = match$10[1];
  var dealerGroups = match$10[0];
  var match$11 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setRegionalNames = match$11[1];
  var regionalNames = match$11[0];
  var match$12 = React.useState(function () {
        return /* [] */0;
      });
  var setAllDealers = match$12[1];
  var allDealers = match$12[0];
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.queryForDrSelect(alert, userManager, setDrOptions, selectedRole);
          App_Api$DvmAdminFrontendFr.fetchDealerGroups(userManager, setDealerGroups, alert, selectedRole, /* [] */0);
          App_Api$DvmAdminFrontendFr.fetchDealerRegionalName(userManager, setRegionalNames, alert, selectedRole);
          App_Api$DvmAdminFrontendFr.fetchDealersAndAgents(userManager, setAgents, selectedRole, /* [] */0);
          Flash_Edit_NonFleet_Api$DvmAdminFrontendFr.fetchFranchizedDealers(userManager, setFranchisedDealers, selectedRole);
        }), []);
  React.useEffect((function () {
          Curry._1(debounce, [
                drs,
                filteredDealerGroups,
                filteredRegionalNames
              ]);
        }), [
        drs,
        filteredDealerGroups,
        filteredRegionalNames
      ]);
  React.useEffect((function () {
          if (Belt_List.length(allDealers) > 0) {
            
          } else {
            App_Types_Result$DvmAdminFrontendFr.mapWithDefault(dealers, undefined, (function (x) {
                    Curry._1(setAllDealers, (function (param) {
                            return x;
                          }));
                  }));
          }
        }), [dealers]);
  React.useEffect((function () {
          if (Belt_List.length(form.input.dealerIds) === 0) {
            Curry._2(form.updateDealerWithAgents, (function (input, dealerWithAgents) {
                    var newrecord = Caml_obj.obj_dup(input);
                    newrecord.dealerWithAgents = dealerWithAgents;
                    return newrecord;
                  }), /* [] */0);
          } else {
            Curry._2(form.updateDealerWithAgents, (function (input, dealerWithAgents) {
                    var newrecord = Caml_obj.obj_dup(input);
                    newrecord.dealerWithAgents = dealerWithAgents;
                    return newrecord;
                  }), Belt_List.keep(form.input.dealerWithAgents, (function (agent) {
                        var dealerIdOnAgent = Belt_Option.getWithDefault(Belt_Array.get(agent.value.split(":::"), 0), "");
                        return Belt_List.has(form.input.dealerIds, dealerIdOnAgent, (function (a, b) {
                                      return a.value === b;
                                    }));
                      })));
          }
        }), [form.input.dealerIds]);
  var tmp;
  switch (filterFranchised) {
    case /* Yes */0 :
        tmp = "YES";
        break;
    case /* No */1 :
        tmp = "NO";
        break;
    case /* All */2 :
        tmp = "ALL";
        break;
    
  }
  var tmp$1 = {
    values: Belt_List.map(form.input.dealerIds, (function (selected) {
            return Belt_Option.mapWithDefault(Belt_List.getBy(allDealers, (function (options) {
                              return selected.value === options.dealerId;
                            })), selected, makeDealerOption);
          })),
    options: Belt_List.keep(App_Types_Result$DvmAdminFrontendFr.mapWithDefault(dealers, /* [] */0, (function (dealers) {
                return Belt_List.map(dealers, makeDealerOption);
              })), (function (x) {
            switch (filterFranchised) {
              case /* Yes */0 :
                  return Belt_List.has(Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(franchisedDealers, /* [] */0), (function (x) {
                                    return x.parent;
                                  })), x.value, (function (a, b) {
                                return a === b;
                              }));
              case /* No */1 :
                  return !Belt_List.has(Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(franchisedDealers, /* [] */0), (function (x) {
                                    return x.parent;
                                  })), x.value, (function (a, b) {
                                return a === b;
                              }));
              case /* All */2 :
                  return true;
              
            }
          })),
    onChange: Curry._1(form.updateDealerIds, (function (input, dealerIds) {
            var newrecord = Caml_obj.obj_dup(input);
            newrecord.dealerIds = dealerIds;
            return newrecord;
          })),
    boxHeight: 260,
    search: true
  };
  if (form.dealerIdsResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.dealerIdsResult);
  }
  var tmp$2 = {
    values: labelForAgents(form.input.dealerWithAgents, agents),
    options: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(agents, /* [] */0, (function (agents) {
            return makeAgentsList(agents, form, franchisedDealers, filterFranchised);
          })),
    onChange: Curry._1(form.updateDealerWithAgents, (function (input, dealerWithAgents) {
            var newrecord = Caml_obj.obj_dup(input);
            newrecord.dealerWithAgents = dealerWithAgents;
            return newrecord;
          })),
    boxHeight: 260,
    search: true
  };
  if (form.dealerWithAgentsResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.dealerWithAgentsResult);
  }
  return React.createElement(Core.Grid, {
              children: React.createElement(Flash_Edit_Accordion$DvmAdminFrontendFr.make, {
                    title: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.dealerAccordionTitle),
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          direction: "column",
                          spacing: 4
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                    style: {
                                      fontWeight: "600"
                                    },
                                    children: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.choiceOfDealers),
                                    variant: "bodyBig"
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(Core.Box, {
                                    children: React.createElement(Core.Grid, {
                                          children: null,
                                          container: true,
                                          direction: "column",
                                          item: true,
                                          spacing: 1
                                        }, React.createElement(Core.Grid, {
                                              children: null,
                                              container: true,
                                              item: true,
                                              spacing: 1
                                            }, React.createElement(Core.Grid, {
                                                  children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                        size: /* Large */3,
                                                        type_: /* Search */49
                                                      }),
                                                  item: true
                                                }), React.createElement(Core.Grid, {
                                                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                        style: {
                                                          fontWeight: "600"
                                                        },
                                                        children: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.filters),
                                                        variant: "bodyBig"
                                                      }),
                                                  item: true
                                                })), React.createElement(Core.Grid, {
                                              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                                    show: App_Types_Result$DvmAdminFrontendFr.isPending(drOptions),
                                                    children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                                          label: Messages_Common$DvmAdminFrontendFr.territory,
                                                          value: {
                                                            TAG: /* Multiple */1,
                                                            _0: {
                                                              value: drs,
                                                              onChange: (function (val) {
                                                                  Curry._1(setDrs, (function (param) {
                                                                          return val;
                                                                        }));
                                                                })
                                                            }
                                                          },
                                                          options: {
                                                            TAG: /* Unlabeled */0,
                                                            _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(drOptions, /* [] */0)
                                                          }
                                                        })
                                                  }),
                                              item: true
                                            }), React.createElement(Core.Grid, {
                                              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                                    show: App_Types_Result$DvmAdminFrontendFr.isPending(dealerGroups),
                                                    children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                                          label: Messages_Dealer_Detail$DvmAdminFrontendFr.nameR1,
                                                          value: {
                                                            TAG: /* Multiple */1,
                                                            _0: {
                                                              value: filteredDealerGroups,
                                                              onChange: (function (val) {
                                                                  Curry._1(setFilteredDealerGroups, (function (param) {
                                                                          return val;
                                                                        }));
                                                                })
                                                            }
                                                          },
                                                          options: {
                                                            TAG: /* Unlabeled */0,
                                                            _0: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(dealerGroups, /* [] */0, (function (x) {
                                                                    return Belt_List.keep(x, (function (x) {
                                                                                  return x.length > 0;
                                                                                }));
                                                                  }))
                                                          }
                                                        })
                                                  }),
                                              item: true
                                            }), React.createElement(Core.Grid, {
                                              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                                    show: App_Types_Result$DvmAdminFrontendFr.isPending(regionalNames),
                                                    children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                                          label: Messages_Flash_Edit$DvmAdminFrontendFr.nameR2plaque,
                                                          value: {
                                                            TAG: /* Multiple */1,
                                                            _0: {
                                                              value: filteredRegionalNames,
                                                              onChange: (function (val) {
                                                                  Curry._1(setFilteredRegionalNames, (function (param) {
                                                                          return val;
                                                                        }));
                                                                })
                                                            }
                                                          },
                                                          options: {
                                                            TAG: /* Unlabeled */0,
                                                            _0: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(regionalNames, /* [] */0, (function (x) {
                                                                    return Belt_List.keep(x, (function (x) {
                                                                                  return x.length > 0;
                                                                                }));
                                                                  }))
                                                          }
                                                        })
                                                  }),
                                              item: true
                                            }), React.createElement(Core.Grid, {
                                              children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, {
                                                    label: Messages_Flash_Edit$DvmAdminFrontendFr.franchisedSelectionTitle,
                                                    value: tmp,
                                                    options: {
                                                      hd: {
                                                        label: {
                                                          TAG: /* Message */0,
                                                          _0: Messages_Common$DvmAdminFrontendFr.yes
                                                        },
                                                        value: "YES"
                                                      },
                                                      tl: {
                                                        hd: {
                                                          label: {
                                                            TAG: /* Message */0,
                                                            _0: Messages_Common$DvmAdminFrontendFr.no
                                                          },
                                                          value: "NO"
                                                        },
                                                        tl: {
                                                          hd: {
                                                            label: {
                                                              TAG: /* Message */0,
                                                              _0: Messages_Common$DvmAdminFrontendFr.everything
                                                            },
                                                            value: "ALL"
                                                          },
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    },
                                                    onChange: (function (val) {
                                                        Curry._1(setFilterFranchised, (function (param) {
                                                                switch (val) {
                                                                  case "NO" :
                                                                      return /* No */1;
                                                                  case "YES" :
                                                                      return /* Yes */0;
                                                                  default:
                                                                    return /* All */2;
                                                                }
                                                              }));
                                                      })
                                                  }),
                                              item: true
                                            })),
                                    borderColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
                                    borderLeft: 2,
                                    paddingLeft: 1
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                    show: App_Types_Result$DvmAdminFrontendFr.isPending(dealers),
                                    children: React.createElement(Core.Grid, {
                                          children: React.createElement(App_TransferListFormInput_Virtualized$DvmAdminFrontendFr.make, tmp$1),
                                          item: true,
                                          sm: Grid$Mui.Sm[12]
                                        })
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                    style: {
                                      fontWeight: "600"
                                    },
                                    children: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.choiceOfAgents),
                                    variant: "bodyBig"
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                    show: App_Types_Result$DvmAdminFrontendFr.isPending(dealers),
                                    children: React.createElement(Core.Grid, {
                                          children: React.createElement(App_TransferListFormInput_Virtualized$DvmAdminFrontendFr.make, tmp$2),
                                          item: true,
                                          sm: Grid$Mui.Sm[12]
                                        })
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: step === 1 ? React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                      onClick: (function (param) {
                                          Curry._1(setStep, (function (step) {
                                                  return step + 1 | 0;
                                                }));
                                          Curry._1(setNonFleetDealerExpanded, (function (param) {
                                                  return false;
                                                }));
                                          Curry._1(setDetailREPRExpanded, (function (param) {
                                                  return true;
                                                }));
                                        }),
                                      label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.next),
                                      size: "medium"
                                    }) : null,
                              container: true,
                              item: true,
                              justifyContent: "center"
                            })),
                    expanded: nonFleetDealerExpanded,
                    setter: setNonFleetDealerExpanded,
                    disabled: step < 1
                  }),
              item: true,
              sm: Grid$Mui.Sm[12]
            });
}

var Form;

var make = Flash_Edit_Details_NonFleet_Dealer;

export {
  Form ,
  makeDealerOption ,
  makeAgentOptions ,
  makeAgentsList ,
  labelForAgents ,
  make ,
}
/* react Not a pure module */
