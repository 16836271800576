// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../../common/App_Api.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Filter_Button.bs.js";
import * as Messages_Category$DvmAdminFrontendFr from "../../../../intl/messages/category/Messages_Category.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../common/components/App_OverlaySpinner.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Category_Edit_VersionsSelection_Modal_Form$DvmAdminFrontendFr from "./Category_Edit_VersionsSelection_Modal_Form.bs.js";

var container = Css.css({
      minWidth: "940px",
      gridColumnGap: "16px"
    });

function Category_Edit_VersionsSelection_Modal_Filter(Props) {
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var formValid = Props.formValid;
  var model = Props.model;
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setVersions = match[1];
  var versions = match[0];
  var match$1 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setMotorisations = match$1[1];
  var motorisations = match$1[0];
  var match$2 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$3 = match$2[0];
  var userManager = match$3.userManager;
  var selectedRole = match$3.selectedRole;
  var form = Category_Edit_VersionsSelection_Modal_Form$DvmAdminFrontendFr.VersionsSelectionForm.useForm(Category_Edit_VersionsSelection_Modal_Form$DvmAdminFrontendFr.initialInput, (function (param, param$1) {
          Curry._1(fetchData, {
                page: 0,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: fetchRequest.headers,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                              hd: param.versionName,
                              tl: {
                                hd: param.version,
                                tl: {
                                  hd: param.motorisation,
                                  tl: /* [] */0
                                }
                              }
                            }, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
          Curry._1(param$1.notifyOnSuccess, undefined);
        }));
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchVersions(setVersions, userManager, model, selectedRole, false, undefined, undefined);
          App_Api$DvmAdminFrontendFr.fetchMotorisations(setMotorisations, userManager, selectedRole);
        }), []);
  var tmp = {
    onChange: Curry._1(form.updateVersionName, (function (input, versionName) {
            return {
                    versionName: versionName,
                    version: input.version,
                    motorisation: input.motorisation
                  };
          })),
    value: form.input.versionName,
    onBlur: (function (param) {
        Curry._1(form.blurVersionName, undefined);
      }),
    fullWidth: true,
    label: Messages_Category$DvmAdminFrontendFr.versionName
  };
  if (form.versionNameResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.versionNameResult);
  }
  var tmp$1 = {
    label: Messages_Common$DvmAdminFrontendFr.version,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.version,
        onChange: Curry._1(form.updateVersion, (function (input, version) {
                return {
                        versionName: input.versionName,
                        version: version,
                        motorisation: input.motorisation
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(App_Types_Result$DvmAdminFrontendFr.map(versions, Belt_SetString.toList), /* [] */0)
    },
    onBlur: (function (param) {
        Curry._1(form.blurVersion, undefined);
      }),
    fullWidth: true
  };
  if (form.versionResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.versionResult);
  }
  var tmp$2 = {
    label: Messages_Category$DvmAdminFrontendFr.motorisation,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.motorisation,
        onChange: Curry._1(form.updateMotorisation, (function (input, motorisation) {
                return {
                        versionName: input.versionName,
                        version: input.version,
                        motorisation: motorisation
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(motorisations, /* [] */0, (function (x) {
              return Belt_List.add(Belt_List.sort(Belt_List.keep(x, (function (x) {
                                    return x.length > 0;
                                  })), Caml.string_compare), "Rien");
            }))
    },
    onBlur: (function (param) {
        Curry._1(form.blurMotorisation, undefined);
      })
  };
  if (form.motorisationResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.motorisationResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: container,
                  container: true
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp),
                      item: true,
                      xs: Grid$Mui.Xs[3]
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                            show: App_Types_Result$DvmAdminFrontendFr.isPending(versions),
                            children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$1)
                          }),
                      item: true,
                      xs: Grid$Mui.Xs[2]
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                            show: App_Types_Result$DvmAdminFrontendFr.isPending(motorisations),
                            children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$2)
                          }),
                      item: true,
                      xs: Grid$Mui.Xs[3]
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            disabled: !formValid,
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var Form;

var InputStyles;

var make = Category_Edit_VersionsSelection_Modal_Filter;

export {
  Form ,
  InputStyles ,
  container ,
  make ,
}
/* container Not a pure module */
