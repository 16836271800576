// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as OidcClient from "oidc-client";
import * as EnvVar$DvmAdminFrontendFr from "../../bindings/global/EnvVar.bs.js";
import * as Constants$DvmAdminFrontendFr from "../Constants.bs.js";
import * as OidcClient$DvmAdminFrontendFr from "../../bindings/oidc/OidcClient.bs.js";
import * as Messages_Roles$DvmAdminFrontendFr from "../../intl/messages/roles/Messages_Roles.bs.js";
import * as Utils_Environment$DvmAdminFrontendFr from "./Utils_Environment.bs.js";

var idpBaseUrl = process.env.REACT_APP_IDP_DOMAIN + "/nidp/oauth/nam";

var oktaBaseUrl = process.env.REACT_APP_OKTA_DOMAIN;

function makeOidcClientMetadata(issuer) {
  return {
          issuer: issuer,
          authorization_endpoint: "" + issuer + "/authz",
          token_endpoint: "" + issuer + "/token",
          userinfo_endpoint: "" + issuer + "/userinfo",
          jwks_uri: "" + issuer + "/keys",
          registration_endpoint: "" + issuer + "/clients",
          end_session_endpoint: "" + issuer + "/logout"
        };
}

function makeOidcClientMetadataOkta(issuer) {
  return {
          issuer: issuer,
          authorization_endpoint: "" + issuer + "/v1/authorize",
          token_endpoint: "" + issuer + "/v1/token",
          userinfo_endpoint: "" + issuer + "/v1/userinfo",
          jwks_uri: "" + issuer + "/v1/keys",
          registration_endpoint: "" + issuer + "/v1/clients",
          end_session_endpoint: "" + issuer + "/v1/logout"
        };
}

function getAuthenticationDomain(param) {
  if (EnvVar$DvmAdminFrontendFr.oktaEnabled) {
    return /* Okta */2;
  } else if (window.location.hostname.toLocaleLowerCase().includes("dcs")) {
    return /* Vectury */1;
  } else {
    return /* Arca */0;
  }
}

function getRoleTranslation(role) {
  if (typeof role !== "number") {
    if (role.TAG === /* Administrator */0) {
      if (role._0) {
        return Messages_Roles$DvmAdminFrontendFr.administratorSpain;
      } else if (EnvVar$DvmAdminFrontendFr.currentEnvironmentProd) {
        return Messages_Roles$DvmAdminFrontendFr.administratorFranceProd;
      } else {
        return Messages_Roles$DvmAdminFrontendFr.administratorFrance;
      }
    } else if (role._0) {
      return Messages_Roles$DvmAdminFrontendFr.adminDealerES;
    } else {
      return Messages_Roles$DvmAdminFrontendFr.adminDealerFR;
    }
  }
  switch (role) {
    case /* Advisor */0 :
        return Messages_Roles$DvmAdminFrontendFr.advisor;
    case /* PreDirector */1 :
        return Messages_Roles$DvmAdminFrontendFr.preDirector;
    case /* CompanyManager */2 :
        return Messages_Roles$DvmAdminFrontendFr.companyManager;
    case /* SeniorCompanyManager */3 :
        return Messages_Roles$DvmAdminFrontendFr.seniorCompanyManager;
    case /* AcountManager */4 :
        return Messages_Roles$DvmAdminFrontendFr.acountManager;
    case /* LldManager */5 :
        return Messages_Roles$DvmAdminFrontendFr.lldManager;
    case /* BigAccountManager */6 :
        return Messages_Roles$DvmAdminFrontendFr.bigAccountManager;
    case /* Dealer */7 :
        return Messages_Roles$DvmAdminFrontendFr.dealer;
    case /* MRR */8 :
        return Messages_Roles$DvmAdminFrontendFr.mrr;
    case /* NoRole */9 :
        return Messages_Roles$DvmAdminFrontendFr.noRole;
    
  }
}

function roleToString(role) {
  if (typeof role !== "number") {
    if (role.TAG === /* Administrator */0) {
      if (role._0) {
        return "dvme_es_nv_admin";
      } else {
        return "dvme_fr_admin";
      }
    } else if (role._0) {
      return "dvme_es_admindealer";
    } else {
      return "dvme_fr_admindealer";
    }
  }
  switch (role) {
    case /* Advisor */0 :
        return "dvme_fr_ct";
    case /* PreDirector */1 :
        return "dvme_fr_dir_rpe";
    case /* CompanyManager */2 :
        return "dvme_fr_me";
    case /* SeniorCompanyManager */3 :
        return "dvme_fr_sme";
    case /* AcountManager */4 :
        return "dvme_fr_smgc";
    case /* LldManager */5 :
        return "dvme_fr_smlld";
    case /* BigAccountManager */6 :
        return "dvme_fr_mgc";
    case /* Dealer */7 :
        return "dvme_fr_reseau";
    case /* MRR */8 :
        return "dvme_fr_mrr";
    case /* NoRole */9 :
        return "no_role";
    
  }
}

function roleFromString(role) {
  var match = role.toLowerCase();
  switch (match) {
    case "dvme_es_admindealer" :
        return {
                TAG: /* ADMINDEALER */1,
                _0: /* Spain */1
              };
    case "dvme_es_nv_admin" :
        return {
                TAG: /* Administrator */0,
                _0: /* Spain */1
              };
    case "dvme_fr_admin" :
        return {
                TAG: /* Administrator */0,
                _0: /* France */0
              };
    case "dvme_fr_admindealer" :
        return {
                TAG: /* ADMINDEALER */1,
                _0: /* France */0
              };
    case "dvme_fr_ct" :
        return /* Advisor */0;
    case "dvme_fr_dir_rpe" :
        return /* PreDirector */1;
    case "dvme_fr_me" :
        return /* CompanyManager */2;
    case "dvme_fr_mgc" :
        return /* BigAccountManager */6;
    case "dvme_fr_mrr" :
        return /* MRR */8;
    case "dvme_fr_sme" :
        return /* SeniorCompanyManager */3;
    case "dvme_fr_smgc" :
        return /* AcountManager */4;
    case "dvme_fr_smlld" :
        return /* LldManager */5;
    case "dvme_fr_reseau" :
    case "irn-72019:dvme_fr_reseau" :
        return /* Dealer */7;
    default:
      return /* NoRole */9;
  }
}

function parseTokenRoles(profile) {
  var parseRoles = function (roles) {
    if (Array.isArray(roles)) {
      return roles;
    } else {
      return [roles];
    }
  };
  var match = getAuthenticationDomain(undefined);
  var authorities;
  switch (match) {
    case /* Arca */0 :
        authorities = parseRoles(Belt_Option.getWithDefault(profile["role-dvm-irn72019"], ""));
        break;
    case /* Vectury */1 :
        authorities = parseRoles(Belt_Option.getWithDefault(profile["role-rnet-dvm-irn72019"], ""));
        break;
    case /* Okta */2 :
        authorities = profile.roles;
        break;
    
  }
  var filteredRoles = [];
  authorities.forEach(function (authority) {
        var role = roleFromString(authority);
        if (role !== /* NoRole */9) {
          filteredRoles.push(role);
          return ;
        }
        
      });
  return Belt_List.fromArray(filteredRoles);
}

function parseTokenFirstName(profile) {
  var match = getAuthenticationDomain(undefined);
  if (match !== 0) {
    return Belt_Option.getWithDefault(profile.given_name, "");
  } else {
    return Belt_Option.getWithDefault(profile.firstname, "");
  }
}

function parseTokenLastName(profile) {
  var match = getAuthenticationDomain(undefined);
  if (match !== 0) {
    return Belt_Option.getWithDefault(profile.family_name, "");
  } else {
    return Belt_Option.getWithDefault(profile.lastname, "");
  }
}

function parseTokenUid(profile) {
  var match = getAuthenticationDomain(undefined);
  if (match >= 2) {
    return Belt_Option.getWithDefault(profile.login, "");
  } else {
    return Belt_Option.getWithDefault(profile.uid, "");
  }
}

function createUserManager(param) {
  var authenticationDomain = getAuthenticationDomain(undefined);
  var log = OidcClient.Log;
  log.logger = console;
  var match = Utils_Environment$DvmAdminFrontendFr.getBuildEnvironment(undefined);
  if (match) {
    log.level = log.WARN;
  } else {
    log.level = log.DEBUG;
  }
  var getScopes = function (authenticationDomain) {
    switch (authenticationDomain) {
      case /* Arca */0 :
          return {
                  hd: "email",
                  tl: {
                    hd: "openid",
                    tl: {
                      hd: "arcaX",
                      tl: {
                        hd: "role-dvm-irn72019",
                        tl: /* [] */0
                      }
                    }
                  }
                };
      case /* Vectury */1 :
          return {
                  hd: "email",
                  tl: {
                    hd: "openid",
                    tl: {
                      hd: "profile",
                      tl: {
                        hd: "vectury-userX",
                        tl: {
                          hd: "role-rnet-dvm-irn72019",
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                };
      case /* Okta */2 :
          return {
                  hd: "apis.default",
                  tl: {
                    hd: "openid",
                    tl: {
                      hd: "profile",
                      tl: {
                        hd: "email",
                        tl: {
                          hd: "alliance_profile",
                          tl: {
                            hd: "legacy",
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                };
      
    }
  };
  var getClientId = function (authenticationDomain) {
    switch (authenticationDomain) {
      case /* Arca */0 :
          return process.env.REACT_APP_IDP_ARCA_CLIENT_ID;
      case /* Vectury */1 :
          return process.env.REACT_APP_IDP_VECTURY_CLIENT_ID;
      case /* Okta */2 :
          return process.env.REACT_APP_OKTA_ID;
      
    }
  };
  var getAcrValues = function (authenticationDomain) {
    if (authenticationDomain !== 1) {
      return ;
    } else {
      return "secure/name/x509/uri/RNETandTWIN";
    }
  };
  var getAuthority = function (authenticationDomain) {
    if (authenticationDomain >= 2) {
      return oktaBaseUrl;
    } else {
      return idpBaseUrl;
    }
  };
  var getMetadataMaker = function (authenticationDomain) {
    if (authenticationDomain >= 2) {
      return makeOidcClientMetadataOkta;
    } else {
      return makeOidcClientMetadata;
    }
  };
  var userManager = new OidcClient.UserManager(OidcClient$DvmAdminFrontendFr.createOidcClientSettings(false, {
            hd: "code",
            tl: /* [] */0
          }, getScopes(authenticationDomain), getAcrValues(authenticationDomain), getAuthority(authenticationDomain), getClientId(authenticationDomain), getMetadataMaker(authenticationDomain)(getAuthority(authenticationDomain)), undefined));
  userManager.events.addAccessTokenExpired(function (param) {
        userManager.signoutRedirect();
      });
  return userManager;
}

function authenticateAndRedirect(userManager) {
  userManager.clearStaleState().then(function (param) {
        userManager.signinRedirect();
      });
}

function logoutAndRedirect(userManager) {
  userManager.signoutRedirect();
}

function processAuthenticationRedirect(userManager) {
  return userManager.signinRedirectCallback();
}

function isLocationSearchRedirectedFromIdp(search) {
  return Constants$DvmAdminFrontendFr.Regex.oidcAuthCodeUrl.test(search);
}

function isLocationRedirectedFromIdp(param) {
  return Constants$DvmAdminFrontendFr.Regex.oidcAuthCodeUrl.test(param.search);
}

export {
  idpBaseUrl ,
  oktaBaseUrl ,
  makeOidcClientMetadata ,
  makeOidcClientMetadataOkta ,
  getAuthenticationDomain ,
  getRoleTranslation ,
  roleToString ,
  roleFromString ,
  parseTokenRoles ,
  parseTokenFirstName ,
  parseTokenLastName ,
  parseTokenUid ,
  createUserManager ,
  authenticateAndRedirect ,
  logoutAndRedirect ,
  processAuthenticationRedirect ,
  isLocationSearchRedirectedFromIdp ,
  isLocationRedirectedFromIdp ,
}
/* idpBaseUrl Not a pure module */
