// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Constants$DvmAdminFrontendFr from "../../common/Constants.bs.js";
import * as Utils_Authentication$DvmAdminFrontendFr from "../../common/utils/Utils_Authentication.bs.js";

function storeOidcUser(dispatch, profile) {
  return Curry._1(dispatch, {
              TAG: /* StoreAsAuthenticated */0,
              _0: profile
            });
}

function checkOidcUser(user, userManager, dispatch) {
  if (user == null) {
    return Utils_Authentication$DvmAdminFrontendFr.authenticateAndRedirect(userManager);
  }
  var expired = user.expired;
  if (expired !== undefined && !expired) {
    return Curry._1(dispatch, {
                TAG: /* StoreAsAuthenticated */0,
                _0: user.profile
              });
  } else {
    return Utils_Authentication$DvmAdminFrontendFr.authenticateAndRedirect(userManager);
  }
}

function authenticate(dispatch, currentUrl, userManager) {
  if (Utils_Authentication$DvmAdminFrontendFr.isLocationRedirectedFromIdp(currentUrl)) {
    var redirect = sessionStorage.getItem(Constants$DvmAdminFrontendFr.redirectUrlSessionStorageKey);
    var redirectString = Belt_Option.mapWithDefault((redirect == null) ? undefined : Caml_option.some(redirect), "/", (function (x) {
            return currentUrl.origin + x;
          }));
    $$Promise.$$catch(Utils_Authentication$DvmAdminFrontendFr.processAuthenticationRedirect(userManager).then(function (user) {
              Curry._1(dispatch, {
                    TAG: /* StoreAsAuthenticated */0,
                    _0: user.profile
                  });
              window.location.replace(redirectString);
            }), (function (param) {
            return Promise.resolve(Utils_Authentication$DvmAdminFrontendFr.authenticateAndRedirect(userManager));
          }));
  } else {
    userManager.getUser().then(function (user) {
          checkOidcUser(user, userManager, dispatch);
        });
    Promise.resolve(undefined);
  }
}

export {
  storeOidcUser ,
  checkOidcUser ,
  authenticate ,
}
/* Utils_Authentication-DvmAdminFrontendFr Not a pure module */
