// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../common/App_Typography.bs.js";

function Order_Detail_Correct_Box(Props) {
  var content = Props.content;
  var bgcolor = Props.bgcolor;
  var color = Props.color;
  var title = Props.title;
  var tmp = {
    children: React.createElement(Core.Grid, {
          children: Belt_Array.mapWithIndex(content, (function (index, element) {
                  return React.createElement(Core.Grid, {
                              children: React.createElement(Core.Box, {
                                    children: element,
                                    minHeight: 48
                                  }),
                              item: true,
                              key: String(index)
                            });
                })),
          container: true,
          direction: "column",
          spacing: 2
        }),
    borderColor: color,
    borderLeft: 4,
    boxShadow: "0 2px 7px 1px #eee",
    padding: "24px 32px"
  };
  var tmp$1 = Belt_Option.mapWithDefault(bgcolor, undefined, (function (x) {
          return Caml_option.some(x);
        }));
  if (tmp$1 !== undefined) {
    tmp.bgcolor = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              direction: "column",
              item: true,
              sm: Grid$Mui.Sm[6],
              spacing: 2
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        style: {
                          color: color,
                          fontWeight: "600"
                        },
                        children: title
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Core.Box, tmp),
                  item: true
                }));
}

var make = Order_Detail_Correct_Box;

export {
  make ,
}
/* react Not a pure module */
