// Generated by ReScript, PLEASE EDIT WITH CARE


var title = {
  id: "6b3581db-0463-4aae-bed4-33d0ba5b7e1c",
  defaultMessage: "Suivre les accords PCD"
};

var modelID = {
  id: "8dcfd1e9-f72c-45ce-aa49-dc9fcaadf060",
  defaultMessage: "Code Mod."
};

var categoryIDShort = {
  id: "2a2129b2-b8e3-45b5-b20d-98733a1d2f55",
  defaultMessage: "Code cat."
};

var acquisitionType = {
  id: "7467d2f3-4f16-496c-85f2-3b149c055a6c",
  defaultMessage: "Type Acq."
};

var contractID = {
  id: "e9fadca1-841d-4a0b-a4c3-79fa7e09072e",
  defaultMessage: "Code Prot"
};

var avsMarginGridId = {
  id: "94421d1c-003e-40ee-b06b-bd3256c16571",
  defaultMessage: "Code Grille"
};

var companyDiscountPercentage = {
  id: "744076e6-bb09-4a45-a956-18bf8d484ea9",
  defaultMessage: "% Remise"
};

var volume = {
  id: "329854cf-078b-4e4e-b9f8-eb338557ddf3",
  defaultMessage: "Volume"
};

var processingDate = {
  id: "62f8c57b-4ad3-4e38-92a5-bb991ea96d91",
  defaultMessage: "Date d’envoi"
};

var resultInfo = {
  id: "e9d6b0a1-a06e-424f-969e-ff0a7742a080",
  defaultMessage: "accords"
};

var eraseRow = {
  id: "825dea17-3167-47b4-a005-ef8e0b704998",
  defaultMessage: "Supprimer l’accord"
};

var modifyRow = {
  id: "40e5e8b0-c529-499f-adf8-c85edee0e601",
  defaultMessage: "Modifier le volume restant"
};

var eraseSuccess = {
  id: "21ae7966-43dc-4ff4-9431-ec47e4c53a76",
  defaultMessage: "Suppression effectuée"
};

var modifySuccess = {
  id: "ddf1e52c-5da0-4e00-b1b6-a258415bdbf6",
  defaultMessage: "Modification effectuée"
};

var deleteTitle = {
  id: "0b6cedde-713e-487a-b62f-70e7099aacd0",
  defaultMessage: "Supprimer un accord"
};

var deleteMessage = {
  id: "fe677d27-1a7f-4cc3-9c61-70603df685c0",
  defaultMessage: "Confirmez-vous la suppression de l’accord sélectionné ?"
};

var updateTitle = {
  id: "918004e2-d108-4a54-937b-710ab5e36d19",
  defaultMessage: "Modifier un accord"
};

var responseNumber = {
  id: "27184fb9-4a3b-4e9c-b0dc-091b24d7655c",
  defaultMessage: "N° Réponse RPE"
};

var unlimited = {
  id: "c3fb0304-d4f8-4402-858a-509e5e716221",
  defaultMessage: "Illimité"
};

export {
  title ,
  modelID ,
  categoryIDShort ,
  acquisitionType ,
  contractID ,
  avsMarginGridId ,
  companyDiscountPercentage ,
  volume ,
  processingDate ,
  resultInfo ,
  eraseRow ,
  modifyRow ,
  eraseSuccess ,
  modifySuccess ,
  deleteTitle ,
  deleteMessage ,
  updateTitle ,
  responseNumber ,
  unlimited ,
}
/* No side effect */
