// Generated by ReScript, PLEASE EDIT WITH CARE


var home = {
  id: "a58ab722-9b47-49e6-aa1b-dd84a14148be",
  defaultMessage: "Accueil"
};

var view = {
  id: "93eab0d6-8863-45ae-b38e-aab9b30981ae",
  defaultMessage: "Consulter"
};

var manage = {
  id: "7c4051e2-2355-42f3-a81a-d54eb6a2d7d9",
  defaultMessage: "Paramétrer"
};

var order = {
  id: "7c3a147f-dc89-44bc-8378-976654d39034",
  defaultMessage: "Commandes"
};

var flash = {
  id: "b2d73d38-8fee-4467-9f64-61e2d21494e9",
  defaultMessage: "Actions commerciales"
};

var createFlash = {
  id: "d819f44e-4641-44e9-aea6-25157711e410",
  defaultMessage: "Créer une action commerciale"
};

var contractManager = {
  id: "f056a835-df3b-40b8-823c-46eba4f294b2",
  defaultMessage: "Intervenants"
};

var quotas = {
  id: "a67fcf2a-95b2-454e-8761-cac085d16c52",
  defaultMessage: "Quotas LCD"
};

var modelVersionGrid = {
  id: "16269e41-8bed-429a-b940-7385733e4661",
  defaultMessage: "Grilles de conditions"
};

var margingrid = {
  id: "36416ff5-9744-4469-9776-72dc4e96a964",
  defaultMessage: "Grilles de marges"
};

var modelVersionList = {
  id: "5fc2c4c9-339a-4bbc-9c26-9b39927335bd",
  defaultMessage: "Modèles / Versions"
};

var bonusesView = {
  id: "7eb839de-0d04-4492-bad0-d0d0e94b87af",
  defaultMessage: "Suivi des moyens co variables"
};

var bonusesEdit = {
  id: "5f3c2540-e45c-42ad-8923-17bf40303947",
  defaultMessage: "Suivi et Validation PERF"
};

var contracts = {
  id: "520f1b74-7a74-45cb-b57d-81735aa74eed",
  defaultMessage: "Contrats/Protocoles"
};

var attachments = {
  id: "2344da11-939b-4cd3-acd6-f57b71d6f691",
  defaultMessage: "Rattachements"
};

var pcdAgreement = {
  id: "92d64fba-860a-4b24-a7d7-e3821d266377",
  defaultMessage: "Accords PCD"
};

var contractEdit = {
  id: "000a56ff-baf0-45fa-bbc1-ff68636ec17b",
  defaultMessage: "Créer un contrat"
};

var categories = {
  id: "a38c5844-a641-40e3-9ab4-c182724761ee",
  defaultMessage: "Catégories"
};

var createCategory = {
  id: "186b33a9-3d3e-484e-8c07-9dcda5c5b0a0",
  defaultMessage: "Gestion des catégories"
};

var companyList = {
  id: "896bd5c2-c731-4bd8-8a2f-ce9fc6733f5b",
  defaultMessage: "Sociétés"
};

var companyVirtualEdit = {
  id: "a77cb7ef-6ebc-46bd-b8f3-d835a6373528",
  defaultMessage: "Modification des SIREN virtuels"
};

var companyManage = {
  id: "93ca9e94-f1be-4f67-9edf-30ccb0d29833",
  defaultMessage: "Modification des SIRET"
};

var companyVirtualView = {
  id: "73fe8ef7-7cbc-4263-8139-63c620992dc1",
  defaultMessage: "SIREN virtuels"
};

var dealerList = {
  id: "3ea65bf9-a0ac-42c4-81b6-65f1c00c2935",
  defaultMessage: "Rechercher une affaire"
};

var marginMinimum = {
  id: "9cd1b42e-4d19-49f2-98d0-114e299f8e42",
  defaultMessage: "Marge minimum"
};

var visibilityProfiles = {
  id: "b18b61df-a5a7-4adb-9a6a-e9069988907f",
  defaultMessage: "Gestion des profils"
};

var gemo = {
  id: "a3e9801f-88b9-4b29-b12b-b8150991f499",
  defaultMessage: "GEMO"
};

var createNewGemo = {
  id: "cf8e3aba-a6e4-43ed-950f-c99c4492a374",
  defaultMessage: "GEMO"
};

var submarkets = {
  id: "c0003b1c-0686-4b3c-9de8-283b8983e3c4",
  defaultMessage: "Sous-marchés"
};

var changeRoles = {
  id: "848dd58d-2f6f-4b57-9e31-c1e85f4e8138",
  defaultMessage: "Rôle de changement"
};

var logout = {
  id: "ef879c91-6eea-4eb4-a3b7-2b63d4999405",
  defaultMessage: "Se déconnecter"
};

var upload = {
  id: "7d5180f1-b9c6-452e-957e-b491971c2c9e",
  defaultMessage: "Import fichiers"
};

var contractErrors = {
  id: "d36e6b4e-ab56-4d67-88f5-93a7b343808c",
  defaultMessage: "Erreurs de rattachement"
};

var submenuResults = {
  id: "e93455e8-7ee5-4363-b871-dd5e3f001f25",
  defaultMessage: "Résultats"
};

var submenuBudgets = {
  id: "48aa1fd3-dfbf-4daa-aec5-88ebd4e8f939",
  defaultMessage: "Budgets"
};

var submenuActions = {
  id: "3b232c96-0012-4ac2-a280-03aaa5ba1c53",
  defaultMessage: "Actions"
};

var submenuContracts = {
  id: "14059e5f-f449-46c0-9388-c300fadeb1ab",
  defaultMessage: "Contrats/Protocoles"
};

var submenuOthers = {
  id: "81a78327-b5d3-40cf-8f67-8cb236ddf198",
  defaultMessage: "Autres"
};

var submenuClients = {
  id: "e057eac3-f373-43a0-8f93-6d38248e6813",
  defaultMessage: "Clients"
};

var changeLanguage = {
  id: "9e3cadaf-955e-4e24-9c64-a0cfbca96208",
  defaultMessage: "Changer de langue"
};

var financialCode = {
  id: "2d1afe64-b46f-4e28-9cd7-cbe484c96797",
  defaultMessage: "Code barème financement"
};

var orderPERFORMANCE = {
  id: "079196d1-cdb2-4af5-aef4-1d324aca2bf0",
  defaultMessage: "PERFORMANCE Commandes"
};

var bonusesViewPERFORMANCE = {
  id: "1955b99e-aea2-4006-8b86-eb07a1da32d1",
  defaultMessage: "PERFORMANCE Suivi des moyens co variables"
};

var calculationsBanner = {
  id: "e2202db3-3d1a-4fff-9e69-ff98b37e4206",
  defaultMessage: "Traitement de données en cours. La saisie de nouvelles données sera possible dans un instant."
};

var brandsManagementPage = {
  id: "e2d77949-09f5-4dde-927b-2033503d2b3e",
  defaultMessage: "Gestion des marques VO"
};

export {
  home ,
  view ,
  manage ,
  order ,
  flash ,
  createFlash ,
  contractManager ,
  quotas ,
  modelVersionGrid ,
  margingrid ,
  modelVersionList ,
  bonusesView ,
  bonusesEdit ,
  contracts ,
  attachments ,
  pcdAgreement ,
  contractEdit ,
  categories ,
  createCategory ,
  companyList ,
  companyVirtualEdit ,
  companyManage ,
  companyVirtualView ,
  dealerList ,
  marginMinimum ,
  visibilityProfiles ,
  gemo ,
  createNewGemo ,
  submarkets ,
  changeRoles ,
  logout ,
  upload ,
  contractErrors ,
  submenuResults ,
  submenuBudgets ,
  submenuActions ,
  submenuContracts ,
  submenuOthers ,
  submenuClients ,
  changeLanguage ,
  financialCode ,
  orderPERFORMANCE ,
  bonusesViewPERFORMANCE ,
  calculationsBanner ,
  brandsManagementPage ,
}
/* No side effect */
