// Generated by ReScript, PLEASE EDIT WITH CARE


var title = {
  id: "b7c4155e-76b0-489e-a83f-b22e493663e3",
  defaultMessage: "Interroger les modèles/versions"
};

var labelModel = {
  id: "facf8f22-7b1d-4ba7-a3e5-f8c82af3c4fe",
  defaultMessage: "Libellé Modèle"
};

var labelVersion = {
  id: "34c7ad06-5512-4aee-bfc5-d66bae2e6dfc",
  defaultMessage: "Libellé Version"
};

var motorization = {
  id: "5a779ecc-8673-446f-bf8c-07b8f41ccb31",
  defaultMessage: "Energie"
};

var labelModelShort = {
  id: "ba87d476-d2d5-4f75-926c-cb57c3166c59",
  defaultMessage: "Libellé mod"
};

var labelVersionShort = {
  id: "2df83a78-e1a2-4063-84bc-4e15620e7103",
  defaultMessage: "Libellé ver"
};

var tarifNumber = {
  id: "333b937a-0fb1-45a1-8171-c503f1c95df7",
  defaultMessage: "N° Tarif"
};

var _type = {
  id: "f4371be9-2fc6-4b28-af02-5bfad68e644e",
  defaultMessage: "Type"
};

var active = {
  id: "53edaa1e-32e2-438d-b6c7-2b15101a191a",
  defaultMessage: "Actif"
};

var activationModalTitleModel = {
  id: "375e8830-2234-43e5-853f-a3e295fe6c3a",
  defaultMessage: "Voulez-vous activer le modèle ?"
};

var deactivationModalTitleModel = {
  id: "6e6b8581-e60f-44f3-8712-89b35e7d6b8d",
  defaultMessage: "Voulez-vous désactiver le modèle ?"
};

var activationModalTitleVersion = {
  id: "7690059a-b1e6-462d-84f2-27cea894b8b4",
  defaultMessage: "Voulez-vous activer la version ?"
};

var deactivationModalTitleVersion = {
  id: "0c471e06-b92a-48a4-9b01-3dc6a7c8501d",
  defaultMessage: "Voulez-vous désactiver la version ?"
};

var activateModalButton = {
  id: "e6a439ca-53e5-4a4c-9f85-b44748866b90",
  defaultMessage: "Activer"
};

var deactivateModalButton = {
  id: "50fc2899-8868-4517-9180-e3604dfa3e7f",
  defaultMessage: "Inactiver"
};

var tooltipMissingMotorizations = {
  id: "84a0d97a-2bc4-400c-a310-1a50b03351ee",
  defaultMessage: "Il manque l'énergie sur {total} versions"
};

var emptyMotorizationsCheckbox = {
  id: "ed233496-c982-4673-9fdb-9aeb0d4fa785",
  defaultMessage: "Si vide"
};

var newVersionsIconNotification = {
  id: "b5a70f41-d317-403d-8c26-a1241463ef51",
  defaultMessage: "Nouvelles versions disponibles (Cliquer pour voir)"
};

var additionDateLabel = {
  id: "eff29ac5-2cab-46a0-985f-f05a49d5831e",
  defaultMessage: "Date d'ajout"
};

export {
  title ,
  labelModel ,
  labelVersion ,
  motorization ,
  labelModelShort ,
  labelVersionShort ,
  tarifNumber ,
  _type ,
  active ,
  activationModalTitleModel ,
  deactivationModalTitleModel ,
  activationModalTitleVersion ,
  deactivationModalTitleVersion ,
  activateModalButton ,
  deactivateModalButton ,
  tooltipMissingMotorizations ,
  emptyMotorizationsCheckbox ,
  newVersionsIconNotification ,
  additionDateLabel ,
}
/* No side effect */
