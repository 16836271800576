// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as App_Spinner$DvmAdminFrontendFr from "../../../../common/components/App_Spinner.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../../../route/Route_Paths.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as Company_Detail_Api$DvmAdminFrontendFr from "../../../company/detail/Company_Detail_Api.bs.js";

function Order_Detail_Closed_Siret_Message(Props) {
  var actionDetail = Props.actionDetail;
  var siret = Props.siret;
  var comment = Props.comment;
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setClosedSiretResult = match[1];
  var intl = ReactIntl.useIntl();
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var locale = match$2.locale;
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  React.useEffect((function () {
          var isSiretClosed = Belt_Option.mapWithDefault(actionDetail.comment, false, (function (comment) {
                  return Belt_Option.isSome(Belt_List.getBy(comment, (function (x) {
                                    return x.errorId === /* SIRET_CLOSED */119;
                                  })));
                }));
          Belt_Option.mapWithDefault(siret, undefined, (function (siret) {
                  if (isSiretClosed) {
                    return Company_Detail_Api$DvmAdminFrontendFr.fetchCompanyDetail(siret, userManager, setClosedSiretResult, selectedRole);
                  }
                  
                }));
        }), []);
  return App_Types_Result$DvmAdminFrontendFr.mapWithDefault(match[0], React.createElement(Core.Grid, {
                  alignItems: "center",
                  children: null,
                  container: true,
                  item: true,
                  spacing: 2
                }, React.createElement(Core.Grid, {
                      children: Belt_Option.getWithDefault(locale === /* En */0 ? comment.errorDescription : comment.errorDescriptionLocal, ""),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Spinner$DvmAdminFrontendFr.make, {
                            size: /* Fixed */{
                              _0: "15px"
                            }
                          }),
                      item: true
                    })), (function (closedSiret) {
                return React.createElement(Core.Link, {
                            children: Belt_Option.mapWithDefault(locale === /* En */0 ? comment.errorDescription : comment.errorDescriptionLocal, "", (function (x) {
                                    return "" + x + " (" + closedSiret.siret + " - " + Belt_Option.mapWithDefault(closedSiret.endDate, "-", (function (param) {
                                                  return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                })) + ")";
                                  })),
                            color: "error",
                            style: {
                              cursor: "pointer"
                            },
                            onClick: (function (param) {
                                RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rView + Route_Paths$DvmAdminFrontendFr.rCompany + "/" + closedSiret.siret);
                              })
                          });
              }));
}

var make = Order_Detail_Closed_Siret_Message;

export {
  make ,
}
/* react Not a pure module */
