// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../common/utils/Utils_Form.bs.js";
import * as Contract_Attachments_Utils$DvmAdminFrontendFr from "../../../contract/editCreate/attachment/Contract_Attachments_Utils.bs.js";

var validators_siret = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var value = Utils_Form$DvmAdminFrontendFr.validateRequired(param.siret.toUpperCase());
      if (value.TAG === /* Ok */0) {
        return Utils_Form$DvmAdminFrontendFr.validateMaxCharacters(value._0, 9);
      } else {
        return {
                TAG: /* Error */1,
                _0: value._0
              };
      }
    })
};

var validators_name = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.name);
    })
};

var validators_zipCode = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateWithRegex(param.zipCode, /^(\d{5})$/);
    })
};

var validators_endDate = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateDate(param.endDate, undefined);
    })
};

var validators_contractID = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      if (param.addAttachment) {
        return Utils_Form$DvmAdminFrontendFr.validateRequired(param.contractID);
      } else {
        return {
                TAG: /* Ok */0,
                _0: ""
              };
      }
    })
};

var validators_contractStartDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      if (param.addAttachment) {
        return Utils_Form$DvmAdminFrontendFr.validateDate(param.contractStartDate, undefined);
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    })
};

var validators_contractEndDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      if (param.addAttachment) {
        return Utils_Form$DvmAdminFrontendFr.validateDate(param.contractEndDate, undefined);
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    })
};

var validators_attachmentStartDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      if (param.addAttachment) {
        return Contract_Attachments_Utils$DvmAdminFrontendFr.validateAttachmentStartDate(param.attachmentStartDate, param.contractStartDate, param.contractEndDate, "required");
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    })
};

var validators_attachmentEndDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      if (param.addAttachment) {
        return Contract_Attachments_Utils$DvmAdminFrontendFr.validateAttachmentDates(param.attachmentStartDate, param.attachmentEndDate, param.contractStartDate, param.contractEndDate, param.endDateAsParent);
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    })
};

var validators_endDateAsParent = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.endDateAsParent
            };
    })
};

var validators = {
  siret: validators_siret,
  name: validators_name,
  street: undefined,
  streetNbr: undefined,
  additionalAddressInfo: undefined,
  zipCode: validators_zipCode,
  city: undefined,
  countryCode: undefined,
  endDate: validators_endDate,
  addAttachment: undefined,
  contractID: validators_contractID,
  contractStartDate: validators_contractStartDate,
  contractEndDate: validators_contractEndDate,
  attachmentStartDate: validators_attachmentStartDate,
  attachmentEndDate: validators_attachmentEndDate,
  endDateAsParent: validators_endDateAsParent,
  origAttachmentStartDate: undefined
};

function initialFieldsStatuses(_input) {
  return {
          siret: /* Pristine */0,
          name: /* Pristine */0,
          street: /* Pristine */0,
          streetNbr: /* Pristine */0,
          additionalAddressInfo: /* Pristine */0,
          zipCode: /* Pristine */0,
          city: /* Pristine */0,
          countryCode: /* Pristine */0,
          endDate: /* Pristine */0,
          addAttachment: /* Pristine */0,
          contractID: /* Pristine */0,
          contractStartDate: /* Pristine */0,
          contractEndDate: /* Pristine */0,
          attachmentStartDate: /* Pristine */0,
          attachmentEndDate: /* Pristine */0,
          endDateAsParent: /* Pristine */0,
          origAttachmentStartDate: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.siret;
  var match_0 = match ? match._0 : Curry._1(validators.siret.validate, input);
  var match$1 = fieldsStatuses.name;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.name.validate, input);
  var match_0$2 = {
    TAG: /* Ok */0,
    _0: input.street
  };
  var match_0$3 = {
    TAG: /* Ok */0,
    _0: input.streetNbr
  };
  var match_0$4 = {
    TAG: /* Ok */0,
    _0: input.additionalAddressInfo
  };
  var match$2 = fieldsStatuses.zipCode;
  var match_0$5 = match$2 ? match$2._0 : Curry._1(validators.zipCode.validate, input);
  var match_0$6 = {
    TAG: /* Ok */0,
    _0: input.city
  };
  var match_0$7 = {
    TAG: /* Ok */0,
    _0: input.countryCode
  };
  var match$3 = fieldsStatuses.endDate;
  var match_0$8 = match$3 ? match$3._0 : Curry._1(validators.endDate.validate, input);
  var match_0$9 = {
    TAG: /* Ok */0,
    _0: input.addAttachment
  };
  var match$4 = fieldsStatuses.contractID;
  var match_0$10 = match$4 ? match$4._0 : Curry._1(validators.contractID.validate, input);
  var match$5 = fieldsStatuses.contractStartDate;
  var match_0$11 = match$5 ? match$5._0 : Curry._1(validators.contractStartDate.validate, input);
  var match$6 = fieldsStatuses.contractEndDate;
  var match_0$12 = match$6 ? match$6._0 : Curry._1(validators.contractEndDate.validate, input);
  var match$7 = fieldsStatuses.attachmentStartDate;
  var match_0$13 = match$7 ? match$7._0 : Curry._1(validators.attachmentStartDate.validate, input);
  var match$8 = fieldsStatuses.attachmentEndDate;
  var match_0$14 = match$8 ? match$8._0 : Curry._1(validators.attachmentEndDate.validate, input);
  var match$9 = fieldsStatuses.endDateAsParent;
  var match_0$15 = match$9 ? match$9._0 : Curry._1(validators.endDateAsParent.validate, input);
  var match_0$16 = {
    TAG: /* Ok */0,
    _0: input.origAttachmentStartDate
  };
  var siretResult = match_0;
  var siretResult$1;
  if (siretResult.TAG === /* Ok */0) {
    var nameResult = match_0$1;
    if (nameResult.TAG === /* Ok */0) {
      var streetResult = match_0$2;
      if (streetResult.TAG === /* Ok */0) {
        var streetNbrResult = match_0$3;
        if (streetNbrResult.TAG === /* Ok */0) {
          var additionalAddressInfoResult = match_0$4;
          if (additionalAddressInfoResult.TAG === /* Ok */0) {
            var zipCodeResult = match_0$5;
            if (zipCodeResult.TAG === /* Ok */0) {
              var cityResult = match_0$6;
              if (cityResult.TAG === /* Ok */0) {
                var countryCodeResult = match_0$7;
                if (countryCodeResult.TAG === /* Ok */0) {
                  var endDateResult = match_0$8;
                  if (endDateResult.TAG === /* Ok */0) {
                    var addAttachmentResult = match_0$9;
                    if (addAttachmentResult.TAG === /* Ok */0) {
                      var contractIDResult = match_0$10;
                      if (contractIDResult.TAG === /* Ok */0) {
                        var contractStartDateResult = match_0$11;
                        if (contractStartDateResult.TAG === /* Ok */0) {
                          var contractEndDateResult = match_0$12;
                          if (contractEndDateResult.TAG === /* Ok */0) {
                            var attachmentStartDateResult = match_0$13;
                            if (attachmentStartDateResult.TAG === /* Ok */0) {
                              var attachmentEndDateResult = match_0$14;
                              if (attachmentEndDateResult.TAG === /* Ok */0) {
                                var endDateAsParentResult = match_0$15;
                                if (endDateAsParentResult.TAG === /* Ok */0) {
                                  var origAttachmentStartDateResult = match_0$16;
                                  if (origAttachmentStartDateResult.TAG === /* Ok */0) {
                                    return {
                                            TAG: /* Valid */0,
                                            output: {
                                              siret: siretResult._0,
                                              name: nameResult._0,
                                              street: streetResult._0,
                                              streetNbr: streetNbrResult._0,
                                              additionalAddressInfo: additionalAddressInfoResult._0,
                                              zipCode: zipCodeResult._0,
                                              city: cityResult._0,
                                              countryCode: countryCodeResult._0,
                                              endDate: endDateResult._0,
                                              addAttachment: addAttachmentResult._0,
                                              contractID: contractIDResult._0,
                                              contractStartDate: contractStartDateResult._0,
                                              contractEndDate: contractEndDateResult._0,
                                              attachmentStartDate: attachmentStartDateResult._0,
                                              attachmentEndDate: attachmentEndDateResult._0,
                                              endDateAsParent: endDateAsParentResult._0,
                                              origAttachmentStartDate: origAttachmentStartDateResult._0
                                            },
                                            fieldsStatuses: {
                                              siret: /* Dirty */{
                                                _0: siretResult,
                                                _1: /* Shown */0
                                              },
                                              name: /* Dirty */{
                                                _0: nameResult,
                                                _1: /* Shown */0
                                              },
                                              street: /* Dirty */{
                                                _0: streetResult,
                                                _1: /* Hidden */1
                                              },
                                              streetNbr: /* Dirty */{
                                                _0: streetNbrResult,
                                                _1: /* Hidden */1
                                              },
                                              additionalAddressInfo: /* Dirty */{
                                                _0: additionalAddressInfoResult,
                                                _1: /* Hidden */1
                                              },
                                              zipCode: /* Dirty */{
                                                _0: zipCodeResult,
                                                _1: /* Shown */0
                                              },
                                              city: /* Dirty */{
                                                _0: cityResult,
                                                _1: /* Hidden */1
                                              },
                                              countryCode: /* Dirty */{
                                                _0: countryCodeResult,
                                                _1: /* Hidden */1
                                              },
                                              endDate: /* Dirty */{
                                                _0: endDateResult,
                                                _1: /* Shown */0
                                              },
                                              addAttachment: /* Dirty */{
                                                _0: addAttachmentResult,
                                                _1: /* Hidden */1
                                              },
                                              contractID: /* Dirty */{
                                                _0: contractIDResult,
                                                _1: /* Shown */0
                                              },
                                              contractStartDate: /* Dirty */{
                                                _0: contractStartDateResult,
                                                _1: /* Shown */0
                                              },
                                              contractEndDate: /* Dirty */{
                                                _0: contractEndDateResult,
                                                _1: /* Shown */0
                                              },
                                              attachmentStartDate: /* Dirty */{
                                                _0: attachmentStartDateResult,
                                                _1: /* Shown */0
                                              },
                                              attachmentEndDate: /* Dirty */{
                                                _0: attachmentEndDateResult,
                                                _1: /* Shown */0
                                              },
                                              endDateAsParent: /* Dirty */{
                                                _0: endDateAsParentResult,
                                                _1: /* Shown */0
                                              },
                                              origAttachmentStartDate: /* Dirty */{
                                                _0: origAttachmentStartDateResult,
                                                _1: /* Hidden */1
                                              }
                                            },
                                            collectionsStatuses: undefined
                                          };
                                  }
                                  siretResult$1 = siretResult;
                                } else {
                                  siretResult$1 = siretResult;
                                }
                              } else {
                                siretResult$1 = siretResult;
                              }
                            } else {
                              siretResult$1 = siretResult;
                            }
                          } else {
                            siretResult$1 = siretResult;
                          }
                        } else {
                          siretResult$1 = siretResult;
                        }
                      } else {
                        siretResult$1 = siretResult;
                      }
                    } else {
                      siretResult$1 = siretResult;
                    }
                  } else {
                    siretResult$1 = siretResult;
                  }
                } else {
                  siretResult$1 = siretResult;
                }
              } else {
                siretResult$1 = siretResult;
              }
            } else {
              siretResult$1 = siretResult;
            }
          } else {
            siretResult$1 = siretResult;
          }
        } else {
          siretResult$1 = siretResult;
        }
      } else {
        siretResult$1 = siretResult;
      }
    } else {
      siretResult$1 = siretResult;
    }
  } else {
    siretResult$1 = siretResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            siret: /* Dirty */{
              _0: siretResult$1,
              _1: /* Shown */0
            },
            name: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            street: /* Dirty */{
              _0: match_0$2,
              _1: /* Hidden */1
            },
            streetNbr: /* Dirty */{
              _0: match_0$3,
              _1: /* Hidden */1
            },
            additionalAddressInfo: /* Dirty */{
              _0: match_0$4,
              _1: /* Hidden */1
            },
            zipCode: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            city: /* Dirty */{
              _0: match_0$6,
              _1: /* Hidden */1
            },
            countryCode: /* Dirty */{
              _0: match_0$7,
              _1: /* Hidden */1
            },
            endDate: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            },
            addAttachment: /* Dirty */{
              _0: match_0$9,
              _1: /* Hidden */1
            },
            contractID: /* Dirty */{
              _0: match_0$10,
              _1: /* Shown */0
            },
            contractStartDate: /* Dirty */{
              _0: match_0$11,
              _1: /* Shown */0
            },
            contractEndDate: /* Dirty */{
              _0: match_0$12,
              _1: /* Shown */0
            },
            attachmentStartDate: /* Dirty */{
              _0: match_0$13,
              _1: /* Shown */0
            },
            attachmentEndDate: /* Dirty */{
              _0: match_0$14,
              _1: /* Shown */0
            },
            endDateAsParent: /* Dirty */{
              _0: match_0$15,
              _1: /* Shown */0
            },
            origAttachmentStartDate: /* Dirty */{
              _0: match_0$16,
              _1: /* Hidden */1
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurSiretField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.siret, validators_siret, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: status,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurNameField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.name, validators_name, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  name: status,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurStreetField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithoutValidator(state.input.street, state.fieldsStatuses.street, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: status,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurStreetNbrField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithoutValidator(state.input.streetNbr, state.fieldsStatuses.streetNbr, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: status,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAdditionalAddressInfoField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithoutValidator(state.input.additionalAddressInfo, state.fieldsStatuses.additionalAddressInfo, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: status,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurZipCodeField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.zipCode, validators_zipCode, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: status,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCityField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithoutValidator(state.input.city, state.fieldsStatuses.city, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: status,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCountryCodeField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithoutValidator(state.input.countryCode, state.fieldsStatuses.countryCode, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: status,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEndDateField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.endDate, validators_endDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: status,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAddAttachmentField */9 :
                  var result$9 = Formality.validateFieldOnBlurWithoutValidator(state.input.addAttachment, state.fieldsStatuses.addAttachment, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: status,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurContractIDField */10 :
                  var result$10 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.contractID, validators_contractID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: status,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurContractStartDateField */11 :
                  var result$11 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.contractStartDate, validators_contractStartDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: status,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurContractEndDateField */12 :
                  var result$12 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.contractEndDate, validators_contractEndDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: status,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$12 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$12,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAttachmentStartDateField */13 :
                  var result$13 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.attachmentStartDate, validators_attachmentStartDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: status,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$13 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$13,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAttachmentEndDateField */14 :
                  var result$14 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.attachmentEndDate, validators_attachmentEndDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: status,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$14 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$14,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEndDateAsParentField */15 :
                  var result$15 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.endDateAsParent, validators_endDateAsParent, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: status,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$15 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$15,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurOrigAttachmentStartDateField */16 :
                  var result$16 = Formality.validateFieldOnBlurWithoutValidator(state.input.origAttachmentStartDate, state.fieldsStatuses.origAttachmentStartDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: status
                                };
                        }));
                  if (result$16 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$16,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */17 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */17,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */18,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */20);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */19);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */18 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */19 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */20 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateSiretField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.siret, state.submissionStatus, validators_siret, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: status,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            endDate: init.endDate,
                                            addAttachment: init.addAttachment,
                                            contractID: init.contractID,
                                            contractStartDate: init.contractStartDate,
                                            contractEndDate: init.contractEndDate,
                                            attachmentStartDate: init.attachmentStartDate,
                                            attachmentEndDate: init.attachmentEndDate,
                                            endDateAsParent: init.endDateAsParent,
                                            origAttachmentStartDate: init.origAttachmentStartDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateNameField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.name, state.submissionStatus, validators_name, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: init.siret,
                                            name: status,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            endDate: init.endDate,
                                            addAttachment: init.addAttachment,
                                            contractID: init.contractID,
                                            contractStartDate: init.contractStartDate,
                                            contractEndDate: init.contractEndDate,
                                            attachmentStartDate: init.attachmentStartDate,
                                            attachmentEndDate: init.attachmentEndDate,
                                            endDateAsParent: init.endDateAsParent,
                                            origAttachmentStartDate: init.origAttachmentStartDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateStreetField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$2.street, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: init.siret,
                                            name: init.name,
                                            street: status,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            endDate: init.endDate,
                                            addAttachment: init.addAttachment,
                                            contractID: init.contractID,
                                            contractStartDate: init.contractStartDate,
                                            contractEndDate: init.contractEndDate,
                                            attachmentStartDate: init.attachmentStartDate,
                                            attachmentEndDate: init.attachmentEndDate,
                                            endDateAsParent: init.endDateAsParent,
                                            origAttachmentStartDate: init.origAttachmentStartDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateStreetNbrField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$3.streetNbr, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: init.siret,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: status,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            endDate: init.endDate,
                                            addAttachment: init.addAttachment,
                                            contractID: init.contractID,
                                            contractStartDate: init.contractStartDate,
                                            contractEndDate: init.contractEndDate,
                                            attachmentStartDate: init.attachmentStartDate,
                                            attachmentEndDate: init.attachmentEndDate,
                                            endDateAsParent: init.endDateAsParent,
                                            origAttachmentStartDate: init.origAttachmentStartDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAdditionalAddressInfoField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$4.additionalAddressInfo, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: init.siret,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: status,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            endDate: init.endDate,
                                            addAttachment: init.addAttachment,
                                            contractID: init.contractID,
                                            contractStartDate: init.contractStartDate,
                                            contractEndDate: init.contractEndDate,
                                            attachmentStartDate: init.attachmentStartDate,
                                            attachmentEndDate: init.attachmentEndDate,
                                            endDateAsParent: init.endDateAsParent,
                                            origAttachmentStartDate: init.origAttachmentStartDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateZipCodeField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.zipCode, state.submissionStatus, validators_zipCode, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: init.siret,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: status,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            endDate: init.endDate,
                                            addAttachment: init.addAttachment,
                                            contractID: init.contractID,
                                            contractStartDate: init.contractStartDate,
                                            contractEndDate: init.contractEndDate,
                                            attachmentStartDate: init.attachmentStartDate,
                                            attachmentEndDate: init.attachmentEndDate,
                                            endDateAsParent: init.endDateAsParent,
                                            origAttachmentStartDate: init.origAttachmentStartDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCityField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$6.city, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: init.siret,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: status,
                                            countryCode: init.countryCode,
                                            endDate: init.endDate,
                                            addAttachment: init.addAttachment,
                                            contractID: init.contractID,
                                            contractStartDate: init.contractStartDate,
                                            contractEndDate: init.contractEndDate,
                                            attachmentStartDate: init.attachmentStartDate,
                                            attachmentEndDate: init.attachmentEndDate,
                                            endDateAsParent: init.endDateAsParent,
                                            origAttachmentStartDate: init.origAttachmentStartDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCountryCodeField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$7.countryCode, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: init.siret,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: status,
                                            endDate: init.endDate,
                                            addAttachment: init.addAttachment,
                                            contractID: init.contractID,
                                            contractStartDate: init.contractStartDate,
                                            contractEndDate: init.contractEndDate,
                                            attachmentStartDate: init.attachmentStartDate,
                                            attachmentEndDate: init.attachmentEndDate,
                                            endDateAsParent: init.endDateAsParent,
                                            origAttachmentStartDate: init.origAttachmentStartDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEndDateField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$8, state.fieldsStatuses.endDate, state.submissionStatus, validators_endDate, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: init.siret,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            endDate: status,
                                            addAttachment: init.addAttachment,
                                            contractID: init.contractID,
                                            contractStartDate: init.contractStartDate,
                                            contractEndDate: init.contractEndDate,
                                            attachmentStartDate: init.attachmentStartDate,
                                            attachmentEndDate: init.attachmentEndDate,
                                            endDateAsParent: init.endDateAsParent,
                                            origAttachmentStartDate: init.origAttachmentStartDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAddAttachmentField */9 :
                  var nextInput$9 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$9.addAttachment, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: init.siret,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            endDate: init.endDate,
                                            addAttachment: status,
                                            contractID: init.contractID,
                                            contractStartDate: init.contractStartDate,
                                            contractEndDate: init.contractEndDate,
                                            attachmentStartDate: init.attachmentStartDate,
                                            attachmentEndDate: init.attachmentEndDate,
                                            endDateAsParent: init.endDateAsParent,
                                            origAttachmentStartDate: init.origAttachmentStartDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateContractIDField */10 :
                  var nextInput$10 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$17 = Formality.validateDependentFieldOnChange(nextInput$10, nextFieldsStatuses.contents.attachmentStartDate, validators_attachmentStartDate, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: status,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$17 !== undefined) {
                    nextFieldsStatuses.contents = result$17;
                  }
                  var result$18 = Formality.validateDependentFieldOnChange(nextInput$10, nextFieldsStatuses.contents.attachmentEndDate, validators_attachmentEndDate, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: status,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$18 !== undefined) {
                    nextFieldsStatuses.contents = result$18;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$10, nextFieldsStatuses.contents.contractID, state.submissionStatus, validators_contractID, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            siret: init.siret,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            endDate: init.endDate,
                                            addAttachment: init.addAttachment,
                                            contractID: status,
                                            contractStartDate: init.contractStartDate,
                                            contractEndDate: init.contractEndDate,
                                            attachmentStartDate: init.attachmentStartDate,
                                            attachmentEndDate: init.attachmentEndDate,
                                            endDateAsParent: init.endDateAsParent,
                                            origAttachmentStartDate: init.origAttachmentStartDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateContractStartDateField */11 :
                  var nextInput$11 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$19 = Formality.validateDependentFieldOnChange(nextInput$11, nextFieldsStatuses$1.contents.contractEndDate, validators_contractEndDate, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: status,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$19 !== undefined) {
                    nextFieldsStatuses$1.contents = result$19;
                  }
                  var result$20 = Formality.validateDependentFieldOnChange(nextInput$11, nextFieldsStatuses$1.contents.contractID, validators_contractID, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: status,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$20 !== undefined) {
                    nextFieldsStatuses$1.contents = result$20;
                  }
                  var result$21 = Formality.validateDependentFieldOnChange(nextInput$11, nextFieldsStatuses$1.contents.attachmentStartDate, validators_attachmentStartDate, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: status,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$21 !== undefined) {
                    nextFieldsStatuses$1.contents = result$21;
                  }
                  var result$22 = Formality.validateDependentFieldOnChange(nextInput$11, nextFieldsStatuses$1.contents.attachmentEndDate, validators_attachmentEndDate, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: status,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$22 !== undefined) {
                    nextFieldsStatuses$1.contents = result$22;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$11, nextFieldsStatuses$1.contents.contractStartDate, state.submissionStatus, validators_contractStartDate, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            siret: init.siret,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            endDate: init.endDate,
                                            addAttachment: init.addAttachment,
                                            contractID: init.contractID,
                                            contractStartDate: status,
                                            contractEndDate: init.contractEndDate,
                                            attachmentStartDate: init.attachmentStartDate,
                                            attachmentEndDate: init.attachmentEndDate,
                                            endDateAsParent: init.endDateAsParent,
                                            origAttachmentStartDate: init.origAttachmentStartDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateContractEndDateField */12 :
                  var nextInput$12 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$2 = {
                    contents: state.fieldsStatuses
                  };
                  var result$23 = Formality.validateDependentFieldOnChange(nextInput$12, nextFieldsStatuses$2.contents.contractStartDate, validators_contractStartDate, (function (status) {
                          var init = nextFieldsStatuses$2.contents;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: status,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$23 !== undefined) {
                    nextFieldsStatuses$2.contents = result$23;
                  }
                  var result$24 = Formality.validateDependentFieldOnChange(nextInput$12, nextFieldsStatuses$2.contents.contractID, validators_contractID, (function (status) {
                          var init = nextFieldsStatuses$2.contents;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: status,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$24 !== undefined) {
                    nextFieldsStatuses$2.contents = result$24;
                  }
                  var result$25 = Formality.validateDependentFieldOnChange(nextInput$12, nextFieldsStatuses$2.contents.attachmentStartDate, validators_attachmentStartDate, (function (status) {
                          var init = nextFieldsStatuses$2.contents;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: status,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$25 !== undefined) {
                    nextFieldsStatuses$2.contents = result$25;
                  }
                  var result$26 = Formality.validateDependentFieldOnChange(nextInput$12, nextFieldsStatuses$2.contents.attachmentEndDate, validators_attachmentEndDate, (function (status) {
                          var init = nextFieldsStatuses$2.contents;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: status,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$26 !== undefined) {
                    nextFieldsStatuses$2.contents = result$26;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$12, nextFieldsStatuses$2.contents.contractEndDate, state.submissionStatus, validators_contractEndDate, (function (status) {
                                    var init = nextFieldsStatuses$2.contents;
                                    return {
                                            siret: init.siret,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            endDate: init.endDate,
                                            addAttachment: init.addAttachment,
                                            contractID: init.contractID,
                                            contractStartDate: init.contractStartDate,
                                            contractEndDate: status,
                                            attachmentStartDate: init.attachmentStartDate,
                                            attachmentEndDate: init.attachmentEndDate,
                                            endDateAsParent: init.endDateAsParent,
                                            origAttachmentStartDate: init.origAttachmentStartDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAttachmentStartDateField */13 :
                  var nextInput$13 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$3 = {
                    contents: state.fieldsStatuses
                  };
                  var result$27 = Formality.validateDependentFieldOnChange(nextInput$13, nextFieldsStatuses$3.contents.attachmentEndDate, validators_attachmentEndDate, (function (status) {
                          var init = nextFieldsStatuses$3.contents;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: status,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$27 !== undefined) {
                    nextFieldsStatuses$3.contents = result$27;
                  }
                  var result$28 = Formality.validateDependentFieldOnChange(nextInput$13, nextFieldsStatuses$3.contents.contractStartDate, validators_contractStartDate, (function (status) {
                          var init = nextFieldsStatuses$3.contents;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: status,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$28 !== undefined) {
                    nextFieldsStatuses$3.contents = result$28;
                  }
                  var result$29 = Formality.validateDependentFieldOnChange(nextInput$13, nextFieldsStatuses$3.contents.contractEndDate, validators_contractEndDate, (function (status) {
                          var init = nextFieldsStatuses$3.contents;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: status,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$29 !== undefined) {
                    nextFieldsStatuses$3.contents = result$29;
                  }
                  var result$30 = Formality.validateDependentFieldOnChange(nextInput$13, nextFieldsStatuses$3.contents.contractID, validators_contractID, (function (status) {
                          var init = nextFieldsStatuses$3.contents;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: status,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$30 !== undefined) {
                    nextFieldsStatuses$3.contents = result$30;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$13,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$13, nextFieldsStatuses$3.contents.attachmentStartDate, state.submissionStatus, validators_attachmentStartDate, (function (status) {
                                    var init = nextFieldsStatuses$3.contents;
                                    return {
                                            siret: init.siret,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            endDate: init.endDate,
                                            addAttachment: init.addAttachment,
                                            contractID: init.contractID,
                                            contractStartDate: init.contractStartDate,
                                            contractEndDate: init.contractEndDate,
                                            attachmentStartDate: status,
                                            attachmentEndDate: init.attachmentEndDate,
                                            endDateAsParent: init.endDateAsParent,
                                            origAttachmentStartDate: init.origAttachmentStartDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAttachmentEndDateField */14 :
                  var nextInput$14 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$4 = {
                    contents: state.fieldsStatuses
                  };
                  var result$31 = Formality.validateDependentFieldOnChange(nextInput$14, nextFieldsStatuses$4.contents.attachmentStartDate, validators_attachmentStartDate, (function (status) {
                          var init = nextFieldsStatuses$4.contents;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: status,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$31 !== undefined) {
                    nextFieldsStatuses$4.contents = result$31;
                  }
                  var result$32 = Formality.validateDependentFieldOnChange(nextInput$14, nextFieldsStatuses$4.contents.contractStartDate, validators_contractStartDate, (function (status) {
                          var init = nextFieldsStatuses$4.contents;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: status,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$32 !== undefined) {
                    nextFieldsStatuses$4.contents = result$32;
                  }
                  var result$33 = Formality.validateDependentFieldOnChange(nextInput$14, nextFieldsStatuses$4.contents.contractEndDate, validators_contractEndDate, (function (status) {
                          var init = nextFieldsStatuses$4.contents;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: status,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$33 !== undefined) {
                    nextFieldsStatuses$4.contents = result$33;
                  }
                  var result$34 = Formality.validateDependentFieldOnChange(nextInput$14, nextFieldsStatuses$4.contents.contractID, validators_contractID, (function (status) {
                          var init = nextFieldsStatuses$4.contents;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: status,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$34 !== undefined) {
                    nextFieldsStatuses$4.contents = result$34;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$14,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$14, nextFieldsStatuses$4.contents.attachmentEndDate, state.submissionStatus, validators_attachmentEndDate, (function (status) {
                                    var init = nextFieldsStatuses$4.contents;
                                    return {
                                            siret: init.siret,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            endDate: init.endDate,
                                            addAttachment: init.addAttachment,
                                            contractID: init.contractID,
                                            contractStartDate: init.contractStartDate,
                                            contractEndDate: init.contractEndDate,
                                            attachmentStartDate: init.attachmentStartDate,
                                            attachmentEndDate: status,
                                            endDateAsParent: init.endDateAsParent,
                                            origAttachmentStartDate: init.origAttachmentStartDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEndDateAsParentField */15 :
                  var nextInput$15 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$5 = {
                    contents: state.fieldsStatuses
                  };
                  var result$35 = Formality.validateDependentFieldOnChange(nextInput$15, nextFieldsStatuses$5.contents.attachmentEndDate, validators_attachmentEndDate, (function (status) {
                          var init = nextFieldsStatuses$5.contents;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: init.attachmentStartDate,
                                  attachmentEndDate: status,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$35 !== undefined) {
                    nextFieldsStatuses$5.contents = result$35;
                  }
                  var result$36 = Formality.validateDependentFieldOnChange(nextInput$15, nextFieldsStatuses$5.contents.attachmentStartDate, validators_attachmentStartDate, (function (status) {
                          var init = nextFieldsStatuses$5.contents;
                          return {
                                  siret: init.siret,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  endDate: init.endDate,
                                  addAttachment: init.addAttachment,
                                  contractID: init.contractID,
                                  contractStartDate: init.contractStartDate,
                                  contractEndDate: init.contractEndDate,
                                  attachmentStartDate: status,
                                  attachmentEndDate: init.attachmentEndDate,
                                  endDateAsParent: init.endDateAsParent,
                                  origAttachmentStartDate: init.origAttachmentStartDate
                                };
                        }));
                  if (result$36 !== undefined) {
                    nextFieldsStatuses$5.contents = result$36;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$15,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$15, nextFieldsStatuses$5.contents.endDateAsParent, state.submissionStatus, validators_endDateAsParent, (function (status) {
                                    var init = nextFieldsStatuses$5.contents;
                                    return {
                                            siret: init.siret,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            endDate: init.endDate,
                                            addAttachment: init.addAttachment,
                                            contractID: init.contractID,
                                            contractStartDate: init.contractStartDate,
                                            contractEndDate: init.contractEndDate,
                                            attachmentStartDate: init.attachmentStartDate,
                                            attachmentEndDate: init.attachmentEndDate,
                                            endDateAsParent: status,
                                            origAttachmentStartDate: init.origAttachmentStartDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateOrigAttachmentStartDateField */16 :
                  var nextInput$16 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$16,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$16.origAttachmentStartDate, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: init.siret,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            endDate: init.endDate,
                                            addAttachment: init.addAttachment,
                                            contractID: init.contractID,
                                            contractStartDate: init.contractStartDate,
                                            contractEndDate: init.contractEndDate,
                                            attachmentStartDate: init.attachmentStartDate,
                                            attachmentEndDate: init.attachmentEndDate,
                                            endDateAsParent: init.endDateAsParent,
                                            origAttachmentStartDate: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */17 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */18 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */19 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateSiret: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSiretField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateNameField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateStreet: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateStreetField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateStreetNbr: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateStreetNbrField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateAdditionalAddressInfo: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAdditionalAddressInfoField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateZipCode: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateZipCodeField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCity: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCityField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCountryCode: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCountryCodeField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEndDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEndDateField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateAddAttachment: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAddAttachmentField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateContractID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateContractIDField */10,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateContractStartDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateContractStartDateField */11,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateContractEndDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateContractEndDateField */12,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateAttachmentStartDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAttachmentStartDateField */13,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateAttachmentEndDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAttachmentEndDateField */14,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEndDateAsParent: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEndDateAsParentField */15,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateOrigAttachmentStartDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrigAttachmentStartDateField */16,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurSiret: (function (param) {
              Curry._1(dispatch, /* BlurSiretField */0);
            }),
          blurName: (function (param) {
              Curry._1(dispatch, /* BlurNameField */1);
            }),
          blurStreet: (function (param) {
              Curry._1(dispatch, /* BlurStreetField */2);
            }),
          blurStreetNbr: (function (param) {
              Curry._1(dispatch, /* BlurStreetNbrField */3);
            }),
          blurAdditionalAddressInfo: (function (param) {
              Curry._1(dispatch, /* BlurAdditionalAddressInfoField */4);
            }),
          blurZipCode: (function (param) {
              Curry._1(dispatch, /* BlurZipCodeField */5);
            }),
          blurCity: (function (param) {
              Curry._1(dispatch, /* BlurCityField */6);
            }),
          blurCountryCode: (function (param) {
              Curry._1(dispatch, /* BlurCountryCodeField */7);
            }),
          blurEndDate: (function (param) {
              Curry._1(dispatch, /* BlurEndDateField */8);
            }),
          blurAddAttachment: (function (param) {
              Curry._1(dispatch, /* BlurAddAttachmentField */9);
            }),
          blurContractID: (function (param) {
              Curry._1(dispatch, /* BlurContractIDField */10);
            }),
          blurContractStartDate: (function (param) {
              Curry._1(dispatch, /* BlurContractStartDateField */11);
            }),
          blurContractEndDate: (function (param) {
              Curry._1(dispatch, /* BlurContractEndDateField */12);
            }),
          blurAttachmentStartDate: (function (param) {
              Curry._1(dispatch, /* BlurAttachmentStartDateField */13);
            }),
          blurAttachmentEndDate: (function (param) {
              Curry._1(dispatch, /* BlurAttachmentEndDateField */14);
            }),
          blurEndDateAsParent: (function (param) {
              Curry._1(dispatch, /* BlurEndDateAsParentField */15);
            }),
          blurOrigAttachmentStartDate: (function (param) {
              Curry._1(dispatch, /* BlurOrigAttachmentStartDateField */16);
            }),
          siretResult: Formality.exposeFieldResult(state.fieldsStatuses.siret),
          nameResult: Formality.exposeFieldResult(state.fieldsStatuses.name),
          streetResult: Formality.exposeFieldResult(state.fieldsStatuses.street),
          streetNbrResult: Formality.exposeFieldResult(state.fieldsStatuses.streetNbr),
          additionalAddressInfoResult: Formality.exposeFieldResult(state.fieldsStatuses.additionalAddressInfo),
          zipCodeResult: Formality.exposeFieldResult(state.fieldsStatuses.zipCode),
          cityResult: Formality.exposeFieldResult(state.fieldsStatuses.city),
          countryCodeResult: Formality.exposeFieldResult(state.fieldsStatuses.countryCode),
          endDateResult: Formality.exposeFieldResult(state.fieldsStatuses.endDate),
          addAttachmentResult: Formality.exposeFieldResult(state.fieldsStatuses.addAttachment),
          contractIDResult: Formality.exposeFieldResult(state.fieldsStatuses.contractID),
          contractStartDateResult: Formality.exposeFieldResult(state.fieldsStatuses.contractStartDate),
          contractEndDateResult: Formality.exposeFieldResult(state.fieldsStatuses.contractEndDate),
          attachmentStartDateResult: Formality.exposeFieldResult(state.fieldsStatuses.attachmentStartDate),
          attachmentEndDateResult: Formality.exposeFieldResult(state.fieldsStatuses.attachmentEndDate),
          endDateAsParentResult: Formality.exposeFieldResult(state.fieldsStatuses.endDateAsParent),
          origAttachmentStartDateResult: Formality.exposeFieldResult(state.fieldsStatuses.origAttachmentStartDate),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              var exit = 0;
              if (match.siret || match.name || match.street || match.streetNbr || match.additionalAddressInfo || match.zipCode || match.city || match.countryCode || match.endDate || match.addAttachment || match.contractID || match.contractStartDate || match.contractEndDate || match.attachmentStartDate || match.attachmentEndDate || match.endDateAsParent) {
                exit = 1;
              } else {
                if (!match.origAttachmentStartDate) {
                  return false;
                }
                exit = 1;
              }
              if (exit === 1) {
                var exit$1 = 0;
                exit$1 = 2;
                if (exit$1 === 2) {
                  var exit$2 = 0;
                  exit$2 = 3;
                  if (exit$2 === 3) {
                    var exit$3 = 0;
                    exit$3 = 4;
                    if (exit$3 === 4) {
                      var exit$4 = 0;
                      exit$4 = 5;
                      if (exit$4 === 5) {
                        var exit$5 = 0;
                        exit$5 = 6;
                        if (exit$5 === 6) {
                          return true;
                        }
                        
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */17);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */18);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */19);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */19,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */20);
            })
        };
}

var Form = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialInput = {
  siret: "",
  name: "",
  street: "",
  streetNbr: "",
  additionalAddressInfo: "",
  zipCode: "",
  city: "",
  countryCode: "",
  endDate: undefined,
  addAttachment: false,
  contractID: "",
  contractStartDate: undefined,
  contractEndDate: undefined,
  attachmentStartDate: undefined,
  attachmentEndDate: undefined,
  endDateAsParent: true,
  origAttachmentStartDate: undefined
};

export {
  Form ,
  initialInput ,
}
/* react Not a pure module */
