// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Flash_Publish_Api$DvmAdminFrontendFr from "./Flash_Publish_Api.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Flash_Publish$DvmAdminFrontendFr from "../../../intl/messages/flash/Messages_Flash_Publish.bs.js";

function Flash_Publish_Export_Modal(Props) {
  var onClose = Props.onClose;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$2[1];
  var result = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setPublishStateResult = match$3[1];
  var publishStateResult = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var setIsRunning = match$4[1];
  var isRunning = match$4[0];
  var alert = ReactAlert.useAlert();
  React.useEffect((function () {
          Flash_Publish_Api$DvmAdminFrontendFr.fetchPublishState(userManager, setPublishStateResult, selectedRole);
        }), []);
  React.useEffect((function () {
          if (typeof result === "number") {
            result === /* NotStarted */0;
          } else if (result.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(onClose, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: result._0
                      }
                    }));
          }
        }), [result]);
  React.useEffect((function () {
          if (typeof publishStateResult === "number") {
            publishStateResult === /* NotStarted */0;
          } else if (publishStateResult.TAG === /* Ok */0) {
            if (Belt_List.length(Belt_List.keep(publishStateResult._0, (function (x) {
                          return Belt_Option.getWithDefault(x.jobStatus, "") === "RUNNING";
                        }))) > 0) {
              Curry._1(setIsRunning, (function (param) {
                      return true;
                    }));
            }
            
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: publishStateResult._0
                      }
                    }));
          }
        }), [publishStateResult]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Flash_Publish$DvmAdminFrontendFr.actionPublishTitle),
              minWidth: "450px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(result) || App_Types_Result$DvmAdminFrontendFr.isPending(publishStateResult),
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          direction: "column",
                          item: true,
                          spacing: 1
                        }, isRunning ? React.createElement(Core.Grid, {
                                children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                      gutterBottom: true,
                                      children: intl.formatMessage(Messages_Flash_Publish$DvmAdminFrontendFr.publishRunning),
                                      variant: "h4"
                                    }),
                                item: true
                              }) : null, React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              item: true,
                              justifyContent: "space-between"
                            }, React.createElement(Core.Grid, {
                                  children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                        onClick: (function (param) {
                                            Curry._1(onClose, undefined);
                                          }),
                                        label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.cancel),
                                        color: "primaryBorder",
                                        size: "medium"
                                      }),
                                  item: true
                                }), React.createElement(Core.Grid, {
                                  children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                        onClick: (function (param) {
                                            Flash_Publish_Api$DvmAdminFrontendFr.runFlashPublish(userManager, setResult, selectedRole);
                                          }),
                                        label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                        size: "medium",
                                        disabled: isRunning
                                      }),
                                  item: true
                                })))
                  })
            });
}

var Api;

var Types;

var make = Flash_Publish_Export_Modal;

export {
  Api ,
  Types ,
  make ,
}
/* react Not a pure module */
