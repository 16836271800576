// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as Grid_Edit_Actions_Api$DvmAdminFrontendFr from "./Grid_Edit_Actions_Api.bs.js";

function Grid_Edit_Delete_Modal(Props) {
  var tableResult = Props.tableResult;
  var setResult = Props.setResult;
  var onClose = Props.onClose;
  var whatTable = Props.whatTable;
  var whatGrid = Props.whatGrid;
  var budget = Props.budget;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var $$delete = function (param) {
    Belt_Option.mapWithDefault(tableResult, undefined, (function (param) {
            return Grid_Edit_Actions_Api$DvmAdminFrontendFr.editOrDeleteMarginRows(userManager, setResult, whatTable, whatGrid, "DELETE", budget, selectedRole, param);
          }));
  };
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.reallyDelete),
              minWidth: "450px",
              children: React.createElement(Core.Grid, {
                    children: null,
                    container: true,
                    justifyContent: "space-between"
                  }, React.createElement(Core.Grid, {
                        children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                              onClick: (function (param) {
                                  Curry._1(onClose, undefined);
                                }),
                              label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.cancel),
                              color: "primaryBorder",
                              size: "medium"
                            }),
                        item: true
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                              onClick: (function (param) {
                                  $$delete(undefined);
                                  Curry._1(onClose, undefined);
                                }),
                              label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.$$delete),
                              color: "delete",
                              size: "medium"
                            }),
                        item: true
                      }))
            });
}

var Api;

var Types;

var make = Grid_Edit_Delete_Modal;

export {
  Api ,
  Types ,
  make ,
}
/* react Not a pure module */
