// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";

function formatPercent(intl, val) {
  return intl.formatNumber(val / 100.0, {
              style: "percent",
              minimumFractionDigits: 2
            });
}

function formatCurrency(intl, minimumFractionDigitsOpt, val) {
  var minimumFractionDigits = minimumFractionDigitsOpt !== undefined ? minimumFractionDigitsOpt : 2;
  return intl.formatNumber(val, {
              style: "currency",
              currency: "EUR",
              currencyDisplay: "symbol",
              minimumFractionDigits: minimumFractionDigits
            });
}

function formatDate(intl, date) {
  return intl.formatDate(date);
}

function formatDateShort(intl, date) {
  return intl.formatDate(date, {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit"
            });
}

function formatDateWithTime(intl, hoursOpt, minutesOpt, secondsOpt, date) {
  var hours = hoursOpt !== undefined ? hoursOpt : true;
  var minutes = minutesOpt !== undefined ? minutesOpt : true;
  var seconds = secondsOpt !== undefined ? secondsOpt : false;
  var tmp = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  };
  var tmp$1 = hours ? "numeric" : undefined;
  if (tmp$1 !== undefined) {
    tmp.hour = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = minutes ? "numeric" : undefined;
  if (tmp$2 !== undefined) {
    tmp.minute = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = seconds ? "numeric" : undefined;
  if (tmp$3 !== undefined) {
    tmp.second = Caml_option.valFromOption(tmp$3);
  }
  return intl.formatDate(date, tmp);
}

function formatStringDate(intl, date) {
  return intl.formatDate(new Date(date));
}

var defaultTimeZone = "Europe/Paris";

export {
  defaultTimeZone ,
  formatPercent ,
  formatCurrency ,
  formatDate ,
  formatDateShort ,
  formatDateWithTime ,
  formatStringDate ,
}
/* No side effect */
