// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "@emotion/css";

var detailsPaper = Css.css({
      minHeight: "180px",
      padding: "17px"
    });

export {
  detailsPaper ,
}
/* detailsPaper Not a pure module */
