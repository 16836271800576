// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as Flash_Detail_Api$DvmAdminFrontendFr from "../../Flash_Detail_Api.bs.js";
import * as Flash_Detail_Table_Types$DvmAdminFrontendFr from "../Flash_Detail_Table_Types.bs.js";
import * as Flash_Detail_Models_Table_NonFleet$DvmAdminFrontendFr from "./Flash_Detail_Models_Table_NonFleet.bs.js";
import * as Flash_Detail_Models_Filter_NonFleet$DvmAdminFrontendFr from "./Flash_Detail_Models_Filter_NonFleet.bs.js";

function comparatorStr(header, first, second) {
  var match = header.order;
  switch (match) {
    case /* Asc */0 :
        return Caml.string_compare(first, second);
    case /* Desc */1 :
        return Caml.string_compare(second, first);
    case /* NotSet */2 :
        return 0;
    
  }
}

function comparatorDate(header, first, second) {
  var firstDate = Belt_Option.getWithDefault(first, new Date(0.0));
  var secondDate = Belt_Option.getWithDefault(second, new Date(0.0));
  var match = header.order;
  switch (match) {
    case /* Asc */0 :
        return ReDate.compareAsc(firstDate, secondDate);
    case /* Desc */1 :
        return ReDate.compareAsc(secondDate, firstDate);
    case /* NotSet */2 :
        return 0;
    
  }
}

function Flash_Detail_Models_Page_NonFleet(Props) {
  var flashId = Props.flashId;
  var flash = Props.flash;
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match$2[1];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$3[1];
  var result = match$3[0];
  var match$4 = React.useState(function () {
        return {
                page: 0,
                headers: Flash_Detail_Models_Table_NonFleet$DvmAdminFrontendFr.headersConfiguration(flash),
                brand: undefined,
                model: undefined,
                version: undefined,
                versionLabel: undefined
              };
      });
  var setTableHandler = match$4[1];
  var tableHandler = match$4[0];
  var match$5 = React.useState(function () {
        return false;
      });
  var filterPagingAndSort = function (tableHandler) {
    Curry._1(setTablePage, (function (param) {
            return App_Types_Result$DvmAdminFrontendFr.mapWithDefault(result, /* Pending */1, (function (list) {
                          var filteredList = Belt_List.keep(list, (function (x) {
                                  if (Belt_Option.mapWithDefault(tableHandler.brand, true, (function (brand) {
                                            return Belt_Option.getWithDefault(x.brandDescription, "") === brand;
                                          })) && Belt_Option.mapWithDefault(tableHandler.model, true, (function (model) {
                                            return x.model === model;
                                          })) && Belt_Option.mapWithDefault(tableHandler.version, true, (function (version) {
                                            return x.version === version;
                                          }))) {
                                    return Belt_Option.mapWithDefault(tableHandler.versionLabel, true, (function (versionLabel) {
                                                  return Belt_Option.getWithDefault(x.versionLabel, "").toLowerCase().includes(versionLabel.toLowerCase());
                                                }));
                                  } else {
                                    return false;
                                  }
                                }));
                          var sortedList = Belt_List.sort(filteredList, (function (a, b) {
                                  return Belt_List.reduce(tableHandler.headers, 0, (function (comparatorResult, header) {
                                                if (!header.sort) {
                                                  return comparatorResult;
                                                }
                                                var idheader = header.idheader;
                                                if (idheader === Flash_Detail_Table_Types$DvmAdminFrontendFr.Fields.model) {
                                                  return comparatorResult + Math.imul(comparatorStr(header, a.model, b.model), 5) | 0;
                                                } else if (idheader === Flash_Detail_Table_Types$DvmAdminFrontendFr.Fields.version) {
                                                  return comparatorResult + (comparatorStr(header, a.version, b.version) << 2) | 0;
                                                } else if (idheader === Flash_Detail_Table_Types$DvmAdminFrontendFr.Fields.versionLabel) {
                                                  return comparatorResult + Math.imul(comparatorStr(header, Belt_Option.getWithDefault(a.versionLabel, ""), Belt_Option.getWithDefault(b.versionLabel, "")), 3) | 0;
                                                } else if (idheader === Flash_Detail_Table_Types$DvmAdminFrontendFr.Fields.startDate) {
                                                  return comparatorResult + (comparatorDate(header, a.startDate, b.startDate) << 1) | 0;
                                                } else if (idheader === Flash_Detail_Table_Types$DvmAdminFrontendFr.Fields.endDate) {
                                                  return comparatorResult + (comparatorDate(header, a.endDate, b.endDate) << 0) | 0;
                                                } else {
                                                  return comparatorResult;
                                                }
                                              }));
                                }));
                          var paged = Belt_List.keepWithIndex(sortedList, (function (param, index) {
                                  if (index >= Math.imul(tableHandler.page, 10)) {
                                    return index < Math.imul(tableHandler.page + 1 | 0, 10);
                                  } else {
                                    return false;
                                  }
                                }));
                          return {
                                  TAG: /* Ok */0,
                                  _0: {
                                    content: paged,
                                    total: Belt_List.length(filteredList)
                                  }
                                };
                        }));
          }));
    Curry._1(setTableHandler, (function (param) {
            return tableHandler;
          }));
  };
  React.useEffect((function () {
          if (typeof result !== "number" && result.TAG === /* Ok */0) {
            filterPagingAndSort(tableHandler);
          }
          
        }), [result]);
  React.useEffect((function () {
          Flash_Detail_Api$DvmAdminFrontendFr.fetchModelsTableNonFleet(userManager, setResult, selectedRole, flashId, flash);
        }), []);
  return React.createElement(React.Fragment, undefined, React.createElement(Flash_Detail_Models_Filter_NonFleet$DvmAdminFrontendFr.make, {
                  tableHandler: tableHandler,
                  filterPagingAndSort: filterPagingAndSort,
                  withClosedModels: match$5[0],
                  setWithClosedModels: match$5[1],
                  result: result
                }), React.createElement(Flash_Detail_Models_Table_NonFleet$DvmAdminFrontendFr.make, {
                  tablePage: match$2[0],
                  tableHandler: tableHandler,
                  filterPagingAndSort: filterPagingAndSort,
                  flash: flash
                }));
}

var Api;

var Table;

var Filter;

var Types;

var make = Flash_Detail_Models_Page_NonFleet;

export {
  Api ,
  Table ,
  Filter ,
  Types ,
  comparatorStr ,
  comparatorDate ,
  make ,
}
/* react Not a pure module */
