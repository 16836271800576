// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as Bonuses_Manage_Types$DvmAdminFrontendFr from "./Bonuses_Manage_Types.bs.js";
import * as Messages_Bonuses_Manage$DvmAdminFrontendFr from "../../../intl/messages/bonuses/Messages_Bonuses_Manage.bs.js";

function changeBonus(userManager, setResult, bonuses, operation, intl, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var tmp;
  switch (operation) {
    case /* Validate */0 :
        tmp = "/bonuses/manual/perf/status?newStatus=VALIDATED";
        break;
    case /* Refuse */1 :
        tmp = "/bonuses/manual/perf/status?newStatus=REFUSED";
        break;
    case /* ToValidate */2 :
        tmp = "/bonuses/manual/perf/status?newStatus=TOVALIDATE";
        break;
    case /* ChangeAmount */3 :
        tmp = "/bonuses/manual/perf/amount";
        break;
    
  }
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("PUT", {
          TAG: /* Json */0,
          _0: Bonuses_Manage_Types$DvmAdminFrontendFr.editedBonusList_encode(bonuses)
        }, userManager, selectedRole, tmp).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        var tmp;
                        switch (operation) {
                          case /* Validate */0 :
                              tmp = Messages_Bonuses_Manage$DvmAdminFrontendFr.validateSuccess;
                              break;
                          case /* Refuse */1 :
                              tmp = Messages_Bonuses_Manage$DvmAdminFrontendFr.refusedSuccess;
                              break;
                          case /* ToValidate */2 :
                              tmp = Messages_Bonuses_Manage$DvmAdminFrontendFr.backToValidateSuccess;
                              break;
                          case /* ChangeAmount */3 :
                              tmp = Messages_Bonuses_Manage$DvmAdminFrontendFr.amountChangeSuccess;
                              break;
                          
                        }
                        return {
                                TAG: /* Ok */0,
                                _0: intl.formatMessage(tmp)
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function changeBonusAmount(userManager, setResult, bonuses, intl, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("PUT", {
          TAG: /* Json */0,
          _0: Bonuses_Manage_Types$DvmAdminFrontendFr.editedBonus_encode(bonuses)
        }, userManager, selectedRole, "/bonuses/manual/perf/amount").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: intl.formatMessage(Messages_Bonuses_Manage$DvmAdminFrontendFr.amountChangeSuccess)
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

export {
  changeBonus ,
  changeBonusAmount ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
