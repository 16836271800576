// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../../../common/components/icons/App_Icon.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../../../../context/user/User_Types_Utils.bs.js";
import * as Order_Detail_Bonus_Derogate_Delete$DvmAdminFrontendFr from "../delete/Order_Detail_Bonus_Derogate_Delete.bs.js";

function Order_Detail_Bonus_Derogate_Edit_Buttons(Props) {
  var dero = Props.dero;
  var order = Props.order;
  var orderId = Props.orderId;
  var reloadPage = Props.reloadPage;
  var openEditModal = Props.openEditModal;
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = React.useState(function () {
        return null;
      });
  var setModal = match$1[1];
  return React.createElement(React.Fragment, undefined, Belt_Option.mapWithDefault(dero.state, true, (function (x) {
                    if (x !== /* PAID */4 && x !== /* NOBONUS */3) {
                      return x !== /* PREINVOICED */5;
                    } else {
                      return false;
                    }
                  })) && !Belt_Option.getWithDefault(order.archived, true) && User_Types_Utils$DvmAdminFrontendFr.isAdmin(match[0].selectedRole) ? React.createElement(Core.Grid, {
                    children: null,
                    container: true,
                    direction: "column",
                    spacing: 2,
                    style: {
                      left: "50px",
                      position: "absolute",
                      width: "min-content"
                    }
                  }, React.createElement(Core.Grid, {
                        children: React.createElement(Core.IconButton, {
                              onClick: openEditModal,
                              children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                    size: /* Large */3,
                                    type_: /* Edit */60
                                  })
                            }),
                        item: true
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(Core.IconButton, {
                              onClick: (function (param) {
                                  Curry._1(setModal, (function (param) {
                                          return React.createElement(Order_Detail_Bonus_Derogate_Delete$DvmAdminFrontendFr.make, {
                                                      onClose: (function (param) {
                                                          Curry._1(setModal, (function (param) {
                                                                  return null;
                                                                }));
                                                        }),
                                                      orderId: orderId,
                                                      dero: dero,
                                                      reloadPage: reloadPage
                                                    });
                                        }));
                                }),
                              children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                    size: /* Large */3,
                                    type_: /* Trash */38,
                                    color: "red"
                                  })
                            }),
                        item: true
                      })) : null, match$1[0]);
}

var make = Order_Detail_Bonus_Derogate_Edit_Buttons;

export {
  make ,
}
/* react Not a pure module */
