// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../common/utils/Utils_Api.bs.js";
import * as Grid_Types$DvmAdminFrontendFr from "../../Grid_Types.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../../../common/utils/Utils_Date.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../common/utils/Utils_Form.bs.js";

var validators_year = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.year, undefined), (function (date) {
                    var date$1 = Utils_Date$DvmAdminFrontendFr.toYearString(date);
                    if (date$1 === undefined) {
                      return ;
                    }
                    if (date$1.length === 0) {
                      return ;
                    }
                    var start = "" + Grid_Types$DvmAdminFrontendFr.Fields.startYear + "=%5B%5C," + date$1 + "%5D";
                    var end = "" + Grid_Types$DvmAdminFrontendFr.Fields.endYear + "=%5B" + date$1 + "%5C,%5D;" + Grid_Types$DvmAdminFrontendFr.Fields.endYear + "=NULL";
                    return "" + start + ";" + end + "";
                  }));
    })
};

var validators_discount = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Grid_Types$DvmAdminFrontendFr.Fields.discount,
                    value: param.discount
                  })
            };
    })
};

var validators_categoryName = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(undefined, {
                    fieldId: Grid_Types$DvmAdminFrontendFr.Fields.categoryName,
                    value: param.categoryName
                  })
            };
    })
};

var validators_categoryID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Grid_Types$DvmAdminFrontendFr.Fields.categoryID,
                    value: param.categoryID
                  })
            };
    })
};

var validators_avsMarginGridID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Grid_Types$DvmAdminFrontendFr.Fields.avsMarginGridID,
                    value: param.avsMarginGridID
                  })
            };
    })
};

var validators_version = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Grid_Types$DvmAdminFrontendFr.Fields.version,
                    value: param.version
                  })
            };
    })
};

var validators_model = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Grid_Types$DvmAdminFrontendFr.Fields.model,
                    value: param.model
                  })
            };
    })
};

var validators_budget = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(Belt_Option.mapWithDefault(Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                          fieldId: Grid_Types$DvmAdminFrontendFr.Fields.budget,
                          value: param.budget
                        }), "", (function (x) {
                        return x;
                      })));
    })
};

var validators = {
  year: validators_year,
  discount: validators_discount,
  categoryName: validators_categoryName,
  categoryID: validators_categoryID,
  avsMarginGridID: validators_avsMarginGridID,
  version: validators_version,
  model: validators_model,
  budget: validators_budget
};

function initialFieldsStatuses(_input) {
  return {
          year: /* Pristine */0,
          discount: /* Pristine */0,
          categoryName: /* Pristine */0,
          categoryID: /* Pristine */0,
          avsMarginGridID: /* Pristine */0,
          version: /* Pristine */0,
          model: /* Pristine */0,
          budget: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.year;
  var match_0 = match ? match._0 : Curry._1(validators.year.validate, input);
  var match$1 = fieldsStatuses.discount;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.discount.validate, input);
  var match$2 = fieldsStatuses.categoryName;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.categoryName.validate, input);
  var match$3 = fieldsStatuses.categoryID;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.categoryID.validate, input);
  var match$4 = fieldsStatuses.avsMarginGridID;
  var match_0$4 = match$4 ? match$4._0 : Curry._1(validators.avsMarginGridID.validate, input);
  var match$5 = fieldsStatuses.version;
  var match_0$5 = match$5 ? match$5._0 : Curry._1(validators.version.validate, input);
  var match$6 = fieldsStatuses.model;
  var match_0$6 = match$6 ? match$6._0 : Curry._1(validators.model.validate, input);
  var match$7 = fieldsStatuses.budget;
  var match_0$7 = match$7 ? match$7._0 : Curry._1(validators.budget.validate, input);
  var yearResult = match_0;
  var yearResult$1;
  if (yearResult.TAG === /* Ok */0) {
    var discountResult = match_0$1;
    if (discountResult.TAG === /* Ok */0) {
      var categoryNameResult = match_0$2;
      if (categoryNameResult.TAG === /* Ok */0) {
        var categoryIDResult = match_0$3;
        if (categoryIDResult.TAG === /* Ok */0) {
          var avsMarginGridIDResult = match_0$4;
          if (avsMarginGridIDResult.TAG === /* Ok */0) {
            var versionResult = match_0$5;
            if (versionResult.TAG === /* Ok */0) {
              var modelResult = match_0$6;
              if (modelResult.TAG === /* Ok */0) {
                var budgetResult = match_0$7;
                if (budgetResult.TAG === /* Ok */0) {
                  return {
                          TAG: /* Valid */0,
                          output: {
                            budget: budgetResult._0,
                            model: modelResult._0,
                            version: versionResult._0,
                            avsMarginGridID: avsMarginGridIDResult._0,
                            categoryID: categoryIDResult._0,
                            categoryName: categoryNameResult._0,
                            discount: discountResult._0,
                            year: yearResult._0
                          },
                          fieldsStatuses: {
                            year: /* Dirty */{
                              _0: yearResult,
                              _1: /* Shown */0
                            },
                            discount: /* Dirty */{
                              _0: discountResult,
                              _1: /* Shown */0
                            },
                            categoryName: /* Dirty */{
                              _0: categoryNameResult,
                              _1: /* Shown */0
                            },
                            categoryID: /* Dirty */{
                              _0: categoryIDResult,
                              _1: /* Shown */0
                            },
                            avsMarginGridID: /* Dirty */{
                              _0: avsMarginGridIDResult,
                              _1: /* Shown */0
                            },
                            version: /* Dirty */{
                              _0: versionResult,
                              _1: /* Shown */0
                            },
                            model: /* Dirty */{
                              _0: modelResult,
                              _1: /* Shown */0
                            },
                            budget: /* Dirty */{
                              _0: budgetResult,
                              _1: /* Shown */0
                            }
                          },
                          collectionsStatuses: undefined
                        };
                }
                yearResult$1 = yearResult;
              } else {
                yearResult$1 = yearResult;
              }
            } else {
              yearResult$1 = yearResult;
            }
          } else {
            yearResult$1 = yearResult;
          }
        } else {
          yearResult$1 = yearResult;
        }
      } else {
        yearResult$1 = yearResult;
      }
    } else {
      yearResult$1 = yearResult;
    }
  } else {
    yearResult$1 = yearResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            year: /* Dirty */{
              _0: yearResult$1,
              _1: /* Shown */0
            },
            discount: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            categoryName: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            categoryID: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            avsMarginGridID: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            version: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            model: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            budget: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurYearField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.year, validators_year, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  year: status,
                                  discount: init.discount,
                                  categoryName: init.categoryName,
                                  categoryID: init.categoryID,
                                  avsMarginGridID: init.avsMarginGridID,
                                  version: init.version,
                                  model: init.model,
                                  budget: init.budget
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDiscountField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.discount, validators_discount, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  year: init.year,
                                  discount: status,
                                  categoryName: init.categoryName,
                                  categoryID: init.categoryID,
                                  avsMarginGridID: init.avsMarginGridID,
                                  version: init.version,
                                  model: init.model,
                                  budget: init.budget
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCategoryNameField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.categoryName, validators_categoryName, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  year: init.year,
                                  discount: init.discount,
                                  categoryName: status,
                                  categoryID: init.categoryID,
                                  avsMarginGridID: init.avsMarginGridID,
                                  version: init.version,
                                  model: init.model,
                                  budget: init.budget
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCategoryIDField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.categoryID, validators_categoryID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  year: init.year,
                                  discount: init.discount,
                                  categoryName: init.categoryName,
                                  categoryID: status,
                                  avsMarginGridID: init.avsMarginGridID,
                                  version: init.version,
                                  model: init.model,
                                  budget: init.budget
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAvsMarginGridIDField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.avsMarginGridID, validators_avsMarginGridID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  year: init.year,
                                  discount: init.discount,
                                  categoryName: init.categoryName,
                                  categoryID: init.categoryID,
                                  avsMarginGridID: status,
                                  version: init.version,
                                  model: init.model,
                                  budget: init.budget
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.version, validators_version, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  year: init.year,
                                  discount: init.discount,
                                  categoryName: init.categoryName,
                                  categoryID: init.categoryID,
                                  avsMarginGridID: init.avsMarginGridID,
                                  version: status,
                                  model: init.model,
                                  budget: init.budget
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurModelField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.model, validators_model, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  year: init.year,
                                  discount: init.discount,
                                  categoryName: init.categoryName,
                                  categoryID: init.categoryID,
                                  avsMarginGridID: init.avsMarginGridID,
                                  version: init.version,
                                  model: status,
                                  budget: init.budget
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBudgetField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.budget, validators_budget, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  year: init.year,
                                  discount: init.discount,
                                  categoryName: init.categoryName,
                                  categoryID: init.categoryID,
                                  avsMarginGridID: init.avsMarginGridID,
                                  version: init.version,
                                  model: init.model,
                                  budget: status
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */8 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */8,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */9,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */11);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */10);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */9 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */10 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */11 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateYearField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.year, state.submissionStatus, validators_year, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            year: status,
                                            discount: init.discount,
                                            categoryName: init.categoryName,
                                            categoryID: init.categoryID,
                                            avsMarginGridID: init.avsMarginGridID,
                                            version: init.version,
                                            model: init.model,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDiscountField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.discount, state.submissionStatus, validators_discount, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            year: init.year,
                                            discount: status,
                                            categoryName: init.categoryName,
                                            categoryID: init.categoryID,
                                            avsMarginGridID: init.avsMarginGridID,
                                            version: init.version,
                                            model: init.model,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCategoryNameField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.categoryName, state.submissionStatus, validators_categoryName, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            year: init.year,
                                            discount: init.discount,
                                            categoryName: status,
                                            categoryID: init.categoryID,
                                            avsMarginGridID: init.avsMarginGridID,
                                            version: init.version,
                                            model: init.model,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCategoryIDField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.categoryID, state.submissionStatus, validators_categoryID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            year: init.year,
                                            discount: init.discount,
                                            categoryName: init.categoryName,
                                            categoryID: status,
                                            avsMarginGridID: init.avsMarginGridID,
                                            version: init.version,
                                            model: init.model,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAvsMarginGridIDField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.avsMarginGridID, state.submissionStatus, validators_avsMarginGridID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            year: init.year,
                                            discount: init.discount,
                                            categoryName: init.categoryName,
                                            categoryID: init.categoryID,
                                            avsMarginGridID: status,
                                            version: init.version,
                                            model: init.model,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.version, state.submissionStatus, validators_version, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            year: init.year,
                                            discount: init.discount,
                                            categoryName: init.categoryName,
                                            categoryID: init.categoryID,
                                            avsMarginGridID: init.avsMarginGridID,
                                            version: status,
                                            model: init.model,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateModelField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.model, state.submissionStatus, validators_model, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            year: init.year,
                                            discount: init.discount,
                                            categoryName: init.categoryName,
                                            categoryID: init.categoryID,
                                            avsMarginGridID: init.avsMarginGridID,
                                            version: init.version,
                                            model: status,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBudgetField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$7, state.fieldsStatuses.budget, state.submissionStatus, validators_budget, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            year: init.year,
                                            discount: init.discount,
                                            categoryName: init.categoryName,
                                            categoryID: init.categoryID,
                                            avsMarginGridID: init.avsMarginGridID,
                                            version: init.version,
                                            model: init.model,
                                            budget: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */8 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */9 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */10 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateYear: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateYearField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDiscount: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDiscountField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCategoryName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCategoryNameField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCategoryID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCategoryIDField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateAvsMarginGridID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAvsMarginGridIDField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVersion: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateModel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBudget: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBudgetField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurYear: (function (param) {
              Curry._1(dispatch, /* BlurYearField */0);
            }),
          blurDiscount: (function (param) {
              Curry._1(dispatch, /* BlurDiscountField */1);
            }),
          blurCategoryName: (function (param) {
              Curry._1(dispatch, /* BlurCategoryNameField */2);
            }),
          blurCategoryID: (function (param) {
              Curry._1(dispatch, /* BlurCategoryIDField */3);
            }),
          blurAvsMarginGridID: (function (param) {
              Curry._1(dispatch, /* BlurAvsMarginGridIDField */4);
            }),
          blurVersion: (function (param) {
              Curry._1(dispatch, /* BlurVersionField */5);
            }),
          blurModel: (function (param) {
              Curry._1(dispatch, /* BlurModelField */6);
            }),
          blurBudget: (function (param) {
              Curry._1(dispatch, /* BlurBudgetField */7);
            }),
          yearResult: Formality.exposeFieldResult(state.fieldsStatuses.year),
          discountResult: Formality.exposeFieldResult(state.fieldsStatuses.discount),
          categoryNameResult: Formality.exposeFieldResult(state.fieldsStatuses.categoryName),
          categoryIDResult: Formality.exposeFieldResult(state.fieldsStatuses.categoryID),
          avsMarginGridIDResult: Formality.exposeFieldResult(state.fieldsStatuses.avsMarginGridID),
          versionResult: Formality.exposeFieldResult(state.fieldsStatuses.version),
          modelResult: Formality.exposeFieldResult(state.fieldsStatuses.model),
          budgetResult: Formality.exposeFieldResult(state.fieldsStatuses.budget),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.year || match.discount || match.categoryName || match.categoryID || match.avsMarginGridID || match.version || match.model || match.budget) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */8);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */9);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */10);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */10,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */11);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  budget: "",
  model: "",
  version: "",
  avsMarginGridID: "",
  categoryID: "",
  categoryName: "",
  discount: "",
  year: undefined
};

export {
  TableFilterForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
