// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as App_Table$DvmAdminFrontendFr from "../../../../common/components/table/App_Table.bs.js";
import * as Grid_Types$DvmAdminFrontendFr from "../../Grid_Types.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Head.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as Messages_MarginGrid$DvmAdminFrontendFr from "../../../../intl/messages/marginGrid/Messages_MarginGrid.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Pagination.bs.js";
import * as Messages_ModelVersionGrid$DvmAdminFrontendFr from "../../../../intl/messages/modelVersionGrid/Messages_ModelVersionGrid.bs.js";
import * as Grid_Edit_Modal_Collection_Row$DvmAdminFrontendFr from "./row/Grid_Edit_Modal_Collection_Row.bs.js";

function getHeadersConfiguration(tableType, whatGrid, budget) {
  var displayAcquisition = whatGrid === /* ModelVersionGrid */0 && (budget === "LCD" || budget === "PROT") ? App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.acquisitionId, undefined, undefined, undefined, undefined, undefined, Messages_MarginGrid$DvmAdminFrontendFr.acquisitionId) : undefined;
  var modelList = Belt_List.keepMap({
        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.model, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.model),
        tl: {
          hd: displayAcquisition,
          tl: {
            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.discount, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.discount),
            tl: /* [] */0
          }
        }
      }, (function (x) {
          return x;
        }));
  var versionList = Belt_List.keepMap({
        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.model, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.model),
        tl: {
          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.version, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.version),
          tl: {
            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.categoryName, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.categoryLabel),
            tl: {
              hd: displayAcquisition,
              tl: {
                hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.discount, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.discount),
                tl: /* [] */0
              }
            }
          }
        }
      }, (function (x) {
          return x;
        }));
  var tailList = Belt_List.keepMap({
        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.previousEndDate, undefined, false, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.previousEndDate),
        tl: {
          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.startDate, /* Desc */1, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.startDate),
          tl: {
            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.endDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.endDate),
            tl: {
              hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.followingStartDate, undefined, false, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.followingStartDate),
              tl: {
                hd: whatGrid === /* ModelVersionGrid */0 && tableType === /* Model */0 ? App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.categoryName, undefined, undefined, undefined, undefined, undefined, Messages_ModelVersionGrid$DvmAdminFrontendFr.versionException) : undefined,
                tl: /* [] */0
              }
            }
          }
        }
      }, (function (x) {
          return x;
        }));
  var tmp;
  if (whatGrid) {
    var tmp$1;
    switch (tableType) {
      case /* Model */0 :
          tmp$1 = modelList;
          break;
      case /* Version */1 :
          tmp$1 = versionList;
          break;
      case /* GridID */2 :
          tmp$1 = {
            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.categoryID, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.categoryCode),
            tl: {
              hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.discount, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.discount),
              tl: /* [] */0
            }
          };
          break;
      
    }
    tmp = Belt_List.concat(tmp$1, {
          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Grid_Types$DvmAdminFrontendFr.Fields.margin, undefined, undefined, undefined, undefined, undefined, Messages_MarginGrid$DvmAdminFrontendFr.margin),
          tl: /* [] */0
        });
  } else {
    switch (tableType) {
      case /* Model */0 :
          tmp = modelList;
          break;
      case /* Version */1 :
          tmp = versionList;
          break;
      case /* GridID */2 :
          tmp = /* [] */0;
          break;
      
    }
  }
  return Belt_List.concat(tmp, tailList);
}

function Grid_Edit_Modal_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var setResult = Props.setResult;
  var form = Props.form;
  var isAllEdited = Props.isAllEdited;
  var whatTable = Props.whatTable;
  var setActionsPending = Props.setActionsPending;
  var whatGrid = Props.whatGrid;
  var budget = Props.budget;
  var acquisitionOptions = Props.acquisitionOptions;
  var budgetOrID = Props.budgetOrID;
  var filterStartDate = Props.filterStartDate;
  var filterEndDate = Props.filterEndDate;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return null;
      });
  var setModal = match[1];
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.mapWithIndex(param.content, (function (index, tableRow) {
                                  return Grid_Edit_Modal_Collection_Row$DvmAdminFrontendFr.makeRow(form, index, tableRow, setModal, setResult, intl, isAllEdited, whatTable, whatGrid, setActionsPending, budget, acquisitionOptions, budgetOrID, filterStartDate, filterEndDate);
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._2(fetchData, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, {
                  headers: fetchRequest.headers,
                  handleSorting: handleSorting,
                  tablePage: convertTable(tablePage),
                  paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(/* BOTTOM */1, 10, handleChangePage, handleRowsPerPageChange, undefined),
                  rowButton: {
                    TAG: /* Left */0,
                    _0: /* EmptyCell */0
                  }
                }), match[0]);
}

var Row;

var make = Grid_Edit_Modal_Table;

export {
  Row ,
  getHeadersConfiguration ,
  make ,
}
/* react Not a pure module */
