// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReDate_utils from "@mobily/rescript-date/src/ReDate_utils.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../intl/messages/Messages_Error_Inputs.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";

function validateRequiredDateCompare(smallerDate, largerDate, rangeStartDate, rangeEndDate, thisDate) {
  var match = thisDate === "smaller" ? [
      smallerDate,
      largerDate
    ] : [
      largerDate,
      smallerDate
    ];
  var that = match[1];
  var $$this = match[0];
  if (rangeStartDate !== undefined) {
    if (rangeEndDate !== undefined) {
      return {
              TAG: /* Error */1,
              _0: Messages_Quotas_Detail$DvmAdminFrontendFr.actionDatesHaveIssue
            };
    }
    var start = Caml_option.valFromOption(rangeStartDate);
    if (App_Types_Date$DvmAdminFrontendFr.isValid(start)) {
      return Belt_Option.mapWithDefault($$this, {
                  TAG: /* Ok */0,
                  _0: undefined
                }, (function ($$this) {
                    if (App_Types_Date$DvmAdminFrontendFr.isValid($$this)) {
                      if (ReDate_utils.makeStartOfDayDate(start).valueOf() <= ReDate_utils.makeStartOfDayDate($$this).valueOf()) {
                        return Belt_Option.mapWithDefault(that, {
                                    TAG: /* Error */1,
                                    _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherDateNotValid
                                  }, (function (that) {
                                      if (App_Types_Date$DvmAdminFrontendFr.isValid(that)) {
                                        if (thisDate === "smaller") {
                                          if (ReDate.isAfter(that, $$this)) {
                                            return {
                                                    TAG: /* Ok */0,
                                                    _0: Caml_option.some($$this)
                                                  };
                                          } else {
                                            return {
                                                    TAG: /* Error */1,
                                                    _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateTooLarge
                                                  };
                                          }
                                        } else if (ReDate.isAfter($$this, that)) {
                                          return {
                                                  TAG: /* Ok */0,
                                                  _0: Caml_option.some($$this)
                                                };
                                        } else {
                                          return {
                                                  TAG: /* Error */1,
                                                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateTooSmall
                                                };
                                        }
                                      } else {
                                        return {
                                                TAG: /* Error */1,
                                                _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherDateNotValid
                                              };
                                      }
                                    }));
                      } else {
                        return {
                                TAG: /* Error */1,
                                _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateOutOfPeriod
                              };
                      }
                    } else {
                      return {
                              TAG: /* Error */1,
                              _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                            };
                    }
                  }));
    } else {
      return {
              TAG: /* Error */1,
              _0: Messages_Quotas_Detail$DvmAdminFrontendFr.missingStartDate
            };
    }
  }
  if (rangeEndDate === undefined) {
    return {
            TAG: /* Error */1,
            _0: Messages_Quotas_Detail$DvmAdminFrontendFr.actionDatesHaveIssue
          };
  }
  var end = Caml_option.valFromOption(rangeEndDate);
  if (App_Types_Date$DvmAdminFrontendFr.isValid(end)) {
    return Belt_Option.mapWithDefault($$this, {
                TAG: /* Ok */0,
                _0: undefined
              }, (function ($$this) {
                  if (App_Types_Date$DvmAdminFrontendFr.isValid($$this)) {
                    if (ReDate_utils.makeStartOfDayDate(end).valueOf() >= ReDate_utils.makeStartOfDayDate($$this).valueOf()) {
                      return Belt_Option.mapWithDefault(that, {
                                  TAG: /* Error */1,
                                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherDateNotValid
                                }, (function (that) {
                                    if (App_Types_Date$DvmAdminFrontendFr.isValid(that)) {
                                      if (thisDate === "smaller") {
                                        if (ReDate.isAfter(that, $$this)) {
                                          return {
                                                  TAG: /* Ok */0,
                                                  _0: Caml_option.some($$this)
                                                };
                                        } else {
                                          return {
                                                  TAG: /* Error */1,
                                                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateTooLarge
                                                };
                                        }
                                      } else if (ReDate.isAfter($$this, that)) {
                                        return {
                                                TAG: /* Ok */0,
                                                _0: Caml_option.some($$this)
                                              };
                                      } else {
                                        return {
                                                TAG: /* Error */1,
                                                _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateTooSmall
                                              };
                                      }
                                    } else {
                                      return {
                                              TAG: /* Error */1,
                                              _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherDateNotValid
                                            };
                                    }
                                  }));
                    } else {
                      return {
                              TAG: /* Error */1,
                              _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateOutOfPeriod
                            };
                    }
                  } else {
                    return {
                            TAG: /* Error */1,
                            _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                          };
                  }
                }));
  } else {
    return {
            TAG: /* Error */1,
            _0: Messages_Quotas_Detail$DvmAdminFrontendFr.missingEndDate
          };
  }
}

export {
  validateRequiredDateCompare ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
