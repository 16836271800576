// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../api/RestApi.bs.js";
import * as Model_Version_Types$DvmAdminFrontendFr from "./Model_Version_Types.bs.js";

function fetchModelSearch(submissionCallbacks, userManager, setResult, queryParams, selectedRole) {
  Curry._1(setResult, (function (param) {
          return {
                  TAG: /* ModelPage */0,
                  _0: /* Pending */1
                };
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Model_Version_Types$DvmAdminFrontendFr.Model.searchResult_decode, undefined, userManager, selectedRole, "/modelversion/models" + queryParams + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var modelsTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* ModelPage */0,
                                _0: {
                                  TAG: /* Ok */0,
                                  _0: modelsTable
                                }
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnSuccess, undefined);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* ModelPage */0,
                        _0: {
                          TAG: /* Error */1,
                          _0: error
                        }
                      };
              }));
      });
}

function fetchVersionSearch(submissionCallbacks, userManager, setResult, queryParams, selectedRole) {
  Curry._1(setResult, (function (param) {
          return {
                  TAG: /* VersionPage */1,
                  _0: /* Pending */1
                };
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Model_Version_Types$DvmAdminFrontendFr.Version.searchResult_decode, undefined, userManager, selectedRole, "/modelversion/versions" + queryParams + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var modelsTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* VersionPage */1,
                                _0: {
                                  TAG: /* Ok */0,
                                  _0: modelsTable
                                }
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnSuccess, undefined);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* VersionPage */1,
                        _0: {
                          TAG: /* Error */1,
                          _0: error
                        }
                      };
              }));
      });
}

function editRow(row, userManager, setResult, whatToDo, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var tmp;
  if (row.TAG === /* VersionActivate */0) {
    var content = row._0;
    tmp = {
      TAG: /* Json */0,
      _0: Model_Version_Types$DvmAdminFrontendFr.versionArray_encode([{
              modelCode: content.model,
              versionCode: content.version,
              phase: content.phase
            }])
    };
  } else {
    var content$1 = row._0;
    tmp = {
      TAG: /* Json */0,
      _0: Model_Version_Types$DvmAdminFrontendFr.modelArray_encode([{
              modelCode: content$1.model,
              phase: content$1.phase
            }])
    };
  }
  var tmp$1;
  tmp$1 = row.TAG === /* VersionActivate */0 ? "/modelversion/activateversion/" : "/modelversion/activatemodel/";
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("PUT", tmp, userManager, selectedRole, tmp$1 + (
          whatToDo ? "false" : "true"
        )).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function editMotorization(motorization, movelVersionId, userManager, setResult, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("PUT", undefined, userManager, selectedRole, "/modelversion/motorisation/" + movelVersionId + "?motorisation=" + motorization + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchMissingMotorizations(userManager, setResult, queryParams, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Model_Version_Types$DvmAdminFrontendFr.Version.searchResult_decode, undefined, userManager, selectedRole, "/enginetypes/empty" + queryParams + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

export {
  Form ,
  fetchModelSearch ,
  fetchVersionSearch ,
  editRow ,
  editMotorization ,
  fetchMissingMotorizations ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
