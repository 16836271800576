// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as RestApi$DvmAdminFrontendFr from "../../api/RestApi.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../common/utils/Utils_Alert.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../../common/types/App_Types_Input.bs.js";

function fetchPossibleUploads(alert, userManager, setResult, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.stringList_decode, undefined, userManager, selectedRole, "/upload/excel/tables").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var table = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: table
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

export {
  fetchPossibleUploads ,
}
/* react Not a pure module */
