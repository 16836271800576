// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as App_Checkbox$DvmAdminFrontendFr from "../App_Checkbox.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";

var headerText = Css.css(Object.assign({}, {
          backgroundColor: Theme_Colors$DvmAdminFrontendFr.mineralDarker,
          color: Theme_Colors$DvmAdminFrontendFr.white,
          fontSize: "16px",
          height: "45px",
          padding: "0px 5px 0px 5px"
        }, {
          "&:first-child": {
            padding: "0px 5px 0px 16px"
          },
          "&:last-child": {
            padding: "0px 16px 0px 5px"
          }
        }));

var wholeRow = Css.css({
      backgroundColor: Theme_Colors$DvmAdminFrontendFr.mineralDarker
    });

function makeHeaderCell(idheaderOpt, orderOpt, sortOpt, alignOpt, priority, hideOpt, label) {
  var idheader = idheaderOpt !== undefined ? idheaderOpt : "";
  var order = orderOpt !== undefined ? orderOpt : /* NotSet */2;
  var sort = sortOpt !== undefined ? sortOpt : true;
  var align = alignOpt !== undefined ? alignOpt : "left";
  var hide = hideOpt !== undefined ? hideOpt : false;
  return {
          idheader: idheader,
          label: label,
          order: order,
          sort: sort,
          align: align,
          priority: priority,
          hide: hide
        };
}

function createString(paramsStyle, headers) {
  var priorityHeaders = Belt_List.keep(headers, (function (x) {
          return Belt_Option.isSome(x.priority);
        }));
  var noPriorityHeaders = Belt_List.keep(headers, (function (x) {
          return Belt_Option.isNone(x.priority);
        }));
  var queryParams = Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap(Belt_List.map(Belt_List.concat(Belt_List.sort(priorityHeaders, (function (a, b) {
                              return Belt_Option.getWithDefault(a.priority, 100) - Belt_Option.getWithDefault(b.priority, 100) | 0;
                            })), noPriorityHeaders), (function (element) {
                      var match = element.order;
                      switch (match) {
                        case /* Asc */0 :
                            if (paramsStyle === "sqlLike") {
                              return element.idheader + " asc";
                            } else {
                              return "+" + element.idheader;
                            }
                        case /* Desc */1 :
                            if (paramsStyle === "sqlLike") {
                              return element.idheader + " desc";
                            } else {
                              return "-" + element.idheader;
                            }
                        case /* NotSet */2 :
                            return ;
                        
                      }
                    })), (function (queryParam) {
                  return queryParam;
                }))), ",", (function (queryParam) {
          return queryParam;
        }));
  if (queryParams === "") {
    return ;
  } else {
    return "orderBy=" + queryParams;
  }
}

function App_Table_Head(Props) {
  var headers = Props.headers;
  var handleSorting = Props.handleSorting;
  var rowButton = Props.rowButton;
  var intl = ReactIntl.useIntl();
  var tmp;
  if (rowButton !== undefined && rowButton.TAG === /* Left */0) {
    var headerButton = rowButton._0;
    var tmp$1;
    tmp$1 = typeof headerButton === "number" ? null : (
        headerButton.TAG === /* Checkbox */0 ? React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                checked: headerButton.checked,
                onClick: headerButton.onClick,
                mode: /* Dark */1
              }) : headerButton._0
      );
    tmp = React.createElement(Core.TableCell, {
          align: "left",
          children: tmp$1,
          className: headerText,
          key: "editCell"
        });
  } else {
    tmp = null;
  }
  var tmp$2;
  if (rowButton !== undefined && rowButton.TAG !== /* Left */0) {
    var headerButton$1 = rowButton._0;
    var tmp$3;
    tmp$3 = typeof headerButton$1 === "number" ? null : (
        headerButton$1.TAG === /* Checkbox */0 ? React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                checked: headerButton$1.checked,
                onClick: headerButton$1.onClick,
                mode: /* Dark */1
              }) : headerButton$1._0
      );
    tmp$2 = React.createElement(Core.TableCell, {
          align: "right",
          children: tmp$3,
          className: headerText,
          key: "editCell"
        });
  } else {
    tmp$2 = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Core.TableHead, {
                  children: React.createElement(Core.TableRow, {
                        children: null,
                        className: wholeRow
                      }, tmp, Belt_List.toArray(Belt_List.map(headers, (function (param) {
                                  if (param.hide) {
                                    return null;
                                  }
                                  var order = param.order;
                                  var label = param.label;
                                  var idheader = param.idheader;
                                  return React.createElement(Core.TableCell, {
                                              align: param.align,
                                              children: param.sort && handleSorting !== undefined ? React.createElement(Core.TableSortLabel, {
                                                      onClick: (function (param) {
                                                          Curry._1(handleSorting, Belt_List.map(headers, (function (header) {
                                                                      if (header.idheader !== idheader) {
                                                                        return header;
                                                                      }
                                                                      var tmp;
                                                                      switch (order) {
                                                                        case /* Asc */0 :
                                                                            tmp = /* Desc */1;
                                                                            break;
                                                                        case /* Desc */1 :
                                                                            tmp = /* NotSet */2;
                                                                            break;
                                                                        case /* NotSet */2 :
                                                                            tmp = /* Asc */0;
                                                                            break;
                                                                        
                                                                      }
                                                                      return {
                                                                              idheader: header.idheader,
                                                                              label: header.label,
                                                                              order: tmp,
                                                                              sort: header.sort,
                                                                              align: header.align,
                                                                              priority: header.priority,
                                                                              hide: header.hide
                                                                            };
                                                                    })));
                                                        }),
                                                      active: order !== /* NotSet */2,
                                                      children: intl.formatMessage(label),
                                                      direction: order !== 0 ? "desc" : "asc"
                                                    }) : intl.formatMessage(label),
                                              className: headerText,
                                              key: idheader
                                            });
                                }))), tmp$2)
                }));
}

var make = App_Table_Head;

export {
  headerText ,
  wholeRow ,
  makeHeaderCell ,
  createString ,
  make ,
}
/* headerText Not a pure module */
