// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Pickers from "@material-ui/pickers";
import * as App_Icon$DvmAdminFrontendFr from "../icons/App_Icon.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../utils/Utils_Form.bs.js";
import * as Utils_Input$DvmAdminFrontendFr from "../../utils/Utils_Input.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../intl/messages/Messages_Error_Inputs.bs.js";

function dateToString(date) {
  if (date == null) {
    return "";
  } else {
    return date.getFullYear().toString() + "-" + (date.getMonth() + 1.0).toString() + "-" + date.getDate().toString();
  }
}

function App_DateInputFormField(Props) {
  var label = Props.label;
  var value = Props.value;
  var onChange = Props.onChange;
  var validationResult = Props.validationResult;
  var disabled = Props.disabled;
  var className = Props.className;
  var id = Props.id;
  var variantOpt = Props.variant;
  var minDate = Props.minDate;
  var maxDate = Props.maxDate;
  var onError = Props.onError;
  var onAccept = Props.onAccept;
  var clearable = Props.clearable;
  var onBlur = Props.onBlur;
  var required = Props.required;
  var variant = variantOpt !== undefined ? variantOpt : /* DATE */1;
  var intl = ReactIntl.useIntl();
  var tmp;
  var exit = 0;
  if (disabled !== undefined && disabled) {
    tmp = null;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = variant ? React.createElement("div", {
            style: {
              color: Theme_Colors$DvmAdminFrontendFr.black
            }
          }, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                size: /* Regular */2,
                type_: /* Calendar */18
              })) : React.createElement(App_Icon$DvmAdminFrontendFr.make, {
            size: /* Small */1,
            type_: /* PadDown */11
          });
  }
  var tmp$1 = {
    value: Belt_Option.mapWithDefault(value, null, (function (prim) {
            return prim;
          })),
    onChange: (function (date, param) {
        Curry._1(onChange, Belt_Option.map((date == null) ? undefined : Caml_option.some(date), ReDate.endOfDay));
      }),
    format: variant ? "dd/MM/yy" : "yyyy",
    allowKeyboardControl: true,
    inputVariant: "filled",
    margin: "dense",
    fullWidth: true,
    autoOk: true,
    InputProps: {
      style: {
        paddingRight: "0px",
        borderRadius: "0px"
      },
      onBlur: onBlur,
      required: Belt_Option.map(required, Utils_Input$DvmAdminFrontendFr.isHtmlRequired)
    },
    placeholder: variant ? "--/--/--" : "----",
    invalidDateMessage: intl.formatMessage(Messages_Error_Inputs$DvmAdminFrontendFr.invalidField),
    views: variant ? [
        "year",
        "month",
        "date"
      ] : ["year"],
    keyboardIcon: tmp
  };
  var tmp$2 = Belt_Option.map(label, (function (label) {
          if (required !== undefined && required) {
            return Utils_Input$DvmAdminFrontendFr.requiredLabel(intl, label);
          } else {
            return intl.formatMessage(label);
          }
        }));
  if (tmp$2 !== undefined) {
    tmp$1.label = tmp$2;
  }
  if (minDate !== undefined) {
    tmp$1.minDate = Caml_option.valFromOption(minDate);
  }
  if (maxDate !== undefined) {
    tmp$1.maxDate = Caml_option.valFromOption(maxDate);
  }
  if (disabled !== undefined) {
    tmp$1.disabled = disabled;
  }
  if (clearable !== undefined) {
    tmp$1.clearable = clearable;
  }
  var tmp$3 = Belt_Option.map(validationResult, Belt_Result.isError);
  if (tmp$3 !== undefined) {
    tmp$1.error = tmp$3;
  }
  var tmp$4 = Utils_Form$DvmAdminFrontendFr.getErrorMessage(intl, validationResult);
  if (tmp$4 !== undefined) {
    tmp$1.helperText = tmp$4;
  }
  if (id !== undefined) {
    tmp$1.id = id;
  }
  if (onError !== undefined) {
    tmp$1.onError = Caml_option.valFromOption(onError);
  }
  if (onAccept !== undefined) {
    tmp$1.onAccept = Caml_option.valFromOption(onAccept);
  }
  var tmp$5 = {
    children: React.createElement(Pickers.KeyboardDatePicker, tmp$1)
  };
  if (className !== undefined) {
    tmp$5.className = className;
  }
  return React.createElement(Core.FormControl, tmp$5);
}

var make = App_DateInputFormField;

export {
  dateToString ,
  make ,
}
/* react Not a pure module */
