// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function dealerInfo_encode(v) {
  return Js_dict.fromArray([
              [
                "guaranteeAccount",
                Decco.stringToJson(v.guaranteeAccount)
              ],
              [
                "dealerId",
                Decco.stringToJson(v.dealerId)
              ],
              [
                "agentCode",
                Decco.stringToJson(v.agentCode)
              ],
              [
                "dr",
                Decco.stringToJson(v.dr)
              ],
              [
                "bir",
                Decco.stringToJson(v.bir)
              ]
            ]);
}

function dealerInfo_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var guaranteeAccount = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "guaranteeAccount"), null));
  if (guaranteeAccount.TAG === /* Ok */0) {
    var dealerId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerId"), null));
    if (dealerId.TAG === /* Ok */0) {
      var agentCode = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "agentCode"), null));
      if (agentCode.TAG === /* Ok */0) {
        var dr = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "dr"), null));
        if (dr.TAG === /* Ok */0) {
          var bir = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "bir"), null));
          if (bir.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      guaranteeAccount: guaranteeAccount._0,
                      dealerId: dealerId._0,
                      agentCode: agentCode._0,
                      dr: dr._0,
                      bir: bir._0
                    }
                  };
          }
          var e = bir._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".bir" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = dr._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".dr" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = agentCode._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".agentCode" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = dealerId._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".dealerId" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = guaranteeAccount._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".guaranteeAccount" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function profile_encode(v) {
  return Js_dict.fromArray([[
                "dealerInfo",
                Decco.optionToJson(dealerInfo_encode, v.dealerInfo)
              ]]);
}

function profile_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dealerInfo = Decco.optionFromJson(dealerInfo_decode, Belt_Option.getWithDefault(Js_dict.get(dict._0, "dealerInfo"), null));
  if (dealerInfo.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              dealerInfo: dealerInfo._0
            }
          };
  }
  var e = dealerInfo._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".dealerInfo" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

export {
  dealerInfo_encode ,
  dealerInfo_decode ,
  profile_encode ,
  profile_decode ,
}
/* No side effect */
