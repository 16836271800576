// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_Company_Manage$DvmAdminFrontendFr from "../../../../intl/messages/company/Messages_Company_Manage.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_AutocompleteFormInput.bs.js";

function Company_Manage_Modal_Inputs(Props) {
  var form = Props.form;
  var row = Props.row;
  var intl = ReactIntl.useIntl();
  var tmp = {
    onChange: Curry._1(form.updateSiret, (function (input, siret) {
            return {
                    siret: siret.toUpperCase(),
                    siren: input.siren,
                    name: input.name,
                    street: input.street,
                    streetNbr: input.streetNbr,
                    additionalAddressInfo: input.additionalAddressInfo,
                    zipCode: input.zipCode,
                    city: input.city,
                    countryCode: input.countryCode,
                    hierarchy: input.hierarchy,
                    codeApe: input.codeApe,
                    endDate: input.endDate
                  };
          })),
    value: form.input.siret,
    disabled: Belt_Option.isSome(row),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    label: Messages_Common$DvmAdminFrontendFr.siret,
    required: /* OnlyLabel */1
  };
  if (form.siretResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.siretResult);
  }
  var tmp$1 = {
    onChange: Curry._1(form.updateSiren, (function (input, siren) {
            return {
                    siret: input.siret,
                    siren: siren.toUpperCase(),
                    name: input.name,
                    street: input.street,
                    streetNbr: input.streetNbr,
                    additionalAddressInfo: input.additionalAddressInfo,
                    zipCode: input.zipCode,
                    city: input.city,
                    countryCode: input.countryCode,
                    hierarchy: input.hierarchy,
                    codeApe: input.codeApe,
                    endDate: input.endDate
                  };
          })),
    value: form.input.siren,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    label: Messages_Common$DvmAdminFrontendFr.siren,
    required: /* OnlyLabel */1
  };
  if (form.sirenResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.sirenResult);
  }
  var tmp$2 = {
    onChange: Curry._1(form.updateName, (function (input, name) {
            return {
                    siret: input.siret,
                    siren: input.siren,
                    name: name,
                    street: input.street,
                    streetNbr: input.streetNbr,
                    additionalAddressInfo: input.additionalAddressInfo,
                    zipCode: input.zipCode,
                    city: input.city,
                    countryCode: input.countryCode,
                    hierarchy: input.hierarchy,
                    codeApe: input.codeApe,
                    endDate: input.endDate
                  };
          })),
    value: form.input.name,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    label: Messages_Common$DvmAdminFrontendFr.companyName,
    required: /* OnlyLabel */1
  };
  if (form.nameResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.nameResult);
  }
  var tmp$3 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    siret: input.siret,
                    siren: input.siren,
                    name: input.name,
                    street: input.street,
                    streetNbr: input.streetNbr,
                    additionalAddressInfo: input.additionalAddressInfo,
                    zipCode: input.zipCode,
                    city: input.city,
                    countryCode: input.countryCode,
                    hierarchy: input.hierarchy,
                    codeApe: input.codeApe,
                    endDate: endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
  };
  if (form.endDateResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  var tmp$4 = {
    onChange: Curry._1(form.updateZipCode, (function (input, zipCode) {
            return {
                    siret: input.siret,
                    siren: input.siren,
                    name: input.name,
                    street: input.street,
                    streetNbr: input.streetNbr,
                    additionalAddressInfo: input.additionalAddressInfo,
                    zipCode: zipCode,
                    city: input.city,
                    countryCode: input.countryCode,
                    hierarchy: input.hierarchy,
                    codeApe: input.codeApe,
                    endDate: input.endDate
                  };
          })),
    value: form.input.zipCode,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    label: Messages_Company_Manage$DvmAdminFrontendFr.zipCode,
    required: /* OnlyLabel */1
  };
  if (form.zipCodeResult !== undefined) {
    tmp$4.validationResult = Caml_option.valFromOption(form.zipCodeResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  spacing: 2,
                  style: {
                    maxWidth: "800px"
                  }
                }, React.createElement(Core.Grid, {
                      children: React.createElement(Core.Paper, {
                            children: React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  item: true,
                                  spacing: 2
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp),
                                      item: true,
                                      sm: Grid$Mui.Sm[6]
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$1),
                                      item: true,
                                      sm: Grid$Mui.Sm[6]
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$2),
                                      item: true,
                                      sm: Grid$Mui.Sm[6]
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                            onChange: Curry._1(form.updateCodeApe, (function (input, codeApe) {
                                                    return {
                                                            siret: input.siret,
                                                            siren: input.siren,
                                                            name: input.name,
                                                            street: input.street,
                                                            streetNbr: input.streetNbr,
                                                            additionalAddressInfo: input.additionalAddressInfo,
                                                            zipCode: input.zipCode,
                                                            city: input.city,
                                                            countryCode: input.countryCode,
                                                            hierarchy: input.hierarchy,
                                                            codeApe: codeApe,
                                                            endDate: input.endDate
                                                          };
                                                  })),
                                            value: form.input.codeApe,
                                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                                            label: Messages_Company_Manage$DvmAdminFrontendFr.codeAPE
                                          }),
                                      item: true,
                                      sm: Grid$Mui.Sm[6]
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                            label: Messages_Company_Manage$DvmAdminFrontendFr.hierarchy,
                                            value: {
                                              TAG: /* Single */0,
                                              _0: {
                                                value: form.input.hierarchy,
                                                onChange: Curry._1(form.updateHierarchy, (function (input, hierarchy) {
                                                        return {
                                                                siret: input.siret,
                                                                siren: input.siren,
                                                                name: input.name,
                                                                street: input.street,
                                                                streetNbr: input.streetNbr,
                                                                additionalAddressInfo: input.additionalAddressInfo,
                                                                zipCode: input.zipCode,
                                                                city: input.city,
                                                                countryCode: input.countryCode,
                                                                hierarchy: hierarchy,
                                                                codeApe: input.codeApe,
                                                                endDate: input.endDate
                                                              };
                                                      }))
                                              }
                                            },
                                            options: {
                                              TAG: /* Labeled */1,
                                              _0: {
                                                hd: {
                                                  label: {
                                                    TAG: /* Message */0,
                                                    _0: Messages_Company_Manage$DvmAdminFrontendFr.hierarchyOne
                                                  },
                                                  value: "1"
                                                },
                                                tl: {
                                                  hd: {
                                                    label: {
                                                      TAG: /* Message */0,
                                                      _0: Messages_Company_Manage$DvmAdminFrontendFr.hierarchyTwo
                                                    },
                                                    value: "2"
                                                  },
                                                  tl: {
                                                    hd: {
                                                      label: {
                                                        TAG: /* Message */0,
                                                        _0: Messages_Company_Manage$DvmAdminFrontendFr.hierarchyFromBCC
                                                      },
                                                      value: "9"
                                                    },
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            },
                                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                                          }),
                                      item: true,
                                      sm: Grid$Mui.Sm[6]
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$3),
                                      item: true,
                                      sm: Grid$Mui.Sm[6]
                                    })),
                            square: true,
                            style: {
                              padding: "17px"
                            }
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(Core.Paper, {
                            children: React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  item: true,
                                  spacing: 2
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                            onChange: Curry._1(form.updateStreetNbr, (function (input, streetNbr) {
                                                    return {
                                                            siret: input.siret,
                                                            siren: input.siren,
                                                            name: input.name,
                                                            street: input.street,
                                                            streetNbr: streetNbr,
                                                            additionalAddressInfo: input.additionalAddressInfo,
                                                            zipCode: input.zipCode,
                                                            city: input.city,
                                                            countryCode: input.countryCode,
                                                            hierarchy: input.hierarchy,
                                                            codeApe: input.codeApe,
                                                            endDate: input.endDate
                                                          };
                                                  })),
                                            value: form.input.streetNbr,
                                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                                            label: Messages_Company_Manage$DvmAdminFrontendFr.streetNbr
                                          }),
                                      item: true,
                                      sm: Grid$Mui.Sm[3]
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                            onChange: Curry._1(form.updateStreet, (function (input, street) {
                                                    return {
                                                            siret: input.siret,
                                                            siren: input.siren,
                                                            name: input.name,
                                                            street: street,
                                                            streetNbr: input.streetNbr,
                                                            additionalAddressInfo: input.additionalAddressInfo,
                                                            zipCode: input.zipCode,
                                                            city: input.city,
                                                            countryCode: input.countryCode,
                                                            hierarchy: input.hierarchy,
                                                            codeApe: input.codeApe,
                                                            endDate: input.endDate
                                                          };
                                                  })),
                                            value: form.input.street,
                                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                                            label: Messages_Company_Manage$DvmAdminFrontendFr.street
                                          }),
                                      item: true,
                                      sm: Grid$Mui.Sm[5]
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                            onChange: Curry._1(form.updateAdditionalAddressInfo, (function (input, additionalAddressInfo) {
                                                    return {
                                                            siret: input.siret,
                                                            siren: input.siren,
                                                            name: input.name,
                                                            street: input.street,
                                                            streetNbr: input.streetNbr,
                                                            additionalAddressInfo: additionalAddressInfo,
                                                            zipCode: input.zipCode,
                                                            city: input.city,
                                                            countryCode: input.countryCode,
                                                            hierarchy: input.hierarchy,
                                                            codeApe: input.codeApe,
                                                            endDate: input.endDate
                                                          };
                                                  })),
                                            value: form.input.additionalAddressInfo,
                                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                                            label: Messages_Company_Manage$DvmAdminFrontendFr.additionalAddressInfo
                                          }),
                                      item: true,
                                      sm: Grid$Mui.Sm[4]
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$4),
                                      item: true,
                                      sm: Grid$Mui.Sm[3]
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                            onChange: Curry._1(form.updateCity, (function (input, city) {
                                                    return {
                                                            siret: input.siret,
                                                            siren: input.siren,
                                                            name: input.name,
                                                            street: input.street,
                                                            streetNbr: input.streetNbr,
                                                            additionalAddressInfo: input.additionalAddressInfo,
                                                            zipCode: input.zipCode,
                                                            city: city,
                                                            countryCode: input.countryCode,
                                                            hierarchy: input.hierarchy,
                                                            codeApe: input.codeApe,
                                                            endDate: input.endDate
                                                          };
                                                  })),
                                            value: form.input.city,
                                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                                            label: Messages_Common$DvmAdminFrontendFr.city
                                          }),
                                      item: true,
                                      sm: Grid$Mui.Sm[6]
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                            onChange: Curry._1(form.updateCountryCode, (function (input, countryCode) {
                                                    return {
                                                            siret: input.siret,
                                                            siren: input.siren,
                                                            name: input.name,
                                                            street: input.street,
                                                            streetNbr: input.streetNbr,
                                                            additionalAddressInfo: input.additionalAddressInfo,
                                                            zipCode: input.zipCode,
                                                            city: input.city,
                                                            countryCode: countryCode,
                                                            hierarchy: input.hierarchy,
                                                            codeApe: input.codeApe,
                                                            endDate: input.endDate
                                                          };
                                                  })),
                                            value: form.input.countryCode,
                                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                                            label: Messages_Company_Manage$DvmAdminFrontendFr.countryCode
                                          }),
                                      item: true,
                                      sm: Grid$Mui.Sm[3]
                                    })),
                            square: true,
                            style: {
                              padding: "17px"
                            }
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                            onClick: (function (param) {
                                Curry._1(form.submit, undefined);
                              }),
                            label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                            size: "medium",
                            type_: "submit"
                          }),
                      container: true,
                      item: true,
                      justifyContent: "flex-end"
                    })));
}

var Form;

var DateWithCheckbox;

var InputStyles;

var make = Company_Manage_Modal_Inputs;

export {
  Form ,
  DateWithCheckbox ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
