// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../common/utils/Utils_Form.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../common/types/App_Types_Date.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Error_Inputs.bs.js";

function validateDate(smallerDate, largerDate, thisDate, requiredSmaller, requiredLarger, needsSameYearOpt, param) {
  var needsSameYear = needsSameYearOpt !== undefined ? needsSameYearOpt : false;
  var match = thisDate === "smaller" ? [
      smallerDate,
      largerDate,
      requiredSmaller,
      requiredLarger
    ] : [
      largerDate,
      smallerDate,
      requiredLarger,
      requiredSmaller
    ];
  var requiredThat = match[3];
  var that = match[1];
  return Belt_Option.mapWithDefault(match[0], match[2] ? ({
                  TAG: /* Error */1,
                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
                }) : ({
                  TAG: /* Ok */0,
                  _0: undefined
                }), (function ($$this) {
                if (App_Types_Date$DvmAdminFrontendFr.isValid($$this)) {
                  return Belt_Option.mapWithDefault(that, requiredThat ? ({
                                  TAG: /* Error */1,
                                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherDateNotValid
                                }) : ({
                                  TAG: /* Ok */0,
                                  _0: Caml_option.some($$this)
                                }), (function (that) {
                                if (App_Types_Date$DvmAdminFrontendFr.isValid(that)) {
                                  if (ReDate.getYear($$this) === ReDate.getYear(that) || !needsSameYear) {
                                    if (thisDate === "smaller") {
                                      if ($$this.valueOf() < that.valueOf()) {
                                        return {
                                                TAG: /* Ok */0,
                                                _0: Caml_option.some($$this)
                                              };
                                      } else {
                                        return {
                                                TAG: /* Error */1,
                                                _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateTooLarge
                                              };
                                      }
                                    } else if ($$this.valueOf() > that.valueOf()) {
                                      return {
                                              TAG: /* Ok */0,
                                              _0: Caml_option.some($$this)
                                            };
                                    } else {
                                      return {
                                              TAG: /* Error */1,
                                              _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateTooSmall
                                            };
                                    }
                                  } else {
                                    return {
                                            TAG: /* Error */1,
                                            _0: Messages_Error_Inputs$DvmAdminFrontendFr.datesNotSameYear
                                          };
                                  }
                                } else {
                                  return {
                                          TAG: /* Error */1,
                                          _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherDateNotValid
                                        };
                                }
                              }));
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                        };
                }
              }));
}

var validators_controllingAppEndDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      return validateDate(param.controllingAppStartDate, param.controllingAppEndDate, "larger", false, false, undefined, undefined);
    })
};

var validators_controllingAppStartDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      return validateDate(param.controllingAppStartDate, param.controllingAppEndDate, "smaller", false, false, undefined, undefined);
    })
};

var validators_paidTo = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.paidTo
            };
    })
};

var validators_definition = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var definition = param.definition;
      if (definition.length <= 100) {
        return {
                TAG: /* Ok */0,
                _0: definition
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldMoreCharactersThanMaximum
              };
      }
    })
};

var validators_controllingCorpTypeLabel = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.controllingCorpTypeLabel
            };
    })
};

var validators_controllingCorpTypeID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.controllingCorpTypeID
            };
    })
};

var validators_vmeDestination = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.vmeDestination
            };
    })
};

var validators_marketingCorpType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.marketingCorpType
            };
    })
};

var validators_marketingCorpCategory = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.marketingCorpCategory
            };
    })
};

var validators_appSubChannel = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.appSubChannel
            };
    })
};

var validators_appClientChannel = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.appClientChannel
            };
    })
};

var validators_distributionMode = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.distributionMode
            };
    })
};

var validators_brand = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.brand
            };
    })
};

var validators_appCorpChannel = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.appCorpChannel
            };
    })
};

var validators_localActionTrigger = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.localActionTrigger
            };
    })
};

var validators_localActionDescription = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.localActionDescription
            };
    })
};

var validators_localActionTypeLabel = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.localActionTypeLabel
            };
    })
};

var validators_localActionTypeID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.localActionTypeID);
    })
};

var validators_countryCode = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.countryCode);
    })
};

var validators = {
  controllingAppEndDate: validators_controllingAppEndDate,
  controllingAppStartDate: validators_controllingAppStartDate,
  paidTo: validators_paidTo,
  definition: validators_definition,
  controllingCorpTypeLabel: validators_controllingCorpTypeLabel,
  controllingCorpTypeID: validators_controllingCorpTypeID,
  vmeDestination: validators_vmeDestination,
  marketingCorpType: validators_marketingCorpType,
  marketingCorpCategory: validators_marketingCorpCategory,
  appSubChannel: validators_appSubChannel,
  appClientChannel: validators_appClientChannel,
  distributionMode: validators_distributionMode,
  brand: validators_brand,
  appCorpChannel: validators_appCorpChannel,
  localActionTrigger: validators_localActionTrigger,
  localActionDescription: validators_localActionDescription,
  localActionTypeLabel: validators_localActionTypeLabel,
  localActionTypeID: validators_localActionTypeID,
  countryCode: validators_countryCode
};

function initialFieldsStatuses(_input) {
  return {
          controllingAppEndDate: /* Pristine */0,
          controllingAppStartDate: /* Pristine */0,
          paidTo: /* Pristine */0,
          definition: /* Pristine */0,
          controllingCorpTypeLabel: /* Pristine */0,
          controllingCorpTypeID: /* Pristine */0,
          vmeDestination: /* Pristine */0,
          marketingCorpType: /* Pristine */0,
          marketingCorpCategory: /* Pristine */0,
          appSubChannel: /* Pristine */0,
          appClientChannel: /* Pristine */0,
          distributionMode: /* Pristine */0,
          brand: /* Pristine */0,
          appCorpChannel: /* Pristine */0,
          localActionTrigger: /* Pristine */0,
          localActionDescription: /* Pristine */0,
          localActionTypeLabel: /* Pristine */0,
          localActionTypeID: /* Pristine */0,
          countryCode: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.controllingAppEndDate;
  var match_0 = match ? match._0 : Curry._1(validators.controllingAppEndDate.validate, input);
  var match$1 = fieldsStatuses.controllingAppStartDate;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.controllingAppStartDate.validate, input);
  var match$2 = fieldsStatuses.paidTo;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.paidTo.validate, input);
  var match$3 = fieldsStatuses.definition;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.definition.validate, input);
  var match$4 = fieldsStatuses.controllingCorpTypeLabel;
  var match_0$4 = match$4 ? match$4._0 : Curry._1(validators.controllingCorpTypeLabel.validate, input);
  var match$5 = fieldsStatuses.controllingCorpTypeID;
  var match_0$5 = match$5 ? match$5._0 : Curry._1(validators.controllingCorpTypeID.validate, input);
  var match$6 = fieldsStatuses.vmeDestination;
  var match_0$6 = match$6 ? match$6._0 : Curry._1(validators.vmeDestination.validate, input);
  var match$7 = fieldsStatuses.marketingCorpType;
  var match_0$7 = match$7 ? match$7._0 : Curry._1(validators.marketingCorpType.validate, input);
  var match$8 = fieldsStatuses.marketingCorpCategory;
  var match_0$8 = match$8 ? match$8._0 : Curry._1(validators.marketingCorpCategory.validate, input);
  var match$9 = fieldsStatuses.appSubChannel;
  var match_0$9 = match$9 ? match$9._0 : Curry._1(validators.appSubChannel.validate, input);
  var match$10 = fieldsStatuses.appClientChannel;
  var match_0$10 = match$10 ? match$10._0 : Curry._1(validators.appClientChannel.validate, input);
  var match$11 = fieldsStatuses.distributionMode;
  var match_0$11 = match$11 ? match$11._0 : Curry._1(validators.distributionMode.validate, input);
  var match$12 = fieldsStatuses.brand;
  var match_0$12 = match$12 ? match$12._0 : Curry._1(validators.brand.validate, input);
  var match$13 = fieldsStatuses.appCorpChannel;
  var match_0$13 = match$13 ? match$13._0 : Curry._1(validators.appCorpChannel.validate, input);
  var match$14 = fieldsStatuses.localActionTrigger;
  var match_0$14 = match$14 ? match$14._0 : Curry._1(validators.localActionTrigger.validate, input);
  var match$15 = fieldsStatuses.localActionDescription;
  var match_0$15 = match$15 ? match$15._0 : Curry._1(validators.localActionDescription.validate, input);
  var match$16 = fieldsStatuses.localActionTypeLabel;
  var match_0$16 = match$16 ? match$16._0 : Curry._1(validators.localActionTypeLabel.validate, input);
  var match$17 = fieldsStatuses.localActionTypeID;
  var match_0$17 = match$17 ? match$17._0 : Curry._1(validators.localActionTypeID.validate, input);
  var match$18 = fieldsStatuses.countryCode;
  var match_0$18 = match$18 ? match$18._0 : Curry._1(validators.countryCode.validate, input);
  var controllingAppEndDateResult = match_0;
  var controllingAppEndDateResult$1;
  if (controllingAppEndDateResult.TAG === /* Ok */0) {
    var controllingAppStartDateResult = match_0$1;
    if (controllingAppStartDateResult.TAG === /* Ok */0) {
      var paidToResult = match_0$2;
      if (paidToResult.TAG === /* Ok */0) {
        var definitionResult = match_0$3;
        if (definitionResult.TAG === /* Ok */0) {
          var controllingCorpTypeLabelResult = match_0$4;
          if (controllingCorpTypeLabelResult.TAG === /* Ok */0) {
            var controllingCorpTypeIDResult = match_0$5;
            if (controllingCorpTypeIDResult.TAG === /* Ok */0) {
              var vmeDestinationResult = match_0$6;
              if (vmeDestinationResult.TAG === /* Ok */0) {
                var marketingCorpTypeResult = match_0$7;
                if (marketingCorpTypeResult.TAG === /* Ok */0) {
                  var marketingCorpCategoryResult = match_0$8;
                  if (marketingCorpCategoryResult.TAG === /* Ok */0) {
                    var appSubChannelResult = match_0$9;
                    if (appSubChannelResult.TAG === /* Ok */0) {
                      var appClientChannelResult = match_0$10;
                      if (appClientChannelResult.TAG === /* Ok */0) {
                        var distributionModeResult = match_0$11;
                        if (distributionModeResult.TAG === /* Ok */0) {
                          var brandResult = match_0$12;
                          if (brandResult.TAG === /* Ok */0) {
                            var appCorpChannelResult = match_0$13;
                            if (appCorpChannelResult.TAG === /* Ok */0) {
                              var localActionTriggerResult = match_0$14;
                              if (localActionTriggerResult.TAG === /* Ok */0) {
                                var localActionDescriptionResult = match_0$15;
                                if (localActionDescriptionResult.TAG === /* Ok */0) {
                                  var localActionTypeLabelResult = match_0$16;
                                  if (localActionTypeLabelResult.TAG === /* Ok */0) {
                                    var localActionTypeIDResult = match_0$17;
                                    if (localActionTypeIDResult.TAG === /* Ok */0) {
                                      var countryCodeResult = match_0$18;
                                      if (countryCodeResult.TAG === /* Ok */0) {
                                        return {
                                                TAG: /* Valid */0,
                                                output: {
                                                  countryCode: countryCodeResult._0,
                                                  localActionTypeID: localActionTypeIDResult._0,
                                                  localActionTypeLabel: localActionTypeLabelResult._0,
                                                  localActionDescription: localActionDescriptionResult._0,
                                                  localActionTrigger: localActionTriggerResult._0,
                                                  appCorpChannel: appCorpChannelResult._0,
                                                  brand: brandResult._0,
                                                  distributionMode: distributionModeResult._0,
                                                  appClientChannel: appClientChannelResult._0,
                                                  appSubChannel: appSubChannelResult._0,
                                                  marketingCorpCategory: marketingCorpCategoryResult._0,
                                                  marketingCorpType: marketingCorpTypeResult._0,
                                                  vmeDestination: vmeDestinationResult._0,
                                                  controllingCorpTypeID: controllingCorpTypeIDResult._0,
                                                  controllingCorpTypeLabel: controllingCorpTypeLabelResult._0,
                                                  definition: definitionResult._0,
                                                  paidTo: paidToResult._0,
                                                  controllingAppStartDate: controllingAppStartDateResult._0,
                                                  controllingAppEndDate: controllingAppEndDateResult._0
                                                },
                                                fieldsStatuses: {
                                                  controllingAppEndDate: /* Dirty */{
                                                    _0: controllingAppEndDateResult,
                                                    _1: /* Shown */0
                                                  },
                                                  controllingAppStartDate: /* Dirty */{
                                                    _0: controllingAppStartDateResult,
                                                    _1: /* Shown */0
                                                  },
                                                  paidTo: /* Dirty */{
                                                    _0: paidToResult,
                                                    _1: /* Shown */0
                                                  },
                                                  definition: /* Dirty */{
                                                    _0: definitionResult,
                                                    _1: /* Shown */0
                                                  },
                                                  controllingCorpTypeLabel: /* Dirty */{
                                                    _0: controllingCorpTypeLabelResult,
                                                    _1: /* Shown */0
                                                  },
                                                  controllingCorpTypeID: /* Dirty */{
                                                    _0: controllingCorpTypeIDResult,
                                                    _1: /* Shown */0
                                                  },
                                                  vmeDestination: /* Dirty */{
                                                    _0: vmeDestinationResult,
                                                    _1: /* Shown */0
                                                  },
                                                  marketingCorpType: /* Dirty */{
                                                    _0: marketingCorpTypeResult,
                                                    _1: /* Shown */0
                                                  },
                                                  marketingCorpCategory: /* Dirty */{
                                                    _0: marketingCorpCategoryResult,
                                                    _1: /* Shown */0
                                                  },
                                                  appSubChannel: /* Dirty */{
                                                    _0: appSubChannelResult,
                                                    _1: /* Shown */0
                                                  },
                                                  appClientChannel: /* Dirty */{
                                                    _0: appClientChannelResult,
                                                    _1: /* Shown */0
                                                  },
                                                  distributionMode: /* Dirty */{
                                                    _0: distributionModeResult,
                                                    _1: /* Shown */0
                                                  },
                                                  brand: /* Dirty */{
                                                    _0: brandResult,
                                                    _1: /* Shown */0
                                                  },
                                                  appCorpChannel: /* Dirty */{
                                                    _0: appCorpChannelResult,
                                                    _1: /* Shown */0
                                                  },
                                                  localActionTrigger: /* Dirty */{
                                                    _0: localActionTriggerResult,
                                                    _1: /* Shown */0
                                                  },
                                                  localActionDescription: /* Dirty */{
                                                    _0: localActionDescriptionResult,
                                                    _1: /* Shown */0
                                                  },
                                                  localActionTypeLabel: /* Dirty */{
                                                    _0: localActionTypeLabelResult,
                                                    _1: /* Shown */0
                                                  },
                                                  localActionTypeID: /* Dirty */{
                                                    _0: localActionTypeIDResult,
                                                    _1: /* Shown */0
                                                  },
                                                  countryCode: /* Dirty */{
                                                    _0: countryCodeResult,
                                                    _1: /* Shown */0
                                                  }
                                                },
                                                collectionsStatuses: undefined
                                              };
                                      }
                                      controllingAppEndDateResult$1 = controllingAppEndDateResult;
                                    } else {
                                      controllingAppEndDateResult$1 = controllingAppEndDateResult;
                                    }
                                  } else {
                                    controllingAppEndDateResult$1 = controllingAppEndDateResult;
                                  }
                                } else {
                                  controllingAppEndDateResult$1 = controllingAppEndDateResult;
                                }
                              } else {
                                controllingAppEndDateResult$1 = controllingAppEndDateResult;
                              }
                            } else {
                              controllingAppEndDateResult$1 = controllingAppEndDateResult;
                            }
                          } else {
                            controllingAppEndDateResult$1 = controllingAppEndDateResult;
                          }
                        } else {
                          controllingAppEndDateResult$1 = controllingAppEndDateResult;
                        }
                      } else {
                        controllingAppEndDateResult$1 = controllingAppEndDateResult;
                      }
                    } else {
                      controllingAppEndDateResult$1 = controllingAppEndDateResult;
                    }
                  } else {
                    controllingAppEndDateResult$1 = controllingAppEndDateResult;
                  }
                } else {
                  controllingAppEndDateResult$1 = controllingAppEndDateResult;
                }
              } else {
                controllingAppEndDateResult$1 = controllingAppEndDateResult;
              }
            } else {
              controllingAppEndDateResult$1 = controllingAppEndDateResult;
            }
          } else {
            controllingAppEndDateResult$1 = controllingAppEndDateResult;
          }
        } else {
          controllingAppEndDateResult$1 = controllingAppEndDateResult;
        }
      } else {
        controllingAppEndDateResult$1 = controllingAppEndDateResult;
      }
    } else {
      controllingAppEndDateResult$1 = controllingAppEndDateResult;
    }
  } else {
    controllingAppEndDateResult$1 = controllingAppEndDateResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            controllingAppEndDate: /* Dirty */{
              _0: controllingAppEndDateResult$1,
              _1: /* Shown */0
            },
            controllingAppStartDate: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            paidTo: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            definition: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            controllingCorpTypeLabel: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            controllingCorpTypeID: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            vmeDestination: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            marketingCorpType: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            },
            marketingCorpCategory: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            },
            appSubChannel: /* Dirty */{
              _0: match_0$9,
              _1: /* Shown */0
            },
            appClientChannel: /* Dirty */{
              _0: match_0$10,
              _1: /* Shown */0
            },
            distributionMode: /* Dirty */{
              _0: match_0$11,
              _1: /* Shown */0
            },
            brand: /* Dirty */{
              _0: match_0$12,
              _1: /* Shown */0
            },
            appCorpChannel: /* Dirty */{
              _0: match_0$13,
              _1: /* Shown */0
            },
            localActionTrigger: /* Dirty */{
              _0: match_0$14,
              _1: /* Shown */0
            },
            localActionDescription: /* Dirty */{
              _0: match_0$15,
              _1: /* Shown */0
            },
            localActionTypeLabel: /* Dirty */{
              _0: match_0$16,
              _1: /* Shown */0
            },
            localActionTypeID: /* Dirty */{
              _0: match_0$17,
              _1: /* Shown */0
            },
            countryCode: /* Dirty */{
              _0: match_0$18,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurControllingAppEndDateField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.controllingAppEndDate, validators_controllingAppEndDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  controllingAppEndDate: status,
                                  controllingAppStartDate: init.controllingAppStartDate,
                                  paidTo: init.paidTo,
                                  definition: init.definition,
                                  controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                  controllingCorpTypeID: init.controllingCorpTypeID,
                                  vmeDestination: init.vmeDestination,
                                  marketingCorpType: init.marketingCorpType,
                                  marketingCorpCategory: init.marketingCorpCategory,
                                  appSubChannel: init.appSubChannel,
                                  appClientChannel: init.appClientChannel,
                                  distributionMode: init.distributionMode,
                                  brand: init.brand,
                                  appCorpChannel: init.appCorpChannel,
                                  localActionTrigger: init.localActionTrigger,
                                  localActionDescription: init.localActionDescription,
                                  localActionTypeLabel: init.localActionTypeLabel,
                                  localActionTypeID: init.localActionTypeID,
                                  countryCode: init.countryCode
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurControllingAppStartDateField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.controllingAppStartDate, validators_controllingAppStartDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  controllingAppEndDate: init.controllingAppEndDate,
                                  controllingAppStartDate: status,
                                  paidTo: init.paidTo,
                                  definition: init.definition,
                                  controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                  controllingCorpTypeID: init.controllingCorpTypeID,
                                  vmeDestination: init.vmeDestination,
                                  marketingCorpType: init.marketingCorpType,
                                  marketingCorpCategory: init.marketingCorpCategory,
                                  appSubChannel: init.appSubChannel,
                                  appClientChannel: init.appClientChannel,
                                  distributionMode: init.distributionMode,
                                  brand: init.brand,
                                  appCorpChannel: init.appCorpChannel,
                                  localActionTrigger: init.localActionTrigger,
                                  localActionDescription: init.localActionDescription,
                                  localActionTypeLabel: init.localActionTypeLabel,
                                  localActionTypeID: init.localActionTypeID,
                                  countryCode: init.countryCode
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPaidToField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.paidTo, validators_paidTo, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  controllingAppEndDate: init.controllingAppEndDate,
                                  controllingAppStartDate: init.controllingAppStartDate,
                                  paidTo: status,
                                  definition: init.definition,
                                  controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                  controllingCorpTypeID: init.controllingCorpTypeID,
                                  vmeDestination: init.vmeDestination,
                                  marketingCorpType: init.marketingCorpType,
                                  marketingCorpCategory: init.marketingCorpCategory,
                                  appSubChannel: init.appSubChannel,
                                  appClientChannel: init.appClientChannel,
                                  distributionMode: init.distributionMode,
                                  brand: init.brand,
                                  appCorpChannel: init.appCorpChannel,
                                  localActionTrigger: init.localActionTrigger,
                                  localActionDescription: init.localActionDescription,
                                  localActionTypeLabel: init.localActionTypeLabel,
                                  localActionTypeID: init.localActionTypeID,
                                  countryCode: init.countryCode
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDefinitionField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.definition, validators_definition, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  controllingAppEndDate: init.controllingAppEndDate,
                                  controllingAppStartDate: init.controllingAppStartDate,
                                  paidTo: init.paidTo,
                                  definition: status,
                                  controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                  controllingCorpTypeID: init.controllingCorpTypeID,
                                  vmeDestination: init.vmeDestination,
                                  marketingCorpType: init.marketingCorpType,
                                  marketingCorpCategory: init.marketingCorpCategory,
                                  appSubChannel: init.appSubChannel,
                                  appClientChannel: init.appClientChannel,
                                  distributionMode: init.distributionMode,
                                  brand: init.brand,
                                  appCorpChannel: init.appCorpChannel,
                                  localActionTrigger: init.localActionTrigger,
                                  localActionDescription: init.localActionDescription,
                                  localActionTypeLabel: init.localActionTypeLabel,
                                  localActionTypeID: init.localActionTypeID,
                                  countryCode: init.countryCode
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurControllingCorpTypeLabelField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.controllingCorpTypeLabel, validators_controllingCorpTypeLabel, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  controllingAppEndDate: init.controllingAppEndDate,
                                  controllingAppStartDate: init.controllingAppStartDate,
                                  paidTo: init.paidTo,
                                  definition: init.definition,
                                  controllingCorpTypeLabel: status,
                                  controllingCorpTypeID: init.controllingCorpTypeID,
                                  vmeDestination: init.vmeDestination,
                                  marketingCorpType: init.marketingCorpType,
                                  marketingCorpCategory: init.marketingCorpCategory,
                                  appSubChannel: init.appSubChannel,
                                  appClientChannel: init.appClientChannel,
                                  distributionMode: init.distributionMode,
                                  brand: init.brand,
                                  appCorpChannel: init.appCorpChannel,
                                  localActionTrigger: init.localActionTrigger,
                                  localActionDescription: init.localActionDescription,
                                  localActionTypeLabel: init.localActionTypeLabel,
                                  localActionTypeID: init.localActionTypeID,
                                  countryCode: init.countryCode
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurControllingCorpTypeIDField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.controllingCorpTypeID, validators_controllingCorpTypeID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  controllingAppEndDate: init.controllingAppEndDate,
                                  controllingAppStartDate: init.controllingAppStartDate,
                                  paidTo: init.paidTo,
                                  definition: init.definition,
                                  controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                  controllingCorpTypeID: status,
                                  vmeDestination: init.vmeDestination,
                                  marketingCorpType: init.marketingCorpType,
                                  marketingCorpCategory: init.marketingCorpCategory,
                                  appSubChannel: init.appSubChannel,
                                  appClientChannel: init.appClientChannel,
                                  distributionMode: init.distributionMode,
                                  brand: init.brand,
                                  appCorpChannel: init.appCorpChannel,
                                  localActionTrigger: init.localActionTrigger,
                                  localActionDescription: init.localActionDescription,
                                  localActionTypeLabel: init.localActionTypeLabel,
                                  localActionTypeID: init.localActionTypeID,
                                  countryCode: init.countryCode
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVmeDestinationField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.vmeDestination, validators_vmeDestination, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  controllingAppEndDate: init.controllingAppEndDate,
                                  controllingAppStartDate: init.controllingAppStartDate,
                                  paidTo: init.paidTo,
                                  definition: init.definition,
                                  controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                  controllingCorpTypeID: init.controllingCorpTypeID,
                                  vmeDestination: status,
                                  marketingCorpType: init.marketingCorpType,
                                  marketingCorpCategory: init.marketingCorpCategory,
                                  appSubChannel: init.appSubChannel,
                                  appClientChannel: init.appClientChannel,
                                  distributionMode: init.distributionMode,
                                  brand: init.brand,
                                  appCorpChannel: init.appCorpChannel,
                                  localActionTrigger: init.localActionTrigger,
                                  localActionDescription: init.localActionDescription,
                                  localActionTypeLabel: init.localActionTypeLabel,
                                  localActionTypeID: init.localActionTypeID,
                                  countryCode: init.countryCode
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurMarketingCorpTypeField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.marketingCorpType, validators_marketingCorpType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  controllingAppEndDate: init.controllingAppEndDate,
                                  controllingAppStartDate: init.controllingAppStartDate,
                                  paidTo: init.paidTo,
                                  definition: init.definition,
                                  controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                  controllingCorpTypeID: init.controllingCorpTypeID,
                                  vmeDestination: init.vmeDestination,
                                  marketingCorpType: status,
                                  marketingCorpCategory: init.marketingCorpCategory,
                                  appSubChannel: init.appSubChannel,
                                  appClientChannel: init.appClientChannel,
                                  distributionMode: init.distributionMode,
                                  brand: init.brand,
                                  appCorpChannel: init.appCorpChannel,
                                  localActionTrigger: init.localActionTrigger,
                                  localActionDescription: init.localActionDescription,
                                  localActionTypeLabel: init.localActionTypeLabel,
                                  localActionTypeID: init.localActionTypeID,
                                  countryCode: init.countryCode
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurMarketingCorpCategoryField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.marketingCorpCategory, validators_marketingCorpCategory, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  controllingAppEndDate: init.controllingAppEndDate,
                                  controllingAppStartDate: init.controllingAppStartDate,
                                  paidTo: init.paidTo,
                                  definition: init.definition,
                                  controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                  controllingCorpTypeID: init.controllingCorpTypeID,
                                  vmeDestination: init.vmeDestination,
                                  marketingCorpType: init.marketingCorpType,
                                  marketingCorpCategory: status,
                                  appSubChannel: init.appSubChannel,
                                  appClientChannel: init.appClientChannel,
                                  distributionMode: init.distributionMode,
                                  brand: init.brand,
                                  appCorpChannel: init.appCorpChannel,
                                  localActionTrigger: init.localActionTrigger,
                                  localActionDescription: init.localActionDescription,
                                  localActionTypeLabel: init.localActionTypeLabel,
                                  localActionTypeID: init.localActionTypeID,
                                  countryCode: init.countryCode
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAppSubChannelField */9 :
                  var result$9 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.appSubChannel, validators_appSubChannel, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  controllingAppEndDate: init.controllingAppEndDate,
                                  controllingAppStartDate: init.controllingAppStartDate,
                                  paidTo: init.paidTo,
                                  definition: init.definition,
                                  controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                  controllingCorpTypeID: init.controllingCorpTypeID,
                                  vmeDestination: init.vmeDestination,
                                  marketingCorpType: init.marketingCorpType,
                                  marketingCorpCategory: init.marketingCorpCategory,
                                  appSubChannel: status,
                                  appClientChannel: init.appClientChannel,
                                  distributionMode: init.distributionMode,
                                  brand: init.brand,
                                  appCorpChannel: init.appCorpChannel,
                                  localActionTrigger: init.localActionTrigger,
                                  localActionDescription: init.localActionDescription,
                                  localActionTypeLabel: init.localActionTypeLabel,
                                  localActionTypeID: init.localActionTypeID,
                                  countryCode: init.countryCode
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAppClientChannelField */10 :
                  var result$10 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.appClientChannel, validators_appClientChannel, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  controllingAppEndDate: init.controllingAppEndDate,
                                  controllingAppStartDate: init.controllingAppStartDate,
                                  paidTo: init.paidTo,
                                  definition: init.definition,
                                  controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                  controllingCorpTypeID: init.controllingCorpTypeID,
                                  vmeDestination: init.vmeDestination,
                                  marketingCorpType: init.marketingCorpType,
                                  marketingCorpCategory: init.marketingCorpCategory,
                                  appSubChannel: init.appSubChannel,
                                  appClientChannel: status,
                                  distributionMode: init.distributionMode,
                                  brand: init.brand,
                                  appCorpChannel: init.appCorpChannel,
                                  localActionTrigger: init.localActionTrigger,
                                  localActionDescription: init.localActionDescription,
                                  localActionTypeLabel: init.localActionTypeLabel,
                                  localActionTypeID: init.localActionTypeID,
                                  countryCode: init.countryCode
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDistributionModeField */11 :
                  var result$11 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.distributionMode, validators_distributionMode, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  controllingAppEndDate: init.controllingAppEndDate,
                                  controllingAppStartDate: init.controllingAppStartDate,
                                  paidTo: init.paidTo,
                                  definition: init.definition,
                                  controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                  controllingCorpTypeID: init.controllingCorpTypeID,
                                  vmeDestination: init.vmeDestination,
                                  marketingCorpType: init.marketingCorpType,
                                  marketingCorpCategory: init.marketingCorpCategory,
                                  appSubChannel: init.appSubChannel,
                                  appClientChannel: init.appClientChannel,
                                  distributionMode: status,
                                  brand: init.brand,
                                  appCorpChannel: init.appCorpChannel,
                                  localActionTrigger: init.localActionTrigger,
                                  localActionDescription: init.localActionDescription,
                                  localActionTypeLabel: init.localActionTypeLabel,
                                  localActionTypeID: init.localActionTypeID,
                                  countryCode: init.countryCode
                                };
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBrandField */12 :
                  var result$12 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.brand, validators_brand, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  controllingAppEndDate: init.controllingAppEndDate,
                                  controllingAppStartDate: init.controllingAppStartDate,
                                  paidTo: init.paidTo,
                                  definition: init.definition,
                                  controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                  controllingCorpTypeID: init.controllingCorpTypeID,
                                  vmeDestination: init.vmeDestination,
                                  marketingCorpType: init.marketingCorpType,
                                  marketingCorpCategory: init.marketingCorpCategory,
                                  appSubChannel: init.appSubChannel,
                                  appClientChannel: init.appClientChannel,
                                  distributionMode: init.distributionMode,
                                  brand: status,
                                  appCorpChannel: init.appCorpChannel,
                                  localActionTrigger: init.localActionTrigger,
                                  localActionDescription: init.localActionDescription,
                                  localActionTypeLabel: init.localActionTypeLabel,
                                  localActionTypeID: init.localActionTypeID,
                                  countryCode: init.countryCode
                                };
                        }));
                  if (result$12 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$12,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAppCorpChannelField */13 :
                  var result$13 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.appCorpChannel, validators_appCorpChannel, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  controllingAppEndDate: init.controllingAppEndDate,
                                  controllingAppStartDate: init.controllingAppStartDate,
                                  paidTo: init.paidTo,
                                  definition: init.definition,
                                  controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                  controllingCorpTypeID: init.controllingCorpTypeID,
                                  vmeDestination: init.vmeDestination,
                                  marketingCorpType: init.marketingCorpType,
                                  marketingCorpCategory: init.marketingCorpCategory,
                                  appSubChannel: init.appSubChannel,
                                  appClientChannel: init.appClientChannel,
                                  distributionMode: init.distributionMode,
                                  brand: init.brand,
                                  appCorpChannel: status,
                                  localActionTrigger: init.localActionTrigger,
                                  localActionDescription: init.localActionDescription,
                                  localActionTypeLabel: init.localActionTypeLabel,
                                  localActionTypeID: init.localActionTypeID,
                                  countryCode: init.countryCode
                                };
                        }));
                  if (result$13 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$13,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurLocalActionTriggerField */14 :
                  var result$14 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.localActionTrigger, validators_localActionTrigger, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  controllingAppEndDate: init.controllingAppEndDate,
                                  controllingAppStartDate: init.controllingAppStartDate,
                                  paidTo: init.paidTo,
                                  definition: init.definition,
                                  controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                  controllingCorpTypeID: init.controllingCorpTypeID,
                                  vmeDestination: init.vmeDestination,
                                  marketingCorpType: init.marketingCorpType,
                                  marketingCorpCategory: init.marketingCorpCategory,
                                  appSubChannel: init.appSubChannel,
                                  appClientChannel: init.appClientChannel,
                                  distributionMode: init.distributionMode,
                                  brand: init.brand,
                                  appCorpChannel: init.appCorpChannel,
                                  localActionTrigger: status,
                                  localActionDescription: init.localActionDescription,
                                  localActionTypeLabel: init.localActionTypeLabel,
                                  localActionTypeID: init.localActionTypeID,
                                  countryCode: init.countryCode
                                };
                        }));
                  if (result$14 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$14,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurLocalActionDescriptionField */15 :
                  var result$15 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.localActionDescription, validators_localActionDescription, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  controllingAppEndDate: init.controllingAppEndDate,
                                  controllingAppStartDate: init.controllingAppStartDate,
                                  paidTo: init.paidTo,
                                  definition: init.definition,
                                  controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                  controllingCorpTypeID: init.controllingCorpTypeID,
                                  vmeDestination: init.vmeDestination,
                                  marketingCorpType: init.marketingCorpType,
                                  marketingCorpCategory: init.marketingCorpCategory,
                                  appSubChannel: init.appSubChannel,
                                  appClientChannel: init.appClientChannel,
                                  distributionMode: init.distributionMode,
                                  brand: init.brand,
                                  appCorpChannel: init.appCorpChannel,
                                  localActionTrigger: init.localActionTrigger,
                                  localActionDescription: status,
                                  localActionTypeLabel: init.localActionTypeLabel,
                                  localActionTypeID: init.localActionTypeID,
                                  countryCode: init.countryCode
                                };
                        }));
                  if (result$15 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$15,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurLocalActionTypeLabelField */16 :
                  var result$16 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.localActionTypeLabel, validators_localActionTypeLabel, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  controllingAppEndDate: init.controllingAppEndDate,
                                  controllingAppStartDate: init.controllingAppStartDate,
                                  paidTo: init.paidTo,
                                  definition: init.definition,
                                  controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                  controllingCorpTypeID: init.controllingCorpTypeID,
                                  vmeDestination: init.vmeDestination,
                                  marketingCorpType: init.marketingCorpType,
                                  marketingCorpCategory: init.marketingCorpCategory,
                                  appSubChannel: init.appSubChannel,
                                  appClientChannel: init.appClientChannel,
                                  distributionMode: init.distributionMode,
                                  brand: init.brand,
                                  appCorpChannel: init.appCorpChannel,
                                  localActionTrigger: init.localActionTrigger,
                                  localActionDescription: init.localActionDescription,
                                  localActionTypeLabel: status,
                                  localActionTypeID: init.localActionTypeID,
                                  countryCode: init.countryCode
                                };
                        }));
                  if (result$16 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$16,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurLocalActionTypeIDField */17 :
                  var result$17 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.localActionTypeID, validators_localActionTypeID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  controllingAppEndDate: init.controllingAppEndDate,
                                  controllingAppStartDate: init.controllingAppStartDate,
                                  paidTo: init.paidTo,
                                  definition: init.definition,
                                  controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                  controllingCorpTypeID: init.controllingCorpTypeID,
                                  vmeDestination: init.vmeDestination,
                                  marketingCorpType: init.marketingCorpType,
                                  marketingCorpCategory: init.marketingCorpCategory,
                                  appSubChannel: init.appSubChannel,
                                  appClientChannel: init.appClientChannel,
                                  distributionMode: init.distributionMode,
                                  brand: init.brand,
                                  appCorpChannel: init.appCorpChannel,
                                  localActionTrigger: init.localActionTrigger,
                                  localActionDescription: init.localActionDescription,
                                  localActionTypeLabel: init.localActionTypeLabel,
                                  localActionTypeID: status,
                                  countryCode: init.countryCode
                                };
                        }));
                  if (result$17 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$17,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCountryCodeField */18 :
                  var result$18 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.countryCode, validators_countryCode, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  controllingAppEndDate: init.controllingAppEndDate,
                                  controllingAppStartDate: init.controllingAppStartDate,
                                  paidTo: init.paidTo,
                                  definition: init.definition,
                                  controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                  controllingCorpTypeID: init.controllingCorpTypeID,
                                  vmeDestination: init.vmeDestination,
                                  marketingCorpType: init.marketingCorpType,
                                  marketingCorpCategory: init.marketingCorpCategory,
                                  appSubChannel: init.appSubChannel,
                                  appClientChannel: init.appClientChannel,
                                  distributionMode: init.distributionMode,
                                  brand: init.brand,
                                  appCorpChannel: init.appCorpChannel,
                                  localActionTrigger: init.localActionTrigger,
                                  localActionDescription: init.localActionDescription,
                                  localActionTypeLabel: init.localActionTypeLabel,
                                  localActionTypeID: init.localActionTypeID,
                                  countryCode: status
                                };
                        }));
                  if (result$18 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$18,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */19 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */19,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */20,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */22);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */21);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */20 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */21 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */22 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateControllingAppEndDateField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$19 = Formality.validateDependentFieldOnChange(nextInput, nextFieldsStatuses.contents.controllingAppStartDate, validators_controllingAppStartDate, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  controllingAppEndDate: init.controllingAppEndDate,
                                  controllingAppStartDate: status,
                                  paidTo: init.paidTo,
                                  definition: init.definition,
                                  controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                  controllingCorpTypeID: init.controllingCorpTypeID,
                                  vmeDestination: init.vmeDestination,
                                  marketingCorpType: init.marketingCorpType,
                                  marketingCorpCategory: init.marketingCorpCategory,
                                  appSubChannel: init.appSubChannel,
                                  appClientChannel: init.appClientChannel,
                                  distributionMode: init.distributionMode,
                                  brand: init.brand,
                                  appCorpChannel: init.appCorpChannel,
                                  localActionTrigger: init.localActionTrigger,
                                  localActionDescription: init.localActionDescription,
                                  localActionTypeLabel: init.localActionTypeLabel,
                                  localActionTypeID: init.localActionTypeID,
                                  countryCode: init.countryCode
                                };
                        }));
                  if (result$19 !== undefined) {
                    nextFieldsStatuses.contents = result$19;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, nextFieldsStatuses.contents.controllingAppEndDate, state.submissionStatus, validators_controllingAppEndDate, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            controllingAppEndDate: status,
                                            controllingAppStartDate: init.controllingAppStartDate,
                                            paidTo: init.paidTo,
                                            definition: init.definition,
                                            controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                            controllingCorpTypeID: init.controllingCorpTypeID,
                                            vmeDestination: init.vmeDestination,
                                            marketingCorpType: init.marketingCorpType,
                                            marketingCorpCategory: init.marketingCorpCategory,
                                            appSubChannel: init.appSubChannel,
                                            appClientChannel: init.appClientChannel,
                                            distributionMode: init.distributionMode,
                                            brand: init.brand,
                                            appCorpChannel: init.appCorpChannel,
                                            localActionTrigger: init.localActionTrigger,
                                            localActionDescription: init.localActionDescription,
                                            localActionTypeLabel: init.localActionTypeLabel,
                                            localActionTypeID: init.localActionTypeID,
                                            countryCode: init.countryCode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateControllingAppStartDateField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$20 = Formality.validateDependentFieldOnChange(nextInput$1, nextFieldsStatuses$1.contents.controllingAppEndDate, validators_controllingAppEndDate, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  controllingAppEndDate: status,
                                  controllingAppStartDate: init.controllingAppStartDate,
                                  paidTo: init.paidTo,
                                  definition: init.definition,
                                  controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                  controllingCorpTypeID: init.controllingCorpTypeID,
                                  vmeDestination: init.vmeDestination,
                                  marketingCorpType: init.marketingCorpType,
                                  marketingCorpCategory: init.marketingCorpCategory,
                                  appSubChannel: init.appSubChannel,
                                  appClientChannel: init.appClientChannel,
                                  distributionMode: init.distributionMode,
                                  brand: init.brand,
                                  appCorpChannel: init.appCorpChannel,
                                  localActionTrigger: init.localActionTrigger,
                                  localActionDescription: init.localActionDescription,
                                  localActionTypeLabel: init.localActionTypeLabel,
                                  localActionTypeID: init.localActionTypeID,
                                  countryCode: init.countryCode
                                };
                        }));
                  if (result$20 !== undefined) {
                    nextFieldsStatuses$1.contents = result$20;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, nextFieldsStatuses$1.contents.controllingAppStartDate, state.submissionStatus, validators_controllingAppStartDate, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            controllingAppEndDate: init.controllingAppEndDate,
                                            controllingAppStartDate: status,
                                            paidTo: init.paidTo,
                                            definition: init.definition,
                                            controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                            controllingCorpTypeID: init.controllingCorpTypeID,
                                            vmeDestination: init.vmeDestination,
                                            marketingCorpType: init.marketingCorpType,
                                            marketingCorpCategory: init.marketingCorpCategory,
                                            appSubChannel: init.appSubChannel,
                                            appClientChannel: init.appClientChannel,
                                            distributionMode: init.distributionMode,
                                            brand: init.brand,
                                            appCorpChannel: init.appCorpChannel,
                                            localActionTrigger: init.localActionTrigger,
                                            localActionDescription: init.localActionDescription,
                                            localActionTypeLabel: init.localActionTypeLabel,
                                            localActionTypeID: init.localActionTypeID,
                                            countryCode: init.countryCode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePaidToField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.paidTo, state.submissionStatus, validators_paidTo, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            controllingAppEndDate: init.controllingAppEndDate,
                                            controllingAppStartDate: init.controllingAppStartDate,
                                            paidTo: status,
                                            definition: init.definition,
                                            controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                            controllingCorpTypeID: init.controllingCorpTypeID,
                                            vmeDestination: init.vmeDestination,
                                            marketingCorpType: init.marketingCorpType,
                                            marketingCorpCategory: init.marketingCorpCategory,
                                            appSubChannel: init.appSubChannel,
                                            appClientChannel: init.appClientChannel,
                                            distributionMode: init.distributionMode,
                                            brand: init.brand,
                                            appCorpChannel: init.appCorpChannel,
                                            localActionTrigger: init.localActionTrigger,
                                            localActionDescription: init.localActionDescription,
                                            localActionTypeLabel: init.localActionTypeLabel,
                                            localActionTypeID: init.localActionTypeID,
                                            countryCode: init.countryCode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDefinitionField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.definition, state.submissionStatus, validators_definition, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            controllingAppEndDate: init.controllingAppEndDate,
                                            controllingAppStartDate: init.controllingAppStartDate,
                                            paidTo: init.paidTo,
                                            definition: status,
                                            controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                            controllingCorpTypeID: init.controllingCorpTypeID,
                                            vmeDestination: init.vmeDestination,
                                            marketingCorpType: init.marketingCorpType,
                                            marketingCorpCategory: init.marketingCorpCategory,
                                            appSubChannel: init.appSubChannel,
                                            appClientChannel: init.appClientChannel,
                                            distributionMode: init.distributionMode,
                                            brand: init.brand,
                                            appCorpChannel: init.appCorpChannel,
                                            localActionTrigger: init.localActionTrigger,
                                            localActionDescription: init.localActionDescription,
                                            localActionTypeLabel: init.localActionTypeLabel,
                                            localActionTypeID: init.localActionTypeID,
                                            countryCode: init.countryCode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateControllingCorpTypeLabelField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.controllingCorpTypeLabel, state.submissionStatus, validators_controllingCorpTypeLabel, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            controllingAppEndDate: init.controllingAppEndDate,
                                            controllingAppStartDate: init.controllingAppStartDate,
                                            paidTo: init.paidTo,
                                            definition: init.definition,
                                            controllingCorpTypeLabel: status,
                                            controllingCorpTypeID: init.controllingCorpTypeID,
                                            vmeDestination: init.vmeDestination,
                                            marketingCorpType: init.marketingCorpType,
                                            marketingCorpCategory: init.marketingCorpCategory,
                                            appSubChannel: init.appSubChannel,
                                            appClientChannel: init.appClientChannel,
                                            distributionMode: init.distributionMode,
                                            brand: init.brand,
                                            appCorpChannel: init.appCorpChannel,
                                            localActionTrigger: init.localActionTrigger,
                                            localActionDescription: init.localActionDescription,
                                            localActionTypeLabel: init.localActionTypeLabel,
                                            localActionTypeID: init.localActionTypeID,
                                            countryCode: init.countryCode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateControllingCorpTypeIDField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.controllingCorpTypeID, state.submissionStatus, validators_controllingCorpTypeID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            controllingAppEndDate: init.controllingAppEndDate,
                                            controllingAppStartDate: init.controllingAppStartDate,
                                            paidTo: init.paidTo,
                                            definition: init.definition,
                                            controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                            controllingCorpTypeID: status,
                                            vmeDestination: init.vmeDestination,
                                            marketingCorpType: init.marketingCorpType,
                                            marketingCorpCategory: init.marketingCorpCategory,
                                            appSubChannel: init.appSubChannel,
                                            appClientChannel: init.appClientChannel,
                                            distributionMode: init.distributionMode,
                                            brand: init.brand,
                                            appCorpChannel: init.appCorpChannel,
                                            localActionTrigger: init.localActionTrigger,
                                            localActionDescription: init.localActionDescription,
                                            localActionTypeLabel: init.localActionTypeLabel,
                                            localActionTypeID: init.localActionTypeID,
                                            countryCode: init.countryCode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVmeDestinationField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.vmeDestination, state.submissionStatus, validators_vmeDestination, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            controllingAppEndDate: init.controllingAppEndDate,
                                            controllingAppStartDate: init.controllingAppStartDate,
                                            paidTo: init.paidTo,
                                            definition: init.definition,
                                            controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                            controllingCorpTypeID: init.controllingCorpTypeID,
                                            vmeDestination: status,
                                            marketingCorpType: init.marketingCorpType,
                                            marketingCorpCategory: init.marketingCorpCategory,
                                            appSubChannel: init.appSubChannel,
                                            appClientChannel: init.appClientChannel,
                                            distributionMode: init.distributionMode,
                                            brand: init.brand,
                                            appCorpChannel: init.appCorpChannel,
                                            localActionTrigger: init.localActionTrigger,
                                            localActionDescription: init.localActionDescription,
                                            localActionTypeLabel: init.localActionTypeLabel,
                                            localActionTypeID: init.localActionTypeID,
                                            countryCode: init.countryCode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateMarketingCorpTypeField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$7, state.fieldsStatuses.marketingCorpType, state.submissionStatus, validators_marketingCorpType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            controllingAppEndDate: init.controllingAppEndDate,
                                            controllingAppStartDate: init.controllingAppStartDate,
                                            paidTo: init.paidTo,
                                            definition: init.definition,
                                            controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                            controllingCorpTypeID: init.controllingCorpTypeID,
                                            vmeDestination: init.vmeDestination,
                                            marketingCorpType: status,
                                            marketingCorpCategory: init.marketingCorpCategory,
                                            appSubChannel: init.appSubChannel,
                                            appClientChannel: init.appClientChannel,
                                            distributionMode: init.distributionMode,
                                            brand: init.brand,
                                            appCorpChannel: init.appCorpChannel,
                                            localActionTrigger: init.localActionTrigger,
                                            localActionDescription: init.localActionDescription,
                                            localActionTypeLabel: init.localActionTypeLabel,
                                            localActionTypeID: init.localActionTypeID,
                                            countryCode: init.countryCode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateMarketingCorpCategoryField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$8, state.fieldsStatuses.marketingCorpCategory, state.submissionStatus, validators_marketingCorpCategory, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            controllingAppEndDate: init.controllingAppEndDate,
                                            controllingAppStartDate: init.controllingAppStartDate,
                                            paidTo: init.paidTo,
                                            definition: init.definition,
                                            controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                            controllingCorpTypeID: init.controllingCorpTypeID,
                                            vmeDestination: init.vmeDestination,
                                            marketingCorpType: init.marketingCorpType,
                                            marketingCorpCategory: status,
                                            appSubChannel: init.appSubChannel,
                                            appClientChannel: init.appClientChannel,
                                            distributionMode: init.distributionMode,
                                            brand: init.brand,
                                            appCorpChannel: init.appCorpChannel,
                                            localActionTrigger: init.localActionTrigger,
                                            localActionDescription: init.localActionDescription,
                                            localActionTypeLabel: init.localActionTypeLabel,
                                            localActionTypeID: init.localActionTypeID,
                                            countryCode: init.countryCode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAppSubChannelField */9 :
                  var nextInput$9 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$9, state.fieldsStatuses.appSubChannel, state.submissionStatus, validators_appSubChannel, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            controllingAppEndDate: init.controllingAppEndDate,
                                            controllingAppStartDate: init.controllingAppStartDate,
                                            paidTo: init.paidTo,
                                            definition: init.definition,
                                            controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                            controllingCorpTypeID: init.controllingCorpTypeID,
                                            vmeDestination: init.vmeDestination,
                                            marketingCorpType: init.marketingCorpType,
                                            marketingCorpCategory: init.marketingCorpCategory,
                                            appSubChannel: status,
                                            appClientChannel: init.appClientChannel,
                                            distributionMode: init.distributionMode,
                                            brand: init.brand,
                                            appCorpChannel: init.appCorpChannel,
                                            localActionTrigger: init.localActionTrigger,
                                            localActionDescription: init.localActionDescription,
                                            localActionTypeLabel: init.localActionTypeLabel,
                                            localActionTypeID: init.localActionTypeID,
                                            countryCode: init.countryCode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAppClientChannelField */10 :
                  var nextInput$10 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$10, state.fieldsStatuses.appClientChannel, state.submissionStatus, validators_appClientChannel, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            controllingAppEndDate: init.controllingAppEndDate,
                                            controllingAppStartDate: init.controllingAppStartDate,
                                            paidTo: init.paidTo,
                                            definition: init.definition,
                                            controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                            controllingCorpTypeID: init.controllingCorpTypeID,
                                            vmeDestination: init.vmeDestination,
                                            marketingCorpType: init.marketingCorpType,
                                            marketingCorpCategory: init.marketingCorpCategory,
                                            appSubChannel: init.appSubChannel,
                                            appClientChannel: status,
                                            distributionMode: init.distributionMode,
                                            brand: init.brand,
                                            appCorpChannel: init.appCorpChannel,
                                            localActionTrigger: init.localActionTrigger,
                                            localActionDescription: init.localActionDescription,
                                            localActionTypeLabel: init.localActionTypeLabel,
                                            localActionTypeID: init.localActionTypeID,
                                            countryCode: init.countryCode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDistributionModeField */11 :
                  var nextInput$11 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$11, state.fieldsStatuses.distributionMode, state.submissionStatus, validators_distributionMode, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            controllingAppEndDate: init.controllingAppEndDate,
                                            controllingAppStartDate: init.controllingAppStartDate,
                                            paidTo: init.paidTo,
                                            definition: init.definition,
                                            controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                            controllingCorpTypeID: init.controllingCorpTypeID,
                                            vmeDestination: init.vmeDestination,
                                            marketingCorpType: init.marketingCorpType,
                                            marketingCorpCategory: init.marketingCorpCategory,
                                            appSubChannel: init.appSubChannel,
                                            appClientChannel: init.appClientChannel,
                                            distributionMode: status,
                                            brand: init.brand,
                                            appCorpChannel: init.appCorpChannel,
                                            localActionTrigger: init.localActionTrigger,
                                            localActionDescription: init.localActionDescription,
                                            localActionTypeLabel: init.localActionTypeLabel,
                                            localActionTypeID: init.localActionTypeID,
                                            countryCode: init.countryCode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBrandField */12 :
                  var nextInput$12 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$12, state.fieldsStatuses.brand, state.submissionStatus, validators_brand, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            controllingAppEndDate: init.controllingAppEndDate,
                                            controllingAppStartDate: init.controllingAppStartDate,
                                            paidTo: init.paidTo,
                                            definition: init.definition,
                                            controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                            controllingCorpTypeID: init.controllingCorpTypeID,
                                            vmeDestination: init.vmeDestination,
                                            marketingCorpType: init.marketingCorpType,
                                            marketingCorpCategory: init.marketingCorpCategory,
                                            appSubChannel: init.appSubChannel,
                                            appClientChannel: init.appClientChannel,
                                            distributionMode: init.distributionMode,
                                            brand: status,
                                            appCorpChannel: init.appCorpChannel,
                                            localActionTrigger: init.localActionTrigger,
                                            localActionDescription: init.localActionDescription,
                                            localActionTypeLabel: init.localActionTypeLabel,
                                            localActionTypeID: init.localActionTypeID,
                                            countryCode: init.countryCode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAppCorpChannelField */13 :
                  var nextInput$13 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$13,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$13, state.fieldsStatuses.appCorpChannel, state.submissionStatus, validators_appCorpChannel, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            controllingAppEndDate: init.controllingAppEndDate,
                                            controllingAppStartDate: init.controllingAppStartDate,
                                            paidTo: init.paidTo,
                                            definition: init.definition,
                                            controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                            controllingCorpTypeID: init.controllingCorpTypeID,
                                            vmeDestination: init.vmeDestination,
                                            marketingCorpType: init.marketingCorpType,
                                            marketingCorpCategory: init.marketingCorpCategory,
                                            appSubChannel: init.appSubChannel,
                                            appClientChannel: init.appClientChannel,
                                            distributionMode: init.distributionMode,
                                            brand: init.brand,
                                            appCorpChannel: status,
                                            localActionTrigger: init.localActionTrigger,
                                            localActionDescription: init.localActionDescription,
                                            localActionTypeLabel: init.localActionTypeLabel,
                                            localActionTypeID: init.localActionTypeID,
                                            countryCode: init.countryCode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateLocalActionTriggerField */14 :
                  var nextInput$14 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$14,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$14, state.fieldsStatuses.localActionTrigger, state.submissionStatus, validators_localActionTrigger, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            controllingAppEndDate: init.controllingAppEndDate,
                                            controllingAppStartDate: init.controllingAppStartDate,
                                            paidTo: init.paidTo,
                                            definition: init.definition,
                                            controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                            controllingCorpTypeID: init.controllingCorpTypeID,
                                            vmeDestination: init.vmeDestination,
                                            marketingCorpType: init.marketingCorpType,
                                            marketingCorpCategory: init.marketingCorpCategory,
                                            appSubChannel: init.appSubChannel,
                                            appClientChannel: init.appClientChannel,
                                            distributionMode: init.distributionMode,
                                            brand: init.brand,
                                            appCorpChannel: init.appCorpChannel,
                                            localActionTrigger: status,
                                            localActionDescription: init.localActionDescription,
                                            localActionTypeLabel: init.localActionTypeLabel,
                                            localActionTypeID: init.localActionTypeID,
                                            countryCode: init.countryCode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateLocalActionDescriptionField */15 :
                  var nextInput$15 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$15,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$15, state.fieldsStatuses.localActionDescription, state.submissionStatus, validators_localActionDescription, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            controllingAppEndDate: init.controllingAppEndDate,
                                            controllingAppStartDate: init.controllingAppStartDate,
                                            paidTo: init.paidTo,
                                            definition: init.definition,
                                            controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                            controllingCorpTypeID: init.controllingCorpTypeID,
                                            vmeDestination: init.vmeDestination,
                                            marketingCorpType: init.marketingCorpType,
                                            marketingCorpCategory: init.marketingCorpCategory,
                                            appSubChannel: init.appSubChannel,
                                            appClientChannel: init.appClientChannel,
                                            distributionMode: init.distributionMode,
                                            brand: init.brand,
                                            appCorpChannel: init.appCorpChannel,
                                            localActionTrigger: init.localActionTrigger,
                                            localActionDescription: status,
                                            localActionTypeLabel: init.localActionTypeLabel,
                                            localActionTypeID: init.localActionTypeID,
                                            countryCode: init.countryCode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateLocalActionTypeLabelField */16 :
                  var nextInput$16 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$16,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$16, state.fieldsStatuses.localActionTypeLabel, state.submissionStatus, validators_localActionTypeLabel, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            controllingAppEndDate: init.controllingAppEndDate,
                                            controllingAppStartDate: init.controllingAppStartDate,
                                            paidTo: init.paidTo,
                                            definition: init.definition,
                                            controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                            controllingCorpTypeID: init.controllingCorpTypeID,
                                            vmeDestination: init.vmeDestination,
                                            marketingCorpType: init.marketingCorpType,
                                            marketingCorpCategory: init.marketingCorpCategory,
                                            appSubChannel: init.appSubChannel,
                                            appClientChannel: init.appClientChannel,
                                            distributionMode: init.distributionMode,
                                            brand: init.brand,
                                            appCorpChannel: init.appCorpChannel,
                                            localActionTrigger: init.localActionTrigger,
                                            localActionDescription: init.localActionDescription,
                                            localActionTypeLabel: status,
                                            localActionTypeID: init.localActionTypeID,
                                            countryCode: init.countryCode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateLocalActionTypeIDField */17 :
                  var nextInput$17 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$17,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$17, state.fieldsStatuses.localActionTypeID, state.submissionStatus, validators_localActionTypeID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            controllingAppEndDate: init.controllingAppEndDate,
                                            controllingAppStartDate: init.controllingAppStartDate,
                                            paidTo: init.paidTo,
                                            definition: init.definition,
                                            controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                            controllingCorpTypeID: init.controllingCorpTypeID,
                                            vmeDestination: init.vmeDestination,
                                            marketingCorpType: init.marketingCorpType,
                                            marketingCorpCategory: init.marketingCorpCategory,
                                            appSubChannel: init.appSubChannel,
                                            appClientChannel: init.appClientChannel,
                                            distributionMode: init.distributionMode,
                                            brand: init.brand,
                                            appCorpChannel: init.appCorpChannel,
                                            localActionTrigger: init.localActionTrigger,
                                            localActionDescription: init.localActionDescription,
                                            localActionTypeLabel: init.localActionTypeLabel,
                                            localActionTypeID: status,
                                            countryCode: init.countryCode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCountryCodeField */18 :
                  var nextInput$18 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$18,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$18, state.fieldsStatuses.countryCode, state.submissionStatus, validators_countryCode, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            controllingAppEndDate: init.controllingAppEndDate,
                                            controllingAppStartDate: init.controllingAppStartDate,
                                            paidTo: init.paidTo,
                                            definition: init.definition,
                                            controllingCorpTypeLabel: init.controllingCorpTypeLabel,
                                            controllingCorpTypeID: init.controllingCorpTypeID,
                                            vmeDestination: init.vmeDestination,
                                            marketingCorpType: init.marketingCorpType,
                                            marketingCorpCategory: init.marketingCorpCategory,
                                            appSubChannel: init.appSubChannel,
                                            appClientChannel: init.appClientChannel,
                                            distributionMode: init.distributionMode,
                                            brand: init.brand,
                                            appCorpChannel: init.appCorpChannel,
                                            localActionTrigger: init.localActionTrigger,
                                            localActionDescription: init.localActionDescription,
                                            localActionTypeLabel: init.localActionTypeLabel,
                                            localActionTypeID: init.localActionTypeID,
                                            countryCode: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */19 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */20 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */21 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateControllingAppEndDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateControllingAppEndDateField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateControllingAppStartDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateControllingAppStartDateField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updatePaidTo: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePaidToField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDefinition: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDefinitionField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateControllingCorpTypeLabel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateControllingCorpTypeLabelField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateControllingCorpTypeID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateControllingCorpTypeIDField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVmeDestination: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVmeDestinationField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateMarketingCorpType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateMarketingCorpTypeField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateMarketingCorpCategory: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateMarketingCorpCategoryField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateAppSubChannel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAppSubChannelField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateAppClientChannel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAppClientChannelField */10,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDistributionMode: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDistributionModeField */11,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBrand: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBrandField */12,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateAppCorpChannel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAppCorpChannelField */13,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateLocalActionTrigger: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateLocalActionTriggerField */14,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateLocalActionDescription: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateLocalActionDescriptionField */15,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateLocalActionTypeLabel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateLocalActionTypeLabelField */16,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateLocalActionTypeID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateLocalActionTypeIDField */17,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCountryCode: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCountryCodeField */18,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurControllingAppEndDate: (function (param) {
              Curry._1(dispatch, /* BlurControllingAppEndDateField */0);
            }),
          blurControllingAppStartDate: (function (param) {
              Curry._1(dispatch, /* BlurControllingAppStartDateField */1);
            }),
          blurPaidTo: (function (param) {
              Curry._1(dispatch, /* BlurPaidToField */2);
            }),
          blurDefinition: (function (param) {
              Curry._1(dispatch, /* BlurDefinitionField */3);
            }),
          blurControllingCorpTypeLabel: (function (param) {
              Curry._1(dispatch, /* BlurControllingCorpTypeLabelField */4);
            }),
          blurControllingCorpTypeID: (function (param) {
              Curry._1(dispatch, /* BlurControllingCorpTypeIDField */5);
            }),
          blurVmeDestination: (function (param) {
              Curry._1(dispatch, /* BlurVmeDestinationField */6);
            }),
          blurMarketingCorpType: (function (param) {
              Curry._1(dispatch, /* BlurMarketingCorpTypeField */7);
            }),
          blurMarketingCorpCategory: (function (param) {
              Curry._1(dispatch, /* BlurMarketingCorpCategoryField */8);
            }),
          blurAppSubChannel: (function (param) {
              Curry._1(dispatch, /* BlurAppSubChannelField */9);
            }),
          blurAppClientChannel: (function (param) {
              Curry._1(dispatch, /* BlurAppClientChannelField */10);
            }),
          blurDistributionMode: (function (param) {
              Curry._1(dispatch, /* BlurDistributionModeField */11);
            }),
          blurBrand: (function (param) {
              Curry._1(dispatch, /* BlurBrandField */12);
            }),
          blurAppCorpChannel: (function (param) {
              Curry._1(dispatch, /* BlurAppCorpChannelField */13);
            }),
          blurLocalActionTrigger: (function (param) {
              Curry._1(dispatch, /* BlurLocalActionTriggerField */14);
            }),
          blurLocalActionDescription: (function (param) {
              Curry._1(dispatch, /* BlurLocalActionDescriptionField */15);
            }),
          blurLocalActionTypeLabel: (function (param) {
              Curry._1(dispatch, /* BlurLocalActionTypeLabelField */16);
            }),
          blurLocalActionTypeID: (function (param) {
              Curry._1(dispatch, /* BlurLocalActionTypeIDField */17);
            }),
          blurCountryCode: (function (param) {
              Curry._1(dispatch, /* BlurCountryCodeField */18);
            }),
          controllingAppEndDateResult: Formality.exposeFieldResult(state.fieldsStatuses.controllingAppEndDate),
          controllingAppStartDateResult: Formality.exposeFieldResult(state.fieldsStatuses.controllingAppStartDate),
          paidToResult: Formality.exposeFieldResult(state.fieldsStatuses.paidTo),
          definitionResult: Formality.exposeFieldResult(state.fieldsStatuses.definition),
          controllingCorpTypeLabelResult: Formality.exposeFieldResult(state.fieldsStatuses.controllingCorpTypeLabel),
          controllingCorpTypeIDResult: Formality.exposeFieldResult(state.fieldsStatuses.controllingCorpTypeID),
          vmeDestinationResult: Formality.exposeFieldResult(state.fieldsStatuses.vmeDestination),
          marketingCorpTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.marketingCorpType),
          marketingCorpCategoryResult: Formality.exposeFieldResult(state.fieldsStatuses.marketingCorpCategory),
          appSubChannelResult: Formality.exposeFieldResult(state.fieldsStatuses.appSubChannel),
          appClientChannelResult: Formality.exposeFieldResult(state.fieldsStatuses.appClientChannel),
          distributionModeResult: Formality.exposeFieldResult(state.fieldsStatuses.distributionMode),
          brandResult: Formality.exposeFieldResult(state.fieldsStatuses.brand),
          appCorpChannelResult: Formality.exposeFieldResult(state.fieldsStatuses.appCorpChannel),
          localActionTriggerResult: Formality.exposeFieldResult(state.fieldsStatuses.localActionTrigger),
          localActionDescriptionResult: Formality.exposeFieldResult(state.fieldsStatuses.localActionDescription),
          localActionTypeLabelResult: Formality.exposeFieldResult(state.fieldsStatuses.localActionTypeLabel),
          localActionTypeIDResult: Formality.exposeFieldResult(state.fieldsStatuses.localActionTypeID),
          countryCodeResult: Formality.exposeFieldResult(state.fieldsStatuses.countryCode),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              var exit = 0;
              if (match.controllingAppEndDate || match.controllingAppStartDate || match.paidTo || match.definition || match.controllingCorpTypeLabel || match.controllingCorpTypeID || match.vmeDestination || match.marketingCorpType || match.marketingCorpCategory || match.appSubChannel || match.appClientChannel || match.distributionMode || match.brand || match.appCorpChannel || match.localActionTrigger || match.localActionDescription || match.localActionTypeLabel || match.localActionTypeID) {
                exit = 1;
              } else {
                if (!match.countryCode) {
                  return false;
                }
                exit = 1;
              }
              if (exit === 1) {
                var exit$1 = 0;
                exit$1 = 2;
                if (exit$1 === 2) {
                  var exit$2 = 0;
                  exit$2 = 3;
                  if (exit$2 === 3) {
                    var exit$3 = 0;
                    exit$3 = 4;
                    if (exit$3 === 4) {
                      var exit$4 = 0;
                      exit$4 = 5;
                      if (exit$4 === 5) {
                        var exit$5 = 0;
                        exit$5 = 6;
                        if (exit$5 === 6) {
                          var exit$6 = 0;
                          exit$6 = 7;
                          if (exit$6 === 7) {
                            var exit$7 = 0;
                            exit$7 = 8;
                            if (exit$7 === 8) {
                              return true;
                            }
                            
                          }
                          
                        }
                        
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */19);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */20);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */21);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */21,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */22);
            })
        };
}

var Form = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  countryCode: "",
  localActionTypeID: "",
  localActionTypeLabel: "",
  localActionDescription: "",
  localActionTrigger: "",
  appCorpChannel: "",
  brand: "",
  distributionMode: "",
  appClientChannel: "",
  appSubChannel: "",
  marketingCorpCategory: "",
  marketingCorpType: "",
  vmeDestination: "",
  controllingCorpTypeID: "",
  controllingCorpTypeLabel: "",
  definition: "",
  paidTo: "",
  controllingAppStartDate: undefined,
  controllingAppEndDate: undefined
};

export {
  validateDate ,
  Form ,
  initialState$1 as initialState,
}
/* react Not a pure module */
