// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../common/utils/Utils_Form.bs.js";

var validators_endDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequiredDateCompare(param.startDate, param.endDate, "larger", true, true, undefined);
    })
};

var validators_startDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequiredDateCompare(param.startDate, param.endDate, "smaller", true, true, undefined);
    })
};

var validators_amount = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateFloatPositiveRequired(param.amount);
    })
};

var validators_budgetType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.budgetType);
    })
};

var validators = {
  endDate: validators_endDate,
  startDate: validators_startDate,
  amount: validators_amount,
  budgetType: validators_budgetType
};

function initialFieldsStatuses(_input) {
  return {
          endDate: /* Pristine */0,
          startDate: /* Pristine */0,
          amount: /* Pristine */0,
          budgetType: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            endDate: /* Pristine */0,
            startDate: /* Pristine */0,
            amount: /* Pristine */0,
            budgetType: /* Pristine */0
          },
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.endDate;
  var match_0 = match ? match._0 : Curry._1(validators.endDate.validate, input);
  var match$1 = fieldsStatuses.startDate;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.startDate.validate, input);
  var match$2 = fieldsStatuses.amount;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.amount.validate, input);
  var match$3 = fieldsStatuses.budgetType;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.budgetType.validate, input);
  var endDateResult = match_0;
  var endDateResult$1;
  if (endDateResult.TAG === /* Ok */0) {
    var startDateResult = match_0$1;
    if (startDateResult.TAG === /* Ok */0) {
      var amountResult = match_0$2;
      if (amountResult.TAG === /* Ok */0) {
        var budgetTypeResult = match_0$3;
        if (budgetTypeResult.TAG === /* Ok */0) {
          return {
                  TAG: /* Valid */0,
                  output: {
                    budgetType: budgetTypeResult._0,
                    amount: amountResult._0,
                    startDate: startDateResult._0,
                    endDate: endDateResult._0
                  },
                  fieldsStatuses: {
                    endDate: /* Dirty */{
                      _0: endDateResult,
                      _1: /* Shown */0
                    },
                    startDate: /* Dirty */{
                      _0: startDateResult,
                      _1: /* Shown */0
                    },
                    amount: /* Dirty */{
                      _0: amountResult,
                      _1: /* Shown */0
                    },
                    budgetType: /* Dirty */{
                      _0: budgetTypeResult,
                      _1: /* Shown */0
                    }
                  },
                  collectionsStatuses: undefined
                };
        }
        endDateResult$1 = endDateResult;
      } else {
        endDateResult$1 = endDateResult;
      }
    } else {
      endDateResult$1 = endDateResult;
    }
  } else {
    endDateResult$1 = endDateResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            endDate: /* Dirty */{
              _0: endDateResult$1,
              _1: /* Shown */0
            },
            startDate: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            amount: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            budgetType: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurEndDateField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.endDate, validators_endDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: status,
                                  startDate: init.startDate,
                                  amount: init.amount,
                                  budgetType: init.budgetType
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurStartDateField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.startDate, validators_startDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: init.endDate,
                                  startDate: status,
                                  amount: init.amount,
                                  budgetType: init.budgetType
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAmountField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.amount, validators_amount, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  amount: status,
                                  budgetType: init.budgetType
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBudgetTypeField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.budgetType, validators_budgetType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  amount: init.amount,
                                  budgetType: status
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */4 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */4,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */5,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */7);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */6);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */5 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */6 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */7 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateEndDateField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$4 = Formality.validateDependentFieldOnChange(nextInput, nextFieldsStatuses.contents.startDate, validators_startDate, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  endDate: init.endDate,
                                  startDate: status,
                                  amount: init.amount,
                                  budgetType: init.budgetType
                                };
                        }));
                  if (result$4 !== undefined) {
                    nextFieldsStatuses.contents = result$4;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, nextFieldsStatuses.contents.endDate, state.submissionStatus, validators_endDate, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            endDate: status,
                                            startDate: init.startDate,
                                            amount: init.amount,
                                            budgetType: init.budgetType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateStartDateField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$5 = Formality.validateDependentFieldOnChange(nextInput$1, nextFieldsStatuses$1.contents.endDate, validators_endDate, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  endDate: status,
                                  startDate: init.startDate,
                                  amount: init.amount,
                                  budgetType: init.budgetType
                                };
                        }));
                  if (result$5 !== undefined) {
                    nextFieldsStatuses$1.contents = result$5;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, nextFieldsStatuses$1.contents.startDate, state.submissionStatus, validators_startDate, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            endDate: init.endDate,
                                            startDate: status,
                                            amount: init.amount,
                                            budgetType: init.budgetType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAmountField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.amount, state.submissionStatus, validators_amount, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            amount: status,
                                            budgetType: init.budgetType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBudgetTypeField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.budgetType, state.submissionStatus, validators_budgetType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            amount: init.amount,
                                            budgetType: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */4 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                endDate: /* Pristine */0,
                                startDate: /* Pristine */0,
                                amount: /* Pristine */0,
                                budgetType: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                endDate: /* Pristine */0,
                                startDate: /* Pristine */0,
                                amount: /* Pristine */0,
                                budgetType: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */5 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */6 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateEndDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEndDateField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateStartDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateStartDateField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateAmount: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAmountField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBudgetType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBudgetTypeField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurEndDate: (function (param) {
              Curry._1(dispatch, /* BlurEndDateField */0);
            }),
          blurStartDate: (function (param) {
              Curry._1(dispatch, /* BlurStartDateField */1);
            }),
          blurAmount: (function (param) {
              Curry._1(dispatch, /* BlurAmountField */2);
            }),
          blurBudgetType: (function (param) {
              Curry._1(dispatch, /* BlurBudgetTypeField */3);
            }),
          endDateResult: Formality.exposeFieldResult(state.fieldsStatuses.endDate),
          startDateResult: Formality.exposeFieldResult(state.fieldsStatuses.startDate),
          amountResult: Formality.exposeFieldResult(state.fieldsStatuses.amount),
          budgetTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.budgetType),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.endDate || match.startDate || match.amount || match.budgetType) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */4);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */5);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */6);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */6,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */7);
            })
        };
}

var Form = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialInput = {
  budgetType: "",
  amount: "",
  startDate: undefined,
  endDate: undefined
};

export {
  Form ,
  initialInput ,
}
/* react Not a pure module */
