// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as App_Spinner$DvmAdminFrontendFr from "../../../common/components/App_Spinner.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as App_Table_Result$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Result.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Company_Manage_Api$DvmAdminFrontendFr from "./Company_Manage_Api.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Pagination.bs.js";
import * as Company_Manage_Modal$DvmAdminFrontendFr from "./modal/Company_Manage_Modal.bs.js";
import * as Company_Manage_Table$DvmAdminFrontendFr from "./Company_Manage_Table.bs.js";
import * as Company_Manage_Filter$DvmAdminFrontendFr from "./Company_Manage_Filter.bs.js";
import * as Messages_Company_List$DvmAdminFrontendFr from "../../../intl/messages/company/Messages_Company_List.bs.js";
import * as App_Table_Create_Button$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Create_Button.bs.js";
import * as Messages_Company_Manage$DvmAdminFrontendFr from "../../../intl/messages/company/Messages_Company_Manage.bs.js";

function Company_Manage_Page(Props) {
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match[1];
  var tablePage = match[0];
  var match$1 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: Caml_array.get(App_Table_Pagination$DvmAdminFrontendFr.options, 0),
                headers: Company_Manage_Table$DvmAdminFrontendFr.headersConfiguration,
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$1[1];
  var fetchRequest = match$1[0];
  var alert = ReactAlert.useAlert();
  var match$2 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$3 = match$2[0];
  var userManager = match$3.userManager;
  var selectedRole = match$3.selectedRole;
  var match$4 = React.useState(function () {
        return false;
      });
  var setModal = match$4[1];
  var fetchData = function (submissionCallbacks, fetchRequest) {
    Company_Manage_Api$DvmAdminFrontendFr.fetchCompanySearch(submissionCallbacks, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, fetchRequest), selectedRole);
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  var refresh = function (param) {
    fetchData(undefined, fetchRequest);
  };
  React.useEffect((function () {
          fetchData(undefined, fetchRequest);
        }), []);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.forError(tablePage, (function (error) {
                  Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                            message: {
                              TAG: /* RestError */2,
                              _0: error
                            }
                          }));
                }));
        }), [tablePage]);
  var tmp;
  var exit = 0;
  if (typeof tablePage === "number") {
    exit = 1;
  } else {
    tmp = tablePage.TAG === /* Ok */0 ? React.createElement(App_Table_Result$DvmAdminFrontendFr.make, {
            number: tablePage._0.total,
            message: Messages_Company_List$DvmAdminFrontendFr.resultInfo
          }) : null;
  }
  if (exit === 1) {
    tmp = React.createElement(App_Spinner$DvmAdminFrontendFr.make, {});
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  style: {
                    fontWeight: "600"
                  },
                  children: intl.formatMessage(Messages_Company_Manage$DvmAdminFrontendFr.pageTitle),
                  variant: "h3"
                }), React.createElement(Company_Manage_Filter$DvmAdminFrontendFr.make, {
                  fetchData: fetchData,
                  fetchRequest: fetchRequest
                }), React.createElement(Core.Box, {
                  children: null
                }, tmp, React.createElement(Company_Manage_Table$DvmAdminFrontendFr.make, {
                      tablePage: tablePage,
                      fetchRequest: fetchRequest,
                      fetchData: fetchData,
                      refresh: refresh,
                      createCompanyElement: React.createElement(App_Table_Create_Button$DvmAdminFrontendFr.make, {
                            onClick: (function (param) {
                                Curry._1(setModal, (function (param) {
                                        return true;
                                      }));
                              }),
                            message: Messages_Company_Manage$DvmAdminFrontendFr.createTitle
                          })
                    })), match$4[0] ? React.createElement(Company_Manage_Modal$DvmAdminFrontendFr.make, {
                    operation: /* Create */0,
                    onClose: (function (param) {
                        Curry._1(setModal, (function (param) {
                                return false;
                              }));
                      }),
                    refresh: refresh
                  }) : null);
}

var Api;

var Table;

var Filter;

var Modal;

var make = Company_Manage_Page;

export {
  Api ,
  Table ,
  Filter ,
  Modal ,
  make ,
}
/* react Not a pure module */
