// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReDate_utils from "@mobily/rescript-date/src/ReDate_utils.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../../common/utils/Utils_Date.bs.js";

function getQuery(row) {
  var start = Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(Caml_option.some(row.startDate)), "");
  var end = Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(row.endDate), "");
  var queryBudget = "budget=" + row.budgetType + "";
  var queryList = encodeURIComponent(Belt_Array.joinWith([
            queryBudget + "&&" + ("startDate=[\\\," + start + "]&&endDate=[" + start + "\\\,]"),
            queryBudget + "&&" + ("startDate=[\\\," + end + "]&&endDate=[" + end + "\\\,]"),
            queryBudget + "&&" + ("startDate=[" + start + "\\\,]&&endDate=[\\\," + end + "]"),
            queryBudget + "&&" + ("startDate=[\\\," + start + "]&&endDate=[" + end + "\\\,]")
          ], "||", (function (x) {
              return x;
            })));
  return "/margins/minimum?limit=10&offset=0&filter=" + queryList;
}

function removeOriginalFromQuery(listApi, original) {
  return Belt_List.keep(listApi, (function (apiElement) {
                var tmp = false;
                if (apiElement.budgetType === original.budgetType) {
                  var match = original.endDate;
                  var match$1 = apiElement.endDate;
                  var tmp$1;
                  if (match !== undefined && match$1 !== undefined) {
                    var originalStartDateVal = ReDate_utils.makeStartOfDayDate(original.startDate).valueOf();
                    var originalEndDateVal = ReDate_utils.makeStartOfDayDate(Caml_option.valFromOption(match)).valueOf();
                    var apiStartDateVal = ReDate_utils.makeStartOfDayDate(apiElement.startDate).valueOf();
                    var apiEndDateVal = ReDate_utils.makeStartOfDayDate(Caml_option.valFromOption(match$1)).valueOf();
                    tmp$1 = originalStartDateVal === apiStartDateVal && apiEndDateVal === originalEndDateVal;
                  } else {
                    tmp$1 = true;
                  }
                  tmp = tmp$1;
                }
                return !tmp;
              }));
}

export {
  getQuery ,
  removeOriginalFromQuery ,
}
/* Utils_Date-DvmAdminFrontendFr Not a pure module */
