// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../../../common/utils/Utils_Form.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Error_Inputs.bs.js";

var validators = {
  versions: {
    collection: (function (param) {
        if (param.versions.length !== 0) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                };
        }
      }),
    fields: {
      index: undefined,
      brand: undefined,
      model: undefined,
      version: undefined,
      versionLabel: undefined,
      customBonus: {
        strategy: /* OnFirstChange */1,
        validate: (function (param, at) {
            var customBonusFields = Belt_Option.map(Belt_Array.get(param.versions, at), (function (param) {
                    return param.customBonus;
                  }));
            if (customBonusFields !== undefined) {
              return Utils_Form$DvmAdminFrontendFr.validateFloatPositiveRequired(customBonusFields);
            } else {
              return {
                      TAG: /* Error */1,
                      _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                    };
            }
          })
      },
      originalBonus: undefined,
      actionBonus: undefined,
      isCustomFEValue: undefined,
      engineType: undefined,
      modelPhase: undefined
    }
  }
};

function initialFieldsStatuses(input) {
  return {
          versions: Belt_Array.make(input.versions.length, {
                index: /* Pristine */0,
                brand: /* Pristine */0,
                model: /* Pristine */0,
                version: /* Pristine */0,
                versionLabel: /* Pristine */0,
                customBonus: /* Pristine */0,
                originalBonus: /* Pristine */0,
                actionBonus: /* Pristine */0,
                isCustomFEValue: /* Pristine */0,
                engineType: /* Pristine */0,
                modelPhase: /* Pristine */0
              })
        };
}

var initialCollectionsStatuses = {
  versions: undefined
};

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: initialCollectionsStatuses,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = Curry._1(validators.versions.collection, input);
  var match$1 = Belt_Array.reduceWithIndex(fieldsStatuses.versions, [
        {
          TAG: /* Ok */0,
          _0: []
        },
        []
      ], (function (param, fieldStatus, index) {
          var statuses = param[1];
          var output = param[0];
          var match_0 = {
            TAG: /* Ok */0,
            _0: input.versions[index].index
          };
          var match_0$1 = {
            TAG: /* Ok */0,
            _0: input.versions[index].brand
          };
          var match_0$2 = {
            TAG: /* Ok */0,
            _0: input.versions[index].model
          };
          var match_0$3 = {
            TAG: /* Ok */0,
            _0: input.versions[index].version
          };
          var match_0$4 = {
            TAG: /* Ok */0,
            _0: input.versions[index].versionLabel
          };
          var match = fieldStatus.customBonus;
          var match_0$5 = match ? match._0 : Curry._2(validators.versions.fields.customBonus.validate, input, index);
          var match_0$6 = {
            TAG: /* Ok */0,
            _0: input.versions[index].originalBonus
          };
          var match_0$7 = {
            TAG: /* Ok */0,
            _0: input.versions[index].actionBonus
          };
          var match_0$8 = {
            TAG: /* Ok */0,
            _0: input.versions[index].isCustomFEValue
          };
          var match_0$9 = {
            TAG: /* Ok */0,
            _0: input.versions[index].engineType
          };
          var match_0$10 = {
            TAG: /* Ok */0,
            _0: input.versions[index].modelPhase
          };
          if (output.TAG === /* Ok */0) {
            var indexResult = match_0;
            var output$1 = output._0;
            if (indexResult.TAG === /* Ok */0) {
              var brandResult = match_0$1;
              if (brandResult.TAG === /* Ok */0) {
                var modelResult = match_0$2;
                if (modelResult.TAG === /* Ok */0) {
                  var versionResult = match_0$3;
                  if (versionResult.TAG === /* Ok */0) {
                    var versionLabelResult = match_0$4;
                    if (versionLabelResult.TAG === /* Ok */0) {
                      var customBonusResult = match_0$5;
                      if (customBonusResult.TAG === /* Ok */0) {
                        var originalBonusResult = match_0$6;
                        if (originalBonusResult.TAG === /* Ok */0) {
                          var actionBonusResult = match_0$7;
                          if (actionBonusResult.TAG === /* Ok */0) {
                            var isCustomFEValueResult = match_0$8;
                            if (isCustomFEValueResult.TAG === /* Ok */0) {
                              var engineTypeResult = match_0$9;
                              if (engineTypeResult.TAG === /* Ok */0) {
                                var modelPhaseResult = match_0$10;
                                if (modelPhaseResult.TAG === /* Ok */0) {
                                  output$1.push({
                                        index: indexResult._0,
                                        brand: brandResult._0,
                                        model: modelResult._0,
                                        version: versionResult._0,
                                        versionLabel: versionLabelResult._0,
                                        customBonus: customBonusResult._0,
                                        originalBonus: originalBonusResult._0,
                                        actionBonus: actionBonusResult._0,
                                        isCustomFEValue: isCustomFEValueResult._0,
                                        engineType: engineTypeResult._0,
                                        modelPhase: modelPhaseResult._0
                                      });
                                  statuses.push({
                                        index: /* Dirty */{
                                          _0: indexResult,
                                          _1: /* Hidden */1
                                        },
                                        brand: /* Dirty */{
                                          _0: brandResult,
                                          _1: /* Hidden */1
                                        },
                                        model: /* Dirty */{
                                          _0: modelResult,
                                          _1: /* Hidden */1
                                        },
                                        version: /* Dirty */{
                                          _0: versionResult,
                                          _1: /* Hidden */1
                                        },
                                        versionLabel: /* Dirty */{
                                          _0: versionLabelResult,
                                          _1: /* Hidden */1
                                        },
                                        customBonus: /* Dirty */{
                                          _0: customBonusResult,
                                          _1: /* Shown */0
                                        },
                                        originalBonus: /* Dirty */{
                                          _0: originalBonusResult,
                                          _1: /* Hidden */1
                                        },
                                        actionBonus: /* Dirty */{
                                          _0: actionBonusResult,
                                          _1: /* Hidden */1
                                        },
                                        isCustomFEValue: /* Dirty */{
                                          _0: isCustomFEValueResult,
                                          _1: /* Hidden */1
                                        },
                                        engineType: /* Dirty */{
                                          _0: engineTypeResult,
                                          _1: /* Hidden */1
                                        },
                                        modelPhase: /* Dirty */{
                                          _0: modelPhaseResult,
                                          _1: /* Hidden */1
                                        }
                                      });
                                  return [
                                          {
                                            TAG: /* Ok */0,
                                            _0: output$1
                                          },
                                          statuses
                                        ];
                                }
                                
                              }
                              
                            }
                            
                          }
                          
                        }
                        
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }
            
          }
          statuses.push({
                index: /* Dirty */{
                  _0: match_0,
                  _1: /* Hidden */1
                },
                brand: /* Dirty */{
                  _0: match_0$1,
                  _1: /* Hidden */1
                },
                model: /* Dirty */{
                  _0: match_0$2,
                  _1: /* Hidden */1
                },
                version: /* Dirty */{
                  _0: match_0$3,
                  _1: /* Hidden */1
                },
                versionLabel: /* Dirty */{
                  _0: match_0$4,
                  _1: /* Hidden */1
                },
                customBonus: /* Dirty */{
                  _0: match_0$5,
                  _1: /* Shown */0
                },
                originalBonus: /* Dirty */{
                  _0: match_0$6,
                  _1: /* Hidden */1
                },
                actionBonus: /* Dirty */{
                  _0: match_0$7,
                  _1: /* Hidden */1
                },
                isCustomFEValue: /* Dirty */{
                  _0: match_0$8,
                  _1: /* Hidden */1
                },
                engineType: /* Dirty */{
                  _0: match_0$9,
                  _1: /* Hidden */1
                },
                modelPhase: /* Dirty */{
                  _0: match_0$10,
                  _1: /* Hidden */1
                }
              });
          return [
                  {
                    TAG: /* Error */1,
                    _0: undefined
                  },
                  statuses
                ];
        }));
  if (match.TAG === /* Ok */0) {
    var versions = match$1[0];
    if (versions.TAG === /* Ok */0) {
      return {
              TAG: /* Valid */0,
              output: {
                versions: versions._0
              },
              fieldsStatuses: {
                versions: match$1[1]
              },
              collectionsStatuses: {
                versions: match
              }
            };
    }
    
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            versions: match$1[1]
          },
          collectionsStatuses: {
            versions: match
          }
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* Submit */0 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */24,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */25,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */3);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */2);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */1 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */2 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */3 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateVersionIndexField */0 :
                  var index = action._1;
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput.versions[index].index, (function (status) {
                                    return {
                                            versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                                    if (index$p === index) {
                                                      return {
                                                              index: status,
                                                              brand: item.brand,
                                                              model: item.model,
                                                              version: item.version,
                                                              versionLabel: item.versionLabel,
                                                              customBonus: item.customBonus,
                                                              originalBonus: item.originalBonus,
                                                              actionBonus: item.actionBonus,
                                                              isCustomFEValue: item.isCustomFEValue,
                                                              engineType: item.engineType,
                                                              modelPhase: item.modelPhase
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionBrandField */1 :
                  var index$1 = action._1;
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$1.versions[index$1].brand, (function (status) {
                                    return {
                                            versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                                    if (index$p === index$1) {
                                                      return {
                                                              index: item.index,
                                                              brand: status,
                                                              model: item.model,
                                                              version: item.version,
                                                              versionLabel: item.versionLabel,
                                                              customBonus: item.customBonus,
                                                              originalBonus: item.originalBonus,
                                                              actionBonus: item.actionBonus,
                                                              isCustomFEValue: item.isCustomFEValue,
                                                              engineType: item.engineType,
                                                              modelPhase: item.modelPhase
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionModelField */2 :
                  var index$2 = action._1;
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$2.versions[index$2].model, (function (status) {
                                    return {
                                            versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                                    if (index$p === index$2) {
                                                      return {
                                                              index: item.index,
                                                              brand: item.brand,
                                                              model: status,
                                                              version: item.version,
                                                              versionLabel: item.versionLabel,
                                                              customBonus: item.customBonus,
                                                              originalBonus: item.originalBonus,
                                                              actionBonus: item.actionBonus,
                                                              isCustomFEValue: item.isCustomFEValue,
                                                              engineType: item.engineType,
                                                              modelPhase: item.modelPhase
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionVersionField */3 :
                  var index$3 = action._1;
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$3.versions[index$3].version, (function (status) {
                                    return {
                                            versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                                    if (index$p === index$3) {
                                                      return {
                                                              index: item.index,
                                                              brand: item.brand,
                                                              model: item.model,
                                                              version: status,
                                                              versionLabel: item.versionLabel,
                                                              customBonus: item.customBonus,
                                                              originalBonus: item.originalBonus,
                                                              actionBonus: item.actionBonus,
                                                              isCustomFEValue: item.isCustomFEValue,
                                                              engineType: item.engineType,
                                                              modelPhase: item.modelPhase
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionVersionLabelField */4 :
                  var index$4 = action._1;
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$4.versions[index$4].versionLabel, (function (status) {
                                    return {
                                            versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                                    if (index$p === index$4) {
                                                      return {
                                                              index: item.index,
                                                              brand: item.brand,
                                                              model: item.model,
                                                              version: item.version,
                                                              versionLabel: status,
                                                              customBonus: item.customBonus,
                                                              originalBonus: item.originalBonus,
                                                              actionBonus: item.actionBonus,
                                                              isCustomFEValue: item.isCustomFEValue,
                                                              engineType: item.engineType,
                                                              modelPhase: item.modelPhase
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionCustomBonusField */5 :
                  var index$5 = action._1;
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$5, index$5, state.fieldsStatuses.versions[index$5].customBonus, state.submissionStatus, validators.versions.fields.customBonus, (function (status) {
                                    return {
                                            versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                                    if (index$p === index$5) {
                                                      return {
                                                              index: item.index,
                                                              brand: item.brand,
                                                              model: item.model,
                                                              version: item.version,
                                                              versionLabel: item.versionLabel,
                                                              customBonus: status,
                                                              originalBonus: item.originalBonus,
                                                              actionBonus: item.actionBonus,
                                                              isCustomFEValue: item.isCustomFEValue,
                                                              engineType: item.engineType,
                                                              modelPhase: item.modelPhase
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionOriginalBonusField */6 :
                  var index$6 = action._1;
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$6.versions[index$6].originalBonus, (function (status) {
                                    return {
                                            versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                                    if (index$p === index$6) {
                                                      return {
                                                              index: item.index,
                                                              brand: item.brand,
                                                              model: item.model,
                                                              version: item.version,
                                                              versionLabel: item.versionLabel,
                                                              customBonus: item.customBonus,
                                                              originalBonus: status,
                                                              actionBonus: item.actionBonus,
                                                              isCustomFEValue: item.isCustomFEValue,
                                                              engineType: item.engineType,
                                                              modelPhase: item.modelPhase
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionActionBonusField */7 :
                  var index$7 = action._1;
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$7.versions[index$7].actionBonus, (function (status) {
                                    return {
                                            versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                                    if (index$p === index$7) {
                                                      return {
                                                              index: item.index,
                                                              brand: item.brand,
                                                              model: item.model,
                                                              version: item.version,
                                                              versionLabel: item.versionLabel,
                                                              customBonus: item.customBonus,
                                                              originalBonus: item.originalBonus,
                                                              actionBonus: status,
                                                              isCustomFEValue: item.isCustomFEValue,
                                                              engineType: item.engineType,
                                                              modelPhase: item.modelPhase
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionIsCustomFEValueField */8 :
                  var index$8 = action._1;
                  var nextInput$8 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$8.versions[index$8].isCustomFEValue, (function (status) {
                                    return {
                                            versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                                    if (index$p === index$8) {
                                                      return {
                                                              index: item.index,
                                                              brand: item.brand,
                                                              model: item.model,
                                                              version: item.version,
                                                              versionLabel: item.versionLabel,
                                                              customBonus: item.customBonus,
                                                              originalBonus: item.originalBonus,
                                                              actionBonus: item.actionBonus,
                                                              isCustomFEValue: status,
                                                              engineType: item.engineType,
                                                              modelPhase: item.modelPhase
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionEngineTypeField */9 :
                  var index$9 = action._1;
                  var nextInput$9 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$9.versions[index$9].engineType, (function (status) {
                                    return {
                                            versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                                    if (index$p === index$9) {
                                                      return {
                                                              index: item.index,
                                                              brand: item.brand,
                                                              model: item.model,
                                                              version: item.version,
                                                              versionLabel: item.versionLabel,
                                                              customBonus: item.customBonus,
                                                              originalBonus: item.originalBonus,
                                                              actionBonus: item.actionBonus,
                                                              isCustomFEValue: item.isCustomFEValue,
                                                              engineType: status,
                                                              modelPhase: item.modelPhase
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionModelPhaseField */10 :
                  var index$10 = action._1;
                  var nextInput$10 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$10.versions[index$10].modelPhase, (function (status) {
                                    return {
                                            versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                                    if (index$p === index$10) {
                                                      return {
                                                              index: item.index,
                                                              brand: item.brand,
                                                              model: item.model,
                                                              version: item.version,
                                                              versionLabel: item.versionLabel,
                                                              customBonus: item.customBonus,
                                                              originalBonus: item.originalBonus,
                                                              actionBonus: item.actionBonus,
                                                              isCustomFEValue: item.isCustomFEValue,
                                                              engineType: item.engineType,
                                                              modelPhase: status
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* BlurVersionIndexField */11 :
                  var index$11 = action._0;
                  var result = Formality.validateFieldOnBlurWithoutValidator(state.input.versions[index$11].index, state.fieldsStatuses.versions[index$11].index, (function (status) {
                          return {
                                  versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                          if (index$p === index$11) {
                                            return {
                                                    index: status,
                                                    brand: item.brand,
                                                    model: item.model,
                                                    version: item.version,
                                                    versionLabel: item.versionLabel,
                                                    customBonus: item.customBonus,
                                                    originalBonus: item.originalBonus,
                                                    actionBonus: item.actionBonus,
                                                    isCustomFEValue: item.isCustomFEValue,
                                                    engineType: item.engineType,
                                                    modelPhase: item.modelPhase
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionBrandField */12 :
                  var index$12 = action._0;
                  var result$1 = Formality.validateFieldOnBlurWithoutValidator(state.input.versions[index$12].brand, state.fieldsStatuses.versions[index$12].brand, (function (status) {
                          return {
                                  versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                          if (index$p === index$12) {
                                            return {
                                                    index: item.index,
                                                    brand: status,
                                                    model: item.model,
                                                    version: item.version,
                                                    versionLabel: item.versionLabel,
                                                    customBonus: item.customBonus,
                                                    originalBonus: item.originalBonus,
                                                    actionBonus: item.actionBonus,
                                                    isCustomFEValue: item.isCustomFEValue,
                                                    engineType: item.engineType,
                                                    modelPhase: item.modelPhase
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionModelField */13 :
                  var index$13 = action._0;
                  var result$2 = Formality.validateFieldOnBlurWithoutValidator(state.input.versions[index$13].model, state.fieldsStatuses.versions[index$13].model, (function (status) {
                          return {
                                  versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                          if (index$p === index$13) {
                                            return {
                                                    index: item.index,
                                                    brand: item.brand,
                                                    model: status,
                                                    version: item.version,
                                                    versionLabel: item.versionLabel,
                                                    customBonus: item.customBonus,
                                                    originalBonus: item.originalBonus,
                                                    actionBonus: item.actionBonus,
                                                    isCustomFEValue: item.isCustomFEValue,
                                                    engineType: item.engineType,
                                                    modelPhase: item.modelPhase
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionVersionField */14 :
                  var index$14 = action._0;
                  var result$3 = Formality.validateFieldOnBlurWithoutValidator(state.input.versions[index$14].version, state.fieldsStatuses.versions[index$14].version, (function (status) {
                          return {
                                  versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                          if (index$p === index$14) {
                                            return {
                                                    index: item.index,
                                                    brand: item.brand,
                                                    model: item.model,
                                                    version: status,
                                                    versionLabel: item.versionLabel,
                                                    customBonus: item.customBonus,
                                                    originalBonus: item.originalBonus,
                                                    actionBonus: item.actionBonus,
                                                    isCustomFEValue: item.isCustomFEValue,
                                                    engineType: item.engineType,
                                                    modelPhase: item.modelPhase
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionVersionLabelField */15 :
                  var index$15 = action._0;
                  var result$4 = Formality.validateFieldOnBlurWithoutValidator(state.input.versions[index$15].versionLabel, state.fieldsStatuses.versions[index$15].versionLabel, (function (status) {
                          return {
                                  versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                          if (index$p === index$15) {
                                            return {
                                                    index: item.index,
                                                    brand: item.brand,
                                                    model: item.model,
                                                    version: item.version,
                                                    versionLabel: status,
                                                    customBonus: item.customBonus,
                                                    originalBonus: item.originalBonus,
                                                    actionBonus: item.actionBonus,
                                                    isCustomFEValue: item.isCustomFEValue,
                                                    engineType: item.engineType,
                                                    modelPhase: item.modelPhase
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionCustomBonusField */16 :
                  var index$16 = action._0;
                  var result$5 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$16, state.fieldsStatuses.versions[index$16].customBonus, validators.versions.fields.customBonus, (function (status) {
                          return {
                                  versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                          if (index$p === index$16) {
                                            return {
                                                    index: item.index,
                                                    brand: item.brand,
                                                    model: item.model,
                                                    version: item.version,
                                                    versionLabel: item.versionLabel,
                                                    customBonus: status,
                                                    originalBonus: item.originalBonus,
                                                    actionBonus: item.actionBonus,
                                                    isCustomFEValue: item.isCustomFEValue,
                                                    engineType: item.engineType,
                                                    modelPhase: item.modelPhase
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionOriginalBonusField */17 :
                  var index$17 = action._0;
                  var result$6 = Formality.validateFieldOnBlurWithoutValidator(state.input.versions[index$17].originalBonus, state.fieldsStatuses.versions[index$17].originalBonus, (function (status) {
                          return {
                                  versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                          if (index$p === index$17) {
                                            return {
                                                    index: item.index,
                                                    brand: item.brand,
                                                    model: item.model,
                                                    version: item.version,
                                                    versionLabel: item.versionLabel,
                                                    customBonus: item.customBonus,
                                                    originalBonus: status,
                                                    actionBonus: item.actionBonus,
                                                    isCustomFEValue: item.isCustomFEValue,
                                                    engineType: item.engineType,
                                                    modelPhase: item.modelPhase
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionActionBonusField */18 :
                  var index$18 = action._0;
                  var result$7 = Formality.validateFieldOnBlurWithoutValidator(state.input.versions[index$18].actionBonus, state.fieldsStatuses.versions[index$18].actionBonus, (function (status) {
                          return {
                                  versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                          if (index$p === index$18) {
                                            return {
                                                    index: item.index,
                                                    brand: item.brand,
                                                    model: item.model,
                                                    version: item.version,
                                                    versionLabel: item.versionLabel,
                                                    customBonus: item.customBonus,
                                                    originalBonus: item.originalBonus,
                                                    actionBonus: status,
                                                    isCustomFEValue: item.isCustomFEValue,
                                                    engineType: item.engineType,
                                                    modelPhase: item.modelPhase
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionIsCustomFEValueField */19 :
                  var index$19 = action._0;
                  var result$8 = Formality.validateFieldOnBlurWithoutValidator(state.input.versions[index$19].isCustomFEValue, state.fieldsStatuses.versions[index$19].isCustomFEValue, (function (status) {
                          return {
                                  versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                          if (index$p === index$19) {
                                            return {
                                                    index: item.index,
                                                    brand: item.brand,
                                                    model: item.model,
                                                    version: item.version,
                                                    versionLabel: item.versionLabel,
                                                    customBonus: item.customBonus,
                                                    originalBonus: item.originalBonus,
                                                    actionBonus: item.actionBonus,
                                                    isCustomFEValue: status,
                                                    engineType: item.engineType,
                                                    modelPhase: item.modelPhase
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionEngineTypeField */20 :
                  var index$20 = action._0;
                  var result$9 = Formality.validateFieldOnBlurWithoutValidator(state.input.versions[index$20].engineType, state.fieldsStatuses.versions[index$20].engineType, (function (status) {
                          return {
                                  versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                          if (index$p === index$20) {
                                            return {
                                                    index: item.index,
                                                    brand: item.brand,
                                                    model: item.model,
                                                    version: item.version,
                                                    versionLabel: item.versionLabel,
                                                    customBonus: item.customBonus,
                                                    originalBonus: item.originalBonus,
                                                    actionBonus: item.actionBonus,
                                                    isCustomFEValue: item.isCustomFEValue,
                                                    engineType: status,
                                                    modelPhase: item.modelPhase
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionModelPhaseField */21 :
                  var index$21 = action._0;
                  var result$10 = Formality.validateFieldOnBlurWithoutValidator(state.input.versions[index$21].modelPhase, state.fieldsStatuses.versions[index$21].modelPhase, (function (status) {
                          return {
                                  versions: Belt_Array.mapWithIndex(state.fieldsStatuses.versions, (function (index$p, item) {
                                          if (index$p === index$21) {
                                            return {
                                                    index: item.index,
                                                    brand: item.brand,
                                                    model: item.model,
                                                    version: item.version,
                                                    versionLabel: item.versionLabel,
                                                    customBonus: item.customBonus,
                                                    originalBonus: item.originalBonus,
                                                    actionBonus: item.actionBonus,
                                                    isCustomFEValue: item.isCustomFEValue,
                                                    engineType: item.engineType,
                                                    modelPhase: status
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* AddVersionEntry */22 :
                  var nextInput$11 = {
                    versions: Belt_Array.concat(state.input.versions, [action._0])
                  };
                  var nextFieldsStatuses = {
                    versions: Belt_Array.concat(state.fieldsStatuses.versions, [{
                            index: /* Pristine */0,
                            brand: /* Pristine */0,
                            model: /* Pristine */0,
                            version: /* Pristine */0,
                            versionLabel: /* Pristine */0,
                            customBonus: /* Pristine */0,
                            originalBonus: /* Pristine */0,
                            actionBonus: /* Pristine */0,
                            isCustomFEValue: /* Pristine */0,
                            engineType: /* Pristine */0,
                            modelPhase: /* Pristine */0
                          }])
                  };
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: nextFieldsStatuses,
                            collectionsStatuses: {
                              versions: Curry._1(validators.versions.collection, nextInput$11)
                            },
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* RemoveVersionEntry */23 :
                  var index$22 = action._0;
                  var nextInput$12 = {
                    versions: Belt_Array.keepWithIndex(state.input.versions, (function (param, i) {
                            return i !== index$22;
                          }))
                  };
                  var nextFieldsStatuses$1 = {
                    versions: Belt_Array.keepWithIndex(state.fieldsStatuses.versions, (function (param, i) {
                            return i !== index$22;
                          }))
                  };
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: nextFieldsStatuses$1,
                            collectionsStatuses: {
                              versions: Curry._1(validators.versions.collection, nextInput$12)
                            },
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */24 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */25 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */26 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateVersionIndex: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionIndexField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateVersionBrand: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionBrandField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateVersionModel: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionModelField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateVersionVersion: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionVersionField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateVersionVersionLabel: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionVersionLabelField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateVersionCustomBonus: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionCustomBonusField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateVersionOriginalBonus: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionOriginalBonusField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateVersionActionBonus: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionActionBonusField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateVersionIsCustomFEValue: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionIsCustomFEValueField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateVersionEngineType: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionEngineTypeField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateVersionModelPhase: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionModelPhaseField */10,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          blurVersionIndex: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurVersionIndexField */11,
                    _0: index
                  });
            }),
          blurVersionBrand: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurVersionBrandField */12,
                    _0: index
                  });
            }),
          blurVersionModel: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurVersionModelField */13,
                    _0: index
                  });
            }),
          blurVersionVersion: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurVersionVersionField */14,
                    _0: index
                  });
            }),
          blurVersionVersionLabel: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurVersionVersionLabelField */15,
                    _0: index
                  });
            }),
          blurVersionCustomBonus: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurVersionCustomBonusField */16,
                    _0: index
                  });
            }),
          blurVersionOriginalBonus: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurVersionOriginalBonusField */17,
                    _0: index
                  });
            }),
          blurVersionActionBonus: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurVersionActionBonusField */18,
                    _0: index
                  });
            }),
          blurVersionIsCustomFEValue: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurVersionIsCustomFEValueField */19,
                    _0: index
                  });
            }),
          blurVersionEngineType: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurVersionEngineTypeField */20,
                    _0: index
                  });
            }),
          blurVersionModelPhase: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurVersionModelPhaseField */21,
                    _0: index
                  });
            }),
          versionIndexResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.versions[index].index);
            }),
          versionBrandResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.versions[index].brand);
            }),
          versionModelResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.versions[index].model);
            }),
          versionVersionResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.versions[index].version);
            }),
          versionVersionLabelResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.versions[index].versionLabel);
            }),
          versionCustomBonusResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.versions[index].customBonus);
            }),
          versionOriginalBonusResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.versions[index].originalBonus);
            }),
          versionActionBonusResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.versions[index].actionBonus);
            }),
          versionIsCustomFEValueResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.versions[index].isCustomFEValue);
            }),
          versionEngineTypeResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.versions[index].engineType);
            }),
          versionModelPhaseResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.versions[index].modelPhase);
            }),
          addVersion: (function (entry) {
              Curry._1(dispatch, {
                    TAG: /* AddVersionEntry */22,
                    _0: entry
                  });
            }),
          removeVersion: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* RemoveVersionEntry */23,
                    _0: index
                  });
            }),
          versionsResult: state.collectionsStatuses.versions,
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              return Belt_Array.every(state.fieldsStatuses.versions, (function (item) {
                            if (item.index) {
                              return true;
                            }
                            if (!item.brand && !item.model && !item.version && !item.versionLabel && !item.customBonus && !item.originalBonus && !item.actionBonus && !item.isCustomFEValue && !item.engineType && !item.modelPhase) {
                              return false;
                            }
                            return true;
                          }));
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */0);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */1);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */2);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */26,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */3);
            })
        };
}

var VersionsForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: initialCollectionsStatuses,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialInput = {
  versions: []
};

var initialAttachmentInput = {
  index: 0,
  brand: "",
  model: "",
  version: "",
  versionLabel: "",
  customBonus: "",
  originalBonus: 0.0,
  actionBonus: 0.0,
  isCustomFEValue: false,
  engineType: "",
  modelPhase: ""
};

export {
  VersionsForm ,
  initialInput ,
  initialAttachmentInput ,
}
/* react Not a pure module */
