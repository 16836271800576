// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as App_Table$DvmAdminFrontendFr from "../../../../common/components/table/App_Table.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Pagination.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as Quota_Detail_Attachment_Api$DvmAdminFrontendFr from "./Quota_Detail_Attachment_Api.bs.js";
import * as Quota_Detail_Attachment_Types$DvmAdminFrontendFr from "./Quota_Detail_Attachment_Types.bs.js";
import * as Quota_Detail_Attachment_Delete_Modal$DvmAdminFrontendFr from "./modal/Quota_Detail_Attachment_Delete_Modal.bs.js";
import * as Quota_Detail_Attachment_Single_Modal$DvmAdminFrontendFr from "./modal/Quota_Detail_Attachment_Single_Modal.bs.js";

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_Detail_Attachment_Types$DvmAdminFrontendFr.Fields.siret, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.siret);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_Detail_Attachment_Types$DvmAdminFrontendFr.Fields.name, undefined, false, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.companyName),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_Detail_Attachment_Types$DvmAdminFrontendFr.Fields.dealerId, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.dealerID),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_Detail_Attachment_Types$DvmAdminFrontendFr.Fields.volume, undefined, undefined, undefined, undefined, undefined, Messages_Quotas_Detail$DvmAdminFrontendFr.volume),
      tl: {
        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_Detail_Attachment_Types$DvmAdminFrontendFr.Fields.startDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.startDate),
        tl: {
          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_Detail_Attachment_Types$DvmAdminFrontendFr.Fields.endDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.endDate),
          tl: /* [] */0
        }
      }
    }
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function Quota_Detail_Attachment_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var quota = Props.quota;
  var fetchVolumeTotal = Props.fetchVolumeTotal;
  var alert = ReactAlert.useAlert();
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return false;
      });
  var setModal = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setDeleteModal = match$1[1];
  var match$2 = React.useState(function () {
        
      });
  var setRow = match$2[1];
  var row = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match$3[1];
  var saveResult = match$3[0];
  var match$4 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$5 = match$4[0];
  var userManager = match$5.userManager;
  var selectedRole = match$5.selectedRole;
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (row) {
                                  var tmp = {
                                    size: /* Large */3,
                                    type_: /* Trash */38
                                  };
                                  var tmp$1 = Belt_Option.getWithDefault(row.consumed, 0) > 0 ? undefined : "red";
                                  if (tmp$1 !== undefined) {
                                    tmp.color = Caml_option.valFromOption(tmp$1);
                                  }
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, Caml_option.some(React.createElement(Core.Grid, {
                                                      children: null,
                                                      container: true,
                                                      wrap: "nowrap"
                                                    }, React.createElement(Core.Grid, {
                                                          children: React.createElement(Core.Button, {
                                                                onClick: (function (param) {
                                                                    Curry._1(setRow, (function (param) {
                                                                            return row;
                                                                          }));
                                                                    Curry._1(setModal, (function (param) {
                                                                            return true;
                                                                          }));
                                                                  }),
                                                                children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                      size: /* Large */3,
                                                                      type_: /* Edit */60
                                                                    })
                                                              }),
                                                          item: true
                                                        }), React.createElement(Core.Grid, {
                                                          children: React.createElement(Core.Tooltip, {
                                                                arrow: true,
                                                                children: React.createElement("div", undefined, React.createElement(Core.Button, {
                                                                          onClick: (function (param) {
                                                                              Curry._1(setRow, (function (param) {
                                                                                      return row;
                                                                                    }));
                                                                              Curry._1(setDeleteModal, (function (param) {
                                                                                      return true;
                                                                                    }));
                                                                            }),
                                                                          children: React.createElement(App_Icon$DvmAdminFrontendFr.make, tmp),
                                                                          disabled: Belt_Option.getWithDefault(row.consumed, 0) > 0
                                                                        })),
                                                                classes: {
                                                                  tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
                                                                },
                                                                placement: "bottom",
                                                                title: Belt_Option.getWithDefault(row.consumed, 0) > 0 ? intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.alreadyConsumed) : ""
                                                              }),
                                                          item: true
                                                        }))), undefined, Belt_List.mapWithIndex({
                                                  hd: row.siret,
                                                  tl: {
                                                    hd: Belt_Option.getWithDefault(row.companyName, ""),
                                                    tl: {
                                                      hd: row.dealerId,
                                                      tl: {
                                                        hd: String(row.volume),
                                                        tl: {
                                                          hd: Intl_Utils$DvmAdminFrontendFr.formatDate(intl, row.attachmentStartDate),
                                                          tl: {
                                                            hd: Intl_Utils$DvmAdminFrontendFr.formatDate(intl, row.attachmentEndDate),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(setModal, (function (param) {
                    return false;
                  }));
            Curry._1(setRow, (function (param) {
                    
                  }));
            Curry._2(fetchData, undefined, fetchRequest);
            Curry._1(fetchVolumeTotal, quota);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  var onSubmit = function (param, param$1) {
    var volume = param.volume;
    var endDate = param.endDate;
    var startDate = param.startDate;
    Belt_Option.mapWithDefault(row, undefined, (function (row) {
            Quota_Detail_Attachment_Api$DvmAdminFrontendFr.updateAttachment(setSaveResult, userManager, selectedRole, {
                  attachmentOldStartDate: row.attachmentStartDate,
                  contractId: row.contractId,
                  dr: row.dr,
                  vehicleType: row.vehicleType,
                  quotaStartDate: row.quotaStartDate,
                  siret: row.siret,
                  companyName: row.companyName,
                  dealerId: row.dealerId,
                  volume: volume,
                  attachmentStartDate: Belt_Option.getWithDefault(startDate, new Date(0.0)),
                  attachmentEndDate: Belt_Option.getWithDefault(endDate, new Date(0.0))
                });
          }));
  };
  var handleSorting = function (headers) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._2(fetchData, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, {
                  headers: fetchRequest.headers,
                  handleSorting: handleSorting,
                  tablePage: convertTable(tablePage),
                  paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(/* BOTTOM */1, 10, handleChangePage, handleRowsPerPageChange, undefined),
                  rowButton: {
                    TAG: /* Left */0,
                    _0: /* EmptyCell */0
                  }
                }), match[0] ? Belt_Option.mapWithDefault(row, null, (function (row) {
                      var tmp = {
                        parentStartDate: quota.startDate,
                        showSpinner: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult),
                        onClose: (function (param) {
                            Curry._1(setModal, (function (param) {
                                    return false;
                                  }));
                          }),
                        onSubmit: onSubmit,
                        row: row
                      };
                      if (quota.endDate !== undefined) {
                        tmp.parentEndDate = Caml_option.valFromOption(quota.endDate);
                      }
                      return React.createElement(Quota_Detail_Attachment_Single_Modal$DvmAdminFrontendFr.make, tmp);
                    })) : null, match$1[0] ? Belt_Option.mapWithDefault(row, null, (function (row) {
                      return React.createElement(Quota_Detail_Attachment_Delete_Modal$DvmAdminFrontendFr.make, {
                                  onClose: (function (param) {
                                      Curry._1(setDeleteModal, (function (param) {
                                              return false;
                                            }));
                                    }),
                                  fetchRequest: fetchRequest,
                                  fetchData: fetchData,
                                  row: row,
                                  setRow: setRow,
                                  fetchVolumeTotal: fetchVolumeTotal,
                                  quota: quota
                                });
                    })) : null);
}

var Api;

var make = Quota_Detail_Attachment_Table;

export {
  Api ,
  headersConfiguration ,
  make ,
}
/* headersConfiguration Not a pure module */
