// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../common/types/App_Types_Date.bs.js";

var FilterFields = {
  dr: "dr",
  contractId: "contractID",
  vehicleType: "vehicleType",
  volume: "volume",
  startDate: "startDate",
  endDate: "endDate",
  startYear: "startYear"
};

function row_encode(v) {
  return Js_dict.fromArray([
              [
                "dr",
                Decco.stringToJson(v.dr)
              ],
              [
                "contractId",
                Decco.stringToJson(v.contractId)
              ],
              [
                "vehicleType",
                Decco.stringToJson(v.vehicleType)
              ],
              [
                "volume",
                Decco.optionToJson(Decco.intToJson, v.volume)
              ],
              [
                "startDate",
                Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function row_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var dr = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "dr"), null));
  if (dr.TAG === /* Ok */0) {
    var contractId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractId"), null));
    if (contractId.TAG === /* Ok */0) {
      var vehicleType = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleType"), null));
      if (vehicleType.TAG === /* Ok */0) {
        var volume = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "volume"), null));
        if (volume.TAG === /* Ok */0) {
          var startDate = Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
          if (startDate.TAG === /* Ok */0) {
            var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
            if (endDate.TAG === /* Ok */0) {
              return {
                      TAG: /* Ok */0,
                      _0: {
                        dr: dr._0,
                        contractId: contractId._0,
                        vehicleType: vehicleType._0,
                        volume: volume._0,
                        startDate: startDate._0,
                        endDate: endDate._0
                      }
                    };
            }
            var e = endDate._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".endDate" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = startDate._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".startDate" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = volume._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".volume" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = vehicleType._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".vehicleType" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = contractId._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".contractId" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = dr._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".dr" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(row_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(row_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var Quotas = {
  row_encode: row_encode,
  row_decode: row_decode,
  searchResult_encode: searchResult_encode,
  searchResult_decode: searchResult_decode
};

export {
  FilterFields ,
  Quotas ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
