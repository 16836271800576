// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../icons/App_Icon.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../utils/Utils_Form.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../App_Checkbox.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as App_Types_Text$DvmAdminFrontendFr from "../../types/App_Types_Text.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "./App_TextFieldFormInput.bs.js";
import * as App_TransferListFormInput_Virtualized_List$DvmAdminFrontendFr from "./App_TransferListFormInput_Virtualized_List.bs.js";

function onlyVisible(checked, options) {
  return Belt_List.keep(checked, (function (x) {
                return Belt_List.has(options, x.value, (function (a, b) {
                              return a.value === b;
                            }));
              }));
}

function App_TransferListFormInput_Virtualized(Props) {
  var label = Props.label;
  var values = Props.values;
  var options = Props.options;
  var validationResult = Props.validationResult;
  var onChange = Props.onChange;
  var className = Props.className;
  var boxHeight = Props.boxHeight;
  var disabled = Props.disabled;
  var boxLabels = Props.boxLabels;
  var searchOpt = Props.search;
  var checkAllOpt = Props.checkAll;
  var selectedToBlock = Props.selectedToBlock;
  var additionalFilters = Props.additionalFilters;
  var leftBorderOpt = Props.leftBorder;
  var search = searchOpt !== undefined ? searchOpt : true;
  var checkAll = checkAllOpt !== undefined ? checkAllOpt : true;
  var leftBorder = leftBorderOpt !== undefined ? leftBorderOpt : true;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return "";
      });
  var setSearchLeft = match[1];
  var searchLeft = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setSearchRight = match$1[1];
  var searchRight = match$1[0];
  var match$2 = React.useState(function () {
        return /* [] */0;
      });
  var setChecked = match$2[1];
  var checked = match$2[0];
  React.useEffect((function () {
          Curry._1(setChecked, (function (checked) {
                  return onlyVisible(checked, options);
                }));
        }), [options]);
  var optionsSorted = Belt_List.fromArray(Belt_SortArray.stableSortBy(Belt_List.toArray(options), (function (x, y) {
              return App_Types_Text$DvmAdminFrontendFr.toString(intl, x.label).localeCompare(App_Types_Text$DvmAdminFrontendFr.toString(intl, y.label)) | 0;
            })));
  var intersection = function (first, second) {
    return Belt_List.keep(first, (function (element) {
                  return Belt_List.some(second, (function (x) {
                                return x.value === element.value;
                              }));
                }));
  };
  var remove = function (what, from) {
    return Belt_List.keep(from, (function (element) {
                  return !Belt_List.some(what, (function (x) {
                                return x.value === element.value;
                              }));
                }));
  };
  var left = remove(values, optionsSorted);
  var leftChecked = intersection(checked, left);
  var rightChecked = intersection(checked, values);
  var handleToggle = function (value) {
    if (Belt_List.some(checked, (function (x) {
              return x.value === value.value;
            }))) {
      return Curry._1(setChecked, (function (param) {
                    return Belt_List.keep(checked, (function (x) {
                                  return x.value !== value.value;
                                }));
                  }));
    } else {
      return Curry._1(setChecked, (function (param) {
                    return Belt_List.add(checked, value);
                  }));
    }
  };
  var customList = function (title, items, which) {
    var tmp;
    if (checkAll) {
      var relevantItems = which === "right" && selectedToBlock !== undefined ? remove(selectedToBlock, items) : items;
      tmp = React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
            checked: Belt_List.length(intersection(relevantItems, checked)) === Belt_List.length(relevantItems) && Belt_List.length(relevantItems) !== 0,
            onClick: (function (param) {
                if (Belt_List.length(intersection(relevantItems, checked)) === Belt_List.length(relevantItems)) {
                  return Curry._1(setChecked, (function (param) {
                                return remove(relevantItems, checked);
                              }));
                } else {
                  return Curry._1(setChecked, (function (param) {
                                return Belt_List.map(Belt_MapString.toList(Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(Belt_List.concat(checked, relevantItems), (function (x) {
                                                              return [
                                                                      x.value,
                                                                      x.label
                                                                    ];
                                                            }))))), (function (x) {
                                              return {
                                                      label: x[1],
                                                      value: x[0]
                                                    };
                                            }));
                              }));
                }
              }),
            disabled: Belt_List.length(relevantItems) === 0,
            indeterminate: Belt_List.length(intersection(relevantItems, checked)) !== Belt_List.length(relevantItems) && Belt_List.length(intersection(relevantItems, checked)) !== 0
          });
    } else {
      tmp = null;
    }
    return React.createElement(Core.Card, {
                elevation: 0,
                square: true,
                children: null,
                style: which === "right" ? ({
                      border: "2px solid",
                      borderColor: Belt_Option.mapWithDefault(validationResult, false, Belt_Result.isError) ? Theme_Colors$DvmAdminFrontendFr.red : Theme_Colors$DvmAdminFrontendFr.primaryBrown
                    }) : ({
                      backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight2
                    })
              }, React.createElement(Core.CardHeader, {
                    avatar: tmp,
                    disableTypography: true,
                    subheader: "" + String(Belt_List.length(intersection(items, checked))) + "/" + String(Belt_List.length(items)) + " selected",
                    title: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                          color: "primary",
                          style: {
                            fontWeight: "bold"
                          },
                          children: title,
                          variant: "bodyBig"
                        }),
                    style: {
                      padding: "5px 10px 0px 10px"
                    }
                  }), search ? React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                      onChange: which === "right" ? (function (value) {
                            Curry._1(setSearchRight, (function (param) {
                                    return value;
                                  }));
                          }) : (function (value) {
                            Curry._1(setSearchLeft, (function (param) {
                                    return value;
                                  }));
                          }),
                      value: which === "right" ? searchRight : searchLeft,
                      style: {
                        padding: "0px 10px 5px 10px",
                        width: "100%"
                      },
                      label: Messages_Common$DvmAdminFrontendFr.search
                    }) : null, React.createElement(Core.Divider, {}), React.createElement(App_TransferListFormInput_Virtualized_List$DvmAdminFrontendFr.make, {
                    boxHeight: boxHeight,
                    disabled: disabled,
                    selectedToBlock: selectedToBlock,
                    checked: checked,
                    handleToggle: handleToggle,
                    items: items
                  }));
  };
  var tmp = {
    children: null,
    borderColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
    paddingLeft: 1
  };
  var tmp$1 = leftBorder ? 2 : undefined;
  if (tmp$1 !== undefined) {
    tmp.borderLeft = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = {
    alignItems: "center",
    children: null,
    container: true,
    direction: "row",
    spacing: 2,
    wrap: "nowrap"
  };
  if (className !== undefined) {
    tmp$2.className = className;
  }
  return React.createElement(Core.Box, tmp, Belt_Option.mapWithDefault(label, null, (function (x) {
                    return React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                color: Belt_Option.mapWithDefault(validationResult, false, Belt_Result.isError) ? "error" : "primary",
                                gutterBottom: true,
                                children: App_Types_Text$DvmAdminFrontendFr.toString(intl, x),
                                variant: "h3"
                              });
                  })), Belt_Option.getWithDefault(additionalFilters, null), React.createElement(Core.Grid, tmp$2, React.createElement(Core.Grid, {
                      children: customList(Belt_Option.mapWithDefault(boxLabels, "", (function (x) {
                                  return App_Types_Text$DvmAdminFrontendFr.toString(intl, x.left);
                                })), Belt_List.keep(left, (function (x) {
                                  return App_Types_Text$DvmAdminFrontendFr.toString(intl, x.label).toLowerCase().includes(searchLeft.toLowerCase());
                                })), "left"),
                      item: true,
                      sm: Grid$Mui.Sm.$$true
                    }), React.createElement(Core.Grid, {
                      alignItems: "center",
                      children: null,
                      container: true,
                      direction: "column",
                      item: true,
                      sm: Grid$Mui.Sm[1],
                      spacing: 1
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(Core.Button, {
                                onClick: (function (param) {
                                    Curry._1(onChange, Belt_List.fromArray(Belt_SortArray.stableSortBy(Belt_List.toArray(Belt_List.concat(values, leftChecked)), (function (x, y) {
                                                    return App_Types_Text$DvmAdminFrontendFr.toString(intl, x.label).localeCompare(App_Types_Text$DvmAdminFrontendFr.toString(intl, y.label)) | 0;
                                                  }))));
                                    Curry._1(setChecked, (function (param) {
                                            return remove(leftChecked, checked);
                                          }));
                                  }),
                                children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                      size: /* Regular */2,
                                      type_: /* PadRight */13
                                    }),
                                disabled: Belt_List.length(leftChecked) === 0 || Belt_Option.getWithDefault(disabled, false),
                                type: "button",
                                variant: "outlined"
                              }),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(Core.Button, {
                                onClick: (function (param) {
                                    Curry._1(onChange, Belt_List.fromArray(Belt_SortArray.stableSortBy(Belt_List.toArray(remove(rightChecked, values)), (function (x, y) {
                                                    return App_Types_Text$DvmAdminFrontendFr.toString(intl, x.label).localeCompare(App_Types_Text$DvmAdminFrontendFr.toString(intl, y.label)) | 0;
                                                  }))));
                                    Curry._1(setChecked, (function (param) {
                                            return remove(rightChecked, checked);
                                          }));
                                  }),
                                children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                      size: /* Regular */2,
                                      type_: /* PadLeft */12
                                    }),
                                disabled: Belt_List.length(rightChecked) === 0 || Belt_Option.getWithDefault(disabled, false),
                                type: "button",
                                variant: "outlined"
                              }),
                          item: true
                        })), React.createElement(Core.Grid, {
                      children: customList(Belt_Option.mapWithDefault(boxLabels, "", (function (x) {
                                  return App_Types_Text$DvmAdminFrontendFr.toString(intl, x.right);
                                })), Belt_List.keep(values, (function (x) {
                                  return App_Types_Text$DvmAdminFrontendFr.toString(intl, x.label).toLowerCase().includes(searchRight.toLowerCase());
                                })), "right"),
                      item: true,
                      sm: Grid$Mui.Sm.$$true
                    })), React.createElement(Core.Grid, {
                  children: Belt_Option.mapWithDefault(Utils_Form$DvmAdminFrontendFr.getErrorMessage(intl, validationResult), null, (function (errorMessage) {
                          return React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                      color: "error",
                                      children: errorMessage,
                                      variant: "subtitle"
                                    });
                        })),
                  container: true,
                  justifyContent: "flex-end"
                }));
}

var make = App_TransferListFormInput_Virtualized;

export {
  onlyVisible ,
  make ,
}
/* react Not a pure module */
