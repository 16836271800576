// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../../common/App_Api.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../../common/components/App_Checkbox.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../common/components/App_OverlaySpinner.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as App_DoubleSelectFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_DoubleSelectFormInput.bs.js";

function Grid_Edit_Modal_Filters(Props) {
  var budgetOrID = Props.budgetOrID;
  var form = Props.form;
  var whatTable = Props.whatTable;
  var categories = Props.categories;
  var withClosedModels = Props.withClosedModels;
  var setWithClosedModels = Props.setWithClosedModels;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setCategoriesForFilter = match$2[1];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelVersions = match$3[1];
  var modelVersions = match$3[0];
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var setModelVersionsList = match$4[1];
  var modelVersionsList = match$4[0];
  var existingCategories = Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.flatten(Belt_List.map(Belt_List.keep(Belt_Option.getWithDefault(categories, /* [] */0), (function (x) {
                              return x.data === budgetOrID;
                            })), (function (x) {
                          return Belt_List.map(x.children, (function (x) {
                                        return x.data;
                                      }));
                        }))))));
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchModelVersions(setModelVersions, userManager, selectedRole, withClosedModels, undefined, undefined);
          App_Api$DvmAdminFrontendFr.fetchAllCategories(userManager, setCategoriesForFilter, /* Today */0, selectedRole, undefined);
        }), []);
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchModelVersions(setModelVersions, userManager, selectedRole, withClosedModels, undefined, undefined);
        }), [withClosedModels]);
  React.useEffect((function () {
          if (typeof modelVersions === "number") {
            modelVersions === /* NotStarted */0;
          } else if (modelVersions.TAG === /* Ok */0) {
            var modelVersions$1 = modelVersions._0;
            Curry._1(setModelVersionsList, (function (param) {
                    return modelVersions$1;
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: modelVersions._0
                      }
                    }));
          }
        }), [modelVersions]);
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchAllCategories(userManager, setCategoriesForFilter, /* Today */0, selectedRole, form.input.model.length === 0 ? undefined : form.input.model);
        }), [form.input.model]);
  var wrapWithClosedModelsCheckboxElement = function (children) {
    return React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                show: App_Types_Result$DvmAdminFrontendFr.isPending(modelVersions),
                children: React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      direction: "column",
                      item: true,
                      style: {
                        width: "fit-content"
                      }
                    }, React.createElement(Core.Grid, {
                          children: children,
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(Core.FormControlLabel, {
                                classes: {
                                  label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                },
                                control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                      checked: withClosedModels,
                                      onClick: (function ($$event) {
                                          Curry._1(setWithClosedModels, (function (param) {
                                                  return $$event.target.checked;
                                                }));
                                        })
                                    }),
                                label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.includeClosedCars)
                              }),
                          item: true,
                          style: {
                            whiteSpace: "nowrap",
                            width: "1px"
                          }
                        }))
              });
  };
  var tmp;
  switch (whatTable) {
    case /* Model */0 :
        var tmp$1 = {
          label: Messages_Common$DvmAdminFrontendFr.model,
          value: {
            TAG: /* Single */0,
            _0: {
              value: form.input.model,
              onChange: Curry._1(form.updateModel, (function (input, model) {
                      return {
                              someID: input.someID,
                              model: model,
                              version: input.version,
                              categoryID: input.categoryID,
                              discount: input.discount,
                              startDate: input.startDate,
                              endDate: input.endDate
                            };
                    }))
            }
          },
          options: {
            TAG: /* Unlabeled */0,
            _0: Belt_List.map(modelVersionsList, (function (x) {
                    return x.parent;
                  }))
          },
          className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
        };
        if (form.modelResult !== undefined) {
          tmp$1.validationResult = Caml_option.valFromOption(form.modelResult);
        }
        tmp = wrapWithClosedModelsCheckboxElement(React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$1));
        break;
    case /* Version */1 :
        var tmp$2 = {
          label: Messages_Common$DvmAdminFrontendFr.category,
          value: {
            TAG: /* Single */0,
            _0: {
              value: form.input.categoryID,
              onChange: Curry._1(form.updateCategoryID, (function (input, categoryID) {
                      return {
                              someID: input.someID,
                              model: input.model,
                              version: input.version,
                              categoryID: categoryID,
                              discount: input.discount,
                              startDate: input.startDate,
                              endDate: input.endDate
                            };
                    }))
            }
          },
          options: {
            TAG: /* Labeled */1,
            _0: Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$2[0], /* [] */0), (function (x) {
                    return {
                            label: {
                              TAG: /* String */3,
                              _0: "" + x.categoryID + " " + Belt_Option.mapWithDefault(x.name, "", (function (x) {
                                      return "(" + x + ")";
                                    })) + ""
                            },
                            value: x.categoryID
                          };
                  }))
          },
          className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
        };
        if (form.categoryIDResult !== undefined) {
          tmp$2.validationResult = Caml_option.valFromOption(form.categoryIDResult);
        }
        tmp = React.createElement(React.Fragment, undefined, wrapWithClosedModelsCheckboxElement(React.createElement(App_DoubleSelectFormInput$DvmAdminFrontendFr.make, {
                      labelParent: Messages_Common$DvmAdminFrontendFr.model,
                      labelChild: Messages_Common$DvmAdminFrontendFr.version,
                      valueParent: {
                        TAG: /* Single */0,
                        _0: {
                          value: form.input.model,
                          onChange: Curry._1(form.updateModel, (function (input, model) {
                                  return {
                                          someID: input.someID,
                                          model: model,
                                          version: input.version,
                                          categoryID: input.categoryID,
                                          discount: input.discount,
                                          startDate: input.startDate,
                                          endDate: input.endDate
                                        };
                                }))
                        }
                      },
                      valueChild: {
                        TAG: /* Single */0,
                        _0: {
                          value: form.input.version,
                          onChange: Curry._1(form.updateVersion, (function (input, version) {
                                  return {
                                          someID: input.someID,
                                          model: input.model,
                                          version: version,
                                          categoryID: input.categoryID,
                                          discount: input.discount,
                                          startDate: input.startDate,
                                          endDate: input.endDate
                                        };
                                }))
                        }
                      },
                      options: modelVersionsList,
                      classNameParent: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
                      classNameChild: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                    })), React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$2),
                  item: true
                }));
        break;
    case /* GridID */2 :
        var tmp$3 = {
          label: Messages_Common$DvmAdminFrontendFr.categoryCode,
          value: {
            TAG: /* Single */0,
            _0: {
              value: form.input.someID,
              onChange: Curry._1(form.updateSomeID, (function (input, someID) {
                      return {
                              someID: someID,
                              model: input.model,
                              version: input.version,
                              categoryID: input.categoryID,
                              discount: input.discount,
                              startDate: input.startDate,
                              endDate: input.endDate
                            };
                    }))
            }
          },
          options: {
            TAG: /* Unlabeled */0,
            _0: existingCategories
          },
          className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
        };
        if (form.someIDResult !== undefined) {
          tmp$3.validationResult = Caml_option.valFromOption(form.someIDResult);
        }
        tmp = wrapWithClosedModelsCheckboxElement(React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$3));
        break;
    
  }
  var tmp$4 = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    someID: input.someID,
                    model: input.model,
                    version: input.version,
                    categoryID: input.categoryID,
                    discount: input.discount,
                    startDate: startDate,
                    endDate: input.endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
  };
  if (form.startDateResult !== undefined) {
    tmp$4.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  var tmp$5 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    someID: input.someID,
                    model: input.model,
                    version: input.version,
                    categoryID: input.categoryID,
                    discount: input.discount,
                    startDate: input.startDate,
                    endDate: endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
  };
  if (form.endDateResult !== undefined) {
    tmp$5.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true,
                  item: true
                }, tmp, React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry._1(form.updateDiscount, (function (input, discount) {
                                    return {
                                            someID: input.someID,
                                            model: input.model,
                                            version: input.version,
                                            categoryID: input.categoryID,
                                            discount: discount,
                                            startDate: input.startDate,
                                            endDate: input.endDate
                                          };
                                  })),
                            value: form.input.discount,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
                            label: Messages_Common$DvmAdminFrontendFr.discount,
                            InputProps: {
                              startAdornment: React.createElement(Core.InputAdornment, {
                                    children: "%",
                                    position: "start"
                                  })
                            },
                            inputType: "number"
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$4),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$5),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var Form;

var EditApi;

var InputStyles;

var make = Grid_Edit_Modal_Filters;

export {
  Form ,
  EditApi ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
