// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../common/utils/Utils_Api.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Company_Detail_Api$DvmAdminFrontendFr from "../Company_Detail_Api.bs.js";
import * as Company_Detail_Table$DvmAdminFrontendFr from "./Company_Detail_Table.bs.js";
import * as Messages_Company_Detail$DvmAdminFrontendFr from "../../../../intl/messages/company/Messages_Company_Detail.bs.js";

function Company_Detail_Table_Page(Props) {
  var siret = Props.siret;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match[1];
  var match$1 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: 10,
                headers: Company_Detail_Table$DvmAdminFrontendFr.headersConfiguration,
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$1[1];
  var fetchRequest = match$1[0];
  var match$2 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$3 = match$2[0];
  var userManager = match$3.userManager;
  var selectedRole = match$3.selectedRole;
  var fetchData = function (submissionCallbacks, fetchRequest) {
    Company_Detail_Api$DvmAdminFrontendFr.fetchAttachmentSearch(submissionCallbacks, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
              page: fetchRequest.page,
              rowsPerPage: fetchRequest.rowsPerPage,
              headers: fetchRequest.headers,
              filterQueryParams: "siren=" + siret.substr(0, 9) + ";" + fetchRequest.filterQueryParams,
              additionalQueryParams: fetchRequest.additionalQueryParams
            }), selectedRole);
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  React.useEffect((function () {
          fetchData(undefined, fetchRequest);
        }), []);
  return React.createElement(React.Fragment, undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  color: "primary",
                  gutterBottom: true,
                  children: intl.formatMessage(Messages_Company_Detail$DvmAdminFrontendFr.attachmentsHistory),
                  variant: "h3"
                }), React.createElement(Company_Detail_Table$DvmAdminFrontendFr.make, {
                  tablePage: match[0],
                  fetchRequest: fetchRequest,
                  fetchData: fetchData
                }));
}

var Api;

var Table;

var make = Company_Detail_Table_Page;

export {
  Api ,
  Table ,
  make ,
}
/* react Not a pure module */
