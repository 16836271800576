// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../../common/components/App_Checkbox.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_UserTypeCheckBox$DvmAdminFrontendFr from "../../../../common/components/input/App_UserTypeCheckBox.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_Contract_List$DvmAdminFrontendFr from "../../../../intl/messages/contract/Messages_Contract_List.bs.js";
import * as Messages_Company_Manage$DvmAdminFrontendFr from "../../../../intl/messages/company/Messages_Company_Manage.bs.js";
import * as Messages_Company_Virtual$DvmAdminFrontendFr from "../../../../intl/messages/company/Messages_Company_Virtual.bs.js";
import * as Messages_Contract_Create$DvmAdminFrontendFr from "../../../../intl/messages/contract/Messages_Contract_Create.bs.js";
import * as App_DoubleSelectFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_DoubleSelectFormInput.bs.js";
import * as App_DatesEdit_DateWithCheckbox$DvmAdminFrontendFr from "../../../../common/components/dates/App_DatesEdit_DateWithCheckbox.bs.js";

function Company_Virtual_Modal_Inputs(Props) {
  var form = Props.form;
  var row = Props.row;
  var isContractFilled = Props.isContractFilled;
  var selectedPeriodLabel = Props.selectedPeriodLabel;
  var handleDateLabelChange = Props.handleDateLabelChange;
  var contractsModified = Props.contractsModified;
  var intl = ReactIntl.useIntl();
  var tmp = {
    onChange: Curry._1(form.updateSiret, (function (input, siret) {
            return {
                    siret: siret.toUpperCase(),
                    name: input.name,
                    street: input.street,
                    streetNbr: input.streetNbr,
                    additionalAddressInfo: input.additionalAddressInfo,
                    zipCode: input.zipCode,
                    city: input.city,
                    countryCode: input.countryCode,
                    endDate: input.endDate,
                    addAttachment: input.addAttachment,
                    contractID: input.contractID,
                    contractStartDate: input.contractStartDate,
                    contractEndDate: input.contractEndDate,
                    attachmentStartDate: input.attachmentStartDate,
                    attachmentEndDate: input.attachmentEndDate,
                    endDateAsParent: input.endDateAsParent,
                    origAttachmentStartDate: input.origAttachmentStartDate
                  };
          })),
    value: form.input.siret,
    disabled: Belt_Option.isSome(row),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    label: Messages_Company_Virtual$DvmAdminFrontendFr.virtualSiren,
    required: /* OnlyLabel */1
  };
  if (form.siretResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.siretResult);
  }
  var tmp$1 = {
    onChange: Curry._1(form.updateName, (function (input, name) {
            return {
                    siret: input.siret,
                    name: name,
                    street: input.street,
                    streetNbr: input.streetNbr,
                    additionalAddressInfo: input.additionalAddressInfo,
                    zipCode: input.zipCode,
                    city: input.city,
                    countryCode: input.countryCode,
                    endDate: input.endDate,
                    addAttachment: input.addAttachment,
                    contractID: input.contractID,
                    contractStartDate: input.contractStartDate,
                    contractEndDate: input.contractEndDate,
                    attachmentStartDate: input.attachmentStartDate,
                    attachmentEndDate: input.attachmentEndDate,
                    endDateAsParent: input.endDateAsParent,
                    origAttachmentStartDate: input.origAttachmentStartDate
                  };
          })),
    value: form.input.name,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    label: Messages_Common$DvmAdminFrontendFr.companyName,
    required: /* OnlyLabel */1
  };
  if (form.nameResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.nameResult);
  }
  var tmp$2 = {
    onChange: Curry._1(form.updateZipCode, (function (input, zipCode) {
            return {
                    siret: input.siret,
                    name: input.name,
                    street: input.street,
                    streetNbr: input.streetNbr,
                    additionalAddressInfo: input.additionalAddressInfo,
                    zipCode: zipCode,
                    city: input.city,
                    countryCode: input.countryCode,
                    endDate: input.endDate,
                    addAttachment: input.addAttachment,
                    contractID: input.contractID,
                    contractStartDate: input.contractStartDate,
                    contractEndDate: input.contractEndDate,
                    attachmentStartDate: input.attachmentStartDate,
                    attachmentEndDate: input.attachmentEndDate,
                    endDateAsParent: input.endDateAsParent,
                    origAttachmentStartDate: input.origAttachmentStartDate
                  };
          })),
    value: form.input.zipCode,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    label: Messages_Company_Manage$DvmAdminFrontendFr.zipCode,
    required: /* OnlyLabel */1
  };
  if (form.zipCodeResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.zipCodeResult);
  }
  var tmp$3 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    siret: input.siret,
                    name: input.name,
                    street: input.street,
                    streetNbr: input.streetNbr,
                    additionalAddressInfo: input.additionalAddressInfo,
                    zipCode: input.zipCode,
                    city: input.city,
                    countryCode: input.countryCode,
                    endDate: endDate,
                    addAttachment: input.addAttachment,
                    contractID: input.contractID,
                    contractStartDate: input.contractStartDate,
                    contractEndDate: input.contractEndDate,
                    attachmentStartDate: input.attachmentStartDate,
                    attachmentEndDate: input.attachmentEndDate,
                    endDateAsParent: input.endDateAsParent,
                    origAttachmentStartDate: input.origAttachmentStartDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
  };
  if (form.endDateResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  var tmp$4;
  if (form.input.addAttachment) {
    var tmp$5 = {
      label: Messages_Common$DvmAdminFrontendFr.startDate,
      value: form.input.attachmentStartDate,
      onChange: Curry._1(form.updateAttachmentStartDate, (function (input, attachmentStartDate) {
              return {
                      siret: input.siret,
                      name: input.name,
                      street: input.street,
                      streetNbr: input.streetNbr,
                      additionalAddressInfo: input.additionalAddressInfo,
                      zipCode: input.zipCode,
                      city: input.city,
                      countryCode: input.countryCode,
                      endDate: input.endDate,
                      addAttachment: input.addAttachment,
                      contractID: input.contractID,
                      contractStartDate: input.contractStartDate,
                      contractEndDate: input.contractEndDate,
                      attachmentStartDate: attachmentStartDate,
                      attachmentEndDate: input.attachmentEndDate,
                      endDateAsParent: input.endDateAsParent,
                      origAttachmentStartDate: input.origAttachmentStartDate
                    };
            })),
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
      onBlur: (function (param) {
          Curry._1(form.blurAttachmentStartDate, undefined);
        })
    };
    if (form.attachmentStartDateResult !== undefined) {
      tmp$5.validationResult = Caml_option.valFromOption(form.attachmentStartDateResult);
    }
    var tmp$6 = {
      label: Messages_Common$DvmAdminFrontendFr.endDate,
      value: form.input.attachmentEndDate,
      onChange: Curry._1(form.updateAttachmentEndDate, (function (input, attachmentEndDate) {
              return {
                      siret: input.siret,
                      name: input.name,
                      street: input.street,
                      streetNbr: input.streetNbr,
                      additionalAddressInfo: input.additionalAddressInfo,
                      zipCode: input.zipCode,
                      city: input.city,
                      countryCode: input.countryCode,
                      endDate: input.endDate,
                      addAttachment: input.addAttachment,
                      contractID: input.contractID,
                      contractStartDate: input.contractStartDate,
                      contractEndDate: input.contractEndDate,
                      attachmentStartDate: input.attachmentStartDate,
                      attachmentEndDate: attachmentEndDate,
                      endDateAsParent: input.endDateAsParent,
                      origAttachmentStartDate: input.origAttachmentStartDate
                    };
            })),
      disabled: form.input.endDateAsParent,
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
      onBlur: (function (param) {
          Curry._1(form.blurAttachmentEndDate, undefined);
        })
    };
    if (form.attachmentEndDateResult !== undefined) {
      tmp$6.validationResult = Caml_option.valFromOption(form.attachmentEndDateResult);
    }
    tmp$4 = React.createElement(Core.Grid, {
          children: null,
          container: true,
          spacing: 2
        }, React.createElement(Core.Grid, {
              children: React.createElement(App_DoubleSelectFormInput$DvmAdminFrontendFr.make, {
                    labelParent: Messages_Contract_List$DvmAdminFrontendFr.contractID,
                    labelChild: Messages_Company_Virtual$DvmAdminFrontendFr.contractPeriod,
                    valueParent: {
                      TAG: /* Single */0,
                      _0: {
                        value: form.input.contractID,
                        onChange: Curry._1(form.updateContractID, (function (input, contractID) {
                                return {
                                        siret: input.siret,
                                        name: input.name,
                                        street: input.street,
                                        streetNbr: input.streetNbr,
                                        additionalAddressInfo: input.additionalAddressInfo,
                                        zipCode: input.zipCode,
                                        city: input.city,
                                        countryCode: input.countryCode,
                                        endDate: input.endDate,
                                        addAttachment: input.addAttachment,
                                        contractID: contractID,
                                        contractStartDate: input.contractStartDate,
                                        contractEndDate: input.contractEndDate,
                                        attachmentStartDate: input.attachmentStartDate,
                                        attachmentEndDate: input.attachmentEndDate,
                                        endDateAsParent: input.endDateAsParent,
                                        origAttachmentStartDate: input.origAttachmentStartDate
                                      };
                              }))
                      }
                    },
                    valueChild: {
                      TAG: /* Single */0,
                      _0: {
                        value: selectedPeriodLabel,
                        onChange: (function (val) {
                            Curry._2(handleDateLabelChange, val, undefined);
                          })
                      }
                    },
                    options: Belt_List.map(Curry._1(contractsModified, undefined), (function (x) {
                            return {
                                    parent: x.contractID,
                                    child: form.input.contractID === "" ? /* [] */0 : Belt_List.map(x.dates, (function (x) {
                                              return Belt_Option.getWithDefault(x.label, "");
                                            }))
                                  };
                          })),
                    classNameParent: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
                    classNameChild: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                    disableParent: Belt_Option.isSome(row) && isContractFilled,
                    disableChild: Belt_Option.isSome(row) && isContractFilled
                  }),
              item: true
            }), React.createElement(Core.Grid, {
              children: null,
              container: true,
              item: true,
              spacing: 2
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$5),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_DatesEdit_DateWithCheckbox$DvmAdminFrontendFr.make, {
                        checkboxElement: React.createElement(App_UserTypeCheckBox$DvmAdminFrontendFr.make, {
                              label: intl.formatMessage(Messages_Contract_Create$DvmAdminFrontendFr.asContract),
                              checked: form.input.endDateAsParent,
                              onClick: (function (param) {
                                  Curry._2(form.updateEndDateAsParent, (function (input, endDateAsParent) {
                                          return {
                                                  siret: input.siret,
                                                  name: input.name,
                                                  street: input.street,
                                                  streetNbr: input.streetNbr,
                                                  additionalAddressInfo: input.additionalAddressInfo,
                                                  zipCode: input.zipCode,
                                                  city: input.city,
                                                  countryCode: input.countryCode,
                                                  endDate: input.endDate,
                                                  addAttachment: input.addAttachment,
                                                  contractID: input.contractID,
                                                  contractStartDate: input.contractStartDate,
                                                  contractEndDate: input.contractEndDate,
                                                  attachmentStartDate: input.attachmentStartDate,
                                                  attachmentEndDate: input.attachmentEndDate,
                                                  endDateAsParent: endDateAsParent,
                                                  origAttachmentStartDate: input.origAttachmentStartDate
                                                };
                                        }), !form.input.endDateAsParent);
                                })
                            }),
                        children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$6)
                      }),
                  item: true
                })));
  } else {
    tmp$4 = null;
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  spacing: 2,
                  style: {
                    maxWidth: "800px"
                  }
                }, React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      item: true
                    }, React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          item: true,
                          spacing: 2
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp),
                              item: true,
                              sm: Grid$Mui.Sm[6]
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$1),
                              item: true,
                              sm: Grid$Mui.Sm[6]
                            })), React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          item: true,
                          spacing: 2
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                    onChange: Curry._1(form.updateStreetNbr, (function (input, streetNbr) {
                                            return {
                                                    siret: input.siret,
                                                    name: input.name,
                                                    street: input.street,
                                                    streetNbr: streetNbr,
                                                    additionalAddressInfo: input.additionalAddressInfo,
                                                    zipCode: input.zipCode,
                                                    city: input.city,
                                                    countryCode: input.countryCode,
                                                    endDate: input.endDate,
                                                    addAttachment: input.addAttachment,
                                                    contractID: input.contractID,
                                                    contractStartDate: input.contractStartDate,
                                                    contractEndDate: input.contractEndDate,
                                                    attachmentStartDate: input.attachmentStartDate,
                                                    attachmentEndDate: input.attachmentEndDate,
                                                    endDateAsParent: input.endDateAsParent,
                                                    origAttachmentStartDate: input.origAttachmentStartDate
                                                  };
                                          })),
                                    value: form.input.streetNbr,
                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                                    label: Messages_Company_Manage$DvmAdminFrontendFr.streetNbr
                                  }),
                              item: true,
                              sm: Grid$Mui.Sm[2]
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                    onChange: Curry._1(form.updateStreet, (function (input, street) {
                                            return {
                                                    siret: input.siret,
                                                    name: input.name,
                                                    street: street,
                                                    streetNbr: input.streetNbr,
                                                    additionalAddressInfo: input.additionalAddressInfo,
                                                    zipCode: input.zipCode,
                                                    city: input.city,
                                                    countryCode: input.countryCode,
                                                    endDate: input.endDate,
                                                    addAttachment: input.addAttachment,
                                                    contractID: input.contractID,
                                                    contractStartDate: input.contractStartDate,
                                                    contractEndDate: input.contractEndDate,
                                                    attachmentStartDate: input.attachmentStartDate,
                                                    attachmentEndDate: input.attachmentEndDate,
                                                    endDateAsParent: input.endDateAsParent,
                                                    origAttachmentStartDate: input.origAttachmentStartDate
                                                  };
                                          })),
                                    value: form.input.street,
                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                                    label: Messages_Company_Manage$DvmAdminFrontendFr.street
                                  }),
                              item: true,
                              sm: Grid$Mui.Sm[5]
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                    onChange: Curry._1(form.updateAdditionalAddressInfo, (function (input, additionalAddressInfo) {
                                            return {
                                                    siret: input.siret,
                                                    name: input.name,
                                                    street: input.street,
                                                    streetNbr: input.streetNbr,
                                                    additionalAddressInfo: additionalAddressInfo,
                                                    zipCode: input.zipCode,
                                                    city: input.city,
                                                    countryCode: input.countryCode,
                                                    endDate: input.endDate,
                                                    addAttachment: input.addAttachment,
                                                    contractID: input.contractID,
                                                    contractStartDate: input.contractStartDate,
                                                    contractEndDate: input.contractEndDate,
                                                    attachmentStartDate: input.attachmentStartDate,
                                                    attachmentEndDate: input.attachmentEndDate,
                                                    endDateAsParent: input.endDateAsParent,
                                                    origAttachmentStartDate: input.origAttachmentStartDate
                                                  };
                                          })),
                                    value: form.input.additionalAddressInfo,
                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                                    label: Messages_Company_Manage$DvmAdminFrontendFr.additionalAddressInfo
                                  }),
                              item: true,
                              sm: Grid$Mui.Sm[5]
                            })), React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          item: true,
                          spacing: 2
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$2),
                              item: true,
                              sm: Grid$Mui.Sm[3]
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                    onChange: Curry._1(form.updateCity, (function (input, city) {
                                            return {
                                                    siret: input.siret,
                                                    name: input.name,
                                                    street: input.street,
                                                    streetNbr: input.streetNbr,
                                                    additionalAddressInfo: input.additionalAddressInfo,
                                                    zipCode: input.zipCode,
                                                    city: city,
                                                    countryCode: input.countryCode,
                                                    endDate: input.endDate,
                                                    addAttachment: input.addAttachment,
                                                    contractID: input.contractID,
                                                    contractStartDate: input.contractStartDate,
                                                    contractEndDate: input.contractEndDate,
                                                    attachmentStartDate: input.attachmentStartDate,
                                                    attachmentEndDate: input.attachmentEndDate,
                                                    endDateAsParent: input.endDateAsParent,
                                                    origAttachmentStartDate: input.origAttachmentStartDate
                                                  };
                                          })),
                                    value: form.input.city,
                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                                    label: Messages_Common$DvmAdminFrontendFr.city
                                  }),
                              item: true,
                              sm: Grid$Mui.Sm[3]
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                    onChange: Curry._1(form.updateCountryCode, (function (input, countryCode) {
                                            return {
                                                    siret: input.siret,
                                                    name: input.name,
                                                    street: input.street,
                                                    streetNbr: input.streetNbr,
                                                    additionalAddressInfo: input.additionalAddressInfo,
                                                    zipCode: input.zipCode,
                                                    city: input.city,
                                                    countryCode: countryCode,
                                                    endDate: input.endDate,
                                                    addAttachment: input.addAttachment,
                                                    contractID: input.contractID,
                                                    contractStartDate: input.contractStartDate,
                                                    contractEndDate: input.contractEndDate,
                                                    attachmentStartDate: input.attachmentStartDate,
                                                    attachmentEndDate: input.attachmentEndDate,
                                                    endDateAsParent: input.endDateAsParent,
                                                    origAttachmentStartDate: input.origAttachmentStartDate
                                                  };
                                          })),
                                    value: form.input.countryCode,
                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                                    label: Messages_Company_Manage$DvmAdminFrontendFr.countryCode
                                  }),
                              item: true,
                              sm: Grid$Mui.Sm[3]
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$3),
                              item: true,
                              sm: Grid$Mui.Sm[3]
                            }))), React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      item: true
                    }, Belt_Option.isSome(row) && isContractFilled ? null : React.createElement(Core.Grid, {
                            children: React.createElement(Core.FormControlLabel, {
                                  classes: {
                                    label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                  },
                                  control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                        checked: form.input.addAttachment,
                                        onClick: (function ($$event) {
                                            Curry._2(form.updateAddAttachment, (function (input, addAttachment) {
                                                    return {
                                                            siret: input.siret,
                                                            name: input.name,
                                                            street: input.street,
                                                            streetNbr: input.streetNbr,
                                                            additionalAddressInfo: input.additionalAddressInfo,
                                                            zipCode: input.zipCode,
                                                            city: input.city,
                                                            countryCode: input.countryCode,
                                                            endDate: input.endDate,
                                                            addAttachment: addAttachment,
                                                            contractID: input.contractID,
                                                            contractStartDate: input.contractStartDate,
                                                            contractEndDate: input.contractEndDate,
                                                            attachmentStartDate: input.attachmentStartDate,
                                                            attachmentEndDate: input.attachmentEndDate,
                                                            endDateAsParent: input.endDateAsParent,
                                                            origAttachmentStartDate: input.origAttachmentStartDate
                                                          };
                                                  }), $$event.target.checked);
                                          })
                                      }),
                                  label: intl.formatMessage(Messages_Company_Virtual$DvmAdminFrontendFr.addToContract)
                                }),
                            item: true,
                            sm: Grid$Mui.Sm[3]
                          }), tmp$4), React.createElement(Core.Grid, {
                      children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                            onClick: (function (param) {
                                Curry._1(form.submit, undefined);
                              }),
                            label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                            size: "medium",
                            type_: "submit"
                          }),
                      container: true,
                      item: true,
                      justifyContent: "flex-end"
                    })));
}

var Form;

var DateWithCheckbox;

var InputStyles;

var make = Company_Virtual_Modal_Inputs;

export {
  Form ,
  DateWithCheckbox ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
