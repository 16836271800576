// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Table$DvmAdminFrontendFr from "../../../common/components/table/App_Table.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Dealer_Detail_Types$DvmAdminFrontendFr from "./Dealer_Detail_Types.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Pagination.bs.js";
import * as Messages_Dealer_List$DvmAdminFrontendFr from "../../../intl/messages/dealer/Messages_Dealer_List.bs.js";
import * as Messages_Dealer_Detail$DvmAdminFrontendFr from "../../../intl/messages/dealer/Messages_Dealer_Detail.bs.js";

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Dealer_Detail_Types$DvmAdminFrontendFr.Fields.agentCode, undefined, undefined, undefined, undefined, undefined, Messages_Dealer_Detail$DvmAdminFrontendFr.agentCode);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Dealer_Detail_Types$DvmAdminFrontendFr.Fields.dealerName, undefined, undefined, undefined, undefined, undefined, Messages_Dealer_List$DvmAdminFrontendFr.dealerName),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Dealer_Detail_Types$DvmAdminFrontendFr.Fields.city, undefined, undefined, undefined, undefined, undefined, Messages_Dealer_List$DvmAdminFrontendFr.localityName),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Dealer_Detail_Types$DvmAdminFrontendFr.Fields.zipCode, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.zipCode),
      tl: /* [] */0
    }
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function Dealer_Detail_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (param) {
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, undefined, undefined, Belt_List.mapWithIndex({
                                                  hd: param.agentCode,
                                                  tl: {
                                                    hd: Belt_Option.getWithDefault(param.dealerName, ""),
                                                    tl: {
                                                      hd: Belt_Option.getWithDefault(param.city, ""),
                                                      tl: {
                                                        hd: Belt_Option.getWithDefault(param.zipCode, ""),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._1(fetchData, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._1(fetchData, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._1(fetchData, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, {
                  headers: fetchRequest.headers,
                  handleSorting: handleSorting,
                  tablePage: convertTable(tablePage),
                  paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(/* BOTTOM */1, 10, handleChangePage, handleRowsPerPageChange, undefined)
                }));
}

var make = Dealer_Detail_Table;

export {
  headersConfiguration ,
  make ,
}
/* headersConfiguration Not a pure module */
