// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../common/utils/Utils_Api.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../common/utils/Utils_Form.bs.js";
import * as Bonuses_Search_Types$DvmAdminFrontendFr from "../../Bonuses_Search_Types.bs.js";
import * as Bonuses_Search_Modal_Form$DvmAdminFrontendFr from "./Bonuses_Search_Modal_Form.bs.js";

var validators_orderStatus = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.orderStatus, "normal", param.orderStatus)
            };
    })
};

var validators_preinvoiceNumber = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.preinvoiceNumber, param.preinvoiceNumber, false)
            };
    })
};

var validators_invoiceDateEnd = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      var invoiceDateStart = param.invoiceDateStart;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.invoiceDateEnd, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(invoiceDateStart), Caml_option.some(date), Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.invoiceDate, "normal");
                  }));
    })
};

var validators_invoiceDateStart = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      var invoiceDateEnd = param.invoiceDateEnd;
      var invoiceDateStart = param.invoiceDateStart;
      switch (param.invoiceDateExists) {
        case "no" :
            return {
                    TAG: /* Ok */0,
                    _0: Bonuses_Search_Modal_Form$DvmAdminFrontendFr.boolFieldToEmpty(Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.invoiceDate)
                  };
        case "yes" :
            if (invoiceDateStart === undefined && invoiceDateEnd === undefined) {
              return {
                      TAG: /* Ok */0,
                      _0: Bonuses_Search_Modal_Form$DvmAdminFrontendFr.datePairToQuery(undefined, Caml_option.some(new Date(new Date().setFullYear(3000.0))), Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.invoiceDate)
                    };
            }
            break;
        default:
          
      }
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(invoiceDateStart, undefined), (function (date) {
                    return Bonuses_Search_Modal_Form$DvmAdminFrontendFr.datePairToQuery(date, invoiceDateEnd, Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.invoiceDate);
                  }));
    })
};

var validators_preinvoiceDateEnd = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      var preinvoiceDateStart = param.preinvoiceDateStart;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.preinvoiceDateEnd, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(preinvoiceDateStart), Caml_option.some(date), Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.preinvoiceDate, "normal");
                  }));
    })
};

var validators_preinvoiceDateStart = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      var preinvoiceDateEnd = param.preinvoiceDateEnd;
      var preinvoiceDateStart = param.preinvoiceDateStart;
      switch (param.preinvoiceDateExists) {
        case "no" :
            return {
                    TAG: /* Ok */0,
                    _0: Bonuses_Search_Modal_Form$DvmAdminFrontendFr.boolFieldToEmpty(Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.preinvoiceDate)
                  };
        case "yes" :
            if (preinvoiceDateStart === undefined && preinvoiceDateEnd === undefined) {
              return {
                      TAG: /* Ok */0,
                      _0: Bonuses_Search_Modal_Form$DvmAdminFrontendFr.datePairToQuery(undefined, Caml_option.some(new Date(new Date().setFullYear(3000.0))), Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.preinvoiceDate)
                    };
            }
            break;
        default:
          
      }
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(preinvoiceDateStart, undefined), (function (date) {
                    return Bonuses_Search_Modal_Form$DvmAdminFrontendFr.datePairToQuery(date, preinvoiceDateEnd, Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.preinvoiceDate);
                  }));
    })
};

var validators = {
  orderStatus: validators_orderStatus,
  preinvoiceNumber: validators_preinvoiceNumber,
  invoiceDateExists: undefined,
  invoiceDateEnd: validators_invoiceDateEnd,
  invoiceDateStart: validators_invoiceDateStart,
  preinvoiceDateExists: undefined,
  preinvoiceDateEnd: validators_preinvoiceDateEnd,
  preinvoiceDateStart: validators_preinvoiceDateStart
};

function initialFieldsStatuses(_input) {
  return {
          orderStatus: /* Pristine */0,
          preinvoiceNumber: /* Pristine */0,
          invoiceDateExists: /* Pristine */0,
          invoiceDateEnd: /* Pristine */0,
          invoiceDateStart: /* Pristine */0,
          preinvoiceDateExists: /* Pristine */0,
          preinvoiceDateEnd: /* Pristine */0,
          preinvoiceDateStart: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.orderStatus;
  var match_0 = match ? match._0 : Curry._1(validators.orderStatus.validate, input);
  var match$1 = fieldsStatuses.preinvoiceNumber;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.preinvoiceNumber.validate, input);
  var match_0$2 = {
    TAG: /* Ok */0,
    _0: input.invoiceDateExists
  };
  var match$2 = fieldsStatuses.invoiceDateEnd;
  var match_0$3 = match$2 ? match$2._0 : Curry._1(validators.invoiceDateEnd.validate, input);
  var match$3 = fieldsStatuses.invoiceDateStart;
  var match_0$4 = match$3 ? match$3._0 : Curry._1(validators.invoiceDateStart.validate, input);
  var match_0$5 = {
    TAG: /* Ok */0,
    _0: input.preinvoiceDateExists
  };
  var match$4 = fieldsStatuses.preinvoiceDateEnd;
  var match_0$6 = match$4 ? match$4._0 : Curry._1(validators.preinvoiceDateEnd.validate, input);
  var match$5 = fieldsStatuses.preinvoiceDateStart;
  var match_0$7 = match$5 ? match$5._0 : Curry._1(validators.preinvoiceDateStart.validate, input);
  var orderStatusResult = match_0;
  var orderStatusResult$1;
  if (orderStatusResult.TAG === /* Ok */0) {
    var preinvoiceNumberResult = match_0$1;
    if (preinvoiceNumberResult.TAG === /* Ok */0) {
      var invoiceDateExistsResult = match_0$2;
      if (invoiceDateExistsResult.TAG === /* Ok */0) {
        var invoiceDateEndResult = match_0$3;
        if (invoiceDateEndResult.TAG === /* Ok */0) {
          var invoiceDateStartResult = match_0$4;
          if (invoiceDateStartResult.TAG === /* Ok */0) {
            var preinvoiceDateExistsResult = match_0$5;
            if (preinvoiceDateExistsResult.TAG === /* Ok */0) {
              var preinvoiceDateEndResult = match_0$6;
              if (preinvoiceDateEndResult.TAG === /* Ok */0) {
                var preinvoiceDateStartResult = match_0$7;
                if (preinvoiceDateStartResult.TAG === /* Ok */0) {
                  return {
                          TAG: /* Valid */0,
                          output: {
                            preinvoiceDateStart: preinvoiceDateStartResult._0,
                            preinvoiceDateEnd: preinvoiceDateEndResult._0,
                            preinvoiceDateExists: preinvoiceDateExistsResult._0,
                            invoiceDateStart: invoiceDateStartResult._0,
                            invoiceDateEnd: invoiceDateEndResult._0,
                            invoiceDateExists: invoiceDateExistsResult._0,
                            preinvoiceNumber: preinvoiceNumberResult._0,
                            orderStatus: orderStatusResult._0
                          },
                          fieldsStatuses: {
                            orderStatus: /* Dirty */{
                              _0: orderStatusResult,
                              _1: /* Shown */0
                            },
                            preinvoiceNumber: /* Dirty */{
                              _0: preinvoiceNumberResult,
                              _1: /* Shown */0
                            },
                            invoiceDateExists: /* Dirty */{
                              _0: invoiceDateExistsResult,
                              _1: /* Hidden */1
                            },
                            invoiceDateEnd: /* Dirty */{
                              _0: invoiceDateEndResult,
                              _1: /* Shown */0
                            },
                            invoiceDateStart: /* Dirty */{
                              _0: invoiceDateStartResult,
                              _1: /* Shown */0
                            },
                            preinvoiceDateExists: /* Dirty */{
                              _0: preinvoiceDateExistsResult,
                              _1: /* Hidden */1
                            },
                            preinvoiceDateEnd: /* Dirty */{
                              _0: preinvoiceDateEndResult,
                              _1: /* Shown */0
                            },
                            preinvoiceDateStart: /* Dirty */{
                              _0: preinvoiceDateStartResult,
                              _1: /* Shown */0
                            }
                          },
                          collectionsStatuses: undefined
                        };
                }
                orderStatusResult$1 = orderStatusResult;
              } else {
                orderStatusResult$1 = orderStatusResult;
              }
            } else {
              orderStatusResult$1 = orderStatusResult;
            }
          } else {
            orderStatusResult$1 = orderStatusResult;
          }
        } else {
          orderStatusResult$1 = orderStatusResult;
        }
      } else {
        orderStatusResult$1 = orderStatusResult;
      }
    } else {
      orderStatusResult$1 = orderStatusResult;
    }
  } else {
    orderStatusResult$1 = orderStatusResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            orderStatus: /* Dirty */{
              _0: orderStatusResult$1,
              _1: /* Shown */0
            },
            preinvoiceNumber: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            invoiceDateExists: /* Dirty */{
              _0: match_0$2,
              _1: /* Hidden */1
            },
            invoiceDateEnd: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            invoiceDateStart: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            preinvoiceDateExists: /* Dirty */{
              _0: match_0$5,
              _1: /* Hidden */1
            },
            preinvoiceDateEnd: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            preinvoiceDateStart: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurOrderStatusField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.orderStatus, validators_orderStatus, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  orderStatus: status,
                                  preinvoiceNumber: init.preinvoiceNumber,
                                  invoiceDateExists: init.invoiceDateExists,
                                  invoiceDateEnd: init.invoiceDateEnd,
                                  invoiceDateStart: init.invoiceDateStart,
                                  preinvoiceDateExists: init.preinvoiceDateExists,
                                  preinvoiceDateEnd: init.preinvoiceDateEnd,
                                  preinvoiceDateStart: init.preinvoiceDateStart
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPreinvoiceNumberField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.preinvoiceNumber, validators_preinvoiceNumber, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  orderStatus: init.orderStatus,
                                  preinvoiceNumber: status,
                                  invoiceDateExists: init.invoiceDateExists,
                                  invoiceDateEnd: init.invoiceDateEnd,
                                  invoiceDateStart: init.invoiceDateStart,
                                  preinvoiceDateExists: init.preinvoiceDateExists,
                                  preinvoiceDateEnd: init.preinvoiceDateEnd,
                                  preinvoiceDateStart: init.preinvoiceDateStart
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurInvoiceDateExistsField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithoutValidator(state.input.invoiceDateExists, state.fieldsStatuses.invoiceDateExists, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  orderStatus: init.orderStatus,
                                  preinvoiceNumber: init.preinvoiceNumber,
                                  invoiceDateExists: status,
                                  invoiceDateEnd: init.invoiceDateEnd,
                                  invoiceDateStart: init.invoiceDateStart,
                                  preinvoiceDateExists: init.preinvoiceDateExists,
                                  preinvoiceDateEnd: init.preinvoiceDateEnd,
                                  preinvoiceDateStart: init.preinvoiceDateStart
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurInvoiceDateEndField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.invoiceDateEnd, validators_invoiceDateEnd, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  orderStatus: init.orderStatus,
                                  preinvoiceNumber: init.preinvoiceNumber,
                                  invoiceDateExists: init.invoiceDateExists,
                                  invoiceDateEnd: status,
                                  invoiceDateStart: init.invoiceDateStart,
                                  preinvoiceDateExists: init.preinvoiceDateExists,
                                  preinvoiceDateEnd: init.preinvoiceDateEnd,
                                  preinvoiceDateStart: init.preinvoiceDateStart
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurInvoiceDateStartField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.invoiceDateStart, validators_invoiceDateStart, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  orderStatus: init.orderStatus,
                                  preinvoiceNumber: init.preinvoiceNumber,
                                  invoiceDateExists: init.invoiceDateExists,
                                  invoiceDateEnd: init.invoiceDateEnd,
                                  invoiceDateStart: status,
                                  preinvoiceDateExists: init.preinvoiceDateExists,
                                  preinvoiceDateEnd: init.preinvoiceDateEnd,
                                  preinvoiceDateStart: init.preinvoiceDateStart
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPreinvoiceDateExistsField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithoutValidator(state.input.preinvoiceDateExists, state.fieldsStatuses.preinvoiceDateExists, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  orderStatus: init.orderStatus,
                                  preinvoiceNumber: init.preinvoiceNumber,
                                  invoiceDateExists: init.invoiceDateExists,
                                  invoiceDateEnd: init.invoiceDateEnd,
                                  invoiceDateStart: init.invoiceDateStart,
                                  preinvoiceDateExists: status,
                                  preinvoiceDateEnd: init.preinvoiceDateEnd,
                                  preinvoiceDateStart: init.preinvoiceDateStart
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPreinvoiceDateEndField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.preinvoiceDateEnd, validators_preinvoiceDateEnd, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  orderStatus: init.orderStatus,
                                  preinvoiceNumber: init.preinvoiceNumber,
                                  invoiceDateExists: init.invoiceDateExists,
                                  invoiceDateEnd: init.invoiceDateEnd,
                                  invoiceDateStart: init.invoiceDateStart,
                                  preinvoiceDateExists: init.preinvoiceDateExists,
                                  preinvoiceDateEnd: status,
                                  preinvoiceDateStart: init.preinvoiceDateStart
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPreinvoiceDateStartField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.preinvoiceDateStart, validators_preinvoiceDateStart, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  orderStatus: init.orderStatus,
                                  preinvoiceNumber: init.preinvoiceNumber,
                                  invoiceDateExists: init.invoiceDateExists,
                                  invoiceDateEnd: init.invoiceDateEnd,
                                  invoiceDateStart: init.invoiceDateStart,
                                  preinvoiceDateExists: init.preinvoiceDateExists,
                                  preinvoiceDateEnd: init.preinvoiceDateEnd,
                                  preinvoiceDateStart: status
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */8 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */8,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */9,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */11);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */10);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */9 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */10 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */11 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateOrderStatusField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.orderStatus, state.submissionStatus, validators_orderStatus, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            orderStatus: status,
                                            preinvoiceNumber: init.preinvoiceNumber,
                                            invoiceDateExists: init.invoiceDateExists,
                                            invoiceDateEnd: init.invoiceDateEnd,
                                            invoiceDateStart: init.invoiceDateStart,
                                            preinvoiceDateExists: init.preinvoiceDateExists,
                                            preinvoiceDateEnd: init.preinvoiceDateEnd,
                                            preinvoiceDateStart: init.preinvoiceDateStart
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePreinvoiceNumberField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.preinvoiceNumber, state.submissionStatus, validators_preinvoiceNumber, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            orderStatus: init.orderStatus,
                                            preinvoiceNumber: status,
                                            invoiceDateExists: init.invoiceDateExists,
                                            invoiceDateEnd: init.invoiceDateEnd,
                                            invoiceDateStart: init.invoiceDateStart,
                                            preinvoiceDateExists: init.preinvoiceDateExists,
                                            preinvoiceDateEnd: init.preinvoiceDateEnd,
                                            preinvoiceDateStart: init.preinvoiceDateStart
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateInvoiceDateExistsField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$8 = Formality.validateDependentFieldOnChange(nextInput$2, nextFieldsStatuses.contents.invoiceDateStart, validators_invoiceDateStart, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  orderStatus: init.orderStatus,
                                  preinvoiceNumber: init.preinvoiceNumber,
                                  invoiceDateExists: init.invoiceDateExists,
                                  invoiceDateEnd: init.invoiceDateEnd,
                                  invoiceDateStart: status,
                                  preinvoiceDateExists: init.preinvoiceDateExists,
                                  preinvoiceDateEnd: init.preinvoiceDateEnd,
                                  preinvoiceDateStart: init.preinvoiceDateStart
                                };
                        }));
                  if (result$8 !== undefined) {
                    nextFieldsStatuses.contents = result$8;
                  }
                  var result$9 = Formality.validateDependentFieldOnChange(nextInput$2, nextFieldsStatuses.contents.invoiceDateEnd, validators_invoiceDateEnd, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  orderStatus: init.orderStatus,
                                  preinvoiceNumber: init.preinvoiceNumber,
                                  invoiceDateExists: init.invoiceDateExists,
                                  invoiceDateEnd: status,
                                  invoiceDateStart: init.invoiceDateStart,
                                  preinvoiceDateExists: init.preinvoiceDateExists,
                                  preinvoiceDateEnd: init.preinvoiceDateEnd,
                                  preinvoiceDateStart: init.preinvoiceDateStart
                                };
                        }));
                  if (result$9 !== undefined) {
                    nextFieldsStatuses.contents = result$9;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$2.invoiceDateExists, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            orderStatus: init.orderStatus,
                                            preinvoiceNumber: init.preinvoiceNumber,
                                            invoiceDateExists: status,
                                            invoiceDateEnd: init.invoiceDateEnd,
                                            invoiceDateStart: init.invoiceDateStart,
                                            preinvoiceDateExists: init.preinvoiceDateExists,
                                            preinvoiceDateEnd: init.preinvoiceDateEnd,
                                            preinvoiceDateStart: init.preinvoiceDateStart
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateInvoiceDateEndField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$10 = Formality.validateDependentFieldOnChange(nextInput$3, nextFieldsStatuses$1.contents.invoiceDateStart, validators_invoiceDateStart, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  orderStatus: init.orderStatus,
                                  preinvoiceNumber: init.preinvoiceNumber,
                                  invoiceDateExists: init.invoiceDateExists,
                                  invoiceDateEnd: init.invoiceDateEnd,
                                  invoiceDateStart: status,
                                  preinvoiceDateExists: init.preinvoiceDateExists,
                                  preinvoiceDateEnd: init.preinvoiceDateEnd,
                                  preinvoiceDateStart: init.preinvoiceDateStart
                                };
                        }));
                  if (result$10 !== undefined) {
                    nextFieldsStatuses$1.contents = result$10;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, nextFieldsStatuses$1.contents.invoiceDateEnd, state.submissionStatus, validators_invoiceDateEnd, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            orderStatus: init.orderStatus,
                                            preinvoiceNumber: init.preinvoiceNumber,
                                            invoiceDateExists: init.invoiceDateExists,
                                            invoiceDateEnd: status,
                                            invoiceDateStart: init.invoiceDateStart,
                                            preinvoiceDateExists: init.preinvoiceDateExists,
                                            preinvoiceDateEnd: init.preinvoiceDateEnd,
                                            preinvoiceDateStart: init.preinvoiceDateStart
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateInvoiceDateStartField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$2 = {
                    contents: state.fieldsStatuses
                  };
                  var result$11 = Formality.validateDependentFieldOnChange(nextInput$4, nextFieldsStatuses$2.contents.invoiceDateEnd, validators_invoiceDateEnd, (function (status) {
                          var init = nextFieldsStatuses$2.contents;
                          return {
                                  orderStatus: init.orderStatus,
                                  preinvoiceNumber: init.preinvoiceNumber,
                                  invoiceDateExists: init.invoiceDateExists,
                                  invoiceDateEnd: status,
                                  invoiceDateStart: init.invoiceDateStart,
                                  preinvoiceDateExists: init.preinvoiceDateExists,
                                  preinvoiceDateEnd: init.preinvoiceDateEnd,
                                  preinvoiceDateStart: init.preinvoiceDateStart
                                };
                        }));
                  if (result$11 !== undefined) {
                    nextFieldsStatuses$2.contents = result$11;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, nextFieldsStatuses$2.contents.invoiceDateStart, state.submissionStatus, validators_invoiceDateStart, (function (status) {
                                    var init = nextFieldsStatuses$2.contents;
                                    return {
                                            orderStatus: init.orderStatus,
                                            preinvoiceNumber: init.preinvoiceNumber,
                                            invoiceDateExists: init.invoiceDateExists,
                                            invoiceDateEnd: init.invoiceDateEnd,
                                            invoiceDateStart: status,
                                            preinvoiceDateExists: init.preinvoiceDateExists,
                                            preinvoiceDateEnd: init.preinvoiceDateEnd,
                                            preinvoiceDateStart: init.preinvoiceDateStart
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePreinvoiceDateExistsField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$3 = {
                    contents: state.fieldsStatuses
                  };
                  var result$12 = Formality.validateDependentFieldOnChange(nextInput$5, nextFieldsStatuses$3.contents.preinvoiceDateStart, validators_preinvoiceDateStart, (function (status) {
                          var init = nextFieldsStatuses$3.contents;
                          return {
                                  orderStatus: init.orderStatus,
                                  preinvoiceNumber: init.preinvoiceNumber,
                                  invoiceDateExists: init.invoiceDateExists,
                                  invoiceDateEnd: init.invoiceDateEnd,
                                  invoiceDateStart: init.invoiceDateStart,
                                  preinvoiceDateExists: init.preinvoiceDateExists,
                                  preinvoiceDateEnd: init.preinvoiceDateEnd,
                                  preinvoiceDateStart: status
                                };
                        }));
                  if (result$12 !== undefined) {
                    nextFieldsStatuses$3.contents = result$12;
                  }
                  var result$13 = Formality.validateDependentFieldOnChange(nextInput$5, nextFieldsStatuses$3.contents.preinvoiceDateEnd, validators_preinvoiceDateEnd, (function (status) {
                          var init = nextFieldsStatuses$3.contents;
                          return {
                                  orderStatus: init.orderStatus,
                                  preinvoiceNumber: init.preinvoiceNumber,
                                  invoiceDateExists: init.invoiceDateExists,
                                  invoiceDateEnd: init.invoiceDateEnd,
                                  invoiceDateStart: init.invoiceDateStart,
                                  preinvoiceDateExists: init.preinvoiceDateExists,
                                  preinvoiceDateEnd: status,
                                  preinvoiceDateStart: init.preinvoiceDateStart
                                };
                        }));
                  if (result$13 !== undefined) {
                    nextFieldsStatuses$3.contents = result$13;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$5.preinvoiceDateExists, (function (status) {
                                    var init = nextFieldsStatuses$3.contents;
                                    return {
                                            orderStatus: init.orderStatus,
                                            preinvoiceNumber: init.preinvoiceNumber,
                                            invoiceDateExists: init.invoiceDateExists,
                                            invoiceDateEnd: init.invoiceDateEnd,
                                            invoiceDateStart: init.invoiceDateStart,
                                            preinvoiceDateExists: status,
                                            preinvoiceDateEnd: init.preinvoiceDateEnd,
                                            preinvoiceDateStart: init.preinvoiceDateStart
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePreinvoiceDateEndField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$4 = {
                    contents: state.fieldsStatuses
                  };
                  var result$14 = Formality.validateDependentFieldOnChange(nextInput$6, nextFieldsStatuses$4.contents.preinvoiceDateStart, validators_preinvoiceDateStart, (function (status) {
                          var init = nextFieldsStatuses$4.contents;
                          return {
                                  orderStatus: init.orderStatus,
                                  preinvoiceNumber: init.preinvoiceNumber,
                                  invoiceDateExists: init.invoiceDateExists,
                                  invoiceDateEnd: init.invoiceDateEnd,
                                  invoiceDateStart: init.invoiceDateStart,
                                  preinvoiceDateExists: init.preinvoiceDateExists,
                                  preinvoiceDateEnd: init.preinvoiceDateEnd,
                                  preinvoiceDateStart: status
                                };
                        }));
                  if (result$14 !== undefined) {
                    nextFieldsStatuses$4.contents = result$14;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, nextFieldsStatuses$4.contents.preinvoiceDateEnd, state.submissionStatus, validators_preinvoiceDateEnd, (function (status) {
                                    var init = nextFieldsStatuses$4.contents;
                                    return {
                                            orderStatus: init.orderStatus,
                                            preinvoiceNumber: init.preinvoiceNumber,
                                            invoiceDateExists: init.invoiceDateExists,
                                            invoiceDateEnd: init.invoiceDateEnd,
                                            invoiceDateStart: init.invoiceDateStart,
                                            preinvoiceDateExists: init.preinvoiceDateExists,
                                            preinvoiceDateEnd: status,
                                            preinvoiceDateStart: init.preinvoiceDateStart
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePreinvoiceDateStartField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$5 = {
                    contents: state.fieldsStatuses
                  };
                  var result$15 = Formality.validateDependentFieldOnChange(nextInput$7, nextFieldsStatuses$5.contents.preinvoiceDateEnd, validators_preinvoiceDateEnd, (function (status) {
                          var init = nextFieldsStatuses$5.contents;
                          return {
                                  orderStatus: init.orderStatus,
                                  preinvoiceNumber: init.preinvoiceNumber,
                                  invoiceDateExists: init.invoiceDateExists,
                                  invoiceDateEnd: init.invoiceDateEnd,
                                  invoiceDateStart: init.invoiceDateStart,
                                  preinvoiceDateExists: init.preinvoiceDateExists,
                                  preinvoiceDateEnd: status,
                                  preinvoiceDateStart: init.preinvoiceDateStart
                                };
                        }));
                  if (result$15 !== undefined) {
                    nextFieldsStatuses$5.contents = result$15;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$7, nextFieldsStatuses$5.contents.preinvoiceDateStart, state.submissionStatus, validators_preinvoiceDateStart, (function (status) {
                                    var init = nextFieldsStatuses$5.contents;
                                    return {
                                            orderStatus: init.orderStatus,
                                            preinvoiceNumber: init.preinvoiceNumber,
                                            invoiceDateExists: init.invoiceDateExists,
                                            invoiceDateEnd: init.invoiceDateEnd,
                                            invoiceDateStart: init.invoiceDateStart,
                                            preinvoiceDateExists: init.preinvoiceDateExists,
                                            preinvoiceDateEnd: init.preinvoiceDateEnd,
                                            preinvoiceDateStart: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */8 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */9 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */10 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateOrderStatus: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrderStatusField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updatePreinvoiceNumber: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePreinvoiceNumberField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateInvoiceDateExists: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateInvoiceDateExistsField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateInvoiceDateEnd: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateInvoiceDateEndField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateInvoiceDateStart: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateInvoiceDateStartField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updatePreinvoiceDateExists: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePreinvoiceDateExistsField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updatePreinvoiceDateEnd: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePreinvoiceDateEndField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updatePreinvoiceDateStart: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePreinvoiceDateStartField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurOrderStatus: (function (param) {
              Curry._1(dispatch, /* BlurOrderStatusField */0);
            }),
          blurPreinvoiceNumber: (function (param) {
              Curry._1(dispatch, /* BlurPreinvoiceNumberField */1);
            }),
          blurInvoiceDateExists: (function (param) {
              Curry._1(dispatch, /* BlurInvoiceDateExistsField */2);
            }),
          blurInvoiceDateEnd: (function (param) {
              Curry._1(dispatch, /* BlurInvoiceDateEndField */3);
            }),
          blurInvoiceDateStart: (function (param) {
              Curry._1(dispatch, /* BlurInvoiceDateStartField */4);
            }),
          blurPreinvoiceDateExists: (function (param) {
              Curry._1(dispatch, /* BlurPreinvoiceDateExistsField */5);
            }),
          blurPreinvoiceDateEnd: (function (param) {
              Curry._1(dispatch, /* BlurPreinvoiceDateEndField */6);
            }),
          blurPreinvoiceDateStart: (function (param) {
              Curry._1(dispatch, /* BlurPreinvoiceDateStartField */7);
            }),
          orderStatusResult: Formality.exposeFieldResult(state.fieldsStatuses.orderStatus),
          preinvoiceNumberResult: Formality.exposeFieldResult(state.fieldsStatuses.preinvoiceNumber),
          invoiceDateExistsResult: Formality.exposeFieldResult(state.fieldsStatuses.invoiceDateExists),
          invoiceDateEndResult: Formality.exposeFieldResult(state.fieldsStatuses.invoiceDateEnd),
          invoiceDateStartResult: Formality.exposeFieldResult(state.fieldsStatuses.invoiceDateStart),
          preinvoiceDateExistsResult: Formality.exposeFieldResult(state.fieldsStatuses.preinvoiceDateExists),
          preinvoiceDateEndResult: Formality.exposeFieldResult(state.fieldsStatuses.preinvoiceDateEnd),
          preinvoiceDateStartResult: Formality.exposeFieldResult(state.fieldsStatuses.preinvoiceDateStart),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.orderStatus || match.preinvoiceNumber || match.invoiceDateExists || match.invoiceDateEnd || match.invoiceDateStart || match.preinvoiceDateExists || match.preinvoiceDateEnd || match.preinvoiceDateStart) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */8);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */9);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */10);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */10,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */11);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  preinvoiceDateStart: undefined,
  preinvoiceDateEnd: undefined,
  preinvoiceDateExists: "",
  invoiceDateStart: undefined,
  invoiceDateEnd: undefined,
  invoiceDateExists: "",
  preinvoiceNumber: "",
  orderStatus: /* [] */0
};

export {
  TableFilterForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
