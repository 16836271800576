// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_SelectFormInput.bs.js";
import * as Margin_Minimum_Form$DvmAdminFrontendFr from "./Margin_Minimum_Form.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../common/components/input/App_DateInputFormField.bs.js";

function Margin_Minimum_Filters(Props) {
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var budgets = Props.budgets;
  var form = Margin_Minimum_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Margin_Minimum_Form$DvmAdminFrontendFr.initialState, (function (param, submissionCallbacks) {
          Curry._2(fetchData, submissionCallbacks, {
                page: 0,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: fetchRequest.headers,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                              hd: param.yearBudget,
                              tl: {
                                hd: param.budgetType,
                                tl: /* [] */0
                              }
                            }, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.year,
    value: form.input.yearBudget,
    onChange: Curry._1(form.updateYearBudget, (function (input, yearBudget) {
            return {
                    yearBudget: yearBudget,
                    budgetType: input.budgetType
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
    variant: /* YEAR */0
  };
  if (form.yearBudgetResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.yearBudgetResult);
  }
  var tmp$1 = {
    label: Messages_Common$DvmAdminFrontendFr.budget,
    options: Belt_List.add(Belt_List.map(budgets, (function (x) {
                return {
                        label: {
                          TAG: /* String */3,
                          _0: x
                        },
                        value: x
                      };
              })), {
          label: {
            TAG: /* Message */0,
            _0: Messages_Common$DvmAdminFrontendFr.nothing
          },
          value: ""
        }),
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.budgetType,
        onChange: Curry._1(form.updateBudgetType, (function (input, budgetType) {
                return {
                        yearBudget: input.yearBudget,
                        budgetType: budgetType
                      };
              }))
      }
    },
    id: "budget",
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
  };
  if (form.budgetTypeResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.budgetTypeResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, tmp$1),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var Form;

var InputStyles;

var make = Margin_Minimum_Filters;

export {
  Form ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
