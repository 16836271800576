// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReDate_utils from "@mobily/rescript-date/src/ReDate_utils.bs.js";

function areTwoDatesEqual(dateOne, dateTwo) {
  if (dateOne !== undefined) {
    if (dateTwo !== undefined) {
      return ReDate.isEqual(ReDate_utils.makeStartOfDayDate(Caml_option.valFromOption(dateTwo)), ReDate_utils.makeStartOfDayDate(Caml_option.valFromOption(dateOne)));
    } else {
      return false;
    }
  } else {
    return dateTwo === undefined;
  }
}

function compareStringArrays(arrayOne, arrayTwo) {
  return Belt_List.reduce(arrayOne, "", (function (a, b) {
                return a + b;
              })) === Belt_List.reduce(arrayTwo, "", (function (a, b) {
                return a + b;
              }));
}

function hasFormChanged(manager, form, initialRows) {
  if (Belt_Option.mapWithDefault(manager, false, (function (x) {
            return x.managerID !== form.input.managerID ? true : Belt_Option.getWithDefault(x.managerName, "") !== form.input.managerName;
          })) || Belt_Array.reduce(Belt_Array.map(form.input.budgetRows, (function (x) {
                return !Belt_Option.isSome(Belt_Array.getBy(initialRows, (function (row) {
                                  return areTwoDatesEqual(row.startDate, x.startDate) && areTwoDatesEqual(row.endDate, x.endDate) ? compareStringArrays(row.budgets, x.budgets) : false;
                                })));
              })), false, (function (a, b) {
            return a ? true : b;
          }))) {
    return true;
  } else {
    return form.input.budgetRows.length !== initialRows.length;
  }
}

var Types;

var Form;

export {
  Types ,
  Form ,
  areTwoDatesEqual ,
  compareStringArrays ,
  hasFormChanged ,
}
/* No side effect */
