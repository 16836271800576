// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as User_Context$DvmAdminFrontendFr from "./user/User_Context.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "./router/Context_Router.bs.js";

function Context_Wrapper(Props) {
  var children = Props.children;
  return React.createElement(User_Context$DvmAdminFrontendFr.Provider.make, {
              children: React.createElement(Context_Router$DvmAdminFrontendFr.Provider.make, {
                    children: children
                  })
            });
}

var make = Context_Wrapper;

export {
  make ,
}
/* react Not a pure module */
