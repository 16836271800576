// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as App_Table$DvmAdminFrontendFr from "../../../../common/components/table/App_Table.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../common/utils/Utils_Api.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../theme/Theme_Colors.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as Messages_Quotas$DvmAdminFrontendFr from "../../../../intl/messages/quotas/Messages_Quotas.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as Quota_View_Types$DvmAdminFrontendFr from "./Quota_View_Types.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Pagination.bs.js";
import * as App_Table_Export_Menu$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Export_Menu.bs.js";
import * as Quota_View_Modal_Exclusion$DvmAdminFrontendFr from "./modal/Quota_View_Modal_Exclusion.bs.js";

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Types$DvmAdminFrontendFr.Columns.siret, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.siret);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Types$DvmAdminFrontendFr.Columns.name, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.companyName),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Types$DvmAdminFrontendFr.Columns.dealerId, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.dealerID),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Types$DvmAdminFrontendFr.Columns.initial, undefined, undefined, undefined, undefined, undefined, Messages_Quotas$DvmAdminFrontendFr.initial),
      tl: {
        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Types$DvmAdminFrontendFr.Columns.corrected, undefined, undefined, undefined, undefined, undefined, Messages_Quotas$DvmAdminFrontendFr.corrected),
        tl: {
          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Types$DvmAdminFrontendFr.Columns.customerInvoiced, undefined, undefined, undefined, undefined, undefined, Messages_Quotas$DvmAdminFrontendFr.customerInvoiced),
          tl: {
            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Types$DvmAdminFrontendFr.Columns.customerNotInvoiced, undefined, undefined, undefined, undefined, undefined, Messages_Quotas$DvmAdminFrontendFr.customerNotInvoiced),
            tl: {
              hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Types$DvmAdminFrontendFr.Columns.consumedTotal, undefined, undefined, undefined, undefined, undefined, Messages_Quotas$DvmAdminFrontendFr.consumedTotal),
              tl: {
                hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Types$DvmAdminFrontendFr.Columns.remaining, undefined, undefined, undefined, undefined, undefined, Messages_Quotas$DvmAdminFrontendFr.remaining),
                tl: {
                  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Types$DvmAdminFrontendFr.Columns.exceeded, undefined, undefined, undefined, undefined, undefined, Messages_Quotas$DvmAdminFrontendFr.exceeded),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    }
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function Quota_View_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var match = React.useState(function () {
        return null;
      });
  var setModal = match[1];
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (quota) {
                                  var row_0 = Belt_Option.getWithDefault(quota.siret, "");
                                  var row_1 = {
                                    hd: Belt_Option.getWithDefault(quota.companyName, ""),
                                    tl: {
                                      hd: Belt_Option.getWithDefault(quota.dealerId, ""),
                                      tl: {
                                        hd: Belt_Option.mapWithDefault(quota.initial, "", (function (prim) {
                                                return String(prim);
                                              })),
                                        tl: {
                                          hd: Belt_Option.mapWithDefault(quota.corrected, "", (function (prim) {
                                                  return String(prim);
                                                })),
                                          tl: {
                                            hd: Belt_Option.mapWithDefault(quota.customerInvoiced, "", (function (prim) {
                                                    return String(prim);
                                                  })),
                                            tl: {
                                              hd: Belt_Option.mapWithDefault(quota.customerNotInvoiced, "", (function (prim) {
                                                      return String(prim);
                                                    })),
                                              tl: {
                                                hd: Belt_Option.mapWithDefault(quota.consumedTotal, "", (function (prim) {
                                                        return String(prim);
                                                      })),
                                                tl: {
                                                  hd: Belt_Option.mapWithDefault(quota.remaining, "", (function (prim) {
                                                          return String(prim);
                                                        })),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  };
                                  var row = {
                                    hd: row_0,
                                    tl: row_1
                                  };
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow((function (param) {
                                                Curry._1(setModal, (function (param) {
                                                        return React.createElement(Quota_View_Modal_Exclusion$DvmAdminFrontendFr.make, {
                                                                    quota: {
                                                                      dr: quota.dr,
                                                                      drName: undefined,
                                                                      contractId: quota.contractID,
                                                                      contractLabel: undefined,
                                                                      vehicleType: quota.vehicleType,
                                                                      volume: undefined,
                                                                      startDate: quota.quotaStartDate,
                                                                      endDate: undefined
                                                                    },
                                                                    onClose: (function (param) {
                                                                        Curry._1(setModal, (function (param) {
                                                                                return null;
                                                                              }));
                                                                      })
                                                                  });
                                                      }));
                                              }), undefined, undefined, Belt_List.concat(Belt_List.mapWithIndex(row, (function (index, content) {
                                                        return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                          return param.align;
                                                                        })), undefined, undefined, content);
                                                      })), {
                                                  hd: App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, Belt_List.length(row)), (function (param) {
                                                              return param.align;
                                                            })), Caml_option.some(Belt_Option.getWithDefault(quota.exceeded, 0) > 0 ? Theme_Colors$DvmAdminFrontendFr.accentRedHover : undefined), undefined, Belt_Option.mapWithDefault(quota.exceeded, "", (function (prim) {
                                                              return String(prim);
                                                            }))),
                                                  tl: /* [] */0
                                                }));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._2(fetchData, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, {
                  headers: fetchRequest.headers,
                  handleSorting: handleSorting,
                  tablePage: convertTable(tablePage),
                  paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined),
                  exportMenu: React.createElement(App_Table_Export_Menu$DvmAdminFrontendFr.make, {
                        query: Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                              page: 0,
                              rowsPerPage: 150000,
                              headers: fetchRequest.headers,
                              filterQueryParams: fetchRequest.filterQueryParams,
                              additionalQueryParams: fetchRequest.additionalQueryParams
                            }),
                        endpoint: "quotas"
                      })
                }), match[0]);
}

var make = Quota_View_Table;

export {
  headersConfiguration ,
  make ,
}
/* headersConfiguration Not a pure module */
