// Generated by ReScript, PLEASE EDIT WITH CARE


function toRem(size) {
  return "" + String(size) + "rem";
}

function multiply(by, size) {
  return Math.round(size * by * 1000) / 1000;
}

function multiplyToRem(by, size) {
  return toRem(multiply(by, size));
}

function pxToRem(size) {
  return size / 10;
}

function remToPx(size) {
  return size * 10;
}

var globalFontSize = 10;

var defaultIconSize = 2.0;

export {
  globalFontSize ,
  defaultIconSize ,
  toRem ,
  multiply ,
  multiplyToRem ,
  pxToRem ,
  remToPx ,
}
/* No side effect */
