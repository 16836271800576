// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReactPiwik from "react-piwik";
import ReactPiwik$1 from "react-piwik";

function initPiwikEnabled(url, siteId) {
  console.log("Piwik is enabled", url, siteId);
  new ReactPiwik$1({
        url: url,
        siteId: siteId,
        trackErrors: true,
        jsFilename: "piwik.js",
        phpFilename: "piwik.php"
      });
  ReactPiwik.default.push(["enableHeartBeatTimer"]);
}

function initPiwikDisabled(url, siteId) {
  console.log("Piwik is disabled", url, siteId);
}

function initPiwik(piwikEnabled) {
  if (piwikEnabled) {
    return initPiwikEnabled;
  } else {
    return initPiwikDisabled;
  }
}

function push(pushedValues) {
  ReactPiwik.default.push(pushedValues);
}

function pushVisitedUrlEnabled(url, userId) {
  ReactPiwik.default.push([
        "setUserId",
        userId
      ]);
  ReactPiwik.default.push([
        "setCustomUrl",
        url
      ]);
  ReactPiwik.default.push(["trackPageView"]);
}

function pushVisitedUrl(piwikEnabled) {
  if (piwikEnabled) {
    return pushVisitedUrlEnabled;
  } else {
    return function (param, param$1) {
      
    };
  }
}

export {
  initPiwikEnabled ,
  initPiwikDisabled ,
  initPiwik ,
  push ,
  pushVisitedUrlEnabled ,
  pushVisitedUrl ,
}
/* react-piwik Not a pure module */
