// Generated by ReScript, PLEASE EDIT WITH CARE


function makeRow(onClick, rowButton, disabled, cells) {
  return {
          cells: cells,
          onClick: onClick,
          rowButton: rowButton,
          disabled: disabled
        };
}

export {
  makeRow ,
}
/* No side effect */
