// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

var Fields = {
  actionId: "actionId",
  model: "model",
  version: "version",
  versionLabel: "versionLabel",
  startDate: "startDate",
  endDate: "endDate",
  customBonus: "customBonus",
  isCustomFEValue: "isCustomFEValue",
  engineType: "engineType",
  modelPhase: "modelPhase"
};

function row_encode(v) {
  return Js_dict.fromArray([
              [
                "brandDescription",
                Decco.stringToJson(v.brandDescription)
              ],
              [
                "model",
                Decco.stringToJson(v.model)
              ],
              [
                "version",
                Decco.stringToJson(v.version)
              ],
              [
                "versionLabel",
                Decco.optionToJson(Decco.stringToJson, v.versionLabel)
              ],
              [
                "customBonus",
                Decco.optionToJson(Decco.floatToJson, v.customBonus)
              ],
              [
                "isCustomFEValue",
                Decco.optionToJson(Decco.boolToJson, v.isCustomFEValue)
              ],
              [
                "engineType",
                Decco.optionToJson(Decco.stringToJson, v.engineType)
              ],
              [
                "modelPhase",
                Decco.optionToJson(Decco.stringToJson, v.modelPhase)
              ]
            ]);
}

function row_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var brandDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "brandDescription"), null));
  if (brandDescription.TAG === /* Ok */0) {
    var model = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "model"), null));
    if (model.TAG === /* Ok */0) {
      var version = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "version"), null));
      if (version.TAG === /* Ok */0) {
        var versionLabel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "versionLabel"), null));
        if (versionLabel.TAG === /* Ok */0) {
          var customBonus = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "customBonus"), null));
          if (customBonus.TAG === /* Ok */0) {
            var isCustomFEValue = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "isCustomFEValue"), null));
            if (isCustomFEValue.TAG === /* Ok */0) {
              var engineType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "engineType"), null));
              if (engineType.TAG === /* Ok */0) {
                var modelPhase = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelPhase"), null));
                if (modelPhase.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: {
                            brandDescription: brandDescription._0,
                            model: model._0,
                            version: version._0,
                            versionLabel: versionLabel._0,
                            customBonus: customBonus._0,
                            isCustomFEValue: isCustomFEValue._0,
                            engineType: engineType._0,
                            modelPhase: modelPhase._0
                          }
                        };
                }
                var e = modelPhase._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".modelPhase" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = engineType._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".engineType" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = isCustomFEValue._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".isCustomFEValue" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = customBonus._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".customBonus" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = versionLabel._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".versionLabel" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = version._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".version" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = model._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".model" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = brandDescription._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".brandDescription" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function actionVersionsList_encode(v) {
  return Decco.listToJson(row_encode, v);
}

function actionVersionsList_decode(v) {
  return Decco.listFromJson(row_decode, v);
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(row_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(row_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var ModalSearchResult = {};

export {
  Fields ,
  row_encode ,
  row_decode ,
  actionVersionsList_encode ,
  actionVersionsList_decode ,
  searchResult_encode ,
  searchResult_decode ,
  ModalSearchResult ,
}
/* No side effect */
