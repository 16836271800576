// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";

function makeOptions(timeoutOpt, type_Opt, onOpenOpt, onCloseOpt, headerMsgOpt, param) {
  var timeout = timeoutOpt !== undefined ? Caml_option.valFromOption(timeoutOpt) : undefined;
  var type_ = type_Opt !== undefined ? Caml_option.valFromOption(type_Opt) : undefined;
  var onOpen = onOpenOpt !== undefined ? Caml_option.valFromOption(onOpenOpt) : undefined;
  var onClose = onCloseOpt !== undefined ? Caml_option.valFromOption(onCloseOpt) : undefined;
  var headerMsg = headerMsgOpt !== undefined ? Caml_option.valFromOption(headerMsgOpt) : undefined;
  return {
          timeout: timeout,
          type: type_,
          onOpen: onOpen,
          onClose: onClose,
          headerMsg: headerMsg
        };
}

var AlertProvider = {};

export {
  makeOptions ,
  AlertProvider ,
}
/* No side effect */
