// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../../../common/App_Api.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../../../context/user/User_Types_Utils.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as Contract_Search_Api$DvmAdminFrontendFr from "../../../../contract/search/Contract_Search_Api.bs.js";
import * as App_CompanyFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_CompanyFormInput.bs.js";
import * as App_DebounceFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_DebounceFormInput.bs.js";
import * as Messages_Orders_Modal$DvmAdminFrontendFr from "../../../../../intl/messages/orders/Messages_Orders_Modal.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_AutocompleteFormInput.bs.js";

function Order_Search_Modal_Customer_Filters(Props) {
  var form = Props.form;
  var formAdditional = Props.formAdditional;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setCustomerName = match[1];
  var alert = ReactAlert.useAlert();
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  var callApi = function (filter) {
    App_Api$DvmAdminFrontendFr.fetchCustomerName(userManager, setCustomerName, alert, selectedRole, filter);
  };
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var match$6 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setContractsPROTAVS = match$6[1];
  var match$7 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setContractsLLDLCD = match$7[1];
  React.useEffect((function () {
          Contract_Search_Api$DvmAdminFrontendFr.fetchContractSearch(undefined, userManager, setContractsPROTAVS, "?limit=50000&offset=0&filter=budget=AVS%5C%2CPROT", selectedRole);
          Contract_Search_Api$DvmAdminFrontendFr.fetchContractSearch(undefined, userManager, setContractsLLDLCD, "?limit=50000&offset=0&filter=budget=LLD%5C%2CLCD", selectedRole);
        }), []);
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              item: true,
              spacing: 3
            }, User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                        children: React.createElement(App_CompanyFormInput$DvmAdminFrontendFr.make, {
                              onChange: (function (siren) {
                                  Curry._2(formAdditional.updateSiren, (function (input, siren) {
                                          var newrecord = Caml_obj.obj_dup(input);
                                          newrecord.siren = siren;
                                          return newrecord;
                                        }), siren.trim());
                                }),
                              value: formAdditional.input.siren,
                              companies: match$5[0],
                              setCompanies: match$5[1],
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                              companyType: "siren",
                              label: Messages_Common$DvmAdminFrontendFr.siren
                            }),
                        item: true,
                        sm: Grid$Mui.Sm[6]
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(App_DebounceFormInput$DvmAdminFrontendFr.make, {
                              onChange: (function (customerName) {
                                  Curry._2(form.updateCustomerName, (function (input, customerName) {
                                          var newrecord = Caml_obj.obj_dup(input);
                                          newrecord.customerName = customerName;
                                          return newrecord;
                                        }), customerName.trim());
                                }),
                              value: form.input.customerName,
                              values: match[0],
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                              callApi: callApi,
                              label: Messages_Common$DvmAdminFrontendFr.companyName,
                              debounceTriggerLength: 2
                            }),
                        item: true,
                        sm: Grid$Mui.Sm[6]
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(App_CompanyFormInput$DvmAdminFrontendFr.make, {
                              onChange: (function (siren) {
                                  Curry._2(formAdditional.updateSirenClientFinalLLD, (function (input, sirenClientFinalLLD) {
                                          var newrecord = Caml_obj.obj_dup(input);
                                          newrecord.sirenClientFinalLLD = sirenClientFinalLLD;
                                          return newrecord;
                                        }), siren.trim());
                                }),
                              value: formAdditional.input.sirenClientFinalLLD,
                              companies: match$3[0],
                              setCompanies: match$3[1],
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                              companyType: "siren",
                              label: Messages_Orders_Modal$DvmAdminFrontendFr.sirenClientFinalLLD
                            }),
                        item: true,
                        sm: Grid$Mui.Sm[6]
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                              onChange: Curry._1(formAdditional.updateSirenClientFinalLLDName, (function (input, sirenClientFinalLLDName) {
                                      var newrecord = Caml_obj.obj_dup(input);
                                      newrecord.sirenClientFinalLLDName = sirenClientFinalLLDName;
                                      return newrecord;
                                    })),
                              value: formAdditional.input.sirenClientFinalLLDName,
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                              label: Messages_Orders_Modal$DvmAdminFrontendFr.sirenClientFinalLLDName
                            }),
                        item: true,
                        sm: Grid$Mui.Sm[6]
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(App_CompanyFormInput$DvmAdminFrontendFr.make, {
                              onChange: (function (siren) {
                                  Curry._2(formAdditional.updateMainContractSIREN, (function (input, mainContractSIREN) {
                                          var newrecord = Caml_obj.obj_dup(input);
                                          newrecord.mainContractSIREN = mainContractSIREN;
                                          return newrecord;
                                        }), siren.trim());
                                }),
                              value: formAdditional.input.mainContractSIREN,
                              companies: match$4[0],
                              setCompanies: match$4[1],
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                              companyType: "siren",
                              label: Messages_Orders_Modal$DvmAdminFrontendFr.mainContractSIREN
                            }),
                        item: true,
                        sm: Grid$Mui.Sm[6]
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Modal$DvmAdminFrontendFr.contractID,
                              value: {
                                TAG: /* Single */0,
                                _0: {
                                  value: formAdditional.input.contractID,
                                  onChange: Curry._1(formAdditional.updateContractID, (function (input, contractID) {
                                          var newrecord = Caml_obj.obj_dup(input);
                                          newrecord.contractID = contractID;
                                          return newrecord;
                                        }))
                                }
                              },
                              options: {
                                TAG: /* Labeled */1,
                                _0: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(match$6[0], /* [] */0, (function (x) {
                                        return Belt_List.map(x.content, (function (x) {
                                                      return {
                                                              label: {
                                                                TAG: /* String */3,
                                                                _0: x.contractID + Belt_Option.mapWithDefault(x.contractLabel, "", (function (x) {
                                                                        return " (" + x + ")";
                                                                      }))
                                                              },
                                                              value: x.contractID
                                                            };
                                                    }));
                                      }))
                              },
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                            }),
                        item: true,
                        sm: Grid$Mui.Sm[6]
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Modal$DvmAdminFrontendFr.loueurID,
                              value: {
                                TAG: /* Single */0,
                                _0: {
                                  value: formAdditional.input.loueurID,
                                  onChange: Curry._1(formAdditional.updateLoueurID, (function (input, loueurID) {
                                          var newrecord = Caml_obj.obj_dup(input);
                                          newrecord.loueurID = loueurID;
                                          return newrecord;
                                        }))
                                }
                              },
                              options: {
                                TAG: /* Labeled */1,
                                _0: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(match$7[0], /* [] */0, (function (x) {
                                        return Belt_List.map(x.content, (function (x) {
                                                      return {
                                                              label: {
                                                                TAG: /* String */3,
                                                                _0: x.contractID + Belt_Option.mapWithDefault(x.contractLabel, "", (function (x) {
                                                                        return " (" + x + ")";
                                                                      }))
                                                              },
                                                              value: x.contractID
                                                            };
                                                    }));
                                      }))
                              },
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                            }),
                        item: true,
                        sm: Grid$Mui.Sm[6]
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Modal$DvmAdminFrontendFr.codeAPE,
                              value: {
                                TAG: /* Multiple */1,
                                _0: {
                                  value: formAdditional.input.codeAPE,
                                  onChange: Curry._1(formAdditional.updateCodeAPE, (function (input, codeAPE) {
                                          var newrecord = Caml_obj.obj_dup(input);
                                          newrecord.codeAPE = codeAPE;
                                          return newrecord;
                                        }))
                                }
                              },
                              options: {
                                TAG: /* Unlabeled */0,
                                _0: /* [] */0
                              },
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                              freeSolo: true,
                              placeholder: intl.formatMessage(Messages_Orders_Modal$DvmAdminFrontendFr.codeAPEhint)
                            }),
                        item: true,
                        sm: Grid$Mui.Sm[6]
                      })) : null, React.createElement(Core.Grid, {
                  children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                        onChange: Curry._1(formAdditional.updateCustomerName, (function (input, customerName) {
                                var newrecord = Caml_obj.obj_dup(input);
                                newrecord.customerName = customerName;
                                return newrecord;
                              })),
                        value: formAdditional.input.customerName,
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                        label: Messages_Orders_Detail$DvmAdminFrontendFr.customerName
                      }),
                  item: true,
                  sm: Grid$Mui.Sm[6]
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                        onChange: Curry._1(formAdditional.updateFirstName, (function (input, firstName) {
                                var newrecord = Caml_obj.obj_dup(input);
                                newrecord.firstName = firstName;
                                return newrecord;
                              })),
                        value: formAdditional.input.firstName,
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                        label: Messages_Orders_Detail$DvmAdminFrontendFr.clientFirstName
                      }),
                  item: true,
                  sm: Grid$Mui.Sm[6]
                }));
}

var InputStyles;

var make = Order_Search_Modal_Customer_Filters;

export {
  InputStyles ,
  make ,
}
/* react Not a pure module */
