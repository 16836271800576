// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Api.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Form.bs.js";
import * as Order_Search_Types$DvmAdminFrontendFr from "../../Order_Search_Types.bs.js";

function boolEmptyFieldToQueryParamBilingual(empty, field) {
  if (empty) {
    return {
            en: field[0] + "=",
            fr: field[1] + "="
          };
  }
  
}

var validators_registrationIDEmpty = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: boolEmptyFieldToQueryParamBilingual(param.registrationIDEmpty, Order_Search_Types$DvmAdminFrontendFr.Fields.usedCarRegistrationID)
            };
    })
};

var validators_usedCarRegistrationID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.registrationIDEmpty ? undefined : Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(false, Order_Search_Types$DvmAdminFrontendFr.Fields.usedCarRegistrationID, param.usedCarRegistrationID, false)
            };
    })
};

var validators_releaseDateEmpty = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: boolEmptyFieldToQueryParamBilingual(param.releaseDateEmpty, Order_Search_Types$DvmAdminFrontendFr.Fields.usedCarFirstReleaseDate)
            };
    })
};

var validators_usedCarFirstReleaseDateEnd = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      if (param.releaseDateEmpty) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      var usedCarFirstReleaseDateStart = param.usedCarFirstReleaseDateStart;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.usedCarFirstReleaseDateEnd, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(usedCarFirstReleaseDateStart), Caml_option.some(date), Order_Search_Types$DvmAdminFrontendFr.Fields.usedCarFirstReleaseDate, "normal");
                  }));
    })
};

var validators_usedCarFirstReleaseDateStart = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      if (param.releaseDateEmpty) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      var usedCarFirstReleaseDateEnd = param.usedCarFirstReleaseDateEnd;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.usedCarFirstReleaseDateStart, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(date), Caml_option.some(usedCarFirstReleaseDateEnd), Order_Search_Types$DvmAdminFrontendFr.Fields.usedCarFirstReleaseDate, "normal");
                  }));
    })
};

var validators_registrationDateEmpty = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: boolEmptyFieldToQueryParamBilingual(param.registrationDateEmpty, Order_Search_Types$DvmAdminFrontendFr.Fields.usedCarLastRegistrationDate)
            };
    })
};

var validators_usedCarLastRegistrationDateEnd = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      if (param.registrationDateEmpty) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      var usedCarLastRegistrationDateStart = param.usedCarLastRegistrationDateStart;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.usedCarLastRegistrationDateEnd, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(usedCarLastRegistrationDateStart), Caml_option.some(date), Order_Search_Types$DvmAdminFrontendFr.Fields.usedCarLastRegistrationDate, "normal");
                  }));
    })
};

var validators_usedCarLastRegistrationDateStart = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      if (param.registrationDateEmpty) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      var usedCarLastRegistrationDateEnd = param.usedCarLastRegistrationDateEnd;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.usedCarLastRegistrationDateStart, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(date), Caml_option.some(usedCarLastRegistrationDateEnd), Order_Search_Types$DvmAdminFrontendFr.Fields.usedCarLastRegistrationDate, "normal");
                  }));
    })
};

var validators_policeNumberEmpty = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: boolEmptyFieldToQueryParamBilingual(param.policeNumberEmpty, Order_Search_Types$DvmAdminFrontendFr.Fields.usedCarPoliceNumber)
            };
    })
};

var validators_usedCarPoliceNumber = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.policeNumberEmpty ? undefined : Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(false, Order_Search_Types$DvmAdminFrontendFr.Fields.usedCarPoliceNumber, param.usedCarPoliceNumber, false)
            };
    })
};

var validators_vinEmpty = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: boolEmptyFieldToQueryParamBilingual(param.vinEmpty, Order_Search_Types$DvmAdminFrontendFr.Fields.vinUsedCar)
            };
    })
};

var validators_vinUsedCar = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.vinEmpty ? undefined : Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(false, Order_Search_Types$DvmAdminFrontendFr.Fields.vinUsedCar, param.vinUsedCar, false)
            };
    })
};

var validators_modelEmpty = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: boolEmptyFieldToQueryParamBilingual(param.modelEmpty, Order_Search_Types$DvmAdminFrontendFr.Fields.modelUsedCar)
            };
    })
};

var validators_modelUsedCar = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.modelEmpty ? undefined : Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(false, Order_Search_Types$DvmAdminFrontendFr.Fields.modelUsedCar, param.modelUsedCar, false)
            };
    })
};

var validators_brandEmpty = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: boolEmptyFieldToQueryParamBilingual(param.brandEmpty, Order_Search_Types$DvmAdminFrontendFr.Fields.brandUsedCar)
            };
    })
};

var validators_brandUsedCar = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.brandEmpty ? undefined : Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(false, Order_Search_Types$DvmAdminFrontendFr.Fields.brandUsedCar, param.brandUsedCar, false)
            };
    })
};

var validators = {
  registrationIDEmpty: validators_registrationIDEmpty,
  usedCarRegistrationID: validators_usedCarRegistrationID,
  releaseDateEmpty: validators_releaseDateEmpty,
  usedCarFirstReleaseDateEnd: validators_usedCarFirstReleaseDateEnd,
  usedCarFirstReleaseDateStart: validators_usedCarFirstReleaseDateStart,
  registrationDateEmpty: validators_registrationDateEmpty,
  usedCarLastRegistrationDateEnd: validators_usedCarLastRegistrationDateEnd,
  usedCarLastRegistrationDateStart: validators_usedCarLastRegistrationDateStart,
  policeNumberEmpty: validators_policeNumberEmpty,
  usedCarPoliceNumber: validators_usedCarPoliceNumber,
  vinEmpty: validators_vinEmpty,
  vinUsedCar: validators_vinUsedCar,
  modelEmpty: validators_modelEmpty,
  modelUsedCar: validators_modelUsedCar,
  brandEmpty: validators_brandEmpty,
  brandUsedCar: validators_brandUsedCar
};

function initialFieldsStatuses(_input) {
  return {
          registrationIDEmpty: /* Pristine */0,
          usedCarRegistrationID: /* Pristine */0,
          releaseDateEmpty: /* Pristine */0,
          usedCarFirstReleaseDateEnd: /* Pristine */0,
          usedCarFirstReleaseDateStart: /* Pristine */0,
          registrationDateEmpty: /* Pristine */0,
          usedCarLastRegistrationDateEnd: /* Pristine */0,
          usedCarLastRegistrationDateStart: /* Pristine */0,
          policeNumberEmpty: /* Pristine */0,
          usedCarPoliceNumber: /* Pristine */0,
          vinEmpty: /* Pristine */0,
          vinUsedCar: /* Pristine */0,
          modelEmpty: /* Pristine */0,
          modelUsedCar: /* Pristine */0,
          brandEmpty: /* Pristine */0,
          brandUsedCar: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.registrationIDEmpty;
  var match_0 = match ? match._0 : Curry._1(validators.registrationIDEmpty.validate, input);
  var match$1 = fieldsStatuses.usedCarRegistrationID;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.usedCarRegistrationID.validate, input);
  var match$2 = fieldsStatuses.releaseDateEmpty;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.releaseDateEmpty.validate, input);
  var match$3 = fieldsStatuses.usedCarFirstReleaseDateEnd;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.usedCarFirstReleaseDateEnd.validate, input);
  var match$4 = fieldsStatuses.usedCarFirstReleaseDateStart;
  var match_0$4 = match$4 ? match$4._0 : Curry._1(validators.usedCarFirstReleaseDateStart.validate, input);
  var match$5 = fieldsStatuses.registrationDateEmpty;
  var match_0$5 = match$5 ? match$5._0 : Curry._1(validators.registrationDateEmpty.validate, input);
  var match$6 = fieldsStatuses.usedCarLastRegistrationDateEnd;
  var match_0$6 = match$6 ? match$6._0 : Curry._1(validators.usedCarLastRegistrationDateEnd.validate, input);
  var match$7 = fieldsStatuses.usedCarLastRegistrationDateStart;
  var match_0$7 = match$7 ? match$7._0 : Curry._1(validators.usedCarLastRegistrationDateStart.validate, input);
  var match$8 = fieldsStatuses.policeNumberEmpty;
  var match_0$8 = match$8 ? match$8._0 : Curry._1(validators.policeNumberEmpty.validate, input);
  var match$9 = fieldsStatuses.usedCarPoliceNumber;
  var match_0$9 = match$9 ? match$9._0 : Curry._1(validators.usedCarPoliceNumber.validate, input);
  var match$10 = fieldsStatuses.vinEmpty;
  var match_0$10 = match$10 ? match$10._0 : Curry._1(validators.vinEmpty.validate, input);
  var match$11 = fieldsStatuses.vinUsedCar;
  var match_0$11 = match$11 ? match$11._0 : Curry._1(validators.vinUsedCar.validate, input);
  var match$12 = fieldsStatuses.modelEmpty;
  var match_0$12 = match$12 ? match$12._0 : Curry._1(validators.modelEmpty.validate, input);
  var match$13 = fieldsStatuses.modelUsedCar;
  var match_0$13 = match$13 ? match$13._0 : Curry._1(validators.modelUsedCar.validate, input);
  var match$14 = fieldsStatuses.brandEmpty;
  var match_0$14 = match$14 ? match$14._0 : Curry._1(validators.brandEmpty.validate, input);
  var match$15 = fieldsStatuses.brandUsedCar;
  var match_0$15 = match$15 ? match$15._0 : Curry._1(validators.brandUsedCar.validate, input);
  var registrationIDEmptyResult = match_0;
  var registrationIDEmptyResult$1;
  if (registrationIDEmptyResult.TAG === /* Ok */0) {
    var usedCarRegistrationIDResult = match_0$1;
    if (usedCarRegistrationIDResult.TAG === /* Ok */0) {
      var releaseDateEmptyResult = match_0$2;
      if (releaseDateEmptyResult.TAG === /* Ok */0) {
        var usedCarFirstReleaseDateEndResult = match_0$3;
        if (usedCarFirstReleaseDateEndResult.TAG === /* Ok */0) {
          var usedCarFirstReleaseDateStartResult = match_0$4;
          if (usedCarFirstReleaseDateStartResult.TAG === /* Ok */0) {
            var registrationDateEmptyResult = match_0$5;
            if (registrationDateEmptyResult.TAG === /* Ok */0) {
              var usedCarLastRegistrationDateEndResult = match_0$6;
              if (usedCarLastRegistrationDateEndResult.TAG === /* Ok */0) {
                var usedCarLastRegistrationDateStartResult = match_0$7;
                if (usedCarLastRegistrationDateStartResult.TAG === /* Ok */0) {
                  var policeNumberEmptyResult = match_0$8;
                  if (policeNumberEmptyResult.TAG === /* Ok */0) {
                    var usedCarPoliceNumberResult = match_0$9;
                    if (usedCarPoliceNumberResult.TAG === /* Ok */0) {
                      var vinEmptyResult = match_0$10;
                      if (vinEmptyResult.TAG === /* Ok */0) {
                        var vinUsedCarResult = match_0$11;
                        if (vinUsedCarResult.TAG === /* Ok */0) {
                          var modelEmptyResult = match_0$12;
                          if (modelEmptyResult.TAG === /* Ok */0) {
                            var modelUsedCarResult = match_0$13;
                            if (modelUsedCarResult.TAG === /* Ok */0) {
                              var brandEmptyResult = match_0$14;
                              if (brandEmptyResult.TAG === /* Ok */0) {
                                var brandUsedCarResult = match_0$15;
                                if (brandUsedCarResult.TAG === /* Ok */0) {
                                  return {
                                          TAG: /* Valid */0,
                                          output: {
                                            brandUsedCar: brandUsedCarResult._0,
                                            brandEmpty: brandEmptyResult._0,
                                            modelUsedCar: modelUsedCarResult._0,
                                            modelEmpty: modelEmptyResult._0,
                                            vinUsedCar: vinUsedCarResult._0,
                                            vinEmpty: vinEmptyResult._0,
                                            usedCarPoliceNumber: usedCarPoliceNumberResult._0,
                                            policeNumberEmpty: policeNumberEmptyResult._0,
                                            usedCarLastRegistrationDateStart: usedCarLastRegistrationDateStartResult._0,
                                            usedCarLastRegistrationDateEnd: usedCarLastRegistrationDateEndResult._0,
                                            registrationDateEmpty: registrationDateEmptyResult._0,
                                            usedCarFirstReleaseDateStart: usedCarFirstReleaseDateStartResult._0,
                                            usedCarFirstReleaseDateEnd: usedCarFirstReleaseDateEndResult._0,
                                            releaseDateEmpty: releaseDateEmptyResult._0,
                                            usedCarRegistrationID: usedCarRegistrationIDResult._0,
                                            registrationIDEmpty: registrationIDEmptyResult._0
                                          },
                                          fieldsStatuses: {
                                            registrationIDEmpty: /* Dirty */{
                                              _0: registrationIDEmptyResult,
                                              _1: /* Shown */0
                                            },
                                            usedCarRegistrationID: /* Dirty */{
                                              _0: usedCarRegistrationIDResult,
                                              _1: /* Shown */0
                                            },
                                            releaseDateEmpty: /* Dirty */{
                                              _0: releaseDateEmptyResult,
                                              _1: /* Shown */0
                                            },
                                            usedCarFirstReleaseDateEnd: /* Dirty */{
                                              _0: usedCarFirstReleaseDateEndResult,
                                              _1: /* Shown */0
                                            },
                                            usedCarFirstReleaseDateStart: /* Dirty */{
                                              _0: usedCarFirstReleaseDateStartResult,
                                              _1: /* Shown */0
                                            },
                                            registrationDateEmpty: /* Dirty */{
                                              _0: registrationDateEmptyResult,
                                              _1: /* Shown */0
                                            },
                                            usedCarLastRegistrationDateEnd: /* Dirty */{
                                              _0: usedCarLastRegistrationDateEndResult,
                                              _1: /* Shown */0
                                            },
                                            usedCarLastRegistrationDateStart: /* Dirty */{
                                              _0: usedCarLastRegistrationDateStartResult,
                                              _1: /* Shown */0
                                            },
                                            policeNumberEmpty: /* Dirty */{
                                              _0: policeNumberEmptyResult,
                                              _1: /* Shown */0
                                            },
                                            usedCarPoliceNumber: /* Dirty */{
                                              _0: usedCarPoliceNumberResult,
                                              _1: /* Shown */0
                                            },
                                            vinEmpty: /* Dirty */{
                                              _0: vinEmptyResult,
                                              _1: /* Shown */0
                                            },
                                            vinUsedCar: /* Dirty */{
                                              _0: vinUsedCarResult,
                                              _1: /* Shown */0
                                            },
                                            modelEmpty: /* Dirty */{
                                              _0: modelEmptyResult,
                                              _1: /* Shown */0
                                            },
                                            modelUsedCar: /* Dirty */{
                                              _0: modelUsedCarResult,
                                              _1: /* Shown */0
                                            },
                                            brandEmpty: /* Dirty */{
                                              _0: brandEmptyResult,
                                              _1: /* Shown */0
                                            },
                                            brandUsedCar: /* Dirty */{
                                              _0: brandUsedCarResult,
                                              _1: /* Shown */0
                                            }
                                          },
                                          collectionsStatuses: undefined
                                        };
                                }
                                registrationIDEmptyResult$1 = registrationIDEmptyResult;
                              } else {
                                registrationIDEmptyResult$1 = registrationIDEmptyResult;
                              }
                            } else {
                              registrationIDEmptyResult$1 = registrationIDEmptyResult;
                            }
                          } else {
                            registrationIDEmptyResult$1 = registrationIDEmptyResult;
                          }
                        } else {
                          registrationIDEmptyResult$1 = registrationIDEmptyResult;
                        }
                      } else {
                        registrationIDEmptyResult$1 = registrationIDEmptyResult;
                      }
                    } else {
                      registrationIDEmptyResult$1 = registrationIDEmptyResult;
                    }
                  } else {
                    registrationIDEmptyResult$1 = registrationIDEmptyResult;
                  }
                } else {
                  registrationIDEmptyResult$1 = registrationIDEmptyResult;
                }
              } else {
                registrationIDEmptyResult$1 = registrationIDEmptyResult;
              }
            } else {
              registrationIDEmptyResult$1 = registrationIDEmptyResult;
            }
          } else {
            registrationIDEmptyResult$1 = registrationIDEmptyResult;
          }
        } else {
          registrationIDEmptyResult$1 = registrationIDEmptyResult;
        }
      } else {
        registrationIDEmptyResult$1 = registrationIDEmptyResult;
      }
    } else {
      registrationIDEmptyResult$1 = registrationIDEmptyResult;
    }
  } else {
    registrationIDEmptyResult$1 = registrationIDEmptyResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            registrationIDEmpty: /* Dirty */{
              _0: registrationIDEmptyResult$1,
              _1: /* Shown */0
            },
            usedCarRegistrationID: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            releaseDateEmpty: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            usedCarFirstReleaseDateEnd: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            usedCarFirstReleaseDateStart: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            registrationDateEmpty: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            usedCarLastRegistrationDateEnd: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            usedCarLastRegistrationDateStart: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            },
            policeNumberEmpty: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            },
            usedCarPoliceNumber: /* Dirty */{
              _0: match_0$9,
              _1: /* Shown */0
            },
            vinEmpty: /* Dirty */{
              _0: match_0$10,
              _1: /* Shown */0
            },
            vinUsedCar: /* Dirty */{
              _0: match_0$11,
              _1: /* Shown */0
            },
            modelEmpty: /* Dirty */{
              _0: match_0$12,
              _1: /* Shown */0
            },
            modelUsedCar: /* Dirty */{
              _0: match_0$13,
              _1: /* Shown */0
            },
            brandEmpty: /* Dirty */{
              _0: match_0$14,
              _1: /* Shown */0
            },
            brandUsedCar: /* Dirty */{
              _0: match_0$15,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurRegistrationIDEmptyField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.registrationIDEmpty, validators_registrationIDEmpty, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  registrationIDEmpty: status,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  releaseDateEmpty: init.releaseDateEmpty,
                                  usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                  usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                  registrationDateEmpty: init.registrationDateEmpty,
                                  usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                  usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                  policeNumberEmpty: init.policeNumberEmpty,
                                  usedCarPoliceNumber: init.usedCarPoliceNumber,
                                  vinEmpty: init.vinEmpty,
                                  vinUsedCar: init.vinUsedCar,
                                  modelEmpty: init.modelEmpty,
                                  modelUsedCar: init.modelUsedCar,
                                  brandEmpty: init.brandEmpty,
                                  brandUsedCar: init.brandUsedCar
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurUsedCarRegistrationIDField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.usedCarRegistrationID, validators_usedCarRegistrationID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  registrationIDEmpty: init.registrationIDEmpty,
                                  usedCarRegistrationID: status,
                                  releaseDateEmpty: init.releaseDateEmpty,
                                  usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                  usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                  registrationDateEmpty: init.registrationDateEmpty,
                                  usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                  usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                  policeNumberEmpty: init.policeNumberEmpty,
                                  usedCarPoliceNumber: init.usedCarPoliceNumber,
                                  vinEmpty: init.vinEmpty,
                                  vinUsedCar: init.vinUsedCar,
                                  modelEmpty: init.modelEmpty,
                                  modelUsedCar: init.modelUsedCar,
                                  brandEmpty: init.brandEmpty,
                                  brandUsedCar: init.brandUsedCar
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurReleaseDateEmptyField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.releaseDateEmpty, validators_releaseDateEmpty, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  registrationIDEmpty: init.registrationIDEmpty,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  releaseDateEmpty: status,
                                  usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                  usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                  registrationDateEmpty: init.registrationDateEmpty,
                                  usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                  usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                  policeNumberEmpty: init.policeNumberEmpty,
                                  usedCarPoliceNumber: init.usedCarPoliceNumber,
                                  vinEmpty: init.vinEmpty,
                                  vinUsedCar: init.vinUsedCar,
                                  modelEmpty: init.modelEmpty,
                                  modelUsedCar: init.modelUsedCar,
                                  brandEmpty: init.brandEmpty,
                                  brandUsedCar: init.brandUsedCar
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurUsedCarFirstReleaseDateEndField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.usedCarFirstReleaseDateEnd, validators_usedCarFirstReleaseDateEnd, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  registrationIDEmpty: init.registrationIDEmpty,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  releaseDateEmpty: init.releaseDateEmpty,
                                  usedCarFirstReleaseDateEnd: status,
                                  usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                  registrationDateEmpty: init.registrationDateEmpty,
                                  usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                  usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                  policeNumberEmpty: init.policeNumberEmpty,
                                  usedCarPoliceNumber: init.usedCarPoliceNumber,
                                  vinEmpty: init.vinEmpty,
                                  vinUsedCar: init.vinUsedCar,
                                  modelEmpty: init.modelEmpty,
                                  modelUsedCar: init.modelUsedCar,
                                  brandEmpty: init.brandEmpty,
                                  brandUsedCar: init.brandUsedCar
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurUsedCarFirstReleaseDateStartField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.usedCarFirstReleaseDateStart, validators_usedCarFirstReleaseDateStart, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  registrationIDEmpty: init.registrationIDEmpty,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  releaseDateEmpty: init.releaseDateEmpty,
                                  usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                  usedCarFirstReleaseDateStart: status,
                                  registrationDateEmpty: init.registrationDateEmpty,
                                  usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                  usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                  policeNumberEmpty: init.policeNumberEmpty,
                                  usedCarPoliceNumber: init.usedCarPoliceNumber,
                                  vinEmpty: init.vinEmpty,
                                  vinUsedCar: init.vinUsedCar,
                                  modelEmpty: init.modelEmpty,
                                  modelUsedCar: init.modelUsedCar,
                                  brandEmpty: init.brandEmpty,
                                  brandUsedCar: init.brandUsedCar
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRegistrationDateEmptyField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.registrationDateEmpty, validators_registrationDateEmpty, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  registrationIDEmpty: init.registrationIDEmpty,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  releaseDateEmpty: init.releaseDateEmpty,
                                  usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                  usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                  registrationDateEmpty: status,
                                  usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                  usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                  policeNumberEmpty: init.policeNumberEmpty,
                                  usedCarPoliceNumber: init.usedCarPoliceNumber,
                                  vinEmpty: init.vinEmpty,
                                  vinUsedCar: init.vinUsedCar,
                                  modelEmpty: init.modelEmpty,
                                  modelUsedCar: init.modelUsedCar,
                                  brandEmpty: init.brandEmpty,
                                  brandUsedCar: init.brandUsedCar
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurUsedCarLastRegistrationDateEndField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.usedCarLastRegistrationDateEnd, validators_usedCarLastRegistrationDateEnd, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  registrationIDEmpty: init.registrationIDEmpty,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  releaseDateEmpty: init.releaseDateEmpty,
                                  usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                  usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                  registrationDateEmpty: init.registrationDateEmpty,
                                  usedCarLastRegistrationDateEnd: status,
                                  usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                  policeNumberEmpty: init.policeNumberEmpty,
                                  usedCarPoliceNumber: init.usedCarPoliceNumber,
                                  vinEmpty: init.vinEmpty,
                                  vinUsedCar: init.vinUsedCar,
                                  modelEmpty: init.modelEmpty,
                                  modelUsedCar: init.modelUsedCar,
                                  brandEmpty: init.brandEmpty,
                                  brandUsedCar: init.brandUsedCar
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurUsedCarLastRegistrationDateStartField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.usedCarLastRegistrationDateStart, validators_usedCarLastRegistrationDateStart, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  registrationIDEmpty: init.registrationIDEmpty,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  releaseDateEmpty: init.releaseDateEmpty,
                                  usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                  usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                  registrationDateEmpty: init.registrationDateEmpty,
                                  usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                  usedCarLastRegistrationDateStart: status,
                                  policeNumberEmpty: init.policeNumberEmpty,
                                  usedCarPoliceNumber: init.usedCarPoliceNumber,
                                  vinEmpty: init.vinEmpty,
                                  vinUsedCar: init.vinUsedCar,
                                  modelEmpty: init.modelEmpty,
                                  modelUsedCar: init.modelUsedCar,
                                  brandEmpty: init.brandEmpty,
                                  brandUsedCar: init.brandUsedCar
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPoliceNumberEmptyField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.policeNumberEmpty, validators_policeNumberEmpty, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  registrationIDEmpty: init.registrationIDEmpty,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  releaseDateEmpty: init.releaseDateEmpty,
                                  usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                  usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                  registrationDateEmpty: init.registrationDateEmpty,
                                  usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                  usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                  policeNumberEmpty: status,
                                  usedCarPoliceNumber: init.usedCarPoliceNumber,
                                  vinEmpty: init.vinEmpty,
                                  vinUsedCar: init.vinUsedCar,
                                  modelEmpty: init.modelEmpty,
                                  modelUsedCar: init.modelUsedCar,
                                  brandEmpty: init.brandEmpty,
                                  brandUsedCar: init.brandUsedCar
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurUsedCarPoliceNumberField */9 :
                  var result$9 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.usedCarPoliceNumber, validators_usedCarPoliceNumber, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  registrationIDEmpty: init.registrationIDEmpty,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  releaseDateEmpty: init.releaseDateEmpty,
                                  usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                  usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                  registrationDateEmpty: init.registrationDateEmpty,
                                  usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                  usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                  policeNumberEmpty: init.policeNumberEmpty,
                                  usedCarPoliceNumber: status,
                                  vinEmpty: init.vinEmpty,
                                  vinUsedCar: init.vinUsedCar,
                                  modelEmpty: init.modelEmpty,
                                  modelUsedCar: init.modelUsedCar,
                                  brandEmpty: init.brandEmpty,
                                  brandUsedCar: init.brandUsedCar
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVinEmptyField */10 :
                  var result$10 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.vinEmpty, validators_vinEmpty, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  registrationIDEmpty: init.registrationIDEmpty,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  releaseDateEmpty: init.releaseDateEmpty,
                                  usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                  usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                  registrationDateEmpty: init.registrationDateEmpty,
                                  usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                  usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                  policeNumberEmpty: init.policeNumberEmpty,
                                  usedCarPoliceNumber: init.usedCarPoliceNumber,
                                  vinEmpty: status,
                                  vinUsedCar: init.vinUsedCar,
                                  modelEmpty: init.modelEmpty,
                                  modelUsedCar: init.modelUsedCar,
                                  brandEmpty: init.brandEmpty,
                                  brandUsedCar: init.brandUsedCar
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVinUsedCarField */11 :
                  var result$11 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.vinUsedCar, validators_vinUsedCar, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  registrationIDEmpty: init.registrationIDEmpty,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  releaseDateEmpty: init.releaseDateEmpty,
                                  usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                  usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                  registrationDateEmpty: init.registrationDateEmpty,
                                  usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                  usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                  policeNumberEmpty: init.policeNumberEmpty,
                                  usedCarPoliceNumber: init.usedCarPoliceNumber,
                                  vinEmpty: init.vinEmpty,
                                  vinUsedCar: status,
                                  modelEmpty: init.modelEmpty,
                                  modelUsedCar: init.modelUsedCar,
                                  brandEmpty: init.brandEmpty,
                                  brandUsedCar: init.brandUsedCar
                                };
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurModelEmptyField */12 :
                  var result$12 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.modelEmpty, validators_modelEmpty, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  registrationIDEmpty: init.registrationIDEmpty,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  releaseDateEmpty: init.releaseDateEmpty,
                                  usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                  usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                  registrationDateEmpty: init.registrationDateEmpty,
                                  usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                  usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                  policeNumberEmpty: init.policeNumberEmpty,
                                  usedCarPoliceNumber: init.usedCarPoliceNumber,
                                  vinEmpty: init.vinEmpty,
                                  vinUsedCar: init.vinUsedCar,
                                  modelEmpty: status,
                                  modelUsedCar: init.modelUsedCar,
                                  brandEmpty: init.brandEmpty,
                                  brandUsedCar: init.brandUsedCar
                                };
                        }));
                  if (result$12 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$12,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurModelUsedCarField */13 :
                  var result$13 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.modelUsedCar, validators_modelUsedCar, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  registrationIDEmpty: init.registrationIDEmpty,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  releaseDateEmpty: init.releaseDateEmpty,
                                  usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                  usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                  registrationDateEmpty: init.registrationDateEmpty,
                                  usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                  usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                  policeNumberEmpty: init.policeNumberEmpty,
                                  usedCarPoliceNumber: init.usedCarPoliceNumber,
                                  vinEmpty: init.vinEmpty,
                                  vinUsedCar: init.vinUsedCar,
                                  modelEmpty: init.modelEmpty,
                                  modelUsedCar: status,
                                  brandEmpty: init.brandEmpty,
                                  brandUsedCar: init.brandUsedCar
                                };
                        }));
                  if (result$13 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$13,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBrandEmptyField */14 :
                  var result$14 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.brandEmpty, validators_brandEmpty, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  registrationIDEmpty: init.registrationIDEmpty,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  releaseDateEmpty: init.releaseDateEmpty,
                                  usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                  usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                  registrationDateEmpty: init.registrationDateEmpty,
                                  usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                  usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                  policeNumberEmpty: init.policeNumberEmpty,
                                  usedCarPoliceNumber: init.usedCarPoliceNumber,
                                  vinEmpty: init.vinEmpty,
                                  vinUsedCar: init.vinUsedCar,
                                  modelEmpty: init.modelEmpty,
                                  modelUsedCar: init.modelUsedCar,
                                  brandEmpty: status,
                                  brandUsedCar: init.brandUsedCar
                                };
                        }));
                  if (result$14 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$14,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBrandUsedCarField */15 :
                  var result$15 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.brandUsedCar, validators_brandUsedCar, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  registrationIDEmpty: init.registrationIDEmpty,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  releaseDateEmpty: init.releaseDateEmpty,
                                  usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                  usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                  registrationDateEmpty: init.registrationDateEmpty,
                                  usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                  usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                  policeNumberEmpty: init.policeNumberEmpty,
                                  usedCarPoliceNumber: init.usedCarPoliceNumber,
                                  vinEmpty: init.vinEmpty,
                                  vinUsedCar: init.vinUsedCar,
                                  modelEmpty: init.modelEmpty,
                                  modelUsedCar: init.modelUsedCar,
                                  brandEmpty: init.brandEmpty,
                                  brandUsedCar: status
                                };
                        }));
                  if (result$15 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$15,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */16 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */16,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */17,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */19);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */18);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */17 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */18 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */19 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateRegistrationIDEmptyField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.registrationIDEmpty, state.submissionStatus, validators_registrationIDEmpty, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            registrationIDEmpty: status,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            releaseDateEmpty: init.releaseDateEmpty,
                                            usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                            usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                            registrationDateEmpty: init.registrationDateEmpty,
                                            usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                            usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                            policeNumberEmpty: init.policeNumberEmpty,
                                            usedCarPoliceNumber: init.usedCarPoliceNumber,
                                            vinEmpty: init.vinEmpty,
                                            vinUsedCar: init.vinUsedCar,
                                            modelEmpty: init.modelEmpty,
                                            modelUsedCar: init.modelUsedCar,
                                            brandEmpty: init.brandEmpty,
                                            brandUsedCar: init.brandUsedCar
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateUsedCarRegistrationIDField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.usedCarRegistrationID, state.submissionStatus, validators_usedCarRegistrationID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            registrationIDEmpty: init.registrationIDEmpty,
                                            usedCarRegistrationID: status,
                                            releaseDateEmpty: init.releaseDateEmpty,
                                            usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                            usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                            registrationDateEmpty: init.registrationDateEmpty,
                                            usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                            usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                            policeNumberEmpty: init.policeNumberEmpty,
                                            usedCarPoliceNumber: init.usedCarPoliceNumber,
                                            vinEmpty: init.vinEmpty,
                                            vinUsedCar: init.vinUsedCar,
                                            modelEmpty: init.modelEmpty,
                                            modelUsedCar: init.modelUsedCar,
                                            brandEmpty: init.brandEmpty,
                                            brandUsedCar: init.brandUsedCar
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateReleaseDateEmptyField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.releaseDateEmpty, state.submissionStatus, validators_releaseDateEmpty, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            registrationIDEmpty: init.registrationIDEmpty,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            releaseDateEmpty: status,
                                            usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                            usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                            registrationDateEmpty: init.registrationDateEmpty,
                                            usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                            usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                            policeNumberEmpty: init.policeNumberEmpty,
                                            usedCarPoliceNumber: init.usedCarPoliceNumber,
                                            vinEmpty: init.vinEmpty,
                                            vinUsedCar: init.vinUsedCar,
                                            modelEmpty: init.modelEmpty,
                                            modelUsedCar: init.modelUsedCar,
                                            brandEmpty: init.brandEmpty,
                                            brandUsedCar: init.brandUsedCar
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateUsedCarFirstReleaseDateEndField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$16 = Formality.validateDependentFieldOnChange(nextInput$3, nextFieldsStatuses.contents.usedCarFirstReleaseDateStart, validators_usedCarFirstReleaseDateStart, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  registrationIDEmpty: init.registrationIDEmpty,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  releaseDateEmpty: init.releaseDateEmpty,
                                  usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                  usedCarFirstReleaseDateStart: status,
                                  registrationDateEmpty: init.registrationDateEmpty,
                                  usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                  usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                  policeNumberEmpty: init.policeNumberEmpty,
                                  usedCarPoliceNumber: init.usedCarPoliceNumber,
                                  vinEmpty: init.vinEmpty,
                                  vinUsedCar: init.vinUsedCar,
                                  modelEmpty: init.modelEmpty,
                                  modelUsedCar: init.modelUsedCar,
                                  brandEmpty: init.brandEmpty,
                                  brandUsedCar: init.brandUsedCar
                                };
                        }));
                  if (result$16 !== undefined) {
                    nextFieldsStatuses.contents = result$16;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, nextFieldsStatuses.contents.usedCarFirstReleaseDateEnd, state.submissionStatus, validators_usedCarFirstReleaseDateEnd, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            registrationIDEmpty: init.registrationIDEmpty,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            releaseDateEmpty: init.releaseDateEmpty,
                                            usedCarFirstReleaseDateEnd: status,
                                            usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                            registrationDateEmpty: init.registrationDateEmpty,
                                            usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                            usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                            policeNumberEmpty: init.policeNumberEmpty,
                                            usedCarPoliceNumber: init.usedCarPoliceNumber,
                                            vinEmpty: init.vinEmpty,
                                            vinUsedCar: init.vinUsedCar,
                                            modelEmpty: init.modelEmpty,
                                            modelUsedCar: init.modelUsedCar,
                                            brandEmpty: init.brandEmpty,
                                            brandUsedCar: init.brandUsedCar
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateUsedCarFirstReleaseDateStartField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$17 = Formality.validateDependentFieldOnChange(nextInput$4, nextFieldsStatuses$1.contents.usedCarFirstReleaseDateEnd, validators_usedCarFirstReleaseDateEnd, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  registrationIDEmpty: init.registrationIDEmpty,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  releaseDateEmpty: init.releaseDateEmpty,
                                  usedCarFirstReleaseDateEnd: status,
                                  usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                  registrationDateEmpty: init.registrationDateEmpty,
                                  usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                  usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                  policeNumberEmpty: init.policeNumberEmpty,
                                  usedCarPoliceNumber: init.usedCarPoliceNumber,
                                  vinEmpty: init.vinEmpty,
                                  vinUsedCar: init.vinUsedCar,
                                  modelEmpty: init.modelEmpty,
                                  modelUsedCar: init.modelUsedCar,
                                  brandEmpty: init.brandEmpty,
                                  brandUsedCar: init.brandUsedCar
                                };
                        }));
                  if (result$17 !== undefined) {
                    nextFieldsStatuses$1.contents = result$17;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, nextFieldsStatuses$1.contents.usedCarFirstReleaseDateStart, state.submissionStatus, validators_usedCarFirstReleaseDateStart, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            registrationIDEmpty: init.registrationIDEmpty,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            releaseDateEmpty: init.releaseDateEmpty,
                                            usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                            usedCarFirstReleaseDateStart: status,
                                            registrationDateEmpty: init.registrationDateEmpty,
                                            usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                            usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                            policeNumberEmpty: init.policeNumberEmpty,
                                            usedCarPoliceNumber: init.usedCarPoliceNumber,
                                            vinEmpty: init.vinEmpty,
                                            vinUsedCar: init.vinUsedCar,
                                            modelEmpty: init.modelEmpty,
                                            modelUsedCar: init.modelUsedCar,
                                            brandEmpty: init.brandEmpty,
                                            brandUsedCar: init.brandUsedCar
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRegistrationDateEmptyField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.registrationDateEmpty, state.submissionStatus, validators_registrationDateEmpty, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            registrationIDEmpty: init.registrationIDEmpty,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            releaseDateEmpty: init.releaseDateEmpty,
                                            usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                            usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                            registrationDateEmpty: status,
                                            usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                            usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                            policeNumberEmpty: init.policeNumberEmpty,
                                            usedCarPoliceNumber: init.usedCarPoliceNumber,
                                            vinEmpty: init.vinEmpty,
                                            vinUsedCar: init.vinUsedCar,
                                            modelEmpty: init.modelEmpty,
                                            modelUsedCar: init.modelUsedCar,
                                            brandEmpty: init.brandEmpty,
                                            brandUsedCar: init.brandUsedCar
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateUsedCarLastRegistrationDateEndField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$2 = {
                    contents: state.fieldsStatuses
                  };
                  var result$18 = Formality.validateDependentFieldOnChange(nextInput$6, nextFieldsStatuses$2.contents.usedCarLastRegistrationDateStart, validators_usedCarLastRegistrationDateStart, (function (status) {
                          var init = nextFieldsStatuses$2.contents;
                          return {
                                  registrationIDEmpty: init.registrationIDEmpty,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  releaseDateEmpty: init.releaseDateEmpty,
                                  usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                  usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                  registrationDateEmpty: init.registrationDateEmpty,
                                  usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                  usedCarLastRegistrationDateStart: status,
                                  policeNumberEmpty: init.policeNumberEmpty,
                                  usedCarPoliceNumber: init.usedCarPoliceNumber,
                                  vinEmpty: init.vinEmpty,
                                  vinUsedCar: init.vinUsedCar,
                                  modelEmpty: init.modelEmpty,
                                  modelUsedCar: init.modelUsedCar,
                                  brandEmpty: init.brandEmpty,
                                  brandUsedCar: init.brandUsedCar
                                };
                        }));
                  if (result$18 !== undefined) {
                    nextFieldsStatuses$2.contents = result$18;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, nextFieldsStatuses$2.contents.usedCarLastRegistrationDateEnd, state.submissionStatus, validators_usedCarLastRegistrationDateEnd, (function (status) {
                                    var init = nextFieldsStatuses$2.contents;
                                    return {
                                            registrationIDEmpty: init.registrationIDEmpty,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            releaseDateEmpty: init.releaseDateEmpty,
                                            usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                            usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                            registrationDateEmpty: init.registrationDateEmpty,
                                            usedCarLastRegistrationDateEnd: status,
                                            usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                            policeNumberEmpty: init.policeNumberEmpty,
                                            usedCarPoliceNumber: init.usedCarPoliceNumber,
                                            vinEmpty: init.vinEmpty,
                                            vinUsedCar: init.vinUsedCar,
                                            modelEmpty: init.modelEmpty,
                                            modelUsedCar: init.modelUsedCar,
                                            brandEmpty: init.brandEmpty,
                                            brandUsedCar: init.brandUsedCar
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateUsedCarLastRegistrationDateStartField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$3 = {
                    contents: state.fieldsStatuses
                  };
                  var result$19 = Formality.validateDependentFieldOnChange(nextInput$7, nextFieldsStatuses$3.contents.usedCarLastRegistrationDateEnd, validators_usedCarLastRegistrationDateEnd, (function (status) {
                          var init = nextFieldsStatuses$3.contents;
                          return {
                                  registrationIDEmpty: init.registrationIDEmpty,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  releaseDateEmpty: init.releaseDateEmpty,
                                  usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                  usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                  registrationDateEmpty: init.registrationDateEmpty,
                                  usedCarLastRegistrationDateEnd: status,
                                  usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                  policeNumberEmpty: init.policeNumberEmpty,
                                  usedCarPoliceNumber: init.usedCarPoliceNumber,
                                  vinEmpty: init.vinEmpty,
                                  vinUsedCar: init.vinUsedCar,
                                  modelEmpty: init.modelEmpty,
                                  modelUsedCar: init.modelUsedCar,
                                  brandEmpty: init.brandEmpty,
                                  brandUsedCar: init.brandUsedCar
                                };
                        }));
                  if (result$19 !== undefined) {
                    nextFieldsStatuses$3.contents = result$19;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$7, nextFieldsStatuses$3.contents.usedCarLastRegistrationDateStart, state.submissionStatus, validators_usedCarLastRegistrationDateStart, (function (status) {
                                    var init = nextFieldsStatuses$3.contents;
                                    return {
                                            registrationIDEmpty: init.registrationIDEmpty,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            releaseDateEmpty: init.releaseDateEmpty,
                                            usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                            usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                            registrationDateEmpty: init.registrationDateEmpty,
                                            usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                            usedCarLastRegistrationDateStart: status,
                                            policeNumberEmpty: init.policeNumberEmpty,
                                            usedCarPoliceNumber: init.usedCarPoliceNumber,
                                            vinEmpty: init.vinEmpty,
                                            vinUsedCar: init.vinUsedCar,
                                            modelEmpty: init.modelEmpty,
                                            modelUsedCar: init.modelUsedCar,
                                            brandEmpty: init.brandEmpty,
                                            brandUsedCar: init.brandUsedCar
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePoliceNumberEmptyField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$8, state.fieldsStatuses.policeNumberEmpty, state.submissionStatus, validators_policeNumberEmpty, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            registrationIDEmpty: init.registrationIDEmpty,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            releaseDateEmpty: init.releaseDateEmpty,
                                            usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                            usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                            registrationDateEmpty: init.registrationDateEmpty,
                                            usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                            usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                            policeNumberEmpty: status,
                                            usedCarPoliceNumber: init.usedCarPoliceNumber,
                                            vinEmpty: init.vinEmpty,
                                            vinUsedCar: init.vinUsedCar,
                                            modelEmpty: init.modelEmpty,
                                            modelUsedCar: init.modelUsedCar,
                                            brandEmpty: init.brandEmpty,
                                            brandUsedCar: init.brandUsedCar
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateUsedCarPoliceNumberField */9 :
                  var nextInput$9 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$9, state.fieldsStatuses.usedCarPoliceNumber, state.submissionStatus, validators_usedCarPoliceNumber, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            registrationIDEmpty: init.registrationIDEmpty,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            releaseDateEmpty: init.releaseDateEmpty,
                                            usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                            usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                            registrationDateEmpty: init.registrationDateEmpty,
                                            usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                            usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                            policeNumberEmpty: init.policeNumberEmpty,
                                            usedCarPoliceNumber: status,
                                            vinEmpty: init.vinEmpty,
                                            vinUsedCar: init.vinUsedCar,
                                            modelEmpty: init.modelEmpty,
                                            modelUsedCar: init.modelUsedCar,
                                            brandEmpty: init.brandEmpty,
                                            brandUsedCar: init.brandUsedCar
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVinEmptyField */10 :
                  var nextInput$10 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$10, state.fieldsStatuses.vinEmpty, state.submissionStatus, validators_vinEmpty, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            registrationIDEmpty: init.registrationIDEmpty,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            releaseDateEmpty: init.releaseDateEmpty,
                                            usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                            usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                            registrationDateEmpty: init.registrationDateEmpty,
                                            usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                            usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                            policeNumberEmpty: init.policeNumberEmpty,
                                            usedCarPoliceNumber: init.usedCarPoliceNumber,
                                            vinEmpty: status,
                                            vinUsedCar: init.vinUsedCar,
                                            modelEmpty: init.modelEmpty,
                                            modelUsedCar: init.modelUsedCar,
                                            brandEmpty: init.brandEmpty,
                                            brandUsedCar: init.brandUsedCar
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVinUsedCarField */11 :
                  var nextInput$11 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$11, state.fieldsStatuses.vinUsedCar, state.submissionStatus, validators_vinUsedCar, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            registrationIDEmpty: init.registrationIDEmpty,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            releaseDateEmpty: init.releaseDateEmpty,
                                            usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                            usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                            registrationDateEmpty: init.registrationDateEmpty,
                                            usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                            usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                            policeNumberEmpty: init.policeNumberEmpty,
                                            usedCarPoliceNumber: init.usedCarPoliceNumber,
                                            vinEmpty: init.vinEmpty,
                                            vinUsedCar: status,
                                            modelEmpty: init.modelEmpty,
                                            modelUsedCar: init.modelUsedCar,
                                            brandEmpty: init.brandEmpty,
                                            brandUsedCar: init.brandUsedCar
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateModelEmptyField */12 :
                  var nextInput$12 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$12, state.fieldsStatuses.modelEmpty, state.submissionStatus, validators_modelEmpty, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            registrationIDEmpty: init.registrationIDEmpty,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            releaseDateEmpty: init.releaseDateEmpty,
                                            usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                            usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                            registrationDateEmpty: init.registrationDateEmpty,
                                            usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                            usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                            policeNumberEmpty: init.policeNumberEmpty,
                                            usedCarPoliceNumber: init.usedCarPoliceNumber,
                                            vinEmpty: init.vinEmpty,
                                            vinUsedCar: init.vinUsedCar,
                                            modelEmpty: status,
                                            modelUsedCar: init.modelUsedCar,
                                            brandEmpty: init.brandEmpty,
                                            brandUsedCar: init.brandUsedCar
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateModelUsedCarField */13 :
                  var nextInput$13 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$13,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$13, state.fieldsStatuses.modelUsedCar, state.submissionStatus, validators_modelUsedCar, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            registrationIDEmpty: init.registrationIDEmpty,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            releaseDateEmpty: init.releaseDateEmpty,
                                            usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                            usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                            registrationDateEmpty: init.registrationDateEmpty,
                                            usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                            usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                            policeNumberEmpty: init.policeNumberEmpty,
                                            usedCarPoliceNumber: init.usedCarPoliceNumber,
                                            vinEmpty: init.vinEmpty,
                                            vinUsedCar: init.vinUsedCar,
                                            modelEmpty: init.modelEmpty,
                                            modelUsedCar: status,
                                            brandEmpty: init.brandEmpty,
                                            brandUsedCar: init.brandUsedCar
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBrandEmptyField */14 :
                  var nextInput$14 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$14,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$14, state.fieldsStatuses.brandEmpty, state.submissionStatus, validators_brandEmpty, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            registrationIDEmpty: init.registrationIDEmpty,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            releaseDateEmpty: init.releaseDateEmpty,
                                            usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                            usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                            registrationDateEmpty: init.registrationDateEmpty,
                                            usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                            usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                            policeNumberEmpty: init.policeNumberEmpty,
                                            usedCarPoliceNumber: init.usedCarPoliceNumber,
                                            vinEmpty: init.vinEmpty,
                                            vinUsedCar: init.vinUsedCar,
                                            modelEmpty: init.modelEmpty,
                                            modelUsedCar: init.modelUsedCar,
                                            brandEmpty: status,
                                            brandUsedCar: init.brandUsedCar
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBrandUsedCarField */15 :
                  var nextInput$15 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$15,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$15, state.fieldsStatuses.brandUsedCar, state.submissionStatus, validators_brandUsedCar, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            registrationIDEmpty: init.registrationIDEmpty,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            releaseDateEmpty: init.releaseDateEmpty,
                                            usedCarFirstReleaseDateEnd: init.usedCarFirstReleaseDateEnd,
                                            usedCarFirstReleaseDateStart: init.usedCarFirstReleaseDateStart,
                                            registrationDateEmpty: init.registrationDateEmpty,
                                            usedCarLastRegistrationDateEnd: init.usedCarLastRegistrationDateEnd,
                                            usedCarLastRegistrationDateStart: init.usedCarLastRegistrationDateStart,
                                            policeNumberEmpty: init.policeNumberEmpty,
                                            usedCarPoliceNumber: init.usedCarPoliceNumber,
                                            vinEmpty: init.vinEmpty,
                                            vinUsedCar: init.vinUsedCar,
                                            modelEmpty: init.modelEmpty,
                                            modelUsedCar: init.modelUsedCar,
                                            brandEmpty: init.brandEmpty,
                                            brandUsedCar: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */16 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */17 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */18 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateRegistrationIDEmpty: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRegistrationIDEmptyField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateUsedCarRegistrationID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateUsedCarRegistrationIDField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateReleaseDateEmpty: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateReleaseDateEmptyField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateUsedCarFirstReleaseDateEnd: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateUsedCarFirstReleaseDateEndField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateUsedCarFirstReleaseDateStart: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateUsedCarFirstReleaseDateStartField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateRegistrationDateEmpty: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRegistrationDateEmptyField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateUsedCarLastRegistrationDateEnd: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateUsedCarLastRegistrationDateEndField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateUsedCarLastRegistrationDateStart: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateUsedCarLastRegistrationDateStartField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updatePoliceNumberEmpty: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePoliceNumberEmptyField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateUsedCarPoliceNumber: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateUsedCarPoliceNumberField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVinEmpty: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVinEmptyField */10,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVinUsedCar: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVinUsedCarField */11,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateModelEmpty: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelEmptyField */12,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateModelUsedCar: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelUsedCarField */13,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBrandEmpty: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBrandEmptyField */14,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBrandUsedCar: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBrandUsedCarField */15,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurRegistrationIDEmpty: (function (param) {
              Curry._1(dispatch, /* BlurRegistrationIDEmptyField */0);
            }),
          blurUsedCarRegistrationID: (function (param) {
              Curry._1(dispatch, /* BlurUsedCarRegistrationIDField */1);
            }),
          blurReleaseDateEmpty: (function (param) {
              Curry._1(dispatch, /* BlurReleaseDateEmptyField */2);
            }),
          blurUsedCarFirstReleaseDateEnd: (function (param) {
              Curry._1(dispatch, /* BlurUsedCarFirstReleaseDateEndField */3);
            }),
          blurUsedCarFirstReleaseDateStart: (function (param) {
              Curry._1(dispatch, /* BlurUsedCarFirstReleaseDateStartField */4);
            }),
          blurRegistrationDateEmpty: (function (param) {
              Curry._1(dispatch, /* BlurRegistrationDateEmptyField */5);
            }),
          blurUsedCarLastRegistrationDateEnd: (function (param) {
              Curry._1(dispatch, /* BlurUsedCarLastRegistrationDateEndField */6);
            }),
          blurUsedCarLastRegistrationDateStart: (function (param) {
              Curry._1(dispatch, /* BlurUsedCarLastRegistrationDateStartField */7);
            }),
          blurPoliceNumberEmpty: (function (param) {
              Curry._1(dispatch, /* BlurPoliceNumberEmptyField */8);
            }),
          blurUsedCarPoliceNumber: (function (param) {
              Curry._1(dispatch, /* BlurUsedCarPoliceNumberField */9);
            }),
          blurVinEmpty: (function (param) {
              Curry._1(dispatch, /* BlurVinEmptyField */10);
            }),
          blurVinUsedCar: (function (param) {
              Curry._1(dispatch, /* BlurVinUsedCarField */11);
            }),
          blurModelEmpty: (function (param) {
              Curry._1(dispatch, /* BlurModelEmptyField */12);
            }),
          blurModelUsedCar: (function (param) {
              Curry._1(dispatch, /* BlurModelUsedCarField */13);
            }),
          blurBrandEmpty: (function (param) {
              Curry._1(dispatch, /* BlurBrandEmptyField */14);
            }),
          blurBrandUsedCar: (function (param) {
              Curry._1(dispatch, /* BlurBrandUsedCarField */15);
            }),
          registrationIDEmptyResult: Formality.exposeFieldResult(state.fieldsStatuses.registrationIDEmpty),
          usedCarRegistrationIDResult: Formality.exposeFieldResult(state.fieldsStatuses.usedCarRegistrationID),
          releaseDateEmptyResult: Formality.exposeFieldResult(state.fieldsStatuses.releaseDateEmpty),
          usedCarFirstReleaseDateEndResult: Formality.exposeFieldResult(state.fieldsStatuses.usedCarFirstReleaseDateEnd),
          usedCarFirstReleaseDateStartResult: Formality.exposeFieldResult(state.fieldsStatuses.usedCarFirstReleaseDateStart),
          registrationDateEmptyResult: Formality.exposeFieldResult(state.fieldsStatuses.registrationDateEmpty),
          usedCarLastRegistrationDateEndResult: Formality.exposeFieldResult(state.fieldsStatuses.usedCarLastRegistrationDateEnd),
          usedCarLastRegistrationDateStartResult: Formality.exposeFieldResult(state.fieldsStatuses.usedCarLastRegistrationDateStart),
          policeNumberEmptyResult: Formality.exposeFieldResult(state.fieldsStatuses.policeNumberEmpty),
          usedCarPoliceNumberResult: Formality.exposeFieldResult(state.fieldsStatuses.usedCarPoliceNumber),
          vinEmptyResult: Formality.exposeFieldResult(state.fieldsStatuses.vinEmpty),
          vinUsedCarResult: Formality.exposeFieldResult(state.fieldsStatuses.vinUsedCar),
          modelEmptyResult: Formality.exposeFieldResult(state.fieldsStatuses.modelEmpty),
          modelUsedCarResult: Formality.exposeFieldResult(state.fieldsStatuses.modelUsedCar),
          brandEmptyResult: Formality.exposeFieldResult(state.fieldsStatuses.brandEmpty),
          brandUsedCarResult: Formality.exposeFieldResult(state.fieldsStatuses.brandUsedCar),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              var exit = 0;
              if (match.registrationIDEmpty || match.usedCarRegistrationID || match.releaseDateEmpty || match.usedCarFirstReleaseDateEnd || match.usedCarFirstReleaseDateStart || match.registrationDateEmpty || match.usedCarLastRegistrationDateEnd || match.usedCarLastRegistrationDateStart || match.policeNumberEmpty || match.usedCarPoliceNumber || match.vinEmpty || match.vinUsedCar || match.modelEmpty || match.modelUsedCar || match.brandEmpty) {
                exit = 1;
              } else {
                if (!match.brandUsedCar) {
                  return false;
                }
                exit = 1;
              }
              if (exit === 1) {
                var exit$1 = 0;
                exit$1 = 2;
                if (exit$1 === 2) {
                  var exit$2 = 0;
                  exit$2 = 3;
                  if (exit$2 === 3) {
                    var exit$3 = 0;
                    exit$3 = 4;
                    if (exit$3 === 4) {
                      var exit$4 = 0;
                      exit$4 = 5;
                      if (exit$4 === 5) {
                        return true;
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */16);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */17);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */18);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */18,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */19);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  brandUsedCar: "",
  brandEmpty: false,
  modelUsedCar: "",
  modelEmpty: false,
  vinUsedCar: "",
  vinEmpty: false,
  usedCarPoliceNumber: "",
  policeNumberEmpty: false,
  usedCarLastRegistrationDateStart: undefined,
  usedCarLastRegistrationDateEnd: undefined,
  registrationDateEmpty: false,
  usedCarFirstReleaseDateStart: undefined,
  usedCarFirstReleaseDateEnd: undefined,
  releaseDateEmpty: false,
  usedCarRegistrationID: "",
  registrationIDEmpty: false
};

export {
  boolEmptyFieldToQueryParamBilingual ,
  TableFilterForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
