// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function deroLabelExistingBonus(editedDero, action, form) {
  if (Belt_Option.isNone(editedDero)) {
    return Belt_Option.mapWithDefault(action, undefined, (function (x) {
                  Curry._2(form.updateDerogateLabel, (function (input, derogateLabel) {
                          return {
                                  negative: input.negative,
                                  action: input.action,
                                  actionId: input.actionId,
                                  derogateLabel: derogateLabel,
                                  amount: input.amount,
                                  comment: input.comment,
                                  validation: input.validation
                                };
                        }), "DERO " + x.actionId + " - " + Belt_Option.getWithDefault(x.actionIdLabel, ""));
                }));
  }
  
}

function deroLabelNewBonus(editedDero, form, actionFetch) {
  if (typeof actionFetch === "number" || !(actionFetch.TAG === /* Ok */0 && Belt_Option.isNone(editedDero))) {
    return ;
  } else {
    return Belt_Option.mapWithDefault(actionFetch._0, undefined, (function (x) {
                  Curry._2(form.updateDerogateLabel, (function (input, derogateLabel) {
                          return {
                                  negative: input.negative,
                                  action: input.action,
                                  actionId: input.actionId,
                                  derogateLabel: derogateLabel,
                                  amount: input.amount,
                                  comment: input.comment,
                                  validation: input.validation
                                };
                        }), "DERO " + x.actionID + " - " + x.actionLabel);
                }));
  }
}

export {
  deroLabelExistingBonus ,
  deroLabelNewBonus ,
}
/* No side effect */
