// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../common/components/input/App_DateInputFormField.bs.js";

function Bonuses_Search_Modal_Date_Box(Props) {
  var disabledOpt = Props.disabled;
  var title = Props.title;
  var onChangeFrom = Props.onChangeFrom;
  var onChangeTo = Props.onChangeTo;
  var dateFrom = Props.dateFrom;
  var dateTo = Props.dateTo;
  var className = Props.className;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var intl = ReactIntl.useIntl();
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              item: true
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        style: {
                          fontWeight: "600"
                        },
                        children: intl.formatMessage(title)
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  item: true,
                  spacing: 1,
                  wrap: "nowrap"
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, {
                            label: Messages_Common$DvmAdminFrontendFr.startDate,
                            value: dateFrom,
                            onChange: Curry.__1(onChangeFrom),
                            disabled: disabled,
                            className: className
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, {
                            label: Messages_Common$DvmAdminFrontendFr.endDate,
                            value: dateTo,
                            onChange: Curry.__1(onChangeTo),
                            disabled: disabled,
                            className: className
                          }),
                      item: true
                    })));
}

var make = Bonuses_Search_Modal_Date_Box;

export {
  make ,
}
/* react Not a pure module */
