// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as Messages_MarginGrid$DvmAdminFrontendFr from "../../../../intl/messages/marginGrid/Messages_MarginGrid.bs.js";
import * as Grid_Edit_New_Row_Api$DvmAdminFrontendFr from "./Grid_Edit_New_Row_Api.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_AutocompleteFormInput.bs.js";

function Grid_Edit_New_Row_Inputs_GridID(Props) {
  var form = Props.form;
  var budgetOrID = Props.budgetOrID;
  var modelVersionsList = Props.modelVersionsList;
  var filterStartDate = Props.filterStartDate;
  var filterEndDate = Props.filterEndDate;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$2[1];
  React.useEffect((function () {
          Grid_Edit_New_Row_Api$DvmAdminFrontendFr.fetchCategoriesByDate(filterStartDate, filterEndDate, setResult, userManager, selectedRole);
        }), []);
  var datesToString = function (param) {
    var startString = Belt_Option.mapWithDefault(filterStartDate, "", (function (param) {
            return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
          }));
    var endString = Belt_Option.mapWithDefault(filterEndDate, "", (function (param) {
            return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
          }));
    if (startString === "" && endString === "") {
      return null;
    }
    return React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                children: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.categoryCode) + ": " + startString + " - " + endString,
                variant: "subtitle"
              });
  };
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.categoryCode,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.categoryCode,
        onChange: Curry._1(form.updateCategoryCode, (function (input, categoryCode) {
                return {
                        model: input.model,
                        version: input.version,
                        categoryCode: categoryCode,
                        discount: input.discount,
                        margin: input.margin,
                        startDate: input.startDate,
                        endDate: input.endDate,
                        acquisitionType: input.acquisitionType,
                        hasExceptions: input.hasExceptions,
                        categoryStartDate: input.categoryStartDate
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: Belt_List.concat(Belt_List.map(modelVersionsList, (function (x) {
                  return x.parent;
                })), App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$2[0], /* [] */0))
    },
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.categoryCodeResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.categoryCodeResult);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: "" + intl.formatMessage(Messages_MarginGrid$DvmAdminFrontendFr.gridID) + ": " + budgetOrID + "",
                  item: true
                }), React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp), datesToString(undefined));
}

var Api;

var Form;

var InputStyles;

var make = Grid_Edit_New_Row_Inputs_GridID;

export {
  Api ,
  Form ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
