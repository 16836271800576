// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../../common/utils/Utils_Date.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../common/utils/Utils_Form.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../intl/messages/Messages_Error_Inputs.bs.js";

var validators_avsMarginGridId = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.avsMarginGridId);
    })
};

var validators_contractID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.contractID
            };
    })
};

var validators_yearBudget = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var date = Utils_Date$DvmAdminFrontendFr.toYearString(param.yearBudget);
      if (date !== undefined && date.length !== 0) {
        return Belt_Option.mapWithDefault(Belt_Int.fromString(date), {
                    TAG: /* Error */1,
                    _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                  }, (function (x) {
                      return {
                              TAG: /* Ok */0,
                              _0: x
                            };
                    }));
      } else {
        return {
                TAG: /* Error */1,
                _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
              };
      }
    })
};

var validators_acquisitionType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.acquisitionType);
    })
};

var validators_volume = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      if (param.unlimitedVolume) {
        return {
                TAG: /* Ok */0,
                _0: -1
              };
      }
      var value = Utils_Form$DvmAdminFrontendFr.validateInteger(param.volume);
      if (value.TAG === /* Ok */0) {
        return Utils_Form$DvmAdminFrontendFr.validateIntegerRequired(value._0);
      } else {
        return {
                TAG: /* Error */1,
                _0: value._0
              };
      }
    })
};

var validators = {
  unlimitedVolume: undefined,
  avsMarginGridId: validators_avsMarginGridId,
  contractID: validators_contractID,
  yearBudget: validators_yearBudget,
  acquisitionType: validators_acquisitionType,
  volume: validators_volume
};

function initialFieldsStatuses(_input) {
  return {
          unlimitedVolume: /* Pristine */0,
          avsMarginGridId: /* Pristine */0,
          contractID: /* Pristine */0,
          yearBudget: /* Pristine */0,
          acquisitionType: /* Pristine */0,
          volume: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match_0 = {
    TAG: /* Ok */0,
    _0: input.unlimitedVolume
  };
  var match = fieldsStatuses.avsMarginGridId;
  var match_0$1 = match ? match._0 : Curry._1(validators.avsMarginGridId.validate, input);
  var match$1 = fieldsStatuses.contractID;
  var match_0$2 = match$1 ? match$1._0 : Curry._1(validators.contractID.validate, input);
  var match$2 = fieldsStatuses.yearBudget;
  var match_0$3 = match$2 ? match$2._0 : Curry._1(validators.yearBudget.validate, input);
  var match$3 = fieldsStatuses.acquisitionType;
  var match_0$4 = match$3 ? match$3._0 : Curry._1(validators.acquisitionType.validate, input);
  var match$4 = fieldsStatuses.volume;
  var match_0$5 = match$4 ? match$4._0 : Curry._1(validators.volume.validate, input);
  var unlimitedVolumeResult = match_0;
  var unlimitedVolumeResult$1;
  if (unlimitedVolumeResult.TAG === /* Ok */0) {
    var avsMarginGridIdResult = match_0$1;
    if (avsMarginGridIdResult.TAG === /* Ok */0) {
      var contractIDResult = match_0$2;
      if (contractIDResult.TAG === /* Ok */0) {
        var yearBudgetResult = match_0$3;
        if (yearBudgetResult.TAG === /* Ok */0) {
          var acquisitionTypeResult = match_0$4;
          if (acquisitionTypeResult.TAG === /* Ok */0) {
            var volumeResult = match_0$5;
            if (volumeResult.TAG === /* Ok */0) {
              return {
                      TAG: /* Valid */0,
                      output: {
                        volume: volumeResult._0,
                        acquisitionType: acquisitionTypeResult._0,
                        yearBudget: yearBudgetResult._0,
                        contractID: contractIDResult._0,
                        avsMarginGridId: avsMarginGridIdResult._0,
                        unlimitedVolume: unlimitedVolumeResult._0
                      },
                      fieldsStatuses: {
                        unlimitedVolume: /* Dirty */{
                          _0: unlimitedVolumeResult,
                          _1: /* Hidden */1
                        },
                        avsMarginGridId: /* Dirty */{
                          _0: avsMarginGridIdResult,
                          _1: /* Shown */0
                        },
                        contractID: /* Dirty */{
                          _0: contractIDResult,
                          _1: /* Shown */0
                        },
                        yearBudget: /* Dirty */{
                          _0: yearBudgetResult,
                          _1: /* Shown */0
                        },
                        acquisitionType: /* Dirty */{
                          _0: acquisitionTypeResult,
                          _1: /* Shown */0
                        },
                        volume: /* Dirty */{
                          _0: volumeResult,
                          _1: /* Shown */0
                        }
                      },
                      collectionsStatuses: undefined
                    };
            }
            unlimitedVolumeResult$1 = unlimitedVolumeResult;
          } else {
            unlimitedVolumeResult$1 = unlimitedVolumeResult;
          }
        } else {
          unlimitedVolumeResult$1 = unlimitedVolumeResult;
        }
      } else {
        unlimitedVolumeResult$1 = unlimitedVolumeResult;
      }
    } else {
      unlimitedVolumeResult$1 = unlimitedVolumeResult;
    }
  } else {
    unlimitedVolumeResult$1 = unlimitedVolumeResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            unlimitedVolume: /* Dirty */{
              _0: unlimitedVolumeResult$1,
              _1: /* Hidden */1
            },
            avsMarginGridId: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            contractID: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            yearBudget: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            acquisitionType: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            volume: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurUnlimitedVolumeField */0 :
                  var result = Formality.validateFieldOnBlurWithoutValidator(state.input.unlimitedVolume, state.fieldsStatuses.unlimitedVolume, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  unlimitedVolume: status,
                                  avsMarginGridId: init.avsMarginGridId,
                                  contractID: init.contractID,
                                  yearBudget: init.yearBudget,
                                  acquisitionType: init.acquisitionType,
                                  volume: init.volume
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAvsMarginGridIdField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.avsMarginGridId, validators_avsMarginGridId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  unlimitedVolume: init.unlimitedVolume,
                                  avsMarginGridId: status,
                                  contractID: init.contractID,
                                  yearBudget: init.yearBudget,
                                  acquisitionType: init.acquisitionType,
                                  volume: init.volume
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurContractIDField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.contractID, validators_contractID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  unlimitedVolume: init.unlimitedVolume,
                                  avsMarginGridId: init.avsMarginGridId,
                                  contractID: status,
                                  yearBudget: init.yearBudget,
                                  acquisitionType: init.acquisitionType,
                                  volume: init.volume
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurYearBudgetField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.yearBudget, validators_yearBudget, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  unlimitedVolume: init.unlimitedVolume,
                                  avsMarginGridId: init.avsMarginGridId,
                                  contractID: init.contractID,
                                  yearBudget: status,
                                  acquisitionType: init.acquisitionType,
                                  volume: init.volume
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAcquisitionTypeField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.acquisitionType, validators_acquisitionType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  unlimitedVolume: init.unlimitedVolume,
                                  avsMarginGridId: init.avsMarginGridId,
                                  contractID: init.contractID,
                                  yearBudget: init.yearBudget,
                                  acquisitionType: status,
                                  volume: init.volume
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVolumeField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.volume, validators_volume, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  unlimitedVolume: init.unlimitedVolume,
                                  avsMarginGridId: init.avsMarginGridId,
                                  contractID: init.contractID,
                                  yearBudget: init.yearBudget,
                                  acquisitionType: init.acquisitionType,
                                  volume: status
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */6 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */6,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */7,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */9);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */8);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */7 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */8 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */9 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateUnlimitedVolumeField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput.unlimitedVolume, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            unlimitedVolume: status,
                                            avsMarginGridId: init.avsMarginGridId,
                                            contractID: init.contractID,
                                            yearBudget: init.yearBudget,
                                            acquisitionType: init.acquisitionType,
                                            volume: init.volume
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAvsMarginGridIdField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.avsMarginGridId, state.submissionStatus, validators_avsMarginGridId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            unlimitedVolume: init.unlimitedVolume,
                                            avsMarginGridId: status,
                                            contractID: init.contractID,
                                            yearBudget: init.yearBudget,
                                            acquisitionType: init.acquisitionType,
                                            volume: init.volume
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateContractIDField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.contractID, state.submissionStatus, validators_contractID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            unlimitedVolume: init.unlimitedVolume,
                                            avsMarginGridId: init.avsMarginGridId,
                                            contractID: status,
                                            yearBudget: init.yearBudget,
                                            acquisitionType: init.acquisitionType,
                                            volume: init.volume
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateYearBudgetField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.yearBudget, state.submissionStatus, validators_yearBudget, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            unlimitedVolume: init.unlimitedVolume,
                                            avsMarginGridId: init.avsMarginGridId,
                                            contractID: init.contractID,
                                            yearBudget: status,
                                            acquisitionType: init.acquisitionType,
                                            volume: init.volume
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAcquisitionTypeField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.acquisitionType, state.submissionStatus, validators_acquisitionType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            unlimitedVolume: init.unlimitedVolume,
                                            avsMarginGridId: init.avsMarginGridId,
                                            contractID: init.contractID,
                                            yearBudget: init.yearBudget,
                                            acquisitionType: status,
                                            volume: init.volume
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVolumeField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.volume, state.submissionStatus, validators_volume, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            unlimitedVolume: init.unlimitedVolume,
                                            avsMarginGridId: init.avsMarginGridId,
                                            contractID: init.contractID,
                                            yearBudget: init.yearBudget,
                                            acquisitionType: init.acquisitionType,
                                            volume: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */6 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */7 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */8 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateUnlimitedVolume: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateUnlimitedVolumeField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateAvsMarginGridId: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAvsMarginGridIdField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateContractID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateContractIDField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateYearBudget: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateYearBudgetField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateAcquisitionType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAcquisitionTypeField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVolume: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVolumeField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurUnlimitedVolume: (function (param) {
              Curry._1(dispatch, /* BlurUnlimitedVolumeField */0);
            }),
          blurAvsMarginGridId: (function (param) {
              Curry._1(dispatch, /* BlurAvsMarginGridIdField */1);
            }),
          blurContractID: (function (param) {
              Curry._1(dispatch, /* BlurContractIDField */2);
            }),
          blurYearBudget: (function (param) {
              Curry._1(dispatch, /* BlurYearBudgetField */3);
            }),
          blurAcquisitionType: (function (param) {
              Curry._1(dispatch, /* BlurAcquisitionTypeField */4);
            }),
          blurVolume: (function (param) {
              Curry._1(dispatch, /* BlurVolumeField */5);
            }),
          unlimitedVolumeResult: Formality.exposeFieldResult(state.fieldsStatuses.unlimitedVolume),
          avsMarginGridIdResult: Formality.exposeFieldResult(state.fieldsStatuses.avsMarginGridId),
          contractIDResult: Formality.exposeFieldResult(state.fieldsStatuses.contractID),
          yearBudgetResult: Formality.exposeFieldResult(state.fieldsStatuses.yearBudget),
          acquisitionTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.acquisitionType),
          volumeResult: Formality.exposeFieldResult(state.fieldsStatuses.volume),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.unlimitedVolume || match.avsMarginGridId || match.contractID || match.yearBudget || match.acquisitionType || match.volume) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */6);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */7);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */8);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */8,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */9);
            })
        };
}

var Form = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  volume: "",
  acquisitionType: "",
  yearBudget: undefined,
  contractID: "",
  avsMarginGridId: "",
  unlimitedVolume: false
};

export {
  Form ,
  initialState$1 as initialState,
}
/* react Not a pure module */
