// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as Utils_Api$DvmAdminFrontendFr from "../../utils/Utils_Api.bs.js";
import * as App_Spinner$DvmAdminFrontendFr from "../../components/App_Spinner.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../components/table/App_Table_Pagination.bs.js";
import * as File_Upload_Contract_Errors_Api$DvmAdminFrontendFr from "./File_Upload_Contract_Errors_Api.bs.js";
import * as File_Upload_Contract_Errors_Table$DvmAdminFrontendFr from "./File_Upload_Contract_Errors_Table.bs.js";

function File_Upload_Contract_Errors_Page(Props) {
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match[1];
  var tablePage = match[0];
  var match$1 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: Caml_array.get(App_Table_Pagination$DvmAdminFrontendFr.options, 0),
                headers: File_Upload_Contract_Errors_Table$DvmAdminFrontendFr.headersConfiguration,
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$1[1];
  var fetchRequest = match$1[0];
  var alert = ReactAlert.useAlert();
  var match$2 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$3 = match$2[0];
  var userManager = match$3.userManager;
  var selectedRole = match$3.selectedRole;
  var fetchData = function (fetchRequest) {
    File_Upload_Contract_Errors_Api$DvmAdminFrontendFr.fetchAttachmentUploadErrorsSearch(userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, fetchRequest), selectedRole);
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  React.useEffect((function () {
          fetchData(fetchRequest);
        }), []);
  var tmp;
  if (typeof tablePage === "number") {
    tmp = tablePage === /* NotStarted */0 ? null : React.createElement(App_Spinner$DvmAdminFrontendFr.make, {});
  } else if (tablePage.TAG === /* Ok */0) {
    tmp = null;
  } else {
    Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
              message: {
                TAG: /* RestError */2,
                _0: tablePage._0
              }
            }));
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Box, {
                  children: null
                }, tmp, React.createElement(File_Upload_Contract_Errors_Table$DvmAdminFrontendFr.make, {
                      tablePage: tablePage,
                      fetchRequest: fetchRequest,
                      fetchData: fetchData
                    })));
}

var Api;

var Table;

var make = File_Upload_Contract_Errors_Page;

export {
  Api ,
  Table ,
  make ,
}
/* react Not a pure module */
