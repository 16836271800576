// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../common/components/icons/App_Icon.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../route/Route_Paths.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../context/user/User_Context.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../context/router/Context_Router.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../common/styles/App_Styles_Common.bs.js";
import * as Model_Version_Api$DvmAdminFrontendFr from "./Model_Version_Api.bs.js";
import * as Model_Version_Form$DvmAdminFrontendFr from "./Model_Version_Form.bs.js";
import * as Messages_ModelVersion$DvmAdminFrontendFr from "../../intl/messages/modelVersion/Messages_ModelVersion.bs.js";

function Model_Version_Missing_Motorization_Menu_Icon(Props) {
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match[1];
  var result = match[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match$1 = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match$1[1];
  var match$2 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$3 = match$2[0];
  var userManager = match$3.userManager;
  var selectedRole = match$3.selectedRole;
  React.useEffect((function () {
          Model_Version_Api$DvmAdminFrontendFr.fetchMissingMotorizations(userManager, setResult, "?limit=1&offset=0", selectedRole);
        }), [url]);
  var tmp;
  if (typeof result === "number" || result.TAG !== /* Ok */0) {
    tmp = null;
  } else {
    var total = result._0.total;
    tmp = total > 0 ? React.createElement(Core.Tooltip, {
            arrow: true,
            children: React.createElement("div", undefined, React.createElement(Core.Button, {
                      onClick: (function (param) {
                          Curry._1(dispatch, {
                                TAG: /* StoreModelVersionFilter */2,
                                _0: {
                                  brand: Model_Version_Form$DvmAdminFrontendFr.initialState.brand,
                                  model: Model_Version_Form$DvmAdminFrontendFr.initialState.model,
                                  version: Model_Version_Form$DvmAdminFrontendFr.initialState.version,
                                  phase: Model_Version_Form$DvmAdminFrontendFr.initialState.phase,
                                  modelLabel: Model_Version_Form$DvmAdminFrontendFr.initialState.modelLabel,
                                  versionLabel: Model_Version_Form$DvmAdminFrontendFr.initialState.versionLabel,
                                  motorization: Model_Version_Form$DvmAdminFrontendFr.initialState.motorization,
                                  emptyMotorization: true,
                                  active: "TRUE",
                                  tableType: /* Version */1,
                                  additionDate: Model_Version_Form$DvmAdminFrontendFr.initialState.additionDate
                                }
                              });
                          RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rModelVersionList);
                        }),
                      children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                            size: /* XLarge */4,
                            type_: /* FuelPump */67,
                            color: "orange"
                          })
                    })),
            classes: {
              tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
            },
            placement: "bottom",
            title: React.createElement("p", undefined, intl.formatMessage(Messages_ModelVersion$DvmAdminFrontendFr.tooltipMissingMotorizations, {
                      total: total
                    }))
          }) : null;
  }
  return React.createElement(Core.Box, {
              children: tmp
            });
}

var Api;

var make = Model_Version_Missing_Motorization_Menu_Icon;

export {
  Api ,
  make ,
}
/* react Not a pure module */
