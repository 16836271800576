// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Constants$DvmAdminFrontendFr from "../../Constants.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../App_Typography.bs.js";
import * as App_TopMenu_Api$DvmAdminFrontendFr from "./App_TopMenu_Api.bs.js";
import * as Messages_TopMenu$DvmAdminFrontendFr from "../../../intl/messages/topMenu/Messages_TopMenu.bs.js";

var calculationBanner = Css.css({
      backgroundColor: Theme_Colors$DvmAdminFrontendFr.accentOrangeHover,
      position: "fixed",
      top: Constants$DvmAdminFrontendFr.topMenuHeight,
      width: "100%"
    });

var Styles = {
  calculationBanner: calculationBanner
};

function App_TopMenu_Calculations_Banner(Props) {
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$2[1];
  var result = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setCalcRunning = match$3[1];
  React.useEffect((function () {
          if (selectedRole !== /* NoRole */9) {
            App_TopMenu_Api$DvmAdminFrontendFr.fetchCalculationStatus(userManager, setResult, selectedRole);
          }
          
        }), [url]);
  React.useEffect((function () {
          if (typeof result !== "number" && result.TAG === /* Ok */0) {
            var isCalcRunning = Belt_List.length(Belt_List.keep(result._0, (function (x) {
                        if (Belt_Option.getWithDefault(x.status, "") === "Running" && Belt_Option.getWithDefault(x.type, "") !== "Publication") {
                          return Belt_Option.getWithDefault(x.type, "") !== "Import";
                        } else {
                          return false;
                        }
                      }))) > 0;
            Curry._1(setCalcRunning, (function (param) {
                    return isCalcRunning;
                  }));
          }
          
        }), [result]);
  if (match$3[0]) {
    return React.createElement(Core.Box, {
                className: calculationBanner,
                children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                      color: "textPrimary",
                      children: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.calculationsBanner),
                      variant: "h6"
                    }),
                display: "flex",
                justifyContent: "center"
              });
  } else {
    return null;
  }
}

var make = App_TopMenu_Calculations_Banner;

export {
  Styles ,
  make ,
}
/* calculationBanner Not a pure module */
