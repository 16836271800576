// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReDate_utils from "@mobily/rescript-date/src/ReDate_utils.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../intl/messages/Messages_Error_Inputs.bs.js";

function validateMarginDates(smallerDate, largerDate, followingStartDate, previousEndDate, thisDate) {
  var match = thisDate === "smaller" ? [
      smallerDate,
      largerDate
    ] : [
      largerDate,
      smallerDate
    ];
  var that = match[1];
  return Belt_Option.mapWithDefault(match[0], {
              TAG: /* Ok */0,
              _0: undefined
            }, (function ($$this) {
                if (App_Types_Date$DvmAdminFrontendFr.isValid($$this)) {
                  return Belt_Option.mapWithDefault(that, {
                              TAG: /* Error */1,
                              _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherDateNotValid
                            }, (function (that) {
                                if (App_Types_Date$DvmAdminFrontendFr.isValid(that)) {
                                  if (thisDate === "smaller") {
                                    if (ReDate.isAfter(that, $$this) || ReDate.isEqual(that, $$this)) {
                                      return Belt_Option.mapWithDefault(previousEndDate, {
                                                  TAG: /* Ok */0,
                                                  _0: Caml_option.some($$this)
                                                }, (function (previousDate) {
                                                    if (App_Types_Date$DvmAdminFrontendFr.isValid(previousDate) && ReDate_utils.makeStartOfDayDate($$this).valueOf() < ReDate_utils.makeStartOfDayDate(previousDate).valueOf()) {
                                                      return {
                                                              TAG: /* Error */1,
                                                              _0: Messages_Error_Inputs$DvmAdminFrontendFr.startDateTooLow
                                                            };
                                                    } else {
                                                      return {
                                                              TAG: /* Ok */0,
                                                              _0: Caml_option.some($$this)
                                                            };
                                                    }
                                                  }));
                                    } else {
                                      return {
                                              TAG: /* Error */1,
                                              _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateTooLarge
                                            };
                                    }
                                  } else if (ReDate.isAfter($$this, that) || ReDate.isEqual($$this, that)) {
                                    return Belt_Option.mapWithDefault(followingStartDate, {
                                                TAG: /* Ok */0,
                                                _0: Caml_option.some($$this)
                                              }, (function (followingDate) {
                                                  if (App_Types_Date$DvmAdminFrontendFr.isValid(followingDate) && ReDate_utils.makeStartOfDayDate($$this).valueOf() > ReDate_utils.makeStartOfDayDate(followingDate).valueOf()) {
                                                    return {
                                                            TAG: /* Error */1,
                                                            _0: Messages_Error_Inputs$DvmAdminFrontendFr.endDateTooHigh
                                                          };
                                                  } else {
                                                    return {
                                                            TAG: /* Ok */0,
                                                            _0: Caml_option.some($$this)
                                                          };
                                                  }
                                                }));
                                  } else {
                                    return {
                                            TAG: /* Error */1,
                                            _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateTooSmall
                                          };
                                  }
                                } else {
                                  return {
                                          TAG: /* Error */1,
                                          _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherDateNotValid
                                        };
                                }
                              }));
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                        };
                }
              }));
}

export {
  validateMarginDates ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
