// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../icons/App_Icon.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../App_Typography.bs.js";

function App_Table_Create_Button(Props) {
  var onClick = Props.onClick;
  var iconOpt = Props.icon;
  var message = Props.message;
  var icon = iconOpt !== undefined ? iconOpt : /* More */17;
  var intl = ReactIntl.useIntl();
  return React.createElement(Core.Button, {
              onClick: onClick,
              children: React.createElement(Core.Grid, {
                    children: null,
                    container: true,
                    wrap: "nowrap"
                  }, React.createElement(Core.Grid, {
                        alignItems: "center",
                        children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                              size: /* Regular */2,
                              type_: icon,
                              color: Theme_Colors$DvmAdminFrontendFr.secondaryBlue,
                              fontWeight: "600"
                            }),
                        container: true,
                        item: true,
                        style: {
                          paddingRight: "3px"
                        }
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                              noWrap: true,
                              style: {
                                color: Theme_Colors$DvmAdminFrontendFr.secondaryBlue,
                                fontWeight: "600"
                              },
                              children: intl.formatMessage(message),
                              variant: "subtitle"
                            }),
                        item: true
                      }))
            });
}

var make = App_Table_Create_Button;

export {
  make ,
}
/* react Not a pure module */
