// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactIntl from "react-intl";
import * as App_Typography$DvmAdminFrontendFr from "../../App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";

function App_Table_Result(Props) {
  var number = Props.number;
  var message = Props.message;
  var intl = ReactIntl.useIntl();
  return React.createElement(App_Typography$DvmAdminFrontendFr.make, {
              style: {
                fontWeight: "bold"
              },
              children: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.tablesResult) + " : " + intl.formatNumber(number) + " " + intl.formatMessage(message).toLowerCase(),
              variant: "bodyBig"
            });
}

var make = App_Table_Result;

export {
  make ,
}
/* react Not a pure module */
