// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../common/components/App_Modal.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Api.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table_Pagination.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as Quota_View_Modal_Exclusion_Api$DvmAdminFrontendFr from "./Quota_View_Modal_Exclusion_Api.bs.js";
import * as Quota_View_Modal_Exclusion_Form$DvmAdminFrontendFr from "./Quota_View_Modal_Exclusion_Form.bs.js";
import * as Quota_View_Modal_Exclusion_Table$DvmAdminFrontendFr from "./Quota_View_Modal_Exclusion_Table.bs.js";
import * as Quota_View_Modal_Exclusion_Filters$DvmAdminFrontendFr from "./Quota_View_Modal_Exclusion_Filters.bs.js";

function Quota_View_Modal_Exclusion(Props) {
  var quota = Props.quota;
  var onClose = Props.onClose;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match[1];
  var match$1 = React.useState(function () {
        return /* Model */0;
      });
  var tableType = match$1[0];
  var match$2 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: Caml_array.get(App_Table_Pagination$DvmAdminFrontendFr.options, 0),
                headers: Quota_View_Modal_Exclusion_Table$DvmAdminFrontendFr.headersConfiguration(tableType),
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$2[1];
  var fetchRequest = match$2[0];
  var match$3 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$4 = match$3[0];
  var userManager = match$4.userManager;
  var selectedRole = match$4.selectedRole;
  var fetchData = function (submissionCallbacks, fetchRequest) {
    Quota_View_Modal_Exclusion_Api$DvmAdminFrontendFr.fetchQuotaDetailExclusionSearch(submissionCallbacks, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
              page: fetchRequest.page,
              rowsPerPage: 10,
              headers: fetchRequest.headers,
              filterQueryParams: fetchRequest.filterQueryParams,
              additionalQueryParams: fetchRequest.additionalQueryParams
            }), tableType, selectedRole);
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  var form = Quota_View_Modal_Exclusion_Form$DvmAdminFrontendFr.TableDetailExclusionFilter.useForm(Quota_View_Modal_Exclusion_Form$DvmAdminFrontendFr.initialState, (function (param, submissionCallbacks) {
          fetchData(submissionCallbacks, {
                page: fetchRequest.page,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: Quota_View_Modal_Exclusion_Table$DvmAdminFrontendFr.headersConfiguration(tableType),
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                              hd: param.model,
                              tl: {
                                hd: tableType === /* Model */0 ? undefined : param.version,
                                tl: {
                                  hd: "contractId=" + quota.contractId + "",
                                  tl: {
                                    hd: quota.vehicleType === "VPVU" ? undefined : "vehicleType=" + quota.vehicleType,
                                    tl: {
                                      hd: Utils_Api$DvmAdminFrontendFr.datePairToQueryParam(Caml_option.some(Caml_option.some(quota.startDate)), Caml_option.some(quota.endDate), "startDate"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  React.useEffect((function () {
          Curry._1(form.submit, undefined);
        }), [tableType]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.exclusionTitle),
              minWidth: "700px",
              children: null
            }, React.createElement(Core.Grid, {
                  alignItems: "center",
                  children: React.createElement(Core.Grid, {
                        children: React.createElement(Quota_View_Modal_Exclusion_Filters$DvmAdminFrontendFr.make, {
                              form: form
                            }),
                        item: true
                      }),
                  container: true,
                  justifyContent: "space-between"
                }), React.createElement(Quota_View_Modal_Exclusion_Table$DvmAdminFrontendFr.make, {
                  tablePage: match[0],
                  fetchRequest: fetchRequest,
                  fetchData: fetchData,
                  tableType: tableType
                }));
}

var Api;

var Table;

var Filter;

var Form;

var make = Quota_View_Modal_Exclusion;

export {
  Api ,
  Table ,
  Filter ,
  Form ,
  make ,
}
/* react Not a pure module */
