// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as Grid_Types$DvmAdminFrontendFr from "../Grid_Types.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as ModelVersion_Grid_Manage_Types$DvmAdminFrontendFr from "./ModelVersion_Grid_Manage_Types.bs.js";

function fetchModelVersionSearch(submissionCallbacks, userManager, setResult, queryParams, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", ModelVersion_Grid_Manage_Types$DvmAdminFrontendFr.MVGrid.searchResult_decode, undefined, userManager, selectedRole, "/" + Grid_Types$DvmAdminFrontendFr.modelGridEndpoint + "/contractInfo" + queryParams + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var modelsTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: modelsTable
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchModelVersionGridManageSearch */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function queryForContractIdSelect(alert, userManager, setContractOptions, selectedRole) {
  RestApi$DvmAdminFrontendFr.call("GET", ModelVersion_Grid_Manage_Types$DvmAdminFrontendFr.ContractSelect.searchResult_decode, undefined, userManager, selectedRole, "/contract/contracts?limit=150000&offset=0").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var contracts = result._0;
          return Curry._1(setContractOptions, (function (param) {
                        return contracts.content;
                      }));
        }
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: result._0
                  }
                }));
        return Curry._1(setContractOptions, (function (param) {
                      return /* [] */0;
                    }));
      });
}

var Form;

export {
  Form ,
  fetchModelVersionSearch ,
  queryForContractIdSelect ,
}
/* react Not a pure module */
