// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Button$DvmAdminFrontendFr from "../../../components/button/App_Button.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../components/table/App_Table_Head.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Table_Styles$DvmAdminFrontendFr from "../../../components/table/App_Table_Styles.bs.js";
import * as File_Upload_Types$DvmAdminFrontendFr from "../../File_Upload_Types.bs.js";
import * as Messages_Category$DvmAdminFrontendFr from "../../../../intl/messages/category/Messages_Category.bs.js";
import * as Messages_File_Upload$DvmAdminFrontendFr from "../../../../intl/messages/Messages_File_Upload.bs.js";
import * as Messages_Quotas_PARC$DvmAdminFrontendFr from "../../../../intl/messages/quotas/Messages_Quotas_PARC.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as Quota_PARC_Manage_Utils$DvmAdminFrontendFr from "../../../../pages/quotaPARC/admin/Quota_PARC_Manage_Utils.bs.js";

function reFirstToString(re) {
  return Belt_Option.mapWithDefault(re, undefined, (function (x) {
                return Belt_Option.mapWithDefault(Belt_Array.get(x, 1), undefined, (function (val) {
                              if (val == null) {
                                return ;
                              } else {
                                return Caml_option.some(val);
                              }
                            }));
              }));
}

function tryParseError(row, intl) {
  var rowNumRegex = /Record (\d+)/.exec(row);
  var match = row.includes("plContracts") ? [
      Caml_option.null_to_opt(/plContracts (\d+)/.exec(row)),
      Messages_File_Upload$DvmAdminFrontendFr.contractProtocol,
      /* PicklistMissing */0
    ] : (
      row.includes("plCompanySIREN") ? [
          Caml_option.null_to_opt(/plCompanySIREN (\d+)/.exec(row)),
          Messages_Common$DvmAdminFrontendFr.siren,
          /* PicklistMissing */0
        ] : (
          row.includes("Showing first") ? [
              Caml_option.null_to_opt(/Showing first 10 of (\d+)/.exec(row)),
              undefined,
              /* ShowingLines */2
            ] : [
              undefined,
              undefined,
              undefined
            ]
        )
    );
  var errorKind = match[2];
  var tableMessage = match[1];
  var captureVal = reFirstToString(match[0]);
  var captureRow = reFirstToString(rowNumRegex === null ? undefined : Caml_option.some(rowNumRegex));
  if (errorKind === undefined) {
    return row;
  }
  switch (errorKind) {
    case /* PicklistMissing */0 :
    case /* InvalidDate */1 :
        if (captureRow !== undefined && captureVal !== undefined && tableMessage !== undefined) {
          return intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.picklistMissingError, {
                      rowNumber: captureRow,
                      value: captureVal,
                      table: intl.formatMessage(tableMessage)
                    });
        } else {
          return row;
        }
    case /* ShowingLines */2 :
        if (captureVal !== undefined) {
          return intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.showingFirstTenOfRows, {
                      number: captureVal
                    });
        } else {
          return row;
        }
    
  }
}

function getValByIndex(vals, index, intl, label) {
  return Belt_Option.mapWithDefault(Belt_Array.get(vals, index), "", (function (x) {
                return Belt_Option.mapWithDefault(x, "", (function (x) {
                              if (x.length > 0) {
                                return intl.formatMessage(label) + ": " + x;
                              } else {
                                return "";
                              }
                            }));
              }));
}

function tryParseErrorParcQuota(row, intl) {
  var vals = row.split(/-/);
  var dealer = getValByIndex(vals, 0, intl, Messages_Common$DvmAdminFrontendFr.dealerID);
  var orderType = getValByIndex(vals, 1, intl, Messages_Common$DvmAdminFrontendFr.orderType);
  var model = getValByIndex(vals, 2, intl, Messages_Common$DvmAdminFrontendFr.model);
  var phase = getValByIndex(vals, 3, intl, Messages_Common$DvmAdminFrontendFr.phase);
  var energy = getValByIndex(vals, 4, intl, Messages_Category$DvmAdminFrontendFr.motorisation);
  var startDate = getValByIndex(vals, 5, intl, Messages_Common$DvmAdminFrontendFr.startDate);
  var endDate = getValByIndex(vals, 6, intl, Messages_Common$DvmAdminFrontendFr.endDate);
  var volume = getValByIndex(vals, 7, intl, Messages_Quotas_Detail$DvmAdminFrontendFr.volume);
  return intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.rowOverlapping) + ": " + Belt_Array.joinWith(Belt_Array.keep([
                  dealer,
                  orderType,
                  model,
                  phase,
                  energy,
                  startDate,
                  endDate,
                  volume
                ], (function (x) {
                    return x.length > 0;
                  })), " | ", (function (x) {
                return x;
              }));
}

function foundErrors(checkWithStageResult) {
  return Belt_Option.mapWithDefault(checkWithStageResult.results, false, (function (results) {
                return Belt_List.length(Belt_List.keep(results, (function (row) {
                                  return Belt_List.length(Belt_List.keep(row.errors, (function (error) {
                                                    return error.errorLevel === /* ERROR */0;
                                                  }))) > 0;
                                }))) > 0;
              }));
}

function foundWarnings(checkWithStageResult) {
  return Belt_Option.mapWithDefault(checkWithStageResult.results, false, (function (results) {
                return Belt_List.length(Belt_List.keep(results, (function (row) {
                                  return Belt_List.length(Belt_List.keep(row.errors, (function (error) {
                                                    return error.errorLevel === /* WARN_CONFIRM */2;
                                                  }))) > 0;
                                }))) > 0;
              }));
}

function File_Upload_Excel_Check_Stage_Table_Parc(Props) {
  var checkWithStageResult = Props.checkWithStageResult;
  var setNextDisabled = Props.setNextDisabled;
  var handleNext = Props.handleNext;
  var setOverrideButton = Props.setOverrideButton;
  var onClose = Props.onClose;
  var intl = ReactIntl.useIntl();
  React.useEffect((function () {
          if (!foundErrors(checkWithStageResult)) {
            Curry._1(setNextDisabled, (function (param) {
                    return false;
                  }));
          }
          if (!foundErrors(checkWithStageResult) && foundWarnings(checkWithStageResult)) {
            Curry._1(setOverrideButton, (function (param) {
                    return Caml_option.some(React.createElement(Core.Grid, {
                                    children: null,
                                    container: true,
                                    item: true,
                                    justifyContent: "center",
                                    spacing: 4
                                  }, React.createElement(Core.Grid, {
                                        children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                              onClick: (function (param) {
                                                  Curry._1(onClose, undefined);
                                                }),
                                              label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.close),
                                              color: "primaryBorder",
                                              size: "medium"
                                            }),
                                        item: true
                                      }), React.createElement(Core.Grid, {
                                        children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                              onClick: (function (param) {
                                                  Curry._1(setOverrideButton, (function (param) {
                                                          
                                                        }));
                                                  Curry._1(setNextDisabled, (function (param) {
                                                          return true;
                                                        }));
                                                  Curry._1(handleNext, undefined);
                                                }),
                                              label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.next),
                                              color: "primary",
                                              size: "medium"
                                            }),
                                        item: true
                                      })));
                  }));
          }
          
        }), []);
  var okMessage = React.createElement(App_Typography$DvmAdminFrontendFr.make, {
        color: "primary",
        children: intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.excelOK),
        variant: "bodyBig"
      });
  var checksResult = function (param) {
    return Belt_Option.mapWithDefault(checkWithStageResult.results, okMessage, (function (results) {
                  if (Belt_List.length(results) === 0) {
                    return okMessage;
                  } else {
                    return React.createElement(Core.TableContainer, {
                                children: React.createElement(Core.Table, {
                                      children: null,
                                      stickyHeader: true
                                    }, React.createElement(Core.TableHead, {
                                          children: React.createElement(Core.TableRow, {
                                                children: Belt_List.toArray(Belt_List.map({
                                                          hd: "Ligne",
                                                          tl: {
                                                            hd: "Erreur",
                                                            tl: /* [] */0
                                                          }
                                                        }, (function (column) {
                                                            return React.createElement(Core.TableCell, {
                                                                        children: column,
                                                                        className: App_Table_Head$DvmAdminFrontendFr.headerText,
                                                                        key: column
                                                                      });
                                                          }))),
                                                className: App_Table_Head$DvmAdminFrontendFr.wholeRow
                                              })
                                        }), React.createElement(Core.TableBody, {
                                          children: Belt_List.toArray(Belt_List.mapWithIndex(results, (function (rowIndex, row) {
                                                      return React.createElement(Core.TableRow, {
                                                                  children: null,
                                                                  key: "row" + String(rowIndex)
                                                                }, React.createElement(Core.TableCell, {
                                                                      children: React.createElement("div", {
                                                                            className: App_Table_Styles$DvmAdminFrontendFr.tableCellContent
                                                                          }, React.createElement(Core.Grid, {
                                                                                children: React.createElement(Core.Grid, {
                                                                                      children: row.rowNumber,
                                                                                      item: true
                                                                                    }),
                                                                                container: true,
                                                                                spacing: 2
                                                                              })),
                                                                      className: App_Table_Styles$DvmAdminFrontendFr.tableCell(undefined, undefined),
                                                                      key: "rowNumber"
                                                                    }), Belt_Option.mapWithDefault(Belt_List.get(row.errors, 0), null, (function (error) {
                                                                        return React.createElement(Core.TableCell, {
                                                                                    children: React.createElement("div", {
                                                                                          className: App_Table_Styles$DvmAdminFrontendFr.tableCellContent
                                                                                        }, React.createElement(Core.Grid, {
                                                                                              children: React.createElement(Core.Grid, {
                                                                                                    children: File_Upload_Types$DvmAdminFrontendFr.errorToMessage(error.errorType, intl),
                                                                                                    item: true
                                                                                                  }),
                                                                                              container: true,
                                                                                              spacing: 2
                                                                                            })),
                                                                                    className: App_Table_Styles$DvmAdminFrontendFr.tableCell(undefined, undefined),
                                                                                    key: "cell" + String(rowIndex)
                                                                                  });
                                                                      })));
                                                    })))
                                        })),
                                className: App_Table_Styles$DvmAdminFrontendFr.table,
                                style: {
                                  height: "50vh"
                                }
                              });
                  }
                }));
  };
  return Belt_Option.mapWithDefault(checkWithStageResult.corruptedObjects, checksResult(undefined), (function (errorRows) {
                if (Belt_List.length(errorRows) > 0) {
                  return React.createElement(React.Fragment, undefined, React.createElement(Core.TableContainer, {
                                  children: React.createElement(Core.Table, {
                                        children: null,
                                        stickyHeader: true
                                      }, React.createElement(Core.TableHead, {
                                            children: React.createElement(Core.TableRow, {
                                                  children: Belt_List.toArray(Belt_List.map(!foundErrors(checkWithStageResult) && foundWarnings(checkWithStageResult) ? ({
                                                                hd: "Alerte",
                                                                tl: /* [] */0
                                                              }) : ({
                                                                hd: "Erreur",
                                                                tl: /* [] */0
                                                              }), (function (column) {
                                                              return React.createElement(Core.TableCell, {
                                                                          children: column,
                                                                          className: App_Table_Head$DvmAdminFrontendFr.headerText,
                                                                          key: column
                                                                        });
                                                            }))),
                                                  className: App_Table_Head$DvmAdminFrontendFr.wholeRow
                                                })
                                          }), React.createElement(Core.TableBody, {
                                            children: Belt_List.toArray(Belt_List.mapWithIndex(errorRows, (function (rowIndex, row) {
                                                        return React.createElement(Core.TableRow, {
                                                                    children: React.createElement(Core.TableCell, {
                                                                          children: React.createElement("div", {
                                                                                className: App_Table_Styles$DvmAdminFrontendFr.tableCellContent
                                                                              }, React.createElement(Core.Grid, {
                                                                                    children: React.createElement(Core.Grid, {
                                                                                          children: Quota_PARC_Manage_Utils$DvmAdminFrontendFr.makeStringFromConflict(row, intl),
                                                                                          item: true
                                                                                        }),
                                                                                    container: true,
                                                                                    spacing: 2
                                                                                  })),
                                                                          className: App_Table_Styles$DvmAdminFrontendFr.tableCell(undefined, undefined),
                                                                          key: "cell" + String(rowIndex)
                                                                        }),
                                                                    key: "row" + String(rowIndex)
                                                                  });
                                                      })))
                                          })),
                                  className: App_Table_Styles$DvmAdminFrontendFr.table,
                                  style: {
                                    height: "50vh"
                                  }
                                }), !foundErrors(checkWithStageResult) && foundWarnings(checkWithStageResult) ? React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                    children: intl.formatMessage(Messages_Quotas_PARC$DvmAdminFrontendFr.overlapsWillBeDeleted),
                                    variant: "bodyBig"
                                  }) : null);
                } else {
                  return checksResult(undefined);
                }
              }));
}

var Types;

var Styles;

var make = File_Upload_Excel_Check_Stage_Table_Parc;

export {
  Types ,
  Styles ,
  reFirstToString ,
  tryParseError ,
  getValByIndex ,
  tryParseErrorParcQuota ,
  foundErrors ,
  foundWarnings ,
  make ,
}
/* react Not a pure module */
