// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";

var textArea = Css.css({
      padding: "8px 25px 18px 16px",
      width: "100%"
    });

function App_TextArea(Props) {
  var classNameOpt = Props.className;
  var disabledOpt = Props.disabled;
  var onChange = Props.onChange;
  var rowsOpt = Props.rows;
  var style = Props.style;
  var valueOpt = Props.value;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var rows = rowsOpt !== undefined ? rowsOpt : 3;
  var value = valueOpt !== undefined ? valueOpt : "-";
  var tmp = {
    className: Css.cx([
          textArea,
          className
        ]),
    disabled: disabled,
    rows: rows,
    value: value,
    onChange: onChange
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  return React.createElement("textarea", tmp);
}

var make = App_TextArea;

export {
  textArea ,
  make ,
}
/* textArea Not a pure module */
