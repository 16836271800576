// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Css from "@emotion/css";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../common/components/icons/App_Icon.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../common/components/button/App_Button.bs.js";
import * as App_Styles$DvmAdminFrontendFr from "../../common/App_Styles.bs.js";
import * as Simple_Menu$DvmAdminFrontendFr from "../../common/components/menu/Simple_Menu.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../theme/Theme_Colors.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../intl/messages/Messages_Button.bs.js";
import * as Bonuses_Manage_Modal$DvmAdminFrontendFr from "./modal/Bonuses_Manage_Modal.bs.js";
import * as Messages_Bonuses_Manage$DvmAdminFrontendFr from "../../intl/messages/bonuses/Messages_Bonuses_Manage.bs.js";

var titleBox = Css.css({
      backgroundColor: "white",
      color: "black",
      marginTop: "20px",
      minHeight: "40px",
      padding: "19px 25px",
      boxShadow: "0 -4px 6px 2px rgba(0, 0, 0, 0.2)"
    });

var popMenuItem = Css.css(Object.assign({}, {
          backgroundColor: Theme_Colors$DvmAdminFrontendFr.black,
          color: Theme_Colors$DvmAdminFrontendFr.white
        }, {
          "&:hover": {
            backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayDark4
          }
        }));

function Bonuses_Search_Footer(Props) {
  var selectedRowsMap = Props.selectedRowsMap;
  var $$fetch = Props.fetch;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return null;
      });
  var setModal = match[1];
  var allToValidate = Belt_MapString.every(selectedRowsMap, (function (param, row) {
          return row.status === /* TOVALIDATE */8;
        }));
  var notAllowedToModify = Belt_MapString.every(selectedRowsMap, (function (param, row) {
          if (row.status === /* PAID */4 || row.status === /* PREINVOICED */5 || row.status === /* NOBONUS */3 || row.status === /* BLOCKED */0 || row.status === /* CALCULABLE */1) {
            return true;
          } else {
            return row.status === /* CANCELED */2;
          }
        }));
  var noDealerHasMore = Belt_MapString.every(selectedRowsMap, (function (param, row) {
          return row.dealerHasMore === false;
        }));
  var canMoveToValidate = Belt_MapString.every(selectedRowsMap, (function (param, row) {
          return Belt_Option.isSome(row.approved);
        }));
  var canValidate = allToValidate && noDealerHasMore && !notAllowedToModify;
  var openModal = function (action) {
    Curry._1(setModal, (function (param) {
            return React.createElement(Bonuses_Manage_Modal$DvmAdminFrontendFr.make, {
                        rows: Belt_List.fromArray(Belt_MapString.valuesToArray(selectedRowsMap)),
                        onClose: (function (param) {
                            Curry._1(setModal, (function (param) {
                                    return null;
                                  }));
                          }),
                        onOk: (function (param) {
                            Curry._1(setModal, (function (param) {
                                    return null;
                                  }));
                            Curry._1($$fetch, undefined);
                          }),
                        action: action
                      });
          }));
  };
  return React.createElement(React.Fragment, undefined, React.createElement(Core.AppBar, {
                  children: React.createElement(Core.Grid, {
                        children: null,
                        className: titleBox,
                        container: true,
                        justifyContent: "space-between",
                        wrap: "nowrap"
                      }, React.createElement(Core.Grid, {
                            alignItems: "center",
                            children: null,
                            container: true,
                            item: true,
                            spacing: 2
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                      style: {
                                        fontWeight: "600"
                                      },
                                      children: intl.formatMessage(Messages_Bonuses_Manage$DvmAdminFrontendFr.numberSelected, {
                                            number: Belt_MapString.size(selectedRowsMap)
                                          }),
                                      variant: "bodyBig"
                                    }),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: React.createElement("div", {
                                      style: {
                                        backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayDark2,
                                        height: "40px",
                                        width: "1px"
                                      }
                                    }),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                      style: {
                                        fontWeight: "600"
                                      },
                                      children: intl.formatMessage(Messages_Bonuses_Manage$DvmAdminFrontendFr.totalBonusAmount, {
                                            amountEuro: Belt_Array.reduce(Belt_MapString.valuesToArray(selectedRowsMap), 0.0, (function (a, b) {
                                                    return a + b.actualAmount;
                                                  }))
                                          }),
                                      variant: "bodyBig"
                                    }),
                                item: true
                              })), React.createElement(Core.Grid, {
                            alignItems: "center",
                            children: null,
                            container: true,
                            item: true,
                            justifyContent: "flex-end",
                            spacing: 2
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                      onClick: (function (param) {
                                          openModal("refuse");
                                        }),
                                      label: intl.formatMessage(Messages_Bonuses_Manage$DvmAdminFrontendFr.refuseBonus),
                                      color: "delete",
                                      size: "small",
                                      disabled: Belt_MapString.size(selectedRowsMap) > 1 || !allToValidate || notAllowedToModify
                                    }),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                      onClick: (function (param) {
                                          openModal("validate");
                                        }),
                                      label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                      size: "small",
                                      disabled: !canValidate
                                    }),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: React.createElement("div", {
                                      style: {
                                        backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayDark2,
                                        height: "40px",
                                        width: "1px"
                                      }
                                    }),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(Simple_Menu$DvmAdminFrontendFr.make, {
                                      children: null,
                                      title: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                            size: /* XLarge */4,
                                            type_: /* Edit */60
                                          }),
                                      disabled: Belt_MapString.size(selectedRowsMap) !== 1 || notAllowedToModify,
                                      horizontalAnchor: "right",
                                      verticalAnchor: "top"
                                    }, React.createElement(Core.MenuItem, {
                                          children: intl.formatMessage(Messages_Bonuses_Manage$DvmAdminFrontendFr.modifyStatus),
                                          className: popMenuItem,
                                          disabled: !canMoveToValidate,
                                          onClick: (function (param) {
                                              openModal("statusChange");
                                            })
                                        }), React.createElement(Core.MenuItem, {
                                          children: intl.formatMessage(Messages_Bonuses_Manage$DvmAdminFrontendFr.modifyAmount),
                                          className: popMenuItem,
                                          onClick: (function (param) {
                                              openModal("newAmount");
                                            })
                                        })),
                                item: true,
                                justifyContent: "center"
                              }))),
                  className: App_Styles$DvmAdminFrontendFr.footerBar
                }), match[0]);
}

var Types;

var make = Bonuses_Search_Footer;

export {
  Types ,
  titleBox ,
  popMenuItem ,
  make ,
}
/* titleBox Not a pure module */
