// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Messages_TopMenu$DvmAdminFrontendFr from "../../../intl/messages/topMenu/Messages_TopMenu.bs.js";
import * as App_Table_Create_Button$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Create_Button.bs.js";
import * as Visibility_Profiles_Api$DvmAdminFrontendFr from "./Visibility_Profiles_Api.bs.js";
import * as Visibility_Profiles_List$DvmAdminFrontendFr from "./Visibility_Profiles_List.bs.js";
import * as Visibility_Profiles_Modal$DvmAdminFrontendFr from "../modal/Visibility_Profiles_Modal.bs.js";
import * as Visibility_Profiles_Filter$DvmAdminFrontendFr from "./Visibility_Profiles_Filter.bs.js";
import * as Messages_Visibility_Profiles$DvmAdminFrontendFr from "../../../intl/messages/visibilityProfiles/Messages_Visibility_Profiles.bs.js";

function Visibility_Profiles(Props) {
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setVisibilityProfiles = match$2[1];
  var visibilityProfiles = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setAllBIRs = match$3[1];
  var allBIRs = match$3[0];
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var ipn = match$4[0];
  var match$5 = React.useState(function () {
        return /* [] */0;
      });
  var dealers = match$5[0];
  var intl = ReactIntl.useIntl();
  var match$6 = React.useState(function () {
        return null;
      });
  var setModal = match$6[1];
  var refresh = function (param) {
    Visibility_Profiles_Api$DvmAdminFrontendFr.fetchVisibilityProfiles(setVisibilityProfiles, userManager, selectedRole);
  };
  React.useEffect((function () {
          Visibility_Profiles_Api$DvmAdminFrontendFr.fetchVisibilityProfiles(setVisibilityProfiles, userManager, selectedRole);
          Visibility_Profiles_Api$DvmAdminFrontendFr.fetchAllBIR(setAllBIRs, userManager, selectedRole);
        }), []);
  var openModal = function (profile) {
    Curry._1(setModal, (function (param) {
            var tmp = {
              allBIRs: App_Types_Result$DvmAdminFrontendFr.getWithDefault(allBIRs, /* [] */0),
              onClose: (function (param) {
                  Curry._1(setModal, (function (param) {
                          return null;
                        }));
                }),
              refresh: refresh
            };
            if (profile !== undefined) {
              tmp.profile = Caml_option.valFromOption(profile);
            }
            return React.createElement(Visibility_Profiles_Modal$DvmAdminFrontendFr.make, tmp);
          }));
  };
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  spacing: 3
                }, React.createElement(Core.Grid, {
                      children: React.createElement(Core.Grid, {
                            children: React.createElement(Core.Typography, {
                                  children: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.visibilityProfiles),
                                  variant: "h3",
                                  style: {
                                    fontWeight: "600"
                                  }
                                }),
                            item: true
                          }),
                      container: true,
                      item: true,
                      justifyContent: "space-between"
                    }), React.createElement(Core.Grid, {
                      alignItems: "flex-end",
                      children: null,
                      container: true,
                      item: true,
                      wrap: "nowrap"
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(Visibility_Profiles_Filter$DvmAdminFrontendFr.make, {
                                ipn: ipn,
                                setIpn: match$4[1],
                                dealers: dealers,
                                setDealers: match$5[1],
                                visibilityProfiles: App_Types_Result$DvmAdminFrontendFr.getWithDefault(visibilityProfiles, /* [] */0)
                              }),
                          container: true,
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(App_Table_Create_Button$DvmAdminFrontendFr.make, {
                                onClick: (function (param) {
                                    openModal(undefined);
                                  }),
                                message: Messages_Visibility_Profiles$DvmAdminFrontendFr.modalTitleAdd
                              }),
                          item: true
                        })), React.createElement(Core.Grid, {
                      children: React.createElement(Visibility_Profiles_List$DvmAdminFrontendFr.make, {
                            ipn: ipn,
                            dealers: dealers,
                            visibilityProfiles: App_Types_Result$DvmAdminFrontendFr.getWithDefault(visibilityProfiles, /* [] */0),
                            isPending: App_Types_Result$DvmAdminFrontendFr.isPending(visibilityProfiles),
                            openModal: openModal
                          }),
                      item: true
                    })), match$6[0]);
}

var Filter;

var List;

var make = Visibility_Profiles;

export {
  Filter ,
  List ,
  make ,
}
/* react Not a pure module */
