// Generated by ReScript, PLEASE EDIT WITH CARE


var title = {
  id: "3cb1c957-d005-4aa3-a7c9-4a49d1386955",
  defaultMessage: "Rechercher une action commerciale"
};

var flashMarketingActionID = {
  id: "5f1565d3-4329-4158-b2d7-b01ad0621472",
  defaultMessage: "Code Action"
};

var bonusLabel = {
  id: "4c5f2a12-eb60-4bfe-843e-c22490fe9157",
  defaultMessage: "Libellé AC"
};

var bonusType = {
  id: "3782d5a5-c7ba-4f4d-b5b4-9b56e1d66b0f",
  defaultMessage: "Type AC"
};

var resultInfo = {
  id: "3559167f-fd42-46a1-adcc-dae51d68b639",
  defaultMessage: "actions commerciales"
};

var fleetTable = {
  id: "c5c9041f-2052-4655-bbfa-95e42029b97b",
  defaultMessage: "Flotte"
};

var nonFleetTable = {
  id: "bccc9898-d0dd-4e9c-9cd5-45f9ca1bbac6",
  defaultMessage: "DMF/ PARC"
};

var searchForAction = {
  id: "c1925e87-e3e3-4827-bcc6-9ef2877777bf",
  defaultMessage: "Vous devez sélectionner un type de commande avant de lancer une recherche d'action commerciale"
};

var numberValFrom = {
  id: "b3f10ed6-6316-4409-abf0-4d3eb644a96e",
  defaultMessage: "De"
};

var numberValTo = {
  id: "691ff28c-80d6-4552-927f-8f4ae00d544e",
  defaultMessage: "À"
};

var detentionDurationMin = {
  id: "45607855-12b6-4508-a21e-221883fc5173",
  defaultMessage: "Durée de détention min (en jours)"
};

var detentionDurationMax = {
  id: "47ab1b17-4b4c-4501-920b-2e010ccd6af0",
  defaultMessage: "Durée de détention max (en jours)"
};

var ordetTypeOnlyFleetOrNonfleet = {
  id: "5424873d-6c44-4d33-a881-9b38ba4836a9",
  defaultMessage: "Le type de commande SO ne peut être selectionné avec aucun autre type de commande"
};

var startDateFrom = {
  id: "991bc082-da0f-4f84-94d6-7aae1929d06b",
  defaultMessage: "Date début de"
};

var startDateTo = {
  id: "0f798a2f-4828-40d0-9dae-43c18be250ac",
  defaultMessage: "Date début à"
};

var endDateFrom = {
  id: "ede97e54-1dac-4593-b944-4a9672adcdf9",
  defaultMessage: "Date fin de"
};

var endDateTo = {
  id: "4b91d043-db2e-4d0a-a18e-5e853342e698",
  defaultMessage: "Date fin à"
};

export {
  title ,
  flashMarketingActionID ,
  bonusLabel ,
  bonusType ,
  resultInfo ,
  fleetTable ,
  nonFleetTable ,
  searchForAction ,
  numberValFrom ,
  numberValTo ,
  detentionDurationMin ,
  detentionDurationMax ,
  ordetTypeOnlyFleetOrNonfleet ,
  startDateFrom ,
  startDateTo ,
  endDateFrom ,
  endDateTo ,
}
/* No side effect */
