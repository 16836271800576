// Generated by ReScript, PLEASE EDIT WITH CARE


var title = {
  id: "449318e1-0c99-46b3-b5aa-74df3e67b446",
  defaultMessage: "Rechercher un intervenant"
};

var managerIPN = {
  id: "ac6f77f6-fa04-4e10-ac38-9cd7f7f0bd3c",
  defaultMessage: "IPN Intervenant"
};

var managerName = {
  id: "efff57c6-f494-4ad1-8309-4611b58845ef",
  defaultMessage: "Nom Intervenant"
};

var endingDate = {
  id: "f4a84721-e7ef-4938-98ed-e39f3a3c4e13",
  defaultMessage: "Date de suppression"
};

var resultManagersLabel = {
  id: "f1328990-01a2-40b9-9bfa-8a9f4483dc0d",
  defaultMessage: "intervenants"
};

var showManagerHistory = {
  id: "f640f424-4afb-4c5f-b582-bc5d8d331949",
  defaultMessage: "Historique des intervenants"
};

export {
  title ,
  managerIPN ,
  managerName ,
  endingDate ,
  resultManagersLabel ,
  showManagerHistory ,
}
/* No side effect */
