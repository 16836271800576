// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "./App_Types_Date.bs.js";

function parentContent_encode(v) {
  return Js_dict.fromArray([
              [
                "parent",
                Decco.stringToJson(v.parent)
              ],
              [
                "child",
                Decco.listToJson(Decco.stringToJson, v.child)
              ]
            ]);
}

function parentContent_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var parent = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "parent"), null));
  if (parent.TAG === /* Ok */0) {
    var child = Decco.listFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "child"), null));
    if (child.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                parent: parent._0,
                child: child._0
              }
            };
    }
    var e = child._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".child" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = parent._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".parent" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function parentChildList_encode(v) {
  return Decco.listToJson(parentContent_encode, v);
}

function parentChildList_decode(v) {
  return Decco.listFromJson(parentContent_decode, v);
}

function stringList_encode(v) {
  return Decco.listToJson(Decco.stringToJson, v);
}

function stringList_decode(v) {
  return Decco.listFromJson(Decco.stringFromJson, v);
}

function twoParams_encode(v) {
  return Js_dict.fromArray([
              [
                "paramOne",
                Decco.stringToJson(v.paramOne)
              ],
              [
                "paramTwo",
                Decco.optionToJson(Decco.stringToJson, v.paramTwo)
              ]
            ]);
}

function twoParams_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var paramOne = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "paramOne"), null));
  if (paramOne.TAG === /* Ok */0) {
    var paramTwo = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "paramTwo"), null));
    if (paramTwo.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                paramOne: paramOne._0,
                paramTwo: paramTwo._0
              }
            };
    }
    var e = paramTwo._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".paramTwo" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = paramOne._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".paramOne" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function twoParamList_encode(v) {
  return Decco.listToJson(twoParams_encode, v);
}

function twoParamList_decode(v) {
  return Decco.listFromJson(twoParams_decode, v);
}

function siren_encode(v) {
  return Js_dict.fromArray([[
                "response",
                Decco.stringToJson(v.response)
              ]]);
}

function siren_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var response = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "response"), null));
  if (response.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              response: response._0
            }
          };
  }
  var e = response._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".response" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function t_encode(v) {
  return Js_dict.fromArray([
              [
                "categoryID",
                Decco.stringToJson(v.categoryID)
              ],
              [
                "name",
                Decco.optionToJson(Decco.stringToJson, v.name)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var categoryID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "categoryID"), null));
  if (categoryID.TAG === /* Ok */0) {
    var name = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "name"), null));
    if (name.TAG === /* Ok */0) {
      var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
      if (startDate.TAG === /* Ok */0) {
        var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
        if (endDate.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    categoryID: categoryID._0,
                    name: name._0,
                    startDate: startDate._0,
                    endDate: endDate._0
                  }
                };
        }
        var e = endDate._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".endDate" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = startDate._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".startDate" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = name._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".name" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = categoryID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".categoryID" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function categoryList_encode(v) {
  return Decco.listToJson(t_encode, v);
}

function categoryList_decode(v) {
  return Decco.listFromJson(t_decode, v);
}

var DropdownCategories = {
  t_encode: t_encode,
  t_decode: t_decode,
  categoryList_encode: categoryList_encode,
  categoryList_decode: categoryList_decode
};

export {
  parentContent_encode ,
  parentContent_decode ,
  parentChildList_encode ,
  parentChildList_decode ,
  stringList_encode ,
  stringList_decode ,
  twoParams_encode ,
  twoParams_decode ,
  twoParamList_encode ,
  twoParamList_decode ,
  siren_encode ,
  siren_decode ,
  DropdownCategories ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
