// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";

var Fields = {
  siret: "siret",
  siren: "siren",
  name: "name",
  budgetOne: "budgetOne",
  budgetTwo: "budgetTwo",
  street: "street",
  streetNbr: "streetNbr",
  additionalAddressInfo: "additionalAddressInfo",
  zipCode: "zipCode",
  city: "city",
  countryCode: "countryCode",
  endDate: "endDate"
};

function row_encode(v) {
  return Js_dict.fromArray([
              [
                "siret",
                Decco.stringToJson(v.siret)
              ],
              [
                "siren",
                Decco.optionToJson(Decco.stringToJson, v.siren)
              ],
              [
                "name",
                Decco.optionToJson(Decco.stringToJson, v.name)
              ],
              [
                "street",
                Decco.optionToJson(Decco.stringToJson, v.street)
              ],
              [
                "streetNbr",
                Decco.optionToJson(Decco.stringToJson, v.streetNbr)
              ],
              [
                "additionalAddressInfo",
                Decco.optionToJson(Decco.stringToJson, v.additionalAddressInfo)
              ],
              [
                "zipCode",
                Decco.optionToJson(Decco.stringToJson, v.zipCode)
              ],
              [
                "city",
                Decco.optionToJson(Decco.stringToJson, v.city)
              ],
              [
                "countryCode",
                Decco.optionToJson(Decco.stringToJson, v.countryCode)
              ],
              [
                "budgetOne",
                Decco.optionToJson(Decco.stringToJson, v.budgetOne)
              ],
              [
                "budgetTwo",
                Decco.optionToJson(Decco.stringToJson, v.budgetTwo)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function row_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var siret = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "siret"), null));
  if (siret.TAG === /* Ok */0) {
    var siren = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "siren"), null));
    if (siren.TAG === /* Ok */0) {
      var name = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "name"), null));
      if (name.TAG === /* Ok */0) {
        var street = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "street"), null));
        if (street.TAG === /* Ok */0) {
          var streetNbr = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "streetNbr"), null));
          if (streetNbr.TAG === /* Ok */0) {
            var additionalAddressInfo = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "additionalAddressInfo"), null));
            if (additionalAddressInfo.TAG === /* Ok */0) {
              var zipCode = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "zipCode"), null));
              if (zipCode.TAG === /* Ok */0) {
                var city = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "city"), null));
                if (city.TAG === /* Ok */0) {
                  var countryCode = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "countryCode"), null));
                  if (countryCode.TAG === /* Ok */0) {
                    var budgetOne = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "budgetOne"), null));
                    if (budgetOne.TAG === /* Ok */0) {
                      var budgetTwo = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "budgetTwo"), null));
                      if (budgetTwo.TAG === /* Ok */0) {
                        var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
                        if (endDate.TAG === /* Ok */0) {
                          return {
                                  TAG: /* Ok */0,
                                  _0: {
                                    siret: siret._0,
                                    siren: siren._0,
                                    name: name._0,
                                    street: street._0,
                                    streetNbr: streetNbr._0,
                                    additionalAddressInfo: additionalAddressInfo._0,
                                    zipCode: zipCode._0,
                                    city: city._0,
                                    countryCode: countryCode._0,
                                    budgetOne: budgetOne._0,
                                    budgetTwo: budgetTwo._0,
                                    endDate: endDate._0
                                  }
                                };
                        }
                        var e = endDate._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".endDate" + e.path,
                                  message: e.message,
                                  value: e.value
                                }
                              };
                      }
                      var e$1 = budgetTwo._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".budgetTwo" + e$1.path,
                                message: e$1.message,
                                value: e$1.value
                              }
                            };
                    }
                    var e$2 = budgetOne._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".budgetOne" + e$2.path,
                              message: e$2.message,
                              value: e$2.value
                            }
                          };
                  }
                  var e$3 = countryCode._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".countryCode" + e$3.path,
                            message: e$3.message,
                            value: e$3.value
                          }
                        };
                }
                var e$4 = city._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".city" + e$4.path,
                          message: e$4.message,
                          value: e$4.value
                        }
                      };
              }
              var e$5 = zipCode._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".zipCode" + e$5.path,
                        message: e$5.message,
                        value: e$5.value
                      }
                    };
            }
            var e$6 = additionalAddressInfo._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".additionalAddressInfo" + e$6.path,
                      message: e$6.message,
                      value: e$6.value
                    }
                  };
          }
          var e$7 = streetNbr._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".streetNbr" + e$7.path,
                    message: e$7.message,
                    value: e$7.value
                  }
                };
        }
        var e$8 = street._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".street" + e$8.path,
                  message: e$8.message,
                  value: e$8.value
                }
              };
      }
      var e$9 = name._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".name" + e$9.path,
                message: e$9.message,
                value: e$9.value
              }
            };
    }
    var e$10 = siren._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".siren" + e$10.path,
              message: e$10.message,
              value: e$10.value
            }
          };
  }
  var e$11 = siret._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".siret" + e$11.path,
            message: e$11.message,
            value: e$11.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(row_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(row_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function contractDateItem_encode(v) {
  return Js_dict.fromArray([
              [
                "label",
                Decco.optionToJson(Decco.stringToJson, v.label)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function contractDateItem_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var label = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "label"), null));
  if (label.TAG === /* Ok */0) {
    var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
    if (startDate.TAG === /* Ok */0) {
      var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
      if (endDate.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  label: label._0,
                  startDate: startDate._0,
                  endDate: endDate._0
                }
              };
      }
      var e = endDate._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".endDate" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = startDate._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".startDate" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = label._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".label" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function contractItem_encode(v) {
  return Js_dict.fromArray([
              [
                "contractID",
                Decco.stringToJson(v.contractID)
              ],
              [
                "dates",
                Decco.listToJson(contractDateItem_encode, v.dates)
              ]
            ]);
}

function contractItem_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var contractID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractID"), null));
  if (contractID.TAG === /* Ok */0) {
    var dates = Decco.listFromJson(contractDateItem_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "dates"), null));
    if (dates.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                contractID: contractID._0,
                dates: dates._0
              }
            };
    }
    var e = dates._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".dates" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = contractID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".contractID" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function contracts_encode(v) {
  return Decco.listToJson(contractItem_encode, v);
}

function contracts_decode(v) {
  return Decco.listFromJson(contractItem_decode, v);
}

export {
  Fields ,
  row_encode ,
  row_decode ,
  searchResult_encode ,
  searchResult_decode ,
  contractDateItem_encode ,
  contractDateItem_decode ,
  contractItem_encode ,
  contractItem_decode ,
  contracts_encode ,
  contracts_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
