// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Messages_Error$DvmAdminFrontendFr from "../../intl/messages/Messages_Error.bs.js";

function map(result, mapFunction) {
  if (typeof result === "number") {
    if (result === /* NotStarted */0) {
      return /* NotStarted */0;
    } else {
      return /* Pending */1;
    }
  } else if (result.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: Curry._1(mapFunction, result._0)
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: result._0
          };
  }
}

function mapWithDefault(result, defaultValue, mapFunction) {
  if (typeof result === "number" || result.TAG !== /* Ok */0) {
    return defaultValue;
  } else {
    return Curry._1(mapFunction, result._0);
  }
}

function mapErrorWithDefault(result, defaultValue, mapFunction) {
  if (typeof result === "number" || result.TAG !== /* Error */1) {
    return defaultValue;
  } else {
    return Curry._1(mapFunction, result._0);
  }
}

function getWithDefault(result, defaultValue) {
  return mapWithDefault(result, defaultValue, (function (value) {
                return value;
              }));
}

function isPending(result) {
  if (typeof result === "number") {
    return result !== 0;
  } else {
    return false;
  }
}

function isSuccess(result) {
  return mapWithDefault(result, false, (function (param) {
                return true;
              }));
}

function isError(result) {
  return mapErrorWithDefault(result, false, (function (param) {
                return true;
              }));
}

function forSuccess(result, callback) {
  if (typeof result === "number" || result.TAG !== /* Ok */0) {
    return ;
  } else {
    return Curry._1(callback, result._0);
  }
}

function forError(result, callback) {
  if (typeof result === "number" || result.TAG !== /* Error */1) {
    return ;
  } else {
    return Curry._1(callback, result._0);
  }
}

function toOption(result) {
  if (typeof result === "number" || result.TAG !== /* Ok */0) {
    return ;
  } else {
    return Caml_option.some(result._0);
  }
}

function error_encode(v) {
  return Js_dict.fromArray([
              [
                "error",
                Decco.stringToJson(v.messageId)
              ],
              [
                "arguments",
                Decco.optionToJson((function (param) {
                        return Decco.dictToJson(Decco.stringToJson, param);
                      }), v.arguments)
              ],
              [
                "message",
                Decco.optionToJson(Decco.stringToJson, v.message)
              ],
              [
                "requestParams",
                Decco.optionToJson(Decco.stringToJson, v.requestParams)
              ]
            ]);
}

function error_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var messageId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "error"), null));
  if (messageId.TAG === /* Ok */0) {
    var $$arguments = Decco.optionFromJson((function (param) {
            return Decco.dictFromJson(Decco.stringFromJson, param);
          }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "arguments"), null));
    if ($$arguments.TAG === /* Ok */0) {
      var message = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "message"), null));
      if (message.TAG === /* Ok */0) {
        var requestParams = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "requestParams"), null));
        if (requestParams.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    messageId: messageId._0,
                    arguments: $$arguments._0,
                    message: message._0,
                    requestParams: requestParams._0
                  }
                };
        }
        var e = requestParams._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".requestParams" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = message._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".message" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = $$arguments._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".arguments" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = messageId._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: "." + ("error" + e$3.path),
            message: e$3.message,
            value: e$3.value
          }
        };
}

function makeError($$arguments, message, requestParams, messageId) {
  return {
          messageId: messageId,
          arguments: $$arguments,
          message: message,
          requestParams: requestParams
        };
}

function toMessage(param) {
  return [
          {
            id: param.messageId,
            defaultMessage: Messages_Error$DvmAdminFrontendFr.backEndUnexpectedError.defaultMessage
          },
          param.arguments
        ];
}

var Rest = {
  error_encode: error_encode,
  error_decode: error_decode,
  makeError: makeError,
  toMessage: toMessage
};

var $$Date;

export {
  $$Date ,
  map ,
  mapWithDefault ,
  mapErrorWithDefault ,
  getWithDefault ,
  isPending ,
  isSuccess ,
  isError ,
  forSuccess ,
  forError ,
  toOption ,
  Rest ,
}
/* No side effect */
