// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";

function Order_Detail_Bonus_DealerRequest_FileUpload_Button(Props) {
  var addFile = Props.addFile;
  return React.createElement(React.Fragment, undefined, React.createElement("input", {
                  id: "contained-button-file",
                  style: {
                    display: "none"
                  },
                  accept: ".pdf",
                  type: "file",
                  value: "",
                  onChange: (function ($$event) {
                      var file = Belt_Array.get($$event.currentTarget.files, 0);
                      if (file !== undefined) {
                        return Curry._1(addFile, Caml_option.valFromOption(file));
                      }
                      
                    })
                }), React.createElement("label", {
                  htmlFor: "contained-button-file"
                }, React.createElement(Core.Button, {
                      children: "+",
                      color: "primary",
                      component: "span",
                      size: "medium",
                      variant: "contained"
                    })));
}

var make = Order_Detail_Bonus_DealerRequest_FileUpload_Button;

export {
  make ,
}
/* react Not a pure module */
