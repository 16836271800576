// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../common/components/App_Modal.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Messages_Error$DvmAdminFrontendFr from "../../../intl/messages/Messages_Error.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../common/components/App_LabeledText.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as Bonuses_Manage_Amount$DvmAdminFrontendFr from "./Bonuses_Manage_Amount.bs.js";
import * as Bonuses_Manage_Refuse$DvmAdminFrontendFr from "./Bonuses_Manage_Refuse.bs.js";
import * as Bonuses_Manage_Status$DvmAdminFrontendFr from "./Bonuses_Manage_Status.bs.js";
import * as Messages_Bonuses_List$DvmAdminFrontendFr from "../../../intl/messages/bonuses/Messages_Bonuses_List.bs.js";
import * as Bonuses_Manage_Validate$DvmAdminFrontendFr from "./Bonuses_Manage_Validate.bs.js";
import * as Messages_Bonuses_Manage$DvmAdminFrontendFr from "../../../intl/messages/bonuses/Messages_Bonuses_Manage.bs.js";
import * as Bonuses_Manage_Modal_Api$DvmAdminFrontendFr from "./Bonuses_Manage_Modal_Api.bs.js";

function Bonuses_Manage_Modal(Props) {
  var rows = Props.rows;
  var onClose = Props.onClose;
  var onOk = Props.onOk;
  var action = Props.action;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var alert = ReactAlert.useAlert();
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$2[1];
  var result = match$2[0];
  var manageCall = function (operation, reason, param) {
    Bonuses_Manage_Modal_Api$DvmAdminFrontendFr.changeBonus(userManager, setResult, Belt_List.map(rows, (function (x) {
                return {
                        actionID: x.actionId,
                        orderID: x.orderId,
                        editedAmount: undefined,
                        refuseReason: reason,
                        approved: operation !== 1 ? (
                            operation !== 0 ? undefined : "TRUE"
                          ) : "FALSE"
                      };
              })), operation, intl, selectedRole);
  };
  var manageCallAmount = function (amount) {
    var bonus = Belt_List.get(Belt_List.map(rows, (function (x) {
                return {
                        actionID: x.actionId,
                        orderID: x.orderId,
                        editedAmount: amount,
                        refuseReason: undefined,
                        approved: undefined
                      };
              })), 0);
    if (bonus !== undefined) {
      return Bonuses_Manage_Modal_Api$DvmAdminFrontendFr.changeBonusAmount(userManager, setResult, bonus, intl, selectedRole);
    } else {
      return Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* Message */0,
                        _0: Messages_Error$DvmAdminFrontendFr.errorOccured
                      }
                    }));
    }
  };
  React.useEffect((function () {
          if (typeof result === "number") {
            result === /* NotStarted */0;
          } else if (result.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* String */3,
                        _0: result._0
                      }
                    }));
            Curry._1(onOk, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: result._0
                      }
                    }));
          }
        }), [result]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(action === "validate" ? Messages_Bonuses_Manage$DvmAdminFrontendFr.modalValidateTitle : (
                      action === "newAmount" ? Messages_Bonuses_Manage$DvmAdminFrontendFr.modalModifyAmountTitle : (
                          action === "statusChange" ? Messages_Bonuses_Manage$DvmAdminFrontendFr.modalModifyStatusTitle : Messages_Bonuses_Manage$DvmAdminFrontendFr.modalRefuseTitle
                        )
                    )),
              minWidth: "700px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(result),
                    children: null
                  }, React.createElement(Core.Box, {
                        style: {
                          backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight2,
                          padding: "15px 50px"
                        },
                        children: Belt_List.toArray(Belt_List.map(rows, (function (element) {
                                    return React.createElement(Core.Grid, {
                                                children: null,
                                                container: true,
                                                item: true,
                                                justifyContent: "space-between",
                                                spacing: 1
                                              }, React.createElement(Core.Grid, {
                                                    children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                          label: Messages_Bonuses_List$DvmAdminFrontendFr.orderID,
                                                          children: element.orderNumber
                                                        }),
                                                    item: true
                                                  }), React.createElement(Core.Grid, {
                                                    children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                          label: Messages_Common$DvmAdminFrontendFr.budget,
                                                          children: element.budgetType
                                                        }),
                                                    item: true
                                                  }), React.createElement(Core.Grid, {
                                                    children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                          label: Messages_Bonuses_List$DvmAdminFrontendFr.flashLabel,
                                                          children: element.actionLabel
                                                        }),
                                                    item: true
                                                  }), React.createElement(Core.Grid, {
                                                    children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                          label: Messages_Common$DvmAdminFrontendFr.model,
                                                          children: element.model
                                                        }),
                                                    item: true
                                                  }), React.createElement(Core.Grid, {
                                                    children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                          label: Messages_Common$DvmAdminFrontendFr.version,
                                                          children: element.version
                                                        }),
                                                    item: true
                                                  }));
                                  })))
                      }), React.createElement(Core.Box, {
                        style: {
                          padding: "15px 50px"
                        },
                        children: action === "validate" ? React.createElement(Bonuses_Manage_Validate$DvmAdminFrontendFr.make, {
                                manageCall: manageCall
                              }) : (
                            action === "newAmount" ? React.createElement(Bonuses_Manage_Amount$DvmAdminFrontendFr.make, {
                                    rows: rows,
                                    manageCallAmount: manageCallAmount
                                  }) : (
                                action === "statusChange" ? React.createElement(Bonuses_Manage_Status$DvmAdminFrontendFr.make, {
                                        rows: rows,
                                        manageCall: manageCall
                                      }) : React.createElement(Bonuses_Manage_Refuse$DvmAdminFrontendFr.make, {
                                        rows: rows,
                                        manageCall: manageCall
                                      })
                              )
                          )
                      })),
              padding: false
            });
}

var Types;

var Api;

var make = Bonuses_Manage_Modal;

export {
  Types ,
  Api ,
  make ,
}
/* react Not a pure module */
