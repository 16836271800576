// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as Grid_Types$DvmAdminFrontendFr from "../../grids/Grid_Types.bs.js";
import * as Messages_PCDAgreement$DvmAdminFrontendFr from "../../../intl/messages/pcdAgreement/Messages_PCDAgreement.bs.js";
import * as PCDAgreement_Search_Types$DvmAdminFrontendFr from "../search/PCDAgreement_Search_Types.bs.js";

function deleteRow(userManager, setResult, row, intl, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", {
          TAG: /* Json */0,
          _0: PCDAgreement_Search_Types$DvmAdminFrontendFr.PCDRows.pcdRow_encode(row)
        }, userManager, selectedRole, "/agreements").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: intl.formatMessage(Messages_PCDAgreement$DvmAdminFrontendFr.eraseSuccess)
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function deleteThenCreateRow(userManager, setResult, newRow, oldRow, intl, submissionCallbacks, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", {
          TAG: /* Json */0,
          _0: PCDAgreement_Search_Types$DvmAdminFrontendFr.PCDRows.pcdRow_encode(oldRow)
        }, userManager, selectedRole, "/agreements").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
                  TAG: /* Json */0,
                  _0: PCDAgreement_Search_Types$DvmAdminFrontendFr.PCDRows.pcdRow_encode(newRow)
                }, userManager, selectedRole, "/agreements").then(function (result) {
                if (result.TAG === /* OkResult */0) {
                  Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
                  return Curry._1(setResult, (function (param) {
                                return {
                                        TAG: /* Ok */0,
                                        _0: intl.formatMessage(Messages_PCDAgreement$DvmAdminFrontendFr.modifySuccess)
                                      };
                              }));
                }
                var error = result._0;
                Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToUpdatePCDAgreement */0);
                Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Error */1,
                                _0: error
                              };
                      }));
              });
          return ;
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToUpdatePCDAgreement */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchContractIds(userManager, setResult, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", PCDAgreement_Search_Types$DvmAdminFrontendFr.contractsIdsList_decode, undefined, userManager, selectedRole, "/dropdown/contractStartDates").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var contractTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: contractTable
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function getMarginsAVS(userManager, setMargins, selectedRole) {
  Curry._1(setMargins, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Grid_Types$DvmAdminFrontendFr.gridTreeList_decode, undefined, userManager, selectedRole, "/margins/avs/dropdown/categories").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var margins = result._0;
          return Curry._1(setMargins, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: margins
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setMargins, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

export {
  Form ,
  deleteRow ,
  deleteThenCreateRow ,
  fetchContractIds ,
  getMarginsAVS ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
