// Generated by ReScript, PLEASE EDIT WITH CARE


var title = {
  id: "8afd972b-e71c-418f-91f9-9b7fa27ad850",
  defaultMessage: "Définir le montant minimum de marges"
};

var newMinimum = {
  id: "b1218004-5f1a-4978-9728-a2ce3cfca153",
  defaultMessage: "Nouveau montant minimum"
};

var minimum = {
  id: "6e38bb69-47de-4bb3-ab3d-bf80038f1e40",
  defaultMessage: "Montant min."
};

var budgetYear = {
  id: "79c3f1e5-2e88-4dfb-9ffd-fa4744dd9410",
  defaultMessage: "Année Budget"
};

var resultInfo = {
  id: "28940284-bff4-430c-b42a-0df233c96a01",
  defaultMessage: "lignes"
};

export {
  title ,
  newMinimum ,
  minimum ,
  budgetYear ,
  resultInfo ,
}
/* No side effect */
