// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../../common/components/App_Modal.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Result.bs.js";
import * as Flash_Detail_Api$DvmAdminFrontendFr from "../../../../../flash/detail/Flash_Detail_Api.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Orders_DealerRequest$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_DealerRequest.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Create_Api$DvmAdminFrontendFr from "../create/Order_Detail_Bonus_DealerRequest_Create_Api.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Documents_LinkRow$DvmAdminFrontendFr from "./Order_Detail_Bonus_DealerRequest_Documents_LinkRow.bs.js";

function Order_Detail_Bonus_DealerRequest_Documents_Modal(Props) {
  var onClose = Props.onClose;
  var actionId = Props.actionId;
  var documents = Props.documents;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDocumentTypes = match$2[1];
  var documentTypes = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setFetchedFlash = match$3[1];
  var fetchedFlash = match$3[0];
  React.useEffect((function () {
          Belt_Option.mapWithDefault(actionId, undefined, (function (x) {
                  Flash_Detail_Api$DvmAdminFrontendFr.fetchFlash(x, userManager, setFetchedFlash, selectedRole, /* NonFleet */1);
                }));
        }), []);
  React.useEffect((function () {
          if (typeof fetchedFlash !== "number" && fetchedFlash.TAG === /* Ok */0) {
            Order_Detail_Bonus_DealerRequest_Create_Api$DvmAdminFrontendFr.fetchDocumentTypes(userManager, setDocumentTypes, selectedRole, Belt_Option.getWithDefault(fetchedFlash._0.requestType, ""));
          }
          
        }), [fetchedFlash]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Orders_DealerRequest$DvmAdminFrontendFr.viewDocumentsModalTitle, {
                    actionId: actionId
                  }),
              minWidth: "1000px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(documentTypes) || App_Types_Result$DvmAdminFrontendFr.isPending(fetchedFlash),
                    children: React.createElement(Core.Grid, {
                          children: Belt_Array.mapWithIndex(Belt_List.toArray(documents), (function (_index, item) {
                                  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                                                  children: React.createElement(Order_Detail_Bonus_DealerRequest_Documents_LinkRow$DvmAdminFrontendFr.make, {
                                                        item: item,
                                                        documentTypes: documentTypes
                                                      }),
                                                  item: true
                                                }), React.createElement(Core.Grid, {
                                                  children: React.createElement(Core.Divider, {}),
                                                  item: true
                                                }));
                                })),
                          container: true,
                          direction: "column",
                          item: true,
                          spacing: 1
                        })
                  })
            });
}

var Form;

var Api;

var Types;

var make = Order_Detail_Bonus_DealerRequest_Documents_Modal;

export {
  Form ,
  Api ,
  Types ,
  make ,
}
/* react Not a pure module */
