// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Constants$DvmAdminFrontendFr from "../Constants.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "./Utils_Date.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../components/table/App_Table_Head.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../components/table/App_Table_Pagination.bs.js";

function spacesEncode(value) {
  return value.replace(Constants$DvmAdminFrontendFr.Regex.whitespaces, "%20");
}

function stringFieldToQueryParam($staropt$star, param) {
  var value = param.value;
  var exactMatch = $staropt$star !== undefined ? $staropt$star : false;
  if (value === "") {
    return ;
  } else {
    return param.fieldId + (
            exactMatch ? "=" : "⊇"
          ) + spacesEncode(value);
  }
}

function datePairToQueryParam(valueFrom, valueTo, fieldId) {
  var match = Belt_Option.mapWithDefault(valueFrom, undefined, Utils_Date$DvmAdminFrontendFr.toISODateString);
  var match$1 = Belt_Option.mapWithDefault(valueTo, undefined, Utils_Date$DvmAdminFrontendFr.toISODateString);
  if (match !== undefined) {
    if (match$1 !== undefined && (match + match$1).length === 0) {
      return ;
    }
    
  } else if (match$1 === undefined) {
    return ;
  }
  return "" + fieldId + "=%5B" + Belt_Option.getWithDefault(match, "") + "%5C," + Belt_Option.getWithDefault(match$1, "") + "%5D";
}

function pairSelectToQueryParam(field1, field2, param) {
  if (field1 === undefined) {
    return ;
  }
  var value1 = field1.value;
  var fieldId1 = field1.fieldId;
  if (field2 === undefined) {
    if (value1.length === 0) {
      return fieldId1 + "=" + value1;
    } else {
      return ;
    }
  }
  var value2 = field2.value;
  if ((value1 + value2).length === 0) {
    return fieldId1 + "=" + value1 + ";" + field2.fieldId + "=" + value2;
  }
  
}

function dateToQueryParam(fieldId, date) {
  var date$1 = Utils_Date$DvmAdminFrontendFr.toISODateString(date);
  if (date$1 !== undefined && date$1.length !== 0) {
    return fieldId + "=" + date$1;
  }
  
}

function dateYearToQueryParam(fieldId, date) {
  var date$1 = Utils_Date$DvmAdminFrontendFr.toYearString(date);
  if (date$1 !== undefined && date$1.length !== 0) {
    return fieldId + "=" + date$1;
  }
  
}

function multiSelectToQueryParam(fieldId, values) {
  var result = encodeURIComponent(Belt_Array.joinWith(Belt_List.toArray(values), "\\,", (function (value) {
              return value;
            })));
  if (result.length !== 0) {
    return (fieldId + "=" + result).replace(/%2B/g, "%20");
  }
  
}

function createQueryParams($staropt$star, param) {
  var filterQueryParams = param.filterQueryParams;
  var paramsStyle = $staropt$star !== undefined ? $staropt$star : "normal";
  return Js_string.concat(Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap(Belt_List.concat({
                                hd: App_Table_Pagination$DvmAdminFrontendFr.createString(param.rowsPerPage, param.page),
                                tl: {
                                  hd: filterQueryParams.length === 0 ? undefined : "filter=" + filterQueryParams,
                                  tl: {
                                    hd: App_Table_Head$DvmAdminFrontendFr.createString(paramsStyle, param.headers),
                                    tl: /* [] */0
                                  }
                                }
                              }, Belt_List.map(param.additionalQueryParams, (function (param) {
                                      return param;
                                    }))), (function (queryParams) {
                              return queryParams;
                            }))), "&", (function (queryParams) {
                      return queryParams;
                    })), "?").replace(Constants$DvmAdminFrontendFr.Regex.whitespaces, encodeURIComponent("+"));
}

function stringFieldToQueryParamBilingual(exactMatchOpt, fieldId, value, parentheses) {
  var exactMatch = exactMatchOpt !== undefined ? exactMatchOpt : false;
  if (value === "") {
    return ;
  } else {
    return {
            en: fieldId[0] + (
              exactMatch ? "=" : "⊇"
            ) + (
              parentheses ? "'" + spacesEncode(value) + "'" : spacesEncode(value)
            ),
            fr: fieldId[1] + (
              exactMatch ? "=" : "⊇"
            ) + spacesEncode(value)
          };
  }
}

function dateToQueryParamBilingual(fieldId, date) {
  var date$1 = Utils_Date$DvmAdminFrontendFr.toISODateString(date);
  if (date$1 !== undefined && date$1.length !== 0) {
    return {
            en: fieldId[0] + "=" + date$1,
            fr: fieldId[1] + "=" + date$1
          };
  }
  
}

function datePairToQueryParamBilingual(valueFrom, valueTo, fieldId, style) {
  var en = fieldId[0];
  var match = Belt_Option.mapWithDefault(valueFrom, undefined, Utils_Date$DvmAdminFrontendFr.toISODateString);
  var match$1 = Belt_Option.mapWithDefault(valueTo, undefined, Utils_Date$DvmAdminFrontendFr.toISODateString);
  if (match !== undefined) {
    if (match$1 !== undefined && (match + match$1).length === 0) {
      return ;
    }
    
  } else if (match$1 === undefined) {
    return ;
  }
  var from = Belt_Option.getWithDefault(match, "");
  var to = Belt_Option.getWithDefault(match$1, "");
  return {
          en: style === "sqlLike" ? "(" + en + " >= '" + from + "' AND " + en + " <= '" + to + "')" : "" + en + "=%5B" + from + "%5C," + to + "%5D",
          fr: "" + fieldId[1] + "=%5B" + from + "%5C," + to + "%5D"
        };
}

function multiSelectToQueryParamBilingual(fieldId, style, values) {
  var en = fieldId[0];
  var result = encodeURIComponent(Belt_Array.joinWith(Belt_List.toArray(values), style === "sqlLike" ? " OR " : "\\,", (function (value) {
              if (style === "sqlLike") {
                return "" + en + "='" + value + "'";
              } else {
                return value;
              }
            })));
  var resultFR = encodeURIComponent(Belt_Array.joinWith(Belt_List.toArray(values), "\\,", (function (value) {
              return value;
            })));
  if (result.length !== 0) {
    return {
            en: style === "sqlLike" ? "(" + result + ")" : en + "=" + result,
            fr: fieldId[1] + "=" + resultFR
          };
  }
  
}

function boolFieldToQueryParamBilingual(fieldId, value) {
  return {
          en: fieldId[0] + "=" + (
            value ? "true" : "false"
          ),
          fr: fieldId[1] + "=" + (
            value ? "true" : "false"
          )
        };
}

function boolFieldToQueryParam(fieldId, value) {
  return fieldId + "=" + (
          value ? "true" : "false"
        );
}

function dateYearToQueryParamBilingual(fieldId, date) {
  var date$1 = Utils_Date$DvmAdminFrontendFr.toYearString(date);
  if (date$1 !== undefined && date$1.length !== 0) {
    return {
            en: fieldId[0] + "=" + date$1,
            fr: fieldId[1] + "=" + date$1
          };
  }
  
}

function stringPairToQueryParamBilingual(valueFrom, valueTo, fieldId) {
  if (valueFrom !== undefined) {
    if (valueTo !== undefined && (valueFrom + valueTo).length === 0) {
      return ;
    }
    
  } else if (valueTo === undefined) {
    return ;
  }
  var from = Belt_Option.getWithDefault(valueFrom, "");
  var to = Belt_Option.getWithDefault(valueTo, "");
  return {
          en: "" + fieldId[0] + "=%5B" + from + "%5C," + to + "%5D",
          fr: "" + fieldId[1] + "=%5B" + from + "%5C," + to + "%5D"
        };
}

export {
  spacesEncode ,
  stringFieldToQueryParam ,
  datePairToQueryParam ,
  pairSelectToQueryParam ,
  dateToQueryParam ,
  dateYearToQueryParam ,
  multiSelectToQueryParam ,
  createQueryParams ,
  stringFieldToQueryParamBilingual ,
  dateToQueryParamBilingual ,
  datePairToQueryParamBilingual ,
  multiSelectToQueryParamBilingual ,
  boolFieldToQueryParamBilingual ,
  boolFieldToQueryParam ,
  dateYearToQueryParamBilingual ,
  stringPairToQueryParamBilingual ,
}
/* Utils_Date-DvmAdminFrontendFr Not a pure module */
