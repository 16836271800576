// Generated by ReScript, PLEASE EDIT WITH CARE


var numberSelected = {
  id: "1b0a73c0-5a2e-43c6-941f-f98024874fca",
  defaultMessage: "{number} sélectionnés"
};

var totalBonusAmount = {
  id: "7e89d66c-4b51-4193-9113-690a55c8cf29",
  defaultMessage: "Montant total des aides : {amountEuro} €"
};

var refuseBonus = {
  id: "70c9e7a9-ad2f-4b1b-9e93-729f13bf7fb3",
  defaultMessage: "Refuser"
};

var modifyStatus = {
  id: "d0858fdd-4515-4738-be2b-f4d85ca1565d",
  defaultMessage: "Modifier le statut de l’action"
};

var modifyAmount = {
  id: "74519c25-b1d0-4470-85c3-2d7b0bce9677",
  defaultMessage: "Modifier le montant de l’action"
};

var modalModifyStatusTitle = {
  id: "846bdc74-fc17-4ca7-9666-003351d37317",
  defaultMessage: "Modifier un statut"
};

var modalRefuseTitle = {
  id: "1a25a1cd-f983-442a-8f9d-426b7ad75ebb",
  defaultMessage: "Refuser une aide"
};

var modalModifyAmountTitle = {
  id: "6377c5d6-834b-4a4b-8b0b-80b500696b39",
  defaultMessage: "Modifier un montant"
};

var modalValidateTitle = {
  id: "3f8b37b8-2cd0-4098-8d4f-02a7cbbcebb7",
  defaultMessage: "Valider une aide"
};

var amountFromDealer = {
  id: "26fb798e-a1b8-42dc-a6ba-5af0d515aaf5",
  defaultMessage: "Montant saisie par l'affaire"
};

var authorizedAmount = {
  id: "8586206e-91e3-4615-8ba2-f3611e5c2b92",
  defaultMessage: "Montant max. autorisé"
};

var newAmount = {
  id: "70697e00-d31a-4a78-ad60-220a6e0ac6d7",
  defaultMessage: "Nouveau montant"
};

var currentStatus = {
  id: "a0d8ef92-faf4-48cf-86f0-de90fea57681",
  defaultMessage: "Statut actuel"
};

var newStatus = {
  id: "1b772116-429e-4cc1-9106-451ea1bd84a9",
  defaultMessage: "Nouveau statut"
};

var refusalMotive = {
  id: "208cfb30-5825-4d69-81c8-d8604cb45fad",
  defaultMessage: "Motif de refus"
};

var refusalConfirmation = {
  id: "3fff444d-387c-48c5-b430-5c871d25fe89",
  defaultMessage: "Confirmez vous le refus de cette aide ?"
};

var validationConfirmation = {
  id: "f0015194-4e76-47b4-ba8e-a7e290d9c5f7",
  defaultMessage: "Êtes-vous sûr de vouloir valider l’aide ?"
};

var validateSuccess = {
  id: "cfefd24d-9fb7-41a7-8dfc-256b3fdee9da",
  defaultMessage: "Validation de l'aide effectuée"
};

var refusedSuccess = {
  id: "efafad86-9a93-4426-8254-dbd1b9e7dfdc",
  defaultMessage: "L’aide a bien été refusée"
};

var amountChangeSuccess = {
  id: "86225fdc-49a1-449d-b343-0c3da3ea211c",
  defaultMessage: "Le montant de l’aide a bien été modifié"
};

var backToValidateSuccess = {
  id: "c5d09a2e-970a-410b-8930-aaa50f74f003",
  defaultMessage: "L’aide a bien été remise au statut A VALIDER"
};

var searchExceededAmount = {
  id: "3459b74f-21f7-414f-bd81-4ad47c2a48f5",
  defaultMessage: "Recherche Montants en dépassement"
};

var toValidateStatus = {
  id: "1b1fc139-a323-42fa-aa36-607e4e2da03f",
  defaultMessage: "À valider"
};

export {
  numberSelected ,
  totalBonusAmount ,
  refuseBonus ,
  modifyStatus ,
  modifyAmount ,
  modalModifyStatusTitle ,
  modalRefuseTitle ,
  modalModifyAmountTitle ,
  modalValidateTitle ,
  amountFromDealer ,
  authorizedAmount ,
  newAmount ,
  currentStatus ,
  newStatus ,
  refusalMotive ,
  refusalConfirmation ,
  validationConfirmation ,
  validateSuccess ,
  refusedSuccess ,
  amountChangeSuccess ,
  backToValidateSuccess ,
  searchExceededAmount ,
  toValidateStatus ,
}
/* No side effect */
