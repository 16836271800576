// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../types/App_Types_Date.bs.js";

function statusResponseItem_encode(v) {
  return Js_dict.fromArray([
              [
                "status",
                Decco.optionToJson(Decco.stringToJson, v.status)
              ],
              [
                "type",
                Decco.optionToJson(Decco.stringToJson, v.type)
              ],
              [
                "time",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.time)
              ]
            ]);
}

function statusResponseItem_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var status = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "status"), null));
  if (status.TAG === /* Ok */0) {
    var type = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "type"), null));
    if (type.TAG === /* Ok */0) {
      var time = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "time"), null));
      if (time.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  status: status._0,
                  type: type._0,
                  time: time._0
                }
              };
      }
      var e = time._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".time" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = type._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".type" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = status._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".status" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function statusResponse_encode(v) {
  return Decco.listToJson(statusResponseItem_encode, v);
}

function statusResponse_decode(v) {
  return Decco.listFromJson(statusResponseItem_decode, v);
}

function fetchCalculationStatus(userManager, setResult, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", statusResponse_decode, undefined, userManager, selectedRole, "/varicent/activities").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

export {
  statusResponseItem_encode ,
  statusResponseItem_decode ,
  statusResponse_encode ,
  statusResponse_decode ,
  fetchCalculationStatus ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
