// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../../intl/Intl_Utils.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../../../../common/components/App_LabeledText.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Messages_FinancialCodes$DvmAdminFrontendFr from "../../../../../../intl/messages/easypack/Messages_FinancialCodes.bs.js";
import * as Order_Detail_Correct_Box$DvmAdminFrontendFr from "../Order_Detail_Correct_Box.bs.js";
import * as Order_Detail_Types_Order_Type$DvmAdminFrontendFr from "../../../Order_Detail_Types_Order_Type.bs.js";

function Order_Detail_Correct_Order_Content_NonFleet(Props) {
  var order = Props.order;
  var color = Props.color;
  var title = Props.title;
  var newestUndeployedCorrection = Props.newestUndeployedCorrection;
  var tooltipHeight = Props.tooltipHeight;
  var form = Props.form;
  var intl = ReactIntl.useIntl();
  var checkEmptyString = function (string) {
    return Belt_Option.mapWithDefault(string, undefined, (function (x) {
                  if (x.length > 0) {
                    return x;
                  }
                  
                }));
  };
  var match = Belt_Option.mapWithDefault(newestUndeployedCorrection, [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ], (function (x) {
          return [
                  Belt_Option.mapWithDefault(x.orderType, "", (function (x) {
                          if (Order_Detail_Types_Order_Type$DvmAdminFrontendFr.toString(x) !== Order_Detail_Types_Order_Type$DvmAdminFrontendFr.toString(Belt_Option.getWithDefault(order.orderType, /* SO */0))) {
                            return " (" + Order_Detail_Types_Order_Type$DvmAdminFrontendFr.toString(x) + ")";
                          } else {
                            return "";
                          }
                        })),
                  Belt_Option.mapWithDefault(x.orderDate, "", (function (x) {
                          if (ReDate.isSameDay(x, Belt_Option.getWithDefault(order.detail.orderDate, new Date(0.0)))) {
                            return "";
                          } else {
                            return " (" + Intl_Utils$DvmAdminFrontendFr.formatDate(intl, x) + ")";
                          }
                        })),
                  Belt_Option.mapWithDefault(checkEmptyString(x.shortCustomerName), "", (function (x) {
                          if (x !== Belt_Option.getWithDefault(order.detail.shortCustomerName, "")) {
                            return " (" + x + ")";
                          } else {
                            return "";
                          }
                        })),
                  Belt_Option.mapWithDefault(checkEmptyString(x.customerFirstName), "", (function (x) {
                          if (x !== Belt_Option.getWithDefault(order.detail.clientFirstName, "")) {
                            return " (" + x + ")";
                          } else {
                            return "";
                          }
                        })),
                  Belt_Option.mapWithDefault(x.newCarRegistrationDate, "", (function (x) {
                          if (ReDate.isSameDay(x, Belt_Option.getWithDefault(order.detail.registrationDate, new Date(0.0)))) {
                            return "";
                          } else {
                            return " (" + Intl_Utils$DvmAdminFrontendFr.formatDate(intl, x) + ")";
                          }
                        })),
                  Belt_Option.mapWithDefault(checkEmptyString(x.newCarRegistrationID), "", (function (x) {
                          if (x !== Belt_Option.getWithDefault(order.detail.registrationId, "")) {
                            return " (" + x + ")";
                          } else {
                            return "";
                          }
                        })),
                  Belt_Option.mapWithDefault(x.parcStartDate, "", (function (x) {
                          if (ReDate.isSameDay(x, Belt_Option.getWithDefault(order.detail.parcStartDate, new Date(0.0)))) {
                            return "";
                          } else {
                            return " (" + Intl_Utils$DvmAdminFrontendFr.formatDate(intl, x) + ")";
                          }
                        })),
                  Belt_Option.mapWithDefault(x.parcEndDate, "", (function (x) {
                          if (ReDate.isSameDay(x, Belt_Option.getWithDefault(order.detail.parcEndDate, new Date(0.0)))) {
                            return "";
                          } else {
                            return " (" + Intl_Utils$DvmAdminFrontendFr.formatDate(intl, x) + ")";
                          }
                        })),
                  Belt_Option.mapWithDefault(x.customerDiscount, "", (function (x) {
                          if (x !== Belt_Option.getWithDefault(order.detail.discount, 0.0)) {
                            return " (" + Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, x) + ")";
                          } else {
                            return "";
                          }
                        })),
                  Belt_Option.mapWithDefault(checkEmptyString(x.easyPack), "", (function (x) {
                          if (x !== Belt_Option.getWithDefault(order.detail.financialCode, "")) {
                            return " (" + x + ")";
                          } else {
                            return "";
                          }
                        })),
                  Belt_Option.mapWithDefault(x.customerInvoiceDate, "", (function (x) {
                          if (ReDate.isSameDay(x, Belt_Option.getWithDefault(order.detail.customerInvoiceDate, new Date(0.0)))) {
                            return "";
                          } else {
                            return " (" + Intl_Utils$DvmAdminFrontendFr.formatDate(intl, x) + ")";
                          }
                        })),
                  Belt_Option.mapWithDefault(checkEmptyString(x.customerInvoiceNumber), "", (function (x) {
                          if (x !== Belt_Option.getWithDefault(order.detail.customerInvoiceNumber, "")) {
                            return " (" + x + ")";
                          } else {
                            return "";
                          }
                        })),
                  Belt_Option.mapWithDefault(x.renaultInvoiceDate, "", (function (x) {
                          if (ReDate.isSameDay(x, Belt_Option.getWithDefault(order.detail.renaultInvoiceDate, new Date(0.0)))) {
                            return "";
                          } else {
                            return " (" + Intl_Utils$DvmAdminFrontendFr.formatDate(intl, x) + ")";
                          }
                        })),
                  Belt_Option.mapWithDefault(x.carSaleAvailibilityDateMADA, "", (function (x) {
                          if (ReDate.isSameDay(x, Belt_Option.getWithDefault(order.detail.madaDate, new Date(0.0)))) {
                            return "";
                          } else {
                            return " (" + Intl_Utils$DvmAdminFrontendFr.formatDate(intl, x) + ")";
                          }
                        })),
                  Belt_Option.mapWithDefault(x.carSaleAvailibilityDateMADC, "", (function (x) {
                          if (ReDate.isSameDay(x, Belt_Option.getWithDefault(order.detail.madcDate, new Date(0.0)))) {
                            return "";
                          } else {
                            return " (" + Intl_Utils$DvmAdminFrontendFr.formatDate(intl, x) + ")";
                          }
                        })),
                  Belt_Option.mapWithDefault(checkEmptyString(x.modelID), "", (function (x) {
                          if (x !== Belt_Option.getWithDefault(order.detail.model, "")) {
                            return " (" + x + ")";
                          } else {
                            return "";
                          }
                        })),
                  Belt_Option.mapWithDefault(checkEmptyString(x.versionID), "", (function (x) {
                          if (x !== Belt_Option.getWithDefault(order.detail.version, "")) {
                            return " (" + x + ")";
                          } else {
                            return "";
                          }
                        })),
                  Belt_Option.mapWithDefault(checkEmptyString(x.phase), "", (function (x) {
                          if (x !== Belt_Option.getWithDefault(order.detail.phase, "")) {
                            return " (" + x + ")";
                          } else {
                            return "";
                          }
                        })),
                  Belt_Option.mapWithDefault(checkEmptyString(x.salesmanName), "", (function (x) {
                          if (x !== Belt_Option.getWithDefault(order.detail.salesmanName, "")) {
                            return " (" + x + ")";
                          } else {
                            return "";
                          }
                        })),
                  Belt_Option.mapWithDefault(checkEmptyString(x.agentCode), "", (function (x) {
                          if (x !== Belt_Option.getWithDefault(order.detail.agentCode, "")) {
                            return " (" + x + ")";
                          } else {
                            return "";
                          }
                        }))
                ];
        }));
  return React.createElement(Order_Detail_Correct_Box$DvmAdminFrontendFr.make, {
              content: Belt_Array.keepMap([
                    Belt_Option.mapWithDefault(tooltipHeight, undefined, (function (x) {
                            return Caml_option.some(React.createElement("div", {
                                            style: {
                                              height: x
                                            }
                                          }));
                          })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.orderType,
                              children: Belt_Option.mapWithDefault(order.orderType, "-", Order_Detail_Types_Order_Type$DvmAdminFrontendFr.toString) + match[0]
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.orderDateV2,
                              children: Belt_Option.mapWithDefault(order.detail.orderDate, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    })) + match[1]
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.customerName,
                              children: Belt_Option.getWithDefault(order.detail.shortCustomerName, "-") + match[2]
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.clientFirstName,
                              children: Belt_Option.getWithDefault(order.detail.clientFirstName, "-") + match[3]
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.registrationDate,
                              children: Belt_Option.mapWithDefault(order.detail.registrationDate, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    })) + match[4]
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.registrationNumber,
                              children: Belt_Option.getWithDefault(order.detail.registrationId, "-") + match[5]
                            })),
                    form.input.orderType === /* VE */2 || form.input.orderType === /* RR */4 || form.input.orderType === /* RC */5 ? Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                label: Messages_Orders_Detail$DvmAdminFrontendFr.dateInputParc,
                                children: Belt_Option.mapWithDefault(order.detail.parcStartDate, Belt_Option.mapWithDefault(order.detail.parcStartCalcDate, "-", (function (param) {
                                            return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                          })), (function (param) {
                                        return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                      })) + match[6]
                              })) : undefined,
                    form.input.orderType === /* VE */2 || form.input.orderType === /* RR */4 || form.input.orderType === /* RC */5 ? Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                label: Messages_Orders_Detail$DvmAdminFrontendFr.dateOutputParc,
                                children: Belt_Option.mapWithDefault(order.detail.parcEndDate, "-", (function (param) {
                                        return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                      })) + match[7]
                              })) : undefined,
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.discountTTC,
                              children: Belt_Option.mapWithDefault(order.detail.discount, "-", (function (x) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, x);
                                    })) + match[8]
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_FinancialCodes$DvmAdminFrontendFr.codeId,
                              children: Belt_Option.getWithDefault(order.detail.financialCode, "-") + match[9]
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.invoiceClient,
                              children: Belt_Option.mapWithDefault(order.detail.customerInvoiceDate, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    })) + match[10]
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.invoiceNumber,
                              children: Belt_Option.getWithDefault(order.detail.customerInvoiceNumber, "-") + match[11]
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.invoiceDateV2,
                              children: Belt_Option.mapWithDefault(order.detail.renaultInvoiceDate, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    })) + match[12]
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.madaDate,
                              children: Belt_Option.mapWithDefault(order.detail.madaDate, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    })) + match[13]
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.madcDate,
                              children: Belt_Option.mapWithDefault(order.detail.madcDate, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    })) + match[14]
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.model,
                              children: Belt_Option.getWithDefault(order.detail.model, "-") + match[15]
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.version,
                              children: Belt_Option.getWithDefault(order.detail.version, "-") + match[16]
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.phase,
                              children: Belt_Option.getWithDefault(order.detail.phase, "-") + match[17]
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.seller,
                              children: Belt_Option.getWithDefault(order.detail.salesmanName, "-") + match[18]
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.agent,
                              children: Belt_Option.getWithDefault(order.detail.agentCode, "-") + match[19]
                            }))
                  ], (function (x) {
                      return x;
                    })),
              color: color,
              title: title
            });
}

var make = Order_Detail_Correct_Order_Content_NonFleet;

export {
  make ,
}
/* react Not a pure module */
