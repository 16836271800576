// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../../../common/components/icons/App_Icon.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../../theme/Theme_Colors.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Result.bs.js";
import * as Messages_Orders_DealerRequest$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_DealerRequest.bs.js";

function Order_Detail_Bonus_DealerRequest_FileType_List(Props) {
  var newDocuments = Props.newDocuments;
  var existingDocuments = Props.existingDocuments;
  var documentTypes = Props.documentTypes;
  var intl = ReactIntl.useIntl();
  var allFileTypes = function (param) {
    return Belt_List.concat(Belt_List.map(newDocuments, (function (x) {
                      return x.fileType;
                    })), Belt_List.keepMap(existingDocuments, (function (x) {
                      return x.fileType;
                    })));
  };
  if (App_Types_Result$DvmAdminFrontendFr.isPending(documentTypes)) {
    return React.createElement(Core.Grid, {
                alignItems: "center",
                children: React.createElement(Core.CircularProgress, {
                      size: 50
                    }),
                container: true,
                style: {
                  padding: "20px"
                }
              });
  } else {
    return App_Types_Result$DvmAdminFrontendFr.mapWithDefault(documentTypes, null, (function (docTypes) {
                  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                                  children: React.createElement(Core.Divider, {}),
                                  item: true,
                                  style: {
                                    marginTop: "15px",
                                    marginBottom: "5px"
                                  }
                                }), React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  direction: "column",
                                  item: true
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                            gutterBottom: true,
                                            style: {
                                              fontWeight: "600"
                                            },
                                            children: intl.formatMessage(Messages_Orders_DealerRequest$DvmAdminFrontendFr.documentTypesTitle),
                                            variant: "h6"
                                          }),
                                      item: true,
                                      xs: Grid$Mui.Xs[3]
                                    }), Belt_List.toArray(Belt_List.map(docTypes, (function (docType) {
                                            var tmp = {};
                                            var tmp$1 = docType.mandatory ? "600" : undefined;
                                            if (tmp$1 !== undefined) {
                                              tmp.fontWeight = tmp$1;
                                            }
                                            return React.createElement(Core.Grid, {
                                                        alignItems: "center",
                                                        children: null,
                                                        container: true,
                                                        item: true,
                                                        spacing: 1
                                                      }, React.createElement(Core.Grid, {
                                                            children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                                  gutterBottom: true,
                                                                  style: tmp,
                                                                  children: docType.fileLabel + " - " + intl.formatMessage(docType.mandatory ? Messages_Common$DvmAdminFrontendFr.mandatory : Messages_Common$DvmAdminFrontendFr.optional),
                                                                  variant: "subtitle"
                                                                }),
                                                            item: true
                                                          }), Belt_List.has(allFileTypes(undefined), docType, (function (a, b) {
                                                              return a === b.fileType;
                                                            })) ? React.createElement(Core.Grid, {
                                                              children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                    size: /* Large */3,
                                                                    type_: /* Tick */1,
                                                                    color: Theme_Colors$DvmAdminFrontendFr.accentGreen
                                                                  }),
                                                              item: true
                                                            }) : null);
                                          })))));
                }));
  }
}

var make = Order_Detail_Bonus_DealerRequest_FileType_List;

export {
  make ,
}
/* react Not a pure module */
