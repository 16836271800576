// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../../intl/Intl_Utils.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../common/App_Typography.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../../../../common/components/App_LabeledText.bs.js";
import * as Messages_Orders_Correction$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_Correction.bs.js";

function Order_Detail_Correct_Perfs_History_Content(Props) {
  var correction = Props.correction;
  var color = Props.color;
  var title = Props.title;
  var intl = ReactIntl.useIntl();
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              direction: "column",
              item: true,
              sm: Grid$Mui.Sm[6],
              spacing: 2
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        style: {
                          color: color,
                          fontWeight: "600"
                        },
                        children: title
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Core.Box, {
                        children: React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              direction: "column",
                              spacing: 5
                            }, React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  direction: "column",
                                  item: true,
                                  spacing: 1
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Box, {
                                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                  label: Messages_Orders_Correction$DvmAdminFrontendFr.idPerf,
                                                  children: Belt_Option.getWithDefault(correction.planPerfID, "-") + Belt_Option.mapWithDefault(correction.planPerfLabel1, "", (function (x) {
                                                          return " (" + x + ")";
                                                        }))
                                                }),
                                            minHeight: 48
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Box, {
                                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                  label: Messages_Orders_Correction$DvmAdminFrontendFr.typePerf,
                                                  children: Belt_Option.getWithDefault(correction.bonusActionTypePERF, "-")
                                                }),
                                            minHeight: 48
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Box, {
                                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                  label: Messages_Orders_Correction$DvmAdminFrontendFr.amountPerf,
                                                  children: Belt_Option.mapWithDefault(correction.planPerfAmount, "-", (function (eta) {
                                                          return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, eta);
                                                        }))
                                                }),
                                            minHeight: 48
                                          }),
                                      item: true
                                    })), React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  direction: "column",
                                  item: true,
                                  spacing: 1
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Box, {
                                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                  label: Messages_Orders_Correction$DvmAdminFrontendFr.idPerf2,
                                                  children: Belt_Option.getWithDefault(correction.planPerfID2, "-") + Belt_Option.mapWithDefault(correction.planPerfLabel2, "", (function (x) {
                                                          return " (" + x + ")";
                                                        }))
                                                }),
                                            minHeight: 48
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Box, {
                                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                  label: Messages_Orders_Correction$DvmAdminFrontendFr.typePerf2,
                                                  children: Belt_Option.getWithDefault(correction.bonusActionTypePERF2, "-")
                                                }),
                                            minHeight: 48
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Box, {
                                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                  label: Messages_Orders_Correction$DvmAdminFrontendFr.amountPerf2,
                                                  children: Belt_Option.mapWithDefault(correction.planPerfAmount2, "-", (function (eta) {
                                                          return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, eta);
                                                        }))
                                                }),
                                            minHeight: 48
                                          }),
                                      item: true
                                    })), React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  direction: "column",
                                  item: true,
                                  spacing: 1
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Box, {
                                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                  label: Messages_Orders_Correction$DvmAdminFrontendFr.idPerf3,
                                                  children: Belt_Option.getWithDefault(correction.planPerfID3, "-") + Belt_Option.mapWithDefault(correction.planPerfLabel3, "", (function (x) {
                                                          return " (" + x + ")";
                                                        }))
                                                }),
                                            minHeight: 48
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Box, {
                                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                  label: Messages_Orders_Correction$DvmAdminFrontendFr.typePerf3,
                                                  children: Belt_Option.getWithDefault(correction.bonusActionTypePERF3, "-")
                                                }),
                                            minHeight: 48
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Box, {
                                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                  label: Messages_Orders_Correction$DvmAdminFrontendFr.amountPerf3,
                                                  children: Belt_Option.mapWithDefault(correction.planPerfAmount3, "-", (function (eta) {
                                                          return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, eta);
                                                        }))
                                                }),
                                            minHeight: 48
                                          }),
                                      item: true
                                    }))),
                        borderColor: color,
                        borderLeft: 4,
                        boxShadow: "0 2px 7px 1px #eee",
                        padding: "24px 32px"
                      }),
                  item: true
                }));
}

var make = Order_Detail_Correct_Perfs_History_Content;

export {
  make ,
}
/* react Not a pure module */
