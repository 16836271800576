// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as Contract_Edit_Api$DvmAdminFrontendFr from "../Contract_Edit_Api.bs.js";
import * as App_RadioFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_RadioFormInput.bs.js";
import * as Contract_Edit_Utils$DvmAdminFrontendFr from "../Contract_Edit_Utils.bs.js";
import * as App_CompanyFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_CompanyFormInput.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_Contract_Create$DvmAdminFrontendFr from "../../../../intl/messages/contract/Messages_Contract_Create.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Messages_Contr_Man_Search$DvmAdminFrontendFr from "../../../../intl/messages/contractManagers/Messages_Contr_Man_Search.bs.js";

function Contract_Edit_Main_Info_Edit_Inputs(Props) {
  var budgets = Props.budgets;
  var form = Props.form;
  var managerIds = Props.managerIds;
  var contract = Props.contract;
  var sirenName = Props.sirenName;
  var setSirenName = Props.setSirenName;
  var sirens = Props.sirens;
  var setSirens = Props.setSirens;
  var attachmentCopyCheck = Props.attachmentCopyCheck;
  var setAttachmentCopyCheck = Props.setAttachmentCopyCheck;
  var checkSirenOK = Props.checkSirenOK;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var getManagerOptionsLimitedByBudget = function (budgetOpt, param) {
    var budget = budgetOpt !== undefined ? budgetOpt : form.input.budget;
    return Belt_List.map(Belt_MapString.toList(Belt_MapString.fromArray(Belt_List.toArray(Belt_Option.mapWithDefault(managerIds, /* [] */0, (function (param) {
                                  return Belt_List.keepMap(param.content, (function (param) {
                                                var endDate = param.endDate;
                                                var startDate = param.startDate;
                                                var managerID = param.managerID;
                                                var managerOption_1 = managerID + Belt_Option.mapWithDefault(param.managerName, "", (function (x) {
                                                        if (x.length > 0) {
                                                          return " (" + x + ")";
                                                        } else {
                                                          return "";
                                                        }
                                                      }));
                                                var managerOption = [
                                                  managerID,
                                                  managerOption_1
                                                ];
                                                if (budget === "") {
                                                  return managerOption;
                                                } else {
                                                  return Belt_Option.flatMap(param.budgets, (function (managerBudget) {
                                                                if (Belt_Option.isSome(Belt_List.getBy(managerBudget, (function (x) {
                                                                              return x === budget ? Contract_Edit_Utils$DvmAdminFrontendFr.doDatesOverlap(form.input.startDate, form.input.endDate, startDate, endDate) : false;
                                                                            })))) {
                                                                  return managerOption;
                                                                }
                                                                
                                                              }));
                                                }
                                              }));
                                }))))), (function (param) {
                  return {
                          label: {
                            TAG: /* String */3,
                            _0: param[1]
                          },
                          value: param[0]
                        };
                }));
  };
  var onBudgetChange = function (budget) {
    Curry._2(form.updateBudget, (function (input, budget) {
            return {
                    budget: budget,
                    contractID: input.contractID,
                    contractLabel: input.contractLabel,
                    siren: input.siren,
                    managerID: input.managerID,
                    startDate: input.startDate,
                    endDate: input.endDate,
                    referenceLLD: input.referenceLLD,
                    newStartDate: input.newStartDate,
                    applyNewStartDate: input.applyNewStartDate,
                    copyAttachments: input.copyAttachments
                  };
          }), budget);
    if (!Belt_List.has(getManagerOptionsLimitedByBudget(budget, undefined), form.input.managerID, (function (a, b) {
              return a.value === b;
            }))) {
      return Curry._2(form.updateManagerID, (function (input, managerID) {
                    return {
                            budget: input.budget,
                            contractID: input.contractID,
                            contractLabel: input.contractLabel,
                            siren: input.siren,
                            managerID: managerID,
                            startDate: input.startDate,
                            endDate: input.endDate,
                            referenceLLD: input.referenceLLD,
                            newStartDate: input.newStartDate,
                            applyNewStartDate: input.applyNewStartDate,
                            copyAttachments: input.copyAttachments
                          };
                  }), "");
    }
    
  };
  React.useEffect((function () {
          if (form.input.copyAttachments) {
            Contract_Edit_Api$DvmAdminFrontendFr.checkAttachmentCopy(userManager, setAttachmentCopyCheck, form.input.contractID, form.input.startDate, form.input.newStartDate, selectedRole);
          }
          
        }), [
        form.input.copyAttachments,
        form.input.newStartDate
      ]);
  var tmp = {
    label: Messages_Contract_Create$DvmAdminFrontendFr.contractType,
    value: form.input.budget,
    options: Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(budgets, /* [] */0), (function (x) {
            return {
                    label: {
                      TAG: /* String */3,
                      _0: x
                    },
                    value: x
                  };
          })),
    onChange: onBudgetChange,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.extraLongInput
  };
  if (form.budgetResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.budgetResult);
  }
  var tmp$1 = {
    onChange: Curry._1(form.updateContractLabel, (function (input, contractLabel) {
            return {
                    budget: input.budget,
                    contractID: input.contractID,
                    contractLabel: contractLabel,
                    siren: input.siren,
                    managerID: input.managerID,
                    startDate: input.startDate,
                    endDate: input.endDate,
                    referenceLLD: input.referenceLLD,
                    newStartDate: input.newStartDate,
                    applyNewStartDate: input.applyNewStartDate,
                    copyAttachments: input.copyAttachments
                  };
          })),
    value: form.input.contractLabel,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Contract_Create$DvmAdminFrontendFr.contractName
  };
  if (form.contractLabelResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.contractLabelResult);
  }
  var tmp$2 = {
    onChange: Curry._1(form.updateContractID, (function (input, contractID) {
            return {
                    budget: input.budget,
                    contractID: contractID.toUpperCase(),
                    contractLabel: input.contractLabel,
                    siren: input.siren,
                    managerID: input.managerID,
                    startDate: input.startDate,
                    endDate: input.endDate,
                    referenceLLD: input.referenceLLD,
                    newStartDate: input.newStartDate,
                    applyNewStartDate: input.applyNewStartDate,
                    copyAttachments: input.copyAttachments
                  };
          })),
    value: form.input.contractID,
    disabled: Belt_Option.isSome(contract),
    onBlur: (function (param) {
        Curry._1(checkSirenOK, undefined);
      }),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Contract_Create$DvmAdminFrontendFr.contractID
  };
  if (form.contractIDResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.contractIDResult);
  }
  var tmp$3 = {
    existingCompany: Belt_Option.mapWithDefault(contract, "", (function (contract) {
            return Belt_Option.getWithDefault(contract.siren, "");
          })),
    onChange: (function (siren) {
        Curry._2(form.updateSiren, (function (input, siren) {
                return {
                        budget: input.budget,
                        contractID: input.contractID,
                        contractLabel: input.contractLabel,
                        siren: siren,
                        managerID: input.managerID,
                        startDate: input.startDate,
                        endDate: input.endDate,
                        referenceLLD: input.referenceLLD,
                        newStartDate: input.newStartDate,
                        applyNewStartDate: input.applyNewStartDate,
                        copyAttachments: input.copyAttachments
                      };
              }), siren);
      }),
    value: form.input.siren,
    companyName: sirenName,
    setCompanyName: setSirenName,
    companies: sirens,
    setCompanies: setSirens,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.extraLongInput,
    companyType: "siren",
    onBlur: (function (param) {
        Curry._1(form.blurSiren, undefined);
      })
  };
  if (form.sirenResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.sirenResult);
  }
  var tmp$4 = {
    label: Messages_Contr_Man_Search$DvmAdminFrontendFr.managerIPN,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.managerID,
        onChange: Curry._1(form.updateManagerID, (function (input, managerID) {
                return {
                        budget: input.budget,
                        contractID: input.contractID,
                        contractLabel: input.contractLabel,
                        siren: input.siren,
                        managerID: managerID,
                        startDate: input.startDate,
                        endDate: input.endDate,
                        referenceLLD: input.referenceLLD,
                        newStartDate: input.newStartDate,
                        applyNewStartDate: input.applyNewStartDate,
                        copyAttachments: input.copyAttachments
                      };
              }))
      }
    },
    options: {
      TAG: /* Labeled */1,
      _0: getManagerOptionsLimitedByBudget(undefined, undefined)
    },
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.extraLongInput
  };
  if (form.managerIDResult !== undefined) {
    tmp$4.validationResult = Caml_option.valFromOption(form.managerIDResult);
  }
  var tmp$5 = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    budget: input.budget,
                    contractID: input.contractID,
                    contractLabel: input.contractLabel,
                    siren: input.siren,
                    managerID: input.managerID,
                    startDate: startDate,
                    endDate: input.endDate,
                    referenceLLD: input.referenceLLD,
                    newStartDate: input.newStartDate,
                    applyNewStartDate: input.applyNewStartDate,
                    copyAttachments: input.copyAttachments
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.startDateResult !== undefined) {
    tmp$5.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  var tmp$6 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    budget: input.budget,
                    contractID: input.contractID,
                    contractLabel: input.contractLabel,
                    siren: input.siren,
                    managerID: input.managerID,
                    startDate: input.startDate,
                    endDate: endDate,
                    referenceLLD: input.referenceLLD,
                    newStartDate: input.newStartDate,
                    applyNewStartDate: input.applyNewStartDate,
                    copyAttachments: input.copyAttachments
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.endDateResult !== undefined) {
    tmp$6.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  var match$2 = form.input.budget;
  var tmp$7 = match$2 === "PROT" ? React.createElement(Core.Grid, {
          children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                onChange: Curry._1(form.updateReferenceLLD, (function (input, referenceLLD) {
                        return {
                                budget: input.budget,
                                contractID: input.contractID,
                                contractLabel: input.contractLabel,
                                siren: input.siren,
                                managerID: input.managerID,
                                startDate: input.startDate,
                                endDate: input.endDate,
                                referenceLLD: referenceLLD,
                                newStartDate: input.newStartDate,
                                applyNewStartDate: input.applyNewStartDate,
                                copyAttachments: input.copyAttachments
                              };
                      })),
                value: form.input.referenceLLD,
                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                label: Messages_Contract_Create$DvmAdminFrontendFr.referenceLLD
              }),
          item: true
        }) : null;
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, tmp),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  item: true,
                  justifyContent: "center",
                  spacing: 3
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$1),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$2),
                      item: true
                    })), React.createElement(Core.Grid, {
                  children: React.createElement(App_CompanyFormInput$DvmAdminFrontendFr.make, tmp$3),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Core.Grid, {
                        children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$4),
                        item: true
                      }),
                  container: true,
                  item: true,
                  justifyContent: "center",
                  spacing: 3
                }), React.createElement(Core.Collapse, {
                  children: React.createElement(Core.Grid, {
                        children: null,
                        container: true,
                        item: true,
                        justifyContent: "center",
                        spacing: 3
                      }, React.createElement(Core.Grid, {
                            children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$5),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$6),
                            item: true
                          })),
                  in: !form.input.applyNewStartDate
                }), tmp$7, Belt_Option.mapWithDefault(contract, null, (function (contract) {
                    var match = contract.endDate;
                    var match$1 = contract.overallEndDate;
                    if (match !== undefined ? (
                          match$1 !== undefined ? !ReDate.isEqual(Caml_option.valFromOption(match$1), Caml_option.valFromOption(match)) : true
                        ) : match$1 !== undefined) {
                      return null;
                    }
                    var tmp = {
                      label: Messages_Contract_Create$DvmAdminFrontendFr.newStartDate,
                      value: form.input.newStartDate,
                      onChange: Curry._1(form.updateNewStartDate, (function (input, newStartDate) {
                              return {
                                      budget: input.budget,
                                      contractID: input.contractID,
                                      contractLabel: input.contractLabel,
                                      siren: input.siren,
                                      managerID: input.managerID,
                                      startDate: input.startDate,
                                      endDate: input.endDate,
                                      referenceLLD: input.referenceLLD,
                                      newStartDate: newStartDate,
                                      applyNewStartDate: input.applyNewStartDate,
                                      copyAttachments: input.copyAttachments
                                    };
                            })),
                      disabled: !form.input.applyNewStartDate,
                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                    };
                    if (form.newStartDateResult !== undefined) {
                      tmp.validationResult = Caml_option.valFromOption(form.newStartDateResult);
                    }
                    return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                                    children: React.createElement(Core.Grid, {
                                          children: React.createElement(Core.FormControlLabel, {
                                                classes: {
                                                  label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                                },
                                                control: React.createElement(Core.Switch, {
                                                      onClick: (function ($$event) {
                                                          Curry._2(form.updateApplyNewStartDate, (function (input, applyNewStartDate) {
                                                                  return {
                                                                          budget: input.budget,
                                                                          contractID: input.contractID,
                                                                          contractLabel: input.contractLabel,
                                                                          siren: input.siren,
                                                                          managerID: input.managerID,
                                                                          startDate: input.startDate,
                                                                          endDate: input.endDate,
                                                                          referenceLLD: input.referenceLLD,
                                                                          newStartDate: input.newStartDate,
                                                                          applyNewStartDate: applyNewStartDate,
                                                                          copyAttachments: input.copyAttachments
                                                                        };
                                                                }), $$event.target.checked);
                                                        }),
                                                      checked: form.input.applyNewStartDate
                                                    }),
                                                label: intl.formatMessage(Messages_Contract_Create$DvmAdminFrontendFr.editApplyDate)
                                              }),
                                          item: true
                                        }),
                                    container: true,
                                    item: true,
                                    justifyContent: "center"
                                  }), React.createElement(Core.Collapse, {
                                    children: React.createElement(Core.Grid, {
                                          alignItems: "center",
                                          children: null,
                                          container: true,
                                          item: true,
                                          justifyContent: "center",
                                          spacing: 2
                                        }, React.createElement(Core.Grid, {
                                              children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp),
                                              item: true
                                            }), form.input.applyNewStartDate ? React.createElement(Core.Grid, {
                                                children: React.createElement(Core.FormControlLabel, {
                                                      classes: {
                                                        label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                                      },
                                                      control: React.createElement(Core.Checkbox, {
                                                            onClick: (function ($$event) {
                                                                Curry._2(form.updateCopyAttachments, (function (input, copyAttachments) {
                                                                        return {
                                                                                budget: input.budget,
                                                                                contractID: input.contractID,
                                                                                contractLabel: input.contractLabel,
                                                                                siren: input.siren,
                                                                                managerID: input.managerID,
                                                                                startDate: input.startDate,
                                                                                endDate: input.endDate,
                                                                                referenceLLD: input.referenceLLD,
                                                                                newStartDate: input.newStartDate,
                                                                                applyNewStartDate: input.applyNewStartDate,
                                                                                copyAttachments: copyAttachments
                                                                              };
                                                                      }), $$event.target.checked);
                                                              }),
                                                            checked: form.input.copyAttachments
                                                          }),
                                                      label: intl.formatMessage(Messages_Contract_Create$DvmAdminFrontendFr.copyAttachments)
                                                    }),
                                                item: true
                                              }) : null, App_Types_Result$DvmAdminFrontendFr.mapWithDefault(attachmentCopyCheck, null, (function (x) {
                                                if (Belt_List.length(x) > 0 && form.input.copyAttachments) {
                                                  return React.createElement(Core.Grid, {
                                                              children: React.createElement(Core.Tooltip, {
                                                                    arrow: true,
                                                                    children: React.createElement("div", undefined, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                              size: /* Large */3,
                                                                              type_: /* HelpWarning */0,
                                                                              color: "red"
                                                                            })),
                                                                    classes: {
                                                                      tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
                                                                    },
                                                                    placement: "left-start",
                                                                    title: React.createElement(React.Fragment, undefined, React.createElement("p", undefined, intl.formatMessage(Messages_Contract_Create$DvmAdminFrontendFr.copyAttachmentOverlapIssue)), React.createElement(Core.Grid, {
                                                                              children: Belt_Array.mapWithIndex(Belt_List.toArray(x), (function (index, element) {
                                                                                      return React.createElement(Core.Grid, {
                                                                                                  children: element,
                                                                                                  item: true,
                                                                                                  key: String(index)
                                                                                                });
                                                                                    })),
                                                                              container: true,
                                                                              direction: "column"
                                                                            }))
                                                                  }),
                                                              item: true
                                                            });
                                                } else {
                                                  return null;
                                                }
                                              }))),
                                    in: form.input.applyNewStartDate
                                  }));
                  })));
}

var InputStyles;

var Form;

var Types;

var make = Contract_Edit_Main_Info_Edit_Inputs;

export {
  InputStyles ,
  Form ,
  Types ,
  make ,
}
/* react Not a pure module */
