// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../route/Route_Paths.bs.js";

function routeToMainPage(param) {
  RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rHome);
}

export {
  routeToMainPage ,
}
/* RescriptReactRouter Not a pure module */
