// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Constants$DvmAdminFrontendFr from "../../common/Constants.bs.js";
import * as App_Context$DvmAdminFrontendFr from "../../app/App_Context.bs.js";
import * as Intl_Locale$DvmAdminFrontendFr from "../../intl/Intl_Locale.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../common/types/App_Types_Result.bs.js";
import * as Utils_Authentication$DvmAdminFrontendFr from "../../common/utils/Utils_Authentication.bs.js";
import * as Utils_SessionStorageManager$DvmAdminFrontendFr from "../../common/utils/Utils_SessionStorageManager.bs.js";

function getLocale(param) {
  return Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(localStorage.getItem(Constants$DvmAdminFrontendFr.dvmeLocaleLocalStorageKey)), Belt_Option.getWithDefault(Intl_Locale$DvmAdminFrontendFr.fromCode(navigator.language), /* Fr */1), (function (locale) {
                return Belt_Option.getWithDefault(Intl_Locale$DvmAdminFrontendFr.fromCode(locale), /* Fr */1);
              }));
}

var initialState_locale = getLocale(undefined);

var initialState = {
  authenticated: false,
  roles: /* [] */0,
  selectedRole: /* NoRole */9,
  userManager: undefined,
  firstName: "",
  lastName: "",
  ipn: "",
  dealerInfo: /* NotStarted */0,
  locale: initialState_locale
};

function getDynatraceUserTag(name, ipn, profile) {
  var user = "" + name + " " + ipn + "";
  var dealer = profile.dealerInfo;
  return user + (
          dealer !== undefined ? " " + dealer.guaranteeAccount + "" : ""
        );
}

function reducer(state, action) {
  switch (action.TAG | 0) {
    case /* StoreAsAuthenticated */0 :
        var profile = action._0;
        var firstName = Utils_Authentication$DvmAdminFrontendFr.parseTokenFirstName(profile);
        var lastName = Utils_Authentication$DvmAdminFrontendFr.parseTokenLastName(profile);
        var ipn = Utils_Authentication$DvmAdminFrontendFr.parseTokenUid(profile);
        return {
                authenticated: true,
                roles: Utils_Authentication$DvmAdminFrontendFr.parseTokenRoles(profile),
                selectedRole: state.selectedRole,
                userManager: state.userManager,
                firstName: firstName,
                lastName: lastName,
                ipn: ipn,
                dealerInfo: state.dealerInfo,
                locale: state.locale
              };
    case /* StoreUserManager */1 :
        return {
                authenticated: state.authenticated,
                roles: state.roles,
                selectedRole: state.selectedRole,
                userManager: Caml_option.some(action._0),
                firstName: state.firstName,
                lastName: state.lastName,
                ipn: state.ipn,
                dealerInfo: state.dealerInfo,
                locale: state.locale
              };
    case /* StoreUserProfile */2 :
        var profile$1 = action._0;
        App_Types_Result$DvmAdminFrontendFr.mapWithDefault(profile$1, undefined, (function (profile) {
                Utils_SessionStorageManager$DvmAdminFrontendFr.storeDynatraceTagUser(getDynatraceUserTag("" + state.firstName + " " + state.lastName + "", state.ipn, profile));
              }));
        return {
                authenticated: state.authenticated,
                roles: state.roles,
                selectedRole: state.selectedRole,
                userManager: state.userManager,
                firstName: state.firstName,
                lastName: state.lastName,
                ipn: state.ipn,
                dealerInfo: profile$1,
                locale: state.locale
              };
    case /* StoreRole */3 :
        return {
                authenticated: state.authenticated,
                roles: state.roles,
                selectedRole: action._0,
                userManager: state.userManager,
                firstName: state.firstName,
                lastName: state.lastName,
                ipn: state.ipn,
                dealerInfo: state.dealerInfo,
                locale: state.locale
              };
    case /* StoreLocale */4 :
        return {
                authenticated: state.authenticated,
                roles: state.roles,
                selectedRole: state.selectedRole,
                userManager: state.userManager,
                firstName: state.firstName,
                lastName: state.lastName,
                ipn: state.ipn,
                dealerInfo: state.dealerInfo,
                locale: action._0
              };
    
  }
}

function defaultValue_1(param) {
  
}

var defaultValue = [
  initialState,
  defaultValue_1
];

var include = App_Context$DvmAdminFrontendFr.Make({
      defaultValue: defaultValue
    });

var Provider = include.Provider;

var Context_t = include.t;

var Context_useAppContext = include.useAppContext;

var Context = {
  t: Context_t,
  Provider: Provider,
  useAppContext: Context_useAppContext
};

function User_Context$Provider(Props) {
  var children = Props.children;
  var match = React.useReducer(reducer, initialState);
  return React.createElement(Provider.make, {
              value: [
                match[0],
                match[1]
              ],
              children: children
            });
}

var Provider$1 = {
  make: User_Context$Provider
};

export {
  getLocale ,
  initialState ,
  getDynatraceUserTag ,
  reducer ,
  Context ,
  Provider$1 as Provider,
}
/* initialState Not a pure module */
