// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../../common/App_Api.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../common/utils/Utils_Api.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as Quota_Detail_Attachment_Add$DvmAdminFrontendFr from "./modal/Quota_Detail_Attachment_Add.bs.js";
import * as Quota_Detail_Attachment_Api$DvmAdminFrontendFr from "./Quota_Detail_Attachment_Api.bs.js";
import * as Quota_Detail_Attachment_Form$DvmAdminFrontendFr from "./Quota_Detail_Attachment_Form.bs.js";
import * as Quota_Detail_Attachment_Table$DvmAdminFrontendFr from "./Quota_Detail_Attachment_Table.bs.js";
import * as Quota_Detail_Attachment_Filters$DvmAdminFrontendFr from "./Quota_Detail_Attachment_Filters.bs.js";

function Quota_Detail_Attachment(Props) {
  var quota = Props.quota;
  var volumeOfAttachments = Props.volumeOfAttachments;
  var fetchVolumeTotal = Props.fetchVolumeTotal;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setActivePage = match[1];
  var activePage = match[0];
  var match$1 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setInactivePage = match$1[1];
  var inactivePage = match$1[0];
  var match$2 = React.useState(function () {
        return null;
      });
  var setAddRowsModal = match$2[1];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDrAndDealers = match$3[1];
  var drAndDealers = match$3[0];
  var match$4 = React.useState(function () {
        return 1;
      });
  var setActiveTab = match$4[1];
  var activeTab = match$4[0];
  var match$5 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: 10,
                headers: Quota_Detail_Attachment_Table$DvmAdminFrontendFr.headersConfiguration,
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$5[1];
  var fetchRequest = match$5[0];
  var match$6 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$7 = match$6[0];
  var userManager = match$7.userManager;
  var selectedRole = match$7.selectedRole;
  var fetchData = function (submissionCallbacks, fetchRequest) {
    Quota_Detail_Attachment_Api$DvmAdminFrontendFr.fetchQuotaDetailAtatachmentSearch(submissionCallbacks, userManager, setActivePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
              page: fetchRequest.page,
              rowsPerPage: 10,
              headers: fetchRequest.headers,
              filterQueryParams: fetchRequest.filterQueryParams,
              additionalQueryParams: fetchRequest.additionalQueryParams
            }), "active", selectedRole);
    Quota_Detail_Attachment_Api$DvmAdminFrontendFr.fetchQuotaDetailAtatachmentSearch(submissionCallbacks, userManager, setInactivePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
              page: fetchRequest.page,
              rowsPerPage: 10,
              headers: fetchRequest.headers,
              filterQueryParams: fetchRequest.filterQueryParams,
              additionalQueryParams: fetchRequest.additionalQueryParams
            }), "inactive", selectedRole);
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  var activeCount = App_Types_Result$DvmAdminFrontendFr.mapWithDefault(activePage, 0, (function (param) {
          return param.total;
        }));
  var inactiveCount = App_Types_Result$DvmAdminFrontendFr.mapWithDefault(inactivePage, 0, (function (param) {
          return param.total;
        }));
  var form = Quota_Detail_Attachment_Form$DvmAdminFrontendFr.TableDetailAttachmentFilter.useForm({
        siret: "",
        dealerId: "",
        dr: quota.dr,
        contractId: quota.contractId,
        vehicleType: quota.vehicleType,
        startDate: Caml_option.some(quota.startDate),
        endDate: quota.endDate
      }, (function (param, submissionCallbacks) {
          fetchData(submissionCallbacks, {
                page: fetchRequest.page,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: Quota_Detail_Attachment_Table$DvmAdminFrontendFr.headersConfiguration,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                              hd: param.siret,
                              tl: {
                                hd: param.dealerId,
                                tl: {
                                  hd: param.dr,
                                  tl: {
                                    hd: param.contractId,
                                    tl: {
                                      hd: param.vehicleType,
                                      tl: {
                                        hd: param.startDate,
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  var onOk = function (param) {
    Curry._1(fetchVolumeTotal, quota);
    Curry._1(setAddRowsModal, (function (param) {
            return null;
          }));
    fetchData(undefined, fetchRequest);
  };
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.queryForDRAndDealers(alert, userManager, setDrAndDealers, false, selectedRole);
          Curry._1(form.submit, undefined);
        }), []);
  var tmp = {
    onClick: (function (param) {
        Curry._1(setActiveTab, (function (param) {
                return 1;
              }));
      }),
    children: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.active, {
          count: activeCount
        })
  };
  var tmp$1 = activeTab === 1 ? ({
        borderBottom: "solid 5px " + Theme_Colors$DvmAdminFrontendFr.primaryBrown + ""
      }) : undefined;
  if (tmp$1 !== undefined) {
    tmp.style = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = {
    onClick: (function (param) {
        Curry._1(setActiveTab, (function (param) {
                return 2;
              }));
      }),
    children: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.inactive, {
          count: inactiveCount
        })
  };
  var tmp$3 = activeTab === 2 ? ({
        borderBottom: "solid 5px " + Theme_Colors$DvmAdminFrontendFr.primaryBrown + ""
      }) : undefined;
  if (tmp$3 !== undefined) {
    tmp$2.style = Caml_option.valFromOption(tmp$3);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  alignItems: "center",
                  children: null,
                  container: true,
                  justifyContent: "space-between"
                }, React.createElement(Core.Grid, {
                      children: React.createElement(Quota_Detail_Attachment_Filters$DvmAdminFrontendFr.make, {
                            quota: quota,
                            form: form,
                            drAndDealers: drAndDealers
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      alignItems: "center",
                      children: React.createElement(Core.Grid, {
                            children: React.createElement(Core.Grid, {
                                  children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                        onClick: (function (param) {
                                            Curry._1(setAddRowsModal, (function (param) {
                                                    return React.createElement(Quota_Detail_Attachment_Add$DvmAdminFrontendFr.make, {
                                                                quota: quota,
                                                                onClose: (function (param) {
                                                                    Curry._1(setAddRowsModal, (function (param) {
                                                                            return null;
                                                                          }));
                                                                  }),
                                                                attachmentsUpdate: undefined,
                                                                onOk: onOk,
                                                                volumeOfAttachments: volumeOfAttachments,
                                                                drAndDealers: drAndDealers
                                                              });
                                                  }));
                                          }),
                                        label: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.newAttachment),
                                        color: "secondary",
                                        size: "medium"
                                      }),
                                  item: true
                                }),
                            container: true,
                            spacing: 1
                          }),
                      item: true
                    })), React.createElement(Core.Box, {
                  children: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.attachmentModalRemaining, {
                        sum: Belt_Option.getWithDefault(quota.volume, 0) - App_Types_Result$DvmAdminFrontendFr.getWithDefault(volumeOfAttachments, 0) | 0,
                        span: (function (chunk) {
                            return chunk;
                          }),
                        total: Belt_Option.getWithDefault(quota.volume, 0)
                      }),
                  display: "flex",
                  flexDirection: "row-reverse"
                }), React.createElement(Core.Box, {
                  children: null
                }, React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      spacing: 2
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(Core.Button, tmp),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(Core.Button, tmp$2),
                          item: true
                        })), activeTab === 2 ? React.createElement(Quota_Detail_Attachment_Table$DvmAdminFrontendFr.make, {
                        tablePage: inactivePage,
                        fetchRequest: fetchRequest,
                        fetchData: fetchData,
                        quota: quota,
                        fetchVolumeTotal: fetchVolumeTotal
                      }) : React.createElement(Quota_Detail_Attachment_Table$DvmAdminFrontendFr.make, {
                        tablePage: activePage,
                        fetchRequest: fetchRequest,
                        fetchData: fetchData,
                        quota: quota,
                        fetchVolumeTotal: fetchVolumeTotal
                      })), match$2[0]);
}

var Api;

var Table;

var Filter;

var Attachments_Add;

var Form;

var make = Quota_Detail_Attachment;

export {
  Api ,
  Table ,
  Filter ,
  Attachments_Add ,
  Form ,
  make ,
}
/* react Not a pure module */
