// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Action_Referential_Fields_Api$DvmAdminFrontendFr from "./Action_Referential_Fields_Api.bs.js";
import * as Action_Referential_Fields_Form$DvmAdminFrontendFr from "./Action_Referential_Fields_Form.bs.js";
import * as Action_Referential_Fields_Utils$DvmAdminFrontendFr from "./Action_Referential_Fields_Utils.bs.js";
import * as Messages_Action_Referential_Fields$DvmAdminFrontendFr from "../../../intl/messages/actionReferential/Messages_Action_Referential_Fields.bs.js";

function Action_Referential_Fields_Create_Modal(Props) {
  var fieldType = Props.fieldType;
  var onClose = Props.onClose;
  var onOk = Props.onOk;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var alert = ReactAlert.useAlert();
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match$2[1];
  var saveResult = match$2[0];
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(onOk, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  var form = Action_Referential_Fields_Form$DvmAdminFrontendFr.Form.useForm(Action_Referential_Fields_Form$DvmAdminFrontendFr.initialState, (function (output, submissionCallbacks) {
          Action_Referential_Fields_Api$DvmAdminFrontendFr.createRow(userManager, setSaveResult, {
                hd: output.val,
                tl: /* [] */0
              }, submissionCallbacks, selectedRole, fieldType);
        }));
  var tmp = {
    onChange: Curry._1(form.updateVal, (function (param, val) {
            return {
                    val: val
                  };
          })),
    value: form.input.val,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    label: Action_Referential_Fields_Utils$DvmAdminFrontendFr.fieldTypeToMessage(fieldType),
    required: /* OnlyLabel */1
  };
  if (form.valResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.valResult);
  }
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Action_Referential_Fields$DvmAdminFrontendFr.addNewLine) + " - " + intl.formatMessage(Action_Referential_Fields_Utils$DvmAdminFrontendFr.fieldTypeToMessage(fieldType)),
              minWidth: "600px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult),
                    children: React.createElement("form", {
                          onSubmit: (function ($$event) {
                              $$event.preventDefault();
                              Curry._1(form.submit, undefined);
                            })
                        }, React.createElement(Core.Grid, {
                              alignItems: "center",
                              children: null,
                              container: true,
                              direction: "column",
                              spacing: 4
                            }, React.createElement(Core.Grid, {
                                  children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp),
                                  item: true
                                }), React.createElement(Core.Grid, {
                                  children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                        label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                        color: "primary",
                                        size: "medium",
                                        type_: "submit"
                                      }),
                                  item: true
                                })))
                  })
            });
}

var Api;

var Form;

var InputStyles;

var make = Action_Referential_Fields_Create_Modal;

export {
  Api ,
  Form ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
