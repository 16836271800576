// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Dialog$Mui from "rescript-material-ui/src/Dialog.bs.js";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as App_Styles$DvmAdminFrontendFr from "../../../common/App_Styles.bs.js";

function dialog(minWidth) {
  var tmp = {
    height: "100vh",
    margin: "0"
  };
  if (minWidth !== undefined) {
    tmp.minWidth = minWidth;
  }
  return Css.css(tmp);
}

var dialogContent = Css.css({
      padding: "36px 24px 36px 24px"
    });

var dialogContentNoPadding = Css.css({
      overflow: "hidden",
      padding: "0px 0px 0px 0px"
    });

var toolbar = Css.css({
      padding: "unset"
    });

var closeIcon = Css.css({
      color: "inherit",
      position: "absolute",
      right: "1rem"
    });

var container = Css.css({
      height: "unset",
      alignItems: "unset",
      justifyContent: "flex-end"
    });

var Styles = {
  dialog: dialog,
  dialogContent: dialogContent,
  dialogContentNoPadding: dialogContentNoPadding,
  toolbar: toolbar,
  closeIcon: closeIcon,
  container: container
};

function Bonuses_Search_Modal_Element(Props) {
  var open = Props.open;
  var onClose = Props.onClose;
  var title = Props.title;
  var minWidth = Props.minWidth;
  var children = Props.children;
  var paddingOpt = Props.padding;
  var padding = paddingOpt !== undefined ? paddingOpt : true;
  return React.createElement(Core.Dialog, {
              closeAfterTransition: true,
              children: null,
              classes: {
                container: container,
                paper: dialog(minWidth)
              },
              disableEscapeKeyDown: true,
              maxWidth: Dialog$Mui.MaxWidth.md,
              onClose: (function (param, reason) {
                  if (reason === "backdropClick") {
                    return Curry._1(onClose, undefined);
                  }
                  
                }),
              open: open,
              PaperProps: {
                square: true
              }
            }, React.createElement(Core.DialogTitle, {
                  children: React.createElement(Core.AppBar, {
                        elevation: 0,
                        children: React.createElement(Core.Toolbar, {
                              children: null,
                              variant: "dense"
                            }, React.createElement(Core.IconButton, {
                                  onClick: (function (param) {
                                      Curry._1(onClose, undefined);
                                    }),
                                  children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                        size: /* Large */3,
                                        type_: /* Cross */42
                                      }),
                                  className: closeIcon
                                }), React.createElement(Core.Grid, {
                                  children: React.createElement(Core.Grid, {
                                        children: React.createElement(Core.Typography, {
                                              children: title,
                                              classes: {
                                                h4: App_Styles$DvmAdminFrontendFr.bold
                                              },
                                              variant: "h4"
                                            }),
                                        item: true
                                      }),
                                  container: true
                                })),
                        position: "relative",
                        style: {
                          backgroundColor: "white",
                          color: "black"
                        }
                      }),
                  classes: {
                    root: toolbar
                  }
                }), React.createElement(Core.DialogContent, {
                  children: children,
                  className: padding ? dialogContent : dialogContentNoPadding
                }));
}

var make = Bonuses_Search_Modal_Element;

export {
  Styles ,
  make ,
}
/* dialogContent Not a pure module */
