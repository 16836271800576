// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../theme/Theme_Colors.bs.js";
import * as Messages_Orders_Modal$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Modal.bs.js";
import * as Order_Search_Modal_Utils$DvmAdminFrontendFr from "./Order_Search_Modal_Utils.bs.js";

function getButtonElement(total, kind, filterPage, setFilterPage, intl, message) {
  var tmp = {
    onClick: (function (param) {
        Curry._1(setFilterPage, (function (param) {
                return kind;
              }));
      }),
    children: intl.formatMessage(message)
  };
  var tmp$1 = filterPage === kind ? ({
        borderBottom: "solid 5px " + Theme_Colors$DvmAdminFrontendFr.primaryBrown + ""
      }) : undefined;
  if (tmp$1 !== undefined) {
    tmp.style = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(Core.Grid, {
              children: React.createElement(Core.Badge, {
                    anchorOrigin: {
                      horizontal: "right",
                      vertical: "top"
                    },
                    badgeContent: total,
                    children: React.createElement(Core.Button, tmp),
                    color: "primary"
                  }),
              item: true
            });
}

function Order_Search_Modal_Page_Buttons(Props) {
  var filterPage = Props.filterPage;
  var setFilterPage = Props.setFilterPage;
  var form = Props.form;
  var formAdditional = Props.formAdditional;
  var formModalVO = Props.formModalVO;
  var intl = ReactIntl.useIntl();
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              item: true,
              spacing: 2
            }, getButtonElement(Order_Search_Modal_Utils$DvmAdminFrontendFr.handleDateNumber(form), /* Date */0, filterPage, setFilterPage, intl, Messages_Orders_Modal$DvmAdminFrontendFr.dateFilterPage), getButtonElement(Order_Search_Modal_Utils$DvmAdminFrontendFr.handleCustomerNumber(form, formAdditional), /* Customer */1, filterPage, setFilterPage, intl, Messages_Orders_Modal$DvmAdminFrontendFr.customerFilterPage), getButtonElement(Order_Search_Modal_Utils$DvmAdminFrontendFr.handleDealerNumber(form, formAdditional), /* Dealer */2, filterPage, setFilterPage, intl, Messages_Orders_Modal$DvmAdminFrontendFr.dealerFilterPage), getButtonElement(Order_Search_Modal_Utils$DvmAdminFrontendFr.handleCarNumber(formAdditional), /* Car */3, filterPage, setFilterPage, intl, Messages_Orders_Modal$DvmAdminFrontendFr.carFilterPage), getButtonElement(Order_Search_Modal_Utils$DvmAdminFrontendFr.handleVONumber(formModalVO), /* VO */4, filterPage, setFilterPage, intl, Messages_Orders_Modal$DvmAdminFrontendFr.voFilterPage));
}

var Utils;

var make = Order_Search_Modal_Page_Buttons;

export {
  Utils ,
  getButtonElement ,
  make ,
}
/* react Not a pure module */
