// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../../common/App_Api.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../../../common/utils/Utils_Date.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../common/types/App_Types_Date.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as Contract_Edit_Api$DvmAdminFrontendFr from "../Contract_Edit_Api.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../common/components/App_OverlaySpinner.bs.js";
import * as Contract_Edit_Router$DvmAdminFrontendFr from "../Contract_Edit_Router.bs.js";
import * as Messages_Contract_Create$DvmAdminFrontendFr from "../../../../intl/messages/contract/Messages_Contract_Create.bs.js";
import * as Contract_Edit_Main_Info_Form$DvmAdminFrontendFr from "./Contract_Edit_Main_Info_Form.bs.js";
import * as Contract_Edit_Main_Info_Edit_Inputs$DvmAdminFrontendFr from "./Contract_Edit_Main_Info_Edit_Inputs.bs.js";

function Contract_Edit_Main_Info_Edit(Props) {
  var contract = Props.contract;
  var managerIds = Props.managerIds;
  var saveResult = Props.saveResult;
  var setSaveResult = Props.setSaveResult;
  var reload = Props.reload;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var sirens = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSirenName = match$3[1];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setCheckSiren = match$4[1];
  var checkSiren = match$4[0];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setBudgets = match$5[1];
  var match$6 = React.useState(function () {
        return /* NotStarted */0;
      });
  var attachmentCopyCheck = match$6[0];
  var form = Contract_Edit_Main_Info_Form$DvmAdminFrontendFr.ContractForm.useForm(Belt_Option.mapWithDefault(contract, Contract_Edit_Main_Info_Form$DvmAdminFrontendFr.initialState, (function (x) {
              return {
                      budget: Belt_Option.getWithDefault(x.budget, ""),
                      contractID: x.contractID,
                      contractLabel: Belt_Option.getWithDefault(x.contractLabel, ""),
                      siren: Belt_Option.getWithDefault(x.siren, ""),
                      managerID: Belt_Option.getWithDefault(x.managerID, ""),
                      startDate: x.startDate,
                      endDate: x.endDate,
                      referenceLLD: Belt_Option.getWithDefault(x.referenceLLD, ""),
                      newStartDate: undefined,
                      applyNewStartDate: false,
                      copyAttachments: false
                    };
            })), {
        userManager: userManager,
        setSirenName: setSirenName,
        selectedRole: selectedRole
      }, (function (output, submissionCallbacks) {
          Contract_Edit_Api$DvmAdminFrontendFr.saveContract(Belt_Option.isSome(contract) ? /* Update */1 : /* Create */0, userManager, setSaveResult, submissionCallbacks, output.copyAttachments, selectedRole, {
                contractID: output.contractID,
                siren: output.siren,
                startDate: Belt_Option.mapWithDefault(contract, output.startDate, (function (x) {
                        return x.startDate;
                      })),
                endDate: output.applyNewStartDate ? undefined : Belt_Option.mapWithDefault(output.endDate, undefined, (function (x) {
                          return Caml_option.some(new Date(x.setUTCHours(0.0, 0.0, 0.0, 0.0)));
                        })),
                managerID: output.managerID,
                managerName: undefined,
                budget: output.budget,
                contractLabel: output.contractLabel,
                referenceLLD: output.referenceLLD,
                overallEndDate: undefined,
                newStartDate: output.newStartDate,
                editingStartDate: output.startDate
              });
        }));
  React.useEffect((function () {
          Curry._1(form.reset, undefined);
        }), [contract]);
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._2(form.updateApplyNewStartDate, (function (input, applyNewStartDate) {
                    return {
                            budget: input.budget,
                            contractID: input.contractID,
                            contractLabel: input.contractLabel,
                            siren: input.siren,
                            managerID: input.managerID,
                            startDate: input.startDate,
                            endDate: input.endDate,
                            referenceLLD: input.referenceLLD,
                            newStartDate: input.newStartDate,
                            applyNewStartDate: applyNewStartDate,
                            copyAttachments: input.copyAttachments
                          };
                  }), false);
            Curry._2(form.updateNewStartDate, (function (input, newStartDate) {
                    return {
                            budget: input.budget,
                            contractID: input.contractID,
                            contractLabel: input.contractLabel,
                            siren: input.siren,
                            managerID: input.managerID,
                            startDate: input.startDate,
                            endDate: input.endDate,
                            referenceLLD: input.referenceLLD,
                            newStartDate: newStartDate,
                            applyNewStartDate: input.applyNewStartDate,
                            copyAttachments: input.copyAttachments
                          };
                  }), undefined);
            Contract_Edit_Router$DvmAdminFrontendFr.routeToPage(form.input.contractID, form.input.applyNewStartDate ? Utils_Date$DvmAdminFrontendFr.toISODateString(form.input.newStartDate) : Utils_Date$DvmAdminFrontendFr.toISODateString(form.input.startDate), undefined, undefined);
            Curry._1(reload, form.input.applyNewStartDate ? Utils_Date$DvmAdminFrontendFr.toISODateString(form.input.newStartDate) : Utils_Date$DvmAdminFrontendFr.toISODateString(form.input.startDate));
          } else {
            var error = saveResult._0;
            if (error.messageId === "DATE_MISMATCH") {
              Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                        message: {
                          TAG: /* Message */0,
                          _0: Messages_Contract_Create$DvmAdminFrontendFr.datesModifyError
                        }
                      }));
            } else {
              Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                        message: {
                          TAG: /* RestError */2,
                          _0: error
                        }
                      }));
            }
          }
        }), [saveResult]);
  var checkSirenOK = function (param) {
    if (form.input.siren.length > 0 && Belt_Option.mapWithDefault(form.input.startDate, false, App_Types_Date$DvmAdminFrontendFr.isValid) && Belt_Option.mapWithDefault(form.input.endDate, true, App_Types_Date$DvmAdminFrontendFr.isValid) && Belt_Option.mapWithDefault(form.newStartDateResult, !form.input.applyNewStartDate, Belt_Result.isOk) && form.input.contractID.length > 0) {
      return Contract_Edit_Api$DvmAdminFrontendFr.checkIfCanAddSiren(form.input.siren, form.input.applyNewStartDate ? form.input.newStartDate : form.input.startDate, form.input.applyNewStartDate ? undefined : form.input.endDate, setCheckSiren, userManager, form.input.contractID, selectedRole, "add", undefined);
    }
    
  };
  React.useEffect((function () {
          checkSirenOK(undefined);
        }), [
        form.input.startDate,
        form.input.endDate,
        form.input.siren,
        form.input.newStartDate
      ]);
  React.useEffect((function () {
          Curry._2(form.updateCopyAttachments, (function (input, copyAttachments) {
                  return {
                          budget: input.budget,
                          contractID: input.contractID,
                          contractLabel: input.contractLabel,
                          siren: input.siren,
                          managerID: input.managerID,
                          startDate: input.startDate,
                          endDate: input.endDate,
                          referenceLLD: input.referenceLLD,
                          newStartDate: input.newStartDate,
                          applyNewStartDate: input.applyNewStartDate,
                          copyAttachments: copyAttachments
                        };
                }), false);
        }), [form.input.applyNewStartDate]);
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchBudgets(userManager, setBudgets, selectedRole);
        }), []);
  var tmp;
  if (typeof checkSiren === "number" || !(checkSiren.TAG === /* Error */1 && form.input.siren.length > 0)) {
    tmp = null;
  } else {
    var match$7 = App_Types_Result$DvmAdminFrontendFr.Rest.toMessage(checkSiren._0);
    tmp = React.createElement(Core.Grid, {
          children: React.createElement(Core.Tooltip, {
                arrow: true,
                children: React.createElement("div", undefined, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                          size: /* Large */3,
                          type_: /* HelpWarning */0,
                          color: "red"
                        })),
                classes: {
                  tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
                },
                placement: "left-start",
                title: intl.formatMessage(match$7[0])
              }),
          item: true
        });
  }
  return React.createElement(React.Fragment, undefined, React.createElement("form", {
                  onSubmit: (function ($$event) {
                      $$event.preventDefault();
                      Curry._1(form.submit, undefined);
                    })
                }, React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      direction: "row",
                      justifyContent: "center",
                      spacing: 2
                    }, React.createElement(Contract_Edit_Main_Info_Edit_Inputs$DvmAdminFrontendFr.make, {
                          budgets: match$5[0],
                          form: form,
                          managerIds: managerIds,
                          contract: contract,
                          sirenName: match$3[0],
                          setSirenName: setSirenName,
                          sirens: sirens,
                          setSirens: match$2[1],
                          attachmentCopyCheck: attachmentCopyCheck,
                          setAttachmentCopyCheck: match$6[1],
                          checkSirenOK: checkSirenOK
                        }), React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          item: true,
                          justifyContent: "center",
                          spacing: 2
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                    show: App_Types_Result$DvmAdminFrontendFr.isPending(sirens) || App_Types_Result$DvmAdminFrontendFr.isPending(checkSiren) || App_Types_Result$DvmAdminFrontendFr.isPending(attachmentCopyCheck),
                                    children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                          label: intl.formatMessage(Belt_Option.mapWithDefault(contract, Messages_Contract_Create$DvmAdminFrontendFr.createContract, (function (param) {
                                                      return Messages_Contract_Create$DvmAdminFrontendFr.editContract;
                                                    }))),
                                          color: "secondary",
                                          size: "medium",
                                          disabled: App_Types_Result$DvmAdminFrontendFr.isError(checkSiren) || App_Types_Result$DvmAdminFrontendFr.mapWithDefault(attachmentCopyCheck, false, (function (x) {
                                                  return Belt_List.length(x) > 0;
                                                })) && form.input.copyAttachments,
                                          type_: "submit"
                                        })
                                  }),
                              item: true
                            }), tmp))));
}

var Form;

var Api;

var Types;

var Router;

var make = Contract_Edit_Main_Info_Edit;

export {
  Form ,
  Api ,
  Types ,
  Router ,
  make ,
}
/* react Not a pure module */
