// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../api/RestApi.bs.js";
import * as Category_Types$DvmAdminFrontendFr from "./Category_Types.bs.js";

function fetchCategories(submissionCallbacks, queryParams, setResult, userManager, allVisible, selectedRole, withClosedModels) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Category_Types$DvmAdminFrontendFr.CategorySearch.searchResult_decode, undefined, userManager, selectedRole, "/category/search/" + (
          allVisible ? "true" : "false"
        ) + "" + queryParams + "&withClosedModels=" + (
          withClosedModels ? "true" : "false"
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchResult = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchResult
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchCategories */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchVersions(categoryId, queryParams, setResult, userManager, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Category_Types$DvmAdminFrontendFr.VersionSearch.searchResult_decode, undefined, userManager, selectedRole, "/category/versions" + Belt_Option.mapWithDefault(categoryId, "", (function (categoryId) {
                return "/" + categoryId + "";
              })) + "" + queryParams + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchResult = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchResult
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function saveCategory(setResult, userManager, selectedRole, category) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
          TAG: /* Json */0,
          _0: Category_Types$DvmAdminFrontendFr.CategoryCreate.t_encode(category)
        }, userManager, selectedRole, "/category").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

export {
  fetchCategories ,
  fetchVersions ,
  saveCategory ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
