// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Messages_ModelVersionGrid$DvmAdminFrontendFr from "../../../intl/messages/modelVersionGrid/Messages_ModelVersionGrid.bs.js";
import * as ModelVersion_Grid_Manage_Api$DvmAdminFrontendFr from "./ModelVersion_Grid_Manage_Api.bs.js";
import * as ModelVersion_Grid_Manage_Form$DvmAdminFrontendFr from "./ModelVersion_Grid_Manage_Form.bs.js";
import * as ModelVersion_Grid_Manage_Table$DvmAdminFrontendFr from "./ModelVersion_Grid_Manage_Table.bs.js";

function ModelVersion_Grid_Manage_Filters(Props) {
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* [] */0;
      });
  var setContractOptions = match$2[1];
  var contractOptions = match$2[0];
  var match$3 = React.useState(function () {
        return /* [] */0;
      });
  var setContractsClean = match$3[1];
  var form = ModelVersion_Grid_Manage_Form$DvmAdminFrontendFr.TableFilterForm.useForm({
        budget: selectedRole === /* AcountManager */4 ? "PROT" : "",
        contractId: ModelVersion_Grid_Manage_Form$DvmAdminFrontendFr.initialState.contractId
      }, (function (param, submissionCallbacks) {
          Curry._2(fetchData, submissionCallbacks, {
                page: 0,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: ModelVersion_Grid_Manage_Table$DvmAdminFrontendFr.headersConfiguration,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                              hd: param.budget,
                              tl: {
                                hd: param.contractId,
                                tl: /* [] */0
                              }
                            }, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  React.useEffect((function () {
          ModelVersion_Grid_Manage_Api$DvmAdminFrontendFr.queryForContractIdSelect(alert, userManager, setContractOptions, selectedRole);
          Curry._1(form.submit, undefined);
        }), []);
  React.useEffect((function () {
          Curry._1(setContractsClean, (function (param) {
                  return Belt_List.map(contractOptions, (function (x) {
                                return x.contractID;
                              }));
                }));
        }), [contractOptions]);
  React.useEffect((function () {
          Curry._2(form.updateContractId, (function (input, param) {
                  return {
                          budget: input.budget,
                          contractId: ""
                        };
                }), "");
          Curry._1(setContractsClean, (function (param) {
                  if (form.input.budget.length === 0) {
                    return Belt_List.map(contractOptions, (function (x) {
                                  return x.contractID;
                                }));
                  } else {
                    return Belt_List.keepMap(contractOptions, (function (x) {
                                  if (Belt_Option.getWithDefault(x.budget, "") === form.input.budget) {
                                    return x.contractID;
                                  }
                                  
                                }));
                  }
                }));
        }), [form.input.budget]);
  var tmp;
  if (selectedRole === /* AcountManager */4) {
    tmp = null;
  } else {
    var tmp$1 = {
      label: Messages_Common$DvmAdminFrontendFr.budget,
      value: {
        TAG: /* Single */0,
        _0: {
          value: form.input.budget,
          onChange: Curry._1(form.updateBudget, (function (input, budget) {
                  return {
                          budget: budget,
                          contractId: input.contractId
                        };
                }))
        }
      },
      options: {
        TAG: /* Labeled */1,
        _0: {
          hd: {
            label: {
              TAG: /* String */3,
              _0: "LCD"
            },
            value: "LCD"
          },
          tl: {
            hd: {
              label: {
                TAG: /* String */3,
                _0: "LLD"
              },
              value: "LLD"
            },
            tl: {
              hd: {
                label: {
                  TAG: /* String */3,
                  _0: "PROT"
                },
                value: "PROT"
              },
              tl: /* [] */0
            }
          }
        }
      },
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
    };
    if (form.budgetResult !== undefined) {
      tmp$1.validationResult = Caml_option.valFromOption(form.budgetResult);
    }
    tmp = React.createElement(Core.Grid, {
          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$1),
          item: true
        });
  }
  var tmp$2 = {
    label: Messages_ModelVersionGrid$DvmAdminFrontendFr.orderID,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.contractId,
        onChange: Curry._1(form.updateContractId, (function (input, contractId) {
                return {
                        budget: input.budget,
                        contractId: contractId
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: match$3[0]
    },
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.contractIdResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.contractIdResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, tmp, React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$2),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var Form;

var Api;

var Table;

var InputStyles;

var make = ModelVersion_Grid_Manage_Filters;

export {
  Form ,
  Api ,
  Table ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
