// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Button$DvmAdminFrontendFr from "../../../../../../../../common/components/button/App_Button.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../../../../common/styles/App_Styles_Common.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Flash_Edit_Version_Perf_Edit_Api$DvmAdminFrontendFr from "./Flash_Edit_Version_Perf_Edit_Api.bs.js";
import * as Flash_Edit_Version_Perf_Edit_Forms$DvmAdminFrontendFr from "./Flash_Edit_Version_Perf_Edit_Forms.bs.js";

function Flash_Edit_Version_Perf_Edit_New_Period(Props) {
  var flash = Props.flash;
  var selectedVersionsListFiltered = Props.selectedVersionsListFiltered;
  var setResultEdit = Props.setResultEdit;
  var onlyLatest = Props.onlyLatest;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var highestStartDate = Belt_List.get(Belt_List.sort(Belt_List.keepMap(selectedVersionsListFiltered, (function (x) {
                  return x.startDate;
                })), ReDate.compareDesc), 0);
  var versionsToBeCropped = function (date) {
    return Belt_List.keep(selectedVersionsListFiltered, (function (x) {
                  return Belt_Option.mapWithDefault(x.endDate, true, (function (versionEndDate) {
                                return ReDate.isAfter(versionEndDate, date);
                              }));
                }));
  };
  var form = Flash_Edit_Version_Perf_Edit_Forms$DvmAdminFrontendFr.EditVersionsForm.useForm(Flash_Edit_Version_Perf_Edit_Forms$DvmAdminFrontendFr.initialState, {
        operation: "NewPeriod",
        flashStartDate: flash.orderStartDate,
        flashEndDate: flash.orderEndDate,
        highestStartDate: highestStartDate
      }, (function (param, submissionCallbacks) {
          var amount = param.amount;
          Belt_Option.mapWithDefault(param.date, undefined, (function (date) {
                  Flash_Edit_Version_Perf_Edit_Api$DvmAdminFrontendFr.perfNewHigherPeriod(setResultEdit, userManager, submissionCallbacks, Belt_List.map(selectedVersionsListFiltered, (function (x) {
                              return {
                                      actionID: flash.actionId,
                                      modelVersionID: x.modelVersionID,
                                      oldStartDate: undefined,
                                      startDate: Caml_option.some(date),
                                      endDate: undefined,
                                      maxBonus: amount
                                    };
                            })), Belt_List.map(versionsToBeCropped(date), (function (x) {
                              return {
                                      actionID: flash.actionId,
                                      modelVersionID: x.modelVersionID,
                                      oldStartDate: undefined,
                                      startDate: x.startDate,
                                      endDate: Caml_option.some(ReDate.addDays(date, -1.0)),
                                      maxBonus: x.maxBonus
                                    };
                            })), flash.actionId, selectedRole);
                }));
        }));
  React.useEffect((function () {
          Curry._2(form.updateDate, (function (input, date) {
                  return {
                          amount: input.amount,
                          date: date
                        };
                }), form.input.date);
        }), [selectedVersionsListFiltered]);
  var tmp = {
    onChange: Curry._1(form.updateAmount, (function (input, amount) {
            return {
                    amount: amount,
                    date: input.date
                  };
          })),
    value: form.input.amount,
    label: Messages_Common$DvmAdminFrontendFr.amount,
    InputProps: {
      startAdornment: React.createElement(Core.InputAdornment, {
            children: "€",
            position: "start"
          })
    },
    inputType: "number"
  };
  if (form.amountResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.amountResult);
  }
  var tmp$1 = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.date,
    onChange: Curry._1(form.updateDate, (function (input, date) {
            return {
                    amount: input.amount,
                    date: date
                  };
          }))
  };
  if (form.dateResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.dateResult);
  }
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              direction: "column",
              item: true
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        gutterBottom: true,
                        style: {
                          fontWeight: "600"
                        },
                        children: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.newMaxAmount),
                        variant: "bodyBig"
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement("form", {
                        onSubmit: (function ($$event) {
                            $$event.preventDefault();
                            Curry._1(form.submit, undefined);
                          })
                      }, React.createElement(Core.Grid, {
                            alignItems: "flex-start",
                            children: null,
                            container: true,
                            spacing: 3
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$1),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: onlyLatest ? React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                        label: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.applyToResults),
                                        color: "secondary",
                                        size: "medium",
                                        disabled: !onlyLatest,
                                        type_: "submit"
                                      }) : React.createElement(Core.Tooltip, {
                                        children: React.createElement("div", undefined, React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                  label: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.applyToResults),
                                                  color: "secondary",
                                                  size: "medium",
                                                  disabled: !onlyLatest,
                                                  type_: "submit"
                                                })),
                                        classes: {
                                          tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
                                        },
                                        placement: "right",
                                        title: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.hasToSelectOnlyLatestTooltip)
                                      }),
                                item: true
                              }))),
                  item: true
                }));
}

var Form;

var Api;

var Types;

var make = Flash_Edit_Version_Perf_Edit_New_Period;

export {
  Form ,
  Api ,
  Types ,
  make ,
}
/* react Not a pure module */
