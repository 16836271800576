// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Messages_Energy$DvmAdminFrontendFr from "../../../intl/messages/energy/Messages_Energy.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_TextFieldFormInput.bs.js";

function Energy_Edit_Inputs(Props) {
  var form = Props.form;
  var tmp = {
    onChange: (function (val) {
        Curry._2(form.updateOriginalEngineType, (function (input, originalEngineType) {
                return {
                        originalEngineType: originalEngineType,
                        identificationEngineType: input.identificationEngineType
                      };
              }), val.toUpperCase());
      }),
    value: form.input.originalEngineType,
    disabled: true,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.extraLongInput,
    label: Messages_Energy$DvmAdminFrontendFr.originalValue,
    required: /* OnlyLabel */1
  };
  if (form.originalEngineTypeResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.originalEngineTypeResult);
  }
  var tmp$1 = {
    onChange: (function (val) {
        Curry._2(form.updateIdentificationEngineType, (function (input, identificationEngineType) {
                return {
                        originalEngineType: input.originalEngineType,
                        identificationEngineType: identificationEngineType
                      };
              }), val.toUpperCase());
      }),
    value: form.input.identificationEngineType,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.extraLongInput,
    label: Messages_Energy$DvmAdminFrontendFr.attachedValue,
    required: /* OnlyLabel */1
  };
  if (form.identificationEngineTypeResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.identificationEngineTypeResult);
  }
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              direction: "column",
              item: true,
              spacing: 1
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$1),
                  item: true
                }));
}

var InputStyles;

var Api;

var Types;

var Form;

var make = Energy_Edit_Inputs;

export {
  InputStyles ,
  Api ,
  Types ,
  Form ,
  make ,
}
/* react Not a pure module */
