// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../../../../common/components/button/App_Button.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../../../../intl/messages/Messages_Button.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Flash_Edit_Version_Perf_Edit_Table$DvmAdminFrontendFr from "./Flash_Edit_Version_Perf_Edit_Table.bs.js";
import * as Flash_Edit_Version_Perf_Edit_Inputs$DvmAdminFrontendFr from "./Flash_Edit_Version_Perf_Edit_Inputs.bs.js";
import * as Flash_Edit_Version_Perf_Edit_Filters$DvmAdminFrontendFr from "./Flash_Edit_Version_Perf_Edit_Filters.bs.js";

function Flash_Edit_Version_Perf_Edit_Modal(Props) {
  var flash = Props.flash;
  var onClose = Props.onClose;
  var onOk = Props.onOk;
  var actionAllVersions = Props.actionAllVersions;
  var refreshAndFetch = Props.refreshAndFetch;
  var withClosedModels = Props.withClosedModels;
  var setWithClosedModels = Props.setWithClosedModels;
  var versionsListResult = Props.versionsListResult;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var resultEdit = match[0];
  var match$1 = React.useState(function () {
        return 0;
      });
  var setPage = match$1[1];
  var page = match$1[0];
  var match$2 = React.useState(function () {
        return 0;
      });
  var match$3 = React.useState(function () {
        return true;
      });
  var onlyLatest = match$3[0];
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var setSelectedVersionsList = match$4[1];
  var match$5 = React.useState(function () {
        return /* [] */0;
      });
  var match$6 = React.useState(function () {
        return /* [] */0;
      });
  React.useEffect((function () {
          if (actionAllVersions !== undefined) {
            Curry._1(setSelectedVersionsList, (function (param) {
                    return actionAllVersions;
                  }));
          }
          
        }), [actionAllVersions]);
  React.useEffect((function () {
          Curry._1(refreshAndFetch, undefined);
        }), [withClosedModels]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.addVersions),
              minWidth: "1200px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(resultEdit) || App_Types_Result$DvmAdminFrontendFr.isPending(versionsListResult),
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          spacing: 2
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(Flash_Edit_Version_Perf_Edit_Filters$DvmAdminFrontendFr.make, {
                                    selectedVersionsList: match$4[0],
                                    setSelectedVersionsListFiltered: match$5[1],
                                    page: page,
                                    setPage: setPage,
                                    rowsPerPage: 10,
                                    setTotalFilteredRows: match$2[1],
                                    onlyLatest: onlyLatest,
                                    setOnlylatest: match$3[1],
                                    setSelectedVersionsListFilteredPaged: match$6[1],
                                    withClosedModels: withClosedModels,
                                    setWithClosedModels: setWithClosedModels
                                  }),
                              container: true,
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(Flash_Edit_Version_Perf_Edit_Table$DvmAdminFrontendFr.make, {
                                    selectedVersionsListFilteredPaged: match$6[0],
                                    page: page,
                                    setPage: setPage,
                                    rowsPerPage: 10,
                                    totalFilteredRows: match$2[0]
                                  }),
                              container: true,
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(Flash_Edit_Version_Perf_Edit_Inputs$DvmAdminFrontendFr.make, {
                                    selectedVersionsListFiltered: match$5[0],
                                    flash: flash,
                                    refreshAndFetch: refreshAndFetch,
                                    resultEdit: resultEdit,
                                    setResultEdit: match[1],
                                    onlyLatest: onlyLatest
                                  }),
                              container: true,
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                    onClick: (function (param) {
                                        Curry._1(onOk, undefined);
                                      }),
                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.close),
                                    size: "medium"
                                  }),
                              container: true,
                              item: true,
                              justifyContent: "flex-end"
                            }))
                  })
            });
}

var Types;

var make = Flash_Edit_Version_Perf_Edit_Modal;

export {
  Types ,
  make ,
}
/* react Not a pure module */
