// Generated by ReScript, PLEASE EDIT WITH CARE


var title = {
  id: "752d185b-751e-4615-a91b-1faed766f1a8",
  defaultMessage: "Suivi des anomalies"
};

var errorDescription = {
  id: "fd7117b6-3548-4647-82a1-1eed3a651d97",
  defaultMessage: "Motif d'anomalie"
};

var resultInfo = {
  id: "88db87bb-3bb2-4d6a-8e56-9a5ef78c4886",
  defaultMessage: "anomalies"
};

var anomalieDelivered = {
  id: "d2fe650b-5c55-4be4-9329-990863cc6243",
  defaultMessage: "Livré"
};

var anomalieNonDelivered = {
  id: "3faf47c9-aa10-4499-8791-dace2790413d",
  defaultMessage: "Non livré"
};

var dealerKeyAccountManager = {
  id: "3d7b8d20-411f-4e3f-b4f3-40d44c618cfb",
  defaultMessage: "ME"
};

var messageBeforeSearch = {
  id: "4f00f6fe-212b-4828-bb37-b0451b2adb14",
  defaultMessage: "Pour la recherche d’anomalies, vous devez sélectionner un budget et un type d’aide."
};

export {
  title ,
  errorDescription ,
  resultInfo ,
  anomalieDelivered ,
  anomalieNonDelivered ,
  dealerKeyAccountManager ,
  messageBeforeSearch ,
}
/* No side effect */
