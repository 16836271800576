// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as App_Modal$DvmAdminFrontendFr from "../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as Home_Types$DvmAdminFrontendFr from "../Home_Types.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Home_Message$DvmAdminFrontendFr from "../../../intl/messages/home/Messages_Home_Message.bs.js";
import * as Home_Messages_Modal_Input$DvmAdminFrontendFr from "./Home_Messages_Modal_Input.bs.js";

function Home_Messages_Modal_Update(Props) {
  var onClose = Props.onClose;
  var onOk = Props.onOk;
  var message = Props.message;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return message.title;
      });
  var setTitle = match$2[1];
  var title = match$2[0];
  var match$3 = React.useState(function () {
        return message.message;
      });
  var setMessageText = match$3[1];
  var messageText = match$3[0];
  var match$4 = React.useState(function () {
        return message.group;
      });
  var setGroup = match$4[1];
  var group = match$4[0];
  var match$5 = React.useState(function () {
        return false;
      });
  var setSaving = match$5[1];
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Home_Message$DvmAdminFrontendFr.messagesModalTitleUpdate),
              minWidth: "500px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: match$5[0],
                    children: null
                  }, React.createElement(Home_Messages_Modal_Input$DvmAdminFrontendFr.make, {
                        data: {
                          title: title,
                          message: messageText,
                          group: group
                        },
                        setTitle: (function (value) {
                            Curry._1(setTitle, (function (param) {
                                    return value;
                                  }));
                          }),
                        setMessage: (function (value) {
                            Curry._1(setMessageText, (function (param) {
                                    return value;
                                  }));
                          }),
                        setGroup: (function (value) {
                            Curry._1(setGroup, (function (param) {
                                    return value;
                                  }));
                          })
                      }), React.createElement(Core.Box, {
                        children: null,
                        display: "flex",
                        marginTop: 7
                      }, React.createElement(Core.Box, {
                            flexGrow: 1
                          }), React.createElement(App_Button$DvmAdminFrontendFr.make, {
                            onClick: (function (param) {
                                Curry._1(setSaving, (function (param) {
                                        return true;
                                      }));
                                RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", undefined, userManager, selectedRole, "/dashboard/messages/" + message.uuid + "").then(function (result) {
                                      if (result.TAG === /* OkResult */0) {
                                        Curry._1(onOk, undefined);
                                      } else {
                                        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                                                  message: {
                                                    TAG: /* RestError */2,
                                                    _0: result._0
                                                  }
                                                }));
                                      }
                                      Curry._1(setSaving, (function (param) {
                                              return false;
                                            }));
                                    });
                              }),
                            label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.$$delete),
                            color: "delete"
                          }), React.createElement(Core.Box, {
                            children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                  onClick: (function (param) {
                                      var msg = {
                                        uuid: message.uuid,
                                        creator: message.creator,
                                        creatorName: message.creatorName,
                                        createDate: message.createDate,
                                        title: title,
                                        message: messageText,
                                        group: group,
                                        noted: message.noted,
                                        editor: message.editor,
                                        editDate: message.editDate
                                      };
                                      Curry._1(setSaving, (function (param) {
                                              return true;
                                            }));
                                      RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("PUT", {
                                              TAG: /* Json */0,
                                              _0: Home_Types$DvmAdminFrontendFr.homeMessage_encode(msg)
                                            }, userManager, selectedRole, "/dashboard/messages/" + message.uuid + "").then(function (result) {
                                            if (result.TAG === /* OkResult */0) {
                                              Curry._1(onOk, undefined);
                                            } else {
                                              Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                                                        message: {
                                                          TAG: /* RestError */2,
                                                          _0: result._0
                                                        }
                                                      }));
                                            }
                                            Curry._1(setSaving, (function (param) {
                                                    return false;
                                                  }));
                                          });
                                    }),
                                  label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm)
                                }),
                            marginLeft: 3
                          })))
            });
}

var make = Home_Messages_Modal_Update;

export {
  make ,
}
/* react Not a pure module */
