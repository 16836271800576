// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as App_Clickable_Element$DvmAdminFrontendFr from "../../../../common/components/button/App_Clickable_Element.bs.js";
import * as Messages_Orders_Correction$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Correction.bs.js";
import * as Order_Detail_Old_Vehicle_Edits_Modal$DvmAdminFrontendFr from "./edits/Order_Detail_Old_Vehicle_Edits_Modal.bs.js";

function Order_Detail_Old_Vehicle_Add(Props) {
  var newestUsedCarValues = Props.newestUsedCarValues;
  var orderId = Props.orderId;
  var reloadPage = Props.reloadPage;
  var order = Props.order;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return null;
      });
  var setModal = match[1];
  return React.createElement(React.Fragment, undefined, React.createElement(App_Clickable_Element$DvmAdminFrontendFr.make, {
                  onClick: (function (param) {
                      Curry._1(setModal, (function (param) {
                              return React.createElement(Order_Detail_Old_Vehicle_Edits_Modal$DvmAdminFrontendFr.make, {
                                          onClose: (function (param) {
                                              Curry._1(setModal, (function (param) {
                                                      return null;
                                                    }));
                                            }),
                                          orderId: orderId,
                                          order: order,
                                          reloadPage: reloadPage,
                                          newestUsedCarValues: newestUsedCarValues,
                                          addNew: true
                                        });
                            }));
                    }),
                  children: React.createElement(Core.Grid, {
                        children: null,
                        container: true,
                        spacing: 1
                      }, React.createElement(Core.Grid, {
                            children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                  size: /* Large */3,
                                  type_: /* More */17
                                }),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.appendCarInfo),
                            item: true
                          }))
                }), match[0]);
}

var make = Order_Detail_Old_Vehicle_Add;

export {
  make ,
}
/* react Not a pure module */
