// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";

function button(padding) {
  var tmp = {
    cursor: "pointer"
  };
  var tmp$1 = padding ? "5px" : undefined;
  if (tmp$1 !== undefined) {
    tmp.padding = tmp$1;
  }
  return Css.css(Object.assign({}, tmp, {
                  "&:hover": {
                    backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight2
                  }
                }));
}

function disabledStyle(padding) {
  var tmp = {
    backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayBackground,
    color: Theme_Colors$DvmAdminFrontendFr.grayHover
  };
  var tmp$1 = padding ? "5px" : undefined;
  if (tmp$1 !== undefined) {
    tmp.padding = tmp$1;
  }
  return Css.css(tmp);
}

function App_Clickable_Element(Props) {
  var onClick = Props.onClick;
  var disabledOpt = Props.disabled;
  var children = Props.children;
  var paddingOpt = Props.padding;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var padding = paddingOpt !== undefined ? paddingOpt : true;
  var tmp = {
    className: disabled ? disabledStyle(padding) : button(padding)
  };
  var tmp$1 = disabled ? undefined : onClick;
  if (tmp$1 !== undefined) {
    tmp.onClick = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("div", tmp, children);
}

var make = App_Clickable_Element;

export {
  button ,
  disabledStyle ,
  make ,
}
/* react Not a pure module */
