// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../../api/RestApi.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Order_Detail_Old_Vehicle_Edits_Types$DvmAdminFrontendFr from "./Order_Detail_Old_Vehicle_Edits_Types.bs.js";

function updatePoliceNumber(userManager, setResult, orderId, intl, submissionCallbacks, selectedRole, policeNumber) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("PUT", undefined, userManager, selectedRole, "/orders/police/" + orderId + "?policeNumber=" + policeNumber + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.modifySuccess)
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToUpdateOldVehicle */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function postDealerEditCorrection(userManager, setResult, orderId, submissionCallbacks, selectedRole, dealerEditsCorrection) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
          TAG: /* Json */0,
          _0: Order_Detail_Old_Vehicle_Edits_Types$DvmAdminFrontendFr.dealerEditsCorrection_encode(dealerEditsCorrection)
        }, userManager, selectedRole, "/orders/correction/dealer/" + orderId + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: ""
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToUpdateOldVehicle */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var VehicleCorrectionForm;

var Types;

export {
  VehicleCorrectionForm ,
  Types ,
  updatePoliceNumber ,
  postDealerEditCorrection ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
