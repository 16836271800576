// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../../../intl/messages/Messages_Common.bs.js";
import * as App_TransferListFormInput$DvmAdminFrontendFr from "../../../../../../../../common/components/input/App_TransferListFormInput.bs.js";

function Flash_Edit_Version_Perf_New_Selection(Props) {
  var filteredOptions = Props.filteredOptions;
  var actionDistinctVersions = Props.actionDistinctVersions;
  var selectedVersionsList = Props.selectedVersionsList;
  var setSelectedVersionsList = Props.setSelectedVersionsList;
  var intl = ReactIntl.useIntl();
  var availableOptions = Belt_List.keep(filteredOptions, (function (element) {
          return !Belt_List.some(actionDistinctVersions, (function (x) {
                        return x.modelVersionID === element.modelVersionID;
                      }));
        }));
  var mapper = function (list) {
    return Belt_List.map(list, (function (x) {
                  return {
                          label: {
                            TAG: /* String */3,
                            _0: "" + x.model + " - " + x.version + " (" + Belt_Option.getWithDefault(x.versionName, "") + ")"
                          },
                          value: x.modelVersionID
                        };
                }));
  };
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: React.createElement(Core.Grid, {
                        children: React.createElement(App_TransferListFormInput$DvmAdminFrontendFr.make, {
                              values: mapper(selectedVersionsList),
                              options: mapper(availableOptions),
                              onChange: (function (val) {
                                  var $$new = Belt_List.keepMap(Belt_List.map(Belt_List.keep(val, (function (x) {
                                                  return !Belt_List.has(selectedVersionsList, x.value, (function (a, b) {
                                                                return a.modelVersionID === b;
                                                              }));
                                                })), (function (x) {
                                              return Belt_List.getBy(filteredOptions, (function (y) {
                                                            return y.modelVersionID === x.value;
                                                          }));
                                            })), (function (x) {
                                          return x;
                                        }));
                                  var toKeep = Belt_List.keep(selectedVersionsList, (function (x) {
                                          return Belt_List.has(val, x.modelVersionID, (function (a, b) {
                                                        return a.value === b;
                                                      }));
                                        }));
                                  Curry._1(setSelectedVersionsList, (function (param) {
                                          return Belt_List.concat($$new, toKeep);
                                        }));
                                }),
                              boxHeight: 260,
                              boxLabels: {
                                left: {
                                  TAG: /* String */3,
                                  _0: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.transferListOptions, {
                                        count: Belt_List.length(Belt_List.keep(availableOptions, (function (element) {
                                                    return !Belt_List.some(selectedVersionsList, (function (x) {
                                                                  return x.modelVersionID === element.modelVersionID;
                                                                }));
                                                  })))
                                      })
                                },
                                right: {
                                  TAG: /* String */3,
                                  _0: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.transferListSelected, {
                                        count: Belt_List.length(selectedVersionsList)
                                      })
                                }
                              }
                            }),
                        item: true,
                        sm: Grid$Mui.Sm[12]
                      }),
                  container: true,
                  spacing: 2
                }));
}

var Form;

var Types;

var make = Flash_Edit_Version_Perf_New_Selection;

export {
  Form ,
  Types ,
  make ,
}
/* react Not a pure module */
