// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.bs.js";
import * as App_Api$DvmAdminFrontendFr from "../../../common/App_Api.bs.js";
import * as App_Modal$DvmAdminFrontendFr from "../../../common/components/App_Modal.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../../common/utils/Utils_Date.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Quotas_PARC$DvmAdminFrontendFr from "../../../intl/messages/quotas/Messages_Quotas_PARC.bs.js";
import * as Quota_PARC_Manage_Api$DvmAdminFrontendFr from "./Quota_PARC_Manage_Api.bs.js";
import * as Quota_PARC_Manage_Form$DvmAdminFrontendFr from "./Quota_PARC_Manage_Form.bs.js";
import * as Quota_PARC_Manage_Inputs$DvmAdminFrontendFr from "./Quota_PARC_Manage_Inputs.bs.js";
import * as Quota_PARC_Manage_Create_Options$DvmAdminFrontendFr from "./Quota_PARC_Manage_Create_Options.bs.js";

function Quota_PARC_Manage_Modal(Props) {
  var onClose = Props.onClose;
  var operation = Props.operation;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var inputBoxRef = React.useRef(null);
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match$2[1];
  var saveResult = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setAllModels = match$3[1];
  var allModels = match$3[0];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDrAndDealers = match$4[1];
  var drAndDealers = match$4[0];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setConflicts = match$5[1];
  var conflicts = match$5[0];
  var match$6 = React.useState(function () {
        return 0.0;
      });
  var setInputsHeight = match$6[1];
  var inputsHeight = match$6[0];
  var debounce = ReactDebounce.useDebounced(500, (function (formState) {
          Quota_PARC_Manage_Api$DvmAdminFrontendFr.getParcQuotaConflicts(userManager, setConflicts, selectedRole, formState);
        }));
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchModelVersions(setAllModels, userManager, selectedRole, undefined, undefined, undefined);
          App_Api$DvmAdminFrontendFr.queryForDRAndDealers(alert, userManager, setDrAndDealers, false, selectedRole);
        }), []);
  var tmp;
  if (operation) {
    var row = operation._0;
    tmp = {
      orderType: Belt_Option.getWithDefault(row.orderType, ""),
      dealerId: Belt_Option.getWithDefault(row.dealerId, ""),
      modelId: Belt_Option.getWithDefault(row.modelId, ""),
      phase: Belt_Option.getWithDefault(row.phase, ""),
      engineType: Belt_Option.getWithDefault(row.engineType, ""),
      volume: Belt_Option.mapWithDefault(row.volume, "", (function (prim) {
              return String(prim);
            })),
      startDate: row.startDate,
      endDate: row.endDate
    };
  } else {
    tmp = Quota_PARC_Manage_Form$DvmAdminFrontendFr.initialInput;
  }
  var form = Quota_PARC_Manage_Form$DvmAdminFrontendFr.QuotaPARCEditForm.useForm(tmp, (function (output, submissionCallbacks) {
          Quota_PARC_Manage_Api$DvmAdminFrontendFr.savePARCQuota(operation, userManager, setSaveResult, submissionCallbacks, selectedRole, operation ? operation._0.id : undefined, conflicts, output);
        }));
  React.useEffect((function () {
          var tmp = false;
          var tmp$1 = false;
          if (form.input.dealerId.length > 0 && form.input.orderType.length > 0) {
            var date = Utils_Date$DvmAdminFrontendFr.toISODateString(form.input.startDate);
            tmp$1 = date !== undefined ? date.length !== 0 : false;
          }
          if (tmp$1) {
            var date$1 = Utils_Date$DvmAdminFrontendFr.toISODateString(form.input.endDate);
            tmp = date$1 !== undefined ? date$1.length !== 0 : false;
          }
          if (tmp) {
            Curry._1(debounce, form);
          }
          
        }), [
        form.input.dealerId,
        form.input.orderType,
        form.input.startDate,
        form.input.endDate,
        form.input.modelId,
        form.input.phase,
        form.input.engineType
      ]);
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._2(fetchData, undefined, fetchRequest);
            Curry._1(onClose, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  React.useEffect((function () {
          if (inputsHeight > 100) {
            
          } else {
            Curry._1(setInputsHeight, (function (param) {
                    return Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(inputBoxRef.current), 0.0, (function (x) {
                                  return x.getBoundingClientRect().height;
                                }));
                  }));
          }
        }), [inputBoxRef.current]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(operation ? Messages_Common$DvmAdminFrontendFr.modifyRow : Messages_Quotas_PARC$DvmAdminFrontendFr.modalTitle),
              minWidth: "800px",
              maxWidth: "800px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult),
                    children: operation ? React.createElement(Quota_PARC_Manage_Inputs$DvmAdminFrontendFr.make, {
                            operation: operation,
                            form: form,
                            onClose: onClose,
                            allModels: allModels,
                            drAndDealers: drAndDealers,
                            inputBoxRef: inputBoxRef,
                            conflicts: conflicts
                          }) : React.createElement(Quota_PARC_Manage_Create_Options$DvmAdminFrontendFr.make, {
                            form: form,
                            onClose: onClose,
                            operation: operation,
                            allModels: allModels,
                            drAndDealers: drAndDealers,
                            inputBoxRef: inputBoxRef,
                            inputsHeight: inputsHeight,
                            conflicts: conflicts
                          })
                  })
            });
}

var Msg;

var Api;

var Types;

var Form;

var make = Quota_PARC_Manage_Modal;

export {
  Msg ,
  Api ,
  Types ,
  Form ,
  make ,
}
/* react Not a pure module */
