// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function dealerCodes_encode(v) {
  return Js_dict.fromArray([
              [
                "dr",
                Decco.optionToJson(Decco.stringToJson, v.dr)
              ],
              [
                "entityCode",
                Decco.optionToJson(Decco.stringToJson, v.entityCode)
              ],
              [
                "dealerID",
                Decco.optionToJson(Decco.stringToJson, v.dealerID)
              ],
              [
                "dealerName",
                Decco.optionToJson(Decco.stringToJson, v.dealerName)
              ],
              [
                "agentCode",
                Decco.optionToJson(Decco.stringToJson, v.agentCode)
              ]
            ]);
}

function dealerCodes_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var dr = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "dr"), null));
  if (dr.TAG === /* Ok */0) {
    var entityCode = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "entityCode"), null));
    if (entityCode.TAG === /* Ok */0) {
      var dealerID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerID"), null));
      if (dealerID.TAG === /* Ok */0) {
        var dealerName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerName"), null));
        if (dealerName.TAG === /* Ok */0) {
          var agentCode = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "agentCode"), null));
          if (agentCode.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      dr: dr._0,
                      entityCode: entityCode._0,
                      dealerID: dealerID._0,
                      dealerName: dealerName._0,
                      agentCode: agentCode._0
                    }
                  };
          }
          var e = agentCode._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".agentCode" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = dealerName._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".dealerName" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = dealerID._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".dealerID" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = entityCode._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".entityCode" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = dr._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".dr" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function dealerCodesList_encode(v) {
  return Decco.listToJson(dealerCodes_encode, v);
}

function dealerCodesList_decode(v) {
  return Decco.listFromJson(dealerCodes_decode, v);
}

function profile_encode(v) {
  return Js_dict.fromArray([
              [
                "userID",
                Decco.stringToJson(v.userID)
              ],
              [
                "dealerCodes",
                Decco.listToJson(dealerCodes_encode, v.dealerCodes)
              ]
            ]);
}

function profile_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var userID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "userID"), null));
  if (userID.TAG === /* Ok */0) {
    var dealerCodes = Decco.listFromJson(dealerCodes_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerCodes"), null));
    if (dealerCodes.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                userID: userID._0,
                dealerCodes: dealerCodes._0
              }
            };
    }
    var e = dealerCodes._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".dealerCodes" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = userID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".userID" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function t_encode(v) {
  return Decco.listToJson(profile_encode, v);
}

function t_decode(v) {
  return Decco.listFromJson(profile_decode, v);
}

var VisibilityProfilesList = {
  dealerCodes_encode: dealerCodes_encode,
  dealerCodes_decode: dealerCodes_decode,
  dealerCodesList_encode: dealerCodesList_encode,
  dealerCodesList_decode: dealerCodesList_decode,
  profile_encode: profile_encode,
  profile_decode: profile_decode,
  t_encode: t_encode,
  t_decode: t_decode
};

export {
  VisibilityProfilesList ,
}
/* No side effect */
