// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as Dealer_Detail_Api$DvmAdminFrontendFr from "./Dealer_Detail_Api.bs.js";
import * as Dealer_Detail_Table$DvmAdminFrontendFr from "./Dealer_Detail_Table.bs.js";
import * as Dealer_Detail_Styles$DvmAdminFrontendFr from "./Dealer_Detail_Styles.bs.js";
import * as Messages_Dealer_Detail$DvmAdminFrontendFr from "../../../intl/messages/dealer/Messages_Dealer_Detail.bs.js";
import * as Dealer_Detail_Main_Info$DvmAdminFrontendFr from "./Dealer_Detail_Main_Info.bs.js";

function Dealer_Detail_Page(Props) {
  var dealerId = Props.dealerId;
  var startDate = Props.startDate;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$2[1];
  var result = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match$3[1];
  var tablePage = match$3[0];
  var match$4 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: 10,
                headers: Dealer_Detail_Table$DvmAdminFrontendFr.headersConfiguration,
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$4[1];
  var fetchRequest = match$4[0];
  var fetchData = function (fetchRequest) {
    Dealer_Detail_Api$DvmAdminFrontendFr.fetchAgents(userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, fetchRequest) + ("&filter=dealerID=" + dealerId + ""), selectedRole);
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  React.useEffect((function () {
          if (typeof tablePage !== "number" && tablePage.TAG === /* Error */1) {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: tablePage._0
                      }
                    }));
          }
          
        }), [tablePage]);
  React.useEffect((function () {
          fetchData(fetchRequest);
          Dealer_Detail_Api$DvmAdminFrontendFr.fetchDealerDetail(dealerId, userManager, setResult, Belt_Option.getWithDefault(startDate, new Date(0.0)).toISOString(), selectedRole);
        }), []);
  if (typeof result === "number") {
    if (result === /* NotStarted */0) {
      return null;
    } else {
      return React.createElement(Core.Grid, {
                  children: React.createElement(Core.CircularProgress, {
                        size: 100
                      }),
                  container: true,
                  justifyContent: "center"
                });
    }
  } else if (result.TAG === /* Ok */0) {
    return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                    children: null,
                    container: true,
                    direction: "column",
                    spacing: 5
                  }, React.createElement(Core.Grid, {
                        children: React.createElement(Dealer_Detail_Main_Info$DvmAdminFrontendFr.make, {
                              dealerDetail: result._0
                            }),
                        item: true
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(Core.Paper, {
                              children: null,
                              className: Dealer_Detail_Styles$DvmAdminFrontendFr.detailsPaper,
                              square: true
                            }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                  color: "primary",
                                  gutterBottom: true,
                                  children: intl.formatMessage(Messages_Dealer_Detail$DvmAdminFrontendFr.listOfAgents),
                                  variant: "h3"
                                }), React.createElement(Dealer_Detail_Table$DvmAdminFrontendFr.make, {
                                  tablePage: tablePage,
                                  fetchRequest: fetchRequest,
                                  fetchData: fetchData
                                })),
                        item: true
                      })));
  } else {
    return React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                color: "error",
                children: "Affaire Primaire introuvable",
                variant: "h2"
              });
  }
}

var Table;

var Api;

var make = Dealer_Detail_Page;

export {
  Table ,
  Api ,
  make ,
}
/* react Not a pure module */
