// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";

function Simple_Menu(Props) {
  var children = Props.children;
  var title = Props.title;
  var disabled = Props.disabled;
  var horizontalAnchorOpt = Props.horizontalAnchor;
  var verticalAnchorOpt = Props.verticalAnchor;
  var horizontalAnchor = horizontalAnchorOpt !== undefined ? horizontalAnchorOpt : "left";
  var verticalAnchor = verticalAnchorOpt !== undefined ? verticalAnchorOpt : "bottom";
  var match = React.useState(function () {
        
      });
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var vertical = verticalAnchor === "top" ? "top" : "bottom";
  var verticalInvert = verticalAnchor === "top" ? "bottom" : "top";
  var horizontal = horizontalAnchor === "right" ? "right" : "left";
  var tmp = {
    onClick: (function ($$event) {
        Curry._1(setAnchorEl, (function (param) {
                return Caml_option.some($$event.currentTarget);
              }));
      }),
    children: title
  };
  if (disabled !== undefined) {
    tmp.disabled = disabled;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Button, tmp), React.createElement(Core.Menu, {
                  keepMounted: false,
                  anchorOrigin: {
                    horizontal: horizontal,
                    vertical: vertical
                  },
                  getContentAnchorEl: undefined,
                  transformOrigin: {
                    horizontal: horizontal,
                    vertical: verticalInvert
                  },
                  anchorEl: anchorEl,
                  children: React.createElement("div", {
                        onClick: (function ($$event) {
                            Curry._1(setAnchorEl, (function (param) {
                                    
                                  }));
                          })
                      }, children),
                  onClose: (function (param, param$1) {
                      Curry._1(setAnchorEl, (function (param) {
                              
                            }));
                    }),
                  open: anchorEl !== undefined
                }));
}

var make = Simple_Menu;

export {
  make ,
}
/* react Not a pure module */
