// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../common/types/App_Types_Date.bs.js";

function dateOptionToStr(date) {
  return Belt_Option.mapWithDefault(date, "", (function (x) {
                if (App_Types_Date$DvmAdminFrontendFr.isValid(x)) {
                  return x.toISOString();
                } else {
                  return "";
                }
              }));
}

function handleDateNumber(form, formMoreDates) {
  return Belt_List.length(Belt_List.keep({
                  hd: dateOptionToStr(form.input.registrationStartFrom),
                  tl: {
                    hd: dateOptionToStr(form.input.registrationStartTo),
                    tl: {
                      hd: dateOptionToStr(form.input.registrationEndFrom),
                      tl: {
                        hd: dateOptionToStr(form.input.registrationEndTo),
                        tl: {
                          hd: dateOptionToStr(form.input.deliveryStartFrom),
                          tl: {
                            hd: dateOptionToStr(form.input.deliveryStartTo),
                            tl: {
                              hd: dateOptionToStr(form.input.deliveryEndFrom),
                              tl: {
                                hd: dateOptionToStr(form.input.deliveryEndTo),
                                tl: {
                                  hd: dateOptionToStr(form.input.madaStartFrom),
                                  tl: {
                                    hd: dateOptionToStr(form.input.madaStartTo),
                                    tl: {
                                      hd: dateOptionToStr(form.input.madaEndFrom),
                                      tl: {
                                        hd: dateOptionToStr(form.input.madaEndTo),
                                        tl: {
                                          hd: dateOptionToStr(formMoreDates.input.madcStartFrom),
                                          tl: {
                                            hd: dateOptionToStr(formMoreDates.input.madcStartTo),
                                            tl: {
                                              hd: dateOptionToStr(formMoreDates.input.madcEndFrom),
                                              tl: {
                                                hd: dateOptionToStr(formMoreDates.input.madcEndTo),
                                                tl: {
                                                  hd: dateOptionToStr(formMoreDates.input.entryParcStartFrom),
                                                  tl: {
                                                    hd: dateOptionToStr(formMoreDates.input.entryParcStartTo),
                                                    tl: {
                                                      hd: dateOptionToStr(formMoreDates.input.entryParcEndFrom),
                                                      tl: {
                                                        hd: dateOptionToStr(formMoreDates.input.entryParcEndTo),
                                                        tl: {
                                                          hd: dateOptionToStr(formMoreDates.input.exitParcStartFrom),
                                                          tl: {
                                                            hd: dateOptionToStr(formMoreDates.input.exitParcStartTo),
                                                            tl: {
                                                              hd: dateOptionToStr(formMoreDates.input.exitParcEndFrom),
                                                              tl: {
                                                                hd: dateOptionToStr(formMoreDates.input.exitParcEndTo),
                                                                tl: {
                                                                  hd: form.input.detentionMinFrom,
                                                                  tl: {
                                                                    hd: form.input.detentionMinFrom,
                                                                    tl: {
                                                                      hd: form.input.detentionMaxFrom,
                                                                      tl: {
                                                                        hd: form.input.detentionMaxTo,
                                                                        tl: /* [] */0
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }, (function (result) {
                    return result.length > 0;
                  })));
}

function handleCarNumber(formAdditional) {
  return Belt_List.length(Belt_List.keep({
                  hd: formAdditional.input.brand,
                  tl: {
                    hd: Belt_List.reduce(formAdditional.input.modelID, "", (function (a, b) {
                            return a + b;
                          })),
                    tl: {
                      hd: formAdditional.input.phase,
                      tl: /* [] */0
                    }
                  }
                }, (function (result) {
                    return result.length > 0;
                  })));
}

function handleModalNumber(form, formMoreDates, formAdditional) {
  return handleDateNumber(form, formMoreDates) + handleCarNumber(formAdditional) | 0;
}

function dateOptionToBool(date) {
  return Belt_Option.mapWithDefault(date, false, (function (x) {
                if (x.TAG === /* Ok */0) {
                  return false;
                } else {
                  return true;
                }
              }));
}

function datesIncorrect(form, formMoreDates) {
  if (dateOptionToBool(form.registrationStartFromResult) || dateOptionToBool(form.registrationStartToResult) || dateOptionToBool(form.registrationEndFromResult) || dateOptionToBool(form.registrationEndToResult) || dateOptionToBool(form.deliveryStartFromResult) || dateOptionToBool(form.deliveryStartToResult) || dateOptionToBool(form.deliveryEndFromResult) || dateOptionToBool(form.deliveryEndToResult) || dateOptionToBool(form.madaStartFromResult) || dateOptionToBool(form.madaStartToResult) || dateOptionToBool(form.madaEndFromResult) || dateOptionToBool(form.madaEndToResult) || dateOptionToBool(formMoreDates.madcStartFromResult) || dateOptionToBool(formMoreDates.madcStartToResult) || dateOptionToBool(formMoreDates.madcEndFromResult) || dateOptionToBool(formMoreDates.madcEndToResult) || dateOptionToBool(formMoreDates.entryParcStartFromResult) || dateOptionToBool(formMoreDates.entryParcStartToResult) || dateOptionToBool(formMoreDates.entryParcEndFromResult) || dateOptionToBool(formMoreDates.entryParcEndToResult) || dateOptionToBool(formMoreDates.exitParcStartFromResult) || dateOptionToBool(formMoreDates.exitParcStartToResult) || dateOptionToBool(formMoreDates.exitParcEndFromResult)) {
    return true;
  } else {
    return dateOptionToBool(formMoreDates.exitParcEndToResult);
  }
}

export {
  dateOptionToStr ,
  handleDateNumber ,
  handleCarNumber ,
  handleModalNumber ,
  dateOptionToBool ,
  datesIncorrect ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
