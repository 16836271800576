// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../../common/styles/App_Styles_Common.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Flash_Edit_NonFleet_Modal_Amount_All_Form$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_Modal_Amount_All_Form.bs.js";

function isAmountCustom(originalBonus, customBonus) {
  return originalBonus !== customBonus;
}

function Flash_Edit_NonFleet_Modal_Amount_All(Props) {
  var flash = Props.flash;
  var collectionForm = Props.collectionForm;
  var lastFilteredList = Props.lastFilteredList;
  var intl = ReactIntl.useIntl();
  var form = Flash_Edit_NonFleet_Modal_Amount_All_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Flash_Edit_NonFleet_Modal_Amount_All_Form$DvmAdminFrontendFr.initialState, (function (param, submissionCallbacks) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          Belt_Option.mapWithDefault(param.amount, undefined, (function (amount) {
                  Belt_List.map(lastFilteredList, (function (versionItem) {
                          Curry._3(collectionForm.updateVersionCustomBonus, versionItem.index, (function (param, customBonus) {
                                  return {
                                          versions: Belt_Array.mapWithIndex(param.versions, (function (eachindex, arrayversion) {
                                                  if (eachindex === versionItem.index) {
                                                    return {
                                                            index: arrayversion.index,
                                                            brand: arrayversion.brand,
                                                            model: arrayversion.model,
                                                            version: arrayversion.version,
                                                            versionLabel: arrayversion.versionLabel,
                                                            customBonus: customBonus,
                                                            originalBonus: arrayversion.originalBonus,
                                                            actionBonus: arrayversion.actionBonus,
                                                            isCustomFEValue: arrayversion.isCustomFEValue,
                                                            engineType: arrayversion.engineType,
                                                            modelPhase: arrayversion.modelPhase
                                                          };
                                                  } else {
                                                    return arrayversion;
                                                  }
                                                }))
                                        };
                                }), String(amount));
                          Curry._3(collectionForm.updateVersionIsCustomFEValue, versionItem.index, (function (param, isCustomFEValue) {
                                  return {
                                          versions: Belt_Array.mapWithIndex(param.versions, (function (eachindex, arrayversion) {
                                                  if (eachindex === versionItem.index) {
                                                    return {
                                                            index: arrayversion.index,
                                                            brand: arrayversion.brand,
                                                            model: arrayversion.model,
                                                            version: arrayversion.version,
                                                            versionLabel: arrayversion.versionLabel,
                                                            customBonus: arrayversion.customBonus,
                                                            originalBonus: arrayversion.originalBonus,
                                                            actionBonus: arrayversion.actionBonus,
                                                            isCustomFEValue: isCustomFEValue,
                                                            engineType: arrayversion.engineType,
                                                            modelPhase: arrayversion.modelPhase
                                                          };
                                                  } else {
                                                    return arrayversion;
                                                  }
                                                }))
                                        };
                                }), versionItem.actionBonus !== amount);
                        }));
                }));
        }));
  var tmp = {
    onChange: Curry._1(form.updateAmount, (function (param, amount) {
            return {
                    amount: amount
                  };
          })),
    value: form.input.amount,
    fullWidth: true,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
    label: Messages_Common$DvmAdminFrontendFr.amount,
    InputProps: {
      startAdornment: React.createElement(Core.InputAdornment, {
            children: Belt_Option.mapWithDefault(flash.amount, "", (function (x) {
                    return Belt_Option.mapWithDefault(x.numberType, "", (function (numberType) {
                                  switch (numberType) {
                                    case /* PERCENT */0 :
                                        return "%";
                                    case /* EURO */1 :
                                        return "€";
                                    case /* NOTSET */2 :
                                        return "";
                                    
                                  }
                                }));
                  })),
            position: "start"
          })
    },
    inputType: "number"
  };
  if (form.amountResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.amountResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  alignItems: "flex-end",
                  children: null,
                  container: true,
                  justifyContent: "flex-end",
                  spacing: 1
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                            style: {
                              fontWeight: "600"
                            },
                            children: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.editAllFilteredNonFleet),
                            variant: "body"
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var InputStyles;

var Form;

var CollectionForm;

var make = Flash_Edit_NonFleet_Modal_Amount_All;

export {
  InputStyles ,
  Form ,
  CollectionForm ,
  isAmountCustom ,
  make ,
}
/* react Not a pure module */
