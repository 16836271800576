// Generated by ReScript, PLEASE EDIT WITH CARE


var titleDetail = {
  id: "37042ab8-a925-4989-8a4d-a577f922f8ca",
  defaultMessage: "Détail de l’affaire {dealer}"
};

var address = {
  id: "4ceadcda-1b89-48d7-bfea-2e6574c2deb4",
  defaultMessage: "Adresse"
};

var dc = {
  id: "e6847b59-58ed-41b7-b017-a1ac774d22f2",
  defaultMessage: "DC"
};

var drName = {
  id: "c8e81fc0-cdc8-42f5-9fe7-22ce875a687c",
  defaultMessage: "Nom usuel DR"
};

var entityCode = {
  id: "b22dd41f-ddc7-487e-b5ef-f5e5c09e49f9",
  defaultMessage: "Code entité"
};

var alcorID = {
  id: "71832dea-717e-4971-b182-d5b65fc46de5",
  defaultMessage: "Compte ALCOR"
};

var nameR1 = {
  id: "affaad9a-db32-4530-b5d1-8c7e40c1723e",
  defaultMessage: "Groupe National"
};

var nameR2 = {
  id: "e1b02ae2-f39f-4ee3-8312-f4811e090ab4",
  defaultMessage: "Groupe Régional"
};

var listOfAgents = {
  id: "d973139c-336a-401d-9066-ffb919671049",
  defaultMessage: "Liste des agents de l’affaire"
};

var agentCode = {
  id: "ff537052-ec62-43a4-b246-7cde4fdab567",
  defaultMessage: "Code Agent"
};

var keyAccountManagerNameDealerDetailWithIPN = {
  id: "f1ae7dc0-81c4-4fb7-8959-f42f32050e6d",
  defaultMessage: "ME"
};

export {
  titleDetail ,
  address ,
  dc ,
  drName ,
  entityCode ,
  alcorID ,
  nameR1 ,
  nameR2 ,
  listOfAgents ,
  agentCode ,
  keyAccountManagerNameDealerDetailWithIPN ,
}
/* No side effect */
