// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../../../api/RestApi.bs.js";

function deleteDerogate(userManager, setResult, orderId, actionId, selectedRole, deroId) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", undefined, userManager, selectedRole, "/orders/" + deroId + "/derogate/" + (
          actionId !== undefined ? "action?derogatedActionId=" + actionId + "&orderId=" + orderId + "" : "budget?orderId=" + orderId + ""
        )).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

export {
  deleteDerogate ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
