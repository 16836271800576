// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../common/components/App_Modal.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../common/utils/Utils_Api.bs.js";
import * as App_Styles$DvmAdminFrontendFr from "../../../../common/App_Styles.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Category_Types$DvmAdminFrontendFr from "../../Category_Types.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as Category_Edit_Api$DvmAdminFrontendFr from "../Category_Edit_Api.bs.js";
import * as Messages_Category$DvmAdminFrontendFr from "../../../../intl/messages/category/Messages_Category.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../common/components/App_OverlaySpinner.bs.js";
import * as Category_Edit_VersionsSelection_Modal_Table$DvmAdminFrontendFr from "./Category_Edit_VersionsSelection_Modal_Table.bs.js";
import * as Category_Edit_VersionsSelection_Modal_Filter$DvmAdminFrontendFr from "./Category_Edit_VersionsSelection_Modal_Filter.bs.js";

function Category_Edit_VersionsSelection_Modal(Props) {
  var onClose = Props.onClose;
  var category = Props.category;
  var refresh = Props.refresh;
  var takenVersionsList = Props.takenVersionsList;
  var alert = ReactAlert.useAlert();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match[1];
  var match$1 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResultVersions = match$1[1];
  var resultVersions = match$1[0];
  var match$2 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: 10,
                headers: Category_Edit_VersionsSelection_Modal_Table$DvmAdminFrontendFr.headersConfiguration,
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$2[1];
  var fetchRequest = match$2[0];
  var intl = ReactIntl.useIntl();
  var match$3 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$4 = match$3[0];
  var userManager = match$4.userManager;
  var selectedRole = match$4.selectedRole;
  var match$5 = React.useState(function () {
        return false;
      });
  var formValid = match$5[0];
  var fetchData = function (fetchRequest) {
    Category_Edit_Api$DvmAdminFrontendFr.fetchAllVersions(Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
              page: fetchRequest.page,
              rowsPerPage: fetchRequest.rowsPerPage,
              headers: fetchRequest.headers,
              filterQueryParams: Belt_Option.getWithDefault(Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(undefined, {
                        fieldId: Category_Types$DvmAdminFrontendFr.Fields.model,
                        value: category.modelID
                      }), "") + (
                fetchRequest.filterQueryParams.length === 0 ? fetchRequest.filterQueryParams : ";" + fetchRequest.filterQueryParams
              ),
              additionalQueryParams: fetchRequest.additionalQueryParams
            }), setTablePage, userManager, selectedRole);
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  React.useEffect((function () {
          fetchData(fetchRequest);
        }), [category.modelID]);
  React.useEffect((function () {
          if (typeof resultVersions === "number") {
            resultVersions === /* NotStarted */0;
          } else if (resultVersions.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(refresh, undefined);
            Curry._1(onClose, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: resultVersions._0
                      }
                    }));
          }
        }), [resultVersions]);
  var saveVersions = function (versions) {
    Category_Edit_Api$DvmAdminFrontendFr.saveVersions(setResultVersions, userManager, "PUT", selectedRole, {
          modelID: category.modelID,
          categoryID: category.categoryID,
          categoryName: category.categoryName,
          startDate: category.startDate,
          endDate: category.endDate,
          versions: versions
        });
  };
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Category$DvmAdminFrontendFr.assignVersionsTitle, {
                    categoryCode: category.categoryID,
                    categoryName: category.categoryName
                  }),
              minWidth: "52rem",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(resultVersions),
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          direction: "column",
                          style: {
                            gridRowGap: "24px"
                          }
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(Core.Typography, {
                                    children: intl.formatMessage(Messages_Category$DvmAdminFrontendFr.selectVersionsTitle, {
                                          model: category.modelID
                                        }),
                                    classes: {
                                      body1: App_Styles$DvmAdminFrontendFr.bold
                                    },
                                    variant: "body1"
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(Category_Edit_VersionsSelection_Modal_Filter$DvmAdminFrontendFr.make, {
                                    fetchData: fetchData,
                                    fetchRequest: fetchRequest,
                                    formValid: formValid,
                                    model: category.modelID
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(Category_Edit_VersionsSelection_Modal_Table$DvmAdminFrontendFr.make, {
                                    tablePage: match[0],
                                    fetchRequest: fetchRequest,
                                    takenVersionsList: takenVersionsList,
                                    category: category,
                                    saveVersions: saveVersions,
                                    fetchData: fetchData,
                                    formValid: formValid,
                                    setFormValid: match$5[1]
                                  }),
                              item: true
                            }))
                  })
            });
}

var Api;

var VersionsFilter;

var VersionsTable;

var make = Category_Edit_VersionsSelection_Modal;

export {
  Api ,
  VersionsFilter ,
  VersionsTable ,
  make ,
}
/* react Not a pure module */
