// Generated by ReScript, PLEASE EDIT WITH CARE


var QuickAccess;

var Message;

var Videos;

var title = {
  id: "33fe1279-32ca-41bb-bfc6-5e9fedccfd59",
  defaultMessage: "Bienvenue"
};

export {
  QuickAccess ,
  Message ,
  Videos ,
  title ,
}
/* No side effect */
