// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../../route/Route_Paths.bs.js";

function routeToPage(contractId, startDate, editOpt, param) {
  var edit = editOpt !== undefined ? editOpt : false;
  RescriptReactRouter.push((
        edit ? Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rContractEdit : Route_Paths$DvmAdminFrontendFr.rView + Route_Paths$DvmAdminFrontendFr.rContractView
      ) + (
        contractId !== undefined && startDate !== undefined ? "/" + contractId + "/" + startDate : ""
      ));
}

export {
  routeToPage ,
}
/* RescriptReactRouter Not a pure module */
