// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as Flash_Edit_Api$DvmAdminFrontendFr from "../../../../../flash/edit/Flash_Edit_Api.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../../common/components/App_OverlaySpinner.bs.js";
import * as App_RadioFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_RadioFormInput.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_Orders_Derogate$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_Derogate.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Messages_Orders_DealerRequest$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_DealerRequest.bs.js";
import * as Order_Detail_Bonus_DealerRequest_FileType_List$DvmAdminFrontendFr from "./Order_Detail_Bonus_DealerRequest_FileType_List.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Document_Selection$DvmAdminFrontendFr from "./Order_Detail_Bonus_DealerRequest_Document_Selection.bs.js";

function roundFloat($$float, precision) {
  return Belt_Option.getWithDefault(Belt_Float.fromString($$float.toFixed(precision)), 0.0);
}

function Order_Detail_Bonus_DealerRequest_Inputs(Props) {
  var action = Props.action;
  var form = Props.form;
  var actionIdOptions = Props.actionIdOptions;
  var order = Props.order;
  var newDocuments = Props.newDocuments;
  var setNewDocuments = Props.setNewDocuments;
  var saveResult = Props.saveResult;
  var orderId = Props.orderId;
  var existingDocuments = Props.existingDocuments;
  var setExistingDocuments = Props.setExistingDocuments;
  var documentTypes = Props.documentTypes;
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setVATResult = match$2[1];
  var vatResult = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var setAmountWithVAT = match$3[1];
  var amountWithVAT = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var setVatFocus = match$4[1];
  var vatFocus = match$4[0];
  React.useEffect((function () {
          Flash_Edit_Api$DvmAdminFrontendFr.fetchVAT(userManager, setVATResult, selectedRole);
        }), []);
  React.useEffect((function () {
          if (vatFocus) {
            var vatFloat = App_Types_Result$DvmAdminFrontendFr.mapWithDefault(vatResult, undefined, (function (vat) {
                    return Belt_Option.mapWithDefault(vat.response, undefined, Belt_Float.fromString);
                  }));
            Belt_Option.mapWithDefault(vatFloat, undefined, (function (vatFloat) {
                    Curry._2(form.updateAmount, (function (input, amount) {
                            return {
                                    actionId: input.actionId,
                                    amount: amount,
                                    perimeter: input.perimeter,
                                    comment: input.comment
                                  };
                          }), String(roundFloat(Belt_Option.getWithDefault(Belt_Float.fromString(amountWithVAT), 0.0) / (vatFloat / 100.0 + 1.0), 2)));
                  }));
          }
          
        }), [amountWithVAT]);
  React.useEffect((function () {
          if (!vatFocus) {
            var vatFloat = App_Types_Result$DvmAdminFrontendFr.mapWithDefault(vatResult, undefined, (function (vat) {
                    return Belt_Option.mapWithDefault(vat.response, undefined, Belt_Float.fromString);
                  }));
            Belt_Option.mapWithDefault(vatFloat, undefined, (function (vatFloat) {
                    Belt_Option.mapWithDefault(Belt_Float.fromString(form.input.amount), undefined, (function (value) {
                            Curry._1(setAmountWithVAT, (function (param) {
                                    return String(roundFloat(value * (vatFloat / 100.0 + 1.0), 2));
                                  }));
                          }));
                  }));
          }
          
        }), [
        form.input.amount,
        vatResult
      ]);
  var tmp;
  if (Belt_Option.getWithDefault(order.orderType, /* NOTSET */7) === /* SO */0) {
    tmp = React.createElement(Core.Box, {
          children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, {
                label: Messages_Orders_Derogate$DvmAdminFrontendFr.derogateActionLabel,
                value: "PERF",
                options: {
                  hd: {
                    label: {
                      TAG: /* String */3,
                      _0: "PERF"
                    },
                    value: "PERF"
                  },
                  tl: /* [] */0
                },
                onChange: (function (param) {
                    
                  }),
                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                disabled: Belt_Option.isSome(action)
              }),
          mt: 2
        });
  } else {
    var tmp$1 = {
      label: Messages_Orders_Derogate$DvmAdminFrontendFr.derogateActionLabel,
      value: form.input.perimeter,
      options: {
        hd: {
          label: {
            TAG: /* String */3,
            _0: "POOL"
          },
          value: "POOL"
        },
        tl: {
          hd: {
            label: {
              TAG: /* String */3,
              _0: "PSER"
            },
            value: "PSER"
          },
          tl: /* [] */0
        }
      },
      onChange: Curry._1(form.updatePerimeter, (function (input, perimeter) {
              return {
                      actionId: input.actionId,
                      amount: input.amount,
                      perimeter: perimeter,
                      comment: input.comment
                    };
            })),
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
      disabled: Belt_Option.isSome(action)
    };
    var tmp$2 = Belt_Option.mapWithDefault(order.orderType, undefined, (function (x) {
            if (x !== 1) {
              return ;
            } else {
              return {
                      hd: false,
                      tl: {
                        hd: false,
                        tl: {
                          hd: true,
                          tl: /* [] */0
                        }
                      }
                    };
            }
          }));
    if (tmp$2 !== undefined) {
      tmp$1.optionsDisabled = Caml_option.valFromOption(tmp$2);
    }
    if (form.perimeterResult !== undefined) {
      tmp$1.validationResult = Caml_option.valFromOption(form.perimeterResult);
    }
    tmp = React.createElement(Core.Box, {
          children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, tmp$1),
          mt: 2
        });
  }
  var tmp$3;
  if (Belt_Option.isSome(action)) {
    tmp$3 = null;
  } else {
    var tmp$4 = {
      label: Messages_Orders_Derogate$DvmAdminFrontendFr.derogateIdAction,
      value: {
        TAG: /* Single */0,
        _0: {
          value: form.input.actionId,
          onChange: Curry._1(form.updateActionId, (function (input, actionId) {
                  return {
                          actionId: actionId,
                          amount: input.amount,
                          perimeter: input.perimeter,
                          comment: input.comment
                        };
                }))
        }
      },
      options: {
        TAG: /* Labeled */1,
        _0: Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionIdOptions, /* [] */0), (function (item) {
                return {
                        label: {
                          TAG: /* String */3,
                          _0: "" + item.paramOne + " - " + Belt_Option.getWithDefault(item.paramTwo, "") + ""
                        },
                        value: item.paramOne
                      };
              }))
      },
      disabled: Belt_Option.isSome(action),
      required: /* HtmlRequired */0,
      fullWidth: true
    };
    if (form.actionIdResult !== undefined) {
      tmp$4.validationResult = Caml_option.valFromOption(form.actionIdResult);
    }
    tmp$3 = React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
              children: React.createElement(Core.Grid, {
                    children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                          show: App_Types_Result$DvmAdminFrontendFr.isPending(actionIdOptions),
                          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$4)
                        }),
                    item: true,
                    xs: Grid$Mui.Xs[12]
                  }),
              container: true,
              item: true,
              justifyContent: "space-between",
              spacing: 4
            }));
  }
  var tmp$5 = {
    onChange: Curry._1(form.updateAmount, (function (input, amount) {
            return {
                    actionId: input.actionId,
                    amount: amount,
                    perimeter: input.perimeter,
                    comment: input.comment
                  };
          })),
    value: form.input.amount,
    fullWidth: true,
    label: form.input.perimeter === "PSER" ? Messages_Orders_DealerRequest$DvmAdminFrontendFr.transformationPrice : Messages_Orders_DealerRequest$DvmAdminFrontendFr.requestAmount,
    required: /* HtmlRequired */0,
    InputProps: {
      startAdornment: React.createElement(Core.InputAdornment, {
            children: "€",
            position: "start"
          })
    },
    inputType: "number"
  };
  if (form.amountResult !== undefined) {
    tmp$5.validationResult = Caml_option.valFromOption(form.amountResult);
  }
  return React.createElement(React.Fragment, undefined, tmp, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true,
                  spacing: 1
                }, tmp$3, React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      item: true,
                      justifyContent: "space-between",
                      spacing: 4
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$5),
                          item: true,
                          xs: Grid$Mui.Xs[6]
                        }), Belt_Option.getWithDefault(order.orderType, /* NOTSET */7) === /* SO */0 ? null : React.createElement(Core.Grid, {
                            children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                  onChange: (function (val) {
                                      Curry._1(setAmountWithVAT, (function (param) {
                                              return val;
                                            }));
                                    }),
                                  value: amountWithVAT,
                                  onBlur: (function (param) {
                                      Curry._1(setVatFocus, (function (param) {
                                              return false;
                                            }));
                                    }),
                                  fullWidth: true,
                                  label: form.input.perimeter === "PSER" ? Messages_Orders_DealerRequest$DvmAdminFrontendFr.transformationPriceInformative : Messages_Orders_DealerRequest$DvmAdminFrontendFr.requestAmountInformative,
                                  InputProps: {
                                    startAdornment: React.createElement(Core.InputAdornment, {
                                          children: "€",
                                          position: "start"
                                        })
                                  },
                                  inputType: "number",
                                  onFocus: (function (param) {
                                      Curry._1(setVatFocus, (function (param) {
                                              return true;
                                            }));
                                    })
                                }),
                            item: true,
                            xs: Grid$Mui.Xs[6]
                          })), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry._1(form.updateComment, (function (input, comment) {
                                    return {
                                            actionId: input.actionId,
                                            amount: input.amount,
                                            perimeter: input.perimeter,
                                            comment: comment
                                          };
                                  })),
                            value: form.input.comment,
                            fullWidth: true,
                            label: Messages_Common$DvmAdminFrontendFr.comment
                          }),
                      item: true,
                      xs: Grid$Mui.Xs[12]
                    })), React.createElement(Order_Detail_Bonus_DealerRequest_FileType_List$DvmAdminFrontendFr.make, {
                  newDocuments: newDocuments,
                  existingDocuments: existingDocuments,
                  documentTypes: documentTypes
                }), Belt_Option.getWithDefault(order.orderType, /* NOTSET */7) === /* SO */0 ? null : React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                        children: React.createElement(Core.Divider, {}),
                        item: true,
                        style: {
                          marginTop: "15px",
                          marginBottom: "5px"
                        }
                      }), React.createElement(Order_Detail_Bonus_DealerRequest_Document_Selection$DvmAdminFrontendFr.make, {
                        newDocuments: newDocuments,
                        setNewDocuments: setNewDocuments,
                        saveResult: saveResult,
                        actionId: form.input.actionId,
                        orderId: orderId,
                        existingDocuments: existingDocuments,
                        setExistingDocuments: setExistingDocuments,
                        documentTypes: documentTypes
                      })));
}

var Form;

var InputStyles;

var make = Order_Detail_Bonus_DealerRequest_Inputs;

export {
  Form ,
  InputStyles ,
  roundFloat ,
  make ,
}
/* react Not a pure module */
