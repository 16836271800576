// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";

var Fields = {
  dealerID: "dealerID",
  siren: "siren",
  modelID: "modelID",
  categoryID: "categoryID",
  acquisitionType: "acquisitionType",
  contractID: "contractID",
  avsMarginGridId: "avsMarginGrid",
  companyDiscountPercentage: "companyDiscountPercentage",
  volume: "volume",
  responseNumber: "responseNumber",
  yearBudget: "yearBudget",
  processingDate: "processingDate"
};

function pcdRow_encode(v) {
  return Js_dict.fromArray([
              [
                "dealerID",
                Decco.stringToJson(v.dealerID)
              ],
              [
                "siren",
                Decco.optionToJson(Decco.stringToJson, v.siren)
              ],
              [
                "siret",
                Decco.optionToJson(Decco.stringToJson, v.siret)
              ],
              [
                "modelID",
                Decco.optionToJson(Decco.stringToJson, v.modelID)
              ],
              [
                "categoryID",
                Decco.optionToJson(Decco.stringToJson, v.categoryID)
              ],
              [
                "acquisitionType",
                Decco.optionToJson(Decco.stringToJson, v.acquisitionType)
              ],
              [
                "contractID",
                Decco.optionToJson(Decco.stringToJson, v.contractID)
              ],
              [
                "avsMarginGridId",
                Decco.optionToJson(Decco.stringToJson, v.avsMarginGridId)
              ],
              [
                "companyDiscountPercentage",
                Decco.optionToJson(Decco.floatToJson, v.companyDiscountPercentage)
              ],
              [
                "volume",
                Decco.optionToJson(Decco.intToJson, v.volume)
              ],
              [
                "responseNumber",
                Decco.optionToJson(Decco.stringToJson, v.responseNumber)
              ],
              [
                "yearBudget",
                Decco.optionToJson(Decco.intToJson, v.yearBudget)
              ],
              [
                "processingDate",
                Decco.optionToJson(Decco.stringToJson, v.processingDate)
              ],
              [
                "isReplaced",
                Decco.optionToJson(Decco.boolToJson, v.isReplaced)
              ],
              [
                "avsModelVersion",
                Decco.optionToJson(Decco.stringToJson, v.avsModelVersion)
              ]
            ]);
}

function pcdRow_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var dealerID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerID"), null));
  if (dealerID.TAG === /* Ok */0) {
    var siren = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "siren"), null));
    if (siren.TAG === /* Ok */0) {
      var siret = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "siret"), null));
      if (siret.TAG === /* Ok */0) {
        var modelID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelID"), null));
        if (modelID.TAG === /* Ok */0) {
          var categoryID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "categoryID"), null));
          if (categoryID.TAG === /* Ok */0) {
            var acquisitionType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "acquisitionType"), null));
            if (acquisitionType.TAG === /* Ok */0) {
              var contractID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractID"), null));
              if (contractID.TAG === /* Ok */0) {
                var avsMarginGridId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "avsMarginGridId"), null));
                if (avsMarginGridId.TAG === /* Ok */0) {
                  var companyDiscountPercentage = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "companyDiscountPercentage"), null));
                  if (companyDiscountPercentage.TAG === /* Ok */0) {
                    var volume = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "volume"), null));
                    if (volume.TAG === /* Ok */0) {
                      var responseNumber = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "responseNumber"), null));
                      if (responseNumber.TAG === /* Ok */0) {
                        var yearBudget = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "yearBudget"), null));
                        if (yearBudget.TAG === /* Ok */0) {
                          var processingDate = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "processingDate"), null));
                          if (processingDate.TAG === /* Ok */0) {
                            var isReplaced = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "isReplaced"), null));
                            if (isReplaced.TAG === /* Ok */0) {
                              var avsModelVersion = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "avsModelVersion"), null));
                              if (avsModelVersion.TAG === /* Ok */0) {
                                return {
                                        TAG: /* Ok */0,
                                        _0: {
                                          dealerID: dealerID._0,
                                          siren: siren._0,
                                          siret: siret._0,
                                          modelID: modelID._0,
                                          categoryID: categoryID._0,
                                          acquisitionType: acquisitionType._0,
                                          contractID: contractID._0,
                                          avsMarginGridId: avsMarginGridId._0,
                                          companyDiscountPercentage: companyDiscountPercentage._0,
                                          volume: volume._0,
                                          responseNumber: responseNumber._0,
                                          yearBudget: yearBudget._0,
                                          processingDate: processingDate._0,
                                          isReplaced: isReplaced._0,
                                          avsModelVersion: avsModelVersion._0
                                        }
                                      };
                              }
                              var e = avsModelVersion._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".avsModelVersion" + e.path,
                                        message: e.message,
                                        value: e.value
                                      }
                                    };
                            }
                            var e$1 = isReplaced._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".isReplaced" + e$1.path,
                                      message: e$1.message,
                                      value: e$1.value
                                    }
                                  };
                          }
                          var e$2 = processingDate._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".processingDate" + e$2.path,
                                    message: e$2.message,
                                    value: e$2.value
                                  }
                                };
                        }
                        var e$3 = yearBudget._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".yearBudget" + e$3.path,
                                  message: e$3.message,
                                  value: e$3.value
                                }
                              };
                      }
                      var e$4 = responseNumber._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".responseNumber" + e$4.path,
                                message: e$4.message,
                                value: e$4.value
                              }
                            };
                    }
                    var e$5 = volume._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".volume" + e$5.path,
                              message: e$5.message,
                              value: e$5.value
                            }
                          };
                  }
                  var e$6 = companyDiscountPercentage._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".companyDiscountPercentage" + e$6.path,
                            message: e$6.message,
                            value: e$6.value
                          }
                        };
                }
                var e$7 = avsMarginGridId._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".avsMarginGridId" + e$7.path,
                          message: e$7.message,
                          value: e$7.value
                        }
                      };
              }
              var e$8 = contractID._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".contractID" + e$8.path,
                        message: e$8.message,
                        value: e$8.value
                      }
                    };
            }
            var e$9 = acquisitionType._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".acquisitionType" + e$9.path,
                      message: e$9.message,
                      value: e$9.value
                    }
                  };
          }
          var e$10 = categoryID._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".categoryID" + e$10.path,
                    message: e$10.message,
                    value: e$10.value
                  }
                };
        }
        var e$11 = modelID._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".modelID" + e$11.path,
                  message: e$11.message,
                  value: e$11.value
                }
              };
      }
      var e$12 = siret._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".siret" + e$12.path,
                message: e$12.message,
                value: e$12.value
              }
            };
    }
    var e$13 = siren._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".siren" + e$13.path,
              message: e$13.message,
              value: e$13.value
            }
          };
  }
  var e$14 = dealerID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".dealerID" + e$14.path,
            message: e$14.message,
            value: e$14.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(pcdRow_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(pcdRow_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var PCDRows = {
  pcdRow_encode: pcdRow_encode,
  pcdRow_decode: pcdRow_decode,
  searchResult_encode: searchResult_encode,
  searchResult_decode: searchResult_decode
};

function row_encode(v) {
  return Js_dict.fromArray([[
                "categoryID",
                Decco.stringToJson(v.categoryID)
              ]]);
}

function row_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var categoryID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "categoryID"), null));
  if (categoryID.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              categoryID: categoryID._0
            }
          };
  }
  var e = categoryID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".categoryID" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function searchResult_encode$1(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(row_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode$1(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(row_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var CategoryCodes = {
  row_encode: row_encode,
  row_decode: row_decode,
  searchResult_encode: searchResult_encode$1,
  searchResult_decode: searchResult_decode$1
};

function datesItems_encode(v) {
  return Js_dict.fromArray([
              [
                "startDate",
                Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function datesItems_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var startDate = Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
  if (startDate.TAG === /* Ok */0) {
    var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
    if (endDate.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                startDate: startDate._0,
                endDate: endDate._0
              }
            };
    }
    var e = endDate._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".endDate" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = startDate._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".startDate" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function contractIdItem_encode(v) {
  return Js_dict.fromArray([
              [
                "contractID",
                Decco.stringToJson(v.contractID)
              ],
              [
                "dates",
                Decco.listToJson(datesItems_encode, v.dates)
              ]
            ]);
}

function contractIdItem_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var contractID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractID"), null));
  if (contractID.TAG === /* Ok */0) {
    var dates = Decco.listFromJson(datesItems_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "dates"), null));
    if (dates.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                contractID: contractID._0,
                dates: dates._0
              }
            };
    }
    var e = dates._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".dates" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = contractID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".contractID" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function contractsIdsList_encode(v) {
  return Decco.listToJson(contractIdItem_encode, v);
}

function contractsIdsList_decode(v) {
  return Decco.listFromJson(contractIdItem_decode, v);
}

export {
  Fields ,
  PCDRows ,
  CategoryCodes ,
  datesItems_encode ,
  datesItems_decode ,
  contractIdItem_encode ,
  contractIdItem_decode ,
  contractsIdsList_encode ,
  contractsIdsList_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
