// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../../../api/RestApi.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../../common/utils/Utils_Alert.bs.js";
import * as Flash_Search_Types$DvmAdminFrontendFr from "../../../../search/Flash_Search_Types.bs.js";
import * as Flash_Edit_Versions_Types$DvmAdminFrontendFr from "../../Flash_Edit_Versions_Types.bs.js";

function saveVersion(setResult, userManager, submissionCallbacks, selectedRole, version) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("PUT", {
          TAG: /* Json */0,
          _0: Flash_Edit_Versions_Types$DvmAdminFrontendFr.VersionEdit.versionEdit_encode(version)
        }, userManager, selectedRole, "/flash/map").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToSaveSingleVersion */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchAllVersions(submissionCallbacks, userManager, setResult, queryParams, selectedRole, withClosedModels) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Edit_Versions_Types$DvmAdminFrontendFr.IncludedModelVersions.searchResult_decode, undefined, userManager, selectedRole, "/dropdown/search/versions" + queryParams + "&withClosedModels=" + (
          withClosedModels ? "true" : "false"
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchVersionsSearch */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function editMultipleVersions(operation, userManager, setResult, submissionCallbacks, flashId, selectedRole, versions) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call(operation ? "DELETE" : "POST", {
          TAG: /* Json */0,
          _0: Flash_Edit_Versions_Types$DvmAdminFrontendFr.VersionEdit.versionEditList_encode(versions)
        }, userManager, selectedRole, "/flash/map/" + flashId + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: operation
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToSaveVersions */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function deleteVersion(userManager, setResult, flashId, selectedRole, versions) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", {
          TAG: /* Json */0,
          _0: Flash_Edit_Versions_Types$DvmAdminFrontendFr.VersionEdit.versionEditList_encode(versions)
        }, userManager, selectedRole, "/flash/map/" + flashId + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function getActionsOnActionType(alert, userManager, setActions, selectedRole, actionType) {
  var endpoint;
  if (typeof actionType === "number") {
    switch (actionType) {
      case /* STOC */0 :
          endpoint = "/flash/actions?limit=2000&offset=0&filter=actionType=STOC";
          break;
      case /* PERF */1 :
          endpoint = "/flash/actions?limit=2000&offset=0&filter=actionType=PERF";
          break;
      case /* REPR */2 :
          endpoint = "/flash/actions?limit=2000&offset=0&filter=actionType=REPR";
          break;
      case /* NOTSET */3 :
          endpoint = "";
          break;
      
    }
  } else {
    endpoint = "/flash/actions/modular?limit=2000&offset=0";
  }
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Search_Types$DvmAdminFrontendFr.searchResult_decode, undefined, userManager, selectedRole, endpoint).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var actions = result._0;
          return Curry._1(setActions, (function (param) {
                        return Belt_List.map(actions.content, (function (x) {
                                      return {
                                              paramOne: x.actionID,
                                              paramTwo: x.actionLabel
                                            };
                                    }));
                      }));
        }
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: result._0
                  }
                }));
        return Curry._1(setActions, (function (param) {
                      return /* [] */0;
                    }));
      });
}

function fetchVersionsOfAction(userManager, setResult, flashId, selectedRole, duplicateActionWithClosedModels) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Edit_Versions_Types$DvmAdminFrontendFr.IncludedModelVersions.searchResult_decode, undefined, userManager, selectedRole, "/flash/map/" + flashId + "?limit=5000&offset=0&withClosedModels=" + (
          duplicateActionWithClosedModels ? "true" : "false"
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

var Types;

export {
  Form ,
  Types ,
  saveVersion ,
  fetchAllVersions ,
  editMultipleVersions ,
  deleteVersion ,
  getActionsOnActionType ,
  fetchVersionsOfAction ,
}
/* react Not a pure module */
