// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as App_Table$DvmAdminFrontendFr from "../../../../common/components/table/App_Table.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as Messages_Quotas$DvmAdminFrontendFr from "../../../../intl/messages/quotas/Messages_Quotas.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../../context/user/User_Types_Utils.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Pagination.bs.js";
import * as Messages_ModelVersion$DvmAdminFrontendFr from "../../../../intl/messages/modelVersion/Messages_ModelVersion.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as Quota_View_Exclusion_Types$DvmAdminFrontendFr from "./Quota_View_Exclusion_Types.bs.js";
import * as Quota_View_Exclusion_Update$DvmAdminFrontendFr from "./modal/update/Quota_View_Exclusion_Update.bs.js";
import * as Quota_View_Exclusion_Delete_Modal$DvmAdminFrontendFr from "./modal/update/Quota_View_Exclusion_Delete_Modal.bs.js";

function headersConfiguration(tableType) {
  return {
          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Exclusion_Types$DvmAdminFrontendFr.Columns.contractId, undefined, undefined, undefined, undefined, undefined, Messages_Quotas$DvmAdminFrontendFr.contractId),
          tl: {
            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Exclusion_Types$DvmAdminFrontendFr.Columns.vehicleType, undefined, undefined, undefined, undefined, undefined, Messages_Quotas_Detail$DvmAdminFrontendFr.vehicleType),
            tl: {
              hd: tableType === /* Model */0 ? App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Exclusion_Types$DvmAdminFrontendFr.Columns.modelId, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.model) : App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Exclusion_Types$DvmAdminFrontendFr.Columns.modelVersionId, undefined, undefined, undefined, undefined, undefined, Messages_Quotas_Detail$DvmAdminFrontendFr.modelVersion),
              tl: {
                hd: tableType === /* Model */0 ? App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Exclusion_Types$DvmAdminFrontendFr.Columns.modelName, undefined, undefined, undefined, undefined, undefined, Messages_ModelVersion$DvmAdminFrontendFr.labelModel) : App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Exclusion_Types$DvmAdminFrontendFr.Columns.versionName, undefined, undefined, undefined, undefined, undefined, Messages_Quotas_Detail$DvmAdminFrontendFr.versionName),
                tl: {
                  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Exclusion_Types$DvmAdminFrontendFr.Columns.startDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.startDate),
                  tl: {
                    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Exclusion_Types$DvmAdminFrontendFr.Columns.endDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.endDate),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        };
}

function Quota_View_Exclusion_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var refreshVersions = Props.refreshVersions;
  var tableType = Props.tableType;
  var excludeElement = Props.excludeElement;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return null;
      });
  var setModal = match[1];
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var selectedRole = match$1[0].selectedRole;
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (param) {
                                  var endDate = param.endDate;
                                  var startDate = param.startDate;
                                  var modelVersion = param.modelVersion;
                                  var model = param.model;
                                  var vehicleType = param.vehicleType;
                                  var contractId = param.contractId;
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) ? Caml_option.some(React.createElement(Core.Grid, {
                                                        children: null,
                                                        container: true,
                                                        wrap: "nowrap"
                                                      }, React.createElement(Core.Grid, {
                                                            children: React.createElement(Core.Button, {
                                                                  onClick: (function (param) {
                                                                      Curry._1(setModal, (function (param) {
                                                                              return React.createElement(Quota_View_Exclusion_Update$DvmAdminFrontendFr.make, {
                                                                                          contractId: contractId,
                                                                                          vehicleType: vehicleType,
                                                                                          model: model,
                                                                                          modelVersion: modelVersion,
                                                                                          startDate: startDate,
                                                                                          endDate: endDate,
                                                                                          onClose: (function (param) {
                                                                                              Curry._1(setModal, (function (param) {
                                                                                                      return null;
                                                                                                    }));
                                                                                            }),
                                                                                          onOk: (function (param) {
                                                                                              Curry._1(setModal, (function (param) {
                                                                                                      return null;
                                                                                                    }));
                                                                                              Curry._1(refreshVersions, undefined);
                                                                                              Curry._2(fetchData, undefined, fetchRequest);
                                                                                            }),
                                                                                          tableType: tableType
                                                                                        });
                                                                            }));
                                                                    }),
                                                                  children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                        size: /* Large */3,
                                                                        type_: /* Edit */60
                                                                      }),
                                                                  key: "edit"
                                                                }),
                                                            item: true
                                                          }), React.createElement(Core.Grid, {
                                                            children: React.createElement(Core.Button, {
                                                                  onClick: (function (param) {
                                                                      Curry._1(setModal, (function (param) {
                                                                              return React.createElement(Quota_View_Exclusion_Delete_Modal$DvmAdminFrontendFr.make, {
                                                                                          contractId: contractId,
                                                                                          vehicleType: vehicleType,
                                                                                          model: model,
                                                                                          modelVersion: modelVersion,
                                                                                          startDate: startDate,
                                                                                          endDate: endDate,
                                                                                          onClose: (function (param) {
                                                                                              Curry._1(setModal, (function (param) {
                                                                                                      return null;
                                                                                                    }));
                                                                                            }),
                                                                                          onOk: (function (param) {
                                                                                              Curry._1(setModal, (function (param) {
                                                                                                      return null;
                                                                                                    }));
                                                                                              Curry._1(refreshVersions, undefined);
                                                                                              Curry._2(fetchData, undefined, fetchRequest);
                                                                                            }),
                                                                                          tableType: tableType
                                                                                        });
                                                                            }));
                                                                    }),
                                                                  children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                        size: /* Large */3,
                                                                        type_: /* Trash */38,
                                                                        color: "red"
                                                                      }),
                                                                  key: "delete"
                                                                }),
                                                            item: true
                                                          }))) : undefined, undefined, Belt_List.mapWithIndex({
                                                  hd: contractId,
                                                  tl: {
                                                    hd: vehicleType,
                                                    tl: {
                                                      hd: tableType === /* Model */0 ? Belt_Option.getWithDefault(model, "") : Belt_Option.getWithDefault(modelVersion, ""),
                                                      tl: {
                                                        hd: tableType === /* Model */0 ? Belt_Option.getWithDefault(param.modelName, "") : Belt_Option.getWithDefault(param.versionName, ""),
                                                        tl: {
                                                          hd: Belt_Option.mapWithDefault(startDate, "", (function (param) {
                                                                  return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                })),
                                                          tl: {
                                                            hd: Belt_Option.mapWithDefault(endDate, "", (function (param) {
                                                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                  })),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration(tableType), index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._2(fetchData, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var tmp = {
    headers: fetchRequest.headers,
    handleSorting: handleSorting,
    tablePage: convertTable(tablePage),
    paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined)
  };
  var tmp$1 = User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) ? ({
        TAG: /* Left */0,
        _0: /* EmptyCell */0
      }) : undefined;
  if (tmp$1 !== undefined) {
    tmp.rowButton = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) ? Caml_option.some(React.createElement(Core.Grid, {
              children: React.createElement(Core.Grid, {
                    children: excludeElement,
                    item: true
                  }),
              container: true,
              item: true,
              justifyContent: "flex-end",
              spacing: 1
            })) : undefined;
  if (tmp$2 !== undefined) {
    tmp.exportMenu = Caml_option.valFromOption(tmp$2);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, tmp), match[0]);
}

var make = Quota_View_Exclusion_Table;

export {
  headersConfiguration ,
  make ,
}
/* react Not a pure module */
