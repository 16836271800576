// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Core from "@material-ui/core";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../App_Typography.bs.js";
import * as File_Upload_Api$DvmAdminFrontendFr from "../../File_Upload_Api.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../types/App_Types_Result.bs.js";
import * as Messages_File_Upload$DvmAdminFrontendFr from "../../../../intl/messages/Messages_File_Upload.bs.js";
import * as File_Upload_Excel_Check_Stage_Table$DvmAdminFrontendFr from "./File_Upload_Excel_Check_Stage_Table.bs.js";
import * as File_Upload_Excel_Check_Internal_Table$DvmAdminFrontendFr from "./File_Upload_Excel_Check_Internal_Table.bs.js";
import * as File_Upload_Excel_Check_Stage_Table_Parc$DvmAdminFrontendFr from "./File_Upload_Excel_Check_Stage_Table_Parc.bs.js";

function File_Upload_Excel_Check(Props) {
  var mapped = Props.mapped;
  var excelInfo = Props.excelInfo;
  var selectedTable = Props.selectedTable;
  var validateResult = Props.validateResult;
  var setValidateResult = Props.setValidateResult;
  var setNextDisabled = Props.setNextDisabled;
  var setOverrideButton = Props.setOverrideButton;
  var checkWithStageResult = Props.checkWithStageResult;
  var setCheckWithStageResult = Props.setCheckWithStageResult;
  var handleNext = Props.handleNext;
  var onClose = Props.onClose;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var checkStage = function (validateResult) {
    File_Upload_Api$DvmAdminFrontendFr.checkWithStageTable(userManager, setCheckWithStageResult, Belt_List.keepMap(Belt_MapString.toList(mapped), (function (item) {
                var table = item[1];
                var excel = item[0];
                if (selectedTable) {
                  if (table.length > 0) {
                    return {
                            tableHeader: table,
                            excelHeader: excel
                          };
                  } else {
                    return ;
                  }
                } else if (table.length > 0) {
                  if (table === "CreationDate") {
                    return {
                            tableHeader: table,
                            excelHeader: ""
                          };
                  } else {
                    return {
                            tableHeader: table,
                            excelHeader: excel
                          };
                  }
                } else {
                  return ;
                }
              })), Belt_Option.getWithDefault(validateResult.expirationToken, ""), selectedTable, Belt_Option.getWithDefault(validateResult.cellRange, ""), excelInfo.sheet, selectedRole);
  };
  React.useEffect((function () {
          if (typeof validateResult === "number") {
            validateResult === /* NotStarted */0;
          } else if (validateResult.TAG === /* Ok */0) {
            var validateResult$1 = validateResult._0;
            if (Belt_List.length(Belt_Option.getWithDefault(validateResult$1.results, /* [] */0)) === 0) {
              checkStage(validateResult$1);
            }
            
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: validateResult._0
                      }
                    }));
          }
        }), [validateResult]);
  React.useEffect((function () {
          Curry._1(setNextDisabled, (function (param) {
                  return true;
                }));
          File_Upload_Api$DvmAdminFrontendFr.fetchValidateResult(userManager, setValidateResult, Belt_List.keepMap(Belt_MapString.toList(mapped), (function (item) {
                      var table = item[1];
                      var excel = item[0];
                      if (selectedTable) {
                        if (table.length > 0) {
                          return {
                                  tableHeader: table,
                                  excelHeader: excel
                                };
                        } else {
                          return ;
                        }
                      } else if (table.length > 0) {
                        if (table === "CreationDate") {
                          return {
                                  tableHeader: table,
                                  excelHeader: ""
                                };
                        } else {
                          return {
                                  tableHeader: table,
                                  excelHeader: excel
                                };
                        }
                      } else {
                        return ;
                      }
                    })), selectedTable, excelInfo.sheet, selectedRole);
        }), []);
  var tmp;
  var exit = 0;
  if (typeof validateResult === "number") {
    exit = 1;
  } else if (validateResult.TAG === /* Ok */0) {
    if (typeof checkWithStageResult === "number") {
      tmp = checkWithStageResult === /* NotStarted */0 ? React.createElement(File_Upload_Excel_Check_Internal_Table$DvmAdminFrontendFr.make, {
              validateResult: validateResult._0,
              excelInfo: excelInfo,
              setNextDisabled: setNextDisabled,
              checkStage: checkStage,
              selectedTable: selectedTable,
              setOverrideButton: setOverrideButton
            }) : React.createElement(App_Typography$DvmAdminFrontendFr.make, {
              color: "primary",
              children: intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.stageCheck),
              variant: "bodyBig"
            });
    } else if (checkWithStageResult.TAG === /* Ok */0) {
      var checkWithStageResult$1 = checkWithStageResult._0;
      tmp = selectedTable ? React.createElement(File_Upload_Excel_Check_Stage_Table$DvmAdminFrontendFr.make, {
              checkWithStageResult: checkWithStageResult$1,
              setNextDisabled: setNextDisabled
            }) : React.createElement(File_Upload_Excel_Check_Stage_Table_Parc$DvmAdminFrontendFr.make, {
              checkWithStageResult: checkWithStageResult$1,
              setNextDisabled: setNextDisabled,
              handleNext: handleNext,
              setOverrideButton: setOverrideButton,
              onClose: onClose
            });
    } else {
      tmp = React.createElement(App_Typography$DvmAdminFrontendFr.make, {
            color: "error",
            children: intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.unexpectedStageError),
            variant: "bodyBig"
          });
    }
  } else {
    tmp = React.createElement(App_Typography$DvmAdminFrontendFr.make, {
          color: "error",
          children: intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.unexpectedInternalError),
          variant: "bodyBig"
        });
  }
  if (exit === 1) {
    tmp = React.createElement(App_Typography$DvmAdminFrontendFr.make, {
          color: "primary",
          children: intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.internalCheck),
          variant: "bodyBig"
        });
  }
  return React.createElement(Core.Grid, {
              alignItems: "center",
              children: null,
              container: true,
              direction: "column"
            }, React.createElement(Core.Grid, {
                  children: tmp,
                  container: true,
                  item: true,
                  justifyContent: "center",
                  style: {
                    marginBottom: "20px"
                  }
                }), App_Types_Result$DvmAdminFrontendFr.isPending(validateResult) || App_Types_Result$DvmAdminFrontendFr.isPending(checkWithStageResult) ? React.createElement(Core.Grid, {
                    children: React.createElement(Core.CircularProgress, {
                          size: 100
                        }),
                    item: true
                  }) : null);
}

var Api;

var Types;

var make = File_Upload_Excel_Check;

export {
  Api ,
  Types ,
  make ,
}
/* react Not a pure module */
