// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Date.bs.js";

function dealerEditsCorrection_encode(v) {
  return Js_dict.fromArray([
              [
                "value",
                Decco.optionToJson(Decco.floatToJson, v.value)
              ],
              [
                "estimatedValue",
                Decco.optionToJson(Decco.floatToJson, v.estimatedValue)
              ],
              [
                "endOfLife",
                Decco.optionToJson(Decco.boolToJson, v.endOfLife)
              ],
              [
                "brandUsedCar",
                Decco.optionToJson(Decco.stringToJson, v.brandUsedCar)
              ],
              [
                "modelUsedCar",
                Decco.optionToJson(Decco.stringToJson, v.modelUsedCar)
              ],
              [
                "versionUsedCar",
                Decco.optionToJson(Decco.stringToJson, v.versionUsedCar)
              ],
              [
                "registrationNumberUsedCar",
                Decco.optionToJson(Decco.stringToJson, v.registrationNumberUsedCar)
              ],
              [
                "vinUsedCar",
                Decco.optionToJson(Decco.stringToJson, v.vinUsedCar)
              ],
              [
                "firstReleaseDateUsedCar",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.firstReleaseDateUsedCar)
              ],
              [
                "registrationDateUsedCar",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.registrationDateUsedCar)
              ],
              [
                "registrationDateUsedCarToDelete",
                Decco.boolToJson(v.registrationDateUsedCarToDelete)
              ],
              [
                "firstReleaseDateUsedCarToDelete",
                Decco.boolToJson(v.firstReleaseDateUsedCarToDelete)
              ],
              [
                "usedCarPoliceNumber",
                Decco.optionToJson(Decco.stringToJson, v.usedCarPoliceNumber)
              ]
            ]);
}

function dealerEditsCorrection_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var value = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "value"), null));
  if (value.TAG === /* Ok */0) {
    var estimatedValue = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "estimatedValue"), null));
    if (estimatedValue.TAG === /* Ok */0) {
      var endOfLife = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endOfLife"), null));
      if (endOfLife.TAG === /* Ok */0) {
        var brandUsedCar = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "brandUsedCar"), null));
        if (brandUsedCar.TAG === /* Ok */0) {
          var modelUsedCar = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelUsedCar"), null));
          if (modelUsedCar.TAG === /* Ok */0) {
            var versionUsedCar = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "versionUsedCar"), null));
            if (versionUsedCar.TAG === /* Ok */0) {
              var registrationNumberUsedCar = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "registrationNumberUsedCar"), null));
              if (registrationNumberUsedCar.TAG === /* Ok */0) {
                var vinUsedCar = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "vinUsedCar"), null));
                if (vinUsedCar.TAG === /* Ok */0) {
                  var firstReleaseDateUsedCar = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "firstReleaseDateUsedCar"), null));
                  if (firstReleaseDateUsedCar.TAG === /* Ok */0) {
                    var registrationDateUsedCar = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "registrationDateUsedCar"), null));
                    if (registrationDateUsedCar.TAG === /* Ok */0) {
                      var registrationDateUsedCarToDelete = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "registrationDateUsedCarToDelete"), null));
                      if (registrationDateUsedCarToDelete.TAG === /* Ok */0) {
                        var firstReleaseDateUsedCarToDelete = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "firstReleaseDateUsedCarToDelete"), null));
                        if (firstReleaseDateUsedCarToDelete.TAG === /* Ok */0) {
                          var usedCarPoliceNumber = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "usedCarPoliceNumber"), null));
                          if (usedCarPoliceNumber.TAG === /* Ok */0) {
                            return {
                                    TAG: /* Ok */0,
                                    _0: {
                                      value: value._0,
                                      estimatedValue: estimatedValue._0,
                                      endOfLife: endOfLife._0,
                                      brandUsedCar: brandUsedCar._0,
                                      modelUsedCar: modelUsedCar._0,
                                      versionUsedCar: versionUsedCar._0,
                                      registrationNumberUsedCar: registrationNumberUsedCar._0,
                                      vinUsedCar: vinUsedCar._0,
                                      firstReleaseDateUsedCar: firstReleaseDateUsedCar._0,
                                      registrationDateUsedCar: registrationDateUsedCar._0,
                                      registrationDateUsedCarToDelete: registrationDateUsedCarToDelete._0,
                                      firstReleaseDateUsedCarToDelete: firstReleaseDateUsedCarToDelete._0,
                                      usedCarPoliceNumber: usedCarPoliceNumber._0
                                    }
                                  };
                          }
                          var e = usedCarPoliceNumber._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".usedCarPoliceNumber" + e.path,
                                    message: e.message,
                                    value: e.value
                                  }
                                };
                        }
                        var e$1 = firstReleaseDateUsedCarToDelete._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".firstReleaseDateUsedCarToDelete" + e$1.path,
                                  message: e$1.message,
                                  value: e$1.value
                                }
                              };
                      }
                      var e$2 = registrationDateUsedCarToDelete._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".registrationDateUsedCarToDelete" + e$2.path,
                                message: e$2.message,
                                value: e$2.value
                              }
                            };
                    }
                    var e$3 = registrationDateUsedCar._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".registrationDateUsedCar" + e$3.path,
                              message: e$3.message,
                              value: e$3.value
                            }
                          };
                  }
                  var e$4 = firstReleaseDateUsedCar._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".firstReleaseDateUsedCar" + e$4.path,
                            message: e$4.message,
                            value: e$4.value
                          }
                        };
                }
                var e$5 = vinUsedCar._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".vinUsedCar" + e$5.path,
                          message: e$5.message,
                          value: e$5.value
                        }
                      };
              }
              var e$6 = registrationNumberUsedCar._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".registrationNumberUsedCar" + e$6.path,
                        message: e$6.message,
                        value: e$6.value
                      }
                    };
            }
            var e$7 = versionUsedCar._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".versionUsedCar" + e$7.path,
                      message: e$7.message,
                      value: e$7.value
                    }
                  };
          }
          var e$8 = modelUsedCar._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".modelUsedCar" + e$8.path,
                    message: e$8.message,
                    value: e$8.value
                  }
                };
        }
        var e$9 = brandUsedCar._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".brandUsedCar" + e$9.path,
                  message: e$9.message,
                  value: e$9.value
                }
              };
      }
      var e$10 = endOfLife._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".endOfLife" + e$10.path,
                message: e$10.message,
                value: e$10.value
              }
            };
    }
    var e$11 = estimatedValue._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".estimatedValue" + e$11.path,
              message: e$11.message,
              value: e$11.value
            }
          };
  }
  var e$12 = value._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".value" + e$12.path,
            message: e$12.message,
            value: e$12.value
          }
        };
}

export {
  dealerEditsCorrection_encode ,
  dealerEditsCorrection_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
