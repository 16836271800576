// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Grid_Edit_Delete$DvmAdminFrontendFr from "./Grid_Edit_Delete.bs.js";
import * as Grid_Edit_EditDate$DvmAdminFrontendFr from "./Grid_Edit_EditDate.bs.js";
import * as Messages_MarginGrid$DvmAdminFrontendFr from "../../../../intl/messages/marginGrid/Messages_MarginGrid.bs.js";
import * as Grid_Edit_NewCategory$DvmAdminFrontendFr from "./Grid_Edit_NewCategory.bs.js";

function Grid_Edit_Modal_Actions(Props) {
  var setActionsPending = Props.setActionsPending;
  var tableResult = Props.tableResult;
  var categoriesAll = Props.categoriesAll;
  var whatTable = Props.whatTable;
  var whatGrid = Props.whatGrid;
  var budget = Props.budget;
  var intl = ReactIntl.useIntl();
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              spacing: 3
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        gutterBottom: true,
                        style: {
                          fontWeight: "800"
                        },
                        children: intl.formatMessage(Messages_MarginGrid$DvmAdminFrontendFr.actionsTitle),
                        variant: "bodyBig"
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  item: true
                }, React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      direction: "column",
                      item: true,
                      lg: Grid$Mui.Lg[6],
                      spacing: 2
                    }, React.createElement(Grid_Edit_EditDate$DvmAdminFrontendFr.make, {
                          setActionsPending: setActionsPending,
                          tableResult: tableResult,
                          whatTable: whatTable,
                          whatGrid: whatGrid,
                          budget: budget,
                          whatDate: "startDate"
                        }), React.createElement(Grid_Edit_EditDate$DvmAdminFrontendFr.make, {
                          setActionsPending: setActionsPending,
                          tableResult: tableResult,
                          whatTable: whatTable,
                          whatGrid: whatGrid,
                          budget: budget,
                          whatDate: "endDate"
                        })), React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      direction: "column",
                      item: true,
                      lg: Grid$Mui.Lg[6],
                      spacing: 2
                    }, whatGrid === /* MarginGrid */1 ? React.createElement(Grid_Edit_Delete$DvmAdminFrontendFr.make, {
                            setActionsPending: setActionsPending,
                            tableResult: tableResult,
                            whatTable: whatTable,
                            whatGrid: whatGrid,
                            budget: budget
                          }) : null, whatTable >= 2 ? React.createElement(Grid_Edit_NewCategory$DvmAdminFrontendFr.make, {
                            setActionsPending: setActionsPending,
                            tableResult: tableResult,
                            categoriesAll: categoriesAll
                          }) : null)));
}

var Types;

var Delete;

var EditDate;

var NewCategory;

var make = Grid_Edit_Modal_Actions;

export {
  Types ,
  Delete ,
  EditDate ,
  NewCategory ,
  make ,
}
/* react Not a pure module */
