// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "@emotion/css";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";

var titleBox = Css.css({
      backgroundColor: Theme_Colors$DvmAdminFrontendFr.white,
      marginBottom: "39px",
      padding: "19px 0px",
      boxShadow: "0px 2px 6px 2px rgba(0, 0, 0, 0.05)"
    });

var detailBox = Css.css({
      borderLeft: "4px solid " + Theme_Colors$DvmAdminFrontendFr.primaryBrown + "",
      marginTop: "16px",
      minHeight: "40px",
      paddingLeft: "31px"
    });

var vehicleBox = Css.css({
      borderLeft: "4px solid " + Theme_Colors$DvmAdminFrontendFr.primaryBrown2 + "",
      marginTop: "16px",
      minHeight: "40px",
      paddingLeft: "31px"
    });

var detailRow = Css.css(Object.assign({}, {
          minHeight: "40px",
          padding: "14px 0px",
          gridRowGap: "10px"
        }, {
          "&:not(:last-child)": {
            borderBottom: "1px solid " + Theme_Colors$DvmAdminFrontendFr.grayLight3 + ""
          }
        }));

var detailItem = Css.css(Object.assign({}, {
          width: "160px"
        }, {
          "&:not(:last-child)": {
            marginRight: "20px"
          },
          "&:last-child": {
            width: "fit-content"
          }
        }));

var helpersBox = Css.css({
      margin: "10px 0px",
      minHeight: "40px",
      padding: "5px 20px"
    });

var helpersBoxNew = Css.css({
      margin: "10px 0px",
      minHeight: "40px",
      padding: "5px 0px 5px 20px"
    });

var bonusesItem = Css.css({
      width: "110px"
    });

var bonusesItemNew = Css.css({
      width: "10%"
    });

var bonusesItemDoubleWide = Css.css({
      width: "20%"
    });

var bonusesItemTripleWide = Css.css({
      width: "30%"
    });

var bonusesItemFiftyPercent = Css.css({
      width: "50%"
    });

var oldVehicleDetailItem = Css.css(Object.assign({}, {
          width: "250px"
        }, {
          "&:not(:last-child)": {
            marginRight: "20px"
          },
          "&:last-child": {
            width: "fit-content"
          }
        }));

export {
  titleBox ,
  detailBox ,
  vehicleBox ,
  detailRow ,
  detailItem ,
  helpersBox ,
  helpersBoxNew ,
  bonusesItem ,
  bonusesItemNew ,
  bonusesItemDoubleWide ,
  bonusesItemTripleWide ,
  bonusesItemFiftyPercent ,
  oldVehicleDetailItem ,
}
/* titleBox Not a pure module */
