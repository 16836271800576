// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../../intl/Intl_Utils.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../../theme/Theme_Colors.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../common/App_Typography.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../../../../common/components/App_LabeledText.bs.js";
import * as Messages_Orders_Correction$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_Correction.bs.js";

function makeLabeledTextElement(label, children) {
  return React.createElement(Core.Box, {
              children: null
            }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  style: {
                    color: Theme_Colors$DvmAdminFrontendFr.mineralDarker
                  },
                  children: label,
                  variant: "subtitle"
                }), React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  color: "textPrimary",
                  style: {
                    fontWeight: "600"
                  },
                  children: children,
                  variant: "body"
                }));
}

function checkEmptyString(string) {
  return Belt_Option.mapWithDefault(string, undefined, (function (x) {
                if (x.length > 0) {
                  return x;
                }
                
              }));
}

function getCurrentValues(index, order) {
  return Belt_Option.mapWithDefault(Belt_List.get(Belt_Option.getWithDefault(order.detail.perfDetails, /* [] */0), index), [
              undefined,
              undefined,
              undefined
            ], (function (x) {
                return [
                        x.planPerfID,
                        x.bonusActionTypePERF,
                        x.planPerfAmount
                      ];
              }));
}

function getNewPerfValues(id, actionType, amount, label, index, order, intl) {
  var match = getCurrentValues(index, order);
  var currentAmount = match[2];
  var currentActionType = match[1];
  var currentID = match[0];
  return [
          Belt_Option.mapWithDefault(checkEmptyString(id), "", (function (x) {
                  if (x !== Belt_Option.getWithDefault(currentID, "")) {
                    return " (" + x + " " + Belt_Option.getWithDefault(checkEmptyString(label), "") + ")";
                  } else {
                    return "";
                  }
                })),
          Belt_Option.mapWithDefault(checkEmptyString(actionType), "", (function (x) {
                  if (x !== Belt_Option.getWithDefault(currentActionType, "")) {
                    return " (" + x + ")";
                  } else {
                    return "";
                  }
                })),
          Belt_Option.mapWithDefault(amount, "", (function (x) {
                  if (x !== Belt_Option.getWithDefault(currentAmount, 0.0)) {
                    return " (" + Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, x) + ")";
                  } else {
                    return "";
                  }
                }))
        ];
}

function Order_Detail_Correct_Perfs_Content(Props) {
  var order = Props.order;
  var color = Props.color;
  var title = Props.title;
  var newestUndeployedCorrection = Props.newestUndeployedCorrection;
  var intl = ReactIntl.useIntl();
  var match_0 = getCurrentValues(0, order);
  var match_1 = getCurrentValues(1, order);
  var match_2 = getCurrentValues(2, order);
  var match = match_2;
  var match$1 = match_1;
  var match$2 = match_0;
  var match$3 = Belt_Option.mapWithDefault(newestUndeployedCorrection, [
        [
          "",
          "",
          ""
        ],
        [
          "",
          "",
          ""
        ],
        [
          "",
          "",
          ""
        ]
      ], (function (newUndepCorr) {
          return [
                  getNewPerfValues(newUndepCorr.planPerfID, newUndepCorr.bonusActionTypePERF, newUndepCorr.planPerfAmount, newUndepCorr.planPerfLabel1, 0, order, intl),
                  getNewPerfValues(newUndepCorr.planPerfID2, newUndepCorr.bonusActionTypePERF2, newUndepCorr.planPerfAmount2, newUndepCorr.planPerfLabel2, 1, order, intl),
                  getNewPerfValues(newUndepCorr.planPerfID3, newUndepCorr.bonusActionTypePERF3, newUndepCorr.planPerfAmount3, newUndepCorr.planPerfLabel3, 2, order, intl)
                ];
        }));
  var match$4 = match$3[2];
  var match$5 = match$3[1];
  var match$6 = match$3[0];
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              direction: "column",
              item: true,
              sm: Grid$Mui.Sm[6],
              spacing: 2
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        style: {
                          color: color,
                          fontWeight: "600"
                        },
                        children: title
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Core.Box, {
                        children: React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              direction: "column",
                              spacing: 5
                            }, React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  direction: "column",
                                  item: true,
                                  spacing: 1
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Box, {
                                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                  label: Messages_Orders_Correction$DvmAdminFrontendFr.idPerf,
                                                  children: Belt_Option.getWithDefault(match$2[0], "-") + match$6[0]
                                                }),
                                            minHeight: 48
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Box, {
                                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                  label: Messages_Orders_Correction$DvmAdminFrontendFr.typePerf,
                                                  children: Belt_Option.getWithDefault(match$2[1], "-") + match$6[1]
                                                }),
                                            minHeight: 48
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Box, {
                                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                  label: Messages_Orders_Correction$DvmAdminFrontendFr.amountPerf,
                                                  children: Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, Belt_Option.getWithDefault(match$2[2], 0.0)) + match$6[2]
                                                }),
                                            minHeight: 48
                                          }),
                                      item: true
                                    })), React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  direction: "column",
                                  item: true,
                                  spacing: 1
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Box, {
                                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                  label: Messages_Orders_Correction$DvmAdminFrontendFr.idPerf2,
                                                  children: Belt_Option.getWithDefault(match$1[0], "-") + match$5[0]
                                                }),
                                            minHeight: 48
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Box, {
                                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                  label: Messages_Orders_Correction$DvmAdminFrontendFr.typePerf2,
                                                  children: Belt_Option.getWithDefault(match$1[1], "-") + match$5[1]
                                                }),
                                            minHeight: 48
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Box, {
                                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                  label: Messages_Orders_Correction$DvmAdminFrontendFr.amountPerf2,
                                                  children: Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, Belt_Option.getWithDefault(match$1[2], 0.0)) + match$5[2]
                                                }),
                                            minHeight: 48
                                          }),
                                      item: true
                                    })), React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  direction: "column",
                                  item: true,
                                  spacing: 1
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Box, {
                                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                  label: Messages_Orders_Correction$DvmAdminFrontendFr.idPerf3,
                                                  children: Belt_Option.getWithDefault(match[0], "-") + match$4[0]
                                                }),
                                            minHeight: 48
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Box, {
                                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                  label: Messages_Orders_Correction$DvmAdminFrontendFr.typePerf3,
                                                  children: Belt_Option.getWithDefault(match[1], "-") + match$4[1]
                                                }),
                                            minHeight: 48
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Box, {
                                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                  label: Messages_Orders_Correction$DvmAdminFrontendFr.amountPerf3,
                                                  children: Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, Belt_Option.getWithDefault(match[2], 0.0)) + match$4[2]
                                                }),
                                            minHeight: 48
                                          }),
                                      item: true
                                    }))),
                        borderColor: color,
                        borderLeft: 4,
                        boxShadow: "0 2px 7px 1px #eee",
                        padding: "24px 32px"
                      }),
                  item: true
                }));
}

var make = Order_Detail_Correct_Perfs_Content;

export {
  makeLabeledTextElement ,
  checkEmptyString ,
  getCurrentValues ,
  getNewPerfValues ,
  make ,
}
/* react Not a pure module */
