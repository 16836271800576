// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as File_Upload_Contract_Errors_Types$DvmAdminFrontendFr from "./File_Upload_Contract_Errors_Types.bs.js";

function fetchAttachmentUploadErrorsSearch(userManager, setResult, queryParams, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", File_Upload_Contract_Errors_Types$DvmAdminFrontendFr.Errors.searchResult_decode, undefined, userManager, selectedRole, "/contract/attachments/check/blocked" + queryParams + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

export {
  fetchAttachmentUploadErrorsSearch ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
