// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";

var FilterFields = {
  budget: "budget",
  contractID: "contractID",
  categoryLabel: "categoryName",
  model: "model",
  version: "version",
  startYear: "startYear",
  endYear: "endYear"
};

var Fields = {
  model: "model",
  version: "version",
  hasExceptions: "exception",
  categoryLabel: "categoryName",
  acquisitionType: "acquisitionType",
  discount: "discount",
  startDate: "startDate",
  endDate: "endDate"
};

function modelVersionRow_encode(v) {
  return Js_dict.fromArray([
              [
                "model",
                Decco.stringToJson(v.model)
              ],
              [
                "version",
                Decco.optionToJson(Decco.stringToJson, v.version)
              ],
              [
                "phase",
                Decco.optionToJson(Decco.stringToJson, v.phase)
              ],
              [
                "categoryName",
                Decco.optionToJson(Decco.stringToJson, v.categoryName)
              ],
              [
                "hasExceptions",
                Decco.optionToJson(Decco.boolToJson, v.hasExceptions)
              ],
              [
                "acquisitionType",
                Decco.optionToJson(Decco.stringToJson, v.acquisitionType)
              ],
              [
                "discount",
                Decco.optionToJson(Decco.floatToJson, v.discount)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function modelVersionRow_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var model = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "model"), null));
  if (model.TAG === /* Ok */0) {
    var version = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "version"), null));
    if (version.TAG === /* Ok */0) {
      var phase = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "phase"), null));
      if (phase.TAG === /* Ok */0) {
        var categoryName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "categoryName"), null));
        if (categoryName.TAG === /* Ok */0) {
          var hasExceptions = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "hasExceptions"), null));
          if (hasExceptions.TAG === /* Ok */0) {
            var acquisitionType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "acquisitionType"), null));
            if (acquisitionType.TAG === /* Ok */0) {
              var discount = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "discount"), null));
              if (discount.TAG === /* Ok */0) {
                var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
                if (startDate.TAG === /* Ok */0) {
                  var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
                  if (endDate.TAG === /* Ok */0) {
                    return {
                            TAG: /* Ok */0,
                            _0: {
                              model: model._0,
                              version: version._0,
                              phase: phase._0,
                              categoryName: categoryName._0,
                              hasExceptions: hasExceptions._0,
                              acquisitionType: acquisitionType._0,
                              discount: discount._0,
                              startDate: startDate._0,
                              endDate: endDate._0
                            }
                          };
                  }
                  var e = endDate._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".endDate" + e.path,
                            message: e.message,
                            value: e.value
                          }
                        };
                }
                var e$1 = startDate._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".startDate" + e$1.path,
                          message: e$1.message,
                          value: e$1.value
                        }
                      };
              }
              var e$2 = discount._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".discount" + e$2.path,
                        message: e$2.message,
                        value: e$2.value
                      }
                    };
            }
            var e$3 = acquisitionType._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".acquisitionType" + e$3.path,
                      message: e$3.message,
                      value: e$3.value
                    }
                  };
          }
          var e$4 = hasExceptions._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".hasExceptions" + e$4.path,
                    message: e$4.message,
                    value: e$4.value
                  }
                };
        }
        var e$5 = categoryName._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".categoryName" + e$5.path,
                  message: e$5.message,
                  value: e$5.value
                }
              };
      }
      var e$6 = phase._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".phase" + e$6.path,
                message: e$6.message,
                value: e$6.value
              }
            };
    }
    var e$7 = version._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".version" + e$7.path,
              message: e$7.message,
              value: e$7.value
            }
          };
  }
  var e$8 = model._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".model" + e$8.path,
            message: e$8.message,
            value: e$8.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(modelVersionRow_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(modelVersionRow_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var ModelVersion = {
  modelVersionRow_encode: modelVersionRow_encode,
  modelVersionRow_decode: modelVersionRow_decode,
  searchResult_encode: searchResult_encode,
  searchResult_decode: searchResult_decode
};

function contracts_encode(v) {
  return Js_dict.fromArray([
              [
                "contractID",
                Decco.stringToJson(v.contractID)
              ],
              [
                "budget",
                Decco.optionToJson(Decco.stringToJson, v.budget)
              ],
              [
                "contractLabel",
                Decco.optionToJson(Decco.stringToJson, v.contractLabel)
              ]
            ]);
}

function contracts_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var contractID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractID"), null));
  if (contractID.TAG === /* Ok */0) {
    var budget = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "budget"), null));
    if (budget.TAG === /* Ok */0) {
      var contractLabel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractLabel"), null));
      if (contractLabel.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  contractID: contractID._0,
                  budget: budget._0,
                  contractLabel: contractLabel._0
                }
              };
      }
      var e = contractLabel._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".contractLabel" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = budget._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".budget" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = contractID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".contractID" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function searchResult_encode$1(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(contracts_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode$1(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(contracts_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var ContractSelect = {
  contracts_encode: contracts_encode,
  contracts_decode: contracts_decode,
  searchResult_encode: searchResult_encode$1,
  searchResult_decode: searchResult_decode$1
};

export {
  FilterFields ,
  Fields ,
  ModelVersion ,
  ContractSelect ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
