// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../api/RestApi.bs.js";
import * as Grid_Types$DvmAdminFrontendFr from "../../Grid_Types.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../../../common/utils/Utils_Date.bs.js";
import * as Grid_Edit_Api$DvmAdminFrontendFr from "../Grid_Edit_Api.bs.js";
import * as Category_Types$DvmAdminFrontendFr from "../../../category/Category_Types.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as Grid_Edit_Api_Check$DvmAdminFrontendFr from "../Grid_Edit_Api_Check.bs.js";

function fetchCategories(setCategoriesFiltered, userManager, model, selectedRole) {
  Curry._1(setCategoriesFiltered, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Category_Types$DvmAdminFrontendFr.CategorySearch.searchResult_decode, undefined, userManager, selectedRole, "/category/search/true?limit=150000&offset=0&filter=model=" + model + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchResult = result._0;
          return Curry._1(setCategoriesFiltered, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchResult
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setCategoriesFiltered, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function categoryByDateType_encode(v) {
  return Js_dict.fromArray([[
                "categoryID",
                Decco.stringToJson(v.categoryID)
              ]]);
}

function categoryByDateType_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var categoryID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "categoryID"), null));
  if (categoryID.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              categoryID: categoryID._0
            }
          };
  }
  var e = categoryID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".categoryID" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function categoryByDateSearchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(categoryByDateType_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function categoryByDateSearchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(categoryByDateType_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function fetchCategoriesByDate(startDate, endDate, setResult, userManager, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var start = Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(startDate), "1900-01-01");
  var end = Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(endDate), "3000-12-31");
  var filter = encodeURIComponent(Belt_Array.joinWith([
              "startDate=[\\," + start + "]&&endDate=[" + start + "\\,]",
              "startDate=[\\," + end + "]&&endDate=[" + end + "\\,]",
              "startDate=[" + start + "\\,]&&endDate=[\\," + end + "]",
              "startDate=[\\," + start + "]&&endDate=[" + end + "\\,]"
            ], "||", (function (x) {
                return x;
              }))).replace(/%20/g, "%2B");
  RestApi$DvmAdminFrontendFr.call("GET", categoryByDateSearchResult_decode, undefined, userManager, selectedRole, "/category/filtered?limit=150000&offset=0&filter=" + filter + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchResult = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(searchResult.content, (function (x) {
                                                    return x.categoryID;
                                                  })))))
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function tryCreateNewMarginRow(submissionCallbacks, userManager, setResult, whatTable, whatGrid, budget, selectedRole, row) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Grid_Types$DvmAdminFrontendFr.Margins.searchResult_decode, undefined, userManager, selectedRole, Grid_Edit_Api_Check$DvmAdminFrontendFr.getQuery(whatTable, whatGrid, {
                hd: row,
                tl: /* [] */0
              }).replace(/\s/g, "%20")).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          if (Belt_List.length(result._0.content) > 0) {
            Belt_Option.forEach(submissionCallbacks, (function (param) {
                    Curry._1(param.notifyOnFailure, /* FailedToCreateMarginGridRow */0);
                  }));
            return Curry._1(setResult, (function (param) {
                          return {
                                  TAG: /* Error */1,
                                  _0: App_Types_Result$DvmAdminFrontendFr.Rest.makeError(undefined, undefined, undefined, "9ff6a009-181a-46a6-81de-e47e68753e69")
                                };
                        }));
          } else {
            RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
                    TAG: /* Json */0,
                    _0: Grid_Types$DvmAdminFrontendFr.Margins.editList_encode({
                          hd: row,
                          tl: /* [] */0
                        })
                  }, userManager, selectedRole, Grid_Edit_Api$DvmAdminFrontendFr.getEndpoint(whatGrid, whatTable, budget, undefined)).then(function (result) {
                  if (result.TAG === /* OkResult */0) {
                    Belt_Option.forEach(submissionCallbacks, (function (param) {
                            Curry._1(param.notifyOnSuccess, undefined);
                          }));
                    return Curry._1(setResult, (function (param) {
                                  return {
                                          TAG: /* Ok */0,
                                          _0: undefined
                                        };
                                }));
                  }
                  var error = result._0;
                  Belt_Option.forEach(submissionCallbacks, (function (param) {
                          Curry._1(param.notifyOnFailure, /* FailedToCreateMarginGridRow */0);
                        }));
                  Curry._1(setResult, (function (param) {
                          return {
                                  TAG: /* Error */1,
                                  _0: error
                                };
                        }));
                });
            return ;
          }
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToCreateMarginGridRow */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function tryAddCategoryToMargins(submissionCallbacks, userManager, setResult, categoryCode, categoryStartDate, budget, whatGrid, selectedRole, row) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
          TAG: /* Json */0,
          _0: Grid_Types$DvmAdminFrontendFr.Margins.marginRow_encode(row)
        }, userManager, selectedRole, whatGrid === /* ModelVersionGrid */0 ? "/versiongrid/" + budget + "/" + categoryCode + "/" + categoryStartDate + "" : "/margins/modelversion/" + categoryCode + "/" + categoryStartDate + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToCreateMarginGridRow */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

var AddRowForm;

var ApiCheck;

export {
  Form ,
  AddRowForm ,
  ApiCheck ,
  fetchCategories ,
  categoryByDateType_encode ,
  categoryByDateType_decode ,
  categoryByDateSearchResult_encode ,
  categoryByDateSearchResult_decode ,
  fetchCategoriesByDate ,
  tryCreateNewMarginRow ,
  tryAddCategoryToMargins ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
