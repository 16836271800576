// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as App_Table$DvmAdminFrontendFr from "../../../../common/components/table/App_Table.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../../common/components/App_Checkbox.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Category$DvmAdminFrontendFr from "../../../../intl/messages/category/Messages_Category.bs.js";
import * as App_UserTypeCheckBox$DvmAdminFrontendFr from "../../../../common/components/input/App_UserTypeCheckBox.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../common/components/input/App_DateInputFormField.bs.js";

var checkbox = Css.css({
      marginTop: "-9px"
    });

var standardInputRow = Css.css(Object.assign({}, {
          width: "120px"
        }, {
          "& > div *": {
            paddingTop: "0px"
          }
        }));

var tooltip = Css.css({
      backgroundColor: "white",
      border: "1px solid black"
    });

function makeRow(form, index, takenVersionsList, intl, updateSelectedRows) {
  var row = Belt_Array.get(form.input.versions, index);
  return Belt_Option.mapWithDefault(row, {
              cells: /* [] */0,
              onClick: undefined,
              rowButton: undefined,
              disabled: undefined
            }, (function (row) {
                var tmp = {
                  value: row.startDate,
                  onChange: (function (startDate) {
                      Curry._2(updateSelectedRows, {
                            selected: row.selected,
                            versionID: row.versionID,
                            versionLabel: row.versionLabel,
                            startDate: startDate,
                            startDateAsCategory: row.startDateAsCategory,
                            endDate: row.endDate,
                            endDateAsCategory: row.endDateAsCategory
                          }, "add");
                    }),
                  disabled: row.startDateAsCategory,
                  className: standardInputRow
                };
                var tmp$1 = Curry._1(form.versionStartDateResult, index);
                if (tmp$1 !== undefined) {
                  tmp.validationResult = Caml_option.valFromOption(tmp$1);
                }
                var tmp$2 = {
                  value: row.endDate,
                  onChange: (function (endDate) {
                      Curry._2(updateSelectedRows, {
                            selected: row.selected,
                            versionID: row.versionID,
                            versionLabel: row.versionLabel,
                            startDate: row.startDate,
                            startDateAsCategory: row.startDateAsCategory,
                            endDate: endDate,
                            endDateAsCategory: row.endDateAsCategory
                          }, "add");
                    }),
                  disabled: row.endDateAsCategory,
                  className: standardInputRow
                };
                var tmp$3 = Curry._1(form.versionEndDateResult, index);
                if (tmp$3 !== undefined) {
                  tmp$2.validationResult = Caml_option.valFromOption(tmp$3);
                }
                return App_Table_Types$DvmAdminFrontendFr.makeRow((function (param) {
                              
                            }), Caml_option.some(React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                    checked: row.selected,
                                    onClick: (function ($$event) {
                                        Curry._2(updateSelectedRows, {
                                              selected: !row.selected,
                                              versionID: row.versionID,
                                              versionLabel: row.versionLabel,
                                              startDate: row.startDate,
                                              startDateAsCategory: row.startDateAsCategory,
                                              endDate: row.endDate,
                                              endDateAsCategory: row.endDateAsCategory
                                            }, row.selected ? "remove" : "add");
                                      }),
                                    disabled: false,
                                    className: checkbox
                                  })), false, Belt_List.mapWithIndex({
                                hd: row.versionID,
                                tl: {
                                  hd: row.versionLabel,
                                  tl: {
                                    hd: React.createElement(Core.Grid, {
                                          children: null,
                                          container: true,
                                          spacing: 2
                                        }, React.createElement(Core.Grid, {
                                              children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp),
                                              item: true
                                            }), React.createElement(Core.Grid, {
                                              children: React.createElement(Core.Tooltip, {
                                                    arrow: true,
                                                    children: React.createElement("div", undefined, React.createElement(App_UserTypeCheckBox$DvmAdminFrontendFr.make, {
                                                              checked: row.startDateAsCategory,
                                                              onClick: (function ($$event) {
                                                                  Curry._2(updateSelectedRows, {
                                                                        selected: $$event.target.checked ? row.selected : true,
                                                                        versionID: row.versionID,
                                                                        versionLabel: row.versionLabel,
                                                                        startDate: $$event.target.checked ? undefined : row.startDate,
                                                                        startDateAsCategory: !row.startDateAsCategory,
                                                                        endDate: row.endDate,
                                                                        endDateAsCategory: row.endDateAsCategory
                                                                      }, "add");
                                                                })
                                                            })),
                                                    classes: {
                                                      tooltip: tooltip
                                                    },
                                                    placement: "left",
                                                    title: intl.formatMessage(Messages_Category$DvmAdminFrontendFr.sameAsCategory)
                                                  }),
                                              item: true
                                            })),
                                    tl: {
                                      hd: React.createElement(Core.Grid, {
                                            children: null,
                                            container: true,
                                            spacing: 2
                                          }, React.createElement(Core.Grid, {
                                                children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$2),
                                                item: true
                                              }), React.createElement(Core.Grid, {
                                                children: React.createElement(Core.Tooltip, {
                                                      arrow: true,
                                                      children: React.createElement("div", undefined, React.createElement(App_UserTypeCheckBox$DvmAdminFrontendFr.make, {
                                                                checked: row.endDateAsCategory,
                                                                onClick: (function ($$event) {
                                                                    Curry._2(updateSelectedRows, {
                                                                          selected: $$event.target.checked ? row.selected : true,
                                                                          versionID: row.versionID,
                                                                          versionLabel: row.versionLabel,
                                                                          startDate: row.startDate,
                                                                          startDateAsCategory: row.startDateAsCategory,
                                                                          endDate: $$event.target.checked ? undefined : row.endDate,
                                                                          endDateAsCategory: !row.endDateAsCategory
                                                                        }, "add");
                                                                  })
                                                              })),
                                                      classes: {
                                                        tooltip: tooltip
                                                      },
                                                      placement: "left",
                                                      title: intl.formatMessage(Messages_Category$DvmAdminFrontendFr.sameAsCategory)
                                                    }),
                                                item: true
                                              }), Belt_List.length(Belt_List.keep(takenVersionsList, (function (x) {
                                                      return x.version === row.versionID;
                                                    }))) > 0 ? React.createElement(Core.Grid, {
                                                  children: React.createElement(Core.Tooltip, {
                                                        arrow: true,
                                                        children: React.createElement("div", undefined, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                  size: /* Large */3,
                                                                  type_: /* HelpWarning */0,
                                                                  color: "red"
                                                                })),
                                                        classes: {
                                                          tooltip: tooltip
                                                        },
                                                        placement: "left-start",
                                                        title: React.createElement(React.Fragment, undefined, React.createElement("p", undefined, intl.formatMessage(Messages_Category$DvmAdminFrontendFr.versionsTaken)), React.createElement("div", undefined, Belt_Array.mapWithIndex(Belt_List.toArray(Belt_List.sort(Belt_List.keep(takenVersionsList, (function (x) {
                                                                                    return x.version === row.versionID;
                                                                                  })), (function (a, b) {
                                                                                return ReDate.compareAsc(Belt_Option.getWithDefault(a.startDate, new Date(0.0)), Belt_Option.getWithDefault(b.startDate, new Date(0.0)));
                                                                              }))), (function (index, element) {
                                                                        return React.createElement("p", {
                                                                                    key: String(index)
                                                                                  }, "" + Belt_Option.mapWithDefault(element.startDate, "", (function (param) {
                                                                                          return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                                        })) + " - " + Belt_Option.mapWithDefault(element.endDate, "", (function (param) {
                                                                                          return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                                        })) + "");
                                                                      }))))
                                                      }),
                                                  item: true
                                                }) : null),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }, (function (param, content) {
                                  return App_Table$DvmAdminFrontendFr.makeCell(undefined, undefined, undefined, content);
                                })));
              }));
}

var Form;

export {
  Form ,
  checkbox ,
  standardInputRow ,
  tooltip ,
  makeRow ,
}
/* checkbox Not a pure module */
