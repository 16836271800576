// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Date.bs.js";

function versionType_encode(v) {
  return Js_dict.fromArray([
              [
                "model",
                Decco.stringToJson(v.model)
              ],
              [
                "modelVersion",
                Decco.stringToJson(v.modelVersion)
              ],
              [
                "modelVersionName",
                Decco.stringToJson(v.modelVersionName)
              ],
              [
                "vehicleType",
                Decco.stringToJson(v.vehicleType)
              ],
              [
                "engineType",
                Decco.stringToJson(v.engineType)
              ],
              [
                "brand",
                Decco.stringToJson(v.brand)
              ],
              [
                "modelPhase",
                Decco.stringToJson(v.modelPhase)
              ],
              [
                "categoryId",
                Decco.optionToJson(Decco.stringToJson, v.categoryId)
              ],
              [
                "categoryName",
                Decco.optionToJson(Decco.stringToJson, v.categoryName)
              ]
            ]);
}

function versionType_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var model = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "model"), null));
  if (model.TAG === /* Ok */0) {
    var modelVersion = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelVersion"), null));
    if (modelVersion.TAG === /* Ok */0) {
      var modelVersionName = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelVersionName"), null));
      if (modelVersionName.TAG === /* Ok */0) {
        var vehicleType = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleType"), null));
        if (vehicleType.TAG === /* Ok */0) {
          var engineType = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "engineType"), null));
          if (engineType.TAG === /* Ok */0) {
            var brand = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "brand"), null));
            if (brand.TAG === /* Ok */0) {
              var modelPhase = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelPhase"), null));
              if (modelPhase.TAG === /* Ok */0) {
                var categoryId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "categoryId"), null));
                if (categoryId.TAG === /* Ok */0) {
                  var categoryName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "categoryName"), null));
                  if (categoryName.TAG === /* Ok */0) {
                    return {
                            TAG: /* Ok */0,
                            _0: {
                              model: model._0,
                              modelVersion: modelVersion._0,
                              modelVersionName: modelVersionName._0,
                              vehicleType: vehicleType._0,
                              engineType: engineType._0,
                              brand: brand._0,
                              modelPhase: modelPhase._0,
                              categoryId: categoryId._0,
                              categoryName: categoryName._0
                            }
                          };
                  }
                  var e = categoryName._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".categoryName" + e.path,
                            message: e.message,
                            value: e.value
                          }
                        };
                }
                var e$1 = categoryId._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".categoryId" + e$1.path,
                          message: e$1.message,
                          value: e$1.value
                        }
                      };
              }
              var e$2 = modelPhase._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".modelPhase" + e$2.path,
                        message: e$2.message,
                        value: e$2.value
                      }
                    };
            }
            var e$3 = brand._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".brand" + e$3.path,
                      message: e$3.message,
                      value: e$3.value
                    }
                  };
          }
          var e$4 = engineType._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".engineType" + e$4.path,
                    message: e$4.message,
                    value: e$4.value
                  }
                };
        }
        var e$5 = vehicleType._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".vehicleType" + e$5.path,
                  message: e$5.message,
                  value: e$5.value
                }
              };
      }
      var e$6 = modelVersionName._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".modelVersionName" + e$6.path,
                message: e$6.message,
                value: e$6.value
              }
            };
    }
    var e$7 = modelVersion._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".modelVersion" + e$7.path,
              message: e$7.message,
              value: e$7.value
            }
          };
  }
  var e$8 = model._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".model" + e$8.path,
            message: e$8.message,
            value: e$8.value
          }
        };
}

function versionTypeList_encode(v) {
  return Decco.listToJson(versionType_encode, v);
}

function versionTypeList_decode(v) {
  return Decco.listFromJson(versionType_decode, v);
}

function oneTwoItem_encode(encoder_a, v) {
  return Js_dict.fromArray([
              [
                "one",
                Decco.stringToJson(v.one)
              ],
              [
                "two",
                Curry._1(encoder_a, v.two)
              ]
            ]);
}

function oneTwoItem_decode(decoder_a, v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var one = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "one"), null));
  if (one.TAG === /* Ok */0) {
    var two = Curry._1(decoder_a, Belt_Option.getWithDefault(Js_dict.get(dict$1, "two"), null));
    if (two.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                one: one._0,
                two: two._0
              }
            };
    }
    var e = two._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".two" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = one._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".one" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function t_encode(v) {
  return Js_dict.fromArray([
              [
                "actionStartDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.actionStartDate)
              ],
              [
                "actionEndDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.actionEndDate)
              ],
              [
                "customBonuses",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson((function (param) {
                                      return oneTwoItem_encode(Decco.floatToJson, param);
                                    }), param);
                      }), v.customBonuses)
              ],
              [
                "engineTypes",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(Decco.stringToJson, param);
                      }), v.engineTypes)
              ],
              [
                "modelPhases",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson((function (param) {
                                      return oneTwoItem_encode(Decco.stringToJson, param);
                                    }), param);
                      }), v.modelPhases)
              ],
              [
                "mappingDirection",
                Decco.optionToJson(Decco.stringToJson, v.mappingDirection)
              ],
              [
                "modelVersions",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(Decco.stringToJson, param);
                      }), v.modelVersions)
              ],
              [
                "brandsNewCar",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(Decco.stringToJson, param);
                      }), v.brandsNewCar)
              ]
            ]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var actionStartDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionStartDate"), null));
  if (actionStartDate.TAG === /* Ok */0) {
    var actionEndDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionEndDate"), null));
    if (actionEndDate.TAG === /* Ok */0) {
      var customBonuses = Decco.optionFromJson((function (param) {
              return Decco.listFromJson((function (param) {
                            return oneTwoItem_decode(Decco.floatFromJson, param);
                          }), param);
            }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "customBonuses"), null));
      if (customBonuses.TAG === /* Ok */0) {
        var engineTypes = Decco.optionFromJson((function (param) {
                return Decco.listFromJson(Decco.stringFromJson, param);
              }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "engineTypes"), null));
        if (engineTypes.TAG === /* Ok */0) {
          var modelPhases = Decco.optionFromJson((function (param) {
                  return Decco.listFromJson((function (param) {
                                return oneTwoItem_decode(Decco.stringFromJson, param);
                              }), param);
                }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelPhases"), null));
          if (modelPhases.TAG === /* Ok */0) {
            var mappingDirection = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "mappingDirection"), null));
            if (mappingDirection.TAG === /* Ok */0) {
              var modelVersions = Decco.optionFromJson((function (param) {
                      return Decco.listFromJson(Decco.stringFromJson, param);
                    }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelVersions"), null));
              if (modelVersions.TAG === /* Ok */0) {
                var brandsNewCar = Decco.optionFromJson((function (param) {
                        return Decco.listFromJson(Decco.stringFromJson, param);
                      }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "brandsNewCar"), null));
                if (brandsNewCar.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: {
                            actionStartDate: actionStartDate._0,
                            actionEndDate: actionEndDate._0,
                            customBonuses: customBonuses._0,
                            engineTypes: engineTypes._0,
                            modelPhases: modelPhases._0,
                            mappingDirection: mappingDirection._0,
                            modelVersions: modelVersions._0,
                            brandsNewCar: brandsNewCar._0
                          }
                        };
                }
                var e = brandsNewCar._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".brandsNewCar" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = modelVersions._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".modelVersions" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = mappingDirection._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".mappingDirection" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = modelPhases._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".modelPhases" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = engineTypes._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".engineTypes" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = customBonuses._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".customBonuses" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = actionEndDate._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".actionEndDate" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = actionStartDate._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".actionStartDate" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

var NewVehicles = {
  oneTwoItem_encode: oneTwoItem_encode,
  oneTwoItem_decode: oneTwoItem_decode,
  t_encode: t_encode,
  t_decode: t_decode
};

var delimiter = ":::";

export {
  versionType_encode ,
  versionType_decode ,
  versionTypeList_encode ,
  versionTypeList_decode ,
  delimiter ,
  NewVehicles ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
