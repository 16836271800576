// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_AutocompleteFormInput.bs.js";

function Quota_Detail_Attachment_Filters(Props) {
  var quota = Props.quota;
  var form = Props.form;
  var drAndDealers = Props.drAndDealers;
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry._1(form.updateSiret, (function (input, siret) {
                                    return {
                                            siret: siret,
                                            dealerId: input.dealerId,
                                            dr: input.dr,
                                            contractId: input.contractId,
                                            vehicleType: input.vehicleType,
                                            startDate: input.startDate,
                                            endDate: input.endDate
                                          };
                                  })),
                            value: form.input.siret,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
                            label: Messages_Common$DvmAdminFrontendFr.siret
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Common$DvmAdminFrontendFr.dealerID,
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.dealerId,
                                onChange: Curry._1(form.updateDealerId, (function (input, dealerId) {
                                        return {
                                                siret: input.siret,
                                                dealerId: dealerId,
                                                dr: input.dr,
                                                contractId: input.contractId,
                                                vehicleType: input.vehicleType,
                                                startDate: input.startDate,
                                                endDate: input.endDate
                                              };
                                      }))
                              }
                            },
                            options: {
                              TAG: /* Unlabeled */0,
                              _0: Belt_Option.mapWithDefault(Belt_List.getBy(App_Types_Result$DvmAdminFrontendFr.getWithDefault(drAndDealers, /* [] */0), (function (x) {
                                          return x.parent === quota.dr;
                                        })), /* [] */0, (function (x) {
                                      return x.child;
                                    }))
                            },
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var Form;

var make = Quota_Detail_Attachment_Filters;

export {
  Form ,
  make ,
}
/* react Not a pure module */
