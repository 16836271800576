// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_RadioFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_RadioFormInput.bs.js";
import * as Messages_Bonuses_List$DvmAdminFrontendFr from "../../../../../intl/messages/bonuses/Messages_Bonuses_List.bs.js";
import * as Messages_Orders_Modal$DvmAdminFrontendFr from "../../../../../intl/messages/orders/Messages_Orders_Modal.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Order_Search_Modal_Date_Box$DvmAdminFrontendFr from "../Order_Search_Modal_Date_Box.bs.js";

function Order_Search_Modal_Date_Filters(Props) {
  var orderType = Props.orderType;
  var form = Props.form;
  var refreshCategories = Props.refreshCategories;
  var intl = ReactIntl.useIntl();
  var match = form.input.delivered;
  var tmp;
  switch (match) {
    case "" :
        tmp = Messages_Orders_Modal$DvmAdminFrontendFr.deliveryPeriodBoth;
        break;
    case "FALSE" :
        tmp = Messages_Orders_Modal$DvmAdminFrontendFr.desiredDeliveryPeriod;
        break;
    case "TRUE" :
        tmp = Messages_Orders_Modal$DvmAdminFrontendFr.deliveryPeriod;
        break;
    default:
      tmp = Messages_Orders_Modal$DvmAdminFrontendFr.deliveryPeriodBoth;
  }
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              item: true,
              spacing: 8
            }, React.createElement(Core.Grid, {
                  children: React.createElement(Core.Grid, {
                        children: React.createElement("div", {
                              onBlur: (function (param) {
                                  Curry._1(refreshCategories, undefined);
                                })
                            }, React.createElement(Order_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                                  title: Messages_Orders_Modal$DvmAdminFrontendFr.orderPeriod,
                                  onChangeFrom: Curry._1(form.updateOrderDateStart, (function (input, orderDateStart) {
                                          var newrecord = Caml_obj.obj_dup(input);
                                          newrecord.orderDateStart = orderDateStart;
                                          return newrecord;
                                        })),
                                  onChangeTo: Curry._1(form.updateOrderDateEnd, (function (input, orderDateEnd) {
                                          var newrecord = Caml_obj.obj_dup(input);
                                          newrecord.orderDateEnd = orderDateEnd;
                                          return newrecord;
                                        })),
                                  dateFrom: form.input.orderDateStart,
                                  dateTo: form.input.orderDateEnd,
                                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                                })),
                        item: true
                      }),
                  container: true,
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  spacing: 1
                }), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  spacing: 1
                }, React.createElement(Core.Grid, {
                      children: React.createElement(Order_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                            title: tmp,
                            onChangeFrom: Curry._1(form.updateDeliveryDateStart, (function (input, deliveryDateStart) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.deliveryDateStart = deliveryDateStart;
                                    return newrecord;
                                  })),
                            onChangeTo: Curry._1(form.updateDeliveryDateEnd, (function (input, deliveryDateEnd) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.deliveryDateEnd = deliveryDateEnd;
                                    return newrecord;
                                  })),
                            dateFrom: form.input.deliveryDateStart,
                            dateTo: form.input.deliveryDateEnd,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      item: true,
                      spacing: 2
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                style: {
                                  fontWeight: "400"
                                },
                                children: intl.formatMessage(Messages_Orders_Modal$DvmAdminFrontendFr.delivered)
                              }),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, {
                                value: form.input.delivered,
                                options: {
                                  hd: {
                                    label: {
                                      TAG: /* Message */0,
                                      _0: Messages_Common$DvmAdminFrontendFr.yes
                                    },
                                    value: "TRUE"
                                  },
                                  tl: {
                                    hd: {
                                      label: {
                                        TAG: /* Message */0,
                                        _0: Messages_Common$DvmAdminFrontendFr.no
                                      },
                                      value: "FALSE"
                                    },
                                    tl: {
                                      hd: {
                                        label: {
                                          TAG: /* Message */0,
                                          _0: Messages_Common$DvmAdminFrontendFr.everything
                                        },
                                        value: ""
                                      },
                                      tl: /* [] */0
                                    }
                                  }
                                },
                                onChange: Curry._1(form.updateDelivered, (function (input, delivered) {
                                        var newrecord = Caml_obj.obj_dup(input);
                                        newrecord.delivered = delivered;
                                        return newrecord;
                                      })),
                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                              }),
                          item: true
                        }))), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  spacing: 1
                }, React.createElement(Core.Grid, {
                      children: React.createElement(Order_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                            disabled: form.input.registrationDateExists === "no",
                            title: Messages_Orders_Modal$DvmAdminFrontendFr.registrationPeriod,
                            onChangeFrom: Curry._1(form.updateRegistrationDateStart, (function (input, registrationDateStart) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.registrationDateStart = registrationDateStart;
                                    return newrecord;
                                  })),
                            onChangeTo: Curry._1(form.updateRegistrationDateEnd, (function (input, registrationDateEnd) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.registrationDateEnd = registrationDateEnd;
                                    return newrecord;
                                  })),
                            dateFrom: form.input.registrationDateStart,
                            dateTo: form.input.registrationDateEnd,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      item: true,
                      spacing: 2
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                style: {
                                  fontWeight: "400"
                                },
                                children: intl.formatMessage(Messages_Orders_Modal$DvmAdminFrontendFr.registeredDateCheckbox)
                              }),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, {
                                value: form.input.registrationDateExists,
                                options: {
                                  hd: {
                                    label: {
                                      TAG: /* Message */0,
                                      _0: Messages_Common$DvmAdminFrontendFr.yes
                                    },
                                    value: "yes"
                                  },
                                  tl: {
                                    hd: {
                                      label: {
                                        TAG: /* Message */0,
                                        _0: Messages_Common$DvmAdminFrontendFr.no
                                      },
                                      value: "no"
                                    },
                                    tl: {
                                      hd: {
                                        label: {
                                          TAG: /* Message */0,
                                          _0: Messages_Common$DvmAdminFrontendFr.everything
                                        },
                                        value: ""
                                      },
                                      tl: /* [] */0
                                    }
                                  }
                                },
                                onChange: Curry._1(form.updateRegistrationDateExists, (function (input, registrationDateExists) {
                                        var newrecord = Caml_obj.obj_dup(input);
                                        newrecord.registrationDateExists = registrationDateExists;
                                        return newrecord;
                                      })),
                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                              }),
                          item: true
                        }))), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  spacing: 1
                }, React.createElement(Core.Grid, {
                      children: React.createElement(Order_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                            disabled: form.input.customerInvoiceExists === "no",
                            title: Messages_Orders_Modal$DvmAdminFrontendFr.custInvoicePeriod,
                            onChangeFrom: Curry._1(form.updateCustomerInvoiceStart, (function (input, customerInvoiceStart) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.customerInvoiceStart = customerInvoiceStart;
                                    return newrecord;
                                  })),
                            onChangeTo: Curry._1(form.updateCustomerInvoiceEnd, (function (input, customerInvoiceEnd) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.customerInvoiceEnd = customerInvoiceEnd;
                                    return newrecord;
                                  })),
                            dateFrom: form.input.customerInvoiceStart,
                            dateTo: form.input.customerInvoiceEnd,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      item: true,
                      spacing: 2
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                style: {
                                  fontWeight: "400"
                                },
                                children: intl.formatMessage(Messages_Orders_Modal$DvmAdminFrontendFr.invoicedCheckbox)
                              }),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, {
                                value: form.input.customerInvoiceExists,
                                options: {
                                  hd: {
                                    label: {
                                      TAG: /* Message */0,
                                      _0: Messages_Common$DvmAdminFrontendFr.yes
                                    },
                                    value: "yes"
                                  },
                                  tl: {
                                    hd: {
                                      label: {
                                        TAG: /* Message */0,
                                        _0: Messages_Common$DvmAdminFrontendFr.no
                                      },
                                      value: "no"
                                    },
                                    tl: {
                                      hd: {
                                        label: {
                                          TAG: /* Message */0,
                                          _0: Messages_Common$DvmAdminFrontendFr.everything
                                        },
                                        value: ""
                                      },
                                      tl: /* [] */0
                                    }
                                  }
                                },
                                onChange: Curry._1(form.updateCustomerInvoiceExists, (function (input, customerInvoiceExists) {
                                        var newrecord = Caml_obj.obj_dup(input);
                                        newrecord.customerInvoiceExists = customerInvoiceExists;
                                        return newrecord;
                                      })),
                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                              }),
                          item: true
                        }))), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  spacing: 1
                }, React.createElement(Core.Grid, {
                      children: React.createElement(Order_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                            disabled: !(Belt_List.every(orderType, (function (x) {
                                      switch (x) {
                                        case "RC" :
                                        case "RR" :
                                        case "VE" :
                                            return true;
                                        default:
                                          return false;
                                      }
                                    })) || Belt_List.length(orderType) === 0),
                            title: Messages_Orders_Detail$DvmAdminFrontendFr.entryDateParc,
                            onChangeFrom: Curry._1(form.updateEntryDateParcStart, (function (input, entryDateParcStart) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.entryDateParcStart = entryDateParcStart;
                                    return newrecord;
                                  })),
                            onChangeTo: Curry._1(form.updateEntryDateParcEnd, (function (input, entryDateParcEnd) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.entryDateParcEnd = entryDateParcEnd;
                                    return newrecord;
                                  })),
                            dateFrom: form.input.entryDateParcStart,
                            dateTo: form.input.entryDateParcEnd,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      item: true,
                      spacing: 2
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                style: {
                                  fontWeight: "400"
                                },
                                children: intl.formatMessage(Messages_Bonuses_List$DvmAdminFrontendFr.parcActiveCheckbox)
                              }),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, {
                                value: form.input.parcActive,
                                options: {
                                  hd: {
                                    label: {
                                      TAG: /* Message */0,
                                      _0: Messages_Common$DvmAdminFrontendFr.yes
                                    },
                                    value: "yes"
                                  },
                                  tl: {
                                    hd: {
                                      label: {
                                        TAG: /* Message */0,
                                        _0: Messages_Common$DvmAdminFrontendFr.no
                                      },
                                      value: "no"
                                    },
                                    tl: {
                                      hd: {
                                        label: {
                                          TAG: /* Message */0,
                                          _0: Messages_Common$DvmAdminFrontendFr.everything
                                        },
                                        value: ""
                                      },
                                      tl: /* [] */0
                                    }
                                  }
                                },
                                onChange: Curry._1(form.updateParcActive, (function (input, parcActive) {
                                        var newrecord = Caml_obj.obj_dup(input);
                                        newrecord.parcActive = parcActive;
                                        return newrecord;
                                      })),
                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                                disabled: !(Belt_List.every(orderType, (function (x) {
                                          switch (x) {
                                            case "RC" :
                                            case "RR" :
                                            case "VE" :
                                                return true;
                                            default:
                                              return false;
                                          }
                                        })) || Belt_List.length(orderType) === 0)
                              }),
                          item: true
                        }))), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  spacing: 1
                }, React.createElement(Core.Grid, {
                      children: React.createElement(Order_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                            disabled: form.input.parcActive === "yes" || !(Belt_List.every(orderType, (function (x) {
                                      switch (x) {
                                        case "RC" :
                                        case "RR" :
                                        case "VE" :
                                            return true;
                                        default:
                                          return false;
                                      }
                                    })) || Belt_List.length(orderType) === 0),
                            title: Messages_Orders_Detail$DvmAdminFrontendFr.exitDateParc,
                            onChangeFrom: Curry._1(form.updateExitDateParcStart, (function (input, exitDateParcStart) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.exitDateParcStart = exitDateParcStart;
                                    return newrecord;
                                  })),
                            onChangeTo: Curry._1(form.updateExitDateParcEnd, (function (input, exitDateParcEnd) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.exitDateParcEnd = exitDateParcEnd;
                                    return newrecord;
                                  })),
                            dateFrom: form.input.exitDateParcStart,
                            dateTo: form.input.exitDateParcEnd,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      item: true,
                      spacing: 1
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                style: {
                                  fontWeight: "400"
                                },
                                children: intl.formatMessage(Messages_Orders_Modal$DvmAdminFrontendFr.parcAutomatic)
                              }),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, {
                                value: form.input.parcAutomatic,
                                options: {
                                  hd: {
                                    label: {
                                      TAG: /* Message */0,
                                      _0: Messages_Common$DvmAdminFrontendFr.yes
                                    },
                                    value: "TRUE"
                                  },
                                  tl: {
                                    hd: {
                                      label: {
                                        TAG: /* Message */0,
                                        _0: Messages_Common$DvmAdminFrontendFr.no
                                      },
                                      value: "FALSE"
                                    },
                                    tl: {
                                      hd: {
                                        label: {
                                          TAG: /* Message */0,
                                          _0: Messages_Common$DvmAdminFrontendFr.everything
                                        },
                                        value: ""
                                      },
                                      tl: /* [] */0
                                    }
                                  }
                                },
                                onChange: Curry._1(form.updateParcAutomatic, (function (input, parcAutomatic) {
                                        var newrecord = Caml_obj.obj_dup(input);
                                        newrecord.parcAutomatic = parcAutomatic;
                                        return newrecord;
                                      })),
                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                                disabled: !(Belt_List.every(orderType, (function (x) {
                                          if (x === "VE") {
                                            return true;
                                          } else {
                                            return false;
                                          }
                                        })) || Belt_List.length(orderType) === 0)
                              }),
                          item: true
                        }))), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  spacing: 1
                }, React.createElement(Core.Grid, {
                      children: React.createElement(Order_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                            disabled: form.input.renaultInvoiceExists === "no",
                            title: Messages_Orders_Modal$DvmAdminFrontendFr.renaultInvoiceDate,
                            onChangeFrom: Curry._1(form.updateRenaultInvoiceDateStart, (function (input, renaultInvoiceDateStart) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.renaultInvoiceDateStart = renaultInvoiceDateStart;
                                    return newrecord;
                                  })),
                            onChangeTo: Curry._1(form.updateRenaultInvoiceDateEnd, (function (input, renaultInvoiceDateEnd) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.renaultInvoiceDateEnd = renaultInvoiceDateEnd;
                                    return newrecord;
                                  })),
                            dateFrom: form.input.renaultInvoiceDateStart,
                            dateTo: form.input.renaultInvoiceDateEnd,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      item: true,
                      spacing: 2
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                style: {
                                  fontWeight: "400"
                                },
                                children: intl.formatMessage(Messages_Orders_Modal$DvmAdminFrontendFr.invoicedCheckbox)
                              }),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, {
                                value: form.input.renaultInvoiceExists,
                                options: {
                                  hd: {
                                    label: {
                                      TAG: /* Message */0,
                                      _0: Messages_Common$DvmAdminFrontendFr.yes
                                    },
                                    value: "yes"
                                  },
                                  tl: {
                                    hd: {
                                      label: {
                                        TAG: /* Message */0,
                                        _0: Messages_Common$DvmAdminFrontendFr.no
                                      },
                                      value: "no"
                                    },
                                    tl: {
                                      hd: {
                                        label: {
                                          TAG: /* Message */0,
                                          _0: Messages_Common$DvmAdminFrontendFr.everything
                                        },
                                        value: ""
                                      },
                                      tl: /* [] */0
                                    }
                                  }
                                },
                                onChange: Curry._1(form.updateRenaultInvoiceExists, (function (input, renaultInvoiceExists) {
                                        var newrecord = Caml_obj.obj_dup(input);
                                        newrecord.renaultInvoiceExists = renaultInvoiceExists;
                                        return newrecord;
                                      })),
                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                              }),
                          item: true
                        }))));
}

var InputStyles;

var make = Order_Search_Modal_Date_Filters;

export {
  InputStyles ,
  make ,
}
/* react Not a pure module */
