// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../api/RestApi.bs.js";
import * as User_Types$DvmAdminFrontendFr from "../context/user/User_Types.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../common/utils/Utils_Alert.bs.js";
import * as App_Types_Json$DvmAdminFrontendFr from "../common/types/App_Types_Json.bs.js";
import * as Messages_Error$DvmAdminFrontendFr from "../intl/messages/Messages_Error.bs.js";

function fetchProfile(userManager, selectedRole, setIsVaricentProfile, dispatchUser, alert) {
  Curry._1(dispatchUser, {
        TAG: /* StoreUserProfile */2,
        _0: /* Pending */1
      });
  RestApi$DvmAdminFrontendFr.call("GET", User_Types$DvmAdminFrontendFr.profile_decode, undefined, userManager, selectedRole, "/profile").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(setIsVaricentProfile, (function (param) {
                  return true;
                }));
          return Curry._1(dispatchUser, {
                      TAG: /* StoreUserProfile */2,
                      _0: {
                        TAG: /* Ok */0,
                        _0: result._0
                      }
                    });
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(dispatchUser, {
              TAG: /* StoreUserProfile */2,
              _0: {
                TAG: /* Error */1,
                _0: error
              }
            });
      });
}

function toString(status) {
  switch (status) {
    case /* OK */0 :
        return "OK";
    case /* MIGRATRION_IN_PROCESS */1 :
        return "MIGRATRION_IN_PROCESS";
    case /* UNKNOWN */2 :
        return "UNKNOWN";
    
  }
}

function fromString(status) {
  switch (status) {
    case "MIGRATRION_IN_PROCESS" :
        return /* MIGRATRION_IN_PROCESS */1;
    case "OK" :
        return /* OK */0;
    case "UNKNOWN" :
        return /* UNKNOWN */2;
    default:
      return /* UNKNOWN */2;
  }
}

var Decco$1 = App_Types_Json$DvmAdminFrontendFr.MakeStringableJsonType({
      toString: toString,
      fromString: fromString
    });

var VaricentState = {
  toString: toString,
  fromString: fromString,
  Decco: Decco$1
};

function varicentStatus_encode(v) {
  return Js_dict.fromArray([[
                "response",
                Curry._1(Decco$1.t_encode, v.response)
              ]]);
}

function varicentStatus_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var response = Curry._1(Decco$1.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict._0, "response"), null));
  if (response.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              response: response._0
            }
          };
  }
  var e = response._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".response" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function fetchVaricentState(userManager, selectedRole, setVaricentState, intl, alert) {
  RestApi$DvmAdminFrontendFr.call("GET", varicentStatus_decode, undefined, userManager, selectedRole, "/varicent/status").then(function (result) {
        if (result.TAG !== /* OkResult */0) {
          return Utils_Alert$DvmAdminFrontendFr.info(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                          message: {
                            TAG: /* String */3,
                            _0: intl.formatMessage(Messages_Error$DvmAdminFrontendFr.loginNotPossibleTryLater)
                          }
                        }));
        }
        var varicentState = result._0;
        Curry._1(setVaricentState, (function (param) {
                return varicentState.response;
              }));
      });
}

export {
  fetchProfile ,
  VaricentState ,
  varicentStatus_encode ,
  varicentStatus_decode ,
  fetchVaricentState ,
}
/* Decco Not a pure module */
