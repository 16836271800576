// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Assets$DvmAdminFrontendFr from "../../../../assets/Assets.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../theme/Theme_Colors.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as Flash_Detail_Styles$DvmAdminFrontendFr from "./Flash_Detail_Styles.bs.js";
import * as Flash_Detail_Info_Row$DvmAdminFrontendFr from "./Flash_Detail_Info_Row.bs.js";
import * as Messages_Flash_Detail$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Detail.bs.js";

function Flash_Detail_Conditions(Props) {
  var flash = Props.flash;
  var flashType = Props.flashType;
  var intl = ReactIntl.useIntl();
  var showConditions = function (conditions) {
    if (Belt_Option.mapWithDefault(conditions.brands, false, (function (x) {
              return Belt_Option.isSome(x.ie) ? true : Belt_Option.mapWithDefault(x.brands, false, (function (brandsArray) {
                              return brandsArray.length !== 0;
                            }));
            })) || Belt_Option.mapWithDefault(conditions.carAge.atOrder, false, (function (val) {
              return val > 0;
            })) || Belt_Option.mapWithDefault(conditions.carAge.atDelivery, false, (function (val) {
              return val > 0;
            })) || Belt_Option.mapWithDefault(conditions.carOwnershipAge.atOrder, false, (function (val) {
              return val > 0;
            }))) {
      return true;
    } else {
      return Belt_Option.mapWithDefault(conditions.carOwnershipAge.atDelivery, false, (function (val) {
                    return val > 0;
                  }));
    }
  };
  var conditions = flash.conditions;
  var tmp;
  if (conditions !== undefined && showConditions(conditions)) {
    var brands = conditions.brands;
    tmp = React.createElement(React.Fragment, undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
              color: "primary",
              children: intl.formatMessage(Messages_Flash_Detail$DvmAdminFrontendFr.conditionsTitle),
              variant: "h3"
            }), React.createElement(Core.Grid, {
              children: null,
              className: Flash_Detail_Styles$DvmAdminFrontendFr.detailBox,
              container: true,
              direction: "column"
            }, React.createElement(Flash_Detail_Info_Row$DvmAdminFrontendFr.make, {
                  label: Messages_Flash_Detail$DvmAdminFrontendFr.carAge,
                  values: [
                    {
                      label: Messages_Flash_Detail$DvmAdminFrontendFr.atOrder,
                      value: Belt_Option.mapWithDefault(conditions.carAge.atOrder, "-", (function (x) {
                              if (x > 0) {
                                return String(x);
                              } else {
                                return "-";
                              }
                            }))
                    },
                    {
                      label: Messages_Flash_Detail$DvmAdminFrontendFr.atDelivery,
                      value: Belt_Option.mapWithDefault(conditions.carAge.atDelivery, "-", (function (x) {
                              if (x > 0) {
                                return String(x);
                              } else {
                                return "-";
                              }
                            }))
                    }
                  ]
                }), React.createElement(Flash_Detail_Info_Row$DvmAdminFrontendFr.make, {
                  label: Messages_Flash_Detail$DvmAdminFrontendFr.carOwnershipAge,
                  values: [
                    {
                      label: Messages_Flash_Detail$DvmAdminFrontendFr.atOrder,
                      value: Belt_Option.mapWithDefault(conditions.carOwnershipAge.atOrder, "-", (function (x) {
                              if (x > 0) {
                                return String(x);
                              } else {
                                return "-";
                              }
                            }))
                    },
                    {
                      label: Messages_Flash_Detail$DvmAdminFrontendFr.atDelivery,
                      value: Belt_Option.mapWithDefault(conditions.carOwnershipAge.atDelivery, "-", (function (x) {
                              if (x > 0) {
                                return String(x);
                              } else {
                                return "-";
                              }
                            }))
                    }
                  ]
                })), brands !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                    alignItems: "center",
                    children: null,
                    className: Flash_Detail_Styles$DvmAdminFrontendFr.eligibleCarBox,
                    container: true,
                    spacing: 1
                  }, React.createElement(Core.Grid, {
                        children: React.createElement(Assets$DvmAdminFrontendFr.VO_Icon.make, {}),
                        item: true
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                              style: {
                                fontWeight: "600"
                              },
                              children: intl.formatMessage(Messages_Flash_Detail$DvmAdminFrontendFr.eligibleNonFleetVO),
                              variant: "bodyBig"
                            }),
                        item: true
                      })), Belt_Option.mapWithDefault(brands.ie, null, (function (inclusion) {
                      var tmp;
                      switch (inclusion) {
                        case /* ALL */0 :
                            tmp = Messages_Flash_Detail$DvmAdminFrontendFr.allBrandsIncluded;
                            break;
                        case /* INCLUSION */1 :
                            tmp = Messages_Flash_Detail$DvmAdminFrontendFr.includedBrands;
                            break;
                        case /* EXCLUSION */2 :
                            tmp = Messages_Flash_Detail$DvmAdminFrontendFr.excludedBrands;
                            break;
                        
                      }
                      var tmp$1 = {
                        children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                              style: {
                                fontWeight: "600"
                              },
                              children: intl.formatMessage(tmp),
                              variant: "bodyBig"
                            }),
                        item: true
                      };
                      var tmp$2 = inclusion === /* ALL */0 ? undefined : Caml_option.some(Grid$Mui.Md[2]);
                      if (tmp$2 !== undefined) {
                        tmp$1.md = Caml_option.valFromOption(tmp$2);
                      }
                      var tmp$3 = inclusion === /* ALL */0 ? undefined : Caml_option.some(Grid$Mui.Sm[4]);
                      if (tmp$3 !== undefined) {
                        tmp$1.sm = Caml_option.valFromOption(tmp$3);
                      }
                      var tmp$4;
                      if (flashType && inclusion === /* INCLUSION */1) {
                        var models = Belt_Option.mapWithDefault(flash.usedCarModels, {
                              hd: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.everything),
                              tl: /* [] */0
                            }, (function (modelsList) {
                                if (Belt_List.length(modelsList) > 0) {
                                  return modelsList;
                                } else {
                                  return {
                                          hd: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.everything),
                                          tl: /* [] */0
                                        };
                                }
                              }));
                        tmp$4 = React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              direction: "row",
                              item: true,
                              spacing: 2,
                              style: {
                                borderTop: "1px solid " + Theme_Colors$DvmAdminFrontendFr.primaryBrown + ""
                              }
                            }, React.createElement(Core.Grid, {
                                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                        style: {
                                          fontWeight: "600"
                                        },
                                        children: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.models),
                                        variant: "bodyBig"
                                      }),
                                  item: true,
                                  md: Grid$Mui.Md[2],
                                  sm: Grid$Mui.Sm[4]
                                }), React.createElement(Core.Grid, {
                                  alignItems: "center",
                                  children: Belt_Array.map(Belt_List.toArray(models), (function (row) {
                                          return React.createElement(Core.Grid, {
                                                      children: React.createElement(Core.Chip, {
                                                            label: row,
                                                            size: "small",
                                                            style: {
                                                              backgroundColor: "#4b9bf5",
                                                              color: "white"
                                                            }
                                                          }),
                                                      item: true
                                                    });
                                        })),
                                  container: true,
                                  direction: "row",
                                  item: true,
                                  spacing: 2,
                                  xs: Grid$Mui.Xs.$$true
                                }));
                      } else {
                        tmp$4 = null;
                      }
                      return React.createElement(Core.Box, {
                                  children: React.createElement(Core.Grid, {
                                        children: null,
                                        container: true,
                                        direction: "column",
                                        item: true,
                                        spacing: 4
                                      }, React.createElement(Core.Grid, {
                                            children: null,
                                            container: true,
                                            direction: "row",
                                            item: true,
                                            spacing: 2
                                          }, React.createElement(Core.Grid, tmp$1), inclusion === /* ALL */0 ? null : React.createElement(Core.Grid, {
                                                  alignItems: "center",
                                                  children: Belt_Array.map(Belt_Option.getWithDefault(brands.brands, []), (function (row) {
                                                          var tmp;
                                                          switch (inclusion) {
                                                            case /* ALL */0 :
                                                                tmp = "#white";
                                                                break;
                                                            case /* INCLUSION */1 :
                                                                tmp = "#4b9bf5";
                                                                break;
                                                            case /* EXCLUSION */2 :
                                                                tmp = "#cb4b32";
                                                                break;
                                                            
                                                          }
                                                          return React.createElement(Core.Grid, {
                                                                      children: React.createElement(Core.Chip, {
                                                                            label: Belt_Option.getWithDefault(row, ""),
                                                                            size: "small",
                                                                            style: {
                                                                              backgroundColor: tmp,
                                                                              color: "white"
                                                                            }
                                                                          }),
                                                                      item: true
                                                                    });
                                                        })),
                                                  container: true,
                                                  direction: "row",
                                                  item: true,
                                                  spacing: 2,
                                                  xs: Grid$Mui.Xs.$$true
                                                })), tmp$4),
                                  padding: 3
                                });
                    }))) : null);
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, tmp);
}

var make = Flash_Detail_Conditions;

export {
  make ,
}
/* react Not a pure module */
