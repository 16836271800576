// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReDate_utils from "@mobily/rescript-date/src/ReDate_utils.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Error_Inputs.bs.js";

function removeFromTakenVersions(takenVersionsList, category) {
  return Belt_List.keep(takenVersionsList, (function (x) {
                var version = Belt_List.getBy(category.versions, (function (y) {
                        return y.version === x.version;
                      }));
                if (version === undefined) {
                  return true;
                }
                var startDate = ReDate_utils.makeStartOfDayDate(Belt_Option.getWithDefault(version.startDate, Belt_Option.getWithDefault(category.startDate, new Date(0.0))));
                var endDate = ReDate_utils.makeStartOfDayDate(Belt_Option.getWithDefault(version.endDate, Belt_Option.getWithDefault(category.endDate, new Date(0.0))));
                if (Belt_Option.mapWithDefault(x.startDate, false, (function (x) {
                          var start = ReDate_utils.makeStartOfDayDate(x);
                          return !ReDate.isEqual(start, startDate);
                        }))) {
                  return Belt_Option.mapWithDefault(x.endDate, false, (function (x) {
                                var end = ReDate_utils.makeStartOfDayDate(x);
                                return !ReDate.isEqual(end, endDate);
                              }));
                } else {
                  return false;
                }
              }));
}

function compareToTakenVersions(result, versionID, takenVersionsList, thisDate, otherDate, categoryStartDate, categoryEndDate) {
  if (result.TAG !== /* Ok */0) {
    return {
            TAG: /* Error */1,
            _0: result._0
          };
  }
  var date = result._0;
  var match = thisDate === "smaller" ? [
      ReDate_utils.makeStartOfDayDate(Belt_Option.getWithDefault(date, Belt_Option.getWithDefault(categoryStartDate, new Date(0.0)))),
      ReDate_utils.makeStartOfDayDate(Belt_Option.getWithDefault(otherDate, Belt_Option.getWithDefault(categoryEndDate, new Date(0.0))))
    ] : [
      ReDate_utils.makeStartOfDayDate(Belt_Option.getWithDefault(otherDate, Belt_Option.getWithDefault(categoryStartDate, new Date(0.0)))),
      ReDate_utils.makeStartOfDayDate(Belt_Option.getWithDefault(date, Belt_Option.getWithDefault(categoryEndDate, new Date(0.0))))
    ];
  var endDate = match[1];
  var startDate = match[0];
  if (Belt_List.length(Belt_List.keep(takenVersionsList, (function (x) {
                return x.version === versionID ? (
                          Belt_Option.mapWithDefault(x.startDate, false, (function (x) {
                                  var start = ReDate_utils.makeStartOfDayDate(x);
                                  return ReDate.isBefore(start, startDate) ? true : ReDate.isEqual(start, startDate);
                                })) && Belt_Option.mapWithDefault(x.endDate, false, (function (x) {
                                  var end = ReDate_utils.makeStartOfDayDate(x);
                                  return ReDate.isAfter(end, endDate) ? true : ReDate.isEqual(end, endDate);
                                })) || Belt_Option.mapWithDefault(x.startDate, false, (function (x) {
                                  var start = ReDate_utils.makeStartOfDayDate(x);
                                  return ReDate.isAfter(start, startDate) ? true : ReDate.isEqual(start, startDate);
                                })) && Belt_Option.mapWithDefault(x.endDate, false, (function (x) {
                                  var end = ReDate_utils.makeStartOfDayDate(x);
                                  return ReDate.isBefore(end, endDate) ? true : ReDate.isEqual(end, endDate);
                                })) || Belt_Option.mapWithDefault(x.startDate, false, (function (x) {
                                  var start = ReDate_utils.makeStartOfDayDate(x);
                                  return ReDate.isBefore(start, startDate) ? true : ReDate.isEqual(start, startDate);
                                })) && Belt_Option.mapWithDefault(x.endDate, false, (function (x) {
                                  var end = ReDate_utils.makeStartOfDayDate(x);
                                  return ReDate.isAfter(end, startDate) ? true : ReDate.isEqual(end, startDate);
                                })) ? true : (
                              Belt_Option.mapWithDefault(x.startDate, false, (function (x) {
                                      var start = ReDate_utils.makeStartOfDayDate(x);
                                      return ReDate.isBefore(start, endDate) ? true : ReDate.isEqual(start, endDate);
                                    })) ? Belt_Option.mapWithDefault(x.endDate, false, (function (x) {
                                        var end = ReDate_utils.makeStartOfDayDate(x);
                                        return ReDate.isAfter(end, endDate) ? true : ReDate.isEqual(end, endDate);
                                      })) : false
                            )
                        ) : false;
              }))) > 0) {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateOutOfPeriod
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: date
          };
  }
}

export {
  removeFromTakenVersions ,
  compareToTakenVersions ,
}
/* No side effect */
