// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as Flash_Edit_NonFleet_Types$DvmAdminFrontendFr from "./steps/NonFleet/Flash_Edit_NonFleet_Types.bs.js";

function submitFleet(output, outputVOForm, outputGeneralDates, actionType) {
  var hasVO = actionType === /* REPR */2;
  var hasMADAMADC = actionType === /* STOC */0;
  var tmp;
  if (hasMADAMADC) {
    var match = output.stocDateType;
    tmp = match >= 2 ? undefined : output.stocDateStart;
  } else {
    tmp = undefined;
  }
  var tmp$1;
  if (hasMADAMADC) {
    var match$1 = output.stocDateType;
    tmp$1 = match$1 >= 2 ? undefined : output.stocDateEnd;
  } else {
    tmp$1 = undefined;
  }
  var tmp$2;
  if (hasMADAMADC) {
    var match$2 = output.stocDateType;
    tmp$2 = match$2 >= 2 ? undefined : output.stocDateType;
  } else {
    tmp$2 = undefined;
  }
  return {
          actionId: output.actionID,
          actionLabel: output.actionLabel,
          actionType: output.actionType,
          budgetType: output.orderType === /* SO */0 ? output.budgetType : undefined,
          orderType: output.orderType,
          orderStartDate: outputGeneralDates.startDate,
          orderEndDate: outputGeneralDates.endDate,
          deliveryStartDate: outputGeneralDates.deliveryStartDate,
          deliveryEndDate: outputGeneralDates.deliveryEndDate,
          deliveryDateRequired: Belt_Option.isSome(outputGeneralDates.deliveryStartDate) && Belt_Option.isSome(outputGeneralDates.deliveryEndDate),
          registrationStartDate: outputGeneralDates.registrationStartDate,
          registrationEndDate: outputGeneralDates.registrationEndDate,
          registrationDateRequired: Belt_Option.isSome(outputGeneralDates.registrationStartDate) && Belt_Option.isSome(outputGeneralDates.registrationEndDate),
          stocDateStart: tmp,
          stocDateEnd: tmp$1,
          stocDateType: tmp$2,
          amount: actionType === /* PERF */1 ? undefined : ({
                value: Belt_Float.fromString(output.value),
                numberType: output.numberType
              }),
          carAgeAtOrderDate: hasVO ? (
              outputVOForm.carAgeWhich === "order" ? outputVOForm.carAge : 0
            ) : undefined,
          carAgeAtDeliveryDate: hasVO ? (
              outputVOForm.carAgeWhich === "delivery" ? outputVOForm.carAge : 0
            ) : undefined,
          possesionPeriodAtOrderDate: hasVO ? (
              outputVOForm.possesionPeriodWhich === "order" ? outputVOForm.possesionPeriod : 0
            ) : undefined,
          possesionPeriodAtDeliveryDate: hasVO ? (
              outputVOForm.possesionPeriodWhich === "delivery" ? outputVOForm.possesionPeriod : 0
            ) : undefined,
          brands: hasVO ? ({
                brands: Belt_List.length(outputVOForm.brands) === 0 ? undefined : outputVOForm.brands,
                ie: outputVOForm.ie
              }) : undefined,
          perfType: actionType === /* PERF */1 ? output.perfType : undefined,
          carAgeAtOrderSelected: hasVO ? outputVOForm.carAgeWhich === "order" && outputVOForm.actionWithVO : undefined,
          carAgeAtDeliverySelected: hasVO ? outputVOForm.carAgeWhich === "delivery" && outputVOForm.actionWithVO : undefined,
          possessionPeriodAtOrderSelected: hasVO ? outputVOForm.possesionPeriodWhich === "order" && outputVOForm.actionWithVO : undefined,
          possessionPeriodAtDeliverySelected: hasVO ? outputVOForm.possesionPeriodWhich === "delivery" && outputVOForm.actionWithVO : undefined,
          financialCodeOrientation: output.includeFinancialCode ? output.financialCodeOrientation : undefined,
          financialCodes: output.includeFinancialCode ? output.financialCodes : undefined,
          vehicleType: undefined,
          usedCarModels: typeof actionType === "number" || outputVOForm.ie !== /* INCLUSION */1 ? undefined : outputVOForm.usedCarModels,
          withUsedCar: undefined,
          entryParcDatePeriodStart: undefined,
          entryParcDatePeriodEnd: undefined,
          exitParcDatePeriodStart: undefined,
          exitParcDatePeriodEnd: undefined,
          detentionMin: undefined,
          detentionMax: undefined,
          priceType: undefined,
          isPARCExit: undefined,
          dealerIds: undefined,
          dealerWithAgents: undefined,
          requestType: undefined,
          clientTypes: undefined,
          saleTypes: undefined
        };
}

function dealerAgentsToParentChild(dealerWithAgents) {
  var splitRows = Belt_List.map(dealerWithAgents, (function (x) {
          return x.split(":::");
        }));
  var dealers = Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.keepMap(Belt_List.map(splitRows, (function (x) {
                          return Belt_Array.get(x, 0);
                        })), (function (x) {
                      return x;
                    })))));
  var agentsForDealer = function (dealer) {
    return Belt_List.keepMap(Belt_List.keep(splitRows, (function (row) {
                      return Belt_Option.getWithDefault(Belt_Array.get(row, 0), "") === dealer;
                    })), (function (x) {
                  return Belt_Array.get(x, 1);
                }));
  };
  return Belt_List.map(dealers, (function (x) {
                return {
                        parent: x,
                        child: agentsForDealer(x)
                      };
              }));
}

function submitNonFleet(output, outputAdditional, outputGeneralDates, outputVOForm, formNonFleetNewVehicleOutput, selectedRole) {
  var hasVO = outputVOForm.actionWithVO && output.orderType !== /* VE */2;
  var hasMADAMADC = output.orderType !== /* VE */2;
  var tmp;
  if (hasMADAMADC) {
    var match = output.stocDateType;
    tmp = match >= 2 ? undefined : output.stocDateStart;
  } else {
    tmp = undefined;
  }
  var tmp$1;
  if (hasMADAMADC) {
    var match$1 = output.stocDateType;
    tmp$1 = match$1 >= 2 ? undefined : output.stocDateEnd;
  } else {
    tmp$1 = undefined;
  }
  var tmp$2;
  if (hasMADAMADC) {
    var match$2 = output.stocDateType;
    tmp$2 = match$2 >= 2 ? undefined : output.stocDateType;
  } else {
    tmp$2 = undefined;
  }
  return {
          actionId: output.actionID,
          actionLabel: output.actionLabel,
          actionType: output.actionType,
          budgetType: undefined,
          orderType: output.orderType,
          orderStartDate: output.orderType === /* VE */2 ? (
              Belt_Option.getWithDefault(output.isPARCExit, false) ? output.exitParcDatePeriodStart : output.entryParcDatePeriodStart
            ) : outputGeneralDates.startDate,
          orderEndDate: output.orderType === /* VE */2 ? (
              Belt_Option.getWithDefault(output.isPARCExit, false) ? output.exitParcDatePeriodEnd : output.entryParcDatePeriodEnd
            ) : outputGeneralDates.endDate,
          deliveryStartDate: output.orderType === /* VE */2 ? undefined : outputGeneralDates.deliveryStartDate,
          deliveryEndDate: output.orderType === /* VE */2 ? undefined : outputGeneralDates.deliveryEndDate,
          deliveryDateRequired: output.orderType === /* VE */2 ? false : Belt_Option.isSome(outputGeneralDates.deliveryStartDate) && Belt_Option.isSome(outputGeneralDates.deliveryEndDate),
          registrationStartDate: output.orderType === /* VE */2 ? undefined : outputGeneralDates.registrationStartDate,
          registrationEndDate: output.orderType === /* VE */2 ? undefined : outputGeneralDates.registrationEndDate,
          registrationDateRequired: output.orderType === /* VE */2 ? false : Belt_Option.isSome(outputGeneralDates.registrationStartDate) && Belt_Option.isSome(outputGeneralDates.registrationEndDate),
          stocDateStart: tmp,
          stocDateEnd: tmp$1,
          stocDateType: tmp$2,
          amount: {
            value: Belt_Float.fromString(output.value),
            numberType: output.numberType
          },
          carAgeAtOrderDate: hasVO ? (
              outputVOForm.carAgeWhich === "order" ? outputVOForm.carAge : 0
            ) : undefined,
          carAgeAtDeliveryDate: hasVO ? (
              outputVOForm.carAgeWhich === "delivery" ? outputVOForm.carAge : 0
            ) : undefined,
          possesionPeriodAtOrderDate: hasVO ? (
              outputVOForm.possesionPeriodWhich === "order" ? outputVOForm.possesionPeriod : 0
            ) : undefined,
          possesionPeriodAtDeliveryDate: hasVO ? (
              outputVOForm.possesionPeriodWhich === "delivery" ? outputVOForm.possesionPeriod : 0
            ) : undefined,
          brands: hasVO ? ({
                brands: Belt_List.length(outputVOForm.brands) === 0 ? undefined : outputVOForm.brands,
                ie: outputVOForm.ie
              }) : undefined,
          perfType: undefined,
          carAgeAtOrderSelected: hasVO ? outputVOForm.carAgeWhich === "order" && outputVOForm.actionWithVO : undefined,
          carAgeAtDeliverySelected: hasVO ? outputVOForm.carAgeWhich === "delivery" && outputVOForm.actionWithVO : undefined,
          possessionPeriodAtOrderSelected: hasVO ? outputVOForm.possesionPeriodWhich === "order" && outputVOForm.actionWithVO : undefined,
          possessionPeriodAtDeliverySelected: hasVO ? outputVOForm.possesionPeriodWhich === "delivery" && outputVOForm.actionWithVO : undefined,
          financialCodeOrientation: output.includeFinancialCode ? output.financialCodeOrientation : undefined,
          financialCodes: output.includeFinancialCode ? output.financialCodes : undefined,
          vehicleType: formNonFleetNewVehicleOutput.vehicleType,
          usedCarModels: hasVO && outputVOForm.ie === /* INCLUSION */1 ? outputVOForm.usedCarModels : undefined,
          withUsedCar: hasVO,
          entryParcDatePeriodStart: output.orderType === /* VE */2 && !Belt_Option.getWithDefault(output.isPARCExit, true) || output.orderType === /* RR */4 || output.orderType === /* RC */5 ? output.entryParcDatePeriodStart : undefined,
          entryParcDatePeriodEnd: output.orderType === /* VE */2 && !Belt_Option.getWithDefault(output.isPARCExit, true) || output.orderType === /* RR */4 || output.orderType === /* RC */5 ? output.entryParcDatePeriodEnd : undefined,
          exitParcDatePeriodStart: output.orderType === /* VE */2 && Belt_Option.getWithDefault(output.isPARCExit, false) ? output.exitParcDatePeriodStart : undefined,
          exitParcDatePeriodEnd: output.orderType === /* VE */2 && Belt_Option.getWithDefault(output.isPARCExit, false) ? output.exitParcDatePeriodEnd : undefined,
          detentionMin: (output.orderType === /* VE */2 || output.orderType === /* RR */4 || output.orderType === /* RC */5) && Belt_Option.getWithDefault(output.isPARCExit, false) ? output.detentionMin : undefined,
          detentionMax: (output.orderType === /* VE */2 || output.orderType === /* RR */4 || output.orderType === /* RC */5) && Belt_Option.getWithDefault(output.isPARCExit, false) ? output.detentionMax : undefined,
          priceType: (output.orderType === /* VE */2 || output.orderType === /* RR */4 || output.orderType === /* RC */5 || output.orderType === /* CL */1 || User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* Spain */1) && output.numberType === /* PERCENT */0 ? (
              Belt_Option.getWithDefault(output.requestType, "") === "" && output.orderType === /* CL */1 ? "PVC_HT" : output.priceType
            ) : undefined,
          isPARCExit: output.orderType === /* VE */2 || output.orderType === /* RR */4 || output.orderType === /* RC */5 ? Belt_Option.getWithDefault(output.isPARCExit, false) : false,
          dealerIds: (output.orderType === /* RR */4 || output.orderType === /* RC */5) && Belt_List.length(output.dealerWithAgents) <= 0 ? output.dealerIds : undefined,
          dealerWithAgents: output.orderType === /* RR */4 || output.orderType === /* RC */5 ? dealerAgentsToParentChild(output.dealerWithAgents) : undefined,
          requestType: output.orderType === /* CL */1 && User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) !== /* Spain */1 ? output.requestType : undefined,
          clientTypes: User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* Spain */1 ? outputAdditional.clientType : undefined,
          saleTypes: User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* Spain */1 ? outputAdditional.saleType : undefined
        };
}

function submitNonFleetNewVehicle(formOutput, formNonFleetNewVehicleOutput, formGeneralDatesOutput, newVehicleResult) {
  var match = formOutput.orderType;
  var tmp;
  switch (match) {
    case /* VE */2 :
        tmp = Belt_Option.getWithDefault(formOutput.isPARCExit, false) ? formOutput.exitParcDatePeriodStart : formOutput.entryParcDatePeriodStart;
        break;
    case /* RR */4 :
    case /* RC */5 :
        tmp = formGeneralDatesOutput.registrationStartDate;
        break;
    case /* SO */0 :
    case /* CL */1 :
    case /* VD */3 :
    case /* NOTSET */6 :
        tmp = formGeneralDatesOutput.startDate;
        break;
    
  }
  var match$1 = formOutput.orderType;
  var tmp$1;
  switch (match$1) {
    case /* VE */2 :
        tmp$1 = Belt_Option.getWithDefault(formOutput.isPARCExit, false) ? formOutput.exitParcDatePeriodEnd : formOutput.entryParcDatePeriodEnd;
        break;
    case /* RR */4 :
    case /* RC */5 :
        tmp$1 = formGeneralDatesOutput.registrationEndDate;
        break;
    case /* SO */0 :
    case /* CL */1 :
    case /* VD */3 :
    case /* NOTSET */6 :
        tmp$1 = formGeneralDatesOutput.endDate;
        break;
    
  }
  return {
          actionStartDate: tmp,
          actionEndDate: tmp$1,
          customBonuses: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(newVehicleResult, undefined, (function (x) {
                  return x.customBonuses;
                })),
          engineTypes: formNonFleetNewVehicleOutput.engineTypes,
          modelPhases: Belt_List.keepMap(Belt_List.map(formNonFleetNewVehicleOutput.modelPhases, (function (x) {
                      var splitArray = x.split(Flash_Edit_NonFleet_Types$DvmAdminFrontendFr.delimiter);
                      var one = Belt_Array.get(splitArray, 0);
                      var two = Belt_Array.get(splitArray, 1);
                      if (one !== undefined && two !== undefined) {
                        return {
                                one: one,
                                two: two
                              };
                      }
                      
                    })), (function (x) {
                  return x;
                })),
          mappingDirection: formNonFleetNewVehicleOutput.mappingDirection,
          modelVersions: Belt_List.map(formNonFleetNewVehicleOutput.versions, (function (x) {
                  return x.value;
                })),
          brandsNewCar: Belt_List.concatMany(Belt_List.toArray(Belt_List.map(formNonFleetNewVehicleOutput.brandsNewCar, (function (x) {
                          return Belt_List.fromArray(x.split("%5C%2C"));
                        }))))
        };
}

var Form;

var FormAdditional;

var FormDates;

var FormVO;

var Types;

var FormNonFleetNewVehicle;

export {
  Form ,
  FormAdditional ,
  FormDates ,
  FormVO ,
  Types ,
  FormNonFleetNewVehicle ,
  submitFleet ,
  dealerAgentsToParentChild ,
  submitNonFleet ,
  submitNonFleetNewVehicle ,
}
/* Flash_Edit_NonFleet_Types-DvmAdminFrontendFr Not a pure module */
