// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as EnvVar$DvmAdminFrontendFr from "../bindings/global/EnvVar.bs.js";
import * as App_Main$DvmAdminFrontendFr from "./App_Main.bs.js";
import * as Constants$DvmAdminFrontendFr from "../common/Constants.bs.js";
import * as User_Action$DvmAdminFrontendFr from "../context/user/User_Action.bs.js";
import * as Utils_Piwik$DvmAdminFrontendFr from "../common/utils/Utils_Piwik.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../context/user/User_Context.bs.js";
import * as App_Initial_Api$DvmAdminFrontendFr from "./App_Initial_Api.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../intl/messages/Messages_Common.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../context/user/User_Types_Utils.bs.js";
import * as Utils_Authentication$DvmAdminFrontendFr from "../common/utils/Utils_Authentication.bs.js";
import * as Auth_Select_Role_Page$DvmAdminFrontendFr from "../pages/auth/selectRole/Auth_Select_Role_Page.bs.js";
import * as Varicent_Migration_Info$DvmAdminFrontendFr from "../pages/varicentMigrationInfo/Varicent_Migration_Info.bs.js";

function App(Props) {
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatchUser = match[1];
  var match$1 = match[0];
  var ipn = match$1.ipn;
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var roles = match$1.roles;
  var authenticated = match$1.authenticated;
  var match$2 = React.useState(function () {
        return false;
      });
  var setIsVaricentProfile = match$2[1];
  var match$3 = React.useState(function () {
        return /* UNKNOWN */2;
      });
  var setVaricentState = match$3[1];
  var varicentState = match$3[0];
  var sessionStorage$1 = sessionStorage;
  React.useEffect((function () {
          Utils_Piwik$DvmAdminFrontendFr.initPiwik(EnvVar$DvmAdminFrontendFr.piwikEnabled)(process.env.REACT_APP_PIWIK_URL, process.env.REACT_APP_PIWIK_SITE_ID);
        }), []);
  React.useEffect((function () {
          var joinUrlParts = function (urlPart1, urlPart2) {
            return "" + urlPart1 + "/" + urlPart2 + "";
          };
          var pushUrlToPiwik = function (param) {
            var visitedUrl = Belt_List.reduce(url.path, "", joinUrlParts);
            Utils_Piwik$DvmAdminFrontendFr.pushVisitedUrl(EnvVar$DvmAdminFrontendFr.piwikEnabled)(visitedUrl, ipn);
          };
          pushUrlToPiwik(undefined);
        }), [
        url.path,
        authenticated,
        ipn
      ]);
  React.useEffect((function () {
          if (authenticated && selectedRole !== /* NoRole */9 && varicentState === /* OK */0) {
            App_Initial_Api$DvmAdminFrontendFr.fetchProfile(userManager, selectedRole, setIsVaricentProfile, dispatchUser, alert);
          }
          
        }), [
        authenticated,
        selectedRole,
        varicentState
      ]);
  React.useEffect((function () {
          if (authenticated && selectedRole !== /* NoRole */9) {
            App_Initial_Api$DvmAdminFrontendFr.fetchVaricentState(userManager, selectedRole, setVaricentState, intl, alert);
          }
          
        }), [
        authenticated,
        selectedRole
      ]);
  React.useEffect((function () {
          if (userManager !== undefined) {
            User_Action$DvmAdminFrontendFr.authenticate(dispatchUser, window.location, Caml_option.valFromOption(userManager));
          } else {
            Curry._1(dispatchUser, {
                  TAG: /* StoreUserManager */1,
                  _0: Utils_Authentication$DvmAdminFrontendFr.createUserManager(undefined)
                });
          }
        }), [
        userManager,
        dispatchUser
      ]);
  React.useEffect((function () {
          if (!Utils_Authentication$DvmAdminFrontendFr.isLocationRedirectedFromIdp(window.location) && authenticated === false) {
            var joinUrlParts = function (urlPart1, urlPart2) {
              return "" + urlPart1 + "/" + urlPart2 + "";
            };
            var visitedUrl = Belt_List.reduce(url.path, "", joinUrlParts);
            sessionStorage$1.setItem(Constants$DvmAdminFrontendFr.redirectUrlSessionStorageKey, visitedUrl);
          }
          if (authenticated) {
            var redirect = sessionStorage$1.getItem(Constants$DvmAdminFrontendFr.redirectUrlSessionStorageKey);
            Belt_Option.mapWithDefault((redirect == null) ? undefined : Caml_option.some(redirect), undefined, RescriptReactRouter.push);
          }
          
        }), [authenticated]);
  React.useEffect((function () {
          var storedRole = sessionStorage$1.getItem(Constants$DvmAdminFrontendFr.dvmeRoleSessionStorageKey);
          var storedRole$1 = (storedRole == null) ? undefined : Caml_option.some(storedRole);
          if (Belt_List.length(Belt_List.keep(roles, User_Types_Utils$DvmAdminFrontendFr.isAdmin)) > 0 && selectedRole === /* NoRole */9 && Belt_Option.isNone(storedRole$1)) {
            sessionStorage$1.setItem(Constants$DvmAdminFrontendFr.dvmeRoleSessionStorageKey, "Administrator");
          }
          if (selectedRole === /* NoRole */9) {
            Belt_Option.mapWithDefault(storedRole$1, undefined, (function (x) {
                    console.log(x);
                    if (Belt_List.has(roles, x, (function (a, b) {
                              return User_Types_Utils$DvmAdminFrontendFr.userRoleToString(a) === b;
                            })) || Belt_List.length(Belt_List.keep(roles, User_Types_Utils$DvmAdminFrontendFr.isAdmin)) > 0) {
                      return Curry._1(dispatchUser, {
                                  TAG: /* StoreRole */3,
                                  _0: User_Types_Utils$DvmAdminFrontendFr.userRoleFromString(x)
                                });
                    }
                    
                  }));
          }
          
        }), [roles]);
  var match$4 = Belt_List.length(roles) > 0;
  if (!authenticated) {
    return React.createElement("div", undefined, intl.formatMessage(Messages_Common$DvmAdminFrontendFr.authentication));
  }
  if (!match$4) {
    return React.createElement("div", undefined, intl.formatMessage(Messages_Common$DvmAdminFrontendFr.noRole));
  }
  if (typeof selectedRole === "number" && selectedRole >= 9) {
    if (Belt_List.length(Belt_List.keep(roles, User_Types_Utils$DvmAdminFrontendFr.isAdmin)) > 0) {
      return null;
    } else {
      return React.createElement(Auth_Select_Role_Page$DvmAdminFrontendFr.make, {});
    }
  }
  switch (varicentState) {
    case /* OK */0 :
        if (match$2[0]) {
          return React.createElement(App_Main$DvmAdminFrontendFr.make, {});
        } else {
          return React.createElement("div", undefined, intl.formatMessage(Messages_Common$DvmAdminFrontendFr.loadVaricentProfile));
        }
    case /* MIGRATRION_IN_PROCESS */1 :
        return React.createElement(Varicent_Migration_Info$DvmAdminFrontendFr.make, {});
    case /* UNKNOWN */2 :
        return React.createElement("div", undefined, intl.formatMessage(Messages_Common$DvmAdminFrontendFr.loadVaricentProfile));
    
  }
}

var Api;

var make = App;

export {
  Api ,
  make ,
}
/* react Not a pure module */
