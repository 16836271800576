// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../../../common/App_Api.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_AutocompleteFormInput.bs.js";

function Quota_View_Modal_Exclusion_Filters(Props) {
  var form = Props.form;
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelVersionOptions = match$2[1];
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchModelVersions(setModelVersionOptions, userManager, selectedRole, undefined, undefined, undefined);
        }), []);
  React.useEffect((function () {
          Curry._1(form.submit, undefined);
        }), [form.input.model]);
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: React.createElement(Core.Grid, {
                        children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.model,
                              value: {
                                TAG: /* Single */0,
                                _0: {
                                  value: form.input.model,
                                  onChange: Curry._1(form.updateModel, (function (input, model) {
                                          return {
                                                  model: model,
                                                  version: input.version
                                                };
                                        }))
                                }
                              },
                              options: {
                                TAG: /* Unlabeled */0,
                                _0: Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$2[0], /* [] */0), (function (item) {
                                        return item.parent;
                                      }))
                              },
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
                            }),
                        item: true
                      }),
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }));
}

var Form;

var make = Quota_View_Modal_Exclusion_Filters;

export {
  Form ,
  make ,
}
/* react Not a pure module */
