// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../api/RestApi.bs.js";
import * as Quota_View_Exclusion_Types$DvmAdminFrontendFr from "./Quota_View_Exclusion_Types.bs.js";

function fetchQuotaDetailExclusionSearch(submissionCallbacks, userManager, setResult, queryParams, tableType, selectedRole, withClosedModels) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Quota_View_Exclusion_Types$DvmAdminFrontendFr.Exclusion.searchResult_decode, undefined, userManager, selectedRole, "/quotas/exclusions/" + (
          tableType === /* Model */0 ? "model" : "modelversion"
        ) + "" + queryParams + "&withClosedModels=" + (
          withClosedModels ? "true" : "false"
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnSuccess, undefined);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function saveExclusions(setResult, userManager, tableType, selectedRole, exclusions) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
          TAG: /* Json */0,
          _0: Quota_View_Exclusion_Types$DvmAdminFrontendFr.Exclusion.exclusionsSaveRequest_encode(exclusions)
        }, userManager, selectedRole, "/quotas/exclusions/" + (
          tableType === /* Model */0 ? "model" : "modelversion"
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

export {
  Form ,
  fetchQuotaDetailExclusionSearch ,
  saveExclusions ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
