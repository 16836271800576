// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../../common/App_Api.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Flash_Edit_Info$DvmAdminFrontendFr from "./Flash_Edit_Info.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Flash_Edit_Accordion$DvmAdminFrontendFr from "./Flash_Edit_Accordion.bs.js";
import * as Flash_Edit_Steps_Utils$DvmAdminFrontendFr from "./Flash_Edit_Steps_Utils.bs.js";
import * as Flash_Edit_Details_PERF$DvmAdminFrontendFr from "./Fleet/Flash_Edit_Details_PERF.bs.js";
import * as Flash_Edit_Details_REPR$DvmAdminFrontendFr from "./Fleet/Flash_Edit_Details_REPR.bs.js";
import * as Flash_Edit_Details_STOC$DvmAdminFrontendFr from "./Fleet/Flash_Edit_Details_STOC.bs.js";
import * as Flash_Edit_Details_NonFleet$DvmAdminFrontendFr from "./NonFleet/Flash_Edit_Details_NonFleet.bs.js";
import * as Flash_Edit_Info_Old_Vehicle$DvmAdminFrontendFr from "./Flash_Edit_Info_Old_Vehicle.bs.js";

function Flash_Edit_Steps(Props) {
  var form = Props.form;
  var formAdditional = Props.formAdditional;
  var formVO = Props.formVO;
  var formGeneralDates = Props.formGeneralDates;
  var formNonFleetNewVehicle = Props.formNonFleetNewVehicle;
  var actionType = Props.actionType;
  var brandsList = Props.brandsList;
  var flashIdOpt = Props.flashId;
  var fetchFlashModelVersionsForCumulables = Props.fetchFlashModelVersionsForCumulables;
  var newVehicleResult = Props.newVehicleResult;
  var setNewVehicleResult = Props.setNewVehicleResult;
  var setDuplicateActionNewVehicle = Props.setDuplicateActionNewVehicle;
  var flash = Props.flash;
  var flashId = flashIdOpt !== undefined ? Caml_option.valFromOption(flashIdOpt) : undefined;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setBudgets = match$2[1];
  var budgets = match$2[0];
  var match$3 = React.useState(function () {
        return 0;
      });
  var setStep = match$3[1];
  var step = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var setInfoFinished = match$4[1];
  var infoFinished = match$4[0];
  var match$5 = React.useState(function () {
        return false;
      });
  var setPerfInfoFinished = match$5[1];
  var perfInfoFinished = match$5[0];
  var match$6 = React.useState(function () {
        return false;
      });
  var setDetailREPRFinished = match$6[1];
  var match$7 = React.useState(function () {
        return false;
      });
  var setDetailNonSOFinished = match$7[1];
  var match$8 = React.useState(function () {
        return false;
      });
  var setDetailRRorRCFinished = match$8[1];
  var match$9 = React.useState(function () {
        return true;
      });
  var setInfoExpanded = match$9[1];
  var match$10 = React.useState(function () {
        return false;
      });
  var setDetailREPRExpanded = match$10[1];
  var detailREPRExpanded = match$10[0];
  var match$11 = React.useState(function () {
        return false;
      });
  var setDetailSTOCExpanded = match$11[1];
  var detailSTOCExpanded = match$11[0];
  var match$12 = React.useState(function () {
        return false;
      });
  var setOldVehicleExpanded = match$12[1];
  var oldVehicleExpanded = match$12[0];
  var match$13 = React.useState(function () {
        return false;
      });
  var match$14 = React.useState(function () {
        return false;
      });
  var setNonFleetDealerExpanded = match$14[1];
  React.useEffect((function () {
          Curry._1(setInfoFinished, (function (param) {
                  return Flash_Edit_Steps_Utils$DvmAdminFrontendFr.isInfoFinished(form);
                }));
          Curry._1(setDetailREPRFinished, (function (param) {
                  return Flash_Edit_Steps_Utils$DvmAdminFrontendFr.isDetailREPRFinished(form, formGeneralDates);
                }));
          Curry._1(setPerfInfoFinished, (function (param) {
                  return Flash_Edit_Steps_Utils$DvmAdminFrontendFr.isPerfInfoFinished(form);
                }));
          Curry._1(setDetailNonSOFinished, (function (param) {
                  return Flash_Edit_Steps_Utils$DvmAdminFrontendFr.isDetailNonSOFinished(form, formGeneralDates);
                }));
          Curry._1(setDetailRRorRCFinished, (function (param) {
                  return Flash_Edit_Steps_Utils$DvmAdminFrontendFr.isDetailRRorRCFinished(form, formGeneralDates);
                }));
        }), [form]);
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchBudgets(userManager, setBudgets, selectedRole);
          if (flashId !== undefined) {
            Curry._1(setStep, (function (param) {
                    return 50;
                  }));
          }
          
        }), []);
  var button = function (param) {
    if (step === 0) {
      return React.createElement(App_Button$DvmAdminFrontendFr.make, {
                  onClick: (function (param) {
                      Curry._1(setStep, (function (step) {
                              return step + 1 | 0;
                            }));
                      if (form.input.orderType === /* RR */4 || form.input.orderType === /* RC */5) {
                        Curry._1(setNonFleetDealerExpanded, (function (param) {
                                return true;
                              }));
                      } else {
                        Curry._1(setDetailREPRExpanded, (function (param) {
                                return true;
                              }));
                      }
                      Curry._1(setDetailSTOCExpanded, (function (param) {
                              return true;
                            }));
                      Curry._1(setInfoExpanded, (function (param) {
                              return false;
                            }));
                    }),
                  label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.next),
                  size: "medium",
                  disabled: !infoFinished || (
                    actionType === /* PERF */1 ? !perfInfoFinished : false
                  )
                });
    } else {
      return null;
    }
  };
  var tmp;
  if (typeof actionType === "number") {
    switch (actionType) {
      case /* STOC */0 :
          tmp = React.createElement(Core.Grid, {
                children: React.createElement(Flash_Edit_Accordion$DvmAdminFrontendFr.make, {
                      title: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.detailsTitle) + " " + form.input.actionLabel,
                      children: React.createElement(Flash_Edit_Details_STOC$DvmAdminFrontendFr.make, {
                            form: form,
                            formGeneralDates: formGeneralDates,
                            budgets: budgets,
                            isStepOpen: detailSTOCExpanded
                          }),
                      expanded: detailSTOCExpanded,
                      setter: setDetailSTOCExpanded,
                      disabled: step < 1
                    }),
                item: true,
                sm: Grid$Mui.Sm[12]
              });
          break;
      case /* PERF */1 :
          tmp = React.createElement(Core.Grid, {
                children: React.createElement(Flash_Edit_Accordion$DvmAdminFrontendFr.make, {
                      title: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.detailsTitle) + " " + form.input.actionLabel,
                      children: React.createElement(Flash_Edit_Details_PERF$DvmAdminFrontendFr.make, {
                            form: form,
                            formGeneralDates: formGeneralDates,
                            budgets: budgets
                          }),
                      expanded: detailSTOCExpanded,
                      setter: setDetailSTOCExpanded,
                      disabled: step < 1
                    }),
                item: true,
                sm: Grid$Mui.Sm[12]
              });
          break;
      case /* REPR */2 :
          tmp = React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                    children: React.createElement(Flash_Edit_Accordion$DvmAdminFrontendFr.make, {
                          title: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.detailsTitle) + " " + form.input.actionLabel,
                          children: React.createElement(Flash_Edit_Details_REPR$DvmAdminFrontendFr.make, {
                                form: form,
                                formGeneralDates: formGeneralDates,
                                children: step === 1 ? React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                        onClick: (function (param) {
                                            Curry._1(setStep, (function (step) {
                                                    return step + 1 | 0;
                                                  }));
                                            Curry._1(setOldVehicleExpanded, (function (param) {
                                                    return true;
                                                  }));
                                            Curry._1(setDetailREPRExpanded, (function (param) {
                                                    return false;
                                                  }));
                                          }),
                                        label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.next),
                                        size: "medium",
                                        disabled: !match$6[0]
                                      }) : null,
                                budgets: budgets,
                                isStepOpen: detailREPRExpanded
                              }),
                          expanded: detailREPRExpanded,
                          setter: setDetailREPRExpanded,
                          disabled: step < 1
                        }),
                    item: true,
                    sm: Grid$Mui.Sm[12]
                  }), step < 1 ? null : React.createElement(Core.Grid, {
                      children: React.createElement(Flash_Edit_Accordion$DvmAdminFrontendFr.make, {
                            title: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.oldVehicleInfoTitle),
                            children: React.createElement(Flash_Edit_Info_Old_Vehicle$DvmAdminFrontendFr.make, {
                                  form: form,
                                  formVO: formVO,
                                  brandsList: brandsList
                                }),
                            expanded: oldVehicleExpanded,
                            setter: setOldVehicleExpanded,
                            disabled: step < 2
                          }),
                      item: true,
                      sm: Grid$Mui.Sm[12]
                    }));
          break;
      case /* NOTSET */3 :
          tmp = null;
          break;
      
    }
  } else {
    tmp = React.createElement(Flash_Edit_Details_NonFleet$DvmAdminFrontendFr.make, {
          form: form,
          formAdditional: formAdditional,
          formVO: formVO,
          formGeneralDates: formGeneralDates,
          formNonFleetNewVehicle: formNonFleetNewVehicle,
          budgets: budgets,
          detailREPRExpanded: detailREPRExpanded,
          setDetailREPRExpanded: setDetailREPRExpanded,
          step: step,
          setStep: setStep,
          detailNonSOFinished: match$7[0],
          detailRRorRCFinished: match$8[0],
          brandsList: brandsList,
          oldVehicleExpanded: oldVehicleExpanded,
          setOldVehicleExpanded: setOldVehicleExpanded,
          newVehicleExpanded: match$13[0],
          setNewVehicleExpanded: match$13[1],
          fetchFlashModelVersionsForCumulables: fetchFlashModelVersionsForCumulables,
          newVehicleResult: newVehicleResult,
          setNewVehicleResult: setNewVehicleResult,
          flashId: flashId,
          flash: flash,
          setDuplicateActionNewVehicle: setDuplicateActionNewVehicle,
          nonFleetDealerExpanded: match$14[0],
          setNonFleetDealerExpanded: setNonFleetDealerExpanded
        });
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: React.createElement(Flash_Edit_Accordion$DvmAdminFrontendFr.make, {
                        title: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.mainInfoTitle),
                        children: React.createElement(Flash_Edit_Info$DvmAdminFrontendFr.make, {
                              form: form,
                              formAdditional: formAdditional,
                              formVO: formVO,
                              flashId: flashId,
                              children: button(undefined)
                            }),
                        expanded: match$9[0],
                        setter: setInfoExpanded
                      }),
                  item: true,
                  sm: Grid$Mui.Sm[12]
                }), tmp, React.createElement(Core.Grid, {
                  children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                        label: flashId !== undefined ? intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.editAction) : intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.createAction),
                        color: "secondary",
                        size: "medium",
                        disabled: flashId !== undefined ? false : (
                            typeof actionType === "number" ? (
                                actionType !== 2 ? (
                                    actionType >= 3 ? false : step < 1
                                  ) : step < 2
                              ) : (
                                formVO.input.actionWithVO ? step < 2 : step < 1
                              )
                          ),
                        type_: "submit"
                      }),
                  container: true,
                  item: true,
                  justifyContent: "center",
                  sm: Grid$Mui.Sm[12]
                }));
}

var Form;

var FormAdditional;

var FormVO;

var FormGeneralDates;

var FormNewVehicle;

var Types;

var make = Flash_Edit_Steps;

export {
  Form ,
  FormAdditional ,
  FormVO ,
  FormGeneralDates ,
  FormNewVehicle ,
  Types ,
  make ,
}
/* react Not a pure module */
