// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../../../api/RestApi.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../../common/utils/Utils_Alert.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Input.bs.js";
import * as Flash_Search_Types$DvmAdminFrontendFr from "../../../../../flash/search/Flash_Search_Types.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Create_Types$DvmAdminFrontendFr from "./Order_Detail_Bonus_DealerRequest_Create_Types.bs.js";

function postFile(userManager, file, selectedRole, orderId, actionId, setResult) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var formData = new FormData();
  formData.append("file", file.document);
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
          TAG: /* FormData */1,
          _0: formData
        }, userManager, selectedRole, "/flash/document/upload?orderId=" + orderId + "&actionId=" + actionId + "&fileName=" + file.document.name + "&fileType=" + file.fileType + "&comment=" + file.comment + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var excelInfo = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: excelInfo
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function deleteFile(userManager, file, selectedRole, orderId, actionId, setResult) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", undefined, userManager, selectedRole, "/flash/document/upload?orderId=" + orderId + "&actionId=" + actionId + "&fileName=" + Belt_Option.getWithDefault(file.fileName, "") + "&fileId=" + file.fileId + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var excelInfo = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: excelInfo
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function deleteRequest(userManager, selectedRole, orderId, actionId, setResult) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", undefined, userManager, selectedRole, "/flash/bonus/request/" + orderId + "/" + actionId + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var excelInfo = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: excelInfo
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function saveOrEditRequest(userManager, setResult, orderId, submissionCallbacks, selectedRole, operation, output) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call(operation ? "POST" : "PUT", {
          TAG: /* Json */0,
          _0: Order_Detail_Bonus_DealerRequest_Create_Types$DvmAdminFrontendFr.requestBody_encode({
                orderId: orderId,
                actionId: output.actionId,
                amount: output.amount,
                comment: output.comment
              })
        }, userManager, selectedRole, "/flash/bonus/request").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          Curry._1(setResult, (function (param) {
                  return {
                          TAG: /* Ok */0,
                          _0: undefined
                        };
                }));
          return ;
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToCreateDerogate */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchActionSelect(alert, userManager, setActionIdOptions, selectedRole, perimeter, orderId) {
  Curry._1(setActionIdOptions, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.twoParamList_decode, undefined, userManager, selectedRole, "/flash/actions/bonus/" + orderId + "/request?requestType=" + perimeter + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var actions = result._0;
          return Curry._1(setActionIdOptions, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: actions
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setActionIdOptions, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchOneAction(userManager, setResult, selectedRole, actionID, orderType) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Search_Types$DvmAdminFrontendFr.searchResult_decode, undefined, userManager, selectedRole, orderType !== 0 ? "/flash/actions/modular?limit=50&offset=0&filter=actionID=" + actionID + "" : "/flash/actions?limit=50&offset=0&filter=actionID=" + actionID + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: Belt_List.get(searchTable.content, 0)
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchDocumentTypes(userManager, setResult, selectedRole, requestType) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Order_Detail_Bonus_DealerRequest_Create_Types$DvmAdminFrontendFr.doccumentFileTypeList_decode, undefined, userManager, selectedRole, "/flash/document/" + requestType + "/types").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var documents = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: documents
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

var Types;

export {
  Form ,
  Types ,
  postFile ,
  deleteFile ,
  deleteRequest ,
  saveOrEditRequest ,
  fetchActionSelect ,
  fetchOneAction ,
  fetchDocumentTypes ,
}
/* react Not a pure module */
