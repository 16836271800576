// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as App_Table$DvmAdminFrontendFr from "../../../common/components/table/App_Table.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../intl/Intl_Utils.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../../route/Route_Paths.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Pagination.bs.js";
import * as App_Table_Export_Menu$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Export_Menu.bs.js";
import * as App_Table_Create_Button$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Create_Button.bs.js";
import * as Messages_Contr_Man_Search$DvmAdminFrontendFr from "../../../intl/messages/contractManagers/Messages_Contr_Man_Search.bs.js";
import * as Contract_Manager_Edit_Router$DvmAdminFrontendFr from "../edit/Contract_Manager_Edit_Router.bs.js";
import * as Contract_Manager_Search_Types$DvmAdminFrontendFr from "./Contract_Manager_Search_Types.bs.js";
import * as Messages_Contract_Manager_Edit$DvmAdminFrontendFr from "../../../intl/messages/contractManagers/Messages_Contract_Manager_Edit.bs.js";

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Contract_Manager_Search_Types$DvmAdminFrontendFr.Fields.managerID, undefined, undefined, undefined, undefined, undefined, Messages_Contr_Man_Search$DvmAdminFrontendFr.managerIPN);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Contract_Manager_Search_Types$DvmAdminFrontendFr.Fields.managerName, undefined, undefined, undefined, undefined, undefined, Messages_Contr_Man_Search$DvmAdminFrontendFr.managerName),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(undefined, undefined, false, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.budget),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Contract_Manager_Search_Types$DvmAdminFrontendFr.Fields.startDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.creationDate),
      tl: {
        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Contract_Manager_Search_Types$DvmAdminFrontendFr.Fields.endDate, undefined, undefined, undefined, undefined, undefined, Messages_Contr_Man_Search$DvmAdminFrontendFr.endingDate),
        tl: /* [] */0
      }
    }
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function Contract_Manager_Search_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var showHistory = Props.showHistory;
  var operation = Props.operation;
  var intl = ReactIntl.useIntl();
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (param) {
                                  var managerID = param.managerID;
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, operation === /* Manage */1 ? Caml_option.some(React.createElement(Core.Button, {
                                                        onClick: (function (param) {
                                                            Contract_Manager_Edit_Router$DvmAdminFrontendFr.routeToPage(managerID, undefined);
                                                          }),
                                                        children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                              size: /* Large */3,
                                                              type_: /* Edit */60
                                                            }),
                                                        key: "edit"
                                                      })) : undefined, undefined, Belt_List.mapWithIndex({
                                                  hd: managerID,
                                                  tl: {
                                                    hd: Belt_Option.getWithDefault(param.managerName, ""),
                                                    tl: {
                                                      hd: Belt_Option.mapWithDefault(param.budgets, "", (function (x) {
                                                              return Belt_Array.joinWith(Belt_List.toArray(x), ", ", (function (queryParam) {
                                                                            return queryParam;
                                                                          }));
                                                            })),
                                                      tl: {
                                                        hd: Belt_Option.mapWithDefault(param.startDate, "", (function (param) {
                                                                return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                              })),
                                                        tl: {
                                                          hd: Belt_Option.mapWithDefault(param.endDate, "", (function (param) {
                                                                  return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                })),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._2(fetchData, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._2(fetchData, undefined, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var tmp = {
    headers: fetchRequest.headers,
    handleSorting: handleSorting,
    tablePage: convertTable(tablePage),
    paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined),
    exportMenu: React.createElement(Core.Grid, {
          children: null,
          container: true,
          item: true,
          justifyContent: "flex-end"
        }, operation === /* Manage */1 ? React.createElement(Core.Grid, {
                children: React.createElement(App_Table_Create_Button$DvmAdminFrontendFr.make, {
                      onClick: (function (param) {
                          RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rContractManagerEdit);
                        }),
                      message: Messages_Contract_Manager_Edit$DvmAdminFrontendFr.mainTitleCreate
                    }),
                item: true
              }) : null, React.createElement(Core.Grid, {
              children: React.createElement(App_Table_Export_Menu$DvmAdminFrontendFr.make, {
                    query: Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                          page: 0,
                          rowsPerPage: 150000,
                          headers: fetchRequest.headers,
                          filterQueryParams: fetchRequest.filterQueryParams,
                          additionalQueryParams: fetchRequest.additionalQueryParams
                        }),
                    endpoint: "contract/managers/" + (
                      showHistory ? "true" : "false"
                    ) + ""
                  }),
              item: true
            }))
  };
  var tmp$1 = operation === /* Manage */1 ? ({
        TAG: /* Left */0,
        _0: /* EmptyCell */0
      }) : undefined;
  if (tmp$1 !== undefined) {
    tmp.rowButton = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, tmp));
}

var Api;

var make = Contract_Manager_Search_Table;

export {
  Api ,
  headersConfiguration ,
  make ,
}
/* headersConfiguration Not a pure module */
