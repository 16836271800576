// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../../common/App_Api.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../../common/components/App_LabeledText.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as Messages_Contract_Create$DvmAdminFrontendFr from "../../../../intl/messages/contract/Messages_Contract_Create.bs.js";
import * as Messages_Contr_Man_Search$DvmAdminFrontendFr from "../../../../intl/messages/contractManagers/Messages_Contr_Man_Search.bs.js";

function Contract_Edit_Main_Info_View(Props) {
  var contract = Props.contract;
  var managerIds = Props.managerIds;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSirenName = match$2[1];
  var match$3 = Belt_Option.mapWithDefault(contract, [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ], (function (contract) {
          return [
                  Belt_Option.getWithDefault(contract.budget, ""),
                  Belt_Option.getWithDefault(contract.contractLabel, ""),
                  contract.contractID,
                  Belt_Option.getWithDefault(contract.siren, ""),
                  Belt_Option.getWithDefault(contract.managerID, ""),
                  Belt_Option.mapWithDefault(contract.startDate, "", (function (param) {
                          return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                        })),
                  Belt_Option.mapWithDefault(contract.endDate, "", (function (param) {
                          return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                        })),
                  Belt_Option.getWithDefault(contract.referenceLLD, "")
                ];
        }));
  var sirenStr = match$3[3];
  var budgetStr = match$3[0];
  React.useEffect((function () {
          if (sirenStr.length > 0) {
            App_Api$DvmAdminFrontendFr.fetchSirenSimple(userManager, setSirenName, selectedRole, sirenStr);
          }
          
        }), [sirenStr]);
  var getManagerName = function (param) {
    return Belt_Option.mapWithDefault(managerIds, "", (function (x) {
                  return Belt_Option.mapWithDefault(Belt_List.getBy(x.content, (function (manager) {
                                    return manager.managerID === Belt_Option.mapWithDefault(contract, "", (function (contract) {
                                                  return Belt_Option.getWithDefault(contract.managerID, "");
                                                }));
                                  })), "", (function (x) {
                                return Belt_Option.getWithDefault(x.managerName, "");
                              }));
                }));
  };
  var tmp = budgetStr === "PROT" ? React.createElement(Core.Grid, {
          children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                label: Messages_Contract_Create$DvmAdminFrontendFr.referenceLLD,
                children: match$3[7]
              }),
          item: true
        }) : null;
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              direction: "column",
              item: true,
              spacing: 2
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                        label: Messages_Contract_Create$DvmAdminFrontendFr.contractType,
                        children: budgetStr
                      }),
                  item: true,
                  sm: Grid$Mui.Sm[2]
                }), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  item: true,
                  spacing: 3
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                            label: Messages_Contract_Create$DvmAdminFrontendFr.contractID,
                            children: match$3[2]
                          }),
                      item: true,
                      sm: Grid$Mui.Sm[2]
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                            label: Messages_Contract_Create$DvmAdminFrontendFr.contractName,
                            children: match$3[1]
                          }),
                      item: true
                    })), React.createElement(Core.Grid, {
                  children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                        label: Messages_Common$DvmAdminFrontendFr.siren,
                        children: sirenStr + " - " + App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$2[0], "")
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  item: true,
                  spacing: 3
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                            label: Messages_Contr_Man_Search$DvmAdminFrontendFr.managerIPN,
                            children: match$3[4]
                          }),
                      item: true,
                      sm: Grid$Mui.Sm[2]
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                            label: Messages_Contr_Man_Search$DvmAdminFrontendFr.managerName,
                            children: getManagerName(undefined)
                          }),
                      item: true
                    })), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  item: true,
                  spacing: 3
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                            label: Messages_Common$DvmAdminFrontendFr.startDate,
                            children: match$3[5]
                          }),
                      item: true,
                      sm: Grid$Mui.Sm[2]
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                            label: Messages_Common$DvmAdminFrontendFr.endDate,
                            children: match$3[6]
                          }),
                      item: true
                    })), tmp);
}

var Types;

var make = Contract_Edit_Main_Info_View;

export {
  Types ,
  make ,
}
/* react Not a pure module */
