// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../../../api/RestApi.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../../common/utils/Utils_Alert.bs.js";
import * as Flash_Search_Types$DvmAdminFrontendFr from "../../../../search/Flash_Search_Types.bs.js";
import * as Flash_Edit_Versions_Types$DvmAdminFrontendFr from "../../Flash_Edit_Versions_Types.bs.js";

function saveVersion(setResult, userManager, submissionCallbacks, version, flashId, method, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call(method, {
          TAG: /* Json */0,
          _0: Flash_Edit_Versions_Types$DvmAdminFrontendFr.VersionEdit.versionEditList_encode(version)
        }, userManager, selectedRole, "/flash/map/perf" + (
          method === "PUT" ? "/" + flashId : ""
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToSaveSingleVersion */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchAllVersions(submissionCallbacks, userManager, setResult, queryParams, selectedRole, withClosedModels) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Edit_Versions_Types$DvmAdminFrontendFr.IncludedModelVersions.searchResult_decode, undefined, userManager, selectedRole, "/dropdown/search/versions" + queryParams + "&withClosedModels=" + (
          withClosedModels ? "true" : "false"
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchVersionsSearch */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function deleteVersion(userManager, setResult, selectedRole, versions) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", {
          TAG: /* Json */0,
          _0: Flash_Edit_Versions_Types$DvmAdminFrontendFr.VersionEdit.versionEditList_encode(versions)
        }, userManager, selectedRole, "/flash/map/perf").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var twoYearsAgo = ReDate.subYears(new Date(Date.now()), 2.0).toISOString();

function getActions(alert, userManager, setActions, selectedRole) {
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Search_Types$DvmAdminFrontendFr.searchResult_decode, undefined, userManager, selectedRole, "/flash/actions?limit=5000&offset=0&filter=actionType=PERF;endDate=%5B" + twoYearsAgo + "%5C,%5D").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var actions = result._0;
          return Curry._1(setActions, (function (param) {
                        return actions.content;
                      }));
        }
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: result._0
                  }
                }));
        return Curry._1(setActions, (function (param) {
                      return /* [] */0;
                    }));
      });
}

function fetchVersionsOfAction(userManager, setResult, flashId, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Edit_Versions_Types$DvmAdminFrontendFr.IncludedModelVersions.searchResult_decode, undefined, userManager, selectedRole, "/flash/map/" + flashId + "?limit=150000&offset=0").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function overwriteVersion(setResult, userManager, submissionCallbacks, selectedRole, rows) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", {
          TAG: /* Json */0,
          _0: Flash_Edit_Versions_Types$DvmAdminFrontendFr.VersionEdit.versionEditList_encode(Belt_List.map(rows, (function (x) {
                      return x.original;
                    })))
        }, userManager, selectedRole, "/flash/map/perf").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
                  TAG: /* Json */0,
                  _0: Flash_Edit_Versions_Types$DvmAdminFrontendFr.VersionEdit.versionEditList_encode(Belt_List.map(rows, (function (x) {
                              return x.new;
                            })))
                }, userManager, selectedRole, "/flash/map/perf").then(function (result) {
                if (result.TAG === /* OkResult */0) {
                  Belt_Option.forEach(submissionCallbacks, (function (param) {
                          Curry._1(param.notifyOnSuccess, undefined);
                        }));
                  return Curry._1(setResult, (function (param) {
                                return {
                                        TAG: /* Ok */0,
                                        _0: undefined
                                      };
                              }));
                }
                var error = result._0;
                Belt_Option.forEach(submissionCallbacks, (function (param) {
                        Curry._1(param.notifyOnFailure, /* FailedToSaveSingleVersion */0);
                      }));
                Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Error */1,
                                _0: error
                              };
                      }));
              });
          return ;
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToSaveSingleVersion */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function modelVersionRowList_encode(v) {
  return Decco.listToJson(Flash_Edit_Versions_Types$DvmAdminFrontendFr.IncludedModelVersions.row_encode, v);
}

function modelVersionRowList_decode(v) {
  return Decco.listFromJson(Flash_Edit_Versions_Types$DvmAdminFrontendFr.IncludedModelVersions.row_decode, v);
}

function queryForAdjacentDates(userManager, selectedRole, row, setResult) {
  RestApi$DvmAdminFrontendFr.call("POST", modelVersionRowList_decode, {
          TAG: /* Json */0,
          _0: Decco.listToJson(Flash_Edit_Versions_Types$DvmAdminFrontendFr.IncludedModelVersions.row_encode, {
                hd: row,
                tl: /* [] */0
              })
        }, userManager, selectedRole, "/flash/adjacent-dates").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var resultRow = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: Belt_Option.getWithDefault(Belt_List.get(resultRow, 0), row)
                              };
                      }));
        }
        var error = result._0;
        return Curry._1(setResult, (function (param) {
                      return {
                              TAG: /* Error */1,
                              _0: error
                            };
                    }));
      });
}

var SingleForm;

var AddForm;

var Types;

export {
  SingleForm ,
  AddForm ,
  Types ,
  saveVersion ,
  fetchAllVersions ,
  deleteVersion ,
  twoYearsAgo ,
  getActions ,
  fetchVersionsOfAction ,
  overwriteVersion ,
  modelVersionRowList_encode ,
  modelVersionRowList_decode ,
  queryForAdjacentDates ,
}
/* twoYearsAgo Not a pure module */
