// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../common/utils/Utils_Form.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../intl/messages/Messages_Error_Inputs.bs.js";
import * as PCDAgreement_Search_Types$DvmAdminFrontendFr from "./PCDAgreement_Search_Types.bs.js";

var validators_volume = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var volume = param.volume;
      if (Belt_Option.getWithDefault(Belt_Int.fromString(volume), 1) > 0) {
        return {
                TAG: /* Ok */0,
                _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                      fieldId: PCDAgreement_Search_Types$DvmAdminFrontendFr.Fields.volume,
                      value: volume
                    })
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldPositive
              };
      }
    })
};

var validators_responseNumber = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(undefined, {
                    fieldId: PCDAgreement_Search_Types$DvmAdminFrontendFr.Fields.responseNumber,
                    value: param.responseNumber
                  })
            };
    })
};

var validators_yearBudget = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.yearBudget, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.dateYearToQueryParam(PCDAgreement_Search_Types$DvmAdminFrontendFr.Fields.yearBudget, date);
                  }));
    })
};

var validators_categoryID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: PCDAgreement_Search_Types$DvmAdminFrontendFr.Fields.categoryID,
                    value: param.categoryID
                  })
            };
    })
};

var validators_modelID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: PCDAgreement_Search_Types$DvmAdminFrontendFr.Fields.modelID,
                    value: param.modelID
                  })
            };
    })
};

var validators_dealerID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: PCDAgreement_Search_Types$DvmAdminFrontendFr.Fields.dealerID,
                    value: param.dealerID
                  })
            };
    })
};

var validators_siren = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: PCDAgreement_Search_Types$DvmAdminFrontendFr.Fields.siren,
                    value: param.siren
                  })
            };
    })
};

var validators = {
  volume: validators_volume,
  responseNumber: validators_responseNumber,
  yearBudget: validators_yearBudget,
  categoryID: validators_categoryID,
  modelID: validators_modelID,
  dealerID: validators_dealerID,
  siren: validators_siren
};

function initialFieldsStatuses(_input) {
  return {
          volume: /* Pristine */0,
          responseNumber: /* Pristine */0,
          yearBudget: /* Pristine */0,
          categoryID: /* Pristine */0,
          modelID: /* Pristine */0,
          dealerID: /* Pristine */0,
          siren: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.volume;
  var match_0 = match ? match._0 : Curry._1(validators.volume.validate, input);
  var match$1 = fieldsStatuses.responseNumber;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.responseNumber.validate, input);
  var match$2 = fieldsStatuses.yearBudget;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.yearBudget.validate, input);
  var match$3 = fieldsStatuses.categoryID;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.categoryID.validate, input);
  var match$4 = fieldsStatuses.modelID;
  var match_0$4 = match$4 ? match$4._0 : Curry._1(validators.modelID.validate, input);
  var match$5 = fieldsStatuses.dealerID;
  var match_0$5 = match$5 ? match$5._0 : Curry._1(validators.dealerID.validate, input);
  var match$6 = fieldsStatuses.siren;
  var match_0$6 = match$6 ? match$6._0 : Curry._1(validators.siren.validate, input);
  var volumeResult = match_0;
  var volumeResult$1;
  if (volumeResult.TAG === /* Ok */0) {
    var responseNumberResult = match_0$1;
    if (responseNumberResult.TAG === /* Ok */0) {
      var yearBudgetResult = match_0$2;
      if (yearBudgetResult.TAG === /* Ok */0) {
        var categoryIDResult = match_0$3;
        if (categoryIDResult.TAG === /* Ok */0) {
          var modelIDResult = match_0$4;
          if (modelIDResult.TAG === /* Ok */0) {
            var dealerIDResult = match_0$5;
            if (dealerIDResult.TAG === /* Ok */0) {
              var sirenResult = match_0$6;
              if (sirenResult.TAG === /* Ok */0) {
                return {
                        TAG: /* Valid */0,
                        output: {
                          siren: sirenResult._0,
                          dealerID: dealerIDResult._0,
                          modelID: modelIDResult._0,
                          categoryID: categoryIDResult._0,
                          yearBudget: yearBudgetResult._0,
                          responseNumber: responseNumberResult._0,
                          volume: volumeResult._0
                        },
                        fieldsStatuses: {
                          volume: /* Dirty */{
                            _0: volumeResult,
                            _1: /* Shown */0
                          },
                          responseNumber: /* Dirty */{
                            _0: responseNumberResult,
                            _1: /* Shown */0
                          },
                          yearBudget: /* Dirty */{
                            _0: yearBudgetResult,
                            _1: /* Shown */0
                          },
                          categoryID: /* Dirty */{
                            _0: categoryIDResult,
                            _1: /* Shown */0
                          },
                          modelID: /* Dirty */{
                            _0: modelIDResult,
                            _1: /* Shown */0
                          },
                          dealerID: /* Dirty */{
                            _0: dealerIDResult,
                            _1: /* Shown */0
                          },
                          siren: /* Dirty */{
                            _0: sirenResult,
                            _1: /* Shown */0
                          }
                        },
                        collectionsStatuses: undefined
                      };
              }
              volumeResult$1 = volumeResult;
            } else {
              volumeResult$1 = volumeResult;
            }
          } else {
            volumeResult$1 = volumeResult;
          }
        } else {
          volumeResult$1 = volumeResult;
        }
      } else {
        volumeResult$1 = volumeResult;
      }
    } else {
      volumeResult$1 = volumeResult;
    }
  } else {
    volumeResult$1 = volumeResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            volume: /* Dirty */{
              _0: volumeResult$1,
              _1: /* Shown */0
            },
            responseNumber: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            yearBudget: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            categoryID: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            modelID: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            dealerID: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            siren: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurVolumeField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.volume, validators_volume, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  volume: status,
                                  responseNumber: init.responseNumber,
                                  yearBudget: init.yearBudget,
                                  categoryID: init.categoryID,
                                  modelID: init.modelID,
                                  dealerID: init.dealerID,
                                  siren: init.siren
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurResponseNumberField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.responseNumber, validators_responseNumber, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  volume: init.volume,
                                  responseNumber: status,
                                  yearBudget: init.yearBudget,
                                  categoryID: init.categoryID,
                                  modelID: init.modelID,
                                  dealerID: init.dealerID,
                                  siren: init.siren
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurYearBudgetField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.yearBudget, validators_yearBudget, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  volume: init.volume,
                                  responseNumber: init.responseNumber,
                                  yearBudget: status,
                                  categoryID: init.categoryID,
                                  modelID: init.modelID,
                                  dealerID: init.dealerID,
                                  siren: init.siren
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCategoryIDField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.categoryID, validators_categoryID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  volume: init.volume,
                                  responseNumber: init.responseNumber,
                                  yearBudget: init.yearBudget,
                                  categoryID: status,
                                  modelID: init.modelID,
                                  dealerID: init.dealerID,
                                  siren: init.siren
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurModelIDField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.modelID, validators_modelID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  volume: init.volume,
                                  responseNumber: init.responseNumber,
                                  yearBudget: init.yearBudget,
                                  categoryID: init.categoryID,
                                  modelID: status,
                                  dealerID: init.dealerID,
                                  siren: init.siren
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDealerIDField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.dealerID, validators_dealerID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  volume: init.volume,
                                  responseNumber: init.responseNumber,
                                  yearBudget: init.yearBudget,
                                  categoryID: init.categoryID,
                                  modelID: init.modelID,
                                  dealerID: status,
                                  siren: init.siren
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSirenField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.siren, validators_siren, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  volume: init.volume,
                                  responseNumber: init.responseNumber,
                                  yearBudget: init.yearBudget,
                                  categoryID: init.categoryID,
                                  modelID: init.modelID,
                                  dealerID: init.dealerID,
                                  siren: status
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */7 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */7,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */8,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */10);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */9);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */8 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */9 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */10 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateVolumeField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.volume, state.submissionStatus, validators_volume, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            volume: status,
                                            responseNumber: init.responseNumber,
                                            yearBudget: init.yearBudget,
                                            categoryID: init.categoryID,
                                            modelID: init.modelID,
                                            dealerID: init.dealerID,
                                            siren: init.siren
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateResponseNumberField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.responseNumber, state.submissionStatus, validators_responseNumber, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            volume: init.volume,
                                            responseNumber: status,
                                            yearBudget: init.yearBudget,
                                            categoryID: init.categoryID,
                                            modelID: init.modelID,
                                            dealerID: init.dealerID,
                                            siren: init.siren
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateYearBudgetField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.yearBudget, state.submissionStatus, validators_yearBudget, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            volume: init.volume,
                                            responseNumber: init.responseNumber,
                                            yearBudget: status,
                                            categoryID: init.categoryID,
                                            modelID: init.modelID,
                                            dealerID: init.dealerID,
                                            siren: init.siren
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCategoryIDField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.categoryID, state.submissionStatus, validators_categoryID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            volume: init.volume,
                                            responseNumber: init.responseNumber,
                                            yearBudget: init.yearBudget,
                                            categoryID: status,
                                            modelID: init.modelID,
                                            dealerID: init.dealerID,
                                            siren: init.siren
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateModelIDField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.modelID, state.submissionStatus, validators_modelID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            volume: init.volume,
                                            responseNumber: init.responseNumber,
                                            yearBudget: init.yearBudget,
                                            categoryID: init.categoryID,
                                            modelID: status,
                                            dealerID: init.dealerID,
                                            siren: init.siren
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDealerIDField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.dealerID, state.submissionStatus, validators_dealerID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            volume: init.volume,
                                            responseNumber: init.responseNumber,
                                            yearBudget: init.yearBudget,
                                            categoryID: init.categoryID,
                                            modelID: init.modelID,
                                            dealerID: status,
                                            siren: init.siren
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSirenField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.siren, state.submissionStatus, validators_siren, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            volume: init.volume,
                                            responseNumber: init.responseNumber,
                                            yearBudget: init.yearBudget,
                                            categoryID: init.categoryID,
                                            modelID: init.modelID,
                                            dealerID: init.dealerID,
                                            siren: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */7 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */8 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */9 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateVolume: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVolumeField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateResponseNumber: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateResponseNumberField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateYearBudget: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateYearBudgetField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCategoryID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCategoryIDField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateModelID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelIDField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDealerID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDealerIDField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateSiren: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSirenField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurVolume: (function (param) {
              Curry._1(dispatch, /* BlurVolumeField */0);
            }),
          blurResponseNumber: (function (param) {
              Curry._1(dispatch, /* BlurResponseNumberField */1);
            }),
          blurYearBudget: (function (param) {
              Curry._1(dispatch, /* BlurYearBudgetField */2);
            }),
          blurCategoryID: (function (param) {
              Curry._1(dispatch, /* BlurCategoryIDField */3);
            }),
          blurModelID: (function (param) {
              Curry._1(dispatch, /* BlurModelIDField */4);
            }),
          blurDealerID: (function (param) {
              Curry._1(dispatch, /* BlurDealerIDField */5);
            }),
          blurSiren: (function (param) {
              Curry._1(dispatch, /* BlurSirenField */6);
            }),
          volumeResult: Formality.exposeFieldResult(state.fieldsStatuses.volume),
          responseNumberResult: Formality.exposeFieldResult(state.fieldsStatuses.responseNumber),
          yearBudgetResult: Formality.exposeFieldResult(state.fieldsStatuses.yearBudget),
          categoryIDResult: Formality.exposeFieldResult(state.fieldsStatuses.categoryID),
          modelIDResult: Formality.exposeFieldResult(state.fieldsStatuses.modelID),
          dealerIDResult: Formality.exposeFieldResult(state.fieldsStatuses.dealerID),
          sirenResult: Formality.exposeFieldResult(state.fieldsStatuses.siren),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.volume || match.responseNumber || match.yearBudget || match.categoryID || match.modelID || match.dealerID || match.siren) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */7);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */8);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */9);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */9,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */10);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  siren: "",
  dealerID: "",
  modelID: "",
  categoryID: "",
  yearBudget: undefined,
  responseNumber: "",
  volume: ""
};

export {
  TableFilterForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
