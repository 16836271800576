// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";

var validators_agentCode = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.agentCode
            };
    })
};

var validators_salesmanName = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.salesmanName
            };
    })
};

var validators_phase = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.phase
            };
    })
};

var validators_versionID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.versionID
            };
    })
};

var validators_modelID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.modelID
            };
    })
};

var validators = {
  agentCode: validators_agentCode,
  salesmanName: validators_salesmanName,
  phase: validators_phase,
  versionID: validators_versionID,
  modelID: validators_modelID
};

function initialFieldsStatuses(_input) {
  return {
          agentCode: /* Pristine */0,
          salesmanName: /* Pristine */0,
          phase: /* Pristine */0,
          versionID: /* Pristine */0,
          modelID: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.agentCode;
  var match_0 = match ? match._0 : Curry._1(validators.agentCode.validate, input);
  var match$1 = fieldsStatuses.salesmanName;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.salesmanName.validate, input);
  var match$2 = fieldsStatuses.phase;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.phase.validate, input);
  var match$3 = fieldsStatuses.versionID;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.versionID.validate, input);
  var match$4 = fieldsStatuses.modelID;
  var match_0$4 = match$4 ? match$4._0 : Curry._1(validators.modelID.validate, input);
  var agentCodeResult = match_0;
  var agentCodeResult$1;
  if (agentCodeResult.TAG === /* Ok */0) {
    var salesmanNameResult = match_0$1;
    if (salesmanNameResult.TAG === /* Ok */0) {
      var phaseResult = match_0$2;
      if (phaseResult.TAG === /* Ok */0) {
        var versionIDResult = match_0$3;
        if (versionIDResult.TAG === /* Ok */0) {
          var modelIDResult = match_0$4;
          if (modelIDResult.TAG === /* Ok */0) {
            return {
                    TAG: /* Valid */0,
                    output: {
                      modelID: modelIDResult._0,
                      versionID: versionIDResult._0,
                      phase: phaseResult._0,
                      salesmanName: salesmanNameResult._0,
                      agentCode: agentCodeResult._0
                    },
                    fieldsStatuses: {
                      agentCode: /* Dirty */{
                        _0: agentCodeResult,
                        _1: /* Shown */0
                      },
                      salesmanName: /* Dirty */{
                        _0: salesmanNameResult,
                        _1: /* Shown */0
                      },
                      phase: /* Dirty */{
                        _0: phaseResult,
                        _1: /* Shown */0
                      },
                      versionID: /* Dirty */{
                        _0: versionIDResult,
                        _1: /* Shown */0
                      },
                      modelID: /* Dirty */{
                        _0: modelIDResult,
                        _1: /* Shown */0
                      }
                    },
                    collectionsStatuses: undefined
                  };
          }
          agentCodeResult$1 = agentCodeResult;
        } else {
          agentCodeResult$1 = agentCodeResult;
        }
      } else {
        agentCodeResult$1 = agentCodeResult;
      }
    } else {
      agentCodeResult$1 = agentCodeResult;
    }
  } else {
    agentCodeResult$1 = agentCodeResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            agentCode: /* Dirty */{
              _0: agentCodeResult$1,
              _1: /* Shown */0
            },
            salesmanName: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            phase: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            versionID: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            modelID: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurAgentCodeField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.agentCode, validators_agentCode, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  agentCode: status,
                                  salesmanName: init.salesmanName,
                                  phase: init.phase,
                                  versionID: init.versionID,
                                  modelID: init.modelID
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSalesmanNameField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.salesmanName, validators_salesmanName, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  agentCode: init.agentCode,
                                  salesmanName: status,
                                  phase: init.phase,
                                  versionID: init.versionID,
                                  modelID: init.modelID
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPhaseField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.phase, validators_phase, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  agentCode: init.agentCode,
                                  salesmanName: init.salesmanName,
                                  phase: status,
                                  versionID: init.versionID,
                                  modelID: init.modelID
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionIDField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.versionID, validators_versionID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  agentCode: init.agentCode,
                                  salesmanName: init.salesmanName,
                                  phase: init.phase,
                                  versionID: status,
                                  modelID: init.modelID
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurModelIDField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.modelID, validators_modelID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  agentCode: init.agentCode,
                                  salesmanName: init.salesmanName,
                                  phase: init.phase,
                                  versionID: init.versionID,
                                  modelID: status
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */5 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */5,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */6,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */8);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */7);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */6 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */7 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */8 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateAgentCodeField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.agentCode, state.submissionStatus, validators_agentCode, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            agentCode: status,
                                            salesmanName: init.salesmanName,
                                            phase: init.phase,
                                            versionID: init.versionID,
                                            modelID: init.modelID
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSalesmanNameField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.salesmanName, state.submissionStatus, validators_salesmanName, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            agentCode: init.agentCode,
                                            salesmanName: status,
                                            phase: init.phase,
                                            versionID: init.versionID,
                                            modelID: init.modelID
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePhaseField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.phase, state.submissionStatus, validators_phase, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            agentCode: init.agentCode,
                                            salesmanName: init.salesmanName,
                                            phase: status,
                                            versionID: init.versionID,
                                            modelID: init.modelID
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionIDField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.versionID, state.submissionStatus, validators_versionID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            agentCode: init.agentCode,
                                            salesmanName: init.salesmanName,
                                            phase: init.phase,
                                            versionID: status,
                                            modelID: init.modelID
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateModelIDField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.modelID, state.submissionStatus, validators_modelID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            agentCode: init.agentCode,
                                            salesmanName: init.salesmanName,
                                            phase: init.phase,
                                            versionID: init.versionID,
                                            modelID: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */5 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */6 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */7 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateAgentCode: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAgentCodeField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateSalesmanName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSalesmanNameField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updatePhase: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePhaseField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVersionID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionIDField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateModelID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelIDField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurAgentCode: (function (param) {
              Curry._1(dispatch, /* BlurAgentCodeField */0);
            }),
          blurSalesmanName: (function (param) {
              Curry._1(dispatch, /* BlurSalesmanNameField */1);
            }),
          blurPhase: (function (param) {
              Curry._1(dispatch, /* BlurPhaseField */2);
            }),
          blurVersionID: (function (param) {
              Curry._1(dispatch, /* BlurVersionIDField */3);
            }),
          blurModelID: (function (param) {
              Curry._1(dispatch, /* BlurModelIDField */4);
            }),
          agentCodeResult: Formality.exposeFieldResult(state.fieldsStatuses.agentCode),
          salesmanNameResult: Formality.exposeFieldResult(state.fieldsStatuses.salesmanName),
          phaseResult: Formality.exposeFieldResult(state.fieldsStatuses.phase),
          versionIDResult: Formality.exposeFieldResult(state.fieldsStatuses.versionID),
          modelIDResult: Formality.exposeFieldResult(state.fieldsStatuses.modelID),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.agentCode || match.salesmanName || match.phase || match.versionID || match.modelID) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */5);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */6);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */7);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */7,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */8);
            })
        };
}

var CorrectionForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  modelID: "",
  versionID: "",
  phase: "",
  salesmanName: "",
  agentCode: ""
};

export {
  CorrectionForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
