// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../../common/components/icons/App_Icon.bs.js";
import * as Grid_Edit_Delete_Modal$DvmAdminFrontendFr from "../../actions/Grid_Edit_Delete_Modal.bs.js";
import * as Grid_Edit_New_Row_Modal$DvmAdminFrontendFr from "../../addRow/Grid_Edit_New_Row_Modal.bs.js";

function Grid_Edit_Modal_Collection_Row_ActionButtons(Props) {
  var form = Props.form;
  var index = Props.index;
  var tableRow = Props.tableRow;
  var setModal = Props.setModal;
  var setResult = Props.setResult;
  var whatTable = Props.whatTable;
  var setActionsPending = Props.setActionsPending;
  var whatGrid = Props.whatGrid;
  var budget = Props.budget;
  var acquisitionOptions = Props.acquisitionOptions;
  var budgetOrID = Props.budgetOrID;
  var filterStartDate = Props.filterStartDate;
  var filterEndDate = Props.filterEndDate;
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              justifyContent: "flex-start",
              wrap: "nowrap"
            }, React.createElement(Core.Grid, {
                  children: React.createElement(Core.Button, {
                        onClick: (function (param) {
                            Curry._1(setModal, (function (param) {
                                    return React.createElement(Grid_Edit_New_Row_Modal$DvmAdminFrontendFr.make, {
                                                budgetOrID: budgetOrID,
                                                original: tableRow,
                                                onClose: (function (param) {
                                                    Curry._1(setModal, (function (param) {
                                                            return null;
                                                          }));
                                                  }),
                                                whatTable: whatTable,
                                                whatGrid: whatGrid,
                                                budget: budget,
                                                operation: /* Duplicate */0,
                                                setActionsPending: setActionsPending,
                                                acquisitionOptions: acquisitionOptions,
                                                filterStartDate: filterStartDate,
                                                filterEndDate: filterEndDate
                                              });
                                  }));
                          }),
                        children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                              size: /* Large */3,
                              type_: /* Documents */5
                            }),
                        key: "add"
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Core.Button, {
                        onClick: (function (param) {
                            Curry._3(form.updateSingleRowIsEdited, index, (function (param, isEdited) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(param.marginRows, (function (indexRow, row) {
                                                    if (indexRow !== index) {
                                                      return row;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(row);
                                                    newrecord.isEdited = isEdited;
                                                    return newrecord;
                                                  }))
                                          };
                                  }), true);
                          }),
                        children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                              size: /* Large */3,
                              type_: /* Edit */60
                            }),
                        key: "edit"
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Core.Button, {
                        onClick: (function (param) {
                            Curry._1(setModal, (function (param) {
                                    return React.createElement(Grid_Edit_Delete_Modal$DvmAdminFrontendFr.make, {
                                                tableResult: {
                                                  hd: tableRow,
                                                  tl: /* [] */0
                                                },
                                                setResult: setResult,
                                                onClose: (function (param) {
                                                    Curry._1(setModal, (function (param) {
                                                            return null;
                                                          }));
                                                  }),
                                                whatTable: whatTable,
                                                whatGrid: whatGrid,
                                                budget: budget
                                              });
                                  }));
                          }),
                        children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                              size: /* Large */3,
                              type_: /* Trash */38,
                              color: "red"
                            }),
                        key: "delete"
                      }),
                  item: true
                }));
}

var Form;

var Types;

var make = Grid_Edit_Modal_Collection_Row_ActionButtons;

export {
  Form ,
  Types ,
  make ,
}
/* react Not a pure module */
