// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as App_Table$DvmAdminFrontendFr from "../../../../common/components/table/App_Table.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../common/utils/Utils_Api.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Types.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../../context/user/User_Types_Utils.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Pagination.bs.js";
import * as App_Table_Export_Menu$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Export_Menu.bs.js";
import * as App_Table_Create_Button$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Create_Button.bs.js";
import * as Action_Referential_Types$DvmAdminFrontendFr from "./Action_Referential_Types.bs.js";
import * as Messages_Action_Referential$DvmAdminFrontendFr from "../../../../intl/messages/actionReferential/Messages_Action_Referential.bs.js";
import * as Action_Referential_Delete_Modal$DvmAdminFrontendFr from "../modal/Action_Referential_Delete_Modal.bs.js";
import * as Action_Referential_Update_Modal$DvmAdminFrontendFr from "../modal/Action_Referential_Update_Modal.bs.js";
import * as Messages_Action_Referential_Fields$DvmAdminFrontendFr from "../../../../intl/messages/actionReferential/Messages_Action_Referential_Fields.bs.js";
import * as Action_Referential_Fields_Page_Modal$DvmAdminFrontendFr from "../../fields/Action_Referential_Fields_Page_Modal.bs.js";

function getEnglish(param) {
  return param[0];
}

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Action_Referential_Types$DvmAdminFrontendFr.Fields.countryCode), undefined, undefined, undefined, undefined, undefined, Messages_Action_Referential$DvmAdminFrontendFr.countryCode);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Action_Referential_Types$DvmAdminFrontendFr.Fields.localActionTypeID, undefined, undefined, undefined, undefined, undefined, Messages_Action_Referential$DvmAdminFrontendFr.localActionTypeID),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Action_Referential_Types$DvmAdminFrontendFr.Fields.localActionTypeLabel, undefined, undefined, undefined, undefined, undefined, Messages_Action_Referential$DvmAdminFrontendFr.localActionTypeLabel),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Action_Referential_Types$DvmAdminFrontendFr.Fields.localActionDescription, undefined, undefined, undefined, undefined, undefined, Messages_Action_Referential$DvmAdminFrontendFr.localActionDescription),
      tl: {
        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Action_Referential_Types$DvmAdminFrontendFr.Fields.localActionTrigger, undefined, undefined, undefined, undefined, undefined, Messages_Action_Referential$DvmAdminFrontendFr.localActionTrigger),
        tl: {
          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Action_Referential_Types$DvmAdminFrontendFr.Fields.appCorpChannel, undefined, undefined, undefined, undefined, undefined, Messages_Action_Referential$DvmAdminFrontendFr.appCorpChannel),
          tl: {
            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Action_Referential_Types$DvmAdminFrontendFr.Fields.brand, undefined, undefined, undefined, undefined, undefined, Messages_Action_Referential$DvmAdminFrontendFr.brandNoFrench),
            tl: {
              hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Action_Referential_Types$DvmAdminFrontendFr.Fields.distributionMode, undefined, undefined, undefined, undefined, undefined, Messages_Action_Referential$DvmAdminFrontendFr.distributionMode),
              tl: {
                hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Action_Referential_Types$DvmAdminFrontendFr.Fields.appClientChannel, undefined, undefined, undefined, undefined, undefined, Messages_Action_Referential$DvmAdminFrontendFr.applicationClientChannel),
                tl: {
                  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Action_Referential_Types$DvmAdminFrontendFr.Fields.appSubChannel, undefined, undefined, undefined, undefined, undefined, Messages_Action_Referential$DvmAdminFrontendFr.appSubChannel),
                  tl: {
                    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Action_Referential_Types$DvmAdminFrontendFr.Fields.marketingCorpCategory, undefined, undefined, undefined, undefined, undefined, Messages_Action_Referential$DvmAdminFrontendFr.marketingCorpCategory),
                    tl: {
                      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Action_Referential_Types$DvmAdminFrontendFr.Fields.marketingCorpType, undefined, undefined, undefined, undefined, undefined, Messages_Action_Referential$DvmAdminFrontendFr.marketingCorpType),
                      tl: {
                        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Action_Referential_Types$DvmAdminFrontendFr.Fields.vmeDestination, undefined, undefined, undefined, undefined, undefined, Messages_Action_Referential$DvmAdminFrontendFr.vmeDestination),
                        tl: {
                          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Action_Referential_Types$DvmAdminFrontendFr.Fields.controllingCorpTypeID, undefined, undefined, undefined, undefined, undefined, Messages_Action_Referential$DvmAdminFrontendFr.controllingCorpTypeID),
                          tl: {
                            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Action_Referential_Types$DvmAdminFrontendFr.Fields.controllingCorpTypeLabel, undefined, undefined, undefined, undefined, undefined, Messages_Action_Referential$DvmAdminFrontendFr.controllingCorpTypeLabel),
                            tl: {
                              hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Action_Referential_Types$DvmAdminFrontendFr.Fields.definition, undefined, undefined, undefined, undefined, undefined, Messages_Action_Referential$DvmAdminFrontendFr.definition),
                              tl: {
                                hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Action_Referential_Types$DvmAdminFrontendFr.Fields.paidTo, undefined, undefined, undefined, undefined, undefined, Messages_Action_Referential$DvmAdminFrontendFr.paidTo),
                                tl: {
                                  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Action_Referential_Types$DvmAdminFrontendFr.Fields.controllingAppStartDate, undefined, undefined, undefined, undefined, undefined, Messages_Action_Referential$DvmAdminFrontendFr.controllingAppStartDate),
                                  tl: {
                                    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Action_Referential_Types$DvmAdminFrontendFr.Fields.controllingAppEndDate, undefined, undefined, undefined, undefined, undefined, Messages_Action_Referential$DvmAdminFrontendFr.controllingAppEndDate),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function Action_Referential_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var operation = Props.operation;
  var paramsList = Props.paramsList;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = React.useState(function () {
        return null;
      });
  var setModal = match$1[1];
  var match$2 = React.useState(function () {
        return null;
      });
  var setFieldsModal = match$2[1];
  var onCloseModal = function (param) {
    Curry._1(setModal, (function (param) {
            return null;
          }));
    Curry._1(setFieldsModal, (function (param) {
            return null;
          }));
    Curry._2(fetchData, undefined, fetchRequest);
  };
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (row) {
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, operation === /* Manage */1 ? Caml_option.some(React.createElement(Core.Grid, {
                                                        children: null,
                                                        container: true,
                                                        justifyContent: "flex-start",
                                                        wrap: "nowrap"
                                                      }, React.createElement(Core.Grid, {
                                                            children: React.createElement(Core.Button, {
                                                                  onClick: (function (param) {
                                                                      Curry._1(setModal, (function (param) {
                                                                              return React.createElement(Action_Referential_Update_Modal$DvmAdminFrontendFr.make, {
                                                                                          onClose: (function (param) {
                                                                                              onCloseModal(undefined);
                                                                                            }),
                                                                                          row: row,
                                                                                          onOk: (function (param) {
                                                                                              onCloseModal(undefined);
                                                                                            })
                                                                                        });
                                                                            }));
                                                                    }),
                                                                  children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                        size: /* Large */3,
                                                                        type_: /* Edit */60
                                                                      }),
                                                                  key: "edit"
                                                                }),
                                                            item: true
                                                          }), React.createElement(Core.Grid, {
                                                            children: React.createElement(Core.Button, {
                                                                  onClick: (function (param) {
                                                                      Curry._1(setModal, (function (param) {
                                                                              return React.createElement(Action_Referential_Delete_Modal$DvmAdminFrontendFr.make, {
                                                                                          onClose: (function (param) {
                                                                                              onCloseModal(undefined);
                                                                                            }),
                                                                                          row: row,
                                                                                          onOk: (function (param) {
                                                                                              onCloseModal(undefined);
                                                                                            })
                                                                                        });
                                                                            }));
                                                                    }),
                                                                  children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                        size: /* Large */3,
                                                                        type_: /* Trash */38,
                                                                        color: "red"
                                                                      }),
                                                                  key: "delete"
                                                                }),
                                                            item: true
                                                          }))) : undefined, undefined, Belt_List.mapWithIndex({
                                                  hd: row.countryCode,
                                                  tl: {
                                                    hd: row.localActionTypeID,
                                                    tl: {
                                                      hd: Belt_Option.getWithDefault(row.localActionTypeLabel, ""),
                                                      tl: {
                                                        hd: Belt_Option.getWithDefault(row.localActionDescription, ""),
                                                        tl: {
                                                          hd: Belt_Option.getWithDefault(row.localActionTrigger, ""),
                                                          tl: {
                                                            hd: Belt_Option.getWithDefault(row.appCorpChannel, ""),
                                                            tl: {
                                                              hd: Belt_Option.getWithDefault(row.brand, ""),
                                                              tl: {
                                                                hd: Belt_Option.getWithDefault(row.distributionMode, ""),
                                                                tl: {
                                                                  hd: Belt_Option.getWithDefault(row.appClientChannel, ""),
                                                                  tl: {
                                                                    hd: Belt_Option.getWithDefault(row.appSubChannel, ""),
                                                                    tl: {
                                                                      hd: Belt_Option.getWithDefault(row.marketingCorpCategory, ""),
                                                                      tl: {
                                                                        hd: Belt_Option.getWithDefault(row.marketingCorpType, ""),
                                                                        tl: {
                                                                          hd: Belt_Option.getWithDefault(row.vmeDestination, ""),
                                                                          tl: {
                                                                            hd: Belt_Option.getWithDefault(row.controllingCorpTypeID, ""),
                                                                            tl: {
                                                                              hd: Belt_Option.getWithDefault(row.controllingCorpTypeLabel, ""),
                                                                              tl: {
                                                                                hd: Belt_Option.getWithDefault(row.definition, ""),
                                                                                tl: {
                                                                                  hd: Belt_Option.getWithDefault(row.paidTo, ""),
                                                                                  tl: {
                                                                                    hd: Belt_Option.mapWithDefault(row.controllingAppStartDate, "", (function (param) {
                                                                                            return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                                          })),
                                                                                    tl: {
                                                                                      hd: Belt_Option.mapWithDefault(row.controllingAppEndDate, "", (function (param) {
                                                                                              return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                                            })),
                                                                                      tl: /* [] */0
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._2(fetchData, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._2(fetchData, undefined, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var tmp = {
    headers: fetchRequest.headers,
    handleSorting: handleSorting,
    tablePage: convertTable(tablePage),
    paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined),
    exportMenu: React.createElement(Core.Grid, {
          children: null,
          container: true,
          item: true,
          justifyContent: "flex-end"
        }, operation === /* Manage */1 ? React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                    children: React.createElement(App_Table_Create_Button$DvmAdminFrontendFr.make, {
                          onClick: (function (param) {
                              Curry._1(setFieldsModal, (function (param) {
                                      return React.createElement(Action_Referential_Fields_Page_Modal$DvmAdminFrontendFr.make, {
                                                  onClose: (function (param) {
                                                      onCloseModal(undefined);
                                                    })
                                                });
                                    }));
                            }),
                          message: Messages_Action_Referential_Fields$DvmAdminFrontendFr.menuTitle
                        }),
                    item: true
                  }), React.createElement(Core.Grid, {
                    children: React.createElement(App_Table_Create_Button$DvmAdminFrontendFr.make, {
                          onClick: (function (param) {
                              Curry._1(setModal, (function (param) {
                                      return React.createElement(Action_Referential_Update_Modal$DvmAdminFrontendFr.make, {
                                                  onClose: (function (param) {
                                                      onCloseModal(undefined);
                                                    }),
                                                  row: undefined,
                                                  onOk: (function (param) {
                                                      onCloseModal(undefined);
                                                    })
                                                });
                                    }));
                            }),
                          message: Messages_Action_Referential$DvmAdminFrontendFr.createReferential
                        }),
                    item: true
                  })) : null, React.createElement(Core.Grid, {
              children: React.createElement(App_Table_Export_Menu$DvmAdminFrontendFr.make, {
                    query: Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                          page: 0,
                          rowsPerPage: 150000,
                          headers: fetchRequest.headers,
                          filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap(paramsList, (function (queryParam) {
                                          return Belt_Option.map(queryParam, (function (x) {
                                                        return x.fr;
                                                      }));
                                        }))), ";", (function (queryParam) {
                                  return queryParam;
                                })),
                          additionalQueryParams: fetchRequest.additionalQueryParams
                        }),
                    endpoint: "actions/referential"
                  }),
              item: true
            }))
  };
  var tmp$1 = User_Types_Utils$DvmAdminFrontendFr.isAdmin(match[0].selectedRole) && operation === /* Manage */1 ? ({
        TAG: /* Left */0,
        _0: /* EmptyCell */0
      }) : undefined;
  if (tmp$1 !== undefined) {
    tmp.rowButton = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, tmp), match$1[0], match$2[0]);
}

var make = Action_Referential_Table;

export {
  getEnglish ,
  headersConfiguration ,
  make ,
}
/* headersConfiguration Not a pure module */
