// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as App_Table$DvmAdminFrontendFr from "../../../common/components/table/App_Table.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../intl/Intl_Utils.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as Messages_Quotas$DvmAdminFrontendFr from "../../../intl/messages/quotas/Messages_Quotas.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Pagination.bs.js";
import * as Messages_Quotas_PARC$DvmAdminFrontendFr from "../../../intl/messages/quotas/Messages_Quotas_PARC.bs.js";
import * as App_Table_Export_Menu$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Export_Menu.bs.js";
import * as Messages_ModelVersion$DvmAdminFrontendFr from "../../../intl/messages/modelVersion/Messages_ModelVersion.bs.js";
import * as App_Table_Create_Button$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Create_Button.bs.js";
import * as Quota_PARC_Manage_Modal$DvmAdminFrontendFr from "../admin/Quota_PARC_Manage_Modal.bs.js";
import * as Quota_PARC_Search_Types$DvmAdminFrontendFr from "./Quota_PARC_Search_Types.bs.js";
import * as Quota_PARC_Manage_Delete_Modal$DvmAdminFrontendFr from "../admin/Quota_PARC_Manage_Delete_Modal.bs.js";

function headersConfiguration(pageType) {
  return Belt_List.concat({
              hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_PARC_Search_Types$DvmAdminFrontendFr.FilterFields.orderType, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.orderType),
              tl: {
                hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_PARC_Search_Types$DvmAdminFrontendFr.FilterFields.dealerId, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.dealerID),
                tl: {
                  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_PARC_Search_Types$DvmAdminFrontendFr.FilterFields.dealerName, undefined, undefined, undefined, undefined, undefined, Messages_Quotas_PARC$DvmAdminFrontendFr.dealerName),
                  tl: {
                    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_PARC_Search_Types$DvmAdminFrontendFr.FilterFields.modelId, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.model),
                    tl: {
                      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_PARC_Search_Types$DvmAdminFrontendFr.FilterFields.phase, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.phase),
                      tl: {
                        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_PARC_Search_Types$DvmAdminFrontendFr.FilterFields.engineType, undefined, undefined, undefined, undefined, undefined, Messages_ModelVersion$DvmAdminFrontendFr.motorization),
                        tl: {
                          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_PARC_Search_Types$DvmAdminFrontendFr.FilterFields.volumeInitial, undefined, undefined, undefined, undefined, undefined, Messages_Quotas$DvmAdminFrontendFr.initial),
                          tl: {
                            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_PARC_Search_Types$DvmAdminFrontendFr.FilterFields.volume, undefined, undefined, undefined, undefined, undefined, Messages_Quotas$DvmAdminFrontendFr.corrected),
                            tl: {
                              hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_PARC_Search_Types$DvmAdminFrontendFr.FilterFields.consumed, undefined, undefined, undefined, undefined, undefined, Messages_Quotas$DvmAdminFrontendFr.consumedTotal),
                              tl: {
                                hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_PARC_Search_Types$DvmAdminFrontendFr.FilterFields.remaining, undefined, false, undefined, undefined, undefined, Messages_Quotas$DvmAdminFrontendFr.remaining),
                                tl: {
                                  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_PARC_Search_Types$DvmAdminFrontendFr.FilterFields.startDate, /* Desc */1, undefined, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.startDate),
                                  tl: {
                                    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_PARC_Search_Types$DvmAdminFrontendFr.FilterFields.endDate, undefined, undefined, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.endDate),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }, pageType === /* Manage */1 ? ({
                  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_PARC_Search_Types$DvmAdminFrontendFr.FilterFields.creationDate, undefined, undefined, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.creationDate),
                  tl: {
                    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_PARC_Search_Types$DvmAdminFrontendFr.FilterFields.modificationDate, undefined, undefined, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.lastModificationDate),
                    tl: /* [] */0
                  }
                }) : /* [] */0);
}

function Quota_PARC_Search_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var pageType = Props.pageType;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return null;
      });
  var setModal = match[1];
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (quota) {
                                  var remaining = Belt_Option.getWithDefault(quota.volume, 0) - Belt_Option.getWithDefault(quota.consumed, 0) | 0;
                                  var row = Belt_List.concat({
                                        hd: Belt_Option.getWithDefault(quota.orderType, ""),
                                        tl: {
                                          hd: Belt_Option.getWithDefault(quota.dealerId, ""),
                                          tl: {
                                            hd: Belt_Option.getWithDefault(quota.dealerName, ""),
                                            tl: {
                                              hd: Belt_Option.getWithDefault(quota.modelId, ""),
                                              tl: {
                                                hd: Belt_Option.getWithDefault(quota.phase, ""),
                                                tl: {
                                                  hd: Belt_Option.getWithDefault(quota.engineType, ""),
                                                  tl: {
                                                    hd: Belt_Option.mapWithDefault(quota.volumeInitial, "", (function (prim) {
                                                            return String(prim);
                                                          })),
                                                    tl: {
                                                      hd: Belt_Option.mapWithDefault(quota.volume, "", (function (prim) {
                                                              return String(prim);
                                                            })),
                                                      tl: {
                                                        hd: Belt_Option.mapWithDefault(quota.consumed, "", (function (prim) {
                                                                return String(prim);
                                                              })),
                                                        tl: {
                                                          hd: String(remaining),
                                                          tl: {
                                                            hd: Belt_Option.mapWithDefault(quota.startDate, "", (function (param) {
                                                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                  })),
                                                            tl: {
                                                              hd: Belt_Option.mapWithDefault(quota.endDate, "", (function (param) {
                                                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                    })),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }, pageType === /* Manage */1 ? ({
                                            hd: Belt_Option.mapWithDefault(quota.creationDate, "", (function (param) {
                                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                  })),
                                            tl: {
                                              hd: Belt_Option.mapWithDefault(quota.modificationDate, "", (function (param) {
                                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                    })),
                                              tl: /* [] */0
                                            }
                                          }) : /* [] */0);
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, Caml_option.some(React.createElement(Core.Grid, {
                                                      children: null,
                                                      container: true,
                                                      item: true,
                                                      wrap: "nowrap"
                                                    }, React.createElement(Core.Grid, {
                                                          children: React.createElement(Core.Button, {
                                                                onClick: (function (param) {
                                                                    Curry._1(setModal, (function (param) {
                                                                            return React.createElement(Quota_PARC_Manage_Modal$DvmAdminFrontendFr.make, {
                                                                                        onClose: (function (param) {
                                                                                            Curry._1(setModal, (function (param) {
                                                                                                    return null;
                                                                                                  }));
                                                                                          }),
                                                                                        operation: /* Modify */{
                                                                                          _0: quota
                                                                                        },
                                                                                        fetchRequest: fetchRequest,
                                                                                        fetchData: fetchData
                                                                                      });
                                                                          }));
                                                                  }),
                                                                children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                      size: /* Large */3,
                                                                      type_: /* Edit */60
                                                                    }),
                                                                key: "edit"
                                                              }),
                                                          item: true
                                                        }), React.createElement(Core.Grid, {
                                                          children: React.createElement(Core.Button, {
                                                                onClick: (function (param) {
                                                                    Curry._1(setModal, (function (param) {
                                                                            return React.createElement(Quota_PARC_Manage_Delete_Modal$DvmAdminFrontendFr.make, {
                                                                                        tableRow: quota,
                                                                                        onClose: (function (param) {
                                                                                            Curry._1(setModal, (function (param) {
                                                                                                    return null;
                                                                                                  }));
                                                                                          }),
                                                                                        fetchRequest: fetchRequest,
                                                                                        fetchData: fetchData
                                                                                      });
                                                                          }));
                                                                  }),
                                                                children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                      size: /* Large */3,
                                                                      type_: /* Trash */38,
                                                                      color: "red"
                                                                    }),
                                                                key: "delete"
                                                              }),
                                                          item: true
                                                        }))), undefined, Belt_List.mapWithIndex(row, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration(pageType), index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._2(fetchData, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var tmp = {
    headers: fetchRequest.headers,
    handleSorting: handleSorting,
    tablePage: convertTable(tablePage),
    paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined),
    exportMenu: React.createElement(Core.Grid, {
          children: null,
          container: true,
          item: true,
          justifyContent: "flex-end"
        }, pageType === /* Manage */1 ? React.createElement(Core.Grid, {
                children: React.createElement(App_Table_Create_Button$DvmAdminFrontendFr.make, {
                      onClick: (function (param) {
                          Curry._1(setModal, (function (param) {
                                  return React.createElement(Quota_PARC_Manage_Modal$DvmAdminFrontendFr.make, {
                                              onClose: (function (param) {
                                                  Curry._2(fetchData, undefined, fetchRequest);
                                                  Curry._1(setModal, (function (param) {
                                                          return null;
                                                        }));
                                                }),
                                              operation: /* Create */0,
                                              fetchRequest: fetchRequest,
                                              fetchData: fetchData
                                            });
                                }));
                        }),
                      message: Messages_Quotas_PARC$DvmAdminFrontendFr.createQuotaButton
                    }),
                item: true
              }) : null, React.createElement(Core.Grid, {
              children: React.createElement(App_Table_Export_Menu$DvmAdminFrontendFr.make, {
                    query: Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                          page: 0,
                          rowsPerPage: 150000,
                          headers: fetchRequest.headers,
                          filterQueryParams: fetchRequest.filterQueryParams,
                          additionalQueryParams: fetchRequest.additionalQueryParams
                        }),
                    endpoint: pageType ? "parc/quotas/settings" : "parc/quotas"
                  }),
              item: true
            }))
  };
  var tmp$1 = pageType === /* Manage */1 ? ({
        TAG: /* Left */0,
        _0: /* EmptyCell */0
      }) : undefined;
  if (tmp$1 !== undefined) {
    tmp.rowButton = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, tmp), match[0]);
}

var make = Quota_PARC_Search_Table;

export {
  headersConfiguration ,
  make ,
}
/* react Not a pure module */
