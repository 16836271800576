// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/core/styles";

function checkboxLight(theme, hideClickBox) {
  var tmp = {
    color: theme.palette.primary.main
  };
  var tmp$1 = hideClickBox ? "none" : undefined;
  if (tmp$1 !== undefined) {
    tmp.display = tmp$1;
  }
  return Css.css(tmp);
}

function checkboxDark(theme, hideClickBox) {
  var tmp = {
    color: theme.palette.common.white
  };
  var tmp$1 = hideClickBox ? "none" : undefined;
  if (tmp$1 !== undefined) {
    tmp.display = tmp$1;
  }
  return Css.css(tmp);
}

function App_Checkbox(Props) {
  var checked = Props.checked;
  var onClick = Props.onClick;
  var modeOpt = Props.mode;
  var disabled = Props.disabled;
  var disableTouchRipple = Props.disableTouchRipple;
  var indeterminate = Props.indeterminate;
  var tabIndex = Props.tabIndex;
  var disableRipple = Props.disableRipple;
  var id = Props.id;
  var className = Props.className;
  var value = Props.value;
  var hideClickBoxOpt = Props.hideClickBox;
  var mode = modeOpt !== undefined ? modeOpt : /* Light */0;
  var hideClickBox = hideClickBoxOpt !== undefined ? hideClickBoxOpt : false;
  var theme = Styles.useTheme();
  var tmp = {
    color: "default"
  };
  if (disableTouchRipple !== undefined) {
    tmp.disableTouchRipple = disableTouchRipple;
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (tabIndex !== undefined) {
    tmp.tabIndex = Caml_option.valFromOption(tabIndex);
  }
  if (className !== undefined) {
    tmp.className = className;
  }
  if (checked !== undefined) {
    tmp.checked = checked;
  }
  var tmp$1 = mode ? ({
        root: checkboxDark(theme, hideClickBox),
        checked: checkboxDark(theme, hideClickBox)
      }) : ({
        root: checkboxLight(theme, hideClickBox),
        checked: checkboxLight(theme, hideClickBox)
      });
  if (tmp$1 !== undefined) {
    tmp.classes = Caml_option.valFromOption(tmp$1);
  }
  if (disabled !== undefined) {
    tmp.disabled = disabled;
  }
  if (disableRipple !== undefined) {
    tmp.disableRipple = disableRipple;
  }
  if (id !== undefined) {
    tmp.id = id;
  }
  if (indeterminate !== undefined) {
    tmp.indeterminate = indeterminate;
  }
  if (value !== undefined) {
    tmp.value = Caml_option.valFromOption(value);
  }
  return React.createElement(Core.Checkbox, tmp);
}

var make = App_Checkbox;

export {
  checkboxLight ,
  checkboxDark ,
  make ,
}
/* react Not a pure module */
