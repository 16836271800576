// Generated by ReScript, PLEASE EDIT WITH CARE


var pageEditTitle = {
  id: "2638db67-8979-48f9-88ae-6e38a23175ab",
  defaultMessage: "Modification des SIREN virtuels"
};

var pageViewTitle = {
  id: "6baa3bbf-c19f-4ece-9474-d24925e27742",
  defaultMessage: "SIREN virtuels"
};

var editModalTitle = {
  id: "c3651834-c339-4e29-912e-fded363512ba",
  defaultMessage: "Modifier un SIREN virtuel"
};

var createTitle = {
  id: "9841f410-a9ad-4134-b381-161765d1a324",
  defaultMessage: "Créer un nouveau SIREN virtuel"
};

var budgetOne = {
  id: "2421e9ca-d14d-4c46-a18f-7007b151b056",
  defaultMessage: "Budget 1"
};

var budgetTwo = {
  id: "2c742f1f-4d16-49c3-bc86-4f72c4dd3e24",
  defaultMessage: "Budget 2"
};

var addToContract = {
  id: "a3e6cb4c-0d2a-4de4-8178-668cdf161cea",
  defaultMessage: "Ajouter au protocole"
};

var contractPeriod = {
  id: "e7333ac2-236f-42f6-8318-5d01c85a708e",
  defaultMessage: "Période d'application"
};

var virtualSiren = {
  id: "bc42e481-ad05-4787-8f40-38323d25cb27",
  defaultMessage: "SIREN virtuel"
};

export {
  pageEditTitle ,
  pageViewTitle ,
  editModalTitle ,
  createTitle ,
  budgetOne ,
  budgetTwo ,
  addToContract ,
  contractPeriod ,
  virtualSiren ,
}
/* No side effect */
