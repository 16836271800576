// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../common/types/App_Types_Date.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../../common/types/App_Types_Input.bs.js";

var FilterFields = {
  brandDescription: "BrandDescription",
  model: "model",
  version: "version",
  modelLabel: "modelLabel",
  versionLabel: "versionLabel",
  motorization: "IdentificationEngineType"
};

var Fields = {
  model: "model",
  modelLabel: "modelLabel",
  tariff: "tariff",
  modelVehicleType: "modelVehicleType",
  modelVehiclePhase: "modelPhase",
  modelActive: "modelActive"
};

var FieldsVersion = {
  model: "model",
  modelLabel: "modelLabel",
  version: "version",
  versionLabel: "versionLabel",
  tariff: "tariff",
  versionVehicleType: "versionVehicleType",
  versionVehiclePhase: "versionPhase",
  versionActive: "versionActive",
  identificationEngineType: "identificationEngineType",
  additionDate: "additionDate"
};

function modelRow_encode(v) {
  return Js_dict.fromArray([
              [
                "model",
                Decco.stringToJson(v.model)
              ],
              [
                "modelLabel",
                Decco.stringToJson(v.modelLabel)
              ],
              [
                "tariff",
                Decco.optionToJson(Decco.stringToJson, v.tariff)
              ],
              [
                "modelVehicleType",
                Decco.optionToJson(Decco.stringToJson, v.modelVehicleType)
              ],
              [
                "modelVehiclePhase",
                Decco.optionToJson(Decco.stringToJson, v.modelVehiclePhase)
              ],
              [
                "modelActive",
                Decco.optionToJson(Decco.boolToJson, v.modelActive)
              ]
            ]);
}

function modelRow_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var model = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "model"), null));
  if (model.TAG === /* Ok */0) {
    var modelLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelLabel"), null));
    if (modelLabel.TAG === /* Ok */0) {
      var tariff = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "tariff"), null));
      if (tariff.TAG === /* Ok */0) {
        var modelVehicleType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelVehicleType"), null));
        if (modelVehicleType.TAG === /* Ok */0) {
          var modelVehiclePhase = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelVehiclePhase"), null));
          if (modelVehiclePhase.TAG === /* Ok */0) {
            var modelActive = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelActive"), null));
            if (modelActive.TAG === /* Ok */0) {
              return {
                      TAG: /* Ok */0,
                      _0: {
                        model: model._0,
                        modelLabel: modelLabel._0,
                        tariff: tariff._0,
                        modelVehicleType: modelVehicleType._0,
                        modelVehiclePhase: modelVehiclePhase._0,
                        modelActive: modelActive._0
                      }
                    };
            }
            var e = modelActive._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".modelActive" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = modelVehiclePhase._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".modelVehiclePhase" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = modelVehicleType._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".modelVehicleType" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = tariff._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".tariff" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = modelLabel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".modelLabel" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = model._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".model" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(modelRow_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(modelRow_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var Model = {
  modelRow_encode: modelRow_encode,
  modelRow_decode: modelRow_decode,
  searchResult_encode: searchResult_encode,
  searchResult_decode: searchResult_decode
};

function versionRow_encode(v) {
  return Js_dict.fromArray([
              [
                "model",
                Decco.stringToJson(v.model)
              ],
              [
                "modelLabel",
                Decco.stringToJson(v.modelLabel)
              ],
              [
                "version",
                Decco.stringToJson(v.version)
              ],
              [
                "versionLabel",
                Decco.stringToJson(v.versionLabel)
              ],
              [
                "tariff",
                Decco.optionToJson(Decco.stringToJson, v.tariff)
              ],
              [
                "versionVehicleType",
                Decco.optionToJson(Decco.stringToJson, v.versionVehicleType)
              ],
              [
                "versionVehiclePhase",
                Decco.optionToJson(Decco.stringToJson, v.versionVehiclePhase)
              ],
              [
                "versionActive",
                Decco.optionToJson(Decco.boolToJson, v.versionActive)
              ],
              [
                "identificationEngineType",
                Decco.optionToJson(Decco.stringToJson, v.identificationEngineType)
              ],
              [
                "modelVersionId",
                Decco.stringToJson(v.modelVersionId)
              ],
              [
                "additionDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.additionDate)
              ]
            ]);
}

function versionRow_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var model = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "model"), null));
  if (model.TAG === /* Ok */0) {
    var modelLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelLabel"), null));
    if (modelLabel.TAG === /* Ok */0) {
      var version = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "version"), null));
      if (version.TAG === /* Ok */0) {
        var versionLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "versionLabel"), null));
        if (versionLabel.TAG === /* Ok */0) {
          var tariff = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "tariff"), null));
          if (tariff.TAG === /* Ok */0) {
            var versionVehicleType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "versionVehicleType"), null));
            if (versionVehicleType.TAG === /* Ok */0) {
              var versionVehiclePhase = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "versionVehiclePhase"), null));
              if (versionVehiclePhase.TAG === /* Ok */0) {
                var versionActive = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "versionActive"), null));
                if (versionActive.TAG === /* Ok */0) {
                  var identificationEngineType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "identificationEngineType"), null));
                  if (identificationEngineType.TAG === /* Ok */0) {
                    var modelVersionId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelVersionId"), null));
                    if (modelVersionId.TAG === /* Ok */0) {
                      var additionDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "additionDate"), null));
                      if (additionDate.TAG === /* Ok */0) {
                        return {
                                TAG: /* Ok */0,
                                _0: {
                                  model: model._0,
                                  modelLabel: modelLabel._0,
                                  version: version._0,
                                  versionLabel: versionLabel._0,
                                  tariff: tariff._0,
                                  versionVehicleType: versionVehicleType._0,
                                  versionVehiclePhase: versionVehiclePhase._0,
                                  versionActive: versionActive._0,
                                  identificationEngineType: identificationEngineType._0,
                                  modelVersionId: modelVersionId._0,
                                  additionDate: additionDate._0
                                }
                              };
                      }
                      var e = additionDate._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".additionDate" + e.path,
                                message: e.message,
                                value: e.value
                              }
                            };
                    }
                    var e$1 = modelVersionId._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".modelVersionId" + e$1.path,
                              message: e$1.message,
                              value: e$1.value
                            }
                          };
                  }
                  var e$2 = identificationEngineType._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".identificationEngineType" + e$2.path,
                            message: e$2.message,
                            value: e$2.value
                          }
                        };
                }
                var e$3 = versionActive._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".versionActive" + e$3.path,
                          message: e$3.message,
                          value: e$3.value
                        }
                      };
              }
              var e$4 = versionVehiclePhase._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".versionVehiclePhase" + e$4.path,
                        message: e$4.message,
                        value: e$4.value
                      }
                    };
            }
            var e$5 = versionVehicleType._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".versionVehicleType" + e$5.path,
                      message: e$5.message,
                      value: e$5.value
                    }
                  };
          }
          var e$6 = tariff._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".tariff" + e$6.path,
                    message: e$6.message,
                    value: e$6.value
                  }
                };
        }
        var e$7 = versionLabel._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".versionLabel" + e$7.path,
                  message: e$7.message,
                  value: e$7.value
                }
              };
      }
      var e$8 = version._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".version" + e$8.path,
                message: e$8.message,
                value: e$8.value
              }
            };
    }
    var e$9 = modelLabel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".modelLabel" + e$9.path,
              message: e$9.message,
              value: e$9.value
            }
          };
  }
  var e$10 = model._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".model" + e$10.path,
            message: e$10.message,
            value: e$10.value
          }
        };
}

function searchResult_encode$1(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(versionRow_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode$1(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(versionRow_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var Version = {
  versionRow_encode: versionRow_encode,
  versionRow_decode: versionRow_decode,
  searchResult_encode: searchResult_encode$1,
  searchResult_decode: searchResult_decode$1
};

function parentChildList_encode(v) {
  return Decco.listToJson(App_Types_Input$DvmAdminFrontendFr.parentContent_encode, v);
}

function parentChildList_decode(v) {
  return Decco.listFromJson(App_Types_Input$DvmAdminFrontendFr.parentContent_decode, v);
}

function modelTypeApi_encode(v) {
  return Js_dict.fromArray([
              [
                "modelCode",
                Decco.stringToJson(v.modelCode)
              ],
              [
                "phase",
                Decco.stringToJson(v.phase)
              ]
            ]);
}

function modelTypeApi_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var modelCode = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelCode"), null));
  if (modelCode.TAG === /* Ok */0) {
    var phase = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "phase"), null));
    if (phase.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                modelCode: modelCode._0,
                phase: phase._0
              }
            };
    }
    var e = phase._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".phase" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = modelCode._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".modelCode" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var ModelActivate = {
  modelTypeApi_encode: modelTypeApi_encode,
  modelTypeApi_decode: modelTypeApi_decode
};

function versionTypeApi_encode(v) {
  return Js_dict.fromArray([
              [
                "modelCode",
                Decco.stringToJson(v.modelCode)
              ],
              [
                "versionCode",
                Decco.stringToJson(v.versionCode)
              ],
              [
                "phase",
                Decco.stringToJson(v.phase)
              ]
            ]);
}

function versionTypeApi_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var modelCode = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelCode"), null));
  if (modelCode.TAG === /* Ok */0) {
    var versionCode = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "versionCode"), null));
    if (versionCode.TAG === /* Ok */0) {
      var phase = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "phase"), null));
      if (phase.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  modelCode: modelCode._0,
                  versionCode: versionCode._0,
                  phase: phase._0
                }
              };
      }
      var e = phase._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".phase" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = versionCode._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".versionCode" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = modelCode._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".modelCode" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var VersionActivate = {
  versionTypeApi_encode: versionTypeApi_encode,
  versionTypeApi_decode: versionTypeApi_decode
};

function versionArray_encode(v) {
  return Decco.arrayToJson(versionTypeApi_encode, v);
}

function versionArray_decode(v) {
  return Decco.arrayFromJson(versionTypeApi_decode, v);
}

function modelArray_encode(v) {
  return Decco.arrayToJson(modelTypeApi_encode, v);
}

function modelArray_decode(v) {
  return Decco.arrayFromJson(modelTypeApi_decode, v);
}

export {
  FilterFields ,
  Fields ,
  FieldsVersion ,
  Model ,
  Version ,
  parentChildList_encode ,
  parentChildList_decode ,
  ModelActivate ,
  VersionActivate ,
  versionArray_encode ,
  versionArray_decode ,
  modelArray_encode ,
  modelArray_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
