// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReDate_utils from "@mobily/rescript-date/src/ReDate_utils.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../../../../../common/types/App_Types_Date.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../../../../../../intl/messages/Messages_Error_Inputs.bs.js";

function validateEditEndDate(date, flashStartDate, flashEndDate, highestStartDate) {
  if (flashStartDate === undefined) {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.datesHaveIssue
          };
  }
  if (flashEndDate === undefined) {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.datesHaveIssue
          };
  }
  var end = Caml_option.valFromOption(flashEndDate);
  var start = Caml_option.valFromOption(flashStartDate);
  var match = App_Types_Date$DvmAdminFrontendFr.isValid(start);
  var match$1 = App_Types_Date$DvmAdminFrontendFr.isValid(end);
  if (match && match$1) {
    return Belt_Option.mapWithDefault(date, {
                TAG: /* Error */1,
                _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
              }, (function (date) {
                  if (!App_Types_Date$DvmAdminFrontendFr.isValid(date)) {
                    return {
                            TAG: /* Error */1,
                            _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                          };
                  }
                  if (!(ReDate_utils.makeStartOfDayDate(start).valueOf() <= ReDate_utils.makeStartOfDayDate(date).valueOf() && ReDate_utils.makeStartOfDayDate(end).valueOf() >= ReDate_utils.makeStartOfDayDate(date).valueOf())) {
                    return {
                            TAG: /* Error */1,
                            _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateOutOfPeriod
                          };
                  }
                  if (highestStartDate === undefined) {
                    return {
                            TAG: /* Ok */0,
                            _0: Caml_option.some(date)
                          };
                  }
                  var highestStart = Caml_option.valFromOption(highestStartDate);
                  if (App_Types_Date$DvmAdminFrontendFr.isValid(highestStart)) {
                    if (ReDate_utils.makeStartOfDayDate(date).valueOf() > ReDate_utils.makeStartOfDayDate(highestStart).valueOf()) {
                      return {
                              TAG: /* Ok */0,
                              _0: Caml_option.some(date)
                            };
                    } else {
                      return {
                              TAG: /* Error */1,
                              _0: Messages_Flash_Edit$DvmAdminFrontendFr.highestStartDateError
                            };
                    }
                  } else {
                    return {
                            TAG: /* Error */1,
                            _0: Messages_Error_Inputs$DvmAdminFrontendFr.datesHaveIssue
                          };
                  }
                }));
  } else {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.datesHaveIssue
          };
  }
}

function validateNewPeriod(date, flashStartDate, flashEndDate, highestStartDate) {
  if (flashStartDate === undefined) {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.datesHaveIssue
          };
  }
  if (flashEndDate === undefined) {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.datesHaveIssue
          };
  }
  var end = Caml_option.valFromOption(flashEndDate);
  var start = Caml_option.valFromOption(flashStartDate);
  var match = App_Types_Date$DvmAdminFrontendFr.isValid(start);
  var match$1 = App_Types_Date$DvmAdminFrontendFr.isValid(end);
  if (match && match$1) {
    return Belt_Option.mapWithDefault(date, {
                TAG: /* Error */1,
                _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
              }, (function (date) {
                  if (!App_Types_Date$DvmAdminFrontendFr.isValid(date)) {
                    return {
                            TAG: /* Error */1,
                            _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                          };
                  }
                  if (!(ReDate_utils.makeStartOfDayDate(start).valueOf() <= ReDate_utils.makeStartOfDayDate(date).valueOf() && ReDate_utils.makeStartOfDayDate(end).valueOf() >= ReDate_utils.makeStartOfDayDate(date).valueOf())) {
                    return {
                            TAG: /* Error */1,
                            _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateOutOfPeriod
                          };
                  }
                  if (highestStartDate === undefined) {
                    return {
                            TAG: /* Ok */0,
                            _0: Caml_option.some(date)
                          };
                  }
                  var highestStartDate$1 = Caml_option.valFromOption(highestStartDate);
                  if (App_Types_Date$DvmAdminFrontendFr.isValid(highestStartDate$1)) {
                    if (ReDate_utils.makeStartOfDayDate(date).valueOf() > ReDate_utils.makeStartOfDayDate(highestStartDate$1).valueOf()) {
                      return {
                              TAG: /* Ok */0,
                              _0: Caml_option.some(date)
                            };
                    } else {
                      return {
                              TAG: /* Error */1,
                              _0: Messages_Flash_Edit$DvmAdminFrontendFr.lowestStartNewPeriodError
                            };
                    }
                  } else {
                    return {
                            TAG: /* Error */1,
                            _0: Messages_Error_Inputs$DvmAdminFrontendFr.datesHaveIssue
                          };
                  }
                }));
  } else {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.datesHaveIssue
          };
  }
}

export {
  validateEditEndDate ,
  validateNewPeriod ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
