// Generated by ReScript, PLEASE EDIT WITH CARE


var title = {
  id: "b07c843b-52f4-4cfe-9670-22621c8bfc8a",
  defaultMessage: "Rechercher une société"
};

var resultInfo = {
  id: "29f16d90-9cff-4751-96b1-09ef97d64ad0",
  defaultMessage: "sociétés"
};

var active = {
  id: "ac250568-4a61-4220-aee0-9833e5540fea",
  defaultMessage: "Actif"
};

var activeDateProblem = {
  id: "7ccdfaee-a153-4b67-96e1-37e86c9b86e3",
  defaultMessage: "Incoherence entre le statut de la société et la date de fin"
};

var endDateBy = {
  id: "43f49232-e3f0-426d-a666-74bcb97bfefc",
  defaultMessage: "Date de fin"
};

export {
  title ,
  resultInfo ,
  active ,
  activeDateProblem ,
  endDateBy ,
}
/* No side effect */
