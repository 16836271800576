// Generated by ReScript, PLEASE EDIT WITH CARE


var derogateTitle = {
  id: "be8894dd-960e-490f-91b1-ece98c266534",
  defaultMessage: "Accord spécifique de la commande {id}"
};

var bypassButton = {
  id: "8850415e-8cfa-48e3-880b-21b4c46fd1ae",
  defaultMessage: "Accord spécifique"
};

var discountPercentTitle = {
  id: "28d8ecdb-5970-4f7a-8ea0-3add88257084",
  defaultMessage: "% Accordé"
};

var discountCondiditonGridTitle = {
  id: "8f82208a-c69e-49ca-b31c-8afe5971e3dd",
  defaultMessage: "% Grille conditions"
};

export {
  derogateTitle ,
  bypassButton ,
  discountPercentTitle ,
  discountCondiditonGridTitle ,
}
/* No side effect */
