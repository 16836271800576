// Generated by ReScript, PLEASE EDIT WITH CARE


var title = {
  id: "6e35ccca-5a9d-40ba-b409-4431d92e5f76",
  defaultMessage: "Gestion des Code barème financement"
};

var updateTitle = {
  id: "d2cd1c94-1ada-465e-9c13-006ff4bae9e7",
  defaultMessage: "Modifier un code barème financement"
};

var resultInfo = {
  id: "96831390-511b-45e7-ac56-a8d750a62d3d",
  defaultMessage: "codes barème financement"
};

var codeId = {
  id: "51bfa7d3-daa9-4270-9ce3-017e6ce97d64",
  defaultMessage: "Code barème financement"
};

var codeLabel = {
  id: "42dd8ea0-86fd-46c3-a924-347030e808a0",
  defaultMessage: "Libellé Code barème financement"
};

var deleteTitle = {
  id: "109c3e90-de4f-4db8-a9a8-1789c4f42e4b",
  defaultMessage: "Supprimer un code barème financement"
};

var deleteMessage = {
  id: "b44bc3c0-0293-43ed-ab72-632581e680aa",
  defaultMessage: "Etes vous certain de vouloir supprimer ce code barème financement?"
};

var newFinancialCode = {
  id: "a341b408-5dd4-4d95-811e-f4dead165144",
  defaultMessage: "Créer un nouveau code barème financement"
};

export {
  title ,
  updateTitle ,
  resultInfo ,
  codeId ,
  codeLabel ,
  deleteTitle ,
  deleteMessage ,
  newFinancialCode ,
}
/* No side effect */
