// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as RestApi$DvmAdminFrontendFr from "../../../../api/RestApi.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as Quota_Detail_Info_Types$DvmAdminFrontendFr from "./Quota_Detail_Info_Types.bs.js";

function fetchQuotaDetailInfo(alert, userManager, setResult, stringQuery, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Quota_Detail_Info_Types$DvmAdminFrontendFr.quotaDetail_decode, undefined, userManager, selectedRole, "/quotas?" + stringQuery + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var data = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: data
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function saveQuotaDetailInfo(alert, userManager, setResult, submissionCallbacks, update, selectedRole, detailInfo) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call(update ? "PUT" : "POST", {
          TAG: /* Json */0,
          _0: Quota_Detail_Info_Types$DvmAdminFrontendFr.quotaDetail_encode(detailInfo)
        }, userManager, selectedRole, "/quotas").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                    message: {
                      TAG: /* Message */0,
                      _0: Messages_Quotas_Detail$DvmAdminFrontendFr.quotaSavedMessage
                    }
                  }));
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: detailInfo
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToSaveQuotaDetailInfo */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

export {
  Form ,
  fetchQuotaDetailInfo ,
  saveQuotaDetailInfo ,
}
/* react Not a pure module */
