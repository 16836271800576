// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as Category_Router$DvmAdminFrontendFr from "../Category_Router.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Messages_Category$DvmAdminFrontendFr from "../../../intl/messages/category/Messages_Category.bs.js";
import * as Category_Search_List$DvmAdminFrontendFr from "./Category_Search_List.bs.js";
import * as Category_Search_Filter$DvmAdminFrontendFr from "./Category_Search_Filter.bs.js";
import * as App_Table_Create_Button$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Create_Button.bs.js";

function Category_Search(Props) {
  var manage = Props.manage;
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var categories = match[0];
  var match$1 = React.useState(function () {
        
      });
  var intl = ReactIntl.useIntl();
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              direction: "column",
              spacing: 3
            }, React.createElement(Core.Grid, {
                  children: React.createElement(Core.Typography, {
                        children: intl.formatMessage(Messages_Category$DvmAdminFrontendFr.searchCategoryHeader),
                        variant: "h3",
                        style: {
                          fontWeight: "600"
                        }
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Category_Search_Filter$DvmAdminFrontendFr.make, {
                        setResult: match[1],
                        setSelectedModel: match$1[1]
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  item: true
                }, manage ? React.createElement(Core.Grid, {
                        children: React.createElement(App_Table_Create_Button$DvmAdminFrontendFr.make, {
                              onClick: (function (param) {
                                  Category_Router$DvmAdminFrontendFr.routeToEditPage(undefined, undefined, undefined);
                                }),
                              message: Messages_Category$DvmAdminFrontendFr.createNewCategory
                            }),
                        container: true,
                        item: true,
                        justifyContent: "flex-end"
                      }) : null, React.createElement(Core.Grid, {
                      children: React.createElement(Category_Search_List$DvmAdminFrontendFr.make, {
                            categories: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(categories, /* [] */0, (function (param) {
                                    return param.content;
                                  })),
                            isPending: App_Types_Result$DvmAdminFrontendFr.isPending(categories),
                            selectedModel: match$1[0],
                            manage: manage
                          }),
                      container: true,
                      item: true
                    })));
}

var Filter;

var List;

var make = Category_Search;

export {
  Filter ,
  List ,
  make ,
}
/* react Not a pure module */
