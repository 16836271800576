// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Button$DvmAdminFrontendFr from "../../../../../common/components/button/App_Button.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../../../common/components/App_Checkbox.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as App_DoubleSelectFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_DoubleSelectFormInput.bs.js";
import * as Flash_Edit_Fleet_Version_Form$DvmAdminFrontendFr from "./Flash_Edit_Fleet_Version_Form.bs.js";
import * as Flash_Edit_Version_Perf_New_Modal$DvmAdminFrontendFr from "./modalPerf/editAdd/perfNew/Flash_Edit_Version_Perf_New_Modal.bs.js";
import * as Flash_Edit_Fleet_Version_Add_Modal$DvmAdminFrontendFr from "./modal/Flash_Edit_Fleet_Version_Add_Modal.bs.js";

function Flash_Edit_Fleet_Version_Filter(Props) {
  var flash = Props.flash;
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var refreshVersions = Props.refreshVersions;
  var actionDistinctVersions = Props.actionDistinctVersions;
  var actionType = Props.actionType;
  var modelVersions = Props.modelVersions;
  var fetchUpdatedModelsForDropdownAndCumulables = Props.fetchUpdatedModelsForDropdownAndCumulables;
  var withClosedModels = Props.withClosedModels;
  var setWithClosedModels = Props.setWithClosedModels;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return null;
      });
  var setModal = match[1];
  var form = Flash_Edit_Fleet_Version_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Flash_Edit_Fleet_Version_Form$DvmAdminFrontendFr.initialState, (function (param, submissionCallbacks) {
          Curry._2(fetchData, submissionCallbacks, {
                page: 0,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: fetchRequest.headers,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                              hd: param.brand,
                              tl: {
                                hd: param.versionName,
                                tl: {
                                  hd: param.model,
                                  tl: {
                                    hd: param.version,
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  var tmp = {
    onChange: Curry._1(form.updateVersionName, (function (input, versionName) {
            return {
                    brand: input.brand,
                    versionName: versionName,
                    model: input.model,
                    version: input.version
                  };
          })),
    value: form.input.versionName,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Flash_Edit$DvmAdminFrontendFr.modelVersionSearch
  };
  if (form.versionNameResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.versionNameResult);
  }
  return React.createElement(React.Fragment, undefined, React.createElement("form", {
                  onSubmit: (function ($$event) {
                      $$event.preventDefault();
                      Curry._1(form.submit, undefined);
                    })
                }, React.createElement(Core.Grid, {
                      children: null,
                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                      container: true
                    }, React.createElement(Core.Grid, {
                          alignItems: "flex-start",
                          children: null,
                          container: true,
                          item: true,
                          justifyContent: "flex-start",
                          sm: Grid$Mui.Sm[9],
                          spacing: 2
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                    show: App_Types_Result$DvmAdminFrontendFr.isPending(modelVersions),
                                    children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                          label: Messages_Common$DvmAdminFrontendFr.brand,
                                          value: {
                                            TAG: /* Multiple */1,
                                            _0: {
                                              value: form.input.brand,
                                              onChange: Curry._1(form.updateBrand, (function (input, brand) {
                                                      return {
                                                              brand: brand,
                                                              versionName: input.versionName,
                                                              model: input.model,
                                                              version: input.version
                                                            };
                                                    }))
                                            }
                                          },
                                          options: {
                                            TAG: /* Unlabeled */0,
                                            _0: {
                                              hd: "RENAULT",
                                              tl: {
                                                hd: "ALPINE",
                                                tl: {
                                                  hd: "DACIA",
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          },
                                          className: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInput,
                                          smallChips: true
                                        })
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                    show: App_Types_Result$DvmAdminFrontendFr.isPending(modelVersions),
                                    children: React.createElement(Core.Grid, {
                                          children: null,
                                          container: true,
                                          direction: "column",
                                          item: true,
                                          style: {
                                            width: "fit-content"
                                          }
                                        }, React.createElement(Core.Grid, {
                                              children: React.createElement(App_DoubleSelectFormInput$DvmAdminFrontendFr.make, {
                                                    labelParent: Messages_Common$DvmAdminFrontendFr.model,
                                                    labelChild: Messages_Common$DvmAdminFrontendFr.version,
                                                    valueParent: {
                                                      TAG: /* Multiple */1,
                                                      _0: {
                                                        value: form.input.model,
                                                        onChange: Curry._1(form.updateModel, (function (input, model) {
                                                                return {
                                                                        brand: input.brand,
                                                                        versionName: input.versionName,
                                                                        model: model,
                                                                        version: input.version
                                                                      };
                                                              }))
                                                      }
                                                    },
                                                    valueChild: {
                                                      TAG: /* Multiple */1,
                                                      _0: {
                                                        value: form.input.version,
                                                        onChange: Curry._1(form.updateVersion, (function (input, version) {
                                                                return {
                                                                        brand: input.brand,
                                                                        versionName: input.versionName,
                                                                        model: input.model,
                                                                        version: version
                                                                      };
                                                              }))
                                                      }
                                                    },
                                                    options: App_Types_Result$DvmAdminFrontendFr.getWithDefault(modelVersions, /* [] */0),
                                                    classNameParent: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInput,
                                                    classNameChild: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInput,
                                                    limitTagsParent: 2,
                                                    limitTagsChild: 2
                                                  }),
                                              item: true
                                            }), React.createElement(Core.Grid, {
                                              children: React.createElement(Core.FormControlLabel, {
                                                    classes: {
                                                      label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                                    },
                                                    control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                                          checked: withClosedModels,
                                                          onClick: (function ($$event) {
                                                              Curry._1(setWithClosedModels, (function (param) {
                                                                      return $$event.target.checked;
                                                                    }));
                                                            })
                                                        }),
                                                    label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.includeClosedCars)
                                                  }),
                                              item: true,
                                              style: {
                                                whiteSpace: "nowrap",
                                                width: "1px"
                                              }
                                            }))
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                                    label: "OK",
                                    type_: "submit"
                                  }),
                              item: true
                            })), React.createElement(Core.Grid, {
                          children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                onClick: (function (param) {
                                    Curry._1(setModal, (function (param) {
                                            if (actionType === /* PERF */1) {
                                              return React.createElement(Flash_Edit_Version_Perf_New_Modal$DvmAdminFrontendFr.make, {
                                                          flash: flash,
                                                          onClose: (function (param) {
                                                              Curry._1(setModal, (function (param) {
                                                                      return null;
                                                                    }));
                                                            }),
                                                          onOk: (function (param) {
                                                              Curry._1(setModal, (function (param) {
                                                                      return null;
                                                                    }));
                                                              Curry._2(fetchData, undefined, fetchRequest);
                                                              Curry._1(refreshVersions, undefined);
                                                            }),
                                                          actionDistinctVersions: actionDistinctVersions,
                                                          fetchUpdatedModelsForDropdownAndCumulables: fetchUpdatedModelsForDropdownAndCumulables
                                                        });
                                            } else {
                                              return React.createElement(Flash_Edit_Fleet_Version_Add_Modal$DvmAdminFrontendFr.make, {
                                                          flash: flash,
                                                          onClose: (function (param) {
                                                              Curry._1(setModal, (function (param) {
                                                                      return null;
                                                                    }));
                                                            }),
                                                          onOk: (function (param) {
                                                              Curry._1(setModal, (function (param) {
                                                                      return null;
                                                                    }));
                                                              Curry._2(fetchData, undefined, fetchRequest);
                                                              Curry._1(refreshVersions, undefined);
                                                            }),
                                                          actionDistinctVersions: actionDistinctVersions,
                                                          fetchUpdatedModelsForDropdownAndCumulables: fetchUpdatedModelsForDropdownAndCumulables,
                                                          actionType: actionType
                                                        });
                                            }
                                          }));
                                  }),
                                label: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.addModel),
                                color: "secondary",
                                size: "medium",
                                type_: "button",
                                style: {
                                  maxHeight: "40px"
                                }
                              }),
                          container: true,
                          item: true,
                          justifyContent: "flex-end",
                          sm: Grid$Mui.Sm[3]
                        }))), match[0]);
}

var Form;

var Types;

var InputStyles;

var make = Flash_Edit_Fleet_Version_Filter;

export {
  Form ,
  Types ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
