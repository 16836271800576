// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";

var Fields = {
  siret: "siret",
  siren: "siren",
  name: "name",
  street: "street",
  streetNbr: "streetNbr",
  additionalAddressInfo: "additionalAddressInfo",
  zipCode: "zipCode",
  city: "city",
  countryCode: "countryCode",
  importDate: "importDate",
  budgetLLD: "budgetLLD",
  budgetLCD: "budgetLCD",
  budgetAVS: "budgetAVS",
  budgetPROT: "budgetPROT",
  hierarchy: "hierarchy",
  createDate: "createDate",
  codeApe: "codeApe",
  virtual: "virtual",
  endDate: "endDate"
};

function row_encode(v) {
  return Js_dict.fromArray([
              [
                "siret",
                Decco.stringToJson(v.siret)
              ],
              [
                "siren",
                Decco.optionToJson(Decco.stringToJson, v.siren)
              ],
              [
                "name",
                Decco.optionToJson(Decco.stringToJson, v.name)
              ],
              [
                "street",
                Decco.optionToJson(Decco.stringToJson, v.street)
              ],
              [
                "streetNbr",
                Decco.optionToJson(Decco.stringToJson, v.streetNbr)
              ],
              [
                "additionalAddressInfo",
                Decco.optionToJson(Decco.stringToJson, v.additionalAddressInfo)
              ],
              [
                "zipCode",
                Decco.optionToJson(Decco.stringToJson, v.zipCode)
              ],
              [
                "city",
                Decco.optionToJson(Decco.stringToJson, v.city)
              ],
              [
                "countryCode",
                Decco.optionToJson(Decco.stringToJson, v.countryCode)
              ],
              [
                "importDate",
                Decco.optionToJson(Decco.stringToJson, v.importDate)
              ],
              [
                "budgetLLD",
                Decco.optionToJson(Decco.boolToJson, v.budgetLLD)
              ],
              [
                "budgetLCD",
                Decco.optionToJson(Decco.boolToJson, v.budgetLCD)
              ],
              [
                "budgetAVS",
                Decco.optionToJson(Decco.boolToJson, v.budgetAVS)
              ],
              [
                "budgetPROT",
                Decco.optionToJson(Decco.boolToJson, v.budgetPROT)
              ],
              [
                "hierarchy",
                Decco.optionToJson(Decco.stringToJson, v.hierarchy)
              ],
              [
                "createDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.createDate)
              ],
              [
                "codeApe",
                Decco.optionToJson(Decco.stringToJson, v.codeApe)
              ],
              [
                "virtual",
                Decco.optionToJson(Decco.boolToJson, v.virtual)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function row_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var siret = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "siret"), null));
  if (siret.TAG === /* Ok */0) {
    var siren = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "siren"), null));
    if (siren.TAG === /* Ok */0) {
      var name = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "name"), null));
      if (name.TAG === /* Ok */0) {
        var street = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "street"), null));
        if (street.TAG === /* Ok */0) {
          var streetNbr = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "streetNbr"), null));
          if (streetNbr.TAG === /* Ok */0) {
            var additionalAddressInfo = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "additionalAddressInfo"), null));
            if (additionalAddressInfo.TAG === /* Ok */0) {
              var zipCode = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "zipCode"), null));
              if (zipCode.TAG === /* Ok */0) {
                var city = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "city"), null));
                if (city.TAG === /* Ok */0) {
                  var countryCode = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "countryCode"), null));
                  if (countryCode.TAG === /* Ok */0) {
                    var importDate = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "importDate"), null));
                    if (importDate.TAG === /* Ok */0) {
                      var budgetLLD = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "budgetLLD"), null));
                      if (budgetLLD.TAG === /* Ok */0) {
                        var budgetLCD = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "budgetLCD"), null));
                        if (budgetLCD.TAG === /* Ok */0) {
                          var budgetAVS = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "budgetAVS"), null));
                          if (budgetAVS.TAG === /* Ok */0) {
                            var budgetPROT = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "budgetPROT"), null));
                            if (budgetPROT.TAG === /* Ok */0) {
                              var hierarchy = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "hierarchy"), null));
                              if (hierarchy.TAG === /* Ok */0) {
                                var createDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "createDate"), null));
                                if (createDate.TAG === /* Ok */0) {
                                  var codeApe = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "codeApe"), null));
                                  if (codeApe.TAG === /* Ok */0) {
                                    var virtual = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "virtual"), null));
                                    if (virtual.TAG === /* Ok */0) {
                                      var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
                                      if (endDate.TAG === /* Ok */0) {
                                        return {
                                                TAG: /* Ok */0,
                                                _0: {
                                                  siret: siret._0,
                                                  siren: siren._0,
                                                  name: name._0,
                                                  street: street._0,
                                                  streetNbr: streetNbr._0,
                                                  additionalAddressInfo: additionalAddressInfo._0,
                                                  zipCode: zipCode._0,
                                                  city: city._0,
                                                  countryCode: countryCode._0,
                                                  importDate: importDate._0,
                                                  budgetLLD: budgetLLD._0,
                                                  budgetLCD: budgetLCD._0,
                                                  budgetAVS: budgetAVS._0,
                                                  budgetPROT: budgetPROT._0,
                                                  hierarchy: hierarchy._0,
                                                  createDate: createDate._0,
                                                  codeApe: codeApe._0,
                                                  virtual: virtual._0,
                                                  endDate: endDate._0
                                                }
                                              };
                                      }
                                      var e = endDate._0;
                                      return {
                                              TAG: /* Error */1,
                                              _0: {
                                                path: ".endDate" + e.path,
                                                message: e.message,
                                                value: e.value
                                              }
                                            };
                                    }
                                    var e$1 = virtual._0;
                                    return {
                                            TAG: /* Error */1,
                                            _0: {
                                              path: ".virtual" + e$1.path,
                                              message: e$1.message,
                                              value: e$1.value
                                            }
                                          };
                                  }
                                  var e$2 = codeApe._0;
                                  return {
                                          TAG: /* Error */1,
                                          _0: {
                                            path: ".codeApe" + e$2.path,
                                            message: e$2.message,
                                            value: e$2.value
                                          }
                                        };
                                }
                                var e$3 = createDate._0;
                                return {
                                        TAG: /* Error */1,
                                        _0: {
                                          path: ".createDate" + e$3.path,
                                          message: e$3.message,
                                          value: e$3.value
                                        }
                                      };
                              }
                              var e$4 = hierarchy._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".hierarchy" + e$4.path,
                                        message: e$4.message,
                                        value: e$4.value
                                      }
                                    };
                            }
                            var e$5 = budgetPROT._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".budgetPROT" + e$5.path,
                                      message: e$5.message,
                                      value: e$5.value
                                    }
                                  };
                          }
                          var e$6 = budgetAVS._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".budgetAVS" + e$6.path,
                                    message: e$6.message,
                                    value: e$6.value
                                  }
                                };
                        }
                        var e$7 = budgetLCD._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".budgetLCD" + e$7.path,
                                  message: e$7.message,
                                  value: e$7.value
                                }
                              };
                      }
                      var e$8 = budgetLLD._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".budgetLLD" + e$8.path,
                                message: e$8.message,
                                value: e$8.value
                              }
                            };
                    }
                    var e$9 = importDate._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".importDate" + e$9.path,
                              message: e$9.message,
                              value: e$9.value
                            }
                          };
                  }
                  var e$10 = countryCode._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".countryCode" + e$10.path,
                            message: e$10.message,
                            value: e$10.value
                          }
                        };
                }
                var e$11 = city._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".city" + e$11.path,
                          message: e$11.message,
                          value: e$11.value
                        }
                      };
              }
              var e$12 = zipCode._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".zipCode" + e$12.path,
                        message: e$12.message,
                        value: e$12.value
                      }
                    };
            }
            var e$13 = additionalAddressInfo._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".additionalAddressInfo" + e$13.path,
                      message: e$13.message,
                      value: e$13.value
                    }
                  };
          }
          var e$14 = streetNbr._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".streetNbr" + e$14.path,
                    message: e$14.message,
                    value: e$14.value
                  }
                };
        }
        var e$15 = street._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".street" + e$15.path,
                  message: e$15.message,
                  value: e$15.value
                }
              };
      }
      var e$16 = name._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".name" + e$16.path,
                message: e$16.message,
                value: e$16.value
              }
            };
    }
    var e$17 = siren._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".siren" + e$17.path,
              message: e$17.message,
              value: e$17.value
            }
          };
  }
  var e$18 = siret._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".siret" + e$18.path,
            message: e$18.message,
            value: e$18.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(row_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(row_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

export {
  Fields ,
  row_encode ,
  row_decode ,
  searchResult_encode ,
  searchResult_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
