// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../../../common/types/App_Types_Input.bs.js";
import * as Visibility_Profiles_Types$DvmAdminFrontendFr from "./Visibility_Profiles_Types.bs.js";

function fetchVisibilityProfiles(setResult, userManager, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Visibility_Profiles_Types$DvmAdminFrontendFr.VisibilityProfilesList.t_decode, undefined, userManager, selectedRole, "/dealers/profile/mapping").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchResult = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchResult
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchAllBIR(setResult, userManager, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Visibility_Profiles_Types$DvmAdminFrontendFr.VisibilityProfilesList.dealerCodesList_decode, undefined, userManager, selectedRole, "/dealers/profile/bir").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchResult = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchResult
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function saveProfile(setResult, userManager, selection, submissionCallbacks, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("PUT", {
          TAG: /* Json */0,
          _0: App_Types_Input$DvmAdminFrontendFr.parentContent_encode(selection)
        }, userManager, selectedRole, "/dealers/profile/mapping").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToSaveProfile */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function deleteProfile(setResult, userManager, userID, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", undefined, userManager, selectedRole, "/dealers/profile/mapping/" + userID + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Types;

var Form;

export {
  Types ,
  Form ,
  fetchVisibilityProfiles ,
  fetchAllBIR ,
  saveProfile ,
  deleteProfile ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
