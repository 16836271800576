// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";

var inputStyle = Css.css({
      padding: "9px 20px 8px 15px",
      width: "100%"
    });

function App_TextInput(Props) {
  var additionalClassNameOpt = Props.additionalClassName;
  var onChange = Props.onChange;
  var onClick = Props.onClick;
  var onDoubleClick = Props.onDoubleClick;
  var disabledOpt = Props.disabled;
  var value = Props.value;
  var placeholderOpt = Props.placeholder;
  var additionalClassName = additionalClassNameOpt !== undefined ? additionalClassNameOpt : "";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "";
  var tmp = {
    className: Css.cx([
          inputStyle,
          additionalClassName
        ]),
    disabled: disabled,
    placeholder: placeholder,
    type: "text",
    value: value,
    onChange: onChange
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (onDoubleClick !== undefined) {
    tmp.onDoubleClick = Caml_option.valFromOption(onDoubleClick);
  }
  return React.createElement("input", tmp);
}

var make = App_TextInput;

export {
  inputStyle ,
  make ,
}
/* inputStyle Not a pure module */
