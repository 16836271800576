// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../api/RestApi.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as Quota_Detail_Attachment_Types$DvmAdminFrontendFr from "./Quota_Detail_Attachment_Types.bs.js";

function fetchQuotaDetailAtatachmentSearch(submissionCallbacks, userManager, setResult, queryParams, whichTable, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var whichTable$1 = whichTable === "active" ? "active" : "nonactive";
  RestApi$DvmAdminFrontendFr.call("GET", Quota_Detail_Attachment_Types$DvmAdminFrontendFr.DetailAttachment.searchResult_decode, undefined, userManager, selectedRole, "/quotas/attachments/" + whichTable$1 + "" + queryParams + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnSuccess, undefined);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function saveAttachments(setResult, userManager, selectedRole, attachments) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
          TAG: /* Json */0,
          _0: Quota_Detail_Attachment_Types$DvmAdminFrontendFr.DetailAttachment.attachmentsSaveRequest_encode(attachments)
        }, userManager, selectedRole, "/quotas/attachments").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function updateAttachment(setResult, userManager, selectedRole, attachment) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("PUT", {
          TAG: /* Json */0,
          _0: Quota_Detail_Attachment_Types$DvmAdminFrontendFr.DetailAttachment.attachmentUpdate_encode(attachment)
        }, userManager, selectedRole, "/quotas/attachment/correction").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchVolumeOfAttachments(userManager, alert, setResult, quota, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Quota_Detail_Attachment_Types$DvmAdminFrontendFr.volumeOfAttachments_decode, undefined, userManager, selectedRole, "/quotas/attachments/volumes?dr=" + quota.dr + "&contractID=" + quota.contractId + "&vehicleType=" + quota.vehicleType + "&startDate=" + quota.startDate.toISOString() + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var volume = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: volume
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function deleteAttachment(setResult, userManager, selectedRole, attachment) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", {
          TAG: /* Json */0,
          _0: Quota_Detail_Attachment_Types$DvmAdminFrontendFr.DetailAttachment.attachmentUpdate_encode(attachment)
        }, userManager, selectedRole, "/quotas/attachments").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

export {
  Form ,
  fetchQuotaDetailAtatachmentSearch ,
  saveAttachments ,
  updateAttachment ,
  fetchVolumeOfAttachments ,
  deleteAttachment ,
}
/* react Not a pure module */
