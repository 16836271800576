// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../../../api/RestApi.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../../common/utils/Utils_Alert.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Input.bs.js";
import * as Flash_Search_Types$DvmAdminFrontendFr from "../../../../../flash/search/Flash_Search_Types.bs.js";
import * as Order_Detail_Types_Shared$DvmAdminFrontendFr from "../../../Order_Detail_Types_Shared.bs.js";

function saveOrEditDerogate(userManager, setResult, orderId, actionType, derogate, submissionCallbacks, selectedRole, operation) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call(operation ? "POST" : "PUT", {
          TAG: /* Json */0,
          _0: Order_Detail_Types_Shared$DvmAdminFrontendFr.DeroSave.derogateSave_encode(derogate)
        }, userManager, selectedRole, "/orders/" + orderId + "/derogate/" + (
          actionType === "budget" ? "budget" : "action"
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToCreateDerogate */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchActionSelect(alert, userManager, setActionIdOptions, selectedRole, orderType, actionType) {
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.twoParamList_decode, undefined, userManager, selectedRole, orderType !== 0 ? "/dropdown/dero/actions/modular" : "/dropdown/dero/actions?actionType=" + actionType.toUpperCase() + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var actions = result._0;
          return Curry._1(setActionIdOptions, (function (param) {
                        return actions;
                      }));
        }
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: result._0
                  }
                }));
        return Curry._1(setActionIdOptions, (function (param) {
                      return /* [] */0;
                    }));
      });
}

function fetchOneAction(userManager, setResult, selectedRole, actionID, orderType) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Search_Types$DvmAdminFrontendFr.searchResult_decode, undefined, userManager, selectedRole, orderType !== 0 ? "/flash/actions/modular?limit=50&offset=0&filter=actionID=" + actionID + "" : "/flash/actions?limit=50&offset=0&filter=actionID=" + actionID + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: Belt_List.get(searchTable.content, 0)
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

export {
  Form ,
  saveOrEditDerogate ,
  fetchActionSelect ,
  fetchOneAction ,
}
/* react Not a pure module */
