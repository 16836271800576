// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../../../../common/components/App_Modal.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../../../../../common/utils/Utils_Api.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../../../context/user/User_Context.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../../../../intl/messages/Messages_Button.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../../../../../common/components/table/App_Table_Pagination.bs.js";
import * as Flash_Edit_Version_Perf_Modal_Api$DvmAdminFrontendFr from "../../Flash_Edit_Version_Perf_Modal_Api.bs.js";
import * as Flash_Edit_Version_Perf_New_Table$DvmAdminFrontendFr from "./Flash_Edit_Version_Perf_New_Table.bs.js";
import * as Flash_Edit_Version_Perf_New_Inputs$DvmAdminFrontendFr from "./Flash_Edit_Version_Perf_New_Inputs.bs.js";
import * as Flash_Edit_Version_Perf_New_Filters$DvmAdminFrontendFr from "./Flash_Edit_Version_Perf_New_Filters.bs.js";
import * as Flash_Edit_Version_Perf_New_Selection$DvmAdminFrontendFr from "./Flash_Edit_Version_Perf_New_Selection.bs.js";
import * as Flash_Edit_Version_Duplicate_Action_Perf$DvmAdminFrontendFr from "./Flash_Edit_Version_Duplicate_Action_Perf.bs.js";
import * as Flash_Edit_Version_Perf_New_Selection_Filter$DvmAdminFrontendFr from "./Flash_Edit_Version_Perf_New_Selection_Filter.bs.js";

function Flash_Edit_Version_Perf_New_Modal(Props) {
  var flash = Props.flash;
  var onClose = Props.onClose;
  var onOk = Props.onOk;
  var actionDistinctVersions = Props.actionDistinctVersions;
  var actionAllVersions = Props.actionAllVersions;
  var fetchUpdatedModelsForDropdownAndCumulables = Props.fetchUpdatedModelsForDropdownAndCumulables;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match$2[1];
  var saveResult = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match$3[1];
  var tablePage = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var withClosedModels = match$4[0];
  var match$5 = React.useState(function () {
        return /* [] */0;
      });
  var match$6 = React.useState(function () {
        return /* [] */0;
      });
  var setAllOptions = match$6[1];
  var match$7 = React.useState(function () {
        return /* [] */0;
      });
  var setSelectedVersionsList = match$7[1];
  var selectedVersionsList = match$7[0];
  var match$8 = React.useState(function () {
        return /* [] */0;
      });
  var selectedVersionsListFiltered = match$8[0];
  var match$9 = React.useState(function () {
        return /* [] */0;
      });
  var match$10 = React.useState(function () {
        return /* First */0;
      });
  var setStep = match$10[1];
  var match$11 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: Caml_array.get(App_Table_Pagination$DvmAdminFrontendFr.options, 0),
                headers: /* [] */0,
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$11[1];
  var fetchData = function (submissionCallbacks, fetchRequest) {
    Flash_Edit_Version_Perf_Modal_Api$DvmAdminFrontendFr.fetchAllVersions(submissionCallbacks, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
              page: fetchRequest.page,
              rowsPerPage: 2000,
              headers: fetchRequest.headers,
              filterQueryParams: fetchRequest.filterQueryParams,
              additionalQueryParams: fetchRequest.additionalQueryParams
            }), selectedRole, withClosedModels);
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  var filledVersions = Belt_List.keep(selectedVersionsList, (function (x) {
          if (Belt_Option.isSome(x.maxBonus)) {
            return Belt_Option.isSome(x.startDate);
          } else {
            return false;
          }
        }));
  React.useEffect((function () {
          if (typeof tablePage === "number") {
            tablePage === /* NotStarted */0;
          } else if (tablePage.TAG === /* Ok */0) {
            var table = tablePage._0;
            Curry._1(setAllOptions, (function (param) {
                    return table.content;
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: tablePage._0
                      }
                    }));
          }
        }), [tablePage]);
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(fetchUpdatedModelsForDropdownAndCumulables, undefined);
            Curry._1(onOk, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  React.useEffect((function () {
          if (actionAllVersions !== undefined) {
            Curry._1(setSelectedVersionsList, (function (param) {
                    return actionAllVersions;
                  }));
          }
          
        }), [actionAllVersions]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.addVersions),
              minWidth: "1200px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult) || App_Types_Result$DvmAdminFrontendFr.isPending(tablePage),
                    children: React.createElement(Core.Grid, {
                          children: match$10[0] ? React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                                      children: React.createElement(Flash_Edit_Version_Perf_New_Filters$DvmAdminFrontendFr.make, {
                                            selectedVersionsList: selectedVersionsList,
                                            setSelectedVersionsListFiltered: match$8[1],
                                            edit: false
                                          }),
                                      container: true,
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(Flash_Edit_Version_Perf_New_Table$DvmAdminFrontendFr.make, {
                                            selectedVersionsListFiltered: selectedVersionsListFiltered,
                                            selectedVersionsListFilteredPaged: match$9[0],
                                            setSelectedVersionsListFilteredPaged: match$9[1]
                                          }),
                                      container: true,
                                      item: true,
                                      style: {
                                        minHeight: "56vh"
                                      }
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(Flash_Edit_Version_Perf_New_Inputs$DvmAdminFrontendFr.make, {
                                            selectedVersionsListFiltered: selectedVersionsListFiltered,
                                            selectedVersionsList: selectedVersionsList,
                                            setSelectedVersionsList: setSelectedVersionsList,
                                            flash: flash
                                          }),
                                      container: true,
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: null,
                                      container: true,
                                      item: true,
                                      justifyContent: "space-between"
                                    }, React.createElement(Core.Grid, {
                                          item: true,
                                          sm: Grid$Mui.Sm[4]
                                        }), React.createElement(Core.Grid, {
                                          children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                onClick: (function (param) {
                                                    Curry._1(setStep, (function (param) {
                                                            return /* First */0;
                                                          }));
                                                  }),
                                                label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.previous),
                                                color: "primaryBorder",
                                                size: "medium"
                                              }),
                                          container: true,
                                          item: true,
                                          justifyContent: "center",
                                          sm: Grid$Mui.Sm[4]
                                        }), React.createElement(Core.Grid, {
                                          children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                onClick: (function (param) {
                                                    Flash_Edit_Version_Perf_Modal_Api$DvmAdminFrontendFr.saveVersion(setSaveResult, userManager, undefined, Belt_List.map(filledVersions, (function (x) {
                                                                return {
                                                                        actionID: flash.actionId,
                                                                        modelVersionID: x.modelVersionID,
                                                                        oldStartDate: undefined,
                                                                        startDate: x.startDate,
                                                                        endDate: undefined,
                                                                        maxBonus: x.maxBonus
                                                                      };
                                                              })), flash.actionId, "POST", selectedRole);
                                                  }),
                                                label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                                size: "medium",
                                                disabled: Belt_List.length(filledVersions) === 0
                                              }),
                                          container: true,
                                          item: true,
                                          justifyContent: "flex-end",
                                          sm: Grid$Mui.Sm[4]
                                        }))) : React.createElement(React.Fragment, undefined, React.createElement(Flash_Edit_Version_Duplicate_Action_Perf$DvmAdminFrontendFr.make, {
                                      flash: flash,
                                      setSelectedVersionsList: setSelectedVersionsList
                                    }), React.createElement(Core.Grid, {
                                      children: null,
                                      container: true,
                                      direction: "column",
                                      item: true,
                                      spacing: 1
                                    }, React.createElement(Core.Grid, {
                                          children: React.createElement(Flash_Edit_Version_Perf_New_Selection_Filter$DvmAdminFrontendFr.make, {
                                                allOptions: match$6[0],
                                                fetchData: fetchData,
                                                fetchRequest: match$11[0],
                                                setFilteredOptions: match$5[1],
                                                withClosedModels: withClosedModels,
                                                setWithClosedModels: match$4[1]
                                              }),
                                          item: true
                                        }), React.createElement(Core.Grid, {
                                          children: React.createElement(Flash_Edit_Version_Perf_New_Selection$DvmAdminFrontendFr.make, {
                                                filteredOptions: match$5[0],
                                                actionDistinctVersions: actionDistinctVersions,
                                                selectedVersionsList: selectedVersionsList,
                                                setSelectedVersionsList: setSelectedVersionsList
                                              }),
                                          item: true
                                        })), React.createElement(Core.Grid, {
                                      children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                            onClick: (function (param) {
                                                Curry._1(setStep, (function (param) {
                                                        return /* Second */1;
                                                      }));
                                              }),
                                            label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.next),
                                            color: "secondary",
                                            size: "medium",
                                            disabled: Belt_List.length(selectedVersionsList) === 0
                                          }),
                                      container: true,
                                      item: true,
                                      justifyContent: "center"
                                    })),
                          container: true,
                          spacing: 2
                        })
                  })
            });
}

var Api;

var Types;

var make = Flash_Edit_Version_Perf_New_Modal;

export {
  Api ,
  Types ,
  make ,
}
/* react Not a pure module */
