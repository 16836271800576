// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../../theme/Theme_Colors.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_SelectFormInput.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Order_Detail_Correct_Box$DvmAdminFrontendFr from "../Order_Detail_Correct_Box.bs.js";
import * as Messages_Orders_Correction$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_Correction.bs.js";

function Order_Detail_Correct_Vehicle_New(Props) {
  var form = Props.form;
  var formVehicle = Props.formVehicle;
  var vehicleEditDisabled = Props.vehicleEditDisabled;
  var intl = ReactIntl.useIntl();
  React.useEffect((function () {
          Curry._2(formVehicle.updateOrderType, (function (input, orderType) {
                  return {
                          orderType: orderType,
                          policeNumber: input.policeNumber,
                          usedCarLastRegistrationDate: input.usedCarLastRegistrationDate,
                          usedCarRegistrationID: input.usedCarRegistrationID,
                          usedCarFirstReleaseDate: input.usedCarFirstReleaseDate,
                          brand: input.brand,
                          model: input.model,
                          version: input.version,
                          vin: input.vin,
                          valueUsedCar: input.valueUsedCar,
                          estimatedValueUsedCar: input.estimatedValueUsedCar,
                          endOfLifeUsedCar: input.endOfLifeUsedCar
                        };
                }), form.input.orderType);
        }), [form.input.orderType]);
  var tmp = {
    onChange: Curry._1(formVehicle.updatePoliceNumber, (function (input, policeNumber) {
            return {
                    orderType: input.orderType,
                    policeNumber: policeNumber,
                    usedCarLastRegistrationDate: input.usedCarLastRegistrationDate,
                    usedCarRegistrationID: input.usedCarRegistrationID,
                    usedCarFirstReleaseDate: input.usedCarFirstReleaseDate,
                    brand: input.brand,
                    model: input.model,
                    version: input.version,
                    vin: input.vin,
                    valueUsedCar: input.valueUsedCar,
                    estimatedValueUsedCar: input.estimatedValueUsedCar,
                    endOfLifeUsedCar: input.endOfLifeUsedCar
                  };
          })),
    value: formVehicle.input.policeNumber,
    disabled: vehicleEditDisabled,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Orders_Detail$DvmAdminFrontendFr.reprPoliceNumber
  };
  if (formVehicle.policeNumberResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(formVehicle.policeNumberResult);
  }
  var tmp$1 = {
    onChange: Curry._1(formVehicle.updateUsedCarRegistrationID, (function (input, usedCarRegistrationID) {
            return {
                    orderType: input.orderType,
                    policeNumber: input.policeNumber,
                    usedCarLastRegistrationDate: input.usedCarLastRegistrationDate,
                    usedCarRegistrationID: usedCarRegistrationID,
                    usedCarFirstReleaseDate: input.usedCarFirstReleaseDate,
                    brand: input.brand,
                    model: input.model,
                    version: input.version,
                    vin: input.vin,
                    valueUsedCar: input.valueUsedCar,
                    estimatedValueUsedCar: input.estimatedValueUsedCar,
                    endOfLifeUsedCar: input.endOfLifeUsedCar
                  };
          })),
    value: formVehicle.input.usedCarRegistrationID,
    disabled: vehicleEditDisabled,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Common$DvmAdminFrontendFr.registrationVO
  };
  if (formVehicle.usedCarRegistrationIDResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(formVehicle.usedCarRegistrationIDResult);
  }
  var tmp$2 = {
    label: Messages_Orders_Detail$DvmAdminFrontendFr.registrationDate,
    value: formVehicle.input.usedCarLastRegistrationDate,
    onChange: Curry._1(formVehicle.updateUsedCarLastRegistrationDate, (function (input, usedCarLastRegistrationDate) {
            return {
                    orderType: input.orderType,
                    policeNumber: input.policeNumber,
                    usedCarLastRegistrationDate: usedCarLastRegistrationDate,
                    usedCarRegistrationID: input.usedCarRegistrationID,
                    usedCarFirstReleaseDate: input.usedCarFirstReleaseDate,
                    brand: input.brand,
                    model: input.model,
                    version: input.version,
                    vin: input.vin,
                    valueUsedCar: input.valueUsedCar,
                    estimatedValueUsedCar: input.estimatedValueUsedCar,
                    endOfLifeUsedCar: input.endOfLifeUsedCar
                  };
          })),
    disabled: vehicleEditDisabled,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (formVehicle.usedCarLastRegistrationDateResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(formVehicle.usedCarLastRegistrationDateResult);
  }
  var tmp$3 = {
    label: Messages_Orders_Detail$DvmAdminFrontendFr.reprDatePremiere,
    value: formVehicle.input.usedCarFirstReleaseDate,
    onChange: Curry._1(formVehicle.updateUsedCarFirstReleaseDate, (function (input, usedCarFirstReleaseDate) {
            return {
                    orderType: input.orderType,
                    policeNumber: input.policeNumber,
                    usedCarLastRegistrationDate: input.usedCarLastRegistrationDate,
                    usedCarRegistrationID: input.usedCarRegistrationID,
                    usedCarFirstReleaseDate: usedCarFirstReleaseDate,
                    brand: input.brand,
                    model: input.model,
                    version: input.version,
                    vin: input.vin,
                    valueUsedCar: input.valueUsedCar,
                    estimatedValueUsedCar: input.estimatedValueUsedCar,
                    endOfLifeUsedCar: input.endOfLifeUsedCar
                  };
          })),
    disabled: vehicleEditDisabled,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (formVehicle.usedCarFirstReleaseDateResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(formVehicle.usedCarFirstReleaseDateResult);
  }
  var tmp$4 = {
    onChange: Curry._1(formVehicle.updateBrand, (function (input, brand) {
            return {
                    orderType: input.orderType,
                    policeNumber: input.policeNumber,
                    usedCarLastRegistrationDate: input.usedCarLastRegistrationDate,
                    usedCarRegistrationID: input.usedCarRegistrationID,
                    usedCarFirstReleaseDate: input.usedCarFirstReleaseDate,
                    brand: brand,
                    model: input.model,
                    version: input.version,
                    vin: input.vin,
                    valueUsedCar: input.valueUsedCar,
                    estimatedValueUsedCar: input.estimatedValueUsedCar,
                    endOfLifeUsedCar: input.endOfLifeUsedCar
                  };
          })),
    value: formVehicle.input.brand,
    disabled: vehicleEditDisabled,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Common$DvmAdminFrontendFr.brandVO
  };
  if (formVehicle.brandResult !== undefined) {
    tmp$4.validationResult = Caml_option.valFromOption(formVehicle.brandResult);
  }
  var tmp$5 = {
    onChange: Curry._1(formVehicle.updateModel, (function (input, model) {
            return {
                    orderType: input.orderType,
                    policeNumber: input.policeNumber,
                    usedCarLastRegistrationDate: input.usedCarLastRegistrationDate,
                    usedCarRegistrationID: input.usedCarRegistrationID,
                    usedCarFirstReleaseDate: input.usedCarFirstReleaseDate,
                    brand: input.brand,
                    model: model,
                    version: input.version,
                    vin: input.vin,
                    valueUsedCar: input.valueUsedCar,
                    estimatedValueUsedCar: input.estimatedValueUsedCar,
                    endOfLifeUsedCar: input.endOfLifeUsedCar
                  };
          })),
    value: formVehicle.input.model,
    disabled: vehicleEditDisabled,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Common$DvmAdminFrontendFr.modelVO
  };
  if (formVehicle.modelResult !== undefined) {
    tmp$5.validationResult = Caml_option.valFromOption(formVehicle.modelResult);
  }
  var tmp$6 = {
    onChange: Curry._1(formVehicle.updateVersion, (function (input, version) {
            return {
                    orderType: input.orderType,
                    policeNumber: input.policeNumber,
                    usedCarLastRegistrationDate: input.usedCarLastRegistrationDate,
                    usedCarRegistrationID: input.usedCarRegistrationID,
                    usedCarFirstReleaseDate: input.usedCarFirstReleaseDate,
                    brand: input.brand,
                    model: input.model,
                    version: version,
                    vin: input.vin,
                    valueUsedCar: input.valueUsedCar,
                    estimatedValueUsedCar: input.estimatedValueUsedCar,
                    endOfLifeUsedCar: input.endOfLifeUsedCar
                  };
          })),
    value: formVehicle.input.version,
    disabled: vehicleEditDisabled,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Common$DvmAdminFrontendFr.versionVO
  };
  if (formVehicle.versionResult !== undefined) {
    tmp$6.validationResult = Caml_option.valFromOption(formVehicle.versionResult);
  }
  var tmp$7 = {
    onChange: Curry._1(formVehicle.updateVin, (function (input, vin) {
            return {
                    orderType: input.orderType,
                    policeNumber: input.policeNumber,
                    usedCarLastRegistrationDate: input.usedCarLastRegistrationDate,
                    usedCarRegistrationID: input.usedCarRegistrationID,
                    usedCarFirstReleaseDate: input.usedCarFirstReleaseDate,
                    brand: input.brand,
                    model: input.model,
                    version: input.version,
                    vin: vin,
                    valueUsedCar: input.valueUsedCar,
                    estimatedValueUsedCar: input.estimatedValueUsedCar,
                    endOfLifeUsedCar: input.endOfLifeUsedCar
                  };
          })),
    value: formVehicle.input.vin,
    disabled: vehicleEditDisabled,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Common$DvmAdminFrontendFr.vinVO
  };
  if (formVehicle.vinResult !== undefined) {
    tmp$7.validationResult = Caml_option.valFromOption(formVehicle.vinResult);
  }
  var match = form.input.orderType;
  var tmp$8;
  if (match !== 0) {
    var tmp$9 = {
      onChange: Curry._1(formVehicle.updateValueUsedCar, (function (input, valueUsedCar) {
              return {
                      orderType: input.orderType,
                      policeNumber: input.policeNumber,
                      usedCarLastRegistrationDate: input.usedCarLastRegistrationDate,
                      usedCarRegistrationID: input.usedCarRegistrationID,
                      usedCarFirstReleaseDate: input.usedCarFirstReleaseDate,
                      brand: input.brand,
                      model: input.model,
                      version: input.version,
                      vin: input.vin,
                      valueUsedCar: valueUsedCar,
                      estimatedValueUsedCar: input.estimatedValueUsedCar,
                      endOfLifeUsedCar: input.endOfLifeUsedCar
                    };
            })),
      value: formVehicle.input.valueUsedCar,
      disabled: vehicleEditDisabled,
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
      label: Messages_Orders_Detail$DvmAdminFrontendFr.valueVO,
      InputProps: {
        startAdornment: React.createElement(Core.InputAdornment, {
              children: "€",
              position: "start"
            })
      },
      inputType: "number"
    };
    if (formVehicle.valueUsedCarResult !== undefined) {
      tmp$9.validationResult = Caml_option.valFromOption(formVehicle.valueUsedCarResult);
    }
    var tmp$10 = {
      onChange: Curry._1(formVehicle.updateEstimatedValueUsedCar, (function (input, estimatedValueUsedCar) {
              return {
                      orderType: input.orderType,
                      policeNumber: input.policeNumber,
                      usedCarLastRegistrationDate: input.usedCarLastRegistrationDate,
                      usedCarRegistrationID: input.usedCarRegistrationID,
                      usedCarFirstReleaseDate: input.usedCarFirstReleaseDate,
                      brand: input.brand,
                      model: input.model,
                      version: input.version,
                      vin: input.vin,
                      valueUsedCar: input.valueUsedCar,
                      estimatedValueUsedCar: estimatedValueUsedCar,
                      endOfLifeUsedCar: input.endOfLifeUsedCar
                    };
            })),
      value: formVehicle.input.estimatedValueUsedCar,
      disabled: vehicleEditDisabled,
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
      label: Messages_Orders_Detail$DvmAdminFrontendFr.estimatedValueVO,
      InputProps: {
        startAdornment: React.createElement(Core.InputAdornment, {
              children: "€",
              position: "start"
            })
      },
      inputType: "number"
    };
    if (formVehicle.estimatedValueUsedCarResult !== undefined) {
      tmp$10.validationResult = Caml_option.valFromOption(formVehicle.estimatedValueUsedCarResult);
    }
    var tmp$11 = {
      label: Messages_Orders_Detail$DvmAdminFrontendFr.endOfLifeVO,
      options: {
        hd: {
          label: {
            TAG: /* Message */0,
            _0: Messages_Common$DvmAdminFrontendFr.nothing
          },
          value: ""
        },
        tl: {
          hd: {
            label: {
              TAG: /* Message */0,
              _0: Messages_Common$DvmAdminFrontendFr.yes
            },
            value: "true"
          },
          tl: {
            hd: {
              label: {
                TAG: /* Message */0,
                _0: Messages_Common$DvmAdminFrontendFr.no
              },
              value: "false"
            },
            tl: /* [] */0
          }
        }
      },
      value: {
        TAG: /* Single */0,
        _0: {
          value: formVehicle.input.endOfLifeUsedCar,
          onChange: Curry._1(formVehicle.updateEndOfLifeUsedCar, (function (input, endOfLifeUsedCar) {
                  return {
                          orderType: input.orderType,
                          policeNumber: input.policeNumber,
                          usedCarLastRegistrationDate: input.usedCarLastRegistrationDate,
                          usedCarRegistrationID: input.usedCarRegistrationID,
                          usedCarFirstReleaseDate: input.usedCarFirstReleaseDate,
                          brand: input.brand,
                          model: input.model,
                          version: input.version,
                          vin: input.vin,
                          valueUsedCar: input.valueUsedCar,
                          estimatedValueUsedCar: input.estimatedValueUsedCar,
                          endOfLifeUsedCar: endOfLifeUsedCar
                        };
                }))
        }
      },
      id: "endOfLifeUsedCar",
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
      disabled: vehicleEditDisabled
    };
    if (formVehicle.endOfLifeUsedCarResult !== undefined) {
      tmp$11.validationResult = Caml_option.valFromOption(formVehicle.endOfLifeUsedCarResult);
    }
    tmp$8 = [
      React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$9),
      React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$10),
      React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, tmp$11)
    ];
  } else {
    tmp$8 = [];
  }
  return React.createElement(Order_Detail_Correct_Box$DvmAdminFrontendFr.make, {
              content: Belt_Array.concat([
                    React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp),
                    React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$1),
                    React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$2),
                    React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$3),
                    React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$4),
                    React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$5),
                    React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$6),
                    React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$7)
                  ], tmp$8),
              bgcolor: Theme_Colors$DvmAdminFrontendFr.grayLight1,
              color: Theme_Colors$DvmAdminFrontendFr.secondaryDarkGreenDark,
              title: intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.correctionCarValues)
            });
}

var Form;

var FormVehicle;

var InputStyles;

var make = Order_Detail_Correct_Vehicle_New;

export {
  Form ,
  FormVehicle ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
