// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../common/components/icons/App_Icon.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../theme/Theme_Colors.bs.js";
import * as Bonuses_Search_Modal$DvmAdminFrontendFr from "./filterModal/Bonuses_Search_Modal.bs.js";
import * as Bonuses_Search_Modal_Utils$DvmAdminFrontendFr from "./filterModal/Bonuses_Search_Modal_Utils.bs.js";

var modalButton = Css.css(Object.assign({}, {
          color: Theme_Colors$DvmAdminFrontendFr.black,
          height: "48px",
          width: "48px"
        }, {
          "&:hover": {
            backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayMain
          }
        }));

var icon = Css.css({
      fontWeight: "bold"
    });

function Bonuses_Search_Modal_Wrapper(Props) {
  var form = Props.form;
  var formModal = Props.formModal;
  var formAdditional = Props.formAdditional;
  var formAdditionalSecond = Props.formAdditionalSecond;
  var formModalVO = Props.formModalVO;
  var modal = Props.modal;
  var setModal = Props.setModal;
  var submit = Props.submit;
  var match = React.useState(function () {
        return 0;
      });
  var setModalNumber = match[1];
  React.useEffect((function () {
          Curry._1(setModalNumber, (function (param) {
                  return Bonuses_Search_Modal_Utils$DvmAdminFrontendFr.handleModalNumber(formModal, formAdditional, formModalVO, formAdditionalSecond);
                }));
        }), [formModal]);
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Button, {
                  onClick: (function (param) {
                      Curry._1(setModal, (function (param) {
                              return true;
                            }));
                    }),
                  children: React.createElement(Core.Badge, {
                        anchorOrigin: {
                          horizontal: "right",
                          vertical: "bottom"
                        },
                        badgeContent: match[0],
                        children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                              size: /* Large */3,
                              type_: /* Filter */63,
                              iconClassName: icon
                            }),
                        color: "primary"
                      }),
                  className: modalButton,
                  type: "button"
                }), modal ? React.createElement(Bonuses_Search_Modal$DvmAdminFrontendFr.make, {
                    onClose: (function (param) {
                        Curry._1(setModal, (function (param) {
                                return false;
                              }));
                      }),
                    submit: submit,
                    orderType: form.input.orderType,
                    form: formModal,
                    formAdditional: formAdditional,
                    formAdditionalSecond: formAdditionalSecond,
                    formModalVO: formModalVO,
                    dealerIds: form.input.dealerId
                  }) : null);
}

var make = Bonuses_Search_Modal_Wrapper;

export {
  modalButton ,
  icon ,
  make ,
}
/* modalButton Not a pure module */
