// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../intl/Intl_Utils.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../components/table/App_Table_Head.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../App_Typography.bs.js";
import * as App_Table_Styles$DvmAdminFrontendFr from "../../components/table/App_Table_Styles.bs.js";
import * as Messages_File_Upload$DvmAdminFrontendFr from "../../../intl/messages/Messages_File_Upload.bs.js";

function File_Upload_Preview_Table(Props) {
  var excelInfo = Props.excelInfo;
  var setNextDisabled = Props.setNextDisabled;
  var intl = ReactIntl.useIntl();
  React.useEffect((function () {
          Curry._1(setNextDisabled, (function (param) {
                  return false;
                }));
        }), []);
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              direction: "column",
              item: true,
              spacing: 1
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        color: "textPrimary",
                        gutterBottom: true,
                        children: intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.tablePreview),
                        variant: "bodyBig"
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Core.TableContainer, {
                        children: React.createElement(Core.Table, {
                              children: null,
                              stickyHeader: true
                            }, React.createElement(Core.TableHead, {
                                  children: React.createElement(Core.TableRow, {
                                        children: Belt_List.toArray(Belt_List.map(excelInfo.excelColumns, (function (column) {
                                                    return React.createElement(Core.TableCell, {
                                                                children: column,
                                                                className: App_Table_Head$DvmAdminFrontendFr.headerText,
                                                                key: column
                                                              });
                                                  }))),
                                        className: App_Table_Head$DvmAdminFrontendFr.wholeRow
                                      })
                                }), React.createElement(Core.TableBody, {
                                  children: Belt_List.toArray(Belt_List.mapWithIndex(excelInfo.preview, (function (rowIndex, row) {
                                              return React.createElement(Core.TableRow, {
                                                          children: Belt_List.toArray(Belt_List.mapWithIndex(row, (function (cellIndex, cell) {
                                                                      return Belt_Option.mapWithDefault(cell, null, (function (cellStr) {
                                                                                    return React.createElement(Core.TableCell, {
                                                                                                children: React.createElement("div", {
                                                                                                      className: App_Table_Styles$DvmAdminFrontendFr.tableCellContent
                                                                                                    }, /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/.test(cellStr) ? Intl_Utils$DvmAdminFrontendFr.formatDate(intl, new Date(cellStr)) : cellStr),
                                                                                                className: App_Table_Styles$DvmAdminFrontendFr.tableCell(undefined, undefined),
                                                                                                key: "cell" + String(rowIndex) + String(cellIndex)
                                                                                              });
                                                                                  }));
                                                                    }))),
                                                          key: "row" + String(rowIndex)
                                                        });
                                            })))
                                })),
                        className: App_Table_Styles$DvmAdminFrontendFr.table
                      }),
                  item: true
                }));
}

var Types;

var Styles;

var make = File_Upload_Preview_Table;

export {
  Types ,
  Styles ,
  make ,
}
/* react Not a pure module */
