// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_TransferListFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_TransferListFormInput.bs.js";

function Margin_Grid_Duplicate_TranferList(Props) {
  var form = Props.form;
  var labeledCategories = Props.labeledCategories;
  var intl = ReactIntl.useIntl();
  var tmp = {
    values: form.input.discounts,
    options: labeledCategories,
    onChange: Curry._1(form.updateDiscounts, (function (input, discounts) {
            return {
                    newOrExisting: input.newOrExisting,
                    gridID: input.gridID,
                    discounts: discounts,
                    startDate: input.startDate,
                    endDate: input.endDate
                  };
          })),
    boxHeight: 260,
    boxLabels: {
      left: {
        TAG: /* String */3,
        _0: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.transferListOptions, {
              count: Belt_List.length(Belt_List.keep(labeledCategories, (function (element) {
                          return !Belt_List.some(form.input.discounts, (function (x) {
                                        return x.value === element.value;
                                      }));
                        })))
            })
      },
      right: {
        TAG: /* String */3,
        _0: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.transferListSelected, {
              count: Belt_List.length(form.input.discounts)
            })
      }
    },
    search: false
  };
  if (form.discountsResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.discountsResult);
  }
  return React.createElement(Core.Grid, {
              children: React.createElement(App_TransferListFormInput$DvmAdminFrontendFr.make, tmp),
              item: true
            });
}

var Form;

var make = Margin_Grid_Duplicate_TranferList;

export {
  Form ,
  make ,
}
/* react Not a pure module */
