// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../../common/App_Api.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as Flash_Edit_Types$DvmAdminFrontendFr from "../../../flash/edit/Flash_Edit_Types.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../../context/user/User_Types_Utils.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../common/components/App_OverlaySpinner.bs.js";
import * as App_RadioFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_RadioFormInput.bs.js";
import * as Messages_Orders_Search$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Search.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as Flash_Edit_NonFleet_Api$DvmAdminFrontendFr from "../../../flash/edit/steps/NonFleet/Flash_Edit_NonFleet_Api.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Bonuses_Search_Modal_Car_ModelVersionInput$DvmAdminFrontendFr from "./Bonuses_Search_Modal_Car_ModelVersionInput.bs.js";

function Bonuses_Search_Modal_Car_Filters(Props) {
  var form = Props.form;
  var formAdditionalSecond = Props.formAdditionalSecond;
  var resultCategoriesAll = Props.resultCategoriesAll;
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setMotorizations = match$2[1];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setMotorizationsString = match$3[1];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelVersions = match$4[1];
  var modelVersions = match$4[0];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setAllModelVersions = match$5[1];
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchMotorisations(setMotorizationsString, userManager, selectedRole);
          App_Api$DvmAdminFrontendFr.fetchModelVersions(setAllModelVersions, userManager, selectedRole, undefined, undefined, undefined);
        }), []);
  React.useEffect((function () {
          Flash_Edit_NonFleet_Api$DvmAdminFrontendFr.fetchModelsByBrand(setModelVersions, userManager, selectedRole, form.input.brand.length > 0 ? ({
                    hd: form.input.brand,
                    tl: /* [] */0
                  }) : ({
                    hd: "RENAULT",
                    tl: {
                      hd: "DACIA",
                      tl: {
                        hd: "ALPINE",
                        tl: /* [] */0
                      }
                    }
                  }), Belt_Option.getWithDefault(Flash_Edit_Types$DvmAdminFrontendFr.VehicleType.fromString(form.input.vehicleType), /* NOTSET */3));
        }), [
        form.input.vehicleType,
        form.input.brand
      ]);
  React.useEffect((function () {
          if (Belt_List.length(form.input.modelID) > 0) {
            Flash_Edit_NonFleet_Api$DvmAdminFrontendFr.getEngines(userManager, selectedRole, setMotorizations, form.input.modelID);
          }
          
        }), [form.input.modelID]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.forError(modelVersions, (function (error) {
                  Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                            message: {
                              TAG: /* RestError */2,
                              _0: error
                            }
                          }));
                }));
        }), [modelVersions]);
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              item: true,
              spacing: 3
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                        label: Messages_Common$DvmAdminFrontendFr.brand,
                        value: {
                          TAG: /* Single */0,
                          _0: {
                            value: form.input.brand,
                            onChange: Curry._1(form.updateBrand, (function (input, brand) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.brand = brand;
                                    return newrecord;
                                  }))
                          }
                        },
                        options: {
                          TAG: /* Labeled */1,
                          _0: {
                            hd: {
                              label: {
                                TAG: /* String */3,
                                _0: "Renault"
                              },
                              value: "RENAULT"
                            },
                            tl: {
                              hd: {
                                label: {
                                  TAG: /* String */3,
                                  _0: "Dacia"
                                },
                                value: "DACIA"
                              },
                              tl: {
                                hd: {
                                  label: {
                                    TAG: /* String */3,
                                    _0: "Alpine"
                                  },
                                  value: "ALPINE"
                                },
                                tl: /* [] */0
                              }
                            }
                          }
                        },
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                      }),
                  item: true,
                  sm: Grid$Mui.Sm[6]
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, {
                        label: Messages_Quotas_Detail$DvmAdminFrontendFr.vehicleType,
                        value: form.input.vehicleType,
                        options: {
                          hd: {
                            label: {
                              TAG: /* String */3,
                              _0: "VP/VU"
                            },
                            value: ""
                          },
                          tl: {
                            hd: {
                              label: {
                                TAG: /* String */3,
                                _0: "VP"
                              },
                              value: "VP"
                            },
                            tl: {
                              hd: {
                                label: {
                                  TAG: /* String */3,
                                  _0: "VU"
                                },
                                value: "VU"
                              },
                              tl: /* [] */0
                            }
                          }
                        },
                        onChange: Curry._1(form.updateVehicleType, (function (input, vehicleType) {
                                var newrecord = Caml_obj.obj_dup(input);
                                newrecord.vehicleType = vehicleType;
                                return newrecord;
                              })),
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.extraLongInput
                      }),
                  container: true,
                  item: true,
                  sm: Grid$Mui.Sm[6]
                }), React.createElement(Bonuses_Search_Modal_Car_ModelVersionInput$DvmAdminFrontendFr.make, {
                  allModelVersions: App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$5[0], /* [] */0),
                  modelVersions: modelVersions,
                  form: form,
                  motorizations: match$2[0],
                  motorizationsString: match$3[0]
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                        show: App_Types_Result$DvmAdminFrontendFr.isPending(resultCategoriesAll),
                        children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.category,
                              value: {
                                TAG: /* Multiple */1,
                                _0: {
                                  value: form.input.categoryLabel,
                                  onChange: Curry._1(form.updateCategoryLabel, (function (input, categoryLabel) {
                                          var newrecord = Caml_obj.obj_dup(input);
                                          newrecord.categoryLabel = categoryLabel;
                                          return newrecord;
                                        }))
                                }
                              },
                              options: {
                                TAG: /* Unlabeled */0,
                                _0: Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.keepMap(Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(resultCategoriesAll, /* [] */0), (function (x) {
                                                        return x.name;
                                                      })), (function (x) {
                                                    return x;
                                                  })))))
                              },
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                              smallChips: true
                            })
                      }),
                  item: true,
                  sm: Grid$Mui.Sm[12]
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                        label: Messages_Orders_Search$DvmAdminFrontendFr.orderStatusLabel,
                        value: {
                          TAG: /* Multiple */1,
                          _0: {
                            value: formAdditionalSecond.input.orderStatus,
                            onChange: Curry._1(formAdditionalSecond.updateOrderStatus, (function (input, orderStatus) {
                                    return {
                                            preinvoiceDateStart: input.preinvoiceDateStart,
                                            preinvoiceDateEnd: input.preinvoiceDateEnd,
                                            preinvoiceDateExists: input.preinvoiceDateExists,
                                            invoiceDateStart: input.invoiceDateStart,
                                            invoiceDateEnd: input.invoiceDateEnd,
                                            invoiceDateExists: input.invoiceDateExists,
                                            preinvoiceNumber: input.preinvoiceNumber,
                                            orderStatus: orderStatus
                                          };
                                  }))
                          }
                        },
                        options: {
                          TAG: /* Labeled */1,
                          _0: Belt_List.keepMap({
                                hd: {
                                  label: {
                                    TAG: /* Message */0,
                                    _0: Messages_Orders_Search$DvmAdminFrontendFr.statusComplete
                                  },
                                  value: "Complete"
                                },
                                tl: {
                                  hd: {
                                    label: {
                                      TAG: /* Message */0,
                                      _0: Messages_Orders_Search$DvmAdminFrontendFr.statusIncomplete
                                    },
                                    value: "Incomplete"
                                  },
                                  tl: {
                                    hd: {
                                      label: {
                                        TAG: /* Message */0,
                                        _0: Messages_Orders_Search$DvmAdminFrontendFr.statusCanceled
                                      },
                                      value: "Annulee"
                                    },
                                    tl: {
                                      hd: selectedRole === /* Dealer */7 || User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole) ? undefined : ({
                                            label: {
                                              TAG: /* Message */0,
                                              _0: Messages_Orders_Search$DvmAdminFrontendFr.statusReparceled
                                            },
                                            value: "Remembrement"
                                          }),
                                      tl: {
                                        hd: {
                                          label: {
                                            TAG: /* Message */0,
                                            _0: Messages_Orders_Search$DvmAdminFrontendFr.parcPending
                                          },
                                          value: "Attente entrée PARC"
                                        },
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }, (function (x) {
                                  return x;
                                }))
                        },
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                        limitTags: 3,
                        smallChips: true
                      }),
                  item: true,
                  sm: Grid$Mui.Sm[12]
                }));
}

var InputStyles;

var make = Bonuses_Search_Modal_Car_Filters;

export {
  InputStyles ,
  make ,
}
/* react Not a pure module */
