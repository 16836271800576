import "./dynatraceLoader.js";
import React from "react";
import ReactDOM from "react-dom";
import { make as Root } from "./app/Root.bs";
import reportWebVitals from "./reportWebVitals";
import "./theme/font/font.css";
import "./theme/desyre/desyre-toast.css";

// taken from DIA 71411
// following commands should ensure correct specificity of the injected css
// hopefully this would go away with Mui v5
const head = document.head;
const emotionStyles = document.querySelector("[data-emotion=css]");
const muiInsertionPoint = document.createElement("meta");
muiInsertionPoint.setAttribute("id", "mui-insertion-point");
if (emotionStyles == null) {
  head.append(muiInsertionPoint);
} else {
  emotionStyles.before(muiInsertionPoint);
}
const otherStyles = document.querySelectorAll("style:not([data-emotion=css]):not(data-jss)");
muiInsertionPoint.before(...otherStyles);

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);

function loadAllFonts() {
  const iterable = document.fonts.entries();
  let iterator = iterable.next();

  while (iterator.done === false) {
    iterator.value[0].load()
    iterator = iterable.next();
  }
}

loadAllFonts()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
