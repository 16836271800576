// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../api/RestApi.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../../../common/utils/Utils_Date.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as Order_Detail_Correct_Types$DvmAdminFrontendFr from "./Order_Detail_Correct_Types.bs.js";

function saveCorrection(correction, userManager, setResult, submissionCallbacks, perfSubmissionCallbacks, vehicleSubmissionCallbacks, additionalSubmissionCallbacks, selectedRole, orderType) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
          TAG: /* Json */0,
          _0: Order_Detail_Correct_Types$DvmAdminFrontendFr.orderCorrection_encode(correction)
        }, userManager, selectedRole, orderType !== 0 ? "/orders/nonfleet/corrections" : "/orders/fleet/corrections").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          Curry._1(perfSubmissionCallbacks.notifyOnSuccess, undefined);
          Curry._1(vehicleSubmissionCallbacks.notifyOnSuccess, undefined);
          Curry._1(additionalSubmissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToCorrectOrder */0);
        Curry._1(perfSubmissionCallbacks.notifyOnFailure, /* FailedToCorrectOrder */0);
        Curry._1(vehicleSubmissionCallbacks.notifyOnFailure, /* FailedToCorrectOrder */0);
        Curry._1(additionalSubmissionCallbacks.notifyOnFailure, /* FailedToCorrectOrder */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function getAllCorrections(alert, userManager, setCorrectionsResult, orderId, selectedRole) {
  Curry._1(setCorrectionsResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Order_Detail_Correct_Types$DvmAdminFrontendFr.searchResult_decode, undefined, userManager, selectedRole, "/orders/corrections?filter=orderID=" + orderId + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var locations = result._0;
          return Curry._1(setCorrectionsResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: locations
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setCorrectionsResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchPerfActions(userManager, setResult, selectedRole, order) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var orderDate = Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(order.detail.orderDate), "");
  var dateFilter = "actionStartDate=%5B%5C," + orderDate + "%5D;actionEndDate=%5B" + orderDate + "%5C,%5D%7C%7CactionEndDate=NULL";
  var model = "model=" + Belt_Option.getWithDefault(order.detail.model, "") + "";
  var version = "version=" + Belt_Option.getWithDefault(order.detail.version, "") + "";
  var modelVersionDateFilter = "modVerStartDate=%5B%5C," + orderDate + "%5D;modVerEndDate=%5B" + orderDate + "%5C,%5D%7C%7CmodVerEndDate=NULL";
  var filters = Belt_Array.joinWith([
        "actionType=PERF",
        dateFilter,
        model,
        version,
        modelVersionDateFilter
      ], ";", (function (x) {
          return x;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Order_Detail_Correct_Types$DvmAdminFrontendFr.perfActionList_decode, undefined, userManager, selectedRole, "/flash/actions/dropdown?filter=" + filters + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Types;

var Form;

var FormPerfs;

var FormVehicle;

var FormAdditional;

export {
  Types ,
  Form ,
  FormPerfs ,
  FormVehicle ,
  FormAdditional ,
  saveCorrection ,
  getAllCorrections ,
  fetchPerfActions ,
}
/* react Not a pure module */
