// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../theme/Theme_Colors.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../common/components/input/App_DateInputFormField.bs.js";

function Flash_Edit_DateInput(Props) {
  var icon = Props.icon;
  var message = Props.message;
  var startDateValue = Props.startDateValue;
  var startDateOnChange = Props.startDateOnChange;
  var startDateResult = Props.startDateResult;
  var endDateValue = Props.endDateValue;
  var endDateOnChange = Props.endDateOnChange;
  var endDateResult = Props.endDateResult;
  var startDateDisabledOpt = Props.startDateDisabled;
  var endDateDisabledOpt = Props.endDateDisabled;
  var startDateHiddenOpt = Props.startDateHidden;
  var endDateHiddenOpt = Props.endDateHidden;
  var controller = Props.controller;
  var customStartDateMessage = Props.customStartDateMessage;
  var startDateRequiredOpt = Props.startDateRequired;
  var endDateRequiredOpt = Props.endDateRequired;
  var startDateDisabled = startDateDisabledOpt !== undefined ? startDateDisabledOpt : false;
  var endDateDisabled = endDateDisabledOpt !== undefined ? endDateDisabledOpt : false;
  var startDateHidden = startDateHiddenOpt !== undefined ? startDateHiddenOpt : false;
  var endDateHidden = endDateHiddenOpt !== undefined ? endDateHiddenOpt : false;
  var startDateRequired = startDateRequiredOpt !== undefined ? startDateRequiredOpt : false;
  var endDateRequired = endDateRequiredOpt !== undefined ? endDateRequiredOpt : false;
  var intl = ReactIntl.useIntl();
  var tmp;
  if (startDateHidden) {
    tmp = null;
  } else {
    var tmp$1 = {
      label: Belt_Option.getWithDefault(customStartDateMessage, Messages_Common$DvmAdminFrontendFr.startDate),
      value: startDateValue,
      onChange: startDateOnChange,
      disabled: startDateDisabled,
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
    };
    if (startDateResult !== undefined) {
      tmp$1.validationResult = Caml_option.valFromOption(startDateResult);
    }
    var tmp$2 = startDateRequired ? /* OnlyLabel */1 : undefined;
    if (tmp$2 !== undefined) {
      tmp$1.required = Caml_option.valFromOption(tmp$2);
    }
    tmp = React.createElement(Core.Grid, {
          children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$1),
          item: true
        });
  }
  var tmp$3;
  if (endDateHidden) {
    tmp$3 = null;
  } else {
    var tmp$4 = {
      label: Messages_Common$DvmAdminFrontendFr.endDate,
      value: endDateValue,
      onChange: endDateOnChange,
      disabled: endDateDisabled,
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
    };
    if (endDateResult !== undefined) {
      tmp$4.validationResult = Caml_option.valFromOption(endDateResult);
    }
    var tmp$5 = endDateRequired ? /* OnlyLabel */1 : undefined;
    if (tmp$5 !== undefined) {
      tmp$4.required = Caml_option.valFromOption(tmp$5);
    }
    tmp$3 = React.createElement(Core.Grid, {
          children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$4),
          item: true
        });
  }
  return React.createElement(Core.Box, {
              children: React.createElement(Core.Grid, {
                    children: null,
                    container: true
                  }, React.createElement(Core.Grid, {
                        children: null,
                        container: true,
                        item: true,
                        spacing: 1
                      }, React.createElement(Core.Grid, {
                            children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                  size: /* Large */3,
                                  type_: icon
                                }),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                  style: {
                                    fontWeight: "600"
                                  },
                                  children: intl.formatMessage(message),
                                  variant: "bodyBig"
                                }),
                            item: true
                          })), React.createElement(Core.Grid, {
                        children: Belt_Option.getWithDefault(controller, null),
                        item: true
                      }), React.createElement(Core.Grid, {
                        children: null,
                        container: true,
                        item: true,
                        spacing: 3
                      }, tmp, tmp$3)),
              borderColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
              borderLeft: 2,
              paddingLeft: 1
            });
}

var InputStyles;

var make = Flash_Edit_DateInput;

export {
  InputStyles ,
  make ,
}
/* react Not a pure module */
