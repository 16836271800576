// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReDate_utils from "@mobily/rescript-date/src/ReDate_utils.bs.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../../../../../../common/components/App_Checkbox.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../../../../../common/types/App_Types_Date.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../../../../common/styles/App_Styles_Common.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Messages_ModelVersion$DvmAdminFrontendFr from "../../../../../../../../intl/messages/modelVersion/Messages_ModelVersion.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as App_DoubleSelectFormInput$DvmAdminFrontendFr from "../../../../../../../../common/components/input/App_DoubleSelectFormInput.bs.js";

function Flash_Edit_Version_Perf_Edit_Filters(Props) {
  var selectedVersionsList = Props.selectedVersionsList;
  var setSelectedVersionsListFiltered = Props.setSelectedVersionsListFiltered;
  var page = Props.page;
  var setPage = Props.setPage;
  var rowsPerPage = Props.rowsPerPage;
  var setTotalFilteredRows = Props.setTotalFilteredRows;
  var onlyLatest = Props.onlyLatest;
  var setOnlylatest = Props.setOnlylatest;
  var setSelectedVersionsListFilteredPaged = Props.setSelectedVersionsListFilteredPaged;
  var withClosedModels = Props.withClosedModels;
  var setWithClosedModels = Props.setWithClosedModels;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return "";
      });
  var setModel = match[1];
  var model = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setVersion = match$1[1];
  var version = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setCategory = match$2[1];
  var category = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var setVersionName = match$3[1];
  var versionName = match$3[0];
  var match$4 = React.useState(function () {
        
      });
  var setDate = match$4[1];
  var date = match$4[0];
  var models = Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(selectedVersionsList, (function (x) {
                      return x.model;
                    })))));
  var modelVersions = Belt_List.map(models, (function (x) {
          return {
                  parent: x,
                  child: Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(Belt_List.keep(selectedVersionsList, (function (y) {
                                          return y.model === x;
                                        })), (function (x) {
                                      return x.version;
                                    })))))
                };
        }));
  var categories = model === "" ? Belt_List.concat(models, Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.keepMap(Belt_List.map(selectedVersionsList, (function (x) {
                                return x.categoryID;
                              })), (function (x) {
                            return x;
                          })))))) : Belt_List.add(Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.keepMap(Belt_List.map(Belt_List.keep(selectedVersionsList, (function (x) {
                                    return x.model === model;
                                  })), (function (x) {
                                return x.categoryID;
                              })), (function (x) {
                            return x;
                          }))))), model);
  var filterSortAndPage = function (param) {
    var filteredList = Belt_List.keep(selectedVersionsList, (function (x) {
            if ((
                model.length > 0 ? x.model === model : true
              ) && (
                version.length > 0 ? x.version === version : true
              ) && (
                versionName.length > 0 ? Belt_Option.getWithDefault(x.versionName, "").toLowerCase().includes(versionName.toLowerCase()) : true
              ) && (
                category.length > 0 ? Belt_Option.getWithDefault(x.categoryID, x.model) === category : true
              )) {
              return Belt_Option.mapWithDefault(date, true, (function (date) {
                            if (!App_Types_Date$DvmAdminFrontendFr.isValid(date)) {
                              return true;
                            }
                            var date$1 = ReDate_utils.makeStartOfDayDate(date);
                            if (Belt_Option.mapWithDefault(x.startDate, true, (function (start) {
                                      return ReDate.isBefore(ReDate_utils.makeStartOfDayDate(start), date$1) ? true : ReDate.isEqual(ReDate_utils.makeStartOfDayDate(start), date$1);
                                    }))) {
                              return Belt_Option.mapWithDefault(x.endDate, true, (function (end) {
                                            if (ReDate.isAfter(ReDate_utils.makeStartOfDayDate(end), date$1)) {
                                              return true;
                                            } else {
                                              return ReDate.isEqual(ReDate_utils.makeStartOfDayDate(end), date$1);
                                            }
                                          }));
                            } else {
                              return false;
                            }
                          }));
            } else {
              return false;
            }
          }));
    if (onlyLatest) {
      return Belt_List.map(Belt_MapString.toList(Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(Belt_List.sort(filteredList, (function (a, b) {
                                        return ReDate.compareAsc(Belt_Option.getWithDefault(a.endDate, new Date(new Date().setFullYear(3000.0))), Belt_Option.getWithDefault(b.endDate, new Date(new Date().setFullYear(3000.0))));
                                      })), (function (x) {
                                    return [
                                            x.modelVersionID,
                                            x
                                          ];
                                  }))))), (function (param) {
                    return param[1];
                  }));
    } else {
      return filteredList;
    }
  };
  React.useEffect((function () {
          var list = filterSortAndPage(undefined);
          Curry._1(setTotalFilteredRows, (function (param) {
                  return Belt_List.length(list);
                }));
          Curry._1(setSelectedVersionsListFiltered, (function (param) {
                  return list;
                }));
          var paged = Belt_List.keepWithIndex(list, (function (param, index) {
                  if (index >= Math.imul(page, rowsPerPage)) {
                    return index < Math.imul(page + 1 | 0, rowsPerPage);
                  } else {
                    return false;
                  }
                }));
          Curry._1(setSelectedVersionsListFilteredPaged, (function (param) {
                  return paged;
                }));
          Curry._1(setPage, (function (param) {
                  return 0;
                }));
        }), [
        model,
        version,
        versionName,
        category,
        selectedVersionsList,
        date,
        onlyLatest
      ]);
  React.useEffect((function () {
          var list = filterSortAndPage(undefined);
          var paged = Belt_List.keepWithIndex(list, (function (param, index) {
                  if (index >= Math.imul(page, rowsPerPage)) {
                    return index < Math.imul(page + 1 | 0, rowsPerPage);
                  } else {
                    return false;
                  }
                }));
          Curry._1(setSelectedVersionsListFilteredPaged, (function (param) {
                  return paged;
                }));
        }), [page]);
  React.useEffect((function () {
          Curry._1(setVersion, (function (param) {
                  return "";
                }));
          Curry._1(setCategory, (function (param) {
                  return "";
                }));
        }), [model]);
  return React.createElement(Core.Grid, {
              children: null,
              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
              container: true
            }, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true,
                  style: {
                    width: "fit-content"
                  }
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_DoubleSelectFormInput$DvmAdminFrontendFr.make, {
                            labelParent: Messages_Common$DvmAdminFrontendFr.model,
                            labelChild: Messages_Common$DvmAdminFrontendFr.version,
                            valueParent: {
                              TAG: /* Single */0,
                              _0: {
                                value: model,
                                onChange: (function (value) {
                                    Curry._1(setModel, (function (param) {
                                            return value;
                                          }));
                                  })
                              }
                            },
                            valueChild: {
                              TAG: /* Single */0,
                              _0: {
                                value: version,
                                onChange: (function (value) {
                                    Curry._1(setVersion, (function (param) {
                                            return value;
                                          }));
                                  })
                              }
                            },
                            options: modelVersions,
                            classNameParent: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
                            classNameChild: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(Core.FormControlLabel, {
                            classes: {
                              label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                            },
                            control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                  checked: withClosedModels,
                                  onClick: (function ($$event) {
                                      Curry._1(setWithClosedModels, (function (param) {
                                              return $$event.target.checked;
                                            }));
                                    })
                                }),
                            label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.includeClosedCars)
                          }),
                      item: true,
                      style: {
                        whiteSpace: "nowrap",
                        width: "1px"
                      }
                    })), React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                        label: Messages_Common$DvmAdminFrontendFr.category,
                        value: {
                          TAG: /* Single */0,
                          _0: {
                            value: category,
                            onChange: (function (value) {
                                Curry._1(setCategory, (function (param) {
                                        return value;
                                      }));
                              })
                          }
                        },
                        options: {
                          TAG: /* Unlabeled */0,
                          _0: categories
                        },
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                        onChange: (function (value) {
                            Curry._1(setVersionName, (function (param) {
                                    return value;
                                  }));
                          }),
                        value: versionName,
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
                        label: Messages_ModelVersion$DvmAdminFrontendFr.labelVersion
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true,
                  style: {
                    width: "fit-content"
                  }
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, {
                            label: Messages_Flash_Edit$DvmAdminFrontendFr.dateInput,
                            value: date,
                            onChange: (function (value) {
                                Curry._1(setDate, (function (param) {
                                        return value;
                                      }));
                              }),
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(Core.FormControlLabel, {
                            classes: {
                              label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                            },
                            control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                  checked: onlyLatest,
                                  onClick: (function ($$event) {
                                      Curry._1(setOnlylatest, (function (param) {
                                              return $$event.target.checked;
                                            }));
                                    })
                                }),
                            label: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.filterOnlyLatest)
                          }),
                      item: true,
                      style: {
                        whiteSpace: "nowrap",
                        width: "1px"
                      }
                    })));
}

var InputStyles;

var Types;

var make = Flash_Edit_Version_Perf_Edit_Filters;

export {
  InputStyles ,
  Types ,
  make ,
}
/* react Not a pure module */
