// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../components/table/App_Table_Head.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Table_Styles$DvmAdminFrontendFr from "../../../components/table/App_Table_Styles.bs.js";
import * as File_Upload_Types$DvmAdminFrontendFr from "../../File_Upload_Types.bs.js";
import * as Messages_File_Upload$DvmAdminFrontendFr from "../../../../intl/messages/Messages_File_Upload.bs.js";

function reFirstToString(re) {
  return Belt_Option.mapWithDefault(re, undefined, (function (x) {
                return Belt_Option.mapWithDefault(Belt_Array.get(x, 1), undefined, (function (val) {
                              if (val == null) {
                                return ;
                              } else {
                                return Caml_option.some(val);
                              }
                            }));
              }));
}

function tryParseError(row, intl) {
  var rowNumRegex = /Record (\d+)/.exec(row);
  var match = row.includes("plContracts") ? [
      Caml_option.null_to_opt(/plContracts (\d+)/.exec(row)),
      Messages_File_Upload$DvmAdminFrontendFr.contractProtocol,
      /* PicklistMissing */0
    ] : (
      row.includes("plCompanySIREN") ? [
          Caml_option.null_to_opt(/plCompanySIREN (\d+)/.exec(row)),
          Messages_Common$DvmAdminFrontendFr.siren,
          /* PicklistMissing */0
        ] : (
          row.includes("Showing first") ? [
              Caml_option.null_to_opt(/Showing first 10 of (\d+)/.exec(row)),
              undefined,
              /* ShowingLines */2
            ] : [
              undefined,
              undefined,
              undefined
            ]
        )
    );
  var errorKind = match[2];
  var tableMessage = match[1];
  var captureVal = reFirstToString(match[0]);
  var captureRow = reFirstToString(rowNumRegex === null ? undefined : Caml_option.some(rowNumRegex));
  if (errorKind === undefined) {
    return row;
  }
  switch (errorKind) {
    case /* PicklistMissing */0 :
    case /* InvalidDate */1 :
        if (captureRow !== undefined && captureVal !== undefined && tableMessage !== undefined) {
          return intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.picklistMissingError, {
                      rowNumber: captureRow,
                      value: captureVal,
                      table: intl.formatMessage(tableMessage)
                    });
        } else {
          return row;
        }
    case /* ShowingLines */2 :
        if (captureVal !== undefined) {
          return intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.showingFirstTenOfRows, {
                      number: captureVal
                    });
        } else {
          return row;
        }
    
  }
}

function File_Upload_Excel_Check_Stage_Table(Props) {
  var checkWithStageResult = Props.checkWithStageResult;
  var setNextDisabled = Props.setNextDisabled;
  var intl = ReactIntl.useIntl();
  var okMessage = React.createElement(App_Typography$DvmAdminFrontendFr.make, {
        color: "primary",
        children: intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.excelOK),
        variant: "bodyBig"
      });
  React.useEffect((function () {
          var stageResultsError = Belt_Option.mapWithDefault(checkWithStageResult.results, false, (function (x) {
                  return Belt_List.length(x) > 0;
                }));
          var errorDescriptionHasRows = Belt_Option.mapWithDefault(checkWithStageResult.errorDescriptionRows, false, (function (x) {
                  return Belt_List.length(x) > 0;
                }));
          if (stageResultsError || errorDescriptionHasRows) {
            Curry._1(setNextDisabled, (function (param) {
                    return true;
                  }));
          } else {
            Curry._1(setNextDisabled, (function (param) {
                    return false;
                  }));
          }
        }), [checkWithStageResult]);
  var checksResult = function (param) {
    return Belt_Option.mapWithDefault(checkWithStageResult.results, okMessage, (function (results) {
                  if (Belt_List.length(results) === 0) {
                    return okMessage;
                  } else {
                    return React.createElement(Core.TableContainer, {
                                children: React.createElement(Core.Table, {
                                      children: null,
                                      stickyHeader: true
                                    }, React.createElement(Core.TableHead, {
                                          children: React.createElement(Core.TableRow, {
                                                children: Belt_List.toArray(Belt_List.map({
                                                          hd: "Ligne",
                                                          tl: {
                                                            hd: "Erreur",
                                                            tl: /* [] */0
                                                          }
                                                        }, (function (column) {
                                                            return React.createElement(Core.TableCell, {
                                                                        children: column,
                                                                        className: App_Table_Head$DvmAdminFrontendFr.headerText,
                                                                        key: column
                                                                      });
                                                          }))),
                                                className: App_Table_Head$DvmAdminFrontendFr.wholeRow
                                              })
                                        }), React.createElement(Core.TableBody, {
                                          children: Belt_List.toArray(Belt_List.mapWithIndex(results, (function (rowIndex, row) {
                                                      return React.createElement(Core.TableRow, {
                                                                  children: null,
                                                                  key: "row" + String(rowIndex)
                                                                }, React.createElement(Core.TableCell, {
                                                                      children: React.createElement("div", {
                                                                            className: App_Table_Styles$DvmAdminFrontendFr.tableCellContent
                                                                          }, React.createElement(Core.Grid, {
                                                                                children: React.createElement(Core.Grid, {
                                                                                      children: row.rowNumber,
                                                                                      item: true
                                                                                    }),
                                                                                container: true,
                                                                                spacing: 2
                                                                              })),
                                                                      className: App_Table_Styles$DvmAdminFrontendFr.tableCell(undefined, undefined),
                                                                      key: "rowNumber"
                                                                    }), Belt_Option.mapWithDefault(Belt_List.get(row.errors, 0), null, (function (error) {
                                                                        return React.createElement(Core.TableCell, {
                                                                                    children: React.createElement("div", {
                                                                                          className: App_Table_Styles$DvmAdminFrontendFr.tableCellContent
                                                                                        }, React.createElement(Core.Grid, {
                                                                                              children: React.createElement(Core.Grid, {
                                                                                                    children: File_Upload_Types$DvmAdminFrontendFr.errorToMessage(error.errorType, intl),
                                                                                                    item: true
                                                                                                  }),
                                                                                              container: true,
                                                                                              spacing: 2
                                                                                            })),
                                                                                    className: App_Table_Styles$DvmAdminFrontendFr.tableCell(undefined, undefined),
                                                                                    key: "cell" + String(rowIndex)
                                                                                  });
                                                                      })));
                                                    })))
                                        })),
                                className: App_Table_Styles$DvmAdminFrontendFr.table,
                                style: {
                                  height: "50vh"
                                }
                              });
                  }
                }));
  };
  return Belt_Option.mapWithDefault(checkWithStageResult.errorDescriptionRows, checksResult(undefined), (function (errorRows) {
                if (Belt_List.length(errorRows) > 0) {
                  return React.createElement(Core.TableContainer, {
                              children: React.createElement(Core.Table, {
                                    children: null,
                                    stickyHeader: true
                                  }, React.createElement(Core.TableHead, {
                                        children: React.createElement(Core.TableRow, {
                                              children: Belt_List.toArray(Belt_List.map({
                                                        hd: "Erreur",
                                                        tl: /* [] */0
                                                      }, (function (column) {
                                                          return React.createElement(Core.TableCell, {
                                                                      children: column,
                                                                      className: App_Table_Head$DvmAdminFrontendFr.headerText,
                                                                      key: column
                                                                    });
                                                        }))),
                                              className: App_Table_Head$DvmAdminFrontendFr.wholeRow
                                            })
                                      }), React.createElement(Core.TableBody, {
                                        children: Belt_List.toArray(Belt_List.mapWithIndex(errorRows, (function (rowIndex, row) {
                                                    return React.createElement(Core.TableRow, {
                                                                children: React.createElement(Core.TableCell, {
                                                                      children: React.createElement("div", {
                                                                            className: App_Table_Styles$DvmAdminFrontendFr.tableCellContent
                                                                          }, React.createElement(Core.Grid, {
                                                                                children: React.createElement(Core.Grid, {
                                                                                      children: tryParseError(row, intl),
                                                                                      item: true
                                                                                    }),
                                                                                container: true,
                                                                                spacing: 2
                                                                              })),
                                                                      className: App_Table_Styles$DvmAdminFrontendFr.tableCell(undefined, undefined),
                                                                      key: "cell" + String(rowIndex)
                                                                    }),
                                                                key: "row" + String(rowIndex)
                                                              });
                                                  })))
                                      })),
                              className: App_Table_Styles$DvmAdminFrontendFr.table,
                              style: {
                                height: "50vh"
                              }
                            });
                } else {
                  return checksResult(undefined);
                }
              }));
}

var Types;

var Styles;

var make = File_Upload_Excel_Check_Stage_Table;

export {
  Types ,
  Styles ,
  reFirstToString ,
  tryParseError ,
  make ,
}
/* react Not a pure module */
