// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "@emotion/css";
import * as Theme_Colors$DvmAdminFrontendFr from "../../theme/Theme_Colors.bs.js";

var searchFiltersBox = Css.css({
      margin: "17px 0px",
      gridColumnGap: "15px",
      gridRowGap: "10px"
    });

var shortInput = Css.css({
      width: "125px"
    });

var standardInput = Css.css({
      width: "200px"
    });

var longInput = Css.css({
      width: "300px"
    });

var extraLongInput = Css.css({
      width: "620px"
    });

var fullLength = Css.css({
      width: "100%"
    });

var chipInput = Css.css({
      maxWidth: "600px",
      minWidth: "120px"
    });

var chipInputLong = Css.css({
      maxWidth: "600px",
      minWidth: "250px"
    });

var chipInputExtraLong = Css.css({
      maxWidth: "600px",
      minWidth: "300px"
    });

var chipInputExtraLongPlus = Css.css({
      maxWidth: "600px",
      minWidth: "400px"
    });

var filterCheckboxLabel = Css.css({
      color: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
      fontSize: "14px",
      fontWeight: "600"
    });

var Inputs = {
  searchFiltersBox: searchFiltersBox,
  shortInput: shortInput,
  standardInput: standardInput,
  longInput: longInput,
  extraLongInput: extraLongInput,
  fullLength: fullLength,
  chipInput: chipInput,
  chipInputLong: chipInputLong,
  chipInputExtraLong: chipInputExtraLong,
  chipInputExtraLongPlus: chipInputExtraLongPlus,
  filterCheckboxLabel: filterCheckboxLabel
};

var shortInput$1 = Css.css(Object.assign({}, {
          width: "90px"
        }, {
          "& > div *": {
            paddingTop: "0px"
          }
        }));

var standardInput$1 = Css.css(Object.assign({}, {
          width: "120px"
        }, {
          "& > div *": {
            paddingTop: "0px"
          }
        }));

var longInput$1 = Css.css(Object.assign({}, {
          width: "320px"
        }, {
          "& > div *": {
            paddingTop: "0px"
          }
        }));

var dropdownInput = Css.css({
      paddingTop: "0px",
      width: "130px"
    });

var input = Css.css(Object.assign({}, {}, {
          "& > * > input": {
            padding: "unset !important"
          },
          "& > *": {
            backgroundColor: "transparent !important",
            paddingTop: "unset !important"
          }
        }));

var TableInputs = {
  shortInput: shortInput$1,
  standardInput: standardInput$1,
  longInput: longInput$1,
  dropdownInput: dropdownInput,
  input: input
};

var tooltip = Css.css({
      backgroundColor: "white",
      border: "1px solid black"
    });

var tooltipLineBreak = Css.css({
      backgroundColor: "white",
      border: "1px solid black",
      whiteSpace: "pre-line"
    });

export {
  Inputs ,
  TableInputs ,
  tooltip ,
  tooltipLineBreak ,
}
/* searchFiltersBox Not a pure module */
