// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as App_Spinner$DvmAdminFrontendFr from "../../../common/components/App_Spinner.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Table_Result$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Result.bs.js";
import * as Messages_MarginGrid$DvmAdminFrontendFr from "../../../intl/messages/marginGrid/Messages_MarginGrid.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Pagination.bs.js";
import * as ModelVersion_Grid_Api$DvmAdminFrontendFr from "./ModelVersion_Grid_Api.bs.js";
import * as ModelVersion_Grid_Table$DvmAdminFrontendFr from "./ModelVersion_Grid_Table.bs.js";
import * as Messages_ModelVersionGrid$DvmAdminFrontendFr from "../../../intl/messages/modelVersionGrid/Messages_ModelVersionGrid.bs.js";
import * as ModelVersion_Grid_Filters$DvmAdminFrontendFr from "./ModelVersion_Grid_Filters.bs.js";

function ModelVersion_Grid_Page(Props) {
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match[1];
  var tablePage = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var withClosedModels = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setWhatTable = match$2[1];
  var whatTable = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var budget = match$3[0];
  var match$4 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: Caml_array.get(App_Table_Pagination$DvmAdminFrontendFr.options, 0),
                headers: whatTable !== undefined ? ModelVersion_Grid_Table$DvmAdminFrontendFr.headersConfiguration(budget, whatTable) : /* [] */0,
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$4[1];
  var fetchRequest = match$4[0];
  var alert = ReactAlert.useAlert();
  var match$5 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$6 = match$5[0];
  var userManager = match$6.userManager;
  var selectedRole = match$6.selectedRole;
  var fetchData = function (submissionCallbacks, budget, fetchRequest) {
    if (whatTable !== undefined) {
      ModelVersion_Grid_Api$DvmAdminFrontendFr.fetchModelVersionSearch(submissionCallbacks, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, fetchRequest), budget, whatTable, selectedRole, withClosedModels);
    }
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  var tmp;
  if (whatTable !== undefined) {
    var tmp$1;
    if (typeof tablePage === "number") {
      tmp$1 = tablePage === /* NotStarted */0 ? null : React.createElement(App_Spinner$DvmAdminFrontendFr.make, {});
    } else if (tablePage.TAG === /* Ok */0) {
      tmp$1 = React.createElement(App_Table_Result$DvmAdminFrontendFr.make, {
            number: tablePage._0.total,
            message: whatTable === /* Model */0 ? Messages_Common$DvmAdminFrontendFr.models : Messages_Common$DvmAdminFrontendFr.versions
          });
    } else {
      Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                message: {
                  TAG: /* RestError */2,
                  _0: tablePage._0
                }
              }));
      tmp$1 = null;
    }
    var tmp$2 = {
      onClick: (function (param) {
          Curry._1(setWhatTable, (function (param) {
                  return /* Model */0;
                }));
        }),
      children: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.models)
    };
    var tmp$3 = whatTable === /* Model */0 ? ({
          borderBottom: "solid 5px " + Theme_Colors$DvmAdminFrontendFr.primaryBrown + ""
        }) : undefined;
    if (tmp$3 !== undefined) {
      tmp$2.style = Caml_option.valFromOption(tmp$3);
    }
    var tmp$4 = {
      onClick: (function (param) {
          Curry._1(setWhatTable, (function (param) {
                  return /* Version */1;
                }));
        }),
      children: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.versions)
    };
    var tmp$5 = whatTable === /* Version */1 ? ({
          borderBottom: "solid 5px " + Theme_Colors$DvmAdminFrontendFr.primaryBrown + ""
        }) : undefined;
    if (tmp$5 !== undefined) {
      tmp$4.style = Caml_option.valFromOption(tmp$5);
    }
    tmp = React.createElement(React.Fragment, undefined, tmp$1, React.createElement(Core.Grid, {
              children: null,
              container: true,
              spacing: 2
            }, React.createElement(Core.Grid, {
                  children: React.createElement(Core.Button, tmp$2),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Core.Button, tmp$4),
                  item: true
                })), React.createElement(ModelVersion_Grid_Table$DvmAdminFrontendFr.make, {
              tablePage: tablePage,
              fetchRequest: fetchRequest,
              fetchData: fetchData,
              budget: budget,
              whatTable: whatTable,
              withClosedModels: withClosedModels
            }));
  } else {
    tmp = React.createElement(App_Typography$DvmAdminFrontendFr.make, {
          style: {
            fontWeight: "bold"
          },
          children: intl.formatMessage(Messages_MarginGrid$DvmAdminFrontendFr.beforeResult),
          variant: "bodyBig"
        });
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  children: intl.formatMessage(Messages_ModelVersionGrid$DvmAdminFrontendFr.title),
                  variant: "h3"
                }), React.createElement(ModelVersion_Grid_Filters$DvmAdminFrontendFr.make, {
                  fetchData: fetchData,
                  fetchRequest: fetchRequest,
                  whatTable: whatTable,
                  setWhatTable: setWhatTable,
                  setBudget: match$3[1],
                  withClosedModels: withClosedModels,
                  setWithClosedModels: match$1[1]
                }), React.createElement(Core.Box, {
                  children: tmp
                }));
}

var Api;

var Table;

var Filter;

var make = ModelVersion_Grid_Page;

export {
  Api ,
  Table ,
  Filter ,
  make ,
}
/* react Not a pure module */
