// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_MarginGrid$DvmAdminFrontendFr from "../../../../intl/messages/marginGrid/Messages_MarginGrid.bs.js";
import * as Grid_Edit_Delete_Modal$DvmAdminFrontendFr from "./Grid_Edit_Delete_Modal.bs.js";

function Grid_Edit_Delete(Props) {
  var setActionsPending = Props.setActionsPending;
  var tableResult = Props.tableResult;
  var whatTable = Props.whatTable;
  var whatGrid = Props.whatGrid;
  var budget = Props.budget;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return null;
      });
  var setModal = match[1];
  var match$1 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$1[1];
  var result = match$1[0];
  React.useEffect((function () {
          if (typeof result === "number") {
            if (result !== /* NotStarted */0) {
              Curry._1(setActionsPending, (function (param) {
                      return true;
                    }));
            }
            
          } else if (result.TAG === /* Ok */0) {
            Curry._1(setActionsPending, (function (param) {
                    return false;
                  }));
          } else {
            Curry._1(setActionsPending, (function (param) {
                    return false;
                  }));
          }
        }), [result]);
  var tableLength = Belt_Option.mapWithDefault(tableResult, 0, Belt_List.length);
  var tmp;
  tmp = typeof result === "number" ? null : (
      result.TAG === /* Ok */0 ? React.createElement(Core.Grid, {
              children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                    size: /* XLarge */4,
                    type_: /* Tick */1
                  }),
              item: true,
              style: {
                color: "green"
              }
            }) : React.createElement(Core.Grid, {
              children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                    size: /* XLarge */4,
                    type_: /* Cross */42
                  }),
              item: true,
              style: {
                color: "red"
              }
            })
    );
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                            gutterBottom: true,
                            style: {
                              fontWeight: "600"
                            },
                            children: intl.formatMessage(Messages_MarginGrid$DvmAdminFrontendFr.deleteMargin),
                            variant: "bodyBig"
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(Core.Grid, {
                            alignItems: "center",
                            children: null,
                            container: true,
                            spacing: 3
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                      color: "error",
                                      gutterBottom: true,
                                      style: {
                                        fontWeight: "600"
                                      },
                                      children: intl.formatMessage(Messages_MarginGrid$DvmAdminFrontendFr.deleteMarginMessage, {
                                            number: tableLength
                                          }),
                                      variant: "bodyBig"
                                    }),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                      onClick: (function (param) {
                                          Curry._1(setModal, (function (param) {
                                                  return React.createElement(Grid_Edit_Delete_Modal$DvmAdminFrontendFr.make, {
                                                              tableResult: tableResult,
                                                              setResult: setResult,
                                                              onClose: (function (param) {
                                                                  Curry._1(setModal, (function (param) {
                                                                          return null;
                                                                        }));
                                                                }),
                                                              whatTable: whatTable,
                                                              whatGrid: whatGrid,
                                                              budget: budget
                                                            });
                                                }));
                                        }),
                                      label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.$$delete),
                                      color: "delete",
                                      size: "medium",
                                      disabled: tableLength === 0
                                    }),
                                item: true
                              }), tmp),
                      item: true
                    })), match[0]);
}

var Types;

var make = Grid_Edit_Delete;

export {
  Types ,
  make ,
}
/* react Not a pure module */
