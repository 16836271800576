// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Theme_Size$DvmAdminFrontendFr from "./Theme_Size.bs.js";

function getValue(fontSize) {
  if (fontSize === "button") {
    return 1.6;
  } else if (fontSize === "subtitle1") {
    return 1.6;
  } else if (fontSize === "subtitle2") {
    return 1.4;
  } else if (fontSize === "h1") {
    return 3.8;
  } else if (fontSize === "h2") {
    return 3.2;
  } else if (fontSize === "h3") {
    return 2.4;
  } else if (fontSize === "h4") {
    return 2.2;
  } else if (fontSize === "h5") {
    return 2.0;
  } else if (fontSize === "h6") {
    return 1.8;
  } else if (fontSize === "caption") {
    return 1.2;
  } else if (fontSize === "body2") {
    return 1.4;
  } else {
    return 1.6;
  }
}

function getRem(fontSize) {
  return Theme_Size$DvmAdminFrontendFr.toRem(getValue(fontSize));
}

function multiply(by, fontSize) {
  return Theme_Size$DvmAdminFrontendFr.multiplyToRem(by, getValue(fontSize));
}

var FontSize = {
  h1: 3.8,
  h2: 3.2,
  h3: 2.4,
  h4: 2.2,
  h5: 2.0,
  h6: 1.8,
  subtitle1: 1.6,
  subtitle2: 1.4,
  body1: 1.6,
  body2: 1.4,
  button: 1.6,
  caption: 1.2,
  hero: 4.8,
  getValue: getValue,
  getRem: getRem,
  multiply: multiply
};

export {
  FontSize ,
}
/* No side effect */
