// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../common/App_Api.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../common/components/App_Checkbox.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../../context/router/Context_Router.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as App_CompanyFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_CompanyFormInput.bs.js";
import * as Messages_PCDAgreement$DvmAdminFrontendFr from "../../../intl/messages/pcdAgreement/Messages_PCDAgreement.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as PCDAgreement_Search_Api$DvmAdminFrontendFr from "./PCDAgreement_Search_Api.bs.js";
import * as PCDAgreement_Search_Form$DvmAdminFrontendFr from "./PCDAgreement_Search_Form.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as PCDAgreement_Search_Types$DvmAdminFrontendFr from "./PCDAgreement_Search_Types.bs.js";

function PCDAgreement_Search_Filter(Props) {
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var match = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match[1];
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  var match$3 = React.useState(function () {
        return /* [] */0;
      });
  var setDealers = match$3[1];
  var dealers = match$3[0];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModels = match$4[1];
  var models = match$4[0];
  var match$5 = React.useState(function () {
        return /* [] */0;
      });
  var setCategoryCodes = match$5[1];
  var match$6 = React.useState(function () {
        return /* NotStarted */0;
      });
  var match$7 = React.useState(function () {
        return false;
      });
  var setShowUnlimited = match$7[1];
  var showUnlimited = match$7[0];
  var form = PCDAgreement_Search_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.getWithDefault(match[0].pcdAgreementFilter, PCDAgreement_Search_Form$DvmAdminFrontendFr.initialState), (function (param, submissionCallbacks) {
          Curry._2(fetchData, submissionCallbacks, {
                page: 0,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: fetchRequest.headers,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                              hd: param.siren,
                              tl: {
                                hd: param.dealerID,
                                tl: {
                                  hd: param.modelID,
                                  tl: {
                                    hd: param.categoryID,
                                    tl: {
                                      hd: param.yearBudget,
                                      tl: {
                                        hd: param.responseNumber,
                                        tl: {
                                          hd: showUnlimited ? PCDAgreement_Search_Types$DvmAdminFrontendFr.Fields.volume + "=-1" : param.volume,
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  React.useEffect((function () {
          PCDAgreement_Search_Api$DvmAdminFrontendFr.queryForDealersSelect(alert, userManager, setDealers, selectedRole);
          App_Api$DvmAdminFrontendFr.fetchModels(setModels, userManager, selectedRole, undefined, undefined, undefined);
          PCDAgreement_Search_Api$DvmAdminFrontendFr.queryForAVSSelects(alert, userManager, setCategoryCodes, selectedRole);
        }), []);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.forError(models, (function (error) {
                  Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                            message: {
                              TAG: /* RestError */2,
                              _0: error
                            }
                          }));
                }));
        }), [models]);
  React.useEffect((function () {
          if (Belt_List.length(dealers) === 1) {
            Belt_Option.mapWithDefault(Belt_List.get(dealers, 0), undefined, (function (dealer) {
                    Curry._2(form.updateDealerID, (function (input, dealerID) {
                            return {
                                    siren: input.siren,
                                    dealerID: dealerID,
                                    modelID: input.modelID,
                                    categoryID: input.categoryID,
                                    yearBudget: input.yearBudget,
                                    responseNumber: input.responseNumber,
                                    volume: input.volume
                                  };
                          }), dealer);
                  }));
          }
          
        }), [dealers]);
  var tmp = {
    onChange: Curry._1(form.updateVolume, (function (input, volume) {
            return {
                    siren: input.siren,
                    dealerID: input.dealerID,
                    modelID: input.modelID,
                    categoryID: input.categoryID,
                    yearBudget: input.yearBudget,
                    responseNumber: input.responseNumber,
                    volume: volume
                  };
          })),
    value: form.input.volume,
    disabled: showUnlimited,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
    label: Messages_PCDAgreement$DvmAdminFrontendFr.volume,
    inputType: "number"
  };
  if (form.volumeResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.volumeResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(dispatch, {
                        TAG: /* StorePCDAgreementFilter */30,
                        _0: form.input
                      });
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_CompanyFormInput$DvmAdminFrontendFr.make, {
                            onChange: (function (siren) {
                                Curry._2(form.updateSiren, (function (input, siren) {
                                        return {
                                                siren: siren,
                                                dealerID: input.dealerID,
                                                modelID: input.modelID,
                                                categoryID: input.categoryID,
                                                yearBudget: input.yearBudget,
                                                responseNumber: input.responseNumber,
                                                volume: input.volume
                                              };
                                      }), siren.trim());
                              }),
                            value: form.input.siren,
                            companies: match$6[0],
                            setCompanies: match$6[1],
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                            companyType: "siren"
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Common$DvmAdminFrontendFr.dealerID,
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.dealerID,
                                onChange: Curry._1(form.updateDealerID, (function (input, dealerID) {
                                        return {
                                                siren: input.siren,
                                                dealerID: dealerID,
                                                modelID: input.modelID,
                                                categoryID: input.categoryID,
                                                yearBudget: input.yearBudget,
                                                responseNumber: input.responseNumber,
                                                volume: input.volume
                                              };
                                      }))
                              }
                            },
                            options: {
                              TAG: /* Unlabeled */0,
                              _0: dealers
                            },
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Common$DvmAdminFrontendFr.model,
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.modelID,
                                onChange: Curry._1(form.updateModelID, (function (input, modelID) {
                                        return {
                                                siren: input.siren,
                                                dealerID: input.dealerID,
                                                modelID: modelID,
                                                categoryID: input.categoryID,
                                                yearBudget: input.yearBudget,
                                                responseNumber: input.responseNumber,
                                                volume: input.volume
                                              };
                                      }))
                              }
                            },
                            options: {
                              TAG: /* Unlabeled */0,
                              _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(models, /* [] */0)
                            },
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Common$DvmAdminFrontendFr.categoryCode,
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.categoryID,
                                onChange: Curry._1(form.updateCategoryID, (function (input, categoryID) {
                                        return {
                                                siren: input.siren,
                                                dealerID: input.dealerID,
                                                modelID: input.modelID,
                                                categoryID: categoryID,
                                                yearBudget: input.yearBudget,
                                                responseNumber: input.responseNumber,
                                                volume: input.volume
                                              };
                                      }))
                              }
                            },
                            options: {
                              TAG: /* Unlabeled */0,
                              _0: match$5[0]
                            },
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, {
                            label: Messages_Common$DvmAdminFrontendFr.year,
                            value: form.input.yearBudget,
                            onChange: Curry._1(form.updateYearBudget, (function (input, yearBudget) {
                                    return {
                                            siren: input.siren,
                                            dealerID: input.dealerID,
                                            modelID: input.modelID,
                                            categoryID: input.categoryID,
                                            yearBudget: yearBudget,
                                            responseNumber: input.responseNumber,
                                            volume: input.volume
                                          };
                                  })),
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
                            variant: /* YEAR */0
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry._1(form.updateResponseNumber, (function (input, responseNumber) {
                                    return {
                                            siren: input.siren,
                                            dealerID: input.dealerID,
                                            modelID: input.modelID,
                                            categoryID: input.categoryID,
                                            yearBudget: input.yearBudget,
                                            responseNumber: responseNumber,
                                            volume: input.volume
                                          };
                                  })),
                            value: form.input.responseNumber,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
                            label: Messages_PCDAgreement$DvmAdminFrontendFr.responseNumber
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      direction: "column",
                      item: true,
                      style: {
                        width: "fit-content"
                      }
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(Core.FormControlLabel, {
                                classes: {
                                  label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                },
                                control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                      checked: showUnlimited,
                                      onClick: (function ($$event) {
                                          Curry._1(setShowUnlimited, (function (param) {
                                                  return $$event.target.checked;
                                                }));
                                        })
                                    }),
                                label: intl.formatMessage(Messages_PCDAgreement$DvmAdminFrontendFr.unlimited)
                              }),
                          item: true
                        })), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var Style;

var Form;

var Api;

var make = PCDAgreement_Search_Filter;

export {
  Style ,
  Form ,
  Api ,
  make ,
}
/* react Not a pure module */
