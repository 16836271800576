// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../context/user/User_Context.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../context/user/User_Types_Utils.bs.js";

function Route_Protected(Props) {
  var roles = Props.roles;
  var redirectOpt = Props.redirect;
  var children = Props.children;
  var redirect = redirectOpt !== undefined ? redirectOpt : "/";
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var selectedRole = match[0].selectedRole;
  if (roles.TAG === /* Access */0) {
    if (!Belt_List.has(roles._0, selectedRole, User_Types_Utils$DvmAdminFrontendFr.copareRoles)) {
      RescriptReactRouter.push(redirect);
    }
    
  } else if (Belt_List.has(roles._0, selectedRole, User_Types_Utils$DvmAdminFrontendFr.copareRoles)) {
    RescriptReactRouter.push(redirect);
  }
  return children;
}

var make = Route_Protected;

export {
  make ,
}
/* RescriptReactRouter Not a pure module */
