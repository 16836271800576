// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../../intl/Intl_Utils.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../../theme/Theme_Colors.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../../../../common/components/App_LabeledText.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Order_Detail_Correct_Box$DvmAdminFrontendFr from "../Order_Detail_Correct_Box.bs.js";
import * as Order_Detail_Types_Order_Type$DvmAdminFrontendFr from "../../../Order_Detail_Types_Order_Type.bs.js";

function Order_Detail_Correct_Order_History_Content_Fleet(Props) {
  var correction = Props.correction;
  var color = Props.color;
  var title = Props.title;
  var intl = ReactIntl.useIntl();
  return React.createElement(Order_Detail_Correct_Box$DvmAdminFrontendFr.make, {
              content: Belt_Array.keepMap([
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.orderType,
                              children: Belt_Option.mapWithDefault(correction.orderType, "-", Order_Detail_Types_Order_Type$DvmAdminFrontendFr.toString)
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.orderDateV2,
                              children: Belt_Option.mapWithDefault(correction.orderDate, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    }))
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.siret,
                              children: Belt_Option.getWithDefault(correction.siret, "-")
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.finalCustomer,
                              children: Belt_Option.getWithDefault(correction.sirenFinalCustomerLLD, "-")
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.discount,
                              children: Belt_Option.mapWithDefault(correction.companyDiscountPercentage, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatPercent(intl, param);
                                    }))
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.amount,
                              children: Belt_Option.mapWithDefault(correction.planPerfAmount, "-", (function (eta) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, eta);
                                    }))
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.aquisitionType,
                              children: Belt_Option.getWithDefault(correction.acquisitionType, "-")
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.budget,
                              children: Belt_Option.getWithDefault(correction.budgetType, "-")
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.registrationDate,
                              children: Belt_Option.mapWithDefault(correction.newCarRegistrationDate, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    }))
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.registrationNumber,
                              children: Belt_Option.getWithDefault(correction.newCarRegistrationID, "-")
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.invoiceClient,
                              children: Belt_Option.mapWithDefault(correction.customerInvoiceDate, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    }))
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.invoiceNumber,
                              children: Belt_Option.getWithDefault(correction.customerInvoiceNumber, "-")
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.invoiceDateV2,
                              children: Belt_Option.mapWithDefault(correction.renaultInvoiceDate, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    }))
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.madaDate,
                              children: Belt_Option.mapWithDefault(correction.carSaleAvailibilityDateMADA, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    }))
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.madcDate,
                              children: Belt_Option.mapWithDefault(correction.carSaleAvailibilityDateMADC, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    }))
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.model,
                              children: Belt_Option.getWithDefault(correction.modelID, "-")
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.version,
                              children: Belt_Option.getWithDefault(correction.versionID, "-")
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.phase,
                              children: Belt_Option.getWithDefault(correction.phase, "-")
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.seller,
                              children: Belt_Option.getWithDefault(correction.salesmanName, "-")
                            })),
                    Caml_option.some(React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.agent,
                              children: Belt_Option.getWithDefault(correction.agentCode, "-")
                            }))
                  ], (function (x) {
                      return x;
                    })),
              bgcolor: Theme_Colors$DvmAdminFrontendFr.grayLight1,
              color: color,
              title: title
            });
}

var make = Order_Detail_Correct_Order_History_Content_Fleet;

export {
  make ,
}
/* react Not a pure module */
