// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as Flash_Edit_NonFleet_Types$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_Types.bs.js";

function hasFormChanged(form, initialValuesResult) {
  return App_Types_Result$DvmAdminFrontendFr.mapWithDefault(initialValuesResult, false, (function (x) {
                var modelsSame = Belt_List.eq(Belt_List.sort(x.includedModels, Caml.string_compare), Belt_List.sort(form.input.models, Caml.string_compare), (function (a, b) {
                        return a === b;
                      }));
                var versionsSame = Belt_List.eq(Belt_List.sort(Belt_List.map(x.excludedVersions, (function (x) {
                                return x.modelVersionID;
                              })), Caml.string_compare), Belt_List.sort(Belt_List.map(form.input.versions, (function (x) {
                                return x.value;
                              })), Caml.string_compare), (function (a, b) {
                        return a === b;
                      }));
                if (modelsSame) {
                  return !versionsSame;
                } else {
                  return true;
                }
              }));
}

function getDelimitedPhases(alreadySelectedPhasesOpt, models, result) {
  var alreadySelectedPhases = alreadySelectedPhasesOpt !== undefined ? alreadySelectedPhasesOpt : /* [] */0;
  var alreadySelectedModelsCleaned = Belt_List.keep(Belt_List.keepMap(Belt_List.map(alreadySelectedPhases, (function (x) {
                  return Belt_Array.get(x.split(Flash_Edit_NonFleet_Types$DvmAdminFrontendFr.delimiter), 0);
                })), (function (x) {
              return x;
            })), (function (x) {
          return Belt_List.has(models, x, (function (a, b) {
                        return a === b;
                      }));
        }));
  var selectedPhasesCleaned = Belt_List.keepMap(Belt_List.map(alreadySelectedPhases, (function (phases) {
              var split = phases.split(Flash_Edit_NonFleet_Types$DvmAdminFrontendFr.delimiter);
              var mod = Belt_Array.get(split, 0);
              var phas = Belt_Array.get(split, 1);
              if (mod !== undefined && phas !== undefined && Belt_List.has(models, mod, (function (a, b) {
                        return a === b;
                      }))) {
                return {
                        label: {
                          TAG: /* String */3,
                          _0: mod + " - " + phas
                        },
                        value: mod + Flash_Edit_NonFleet_Types$DvmAdminFrontendFr.delimiter + phas
                      };
              }
              
            })), (function (x) {
          return x;
        }));
  return Belt_List.concat(Belt_List.concatMany(Belt_List.toArray(Belt_List.keepMap(App_Types_Result$DvmAdminFrontendFr.getWithDefault(result, /* [] */0), (function (model) {
                            if (Belt_List.has(alreadySelectedModelsCleaned, model.parent, (function (a, b) {
                                      return a === b;
                                    }))) {
                              return ;
                            } else {
                              return Belt_List.map(Belt_SetString.toList(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_List.toArray(Belt_List.map(model.child, (function (phase) {
                                                                return phase.split(",");
                                                              })))))), (function (phase) {
                                            return {
                                                    label: {
                                                      TAG: /* String */3,
                                                      _0: model.parent + " - " + phase
                                                    },
                                                    value: model.parent + Flash_Edit_NonFleet_Types$DvmAdminFrontendFr.delimiter + phase
                                                  };
                                          }));
                            }
                          })))), selectedPhasesCleaned);
}

function getDistinctEngines(result) {
  return Belt_List.keep(Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.concatMany(Belt_List.toArray(Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(result, /* [] */0), (function (model) {
                                        return model.child;
                                      }))))))), (function (x) {
                return x.length > 0;
              }));
}

function getPhasesNotAllowedToRemove(form) {
  return Belt_List.concatMany(Belt_List.toArray(Belt_List.keep(Belt_List.map(form.input.models, (function (model) {
                            return Belt_List.keepMap(Belt_List.map(form.input.modelPhases, (function (phase) {
                                              var splitArray = phase.split(Flash_Edit_NonFleet_Types$DvmAdminFrontendFr.delimiter);
                                              var one = Belt_Array.get(splitArray, 0);
                                              if (one !== undefined && one === model) {
                                                return phase;
                                              }
                                              
                                            })), (function (x) {
                                          return x;
                                        }));
                          })), (function (x) {
                        return Belt_List.length(x) < 2;
                      }))));
}

var Form;

export {
  Form ,
  hasFormChanged ,
  getDelimitedPhases ,
  getDistinctEngines ,
  getPhasesNotAllowedToRemove ,
}
/* Flash_Edit_NonFleet_Types-DvmAdminFrontendFr Not a pure module */
