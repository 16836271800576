// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";

var Fields = {
  siret: "siret",
  siren: "siren",
  siretName: "name",
  creationDate: "creationDate",
  city: "city",
  endDate: "endDate"
};

function row_encode(v) {
  return Js_dict.fromArray([
              [
                "siret",
                Decco.stringToJson(v.siret)
              ],
              [
                "siren",
                Decco.optionToJson(Decco.stringToJson, v.siren)
              ],
              [
                "siretName",
                Decco.optionToJson(Decco.stringToJson, v.siretName)
              ],
              [
                "creationDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.creationDate)
              ],
              [
                "city",
                Decco.optionToJson(Decco.stringToJson, v.city)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function row_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var siret = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "siret"), null));
  if (siret.TAG === /* Ok */0) {
    var siren = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "siren"), null));
    if (siren.TAG === /* Ok */0) {
      var siretName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "siretName"), null));
      if (siretName.TAG === /* Ok */0) {
        var creationDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "creationDate"), null));
        if (creationDate.TAG === /* Ok */0) {
          var city = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "city"), null));
          if (city.TAG === /* Ok */0) {
            var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
            if (endDate.TAG === /* Ok */0) {
              return {
                      TAG: /* Ok */0,
                      _0: {
                        siret: siret._0,
                        siren: siren._0,
                        siretName: siretName._0,
                        creationDate: creationDate._0,
                        city: city._0,
                        endDate: endDate._0
                      }
                    };
            }
            var e = endDate._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".endDate" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = city._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".city" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = creationDate._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".creationDate" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = siretName._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".siretName" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = siren._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".siren" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = siret._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".siret" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(row_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(row_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

export {
  Fields ,
  row_encode ,
  row_decode ,
  searchResult_encode ,
  searchResult_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
