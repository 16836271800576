// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Grid_Edit_Api$DvmAdminFrontendFr from "../Grid_Edit_Api.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Grid_Edit_Api_Check$DvmAdminFrontendFr from "../Grid_Edit_Api_Check.bs.js";
import * as Messages_MarginGrid$DvmAdminFrontendFr from "../../../../intl/messages/marginGrid/Messages_MarginGrid.bs.js";
import * as Grid_Edit_Modal_Table$DvmAdminFrontendFr from "./Grid_Edit_Modal_Table.bs.js";
import * as Grid_Edit_New_Row_Modal$DvmAdminFrontendFr from "../addRow/Grid_Edit_New_Row_Modal.bs.js";
import * as Grid_Edit_Modal_Collection_Form$DvmAdminFrontendFr from "./Grid_Edit_Modal_Collection_Form.bs.js";
import * as Grid_Edit_Modal_Collection_Utils$DvmAdminFrontendFr from "./Grid_Edit_Modal_Collection_Utils.bs.js";

function Grid_Edit_Modal_Collection_Display(Props) {
  var budgetOrID = Props.budgetOrID;
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var setResult = Props.setResult;
  var whatTable = Props.whatTable;
  var categoriesAll = Props.categoriesAll;
  var setActionsPending = Props.setActionsPending;
  var whatGrid = Props.whatGrid;
  var budget = Props.budget;
  var acquisitionOptions = Props.acquisitionOptions;
  var filterStartDate = Props.filterStartDate;
  var filterEndDate = Props.filterEndDate;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return false;
      });
  var setIsAllEdited = match$2[1];
  var isAllEdited = match$2[0];
  var match$3 = React.useState(function () {
        return null;
      });
  var setModal = match$3[1];
  var saveGrids = function (param) {
    var rows = Grid_Edit_Modal_Collection_Utils$DvmAdminFrontendFr.getSaveRows(param.marginRows, isAllEdited, budgetOrID);
    if (Belt_List.length(Grid_Edit_Api_Check$DvmAdminFrontendFr.getOnlyChangedRows(rows)) > 0) {
      return Grid_Edit_Api$DvmAdminFrontendFr.overwriteMarginRows(alert, userManager, setResult, whatTable, whatGrid, budget, selectedRole, rows);
    }
    
  };
  var form = Grid_Edit_Modal_Collection_Form$DvmAdminFrontendFr.MarginEditForm.useForm(Grid_Edit_Modal_Collection_Form$DvmAdminFrontendFr.initialInput, {
        whatGrid: whatGrid,
        whatTable: whatTable
      }, (function (output, param) {
          console.log(output);
          saveGrids(output);
          Curry._1(param.notifyOnSuccess, undefined);
        }));
  React.useEffect((function () {
          Grid_Edit_Modal_Collection_Utils$DvmAdminFrontendFr.resetForm(form, tablePage, setIsAllEdited, budget);
        }), [tablePage]);
  React.useEffect((function () {
          Belt_Array.mapWithIndex(form.input.marginRows, (function (index, param) {
                  Curry._3(form.updateSingleRowIsEdited, index, (function (param, isEdited) {
                          return {
                                  marginRows: Belt_Array.mapWithIndex(param.marginRows, (function (indexRow, row) {
                                          if (indexRow !== index) {
                                            return row;
                                          }
                                          var newrecord = Caml_obj.obj_dup(row);
                                          newrecord.isEdited = isEdited;
                                          return newrecord;
                                        }))
                                };
                        }), isAllEdited);
                }));
        }), [isAllEdited]);
  var tmp;
  if (isAllEdited) {
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
              children: React.createElement(Core.Button, {
                    onClick: (function ($$event) {
                        Curry._1(form.submit, undefined);
                      }),
                    children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                          size: /* XLarge */4,
                          type_: /* Tick */1,
                          color: "green"
                        }),
                    key: "submit"
                  }),
              item: true
            }), React.createElement(Core.Grid, {
              children: React.createElement(Core.Button, {
                    onClick: (function (param) {
                        Curry._1(setIsAllEdited, (function (param) {
                                return false;
                              }));
                        Grid_Edit_Modal_Collection_Utils$DvmAdminFrontendFr.resetForm(form, tablePage, setIsAllEdited, budget);
                      }),
                    children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                          size: /* XLarge */4,
                          type_: /* Cross */42
                        }),
                    key: "cancel"
                  }),
              item: true
            }));
  } else {
    var tmp$1;
    switch (whatTable) {
      case /* Model */0 :
          tmp$1 = Messages_MarginGrid$DvmAdminFrontendFr.addModel;
          break;
      case /* Version */1 :
          tmp$1 = Messages_MarginGrid$DvmAdminFrontendFr.addVersion;
          break;
      case /* GridID */2 :
          tmp$1 = Messages_MarginGrid$DvmAdminFrontendFr.addCategory;
          break;
      
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
              children: React.createElement(Core.Button, {
                    onClick: (function (param) {
                        Curry._1(setIsAllEdited, (function (param) {
                                return true;
                              }));
                      }),
                    children: null,
                    key: "edit"
                  }, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                        size: /* XLarge */4,
                        type_: /* Edit */60
                      }), React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        style: {
                          fontWeight: "600",
                          marginLeft: "10px"
                        },
                        children: intl.formatMessage(Messages_MarginGrid$DvmAdminFrontendFr.editTable),
                        variant: "body"
                      })),
              item: true
            }), whatTable === /* Version */1 ? React.createElement(Core.Grid, {
                children: React.createElement(Core.Button, {
                      onClick: (function (param) {
                          Curry._1(setModal, (function (param) {
                                  return React.createElement(Grid_Edit_New_Row_Modal$DvmAdminFrontendFr.make, {
                                              budgetOrID: budgetOrID,
                                              onClose: (function (param) {
                                                  Curry._1(setModal, (function (param) {
                                                          return null;
                                                        }));
                                                }),
                                              whatTable: whatTable,
                                              whatGrid: whatGrid,
                                              budget: budget,
                                              operation: /* AddCategory */2,
                                              setActionsPending: setActionsPending,
                                              acquisitionOptions: acquisitionOptions,
                                              filterStartDate: filterStartDate,
                                              filterEndDate: filterEndDate
                                            });
                                }));
                        }),
                      children: null,
                      disabled: Belt_List.length(categoriesAll) === 0,
                      key: "add"
                    }, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                          size: /* XLarge */4,
                          type_: /* More */17
                        }), React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                          size: /* Large */3,
                          type_: /* More */17,
                          style: {
                            left: "-10px",
                            position: "relative",
                            top: "10px"
                          }
                        }), React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                          style: {
                            fontWeight: "600"
                          },
                          children: intl.formatMessage(Messages_MarginGrid$DvmAdminFrontendFr.addVersionsOfCategory),
                          variant: "body"
                        })),
                item: true
              }) : null, React.createElement(Core.Grid, {
              children: React.createElement(Core.Button, {
                    onClick: (function (param) {
                        Curry._1(setModal, (function (param) {
                                return React.createElement(Grid_Edit_New_Row_Modal$DvmAdminFrontendFr.make, {
                                            budgetOrID: budgetOrID,
                                            onClose: (function (param) {
                                                Curry._1(setModal, (function (param) {
                                                        return null;
                                                      }));
                                              }),
                                            whatTable: whatTable,
                                            whatGrid: whatGrid,
                                            budget: budget,
                                            operation: /* Create */1,
                                            setActionsPending: setActionsPending,
                                            acquisitionOptions: acquisitionOptions,
                                            filterStartDate: filterStartDate,
                                            filterEndDate: filterEndDate
                                          });
                              }));
                      }),
                    children: null,
                    disabled: Belt_List.length(categoriesAll) === 0,
                    key: "add"
                  }, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                        size: /* XLarge */4,
                        type_: /* More */17
                      }), React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        style: {
                          fontWeight: "600",
                          marginLeft: "10px"
                        },
                        children: intl.formatMessage(tmp$1),
                        variant: "body"
                      })),
              item: true
            }));
  }
  return React.createElement(React.Fragment, undefined, React.createElement("form", {
                  onSubmit: (function ($$event) {
                      $$event.preventDefault();
                    })
                }, React.createElement(Core.Grid, {
                      children: tmp,
                      container: true,
                      justifyContent: "flex-start",
                      spacing: 2
                    }), React.createElement(Grid_Edit_Modal_Table$DvmAdminFrontendFr.make, {
                      tablePage: tablePage,
                      fetchRequest: fetchRequest,
                      fetchData: fetchData,
                      setResult: setResult,
                      form: form,
                      isAllEdited: isAllEdited,
                      whatTable: whatTable,
                      setActionsPending: setActionsPending,
                      whatGrid: whatGrid,
                      budget: budget,
                      acquisitionOptions: acquisitionOptions,
                      budgetOrID: budgetOrID,
                      filterStartDate: filterStartDate,
                      filterEndDate: filterEndDate
                    })), match$3[0]);
}

var Form;

var Api;

var Table;

var ApiCheck;

var Utils;

var make = Grid_Edit_Modal_Collection_Display;

export {
  Form ,
  Api ,
  Table ,
  ApiCheck ,
  Utils ,
  make ,
}
/* react Not a pure module */
