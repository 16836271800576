// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core from "@material-ui/core";

function App_DatesEdit_DateWithCheckbox(Props) {
  var checkboxElement = Props.checkboxElement;
  var children = Props.children;
  return React.createElement(Core.Grid, {
              alignItems: "center",
              children: null,
              container: true,
              style: {
                gridColumnGap: "24px"
              }
            }, React.createElement(Core.Grid, {
                  children: children,
                  item: true
                }), React.createElement(Core.Grid, {
                  children: checkboxElement,
                  item: true
                }));
}

var make = App_DatesEdit_DateWithCheckbox;

export {
  make ,
}
/* react Not a pure module */
