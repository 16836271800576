// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Messages_Brands$DvmAdminFrontendFr from "../../../intl/messages/brands/Messages_Brands.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_TextFieldFormInput.bs.js";

function Brands_AddEdit_Inputs(Props) {
  var form = Props.form;
  var original = Props.original;
  var tmp = {
    onChange: (function (val) {
        Curry._2(form.updateBrand, (function (input, brand) {
                return {
                        brand: brand,
                        brandLabel: input.brandLabel,
                        startDate: input.startDate,
                        endDate: input.endDate
                      };
              }), val.toUpperCase());
      }),
    value: form.input.brand,
    disabled: Belt_Option.isSome(original),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
    label: Messages_Brands$DvmAdminFrontendFr.brandCode,
    required: /* OnlyLabel */1
  };
  if (form.brandResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.brandResult);
  }
  var tmp$1 = {
    onChange: (function (val) {
        Curry._2(form.updateBrandLabel, (function (input, brandLabel) {
                return {
                        brand: input.brand,
                        brandLabel: brandLabel,
                        startDate: input.startDate,
                        endDate: input.endDate
                      };
              }), val.toUpperCase());
      }),
    value: form.input.brandLabel,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
    label: Messages_Brands$DvmAdminFrontendFr.brandLabel,
    required: /* OnlyLabel */1
  };
  if (form.brandLabelResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.brandLabelResult);
  }
  var tmp$2 = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    brand: input.brand,
                    brandLabel: input.brandLabel,
                    startDate: startDate,
                    endDate: input.endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.startDateResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  var tmp$3 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    brand: input.brand,
                    brandLabel: input.brandLabel,
                    startDate: input.startDate,
                    endDate: endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.endDateResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              item: true,
              spacing: 1
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$1),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$2),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$3),
                  item: true
                }));
}

var InputStyles;

var Api;

var Types;

var Form;

var make = Brands_AddEdit_Inputs;

export {
  InputStyles ,
  Api ,
  Types ,
  Form ,
  make ,
}
/* react Not a pure module */
