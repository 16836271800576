// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as App_Table$DvmAdminFrontendFr from "../../../common/components/table/App_Table.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../../common/utils/Utils_Date.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../../route/Route_Paths.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Head.bs.js";
import * as Contract_Types$DvmAdminFrontendFr from "./Contract_Types.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Messages_TopMenu$DvmAdminFrontendFr from "../../../intl/messages/topMenu/Messages_TopMenu.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Pagination.bs.js";
import * as Contract_Edit_Router$DvmAdminFrontendFr from "../editCreate/Contract_Edit_Router.bs.js";
import * as App_Table_Export_Menu$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Export_Menu.bs.js";
import * as Messages_Contract_List$DvmAdminFrontendFr from "../../../intl/messages/contract/Messages_Contract_List.bs.js";
import * as App_Table_Create_Button$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Create_Button.bs.js";
import * as Messages_Contract_Create$DvmAdminFrontendFr from "../../../intl/messages/contract/Messages_Contract_Create.bs.js";
import * as Messages_Contr_Man_Search$DvmAdminFrontendFr from "../../../intl/messages/contractManagers/Messages_Contr_Man_Search.bs.js";

function getEnglish(param) {
  return param[0];
}

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Contract_Types$DvmAdminFrontendFr.Fields.budget), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.budget);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Contract_Types$DvmAdminFrontendFr.Fields.contractID), undefined, undefined, undefined, undefined, undefined, Messages_Contract_List$DvmAdminFrontendFr.contractID),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Contract_Types$DvmAdminFrontendFr.Fields.contractLabel), undefined, undefined, undefined, undefined, undefined, Messages_Contract_Create$DvmAdminFrontendFr.contractName),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Contract_Types$DvmAdminFrontendFr.Fields.siren), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.siren),
      tl: {
        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Contract_Types$DvmAdminFrontendFr.Fields.managerName), undefined, undefined, undefined, undefined, undefined, Messages_Contr_Man_Search$DvmAdminFrontendFr.managerName),
        tl: {
          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Contract_Types$DvmAdminFrontendFr.Fields.startDate), undefined, undefined, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.startDate),
          tl: {
            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Contract_Types$DvmAdminFrontendFr.Fields.endDate), undefined, undefined, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.endDate),
            tl: /* [] */0
          }
        }
      }
    }
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function Contract_Search_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var tableType = Props.tableType;
  var paramsList = Props.paramsList;
  var operation = Props.operation;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var selectedRole = match[0].selectedRole;
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (row) {
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(operation === /* View */0 && tableType === /* Contract */0 ? (function (param) {
                                                  Contract_Edit_Router$DvmAdminFrontendFr.routeToPage(row.contractID, Utils_Date$DvmAdminFrontendFr.toISODateString(row.startDate), false, undefined);
                                                }) : undefined, operation === /* Manage */1 && tableType === /* Contract */0 ? Caml_option.some(React.createElement(Core.Button, {
                                                        onClick: (function (param) {
                                                            Contract_Edit_Router$DvmAdminFrontendFr.routeToPage(row.contractID, Utils_Date$DvmAdminFrontendFr.toISODateString(row.startDate), User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) && operation === /* Manage */1, undefined);
                                                          }),
                                                        children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                              size: /* Large */3,
                                                              type_: /* Edit */60
                                                            }),
                                                        key: "edit"
                                                      })) : undefined, undefined, Belt_List.mapWithIndex({
                                                  hd: Belt_Option.getWithDefault(row.budget, ""),
                                                  tl: {
                                                    hd: row.contractID,
                                                    tl: {
                                                      hd: Belt_Option.getWithDefault(row.contractLabel, ""),
                                                      tl: {
                                                        hd: Belt_Option.getWithDefault(row.siren, ""),
                                                        tl: {
                                                          hd: Belt_Option.getWithDefault(row.managerName, ""),
                                                          tl: {
                                                            hd: Belt_Option.mapWithDefault(row.startDate, "", (function (prim) {
                                                                    return prim.toLocaleDateString();
                                                                  })),
                                                            tl: {
                                                              hd: Belt_Option.mapWithDefault(row.endDate, "", (function (prim) {
                                                                      return prim.toLocaleDateString();
                                                                    })),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._2(fetchData, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._2(fetchData, undefined, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var tmp = {
    headers: fetchRequest.headers,
    handleSorting: handleSorting,
    tablePage: convertTable(tablePage),
    paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined),
    exportMenu: tableType ? React.createElement(App_Table_Export_Menu$DvmAdminFrontendFr.make, {
            query: Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                  page: 0,
                  rowsPerPage: fetchRequest.rowsPerPage,
                  headers: fetchRequest.headers,
                  filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap(paramsList, (function (queryParam) {
                                  return Belt_Option.map(queryParam, (function (x) {
                                                return x.fr;
                                              }));
                                }))), ";", (function (queryParam) {
                          return queryParam;
                        })),
                  additionalQueryParams: fetchRequest.additionalQueryParams
                }),
            endpoint: "contract/all",
            filename: "siren_rattaches"
          }) : React.createElement(Core.Grid, {
            children: null,
            container: true,
            item: true,
            justifyContent: "flex-end"
          }, operation === /* Manage */1 ? React.createElement(Core.Grid, {
                  children: React.createElement(App_Table_Create_Button$DvmAdminFrontendFr.make, {
                        onClick: (function (param) {
                            RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rContractEdit);
                          }),
                        message: Messages_TopMenu$DvmAdminFrontendFr.contractEdit
                      }),
                  item: true
                }) : null, React.createElement(Core.Grid, {
                children: React.createElement(Core.Tooltip, {
                      arrow: true,
                      children: React.createElement("div", undefined, React.createElement(App_Table_Export_Menu$DvmAdminFrontendFr.make, {
                                query: Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                                      page: 0,
                                      rowsPerPage: 150000,
                                      headers: fetchRequest.headers,
                                      filterQueryParams: fetchRequest.filterQueryParams,
                                      additionalQueryParams: fetchRequest.additionalQueryParams
                                    }),
                                endpoint: "contract",
                                filename: "liste_protocoles",
                                disabled: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(tablePage, false, (function (param) {
                                        return param.total > 150000;
                                      }))
                              })),
                      placement: "top",
                      title: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.maxLinesExport, {
                            count: "150 000"
                          })
                    }),
                item: true
              }))
  };
  var tmp$1 = operation === /* Manage */1 && tableType === /* Contract */0 ? ({
        TAG: /* Left */0,
        _0: /* EmptyCell */0
      }) : undefined;
  if (tmp$1 !== undefined) {
    tmp.rowButton = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(App_Table$DvmAdminFrontendFr.make, tmp);
}

var make = Contract_Search_Table;

export {
  getEnglish ,
  headersConfiguration ,
  make ,
}
/* headersConfiguration Not a pure module */
