// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../../common/utils/Utils_Date.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";
import * as Contract_Types$DvmAdminFrontendFr from "../search/Contract_Types.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../../../common/types/App_Types_Input.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Messages_Contract_Create$DvmAdminFrontendFr from "../../../intl/messages/contract/Messages_Contract_Create.bs.js";
import * as Contract_Attachments_Types$DvmAdminFrontendFr from "./attachment/Contract_Attachments_Types.bs.js";

function fetchContract(userManager, setResult, startDate, selectedRole, contractId) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Contract_Types$DvmAdminFrontendFr.Contract.searchResult_decode, undefined, userManager, selectedRole, "/contract/contracts?limit=1&offset=0&filter=contractId=" + contractId + ";startDate=" + startDate + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var contract = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: contract
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function saveContract(operation, userManager, setResult, submissionCallbacks, withAttachments, selectedRole, contract) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call(operation ? "PUT" : "POST", {
          TAG: /* Json */0,
          _0: Contract_Types$DvmAdminFrontendFr.Contract.contractRow_encode(contract)
        }, userManager, selectedRole, "/contract/contracts" + (
          operation ? "?withAttachments=" + (
              withAttachments ? "true" : "false"
            ) : ""
        )).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToSaveContract */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function checkAttachmentCopy(userManager, setAttachmentCopyCheck, contractID, startDate, newStartDate, selectedRole) {
  Curry._1(setAttachmentCopyCheck, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.stringList_decode, undefined, userManager, selectedRole, "/contract/check/attachment-validity?contractID=" + contractID + "&startDate=" + Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateTimeString(true, startDate), "") + "&newStartDate=" + Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateTimeString(true, newStartDate), "") + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var sirens = result._0;
          return Curry._1(setAttachmentCopyCheck, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: sirens
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setAttachmentCopyCheck, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function checkIfCanAddSiren(siren, startDate, endDate, setResult, userManager, contractID, selectedRole, mode, param) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var start = new Date(Belt_Option.mapWithDefault(startDate, new Date(0.0), (function (x) {
                  if (App_Types_Date$DvmAdminFrontendFr.isValid(x)) {
                    return x;
                  } else {
                    return new Date(0.0);
                  }
                })).setUTCHours(0.0, 0.0, 0.0, 0.0)).toISOString();
  var end = Belt_Option.mapWithDefault(endDate, new Date(3000.0, 12.0), (function (x) {
            if (App_Types_Date$DvmAdminFrontendFr.isValid(x)) {
              return x;
            } else {
              return new Date(3000.0, 12.0);
            }
          })).toISOString();
  var contract = mode === "add" ? "" : "/" + contractID;
  var sirenInAttachmentCheck = function (param) {
    RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("GET", undefined, userManager, selectedRole, "/contract/check/siren-in-attachments" + contract + "?siren=" + siren + "&startDate=" + start + "&endDate=" + end + "").then(function (result) {
          if (result.TAG === /* OkResult */0) {
            RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("GET", undefined, userManager, selectedRole, "/contract/check/siren-in-contracts/" + contractID + "?siren=" + siren + "&startDate=" + start + "&endDate=" + end + "").then(function (result) {
                  if (result.TAG === /* OkResult */0) {
                    return Curry._1(setResult, (function (param) {
                                  return {
                                          TAG: /* Ok */0,
                                          _0: undefined
                                        };
                                }));
                  } else {
                    return Curry._1(setResult, (function (param) {
                                  return {
                                          TAG: /* Error */1,
                                          _0: App_Types_Result$DvmAdminFrontendFr.Rest.makeError(undefined, undefined, undefined, Messages_Contract_Create$DvmAdminFrontendFr.attachmentOverlapError.id)
                                        };
                                }));
                  }
                });
            return ;
          } else {
            return Curry._1(setResult, (function (param) {
                          return {
                                  TAG: /* Error */1,
                                  _0: App_Types_Result$DvmAdminFrontendFr.Rest.makeError(undefined, undefined, undefined, Messages_Contract_Create$DvmAdminFrontendFr.attachmentOverlapError.id)
                                };
                        }));
          }
        });
  };
  RestApi$DvmAdminFrontendFr.call("GET", Contract_Attachments_Types$DvmAdminFrontendFr.ClosedSiren.closedSiren_decode, undefined, userManager, selectedRole, "/sirets/siren/live?siren=" + siren + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var foundEndDate = result._0.response;
          if (foundEndDate === undefined) {
            return sirenInAttachmentCheck(undefined);
          }
          var attachmentStart = Belt_Option.getWithDefault(startDate, new Date(0.0));
          if (ReDate.isBefore(Caml_option.valFromOption(foundEndDate), attachmentStart)) {
            return Curry._1(setResult, (function (param) {
                          return {
                                  TAG: /* Error */1,
                                  _0: App_Types_Result$DvmAdminFrontendFr.Rest.makeError(undefined, undefined, undefined, Messages_Contract_Create$DvmAdminFrontendFr.attachmentSIRENendDateTooLow.id)
                                };
                        }));
          } else {
            return sirenInAttachmentCheck(undefined);
          }
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

var Types;

export {
  Form ,
  Types ,
  fetchContract ,
  saveContract ,
  checkAttachmentCopy ,
  checkIfCanAddSiren ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
