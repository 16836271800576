// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/core/styles";
import * as App_Icon$DvmAdminFrontendFr from "../icons/App_Icon.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../App_Typography.bs.js";
import * as Messages_Pagination$DvmAdminFrontendFr from "../../../intl/messages/Messages_Pagination.bs.js";

var theme = Styles.createTheme({
      overrides: {
        MuiInputBase: {
          root: {
            fontSize: "14px",
            height: "20px",
            padding: "0px 0px 0px 0px",
            paddingRight: "0px",
            borderRadius: "0px"
          }
        },
        MuiOutlinedInput: {
          input: {
            padding: "7px 8px"
          },
          root: {
            borderRadius: "0px"
          }
        }
      }
    });

function createString(rowsPerPage, page) {
  return "limit=" + String(rowsPerPage) + "&offset=" + String(Math.imul(rowsPerPage, page));
}

var options = [
  20,
  30,
  50
];

function makePaginator(positionOpt, rowsPerPageOpt, handleChangePage, handleRowsPerPageChange, param) {
  var position = positionOpt !== undefined ? positionOpt : /* TOP */0;
  var rowsPerPage = rowsPerPageOpt !== undefined ? rowsPerPageOpt : Belt_Option.getWithDefault(Belt_Array.get(options, 0), 20);
  return {
          position: position,
          rowsPerPage: rowsPerPage,
          handleChangePage: handleChangePage,
          handleRowsPerPageChange: handleRowsPerPageChange
        };
}

function App_Table_Pagination(Props) {
  var totalElements = Props.totalElements;
  var rowsPerPage = Props.rowsPerPage;
  var currentPage = Props.currentPage;
  var handleChangePage = Props.handleChangePage;
  var handleRowsPerPageChange = Props.handleRowsPerPageChange;
  var variantOpt = Props.variant;
  var disablePaginatorOpt = Props.disablePaginator;
  var variant = variantOpt !== undefined ? variantOpt : /* NORMAL */0;
  var disablePaginator = disablePaginatorOpt !== undefined ? disablePaginatorOpt : false;
  var match = React.useState(function () {
        return rowsPerPage;
      });
  var setRowsPerPage = match[1];
  var rowsPerPage$1 = match[0];
  var intl = ReactIntl.useIntl();
  var handleRowsPerPageChange$1 = function ($$event, param) {
    var rowsPerPage = $$event.target.value;
    Curry._1(handleRowsPerPageChange, rowsPerPage);
    Curry._1(setRowsPerPage, (function (param) {
            return rowsPerPage;
          }));
  };
  var handlePageChange = function (dir) {
    if (dir) {
      if (currentPage !== 0) {
        return Curry._1(handleChangePage, currentPage - 1 | 0);
      } else {
        return Curry._1(handleChangePage, 0);
      }
    } else {
      return Curry._1(handleChangePage, currentPage + 1 | 0);
    }
  };
  var tmp;
  switch (variant) {
    case /* NORMAL */0 :
        tmp = React.createElement(Core.Grid, {
              alignItems: "center",
              children: null,
              container: true,
              style: {
                gridColumnGap: "16px"
              }
            }, React.createElement(Core.Grid, {
                  children: intl.formatMessage(Messages_Pagination$DvmAdminFrontendFr.show),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Styles.ThemeProvider, {
                        children: React.createElement(Core.Select, {
                              children: Belt_Array.map(options, (function (element) {
                                      return React.createElement(Core.MenuItem, {
                                                  style: {
                                                    fontSize: "14px",
                                                    height: "20px"
                                                  },
                                                  children: element,
                                                  value: element
                                                });
                                    })),
                              onChange: handleRowsPerPageChange$1,
                              value: rowsPerPage$1,
                              variant: "outlined"
                            }),
                        theme: theme
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Core.Button, {
                        onClick: (function (param) {
                            handlePageChange(/* Down */1);
                          }),
                        children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                              type_: /* PadLeft */12
                            }),
                        disabled: currentPage === 0,
                        size: "small"
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        children: intl.formatMessage(Messages_Pagination$DvmAdminFrontendFr.paginator, {
                              fromNumber: totalElements > 0 ? Math.imul(currentPage, rowsPerPage$1) + 1 | 0 : 0,
                              toNumber: Math.imul(currentPage + 1 | 0, rowsPerPage$1) > totalElements ? totalElements : Math.imul(currentPage + 1 | 0, rowsPerPage$1),
                              totalNumber: intl.formatNumber(totalElements)
                            }),
                        variant: "bodyBig"
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Core.Button, {
                        onClick: (function (param) {
                            Curry._1(handleChangePage, currentPage + 1 | 0);
                          }),
                        children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                              type_: /* PadRight */13
                            }),
                        disabled: Math.imul(currentPage + 1 | 0, rowsPerPage$1) >= totalElements,
                        size: "small"
                      }),
                  item: true
                }));
        break;
    case /* SMALL */1 :
        tmp = React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  alignItems: "center",
                  children: null,
                  container: true,
                  justifyContent: "space-between"
                }, React.createElement(Core.Grid, {
                      children: null,
                      item: true,
                      sm: Grid$Mui.Sm[3]
                    }), React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      item: true,
                      justifyContent: "center",
                      sm: Grid$Mui.Sm[6],
                      spacing: 1
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(Core.Button, {
                                onClick: (function (param) {
                                    handlePageChange(/* Down */1);
                                  }),
                                children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                      size: /* Large */3,
                                      type_: /* PadLeft */12
                                    }),
                                disabled: currentPage === 0 || disablePaginator,
                                size: "small"
                              }),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(Core.Button, {
                                onClick: (function (param) {
                                    Curry._1(handleChangePage, currentPage + 1 | 0);
                                  }),
                                children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                      size: /* Large */3,
                                      type_: /* PadRight */13
                                    }),
                                disabled: Math.imul(currentPage + 1 | 0, rowsPerPage$1) >= totalElements || disablePaginator,
                                size: "small"
                              }),
                          item: true
                        })), React.createElement(Core.Grid, {
                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                            children: intl.formatMessage(Messages_Pagination$DvmAdminFrontendFr.paginator, {
                                  fromNumber: totalElements > 0 ? Math.imul(currentPage, rowsPerPage$1) + 1 | 0 : 0,
                                  toNumber: Math.imul(currentPage + 1 | 0, rowsPerPage$1) > totalElements ? totalElements : Math.imul(currentPage + 1 | 0, rowsPerPage$1),
                                  totalNumber: totalElements
                                }),
                            variant: "bodyBig"
                          }),
                      container: true,
                      item: true,
                      justifyContent: "flex-end",
                      sm: Grid$Mui.Sm[3]
                    })));
        break;
    case /* TINY */2 :
        tmp = React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  alignItems: "center",
                  children: React.createElement(Core.Grid, {
                        children: null,
                        container: true,
                        item: true,
                        justifyContent: "center",
                        spacing: 1
                      }, React.createElement(Core.Grid, {
                            children: React.createElement(Core.Button, {
                                  onClick: (function (param) {
                                      handlePageChange(/* Down */1);
                                    }),
                                  children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                        size: /* Large */3,
                                        type_: /* PadLeft */12
                                      }),
                                  disabled: currentPage === 0 || disablePaginator,
                                  size: "small"
                                }),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                  children: intl.formatMessage(Messages_Pagination$DvmAdminFrontendFr.paginator, {
                                        fromNumber: totalElements > 0 ? Math.imul(currentPage, rowsPerPage$1) + 1 | 0 : 0,
                                        toNumber: Math.imul(currentPage + 1 | 0, rowsPerPage$1) > totalElements ? totalElements : Math.imul(currentPage + 1 | 0, rowsPerPage$1),
                                        totalNumber: totalElements
                                      }),
                                  variant: "bodyBig"
                                }),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(Core.Button, {
                                  onClick: (function (param) {
                                      Curry._1(handleChangePage, currentPage + 1 | 0);
                                    }),
                                  children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                        size: /* Large */3,
                                        type_: /* PadRight */13
                                      }),
                                  disabled: Math.imul(currentPage + 1 | 0, rowsPerPage$1) >= totalElements || disablePaginator,
                                  size: "small"
                                }),
                            item: true
                          })),
                  container: true,
                  justifyContent: "center"
                }));
        break;
    
  }
  return React.createElement(React.Fragment, undefined, tmp);
}

var make = App_Table_Pagination;

export {
  theme ,
  createString ,
  options ,
  makePaginator ,
  make ,
}
/* theme Not a pure module */
