// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as App_Table$DvmAdminFrontendFr from "../../../common/components/table/App_Table.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../../common/utils/Utils_Date.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as Dealer_Search_Types$DvmAdminFrontendFr from "./Dealer_Search_Types.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Pagination.bs.js";
import * as Messages_Dealer_List$DvmAdminFrontendFr from "../../../intl/messages/dealer/Messages_Dealer_List.bs.js";
import * as App_Table_Export_Menu$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Export_Menu.bs.js";

function headersConfiguration(selectedRole) {
  return Belt_List.keepMap({
              hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Dealer_Search_Types$DvmAdminFrontendFr.Fields.dr, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.territory),
              tl: {
                hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Dealer_Search_Types$DvmAdminFrontendFr.Fields.dealerId, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.dealerID),
                tl: {
                  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Dealer_Search_Types$DvmAdminFrontendFr.Fields.dealerName, undefined, undefined, undefined, undefined, undefined, Messages_Dealer_List$DvmAdminFrontendFr.dealerName),
                  tl: {
                    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Dealer_Search_Types$DvmAdminFrontendFr.Fields.entityLabel, undefined, undefined, undefined, undefined, undefined, Messages_Dealer_List$DvmAdminFrontendFr.entityLabel),
                    tl: {
                      hd: selectedRole === /* Dealer */7 || User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole) ? undefined : App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Dealer_Search_Types$DvmAdminFrontendFr.Fields.ipnKeyAccountManager, undefined, undefined, undefined, undefined, undefined, Messages_Dealer_List$DvmAdminFrontendFr.ipnKeyAccountManager),
                      tl: {
                        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Dealer_Search_Types$DvmAdminFrontendFr.Fields.keyAccountManagerName, undefined, undefined, undefined, undefined, undefined, Messages_Dealer_List$DvmAdminFrontendFr.keyAccountManagerName),
                        tl: {
                          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Dealer_Search_Types$DvmAdminFrontendFr.Fields.city, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.city),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }, (function (x) {
                return x;
              }));
}

function Dealer_Search_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var showHistory = Props.showHistory;
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var selectedRole = match[0].selectedRole;
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (row) {
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow((function (param) {
                                                RescriptReactRouter.push("dealer/" + row.dealerID + "/" + Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(row.startDate), "") + "");
                                              }), undefined, undefined, Belt_List.mapWithIndex(Belt_List.keepMap({
                                                      hd: row.dr,
                                                      tl: {
                                                        hd: row.dealerID,
                                                        tl: {
                                                          hd: Belt_Option.getWithDefault(row.dealerName, ""),
                                                          tl: {
                                                            hd: Belt_Option.getWithDefault(row.entityLabel, ""),
                                                            tl: {
                                                              hd: selectedRole === /* Dealer */7 || User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole) ? undefined : Belt_Option.getWithDefault(row.ipnKeyAccountManager, ""),
                                                              tl: {
                                                                hd: Belt_Option.getWithDefault(row.keyAccountManagerName, ""),
                                                                tl: {
                                                                  hd: Belt_Option.getWithDefault(row.city, ""),
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }, (function (x) {
                                                        return x;
                                                      })), (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration(selectedRole), index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._2(fetchData, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._2(fetchData, undefined, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, {
                  headers: fetchRequest.headers,
                  handleSorting: handleSorting,
                  tablePage: convertTable(tablePage),
                  paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined),
                  exportMenu: React.createElement(App_Table_Export_Menu$DvmAdminFrontendFr.make, {
                        query: Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                              page: 0,
                              rowsPerPage: 150000,
                              headers: fetchRequest.headers,
                              filterQueryParams: fetchRequest.filterQueryParams,
                              additionalQueryParams: Belt_List.add(fetchRequest.additionalQueryParams, "history=" + (
                                    showHistory ? "true" : "false"
                                  ) + "")
                            }),
                        endpoint: "dealers"
                      })
                }));
}

var Api;

var make = Dealer_Search_Table;

export {
  Api ,
  headersConfiguration ,
  make ,
}
/* react Not a pure module */
