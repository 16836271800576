// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as App_Link$DvmAdminFrontendFr from "../../../common/components/link/App_Link.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as App_Spinner$DvmAdminFrontendFr from "../../../common/components/App_Spinner.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as Grid_Edit_Modal$DvmAdminFrontendFr from "../edit/Grid_Edit_Modal.bs.js";
import * as Margin_Grid_Api$DvmAdminFrontendFr from "./search/Margin_Grid_Api.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Table_Result$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Result.bs.js";
import * as Margin_Grid_Form$DvmAdminFrontendFr from "./search/Margin_Grid_Form.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as Margin_Grid_Table$DvmAdminFrontendFr from "./search/Margin_Grid_Table.bs.js";
import * as Margin_Grid_Filters$DvmAdminFrontendFr from "./search/Margin_Grid_Filters.bs.js";
import * as Messages_MarginGrid$DvmAdminFrontendFr from "../../../intl/messages/marginGrid/Messages_MarginGrid.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Pagination.bs.js";
import * as Margin_Grid_Duplicate_Modal$DvmAdminFrontendFr from "./duplicate/Margin_Grid_Duplicate_Modal.bs.js";
import * as Messages_MarginGrid_Duplicate$DvmAdminFrontendFr from "../../../intl/messages/marginGrid/Messages_MarginGrid_Duplicate.bs.js";
import * as Messages_ModelVersionGrid_Manage$DvmAdminFrontendFr from "../../../intl/messages/modelVersionGrid/Messages_ModelVersionGrid_Manage.bs.js";

var underline = Css.css({
      borderBottom: "solid 5px " + Theme_Colors$DvmAdminFrontendFr.primaryBrown + ""
    });

function Margin_Grid_Page(Props) {
  var operation = Props.operation;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return false;
      });
  var withClosedModels = match[0];
  var alert = ReactAlert.useAlert();
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match$3[1];
  var tablePage = match$3[0];
  var match$4 = React.useState(function () {
        
      });
  var setTableType = match$4[1];
  var tableType = match$4[0];
  var match$5 = React.useState(function () {
        return null;
      });
  var setModal = match$5[1];
  var match$6 = React.useState(function () {
        return /* [] */0;
      });
  var setCategories = match$6[1];
  var categories = match$6[0];
  var match$7 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: Caml_array.get(App_Table_Pagination$DvmAdminFrontendFr.options, 0),
                headers: Margin_Grid_Table$DvmAdminFrontendFr.getHeadersConfiguration(tableType),
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$7[1];
  var fetchRequest = match$7[0];
  var fetchData = function (submissionCallbacks, fetchRequest) {
    if (tableType !== undefined) {
      Margin_Grid_Api$DvmAdminFrontendFr.fetchSearch(submissionCallbacks, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, fetchRequest), tableType, selectedRole, withClosedModels);
    }
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  var form = Margin_Grid_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Margin_Grid_Form$DvmAdminFrontendFr.initialState, (function (param, submissionCallbacks) {
          var year = param.year;
          var discount = param.discount;
          var categoryID = param.categoryID;
          var model = param.model;
          var budget = param.budget;
          var tmp;
          if (tableType !== undefined) {
            switch (tableType) {
              case /* Model */0 :
                  tmp = {
                    hd: budget,
                    tl: {
                      hd: model,
                      tl: {
                        hd: discount,
                        tl: {
                          hd: year,
                          tl: /* [] */0
                        }
                      }
                    }
                  };
                  break;
              case /* Version */1 :
                  tmp = {
                    hd: budget,
                    tl: {
                      hd: categoryID,
                      tl: {
                        hd: model,
                        tl: {
                          hd: param.version,
                          tl: {
                            hd: discount,
                            tl: {
                              hd: year,
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  };
                  break;
              case /* GridID */2 :
                  tmp = {
                    hd: param.avsMarginGridID,
                    tl: {
                      hd: categoryID,
                      tl: {
                        hd: param.categoryName,
                        tl: {
                          hd: discount,
                          tl: {
                            hd: year,
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  };
                  break;
              
            }
          } else {
            tmp = {
              hd: budget,
              tl: {
                hd: model,
                tl: /* [] */0
              }
            };
          }
          fetchData(submissionCallbacks, {
                page: 0,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: Margin_Grid_Table$DvmAdminFrontendFr.getHeadersConfiguration(tableType),
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap(tmp, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  React.useEffect((function () {
          Margin_Grid_Api$DvmAdminFrontendFr.getCategories(alert, userManager, setCategories, selectedRole);
        }), []);
  var buttonElement = function (budget, budgetOrID) {
    return React.createElement(Core.Grid, {
                children: React.createElement(App_Link$DvmAdminFrontendFr.make, {
                      onClick: (function (param) {
                          Curry._1(setModal, (function (param) {
                                  return React.createElement(Grid_Edit_Modal$DvmAdminFrontendFr.make, {
                                              onClose: (function (param) {
                                                  Curry._1(setModal, (function (param) {
                                                          return null;
                                                        }));
                                                  fetchData(undefined, fetchRequest);
                                                  Margin_Grid_Api$DvmAdminFrontendFr.getCategories(alert, userManager, setCategories, selectedRole);
                                                }),
                                              whatTable: budget === "AVS" ? /* GridID */2 : /* Model */0,
                                              whatGrid: /* MarginGrid */1,
                                              budgetOrID: budgetOrID,
                                              budget: budget
                                            });
                                }));
                        }),
                      label: "Modifier " + budgetOrID + "",
                      icon: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                            size: /* Large */3,
                            type_: /* Edit */60
                          })
                    }),
                item: true
              });
  };
  var tmp;
  if (User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) && operation === /* Manage */1) {
    var budget = form.input.budget;
    var tmp$1;
    switch (budget) {
      case "" :
          tmp$1 = null;
          break;
      case "AVS" :
          tmp$1 = form.input.avsMarginGridID.length > 0 ? React.createElement(React.Fragment, undefined, buttonElement("AVS", form.input.avsMarginGridID), React.createElement(Core.Grid, {
                      children: React.createElement(App_Link$DvmAdminFrontendFr.make, {
                            onClick: (function (param) {
                                Curry._1(setModal, (function (param) {
                                        return React.createElement(Margin_Grid_Duplicate_Modal$DvmAdminFrontendFr.make, {
                                                    onClose: (function (param) {
                                                        Curry._1(setModal, (function (param) {
                                                                return null;
                                                              }));
                                                        fetchData(undefined, fetchRequest);
                                                        Margin_Grid_Api$DvmAdminFrontendFr.getCategories(alert, userManager, setCategories, selectedRole);
                                                      }),
                                                    budgetOrID: form.input.avsMarginGridID,
                                                    categories: categories
                                                  });
                                      }));
                              }),
                            label: intl.formatMessage(Messages_MarginGrid_Duplicate$DvmAdminFrontendFr.duplicateGridID, {
                                  gridID: form.input.avsMarginGridID
                                }),
                            icon: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                  size: /* Large */3,
                                  type_: /* Documents */5
                                })
                          }),
                      item: true
                    })) : null;
          break;
      default:
        tmp$1 = buttonElement(budget, budget);
    }
    tmp = React.createElement(Core.Grid, {
          alignItems: "flex-end",
          children: null,
          container: true,
          direction: "column",
          item: true,
          sm: Grid$Mui.Sm[7],
          spacing: 3
        }, React.createElement(Core.Grid, {
              children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                    children: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.actions),
                    variant: "h3"
                  }),
              item: true
            }), React.createElement(Core.Grid, {
              alignItems: "center",
              children: null,
              container: true,
              item: true,
              justifyContent: "flex-end",
              spacing: 3
            }, tmp$1, React.createElement(Core.Grid, {
                  children: React.createElement(App_Link$DvmAdminFrontendFr.make, {
                        label: intl.formatMessage(Messages_ModelVersionGrid_Manage$DvmAdminFrontendFr.createGridJiraButton),
                        icon: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                              size: /* Large */3,
                              type_: /* More */17
                            }),
                        target: "_blank",
                        href: "https://jira.dt.renault.com/secure/RapidBoard.jspa?rapidView=16416&projectKey=IRN072019&view=detail&selectedIssue=IRN072019-249"
                      }),
                  item: true
                })));
  } else {
    tmp = null;
  }
  var tmp$2;
  if (operation === /* View */0) {
    var tmp$3;
    if (tableType !== undefined) {
      var tmp$4;
      if (typeof tablePage === "number") {
        tmp$4 = tablePage === /* NotStarted */0 ? null : React.createElement(App_Spinner$DvmAdminFrontendFr.make, {});
      } else if (tablePage.TAG === /* Ok */0) {
        var tmp$5;
        switch (tableType) {
          case /* Model */0 :
              tmp$5 = Messages_Common$DvmAdminFrontendFr.models;
              break;
          case /* Version */1 :
              tmp$5 = Messages_Common$DvmAdminFrontendFr.versions;
              break;
          case /* GridID */2 :
              tmp$5 = Messages_MarginGrid$DvmAdminFrontendFr.resultInfoAVS;
              break;
          
        }
        tmp$4 = React.createElement(App_Table_Result$DvmAdminFrontendFr.make, {
              number: tablePage._0.total,
              message: tmp$5
            });
      } else {
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: tablePage._0
                  }
                }));
        tmp$4 = null;
      }
      var tmp$6;
      if (tableType === /* GridID */2) {
        tmp$6 = null;
      } else {
        var tmp$7 = {
          onClick: (function (param) {
              Curry._1(setTableType, (function (param) {
                      return /* Model */0;
                    }));
            }),
          children: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.models)
        };
        var tmp$8 = tableType === /* Model */0 ? underline : undefined;
        if (tmp$8 !== undefined) {
          tmp$7.className = tmp$8;
        }
        var tmp$9 = {
          onClick: (function (param) {
              Curry._1(setTableType, (function (param) {
                      return /* Version */1;
                    }));
            }),
          children: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.versions)
        };
        var tmp$10 = tableType === /* Version */1 ? underline : undefined;
        if (tmp$10 !== undefined) {
          tmp$9.className = tmp$10;
        }
        tmp$6 = React.createElement(Core.Grid, {
              children: null,
              container: true,
              spacing: 2
            }, React.createElement(Core.Grid, {
                  children: React.createElement(Core.Button, tmp$7),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Core.Button, tmp$9),
                  item: true
                }));
      }
      tmp$3 = React.createElement(React.Fragment, undefined, tmp$4, tmp$6);
    } else {
      tmp$3 = React.createElement(App_Typography$DvmAdminFrontendFr.make, {
            style: {
              fontWeight: "bold"
            },
            children: intl.formatMessage(Messages_MarginGrid$DvmAdminFrontendFr.beforeResult),
            variant: "bodyBig"
          });
    }
    tmp$2 = React.createElement(Core.Box, {
          children: null
        }, tmp$3, tableType !== undefined ? React.createElement(Margin_Grid_Table$DvmAdminFrontendFr.make, {
                tableType: tableType,
                tablePage: tablePage,
                fetchRequest: fetchRequest,
                fetchData: fetchData,
                withClosedModels: withClosedModels
              }) : null);
  } else {
    tmp$2 = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  justifyContent: "space-between"
                }, React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      direction: "column",
                      item: true,
                      sm: Grid$Mui.Sm.$$true
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                children: intl.formatMessage(operation === /* View */0 ? Messages_MarginGrid$DvmAdminFrontendFr.title : Messages_MarginGrid$DvmAdminFrontendFr.manageMarginGridTitle),
                                variant: "h3"
                              }),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(Margin_Grid_Filters$DvmAdminFrontendFr.make, {
                                tableType: tableType,
                                setTableType: setTableType,
                                form: form,
                                categories: categories,
                                operation: operation,
                                withClosedModels: withClosedModels,
                                setWithClosedModels: match[1]
                              }),
                          item: true
                        })), tmp), tmp$2, match$5[0]);
}

var Api;

var Table;

var Filter;

var Form;

var make = Margin_Grid_Page;

export {
  Api ,
  Table ,
  Filter ,
  Form ,
  underline ,
  make ,
}
/* underline Not a pure module */
