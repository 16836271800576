// Generated by ReScript, PLEASE EDIT WITH CARE


var Detail;

var title = {
  id: "27e16eaa-5dda-4bfe-b447-4063476df1c0",
  defaultMessage: "LCD mise à jour quotas"
};

var contractId = {
  id: "47e1592a-076d-43fd-ae64-248ea20868dd",
  defaultMessage: "Code Loueur"
};

var contractLabel = {
  id: "3aed7cf2-2c5b-4307-8afe-185a593c43d2",
  defaultMessage: "Libellé Loueur"
};

var drLabel = {
  id: "c9c430f3-7e71-4963-80d0-0c83a710367e",
  defaultMessage: "Libellé DR"
};

var mandatoryInfo = {
  id: "fc235fde-82b1-4353-a48f-be192a6a2ec6",
  defaultMessage: "Indiquer les champs obligatoires pour afficher votre résultat*"
};

var remaining = {
  id: "6d21ffe1-fa64-4e5f-9b3c-592bf1af28bd",
  defaultMessage: "Solde"
};

var initial = {
  id: "617b637f-5017-4ab8-a127-b3b267638c89",
  defaultMessage: "Quota Init."
};

var corrected = {
  id: "721dfffb-cd3a-4acc-ab81-17f2990ac6e7",
  defaultMessage: "Quota Corr."
};

var customerInvoiced = {
  id: "98dc03d1-cda9-423e-bf69-3a21b63067be",
  defaultMessage: "Cde facturée"
};

var customerNotInvoiced = {
  id: "9f4525a4-cad4-4ba5-9ff8-32abdfff503f",
  defaultMessage: "Cde non facturée"
};

var consumedTotal = {
  id: "9624a76e-ac86-441f-bfae-eb802f0b20f6",
  defaultMessage: "Quota conso."
};

var exceeded = {
  id: "326a3ab7-78dc-411c-bbfb-2849a5d0fe25",
  defaultMessage: "Dépassement"
};

var createButton = {
  id: "fc9b83ea-2470-4c83-be65-3b0f9fc6f24d",
  defaultMessage: "Créer un nouveau quota"
};

export {
  Detail ,
  title ,
  contractId ,
  contractLabel ,
  drLabel ,
  mandatoryInfo ,
  remaining ,
  initial ,
  corrected ,
  customerInvoiced ,
  customerNotInvoiced ,
  consumedTotal ,
  exceeded ,
  createButton ,
}
/* No side effect */
