// Generated by ReScript, PLEASE EDIT WITH CARE

import * as App_Types_Json$DvmAdminFrontendFr from "./App_Types_Json.bs.js";
import * as Messages_Statuses$DvmAdminFrontendFr from "../../intl/messages/Messages_Statuses.bs.js";

function toString(status) {
  switch (status) {
    case /* BLOCKED */0 :
        return "Anomalie";
    case /* CALCULABLE */1 :
        return "Conforme";
    case /* CANCELED */2 :
        return "Annulee";
    case /* NOBONUS */3 :
        return "Sans Aide";
    case /* PAID */4 :
        return "Paye";
    case /* PREINVOICED */5 :
        return "Prefacture";
    case /* VALIDATED */6 :
        return "Valide";
    case /* REFUSED */7 :
        return "Refuse";
    case /* TOVALIDATE */8 :
        return "A Valider";
    case /* REPARCELED */9 :
        return "Remembrement";
    case /* EMPTYSTATUS */10 :
        return "En cours de traitement";
    case /* MISSINGSTATUS */11 :
        return "Status missing";
    case /* PENDING */12 :
        return "En attente";
    case /* PENDINGINVOICINGPARC */13 :
        return "Attente sortie PARC";
    
  }
}

function fromString(status) {
  switch (status) {
    case "" :
        return /* EMPTYSTATUS */10;
    case "Anomalie" :
    case "BLOCKED" :
        return /* BLOCKED */0;
    case "Annulee" :
    case "CANCELED" :
        return /* CANCELED */2;
    case "CALCULABLE" :
    case "Conforme" :
        return /* CALCULABLE */1;
    case "En attente" :
    case "PENDING" :
        return /* PENDING */12;
    case "Attente sortie PARC" :
    case "PENDING_INVOICING" :
        return /* PENDINGINVOICINGPARC */13;
    case "PAID" :
    case "Paye" :
        return /* PAID */4;
    case "PREINVOICED" :
    case "Prefacture" :
        return /* PREINVOICED */5;
    case "REFUSED" :
    case "Refuse" :
        return /* REFUSED */7;
    case "REPARCELED" :
    case "Remembrement" :
        return /* REPARCELED */9;
    case "NOBONUS" :
    case "Sans Aide" :
        return /* NOBONUS */3;
    case "A Valider" :
    case "TOVALIDATE" :
        return /* TOVALIDATE */8;
    case "VALIDATED" :
    case "Valide" :
        return /* VALIDATED */6;
    default:
      return /* MISSINGSTATUS */11;
  }
}

var Decco = App_Types_Json$DvmAdminFrontendFr.MakeStringableJsonType({
      toString: toString,
      fromString: fromString
    });

function toMessage(status) {
  switch (status) {
    case /* BLOCKED */0 :
        return Messages_Statuses$DvmAdminFrontendFr.blocked;
    case /* CALCULABLE */1 :
        return Messages_Statuses$DvmAdminFrontendFr.calculable;
    case /* CANCELED */2 :
        return Messages_Statuses$DvmAdminFrontendFr.canceled;
    case /* NOBONUS */3 :
        return Messages_Statuses$DvmAdminFrontendFr.nobonus;
    case /* PAID */4 :
        return Messages_Statuses$DvmAdminFrontendFr.paid;
    case /* PREINVOICED */5 :
        return Messages_Statuses$DvmAdminFrontendFr.preinvoiced;
    case /* VALIDATED */6 :
        return Messages_Statuses$DvmAdminFrontendFr.validated;
    case /* REFUSED */7 :
        return Messages_Statuses$DvmAdminFrontendFr.refused;
    case /* TOVALIDATE */8 :
        return Messages_Statuses$DvmAdminFrontendFr.toValidate;
    case /* REPARCELED */9 :
        return Messages_Statuses$DvmAdminFrontendFr.reparceled;
    case /* EMPTYSTATUS */10 :
        return Messages_Statuses$DvmAdminFrontendFr.emptyStatus;
    case /* MISSINGSTATUS */11 :
        return Messages_Statuses$DvmAdminFrontendFr.missingStatus;
    case /* PENDING */12 :
        return Messages_Statuses$DvmAdminFrontendFr.pending;
    case /* PENDINGINVOICINGPARC */13 :
        return Messages_Statuses$DvmAdminFrontendFr.pendingInvoicingParc;
    
  }
}

export {
  toString ,
  fromString ,
  Decco ,
  toMessage ,
}
/* Decco Not a pure module */
