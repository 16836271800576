// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Visibility_Profiles$DvmAdminFrontendFr from "../../../intl/messages/visibilityProfiles/Messages_Visibility_Profiles.bs.js";

function Visibility_Profiles_Delete_Modal(Props) {
  var onClose = Props.onClose;
  var userID = Props.userID;
  var deleteIPN = Props.deleteIPN;
  var intl = ReactIntl.useIntl();
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Visibility_Profiles$DvmAdminFrontendFr.deleteTitle, {
                    ipn: userID
                  }),
              minWidth: "600px",
              children: React.createElement(Core.Grid, {
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          item: true,
                          justifyContent: "space-between"
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                    onClick: (function (param) {
                                        Curry._1(onClose, undefined);
                                      }),
                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.cancel),
                                    color: "primaryBorder",
                                    size: "medium"
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                    onClick: (function (param) {
                                        Curry._1(deleteIPN, userID);
                                        Curry._1(onClose, undefined);
                                      }),
                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.$$delete),
                                    color: "delete",
                                    size: "medium"
                                  }),
                              item: true
                            })),
                    container: true,
                    direction: "column",
                    spacing: 2
                  })
            });
}

var make = Visibility_Profiles_Delete_Modal;

export {
  make ,
}
/* react Not a pure module */
