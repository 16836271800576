// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";

var button = Css.css(Object.assign({}, {
          backgroundColor: Theme_Colors$DvmAdminFrontendFr.black,
          border: "none",
          color: Theme_Colors$DvmAdminFrontendFr.white,
          cursor: "pointer",
          display: "flex",
          textTransform: "uppercase",
          alignItems: "center",
          justifyContent: "center"
        }, {
          "&:hover": {
            backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayMain
          }
        }));

var large = Css.css({
      fontSize: "16px",
      fontWeight: "600",
      minHeight: "56px",
      minWidth: "128px",
      padding: "15px 19px"
    });

var medium = Css.css({
      fontSize: "14px",
      fontWeight: "600",
      minHeight: "45px",
      minWidth: "128px",
      padding: "7px 14px"
    });

var small = Css.css({
      fontSize: "12px",
      fontWeight: "600",
      minHeight: "32px",
      minWidth: "50px",
      padding: "7px 14px"
    });

var icon = Css.css({
      paddingRight: "5px"
    });

var primary = Css.css({
      backgroundColor: Theme_Colors$DvmAdminFrontendFr.black
    });

var secondary = Css.css({
      backgroundColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown
    });

var $$delete = Css.css({
      backgroundColor: Theme_Colors$DvmAdminFrontendFr.red
    });

var disabled = Css.css(Object.assign({}, {
          backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayBackground,
          cursor: "default"
        }, {
          "&:hover": {
            backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayBackground
          }
        }));

var disabledPrimaryBorder = Css.css(Object.assign({}, {
          backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayBackground,
          border: "2px solid",
          borderColor: Theme_Colors$DvmAdminFrontendFr.grayLight4,
          color: Theme_Colors$DvmAdminFrontendFr.grayLight4,
          cursor: "default"
        }, {
          "&:hover": {
            backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayBackground
          }
        }));

var primaryBorder = Css.css({
      backgroundColor: Theme_Colors$DvmAdminFrontendFr.white,
      border: "2px solid",
      borderColor: Theme_Colors$DvmAdminFrontendFr.black,
      color: Theme_Colors$DvmAdminFrontendFr.black
    });

var transparentBlue = Css.css(Object.assign({}, {
          backgroundColor: "transparent",
          color: Theme_Colors$DvmAdminFrontendFr.secondaryBlue,
          fontWeight: "600"
        }, {
          "&:hover": {
            backgroundColor: Theme_Colors$DvmAdminFrontendFr.black
          }
        }));

var fullWidth = Css.css({
      minWidth: "unset",
      width: "100%"
    });

var Styles = {
  button: button,
  large: large,
  medium: medium,
  small: small,
  icon: icon,
  primary: primary,
  secondary: secondary,
  $$delete: $$delete,
  disabled: disabled,
  disabledPrimaryBorder: disabledPrimaryBorder,
  primaryBorder: primaryBorder,
  transparentBlue: transparentBlue,
  fullWidth: fullWidth
};

function App_Button(Props) {
  var onClick = Props.onClick;
  var label = Props.label;
  var icon$1 = Props.icon;
  var colorOpt = Props.color;
  var sizeOpt = Props.size;
  var disabledOpt = Props.disabled;
  var type_Opt = Props.type_;
  var style = Props.style;
  var color = colorOpt !== undefined ? colorOpt : "primary";
  var size = sizeOpt !== undefined ? sizeOpt : "large";
  var disabled$1 = disabledOpt !== undefined ? disabledOpt : false;
  var type_ = type_Opt !== undefined ? type_Opt : "button";
  var getColor = function (param) {
    if (color === "delete") {
      return $$delete;
    } else if (color === "primary") {
      return primary;
    } else if (color === "primaryBorder") {
      return primaryBorder;
    } else {
      return secondary;
    }
  };
  var getSize = function (param) {
    if (size === "medium") {
      return medium;
    } else if (size === "small") {
      return small;
    } else {
      return large;
    }
  };
  var tmp = {
    className: Css.cx([
          button,
          getSize(undefined),
          getColor(undefined),
          disabled$1 ? (
              color === "primaryBorder" ? disabledPrimaryBorder : disabled
            ) : ""
        ]),
    disabled: disabled$1,
    type: type_
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement("button", tmp, React.createElement(Core.Box, {
                  children: null,
                  alignItems: "center",
                  display: "flex"
                }, Belt_Option.mapWithDefault(icon$1, null, (function (i) {
                        return React.createElement(Core.Box, {
                                    className: Belt_Option.isSome(label) ? icon : "",
                                    children: i,
                                    display: "flex"
                                  });
                      })), React.createElement(Core.Box, {
                      children: Belt_Option.mapWithDefault(label, null, (function (l) {
                              return l;
                            }))
                    })));
}

var make = App_Button;

export {
  Styles ,
  make ,
}
/* button Not a pure module */
