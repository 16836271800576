// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../common/App_Api.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../common/components/App_Checkbox.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../../context/router/Context_Router.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_SelectFormInput.bs.js";
import * as ModelVersion_Grid_Api$DvmAdminFrontendFr from "./ModelVersion_Grid_Api.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as ModelVersion_Grid_Form$DvmAdminFrontendFr from "./ModelVersion_Grid_Form.bs.js";
import * as ModelVersion_Grid_Table$DvmAdminFrontendFr from "./ModelVersion_Grid_Table.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as App_DoubleSelectFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_DoubleSelectFormInput.bs.js";
import * as Messages_ModelVersionGrid$DvmAdminFrontendFr from "../../../intl/messages/modelVersionGrid/Messages_ModelVersionGrid.bs.js";

function ModelVersion_Grid_Filters(Props) {
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var whatTable = Props.whatTable;
  var setWhatTable = Props.setWhatTable;
  var setBudget = Props.setBudget;
  var withClosedModels = Props.withClosedModels;
  var setWithClosedModels = Props.setWithClosedModels;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var afterMount = React.useRef(function (param) {
        return false;
      });
  var match = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match[1];
  var modelVersionGridFilter = match[0].modelVersionGridFilter;
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelVersions = match$3[1];
  var modelVersions = match$3[0];
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var setContractOptions = match$4[1];
  var contractOptions = match$4[0];
  var match$5 = React.useState(function () {
        return /* [] */0;
      });
  var setContractsClean = match$5[1];
  var match$6 = React.useState(function () {
        return Belt_Option.map(modelVersionGridFilter, (function (filter) {
                      return filter.contractLabel;
                    }));
      });
  var setContractLabel = match$6[1];
  var form = ModelVersion_Grid_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.mapWithDefault(modelVersionGridFilter, ModelVersion_Grid_Form$DvmAdminFrontendFr.initialState, (function (filter) {
              return {
                      budget: filter.budget,
                      contractID: filter.contractId,
                      categoryLabel: ModelVersion_Grid_Form$DvmAdminFrontendFr.initialState.categoryLabel,
                      model: ModelVersion_Grid_Form$DvmAdminFrontendFr.initialState.model,
                      version: ModelVersion_Grid_Form$DvmAdminFrontendFr.initialState.version,
                      discount: ModelVersion_Grid_Form$DvmAdminFrontendFr.initialState.discount,
                      year: ModelVersion_Grid_Form$DvmAdminFrontendFr.initialState.year,
                      acquisitionType: ModelVersion_Grid_Form$DvmAdminFrontendFr.initialState.acquisitionType
                    };
            })), (function (param, submissionCallbacks) {
          var acquisitionType = param.acquisitionType;
          var year = param.year;
          var discount = param.discount;
          var model = param.model;
          var contractID = param.contractID;
          var budget = param.budget;
          Curry._1(setBudget, (function (param) {
                  return budget;
                }));
          Curry._3(fetchData, submissionCallbacks, budget, {
                page: 0,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: whatTable !== undefined ? ModelVersion_Grid_Table$DvmAdminFrontendFr.headersConfiguration(budget, whatTable) : /* [] */0,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap(whatTable !== undefined ? (
                                whatTable ? ({
                                      hd: contractID,
                                      tl: {
                                        hd: param.categoryLabel,
                                        tl: {
                                          hd: model,
                                          tl: {
                                            hd: param.version,
                                            tl: {
                                              hd: discount,
                                              tl: {
                                                hd: year,
                                                tl: {
                                                  hd: acquisitionType,
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }) : ({
                                      hd: contractID,
                                      tl: {
                                        hd: model,
                                        tl: {
                                          hd: discount,
                                          tl: {
                                            hd: year,
                                            tl: {
                                              hd: acquisitionType,
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    })
                              ) : /* [] */0, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: /* [] */0
              });
        }));
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchModelVersions(setModelVersions, userManager, selectedRole, withClosedModels, undefined, undefined);
          ModelVersion_Grid_Api$DvmAdminFrontendFr.queryForContractIdSelect(alert, userManager, setContractOptions, selectedRole);
        }), []);
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchModelVersions(setModelVersions, userManager, selectedRole, withClosedModels, undefined, undefined);
        }), [withClosedModels]);
  React.useEffect((function () {
          if (Curry._1(afterMount.current, undefined) && whatTable !== undefined) {
            if (whatTable) {
              Curry._1(form.submit, undefined);
            } else {
              Curry._2(form.updateVersion, (function (input, param) {
                      return {
                              budget: input.budget,
                              contractID: input.contractID,
                              categoryLabel: input.categoryLabel,
                              model: input.model,
                              version: "",
                              discount: input.discount,
                              year: input.year,
                              acquisitionType: input.acquisitionType
                            };
                    }), "");
              Curry._1(form.submit, undefined);
            }
          }
          
        }), [whatTable]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.forError(modelVersions, (function (error) {
                  Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                            message: {
                              TAG: /* RestError */2,
                              _0: error
                            }
                          }));
                }));
          Curry._1(setContractsClean, (function (param) {
                  return Belt_List.keepMap(contractOptions, (function (x) {
                                if (Belt_Option.getWithDefault(x.budget, "") === form.input.budget) {
                                  return x.contractID;
                                }
                                
                              }));
                }));
        }), [modelVersions]);
  var beforeSubmit = function (param) {
    var match = form.input.version;
    if (match === "") {
      if (Belt_Option.isNone(whatTable)) {
        Curry._1(setWhatTable, (function (param) {
                return /* Model */0;
              }));
      }
      
    } else {
      Curry._1(setWhatTable, (function (param) {
              return /* Version */1;
            }));
    }
    Curry._1(form.submit, undefined);
  };
  React.useEffect((function () {
          if (Curry._1(afterMount.current, undefined)) {
            Curry._2(form.updateContractID, (function (input, param) {
                    return {
                            budget: input.budget,
                            contractID: "",
                            categoryLabel: input.categoryLabel,
                            model: input.model,
                            version: input.version,
                            discount: input.discount,
                            year: input.year,
                            acquisitionType: input.acquisitionType
                          };
                  }), "");
            Curry._1(setContractsClean, (function (param) {
                    return Belt_List.keepMap(contractOptions, (function (x) {
                                  if (Belt_Option.getWithDefault(x.budget, "") === form.input.budget) {
                                    return x.contractID;
                                  }
                                  
                                }));
                  }));
          }
          
        }), [form.input.budget]);
  React.useEffect((function () {
          if (Curry._1(afterMount.current, undefined)) {
            Curry._1(setContractLabel, (function (param) {
                    return Belt_Option.mapWithDefault(Belt_List.get(Belt_List.keepMap(contractOptions, (function (x) {
                                          if (x.contractID === form.input.contractID) {
                                            return Caml_option.some(x.contractLabel);
                                          }
                                          
                                        })), 0), undefined, (function (x) {
                                  return x;
                                }));
                  }));
          }
          
        }), [form.input.contractID]);
  React.useEffect((function () {
          if (Curry._1(afterMount.current, undefined) === false) {
            afterMount.current = (function (param) {
                return true;
              });
          }
          Curry._1(dispatch, {
                TAG: /* StoreModelVersionFilter */2,
                _0: undefined
              });
          if (Belt_Option.isSome(modelVersionGridFilter)) {
            beforeSubmit(undefined);
          }
          
        }), []);
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.budget,
    options: {
      hd: {
        label: {
          TAG: /* String */3,
          _0: "LCD"
        },
        value: "LCD"
      },
      tl: {
        hd: {
          label: {
            TAG: /* String */3,
            _0: "LLD"
          },
          value: "LLD"
        },
        tl: {
          hd: {
            label: {
              TAG: /* String */3,
              _0: "PROT"
            },
            value: "PROT"
          },
          tl: /* [] */0
        }
      }
    },
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.budget,
        onChange: Curry._1(form.updateBudget, (function (input, budget) {
                return {
                        budget: budget,
                        contractID: input.contractID,
                        categoryLabel: input.categoryLabel,
                        model: input.model,
                        version: input.version,
                        discount: input.discount,
                        year: input.year,
                        acquisitionType: input.acquisitionType
                      };
              }))
      }
    },
    id: "budget",
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
    required: /* OnlyLabel */1
  };
  if (form.budgetResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.budgetResult);
  }
  var tmp$1 = {
    label: Messages_ModelVersionGrid$DvmAdminFrontendFr.orderID,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.contractID,
        onChange: Curry._1(form.updateContractID, (function (input, contractID) {
                return {
                        budget: input.budget,
                        contractID: contractID,
                        categoryLabel: input.categoryLabel,
                        model: input.model,
                        version: input.version,
                        discount: input.discount,
                        year: input.year,
                        acquisitionType: input.acquisitionType
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(match$5[0])))
    },
    required: /* OnlyLabel */1,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.contractIDResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.contractIDResult);
  }
  var tmp$2 = {
    label: Messages_Common$DvmAdminFrontendFr.year,
    value: form.input.year,
    onChange: Curry._1(form.updateYear, (function (input, year) {
            return {
                    budget: input.budget,
                    contractID: input.contractID,
                    categoryLabel: input.categoryLabel,
                    model: input.model,
                    version: input.version,
                    discount: input.discount,
                    year: year,
                    acquisitionType: input.acquisitionType
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
    variant: /* YEAR */0
  };
  if (form.yearResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.yearResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  beforeSubmit(undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, tmp),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$1),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(Core.TextField, {
                            disabled: true,
                            label: intl.formatMessage(Messages_ModelVersionGrid$DvmAdminFrontendFr.labelContract),
                            value: Belt_Option.mapWithDefault(match$6[0], "", (function (x) {
                                    return x;
                                  }))
                          }),
                      item: true
                    }), React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                      show: App_Types_Result$DvmAdminFrontendFr.isPending(modelVersions),
                      children: React.createElement(Core.Grid, {
                            children: null,
                            container: true,
                            direction: "column",
                            item: true,
                            style: {
                              width: "fit-content"
                            }
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_DoubleSelectFormInput$DvmAdminFrontendFr.make, {
                                      labelParent: Messages_Common$DvmAdminFrontendFr.model,
                                      labelChild: Messages_Common$DvmAdminFrontendFr.version,
                                      valueParent: {
                                        TAG: /* Single */0,
                                        _0: {
                                          value: form.input.model,
                                          onChange: Curry._1(form.updateModel, (function (input, model) {
                                                  return {
                                                          budget: input.budget,
                                                          contractID: input.contractID,
                                                          categoryLabel: input.categoryLabel,
                                                          model: model,
                                                          version: input.version,
                                                          discount: input.discount,
                                                          year: input.year,
                                                          acquisitionType: input.acquisitionType
                                                        };
                                                }))
                                        }
                                      },
                                      valueChild: {
                                        TAG: /* Single */0,
                                        _0: {
                                          value: form.input.version,
                                          onChange: Curry._1(form.updateVersion, (function (input, version) {
                                                  return {
                                                          budget: input.budget,
                                                          contractID: input.contractID,
                                                          categoryLabel: input.categoryLabel,
                                                          model: input.model,
                                                          version: version,
                                                          discount: input.discount,
                                                          year: input.year,
                                                          acquisitionType: input.acquisitionType
                                                        };
                                                }))
                                        }
                                      },
                                      options: App_Types_Result$DvmAdminFrontendFr.getWithDefault(modelVersions, /* [] */0),
                                      classNameParent: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
                                      classNameChild: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                                    }),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(Core.FormControlLabel, {
                                      classes: {
                                        label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                      },
                                      control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                            checked: withClosedModels,
                                            onClick: (function ($$event) {
                                                Curry._1(setWithClosedModels, (function (param) {
                                                        return $$event.target.checked;
                                                      }));
                                              })
                                          }),
                                      label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.includeClosedCars)
                                    }),
                                item: true,
                                style: {
                                  whiteSpace: "nowrap",
                                  width: "1px"
                                }
                              }))
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry._1(form.updateCategoryLabel, (function (input, categoryLabel) {
                                    return {
                                            budget: input.budget,
                                            contractID: input.contractID,
                                            categoryLabel: categoryLabel,
                                            model: input.model,
                                            version: input.version,
                                            discount: input.discount,
                                            year: input.year,
                                            acquisitionType: input.acquisitionType
                                          };
                                  })),
                            value: form.input.categoryLabel,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
                            label: Messages_Common$DvmAdminFrontendFr.categoryLabel
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_ModelVersionGrid$DvmAdminFrontendFr.acquisitionType,
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.acquisitionType,
                                onChange: Curry._1(form.updateAcquisitionType, (function (input, acquisitionType) {
                                        return {
                                                budget: input.budget,
                                                contractID: input.contractID,
                                                categoryLabel: input.categoryLabel,
                                                model: input.model,
                                                version: input.version,
                                                discount: input.discount,
                                                year: input.year,
                                                acquisitionType: acquisitionType
                                              };
                                      }))
                              }
                            },
                            options: {
                              TAG: /* Unlabeled */0,
                              _0: {
                                hd: "ACPT",
                                tl: {
                                  hd: "DLOC",
                                  tl: /* [] */0
                                }
                              }
                            },
                            disabled: !(form.input.budget === "LCD" || form.input.budget === "PROT"),
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry._1(form.updateDiscount, (function (input, discount) {
                                    return {
                                            budget: input.budget,
                                            contractID: input.contractID,
                                            categoryLabel: input.categoryLabel,
                                            model: input.model,
                                            version: input.version,
                                            discount: discount,
                                            year: input.year,
                                            acquisitionType: input.acquisitionType
                                          };
                                  })),
                            value: form.input.discount,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
                            label: Messages_Common$DvmAdminFrontendFr.discount,
                            InputProps: {
                              startAdornment: React.createElement(Core.InputAdornment, {
                                    children: "%",
                                    position: "start"
                                  })
                            },
                            inputType: "number"
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$2),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var Form;

var Api;

var Table;

var InputStyles;

var make = ModelVersion_Grid_Filters;

export {
  Form ,
  Api ,
  Table ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
