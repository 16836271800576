// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../../common/components/App_Modal.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Flash_Edit_NonFleet_Api$DvmAdminFrontendFr from "../../../steps/NonFleet/Flash_Edit_NonFleet_Api.bs.js";
import * as Flash_Edit_NonFleet_Modal_Api$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_Modal_Api.bs.js";
import * as Flash_Edit_NonFleet_Modal_Table$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_Modal_Table.bs.js";
import * as Flash_Edit_NonFleet_Modal_Utils$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_Modal_Utils.bs.js";
import * as Flash_Edit_NonFleet_Modal_Filter$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_Modal_Filter.bs.js";
import * as Flash_Edit_NonFleet_Modal_Amount_All$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_Modal_Amount_All.bs.js";
import * as Flash_Edit_NonFleet_Modal_Sort_Table$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_Modal_Sort_Table.bs.js";
import * as Flash_Edit_NonFleet_Modal_Collection_Form$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_Modal_Collection_Form.bs.js";

function Flash_Edit_NonFleet_Modal(Props) {
  var onClose = Props.onClose;
  var versions = Props.versions;
  var flash = Props.flash;
  var refreshPage = Props.refreshPage;
  var match = React.useState(function () {
        return {
                content: /* [] */0,
                total: 0
              };
      });
  var setTablePage = match[1];
  var match$1 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setNewVehicleResult = match$1[1];
  var newVehicleResult = match$1[0];
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match$2[1];
  var saveResult = match$2[0];
  var match$3 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$4 = match$3[0];
  var userManager = match$4.userManager;
  var selectedRole = match$4.selectedRole;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match$5 = React.useState(function () {
        return {
                page: 0,
                headers: Flash_Edit_NonFleet_Modal_Table$DvmAdminFrontendFr.headersConfiguration(flash),
                brand: undefined,
                model: undefined,
                version: undefined,
                versionLabel: undefined,
                engineType: undefined,
                modelPhase: undefined
              };
      });
  var setTableHandler = match$5[1];
  var tableHandler = match$5[0];
  var match$6 = React.useState(function () {
        return false;
      });
  var match$7 = React.useState(function () {
        return /* [] */0;
      });
  var setLastFilteredList = match$7[1];
  var form = Flash_Edit_NonFleet_Modal_Collection_Form$DvmAdminFrontendFr.VersionsForm.useForm(Flash_Edit_NonFleet_Modal_Collection_Form$DvmAdminFrontendFr.initialInput, (function (output, submissionCallbacks) {
          if (typeof newVehicleResult === "number" || newVehicleResult.TAG !== /* Ok */0) {
            return ;
          } else {
            return Flash_Edit_NonFleet_Modal_Api$DvmAdminFrontendFr.saveCustomBonuses(userManager, selectedRole, setSaveResult, Flash_Edit_NonFleet_Modal_Utils$DvmAdminFrontendFr.mapToNewVehicle(output, newVehicleResult._0), flash, submissionCallbacks);
          }
        }));
  React.useEffect((function () {
          Flash_Edit_NonFleet_Modal_Utils$DvmAdminFrontendFr.populateForm(versions, form, flash);
        }), []);
  var filterPagingAndSort = function (tableHandler) {
    var filteredList = Belt_List.keep(Belt_List.fromArray(form.input.versions), (function (x) {
            if (Belt_Option.mapWithDefault(tableHandler.brand, true, (function (brand) {
                      return x.brand === brand;
                    })) && Belt_Option.mapWithDefault(tableHandler.model, true, (function (model) {
                      return x.model === model;
                    })) && Belt_Option.mapWithDefault(tableHandler.version, true, (function (version) {
                      return x.version === version;
                    })) && Belt_Option.mapWithDefault(tableHandler.versionLabel, true, (function (versionLabel) {
                      return x.versionLabel.toLowerCase().includes(versionLabel.toLowerCase());
                    })) && Belt_Option.mapWithDefault(tableHandler.engineType, true, (function (engine) {
                      return x.engineType === engine;
                    }))) {
              return Belt_Option.mapWithDefault(tableHandler.modelPhase, true, (function (phase) {
                            return x.modelPhase === phase;
                          }));
            } else {
              return false;
            }
          }));
    var sortedList = Flash_Edit_NonFleet_Modal_Sort_Table$DvmAdminFrontendFr.sortList(filteredList, tableHandler);
    var paged = Belt_List.keepWithIndex(sortedList, (function (param, index) {
            if (index >= Math.imul(tableHandler.page, 10)) {
              return index < Math.imul(tableHandler.page + 1 | 0, 10);
            } else {
              return false;
            }
          }));
    Curry._1(setTablePage, (function (param) {
            return {
                    content: paged,
                    total: Belt_List.length(filteredList)
                  };
          }));
    Curry._1(setLastFilteredList, (function (param) {
            return filteredList;
          }));
    Curry._1(setTableHandler, (function (param) {
            return tableHandler;
          }));
  };
  var fetchNewVehicleInfo = function (param) {
    Flash_Edit_NonFleet_Api$DvmAdminFrontendFr.fetchNewVehicleInfo(userManager, setNewVehicleResult, flash.actionId, selectedRole);
  };
  React.useEffect((function () {
          fetchNewVehicleInfo(undefined);
        }), []);
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Curry._1(refreshPage, undefined);
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.customNonFleetAmountsTitle, {
                    actionID: flash.actionId
                  }),
              minWidth: "100rem",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(newVehicleResult) || App_Types_Result$DvmAdminFrontendFr.isPending(saveResult),
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          direction: "column",
                          spacing: 1,
                          style: {
                            padding: "10px"
                          }
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(Flash_Edit_NonFleet_Modal_Filter$DvmAdminFrontendFr.make, {
                                    tableHandler: tableHandler,
                                    filterPagingAndSort: filterPagingAndSort,
                                    withClosedModels: match$6[0],
                                    setWithClosedModels: match$6[1],
                                    versions: versions,
                                    collectionForm: form
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(Flash_Edit_NonFleet_Modal_Amount_All$DvmAdminFrontendFr.make, {
                                    flash: flash,
                                    collectionForm: form,
                                    lastFilteredList: match$7[0]
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(Flash_Edit_NonFleet_Modal_Table$DvmAdminFrontendFr.make, {
                                    tablePage: match[0],
                                    tableHandler: tableHandler,
                                    filterPagingAndSort: filterPagingAndSort,
                                    form: form
                                  }),
                              item: true
                            }))
                  }),
              padding: false
            });
}

var Types;

var VersionsFilter;

var VersionsTable;

var Table;

var SortTable;

var Form;

var ModalUtils;

var Api;

var make = Flash_Edit_NonFleet_Modal;

export {
  Types ,
  VersionsFilter ,
  VersionsTable ,
  Table ,
  SortTable ,
  Form ,
  ModalUtils ,
  Api ,
  make ,
}
/* react Not a pure module */
