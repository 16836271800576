// Generated by ReScript, PLEASE EDIT WITH CARE


var derogateButton = {
  id: "6def289b-1bb1-4de6-a05b-278fdc37eb8a",
  defaultMessage: "déroger"
};

var derogateTitle = {
  id: "9793c548-5b4c-4dcb-bd7b-87e9b4e1a91b",
  defaultMessage: "Dérogation pour la commande {id}"
};

var derogateOrderLabel = {
  id: "7c734942-d756-4ce7-a5e4-d5a3bab55a20",
  defaultMessage: "Informations commande"
};

var derogateActionInfo = {
  id: "a6df036c-4244-436a-927f-c05e571689da",
  defaultMessage: "Informations Aide"
};

var derogateSignLabel = {
  id: "4854b842-6050-4961-9e82-e99e4dfe5f7c",
  defaultMessage: "Nature de la dérogation"
};

var derogateActionLabel = {
  id: "ce52a87f-6f41-4e7a-b2a9-03788b9014af",
  defaultMessage: "Périmétre d’application"
};

var derogateInfoLabel = {
  id: "bd9cd8ef-eaa8-4be6-a33a-53362de449c9",
  defaultMessage: "Informations dérogation"
};

var derogatePlanPerf = {
  id: "e31ed128-00ce-453a-92f2-3ffb06bdc252",
  defaultMessage: "Plan Perf"
};

var derogateStoc = {
  id: "2f4282ac-402f-44e6-9b3e-4ece930a855f",
  defaultMessage: "STOC"
};

var derogateReprise = {
  id: "cfb49700-ce29-4372-b187-c3938ab58fb7",
  defaultMessage: "REPR"
};

var derogateIdAction = {
  id: "6e4021a5-bbca-4fce-bb07-18e6495c4dc1",
  defaultMessage: "Code Action / Libellé Action Flash Marketing"
};

var derogateId = {
  id: "9275e88b-8266-4b34-837b-13921597366a",
  defaultMessage: "Code Derogation"
};

var derogateIdLabel = {
  id: "c715b086-7eb2-4bc4-bc49-4813910dde3e",
  defaultMessage: "Libellé Action Dero"
};

var derogateAmount = {
  id: "dcd82013-39ff-48ca-b023-a021aea9eb2a",
  defaultMessage: "Montant d’aide en €"
};

var derogateConfirmTitle = {
  id: "ae206704-386a-418c-a746-7b3ec2e40673",
  defaultMessage: "Confirmation montant"
};

var derogateConfirmInfo = {
  id: "2370c327-86c4-49d3-a507-05d9adc20fea",
  defaultMessage: "Confirmez-vous le montant d’aide de {amount} ? "
};

var derogateBonusAmountPaid = {
  id: "512cf9d5-fb7b-436f-95ae-78f85f0bd3b5",
  defaultMessage: "Montant payé"
};

var validation = {
  id: "f8cf6b42-8de4-4c6d-b9a9-e29aa768b3b5",
  defaultMessage: "Validation"
};

var alertDero = {
  id: "b4b8cf41-4e8d-47d2-8819-5423c727b616  ",
  defaultMessage: "Alerte!"
};

var derogationImpossible = {
  id: "a1495253-5fb8-4f8b-a39f-ca777581fd47",
  defaultMessage: "Dérogation impossible, montant supérieur à {limit}"
};

var budgetDero = {
  id: "eb576467-54da-40c7-941c-105fdb340dc3",
  defaultMessage: "Budget/AC"
};

var deroAmountAction = {
  id: "0a1602d8-e389-46f0-a505-4b9b128c891a",
  defaultMessage: "Montant/%"
};

var deroConfirmDelete = {
  id: "f0429116-8980-4ca5-96b8-e513cf2d806e",
  defaultMessage: "Êtes-vous sûr de vouloir supprimer la dérogation ?"
};

var deroAmountModal = {
  id: "8379752f-66f5-44bf-b09c-c39964a0b08a",
  defaultMessage: "Montant Dérogation"
};

export {
  derogateButton ,
  derogateTitle ,
  derogateOrderLabel ,
  derogateActionInfo ,
  derogateSignLabel ,
  derogateActionLabel ,
  derogateInfoLabel ,
  derogatePlanPerf ,
  derogateStoc ,
  derogateReprise ,
  derogateIdAction ,
  derogateId ,
  derogateIdLabel ,
  derogateAmount ,
  derogateConfirmTitle ,
  derogateConfirmInfo ,
  derogateBonusAmountPaid ,
  validation ,
  alertDero ,
  derogationImpossible ,
  budgetDero ,
  deroAmountAction ,
  deroConfirmDelete ,
  deroAmountModal ,
}
/* No side effect */
