// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Home_Video_Modal$DvmAdminFrontendFr from "./Home_Video_Modal.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Home_Videos$DvmAdminFrontendFr from "../../../intl/messages/home/Messages_Home_Videos.bs.js";

function videosListItem_encode(v) {
  return Js_dict.fromArray([
              [
                "image",
                Decco.stringToJson(v.image)
              ],
              [
                "targetName",
                Decco.stringToJson(v.targetName)
              ]
            ]);
}

function videosListItem_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var image = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "image"), null));
  if (image.TAG === /* Ok */0) {
    var targetName = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "targetName"), null));
    if (targetName.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                image: image._0,
                targetName: targetName._0
              }
            };
    }
    var e = targetName._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".targetName" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = image._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".image" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function videosList_encode(v) {
  return Decco.listToJson(videosListItem_encode, v);
}

function videosList_decode(v) {
  return Decco.listFromJson(videosListItem_decode, v);
}

function Home_Videos(Props) {
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setVideosResult = match$2[1];
  var videosResult = match$2[0];
  var match$3 = React.useState(function () {
        return null;
      });
  var setModal = match$3[1];
  React.useEffect((function () {
          RestApi$DvmAdminFrontendFr.call("GET", videosList_decode, undefined, userManager, selectedRole, "/cloud/video/list").then(function (result) {
                if (result.TAG !== /* OkResult */0) {
                  return Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                                  message: {
                                    TAG: /* RestError */2,
                                    _0: result._0
                                  }
                                }));
                }
                var videoLinks = result._0;
                Curry._1(setVideosResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: videoLinks
                              };
                      }));
              });
        }), []);
  return React.createElement("div", undefined, match$3[0], React.createElement(Core.Box, {
                  children: React.createElement("h3", undefined, intl.formatMessage(Messages_Home_Videos$DvmAdminFrontendFr.tutorialsTitle)),
                  alignItems: "center",
                  display: "flex"
                }), React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                  show: App_Types_Result$DvmAdminFrontendFr.isPending(videosResult),
                  children: React.createElement(Core.Box, {
                        children: Belt_List.toArray(Belt_List.mapWithIndex(App_Types_Result$DvmAdminFrontendFr.getWithDefault(videosResult, /* [] */0), (function (index, video) {
                                    return React.createElement(Core.Tooltip, {
                                                arrow: true,
                                                children: React.createElement(Core.Button, {
                                                      onClick: (function (param) {
                                                          Curry._1(setModal, (function (param) {
                                                                  return React.createElement(Home_Video_Modal$DvmAdminFrontendFr.make, {
                                                                              videoName: video.targetName,
                                                                              onClose: (function (param) {
                                                                                  Curry._1(setModal, (function (param) {
                                                                                          return null;
                                                                                        }));
                                                                                })
                                                                            });
                                                                }));
                                                        }),
                                                      style: {
                                                        width: "" + String(288) + "px"
                                                      },
                                                      children: React.createElement(Core.Box, {
                                                            children: null,
                                                            alignItems: "flex-start",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gridRowGap: 5
                                                          }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                                color: "primary",
                                                                noWrap: true,
                                                                style: {
                                                                  fontWeight: "600",
                                                                  maxWidth: "230px",
                                                                  textOverflow: "ellipsis"
                                                                },
                                                                children: video.targetName,
                                                                variant: "bodyBig"
                                                              }), React.createElement("div", {
                                                                style: {
                                                                  position: "relative"
                                                                }
                                                              }, React.createElement(Core.Avatar, {
                                                                    children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                          size: /* XLarge */4,
                                                                          type_: /* Play */68
                                                                        }),
                                                                    style: {
                                                                      height: "50px",
                                                                      left: "calc(50% - 25px)",
                                                                      position: "absolute",
                                                                      top: "calc(50% - 25px)",
                                                                      width: "50px"
                                                                    }
                                                                  }), React.createElement("img", {
                                                                    style: {
                                                                      border: "1px solid " + Theme_Colors$DvmAdminFrontendFr.primaryBrown + "",
                                                                      borderRadius: "10px"
                                                                    },
                                                                    alt: video.targetName.substring(0, 50),
                                                                    src: "data:image/jpeg;base64," + video.image,
                                                                    width: "250"
                                                                  }))),
                                                      disableFocusRipple: true
                                                    }),
                                                classes: {
                                                  tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
                                                },
                                                placement: "top",
                                                title: video.targetName,
                                                key: String(index)
                                              });
                                  }))),
                        display: "flex",
                        flexWrap: "wrap",
                        gridColumnGap: "20px",
                        gridRowGap: "20px",
                        width: "100%"
                      })
                }));
}

var videoButtonWidth = 288;

var make = Home_Videos;

export {
  videosListItem_encode ,
  videosListItem_decode ,
  videosList_encode ,
  videosList_decode ,
  videoButtonWidth ,
  make ,
}
/* react Not a pure module */
