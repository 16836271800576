// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as RestApi$DvmAdminFrontendFr from "../../api/RestApi.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../types/App_Types_Input.bs.js";
import * as File_Upload_Types$DvmAdminFrontendFr from "./File_Upload_Types.bs.js";

function postFile(userManager, setResult, file, selectedTable, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var formData = new FormData();
  formData.append("file", file);
  RestApi$DvmAdminFrontendFr.call("POST", File_Upload_Types$DvmAdminFrontendFr.excelInfo_decode, {
          TAG: /* FormData */1,
          _0: formData
        }, userManager, selectedRole, "/upload/excel/upload?table=" + File_Upload_Types$DvmAdminFrontendFr.SelectedTable.toString(selectedTable) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var excelInfo = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: excelInfo
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var intResponse_encode = Decco.intToJson;

var intResponse_decode = Decco.intFromJson;

function excelConfirm(userManager, setResult, confirmBody, selectedTable, expirationToken, cellRange, $$delete, sheet, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("POST", intResponse_decode, {
          TAG: /* Json */0,
          _0: File_Upload_Types$DvmAdminFrontendFr.confirmBody_encode(confirmBody)
        }, userManager, selectedRole, "/upload/excel/confirm?table=" + File_Upload_Types$DvmAdminFrontendFr.SelectedTable.toString(selectedTable) + "&expirationToken=" + expirationToken + "&sheet=" + sheet + "&cellRange=" + cellRange + "&delete=" + (
          $$delete ? "true" : "false"
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var actionId = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: actionId
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchValidateResult(userManager, setResult, mapping, selectedTable, sheet, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("POST", File_Upload_Types$DvmAdminFrontendFr.validateResult_decode, {
          TAG: /* Json */0,
          _0: File_Upload_Types$DvmAdminFrontendFr.mappedPairsList_encode(mapping)
        }, userManager, selectedRole, "/upload/excel/validate?table=" + File_Upload_Types$DvmAdminFrontendFr.SelectedTable.toString(selectedTable) + "&sheet=" + sheet + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var validateResult = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: validateResult
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function getTableNames(userManager, setTableNames, selectedRole) {
  Curry._1(setTableNames, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.stringList_decode, undefined, userManager, selectedRole, "/upload/excel/tables").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var names = result._0;
          return Curry._1(setTableNames, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: names
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setTableNames, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function checkWithStageTable(userManager, setCheckWithStageResult, mapping, expirationToken, selectedTable, cellRange, sheet, selectedRole) {
  Curry._1(setCheckWithStageResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("POST", File_Upload_Types$DvmAdminFrontendFr.validateResult_decode, {
          TAG: /* Json */0,
          _0: File_Upload_Types$DvmAdminFrontendFr.mappedPairsList_encode(mapping)
        }, userManager, selectedRole, "/upload/excel/stage?expirationToken=" + expirationToken + "&table=" + File_Upload_Types$DvmAdminFrontendFr.SelectedTable.toString(selectedTable) + "&cellRange=" + cellRange + "&sheet=" + sheet + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var result$1 = result._0;
          return Curry._1(setCheckWithStageResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: result$1
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setCheckWithStageResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function checkImportStatus(userManager, setResult, selectedRole, activityId) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", File_Upload_Types$DvmAdminFrontendFr.statusResult_decode, undefined, userManager, selectedRole, "/upload/excel/import/status/" + activityId + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var response = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: response
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function runContractAttachmentCalculations(userManager, setResult, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("POST", intResponse_decode, undefined, userManager, selectedRole, "/upload/excel/check/contract-attachments/inject").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var response = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: response
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Types;

export {
  Types ,
  postFile ,
  intResponse_encode ,
  intResponse_decode ,
  excelConfirm ,
  fetchValidateResult ,
  getTableNames ,
  checkWithStageTable ,
  checkImportStatus ,
  runContractAttachmentCalculations ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
