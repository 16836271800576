// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as Utils_Form$DvmAdminFrontendFr from "../../utils/Utils_Form.bs.js";
import * as Utils_Input$DvmAdminFrontendFr from "../../utils/Utils_Input.bs.js";
import * as App_Types_Text$DvmAdminFrontendFr from "../../types/App_Types_Text.bs.js";

var radioStyle = Css.css({
      padding: "0px 9px"
    });

function App_RadioFormInput(Props) {
  var label = Props.label;
  var required = Props.required;
  var value = Props.value;
  var options = Props.options;
  var optionsDisabled = Props.optionsDisabled;
  var validationResult = Props.validationResult;
  var onChange = Props.onChange;
  var className = Props.className;
  var classNameTitle = Props.classNameTitle;
  var disabled = Props.disabled;
  var rowOpt = Props.row;
  var row = rowOpt !== undefined ? rowOpt : true;
  var intl = ReactIntl.useIntl();
  var tmp = {
    children: null
  };
  if (className !== undefined) {
    tmp.className = className;
  }
  var tmp$1 = Belt_Option.map(validationResult, Belt_Result.isError);
  if (tmp$1 !== undefined) {
    tmp.error = tmp$1;
  }
  var tmp$2 = {
    children: Belt_Option.mapWithDefault(label, null, (function (label) {
            if (required !== undefined && required) {
              return Utils_Input$DvmAdminFrontendFr.requiredLabel(intl, label);
            } else {
              return intl.formatMessage(label);
            }
          })),
    variant: "subtitle1"
  };
  if (classNameTitle !== undefined) {
    tmp$2.className = classNameTitle;
  }
  return React.createElement(Core.FormControl, tmp, React.createElement(Core.FormLabel, {
                  children: React.createElement(Core.Typography, tmp$2)
                }), React.createElement(Core.RadioGroup, {
                  row: row,
                  children: Belt_List.toArray(Belt_List.mapWithIndex(options, (function (index, option) {
                              return React.createElement(Core.FormControlLabel, {
                                          control: React.createElement(Core.Radio, {
                                                className: radioStyle,
                                                color: "default"
                                              }),
                                          disabled: Belt_Option.getWithDefault(disabled, false) || Belt_Option.getWithDefault(Belt_Option.mapWithDefault(optionsDisabled, undefined, (function (disables) {
                                                      return Belt_List.get(disables, index);
                                                    })), false),
                                          label: App_Types_Text$DvmAdminFrontendFr.toString(intl, option.label),
                                          labelPlacement: "end",
                                          value: option.value,
                                          key: String(index)
                                        });
                            }))),
                  onChange: (function ($$event) {
                      Curry._1(onChange, $$event.currentTarget.value);
                    }),
                  value: value
                }), Belt_Option.mapWithDefault(Utils_Form$DvmAdminFrontendFr.getErrorMessage(intl, validationResult), null, (function (errorMessage) {
                    return React.createElement(Core.FormHelperText, {
                                children: errorMessage
                              });
                  })));
}

var make = App_RadioFormInput;

export {
  radioStyle ,
  make ,
}
/* radioStyle Not a pure module */
