// Generated by ReScript, PLEASE EDIT WITH CARE


var title = {
  id: "dffee42b-2c7e-4fcc-825c-7f6950e951bd",
  defaultMessage: "Gérer les grilles de conditions budget"
};

var contractId = {
  id: "14e31c4b-6424-4690-abb4-64611e9e1d97",
  defaultMessage: "Code Loueur"
};

var createModelVersionGridButton = {
  id: "b6f2d0f7-2c8d-40e8-ac46-74e000ae219e",
  defaultMessage: "créer une nouvelle grille"
};

var manageTitle = {
  id: "2d5bd769-4d1a-4bd0-9aac-c0788c22ce5b",
  defaultMessage: "Paramétrer une grille de conditions"
};

var manageSubTitle = {
  id: "5cf5de36-0ee1-4b92-9cac-353336b59c58",
  defaultMessage: "Informations Grille de Conditions"
};

var createGridJiraButton = {
  id: "7d04b9b8-b36f-4550-bce8-a33dfb860cc6",
  defaultMessage: "Ajouter une nouvelle grille"
};

var actions = {
  id: "df505fdb-a8f8-44d8-8047-154cff815bc5",
  defaultMessage: "Actions"
};

var viewGrid = {
  id: "0a459e20-3f64-4e1c-81f2-e82d64033f81",
  defaultMessage: "Consulter la grille "
};

var modifyGrid = {
  id: "5d8aed7a-0802-4b1b-af29-b7a820bdc978",
  defaultMessage: "Modifier/Corriger la grille"
};

var editModelVersionTitleModal = {
  id: "0c83920b-d724-491d-a1b9-d4ec71627a4b",
  defaultMessage: "Modifier la grille d’aide budget pour {type}"
};

var editModelVersionModalSelectModel = {
  id: "a2951d59-902e-4a7f-8b1e-864be4ab31a1",
  defaultMessage: "Sélectionner un modèle"
};

var editModelVersionModalSelectFilter = {
  id: "caac9964-647f-46f1-bd02-d21bc53cb353",
  defaultMessage: "Recherche avancée"
};

var editModelVersionModalAction = {
  id: "4a44f273-5421-47cb-98df-6762aa74e740",
  defaultMessage: "Sélectionner une date de fin à appliquer aux résultats de votre recherche:"
};

var editModelVersionModalActionDate = {
  id: "d7ae0496-46a3-48d0-aab5-bd86173fc8bb",
  defaultMessage: "Appliquer une date de fin"
};

var noModels = {
  id: "32194520-d242-4457-bf83-0ab543824dcd",
  defaultMessage: "Aucun modèle"
};

var acquisitionId = {
  id: "4baa927b-ddbb-4a47-b242-46d5f33ff6b7",
  defaultMessage: "Type Acq."
};

var multipleEditLabel = {
  id: "1a72e9c7-85d6-4bc7-9edb-8e77e6fb4f40",
  defaultMessage: "Modifier plusieurs lignes"
};

var multipleEditAdctions = {
  id: "2cd7b376-6c99-4ec9-81c4-ac0b33fc1642",
  defaultMessage: "Sélectionner une action à appliquer aux résultats de votre recherche:"
};

var multipleEditAdctionDate = {
  id: "e3d7eb2d-5d6c-4f05-b068-dcfcdeb4836d",
  defaultMessage: "Appliquer une date de fin"
};

var dateInput = {
  id: "cb1d0c0d-f07d-4854-ae54-af8be6b26702",
  defaultMessage: "Période de validité"
};

var exceptionVersion = {
  id: "27a063c1-a66f-458d-841f-a949be935902",
  defaultMessage: "Exception V."
};

export {
  title ,
  contractId ,
  createModelVersionGridButton ,
  manageTitle ,
  manageSubTitle ,
  createGridJiraButton ,
  actions ,
  viewGrid ,
  modifyGrid ,
  editModelVersionTitleModal ,
  editModelVersionModalSelectModel ,
  editModelVersionModalSelectFilter ,
  editModelVersionModalAction ,
  editModelVersionModalActionDate ,
  noModels ,
  acquisitionId ,
  multipleEditLabel ,
  multipleEditAdctions ,
  multipleEditAdctionDate ,
  dateInput ,
  exceptionVersion ,
}
/* No side effect */
