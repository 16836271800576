// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../../../route/Route_Paths.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../../common/components/App_LabeledText.bs.js";
import * as App_Types_Status$DvmAdminFrontendFr from "../../../../common/types/App_Types_Status.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../../context/user/User_Types_Utils.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_Styles_Status$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Status.bs.js";
import * as Order_Detail_Styles$DvmAdminFrontendFr from "../Order_Detail_Styles.bs.js";
import * as Messages_Flash_Search$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Search.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Messages_Orders_Derogate$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Derogate.bs.js";
import * as Order_Detail_Error_Comment$DvmAdminFrontendFr from "./Order_Detail_Error_Comment.bs.js";
import * as Order_Detail_Bonus_Box_Custom_Message$DvmAdminFrontendFr from "./Order_Detail_Bonus_Box_Custom_Message.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Comment$DvmAdminFrontendFr from "./dealerRequest/Order_Detail_Bonus_DealerRequest_Comment.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Edit_Buttons$DvmAdminFrontendFr from "./dealerRequest/edits/Order_Detail_Bonus_DealerRequest_Edit_Buttons.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Documents_Button$DvmAdminFrontendFr from "./dealerRequest/viewDocuments/Order_Detail_Bonus_DealerRequest_Documents_Button.bs.js";

function getAmountText(bonus, archived, intl) {
  var match = bonus.bonus;
  var match$1 = bonus.state;
  if (match !== undefined && !(match$1 !== undefined && match$1 === /* BLOCKED */0 && archived === true && match === 0.0)) {
    return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, match);
  } else {
    return "-";
  }
}

function Order_Detail_Bonus_Box_Fleet(Props) {
  var bonus = Props.bonus;
  var color = Props.color;
  var openModal = Props.openModal;
  var archived = Props.archived;
  var bonusType = Props.bonusType;
  var order = Props.order;
  var documentsResult = Props.documentsResult;
  var dealerRequests = Props.dealerRequests;
  var orderId = Props.orderId;
  var reloadPage = Props.reloadPage;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var selectedRole = match[0].selectedRole;
  var filteredRequest;
  filteredRequest = typeof dealerRequests === "number" || dealerRequests.TAG !== /* Ok */0 ? undefined : Belt_List.getBy(dealerRequests._0, (function (x) {
            return x.actionId === Belt_Option.getWithDefault(bonus.stringActionID, "");
          }));
  var actionIDClickable = React.createElement(Core.Box, {
        children: null
      }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
            style: {
              color: Theme_Colors$DvmAdminFrontendFr.mineralDarker
            },
            children: intl.formatMessage(Messages_Flash_Search$DvmAdminFrontendFr.flashMarketingActionID),
            variant: "body"
          }), React.createElement(App_Typography$DvmAdminFrontendFr.make, {
            style: {
              fontWeight: "600"
            },
            children: Belt_Option.isSome(filteredRequest) ? Belt_Option.getWithDefault(bonus.stringActionID, "-") : React.createElement(Core.Link, {
                    children: Belt_Option.getWithDefault(bonus.stringActionID, "-"),
                    color: "textPrimary",
                    style: {
                      cursor: "pointer"
                    },
                    onClick: (function (param) {
                        Belt_Option.mapWithDefault(bonus.stringActionID, undefined, (function (actionID) {
                                RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rView + Route_Paths$DvmAdminFrontendFr.rFlash + "/fleet/" + actionID);
                              }));
                      })
                  }),
            variant: "body"
          }));
  var editButtons = function (orderType) {
    return React.createElement(Order_Detail_Bonus_DealerRequest_Edit_Buttons$DvmAdminFrontendFr.make, {
                order: order,
                documentsResult: documentsResult,
                actionId: bonus.stringActionID,
                orderType: orderType,
                orderId: orderId,
                reloadPage: reloadPage,
                filteredRequest: filteredRequest
              });
  };
  return Belt_Option.mapWithDefault(bonus.stringActionID, null, (function (param) {
                var comment = bonus.comment;
                return React.createElement(Core.Grid, {
                            children: null,
                            className: Order_Detail_Styles$DvmAdminFrontendFr.helpersBoxNew,
                            container: true,
                            spacing: 1,
                            style: {
                              borderLeft: "4px solid " + color + ""
                            }
                          }, React.createElement(Core.Grid, {
                                children: null,
                                container: true,
                                direction: "column",
                                item: true
                              }, React.createElement(Core.Grid, {
                                    children: null,
                                    container: true,
                                    item: true,
                                    wrap: "nowrap"
                                  }, React.createElement(Core.Grid, {
                                        alignItems: "flex-start",
                                        children: React.createElement(Core.Grid, {
                                              alignItems: "center",
                                              children: null,
                                              container: true,
                                              item: true,
                                              spacing: 1
                                            }, React.createElement(Core.Grid, {
                                                  children: React.createElement(Core.Box, {
                                                        className: App_Styles_Status$DvmAdminFrontendFr.oval,
                                                        bgcolor: App_Styles_Status$DvmAdminFrontendFr.stateColors(Belt_Option.getWithDefault(bonus.state, /* EMPTYSTATUS */10))
                                                      }),
                                                  item: true
                                                }), React.createElement(Core.Grid, {
                                                  children: intl.formatMessage(App_Types_Status$DvmAdminFrontendFr.toMessage(Belt_Option.getWithDefault(bonus.state, /* EMPTYSTATUS */10))),
                                                  item: true
                                                })),
                                        className: Order_Detail_Styles$DvmAdminFrontendFr.bonusesItemNew,
                                        container: true,
                                        item: true
                                      }), React.createElement(Core.Grid, {
                                        children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                              label: Messages_Flash_Search$DvmAdminFrontendFr.bonusType,
                                              children: bonusType
                                            }),
                                        className: Order_Detail_Styles$DvmAdminFrontendFr.bonusesItemNew,
                                        container: true,
                                        item: true
                                      }), React.createElement(Core.Grid, {
                                        children: actionIDClickable,
                                        className: Order_Detail_Styles$DvmAdminFrontendFr.bonusesItemNew,
                                        container: true,
                                        item: true
                                      }), React.createElement(Core.Grid, {
                                        children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                              label: Messages_Flash_Search$DvmAdminFrontendFr.bonusLabel,
                                              children: Belt_Option.getWithDefault(bonus.actionLabel, "-")
                                            }),
                                        className: Order_Detail_Styles$DvmAdminFrontendFr.bonusesItemDoubleWide,
                                        container: true,
                                        item: true
                                      }), React.createElement(Core.Grid, {
                                        children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                              label: Messages_Orders_Detail$DvmAdminFrontendFr.preinvoiceDate,
                                              children: Belt_Option.mapWithDefault(bonus.preinvoiceDate, "-", (function (param) {
                                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                    }))
                                            }),
                                        className: Order_Detail_Styles$DvmAdminFrontendFr.bonusesItemNew,
                                        container: true,
                                        item: true
                                      }), React.createElement(Core.Grid, {
                                        children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                              label: Messages_Orders_Detail$DvmAdminFrontendFr.preinvoiceNumber,
                                              children: Belt_Option.mapWithDefault(bonus.preinvoiceNumber, "-", (function (x) {
                                                      if (x.length === 0) {
                                                        return "-";
                                                      } else {
                                                        return x;
                                                      }
                                                    }))
                                            }),
                                        className: Order_Detail_Styles$DvmAdminFrontendFr.bonusesItemNew,
                                        container: true,
                                        item: true
                                      }), React.createElement(Core.Grid, {
                                        children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                              label: Messages_Orders_Detail$DvmAdminFrontendFr.bapInvoiceDate,
                                              children: Belt_Option.mapWithDefault(bonus.invoiceDate, "-", (function (param) {
                                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                    }))
                                            }),
                                        className: Order_Detail_Styles$DvmAdminFrontendFr.bonusesItemDoubleWide,
                                        container: true,
                                        item: true
                                      }), React.createElement(Core.Grid, {
                                        children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                              label: Messages_Orders_Detail$DvmAdminFrontendFr.actionAmount,
                                              children: React.createElement(Core.Grid, {
                                                    alignItems: "flex-start",
                                                    children: null,
                                                    container: true,
                                                    item: true,
                                                    wrap: "nowrap"
                                                  }, React.createElement(Core.Grid, {
                                                        children: getAmountText(bonus, archived, intl),
                                                        item: true
                                                      }), Belt_Option.mapWithDefault(bonus.state, false, (function (x) {
                                                          if (x === /* PREINVOICED */5 || x === /* NOBONUS */3) {
                                                            return true;
                                                          } else {
                                                            return x === /* PAID */4;
                                                          }
                                                        })) ? React.createElement(Core.Grid, {
                                                          children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                size: /* Large */3,
                                                                type_: /* Tick */1,
                                                                color: Theme_Colors$DvmAdminFrontendFr.accentGreen
                                                              }),
                                                          item: true
                                                        }) : null)
                                            }),
                                        className: Order_Detail_Styles$DvmAdminFrontendFr.bonusesItemNew,
                                        container: true,
                                        item: true
                                      })), (Belt_Option.getWithDefault(bonus.state, /* EMPTYSTATUS */10) === /* PAID */4 || Belt_Option.getWithDefault(bonus.state, /* EMPTYSTATUS */10) === /* NOBONUS */3 || Belt_Option.getWithDefault(bonus.state, /* EMPTYSTATUS */10) === /* PREINVOICED */5) && !archived && User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) ? React.createElement(Core.Grid, {
                                      children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                            onClick: openModal,
                                            label: intl.formatMessage(Messages_Orders_Derogate$DvmAdminFrontendFr.derogateButton),
                                            color: "secondary",
                                            size: "small"
                                          }),
                                      item: true
                                    }) : null, React.createElement(Order_Detail_Bonus_DealerRequest_Documents_Button$DvmAdminFrontendFr.make, {
                                    documentsResult: documentsResult,
                                    actionId: bonus.stringActionID
                                  }), React.createElement(Order_Detail_Bonus_DealerRequest_Comment$DvmAdminFrontendFr.make, {
                                    dealerRequests: dealerRequests,
                                    actionId: bonus.stringActionID
                                  }), Belt_Option.mapWithDefault(bonus.perfValidationDetail, null, (function (validationDetail) {
                                      return Belt_Option.mapWithDefault(validationDetail.approved, null, (function (approved) {
                                                    var match = validationDetail.date;
                                                    var match$1 = validationDetail.approver;
                                                    if (match !== undefined && match$1 !== undefined) {
                                                      return React.createElement(Core.Grid, {
                                                                  children: null,
                                                                  container: true,
                                                                  spacing: 1
                                                                }, React.createElement(Core.Grid, {
                                                                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                                            style: {
                                                                              fontWeight: "600"
                                                                            },
                                                                            children: React.createElement(Core.Grid, {
                                                                                  children: React.createElement(Core.Grid, {
                                                                                        children: intl.formatMessage(approved ? Messages_Orders_Detail$DvmAdminFrontendFr.validation : Messages_Orders_Detail$DvmAdminFrontendFr.refusal) + Intl_Utils$DvmAdminFrontendFr.formatDate(intl, Caml_option.valFromOption(match)) + (
                                                                                          selectedRole !== /* Dealer */7 || !User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole) ? " - " + match$1 : ""
                                                                                        ),
                                                                                        item: true
                                                                                      }),
                                                                                  container: true,
                                                                                  direction: "column",
                                                                                  item: true
                                                                                }),
                                                                            variant: "body"
                                                                          }),
                                                                      item: true
                                                                    }), !approved && Belt_Option.isSome(validationDetail.refuseReason) ? React.createElement(Core.Grid, {
                                                                        children: React.createElement(Core.Tooltip, {
                                                                              arrow: true,
                                                                              children: React.createElement("div", undefined, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                                        size: /* Large */3,
                                                                                        type_: /* Information */43
                                                                                      })),
                                                                              classes: {
                                                                                tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
                                                                              },
                                                                              placement: "bottom",
                                                                              title: Belt_Option.getWithDefault(validationDetail.refuseReason, "-")
                                                                            }),
                                                                        item: true
                                                                      }) : null);
                                                    }
                                                    return React.createElement(Core.Grid, {
                                                                children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                                      style: {
                                                                        fontWeight: "600"
                                                                      },
                                                                      children: React.createElement(Core.Grid, {
                                                                            children: React.createElement(Core.Grid, {
                                                                                  children: intl.formatMessage(approved ? Messages_Orders_Detail$DvmAdminFrontendFr.validationWithoutInfo : Messages_Orders_Detail$DvmAdminFrontendFr.refusalWithoutInfo),
                                                                                  item: true
                                                                                }),
                                                                            container: true,
                                                                            direction: "column",
                                                                            item: true
                                                                          }),
                                                                      variant: "body"
                                                                    }),
                                                                item: true
                                                              });
                                                  }));
                                    })), React.createElement(Order_Detail_Bonus_Box_Custom_Message$DvmAdminFrontendFr.make, {
                                    bonus: bonus,
                                    order: order
                                  }), comment !== undefined ? Belt_Array.map(Belt_List.toArray(comment), (function (comment) {
                                        return React.createElement(Order_Detail_Error_Comment$DvmAdminFrontendFr.make, {
                                                    comment: comment,
                                                    actionDetail: bonus,
                                                    order: order
                                                  });
                                      })) : null), Belt_Option.mapWithDefault(order.orderType, null, (function (orderType) {
                                  return Belt_Option.mapWithDefault(bonus.state, editButtons(orderType), (function (state) {
                                                if (state === /* NOBONUS */3 || state === /* PREINVOICED */5 || state === /* PAID */4) {
                                                  return null;
                                                } else {
                                                  return editButtons(orderType);
                                                }
                                              }));
                                })));
              }));
}

var Msg;

var StatusTypes;

var Styles;

var make = Order_Detail_Bonus_Box_Fleet;

export {
  Msg ,
  StatusTypes ,
  Styles ,
  getAmountText ,
  make ,
}
/* react Not a pure module */
