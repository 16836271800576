// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Css from "@emotion/css";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.bs.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../icons/App_Icon.bs.js";
import * as App_Spinner$DvmAdminFrontendFr from "../App_Spinner.bs.js";
import * as Messages_Error$DvmAdminFrontendFr from "../../../intl/messages/Messages_Error.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../types/App_Types_Result.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "./App_AutocompleteFormInput.bs.js";

var popper = Css.css({
      top: "28px !important"
    });

function App_DebounceFormInput(Props) {
  var onChange = Props.onChange;
  var value = Props.value;
  var values = Props.values;
  var className = Props.className;
  var callApi = Props.callApi;
  var label = Props.label;
  var debounceTriggerLength = Props.debounceTriggerLength;
  var intl = ReactIntl.useIntl();
  var debounce = ReactDebounce.useDebounced(500, Curry.__1(callApi));
  var tmp;
  tmp = typeof values === "number" || values.TAG !== /* Ok */0 ? /* [] */0 : values._0;
  var tmp$1;
  if (typeof values === "number") {
    tmp$1 = values === /* NotStarted */0 ? (
        value.length === 0 ? intl.formatMessage(Messages_Common$DvmAdminFrontendFr.insertNumberOfCharacters, {
                number: debounceTriggerLength
              }) : ""
      ) : intl.formatMessage(Messages_Common$DvmAdminFrontendFr.loading);
  } else if (values.TAG === /* Ok */0) {
    var match = Belt_List.length(values._0);
    var match$1 = value.length;
    tmp$1 = match !== 0 ? "" + String(match) + " trouvé" : (
        match$1 !== 0 ? "" : intl.formatMessage(Messages_Common$DvmAdminFrontendFr.nothingFound)
      );
  } else {
    tmp$1 = intl.formatMessage(Messages_Error$DvmAdminFrontendFr.error);
  }
  return React.createElement(Core.Tooltip, {
              children: React.createElement("div", undefined, React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                        label: label,
                        value: {
                          TAG: /* Single */0,
                          _0: {
                            value: value,
                            onChange: Curry.__1(onChange)
                          }
                        },
                        options: {
                          TAG: /* Unlabeled */0,
                          _0: Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.concat(value.length > 0 ? ({
                                                hd: value,
                                                tl: /* [] */0
                                              }) : /* [] */0, tmp))))
                        },
                        className: className,
                        onSearch: (function (filter) {
                            var value = filter.trim();
                            if (value.length >= debounceTriggerLength) {
                              Curry._1(debounce, value);
                              return Curry._1(onChange, value);
                            }
                            
                          }),
                        loading: App_Types_Result$DvmAdminFrontendFr.isPending(values),
                        matchFrom: "start",
                        popupIcon: App_Types_Result$DvmAdminFrontendFr.isPending(values) ? React.createElement(App_Spinner$DvmAdminFrontendFr.make, {
                                size: /* Fixed */{
                                  _0: "20px"
                                }
                              }) : React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                size: /* Regular */2,
                                type_: /* PadDown */11
                              }),
                        freeSolo: true
                      })),
              classes: {
                popper: popper
              },
              placement: "top",
              title: tmp$1
            });
}

var Api;

var make = App_DebounceFormInput;

export {
  Api ,
  popper ,
  make ,
}
/* popper Not a pure module */
