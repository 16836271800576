// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as App_Table$DvmAdminFrontendFr from "./App_Table.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../types/App_Types_Result.bs.js";

function App_Table_Simple(Props) {
  var headers = Props.headers;
  var handleSorting = Props.handleSorting;
  var content = Props.content;
  var rowButton = Props.rowButton;
  var exportMenuOpt = Props.exportMenu;
  var width = Props.width;
  var height = Props.height;
  var rowHeight = Props.rowHeight;
  var rowPadding = Props.rowPadding;
  var exportMenu = exportMenuOpt !== undefined ? Caml_option.valFromOption(exportMenuOpt) : null;
  var tmp = {
    headers: headers,
    tablePage: App_Types_Result$DvmAdminFrontendFr.map(content, (function (content) {
            return {
                    content: content,
                    currentPage: 0,
                    totalElements: 0
                  };
          })),
    exportMenu: exportMenu
  };
  if (handleSorting !== undefined) {
    tmp.handleSorting = Caml_option.valFromOption(handleSorting);
  }
  if (rowButton !== undefined) {
    tmp.rowButton = Caml_option.valFromOption(rowButton);
  }
  if (width !== undefined) {
    tmp.width = Caml_option.valFromOption(width);
  }
  if (rowHeight !== undefined) {
    tmp.rowHeight = Caml_option.valFromOption(rowHeight);
  }
  if (rowPadding !== undefined) {
    tmp.rowPadding = Caml_option.valFromOption(rowPadding);
  }
  if (height !== undefined) {
    tmp.height = Caml_option.valFromOption(height);
  }
  return React.createElement(App_Table$DvmAdminFrontendFr.make, tmp);
}

var make = App_Table_Simple;

export {
  make ,
}
/* react Not a pure module */
