// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Order_Detail_Types_Comment$DvmAdminFrontendFr from "../Order_Detail_Types_Comment.bs.js";
import * as Order_Detail_Closed_Siret_Message$DvmAdminFrontendFr from "./Order_Detail_Closed_Siret_Message.bs.js";

function Order_Detail_Error_Comment(Props) {
  var comment = Props.comment;
  var actionDetail = Props.actionDetail;
  var order = Props.order;
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var locale = match[0].locale;
  var showErrorInGrey = Belt_Option.mapWithDefault(actionDetail, false, (function (action) {
          if (Belt_Option.getWithDefault(action.bonus, 0.0) === 0.0) {
            return Belt_Option.mapWithDefault(action.state, false, (function (state) {
                          if (state === /* NOBONUS */3) {
                            return true;
                          } else {
                            return state === /* PREINVOICED */5;
                          }
                        }));
          } else {
            return false;
          }
        }));
  return Belt_Option.mapWithDefault(comment.visibility, null, (function (visibility) {
                if (!visibility) {
                  return null;
                }
                var tmp = {
                  children: comment.errorId === /* SIRET_CLOSED */119 ? Belt_Option.mapWithDefault(actionDetail, null, (function (actionDetail) {
                            return React.createElement(Order_Detail_Closed_Siret_Message$DvmAdminFrontendFr.make, {
                                        actionDetail: actionDetail,
                                        siret: order.detail.siret,
                                        comment: comment
                                      });
                          })) : Belt_Option.mapWithDefault(locale === /* En */0 ? comment.errorDescription : comment.errorDescriptionLocal, Belt_Option.getWithDefault(comment.errorDescriptionLocal, ""), (function (x) {
                            if (x.length > 0) {
                              return x;
                            } else {
                              return Belt_Option.getWithDefault(comment.errorDescriptionLocal, "");
                            }
                          })),
                  variant: "caption"
                };
                var tmp$1 = showErrorInGrey ? undefined : "error";
                if (tmp$1 !== undefined) {
                  tmp.color = Caml_option.valFromOption(tmp$1);
                }
                return React.createElement(Core.Grid, {
                            children: React.createElement(App_Typography$DvmAdminFrontendFr.make, tmp),
                            item: true,
                            key: Order_Detail_Types_Comment$DvmAdminFrontendFr.toString(comment.errorId)
                          });
              }));
}

var make = Order_Detail_Error_Comment;

export {
  make ,
}
/* react Not a pure module */
