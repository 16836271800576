// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";

import './desyre/DesyreIcons.css'
;

var basicClass = "dsr-icon";

function pickType(iconType) {
  switch (iconType) {
    case /* HelpWarning */0 :
        return "dsr-nav-help-warning";
    case /* Tick */1 :
        return "dsr-nav-action-tick";
    case /* DeliveryKey */2 :
        return "dsr-commercial-key-delivery";
    case /* DeliveryCar */3 :
        return "dsr-commercial-car-delivery";
    case /* Disconnect */4 :
        return "dsr-nav-action-header-disconnect";
    case /* Documents */5 :
        return "dsr-nav-media-documents";
    case /* Contact */6 :
        return "dsr-nav-help-contact";
    case /* CarPark */7 :
        return "dsr-vehicles-status-cars-park";
    case /* ListOk */8 :
        return "dsr-nav-action-list-ok";
    case /* Home */9 :
        return "dsr-nav-action-header-home";
    case /* PadUp */10 :
        return "dsr-nav-pad-up";
    case /* PadDown */11 :
        return "dsr-nav-pad-down";
    case /* PadLeft */12 :
        return "dsr-nav-pad-left";
    case /* PadRight */13 :
        return "dsr-nav-pad-right";
    case /* Repair */14 :
        return "dsr-factory-repair";
    case /* Sheet */15 :
        return "dsr-nav-files-sheet";
    case /* Less */16 :
        return "dsr-nav-action-adjustement-less";
    case /* More */17 :
        return "dsr-nav-action-adjustement-more";
    case /* Calendar */18 :
        return "dsr-nav-notification-calendar";
    case /* BurgerMenu */19 :
        return "dsr-nav-action-header-burger-menu";
    case /* Alarm */20 :
        return "dsr-nav-notification-alarm";
    case /* HelpBubbleUnfocused */21 :
        return "dsr-nav-help-bubble-unfocused";
    case /* Camera */22 :
        return "dsr-nav-media-movie-camera";
    case /* Hint */23 :
        return "dsr-nav-help-hint";
    case /* ArrowUp */24 :
        return "dsr-nav-arrows-up";
    case /* ArrowRight */25 :
        return "dsr-nav-arrows-right";
    case /* ArrowDown */26 :
        return "dsr-nav-arrows-down";
    case /* ArrowLeft */27 :
        return "dsr-nav-arrows-left";
    case /* ZoomIn */28 :
        return "dsr-nav-action-adjustement-zoom-in";
    case /* ZoomOut */29 :
        return "dsr-nav-action-adjustement-zoom-out";
    case /* EmojiAngry */30 :
        return "dsr-others-emojis-angry";
    case /* EmojiDisappointed */31 :
        return "dsr-others-emojis-disappointed";
    case /* EmojiConfused */32 :
        return "dsr-others-emojis-confused";
    case /* EmojiFine */33 :
        return "dsr-others-emojis-fine";
    case /* EmojiHappy */34 :
        return "dsr-others-emojis-happy";
    case /* DealerFlag */35 :
        return "dsr-commercial-dealer-dealer-flag";
    case /* ProductPage */36 :
        return "dsr-nav-files-product-page";
    case /* Pdf */37 :
        return "dsr-nav-files-pdf";
    case /* Trash */38 :
        return "dsr-nav-action-trash";
    case /* Gear */39 :
        return "dsr-factory-gear";
    case /* ShoppingCart */40 :
        return "dsr-commercial-shopping-cart";
    case /* CarBody */41 :
        return "dsr-vehicles-exterior-car-body";
    case /* Cross */42 :
        return "dsr-nav-action-cross";
    case /* Information */43 :
        return "dsr-nav-help-information";
    case /* Reload */44 :
        return "dsr-nav-action-reload";
    case /* Picture */45 :
        return "dsr-nav-media-picture";
    case /* Todo */46 :
        return "dsr-nav-action-to-do";
    case /* Galaxy */47 :
        return "dsr-nav-action-header-galaxy";
    case /* User */48 :
        return "dsr-nav-users-user";
    case /* Search */49 :
        return "dsr-nav-action-header-search";
    case /* Panel */50 :
        return "dsr-nav-location-panel";
    case /* Cycle */51 :
        return "dsr-vehicles-others-cycle";
    case /* Toolbox */52 :
        return "dsr-factory-toolbox";
    case /* DumpTruck */53 :
        return "dsr-vehicles-cars-dump-truck";
    case /* Factory */54 :
        return "dsr-factory-factory";
    case /* Agreement */55 :
        return "dsr-commercial-agreement";
    case /* Renault */56 :
        return "dsr-commercial-dealer-renault";
    case /* Link */57 :
        return "dsr-nav-action-link";
    case /* FileSpecificityPage */58 :
        return "dsr-nav-files-specificity-page";
    case /* Show */59 :
        return "dsr-nav-action-show";
    case /* Edit */60 :
        return "dsr-nav-action-edit";
    case /* CarOK */61 :
        return "dsr-vehicles-status-signal-ok";
    case /* Export */62 :
        return "dsr-nav-action-export";
    case /* Filter */63 :
        return "dsr-nav-action-filter";
    case /* CarHood */64 :
        return "dsr-vehicles-exterior-car-hood";
    case /* Money */65 :
        return "dsr-commercial-money";
    case /* BubbleFocus */66 :
        return "dsr-nav-help-bubble-focus";
    case /* FuelPump */67 :
        return "dsr-vehicles-energy-fuel-pump";
    case /* Play */68 :
        return "dsr-nav-media-play";
    case /* CheckOil */69 :
        return "dsr-vehicles-maintenance-check-oil";
    case /* Box */70 :
        return "dsr-others-box";
    case /* Nonexistent */71 :
        return "";
    
  }
}

var elementSizeXSmall = "0.9rem";

var elementSizeSmall = "1.8rem";

var elementSizeRegular = "2.7rem";

var elementSizeLarge = "3.6rem";

var elementSizeXLarge = "4.8rem";

var elementSizeXXLarge = "5.0rem";

var elementSizeButton = "3.6rem";

var elementSizeButtonLarge = "6.4rem";

var elementSizeButtonSmall = "1.6rem";

var iconSizeButton = Css.css({
      fontSize: "2.5rem",
      lineHeight: "3.5rem"
    });

var xxlIcon = Css.css({
      fontSize: "8vh"
    });

function iconCircle(color) {
  return Css.css({
              background: "transparent",
              border: "var(--dsr-spacing-line) solid " + Belt_Option.getWithDefault(color, "var(--dsr-color-black)") + "",
              display: "flex",
              borderRadius: "50%",
              alignItems: "center",
              justifyContent: "center"
            });
}

var iconCircleXSmall = Css.css({
      height: elementSizeXSmall,
      width: elementSizeXSmall
    });

var iconCircleSmall = Css.css({
      height: elementSizeSmall,
      width: elementSizeSmall
    });

var iconCircleRegular = Css.css({
      height: elementSizeRegular,
      width: elementSizeRegular
    });

var iconCircleLarge = Css.css({
      height: elementSizeLarge,
      width: elementSizeLarge
    });

var iconCircleXLarge = Css.css({
      height: elementSizeXLarge,
      width: elementSizeXLarge
    });

var iconCircleXXLarge = Css.css({
      height: elementSizeXXLarge,
      width: elementSizeXXLarge
    });

var iconCircleButton = Css.css({
      height: elementSizeButton,
      width: elementSizeButton
    });

var iconCircleButtonLarge = Css.css({
      height: elementSizeButtonLarge,
      width: elementSizeButtonLarge
    });

var iconCircleButtonSmall = Css.css({
      height: elementSizeButtonSmall,
      width: elementSizeButtonSmall
    });

var diagonalLine = Css.css({
      background: "linear-gradient(to bottom left, white 46%, grey 0%, grey 54%, white 0%)"
    });

var Styles = {
  iconSizeButton: iconSizeButton,
  xxlIcon: xxlIcon,
  iconCircle: iconCircle,
  iconCircleXSmall: iconCircleXSmall,
  iconCircleSmall: iconCircleSmall,
  iconCircleRegular: iconCircleRegular,
  iconCircleLarge: iconCircleLarge,
  iconCircleXLarge: iconCircleXLarge,
  iconCircleXXLarge: iconCircleXXLarge,
  iconCircleButton: iconCircleButton,
  iconCircleButtonLarge: iconCircleButtonLarge,
  iconCircleButtonSmall: iconCircleButtonSmall,
  diagonalLine: diagonalLine
};

function App_Icon(Props) {
  var sizeOpt = Props.size;
  var type_ = Props.type_;
  var onClickOpt = Props.onClick;
  var role = Props.role;
  var children = Props.children;
  var inCircleOpt = Props.inCircle;
  var iconClassName = Props.iconClassName;
  var circleClassName = Props.circleClassName;
  var color = Props.color;
  var style = Props.style;
  var fontWeight = Props.fontWeight;
  var diagonalLineOpt = Props.diagonalLine;
  var size = sizeOpt !== undefined ? sizeOpt : /* Regular */2;
  var onClick = onClickOpt !== undefined ? onClickOpt : (function (param) {
        
      });
  var inCircle = inCircleOpt !== undefined ? inCircleOpt : false;
  var diagonalLine$1 = diagonalLineOpt !== undefined ? diagonalLineOpt : false;
  var pickIconSize = function (param) {
    switch (size) {
      case /* XSmall */0 :
          return "" + basicClass + "--xs";
      case /* Small */1 :
          return "" + basicClass + "--s";
      case /* Regular */2 :
          return "" + basicClass + "--m";
      case /* Large */3 :
          return "" + basicClass + "--l";
      case /* XLarge */4 :
          return "" + basicClass + "--xl";
      
    }
  };
  var fontWeight$1 = Belt_Option.mapWithDefault(fontWeight, "", (function (fontWeight) {
          return Css.css({
                      fontWeight: fontWeight
                    });
        }));
  var renderIcon = function (role) {
    var tmp;
    if (style !== undefined) {
      var style$1 = Caml_option.valFromOption(style);
      tmp = color !== undefined ? Caml_option.some(Object.assign({}, style$1, {
                  color: color
                })) : Caml_option.some(style$1);
    } else {
      tmp = color !== undefined ? ({
            color: color
          }) : undefined;
    }
    var tmp$1 = {
      className: Css.cx([
            basicClass,
            pickType(type_),
            pickIconSize(undefined),
            iconClassName !== undefined ? iconClassName : "",
            fontWeight$1,
            diagonalLine$1 ? diagonalLine : ""
          ]),
      onClick: onClick
    };
    if (role !== undefined) {
      tmp$1.role = Caml_option.valFromOption(role);
    }
    if (tmp !== undefined) {
      tmp$1.style = Caml_option.valFromOption(tmp);
    }
    return React.createElement("i", tmp$1, Belt_Option.getWithDefault(children, null));
  };
  if (!inCircle) {
    return renderIcon(role);
  }
  var tmp;
  switch (size) {
    case /* XSmall */0 :
        tmp = iconCircleXSmall;
        break;
    case /* Small */1 :
        tmp = iconCircleSmall;
        break;
    case /* Regular */2 :
        tmp = iconCircleRegular;
        break;
    case /* Large */3 :
        tmp = iconCircleLarge;
        break;
    case /* XLarge */4 :
        tmp = iconCircleXLarge;
        break;
    
  }
  var tmp$1 = {
    className: Css.cx([
          iconCircle(color),
          tmp,
          circleClassName !== undefined ? circleClassName : ""
        ])
  };
  if (style !== undefined) {
    tmp$1.style = Caml_option.valFromOption(style);
  }
  return React.createElement("div", tmp$1, renderIcon(role));
}

var make = App_Icon;

export {
  basicClass ,
  pickType ,
  elementSizeXSmall ,
  elementSizeSmall ,
  elementSizeRegular ,
  elementSizeLarge ,
  elementSizeXLarge ,
  elementSizeXXLarge ,
  elementSizeButton ,
  elementSizeButtonLarge ,
  elementSizeButtonSmall ,
  Styles ,
  make ,
}
/*  Not a pure module */
