// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_MarginGrid$DvmAdminFrontendFr from "../../../../intl/messages/marginGrid/Messages_MarginGrid.bs.js";
import * as Grid_Edit_New_Row_Api$DvmAdminFrontendFr from "./Grid_Edit_New_Row_Api.bs.js";
import * as Grid_Edit_New_Row_Inputs$DvmAdminFrontendFr from "./Grid_Edit_New_Row_Inputs.bs.js";
import * as Grid_Edit_New_Row_Modal_Form$DvmAdminFrontendFr from "./Grid_Edit_New_Row_Modal_Form.bs.js";

function Grid_Edit_New_Row_Modal(Props) {
  var budgetOrID = Props.budgetOrID;
  var original = Props.original;
  var onClose = Props.onClose;
  var whatTable = Props.whatTable;
  var whatGrid = Props.whatGrid;
  var budget = Props.budget;
  var operation = Props.operation;
  var setActionsPending = Props.setActionsPending;
  var acquisitionOptions = Props.acquisitionOptions;
  var filterStartDate = Props.filterStartDate;
  var filterEndDate = Props.filterEndDate;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$2[1];
  var result = match$2[0];
  var form = Grid_Edit_New_Row_Modal_Form$DvmAdminFrontendFr.Form.useForm(Grid_Edit_New_Row_Modal_Form$DvmAdminFrontendFr.initialInput, {
        whatTable: whatTable,
        whatGrid: whatGrid,
        budget: budget,
        operation: operation
      }, (function (output, submissionCallbacks) {
          var newRow_budget = budget;
          var newRow_model = output.model;
          var newRow_version = output.version;
          var newRow_avsMarginGridID = budgetOrID;
          var newRow_categoryID = output.categoryCode;
          var newRow_discount = output.discount;
          var newRow_margin = output.margin;
          var newRow_startDate = output.startDate;
          var newRow_endDate = Caml_option.some(output.endDate);
          var newRow_hasExceptions = output.hasExceptions;
          var newRow_acquisitionType = output.acquisitionType;
          var newRow_contractID = budgetOrID;
          var newRow = {
            budget: newRow_budget,
            model: newRow_model,
            version: newRow_version,
            avsMarginGridID: newRow_avsMarginGridID,
            categoryID: newRow_categoryID,
            discount: newRow_discount,
            margin: newRow_margin,
            previousEndDate: undefined,
            startDate: newRow_startDate,
            endDate: newRow_endDate,
            hasExceptions: newRow_hasExceptions,
            followingStartDate: undefined,
            acquisitionType: newRow_acquisitionType,
            contractID: newRow_contractID,
            categoryName: undefined
          };
          if (operation === /* AddCategory */2) {
            return Grid_Edit_New_Row_Api$DvmAdminFrontendFr.tryAddCategoryToMargins(submissionCallbacks, userManager, setResult, output.categoryCode, output.categoryStartDate, budget, whatGrid, selectedRole, newRow);
          } else {
            return Grid_Edit_New_Row_Api$DvmAdminFrontendFr.tryCreateNewMarginRow(submissionCallbacks, userManager, setResult, whatTable, whatGrid, budget, selectedRole, newRow);
          }
        }));
  React.useEffect((function () {
          if (typeof result === "number") {
            if (result !== /* NotStarted */0) {
              Curry._1(setActionsPending, (function (param) {
                      return true;
                    }));
            }
            
          } else if (result.TAG === /* Ok */0) {
            Curry._1(setActionsPending, (function (param) {
                    return false;
                  }));
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* Message */0,
                        _0: Messages_MarginGrid$DvmAdminFrontendFr.newRowSuccess
                      }
                    }));
            Curry._1(onClose, undefined);
          } else {
            Curry._1(setActionsPending, (function (param) {
                    return false;
                  }));
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: result._0
                      }
                    }));
          }
        }), [result]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(operation === /* AddCategory */2 ? Messages_Common$DvmAdminFrontendFr.addNewRows : Messages_Common$DvmAdminFrontendFr.addNewRow),
              minWidth: "400px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(result),
                    children: React.createElement("form", {
                          onSubmit: (function ($$event) {
                              $$event.preventDefault();
                              Curry._1(form.submit, undefined);
                            })
                        }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                              color: "primary",
                              children: React.createElement(Core.Grid, {
                                    children: null,
                                    container: true,
                                    direction: "column",
                                    spacing: 3
                                  }, React.createElement(Grid_Edit_New_Row_Inputs$DvmAdminFrontendFr.make, {
                                        whatTable: whatTable,
                                        whatGrid: whatGrid,
                                        form: form,
                                        budgetOrID: budgetOrID,
                                        budget: budget,
                                        acquisitionOptions: acquisitionOptions,
                                        operation: operation,
                                        original: original,
                                        filterStartDate: filterStartDate,
                                        filterEndDate: filterEndDate
                                      }), React.createElement(Core.Grid, {
                                        children: null,
                                        container: true,
                                        justifyContent: "space-between"
                                      }, React.createElement(Core.Grid, {
                                            children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                  onClick: (function (param) {
                                                      Curry._1(onClose, undefined);
                                                    }),
                                                  label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.cancel),
                                                  color: "primaryBorder",
                                                  size: "medium"
                                                }),
                                            item: true
                                          }), React.createElement(Core.Grid, {
                                            children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                  label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                                  size: "medium",
                                                  type_: "submit"
                                                }),
                                            item: true
                                          }))),
                              variant: "bodyBig"
                            }))
                  })
            });
}

var Api;

var Types;

var Form;

var make = Grid_Edit_New_Row_Modal;

export {
  Api ,
  Types ,
  Form ,
  make ,
}
/* react Not a pure module */
