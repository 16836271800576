// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../common/components/App_OverlaySpinner.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Messages_Orders_Correction$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Correction.bs.js";
import * as Order_Detail_Dealer_Edits_Api$DvmAdminFrontendFr from "./Order_Detail_Dealer_Edits_Api.bs.js";
import * as Order_Detail_Dealer_Edits_CustomerDiscount_Form$DvmAdminFrontendFr from "./Order_Detail_Dealer_Edits_CustomerDiscount_Form.bs.js";

function Order_Detail_Dealer_Edits_CustomerDiscount_Modal(Props) {
  var onClose = Props.onClose;
  var orderId = Props.orderId;
  var onOk = Props.onOk;
  var value = Props.value;
  var alert = ReactAlert.useAlert();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match[1];
  var saveResult = match[0];
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(onOk, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  var intl = ReactIntl.useIntl();
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  var match$3 = React.useState(function () {
        return false;
      });
  var setConfirmModal = match$3[1];
  var match$4 = React.useState(function () {
        
      });
  var setFinalValues = match$4[1];
  var finalValues = match$4[0];
  var match$5 = React.useState(function () {
        
      });
  var setSubmissionCallbacks = match$5[1];
  var submissionCallbacks = match$5[0];
  var postChange = function (param) {
    if (finalValues !== undefined && submissionCallbacks !== undefined) {
      return Order_Detail_Dealer_Edits_Api$DvmAdminFrontendFr.postDealerEditCorrection(userManager, setSaveResult, orderId, submissionCallbacks, selectedRole, finalValues);
    }
    
  };
  var form = Order_Detail_Dealer_Edits_CustomerDiscount_Form$DvmAdminFrontendFr.Form.useForm({
        customerDiscount: Belt_Option.mapWithDefault(value, "", (function (prim) {
                return String(prim);
              }))
      }, (function (param, submissionCallbacks) {
          var customerDiscount = param.customerDiscount;
          Curry._1(setFinalValues, (function (param) {
                  return {
                          customerDiscount: customerDiscount
                        };
                }));
          Curry._1(setSubmissionCallbacks, (function (param) {
                  return submissionCallbacks;
                }));
        }));
  React.useEffect((function () {
          if (finalValues !== undefined && submissionCallbacks !== undefined) {
            Curry._1(setConfirmModal, (function (param) {
                    return true;
                  }));
          }
          
        }), [
        finalValues,
        submissionCallbacks
      ]);
  var tmp = {
    onChange: Curry._1(form.updateCustomerDiscount, (function (_input, customerDiscount) {
            return {
                    customerDiscount: customerDiscount
                  };
          })),
    value: form.input.customerDiscount,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.extraLongInput,
    label: Messages_Common$DvmAdminFrontendFr.discountTTC,
    required: /* OnlyLabel */1,
    InputProps: {
      startAdornment: React.createElement(Core.InputAdornment, {
            children: "€",
            position: "start"
          })
    },
    inputType: "number"
  };
  if (form.customerDiscountResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.customerDiscountResult);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Modal$DvmAdminFrontendFr.make, {
                  open: true,
                  onClose: onClose,
                  title: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.discountTTC),
                  children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                        show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult),
                        children: React.createElement("form", {
                              onSubmit: (function ($$event) {
                                  $$event.preventDefault();
                                  Curry._1(form.submit, undefined);
                                })
                            }, React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  direction: "row",
                                  justifyContent: "space-between",
                                  spacing: 4,
                                  wrap: "nowrap"
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Grid, {
                                            children: null,
                                            item: true
                                          }, React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp), React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                style: {
                                                  color: Theme_Colors$DvmAdminFrontendFr.grayDark2,
                                                  fontWeight: "600"
                                                },
                                                children: intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.enterOneEuroCustomerDiscount),
                                                variant: "subtitle"
                                              })),
                                      container: true,
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: null,
                                      container: true,
                                      direction: "column",
                                      item: true
                                    }, React.createElement(Core.Grid, {
                                          children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                                color: "primary",
                                                size: "medium",
                                                type_: "submit",
                                                style: {
                                                  width: "100%"
                                                }
                                              }),
                                          item: true
                                        }), React.createElement(Core.Grid, {
                                          children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                noWrap: true,
                                                style: {
                                                  color: Theme_Colors$DvmAdminFrontendFr.grayDark2,
                                                  fontWeight: "600"
                                                },
                                                children: intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.correctionWillUpdateTomorrow),
                                                variant: "subtitle"
                                              }),
                                          item: true
                                        }))))
                      })
                }), match$3[0] ? React.createElement(App_Modal$DvmAdminFrontendFr.make, {
                    open: true,
                    onClose: (function (param) {
                        Curry._1(setConfirmModal, (function (param) {
                                return false;
                              }));
                      }),
                    title: intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.confirmEditModalTitle),
                    minWidth: "400px",
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          justifyContent: "space-between"
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                    onClick: (function (param) {
                                        Curry._1(setConfirmModal, (function (param) {
                                                return false;
                                              }));
                                        Belt_Option.mapWithDefault(submissionCallbacks, undefined, (function (x) {
                                                Curry._1(x.notifyOnFailure, /* FailedToUpdateOldVehicle */0);
                                              }));
                                      }),
                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.cancel),
                                    color: "primaryBorder",
                                    size: "medium"
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                    onClick: (function (param) {
                                        postChange(undefined);
                                        Curry._1(setConfirmModal, (function (param) {
                                                return false;
                                              }));
                                      }),
                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                    size: "medium"
                                  }),
                              item: true
                            }))
                  }) : null);
}

var Types;

var Api;

var Form;

var make = Order_Detail_Dealer_Edits_CustomerDiscount_Modal;

export {
  Types ,
  Api ,
  Form ,
  make ,
}
/* react Not a pure module */
