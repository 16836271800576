// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../../../common/types/App_Types_Input.bs.js";
import * as Company_Virtual_Search_Types$DvmAdminFrontendFr from "./Company_Virtual_Search_Types.bs.js";

function fetchCompanySearch(submissionCallbacks, userManager, setResult, queryParams, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Company_Virtual_Search_Types$DvmAdminFrontendFr.searchResult_decode, undefined, userManager, selectedRole, "/sirets/virtual" + queryParams + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchCompanySearch */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchVirtualSiretsAll(userManager, setResult, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Company_Virtual_Search_Types$DvmAdminFrontendFr.searchResult_decode, undefined, userManager, selectedRole, "/sirets/virtual?limit=150000&offset=0").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function queryForSiretCities(alert, userManager, setCities, onlyVirtual, selectedRole, filter) {
  Curry._1(setCities, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.stringList_decode, undefined, userManager, selectedRole, "/dropdown/cities/" + onlyVirtual + "?filter=" + filter + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var locations = result._0;
          return Curry._1(setCities, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: locations
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setCities, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

export {
  Form ,
  fetchCompanySearch ,
  fetchVirtualSiretsAll ,
  queryForSiretCities ,
}
/* react Not a pure module */
