// Generated by ReScript, PLEASE EDIT WITH CARE


var blocked = {
  id: "f719e487-a75c-4076-9a84-d01154583a2a",
  defaultMessage: "Anomalie"
};

var calculable = {
  id: "d186649c-cd9c-42d4-a50c-f6220f375f6a",
  defaultMessage: "Conforme"
};

var canceled = {
  id: "7a8b871c-6fba-4bcf-a115-f66cf23dea66",
  defaultMessage: "Annulée"
};

var nobonus = {
  id: "527d1107-62fe-4875-b497-e22696da9c1a",
  defaultMessage: "Sans Aide"
};

var paid = {
  id: "ae640318-82e5-4d1d-903f-854a41cda479",
  defaultMessage: "Payé"
};

var preinvoiced = {
  id: "3c5b77b6-63ba-4b1d-880b-303ac22369c3",
  defaultMessage: "Préfacture"
};

var validated = {
  id: "c2f26cdc-07bc-403c-b117-3b9a64170756",
  defaultMessage: "Validée"
};

var refused = {
  id: "adc43205-c94b-45fb-bf05-6d945817486c",
  defaultMessage: "Refusée"
};

var toValidate = {
  id: "188fe52f-43a6-4f2c-8ffa-d7c66a59ae13",
  defaultMessage: "A Valider"
};

var reparceled = {
  id: "eac7e206-ddf8-4ed7-8496-635af93a0b32",
  defaultMessage: "Remembrée"
};

var emptyStatus = {
  id: "710cdea7-4c31-4b1b-a1b0-8f7cad306a34",
  defaultMessage: "En cours de traitement"
};

var missingStatus = {
  id: "fec9a3e2-17b5-42bf-8a1d-4c1ff253f84c",
  defaultMessage: "Statut manquant"
};

var pending = {
  id: "f6c18de4-2e3d-4245-83fd-38ab18a74aeb",
  defaultMessage: "En attente"
};

var pendingInvoicingParc = {
  id: "c6cfb4b5-30b2-4ea6-8be2-1c93a64859bb",
  defaultMessage: "Attente sortie PARC"
};

export {
  blocked ,
  calculable ,
  canceled ,
  nobonus ,
  paid ,
  preinvoiced ,
  validated ,
  refused ,
  toValidate ,
  reparceled ,
  emptyStatus ,
  missingStatus ,
  pending ,
  pendingInvoicingParc ,
}
/* No side effect */
