// Generated by ReScript, PLEASE EDIT WITH CARE


var piwikEnabled = process.env.REACT_APP_PIWIK_ENABLED === "true";

var oktaEnabled = process.env.REACT_APP_OKTA_ENABLED === "true";

var currentEnvironmentProd = process.env.REACT_APP_CURRENT_ENVIRONMENT === "prod";

var currentEnvironmentPreprod = process.env.REACT_APP_CURRENT_ENVIRONMENT === "pprod";

var currentEnvironmentDev = process.env.REACT_APP_CURRENT_ENVIRONMENT === "dev";

export {
  piwikEnabled ,
  oktaEnabled ,
  currentEnvironmentProd ,
  currentEnvironmentPreprod ,
  currentEnvironmentDev ,
}
/* piwikEnabled Not a pure module */
