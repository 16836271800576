// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../common/utils/Utils_Api.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Grid_Edit_Api$DvmAdminFrontendFr from "../Grid_Edit_Api.bs.js";
import * as Grid_Edit_Modal_Table$DvmAdminFrontendFr from "./Grid_Edit_Modal_Table.bs.js";
import * as Grid_Edit_Modal_Filters$DvmAdminFrontendFr from "./Grid_Edit_Modal_Filters.bs.js";
import * as Grid_Edit_Modal_Filter_Form$DvmAdminFrontendFr from "./Grid_Edit_Modal_Filter_Form.bs.js";
import * as Grid_Edit_Modal_Collection_Display$DvmAdminFrontendFr from "./Grid_Edit_Modal_Collection_Display.bs.js";

function Grid_Edit_Modal_Page(Props) {
  var budgetOrID = Props.budgetOrID;
  var budget = Props.budget;
  var whatTable = Props.whatTable;
  var setTablePending = Props.setTablePending;
  var setTableResult = Props.setTableResult;
  var actionsPending = Props.actionsPending;
  var setActionsPending = Props.setActionsPending;
  var setResult = Props.setResult;
  var categories = Props.categories;
  var categoriesAll = Props.categoriesAll;
  var whatGrid = Props.whatGrid;
  var acquisitionOptions = Props.acquisitionOptions;
  var getStartOfQuery;
  if (whatGrid) {
    switch (whatTable) {
      case /* Model */0 :
      case /* Version */1 :
          getStartOfQuery = "budget=" + budgetOrID + "";
          break;
      case /* GridID */2 :
          getStartOfQuery = "avsMarginGridID=" + budgetOrID + "";
          break;
      
    }
  } else {
    getStartOfQuery = "contractId=" + budgetOrID + "";
  }
  var match = React.useState(function () {
        return false;
      });
  var withClosedModels = match[0];
  var match$1 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match$1[1];
  var tablePage = match$1[0];
  var match$2 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: 10,
                headers: Grid_Edit_Modal_Table$DvmAdminFrontendFr.getHeadersConfiguration(whatTable, whatGrid, budget),
                filterQueryParams: getStartOfQuery,
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$2[1];
  var fetchRequest = match$2[0];
  var alert = ReactAlert.useAlert();
  var match$3 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$4 = match$3[0];
  var userManager = match$4.userManager;
  var selectedRole = match$4.selectedRole;
  var fetchData = function (submissionCallbacks, fetchRequest) {
    Grid_Edit_Api$DvmAdminFrontendFr.fetchSearch(submissionCallbacks, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, fetchRequest), whatTable, whatGrid, budget, budgetOrID, selectedRole, withClosedModels);
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  var form = Grid_Edit_Modal_Filter_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Grid_Edit_Modal_Filter_Form$DvmAdminFrontendFr.initialState, {
        whatTable: whatTable
      }, (function (param, submissionCallbacks) {
          fetchData(submissionCallbacks, {
                page: 0,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: Grid_Edit_Modal_Table$DvmAdminFrontendFr.getHeadersConfiguration(whatTable, whatGrid, budget),
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                              hd: getStartOfQuery,
                              tl: {
                                hd: param.model,
                                tl: {
                                  hd: param.someID,
                                  tl: {
                                    hd: whatTable === /* Version */1 ? param.version : undefined,
                                    tl: {
                                      hd: whatTable === /* Version */1 ? param.categoryID : undefined,
                                      tl: {
                                        hd: param.discount,
                                        tl: {
                                          hd: param.startDate,
                                          tl: {
                                            hd: param.endDate,
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  React.useEffect((function () {
          var exit = 0;
          if (typeof tablePage === "number") {
            exit = 1;
          } else if (tablePage.TAG === /* Ok */0) {
            var table = tablePage._0;
            if (Belt_List.length(table.content) === 0 && table.total > 0) {
              var newRequest_page = fetchRequest.page - 1 | 0;
              var newRequest_rowsPerPage = fetchRequest.rowsPerPage;
              var newRequest_headers = fetchRequest.headers;
              var newRequest_filterQueryParams = fetchRequest.filterQueryParams;
              var newRequest_additionalQueryParams = fetchRequest.additionalQueryParams;
              var newRequest = {
                page: newRequest_page,
                rowsPerPage: newRequest_rowsPerPage,
                headers: newRequest_headers,
                filterQueryParams: newRequest_filterQueryParams,
                additionalQueryParams: newRequest_additionalQueryParams
              };
              fetchData(undefined, newRequest);
              Curry._1(setFetchRequest, (function (param) {
                      return newRequest;
                    }));
            } else {
              Curry._1(setTableResult, (function (param) {
                      return table.content;
                    }));
              Curry._1(setTablePending, (function (param) {
                      return false;
                    }));
            }
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: tablePage._0
                      }
                    }));
            Curry._1(setTablePending, (function (param) {
                    return false;
                  }));
          }
          if (exit === 1) {
            Curry._1(setTablePending, (function (param) {
                    return true;
                  }));
          }
          
        }), [tablePage]);
  React.useEffect((function () {
          if (!actionsPending) {
            fetchData(undefined, fetchRequest);
          }
          
        }), [actionsPending]);
  React.useEffect((function () {
          Curry._2(form.updateVersion, (function (input, version) {
                  return {
                          someID: input.someID,
                          model: input.model,
                          version: version,
                          categoryID: input.categoryID,
                          discount: input.discount,
                          startDate: input.startDate,
                          endDate: input.endDate
                        };
                }), "");
          Curry._1(form.submit, undefined);
        }), [whatTable]);
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              direction: "column",
              item: true
            }, React.createElement(Core.Grid, {
                  children: React.createElement(Grid_Edit_Modal_Filters$DvmAdminFrontendFr.make, {
                        budgetOrID: budgetOrID,
                        form: form,
                        whatTable: whatTable,
                        categories: categories,
                        withClosedModels: withClosedModels,
                        setWithClosedModels: match[1]
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Grid_Edit_Modal_Collection_Display$DvmAdminFrontendFr.make, {
                        budgetOrID: budgetOrID,
                        tablePage: tablePage,
                        fetchRequest: fetchRequest,
                        fetchData: fetchData,
                        setResult: setResult,
                        whatTable: whatTable,
                        categoriesAll: categoriesAll,
                        setActionsPending: setActionsPending,
                        whatGrid: whatGrid,
                        budget: budget,
                        acquisitionOptions: acquisitionOptions,
                        filterStartDate: form.input.startDate,
                        filterEndDate: form.input.endDate
                      }),
                  item: true
                }));
}

var Api;

var Table;

var TableDisplay;

var Filter;

var Form;

var Types;

var make = Grid_Edit_Modal_Page;

export {
  Api ,
  Table ,
  TableDisplay ,
  Filter ,
  Form ,
  Types ,
  make ,
}
/* react Not a pure module */
