// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

var FilterFields = {
  model: "model",
  category: "category",
  version: "version",
  versionName: "versionName"
};

function row_encode(v) {
  return Js_dict.fromArray([
              [
                "modelVersionID",
                Decco.stringToJson(v.modelVersionID)
              ],
              [
                "model",
                Decco.stringToJson(v.model)
              ],
              [
                "version",
                Decco.stringToJson(v.version)
              ],
              [
                "versionName",
                Decco.optionToJson(Decco.stringToJson, v.versionName)
              ],
              [
                "categoryID",
                Decco.optionToJson(Decco.stringToJson, v.categoryID)
              ],
              [
                "categoryName",
                Decco.optionToJson(Decco.stringToJson, v.categoryName)
              ]
            ]);
}

function row_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var modelVersionID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelVersionID"), null));
  if (modelVersionID.TAG === /* Ok */0) {
    var model = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "model"), null));
    if (model.TAG === /* Ok */0) {
      var version = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "version"), null));
      if (version.TAG === /* Ok */0) {
        var versionName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "versionName"), null));
        if (versionName.TAG === /* Ok */0) {
          var categoryID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "categoryID"), null));
          if (categoryID.TAG === /* Ok */0) {
            var categoryName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "categoryName"), null));
            if (categoryName.TAG === /* Ok */0) {
              return {
                      TAG: /* Ok */0,
                      _0: {
                        modelVersionID: modelVersionID._0,
                        model: model._0,
                        version: version._0,
                        versionName: versionName._0,
                        categoryID: categoryID._0,
                        categoryName: categoryName._0
                      }
                    };
            }
            var e = categoryName._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".categoryName" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = categoryID._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".categoryID" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = versionName._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".versionName" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = version._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".version" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = model._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".model" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = modelVersionID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".modelVersionID" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(row_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(row_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var AllVersions = {
  row_encode: row_encode,
  row_decode: row_decode,
  searchResult_encode: searchResult_encode,
  searchResult_decode: searchResult_decode
};

export {
  FilterFields ,
  AllVersions ,
}
/* No side effect */
