// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";

var Fields = {
  gemoCode: "gemoCode",
  gemoLabel: "gemoLabel",
  modelId: "modelId",
  bonusType: "bonusType",
  startDate: "startDate",
  endDate: "endDate",
  budgetActionType: "budgetActionType",
  submarketCode: "submarketCode"
};

function row_encode(v) {
  return Js_dict.fromArray([
              [
                "gemoCode",
                Decco.stringToJson(v.gemoCode)
              ],
              [
                "gemoLabel",
                Decco.optionToJson(Decco.stringToJson, v.gemoLabel)
              ],
              [
                "modelId",
                Decco.optionToJson(Decco.stringToJson, v.modelId)
              ],
              [
                "models",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(Decco.stringToJson, param);
                      }), v.models)
              ],
              [
                "bonusType",
                Decco.optionToJson(Decco.stringToJson, v.bonusType)
              ],
              [
                "submarketCode",
                Decco.optionToJson(Decco.stringToJson, v.submarketCode)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ],
              [
                "budgetActionType",
                Decco.optionToJson(Decco.stringToJson, v.budgetActionType)
              ]
            ]);
}

function row_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var gemoCode = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "gemoCode"), null));
  if (gemoCode.TAG === /* Ok */0) {
    var gemoLabel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "gemoLabel"), null));
    if (gemoLabel.TAG === /* Ok */0) {
      var modelId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelId"), null));
      if (modelId.TAG === /* Ok */0) {
        var models = Decco.optionFromJson((function (param) {
                return Decco.listFromJson(Decco.stringFromJson, param);
              }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "models"), null));
        if (models.TAG === /* Ok */0) {
          var bonusType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "bonusType"), null));
          if (bonusType.TAG === /* Ok */0) {
            var submarketCode = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "submarketCode"), null));
            if (submarketCode.TAG === /* Ok */0) {
              var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
              if (startDate.TAG === /* Ok */0) {
                var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
                if (endDate.TAG === /* Ok */0) {
                  var budgetActionType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "budgetActionType"), null));
                  if (budgetActionType.TAG === /* Ok */0) {
                    return {
                            TAG: /* Ok */0,
                            _0: {
                              gemoCode: gemoCode._0,
                              gemoLabel: gemoLabel._0,
                              modelId: modelId._0,
                              models: models._0,
                              bonusType: bonusType._0,
                              submarketCode: submarketCode._0,
                              startDate: startDate._0,
                              endDate: endDate._0,
                              budgetActionType: budgetActionType._0
                            }
                          };
                  }
                  var e = budgetActionType._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".budgetActionType" + e.path,
                            message: e.message,
                            value: e.value
                          }
                        };
                }
                var e$1 = endDate._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".endDate" + e$1.path,
                          message: e$1.message,
                          value: e$1.value
                        }
                      };
              }
              var e$2 = startDate._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".startDate" + e$2.path,
                        message: e$2.message,
                        value: e$2.value
                      }
                    };
            }
            var e$3 = submarketCode._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".submarketCode" + e$3.path,
                      message: e$3.message,
                      value: e$3.value
                    }
                  };
          }
          var e$4 = bonusType._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".bonusType" + e$4.path,
                    message: e$4.message,
                    value: e$4.value
                  }
                };
        }
        var e$5 = models._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".models" + e$5.path,
                  message: e$5.message,
                  value: e$5.value
                }
              };
      }
      var e$6 = modelId._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".modelId" + e$6.path,
                message: e$6.message,
                value: e$6.value
              }
            };
    }
    var e$7 = gemoLabel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".gemoLabel" + e$7.path,
              message: e$7.message,
              value: e$7.value
            }
          };
  }
  var e$8 = gemoCode._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".gemoCode" + e$8.path,
            message: e$8.message,
            value: e$8.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(row_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(row_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

export {
  Fields ,
  row_encode ,
  row_decode ,
  searchResult_encode ,
  searchResult_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
