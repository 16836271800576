// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Notistack from "notistack";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../common/components/icons/App_Icon.bs.js";
import * as App_Types_Text$DvmAdminFrontendFr from "../common/types/App_Types_Text.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../intl/messages/Messages_Common.bs.js";

function pickStyle(alertType) {
  if (alertType === "error") {
    return "dsr-toast--danger";
  } else if (alertType === "success") {
    return "dsr-toast--success";
  } else {
    return "";
  }
}

function pickIcon(alertType) {
  if (alertType === "error") {
    return /* HelpWarning */0;
  } else if (alertType === "success") {
    return /* Tick */1;
  } else {
    return /* HelpBubbleUnfocused */21;
  }
}

var make = React.forwardRef(function (Props, ref) {
      var id = Props.id;
      var header = Props.header;
      var label = Props.label;
      var variant = Props.variant;
      var style = Props.style;
      var intl = ReactIntl.useIntl();
      var match = Notistack.useSnackbar();
      var closeSnackbar = match.closeSnackbar;
      var createAlertHeading = function (param) {
        if (header !== undefined) {
          return App_Types_Text$DvmAdminFrontendFr.toString(intl, header);
        } else if (variant === "error") {
          return intl.formatMessage(Messages_Common$DvmAdminFrontendFr.alertError);
        } else if (variant === "customComp" || variant === "default" || variant === "warning") {
          return "";
        } else if (variant === "success") {
          return intl.formatMessage(Messages_Common$DvmAdminFrontendFr.alertSuccess);
        } else {
          return intl.formatMessage(Messages_Common$DvmAdminFrontendFr.alertInfo);
        }
      };
      var tmp = {
        style: style
      };
      var tmp$1 = Belt_Option.map((ref == null) ? undefined : Caml_option.some(ref), (function (prim) {
              return prim;
            }));
      if (tmp$1 !== undefined) {
        tmp.ref = Caml_option.valFromOption(tmp$1);
      }
      return React.createElement("div", tmp, React.createElement("div", {
                      className: "dsr-toast dsr-toast--colored " + pickStyle(variant) + ""
                    }, React.createElement("div", {
                          className: "dsr-toast__ico"
                        }, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                              size: /* Large */3,
                              type_: pickIcon(variant)
                            })), React.createElement("div", {
                          className: "dsr-toast__content"
                        }, React.createElement("div", {
                              className: "dsr-toast__title"
                            }, createAlertHeading(undefined)), React.createElement("div", {
                              className: "dsr-toast__text"
                            }, React.createElement(Core.Grid, {
                                  children: React.createElement(Core.Typography, {
                                        children: Belt_Option.mapWithDefault(label, "", (function (x) {
                                                return App_Types_Text$DvmAdminFrontendFr.toString(intl, x);
                                              })),
                                        variant: "body2"
                                      }),
                                  item: true,
                                  xs: Grid$Mui.Xs[12]
                                }))), React.createElement("div", {
                          "aria-label": "Close",
                          className: "dsr-toast__close",
                          role: "button",
                          tabIndex: 0,
                          onClick: (function (param) {
                              Curry._1(closeSnackbar, id);
                            })
                        })));
    });

export {
  pickStyle ,
  pickIcon ,
  make ,
}
/* make Not a pure module */
