// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Flash_Types$DvmAdminFrontendFr from "../../Flash_Types.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../theme/Theme_Colors.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_RadioFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_RadioFormInput.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Messages_Flash_Detail$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Detail.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_AutocompleteFormInput.bs.js";

function Flash_Edit_Info_Old_Vehicle(Props) {
  var form = Props.form;
  var formVO = Props.formVO;
  var brandsList = Props.brandsList;
  var intl = ReactIntl.useIntl();
  var tmp = {
    label: Messages_Flash_Edit$DvmAdminFrontendFr.vehicleAgeTitle,
    value: formVO.input.carAgeWhich,
    options: {
      hd: {
        label: {
          TAG: /* Message */0,
          _0: Messages_Common$DvmAdminFrontendFr.no
        },
        value: "none"
      },
      tl: {
        hd: {
          label: {
            TAG: /* Message */0,
            _0: Messages_Flash_Detail$DvmAdminFrontendFr.atDelivery
          },
          value: "delivery"
        },
        tl: {
          hd: {
            label: {
              TAG: /* Message */0,
              _0: Messages_Flash_Detail$DvmAdminFrontendFr.atOrder
            },
            value: "order"
          },
          tl: /* [] */0
        }
      }
    },
    onChange: Curry._1(formVO.updateCarAgeWhich, (function (input, carAgeWhich) {
            return {
                    carAge: input.carAge,
                    carAgeWhich: carAgeWhich,
                    possesionPeriod: input.possesionPeriod,
                    possesionPeriodWhich: input.possesionPeriodWhich,
                    brands: input.brands,
                    ie: input.ie,
                    actionWithVO: input.actionWithVO,
                    usedCarModels: input.usedCarModels
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.extraLongInput
  };
  if (formVO.carAgeWhichResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(formVO.carAgeWhichResult);
  }
  var match = formVO.input.carAgeWhich;
  var tmp$1;
  if (match === "none") {
    tmp$1 = null;
  } else {
    var tmp$2 = {
      onChange: Curry._1(formVO.updateCarAge, (function (input, carAge) {
              return {
                      carAge: carAge,
                      carAgeWhich: input.carAgeWhich,
                      possesionPeriod: input.possesionPeriod,
                      possesionPeriodWhich: input.possesionPeriodWhich,
                      brands: input.brands,
                      ie: input.ie,
                      actionWithVO: input.actionWithVO,
                      usedCarModels: input.usedCarModels
                    };
            })),
      value: formVO.input.carAge,
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
      label: Messages_Flash_Edit$DvmAdminFrontendFr.ageInput,
      inputType: "number"
    };
    if (formVO.carAgeResult !== undefined) {
      tmp$2.validationResult = Caml_option.valFromOption(formVO.carAgeResult);
    }
    tmp$1 = React.createElement(Core.Grid, {
          children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$2),
          item: true
        });
  }
  var tmp$3 = {
    label: Messages_Flash_Edit$DvmAdminFrontendFr.possesionAgeTitle,
    value: formVO.input.possesionPeriodWhich,
    options: {
      hd: {
        label: {
          TAG: /* Message */0,
          _0: Messages_Common$DvmAdminFrontendFr.no
        },
        value: "none"
      },
      tl: {
        hd: {
          label: {
            TAG: /* Message */0,
            _0: Messages_Flash_Detail$DvmAdminFrontendFr.atDelivery
          },
          value: "delivery"
        },
        tl: {
          hd: {
            label: {
              TAG: /* Message */0,
              _0: Messages_Flash_Detail$DvmAdminFrontendFr.atOrder
            },
            value: "order"
          },
          tl: /* [] */0
        }
      }
    },
    onChange: Curry._1(formVO.updatePossesionPeriodWhich, (function (input, possesionPeriodWhich) {
            return {
                    carAge: input.carAge,
                    carAgeWhich: input.carAgeWhich,
                    possesionPeriod: input.possesionPeriod,
                    possesionPeriodWhich: possesionPeriodWhich,
                    brands: input.brands,
                    ie: input.ie,
                    actionWithVO: input.actionWithVO,
                    usedCarModels: input.usedCarModels
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.extraLongInput
  };
  if (formVO.possesionPeriodWhichResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(formVO.possesionPeriodWhichResult);
  }
  var match$1 = formVO.input.possesionPeriodWhich;
  var tmp$4;
  if (match$1 === "none") {
    tmp$4 = null;
  } else {
    var tmp$5 = {
      onChange: Curry._1(formVO.updatePossesionPeriod, (function (input, possesionPeriod) {
              return {
                      carAge: input.carAge,
                      carAgeWhich: input.carAgeWhich,
                      possesionPeriod: possesionPeriod,
                      possesionPeriodWhich: input.possesionPeriodWhich,
                      brands: input.brands,
                      ie: input.ie,
                      actionWithVO: input.actionWithVO,
                      usedCarModels: input.usedCarModels
                    };
            })),
      value: formVO.input.possesionPeriod,
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
      label: Messages_Flash_Edit$DvmAdminFrontendFr.ageInput,
      inputType: "number"
    };
    if (formVO.possesionPeriodResult !== undefined) {
      tmp$5.validationResult = Caml_option.valFromOption(formVO.possesionPeriodResult);
    }
    tmp$4 = React.createElement(Core.Grid, {
          children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$5),
          item: true
        });
  }
  var tmp$6 = {
    label: Messages_Flash_Edit$DvmAdminFrontendFr.includeExcludeTitle,
    value: Flash_Types$DvmAdminFrontendFr.Acceptance.toString(formVO.input.ie),
    options: {
      hd: {
        label: {
          TAG: /* Message */0,
          _0: Messages_Flash_Edit$DvmAdminFrontendFr.brandAll
        },
        value: "ALL"
      },
      tl: {
        hd: {
          label: {
            TAG: /* Message */0,
            _0: Messages_Flash_Edit$DvmAdminFrontendFr.brandInclusion
          },
          value: "INCLUSION"
        },
        tl: {
          hd: {
            label: {
              TAG: /* Message */0,
              _0: Messages_Flash_Edit$DvmAdminFrontendFr.brandExclusion
            },
            value: "EXCLUSION"
          },
          tl: /* [] */0
        }
      }
    },
    onChange: (function (value) {
        Curry._2(formVO.updateIe, (function (input, ie) {
                return {
                        carAge: input.carAge,
                        carAgeWhich: input.carAgeWhich,
                        possesionPeriod: input.possesionPeriod,
                        possesionPeriodWhich: input.possesionPeriodWhich,
                        brands: input.brands,
                        ie: ie,
                        actionWithVO: input.actionWithVO,
                        usedCarModels: input.usedCarModels
                      };
              }), Belt_Option.getWithDefault(Flash_Types$DvmAdminFrontendFr.Acceptance.fromString(value), /* ALL */0));
      }),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.extraLongInput
  };
  if (formVO.ieResult !== undefined) {
    tmp$6.validationResult = Caml_option.valFromOption(formVO.ieResult);
  }
  var match$2 = formVO.input.ie;
  var tmp$7;
  if (match$2 !== 0) {
    var tmp$8 = {
      label: Messages_Common$DvmAdminFrontendFr.brands,
      value: {
        TAG: /* Multiple */1,
        _0: {
          value: formVO.input.brands,
          onChange: Curry._1(formVO.updateBrands, (function (input, brands) {
                  return {
                          carAge: input.carAge,
                          carAgeWhich: input.carAgeWhich,
                          possesionPeriod: input.possesionPeriod,
                          possesionPeriodWhich: input.possesionPeriodWhich,
                          brands: brands,
                          ie: input.ie,
                          actionWithVO: input.actionWithVO,
                          usedCarModels: input.usedCarModels
                        };
                }))
        }
      },
      options: {
        TAG: /* Labeled */1,
        _0: Belt_List.map(brandsList, (function (x) {
                return {
                        label: {
                          TAG: /* String */3,
                          _0: x.brandName
                        },
                        value: x.brandCode
                      };
              }))
      }
    };
    if (formVO.brandsResult !== undefined) {
      tmp$8.validationResult = Caml_option.valFromOption(formVO.brandsResult);
    }
    tmp$7 = React.createElement(Core.Grid, {
          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$8),
          item: true
        });
  } else {
    tmp$7 = null;
  }
  var match$3 = form.input.actionType;
  var tmp$9;
  if (typeof match$3 === "number") {
    tmp$9 = null;
  } else {
    var match$4 = formVO.input.ie;
    if (match$4 !== 1) {
      tmp$9 = null;
    } else {
      var tmp$10 = {
        label: Messages_Common$DvmAdminFrontendFr.models,
        value: {
          TAG: /* Multiple */1,
          _0: {
            value: formVO.input.usedCarModels,
            onChange: (function (val) {
                Curry._2(formVO.updateUsedCarModels, (function (input, usedCarModels) {
                        return {
                                carAge: input.carAge,
                                carAgeWhich: input.carAgeWhich,
                                possesionPeriod: input.possesionPeriod,
                                possesionPeriodWhich: input.possesionPeriodWhich,
                                brands: input.brands,
                                ie: input.ie,
                                actionWithVO: input.actionWithVO,
                                usedCarModels: usedCarModels
                              };
                      }), Belt_List.keep(Belt_List.map(val, (function (x) {
                                return x.replace(/[^\da-zA-Z]/g, "").toUpperCase();
                              })), (function (x) {
                            return x.length > 0;
                          })));
              })
          }
        },
        options: {
          TAG: /* Unlabeled */0,
          _0: /* [] */0
        },
        freeSolo: true,
        autoSelect: true,
        maxLength: 3,
        helperTextWhenNoError: Messages_Flash_Edit$DvmAdminFrontendFr.addModelsForVOHint
      };
      var tmp$11 = Belt_List.length(formVO.input.usedCarModels) > 0 ? undefined : intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.addModelsForVOHint);
      if (tmp$11 !== undefined) {
        tmp$10.placeholder = Caml_option.valFromOption(tmp$11);
      }
      tmp$9 = React.createElement(Core.Grid, {
            children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$10),
            item: true
          });
    }
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  spacing: 4
                }, React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      item: true,
                      spacing: 8
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(Core.Box, {
                                children: React.createElement(Core.Grid, {
                                      children: null,
                                      container: true,
                                      direction: "column",
                                      item: true
                                    }, React.createElement(Core.Grid, {
                                          children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, tmp),
                                          item: true
                                        }), tmp$1),
                                borderColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
                                borderLeft: 2,
                                paddingLeft: 1
                              }),
                          item: true,
                          md: Grid$Mui.Md[6],
                          sm: Grid$Mui.Sm[12]
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(Core.Box, {
                                children: React.createElement(Core.Grid, {
                                      children: null,
                                      container: true,
                                      direction: "column",
                                      item: true
                                    }, React.createElement(Core.Grid, {
                                          children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, tmp$3),
                                          item: true
                                        }), tmp$4),
                                borderColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
                                borderLeft: 2,
                                paddingLeft: 1
                              }),
                          item: true,
                          md: Grid$Mui.Md[6],
                          sm: Grid$Mui.Sm[12]
                        })), React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      direction: "column",
                      item: true,
                      spacing: 2
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, tmp$6),
                          item: true
                        }), tmp$7, tmp$9)));
}

var Form;

var FormVO;

var InputStyles;

var make = Flash_Edit_Info_Old_Vehicle;

export {
  Form ,
  FormVO ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
