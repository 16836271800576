// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Css from "@emotion/css";
import * as Container$Mui from "rescript-material-ui/src/Container.bs.js";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../../route/Route_Paths.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as Messages_Error$DvmAdminFrontendFr from "../../../intl/messages/Messages_Error.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Contract_Manager_Edit_Api$DvmAdminFrontendFr from "./Contract_Manager_Edit_Api.bs.js";
import * as Messages_Contr_Man_Search$DvmAdminFrontendFr from "../../../intl/messages/contractManagers/Messages_Contr_Man_Search.bs.js";
import * as Contract_Manager_Edit_Form$DvmAdminFrontendFr from "./Contract_Manager_Edit_Form.bs.js";
import * as Contract_Manager_Edit_Table$DvmAdminFrontendFr from "./table/Contract_Manager_Edit_Table.bs.js";
import * as Contract_Manager_Edit_Utils$DvmAdminFrontendFr from "./Contract_Manager_Edit_Utils.bs.js";
import * as Contract_Manager_Search_Api$DvmAdminFrontendFr from "../search/Contract_Manager_Search_Api.bs.js";
import * as Contract_Manager_Edit_Router$DvmAdminFrontendFr from "./Contract_Manager_Edit_Router.bs.js";
import * as Messages_Contract_Manager_Edit$DvmAdminFrontendFr from "../../../intl/messages/contractManagers/Messages_Contract_Manager_Edit.bs.js";

var boxForm = Css.css({
      backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight,
      padding: "20px 20px"
    });

var badge = Css.css({
      whiteSpace: "nowrap"
    });

function Contract_Manager_Edit_Page(Props) {
  var managerIdOpt = Props.managerId;
  var managerId = managerIdOpt !== undefined ? Caml_option.valFromOption(managerIdOpt) : undefined;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        
      });
  var setManager = match$2[1];
  var manager = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match$3[1];
  var saveResult = match$3[0];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setFetchedManager = match$4[1];
  var fetchedManager = match$4[0];
  var match$5 = React.useState(function () {
        return [];
      });
  var setInitialRows = match$5[1];
  var form = Contract_Manager_Edit_Form$DvmAdminFrontendFr.ContractManagerForm.useForm(Contract_Manager_Edit_Form$DvmAdminFrontendFr.initialInput, (function (output, submissionCallbacks) {
          Contract_Manager_Edit_Api$DvmAdminFrontendFr.saveManager(Belt_Option.isSome(manager) ? /* Update */1 : /* Create */0, userManager, setSaveResult, submissionCallbacks, output.managerID, intl, selectedRole, Belt_List.fromArray(Belt_Array.map(output.budgetRows, (function (x) {
                          return {
                                  managerID: output.managerID,
                                  managerName: output.managerName,
                                  budgets: x.budgets,
                                  startDate: x.startDate,
                                  endDate: x.endDate
                                };
                        }))));
        }));
  var match$6 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: 1000,
                headers: Contract_Manager_Edit_Table$DvmAdminFrontendFr.headersConfiguration,
                filterQueryParams: "managerId=" + Belt_Option.getWithDefault(managerId, "") + "&orderBy=-startDate",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$6[1];
  var fetchRequest = match$6[0];
  var match$7 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$8 = match$7[0];
  var userManager$1 = match$8.userManager;
  var selectedRole$1 = match$8.selectedRole;
  var fetchData = function (submissionCallbacks, fetchRequest) {
    Contract_Manager_Search_Api$DvmAdminFrontendFr.fetchManagersSearch(submissionCallbacks, userManager$1, setFetchedManager, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, fetchRequest), true, selectedRole$1);
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  var resetForm = function (param) {
    Curry._1(form.reset, undefined);
    Belt_Option.forEach(manager, (function (manager) {
            var __x = manager.managerID;
            Curry._2(form.updateManagerID, (function (input, managerID) {
                    return {
                            managerID: managerID,
                            managerName: input.managerName,
                            budgetRows: input.budgetRows
                          };
                  }), __x);
            Curry._2(form.updateManagerName, (function (input, managerName) {
                    return {
                            managerID: input.managerID,
                            managerName: managerName,
                            budgetRows: input.budgetRows
                          };
                  }), Belt_Option.getWithDefault(manager.managerName, ""));
          }));
    if (typeof fetchedManager !== "number" && fetchedManager.TAG === /* Ok */0) {
      var table = fetchedManager._0;
      Curry._1(setInitialRows, (function (param) {
              return Belt_List.toArray(Belt_List.map(table.content, (function (x) {
                                return {
                                        budgets: Belt_Option.getWithDefault(x.budgets, /* [] */0),
                                        startDate: x.startDate,
                                        endDate: x.endDate
                                      };
                              })));
            }));
      Belt_List.map(table.content, (function (x) {
              Curry._1(form.addBudgetRow, {
                    budgets: Belt_Option.getWithDefault(x.budgets, /* [] */0),
                    startDate: x.startDate,
                    endDate: x.endDate
                  });
            }));
    }
    
  };
  React.useEffect((function () {
          resetForm(undefined);
        }), [fetchedManager]);
  React.useEffect((function () {
          if (Belt_Option.isSome(managerId)) {
            fetchData(undefined, fetchRequest);
          } else {
            Curry._1(form.addBudgetRow, Contract_Manager_Edit_Form$DvmAdminFrontendFr.initialBudgetRowInput);
          }
        }), []);
  React.useEffect((function () {
          if (typeof fetchedManager === "number") {
            fetchedManager === /* NotStarted */0;
          } else if (fetchedManager.TAG === /* Ok */0) {
            var manager = Belt_List.head(fetchedManager._0.content);
            if (manager !== undefined) {
              Curry._1(setManager, (function (param) {
                      return manager;
                    }));
            } else {
              Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                        message: {
                          TAG: /* Message */0,
                          _0: Messages_Error$DvmAdminFrontendFr.errorOccured
                        }
                      }));
            }
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: fetchedManager._0
                      }
                    }));
          }
        }), [fetchedManager]);
  React.useEffect((function () {
          Belt_Option.forEach(manager, (function (manager) {
                  var __x = manager.managerID;
                  Curry._2(form.updateManagerID, (function (input, managerID) {
                          return {
                                  managerID: managerID,
                                  managerName: input.managerName,
                                  budgetRows: input.budgetRows
                                };
                        }), __x);
                  Curry._2(form.updateManagerName, (function (input, managerName) {
                          return {
                                  managerID: input.managerID,
                                  managerName: managerName,
                                  budgetRows: input.budgetRows
                                };
                        }), Belt_Option.getWithDefault(manager.managerName, ""));
                }));
        }), [manager]);
  var shouldDisplayError = function (param) {
    if (typeof fetchedManager === "number") {
      return false;
    } else if (fetchedManager.TAG === /* Ok */0) {
      return fetchedManager._0.total === 0;
    } else {
      return true;
    }
  };
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            fetchData(undefined, fetchRequest);
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Contract_Manager_Edit_Router$DvmAdminFrontendFr.routeToPage(form.input.managerID, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  var tmp;
  if (shouldDisplayError(undefined)) {
    tmp = React.createElement(App_Typography$DvmAdminFrontendFr.make, {
          color: "secondary",
          children: intl.formatMessage(Messages_Contract_Manager_Edit$DvmAdminFrontendFr.notFoundError),
          variant: "h3"
        });
  } else {
    var tmp$1 = {
      onChange: Curry._1(form.updateManagerID, (function (input, managerID) {
              return {
                      managerID: managerID,
                      managerName: input.managerName,
                      budgetRows: input.budgetRows
                    };
            })),
      value: form.input.managerID,
      disabled: Belt_Option.mapWithDefault(manager, false, (function (param) {
              return true;
            })),
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
      label: Messages_Contr_Man_Search$DvmAdminFrontendFr.managerIPN,
      required: /* OnlyLabel */1
    };
    if (form.managerIDResult !== undefined) {
      tmp$1.validationResult = Caml_option.valFromOption(form.managerIDResult);
    }
    var tmp$2 = {
      onChange: Curry._1(form.updateManagerName, (function (input, managerName) {
              return {
                      managerID: input.managerID,
                      managerName: managerName,
                      budgetRows: input.budgetRows
                    };
            })),
      value: form.input.managerName,
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
      label: Messages_Contr_Man_Search$DvmAdminFrontendFr.managerName,
      required: /* OnlyLabel */1
    };
    if (form.managerNameResult !== undefined) {
      tmp$2.validationResult = Caml_option.valFromOption(form.managerNameResult);
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "row",
                  justifyContent: "center",
                  spacing: 2
                }, React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      item: true,
                      justifyContent: "center",
                      spacing: 3
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$1),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$2),
                          item: true
                        })), React.createElement(Core.Grid, {
                      children: React.createElement(Contract_Manager_Edit_Table$DvmAdminFrontendFr.make, {
                            fetchRequest: fetchRequest,
                            form: form
                          }),
                      item: true,
                      sm: Grid$Mui.Sm[12]
                    }), React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      item: true,
                      justifyContent: "space-between"
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                onClick: (function (param) {
                                    RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rView + Route_Paths$DvmAdminFrontendFr.rContractManager);
                                  }),
                                label: intl.formatMessage(Messages_Contract_Manager_Edit$DvmAdminFrontendFr.backButton),
                                color: "primaryBorder",
                                size: "medium"
                              }),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                onClick: (function (param) {
                                    resetForm(undefined);
                                  }),
                                label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.reset),
                                color: "primaryBorder",
                                size: "medium"
                              }),
                          item: true
                        }), React.createElement(Core.Badge, {
                          anchorOrigin: {
                            horizontal: "right",
                            vertical: "top"
                          },
                          badgeContent: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.unsavedChanges),
                          children: React.createElement(Core.Grid, {
                                children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                      label: intl.formatMessage(Belt_Option.mapWithDefault(managerId, Messages_Contract_Manager_Edit$DvmAdminFrontendFr.createManagerButton, (function (param) {
                                                  return Messages_Contract_Manager_Edit$DvmAdminFrontendFr.editManagerButton;
                                                }))),
                                      color: "secondary",
                                      size: "medium",
                                      type_: "submit"
                                    }),
                                item: true
                              }),
                          className: badge,
                          color: "secondary",
                          invisible: !Contract_Manager_Edit_Utils$DvmAdminFrontendFr.hasFormChanged(manager, form, match$5[0]) || !Belt_Option.isSome(managerId)
                        })))));
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  color: "textPrimary",
                  children: intl.formatMessage(Belt_Option.mapWithDefault(managerId, Messages_Contract_Manager_Edit$DvmAdminFrontendFr.mainTitleCreate, (function (param) {
                              return Messages_Contract_Manager_Edit$DvmAdminFrontendFr.mainTitleModify;
                            }))),
                  variant: "h3"
                }), React.createElement(Core.Container, {
                  children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                        show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult) || App_Types_Result$DvmAdminFrontendFr.isPending(fetchedManager),
                        children: tmp
                      }),
                  className: boxForm,
                  maxWidth: Container$Mui.MaxWidth.md
                }));
}

var Form;

var Api;

var Types;

var ApiSearch;

var Table;

var UtilsEdit;

var InputStyles;

var make = Contract_Manager_Edit_Page;

export {
  Form ,
  Api ,
  Types ,
  ApiSearch ,
  Table ,
  UtilsEdit ,
  InputStyles ,
  boxForm ,
  badge ,
  make ,
}
/* boxForm Not a pure module */
