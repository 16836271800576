// Generated by ReScript, PLEASE EDIT WITH CARE


var menuTitle = {
  id: "e7fa7791-bbfa-4d3d-87ca-8b728007e569",
  defaultMessage: "Configuration du référentiel"
};

var title = {
  id: "16daaa2f-2706-4737-aad7-905810b0fca5",
  defaultMessage: "Définition des critères de paramétrage du référentiel des VMEs"
};

var addNewLine = {
  id: "865bd34f-150f-4057-af32-bd0d485d59b3",
  defaultMessage: "Paramétrer une nouvelle valeur"
};

var deleteTitle = {
  id: "5ae3721e-44ba-419b-822a-4d7520194f0d",
  defaultMessage: "Supprimer une valeur"
};

var deleteMessage = {
  id: "9943317c-330d-451f-be82-daabe7cd0bff",
  defaultMessage: "Do you want to delete value {value}?"
};

var deleteWarning = {
  id: "ae23ba01-de39-4d40-ae70-56ce28ca16ed",
  defaultMessage: "This will delete all action referentials using this value!"
};

export {
  menuTitle ,
  title ,
  addNewLine ,
  deleteTitle ,
  deleteMessage ,
  deleteWarning ,
}
/* No side effect */
