// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Constants$DvmAdminFrontendFr from "../Constants.bs.js";

function isHtmlRequired(required) {
  if (required) {
    return false;
  } else {
    return true;
  }
}

function requiredLabel(intl, label) {
  return intl.formatMessage(label).concat(Constants$DvmAdminFrontendFr.requiredLabelSuffix);
}

export {
  isHtmlRequired ,
  requiredLabel ,
}
/* No side effect */
