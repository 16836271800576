// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../../../common/components/icons/App_Icon.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../../common/utils/Utils_Alert.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../common/App_Typography.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Create_Api$DvmAdminFrontendFr from "./Order_Detail_Bonus_DealerRequest_Create_Api.bs.js";

function Order_Detail_Bonus_DealerRequest_Document_Row_Saved(Props) {
  var setExistingDocuments = Props.setExistingDocuments;
  var index = Props.index;
  var item = Props.item;
  var orderId = Props.orderId;
  var actionId = Props.actionId;
  var documentTypes = Props.documentTypes;
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDocumentDeleteResult = match$2[1];
  var documentDeleteResult = match$2[0];
  React.useEffect((function () {
          if (typeof documentDeleteResult === "number") {
            documentDeleteResult === /* NotStarted */0;
          } else if (documentDeleteResult.TAG === /* Ok */0) {
            Curry._1(setExistingDocuments, (function (documents) {
                    return Belt_List.keepWithIndex(documents, (function (param, ind) {
                                  return ind !== index;
                                }));
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: documentDeleteResult._0
                      }
                    }));
          }
        }), [documentDeleteResult]);
  return React.createElement(Core.Grid, {
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(documentDeleteResult),
                    children: React.createElement(Core.Grid, {
                          alignItems: "center",
                          children: null,
                          container: true,
                          item: true,
                          justifyContent: "space-between",
                          spacing: 2,
                          key: String(index)
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                    children: Belt_Option.getWithDefault(item.fileName, ""),
                                    variant: "body"
                                  }),
                              item: true,
                              xs: Grid$Mui.Xs[3]
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                    children: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(documentTypes, "-", (function (docTypes) {
                                            return Belt_Option.mapWithDefault(Belt_List.getBy(docTypes, (function (x) {
                                                              return x.fileType === Belt_Option.getWithDefault(item.fileType, "-");
                                                            })), "-", (function (x) {
                                                          return x.fileLabel;
                                                        }));
                                          })),
                                    variant: "body"
                                  }),
                              item: true,
                              xs: Grid$Mui.Xs[3]
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                    children: Belt_Option.getWithDefault(item.comment, ""),
                                    variant: "body"
                                  }),
                              item: true,
                              xs: Grid$Mui.Xs[5]
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(Core.IconButton, {
                                    onClick: (function (param) {
                                        Order_Detail_Bonus_DealerRequest_Create_Api$DvmAdminFrontendFr.deleteFile(userManager, item, selectedRole, orderId, actionId, setDocumentDeleteResult);
                                      }),
                                    children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                          size: /* Large */3,
                                          type_: /* Trash */38,
                                          color: Theme_Colors$DvmAdminFrontendFr.accentRed
                                        })
                                  }),
                              item: true,
                              xs: Grid$Mui.Xs[1]
                            }))
                  }),
              item: true
            });
}

var Api;

var make = Order_Detail_Bonus_DealerRequest_Document_Row_Saved;

export {
  Api ,
  make ,
}
/* react Not a pure module */
