// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

var Fields = {
  agentCode: "agentCode",
  dealerName: "dealerName",
  city: "city",
  zipCode: "zipCode"
};

function agent_encode(v) {
  return Js_dict.fromArray([
              [
                "agentCode",
                Decco.stringToJson(v.agentCode)
              ],
              [
                "dealerName",
                Decco.optionToJson(Decco.stringToJson, v.dealerName)
              ],
              [
                "city",
                Decco.optionToJson(Decco.stringToJson, v.city)
              ],
              [
                "zipCode",
                Decco.optionToJson(Decco.stringToJson, v.zipCode)
              ]
            ]);
}

function agent_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var agentCode = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "agentCode"), null));
  if (agentCode.TAG === /* Ok */0) {
    var dealerName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerName"), null));
    if (dealerName.TAG === /* Ok */0) {
      var city = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "city"), null));
      if (city.TAG === /* Ok */0) {
        var zipCode = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "zipCode"), null));
        if (zipCode.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    agentCode: agentCode._0,
                    dealerName: dealerName._0,
                    city: city._0,
                    zipCode: zipCode._0
                  }
                };
        }
        var e = zipCode._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".zipCode" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = city._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".city" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = dealerName._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".dealerName" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = agentCode._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".agentCode" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(agent_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(agent_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var Agent = {
  agent_encode: agent_encode,
  agent_decode: agent_decode,
  searchResult_encode: searchResult_encode,
  searchResult_decode: searchResult_decode
};

function dealerDetail_encode(v) {
  return Js_dict.fromArray([
              [
                "dr",
                Decco.stringToJson(v.dr)
              ],
              [
                "dealerID",
                Decco.stringToJson(v.dealerID)
              ],
              [
                "dealerName",
                Decco.optionToJson(Decco.stringToJson, v.dealerName)
              ],
              [
                "entityLabel",
                Decco.optionToJson(Decco.stringToJson, v.entityLabel)
              ],
              [
                "dc",
                Decco.optionToJson(Decco.stringToJson, v.dc)
              ],
              [
                "nameDr",
                Decco.optionToJson(Decco.stringToJson, v.nameDr)
              ],
              [
                "entityCode",
                Decco.optionToJson(Decco.stringToJson, v.entityCode)
              ],
              [
                "alcorID",
                Decco.optionToJson(Decco.stringToJson, v.alcorID)
              ],
              [
                "keyAccountManagerIpn",
                Decco.optionToJson(Decco.stringToJson, v.keyAccountManagerIpn)
              ],
              [
                "keyAccountManagerName",
                Decco.optionToJson(Decco.stringToJson, v.keyAccountManagerName)
              ],
              [
                "nameR1",
                Decco.optionToJson(Decco.stringToJson, v.nameR1)
              ],
              [
                "nameR2",
                Decco.optionToJson(Decco.stringToJson, v.nameR2)
              ],
              [
                "city",
                Decco.optionToJson(Decco.stringToJson, v.city)
              ],
              [
                "zipCode",
                Decco.optionToJson(Decco.stringToJson, v.zipCode)
              ],
              [
                "accountingID",
                Decco.optionToJson(Decco.stringToJson, v.accountingID)
              ],
              [
                "agents",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(agent_encode, param);
                      }), v.agents)
              ]
            ]);
}

function dealerDetail_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var dr = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "dr"), null));
  if (dr.TAG === /* Ok */0) {
    var dealerID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerID"), null));
    if (dealerID.TAG === /* Ok */0) {
      var dealerName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerName"), null));
      if (dealerName.TAG === /* Ok */0) {
        var entityLabel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "entityLabel"), null));
        if (entityLabel.TAG === /* Ok */0) {
          var dc = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "dc"), null));
          if (dc.TAG === /* Ok */0) {
            var nameDr = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "nameDr"), null));
            if (nameDr.TAG === /* Ok */0) {
              var entityCode = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "entityCode"), null));
              if (entityCode.TAG === /* Ok */0) {
                var alcorID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "alcorID"), null));
                if (alcorID.TAG === /* Ok */0) {
                  var keyAccountManagerIpn = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "keyAccountManagerIpn"), null));
                  if (keyAccountManagerIpn.TAG === /* Ok */0) {
                    var keyAccountManagerName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "keyAccountManagerName"), null));
                    if (keyAccountManagerName.TAG === /* Ok */0) {
                      var nameR1 = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "nameR1"), null));
                      if (nameR1.TAG === /* Ok */0) {
                        var nameR2 = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "nameR2"), null));
                        if (nameR2.TAG === /* Ok */0) {
                          var city = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "city"), null));
                          if (city.TAG === /* Ok */0) {
                            var zipCode = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "zipCode"), null));
                            if (zipCode.TAG === /* Ok */0) {
                              var accountingID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "accountingID"), null));
                              if (accountingID.TAG === /* Ok */0) {
                                var agents = Decco.optionFromJson((function (param) {
                                        return Decco.listFromJson(agent_decode, param);
                                      }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "agents"), null));
                                if (agents.TAG === /* Ok */0) {
                                  return {
                                          TAG: /* Ok */0,
                                          _0: {
                                            dr: dr._0,
                                            dealerID: dealerID._0,
                                            dealerName: dealerName._0,
                                            entityLabel: entityLabel._0,
                                            dc: dc._0,
                                            nameDr: nameDr._0,
                                            entityCode: entityCode._0,
                                            alcorID: alcorID._0,
                                            keyAccountManagerIpn: keyAccountManagerIpn._0,
                                            keyAccountManagerName: keyAccountManagerName._0,
                                            nameR1: nameR1._0,
                                            nameR2: nameR2._0,
                                            city: city._0,
                                            zipCode: zipCode._0,
                                            accountingID: accountingID._0,
                                            agents: agents._0
                                          }
                                        };
                                }
                                var e = agents._0;
                                return {
                                        TAG: /* Error */1,
                                        _0: {
                                          path: ".agents" + e.path,
                                          message: e.message,
                                          value: e.value
                                        }
                                      };
                              }
                              var e$1 = accountingID._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".accountingID" + e$1.path,
                                        message: e$1.message,
                                        value: e$1.value
                                      }
                                    };
                            }
                            var e$2 = zipCode._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".zipCode" + e$2.path,
                                      message: e$2.message,
                                      value: e$2.value
                                    }
                                  };
                          }
                          var e$3 = city._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".city" + e$3.path,
                                    message: e$3.message,
                                    value: e$3.value
                                  }
                                };
                        }
                        var e$4 = nameR2._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".nameR2" + e$4.path,
                                  message: e$4.message,
                                  value: e$4.value
                                }
                              };
                      }
                      var e$5 = nameR1._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".nameR1" + e$5.path,
                                message: e$5.message,
                                value: e$5.value
                              }
                            };
                    }
                    var e$6 = keyAccountManagerName._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".keyAccountManagerName" + e$6.path,
                              message: e$6.message,
                              value: e$6.value
                            }
                          };
                  }
                  var e$7 = keyAccountManagerIpn._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".keyAccountManagerIpn" + e$7.path,
                            message: e$7.message,
                            value: e$7.value
                          }
                        };
                }
                var e$8 = alcorID._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".alcorID" + e$8.path,
                          message: e$8.message,
                          value: e$8.value
                        }
                      };
              }
              var e$9 = entityCode._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".entityCode" + e$9.path,
                        message: e$9.message,
                        value: e$9.value
                      }
                    };
            }
            var e$10 = nameDr._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".nameDr" + e$10.path,
                      message: e$10.message,
                      value: e$10.value
                    }
                  };
          }
          var e$11 = dc._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".dc" + e$11.path,
                    message: e$11.message,
                    value: e$11.value
                  }
                };
        }
        var e$12 = entityLabel._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".entityLabel" + e$12.path,
                  message: e$12.message,
                  value: e$12.value
                }
              };
      }
      var e$13 = dealerName._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".dealerName" + e$13.path,
                message: e$13.message,
                value: e$13.value
              }
            };
    }
    var e$14 = dealerID._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".dealerID" + e$14.path,
              message: e$14.message,
              value: e$14.value
            }
          };
  }
  var e$15 = dr._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".dr" + e$15.path,
            message: e$15.message,
            value: e$15.value
          }
        };
}

export {
  Fields ,
  Agent ,
  dealerDetail_encode ,
  dealerDetail_decode ,
}
/* No side effect */
