// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../common/utils/Utils_Date.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../common/types/App_Types_Result.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../context/user/User_Types_Utils.bs.js";
import * as Bonuses_Search_Api$DvmAdminFrontendFr from "./Bonuses_Search_Api.bs.js";

function updateNonFleetActionsAndModels(actionTypesNonFleet, operation, form, alert, userManager, setActionsNonFleet, setModelVersionsNonFleet, selectedRole) {
  if (typeof actionTypesNonFleet === "number") {
    return ;
  }
  if (actionTypesNonFleet.TAG !== /* Ok */0) {
    return ;
  }
  if (!(operation !== /* Manage */0 && !Belt_List.has(form.input.orderType, "SO", (function (a, b) {
              return a === b;
            })))) {
    return ;
  }
  var actionTypes = form.input.actionType.length > 0 ? form.input.actionType : Belt_Array.joinWith(Belt_Array.keep(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.map(Belt_List.toArray(actionTypesNonFleet._0), (function (x) {
                            return x.toUpperCase();
                          })))), (function (x) {
                return x.length > 0;
              })), "%5C,", (function (x) {
            return x;
          }));
  Bonuses_Search_Api$DvmAdminFrontendFr.getActions(alert, userManager, setActionsNonFleet, selectedRole, actionTypes, Utils_Date$DvmAdminFrontendFr.toYearString(form.input.budgetYear), form.input.showBonusesPlusHistory, "nonfleet", undefined);
  Bonuses_Search_Api$DvmAdminFrontendFr.fetchModelVersionsFiltered(setModelVersionsNonFleet, userManager, selectedRole, Utils_Date$DvmAdminFrontendFr.toYearString(form.input.budgetYear), form.input.showBonusesPlusHistory, actionTypes, "nonfleet");
}

function handleActionTypesList(form, actionTypesFleet, actionTypesNonFleet, selectedRole) {
  var actionsFleet = Belt_List.has(form.input.orderType, "SO", (function (a, b) {
          return a === b;
        })) ? App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionTypesFleet, /* [] */0) : /* [] */0;
  var actionsNonFleet = Belt_Option.isSome(Belt_List.getBy(form.input.orderType, (function (x) {
              switch (x) {
                case "CL" :
                case "PG" :
                case "RC" :
                case "RR" :
                case "VD" :
                case "VE" :
                    return true;
                default:
                  return false;
              }
            }))) ? App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionTypesNonFleet, /* [] */0) : /* [] */0;
  var actions = Belt_List.keep(Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(Belt_List.length(form.input.orderType) > 0 ? Belt_List.concat(actionsFleet, actionsNonFleet) : (
                          User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? Belt_List.concat(App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionTypesFleet, /* [] */0), App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionTypesNonFleet, /* [] */0)) : App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionTypesNonFleet, /* [] */0)
                        ), (function (x) {
                          return x.toUpperCase();
                        }))))), (function (x) {
          return x.length > 0;
        }));
  if (Belt_List.has(form.input.orderType, "SO", (function (a, b) {
            return a === b;
          })) || Belt_List.length(form.input.orderType) === 0 && User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0) {
    return Belt_List.add(actions, "Budget");
  } else {
    return actions;
  }
}

function handleActionsDropdownList(form, actionsFleet, actionsNonFleet) {
  var actionsFleetCleaned = Belt_List.has(form.input.orderType, "SO", (function (a, b) {
          return a === b;
        })) ? App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionsFleet, /* [] */0) : /* [] */0;
  var actionsNonFleetCleaned = Belt_Option.isSome(Belt_List.getBy(form.input.orderType, (function (x) {
              switch (x) {
                case "CL" :
                case "PG" :
                case "RC" :
                case "RR" :
                case "VD" :
                case "VE" :
                    return true;
                default:
                  return false;
              }
            }))) ? App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionsNonFleet, /* [] */0) : /* [] */0;
  return Belt_List.map(Belt_List.length(form.input.orderType) > 0 ? Belt_List.concat(actionsFleetCleaned, actionsNonFleetCleaned) : Belt_List.concat(App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionsFleet, /* [] */0), App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionsNonFleet, /* [] */0)), (function (x) {
                return {
                        label: {
                          TAG: /* String */3,
                          _0: "" + x.paramOne + " (" + Belt_Option.getWithDefault(x.paramTwo, "") + ")"
                        },
                        value: x.paramOne
                      };
              }));
}

function handleModelVersionsList(form, modelVersionsFleet, modelVersionsNonFleet) {
  var modelVersionsFleetCleaned = Belt_List.has(form.input.orderType, "SO", (function (a, b) {
          return a === b;
        })) ? App_Types_Result$DvmAdminFrontendFr.getWithDefault(modelVersionsFleet, /* [] */0) : /* [] */0;
  var modelVersionsNonFleetCleaned = Belt_Option.isSome(Belt_List.getBy(form.input.orderType, (function (x) {
              switch (x) {
                case "CL" :
                case "PG" :
                case "RC" :
                case "RR" :
                case "VD" :
                case "VE" :
                    return true;
                default:
                  return false;
              }
            }))) ? App_Types_Result$DvmAdminFrontendFr.getWithDefault(modelVersionsNonFleet, /* [] */0) : /* [] */0;
  if (Belt_List.length(form.input.orderType) > 0) {
    return Belt_List.concat(modelVersionsFleetCleaned, modelVersionsNonFleetCleaned);
  }
  var nonFleetList = App_Types_Result$DvmAdminFrontendFr.getWithDefault(modelVersionsNonFleet, /* [] */0);
  return Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(modelVersionsFleet, /* [] */0), (function (fleetItem) {
                return Belt_Option.mapWithDefault(Belt_List.getBy(nonFleetList, (function (nonFleetItem) {
                                  return fleetItem.parent === nonFleetItem.parent;
                                })), fleetItem, (function (nonfleetItem) {
                              return {
                                      parent: fleetItem.parent,
                                      child: Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.concat(fleetItem.child, nonfleetItem.child))))
                                    };
                            }));
              }));
}

var Form;

var Api;

var Types;

export {
  Form ,
  Api ,
  Types ,
  updateNonFleetActionsAndModels ,
  handleActionTypesList ,
  handleActionsDropdownList ,
  handleModelVersionsList ,
}
/* Utils_Date-DvmAdminFrontendFr Not a pure module */
