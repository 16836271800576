// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as Margin_Grid_Duplicate_Types$DvmAdminFrontendFr from "./Margin_Grid_Duplicate_Types.bs.js";

function yearToCombinations(date, budgetOrID) {
  var year = String(ReDate.getYear(date) | 0);
  var start = "" + year + "-01-01T00:00:00.000Z";
  var end = "" + year + "-12-31T00:00:00.000Z";
  return encodeURIComponent(Belt_Array.joinWith([
                  "avsMarginGridID=" + budgetOrID + "&&startDate=[\\," + start + "]&&endDate=[" + start + "\\,]",
                  "avsMarginGridID=" + budgetOrID + "&&startDate=[\\," + end + "]&&endDate=[" + end + "\\,]",
                  "avsMarginGridID=" + budgetOrID + "&&startDate=[" + start + "\\,]&&endDate=[\\," + end + "]",
                  "avsMarginGridID=" + budgetOrID + "&&startDate=[\\," + start + "]&&endDate=[" + end + "\\,]"
                ], "||", (function (x) {
                    return x;
                  })));
}

function getAvailableCategories(availableCategoriesResult) {
  return Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(App_Types_Result$DvmAdminFrontendFr.mapWithDefault(availableCategoriesResult, /* [] */0, (function (x) {
                            return Belt_List.map(x.content, (function (row) {
                                          return Belt_Option.getWithDefault(row.categoryID, "");
                                        }));
                          })))));
}

function getAvailableDiscounts(filterResult, categoryID) {
  return App_Types_Result$DvmAdminFrontendFr.mapWithDefault(filterResult, /* [] */0, (function (categories) {
                return Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(Belt_List.keep(categories.content, (function (category) {
                                              return Belt_Option.mapWithDefault(category.categoryID, false, (function (x) {
                                                            return x === categoryID;
                                                          }));
                                            })), (function (x) {
                                          return String(x.discount);
                                        })))));
              }));
}

function getDistinctPeriods(intl, list) {
  return Belt_List.map(Belt_MapString.toList(Belt_MapString.fromArray(Belt_List.toArray(Belt_List.keepMap(list, (function (row) {
                                return Belt_Option.mapWithDefault(row.endDate, undefined, (function (endDate) {
                                              var startDateIntlStr = Intl_Utils$DvmAdminFrontendFr.formatDate(intl, row.startDate);
                                              var endDateIntlStr = Belt_Option.mapWithDefault(row.endDate, "", (function (endDate) {
                                                      return " - " + Intl_Utils$DvmAdminFrontendFr.formatDate(intl, endDate);
                                                    }));
                                              var searchString = "startDate=" + row.startDate.toISOString() + Belt_Option.mapWithDefault(row.endDate, "", (function (endDate) {
                                                      return ";endDate=" + endDate.toISOString();
                                                    }));
                                              return [
                                                      searchString,
                                                      {
                                                        startDate: row.startDate,
                                                        endDate: endDate,
                                                        searchString: searchString,
                                                        labeledValues: {
                                                          label: {
                                                            TAG: /* String */3,
                                                            _0: startDateIntlStr + endDateIntlStr
                                                          },
                                                          value: searchString
                                                        }
                                                      }
                                                    ];
                                            }));
                              }))))), (function (param) {
                return param[1];
              }));
}

function toLabelValueRecord(categoryId, discount, margin) {
  return {
          label: {
            TAG: /* String */3,
            _0: "" + categoryId + " (remise " + String(discount) + " %" + Belt_Option.mapWithDefault(margin, "", (function (x) {
                    return " / marge " + String(x) + " %";
                  })) + ")"
          },
          value: categoryId + Margin_Grid_Duplicate_Types$DvmAdminFrontendFr.delimiter + String(discount)
        };
}

function getLabeledCategoriesForDiscount(categories, discount) {
  return Belt_List.keepMap(Belt_List.keep(categories, (function (x) {
                    if (discount.length > 0) {
                      return String(x.discount) === discount;
                    } else {
                      return true;
                    }
                  })), (function (x) {
                return Belt_Option.mapWithDefault(x.categoryID, undefined, (function (categoryId) {
                              return toLabelValueRecord(categoryId, x.discount, x.margin);
                            }));
              }));
}

var Types;

export {
  Types ,
  yearToCombinations ,
  getAvailableCategories ,
  getAvailableDiscounts ,
  getDistinctPeriods ,
  toLabelValueRecord ,
  getLabeledCategoriesForDiscount ,
}
/* Margin_Grid_Duplicate_Types-DvmAdminFrontendFr Not a pure module */
