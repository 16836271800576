// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Belt_SetInt from "rescript/lib/es6/belt_SetInt.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../../common/components/icons/App_Icon.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as Contract_Edit_Api$DvmAdminFrontendFr from "../../Contract_Edit_Api.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../common/components/App_OverlaySpinner.bs.js";
import * as App_CompanyFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_CompanyFormInput.bs.js";
import * as App_UserTypeCheckBox$DvmAdminFrontendFr from "../../../../../common/components/input/App_UserTypeCheckBox.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../../common/components/input/App_DateInputFormField.bs.js";
import * as Messages_Contract_Create$DvmAdminFrontendFr from "../../../../../intl/messages/contract/Messages_Contract_Create.bs.js";

var longInputLocal = Css.css({
      width: "400px"
    });

function Contract_Attachment_Add(Props) {
  var attachment = Props.attachment;
  var form = Props.form;
  var at = Props.at;
  var sirenNamesMap = Props.sirenNamesMap;
  var sirenNameResult = Props.sirenNameResult;
  var setAttachmentErrors = Props.setAttachmentErrors;
  var setSirenCheckPending = Props.setSirenCheckPending;
  var contract = Props.contract;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSirenName = match$3[1];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setCheckSiren = match$4[1];
  var checkSiren = match$4[0];
  React.useEffect((function () {
          if (attachment.siren.length > 0) {
            Belt_Option.mapWithDefault(Belt_MapString.get(sirenNamesMap, attachment.siren), Curry._1(setSirenName, (function (param) {
                        if (typeof sirenNameResult === "number") {
                          if (sirenNameResult === /* NotStarted */0) {
                            return /* NotStarted */0;
                          } else {
                            return /* Pending */1;
                          }
                        } else if (sirenNameResult.TAG === /* Ok */0) {
                          return {
                                  TAG: /* Ok */0,
                                  _0: sirenNameResult._0[1]
                                };
                        } else {
                          return {
                                  TAG: /* Error */1,
                                  _0: sirenNameResult._0
                                };
                        }
                      })), (function (x) {
                    Curry._1(setSirenName, (function (param) {
                            return {
                                    TAG: /* Ok */0,
                                    _0: x
                                  };
                          }));
                  }));
          } else {
            Curry._1(setSirenName, (function (param) {
                    return /* NotStarted */0;
                  }));
          }
        }), [sirenNameResult]);
  React.useEffect((function () {
          var start = Curry._1(form.attachmentStartDateResult, at);
          if (start !== undefined && attachment.siren.length > 0 && Belt_Result.isOk(start) && (
              attachment.endDateAsContract ? true : Belt_Option.mapWithDefault(Curry._1(form.attachmentEndDateResult, at), false, Belt_Result.isOk)
            )) {
            Contract_Edit_Api$DvmAdminFrontendFr.checkIfCanAddSiren(attachment.siren, attachment.startDate, attachment.endDateAsContract ? contract.endDate : attachment.endDate, setCheckSiren, userManager, contract.contractID, selectedRole, "add", undefined);
          }
          if (attachment.siren.length === 0) {
            Curry._1(setAttachmentErrors, (function (attachmentErrors) {
                    return Belt_SetInt.remove(attachmentErrors, at);
                  }));
          }
          
        }), [attachment]);
  React.useEffect((function () {
          if (typeof checkSiren === "number") {
            if (checkSiren !== /* NotStarted */0) {
              Curry._1(setSirenCheckPending, (function (param) {
                      return true;
                    }));
            }
            
          } else if (checkSiren.TAG === /* Ok */0) {
            Curry._1(setSirenCheckPending, (function (param) {
                    return false;
                  }));
            Curry._1(setAttachmentErrors, (function (attachmentErrors) {
                    return Belt_SetInt.remove(attachmentErrors, at);
                  }));
          } else {
            Curry._1(setSirenCheckPending, (function (param) {
                    return false;
                  }));
            Curry._1(setAttachmentErrors, (function (attachmentErrors) {
                    return Belt_SetInt.add(attachmentErrors, at);
                  }));
          }
        }), [checkSiren]);
  var tmp = {
    onChange: Curry._2(form.updateAttachmentSiren, at, (function (param, siren) {
            return {
                    attachments: Belt_Array.mapWithIndex(param.attachments, (function (index, attachment) {
                            if (index === at) {
                              return {
                                      siren: siren,
                                      startDate: attachment.startDate,
                                      endDate: attachment.endDate,
                                      endDateAsContract: attachment.endDateAsContract
                                    };
                            } else {
                              return attachment;
                            }
                          }))
                  };
          })),
    value: attachment.siren,
    companyName: match$3[0],
    setCompanyName: setSirenName,
    companies: match$2[0],
    setCompanies: match$2[1],
    className: longInputLocal,
    companyType: "siren"
  };
  var tmp$1 = Curry._1(form.attachmentSirenResult, at);
  if (tmp$1 !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: attachment.startDate,
    onChange: Curry._2(form.updateAttachmentStartDate, at, (function (param, startDate) {
            return {
                    attachments: Belt_Array.mapWithIndex(param.attachments, (function (index, attachment) {
                            if (index === at) {
                              return {
                                      siren: attachment.siren,
                                      startDate: startDate,
                                      endDate: attachment.endDate,
                                      endDateAsContract: attachment.endDateAsContract
                                    };
                            } else {
                              return attachment;
                            }
                          }))
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
    onBlur: (function (param) {
        Curry._1(form.blurAttachmentStartDate, at);
      })
  };
  var tmp$3 = Curry._1(form.attachmentStartDateResult, at);
  if (tmp$3 !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(tmp$3);
  }
  var tmp$4 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: attachment.endDate,
    onChange: Curry._2(form.updateAttachmentEndDate, at, (function (param, endDate) {
            return {
                    attachments: Belt_Array.mapWithIndex(param.attachments, (function (index, attachment) {
                            if (index === at) {
                              return {
                                      siren: attachment.siren,
                                      startDate: attachment.startDate,
                                      endDate: endDate,
                                      endDateAsContract: attachment.endDateAsContract
                                    };
                            } else {
                              return attachment;
                            }
                          }))
                  };
          })),
    disabled: attachment.endDateAsContract,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
    onBlur: (function (param) {
        Curry._1(form.blurAttachmentEndDate, at);
      })
  };
  var tmp$5 = Curry._1(form.attachmentEndDateResult, at);
  if (tmp$5 !== undefined) {
    tmp$4.validationResult = Caml_option.valFromOption(tmp$5);
  }
  var tmp$6;
  if (typeof checkSiren === "number" || !(checkSiren.TAG === /* Error */1 && attachment.siren.length > 0)) {
    tmp$6 = null;
  } else {
    var match$5 = App_Types_Result$DvmAdminFrontendFr.Rest.toMessage(checkSiren._0);
    tmp$6 = React.createElement(Core.Grid, {
          children: React.createElement(Core.Tooltip, {
                arrow: true,
                children: React.createElement("div", undefined, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                          size: /* Large */3,
                          type_: /* HelpWarning */0,
                          color: "red"
                        })),
                classes: {
                  tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
                },
                placement: "left-start",
                title: intl.formatMessage(match$5[0])
              }),
          item: true
        });
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                  show: App_Types_Result$DvmAdminFrontendFr.isPending(checkSiren),
                  children: React.createElement(Core.Grid, {
                        alignItems: "flex-start",
                        children: null,
                        container: true,
                        wrap: "nowrap",
                        style: {
                          paddingBottom: "10px",
                          gridColumnGap: "24px"
                        }
                      }, React.createElement(Core.Grid, {
                            children: React.createElement(App_CompanyFormInput$DvmAdminFrontendFr.make, tmp),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$2),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$4),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(App_UserTypeCheckBox$DvmAdminFrontendFr.make, {
                                  label: intl.formatMessage(Messages_Contract_Create$DvmAdminFrontendFr.asContract),
                                  checked: attachment.endDateAsContract,
                                  onClick: (function (param) {
                                      Curry._3(form.updateAttachmentEndDateAsContract, at, (function (param, endDateAsContract) {
                                              return {
                                                      attachments: Belt_Array.mapWithIndex(param.attachments, (function (index, attachment) {
                                                              if (index === at) {
                                                                return {
                                                                        siren: attachment.siren,
                                                                        startDate: attachment.startDate,
                                                                        endDate: attachment.endDate,
                                                                        endDateAsContract: endDateAsContract
                                                                      };
                                                              } else {
                                                                return attachment;
                                                              }
                                                            }))
                                                    };
                                            }), !attachment.endDateAsContract);
                                    })
                                }),
                            item: true
                          }), tmp$6)
                }));
}

var Form;

var Api;

var InputStyles;

var make = Contract_Attachment_Add;

export {
  Form ,
  Api ,
  InputStyles ,
  longInputLocal ,
  make ,
}
/* longInputLocal Not a pure module */
