// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Form.bs.js";

var validators_policeNumber = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: param.policeNumber
            };
    })
};

var validators_registrationDateUsedCar = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      if (param$1.onlyPolice) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      var value = Utils_Form$DvmAdminFrontendFr.validateDate(param.registrationDateUsedCar, undefined);
      if (value.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: value._0
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: value._0
              };
      }
    })
};

var validators_firstReleaseDateCar = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      if (param$1.onlyPolice) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      var value = Utils_Form$DvmAdminFrontendFr.validateDate(param.firstReleaseDateCar, undefined);
      if (value.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: value._0
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: value._0
              };
      }
    })
};

var validators_vinUsedCar = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      if (param$1.onlyPolice) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: param.vinUsedCar
              };
      }
    })
};

var validators_registrationNumberUsedCar = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      if (param$1.onlyPolice) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: param.registrationNumberUsedCar
              };
      }
    })
};

var validators_versionUsedCar = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      if (param$1.onlyPolice) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: param.versionUsedCar
              };
      }
    })
};

var validators_modelUsedCar = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      if (param$1.onlyPolice) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: param.modelUsedCar
              };
      }
    })
};

var validators_brandUsedCar = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      if (param$1.onlyPolice) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: param.brandUsedCar
              };
      }
    })
};

var validators_endOfLife = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      if (param$1.onlyPolice || param$1.orderType === /* SO */0) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      var tmp;
      switch (param.endOfLife) {
        case "false" :
            tmp = false;
            break;
        case "true" :
            tmp = true;
            break;
        default:
          tmp = undefined;
      }
      return {
              TAG: /* Ok */0,
              _0: tmp
            };
    })
};

var validators_estimatedValue = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      if (param$1.onlyPolice || param$1.orderType === /* SO */0) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      var ok = Utils_Form$DvmAdminFrontendFr.validateFloatPositive(param.estimatedValue);
      if (ok.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: Belt_Option.getWithDefault(ok._0, 0)
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: ok._0
              };
      }
    })
};

var validators_value = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      if (param$1.onlyPolice || param$1.orderType === /* SO */0) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      var ok = Utils_Form$DvmAdminFrontendFr.validateFloatPositive(param.value);
      if (ok.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: Belt_Option.getWithDefault(ok._0, 0)
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: ok._0
              };
      }
    })
};

var validators = {
  policeNumber: validators_policeNumber,
  registrationDateUsedCar: validators_registrationDateUsedCar,
  firstReleaseDateCar: validators_firstReleaseDateCar,
  vinUsedCar: validators_vinUsedCar,
  registrationNumberUsedCar: validators_registrationNumberUsedCar,
  versionUsedCar: validators_versionUsedCar,
  modelUsedCar: validators_modelUsedCar,
  brandUsedCar: validators_brandUsedCar,
  endOfLife: validators_endOfLife,
  estimatedValue: validators_estimatedValue,
  value: validators_value
};

function initialFieldsStatuses(_input) {
  return {
          policeNumber: /* Pristine */0,
          registrationDateUsedCar: /* Pristine */0,
          firstReleaseDateCar: /* Pristine */0,
          vinUsedCar: /* Pristine */0,
          registrationNumberUsedCar: /* Pristine */0,
          versionUsedCar: /* Pristine */0,
          modelUsedCar: /* Pristine */0,
          brandUsedCar: /* Pristine */0,
          endOfLife: /* Pristine */0,
          estimatedValue: /* Pristine */0,
          value: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match = fieldsStatuses.policeNumber;
  var match_0 = match ? match._0 : Curry._2(validators.policeNumber.validate, input, metadata);
  var match$1 = fieldsStatuses.registrationDateUsedCar;
  var match_0$1 = match$1 ? match$1._0 : Curry._2(validators.registrationDateUsedCar.validate, input, metadata);
  var match$2 = fieldsStatuses.firstReleaseDateCar;
  var match_0$2 = match$2 ? match$2._0 : Curry._2(validators.firstReleaseDateCar.validate, input, metadata);
  var match$3 = fieldsStatuses.vinUsedCar;
  var match_0$3 = match$3 ? match$3._0 : Curry._2(validators.vinUsedCar.validate, input, metadata);
  var match$4 = fieldsStatuses.registrationNumberUsedCar;
  var match_0$4 = match$4 ? match$4._0 : Curry._2(validators.registrationNumberUsedCar.validate, input, metadata);
  var match$5 = fieldsStatuses.versionUsedCar;
  var match_0$5 = match$5 ? match$5._0 : Curry._2(validators.versionUsedCar.validate, input, metadata);
  var match$6 = fieldsStatuses.modelUsedCar;
  var match_0$6 = match$6 ? match$6._0 : Curry._2(validators.modelUsedCar.validate, input, metadata);
  var match$7 = fieldsStatuses.brandUsedCar;
  var match_0$7 = match$7 ? match$7._0 : Curry._2(validators.brandUsedCar.validate, input, metadata);
  var match$8 = fieldsStatuses.endOfLife;
  var match_0$8 = match$8 ? match$8._0 : Curry._2(validators.endOfLife.validate, input, metadata);
  var match$9 = fieldsStatuses.estimatedValue;
  var match_0$9 = match$9 ? match$9._0 : Curry._2(validators.estimatedValue.validate, input, metadata);
  var match$10 = fieldsStatuses.value;
  var match_0$10 = match$10 ? match$10._0 : Curry._2(validators.value.validate, input, metadata);
  var policeNumberResult = match_0;
  var policeNumberResult$1;
  if (policeNumberResult.TAG === /* Ok */0) {
    var registrationDateUsedCarResult = match_0$1;
    if (registrationDateUsedCarResult.TAG === /* Ok */0) {
      var firstReleaseDateCarResult = match_0$2;
      if (firstReleaseDateCarResult.TAG === /* Ok */0) {
        var vinUsedCarResult = match_0$3;
        if (vinUsedCarResult.TAG === /* Ok */0) {
          var registrationNumberUsedCarResult = match_0$4;
          if (registrationNumberUsedCarResult.TAG === /* Ok */0) {
            var versionUsedCarResult = match_0$5;
            if (versionUsedCarResult.TAG === /* Ok */0) {
              var modelUsedCarResult = match_0$6;
              if (modelUsedCarResult.TAG === /* Ok */0) {
                var brandUsedCarResult = match_0$7;
                if (brandUsedCarResult.TAG === /* Ok */0) {
                  var endOfLifeResult = match_0$8;
                  if (endOfLifeResult.TAG === /* Ok */0) {
                    var estimatedValueResult = match_0$9;
                    if (estimatedValueResult.TAG === /* Ok */0) {
                      var valueResult = match_0$10;
                      if (valueResult.TAG === /* Ok */0) {
                        return {
                                TAG: /* Valid */0,
                                output: {
                                  value: valueResult._0,
                                  estimatedValue: estimatedValueResult._0,
                                  endOfLife: endOfLifeResult._0,
                                  brandUsedCar: brandUsedCarResult._0,
                                  modelUsedCar: modelUsedCarResult._0,
                                  versionUsedCar: versionUsedCarResult._0,
                                  registrationNumberUsedCar: registrationNumberUsedCarResult._0,
                                  vinUsedCar: vinUsedCarResult._0,
                                  firstReleaseDateCar: firstReleaseDateCarResult._0,
                                  registrationDateUsedCar: registrationDateUsedCarResult._0,
                                  policeNumber: policeNumberResult._0
                                },
                                fieldsStatuses: {
                                  policeNumber: /* Dirty */{
                                    _0: policeNumberResult,
                                    _1: /* Shown */0
                                  },
                                  registrationDateUsedCar: /* Dirty */{
                                    _0: registrationDateUsedCarResult,
                                    _1: /* Shown */0
                                  },
                                  firstReleaseDateCar: /* Dirty */{
                                    _0: firstReleaseDateCarResult,
                                    _1: /* Shown */0
                                  },
                                  vinUsedCar: /* Dirty */{
                                    _0: vinUsedCarResult,
                                    _1: /* Shown */0
                                  },
                                  registrationNumberUsedCar: /* Dirty */{
                                    _0: registrationNumberUsedCarResult,
                                    _1: /* Shown */0
                                  },
                                  versionUsedCar: /* Dirty */{
                                    _0: versionUsedCarResult,
                                    _1: /* Shown */0
                                  },
                                  modelUsedCar: /* Dirty */{
                                    _0: modelUsedCarResult,
                                    _1: /* Shown */0
                                  },
                                  brandUsedCar: /* Dirty */{
                                    _0: brandUsedCarResult,
                                    _1: /* Shown */0
                                  },
                                  endOfLife: /* Dirty */{
                                    _0: endOfLifeResult,
                                    _1: /* Shown */0
                                  },
                                  estimatedValue: /* Dirty */{
                                    _0: estimatedValueResult,
                                    _1: /* Shown */0
                                  },
                                  value: /* Dirty */{
                                    _0: valueResult,
                                    _1: /* Shown */0
                                  }
                                },
                                collectionsStatuses: undefined
                              };
                      }
                      policeNumberResult$1 = policeNumberResult;
                    } else {
                      policeNumberResult$1 = policeNumberResult;
                    }
                  } else {
                    policeNumberResult$1 = policeNumberResult;
                  }
                } else {
                  policeNumberResult$1 = policeNumberResult;
                }
              } else {
                policeNumberResult$1 = policeNumberResult;
              }
            } else {
              policeNumberResult$1 = policeNumberResult;
            }
          } else {
            policeNumberResult$1 = policeNumberResult;
          }
        } else {
          policeNumberResult$1 = policeNumberResult;
        }
      } else {
        policeNumberResult$1 = policeNumberResult;
      }
    } else {
      policeNumberResult$1 = policeNumberResult;
    }
  } else {
    policeNumberResult$1 = policeNumberResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            policeNumber: /* Dirty */{
              _0: policeNumberResult$1,
              _1: /* Shown */0
            },
            registrationDateUsedCar: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            firstReleaseDateCar: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            vinUsedCar: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            registrationNumberUsedCar: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            versionUsedCar: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            modelUsedCar: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            brandUsedCar: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            },
            endOfLife: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            },
            estimatedValue: /* Dirty */{
              _0: match_0$9,
              _1: /* Shown */0
            },
            value: /* Dirty */{
              _0: match_0$10,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurPoliceNumberField */0 :
                  var result = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.policeNumber, validators_policeNumber, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  policeNumber: status,
                                  registrationDateUsedCar: init.registrationDateUsedCar,
                                  firstReleaseDateCar: init.firstReleaseDateCar,
                                  vinUsedCar: init.vinUsedCar,
                                  registrationNumberUsedCar: init.registrationNumberUsedCar,
                                  versionUsedCar: init.versionUsedCar,
                                  modelUsedCar: init.modelUsedCar,
                                  brandUsedCar: init.brandUsedCar,
                                  endOfLife: init.endOfLife,
                                  estimatedValue: init.estimatedValue,
                                  value: init.value
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRegistrationDateUsedCarField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.registrationDateUsedCar, validators_registrationDateUsedCar, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  policeNumber: init.policeNumber,
                                  registrationDateUsedCar: status,
                                  firstReleaseDateCar: init.firstReleaseDateCar,
                                  vinUsedCar: init.vinUsedCar,
                                  registrationNumberUsedCar: init.registrationNumberUsedCar,
                                  versionUsedCar: init.versionUsedCar,
                                  modelUsedCar: init.modelUsedCar,
                                  brandUsedCar: init.brandUsedCar,
                                  endOfLife: init.endOfLife,
                                  estimatedValue: init.estimatedValue,
                                  value: init.value
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurFirstReleaseDateCarField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.firstReleaseDateCar, validators_firstReleaseDateCar, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  policeNumber: init.policeNumber,
                                  registrationDateUsedCar: init.registrationDateUsedCar,
                                  firstReleaseDateCar: status,
                                  vinUsedCar: init.vinUsedCar,
                                  registrationNumberUsedCar: init.registrationNumberUsedCar,
                                  versionUsedCar: init.versionUsedCar,
                                  modelUsedCar: init.modelUsedCar,
                                  brandUsedCar: init.brandUsedCar,
                                  endOfLife: init.endOfLife,
                                  estimatedValue: init.estimatedValue,
                                  value: init.value
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVinUsedCarField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.vinUsedCar, validators_vinUsedCar, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  policeNumber: init.policeNumber,
                                  registrationDateUsedCar: init.registrationDateUsedCar,
                                  firstReleaseDateCar: init.firstReleaseDateCar,
                                  vinUsedCar: status,
                                  registrationNumberUsedCar: init.registrationNumberUsedCar,
                                  versionUsedCar: init.versionUsedCar,
                                  modelUsedCar: init.modelUsedCar,
                                  brandUsedCar: init.brandUsedCar,
                                  endOfLife: init.endOfLife,
                                  estimatedValue: init.estimatedValue,
                                  value: init.value
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRegistrationNumberUsedCarField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.registrationNumberUsedCar, validators_registrationNumberUsedCar, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  policeNumber: init.policeNumber,
                                  registrationDateUsedCar: init.registrationDateUsedCar,
                                  firstReleaseDateCar: init.firstReleaseDateCar,
                                  vinUsedCar: init.vinUsedCar,
                                  registrationNumberUsedCar: status,
                                  versionUsedCar: init.versionUsedCar,
                                  modelUsedCar: init.modelUsedCar,
                                  brandUsedCar: init.brandUsedCar,
                                  endOfLife: init.endOfLife,
                                  estimatedValue: init.estimatedValue,
                                  value: init.value
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionUsedCarField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.versionUsedCar, validators_versionUsedCar, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  policeNumber: init.policeNumber,
                                  registrationDateUsedCar: init.registrationDateUsedCar,
                                  firstReleaseDateCar: init.firstReleaseDateCar,
                                  vinUsedCar: init.vinUsedCar,
                                  registrationNumberUsedCar: init.registrationNumberUsedCar,
                                  versionUsedCar: status,
                                  modelUsedCar: init.modelUsedCar,
                                  brandUsedCar: init.brandUsedCar,
                                  endOfLife: init.endOfLife,
                                  estimatedValue: init.estimatedValue,
                                  value: init.value
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurModelUsedCarField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.modelUsedCar, validators_modelUsedCar, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  policeNumber: init.policeNumber,
                                  registrationDateUsedCar: init.registrationDateUsedCar,
                                  firstReleaseDateCar: init.firstReleaseDateCar,
                                  vinUsedCar: init.vinUsedCar,
                                  registrationNumberUsedCar: init.registrationNumberUsedCar,
                                  versionUsedCar: init.versionUsedCar,
                                  modelUsedCar: status,
                                  brandUsedCar: init.brandUsedCar,
                                  endOfLife: init.endOfLife,
                                  estimatedValue: init.estimatedValue,
                                  value: init.value
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBrandUsedCarField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.brandUsedCar, validators_brandUsedCar, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  policeNumber: init.policeNumber,
                                  registrationDateUsedCar: init.registrationDateUsedCar,
                                  firstReleaseDateCar: init.firstReleaseDateCar,
                                  vinUsedCar: init.vinUsedCar,
                                  registrationNumberUsedCar: init.registrationNumberUsedCar,
                                  versionUsedCar: init.versionUsedCar,
                                  modelUsedCar: init.modelUsedCar,
                                  brandUsedCar: status,
                                  endOfLife: init.endOfLife,
                                  estimatedValue: init.estimatedValue,
                                  value: init.value
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEndOfLifeField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.endOfLife, validators_endOfLife, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  policeNumber: init.policeNumber,
                                  registrationDateUsedCar: init.registrationDateUsedCar,
                                  firstReleaseDateCar: init.firstReleaseDateCar,
                                  vinUsedCar: init.vinUsedCar,
                                  registrationNumberUsedCar: init.registrationNumberUsedCar,
                                  versionUsedCar: init.versionUsedCar,
                                  modelUsedCar: init.modelUsedCar,
                                  brandUsedCar: init.brandUsedCar,
                                  endOfLife: status,
                                  estimatedValue: init.estimatedValue,
                                  value: init.value
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEstimatedValueField */9 :
                  var result$9 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.estimatedValue, validators_estimatedValue, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  policeNumber: init.policeNumber,
                                  registrationDateUsedCar: init.registrationDateUsedCar,
                                  firstReleaseDateCar: init.firstReleaseDateCar,
                                  vinUsedCar: init.vinUsedCar,
                                  registrationNumberUsedCar: init.registrationNumberUsedCar,
                                  versionUsedCar: init.versionUsedCar,
                                  modelUsedCar: init.modelUsedCar,
                                  brandUsedCar: init.brandUsedCar,
                                  endOfLife: init.endOfLife,
                                  estimatedValue: status,
                                  value: init.value
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurValueField */10 :
                  var result$10 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.value, validators_value, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  policeNumber: init.policeNumber,
                                  registrationDateUsedCar: init.registrationDateUsedCar,
                                  firstReleaseDateCar: init.firstReleaseDateCar,
                                  vinUsedCar: init.vinUsedCar,
                                  registrationNumberUsedCar: init.registrationNumberUsedCar,
                                  versionUsedCar: init.versionUsedCar,
                                  modelUsedCar: init.modelUsedCar,
                                  brandUsedCar: init.brandUsedCar,
                                  endOfLife: init.endOfLife,
                                  estimatedValue: init.estimatedValue,
                                  value: status
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */11 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */11,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */12,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */14);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */13);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */12 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */13 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */14 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdatePoliceNumberField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput, metadata, state.fieldsStatuses.policeNumber, state.submissionStatus, validators_policeNumber, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            policeNumber: status,
                                            registrationDateUsedCar: init.registrationDateUsedCar,
                                            firstReleaseDateCar: init.firstReleaseDateCar,
                                            vinUsedCar: init.vinUsedCar,
                                            registrationNumberUsedCar: init.registrationNumberUsedCar,
                                            versionUsedCar: init.versionUsedCar,
                                            modelUsedCar: init.modelUsedCar,
                                            brandUsedCar: init.brandUsedCar,
                                            endOfLife: init.endOfLife,
                                            estimatedValue: init.estimatedValue,
                                            value: init.value
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRegistrationDateUsedCarField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$1, metadata, state.fieldsStatuses.registrationDateUsedCar, state.submissionStatus, validators_registrationDateUsedCar, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            policeNumber: init.policeNumber,
                                            registrationDateUsedCar: status,
                                            firstReleaseDateCar: init.firstReleaseDateCar,
                                            vinUsedCar: init.vinUsedCar,
                                            registrationNumberUsedCar: init.registrationNumberUsedCar,
                                            versionUsedCar: init.versionUsedCar,
                                            modelUsedCar: init.modelUsedCar,
                                            brandUsedCar: init.brandUsedCar,
                                            endOfLife: init.endOfLife,
                                            estimatedValue: init.estimatedValue,
                                            value: init.value
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateFirstReleaseDateCarField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$2, metadata, state.fieldsStatuses.firstReleaseDateCar, state.submissionStatus, validators_firstReleaseDateCar, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            policeNumber: init.policeNumber,
                                            registrationDateUsedCar: init.registrationDateUsedCar,
                                            firstReleaseDateCar: status,
                                            vinUsedCar: init.vinUsedCar,
                                            registrationNumberUsedCar: init.registrationNumberUsedCar,
                                            versionUsedCar: init.versionUsedCar,
                                            modelUsedCar: init.modelUsedCar,
                                            brandUsedCar: init.brandUsedCar,
                                            endOfLife: init.endOfLife,
                                            estimatedValue: init.estimatedValue,
                                            value: init.value
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVinUsedCarField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$3, metadata, state.fieldsStatuses.vinUsedCar, state.submissionStatus, validators_vinUsedCar, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            policeNumber: init.policeNumber,
                                            registrationDateUsedCar: init.registrationDateUsedCar,
                                            firstReleaseDateCar: init.firstReleaseDateCar,
                                            vinUsedCar: status,
                                            registrationNumberUsedCar: init.registrationNumberUsedCar,
                                            versionUsedCar: init.versionUsedCar,
                                            modelUsedCar: init.modelUsedCar,
                                            brandUsedCar: init.brandUsedCar,
                                            endOfLife: init.endOfLife,
                                            estimatedValue: init.estimatedValue,
                                            value: init.value
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRegistrationNumberUsedCarField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$4, metadata, state.fieldsStatuses.registrationNumberUsedCar, state.submissionStatus, validators_registrationNumberUsedCar, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            policeNumber: init.policeNumber,
                                            registrationDateUsedCar: init.registrationDateUsedCar,
                                            firstReleaseDateCar: init.firstReleaseDateCar,
                                            vinUsedCar: init.vinUsedCar,
                                            registrationNumberUsedCar: status,
                                            versionUsedCar: init.versionUsedCar,
                                            modelUsedCar: init.modelUsedCar,
                                            brandUsedCar: init.brandUsedCar,
                                            endOfLife: init.endOfLife,
                                            estimatedValue: init.estimatedValue,
                                            value: init.value
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionUsedCarField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$5, metadata, state.fieldsStatuses.versionUsedCar, state.submissionStatus, validators_versionUsedCar, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            policeNumber: init.policeNumber,
                                            registrationDateUsedCar: init.registrationDateUsedCar,
                                            firstReleaseDateCar: init.firstReleaseDateCar,
                                            vinUsedCar: init.vinUsedCar,
                                            registrationNumberUsedCar: init.registrationNumberUsedCar,
                                            versionUsedCar: status,
                                            modelUsedCar: init.modelUsedCar,
                                            brandUsedCar: init.brandUsedCar,
                                            endOfLife: init.endOfLife,
                                            estimatedValue: init.estimatedValue,
                                            value: init.value
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateModelUsedCarField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$6, metadata, state.fieldsStatuses.modelUsedCar, state.submissionStatus, validators_modelUsedCar, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            policeNumber: init.policeNumber,
                                            registrationDateUsedCar: init.registrationDateUsedCar,
                                            firstReleaseDateCar: init.firstReleaseDateCar,
                                            vinUsedCar: init.vinUsedCar,
                                            registrationNumberUsedCar: init.registrationNumberUsedCar,
                                            versionUsedCar: init.versionUsedCar,
                                            modelUsedCar: status,
                                            brandUsedCar: init.brandUsedCar,
                                            endOfLife: init.endOfLife,
                                            estimatedValue: init.estimatedValue,
                                            value: init.value
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBrandUsedCarField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$7, metadata, state.fieldsStatuses.brandUsedCar, state.submissionStatus, validators_brandUsedCar, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            policeNumber: init.policeNumber,
                                            registrationDateUsedCar: init.registrationDateUsedCar,
                                            firstReleaseDateCar: init.firstReleaseDateCar,
                                            vinUsedCar: init.vinUsedCar,
                                            registrationNumberUsedCar: init.registrationNumberUsedCar,
                                            versionUsedCar: init.versionUsedCar,
                                            modelUsedCar: init.modelUsedCar,
                                            brandUsedCar: status,
                                            endOfLife: init.endOfLife,
                                            estimatedValue: init.estimatedValue,
                                            value: init.value
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEndOfLifeField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$8, metadata, state.fieldsStatuses.endOfLife, state.submissionStatus, validators_endOfLife, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            policeNumber: init.policeNumber,
                                            registrationDateUsedCar: init.registrationDateUsedCar,
                                            firstReleaseDateCar: init.firstReleaseDateCar,
                                            vinUsedCar: init.vinUsedCar,
                                            registrationNumberUsedCar: init.registrationNumberUsedCar,
                                            versionUsedCar: init.versionUsedCar,
                                            modelUsedCar: init.modelUsedCar,
                                            brandUsedCar: init.brandUsedCar,
                                            endOfLife: status,
                                            estimatedValue: init.estimatedValue,
                                            value: init.value
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEstimatedValueField */9 :
                  var nextInput$9 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$9, metadata, state.fieldsStatuses.estimatedValue, state.submissionStatus, validators_estimatedValue, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            policeNumber: init.policeNumber,
                                            registrationDateUsedCar: init.registrationDateUsedCar,
                                            firstReleaseDateCar: init.firstReleaseDateCar,
                                            vinUsedCar: init.vinUsedCar,
                                            registrationNumberUsedCar: init.registrationNumberUsedCar,
                                            versionUsedCar: init.versionUsedCar,
                                            modelUsedCar: init.modelUsedCar,
                                            brandUsedCar: init.brandUsedCar,
                                            endOfLife: init.endOfLife,
                                            estimatedValue: status,
                                            value: init.value
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateValueField */10 :
                  var nextInput$10 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$10, metadata, state.fieldsStatuses.value, state.submissionStatus, validators_value, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            policeNumber: init.policeNumber,
                                            registrationDateUsedCar: init.registrationDateUsedCar,
                                            firstReleaseDateCar: init.firstReleaseDateCar,
                                            vinUsedCar: init.vinUsedCar,
                                            registrationNumberUsedCar: init.registrationNumberUsedCar,
                                            versionUsedCar: init.versionUsedCar,
                                            modelUsedCar: init.modelUsedCar,
                                            brandUsedCar: init.brandUsedCar,
                                            endOfLife: init.endOfLife,
                                            estimatedValue: init.estimatedValue,
                                            value: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */11 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */12 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */13 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updatePoliceNumber: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePoliceNumberField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateRegistrationDateUsedCar: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRegistrationDateUsedCarField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateFirstReleaseDateCar: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateFirstReleaseDateCarField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVinUsedCar: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVinUsedCarField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateRegistrationNumberUsedCar: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRegistrationNumberUsedCarField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVersionUsedCar: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionUsedCarField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateModelUsedCar: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelUsedCarField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBrandUsedCar: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBrandUsedCarField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEndOfLife: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEndOfLifeField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEstimatedValue: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEstimatedValueField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateValue: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateValueField */10,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurPoliceNumber: (function (param) {
              Curry._1(dispatch, /* BlurPoliceNumberField */0);
            }),
          blurRegistrationDateUsedCar: (function (param) {
              Curry._1(dispatch, /* BlurRegistrationDateUsedCarField */1);
            }),
          blurFirstReleaseDateCar: (function (param) {
              Curry._1(dispatch, /* BlurFirstReleaseDateCarField */2);
            }),
          blurVinUsedCar: (function (param) {
              Curry._1(dispatch, /* BlurVinUsedCarField */3);
            }),
          blurRegistrationNumberUsedCar: (function (param) {
              Curry._1(dispatch, /* BlurRegistrationNumberUsedCarField */4);
            }),
          blurVersionUsedCar: (function (param) {
              Curry._1(dispatch, /* BlurVersionUsedCarField */5);
            }),
          blurModelUsedCar: (function (param) {
              Curry._1(dispatch, /* BlurModelUsedCarField */6);
            }),
          blurBrandUsedCar: (function (param) {
              Curry._1(dispatch, /* BlurBrandUsedCarField */7);
            }),
          blurEndOfLife: (function (param) {
              Curry._1(dispatch, /* BlurEndOfLifeField */8);
            }),
          blurEstimatedValue: (function (param) {
              Curry._1(dispatch, /* BlurEstimatedValueField */9);
            }),
          blurValue: (function (param) {
              Curry._1(dispatch, /* BlurValueField */10);
            }),
          policeNumberResult: Formality.exposeFieldResult(state.fieldsStatuses.policeNumber),
          registrationDateUsedCarResult: Formality.exposeFieldResult(state.fieldsStatuses.registrationDateUsedCar),
          firstReleaseDateCarResult: Formality.exposeFieldResult(state.fieldsStatuses.firstReleaseDateCar),
          vinUsedCarResult: Formality.exposeFieldResult(state.fieldsStatuses.vinUsedCar),
          registrationNumberUsedCarResult: Formality.exposeFieldResult(state.fieldsStatuses.registrationNumberUsedCar),
          versionUsedCarResult: Formality.exposeFieldResult(state.fieldsStatuses.versionUsedCar),
          modelUsedCarResult: Formality.exposeFieldResult(state.fieldsStatuses.modelUsedCar),
          brandUsedCarResult: Formality.exposeFieldResult(state.fieldsStatuses.brandUsedCar),
          endOfLifeResult: Formality.exposeFieldResult(state.fieldsStatuses.endOfLife),
          estimatedValueResult: Formality.exposeFieldResult(state.fieldsStatuses.estimatedValue),
          valueResult: Formality.exposeFieldResult(state.fieldsStatuses.value),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.policeNumber) {
                return true;
              }
              if (!match.registrationDateUsedCar && !match.firstReleaseDateCar && !match.vinUsedCar && !match.registrationNumberUsedCar && !match.versionUsedCar && !match.modelUsedCar && !match.brandUsedCar && !match.endOfLife && !match.estimatedValue && !match.value) {
                return false;
              }
              return true;
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */11);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */12);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */13);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */13,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */14);
            })
        };
}

var Form = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var Types;

var initialState$1 = {
  value: "",
  estimatedValue: "",
  endOfLife: "",
  brandUsedCar: "",
  modelUsedCar: "",
  versionUsedCar: "",
  registrationNumberUsedCar: "",
  vinUsedCar: "",
  firstReleaseDateCar: undefined,
  registrationDateUsedCar: undefined,
  policeNumber: ""
};

export {
  Types ,
  Form ,
  initialState$1 as initialState,
}
/* react Not a pure module */
