// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../common/utils/Utils_Form.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../intl/messages/Messages_Error_Inputs.bs.js";

var validators_usedCarModels = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      if (typeof param$1.actionType === "number") {
        return {
                TAG: /* Ok */0,
                _0: /* [] */0
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: param.usedCarModels
              };
      }
    })
};

var validators_ie = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var actionType = param$1.actionType;
      var ie = param.ie;
      if (typeof actionType === "number" && actionType !== 2) {
        return {
                TAG: /* Ok */0,
                _0: /* ALL */0
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: ie
              };
      }
    })
};

var validators_brands = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var actionType = param$1.actionType;
      var brands = param.brands;
      if (param.ie !== 0 && !(typeof actionType === "number" && actionType !== 2)) {
        return Utils_Form$DvmAdminFrontendFr.validateListRequired(brands);
      } else {
        return {
                TAG: /* Ok */0,
                _0: /* [] */0
              };
      }
    })
};

var validators_possesionPeriodWhich = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var actionType = param$1.actionType;
      var possesionPeriodWhich = param.possesionPeriodWhich;
      if (typeof actionType === "number" && actionType !== 2) {
        return {
                TAG: /* Ok */0,
                _0: "none"
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: possesionPeriodWhich
              };
      }
    })
};

var validators_possesionPeriod = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var actionType = param$1.actionType;
      var possesionPeriodWhich = param.possesionPeriodWhich;
      if (typeof actionType === "number") {
        if (actionType !== 2) {
          return {
                  TAG: /* Ok */0,
                  _0: 0
                };
        }
        if (!(possesionPeriodWhich === "order" || possesionPeriodWhich === "delivery")) {
          return {
                  TAG: /* Ok */0,
                  _0: 0
                };
        }
        
      } else if (!(possesionPeriodWhich === "order" || possesionPeriodWhich === "delivery")) {
        return {
                TAG: /* Ok */0,
                _0: 0
              };
      }
      var number = Belt_Int.fromString(param.possesionPeriod);
      if (number !== undefined) {
        if (number > 0) {
          return {
                  TAG: /* Ok */0,
                  _0: number
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.largerZero
                };
        }
      } else {
        return {
                TAG: /* Error */1,
                _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
              };
      }
    })
};

var validators_carAgeWhich = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var actionType = param$1.actionType;
      var carAgeWhich = param.carAgeWhich;
      if (typeof actionType === "number" && actionType !== 2) {
        return {
                TAG: /* Ok */0,
                _0: "none"
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: carAgeWhich
              };
      }
    })
};

var validators_carAge = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var actionType = param$1.actionType;
      var carAgeWhich = param.carAgeWhich;
      if (typeof actionType === "number") {
        if (actionType !== 2) {
          return {
                  TAG: /* Ok */0,
                  _0: 0
                };
        }
        if (!(carAgeWhich === "order" || carAgeWhich === "delivery")) {
          return {
                  TAG: /* Ok */0,
                  _0: 0
                };
        }
        
      } else if (!(carAgeWhich === "order" || carAgeWhich === "delivery")) {
        return {
                TAG: /* Ok */0,
                _0: 0
              };
      }
      var number = Belt_Int.fromString(param.carAge);
      if (number !== undefined) {
        if (number > 0) {
          return {
                  TAG: /* Ok */0,
                  _0: number
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.largerZero
                };
        }
      } else {
        return {
                TAG: /* Error */1,
                _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
              };
      }
    })
};

var validators = {
  usedCarModels: validators_usedCarModels,
  actionWithVO: undefined,
  ie: validators_ie,
  brands: validators_brands,
  possesionPeriodWhich: validators_possesionPeriodWhich,
  possesionPeriod: validators_possesionPeriod,
  carAgeWhich: validators_carAgeWhich,
  carAge: validators_carAge
};

function initialFieldsStatuses(_input) {
  return {
          usedCarModels: /* Pristine */0,
          actionWithVO: /* Pristine */0,
          ie: /* Pristine */0,
          brands: /* Pristine */0,
          possesionPeriodWhich: /* Pristine */0,
          possesionPeriod: /* Pristine */0,
          carAgeWhich: /* Pristine */0,
          carAge: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match = fieldsStatuses.usedCarModels;
  var match_0 = match ? match._0 : Curry._2(validators.usedCarModels.validate, input, metadata);
  var match_0$1 = {
    TAG: /* Ok */0,
    _0: input.actionWithVO
  };
  var match$1 = fieldsStatuses.ie;
  var match_0$2 = match$1 ? match$1._0 : Curry._2(validators.ie.validate, input, metadata);
  var match$2 = fieldsStatuses.brands;
  var match_0$3 = match$2 ? match$2._0 : Curry._2(validators.brands.validate, input, metadata);
  var match$3 = fieldsStatuses.possesionPeriodWhich;
  var match_0$4 = match$3 ? match$3._0 : Curry._2(validators.possesionPeriodWhich.validate, input, metadata);
  var match$4 = fieldsStatuses.possesionPeriod;
  var match_0$5 = match$4 ? match$4._0 : Curry._2(validators.possesionPeriod.validate, input, metadata);
  var match$5 = fieldsStatuses.carAgeWhich;
  var match_0$6 = match$5 ? match$5._0 : Curry._2(validators.carAgeWhich.validate, input, metadata);
  var match$6 = fieldsStatuses.carAge;
  var match_0$7 = match$6 ? match$6._0 : Curry._2(validators.carAge.validate, input, metadata);
  var usedCarModelsResult = match_0;
  var usedCarModelsResult$1;
  if (usedCarModelsResult.TAG === /* Ok */0) {
    var actionWithVOResult = match_0$1;
    if (actionWithVOResult.TAG === /* Ok */0) {
      var ieResult = match_0$2;
      if (ieResult.TAG === /* Ok */0) {
        var brandsResult = match_0$3;
        if (brandsResult.TAG === /* Ok */0) {
          var possesionPeriodWhichResult = match_0$4;
          if (possesionPeriodWhichResult.TAG === /* Ok */0) {
            var possesionPeriodResult = match_0$5;
            if (possesionPeriodResult.TAG === /* Ok */0) {
              var carAgeWhichResult = match_0$6;
              if (carAgeWhichResult.TAG === /* Ok */0) {
                var carAgeResult = match_0$7;
                if (carAgeResult.TAG === /* Ok */0) {
                  return {
                          TAG: /* Valid */0,
                          output: {
                            carAge: carAgeResult._0,
                            carAgeWhich: carAgeWhichResult._0,
                            possesionPeriod: possesionPeriodResult._0,
                            possesionPeriodWhich: possesionPeriodWhichResult._0,
                            brands: brandsResult._0,
                            ie: ieResult._0,
                            actionWithVO: actionWithVOResult._0,
                            usedCarModels: usedCarModelsResult._0
                          },
                          fieldsStatuses: {
                            usedCarModels: /* Dirty */{
                              _0: usedCarModelsResult,
                              _1: /* Shown */0
                            },
                            actionWithVO: /* Dirty */{
                              _0: actionWithVOResult,
                              _1: /* Hidden */1
                            },
                            ie: /* Dirty */{
                              _0: ieResult,
                              _1: /* Shown */0
                            },
                            brands: /* Dirty */{
                              _0: brandsResult,
                              _1: /* Shown */0
                            },
                            possesionPeriodWhich: /* Dirty */{
                              _0: possesionPeriodWhichResult,
                              _1: /* Shown */0
                            },
                            possesionPeriod: /* Dirty */{
                              _0: possesionPeriodResult,
                              _1: /* Shown */0
                            },
                            carAgeWhich: /* Dirty */{
                              _0: carAgeWhichResult,
                              _1: /* Shown */0
                            },
                            carAge: /* Dirty */{
                              _0: carAgeResult,
                              _1: /* Shown */0
                            }
                          },
                          collectionsStatuses: undefined
                        };
                }
                usedCarModelsResult$1 = usedCarModelsResult;
              } else {
                usedCarModelsResult$1 = usedCarModelsResult;
              }
            } else {
              usedCarModelsResult$1 = usedCarModelsResult;
            }
          } else {
            usedCarModelsResult$1 = usedCarModelsResult;
          }
        } else {
          usedCarModelsResult$1 = usedCarModelsResult;
        }
      } else {
        usedCarModelsResult$1 = usedCarModelsResult;
      }
    } else {
      usedCarModelsResult$1 = usedCarModelsResult;
    }
  } else {
    usedCarModelsResult$1 = usedCarModelsResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            usedCarModels: /* Dirty */{
              _0: usedCarModelsResult$1,
              _1: /* Shown */0
            },
            actionWithVO: /* Dirty */{
              _0: match_0$1,
              _1: /* Hidden */1
            },
            ie: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            brands: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            possesionPeriodWhich: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            possesionPeriod: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            carAgeWhich: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            carAge: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurUsedCarModelsField */0 :
                  var result = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.usedCarModels, validators_usedCarModels, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  usedCarModels: status,
                                  actionWithVO: init.actionWithVO,
                                  ie: init.ie,
                                  brands: init.brands,
                                  possesionPeriodWhich: init.possesionPeriodWhich,
                                  possesionPeriod: init.possesionPeriod,
                                  carAgeWhich: init.carAgeWhich,
                                  carAge: init.carAge
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurActionWithVOField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithoutValidator(state.input.actionWithVO, state.fieldsStatuses.actionWithVO, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  usedCarModels: init.usedCarModels,
                                  actionWithVO: status,
                                  ie: init.ie,
                                  brands: init.brands,
                                  possesionPeriodWhich: init.possesionPeriodWhich,
                                  possesionPeriod: init.possesionPeriod,
                                  carAgeWhich: init.carAgeWhich,
                                  carAge: init.carAge
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurIeField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.ie, validators_ie, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  usedCarModels: init.usedCarModels,
                                  actionWithVO: init.actionWithVO,
                                  ie: status,
                                  brands: init.brands,
                                  possesionPeriodWhich: init.possesionPeriodWhich,
                                  possesionPeriod: init.possesionPeriod,
                                  carAgeWhich: init.carAgeWhich,
                                  carAge: init.carAge
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBrandsField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.brands, validators_brands, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  usedCarModels: init.usedCarModels,
                                  actionWithVO: init.actionWithVO,
                                  ie: init.ie,
                                  brands: status,
                                  possesionPeriodWhich: init.possesionPeriodWhich,
                                  possesionPeriod: init.possesionPeriod,
                                  carAgeWhich: init.carAgeWhich,
                                  carAge: init.carAge
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPossesionPeriodWhichField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.possesionPeriodWhich, validators_possesionPeriodWhich, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  usedCarModels: init.usedCarModels,
                                  actionWithVO: init.actionWithVO,
                                  ie: init.ie,
                                  brands: init.brands,
                                  possesionPeriodWhich: status,
                                  possesionPeriod: init.possesionPeriod,
                                  carAgeWhich: init.carAgeWhich,
                                  carAge: init.carAge
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPossesionPeriodField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.possesionPeriod, validators_possesionPeriod, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  usedCarModels: init.usedCarModels,
                                  actionWithVO: init.actionWithVO,
                                  ie: init.ie,
                                  brands: init.brands,
                                  possesionPeriodWhich: init.possesionPeriodWhich,
                                  possesionPeriod: status,
                                  carAgeWhich: init.carAgeWhich,
                                  carAge: init.carAge
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCarAgeWhichField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.carAgeWhich, validators_carAgeWhich, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  usedCarModels: init.usedCarModels,
                                  actionWithVO: init.actionWithVO,
                                  ie: init.ie,
                                  brands: init.brands,
                                  possesionPeriodWhich: init.possesionPeriodWhich,
                                  possesionPeriod: init.possesionPeriod,
                                  carAgeWhich: status,
                                  carAge: init.carAge
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCarAgeField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.carAge, validators_carAge, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  usedCarModels: init.usedCarModels,
                                  actionWithVO: init.actionWithVO,
                                  ie: init.ie,
                                  brands: init.brands,
                                  possesionPeriodWhich: init.possesionPeriodWhich,
                                  possesionPeriod: init.possesionPeriod,
                                  carAgeWhich: init.carAgeWhich,
                                  carAge: status
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */8 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */8,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */9,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */11);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */10);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */9 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */10 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */11 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateUsedCarModelsField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput, metadata, state.fieldsStatuses.usedCarModels, state.submissionStatus, validators_usedCarModels, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            usedCarModels: status,
                                            actionWithVO: init.actionWithVO,
                                            ie: init.ie,
                                            brands: init.brands,
                                            possesionPeriodWhich: init.possesionPeriodWhich,
                                            possesionPeriod: init.possesionPeriod,
                                            carAgeWhich: init.carAgeWhich,
                                            carAge: init.carAge
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateActionWithVOField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$1.actionWithVO, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            usedCarModels: init.usedCarModels,
                                            actionWithVO: status,
                                            ie: init.ie,
                                            brands: init.brands,
                                            possesionPeriodWhich: init.possesionPeriodWhich,
                                            possesionPeriod: init.possesionPeriod,
                                            carAgeWhich: init.carAgeWhich,
                                            carAge: init.carAge
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateIeField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$8 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$2, metadata, nextFieldsStatuses.contents.brands, validators_brands, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  usedCarModels: init.usedCarModels,
                                  actionWithVO: init.actionWithVO,
                                  ie: init.ie,
                                  brands: status,
                                  possesionPeriodWhich: init.possesionPeriodWhich,
                                  possesionPeriod: init.possesionPeriod,
                                  carAgeWhich: init.carAgeWhich,
                                  carAge: init.carAge
                                };
                        }));
                  if (result$8 !== undefined) {
                    nextFieldsStatuses.contents = result$8;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$2, metadata, nextFieldsStatuses.contents.ie, state.submissionStatus, validators_ie, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            usedCarModels: init.usedCarModels,
                                            actionWithVO: init.actionWithVO,
                                            ie: status,
                                            brands: init.brands,
                                            possesionPeriodWhich: init.possesionPeriodWhich,
                                            possesionPeriod: init.possesionPeriod,
                                            carAgeWhich: init.carAgeWhich,
                                            carAge: init.carAge
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBrandsField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$9 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$3, metadata, nextFieldsStatuses$1.contents.ie, validators_ie, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  usedCarModels: init.usedCarModels,
                                  actionWithVO: init.actionWithVO,
                                  ie: status,
                                  brands: init.brands,
                                  possesionPeriodWhich: init.possesionPeriodWhich,
                                  possesionPeriod: init.possesionPeriod,
                                  carAgeWhich: init.carAgeWhich,
                                  carAge: init.carAge
                                };
                        }));
                  if (result$9 !== undefined) {
                    nextFieldsStatuses$1.contents = result$9;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$3, metadata, nextFieldsStatuses$1.contents.brands, state.submissionStatus, validators_brands, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            usedCarModels: init.usedCarModels,
                                            actionWithVO: init.actionWithVO,
                                            ie: init.ie,
                                            brands: status,
                                            possesionPeriodWhich: init.possesionPeriodWhich,
                                            possesionPeriod: init.possesionPeriod,
                                            carAgeWhich: init.carAgeWhich,
                                            carAge: init.carAge
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePossesionPeriodWhichField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$2 = {
                    contents: state.fieldsStatuses
                  };
                  var result$10 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$4, metadata, nextFieldsStatuses$2.contents.possesionPeriod, validators_possesionPeriod, (function (status) {
                          var init = nextFieldsStatuses$2.contents;
                          return {
                                  usedCarModels: init.usedCarModels,
                                  actionWithVO: init.actionWithVO,
                                  ie: init.ie,
                                  brands: init.brands,
                                  possesionPeriodWhich: init.possesionPeriodWhich,
                                  possesionPeriod: status,
                                  carAgeWhich: init.carAgeWhich,
                                  carAge: init.carAge
                                };
                        }));
                  if (result$10 !== undefined) {
                    nextFieldsStatuses$2.contents = result$10;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$4, metadata, nextFieldsStatuses$2.contents.possesionPeriodWhich, state.submissionStatus, validators_possesionPeriodWhich, (function (status) {
                                    var init = nextFieldsStatuses$2.contents;
                                    return {
                                            usedCarModels: init.usedCarModels,
                                            actionWithVO: init.actionWithVO,
                                            ie: init.ie,
                                            brands: init.brands,
                                            possesionPeriodWhich: status,
                                            possesionPeriod: init.possesionPeriod,
                                            carAgeWhich: init.carAgeWhich,
                                            carAge: init.carAge
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePossesionPeriodField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$3 = {
                    contents: state.fieldsStatuses
                  };
                  var result$11 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$5, metadata, nextFieldsStatuses$3.contents.possesionPeriodWhich, validators_possesionPeriodWhich, (function (status) {
                          var init = nextFieldsStatuses$3.contents;
                          return {
                                  usedCarModels: init.usedCarModels,
                                  actionWithVO: init.actionWithVO,
                                  ie: init.ie,
                                  brands: init.brands,
                                  possesionPeriodWhich: status,
                                  possesionPeriod: init.possesionPeriod,
                                  carAgeWhich: init.carAgeWhich,
                                  carAge: init.carAge
                                };
                        }));
                  if (result$11 !== undefined) {
                    nextFieldsStatuses$3.contents = result$11;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$5, metadata, nextFieldsStatuses$3.contents.possesionPeriod, state.submissionStatus, validators_possesionPeriod, (function (status) {
                                    var init = nextFieldsStatuses$3.contents;
                                    return {
                                            usedCarModels: init.usedCarModels,
                                            actionWithVO: init.actionWithVO,
                                            ie: init.ie,
                                            brands: init.brands,
                                            possesionPeriodWhich: init.possesionPeriodWhich,
                                            possesionPeriod: status,
                                            carAgeWhich: init.carAgeWhich,
                                            carAge: init.carAge
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCarAgeWhichField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$4 = {
                    contents: state.fieldsStatuses
                  };
                  var result$12 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$6, metadata, nextFieldsStatuses$4.contents.carAge, validators_carAge, (function (status) {
                          var init = nextFieldsStatuses$4.contents;
                          return {
                                  usedCarModels: init.usedCarModels,
                                  actionWithVO: init.actionWithVO,
                                  ie: init.ie,
                                  brands: init.brands,
                                  possesionPeriodWhich: init.possesionPeriodWhich,
                                  possesionPeriod: init.possesionPeriod,
                                  carAgeWhich: init.carAgeWhich,
                                  carAge: status
                                };
                        }));
                  if (result$12 !== undefined) {
                    nextFieldsStatuses$4.contents = result$12;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$6, metadata, nextFieldsStatuses$4.contents.carAgeWhich, state.submissionStatus, validators_carAgeWhich, (function (status) {
                                    var init = nextFieldsStatuses$4.contents;
                                    return {
                                            usedCarModels: init.usedCarModels,
                                            actionWithVO: init.actionWithVO,
                                            ie: init.ie,
                                            brands: init.brands,
                                            possesionPeriodWhich: init.possesionPeriodWhich,
                                            possesionPeriod: init.possesionPeriod,
                                            carAgeWhich: status,
                                            carAge: init.carAge
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCarAgeField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$5 = {
                    contents: state.fieldsStatuses
                  };
                  var result$13 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$7, metadata, nextFieldsStatuses$5.contents.carAgeWhich, validators_carAgeWhich, (function (status) {
                          var init = nextFieldsStatuses$5.contents;
                          return {
                                  usedCarModels: init.usedCarModels,
                                  actionWithVO: init.actionWithVO,
                                  ie: init.ie,
                                  brands: init.brands,
                                  possesionPeriodWhich: init.possesionPeriodWhich,
                                  possesionPeriod: init.possesionPeriod,
                                  carAgeWhich: status,
                                  carAge: init.carAge
                                };
                        }));
                  if (result$13 !== undefined) {
                    nextFieldsStatuses$5.contents = result$13;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$7, metadata, nextFieldsStatuses$5.contents.carAge, state.submissionStatus, validators_carAge, (function (status) {
                                    var init = nextFieldsStatuses$5.contents;
                                    return {
                                            usedCarModels: init.usedCarModels,
                                            actionWithVO: init.actionWithVO,
                                            ie: init.ie,
                                            brands: init.brands,
                                            possesionPeriodWhich: init.possesionPeriodWhich,
                                            possesionPeriod: init.possesionPeriod,
                                            carAgeWhich: init.carAgeWhich,
                                            carAge: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */8 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */9 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */10 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateUsedCarModels: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateUsedCarModelsField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateActionWithVO: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateActionWithVOField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateIe: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateIeField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBrands: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBrandsField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updatePossesionPeriodWhich: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePossesionPeriodWhichField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updatePossesionPeriod: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePossesionPeriodField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCarAgeWhich: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCarAgeWhichField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCarAge: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCarAgeField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurUsedCarModels: (function (param) {
              Curry._1(dispatch, /* BlurUsedCarModelsField */0);
            }),
          blurActionWithVO: (function (param) {
              Curry._1(dispatch, /* BlurActionWithVOField */1);
            }),
          blurIe: (function (param) {
              Curry._1(dispatch, /* BlurIeField */2);
            }),
          blurBrands: (function (param) {
              Curry._1(dispatch, /* BlurBrandsField */3);
            }),
          blurPossesionPeriodWhich: (function (param) {
              Curry._1(dispatch, /* BlurPossesionPeriodWhichField */4);
            }),
          blurPossesionPeriod: (function (param) {
              Curry._1(dispatch, /* BlurPossesionPeriodField */5);
            }),
          blurCarAgeWhich: (function (param) {
              Curry._1(dispatch, /* BlurCarAgeWhichField */6);
            }),
          blurCarAge: (function (param) {
              Curry._1(dispatch, /* BlurCarAgeField */7);
            }),
          usedCarModelsResult: Formality.exposeFieldResult(state.fieldsStatuses.usedCarModels),
          actionWithVOResult: Formality.exposeFieldResult(state.fieldsStatuses.actionWithVO),
          ieResult: Formality.exposeFieldResult(state.fieldsStatuses.ie),
          brandsResult: Formality.exposeFieldResult(state.fieldsStatuses.brands),
          possesionPeriodWhichResult: Formality.exposeFieldResult(state.fieldsStatuses.possesionPeriodWhich),
          possesionPeriodResult: Formality.exposeFieldResult(state.fieldsStatuses.possesionPeriod),
          carAgeWhichResult: Formality.exposeFieldResult(state.fieldsStatuses.carAgeWhich),
          carAgeResult: Formality.exposeFieldResult(state.fieldsStatuses.carAge),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.usedCarModels || match.actionWithVO || match.ie || match.brands || match.possesionPeriodWhich || match.possesionPeriod || match.carAgeWhich || match.carAge) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */8);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */9);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */10);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */10,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */11);
            })
        };
}

var FlashForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var Types;

var initialState$1 = {
  carAge: "0",
  carAgeWhich: "none",
  possesionPeriod: "0",
  possesionPeriodWhich: "none",
  brands: /* [] */0,
  ie: /* ALL */0,
  actionWithVO: false,
  usedCarModels: /* [] */0
};

export {
  Types ,
  FlashForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
