// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../../../common/components/icons/App_Icon.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../../common/utils/Utils_Alert.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_ModelVersion$DvmAdminFrontendFr from "../../../../../../intl/messages/modelVersion/Messages_ModelVersion.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Create_Api$DvmAdminFrontendFr from "./Order_Detail_Bonus_DealerRequest_Create_Api.bs.js";

function Order_Detail_Bonus_DealerRequest_Document_Row(Props) {
  var setNewDocuments = Props.setNewDocuments;
  var index = Props.index;
  var item = Props.item;
  var saveResult = Props.saveResult;
  var orderId = Props.orderId;
  var actionId = Props.actionId;
  var documentTypes = Props.documentTypes;
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDocumentSaveResult = match$2[1];
  var documentSaveResult = match$2[0];
  React.useEffect((function () {
          if (App_Types_Result$DvmAdminFrontendFr.isSuccess(saveResult)) {
            Order_Detail_Bonus_DealerRequest_Create_Api$DvmAdminFrontendFr.postFile(userManager, item, selectedRole, orderId, actionId, setDocumentSaveResult);
          }
          
        }), [saveResult]);
  React.useEffect((function () {
          if (typeof documentSaveResult === "number") {
            documentSaveResult === /* NotStarted */0;
          } else if (documentSaveResult.TAG === /* Ok */0) {
            Curry._1(setNewDocuments, (function (documents) {
                    return Belt_List.mapWithIndex(documents, (function (indexDoc, doc) {
                                  if (indexDoc === index) {
                                    return {
                                            document: doc.document,
                                            fileType: doc.fileType,
                                            comment: doc.comment,
                                            uploaded: true
                                          };
                                  } else {
                                    return doc;
                                  }
                                }));
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: documentSaveResult._0
                      }
                    }));
          }
        }), [documentSaveResult]);
  return React.createElement(Core.Grid, {
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(documentSaveResult),
                    children: React.createElement(Core.Grid, {
                          alignItems: "center",
                          children: null,
                          container: true,
                          item: true,
                          justifyContent: "space-between",
                          spacing: 2,
                          key: String(index)
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                    children: item.document.name,
                                    variant: "body"
                                  }),
                              item: true,
                              xs: Grid$Mui.Xs[3]
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                    label: Messages_ModelVersion$DvmAdminFrontendFr._type,
                                    value: {
                                      TAG: /* Single */0,
                                      _0: {
                                        value: item.fileType,
                                        onChange: (function (val) {
                                            Curry._1(setNewDocuments, (function (documents) {
                                                    return Belt_List.mapWithIndex(documents, (function (indexDoc, doc) {
                                                                  if (indexDoc === index) {
                                                                    return {
                                                                            document: doc.document,
                                                                            fileType: val,
                                                                            comment: doc.comment,
                                                                            uploaded: doc.uploaded
                                                                          };
                                                                  } else {
                                                                    return doc;
                                                                  }
                                                                }));
                                                  }));
                                          })
                                      }
                                    },
                                    options: {
                                      TAG: /* Labeled */1,
                                      _0: Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(documentTypes, /* [] */0), (function (x) {
                                              return {
                                                      label: {
                                                        TAG: /* String */3,
                                                        _0: x.fileLabel
                                                      },
                                                      value: x.fileType
                                                    };
                                            }))
                                    },
                                    fullWidth: true
                                  }),
                              item: true,
                              xs: Grid$Mui.Xs[3]
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                    onChange: (function (val) {
                                        Curry._1(setNewDocuments, (function (documents) {
                                                return Belt_List.mapWithIndex(documents, (function (indexDoc, doc) {
                                                              if (indexDoc === index) {
                                                                return {
                                                                        document: doc.document,
                                                                        fileType: doc.fileType,
                                                                        comment: val,
                                                                        uploaded: doc.uploaded
                                                                      };
                                                              } else {
                                                                return doc;
                                                              }
                                                            }));
                                              }));
                                      }),
                                    value: item.comment,
                                    fullWidth: true,
                                    label: Messages_Common$DvmAdminFrontendFr.comment
                                  }),
                              item: true,
                              xs: Grid$Mui.Xs[5]
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(Core.IconButton, {
                                    onClick: (function (param) {
                                        Curry._1(setNewDocuments, (function (documents) {
                                                return Belt_List.keepWithIndex(documents, (function (param, ind) {
                                                              return ind !== index;
                                                            }));
                                              }));
                                      }),
                                    children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                          size: /* Large */3,
                                          type_: /* Trash */38,
                                          color: Theme_Colors$DvmAdminFrontendFr.accentRed
                                        })
                                  }),
                              item: true,
                              xs: Grid$Mui.Xs[1]
                            }))
                  }),
              item: true
            });
}

var Api;

var make = Order_Detail_Bonus_DealerRequest_Document_Row;

export {
  Api ,
  make ,
}
/* react Not a pure module */
