// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function requestBody_encode(v) {
  return Js_dict.fromArray([
              [
                "orderId",
                Decco.stringToJson(v.orderId)
              ],
              [
                "actionId",
                Decco.stringToJson(v.actionId)
              ],
              [
                "amount",
                Decco.optionToJson(Decco.floatToJson, v.amount)
              ],
              [
                "comment",
                Decco.optionToJson(Decco.stringToJson, v.comment)
              ]
            ]);
}

function requestBody_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var orderId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderId"), null));
  if (orderId.TAG === /* Ok */0) {
    var actionId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionId"), null));
    if (actionId.TAG === /* Ok */0) {
      var amount = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "amount"), null));
      if (amount.TAG === /* Ok */0) {
        var comment = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "comment"), null));
        if (comment.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    orderId: orderId._0,
                    actionId: actionId._0,
                    amount: amount._0,
                    comment: comment._0
                  }
                };
        }
        var e = comment._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".comment" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = amount._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".amount" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = actionId._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".actionId" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = orderId._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".orderId" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function requestBodyList_encode(v) {
  return Decco.listToJson(requestBody_encode, v);
}

function requestBodyList_decode(v) {
  return Decco.listFromJson(requestBody_decode, v);
}

function doccumentFileType_encode(v) {
  return Js_dict.fromArray([
              [
                "fileType",
                Decco.stringToJson(v.fileType)
              ],
              [
                "fileLabel",
                Decco.stringToJson(v.fileLabel)
              ],
              [
                "mandatory",
                Decco.boolToJson(v.mandatory)
              ]
            ]);
}

function doccumentFileType_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var fileType = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "fileType"), null));
  if (fileType.TAG === /* Ok */0) {
    var fileLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "fileLabel"), null));
    if (fileLabel.TAG === /* Ok */0) {
      var mandatory = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "mandatory"), null));
      if (mandatory.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  fileType: fileType._0,
                  fileLabel: fileLabel._0,
                  mandatory: mandatory._0
                }
              };
      }
      var e = mandatory._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".mandatory" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = fileLabel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".fileLabel" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = fileType._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".fileType" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function doccumentFileTypeList_encode(v) {
  return Decco.listToJson(doccumentFileType_encode, v);
}

function doccumentFileTypeList_decode(v) {
  return Decco.listFromJson(doccumentFileType_decode, v);
}

var maxAmount = 20000.0;

export {
  maxAmount ,
  requestBody_encode ,
  requestBody_decode ,
  requestBodyList_encode ,
  requestBodyList_decode ,
  doccumentFileType_encode ,
  doccumentFileType_decode ,
  doccumentFileTypeList_encode ,
  doccumentFileTypeList_decode ,
}
/* No side effect */
