// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Lab from "@material-ui/lab";
import * as Core from "@material-ui/core";
import * as Autocomplete from "@material-ui/lab/Autocomplete";
import * as App_Types_Text$DvmAdminFrontendFr from "../../types/App_Types_Text.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "./App_TextFieldFormInput.bs.js";

function App_AutocompleteFormInput(Props) {
  var label = Props.label;
  var value = Props.value;
  var options = Props.options;
  var validationResult = Props.validationResult;
  var onBlur = Props.onBlur;
  var disabled = Props.disabled;
  var required = Props.required;
  var fullWidth = Props.fullWidth;
  var className = Props.className;
  var classes = Props.classes;
  var onSearch = Props.onSearch;
  var loading = Props.loading;
  var noOptionsText = Props.noOptionsText;
  var limitTags = Props.limitTags;
  var smallChips = Props.smallChips;
  var matchFromOpt = Props.matchFrom;
  var loadingText = Props.loadingText;
  var popupIcon = Props.popupIcon;
  var freeSolo = Props.freeSolo;
  var placeholder = Props.placeholder;
  var autoSelect = Props.autoSelect;
  var maxLength = Props.maxLength;
  var disableClearable = Props.disableClearable;
  var disabledValues = Props.disabledValues;
  var helperTextWhenNoError = Props.helperTextWhenNoError;
  var inputValue = Props.inputValue;
  var matchFrom = matchFromOpt !== undefined ? matchFromOpt : "any";
  var intl = ReactIntl.useIntl();
  var tmp;
  tmp = value.TAG === /* Single */0 ? false : true;
  var tmp$1;
  tmp$1 = options.TAG === /* Unlabeled */0 ? (function (option) {
        return option;
      }) : (function (option) {
        return option.label;
      });
  var tmp$2;
  tmp$2 = options.TAG === /* Unlabeled */0 ? (function (option, value) {
        if (option === value) {
          return "true";
        } else {
          return "";
        }
      }) : (function (option, value) {
        if (option.value === value.value) {
          return "true";
        } else {
          return "";
        }
      });
  var tmp$3;
  tmp$3 = value.TAG === /* Single */0 ? false : true;
  var tmp$4;
  tmp$4 = options.TAG === /* Unlabeled */0 ? Belt_List.toArray(Belt_List.map(options._0, (function (prim) {
                return prim;
              }))) : Belt_List.toArray(Belt_List.map(options._0, (function (x) {
                return {
                        label: App_Types_Text$DvmAdminFrontendFr.toString(intl, x.label),
                        value: x.value
                      };
              })));
  var tmp$5;
  if (value.TAG === /* Single */0) {
    var value$1 = value._0.value;
    tmp$5 = options.TAG === /* Unlabeled */0 ? (
        value$1 === "" ? null : value$1
      ) : Belt_Option.mapWithDefault(Belt_List.getBy(options._0, (function (element) {
                  return element.value === value$1;
                })), null, (function (x) {
              return {
                      label: App_Types_Text$DvmAdminFrontendFr.toString(intl, x.label),
                      value: x.value
                    };
            }));
  } else {
    var value$2 = value._0.value;
    if (options.TAG === /* Unlabeled */0) {
      tmp$5 = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_List.toArray(value$2)));
    } else {
      var options$1 = options._0;
      tmp$5 = Belt_List.toArray(Belt_List.map(Belt_List.keepMap(Belt_List.map(Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(value$2))), (function (x) {
                          return Belt_List.getBy(options$1, (function (element) {
                                        return element.value === x;
                                      }));
                        })), (function (x) {
                      return x;
                    })), (function (x) {
                  return {
                          label: App_Types_Text$DvmAdminFrontendFr.toString(intl, x.label),
                          value: x.value
                        };
                })));
    }
  }
  var tmp$6 = {
    disableCloseOnSelect: tmp,
    filterOptions: Autocomplete.createFilterOptions({
          matchFrom: matchFrom
        }),
    forcePopupIcon: true,
    getOptionLabel: tmp$1,
    getOptionSelected: tmp$2,
    loadingText: Belt_Option.getWithDefault(loadingText, intl.formatMessage(Messages_Common$DvmAdminFrontendFr.loading)),
    multiple: tmp$3,
    noOptionsText: Belt_Option.getWithDefault(noOptionsText, intl.formatMessage(Messages_Common$DvmAdminFrontendFr.noOption)),
    onChange: (function (param, val, param$1) {
        if (value.TAG === /* Single */0) {
          var onChange = value._0.onChange;
          if (options.TAG === /* Unlabeled */0) {
            return Curry._1(onChange, Belt_Option.getWithDefault((val == null) ? undefined : Caml_option.some(val), ""));
          } else {
            return Curry._1(onChange, Belt_Option.mapWithDefault((val == null) ? undefined : Caml_option.some(val), "", (function (x) {
                              return x.value;
                            })));
          }
        }
        var onChange$1 = value._0.onChange;
        if (options.TAG === /* Unlabeled */0) {
          return Curry._1(onChange$1, Belt_List.fromArray(Belt_Option.getWithDefault((val == null) ? undefined : Caml_option.some(val), [])));
        } else {
          return Curry._1(onChange$1, Belt_List.map(Belt_List.fromArray(Belt_Option.getWithDefault((val == null) ? undefined : Caml_option.some(val), [])), (function (x) {
                            return x.value;
                          })));
        }
      }),
    onInputChange: (function (param, value, reason) {
        if (reason === "input" && onSearch !== undefined) {
          return Curry._1(onSearch, value);
        }
        
      }),
    options: tmp$4,
    renderInput: (function (props) {
        var tmp = {
          onChange: props.onChange,
          value: props.value,
          disabled: props.disabled,
          fullWidth: props.fullWidth,
          id: props.id,
          inputProps: props.inputProps,
          InputLabelProps: props.InputLabelProps,
          InputProps: props.InputProps
        };
        if (validationResult !== undefined) {
          tmp.validationResult = Caml_option.valFromOption(validationResult);
        }
        if (onBlur !== undefined) {
          tmp.onBlur = Caml_option.valFromOption(onBlur);
        }
        if (classes !== undefined) {
          tmp.classes = Caml_option.valFromOption(classes);
        }
        if (label !== undefined) {
          tmp.label = Caml_option.valFromOption(label);
        }
        if (required !== undefined) {
          tmp.required = Caml_option.valFromOption(required);
        }
        if (placeholder !== undefined) {
          tmp.placeholder = Caml_option.valFromOption(placeholder);
        }
        if (maxLength !== undefined) {
          tmp.maxLength = maxLength;
        }
        if (helperTextWhenNoError !== undefined) {
          tmp.helperTextWhenNoError = Caml_option.valFromOption(helperTextWhenNoError);
        }
        return React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp);
      }),
    renderTags: (function (tagValue, getTagProps) {
        return Belt_Array.mapWithIndex(tagValue, (function (index, option) {
                      var props = Curry._1(getTagProps, {
                            index: index
                          });
                      var match;
                      match = options.TAG === /* Unlabeled */0 ? [
                          option,
                          option
                        ] : [
                          option.label,
                          option.value
                        ];
                      var value = match[1];
                      return React.createElement(Core.Chip, {
                                  avatar: props.avatar,
                                  classes: props.classes,
                                  className: props.className,
                                  clickable: props.clickable,
                                  color: props.color,
                                  component: props.component,
                                  deleteIcon: props.deleteIcon,
                                  disabled: Belt_Option.mapWithDefault(disabledValues, false, (function (disabledVals) {
                                          return Belt_Option.isSome(Belt_List.getBy(disabledVals, (function (x) {
                                                            return x === value;
                                                          })));
                                        })),
                                  icon: props.icon,
                                  label: match[0],
                                  onClick: props.onClick,
                                  onDelete: props.onDelete,
                                  onKeyDown: props.onKeyDown,
                                  onKeyUp: props.onKeyUp,
                                  size: props.size,
                                  variant: props.variant,
                                  id: props.id,
                                  style: Belt_Option.mapWithDefault(smallChips, props.style, (function (x) {
                                          if (x) {
                                            return Object.assign({}, {
                                                        maxHeight: "22px"
                                                      }, props.style);
                                          } else {
                                            return props.style;
                                          }
                                        })),
                                  key: props.key,
                                  ref: props.ref
                                });
                    }));
      }),
    value: tmp$5
  };
  if (autoSelect !== undefined) {
    tmp$6.autoSelect = autoSelect;
  }
  var tmp$7 = Belt_Option.mapWithDefault(smallChips, undefined, (function (x) {
          if (x) {
            return {
                    style: {
                      maxHeight: "22px"
                    }
                  };
          }
          
        }));
  if (tmp$7 !== undefined) {
    tmp$6.ChipProps = Caml_option.valFromOption(tmp$7);
  }
  if (className !== undefined) {
    tmp$6.className = className;
  }
  if (disableClearable !== undefined) {
    tmp$6.disableClearable = disableClearable;
  }
  if (disabled !== undefined) {
    tmp$6.disabled = disabled;
  }
  if (freeSolo !== undefined) {
    tmp$6.freeSolo = freeSolo;
  }
  if (fullWidth !== undefined) {
    tmp$6.fullWidth = fullWidth;
  }
  if (inputValue !== undefined) {
    tmp$6.inputValue = inputValue;
  }
  if (limitTags !== undefined) {
    tmp$6.limitTags = Caml_option.valFromOption(limitTags);
  }
  if (loading !== undefined) {
    tmp$6.loading = loading;
  }
  if (popupIcon !== undefined) {
    tmp$6.popupIcon = Caml_option.valFromOption(popupIcon);
  }
  return React.createElement(Lab.Autocomplete, tmp$6);
}

var make = App_AutocompleteFormInput;

export {
  make ,
}
/* react Not a pure module */
