// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Messages_Error$DvmAdminFrontendFr from "../intl/messages/Messages_Error.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../common/types/App_Types_Result.bs.js";

function isOk(request) {
  if (request.status >= 200) {
    return request.status < 300;
  } else {
    return false;
  }
}

function parseAndHandleError(resolve, errorResponseDecoder, response) {
  var error = Curry._1(errorResponseDecoder, response);
  if (error.TAG === /* Ok */0) {
    return resolve({
                TAG: /* ErrorResult */1,
                _0: error._0
              });
  } else {
    return resolve({
                TAG: /* ErrorResult */1,
                _0: App_Types_Result$DvmAdminFrontendFr.Rest.makeError(undefined, undefined, undefined, Messages_Error$DvmAdminFrontendFr.invalidBackEndResponse.id)
              });
  }
}

function parseAndHandleBlobResponse(resolve, errorResponseDecoder, request) {
  if (isOk(request)) {
    return resolve({
                TAG: /* OkResult */0,
                _0: request.response
              });
  } else {
    return parseAndHandleError(resolve, errorResponseDecoder, request.response);
  }
}

function parseAndHandleJsonResponse(resolve, responseDecoder, errorResponseDecoder, request) {
  var response = request.response;
  if (!isOk(request)) {
    return parseAndHandleError(resolve, errorResponseDecoder, response);
  }
  var responseBody = Curry._1(responseDecoder, response);
  if (responseBody.TAG === /* Ok */0) {
    return resolve({
                TAG: /* OkResult */0,
                _0: responseBody._0
              });
  }
  console.error("Invalid response from BE: error=", responseBody._0);
  return resolve({
              TAG: /* ErrorResult */1,
              _0: App_Types_Result$DvmAdminFrontendFr.Rest.makeError(undefined, undefined, undefined, Messages_Error$DvmAdminFrontendFr.invalidBackEndResponse.id)
            });
}

function handleJsonResponse(resolve, responseDecoder, request) {
  return parseAndHandleJsonResponse(resolve, responseDecoder, App_Types_Result$DvmAdminFrontendFr.Rest.error_decode, request);
}

function handleBlobResponse(resolve, request) {
  return parseAndHandleBlobResponse(resolve, App_Types_Result$DvmAdminFrontendFr.Rest.error_decode, request);
}

function handleResponse(resolve, request) {
  if (isOk(request)) {
    return resolve({
                TAG: /* OkResult */0,
                _0: undefined
              });
  } else {
    return parseAndHandleError(resolve, App_Types_Result$DvmAdminFrontendFr.Rest.error_decode, request.response);
  }
}

var WithEmptyResponse = {
  handleResponse: handleResponse
};

export {
  isOk ,
  parseAndHandleError ,
  parseAndHandleBlobResponse ,
  parseAndHandleJsonResponse ,
  handleJsonResponse ,
  handleBlobResponse ,
  WithEmptyResponse ,
}
/* No side effect */
