// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";

function dateOptionToStr(date) {
  return Belt_Option.mapWithDefault(date, "", (function (x) {
                if (App_Types_Date$DvmAdminFrontendFr.isValid(x)) {
                  return x.toISOString();
                } else {
                  return "";
                }
              }));
}

function handleDateNumber(form, formAdditionalSecond) {
  return Belt_List.length(Belt_List.keep({
                  hd: dateOptionToStr(form.input.orderDateStart),
                  tl: {
                    hd: dateOptionToStr(form.input.orderDateEnd),
                    tl: {
                      hd: dateOptionToStr(form.input.registrationDateStart),
                      tl: {
                        hd: dateOptionToStr(form.input.registrationDateEnd),
                        tl: {
                          hd: dateOptionToStr(form.input.customerInvoiceStart),
                          tl: {
                            hd: dateOptionToStr(form.input.customerInvoiceEnd),
                            tl: {
                              hd: dateOptionToStr(form.input.deliveryDateStart),
                              tl: {
                                hd: dateOptionToStr(form.input.deliveryDateEnd),
                                tl: {
                                  hd: form.input.delivered,
                                  tl: {
                                    hd: dateOptionToStr(form.input.renaultInvoiceDateStart),
                                    tl: {
                                      hd: dateOptionToStr(form.input.renaultInvoiceDateEnd),
                                      tl: {
                                        hd: form.input.registrationDateExists,
                                        tl: {
                                          hd: form.input.customerInvoiceExists,
                                          tl: {
                                            hd: form.input.renaultInvoiceExists,
                                            tl: {
                                              hd: dateOptionToStr(form.input.entryDateParcStart),
                                              tl: {
                                                hd: dateOptionToStr(form.input.entryDateParcEnd),
                                                tl: {
                                                  hd: dateOptionToStr(form.input.exitDateParcStart),
                                                  tl: {
                                                    hd: dateOptionToStr(form.input.exitDateParcEnd),
                                                    tl: {
                                                      hd: form.input.parcActive,
                                                      tl: {
                                                        hd: form.input.parcAutomatic,
                                                        tl: {
                                                          hd: dateOptionToStr(formAdditionalSecond.input.invoiceDateStart),
                                                          tl: {
                                                            hd: dateOptionToStr(formAdditionalSecond.input.invoiceDateEnd),
                                                            tl: {
                                                              hd: formAdditionalSecond.input.invoiceDateExists,
                                                              tl: {
                                                                hd: dateOptionToStr(formAdditionalSecond.input.preinvoiceDateStart),
                                                                tl: {
                                                                  hd: dateOptionToStr(formAdditionalSecond.input.preinvoiceDateEnd),
                                                                  tl: {
                                                                    hd: formAdditionalSecond.input.preinvoiceDateExists,
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }, (function (result) {
                    return result.length > 0;
                  })));
}

function handleCustomerNumber(form, formAdditional) {
  return Belt_List.length(Belt_List.keep({
                  hd: formAdditional.input.sirenClientFinalLLDName,
                  tl: {
                    hd: formAdditional.input.mainContractSIREN,
                    tl: {
                      hd: formAdditional.input.siren,
                      tl: {
                        hd: formAdditional.input.loueurID,
                        tl: {
                          hd: formAdditional.input.contractID,
                          tl: {
                            hd: form.input.customerName,
                            tl: {
                              hd: Belt_List.reduce(formAdditional.input.codeAPE, "", (function (a, b) {
                                      return a + b;
                                    })),
                              tl: {
                                hd: formAdditional.input.customerName,
                                tl: {
                                  hd: formAdditional.input.firstName,
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }, (function (result) {
                    return result.length > 0;
                  })));
}

function handleDealerNumber(form, formAdditional) {
  return Belt_List.length(Belt_List.keep({
                  hd: formAdditional.input.dealerName,
                  tl: {
                    hd: formAdditional.input.dealerGroup,
                    tl: {
                      hd: formAdditional.input.keyAccountManagerID,
                      tl: {
                        hd: formAdditional.input.sectorManagerID,
                        tl: {
                          hd: form.input.salesmanName,
                          tl: {
                            hd: form.input.agentCode,
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }, (function (result) {
                    return result.length > 0;
                  })));
}

function handleCarNumber(formAdditional, formAdditionalSecond) {
  return Belt_List.length(Belt_List.keep({
                  hd: Belt_List.reduce(formAdditional.input.modelID, "", (function (a, b) {
                          return a + b;
                        })),
                  tl: {
                    hd: Belt_List.reduce(formAdditional.input.versionID, "", (function (a, b) {
                            return a + b;
                          })),
                    tl: {
                      hd: Belt_List.reduce(formAdditional.input.categoryLabel, "", (function (a, b) {
                              return a + b;
                            })),
                      tl: {
                        hd: formAdditional.input.brand,
                        tl: {
                          hd: formAdditional.input.vehicleType,
                          tl: {
                            hd: formAdditional.input.phase,
                            tl: {
                              hd: Belt_List.reduce(formAdditional.input.engineType, "", (function (a, b) {
                                      return a + b;
                                    })),
                              tl: {
                                hd: Belt_List.reduce(formAdditionalSecond.input.orderStatus, "", (function (a, b) {
                                        return a + b;
                                      })),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }, (function (result) {
                    return result.length > 0;
                  })));
}

function handleVONumber(form) {
  return Belt_List.length(Belt_List.keep({
                  hd: form.input.brandUsedCar,
                  tl: {
                    hd: form.input.brandEmpty ? "true" : "",
                    tl: {
                      hd: form.input.modelUsedCar,
                      tl: {
                        hd: form.input.modelEmpty ? "true" : "",
                        tl: {
                          hd: form.input.vinUsedCar,
                          tl: {
                            hd: form.input.vinEmpty ? "true" : "",
                            tl: {
                              hd: form.input.usedCarPoliceNumber,
                              tl: {
                                hd: form.input.policeNumberEmpty ? "true" : "",
                                tl: {
                                  hd: form.input.usedCarRegistrationID,
                                  tl: {
                                    hd: form.input.registrationIDEmpty ? "true" : "",
                                    tl: {
                                      hd: dateOptionToStr(form.input.usedCarFirstReleaseDateStart),
                                      tl: {
                                        hd: dateOptionToStr(form.input.usedCarFirstReleaseDateEnd),
                                        tl: {
                                          hd: form.input.releaseDateEmpty ? "true" : "",
                                          tl: {
                                            hd: dateOptionToStr(form.input.usedCarLastRegistrationDateStart),
                                            tl: {
                                              hd: dateOptionToStr(form.input.usedCarLastRegistrationDateEnd),
                                              tl: {
                                                hd: form.input.registrationDateEmpty ? "true" : "",
                                                tl: {
                                                  hd: Belt_List.reduce(form.input.usedCarStatus, "", (function (a, b) {
                                                          return a + b;
                                                        })),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }, (function (result) {
                    return result.length > 0;
                  })));
}

function handleModalNumber(form, formAdditional, formModalVO, formAdditionalSecond) {
  return (((handleDateNumber(form, formAdditionalSecond) + handleCustomerNumber(form, formAdditional) | 0) + handleDealerNumber(form, formAdditional) | 0) + handleCarNumber(formAdditional, formAdditionalSecond) | 0) + handleVONumber(formModalVO) | 0;
}

function dateOptionToBool(date) {
  return Belt_Option.mapWithDefault(date, false, (function (x) {
                if (x.TAG === /* Ok */0) {
                  return false;
                } else {
                  return true;
                }
              }));
}

function datesIncorrect(form, formVO) {
  if (dateOptionToBool(form.orderDateStartResult) || dateOptionToBool(form.orderDateEndResult) || dateOptionToBool(form.registrationDateStartResult) || dateOptionToBool(form.registrationDateEndResult) || dateOptionToBool(form.customerInvoiceStartResult) || dateOptionToBool(form.customerInvoiceEndResult) || dateOptionToBool(form.deliveryDateStartResult) || dateOptionToBool(form.deliveryDateEndResult) || dateOptionToBool(form.renaultInvoiceDateStartResult) || dateOptionToBool(form.renaultInvoiceDateEndResult) || dateOptionToBool(formVO.usedCarFirstReleaseDateStartResult) || dateOptionToBool(formVO.usedCarFirstReleaseDateEndResult) || dateOptionToBool(formVO.usedCarLastRegistrationDateStartResult)) {
    return true;
  } else {
    return dateOptionToBool(formVO.usedCarLastRegistrationDateEndResult);
  }
}

export {
  dateOptionToStr ,
  handleDateNumber ,
  handleCustomerNumber ,
  handleDealerNumber ,
  handleCarNumber ,
  handleVONumber ,
  handleModalNumber ,
  dateOptionToBool ,
  datesIncorrect ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
