// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_SelectFormInput.bs.js";
import * as App_DebounceFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_DebounceFormInput.bs.js";
import * as Messages_Company_List$DvmAdminFrontendFr from "../../../intl/messages/company/Messages_Company_List.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_Company_Virtual$DvmAdminFrontendFr from "../../../intl/messages/company/Messages_Company_Virtual.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Company_Virtual_Search_Api$DvmAdminFrontendFr from "./Company_Virtual_Search_Api.bs.js";
import * as Company_Virtual_Search_Form$DvmAdminFrontendFr from "./Company_Virtual_Search_Form.bs.js";

function Company_Virtual_Search_Filter(Props) {
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var allSirets = Props.allSirets;
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var alert = ReactAlert.useAlert();
  var match$2 = React.useState(function () {
        return /* [] */0;
      });
  var setSirets = match$2[1];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setCities = match$3[1];
  var form = Company_Virtual_Search_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Company_Virtual_Search_Form$DvmAdminFrontendFr.initialState, (function (param, submissionCallbacks) {
          Curry._2(fetchData, submissionCallbacks, {
                page: 0,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: fetchRequest.headers,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                              hd: param.siret,
                              tl: {
                                hd: param.name,
                                tl: {
                                  hd: param.city,
                                  tl: {
                                    hd: param.endDate,
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  var callApi = function (filter) {
    Company_Virtual_Search_Api$DvmAdminFrontendFr.queryForSiretCities(alert, userManager, setCities, "true", selectedRole, filter);
  };
  React.useEffect((function () {
          if (typeof allSirets === "number") {
            allSirets === /* NotStarted */0;
          } else if (allSirets.TAG === /* Ok */0) {
            var sirets = allSirets._0;
            Curry._1(setSirets, (function (param) {
                    return Belt_List.map(sirets.content, (function (x) {
                                  return x.siret;
                                }));
                  }));
          }
          
        }), [allSirets]);
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    siret: input.siret,
                    name: input.name,
                    city: input.city,
                    active: input.active,
                    endDate: endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.endDateResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry._1(form.updateName, (function (input, name) {
                                    return {
                                            siret: input.siret,
                                            name: name,
                                            city: input.city,
                                            active: input.active,
                                            endDate: input.endDate
                                          };
                                  })),
                            value: form.input.name,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                            label: Messages_Common$DvmAdminFrontendFr.companyName
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Company_Virtual$DvmAdminFrontendFr.virtualSiren,
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.siret,
                                onChange: Curry._1(form.updateSiret, (function (input, siret) {
                                        return {
                                                siret: siret,
                                                name: input.name,
                                                city: input.city,
                                                active: input.active,
                                                endDate: input.endDate
                                              };
                                      }))
                              }
                            },
                            options: {
                              TAG: /* Unlabeled */0,
                              _0: match$2[0]
                            },
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DebounceFormInput$DvmAdminFrontendFr.make, {
                            onChange: (function (city) {
                                Curry._2(form.updateCity, (function (input, city) {
                                        return {
                                                siret: input.siret,
                                                name: input.name,
                                                city: city,
                                                active: input.active,
                                                endDate: input.endDate
                                              };
                                      }), city.trim());
                              }),
                            value: form.input.city,
                            values: match$3[0],
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                            callApi: callApi,
                            label: Messages_Common$DvmAdminFrontendFr.city,
                            debounceTriggerLength: 2
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Company_List$DvmAdminFrontendFr.active,
                            options: {
                              hd: {
                                label: {
                                  TAG: /* Message */0,
                                  _0: Messages_Common$DvmAdminFrontendFr.everything
                                },
                                value: ""
                              },
                              tl: {
                                hd: {
                                  label: {
                                    TAG: /* Message */0,
                                    _0: Messages_Common$DvmAdminFrontendFr.yes
                                  },
                                  value: "TRUE"
                                },
                                tl: {
                                  hd: {
                                    label: {
                                      TAG: /* Message */0,
                                      _0: Messages_Common$DvmAdminFrontendFr.no
                                    },
                                    value: "FALSE"
                                  },
                                  tl: /* [] */0
                                }
                              }
                            },
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.active,
                                onChange: Curry._1(form.updateActive, (function (input, active) {
                                        return {
                                                siret: input.siret,
                                                name: input.name,
                                                city: input.city,
                                                active: active,
                                                endDate: input.endDate
                                              };
                                      }))
                              }
                            },
                            id: "active",
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var Form;

var Api;

var InputStyles;

var make = Company_Virtual_Search_Filter;

export {
  Form ,
  Api ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
