// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Messages_Action_Referential$DvmAdminFrontendFr from "../../../intl/messages/actionReferential/Messages_Action_Referential.bs.js";

function fieldTypeToMessage(fieldType) {
  switch (fieldType) {
    case /* LocalActionTrigger */0 :
        return Messages_Action_Referential$DvmAdminFrontendFr.localActionTrigger;
    case /* ApplicationCorpChannel */1 :
        return Messages_Action_Referential$DvmAdminFrontendFr.appCorpChannel;
    case /* Brand */2 :
        return Messages_Action_Referential$DvmAdminFrontendFr.brandNoFrench;
    case /* DistributionMode */3 :
        return Messages_Action_Referential$DvmAdminFrontendFr.distributionMode;
    case /* ApplicationClientChannel */4 :
        return Messages_Action_Referential$DvmAdminFrontendFr.applicationClientChannel;
    case /* ApplicationSubChannel */5 :
        return Messages_Action_Referential$DvmAdminFrontendFr.appSubChannel;
    case /* MarketingCorpCategory */6 :
        return Messages_Action_Referential$DvmAdminFrontendFr.marketingCorpCategory;
    case /* MarketingCorpType */7 :
        return Messages_Action_Referential$DvmAdminFrontendFr.marketingCorpType;
    case /* VmeDesitnation */8 :
        return Messages_Action_Referential$DvmAdminFrontendFr.vmeDestination;
    
  }
}

function fieldTypeToEndpoint(fieldType) {
  switch (fieldType) {
    case /* LocalActionTrigger */0 :
        return "/actions/referential/local/trigger";
    case /* ApplicationCorpChannel */1 :
        return "/actions/referential/corp/channel";
    case /* Brand */2 :
        return "/actions/referential/brand";
    case /* DistributionMode */3 :
        return "/actions/referential/distribution/mode";
    case /* ApplicationClientChannel */4 :
        return "/actions/referential/client/channel";
    case /* ApplicationSubChannel */5 :
        return "/actions/referential/sub/channel";
    case /* MarketingCorpCategory */6 :
        return "/actions/referential/corp/category";
    case /* MarketingCorpType */7 :
        return "/actions/referential/corp/type";
    case /* VmeDesitnation */8 :
        return "/actions/referential/vme/destination";
    
  }
}

var availableFields = {
  hd: /* LocalActionTrigger */0,
  tl: {
    hd: /* ApplicationCorpChannel */1,
    tl: {
      hd: /* Brand */2,
      tl: {
        hd: /* DistributionMode */3,
        tl: {
          hd: /* ApplicationClientChannel */4,
          tl: {
            hd: /* ApplicationSubChannel */5,
            tl: {
              hd: /* MarketingCorpCategory */6,
              tl: {
                hd: /* MarketingCorpType */7,
                tl: {
                  hd: /* VmeDesitnation */8,
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    }
  }
};

export {
  availableFields ,
  fieldTypeToMessage ,
  fieldTypeToEndpoint ,
}
/* No side effect */
