// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../../../api/RestApi.bs.js";
import * as Flash_Edit_NonFleet_Types$DvmAdminFrontendFr from "../../../steps/NonFleet/Flash_Edit_NonFleet_Types.bs.js";

function saveCustomBonuses(userManager, selectedRole, setResult, customBonuses, flash, submissionCallbacks) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("PUT", {
          TAG: /* Json */0,
          _0: Flash_Edit_NonFleet_Types$DvmAdminFrontendFr.NewVehicles.t_encode(customBonuses)
        }, userManager, selectedRole, "/flash/map/modular/" + flash.actionId + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToSaveCustomVersions */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

export {
  Form ,
  saveCustomBonuses ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
