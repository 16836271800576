// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Flash_Types$DvmAdminFrontendFr from "../../../Flash_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as Flash_Edit_Types$DvmAdminFrontendFr from "../../Flash_Edit_Types.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_RadioFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_RadioFormInput.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Flash_Edit_DateInput$DvmAdminFrontendFr from "../Flash_Edit_DateInput.bs.js";
import * as Messages_Flash_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/flash/Messages_Flash_Detail.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Flash_Edit_Details_Dates$DvmAdminFrontendFr from "../Flash_Edit_Details_Dates.bs.js";
import * as Flash_Edit_Value_Percentage_Check$DvmAdminFrontendFr from "../Flash_Edit_Value_Percentage_Check.bs.js";

function Flash_Edit_Details_STOC(Props) {
  var form = Props.form;
  var formGeneralDates = Props.formGeneralDates;
  var budgets = Props.budgets;
  var isStepOpen = Props.isStepOpen;
  var match = form.input.stocDateType;
  var tmp;
  switch (match) {
    case /* MADA */0 :
        tmp = /* DealerFlag */35;
        break;
    case /* MADC */1 :
        tmp = /* Agreement */55;
        break;
    case /* NOTSET */2 :
        tmp = /* Nonexistent */71;
        break;
    
  }
  var match$1 = form.input.stocDateType;
  var tmp$1;
  switch (match$1) {
    case /* MADA */0 :
        tmp$1 = Messages_Flash_Detail$DvmAdminFrontendFr.madaPeriod;
        break;
    case /* MADC */1 :
        tmp$1 = Messages_Flash_Detail$DvmAdminFrontendFr.madcPeriod;
        break;
    case /* NOTSET */2 :
        tmp$1 = Messages_Common$DvmAdminFrontendFr.period;
        break;
    
  }
  var tmp$2 = {
    value: Flash_Edit_Types$DvmAdminFrontendFr.StocDate.toString(form.input.stocDateType),
    options: {
      hd: {
        label: {
          TAG: /* String */3,
          _0: "MADA"
        },
        value: "MADA"
      },
      tl: {
        hd: {
          label: {
            TAG: /* String */3,
            _0: "MADC"
          },
          value: "MADC"
        },
        tl: /* [] */0
      }
    },
    onChange: (function (value) {
        Curry._2(form.updateStocDateType, (function (input, stocDateType) {
                var newrecord = Caml_obj.obj_dup(input);
                newrecord.stocDateType = stocDateType;
                return newrecord;
              }), Belt_Option.getWithDefault(Flash_Edit_Types$DvmAdminFrontendFr.StocDate.fromString(value), /* NOTSET */2));
      }),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.extraLongInput
  };
  if (form.stocDateTypeResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.stocDateTypeResult);
  }
  var tmp$3 = {
    label: Messages_Flash_Edit$DvmAdminFrontendFr.bonusAmount,
    value: Belt_Option.mapWithDefault(form.input.numberType, "", Flash_Types$DvmAdminFrontendFr.ValueType.toString),
    options: {
      hd: {
        label: {
          TAG: /* Message */0,
          _0: Messages_Flash_Edit$DvmAdminFrontendFr.inPercent
        },
        value: "PERCENTAGE"
      },
      tl: {
        hd: {
          label: {
            TAG: /* Message */0,
            _0: Messages_Flash_Edit$DvmAdminFrontendFr.inEuro
          },
          value: "AMOUNT"
        },
        tl: /* [] */0
      }
    },
    onChange: (function (value) {
        Curry._2(form.updateNumberType, (function (input, numberType) {
                var newrecord = Caml_obj.obj_dup(input);
                newrecord.numberType = numberType;
                return newrecord;
              }), Flash_Types$DvmAdminFrontendFr.ValueType.fromString(value));
      }),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.extraLongInput
  };
  if (form.numberTypeResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.numberTypeResult);
  }
  var tmp$4 = {
    onChange: Curry._1(form.updateValue, (function (input, value) {
            var newrecord = Caml_obj.obj_dup(input);
            newrecord.value = value;
            return newrecord;
          })),
    value: form.input.value,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Flash_Edit$DvmAdminFrontendFr.bonusAmount,
    required: /* OnlyLabel */1,
    inputType: "number"
  };
  if (form.valueResult !== undefined) {
    tmp$4.validationResult = Caml_option.valFromOption(form.valueResult);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  spacing: 4
                }, React.createElement(Flash_Edit_Details_Dates$DvmAdminFrontendFr.make, {
                      form: form,
                      formGeneralDates: formGeneralDates,
                      budgets: budgets
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(Flash_Edit_DateInput$DvmAdminFrontendFr.make, {
                            icon: tmp,
                            message: tmp$1,
                            startDateValue: form.input.stocDateStart,
                            startDateOnChange: Curry._1(form.updateStocDateStart, (function (input, stocDateStart) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.stocDateStart = stocDateStart;
                                    return newrecord;
                                  })),
                            startDateResult: form.stocDateStartResult,
                            endDateValue: form.input.stocDateEnd,
                            endDateOnChange: Curry._1(form.updateStocDateEnd, (function (input, stocDateEnd) {
                                    var newrecord = Caml_obj.obj_dup(input);
                                    newrecord.stocDateEnd = stocDateEnd;
                                    return newrecord;
                                  })),
                            endDateResult: form.stocDateEndResult,
                            controller: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, tmp$2)
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      direction: "column",
                      item: true
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, tmp$3),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(Flash_Edit_Value_Percentage_Check$DvmAdminFrontendFr.make, {
                                children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$4),
                                form: form,
                                isStepOpen: isStepOpen
                              }),
                          container: true,
                          item: true
                        }))));
}

var Form;

var FormGeneralDates;

var Types;

var InputStyles;

var make = Flash_Edit_Details_STOC;

export {
  Form ,
  FormGeneralDates ,
  Types ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
