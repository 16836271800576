// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../common/utils/Utils_Api.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../common/utils/Utils_Form.bs.js";
import * as Model_Version_Types$DvmAdminFrontendFr from "./Model_Version_Types.bs.js";

var validators_additionDate = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.additionDate, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.dateToQueryParam(Model_Version_Types$DvmAdminFrontendFr.FieldsVersion.additionDate, date);
                  }));
    })
};

var validators_active = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: param.tableType ? Model_Version_Types$DvmAdminFrontendFr.FieldsVersion.versionActive : Model_Version_Types$DvmAdminFrontendFr.Fields.modelActive,
                    value: param.active
                  })
            };
    })
};

var validators_motorization = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      if (param.emptyMotorization) {
        return {
                TAG: /* Ok */0,
                _0: Model_Version_Types$DvmAdminFrontendFr.FilterFields.motorization + "=%5C%2CNC"
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                      fieldId: Model_Version_Types$DvmAdminFrontendFr.FilterFields.motorization,
                      value: encodeURIComponent(param.motorization)
                    })
              };
      }
    })
};

var validators_versionLabel = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(undefined, {
                    fieldId: Model_Version_Types$DvmAdminFrontendFr.FilterFields.versionLabel,
                    value: param.versionLabel
                  })
            };
    })
};

var validators_modelLabel = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(undefined, {
                    fieldId: Model_Version_Types$DvmAdminFrontendFr.FilterFields.modelLabel,
                    value: param.modelLabel
                  })
            };
    })
};

var validators_phase = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(undefined, {
                    fieldId: param.tableType ? Model_Version_Types$DvmAdminFrontendFr.FieldsVersion.versionVehiclePhase : Model_Version_Types$DvmAdminFrontendFr.Fields.modelVehiclePhase,
                    value: param.phase
                  })
            };
    })
};

var validators_version = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Model_Version_Types$DvmAdminFrontendFr.FilterFields.version,
                    value: param.version
                  })
            };
    })
};

var validators_model = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Model_Version_Types$DvmAdminFrontendFr.FilterFields.model,
                    value: param.model
                  })
            };
    })
};

var validators_brand = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Model_Version_Types$DvmAdminFrontendFr.FilterFields.brandDescription,
                    value: param.brand
                  })
            };
    })
};

var validators = {
  additionDate: validators_additionDate,
  tableType: undefined,
  active: validators_active,
  emptyMotorization: undefined,
  motorization: validators_motorization,
  versionLabel: validators_versionLabel,
  modelLabel: validators_modelLabel,
  phase: validators_phase,
  version: validators_version,
  model: validators_model,
  brand: validators_brand
};

function initialFieldsStatuses(_input) {
  return {
          additionDate: /* Pristine */0,
          tableType: /* Pristine */0,
          active: /* Pristine */0,
          emptyMotorization: /* Pristine */0,
          motorization: /* Pristine */0,
          versionLabel: /* Pristine */0,
          modelLabel: /* Pristine */0,
          phase: /* Pristine */0,
          version: /* Pristine */0,
          model: /* Pristine */0,
          brand: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.additionDate;
  var match_0 = match ? match._0 : Curry._1(validators.additionDate.validate, input);
  var match_0$1 = {
    TAG: /* Ok */0,
    _0: input.tableType
  };
  var match$1 = fieldsStatuses.active;
  var match_0$2 = match$1 ? match$1._0 : Curry._1(validators.active.validate, input);
  var match_0$3 = {
    TAG: /* Ok */0,
    _0: input.emptyMotorization
  };
  var match$2 = fieldsStatuses.motorization;
  var match_0$4 = match$2 ? match$2._0 : Curry._1(validators.motorization.validate, input);
  var match$3 = fieldsStatuses.versionLabel;
  var match_0$5 = match$3 ? match$3._0 : Curry._1(validators.versionLabel.validate, input);
  var match$4 = fieldsStatuses.modelLabel;
  var match_0$6 = match$4 ? match$4._0 : Curry._1(validators.modelLabel.validate, input);
  var match$5 = fieldsStatuses.phase;
  var match_0$7 = match$5 ? match$5._0 : Curry._1(validators.phase.validate, input);
  var match$6 = fieldsStatuses.version;
  var match_0$8 = match$6 ? match$6._0 : Curry._1(validators.version.validate, input);
  var match$7 = fieldsStatuses.model;
  var match_0$9 = match$7 ? match$7._0 : Curry._1(validators.model.validate, input);
  var match$8 = fieldsStatuses.brand;
  var match_0$10 = match$8 ? match$8._0 : Curry._1(validators.brand.validate, input);
  var additionDateResult = match_0;
  var additionDateResult$1;
  if (additionDateResult.TAG === /* Ok */0) {
    var tableTypeResult = match_0$1;
    if (tableTypeResult.TAG === /* Ok */0) {
      var activeResult = match_0$2;
      if (activeResult.TAG === /* Ok */0) {
        var emptyMotorizationResult = match_0$3;
        if (emptyMotorizationResult.TAG === /* Ok */0) {
          var motorizationResult = match_0$4;
          if (motorizationResult.TAG === /* Ok */0) {
            var versionLabelResult = match_0$5;
            if (versionLabelResult.TAG === /* Ok */0) {
              var modelLabelResult = match_0$6;
              if (modelLabelResult.TAG === /* Ok */0) {
                var phaseResult = match_0$7;
                if (phaseResult.TAG === /* Ok */0) {
                  var versionResult = match_0$8;
                  if (versionResult.TAG === /* Ok */0) {
                    var modelResult = match_0$9;
                    if (modelResult.TAG === /* Ok */0) {
                      var brandResult = match_0$10;
                      if (brandResult.TAG === /* Ok */0) {
                        return {
                                TAG: /* Valid */0,
                                output: {
                                  brand: brandResult._0,
                                  model: modelResult._0,
                                  version: versionResult._0,
                                  phase: phaseResult._0,
                                  modelLabel: modelLabelResult._0,
                                  versionLabel: versionLabelResult._0,
                                  motorization: motorizationResult._0,
                                  emptyMotorization: emptyMotorizationResult._0,
                                  active: activeResult._0,
                                  tableType: tableTypeResult._0,
                                  additionDate: additionDateResult._0
                                },
                                fieldsStatuses: {
                                  additionDate: /* Dirty */{
                                    _0: additionDateResult,
                                    _1: /* Shown */0
                                  },
                                  tableType: /* Dirty */{
                                    _0: tableTypeResult,
                                    _1: /* Hidden */1
                                  },
                                  active: /* Dirty */{
                                    _0: activeResult,
                                    _1: /* Shown */0
                                  },
                                  emptyMotorization: /* Dirty */{
                                    _0: emptyMotorizationResult,
                                    _1: /* Hidden */1
                                  },
                                  motorization: /* Dirty */{
                                    _0: motorizationResult,
                                    _1: /* Shown */0
                                  },
                                  versionLabel: /* Dirty */{
                                    _0: versionLabelResult,
                                    _1: /* Shown */0
                                  },
                                  modelLabel: /* Dirty */{
                                    _0: modelLabelResult,
                                    _1: /* Shown */0
                                  },
                                  phase: /* Dirty */{
                                    _0: phaseResult,
                                    _1: /* Shown */0
                                  },
                                  version: /* Dirty */{
                                    _0: versionResult,
                                    _1: /* Shown */0
                                  },
                                  model: /* Dirty */{
                                    _0: modelResult,
                                    _1: /* Shown */0
                                  },
                                  brand: /* Dirty */{
                                    _0: brandResult,
                                    _1: /* Shown */0
                                  }
                                },
                                collectionsStatuses: undefined
                              };
                      }
                      additionDateResult$1 = additionDateResult;
                    } else {
                      additionDateResult$1 = additionDateResult;
                    }
                  } else {
                    additionDateResult$1 = additionDateResult;
                  }
                } else {
                  additionDateResult$1 = additionDateResult;
                }
              } else {
                additionDateResult$1 = additionDateResult;
              }
            } else {
              additionDateResult$1 = additionDateResult;
            }
          } else {
            additionDateResult$1 = additionDateResult;
          }
        } else {
          additionDateResult$1 = additionDateResult;
        }
      } else {
        additionDateResult$1 = additionDateResult;
      }
    } else {
      additionDateResult$1 = additionDateResult;
    }
  } else {
    additionDateResult$1 = additionDateResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            additionDate: /* Dirty */{
              _0: additionDateResult$1,
              _1: /* Shown */0
            },
            tableType: /* Dirty */{
              _0: match_0$1,
              _1: /* Hidden */1
            },
            active: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            emptyMotorization: /* Dirty */{
              _0: match_0$3,
              _1: /* Hidden */1
            },
            motorization: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            versionLabel: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            modelLabel: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            phase: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            },
            version: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            },
            model: /* Dirty */{
              _0: match_0$9,
              _1: /* Shown */0
            },
            brand: /* Dirty */{
              _0: match_0$10,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurAdditionDateField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.additionDate, validators_additionDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  additionDate: status,
                                  tableType: init.tableType,
                                  active: init.active,
                                  emptyMotorization: init.emptyMotorization,
                                  motorization: init.motorization,
                                  versionLabel: init.versionLabel,
                                  modelLabel: init.modelLabel,
                                  phase: init.phase,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurTableTypeField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithoutValidator(state.input.tableType, state.fieldsStatuses.tableType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  additionDate: init.additionDate,
                                  tableType: status,
                                  active: init.active,
                                  emptyMotorization: init.emptyMotorization,
                                  motorization: init.motorization,
                                  versionLabel: init.versionLabel,
                                  modelLabel: init.modelLabel,
                                  phase: init.phase,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurActiveField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.active, validators_active, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  additionDate: init.additionDate,
                                  tableType: init.tableType,
                                  active: status,
                                  emptyMotorization: init.emptyMotorization,
                                  motorization: init.motorization,
                                  versionLabel: init.versionLabel,
                                  modelLabel: init.modelLabel,
                                  phase: init.phase,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEmptyMotorizationField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithoutValidator(state.input.emptyMotorization, state.fieldsStatuses.emptyMotorization, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  additionDate: init.additionDate,
                                  tableType: init.tableType,
                                  active: init.active,
                                  emptyMotorization: status,
                                  motorization: init.motorization,
                                  versionLabel: init.versionLabel,
                                  modelLabel: init.modelLabel,
                                  phase: init.phase,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurMotorizationField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.motorization, validators_motorization, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  additionDate: init.additionDate,
                                  tableType: init.tableType,
                                  active: init.active,
                                  emptyMotorization: init.emptyMotorization,
                                  motorization: status,
                                  versionLabel: init.versionLabel,
                                  modelLabel: init.modelLabel,
                                  phase: init.phase,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionLabelField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.versionLabel, validators_versionLabel, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  additionDate: init.additionDate,
                                  tableType: init.tableType,
                                  active: init.active,
                                  emptyMotorization: init.emptyMotorization,
                                  motorization: init.motorization,
                                  versionLabel: status,
                                  modelLabel: init.modelLabel,
                                  phase: init.phase,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurModelLabelField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.modelLabel, validators_modelLabel, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  additionDate: init.additionDate,
                                  tableType: init.tableType,
                                  active: init.active,
                                  emptyMotorization: init.emptyMotorization,
                                  motorization: init.motorization,
                                  versionLabel: init.versionLabel,
                                  modelLabel: status,
                                  phase: init.phase,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPhaseField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.phase, validators_phase, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  additionDate: init.additionDate,
                                  tableType: init.tableType,
                                  active: init.active,
                                  emptyMotorization: init.emptyMotorization,
                                  motorization: init.motorization,
                                  versionLabel: init.versionLabel,
                                  modelLabel: init.modelLabel,
                                  phase: status,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.version, validators_version, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  additionDate: init.additionDate,
                                  tableType: init.tableType,
                                  active: init.active,
                                  emptyMotorization: init.emptyMotorization,
                                  motorization: init.motorization,
                                  versionLabel: init.versionLabel,
                                  modelLabel: init.modelLabel,
                                  phase: init.phase,
                                  version: status,
                                  model: init.model,
                                  brand: init.brand
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurModelField */9 :
                  var result$9 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.model, validators_model, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  additionDate: init.additionDate,
                                  tableType: init.tableType,
                                  active: init.active,
                                  emptyMotorization: init.emptyMotorization,
                                  motorization: init.motorization,
                                  versionLabel: init.versionLabel,
                                  modelLabel: init.modelLabel,
                                  phase: init.phase,
                                  version: init.version,
                                  model: status,
                                  brand: init.brand
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBrandField */10 :
                  var result$10 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.brand, validators_brand, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  additionDate: init.additionDate,
                                  tableType: init.tableType,
                                  active: init.active,
                                  emptyMotorization: init.emptyMotorization,
                                  motorization: init.motorization,
                                  versionLabel: init.versionLabel,
                                  modelLabel: init.modelLabel,
                                  phase: init.phase,
                                  version: init.version,
                                  model: init.model,
                                  brand: status
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */11 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */11,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */12,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */14);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */13);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */12 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */13 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */14 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateAdditionDateField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.additionDate, state.submissionStatus, validators_additionDate, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            additionDate: status,
                                            tableType: init.tableType,
                                            active: init.active,
                                            emptyMotorization: init.emptyMotorization,
                                            motorization: init.motorization,
                                            versionLabel: init.versionLabel,
                                            modelLabel: init.modelLabel,
                                            phase: init.phase,
                                            version: init.version,
                                            model: init.model,
                                            brand: init.brand
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateTableTypeField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$11 = Formality.validateDependentFieldOnChange(nextInput$1, nextFieldsStatuses.contents.phase, validators_phase, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  additionDate: init.additionDate,
                                  tableType: init.tableType,
                                  active: init.active,
                                  emptyMotorization: init.emptyMotorization,
                                  motorization: init.motorization,
                                  versionLabel: init.versionLabel,
                                  modelLabel: init.modelLabel,
                                  phase: status,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand
                                };
                        }));
                  if (result$11 !== undefined) {
                    nextFieldsStatuses.contents = result$11;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$1.tableType, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            additionDate: init.additionDate,
                                            tableType: status,
                                            active: init.active,
                                            emptyMotorization: init.emptyMotorization,
                                            motorization: init.motorization,
                                            versionLabel: init.versionLabel,
                                            modelLabel: init.modelLabel,
                                            phase: init.phase,
                                            version: init.version,
                                            model: init.model,
                                            brand: init.brand
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateActiveField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.active, state.submissionStatus, validators_active, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            additionDate: init.additionDate,
                                            tableType: init.tableType,
                                            active: status,
                                            emptyMotorization: init.emptyMotorization,
                                            motorization: init.motorization,
                                            versionLabel: init.versionLabel,
                                            modelLabel: init.modelLabel,
                                            phase: init.phase,
                                            version: init.version,
                                            model: init.model,
                                            brand: init.brand
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEmptyMotorizationField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$12 = Formality.validateDependentFieldOnChange(nextInput$3, nextFieldsStatuses$1.contents.motorization, validators_motorization, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  additionDate: init.additionDate,
                                  tableType: init.tableType,
                                  active: init.active,
                                  emptyMotorization: init.emptyMotorization,
                                  motorization: status,
                                  versionLabel: init.versionLabel,
                                  modelLabel: init.modelLabel,
                                  phase: init.phase,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand
                                };
                        }));
                  if (result$12 !== undefined) {
                    nextFieldsStatuses$1.contents = result$12;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$3.emptyMotorization, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            additionDate: init.additionDate,
                                            tableType: init.tableType,
                                            active: init.active,
                                            emptyMotorization: status,
                                            motorization: init.motorization,
                                            versionLabel: init.versionLabel,
                                            modelLabel: init.modelLabel,
                                            phase: init.phase,
                                            version: init.version,
                                            model: init.model,
                                            brand: init.brand
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateMotorizationField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.motorization, state.submissionStatus, validators_motorization, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            additionDate: init.additionDate,
                                            tableType: init.tableType,
                                            active: init.active,
                                            emptyMotorization: init.emptyMotorization,
                                            motorization: status,
                                            versionLabel: init.versionLabel,
                                            modelLabel: init.modelLabel,
                                            phase: init.phase,
                                            version: init.version,
                                            model: init.model,
                                            brand: init.brand
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionLabelField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.versionLabel, state.submissionStatus, validators_versionLabel, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            additionDate: init.additionDate,
                                            tableType: init.tableType,
                                            active: init.active,
                                            emptyMotorization: init.emptyMotorization,
                                            motorization: init.motorization,
                                            versionLabel: status,
                                            modelLabel: init.modelLabel,
                                            phase: init.phase,
                                            version: init.version,
                                            model: init.model,
                                            brand: init.brand
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateModelLabelField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.modelLabel, state.submissionStatus, validators_modelLabel, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            additionDate: init.additionDate,
                                            tableType: init.tableType,
                                            active: init.active,
                                            emptyMotorization: init.emptyMotorization,
                                            motorization: init.motorization,
                                            versionLabel: init.versionLabel,
                                            modelLabel: status,
                                            phase: init.phase,
                                            version: init.version,
                                            model: init.model,
                                            brand: init.brand
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePhaseField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$7, state.fieldsStatuses.phase, state.submissionStatus, validators_phase, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            additionDate: init.additionDate,
                                            tableType: init.tableType,
                                            active: init.active,
                                            emptyMotorization: init.emptyMotorization,
                                            motorization: init.motorization,
                                            versionLabel: init.versionLabel,
                                            modelLabel: init.modelLabel,
                                            phase: status,
                                            version: init.version,
                                            model: init.model,
                                            brand: init.brand
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$8, state.fieldsStatuses.version, state.submissionStatus, validators_version, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            additionDate: init.additionDate,
                                            tableType: init.tableType,
                                            active: init.active,
                                            emptyMotorization: init.emptyMotorization,
                                            motorization: init.motorization,
                                            versionLabel: init.versionLabel,
                                            modelLabel: init.modelLabel,
                                            phase: init.phase,
                                            version: status,
                                            model: init.model,
                                            brand: init.brand
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateModelField */9 :
                  var nextInput$9 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$9, state.fieldsStatuses.model, state.submissionStatus, validators_model, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            additionDate: init.additionDate,
                                            tableType: init.tableType,
                                            active: init.active,
                                            emptyMotorization: init.emptyMotorization,
                                            motorization: init.motorization,
                                            versionLabel: init.versionLabel,
                                            modelLabel: init.modelLabel,
                                            phase: init.phase,
                                            version: init.version,
                                            model: status,
                                            brand: init.brand
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBrandField */10 :
                  var nextInput$10 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$10, state.fieldsStatuses.brand, state.submissionStatus, validators_brand, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            additionDate: init.additionDate,
                                            tableType: init.tableType,
                                            active: init.active,
                                            emptyMotorization: init.emptyMotorization,
                                            motorization: init.motorization,
                                            versionLabel: init.versionLabel,
                                            modelLabel: init.modelLabel,
                                            phase: init.phase,
                                            version: init.version,
                                            model: init.model,
                                            brand: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */11 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */12 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */13 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateAdditionDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAdditionDateField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateTableType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateTableTypeField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateActive: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateActiveField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEmptyMotorization: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEmptyMotorizationField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateMotorization: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateMotorizationField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVersionLabel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionLabelField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateModelLabel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelLabelField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updatePhase: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePhaseField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVersion: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateModel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBrand: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBrandField */10,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurAdditionDate: (function (param) {
              Curry._1(dispatch, /* BlurAdditionDateField */0);
            }),
          blurTableType: (function (param) {
              Curry._1(dispatch, /* BlurTableTypeField */1);
            }),
          blurActive: (function (param) {
              Curry._1(dispatch, /* BlurActiveField */2);
            }),
          blurEmptyMotorization: (function (param) {
              Curry._1(dispatch, /* BlurEmptyMotorizationField */3);
            }),
          blurMotorization: (function (param) {
              Curry._1(dispatch, /* BlurMotorizationField */4);
            }),
          blurVersionLabel: (function (param) {
              Curry._1(dispatch, /* BlurVersionLabelField */5);
            }),
          blurModelLabel: (function (param) {
              Curry._1(dispatch, /* BlurModelLabelField */6);
            }),
          blurPhase: (function (param) {
              Curry._1(dispatch, /* BlurPhaseField */7);
            }),
          blurVersion: (function (param) {
              Curry._1(dispatch, /* BlurVersionField */8);
            }),
          blurModel: (function (param) {
              Curry._1(dispatch, /* BlurModelField */9);
            }),
          blurBrand: (function (param) {
              Curry._1(dispatch, /* BlurBrandField */10);
            }),
          additionDateResult: Formality.exposeFieldResult(state.fieldsStatuses.additionDate),
          tableTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.tableType),
          activeResult: Formality.exposeFieldResult(state.fieldsStatuses.active),
          emptyMotorizationResult: Formality.exposeFieldResult(state.fieldsStatuses.emptyMotorization),
          motorizationResult: Formality.exposeFieldResult(state.fieldsStatuses.motorization),
          versionLabelResult: Formality.exposeFieldResult(state.fieldsStatuses.versionLabel),
          modelLabelResult: Formality.exposeFieldResult(state.fieldsStatuses.modelLabel),
          phaseResult: Formality.exposeFieldResult(state.fieldsStatuses.phase),
          versionResult: Formality.exposeFieldResult(state.fieldsStatuses.version),
          modelResult: Formality.exposeFieldResult(state.fieldsStatuses.model),
          brandResult: Formality.exposeFieldResult(state.fieldsStatuses.brand),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.additionDate) {
                return true;
              }
              if (!match.tableType && !match.active && !match.emptyMotorization && !match.motorization && !match.versionLabel && !match.modelLabel && !match.phase && !match.version && !match.model && !match.brand) {
                return false;
              }
              return true;
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */11);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */12);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */13);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */13,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */14);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  brand: "",
  model: "",
  version: "",
  phase: "",
  modelLabel: "",
  versionLabel: "",
  motorization: "",
  emptyMotorization: false,
  active: "",
  tableType: /* Model */0,
  additionDate: undefined
};

export {
  TableFilterForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
