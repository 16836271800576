// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../common/utils/Utils_Api.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../common/utils/Utils_Form.bs.js";
import * as Quota_View_Types$DvmAdminFrontendFr from "./Quota_View_Types.bs.js";

var validators_quotaEndYear = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.quotaEndYear, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.dateYearToQueryParam(Quota_View_Types$DvmAdminFrontendFr.FilterFields.quotaEndYear, date);
                  }));
    })
};

var validators_quotaStartYear = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateRequiredDate(param.quotaStartYear, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.dateYearToQueryParam(Quota_View_Types$DvmAdminFrontendFr.FilterFields.quotaStartYear, Caml_option.some(date));
                  }));
    })
};

var validators_siret = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Quota_View_Types$DvmAdminFrontendFr.FilterFields.siret,
                    value: param.siret
                  })
            };
    })
};

var validators_contractLabel = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Quota_View_Types$DvmAdminFrontendFr.FilterFields.contractLabel,
                    value: param.contractLabel
                  })
            };
    })
};

var validators_contractId = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(Belt_Option.getWithDefault(Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                          fieldId: Quota_View_Types$DvmAdminFrontendFr.FilterFields.contractId,
                          value: param.contractId
                        }), ""));
    })
};

var validators_dealerId = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParam(Quota_View_Types$DvmAdminFrontendFr.FilterFields.dealerId, param.dealerId)
            };
    })
};

var validators_dr = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParam(Quota_View_Types$DvmAdminFrontendFr.FilterFields.dr, param.dr)
            };
    })
};

var validators = {
  quotaEndYear: validators_quotaEndYear,
  quotaStartYear: validators_quotaStartYear,
  siret: validators_siret,
  contractLabel: validators_contractLabel,
  contractId: validators_contractId,
  dealerId: validators_dealerId,
  dr: validators_dr
};

function initialFieldsStatuses(_input) {
  return {
          quotaEndYear: /* Pristine */0,
          quotaStartYear: /* Pristine */0,
          siret: /* Pristine */0,
          contractLabel: /* Pristine */0,
          contractId: /* Pristine */0,
          dealerId: /* Pristine */0,
          dr: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.quotaEndYear;
  var match_0 = match ? match._0 : Curry._1(validators.quotaEndYear.validate, input);
  var match$1 = fieldsStatuses.quotaStartYear;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.quotaStartYear.validate, input);
  var match$2 = fieldsStatuses.siret;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.siret.validate, input);
  var match$3 = fieldsStatuses.contractLabel;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.contractLabel.validate, input);
  var match$4 = fieldsStatuses.contractId;
  var match_0$4 = match$4 ? match$4._0 : Curry._1(validators.contractId.validate, input);
  var match$5 = fieldsStatuses.dealerId;
  var match_0$5 = match$5 ? match$5._0 : Curry._1(validators.dealerId.validate, input);
  var match$6 = fieldsStatuses.dr;
  var match_0$6 = match$6 ? match$6._0 : Curry._1(validators.dr.validate, input);
  var quotaEndYearResult = match_0;
  var quotaEndYearResult$1;
  if (quotaEndYearResult.TAG === /* Ok */0) {
    var quotaStartYearResult = match_0$1;
    if (quotaStartYearResult.TAG === /* Ok */0) {
      var siretResult = match_0$2;
      if (siretResult.TAG === /* Ok */0) {
        var contractLabelResult = match_0$3;
        if (contractLabelResult.TAG === /* Ok */0) {
          var contractIdResult = match_0$4;
          if (contractIdResult.TAG === /* Ok */0) {
            var dealerIdResult = match_0$5;
            if (dealerIdResult.TAG === /* Ok */0) {
              var drResult = match_0$6;
              if (drResult.TAG === /* Ok */0) {
                return {
                        TAG: /* Valid */0,
                        output: {
                          dr: drResult._0,
                          dealerId: dealerIdResult._0,
                          contractId: contractIdResult._0,
                          contractLabel: contractLabelResult._0,
                          siret: siretResult._0,
                          quotaStartYear: quotaStartYearResult._0,
                          quotaEndYear: quotaEndYearResult._0
                        },
                        fieldsStatuses: {
                          quotaEndYear: /* Dirty */{
                            _0: quotaEndYearResult,
                            _1: /* Shown */0
                          },
                          quotaStartYear: /* Dirty */{
                            _0: quotaStartYearResult,
                            _1: /* Shown */0
                          },
                          siret: /* Dirty */{
                            _0: siretResult,
                            _1: /* Shown */0
                          },
                          contractLabel: /* Dirty */{
                            _0: contractLabelResult,
                            _1: /* Shown */0
                          },
                          contractId: /* Dirty */{
                            _0: contractIdResult,
                            _1: /* Shown */0
                          },
                          dealerId: /* Dirty */{
                            _0: dealerIdResult,
                            _1: /* Shown */0
                          },
                          dr: /* Dirty */{
                            _0: drResult,
                            _1: /* Shown */0
                          }
                        },
                        collectionsStatuses: undefined
                      };
              }
              quotaEndYearResult$1 = quotaEndYearResult;
            } else {
              quotaEndYearResult$1 = quotaEndYearResult;
            }
          } else {
            quotaEndYearResult$1 = quotaEndYearResult;
          }
        } else {
          quotaEndYearResult$1 = quotaEndYearResult;
        }
      } else {
        quotaEndYearResult$1 = quotaEndYearResult;
      }
    } else {
      quotaEndYearResult$1 = quotaEndYearResult;
    }
  } else {
    quotaEndYearResult$1 = quotaEndYearResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            quotaEndYear: /* Dirty */{
              _0: quotaEndYearResult$1,
              _1: /* Shown */0
            },
            quotaStartYear: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            siret: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            contractLabel: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            contractId: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            dealerId: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            dr: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurQuotaEndYearField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.quotaEndYear, validators_quotaEndYear, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  quotaEndYear: status,
                                  quotaStartYear: init.quotaStartYear,
                                  siret: init.siret,
                                  contractLabel: init.contractLabel,
                                  contractId: init.contractId,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurQuotaStartYearField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.quotaStartYear, validators_quotaStartYear, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  quotaEndYear: init.quotaEndYear,
                                  quotaStartYear: status,
                                  siret: init.siret,
                                  contractLabel: init.contractLabel,
                                  contractId: init.contractId,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSiretField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.siret, validators_siret, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  quotaEndYear: init.quotaEndYear,
                                  quotaStartYear: init.quotaStartYear,
                                  siret: status,
                                  contractLabel: init.contractLabel,
                                  contractId: init.contractId,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurContractLabelField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.contractLabel, validators_contractLabel, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  quotaEndYear: init.quotaEndYear,
                                  quotaStartYear: init.quotaStartYear,
                                  siret: init.siret,
                                  contractLabel: status,
                                  contractId: init.contractId,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurContractIdField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.contractId, validators_contractId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  quotaEndYear: init.quotaEndYear,
                                  quotaStartYear: init.quotaStartYear,
                                  siret: init.siret,
                                  contractLabel: init.contractLabel,
                                  contractId: status,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDealerIdField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.dealerId, validators_dealerId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  quotaEndYear: init.quotaEndYear,
                                  quotaStartYear: init.quotaStartYear,
                                  siret: init.siret,
                                  contractLabel: init.contractLabel,
                                  contractId: init.contractId,
                                  dealerId: status,
                                  dr: init.dr
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDrField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.dr, validators_dr, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  quotaEndYear: init.quotaEndYear,
                                  quotaStartYear: init.quotaStartYear,
                                  siret: init.siret,
                                  contractLabel: init.contractLabel,
                                  contractId: init.contractId,
                                  dealerId: init.dealerId,
                                  dr: status
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */7 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */7,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */8,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */10);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */9);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */8 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */9 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */10 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateQuotaEndYearField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.quotaEndYear, state.submissionStatus, validators_quotaEndYear, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            quotaEndYear: status,
                                            quotaStartYear: init.quotaStartYear,
                                            siret: init.siret,
                                            contractLabel: init.contractLabel,
                                            contractId: init.contractId,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateQuotaStartYearField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.quotaStartYear, state.submissionStatus, validators_quotaStartYear, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            quotaEndYear: init.quotaEndYear,
                                            quotaStartYear: status,
                                            siret: init.siret,
                                            contractLabel: init.contractLabel,
                                            contractId: init.contractId,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSiretField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.siret, state.submissionStatus, validators_siret, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            quotaEndYear: init.quotaEndYear,
                                            quotaStartYear: init.quotaStartYear,
                                            siret: status,
                                            contractLabel: init.contractLabel,
                                            contractId: init.contractId,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateContractLabelField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.contractLabel, state.submissionStatus, validators_contractLabel, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            quotaEndYear: init.quotaEndYear,
                                            quotaStartYear: init.quotaStartYear,
                                            siret: init.siret,
                                            contractLabel: status,
                                            contractId: init.contractId,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateContractIdField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.contractId, state.submissionStatus, validators_contractId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            quotaEndYear: init.quotaEndYear,
                                            quotaStartYear: init.quotaStartYear,
                                            siret: init.siret,
                                            contractLabel: init.contractLabel,
                                            contractId: status,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDealerIdField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.dealerId, state.submissionStatus, validators_dealerId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            quotaEndYear: init.quotaEndYear,
                                            quotaStartYear: init.quotaStartYear,
                                            siret: init.siret,
                                            contractLabel: init.contractLabel,
                                            contractId: init.contractId,
                                            dealerId: status,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDrField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.dr, state.submissionStatus, validators_dr, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            quotaEndYear: init.quotaEndYear,
                                            quotaStartYear: init.quotaStartYear,
                                            siret: init.siret,
                                            contractLabel: init.contractLabel,
                                            contractId: init.contractId,
                                            dealerId: init.dealerId,
                                            dr: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */7 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */8 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */9 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateQuotaEndYear: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateQuotaEndYearField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateQuotaStartYear: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateQuotaStartYearField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateSiret: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSiretField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateContractLabel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateContractLabelField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateContractId: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateContractIdField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDealerId: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDealerIdField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDr: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDrField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurQuotaEndYear: (function (param) {
              Curry._1(dispatch, /* BlurQuotaEndYearField */0);
            }),
          blurQuotaStartYear: (function (param) {
              Curry._1(dispatch, /* BlurQuotaStartYearField */1);
            }),
          blurSiret: (function (param) {
              Curry._1(dispatch, /* BlurSiretField */2);
            }),
          blurContractLabel: (function (param) {
              Curry._1(dispatch, /* BlurContractLabelField */3);
            }),
          blurContractId: (function (param) {
              Curry._1(dispatch, /* BlurContractIdField */4);
            }),
          blurDealerId: (function (param) {
              Curry._1(dispatch, /* BlurDealerIdField */5);
            }),
          blurDr: (function (param) {
              Curry._1(dispatch, /* BlurDrField */6);
            }),
          quotaEndYearResult: Formality.exposeFieldResult(state.fieldsStatuses.quotaEndYear),
          quotaStartYearResult: Formality.exposeFieldResult(state.fieldsStatuses.quotaStartYear),
          siretResult: Formality.exposeFieldResult(state.fieldsStatuses.siret),
          contractLabelResult: Formality.exposeFieldResult(state.fieldsStatuses.contractLabel),
          contractIdResult: Formality.exposeFieldResult(state.fieldsStatuses.contractId),
          dealerIdResult: Formality.exposeFieldResult(state.fieldsStatuses.dealerId),
          drResult: Formality.exposeFieldResult(state.fieldsStatuses.dr),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.quotaEndYear || match.quotaStartYear || match.siret || match.contractLabel || match.contractId || match.dealerId || match.dr) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */7);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */8);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */9);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */9,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */10);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  dr: /* [] */0,
  dealerId: /* [] */0,
  contractId: "",
  contractLabel: "",
  siret: "",
  quotaStartYear: undefined,
  quotaEndYear: undefined
};

export {
  TableFilterForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
