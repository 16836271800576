// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../../../../../common/utils/Utils_Api.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../../../../../common/utils/Utils_Form.bs.js";
import * as Flash_Edit_Versions_Types$DvmAdminFrontendFr from "../../../../Flash_Edit_Versions_Types.bs.js";
import * as Flash_Edit_Version_Perf_Utils$DvmAdminFrontendFr from "../../Flash_Edit_Version_Perf_Utils.bs.js";

var validators_versionName = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(undefined, {
                    fieldId: Flash_Edit_Versions_Types$DvmAdminFrontendFr.Fields.versionName,
                    value: param.versionName
                  })
            };
    })
};

var validators_version = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(undefined, {
                    fieldId: Flash_Edit_Versions_Types$DvmAdminFrontendFr.Fields.version,
                    value: param.version
                  })
            };
    })
};

var validators_category = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(undefined, {
                    fieldId: Flash_Edit_Versions_Types$DvmAdminFrontendFr.Fields.category,
                    value: param.category
                  })
            };
    })
};

var validators_model = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Flash_Edit_Versions_Types$DvmAdminFrontendFr.Fields.model,
                    value: param.model
                  })
            };
    })
};

var validators = {
  versionName: validators_versionName,
  version: validators_version,
  category: validators_category,
  model: validators_model
};

function initialFieldsStatuses(_input) {
  return {
          versionName: /* Pristine */0,
          version: /* Pristine */0,
          category: /* Pristine */0,
          model: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            versionName: /* Pristine */0,
            version: /* Pristine */0,
            category: /* Pristine */0,
            model: /* Pristine */0
          },
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.versionName;
  var match_0 = match ? match._0 : Curry._1(validators.versionName.validate, input);
  var match$1 = fieldsStatuses.version;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.version.validate, input);
  var match$2 = fieldsStatuses.category;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.category.validate, input);
  var match$3 = fieldsStatuses.model;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.model.validate, input);
  var versionNameResult = match_0;
  var versionNameResult$1;
  if (versionNameResult.TAG === /* Ok */0) {
    var versionResult = match_0$1;
    if (versionResult.TAG === /* Ok */0) {
      var categoryResult = match_0$2;
      if (categoryResult.TAG === /* Ok */0) {
        var modelResult = match_0$3;
        if (modelResult.TAG === /* Ok */0) {
          return {
                  TAG: /* Valid */0,
                  output: {
                    model: modelResult._0,
                    category: categoryResult._0,
                    version: versionResult._0,
                    versionName: versionNameResult._0
                  },
                  fieldsStatuses: {
                    versionName: /* Dirty */{
                      _0: versionNameResult,
                      _1: /* Shown */0
                    },
                    version: /* Dirty */{
                      _0: versionResult,
                      _1: /* Shown */0
                    },
                    category: /* Dirty */{
                      _0: categoryResult,
                      _1: /* Shown */0
                    },
                    model: /* Dirty */{
                      _0: modelResult,
                      _1: /* Shown */0
                    }
                  },
                  collectionsStatuses: undefined
                };
        }
        versionNameResult$1 = versionNameResult;
      } else {
        versionNameResult$1 = versionNameResult;
      }
    } else {
      versionNameResult$1 = versionNameResult;
    }
  } else {
    versionNameResult$1 = versionNameResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            versionName: /* Dirty */{
              _0: versionNameResult$1,
              _1: /* Shown */0
            },
            version: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            category: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            model: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurVersionNameField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.versionName, validators_versionName, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  versionName: status,
                                  version: init.version,
                                  category: init.category,
                                  model: init.model
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.version, validators_version, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  versionName: init.versionName,
                                  version: status,
                                  category: init.category,
                                  model: init.model
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCategoryField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.category, validators_category, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  versionName: init.versionName,
                                  version: init.version,
                                  category: status,
                                  model: init.model
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurModelField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.model, validators_model, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  versionName: init.versionName,
                                  version: init.version,
                                  category: init.category,
                                  model: status
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */4 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */4,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */5,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */7);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */6);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */5 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */6 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */7 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateVersionNameField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.versionName, state.submissionStatus, validators_versionName, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            versionName: status,
                                            version: init.version,
                                            category: init.category,
                                            model: init.model
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.version, state.submissionStatus, validators_version, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            versionName: init.versionName,
                                            version: status,
                                            category: init.category,
                                            model: init.model
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCategoryField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.category, state.submissionStatus, validators_category, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            versionName: init.versionName,
                                            version: init.version,
                                            category: status,
                                            model: init.model
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateModelField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.model, state.submissionStatus, validators_model, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            versionName: init.versionName,
                                            version: init.version,
                                            category: init.category,
                                            model: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */4 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                versionName: /* Pristine */0,
                                version: /* Pristine */0,
                                category: /* Pristine */0,
                                model: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                versionName: /* Pristine */0,
                                version: /* Pristine */0,
                                category: /* Pristine */0,
                                model: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */5 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */6 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateVersionName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionNameField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVersion: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCategory: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCategoryField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateModel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurVersionName: (function (param) {
              Curry._1(dispatch, /* BlurVersionNameField */0);
            }),
          blurVersion: (function (param) {
              Curry._1(dispatch, /* BlurVersionField */1);
            }),
          blurCategory: (function (param) {
              Curry._1(dispatch, /* BlurCategoryField */2);
            }),
          blurModel: (function (param) {
              Curry._1(dispatch, /* BlurModelField */3);
            }),
          versionNameResult: Formality.exposeFieldResult(state.fieldsStatuses.versionName),
          versionResult: Formality.exposeFieldResult(state.fieldsStatuses.version),
          categoryResult: Formality.exposeFieldResult(state.fieldsStatuses.category),
          modelResult: Formality.exposeFieldResult(state.fieldsStatuses.model),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.versionName || match.version || match.category || match.model) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */4);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */5);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */6);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */6,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */7);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var validators_startDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return Flash_Edit_Version_Perf_Utils$DvmAdminFrontendFr.validateAddStartDate(param.startDate, param$1.flashStartDate, param$1.flashEndDate);
    })
};

var validators_amount = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return Utils_Form$DvmAdminFrontendFr.validateFloatPositiveRequired(param.amount);
    })
};

var validators$1 = {
  startDate: validators_startDate,
  amount: validators_amount
};

function initialFieldsStatuses$1(_input) {
  return {
          startDate: /* Pristine */0,
          amount: /* Pristine */0
        };
}

function initialState$1(input) {
  return {
          input: input,
          fieldsStatuses: {
            startDate: /* Pristine */0,
            amount: /* Pristine */0
          },
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm$1(input, validators, fieldsStatuses, metadata) {
  var match = fieldsStatuses.startDate;
  var match_0 = match ? match._0 : Curry._2(validators.startDate.validate, input, metadata);
  var match$1 = fieldsStatuses.amount;
  var match_0$1 = match$1 ? match$1._0 : Curry._2(validators.amount.validate, input, metadata);
  var startDateResult = match_0;
  var startDateResult$1;
  if (startDateResult.TAG === /* Ok */0) {
    var amountResult = match_0$1;
    if (amountResult.TAG === /* Ok */0) {
      return {
              TAG: /* Valid */0,
              output: {
                amount: amountResult._0,
                startDate: startDateResult._0
              },
              fieldsStatuses: {
                startDate: /* Dirty */{
                  _0: startDateResult,
                  _1: /* Shown */0
                },
                amount: /* Dirty */{
                  _0: amountResult,
                  _1: /* Shown */0
                }
              },
              collectionsStatuses: undefined
            };
    }
    startDateResult$1 = startDateResult;
  } else {
    startDateResult$1 = startDateResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            startDate: /* Dirty */{
              _0: startDateResult$1,
              _1: /* Shown */0
            },
            amount: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm$1(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState$1(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurStartDateField */0 :
                  var result = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.startDate, validators_startDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  startDate: status,
                                  amount: init.amount
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAmountField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.amount, validators_amount, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  startDate: init.startDate,
                                  amount: status
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */2 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm$1(state.input, validators$1, state.fieldsStatuses, metadata);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */2,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */3,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */5);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */4);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */3 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */4 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */5 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState$1(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateStartDateField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput, metadata, state.fieldsStatuses.startDate, state.submissionStatus, validators_startDate, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            startDate: status,
                                            amount: init.amount
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAmountField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$1, metadata, state.fieldsStatuses.amount, state.submissionStatus, validators_amount, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            startDate: init.startDate,
                                            amount: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */2 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                startDate: /* Pristine */0,
                                amount: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                startDate: /* Pristine */0,
                                amount: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */3 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */4 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Caml_option.some(Curry._1(map, Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateStartDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateStartDateField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateAmount: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAmountField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurStartDate: (function (param) {
              Curry._1(dispatch, /* BlurStartDateField */0);
            }),
          blurAmount: (function (param) {
              Curry._1(dispatch, /* BlurAmountField */1);
            }),
          startDateResult: Formality.exposeFieldResult(state.fieldsStatuses.startDate),
          amountResult: Formality.exposeFieldResult(state.fieldsStatuses.amount),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.startDate || match.amount) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm$1(state.input, validators$1, state.fieldsStatuses, metadata);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */2);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */3);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */4);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */4,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */5);
            })
        };
}

var NewVersionsAddForm = {
  validators: validators$1,
  initialFieldsStatuses: initialFieldsStatuses$1,
  initialCollectionsStatuses: undefined,
  initialState: initialState$1,
  validateForm: validateForm$1,
  useForm: useForm$1
};

var initialState$2 = {
  model: "",
  category: "",
  version: "",
  versionName: ""
};

var initialStateNewVersionsAddForm = {
  amount: "",
  startDate: undefined
};

export {
  TableFilterForm ,
  initialState$2 as initialState,
  NewVersionsAddForm ,
  initialStateNewVersionsAddForm ,
}
/* react Not a pure module */
