// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReDate_utils from "@mobily/rescript-date/src/ReDate_utils.bs.js";
import * as Company_Manage_Modal_Form$DvmAdminFrontendFr from "./Company_Manage_Modal_Form.bs.js";

function dateToFloat(date, unlimited) {
  return Belt_Option.mapWithDefault(date, unlimited ? new Date(Date.now()).setFullYear(3000.0) : 0.0, (function (x) {
                return ReDate_utils.makeStartOfDayDate(x).valueOf();
              }));
}

function populateForm(row) {
  if (row !== undefined) {
    return {
            siret: row.siret,
            siren: Belt_Option.getWithDefault(row.siren, ""),
            name: Belt_Option.getWithDefault(row.name, ""),
            street: Belt_Option.getWithDefault(row.street, ""),
            streetNbr: Belt_Option.getWithDefault(row.streetNbr, ""),
            additionalAddressInfo: Belt_Option.getWithDefault(row.additionalAddressInfo, ""),
            zipCode: Belt_Option.getWithDefault(row.zipCode, ""),
            city: Belt_Option.getWithDefault(row.city, ""),
            countryCode: Belt_Option.getWithDefault(row.countryCode, ""),
            hierarchy: Belt_Option.getWithDefault(row.hierarchy, ""),
            codeApe: Belt_Option.getWithDefault(row.codeApe, ""),
            endDate: row.endDate
          };
  } else {
    return Company_Manage_Modal_Form$DvmAdminFrontendFr.initialInput;
  }
}

export {
  dateToFloat ,
  populateForm ,
}
/* Company_Manage_Modal_Form-DvmAdminFrontendFr Not a pure module */
