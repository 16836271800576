// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as EnvVar$DvmAdminFrontendFr from "../../../bindings/global/EnvVar.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../icons/App_Icon.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../../route/Route_Paths.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../App_Typography.bs.js";
import * as Messages_TopMenu$DvmAdminFrontendFr from "../../../intl/messages/topMenu/Messages_TopMenu.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as Utils_Authentication$DvmAdminFrontendFr from "../../utils/Utils_Authentication.bs.js";
import * as App_TopMenu_Locale_Selector$DvmAdminFrontendFr from "./App_TopMenu_Locale_Selector.bs.js";

var topMenuItem = Css.css({
      backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight2,
      color: Theme_Colors$DvmAdminFrontendFr.black,
      display: "flex",
      alignItems: "center"
    });

var topMenuItemHover = Css.css(Object.assign({}, {
          cursor: "pointer"
        }, {
          "&:hover": {
            backgroundColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown
          }
        }));

var topMenuItemPadding = Css.css({
      padding: "2.4rem"
    });

var menuList = Css.css({
      backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight2,
      display: "flex",
      padding: "1.6rem 2.4rem"
    });

var itemBox = Css.css({
      padding: "0.8rem"
    });

var item = Css.css(Object.assign({}, {
          cursor: "pointer"
        }, {
          "&:hover": {
            fontWeight: "600"
          }
        }));

var paddingRight = Css.css({
      paddingRight: "1rem"
    });

var Styles = {
  topMenuItem: topMenuItem,
  topMenuItemHover: topMenuItemHover,
  topMenuItemPadding: topMenuItemPadding,
  menuList: menuList,
  itemBox: itemBox,
  item: item,
  paddingRight: paddingRight
};

function App_TopMenu_User(Props) {
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return false;
      });
  var setOpenMenu = match[1];
  var openMenu = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setModal = match$1[1];
  var anchorRef = React.useRef(null);
  var match$2 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$3 = match$2[0];
  var userManager = match$3.userManager;
  var roles = match$3.roles;
  var prevOpen = React.useRef(openMenu);
  React.useEffect((function () {
          if (prevOpen.current === true && openMenu === false) {
            Belt_Option.forEach(Caml_option.nullable_to_opt(anchorRef.current), (function (input) {
                    input.focus();
                  }));
          }
          prevOpen.current = openMenu;
        }), [openMenu]);
  var activeUserMenu = Belt_List.length(roles) > 1 || EnvVar$DvmAdminFrontendFr.oktaEnabled;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  ref: anchorRef,
                  className: Css.cx([
                        activeUserMenu ? topMenuItemHover : "",
                        topMenuItem,
                        topMenuItemPadding
                      ]),
                  onClick: (function (param) {
                      Curry._1(setOpenMenu, (function (prev) {
                              return !prev;
                            }));
                    })
                }, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                      size: /* Large */3,
                      type_: /* User */48,
                      iconClassName: paddingRight
                    }), React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                      className: "userIdentification",
                      children: "" + match$3.firstName + " " + match$3.lastName + "",
                      variant: "bodyBig"
                    }), activeUserMenu ? React.createElement(Core.Popper, {
                        anchorEl: anchorRef.current,
                        children: React.createElement(Core.ClickAwayListener, {
                              children: React.createElement("div", {
                                    className: menuList
                                  }, React.createElement("div", undefined, Belt_List.length(roles) > 1 || Belt_List.length(Belt_List.keep(roles, User_Types_Utils$DvmAdminFrontendFr.isAdmin)) > 0 ? React.createElement("div", {
                                              className: itemBox
                                            }, React.createElement("div", {
                                                  onClick: (function (param) {
                                                      RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rSelectRole);
                                                    })
                                                }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                      className: item,
                                                      children: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.changeRoles)
                                                    }))) : null, EnvVar$DvmAdminFrontendFr.oktaEnabled ? React.createElement("div", {
                                              className: itemBox
                                            }, React.createElement("div", {
                                                  onClick: (function (param) {
                                                      Utils_Authentication$DvmAdminFrontendFr.logoutAndRedirect(Belt_Option.getExn(userManager));
                                                    })
                                                }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                      className: item,
                                                      children: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.logout)
                                                    }))) : null, React.createElement("div", {
                                            className: itemBox
                                          }, React.createElement("div", {
                                                onClick: (function (param) {
                                                    Curry._1(setModal, (function (param) {
                                                            return true;
                                                          }));
                                                  })
                                              }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                    className: item,
                                                    children: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.changeLanguage)
                                                  }))))),
                              onClickAway: (function ($$event) {
                                  if (!(!(anchorRef.current == null) && anchorRef.current.contains($$event.currentTarget))) {
                                    return Curry._1(setOpenMenu, (function (param) {
                                                  return false;
                                                }));
                                  }
                                  
                                })
                            }),
                        open: openMenu,
                        placement: "bottom-end",
                        style: {
                          zIndex: "1500"
                        }
                      }) : null), match$1[0] ? React.createElement(App_TopMenu_Locale_Selector$DvmAdminFrontendFr.make, {
                    onClose: (function (param) {
                        Curry._1(setModal, (function (param) {
                                return false;
                              }));
                      })
                  }) : null);
}

var make = App_TopMenu_User;

export {
  Styles ,
  make ,
}
/* topMenuItem Not a pure module */
