// Generated by ReScript, PLEASE EDIT WITH CARE

import * as App_Types_Json$DvmAdminFrontendFr from "../../../common/types/App_Types_Json.bs.js";

function toString(status) {
  if (typeof status !== "number") {
    return status._0;
  }
  switch (status) {
    case /* ACQUISITION_TYPE_MISSMATCH */0 :
        return "ACQUISITION TYPE MISSMATCH";
    case /* ADVENSYS_PRICE_UNAVAILABLE */1 :
        return "ADVENSYS PRICE UNAVAILABLE";
    case /* AVS_ACQUISITION_TYPE */2 :
        return "AVS ACQUISITION TYPE";
    case /* AVS_AGREEMENT_MISSING */3 :
        return "AVS AGREEMENT MISSING";
    case /* AVS_AGREEMENT_VALIDITY */4 :
        return "AVS AGREEMENT VALIDITY";
    case /* AVS_CATEGORY_MISSING */5 :
        return "AVS CATEGORY MISSING";
    case /* AVS_CONTRACT_MISMATCH */6 :
        return "AVS CONTRACT MISMATCH";
    case /* AVS_DISCOUNT_MISMATCH */7 :
        return "AVS DISCOUNT MISMATCH";
    case /* AVS_MARGIN_GRID */8 :
        return "AVS MARGIN GRID";
    case /* AVS_MARGIN_MISSING */9 :
        return "AVS MARGIN MISSING";
    case /* AVS_MAX_VOLUME */10 :
        return "AVS MAX VOLUME";
    case /* AVS_QUOTA_EXCEEDED */11 :
        return "AVS QUOTA EXCEEDED";
    case /* BRAND_MISSMATCH */12 :
        return "BRAND MISSMATCH";
    case /* BRAND_USED_CAR_MISSMATCH */13 :
        return "BRAND USED CAR MISSMATCH";
    case /* BUDGET_TYPE_MISSMATCH */14 :
        return "BUDGET TYPE MISSMATCH";
    case /* CONTRACT_VALIDITY */15 :
        return "CONTRACT VALIDITY";
    case /* CUSTOMER_INVOICE_DATE_MISSMATCH */16 :
        return "CUSTOMER INVOICE DATE MISSMATCH";
    case /* DATE_MISMATCH */17 :
        return "DATE MISMATCH";
    case /* DELIVERY_DATE_EMPTY */18 :
        return "DELIVERY DATE EMPTY";
    case /* DELIVERY_DATE_MISMATCH */19 :
        return "DELIVERY DATE MISMATCH";
    case /* EASYPACK_MISMATCH */20 :
        return "EASYPACK MISMATCH";
    case /* EMPTY_ACQUISITION_TYPE */21 :
        return "EMPTY ACQUISITION TYPE";
    case /* EMPTY_BRAND_USED_CAR */22 :
        return "EMPTY BRAND USED CAR";
    case /* EMPTY_BUDGET_TYPE */23 :
        return "EMPTY BUDGET TYPE";
    case /* EMPTY_COMPANY_DISCOUNT_PERCENTAGE */24 :
        return "EMPTY COMPANY DISCOUNT PERCENTAGE";
    case /* EMPTY_CUSTOMER_INVOICE_DATE */25 :
        return "EMPTY CUSTOMER INVOICE DATE";
    case /* EMPTY_DEALERID */26 :
        return "EMPTY DEALERID";
    case /* EMPTY_DVS */27 :
        return "EMPTY DVS";
    case /* EMPTY_MODEL */28 :
        return "EMPTY MODEL";
    case /* EMPTY_MODEL_USED_CAR */29 :
        return "EMPTY MODEL USED CAR";
    case /* EMPTY_ORDER_TYPE */30 :
        return "EMPTY ORDER TYPE";
    case /* EMPTY_REGISTRATIONID */31 :
        return "EMPTY REGISTRATIONID";
    case /* EMPTY_RENAULT_INVOICE_DATE */32 :
        return "EMPTY RENAULT INVOICE DATE";
    case /* FLASH_MODEL_VERSION_COMBINATION */33 :
        return "FLASH MODEL VERSION COMBINATION";
    case /* INCORRECT_BUDGET_TYPE */34 :
        return "INCORRECT BUDGET TYPE";
    case /* INCORRECT_QUOTAS_ATTACHMENT_SIREN */35 :
        return "INCORRECT QUOTAS ATTACHMENT SIREN";
    case /* INVALID_ACQUISITION_TYPE */36 :
        return "INVALID ACQUISITION TYPE";
    case /* INVALID_AVS_GRID_DISCOUNT */37 :
        return "INVALID AVS GRID DISCOUNT";
    case /* INVALID_BUDGET */38 :
        return "INVALID BUDGET";
    case /* INVALID_MODEL_GRID_DISCOUNT */39 :
        return "INVALID MODEL GRID DISCOUNT";
    case /* INVALID_MODEL_VERSION */40 :
        return "INVALID MODEL/VERSION";
    case /* INVALID_SIRET */41 :
        return "INVALID SIRET";
    case /* INVALID_VERSION_GRID_DISCOUNT */42 :
        return "INVALID VERSION GRID DISCOUNT";
    case /* INVOICE_DATE */43 :
        return "INVOICE DATE";
    case /* INVOICE_DATE_MISSING */44 :
        return "INVOICE DATE MISSING";
    case /* LCD_QUOTA_EXCEEDED */45 :
        return "LCD QUOTA EXCEEDED";
    case /* LCD_QUOTAS_ISSUE */46 :
        return "LCD QUOTAS ISSUE";
    case /* MADA_MADC_DATES_MISMATCH */47 :
        return "MADA MADC DATES MISMATCH";
    case /* MADA_MISSMATCH */48 :
        return "MADA MISSMATCH";
    case /* MADC_MISSMATCH */49 :
        return "MADC MISSMATCH";
    case /* MISSING_ACQUISITION_TYPE */50 :
        return "MISSING ACQUISITION TYPE";
    case /* MISSING_AVS_QUOTA_ATTACHMENT */51 :
        return "MISSING AVS QUOTA ATTACHMENT";
    case /* MISSING_BRAND_USED_CAR */52 :
        return "MISSING BRAND USED CAR";
    case /* MISSING_COMPANY_DISCOUNT_PERCENTAGE */53 :
        return "MISSING COMPANY DISCOUNT PERCENTAGE";
    case /* MISSING_CONTRACT_FOR_AVS_ORDER */54 :
        return "MISSING CONTRACT FOR AVS ORDER";
    case /* MISSING_CUSTOMER_DISCOUNT */55 :
        return "MISSING CUSTOMER DISCOUNT";
    case /* MISSING_CUSTOMER_INVOICE_DATE */56 :
        return "MISSING CUSTOMER INVOICE DATE";
    case /* MISSING_CUSTOMER_INVOICE_NUMBER */57 :
        return "MISSING CUSTOMER INVOICE NUMBER";
    case /* MISSING_CUSTOMER_ORDER_NUMBER */58 :
        return "MISSING CUSTOMER ORDER NUMBER";
    case /* MISSING_DEALER */59 :
        return "MISSING DEALER";
    case /* MISSING_DELIVERY_DATE */60 :
        return "MISSING DELIVERY DATE";
    case /* MISSING_EASYPACK */61 :
        return "MISSING EASYPACK";
    case /* MISSING_FLASH_ACTION_MODEL_VERSION */62 :
        return "MISSING FLASH ACTION MODEL VERSION";
    case /* MISSING_INVOICE_INFO */63 :
        return "MISSING INVOICE INFO";
    case /* MISSING_LCD_MODEL_GRID */64 :
        return "MISSING LCD MODEL GRID";
    case /* MISSING_LCD_QUOTA_GRID */65 :
        return "MISSING LCD QUOTA GRID";
    case /* MISSING_LCD_QUOTA_GRID_ATTACHMENT */66 :
        return "MISSING LCD QUOTA GRID ATTACHMENT";
    case /* MISSING_LCD_VERSION_GRID */67 :
        return "MISSING LCD VERSION GRID";
    case /* MISSING_MADA */68 :
        return "MISSING MADA";
    case /* MISSING_MADC */69 :
        return "MISSING MADC";
    case /* MISSING_MODEL_GRID */70 :
        return "MISSING MODEL GRID";
    case /* MISSING_MODEL_MARGIN */71 :
        return "MISSING MODEL MARGIN";
    case /* MISSING_MODEL_USED_CAR */72 :
        return "MISSING MODEL USED CAR";
    case /* MISSING_NEW_CAR_REGISTRATION_DATE */73 :
        return "MISSING NEW CAR REGISTRATION DATE";
    case /* MISSING_NEW_CAR_REGISTRATIONID */74 :
        return "MISSING NEW CAR REGISTRATIONID";
    case /* MISSING_ORDER_NUMBER_CAR */75 :
        return "MISSING ORDER NUMBER CAR";
    case /* MISSING_PCD_AGREEMENT */76 :
        return "MISSING PCD AGREEMENT";
    case /* MISSING_POLICE_NUMBER */77 :
        return "MISSING POLICE NUMBER";
    case /* MISSING_PROT_MODEL_GRID */78 :
        return "MISSING PROT MODEL GRID";
    case /* MISSING_PROT_VERSION_GRID */79 :
        return "MISSING PROT VERSION GRID";
    case /* MISSING_REGISTRATION_INFO */80 :
        return "MISSING REGISTRATION INFO";
    case /* MISSING_REGISTRATION_INFO_NOT_BLOCKING */81 :
        return "MISSING REGISTRATION INFO NOT BLOCKING";
    case /* MISSING_RENAULT_INVOICE_DATE */82 :
        return "MISSING RENAULT INVOICE DATE";
    case /* MISSING_SEDRE_PRICE */83 :
        return "MISSING SEDRE PRICE";
    case /* MISSING_SIREN_CONTRACT */84 :
        return "MISSING SIREN CONTRACT";
    case /* MISSING_SIREN_FINAL_CUSTOMER_LLD */85 :
        return "MISSING SIREN FINAL CUSTOMER LLD";
    case /* MISSING_SIRET */86 :
        return "MISSING SIRET";
    case /* MISSING_USED_CAR_FIRST_RELEASE_DATE */87 :
        return "MISSING USED CAR FIRST RELEASE DATE";
    case /* MISSING_USED_CAR_LAST_REGISTRATION_DATE */88 :
        return "MISSING USED CAR LAST REGISTRATION DATE";
    case /* MISSING_USED_CAR_MODEL */89 :
        return "MISSING USED CAR MODEL";
    case /* MISSING_USED_CAR_REGISTRATION */90 :
        return "MISSING USED CAR REGISTRATION";
    case /* MISSING_USED_CAR_REGISTRATION_DATE */91 :
        return "MISSING USED CAR REGISTRATION DATE";
    case /* MISSING_USED_CAR_REGISTRATIONID */92 :
        return "MISSING USED CAR REGISTRATIONID";
    case /* MISSING_USED_CAR_RELEASE_DATE */93 :
        return "MISSING USED CAR RELEASE DATE";
    case /* MISSING_USED_CAR_VERSION */94 :
        return "MISSING USED CAR VERSION";
    case /* MISSING_USED_CAR_VIN */95 :
        return "MISSING USED CAR VIN";
    case /* MISSING_VERSION_GRID */96 :
        return "MISSING VERSION GRID";
    case /* MISSING_VERSION_MARGIN */97 :
        return "MISSING VERSION MARGIN";
    case /* MISSING_VERSION_USED_CAR */98 :
        return "MISSING VERSION USED CAR";
    case /* MISSING_VIN */99 :
        return "MISSING VIN";
    case /* MISSING_VIN_USED_CAR */100 :
        return "MISSING VIN USED CAR";
    case /* MISSING_VO_POLICE_NUMBER */101 :
        return "MISSING VO POLICE NUMBER";
    case /* MODEL_MISMATCH */102 :
        return "MODEL MISMATCH";
    case /* MODEL_USED_CAR_MISSMATCH */103 :
        return "MODEL USED CAR MISSMATCH";
    case /* MODEL_VERSION_COMBINATION */104 :
        return "MODEL VERSION COMBINATION";
    case /* MODEL_VERSION_MISMATCH */105 :
        return "MODEL VERSION MISMATCH";
    case /* NO_VAT_RATE */106 :
        return "NO VAT RATE";
    case /* NO_VIN */107 :
        return "NO VIN";
    case /* NON_CUMULABLE */108 :
        return "NON_CUMULABLE";
    case /* NOT_CUMULABLE */109 :
        return "NOT CUMULABLE";
    case /* ORDER_DATE_MISMATCH */110 :
        return "ORDER DATE MISMATCH";
    case /* ORDER_TYPE_MISSMATCH */111 :
        return "ORDER TYPE MISSMATCH";
    case /* PERCENTANDEUROFILLED */112 :
        return "PERCENTANDEUROFILLED";
    case /* PERF_NOT_VALIDATED */113 :
        return "PERF NOT VALIDATED";
    case /* PLANPERF_MAXAMOUNT_EXCEEDED */114 :
        return "PLANPERF MAXAMOUNT EXCEEDED";
    case /* REGISTRATION_DATE_EMPTY */115 :
        return "REGISTRATION DATE EMPTY";
    case /* REGISTRATION_DATE_MISMATCH */116 :
        return "REGISTRATION DATE MISMATCH";
    case /* RENAULT_INVOICE_DATE_MISSMATCH */117 :
        return "RENAULT INVOICE DATE MISSMATCH";
    case /* SEDRE_PRICE_UNAVAILABLE */118 :
        return "SEDRE PRICE UNAVAILABLE";
    case /* SIRET_CLOSED */119 :
        return "SIRET CLOSED";
    case /* USED_CAR_AGE_MISMATCH */120 :
        return "USED CAR AGE MISMATCH";
    case /* USED_CAR_POSSESSION_MISMATCH */121 :
        return "USED CAR POSSESSION MISMATCH";
    case /* VEHICLE_TYPE_MISSMATCH */122 :
        return "VEHICLE TYPE MISSMATCH";
    case /* VERSION_EXCLUDED_FROM_LCD_QUOTA_GRID */123 :
        return "VERSION EXCLUDED FROM LCD QUOTA GRID";
    case /* VERSION_MISSMATCH */124 :
        return "VERSION MISSMATCH";
    case /* VO_POLICE_NUMBER_MISSING */125 :
        return "VO POLICE NUMBER MISSING";
    
  }
}

function fromString(status) {
  switch (status) {
    case "" :
        return ;
    case "ACQUISITION TYPE MISSMATCH" :
        return /* ACQUISITION_TYPE_MISSMATCH */0;
    case "ADVENSYS PRICE UNAVAILABLE" :
        return /* ADVENSYS_PRICE_UNAVAILABLE */1;
    case "AVS ACQUISITION TYPE" :
        return /* AVS_ACQUISITION_TYPE */2;
    case "AVS AGREEMENT MISSING" :
        return /* AVS_AGREEMENT_MISSING */3;
    case "AVS AGREEMENT VALIDITY" :
        return /* AVS_AGREEMENT_VALIDITY */4;
    case "AVS CATEGORY MISSING" :
        return /* AVS_CATEGORY_MISSING */5;
    case "AVS CONTRACT MISMATCH" :
        return /* AVS_CONTRACT_MISMATCH */6;
    case "AVS DISCOUNT MISMATCH" :
        return /* AVS_DISCOUNT_MISMATCH */7;
    case "AVS MARGIN GRID" :
        return /* AVS_MARGIN_GRID */8;
    case "AVS MARGIN MISSING" :
        return /* AVS_MARGIN_MISSING */9;
    case "AVS MAX VOLUME" :
        return /* AVS_MAX_VOLUME */10;
    case "AVS QUOTA EXCEEDED" :
        return /* AVS_QUOTA_EXCEEDED */11;
    case "BRAND MISSMATCH" :
        return /* BRAND_MISSMATCH */12;
    case "BRAND USED CAR MISSMATCH" :
        return /* BRAND_USED_CAR_MISSMATCH */13;
    case "BUDGET TYPE MISSMATCH" :
        return /* BUDGET_TYPE_MISSMATCH */14;
    case "CONTRACT VALIDITY" :
        return /* CONTRACT_VALIDITY */15;
    case "CUSTOMER INVOICE DATE MISSMATCH" :
        return /* CUSTOMER_INVOICE_DATE_MISSMATCH */16;
    case "DATE MISMATCH" :
        return /* DATE_MISMATCH */17;
    case "DELIVERY DATE EMPTY" :
        return /* DELIVERY_DATE_EMPTY */18;
    case "DELIVERY DATE MISMATCH" :
        return /* DELIVERY_DATE_MISMATCH */19;
    case "EASYPACK MISMATCH" :
        return /* EASYPACK_MISMATCH */20;
    case "EMPTY ACQUISITION TYPE" :
        return /* EMPTY_ACQUISITION_TYPE */21;
    case "EMPTY BRAND USED CAR" :
        return /* EMPTY_BRAND_USED_CAR */22;
    case "EMPTY BUDGET TYPE" :
        return /* EMPTY_BUDGET_TYPE */23;
    case "EMPTY COMPANY DISCOUNT PERCENTAGE" :
        return /* EMPTY_COMPANY_DISCOUNT_PERCENTAGE */24;
    case "EMPTY CUSTOMER INVOICE DATE" :
        return /* EMPTY_CUSTOMER_INVOICE_DATE */25;
    case "EMPTY DEALERID" :
        return /* EMPTY_DEALERID */26;
    case "EMPTY DVS" :
        return /* EMPTY_DVS */27;
    case "EMPTY MODEL" :
        return /* EMPTY_MODEL */28;
    case "EMPTY MODEL USED CAR" :
        return /* EMPTY_MODEL_USED_CAR */29;
    case "EMPTY ORDER TYPE" :
        return /* EMPTY_ORDER_TYPE */30;
    case "EMPTY REGISTRATIONID" :
        return /* EMPTY_REGISTRATIONID */31;
    case "EMPTY RENAULT INVOICE DATE" :
        return /* EMPTY_RENAULT_INVOICE_DATE */32;
    case "FLASH MODEL VERSION COMBINATION" :
        return /* FLASH_MODEL_VERSION_COMBINATION */33;
    case "INCORRECT BUDGET TYPE" :
        return /* INCORRECT_BUDGET_TYPE */34;
    case "INCORRECT QUOTAS ATTACHMENT SIREN" :
        return /* INCORRECT_QUOTAS_ATTACHMENT_SIREN */35;
    case "INVALID ACQUISITION TYPE" :
        return /* INVALID_ACQUISITION_TYPE */36;
    case "INVALID AVS GRID DISCOUNT" :
        return /* INVALID_AVS_GRID_DISCOUNT */37;
    case "INVALID BUDGET" :
        return /* INVALID_BUDGET */38;
    case "INVALID MODEL GRID DISCOUNT" :
        return /* INVALID_MODEL_GRID_DISCOUNT */39;
    case "INVALID MODEL/VERSION" :
        return /* INVALID_MODEL_VERSION */40;
    case "INVALID SIRET" :
        return /* INVALID_SIRET */41;
    case "INVALID VERSION GRID DISCOUNT" :
        return /* INVALID_VERSION_GRID_DISCOUNT */42;
    case "INVOICE DATE" :
        return /* INVOICE_DATE */43;
    case "INVOICE DATE MISSING" :
        return /* INVOICE_DATE_MISSING */44;
    case "LCD QUOTA EXCEEDED" :
        return /* LCD_QUOTA_EXCEEDED */45;
    case "LCD QUOTAS ISSUE" :
        return /* LCD_QUOTAS_ISSUE */46;
    case "MADA MADC DATES MISMATCH" :
        return /* MADA_MADC_DATES_MISMATCH */47;
    case "MADA MISSMATCH" :
        return /* MADA_MISSMATCH */48;
    case "MADC MISSMATCH" :
        return /* MADC_MISSMATCH */49;
    case "MISSING ACQUISITION TYPE" :
        return /* MISSING_ACQUISITION_TYPE */50;
    case "MISSING AVS QUOTA ATTACHMENT" :
        return /* MISSING_AVS_QUOTA_ATTACHMENT */51;
    case "MISSING BRAND USED CAR" :
        return /* MISSING_BRAND_USED_CAR */52;
    case "MISSING COMPANY DISCOUNT PERCENTAGE" :
        return /* MISSING_COMPANY_DISCOUNT_PERCENTAGE */53;
    case "MISSING CONTRACT FOR AVS ORDER" :
        return /* MISSING_CONTRACT_FOR_AVS_ORDER */54;
    case "MISSING CUSTOMER DISCOUNT" :
        return /* MISSING_CUSTOMER_DISCOUNT */55;
    case "MISSING CUSTOMER INVOICE DATE" :
        return /* MISSING_CUSTOMER_INVOICE_DATE */56;
    case "MISSING CUSTOMER INVOICE NUMBER" :
        return /* MISSING_CUSTOMER_INVOICE_NUMBER */57;
    case "MISSING CUSTOMER ORDER NUMBER" :
        return /* MISSING_CUSTOMER_ORDER_NUMBER */58;
    case "MISSING DEALER" :
        return /* MISSING_DEALER */59;
    case "MISSING DELIVERY DATE" :
        return /* MISSING_DELIVERY_DATE */60;
    case "MISSING EASYPACK" :
        return /* MISSING_EASYPACK */61;
    case "MISSING FLASH ACTION MODEL VERSION" :
        return /* MISSING_FLASH_ACTION_MODEL_VERSION */62;
    case "MISSING INVOICE INFO" :
        return /* MISSING_INVOICE_INFO */63;
    case "MISSING LCD MODEL GRID" :
        return /* MISSING_LCD_MODEL_GRID */64;
    case "MISSING LCD QUOTA GRID" :
        return /* MISSING_LCD_QUOTA_GRID */65;
    case "MISSING LCD QUOTA GRID ATTACHMENT" :
        return /* MISSING_LCD_QUOTA_GRID_ATTACHMENT */66;
    case "MISSING LCD VERSION GRID" :
        return /* MISSING_LCD_VERSION_GRID */67;
    case "MISSING MADA" :
        return /* MISSING_MADA */68;
    case "MISSING MADC" :
        return /* MISSING_MADC */69;
    case "MISSING MODEL GRID" :
        return /* MISSING_MODEL_GRID */70;
    case "MISSING MODEL MARGIN" :
        return /* MISSING_MODEL_MARGIN */71;
    case "MISSING MODEL USED CAR" :
        return /* MISSING_MODEL_USED_CAR */72;
    case "MISSING NEW CAR REGISTRATION DATE" :
        return /* MISSING_NEW_CAR_REGISTRATION_DATE */73;
    case "MISSING NEW CAR REGISTRATIONID" :
        return /* MISSING_NEW_CAR_REGISTRATIONID */74;
    case "MISSING ORDER NUMBER CAR" :
        return /* MISSING_ORDER_NUMBER_CAR */75;
    case "MISSING PCD AGREEMENT" :
        return /* MISSING_PCD_AGREEMENT */76;
    case "MISSING POLICE NUMBER" :
        return /* MISSING_POLICE_NUMBER */77;
    case "MISSING PROT MODEL GRID" :
        return /* MISSING_PROT_MODEL_GRID */78;
    case "MISSING PROT VERSION GRID" :
        return /* MISSING_PROT_VERSION_GRID */79;
    case "MISSING REGISTRATION INFO" :
        return /* MISSING_REGISTRATION_INFO */80;
    case "MISSING REGISTRATION INFO NOT BLOCKING" :
        return /* MISSING_REGISTRATION_INFO_NOT_BLOCKING */81;
    case "MISSING RENAULT INVOICE DATE" :
        return /* MISSING_RENAULT_INVOICE_DATE */82;
    case "MISSING SEDRE PRICE" :
        return /* MISSING_SEDRE_PRICE */83;
    case "MISSING SIREN CONTRACT" :
        return /* MISSING_SIREN_CONTRACT */84;
    case "MISSING SIREN FINAL CUSTOMER LLD" :
        return /* MISSING_SIREN_FINAL_CUSTOMER_LLD */85;
    case "MISSING SIRET" :
        return /* MISSING_SIRET */86;
    case "MISSING USED CAR FIRST RELEASE DATE" :
        return /* MISSING_USED_CAR_FIRST_RELEASE_DATE */87;
    case "MISSING USED CAR LAST REGISTRATION DATE" :
        return /* MISSING_USED_CAR_LAST_REGISTRATION_DATE */88;
    case "MISSING USED CAR MODEL" :
        return /* MISSING_USED_CAR_MODEL */89;
    case "MISSING USED CAR REGISTRATION" :
        return /* MISSING_USED_CAR_REGISTRATION */90;
    case "MISSING USED CAR REGISTRATION DATE" :
        return /* MISSING_USED_CAR_REGISTRATION_DATE */91;
    case "MISSING USED CAR REGISTRATIONID" :
        return /* MISSING_USED_CAR_REGISTRATIONID */92;
    case "MISSING USED CAR RELEASE DATE" :
        return /* MISSING_USED_CAR_RELEASE_DATE */93;
    case "MISSING USED CAR VERSION" :
        return /* MISSING_USED_CAR_VERSION */94;
    case "MISSING USED CAR VIN" :
        return /* MISSING_USED_CAR_VIN */95;
    case "MISSING VERSION GRID" :
        return /* MISSING_VERSION_GRID */96;
    case "MISSING VERSION MARGIN" :
        return /* MISSING_VERSION_MARGIN */97;
    case "MISSING VERSION USED CAR" :
        return /* MISSING_VERSION_USED_CAR */98;
    case "MISSING VIN" :
        return /* MISSING_VIN */99;
    case "MISSING VIN USED CAR" :
        return /* MISSING_VIN_USED_CAR */100;
    case "MISSING VO POLICE NUMBER" :
        return /* MISSING_VO_POLICE_NUMBER */101;
    case "MODEL MISMATCH" :
        return /* MODEL_MISMATCH */102;
    case "MODEL USED CAR MISSMATCH" :
        return /* MODEL_USED_CAR_MISSMATCH */103;
    case "MODEL VERSION COMBINATION" :
        return /* MODEL_VERSION_COMBINATION */104;
    case "MODEL VERSION MISMATCH" :
        return /* MODEL_VERSION_MISMATCH */105;
    case "NO VAT RATE" :
        return /* NO_VAT_RATE */106;
    case "NO VIN" :
        return /* NO_VIN */107;
    case "NON_CUMULABLE" :
        return /* NON_CUMULABLE */108;
    case "NOT CUMULABLE" :
        return /* NOT_CUMULABLE */109;
    case "ORDER DATE MISMATCH" :
        return /* ORDER_DATE_MISMATCH */110;
    case "ORDER TYPE MISSMATCH" :
        return /* ORDER_TYPE_MISSMATCH */111;
    case "PERCENTANDEUROFILLED" :
        return /* PERCENTANDEUROFILLED */112;
    case "PERF NOT VALIDATED" :
        return /* PERF_NOT_VALIDATED */113;
    case "PLANPERF MAXAMOUNT EXCEEDED" :
        return /* PLANPERF_MAXAMOUNT_EXCEEDED */114;
    case "REGISTRATION DATE EMPTY" :
        return /* REGISTRATION_DATE_EMPTY */115;
    case "REGISTRATION DATE MISMATCH" :
        return /* REGISTRATION_DATE_MISMATCH */116;
    case "RENAULT INVOICE DATE MISSMATCH" :
        return /* RENAULT_INVOICE_DATE_MISSMATCH */117;
    case "SEDRE PRICE UNAVAILABLE" :
        return /* SEDRE_PRICE_UNAVAILABLE */118;
    case "SIRET CLOSED" :
        return /* SIRET_CLOSED */119;
    case "USED CAR AGE MISMATCH" :
        return /* USED_CAR_AGE_MISMATCH */120;
    case "USED CAR POSSESSION MISMATCH" :
        return /* USED_CAR_POSSESSION_MISMATCH */121;
    case "VEHICLE TYPE MISSMATCH" :
        return /* VEHICLE_TYPE_MISSMATCH */122;
    case "VERSION EXCLUDED FROM LCD QUOTA GRID" :
        return /* VERSION_EXCLUDED_FROM_LCD_QUOTA_GRID */123;
    case "VERSION MISSMATCH" :
        return /* VERSION_MISSMATCH */124;
    case "VO POLICE NUMBER MISSING" :
        return /* VO_POLICE_NUMBER_MISSING */125;
    default:
      return /* UnhandledString */{
              _0: status
            };
  }
}

var Decco = App_Types_Json$DvmAdminFrontendFr.MakeStringableJsonType({
      toString: toString,
      fromString: fromString
    });

export {
  toString ,
  fromString ,
  Decco ,
}
/* Decco Not a pure module */
