// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as App_Checkbox$DvmAdminFrontendFr from "../App_Checkbox.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";

function labelStyle(checked) {
  return Css.css({
              backgroundColor: checked ? Theme_Colors$DvmAdminFrontendFr.primaryBrown : Theme_Colors$DvmAdminFrontendFr.grayBackground,
              marginLeft: "0px",
              padding: "0px 10px 0px 0px"
            });
}

var checked = Css.css({
      color: Theme_Colors$DvmAdminFrontendFr.white
    });

var labelPlacementStart = Css.css({
      marginLeft: "0px"
    });

var labelPlacementEnd = Css.css({
      marginRight: "0px"
    });

function App_UserTypeCheckBox(Props) {
  var kindOpt = Props.kind;
  var label = Props.label;
  var checked = Props.checked;
  var onClick = Props.onClick;
  var labelPlacement = Props.labelPlacement;
  var disabled = Props.disabled;
  var classNameOpt = Props.className;
  var id = Props.id;
  var controlStyle = Props.controlStyle;
  var kind = kindOpt !== undefined ? kindOpt : /* Classic */1;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var renderCheckbox = function (param) {
    var tmp = {
      onClick: onClick,
      disableTouchRipple: true
    };
    if (checked !== undefined) {
      tmp.checked = Caml_option.valFromOption(checked);
    }
    var tmp$1 = kind || !(checked !== undefined && checked) ? /* Light */0 : /* Dark */1;
    if (tmp$1 !== undefined) {
      tmp.mode = Caml_option.valFromOption(tmp$1);
    }
    if (disabled !== undefined) {
      tmp.disabled = Caml_option.valFromOption(disabled);
    }
    if (id !== undefined) {
      tmp.id = Caml_option.valFromOption(id);
    }
    return React.createElement(App_Checkbox$DvmAdminFrontendFr.make, tmp);
  };
  var match = Belt_Option.getWithDefault(labelPlacement, "end");
  var tmp = {
    className: Css.cx([
          className,
          match === "end" ? (
              kind ? "" : labelPlacementEnd
            ) : (
              match === "start" && !kind ? labelPlacementStart : ""
            ),
          kind ? "" : labelStyle(Belt_Option.getWithDefault(checked, false))
        ]),
    control: renderCheckbox(undefined)
  };
  var tmp$1 = Belt_Option.mapWithDefault(label, undefined, (function (x) {
          return Caml_option.some(x);
        }));
  if (tmp$1 !== undefined) {
    tmp.label = Caml_option.valFromOption(tmp$1);
  }
  if (labelPlacement !== undefined) {
    tmp.labelPlacement = Caml_option.valFromOption(labelPlacement);
  }
  var tmp$2 = {
    children: React.createElement(Core.FormGroup, {
          children: React.createElement(Core.FormControlLabel, tmp)
        })
  };
  if (controlStyle !== undefined) {
    tmp$2.style = Caml_option.valFromOption(controlStyle);
  }
  return React.createElement(Core.FormControl, tmp$2);
}

var make = App_UserTypeCheckBox;

export {
  labelStyle ,
  checked ,
  labelPlacementStart ,
  labelPlacementEnd ,
  make ,
}
/* checked Not a pure module */
