// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../intl/Intl_Utils.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as Messages_Category$DvmAdminFrontendFr from "../../../intl/messages/category/Messages_Category.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as App_UserTypeCheckBox$DvmAdminFrontendFr from "../../../common/components/input/App_UserTypeCheckBox.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_DatesEdit_DateWithCheckbox$DvmAdminFrontendFr from "../../../common/components/dates/App_DatesEdit_DateWithCheckbox.bs.js";
import * as Category_Edit_SingleVersion_Form$DvmAdminFrontendFr from "./Category_Edit_SingleVersion_Form.bs.js";

function Category_Edit_SingleVersion_Modal(Props) {
  var openOpt = Props.open;
  var titleOpt = Props.title;
  var label = Props.label;
  var asParentLabel = Props.asParentLabel;
  var showSpinnerOpt = Props.showSpinner;
  var onClose = Props.onClose;
  var onSubmit = Props.onSubmit;
  var startDateWithoutCheckboxOpt = Props.startDateWithoutCheckbox;
  var blockOldStartDateOpt = Props.blockOldStartDate;
  var takenVersionsList = Props.takenVersionsList;
  var category = Props.category;
  var versionBeingEdited = Props.versionBeingEdited;
  var open = openOpt !== undefined ? openOpt : true;
  var title = titleOpt !== undefined ? titleOpt : Messages_Common$DvmAdminFrontendFr.editDates;
  var showSpinner = showSpinnerOpt !== undefined ? showSpinnerOpt : false;
  var startDateWithoutCheckbox = startDateWithoutCheckboxOpt !== undefined ? startDateWithoutCheckboxOpt : false;
  var blockOldStartDate = blockOldStartDateOpt !== undefined ? blockOldStartDateOpt : true;
  var intl = ReactIntl.useIntl();
  var form = Category_Edit_SingleVersion_Form$DvmAdminFrontendFr.DatesEditForm.useForm(Category_Edit_SingleVersion_Form$DvmAdminFrontendFr.initialInput, {
        categoryStartDate: category.startDate,
        categoryEndDate: category.endDate,
        takenVersionsList: takenVersionsList,
        versionID: versionBeingEdited.version
      }, Curry.__2(onSubmit));
  React.useEffect((function () {
          var __x = versionBeingEdited.startDate;
          Curry._2(form.updateStartDate, (function (input, startDate) {
                  return {
                          startDate: startDate,
                          startDateAsCategory: input.startDateAsCategory,
                          endDate: input.endDate,
                          endDateAsCategory: input.endDateAsCategory
                        };
                }), __x);
          if (Belt_Option.isNone(versionBeingEdited.startDate) && !startDateWithoutCheckbox) {
            Curry._2(form.updateStartDateAsCategory, (function (input, startDateAsCategory) {
                    return {
                            startDate: input.startDate,
                            startDateAsCategory: startDateAsCategory,
                            endDate: input.endDate,
                            endDateAsCategory: input.endDateAsCategory
                          };
                  }), true);
          }
          var __x$1 = versionBeingEdited.endDate;
          Curry._2(form.updateEndDate, (function (input, endDate) {
                  return {
                          startDate: input.startDate,
                          startDateAsCategory: input.startDateAsCategory,
                          endDate: endDate,
                          endDateAsCategory: input.endDateAsCategory
                        };
                }), __x$1);
          if (Belt_Option.isNone(versionBeingEdited.endDate)) {
            Curry._2(form.updateEndDateAsCategory, (function (input, endDateAsCategory) {
                    return {
                            startDate: input.startDate,
                            startDateAsCategory: input.startDateAsCategory,
                            endDate: input.endDate,
                            endDateAsCategory: endDateAsCategory
                          };
                  }), true);
          }
          
        }), [
        Messages_Common$DvmAdminFrontendFr.startDate,
        Messages_Common$DvmAdminFrontendFr.endDate
      ]);
  var tmp;
  if (startDateWithoutCheckbox) {
    var tmp$1 = {
      label: Messages_Common$DvmAdminFrontendFr.startDate,
      value: form.input.startDate,
      onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
              return {
                      startDate: startDate,
                      startDateAsCategory: input.startDateAsCategory,
                      endDate: input.endDate,
                      endDateAsCategory: input.endDateAsCategory
                    };
            })),
      disabled: blockOldStartDate ? Belt_Option.mapWithDefault(versionBeingEdited.startDate, false, ReDate.isPast) : false,
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
      onBlur: (function (param) {
          Curry._1(form.blurStartDate, undefined);
        })
    };
    if (form.startDateResult !== undefined) {
      tmp$1.validationResult = Caml_option.valFromOption(form.startDateResult);
    }
    tmp = React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$1);
  } else {
    var tmp$2 = {
      label: Messages_Common$DvmAdminFrontendFr.startDate,
      value: form.input.startDate,
      onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
              return {
                      startDate: startDate,
                      startDateAsCategory: input.startDateAsCategory,
                      endDate: input.endDate,
                      endDateAsCategory: input.endDateAsCategory
                    };
            })),
      disabled: form.input.startDateAsCategory || (
        blockOldStartDate ? Belt_Option.mapWithDefault(versionBeingEdited.startDate, false, ReDate.isPast) : false
      ),
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
      onBlur: (function (param) {
          Curry._1(form.blurStartDate, undefined);
        })
    };
    if (form.startDateResult !== undefined) {
      tmp$2.validationResult = Caml_option.valFromOption(form.startDateResult);
    }
    tmp = React.createElement(App_DatesEdit_DateWithCheckbox$DvmAdminFrontendFr.make, {
          checkboxElement: React.createElement(App_UserTypeCheckBox$DvmAdminFrontendFr.make, {
                label: intl.formatMessage(asParentLabel),
                checked: form.input.startDateAsCategory,
                onClick: (function (param) {
                    Curry._2(form.updateStartDateAsCategory, (function (input, startDateAsCategory) {
                            return {
                                    startDate: input.startDate,
                                    startDateAsCategory: startDateAsCategory,
                                    endDate: input.endDate,
                                    endDateAsCategory: input.endDateAsCategory
                                  };
                          }), !form.input.startDateAsCategory);
                  }),
                disabled: blockOldStartDate ? Belt_Option.mapWithDefault(versionBeingEdited.startDate, false, ReDate.isPast) : false
              }),
          children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$2)
        });
  }
  var tmp$3 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    startDate: input.startDate,
                    startDateAsCategory: input.startDateAsCategory,
                    endDate: endDate,
                    endDateAsCategory: input.endDateAsCategory
                  };
          })),
    disabled: form.input.endDateAsCategory,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
    onBlur: (function (param) {
        Curry._1(form.blurEndDate, undefined);
      })
  };
  if (form.endDateResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  var takenVersionsFiltered = Belt_List.sort(Belt_List.keep(takenVersionsList, (function (x) {
              return x.version === versionBeingEdited.version;
            })), (function (a, b) {
          return ReDate.compareAsc(Belt_Option.getWithDefault(a.startDate, new Date(0.0)), Belt_Option.getWithDefault(b.startDate, new Date(0.0)));
        }));
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: open,
              onClose: onClose,
              title: intl.formatMessage(title),
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: showSpinner,
                    children: React.createElement("form", {
                          onSubmit: (function ($$event) {
                              $$event.preventDefault();
                              Curry._1(form.submit, undefined);
                            })
                        }, React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              direction: "column",
                              style: {
                                gridRowGap: "24px"
                              }
                            }, React.createElement(Core.Grid, {
                                  children: React.createElement(Core.FormLabel, {
                                        children: label
                                      }),
                                  item: true
                                }), React.createElement(Core.Grid, {
                                  children: tmp,
                                  item: true
                                }), React.createElement(Core.Grid, {
                                  children: React.createElement(App_DatesEdit_DateWithCheckbox$DvmAdminFrontendFr.make, {
                                        checkboxElement: React.createElement(App_UserTypeCheckBox$DvmAdminFrontendFr.make, {
                                              label: intl.formatMessage(asParentLabel),
                                              checked: form.input.endDateAsCategory,
                                              onClick: (function (param) {
                                                  Curry._2(form.updateEndDateAsCategory, (function (input, endDateAsCategory) {
                                                          return {
                                                                  startDate: input.startDate,
                                                                  startDateAsCategory: input.startDateAsCategory,
                                                                  endDate: input.endDate,
                                                                  endDateAsCategory: endDateAsCategory
                                                                };
                                                        }), !form.input.endDateAsCategory);
                                                })
                                            }),
                                        children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$3)
                                      }),
                                  item: true
                                }), Belt_List.length(takenVersionsFiltered) > 0 ? React.createElement(Core.Grid, {
                                    children: null,
                                    item: true
                                  }, React.createElement("p", undefined, intl.formatMessage(Messages_Category$DvmAdminFrontendFr.versionsTaken)), React.createElement("div", undefined, Belt_Array.mapWithIndex(Belt_List.toArray(takenVersionsFiltered), (function (index, element) {
                                              return React.createElement("p", {
                                                          key: String(index)
                                                        }, "" + Belt_Option.mapWithDefault(element.startDate, "", (function (param) {
                                                                return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                              })) + " - " + Belt_Option.mapWithDefault(element.endDate, "", (function (param) {
                                                                return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                              })) + "");
                                            })))) : null, React.createElement(Core.Grid, {
                                  children: React.createElement(Core.Grid, {
                                        children: React.createElement(Core.Grid, {
                                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                                    size: "medium",
                                                    type_: "submit"
                                                  }),
                                              item: true
                                            }),
                                        container: true,
                                        justify: "flex-end"
                                      }),
                                  item: true
                                })))
                  })
            });
}

var Form;

var DateWithCheckbox;

var InputStyles;

var make = Category_Edit_SingleVersion_Modal;

export {
  Form ,
  DateWithCheckbox ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
