// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../common/utils/Utils_Form.bs.js";

var validators_gemoCode = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateMaxCharacters(param.gemoCode, 10);
    })
};

var validators_gemoLabel = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.gemoLabel);
    })
};

var validators_budgetActionType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.budgetActionType);
    })
};

var validators_startDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequiredDateCompare(param.startDate, param.endDate, "smaller", true, undefined, undefined);
    })
};

var validators_endDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequiredDateCompare(param.startDate, param.endDate, "larger", true, undefined, undefined);
    })
};

var validators_submarketCode = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.submarketCode);
    })
};

var validators_models = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateListRequired(param.models);
    })
};

var validators = {
  gemoCode: validators_gemoCode,
  gemoLabel: validators_gemoLabel,
  budgetActionType: validators_budgetActionType,
  startDate: validators_startDate,
  endDate: validators_endDate,
  submarketCode: validators_submarketCode,
  models: validators_models
};

function initialFieldsStatuses(_input) {
  return {
          gemoCode: /* Pristine */0,
          gemoLabel: /* Pristine */0,
          budgetActionType: /* Pristine */0,
          startDate: /* Pristine */0,
          endDate: /* Pristine */0,
          submarketCode: /* Pristine */0,
          models: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.gemoCode;
  var match_0 = match ? match._0 : Curry._1(validators.gemoCode.validate, input);
  var match$1 = fieldsStatuses.gemoLabel;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.gemoLabel.validate, input);
  var match$2 = fieldsStatuses.budgetActionType;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.budgetActionType.validate, input);
  var match$3 = fieldsStatuses.startDate;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.startDate.validate, input);
  var match$4 = fieldsStatuses.endDate;
  var match_0$4 = match$4 ? match$4._0 : Curry._1(validators.endDate.validate, input);
  var match$5 = fieldsStatuses.submarketCode;
  var match_0$5 = match$5 ? match$5._0 : Curry._1(validators.submarketCode.validate, input);
  var match$6 = fieldsStatuses.models;
  var match_0$6 = match$6 ? match$6._0 : Curry._1(validators.models.validate, input);
  var gemoCodeResult = match_0;
  var gemoCodeResult$1;
  if (gemoCodeResult.TAG === /* Ok */0) {
    var gemoLabelResult = match_0$1;
    if (gemoLabelResult.TAG === /* Ok */0) {
      var budgetActionTypeResult = match_0$2;
      if (budgetActionTypeResult.TAG === /* Ok */0) {
        var startDateResult = match_0$3;
        if (startDateResult.TAG === /* Ok */0) {
          var endDateResult = match_0$4;
          if (endDateResult.TAG === /* Ok */0) {
            var submarketCodeResult = match_0$5;
            if (submarketCodeResult.TAG === /* Ok */0) {
              var modelsResult = match_0$6;
              if (modelsResult.TAG === /* Ok */0) {
                return {
                        TAG: /* Valid */0,
                        output: {
                          gemoCode: gemoCodeResult._0,
                          gemoLabel: gemoLabelResult._0,
                          budgetActionType: budgetActionTypeResult._0,
                          startDate: startDateResult._0,
                          endDate: endDateResult._0,
                          submarketCode: submarketCodeResult._0,
                          models: modelsResult._0
                        },
                        fieldsStatuses: {
                          gemoCode: /* Dirty */{
                            _0: gemoCodeResult,
                            _1: /* Shown */0
                          },
                          gemoLabel: /* Dirty */{
                            _0: gemoLabelResult,
                            _1: /* Shown */0
                          },
                          budgetActionType: /* Dirty */{
                            _0: budgetActionTypeResult,
                            _1: /* Shown */0
                          },
                          startDate: /* Dirty */{
                            _0: startDateResult,
                            _1: /* Shown */0
                          },
                          endDate: /* Dirty */{
                            _0: endDateResult,
                            _1: /* Shown */0
                          },
                          submarketCode: /* Dirty */{
                            _0: submarketCodeResult,
                            _1: /* Shown */0
                          },
                          models: /* Dirty */{
                            _0: modelsResult,
                            _1: /* Shown */0
                          }
                        },
                        collectionsStatuses: undefined
                      };
              }
              gemoCodeResult$1 = gemoCodeResult;
            } else {
              gemoCodeResult$1 = gemoCodeResult;
            }
          } else {
            gemoCodeResult$1 = gemoCodeResult;
          }
        } else {
          gemoCodeResult$1 = gemoCodeResult;
        }
      } else {
        gemoCodeResult$1 = gemoCodeResult;
      }
    } else {
      gemoCodeResult$1 = gemoCodeResult;
    }
  } else {
    gemoCodeResult$1 = gemoCodeResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            gemoCode: /* Dirty */{
              _0: gemoCodeResult$1,
              _1: /* Shown */0
            },
            gemoLabel: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            budgetActionType: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            startDate: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            endDate: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            submarketCode: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            models: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurGemoCodeField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.gemoCode, validators_gemoCode, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  gemoCode: status,
                                  gemoLabel: init.gemoLabel,
                                  budgetActionType: init.budgetActionType,
                                  startDate: init.startDate,
                                  endDate: init.endDate,
                                  submarketCode: init.submarketCode,
                                  models: init.models
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurGemoLabelField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.gemoLabel, validators_gemoLabel, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  gemoCode: init.gemoCode,
                                  gemoLabel: status,
                                  budgetActionType: init.budgetActionType,
                                  startDate: init.startDate,
                                  endDate: init.endDate,
                                  submarketCode: init.submarketCode,
                                  models: init.models
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBudgetActionTypeField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.budgetActionType, validators_budgetActionType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  gemoCode: init.gemoCode,
                                  gemoLabel: init.gemoLabel,
                                  budgetActionType: status,
                                  startDate: init.startDate,
                                  endDate: init.endDate,
                                  submarketCode: init.submarketCode,
                                  models: init.models
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurStartDateField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.startDate, validators_startDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  gemoCode: init.gemoCode,
                                  gemoLabel: init.gemoLabel,
                                  budgetActionType: init.budgetActionType,
                                  startDate: status,
                                  endDate: init.endDate,
                                  submarketCode: init.submarketCode,
                                  models: init.models
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEndDateField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.endDate, validators_endDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  gemoCode: init.gemoCode,
                                  gemoLabel: init.gemoLabel,
                                  budgetActionType: init.budgetActionType,
                                  startDate: init.startDate,
                                  endDate: status,
                                  submarketCode: init.submarketCode,
                                  models: init.models
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSubmarketCodeField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.submarketCode, validators_submarketCode, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  gemoCode: init.gemoCode,
                                  gemoLabel: init.gemoLabel,
                                  budgetActionType: init.budgetActionType,
                                  startDate: init.startDate,
                                  endDate: init.endDate,
                                  submarketCode: status,
                                  models: init.models
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurModelsField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.models, validators_models, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  gemoCode: init.gemoCode,
                                  gemoLabel: init.gemoLabel,
                                  budgetActionType: init.budgetActionType,
                                  startDate: init.startDate,
                                  endDate: init.endDate,
                                  submarketCode: init.submarketCode,
                                  models: status
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */7 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */7,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */8,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */10);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */9);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */8 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */9 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */10 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateGemoCodeField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.gemoCode, state.submissionStatus, validators_gemoCode, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            gemoCode: status,
                                            gemoLabel: init.gemoLabel,
                                            budgetActionType: init.budgetActionType,
                                            startDate: init.startDate,
                                            endDate: init.endDate,
                                            submarketCode: init.submarketCode,
                                            models: init.models
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateGemoLabelField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.gemoLabel, state.submissionStatus, validators_gemoLabel, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            gemoCode: init.gemoCode,
                                            gemoLabel: status,
                                            budgetActionType: init.budgetActionType,
                                            startDate: init.startDate,
                                            endDate: init.endDate,
                                            submarketCode: init.submarketCode,
                                            models: init.models
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBudgetActionTypeField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.budgetActionType, state.submissionStatus, validators_budgetActionType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            gemoCode: init.gemoCode,
                                            gemoLabel: init.gemoLabel,
                                            budgetActionType: status,
                                            startDate: init.startDate,
                                            endDate: init.endDate,
                                            submarketCode: init.submarketCode,
                                            models: init.models
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateStartDateField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$7 = Formality.validateDependentFieldOnChange(nextInput$3, nextFieldsStatuses.contents.endDate, validators_endDate, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  gemoCode: init.gemoCode,
                                  gemoLabel: init.gemoLabel,
                                  budgetActionType: init.budgetActionType,
                                  startDate: init.startDate,
                                  endDate: status,
                                  submarketCode: init.submarketCode,
                                  models: init.models
                                };
                        }));
                  if (result$7 !== undefined) {
                    nextFieldsStatuses.contents = result$7;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, nextFieldsStatuses.contents.startDate, state.submissionStatus, validators_startDate, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            gemoCode: init.gemoCode,
                                            gemoLabel: init.gemoLabel,
                                            budgetActionType: init.budgetActionType,
                                            startDate: status,
                                            endDate: init.endDate,
                                            submarketCode: init.submarketCode,
                                            models: init.models
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEndDateField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$8 = Formality.validateDependentFieldOnChange(nextInput$4, nextFieldsStatuses$1.contents.startDate, validators_startDate, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  gemoCode: init.gemoCode,
                                  gemoLabel: init.gemoLabel,
                                  budgetActionType: init.budgetActionType,
                                  startDate: status,
                                  endDate: init.endDate,
                                  submarketCode: init.submarketCode,
                                  models: init.models
                                };
                        }));
                  if (result$8 !== undefined) {
                    nextFieldsStatuses$1.contents = result$8;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, nextFieldsStatuses$1.contents.endDate, state.submissionStatus, validators_endDate, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            gemoCode: init.gemoCode,
                                            gemoLabel: init.gemoLabel,
                                            budgetActionType: init.budgetActionType,
                                            startDate: init.startDate,
                                            endDate: status,
                                            submarketCode: init.submarketCode,
                                            models: init.models
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSubmarketCodeField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.submarketCode, state.submissionStatus, validators_submarketCode, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            gemoCode: init.gemoCode,
                                            gemoLabel: init.gemoLabel,
                                            budgetActionType: init.budgetActionType,
                                            startDate: init.startDate,
                                            endDate: init.endDate,
                                            submarketCode: status,
                                            models: init.models
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateModelsField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.models, state.submissionStatus, validators_models, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            gemoCode: init.gemoCode,
                                            gemoLabel: init.gemoLabel,
                                            budgetActionType: init.budgetActionType,
                                            startDate: init.startDate,
                                            endDate: init.endDate,
                                            submarketCode: init.submarketCode,
                                            models: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */7 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */8 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */9 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateGemoCode: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateGemoCodeField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateGemoLabel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateGemoLabelField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBudgetActionType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBudgetActionTypeField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateStartDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateStartDateField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEndDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEndDateField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateSubmarketCode: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSubmarketCodeField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateModels: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelsField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurGemoCode: (function (param) {
              Curry._1(dispatch, /* BlurGemoCodeField */0);
            }),
          blurGemoLabel: (function (param) {
              Curry._1(dispatch, /* BlurGemoLabelField */1);
            }),
          blurBudgetActionType: (function (param) {
              Curry._1(dispatch, /* BlurBudgetActionTypeField */2);
            }),
          blurStartDate: (function (param) {
              Curry._1(dispatch, /* BlurStartDateField */3);
            }),
          blurEndDate: (function (param) {
              Curry._1(dispatch, /* BlurEndDateField */4);
            }),
          blurSubmarketCode: (function (param) {
              Curry._1(dispatch, /* BlurSubmarketCodeField */5);
            }),
          blurModels: (function (param) {
              Curry._1(dispatch, /* BlurModelsField */6);
            }),
          gemoCodeResult: Formality.exposeFieldResult(state.fieldsStatuses.gemoCode),
          gemoLabelResult: Formality.exposeFieldResult(state.fieldsStatuses.gemoLabel),
          budgetActionTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.budgetActionType),
          startDateResult: Formality.exposeFieldResult(state.fieldsStatuses.startDate),
          endDateResult: Formality.exposeFieldResult(state.fieldsStatuses.endDate),
          submarketCodeResult: Formality.exposeFieldResult(state.fieldsStatuses.submarketCode),
          modelsResult: Formality.exposeFieldResult(state.fieldsStatuses.models),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.gemoCode || match.gemoLabel || match.budgetActionType || match.startDate || match.endDate || match.submarketCode || match.models) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */7);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */8);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */9);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */9,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */10);
            })
        };
}

var GemoEditForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialInput = {
  gemoCode: "",
  gemoLabel: "",
  budgetActionType: "",
  startDate: undefined,
  endDate: undefined,
  submarketCode: "",
  models: /* [] */0
};

export {
  GemoEditForm ,
  initialInput ,
}
/* react Not a pure module */
