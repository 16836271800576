// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";

function refreshVersions(form, selectedVersionsMap) {
  return Belt_Array.mapWithIndex(form.input.versions, (function (index, value) {
                Belt_Option.mapWithDefault(Belt_MapString.get(selectedVersionsMap, value.versionID), (Curry._3(form.updateVersionSelected, index, (function (param, selected) {
                              return {
                                      versions: Belt_Array.mapWithIndex(param.versions, (function (indexRow, row) {
                                              if (indexRow === index) {
                                                return {
                                                        selected: selected,
                                                        versionID: row.versionID,
                                                        versionLabel: row.versionLabel,
                                                        startDate: row.startDate,
                                                        startDateAsCategory: row.startDateAsCategory,
                                                        endDate: row.endDate,
                                                        endDateAsCategory: row.endDateAsCategory
                                                      };
                                              } else {
                                                return row;
                                              }
                                            }))
                                    };
                            }), false), Curry._3(form.updateVersionStartDate, index, (function (param, startDate) {
                              return {
                                      versions: Belt_Array.mapWithIndex(param.versions, (function (indexRow, row) {
                                              if (indexRow === index) {
                                                return {
                                                        selected: row.selected,
                                                        versionID: row.versionID,
                                                        versionLabel: row.versionLabel,
                                                        startDate: startDate,
                                                        startDateAsCategory: row.startDateAsCategory,
                                                        endDate: row.endDate,
                                                        endDateAsCategory: row.endDateAsCategory
                                                      };
                                              } else {
                                                return row;
                                              }
                                            }))
                                    };
                            }), undefined), Curry._3(form.updateVersionEndDate, index, (function (param, endDate) {
                              return {
                                      versions: Belt_Array.mapWithIndex(param.versions, (function (indexRow, row) {
                                              if (indexRow === index) {
                                                return {
                                                        selected: row.selected,
                                                        versionID: row.versionID,
                                                        versionLabel: row.versionLabel,
                                                        startDate: row.startDate,
                                                        startDateAsCategory: row.startDateAsCategory,
                                                        endDate: endDate,
                                                        endDateAsCategory: row.endDateAsCategory
                                                      };
                                              } else {
                                                return row;
                                              }
                                            }))
                                    };
                            }), undefined), Curry._3(form.updateVersionStartDateAsCategory, index, (function (param, startDateAsCategory) {
                              return {
                                      versions: Belt_Array.mapWithIndex(param.versions, (function (indexRow, row) {
                                              if (indexRow === index) {
                                                return {
                                                        selected: row.selected,
                                                        versionID: row.versionID,
                                                        versionLabel: row.versionLabel,
                                                        startDate: row.startDate,
                                                        startDateAsCategory: startDateAsCategory,
                                                        endDate: row.endDate,
                                                        endDateAsCategory: row.endDateAsCategory
                                                      };
                                              } else {
                                                return row;
                                              }
                                            }))
                                    };
                            }), true), Curry._3(form.updateVersionEndDateAsCategory, index, (function (param, endDateAsCategory) {
                              return {
                                      versions: Belt_Array.mapWithIndex(param.versions, (function (indexRow, row) {
                                              if (indexRow === index) {
                                                return {
                                                        selected: row.selected,
                                                        versionID: row.versionID,
                                                        versionLabel: row.versionLabel,
                                                        startDate: row.startDate,
                                                        startDateAsCategory: row.startDateAsCategory,
                                                        endDate: row.endDate,
                                                        endDateAsCategory: endDateAsCategory
                                                      };
                                              } else {
                                                return row;
                                              }
                                            }))
                                    };
                            }), true)), (function (val) {
                        Curry._3(form.updateVersionSelected, index, (function (param, selected) {
                                return {
                                        versions: Belt_Array.mapWithIndex(param.versions, (function (indexRow, row) {
                                                if (indexRow === index) {
                                                  return {
                                                          selected: selected,
                                                          versionID: row.versionID,
                                                          versionLabel: row.versionLabel,
                                                          startDate: row.startDate,
                                                          startDateAsCategory: row.startDateAsCategory,
                                                          endDate: row.endDate,
                                                          endDateAsCategory: row.endDateAsCategory
                                                        };
                                                } else {
                                                  return row;
                                                }
                                              }))
                                      };
                              }), val.selected);
                        Curry._3(form.updateVersionStartDate, index, (function (param, startDate) {
                                return {
                                        versions: Belt_Array.mapWithIndex(param.versions, (function (indexRow, row) {
                                                if (indexRow === index) {
                                                  return {
                                                          selected: row.selected,
                                                          versionID: row.versionID,
                                                          versionLabel: row.versionLabel,
                                                          startDate: startDate,
                                                          startDateAsCategory: row.startDateAsCategory,
                                                          endDate: row.endDate,
                                                          endDateAsCategory: row.endDateAsCategory
                                                        };
                                                } else {
                                                  return row;
                                                }
                                              }))
                                      };
                              }), val.startDate);
                        Curry._3(form.updateVersionEndDate, index, (function (param, endDate) {
                                return {
                                        versions: Belt_Array.mapWithIndex(param.versions, (function (indexRow, row) {
                                                if (indexRow === index) {
                                                  return {
                                                          selected: row.selected,
                                                          versionID: row.versionID,
                                                          versionLabel: row.versionLabel,
                                                          startDate: row.startDate,
                                                          startDateAsCategory: row.startDateAsCategory,
                                                          endDate: endDate,
                                                          endDateAsCategory: row.endDateAsCategory
                                                        };
                                                } else {
                                                  return row;
                                                }
                                              }))
                                      };
                              }), val.endDate);
                        Curry._3(form.updateVersionStartDateAsCategory, index, (function (param, startDateAsCategory) {
                                return {
                                        versions: Belt_Array.mapWithIndex(param.versions, (function (indexRow, row) {
                                                if (indexRow === index) {
                                                  return {
                                                          selected: row.selected,
                                                          versionID: row.versionID,
                                                          versionLabel: row.versionLabel,
                                                          startDate: row.startDate,
                                                          startDateAsCategory: startDateAsCategory,
                                                          endDate: row.endDate,
                                                          endDateAsCategory: row.endDateAsCategory
                                                        };
                                                } else {
                                                  return row;
                                                }
                                              }))
                                      };
                              }), val.startDateAsCategory);
                        Curry._3(form.updateVersionEndDateAsCategory, index, (function (param, endDateAsCategory) {
                                return {
                                        versions: Belt_Array.mapWithIndex(param.versions, (function (indexRow, row) {
                                                if (indexRow === index) {
                                                  return {
                                                          selected: row.selected,
                                                          versionID: row.versionID,
                                                          versionLabel: row.versionLabel,
                                                          startDate: row.startDate,
                                                          startDateAsCategory: row.startDateAsCategory,
                                                          endDate: row.endDate,
                                                          endDateAsCategory: endDateAsCategory
                                                        };
                                                } else {
                                                  return row;
                                                }
                                              }))
                                      };
                              }), val.endDateAsCategory);
                      }));
              }));
}

var Form;

export {
  Form ,
  refreshVersions ,
}
/* No side effect */
