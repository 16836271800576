// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../common/types/App_Types_Date.bs.js";

var Fields_countryCode = [
  "countrycode",
  "country_code"
];

var Fields = {
  countryCode: Fields_countryCode,
  localActionTypeID: "localActionTypeID",
  localActionTypeLabel: "localActionTypeLabel",
  localActionDescription: "localActionDescription",
  localActionTrigger: "localActionTrigger",
  appCorpChannel: "appCorpChannel",
  brand: "brand",
  distributionMode: "distributionMode",
  appClientChannel: "appClientChannel",
  appSubChannel: "appSubChannel",
  marketingCorpCategory: "marketingCorpCategory",
  marketingCorpType: "marketingCorpType",
  vmeDestination: "vmeDestination",
  controllingCorpTypeID: "controllingCorpTypeID",
  controllingCorpTypeLabel: "controllingCorpTypeLabel",
  definition: "definition",
  paidTo: "paidTo",
  controllingAppStartDate: "controllingAppStartDate",
  controllingAppEndDate: "controllingAppEndDate"
};

function referentialRow_encode(v) {
  return Js_dict.fromArray([
              [
                "countryCode",
                Decco.stringToJson(v.countryCode)
              ],
              [
                "localActionTypeID",
                Decco.stringToJson(v.localActionTypeID)
              ],
              [
                "localActionTypeLabel",
                Decco.optionToJson(Decco.stringToJson, v.localActionTypeLabel)
              ],
              [
                "localActionDescription",
                Decco.optionToJson(Decco.stringToJson, v.localActionDescription)
              ],
              [
                "localActionTrigger",
                Decco.optionToJson(Decco.stringToJson, v.localActionTrigger)
              ],
              [
                "appCorpChannel",
                Decco.optionToJson(Decco.stringToJson, v.appCorpChannel)
              ],
              [
                "brand",
                Decco.optionToJson(Decco.stringToJson, v.brand)
              ],
              [
                "distributionMode",
                Decco.optionToJson(Decco.stringToJson, v.distributionMode)
              ],
              [
                "appClientChannel",
                Decco.optionToJson(Decco.stringToJson, v.appClientChannel)
              ],
              [
                "appSubChannel",
                Decco.optionToJson(Decco.stringToJson, v.appSubChannel)
              ],
              [
                "marketingCorpCategory",
                Decco.optionToJson(Decco.stringToJson, v.marketingCorpCategory)
              ],
              [
                "marketingCorpType",
                Decco.optionToJson(Decco.stringToJson, v.marketingCorpType)
              ],
              [
                "vmeDestination",
                Decco.optionToJson(Decco.stringToJson, v.vmeDestination)
              ],
              [
                "controllingCorpTypeID",
                Decco.optionToJson(Decco.stringToJson, v.controllingCorpTypeID)
              ],
              [
                "controllingCorpTypeLabel",
                Decco.optionToJson(Decco.stringToJson, v.controllingCorpTypeLabel)
              ],
              [
                "definition",
                Decco.optionToJson(Decco.stringToJson, v.definition)
              ],
              [
                "paidTo",
                Decco.optionToJson(Decco.stringToJson, v.paidTo)
              ],
              [
                "controllingAppStartDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.controllingAppStartDate)
              ],
              [
                "controllingAppEndDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.controllingAppEndDate)
              ]
            ]);
}

function referentialRow_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var countryCode = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "countryCode"), null));
  if (countryCode.TAG === /* Ok */0) {
    var localActionTypeID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "localActionTypeID"), null));
    if (localActionTypeID.TAG === /* Ok */0) {
      var localActionTypeLabel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "localActionTypeLabel"), null));
      if (localActionTypeLabel.TAG === /* Ok */0) {
        var localActionDescription = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "localActionDescription"), null));
        if (localActionDescription.TAG === /* Ok */0) {
          var localActionTrigger = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "localActionTrigger"), null));
          if (localActionTrigger.TAG === /* Ok */0) {
            var appCorpChannel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "appCorpChannel"), null));
            if (appCorpChannel.TAG === /* Ok */0) {
              var brand = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "brand"), null));
              if (brand.TAG === /* Ok */0) {
                var distributionMode = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "distributionMode"), null));
                if (distributionMode.TAG === /* Ok */0) {
                  var appClientChannel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "appClientChannel"), null));
                  if (appClientChannel.TAG === /* Ok */0) {
                    var appSubChannel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "appSubChannel"), null));
                    if (appSubChannel.TAG === /* Ok */0) {
                      var marketingCorpCategory = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "marketingCorpCategory"), null));
                      if (marketingCorpCategory.TAG === /* Ok */0) {
                        var marketingCorpType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "marketingCorpType"), null));
                        if (marketingCorpType.TAG === /* Ok */0) {
                          var vmeDestination = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "vmeDestination"), null));
                          if (vmeDestination.TAG === /* Ok */0) {
                            var controllingCorpTypeID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "controllingCorpTypeID"), null));
                            if (controllingCorpTypeID.TAG === /* Ok */0) {
                              var controllingCorpTypeLabel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "controllingCorpTypeLabel"), null));
                              if (controllingCorpTypeLabel.TAG === /* Ok */0) {
                                var definition = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "definition"), null));
                                if (definition.TAG === /* Ok */0) {
                                  var paidTo = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "paidTo"), null));
                                  if (paidTo.TAG === /* Ok */0) {
                                    var controllingAppStartDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "controllingAppStartDate"), null));
                                    if (controllingAppStartDate.TAG === /* Ok */0) {
                                      var controllingAppEndDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "controllingAppEndDate"), null));
                                      if (controllingAppEndDate.TAG === /* Ok */0) {
                                        return {
                                                TAG: /* Ok */0,
                                                _0: {
                                                  countryCode: countryCode._0,
                                                  localActionTypeID: localActionTypeID._0,
                                                  localActionTypeLabel: localActionTypeLabel._0,
                                                  localActionDescription: localActionDescription._0,
                                                  localActionTrigger: localActionTrigger._0,
                                                  appCorpChannel: appCorpChannel._0,
                                                  brand: brand._0,
                                                  distributionMode: distributionMode._0,
                                                  appClientChannel: appClientChannel._0,
                                                  appSubChannel: appSubChannel._0,
                                                  marketingCorpCategory: marketingCorpCategory._0,
                                                  marketingCorpType: marketingCorpType._0,
                                                  vmeDestination: vmeDestination._0,
                                                  controllingCorpTypeID: controllingCorpTypeID._0,
                                                  controllingCorpTypeLabel: controllingCorpTypeLabel._0,
                                                  definition: definition._0,
                                                  paidTo: paidTo._0,
                                                  controllingAppStartDate: controllingAppStartDate._0,
                                                  controllingAppEndDate: controllingAppEndDate._0
                                                }
                                              };
                                      }
                                      var e = controllingAppEndDate._0;
                                      return {
                                              TAG: /* Error */1,
                                              _0: {
                                                path: ".controllingAppEndDate" + e.path,
                                                message: e.message,
                                                value: e.value
                                              }
                                            };
                                    }
                                    var e$1 = controllingAppStartDate._0;
                                    return {
                                            TAG: /* Error */1,
                                            _0: {
                                              path: ".controllingAppStartDate" + e$1.path,
                                              message: e$1.message,
                                              value: e$1.value
                                            }
                                          };
                                  }
                                  var e$2 = paidTo._0;
                                  return {
                                          TAG: /* Error */1,
                                          _0: {
                                            path: ".paidTo" + e$2.path,
                                            message: e$2.message,
                                            value: e$2.value
                                          }
                                        };
                                }
                                var e$3 = definition._0;
                                return {
                                        TAG: /* Error */1,
                                        _0: {
                                          path: ".definition" + e$3.path,
                                          message: e$3.message,
                                          value: e$3.value
                                        }
                                      };
                              }
                              var e$4 = controllingCorpTypeLabel._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".controllingCorpTypeLabel" + e$4.path,
                                        message: e$4.message,
                                        value: e$4.value
                                      }
                                    };
                            }
                            var e$5 = controllingCorpTypeID._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".controllingCorpTypeID" + e$5.path,
                                      message: e$5.message,
                                      value: e$5.value
                                    }
                                  };
                          }
                          var e$6 = vmeDestination._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".vmeDestination" + e$6.path,
                                    message: e$6.message,
                                    value: e$6.value
                                  }
                                };
                        }
                        var e$7 = marketingCorpType._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".marketingCorpType" + e$7.path,
                                  message: e$7.message,
                                  value: e$7.value
                                }
                              };
                      }
                      var e$8 = marketingCorpCategory._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".marketingCorpCategory" + e$8.path,
                                message: e$8.message,
                                value: e$8.value
                              }
                            };
                    }
                    var e$9 = appSubChannel._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".appSubChannel" + e$9.path,
                              message: e$9.message,
                              value: e$9.value
                            }
                          };
                  }
                  var e$10 = appClientChannel._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".appClientChannel" + e$10.path,
                            message: e$10.message,
                            value: e$10.value
                          }
                        };
                }
                var e$11 = distributionMode._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".distributionMode" + e$11.path,
                          message: e$11.message,
                          value: e$11.value
                        }
                      };
              }
              var e$12 = brand._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".brand" + e$12.path,
                        message: e$12.message,
                        value: e$12.value
                      }
                    };
            }
            var e$13 = appCorpChannel._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".appCorpChannel" + e$13.path,
                      message: e$13.message,
                      value: e$13.value
                    }
                  };
          }
          var e$14 = localActionTrigger._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".localActionTrigger" + e$14.path,
                    message: e$14.message,
                    value: e$14.value
                  }
                };
        }
        var e$15 = localActionDescription._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".localActionDescription" + e$15.path,
                  message: e$15.message,
                  value: e$15.value
                }
              };
      }
      var e$16 = localActionTypeLabel._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".localActionTypeLabel" + e$16.path,
                message: e$16.message,
                value: e$16.value
              }
            };
    }
    var e$17 = localActionTypeID._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".localActionTypeID" + e$17.path,
              message: e$17.message,
              value: e$17.value
            }
          };
  }
  var e$18 = countryCode._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".countryCode" + e$18.path,
            message: e$18.message,
            value: e$18.value
          }
        };
}

function referentialList_encode(v) {
  return Decco.listToJson(referentialRow_encode, v);
}

function referentialList_decode(v) {
  return Decco.listFromJson(referentialRow_decode, v);
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(referentialRow_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(referentialRow_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var ReferentialRows = {
  referentialRow_encode: referentialRow_encode,
  referentialRow_decode: referentialRow_decode,
  referentialList_encode: referentialList_encode,
  referentialList_decode: referentialList_decode,
  searchResult_encode: searchResult_encode,
  searchResult_decode: searchResult_decode
};

export {
  Fields ,
  ReferentialRows ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
