// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../api/RestApi.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../common/utils/Utils_Alert.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../../common/types/App_Types_Input.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../context/user/User_Types_Utils.bs.js";
import * as Bonuses_Search_Form$DvmAdminFrontendFr from "./Bonuses_Search_Form.bs.js";
import * as Bonuses_Search_Types$DvmAdminFrontendFr from "./Bonuses_Search_Types.bs.js";

function fetchBonusesSearch(submissionCallbacks, submissionCallbacksModal, submissionCallbacksModalAdditional, submissionCallbacksModalAdditionalSecond, submissionCallbacksModalVO, userManager, setResult, queryParams, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Bonuses_Search_Types$DvmAdminFrontendFr.BonusRows.searchResult_decode, undefined, userManager, selectedRole, "/bonuses/automatic" + queryParams + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          Belt_Option.forEach(submissionCallbacksModal, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          Belt_Option.forEach(submissionCallbacksModalAdditional, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          Belt_Option.forEach(submissionCallbacksModalAdditionalSecond, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          Belt_Option.forEach(submissionCallbacksModalVO, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchBonusesSearch */0);
              }));
        Belt_Option.forEach(submissionCallbacksModal, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchBonusesSearch */0);
              }));
        Belt_Option.forEach(submissionCallbacksModalAdditional, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchBonusesSearch */0);
              }));
        Belt_Option.forEach(submissionCallbacksModalAdditionalSecond, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchBonusesSearch */0);
              }));
        Belt_Option.forEach(submissionCallbacksModalVO, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchBonusesSearch */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchBonusesManageSearch(submissionCallbacks, userManager, setResult, queryParams, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Bonuses_Search_Types$DvmAdminFrontendFr.BonusRows.searchResult_decode, undefined, userManager, selectedRole, "/bonuses/manual" + queryParams + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchBonusesSearch */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function queryForStatuses(alert, userManager, setStatuses, selectedRole) {
  RestApi$DvmAdminFrontendFr.call("GET", Bonuses_Search_Types$DvmAdminFrontendFr.statusList_decode, undefined, userManager, selectedRole, "/dropdown/statuses").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var statuses = result._0;
          return Curry._1(setStatuses, (function (param) {
                        if (selectedRole === /* Dealer */7 || User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole)) {
                          return Belt_List.keep(statuses, (function (x) {
                                        return x.paramOne !== /* REPARCELED */9;
                                      }));
                        } else {
                          return statuses;
                        }
                      }));
        }
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: result._0
                  }
                }));
        return Curry._1(setStatuses, (function (param) {
                      return /* [] */0;
                    }));
      });
}

function getActions(alert, userManager, setActions, selectedRole, actionType, year, showBonusesPlusHistory, whatType, param) {
  Curry._1(setActions, (function (param) {
          return /* Pending */1;
        }));
  var filterString = Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                hd: Belt_Option.mapWithDefault(actionType, undefined, (function (x) {
                        return "actionType=" + x;
                      })),
                tl: {
                  hd: showBonusesPlusHistory ? Belt_Option.mapWithDefault(year, undefined, (function (x) {
                            return "actionYear=" + x;
                          })) : "actionYear=" + Belt_Option.getWithDefault(year, Bonuses_Search_Form$DvmAdminFrontendFr.getLastFourYearsToFilter(undefined)),
                  tl: /* [] */0
                }
              }, (function (x) {
                  return x;
                }))), ";", (function (x) {
          return x;
        }));
  var filters = filterString.length > 0 ? "?filter=" + filterString : "";
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.twoParamList_decode, undefined, userManager, selectedRole, whatType === "nonfleet" ? "/dropdown/actions/modular/withBonus" + filters : "/dropdown/actions/withBonus" + filters).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var actions = result._0;
          return Curry._1(setActions, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: actions
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setActions, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchModelVersionsFiltered(setResult, userManager, selectedRole, year, showBonusesPlusHistory, actionType, whatType) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var filterString = Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                hd: Belt_Option.mapWithDefault(actionType, undefined, (function (x) {
                        return "actionType=" + x;
                      })),
                tl: {
                  hd: showBonusesPlusHistory ? Belt_Option.mapWithDefault(year, undefined, (function (x) {
                            return "actionYear=" + x;
                          })) : "actionYear=" + Belt_Option.getWithDefault(year, Bonuses_Search_Form$DvmAdminFrontendFr.getLastFourYearsToFilter(undefined)),
                  tl: /* [] */0
                }
              }, (function (x) {
                  return x;
                }))), ";", (function (x) {
          return x;
        }));
  var filters = filterString.length > 0 ? "?filter=" + filterString : "";
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.parentChildList_decode, undefined, userManager, selectedRole, whatType === "nonfleet" ? "/dropdown/modelVersions/withBonus/modular" + filters : "/dropdown/modelVersions/withBonus" + filters).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var modelVersions = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: modelVersions
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

var FormModal;

var FormModalAdditional;

var FormModalAdditionalSecond;

var FormModalVO;

export {
  Form ,
  FormModal ,
  FormModalAdditional ,
  FormModalAdditionalSecond ,
  FormModalVO ,
  fetchBonusesSearch ,
  fetchBonusesManageSearch ,
  queryForStatuses ,
  getActions ,
  fetchModelVersionsFiltered ,
}
/* react Not a pure module */
