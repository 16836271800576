// Generated by ReScript, PLEASE EDIT WITH CARE


var titleCreate = {
  id: "413eb3d3-3c43-482e-8856-757217fe844f",
  defaultMessage: "Créer un nouveau contrat"
};

var titleModify = {
  id: "989ca328-bebe-4823-b217-7b1f3aaf257a",
  defaultMessage: "Modifier un contrat"
};

var contractName = {
  id: "258f4ff0-d354-4fc7-81be-523ab8e6ada6",
  defaultMessage: "Nom du contrat"
};

var contractID = {
  id: "1c896b7c-bb3e-479a-a556-95d3ca9a216c",
  defaultMessage: "Code Loueur/Protocole"
};

var managerListLink = {
  id: "257dc532-5d9c-42e1-b8c0-cd94f1d146f9",
  defaultMessage: "Voir la liste des intervenants"
};

var contractPeriodTitle = {
  id: "7d310003-8db9-4c6d-ade1-cc20e35425b3",
  defaultMessage: "Période de validité du contrat"
};

var attachmentExists = {
  id: "ae5e7a86-ae11-4913-92c6-9f46fa6bdc90",
  defaultMessage: "Le rattachement existe déjà"
};

var duplicateSiren = {
  id: "c2fe4965-9b36-4764-adbd-bdb118d93c3b",
  defaultMessage: "Le SIREN est déjà ajouté à la liste"
};

var asContract = {
  id: "6a631873-436a-4f8d-8eff-6624512b0a7d",
  defaultMessage: "Date d'application du contrat"
};

var noAttachment = {
  id: "b66130a6-e2f1-4528-931f-2ab2eab2b45e",
  defaultMessage: "Il doit y avoir au moins un rattachement"
};

var createContract = {
  id: "089adda5-5880-428e-830a-85ef65839f96",
  defaultMessage: "Créer le contrat"
};

var editContract = {
  id: "d271e71f-d0cc-436a-8a46-b27860dabf32",
  defaultMessage: "Modifier le contrat"
};

var addAttachments = {
  id: "cdda24cf-b614-433f-820a-1cd8028148a9",
  defaultMessage: "+ Nouvelle société"
};

var backToList = {
  id: "992d4ff1-1dca-4550-ab10-0f92fb951601",
  defaultMessage: "RETOUR À LA LISTE DES CONTRATS"
};

var editAttachments = {
  id: "80bee206-93bd-46ff-989f-3b9b45638473",
  defaultMessage: "Gérer les rattachements"
};

var showAttachments = {
  id: "329b5783-fe44-4456-a606-0867e9b35309",
  defaultMessage: "Rechercher les rattachements"
};

var addAttachmentsModalLabel = {
  id: "e2b89f9b-4375-4d59-964e-cfb92606d804",
  defaultMessage: "Ajouter des rattachements au contrat"
};

var addAttachmentsNumberOfRows = {
  id: "c401bd14-3050-4dac-9217-f07c59590895",
  defaultMessage: "Choisissez le nombre de sociétés à ajouter"
};

var contractInformation = {
  id: "9122d329-7ea4-43a0-b931-c8873ddb081b",
  defaultMessage: "Informations contrat"
};

var contractType = {
  id: "3d3f31a7-d040-4b1d-b5f9-c05b9a43b51b",
  defaultMessage: "Type de contrat"
};

var contractDatesHaveIssue = {
  id: "69683025-49f5-43f1-a171-0641939faeff",
  defaultMessage: "Les dates d'application sont invalides"
};

var referenceLLD = {
  id: "42cc7362-8f3a-4775-b9cd-1a511643df10",
  defaultMessage: "Référence LLD"
};

var datesModifyError = {
  id: "f8be6613-5b60-4377-927b-77b7d785d8e3",
  defaultMessage: "Date postérieure à la date de début d’application"
};

var attachmentOverlapError = {
  id: "25b19f20-49cd-42ff-8a73-3fb84dd1fa5e",
  defaultMessage: "Chevauchement de période de rattachement"
};

var editApplyDate = {
  id: "9c601f16-59c1-42b2-beee-b28b2fb6ca40",
  defaultMessage: "Appliquer le changement à partir de la date"
};

var copyAttachments = {
  id: "8a8aa2c1-5542-40be-a55b-768bd0e82889",
  defaultMessage: "Voulez-vous copier les rattachements ?"
};

var newStartDate = {
  id: "36a03570-0d2a-4c24-8095-fcd4f59ddd2e",
  defaultMessage: "Changer la date de début"
};

var copyAttachmentOverlapIssue = {
  id: "5893b88d-6c3d-4e29-83ad-c3a6fd20e772",
  defaultMessage: " Impossible de copier les rattachements. Chevauchement entre:"
};

var attachmentSIRENendDateTooLow = {
  id: "6ec36127-1f48-4113-953b-e58a633111be",
  defaultMessage: "Le SIREN est fermé"
};

export {
  titleCreate ,
  titleModify ,
  contractName ,
  contractID ,
  managerListLink ,
  contractPeriodTitle ,
  attachmentExists ,
  duplicateSiren ,
  asContract ,
  noAttachment ,
  createContract ,
  editContract ,
  addAttachments ,
  backToList ,
  editAttachments ,
  showAttachments ,
  addAttachmentsModalLabel ,
  addAttachmentsNumberOfRows ,
  contractInformation ,
  contractType ,
  contractDatesHaveIssue ,
  referenceLLD ,
  datesModifyError ,
  attachmentOverlapError ,
  editApplyDate ,
  copyAttachments ,
  newStartDate ,
  copyAttachmentOverlapIssue ,
  attachmentSIRENendDateTooLow ,
}
/* No side effect */
