// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";

var Fields = {
  siren: "siren",
  budgetType: "budget",
  contractID: "contractID",
  companyName: "companyName",
  startDate: "startDate",
  endDate: "endDate"
};

function attachment_encode(v) {
  return Js_dict.fromArray([
              [
                "siren",
                Decco.stringToJson(v.siren)
              ],
              [
                "budget",
                Decco.optionToJson(Decco.stringToJson, v.budget)
              ],
              [
                "contractID",
                Decco.optionToJson(Decco.stringToJson, v.contractID)
              ],
              [
                "companyName",
                Decco.optionToJson(Decco.stringToJson, v.companyName)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function attachment_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var siren = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "siren"), null));
  if (siren.TAG === /* Ok */0) {
    var budget = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "budget"), null));
    if (budget.TAG === /* Ok */0) {
      var contractID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractID"), null));
      if (contractID.TAG === /* Ok */0) {
        var companyName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "companyName"), null));
        if (companyName.TAG === /* Ok */0) {
          var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
          if (startDate.TAG === /* Ok */0) {
            var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
            if (endDate.TAG === /* Ok */0) {
              return {
                      TAG: /* Ok */0,
                      _0: {
                        siren: siren._0,
                        budget: budget._0,
                        contractID: contractID._0,
                        companyName: companyName._0,
                        startDate: startDate._0,
                        endDate: endDate._0
                      }
                    };
            }
            var e = endDate._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".endDate" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = startDate._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".startDate" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = companyName._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".companyName" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = contractID._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".contractID" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = budget._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".budget" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = siren._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".siren" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function companyDetail_encode(v) {
  return Js_dict.fromArray([
              [
                "siret",
                Decco.stringToJson(v.siret)
              ],
              [
                "siretName",
                Decco.optionToJson(Decco.stringToJson, v.siretName)
              ],
              [
                "street",
                Decco.optionToJson(Decco.stringToJson, v.street)
              ],
              [
                "streetNbr",
                Decco.optionToJson(Decco.stringToJson, v.streetNbr)
              ],
              [
                "additionalAddressInfo",
                Decco.optionToJson(Decco.stringToJson, v.additionalAddressInfo)
              ],
              [
                "city",
                Decco.optionToJson(Decco.stringToJson, v.city)
              ],
              [
                "zipCode",
                Decco.optionToJson(Decco.stringToJson, v.zipCode)
              ],
              [
                "countryCode",
                Decco.optionToJson(Decco.stringToJson, v.countryCode)
              ],
              [
                "historicAttachments",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(attachment_encode, param);
                      }), v.historicAttachments)
              ],
              [
                "codeAPE",
                Decco.optionToJson(Decco.stringToJson, v.codeAPE)
              ],
              [
                "descriptionAPE",
                Decco.optionToJson(Decco.stringToJson, v.descriptionAPE)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function companyDetail_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var siret = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "siret"), null));
  if (siret.TAG === /* Ok */0) {
    var siretName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "siretName"), null));
    if (siretName.TAG === /* Ok */0) {
      var street = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "street"), null));
      if (street.TAG === /* Ok */0) {
        var streetNbr = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "streetNbr"), null));
        if (streetNbr.TAG === /* Ok */0) {
          var additionalAddressInfo = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "additionalAddressInfo"), null));
          if (additionalAddressInfo.TAG === /* Ok */0) {
            var city = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "city"), null));
            if (city.TAG === /* Ok */0) {
              var zipCode = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "zipCode"), null));
              if (zipCode.TAG === /* Ok */0) {
                var countryCode = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "countryCode"), null));
                if (countryCode.TAG === /* Ok */0) {
                  var historicAttachments = Decco.optionFromJson((function (param) {
                          return Decco.listFromJson(attachment_decode, param);
                        }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "historicAttachments"), null));
                  if (historicAttachments.TAG === /* Ok */0) {
                    var codeAPE = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "codeAPE"), null));
                    if (codeAPE.TAG === /* Ok */0) {
                      var descriptionAPE = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "descriptionAPE"), null));
                      if (descriptionAPE.TAG === /* Ok */0) {
                        var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
                        if (endDate.TAG === /* Ok */0) {
                          return {
                                  TAG: /* Ok */0,
                                  _0: {
                                    siret: siret._0,
                                    siretName: siretName._0,
                                    street: street._0,
                                    streetNbr: streetNbr._0,
                                    additionalAddressInfo: additionalAddressInfo._0,
                                    city: city._0,
                                    zipCode: zipCode._0,
                                    countryCode: countryCode._0,
                                    historicAttachments: historicAttachments._0,
                                    codeAPE: codeAPE._0,
                                    descriptionAPE: descriptionAPE._0,
                                    endDate: endDate._0
                                  }
                                };
                        }
                        var e = endDate._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".endDate" + e.path,
                                  message: e.message,
                                  value: e.value
                                }
                              };
                      }
                      var e$1 = descriptionAPE._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".descriptionAPE" + e$1.path,
                                message: e$1.message,
                                value: e$1.value
                              }
                            };
                    }
                    var e$2 = codeAPE._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".codeAPE" + e$2.path,
                              message: e$2.message,
                              value: e$2.value
                            }
                          };
                  }
                  var e$3 = historicAttachments._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".historicAttachments" + e$3.path,
                            message: e$3.message,
                            value: e$3.value
                          }
                        };
                }
                var e$4 = countryCode._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".countryCode" + e$4.path,
                          message: e$4.message,
                          value: e$4.value
                        }
                      };
              }
              var e$5 = zipCode._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".zipCode" + e$5.path,
                        message: e$5.message,
                        value: e$5.value
                      }
                    };
            }
            var e$6 = city._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".city" + e$6.path,
                      message: e$6.message,
                      value: e$6.value
                    }
                  };
          }
          var e$7 = additionalAddressInfo._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".additionalAddressInfo" + e$7.path,
                    message: e$7.message,
                    value: e$7.value
                  }
                };
        }
        var e$8 = streetNbr._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".streetNbr" + e$8.path,
                  message: e$8.message,
                  value: e$8.value
                }
              };
      }
      var e$9 = street._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".street" + e$9.path,
                message: e$9.message,
                value: e$9.value
              }
            };
    }
    var e$10 = siretName._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".siretName" + e$10.path,
              message: e$10.message,
              value: e$10.value
            }
          };
  }
  var e$11 = siret._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".siret" + e$11.path,
            message: e$11.message,
            value: e$11.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(attachment_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(attachment_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

export {
  Fields ,
  attachment_encode ,
  attachment_decode ,
  companyDetail_encode ,
  companyDetail_decode ,
  searchResult_encode ,
  searchResult_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
