// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as Company_Detail_Types$DvmAdminFrontendFr from "./Company_Detail_Types.bs.js";

function fetchCompanyDetail(siret, userManager, setResult, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Company_Detail_Types$DvmAdminFrontendFr.companyDetail_decode, undefined, userManager, selectedRole, "/sirets/" + siret + "/detail").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var company = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: company
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchAttachmentSearch(submissionCallbacks, userManager, setTablePage, queryParams, selectedRole) {
  Curry._1(setTablePage, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Company_Detail_Types$DvmAdminFrontendFr.searchResult_decode, undefined, userManager, selectedRole, "/contract/all" + queryParams + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setTablePage, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchAttachmentSearch */0);
              }));
        Curry._1(setTablePage, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

export {
  fetchCompanyDetail ,
  fetchAttachmentSearch ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
