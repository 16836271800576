// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Action_Referential_Fields_Api$DvmAdminFrontendFr from "./Action_Referential_Fields_Api.bs.js";
import * as Messages_Action_Referential_Fields$DvmAdminFrontendFr from "../../../intl/messages/actionReferential/Messages_Action_Referential_Fields.bs.js";
import * as Action_Referential_Fields_Create_Modal$DvmAdminFrontendFr from "./Action_Referential_Fields_Create_Modal.bs.js";
import * as Action_Referential_Fields_Delete_Modal$DvmAdminFrontendFr from "./Action_Referential_Fields_Delete_Modal.bs.js";

var scrollStyle = Css.css(Object.assign({}, {
          overflow: "hidden auto",
          width: "50%"
        }, {
          "&::-webkit-scrollbar": {
            background: Theme_Colors$DvmAdminFrontendFr.primaryBrown2,
            width: "10px"
          },
          "&::-webkit-scrollbar-thumb": {
            background: Theme_Colors$DvmAdminFrontendFr.primaryBrown
          }
        }));

function Action_Referential_Fields_Table(Props) {
  var fieldType = Props.fieldType;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setFieldTypeResult = match$2[1];
  var match$3 = React.useState(function () {
        return false;
      });
  var setModal = match$3[1];
  var match$4 = React.useState(function () {
        
      });
  var setModalDelete = match$4[1];
  var fetchTypes = function (param) {
    Action_Referential_Fields_Api$DvmAdminFrontendFr.fetchActionReferentialsFields(userManager, setFieldTypeResult, fieldType, selectedRole);
  };
  React.useEffect((function () {
          fetchTypes(undefined);
        }), [fieldType]);
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true,
                  spacing: 1,
                  style: {
                    height: "500px"
                  }
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                            onClick: (function (param) {
                                Curry._1(setModal, (function (param) {
                                        return true;
                                      }));
                              }),
                            label: intl.formatMessage(Messages_Action_Referential_Fields$DvmAdminFrontendFr.addNewLine),
                            icon: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                  size: /* Regular */2,
                                  type_: /* More */17
                                }),
                            color: "secondary",
                            size: "small"
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: Belt_List.toArray(Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$2[0], /* [] */0), (function (fieldType) {
                                  return React.createElement(Core.Grid, {
                                              alignItems: "center",
                                              children: null,
                                              container: true,
                                              item: true,
                                              spacing: 1
                                            }, React.createElement(Core.Grid, {
                                                  children: React.createElement(Core.IconButton, {
                                                        onClick: (function (param) {
                                                            Curry._1(setModalDelete, (function (param) {
                                                                    return fieldType;
                                                                  }));
                                                          }),
                                                        children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                              size: /* Large */3,
                                                              type_: /* Trash */38,
                                                              color: Theme_Colors$DvmAdminFrontendFr.accentRed
                                                            })
                                                      }),
                                                  item: true
                                                }), React.createElement(Core.Grid, {
                                                  children: fieldType,
                                                  item: true
                                                }));
                                }))),
                      className: scrollStyle,
                      container: true,
                      direction: "column",
                      item: true,
                      spacing: 1,
                      wrap: "nowrap",
                      xs: Grid$Mui.Xs.$$true
                    })), match$3[0] ? React.createElement(Action_Referential_Fields_Create_Modal$DvmAdminFrontendFr.make, {
                    fieldType: fieldType,
                    onClose: (function (param) {
                        Curry._1(setModal, (function (param) {
                                return false;
                              }));
                      }),
                    onOk: (function (param) {
                        fetchTypes(undefined);
                        Curry._1(setModal, (function (param) {
                                return false;
                              }));
                      })
                  }) : null, Belt_Option.mapWithDefault(match$4[0], null, (function (row) {
                    return React.createElement(Action_Referential_Fields_Delete_Modal$DvmAdminFrontendFr.make, {
                                onClose: (function (param) {
                                    Curry._1(setModalDelete, (function (param) {
                                            
                                          }));
                                  }),
                                row: row,
                                onOk: (function (param) {
                                    fetchTypes(undefined);
                                    Curry._1(setModalDelete, (function (param) {
                                            
                                          }));
                                  }),
                                fieldType: fieldType
                              });
                  })));
}

var Api;

var make = Action_Referential_Fields_Table;

export {
  Api ,
  scrollStyle ,
  make ,
}
/* scrollStyle Not a pure module */
