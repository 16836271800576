// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../../../common/types/App_Types_Input.bs.js";
import * as Flash_Detail_Types$DvmAdminFrontendFr from "./page/Flash_Detail_Types.bs.js";
import * as Flash_Detail_Table_Types$DvmAdminFrontendFr from "./table/Flash_Detail_Table_Types.bs.js";

function fetchFlash(flashId, userManager, setResult, selectedRole, flashType) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Detail_Types$DvmAdminFrontendFr.flashAction_decode, undefined, userManager, selectedRole, "/flash/actions/" + (
          flashType ? "CL" : "SO"
        ) + "/" + flashId + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var flash = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: flash
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchModelsTable(submissionCallbacks, userManager, setResult, queryParams, selectedRole, withClosedModels, flashType) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Detail_Table_Types$DvmAdminFrontendFr.searchResult_decode, undefined, userManager, selectedRole, "/flash/" + (
          flashType ? "modular/" : ""
        ) + "actions/modelversions" + queryParams + "&withClosedModels=" + (
          withClosedModels ? "true" : "false"
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchModelsSearch */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchModelsTablePerf(submissionCallbacks, userManager, setResult, queryParams, actionID, selectedRole, withClosedModels) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Detail_Table_Types$DvmAdminFrontendFr.searchResult_decode, undefined, userManager, selectedRole, "/flash/map/perf/" + actionID + "" + queryParams + "&withClosedModels=" + (
          withClosedModels ? "true" : "false"
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchModelsSearch */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchModelsTableNonFleet(userManager, setResult, selectedRole, flashId, flash) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Detail_Table_Types$DvmAdminFrontendFr.modelVersionList_decode, undefined, userManager, selectedRole, "/flash/" + flashId + "/modelversions/mapped").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          var match = Belt_Option.mapWithDefault(flash.orderPeriod, [
                undefined,
                undefined
              ], (function (orderPeriod) {
                  return [
                          orderPeriod.startDate,
                          orderPeriod.endDate
                        ];
                }));
          var flashEnd = match[1];
          var flashStart = match[0];
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: Belt_List.map(searchTable, (function (x) {
                                        return {
                                                brandDescription: x.brandDescription,
                                                model: x.model,
                                                version: x.version,
                                                versionLabel: x.versionLabel,
                                                startDate: Belt_Option.mapWithDefault(x.startDate, flashStart, (function (x) {
                                                        return Caml_option.some(Belt_Option.mapWithDefault(flashStart, x, (function (start) {
                                                                          if (ReDate.isBefore(x, start)) {
                                                                            return start;
                                                                          } else {
                                                                            return x;
                                                                          }
                                                                        })));
                                                      })),
                                                endDate: Belt_Option.mapWithDefault(x.endDate, flashEnd, (function (x) {
                                                        return Caml_option.some(Belt_Option.mapWithDefault(flashEnd, x, (function (end) {
                                                                          if (ReDate.isAfter(x, end)) {
                                                                            return end;
                                                                          } else {
                                                                            return x;
                                                                          }
                                                                        })));
                                                      })),
                                                maxBonus: x.maxBonus,
                                                customBonus: x.customBonus
                                              };
                                      }))
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchModelsTablePerfAll(userManager, setResult, actionID, selectedRole, withClosedModels) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Detail_Table_Types$DvmAdminFrontendFr.searchResult_decode, undefined, userManager, selectedRole, "/flash/map/perf/" + actionID + "?limit=150000&offset=0&withClosedModels=" + (
          withClosedModels ? "true" : "false"
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function getCumulables(alert, userManager, setResult, flashId, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.twoParamList_decode, undefined, userManager, selectedRole, "/flash/cumulable?actionID=" + flashId + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var nonCumulables = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: nonCumulables
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

export {
  Form ,
  fetchFlash ,
  fetchModelsTable ,
  fetchModelsTablePerf ,
  fetchModelsTableNonFleet ,
  fetchModelsTablePerfAll ,
  getCumulables ,
}
/* react Not a pure module */
