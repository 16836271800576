// Generated by ReScript, PLEASE EDIT WITH CARE

import * as App_Types_Json$DvmAdminFrontendFr from "../../common/types/App_Types_Json.bs.js";

function toString(status) {
  switch (status) {
    case /* ALL */0 :
        return "ALL";
    case /* INCLUSION */1 :
        return "INCLUSION";
    case /* EXCLUSION */2 :
        return "EXCLUSION";
    
  }
}

function fromString(status) {
  switch (status) {
    case "ALL" :
        return /* ALL */0;
    case "EXCLUSION" :
        return /* EXCLUSION */2;
    case "INCLUSION" :
        return /* INCLUSION */1;
    default:
      return ;
  }
}

var Decco = App_Types_Json$DvmAdminFrontendFr.MakeStringableJsonType({
      toString: toString,
      fromString: fromString
    });

var Acceptance = {
  toString: toString,
  fromString: fromString,
  Decco: Decco
};

function toString$1(status) {
  switch (status) {
    case /* PERCENT */0 :
        return "PERCENTAGE";
    case /* EURO */1 :
        return "AMOUNT";
    case /* NOTSET */2 :
        return "";
    
  }
}

function fromString$1(status) {
  switch (status) {
    case "AMOUNT" :
        return /* EURO */1;
    case "PERCENTAGE" :
        return /* PERCENT */0;
    default:
      return ;
  }
}

var Decco$1 = App_Types_Json$DvmAdminFrontendFr.MakeStringableJsonType({
      toString: toString$1,
      fromString: fromString$1
    });

var ValueType = {
  toString: toString$1,
  fromString: fromString$1,
  Decco: Decco$1
};

function toString$2(status) {
  switch (status) {
    case /* REPR */0 :
        return "REPR";
    case /* STOC */1 :
        return "STOC";
    case /* PERF */2 :
        return "PERF";
    case /* NOTSET */3 :
        return "";
    
  }
}

function fromString$2(status) {
  switch (status) {
    case "PERF" :
        return /* PERF */2;
    case "REPR" :
        return /* REPR */0;
    case "STOC" :
        return /* STOC */1;
    default:
      return ;
  }
}

var Decco$2 = App_Types_Json$DvmAdminFrontendFr.MakeStringableJsonType({
      toString: toString$2,
      fromString: fromString$2
    });

var ActionType = {
  toString: toString$2,
  fromString: fromString$2,
  Decco: Decco$2
};

export {
  Acceptance ,
  ValueType ,
  ActionType ,
}
/* Decco Not a pure module */
