// Generated by ReScript, PLEASE EDIT WITH CARE


var title = {
  id: "873ea71d-eb8f-4ba8-ba3d-41fe5bbc7793",
  defaultMessage: "Sélectionnez Importer"
};

var uploadAttachments = {
  id: "7054a6e0-5f68-4219-b8f7-8e4b0806c3cf",
  defaultMessage: "Import des rattachements"
};

export {
  title ,
  uploadAttachments ,
}
/* No side effect */
