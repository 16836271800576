// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReDate_utils from "@mobily/rescript-date/src/ReDate_utils.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as Company_Virtual_Modal_Form$DvmAdminFrontendFr from "./Company_Virtual_Modal_Form.bs.js";

function modifyContractsList(contractsResult, intl) {
  return App_Types_Result$DvmAdminFrontendFr.mapWithDefault(contractsResult, /* [] */0, (function (contracts) {
                return Belt_List.map(contracts, (function (contractItem) {
                              return {
                                      contractID: contractItem.contractID,
                                      dates: Belt_List.map(contractItem.dates, (function (dates) {
                                              return {
                                                      label: Belt_Option.mapWithDefault(dates.startDate, "", (function (param) {
                                                              return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                            })) + " - " + Belt_Option.mapWithDefault(dates.endDate, "", (function (param) {
                                                              return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                            })),
                                                      startDate: dates.startDate,
                                                      endDate: dates.endDate
                                                    };
                                            }))
                                    };
                            }));
              }));
}

function updateContractDates(val, contract, contractsModified, form) {
  Belt_Option.mapWithDefault(Belt_List.getBy(Curry._1(contractsModified, undefined), (function (x) {
              return x.contractID === Belt_Option.getWithDefault(contract, form.input.contractID);
            })), undefined, (function (contract) {
          Belt_Option.mapWithDefault(Belt_List.getBy(contract.dates, (function (x) {
                      return Belt_Option.getWithDefault(x.label, "") === val;
                    })), undefined, (function (item) {
                  Curry._2(form.updateContractStartDate, (function (input, contractStartDate) {
                          return {
                                  siret: input.siret,
                                  name: input.name,
                                  street: input.street,
                                  streetNbr: input.streetNbr,
                                  additionalAddressInfo: input.additionalAddressInfo,
                                  zipCode: input.zipCode,
                                  city: input.city,
                                  countryCode: input.countryCode,
                                  endDate: input.endDate,
                                  addAttachment: input.addAttachment,
                                  contractID: input.contractID,
                                  contractStartDate: contractStartDate,
                                  contractEndDate: input.contractEndDate,
                                  attachmentStartDate: input.attachmentStartDate,
                                  attachmentEndDate: input.attachmentEndDate,
                                  endDateAsParent: input.endDateAsParent,
                                  origAttachmentStartDate: input.origAttachmentStartDate
                                };
                        }), item.startDate);
                  Curry._2(form.updateContractEndDate, (function (input, contractEndDate) {
                          return {
                                  siret: input.siret,
                                  name: input.name,
                                  street: input.street,
                                  streetNbr: input.streetNbr,
                                  additionalAddressInfo: input.additionalAddressInfo,
                                  zipCode: input.zipCode,
                                  city: input.city,
                                  countryCode: input.countryCode,
                                  endDate: input.endDate,
                                  addAttachment: input.addAttachment,
                                  contractID: input.contractID,
                                  contractStartDate: input.contractStartDate,
                                  contractEndDate: contractEndDate,
                                  attachmentStartDate: input.attachmentStartDate,
                                  attachmentEndDate: input.attachmentEndDate,
                                  endDateAsParent: input.endDateAsParent,
                                  origAttachmentStartDate: input.origAttachmentStartDate
                                };
                        }), item.endDate);
                }));
        }));
}

function dateToFloat(date, unlimited) {
  return Belt_Option.mapWithDefault(date, unlimited ? new Date(Date.now()).setFullYear(3000.0) : 0.0, (function (x) {
                return ReDate_utils.makeStartOfDayDate(x).valueOf();
              }));
}

function findProperPeriodLabel(contractsModified, existingAttachment) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(Curry._1(contractsModified, undefined), (function (mod) {
                    return mod.contractID === existingAttachment.contractID;
                  })), undefined, (function (contractRow) {
                return Belt_Option.mapWithDefault(Belt_List.getBy(contractRow.dates, (function (dateRow) {
                                  if (dateToFloat(dateRow.startDate, false) <= dateToFloat(existingAttachment.startDate, false)) {
                                    return dateToFloat(dateRow.endDate, true) >= dateToFloat(existingAttachment.startDate, false);
                                  } else {
                                    return false;
                                  }
                                })), undefined, (function (found) {
                              return found.label;
                            }));
              }));
}

function populateForm(row, existingAttachment) {
  var mainInfo = row !== undefined ? ({
        siret: row.siret,
        name: Belt_Option.getWithDefault(row.name, ""),
        street: Belt_Option.getWithDefault(row.street, ""),
        streetNbr: Belt_Option.getWithDefault(row.streetNbr, ""),
        additionalAddressInfo: Belt_Option.getWithDefault(row.additionalAddressInfo, ""),
        zipCode: Belt_Option.getWithDefault(row.zipCode, ""),
        city: Belt_Option.getWithDefault(row.city, ""),
        countryCode: Belt_Option.getWithDefault(row.countryCode, ""),
        endDate: row.endDate,
        addAttachment: Company_Virtual_Modal_Form$DvmAdminFrontendFr.initialInput.addAttachment,
        contractID: Company_Virtual_Modal_Form$DvmAdminFrontendFr.initialInput.contractID,
        contractStartDate: Company_Virtual_Modal_Form$DvmAdminFrontendFr.initialInput.contractStartDate,
        contractEndDate: Company_Virtual_Modal_Form$DvmAdminFrontendFr.initialInput.contractEndDate,
        attachmentStartDate: Company_Virtual_Modal_Form$DvmAdminFrontendFr.initialInput.attachmentStartDate,
        attachmentEndDate: Company_Virtual_Modal_Form$DvmAdminFrontendFr.initialInput.attachmentEndDate,
        endDateAsParent: Company_Virtual_Modal_Form$DvmAdminFrontendFr.initialInput.endDateAsParent,
        origAttachmentStartDate: Company_Virtual_Modal_Form$DvmAdminFrontendFr.initialInput.origAttachmentStartDate
      }) : Company_Virtual_Modal_Form$DvmAdminFrontendFr.initialInput;
  return App_Types_Result$DvmAdminFrontendFr.mapWithDefault(existingAttachment, mainInfo, (function (x) {
                return Belt_Option.mapWithDefault(Belt_List.get(x.content, 0), mainInfo, (function (x) {
                              return {
                                      siret: mainInfo.siret,
                                      name: mainInfo.name,
                                      street: mainInfo.street,
                                      streetNbr: mainInfo.streetNbr,
                                      additionalAddressInfo: mainInfo.additionalAddressInfo,
                                      zipCode: mainInfo.zipCode,
                                      city: mainInfo.city,
                                      countryCode: mainInfo.countryCode,
                                      endDate: mainInfo.endDate,
                                      addAttachment: true,
                                      contractID: x.contractID,
                                      contractStartDate: mainInfo.contractStartDate,
                                      contractEndDate: mainInfo.contractEndDate,
                                      attachmentStartDate: x.startDate,
                                      attachmentEndDate: x.endDate,
                                      endDateAsParent: Belt_Option.isNone(x.endDate),
                                      origAttachmentStartDate: x.startDate
                                    };
                            }));
              }));
}

export {
  modifyContractsList ,
  updateContractDates ,
  dateToFloat ,
  findProperPeriodLabel ,
  populateForm ,
}
/* Company_Virtual_Modal_Form-DvmAdminFrontendFr Not a pure module */
