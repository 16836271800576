// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_RadioFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_RadioFormInput.bs.js";
import * as Messages_MarginGrid$DvmAdminFrontendFr from "../../../../intl/messages/marginGrid/Messages_MarginGrid.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Messages_MarginGrid_Duplicate$DvmAdminFrontendFr from "../../../../intl/messages/marginGrid/Messages_MarginGrid_Duplicate.bs.js";

function Margin_Grid_Duplicate_Duplication_Definition(Props) {
  var categories = Props.categories;
  var form = Props.form;
  var intl = ReactIntl.useIntl();
  React.useEffect((function () {
          Curry._2(form.updateGridID, (function (input, gridID) {
                  return {
                          newOrExisting: input.newOrExisting,
                          gridID: gridID,
                          discounts: input.discounts,
                          startDate: input.startDate,
                          endDate: input.endDate
                        };
                }), "");
        }), [form.input.newOrExisting]);
  var existingGrids = Belt_List.map(categories, (function (x) {
          return x.data;
        }));
  var tmp = {
    label: Messages_MarginGrid_Duplicate$DvmAdminFrontendFr.duplicateInto,
    required: /* OnlyLabel */1,
    value: form.input.newOrExisting,
    options: {
      hd: {
        label: {
          TAG: /* Message */0,
          _0: Messages_MarginGrid_Duplicate$DvmAdminFrontendFr.newGrid
        },
        value: "new"
      },
      tl: {
        hd: {
          label: {
            TAG: /* Message */0,
            _0: Messages_MarginGrid_Duplicate$DvmAdminFrontendFr.existingGrid
          },
          value: "existing"
        },
        tl: /* [] */0
      }
    },
    onChange: Curry._1(form.updateNewOrExisting, (function (input, newOrExisting) {
            return {
                    newOrExisting: newOrExisting,
                    gridID: input.gridID,
                    discounts: input.discounts,
                    startDate: input.startDate,
                    endDate: input.endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
  };
  if (form.newOrExistingResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.newOrExistingResult);
  }
  var match = form.input.newOrExisting;
  var tmp$1;
  if (match === "existing") {
    var tmp$2 = {
      label: Messages_MarginGrid$DvmAdminFrontendFr.gridID,
      value: {
        TAG: /* Single */0,
        _0: {
          value: form.input.gridID,
          onChange: Curry._1(form.updateGridID, (function (input, gridID) {
                  return {
                          newOrExisting: input.newOrExisting,
                          gridID: gridID,
                          discounts: input.discounts,
                          startDate: input.startDate,
                          endDate: input.endDate
                        };
                }))
        }
      },
      options: {
        TAG: /* Unlabeled */0,
        _0: existingGrids
      },
      required: /* OnlyLabel */1,
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
    };
    if (form.gridIDResult !== undefined) {
      tmp$2.validationResult = Caml_option.valFromOption(form.gridIDResult);
    }
    tmp$1 = React.createElement(Core.Grid, {
          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$2),
          item: true
        });
  } else {
    var tmp$3 = {
      onChange: Curry._1(form.updateGridID, (function (input, gridID) {
              return {
                      newOrExisting: input.newOrExisting,
                      gridID: gridID,
                      discounts: input.discounts,
                      startDate: input.startDate,
                      endDate: input.endDate
                    };
            })),
      value: form.input.gridID,
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
      label: Messages_MarginGrid$DvmAdminFrontendFr.gridID,
      required: /* OnlyLabel */1
    };
    if (form.gridIDResult !== undefined) {
      tmp$3.validationResult = Caml_option.valFromOption(form.gridIDResult);
    }
    tmp$1 = React.createElement(Core.Grid, {
          children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$3),
          item: true
        });
  }
  var tmp$4 = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    newOrExisting: input.newOrExisting,
                    gridID: input.gridID,
                    discounts: input.discounts,
                    startDate: startDate,
                    endDate: input.endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    required: /* OnlyLabel */1
  };
  if (form.startDateResult !== undefined) {
    tmp$4.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  var tmp$5 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    newOrExisting: input.newOrExisting,
                    gridID: input.gridID,
                    discounts: input.discounts,
                    startDate: input.startDate,
                    endDate: endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    required: /* OnlyLabel */1
  };
  if (form.endDateResult !== undefined) {
    tmp$5.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              item: true
            }, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true,
                  sm: Grid$Mui.Sm[6]
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, tmp),
                      item: true
                    }), tmp$1), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true,
                  sm: Grid$Mui.Sm[6]
                }, React.createElement(Core.Grid, {
                      children: React.createElement(Core.Typography, {
                            children: intl.formatMessage(Messages_MarginGrid_Duplicate$DvmAdminFrontendFr.duplicationDates),
                            variant: "subtitle1"
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      item: true,
                      spacing: 1
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$4),
                          item: true,
                          sm: Grid$Mui.Sm[6]
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$5),
                          item: true,
                          sm: Grid$Mui.Sm[6]
                        }))));
}

var Form;

var InputStyles;

var make = Margin_Grid_Duplicate_Duplication_Definition;

export {
  Form ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
