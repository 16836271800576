// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as ReactVirtual from "@tanstack/react-virtual";
import * as App_Checkbox$DvmAdminFrontendFr from "../App_Checkbox.bs.js";
import * as App_Types_Text$DvmAdminFrontendFr from "../../types/App_Types_Text.bs.js";

function App_TransferListFormInput_Virtualized_List(Props) {
  var boxHeight = Props.boxHeight;
  var disabled = Props.disabled;
  var selectedToBlock = Props.selectedToBlock;
  var checked = Props.checked;
  var handleToggle = Props.handleToggle;
  var items = Props.items;
  var intl = ReactIntl.useIntl();
  var chatRefCurrent = React.useRef(null);
  var virtualizer = ReactVirtual.useVirtualizer({
        count: Belt_List.length(items),
        getScrollElement: (function (param) {
            return chatRefCurrent.current;
          }),
        estimateSize: (function (param) {
            return 20;
          })
      });
  var virtualItems = virtualizer.getVirtualItems();
  return React.createElement(Core.Box, {
              style: {
                height: String(boxHeight) + "px",
                overflow: "auto"
              },
              children: React.createElement(Core.Container, {
                    children: React.createElement(Core.List, {
                          children: Belt_Array.map(virtualItems, (function (virtualItem) {
                                  var listItem = Belt_List.get(items, virtualItem.index);
                                  return Belt_Option.mapWithDefault(listItem, null, (function (foundItem) {
                                                return React.cloneElement(React.createElement("div", {
                                                                key: virtualItem.key,
                                                                ref: virtualizer.measureElement
                                                              }, React.createElement(Core.ListItem, {
                                                                    button: true,
                                                                    children: null,
                                                                    disabled: Belt_Option.getWithDefault(disabled, false) || Belt_Option.mapWithDefault(selectedToBlock, false, (function (x) {
                                                                            return Belt_List.some(x, (function (x) {
                                                                                          return x.value === foundItem.value;
                                                                                        }));
                                                                          })),
                                                                    style: {
                                                                      padding: "0px 10px"
                                                                    },
                                                                    onClick: (function (param) {
                                                                        Curry._1(handleToggle, foundItem);
                                                                      }),
                                                                    key: String(virtualItem.index)
                                                                  }, React.createElement(Core.ListItemIcon, {
                                                                        children: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                                                              checked: Belt_List.some(checked, (function (x) {
                                                                                      return x.value === foundItem.value;
                                                                                    })),
                                                                              tabIndex: -1,
                                                                              disableRipple: true
                                                                            })
                                                                      }), React.createElement(Core.ListItemText, {
                                                                        primary: App_Types_Text$DvmAdminFrontendFr.toString(intl, foundItem.label),
                                                                        id: String(virtualItem.index)
                                                                      }))), {
                                                            "data-index": virtualItem.index
                                                          });
                                              }));
                                })),
                          dense: true,
                          style: {
                            left: "0",
                            position: "absolute",
                            top: "0",
                            width: "100%",
                            transform: "translateY(" + String(Belt_Option.mapWithDefault(Belt_Array.get(virtualItems, 0), 0, (function (x) {
                                        return x.start;
                                      }))) + "px)"
                          }
                        }),
                    style: {
                      height: "" + String(virtualizer.getTotalSize()) + "px",
                      position: "relative"
                    }
                  }),
              ref: chatRefCurrent
            });
}

var make = App_TransferListFormInput_Virtualized_List;

export {
  make ,
}
/* react Not a pure module */
