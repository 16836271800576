// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReDate_utils from "@mobily/rescript-date/src/ReDate_utils.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../common/types/App_Types_Date.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Error_Inputs.bs.js";
import * as Messages_Contract_Create$DvmAdminFrontendFr from "../../../../intl/messages/contract/Messages_Contract_Create.bs.js";

function validateAttachmentDates(smallerDate, largerDate, contractStartDate, contractEndDate, endDateAsContract) {
  if (contractStartDate === undefined) {
    return {
            TAG: /* Error */1,
            _0: Messages_Contract_Create$DvmAdminFrontendFr.contractDatesHaveIssue
          };
  }
  var start = Caml_option.valFromOption(contractStartDate);
  if (App_Types_Date$DvmAdminFrontendFr.isValid(start)) {
    if (endDateAsContract) {
      return {
              TAG: /* Ok */0,
              _0: undefined
            };
    } else {
      return Belt_Option.mapWithDefault(largerDate, {
                  TAG: /* Ok */0,
                  _0: undefined
                }, (function (largerDate) {
                    if (App_Types_Date$DvmAdminFrontendFr.isValid(largerDate)) {
                      if (ReDate_utils.makeStartOfDayDate(start).valueOf() <= ReDate_utils.makeStartOfDayDate(largerDate).valueOf() && Belt_Option.mapWithDefault(contractEndDate, true, (function (end) {
                                return App_Types_Date$DvmAdminFrontendFr.isValid(end) ? ReDate_utils.makeStartOfDayDate(end).valueOf() >= ReDate_utils.makeStartOfDayDate(largerDate).valueOf() : false;
                              }))) {
                        return Belt_Option.mapWithDefault(smallerDate, {
                                    TAG: /* Error */1,
                                    _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherDateNotValid
                                  }, (function (smallerDate) {
                                      if (App_Types_Date$DvmAdminFrontendFr.isValid(smallerDate)) {
                                        if (ReDate.isAfter(largerDate, smallerDate)) {
                                          return {
                                                  TAG: /* Ok */0,
                                                  _0: Caml_option.some(largerDate)
                                                };
                                        } else {
                                          return {
                                                  TAG: /* Error */1,
                                                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateTooSmall
                                                };
                                        }
                                      } else {
                                        return {
                                                TAG: /* Error */1,
                                                _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherDateNotValid
                                              };
                                      }
                                    }));
                      } else {
                        return {
                                TAG: /* Error */1,
                                _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateOutOfPeriod
                              };
                      }
                    } else {
                      return {
                              TAG: /* Error */1,
                              _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                            };
                    }
                  }));
    }
  } else {
    return {
            TAG: /* Error */1,
            _0: Messages_Contract_Create$DvmAdminFrontendFr.contractDatesHaveIssue
          };
  }
}

function validateAttachmentStartDate(date, contractStartDate, contractEndDate, siren) {
  if (contractStartDate === undefined) {
    return {
            TAG: /* Error */1,
            _0: Messages_Contract_Create$DvmAdminFrontendFr.contractDatesHaveIssue
          };
  }
  var start = Caml_option.valFromOption(contractStartDate);
  if (App_Types_Date$DvmAdminFrontendFr.isValid(start)) {
    return Belt_Option.mapWithDefault(date, Belt_Option.mapWithDefault(siren, {
                    TAG: /* Ok */0,
                    _0: undefined
                  }, (function (siren) {
                      if (siren.length > 0) {
                        return {
                                TAG: /* Error */1,
                                _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
                              };
                      } else {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }
                    })), (function (date) {
                  if (App_Types_Date$DvmAdminFrontendFr.isValid(date)) {
                    if (ReDate_utils.makeStartOfDayDate(start).valueOf() <= ReDate_utils.makeStartOfDayDate(date).valueOf()) {
                      return Belt_Option.mapWithDefault(contractEndDate, {
                                  TAG: /* Ok */0,
                                  _0: Caml_option.some(date)
                                }, (function (end) {
                                    if (App_Types_Date$DvmAdminFrontendFr.isValid(end)) {
                                      if (ReDate_utils.makeStartOfDayDate(end).valueOf() >= ReDate_utils.makeStartOfDayDate(date).valueOf()) {
                                        return {
                                                TAG: /* Ok */0,
                                                _0: Caml_option.some(date)
                                              };
                                      } else {
                                        return {
                                                TAG: /* Error */1,
                                                _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateOutOfPeriod
                                              };
                                      }
                                    } else {
                                      return {
                                              TAG: /* Error */1,
                                              _0: Messages_Contract_Create$DvmAdminFrontendFr.contractDatesHaveIssue
                                            };
                                    }
                                  }));
                    } else {
                      return {
                              TAG: /* Error */1,
                              _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateOutOfPeriod
                            };
                    }
                  } else {
                    return {
                            TAG: /* Error */1,
                            _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                          };
                  }
                }));
  } else {
    return {
            TAG: /* Error */1,
            _0: Messages_Contract_Create$DvmAdminFrontendFr.contractDatesHaveIssue
          };
  }
}

export {
  validateAttachmentDates ,
  validateAttachmentStartDate ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
