// Generated by ReScript, PLEASE EDIT WITH CARE


var show = {
  id: "4c4d0c6c-63db-438d-9b34-025385528fa5",
  defaultMessage: "Afficher"
};

var paginator = {
  id: "58a43d01-99c9-46df-8a33-577e216de560",
  defaultMessage: "{fromNumber} - {toNumber} sur {totalNumber}"
};

export {
  show ,
  paginator ,
}
/* No side effect */
