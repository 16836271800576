// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Button$DvmAdminFrontendFr from "../../../../../../common/components/button/App_Button.bs.js";
import * as Messages_Orders_DealerRequest$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_DealerRequest.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Documents_Modal$DvmAdminFrontendFr from "./Order_Detail_Bonus_DealerRequest_Documents_Modal.bs.js";

function Order_Detail_Bonus_DealerRequest_Documents_Button(Props) {
  var documentsResult = Props.documentsResult;
  var actionId = Props.actionId;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return null;
      });
  var setModal = match[1];
  var filteredDocs;
  filteredDocs = typeof documentsResult === "number" || documentsResult.TAG !== /* Ok */0 ? /* [] */0 : Belt_List.keep(documentsResult._0, (function (x) {
            return x.actionId === Belt_Option.getWithDefault(actionId, "");
          }));
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: Belt_List.length(filteredDocs) > 0 ? React.createElement(App_Button$DvmAdminFrontendFr.make, {
                          onClick: (function (param) {
                              Curry._1(setModal, (function (param) {
                                      return React.createElement(Order_Detail_Bonus_DealerRequest_Documents_Modal$DvmAdminFrontendFr.make, {
                                                  onClose: (function (param) {
                                                      Curry._1(setModal, (function (param) {
                                                              return null;
                                                            }));
                                                    }),
                                                  actionId: actionId,
                                                  documents: filteredDocs
                                                });
                                    }));
                            }),
                          label: intl.formatMessage(Messages_Orders_DealerRequest$DvmAdminFrontendFr.viewDocuments),
                          color: "secondary",
                          size: "small"
                        }) : null,
                  item: true
                }), match[0]);
}

var Form;

var Api;

var Types;

var make = Order_Detail_Bonus_DealerRequest_Documents_Button;

export {
  Form ,
  Api ,
  Types ,
  make ,
}
/* react Not a pure module */
