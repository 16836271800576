// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../../intl/Intl_Utils.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../common/App_Typography.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../../../../common/components/App_LabeledText.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Messages_Orders_Derogate$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_Derogate.bs.js";

function Order_Detail_Bonus_Derogate_Order_Info(Props) {
  var order = Props.order;
  var intl = ReactIntl.useIntl();
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              item: true
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        gutterBottom: true,
                        style: {
                          fontWeight: "600"
                        },
                        children: intl.formatMessage(Messages_Orders_Derogate$DvmAdminFrontendFr.derogateOrderLabel),
                        variant: "h5"
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  justifyContent: "space-evenly",
                  spacing: 1
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                            label: Messages_Orders_Detail$DvmAdminFrontendFr.orderDate,
                            children: Belt_Option.mapWithDefault(order.detail.orderDate, "-", (function (param) {
                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                  }))
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                            label: Messages_Orders_Detail$DvmAdminFrontendFr.carOrderNum,
                            children: Belt_Option.getWithDefault(order.orderNumberCar, "-")
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                            label: Messages_Common$DvmAdminFrontendFr.model,
                            children: Belt_Option.getWithDefault(order.detail.model, "-")
                          }),
                      item: true
                    })));
}

var make = Order_Detail_Bonus_Derogate_Order_Info;

export {
  make ,
}
/* react Not a pure module */
