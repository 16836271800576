// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_SelectFormInput.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_MarginMinimum$DvmAdminFrontendFr from "../../../intl/messages/marginMinimum/Messages_MarginMinimum.bs.js";

function Margin_Minimum_AddEdit_Inputs(Props) {
  var form = Props.form;
  var budgets = Props.budgets;
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.budget,
    options: Belt_List.map(budgets, (function (x) {
            return {
                    label: {
                      TAG: /* String */3,
                      _0: x
                    },
                    value: x
                  };
          })),
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.budgetType,
        onChange: Curry._1(form.updateBudgetType, (function (i, budgetType) {
                return {
                        budgetType: budgetType,
                        amount: i.amount,
                        startDate: i.startDate,
                        endDate: i.endDate
                      };
              }))
      }
    },
    id: "budgetType",
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
  };
  if (form.budgetTypeResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.budgetTypeResult);
  }
  var tmp$1 = {
    onChange: Curry._1(form.updateAmount, (function (input, amount) {
            return {
                    budgetType: input.budgetType,
                    amount: amount,
                    startDate: input.startDate,
                    endDate: input.endDate
                  };
          })),
    value: form.input.amount,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
    label: Messages_MarginMinimum$DvmAdminFrontendFr.minimum,
    InputProps: {
      startAdornment: React.createElement(Core.InputAdornment, {
            children: "€",
            position: "start"
          })
    },
    inputType: "number"
  };
  if (form.amountResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.amountResult);
  }
  var tmp$2 = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    budgetType: input.budgetType,
                    amount: input.amount,
                    startDate: startDate,
                    endDate: input.endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.startDateResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  var tmp$3 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    budgetType: input.budgetType,
                    amount: input.amount,
                    startDate: input.startDate,
                    endDate: endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.endDateResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              item: true,
              spacing: 1
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, tmp),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$1),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$2),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$3),
                  item: true
                }));
}

var Api;

var Types;

var Form;

var InputStyles;

var make = Margin_Minimum_AddEdit_Inputs;

export {
  Api ,
  Types ,
  Form ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
