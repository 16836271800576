// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Table$DvmAdminFrontendFr from "../../../common/components/table/App_Table.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as Grid_Types$DvmAdminFrontendFr from "../Grid_Types.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../intl/Intl_Utils.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Pagination.bs.js";
import * as App_Table_Export_Menu$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Export_Menu.bs.js";
import * as ModelVersion_Grid_Types$DvmAdminFrontendFr from "./ModelVersion_Grid_Types.bs.js";
import * as Messages_ModelVersionGrid$DvmAdminFrontendFr from "../../../intl/messages/modelVersionGrid/Messages_ModelVersionGrid.bs.js";

function headersConfiguration(budget, whatTable) {
  var displayAcquisition = budget === "LCD" || budget === "PROT" ? App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(ModelVersion_Grid_Types$DvmAdminFrontendFr.Fields.acquisitionType, /* Asc */0, undefined, undefined, 2, undefined, Messages_ModelVersionGrid$DvmAdminFrontendFr.acquisitionType) : undefined;
  return Belt_List.keepMap({
              hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(ModelVersion_Grid_Types$DvmAdminFrontendFr.Fields.model, /* Asc */0, undefined, undefined, 0, undefined, Messages_Common$DvmAdminFrontendFr.model),
              tl: {
                hd: whatTable === /* Version */1 ? App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(ModelVersion_Grid_Types$DvmAdminFrontendFr.Fields.version, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.version) : undefined,
                tl: {
                  hd: whatTable === /* Version */1 ? App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(ModelVersion_Grid_Types$DvmAdminFrontendFr.Fields.categoryLabel, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.categoryLabel) : undefined,
                  tl: {
                    hd: displayAcquisition,
                    tl: {
                      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(ModelVersion_Grid_Types$DvmAdminFrontendFr.Fields.discount, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.discount),
                      tl: {
                        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(ModelVersion_Grid_Types$DvmAdminFrontendFr.Fields.startDate, /* Desc */1, undefined, undefined, 1, undefined, Messages_Common$DvmAdminFrontendFr.startDate),
                        tl: {
                          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(ModelVersion_Grid_Types$DvmAdminFrontendFr.Fields.endDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.endDate),
                          tl: {
                            hd: whatTable === /* Model */0 ? App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(ModelVersion_Grid_Types$DvmAdminFrontendFr.Fields.hasExceptions, undefined, undefined, undefined, undefined, undefined, Messages_ModelVersionGrid$DvmAdminFrontendFr.versionException) : undefined,
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }, (function (x) {
                return x;
              }));
}

function ModelVersion_Grid_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var budget = Props.budget;
  var whatTable = Props.whatTable;
  var withClosedModels = Props.withClosedModels;
  var intl = ReactIntl.useIntl();
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (param) {
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, undefined, undefined, Belt_List.mapWithIndex(Belt_List.keepMap({
                                                      hd: param.model,
                                                      tl: {
                                                        hd: whatTable === /* Version */1 ? Belt_Option.getWithDefault(param.version, "") : undefined,
                                                        tl: {
                                                          hd: whatTable === /* Version */1 ? Belt_Option.getWithDefault(param.categoryName, "") : undefined,
                                                          tl: {
                                                            hd: budget === "LCD" || budget === "PROT" ? Belt_Option.mapWithDefault(param.acquisitionType, "", (function (x) {
                                                                      return x;
                                                                    })) : undefined,
                                                            tl: {
                                                              hd: Belt_Option.mapWithDefault(param.discount, "", (function (param) {
                                                                      return Intl_Utils$DvmAdminFrontendFr.formatPercent(intl, param);
                                                                    })),
                                                              tl: {
                                                                hd: Belt_Option.mapWithDefault(param.startDate, "", (function (param) {
                                                                        return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                      })),
                                                                tl: {
                                                                  hd: Belt_Option.mapWithDefault(param.endDate, "", (function (param) {
                                                                          return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                        })),
                                                                  tl: {
                                                                    hd: whatTable === /* Model */0 ? Belt_Option.mapWithDefault(param.hasExceptions, "", (function (x) {
                                                                              if (x) {
                                                                                return intl.formatMessage(Messages_Common$DvmAdminFrontendFr.yes);
                                                                              } else {
                                                                                return intl.formatMessage(Messages_Common$DvmAdminFrontendFr.no);
                                                                              }
                                                                            })) : undefined,
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }, (function (x) {
                                                        return x;
                                                      })), (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration(budget, whatTable), index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._3(fetchData, undefined, budget, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._3(fetchData, undefined, budget, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._3(fetchData, undefined, budget, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, {
                  headers: fetchRequest.headers,
                  handleSorting: handleSorting,
                  tablePage: convertTable(tablePage),
                  paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined),
                  exportMenu: React.createElement(App_Table_Export_Menu$DvmAdminFrontendFr.make, {
                        query: Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                              page: 0,
                              rowsPerPage: 150000,
                              headers: fetchRequest.headers,
                              filterQueryParams: fetchRequest.filterQueryParams,
                              additionalQueryParams: fetchRequest.additionalQueryParams
                            }) + ("&withClosedModels=" + (
                            withClosedModels ? "true" : "false"
                          ) + ""),
                        endpoint: (
                          whatTable === /* Model */0 ? Grid_Types$DvmAdminFrontendFr.modelGridEndpoint : Grid_Types$DvmAdminFrontendFr.versionGridEndpoint
                        ) + "/" + budget
                      })
                }));
}

var make = ModelVersion_Grid_Table;

export {
  headersConfiguration ,
  make ,
}
/* react Not a pure module */
