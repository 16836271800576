// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../types/App_Types_Date.bs.js";

function toISODateString(date) {
  return Belt_Option.flatMap(date, (function (date) {
                if (App_Types_Date$DvmAdminFrontendFr.isValid(date)) {
                  return date.toISOString().substring(0, 10);
                }
                
              }));
}

function toISODateTimeString(makeStartOfDayOpt, date) {
  var makeStartOfDay = makeStartOfDayOpt !== undefined ? makeStartOfDayOpt : false;
  return Belt_Option.flatMap(date, (function (date) {
                if (App_Types_Date$DvmAdminFrontendFr.isValid(date)) {
                  if (makeStartOfDay) {
                    return new Date(date.setUTCHours(0.0, 0.0, 0.0, 0.0)).toISOString();
                  } else {
                    return date.toISOString();
                  }
                }
                
              }));
}

function toYearString(date) {
  return Belt_Option.flatMap(date, (function (date) {
                if (App_Types_Date$DvmAdminFrontendFr.isValid(date)) {
                  return date.getFullYear().toString();
                }
                
              }));
}

export {
  toISODateString ,
  toISODateTimeString ,
  toYearString ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
