// Generated by ReScript, PLEASE EDIT WITH CARE


var quickAccessTitle = {
  id: "1277a20b-56a1-4c13-8070-a25e3ec35269",
  defaultMessage: "Accès rapide"
};

var quickAccessHint = {
  id: "9617769b-400c-4454-9e24-58d61c176d4d",
  defaultMessage: "Possibilité de choisir jusqu’à {maxCount} accès rapides pour personnaliser votre tableau de bord"
};

var quickAccessSelect = {
  id: "18b031f8-a6c7-4e48-bf25-9b24f81ff27f",
  defaultMessage: "{count} accès rapides sur {maxCount} sélectionnés"
};

var quickAccessItem_ManageAnomaly = {
  id: "90e6311b-4b82-450e-ac8c-762dd5ab1560",
  defaultMessage: "Gestion d'anomalie"
};

var quickAccessItem_ManageBonus = {
  id: "c7f58be5-d815-4c0b-a2cb-243f1df299f9",
  defaultMessage: "Gestion des aides"
};

var quickAccessItem_OrderList = {
  id: "c7f58be5-d815-4c0b-a2cb-243f1df299f8",
  defaultMessage: "Liste des commandes"
};

var quickAccessItem_ModelVersionGrid = {
  id: "115807e1-b943-4b8e-a3a3-7b5f62d51c02",
  defaultMessage: "Grilles de conditions"
};

var quickAccessItem_ModelVersions = {
  id: "abc6b27a-99b7-43df-ad49-5b8502b215c4",
  defaultMessage: "Modèles / Versions"
};

var quickAccessItem_PCDAgreements = {
  id: "38bb08f2-13fb-4593-bebd-e00f0c61e41d",
  defaultMessage: "Accords PCD"
};

var quickAccessItem_MarginGrid = {
  id: "dbd38e20-c08b-490d-ac53-7023351c5556",
  defaultMessage: "Grilles de marges"
};

var quickAccessItem_FlashActions = {
  id: "dc49dfec-56e0-4334-8e6a-63725c729807",
  defaultMessage: "Actions commerciales"
};

var quickAccessItem_Contracts = {
  id: "0db91f47-76df-4d43-9c49-dc869c5ff7fb",
  defaultMessage: "Contrats"
};

var quickAccessItem_ContractManagers = {
  id: "e15fe8ba-25bd-4401-9efc-2c7814aaff20",
  defaultMessage: "Intervenants"
};

var quickAccessItem_ContractManagersCreate = {
  id: "feb7b2b2-a05d-43cb-bbdc-f706ba212627",
  defaultMessage: "Créer un intervenant"
};

export {
  quickAccessTitle ,
  quickAccessHint ,
  quickAccessSelect ,
  quickAccessItem_ManageAnomaly ,
  quickAccessItem_ManageBonus ,
  quickAccessItem_OrderList ,
  quickAccessItem_ModelVersionGrid ,
  quickAccessItem_ModelVersions ,
  quickAccessItem_PCDAgreements ,
  quickAccessItem_MarginGrid ,
  quickAccessItem_FlashActions ,
  quickAccessItem_Contracts ,
  quickAccessItem_ContractManagers ,
  quickAccessItem_ContractManagersCreate ,
}
/* No side effect */
