// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Table$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../intl/Intl_Utils.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table_Pagination.bs.js";
import * as Messages_ModelVersion$DvmAdminFrontendFr from "../../../../../intl/messages/modelVersion/Messages_ModelVersion.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as Quota_View_Modal_Exclusion_Types$DvmAdminFrontendFr from "./Quota_View_Modal_Exclusion_Types.bs.js";

function headersConfiguration(tableType) {
  return {
          hd: tableType === /* Model */0 ? App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Modal_Exclusion_Types$DvmAdminFrontendFr.Columns.modelId, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.model) : App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Modal_Exclusion_Types$DvmAdminFrontendFr.Columns.modelVersionId, undefined, undefined, undefined, undefined, undefined, Messages_Quotas_Detail$DvmAdminFrontendFr.modelVersion),
          tl: {
            hd: tableType === /* Model */0 ? App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Modal_Exclusion_Types$DvmAdminFrontendFr.Columns.modelName, undefined, undefined, undefined, undefined, undefined, Messages_ModelVersion$DvmAdminFrontendFr.labelModel) : App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Modal_Exclusion_Types$DvmAdminFrontendFr.Columns.versionName, undefined, undefined, undefined, undefined, undefined, Messages_Quotas_Detail$DvmAdminFrontendFr.versionName),
            tl: {
              hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Modal_Exclusion_Types$DvmAdminFrontendFr.Columns.vehicleType, undefined, undefined, undefined, undefined, undefined, Messages_Quotas_Detail$DvmAdminFrontendFr.vehicleType),
              tl: {
                hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Modal_Exclusion_Types$DvmAdminFrontendFr.Columns.startDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.startDate),
                tl: {
                  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Quota_View_Modal_Exclusion_Types$DvmAdminFrontendFr.Columns.endDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.endDate),
                  tl: /* [] */0
                }
              }
            }
          }
        };
}

function Quota_View_Modal_Exclusion_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var tableType = Props.tableType;
  var intl = ReactIntl.useIntl();
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (param) {
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, undefined, undefined, Belt_List.mapWithIndex({
                                                  hd: tableType === /* Model */0 ? Belt_Option.getWithDefault(param.model, "") : Belt_Option.getWithDefault(param.modelVersion, ""),
                                                  tl: {
                                                    hd: tableType === /* Model */0 ? Belt_Option.getWithDefault(param.modelName, "") : Belt_Option.getWithDefault(param.versionName, ""),
                                                    tl: {
                                                      hd: Belt_Option.getWithDefault(param.vehicleType, ""),
                                                      tl: {
                                                        hd: Belt_Option.mapWithDefault(param.startDate, "", (function (param) {
                                                                return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                              })),
                                                        tl: {
                                                          hd: Belt_Option.mapWithDefault(param.endDate, "", (function (param) {
                                                                  return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                })),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration(tableType), index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._2(fetchData, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, {
                  headers: fetchRequest.headers,
                  handleSorting: handleSorting,
                  tablePage: convertTable(tablePage),
                  paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(/* BOTTOM */1, 10, handleChangePage, handleRowsPerPageChange, undefined)
                }));
}

var make = Quota_View_Modal_Exclusion_Table;

export {
  headersConfiguration ,
  make ,
}
/* react Not a pure module */
