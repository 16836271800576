// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_CompanyFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_CompanyFormInput.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_AutocompleteFormInput.bs.js";

function Quota_Detail_Attachment_Add_Row(Props) {
  var quota = Props.quota;
  var attachment = Props.attachment;
  var form = Props.form;
  var at = Props.at;
  var drAndDealers = Props.drAndDealers;
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.dealerID,
    value: {
      TAG: /* Single */0,
      _0: {
        value: attachment.dealerId,
        onChange: Curry._2(form.updateAttachmentDealerId, at, (function (param, dealerId) {
                return {
                        attachments: Belt_Array.mapWithIndex(param.attachments, (function (index, attachment) {
                                if (index === at) {
                                  return {
                                          volumeOrigin: attachment.volumeOrigin,
                                          siret: attachment.siret,
                                          companyName: attachment.companyName,
                                          dealerId: dealerId,
                                          volume: attachment.volume,
                                          attachmentStartDate: attachment.attachmentStartDate,
                                          attachmentOldStartDate: attachment.attachmentOldStartDate,
                                          attachmentEndDate: attachment.attachmentEndDate,
                                          dirty: true
                                        };
                                } else {
                                  return attachment;
                                }
                              }))
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: Belt_Option.mapWithDefault(Belt_List.getBy(App_Types_Result$DvmAdminFrontendFr.getWithDefault(drAndDealers, /* [] */0), (function (x) {
                  return x.parent === quota.dr;
                })), /* [] */0, (function (x) {
              return x.child;
            }))
    },
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  var tmp$1 = Curry._1(form.attachmentDealerIdResult, at);
  if (tmp$1 !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = {
    onChange: Curry._2(form.updateAttachmentVolume, at, (function (param, volume) {
            return {
                    attachments: Belt_Array.mapWithIndex(param.attachments, (function (index, attachment) {
                            if (index === at) {
                              return {
                                      volumeOrigin: attachment.volumeOrigin,
                                      siret: attachment.siret,
                                      companyName: attachment.companyName,
                                      dealerId: attachment.dealerId,
                                      volume: volume,
                                      attachmentStartDate: attachment.attachmentStartDate,
                                      attachmentOldStartDate: attachment.attachmentOldStartDate,
                                      attachmentEndDate: attachment.attachmentEndDate,
                                      dirty: true
                                    };
                            } else {
                              return attachment;
                            }
                          }))
                  };
          })),
    value: attachment.volume,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
    label: Messages_Quotas_Detail$DvmAdminFrontendFr.volume,
    inputType: "number"
  };
  var tmp$3 = Curry._1(form.attachmentVolumeResult, at);
  if (tmp$3 !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(tmp$3);
  }
  var tmp$4 = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: attachment.attachmentStartDate,
    onChange: Curry._2(form.updateAttachmentAttachmentStartDate, at, (function (param, attachmentStartDate) {
            return {
                    attachments: Belt_Array.mapWithIndex(param.attachments, (function (index, attachment) {
                            if (index === at) {
                              return {
                                      volumeOrigin: attachment.volumeOrigin,
                                      siret: attachment.siret,
                                      companyName: attachment.companyName,
                                      dealerId: attachment.dealerId,
                                      volume: attachment.volume,
                                      attachmentStartDate: attachmentStartDate,
                                      attachmentOldStartDate: attachment.attachmentOldStartDate,
                                      attachmentEndDate: attachment.attachmentEndDate,
                                      dirty: true
                                    };
                            } else {
                              return attachment;
                            }
                          }))
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
    onBlur: (function (param) {
        Curry._1(form.blurAttachmentAttachmentStartDate, at);
      })
  };
  var tmp$5 = Curry._1(form.attachmentAttachmentStartDateResult, at);
  if (tmp$5 !== undefined) {
    tmp$4.validationResult = Caml_option.valFromOption(tmp$5);
  }
  var tmp$6 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: attachment.attachmentEndDate,
    onChange: Curry._2(form.updateAttachmentAttachmentEndDate, at, (function (param, attachmentEndDate) {
            return {
                    attachments: Belt_Array.mapWithIndex(param.attachments, (function (index, attachment) {
                            if (index === at) {
                              return {
                                      volumeOrigin: attachment.volumeOrigin,
                                      siret: attachment.siret,
                                      companyName: attachment.companyName,
                                      dealerId: attachment.dealerId,
                                      volume: attachment.volume,
                                      attachmentStartDate: attachment.attachmentStartDate,
                                      attachmentOldStartDate: attachment.attachmentOldStartDate,
                                      attachmentEndDate: attachmentEndDate,
                                      dirty: true
                                    };
                            } else {
                              return attachment;
                            }
                          }))
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
    onBlur: (function (param) {
        Curry._1(form.blurAttachmentAttachmentEndDate, at);
      })
  };
  var tmp$7 = Curry._1(form.attachmentAttachmentEndDateResult, at);
  if (tmp$7 !== undefined) {
    tmp$6.validationResult = Caml_option.valFromOption(tmp$7);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  alignItems: "flex-start",
                  children: null,
                  container: true,
                  spacing: 2,
                  wrap: "nowrap"
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_CompanyFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry._2(form.updateAttachmentSiret, at, (function (param, siret) {
                                    return {
                                            attachments: Belt_Array.mapWithIndex(param.attachments, (function (index, attachment) {
                                                    if (index === at) {
                                                      return {
                                                              volumeOrigin: attachment.volumeOrigin,
                                                              siret: siret,
                                                              companyName: attachment.companyName,
                                                              dealerId: attachment.dealerId,
                                                              volume: attachment.volume,
                                                              attachmentStartDate: attachment.attachmentStartDate,
                                                              attachmentOldStartDate: attachment.attachmentOldStartDate,
                                                              attachmentEndDate: attachment.attachmentEndDate,
                                                              dirty: attachment.dirty
                                                            };
                                                    } else {
                                                      return attachment;
                                                    }
                                                  }))
                                          };
                                  })),
                            value: attachment.siret,
                            companies: match[0],
                            setCompanies: match[1],
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                            companyType: "siret"
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$2),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$4),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$6),
                      item: true
                    })));
}

var Form;

var make = Quota_Detail_Attachment_Add_Row;

export {
  Form ,
  make ,
}
/* react Not a pure module */
