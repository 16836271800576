// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Margin_Minimum_Types$DvmAdminFrontendFr from "./Margin_Minimum_Types.bs.js";
import * as Margin_Minimum_Api_Check$DvmAdminFrontendFr from "./Margin_Minimum_Api_Check.bs.js";

function fetchSearch(submissionCallbacks, userManager, setResult, queryParams, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Margin_Minimum_Types$DvmAdminFrontendFr.Minimums.searchResult_decode, undefined, userManager, selectedRole, "/margins/minimum" + queryParams + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var modelsTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: modelsTable
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchMinimumMarginSearch */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function editOrDeleteMinimumMargin(userManager, setResult, method, selectedRole, row) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call(method, {
          TAG: /* Json */0,
          _0: Margin_Minimum_Types$DvmAdminFrontendFr.Minimums.marginMinimum_encode(row)
        }, userManager, selectedRole, "/margins/minimum").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function overwriteMinimumMargin(submissionCallbacks, userManager, setResult, original, selectedRole, row) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var onError = function (error) {
    Belt_Option.forEach(submissionCallbacks, (function (param) {
            Curry._1(param.notifyOnFailure, /* FailedToSaveMinimumMargin */0);
          }));
    Curry._1(setResult, (function (param) {
            return {
                    TAG: /* Error */1,
                    _0: error
                  };
          }));
  };
  RestApi$DvmAdminFrontendFr.call("GET", Margin_Minimum_Types$DvmAdminFrontendFr.Minimums.searchResult_decode, undefined, userManager, selectedRole, Margin_Minimum_Api_Check$DvmAdminFrontendFr.getQuery(row)).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          if (Belt_List.length(Margin_Minimum_Api_Check$DvmAdminFrontendFr.removeOriginalFromQuery(result._0.content, original)) > 0) {
            Belt_Option.forEach(submissionCallbacks, (function (param) {
                    Curry._1(param.notifyOnFailure, /* FailedToSaveMinimumMargin */0);
                  }));
            return Curry._1(setResult, (function (param) {
                          return {
                                  TAG: /* Error */1,
                                  _0: App_Types_Result$DvmAdminFrontendFr.Rest.makeError(undefined, undefined, undefined, "9ff6a009-181a-46a6-81de-e47e68753e69")
                                };
                        }));
          } else {
            RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", {
                    TAG: /* Json */0,
                    _0: Margin_Minimum_Types$DvmAdminFrontendFr.Minimums.marginMinimum_encode(original)
                  }, userManager, selectedRole, "/margins/minimum").then(function (result) {
                  if (result.TAG !== /* OkResult */0) {
                    return onError(result._0);
                  }
                  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
                          TAG: /* Json */0,
                          _0: Margin_Minimum_Types$DvmAdminFrontendFr.Minimums.minimumsList_encode({
                                hd: row,
                                tl: /* [] */0
                              })
                        }, userManager, selectedRole, "/margins/minimum").then(function (result) {
                        if (result.TAG !== /* OkResult */0) {
                          return onError(result._0);
                        }
                        Belt_Option.forEach(submissionCallbacks, (function (param) {
                                Curry._1(param.notifyOnSuccess, undefined);
                              }));
                        Curry._1(setResult, (function (param) {
                                return {
                                        TAG: /* Ok */0,
                                        _0: undefined
                                      };
                              }));
                      });
                });
            return ;
          }
        } else {
          return onError(result._0);
        }
      });
}

function tryCreateNewMinimumMargin(submissionCallbacks, userManager, setResult, selectedRole, row) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var onError = function (error) {
    Belt_Option.forEach(submissionCallbacks, (function (param) {
            Curry._1(param.notifyOnFailure, /* FailedToSaveMinimumMargin */0);
          }));
    Curry._1(setResult, (function (param) {
            return {
                    TAG: /* Error */1,
                    _0: error
                  };
          }));
  };
  RestApi$DvmAdminFrontendFr.call("GET", Margin_Minimum_Types$DvmAdminFrontendFr.Minimums.searchResult_decode, undefined, userManager, selectedRole, Margin_Minimum_Api_Check$DvmAdminFrontendFr.getQuery(row)).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          if (Belt_List.length(result._0.content) > 0) {
            Belt_Option.forEach(submissionCallbacks, (function (param) {
                    Curry._1(param.notifyOnFailure, /* FailedToSaveMinimumMargin */0);
                  }));
            return Curry._1(setResult, (function (param) {
                          return {
                                  TAG: /* Error */1,
                                  _0: App_Types_Result$DvmAdminFrontendFr.Rest.makeError(undefined, undefined, undefined, "9ff6a009-181a-46a6-81de-e47e68753e69")
                                };
                        }));
          } else {
            RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
                    TAG: /* Json */0,
                    _0: Margin_Minimum_Types$DvmAdminFrontendFr.Minimums.minimumsList_encode({
                          hd: row,
                          tl: /* [] */0
                        })
                  }, userManager, selectedRole, "/margins/minimum").then(function (result) {
                  if (result.TAG !== /* OkResult */0) {
                    return onError(result._0);
                  }
                  Belt_Option.forEach(submissionCallbacks, (function (param) {
                          Curry._1(param.notifyOnSuccess, undefined);
                        }));
                  Curry._1(setResult, (function (param) {
                          return {
                                  TAG: /* Ok */0,
                                  _0: undefined
                                };
                        }));
                });
            return ;
          }
        } else {
          return onError(result._0);
        }
      });
}

var Form;

var AddRowForm;

var ApiCheck;

export {
  Form ,
  AddRowForm ,
  ApiCheck ,
  fetchSearch ,
  editOrDeleteMinimumMargin ,
  overwriteMinimumMargin ,
  tryCreateNewMinimumMargin ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
