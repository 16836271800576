// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as App_RadioFormInput$DvmAdminFrontendFr from "./App_RadioFormInput.bs.js";

function App_RadioFormInput_YesNo(Props) {
  var label = Props.label;
  var required = Props.required;
  var yesLabel = Props.yesLabel;
  var noLabel = Props.noLabel;
  var value = Props.value;
  var onChange = Props.onChange;
  var validationResult = Props.validationResult;
  var className = Props.className;
  var classNameTitle = Props.classNameTitle;
  var disabled = Props.disabled;
  var tmp = {
    label: label,
    value: value ? "T" : "F",
    options: {
      hd: {
        label: {
          TAG: /* String */3,
          _0: Belt_Option.getWithDefault(yesLabel, "Yes")
        },
        value: "T"
      },
      tl: {
        hd: {
          label: {
            TAG: /* String */3,
            _0: Belt_Option.getWithDefault(noLabel, "No")
          },
          value: "F"
        },
        tl: /* [] */0
      }
    },
    onChange: (function (v) {
        var tmp = v === "T" ? true : false;
        Curry._1(onChange, tmp);
      })
  };
  if (required !== undefined) {
    tmp.required = Caml_option.valFromOption(required);
  }
  if (validationResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(validationResult);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (classNameTitle !== undefined) {
    tmp.classNameTitle = Caml_option.valFromOption(classNameTitle);
  }
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  return React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, tmp);
}

var make = App_RadioFormInput_YesNo;

export {
  make ,
}
/* react Not a pure module */
