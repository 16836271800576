// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";

var FilterFields = {
  orderType: "orderType",
  dr: "dr",
  dealerId: "dealerID",
  dealerName: "dealerName",
  modelId: "modelId",
  year: "year",
  volumeInitial: "volumeInitial",
  volume: "volume",
  consumed: "consumed",
  remaining: "remaining",
  startDate: "startDate",
  endDate: "endDate",
  creationDate: "creationDate",
  modificationDate: "modificationDate",
  engineType: "engineType",
  phase: "phase"
};

function row_encode(v) {
  return Js_dict.fromArray([
              [
                "id",
                Decco.stringToJson(v.id)
              ],
              [
                "orderType",
                Decco.optionToJson(Decco.stringToJson, v.orderType)
              ],
              [
                "dealerId",
                Decco.optionToJson(Decco.stringToJson, v.dealerId)
              ],
              [
                "dealerName",
                Decco.optionToJson(Decco.stringToJson, v.dealerName)
              ],
              [
                "modelId",
                Decco.optionToJson(Decco.stringToJson, v.modelId)
              ],
              [
                "engineType",
                Decco.optionToJson(Decco.stringToJson, v.engineType)
              ],
              [
                "phase",
                Decco.optionToJson(Decco.stringToJson, v.phase)
              ],
              [
                "volumeInitial",
                Decco.optionToJson(Decco.intToJson, v.volumeInitial)
              ],
              [
                "volume",
                Decco.optionToJson(Decco.intToJson, v.volume)
              ],
              [
                "consumed",
                Decco.optionToJson(Decco.intToJson, v.consumed)
              ],
              [
                "remaining",
                Decco.optionToJson(Decco.intToJson, v.remaining)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ],
              [
                "creationDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.creationDate)
              ],
              [
                "modificationDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.modificationDate)
              ]
            ]);
}

function row_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var id = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "id"), null));
  if (id.TAG === /* Ok */0) {
    var orderType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderType"), null));
    if (orderType.TAG === /* Ok */0) {
      var dealerId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerId"), null));
      if (dealerId.TAG === /* Ok */0) {
        var dealerName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerName"), null));
        if (dealerName.TAG === /* Ok */0) {
          var modelId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelId"), null));
          if (modelId.TAG === /* Ok */0) {
            var engineType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "engineType"), null));
            if (engineType.TAG === /* Ok */0) {
              var phase = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "phase"), null));
              if (phase.TAG === /* Ok */0) {
                var volumeInitial = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "volumeInitial"), null));
                if (volumeInitial.TAG === /* Ok */0) {
                  var volume = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "volume"), null));
                  if (volume.TAG === /* Ok */0) {
                    var consumed = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "consumed"), null));
                    if (consumed.TAG === /* Ok */0) {
                      var remaining = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "remaining"), null));
                      if (remaining.TAG === /* Ok */0) {
                        var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
                        if (startDate.TAG === /* Ok */0) {
                          var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
                          if (endDate.TAG === /* Ok */0) {
                            var creationDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "creationDate"), null));
                            if (creationDate.TAG === /* Ok */0) {
                              var modificationDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "modificationDate"), null));
                              if (modificationDate.TAG === /* Ok */0) {
                                return {
                                        TAG: /* Ok */0,
                                        _0: {
                                          id: id._0,
                                          orderType: orderType._0,
                                          dealerId: dealerId._0,
                                          dealerName: dealerName._0,
                                          modelId: modelId._0,
                                          engineType: engineType._0,
                                          phase: phase._0,
                                          volumeInitial: volumeInitial._0,
                                          volume: volume._0,
                                          consumed: consumed._0,
                                          remaining: remaining._0,
                                          startDate: startDate._0,
                                          endDate: endDate._0,
                                          creationDate: creationDate._0,
                                          modificationDate: modificationDate._0
                                        }
                                      };
                              }
                              var e = modificationDate._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".modificationDate" + e.path,
                                        message: e.message,
                                        value: e.value
                                      }
                                    };
                            }
                            var e$1 = creationDate._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".creationDate" + e$1.path,
                                      message: e$1.message,
                                      value: e$1.value
                                    }
                                  };
                          }
                          var e$2 = endDate._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".endDate" + e$2.path,
                                    message: e$2.message,
                                    value: e$2.value
                                  }
                                };
                        }
                        var e$3 = startDate._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".startDate" + e$3.path,
                                  message: e$3.message,
                                  value: e$3.value
                                }
                              };
                      }
                      var e$4 = remaining._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".remaining" + e$4.path,
                                message: e$4.message,
                                value: e$4.value
                              }
                            };
                    }
                    var e$5 = consumed._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".consumed" + e$5.path,
                              message: e$5.message,
                              value: e$5.value
                            }
                          };
                  }
                  var e$6 = volume._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".volume" + e$6.path,
                            message: e$6.message,
                            value: e$6.value
                          }
                        };
                }
                var e$7 = volumeInitial._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".volumeInitial" + e$7.path,
                          message: e$7.message,
                          value: e$7.value
                        }
                      };
              }
              var e$8 = phase._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".phase" + e$8.path,
                        message: e$8.message,
                        value: e$8.value
                      }
                    };
            }
            var e$9 = engineType._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".engineType" + e$9.path,
                      message: e$9.message,
                      value: e$9.value
                    }
                  };
          }
          var e$10 = modelId._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".modelId" + e$10.path,
                    message: e$10.message,
                    value: e$10.value
                  }
                };
        }
        var e$11 = dealerName._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".dealerName" + e$11.path,
                  message: e$11.message,
                  value: e$11.value
                }
              };
      }
      var e$12 = dealerId._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".dealerId" + e$12.path,
                message: e$12.message,
                value: e$12.value
              }
            };
    }
    var e$13 = orderType._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".orderType" + e$13.path,
              message: e$13.message,
              value: e$13.value
            }
          };
  }
  var e$14 = id._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".id" + e$14.path,
            message: e$14.message,
            value: e$14.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(row_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(row_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

export {
  FilterFields ,
  row_encode ,
  row_decode ,
  searchResult_encode ,
  searchResult_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
