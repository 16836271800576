// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/core/styles";
import * as App_Api$DvmAdminFrontendFr from "../../../common/App_Api.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as App_Styles$DvmAdminFrontendFr from "../../../common/App_Styles.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Category_Router$DvmAdminFrontendFr from "../Category_Router.bs.js";
import * as Category_Styles$DvmAdminFrontendFr from "../Category_Styles.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Category_Edit_Api$DvmAdminFrontendFr from "./Category_Edit_Api.bs.js";
import * as Messages_Category$DvmAdminFrontendFr from "../../../intl/messages/category/Messages_Category.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Category_Edit_CategoryInfo_Form$DvmAdminFrontendFr from "./Category_Edit_CategoryInfo_Form.bs.js";

var modelStyle = Css.css({
      width: "15rem"
    });

function Category_Edit_CategoryInfo(Props) {
  var category = Props.category;
  var refresh = Props.refresh;
  var theme = Styles.useTheme();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModels = match[1];
  var models = match[0];
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$3[1];
  var result = match$3[0];
  var form = Category_Edit_CategoryInfo_Form$DvmAdminFrontendFr.CategoryEditForm.useForm(Belt_Option.mapWithDefault(category, Category_Edit_CategoryInfo_Form$DvmAdminFrontendFr.initialInput, (function (x) {
              return {
                      modelID: x.modelID,
                      categoryID: x.categoryID,
                      categoryName: Belt_Option.getWithDefault(x.categoryName, ""),
                      startDate: x.startDate,
                      endDate: x.endDate
                    };
            })), (function (output, submissionCallbacks) {
          Category_Edit_Api$DvmAdminFrontendFr.saveCategory(setResult, userManager, submissionCallbacks, Belt_Option.isSome(category) ? "PUT" : "POST", selectedRole, {
                modelID: output.modelID,
                categoryID: output.categoryID,
                categoryName: output.categoryName,
                startDate: Caml_option.some(output.startDate),
                endDate: Caml_option.some(output.endDate),
                versions: Belt_Option.mapWithDefault(category, /* [] */0, (function (x) {
                        return x.versions;
                      }))
              });
        }));
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchModels(setModels, userManager, selectedRole, undefined, undefined, undefined);
        }), []);
  React.useEffect((function () {
          if (typeof result === "number") {
            result === /* NotStarted */0;
          } else if (result.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Category_Router$DvmAdminFrontendFr.routeToEditPage(form.input.categoryID, Caml_option.some(form.input.startDate), undefined);
            Curry._1(refresh, undefined);
          } else {
            var error = result._0;
            if (error.messageId === "DATE_MISMATCH") {
              Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                        message: {
                          TAG: /* Message */0,
                          _0: Messages_Category$DvmAdminFrontendFr.datesModifyError
                        }
                      }));
            } else {
              Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                        message: {
                          TAG: /* RestError */2,
                          _0: error
                        }
                      }));
            }
          }
        }), [result]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.forError(models, (function (error) {
                  Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                            message: {
                              TAG: /* RestError */2,
                              _0: error
                            }
                          }));
                }));
        }), [models]);
  React.useEffect((function () {
          Curry._1(form.reset, undefined);
        }), [category]);
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.model,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.modelID,
        onChange: Curry._1(form.updateModelID, (function (input, modelID) {
                return {
                        modelID: modelID,
                        categoryID: input.categoryID,
                        categoryName: input.categoryName,
                        startDate: input.startDate,
                        endDate: input.endDate
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(models, /* [] */0)
    },
    onBlur: (function (param) {
        Curry._1(form.blurModelID, undefined);
      }),
    disabled: Belt_Option.isSome(category),
    required: /* OnlyLabel */1,
    className: modelStyle
  };
  if (form.modelIDResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.modelIDResult);
  }
  var tmp$1 = {
    onChange: Curry._1(form.updateCategoryID, (function (input, categoryID) {
            return {
                    modelID: input.modelID,
                    categoryID: categoryID,
                    categoryName: input.categoryName,
                    startDate: input.startDate,
                    endDate: input.endDate
                  };
          })),
    value: form.input.categoryID,
    disabled: Belt_Option.isSome(category),
    onBlur: (function (param) {
        Curry._1(form.blurCategoryID, undefined);
      }),
    fullWidth: true,
    label: Messages_Common$DvmAdminFrontendFr.categoryCode,
    required: /* OnlyLabel */1
  };
  if (form.categoryIDResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.categoryIDResult);
  }
  var tmp$2 = {
    onChange: Curry._1(form.updateCategoryName, (function (input, categoryName) {
            return {
                    modelID: input.modelID,
                    categoryID: input.categoryID,
                    categoryName: categoryName,
                    startDate: input.startDate,
                    endDate: input.endDate
                  };
          })),
    value: form.input.categoryName,
    onBlur: (function (param) {
        Curry._1(form.blurCategoryName, undefined);
      }),
    fullWidth: true,
    label: Messages_Category$DvmAdminFrontendFr.categoryNameLabel,
    required: /* OnlyLabel */1
  };
  if (form.categoryNameResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.categoryNameResult);
  }
  var tmp$3 = {
    label: Messages_Category$DvmAdminFrontendFr.startDateLabel,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    modelID: input.modelID,
                    categoryID: input.categoryID,
                    categoryName: input.categoryName,
                    startDate: startDate,
                    endDate: input.endDate
                  };
          })),
    disabled: Belt_Option.isSome(category),
    required: /* OnlyLabel */1
  };
  if (form.startDateResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  var tmp$4 = {
    label: Messages_Category$DvmAdminFrontendFr.endDateLabel,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    modelID: input.modelID,
                    categoryID: input.categoryID,
                    categoryName: input.categoryName,
                    startDate: input.startDate,
                    endDate: endDate
                  };
          })),
    required: /* OnlyLabel */1
  };
  if (form.endDateResult !== undefined) {
    tmp$4.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  return React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
              show: App_Types_Result$DvmAdminFrontendFr.isPending(result),
              children: React.createElement("form", {
                    onSubmit: (function ($$event) {
                        $$event.preventDefault();
                        Curry._1(form.submit, undefined);
                      })
                  }, React.createElement(Core.Grid, {
                        children: null,
                        className: Category_Styles$DvmAdminFrontendFr.formSection(theme),
                        container: true,
                        direction: "column",
                        spacing: 3
                      }, React.createElement(Core.Grid, {
                            children: React.createElement(Core.Typography, {
                                  children: intl.formatMessage(Messages_Category$DvmAdminFrontendFr.editTitle),
                                  className: App_Styles$DvmAdminFrontendFr.brownSemibold(theme),
                                  variant: "h4"
                                }),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                  show: App_Types_Result$DvmAdminFrontendFr.isPending(models),
                                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp)
                                }),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: null,
                            container: true,
                            item: true,
                            spacing: 2
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$1),
                                item: true,
                                xs: Grid$Mui.Xs[3]
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$2),
                                item: true,
                                xs: Grid$Mui.Xs[6]
                              })), React.createElement(Core.Grid, {
                            children: React.createElement(Core.Typography, {
                                  children: intl.formatMessage(Messages_Category$DvmAdminFrontendFr.datesTitle),
                                  variant: "h6",
                                  style: {
                                    fontWeight: "bold"
                                  }
                                }),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: null,
                            container: true,
                            item: true,
                            spacing: 2
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$3),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$4),
                                item: true
                              })), React.createElement(Core.Grid, {
                            children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                  label: intl.formatMessage(Belt_Option.isSome(category) ? Messages_Button$DvmAdminFrontendFr.edit : Messages_Button$DvmAdminFrontendFr.create),
                                  color: "secondary",
                                  size: "medium",
                                  type_: "submit"
                                }),
                            container: true,
                            item: true,
                            justifyContent: "center"
                          })))
            });
}

var Router;

var Api;

var Types;

var make = Category_Edit_CategoryInfo;

export {
  Router ,
  Api ,
  Types ,
  modelStyle ,
  make ,
}
/* modelStyle Not a pure module */
