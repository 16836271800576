// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as Action_Referential_Fields_Api$DvmAdminFrontendFr from "./Action_Referential_Fields_Api.bs.js";
import * as Action_Referential_Fields_Utils$DvmAdminFrontendFr from "./Action_Referential_Fields_Utils.bs.js";
import * as Messages_Action_Referential_Fields$DvmAdminFrontendFr from "../../../intl/messages/actionReferential/Messages_Action_Referential_Fields.bs.js";

function Action_Referential_Fields_Delete_Modal(Props) {
  var onClose = Props.onClose;
  var row = Props.row;
  var onOk = Props.onOk;
  var fieldType = Props.fieldType;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var alert = ReactAlert.useAlert();
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match$2[1];
  var saveResult = match$2[0];
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(onOk, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Action_Referential_Fields$DvmAdminFrontendFr.deleteTitle) + " - " + intl.formatMessage(Action_Referential_Fields_Utils$DvmAdminFrontendFr.fieldTypeToMessage(fieldType)),
              minWidth: "600px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult),
                    children: null
                  }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        align: "center",
                        color: "textPrimary",
                        gutterBottom: true,
                        children: intl.formatMessage(Messages_Action_Referential_Fields$DvmAdminFrontendFr.deleteMessage, {
                              value: row
                            }),
                        variant: "bodyBig"
                      }), React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        align: "center",
                        color: "error",
                        gutterBottom: true,
                        children: intl.formatMessage(Messages_Action_Referential_Fields$DvmAdminFrontendFr.deleteWarning),
                        variant: "bodyBig"
                      }), React.createElement(Core.Grid, {
                        children: null,
                        container: true,
                        item: true,
                        justifyContent: "space-between"
                      }, React.createElement(Core.Grid, {
                            children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                  onClick: (function (param) {
                                      Curry._1(onClose, undefined);
                                    }),
                                  label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.cancel),
                                  color: "primaryBorder"
                                }),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                  onClick: (function (param) {
                                      Action_Referential_Fields_Api$DvmAdminFrontendFr.deleteRow(userManager, setSaveResult, row, selectedRole, fieldType);
                                    }),
                                  label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.$$delete),
                                  color: "delete"
                                }),
                            item: true
                          })))
            });
}

var Api;

var make = Action_Referential_Fields_Delete_Modal;

export {
  Api ,
  make ,
}
/* react Not a pure module */
