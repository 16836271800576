// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as App_Api$DvmAdminFrontendFr from "../../../../common/App_Api.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../common/utils/Utils_Form.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../../common/components/App_Checkbox.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Grid_Edit_Api$DvmAdminFrontendFr from "../Grid_Edit_Api.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../common/components/App_OverlaySpinner.bs.js";
import * as Grid_Edit_Api_Check$DvmAdminFrontendFr from "../Grid_Edit_Api_Check.bs.js";
import * as Messages_MarginGrid$DvmAdminFrontendFr from "../../../../intl/messages/marginGrid/Messages_MarginGrid.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_AutocompleteFormInput.bs.js";

var validators = {
  category: {
    strategy: /* OnFirstSuccessOrFirstBlur */3,
    validate: (function (param) {
        return Utils_Form$DvmAdminFrontendFr.validateRequired(param.category);
      })
  }
};

function initialFieldsStatuses(_input) {
  return {
          category: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            category: /* Pristine */0
          },
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.category;
  var match$1 = match ? match._0 : Curry._1(validators.category.validate, input);
  if (match$1.TAG === /* Ok */0) {
    return {
            TAG: /* Valid */0,
            output: {
              category: match$1._0
            },
            fieldsStatuses: {
              category: /* Dirty */{
                _0: match$1,
                _1: /* Shown */0
              }
            },
            collectionsStatuses: undefined
          };
  } else {
    return {
            TAG: /* Invalid */1,
            fieldsStatuses: {
              category: /* Dirty */{
                _0: match$1,
                _1: /* Shown */0
              }
            },
            collectionsStatuses: undefined
          };
  }
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurCategoryField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.category, validators.category, (function (status) {
                          return {
                                  category: status
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */1 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */1,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */2,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */4);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */3);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */2 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */3 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */4 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateCategoryField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.category, state.submissionStatus, validators.category, (function (status) {
                                    return {
                                            category: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */1 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                category: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                category: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */2 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */3 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Caml_option.some(Curry._1(map, Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateCategory: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCategoryField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurCategory: (function (param) {
              Curry._1(dispatch, /* BlurCategoryField */0);
            }),
          categoryResult: Formality.exposeFieldResult(state.fieldsStatuses.category),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.category) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */1);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */2);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */3);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */3,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */4);
            })
        };
}

var EditFinalDateForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialInput = {
  category: ""
};

function Grid_Edit_NewCategory(Props) {
  var setActionsPending = Props.setActionsPending;
  var tableResult = Props.tableResult;
  var categoriesAll = Props.categoriesAll;
  var alert = ReactAlert.useAlert();
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match[1];
  var result = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setWithClosedModels = match$1[1];
  var withClosedModels = match$1[0];
  var match$2 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$3 = match$2[0];
  var userManager = match$3.userManager;
  var selectedRole = match$3.selectedRole;
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelVersions = match$4[1];
  var modelVersions = match$4[0];
  var match$5 = React.useState(function () {
        return /* [] */0;
      });
  var setModelVersionsList = match$5[1];
  var form = useForm(initialInput, (function (param, submissionCallbacks) {
          var category = param.category;
          if (tableResult !== undefined) {
            var rows = Belt_List.map(tableResult, (function (x) {
                    return {
                            original: x,
                            new: {
                              budget: x.budget,
                              model: x.model,
                              version: x.version,
                              avsMarginGridID: x.avsMarginGridID,
                              categoryID: category,
                              discount: x.discount,
                              margin: x.margin,
                              previousEndDate: x.previousEndDate,
                              startDate: x.startDate,
                              endDate: x.endDate,
                              hasExceptions: x.hasExceptions,
                              followingStartDate: x.followingStartDate,
                              acquisitionType: x.acquisitionType,
                              contractID: x.contractID,
                              categoryName: x.categoryName
                            }
                          };
                  }));
            if (Belt_List.length(Grid_Edit_Api_Check$DvmAdminFrontendFr.getOnlyChangedRows(rows)) > 0) {
              Grid_Edit_Api$DvmAdminFrontendFr.overwriteMarginRows(alert, userManager, setResult, /* GridID */2, /* MarginGrid */1, "AVS", selectedRole, rows);
            }
            
          }
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
        }));
  React.useEffect((function () {
          if (typeof result === "number") {
            if (result !== /* NotStarted */0) {
              Curry._1(setActionsPending, (function (param) {
                      return true;
                    }));
            }
            
          } else if (result.TAG === /* Ok */0) {
            Curry._1(setActionsPending, (function (param) {
                    return false;
                  }));
          } else {
            Curry._1(setActionsPending, (function (param) {
                    return false;
                  }));
          }
        }), [result]);
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchModelVersions(setModelVersions, userManager, selectedRole, withClosedModels, undefined, undefined);
        }), []);
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchModelVersions(setModelVersions, userManager, selectedRole, withClosedModels, undefined, undefined);
        }), [withClosedModels]);
  React.useEffect((function () {
          if (typeof modelVersions === "number") {
            modelVersions === /* NotStarted */0;
          } else if (modelVersions.TAG === /* Ok */0) {
            var modelVersions$1 = modelVersions._0;
            Curry._1(setModelVersionsList, (function (param) {
                    return modelVersions$1;
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: modelVersions._0
                      }
                    }));
          }
        }), [modelVersions]);
  var availableCategories = Belt_List.map(categoriesAll, (function (x) {
          return x.categoryID;
        }));
  var tmp = {
    label: Messages_MarginGrid$DvmAdminFrontendFr.newCategoryCode,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.category,
        onChange: Curry._1(form.updateCategory, (function (param, category) {
                return {
                        category: category
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: Belt_List.concat(Belt_List.map(match$5[0], (function (x) {
                  return x.parent;
                })), availableCategories)
    },
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.categoryResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.categoryResult);
  }
  var tmp$1;
  tmp$1 = typeof result === "number" ? null : (
      result.TAG === /* Ok */0 ? React.createElement(Core.Grid, {
              children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                    size: /* XLarge */4,
                    type_: /* Tick */1
                  }),
              item: true,
              style: {
                color: "green"
              }
            }) : React.createElement(Core.Grid, {
              children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                    size: /* XLarge */4,
                    type_: /* Cross */42
                  }),
              item: true,
              style: {
                color: "red"
              }
            })
    );
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                            gutterBottom: true,
                            style: {
                              fontWeight: "600"
                            },
                            children: intl.formatMessage(Messages_MarginGrid$DvmAdminFrontendFr.modifyCategoryCode),
                            variant: "bodyBig"
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement("form", {
                            onSubmit: (function ($$event) {
                                $$event.preventDefault();
                                Curry._1(form.submit, undefined);
                              })
                          }, React.createElement(Core.Grid, {
                                alignItems: "flex-start",
                                children: null,
                                container: true,
                                spacing: 3
                              }, React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                    show: App_Types_Result$DvmAdminFrontendFr.isPending(modelVersions),
                                    children: React.createElement(Core.Grid, {
                                          children: null,
                                          container: true,
                                          direction: "column",
                                          item: true,
                                          style: {
                                            width: "fit-content"
                                          }
                                        }, React.createElement(Core.Grid, {
                                              children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp),
                                              item: true
                                            }), React.createElement(Core.Grid, {
                                              children: React.createElement(Core.FormControlLabel, {
                                                    classes: {
                                                      label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                                    },
                                                    control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                                          checked: withClosedModels,
                                                          onClick: (function ($$event) {
                                                              Curry._1(setWithClosedModels, (function (param) {
                                                                      return $$event.target.checked;
                                                                    }));
                                                            })
                                                        }),
                                                    label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.includeClosedCars)
                                                  }),
                                              item: true,
                                              style: {
                                                whiteSpace: "nowrap",
                                                width: "1px"
                                              }
                                            }))
                                  }), React.createElement(Core.Grid, {
                                    children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                          label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.apply),
                                          color: "secondary",
                                          size: "medium",
                                          type_: "submit"
                                        }),
                                    item: true
                                  }), tmp$1)),
                      item: true
                    })));
}

var Api;

var Types;

var ApiCheck;

var InputStyles;

var make = Grid_Edit_NewCategory;

export {
  Api ,
  Types ,
  ApiCheck ,
  InputStyles ,
  EditFinalDateForm ,
  initialInput ,
  make ,
}
/* react Not a pure module */
