// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Core from "@material-ui/core";
import * as App_Table$DvmAdminFrontendFr from "../../common/components/table/App_Table.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../common/utils/Utils_Api.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../intl/Intl_Utils.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../common/components/table/App_Table_Head.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../common/App_Typography.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../common/types/App_Types_Result.bs.js";
import * as App_Types_Status$DvmAdminFrontendFr from "../../common/types/App_Types_Status.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../context/user/User_Types_Utils.bs.js";
import * as App_Styles_Status$DvmAdminFrontendFr from "../../common/styles/App_Styles_Status.bs.js";
import * as Order_Detail_Router$DvmAdminFrontendFr from "../order/detail/Order_Detail_Router.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../common/components/table/App_Table_Pagination.bs.js";
import * as Bonuses_Search_Types$DvmAdminFrontendFr from "./Bonuses_Search_Types.bs.js";
import * as App_Table_Export_Menu$DvmAdminFrontendFr from "../../common/components/table/App_Table_Export_Menu.bs.js";
import * as Messages_Bonuses_List$DvmAdminFrontendFr from "../../intl/messages/bonuses/Messages_Bonuses_List.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Messages_Orders_Search$DvmAdminFrontendFr from "../../intl/messages/orders/Messages_Orders_Search.bs.js";
import * as Messages_Bonuses_Manage$DvmAdminFrontendFr from "../../intl/messages/bonuses/Messages_Bonuses_Manage.bs.js";

var checkboxStyle = Css.css({
      padding: "0px 9px"
    });

function getEnglish(param) {
  return param[0];
}

function headersConfiguration(selectedRole, operation) {
  return Belt_List.concat(Belt_List.keepMap(Belt_List.concat(User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? ({
                          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.orderType : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.orderType), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.orderType),
                          tl: /* [] */0
                        }) : ({
                          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.clientTypes), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.clientType),
                          tl: {
                            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.saleTypes), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.saleType),
                            tl: /* [] */0
                          }
                        }), {
                      hd: selectedRole !== /* CompanyManager */2 ? App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.dr : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.dr), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.territory) : undefined,
                      tl: {
                        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.dealerID : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.dealerId), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.dealerID),
                        tl: {
                          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.customerOrderNumber : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.orderId), undefined, undefined, undefined, undefined, undefined, Messages_Bonuses_List$DvmAdminFrontendFr.orderID),
                          tl: {
                            hd: User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.budgetType : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.budgetType), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.budget) : undefined,
                            tl: {
                              hd: operation === /* View */1 ? App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.actionType), undefined, undefined, undefined, undefined, undefined, Messages_Bonuses_List$DvmAdminFrontendFr.flashType) : undefined,
                              tl: {
                                hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.actionLabel : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.actionLabel), undefined, undefined, undefined, undefined, undefined, Messages_Bonuses_List$DvmAdminFrontendFr.flashLabel),
                                tl: {
                                  hd: User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.siret : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.siret), undefined, undefined, undefined, undefined, undefined, Messages_Bonuses_List$DvmAdminFrontendFr.clientSiret) : undefined,
                                  tl: {
                                    hd: operation === /* View */1 && User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.customerName), undefined, undefined, undefined, undefined, undefined, Messages_Orders_Detail$DvmAdminFrontendFr.customerName) : undefined,
                                    tl: {
                                      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.actionStatus : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.status), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.state),
                                      tl: {
                                        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.modelID : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.model), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.model),
                                        tl: {
                                          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.versionID : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.version), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.version),
                                          tl: {
                                            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.actionBonus : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.actualAmount), undefined, operation === /* View */1, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.amountEuro),
                                            tl: {
                                              hd: (selectedRole !== /* Dealer */7 || !User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole)) && operation === /* Manage */0 ? App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.maxAmount), undefined, undefined, "right", undefined, undefined, Messages_Bonuses_Manage$DvmAdminFrontendFr.authorizedAmount) : undefined,
                                              tl: {
                                                hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.budgetYear : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.budgetYear), undefined, undefined, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.year),
                                                tl: {
                                                  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.orderDate : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.orderDate), /* Desc */1, undefined, "right", undefined, undefined, Messages_Orders_Search$DvmAdminFrontendFr.orderDate),
                                                  tl: {
                                                    hd: (selectedRole !== /* Dealer */7 || !User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole)) && operation === /* Manage */0 ? App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.deliveryDate), undefined, undefined, "right", undefined, undefined, Messages_Orders_Search$DvmAdminFrontendFr.deliveryDate) : undefined,
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }), (function (x) {
                    return x;
                  })), operation ? ({
                  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.entryDateParc), undefined, undefined, "right", undefined, undefined, Messages_Orders_Detail$DvmAdminFrontendFr.entryDateParc),
                  tl: {
                    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.exitDateParc), undefined, undefined, "right", undefined, undefined, Messages_Orders_Detail$DvmAdminFrontendFr.exitDateParc),
                    tl: /* [] */0
                  }
                }) : /* [] */0);
}

function getStatusItem(status, intl) {
  return React.createElement(Core.Grid, {
              alignItems: "center",
              children: null,
              container: true,
              direction: "row",
              spacing: 1,
              wrap: "nowrap"
            }, React.createElement(Core.Grid, {
                  children: React.createElement(Core.Box, {
                        className: App_Styles_Status$DvmAdminFrontendFr.oval,
                        bgcolor: App_Styles_Status$DvmAdminFrontendFr.stateColors(status !== undefined ? status : /* EMPTYSTATUS */10)
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: Belt_Option.mapWithDefault(status, "", (function (x) {
                          return intl.formatMessage(App_Types_Status$DvmAdminFrontendFr.toMessage(x));
                        })),
                  item: true
                }));
}

function Bonuses_Search_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var updateSelectedRows = Props.updateSelectedRows;
  var selectedRowsMap = Props.selectedRowsMap;
  var selectedRole = Props.selectedRole;
  var operation = Props.operation;
  var paramsList = Props.paramsList;
  var confirmedOrderType = Props.confirmedOrderType;
  var intl = ReactIntl.useIntl();
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (row) {
                                  var tmp;
                                  if ((selectedRole !== /* Dealer */7 || !User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole)) && operation === /* Manage */0) {
                                    var bonus = Belt_Option.getWithDefault(row.actualAmount, 0.0);
                                    var maxAmount = Belt_Option.getWithDefault(row.maxAmount, 0.0);
                                    tmp = Caml_option.some(React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                              color: bonus > maxAmount ? "error" : "textPrimary",
                                              children: Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, maxAmount)
                                            }));
                                  } else {
                                    tmp = undefined;
                                  }
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow((function (param) {
                                                Order_Detail_Router$DvmAdminFrontendFr.routeToPage(row.orderId, undefined);
                                              }), operation === /* Manage */0 ? Caml_option.some(React.createElement(Core.Checkbox, {
                                                        onClick: (function ($$event) {
                                                            var actualAmount = Belt_Option.getWithDefault(row.actualAmount, 0.0);
                                                            var maxAmountPlanPERF = Belt_Option.getWithDefault(row.maxAmount, 0.0);
                                                            Curry._2(updateSelectedRows, {
                                                                  dr: row.dr,
                                                                  dealerId: row.dealerId,
                                                                  orderId: row.orderId,
                                                                  orderNumber: row.orderNumber,
                                                                  budgetType: Belt_Option.getWithDefault(row.budgetType, ""),
                                                                  actionLabel: Belt_Option.getWithDefault(row.actionLabel, ""),
                                                                  actionId: row.actionId,
                                                                  actionType: Belt_Option.getWithDefault(row.actionType, ""),
                                                                  status: Belt_Option.getWithDefault(row.status, /* EMPTYSTATUS */10),
                                                                  model: Belt_Option.getWithDefault(row.model, ""),
                                                                  version: Belt_Option.getWithDefault(row.version, ""),
                                                                  maxAmountPlanPERF: maxAmountPlanPERF,
                                                                  actualAmount: actualAmount,
                                                                  dealerHasMore: actualAmount > maxAmountPlanPERF,
                                                                  approved: row.approved
                                                                }, $$event.target.checked ? "add" : "remove");
                                                          }),
                                                        className: checkboxStyle,
                                                        checked: Belt_Option.isSome(Belt_MapString.get(selectedRowsMap, row.actionId + row.orderId)),
                                                        color: "primary"
                                                      })) : undefined, undefined, Belt_List.mapWithIndex(Belt_List.concat(Belt_List.keepMap(Belt_List.concat(User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? ({
                                                                  hd: Caml_option.some(Belt_Option.getWithDefault(row.orderType, "")),
                                                                  tl: /* [] */0
                                                                }) : ({
                                                                  hd: Caml_option.some(Belt_Option.getWithDefault(row.orderClientType, "")),
                                                                  tl: {
                                                                    hd: Caml_option.some(Belt_Option.getWithDefault(row.orderSaleType, "")),
                                                                    tl: /* [] */0
                                                                  }
                                                                }), {
                                                              hd: selectedRole !== /* CompanyManager */2 ? Caml_option.some(row.dr) : undefined,
                                                              tl: {
                                                                hd: Caml_option.some(row.dealerId),
                                                                tl: {
                                                                  hd: Caml_option.some(row.orderNumber),
                                                                  tl: {
                                                                    hd: User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? Caml_option.some(Belt_Option.getWithDefault(row.budgetType, "")) : undefined,
                                                                    tl: {
                                                                      hd: operation === /* View */1 ? Caml_option.some(Belt_Option.getWithDefault(row.actionType, "")) : undefined,
                                                                      tl: {
                                                                        hd: Caml_option.some(Belt_Option.getWithDefault(row.actionLabel, "")),
                                                                        tl: {
                                                                          hd: User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? Caml_option.some(Belt_Option.getWithDefault(row.siret, "")) : undefined,
                                                                          tl: {
                                                                            hd: operation === /* View */1 && User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? Caml_option.some(Belt_Option.getWithDefault(row.customerName, "")) : undefined,
                                                                            tl: {
                                                                              hd: Caml_option.some(getStatusItem(row.status, intl)),
                                                                              tl: {
                                                                                hd: Caml_option.some(Belt_Option.getWithDefault(row.model, "")),
                                                                                tl: {
                                                                                  hd: Caml_option.some(Belt_Option.getWithDefault(row.version, "")),
                                                                                  tl: {
                                                                                    hd: Caml_option.some(Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, Belt_Option.getWithDefault(operation ? row.actionBonus : row.actualAmount, 0.0))),
                                                                                    tl: {
                                                                                      hd: tmp,
                                                                                      tl: {
                                                                                        hd: Caml_option.some(Belt_Option.getWithDefault(row.budgetYear, "")),
                                                                                        tl: {
                                                                                          hd: Caml_option.some(Belt_Option.mapWithDefault(row.orderDate, "", (function (param) {
                                                                                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                                                    }))),
                                                                                          tl: {
                                                                                            hd: (selectedRole !== /* Dealer */7 || !User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole)) && operation === /* Manage */0 ? Caml_option.some(Belt_Option.mapWithDefault(row.deliveryDate, "", (function (param) {
                                                                                                          return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                                                        }))) : undefined,
                                                                                            tl: /* [] */0
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }), (function (x) {
                                                            return x;
                                                          })), operation && (Belt_List.every(confirmedOrderType, (function (x) {
                                                              switch (x) {
                                                                case /* SO */0 :
                                                                case /* CL */1 :
                                                                case /* VD */3 :
                                                                case /* PG */6 :
                                                                    return false;
                                                                case /* VE */2 :
                                                                case /* RR */4 :
                                                                case /* RC */5 :
                                                                case /* NOTSET */7 :
                                                                    return true;
                                                                
                                                              }
                                                            })) || Belt_List.length(confirmedOrderType) === 0) ? ({
                                                          hd: Belt_Option.mapWithDefault(row.resultCalcPARCStartDate, "", (function (param) {
                                                                  return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                })),
                                                          tl: {
                                                            hd: Belt_Option.mapWithDefault(row.resultPARCEndDate, "", (function (param) {
                                                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                  })),
                                                            tl: /* [] */0
                                                          }
                                                        }) : /* [] */0), (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration(selectedRole, operation), index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._6(fetchData, undefined, undefined, undefined, undefined, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._6(fetchData, undefined, undefined, undefined, undefined, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._6(fetchData, undefined, undefined, undefined, undefined, undefined, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var tmp = {
    headers: operation === /* View */1 ? Belt_List.map(fetchRequest.headers, (function (x) {
              return {
                      idheader: x.idheader,
                      label: x.label,
                      order: x.order,
                      sort: tablePage === /* NotStarted */0 ? false : x.sort,
                      align: x.align,
                      priority: x.priority,
                      hide: operation && !(Belt_List.every(confirmedOrderType, (function (x) {
                                switch (x) {
                                  case /* SO */0 :
                                  case /* CL */1 :
                                  case /* VD */3 :
                                  case /* PG */6 :
                                      return false;
                                  case /* VE */2 :
                                  case /* RR */4 :
                                  case /* RC */5 :
                                  case /* NOTSET */7 :
                                      return true;
                                  
                                }
                              })) || Belt_List.length(confirmedOrderType) === 0) ? x.idheader === getEnglish(Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.entryDateParc) || x.idheader === getEnglish(Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.exitDateParc) : false
                    };
            })) : fetchRequest.headers,
    handleSorting: handleSorting,
    tablePage: convertTable(tablePage),
    paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined),
    exportMenu: React.createElement(Core.Tooltip, {
          arrow: true,
          children: React.createElement("div", undefined, React.createElement(App_Table_Export_Menu$DvmAdminFrontendFr.make, {
                    query: Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                          page: 0,
                          rowsPerPage: 100000,
                          headers: fetchRequest.headers,
                          filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap(paramsList, (function (queryParam) {
                                          return Belt_Option.map(queryParam, (function (x) {
                                                        if (operation) {
                                                          return x.fr;
                                                        } else {
                                                          return x.en;
                                                        }
                                                      }));
                                        }))), ";", (function (queryParam) {
                                  return queryParam;
                                })),
                          additionalQueryParams: fetchRequest.additionalQueryParams
                        }),
                    endpoint: operation === /* Manage */0 ? "bonuses/manual" : "bonuses/automatic",
                    disabled: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(tablePage, false, (function (param) {
                            return param.total > 100000;
                          }))
                  })),
          placement: "top",
          title: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.maxLinesExport, {
                count: intl.formatNumber(100000)
              })
        })
  };
  var tmp$1 = operation === /* Manage */0 ? ({
        TAG: /* Left */0,
        _0: /* EmptyCell */0
      }) : undefined;
  if (tmp$1 !== undefined) {
    tmp.rowButton = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(App_Table$DvmAdminFrontendFr.make, tmp);
}

var make = Bonuses_Search_Table;

export {
  checkboxStyle ,
  getEnglish ,
  headersConfiguration ,
  getStatusItem ,
  make ,
}
/* checkboxStyle Not a pure module */
