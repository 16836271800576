// Generated by ReScript, PLEASE EDIT WITH CARE

import * as App_Types_Json$DvmAdminFrontendFr from "../../../common/types/App_Types_Json.bs.js";

function toString(status) {
  switch (status) {
    case /* SO */0 :
        return "SO";
    case /* CL */1 :
        return "CL";
    case /* VE */2 :
        return "VE";
    case /* VD */3 :
        return "VD";
    case /* RR */4 :
        return "RR";
    case /* RC */5 :
        return "RC";
    case /* PG */6 :
        return "PG";
    case /* NOTSET */7 :
        return "";
    
  }
}

function fromString(status) {
  switch (status) {
    case "" :
        return /* NOTSET */7;
    case "CL" :
        return /* CL */1;
    case "PG" :
        return /* PG */6;
    case "RC" :
        return /* RC */5;
    case "RR" :
        return /* RR */4;
    case "SO" :
        return /* SO */0;
    case "VD" :
        return /* VD */3;
    case "VE" :
        return /* VE */2;
    default:
      return ;
  }
}

var Decco = App_Types_Json$DvmAdminFrontendFr.MakeStringableJsonType({
      toString: toString,
      fromString: fromString
    });

export {
  toString ,
  fromString ,
  Decco ,
}
/* Decco Not a pure module */
