// Generated by ReScript, PLEASE EDIT WITH CARE


var appNameShort = {
  id: "6721e974-de72-4893-9671-9d0950d8d4e5",
  defaultMessage: "DVME"
};

var authentication = {
  id: "292c6192-53a9-4c6b-9f09-64c2ac460eb3",
  defaultMessage: "Authentification..."
};

var noRole = {
  id: "0600e2e0-0282-4e90-be15-9ef4ca7f3206",
  defaultMessage: "Vous n'avez aucun rôle"
};

var loadVaricentProfile = {
  id: "38b2b755-0401-4fde-a91f-b3e2d821b389",
  defaultMessage: "Profil de chargement"
};

var alertInfo = {
  id: "a291fe3a-9c9d-415e-b5d0-18922af9264b",
  defaultMessage: "Information Importante"
};

var alertSuccess = {
  id: "0c9b41aa-b3d5-4f3d-a2e0-4de301d59041",
  defaultMessage: "Mise à jour effectuée"
};

var alertError = {
  id: "e4a7079f-fb53-4bd4-9bfb-328dcf8b9195",
  defaultMessage: "Une erreur inattendue s’est survenue"
};

var yes = {
  id: "7d4fbab3-cc63-44f4-a8e9-f147a41e8168",
  defaultMessage: "Oui"
};

var no = {
  id: "76cee6e0-2ea9-445d-b8ea-05f1764bcadd",
  defaultMessage: "Non"
};

var yesNo = {
  id: "ae13023b-e09d-4660-9754-a6f669d3828d",
  defaultMessage: "Oui/Non"
};

var exportButton = {
  id: "07d8a05c-421a-472a-8f56-e4637802313b",
  defaultMessage: "Exporter"
};

var year = {
  id: "f7cd2cbb-e14d-4131-bc01-66b220791a04",
  defaultMessage: "Année"
};

var startDate = {
  id: "efa7b200-de23-4ec7-983b-2dee9ead8724",
  defaultMessage: "Date début"
};

var endDate = {
  id: "86eedff7-23c0-45cb-b6aa-a37441d22c2b",
  defaultMessage: "Date fin"
};

var updateDate = {
  id: "7f47eae0-d93f-4775-882c-14b44cf93295",
  defaultMessage: "Dernière modification"
};

var amount = {
  id: "434b3e77-d3f9-43fb-bb91-ae2adafbb87e",
  defaultMessage: "Montant"
};

var amountEuro = {
  id: "35408b37-1898-4a01-8562-0971e12f35a4",
  defaultMessage: "Montant (€)"
};

var amountPercent = {
  id: "cc5eeb37-9790-433d-a35d-63042beebfdf",
  defaultMessage: "Montant (%)"
};

var budget = {
  id: "ad2a498d-4a53-4cf7-ae05-45dd14ad5e9f",
  defaultMessage: "Budget"
};

var model = {
  id: "5bfdb74f-c7c9-497b-97b4-9652c208a4c4",
  defaultMessage: "Modèle"
};

var version = {
  id: "00c0c79b-b82e-4a22-8764-7517713e4edc",
  defaultMessage: "Version"
};

var quota = {
  id: "8e34435f-96f4-408c-ad58-1d3e5a797500",
  defaultMessage: "Quota"
};

var models = {
  id: "82d7d46b-d55b-4e24-9f42-6f9216500944",
  defaultMessage: "Modèles"
};

var versions = {
  id: "ddb50ea9-2a48-4c82-b80c-f8eebc7112a5",
  defaultMessage: "Versions"
};

var exceptionVersions = {
  id: "9e4aebab-80fe-4182-847e-556a065b6e79",
  defaultMessage: "Exception versions"
};

var quotas = {
  id: "4029eff5-6f5d-4165-a9ac-e85d4fa66b82",
  defaultMessage: "Quotas"
};

var lld = {
  id: "97be630c-62cd-4c61-b47d-4dc122fd049c",
  defaultMessage: "LLD"
};

var lcd = {
  id: "5f99bed8-171c-43be-99a5-556c85195d0d",
  defaultMessage: "LCD"
};

var prot = {
  id: "9c395c95-661f-4950-be2a-9af05aa881ae",
  defaultMessage: "PROT"
};

var avs = {
  id: "d9c60898-7a1d-4a20-955e-45b3ab4fc84d",
  defaultMessage: "AVS"
};

var siren = {
  id: "ec5a4c93-16d0-4f5b-a0c1-930893807a30",
  defaultMessage: "SIREN"
};

var siret = {
  id: "72b499f2-9ea5-4062-8d66-44b006d2255b",
  defaultMessage: "SIRET"
};

var tablesResult = {
  id: "96aa94c8-1b83-4e3f-b701-26e356083173",
  defaultMessage: "Résultat"
};

var dealerID = {
  id: "d435fee7-a8c6-43c8-a5de-4d82305d239a",
  defaultMessage: "N° affaire"
};

var vin = {
  id: "123a170a-a6de-41ab-8aa5-749ecc071a13",
  defaultMessage: "VIN"
};

var state = {
  id: "8a1a01cc-07c7-4051-b6fb-4cba2ad16d5a",
  defaultMessage: "Statut"
};

var stateBudget = {
  id: "e7a53d09-520a-44a1-9c8b-7365a2890294",
  defaultMessage: "Statut Budget"
};

var agent = {
  id: "da40179e-6e96-4472-bcb6-f98a2fe255fe",
  defaultMessage: "Agent"
};

var seller = {
  id: "1bee47fd-b530-4a3a-b889-2fe77e6ba1d3",
  defaultMessage: "Vendeur"
};

var discount = {
  id: "3f1ede20-7ec0-4593-b129-0b74fb97c8de",
  defaultMessage: "Remise"
};

var discountTTC = {
  id: "baae8e3c-e29e-4585-8c21-3b272c7279c2",
  defaultMessage: "Remise Client TTC"
};

var discountWithPercent = {
  id: "df6b8ab5-7b54-4108-b372-dcccc2fa2575",
  defaultMessage: "Remise %"
};

var phase = {
  id: "2872d317-dc89-4856-9851-de113f649fff",
  defaultMessage: "Phase"
};

var brand = {
  id: "97c9d4ce-f26d-4c71-ad3d-59cbc5137efb",
  defaultMessage: "Marque"
};

var brands = {
  id: "d8e80eaf-260c-44b6-a58e-80d81c691c6c",
  defaultMessage: "Marques"
};

var search = {
  id: "b266667a-16a2-4a3b-9a7c-c8af2de5625d",
  defaultMessage: "Rechercher"
};

var options = {
  id: "c4e23f78-40e5-4366-98bf-43b078e97e28",
  defaultMessage: "Options"
};

var category = {
  id: "949328b2-05c1-4e59-80a4-e2749c70ac46",
  defaultMessage: "Catégorie"
};

var categoryCode = {
  id: "64af080a-934e-4ae5-b19f-49fe6b7f625a",
  defaultMessage: "Code Catégorie"
};

var categoryLabel = {
  id: "e750d899-c398-4c7f-8111-77c849be3d66",
  defaultMessage: "Libellé Catégorie"
};

var period = {
  id: "fd9e29d2-caf8-4d5c-9d34-cb6c0a762f1b",
  defaultMessage: "Période"
};

var searchTheTable = {
  id: "63fd0ede-ccec-4d4f-8b6e-92981239c262",
  defaultMessage: "Rechercher dans le tableau"
};

var nothing = {
  id: "3809c9a1-3c36-42fa-a083-557cfaae95fd",
  defaultMessage: "Rien"
};

var editDates = {
  id: "32a39625-30f8-4b69-91fc-cd89c17b63ec",
  defaultMessage: "Modifier les dates"
};

var zipCode = {
  id: "b885e40d-2bfa-4ca6-b8b7-84e5b18e639a",
  defaultMessage: "Code postal"
};

var positive = {
  id: "19eecb56-08c9-4d40-9770-1b2b17bb6fa7",
  defaultMessage: "Positive"
};

var negative = {
  id: "7301b6f4-3148-4800-9b5f-e98e4f824542",
  defaultMessage: "Négative"
};

var transferListOptions = {
  id: "791942dc-5ef2-430a-a118-17280319f0f1",
  defaultMessage: "Options ({count})"
};

var transferListSelected = {
  id: "bc544c58-8859-4195-a851-d7f1b23a1702",
  defaultMessage: "Sélectionnées ({count})"
};

var actions = {
  id: "45ccfb5d-3855-46f9-ad1b-2307d405310a",
  defaultMessage: "Actions"
};

var loading = {
  id: "caae3035-9d55-442c-89cd-e6f1dbbf4b61",
  defaultMessage: "Téléchargement…"
};

var inProgress = {
  id: "1b77c6f2-c695-40aa-8a95-c971a96ff85d",
  defaultMessage: "En cours…"
};

var noLabel = {
  id: "c2316ded-976b-456c-8b2e-a8d2b4c9cb33",
  defaultMessage: "Libellé vide"
};

var unsavedChanges = {
  id: "70209246-505c-4b18-9451-12231a0da0d7",
  defaultMessage: "Changements non pris en compte"
};

var insertNumberOfCharacters = {
  id: "7eb8a885-8662-4744-b77b-e9838e064f35",
  defaultMessage: "Saisir au moins {number} caractères"
};

var nothingFound = {
  id: "0f354845-6458-4a39-bc9e-5db73e8d8296",
  defaultMessage: "Donnée introuvable"
};

var somethingFound = {
  id: "c54d81d4-e8be-4b41-8464-2fc5fd3de8e5",
  defaultMessage: "Trouvé"
};

var companyName = {
  id: "605cb967-89d3-485d-8a51-e8098a19b972",
  defaultMessage: "Nom société"
};

var addNewRow = {
  id: "87253cdf-ed68-4f13-be34-56e106ae34d6",
  defaultMessage: "Ajouter une nouvelle ligne"
};

var addNewRows = {
  id: "0193d717-d0d5-4ac2-9fdb-a58b9b65604f",
  defaultMessage: "Ajouter plusieurs nouvelles lignes"
};

var modifyRow = {
  id: "e85c1835-69e6-4a2c-9ff2-3ee6366cbc81",
  defaultMessage: "Modifier une ligne"
};

var city = {
  id: "0a32949e-a0d0-4cda-be83-6ef404129b94",
  defaultMessage: "Ville"
};

var reallyDelete = {
  id: "75b9dc98-a705-4363-9ecc-b2641d574959",
  defaultMessage: "Êtes-vous sûr de vouloir supprimer ?"
};

var showDealerHistory = {
  id: "6326741e-6bc0-4364-8fe4-d494b8f6255b",
  defaultMessage: "Afficher y compris les affaires fermées et inconnues"
};

var maxLinesExport = {
  id: "3f5c93ae-ef3d-490c-8574-7d16db168c6e",
  defaultMessage: "{count} lignes max"
};

var everything = {
  id: "f3dbfecd-b628-4ec3-88a3-6d8db557134d",
  defaultMessage: "Tous"
};

var logourMessage = {
  id: "153591d4-db42-456b-8d57-54b155096f1b",
  defaultMessage: "Vous avez été déconnecté"
};

var comment = {
  id: "50416f0f-6990-44a3-bba9-d91d4a0fc954",
  defaultMessage: "Commentaire"
};

var maxCharactersAllowed = {
  id: "7dae6f4e-7170-4883-ae8e-66c7d6ec1c53",
  defaultMessage: "{max} caractères autorisés"
};

var territory = {
  id: "a0c9efea-da50-4c5c-ab72-b78b38bf65cd",
  defaultMessage: "Territoire"
};

var includeClosedCars = {
  id: "e35b2d18-f071-4d19-a3aa-13153be230ad",
  defaultMessage: "Modèles indisponibles inclus"
};

var varicentMigrationInfo = {
  id: "ac80fdd0-187b-45aa-8853-0cb89d620abe",
  defaultMessage: "DVME déploie actuellement une nouvelle version, veuillez patienter et actualiser à la fin du déploiement"
};

var selection = {
  id: "d5898ef0-b230-46cc-a74e-ff114f62e343",
  defaultMessage: "Sélection"
};

var noOption = {
  id: "ca014b71-5a01-4a19-bd38-bb6af081ab79",
  defaultMessage: "Donnée absente"
};

var previousEndDate = {
  id: "31098541-cdf4-410e-912b-c8688a00a234",
  defaultMessage: "Date de fin précédente"
};

var followingStartDate = {
  id: "d88aeb37-b2bf-4107-96c8-e14312516616",
  defaultMessage: "Date de début suivante"
};

var dateFormat = {
  id: "3317a424-cbbe-4e9d-a03e-09694f02bb82",
  defaultMessage: "Format de date"
};

var formatDDMMYYYY = {
  id: "0c4eab75-1cf3-4f65-aed3-7d902d27ecc3",
  defaultMessage: "jj-mm-aaaa"
};

var formatMMDDYYYY = {
  id: "f63bce3d-9bdf-4562-bb25-69d3630b02ee",
  defaultMessage: "mm-jj-aaaa"
};

var formatYYYYMMDD = {
  id: "4c4c511c-a51e-4a67-b6c8-abf02c22ba17",
  defaultMessage: "aaaa-mm-jj"
};

var creationDate = {
  id: "b866bf42-9e9e-4dd4-9c9c-0dbb155769e2",
  defaultMessage: "Date de création"
};

var lastModificationDate = {
  id: "47f05eb7-a565-4f3c-8d4c-b0a256e45c5e",
  defaultMessage: "Dernière modification"
};

var amountHT = {
  id: "28040bbb-d772-46c9-a7bc-5bd74a65f75a",
  defaultMessage: "Montant HT"
};

var amountHTPercentage = {
  id: "6a655970-b139-4ed8-b6b0-976cc8c89253",
  defaultMessage: "Montant HT (€)"
};

var $$default = {
  id: "4fc41ca5-86c0-4ab1-8dbe-3089eb54f42a",
  defaultMessage: "Défaut"
};

var tomorrow = {
  id: "dafb9e8d-f600-455d-ab18-e98b00ed54a0",
  defaultMessage: "Demain"
};

var brandVO = {
  id: "03d4021e-e07c-4c4e-8d4f-205c5c6ce543",
  defaultMessage: "Marque (VO)"
};

var modelVO = {
  id: "29a2b47e-3261-4741-814e-812becf2e7f2",
  defaultMessage: "Modèle (VO)"
};

var versionVO = {
  id: "0bcb827a-d4a2-45cf-9248-d19c42dfeb89",
  defaultMessage: "Version (VO)"
};

var vinVO = {
  id: "07769f4b-609f-4444-92ea-bcaab4992505",
  defaultMessage: "VIN (VO)"
};

var registrationVO = {
  id: "81e7d6dc-345f-4884-88d8-5d4623e44fd6",
  defaultMessage: "Immatriculation (VO)"
};

var orderType = {
  id: "fe52ed50-19e4-452f-abbe-a77fc6893473",
  defaultMessage: "Type de commande"
};

var filters = {
  id: "05262672-2b9e-43ea-b666-a0f2e8325745",
  defaultMessage: "Filtres"
};

var clientType = {
  id: "51501b74-3064-4827-9027-13f617e10192",
  defaultMessage: "Type de client"
};

var saleType = {
  id: "d9902b90-e818-4cfc-85e2-99144936a5a8",
  defaultMessage: "Type de vente"
};

var mandatory = {
  id: "b0bedb16-aa4f-4a7e-82b6-5b11066e2d12",
  defaultMessage: "Obligatoire"
};

var optional = {
  id: "75028949-10e5-4c81-b2f4-f71daec3db9b",
  defaultMessage: "Optionnel"
};

var dealer = {
  id: "0884749e-a19e-4212-a7c6-bc45b4fdc35e",
  defaultMessage: "Affaire"
};

export {
  appNameShort ,
  authentication ,
  noRole ,
  loadVaricentProfile ,
  alertInfo ,
  alertSuccess ,
  alertError ,
  yes ,
  no ,
  yesNo ,
  exportButton ,
  year ,
  startDate ,
  endDate ,
  updateDate ,
  amount ,
  amountEuro ,
  amountPercent ,
  budget ,
  model ,
  version ,
  quota ,
  models ,
  versions ,
  exceptionVersions ,
  quotas ,
  lld ,
  lcd ,
  prot ,
  avs ,
  siren ,
  siret ,
  tablesResult ,
  dealerID ,
  vin ,
  state ,
  stateBudget ,
  agent ,
  seller ,
  discount ,
  discountTTC ,
  discountWithPercent ,
  phase ,
  brand ,
  brands ,
  search ,
  options ,
  category ,
  categoryCode ,
  categoryLabel ,
  period ,
  searchTheTable ,
  nothing ,
  editDates ,
  zipCode ,
  positive ,
  negative ,
  transferListOptions ,
  transferListSelected ,
  actions ,
  loading ,
  inProgress ,
  noLabel ,
  unsavedChanges ,
  insertNumberOfCharacters ,
  nothingFound ,
  somethingFound ,
  companyName ,
  addNewRow ,
  addNewRows ,
  modifyRow ,
  city ,
  reallyDelete ,
  showDealerHistory ,
  maxLinesExport ,
  everything ,
  logourMessage ,
  comment ,
  maxCharactersAllowed ,
  territory ,
  includeClosedCars ,
  varicentMigrationInfo ,
  selection ,
  noOption ,
  previousEndDate ,
  followingStartDate ,
  dateFormat ,
  formatDDMMYYYY ,
  formatMMDDYYYY ,
  formatYYYYMMDD ,
  creationDate ,
  lastModificationDate ,
  amountHT ,
  amountHTPercentage ,
  $$default ,
  $$default as default,
  tomorrow ,
  brandVO ,
  modelVO ,
  versionVO ,
  vinVO ,
  registrationVO ,
  orderType ,
  filters ,
  clientType ,
  saleType ,
  mandatory ,
  optional ,
  dealer ,
}
/* No side effect */
