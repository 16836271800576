// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as App_Table$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../intl/Intl_Utils.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../theme/Theme_Colors.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../common/App_Typography.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as Grid_Edit_Modal_Collection_Row_Edited$DvmAdminFrontendFr from "./Grid_Edit_Modal_Collection_Row_Edited.bs.js";
import * as Grid_Edit_Modal_Collection_Row_ActionButtons$DvmAdminFrontendFr from "./Grid_Edit_Modal_Collection_Row_ActionButtons.bs.js";
import * as Grid_Edit_Modal_Collection_Row_ConfirmButtons$DvmAdminFrontendFr from "./Grid_Edit_Modal_Collection_Row_ConfirmButtons.bs.js";

var expandTextStyle = Css.css({
      backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight1,
      color: "black",
      display: "none",
      position: "absolute",
      width: "max-content"
    });

var containerStyle = Css.css(Object.assign({}, {
          height: "100%",
          position: "relative",
          top: "-20px"
        }, {
          "&:hover > div": {
            display: "initial"
          }
        }));

function makeRow(form, index, tableRow, setModal, setResult, intl, isAllEdited, whatTable, whatGrid, setActionsPending, budget, acquisitionOptions, budgetOrID, filterStartDate, filterEndDate) {
  var row = Belt_Array.get(form.input.marginRows, index);
  var isSomethingEdited = Belt_Option.mapWithDefault(Belt_Array.getBy(form.input.marginRows, (function (x) {
              return x.isEdited;
            })), false, (function (x) {
          return x.isEdited;
        }));
  return Belt_Option.mapWithDefault(row, {
              cells: /* [] */0,
              onClick: undefined,
              rowButton: undefined,
              disabled: undefined
            }, (function (row) {
                var tmp;
                switch (whatTable) {
                  case /* Model */0 :
                      tmp = {
                        hd: Belt_Option.getWithDefault(tableRow.model, ""),
                        tl: /* [] */0
                      };
                      break;
                  case /* Version */1 :
                      tmp = {
                        hd: Belt_Option.getWithDefault(tableRow.model, ""),
                        tl: {
                          hd: Belt_Option.getWithDefault(tableRow.version, ""),
                          tl: {
                            hd: React.createElement(React.Fragment, undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                      noWrap: true,
                                      style: {
                                        maxWidth: "200px"
                                      },
                                      children: Belt_Option.getWithDefault(tableRow.categoryName, "")
                                    }), React.createElement("div", {
                                      className: containerStyle
                                    }, React.createElement("div", {
                                          className: expandTextStyle
                                        }, Belt_Option.getWithDefault(tableRow.categoryName, "")))),
                            tl: /* [] */0
                          }
                        }
                      };
                      break;
                  case /* GridID */2 :
                      tmp = {
                        hd: Belt_Option.getWithDefault(tableRow.categoryID, ""),
                        tl: /* [] */0
                      };
                      break;
                  
                }
                return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, Caml_option.some(isAllEdited ? null : (
                                  row.isEdited ? React.createElement(Grid_Edit_Modal_Collection_Row_ConfirmButtons$DvmAdminFrontendFr.make, {
                                          form: form,
                                          index: index,
                                          tableRow: tableRow
                                        }) : (
                                      isSomethingEdited ? null : React.createElement(Grid_Edit_Modal_Collection_Row_ActionButtons$DvmAdminFrontendFr.make, {
                                              form: form,
                                              index: index,
                                              tableRow: tableRow,
                                              setModal: setModal,
                                              setResult: setResult,
                                              whatTable: whatTable,
                                              setActionsPending: setActionsPending,
                                              whatGrid: whatGrid,
                                              budget: budget,
                                              acquisitionOptions: acquisitionOptions,
                                              budgetOrID: budgetOrID,
                                              filterStartDate: filterStartDate,
                                              filterEndDate: filterEndDate
                                            })
                                    )
                                )), undefined, Belt_List.mapWithIndex(Belt_List.concat(tmp, row.isEdited || isAllEdited ? Grid_Edit_Modal_Collection_Row_Edited$DvmAdminFrontendFr.getList(form, index, whatTable, whatGrid, budget, acquisitionOptions, row, intl) : Belt_List.keepMap({
                                          hd: whatGrid === /* ModelVersionGrid */0 && (budget === "LCD" || budget === "PROT") ? Caml_option.some(Belt_Option.getWithDefault(tableRow.acquisitionType, "")) : undefined,
                                          tl: {
                                            hd: Caml_option.some(Intl_Utils$DvmAdminFrontendFr.formatPercent(intl, tableRow.discount)),
                                            tl: {
                                              hd: whatGrid === /* MarginGrid */1 ? Caml_option.some(Belt_Option.mapWithDefault(tableRow.margin, "", (function (param) {
                                                            return Intl_Utils$DvmAdminFrontendFr.formatPercent(intl, param);
                                                          }))) : undefined,
                                              tl: {
                                                hd: Caml_option.some(Belt_Option.mapWithDefault(tableRow.previousEndDate, "", (function (param) {
                                                            return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                          }))),
                                                tl: {
                                                  hd: Caml_option.some(Intl_Utils$DvmAdminFrontendFr.formatDate(intl, tableRow.startDate)),
                                                  tl: {
                                                    hd: Caml_option.some(Belt_Option.mapWithDefault(tableRow.endDate, "", (function (param) {
                                                                return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                              }))),
                                                    tl: {
                                                      hd: Caml_option.some(Belt_Option.mapWithDefault(tableRow.followingStartDate, "", (function (param) {
                                                                  return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                }))),
                                                      tl: {
                                                        hd: whatGrid === /* ModelVersionGrid */0 && whatTable === /* Model */0 ? Caml_option.some(Belt_Option.mapWithDefault(tableRow.hasExceptions, "", (function (x) {
                                                                      return intl.formatMessage(x ? Messages_Common$DvmAdminFrontendFr.yes : Messages_Common$DvmAdminFrontendFr.no);
                                                                    }))) : undefined,
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }, (function (x) {
                                            return x;
                                          }))), (function (param, content) {
                                  return App_Table$DvmAdminFrontendFr.makeCell(undefined, undefined, undefined, content);
                                })));
              }));
}

var Form;

var Types;

var ActionButtons;

var ConfirmButtons;

var EditedRow;

export {
  Form ,
  Types ,
  ActionButtons ,
  ConfirmButtons ,
  EditedRow ,
  expandTextStyle ,
  containerStyle ,
  makeRow ,
}
/* expandTextStyle Not a pure module */
