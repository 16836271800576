// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as Messages_MarginGrid$DvmAdminFrontendFr from "../../../../intl/messages/marginGrid/Messages_MarginGrid.bs.js";
import * as Grid_Edit_Actions_Api$DvmAdminFrontendFr from "./Grid_Edit_Actions_Api.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../common/components/input/App_DateInputFormField.bs.js";
import * as Grid_Edit_Actions_Utils$DvmAdminFrontendFr from "./Grid_Edit_Actions_Utils.bs.js";

var validators = {
  date: {
    strategy: /* OnFirstChange */1,
    validate: (function (param, param$1) {
        var date = param.date;
        if (param$1.whatDate === "endDate") {
          return Grid_Edit_Actions_Utils$DvmAdminFrontendFr.validateEditEndDate(date, param$1.highestStartDate, param$1.lowestFollowingStartDate);
        } else {
          return Grid_Edit_Actions_Utils$DvmAdminFrontendFr.validateEditStartDate(date, param$1.lowestEndDate, param$1.highestPreviousEndDate);
        }
      })
  }
};

function initialFieldsStatuses(_input) {
  return {
          date: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            date: /* Pristine */0
          },
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match = fieldsStatuses.date;
  var match$1 = match ? match._0 : Curry._2(validators.date.validate, input, metadata);
  if (match$1.TAG === /* Ok */0) {
    return {
            TAG: /* Valid */0,
            output: {
              date: match$1._0
            },
            fieldsStatuses: {
              date: /* Dirty */{
                _0: match$1,
                _1: /* Shown */0
              }
            },
            collectionsStatuses: undefined
          };
  } else {
    return {
            TAG: /* Invalid */1,
            fieldsStatuses: {
              date: /* Dirty */{
                _0: match$1,
                _1: /* Shown */0
              }
            },
            collectionsStatuses: undefined
          };
  }
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurDateField */0 :
                  var result = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.date, validators.date, (function (status) {
                          return {
                                  date: status
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */1 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */1,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */2,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */4);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */3);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */2 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */3 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */4 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateDateField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput, metadata, state.fieldsStatuses.date, state.submissionStatus, validators.date, (function (status) {
                                    return {
                                            date: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */1 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                date: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                date: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */2 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */3 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Caml_option.some(Curry._1(map, Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDateField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurDate: (function (param) {
              Curry._1(dispatch, /* BlurDateField */0);
            }),
          dateResult: Formality.exposeFieldResult(state.fieldsStatuses.date),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.date) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */1);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */2);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */3);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */3,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */4);
            })
        };
}

var EditFinalDateForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialInput = {
  date: undefined
};

function highestStartDate(tableResult) {
  if (tableResult !== undefined) {
    return Belt_List.get(Belt_List.sort(Belt_List.map(tableResult, (function (x) {
                          return x.startDate;
                        })), ReDate.compareDesc), 0);
  }
  
}

function lowestFollowingStartDate(tableResult) {
  if (tableResult !== undefined) {
    return Belt_List.get(Belt_List.sort(Belt_List.keepMap(Belt_List.map(tableResult, (function (x) {
                              return x.followingStartDate;
                            })), (function (x) {
                          return x;
                        })), ReDate.compareAsc), 0);
  }
  
}

function lowestEndDate(tableResult) {
  if (tableResult !== undefined) {
    return Belt_List.get(Belt_List.sort(Belt_List.keepMap(Belt_List.map(tableResult, (function (x) {
                              return x.endDate;
                            })), (function (x) {
                          return x;
                        })), ReDate.compareAsc), 0);
  }
  
}

function highestPreviousEndDate(tableResult) {
  if (tableResult !== undefined) {
    return Belt_List.get(Belt_List.sort(Belt_List.keepMap(Belt_List.map(tableResult, (function (x) {
                              return x.previousEndDate;
                            })), (function (x) {
                          return x;
                        })), ReDate.compareDesc), 0);
  }
  
}

function Grid_Edit_EditDate(Props) {
  var setActionsPending = Props.setActionsPending;
  var tableResult = Props.tableResult;
  var whatTable = Props.whatTable;
  var whatGrid = Props.whatGrid;
  var budget = Props.budget;
  var whatDate = Props.whatDate;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match[1];
  var result = match[0];
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  var form = useForm(initialInput, {
        whatDate: whatDate,
        highestStartDate: highestStartDate(tableResult),
        lowestFollowingStartDate: lowestFollowingStartDate(tableResult),
        lowestEndDate: lowestEndDate(tableResult),
        highestPreviousEndDate: highestPreviousEndDate(tableResult)
      }, (function (param, submissionCallbacks) {
          var date = param.date;
          if (tableResult !== undefined) {
            if (whatDate === "endDate") {
              Grid_Edit_Actions_Api$DvmAdminFrontendFr.editOrDeleteMarginRows(userManager, setResult, whatTable, whatGrid, "PUT", budget, selectedRole, Belt_List.map(tableResult, (function (x) {
                          return {
                                  budget: x.budget,
                                  model: x.model,
                                  version: x.version,
                                  avsMarginGridID: x.avsMarginGridID,
                                  categoryID: x.categoryID,
                                  discount: x.discount,
                                  margin: x.margin,
                                  previousEndDate: x.previousEndDate,
                                  startDate: x.startDate,
                                  endDate: date,
                                  hasExceptions: x.hasExceptions,
                                  followingStartDate: x.followingStartDate,
                                  acquisitionType: x.acquisitionType,
                                  contractID: x.contractID,
                                  categoryName: x.categoryName
                                };
                        })));
            } else {
              Belt_Option.mapWithDefault(date, undefined, (function (date) {
                      Grid_Edit_Actions_Api$DvmAdminFrontendFr.replaceStartDate(userManager, setResult, whatTable, whatGrid, budget, selectedRole, tableResult, Belt_List.map(tableResult, (function (x) {
                                  return {
                                          budget: x.budget,
                                          model: x.model,
                                          version: x.version,
                                          avsMarginGridID: x.avsMarginGridID,
                                          categoryID: x.categoryID,
                                          discount: x.discount,
                                          margin: x.margin,
                                          previousEndDate: x.previousEndDate,
                                          startDate: date,
                                          endDate: x.endDate,
                                          hasExceptions: x.hasExceptions,
                                          followingStartDate: x.followingStartDate,
                                          acquisitionType: x.acquisitionType,
                                          contractID: x.contractID,
                                          categoryName: x.categoryName
                                        };
                                })));
                    }));
            }
          }
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
        }));
  React.useEffect((function () {
          if (typeof result === "number") {
            if (result !== /* NotStarted */0) {
              Curry._1(setActionsPending, (function (param) {
                      return true;
                    }));
            }
            
          } else if (result.TAG === /* Ok */0) {
            Curry._1(setActionsPending, (function (param) {
                    return false;
                  }));
          } else {
            Curry._1(setActionsPending, (function (param) {
                    return false;
                  }));
          }
        }), [result]);
  var tmp = {
    label: whatDate === "endDate" ? Messages_Common$DvmAdminFrontendFr.endDate : Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.date,
    onChange: Curry._1(form.updateDate, (function (param, date) {
            return {
                    date: date
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.dateResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.dateResult);
  }
  var tmp$1;
  tmp$1 = typeof result === "number" ? null : (
      result.TAG === /* Ok */0 ? React.createElement(Core.Grid, {
              children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                    size: /* XLarge */4,
                    type_: /* Tick */1
                  }),
              item: true,
              style: {
                color: "green"
              }
            }) : React.createElement(Core.Grid, {
              children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                    size: /* XLarge */4,
                    type_: /* Cross */42
                  }),
              item: true,
              style: {
                color: "red"
              }
            })
    );
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                            gutterBottom: true,
                            style: {
                              fontWeight: "600"
                            },
                            children: intl.formatMessage(whatDate === "endDate" ? Messages_MarginGrid$DvmAdminFrontendFr.finalDate : Messages_MarginGrid$DvmAdminFrontendFr.startingDate),
                            variant: "bodyBig"
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement("form", {
                            onSubmit: (function ($$event) {
                                $$event.preventDefault();
                                Curry._1(form.submit, undefined);
                              })
                          }, React.createElement(Core.Grid, {
                                alignItems: "flex-start",
                                children: null,
                                container: true,
                                spacing: 3
                              }, React.createElement(Core.Grid, {
                                    children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp),
                                    item: true
                                  }), React.createElement(Core.Grid, {
                                    children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                          label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.apply),
                                          color: "secondary",
                                          size: "medium",
                                          type_: "submit"
                                        }),
                                    item: true
                                  }), tmp$1)),
                      item: true
                    })));
}

var Api;

var Types;

var Utils;

var InputStyles;

var make = Grid_Edit_EditDate;

export {
  Api ,
  Types ,
  Utils ,
  InputStyles ,
  EditFinalDateForm ,
  initialInput ,
  highestStartDate ,
  lowestFollowingStartDate ,
  lowestEndDate ,
  highestPreviousEndDate ,
  make ,
}
/* react Not a pure module */
