// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../common/App_Typography.bs.js";

function Order_Detail_Bonus_DealerRequest_Comment(Props) {
  var dealerRequests = Props.dealerRequests;
  var actionId = Props.actionId;
  var filteredRequest;
  filteredRequest = typeof dealerRequests === "number" || dealerRequests.TAG !== /* Ok */0 ? undefined : Belt_List.getBy(dealerRequests._0, (function (x) {
            return x.actionId === Belt_Option.getWithDefault(actionId, "");
          }));
  return Belt_Option.mapWithDefault(filteredRequest, null, (function (x) {
                return React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                            children: Belt_Option.getWithDefault(x.comment, ""),
                            variant: "caption"
                          });
              }));
}

var make = Order_Detail_Bonus_DealerRequest_Comment;

export {
  make ,
}
/* react Not a pure module */
