// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../../common/components/App_OverlaySpinner.bs.js";
import * as App_Clickable_Element$DvmAdminFrontendFr from "../../../../../../common/components/button/App_Clickable_Element.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Documents_Api$DvmAdminFrontendFr from "./Order_Detail_Bonus_DealerRequest_Documents_Api.bs.js";

function Order_Detail_Bonus_DealerRequest_Documents_LinkRow(Props) {
  var item = Props.item;
  var documentTypes = Props.documentTypes;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDownloadResult = match$2[1];
  return React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
              show: App_Types_Result$DvmAdminFrontendFr.isPending(match$2[0]),
              children: React.createElement(App_Clickable_Element$DvmAdminFrontendFr.make, {
                    onClick: (function (param) {
                        Order_Detail_Bonus_DealerRequest_Documents_Api$DvmAdminFrontendFr.getPdf(userManager, setDownloadResult, item, alert, selectedRole);
                      }),
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          direction: "column",
                          item: true,
                          spacing: 2
                        }, React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              item: true,
                              spacing: 1,
                              wrap: "nowrap"
                            }, React.createElement(Core.Grid, {
                                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                        children: Belt_Option.getWithDefault(item.fileName, ""),
                                        variant: "h5"
                                      }),
                                  item: true,
                                  xs: Grid$Mui.Xs[6]
                                }), React.createElement(Core.Grid, {
                                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                        children: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(documentTypes, "-", (function (docTypes) {
                                                return Belt_Option.mapWithDefault(Belt_List.getBy(docTypes, (function (x) {
                                                                  return x.fileType === Belt_Option.getWithDefault(item.fileType, "-");
                                                                })), "-", (function (x) {
                                                              return x.fileLabel;
                                                            }));
                                              })),
                                        variant: "h5"
                                      }),
                                  item: true,
                                  xs: Grid$Mui.Xs[6]
                                })), React.createElement(Core.Grid, {
                              children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                    children: Belt_Option.mapWithDefault(item.comment, "", (function (x) {
                                            return intl.formatMessage(Messages_Common$DvmAdminFrontendFr.comment) + ": " + x;
                                          })),
                                    variant: "h5"
                                  }),
                              item: true
                            }))
                  })
            });
}

var Api;

var Types;

var make = Order_Detail_Bonus_DealerRequest_Documents_LinkRow;

export {
  Api ,
  Types ,
  make ,
}
/* react Not a pure module */
