// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Styles$DvmAdminFrontendFr from "../../../../common/App_Styles.bs.js";
import * as App_Spinner$DvmAdminFrontendFr from "../../../../common/components/App_Spinner.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../../common/components/App_LabeledText.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as Order_Detail_Api$DvmAdminFrontendFr from "../Order_Detail_Api.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../../context/user/User_Types_Utils.bs.js";
import * as Order_Detail_Utils$DvmAdminFrontendFr from "../Order_Detail_Utils.bs.js";
import * as Order_Detail_Router$DvmAdminFrontendFr from "../Order_Detail_Router.bs.js";
import * as Order_Detail_Styles$DvmAdminFrontendFr from "../Order_Detail_Styles.bs.js";
import * as Order_Detail_Correct_Api$DvmAdminFrontendFr from "./Order_Detail_Correct_Api.bs.js";
import * as Order_Detail_Correct_New$DvmAdminFrontendFr from "./Order_Detail_Correct_New.bs.js";
import * as Order_Detail_Correct_Form$DvmAdminFrontendFr from "./Order_Detail_Correct_Form.bs.js";
import * as Order_Detail_Correct_Save$DvmAdminFrontendFr from "./Order_Detail_Correct_Save.bs.js";
import * as Order_Detail_Layout_Setup$DvmAdminFrontendFr from "../Order_Detail_Layout_Setup.bs.js";
import * as Messages_Orders_Correction$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Correction.bs.js";
import * as Order_Detail_Correct_Utils$DvmAdminFrontendFr from "./Order_Detail_Correct_Utils.bs.js";
import * as Order_Detail_Correct_History$DvmAdminFrontendFr from "./Order_Detail_Correct_History.bs.js";
import * as Order_Detail_Correct_Form_Perfs$DvmAdminFrontendFr from "./Order_Detail_Correct_Form_Perfs.bs.js";
import * as Order_Detail_Correct_Form_Vehicle$DvmAdminFrontendFr from "./Order_Detail_Correct_Form_Vehicle.bs.js";
import * as Order_Detail_Correct_Form_Additional$DvmAdminFrontendFr from "./Order_Detail_Correct_Form_Additional.bs.js";

function Order_Detail_Correct_Page(Props) {
  var orderId = Props.orderId;
  var action = Props.action;
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var alert = ReactAlert.useAlert();
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$2[1];
  var result = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setOrder = match$3[1];
  var order = match$3[0];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setCorrectionsResult = match$4[1];
  var correctionsResult = match$4[0];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setOrderTypeResult = match$5[1];
  var orderTypeResult = match$5[0];
  var match$6 = React.useState(function () {
        
      });
  var setFormOutput = match$6[1];
  var formOutput = match$6[0];
  var match$7 = React.useState(function () {
        
      });
  var setFormPerfOutput = match$7[1];
  var formPerfOutput = match$7[0];
  var match$8 = React.useState(function () {
        
      });
  var setFormVehicleOutput = match$8[1];
  var formVehicleOutput = match$8[0];
  var match$9 = React.useState(function () {
        
      });
  var setFormAdditionalOutput = match$9[1];
  var formAdditionalOutput = match$9[0];
  var match$10 = React.useState(function () {
        
      });
  var setFormSubmissionCallbacks = match$10[1];
  var formSubmissionCallbacks = match$10[0];
  var match$11 = React.useState(function () {
        
      });
  var setFormPerfSubmissionCallbacks = match$11[1];
  var formPerfSubmissionCallbacks = match$11[0];
  var match$12 = React.useState(function () {
        
      });
  var setFormVehicleSubmissionCallbacks = match$12[1];
  var formVehicleSubmissionCallbacks = match$12[0];
  var match$13 = React.useState(function () {
        
      });
  var setFormAdditionalSubmissionCallbacks = match$13[1];
  var formAdditionalSubmissionCallbacks = match$13[0];
  var newestUndeployedCorrection = Belt_List.get(Belt_List.sort(Belt_List.keep(App_Types_Result$DvmAdminFrontendFr.mapWithDefault(correctionsResult, /* [] */0, (function (x) {
                      return x.content;
                    })), (function (x) {
                  if (Belt_Option.getWithDefault(x.editor, "") !== "INITIAL") {
                    return Belt_Option.mapWithDefault(x.timeStamp, 0.0, (function (prim) {
                                  return prim.valueOf();
                                })) > new Date(Date.now()).setHours(0.0, 0.0, 0.0, 0.0);
                  } else {
                    return false;
                  }
                })), (function (a, b) {
              return ReDate.compareDesc(Belt_Option.getWithDefault(a.timeStamp, new Date(0.0)), Belt_Option.getWithDefault(b.timeStamp, new Date(0.0)));
            })), 0);
  var form = Order_Detail_Correct_Form$DvmAdminFrontendFr.CorrectionForm.useForm(App_Types_Result$DvmAdminFrontendFr.mapWithDefault(order, Order_Detail_Correct_Form$DvmAdminFrontendFr.initialState, (function (order) {
              return Order_Detail_Correct_Utils$DvmAdminFrontendFr.fillForm(Order_Detail_Utils$DvmAdminFrontendFr.makeOrder(order), newestUndeployedCorrection);
            })), (function (output, submissionCallbacks) {
          Curry._1(setFormOutput, (function (param) {
                  return output;
                }));
          Curry._1(setFormSubmissionCallbacks, (function (param) {
                  return submissionCallbacks;
                }));
        }));
  var formPerfs = Order_Detail_Correct_Form_Perfs$DvmAdminFrontendFr.CorrectionForm.useForm(App_Types_Result$DvmAdminFrontendFr.mapWithDefault(order, Order_Detail_Correct_Form_Perfs$DvmAdminFrontendFr.initialState, (function (order) {
              return Order_Detail_Correct_Utils$DvmAdminFrontendFr.fillFormPerf(Order_Detail_Utils$DvmAdminFrontendFr.makeOrder(order), newestUndeployedCorrection);
            })), (function (output, submissionCallbacks) {
          Curry._1(setFormPerfOutput, (function (param) {
                  return output;
                }));
          Curry._1(setFormPerfSubmissionCallbacks, (function (param) {
                  return submissionCallbacks;
                }));
        }));
  var formVehicle = Order_Detail_Correct_Form_Vehicle$DvmAdminFrontendFr.CorrectionVehicleForm.useForm(App_Types_Result$DvmAdminFrontendFr.mapWithDefault(order, Order_Detail_Correct_Form_Vehicle$DvmAdminFrontendFr.initialState, (function (order) {
              return Order_Detail_Correct_Utils$DvmAdminFrontendFr.fillFormVehicle(Order_Detail_Utils$DvmAdminFrontendFr.makeOrder(order), newestUndeployedCorrection);
            })), (function (output, submissionCallbacks) {
          Curry._1(setFormVehicleOutput, (function (param) {
                  return output;
                }));
          Curry._1(setFormVehicleSubmissionCallbacks, (function (param) {
                  return submissionCallbacks;
                }));
        }));
  var formAdditional = Order_Detail_Correct_Form_Additional$DvmAdminFrontendFr.CorrectionForm.useForm(App_Types_Result$DvmAdminFrontendFr.mapWithDefault(order, Order_Detail_Correct_Form_Additional$DvmAdminFrontendFr.initialState, (function (order) {
              return Order_Detail_Correct_Utils$DvmAdminFrontendFr.fillFormAdditional(Order_Detail_Utils$DvmAdminFrontendFr.makeOrder(order), newestUndeployedCorrection);
            })), (function (output, submissionCallbacks) {
          Curry._1(setFormAdditionalOutput, (function (param) {
                  return output;
                }));
          Curry._1(setFormAdditionalSubmissionCallbacks, (function (param) {
                  return submissionCallbacks;
                }));
        }));
  React.useEffect((function () {
          if (typeof order !== "number" && order.TAG === /* Ok */0) {
            Curry._1(form.reset, undefined);
            Curry._1(formPerfs.reset, undefined);
            Curry._1(formVehicle.reset, undefined);
            Curry._1(formAdditional.reset, undefined);
          }
          
        }), [order]);
  React.useEffect((function () {
          if (typeof orderTypeResult === "number") {
            orderTypeResult === /* NotStarted */0;
          } else if (orderTypeResult.TAG === /* Ok */0) {
            Order_Detail_Api$DvmAdminFrontendFr.getOrder(userManager, setOrder, orderId, orderTypeResult._0.response, selectedRole);
            Order_Detail_Correct_Api$DvmAdminFrontendFr.getAllCorrections(alert, userManager, setCorrectionsResult, orderId, selectedRole);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: orderTypeResult._0
                      }
                    }));
          }
        }), [orderTypeResult]);
  React.useEffect((function () {
          if (User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) || selectedRole === /* Advisor */0) {
            Order_Detail_Api$DvmAdminFrontendFr.getOrderType(userManager, setOrderTypeResult, orderId, selectedRole);
          }
          
        }), []);
  React.useEffect((function () {
          Order_Detail_Correct_Save$DvmAdminFrontendFr.saveCorrection(formOutput, formPerfOutput, formVehicleOutput, formAdditionalOutput, formSubmissionCallbacks, formPerfSubmissionCallbacks, formVehicleSubmissionCallbacks, formAdditionalSubmissionCallbacks, userManager, setResult, selectedRole, orderId, order);
        }), [formAdditionalSubmissionCallbacks]);
  React.useEffect((function () {
          if (typeof result === "number") {
            result === /* NotStarted */0;
          } else if (result.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* Message */0,
                        _0: Messages_Orders_Correction$DvmAdminFrontendFr.successMessage
                      }
                    }));
            Order_Detail_Router$DvmAdminFrontendFr.routeToPage(orderId, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: result._0
                      }
                    }));
            Order_Detail_Router$DvmAdminFrontendFr.routeToPage(orderId, undefined);
          }
        }), [result]);
  var submitForm = function (param) {
    Belt_Option.mapWithDefault(formSubmissionCallbacks, undefined, (function (x) {
            Curry._1(x.notifyOnSuccess, undefined);
          }));
    Belt_Option.mapWithDefault(formPerfSubmissionCallbacks, undefined, (function (x) {
            Curry._1(x.notifyOnSuccess, undefined);
          }));
    Belt_Option.mapWithDefault(formVehicleSubmissionCallbacks, undefined, (function (x) {
            Curry._1(x.notifyOnSuccess, undefined);
          }));
    Belt_Option.mapWithDefault(formAdditionalSubmissionCallbacks, undefined, (function (x) {
            Curry._1(x.notifyOnSuccess, undefined);
          }));
    Curry._1(form.submit, undefined);
    Curry._1(formPerfs.submit, undefined);
    Curry._1(formVehicle.submit, undefined);
    Curry._1(formAdditional.submit, undefined);
  };
  if (!(User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) || selectedRole === /* Advisor */0)) {
    return null;
  }
  if (typeof order === "number") {
    if (order === /* NotStarted */0) {
      return null;
    } else {
      return React.createElement(App_Spinner$DvmAdminFrontendFr.make, {});
    }
  }
  if (order.TAG === /* Ok */0) {
    var order$1 = Order_Detail_Utils$DvmAdminFrontendFr.makeOrder(order._0);
    var tmp;
    var exit = 0;
    if (action === "correct") {
      if (typeof correctionsResult === "number" || correctionsResult.TAG !== /* Ok */0) {
        exit = 1;
      } else {
        tmp = React.createElement(Order_Detail_Correct_New$DvmAdminFrontendFr.make, {
              order: order$1,
              form: form,
              formPerfs: formPerfs,
              formVehicle: formVehicle,
              formAdditional: formAdditional,
              newestUndeployedCorrection: newestUndeployedCorrection,
              submitForm: submitForm,
              result: result
            });
      }
    } else if (typeof correctionsResult === "number" || correctionsResult.TAG !== /* Ok */0) {
      exit = 1;
    } else {
      tmp = React.createElement(Order_Detail_Correct_History$DvmAdminFrontendFr.make, {
            corrections: correctionsResult._0.content,
            order: order$1,
            form: form
          });
    }
    if (exit === 1) {
      if (typeof correctionsResult === "number") {
        tmp = correctionsResult !== 0 ? React.createElement(App_Spinner$DvmAdminFrontendFr.make, {}) : null;
      } else {
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: correctionsResult._0
                  }
                }));
        tmp = null;
      }
    }
    return React.createElement(React.Fragment, undefined, React.createElement(Core.AppBar, {
                    children: React.createElement(Core.Grid, {
                          children: Belt_Array.mapWithIndex(Order_Detail_Layout_Setup$DvmAdminFrontendFr.headerArray(order$1, selectedRole), (function (index, element) {
                                  return React.createElement(Core.Grid, {
                                              children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                    label: element.label,
                                                    children: element.val
                                                  }),
                                              item: true,
                                              key: String(index)
                                            });
                                })),
                          className: Order_Detail_Styles$DvmAdminFrontendFr.titleBox,
                          container: true,
                          justifyContent: "space-evenly",
                          spacing: 1
                        }),
                    className: App_Styles$DvmAdminFrontendFr.stickyBar
                  }), tmp);
  }
  Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
            message: {
              TAG: /* RestError */2,
              _0: order._0
            }
          }));
  return null;
}

var Setup;

var Api;

var Form;

var FormPerfs;

var FormVehicle;

var FormAdditional;

var UtilsCorrect;

var Types;

var make = Order_Detail_Correct_Page;

export {
  Setup ,
  Api ,
  Form ,
  FormPerfs ,
  FormVehicle ,
  FormAdditional ,
  UtilsCorrect ,
  Types ,
  make ,
}
/* react Not a pure module */
