// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as App_TextArea$DvmAdminFrontendFr from "../../../common/components/input/App_TextArea.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as App_TextInput$DvmAdminFrontendFr from "../../../common/components/input/App_TextInput.bs.js";
import * as App_UserTypeCheckBox$DvmAdminFrontendFr from "../../../common/components/input/App_UserTypeCheckBox.bs.js";
import * as Messages_Home_Message$DvmAdminFrontendFr from "../../../intl/messages/home/Messages_Home_Message.bs.js";

var gray = Css.css({
      color: Theme_Colors$DvmAdminFrontendFr.grayMain
    });

var padding = Css.css({
      padding: "7px 0px"
    });

function Home_Messages_Modal_Input(Props) {
  var data = Props.data;
  var setTitle = Props.setTitle;
  var setMessage = Props.setMessage;
  var setGroup = Props.setGroup;
  var intl = ReactIntl.useIntl();
  return React.createElement("div", undefined, React.createElement("div", {
                  className: Css.cx([
                        gray,
                        padding
                      ])
                }, intl.formatMessage(Messages_Home_Message$DvmAdminFrontendFr.messagesModalObject)), React.createElement("div", undefined, React.createElement(App_TextInput$DvmAdminFrontendFr.make, {
                      onChange: (function ($$event) {
                          Curry._1(setTitle, $$event.currentTarget.value);
                        }),
                      value: data.title,
                      placeholder: "-"
                    })), React.createElement("div", {
                  className: Css.cx([
                        gray,
                        padding
                      ])
                }, intl.formatMessage(Messages_Home_Message$DvmAdminFrontendFr.messagesModalMessage)), React.createElement("div", undefined, React.createElement(App_TextArea$DvmAdminFrontendFr.make, {
                      onChange: (function ($$event) {
                          Curry._1(setMessage, $$event.currentTarget.value);
                        }),
                      rows: 10,
                      value: data.message
                    })), React.createElement("div", {
                  className: padding
                }, intl.formatMessage(Messages_Home_Message$DvmAdminFrontendFr.messagesModalGroup)), React.createElement(Core.Box, {
                  children: null,
                  display: "flex",
                  justifyContent: "space-around"
                }, React.createElement(App_UserTypeCheckBox$DvmAdminFrontendFr.make, {
                      kind: /* Button */0,
                      label: intl.formatMessage(Messages_Home_Message$DvmAdminFrontendFr.messagesGroupRenaultSas),
                      checked: data.group.renaultSas,
                      onClick: (function (param) {
                          var init = data.group;
                          Curry._1(setGroup, {
                                renaultSas: !data.group.renaultSas,
                                network: init.network
                              });
                        })
                    }), React.createElement(App_UserTypeCheckBox$DvmAdminFrontendFr.make, {
                      kind: /* Button */0,
                      label: intl.formatMessage(Messages_Home_Message$DvmAdminFrontendFr.messagesGroupNetwork),
                      checked: data.group.network,
                      onClick: (function (param) {
                          var init = data.group;
                          Curry._1(setGroup, {
                                renaultSas: init.renaultSas,
                                network: !data.group.network
                              });
                        })
                    })));
}

var make = Home_Messages_Modal_Input;

export {
  gray ,
  padding ,
  make ,
}
/* gray Not a pure module */
