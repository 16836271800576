// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../api/RestApi.bs.js";
import * as Messages_PCDAgreement$DvmAdminFrontendFr from "../../../../intl/messages/pcdAgreement/Messages_PCDAgreement.bs.js";
import * as Action_Referential_Types$DvmAdminFrontendFr from "../search/Action_Referential_Types.bs.js";

function deleteRow(userManager, setResult, row, intl, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", {
          TAG: /* Json */0,
          _0: Action_Referential_Types$DvmAdminFrontendFr.ReferentialRows.referentialRow_encode(row)
        }, userManager, selectedRole, "/actions/referential").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: intl.formatMessage(Messages_PCDAgreement$DvmAdminFrontendFr.eraseSuccess)
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function createModifyRow(userManager, setResult, newRow, intl, submissionCallbacks, selectedRole, oldRow) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call(Belt_Option.isSome(oldRow) ? "PUT" : "POST", {
          TAG: /* Json */0,
          _0: Belt_Option.isSome(oldRow) ? Action_Referential_Types$DvmAdminFrontendFr.ReferentialRows.referentialRow_encode(newRow) : Action_Referential_Types$DvmAdminFrontendFr.ReferentialRows.referentialList_encode({
                  hd: newRow,
                  tl: /* [] */0
                })
        }, userManager, selectedRole, "/actions/referential").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: intl.formatMessage(Messages_PCDAgreement$DvmAdminFrontendFr.modifySuccess)
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToUpdateActionReferential */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

export {
  Form ,
  deleteRow ,
  createModifyRow ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
