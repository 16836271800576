// Generated by ReScript, PLEASE EDIT WITH CARE


var title = {
  id: "04fb8be0-5085-4029-b453-d1667f03aa2a",
  defaultMessage: "Rechercher une affaire"
};

var dealerName = {
  id: "5d739f6a-b1b4-4e43-bda6-11bf36d012cc",
  defaultMessage: "Raison sociale"
};

var entityLabel = {
  id: "4b54d096-189d-49ee-a891-8d0f40a59304",
  defaultMessage: "Libellé entité"
};

var ipnKeyAccountManager = {
  id: "205d0c03-308f-4f71-8809-2a68be191a5d",
  defaultMessage: "IPN ME"
};

var keyAccountManagerName = {
  id: "a99d7fd5-562b-4b3b-be81-a9a265a95369",
  defaultMessage: "Nom ME"
};

var localityName = {
  id: "45ef82a4-44cd-444c-9939-fdc12f42eecc",
  defaultMessage: "Localité"
};

var resultInfo = {
  id: "11f21014-e7f2-4761-8972-ec14f8339d79",
  defaultMessage: "affaires"
};

export {
  title ,
  dealerName ,
  entityLabel ,
  ipnKeyAccountManager ,
  keyAccountManagerName ,
  localityName ,
  resultInfo ,
}
/* No side effect */
