// Generated by ReScript, PLEASE EDIT WITH CARE


var orderID = {
  id: "0fca8bae-d83a-4093-9570-6dff83886a4c",
  defaultMessage: "N° commande"
};

var orderIDShort = {
  id: "f679a878-d0d7-48b3-8236-5919871f541c",
  defaultMessage: "N° cde"
};

var flashType = {
  id: "5f8a7200-2eb4-4a5a-bcb0-8f881cde0526",
  defaultMessage: "Type Aide"
};

var flashID = {
  id: "43d7600e-b782-4a0b-a435-2a6982c43ae5",
  defaultMessage: "Code Action"
};

var flashLabel = {
  id: "435af488-474d-4137-a46f-484cf7841ff5",
  defaultMessage: "Libellé AC"
};

var stateHeader = {
  id: "e5713b03-51e6-4a1d-ac8a-0bb74ac08e3b",
  defaultMessage: "État AC"
};

var resultInfo = {
  id: "4668b58a-bbb8-496e-a2b0-d7e659d94c0e",
  defaultMessage: "commandes"
};

var clientSiret = {
  id: "c0df6040-6a77-450d-ad8d-1414b2e11aca",
  defaultMessage: "SIRET du client"
};

var showBonusesPlusHistory = {
  id: "488f8fe3-14d5-4bc0-b527-06417baf8bf7",
  defaultMessage: "Afficher l'historique"
};

var searchForBonuses = {
  id: "1949732f-e7d1-40a8-946f-09cdb864ea3d",
  defaultMessage: "Indiquer vos éléments de recherche pour afficher les aides souhaitées"
};

var preinvoicedCheckbox = {
  id: "a42590c3-133e-47e6-830e-99a7e4655ced",
  defaultMessage: "Préfacturées"
};

var parcActiveCheckbox = {
  id: "2ff51c5f-ef65-4242-9181-d8232e6420a9",
  defaultMessage: "Parc actif"
};

export {
  orderID ,
  orderIDShort ,
  flashType ,
  flashID ,
  flashLabel ,
  stateHeader ,
  resultInfo ,
  clientSiret ,
  showBonusesPlusHistory ,
  searchForBonuses ,
  preinvoicedCheckbox ,
  parcActiveCheckbox ,
}
/* No side effect */
