// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as App_Table$DvmAdminFrontendFr from "../../../common/components/table/App_Table.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../intl/Intl_Utils.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Pagination.bs.js";
import * as App_Table_Export_Menu$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Export_Menu.bs.js";
import * as App_Table_Create_Button$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Create_Button.bs.js";
import * as Messages_FinancialCodes$DvmAdminFrontendFr from "../../../intl/messages/easypack/Messages_FinancialCodes.bs.js";
import * as FinancialCode_Delete_Modal$DvmAdminFrontendFr from "../modal/FinancialCode_Delete_Modal.bs.js";
import * as FinancialCode_Search_Types$DvmAdminFrontendFr from "./FinancialCode_Search_Types.bs.js";
import * as FinancialCode_Update_Modal$DvmAdminFrontendFr from "../modal/FinancialCode_Update_Modal.bs.js";

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(FinancialCode_Search_Types$DvmAdminFrontendFr.Fields.orderType, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.orderType);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(FinancialCode_Search_Types$DvmAdminFrontendFr.Fields.codeId, undefined, undefined, undefined, undefined, undefined, Messages_FinancialCodes$DvmAdminFrontendFr.codeId),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(FinancialCode_Search_Types$DvmAdminFrontendFr.Fields.codeLabel, undefined, undefined, undefined, undefined, undefined, Messages_FinancialCodes$DvmAdminFrontendFr.codeLabel),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(FinancialCode_Search_Types$DvmAdminFrontendFr.Fields.creationDate, undefined, undefined, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.creationDate),
      tl: {
        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(FinancialCode_Search_Types$DvmAdminFrontendFr.Fields.startDate, undefined, undefined, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.startDate),
        tl: {
          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(FinancialCode_Search_Types$DvmAdminFrontendFr.Fields.endDate, undefined, undefined, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.endDate),
          tl: /* [] */0
        }
      }
    }
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function FinancialCode_Search_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var selectedRole = match[0].selectedRole;
  var match$1 = React.useState(function () {
        return null;
      });
  var setModal = match$1[1];
  var onCloseModal = function (param) {
    Curry._1(setModal, (function (param) {
            return null;
          }));
    Curry._2(fetchData, undefined, fetchRequest);
  };
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (row) {
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, Caml_option.some(React.createElement(Core.Grid, {
                                                      children: null,
                                                      container: true,
                                                      justifyContent: "flex-start",
                                                      wrap: "nowrap"
                                                    }, React.createElement(Core.Grid, {
                                                          children: React.createElement(Core.Button, {
                                                                onClick: (function (param) {
                                                                    Curry._1(setModal, (function (param) {
                                                                            return React.createElement(FinancialCode_Update_Modal$DvmAdminFrontendFr.make, {
                                                                                        onClose: (function (param) {
                                                                                            onCloseModal(undefined);
                                                                                          }),
                                                                                        row: row,
                                                                                        onOk: (function (param) {
                                                                                            onCloseModal(undefined);
                                                                                          })
                                                                                      });
                                                                          }));
                                                                  }),
                                                                children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                      size: /* Large */3,
                                                                      type_: /* Edit */60
                                                                    }),
                                                                key: "edit"
                                                              }),
                                                          item: true
                                                        }), React.createElement(Core.Grid, {
                                                          children: React.createElement(Core.Button, {
                                                                onClick: (function (param) {
                                                                    Curry._1(setModal, (function (param) {
                                                                            return React.createElement(FinancialCode_Delete_Modal$DvmAdminFrontendFr.make, {
                                                                                        onClose: (function (param) {
                                                                                            onCloseModal(undefined);
                                                                                          }),
                                                                                        codeId: row.codeId,
                                                                                        onOk: (function (param) {
                                                                                            onCloseModal(undefined);
                                                                                          })
                                                                                      });
                                                                          }));
                                                                  }),
                                                                children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                      size: /* Large */3,
                                                                      type_: /* Trash */38,
                                                                      color: "red"
                                                                    }),
                                                                key: "delete"
                                                              }),
                                                          item: true
                                                        }))), undefined, Belt_List.mapWithIndex({
                                                  hd: Belt_Option.getWithDefault(row.orderType, ""),
                                                  tl: {
                                                    hd: row.codeId,
                                                    tl: {
                                                      hd: Belt_Option.getWithDefault(row.codeLabel, ""),
                                                      tl: {
                                                        hd: Belt_Option.mapWithDefault(row.creationDate, "", (function (param) {
                                                                return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                              })),
                                                        tl: {
                                                          hd: Belt_Option.mapWithDefault(row.startDate, "", (function (param) {
                                                                  return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                })),
                                                          tl: {
                                                            hd: Belt_Option.mapWithDefault(row.endDate, "", (function (param) {
                                                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                  })),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._2(fetchData, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._2(fetchData, undefined, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var tmp = {
    headers: fetchRequest.headers,
    handleSorting: handleSorting,
    tablePage: convertTable(tablePage),
    paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined),
    exportMenu: React.createElement(Core.Grid, {
          children: null,
          container: true,
          item: true,
          justifyContent: "flex-end",
          spacing: 1
        }, User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) ? React.createElement(Core.Grid, {
                children: React.createElement(App_Table_Create_Button$DvmAdminFrontendFr.make, {
                      onClick: (function (param) {
                          Curry._1(setModal, (function (param) {
                                  return React.createElement(FinancialCode_Update_Modal$DvmAdminFrontendFr.make, {
                                              onClose: (function (param) {
                                                  onCloseModal(undefined);
                                                }),
                                              row: undefined,
                                              onOk: (function (param) {
                                                  onCloseModal(undefined);
                                                })
                                            });
                                }));
                        }),
                      message: Messages_FinancialCodes$DvmAdminFrontendFr.newFinancialCode
                    }),
                item: true
              }) : null, React.createElement(Core.Grid, {
              children: React.createElement(App_Table_Export_Menu$DvmAdminFrontendFr.make, {
                    query: Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                          page: 0,
                          rowsPerPage: 150000,
                          headers: fetchRequest.headers,
                          filterQueryParams: fetchRequest.filterQueryParams,
                          additionalQueryParams: fetchRequest.additionalQueryParams
                        }),
                    endpoint: "easypack"
                  }),
              item: true
            }))
  };
  var tmp$1 = User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) ? ({
        TAG: /* Left */0,
        _0: /* EmptyCell */0
      }) : undefined;
  if (tmp$1 !== undefined) {
    tmp.rowButton = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, tmp), match$1[0]);
}

var make = FinancialCode_Search_Table;

export {
  headersConfiguration ,
  make ,
}
/* headersConfiguration Not a pure module */
