// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "@emotion/css";

function formSection(theme) {
  return Css.css({
              backgroundColor: theme.palette.grey[50]
            });
}

export {
  formSection ,
}
/* @emotion/css Not a pure module */
