// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Quota_Detail_Attachment_Api$DvmAdminFrontendFr from "../Quota_Detail_Attachment_Api.bs.js";

function Quota_Detail_Attachment_Delete_Modal(Props) {
  var onClose = Props.onClose;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var row = Props.row;
  var setRow = Props.setRow;
  var fetchVolumeTotal = Props.fetchVolumeTotal;
  var quota = Props.quota;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$2[1];
  var result = match$2[0];
  var alert = ReactAlert.useAlert();
  React.useEffect((function () {
          if (typeof result === "number") {
            result === /* NotStarted */0;
          } else if (result.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(setRow, (function (param) {
                    
                  }));
            Curry._2(fetchData, undefined, fetchRequest);
            Curry._1(fetchVolumeTotal, quota);
            Curry._1(onClose, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: result._0
                      }
                    }));
          }
        }), [result]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.reallyDelete),
              minWidth: "450px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(result),
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          justifyContent: "space-between"
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                    onClick: (function (param) {
                                        Curry._1(onClose, undefined);
                                      }),
                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.cancel),
                                    color: "primaryBorder",
                                    size: "medium"
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                    onClick: (function (param) {
                                        Quota_Detail_Attachment_Api$DvmAdminFrontendFr.deleteAttachment(setResult, userManager, selectedRole, {
                                              attachmentOldStartDate: row.attachmentStartDate,
                                              contractId: row.contractId,
                                              dr: row.dr,
                                              vehicleType: row.vehicleType,
                                              quotaStartDate: row.quotaStartDate,
                                              siret: row.siret,
                                              companyName: row.companyName,
                                              dealerId: row.dealerId,
                                              volume: row.volume,
                                              attachmentStartDate: row.attachmentStartDate,
                                              attachmentEndDate: row.attachmentEndDate
                                            });
                                      }),
                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.$$delete),
                                    color: "delete",
                                    size: "medium"
                                  }),
                              item: true
                            }))
                  })
            });
}

var Api;

var Types;

var make = Quota_Detail_Attachment_Delete_Modal;

export {
  Api ,
  Types ,
  make ,
}
/* react Not a pure module */
