// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Utils_Form$DvmAdminFrontendFr from "../../utils/Utils_Form.bs.js";
import * as Utils_Input$DvmAdminFrontendFr from "../../utils/Utils_Input.bs.js";

function App_FloatFieldFormInput(Props) {
  var validationResult = Props.validationResult;
  var onChangeOpt = Props.onChange;
  var value = Props.value;
  var style = Props.style;
  var disabled = Props.disabled;
  var onBlur = Props.onBlur;
  var fullWidth = Props.fullWidth;
  var multiline = Props.multiline;
  var classes = Props.classes;
  var className = Props.className;
  var label = Props.label;
  var required = Props.required;
  var name = Props.name;
  var autoCompleteOpt = Props.autoComplete;
  var id = Props.id;
  var inputProps = Props.inputProps;
  var InputLabelProps = Props.InputLabelProps;
  var InputProps = Props.InputProps;
  var placeholder = Props.placeholder;
  var maxLength = Props.maxLength;
  var helperTextWhenNoError = Props.helperTextWhenNoError;
  var onChange = onChangeOpt !== undefined ? onChangeOpt : (function (param) {
        
      });
  var autoComplete = autoCompleteOpt !== undefined ? autoCompleteOpt : "off";
  var intl = ReactIntl.useIntl();
  var tmp = {
    autoComplete: autoComplete,
    onChange: (function ($$event) {
        Curry._1(onChange, Belt_Float.fromString($$event.target.value));
      }),
    size: "small",
    type: "number",
    value: String(Belt_Option.getWithDefault(value, 0.0)),
    variant: "filled"
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  if (classes !== undefined) {
    tmp.classes = Caml_option.valFromOption(classes);
  }
  if (className !== undefined) {
    tmp.className = className;
  }
  if (disabled !== undefined) {
    tmp.disabled = disabled;
  }
  var tmp$1 = Belt_Option.map(validationResult, Belt_Result.isError);
  if (tmp$1 !== undefined) {
    tmp.error = tmp$1;
  }
  if (fullWidth !== undefined) {
    tmp.fullWidth = fullWidth;
  }
  var tmp$2 = Belt_Option.mapWithDefault(Utils_Form$DvmAdminFrontendFr.getErrorMessage(intl, validationResult), Belt_Option.mapWithDefault(helperTextWhenNoError, undefined, (function (x) {
              return Caml_option.some(intl.formatMessage(x));
            })), (function (x) {
          return Caml_option.some(x);
        }));
  if (tmp$2 !== undefined) {
    tmp.helperText = Caml_option.valFromOption(tmp$2);
  }
  if (id !== undefined) {
    tmp.id = id;
  }
  if (InputLabelProps !== undefined) {
    tmp.InputLabelProps = Caml_option.valFromOption(InputLabelProps);
  }
  var tmp$3 = Belt_Option.mapWithDefault(maxLength, inputProps, (function (max) {
          return Belt_Option.mapWithDefault(inputProps, undefined, (function (x) {
                        x.maxLength = max;
                        return Caml_option.some(x);
                      }));
        }));
  if (tmp$3 !== undefined) {
    tmp.inputProps = Caml_option.valFromOption(tmp$3);
  }
  if (InputProps !== undefined) {
    tmp.InputProps = Caml_option.valFromOption(InputProps);
  }
  var tmp$4 = Belt_Option.map(label, (function (label) {
          if (required !== undefined && required) {
            return Utils_Input$DvmAdminFrontendFr.requiredLabel(intl, label);
          } else {
            return intl.formatMessage(label);
          }
        }));
  if (tmp$4 !== undefined) {
    tmp.label = Caml_option.valFromOption(tmp$4);
  }
  if (multiline !== undefined) {
    tmp.multiline = multiline;
  }
  if (name !== undefined) {
    tmp.name = name;
  }
  if (onBlur !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(onBlur);
  }
  if (placeholder !== undefined) {
    tmp.placeholder = placeholder;
  }
  var tmp$5 = Belt_Option.map(required, Utils_Input$DvmAdminFrontendFr.isHtmlRequired);
  if (tmp$5 !== undefined) {
    tmp.required = tmp$5;
  }
  return React.createElement(Core.TextField, tmp);
}

var make = App_FloatFieldFormInput;

export {
  make ,
}
/* react Not a pure module */
