// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../../../common/components/icons/App_Icon.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Create$DvmAdminFrontendFr from "../create/Order_Detail_Bonus_DealerRequest_Create.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Delete$DvmAdminFrontendFr from "./Order_Detail_Bonus_DealerRequest_Delete.bs.js";

function Order_Detail_Bonus_DealerRequest_Edit_Buttons(Props) {
  var order = Props.order;
  var documentsResult = Props.documentsResult;
  var actionId = Props.actionId;
  var orderType = Props.orderType;
  var orderId = Props.orderId;
  var reloadPage = Props.reloadPage;
  var filteredRequest = Props.filteredRequest;
  var match = React.useState(function () {
        return null;
      });
  var setModal = match[1];
  var modal = match[0];
  var filteredDocs;
  filteredDocs = typeof documentsResult === "number" || documentsResult.TAG !== /* Ok */0 ? /* [] */0 : Belt_List.keep(documentsResult._0, (function (x) {
            return x.actionId === Belt_Option.getWithDefault(actionId, "");
          }));
  return Belt_Option.mapWithDefault(filteredRequest, null, (function (actionRequest) {
                return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                                children: null,
                                container: true,
                                direction: "column",
                                spacing: 2,
                                style: {
                                  left: "50px",
                                  position: "absolute",
                                  width: "min-content"
                                }
                              }, React.createElement(Core.Grid, {
                                    children: React.createElement(Core.IconButton, {
                                          onClick: (function (param) {
                                              Curry._1(setModal, (function (param) {
                                                      return React.createElement(Order_Detail_Bonus_DealerRequest_Create$DvmAdminFrontendFr.make, {
                                                                  order: order,
                                                                  orderId: orderId,
                                                                  action: actionRequest,
                                                                  onClose: (function (param) {
                                                                      Curry._1(setModal, (function (param) {
                                                                              return null;
                                                                            }));
                                                                    }),
                                                                  onOk: (function (param) {
                                                                      Curry._1(setModal, (function (param) {
                                                                              return null;
                                                                            }));
                                                                      Curry._1(reloadPage, undefined);
                                                                    }),
                                                                  orderType: orderType,
                                                                  savedDocuments: filteredDocs,
                                                                  reloadPage: reloadPage
                                                                });
                                                    }));
                                            }),
                                          children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                size: /* Large */3,
                                                type_: /* Edit */60
                                              })
                                        }),
                                    item: true
                                  }), React.createElement(Core.Grid, {
                                    children: React.createElement(Core.IconButton, {
                                          onClick: (function (param) {
                                              Curry._1(setModal, (function (param) {
                                                      return React.createElement(Order_Detail_Bonus_DealerRequest_Delete$DvmAdminFrontendFr.make, {
                                                                  onClose: (function (param) {
                                                                      Curry._1(setModal, (function (param) {
                                                                              return null;
                                                                            }));
                                                                    }),
                                                                  orderId: orderId,
                                                                  actionId: actionId,
                                                                  reloadPage: reloadPage,
                                                                  filteredDocs: filteredDocs
                                                                });
                                                    }));
                                            }),
                                          children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                size: /* Large */3,
                                                type_: /* Trash */38,
                                                color: "red"
                                              })
                                        }),
                                    item: true
                                  })), modal);
              }));
}

var make = Order_Detail_Bonus_DealerRequest_Edit_Buttons;

export {
  make ,
}
/* react Not a pure module */
