// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "./App_AutocompleteFormInput.bs.js";

function App_DoubleSelectFormInput(Props) {
  var labelParent = Props.labelParent;
  var labelChild = Props.labelChild;
  var valueParent = Props.valueParent;
  var valueChild = Props.valueChild;
  var options = Props.options;
  var classNameParent = Props.classNameParent;
  var classNameChild = Props.classNameChild;
  var validationResultParent = Props.validationResultParent;
  var validationResultChild = Props.validationResultChild;
  var directionOpt = Props.direction;
  var limitTagsParent = Props.limitTagsParent;
  var limitTagsChild = Props.limitTagsChild;
  var disableParent = Props.disableParent;
  var disableChild = Props.disableChild;
  var requiredParent = Props.requiredParent;
  var requiredChild = Props.requiredChild;
  var selectParentIfOnlyOneOpt = Props.selectParentIfOnlyOne;
  var selectChildIfOnlyOneOpt = Props.selectChildIfOnlyOne;
  var spacingOpt = Props.spacing;
  var onSearchChild = Props.onSearchChild;
  var inputValueChild = Props.inputValueChild;
  var direction = directionOpt !== undefined ? directionOpt : "row";
  var selectParentIfOnlyOne = selectParentIfOnlyOneOpt !== undefined ? selectParentIfOnlyOneOpt : false;
  var selectChildIfOnlyOne = selectChildIfOnlyOneOpt !== undefined ? selectChildIfOnlyOneOpt : false;
  var spacing = spacingOpt !== undefined ? spacingOpt : 2;
  var parentOptions = Belt_List.map(options, (function (param) {
          return param.parent;
        }));
  var getChildren = function (parentList) {
    return Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.flatten(Belt_List.map(Belt_List.keep(options, (function (x) {
                                      if (Belt_List.length(parentList) > 0) {
                                        return Belt_List.has(parentList, x.parent, (function (a, b) {
                                                      return a === b;
                                                    }));
                                      } else if (requiredParent !== undefined) {
                                        return Belt_List.has(parentList, x.parent, (function (a, b) {
                                                      return a === b;
                                                    }));
                                      } else {
                                        return true;
                                      }
                                    })), (function (x) {
                                  return x.child;
                                }))))));
  };
  var childOptions;
  if (valueParent.TAG === /* Single */0) {
    var value = valueParent._0.value;
    childOptions = getChildren(value.length > 0 ? ({
              hd: value,
              tl: /* [] */0
            }) : /* [] */0);
  } else {
    childOptions = getChildren(valueParent._0.value);
  }
  var updateChildren = function (param) {
    if (valueChild.TAG === /* Single */0) {
      var match = valueChild._0;
      if (Belt_List.has(childOptions, match.value, (function (a, b) {
                return a === b;
              }))) {
        return ;
      } else {
        return Curry._1(match.onChange, "");
      }
    }
    var match$1 = valueChild._0;
    Curry._1(match$1.onChange, Belt_List.keep(match$1.value, (function (x) {
                return Belt_List.has(childOptions, x, (function (a, b) {
                              return a === b;
                            }));
              })));
  };
  var refValueParent = React.useRef("");
  React.useEffect((function () {
          if (valueParent.TAG === /* Single */0) {
            var value = valueParent._0.value;
            if (refValueParent.current === value) {
              
            } else {
              updateChildren(undefined);
            }
            refValueParent.current = value;
          } else {
            var value$1 = valueParent._0.value;
            if (refValueParent.current === Belt_List.reduce(value$1, "", (function (a, b) {
                      return a + b;
                    }))) {
              
            } else {
              updateChildren(undefined);
            }
            refValueParent.current = Belt_List.reduce(value$1, "", (function (a, b) {
                    return a + b;
                  }));
          }
        }), [valueParent]);
  React.useEffect((function () {
          if (selectParentIfOnlyOne) {
            var parents = Belt_List.map(options, (function (x) {
                    return x.parent;
                  }));
            if (Belt_List.length(parents) === 1) {
              Belt_Option.mapWithDefault(Belt_List.get(parents, 0), undefined, (function (val) {
                      if (valueParent.TAG === /* Single */0) {
                        return Curry._1(valueParent._0.onChange, val);
                      } else {
                        return Curry._1(valueParent._0.onChange, {
                                    hd: val,
                                    tl: /* [] */0
                                  });
                      }
                    }));
            }
            
          }
          if (selectChildIfOnlyOne) {
            var children = Belt_List.concatMany(Belt_List.toArray(Belt_List.map(options, (function (x) {
                            return x.child;
                          }))));
            if (Belt_List.length(children) === 1) {
              Belt_Option.mapWithDefault(Belt_List.get(children, 0), undefined, (function (val) {
                      if (valueChild.TAG === /* Single */0) {
                        return Curry._1(valueChild._0.onChange, val);
                      } else {
                        return Curry._1(valueChild._0.onChange, {
                                    hd: val,
                                    tl: /* [] */0
                                  });
                      }
                    }));
            }
            
          }
          
        }), [options]);
  var tmp = {
    label: labelParent,
    value: valueParent,
    options: {
      TAG: /* Unlabeled */0,
      _0: parentOptions
    },
    className: Belt_Option.getWithDefault(classNameParent, ""),
    smallChips: true
  };
  if (validationResultParent !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(validationResultParent);
  }
  if (disableParent !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disableParent);
  }
  if (requiredParent !== undefined) {
    tmp.required = Caml_option.valFromOption(requiredParent);
  }
  if (limitTagsParent !== undefined) {
    tmp.limitTags = Caml_option.valFromOption(limitTagsParent);
  }
  var tmp$1 = {
    label: labelChild,
    value: valueChild,
    options: {
      TAG: /* Unlabeled */0,
      _0: childOptions
    },
    className: Belt_Option.getWithDefault(classNameChild, ""),
    smallChips: true
  };
  if (validationResultChild !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(validationResultChild);
  }
  if (disableChild !== undefined) {
    tmp$1.disabled = Caml_option.valFromOption(disableChild);
  }
  if (requiredChild !== undefined) {
    tmp$1.required = Caml_option.valFromOption(requiredChild);
  }
  if (onSearchChild !== undefined) {
    tmp$1.onSearch = Caml_option.valFromOption(onSearchChild);
  }
  if (limitTagsChild !== undefined) {
    tmp$1.limitTags = Caml_option.valFromOption(limitTagsChild);
  }
  if (inputValueChild !== undefined) {
    tmp$1.inputValue = Caml_option.valFromOption(inputValueChild);
  }
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              direction: direction,
              spacing: spacing
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$1),
                  item: true
                }));
}

var make = App_DoubleSelectFormInput;

export {
  make ,
}
/* react Not a pure module */
