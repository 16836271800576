// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../common/utils/Utils_Form.bs.js";
import * as Messages_Category$DvmAdminFrontendFr from "../../../intl/messages/category/Messages_Category.bs.js";

var validators_endDate = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequiredDate(param.endDate, undefined);
    })
};

var validators_startDate = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequiredDate(param.startDate, undefined);
    })
};

var validators_categoryName = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.categoryName);
    })
};

var validators_categoryID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var categoryID = param.categoryID;
      if (param.modelID.toUpperCase() === categoryID.toUpperCase().trim()) {
        return {
                TAG: /* Error */1,
                _0: Messages_Category$DvmAdminFrontendFr.categoryNameAsModel
              };
      } else {
        return Utils_Form$DvmAdminFrontendFr.validateRequired(categoryID);
      }
    })
};

var validators_modelID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.modelID);
    })
};

var validators = {
  endDate: validators_endDate,
  startDate: validators_startDate,
  categoryName: validators_categoryName,
  categoryID: validators_categoryID,
  modelID: validators_modelID
};

function initialFieldsStatuses(_input) {
  return {
          endDate: /* Pristine */0,
          startDate: /* Pristine */0,
          categoryName: /* Pristine */0,
          categoryID: /* Pristine */0,
          modelID: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.endDate;
  var match_0 = match ? match._0 : Curry._1(validators.endDate.validate, input);
  var match$1 = fieldsStatuses.startDate;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.startDate.validate, input);
  var match$2 = fieldsStatuses.categoryName;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.categoryName.validate, input);
  var match$3 = fieldsStatuses.categoryID;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.categoryID.validate, input);
  var match$4 = fieldsStatuses.modelID;
  var match_0$4 = match$4 ? match$4._0 : Curry._1(validators.modelID.validate, input);
  var endDateResult = match_0;
  var endDateResult$1;
  if (endDateResult.TAG === /* Ok */0) {
    var startDateResult = match_0$1;
    if (startDateResult.TAG === /* Ok */0) {
      var categoryNameResult = match_0$2;
      if (categoryNameResult.TAG === /* Ok */0) {
        var categoryIDResult = match_0$3;
        if (categoryIDResult.TAG === /* Ok */0) {
          var modelIDResult = match_0$4;
          if (modelIDResult.TAG === /* Ok */0) {
            return {
                    TAG: /* Valid */0,
                    output: {
                      modelID: modelIDResult._0,
                      categoryID: categoryIDResult._0,
                      categoryName: categoryNameResult._0,
                      startDate: startDateResult._0,
                      endDate: endDateResult._0
                    },
                    fieldsStatuses: {
                      endDate: /* Dirty */{
                        _0: endDateResult,
                        _1: /* Shown */0
                      },
                      startDate: /* Dirty */{
                        _0: startDateResult,
                        _1: /* Shown */0
                      },
                      categoryName: /* Dirty */{
                        _0: categoryNameResult,
                        _1: /* Shown */0
                      },
                      categoryID: /* Dirty */{
                        _0: categoryIDResult,
                        _1: /* Shown */0
                      },
                      modelID: /* Dirty */{
                        _0: modelIDResult,
                        _1: /* Shown */0
                      }
                    },
                    collectionsStatuses: undefined
                  };
          }
          endDateResult$1 = endDateResult;
        } else {
          endDateResult$1 = endDateResult;
        }
      } else {
        endDateResult$1 = endDateResult;
      }
    } else {
      endDateResult$1 = endDateResult;
    }
  } else {
    endDateResult$1 = endDateResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            endDate: /* Dirty */{
              _0: endDateResult$1,
              _1: /* Shown */0
            },
            startDate: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            categoryName: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            categoryID: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            modelID: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurEndDateField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.endDate, validators_endDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: status,
                                  startDate: init.startDate,
                                  categoryName: init.categoryName,
                                  categoryID: init.categoryID,
                                  modelID: init.modelID
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurStartDateField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.startDate, validators_startDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: init.endDate,
                                  startDate: status,
                                  categoryName: init.categoryName,
                                  categoryID: init.categoryID,
                                  modelID: init.modelID
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCategoryNameField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.categoryName, validators_categoryName, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  categoryName: status,
                                  categoryID: init.categoryID,
                                  modelID: init.modelID
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCategoryIDField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.categoryID, validators_categoryID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  categoryName: init.categoryName,
                                  categoryID: status,
                                  modelID: init.modelID
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurModelIDField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.modelID, validators_modelID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  categoryName: init.categoryName,
                                  categoryID: init.categoryID,
                                  modelID: status
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */5 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */5,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */6,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */8);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */7);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */6 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */7 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */8 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateEndDateField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.endDate, state.submissionStatus, validators_endDate, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endDate: status,
                                            startDate: init.startDate,
                                            categoryName: init.categoryName,
                                            categoryID: init.categoryID,
                                            modelID: init.modelID
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateStartDateField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.startDate, state.submissionStatus, validators_startDate, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endDate: init.endDate,
                                            startDate: status,
                                            categoryName: init.categoryName,
                                            categoryID: init.categoryID,
                                            modelID: init.modelID
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCategoryNameField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.categoryName, state.submissionStatus, validators_categoryName, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            categoryName: status,
                                            categoryID: init.categoryID,
                                            modelID: init.modelID
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCategoryIDField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.categoryID, state.submissionStatus, validators_categoryID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            categoryName: init.categoryName,
                                            categoryID: status,
                                            modelID: init.modelID
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateModelIDField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.modelID, state.submissionStatus, validators_modelID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            categoryName: init.categoryName,
                                            categoryID: init.categoryID,
                                            modelID: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */5 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */6 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */7 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateEndDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEndDateField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateStartDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateStartDateField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCategoryName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCategoryNameField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCategoryID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCategoryIDField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateModelID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelIDField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurEndDate: (function (param) {
              Curry._1(dispatch, /* BlurEndDateField */0);
            }),
          blurStartDate: (function (param) {
              Curry._1(dispatch, /* BlurStartDateField */1);
            }),
          blurCategoryName: (function (param) {
              Curry._1(dispatch, /* BlurCategoryNameField */2);
            }),
          blurCategoryID: (function (param) {
              Curry._1(dispatch, /* BlurCategoryIDField */3);
            }),
          blurModelID: (function (param) {
              Curry._1(dispatch, /* BlurModelIDField */4);
            }),
          endDateResult: Formality.exposeFieldResult(state.fieldsStatuses.endDate),
          startDateResult: Formality.exposeFieldResult(state.fieldsStatuses.startDate),
          categoryNameResult: Formality.exposeFieldResult(state.fieldsStatuses.categoryName),
          categoryIDResult: Formality.exposeFieldResult(state.fieldsStatuses.categoryID),
          modelIDResult: Formality.exposeFieldResult(state.fieldsStatuses.modelID),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.endDate || match.startDate || match.categoryName || match.categoryID || match.modelID) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */5);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */6);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */7);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */7,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */8);
            })
        };
}

var CategoryEditForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialInput = {
  modelID: "",
  categoryID: "",
  categoryName: "",
  startDate: undefined,
  endDate: undefined
};

export {
  CategoryEditForm ,
  initialInput ,
}
/* react Not a pure module */
