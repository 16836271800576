// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as App_RadioFormInput$DvmAdminFrontendFr from "./App_RadioFormInput.bs.js";

function App_RadioFormInput_VehicleType(Props) {
  var label = Props.label;
  var value = Props.value;
  var onChange = Props.onChange;
  var required = Props.required;
  var validationResult = Props.validationResult;
  var className = Props.className;
  var classNameTitle = Props.classNameTitle;
  var disabled = Props.disabled;
  var tmp = {
    label: label,
    value: value,
    options: {
      hd: {
        label: {
          TAG: /* String */3,
          _0: "VP/VU"
        },
        value: "VPVU"
      },
      tl: {
        hd: {
          label: {
            TAG: /* String */3,
            _0: "VP"
          },
          value: "VP"
        },
        tl: {
          hd: {
            label: {
              TAG: /* String */3,
              _0: "VU"
            },
            value: "VU"
          },
          tl: /* [] */0
        }
      }
    },
    onChange: onChange
  };
  if (required !== undefined) {
    tmp.required = Caml_option.valFromOption(required);
  }
  if (validationResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(validationResult);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (classNameTitle !== undefined) {
    tmp.classNameTitle = Caml_option.valFromOption(classNameTitle);
  }
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  return React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, tmp);
}

var make = App_RadioFormInput_VehicleType;

export {
  make ,
}
/* react Not a pure module */
