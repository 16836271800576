// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../../../common/utils/Utils_Form.bs.js";

var validators_amount = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateFloatPositiveRequired(param.amount);
    })
};

var validators_derogateLabel = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.derogateLabel);
    })
};

var validators_actionId = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      if (param.action === "budget") {
        return {
                TAG: /* Ok */0,
                _0: ""
              };
      } else {
        return Utils_Form$DvmAdminFrontendFr.validateRequired(param.actionId);
      }
    })
};

var validators = {
  validation: undefined,
  comment: undefined,
  amount: validators_amount,
  derogateLabel: validators_derogateLabel,
  actionId: validators_actionId,
  action: undefined,
  negative: undefined
};

function initialFieldsStatuses(_input) {
  return {
          validation: /* Pristine */0,
          comment: /* Pristine */0,
          amount: /* Pristine */0,
          derogateLabel: /* Pristine */0,
          actionId: /* Pristine */0,
          action: /* Pristine */0,
          negative: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match_0 = {
    TAG: /* Ok */0,
    _0: input.validation
  };
  var match_0$1 = {
    TAG: /* Ok */0,
    _0: input.comment
  };
  var match = fieldsStatuses.amount;
  var match_0$2 = match ? match._0 : Curry._1(validators.amount.validate, input);
  var match$1 = fieldsStatuses.derogateLabel;
  var match_0$3 = match$1 ? match$1._0 : Curry._1(validators.derogateLabel.validate, input);
  var match$2 = fieldsStatuses.actionId;
  var match_0$4 = match$2 ? match$2._0 : Curry._1(validators.actionId.validate, input);
  var match_0$5 = {
    TAG: /* Ok */0,
    _0: input.action
  };
  var match_0$6 = {
    TAG: /* Ok */0,
    _0: input.negative
  };
  var validationResult = match_0;
  var validationResult$1;
  if (validationResult.TAG === /* Ok */0) {
    var commentResult = match_0$1;
    if (commentResult.TAG === /* Ok */0) {
      var amountResult = match_0$2;
      if (amountResult.TAG === /* Ok */0) {
        var derogateLabelResult = match_0$3;
        if (derogateLabelResult.TAG === /* Ok */0) {
          var actionIdResult = match_0$4;
          if (actionIdResult.TAG === /* Ok */0) {
            var actionResult = match_0$5;
            if (actionResult.TAG === /* Ok */0) {
              var negativeResult = match_0$6;
              if (negativeResult.TAG === /* Ok */0) {
                return {
                        TAG: /* Valid */0,
                        output: {
                          negative: negativeResult._0,
                          action: actionResult._0,
                          actionId: actionIdResult._0,
                          derogateLabel: derogateLabelResult._0,
                          amount: amountResult._0,
                          comment: commentResult._0,
                          validation: validationResult._0
                        },
                        fieldsStatuses: {
                          validation: /* Dirty */{
                            _0: validationResult,
                            _1: /* Hidden */1
                          },
                          comment: /* Dirty */{
                            _0: commentResult,
                            _1: /* Hidden */1
                          },
                          amount: /* Dirty */{
                            _0: amountResult,
                            _1: /* Shown */0
                          },
                          derogateLabel: /* Dirty */{
                            _0: derogateLabelResult,
                            _1: /* Shown */0
                          },
                          actionId: /* Dirty */{
                            _0: actionIdResult,
                            _1: /* Shown */0
                          },
                          action: /* Dirty */{
                            _0: actionResult,
                            _1: /* Hidden */1
                          },
                          negative: /* Dirty */{
                            _0: negativeResult,
                            _1: /* Hidden */1
                          }
                        },
                        collectionsStatuses: undefined
                      };
              }
              validationResult$1 = validationResult;
            } else {
              validationResult$1 = validationResult;
            }
          } else {
            validationResult$1 = validationResult;
          }
        } else {
          validationResult$1 = validationResult;
        }
      } else {
        validationResult$1 = validationResult;
      }
    } else {
      validationResult$1 = validationResult;
    }
  } else {
    validationResult$1 = validationResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            validation: /* Dirty */{
              _0: validationResult$1,
              _1: /* Hidden */1
            },
            comment: /* Dirty */{
              _0: match_0$1,
              _1: /* Hidden */1
            },
            amount: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            derogateLabel: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            actionId: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            action: /* Dirty */{
              _0: match_0$5,
              _1: /* Hidden */1
            },
            negative: /* Dirty */{
              _0: match_0$6,
              _1: /* Hidden */1
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurValidationField */0 :
                  var result = Formality.validateFieldOnBlurWithoutValidator(state.input.validation, state.fieldsStatuses.validation, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validation: status,
                                  comment: init.comment,
                                  amount: init.amount,
                                  derogateLabel: init.derogateLabel,
                                  actionId: init.actionId,
                                  action: init.action,
                                  negative: init.negative
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCommentField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithoutValidator(state.input.comment, state.fieldsStatuses.comment, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validation: init.validation,
                                  comment: status,
                                  amount: init.amount,
                                  derogateLabel: init.derogateLabel,
                                  actionId: init.actionId,
                                  action: init.action,
                                  negative: init.negative
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAmountField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.amount, validators_amount, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validation: init.validation,
                                  comment: init.comment,
                                  amount: status,
                                  derogateLabel: init.derogateLabel,
                                  actionId: init.actionId,
                                  action: init.action,
                                  negative: init.negative
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDerogateLabelField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.derogateLabel, validators_derogateLabel, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validation: init.validation,
                                  comment: init.comment,
                                  amount: init.amount,
                                  derogateLabel: status,
                                  actionId: init.actionId,
                                  action: init.action,
                                  negative: init.negative
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurActionIdField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.actionId, validators_actionId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validation: init.validation,
                                  comment: init.comment,
                                  amount: init.amount,
                                  derogateLabel: init.derogateLabel,
                                  actionId: status,
                                  action: init.action,
                                  negative: init.negative
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurActionField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithoutValidator(state.input.action, state.fieldsStatuses.action, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validation: init.validation,
                                  comment: init.comment,
                                  amount: init.amount,
                                  derogateLabel: init.derogateLabel,
                                  actionId: init.actionId,
                                  action: status,
                                  negative: init.negative
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurNegativeField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithoutValidator(state.input.negative, state.fieldsStatuses.negative, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validation: init.validation,
                                  comment: init.comment,
                                  amount: init.amount,
                                  derogateLabel: init.derogateLabel,
                                  actionId: init.actionId,
                                  action: init.action,
                                  negative: status
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */7 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */7,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */8,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */10);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */9);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */8 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */9 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */10 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateValidationField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput.validation, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validation: status,
                                            comment: init.comment,
                                            amount: init.amount,
                                            derogateLabel: init.derogateLabel,
                                            actionId: init.actionId,
                                            action: init.action,
                                            negative: init.negative
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCommentField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$1.comment, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validation: init.validation,
                                            comment: status,
                                            amount: init.amount,
                                            derogateLabel: init.derogateLabel,
                                            actionId: init.actionId,
                                            action: init.action,
                                            negative: init.negative
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAmountField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.amount, state.submissionStatus, validators_amount, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validation: init.validation,
                                            comment: init.comment,
                                            amount: status,
                                            derogateLabel: init.derogateLabel,
                                            actionId: init.actionId,
                                            action: init.action,
                                            negative: init.negative
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDerogateLabelField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.derogateLabel, state.submissionStatus, validators_derogateLabel, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validation: init.validation,
                                            comment: init.comment,
                                            amount: init.amount,
                                            derogateLabel: status,
                                            actionId: init.actionId,
                                            action: init.action,
                                            negative: init.negative
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateActionIdField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.actionId, state.submissionStatus, validators_actionId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validation: init.validation,
                                            comment: init.comment,
                                            amount: init.amount,
                                            derogateLabel: init.derogateLabel,
                                            actionId: status,
                                            action: init.action,
                                            negative: init.negative
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateActionField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$7 = Formality.validateDependentFieldOnChange(nextInput$5, nextFieldsStatuses.contents.actionId, validators_actionId, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  validation: init.validation,
                                  comment: init.comment,
                                  amount: init.amount,
                                  derogateLabel: init.derogateLabel,
                                  actionId: status,
                                  action: init.action,
                                  negative: init.negative
                                };
                        }));
                  if (result$7 !== undefined) {
                    nextFieldsStatuses.contents = result$7;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$5.action, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            validation: init.validation,
                                            comment: init.comment,
                                            amount: init.amount,
                                            derogateLabel: init.derogateLabel,
                                            actionId: init.actionId,
                                            action: status,
                                            negative: init.negative
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateNegativeField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$6.negative, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validation: init.validation,
                                            comment: init.comment,
                                            amount: init.amount,
                                            derogateLabel: init.derogateLabel,
                                            actionId: init.actionId,
                                            action: init.action,
                                            negative: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */7 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */8 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */9 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateValidation: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateValidationField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateComment: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCommentField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateAmount: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAmountField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDerogateLabel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDerogateLabelField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateActionId: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateActionIdField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateAction: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateActionField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateNegative: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateNegativeField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurValidation: (function (param) {
              Curry._1(dispatch, /* BlurValidationField */0);
            }),
          blurComment: (function (param) {
              Curry._1(dispatch, /* BlurCommentField */1);
            }),
          blurAmount: (function (param) {
              Curry._1(dispatch, /* BlurAmountField */2);
            }),
          blurDerogateLabel: (function (param) {
              Curry._1(dispatch, /* BlurDerogateLabelField */3);
            }),
          blurActionId: (function (param) {
              Curry._1(dispatch, /* BlurActionIdField */4);
            }),
          blurAction: (function (param) {
              Curry._1(dispatch, /* BlurActionField */5);
            }),
          blurNegative: (function (param) {
              Curry._1(dispatch, /* BlurNegativeField */6);
            }),
          validationResult: Formality.exposeFieldResult(state.fieldsStatuses.validation),
          commentResult: Formality.exposeFieldResult(state.fieldsStatuses.comment),
          amountResult: Formality.exposeFieldResult(state.fieldsStatuses.amount),
          derogateLabelResult: Formality.exposeFieldResult(state.fieldsStatuses.derogateLabel),
          actionIdResult: Formality.exposeFieldResult(state.fieldsStatuses.actionId),
          actionResult: Formality.exposeFieldResult(state.fieldsStatuses.action),
          negativeResult: Formality.exposeFieldResult(state.fieldsStatuses.negative),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.validation || match.comment || match.amount || match.derogateLabel || match.actionId || match.action || match.negative) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */7);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */8);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */9);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */9,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */10);
            })
        };
}

var Form = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  negative: false,
  action: "",
  actionId: "",
  derogateLabel: "",
  amount: "",
  comment: "",
  validation: ""
};

export {
  Form ,
  initialState$1 as initialState,
}
/* react Not a pure module */
