// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as Order_Search_Api$DvmAdminFrontendFr from "../../../order/search/Order_Search_Api.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_SelectFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_AutocompleteFormInput.bs.js";

function Flash_Edit_Info_Spain_Types(Props) {
  var form = Props.form;
  var formAdditional = Props.formAdditional;
  var flashId = Props.flashId;
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setClientTypes = match$2[1];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaleTypes = match$3[1];
  var saleTypes = match$3[0];
  React.useEffect((function () {
          Order_Search_Api$DvmAdminFrontendFr.fetchClientTypes(alert, userManager, setClientTypes, selectedRole);
          Order_Search_Api$DvmAdminFrontendFr.fetchSaleTypes(alert, userManager, setSaleTypes, selectedRole);
        }), []);
  React.useEffect((function () {
          var match = formAdditional.input.clientType;
          switch (match) {
            case "1" :
                Curry._2(form.updateOrderType, (function (input, orderType) {
                        var newrecord = Caml_obj.obj_dup(input);
                        newrecord.orderType = orderType;
                        return newrecord;
                      }), /* CL */1);
                break;
            case "3" :
                Curry._2(form.updateOrderType, (function (input, orderType) {
                        var newrecord = Caml_obj.obj_dup(input);
                        newrecord.orderType = orderType;
                        return newrecord;
                      }), /* VD */3);
                break;
            default:
              
          }
        }), [formAdditional.input.clientType]);
  var filteredSalesTypes = function (x) {
    return Belt_List.keep(x, (function (x) {
                  var salesOne = formAdditional.input.clientType === "1" ? Belt_List.has({
                          hd: "00",
                          tl: {
                            hd: "82",
                            tl: {
                              hd: "83",
                              tl: {
                                hd: "84",
                                tl: {
                                  hd: "86",
                                  tl: {
                                    hd: "90",
                                    tl: {
                                      hd: "91",
                                      tl: {
                                        hd: "92",
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }, x, (function (a, b) {
                            return a === b;
                          })) : false;
                  var salesThree = formAdditional.input.clientType === "3" ? Belt_List.has({
                          hd: "97",
                          tl: {
                            hd: "98",
                            tl: {
                              hd: "99",
                              tl: /* [] */0
                            }
                          }
                        }, x, (function (a, b) {
                            return a === b;
                          })) : false;
                  if (formAdditional.input.clientType.length > 0 && !salesOne) {
                    return salesThree;
                  } else {
                    return true;
                  }
                }));
  };
  var cleanSalesTypes = function (param) {
    return App_Types_Result$DvmAdminFrontendFr.mapWithDefault(saleTypes, /* [] */0, (function (x) {
                  return Belt_List.map(Belt_List.keep(Belt_List.keep(x, (function (x) {
                                        return x.paramOne.length > 0;
                                      })), (function (x) {
                                    var salesOne = formAdditional.input.clientType === "1" ? Belt_List.has({
                                            hd: "00",
                                            tl: {
                                              hd: "82",
                                              tl: {
                                                hd: "83",
                                                tl: {
                                                  hd: "84",
                                                  tl: {
                                                    hd: "86",
                                                    tl: {
                                                      hd: "90",
                                                      tl: {
                                                        hd: "91",
                                                        tl: {
                                                          hd: "92",
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }, x.paramOne, (function (a, b) {
                                              return a === b;
                                            })) : false;
                                    var salesThree = formAdditional.input.clientType === "3" ? Belt_List.has({
                                            hd: "97",
                                            tl: {
                                              hd: "98",
                                              tl: {
                                                hd: "99",
                                                tl: /* [] */0
                                              }
                                            }
                                          }, x.paramOne, (function (a, b) {
                                              return a === b;
                                            })) : false;
                                    if (formAdditional.input.clientType.length > 0 && !salesOne) {
                                      return salesThree;
                                    } else {
                                      return true;
                                    }
                                  })), (function (x) {
                                return {
                                        label: {
                                          TAG: /* String */3,
                                          _0: x.paramOne + Belt_Option.mapWithDefault(x.paramTwo, "", (function (label) {
                                                  return " " + label + "";
                                                }))
                                        },
                                        value: x.paramOne
                                      };
                              }));
                }));
  };
  React.useEffect((function () {
          var filtered = filteredSalesTypes(formAdditional.input.saleType);
          Curry._2(formAdditional.updateSaleType, (function (input, saleType) {
                  return {
                          clientType: input.clientType,
                          saleType: saleType
                        };
                }), filtered);
        }), [formAdditional.input.clientType]);
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.clientType,
    options: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(match$2[0], /* [] */0, (function (x) {
            return Belt_List.map(Belt_List.keep(Belt_List.keep(x, (function (x) {
                                  return x.paramOne.length > 0;
                                })), (function (x) {
                              if (x.paramOne === "1") {
                                return true;
                              } else {
                                return x.paramOne === "3";
                              }
                            })), (function (x) {
                          return {
                                  label: {
                                    TAG: /* String */3,
                                    _0: x.paramOne + Belt_Option.mapWithDefault(x.paramTwo, "", (function (label) {
                                            return " " + label + "";
                                          }))
                                  },
                                  value: x.paramOne
                                };
                        }));
          })),
    value: {
      TAG: /* Single */0,
      _0: {
        value: formAdditional.input.clientType,
        onChange: Curry._1(formAdditional.updateClientType, (function (input, clientType) {
                return {
                        clientType: clientType,
                        saleType: input.saleType
                      };
              }))
      }
    },
    id: "clientType",
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    disabled: Belt_Option.isSome(flashId)
  };
  if (formAdditional.clientTypeResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(formAdditional.clientTypeResult);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, tmp),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                        label: Messages_Common$DvmAdminFrontendFr.saleType,
                        value: {
                          TAG: /* Multiple */1,
                          _0: {
                            value: formAdditional.input.saleType,
                            onChange: Curry._1(formAdditional.updateSaleType, (function (input, saleType) {
                                    return {
                                            clientType: input.clientType,
                                            saleType: saleType
                                          };
                                  }))
                          }
                        },
                        options: {
                          TAG: /* Labeled */1,
                          _0: cleanSalesTypes(undefined)
                        },
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInputExtraLong,
                        smallChips: true
                      }),
                  item: true
                }));
}

var Form;

var FormAdditional;

var InputStyles;

var make = Flash_Edit_Info_Spain_Types;

export {
  Form ,
  FormAdditional ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
