// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function Make(Config) {
  var t = React.createContext(Config.defaultValue);
  var make = t.Provider;
  var Provider = {
    make: make
  };
  var useAppContext = function (param) {
    return React.useContext(t);
  };
  return {
          t: t,
          Provider: Provider,
          useAppContext: useAppContext
        };
}

export {
  Make ,
}
/* react Not a pure module */
