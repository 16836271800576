// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as Margin_Minimum_Api$DvmAdminFrontendFr from "../search/Margin_Minimum_Api.bs.js";
import * as Margin_Minimum_AddEdit_Form$DvmAdminFrontendFr from "./Margin_Minimum_AddEdit_Form.bs.js";
import * as Margin_Minimum_AddEdit_Inputs$DvmAdminFrontendFr from "./Margin_Minimum_AddEdit_Inputs.bs.js";

function Margin_Minimum_AddEdit_Modal(Props) {
  var original = Props.original;
  var onClose = Props.onClose;
  var operation = Props.operation;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var budgets = Props.budgets;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$2[1];
  var result = match$2[0];
  var form = Margin_Minimum_AddEdit_Form$DvmAdminFrontendFr.Form.useForm(operation >= 2 || original === undefined ? Margin_Minimum_AddEdit_Form$DvmAdminFrontendFr.initialInput : ({
            budgetType: original.budgetType,
            amount: Belt_Option.mapWithDefault(original.amount, "", (function (x) {
                    return String(x);
                  })),
            startDate: Caml_option.some(original.startDate),
            endDate: original.endDate
          }), (function (param, submissionCallbacks) {
          var startDate = param.startDate;
          var newRow_yearBudget = Belt_Option.mapWithDefault(startDate, 0, (function (x) {
                  return ReDate.getYear(x) | 0;
                }));
          var newRow_budgetType = param.budgetType;
          var newRow_amount = param.amount;
          var newRow_startDate = Belt_Option.getWithDefault(startDate, new Date(0.0));
          var newRow_endDate = param.endDate;
          var newRow = {
            yearBudget: newRow_yearBudget,
            budgetType: newRow_budgetType,
            amount: newRow_amount,
            startDate: newRow_startDate,
            endDate: newRow_endDate
          };
          if (operation !== 0) {
            return Margin_Minimum_Api$DvmAdminFrontendFr.tryCreateNewMinimumMargin(submissionCallbacks, userManager, setResult, selectedRole, newRow);
          } else if (original !== undefined) {
            return Margin_Minimum_Api$DvmAdminFrontendFr.overwriteMinimumMargin(submissionCallbacks, userManager, setResult, original, selectedRole, newRow);
          } else {
            return ;
          }
        }));
  React.useEffect((function () {
          if (typeof result === "number") {
            result === /* NotStarted */0;
          } else if (result.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._2(fetchData, undefined, fetchRequest);
            Curry._1(onClose, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: result._0
                      }
                    }));
          }
        }), [result]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Belt_Option.isSome(original) ? Messages_Common$DvmAdminFrontendFr.modifyRow : Messages_Common$DvmAdminFrontendFr.addNewRow),
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(result),
                    children: React.createElement("form", {
                          onSubmit: (function ($$event) {
                              $$event.preventDefault();
                              Curry._1(form.submit, undefined);
                            })
                        }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                              color: "primary",
                              children: React.createElement(Core.Grid, {
                                    children: null,
                                    container: true,
                                    direction: "column",
                                    spacing: 3
                                  }, React.createElement(Margin_Minimum_AddEdit_Inputs$DvmAdminFrontendFr.make, {
                                        form: form,
                                        budgets: budgets
                                      }), React.createElement(Core.Grid, {
                                        children: null,
                                        container: true,
                                        justifyContent: "space-between"
                                      }, React.createElement(Core.Grid, {
                                            children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                  onClick: (function (param) {
                                                      Curry._1(onClose, undefined);
                                                    }),
                                                  label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.cancel),
                                                  color: "primaryBorder",
                                                  size: "medium"
                                                }),
                                            item: true
                                          }), React.createElement(Core.Grid, {
                                            children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                  label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                                  size: "medium",
                                                  type_: "submit"
                                                }),
                                            item: true
                                          }))),
                              variant: "bodyBig"
                            }))
                  })
            });
}

var Api;

var Types;

var Form;

var make = Margin_Minimum_AddEdit_Modal;

export {
  Api ,
  Types ,
  Form ,
  make ,
}
/* react Not a pure module */
