// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../types/App_Types_Date.bs.js";

var Fields = {
  siren: "siren",
  contractID: "contractID",
  startDate: "startDate",
  endDate: "endDate"
};

function errorRow_encode(v) {
  return Js_dict.fromArray([
              [
                "siren",
                Decco.stringToJson(v.siren)
              ],
              [
                "contractID",
                Decco.stringToJson(v.contractID)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function errorRow_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var siren = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "siren"), null));
  if (siren.TAG === /* Ok */0) {
    var contractID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractID"), null));
    if (contractID.TAG === /* Ok */0) {
      var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
      if (startDate.TAG === /* Ok */0) {
        var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
        if (endDate.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    siren: siren._0,
                    contractID: contractID._0,
                    startDate: startDate._0,
                    endDate: endDate._0
                  }
                };
        }
        var e = endDate._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".endDate" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = startDate._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".startDate" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = contractID._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".contractID" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = siren._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".siren" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(errorRow_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(errorRow_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var Errors = {
  errorRow_encode: errorRow_encode,
  errorRow_decode: errorRow_decode,
  searchResult_encode: searchResult_encode,
  searchResult_decode: searchResult_decode
};

export {
  Fields ,
  Errors ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
