// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../../../common/components/App_Checkbox.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Messages_Orders_Search$DvmAdminFrontendFr from "../../../../../intl/messages/orders/Messages_Orders_Search.bs.js";
import * as Order_Search_Modal_Date_Box$DvmAdminFrontendFr from "../Order_Search_Modal_Date_Box.bs.js";

function Order_Search_Modal_VO_Filters(Props) {
  var form = Props.form;
  var intl = ReactIntl.useIntl();
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              item: true,
              spacing: 2
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        color: "primary",
                        children: intl.formatMessage(Messages_Orders_Search$DvmAdminFrontendFr.hintShowEmpty),
                        variant: "bodyBig"
                      }),
                  container: true,
                  item: true,
                  sm: Grid$Mui.Sm[12]
                }), React.createElement(Core.Grid, {
                  alignItems: "center",
                  children: null,
                  container: true,
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  wrap: "nowrap"
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                            checked: form.input.releaseDateEmpty,
                            onClick: (function ($$event) {
                                Curry._2(form.updateReleaseDateEmpty, (function (input, releaseDateEmpty) {
                                        return {
                                                brandUsedCar: input.brandUsedCar,
                                                brandEmpty: input.brandEmpty,
                                                modelUsedCar: input.modelUsedCar,
                                                modelEmpty: input.modelEmpty,
                                                vinUsedCar: input.vinUsedCar,
                                                vinEmpty: input.vinEmpty,
                                                usedCarPoliceNumber: input.usedCarPoliceNumber,
                                                policeNumberEmpty: input.policeNumberEmpty,
                                                usedCarLastRegistrationDateStart: input.usedCarLastRegistrationDateStart,
                                                usedCarLastRegistrationDateEnd: input.usedCarLastRegistrationDateEnd,
                                                registrationDateEmpty: input.registrationDateEmpty,
                                                usedCarFirstReleaseDateStart: input.usedCarFirstReleaseDateStart,
                                                usedCarFirstReleaseDateEnd: input.usedCarFirstReleaseDateEnd,
                                                releaseDateEmpty: releaseDateEmpty,
                                                usedCarRegistrationID: input.usedCarRegistrationID,
                                                registrationIDEmpty: input.registrationIDEmpty
                                              };
                                      }), $$event.target.checked);
                              })
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(Order_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                            disabled: form.input.releaseDateEmpty,
                            title: Messages_Orders_Detail$DvmAdminFrontendFr.reprDatePremiere,
                            onChangeFrom: Curry._1(form.updateUsedCarFirstReleaseDateStart, (function (input, usedCarFirstReleaseDateStart) {
                                    return {
                                            brandUsedCar: input.brandUsedCar,
                                            brandEmpty: input.brandEmpty,
                                            modelUsedCar: input.modelUsedCar,
                                            modelEmpty: input.modelEmpty,
                                            vinUsedCar: input.vinUsedCar,
                                            vinEmpty: input.vinEmpty,
                                            usedCarPoliceNumber: input.usedCarPoliceNumber,
                                            policeNumberEmpty: input.policeNumberEmpty,
                                            usedCarLastRegistrationDateStart: input.usedCarLastRegistrationDateStart,
                                            usedCarLastRegistrationDateEnd: input.usedCarLastRegistrationDateEnd,
                                            registrationDateEmpty: input.registrationDateEmpty,
                                            usedCarFirstReleaseDateStart: usedCarFirstReleaseDateStart,
                                            usedCarFirstReleaseDateEnd: input.usedCarFirstReleaseDateEnd,
                                            releaseDateEmpty: input.releaseDateEmpty,
                                            usedCarRegistrationID: input.usedCarRegistrationID,
                                            registrationIDEmpty: input.registrationIDEmpty
                                          };
                                  })),
                            onChangeTo: Curry._1(form.updateUsedCarFirstReleaseDateEnd, (function (input, usedCarFirstReleaseDateEnd) {
                                    return {
                                            brandUsedCar: input.brandUsedCar,
                                            brandEmpty: input.brandEmpty,
                                            modelUsedCar: input.modelUsedCar,
                                            modelEmpty: input.modelEmpty,
                                            vinUsedCar: input.vinUsedCar,
                                            vinEmpty: input.vinEmpty,
                                            usedCarPoliceNumber: input.usedCarPoliceNumber,
                                            policeNumberEmpty: input.policeNumberEmpty,
                                            usedCarLastRegistrationDateStart: input.usedCarLastRegistrationDateStart,
                                            usedCarLastRegistrationDateEnd: input.usedCarLastRegistrationDateEnd,
                                            registrationDateEmpty: input.registrationDateEmpty,
                                            usedCarFirstReleaseDateStart: input.usedCarFirstReleaseDateStart,
                                            usedCarFirstReleaseDateEnd: usedCarFirstReleaseDateEnd,
                                            releaseDateEmpty: input.releaseDateEmpty,
                                            usedCarRegistrationID: input.usedCarRegistrationID,
                                            registrationIDEmpty: input.registrationIDEmpty
                                          };
                                  })),
                            dateFrom: form.input.usedCarFirstReleaseDateStart,
                            dateTo: form.input.usedCarFirstReleaseDateEnd,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                          }),
                      item: true
                    })), React.createElement(Core.Grid, {
                  alignItems: "center",
                  children: null,
                  container: true,
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  wrap: "nowrap"
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                            checked: form.input.registrationDateEmpty,
                            onClick: (function ($$event) {
                                Curry._2(form.updateRegistrationDateEmpty, (function (input, registrationDateEmpty) {
                                        return {
                                                brandUsedCar: input.brandUsedCar,
                                                brandEmpty: input.brandEmpty,
                                                modelUsedCar: input.modelUsedCar,
                                                modelEmpty: input.modelEmpty,
                                                vinUsedCar: input.vinUsedCar,
                                                vinEmpty: input.vinEmpty,
                                                usedCarPoliceNumber: input.usedCarPoliceNumber,
                                                policeNumberEmpty: input.policeNumberEmpty,
                                                usedCarLastRegistrationDateStart: input.usedCarLastRegistrationDateStart,
                                                usedCarLastRegistrationDateEnd: input.usedCarLastRegistrationDateEnd,
                                                registrationDateEmpty: registrationDateEmpty,
                                                usedCarFirstReleaseDateStart: input.usedCarFirstReleaseDateStart,
                                                usedCarFirstReleaseDateEnd: input.usedCarFirstReleaseDateEnd,
                                                releaseDateEmpty: input.releaseDateEmpty,
                                                usedCarRegistrationID: input.usedCarRegistrationID,
                                                registrationIDEmpty: input.registrationIDEmpty
                                              };
                                      }), $$event.target.checked);
                              })
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(Order_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                            disabled: form.input.registrationDateEmpty,
                            title: Messages_Orders_Detail$DvmAdminFrontendFr.reprDateImmat,
                            onChangeFrom: Curry._1(form.updateUsedCarLastRegistrationDateStart, (function (input, usedCarLastRegistrationDateStart) {
                                    return {
                                            brandUsedCar: input.brandUsedCar,
                                            brandEmpty: input.brandEmpty,
                                            modelUsedCar: input.modelUsedCar,
                                            modelEmpty: input.modelEmpty,
                                            vinUsedCar: input.vinUsedCar,
                                            vinEmpty: input.vinEmpty,
                                            usedCarPoliceNumber: input.usedCarPoliceNumber,
                                            policeNumberEmpty: input.policeNumberEmpty,
                                            usedCarLastRegistrationDateStart: usedCarLastRegistrationDateStart,
                                            usedCarLastRegistrationDateEnd: input.usedCarLastRegistrationDateEnd,
                                            registrationDateEmpty: input.registrationDateEmpty,
                                            usedCarFirstReleaseDateStart: input.usedCarFirstReleaseDateStart,
                                            usedCarFirstReleaseDateEnd: input.usedCarFirstReleaseDateEnd,
                                            releaseDateEmpty: input.releaseDateEmpty,
                                            usedCarRegistrationID: input.usedCarRegistrationID,
                                            registrationIDEmpty: input.registrationIDEmpty
                                          };
                                  })),
                            onChangeTo: Curry._1(form.updateUsedCarLastRegistrationDateEnd, (function (input, usedCarLastRegistrationDateEnd) {
                                    return {
                                            brandUsedCar: input.brandUsedCar,
                                            brandEmpty: input.brandEmpty,
                                            modelUsedCar: input.modelUsedCar,
                                            modelEmpty: input.modelEmpty,
                                            vinUsedCar: input.vinUsedCar,
                                            vinEmpty: input.vinEmpty,
                                            usedCarPoliceNumber: input.usedCarPoliceNumber,
                                            policeNumberEmpty: input.policeNumberEmpty,
                                            usedCarLastRegistrationDateStart: input.usedCarLastRegistrationDateStart,
                                            usedCarLastRegistrationDateEnd: usedCarLastRegistrationDateEnd,
                                            registrationDateEmpty: input.registrationDateEmpty,
                                            usedCarFirstReleaseDateStart: input.usedCarFirstReleaseDateStart,
                                            usedCarFirstReleaseDateEnd: input.usedCarFirstReleaseDateEnd,
                                            releaseDateEmpty: input.releaseDateEmpty,
                                            usedCarRegistrationID: input.usedCarRegistrationID,
                                            registrationIDEmpty: input.registrationIDEmpty
                                          };
                                  })),
                            dateFrom: form.input.usedCarLastRegistrationDateStart,
                            dateTo: form.input.usedCarLastRegistrationDateEnd,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                          }),
                      item: true
                    })), React.createElement(Core.Grid, {
                  alignItems: "center",
                  children: null,
                  container: true,
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  wrap: "nowrap"
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                            checked: form.input.vinEmpty,
                            onClick: (function ($$event) {
                                Curry._2(form.updateVinEmpty, (function (input, vinEmpty) {
                                        return {
                                                brandUsedCar: input.brandUsedCar,
                                                brandEmpty: input.brandEmpty,
                                                modelUsedCar: input.modelUsedCar,
                                                modelEmpty: input.modelEmpty,
                                                vinUsedCar: input.vinUsedCar,
                                                vinEmpty: vinEmpty,
                                                usedCarPoliceNumber: input.usedCarPoliceNumber,
                                                policeNumberEmpty: input.policeNumberEmpty,
                                                usedCarLastRegistrationDateStart: input.usedCarLastRegistrationDateStart,
                                                usedCarLastRegistrationDateEnd: input.usedCarLastRegistrationDateEnd,
                                                registrationDateEmpty: input.registrationDateEmpty,
                                                usedCarFirstReleaseDateStart: input.usedCarFirstReleaseDateStart,
                                                usedCarFirstReleaseDateEnd: input.usedCarFirstReleaseDateEnd,
                                                releaseDateEmpty: input.releaseDateEmpty,
                                                usedCarRegistrationID: input.usedCarRegistrationID,
                                                registrationIDEmpty: input.registrationIDEmpty
                                              };
                                      }), $$event.target.checked);
                              })
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry._1(form.updateVinUsedCar, (function (input, vinUsedCar) {
                                    return {
                                            brandUsedCar: input.brandUsedCar,
                                            brandEmpty: input.brandEmpty,
                                            modelUsedCar: input.modelUsedCar,
                                            modelEmpty: input.modelEmpty,
                                            vinUsedCar: vinUsedCar,
                                            vinEmpty: input.vinEmpty,
                                            usedCarPoliceNumber: input.usedCarPoliceNumber,
                                            policeNumberEmpty: input.policeNumberEmpty,
                                            usedCarLastRegistrationDateStart: input.usedCarLastRegistrationDateStart,
                                            usedCarLastRegistrationDateEnd: input.usedCarLastRegistrationDateEnd,
                                            registrationDateEmpty: input.registrationDateEmpty,
                                            usedCarFirstReleaseDateStart: input.usedCarFirstReleaseDateStart,
                                            usedCarFirstReleaseDateEnd: input.usedCarFirstReleaseDateEnd,
                                            releaseDateEmpty: input.releaseDateEmpty,
                                            usedCarRegistrationID: input.usedCarRegistrationID,
                                            registrationIDEmpty: input.registrationIDEmpty
                                          };
                                  })),
                            value: form.input.vinUsedCar,
                            disabled: form.input.vinEmpty,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                            label: Messages_Common$DvmAdminFrontendFr.vinVO
                          }),
                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                      item: true
                    })), React.createElement(Core.Grid, {
                  alignItems: "center",
                  children: null,
                  container: true,
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  wrap: "nowrap"
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                            checked: form.input.registrationIDEmpty,
                            onClick: (function ($$event) {
                                Curry._2(form.updateRegistrationIDEmpty, (function (input, registrationIDEmpty) {
                                        return {
                                                brandUsedCar: input.brandUsedCar,
                                                brandEmpty: input.brandEmpty,
                                                modelUsedCar: input.modelUsedCar,
                                                modelEmpty: input.modelEmpty,
                                                vinUsedCar: input.vinUsedCar,
                                                vinEmpty: input.vinEmpty,
                                                usedCarPoliceNumber: input.usedCarPoliceNumber,
                                                policeNumberEmpty: input.policeNumberEmpty,
                                                usedCarLastRegistrationDateStart: input.usedCarLastRegistrationDateStart,
                                                usedCarLastRegistrationDateEnd: input.usedCarLastRegistrationDateEnd,
                                                registrationDateEmpty: input.registrationDateEmpty,
                                                usedCarFirstReleaseDateStart: input.usedCarFirstReleaseDateStart,
                                                usedCarFirstReleaseDateEnd: input.usedCarFirstReleaseDateEnd,
                                                releaseDateEmpty: input.releaseDateEmpty,
                                                usedCarRegistrationID: input.usedCarRegistrationID,
                                                registrationIDEmpty: registrationIDEmpty
                                              };
                                      }), $$event.target.checked);
                              })
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry._1(form.updateUsedCarRegistrationID, (function (input, usedCarRegistrationID) {
                                    return {
                                            brandUsedCar: input.brandUsedCar,
                                            brandEmpty: input.brandEmpty,
                                            modelUsedCar: input.modelUsedCar,
                                            modelEmpty: input.modelEmpty,
                                            vinUsedCar: input.vinUsedCar,
                                            vinEmpty: input.vinEmpty,
                                            usedCarPoliceNumber: input.usedCarPoliceNumber,
                                            policeNumberEmpty: input.policeNumberEmpty,
                                            usedCarLastRegistrationDateStart: input.usedCarLastRegistrationDateStart,
                                            usedCarLastRegistrationDateEnd: input.usedCarLastRegistrationDateEnd,
                                            registrationDateEmpty: input.registrationDateEmpty,
                                            usedCarFirstReleaseDateStart: input.usedCarFirstReleaseDateStart,
                                            usedCarFirstReleaseDateEnd: input.usedCarFirstReleaseDateEnd,
                                            releaseDateEmpty: input.releaseDateEmpty,
                                            usedCarRegistrationID: usedCarRegistrationID,
                                            registrationIDEmpty: input.registrationIDEmpty
                                          };
                                  })),
                            value: form.input.usedCarRegistrationID,
                            disabled: form.input.registrationIDEmpty,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                            label: Messages_Common$DvmAdminFrontendFr.registrationVO
                          }),
                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                      item: true
                    })), React.createElement(Core.Grid, {
                  alignItems: "center",
                  children: null,
                  container: true,
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  wrap: "nowrap"
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                            checked: form.input.brandEmpty,
                            onClick: (function ($$event) {
                                Curry._2(form.updateBrandEmpty, (function (input, brandEmpty) {
                                        return {
                                                brandUsedCar: input.brandUsedCar,
                                                brandEmpty: brandEmpty,
                                                modelUsedCar: input.modelUsedCar,
                                                modelEmpty: input.modelEmpty,
                                                vinUsedCar: input.vinUsedCar,
                                                vinEmpty: input.vinEmpty,
                                                usedCarPoliceNumber: input.usedCarPoliceNumber,
                                                policeNumberEmpty: input.policeNumberEmpty,
                                                usedCarLastRegistrationDateStart: input.usedCarLastRegistrationDateStart,
                                                usedCarLastRegistrationDateEnd: input.usedCarLastRegistrationDateEnd,
                                                registrationDateEmpty: input.registrationDateEmpty,
                                                usedCarFirstReleaseDateStart: input.usedCarFirstReleaseDateStart,
                                                usedCarFirstReleaseDateEnd: input.usedCarFirstReleaseDateEnd,
                                                releaseDateEmpty: input.releaseDateEmpty,
                                                usedCarRegistrationID: input.usedCarRegistrationID,
                                                registrationIDEmpty: input.registrationIDEmpty
                                              };
                                      }), $$event.target.checked);
                              })
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry._1(form.updateBrandUsedCar, (function (input, brandUsedCar) {
                                    return {
                                            brandUsedCar: brandUsedCar,
                                            brandEmpty: input.brandEmpty,
                                            modelUsedCar: input.modelUsedCar,
                                            modelEmpty: input.modelEmpty,
                                            vinUsedCar: input.vinUsedCar,
                                            vinEmpty: input.vinEmpty,
                                            usedCarPoliceNumber: input.usedCarPoliceNumber,
                                            policeNumberEmpty: input.policeNumberEmpty,
                                            usedCarLastRegistrationDateStart: input.usedCarLastRegistrationDateStart,
                                            usedCarLastRegistrationDateEnd: input.usedCarLastRegistrationDateEnd,
                                            registrationDateEmpty: input.registrationDateEmpty,
                                            usedCarFirstReleaseDateStart: input.usedCarFirstReleaseDateStart,
                                            usedCarFirstReleaseDateEnd: input.usedCarFirstReleaseDateEnd,
                                            releaseDateEmpty: input.releaseDateEmpty,
                                            usedCarRegistrationID: input.usedCarRegistrationID,
                                            registrationIDEmpty: input.registrationIDEmpty
                                          };
                                  })),
                            value: form.input.brandUsedCar,
                            disabled: form.input.brandEmpty,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                            label: Messages_Common$DvmAdminFrontendFr.brandVO
                          }),
                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                      item: true
                    })), React.createElement(Core.Grid, {
                  alignItems: "center",
                  children: null,
                  container: true,
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  wrap: "nowrap"
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                            checked: form.input.modelEmpty,
                            onClick: (function ($$event) {
                                Curry._2(form.updateModelEmpty, (function (input, modelEmpty) {
                                        return {
                                                brandUsedCar: input.brandUsedCar,
                                                brandEmpty: input.brandEmpty,
                                                modelUsedCar: input.modelUsedCar,
                                                modelEmpty: modelEmpty,
                                                vinUsedCar: input.vinUsedCar,
                                                vinEmpty: input.vinEmpty,
                                                usedCarPoliceNumber: input.usedCarPoliceNumber,
                                                policeNumberEmpty: input.policeNumberEmpty,
                                                usedCarLastRegistrationDateStart: input.usedCarLastRegistrationDateStart,
                                                usedCarLastRegistrationDateEnd: input.usedCarLastRegistrationDateEnd,
                                                registrationDateEmpty: input.registrationDateEmpty,
                                                usedCarFirstReleaseDateStart: input.usedCarFirstReleaseDateStart,
                                                usedCarFirstReleaseDateEnd: input.usedCarFirstReleaseDateEnd,
                                                releaseDateEmpty: input.releaseDateEmpty,
                                                usedCarRegistrationID: input.usedCarRegistrationID,
                                                registrationIDEmpty: input.registrationIDEmpty
                                              };
                                      }), $$event.target.checked);
                              })
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry._1(form.updateModelUsedCar, (function (input, modelUsedCar) {
                                    return {
                                            brandUsedCar: input.brandUsedCar,
                                            brandEmpty: input.brandEmpty,
                                            modelUsedCar: modelUsedCar,
                                            modelEmpty: input.modelEmpty,
                                            vinUsedCar: input.vinUsedCar,
                                            vinEmpty: input.vinEmpty,
                                            usedCarPoliceNumber: input.usedCarPoliceNumber,
                                            policeNumberEmpty: input.policeNumberEmpty,
                                            usedCarLastRegistrationDateStart: input.usedCarLastRegistrationDateStart,
                                            usedCarLastRegistrationDateEnd: input.usedCarLastRegistrationDateEnd,
                                            registrationDateEmpty: input.registrationDateEmpty,
                                            usedCarFirstReleaseDateStart: input.usedCarFirstReleaseDateStart,
                                            usedCarFirstReleaseDateEnd: input.usedCarFirstReleaseDateEnd,
                                            releaseDateEmpty: input.releaseDateEmpty,
                                            usedCarRegistrationID: input.usedCarRegistrationID,
                                            registrationIDEmpty: input.registrationIDEmpty
                                          };
                                  })),
                            value: form.input.modelUsedCar,
                            disabled: form.input.modelEmpty,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                            label: Messages_Common$DvmAdminFrontendFr.modelVO
                          }),
                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                      item: true
                    })), React.createElement(Core.Grid, {
                  alignItems: "center",
                  children: null,
                  container: true,
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  wrap: "nowrap"
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                            checked: form.input.policeNumberEmpty,
                            onClick: (function ($$event) {
                                Curry._2(form.updatePoliceNumberEmpty, (function (input, policeNumberEmpty) {
                                        return {
                                                brandUsedCar: input.brandUsedCar,
                                                brandEmpty: input.brandEmpty,
                                                modelUsedCar: input.modelUsedCar,
                                                modelEmpty: input.modelEmpty,
                                                vinUsedCar: input.vinUsedCar,
                                                vinEmpty: input.vinEmpty,
                                                usedCarPoliceNumber: input.usedCarPoliceNumber,
                                                policeNumberEmpty: policeNumberEmpty,
                                                usedCarLastRegistrationDateStart: input.usedCarLastRegistrationDateStart,
                                                usedCarLastRegistrationDateEnd: input.usedCarLastRegistrationDateEnd,
                                                registrationDateEmpty: input.registrationDateEmpty,
                                                usedCarFirstReleaseDateStart: input.usedCarFirstReleaseDateStart,
                                                usedCarFirstReleaseDateEnd: input.usedCarFirstReleaseDateEnd,
                                                releaseDateEmpty: input.releaseDateEmpty,
                                                usedCarRegistrationID: input.usedCarRegistrationID,
                                                registrationIDEmpty: input.registrationIDEmpty
                                              };
                                      }), $$event.target.checked);
                              })
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry._1(form.updateUsedCarPoliceNumber, (function (input, usedCarPoliceNumber) {
                                    return {
                                            brandUsedCar: input.brandUsedCar,
                                            brandEmpty: input.brandEmpty,
                                            modelUsedCar: input.modelUsedCar,
                                            modelEmpty: input.modelEmpty,
                                            vinUsedCar: input.vinUsedCar,
                                            vinEmpty: input.vinEmpty,
                                            usedCarPoliceNumber: usedCarPoliceNumber,
                                            policeNumberEmpty: input.policeNumberEmpty,
                                            usedCarLastRegistrationDateStart: input.usedCarLastRegistrationDateStart,
                                            usedCarLastRegistrationDateEnd: input.usedCarLastRegistrationDateEnd,
                                            registrationDateEmpty: input.registrationDateEmpty,
                                            usedCarFirstReleaseDateStart: input.usedCarFirstReleaseDateStart,
                                            usedCarFirstReleaseDateEnd: input.usedCarFirstReleaseDateEnd,
                                            releaseDateEmpty: input.releaseDateEmpty,
                                            usedCarRegistrationID: input.usedCarRegistrationID,
                                            registrationIDEmpty: input.registrationIDEmpty
                                          };
                                  })),
                            value: form.input.usedCarPoliceNumber,
                            disabled: form.input.policeNumberEmpty,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                            label: Messages_Orders_Detail$DvmAdminFrontendFr.reprPoliceNumber
                          }),
                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                      item: true
                    })));
}

var InputStyles;

var make = Order_Search_Modal_VO_Filters;

export {
  InputStyles ,
  make ,
}
/* react Not a pure module */
