// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function isInfoFinished(form) {
  if (Belt_Option.mapWithDefault(form.actionTypeResult, false, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && Belt_Option.mapWithDefault(form.actionLabelResult, false, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          }))) {
    if (form.input.orderType === /* VE */2) {
      return Belt_Option.mapWithDefault(form.isPARCExitResult, false, (function (x) {
                    if (x.TAG === /* Ok */0) {
                      return true;
                    } else {
                      return false;
                    }
                  }));
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function isPerfInfoFinished(form) {
  if (Belt_Option.mapWithDefault(form.actionTypeResult, false, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && Belt_Option.mapWithDefault(form.actionLabelResult, false, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          }))) {
    return Belt_Option.mapWithDefault(form.perfTypeResult, false, (function (x) {
                  if (x.TAG === /* Ok */0) {
                    return true;
                  } else {
                    return false;
                  }
                }));
  } else {
    return false;
  }
}

function isDetailREPRFinished(form, formGeneralDates) {
  if (Belt_Option.mapWithDefault(form.budgetTypeResult, false, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && Belt_Option.mapWithDefault(formGeneralDates.startDateResult, false, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && Belt_Option.mapWithDefault(formGeneralDates.endDateResult, false, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && Belt_Option.mapWithDefault(formGeneralDates.deliveryStartDateResult, true, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && Belt_Option.mapWithDefault(formGeneralDates.deliveryEndDateResult, true, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && Belt_Option.mapWithDefault(formGeneralDates.registrationStartDateResult, true, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && Belt_Option.mapWithDefault(formGeneralDates.registrationEndDateResult, true, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && Belt_Option.mapWithDefault(form.valueResult, false, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          }))) {
    return Belt_Option.mapWithDefault(form.numberTypeResult, false, (function (x) {
                  if (x.TAG === /* Ok */0) {
                    return true;
                  } else {
                    return false;
                  }
                }));
  } else {
    return false;
  }
}

function isDetailNonSOFinished(form, formGeneralDates) {
  if (form.input.orderType === /* VE */2) {
    if ((
        Belt_Option.getWithDefault(form.input.isPARCExit, false) ? Belt_Option.mapWithDefault(form.exitParcDatePeriodStartResult, false, (function (x) {
                  return x.TAG === /* Ok */0 ? true : false;
                })) && Belt_Option.mapWithDefault(form.exitParcDatePeriodEndResult, false, (function (x) {
                  return x.TAG === /* Ok */0 ? true : false;
                })) && Belt_Option.mapWithDefault(form.detentionMinResult, false, (function (x) {
                  return x.TAG === /* Ok */0 ? true : false;
                })) && Belt_Option.mapWithDefault(form.detentionMaxResult, false, (function (x) {
                  return x.TAG === /* Ok */0 ? true : false;
                })) : Belt_Option.mapWithDefault(form.entryParcDatePeriodStartResult, false, (function (x) {
                  return x.TAG === /* Ok */0 ? true : false;
                })) && Belt_Option.mapWithDefault(form.entryParcDatePeriodEndResult, false, (function (x) {
                  return x.TAG === /* Ok */0 ? true : false;
                }))
      ) && Belt_Option.mapWithDefault(form.valueResult, false, (function (x) {
              return x.TAG === /* Ok */0 ? true : false;
            }))) {
      return Belt_Option.mapWithDefault(form.numberTypeResult, false, (function (x) {
                    if (x.TAG === /* Ok */0) {
                      return true;
                    } else {
                      return false;
                    }
                  }));
    } else {
      return false;
    }
  } else if (Belt_Option.mapWithDefault(formGeneralDates.startDateResult, form.input.orderType === /* RR */4 || form.input.orderType === /* RC */5, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && Belt_Option.mapWithDefault(formGeneralDates.endDateResult, form.input.orderType === /* RR */4 || form.input.orderType === /* RC */5, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && Belt_Option.mapWithDefault(formGeneralDates.deliveryStartDateResult, true, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && Belt_Option.mapWithDefault(formGeneralDates.deliveryEndDateResult, true, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && Belt_Option.mapWithDefault(formGeneralDates.registrationStartDateResult, true, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && Belt_Option.mapWithDefault(formGeneralDates.registrationEndDateResult, true, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && Belt_Option.mapWithDefault(form.valueResult, false, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          }))) {
    return Belt_Option.mapWithDefault(form.numberTypeResult, false, (function (x) {
                  if (x.TAG === /* Ok */0) {
                    return true;
                  } else {
                    return false;
                  }
                }));
  } else {
    return false;
  }
}

function datesAreBothSome(start, end) {
  if (Belt_Option.isSome(start)) {
    return Belt_Option.isSome(end);
  } else {
    return false;
  }
}

function isDetailRRorRCFinished(form, formGeneralDates) {
  if (Belt_Option.mapWithDefault(form.entryParcDatePeriodStartResult, true, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && Belt_Option.mapWithDefault(form.entryParcDatePeriodEndResult, true, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && (
      Belt_Option.getWithDefault(form.input.isPARCExit, false) ? true : Belt_Option.mapWithDefault(form.detentionMinResult, true, (function (x) {
                return x.TAG === /* Ok */0 ? true : false;
              })) && Belt_Option.mapWithDefault(form.detentionMaxResult, true, (function (x) {
                return x.TAG === /* Ok */0 ? true : false;
              }))
    ) && Belt_Option.mapWithDefault(formGeneralDates.startDateResult, true, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && Belt_Option.mapWithDefault(formGeneralDates.endDateResult, true, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && Belt_Option.mapWithDefault(formGeneralDates.deliveryStartDateResult, true, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && Belt_Option.mapWithDefault(formGeneralDates.deliveryEndDateResult, true, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && Belt_Option.mapWithDefault(formGeneralDates.registrationStartDateResult, false, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && Belt_Option.mapWithDefault(formGeneralDates.registrationEndDateResult, false, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          })) && Belt_Option.mapWithDefault(form.valueResult, false, (function (x) {
            return x.TAG === /* Ok */0 ? true : false;
          }))) {
    return Belt_Option.mapWithDefault(form.numberTypeResult, false, (function (x) {
                  if (x.TAG === /* Ok */0) {
                    return true;
                  } else {
                    return false;
                  }
                }));
  } else {
    return false;
  }
}

var Form;

var FormGeneralDates;

export {
  Form ,
  FormGeneralDates ,
  isInfoFinished ,
  isPerfInfoFinished ,
  isDetailREPRFinished ,
  isDetailNonSOFinished ,
  datesAreBothSome ,
  isDetailRRorRCFinished ,
}
/* No side effect */
