// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../common/App_Typography.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../../../../common/components/App_LabeledText.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Result.bs.js";
import * as Messages_Flash_Search$DvmAdminFrontendFr from "../../../../../../intl/messages/flash/Messages_Flash_Search.bs.js";
import * as Messages_Orders_Derogate$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_Derogate.bs.js";
import * as Order_Detail_Bonus_Derogate_Create_Api$DvmAdminFrontendFr from "../../derogate/create/Order_Detail_Bonus_Derogate_Create_Api.bs.js";

function Order_Detail_Bonus_DealerRequest_Action_Info(Props) {
  var orderType = Props.orderType;
  var actionId = Props.actionId;
  var actionFetch = Props.actionFetch;
  var setActionFetch = Props.setActionFetch;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        
      });
  var setActionInfo = match$2[1];
  var actionInfo = match$2[0];
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.mapWithDefault(actionFetch, undefined, (function (x) {
                  Belt_Option.mapWithDefault(x, undefined, (function (action) {
                          Curry._1(setActionInfo, (function (param) {
                                  return {
                                          actionId: action.actionID,
                                          actionIdLabel: action.actionLabel,
                                          actionBudget: action.budgetTypes
                                        };
                                }));
                        }));
                }));
        }), [actionFetch]);
  React.useEffect((function () {
          if (actionId.length > 0) {
            Order_Detail_Bonus_Derogate_Create_Api$DvmAdminFrontendFr.fetchOneAction(userManager, setActionFetch, selectedRole, actionId, orderType);
          }
          
        }), [actionId]);
  var actionInfo$1 = function (param) {
    return Belt_Option.getWithDefault(actionInfo, {
                actionId: "-",
                actionIdLabel: undefined,
                actionBudget: undefined
              });
  };
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              item: true
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        gutterBottom: true,
                        style: {
                          fontWeight: "600"
                        },
                        children: intl.formatMessage(Messages_Orders_Derogate$DvmAdminFrontendFr.derogateActionInfo),
                        variant: "h5"
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  justifyContent: "space-evenly",
                  spacing: 1
                }, orderType !== 0 ? null : React.createElement(Core.Grid, {
                        children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Derogate$DvmAdminFrontendFr.budgetDero,
                              children: Belt_Option.getWithDefault(actionInfo$1(undefined).actionBudget, "-")
                            }),
                        item: true
                      }), React.createElement(Core.Grid, {
                      children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                            label: Messages_Flash_Search$DvmAdminFrontendFr.flashMarketingActionID,
                            children: actionInfo$1(undefined).actionId
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                            label: Messages_Flash_Search$DvmAdminFrontendFr.bonusLabel,
                            children: Belt_Option.getWithDefault(actionInfo$1(undefined).actionIdLabel, "-")
                          }),
                      item: true
                    })));
}

var Form;

var Api;

var Types;

var make = Order_Detail_Bonus_DealerRequest_Action_Info;

export {
  Form ,
  Api ,
  Types ,
  make ,
}
/* react Not a pure module */
