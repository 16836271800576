// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Css from "@emotion/css";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";

var table = Css.css(Object.assign({}, {
          whiteSpace: "nowrap"
        }, {
          "&::-webkit-scrollbar": {
            background: Theme_Colors$DvmAdminFrontendFr.white,
            width: "10px",
            height: "10px"
          },
          "&::-webkit-scrollbar-thumb": {
            background: Theme_Colors$DvmAdminFrontendFr.primaryBrown
          }
        }));

function tableRowDisabled(theme) {
  return Css.css({
              backgroundColor: theme.palette.grey[200] + "!important"
            });
}

function tableCell(rowPadding, rowHeight) {
  return Css.css(Object.assign({}, {
                  height: Belt_Option.getWithDefault(rowHeight, "45px"),
                  padding: Belt_Option.getWithDefault(rowPadding, "0px 0px 0px 0px")
                }, {
                  "&:first-child > div": {
                    padding: "12px 5px 12px 16px"
                  },
                  "&:last-child > div": {
                    padding: "12px 16px 12px 5px"
                  }
                }));
}

var tableCellContent = Css.css({
      height: "45px",
      padding: "12px 5px"
    });

function tableCellDisabled(theme) {
  return Css.css({
              color: theme.palette.grey[600]
            });
}

export {
  table ,
  tableRowDisabled ,
  tableCell ,
  tableCellContent ,
  tableCellDisabled ,
}
/* table Not a pure module */
