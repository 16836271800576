// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../common/utils/Utils_Form.bs.js";
import * as Submarket_Types$DvmAdminFrontendFr from "../Submarket_Types.bs.js";

var validators_submarketCode = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.submarketCode);
    })
};

var validators_orderType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.orderType);
    })
};

var validators_budgetActionType = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      if (param.budgetActionDefault && param.orderType !== "SO") {
        return {
                TAG: /* Ok */0,
                _0: Submarket_Types$DvmAdminFrontendFr.submarketDefault
              };
      } else {
        return Utils_Form$DvmAdminFrontendFr.validateRequired(param.budgetActionType);
      }
    })
};

var validators = {
  submarketCode: validators_submarketCode,
  orderType: validators_orderType,
  budgetActionType: validators_budgetActionType,
  budgetActionDefault: undefined
};

function initialFieldsStatuses(_input) {
  return {
          submarketCode: /* Pristine */0,
          orderType: /* Pristine */0,
          budgetActionType: /* Pristine */0,
          budgetActionDefault: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            submarketCode: /* Pristine */0,
            orderType: /* Pristine */0,
            budgetActionType: /* Pristine */0,
            budgetActionDefault: /* Pristine */0
          },
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.submarketCode;
  var match_0 = match ? match._0 : Curry._1(validators.submarketCode.validate, input);
  var match$1 = fieldsStatuses.orderType;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.orderType.validate, input);
  var match$2 = fieldsStatuses.budgetActionType;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.budgetActionType.validate, input);
  var match_0$3 = {
    TAG: /* Ok */0,
    _0: input.budgetActionDefault
  };
  var submarketCodeResult = match_0;
  var submarketCodeResult$1;
  if (submarketCodeResult.TAG === /* Ok */0) {
    var orderTypeResult = match_0$1;
    if (orderTypeResult.TAG === /* Ok */0) {
      var budgetActionTypeResult = match_0$2;
      if (budgetActionTypeResult.TAG === /* Ok */0) {
        var budgetActionDefaultResult = match_0$3;
        if (budgetActionDefaultResult.TAG === /* Ok */0) {
          return {
                  TAG: /* Valid */0,
                  output: {
                    submarketCode: submarketCodeResult._0,
                    orderType: orderTypeResult._0,
                    budgetActionType: budgetActionTypeResult._0,
                    budgetActionDefault: budgetActionDefaultResult._0
                  },
                  fieldsStatuses: {
                    submarketCode: /* Dirty */{
                      _0: submarketCodeResult,
                      _1: /* Shown */0
                    },
                    orderType: /* Dirty */{
                      _0: orderTypeResult,
                      _1: /* Shown */0
                    },
                    budgetActionType: /* Dirty */{
                      _0: budgetActionTypeResult,
                      _1: /* Shown */0
                    },
                    budgetActionDefault: /* Dirty */{
                      _0: budgetActionDefaultResult,
                      _1: /* Hidden */1
                    }
                  },
                  collectionsStatuses: undefined
                };
        }
        submarketCodeResult$1 = submarketCodeResult;
      } else {
        submarketCodeResult$1 = submarketCodeResult;
      }
    } else {
      submarketCodeResult$1 = submarketCodeResult;
    }
  } else {
    submarketCodeResult$1 = submarketCodeResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            submarketCode: /* Dirty */{
              _0: submarketCodeResult$1,
              _1: /* Shown */0
            },
            orderType: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            budgetActionType: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            budgetActionDefault: /* Dirty */{
              _0: match_0$3,
              _1: /* Hidden */1
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurSubmarketCodeField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.submarketCode, validators_submarketCode, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  submarketCode: status,
                                  orderType: init.orderType,
                                  budgetActionType: init.budgetActionType,
                                  budgetActionDefault: init.budgetActionDefault
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurOrderTypeField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.orderType, validators_orderType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  submarketCode: init.submarketCode,
                                  orderType: status,
                                  budgetActionType: init.budgetActionType,
                                  budgetActionDefault: init.budgetActionDefault
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBudgetActionTypeField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.budgetActionType, validators_budgetActionType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  submarketCode: init.submarketCode,
                                  orderType: init.orderType,
                                  budgetActionType: status,
                                  budgetActionDefault: init.budgetActionDefault
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBudgetActionDefaultField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithoutValidator(state.input.budgetActionDefault, state.fieldsStatuses.budgetActionDefault, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  submarketCode: init.submarketCode,
                                  orderType: init.orderType,
                                  budgetActionType: init.budgetActionType,
                                  budgetActionDefault: status
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */4 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */4,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */5,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */7);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */6);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */5 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */6 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */7 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateSubmarketCodeField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.submarketCode, state.submissionStatus, validators_submarketCode, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            submarketCode: status,
                                            orderType: init.orderType,
                                            budgetActionType: init.budgetActionType,
                                            budgetActionDefault: init.budgetActionDefault
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateOrderTypeField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$4 = Formality.validateDependentFieldOnChange(nextInput$1, nextFieldsStatuses.contents.budgetActionType, validators_budgetActionType, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  submarketCode: init.submarketCode,
                                  orderType: init.orderType,
                                  budgetActionType: status,
                                  budgetActionDefault: init.budgetActionDefault
                                };
                        }));
                  if (result$4 !== undefined) {
                    nextFieldsStatuses.contents = result$4;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, nextFieldsStatuses.contents.orderType, state.submissionStatus, validators_orderType, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            submarketCode: init.submarketCode,
                                            orderType: status,
                                            budgetActionType: init.budgetActionType,
                                            budgetActionDefault: init.budgetActionDefault
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBudgetActionTypeField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.budgetActionType, state.submissionStatus, validators_budgetActionType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            submarketCode: init.submarketCode,
                                            orderType: init.orderType,
                                            budgetActionType: status,
                                            budgetActionDefault: init.budgetActionDefault
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBudgetActionDefaultField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$5 = Formality.validateDependentFieldOnChange(nextInput$3, nextFieldsStatuses$1.contents.budgetActionType, validators_budgetActionType, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  submarketCode: init.submarketCode,
                                  orderType: init.orderType,
                                  budgetActionType: status,
                                  budgetActionDefault: init.budgetActionDefault
                                };
                        }));
                  if (result$5 !== undefined) {
                    nextFieldsStatuses$1.contents = result$5;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$3.budgetActionDefault, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            submarketCode: init.submarketCode,
                                            orderType: init.orderType,
                                            budgetActionType: init.budgetActionType,
                                            budgetActionDefault: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */4 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                submarketCode: /* Pristine */0,
                                orderType: /* Pristine */0,
                                budgetActionType: /* Pristine */0,
                                budgetActionDefault: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                submarketCode: /* Pristine */0,
                                orderType: /* Pristine */0,
                                budgetActionType: /* Pristine */0,
                                budgetActionDefault: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */5 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */6 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateSubmarketCode: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSubmarketCodeField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateOrderType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrderTypeField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBudgetActionType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBudgetActionTypeField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBudgetActionDefault: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBudgetActionDefaultField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurSubmarketCode: (function (param) {
              Curry._1(dispatch, /* BlurSubmarketCodeField */0);
            }),
          blurOrderType: (function (param) {
              Curry._1(dispatch, /* BlurOrderTypeField */1);
            }),
          blurBudgetActionType: (function (param) {
              Curry._1(dispatch, /* BlurBudgetActionTypeField */2);
            }),
          blurBudgetActionDefault: (function (param) {
              Curry._1(dispatch, /* BlurBudgetActionDefaultField */3);
            }),
          submarketCodeResult: Formality.exposeFieldResult(state.fieldsStatuses.submarketCode),
          orderTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.orderType),
          budgetActionTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.budgetActionType),
          budgetActionDefaultResult: Formality.exposeFieldResult(state.fieldsStatuses.budgetActionDefault),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.submarketCode || match.orderType || match.budgetActionType || match.budgetActionDefault) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */4);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */5);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */6);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */6,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */7);
            })
        };
}

var Form = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialInput = {
  submarketCode: "",
  orderType: "",
  budgetActionType: "",
  budgetActionDefault: false
};

export {
  Form ,
  initialInput ,
}
/* react Not a pure module */
