// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Form.bs.js";
import * as Quota_Detail_Utils$DvmAdminFrontendFr from "../../Quota_Detail_Utils.bs.js";
import * as Messages_Contract_Create$DvmAdminFrontendFr from "../../../../../intl/messages/contract/Messages_Contract_Create.bs.js";

var validators = {
  attachments: {
    collection: (function (param, _metadata) {
        if (param.attachments.length !== 0) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Messages_Contract_Create$DvmAdminFrontendFr.noAttachment
                };
        }
      }),
    fields: {
      volumeOrigin: undefined,
      siret: {
        strategy: /* OnFirstSuccessOrFirstBlur */3,
        validate: (function (param, at, param$1) {
            return Utils_Form$DvmAdminFrontendFr.validateRequired(Belt_Option.mapWithDefault(Belt_Array.get(param.attachments, at), "", (function (attachment) {
                              return attachment.siret;
                            })));
          })
      },
      companyName: undefined,
      dealerId: {
        strategy: /* OnFirstSuccessOrFirstBlur */3,
        validate: (function (param, at, param$1) {
            return Utils_Form$DvmAdminFrontendFr.validateRequired(Belt_Option.mapWithDefault(Belt_Array.get(param.attachments, at), "", (function (attachment) {
                              return attachment.dealerId;
                            })));
          })
      },
      volume: {
        strategy: /* OnFirstSuccessOrFirstBlur */3,
        validate: (function (param, at, param$1) {
            var value = Utils_Form$DvmAdminFrontendFr.validateInteger(Belt_Option.mapWithDefault(Belt_Array.get(param.attachments, at), "", (function (attachment) {
                        return attachment.volume;
                      })));
            if (value.TAG === /* Ok */0) {
              return Utils_Form$DvmAdminFrontendFr.validateIntegerPositiveRequired(value._0);
            } else {
              return {
                      TAG: /* Error */1,
                      _0: value._0
                    };
            }
          })
      },
      attachmentStartDate: {
        strategy: /* OnFirstSuccessOrFirstBlur */3,
        validate: (function (param, at, param$1) {
            var att = Belt_Array.get(param.attachments, at);
            return Quota_Detail_Utils$DvmAdminFrontendFr.validateRequiredDateCompare(Belt_Option.mapWithDefault(att, undefined, (function (attachment) {
                              return attachment.attachmentStartDate;
                            })), Belt_Option.mapWithDefault(att, undefined, (function (attachment) {
                              return attachment.attachmentEndDate;
                            })), param$1.rangeStartDate, undefined, "smaller");
          })
      },
      attachmentOldStartDate: undefined,
      attachmentEndDate: {
        strategy: /* OnFirstSuccessOrFirstBlur */3,
        validate: (function (param, at, param$1) {
            var att = Belt_Array.get(param.attachments, at);
            return Quota_Detail_Utils$DvmAdminFrontendFr.validateRequiredDateCompare(Belt_Option.mapWithDefault(att, undefined, (function (attachment) {
                              return attachment.attachmentStartDate;
                            })), Belt_Option.mapWithDefault(att, undefined, (function (attachment) {
                              return attachment.attachmentEndDate;
                            })), undefined, param$1.rangeEndDate, "larger");
          })
      },
      dirty: undefined
    }
  }
};

function initialFieldsStatuses(input) {
  return {
          attachments: Belt_Array.make(input.attachments.length, {
                volumeOrigin: /* Pristine */0,
                siret: /* Pristine */0,
                companyName: /* Pristine */0,
                dealerId: /* Pristine */0,
                volume: /* Pristine */0,
                attachmentStartDate: /* Pristine */0,
                attachmentOldStartDate: /* Pristine */0,
                attachmentEndDate: /* Pristine */0,
                dirty: /* Pristine */0
              })
        };
}

var initialCollectionsStatuses = {
  attachments: undefined
};

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: initialCollectionsStatuses,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match = Curry._2(validators.attachments.collection, input, metadata);
  var match$1 = Belt_Array.reduceWithIndex(fieldsStatuses.attachments, [
        {
          TAG: /* Ok */0,
          _0: []
        },
        []
      ], (function (param, fieldStatus, index) {
          var statuses = param[1];
          var output = param[0];
          var match_0 = {
            TAG: /* Ok */0,
            _0: input.attachments[index].volumeOrigin
          };
          var match = fieldStatus.siret;
          var match_0$1 = match ? match._0 : Curry._3(validators.attachments.fields.siret.validate, input, index, metadata);
          var match_0$2 = {
            TAG: /* Ok */0,
            _0: input.attachments[index].companyName
          };
          var match$1 = fieldStatus.dealerId;
          var match_0$3 = match$1 ? match$1._0 : Curry._3(validators.attachments.fields.dealerId.validate, input, index, metadata);
          var match$2 = fieldStatus.volume;
          var match_0$4 = match$2 ? match$2._0 : Curry._3(validators.attachments.fields.volume.validate, input, index, metadata);
          var match$3 = fieldStatus.attachmentStartDate;
          var match_0$5 = match$3 ? match$3._0 : Curry._3(validators.attachments.fields.attachmentStartDate.validate, input, index, metadata);
          var match_0$6 = {
            TAG: /* Ok */0,
            _0: input.attachments[index].attachmentOldStartDate
          };
          var match$4 = fieldStatus.attachmentEndDate;
          var match_0$7 = match$4 ? match$4._0 : Curry._3(validators.attachments.fields.attachmentEndDate.validate, input, index, metadata);
          var match_0$8 = {
            TAG: /* Ok */0,
            _0: input.attachments[index].dirty
          };
          if (output.TAG === /* Ok */0) {
            var volumeOriginResult = match_0;
            var output$1 = output._0;
            if (volumeOriginResult.TAG === /* Ok */0) {
              var siretResult = match_0$1;
              if (siretResult.TAG === /* Ok */0) {
                var companyNameResult = match_0$2;
                if (companyNameResult.TAG === /* Ok */0) {
                  var dealerIdResult = match_0$3;
                  if (dealerIdResult.TAG === /* Ok */0) {
                    var volumeResult = match_0$4;
                    if (volumeResult.TAG === /* Ok */0) {
                      var attachmentStartDateResult = match_0$5;
                      if (attachmentStartDateResult.TAG === /* Ok */0) {
                        var attachmentOldStartDateResult = match_0$6;
                        if (attachmentOldStartDateResult.TAG === /* Ok */0) {
                          var attachmentEndDateResult = match_0$7;
                          if (attachmentEndDateResult.TAG === /* Ok */0) {
                            var dirtyResult = match_0$8;
                            if (dirtyResult.TAG === /* Ok */0) {
                              output$1.push({
                                    volumeOrigin: volumeOriginResult._0,
                                    siret: siretResult._0,
                                    companyName: companyNameResult._0,
                                    dealerId: dealerIdResult._0,
                                    volume: volumeResult._0,
                                    attachmentStartDate: attachmentStartDateResult._0,
                                    attachmentOldStartDate: attachmentOldStartDateResult._0,
                                    attachmentEndDate: attachmentEndDateResult._0,
                                    dirty: dirtyResult._0
                                  });
                              statuses.push({
                                    volumeOrigin: /* Dirty */{
                                      _0: volumeOriginResult,
                                      _1: /* Hidden */1
                                    },
                                    siret: /* Dirty */{
                                      _0: siretResult,
                                      _1: /* Shown */0
                                    },
                                    companyName: /* Dirty */{
                                      _0: companyNameResult,
                                      _1: /* Hidden */1
                                    },
                                    dealerId: /* Dirty */{
                                      _0: dealerIdResult,
                                      _1: /* Shown */0
                                    },
                                    volume: /* Dirty */{
                                      _0: volumeResult,
                                      _1: /* Shown */0
                                    },
                                    attachmentStartDate: /* Dirty */{
                                      _0: attachmentStartDateResult,
                                      _1: /* Shown */0
                                    },
                                    attachmentOldStartDate: /* Dirty */{
                                      _0: attachmentOldStartDateResult,
                                      _1: /* Hidden */1
                                    },
                                    attachmentEndDate: /* Dirty */{
                                      _0: attachmentEndDateResult,
                                      _1: /* Shown */0
                                    },
                                    dirty: /* Dirty */{
                                      _0: dirtyResult,
                                      _1: /* Hidden */1
                                    }
                                  });
                              return [
                                      {
                                        TAG: /* Ok */0,
                                        _0: output$1
                                      },
                                      statuses
                                    ];
                            }
                            
                          }
                          
                        }
                        
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }
            
          }
          statuses.push({
                volumeOrigin: /* Dirty */{
                  _0: match_0,
                  _1: /* Hidden */1
                },
                siret: /* Dirty */{
                  _0: match_0$1,
                  _1: /* Shown */0
                },
                companyName: /* Dirty */{
                  _0: match_0$2,
                  _1: /* Hidden */1
                },
                dealerId: /* Dirty */{
                  _0: match_0$3,
                  _1: /* Shown */0
                },
                volume: /* Dirty */{
                  _0: match_0$4,
                  _1: /* Shown */0
                },
                attachmentStartDate: /* Dirty */{
                  _0: match_0$5,
                  _1: /* Shown */0
                },
                attachmentOldStartDate: /* Dirty */{
                  _0: match_0$6,
                  _1: /* Hidden */1
                },
                attachmentEndDate: /* Dirty */{
                  _0: match_0$7,
                  _1: /* Shown */0
                },
                dirty: /* Dirty */{
                  _0: match_0$8,
                  _1: /* Hidden */1
                }
              });
          return [
                  {
                    TAG: /* Error */1,
                    _0: undefined
                  },
                  statuses
                ];
        }));
  if (match.TAG === /* Ok */0) {
    var attachments = match$1[0];
    if (attachments.TAG === /* Ok */0) {
      return {
              TAG: /* Valid */0,
              output: {
                attachments: attachments._0
              },
              fieldsStatuses: {
                attachments: match$1[1]
              },
              collectionsStatuses: {
                attachments: match
              }
            };
    }
    
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            attachments: match$1[1]
          },
          collectionsStatuses: {
            attachments: match
          }
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* Submit */0 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */20,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */21,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */3);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */2);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */1 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */2 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */3 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateAttachmentVolumeOriginField */0 :
                  var index = action._1;
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput.attachments[index].volumeOrigin, (function (status) {
                                    return {
                                            attachments: Belt_Array.mapWithIndex(state.fieldsStatuses.attachments, (function (index$p, item) {
                                                    if (index$p === index) {
                                                      return {
                                                              volumeOrigin: status,
                                                              siret: item.siret,
                                                              companyName: item.companyName,
                                                              dealerId: item.dealerId,
                                                              volume: item.volume,
                                                              attachmentStartDate: item.attachmentStartDate,
                                                              attachmentOldStartDate: item.attachmentOldStartDate,
                                                              attachmentEndDate: item.attachmentEndDate,
                                                              dirty: item.dirty
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAttachmentSiretField */1 :
                  var index$1 = action._1;
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidatorAndMetadata(nextInput$1, index$1, metadata, state.fieldsStatuses.attachments[index$1].siret, state.submissionStatus, validators.attachments.fields.siret, (function (status) {
                                    return {
                                            attachments: Belt_Array.mapWithIndex(state.fieldsStatuses.attachments, (function (index$p, item) {
                                                    if (index$p === index$1) {
                                                      return {
                                                              volumeOrigin: item.volumeOrigin,
                                                              siret: status,
                                                              companyName: item.companyName,
                                                              dealerId: item.dealerId,
                                                              volume: item.volume,
                                                              attachmentStartDate: item.attachmentStartDate,
                                                              attachmentOldStartDate: item.attachmentOldStartDate,
                                                              attachmentEndDate: item.attachmentEndDate,
                                                              dirty: item.dirty
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAttachmentCompanyNameField */2 :
                  var index$2 = action._1;
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$2.attachments[index$2].companyName, (function (status) {
                                    return {
                                            attachments: Belt_Array.mapWithIndex(state.fieldsStatuses.attachments, (function (index$p, item) {
                                                    if (index$p === index$2) {
                                                      return {
                                                              volumeOrigin: item.volumeOrigin,
                                                              siret: item.siret,
                                                              companyName: status,
                                                              dealerId: item.dealerId,
                                                              volume: item.volume,
                                                              attachmentStartDate: item.attachmentStartDate,
                                                              attachmentOldStartDate: item.attachmentOldStartDate,
                                                              attachmentEndDate: item.attachmentEndDate,
                                                              dirty: item.dirty
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAttachmentDealerIdField */3 :
                  var index$3 = action._1;
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidatorAndMetadata(nextInput$3, index$3, metadata, state.fieldsStatuses.attachments[index$3].dealerId, state.submissionStatus, validators.attachments.fields.dealerId, (function (status) {
                                    return {
                                            attachments: Belt_Array.mapWithIndex(state.fieldsStatuses.attachments, (function (index$p, item) {
                                                    if (index$p === index$3) {
                                                      return {
                                                              volumeOrigin: item.volumeOrigin,
                                                              siret: item.siret,
                                                              companyName: item.companyName,
                                                              dealerId: status,
                                                              volume: item.volume,
                                                              attachmentStartDate: item.attachmentStartDate,
                                                              attachmentOldStartDate: item.attachmentOldStartDate,
                                                              attachmentEndDate: item.attachmentEndDate,
                                                              dirty: item.dirty
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAttachmentVolumeField */4 :
                  var index$4 = action._1;
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidatorAndMetadata(nextInput$4, index$4, metadata, state.fieldsStatuses.attachments[index$4].volume, state.submissionStatus, validators.attachments.fields.volume, (function (status) {
                                    return {
                                            attachments: Belt_Array.mapWithIndex(state.fieldsStatuses.attachments, (function (index$p, item) {
                                                    if (index$p === index$4) {
                                                      return {
                                                              volumeOrigin: item.volumeOrigin,
                                                              siret: item.siret,
                                                              companyName: item.companyName,
                                                              dealerId: item.dealerId,
                                                              volume: status,
                                                              attachmentStartDate: item.attachmentStartDate,
                                                              attachmentOldStartDate: item.attachmentOldStartDate,
                                                              attachmentEndDate: item.attachmentEndDate,
                                                              dirty: item.dirty
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAttachmentAttachmentStartDateField */5 :
                  var index$5 = action._1;
                  var nextInput$5 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses.contents.attachments, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$5, index$p, metadata, item.attachmentEndDate, validators.attachments.fields.attachmentEndDate, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            volumeOrigin: item.volumeOrigin,
                                                            siret: item.siret,
                                                            companyName: item.companyName,
                                                            dealerId: item.dealerId,
                                                            volume: item.volume,
                                                            attachmentStartDate: item.attachmentStartDate,
                                                            attachmentOldStartDate: item.attachmentOldStartDate,
                                                            attachmentEndDate: status,
                                                            dirty: item.dirty
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidatorAndMetadata(nextInput$5, index$5, metadata, nextFieldsStatuses.contents.attachments[index$5].attachmentStartDate, state.submissionStatus, validators.attachments.fields.attachmentStartDate, (function (status) {
                                    return {
                                            attachments: Belt_Array.mapWithIndex(nextFieldsStatuses.contents.attachments, (function (idx_, item) {
                                                    if (idx_ === index$5) {
                                                      return {
                                                              volumeOrigin: item.volumeOrigin,
                                                              siret: item.siret,
                                                              companyName: item.companyName,
                                                              dealerId: item.dealerId,
                                                              volume: item.volume,
                                                              attachmentStartDate: status,
                                                              attachmentOldStartDate: item.attachmentOldStartDate,
                                                              attachmentEndDate: item.attachmentEndDate,
                                                              dirty: item.dirty
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAttachmentAttachmentOldStartDateField */6 :
                  var index$6 = action._1;
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$6.attachments[index$6].attachmentOldStartDate, (function (status) {
                                    return {
                                            attachments: Belt_Array.mapWithIndex(state.fieldsStatuses.attachments, (function (index$p, item) {
                                                    if (index$p === index$6) {
                                                      return {
                                                              volumeOrigin: item.volumeOrigin,
                                                              siret: item.siret,
                                                              companyName: item.companyName,
                                                              dealerId: item.dealerId,
                                                              volume: item.volume,
                                                              attachmentStartDate: item.attachmentStartDate,
                                                              attachmentOldStartDate: status,
                                                              attachmentEndDate: item.attachmentEndDate,
                                                              dirty: item.dirty
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAttachmentAttachmentEndDateField */7 :
                  var index$7 = action._1;
                  var nextInput$7 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$1.contents.attachments, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$7, index$p, metadata, item.attachmentStartDate, validators.attachments.fields.attachmentStartDate, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$1.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            volumeOrigin: item.volumeOrigin,
                                                            siret: item.siret,
                                                            companyName: item.companyName,
                                                            dealerId: item.dealerId,
                                                            volume: item.volume,
                                                            attachmentStartDate: status,
                                                            attachmentOldStartDate: item.attachmentOldStartDate,
                                                            attachmentEndDate: item.attachmentEndDate,
                                                            dirty: item.dirty
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$1.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidatorAndMetadata(nextInput$7, index$7, metadata, nextFieldsStatuses$1.contents.attachments[index$7].attachmentEndDate, state.submissionStatus, validators.attachments.fields.attachmentEndDate, (function (status) {
                                    return {
                                            attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$1.contents.attachments, (function (idx_, item) {
                                                    if (idx_ === index$7) {
                                                      return {
                                                              volumeOrigin: item.volumeOrigin,
                                                              siret: item.siret,
                                                              companyName: item.companyName,
                                                              dealerId: item.dealerId,
                                                              volume: item.volume,
                                                              attachmentStartDate: item.attachmentStartDate,
                                                              attachmentOldStartDate: item.attachmentOldStartDate,
                                                              attachmentEndDate: status,
                                                              dirty: item.dirty
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAttachmentDirtyField */8 :
                  var index$8 = action._1;
                  var nextInput$8 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$8.attachments[index$8].dirty, (function (status) {
                                    return {
                                            attachments: Belt_Array.mapWithIndex(state.fieldsStatuses.attachments, (function (index$p, item) {
                                                    if (index$p === index$8) {
                                                      return {
                                                              volumeOrigin: item.volumeOrigin,
                                                              siret: item.siret,
                                                              companyName: item.companyName,
                                                              dealerId: item.dealerId,
                                                              volume: item.volume,
                                                              attachmentStartDate: item.attachmentStartDate,
                                                              attachmentOldStartDate: item.attachmentOldStartDate,
                                                              attachmentEndDate: item.attachmentEndDate,
                                                              dirty: status
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* BlurAttachmentVolumeOriginField */9 :
                  var index$9 = action._0;
                  var result = Formality.validateFieldOnBlurWithoutValidator(state.input.attachments[index$9].volumeOrigin, state.fieldsStatuses.attachments[index$9].volumeOrigin, (function (status) {
                          return {
                                  attachments: Belt_Array.mapWithIndex(state.fieldsStatuses.attachments, (function (index$p, item) {
                                          if (index$p === index$9) {
                                            return {
                                                    volumeOrigin: status,
                                                    siret: item.siret,
                                                    companyName: item.companyName,
                                                    dealerId: item.dealerId,
                                                    volume: item.volume,
                                                    attachmentStartDate: item.attachmentStartDate,
                                                    attachmentOldStartDate: item.attachmentOldStartDate,
                                                    attachmentEndDate: item.attachmentEndDate,
                                                    dirty: item.dirty
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAttachmentSiretField */10 :
                  var index$10 = action._0;
                  var result$1 = Formality.validateFieldOfCollectionOnBlurWithValidatorAndMetadata(state.input, index$10, metadata, state.fieldsStatuses.attachments[index$10].siret, validators.attachments.fields.siret, (function (status) {
                          return {
                                  attachments: Belt_Array.mapWithIndex(state.fieldsStatuses.attachments, (function (index$p, item) {
                                          if (index$p === index$10) {
                                            return {
                                                    volumeOrigin: item.volumeOrigin,
                                                    siret: status,
                                                    companyName: item.companyName,
                                                    dealerId: item.dealerId,
                                                    volume: item.volume,
                                                    attachmentStartDate: item.attachmentStartDate,
                                                    attachmentOldStartDate: item.attachmentOldStartDate,
                                                    attachmentEndDate: item.attachmentEndDate,
                                                    dirty: item.dirty
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAttachmentCompanyNameField */11 :
                  var index$11 = action._0;
                  var result$2 = Formality.validateFieldOnBlurWithoutValidator(state.input.attachments[index$11].companyName, state.fieldsStatuses.attachments[index$11].companyName, (function (status) {
                          return {
                                  attachments: Belt_Array.mapWithIndex(state.fieldsStatuses.attachments, (function (index$p, item) {
                                          if (index$p === index$11) {
                                            return {
                                                    volumeOrigin: item.volumeOrigin,
                                                    siret: item.siret,
                                                    companyName: status,
                                                    dealerId: item.dealerId,
                                                    volume: item.volume,
                                                    attachmentStartDate: item.attachmentStartDate,
                                                    attachmentOldStartDate: item.attachmentOldStartDate,
                                                    attachmentEndDate: item.attachmentEndDate,
                                                    dirty: item.dirty
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAttachmentDealerIdField */12 :
                  var index$12 = action._0;
                  var result$3 = Formality.validateFieldOfCollectionOnBlurWithValidatorAndMetadata(state.input, index$12, metadata, state.fieldsStatuses.attachments[index$12].dealerId, validators.attachments.fields.dealerId, (function (status) {
                          return {
                                  attachments: Belt_Array.mapWithIndex(state.fieldsStatuses.attachments, (function (index$p, item) {
                                          if (index$p === index$12) {
                                            return {
                                                    volumeOrigin: item.volumeOrigin,
                                                    siret: item.siret,
                                                    companyName: item.companyName,
                                                    dealerId: status,
                                                    volume: item.volume,
                                                    attachmentStartDate: item.attachmentStartDate,
                                                    attachmentOldStartDate: item.attachmentOldStartDate,
                                                    attachmentEndDate: item.attachmentEndDate,
                                                    dirty: item.dirty
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAttachmentVolumeField */13 :
                  var index$13 = action._0;
                  var result$4 = Formality.validateFieldOfCollectionOnBlurWithValidatorAndMetadata(state.input, index$13, metadata, state.fieldsStatuses.attachments[index$13].volume, validators.attachments.fields.volume, (function (status) {
                          return {
                                  attachments: Belt_Array.mapWithIndex(state.fieldsStatuses.attachments, (function (index$p, item) {
                                          if (index$p === index$13) {
                                            return {
                                                    volumeOrigin: item.volumeOrigin,
                                                    siret: item.siret,
                                                    companyName: item.companyName,
                                                    dealerId: item.dealerId,
                                                    volume: status,
                                                    attachmentStartDate: item.attachmentStartDate,
                                                    attachmentOldStartDate: item.attachmentOldStartDate,
                                                    attachmentEndDate: item.attachmentEndDate,
                                                    dirty: item.dirty
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAttachmentAttachmentStartDateField */14 :
                  var index$14 = action._0;
                  var result$5 = Formality.validateFieldOfCollectionOnBlurWithValidatorAndMetadata(state.input, index$14, metadata, state.fieldsStatuses.attachments[index$14].attachmentStartDate, validators.attachments.fields.attachmentStartDate, (function (status) {
                          return {
                                  attachments: Belt_Array.mapWithIndex(state.fieldsStatuses.attachments, (function (index$p, item) {
                                          if (index$p === index$14) {
                                            return {
                                                    volumeOrigin: item.volumeOrigin,
                                                    siret: item.siret,
                                                    companyName: item.companyName,
                                                    dealerId: item.dealerId,
                                                    volume: item.volume,
                                                    attachmentStartDate: status,
                                                    attachmentOldStartDate: item.attachmentOldStartDate,
                                                    attachmentEndDate: item.attachmentEndDate,
                                                    dirty: item.dirty
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAttachmentAttachmentOldStartDateField */15 :
                  var index$15 = action._0;
                  var result$6 = Formality.validateFieldOnBlurWithoutValidator(state.input.attachments[index$15].attachmentOldStartDate, state.fieldsStatuses.attachments[index$15].attachmentOldStartDate, (function (status) {
                          return {
                                  attachments: Belt_Array.mapWithIndex(state.fieldsStatuses.attachments, (function (index$p, item) {
                                          if (index$p === index$15) {
                                            return {
                                                    volumeOrigin: item.volumeOrigin,
                                                    siret: item.siret,
                                                    companyName: item.companyName,
                                                    dealerId: item.dealerId,
                                                    volume: item.volume,
                                                    attachmentStartDate: item.attachmentStartDate,
                                                    attachmentOldStartDate: status,
                                                    attachmentEndDate: item.attachmentEndDate,
                                                    dirty: item.dirty
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAttachmentAttachmentEndDateField */16 :
                  var index$16 = action._0;
                  var result$7 = Formality.validateFieldOfCollectionOnBlurWithValidatorAndMetadata(state.input, index$16, metadata, state.fieldsStatuses.attachments[index$16].attachmentEndDate, validators.attachments.fields.attachmentEndDate, (function (status) {
                          return {
                                  attachments: Belt_Array.mapWithIndex(state.fieldsStatuses.attachments, (function (index$p, item) {
                                          if (index$p === index$16) {
                                            return {
                                                    volumeOrigin: item.volumeOrigin,
                                                    siret: item.siret,
                                                    companyName: item.companyName,
                                                    dealerId: item.dealerId,
                                                    volume: item.volume,
                                                    attachmentStartDate: item.attachmentStartDate,
                                                    attachmentOldStartDate: item.attachmentOldStartDate,
                                                    attachmentEndDate: status,
                                                    dirty: item.dirty
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAttachmentDirtyField */17 :
                  var index$17 = action._0;
                  var result$8 = Formality.validateFieldOnBlurWithoutValidator(state.input.attachments[index$17].dirty, state.fieldsStatuses.attachments[index$17].dirty, (function (status) {
                          return {
                                  attachments: Belt_Array.mapWithIndex(state.fieldsStatuses.attachments, (function (index$p, item) {
                                          if (index$p === index$17) {
                                            return {
                                                    volumeOrigin: item.volumeOrigin,
                                                    siret: item.siret,
                                                    companyName: item.companyName,
                                                    dealerId: item.dealerId,
                                                    volume: item.volume,
                                                    attachmentStartDate: item.attachmentStartDate,
                                                    attachmentOldStartDate: item.attachmentOldStartDate,
                                                    attachmentEndDate: item.attachmentEndDate,
                                                    dirty: status
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* AddAttachmentEntry */18 :
                  var nextInput$9 = {
                    attachments: Belt_Array.concat(state.input.attachments, [action._0])
                  };
                  var nextFieldsStatuses$2 = {
                    contents: {
                      attachments: Belt_Array.concat(state.fieldsStatuses.attachments, [{
                              volumeOrigin: /* Pristine */0,
                              siret: /* Pristine */0,
                              companyName: /* Pristine */0,
                              dealerId: /* Pristine */0,
                              volume: /* Pristine */0,
                              attachmentStartDate: /* Pristine */0,
                              attachmentOldStartDate: /* Pristine */0,
                              attachmentEndDate: /* Pristine */0,
                              dirty: /* Pristine */0
                            }])
                    }
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$2.contents.attachments, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$9, index$p, metadata, item.attachmentStartDate, validators.attachments.fields.attachmentStartDate, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$2.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            volumeOrigin: item.volumeOrigin,
                                                            siret: item.siret,
                                                            companyName: item.companyName,
                                                            dealerId: item.dealerId,
                                                            volume: item.volume,
                                                            attachmentStartDate: status,
                                                            attachmentOldStartDate: item.attachmentOldStartDate,
                                                            attachmentEndDate: item.attachmentEndDate,
                                                            dirty: item.dirty
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$2.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$2.contents.attachments, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$9, index$p, metadata, item.attachmentEndDate, validators.attachments.fields.attachmentEndDate, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$2.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            volumeOrigin: item.volumeOrigin,
                                                            siret: item.siret,
                                                            companyName: item.companyName,
                                                            dealerId: item.dealerId,
                                                            volume: item.volume,
                                                            attachmentStartDate: item.attachmentStartDate,
                                                            attachmentOldStartDate: item.attachmentOldStartDate,
                                                            attachmentEndDate: status,
                                                            dirty: item.dirty
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$2.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: nextFieldsStatuses$2.contents,
                            collectionsStatuses: {
                              attachments: Curry._2(validators.attachments.collection, nextInput$9, metadata)
                            },
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* RemoveAttachmentEntry */19 :
                  var index$18 = action._0;
                  var nextInput$10 = {
                    attachments: Belt_Array.keepWithIndex(state.input.attachments, (function (param, i) {
                            return i !== index$18;
                          }))
                  };
                  var nextFieldsStatuses$3 = {
                    contents: {
                      attachments: Belt_Array.keepWithIndex(state.fieldsStatuses.attachments, (function (param, i) {
                              return i !== index$18;
                            }))
                    }
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$3.contents.attachments, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$10, index$p, metadata, item.attachmentStartDate, validators.attachments.fields.attachmentStartDate, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$3.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            volumeOrigin: item.volumeOrigin,
                                                            siret: item.siret,
                                                            companyName: item.companyName,
                                                            dealerId: item.dealerId,
                                                            volume: item.volume,
                                                            attachmentStartDate: status,
                                                            attachmentOldStartDate: item.attachmentOldStartDate,
                                                            attachmentEndDate: item.attachmentEndDate,
                                                            dirty: item.dirty
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$3.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$3.contents.attachments, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChangeWithMetadata(nextInput$10, index$p, metadata, item.attachmentEndDate, validators.attachments.fields.attachmentEndDate, (function (status) {
                                  return {
                                          attachments: Belt_Array.mapWithIndex(nextFieldsStatuses$3.contents.attachments, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            volumeOrigin: item.volumeOrigin,
                                                            siret: item.siret,
                                                            companyName: item.companyName,
                                                            dealerId: item.dealerId,
                                                            volume: item.volume,
                                                            attachmentStartDate: item.attachmentStartDate,
                                                            attachmentOldStartDate: item.attachmentOldStartDate,
                                                            attachmentEndDate: status,
                                                            dirty: item.dirty
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$3.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: nextFieldsStatuses$3.contents,
                            collectionsStatuses: {
                              attachments: Curry._2(validators.attachments.collection, nextInput$10, metadata)
                            },
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */20 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */21 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */22 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Caml_option.some(Curry._1(map, Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateAttachmentVolumeOrigin: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAttachmentVolumeOriginField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateAttachmentSiret: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAttachmentSiretField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateAttachmentCompanyName: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAttachmentCompanyNameField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateAttachmentDealerId: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAttachmentDealerIdField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateAttachmentVolume: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAttachmentVolumeField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateAttachmentAttachmentStartDate: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAttachmentAttachmentStartDateField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateAttachmentAttachmentOldStartDate: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAttachmentAttachmentOldStartDateField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateAttachmentAttachmentEndDate: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAttachmentAttachmentEndDateField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateAttachmentDirty: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAttachmentDirtyField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          blurAttachmentVolumeOrigin: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurAttachmentVolumeOriginField */9,
                    _0: index
                  });
            }),
          blurAttachmentSiret: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurAttachmentSiretField */10,
                    _0: index
                  });
            }),
          blurAttachmentCompanyName: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurAttachmentCompanyNameField */11,
                    _0: index
                  });
            }),
          blurAttachmentDealerId: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurAttachmentDealerIdField */12,
                    _0: index
                  });
            }),
          blurAttachmentVolume: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurAttachmentVolumeField */13,
                    _0: index
                  });
            }),
          blurAttachmentAttachmentStartDate: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurAttachmentAttachmentStartDateField */14,
                    _0: index
                  });
            }),
          blurAttachmentAttachmentOldStartDate: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurAttachmentAttachmentOldStartDateField */15,
                    _0: index
                  });
            }),
          blurAttachmentAttachmentEndDate: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurAttachmentAttachmentEndDateField */16,
                    _0: index
                  });
            }),
          blurAttachmentDirty: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurAttachmentDirtyField */17,
                    _0: index
                  });
            }),
          attachmentVolumeOriginResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.attachments[index].volumeOrigin);
            }),
          attachmentSiretResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.attachments[index].siret);
            }),
          attachmentCompanyNameResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.attachments[index].companyName);
            }),
          attachmentDealerIdResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.attachments[index].dealerId);
            }),
          attachmentVolumeResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.attachments[index].volume);
            }),
          attachmentAttachmentStartDateResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.attachments[index].attachmentStartDate);
            }),
          attachmentAttachmentOldStartDateResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.attachments[index].attachmentOldStartDate);
            }),
          attachmentAttachmentEndDateResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.attachments[index].attachmentEndDate);
            }),
          attachmentDirtyResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.attachments[index].dirty);
            }),
          addAttachment: (function (entry) {
              Curry._1(dispatch, {
                    TAG: /* AddAttachmentEntry */18,
                    _0: entry
                  });
            }),
          removeAttachment: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* RemoveAttachmentEntry */19,
                    _0: index
                  });
            }),
          attachmentsResult: state.collectionsStatuses.attachments,
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              return Belt_Array.every(state.fieldsStatuses.attachments, (function (item) {
                            if (item.volumeOrigin || item.siret || item.companyName || item.dealerId || item.volume || item.attachmentStartDate || item.attachmentOldStartDate || item.attachmentEndDate || item.dirty) {
                              return true;
                            } else {
                              return false;
                            }
                          }));
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */0);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */1);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */2);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */22,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */3);
            })
        };
}

var TableDetailAttachmentForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: initialCollectionsStatuses,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialInput = {
  attachments: []
};

var initialState$1 = {
  volumeOrigin: 0,
  siret: "",
  companyName: "",
  dealerId: "",
  volume: "",
  attachmentStartDate: undefined,
  attachmentOldStartDate: undefined,
  attachmentEndDate: undefined,
  dirty: false
};

export {
  TableDetailAttachmentForm ,
  initialInput ,
  initialState$1 as initialState,
}
/* react Not a pure module */
