// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

var Fields = {
  submarketCode: "submarketCode",
  orderType: "orderType",
  budgetActionType: "budgetActionType"
};

function row_encode(v) {
  return Js_dict.fromArray([
              [
                "orderType",
                Decco.stringToJson(v.orderType)
              ],
              [
                "budgetActionType",
                Decco.stringToJson(v.budgetActionType)
              ],
              [
                "submarketCode",
                Decco.optionToJson(Decco.stringToJson, v.submarketCode)
              ]
            ]);
}

function row_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var orderType = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderType"), null));
  if (orderType.TAG === /* Ok */0) {
    var budgetActionType = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "budgetActionType"), null));
    if (budgetActionType.TAG === /* Ok */0) {
      var submarketCode = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "submarketCode"), null));
      if (submarketCode.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  orderType: orderType._0,
                  budgetActionType: budgetActionType._0,
                  submarketCode: submarketCode._0
                }
              };
      }
      var e = submarketCode._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".submarketCode" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = budgetActionType._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".budgetActionType" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = orderType._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".orderType" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(row_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(row_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var submarketDefault = "DEFAULT";

export {
  Fields ,
  row_encode ,
  row_decode ,
  searchResult_encode ,
  searchResult_decode ,
  submarketDefault ,
}
/* No side effect */
