// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Contract_Attachments_Utils$DvmAdminFrontendFr from "../Contract_Attachments_Utils.bs.js";

var validators_startDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return Contract_Attachments_Utils$DvmAdminFrontendFr.validateAttachmentStartDate(param.startDate, param$1.parentStartDate, param$1.parentEndDate, "required");
    })
};

var validators_endDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return Contract_Attachments_Utils$DvmAdminFrontendFr.validateAttachmentDates(param.startDate, param.endDate, param$1.parentStartDate, param$1.parentEndDate, param.endDateAsParent);
    })
};

var validators_endDateAsParent = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: param.endDateAsParent
            };
    })
};

var validators = {
  startDate: validators_startDate,
  endDate: validators_endDate,
  endDateAsParent: validators_endDateAsParent
};

function initialFieldsStatuses(_input) {
  return {
          startDate: /* Pristine */0,
          endDate: /* Pristine */0,
          endDateAsParent: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            startDate: /* Pristine */0,
            endDate: /* Pristine */0,
            endDateAsParent: /* Pristine */0
          },
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match = fieldsStatuses.startDate;
  var match_0 = match ? match._0 : Curry._2(validators.startDate.validate, input, metadata);
  var match$1 = fieldsStatuses.endDate;
  var match_0$1 = match$1 ? match$1._0 : Curry._2(validators.endDate.validate, input, metadata);
  var match$2 = fieldsStatuses.endDateAsParent;
  var match_0$2 = match$2 ? match$2._0 : Curry._2(validators.endDateAsParent.validate, input, metadata);
  var startDateResult = match_0;
  var startDateResult$1;
  if (startDateResult.TAG === /* Ok */0) {
    var endDateResult = match_0$1;
    if (endDateResult.TAG === /* Ok */0) {
      var endDateAsParentResult = match_0$2;
      if (endDateAsParentResult.TAG === /* Ok */0) {
        return {
                TAG: /* Valid */0,
                output: {
                  startDate: startDateResult._0,
                  endDate: endDateResult._0,
                  endDateAsParent: endDateAsParentResult._0
                },
                fieldsStatuses: {
                  startDate: /* Dirty */{
                    _0: startDateResult,
                    _1: /* Shown */0
                  },
                  endDate: /* Dirty */{
                    _0: endDateResult,
                    _1: /* Shown */0
                  },
                  endDateAsParent: /* Dirty */{
                    _0: endDateAsParentResult,
                    _1: /* Shown */0
                  }
                },
                collectionsStatuses: undefined
              };
      }
      startDateResult$1 = startDateResult;
    } else {
      startDateResult$1 = startDateResult;
    }
  } else {
    startDateResult$1 = startDateResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            startDate: /* Dirty */{
              _0: startDateResult$1,
              _1: /* Shown */0
            },
            endDate: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            endDateAsParent: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurStartDateField */0 :
                  var result = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.startDate, validators_startDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  startDate: status,
                                  endDate: init.endDate,
                                  endDateAsParent: init.endDateAsParent
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEndDateField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.endDate, validators_endDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  startDate: init.startDate,
                                  endDate: status,
                                  endDateAsParent: init.endDateAsParent
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEndDateAsParentField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.endDateAsParent, validators_endDateAsParent, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  startDate: init.startDate,
                                  endDate: init.endDate,
                                  endDateAsParent: status
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */3 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */3,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */4,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */6);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */5);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */4 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */5 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */6 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateStartDateField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$3 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput, metadata, nextFieldsStatuses.contents.endDate, validators_endDate, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  startDate: init.startDate,
                                  endDate: status,
                                  endDateAsParent: init.endDateAsParent
                                };
                        }));
                  if (result$3 !== undefined) {
                    nextFieldsStatuses.contents = result$3;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput, metadata, nextFieldsStatuses.contents.startDate, state.submissionStatus, validators_startDate, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            startDate: status,
                                            endDate: init.endDate,
                                            endDateAsParent: init.endDateAsParent
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEndDateField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$4 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$1, metadata, nextFieldsStatuses$1.contents.startDate, validators_startDate, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  startDate: status,
                                  endDate: init.endDate,
                                  endDateAsParent: init.endDateAsParent
                                };
                        }));
                  if (result$4 !== undefined) {
                    nextFieldsStatuses$1.contents = result$4;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$1, metadata, nextFieldsStatuses$1.contents.endDate, state.submissionStatus, validators_endDate, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            startDate: init.startDate,
                                            endDate: status,
                                            endDateAsParent: init.endDateAsParent
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEndDateAsParentField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$2 = {
                    contents: state.fieldsStatuses
                  };
                  var result$5 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$2, metadata, nextFieldsStatuses$2.contents.endDate, validators_endDate, (function (status) {
                          var init = nextFieldsStatuses$2.contents;
                          return {
                                  startDate: init.startDate,
                                  endDate: status,
                                  endDateAsParent: init.endDateAsParent
                                };
                        }));
                  if (result$5 !== undefined) {
                    nextFieldsStatuses$2.contents = result$5;
                  }
                  var result$6 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$2, metadata, nextFieldsStatuses$2.contents.startDate, validators_startDate, (function (status) {
                          var init = nextFieldsStatuses$2.contents;
                          return {
                                  startDate: status,
                                  endDate: init.endDate,
                                  endDateAsParent: init.endDateAsParent
                                };
                        }));
                  if (result$6 !== undefined) {
                    nextFieldsStatuses$2.contents = result$6;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$2, metadata, nextFieldsStatuses$2.contents.endDateAsParent, state.submissionStatus, validators_endDateAsParent, (function (status) {
                                    var init = nextFieldsStatuses$2.contents;
                                    return {
                                            startDate: init.startDate,
                                            endDate: init.endDate,
                                            endDateAsParent: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */3 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                startDate: /* Pristine */0,
                                endDate: /* Pristine */0,
                                endDateAsParent: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                startDate: /* Pristine */0,
                                endDate: /* Pristine */0,
                                endDateAsParent: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */4 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */5 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Caml_option.some(Curry._1(map, Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateStartDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateStartDateField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEndDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEndDateField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEndDateAsParent: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEndDateAsParentField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurStartDate: (function (param) {
              Curry._1(dispatch, /* BlurStartDateField */0);
            }),
          blurEndDate: (function (param) {
              Curry._1(dispatch, /* BlurEndDateField */1);
            }),
          blurEndDateAsParent: (function (param) {
              Curry._1(dispatch, /* BlurEndDateAsParentField */2);
            }),
          startDateResult: Formality.exposeFieldResult(state.fieldsStatuses.startDate),
          endDateResult: Formality.exposeFieldResult(state.fieldsStatuses.endDate),
          endDateAsParentResult: Formality.exposeFieldResult(state.fieldsStatuses.endDateAsParent),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.startDate || match.endDate || match.endDateAsParent) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */3);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */4);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */5);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */5,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */6);
            })
        };
}

var DatesEditForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialInput = {
  startDate: undefined,
  endDate: undefined,
  endDateAsParent: false
};

export {
  DatesEditForm ,
  initialInput ,
}
/* react Not a pure module */
