// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Core from "@material-ui/core";
import * as Flash_Edit_Details_Dates$DvmAdminFrontendFr from "../Flash_Edit_Details_Dates.bs.js";

function Flash_Edit_Details_PERF(Props) {
  var form = Props.form;
  var formGeneralDates = Props.formGeneralDates;
  var budgets = Props.budgets;
  React.useEffect((function () {
          if (Belt_List.length(form.input.budgetType) === 0) {
            Curry._2(form.updateBudgetType, (function (input, budgetType) {
                    var newrecord = Caml_obj.obj_dup(input);
                    newrecord.budgetType = budgetType;
                    return newrecord;
                  }), {
                  hd: "AVS",
                  tl: /* [] */0
                });
          }
          
        }), []);
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: React.createElement(Flash_Edit_Details_Dates$DvmAdminFrontendFr.make, {
                        form: form,
                        formGeneralDates: formGeneralDates,
                        budgets: budgets
                      }),
                  container: true,
                  direction: "column",
                  spacing: 4
                }));
}

var Form;

var FormGeneralDates;

var make = Flash_Edit_Details_PERF;

export {
  Form ,
  FormGeneralDates ,
  make ,
}
/* react Not a pure module */
