// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as SimpleAlert$DvmAdminFrontendFr from "../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../utils/Utils_Alert.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../context/user/User_Context.bs.js";
import * as File_Upload_Api$DvmAdminFrontendFr from "./File_Upload_Api.bs.js";
import * as File_Upload_Modal$DvmAdminFrontendFr from "./File_Upload_Modal.bs.js";

function button(fullWidth) {
  var tmp = {
    backgroundColor: Theme_Colors$DvmAdminFrontendFr.black,
    border: "none",
    color: Theme_Colors$DvmAdminFrontendFr.white,
    cursor: "pointer",
    display: "flex",
    textTransform: "uppercase",
    alignItems: "center",
    justifyContent: "center"
  };
  var tmp$1 = fullWidth ? undefined : "150px";
  if (tmp$1 !== undefined) {
    tmp.width = tmp$1;
  }
  return Css.css(Object.assign({}, tmp, {
                  "&:hover": {
                    backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayMain
                  }
                }));
}

var medium = Css.css({
      fontSize: "14px",
      fontWeight: "600",
      minHeight: "45px",
      minWidth: "128px",
      padding: "7px 14px"
    });

var secondary = Css.css({
      backgroundColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown
    });

function File_Upload_Button(Props) {
  var title = Props.title;
  var table = Props.table;
  var fullWidthOpt = Props.fullWidth;
  var fullWidth = fullWidthOpt !== undefined ? fullWidthOpt : false;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResultFile = match$2[1];
  var resultFile = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setModal = match$3[1];
  React.useEffect((function () {
          if (typeof resultFile !== "number" && resultFile.TAG === /* Error */1) {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: resultFile._0
                      }
                    }));
          }
          
        }), [resultFile]);
  return React.createElement(React.Fragment, undefined, React.createElement("input", {
                  id: "contained-button-file",
                  style: {
                    display: "none"
                  },
                  accept: ".xlsx",
                  type: "file",
                  value: "",
                  onChange: (function ($$event) {
                      var file = Belt_Array.get($$event.currentTarget.files, 0);
                      if (file !== undefined) {
                        Curry._1(setModal, (function (param) {
                                return true;
                              }));
                        return File_Upload_Api$DvmAdminFrontendFr.postFile(userManager, setResultFile, Caml_option.valFromOption(file), table, selectedRole);
                      }
                      
                    })
                }), React.createElement("label", {
                  htmlFor: "contained-button-file"
                }, React.createElement(Core.Button, {
                      children: intl.formatMessage(title),
                      className: Css.cx([
                            button(fullWidth),
                            medium,
                            secondary
                          ]),
                      color: "primary",
                      component: "span",
                      fullWidth: fullWidth,
                      size: "medium",
                      variant: "contained"
                    })), match$3[0] ? React.createElement(File_Upload_Modal$DvmAdminFrontendFr.make, {
                    resultFile: resultFile,
                    onClose: (function (param) {
                        Curry._1(setModal, (function (param) {
                                return false;
                              }));
                      }),
                    selectedTable: table
                  }) : null);
}

var Api;

var make = File_Upload_Button;

export {
  Api ,
  button ,
  medium ,
  secondary ,
  make ,
}
/* medium Not a pure module */
