// Generated by ReScript, PLEASE EDIT WITH CARE


var Rest;

var startDateTooLow = {
  id: "1cd5e9c6-4700-4eb4-aa3a-05c8f40702ef",
  defaultMessage: "Date de début trop ancienne"
};

var endDateTooHigh = {
  id: "aed1afa5-09a2-4433-9ae8-5ea13585b9b2",
  defaultMessage: "Date de fin trop éloignée"
};

var datesNotSameYear = {
  id: "1c883d54-9d34-4db6-af81-486ef8d1aee1",
  defaultMessage: "Les dates doivent être de la même année"
};

var dateOutOfPeriod = {
  id: "3721752a-7ff0-4d6f-94d1-c04b21cc87a2",
  defaultMessage: "Date hors période autorisée"
};

var datesHaveIssue = {
  id: "50b27b21-2292-4849-8dee-7e6f7b4c5c9d",
  defaultMessage: "Date non valide"
};

var largerZero = {
  id: "3c41364d-30d7-45ee-9c3c-b3bdde1abd0f",
  defaultMessage: "Le chiffre doit être supérieur à zéro"
};

var dateTooSmall = {
  id: "db8d07be-4860-4852-b583-759d96d9d042",
  defaultMessage: "Doit être postérieure à l’autre date"
};

var dateTooLarge = {
  id: "874d6eb2-5ddf-4381-8345-65896c902faf",
  defaultMessage: "Doit être antérieure à l’autre date"
};

var otherDateNotValid = {
  id: "3b7a042c-923e-4617-9b8a-4e14397c4f35",
  defaultMessage: "Autre date non valide"
};

var fieldRequired = {
  id: "5de13793-84d3-4f24-b0bf-f8b2d7f365a0",
  defaultMessage: "Ce champ est obligatoire"
};

var fieldPositive = {
  id: "88ab4864-f801-4465-b647-8b296c270ec3",
  defaultMessage: "Le chiffre doit être positif"
};

var fieldNegative = {
  id: "00478c17-da49-4718-bbf6-810b0f58b748",
  defaultMessage: "Le chiffre doit être négatif"
};

var invalidField = {
  id: "45794ba0-1735-472f-a47a-2b8347f964d3",
  defaultMessage: "Valeur non valide"
};

var sirenNotFound = {
  id: "159d8904-50c3-4741-b784-021db2367ce2",
  defaultMessage: "SIREN introuvable"
};

var listTooLong = {
  id: "bca32848-e621-494a-84ef-6d1e0f68adff",
  defaultMessage: "La sélection a trop d’éléments"
};

var fieldMoreCharactersThanMaximum = {
  id: "32035977-e482-4799-82af-289a323d2870",
  defaultMessage: "Le champ est trop long"
};

var otherValueNotValid = {
  id: "5c78aa8b-60ac-475d-adc4-ae9a06e23f05",
  defaultMessage: "Autre valeur non valide"
};

export {
  Rest ,
  startDateTooLow ,
  endDateTooHigh ,
  datesNotSameYear ,
  dateOutOfPeriod ,
  datesHaveIssue ,
  largerZero ,
  dateTooSmall ,
  dateTooLarge ,
  otherDateNotValid ,
  fieldRequired ,
  fieldPositive ,
  fieldNegative ,
  invalidField ,
  sirenNotFound ,
  listTooLong ,
  fieldMoreCharactersThanMaximum ,
  otherValueNotValid ,
}
/* No side effect */
