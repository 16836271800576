if (process.env.REACT_APP_DYNATRACE == "true") {
    const script = document.createElement('script');
    script.src = 'https://js-cdn.dynatrace.com/jstag/16c6bc5bd3b/bf28073qyu/fb63dc0ec8a9e90e_complete.js';
    script.crossorigin = 'anonymous';
    script.async = true;
    script.onload = () => {
        console.log('Dynatrace loaded successfuly');
    };
    script.onerror = () => {
        console.log('Error occurred while loading Dynatrace');
    };
    document.head.appendChild(script);
}