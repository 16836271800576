// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../common/App_Api.bs.js";
import * as App_Modal$DvmAdminFrontendFr from "../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Margin_Grid_Api$DvmAdminFrontendFr from "../marginGrid/search/Margin_Grid_Api.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_MarginGrid$DvmAdminFrontendFr from "../../../intl/messages/marginGrid/Messages_MarginGrid.bs.js";
import * as Grid_Edit_Modal_Page$DvmAdminFrontendFr from "./table/Grid_Edit_Modal_Page.bs.js";
import * as Grid_Edit_Modal_Actions$DvmAdminFrontendFr from "./actions/Grid_Edit_Modal_Actions.bs.js";

var underline = Css.css({
      borderBottom: "solid 5px " + Theme_Colors$DvmAdminFrontendFr.primaryBrown + ""
    });

function Grid_Edit_Modal(Props) {
  var onClose = Props.onClose;
  var whatTable = Props.whatTable;
  var whatGrid = Props.whatGrid;
  var budgetOrID = Props.budgetOrID;
  var budget = Props.budget;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* [] */0;
      });
  var setAcquisitionOptions = match$2[1];
  var match$3 = React.useState(function () {
        return /* [] */0;
      });
  var setCategories = match$3[1];
  var match$4 = React.useState(function () {
        return false;
      });
  var setActionsPending = match$4[1];
  var actionsPending = match$4[0];
  var match$5 = React.useState(function () {
        return false;
      });
  var match$6 = React.useState(function () {
        
      });
  var match$7 = React.useState(function () {
        return /* NotStarted */0;
      });
  var result = match$7[0];
  var match$8 = React.useState(function () {
        return whatTable;
      });
  var setWhatTable = match$8[1];
  var whatTable$1 = match$8[0];
  var match$9 = React.useState(function () {
        return /* [] */0;
      });
  var setCategoriesAll = match$9[1];
  var categoriesAll = match$9[0];
  var match$10 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResultCategoriesAll = match$10[1];
  var resultCategoriesAll = match$10[0];
  React.useEffect((function () {
          if (typeof result === "number") {
            if (result !== 0) {
              Curry._1(setActionsPending, (function (param) {
                      return true;
                    }));
            }
            
          } else {
            Curry._1(setActionsPending, (function (param) {
                    return false;
                  }));
          }
        }), [result]);
  React.useEffect((function () {
          if (typeof resultCategoriesAll === "number") {
            resultCategoriesAll === /* NotStarted */0;
          } else if (resultCategoriesAll.TAG === /* Ok */0) {
            var categories = resultCategoriesAll._0;
            Curry._1(setCategoriesAll, (function (param) {
                    return categories;
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: resultCategoriesAll._0
                      }
                    }));
          }
        }), [resultCategoriesAll]);
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchAllCategories(userManager, setResultCategoriesAll, /* Today */0, selectedRole, undefined);
          App_Api$DvmAdminFrontendFr.queryForAcquisitions(alert, userManager, setAcquisitionOptions, selectedRole);
          if (whatTable$1 >= 2) {
            Margin_Grid_Api$DvmAdminFrontendFr.getCategories(alert, userManager, setCategories, selectedRole);
          }
          
        }), []);
  var tmp;
  if (whatTable$1 === /* GridID */2) {
    tmp = null;
  } else {
    var tmp$1 = {
      onClick: (function (param) {
          Curry._1(setWhatTable, (function (param) {
                  return /* Model */0;
                }));
        }),
      children: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.models)
    };
    var tmp$2 = whatTable$1 === /* Model */0 ? underline : undefined;
    if (tmp$2 !== undefined) {
      tmp$1.className = tmp$2;
    }
    var tmp$3 = {
      onClick: (function (param) {
          Curry._1(setWhatTable, (function (param) {
                  return /* Version */1;
                }));
        }),
      children: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.versions)
    };
    var tmp$4 = whatTable$1 === /* Version */1 ? underline : undefined;
    if (tmp$4 !== undefined) {
      tmp$3.className = tmp$4;
    }
    tmp = React.createElement(Core.Grid, {
          children: null,
          container: true,
          spacing: 2
        }, React.createElement(Core.Grid, {
              children: React.createElement(Core.Button, tmp$1),
              item: true
            }), React.createElement(Core.Grid, {
              children: React.createElement(Core.Button, tmp$3),
              item: true
            }));
  }
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_MarginGrid$DvmAdminFrontendFr.modalTitle, {
                    budgetOrID: budgetOrID
                  }),
              minWidth: "1300px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: actionsPending || match$5[0],
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          spacing: 2
                        }, tmp, React.createElement(Grid_Edit_Modal_Page$DvmAdminFrontendFr.make, {
                              budgetOrID: budgetOrID,
                              budget: budget,
                              whatTable: whatTable$1,
                              setTablePending: match$5[1],
                              setTableResult: match$6[1],
                              actionsPending: actionsPending,
                              setActionsPending: setActionsPending,
                              setResult: match$7[1],
                              categories: match$3[0],
                              categoriesAll: categoriesAll,
                              whatGrid: whatGrid,
                              acquisitionOptions: match$2[0]
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(Grid_Edit_Modal_Actions$DvmAdminFrontendFr.make, {
                                    setActionsPending: setActionsPending,
                                    tableResult: match$6[0],
                                    categoriesAll: categoriesAll,
                                    whatTable: whatTable$1,
                                    whatGrid: whatGrid,
                                    budget: budget
                                  }),
                              container: true,
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                    onClick: (function (param) {
                                        Curry._1(onClose, undefined);
                                      }),
                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.close),
                                    size: "medium"
                                  }),
                              container: true,
                              item: true,
                              justifyContent: "flex-end"
                            }))
                  })
            });
}

var Page;

var Actions;

var Types;

var MainApi;

var make = Grid_Edit_Modal;

export {
  Page ,
  Actions ,
  Types ,
  MainApi ,
  underline ,
  make ,
}
/* underline Not a pure module */
