// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../common/utils/Utils_Api.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../common/utils/Utils_Form.bs.js";
import * as Quota_Admin_Types$DvmAdminFrontendFr from "./Quota_Admin_Types.bs.js";

var validators_startYear = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.startYear, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.dateYearToQueryParam(Quota_Admin_Types$DvmAdminFrontendFr.FilterFields.startYear, date);
                  }));
    })
};

var validators_volume = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Quota_Admin_Types$DvmAdminFrontendFr.FilterFields.volume,
                    value: param.volume
                  })
            };
    })
};

var validators_vehicleType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Quota_Admin_Types$DvmAdminFrontendFr.FilterFields.vehicleType,
                    value: param.vehicleType
                  })
            };
    })
};

var validators_contractId = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Quota_Admin_Types$DvmAdminFrontendFr.FilterFields.contractId,
                    value: param.contractId
                  })
            };
    })
};

var validators_dr = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParam(Quota_Admin_Types$DvmAdminFrontendFr.FilterFields.dr, param.dr)
            };
    })
};

var validators = {
  startYear: validators_startYear,
  volume: validators_volume,
  vehicleType: validators_vehicleType,
  contractId: validators_contractId,
  dr: validators_dr
};

function initialFieldsStatuses(_input) {
  return {
          startYear: /* Pristine */0,
          volume: /* Pristine */0,
          vehicleType: /* Pristine */0,
          contractId: /* Pristine */0,
          dr: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.startYear;
  var match_0 = match ? match._0 : Curry._1(validators.startYear.validate, input);
  var match$1 = fieldsStatuses.volume;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.volume.validate, input);
  var match$2 = fieldsStatuses.vehicleType;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.vehicleType.validate, input);
  var match$3 = fieldsStatuses.contractId;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.contractId.validate, input);
  var match$4 = fieldsStatuses.dr;
  var match_0$4 = match$4 ? match$4._0 : Curry._1(validators.dr.validate, input);
  var startYearResult = match_0;
  var startYearResult$1;
  if (startYearResult.TAG === /* Ok */0) {
    var volumeResult = match_0$1;
    if (volumeResult.TAG === /* Ok */0) {
      var vehicleTypeResult = match_0$2;
      if (vehicleTypeResult.TAG === /* Ok */0) {
        var contractIdResult = match_0$3;
        if (contractIdResult.TAG === /* Ok */0) {
          var drResult = match_0$4;
          if (drResult.TAG === /* Ok */0) {
            return {
                    TAG: /* Valid */0,
                    output: {
                      dr: drResult._0,
                      contractId: contractIdResult._0,
                      vehicleType: vehicleTypeResult._0,
                      volume: volumeResult._0,
                      startYear: startYearResult._0
                    },
                    fieldsStatuses: {
                      startYear: /* Dirty */{
                        _0: startYearResult,
                        _1: /* Shown */0
                      },
                      volume: /* Dirty */{
                        _0: volumeResult,
                        _1: /* Shown */0
                      },
                      vehicleType: /* Dirty */{
                        _0: vehicleTypeResult,
                        _1: /* Shown */0
                      },
                      contractId: /* Dirty */{
                        _0: contractIdResult,
                        _1: /* Shown */0
                      },
                      dr: /* Dirty */{
                        _0: drResult,
                        _1: /* Shown */0
                      }
                    },
                    collectionsStatuses: undefined
                  };
          }
          startYearResult$1 = startYearResult;
        } else {
          startYearResult$1 = startYearResult;
        }
      } else {
        startYearResult$1 = startYearResult;
      }
    } else {
      startYearResult$1 = startYearResult;
    }
  } else {
    startYearResult$1 = startYearResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            startYear: /* Dirty */{
              _0: startYearResult$1,
              _1: /* Shown */0
            },
            volume: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            vehicleType: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            contractId: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            dr: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurStartYearField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.startYear, validators_startYear, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  startYear: status,
                                  volume: init.volume,
                                  vehicleType: init.vehicleType,
                                  contractId: init.contractId,
                                  dr: init.dr
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVolumeField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.volume, validators_volume, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  startYear: init.startYear,
                                  volume: status,
                                  vehicleType: init.vehicleType,
                                  contractId: init.contractId,
                                  dr: init.dr
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVehicleTypeField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.vehicleType, validators_vehicleType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  startYear: init.startYear,
                                  volume: init.volume,
                                  vehicleType: status,
                                  contractId: init.contractId,
                                  dr: init.dr
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurContractIdField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.contractId, validators_contractId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  startYear: init.startYear,
                                  volume: init.volume,
                                  vehicleType: init.vehicleType,
                                  contractId: status,
                                  dr: init.dr
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDrField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.dr, validators_dr, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  startYear: init.startYear,
                                  volume: init.volume,
                                  vehicleType: init.vehicleType,
                                  contractId: init.contractId,
                                  dr: status
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */5 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */5,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */6,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */8);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */7);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */6 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */7 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */8 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateStartYearField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.startYear, state.submissionStatus, validators_startYear, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            startYear: status,
                                            volume: init.volume,
                                            vehicleType: init.vehicleType,
                                            contractId: init.contractId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVolumeField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.volume, state.submissionStatus, validators_volume, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            startYear: init.startYear,
                                            volume: status,
                                            vehicleType: init.vehicleType,
                                            contractId: init.contractId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVehicleTypeField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.vehicleType, state.submissionStatus, validators_vehicleType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            startYear: init.startYear,
                                            volume: init.volume,
                                            vehicleType: status,
                                            contractId: init.contractId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateContractIdField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.contractId, state.submissionStatus, validators_contractId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            startYear: init.startYear,
                                            volume: init.volume,
                                            vehicleType: init.vehicleType,
                                            contractId: status,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDrField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.dr, state.submissionStatus, validators_dr, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            startYear: init.startYear,
                                            volume: init.volume,
                                            vehicleType: init.vehicleType,
                                            contractId: init.contractId,
                                            dr: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */5 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */6 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */7 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateStartYear: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateStartYearField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVolume: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVolumeField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVehicleType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVehicleTypeField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateContractId: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateContractIdField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDr: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDrField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurStartYear: (function (param) {
              Curry._1(dispatch, /* BlurStartYearField */0);
            }),
          blurVolume: (function (param) {
              Curry._1(dispatch, /* BlurVolumeField */1);
            }),
          blurVehicleType: (function (param) {
              Curry._1(dispatch, /* BlurVehicleTypeField */2);
            }),
          blurContractId: (function (param) {
              Curry._1(dispatch, /* BlurContractIdField */3);
            }),
          blurDr: (function (param) {
              Curry._1(dispatch, /* BlurDrField */4);
            }),
          startYearResult: Formality.exposeFieldResult(state.fieldsStatuses.startYear),
          volumeResult: Formality.exposeFieldResult(state.fieldsStatuses.volume),
          vehicleTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.vehicleType),
          contractIdResult: Formality.exposeFieldResult(state.fieldsStatuses.contractId),
          drResult: Formality.exposeFieldResult(state.fieldsStatuses.dr),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.startYear || match.volume || match.vehicleType || match.contractId || match.dr) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */5);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */6);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */7);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */7,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */8);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  dr: /* [] */0,
  contractId: "",
  vehicleType: "",
  volume: "",
  startYear: undefined
};

export {
  TableFilterForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
