// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../common/App_Typography.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as Messages_Flash_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/flash/Messages_Flash_Detail.bs.js";
import * as Messages_Flash_Search$DvmAdminFrontendFr from "../../../../../intl/messages/flash/Messages_Flash_Search.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Flash_Search_Modal_Date_Box$DvmAdminFrontendFr from "../Flash_Search_Modal_Date_Box.bs.js";
import * as Flash_Search_Modal_Detention_Box$DvmAdminFrontendFr from "../Flash_Search_Modal_Detention_Box.bs.js";

function Flash_Search_Modal_Date_Filters(Props) {
  var orderType = Props.orderType;
  var form = Props.form;
  var formMoreDates = Props.formMoreDates;
  var intl = ReactIntl.useIntl();
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              item: true,
              spacing: 5
            }, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  spacing: 1
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                            style: {
                              fontWeight: "600"
                            },
                            children: intl.formatMessage(Messages_Flash_Detail$DvmAdminFrontendFr.deliveryPeriod)
                          }),
                      item: true
                    }), React.createElement(Flash_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                      onChangeFrom: Curry._1(form.updateDeliveryStartFrom, (function (input, deliveryStartFrom) {
                              return {
                                      registrationStartFrom: input.registrationStartFrom,
                                      registrationStartTo: input.registrationStartTo,
                                      registrationEndFrom: input.registrationEndFrom,
                                      registrationEndTo: input.registrationEndTo,
                                      deliveryStartFrom: deliveryStartFrom,
                                      deliveryStartTo: input.deliveryStartTo,
                                      deliveryEndFrom: input.deliveryEndFrom,
                                      deliveryEndTo: input.deliveryEndTo,
                                      madaStartFrom: input.madaStartFrom,
                                      madaStartTo: input.madaStartTo,
                                      madaEndFrom: input.madaEndFrom,
                                      madaEndTo: input.madaEndTo,
                                      detentionMinFrom: input.detentionMinFrom,
                                      detentionMinTo: input.detentionMinTo,
                                      detentionMaxFrom: input.detentionMaxFrom,
                                      detentionMaxTo: input.detentionMaxTo
                                    };
                            })),
                      onChangeTo: Curry._1(form.updateDeliveryStartTo, (function (input, deliveryStartTo) {
                              return {
                                      registrationStartFrom: input.registrationStartFrom,
                                      registrationStartTo: input.registrationStartTo,
                                      registrationEndFrom: input.registrationEndFrom,
                                      registrationEndTo: input.registrationEndTo,
                                      deliveryStartFrom: input.deliveryStartFrom,
                                      deliveryStartTo: deliveryStartTo,
                                      deliveryEndFrom: input.deliveryEndFrom,
                                      deliveryEndTo: input.deliveryEndTo,
                                      madaStartFrom: input.madaStartFrom,
                                      madaStartTo: input.madaStartTo,
                                      madaEndFrom: input.madaEndFrom,
                                      madaEndTo: input.madaEndTo,
                                      detentionMinFrom: input.detentionMinFrom,
                                      detentionMinTo: input.detentionMinTo,
                                      detentionMaxFrom: input.detentionMaxFrom,
                                      detentionMaxTo: input.detentionMaxTo
                                    };
                            })),
                      dateFrom: form.input.deliveryStartFrom,
                      dateTo: form.input.deliveryStartTo,
                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                      startOrEnd: "start"
                    }), React.createElement(Flash_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                      onChangeFrom: Curry._1(form.updateDeliveryEndFrom, (function (input, deliveryEndFrom) {
                              return {
                                      registrationStartFrom: input.registrationStartFrom,
                                      registrationStartTo: input.registrationStartTo,
                                      registrationEndFrom: input.registrationEndFrom,
                                      registrationEndTo: input.registrationEndTo,
                                      deliveryStartFrom: input.deliveryStartFrom,
                                      deliveryStartTo: input.deliveryStartTo,
                                      deliveryEndFrom: deliveryEndFrom,
                                      deliveryEndTo: input.deliveryEndTo,
                                      madaStartFrom: input.madaStartFrom,
                                      madaStartTo: input.madaStartTo,
                                      madaEndFrom: input.madaEndFrom,
                                      madaEndTo: input.madaEndTo,
                                      detentionMinFrom: input.detentionMinFrom,
                                      detentionMinTo: input.detentionMinTo,
                                      detentionMaxFrom: input.detentionMaxFrom,
                                      detentionMaxTo: input.detentionMaxTo
                                    };
                            })),
                      onChangeTo: Curry._1(form.updateDeliveryEndTo, (function (input, deliveryEndTo) {
                              return {
                                      registrationStartFrom: input.registrationStartFrom,
                                      registrationStartTo: input.registrationStartTo,
                                      registrationEndFrom: input.registrationEndFrom,
                                      registrationEndTo: input.registrationEndTo,
                                      deliveryStartFrom: input.deliveryStartFrom,
                                      deliveryStartTo: input.deliveryStartTo,
                                      deliveryEndFrom: input.deliveryEndFrom,
                                      deliveryEndTo: deliveryEndTo,
                                      madaStartFrom: input.madaStartFrom,
                                      madaStartTo: input.madaStartTo,
                                      madaEndFrom: input.madaEndFrom,
                                      madaEndTo: input.madaEndTo,
                                      detentionMinFrom: input.detentionMinFrom,
                                      detentionMinTo: input.detentionMinTo,
                                      detentionMaxFrom: input.detentionMaxFrom,
                                      detentionMaxTo: input.detentionMaxTo
                                    };
                            })),
                      dateFrom: form.input.deliveryEndFrom,
                      dateTo: form.input.deliveryEndTo,
                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                      startOrEnd: "end"
                    })), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  spacing: 1
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                            style: {
                              fontWeight: "600"
                            },
                            children: intl.formatMessage(Messages_Flash_Detail$DvmAdminFrontendFr.registrationPeriod)
                          }),
                      item: true
                    }), React.createElement(Flash_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                      onChangeFrom: Curry._1(form.updateRegistrationStartFrom, (function (input, registrationStartFrom) {
                              return {
                                      registrationStartFrom: registrationStartFrom,
                                      registrationStartTo: input.registrationStartTo,
                                      registrationEndFrom: input.registrationEndFrom,
                                      registrationEndTo: input.registrationEndTo,
                                      deliveryStartFrom: input.deliveryStartFrom,
                                      deliveryStartTo: input.deliveryStartTo,
                                      deliveryEndFrom: input.deliveryEndFrom,
                                      deliveryEndTo: input.deliveryEndTo,
                                      madaStartFrom: input.madaStartFrom,
                                      madaStartTo: input.madaStartTo,
                                      madaEndFrom: input.madaEndFrom,
                                      madaEndTo: input.madaEndTo,
                                      detentionMinFrom: input.detentionMinFrom,
                                      detentionMinTo: input.detentionMinTo,
                                      detentionMaxFrom: input.detentionMaxFrom,
                                      detentionMaxTo: input.detentionMaxTo
                                    };
                            })),
                      onChangeTo: Curry._1(form.updateRegistrationStartTo, (function (input, registrationStartTo) {
                              return {
                                      registrationStartFrom: input.registrationStartFrom,
                                      registrationStartTo: registrationStartTo,
                                      registrationEndFrom: input.registrationEndFrom,
                                      registrationEndTo: input.registrationEndTo,
                                      deliveryStartFrom: input.deliveryStartFrom,
                                      deliveryStartTo: input.deliveryStartTo,
                                      deliveryEndFrom: input.deliveryEndFrom,
                                      deliveryEndTo: input.deliveryEndTo,
                                      madaStartFrom: input.madaStartFrom,
                                      madaStartTo: input.madaStartTo,
                                      madaEndFrom: input.madaEndFrom,
                                      madaEndTo: input.madaEndTo,
                                      detentionMinFrom: input.detentionMinFrom,
                                      detentionMinTo: input.detentionMinTo,
                                      detentionMaxFrom: input.detentionMaxFrom,
                                      detentionMaxTo: input.detentionMaxTo
                                    };
                            })),
                      dateFrom: form.input.registrationStartFrom,
                      dateTo: form.input.registrationStartTo,
                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                      startOrEnd: "start"
                    }), React.createElement(Flash_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                      onChangeFrom: Curry._1(form.updateRegistrationEndFrom, (function (input, registrationEndFrom) {
                              return {
                                      registrationStartFrom: input.registrationStartFrom,
                                      registrationStartTo: input.registrationStartTo,
                                      registrationEndFrom: registrationEndFrom,
                                      registrationEndTo: input.registrationEndTo,
                                      deliveryStartFrom: input.deliveryStartFrom,
                                      deliveryStartTo: input.deliveryStartTo,
                                      deliveryEndFrom: input.deliveryEndFrom,
                                      deliveryEndTo: input.deliveryEndTo,
                                      madaStartFrom: input.madaStartFrom,
                                      madaStartTo: input.madaStartTo,
                                      madaEndFrom: input.madaEndFrom,
                                      madaEndTo: input.madaEndTo,
                                      detentionMinFrom: input.detentionMinFrom,
                                      detentionMinTo: input.detentionMinTo,
                                      detentionMaxFrom: input.detentionMaxFrom,
                                      detentionMaxTo: input.detentionMaxTo
                                    };
                            })),
                      onChangeTo: Curry._1(form.updateRegistrationEndTo, (function (input, registrationEndTo) {
                              return {
                                      registrationStartFrom: input.registrationStartFrom,
                                      registrationStartTo: input.registrationStartTo,
                                      registrationEndFrom: input.registrationEndFrom,
                                      registrationEndTo: registrationEndTo,
                                      deliveryStartFrom: input.deliveryStartFrom,
                                      deliveryStartTo: input.deliveryStartTo,
                                      deliveryEndFrom: input.deliveryEndFrom,
                                      deliveryEndTo: input.deliveryEndTo,
                                      madaStartFrom: input.madaStartFrom,
                                      madaStartTo: input.madaStartTo,
                                      madaEndFrom: input.madaEndFrom,
                                      madaEndTo: input.madaEndTo,
                                      detentionMinFrom: input.detentionMinFrom,
                                      detentionMinTo: input.detentionMinTo,
                                      detentionMaxFrom: input.detentionMaxFrom,
                                      detentionMaxTo: input.detentionMaxTo
                                    };
                            })),
                      dateFrom: form.input.registrationEndFrom,
                      dateTo: form.input.registrationEndTo,
                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                      startOrEnd: "end"
                    })), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  spacing: 1
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                            style: {
                              fontWeight: "600"
                            },
                            children: intl.formatMessage(Messages_Flash_Detail$DvmAdminFrontendFr.madaPeriod)
                          }),
                      item: true
                    }), React.createElement(Flash_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                      onChangeFrom: Curry._1(form.updateMadaStartFrom, (function (input, madaStartFrom) {
                              return {
                                      registrationStartFrom: input.registrationStartFrom,
                                      registrationStartTo: input.registrationStartTo,
                                      registrationEndFrom: input.registrationEndFrom,
                                      registrationEndTo: input.registrationEndTo,
                                      deliveryStartFrom: input.deliveryStartFrom,
                                      deliveryStartTo: input.deliveryStartTo,
                                      deliveryEndFrom: input.deliveryEndFrom,
                                      deliveryEndTo: input.deliveryEndTo,
                                      madaStartFrom: madaStartFrom,
                                      madaStartTo: input.madaStartTo,
                                      madaEndFrom: input.madaEndFrom,
                                      madaEndTo: input.madaEndTo,
                                      detentionMinFrom: input.detentionMinFrom,
                                      detentionMinTo: input.detentionMinTo,
                                      detentionMaxFrom: input.detentionMaxFrom,
                                      detentionMaxTo: input.detentionMaxTo
                                    };
                            })),
                      onChangeTo: Curry._1(form.updateMadaStartTo, (function (input, madaStartTo) {
                              return {
                                      registrationStartFrom: input.registrationStartFrom,
                                      registrationStartTo: input.registrationStartTo,
                                      registrationEndFrom: input.registrationEndFrom,
                                      registrationEndTo: input.registrationEndTo,
                                      deliveryStartFrom: input.deliveryStartFrom,
                                      deliveryStartTo: input.deliveryStartTo,
                                      deliveryEndFrom: input.deliveryEndFrom,
                                      deliveryEndTo: input.deliveryEndTo,
                                      madaStartFrom: input.madaStartFrom,
                                      madaStartTo: madaStartTo,
                                      madaEndFrom: input.madaEndFrom,
                                      madaEndTo: input.madaEndTo,
                                      detentionMinFrom: input.detentionMinFrom,
                                      detentionMinTo: input.detentionMinTo,
                                      detentionMaxFrom: input.detentionMaxFrom,
                                      detentionMaxTo: input.detentionMaxTo
                                    };
                            })),
                      dateFrom: form.input.madaStartFrom,
                      dateTo: form.input.madaStartTo,
                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                      startOrEnd: "start"
                    }), React.createElement(Flash_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                      onChangeFrom: Curry._1(form.updateMadaEndFrom, (function (input, madaEndFrom) {
                              return {
                                      registrationStartFrom: input.registrationStartFrom,
                                      registrationStartTo: input.registrationStartTo,
                                      registrationEndFrom: input.registrationEndFrom,
                                      registrationEndTo: input.registrationEndTo,
                                      deliveryStartFrom: input.deliveryStartFrom,
                                      deliveryStartTo: input.deliveryStartTo,
                                      deliveryEndFrom: input.deliveryEndFrom,
                                      deliveryEndTo: input.deliveryEndTo,
                                      madaStartFrom: input.madaStartFrom,
                                      madaStartTo: input.madaStartTo,
                                      madaEndFrom: madaEndFrom,
                                      madaEndTo: input.madaEndTo,
                                      detentionMinFrom: input.detentionMinFrom,
                                      detentionMinTo: input.detentionMinTo,
                                      detentionMaxFrom: input.detentionMaxFrom,
                                      detentionMaxTo: input.detentionMaxTo
                                    };
                            })),
                      onChangeTo: Curry._1(form.updateMadaEndTo, (function (input, madaEndTo) {
                              return {
                                      registrationStartFrom: input.registrationStartFrom,
                                      registrationStartTo: input.registrationStartTo,
                                      registrationEndFrom: input.registrationEndFrom,
                                      registrationEndTo: input.registrationEndTo,
                                      deliveryStartFrom: input.deliveryStartFrom,
                                      deliveryStartTo: input.deliveryStartTo,
                                      deliveryEndFrom: input.deliveryEndFrom,
                                      deliveryEndTo: input.deliveryEndTo,
                                      madaStartFrom: input.madaStartFrom,
                                      madaStartTo: input.madaStartTo,
                                      madaEndFrom: input.madaEndFrom,
                                      madaEndTo: madaEndTo,
                                      detentionMinFrom: input.detentionMinFrom,
                                      detentionMinTo: input.detentionMinTo,
                                      detentionMaxFrom: input.detentionMaxFrom,
                                      detentionMaxTo: input.detentionMaxTo
                                    };
                            })),
                      dateFrom: form.input.madaEndFrom,
                      dateTo: form.input.madaEndTo,
                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                      startOrEnd: "end"
                    })), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  spacing: 1
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                            style: {
                              fontWeight: "600"
                            },
                            children: intl.formatMessage(Messages_Flash_Detail$DvmAdminFrontendFr.madcPeriod)
                          }),
                      item: true
                    }), React.createElement(Flash_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                      onChangeFrom: Curry._1(formMoreDates.updateMadcStartFrom, (function (input, madcStartFrom) {
                              return {
                                      madcStartFrom: madcStartFrom,
                                      madcStartTo: input.madcStartTo,
                                      madcEndFrom: input.madcEndFrom,
                                      madcEndTo: input.madcEndTo,
                                      entryParcStartFrom: input.entryParcStartFrom,
                                      entryParcStartTo: input.entryParcStartTo,
                                      entryParcEndFrom: input.entryParcEndFrom,
                                      entryParcEndTo: input.entryParcEndTo,
                                      exitParcStartFrom: input.exitParcStartFrom,
                                      exitParcStartTo: input.exitParcStartTo,
                                      exitParcEndFrom: input.exitParcEndFrom,
                                      exitParcEndTo: input.exitParcEndTo
                                    };
                            })),
                      onChangeTo: Curry._1(formMoreDates.updateMadcStartTo, (function (input, madcStartTo) {
                              return {
                                      madcStartFrom: input.madcStartFrom,
                                      madcStartTo: madcStartTo,
                                      madcEndFrom: input.madcEndFrom,
                                      madcEndTo: input.madcEndTo,
                                      entryParcStartFrom: input.entryParcStartFrom,
                                      entryParcStartTo: input.entryParcStartTo,
                                      entryParcEndFrom: input.entryParcEndFrom,
                                      entryParcEndTo: input.entryParcEndTo,
                                      exitParcStartFrom: input.exitParcStartFrom,
                                      exitParcStartTo: input.exitParcStartTo,
                                      exitParcEndFrom: input.exitParcEndFrom,
                                      exitParcEndTo: input.exitParcEndTo
                                    };
                            })),
                      dateFrom: formMoreDates.input.madcStartFrom,
                      dateTo: formMoreDates.input.madcStartTo,
                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                      startOrEnd: "start"
                    }), React.createElement(Flash_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                      onChangeFrom: Curry._1(formMoreDates.updateMadcEndFrom, (function (input, madcEndFrom) {
                              return {
                                      madcStartFrom: input.madcStartFrom,
                                      madcStartTo: input.madcStartTo,
                                      madcEndFrom: madcEndFrom,
                                      madcEndTo: input.madcEndTo,
                                      entryParcStartFrom: input.entryParcStartFrom,
                                      entryParcStartTo: input.entryParcStartTo,
                                      entryParcEndFrom: input.entryParcEndFrom,
                                      entryParcEndTo: input.entryParcEndTo,
                                      exitParcStartFrom: input.exitParcStartFrom,
                                      exitParcStartTo: input.exitParcStartTo,
                                      exitParcEndFrom: input.exitParcEndFrom,
                                      exitParcEndTo: input.exitParcEndTo
                                    };
                            })),
                      onChangeTo: Curry._1(formMoreDates.updateMadcEndTo, (function (input, madcEndTo) {
                              return {
                                      madcStartFrom: input.madcStartFrom,
                                      madcStartTo: input.madcStartTo,
                                      madcEndFrom: input.madcEndFrom,
                                      madcEndTo: madcEndTo,
                                      entryParcStartFrom: input.entryParcStartFrom,
                                      entryParcStartTo: input.entryParcStartTo,
                                      entryParcEndFrom: input.entryParcEndFrom,
                                      entryParcEndTo: input.entryParcEndTo,
                                      exitParcStartFrom: input.exitParcStartFrom,
                                      exitParcStartTo: input.exitParcStartTo,
                                      exitParcEndFrom: input.exitParcEndFrom,
                                      exitParcEndTo: input.exitParcEndTo
                                    };
                            })),
                      dateFrom: formMoreDates.input.madcEndFrom,
                      dateTo: formMoreDates.input.madcEndTo,
                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                      startOrEnd: "end"
                    })), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  spacing: 1
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                            style: {
                              fontWeight: "600"
                            },
                            children: intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.entryDateParc)
                          }),
                      item: true
                    }), React.createElement(Flash_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                      disabled: Belt_List.every(orderType, (function (x) {
                              switch (x) {
                                case "RC" :
                                case "RR" :
                                case "VE" :
                                    return false;
                                default:
                                  return true;
                              }
                            })) || Belt_List.length(orderType) === 0,
                      onChangeFrom: Curry._1(formMoreDates.updateEntryParcStartFrom, (function (input, entryParcStartFrom) {
                              return {
                                      madcStartFrom: input.madcStartFrom,
                                      madcStartTo: input.madcStartTo,
                                      madcEndFrom: input.madcEndFrom,
                                      madcEndTo: input.madcEndTo,
                                      entryParcStartFrom: entryParcStartFrom,
                                      entryParcStartTo: input.entryParcStartTo,
                                      entryParcEndFrom: input.entryParcEndFrom,
                                      entryParcEndTo: input.entryParcEndTo,
                                      exitParcStartFrom: input.exitParcStartFrom,
                                      exitParcStartTo: input.exitParcStartTo,
                                      exitParcEndFrom: input.exitParcEndFrom,
                                      exitParcEndTo: input.exitParcEndTo
                                    };
                            })),
                      onChangeTo: Curry._1(formMoreDates.updateEntryParcStartTo, (function (input, entryParcStartTo) {
                              return {
                                      madcStartFrom: input.madcStartFrom,
                                      madcStartTo: input.madcStartTo,
                                      madcEndFrom: input.madcEndFrom,
                                      madcEndTo: input.madcEndTo,
                                      entryParcStartFrom: input.entryParcStartFrom,
                                      entryParcStartTo: entryParcStartTo,
                                      entryParcEndFrom: input.entryParcEndFrom,
                                      entryParcEndTo: input.entryParcEndTo,
                                      exitParcStartFrom: input.exitParcStartFrom,
                                      exitParcStartTo: input.exitParcStartTo,
                                      exitParcEndFrom: input.exitParcEndFrom,
                                      exitParcEndTo: input.exitParcEndTo
                                    };
                            })),
                      dateFrom: formMoreDates.input.entryParcStartFrom,
                      dateTo: formMoreDates.input.entryParcStartTo,
                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                      startOrEnd: "start"
                    }), React.createElement(Flash_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                      disabled: Belt_List.every(orderType, (function (x) {
                              switch (x) {
                                case "RC" :
                                case "RR" :
                                case "VE" :
                                    return false;
                                default:
                                  return true;
                              }
                            })) || Belt_List.length(orderType) === 0,
                      onChangeFrom: Curry._1(formMoreDates.updateEntryParcEndFrom, (function (input, entryParcEndFrom) {
                              return {
                                      madcStartFrom: input.madcStartFrom,
                                      madcStartTo: input.madcStartTo,
                                      madcEndFrom: input.madcEndFrom,
                                      madcEndTo: input.madcEndTo,
                                      entryParcStartFrom: input.entryParcStartFrom,
                                      entryParcStartTo: input.entryParcStartTo,
                                      entryParcEndFrom: entryParcEndFrom,
                                      entryParcEndTo: input.entryParcEndTo,
                                      exitParcStartFrom: input.exitParcStartFrom,
                                      exitParcStartTo: input.exitParcStartTo,
                                      exitParcEndFrom: input.exitParcEndFrom,
                                      exitParcEndTo: input.exitParcEndTo
                                    };
                            })),
                      onChangeTo: Curry._1(formMoreDates.updateEntryParcEndTo, (function (input, entryParcEndTo) {
                              return {
                                      madcStartFrom: input.madcStartFrom,
                                      madcStartTo: input.madcStartTo,
                                      madcEndFrom: input.madcEndFrom,
                                      madcEndTo: input.madcEndTo,
                                      entryParcStartFrom: input.entryParcStartFrom,
                                      entryParcStartTo: input.entryParcStartTo,
                                      entryParcEndFrom: input.entryParcEndFrom,
                                      entryParcEndTo: entryParcEndTo,
                                      exitParcStartFrom: input.exitParcStartFrom,
                                      exitParcStartTo: input.exitParcStartTo,
                                      exitParcEndFrom: input.exitParcEndFrom,
                                      exitParcEndTo: input.exitParcEndTo
                                    };
                            })),
                      dateFrom: formMoreDates.input.entryParcEndFrom,
                      dateTo: formMoreDates.input.entryParcEndTo,
                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                      startOrEnd: "end"
                    })), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  spacing: 1
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                            style: {
                              fontWeight: "600"
                            },
                            children: intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.exitDateParc)
                          }),
                      item: true
                    }), React.createElement(Flash_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                      disabled: Belt_List.every(orderType, (function (x) {
                              switch (x) {
                                case "RC" :
                                case "RR" :
                                case "VE" :
                                    return false;
                                default:
                                  return true;
                              }
                            })) || Belt_List.length(orderType) === 0,
                      onChangeFrom: Curry._1(formMoreDates.updateExitParcStartFrom, (function (input, exitParcStartFrom) {
                              return {
                                      madcStartFrom: input.madcStartFrom,
                                      madcStartTo: input.madcStartTo,
                                      madcEndFrom: input.madcEndFrom,
                                      madcEndTo: input.madcEndTo,
                                      entryParcStartFrom: input.entryParcStartFrom,
                                      entryParcStartTo: input.entryParcStartTo,
                                      entryParcEndFrom: input.entryParcEndFrom,
                                      entryParcEndTo: input.entryParcEndTo,
                                      exitParcStartFrom: exitParcStartFrom,
                                      exitParcStartTo: input.exitParcStartTo,
                                      exitParcEndFrom: input.exitParcEndFrom,
                                      exitParcEndTo: input.exitParcEndTo
                                    };
                            })),
                      onChangeTo: Curry._1(formMoreDates.updateExitParcStartTo, (function (input, exitParcStartTo) {
                              return {
                                      madcStartFrom: input.madcStartFrom,
                                      madcStartTo: input.madcStartTo,
                                      madcEndFrom: input.madcEndFrom,
                                      madcEndTo: input.madcEndTo,
                                      entryParcStartFrom: input.entryParcStartFrom,
                                      entryParcStartTo: input.entryParcStartTo,
                                      entryParcEndFrom: input.entryParcEndFrom,
                                      entryParcEndTo: input.entryParcEndTo,
                                      exitParcStartFrom: input.exitParcStartFrom,
                                      exitParcStartTo: exitParcStartTo,
                                      exitParcEndFrom: input.exitParcEndFrom,
                                      exitParcEndTo: input.exitParcEndTo
                                    };
                            })),
                      dateFrom: formMoreDates.input.exitParcStartFrom,
                      dateTo: formMoreDates.input.exitParcStartTo,
                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                      startOrEnd: "start"
                    }), React.createElement(Flash_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                      disabled: Belt_List.every(orderType, (function (x) {
                              switch (x) {
                                case "RC" :
                                case "RR" :
                                case "VE" :
                                    return false;
                                default:
                                  return true;
                              }
                            })) || Belt_List.length(orderType) === 0,
                      onChangeFrom: Curry._1(formMoreDates.updateExitParcEndFrom, (function (input, exitParcEndFrom) {
                              return {
                                      madcStartFrom: input.madcStartFrom,
                                      madcStartTo: input.madcStartTo,
                                      madcEndFrom: input.madcEndFrom,
                                      madcEndTo: input.madcEndTo,
                                      entryParcStartFrom: input.entryParcStartFrom,
                                      entryParcStartTo: input.entryParcStartTo,
                                      entryParcEndFrom: input.entryParcEndFrom,
                                      entryParcEndTo: input.entryParcEndTo,
                                      exitParcStartFrom: input.exitParcStartFrom,
                                      exitParcStartTo: input.exitParcStartTo,
                                      exitParcEndFrom: exitParcEndFrom,
                                      exitParcEndTo: input.exitParcEndTo
                                    };
                            })),
                      onChangeTo: Curry._1(formMoreDates.updateExitParcEndTo, (function (input, exitParcEndTo) {
                              return {
                                      madcStartFrom: input.madcStartFrom,
                                      madcStartTo: input.madcStartTo,
                                      madcEndFrom: input.madcEndFrom,
                                      madcEndTo: input.madcEndTo,
                                      entryParcStartFrom: input.entryParcStartFrom,
                                      entryParcStartTo: input.entryParcStartTo,
                                      entryParcEndFrom: input.entryParcEndFrom,
                                      entryParcEndTo: input.entryParcEndTo,
                                      exitParcStartFrom: input.exitParcStartFrom,
                                      exitParcStartTo: input.exitParcStartTo,
                                      exitParcEndFrom: input.exitParcEndFrom,
                                      exitParcEndTo: exitParcEndTo
                                    };
                            })),
                      dateFrom: formMoreDates.input.exitParcEndFrom,
                      dateTo: formMoreDates.input.exitParcEndTo,
                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                      startOrEnd: "end"
                    })), React.createElement(Core.Grid, {
                  children: React.createElement(Flash_Search_Modal_Detention_Box$DvmAdminFrontendFr.make, {
                        disabled: Belt_List.every(orderType, (function (x) {
                                switch (x) {
                                  case "RC" :
                                  case "RR" :
                                  case "VE" :
                                      return false;
                                  default:
                                    return true;
                                }
                              })) || Belt_List.length(orderType) === 0,
                        title: Messages_Flash_Search$DvmAdminFrontendFr.detentionDurationMin,
                        onChangeFrom: Curry._1(form.updateDetentionMinFrom, (function (input, detentionMinFrom) {
                                return {
                                        registrationStartFrom: input.registrationStartFrom,
                                        registrationStartTo: input.registrationStartTo,
                                        registrationEndFrom: input.registrationEndFrom,
                                        registrationEndTo: input.registrationEndTo,
                                        deliveryStartFrom: input.deliveryStartFrom,
                                        deliveryStartTo: input.deliveryStartTo,
                                        deliveryEndFrom: input.deliveryEndFrom,
                                        deliveryEndTo: input.deliveryEndTo,
                                        madaStartFrom: input.madaStartFrom,
                                        madaStartTo: input.madaStartTo,
                                        madaEndFrom: input.madaEndFrom,
                                        madaEndTo: input.madaEndTo,
                                        detentionMinFrom: detentionMinFrom,
                                        detentionMinTo: input.detentionMinTo,
                                        detentionMaxFrom: input.detentionMaxFrom,
                                        detentionMaxTo: input.detentionMaxTo
                                      };
                              })),
                        onChangeTo: Curry._1(form.updateDetentionMinTo, (function (input, detentionMinTo) {
                                return {
                                        registrationStartFrom: input.registrationStartFrom,
                                        registrationStartTo: input.registrationStartTo,
                                        registrationEndFrom: input.registrationEndFrom,
                                        registrationEndTo: input.registrationEndTo,
                                        deliveryStartFrom: input.deliveryStartFrom,
                                        deliveryStartTo: input.deliveryStartTo,
                                        deliveryEndFrom: input.deliveryEndFrom,
                                        deliveryEndTo: input.deliveryEndTo,
                                        madaStartFrom: input.madaStartFrom,
                                        madaStartTo: input.madaStartTo,
                                        madaEndFrom: input.madaEndFrom,
                                        madaEndTo: input.madaEndTo,
                                        detentionMinFrom: input.detentionMinFrom,
                                        detentionMinTo: detentionMinTo,
                                        detentionMaxFrom: input.detentionMaxFrom,
                                        detentionMaxTo: input.detentionMaxTo
                                      };
                              })),
                        valFrom: form.input.detentionMinFrom,
                        valTo: form.input.detentionMinTo,
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                      }),
                  container: true,
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  spacing: 1
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Flash_Search_Modal_Detention_Box$DvmAdminFrontendFr.make, {
                        disabled: Belt_List.every(orderType, (function (x) {
                                switch (x) {
                                  case "RC" :
                                  case "RR" :
                                  case "VE" :
                                      return false;
                                  default:
                                    return true;
                                }
                              })) || Belt_List.length(orderType) === 0,
                        title: Messages_Flash_Search$DvmAdminFrontendFr.detentionDurationMax,
                        onChangeFrom: Curry._1(form.updateDetentionMaxFrom, (function (input, detentionMaxFrom) {
                                return {
                                        registrationStartFrom: input.registrationStartFrom,
                                        registrationStartTo: input.registrationStartTo,
                                        registrationEndFrom: input.registrationEndFrom,
                                        registrationEndTo: input.registrationEndTo,
                                        deliveryStartFrom: input.deliveryStartFrom,
                                        deliveryStartTo: input.deliveryStartTo,
                                        deliveryEndFrom: input.deliveryEndFrom,
                                        deliveryEndTo: input.deliveryEndTo,
                                        madaStartFrom: input.madaStartFrom,
                                        madaStartTo: input.madaStartTo,
                                        madaEndFrom: input.madaEndFrom,
                                        madaEndTo: input.madaEndTo,
                                        detentionMinFrom: input.detentionMinFrom,
                                        detentionMinTo: input.detentionMinTo,
                                        detentionMaxFrom: detentionMaxFrom,
                                        detentionMaxTo: input.detentionMaxTo
                                      };
                              })),
                        onChangeTo: Curry._1(form.updateDetentionMaxTo, (function (input, detentionMaxTo) {
                                return {
                                        registrationStartFrom: input.registrationStartFrom,
                                        registrationStartTo: input.registrationStartTo,
                                        registrationEndFrom: input.registrationEndFrom,
                                        registrationEndTo: input.registrationEndTo,
                                        deliveryStartFrom: input.deliveryStartFrom,
                                        deliveryStartTo: input.deliveryStartTo,
                                        deliveryEndFrom: input.deliveryEndFrom,
                                        deliveryEndTo: input.deliveryEndTo,
                                        madaStartFrom: input.madaStartFrom,
                                        madaStartTo: input.madaStartTo,
                                        madaEndFrom: input.madaEndFrom,
                                        madaEndTo: input.madaEndTo,
                                        detentionMinFrom: input.detentionMinFrom,
                                        detentionMinTo: input.detentionMinTo,
                                        detentionMaxFrom: input.detentionMaxFrom,
                                        detentionMaxTo: detentionMaxTo
                                      };
                              })),
                        valFrom: form.input.detentionMaxFrom,
                        valTo: form.input.detentionMaxTo,
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                      }),
                  container: true,
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  spacing: 1
                }));
}

var InputStyles;

var make = Flash_Search_Modal_Date_Filters;

export {
  InputStyles ,
  make ,
}
/* react Not a pure module */
