// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../common/utils/Utils_Form.bs.js";

var validators_siret = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.siret.toUpperCase());
    })
};

var validators_siren = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.siren.toUpperCase());
    })
};

var validators_name = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.name);
    })
};

var validators_zipCode = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateWithRegex(param.zipCode, /^(\d{5})$/);
    })
};

var validators_endDate = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateDate(param.endDate, undefined);
    })
};

var validators = {
  siret: validators_siret,
  siren: validators_siren,
  name: validators_name,
  street: undefined,
  streetNbr: undefined,
  additionalAddressInfo: undefined,
  zipCode: validators_zipCode,
  city: undefined,
  countryCode: undefined,
  hierarchy: undefined,
  codeApe: undefined,
  endDate: validators_endDate
};

function initialFieldsStatuses(_input) {
  return {
          siret: /* Pristine */0,
          siren: /* Pristine */0,
          name: /* Pristine */0,
          street: /* Pristine */0,
          streetNbr: /* Pristine */0,
          additionalAddressInfo: /* Pristine */0,
          zipCode: /* Pristine */0,
          city: /* Pristine */0,
          countryCode: /* Pristine */0,
          hierarchy: /* Pristine */0,
          codeApe: /* Pristine */0,
          endDate: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.siret;
  var match_0 = match ? match._0 : Curry._1(validators.siret.validate, input);
  var match$1 = fieldsStatuses.siren;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.siren.validate, input);
  var match$2 = fieldsStatuses.name;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.name.validate, input);
  var match_0$3 = {
    TAG: /* Ok */0,
    _0: input.street
  };
  var match_0$4 = {
    TAG: /* Ok */0,
    _0: input.streetNbr
  };
  var match_0$5 = {
    TAG: /* Ok */0,
    _0: input.additionalAddressInfo
  };
  var match$3 = fieldsStatuses.zipCode;
  var match_0$6 = match$3 ? match$3._0 : Curry._1(validators.zipCode.validate, input);
  var match_0$7 = {
    TAG: /* Ok */0,
    _0: input.city
  };
  var match_0$8 = {
    TAG: /* Ok */0,
    _0: input.countryCode
  };
  var match_0$9 = {
    TAG: /* Ok */0,
    _0: input.hierarchy
  };
  var match_0$10 = {
    TAG: /* Ok */0,
    _0: input.codeApe
  };
  var match$4 = fieldsStatuses.endDate;
  var match_0$11 = match$4 ? match$4._0 : Curry._1(validators.endDate.validate, input);
  var siretResult = match_0;
  var siretResult$1;
  if (siretResult.TAG === /* Ok */0) {
    var sirenResult = match_0$1;
    if (sirenResult.TAG === /* Ok */0) {
      var nameResult = match_0$2;
      if (nameResult.TAG === /* Ok */0) {
        var streetResult = match_0$3;
        if (streetResult.TAG === /* Ok */0) {
          var streetNbrResult = match_0$4;
          if (streetNbrResult.TAG === /* Ok */0) {
            var additionalAddressInfoResult = match_0$5;
            if (additionalAddressInfoResult.TAG === /* Ok */0) {
              var zipCodeResult = match_0$6;
              if (zipCodeResult.TAG === /* Ok */0) {
                var cityResult = match_0$7;
                if (cityResult.TAG === /* Ok */0) {
                  var countryCodeResult = match_0$8;
                  if (countryCodeResult.TAG === /* Ok */0) {
                    var hierarchyResult = match_0$9;
                    if (hierarchyResult.TAG === /* Ok */0) {
                      var codeApeResult = match_0$10;
                      if (codeApeResult.TAG === /* Ok */0) {
                        var endDateResult = match_0$11;
                        if (endDateResult.TAG === /* Ok */0) {
                          return {
                                  TAG: /* Valid */0,
                                  output: {
                                    siret: siretResult._0,
                                    siren: sirenResult._0,
                                    name: nameResult._0,
                                    street: streetResult._0,
                                    streetNbr: streetNbrResult._0,
                                    additionalAddressInfo: additionalAddressInfoResult._0,
                                    zipCode: zipCodeResult._0,
                                    city: cityResult._0,
                                    countryCode: countryCodeResult._0,
                                    hierarchy: hierarchyResult._0,
                                    codeApe: codeApeResult._0,
                                    endDate: endDateResult._0
                                  },
                                  fieldsStatuses: {
                                    siret: /* Dirty */{
                                      _0: siretResult,
                                      _1: /* Shown */0
                                    },
                                    siren: /* Dirty */{
                                      _0: sirenResult,
                                      _1: /* Shown */0
                                    },
                                    name: /* Dirty */{
                                      _0: nameResult,
                                      _1: /* Shown */0
                                    },
                                    street: /* Dirty */{
                                      _0: streetResult,
                                      _1: /* Hidden */1
                                    },
                                    streetNbr: /* Dirty */{
                                      _0: streetNbrResult,
                                      _1: /* Hidden */1
                                    },
                                    additionalAddressInfo: /* Dirty */{
                                      _0: additionalAddressInfoResult,
                                      _1: /* Hidden */1
                                    },
                                    zipCode: /* Dirty */{
                                      _0: zipCodeResult,
                                      _1: /* Shown */0
                                    },
                                    city: /* Dirty */{
                                      _0: cityResult,
                                      _1: /* Hidden */1
                                    },
                                    countryCode: /* Dirty */{
                                      _0: countryCodeResult,
                                      _1: /* Hidden */1
                                    },
                                    hierarchy: /* Dirty */{
                                      _0: hierarchyResult,
                                      _1: /* Hidden */1
                                    },
                                    codeApe: /* Dirty */{
                                      _0: codeApeResult,
                                      _1: /* Hidden */1
                                    },
                                    endDate: /* Dirty */{
                                      _0: endDateResult,
                                      _1: /* Shown */0
                                    }
                                  },
                                  collectionsStatuses: undefined
                                };
                        }
                        siretResult$1 = siretResult;
                      } else {
                        siretResult$1 = siretResult;
                      }
                    } else {
                      siretResult$1 = siretResult;
                    }
                  } else {
                    siretResult$1 = siretResult;
                  }
                } else {
                  siretResult$1 = siretResult;
                }
              } else {
                siretResult$1 = siretResult;
              }
            } else {
              siretResult$1 = siretResult;
            }
          } else {
            siretResult$1 = siretResult;
          }
        } else {
          siretResult$1 = siretResult;
        }
      } else {
        siretResult$1 = siretResult;
      }
    } else {
      siretResult$1 = siretResult;
    }
  } else {
    siretResult$1 = siretResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            siret: /* Dirty */{
              _0: siretResult$1,
              _1: /* Shown */0
            },
            siren: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            name: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            street: /* Dirty */{
              _0: match_0$3,
              _1: /* Hidden */1
            },
            streetNbr: /* Dirty */{
              _0: match_0$4,
              _1: /* Hidden */1
            },
            additionalAddressInfo: /* Dirty */{
              _0: match_0$5,
              _1: /* Hidden */1
            },
            zipCode: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            city: /* Dirty */{
              _0: match_0$7,
              _1: /* Hidden */1
            },
            countryCode: /* Dirty */{
              _0: match_0$8,
              _1: /* Hidden */1
            },
            hierarchy: /* Dirty */{
              _0: match_0$9,
              _1: /* Hidden */1
            },
            codeApe: /* Dirty */{
              _0: match_0$10,
              _1: /* Hidden */1
            },
            endDate: /* Dirty */{
              _0: match_0$11,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurSiretField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.siret, validators_siret, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: status,
                                  siren: init.siren,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  hierarchy: init.hierarchy,
                                  codeApe: init.codeApe,
                                  endDate: init.endDate
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSirenField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.siren, validators_siren, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  siren: status,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  hierarchy: init.hierarchy,
                                  codeApe: init.codeApe,
                                  endDate: init.endDate
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurNameField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.name, validators_name, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  siren: init.siren,
                                  name: status,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  hierarchy: init.hierarchy,
                                  codeApe: init.codeApe,
                                  endDate: init.endDate
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurStreetField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithoutValidator(state.input.street, state.fieldsStatuses.street, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  siren: init.siren,
                                  name: init.name,
                                  street: status,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  hierarchy: init.hierarchy,
                                  codeApe: init.codeApe,
                                  endDate: init.endDate
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurStreetNbrField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithoutValidator(state.input.streetNbr, state.fieldsStatuses.streetNbr, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  siren: init.siren,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: status,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  hierarchy: init.hierarchy,
                                  codeApe: init.codeApe,
                                  endDate: init.endDate
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAdditionalAddressInfoField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithoutValidator(state.input.additionalAddressInfo, state.fieldsStatuses.additionalAddressInfo, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  siren: init.siren,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: status,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  hierarchy: init.hierarchy,
                                  codeApe: init.codeApe,
                                  endDate: init.endDate
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurZipCodeField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.zipCode, validators_zipCode, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  siren: init.siren,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: status,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  hierarchy: init.hierarchy,
                                  codeApe: init.codeApe,
                                  endDate: init.endDate
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCityField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithoutValidator(state.input.city, state.fieldsStatuses.city, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  siren: init.siren,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: status,
                                  countryCode: init.countryCode,
                                  hierarchy: init.hierarchy,
                                  codeApe: init.codeApe,
                                  endDate: init.endDate
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCountryCodeField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithoutValidator(state.input.countryCode, state.fieldsStatuses.countryCode, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  siren: init.siren,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: status,
                                  hierarchy: init.hierarchy,
                                  codeApe: init.codeApe,
                                  endDate: init.endDate
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurHierarchyField */9 :
                  var result$9 = Formality.validateFieldOnBlurWithoutValidator(state.input.hierarchy, state.fieldsStatuses.hierarchy, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  siren: init.siren,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  hierarchy: status,
                                  codeApe: init.codeApe,
                                  endDate: init.endDate
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCodeApeField */10 :
                  var result$10 = Formality.validateFieldOnBlurWithoutValidator(state.input.codeApe, state.fieldsStatuses.codeApe, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  siren: init.siren,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  hierarchy: init.hierarchy,
                                  codeApe: status,
                                  endDate: init.endDate
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEndDateField */11 :
                  var result$11 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.endDate, validators_endDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  siret: init.siret,
                                  siren: init.siren,
                                  name: init.name,
                                  street: init.street,
                                  streetNbr: init.streetNbr,
                                  additionalAddressInfo: init.additionalAddressInfo,
                                  zipCode: init.zipCode,
                                  city: init.city,
                                  countryCode: init.countryCode,
                                  hierarchy: init.hierarchy,
                                  codeApe: init.codeApe,
                                  endDate: status
                                };
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */12 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */12,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */13,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */15);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */14);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */13 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */14 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */15 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateSiretField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.siret, state.submissionStatus, validators_siret, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: status,
                                            siren: init.siren,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            hierarchy: init.hierarchy,
                                            codeApe: init.codeApe,
                                            endDate: init.endDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSirenField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.siren, state.submissionStatus, validators_siren, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: init.siret,
                                            siren: status,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            hierarchy: init.hierarchy,
                                            codeApe: init.codeApe,
                                            endDate: init.endDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateNameField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.name, state.submissionStatus, validators_name, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: init.siret,
                                            siren: init.siren,
                                            name: status,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            hierarchy: init.hierarchy,
                                            codeApe: init.codeApe,
                                            endDate: init.endDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateStreetField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$3.street, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: init.siret,
                                            siren: init.siren,
                                            name: init.name,
                                            street: status,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            hierarchy: init.hierarchy,
                                            codeApe: init.codeApe,
                                            endDate: init.endDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateStreetNbrField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$4.streetNbr, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: init.siret,
                                            siren: init.siren,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: status,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            hierarchy: init.hierarchy,
                                            codeApe: init.codeApe,
                                            endDate: init.endDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAdditionalAddressInfoField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$5.additionalAddressInfo, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: init.siret,
                                            siren: init.siren,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: status,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            hierarchy: init.hierarchy,
                                            codeApe: init.codeApe,
                                            endDate: init.endDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateZipCodeField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.zipCode, state.submissionStatus, validators_zipCode, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: init.siret,
                                            siren: init.siren,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: status,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            hierarchy: init.hierarchy,
                                            codeApe: init.codeApe,
                                            endDate: init.endDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCityField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$7.city, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: init.siret,
                                            siren: init.siren,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: status,
                                            countryCode: init.countryCode,
                                            hierarchy: init.hierarchy,
                                            codeApe: init.codeApe,
                                            endDate: init.endDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCountryCodeField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$8.countryCode, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: init.siret,
                                            siren: init.siren,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: status,
                                            hierarchy: init.hierarchy,
                                            codeApe: init.codeApe,
                                            endDate: init.endDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateHierarchyField */9 :
                  var nextInput$9 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$9.hierarchy, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: init.siret,
                                            siren: init.siren,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            hierarchy: status,
                                            codeApe: init.codeApe,
                                            endDate: init.endDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCodeApeField */10 :
                  var nextInput$10 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$10.codeApe, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: init.siret,
                                            siren: init.siren,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            hierarchy: init.hierarchy,
                                            codeApe: status,
                                            endDate: init.endDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEndDateField */11 :
                  var nextInput$11 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$11, state.fieldsStatuses.endDate, state.submissionStatus, validators_endDate, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            siret: init.siret,
                                            siren: init.siren,
                                            name: init.name,
                                            street: init.street,
                                            streetNbr: init.streetNbr,
                                            additionalAddressInfo: init.additionalAddressInfo,
                                            zipCode: init.zipCode,
                                            city: init.city,
                                            countryCode: init.countryCode,
                                            hierarchy: init.hierarchy,
                                            codeApe: init.codeApe,
                                            endDate: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */12 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */13 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */14 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateSiret: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSiretField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateSiren: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSirenField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateNameField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateStreet: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateStreetField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateStreetNbr: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateStreetNbrField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateAdditionalAddressInfo: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAdditionalAddressInfoField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateZipCode: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateZipCodeField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCity: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCityField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCountryCode: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCountryCodeField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateHierarchy: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateHierarchyField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCodeApe: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCodeApeField */10,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEndDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEndDateField */11,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurSiret: (function (param) {
              Curry._1(dispatch, /* BlurSiretField */0);
            }),
          blurSiren: (function (param) {
              Curry._1(dispatch, /* BlurSirenField */1);
            }),
          blurName: (function (param) {
              Curry._1(dispatch, /* BlurNameField */2);
            }),
          blurStreet: (function (param) {
              Curry._1(dispatch, /* BlurStreetField */3);
            }),
          blurStreetNbr: (function (param) {
              Curry._1(dispatch, /* BlurStreetNbrField */4);
            }),
          blurAdditionalAddressInfo: (function (param) {
              Curry._1(dispatch, /* BlurAdditionalAddressInfoField */5);
            }),
          blurZipCode: (function (param) {
              Curry._1(dispatch, /* BlurZipCodeField */6);
            }),
          blurCity: (function (param) {
              Curry._1(dispatch, /* BlurCityField */7);
            }),
          blurCountryCode: (function (param) {
              Curry._1(dispatch, /* BlurCountryCodeField */8);
            }),
          blurHierarchy: (function (param) {
              Curry._1(dispatch, /* BlurHierarchyField */9);
            }),
          blurCodeApe: (function (param) {
              Curry._1(dispatch, /* BlurCodeApeField */10);
            }),
          blurEndDate: (function (param) {
              Curry._1(dispatch, /* BlurEndDateField */11);
            }),
          siretResult: Formality.exposeFieldResult(state.fieldsStatuses.siret),
          sirenResult: Formality.exposeFieldResult(state.fieldsStatuses.siren),
          nameResult: Formality.exposeFieldResult(state.fieldsStatuses.name),
          streetResult: Formality.exposeFieldResult(state.fieldsStatuses.street),
          streetNbrResult: Formality.exposeFieldResult(state.fieldsStatuses.streetNbr),
          additionalAddressInfoResult: Formality.exposeFieldResult(state.fieldsStatuses.additionalAddressInfo),
          zipCodeResult: Formality.exposeFieldResult(state.fieldsStatuses.zipCode),
          cityResult: Formality.exposeFieldResult(state.fieldsStatuses.city),
          countryCodeResult: Formality.exposeFieldResult(state.fieldsStatuses.countryCode),
          hierarchyResult: Formality.exposeFieldResult(state.fieldsStatuses.hierarchy),
          codeApeResult: Formality.exposeFieldResult(state.fieldsStatuses.codeApe),
          endDateResult: Formality.exposeFieldResult(state.fieldsStatuses.endDate),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (!match.siret && !match.siren && !match.name && !match.street && !match.streetNbr && !match.additionalAddressInfo && !match.zipCode && !match.city && !match.countryCode && !match.hierarchy && !match.codeApe && !match.endDate) {
                return false;
              }
              return true;
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */12);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */13);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */14);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */14,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */15);
            })
        };
}

var Form = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialInput = {
  siret: "",
  siren: "",
  name: "",
  street: "",
  streetNbr: "",
  additionalAddressInfo: "",
  zipCode: "",
  city: "",
  countryCode: "",
  hierarchy: "",
  codeApe: "",
  endDate: undefined
};

export {
  Form ,
  initialInput ,
}
/* react Not a pure module */
