// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../theme/Theme_Colors.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";

function Flash_Edit_Accordion(Props) {
  var title = Props.title;
  var children = Props.children;
  var expanded = Props.expanded;
  var setter = Props.setter;
  var disabled = Props.disabled;
  var tmp = {
    elevation: 0,
    children: null,
    expanded: expanded,
    onChange: (function (param, expanded) {
        Curry._1(setter, (function (param) {
                return expanded;
              }));
      }),
    square: true,
    style: {
      backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight1,
      padding: "20px 60px"
    }
  };
  if (disabled !== undefined) {
    tmp.disabled = disabled;
  }
  return React.createElement(Core.Accordion, tmp, React.createElement(Core.AccordionSummary, {
                  children: React.createElement(Core.Grid, {
                        alignItems: "flex-end",
                        children: null,
                        container: true,
                        item: true,
                        spacing: 2
                      }, React.createElement(Core.Grid, {
                            children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                  size: /* Large */3,
                                  type_: expanded ? /* PadUp */10 : /* PadDown */11
                                }),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                  color: "primary",
                                  children: title,
                                  variant: "h3"
                                }),
                            item: true
                          }))
                }), React.createElement(Core.AccordionDetails, {
                  children: children
                }));
}

var make = Flash_Edit_Accordion;

export {
  make ,
}
/* react Not a pure module */
