// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../../common/components/icons/App_Icon.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../common/components/button/App_Button.bs.js";
import * as Flash_Types$DvmAdminFrontendFr from "../../../Flash_Types.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../common/App_Typography.bs.js";
import * as Flash_Edit_Api$DvmAdminFrontendFr from "../../Flash_Edit_Api.bs.js";
import * as Flash_Edit_Form$DvmAdminFrontendFr from "../../Flash_Edit_Form.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as Flash_Edit_Types$DvmAdminFrontendFr from "../../Flash_Edit_Types.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../../../context/user/User_Types_Utils.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_RadioFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_RadioFormInput.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Flash_Edit_Accordion$DvmAdminFrontendFr from "../Flash_Edit_Accordion.bs.js";
import * as Flash_Edit_DateInput$DvmAdminFrontendFr from "../Flash_Edit_DateInput.bs.js";
import * as Messages_Flash_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/flash/Messages_Flash_Detail.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_FinancialCodes$DvmAdminFrontendFr from "../../../../../intl/messages/easypack/Messages_FinancialCodes.bs.js";
import * as Flash_Edit_Details_Dates$DvmAdminFrontendFr from "../Flash_Edit_Details_Dates.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Flash_Edit_Info_Old_Vehicle$DvmAdminFrontendFr from "../Flash_Edit_Info_Old_Vehicle.bs.js";
import * as Flash_Edit_Value_Percentage_Check$DvmAdminFrontendFr from "../Flash_Edit_Value_Percentage_Check.bs.js";
import * as Flash_Edit_Details_NonFleet_Dealer$DvmAdminFrontendFr from "./Flash_Edit_Details_NonFleet_Dealer.bs.js";
import * as Flash_Edit_NonFleet_New_Vehicle_Page$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_New_Vehicle_Page.bs.js";

function roundFloat($$float, precision) {
  return Belt_Option.getWithDefault(Belt_Float.fromString($$float.toFixed(precision)), 0.0);
}

function usePrevious(value) {
  var ref = React.useRef(undefined);
  React.useEffect((function () {
          ref.current = value;
        }), [value]);
  return ref.current;
}

function usePreviousString(value) {
  var ref = React.useRef("");
  React.useEffect((function () {
          ref.current = value;
        }), [value]);
  return ref.current;
}

function usePreviousFloat(value) {
  var ref = React.useRef(0.0);
  React.useEffect((function () {
          ref.current = value;
        }), [value]);
  return ref.current;
}

function Flash_Edit_Details_NonFleet(Props) {
  var form = Props.form;
  var formAdditional = Props.formAdditional;
  var formVO = Props.formVO;
  var formGeneralDates = Props.formGeneralDates;
  var formNonFleetNewVehicle = Props.formNonFleetNewVehicle;
  var budgets = Props.budgets;
  var detailREPRExpanded = Props.detailREPRExpanded;
  var setDetailREPRExpanded = Props.setDetailREPRExpanded;
  var step = Props.step;
  var setStep = Props.setStep;
  var detailNonSOFinished = Props.detailNonSOFinished;
  var detailRRorRCFinished = Props.detailRRorRCFinished;
  var brandsList = Props.brandsList;
  var oldVehicleExpanded = Props.oldVehicleExpanded;
  var setOldVehicleExpanded = Props.setOldVehicleExpanded;
  var newVehicleExpanded = Props.newVehicleExpanded;
  var setNewVehicleExpanded = Props.setNewVehicleExpanded;
  var fetchFlashModelVersionsForCumulables = Props.fetchFlashModelVersionsForCumulables;
  var newVehicleResult = Props.newVehicleResult;
  var setNewVehicleResult = Props.setNewVehicleResult;
  var flashId = Props.flashId;
  var flash = Props.flash;
  var setDuplicateActionNewVehicle = Props.setDuplicateActionNewVehicle;
  var nonFleetDealerExpanded = Props.nonFleetDealerExpanded;
  var setNonFleetDealerExpanded = Props.setNonFleetDealerExpanded;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setVATResult = match$2[1];
  var vatResult = match$2[0];
  var match$3 = React.useState(function () {
        return /* [] */0;
      });
  var setEasypackList = match$3[1];
  var match$4 = React.useState(function () {
        return 0.0;
      });
  var setAmountWithVAT = match$4[1];
  var amountWithVAT = match$4[0];
  var previousNumberType = usePrevious(form.input.numberType);
  var previousValue = usePreviousString(form.input.value);
  var previousValueVAT = usePreviousFloat(amountWithVAT);
  var refreshFinancialCodes = function (param) {
    if (form.input.orderType === /* VE */2 || form.input.orderType === /* VD */3 || form.input.orderType === /* CL */1) {
      return Flash_Edit_Api$DvmAdminFrontendFr.queryForEasypack(userManager, setEasypackList, selectedRole, formGeneralDates.input.startDate, formGeneralDates.input.endDate);
    }
    
  };
  React.useEffect((function () {
          Flash_Edit_Api$DvmAdminFrontendFr.fetchVAT(userManager, setVATResult, selectedRole);
          refreshFinancialCodes(undefined);
        }), []);
  React.useEffect((function () {
          if (previousValueVAT !== amountWithVAT && Belt_Option.getWithDefault(form.input.numberType, /* NOTSET */2) === /* EURO */1) {
            var vatFloat = App_Types_Result$DvmAdminFrontendFr.mapWithDefault(vatResult, undefined, (function (vat) {
                    return Belt_Option.mapWithDefault(vat.response, undefined, Belt_Float.fromString);
                  }));
            Belt_Option.mapWithDefault(vatFloat, undefined, (function (vatFloat) {
                    Curry._2(form.updateValue, (function (input, value) {
                            var newrecord = Caml_obj.obj_dup(input);
                            newrecord.value = value;
                            return newrecord;
                          }), String(roundFloat(amountWithVAT / (vatFloat / 100.0 + 1.0), 2)));
                  }));
          }
          
        }), [amountWithVAT]);
  React.useEffect((function () {
          if ((previousValue !== form.input.value || form.input.value !== "0") && Belt_Option.getWithDefault(form.input.numberType, /* NOTSET */2) === /* EURO */1) {
            var vatFloat = App_Types_Result$DvmAdminFrontendFr.mapWithDefault(vatResult, undefined, (function (vat) {
                    return Belt_Option.mapWithDefault(vat.response, undefined, Belt_Float.fromString);
                  }));
            Belt_Option.mapWithDefault(vatFloat, undefined, (function (vatFloat) {
                    Belt_Option.mapWithDefault(Belt_Float.fromString(form.input.value), undefined, (function (value) {
                            Curry._1(setAmountWithVAT, (function (param) {
                                    return roundFloat(value * (vatFloat / 100.0 + 1.0), 2);
                                  }));
                          }));
                  }));
          }
          
        }), [
        form.input.value,
        vatResult
      ]);
  React.useEffect((function () {
          var match = form.input.numberType;
          if (previousNumberType !== undefined && match !== undefined && Flash_Types$DvmAdminFrontendFr.ValueType.toString(previousNumberType) !== Flash_Types$DvmAdminFrontendFr.ValueType.toString(match)) {
            Curry._1(setAmountWithVAT, (function (param) {
                    return 0.0;
                  }));
            Curry._2(form.updateValue, (function (input, value) {
                    var newrecord = Caml_obj.obj_dup(input);
                    newrecord.value = value;
                    return newrecord;
                  }), "0");
            Belt_Option.mapWithDefault(form.input.numberType, undefined, (function (param) {
                    Curry._2(form.updateValue, (function (input, value) {
                            var newrecord = Caml_obj.obj_dup(input);
                            newrecord.value = value;
                            return newrecord;
                          }), String(0.0));
                  }));
          }
          
        }), [form.input.numberType]);
  React.useEffect((function () {
          var match = form.input.actionType;
          if (typeof match === "number") {
            
          } else {
            if (formNonFleetNewVehicle.input.vehicleType === /* NOTSET */3) {
              Curry._2(formNonFleetNewVehicle.updateVehicleType, (function (input, vehicleType) {
                      return {
                              models: input.models,
                              modelPhases: input.modelPhases,
                              mappingDirection: input.mappingDirection,
                              engineTypes: input.engineTypes,
                              versions: input.versions,
                              brandsNewCar: input.brandsNewCar,
                              vehicleType: vehicleType,
                              includeAllModels: input.includeAllModels
                            };
                    }), /* VPVU */2);
            }
            if (form.input.financialCodeOrientation === /* NOTSET */2) {
              Curry._2(form.updateFinancialCodeOrientation, (function (input, financialCodeOrientation) {
                      var newrecord = Caml_obj.obj_dup(input);
                      newrecord.financialCodeOrientation = financialCodeOrientation;
                      return newrecord;
                    }), /* ALL */0);
            }
            
          }
        }), [form.input.actionType]);
  React.useEffect((function () {
          if (Belt_Option.isNone(flashId)) {
            if (form.input.requestType === "PSER") {
              Curry._2(form.updatePriceType, (function (input, priceType) {
                      var newrecord = Caml_obj.obj_dup(input);
                      newrecord.priceType = priceType;
                      return newrecord;
                    }), "PVC_HT");
            } else {
              Curry._2(form.updatePriceType, (function (input, priceType) {
                      var newrecord = Caml_obj.obj_dup(input);
                      newrecord.priceType = priceType;
                      return newrecord;
                    }), Flash_Edit_Form$DvmAdminFrontendFr.initialState.priceType);
            }
          }
          
        }), [form.input.requestType]);
  React.useEffect((function () {
          if (Belt_Option.isNone(flashId)) {
            Curry._2(form.updateRequestType, (function (input, requestType) {
                    var newrecord = Caml_obj.obj_dup(input);
                    newrecord.requestType = requestType;
                    return newrecord;
                  }), Flash_Edit_Form$DvmAdminFrontendFr.initialState.requestType);
            Curry._2(form.updatePriceType, (function (input, priceType) {
                    var newrecord = Caml_obj.obj_dup(input);
                    newrecord.priceType = priceType;
                    return newrecord;
                  }), Flash_Edit_Form$DvmAdminFrontendFr.initialState.priceType);
          }
          
        }), [form.input.orderType]);
  var tmp;
  if (form.input.orderType === /* VE */2) {
    tmp = null;
  } else {
    var tmp$1 = {
      value: Flash_Edit_Types$DvmAdminFrontendFr.StocDate.toString(form.input.stocDateType),
      options: {
        hd: {
          label: {
            TAG: /* String */3,
            _0: "MADA"
          },
          value: "MADA"
        },
        tl: {
          hd: {
            label: {
              TAG: /* String */3,
              _0: "MADC"
            },
            value: "MADC"
          },
          tl: {
            hd: {
              label: {
                TAG: /* Message */0,
                _0: Messages_Flash_Detail$DvmAdminFrontendFr.madaMadcNoneSelected
              },
              value: ""
            },
            tl: /* [] */0
          }
        }
      },
      onChange: (function (value) {
          Curry._2(form.updateStocDateType, (function (input, stocDateType) {
                  var newrecord = Caml_obj.obj_dup(input);
                  newrecord.stocDateType = stocDateType;
                  return newrecord;
                }), Belt_Option.getWithDefault(Flash_Edit_Types$DvmAdminFrontendFr.StocDate.fromString(value), /* NOTSET */2));
        }),
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.extraLongInput
    };
    if (form.stocDateTypeResult !== undefined) {
      tmp$1.validationResult = Caml_option.valFromOption(form.stocDateTypeResult);
    }
    tmp = React.createElement(Core.Grid, {
          children: React.createElement(Flash_Edit_DateInput$DvmAdminFrontendFr.make, {
                icon: /* DealerFlag */35,
                message: Messages_Flash_Detail$DvmAdminFrontendFr.modularActionMADAorMADCPeriod,
                startDateValue: form.input.stocDateStart,
                startDateOnChange: Curry._1(form.updateStocDateStart, (function (input, stocDateStart) {
                        var newrecord = Caml_obj.obj_dup(input);
                        newrecord.stocDateStart = stocDateStart;
                        return newrecord;
                      })),
                startDateResult: form.stocDateStartResult,
                endDateValue: form.input.stocDateEnd,
                endDateOnChange: Curry._1(form.updateStocDateEnd, (function (input, stocDateEnd) {
                        var newrecord = Caml_obj.obj_dup(input);
                        newrecord.stocDateEnd = stocDateEnd;
                        return newrecord;
                      })),
                endDateResult: form.stocDateEndResult,
                startDateHidden: form.input.stocDateType === /* NOTSET */2,
                endDateHidden: form.input.stocDateType === /* NOTSET */2,
                controller: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, tmp$1)
              }),
          item: true
        });
  }
  var tmp$2 = {
    value: Belt_Option.mapWithDefault(form.input.numberType, "", Flash_Types$DvmAdminFrontendFr.ValueType.toString),
    options: {
      hd: {
        label: {
          TAG: /* Message */0,
          _0: Messages_Flash_Edit$DvmAdminFrontendFr.inPercent
        },
        value: "PERCENTAGE"
      },
      tl: {
        hd: {
          label: {
            TAG: /* Message */0,
            _0: Messages_Flash_Edit$DvmAdminFrontendFr.inEuro
          },
          value: "AMOUNT"
        },
        tl: /* [] */0
      }
    },
    onChange: (function (value) {
        Curry._2(form.updateNumberType, (function (input, numberType) {
                var newrecord = Caml_obj.obj_dup(input);
                newrecord.numberType = numberType;
                return newrecord;
              }), Flash_Types$DvmAdminFrontendFr.ValueType.fromString(value));
      }),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
  };
  if (form.numberTypeResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.numberTypeResult);
  }
  var tmp$3;
  if ((form.input.orderType === /* VE */2 || form.input.orderType === /* RR */4 || form.input.orderType === /* RC */5 || form.input.orderType === /* CL */1 && form.input.requestType.length > 0 || User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* Spain */1) && Belt_Option.getWithDefault(form.input.numberType, /* EURO */1) === /* PERCENT */0) {
    var tmp$4 = {
      value: form.input.priceType,
      options: Belt_List.concat({
            hd: {
              label: {
                TAG: /* String */3,
                _0: "PVR"
              },
              value: "PVRINVOICEAMOUNT"
            },
            tl: {
              hd: {
                label: {
                  TAG: /* String */3,
                  _0: "PVC"
                },
                value: "PVC_HT"
              },
              tl: /* [] */0
            }
          }, form.input.requestType === "PSER" && form.input.orderType === /* CL */1 ? ({
                hd: {
                  label: {
                    TAG: /* String */3,
                    _0: "Prix de transformation"
                  },
                  value: "TRANSFORMATION"
                },
                tl: /* [] */0
              }) : /* [] */0),
      onChange: (function (value) {
          Curry._2(form.updatePriceType, (function (input, priceType) {
                  var newrecord = Caml_obj.obj_dup(input);
                  newrecord.priceType = priceType;
                  return newrecord;
                }), value);
        }),
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
    };
    if (form.priceTypeResult !== undefined) {
      tmp$4.validationResult = Caml_option.valFromOption(form.priceTypeResult);
    }
    tmp$3 = React.createElement(Core.Grid, {
          children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, tmp$4),
          item: true,
          xs: Grid$Mui.Xs[6]
        });
  } else {
    tmp$3 = null;
  }
  var tmp$5 = {
    onChange: Curry._1(form.updateValue, (function (input, value) {
            var newrecord = Caml_obj.obj_dup(input);
            newrecord.value = value;
            return newrecord;
          })),
    value: form.input.value,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Flash_Edit$DvmAdminFrontendFr.bonusAmountHT,
    required: /* OnlyLabel */1,
    inputType: "number"
  };
  if (form.valueResult !== undefined) {
    tmp$5.validationResult = Caml_option.valFromOption(form.valueResult);
  }
  var tmp$6;
  if (form.input.includeFinancialCode) {
    var tmp$7 = {
      value: Flash_Edit_Types$DvmAdminFrontendFr.FinancialCodeOrientation.toString(form.input.financialCodeOrientation),
      options: {
        hd: {
          label: {
            TAG: /* Message */0,
            _0: Messages_Common$DvmAdminFrontendFr.everything
          },
          value: "ALL"
        },
        tl: {
          hd: {
            label: {
              TAG: /* Message */0,
              _0: Messages_Common$DvmAdminFrontendFr.selection
            },
            value: "SELECTION"
          },
          tl: /* [] */0
        }
      },
      onChange: (function (val) {
          Curry._2(form.updateFinancialCodeOrientation, (function (input, financialCodeOrientation) {
                  var newrecord = Caml_obj.obj_dup(input);
                  newrecord.financialCodeOrientation = financialCodeOrientation;
                  return newrecord;
                }), Belt_Option.getWithDefault(Flash_Edit_Types$DvmAdminFrontendFr.FinancialCodeOrientation.fromString(val), /* NOTSET */2));
        }),
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.extraLongInput
    };
    if (form.financialCodeOrientationResult !== undefined) {
      tmp$7.validationResult = Caml_option.valFromOption(form.financialCodeOrientationResult);
    }
    var match$5 = form.input.financialCodeOrientation;
    var tmp$8;
    if (match$5 !== 1) {
      tmp$8 = null;
    } else {
      var tmp$9 = {
        label: Messages_FinancialCodes$DvmAdminFrontendFr.codeId,
        value: {
          TAG: /* Multiple */1,
          _0: {
            value: form.input.financialCodes,
            onChange: Curry._1(form.updateFinancialCodes, (function (input, financialCodes) {
                    var newrecord = Caml_obj.obj_dup(input);
                    newrecord.financialCodes = financialCodes;
                    return newrecord;
                  }))
          }
        },
        options: {
          TAG: /* Labeled */1,
          _0: Belt_List.map(match$3[0], (function (x) {
                  return {
                          label: {
                            TAG: /* String */3,
                            _0: x.paramOne + Belt_Option.mapWithDefault(x.paramTwo, "", (function (x) {
                                    if (x.length > 0) {
                                      return " (" + x + ")";
                                    } else {
                                      return "";
                                    }
                                  }))
                          },
                          value: x.paramOne
                        };
                }))
        }
      };
      if (form.financialCodesResult !== undefined) {
        tmp$9.validationResult = Caml_option.valFromOption(form.financialCodesResult);
      }
      tmp$8 = React.createElement(Core.Grid, {
            children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$9),
            item: true
          });
    }
    tmp$6 = React.createElement(Core.Grid, {
          children: React.createElement(Core.Box, {
                children: React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      direction: "column",
                      item: true
                    }, React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          item: true,
                          spacing: 1
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                    size: /* Large */3,
                                    type_: /* ProductPage */36
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                    style: {
                                      fontWeight: "600"
                                    },
                                    children: intl.formatMessage(Messages_FinancialCodes$DvmAdminFrontendFr.codeId),
                                    variant: "bodyBig"
                                  }),
                              item: true
                            })), React.createElement(Core.Grid, {
                          children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, tmp$7),
                          item: true
                        }), tmp$8),
                borderColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
                borderLeft: 2,
                paddingLeft: 1
              }),
          item: true
        });
  } else {
    tmp$6 = null;
  }
  return React.createElement(React.Fragment, undefined, form.input.orderType === /* RR */4 || form.input.orderType === /* RC */5 ? React.createElement(Flash_Edit_Details_NonFleet_Dealer$DvmAdminFrontendFr.make, {
                    form: form,
                    setDetailREPRExpanded: setDetailREPRExpanded,
                    nonFleetDealerExpanded: nonFleetDealerExpanded,
                    setNonFleetDealerExpanded: setNonFleetDealerExpanded,
                    step: step,
                    setStep: setStep
                  }) : null, React.createElement(Core.Grid, {
                  children: React.createElement(Flash_Edit_Accordion$DvmAdminFrontendFr.make, {
                        title: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.detailsTitle) + " " + form.input.actionLabel,
                        children: React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              direction: "column",
                              spacing: 4
                            }, React.createElement(Flash_Edit_Details_Dates$DvmAdminFrontendFr.make, {
                                  form: form,
                                  formGeneralDates: formGeneralDates,
                                  budgets: budgets,
                                  onOrderPeriodBlur: (function (param) {
                                      refreshFinancialCodes(undefined);
                                    })
                                }), tmp, React.createElement(Core.Grid, {
                                  children: React.createElement(Core.Box, {
                                        children: React.createElement(Core.Grid, {
                                              children: null,
                                              container: true,
                                              direction: "column",
                                              item: true
                                            }, React.createElement(Core.Grid, {
                                                  children: null,
                                                  container: true,
                                                  item: true,
                                                  spacing: 1
                                                }, React.createElement(Core.Grid, {
                                                      children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                            size: /* Large */3,
                                                            type_: /* Money */65
                                                          }),
                                                      item: true
                                                    }), React.createElement(Core.Grid, {
                                                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                            style: {
                                                              fontWeight: "600"
                                                            },
                                                            children: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.bonusAmount),
                                                            variant: "bodyBig"
                                                          }),
                                                      item: true
                                                    })), React.createElement(Core.Grid, {
                                                  children: null,
                                                  container: true,
                                                  item: true,
                                                  spacing: 3
                                                }, React.createElement(Core.Grid, {
                                                      children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, tmp$2),
                                                      item: true,
                                                      xs: Grid$Mui.Xs[4]
                                                    }), tmp$3), React.createElement(Core.Grid, {
                                                  children: null,
                                                  container: true,
                                                  item: true,
                                                  spacing: 3
                                                }, React.createElement(Core.Grid, {
                                                      children: React.createElement(Flash_Edit_Value_Percentage_Check$DvmAdminFrontendFr.make, {
                                                            children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$5),
                                                            form: form,
                                                            isStepOpen: detailREPRExpanded
                                                          }),
                                                      item: true
                                                    }), Belt_Option.getWithDefault(form.input.numberType, /* NOTSET */2) === /* EURO */1 ? React.createElement(Core.Grid, {
                                                        children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                                              onChange: (function (val) {
                                                                  Curry._1(setAmountWithVAT, (function (param) {
                                                                          return Belt_Option.getWithDefault(Belt_Float.fromString(val), 0.0);
                                                                        }));
                                                                }),
                                                              value: String(amountWithVAT),
                                                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                                                              label: Messages_Flash_Edit$DvmAdminFrontendFr.bonusAmountTTC,
                                                              required: /* OnlyLabel */1,
                                                              inputType: "number"
                                                            }),
                                                        item: true
                                                      }) : null)),
                                        borderColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
                                        borderLeft: 2,
                                        paddingLeft: 1
                                      }),
                                  item: true
                                }), tmp$6, React.createElement(Core.Grid, {
                                  children: (
                                    form.input.orderType === /* RR */4 || form.input.orderType === /* RC */5 ? step === 2 : step === 1
                                  ) ? React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                          onClick: (function (param) {
                                              Curry._1(setStep, (function (step) {
                                                      return step + 1 | 0;
                                                    }));
                                              Curry._1(setOldVehicleExpanded, (function (param) {
                                                      return true;
                                                    }));
                                              Curry._1(setNewVehicleExpanded, (function (param) {
                                                      return true;
                                                    }));
                                              Curry._1(setDetailREPRExpanded, (function (param) {
                                                      return false;
                                                    }));
                                            }),
                                          label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.next),
                                          size: "medium",
                                          disabled: form.input.orderType === /* RR */4 || form.input.orderType === /* RC */5 ? !detailRRorRCFinished : !detailNonSOFinished
                                        }) : null,
                                  container: true,
                                  item: true,
                                  justifyContent: "center"
                                })),
                        expanded: detailREPRExpanded,
                        setter: setDetailREPRExpanded,
                        disabled: form.input.orderType === /* RR */4 || form.input.orderType === /* RC */5 ? step < 2 : step < 1
                      }),
                  item: true,
                  sm: Grid$Mui.Sm[12]
                }), (
              form.input.orderType === /* RR */4 || form.input.orderType === /* RC */5 ? step < 2 : step < 1
            ) ? null : React.createElement(Core.Grid, {
                    children: React.createElement(Flash_Edit_Accordion$DvmAdminFrontendFr.make, {
                          title: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.newVehicleInfoTitle),
                          children: React.createElement(Flash_Edit_NonFleet_New_Vehicle_Page$DvmAdminFrontendFr.make, {
                                fetchFlashModelVersionsForCumulables: fetchFlashModelVersionsForCumulables,
                                form: form,
                                formAdditional: formAdditional,
                                formNewVehicle: formNonFleetNewVehicle,
                                newVehicleResult: newVehicleResult,
                                setNewVehicleResult: setNewVehicleResult,
                                flashId: flashId,
                                flash: flash,
                                setDuplicateActionNewVehicle: setDuplicateActionNewVehicle,
                                newVehicleExpanded: newVehicleExpanded
                              }),
                          expanded: newVehicleExpanded,
                          setter: setNewVehicleExpanded,
                          disabled: form.input.orderType === /* RR */4 || form.input.orderType === /* RC */5 ? step < 3 : step < 2
                        }),
                    item: true,
                    sm: Grid$Mui.Sm[12]
                  }), step < 1 || !formVO.input.actionWithVO ? null : React.createElement(Core.Grid, {
                    children: React.createElement(Flash_Edit_Accordion$DvmAdminFrontendFr.make, {
                          title: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.oldVehicleInfoTitle),
                          children: React.createElement(Flash_Edit_Info_Old_Vehicle$DvmAdminFrontendFr.make, {
                                form: form,
                                formVO: formVO,
                                brandsList: brandsList
                              }),
                          expanded: oldVehicleExpanded,
                          setter: setOldVehicleExpanded,
                          disabled: step < 2
                        }),
                    item: true,
                    sm: Grid$Mui.Sm[12]
                  }));
}

var Form;

var FormVO;

var FormGeneralDates;

var FormNewVehicle;

var Types;

var InputStyles;

var make = Flash_Edit_Details_NonFleet;

export {
  Form ,
  FormVO ,
  FormGeneralDates ,
  FormNewVehicle ,
  Types ,
  InputStyles ,
  roundFloat ,
  usePrevious ,
  usePreviousString ,
  usePreviousFloat ,
  make ,
}
/* react Not a pure module */
