// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Bonuses_Manage$DvmAdminFrontendFr from "../../../intl/messages/bonuses/Messages_Bonuses_Manage.bs.js";

function Bonuses_Manage_Validate(Props) {
  var manageCall = Props.manageCall;
  var intl = ReactIntl.useIntl();
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              justifyContent: "space-between",
              spacing: 1
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        style: {
                          fontWeight: "bold"
                        },
                        children: intl.formatMessage(Messages_Bonuses_Manage$DvmAdminFrontendFr.validationConfirmation),
                        variant: "bodyBig"
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                        onClick: (function (param) {
                            Curry._3(manageCall, /* Validate */0, undefined, undefined);
                          }),
                        label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                        size: "medium"
                      }),
                  item: true
                }));
}

var Types;

var make = Bonuses_Manage_Validate;

export {
  Types ,
  make ,
}
/* react Not a pure module */
