// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../../api/RestApi.bs.js";

function requestText_encode(v) {
  return Js_dict.fromArray([[
                "requestText",
                Decco.stringToJson(v.requestText)
              ]]);
}

function requestText_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var requestText = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "requestText"), null));
  if (requestText.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              requestText: requestText._0
            }
          };
  }
  var e = requestText._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".requestText" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function bypassOrder(userManager, setResult, orderId, submissionCallbacks, selectedRole, comment) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
          TAG: /* Json */0,
          _0: requestText_encode({
                requestText: comment
              })
        }, userManager, selectedRole, "/orders/bypass?orderId=" + orderId + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToBypassOrder */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

export {
  Form ,
  requestText_encode ,
  requestText_decode ,
  bypassOrder ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
