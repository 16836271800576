// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as Category_Types$DvmAdminFrontendFr from "../Category_Types.bs.js";

function fetchAllVersions(queryParams, setResult, userManager, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Category_Types$DvmAdminFrontendFr.VersionSearch.searchResult_decode, undefined, userManager, selectedRole, "/category/versions" + queryParams + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchResult = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchResult
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchCategoryVersions(categoryId, setResult, userManager, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Category_Types$DvmAdminFrontendFr.VersionEdit.versionEditList_decode, undefined, userManager, selectedRole, "/category/versions/" + categoryId + "?limit=15000&offset=0").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchResult = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchResult
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchCategory(categoryId, setResult, userManager, startDate, selectedRole, withClosedModels) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Category_Types$DvmAdminFrontendFr.CategoryCreate.t_decode, undefined, userManager, selectedRole, "/category/" + categoryId + "/" + startDate + "?withClosedModels=" + (
          withClosedModels ? "true" : "false"
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchResult = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchResult
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function saveCategory(setResult, userManager, submissionCallbacks, method, selectedRole, category) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call(method, {
          TAG: /* Json */0,
          _0: Category_Types$DvmAdminFrontendFr.CategoryCreate.t_encode(category)
        }, userManager, selectedRole, "/category").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToSaveCategory */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function saveVersions(setResult, userManager, method, selectedRole, category) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call(method, {
          TAG: /* Json */0,
          _0: Category_Types$DvmAdminFrontendFr.CategoryCreate.t_encode(category)
        }, userManager, selectedRole, "/category").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchTakenVersions(modelID, startDate, setResult, userManager, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Category_Types$DvmAdminFrontendFr.TakenVersions.searchResult_decode, undefined, userManager, selectedRole, "/category/versions/period/" + modelID + "?limit=10000&offset=0&startDate=" + Belt_Option.mapWithDefault(startDate, "", (function (x) {
                return x.toISOString();
              })) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchResult = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchResult
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

export {
  Form ,
  fetchAllVersions ,
  fetchCategoryVersions ,
  fetchCategory ,
  saveCategory ,
  saveVersions ,
  fetchTakenVersions ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
