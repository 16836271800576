// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../api/RestApi.bs.js";
import * as Contract_Attachments_Types$DvmAdminFrontendFr from "../../../contract/editCreate/attachment/Contract_Attachments_Types.bs.js";
import * as Company_Virtual_Search_Types$DvmAdminFrontendFr from "../Company_Virtual_Search_Types.bs.js";

function createOrEditSiret(submissionCallbacks, userManager, setResult, siretMethod, attachmentMethod, attachment, oldAttachment, addAttachment, setAttachmentCreateFailed, selectedRole, input) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  Belt_Option.forEach(submissionCallbacks, (function (param) {
          Curry._1(param.notifyOnSuccess, undefined);
        }));
  Curry._1(setResult, (function (param) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        }));
  var resultOk = Curry._1(setAttachmentCreateFailed, (function (param) {
          return false;
        }));
  var resultError = function (error, failed) {
    Belt_Option.forEach(submissionCallbacks, (function (param) {
            Curry._1(param.notifyOnFailure, /* FailedToEditCompany */0);
          }));
    Curry._1(setResult, (function (param) {
            return {
                    TAG: /* Error */1,
                    _0: error
                  };
          }));
    if (failed) {
      return Curry._1(setAttachmentCreateFailed, (function (param) {
                    return true;
                  }));
    }
    
  };
  var attachmentPost = function (param) {
    RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
            TAG: /* Json */0,
            _0: Contract_Attachments_Types$DvmAdminFrontendFr.attachmentsSaveRequest_encode({
                  hd: attachment,
                  tl: /* [] */0
                })
          }, userManager, selectedRole, "/contract/attachment").then(function (result) {
          if (result.TAG === /* OkResult */0) {
            return resultOk;
          } else {
            return resultError(result._0, true);
          }
        });
  };
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call(siretMethod, {
          TAG: /* Json */0,
          _0: Company_Virtual_Search_Types$DvmAdminFrontendFr.row_encode(input)
        }, userManager, selectedRole, "/sirets/virtual").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          if (addAttachment) {
            if (attachmentMethod === "POST") {
              return attachmentPost(undefined);
            } else {
              RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", {
                      TAG: /* Json */0,
                      _0: Contract_Attachments_Types$DvmAdminFrontendFr.attachment_encode(oldAttachment)
                    }, userManager, selectedRole, "/contract/attachment").then(function (result) {
                    if (result.TAG === /* OkResult */0) {
                      return attachmentPost(undefined);
                    } else {
                      return resultError(result._0, true);
                    }
                  });
              return ;
            }
          } else {
            return resultOk;
          }
        } else {
          return resultError(result._0, false);
        }
      });
}

function deleteSiret(userManager, setResult, selectedRole, siret) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", undefined, userManager, selectedRole, "/sirets/virtual/" + siret + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchContractSearch(userManager, setResult, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Company_Virtual_Search_Types$DvmAdminFrontendFr.contracts_decode, undefined, userManager, selectedRole, "/dropdown/contractStartDates").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var contractTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: contractTable
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function checkIfExistsAsAttachment(userManager, setResult, siret, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Contract_Attachments_Types$DvmAdminFrontendFr.contractAttachments_decode, undefined, userManager, selectedRole, "/contract/attachments?limit=50&offset=0&filter=siren=" + siret + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var contractTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: contractTable
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

export {
  Form ,
  createOrEditSiret ,
  deleteSiret ,
  fetchContractSearch ,
  checkIfExistsAsAttachment ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
