// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function editedBonus_encode(v) {
  return Js_dict.fromArray([
              [
                "actionID",
                Decco.stringToJson(v.actionID)
              ],
              [
                "orderID",
                Decco.stringToJson(v.orderID)
              ],
              [
                "editedAmount",
                Decco.optionToJson(Decco.floatToJson, v.editedAmount)
              ],
              [
                "refuseReason",
                Decco.optionToJson(Decco.stringToJson, v.refuseReason)
              ],
              [
                "approved",
                Decco.optionToJson(Decco.stringToJson, v.approved)
              ]
            ]);
}

function editedBonus_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var actionID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionID"), null));
  if (actionID.TAG === /* Ok */0) {
    var orderID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderID"), null));
    if (orderID.TAG === /* Ok */0) {
      var editedAmount = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "editedAmount"), null));
      if (editedAmount.TAG === /* Ok */0) {
        var refuseReason = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "refuseReason"), null));
        if (refuseReason.TAG === /* Ok */0) {
          var approved = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "approved"), null));
          if (approved.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      actionID: actionID._0,
                      orderID: orderID._0,
                      editedAmount: editedAmount._0,
                      refuseReason: refuseReason._0,
                      approved: approved._0
                    }
                  };
          }
          var e = approved._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".approved" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = refuseReason._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".refuseReason" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = editedAmount._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".editedAmount" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = orderID._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".orderID" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = actionID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".actionID" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function editedBonusList_encode(v) {
  return Decco.listToJson(editedBonus_encode, v);
}

function editedBonusList_decode(v) {
  return Decco.listFromJson(editedBonus_decode, v);
}

export {
  editedBonus_encode ,
  editedBonus_decode ,
  editedBonusList_encode ,
  editedBonusList_decode ,
}
/* No side effect */
