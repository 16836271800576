// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as App_Table$DvmAdminFrontendFr from "../../../common/components/table/App_Table.bs.js";
import * as App_Styles$DvmAdminFrontendFr from "../../../common/App_Styles.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../intl/Intl_Utils.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../common/components/App_Checkbox.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Head.bs.js";
import * as Category_Types$DvmAdminFrontendFr from "../Category_Types.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Table_Simple$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Simple.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as Category_Edit_Api$DvmAdminFrontendFr from "./Category_Edit_Api.bs.js";
import * as Messages_Category$DvmAdminFrontendFr from "../../../intl/messages/category/Messages_Category.bs.js";
import * as Category_Edit_SingleVersion_Modal$DvmAdminFrontendFr from "./Category_Edit_SingleVersion_Modal.bs.js";

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Category_Types$DvmAdminFrontendFr.Fields.version, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.version);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Category_Types$DvmAdminFrontendFr.Fields.versionName, undefined, undefined, undefined, undefined, undefined, Messages_Category$DvmAdminFrontendFr.versionName),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell("startDate", undefined, undefined, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.startDate),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell("endDate", undefined, undefined, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.endDate),
      tl: /* [] */0
    }
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function getDateString(date, category, variant, intl) {
  return Belt_Option.mapWithDefault(date, Belt_Option.mapWithDefault(variant === "start" ? category.startDate : category.endDate, "", (function (param) {
                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                  })), (function (param) {
                return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
              }));
}

function Category_Edit_VersionsSelection_Table(Props) {
  var versions = Props.versions;
  var category = Props.category;
  var allVersions = Props.allVersions;
  var refresh = Props.refresh;
  var takenVersionsList = Props.takenVersionsList;
  var withClosedModels = Props.withClosedModels;
  var setWithClosedModels = Props.setWithClosedModels;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = React.useState(function () {
        
      });
  var setVersionBeingEdited = match[1];
  var versionBeingEdited = match[0];
  var match$1 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$1[1];
  var result = match$1[0];
  var match$2 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$3 = match$2[0];
  var userManager = match$3.userManager;
  var selectedRole = match$3.selectedRole;
  var convertTable = function (param) {
    return {
            TAG: /* Ok */0,
            _0: Belt_List.map(versions, (function (version) {
                    return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, Caml_option.some(React.createElement(Core.Button, {
                                        onClick: (function (param) {
                                            return Curry._1(setVersionBeingEdited, (function (param) {
                                                          return version;
                                                        }));
                                          }),
                                        children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                              size: /* Large */3,
                                              type_: /* Edit */60
                                            }),
                                        key: "edit"
                                      })), undefined, Belt_List.mapWithIndex({
                                    hd: version.version,
                                    tl: {
                                      hd: Belt_Option.mapWithDefault(Belt_List.getBy(allVersions, (function (x) {
                                                  return x.versionCode === version.version;
                                                })), "", (function (x) {
                                              return Belt_Option.getWithDefault(x.versionLabel, "");
                                            })),
                                      tl: {
                                        hd: getDateString(version.startDate, category, "start", intl),
                                        tl: {
                                          hd: getDateString(version.endDate, category, "end", intl),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }, (function (index, content) {
                                      return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                        return param.align;
                                                      })), undefined, undefined, content);
                                    })));
                  }))
          };
  };
  var closeModal = function (param) {
    Curry._1(setVersionBeingEdited, (function (param) {
            
          }));
  };
  React.useEffect((function () {
          if (typeof result === "number") {
            result === /* NotStarted */0;
          } else if (result.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(refresh, undefined);
            Curry._1(setVersionBeingEdited, (function (param) {
                    
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: result._0
                      }
                    }));
          }
        }), [result]);
  var onSubmit = function (output, param) {
    Belt_Option.mapWithDefault(versionBeingEdited, undefined, (function (versionBeingEdited) {
            Category_Edit_Api$DvmAdminFrontendFr.saveVersions(setResult, userManager, "PUT", selectedRole, {
                  modelID: category.modelID,
                  categoryID: category.categoryID,
                  categoryName: category.categoryName,
                  startDate: category.startDate,
                  endDate: category.endDate,
                  versions: Belt_List.add(Belt_List.keep(category.versions, (function (x) {
                              return x.version !== versionBeingEdited.version;
                            })), {
                        version: versionBeingEdited.version,
                        startDate: output.startDate,
                        endDate: output.endDate
                      })
                });
          }));
    Curry._1(param.notifyOnSuccess, undefined);
  };
  var renderModalLabel = function (param) {
    return React.createElement(Core.Grid, {
                children: null,
                container: true,
                direction: "column"
              }, React.createElement(Core.Grid, {
                    children: React.createElement(Core.Typography, {
                          children: Belt_Option.mapWithDefault(versionBeingEdited, "", (function (param) {
                                  return param.version;
                                })),
                          className: App_Styles$DvmAdminFrontendFr.bold
                        }),
                    item: true
                  }), React.createElement(Core.Grid, {
                    item: true
                  }));
  };
  return React.createElement(React.Fragment, undefined, Belt_Option.mapWithDefault(versionBeingEdited, null, (function (versionBeingEdited) {
                    return React.createElement(Category_Edit_SingleVersion_Modal$DvmAdminFrontendFr.make, {
                                open: true,
                                label: renderModalLabel(undefined),
                                asParentLabel: Messages_Category$DvmAdminFrontendFr.asCategory,
                                showSpinner: App_Types_Result$DvmAdminFrontendFr.isPending(result),
                                onClose: closeModal,
                                onSubmit: onSubmit,
                                blockOldStartDate: false,
                                takenVersionsList: takenVersionsList,
                                category: category,
                                versionBeingEdited: versionBeingEdited
                              });
                  })), React.createElement(Core.FormControlLabel, {
                  classes: {
                    label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                  },
                  control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                        checked: withClosedModels,
                        onClick: (function ($$event) {
                            Curry._1(setWithClosedModels, (function (param) {
                                    return $$event.target.checked;
                                  }));
                          })
                      }),
                  label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.includeClosedCars)
                }), React.createElement(App_Table_Simple$DvmAdminFrontendFr.make, {
                  headers: headersConfiguration,
                  content: convertTable(undefined),
                  rowButton: {
                    TAG: /* Left */0,
                    _0: /* EmptyCell */0
                  },
                  height: "55vh"
                }));
}

var Api;

var InputStyles;

var make = Category_Edit_VersionsSelection_Table;

export {
  Api ,
  InputStyles ,
  headersConfiguration ,
  getDateString ,
  make ,
}
/* headersConfiguration Not a pure module */
