// Generated by ReScript, PLEASE EDIT WITH CARE


var infoTitle = {
  id: "07be3a7e-b685-47c1-b2c5-fbfc9747dfa7",
  defaultMessage: "Informations Quotas LCD"
};

var createTitle = {
  id: "ed51ff46-da76-4ca7-8309-48f6d11e1d26",
  defaultMessage: "LCD création quotas"
};

var modifyTitle = {
  id: "1205b66e-972d-4897-906e-dad26cd83fd0",
  defaultMessage: "Mise à jour des quotas"
};

var vehicleType = {
  id: "36f764a5-5223-4da6-bbd8-6641444d9cad",
  defaultMessage: "Genre Véhicule"
};

var exclusionTitle = {
  id: "a1705b25-f9db-443b-bcf3-d7b5e9a91565",
  defaultMessage: "LCD modèles hors quotas"
};

var attachmentTitle = {
  id: "10160610-7b3a-41c6-bc87-0255957d9589",
  defaultMessage: "Gérer la répartition des quotas"
};

var volume = {
  id: "f4e1557f-122e-4860-9698-f42450467890",
  defaultMessage: "Volume"
};

var validatePeriod = {
  id: "bfd2f0fb-ad78-4bc4-b8e3-fd5a305b14d0",
  defaultMessage: "Période de validité des quotas"
};

var initial = {
  id: "5c29a578-be3f-44f8-9e1d-4a234aea3f33",
  defaultMessage: "Quota Init."
};

var corrected = {
  id: "11f10e15-b086-4d7e-91df-c1de745a0bd6",
  defaultMessage: "Quota Corr."
};

var consumedOnInvoiced = {
  id: "76eaa4d1-2b1c-4d7b-b0e6-ab1dafe64d09",
  defaultMessage: "Cde facturée"
};

var consumedOnNonInvoiced = {
  id: "1c5cc47f-187d-4dfd-a562-73e73db09779",
  defaultMessage: "Cde non facturée"
};

var consumedTotal = {
  id: "9768fcbb-3a6e-4846-aa32-5bf46026ddd5",
  defaultMessage: "Quota conso."
};

var exceeded = {
  id: "a273a115-9e0e-453d-9967-0eea066bd708",
  defaultMessage: "Dépassement"
};

var secondaryTitle = {
  id: "29bebfd5-a2da-480b-9c69-eab1462000e4",
  defaultMessage: "Rechercher un quota"
};

var addExclusion = {
  id: "d9bf8197-523d-4075-b10c-20b7fc9862c6",
  defaultMessage: "Exclure"
};

var newAttachment = {
  id: "e989fe1f-b7cd-4f73-974a-5185ca888f36",
  defaultMessage: "Nouveau quota"
};

var updateAttachment = {
  id: "2a462a0a-2b2f-465b-b2fb-ab063882fab9",
  defaultMessage: "modifier les quotas"
};

var active = {
  id: "d30585fd-9d60-4abc-8761-187b0da9068f",
  defaultMessage: "Actives ({count})"
};

var inactive = {
  id: "9a63b670-3587-44c5-bd5e-5e275a4d2978",
  defaultMessage: "Inactives ({count})"
};

var attachmentModalTitle = {
  id: "2352bb73-660c-4b77-81e2-cc97de91db46",
  defaultMessage: "Ajouter des franchisés au quota "
};

var attachmentModalAdd = {
  id: "01bc0864-1af5-4283-9326-a9a98f813912",
  defaultMessage: "Choisissez le nombre de sociétés à ajouter"
};

var attachmentModalRemaining = {
  id: "06539ba2-f341-44f6-a502-ee50ffcba61d",
  defaultMessage: "Quotas restants: <span>{sum}</span> sur {total}"
};

var selectModalTitle = {
  id: "d3acffcd-53e5-49d6-bfcc-ec7b74e54bc4",
  defaultMessage: "Modèle / Versions à exclure du quota"
};

var selectExclusionParameters = {
  id: "d2a40a2a-e3b5-40b9-8127-c469ef252028",
  defaultMessage: "1. Critères des Hors quotas"
};

var selectModel = {
  id: "db5eba0a-4965-4234-99e3-06d203ce70b4",
  defaultMessage: "2. Sélectionner le modèle"
};

var selectCategory = {
  id: "ff5cf4d6-9d40-4a2a-8300-8f70dcadd06c",
  defaultMessage: "3. Sélectionner les versions de {model} à exclure de ce quota"
};

var modelVersion = {
  id: "c20f7036-a47f-41bf-9e96-edf02fc61242",
  defaultMessage: "Modèle/Version"
};

var versionName = {
  id: "59ee5680-7542-4de6-9d06-1661059b2f6e",
  defaultMessage: "Libellé commercial Version"
};

var allVersions = {
  id: "41e06aaf-6f51-4210-af63-dc3c5040396a",
  defaultMessage: "Options ({count})"
};

var selectedVersions = {
  id: "a8fb0b00-9daf-4230-8976-06e51ac3f7df",
  defaultMessage: "Sélectionnées ({count})"
};

var deleteAllButton = {
  id: "4227bb10-12e4-455c-a788-2c290f01f67b",
  defaultMessage: "Tout supprimer"
};

var deleteAllTitle = {
  id: "e65cde0e-928d-4063-a70c-08a2ecbcf8c5",
  defaultMessage: "Êtes-vous sûr de vouloir supprimer tout ?"
};

var actionDatesHaveIssue = {
  id: "9f545ab8-8d08-43e7-afd5-21633c9b3e40",
  defaultMessage: "Les dates d’application sont invalides"
};

var missingStartDate = {
  id: "962b202f-a728-4556-b190-bb08024263a3",
  defaultMessage: "Manque la date de début d’application du quota"
};

var missingEndDate = {
  id: "d6eaef61-5822-49c7-aeb9-9aeb916c13c3",
  defaultMessage: "Manque la date de fin d’application du quota"
};

var dateOutOfPeriod = {
  id: "30f7632a-66c8-4e50-b81b-c693202d03fa",
  defaultMessage: "Date d’application du quota hors période"
};

var quotaSavedMessage = {
  id: "d51b4142-3b8c-448f-b2d4-9881b9f672ca",
  defaultMessage: "Quota installé avec succès"
};

var quotaEditAttachment = {
  id: "d195e08c-74af-43d9-ac62-e3035acfbbdc",
  defaultMessage: "Mettre à jour le quota"
};

var deleteQuota = {
  id: "d561c3a8-e90e-4732-9b05-a994d02a3d93",
  defaultMessage: "Confirmez-vous la suppression du quota ?"
};

var quotaVolumeTooLow = {
  id: "70abb53d-8c21-4efd-8359-3f4417373853",
  defaultMessage: "Quota du loueur inférieur au total des quotas des franchisés"
};

var seeExclusions = {
  id: "541cf6fc-e532-4ca5-8a53-df49d624d9b4",
  defaultMessage: "Afficher les hors quota"
};

var alreadyConsumed = {
  id: "8b939a6b-e095-4850-a6b7-8b3b588637b4",
  defaultMessage: "Suppression impossible, quotas déjà consommés"
};

export {
  infoTitle ,
  createTitle ,
  modifyTitle ,
  vehicleType ,
  exclusionTitle ,
  attachmentTitle ,
  volume ,
  validatePeriod ,
  initial ,
  corrected ,
  consumedOnInvoiced ,
  consumedOnNonInvoiced ,
  consumedTotal ,
  exceeded ,
  secondaryTitle ,
  addExclusion ,
  newAttachment ,
  updateAttachment ,
  active ,
  inactive ,
  attachmentModalTitle ,
  attachmentModalAdd ,
  attachmentModalRemaining ,
  selectModalTitle ,
  selectExclusionParameters ,
  selectModel ,
  selectCategory ,
  modelVersion ,
  versionName ,
  allVersions ,
  selectedVersions ,
  deleteAllButton ,
  deleteAllTitle ,
  actionDatesHaveIssue ,
  missingStartDate ,
  missingEndDate ,
  dateOutOfPeriod ,
  quotaSavedMessage ,
  quotaEditAttachment ,
  deleteQuota ,
  quotaVolumeTooLow ,
  seeExclusions ,
  alreadyConsumed ,
}
/* No side effect */
