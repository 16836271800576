// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "@emotion/css";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";

var titleBox = Css.css({
      backgroundColor: "white",
      marginBottom: "39px",
      minHeight: "40px",
      padding: "19px 83px",
      boxShadow: "0 2px 6px 2px rgba(0, 0, 0, 0.05)"
    });

var detailBox = Css.css({
      borderLeft: "4px solid " + Theme_Colors$DvmAdminFrontendFr.primaryBrown + "",
      marginTop: "30px",
      minHeight: "40px",
      paddingLeft: "20px"
    });

var addressBox = Css.css({
      padding: "15px 55px"
    });

export {
  titleBox ,
  detailBox ,
  addressBox ,
}
/* titleBox Not a pure module */
