// Generated by ReScript, PLEASE EDIT WITH CARE


var title = {
  id: "d95d3b5d-f0ea-457d-9857-a7b5a1d7d560",
  defaultMessage: "Gestion du référentiel Energie"
};

var originalValue = {
  id: "06ed6aed-e254-408e-80a1-c71366e7ef69",
  defaultMessage: "Valeur d'origine"
};

var attachedValue = {
  id: "76e3a171-2aad-42c5-9003-ae53ebda0ebe",
  defaultMessage: "Valeur à afficher"
};

var resultInfo = {
  id: "50bad8ea-8bbb-4b76-92d9-626c6858d1b6",
  defaultMessage: "types d'énergie"
};

var newEnergyTypesIconNotification = {
  id: "5b2c6a76-fa57-41c8-b2d0-daff695ba871",
  defaultMessage: "Nouvelles énergies disponibles (cliquer pour ignorer)"
};

export {
  title ,
  originalValue ,
  attachedValue ,
  resultInfo ,
  newEnergyTypesIconNotification ,
}
/* No side effect */
