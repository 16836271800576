// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Table$DvmAdminFrontendFr from "../../components/table/App_Table.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../utils/Utils_Api.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../intl/Intl_Utils.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../types/App_Types_Result.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../components/table/App_Table_Pagination.bs.js";
import * as App_Table_Export_Menu$DvmAdminFrontendFr from "../../components/table/App_Table_Export_Menu.bs.js";
import * as Messages_Contract_List$DvmAdminFrontendFr from "../../../intl/messages/contract/Messages_Contract_List.bs.js";
import * as File_Upload_Contract_Errors_Types$DvmAdminFrontendFr from "./File_Upload_Contract_Errors_Types.bs.js";

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(File_Upload_Contract_Errors_Types$DvmAdminFrontendFr.Fields.siren, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.siren);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(File_Upload_Contract_Errors_Types$DvmAdminFrontendFr.Fields.contractID, undefined, undefined, undefined, undefined, undefined, Messages_Contract_List$DvmAdminFrontendFr.contractID),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(File_Upload_Contract_Errors_Types$DvmAdminFrontendFr.Fields.startDate, undefined, undefined, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.startDate),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(File_Upload_Contract_Errors_Types$DvmAdminFrontendFr.Fields.endDate, undefined, undefined, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.endDate),
      tl: /* [] */0
    }
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function File_Upload_Contract_Errors_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var intl = ReactIntl.useIntl();
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (row) {
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, undefined, undefined, Belt_List.mapWithIndex({
                                                  hd: row.siren,
                                                  tl: {
                                                    hd: row.contractID,
                                                    tl: {
                                                      hd: Belt_Option.mapWithDefault(row.startDate, "", (function (param) {
                                                              return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                            })),
                                                      tl: {
                                                        hd: Belt_Option.mapWithDefault(row.endDate, "", (function (param) {
                                                                return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                              })),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._1(fetchData, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._1(fetchData, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._1(fetchData, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  return React.createElement(App_Table$DvmAdminFrontendFr.make, {
              headers: fetchRequest.headers,
              handleSorting: handleSorting,
              tablePage: convertTable(tablePage),
              paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined),
              exportMenu: React.createElement(App_Table_Export_Menu$DvmAdminFrontendFr.make, {
                    query: Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                          page: 0,
                          rowsPerPage: 150000,
                          headers: fetchRequest.headers,
                          filterQueryParams: fetchRequest.filterQueryParams,
                          additionalQueryParams: fetchRequest.additionalQueryParams
                        }),
                    endpoint: "contract/attachments/check/blocked"
                  })
            });
}

var make = File_Upload_Contract_Errors_Table;

export {
  headersConfiguration ,
  make ,
}
/* headersConfiguration Not a pure module */
