// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../../../../../api/RestApi.bs.js";
import * as Flash_Edit_Versions_Types$DvmAdminFrontendFr from "../../../../Flash_Edit_Versions_Types.bs.js";

function perfEditVersion(setResultEdit, userManager, submissionCallbacks, versions, flashId, method, selectedRole) {
  Curry._1(setResultEdit, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call(method, {
          TAG: /* Json */0,
          _0: Flash_Edit_Versions_Types$DvmAdminFrontendFr.VersionEdit.versionEditList_encode(versions)
        }, userManager, selectedRole, "/flash/map/perf" + (
          method === "PUT" ? "/" + flashId : ""
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResultEdit, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToEditPerfVersion */0);
              }));
        Curry._1(setResultEdit, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function perfNewHigherPeriod(setResultEdit, userManager, submissionCallbacks, versionsNew, versionsEdit, flashId, selectedRole) {
  Curry._1(setResultEdit, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
          TAG: /* Json */0,
          _0: Flash_Edit_Versions_Types$DvmAdminFrontendFr.VersionEdit.versionEditList_encode(versionsNew)
        }, userManager, selectedRole, "/flash/map/perf").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          if (Belt_List.length(versionsEdit) > 0) {
            return RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("PUT", {
                          TAG: /* Json */0,
                          _0: Flash_Edit_Versions_Types$DvmAdminFrontendFr.VersionEdit.versionEditList_encode(versionsEdit)
                        }, userManager, selectedRole, "/flash/map/perf/" + flashId + "").then(function (result) {
                        if (result.TAG === /* OkResult */0) {
                          Belt_Option.forEach(submissionCallbacks, (function (param) {
                                  Curry._1(param.notifyOnSuccess, undefined);
                                }));
                          return Curry._1(setResultEdit, (function (param) {
                                        return {
                                                TAG: /* Ok */0,
                                                _0: undefined
                                              };
                                      }));
                        }
                        var error = result._0;
                        Belt_Option.forEach(submissionCallbacks, (function (param) {
                                Curry._1(param.notifyOnFailure, /* FailedToEditPerfVersion */0);
                              }));
                        Curry._1(setResultEdit, (function (param) {
                                return {
                                        TAG: /* Error */1,
                                        _0: error
                                      };
                              }));
                      });
          } else {
            Belt_Option.forEach(submissionCallbacks, (function (param) {
                    Curry._1(param.notifyOnSuccess, undefined);
                  }));
            Curry._1(setResultEdit, (function (param) {
                    return {
                            TAG: /* Ok */0,
                            _0: undefined
                          };
                  }));
            return Promise.resolve(undefined);
          }
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToEditPerfVersion */0);
              }));
        Curry._1(setResultEdit, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
        return Promise.resolve(undefined);
      });
}

function perfDeleteVersions(setResultDelete, userManager, selectedRole, versions) {
  Curry._1(setResultDelete, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", {
          TAG: /* Json */0,
          _0: Flash_Edit_Versions_Types$DvmAdminFrontendFr.VersionEdit.versionEditList_encode(versions)
        }, userManager, selectedRole, "/flash/map/perf").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResultDelete, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResultDelete, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

var Types;

export {
  Form ,
  Types ,
  perfEditVersion ,
  perfNewHigherPeriod ,
  perfDeleteVersions ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
