// Generated by ReScript, PLEASE EDIT WITH CARE


var title = {
  id: "d6d701cf-d76f-4189-bad9-25df076230b9",
  defaultMessage: "Interroger une grille de marge"
};

var manageMarginGridTitle = {
  id: "68c4e8e5-356c-41cc-b134-ea9ee9fd4794",
  defaultMessage: "Grilles de marges"
};

var beforeResult = {
  id: "40c66690-ab8a-4a86-9528-ca5921c45253",
  defaultMessage: "Indiquer au moins le budget pour afficher des résultats*"
};

var margin = {
  id: "7051548b-7805-46bc-951b-7117a9b6f2ac",
  defaultMessage: "Marge %"
};

var resultInfoAVS = {
  id: "d5262262-8eea-440a-9853-1f773625c57a",
  defaultMessage: "avs margins"
};

var gridID = {
  id: "cf31fae9-7bc9-40b3-abac-d2cefde849a4",
  defaultMessage: "Code Grille"
};

var modalTitle = {
  id: "f4cd05bd-e0c6-4d02-803e-98d1a9c2e97f",
  defaultMessage: "Modifier les informations {budgetOrID}"
};

var actionsTitle = {
  id: "8f181f60-42ea-4359-8f74-c91c32dd881b",
  defaultMessage: "Sélectionner une action à appliquer aux résultats de votre recherche :"
};

var startingDate = {
  id: "784fe3e5-936e-4fba-93d3-5104af605a96",
  defaultMessage: "Appliquer une date de début"
};

var finalDate = {
  id: "d3e9a43b-ae53-4f63-a847-254491ccb85a",
  defaultMessage: "Appliquer une date de fin"
};

var deleteMargin = {
  id: "7d06ab5f-cfd2-4a2e-9981-03201b0a35db",
  defaultMessage: "Supprimer les marges"
};

var deleteMarginMessage = {
  id: "62b83939-31d2-41de-8980-3a67f963b061",
  defaultMessage: "{number} marges seront supprimées définitivement."
};

var modifyCategoryCode = {
  id: "cf417cd0-625b-4c0e-87aa-d580b5f7cefa",
  defaultMessage: "Modifier le code catégorie"
};

var oldCategoryCode = {
  id: "21b8aecd-3e73-4d3f-bcb9-aaf370de8a71",
  defaultMessage: "Code initial"
};

var newCategoryCode = {
  id: "d5aac602-b8f8-4fd2-b1af-85d8e7860f08",
  defaultMessage: "Nouveau code"
};

var editMarginButton = {
  id: "c4607c0f-c404-47dd-a6ef-caa736fb8e11",
  defaultMessage: "Modifier {budgetOrID}"
};

var appendNewMargin = {
  id: "2ae00aee-17e9-4d64-8294-19019dd2b710",
  defaultMessage: "Ajouter une nouvelle grille"
};

var noEditRow = {
  id: "2ed9bfe7-b613-4512-b2c8-b6d4fa291792",
  defaultMessage: "Il faut au moins une ligne à modifier"
};

var newRowSuccess = {
  id: "2a3eaaee-daf5-42bc-a031-ce736c953b9f",
  defaultMessage: "Ligne ajoutée avec succès"
};

var acquisitionId = {
  id: "46cb8502-84dc-4623-873b-75af00f9d676",
  defaultMessage: "Type Acq."
};

var addModel = {
  id: "d55a31e3-5179-428b-959e-07b838b3850b",
  defaultMessage: "Ajouter un modèle"
};

var addVersion = {
  id: "a164e97e-efab-4e7b-b434-19c33beba404",
  defaultMessage: "Ajouter une version"
};

var addVersionsOfCategory = {
  id: "b8ad3e56-1a10-4194-a745-ce15c8eda01d",
  defaultMessage: "Ajouter des versions d'une catégorie"
};

var addCategory = {
  id: "da576a99-735d-4ced-89aa-07153e0cb881",
  defaultMessage: "Ajouter une catégorie"
};

var editTable = {
  id: "4fb0f9ec-c9b0-4b30-8737-cdfebb8d30da",
  defaultMessage: "Modifier le tableau"
};

var dateOutOfAllowedPeriod = {
  id: "7eb46e1a-5b7a-45c4-8c10-0bb2da9cc0c7",
  defaultMessage: "La date est hors période autorisée"
};

export {
  title ,
  manageMarginGridTitle ,
  beforeResult ,
  margin ,
  resultInfoAVS ,
  gridID ,
  modalTitle ,
  actionsTitle ,
  startingDate ,
  finalDate ,
  deleteMargin ,
  deleteMarginMessage ,
  modifyCategoryCode ,
  oldCategoryCode ,
  newCategoryCode ,
  editMarginButton ,
  appendNewMargin ,
  noEditRow ,
  newRowSuccess ,
  acquisitionId ,
  addModel ,
  addVersion ,
  addVersionsOfCategory ,
  addCategory ,
  editTable ,
  dateOutOfAllowedPeriod ,
}
/* No side effect */
