// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../api/RestApi.bs.js";
import * as Grid_Types$DvmAdminFrontendFr from "../../Grid_Types.bs.js";
import * as Margin_Grid_Duplicate_Types$DvmAdminFrontendFr from "./Margin_Grid_Duplicate_Types.bs.js";

function duplicateGrid(userManager, setResult, submissionCallbacks, selectedRole, duplication) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("PUT", {
          TAG: /* Json */0,
          _0: Margin_Grid_Duplicate_Types$DvmAdminFrontendFr.duplication_encode(duplication)
        }, userManager, selectedRole, "/margins/avs/duplicate").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToDuplicateMarginGrid */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function getMarginsForDuplication(submissionCallbacks, userManager, setResult, selectedRole, queryParams) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Grid_Types$DvmAdminFrontendFr.Margins.searchResult_decode, undefined, userManager, selectedRole, "/margins/avs?limit=150000&offset=0&filter=" + queryParams + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var modelsTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: modelsTable
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchMarginGrid */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchSearchForMargin(userManager, setResult, selectedRole, filters) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Grid_Types$DvmAdminFrontendFr.Margins.searchResult_decode, undefined, userManager, selectedRole, "/margins/avs?limit=150000&offset=0&filter=" + filters + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var modelsTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: modelsTable
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchSearchForCategoryIds(userManager, setResult, selectedRole, marginGrid, dates) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Grid_Types$DvmAdminFrontendFr.Margins.searchResult_decode, undefined, userManager, selectedRole, "/margins/avs?limit=150000&offset=0&filter=avsMarginGridID=" + marginGrid + ";" + dates + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var modelsTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: modelsTable
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

var FilterForm;

var Types;

export {
  Form ,
  FilterForm ,
  Types ,
  duplicateGrid ,
  getMarginsForDuplication ,
  fetchSearchForMargin ,
  fetchSearchForCategoryIds ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
