// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../common/types/App_Types_Date.bs.js";

function attachment_encode(v) {
  return Js_dict.fromArray([
              [
                "contractID",
                Decco.stringToJson(v.contractID)
              ],
              [
                "siren",
                Decco.optionToJson(Decco.stringToJson, v.siren)
              ],
              [
                "sirenName",
                Decco.optionToJson(Decco.stringToJson, v.sirenName)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function attachment_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var contractID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractID"), null));
  if (contractID.TAG === /* Ok */0) {
    var siren = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "siren"), null));
    if (siren.TAG === /* Ok */0) {
      var sirenName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "sirenName"), null));
      if (sirenName.TAG === /* Ok */0) {
        var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
        if (startDate.TAG === /* Ok */0) {
          var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
          if (endDate.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      contractID: contractID._0,
                      siren: siren._0,
                      sirenName: sirenName._0,
                      startDate: startDate._0,
                      endDate: endDate._0
                    }
                  };
          }
          var e = endDate._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".endDate" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = startDate._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".startDate" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = sirenName._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".sirenName" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = siren._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".siren" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = contractID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".contractID" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function contractAttachments_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(attachment_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function contractAttachments_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(attachment_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function attachmentsSaveRequest_encode(v) {
  return Decco.listToJson(attachment_encode, v);
}

function attachmentsSaveRequest_decode(v) {
  return Decco.listFromJson(attachment_decode, v);
}

function siren_encode(v) {
  return Js_dict.fromArray([[
                "response",
                Decco.stringToJson(v.response)
              ]]);
}

function siren_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var response = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "response"), null));
  if (response.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              response: response._0
            }
          };
  }
  var e = response._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".response" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var SirenName = {
  siren_encode: siren_encode,
  siren_decode: siren_decode
};

var Fields = {
  siren: "siren",
  sirenName: "name",
  startDate: "startDate",
  endDate: "endDate"
};

function closedSiren_encode(v) {
  return Js_dict.fromArray([[
                "response",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.response)
              ]]);
}

function closedSiren_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var response = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict._0, "response"), null));
  if (response.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              response: response._0
            }
          };
  }
  var e = response._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".response" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var ClosedSiren = {
  closedSiren_encode: closedSiren_encode,
  closedSiren_decode: closedSiren_decode
};

export {
  attachment_encode ,
  attachment_decode ,
  contractAttachments_encode ,
  contractAttachments_decode ,
  attachmentsSaveRequest_encode ,
  attachmentsSaveRequest_decode ,
  SirenName ,
  Fields ,
  ClosedSiren ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
