// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../../../common/types/App_Types_Input.bs.js";
import * as Action_Referential_Fields_Utils$DvmAdminFrontendFr from "./Action_Referential_Fields_Utils.bs.js";

function fetchActionReferentialsFields(userManager, setResult, fieldType, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.stringList_decode, undefined, userManager, selectedRole, Action_Referential_Fields_Utils$DvmAdminFrontendFr.fieldTypeToEndpoint(fieldType)).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function createRow(userManager, setResult, newRow, submissionCallbacks, selectedRole, fieldType) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
          TAG: /* Json */0,
          _0: App_Types_Input$DvmAdminFrontendFr.stringList_encode(newRow)
        }, userManager, selectedRole, Action_Referential_Fields_Utils$DvmAdminFrontendFr.fieldTypeToEndpoint(fieldType)).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToSaveActionReferential */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function deleteRow(userManager, setResult, row, selectedRole, fieldType) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", {
          TAG: /* Json */0,
          _0: App_Types_Input$DvmAdminFrontendFr.stringList_encode({
                hd: row,
                tl: /* [] */0
              })
        }, userManager, selectedRole, Action_Referential_Fields_Utils$DvmAdminFrontendFr.fieldTypeToEndpoint(fieldType)).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

export {
  fetchActionReferentialsFields ,
  createRow ,
  deleteRow ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
