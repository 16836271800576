// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Css from "@emotion/css";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../common/App_Api.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Order_Search_Api$DvmAdminFrontendFr from "../../order/search/Order_Search_Api.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as Flash_Search_Modal$DvmAdminFrontendFr from "./searchModal/Flash_Search_Modal.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_SelectFormInput.bs.js";
import * as Messages_Flash_Detail$DvmAdminFrontendFr from "../../../intl/messages/flash/Messages_Flash_Detail.bs.js";
import * as Messages_Flash_Search$DvmAdminFrontendFr from "../../../intl/messages/flash/Messages_Flash_Search.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Flash_Search_Modal_Utils$DvmAdminFrontendFr from "./searchModal/Flash_Search_Modal_Utils.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_AutocompleteFormInput.bs.js";

var modalButton = Css.css(Object.assign({}, {
          color: Theme_Colors$DvmAdminFrontendFr.black,
          height: "48px",
          width: "48px"
        }, {
          "&:hover": {
            backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayMain
          }
        }));

var icon = Css.css({
      fontWeight: "bold"
    });

function usePreviousList(value) {
  var ref = React.useRef(/* [] */0);
  React.useEffect((function () {
          ref.current = value;
        }), [value]);
  return ref.current;
}

function Flash_Search_Filter(Props) {
  var form = Props.form;
  var formModal = Props.formModal;
  var formMoreDates = Props.formMoreDates;
  var formAdditional = Props.formAdditional;
  var tableType = Props.tableType;
  var submit = Props.submit;
  var alert = ReactAlert.useAlert();
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setBudgets = match[1];
  var match$1 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setActionTypesFleet = match$1[1];
  var actionTypesFleet = match$1[0];
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setActionTypesNonFleet = match$2[1];
  var actionTypesNonFleet = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setClientTypes = match$3[1];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaleTypes = match$4[1];
  var saleTypes = match$4[0];
  var match$5 = React.useState(function () {
        return false;
      });
  var setModal = match$5[1];
  var match$6 = React.useState(function () {
        return 0;
      });
  var setModalNumber = match$6[1];
  var previousOrderType = usePreviousList(form.input.orderType);
  var match$7 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$8 = match$7[0];
  var userManager = match$8.userManager;
  var selectedRole = match$8.selectedRole;
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchBudgets(userManager, setBudgets, selectedRole);
          App_Api$DvmAdminFrontendFr.fetchActionTypes(userManager, setActionTypesFleet, alert, selectedRole, "fleet", /* [] */0);
          if (User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* Spain */1) {
            Order_Search_Api$DvmAdminFrontendFr.fetchClientTypes(alert, userManager, setClientTypes, selectedRole);
            Order_Search_Api$DvmAdminFrontendFr.fetchSaleTypes(alert, userManager, setSaleTypes, selectedRole);
          }
          
        }), []);
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchActionTypes(userManager, setActionTypesNonFleet, alert, selectedRole, "nonfleet", form.input.orderType);
        }), [form.input.orderType]);
  var handleActionTypesList = function (param) {
    return Belt_List.keep(Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(tableType ? actionTypesNonFleet : actionTypesFleet, /* [] */0), (function (x) {
                                  return x.toUpperCase();
                                }))))), (function (x) {
                  return x.length > 0;
                }));
  };
  React.useEffect((function () {
          if (Belt_List.length(handleActionTypesList(undefined)) > 0 && !Belt_List.has(handleActionTypesList(undefined), form.input.actionType, (function (a, b) {
                    return a === b;
                  }))) {
            Curry._2(form.updateActionType, (function (input, actionType) {
                    return {
                            orderType: input.orderType,
                            actionID: input.actionID,
                            actionLabel: input.actionLabel,
                            actionType: actionType,
                            budgetType: input.budgetType,
                            startDate: input.startDate,
                            endDate: input.endDate,
                            clientTypes: input.clientTypes,
                            saleTypes: input.saleTypes
                          };
                  }), "");
          }
          
        }), [actionTypesNonFleet]);
  React.useEffect((function () {
          if (Belt_List.length(handleActionTypesList(undefined)) > 0 && !Belt_List.has(handleActionTypesList(undefined), form.input.actionType, (function (a, b) {
                    return a === b;
                  }))) {
            Curry._2(form.updateActionType, (function (input, actionType) {
                    return {
                            orderType: input.orderType,
                            actionID: input.actionID,
                            actionLabel: input.actionLabel,
                            actionType: actionType,
                            budgetType: input.budgetType,
                            startDate: input.startDate,
                            endDate: input.endDate,
                            clientTypes: input.clientTypes,
                            saleTypes: input.saleTypes
                          };
                  }), "");
          }
          
        }), [tableType]);
  React.useEffect((function () {
          Curry._1(setModalNumber, (function (param) {
                  return Flash_Search_Modal_Utils$DvmAdminFrontendFr.handleModalNumber(formModal, formMoreDates, formAdditional);
                }));
        }), [form]);
  React.useEffect((function () {
          if (Belt_List.cmp(previousOrderType, form.input.orderType, Caml.string_compare) !== 0) {
            if (!Belt_List.has(previousOrderType, "SO", (function (a, b) {
                      return a === b;
                    })) && Belt_List.has(form.input.orderType, "SO", (function (a, b) {
                      return a === b;
                    }))) {
              Curry._2(form.updateOrderType, (function (input, orderType) {
                      return {
                              orderType: orderType,
                              actionID: input.actionID,
                              actionLabel: input.actionLabel,
                              actionType: input.actionType,
                              budgetType: input.budgetType,
                              startDate: input.startDate,
                              endDate: input.endDate,
                              clientTypes: input.clientTypes,
                              saleTypes: input.saleTypes
                            };
                    }), {
                    hd: "SO",
                    tl: /* [] */0
                  });
            }
            if (Belt_List.every(previousOrderType, (function (x) {
                      return x === "SO";
                    })) && !Belt_List.every(form.input.orderType, (function (x) {
                      return x === "SO";
                    }))) {
              Curry._2(form.updateOrderType, (function (input, orderType) {
                      return {
                              orderType: orderType,
                              actionID: input.actionID,
                              actionLabel: input.actionLabel,
                              actionType: input.actionType,
                              budgetType: input.budgetType,
                              startDate: input.startDate,
                              endDate: input.endDate,
                              clientTypes: input.clientTypes,
                              saleTypes: input.saleTypes
                            };
                    }), Belt_List.keep(form.input.orderType, (function (x) {
                          return x !== "SO";
                        })));
            }
            
          }
          
        }), [form.input.orderType]);
  var filteredSalesTypes = function (x) {
    return Belt_List.keep(x, (function (x) {
                  var salesOne = Belt_List.has(form.input.clientTypes, "1", (function (a, b) {
                          return a === b;
                        })) ? Belt_List.has({
                          hd: "00",
                          tl: {
                            hd: "82",
                            tl: {
                              hd: "83",
                              tl: {
                                hd: "84",
                                tl: {
                                  hd: "86",
                                  tl: {
                                    hd: "90",
                                    tl: {
                                      hd: "91",
                                      tl: {
                                        hd: "92",
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }, x, (function (a, b) {
                            return a === b;
                          })) : false;
                  var salesThree = Belt_List.has(form.input.clientTypes, "3", (function (a, b) {
                          return a === b;
                        })) ? Belt_List.has({
                          hd: "97",
                          tl: {
                            hd: "98",
                            tl: {
                              hd: "99",
                              tl: /* [] */0
                            }
                          }
                        }, x, (function (a, b) {
                            return a === b;
                          })) : false;
                  if (Belt_List.length(form.input.clientTypes) > 0 && !salesOne) {
                    return salesThree;
                  } else {
                    return true;
                  }
                }));
  };
  var cleanSalesTypes = function (param) {
    return App_Types_Result$DvmAdminFrontendFr.mapWithDefault(saleTypes, /* [] */0, (function (x) {
                  return Belt_List.map(Belt_List.keep(Belt_List.keep(x, (function (x) {
                                        return x.paramOne.length > 0;
                                      })), (function (x) {
                                    var salesOne = Belt_List.has(form.input.clientTypes, "1", (function (a, b) {
                                            return a === b;
                                          })) ? Belt_List.has({
                                            hd: "00",
                                            tl: {
                                              hd: "82",
                                              tl: {
                                                hd: "83",
                                                tl: {
                                                  hd: "84",
                                                  tl: {
                                                    hd: "86",
                                                    tl: {
                                                      hd: "90",
                                                      tl: {
                                                        hd: "91",
                                                        tl: {
                                                          hd: "92",
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }, x.paramOne, (function (a, b) {
                                              return a === b;
                                            })) : false;
                                    var salesThree = Belt_List.has(form.input.clientTypes, "3", (function (a, b) {
                                            return a === b;
                                          })) ? Belt_List.has({
                                            hd: "97",
                                            tl: {
                                              hd: "98",
                                              tl: {
                                                hd: "99",
                                                tl: /* [] */0
                                              }
                                            }
                                          }, x.paramOne, (function (a, b) {
                                              return a === b;
                                            })) : false;
                                    if (Belt_List.length(form.input.clientTypes) > 0 && !salesOne) {
                                      return salesThree;
                                    } else {
                                      return true;
                                    }
                                  })), (function (x) {
                                return {
                                        label: {
                                          TAG: /* String */3,
                                          _0: x.paramOne + Belt_Option.mapWithDefault(x.paramTwo, "", (function (label) {
                                                  return " " + label + "";
                                                }))
                                        },
                                        value: x.paramOne
                                      };
                              }));
                }));
  };
  React.useEffect((function () {
          var filtered = filteredSalesTypes(form.input.saleTypes);
          Curry._2(form.updateSaleTypes, (function (input, saleTypes) {
                  return {
                          orderType: input.orderType,
                          actionID: input.actionID,
                          actionLabel: input.actionLabel,
                          actionType: input.actionType,
                          budgetType: input.budgetType,
                          startDate: input.startDate,
                          endDate: input.endDate,
                          clientTypes: input.clientTypes,
                          saleTypes: saleTypes
                        };
                }), filtered);
        }), [form.input.clientTypes]);
  var tmp;
  if (User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0) {
    var tmp$1 = {
      label: Messages_Common$DvmAdminFrontendFr.orderType,
      value: {
        TAG: /* Multiple */1,
        _0: {
          value: form.input.orderType,
          onChange: Curry._1(form.updateOrderType, (function (input, orderType) {
                  return {
                          orderType: orderType,
                          actionID: input.actionID,
                          actionLabel: input.actionLabel,
                          actionType: input.actionType,
                          budgetType: input.budgetType,
                          startDate: input.startDate,
                          endDate: input.endDate,
                          clientTypes: input.clientTypes,
                          saleTypes: input.saleTypes
                        };
                }))
        }
      },
      options: {
        TAG: /* Unlabeled */0,
        _0: User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? ({
              hd: "SO",
              tl: {
                hd: "CL",
                tl: {
                  hd: "VD",
                  tl: {
                    hd: "VE",
                    tl: {
                      hd: "RR",
                      tl: {
                        hd: "RC",
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }) : ({
              hd: "CL",
              tl: {
                hd: "VD",
                tl: {
                  hd: "VE",
                  tl: {
                    hd: "RR",
                    tl: {
                      hd: "RC",
                      tl: /* [] */0
                    }
                  }
                }
              }
            })
      },
      required: /* OnlyLabel */1,
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInputLong,
      smallChips: true
    };
    if (form.orderTypeResult !== undefined) {
      tmp$1.validationResult = Caml_option.valFromOption(form.orderTypeResult);
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Core.Tooltip, {
              arrow: true,
              children: React.createElement("div", undefined, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                        size: /* Large */3,
                        type_: /* Information */43
                      })),
              classes: {
                tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
              },
              placement: "bottom",
              title: intl.formatMessage(Messages_Flash_Search$DvmAdminFrontendFr.ordetTypeOnlyFleetOrNonfleet),
              style: {
                display: "flex",
                alignItems: "center"
              }
            }), React.createElement(Core.Grid, {
              children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$1),
              item: true
            }));
  } else {
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
              children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                    label: Messages_Common$DvmAdminFrontendFr.clientType,
                    value: {
                      TAG: /* Multiple */1,
                      _0: {
                        value: form.input.clientTypes,
                        onChange: Curry._1(form.updateClientTypes, (function (input, clientTypes) {
                                return {
                                        orderType: input.orderType,
                                        actionID: input.actionID,
                                        actionLabel: input.actionLabel,
                                        actionType: input.actionType,
                                        budgetType: input.budgetType,
                                        startDate: input.startDate,
                                        endDate: input.endDate,
                                        clientTypes: clientTypes,
                                        saleTypes: input.saleTypes
                                      };
                              }))
                      }
                    },
                    options: {
                      TAG: /* Labeled */1,
                      _0: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(match$3[0], /* [] */0, (function (x) {
                              return Belt_List.map(Belt_List.keep(Belt_List.keep(x, (function (x) {
                                                    return x.paramOne.length > 0;
                                                  })), (function (x) {
                                                if (x.paramOne === "1") {
                                                  return true;
                                                } else {
                                                  return x.paramOne === "3";
                                                }
                                              })), (function (x) {
                                            return {
                                                    label: {
                                                      TAG: /* String */3,
                                                      _0: x.paramOne + Belt_Option.mapWithDefault(x.paramTwo, "", (function (label) {
                                                              return " " + label + "";
                                                            }))
                                                    },
                                                    value: x.paramOne
                                                  };
                                          }));
                            }))
                    },
                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInputLong,
                    limitTags: 2,
                    smallChips: true
                  }),
              item: true
            }), React.createElement(Core.Grid, {
              children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                    label: Messages_Common$DvmAdminFrontendFr.saleType,
                    value: {
                      TAG: /* Multiple */1,
                      _0: {
                        value: form.input.saleTypes,
                        onChange: Curry._1(form.updateSaleTypes, (function (input, saleTypes) {
                                return {
                                        orderType: input.orderType,
                                        actionID: input.actionID,
                                        actionLabel: input.actionLabel,
                                        actionType: input.actionType,
                                        budgetType: input.budgetType,
                                        startDate: input.startDate,
                                        endDate: input.endDate,
                                        clientTypes: input.clientTypes,
                                        saleTypes: saleTypes
                                      };
                              }))
                      }
                    },
                    options: {
                      TAG: /* Labeled */1,
                      _0: cleanSalesTypes(undefined)
                    },
                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInputLong,
                    limitTags: 2,
                    smallChips: true
                  }),
              item: true
            }));
  }
  var tmp$2 = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    orderType: input.orderType,
                    actionID: input.actionID,
                    actionLabel: input.actionLabel,
                    actionType: input.actionType,
                    budgetType: input.budgetType,
                    startDate: startDate,
                    endDate: input.endDate,
                    clientTypes: input.clientTypes,
                    saleTypes: input.saleTypes
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
  };
  if (form.startDateResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  var tmp$3 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    orderType: input.orderType,
                    actionID: input.actionID,
                    actionLabel: input.actionLabel,
                    actionType: input.actionType,
                    budgetType: input.budgetType,
                    startDate: input.startDate,
                    endDate: endDate,
                    clientTypes: input.clientTypes,
                    saleTypes: input.saleTypes
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
  };
  if (form.endDateResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(setModal, (function (param) {
                          return false;
                        }));
                  Curry._1(submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, tmp, React.createElement(Core.Grid, {
                      children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                            show: App_Types_Result$DvmAdminFrontendFr.isPending(actionTypesFleet) || App_Types_Result$DvmAdminFrontendFr.isPending(actionTypesNonFleet),
                            children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                  label: Messages_Flash_Detail$DvmAdminFrontendFr.flashType,
                                  value: {
                                    TAG: /* Single */0,
                                    _0: {
                                      value: form.input.actionType,
                                      onChange: Curry._1(form.updateActionType, (function (input, actionType) {
                                              return {
                                                      orderType: input.orderType,
                                                      actionID: input.actionID,
                                                      actionLabel: input.actionLabel,
                                                      actionType: actionType,
                                                      budgetType: input.budgetType,
                                                      startDate: input.startDate,
                                                      endDate: input.endDate,
                                                      clientTypes: input.clientTypes,
                                                      saleTypes: input.saleTypes
                                                    };
                                            }))
                                    }
                                  },
                                  options: {
                                    TAG: /* Unlabeled */0,
                                    _0: handleActionTypesList(undefined)
                                  },
                                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                                })
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry._1(form.updateActionID, (function (input, actionID) {
                                    return {
                                            orderType: input.orderType,
                                            actionID: actionID,
                                            actionLabel: input.actionLabel,
                                            actionType: input.actionType,
                                            budgetType: input.budgetType,
                                            startDate: input.startDate,
                                            endDate: input.endDate,
                                            clientTypes: input.clientTypes,
                                            saleTypes: input.saleTypes
                                          };
                                  })),
                            value: form.input.actionID,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
                            label: Messages_Flash_Search$DvmAdminFrontendFr.flashMarketingActionID
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry._1(form.updateActionLabel, (function (input, actionLabel) {
                                    return {
                                            orderType: input.orderType,
                                            actionID: input.actionID,
                                            actionLabel: actionLabel,
                                            actionType: input.actionType,
                                            budgetType: input.budgetType,
                                            startDate: input.startDate,
                                            endDate: input.endDate,
                                            clientTypes: input.clientTypes,
                                            saleTypes: input.saleTypes
                                          };
                                  })),
                            value: form.input.actionLabel,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
                            label: Messages_Flash_Search$DvmAdminFrontendFr.bonusLabel
                          }),
                      item: true
                    }), User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? React.createElement(Core.Grid, {
                        children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.budget,
                              options: Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(match[0], /* [] */0), (function (x) {
                                      return {
                                              label: {
                                                TAG: /* String */3,
                                                _0: x
                                              },
                                              value: x
                                            };
                                    })),
                              value: {
                                TAG: /* Multiple */1,
                                _0: {
                                  value: form.input.budgetType,
                                  onChange: Curry._1(form.updateBudgetType, (function (input, budgetType) {
                                          return {
                                                  orderType: input.orderType,
                                                  actionID: input.actionID,
                                                  actionLabel: input.actionLabel,
                                                  actionType: input.actionType,
                                                  budgetType: budgetType,
                                                  startDate: input.startDate,
                                                  endDate: input.endDate,
                                                  clientTypes: input.clientTypes,
                                                  saleTypes: input.saleTypes
                                                };
                                        }))
                                }
                              },
                              id: "budget-type",
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
                              disabled: tableType === /* NonFleet */1
                            }),
                        item: true
                      }) : null, React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$2),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$3),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(Core.Button, {
                            onClick: (function (param) {
                                Curry._1(setModal, (function (param) {
                                        return true;
                                      }));
                              }),
                            children: React.createElement(Core.Badge, {
                                  anchorOrigin: {
                                    horizontal: "right",
                                    vertical: "bottom"
                                  },
                                  badgeContent: match$6[0],
                                  children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                        size: /* Large */3,
                                        type_: /* Filter */63,
                                        iconClassName: icon
                                      }),
                                  color: "primary"
                                }),
                            className: modalButton,
                            type: "button"
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            type_: "submit"
                          }),
                      item: true
                    })), match$5[0] ? React.createElement(Flash_Search_Modal$DvmAdminFrontendFr.make, {
                    onClose: (function (param) {
                        Curry._1(setModal, (function (param) {
                                return false;
                              }));
                      }),
                    submit: submit,
                    orderType: form.input.orderType,
                    form: formModal,
                    formMoreDates: formMoreDates,
                    formAdditional: formAdditional
                  }) : null);
}

var InputStyles;

var Form;

var Api;

var make = Flash_Search_Filter;

export {
  InputStyles ,
  Form ,
  Api ,
  modalButton ,
  icon ,
  usePreviousList ,
  make ,
}
/* modalButton Not a pure module */
