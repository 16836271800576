// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import DateFns from "@date-io/date-fns";
import * as Core from "@material-ui/core";
import Fr from "date-fns/locale/fr";
import * as Pickers from "@material-ui/pickers";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as App_Spinner$DvmAdminFrontendFr from "../../../common/components/App_Spinner.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../../context/router/Context_Router.bs.js";
import * as App_Table_Result$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Result.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Flash_Search_Api$DvmAdminFrontendFr from "./Flash_Search_Api.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as Flash_Search_Form$DvmAdminFrontendFr from "./Flash_Search_Form.bs.js";
import * as Flash_Search_Table$DvmAdminFrontendFr from "./Flash_Search_Table.bs.js";
import * as Flash_Search_Utils$DvmAdminFrontendFr from "./Flash_Search_Utils.bs.js";
import * as Flash_Search_Filter$DvmAdminFrontendFr from "./Flash_Search_Filter.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Pagination.bs.js";
import * as Messages_Flash_Search$DvmAdminFrontendFr from "../../../intl/messages/flash/Messages_Flash_Search.bs.js";
import * as Flash_Search_Modal_Form$DvmAdminFrontendFr from "./searchModal/filterPages/Flash_Search_Modal_Form.bs.js";
import * as Flash_Search_Modal_Additional_Form$DvmAdminFrontendFr from "./searchModal/filterPages/Flash_Search_Modal_Additional_Form.bs.js";
import * as Flash_Search_Modal_More_Dates_Form$DvmAdminFrontendFr from "./searchModal/filterPages/Flash_Search_Modal_More_Dates_Form.bs.js";

function Flash_Search_Page(Props) {
  var operation = Props.operation;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match[1];
  var tablePage = match[0];
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var selectedRole = match$1[0].selectedRole;
  var match$2 = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match$2[1];
  var match$3 = match$2[0];
  var flashFilter = match$3.flashFilter;
  var match$4 = React.useState(function () {
        
      });
  var setFormList = match$4[1];
  var formList = match$4[0];
  var match$5 = React.useState(function () {
        
      });
  var setFormModalList = match$5[1];
  var formModalList = match$5[0];
  var match$6 = React.useState(function () {
        
      });
  var setFormMoreDatesList = match$6[1];
  var formMoreDatesList = match$6[0];
  var match$7 = React.useState(function () {
        
      });
  var setFormModalAdditionalList = match$7[1];
  var formModalAdditionalList = match$7[0];
  var match$8 = React.useState(function () {
        
      });
  var setSubmissionCallbacks = match$8[1];
  var submissionCallbacks = match$8[0];
  var match$9 = React.useState(function () {
        
      });
  var setSubmissionCallbacksModal = match$9[1];
  var submissionCallbacksModal = match$9[0];
  var match$10 = React.useState(function () {
        
      });
  var setSubmissionCallbacksMoreDates = match$10[1];
  var submissionCallbacksMoreDates = match$10[0];
  var match$11 = React.useState(function () {
        
      });
  var setSubmissionCallbacksModalAdditional = match$11[1];
  var submissionCallbacksModalAdditional = match$11[0];
  var initialTableType = function (param) {
    if (User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* Spain */1) {
      return /* NonFleet */1;
    } else {
      return Belt_Option.mapWithDefault(flashFilter, User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? /* Fleet */0 : /* NonFleet */1, (function (x) {
                    if (Belt_List.every(x.orderType, (function (x) {
                              return x === "SO";
                            }))) {
                      return /* Fleet */0;
                    } else {
                      return /* NonFleet */1;
                    }
                  }));
    }
  };
  var match$12 = React.useState(function () {
        return initialTableType(undefined);
      });
  var setSubmitTableType = match$12[1];
  var match$13 = React.useState(function () {
        return initialTableType(undefined);
      });
  var setTableType = match$13[1];
  var tableType = match$13[0];
  var match$14 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: Caml_array.get(App_Table_Pagination$DvmAdminFrontendFr.options, 0),
                headers: Flash_Search_Table$DvmAdminFrontendFr.headersConfiguration(tableType, operation, selectedRole),
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$14[1];
  var fetchRequest = match$14[0];
  var alert = ReactAlert.useAlert();
  var match$15 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$16 = match$15[0];
  var userManager = match$16.userManager;
  var selectedRole$1 = match$16.selectedRole;
  var fetchData = function (submissionCallbacks, submissionCallbacksModal, submissionCallbacksModalAdditional, tableType, fetchRequest) {
    Flash_Search_Api$DvmAdminFrontendFr.fetchFlashSearch(submissionCallbacks, submissionCallbacksModal, submissionCallbacksMoreDates, submissionCallbacksModalAdditional, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, fetchRequest), selectedRole$1, tableType);
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  React.useEffect((function () {
          if (submissionCallbacks !== undefined && submissionCallbacksModal !== undefined && submissionCallbacksModalAdditional !== undefined) {
            fetchData(submissionCallbacks, submissionCallbacksModal, submissionCallbacksModalAdditional, tableType, {
                  page: 0,
                  rowsPerPage: fetchRequest.rowsPerPage,
                  headers: Flash_Search_Table$DvmAdminFrontendFr.headersConfiguration(tableType, operation, selectedRole$1),
                  filterQueryParams: Flash_Search_Utils$DvmAdminFrontendFr.getQueryParams(formList, formModalList, formMoreDatesList, formModalAdditionalList, tableType, "en", selectedRole$1),
                  additionalQueryParams: fetchRequest.additionalQueryParams
                });
          }
          
        }), [formModalAdditionalList]);
  var form = Flash_Search_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.getWithDefault(flashFilter, Flash_Search_Form$DvmAdminFrontendFr.initialState), {
        selectedRole: selectedRole$1
      }, (function (output, submissionCallbacks) {
          Curry._1(setSubmissionCallbacks, (function (param) {
                  return submissionCallbacks;
                }));
          Curry._1(setFormList, (function (param) {
                  return output;
                }));
        }));
  var formModal = Flash_Search_Modal_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.getWithDefault(match$3.flashModalFilter, Flash_Search_Modal_Form$DvmAdminFrontendFr.initialState), {
        orderType: form.input.orderType
      }, (function (output, submissionCallbacks) {
          Curry._1(setSubmissionCallbacksModal, (function (param) {
                  return submissionCallbacks;
                }));
          Curry._1(setFormModalList, (function (param) {
                  return output;
                }));
        }));
  var formMoreDates = Flash_Search_Modal_More_Dates_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.getWithDefault(match$3.flashMoreDatesFilter, Flash_Search_Modal_More_Dates_Form$DvmAdminFrontendFr.initialState), {
        orderType: form.input.orderType
      }, (function (output, submissionCallbacks) {
          Curry._1(setSubmissionCallbacksMoreDates, (function (param) {
                  return submissionCallbacks;
                }));
          Curry._1(setFormMoreDatesList, (function (param) {
                  return output;
                }));
        }));
  var formAdditional = Flash_Search_Modal_Additional_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.getWithDefault(match$3.flashAdditionalFilter, Flash_Search_Modal_Additional_Form$DvmAdminFrontendFr.initialState), (function (output, submissionCallbacks) {
          Curry._1(setSubmissionCallbacksModalAdditional, (function (param) {
                  return submissionCallbacks;
                }));
          Curry._1(setFormModalAdditionalList, (function (param) {
                  return output;
                }));
        }));
  var submit = function (param) {
    Curry._1(setSubmitTableType, (function (param) {
            return tableType;
          }));
    Curry._1(dispatch, {
          TAG: /* StoreFlashFilter */8,
          _0: form.input
        });
    Curry._1(dispatch, {
          TAG: /* StoreFlashModalFilter */9,
          _0: formModal.input
        });
    Curry._1(dispatch, {
          TAG: /* StoreFlashMoreDatesFilter */10,
          _0: formMoreDates.input
        });
    Curry._1(dispatch, {
          TAG: /* StoreFlashModalAdditionalFilter */11,
          _0: formAdditional.input
        });
    Belt_Option.mapWithDefault(submissionCallbacks, undefined, (function (x) {
            Curry._1(x.notifyOnSuccess, undefined);
          }));
    Belt_Option.mapWithDefault(submissionCallbacksModal, undefined, (function (x) {
            Curry._1(x.notifyOnSuccess, undefined);
          }));
    Belt_Option.mapWithDefault(submissionCallbacksMoreDates, undefined, (function (x) {
            Curry._1(x.notifyOnSuccess, undefined);
          }));
    Belt_Option.mapWithDefault(submissionCallbacksModalAdditional, undefined, (function (x) {
            Curry._1(x.notifyOnSuccess, undefined);
          }));
    Curry._1(form.submit, undefined);
    Curry._1(formModal.submit, undefined);
    Curry._1(formMoreDates.submit, undefined);
    Curry._1(formAdditional.submit, undefined);
  };
  React.useEffect((function () {
          Curry._1(setTableType, (function (param) {
                  if (User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole$1) === /* Spain */1 || !Belt_List.every(form.input.orderType, (function (x) {
                            return x === "SO";
                          }))) {
                    return /* NonFleet */1;
                  } else {
                    return /* Fleet */0;
                  }
                }));
        }), [form.input.orderType]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.forError(tablePage, (function (error) {
                  Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                            message: {
                              TAG: /* RestError */2,
                              _0: error
                            }
                          }));
                }));
        }), [tablePage]);
  var tmp;
  tmp = typeof tablePage === "number" ? (
      tablePage === /* NotStarted */0 ? null : React.createElement(App_Spinner$DvmAdminFrontendFr.make, {})
    ) : (
      tablePage.TAG === /* Ok */0 ? React.createElement(App_Table_Result$DvmAdminFrontendFr.make, {
              number: tablePage._0.total,
              message: Messages_Flash_Search$DvmAdminFrontendFr.resultInfo
            }) : null
    );
  return React.createElement(React.Fragment, undefined, React.createElement(Pickers.MuiPickersUtilsProvider, {
                  utils: DateFns,
                  children: null,
                  locale: Fr
                }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                      style: {
                        fontWeight: "600"
                      },
                      children: intl.formatMessage(Messages_Flash_Search$DvmAdminFrontendFr.title),
                      variant: "h3"
                    }), React.createElement(Flash_Search_Filter$DvmAdminFrontendFr.make, {
                      form: form,
                      formModal: formModal,
                      formMoreDates: formMoreDates,
                      formAdditional: formAdditional,
                      tableType: tableType,
                      submit: submit
                    }), React.createElement(Core.Box, {
                      children: null
                    }, tmp, React.createElement(Flash_Search_Table$DvmAdminFrontendFr.make, {
                          tablePage: tablePage,
                          fetchRequest: fetchRequest,
                          fetchData: fetchData,
                          operation: operation,
                          formList: formList,
                          formModalList: formModalList,
                          formMoreDatesList: formMoreDatesList,
                          formModalAdditionalList: formModalAdditionalList,
                          submitTableType: match$12[0]
                        }), tablePage === 0 ? React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                            style: {
                              fontWeight: "600",
                              paddingTop: "100px",
                              textAlign: "center"
                            },
                            children: intl.formatMessage(Messages_Flash_Search$DvmAdminFrontendFr.searchForAction),
                            variant: "bodyBig"
                          }) : null)));
}

var Api;

var Table;

var Filter;

var Form;

var FormModal;

var FormMoreDates;

var FormModalAdditional;

var make = Flash_Search_Page;

export {
  Api ,
  Table ,
  Filter ,
  Form ,
  FormModal ,
  FormMoreDates ,
  FormModalAdditional ,
  make ,
}
/* react Not a pure module */
