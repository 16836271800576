// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function getSaveRows(marginRows, isAllEdited, budgetOrID) {
  return Belt_List.map(Belt_List.fromArray(Belt_Array.keep(marginRows, (function (x) {
                        if (x.toSave) {
                          return true;
                        } else {
                          return isAllEdited;
                        }
                      }))), (function (x) {
                return {
                        original: {
                          budget: x.budgetOriginal,
                          model: x.modelOriginal,
                          version: x.versionOriginal,
                          avsMarginGridID: budgetOrID,
                          categoryID: x.someIDOriginal,
                          discount: x.discountOriginal,
                          margin: x.marginOriginal,
                          previousEndDate: x.previousEndDate,
                          startDate: Belt_Option.getWithDefault(x.startDateOriginal, new Date(0.0)),
                          endDate: x.endDateOriginal,
                          hasExceptions: x.hasExceptionsOriginal,
                          followingStartDate: x.followingStartDate,
                          acquisitionType: x.acquisitionTypeOriginal,
                          contractID: budgetOrID,
                          categoryName: undefined
                        },
                        new: {
                          budget: x.budgetOriginal,
                          model: x.modelOriginal,
                          version: x.versionOriginal,
                          avsMarginGridID: budgetOrID,
                          categoryID: x.someID,
                          discount: Belt_Option.getWithDefault(x.discount, 0.0),
                          margin: x.margin,
                          previousEndDate: x.previousEndDate,
                          startDate: Belt_Option.getWithDefault(x.startDate, new Date(0.0)),
                          endDate: x.endDate,
                          hasExceptions: x.hasExceptions,
                          followingStartDate: x.followingStartDate,
                          acquisitionType: x.acquisitionType,
                          contractID: budgetOrID,
                          categoryName: undefined
                        }
                      };
              }));
}

function resetForm(form, tablePage, setIsAllEdited, budget) {
  Curry._1(form.reset, undefined);
  if (typeof tablePage !== "number" && tablePage.TAG === /* Ok */0) {
    Belt_List.map(tablePage._0.content, (function (x) {
            Curry._1(form.addSingleRow, {
                  someID: Belt_Option.getWithDefault(x.categoryID, ""),
                  someIDOriginal: Belt_Option.getWithDefault(x.categoryID, ""),
                  acquisitionType: Belt_Option.getWithDefault(x.acquisitionType, ""),
                  acquisitionTypeOriginal: Belt_Option.getWithDefault(x.acquisitionType, ""),
                  discount: x.discount,
                  discountOriginal: x.discount,
                  margin: x.margin,
                  marginOriginal: Belt_Option.getWithDefault(x.margin, 0.0),
                  startDate: Caml_option.some(x.startDate),
                  startDateOriginal: Caml_option.some(x.startDate),
                  endDate: x.endDate,
                  endDateOriginal: x.endDate,
                  hasExceptions: Belt_Option.getWithDefault(x.hasExceptions, false),
                  hasExceptionsOriginal: Belt_Option.getWithDefault(x.hasExceptions, false),
                  followingStartDate: x.followingStartDate,
                  previousEndDate: x.previousEndDate,
                  isEdited: false,
                  toSave: false,
                  budgetOriginal: budget,
                  modelOriginal: Belt_Option.getWithDefault(x.model, ""),
                  versionOriginal: Belt_Option.getWithDefault(x.version, "")
                });
          }));
  }
  return Curry._1(setIsAllEdited, (function (param) {
                return false;
              }));
}

var Form;

export {
  Form ,
  getSaveRows ,
  resetForm ,
}
/* No side effect */
