// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../common/components/icons/App_Icon.bs.js";
import * as App_Table$DvmAdminFrontendFr from "../../common/components/table/App_Table.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../common/utils/Utils_Api.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../intl/Intl_Utils.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../common/types/App_Types_Result.bs.js";
import * as Model_Version_Types$DvmAdminFrontendFr from "./Model_Version_Types.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../common/components/table/App_Table_Pagination.bs.js";
import * as App_Table_Export_Menu$DvmAdminFrontendFr from "../../common/components/table/App_Table_Export_Menu.bs.js";
import * as Messages_ModelVersion$DvmAdminFrontendFr from "../../intl/messages/modelVersion/Messages_ModelVersion.bs.js";
import * as ModelVersion_Motorization_Modal$DvmAdminFrontendFr from "./ModelVersion_Motorization_Modal.bs.js";

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Model_Version_Types$DvmAdminFrontendFr.FieldsVersion.model, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.model);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Model_Version_Types$DvmAdminFrontendFr.FieldsVersion.modelLabel, undefined, undefined, undefined, undefined, undefined, Messages_ModelVersion$DvmAdminFrontendFr.labelModelShort),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Model_Version_Types$DvmAdminFrontendFr.FieldsVersion.version, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.version),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Model_Version_Types$DvmAdminFrontendFr.FieldsVersion.versionLabel, undefined, undefined, undefined, undefined, undefined, Messages_ModelVersion$DvmAdminFrontendFr.labelVersionShort),
      tl: {
        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Model_Version_Types$DvmAdminFrontendFr.FieldsVersion.identificationEngineType, undefined, undefined, undefined, undefined, undefined, Messages_ModelVersion$DvmAdminFrontendFr.motorization),
        tl: {
          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Model_Version_Types$DvmAdminFrontendFr.FieldsVersion.tariff, /* Desc */1, undefined, undefined, undefined, undefined, Messages_ModelVersion$DvmAdminFrontendFr.tarifNumber),
          tl: {
            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Model_Version_Types$DvmAdminFrontendFr.FieldsVersion.versionVehicleType, undefined, undefined, undefined, undefined, undefined, Messages_ModelVersion$DvmAdminFrontendFr._type),
            tl: {
              hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Model_Version_Types$DvmAdminFrontendFr.FieldsVersion.versionVehiclePhase, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.phase),
              tl: {
                hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Model_Version_Types$DvmAdminFrontendFr.FieldsVersion.versionActive, undefined, undefined, undefined, undefined, undefined, Messages_ModelVersion$DvmAdminFrontendFr.active),
                tl: {
                  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Model_Version_Types$DvmAdminFrontendFr.FieldsVersion.additionDate, undefined, undefined, undefined, undefined, undefined, Messages_ModelVersion$DvmAdminFrontendFr.additionDateLabel),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    }
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function ModelVersion_Version_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var allowEdit = Props.allowEdit;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return null;
      });
  var setModal = match[1];
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (row) {
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, Caml_option.some(allowEdit ? React.createElement(Core.Button, {
                                                        onClick: (function (param) {
                                                            Curry._1(setModal, (function (param) {
                                                                    return React.createElement(ModelVersion_Motorization_Modal$DvmAdminFrontendFr.make, {
                                                                                onClose: (function (param) {
                                                                                    Curry._1(setModal, (function (param) {
                                                                                            return null;
                                                                                          }));
                                                                                  }),
                                                                                row: row,
                                                                                onOk: (function (param) {
                                                                                    Curry._1(setModal, (function (param) {
                                                                                            return null;
                                                                                          }));
                                                                                    Curry._3(fetchData, undefined, /* Version */1, fetchRequest);
                                                                                  })
                                                                              });
                                                                  }));
                                                          }),
                                                        children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                              size: /* Large */3,
                                                              type_: /* Edit */60
                                                            }),
                                                        key: "edit"
                                                      }) : null), undefined, Belt_List.mapWithIndex({
                                                  hd: row.model,
                                                  tl: {
                                                    hd: row.modelLabel,
                                                    tl: {
                                                      hd: row.version,
                                                      tl: {
                                                        hd: row.versionLabel,
                                                        tl: {
                                                          hd: Belt_Option.getWithDefault(row.identificationEngineType, ""),
                                                          tl: {
                                                            hd: Belt_Option.getWithDefault(row.tariff, ""),
                                                            tl: {
                                                              hd: Belt_Option.getWithDefault(row.versionVehicleType, ""),
                                                              tl: {
                                                                hd: Belt_Option.getWithDefault(row.versionVehiclePhase, ""),
                                                                tl: {
                                                                  hd: Belt_Option.mapWithDefault(row.versionActive, "", (function (x) {
                                                                          return intl.formatMessage(x ? Messages_Common$DvmAdminFrontendFr.yes : Messages_Common$DvmAdminFrontendFr.no);
                                                                        })),
                                                                  tl: {
                                                                    hd: Belt_Option.mapWithDefault(row.additionDate, "", (function (param) {
                                                                            return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                          })),
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._3(fetchData, undefined, /* Version */1, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._3(fetchData, undefined, /* Version */1, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._3(fetchData, undefined, /* Version */1, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var tmp = {
    headers: fetchRequest.headers,
    handleSorting: handleSorting,
    tablePage: convertTable(tablePage),
    paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined),
    exportMenu: React.createElement(App_Table_Export_Menu$DvmAdminFrontendFr.make, {
          query: Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                page: 0,
                rowsPerPage: 150000,
                headers: fetchRequest.headers,
                filterQueryParams: fetchRequest.filterQueryParams,
                additionalQueryParams: fetchRequest.additionalQueryParams
              }),
          endpoint: "modelversion/version"
        })
  };
  var tmp$1 = allowEdit ? ({
        TAG: /* Left */0,
        _0: /* EmptyCell */0
      }) : undefined;
  if (tmp$1 !== undefined) {
    tmp.rowButton = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, tmp), match[0]);
}

var Types;

var make = ModelVersion_Version_Table;

export {
  Types ,
  headersConfiguration ,
  make ,
}
/* headersConfiguration Not a pure module */
