// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Flash_Edit_NonFleet_Version_Types$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_Version_Types.bs.js";

function comparatorStr(header, first, second) {
  var match = header.order;
  switch (match) {
    case /* Asc */0 :
        return Caml.string_compare(first, second);
    case /* Desc */1 :
        return Caml.string_compare(second, first);
    case /* NotSet */2 :
        return 0;
    
  }
}

function comparatorBool(header, first, second) {
  var match = header.order;
  switch (match) {
    case /* Asc */0 :
        return Caml.bool_compare(first, second);
    case /* Desc */1 :
        return Caml.bool_compare(second, first);
    case /* NotSet */2 :
        return 0;
    
  }
}

function comparatorFloat(header, first, second) {
  var match = header.order;
  switch (match) {
    case /* Asc */0 :
        return Caml.float_compare(first, second);
    case /* Desc */1 :
        return Caml.float_compare(second, first);
    case /* NotSet */2 :
        return 0;
    
  }
}

function comparatorDate(header, first, second) {
  var firstDate = Belt_Option.getWithDefault(first, new Date(0.0));
  var secondDate = Belt_Option.getWithDefault(second, new Date(0.0));
  var match = header.order;
  switch (match) {
    case /* Asc */0 :
        return ReDate.compareAsc(firstDate, secondDate);
    case /* Desc */1 :
        return ReDate.compareAsc(secondDate, firstDate);
    case /* NotSet */2 :
        return 0;
    
  }
}

function sortList(filteredList, tableHandler) {
  return Belt_List.sort(filteredList, (function (a, b) {
                return Belt_List.reduce(tableHandler.headers, 0, (function (comparatorResult, header) {
                              if (!header.sort) {
                                return comparatorResult;
                              }
                              var idheader = header.idheader;
                              if (idheader === Flash_Edit_NonFleet_Version_Types$DvmAdminFrontendFr.Fields.model) {
                                return comparatorResult + Math.imul(comparatorStr(header, a.model, b.model), 5) | 0;
                              } else if (idheader === Flash_Edit_NonFleet_Version_Types$DvmAdminFrontendFr.Fields.version) {
                                return comparatorResult + (comparatorStr(header, a.version, b.version) << 2) | 0;
                              } else if (idheader === Flash_Edit_NonFleet_Version_Types$DvmAdminFrontendFr.Fields.versionLabel) {
                                return comparatorResult + Math.imul(comparatorStr(header, Belt_Option.getWithDefault(a.versionLabel, ""), Belt_Option.getWithDefault(b.versionLabel, "")), 3) | 0;
                              } else if (idheader === Flash_Edit_NonFleet_Version_Types$DvmAdminFrontendFr.Fields.customBonus) {
                                return comparatorResult + (comparatorFloat(header, Belt_Option.getWithDefault(a.customBonus, 0.0), Belt_Option.getWithDefault(b.customBonus, 0.0)) << 1) | 0;
                              } else if (idheader === Flash_Edit_NonFleet_Version_Types$DvmAdminFrontendFr.Fields.isCustomFEValue) {
                                return comparatorResult + (comparatorBool(header, Belt_Option.getWithDefault(a.isCustomFEValue, false), Belt_Option.getWithDefault(b.isCustomFEValue, false)) << 0) | 0;
                              } else {
                                return comparatorResult;
                              }
                            }));
              }));
}

var Types;

export {
  Types ,
  comparatorStr ,
  comparatorBool ,
  comparatorFloat ,
  comparatorDate ,
  sortList ,
}
/* No side effect */
