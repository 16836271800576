// Generated by ReScript, PLEASE EDIT WITH CARE

import * as VEHICLE_VO_ICONSvg from "./VEHICLE_VO_ICON.svg";
import * as GR_ICONS_24px_FILTER_1Svg from "./GR_ICONS_24px_FILTER_1.svg";
import * as RENAULT_GROUP_WHITE_RGBSvg from "./RENAULT_GROUP_WHITE_RGB.svg";
import * as VEHICLE_VO_ICON_CROSSEDSvg from "./VEHICLE_VO_ICON_CROSSED.svg";

var make = RENAULT_GROUP_WHITE_RGBSvg.ReactComponent;

var RenaultGroupLogo = {
  make: make
};

var make$1 = GR_ICONS_24px_FILTER_1Svg.ReactComponent;

var DesyreFilterIcon = {
  make: make$1
};

var make$2 = VEHICLE_VO_ICONSvg.ReactComponent;

var VO_Icon = {
  make: make$2
};

var make$3 = VEHICLE_VO_ICON_CROSSEDSvg.ReactComponent;

var VO_Icon_Crossed = {
  make: make$3
};

export {
  RenaultGroupLogo ,
  DesyreFilterIcon ,
  VO_Icon ,
  VO_Icon_Crossed ,
}
/* make Not a pure module */
