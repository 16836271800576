// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../../route/Route_Paths.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Category_Edit_Api$DvmAdminFrontendFr from "./Category_Edit_Api.bs.js";
import * as Messages_Category$DvmAdminFrontendFr from "../../../intl/messages/category/Messages_Category.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as Category_Edit_CategoryInfo$DvmAdminFrontendFr from "./Category_Edit_CategoryInfo.bs.js";
import * as Category_Edit_VersionsSelection$DvmAdminFrontendFr from "./Category_Edit_VersionsSelection.bs.js";

function Category_Edit_Page(Props) {
  var categoryCode = Props.categoryCode;
  var startDate = Props.startDate;
  var match = React.useState(function () {
        
      });
  var setCategory = match[1];
  var category = match[0];
  var alert = ReactAlert.useAlert();
  var intl = ReactIntl.useIntl();
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$3[1];
  var result = match$3[0];
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var setVersions = match$4[1];
  var versions = match$4[0];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResultVersions = match$5[1];
  var resultVersions = match$5[0];
  var match$6 = React.useState(function () {
        return false;
      });
  var setWithClosedModels = match$6[1];
  var withClosedModels = match$6[0];
  var refresh = function (param) {
    Belt_Option.mapWithDefault(categoryCode, undefined, (function (category) {
            Category_Edit_Api$DvmAdminFrontendFr.fetchCategory(category, setResult, userManager, Belt_Option.getWithDefault(startDate, new Date(0.0)).toISOString(), selectedRole, withClosedModels);
            Category_Edit_Api$DvmAdminFrontendFr.fetchAllVersions("?limit=150000&offset=0", setResultVersions, userManager, selectedRole);
          }));
  };
  React.useEffect((function () {
          refresh(undefined);
        }), []);
  React.useEffect((function () {
          Belt_Option.mapWithDefault(categoryCode, undefined, (function (category) {
                  Category_Edit_Api$DvmAdminFrontendFr.fetchCategory(category, setResult, userManager, Belt_Option.getWithDefault(startDate, new Date(0.0)).toISOString(), selectedRole, withClosedModels);
                }));
        }), [withClosedModels]);
  React.useEffect((function () {
          if (typeof resultVersions === "number") {
            resultVersions === /* NotStarted */0;
          } else if (resultVersions.TAG === /* Ok */0) {
            var versions = resultVersions._0;
            Curry._1(setVersions, (function (param) {
                    return versions.content;
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: resultVersions._0
                      }
                    }));
          }
        }), [resultVersions]);
  React.useEffect((function () {
          if (typeof result === "number") {
            result === /* NotStarted */0;
          } else if (result.TAG === /* Ok */0) {
            var category = result._0;
            Curry._1(setCategory, (function (param) {
                    return category;
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: result._0
                      }
                    }));
          }
        }), [result]);
  return React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
              show: App_Types_Result$DvmAdminFrontendFr.isPending(result),
              children: React.createElement(Core.Grid, {
                    children: null,
                    container: true,
                    direction: "column",
                    spacing: 6
                  }, React.createElement(Core.Grid, {
                        children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                              onClick: (function (param) {
                                  RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rCategory);
                                }),
                              label: intl.formatMessage(Messages_Category$DvmAdminFrontendFr.backToCategoryList),
                              size: "small"
                            }),
                        item: true
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(Category_Edit_CategoryInfo$DvmAdminFrontendFr.make, {
                              category: category,
                              refresh: refresh
                            }),
                        item: true
                      }), React.createElement(Core.Grid, {
                        children: Belt_Option.mapWithDefault(category, null, (function (category) {
                                return React.createElement(Category_Edit_VersionsSelection$DvmAdminFrontendFr.make, {
                                            category: category,
                                            versions: category.versions,
                                            allVersions: versions,
                                            refresh: refresh,
                                            withClosedModels: withClosedModels,
                                            setWithClosedModels: setWithClosedModels
                                          });
                              })),
                        item: true
                      }))
            });
}

var CategoryInfo;

var VersionsSelection;

var Api;

var make = Category_Edit_Page;

export {
  CategoryInfo ,
  VersionsSelection ,
  Api ,
  make ,
}
/* react Not a pure module */
