// Generated by ReScript, PLEASE EDIT WITH CARE


var titleDetail = {
  id: "de2b852a-45fa-42c3-b4d8-6a6155d60e1a",
  defaultMessage: "Informations de la société"
};

var lastModification = {
  id: "b2a45619-70be-4aba-b8f9-4a38af20463d",
  defaultMessage: "Dernière modification le {date}"
};

var address = {
  id: "21ce0b5f-0cb7-4254-9797-015bd6ab91a8",
  defaultMessage: "Adresse"
};

var attachmentsHistory = {
  id: "7dd63a87-c7df-419b-98af-d03a920842bb",
  defaultMessage: "Historique de rattachements de la société"
};

var searchCompanyName = {
  id: "56bc088c-2abb-431d-9e6e-ac1db3993b65",
  defaultMessage: "Rechercher par nom de société "
};

var activity = {
  id: "9ce6414d-7254-4cdf-b40c-f509d61fd6a1",
  defaultMessage: "Activité"
};

var contractID = {
  id: "00a19ad6-7390-41bb-831d-945394d29bd8",
  defaultMessage: "Code Loueur"
};

var notFound = {
  id: "00d936e7-3496-4b09-95c5-4ee6c6036df1",
  defaultMessage: "Société introuvable"
};

export {
  titleDetail ,
  lastModification ,
  address ,
  attachmentsHistory ,
  searchCompanyName ,
  activity ,
  contractID ,
  notFound ,
}
/* No side effect */
