// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as App_Types_Json$DvmAdminFrontendFr from "./App_Types_Json.bs.js";

function isValid(date) {
  var match = date.toString();
  if (match === "Invalid Date") {
    return false;
  } else {
    return true;
  }
}

function toString(date) {
  return date.toISOString();
}

function fromString(dateString) {
  var date = new Date(dateString);
  if (isValid(date)) {
    return Caml_option.some(date);
  }
  
}

var Decco = App_Types_Json$DvmAdminFrontendFr.MakeStringableJsonType({
      toString: toString,
      fromString: fromString
    });

var Json;

export {
  Json ,
  isValid ,
  toString ,
  fromString ,
  Decco ,
}
/* Decco Not a pure module */
