// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as App_Modal$DvmAdminFrontendFr from "../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";

function Home_Messages_Modal_Confirm(Props) {
  var onClose = Props.onClose;
  var message = Props.message;
  var onOk = Props.onOk;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var alert = ReactAlert.useAlert();
  var match$2 = React.useState(function () {
        return false;
      });
  var setSaving = match$2[1];
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: message.title,
              minWidth: "500px",
              maxWidth: "600px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: match$2[0],
                    children: null
                  }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        gutterBottom: true,
                        paragraph: true,
                        children: message.message,
                        variant: "bodyBig"
                      }), message.noted ? null : React.createElement(Core.Box, {
                          children: null,
                          display: "flex"
                        }, React.createElement(Core.Box, {
                              flexGrow: 1
                            }), React.createElement(App_Button$DvmAdminFrontendFr.make, {
                              onClick: (function (param) {
                                  Curry._1(setSaving, (function (param) {
                                          return true;
                                        }));
                                  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("PUT", undefined, userManager, selectedRole, "/dashboard/messages/" + message.uuid + "/noted").then(function (result) {
                                        if (result.TAG === /* OkResult */0) {
                                          Curry._1(onOk, undefined);
                                        } else {
                                          Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                                                    message: {
                                                      TAG: /* RestError */2,
                                                      _0: result._0
                                                    }
                                                  }));
                                        }
                                        Curry._1(setSaving, (function (param) {
                                                return false;
                                              }));
                                      });
                                }),
                              label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.noted),
                              size: "medium"
                            })))
            });
}

var make = Home_Messages_Modal_Confirm;

export {
  make ,
}
/* react Not a pure module */
