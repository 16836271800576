// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function MakeStringableJsonType(StringableType) {
  var t_encode = function (stringableValue) {
    return Curry._1(StringableType.toString, stringableValue);
  };
  var t_decode = function (jsonStringToDecode) {
    var stringToDecode = Js_json.classify(jsonStringToDecode);
    if (typeof stringToDecode === "number") {
      return Decco.error(undefined, "Trying to decode a field that is not a string to type", jsonStringToDecode);
    }
    if (stringToDecode.TAG !== /* JSONString */0) {
      return Decco.error(undefined, "Trying to decode a field that is not a string to type", jsonStringToDecode);
    }
    var stringToDecode$1 = stringToDecode._0;
    var value = Curry._1(StringableType.fromString, stringToDecode$1);
    if (value !== undefined) {
      return {
              TAG: /* Ok */0,
              _0: Caml_option.valFromOption(value)
            };
    } else {
      return Decco.error(undefined, "Type '" + stringToDecode$1 + "' is not supported.", jsonStringToDecode);
    }
  };
  return {
          t_encode: t_encode,
          t_decode: t_decode
        };
}

function stringList_encode(v) {
  return Decco.listToJson(Decco.stringToJson, v);
}

function stringList_decode(v) {
  return Decco.listFromJson(Decco.stringFromJson, v);
}

export {
  MakeStringableJsonType ,
  stringList_encode ,
  stringList_decode ,
}
/* No side effect */
