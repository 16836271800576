// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Context_Router$DvmAdminFrontendFr from "../../../../context/router/Context_Router.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as Action_Referential_Form$DvmAdminFrontendFr from "./Action_Referential_Form.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Messages_Action_Referential$DvmAdminFrontendFr from "../../../../intl/messages/actionReferential/Messages_Action_Referential.bs.js";

function Action_Referential_Filter(Props) {
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var setParamsList = Props.setParamsList;
  var match = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match[1];
  var form = Action_Referential_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.getWithDefault(match[0].actionReferentialFilter, Action_Referential_Form$DvmAdminFrontendFr.initialState), (function (param, submissionCallbacks) {
          var countryCode = param.countryCode;
          Curry._1(setParamsList, (function (param) {
                  return {
                          hd: countryCode,
                          tl: /* [] */0
                        };
                }));
          Curry._2(fetchData, submissionCallbacks, {
                page: 0,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: fetchRequest.headers,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                              hd: countryCode,
                              tl: /* [] */0
                            }, (function (queryParam) {
                                return Belt_Option.map(queryParam, (function (x) {
                                              return x.en;
                                            }));
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  React.useEffect((function () {
          Curry._1(form.submit, undefined);
        }), []);
  var tmp = {
    label: Messages_Action_Referential$DvmAdminFrontendFr.countryCode,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.countryCode,
        onChange: Curry._1(form.updateCountryCode, (function (param, countryCode) {
                return {
                        countryCode: countryCode
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: {
        hd: "FR",
        tl: {
          hd: "ES",
          tl: /* [] */0
        }
      }
    },
    required: /* OnlyLabel */1,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.countryCodeResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.countryCodeResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(dispatch, {
                        TAG: /* StoreActionReferentialFilter */31,
                        _0: form.input
                      });
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var Style;

var Form;

var make = Action_Referential_Filter;

export {
  Style ,
  Form ,
  make ,
}
/* react Not a pure module */
