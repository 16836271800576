// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactAlert from "react-alert";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as App_Modal$DvmAdminFrontendFr from "../../../common/components/App_Modal.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";

function Home_Video_Modal(Props) {
  var videoName = Props.videoName;
  var onClose = Props.onClose;
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setVideoUrlResult = match$2[1];
  React.useEffect((function () {
          RestApi$DvmAdminFrontendFr.WithBlobResponse.call("GET", undefined, userManager, selectedRole, "/cloud/video/" + videoName + "/url").then(function (result) {
                if (result.TAG !== /* OkResult */0) {
                  return Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                                  message: {
                                    TAG: /* RestError */2,
                                    _0: result._0
                                  }
                                }));
                }
                result._0.text().then(function (result) {
                      Curry._1(setVideoUrlResult, (function (param) {
                              return {
                                      TAG: /* Ok */0,
                                      _0: result
                                    };
                            }));
                    });
              });
        }), []);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: videoName,
              maxWidth: "100vw",
              children: null,
              padding: false
            }, React.createElement("video", {
                  autoPlay: true,
                  controls: true,
                  height: String(window.innerHeight * 0.6 | 0)
                }, App_Types_Result$DvmAdminFrontendFr.mapWithDefault(match$2[0], null, (function (x) {
                        return React.createElement("source", {
                                    src: x
                                  });
                      }))), React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  color: "primary",
                  style: {
                    maxWidth: "50vw",
                    padding: "10px"
                  },
                  children: videoName,
                  variant: "bodyBig"
                }));
}

var make = Home_Video_Modal;

export {
  make ,
}
/* react Not a pure module */
