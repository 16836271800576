// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Css from "@emotion/css";
import ReactDraggable from "react-draggable";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "./icons/App_Icon.bs.js";
import * as App_Styles$DvmAdminFrontendFr from "../App_Styles.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../theme/Theme_Colors.bs.js";

function dialog(minWidth, maxWidth, modalHeight) {
  var tmp = {};
  if (modalHeight !== undefined) {
    tmp.height = modalHeight;
  }
  if (maxWidth !== undefined) {
    tmp.maxWidth = maxWidth;
  }
  if (minWidth !== undefined) {
    tmp.minWidth = minWidth;
  }
  return Css.css(tmp);
}

var dialogContent = Css.css({
      padding: "36px 24px 36px 24px"
    });

var dialogContentNoPadding = Css.css({
      overflow: "hidden",
      padding: "0px 0px 0px 0px"
    });

var toolbar = Css.css({
      cursor: "move",
      padding: "unset"
    });

var closeIcon = Css.css({
      color: "inherit",
      position: "absolute",
      right: "1rem"
    });

var Styles = {
  dialog: dialog,
  dialogContent: dialogContent,
  dialogContentNoPadding: dialogContentNoPadding,
  toolbar: toolbar,
  closeIcon: closeIcon
};

function App_Modal$PaperComponent(Props) {
  var props = Props.props;
  return React.createElement(ReactDraggable, {
              handle: "#draggable-dialog-title",
              cancel: "[class*=\"MuiDialogContent-root\"]",
              children: React.createElement(Core.Paper, {
                    children: props.children,
                    classes: props.classes,
                    className: props.className,
                    component: props.component,
                    elevation: props.elevation,
                    square: props.square,
                    variant: props.variant,
                    id: props.id,
                    style: props.style,
                    key: props.key,
                    ref: props.ref
                  })
            });
}

var PaperComponent = {
  make: App_Modal$PaperComponent
};

function App_Modal(Props) {
  var open = Props.open;
  var onClose = Props.onClose;
  var title = Props.title;
  var minWidth = Props.minWidth;
  var maxWidthOpt = Props.maxWidth;
  var children = Props.children;
  var paddingOpt = Props.padding;
  var headerColorOpt = Props.headerColor;
  var titleMaxWidthOpt = Props.titleMaxWidth;
  var modalHeight = Props.modalHeight;
  var maxWidth = maxWidthOpt !== undefined ? maxWidthOpt : "100vw";
  var padding = paddingOpt !== undefined ? paddingOpt : true;
  var headerColor = headerColorOpt !== undefined ? headerColorOpt : /* Black */0;
  var titleMaxWidth = titleMaxWidthOpt !== undefined ? titleMaxWidthOpt : "40vw";
  var paperComp = React.useMemo((function () {
          return function (props) {
            return React.createElement(App_Modal$PaperComponent, {
                        props: props
                      });
          };
        }), []);
  var tmp;
  switch (headerColor) {
    case /* Black */0 :
        tmp = "black";
        break;
    case /* Brown */1 :
        tmp = Theme_Colors$DvmAdminFrontendFr.primaryBrown;
        break;
    case /* Red */2 :
        tmp = Theme_Colors$DvmAdminFrontendFr.accentRed;
        break;
    
  }
  return React.createElement(Core.Dialog, {
              closeAfterTransition: true,
              children: null,
              classes: {
                paper: dialog(minWidth, maxWidth, modalHeight)
              },
              disableEscapeKeyDown: true,
              onClose: (function (param, reason) {
                  if (reason === "backdropClick") {
                    return Curry._1(onClose, undefined);
                  }
                  
                }),
              open: open,
              PaperComponent: paperComp,
              PaperProps: {
                square: true
              }
            }, React.createElement(Core.DialogTitle, {
                  children: React.createElement(Core.AppBar, {
                        children: React.createElement(Core.Toolbar, {
                              children: null,
                              variant: "dense"
                            }, React.createElement(Core.IconButton, {
                                  onClick: (function (param) {
                                      Curry._1(onClose, undefined);
                                    }),
                                  children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                        size: /* Large */3,
                                        type_: /* Cross */42
                                      }),
                                  className: closeIcon
                                }), React.createElement(Core.Grid, {
                                  children: React.createElement(Core.Grid, {
                                        children: React.createElement(Core.Typography, {
                                              children: title,
                                              classes: {
                                                h5: App_Styles$DvmAdminFrontendFr.bold
                                              },
                                              noWrap: true,
                                              variant: "h5",
                                              style: {
                                                maxWidth: titleMaxWidth,
                                                textOverflow: "ellipsis"
                                              }
                                            }),
                                        item: true
                                      }),
                                  container: true,
                                  justify: "center"
                                })),
                        position: "relative",
                        style: {
                          backgroundColor: tmp
                        }
                      }),
                  classes: {
                    root: toolbar
                  },
                  id: "draggable-dialog-title"
                }), React.createElement(Core.DialogContent, {
                  children: children,
                  className: padding ? dialogContent : dialogContentNoPadding
                }));
}

var make = App_Modal;

export {
  Styles ,
  PaperComponent ,
  make ,
}
/* dialogContent Not a pure module */
