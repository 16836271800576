// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../../common/App_Api.bs.js";
import * as Quota_Api$DvmAdminFrontendFr from "../Quota_Api.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../../../context/router/Context_Router.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as Messages_Quotas$DvmAdminFrontendFr from "../../../../intl/messages/quotas/Messages_Quotas.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as Quota_Admin_Form$DvmAdminFrontendFr from "./Quota_Admin_Form.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as Quota_Admin_Table$DvmAdminFrontendFr from "./Quota_Admin_Table.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_AutocompleteFormInput.bs.js";

function Quota_Admin_Filters(Props) {
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var alert = ReactAlert.useAlert();
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match$2[1];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDrAndDealers = match$3[1];
  var drAndDealers = match$3[0];
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var setContractOptions = match$4[1];
  var form = Quota_Admin_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.mapWithDefault(match$2[0].quotaAdminFilter, Quota_Admin_Form$DvmAdminFrontendFr.initialState, (function (filter) {
              return filter;
            })), (function (param, submissionCallbacks) {
          Curry._2(fetchData, submissionCallbacks, {
                page: fetchRequest.page,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: Quota_Admin_Table$DvmAdminFrontendFr.headersConfiguration,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                              hd: param.dr,
                              tl: {
                                hd: param.contractId,
                                tl: {
                                  hd: param.vehicleType,
                                  tl: {
                                    hd: param.volume,
                                    tl: {
                                      hd: param.startYear,
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.queryForDRAndDealers(alert, userManager, setDrAndDealers, false, selectedRole);
          Quota_Api$DvmAdminFrontendFr.queryForContractIdSelect(alert, userManager, setContractOptions, selectedRole);
          Curry._1(form.submit, undefined);
        }), []);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.mapWithDefault(drAndDealers, undefined, (function (x) {
                  var drs = Belt_List.map(x, (function (x) {
                          return x.parent;
                        }));
                  if (Belt_List.length(drs) === 1) {
                    return Curry._2(form.updateDr, (function (input, dr) {
                                  return {
                                          dr: dr,
                                          contractId: input.contractId,
                                          vehicleType: input.vehicleType,
                                          volume: input.volume,
                                          startYear: input.startYear
                                        };
                                }), drs);
                  }
                  
                }));
        }), [drAndDealers]);
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.territory,
    value: {
      TAG: /* Multiple */1,
      _0: {
        value: form.input.dr,
        onChange: Curry._1(form.updateDr, (function (input, dr) {
                return {
                        dr: dr,
                        contractId: input.contractId,
                        vehicleType: input.vehicleType,
                        volume: input.volume,
                        startYear: input.startYear
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(drAndDealers, /* [] */0), (function (item) {
              return item.parent;
            }))
    },
    smallChips: true
  };
  if (form.drResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.drResult);
  }
  var tmp$1 = {
    label: Messages_Quotas$DvmAdminFrontendFr.contractId,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.contractId,
        onChange: Curry._1(form.updateContractId, (function (input, contractId) {
                return {
                        dr: input.dr,
                        contractId: contractId,
                        vehicleType: input.vehicleType,
                        volume: input.volume,
                        startYear: input.startYear
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(match$4[0], (function (item) {
                          return item.paramOne;
                        })))))
    },
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.contractIdResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.contractIdResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(dispatch, {
                        TAG: /* StoreQuotaAdminFilter */24,
                        _0: form.input
                      });
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$1),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, {
                            label: Messages_Common$DvmAdminFrontendFr.year,
                            value: form.input.startYear,
                            onChange: Curry._1(form.updateStartYear, (function (input, startYear) {
                                    return {
                                            dr: input.dr,
                                            contractId: input.contractId,
                                            vehicleType: input.vehicleType,
                                            volume: input.volume,
                                            startYear: startYear
                                          };
                                  })),
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
                            variant: /* YEAR */0
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.ok),
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var Form;

var Api;

var Table;

var make = Quota_Admin_Filters;

export {
  Form ,
  Api ,
  Table ,
  make ,
}
/* react Not a pure module */
