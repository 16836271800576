// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.bs.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../App_Api.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../icons/App_Icon.bs.js";
import * as App_Spinner$DvmAdminFrontendFr from "../App_Spinner.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Messages_Error$DvmAdminFrontendFr from "../../../intl/messages/Messages_Error.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../types/App_Types_Result.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "./App_AutocompleteFormInput.bs.js";

var popper = Css.css({
      top: "28px !important"
    });

function App_CompanyFormInput(Props) {
  var existingCompany = Props.existingCompany;
  var onChange = Props.onChange;
  var value = Props.value;
  var validationResult = Props.validationResult;
  var companyName = Props.companyName;
  var setCompanyName = Props.setCompanyName;
  var companies = Props.companies;
  var setCompanies = Props.setCompanies;
  var className = Props.className;
  var companyType = Props.companyType;
  var label = Props.label;
  var disabled = Props.disabled;
  var onBlur = Props.onBlur;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var debounce = ReactDebounce.useDebounced(500, (function (filter) {
          App_Api$DvmAdminFrontendFr.queryForCompaniesSelect(userManager, setCompanies, filter, companyType, selectedRole);
        }));
  React.useEffect((function () {
          Belt_Option.mapWithDefault(existingCompany, undefined, (function (existingCompany) {
                  if (existingCompany.length > 0) {
                    return Curry._1(debounce, existingCompany.slice(0, 4));
                  }
                  
                }));
        }), [existingCompany]);
  var tmp;
  tmp = typeof companies === "number" || companies.TAG !== /* Ok */0 ? /* [] */0 : Belt_List.map(companies._0, (function (x) {
            return {
                    label: {
                      TAG: /* String */3,
                      _0: x.paramOne + Belt_Option.mapWithDefault(x.paramTwo, "", (function (x) {
                              if (x.length > 0) {
                                return " (" + x + ")";
                              } else {
                                return "";
                              }
                            }))
                    },
                    value: x.paramOne
                  };
          }));
  var tmp$1 = {
    label: Belt_Option.getWithDefault(label, companyType === "siret" ? Messages_Common$DvmAdminFrontendFr.siret : Messages_Common$DvmAdminFrontendFr.siren),
    value: {
      TAG: /* Single */0,
      _0: {
        value: value,
        onChange: Curry.__1(onChange)
      }
    },
    options: {
      TAG: /* Labeled */1,
      _0: Belt_List.map(Belt_MapString.toList(Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(Belt_List.concat(value.length > 0 ? Belt_Option.mapWithDefault(companyName, {
                                      hd: {
                                        label: {
                                          TAG: /* String */3,
                                          _0: value
                                        },
                                        value: value
                                      },
                                      tl: /* [] */0
                                    }, (function (companyName) {
                                        if (typeof companyName === "number") {
                                          return {
                                                  hd: {
                                                    label: {
                                                      TAG: /* String */3,
                                                      _0: value
                                                    },
                                                    value: value
                                                  },
                                                  tl: /* [] */0
                                                };
                                        }
                                        if (companyName.TAG !== /* Ok */0) {
                                          return /* [] */0;
                                        }
                                        var companyName$1 = companyName._0;
                                        return {
                                                hd: {
                                                  label: {
                                                    TAG: /* String */3,
                                                    _0: value + (" (" + (
                                                        companyName$1.length > 0 ? companyName$1 : intl.formatMessage(Messages_Common$DvmAdminFrontendFr.noLabel)
                                                      ) + ")")
                                                  },
                                                  value: value
                                                },
                                                tl: /* [] */0
                                              };
                                      })) : /* [] */0, tmp), (function (x) {
                              return [
                                      x.value,
                                      x
                                    ];
                            }))))), (function (x) {
              return x[1];
            }))
    },
    className: className,
    onSearch: (function (filter) {
        var companyTrim = filter.trim();
        if (companyTrim.length > 4) {
          Curry._1(onChange, companyTrim);
          return Belt_Option.mapWithDefault(setCompanyName, undefined, (function (setCompanyName) {
                        Curry._1(setCompanyName, (function (param) {
                                return /* NotStarted */0;
                              }));
                      }));
        } else if (companyTrim.length === 4) {
          return Curry._1(debounce, companyTrim);
        } else {
          return ;
        }
      }),
    loading: App_Types_Result$DvmAdminFrontendFr.isPending(companies) || App_Types_Result$DvmAdminFrontendFr.isPending(Belt_Option.getWithDefault(companyName, /* NotStarted */0)),
    matchFrom: "start",
    popupIcon: App_Types_Result$DvmAdminFrontendFr.isPending(companies) || App_Types_Result$DvmAdminFrontendFr.isPending(Belt_Option.getWithDefault(companyName, /* NotStarted */0)) ? React.createElement(App_Spinner$DvmAdminFrontendFr.make, {
            size: /* Fixed */{
              _0: "20px"
            }
          }) : React.createElement(App_Icon$DvmAdminFrontendFr.make, {
            size: /* Regular */2,
            type_: /* PadDown */11
          }),
    freeSolo: true
  };
  var tmp$2 = Belt_Option.map(validationResult, (function (result) {
          if (result.TAG === /* Validating */0) {
            return {
                    TAG: /* Ok */0,
                    _0: result._0
                  };
          }
          var message = result._0;
          if (message.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: message._0
                  };
          } else {
            return {
                    TAG: /* Error */1,
                    _0: message._0
                  };
          }
        }));
  if (tmp$2 !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(tmp$2);
  }
  if (onBlur !== undefined) {
    tmp$1.onBlur = Caml_option.valFromOption(onBlur);
  }
  if (disabled !== undefined) {
    tmp$1.disabled = Caml_option.valFromOption(disabled);
  }
  var tmp$3;
  if (typeof companies === "number") {
    tmp$3 = companies === /* NotStarted */0 ? (
        value.length === 0 ? intl.formatMessage(Messages_Common$DvmAdminFrontendFr.insertNumberOfCharacters, {
                number: 4
              }) : ""
      ) : intl.formatMessage(Messages_Common$DvmAdminFrontendFr.loading);
  } else if (companies.TAG === /* Ok */0) {
    var match$2 = Belt_List.length(companies._0);
    var match$3 = value.length;
    tmp$3 = match$2 !== 0 ? "" + String(match$2) + " " + (
        companyType === "siret" ? "SIRETs" : "SIRENs"
      ) + "" : (
        match$3 !== 0 ? "" : intl.formatMessage(Messages_Common$DvmAdminFrontendFr.nothingFound)
      );
  } else {
    tmp$3 = intl.formatMessage(Messages_Error$DvmAdminFrontendFr.error);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Tooltip, {
                  children: React.createElement("div", undefined, React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$1)),
                  classes: {
                    popper: popper
                  },
                  placement: "top",
                  title: tmp$3
                }));
}

var Api;

var make = App_CompanyFormInput;

export {
  Api ,
  popper ,
  make ,
}
/* popper Not a pure module */
