// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Css from "@emotion/css";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as App_Api$DvmAdminFrontendFr from "../../../../common/App_Api.bs.js";
import * as Quota_Api$DvmAdminFrontendFr from "../../search/Quota_Api.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../../../route/Route_Paths.bs.js";
import * as Utils_Input$DvmAdminFrontendFr from "../../../../common/utils/Utils_Input.bs.js";
import * as Quota_Delete$DvmAdminFrontendFr from "./Quota_Delete.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as Messages_Quotas$DvmAdminFrontendFr from "../../../../intl/messages/quotas/Messages_Quotas.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as Quota_Detail_Info_Api$DvmAdminFrontendFr from "./Quota_Detail_Info_Api.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as Quota_Detail_Info_Form$DvmAdminFrontendFr from "./Quota_Detail_Info_Form.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as App_RadioFormInput_VehicleType$DvmAdminFrontendFr from "../../../../common/components/input/App_RadioFormInput_VehicleType.bs.js";

var formStyle = Css.css({
      margin: "17px 0px 16px 0px",
      gridColumnGap: "32px",
      gridRowGap: "32px"
    });

var inputStyle = Css.css({
      width: "348px"
    });

var labelStyle = Css.css({
      color: Theme_Colors$DvmAdminFrontendFr.black,
      fontWeight: "bold"
    });

function Quota_Detail_Info(Props) {
  var setSaveResult = Props.setSaveResult;
  var getResult = Props.getResult;
  var initialInput = Props.initialInput;
  var volumeOfAttachments = Props.volumeOfAttachments;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDrOptions = match[1];
  var match$1 = React.useState(function () {
        return /* [] */0;
      });
  var setContractOptions = match$1[1];
  var contractOptions = match$1[0];
  var match$2 = React.useState(function () {
        return null;
      });
  var setDeleteModal = match$2[1];
  var match$3 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$4 = match$3[0];
  var userManager = match$4.userManager;
  var selectedRole = match$4.selectedRole;
  var form = Quota_Detail_Info_Form$DvmAdminFrontendFr.DetailInfoForm.useForm(initialInput, {
        volumeOfAttachments: App_Types_Result$DvmAdminFrontendFr.getWithDefault(volumeOfAttachments, 0)
      }, (function (detailInfo, submissionCallbacks) {
          Quota_Detail_Info_Api$DvmAdminFrontendFr.saveQuotaDetailInfo(alert, userManager, setSaveResult, submissionCallbacks, App_Types_Result$DvmAdminFrontendFr.isSuccess(getResult), selectedRole, {
                dr: detailInfo.dr,
                drName: detailInfo.drName,
                contractId: detailInfo.contractId,
                contractLabel: detailInfo.contractLabel,
                vehicleType: detailInfo.vehicleType,
                volume: detailInfo.volume,
                startDate: detailInfo.startDate,
                endDate: Caml_option.some(detailInfo.endDate)
              });
        }));
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.queryForDrSelect(alert, userManager, setDrOptions, selectedRole);
          Quota_Api$DvmAdminFrontendFr.queryForContractIdSelect(alert, userManager, setContractOptions, selectedRole);
        }), []);
  React.useEffect((function () {
          if (!App_Types_Result$DvmAdminFrontendFr.isSuccess(getResult)) {
            Curry._2(form.updateContractLabel, (function (input, param) {
                    return {
                            dr: input.dr,
                            drName: input.drName,
                            contractId: input.contractId,
                            contractLabel: Belt_Option.getWithDefault(Belt_List.get(Belt_List.keepMap(contractOptions, (function (x) {
                                            if (x.paramOne === form.input.contractId) {
                                              return Caml_option.some(x.paramTwo);
                                            }
                                            
                                          })), 0), undefined),
                            vehicleType: input.vehicleType,
                            volume: input.volume,
                            startDate: input.startDate,
                            endDate: input.endDate
                          };
                  }), undefined);
          }
          
        }), [form.input.contractId]);
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.territory,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.dr,
        onChange: Curry._1(form.updateDr, (function (input, dr) {
                return {
                        dr: dr,
                        drName: input.drName,
                        contractId: input.contractId,
                        contractLabel: input.contractLabel,
                        vehicleType: input.vehicleType,
                        volume: input.volume,
                        startDate: input.startDate,
                        endDate: input.endDate
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(match[0], /* [] */0)
    },
    disabled: App_Types_Result$DvmAdminFrontendFr.isSuccess(getResult),
    required: /* HtmlRequired */0,
    className: inputStyle
  };
  if (form.drResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.drResult);
  }
  var tmp$1 = {
    disabled: true,
    className: inputStyle,
    label: Messages_Quotas$DvmAdminFrontendFr.drLabel
  };
  if (form.input.drName !== undefined) {
    tmp$1.value = Caml_option.valFromOption(form.input.drName);
  }
  var tmp$2 = {
    label: Messages_Quotas$DvmAdminFrontendFr.contractId,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.contractId,
        onChange: Curry._1(form.updateContractId, (function (input, contractId) {
                return {
                        dr: input.dr,
                        drName: input.drName,
                        contractId: contractId,
                        contractLabel: input.contractLabel,
                        vehicleType: input.vehicleType,
                        volume: input.volume,
                        startDate: input.startDate,
                        endDate: input.endDate
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(contractOptions, (function (item) {
                          return item.paramOne;
                        })))))
    },
    disabled: App_Types_Result$DvmAdminFrontendFr.isSuccess(getResult),
    required: /* HtmlRequired */0,
    className: inputStyle
  };
  if (form.contractIdResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.contractIdResult);
  }
  var tmp$3 = {
    disabled: true,
    className: inputStyle,
    label: Messages_Quotas$DvmAdminFrontendFr.contractLabel
  };
  if (form.input.contractLabel !== undefined) {
    tmp$3.value = Caml_option.valFromOption(form.input.contractLabel);
  }
  var tmp$4 = {
    label: Messages_Quotas_Detail$DvmAdminFrontendFr.vehicleType,
    value: form.input.vehicleType,
    onChange: Curry._1(form.updateVehicleType, (function (input, vehicleType) {
            return {
                    dr: input.dr,
                    drName: input.drName,
                    contractId: input.contractId,
                    contractLabel: input.contractLabel,
                    vehicleType: vehicleType,
                    volume: input.volume,
                    startDate: input.startDate,
                    endDate: input.endDate
                  };
          })),
    required: /* OnlyLabel */1,
    classNameTitle: labelStyle,
    disabled: App_Types_Result$DvmAdminFrontendFr.isSuccess(getResult)
  };
  if (form.vehicleTypeResult !== undefined) {
    tmp$4.validationResult = Caml_option.valFromOption(form.vehicleTypeResult);
  }
  var tmp$5 = {
    onChange: Curry._1(form.updateVolume, (function (input, volume) {
            return {
                    dr: input.dr,
                    drName: input.drName,
                    contractId: input.contractId,
                    contractLabel: input.contractLabel,
                    vehicleType: input.vehicleType,
                    volume: volume,
                    startDate: input.startDate,
                    endDate: input.endDate
                  };
          })),
    value: form.input.volume,
    className: inputStyle,
    label: Messages_Quotas_Detail$DvmAdminFrontendFr.volume,
    required: /* HtmlRequired */0,
    inputType: "number"
  };
  if (form.volumeResult !== undefined) {
    tmp$5.validationResult = Caml_option.valFromOption(form.volumeResult);
  }
  var tmp$6 = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    dr: input.dr,
                    drName: input.drName,
                    contractId: input.contractId,
                    contractLabel: input.contractLabel,
                    vehicleType: input.vehicleType,
                    volume: input.volume,
                    startDate: startDate,
                    endDate: input.endDate
                  };
          })),
    disabled: App_Types_Result$DvmAdminFrontendFr.isSuccess(getResult),
    className: inputStyle,
    required: /* OnlyLabel */1
  };
  if (form.startDateResult !== undefined) {
    tmp$6.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  var tmp$7 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    dr: input.dr,
                    drName: input.drName,
                    contractId: input.contractId,
                    contractLabel: input.contractLabel,
                    vehicleType: input.vehicleType,
                    volume: input.volume,
                    startDate: input.startDate,
                    endDate: endDate
                  };
          })),
    className: inputStyle,
    required: /* OnlyLabel */1
  };
  if (form.endDateResult !== undefined) {
    tmp$7.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  return React.createElement(React.Fragment, undefined, React.createElement("form", {
                  onSubmit: (function ($$event) {
                      $$event.preventDefault();
                      Curry._1(form.submit, undefined);
                    })
                }, React.createElement(Core.Grid, {
                      children: null,
                      className: formStyle,
                      container: true
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$1),
                          item: true
                        })), React.createElement(Core.Grid, {
                      children: null,
                      className: formStyle,
                      container: true
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$2),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$3),
                          item: true
                        })), React.createElement(Core.Grid, {
                      children: React.createElement(Core.Grid, {
                            children: React.createElement(App_RadioFormInput_VehicleType$DvmAdminFrontendFr.make, tmp$4),
                            item: true
                          }),
                      className: formStyle,
                      container: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(Core.Grid, {
                            children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$5),
                            item: true
                          }),
                      className: formStyle,
                      container: true
                    }), React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                      className: labelStyle,
                      children: Utils_Input$DvmAdminFrontendFr.requiredLabel(intl, Messages_Quotas_Detail$DvmAdminFrontendFr.validatePeriod),
                      variant: "subtitleBig"
                    }), React.createElement(Core.Grid, {
                      children: null,
                      className: formStyle,
                      container: true
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$6),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$7),
                          item: true
                        })), React.createElement(Core.Grid, {
                      children: null,
                      className: formStyle,
                      container: true,
                      justifyContent: "space-around"
                    }, App_Types_Result$DvmAdminFrontendFr.isSuccess(getResult) ? React.createElement(App_Button$DvmAdminFrontendFr.make, {
                            onClick: (function (param) {
                                Curry._1(setDeleteModal, (function (param) {
                                        return Belt_Option.mapWithDefault(initialInput.startDate, null, (function (startDate) {
                                                      return React.createElement(Quota_Delete$DvmAdminFrontendFr.make, {
                                                                  quota: {
                                                                    contractId: initialInput.contractId,
                                                                    dr: initialInput.dr,
                                                                    vehicleType: initialInput.vehicleType,
                                                                    startDate: startDate
                                                                  },
                                                                  onClose: (function (param) {
                                                                      Curry._1(setDeleteModal, (function (param) {
                                                                              return null;
                                                                            }));
                                                                    }),
                                                                  onOk: (function (param) {
                                                                      Curry._1(setDeleteModal, (function (param) {
                                                                              return null;
                                                                            }));
                                                                      RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rView + Route_Paths$DvmAdminFrontendFr.rQuota);
                                                                    })
                                                                });
                                                    }));
                                      }));
                              }),
                            label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.$$delete),
                            color: "delete",
                            size: "medium",
                            key: "delete"
                          }) : null, React.createElement(App_Button$DvmAdminFrontendFr.make, {
                          label: intl.formatMessage(App_Types_Result$DvmAdminFrontendFr.isSuccess(getResult) ? Messages_Button$DvmAdminFrontendFr.edit : Messages_Button$DvmAdminFrontendFr.create),
                          size: "medium",
                          disabled: App_Types_Result$DvmAdminFrontendFr.isSuccess(getResult) && !Curry._1(form.dirty, undefined),
                          type_: "submit",
                          key: "confirm"
                        }))), match$2[0]);
}

var Api;

var Form;

var make = Quota_Detail_Info;

export {
  Api ,
  Form ,
  formStyle ,
  inputStyle ,
  labelStyle ,
  make ,
}
/* formStyle Not a pure module */
