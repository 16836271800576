// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Utils_Form$DvmAdminFrontendFr from "../../utils/Utils_Form.bs.js";
import * as Utils_Input$DvmAdminFrontendFr from "../../utils/Utils_Input.bs.js";
import * as App_Types_Text$DvmAdminFrontendFr from "../../types/App_Types_Text.bs.js";

function App_SelectFormInput(Props) {
  var label = Props.label;
  var options = Props.options;
  var value = Props.value;
  var validationResult = Props.validationResult;
  var fullWidth = Props.fullWidth;
  var id = Props.id;
  var className = Props.className;
  var disabled = Props.disabled;
  var required = Props.required;
  var classes = Props.classes;
  var onBlur = Props.onBlur;
  var intl = ReactIntl.useIntl();
  var tmp = {
    children: null,
    size: "small",
    variant: "filled"
  };
  if (className !== undefined) {
    tmp.className = className;
  }
  var tmp$1 = Belt_Option.map(validationResult, Belt_Result.isError);
  if (tmp$1 !== undefined) {
    tmp.error = tmp$1;
  }
  if (fullWidth !== undefined) {
    tmp.fullWidth = fullWidth;
  }
  var tmp$2;
  tmp$2 = value.TAG === /* Single */0 ? false : true;
  var tmp$3;
  tmp$3 = value.TAG === /* Single */0 ? value._0.value : Belt_List.toArray(value._0.value);
  var tmp$4 = {
    children: Belt_List.toArray(Belt_List.mapWithIndex(options, (function (index, param) {
                return React.createElement(Core.MenuItem, {
                            children: App_Types_Text$DvmAdminFrontendFr.toString(intl, param.label),
                            value: param.value,
                            key: "select-option-" + String(index) + ""
                          });
              }))),
    id: id,
    multiple: tmp$2,
    onChange: (function ($$event, param) {
        if (value.TAG === /* Single */0) {
          return Curry._1(value._0.onChange, $$event.target.value);
        } else {
          return Curry._1(value._0.onChange, Belt_List.fromArray($$event.target.value));
        }
      }),
    value: tmp$3
  };
  if (onBlur !== undefined) {
    tmp$4.onBlur = Caml_option.valFromOption(onBlur);
  }
  if (disabled !== undefined) {
    tmp$4.disabled = disabled;
  }
  var tmp$5 = Belt_Option.map(required, Utils_Input$DvmAdminFrontendFr.isHtmlRequired);
  if (tmp$5 !== undefined) {
    tmp$4.required = tmp$5;
  }
  if (classes !== undefined) {
    tmp$4.classes = Caml_option.valFromOption(classes);
  }
  var tmp$6 = Belt_Option.map(label, (function (label) {
          if (required !== undefined && required) {
            return Utils_Input$DvmAdminFrontendFr.requiredLabel(intl, label);
          } else {
            return intl.formatMessage(label);
          }
        }));
  if (tmp$6 !== undefined) {
    tmp$4.label = Caml_option.valFromOption(tmp$6);
  }
  return React.createElement(Core.FormControl, tmp, React.createElement(Core.InputLabel, {
                  children: Belt_Option.mapWithDefault(label, null, (function (label) {
                          if (required !== undefined && required) {
                            return Utils_Input$DvmAdminFrontendFr.requiredLabel(intl, label);
                          } else {
                            return intl.formatMessage(label);
                          }
                        })),
                  htmlFor: id
                }), React.createElement(Core.Select, tmp$4), Belt_Option.mapWithDefault(Utils_Form$DvmAdminFrontendFr.getErrorMessage(intl, validationResult), null, (function (errorMessage) {
                    return React.createElement(Core.FormHelperText, {
                                children: errorMessage
                              });
                  })));
}

var make = App_SelectFormInput;

export {
  make ,
}
/* react Not a pure module */
