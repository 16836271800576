// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Button.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../common/components/App_OverlaySpinner.bs.js";
import * as Margin_Grid_Duplicate_Api$DvmAdminFrontendFr from "./Margin_Grid_Duplicate_Api.bs.js";
import * as Margin_Grid_Duplicate_Form$DvmAdminFrontendFr from "./Margin_Grid_Duplicate_Form.bs.js";
import * as Margin_Grid_Duplicate_Types$DvmAdminFrontendFr from "./Margin_Grid_Duplicate_Types.bs.js";
import * as Margin_Grid_Duplicate_Filters$DvmAdminFrontendFr from "./Margin_Grid_Duplicate_Filters.bs.js";
import * as Messages_MarginGrid_Duplicate$DvmAdminFrontendFr from "../../../../intl/messages/marginGrid/Messages_MarginGrid_Duplicate.bs.js";
import * as Margin_Grid_Duplicate_TranferList$DvmAdminFrontendFr from "./Margin_Grid_Duplicate_TranferList.bs.js";
import * as Margin_Grid_Duplicate_Duplication_Definition$DvmAdminFrontendFr from "./Margin_Grid_Duplicate_Duplication_Definition.bs.js";

function Margin_Grid_Duplicate_Modal(Props) {
  var onClose = Props.onClose;
  var budgetOrID = Props.budgetOrID;
  var categories = Props.categories;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$2[1];
  var result = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var gridAllRowsResult = match$3[0];
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var availablePeriods = match$4[0];
  var match$5 = React.useState(function () {
        return "";
      });
  var selectedPeriod = match$5[0];
  var match$6 = React.useState(function () {
        return /* [] */0;
      });
  var form = Margin_Grid_Duplicate_Form$DvmAdminFrontendFr.DuplicateForm.useForm(Margin_Grid_Duplicate_Form$DvmAdminFrontendFr.initialState, (function (param, submissionCallbacks) {
          var endDate = param.endDate;
          var startDate = param.startDate;
          var discounts = param.discounts;
          var gridID = param.gridID;
          var isOk = Belt_List.map(discounts, (function (x) {
                  var row = x.value.split(Margin_Grid_Duplicate_Types$DvmAdminFrontendFr.delimiter);
                  if (Belt_Option.isSome(Belt_Array.get(row, 0))) {
                    return Belt_Option.isSome(Belt_Array.get(row, 1));
                  } else {
                    return false;
                  }
                }));
          if (Belt_List.reduce(isOk, true, (function (a, b) {
                    return a ? b : false;
                  }))) {
            return Belt_Option.mapWithDefault(Belt_List.getBy(availablePeriods, (function (x) {
                              return x.searchString === selectedPeriod;
                            })), undefined, (function (period) {
                          Margin_Grid_Duplicate_Api$DvmAdminFrontendFr.duplicateGrid(userManager, setResult, submissionCallbacks, selectedRole, {
                                duplicatedGridID: gridID,
                                startDate: Caml_option.some(startDate),
                                endDate: Caml_option.some(endDate),
                                duplications: Belt_List.map(discounts, (function (x) {
                                        var row = x.value.split(Margin_Grid_Duplicate_Types$DvmAdminFrontendFr.delimiter);
                                        return {
                                                avsMarginGridID: budgetOrID,
                                                categoryID: Belt_Option.getWithDefault(Belt_Array.get(row, 0), ""),
                                                discount: Belt_Option.mapWithDefault(Belt_Array.get(row, 1), 0.0, (function (x) {
                                                        return Belt_Option.getWithDefault(Belt_Float.fromString(x), 0.0);
                                                      })),
                                                startDate: period.startDate,
                                                endDate: period.endDate
                                              };
                                      }))
                              });
                        }));
          } else {
            return Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                            message: {
                              TAG: /* Message */0,
                              _0: Messages_MarginGrid_Duplicate$DvmAdminFrontendFr.duplicateFail
                            }
                          }));
          }
        }));
  React.useEffect((function () {
          if (typeof result === "number") {
            result === /* NotStarted */0;
          } else if (result.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* Message */0,
                        _0: Messages_MarginGrid_Duplicate$DvmAdminFrontendFr.duplicateSuccess
                      }
                    }));
            Curry._1(onClose, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: result._0
                      }
                    }));
          }
        }), [result]);
  var onFilterDateChange = function (param) {
    Curry._2(form.updateDiscounts, (function (input, discounts) {
            return {
                    newOrExisting: input.newOrExisting,
                    gridID: input.gridID,
                    discounts: discounts,
                    startDate: input.startDate,
                    endDate: input.endDate
                  };
          }), /* [] */0);
  };
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_MarginGrid_Duplicate$DvmAdminFrontendFr.dupicateModalTitle, {
                    duplicate: budgetOrID
                  }),
              minWidth: "600px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(result) || App_Types_Result$DvmAdminFrontendFr.isPending(gridAllRowsResult),
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          style: {
                            maxWidth: "800px"
                          }
                        }, React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              item: true,
                              spacing: 5
                            }, React.createElement(Margin_Grid_Duplicate_Duplication_Definition$DvmAdminFrontendFr.make, {
                                  categories: categories,
                                  form: form
                                }), React.createElement(Core.Grid, {
                                  children: React.createElement(Core.Grid, {
                                        children: null,
                                        container: true,
                                        direction: "column",
                                        item: true,
                                        spacing: 2
                                      }, React.createElement(Core.Grid, {
                                            children: React.createElement(Margin_Grid_Duplicate_Filters$DvmAdminFrontendFr.make, {
                                                  budgetOrID: budgetOrID,
                                                  setLabeledCategories: match$6[1],
                                                  onFilterDateChange: onFilterDateChange,
                                                  gridAllRowsResult: gridAllRowsResult,
                                                  availablePeriods: availablePeriods,
                                                  setAvailablePeriods: match$4[1],
                                                  setSelectedPeriod: match$5[1],
                                                  setGridAllRowsResult: match$3[1]
                                                }),
                                            item: true
                                          }), React.createElement(Margin_Grid_Duplicate_TranferList$DvmAdminFrontendFr.make, {
                                            form: form,
                                            labeledCategories: match$6[0]
                                          })),
                                  container: true,
                                  item: true
                                })), React.createElement(Core.Grid, {
                              children: React.createElement("form", {
                                    onSubmit: (function ($$event) {
                                        $$event.preventDefault();
                                        Curry._1(form.submit, undefined);
                                      })
                                  }, React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                        label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                        size: "medium",
                                        type_: "submit"
                                      })),
                              container: true,
                              item: true,
                              justifyContent: "flex-end"
                            }))
                  })
            });
}

var Types;

var Api;

var Form;

var make = Margin_Grid_Duplicate_Modal;

export {
  Types ,
  Api ,
  Form ,
  make ,
}
/* react Not a pure module */
