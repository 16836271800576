// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../../intl/Intl_Utils.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../common/App_Typography.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../../../../common/components/App_LabeledText.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Result.bs.js";
import * as Messages_Flash_Search$DvmAdminFrontendFr from "../../../../../../intl/messages/flash/Messages_Flash_Search.bs.js";
import * as Messages_Orders_Derogate$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_Derogate.bs.js";
import * as Order_Detail_Bonus_Derogate_Create_Api$DvmAdminFrontendFr from "./Order_Detail_Bonus_Derogate_Create_Api.bs.js";

function Order_Detail_Bonus_Derogate_Action_Info(Props) {
  var action = Props.action;
  var actionType = Props.actionType;
  var actionId = Props.actionId;
  var orderType = Props.orderType;
  var actionFetch = Props.actionFetch;
  var setActionFetch = Props.setActionFetch;
  var displayAmounts = Props.displayAmounts;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        
      });
  var setActionInfo = match$2[1];
  var actionInfo = match$2[0];
  React.useEffect((function () {
          Curry._1(setActionInfo, (function (param) {
                  
                }));
        }), [actionType]);
  React.useEffect((function () {
          Belt_Option.mapWithDefault(action, App_Types_Result$DvmAdminFrontendFr.mapWithDefault(actionFetch, undefined, (function (x) {
                      Belt_Option.mapWithDefault(x, undefined, (function (action) {
                              Curry._1(setActionInfo, (function (param) {
                                      var tmp;
                                      switch (actionType) {
                                        case "budget" :
                                            tmp = "budget";
                                            break;
                                        case "perf" :
                                            tmp = "perf";
                                            break;
                                        case "repr" :
                                            tmp = "repr";
                                            break;
                                        case "stoc" :
                                            tmp = "stoc";
                                            break;
                                        default:
                                          tmp = "budget";
                                      }
                                      return {
                                              actionType: tmp,
                                              actionId: action.actionID,
                                              actionIdLabel: action.actionLabel,
                                              actionBudget: action.budgetTypes,
                                              bonus: undefined,
                                              amountPercent: Belt_Option.getWithDefault(action.calcMethod, "") === "PERCENTAGE" ? action.bonusAmount : undefined,
                                              amountEuro: Belt_Option.getWithDefault(action.calcMethod, "") === "AMOUNT" ? action.bonusAmount : undefined,
                                              bonusValue: action.bonusValue,
                                              calcMethod: action.calcMethod
                                            };
                                    }));
                            }));
                    })), (function (action) {
                  Curry._1(setActionInfo, (function (param) {
                          return {
                                  actionType: action.actionType,
                                  actionId: action.actionId,
                                  actionIdLabel: action.actionIdLabel,
                                  actionBudget: action.actionBudget,
                                  bonus: action.bonus,
                                  amountPercent: action.amountPercent,
                                  amountEuro: action.amountEuro,
                                  bonusValue: action.bonusValue,
                                  calcMethod: action.calcMethod
                                };
                        }));
                }));
        }), [
        action,
        actionFetch
      ]);
  React.useEffect((function () {
          if (Belt_Option.isNone(action)) {
            Order_Detail_Bonus_Derogate_Create_Api$DvmAdminFrontendFr.fetchOneAction(userManager, setActionFetch, selectedRole, actionId, orderType);
          }
          
        }), [actionId]);
  var actionInfo$1 = function (param) {
    return Belt_Option.getWithDefault(actionInfo, {
                actionType: "budget",
                actionId: "-",
                actionIdLabel: undefined,
                actionBudget: undefined,
                bonus: undefined,
                amountPercent: undefined,
                amountEuro: undefined,
                bonusValue: undefined,
                calcMethod: undefined
              });
  };
  var tmp = {
    children: null,
    container: true,
    item: true
  };
  var tmp$1 = orderType !== 0 || actionType !== "budget" ? undefined : ({
        backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight3,
        color: Theme_Colors$DvmAdminFrontendFr.grayDark1
      });
  if (tmp$1 !== undefined) {
    tmp.style = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2;
  if (displayAmounts) {
    var tmp$3;
    if (orderType !== 0) {
      var match$3 = Belt_Option.getWithDefault(actionInfo$1(undefined).calcMethod, "");
      switch (match$3) {
        case "AMOUNT" :
            tmp$3 = Belt_Option.mapWithDefault(actionInfo$1(undefined).bonusValue, "-", (function (eta) {
                    return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, eta);
                  }));
            break;
        case "PERCENTAGE" :
            tmp$3 = Belt_Option.mapWithDefault(actionInfo$1(undefined).bonusValue, "-", (function (param) {
                    return Intl_Utils$DvmAdminFrontendFr.formatPercent(intl, param);
                  }));
            break;
        default:
          tmp$3 = "-";
      }
    } else {
      var match$4 = actionInfo$1(undefined).amountPercent;
      var match$5 = actionInfo$1(undefined).amountEuro;
      tmp$3 = match$4 !== undefined ? (
          match$5 !== undefined ? "-" : Intl_Utils$DvmAdminFrontendFr.formatPercent(intl, match$4)
        ) : (
          match$5 !== undefined ? Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, match$5) : "-"
        );
    }
    tmp$2 = React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
              children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                    label: Messages_Orders_Derogate$DvmAdminFrontendFr.deroAmountAction,
                    children: tmp$3
                  }),
              item: true
            }), React.createElement(Core.Grid, {
              children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                    label: Messages_Orders_Derogate$DvmAdminFrontendFr.derogateBonusAmountPaid,
                    children: Belt_Option.mapWithDefault(actionInfo$1(undefined).bonus, "-", (function (x) {
                            return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, x);
                          }))
                  }),
              item: true
            }));
  } else {
    tmp$2 = null;
  }
  return React.createElement(Core.Grid, tmp, React.createElement(Core.Grid, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        gutterBottom: true,
                        style: {
                          fontWeight: "600"
                        },
                        children: intl.formatMessage(Messages_Orders_Derogate$DvmAdminFrontendFr.derogateActionInfo),
                        variant: "h5"
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  justifyContent: "space-evenly",
                  spacing: 1
                }, orderType !== 0 ? null : React.createElement(Core.Grid, {
                        children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Orders_Derogate$DvmAdminFrontendFr.budgetDero,
                              children: Belt_Option.getWithDefault(actionInfo$1(undefined).actionBudget, "-")
                            }),
                        item: true
                      }), (
                  orderType !== 0 ? false : actionType === "budget"
                ) ? null : React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                  label: Messages_Flash_Search$DvmAdminFrontendFr.flashMarketingActionID,
                                  children: actionInfo$1(undefined).actionId
                                }),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                  label: Messages_Flash_Search$DvmAdminFrontendFr.bonusLabel,
                                  children: Belt_Option.getWithDefault(actionInfo$1(undefined).actionIdLabel, "-")
                                }),
                            item: true
                          })), tmp$2));
}

var Form;

var Api;

var Types;

var make = Order_Detail_Bonus_Derogate_Action_Info;

export {
  Form ,
  Api ,
  Types ,
  make ,
}
/* react Not a pure module */
