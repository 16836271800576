// Generated by ReScript, PLEASE EDIT WITH CARE


var Rest;

var invalidBackEndResponse = {
  id: "371b93ab-5481-44f4-8042-b671579da286",
  defaultMessage: "Réponse non valide du serveur principal"
};

var errorResponseFromBackEnd = {
  id: "3109dcd0-b292-42f3-941c-ef85f37e405b",
  defaultMessage: "Le serveur principal répond avec une erreur"
};

var backEndUnexpectedError = {
  id: "527d3884-2e69-4ef8-a8dc-5b6e1e9554b6",
  defaultMessage: "Erreur inattendue lors de la tentative d’appel du serveur principal"
};

var noAccessToken = {
  id: "5a138337-dd32-4f60-9485-129d3da765b3",
  defaultMessage: "Token d’accès introuvable"
};

var unauthenticatedAccess = {
  id: "c15ac990-09af-42a6-a069-8eeb22d3c01d",
  defaultMessage: "Tentative d’accès non authentifiée"
};

var noAuthenticatedUser = {
  id: "660d3900-4901-4067-a08d-f29f86815cd3",
  defaultMessage: "Utilisateur non authentifié"
};

var userAuthorisationProblem = {
  id: "a5fd47cf-7145-42ca-b7e5-de106df82c70",
  defaultMessage: "Authentification non valide"
};

var pageNotFound = {
  id: "18c27a5a-a2a6-4186-8d43-e82d9ab97563",
  defaultMessage: "Page introuvable"
};

var error = {
  id: "758a036d-e839-4050-b5db-026f60ff01b6",
  defaultMessage: "Erreur"
};

var newRowFail = {
  id: "9ff6a009-181a-46a6-81de-e47e68753e69",
  defaultMessage: "Création impossible. Chevauchement avec d’autres périodes."
};

var errorOccured = {
  id: "7e933c1f-236d-47d6-a169-ad36c705d66f",
  defaultMessage: "Une erreur est survenue"
};

var dealerNotFound = {
  id: "486bcb8f-c2b7-4d32-ae9a-2b79529c2afd",
  defaultMessage: "L’utilisateur n’a pas de compte de rattachement. Il faut sélectionner un autre rôle ou contacter l’administrateur"
};

var loginNotPossibleTryLater = {
  id: "d8637fcd-a3da-4ab3-9162-9ee7fc8a9701",
  defaultMessage: "La connexion n’est pas disponible pour le moment en raison des calculs en cours d’exécution. Veuillez Réessayer dans 90 minutes"
};

export {
  Rest ,
  invalidBackEndResponse ,
  errorResponseFromBackEnd ,
  backEndUnexpectedError ,
  noAccessToken ,
  unauthenticatedAccess ,
  noAuthenticatedUser ,
  userAuthorisationProblem ,
  pageNotFound ,
  error ,
  newRowFail ,
  errorOccured ,
  dealerNotFound ,
  loginNotPossibleTryLater ,
}
/* No side effect */
