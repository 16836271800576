// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../../route/Route_Paths.bs.js";

function routeToModelVersionPage(param) {
  RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rView + Route_Paths$DvmAdminFrontendFr.rModelVersionGrid);
}

export {
  routeToModelVersionPage ,
}
/* RescriptReactRouter Not a pure module */
