// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReDate_utils from "@mobily/rescript-date/src/ReDate_utils.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../utils/Utils_Form.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../types/App_Types_Date.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../intl/messages/Messages_Error_Inputs.bs.js";

function validateRequiredDateCompare(smallerDate, largerDate, parentStartDate, parentEndDate, thisDate, startDateAsParent, endDateAsParent) {
  var match = thisDate === "smaller" ? [
      smallerDate,
      startDateAsParent,
      largerDate,
      endDateAsParent
    ] : [
      largerDate,
      endDateAsParent,
      smallerDate,
      startDateAsParent
    ];
  var thatAsParent = match[3];
  var that = match[2];
  if (parentStartDate === undefined) {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.datesHaveIssue
          };
  }
  if (parentEndDate === undefined) {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.datesHaveIssue
          };
  }
  var end = Caml_option.valFromOption(parentEndDate);
  var start = Caml_option.valFromOption(parentStartDate);
  var match$1 = App_Types_Date$DvmAdminFrontendFr.isValid(start);
  var match$2 = App_Types_Date$DvmAdminFrontendFr.isValid(end);
  if (match$1 && match$2) {
    if (match[1]) {
      return {
              TAG: /* Ok */0,
              _0: undefined
            };
    } else {
      return Belt_Result.flatMap(Utils_Form$DvmAdminFrontendFr.validateRequiredDate(match[0], undefined), (function ($$this) {
                    if (ReDate_utils.makeStartOfDayDate(start).valueOf() <= ReDate_utils.makeStartOfDayDate($$this).valueOf() && ReDate_utils.makeStartOfDayDate(end).valueOf() >= ReDate_utils.makeStartOfDayDate($$this).valueOf()) {
                      if (thatAsParent) {
                        return {
                                TAG: /* Ok */0,
                                _0: Caml_option.some($$this)
                              };
                      } else {
                        return Belt_Option.mapWithDefault(that, {
                                    TAG: /* Ok */0,
                                    _0: Caml_option.some($$this)
                                  }, (function (that) {
                                      if (thisDate === "smaller") {
                                        if (ReDate.isAfter(that, $$this)) {
                                          return {
                                                  TAG: /* Ok */0,
                                                  _0: Caml_option.some($$this)
                                                };
                                        } else {
                                          return {
                                                  TAG: /* Error */1,
                                                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateTooLarge
                                                };
                                        }
                                      } else if (ReDate.isAfter($$this, that)) {
                                        return {
                                                TAG: /* Ok */0,
                                                _0: Caml_option.some($$this)
                                              };
                                      } else {
                                        return {
                                                TAG: /* Error */1,
                                                _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateTooSmall
                                              };
                                      }
                                    }));
                      }
                    } else {
                      return {
                              TAG: /* Error */1,
                              _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateOutOfPeriod
                            };
                    }
                  }));
    }
  } else {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.datesHaveIssue
          };
  }
}

export {
  validateRequiredDateCompare ,
}
/* Utils_Form-DvmAdminFrontendFr Not a pure module */
