// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../theme/Theme_Colors.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../../common/components/App_LabeledText.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as Order_Detail_Styles$DvmAdminFrontendFr from "../Order_Detail_Styles.bs.js";
import * as App_Clickable_Element$DvmAdminFrontendFr from "../../../../common/components/button/App_Clickable_Element.bs.js";
import * as App_Types_VN_VO_Status$DvmAdminFrontendFr from "../../../../common/types/App_Types_VN_VO_Status.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Order_Detail_Old_Vehicle_Delete$DvmAdminFrontendFr from "./Order_Detail_Old_Vehicle_Delete.bs.js";
import * as Order_Detail_Old_Vehicle_Edits_Modal$DvmAdminFrontendFr from "./edits/Order_Detail_Old_Vehicle_Edits_Modal.bs.js";
import * as Order_Detail_Old_Vehicle_Edits_Utils$DvmAdminFrontendFr from "./edits/Order_Detail_Old_Vehicle_Edits_Utils.bs.js";

function textToOption(text) {
  return Belt_Option.mapWithDefault(text, undefined, (function (x) {
                if (x.length === 0) {
                  return ;
                } else {
                  return x;
                }
              }));
}

function getLabeledText(redOnMissing, label, text, order) {
  var textOption = textToOption(text);
  return Belt_Option.mapWithDefault(order.orderType, null, (function (orderType) {
                if (orderType !== 0) {
                  var tmp = {
                    label: label,
                    children: Belt_Option.getWithDefault(textOption, "-")
                  };
                  var tmp$1 = Belt_Option.isNone(textOption) && redOnMissing ? ({
                        TAG: /* Typography */0,
                        _0: "error"
                      }) : undefined;
                  if (tmp$1 !== undefined) {
                    tmp.colorLabel = Caml_option.valFromOption(tmp$1);
                  }
                  return React.createElement(Core.Grid, {
                              children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, tmp),
                              className: Order_Detail_Styles$DvmAdminFrontendFr.oldVehicleDetailItem,
                              item: true
                            });
                }
                var tmp$2 = {
                  label: label,
                  children: Belt_Option.getWithDefault(textOption, "-")
                };
                var tmp$3 = Belt_Option.isNone(textOption) && redOnMissing ? ({
                      TAG: /* Typography */0,
                      _0: "error"
                    }) : undefined;
                if (tmp$3 !== undefined) {
                  tmp$2.colorLabel = Caml_option.valFromOption(tmp$3);
                }
                return React.createElement(Core.Grid, {
                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, tmp$2),
                            item: true
                          });
              }));
}

function Order_Detail_Old_Vehicle(Props) {
  var newestUsedCarValues = Props.newestUsedCarValues;
  var orderId = Props.orderId;
  var reloadPage = Props.reloadPage;
  var order = Props.order;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return null;
      });
  var setModal = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShow = match$1[1];
  var show = match$1[0];
  var missingFieldsTotal = function (param) {
    return Belt_List.length(Belt_List.keep({
                    hd: Belt_Option.isNone(textToOption(newestUsedCarValues.brand)),
                    tl: {
                      hd: Belt_Option.isNone(textToOption(newestUsedCarValues.model)),
                      tl: {
                        hd: Belt_Option.isNone(newestUsedCarValues.firstReleaseDate),
                        tl: {
                          hd: Belt_Option.isNone(newestUsedCarValues.registrationDate),
                          tl: {
                            hd: Belt_Option.isNone(textToOption(newestUsedCarValues.vin)),
                            tl: {
                              hd: Belt_Option.isNone(textToOption(newestUsedCarValues.registrationNumber)),
                              tl: {
                                hd: Belt_Option.isNone(textToOption(newestUsedCarValues.policeNumber)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }, (function (x) {
                      return x;
                    })));
  };
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column"
                }, React.createElement(App_Clickable_Element$DvmAdminFrontendFr.make, {
                      onClick: (function (param) {
                          Curry._1(setShow, (function (show) {
                                  return !show;
                                }));
                        }),
                      children: React.createElement(Core.Grid, {
                            alignItems: "center",
                            children: null,
                            container: true,
                            item: true,
                            spacing: 2,
                            wrap: "nowrap",
                            key: "0"
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                      color: "primary",
                                      style: {
                                        fontWeight: "600"
                                      },
                                      children: intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.oldVehicleInfo),
                                      variant: "h3"
                                    }),
                                item: true
                              }), Belt_Option.mapWithDefault(order.detail.usedCarStatus, null, (function (usedCarStatus) {
                                  if (usedCarStatus === /* INCOMPLETE */1) {
                                    return React.createElement(Core.Grid, {
                                                children: React.createElement(Core.Tooltip, {
                                                      arrow: true,
                                                      children: React.createElement("div", undefined, React.createElement(Core.Badge, {
                                                                anchorOrigin: {
                                                                  horizontal: "right",
                                                                  vertical: "top"
                                                                },
                                                                badgeContent: missingFieldsTotal(undefined),
                                                                children: App_Types_VN_VO_Status$DvmAdminFrontendFr.toChip(usedCarStatus, intl),
                                                                color: "error"
                                                              })),
                                                      classes: {
                                                        tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
                                                      },
                                                      placement: "right",
                                                      title: intl.formatMessage(missingFieldsTotal(undefined) > 1 ? Messages_Orders_Detail$DvmAdminFrontendFr.missingFieldsVOPlural : Messages_Orders_Detail$DvmAdminFrontendFr.missingFieldVOSingular)
                                                    }),
                                                item: true
                                              });
                                  } else {
                                    return null;
                                  }
                                })), React.createElement(Core.Grid, {
                                children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                      size: /* Large */3,
                                      type_: show ? /* PadUp */10 : /* PadDown */11,
                                      color: Theme_Colors$DvmAdminFrontendFr.primaryBrown
                                    }),
                                item: true
                              }), Order_Detail_Old_Vehicle_Edits_Utils$DvmAdminFrontendFr.voActionsStatesEditable(order) ? React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                                      children: React.createElement(Core.IconButton, {
                                            onClick: (function ($$event) {
                                                $$event.stopPropagation();
                                                Curry._1(setModal, (function (param) {
                                                        return React.createElement(Order_Detail_Old_Vehicle_Edits_Modal$DvmAdminFrontendFr.make, {
                                                                    onClose: (function (param) {
                                                                        Curry._1(setModal, (function (param) {
                                                                                return null;
                                                                              }));
                                                                      }),
                                                                    orderId: orderId,
                                                                    order: order,
                                                                    reloadPage: reloadPage,
                                                                    newestUsedCarValues: newestUsedCarValues
                                                                  });
                                                      }));
                                              }),
                                            children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                  size: /* Large */3,
                                                  type_: /* Edit */60
                                                })
                                          }),
                                      item: true
                                    }), Belt_Option.getWithDefault(order.detail.delivered, false) ? React.createElement(Core.Grid, {
                                        children: React.createElement(Core.IconButton, {
                                              onClick: (function ($$event) {
                                                  $$event.stopPropagation();
                                                  Curry._1(setModal, (function (param) {
                                                          return React.createElement(Order_Detail_Old_Vehicle_Delete$DvmAdminFrontendFr.make, {
                                                                      onClose: (function (param) {
                                                                          Curry._1(setModal, (function (param) {
                                                                                  return null;
                                                                                }));
                                                                        }),
                                                                      orderId: orderId,
                                                                      reloadPage: reloadPage
                                                                    });
                                                        }));
                                                }),
                                              children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                    size: /* Large */3,
                                                    type_: /* Trash */38
                                                  })
                                            }),
                                        item: true
                                      }) : null) : null)
                    }), React.createElement(Core.Collapse, {
                      children: React.createElement(Core.Grid, {
                            children: Belt_Option.mapWithDefault(order.orderType, null, (function (orderType) {
                                    if (orderType !== 0) {
                                      return React.createElement(Core.Grid, {
                                                  children: null,
                                                  container: true,
                                                  item: true,
                                                  spacing: 1
                                                }, getLabeledText(true, Messages_Common$DvmAdminFrontendFr.brandVO, newestUsedCarValues.brand, order), getLabeledText(true, Messages_Common$DvmAdminFrontendFr.modelVO, newestUsedCarValues.model, order), getLabeledText(true, Messages_Common$DvmAdminFrontendFr.registrationVO, newestUsedCarValues.registrationNumber, order), getLabeledText(true, Messages_Common$DvmAdminFrontendFr.vinVO, newestUsedCarValues.vin, order), getLabeledText(true, Messages_Orders_Detail$DvmAdminFrontendFr.reprDatePremiere, Belt_Option.mapWithDefault(newestUsedCarValues.firstReleaseDate, undefined, (function (x) {
                                                            return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, x);
                                                          })), order), getLabeledText(true, Messages_Orders_Detail$DvmAdminFrontendFr.reprDateImmat, Belt_Option.mapWithDefault(newestUsedCarValues.registrationDate, undefined, (function (x) {
                                                            return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, x);
                                                          })), order), getLabeledText(false, Messages_Orders_Detail$DvmAdminFrontendFr.valueVO, Belt_Option.mapWithDefault(newestUsedCarValues.value, undefined, (function (x) {
                                                            return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, x);
                                                          })), order), getLabeledText(false, Messages_Orders_Detail$DvmAdminFrontendFr.estimatedValueVO, Belt_Option.mapWithDefault(newestUsedCarValues.estimatedValue, undefined, (function (x) {
                                                            return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, x);
                                                          })), order), getLabeledText(false, Messages_Orders_Detail$DvmAdminFrontendFr.endOfLifeVO, Belt_Option.mapWithDefault(newestUsedCarValues.endOfLife, undefined, (function (endOfLife) {
                                                            return intl.formatMessage(endOfLife ? Messages_Common$DvmAdminFrontendFr.yes : Messages_Common$DvmAdminFrontendFr.no);
                                                          })), order), getLabeledText(true, Messages_Orders_Detail$DvmAdminFrontendFr.reprPoliceNumber, newestUsedCarValues.policeNumber, order));
                                    } else {
                                      return React.createElement(Core.Grid, {
                                                  children: null,
                                                  container: true,
                                                  item: true,
                                                  justifyContent: "space-between",
                                                  spacing: 1
                                                }, getLabeledText(true, Messages_Common$DvmAdminFrontendFr.brandVO, newestUsedCarValues.brand, order), getLabeledText(true, Messages_Common$DvmAdminFrontendFr.modelVO, newestUsedCarValues.model, order), getLabeledText(true, Messages_Common$DvmAdminFrontendFr.registrationVO, newestUsedCarValues.registrationNumber, order), getLabeledText(true, Messages_Common$DvmAdminFrontendFr.vinVO, newestUsedCarValues.vin, order), getLabeledText(true, Messages_Orders_Detail$DvmAdminFrontendFr.reprPoliceNumber, newestUsedCarValues.policeNumber, order), getLabeledText(true, Messages_Orders_Detail$DvmAdminFrontendFr.reprDatePremiere, Belt_Option.mapWithDefault(newestUsedCarValues.firstReleaseDate, undefined, (function (x) {
                                                            return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, x);
                                                          })), order), getLabeledText(true, Messages_Orders_Detail$DvmAdminFrontendFr.reprDateImmat, Belt_Option.mapWithDefault(newestUsedCarValues.registrationDate, undefined, (function (x) {
                                                            return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, x);
                                                          })), order));
                                    }
                                  })),
                            className: Order_Detail_Styles$DvmAdminFrontendFr.vehicleBox,
                            container: true,
                            item: true
                          }),
                      in: show
                    })), match[0]);
}

var Types;

var StatusTypes;

var DealerEditUtils;

var make = Order_Detail_Old_Vehicle;

export {
  Types ,
  StatusTypes ,
  DealerEditUtils ,
  textToOption ,
  getLabeledText ,
  make ,
}
/* react Not a pure module */
