// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../../route/Route_Paths.bs.js";

function routeToEditMVGrid(param) {
  RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rModelVersionGridEdit);
}

function routeToMVGrid(param) {
  RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rModelVersionGrid);
}

export {
  routeToEditMVGrid ,
  routeToMVGrid ,
}
/* RescriptReactRouter Not a pure module */
