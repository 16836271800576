// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../../api/RestApi.bs.js";
import * as Flash_Edit_Versions_Types$DvmAdminFrontendFr from "../Flash_Edit_Versions_Types.bs.js";

function fetchModelsTable(submissionCallbacks, userManager, setResult, queryParams, flashId, selectedRole, isPerf, withClosedModels) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Edit_Versions_Types$DvmAdminFrontendFr.IncludedModelVersions.searchResult_decode, undefined, userManager, selectedRole, "/flash/map/" + (
          isPerf ? "perf/" : ""
        ) + "" + flashId + "" + queryParams + "&withClosedModels=" + (
          withClosedModels ? "true" : "false"
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchModelsSearch */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function getFlashVersionsForDropdown(userManager, setVersionsListResult, flashId, actionType, selectedRole, withClosedModels) {
  Curry._1(setVersionsListResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Edit_Versions_Types$DvmAdminFrontendFr.IncludedModelVersions.searchResult_decode, undefined, userManager, selectedRole, (
          actionType === 1 ? "/flash/map/perf/" + flashId + "?limit=150000&offset=0" : "/flash/map/" + flashId + "?limit=150000&offset=0"
        ) + ("&withClosedModels=" + (
            withClosedModels ? "true" : "false"
          ) + "")).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var versions = result._0;
          return Curry._1(setVersionsListResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: versions.content
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setVersionsListResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function deleteAllFleetVersions(userManager, setDeleteResult, flashId, actionType, selectedRole) {
  Curry._1(setDeleteResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", undefined, userManager, selectedRole, actionType === /* PERF */1 ? "/flash/mapAll/perf/" + flashId + "" : "/flash/mapAll/" + flashId + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setDeleteResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setDeleteResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

var Types;

export {
  Form ,
  Types ,
  fetchModelsTable ,
  getFlashVersionsForDropdown ,
  deleteAllFleetVersions ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
