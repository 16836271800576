// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/core/styles";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Visibility_Profiles$DvmAdminFrontendFr from "../../../intl/messages/visibilityProfiles/Messages_Visibility_Profiles.bs.js";

function focusedListItem(theme) {
  return Css.css({
              backgroundColor: theme.palette.primary.light
            });
}

function listHeader(theme) {
  return Css.css({
              backgroundColor: theme.palette.primary.dark
            });
}

function Visibility_Profiles_List(Props) {
  var ipn = Props.ipn;
  var dealers = Props.dealers;
  var visibilityProfiles = Props.visibilityProfiles;
  var isPending = Props.isPending;
  var openModal = Props.openModal;
  var theme = Styles.useTheme();
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        
      });
  var setExpandedItemIPN = match[1];
  var expandedItemIPN = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setListItemRect = match$1[1];
  var listItemRect = match$1[0];
  var filteredByIPN = Belt_List.length(ipn) > 0 ? Belt_List.keep(visibilityProfiles, (function (x) {
            return Belt_List.some(ipn, (function (ipn) {
                          return ipn === x.userID;
                        }));
          })) : visibilityProfiles;
  var visibilityProfiles$1 = Belt_List.length(dealers) > 0 ? Belt_List.keep(filteredByIPN, (function (x) {
            return Belt_List.reduce(Belt_List.map(dealers, (function (dealer) {
                              return Belt_List.some(Belt_List.map(x.dealerCodes, (function (x) {
                                                return Belt_Option.getWithDefault(x.dealerID, "");
                                              })), (function (dealerCode) {
                                            return dealerCode === dealer;
                                          }));
                            })), false, (function (a, b) {
                          if (a) {
                            return true;
                          } else {
                            return b;
                          }
                        }));
          })) : filteredByIPN;
  var measuredListItemRef = React.useCallback((function (element) {
          Belt_Option.forEach((element == null) ? undefined : Caml_option.some(element), (function (element) {
                  Curry._1(setListItemRect, (function (param) {
                          return element.getBoundingClientRect();
                        }));
                }));
        }), []);
  var isItemExpanded = function (expandedItemIPN, ipn) {
    if (expandedItemIPN !== undefined) {
      return Caml_option.valFromOption(expandedItemIPN) === ipn;
    } else {
      return false;
    }
  };
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: null,
                  container: true
                }, React.createElement(Core.Grid, {
                      children: null,
                      item: true,
                      xs: Grid$Mui.Xs[6]
                    }, React.createElement(Core.List, {
                          children: React.createElement(Core.ListItem, {
                                children: React.createElement(Core.ListItemText, {
                                      inset: true,
                                      primary: React.createElement(Core.Typography, {
                                            children: intl.formatMessage(Messages_Visibility_Profiles$DvmAdminFrontendFr.ipn),
                                            style: {
                                              color: Theme_Colors$DvmAdminFrontendFr.white,
                                              fontWeight: "600"
                                            }
                                          })
                                    }),
                                className: listHeader(theme)
                              })
                        }), React.createElement(Core.List, {
                          children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                show: isPending,
                                children: Belt_List.toArray(Belt_List.map(visibilityProfiles$1, (function (profile) {
                                            var tmp = {
                                              children: null,
                                              divider: true,
                                              key: "ipn-" + profile.userID + "",
                                              ref: measuredListItemRef
                                            };
                                            var tmp$1 = isItemExpanded(expandedItemIPN, profile.userID) ? focusedListItem(theme) : undefined;
                                            if (tmp$1 !== undefined) {
                                              tmp.className = tmp$1;
                                            }
                                            var partial_arg = profile.userID;
                                            return React.createElement(Core.ListItem, tmp, React.createElement(Core.ListItemIcon, {
                                                            children: null
                                                          }, React.createElement(Core.IconButton, {
                                                                onClick: (function (param) {
                                                                    Curry._1(openModal, profile);
                                                                  }),
                                                                children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                      size: /* Large */3,
                                                                      type_: /* Edit */60
                                                                    })
                                                              }), null), React.createElement(Core.ListItemText, {
                                                            primary: React.createElement(React.Fragment, undefined, React.createElement(Core.Typography, {
                                                                      children: profile.userID,
                                                                      variant: "body2",
                                                                      style: {
                                                                        fontWeight: "600"
                                                                      }
                                                                    })),
                                                            secondary: React.createElement(Core.Typography, {
                                                                  children: intl.formatMessage(Messages_Visibility_Profiles$DvmAdminFrontendFr.dealersCount, {
                                                                        count: Belt_List.length(profile.dealerCodes)
                                                                      }),
                                                                  color: "textSecondary",
                                                                  variant: "body2"
                                                                })
                                                          }), React.createElement(Core.ListItemSecondaryAction, {
                                                            children: React.createElement(Core.IconButton, {
                                                                  onClick: (function (param) {
                                                                      return Curry._1(setExpandedItemIPN, (function (expandedItemIPN) {
                                                                                    if (isItemExpanded(expandedItemIPN, partial_arg)) {
                                                                                      return ;
                                                                                    } else {
                                                                                      return partial_arg;
                                                                                    }
                                                                                  }));
                                                                    }),
                                                                  children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                        size: /* Large */3,
                                                                        type_: isItemExpanded(expandedItemIPN, profile.userID) ? /* PadLeft */12 : /* PadRight */13
                                                                      })
                                                                })
                                                          }));
                                          })))
                              }),
                          dense: true
                        })), React.createElement(Core.Grid, {
                      children: null,
                      item: true,
                      xs: Grid$Mui.Xs[6]
                    }, React.createElement(Core.List, {
                          children: React.createElement(Core.ListItem, {
                                children: React.createElement(Core.ListItemText, {
                                      primary: React.createElement(Core.Typography, {
                                            children: intl.formatMessage(Messages_Visibility_Profiles$DvmAdminFrontendFr.listHeaderDealers),
                                            style: {
                                              color: Theme_Colors$DvmAdminFrontendFr.white,
                                              fontWeight: "600"
                                            }
                                          })
                                    }),
                                className: listHeader(theme)
                              })
                        }), Belt_Option.mapWithDefault(expandedItemIPN, null, (function (expandedItemIPN) {
                            return Belt_Option.mapWithDefault(Belt_List.getBy(visibilityProfiles$1, (function (x) {
                                              return x.userID === expandedItemIPN;
                                            })), null, (function (param) {
                                          return React.createElement(Core.Collapse, {
                                                      children: React.createElement(Core.List, {
                                                            children: Belt_List.toArray(Belt_List.map(param.dealerCodes, (function (row) {
                                                                        var tmp = {
                                                                          children: React.createElement(Core.ListItemText, {
                                                                                primary: React.createElement(Core.Typography, {
                                                                                      children: "" + Belt_Option.getWithDefault(row.dealerID, "") + " - " + Belt_Option.getWithDefault(row.dealerName, "") + "",
                                                                                      variant: "body2"
                                                                                    })
                                                                              }),
                                                                          divider: true
                                                                        };
                                                                        var tmp$1 = Belt_Option.map(listItemRect, (function (param) {
                                                                                return {
                                                                                        height: "" + String(param.height) + "px"
                                                                                      };
                                                                              }));
                                                                        if (tmp$1 !== undefined) {
                                                                          tmp.style = Caml_option.valFromOption(tmp$1);
                                                                        }
                                                                        return React.createElement(Core.ListItem, tmp);
                                                                      })))
                                                          }),
                                                      in: true,
                                                      timeout: "auto"
                                                    });
                                        }));
                          })))));
}

var Types;

var make = Visibility_Profiles_List;

export {
  Types ,
  focusedListItem ,
  listHeader ,
  make ,
}
/* react Not a pure module */
