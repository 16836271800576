// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../common/App_Api.bs.js";
import * as App_Modal$DvmAdminFrontendFr from "../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../common/components/App_Checkbox.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../common/components/App_LabeledText.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_PCDAgreement$DvmAdminFrontendFr from "../../../intl/messages/pcdAgreement/Messages_PCDAgreement.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as PCDAgreement_Modal_Api$DvmAdminFrontendFr from "./PCDAgreement_Modal_Api.bs.js";
import * as PCDAgreement_Update_Form$DvmAdminFrontendFr from "./PCDAgreement_Update_Form.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_AutocompleteFormInput.bs.js";

function strNoneToDash(str) {
  if (str === "NONE") {
    return "-";
  } else {
    return str;
  }
}

function PCDAgreement_Update_Modal(Props) {
  var onClose = Props.onClose;
  var row = Props.row;
  var onOk = Props.onOk;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var alert = ReactAlert.useAlert();
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match$2[1];
  var saveResult = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setContractIDs = match$3[1];
  var contractIDs = match$3[0];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setMargins = match$4[1];
  var match$5 = React.useState(function () {
        return /* [] */0;
      });
  var setAcquisitionOptions = match$5[1];
  var match$6 = React.useState(function () {
        return /* [] */0;
      });
  var setContractIdsSorted = match$6[1];
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* String */3,
                        _0: saveResult._0
                      }
                    }));
            Curry._1(onOk, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.queryForAcquisitions(alert, userManager, setAcquisitionOptions, selectedRole);
          PCDAgreement_Modal_Api$DvmAdminFrontendFr.fetchContractIds(userManager, setContractIDs, selectedRole);
          PCDAgreement_Modal_Api$DvmAdminFrontendFr.getMarginsAVS(userManager, setMargins, selectedRole);
        }), []);
  var headerArray = function (param) {
    return [
            {
              label: Messages_Common$DvmAdminFrontendFr.dealerID,
              val: row.dealerID
            },
            {
              label: Messages_Common$DvmAdminFrontendFr.siren,
              val: strNoneToDash(Belt_Option.getWithDefault(row.siren, "-"))
            },
            {
              label: Messages_PCDAgreement$DvmAdminFrontendFr.modelID,
              val: strNoneToDash(Belt_Option.getWithDefault(row.modelID, "-"))
            },
            {
              label: Messages_PCDAgreement$DvmAdminFrontendFr.categoryIDShort,
              val: strNoneToDash(Belt_Option.getWithDefault(row.categoryID, "-"))
            }
          ];
  };
  var form = PCDAgreement_Update_Form$DvmAdminFrontendFr.Form.useForm({
        volume: Belt_Option.mapWithDefault(row.volume, "", (function (prim) {
                return String(prim);
              })),
        acquisitionType: Belt_Option.getWithDefault(row.acquisitionType, ""),
        yearBudget: Belt_Option.mapWithDefault(row.yearBudget, undefined, (function (x) {
                return Caml_option.some(new Date(x, 0.0));
              })),
        contractID: Belt_Option.mapWithDefault(row.contractID, "", (function (x) {
                if (x === "NONE") {
                  return "";
                } else {
                  return x;
                }
              })),
        avsMarginGridId: Belt_Option.getWithDefault(row.avsMarginGridId, ""),
        unlimitedVolume: Belt_Option.mapWithDefault(row.volume, true, (function (x) {
                return x < 0;
              }))
      }, (function (param, submissionCallbacks) {
          var contractID = param.contractID;
          PCDAgreement_Modal_Api$DvmAdminFrontendFr.deleteThenCreateRow(userManager, setSaveResult, {
                dealerID: row.dealerID,
                siren: row.siren,
                siret: row.siret,
                modelID: row.modelID,
                categoryID: row.categoryID,
                acquisitionType: param.acquisitionType,
                contractID: contractID.length > 0 ? contractID : "NONE",
                avsMarginGridId: param.avsMarginGridId,
                companyDiscountPercentage: row.companyDiscountPercentage,
                volume: param.volume,
                responseNumber: row.responseNumber,
                yearBudget: param.yearBudget,
                processingDate: row.processingDate,
                isReplaced: row.isReplaced,
                avsModelVersion: row.avsModelVersion
              }, row, intl, submissionCallbacks, selectedRole);
        }));
  var updateContractIDs = function (param) {
    Belt_Option.mapWithDefault(form.input.yearBudget, undefined, (function (yearBudget) {
            if (!App_Types_Date$DvmAdminFrontendFr.isValid(yearBudget)) {
              return ;
            }
            var contracts = App_Types_Result$DvmAdminFrontendFr.mapWithDefault(contractIDs, /* [] */0, (function (x) {
                    return Belt_List.sort(Belt_List.map(Belt_List.keep(x, (function (x) {
                                          return Belt_Option.isSome(Belt_List.getBy(x.dates, (function (x) {
                                                            var startYear = ReDate.getYear(x.startDate);
                                                            var endDate = Belt_Option.mapWithDefault(x.endDate, 3000.0, ReDate.getYear);
                                                            if (startYear <= ReDate.getYear(yearBudget)) {
                                                              return ReDate.getYear(yearBudget) <= endDate;
                                                            } else {
                                                              return false;
                                                            }
                                                          })));
                                        })), (function (x) {
                                      return x.contractID;
                                    })), Caml.string_compare);
                  }));
            Curry._1(setContractIdsSorted, (function (param) {
                    return contracts;
                  }));
            App_Types_Result$DvmAdminFrontendFr.mapWithDefault(contractIDs, undefined, (function (param) {
                    if (Belt_Option.isNone(Belt_List.getBy(contracts, (function (x) {
                                  return x === form.input.contractID;
                                })))) {
                      return Curry._2(form.updateContractID, (function (input, contractID) {
                                    return {
                                            volume: input.volume,
                                            acquisitionType: input.acquisitionType,
                                            yearBudget: input.yearBudget,
                                            contractID: contractID,
                                            avsMarginGridId: input.avsMarginGridId,
                                            unlimitedVolume: input.unlimitedVolume
                                          };
                                  }), "");
                    }
                    
                  }));
          }));
  };
  React.useEffect((function () {
          updateContractIDs(undefined);
        }), [
        form.input.yearBudget,
        contractIDs
      ]);
  var tmp = {
    label: Messages_PCDAgreement$DvmAdminFrontendFr.acquisitionType,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.acquisitionType,
        onChange: Curry._1(form.updateAcquisitionType, (function (input, acquisitionType) {
                return {
                        volume: input.volume,
                        acquisitionType: acquisitionType,
                        yearBudget: input.yearBudget,
                        contractID: input.contractID,
                        avsMarginGridId: input.avsMarginGridId,
                        unlimitedVolume: input.unlimitedVolume
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: match$5[0]
    },
    required: /* OnlyLabel */1,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
  };
  if (form.acquisitionTypeResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.acquisitionTypeResult);
  }
  var tmp$1 = {
    label: Messages_Common$DvmAdminFrontendFr.year,
    value: form.input.yearBudget,
    onChange: Curry._1(form.updateYearBudget, (function (input, yearBudget) {
            return {
                    volume: input.volume,
                    acquisitionType: input.acquisitionType,
                    yearBudget: yearBudget,
                    contractID: input.contractID,
                    avsMarginGridId: input.avsMarginGridId,
                    unlimitedVolume: input.unlimitedVolume
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    variant: /* YEAR */0,
    required: /* OnlyLabel */1
  };
  if (form.yearBudgetResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.yearBudgetResult);
  }
  var tmp$2 = {
    label: Messages_PCDAgreement$DvmAdminFrontendFr.avsMarginGridId,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.avsMarginGridId,
        onChange: Curry._1(form.updateAvsMarginGridId, (function (input, avsMarginGridId) {
                return {
                        volume: input.volume,
                        acquisitionType: input.acquisitionType,
                        yearBudget: input.yearBudget,
                        contractID: input.contractID,
                        avsMarginGridId: avsMarginGridId,
                        unlimitedVolume: input.unlimitedVolume
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(match$4[0], /* [] */0, (function (x) {
              return Belt_List.map(x, (function (x) {
                            return x.data;
                          }));
            }))
    },
    required: /* OnlyLabel */1,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
  };
  if (form.avsMarginGridIdResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.avsMarginGridIdResult);
  }
  var tmp$3 = {
    onChange: Curry._1(form.updateVolume, (function (input, volume) {
            return {
                    volume: volume,
                    acquisitionType: input.acquisitionType,
                    yearBudget: input.yearBudget,
                    contractID: input.contractID,
                    avsMarginGridId: input.avsMarginGridId,
                    unlimitedVolume: input.unlimitedVolume
                  };
          })),
    value: form.input.volume,
    disabled: form.input.unlimitedVolume,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    label: Messages_PCDAgreement$DvmAdminFrontendFr.volume,
    required: /* OnlyLabel */1
  };
  if (form.volumeResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.volumeResult);
  }
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_PCDAgreement$DvmAdminFrontendFr.updateTitle),
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult),
                    children: null
                  }, React.createElement(Core.Grid, {
                        children: Belt_Array.map(headerArray(undefined), (function (element) {
                                return React.createElement(Core.Grid, {
                                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                  label: element.label,
                                                  children: element.val
                                                }),
                                            item: true
                                          });
                              })),
                        container: true,
                        direction: "row",
                        justifyContent: "center",
                        spacing: 2
                      }), React.createElement("form", {
                        onSubmit: (function ($$event) {
                            $$event.preventDefault();
                            Curry._1(form.submit, undefined);
                          })
                      }, React.createElement(Core.Grid, {
                            alignItems: "center",
                            children: null,
                            container: true,
                            direction: "column",
                            spacing: 4
                          }, React.createElement(Core.Grid, {
                                children: null,
                                container: true,
                                item: true,
                                sm: Grid$Mui.Sm[8],
                                spacing: 4
                              }, React.createElement(Core.Grid, {
                                    children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp),
                                    item: true,
                                    sm: Grid$Mui.Sm[4]
                                  }), React.createElement(Core.Grid, {
                                    children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                          label: Messages_PCDAgreement$DvmAdminFrontendFr.contractID,
                                          value: {
                                            TAG: /* Single */0,
                                            _0: {
                                              value: form.input.contractID,
                                              onChange: Curry._1(form.updateContractID, (function (input, contractID) {
                                                      return {
                                                              volume: input.volume,
                                                              acquisitionType: input.acquisitionType,
                                                              yearBudget: input.yearBudget,
                                                              contractID: contractID,
                                                              avsMarginGridId: input.avsMarginGridId,
                                                              unlimitedVolume: input.unlimitedVolume
                                                            };
                                                    }))
                                            }
                                          },
                                          options: {
                                            TAG: /* Unlabeled */0,
                                            _0: match$6[0]
                                          },
                                          className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                                        }),
                                    item: true,
                                    sm: Grid$Mui.Sm[4]
                                  }), React.createElement(Core.Grid, {
                                    children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$1),
                                    item: true,
                                    sm: Grid$Mui.Sm[4]
                                  }), React.createElement(Core.Grid, {
                                    children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$2),
                                    item: true,
                                    sm: Grid$Mui.Sm[6]
                                  }), React.createElement(Core.Grid, {
                                    children: null,
                                    container: true,
                                    direction: "column",
                                    item: true,
                                    sm: Grid$Mui.Sm[6],
                                    style: {
                                      width: "fit-content"
                                    }
                                  }, React.createElement(Core.Grid, {
                                        children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$3),
                                        item: true
                                      }), React.createElement(Core.Grid, {
                                        children: React.createElement(Core.FormControlLabel, {
                                              classes: {
                                                label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                              },
                                              control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                                    checked: form.input.unlimitedVolume,
                                                    onClick: (function ($$event) {
                                                        Curry._2(form.updateUnlimitedVolume, (function (input, unlimitedVolume) {
                                                                return {
                                                                        volume: input.volume,
                                                                        acquisitionType: input.acquisitionType,
                                                                        yearBudget: input.yearBudget,
                                                                        contractID: input.contractID,
                                                                        avsMarginGridId: input.avsMarginGridId,
                                                                        unlimitedVolume: unlimitedVolume
                                                                      };
                                                              }), $$event.target.checked);
                                                      })
                                                  }),
                                              label: intl.formatMessage(Messages_PCDAgreement$DvmAdminFrontendFr.unlimited)
                                            }),
                                        item: true
                                      }))), React.createElement(Core.Grid, {
                                children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                      label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                      color: "primary",
                                      size: "medium",
                                      type_: "submit"
                                    }),
                                item: true
                              }))))
            });
}

var Api;

var Form;

var InputStyles;

var make = PCDAgreement_Update_Modal;

export {
  Api ,
  Form ,
  InputStyles ,
  strNoneToDash ,
  make ,
}
/* react Not a pure module */
