// Generated by ReScript, PLEASE EDIT WITH CARE


var titleContract = {
  id: "533575a2-2ad4-4eb5-8459-34ea00d237d6",
  defaultMessage: "Rechercher un contrat"
};

var titleAttachment = {
  id: "45239efb-b10a-4398-9c8f-9309b307a53d",
  defaultMessage: "Rechercher un rattachment"
};

var contractID = {
  id: "18d69f90-7090-467e-8cdd-25fcf856e578",
  defaultMessage: "Code Prot/Loueur"
};

var siretSiren = {
  id: "da9575c8-4de9-4f6c-a734-522cada1440f",
  defaultMessage: "SIRET/SIREN"
};

var managerIPN = {
  id: "4e036ba5-2a09-41fd-a4e2-08868b6955d7",
  defaultMessage: "IPN Intervenant"
};

var startDateContract = {
  id: "6857a74b-cb7f-411b-825d-eac599014233",
  defaultMessage: "Début contrat"
};

var endDateContract = {
  id: "4fff920e-b823-499e-bf8b-1a7e6d762127",
  defaultMessage: "Fin contrat"
};

var contracts = {
  id: "7fb2e43d-c276-4831-87d1-59863ad48550",
  defaultMessage: "contrats"
};

var attachments = {
  id: "254d6773-2609-446a-b758-72d88d5cd0c7",
  defaultMessage: "rattachments"
};

var exportOnlyContracts = {
  id: "260ecf61-7b4e-4aa4-94ce-c5a0fad2bda6",
  defaultMessage: "Export Liste protocoles"
};

var exportContractsWithAttachments = {
  id: "e3fb065c-6ecc-450d-86ae-ebc6a77e351f",
  defaultMessage: "Export SIREN rattachés"
};

var contractsTitle = {
  id: "c27ad22b-e669-4c9a-a49d-597c0a2a540a",
  defaultMessage: "Contrats"
};

var attachmentsTitle = {
  id: "8d04697c-8325-4a0a-9eb5-3c678eafb41a",
  defaultMessage: "Rattachments"
};

export {
  titleContract ,
  titleAttachment ,
  contractID ,
  siretSiren ,
  managerIPN ,
  startDateContract ,
  endDateContract ,
  contracts ,
  attachments ,
  exportOnlyContracts ,
  exportContractsWithAttachments ,
  contractsTitle ,
  attachmentsTitle ,
}
/* No side effect */
