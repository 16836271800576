// Generated by ReScript, PLEASE EDIT WITH CARE


var title = {
  id: "ec20ac8b-81b4-4de2-8a97-10fc65e35b5d",
  defaultMessage: "Sous-marchés"
};

var submarketCode = {
  id: "0d93d629-0def-4148-b90d-1faa5913de06",
  defaultMessage: "Code de sous-marché"
};

var orderType = {
  id: "d636f24f-b0a8-4d39-b927-a68ed5e88e39",
  defaultMessage: "Type d’ordre"
};

var budgetActionType = {
  id: "b50806d0-1f8b-49c7-9065-11eb71fadb45",
  defaultMessage: "Budget/Type d’action"
};

var resultInfo = {
  id: "1c66dee1-b9eb-41d4-be87-7c15d51149b3",
  defaultMessage: "codes de sous-marché"
};

var newSubmarketButton = {
  id: "4873d4d2-b97d-4901-80e1-dd7e7e4b74b3",
  defaultMessage: "Création d’un nouveau code de sous-marché"
};

export {
  title ,
  submarketCode ,
  orderType ,
  budgetActionType ,
  resultInfo ,
  newSubmarketButton ,
}
/* No side effect */
