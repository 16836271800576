// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as App_Table$DvmAdminFrontendFr from "../../../common/components/table/App_Table.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as Submarket_Types$DvmAdminFrontendFr from "./Submarket_Types.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Messages_Submarket$DvmAdminFrontendFr from "../../../intl/messages/gemo/Messages_Submarket.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Pagination.bs.js";
import * as App_Table_Export_Menu$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Export_Menu.bs.js";
import * as Submarket_Delete_Modal$DvmAdminFrontendFr from "./edit/Submarket_Delete_Modal.bs.js";
import * as Submarket_AddEdit_Modal$DvmAdminFrontendFr from "./edit/Submarket_AddEdit_Modal.bs.js";

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Submarket_Types$DvmAdminFrontendFr.Fields.submarketCode, undefined, undefined, undefined, undefined, undefined, Messages_Submarket$DvmAdminFrontendFr.submarketCode);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Submarket_Types$DvmAdminFrontendFr.Fields.orderType, undefined, undefined, undefined, undefined, undefined, Messages_Submarket$DvmAdminFrontendFr.orderType),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Submarket_Types$DvmAdminFrontendFr.Fields.budgetActionType, undefined, undefined, undefined, undefined, undefined, Messages_Submarket$DvmAdminFrontendFr.budgetActionType),
    tl: /* [] */0
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function actionButtons(tableRow, setModal, fetchRequest, fetchData) {
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              wrap: "nowrap"
            }, React.createElement(Core.Grid, {
                  children: React.createElement(Core.Button, {
                        onClick: (function (param) {
                            Curry._1(setModal, (function (param) {
                                    return React.createElement(Submarket_AddEdit_Modal$DvmAdminFrontendFr.make, {
                                                original: tableRow,
                                                onClose: (function (param) {
                                                    Curry._1(setModal, (function (param) {
                                                            return null;
                                                          }));
                                                  }),
                                                operation: /* Edit */0,
                                                fetchRequest: fetchRequest,
                                                fetchData: fetchData
                                              });
                                  }));
                          }),
                        children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                              size: /* Large */3,
                              type_: /* Edit */60
                            }),
                        key: "edit"
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Core.Button, {
                        onClick: (function (param) {
                            Curry._1(setModal, (function (param) {
                                    return React.createElement(Submarket_Delete_Modal$DvmAdminFrontendFr.make, {
                                                tableRow: tableRow,
                                                onClose: (function (param) {
                                                    Curry._1(setModal, (function (param) {
                                                            return null;
                                                          }));
                                                  }),
                                                fetchRequest: fetchRequest,
                                                fetchData: fetchData
                                              });
                                  }));
                          }),
                        children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                              size: /* Large */3,
                              type_: /* Trash */38,
                              color: "red"
                            }),
                        key: "delete"
                      }),
                  item: true
                }));
}

function Submarket_Search_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var createSubmarketElement = Props.createSubmarketElement;
  var match = React.useState(function () {
        return null;
      });
  var setModal = match[1];
  var intl = ReactIntl.useIntl();
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (row) {
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, Caml_option.some(actionButtons({
                                                      orderType: row.orderType,
                                                      budgetActionType: row.budgetActionType,
                                                      submarketCode: row.submarketCode
                                                    }, setModal, fetchRequest, fetchData)), undefined, Belt_List.mapWithIndex({
                                                  hd: Belt_Option.getWithDefault(row.submarketCode, ""),
                                                  tl: {
                                                    hd: row.orderType,
                                                    tl: {
                                                      hd: row.budgetActionType.toUpperCase() === Submarket_Types$DvmAdminFrontendFr.submarketDefault ? intl.formatMessage(Messages_Common$DvmAdminFrontendFr.$$default) : row.budgetActionType,
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._2(fetchData, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._2(fetchData, undefined, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, {
                  headers: fetchRequest.headers,
                  handleSorting: handleSorting,
                  tablePage: convertTable(tablePage),
                  paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined),
                  rowButton: {
                    TAG: /* Left */0,
                    _0: /* EmptyCell */0
                  },
                  exportMenu: React.createElement(Core.Grid, {
                        children: null,
                        container: true,
                        item: true,
                        justifyContent: "flex-end",
                        spacing: 1
                      }, React.createElement(Core.Grid, {
                            children: createSubmarketElement,
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(App_Table_Export_Menu$DvmAdminFrontendFr.make, {
                                  query: Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                                        page: 0,
                                        rowsPerPage: 150000,
                                        headers: fetchRequest.headers,
                                        filterQueryParams: fetchRequest.filterQueryParams,
                                        additionalQueryParams: fetchRequest.additionalQueryParams
                                      }),
                                  endpoint: "gemo/submarketcodes"
                                }),
                            item: true
                          }))
                }), match[0]);
}

var Api;

var make = Submarket_Search_Table;

export {
  Api ,
  headersConfiguration ,
  actionButtons ,
  make ,
}
/* headersConfiguration Not a pure module */
