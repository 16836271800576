// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";
import * as App_Types_Status$DvmAdminFrontendFr from "../../../common/types/App_Types_Status.bs.js";
import * as App_Types_VN_VO_Status$DvmAdminFrontendFr from "../../../common/types/App_Types_VN_VO_Status.bs.js";
import * as Order_Detail_Types_Shared$DvmAdminFrontendFr from "./Order_Detail_Types_Shared.bs.js";
import * as Order_Detail_Types_Order_Type$DvmAdminFrontendFr from "./Order_Detail_Types_Order_Type.bs.js";

function orderDetail_encode(v) {
  return Js_dict.fromArray([
              [
                "lastModificationDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.lastModificationDate)
              ],
              [
                "siret",
                Decco.optionToJson(Decco.stringToJson, v.siret)
              ],
              [
                "customerName",
                Decco.optionToJson(Decco.stringToJson, v.customerName)
              ],
              [
                "shortCustomerName",
                Decco.optionToJson(Decco.stringToJson, v.shortCustomerName)
              ],
              [
                "model",
                Decco.optionToJson(Decco.stringToJson, v.model)
              ],
              [
                "version",
                Decco.optionToJson(Decco.stringToJson, v.version)
              ],
              [
                "acquisitionType",
                Decco.optionToJson(Decco.stringToJson, v.acquisitionType)
              ],
              [
                "budget",
                Decco.optionToJson(Decco.stringToJson, v.budget)
              ],
              [
                "orderDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.orderDate)
              ],
              [
                "registrationDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.registrationDate)
              ],
              [
                "deliveryDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.deliveryDate)
              ],
              [
                "renaultInvoiceDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.renaultInvoiceDate)
              ],
              [
                "pvcHt",
                Decco.optionToJson(Decco.floatToJson, v.pvcHt)
              ],
              [
                "tariffNumber",
                Decco.optionToJson(Decco.stringToJson, v.tariffNumber)
              ],
              [
                "madcDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.madcDate)
              ],
              [
                "madaDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.madaDate)
              ],
              [
                "customerInvoiceDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.customerInvoiceDate)
              ],
              [
                "customerInvoiceNumber",
                Decco.optionToJson(Decco.stringToJson, v.customerInvoiceNumber)
              ],
              [
                "agentCode",
                Decco.optionToJson(Decco.stringToJson, v.agentCode)
              ],
              [
                "salesmanName",
                Decco.optionToJson(Decco.stringToJson, v.salesmanName)
              ],
              [
                "delivered",
                Decco.optionToJson(Decco.boolToJson, v.delivered)
              ],
              [
                "finalCustomerLLD",
                Decco.optionToJson(Decco.stringToJson, v.finalCustomerLLD)
              ],
              [
                "finalCustomerNameLLD",
                Decco.optionToJson(Decco.stringToJson, v.finalCustomerNameLLD)
              ],
              [
                "newCarRegistrationID",
                Decco.optionToJson(Decco.stringToJson, v.newCarRegistrationID)
              ],
              [
                "preinvoiceDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.preinvoiceDate)
              ],
              [
                "preinvoiceNumber",
                Decco.optionToJson(Decco.stringToJson, v.preinvoiceNumber)
              ],
              [
                "bapInvoiceDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.bapInvoiceDate)
              ],
              [
                "cancellationDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.cancellationDate)
              ],
              [
                "perfDetails",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(Order_Detail_Types_Shared$DvmAdminFrontendFr.perfDetailItem_encode, param);
                      }), v.perfDetails)
              ],
              [
                "renaultInvoiceNumber",
                Decco.optionToJson(Decco.stringToJson, v.renaultInvoiceNumber)
              ],
              [
                "vin",
                Decco.optionToJson(Decco.stringToJson, v.vin)
              ],
              [
                "contractFinal",
                Decco.optionToJson(Decco.stringToJson, v.contractFinal)
              ],
              [
                "orderStatus",
                Decco.optionToJson(App_Types_VN_VO_Status$DvmAdminFrontendFr.Decco.t_encode, v.orderStatus)
              ],
              [
                "usedCarStatus",
                Decco.optionToJson(App_Types_VN_VO_Status$DvmAdminFrontendFr.Decco.t_encode, v.usedCarStatus)
              ],
              [
                "phase",
                Decco.optionToJson(Decco.stringToJson, v.phase)
              ]
            ]);
}

function orderDetail_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var lastModificationDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "lastModificationDate"), null));
  if (lastModificationDate.TAG === /* Ok */0) {
    var siret = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "siret"), null));
    if (siret.TAG === /* Ok */0) {
      var customerName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "customerName"), null));
      if (customerName.TAG === /* Ok */0) {
        var shortCustomerName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "shortCustomerName"), null));
        if (shortCustomerName.TAG === /* Ok */0) {
          var model = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "model"), null));
          if (model.TAG === /* Ok */0) {
            var version = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "version"), null));
            if (version.TAG === /* Ok */0) {
              var acquisitionType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "acquisitionType"), null));
              if (acquisitionType.TAG === /* Ok */0) {
                var budget = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "budget"), null));
                if (budget.TAG === /* Ok */0) {
                  var orderDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderDate"), null));
                  if (orderDate.TAG === /* Ok */0) {
                    var registrationDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "registrationDate"), null));
                    if (registrationDate.TAG === /* Ok */0) {
                      var deliveryDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "deliveryDate"), null));
                      if (deliveryDate.TAG === /* Ok */0) {
                        var renaultInvoiceDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "renaultInvoiceDate"), null));
                        if (renaultInvoiceDate.TAG === /* Ok */0) {
                          var pvcHt = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "pvcHt"), null));
                          if (pvcHt.TAG === /* Ok */0) {
                            var tariffNumber = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "tariffNumber"), null));
                            if (tariffNumber.TAG === /* Ok */0) {
                              var madcDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "madcDate"), null));
                              if (madcDate.TAG === /* Ok */0) {
                                var madaDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "madaDate"), null));
                                if (madaDate.TAG === /* Ok */0) {
                                  var customerInvoiceDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "customerInvoiceDate"), null));
                                  if (customerInvoiceDate.TAG === /* Ok */0) {
                                    var customerInvoiceNumber = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "customerInvoiceNumber"), null));
                                    if (customerInvoiceNumber.TAG === /* Ok */0) {
                                      var agentCode = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "agentCode"), null));
                                      if (agentCode.TAG === /* Ok */0) {
                                        var salesmanName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "salesmanName"), null));
                                        if (salesmanName.TAG === /* Ok */0) {
                                          var delivered = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "delivered"), null));
                                          if (delivered.TAG === /* Ok */0) {
                                            var finalCustomerLLD = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "finalCustomerLLD"), null));
                                            if (finalCustomerLLD.TAG === /* Ok */0) {
                                              var finalCustomerNameLLD = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "finalCustomerNameLLD"), null));
                                              if (finalCustomerNameLLD.TAG === /* Ok */0) {
                                                var newCarRegistrationID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "newCarRegistrationID"), null));
                                                if (newCarRegistrationID.TAG === /* Ok */0) {
                                                  var preinvoiceDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "preinvoiceDate"), null));
                                                  if (preinvoiceDate.TAG === /* Ok */0) {
                                                    var preinvoiceNumber = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "preinvoiceNumber"), null));
                                                    if (preinvoiceNumber.TAG === /* Ok */0) {
                                                      var bapInvoiceDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "bapInvoiceDate"), null));
                                                      if (bapInvoiceDate.TAG === /* Ok */0) {
                                                        var cancellationDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "cancellationDate"), null));
                                                        if (cancellationDate.TAG === /* Ok */0) {
                                                          var perfDetails = Decco.optionFromJson((function (param) {
                                                                  return Decco.listFromJson(Order_Detail_Types_Shared$DvmAdminFrontendFr.perfDetailItem_decode, param);
                                                                }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "perfDetails"), null));
                                                          if (perfDetails.TAG === /* Ok */0) {
                                                            var renaultInvoiceNumber = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "renaultInvoiceNumber"), null));
                                                            if (renaultInvoiceNumber.TAG === /* Ok */0) {
                                                              var vin = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "vin"), null));
                                                              if (vin.TAG === /* Ok */0) {
                                                                var contractFinal = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractFinal"), null));
                                                                if (contractFinal.TAG === /* Ok */0) {
                                                                  var orderStatus = Decco.optionFromJson(App_Types_VN_VO_Status$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderStatus"), null));
                                                                  if (orderStatus.TAG === /* Ok */0) {
                                                                    var usedCarStatus = Decco.optionFromJson(App_Types_VN_VO_Status$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "usedCarStatus"), null));
                                                                    if (usedCarStatus.TAG === /* Ok */0) {
                                                                      var phase = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "phase"), null));
                                                                      if (phase.TAG === /* Ok */0) {
                                                                        return {
                                                                                TAG: /* Ok */0,
                                                                                _0: {
                                                                                  lastModificationDate: lastModificationDate._0,
                                                                                  siret: siret._0,
                                                                                  customerName: customerName._0,
                                                                                  shortCustomerName: shortCustomerName._0,
                                                                                  model: model._0,
                                                                                  version: version._0,
                                                                                  acquisitionType: acquisitionType._0,
                                                                                  budget: budget._0,
                                                                                  orderDate: orderDate._0,
                                                                                  registrationDate: registrationDate._0,
                                                                                  deliveryDate: deliveryDate._0,
                                                                                  renaultInvoiceDate: renaultInvoiceDate._0,
                                                                                  pvcHt: pvcHt._0,
                                                                                  tariffNumber: tariffNumber._0,
                                                                                  madcDate: madcDate._0,
                                                                                  madaDate: madaDate._0,
                                                                                  customerInvoiceDate: customerInvoiceDate._0,
                                                                                  customerInvoiceNumber: customerInvoiceNumber._0,
                                                                                  agentCode: agentCode._0,
                                                                                  salesmanName: salesmanName._0,
                                                                                  delivered: delivered._0,
                                                                                  finalCustomerLLD: finalCustomerLLD._0,
                                                                                  finalCustomerNameLLD: finalCustomerNameLLD._0,
                                                                                  newCarRegistrationID: newCarRegistrationID._0,
                                                                                  preinvoiceDate: preinvoiceDate._0,
                                                                                  preinvoiceNumber: preinvoiceNumber._0,
                                                                                  bapInvoiceDate: bapInvoiceDate._0,
                                                                                  cancellationDate: cancellationDate._0,
                                                                                  perfDetails: perfDetails._0,
                                                                                  renaultInvoiceNumber: renaultInvoiceNumber._0,
                                                                                  vin: vin._0,
                                                                                  contractFinal: contractFinal._0,
                                                                                  orderStatus: orderStatus._0,
                                                                                  usedCarStatus: usedCarStatus._0,
                                                                                  phase: phase._0
                                                                                }
                                                                              };
                                                                      }
                                                                      var e = phase._0;
                                                                      return {
                                                                              TAG: /* Error */1,
                                                                              _0: {
                                                                                path: ".phase" + e.path,
                                                                                message: e.message,
                                                                                value: e.value
                                                                              }
                                                                            };
                                                                    }
                                                                    var e$1 = usedCarStatus._0;
                                                                    return {
                                                                            TAG: /* Error */1,
                                                                            _0: {
                                                                              path: ".usedCarStatus" + e$1.path,
                                                                              message: e$1.message,
                                                                              value: e$1.value
                                                                            }
                                                                          };
                                                                  }
                                                                  var e$2 = orderStatus._0;
                                                                  return {
                                                                          TAG: /* Error */1,
                                                                          _0: {
                                                                            path: ".orderStatus" + e$2.path,
                                                                            message: e$2.message,
                                                                            value: e$2.value
                                                                          }
                                                                        };
                                                                }
                                                                var e$3 = contractFinal._0;
                                                                return {
                                                                        TAG: /* Error */1,
                                                                        _0: {
                                                                          path: ".contractFinal" + e$3.path,
                                                                          message: e$3.message,
                                                                          value: e$3.value
                                                                        }
                                                                      };
                                                              }
                                                              var e$4 = vin._0;
                                                              return {
                                                                      TAG: /* Error */1,
                                                                      _0: {
                                                                        path: ".vin" + e$4.path,
                                                                        message: e$4.message,
                                                                        value: e$4.value
                                                                      }
                                                                    };
                                                            }
                                                            var e$5 = renaultInvoiceNumber._0;
                                                            return {
                                                                    TAG: /* Error */1,
                                                                    _0: {
                                                                      path: ".renaultInvoiceNumber" + e$5.path,
                                                                      message: e$5.message,
                                                                      value: e$5.value
                                                                    }
                                                                  };
                                                          }
                                                          var e$6 = perfDetails._0;
                                                          return {
                                                                  TAG: /* Error */1,
                                                                  _0: {
                                                                    path: ".perfDetails" + e$6.path,
                                                                    message: e$6.message,
                                                                    value: e$6.value
                                                                  }
                                                                };
                                                        }
                                                        var e$7 = cancellationDate._0;
                                                        return {
                                                                TAG: /* Error */1,
                                                                _0: {
                                                                  path: ".cancellationDate" + e$7.path,
                                                                  message: e$7.message,
                                                                  value: e$7.value
                                                                }
                                                              };
                                                      }
                                                      var e$8 = bapInvoiceDate._0;
                                                      return {
                                                              TAG: /* Error */1,
                                                              _0: {
                                                                path: ".bapInvoiceDate" + e$8.path,
                                                                message: e$8.message,
                                                                value: e$8.value
                                                              }
                                                            };
                                                    }
                                                    var e$9 = preinvoiceNumber._0;
                                                    return {
                                                            TAG: /* Error */1,
                                                            _0: {
                                                              path: ".preinvoiceNumber" + e$9.path,
                                                              message: e$9.message,
                                                              value: e$9.value
                                                            }
                                                          };
                                                  }
                                                  var e$10 = preinvoiceDate._0;
                                                  return {
                                                          TAG: /* Error */1,
                                                          _0: {
                                                            path: ".preinvoiceDate" + e$10.path,
                                                            message: e$10.message,
                                                            value: e$10.value
                                                          }
                                                        };
                                                }
                                                var e$11 = newCarRegistrationID._0;
                                                return {
                                                        TAG: /* Error */1,
                                                        _0: {
                                                          path: ".newCarRegistrationID" + e$11.path,
                                                          message: e$11.message,
                                                          value: e$11.value
                                                        }
                                                      };
                                              }
                                              var e$12 = finalCustomerNameLLD._0;
                                              return {
                                                      TAG: /* Error */1,
                                                      _0: {
                                                        path: ".finalCustomerNameLLD" + e$12.path,
                                                        message: e$12.message,
                                                        value: e$12.value
                                                      }
                                                    };
                                            }
                                            var e$13 = finalCustomerLLD._0;
                                            return {
                                                    TAG: /* Error */1,
                                                    _0: {
                                                      path: ".finalCustomerLLD" + e$13.path,
                                                      message: e$13.message,
                                                      value: e$13.value
                                                    }
                                                  };
                                          }
                                          var e$14 = delivered._0;
                                          return {
                                                  TAG: /* Error */1,
                                                  _0: {
                                                    path: ".delivered" + e$14.path,
                                                    message: e$14.message,
                                                    value: e$14.value
                                                  }
                                                };
                                        }
                                        var e$15 = salesmanName._0;
                                        return {
                                                TAG: /* Error */1,
                                                _0: {
                                                  path: ".salesmanName" + e$15.path,
                                                  message: e$15.message,
                                                  value: e$15.value
                                                }
                                              };
                                      }
                                      var e$16 = agentCode._0;
                                      return {
                                              TAG: /* Error */1,
                                              _0: {
                                                path: ".agentCode" + e$16.path,
                                                message: e$16.message,
                                                value: e$16.value
                                              }
                                            };
                                    }
                                    var e$17 = customerInvoiceNumber._0;
                                    return {
                                            TAG: /* Error */1,
                                            _0: {
                                              path: ".customerInvoiceNumber" + e$17.path,
                                              message: e$17.message,
                                              value: e$17.value
                                            }
                                          };
                                  }
                                  var e$18 = customerInvoiceDate._0;
                                  return {
                                          TAG: /* Error */1,
                                          _0: {
                                            path: ".customerInvoiceDate" + e$18.path,
                                            message: e$18.message,
                                            value: e$18.value
                                          }
                                        };
                                }
                                var e$19 = madaDate._0;
                                return {
                                        TAG: /* Error */1,
                                        _0: {
                                          path: ".madaDate" + e$19.path,
                                          message: e$19.message,
                                          value: e$19.value
                                        }
                                      };
                              }
                              var e$20 = madcDate._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".madcDate" + e$20.path,
                                        message: e$20.message,
                                        value: e$20.value
                                      }
                                    };
                            }
                            var e$21 = tariffNumber._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".tariffNumber" + e$21.path,
                                      message: e$21.message,
                                      value: e$21.value
                                    }
                                  };
                          }
                          var e$22 = pvcHt._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".pvcHt" + e$22.path,
                                    message: e$22.message,
                                    value: e$22.value
                                  }
                                };
                        }
                        var e$23 = renaultInvoiceDate._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".renaultInvoiceDate" + e$23.path,
                                  message: e$23.message,
                                  value: e$23.value
                                }
                              };
                      }
                      var e$24 = deliveryDate._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".deliveryDate" + e$24.path,
                                message: e$24.message,
                                value: e$24.value
                              }
                            };
                    }
                    var e$25 = registrationDate._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".registrationDate" + e$25.path,
                              message: e$25.message,
                              value: e$25.value
                            }
                          };
                  }
                  var e$26 = orderDate._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".orderDate" + e$26.path,
                            message: e$26.message,
                            value: e$26.value
                          }
                        };
                }
                var e$27 = budget._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".budget" + e$27.path,
                          message: e$27.message,
                          value: e$27.value
                        }
                      };
              }
              var e$28 = acquisitionType._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".acquisitionType" + e$28.path,
                        message: e$28.message,
                        value: e$28.value
                      }
                    };
            }
            var e$29 = version._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".version" + e$29.path,
                      message: e$29.message,
                      value: e$29.value
                    }
                  };
          }
          var e$30 = model._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".model" + e$30.path,
                    message: e$30.message,
                    value: e$30.value
                  }
                };
        }
        var e$31 = shortCustomerName._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".shortCustomerName" + e$31.path,
                  message: e$31.message,
                  value: e$31.value
                }
              };
      }
      var e$32 = customerName._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".customerName" + e$32.path,
                message: e$32.message,
                value: e$32.value
              }
            };
    }
    var e$33 = siret._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".siret" + e$33.path,
              message: e$33.message,
              value: e$33.value
            }
          };
  }
  var e$34 = lastModificationDate._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".lastModificationDate" + e$34.path,
            message: e$34.message,
            value: e$34.value
          }
        };
}

function actionDetail_encode(v) {
  return Js_dict.fromArray([
              [
                "stringActionID",
                Decco.optionToJson(Decco.stringToJson, v.stringActionID)
              ],
              [
                "actionLabel",
                Decco.optionToJson(Decco.stringToJson, v.actionLabel)
              ],
              [
                "actionType",
                Decco.optionToJson(Decco.stringToJson, v.actionType)
              ],
              [
                "state",
                Decco.optionToJson(App_Types_Status$DvmAdminFrontendFr.Decco.t_encode, v.state)
              ],
              [
                "comment",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(Order_Detail_Types_Shared$DvmAdminFrontendFr.commentItem_encode, param);
                      }), v.comment)
              ],
              [
                "bonus",
                Decco.optionToJson(Decco.floatToJson, v.bonus)
              ],
              [
                "discount",
                Decco.optionToJson(Decco.floatToJson, v.discount)
              ],
              [
                "year",
                Decco.optionToJson(Decco.stringToJson, v.year)
              ],
              [
                "deros",
                Decco.optionToJson((function (param) {
                        return Decco.arrayToJson(Order_Detail_Types_Shared$DvmAdminFrontendFr.Derogate.derogate_encode, param);
                      }), v.deros)
              ],
              [
                "preinvoiceDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.preinvoiceDate)
              ],
              [
                "preinvoiceNumber",
                Decco.optionToJson(Decco.stringToJson, v.preinvoiceNumber)
              ],
              [
                "invoiceDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.invoiceDate)
              ],
              [
                "budgetType",
                Decco.optionToJson(Decco.stringToJson, v.budgetType)
              ],
              [
                "amountPercent",
                Decco.optionToJson(Decco.floatToJson, v.amountPercent)
              ],
              [
                "amountEuro",
                Decco.optionToJson(Decco.floatToJson, v.amountEuro)
              ],
              [
                "gridDiscount",
                Decco.optionToJson(Decco.floatToJson, v.gridDiscount)
              ],
              [
                "bypassDetail",
                Decco.optionToJson(Order_Detail_Types_Shared$DvmAdminFrontendFr.bonusChangeInfo_encode, v.bypassDetail)
              ],
              [
                "perfValidationDetail",
                Decco.optionToJson(Order_Detail_Types_Shared$DvmAdminFrontendFr.bonusChangeInfo_encode, v.perfValidationDetail)
              ]
            ]);
}

function actionDetail_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var stringActionID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "stringActionID"), null));
  if (stringActionID.TAG === /* Ok */0) {
    var actionLabel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionLabel"), null));
    if (actionLabel.TAG === /* Ok */0) {
      var actionType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionType"), null));
      if (actionType.TAG === /* Ok */0) {
        var state = Decco.optionFromJson(App_Types_Status$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "state"), null));
        if (state.TAG === /* Ok */0) {
          var comment = Decco.optionFromJson((function (param) {
                  return Decco.listFromJson(Order_Detail_Types_Shared$DvmAdminFrontendFr.commentItem_decode, param);
                }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "comment"), null));
          if (comment.TAG === /* Ok */0) {
            var bonus = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "bonus"), null));
            if (bonus.TAG === /* Ok */0) {
              var discount = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "discount"), null));
              if (discount.TAG === /* Ok */0) {
                var year = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "year"), null));
                if (year.TAG === /* Ok */0) {
                  var deros = Decco.optionFromJson((function (param) {
                          return Decco.arrayFromJson(Order_Detail_Types_Shared$DvmAdminFrontendFr.Derogate.derogate_decode, param);
                        }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "deros"), null));
                  if (deros.TAG === /* Ok */0) {
                    var preinvoiceDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "preinvoiceDate"), null));
                    if (preinvoiceDate.TAG === /* Ok */0) {
                      var preinvoiceNumber = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "preinvoiceNumber"), null));
                      if (preinvoiceNumber.TAG === /* Ok */0) {
                        var invoiceDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "invoiceDate"), null));
                        if (invoiceDate.TAG === /* Ok */0) {
                          var budgetType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "budgetType"), null));
                          if (budgetType.TAG === /* Ok */0) {
                            var amountPercent = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "amountPercent"), null));
                            if (amountPercent.TAG === /* Ok */0) {
                              var amountEuro = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "amountEuro"), null));
                              if (amountEuro.TAG === /* Ok */0) {
                                var gridDiscount = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "gridDiscount"), null));
                                if (gridDiscount.TAG === /* Ok */0) {
                                  var bypassDetail = Decco.optionFromJson(Order_Detail_Types_Shared$DvmAdminFrontendFr.bonusChangeInfo_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "bypassDetail"), null));
                                  if (bypassDetail.TAG === /* Ok */0) {
                                    var perfValidationDetail = Decco.optionFromJson(Order_Detail_Types_Shared$DvmAdminFrontendFr.bonusChangeInfo_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "perfValidationDetail"), null));
                                    if (perfValidationDetail.TAG === /* Ok */0) {
                                      return {
                                              TAG: /* Ok */0,
                                              _0: {
                                                stringActionID: stringActionID._0,
                                                actionLabel: actionLabel._0,
                                                actionType: actionType._0,
                                                state: state._0,
                                                comment: comment._0,
                                                bonus: bonus._0,
                                                discount: discount._0,
                                                year: year._0,
                                                deros: deros._0,
                                                preinvoiceDate: preinvoiceDate._0,
                                                preinvoiceNumber: preinvoiceNumber._0,
                                                invoiceDate: invoiceDate._0,
                                                budgetType: budgetType._0,
                                                amountPercent: amountPercent._0,
                                                amountEuro: amountEuro._0,
                                                gridDiscount: gridDiscount._0,
                                                bypassDetail: bypassDetail._0,
                                                perfValidationDetail: perfValidationDetail._0
                                              }
                                            };
                                    }
                                    var e = perfValidationDetail._0;
                                    return {
                                            TAG: /* Error */1,
                                            _0: {
                                              path: ".perfValidationDetail" + e.path,
                                              message: e.message,
                                              value: e.value
                                            }
                                          };
                                  }
                                  var e$1 = bypassDetail._0;
                                  return {
                                          TAG: /* Error */1,
                                          _0: {
                                            path: ".bypassDetail" + e$1.path,
                                            message: e$1.message,
                                            value: e$1.value
                                          }
                                        };
                                }
                                var e$2 = gridDiscount._0;
                                return {
                                        TAG: /* Error */1,
                                        _0: {
                                          path: ".gridDiscount" + e$2.path,
                                          message: e$2.message,
                                          value: e$2.value
                                        }
                                      };
                              }
                              var e$3 = amountEuro._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".amountEuro" + e$3.path,
                                        message: e$3.message,
                                        value: e$3.value
                                      }
                                    };
                            }
                            var e$4 = amountPercent._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".amountPercent" + e$4.path,
                                      message: e$4.message,
                                      value: e$4.value
                                    }
                                  };
                          }
                          var e$5 = budgetType._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".budgetType" + e$5.path,
                                    message: e$5.message,
                                    value: e$5.value
                                  }
                                };
                        }
                        var e$6 = invoiceDate._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".invoiceDate" + e$6.path,
                                  message: e$6.message,
                                  value: e$6.value
                                }
                              };
                      }
                      var e$7 = preinvoiceNumber._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".preinvoiceNumber" + e$7.path,
                                message: e$7.message,
                                value: e$7.value
                              }
                            };
                    }
                    var e$8 = preinvoiceDate._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".preinvoiceDate" + e$8.path,
                              message: e$8.message,
                              value: e$8.value
                            }
                          };
                  }
                  var e$9 = deros._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".deros" + e$9.path,
                            message: e$9.message,
                            value: e$9.value
                          }
                        };
                }
                var e$10 = year._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".year" + e$10.path,
                          message: e$10.message,
                          value: e$10.value
                        }
                      };
              }
              var e$11 = discount._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".discount" + e$11.path,
                        message: e$11.message,
                        value: e$11.value
                      }
                    };
            }
            var e$12 = bonus._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".bonus" + e$12.path,
                      message: e$12.message,
                      value: e$12.value
                    }
                  };
          }
          var e$13 = comment._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".comment" + e$13.path,
                    message: e$13.message,
                    value: e$13.value
                  }
                };
        }
        var e$14 = state._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".state" + e$14.path,
                  message: e$14.message,
                  value: e$14.value
                }
              };
      }
      var e$15 = actionType._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".actionType" + e$15.path,
                message: e$15.message,
                value: e$15.value
              }
            };
    }
    var e$16 = actionLabel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".actionLabel" + e$16.path,
              message: e$16.message,
              value: e$16.value
            }
          };
  }
  var e$17 = stringActionID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".stringActionID" + e$17.path,
            message: e$17.message,
            value: e$17.value
          }
        };
}

function order_encode(v) {
  return Js_dict.fromArray([
              [
                "dr",
                Decco.optionToJson(Decco.stringToJson, v.dr)
              ],
              [
                "dealerID",
                Decco.optionToJson(Decco.stringToJson, v.dealerID)
              ],
              [
                "dealerName",
                Decco.optionToJson(Decco.stringToJson, v.dealerName)
              ],
              [
                "agentName",
                Decco.optionToJson(Decco.stringToJson, v.agentName)
              ],
              [
                "orderNumberCustomer",
                Decco.optionToJson(Decco.stringToJson, v.orderNumberCustomer)
              ],
              [
                "orderNumberCar",
                Decco.optionToJson(Decco.stringToJson, v.orderNumberCar)
              ],
              [
                "detail",
                orderDetail_encode(v.detail)
              ],
              [
                "budget",
                Decco.optionToJson(actionDetail_encode, v.budget)
              ],
              [
                "stoc",
                Decco.optionToJson((function (param) {
                        return Decco.arrayToJson(actionDetail_encode, param);
                      }), v.stoc)
              ],
              [
                "perf",
                Decco.optionToJson((function (param) {
                        return Decco.arrayToJson(actionDetail_encode, param);
                      }), v.perf)
              ],
              [
                "repr",
                Decco.optionToJson((function (param) {
                        return Decco.arrayToJson(actionDetail_encode, param);
                      }), v.repr)
              ],
              [
                "dero",
                Decco.optionToJson((function (param) {
                        return Decco.arrayToJson(Order_Detail_Types_Shared$DvmAdminFrontendFr.Derogate.derogate_encode, param);
                      }), v.dero)
              ],
              [
                "deros",
                Decco.optionToJson((function (param) {
                        return Decco.arrayToJson(Order_Detail_Types_Shared$DvmAdminFrontendFr.Derogate.derogate_encode, param);
                      }), v.deros)
              ],
              [
                "contractID",
                Decco.optionToJson(Decco.stringToJson, v.contractID)
              ],
              [
                "usedCarDetail",
                Decco.optionToJson(Order_Detail_Types_Shared$DvmAdminFrontendFr.usedCarDetail_encode, v.usedCarDetail)
              ],
              [
                "archived",
                Decco.optionToJson(Decco.boolToJson, v.archived)
              ],
              [
                "orderType",
                Decco.optionToJson(Order_Detail_Types_Order_Type$DvmAdminFrontendFr.Decco.t_encode, v.orderType)
              ],
              [
                "locationCommonName",
                Decco.optionToJson(Decco.stringToJson, v.locationCommonName)
              ]
            ]);
}

function order_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var dr = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "dr"), null));
  if (dr.TAG === /* Ok */0) {
    var dealerID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerID"), null));
    if (dealerID.TAG === /* Ok */0) {
      var dealerName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerName"), null));
      if (dealerName.TAG === /* Ok */0) {
        var agentName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "agentName"), null));
        if (agentName.TAG === /* Ok */0) {
          var orderNumberCustomer = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderNumberCustomer"), null));
          if (orderNumberCustomer.TAG === /* Ok */0) {
            var orderNumberCar = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderNumberCar"), null));
            if (orderNumberCar.TAG === /* Ok */0) {
              var detail = orderDetail_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "detail"), null));
              if (detail.TAG === /* Ok */0) {
                var budget = Decco.optionFromJson(actionDetail_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "budget"), null));
                if (budget.TAG === /* Ok */0) {
                  var stoc = Decco.optionFromJson((function (param) {
                          return Decco.arrayFromJson(actionDetail_decode, param);
                        }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "stoc"), null));
                  if (stoc.TAG === /* Ok */0) {
                    var perf = Decco.optionFromJson((function (param) {
                            return Decco.arrayFromJson(actionDetail_decode, param);
                          }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "perf"), null));
                    if (perf.TAG === /* Ok */0) {
                      var repr = Decco.optionFromJson((function (param) {
                              return Decco.arrayFromJson(actionDetail_decode, param);
                            }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "repr"), null));
                      if (repr.TAG === /* Ok */0) {
                        var dero = Decco.optionFromJson((function (param) {
                                return Decco.arrayFromJson(Order_Detail_Types_Shared$DvmAdminFrontendFr.Derogate.derogate_decode, param);
                              }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "dero"), null));
                        if (dero.TAG === /* Ok */0) {
                          var deros = Decco.optionFromJson((function (param) {
                                  return Decco.arrayFromJson(Order_Detail_Types_Shared$DvmAdminFrontendFr.Derogate.derogate_decode, param);
                                }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "deros"), null));
                          if (deros.TAG === /* Ok */0) {
                            var contractID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractID"), null));
                            if (contractID.TAG === /* Ok */0) {
                              var usedCarDetail = Decco.optionFromJson(Order_Detail_Types_Shared$DvmAdminFrontendFr.usedCarDetail_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "usedCarDetail"), null));
                              if (usedCarDetail.TAG === /* Ok */0) {
                                var archived = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "archived"), null));
                                if (archived.TAG === /* Ok */0) {
                                  var orderType = Decco.optionFromJson(Order_Detail_Types_Order_Type$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderType"), null));
                                  if (orderType.TAG === /* Ok */0) {
                                    var locationCommonName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "locationCommonName"), null));
                                    if (locationCommonName.TAG === /* Ok */0) {
                                      return {
                                              TAG: /* Ok */0,
                                              _0: {
                                                dr: dr._0,
                                                dealerID: dealerID._0,
                                                dealerName: dealerName._0,
                                                agentName: agentName._0,
                                                orderNumberCustomer: orderNumberCustomer._0,
                                                orderNumberCar: orderNumberCar._0,
                                                detail: detail._0,
                                                budget: budget._0,
                                                stoc: stoc._0,
                                                perf: perf._0,
                                                repr: repr._0,
                                                dero: dero._0,
                                                deros: deros._0,
                                                contractID: contractID._0,
                                                usedCarDetail: usedCarDetail._0,
                                                archived: archived._0,
                                                orderType: orderType._0,
                                                locationCommonName: locationCommonName._0
                                              }
                                            };
                                    }
                                    var e = locationCommonName._0;
                                    return {
                                            TAG: /* Error */1,
                                            _0: {
                                              path: ".locationCommonName" + e.path,
                                              message: e.message,
                                              value: e.value
                                            }
                                          };
                                  }
                                  var e$1 = orderType._0;
                                  return {
                                          TAG: /* Error */1,
                                          _0: {
                                            path: ".orderType" + e$1.path,
                                            message: e$1.message,
                                            value: e$1.value
                                          }
                                        };
                                }
                                var e$2 = archived._0;
                                return {
                                        TAG: /* Error */1,
                                        _0: {
                                          path: ".archived" + e$2.path,
                                          message: e$2.message,
                                          value: e$2.value
                                        }
                                      };
                              }
                              var e$3 = usedCarDetail._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".usedCarDetail" + e$3.path,
                                        message: e$3.message,
                                        value: e$3.value
                                      }
                                    };
                            }
                            var e$4 = contractID._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".contractID" + e$4.path,
                                      message: e$4.message,
                                      value: e$4.value
                                    }
                                  };
                          }
                          var e$5 = deros._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".deros" + e$5.path,
                                    message: e$5.message,
                                    value: e$5.value
                                  }
                                };
                        }
                        var e$6 = dero._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".dero" + e$6.path,
                                  message: e$6.message,
                                  value: e$6.value
                                }
                              };
                      }
                      var e$7 = repr._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".repr" + e$7.path,
                                message: e$7.message,
                                value: e$7.value
                              }
                            };
                    }
                    var e$8 = perf._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".perf" + e$8.path,
                              message: e$8.message,
                              value: e$8.value
                            }
                          };
                  }
                  var e$9 = stoc._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".stoc" + e$9.path,
                            message: e$9.message,
                            value: e$9.value
                          }
                        };
                }
                var e$10 = budget._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".budget" + e$10.path,
                          message: e$10.message,
                          value: e$10.value
                        }
                      };
              }
              var e$11 = detail._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".detail" + e$11.path,
                        message: e$11.message,
                        value: e$11.value
                      }
                    };
            }
            var e$12 = orderNumberCar._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".orderNumberCar" + e$12.path,
                      message: e$12.message,
                      value: e$12.value
                    }
                  };
          }
          var e$13 = orderNumberCustomer._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".orderNumberCustomer" + e$13.path,
                    message: e$13.message,
                    value: e$13.value
                  }
                };
        }
        var e$14 = agentName._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".agentName" + e$14.path,
                  message: e$14.message,
                  value: e$14.value
                }
              };
      }
      var e$15 = dealerName._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".dealerName" + e$15.path,
                message: e$15.message,
                value: e$15.value
              }
            };
    }
    var e$16 = dealerID._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".dealerID" + e$16.path,
              message: e$16.message,
              value: e$16.value
            }
          };
  }
  var e$17 = dr._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".dr" + e$17.path,
            message: e$17.message,
            value: e$17.value
          }
        };
}

var Shared;

export {
  Shared ,
  orderDetail_encode ,
  orderDetail_decode ,
  actionDetail_encode ,
  actionDetail_decode ,
  order_encode ,
  order_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
