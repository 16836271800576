// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../api/RestApi.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../../../../common/types/App_Types_Input.bs.js";
import * as Flash_Search_Types$DvmAdminFrontendFr from "../../search/Flash_Search_Types.bs.js";

function getCumulables(alert, userManager, setNonCumulablesList, flashId, selectedRole) {
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.twoParamList_decode, undefined, userManager, selectedRole, "/flash/cumulable?actionID=" + flashId + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var nonCumulables = result._0;
          return Curry._1(setNonCumulablesList, (function (param) {
                        return nonCumulables;
                      }));
        }
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: result._0
                  }
                }));
        return Curry._1(setNonCumulablesList, (function (param) {
                      return /* [] */0;
                    }));
      });
}

function getAvailableCumulables(alert, userManager, setActions, models, actionId, selectedRole, flashType, flash) {
  Curry._1(setActions, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Search_Types$DvmAdminFrontendFr.flashRowList_decode, undefined, userManager, selectedRole, "/flash/" + (
          flashType ? "modular/" : ""
        ) + "actions/filtered?models=" + Belt_Array.joinWith(Belt_List.toArray(models), "%5C%2C", (function (model) {
                return model;
              })) + "&actionId=" + actionId + "" + (
          flashType ? "" : Belt_Option.mapWithDefault(flash.orderStartDate, "", (function (x) {
                    return "&startDate=" + x.toISOString() + "";
                  })) + Belt_Option.mapWithDefault(flash.orderEndDate, "", (function (x) {
                    return "&endDate=" + x.toISOString() + "";
                  }))
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var actions = result._0;
          return Curry._1(setActions, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: Belt_List.map(actions, (function (x) {
                                        return {
                                                paramOne: x.actionID,
                                                paramTwo: x.actionLabel
                                              };
                                      }))
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setActions, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function saveCumulables(operation, userManager, setResult, submissionCallbacks, selectedRole, nonCumulables) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call(operation ? "PUT" : "POST", {
          TAG: /* Json */0,
          _0: App_Types_Input$DvmAdminFrontendFr.parentContent_encode(nonCumulables)
        }, userManager, selectedRole, "/flash/cumulable").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: operation
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToSaveNonCumulables */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

var Types;

export {
  Form ,
  Types ,
  getCumulables ,
  getAvailableCumulables ,
  saveCumulables ,
}
/* react Not a pure module */
