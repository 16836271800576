// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as Utils_Api$DvmAdminFrontendFr from "../../common/utils/Utils_Api.bs.js";
import * as App_Spinner$DvmAdminFrontendFr from "../../common/components/App_Spinner.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../common/utils/Utils_Alert.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../common/App_Typography.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../context/router/Context_Router.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../intl/messages/Messages_Common.bs.js";
import * as App_Table_Result$DvmAdminFrontendFr from "../../common/components/table/App_Table_Result.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../common/types/App_Types_Result.bs.js";
import * as Model_Version_Api$DvmAdminFrontendFr from "./Model_Version_Api.bs.js";
import * as Model_Version_Form$DvmAdminFrontendFr from "./Model_Version_Form.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../common/components/table/App_Table_Pagination.bs.js";
import * as Messages_ModelVersion$DvmAdminFrontendFr from "../../intl/messages/modelVersion/Messages_ModelVersion.bs.js";
import * as Model_Version_Filters$DvmAdminFrontendFr from "./Model_Version_Filters.bs.js";
import * as ModelVersion_Model_Table$DvmAdminFrontendFr from "./ModelVersion_Model_Table.bs.js";
import * as ModelVersion_Version_Table$DvmAdminFrontendFr from "./ModelVersion_Version_Table.bs.js";

function Model_Version_Page(Props) {
  var allowEdit = Props.allowEdit;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = React.useState(function () {
        return {
                TAG: /* ModelPage */0,
                _0: /* NotStarted */0
              };
      });
  var setTablePage = match$1[1];
  var tablePage = match$1[0];
  var match$2 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: Caml_array.get(App_Table_Pagination$DvmAdminFrontendFr.options, 0),
                headers: ModelVersion_Model_Table$DvmAdminFrontendFr.headersConfiguration,
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$2[1];
  var fetchRequest = match$2[0];
  var alert = ReactAlert.useAlert();
  var match$3 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$4 = match$3[0];
  var userManager = match$4.userManager;
  var selectedRole = match$4.selectedRole;
  var fetchData = function (submissionCallbacks, tableType, fetchRequest) {
    if (tableType) {
      Model_Version_Api$DvmAdminFrontendFr.fetchVersionSearch(submissionCallbacks, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, fetchRequest), selectedRole);
    } else {
      Model_Version_Api$DvmAdminFrontendFr.fetchModelSearch(submissionCallbacks, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, fetchRequest), selectedRole);
    }
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  var form = Model_Version_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.getWithDefault(match[0].modelVersionFilter, Model_Version_Form$DvmAdminFrontendFr.initialState), (function (param, submissionCallbacks) {
          var tableType = param.tableType;
          var active = param.active;
          var modelLabel = param.modelLabel;
          var phase = param.phase;
          var model = param.model;
          var brand = param.brand;
          fetchData(submissionCallbacks, tableType, {
                page: 0,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: tableType ? ModelVersion_Version_Table$DvmAdminFrontendFr.headersConfiguration : ModelVersion_Model_Table$DvmAdminFrontendFr.headersConfiguration,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap(tableType ? ({
                                  hd: brand,
                                  tl: {
                                    hd: model,
                                    tl: {
                                      hd: param.version,
                                      tl: {
                                        hd: modelLabel,
                                        tl: {
                                          hd: param.versionLabel,
                                          tl: {
                                            hd: param.motorization,
                                            tl: {
                                              hd: active,
                                              tl: {
                                                hd: phase,
                                                tl: {
                                                  hd: param.additionDate,
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }) : ({
                                  hd: brand,
                                  tl: {
                                    hd: model,
                                    tl: {
                                      hd: modelLabel,
                                      tl: {
                                        hd: active,
                                        tl: {
                                          hd: phase,
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }), (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  React.useEffect((function () {
          var match = form.input.tableType;
          if (match) {
            Curry._1(form.submit, undefined);
          } else {
            Curry._2(form.updateVersion, (function (input, version) {
                    return {
                            brand: input.brand,
                            model: input.model,
                            version: version,
                            phase: input.phase,
                            modelLabel: input.modelLabel,
                            versionLabel: input.versionLabel,
                            motorization: input.motorization,
                            emptyMotorization: input.emptyMotorization,
                            active: input.active,
                            tableType: input.tableType,
                            additionDate: input.additionDate
                          };
                  }), "");
            Curry._2(form.updateVersionLabel, (function (input, versionLabel) {
                    return {
                            brand: input.brand,
                            model: input.model,
                            version: input.version,
                            phase: input.phase,
                            modelLabel: input.modelLabel,
                            versionLabel: versionLabel,
                            motorization: input.motorization,
                            emptyMotorization: input.emptyMotorization,
                            active: input.active,
                            tableType: input.tableType,
                            additionDate: input.additionDate
                          };
                  }), "");
            Curry._2(form.updateMotorization, (function (input, motorization) {
                    return {
                            brand: input.brand,
                            model: input.model,
                            version: input.version,
                            phase: input.phase,
                            modelLabel: input.modelLabel,
                            versionLabel: input.versionLabel,
                            motorization: motorization,
                            emptyMotorization: input.emptyMotorization,
                            active: input.active,
                            tableType: input.tableType,
                            additionDate: input.additionDate
                          };
                  }), "");
            Curry._2(form.updateEmptyMotorization, (function (input, emptyMotorization) {
                    return {
                            brand: input.brand,
                            model: input.model,
                            version: input.version,
                            phase: input.phase,
                            modelLabel: input.modelLabel,
                            versionLabel: input.versionLabel,
                            motorization: input.motorization,
                            emptyMotorization: emptyMotorization,
                            active: input.active,
                            tableType: input.tableType,
                            additionDate: input.additionDate
                          };
                  }), false);
            Curry._2(form.updateAdditionDate, (function (input, additionDate) {
                    return {
                            brand: input.brand,
                            model: input.model,
                            version: input.version,
                            phase: input.phase,
                            modelLabel: input.modelLabel,
                            versionLabel: input.versionLabel,
                            motorization: input.motorization,
                            emptyMotorization: input.emptyMotorization,
                            active: input.active,
                            tableType: input.tableType,
                            additionDate: additionDate
                          };
                  }), undefined);
            Curry._1(form.submit, undefined);
          }
        }), [form.input.tableType]);
  React.useEffect((function () {
          if (tablePage.TAG === /* ModelPage */0) {
            App_Types_Result$DvmAdminFrontendFr.forError(tablePage._0, (function (error) {
                    Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                              message: {
                                TAG: /* RestError */2,
                                _0: error
                              }
                            }));
                  }));
          } else {
            App_Types_Result$DvmAdminFrontendFr.forError(tablePage._0, (function (error) {
                    Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                              message: {
                                TAG: /* RestError */2,
                                _0: error
                              }
                            }));
                  }));
          }
        }), [tablePage]);
  var tmp;
  if (tablePage.TAG === /* ModelPage */0) {
    var page = tablePage._0;
    tmp = typeof page === "number" ? (
        page === /* NotStarted */0 ? null : React.createElement(App_Spinner$DvmAdminFrontendFr.make, {})
      ) : (
        page.TAG === /* Ok */0 ? React.createElement(App_Table_Result$DvmAdminFrontendFr.make, {
                number: page._0.total,
                message: Messages_Common$DvmAdminFrontendFr.models
              }) : null
      );
  } else {
    var page$1 = tablePage._0;
    tmp = typeof page$1 === "number" ? (
        page$1 === /* NotStarted */0 ? null : React.createElement(App_Spinner$DvmAdminFrontendFr.make, {})
      ) : (
        page$1.TAG === /* Ok */0 ? React.createElement(App_Table_Result$DvmAdminFrontendFr.make, {
                number: page$1._0.total,
                message: Messages_Common$DvmAdminFrontendFr.versions
              }) : null
      );
  }
  var match$5 = form.input.tableType;
  var match$6 = form.input.tableType;
  var tmp$1;
  tmp$1 = tablePage.TAG === /* ModelPage */0 ? React.createElement(ModelVersion_Model_Table$DvmAdminFrontendFr.make, {
          tablePage: tablePage._0,
          fetchRequest: fetchRequest,
          fetchData: fetchData
        }) : React.createElement(ModelVersion_Version_Table$DvmAdminFrontendFr.make, {
          tablePage: tablePage._0,
          fetchRequest: fetchRequest,
          fetchData: fetchData,
          allowEdit: allowEdit
        });
  return React.createElement(React.Fragment, undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  children: intl.formatMessage(Messages_ModelVersion$DvmAdminFrontendFr.title),
                  variant: "h3"
                }), React.createElement(Model_Version_Filters$DvmAdminFrontendFr.make, {
                  form: form
                }), tmp, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  spacing: 2
                }, React.createElement(Core.Grid, {
                      children: React.createElement(Core.Button, {
                            onClick: (function (param) {
                                Curry._2(form.updateTableType, (function (input, tableType) {
                                        return {
                                                brand: input.brand,
                                                model: input.model,
                                                version: input.version,
                                                phase: input.phase,
                                                modelLabel: input.modelLabel,
                                                versionLabel: input.versionLabel,
                                                motorization: input.motorization,
                                                emptyMotorization: input.emptyMotorization,
                                                active: input.active,
                                                tableType: tableType,
                                                additionDate: input.additionDate
                                              };
                                      }), /* Model */0);
                              }),
                            style: match$5 ? ({}) : ({
                                  borderBottom: "solid 5px " + Theme_Colors$DvmAdminFrontendFr.primaryBrown + ""
                                }),
                            children: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.models)
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(Core.Button, {
                            onClick: (function (param) {
                                Curry._2(form.updateTableType, (function (input, tableType) {
                                        return {
                                                brand: input.brand,
                                                model: input.model,
                                                version: input.version,
                                                phase: input.phase,
                                                modelLabel: input.modelLabel,
                                                versionLabel: input.versionLabel,
                                                motorization: input.motorization,
                                                emptyMotorization: input.emptyMotorization,
                                                active: input.active,
                                                tableType: tableType,
                                                additionDate: input.additionDate
                                              };
                                      }), /* Version */1);
                              }),
                            style: match$6 ? ({
                                  borderBottom: "solid 5px " + Theme_Colors$DvmAdminFrontendFr.primaryBrown + ""
                                }) : ({}),
                            children: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.versions)
                          }),
                      item: true
                    })), tmp$1);
}

var Api;

var TableModel;

var TableVersion;

var Filter;

var Types;

var Form;

var make = Model_Version_Page;

export {
  Api ,
  TableModel ,
  TableVersion ,
  Filter ,
  Types ,
  Form ,
  make ,
}
/* react Not a pure module */
