// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReDate_utils from "@mobily/rescript-date/src/ReDate_utils.bs.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Grid_Types$DvmAdminFrontendFr from "../Grid_Types.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../../common/utils/Utils_Date.bs.js";

function getQuery(whatTable, whatGrid, rows) {
  var budget = Belt_Option.mapWithDefault(Belt_List.get(rows, 0), "", (function (x) {
          return Belt_Option.getWithDefault(x.budget, "");
        }));
  var queryBudget = "budget=" + budget + ";";
  var queryAVSGrid = "avsMarginGridID=" + Belt_Option.mapWithDefault(Belt_List.get(rows, 0), "", (function (x) {
          return Belt_Option.getWithDefault(x.avsMarginGridID, "");
        })) + ";";
  var queryAllMargin = "?limit=10&offset=0&filter=";
  var queryAllModelVersion = "/" + budget + "?limit=10&offset=0&filter=";
  var queryList = encodeURIComponent(Belt_Array.joinWith(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(rows, (function (x) {
                              var start = Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(Caml_option.some(x.startDate)), "");
                              var end = Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(x.endDate), "");
                              var tmp;
                              switch (whatTable) {
                                case /* Model */0 :
                                    tmp = ["model=" + Belt_Option.getWithDefault(x.model, "") + ""];
                                    break;
                                case /* Version */1 :
                                    tmp = [
                                      "model=" + Belt_Option.getWithDefault(x.model, "") + "",
                                      "version=" + Belt_Option.getWithDefault(x.version, "") + ""
                                    ];
                                    break;
                                case /* GridID */2 :
                                    tmp = ["categoryID=" + Belt_Option.getWithDefault(x.categoryID, "") + ""];
                                    break;
                                
                              }
                              var firstStr = Belt_Array.joinWith(Belt_Array.concat(tmp, Belt_Array.keepMap([
                                            whatGrid === /* MarginGrid */1 ? "discount=" + String(x.discount) + "" : undefined,
                                            whatGrid === /* ModelVersionGrid */0 && (budget === "LCD" || budget === "PROT") ? "acquisitionType=" + Belt_Option.getWithDefault(x.acquisitionType, "") + "" : undefined,
                                            whatGrid === /* ModelVersionGrid */0 ? "contractId=" + Belt_Option.getWithDefault(x.contractID, "") + "" : undefined
                                          ], (function (x) {
                                              return x;
                                            }))), "&&", (function (x) {
                                      return x;
                                    }));
                              return Belt_Array.joinWith([
                                          firstStr + "&&" + ("startDate=[\\\," + start + "]&&endDate=[" + start + "\\\,]"),
                                          firstStr + "&&" + ("startDate=[\\\," + end + "]&&endDate=[" + end + "\\\,]"),
                                          firstStr + "&&" + ("startDate=[" + start + "\\\,]&&endDate=[\\\," + end + "]"),
                                          firstStr + "&&" + ("startDate=[\\\," + start + "]&&endDate=[" + end + "\\\,]")
                                        ], "||", (function (x) {
                                            return x;
                                          }));
                            }))))), "||", (function (x) {
              return x;
            })));
  if (whatGrid) {
    switch (whatTable) {
      case /* Model */0 :
          return "/margins/model" + queryAllMargin + queryBudget + queryList;
      case /* Version */1 :
          return "/margins/modelversion" + queryAllMargin + queryBudget + queryList;
      case /* GridID */2 :
          return "/margins/avs" + queryAllMargin + queryAVSGrid + queryList;
      
    }
  } else {
    switch (whatTable) {
      case /* Model */0 :
          return "/" + Grid_Types$DvmAdminFrontendFr.modelGridEndpoint + queryAllModelVersion + queryList;
      case /* Version */1 :
          return "/" + Grid_Types$DvmAdminFrontendFr.versionGridEndpoint + queryAllModelVersion + queryList;
      case /* GridID */2 :
          return "";
      
    }
  }
}

function stringCompare(a, b) {
  return Belt_Option.getWithDefault(a, "") === Belt_Option.getWithDefault(b, "");
}

function floatCompare(a, b) {
  return Belt_Option.getWithDefault(a, 0.0) === Belt_Option.getWithDefault(b, 0.0);
}

function checkForOverlaps(list) {
  var newRows = Belt_List.map(list, (function (x) {
          return x.new;
        }));
  var rowsDistinct = Belt_List.map(Belt_List.fromArray(Belt_MapString.toArray(Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(newRows, (function (x) {
                              return [
                                      Belt_Option.getWithDefault(x.model, "") + Belt_Option.getWithDefault(x.version, "") + Belt_Option.getWithDefault(x.categoryID, "") + String(x.discount) + Belt_Option.getWithDefault(x.acquisitionType, "") + Belt_Option.mapWithDefault(x.margin, "", (function (prim) {
                                              return String(prim);
                                            })) + Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(Caml_option.some(x.startDate)), "") + Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(x.endDate), ""),
                                      x
                                    ];
                            })))))), (function (param) {
          return param[1];
        }));
  return Belt_List.reduce(Belt_List.map(rowsDistinct, (function (x) {
                    return Belt_List.length(Belt_List.keep(rowsDistinct, (function (listElement) {
                                      if (!(stringCompare(listElement.model, x.model) && stringCompare(listElement.version, x.version) && stringCompare(listElement.acquisitionType, x.acquisitionType) && stringCompare(listElement.categoryID, x.categoryID) && listElement.discount === x.discount)) {
                                        return false;
                                      }
                                      var match = x.endDate;
                                      var match$1 = listElement.endDate;
                                      if (match === undefined) {
                                        return true;
                                      }
                                      if (match$1 === undefined) {
                                        return true;
                                      }
                                      var xStartDateVal = ReDate_utils.makeStartOfDayDate(x.startDate).valueOf();
                                      var xEndDateVal = ReDate_utils.makeStartOfDayDate(Caml_option.valFromOption(match)).valueOf();
                                      var listStartDateVal = ReDate_utils.makeStartOfDayDate(listElement.startDate).valueOf();
                                      var listEndDateVal = ReDate_utils.makeStartOfDayDate(Caml_option.valFromOption(match$1)).valueOf();
                                      if (xStartDateVal <= listStartDateVal && listEndDateVal <= xEndDateVal || xStartDateVal >= listStartDateVal && listEndDateVal >= xEndDateVal || listStartDateVal <= xStartDateVal && xStartDateVal <= listEndDateVal) {
                                        return true;
                                      } else if (listStartDateVal <= xEndDateVal) {
                                        return xEndDateVal <= listEndDateVal;
                                      } else {
                                        return false;
                                      }
                                    }))) > 1;
                  })), false, (function (a, b) {
                if (a) {
                  return true;
                } else {
                  return b;
                }
              }));
}

function removeOriginalsFromQuery(listApi, list) {
  return Belt_List.keep(listApi, (function (apiElement) {
                return !Belt_Option.isSome(Belt_List.getBy(list, (function (x) {
                                  if (!(stringCompare(apiElement.model, x.model) && stringCompare(apiElement.version, x.version) && stringCompare(apiElement.acquisitionType, x.acquisitionType) && stringCompare(apiElement.categoryID, x.categoryID) && apiElement.discount === x.discount)) {
                                    return false;
                                  }
                                  var match = x.endDate;
                                  var match$1 = apiElement.endDate;
                                  if (match === undefined) {
                                    return true;
                                  }
                                  if (match$1 === undefined) {
                                    return true;
                                  }
                                  var xStartDateVal = ReDate_utils.makeStartOfDayDate(x.startDate).valueOf();
                                  var xEndDateVal = ReDate_utils.makeStartOfDayDate(Caml_option.valFromOption(match)).valueOf();
                                  var apiStartDateVal = ReDate_utils.makeStartOfDayDate(apiElement.startDate).valueOf();
                                  var apiEndDateVal = ReDate_utils.makeStartOfDayDate(Caml_option.valFromOption(match$1)).valueOf();
                                  if (xStartDateVal === apiStartDateVal) {
                                    return apiEndDateVal === xEndDateVal;
                                  } else {
                                    return false;
                                  }
                                })));
              }));
}

function getOnlyChangedRows(rows) {
  return Belt_List.keep(rows, (function (row) {
                var tmp = false;
                if (stringCompare(row.original.model, row.new.model) && stringCompare(row.original.version, row.new.version) && stringCompare(row.original.acquisitionType, row.new.acquisitionType) && floatCompare(row.original.margin, row.new.margin) && stringCompare(row.original.categoryID, row.new.categoryID) && row.original.discount === row.new.discount && Belt_Option.getWithDefault(row.original.hasExceptions, false) === Belt_Option.getWithDefault(row.new.hasExceptions, false)) {
                  var match = row.original.endDate;
                  var match$1 = row.new.endDate;
                  var tmp$1;
                  if (match !== undefined) {
                    if (match$1 !== undefined) {
                      var newStartDateVal = ReDate_utils.makeStartOfDayDate(row.new.startDate).valueOf();
                      var newEndDateval = ReDate_utils.makeStartOfDayDate(Caml_option.valFromOption(match$1)).valueOf();
                      var origStartDateVal = ReDate_utils.makeStartOfDayDate(row.original.startDate).valueOf();
                      var origEndDateVal = ReDate_utils.makeStartOfDayDate(Caml_option.valFromOption(match)).valueOf();
                      tmp$1 = newStartDateVal === origStartDateVal && newEndDateval === origEndDateVal;
                    } else {
                      tmp$1 = true;
                    }
                  } else {
                    tmp$1 = match$1 === undefined;
                  }
                  tmp = tmp$1;
                }
                return !tmp;
              }));
}

export {
  getQuery ,
  stringCompare ,
  floatCompare ,
  checkForOverlaps ,
  removeOriginalsFromQuery ,
  getOnlyChangedRows ,
}
/* Grid_Types-DvmAdminFrontendFr Not a pure module */
