// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Messages_Flash_Search$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Search.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_TextFieldFormInput.bs.js";

function Flash_Search_Modal_Detention_Box(Props) {
  var disabledOpt = Props.disabled;
  var title = Props.title;
  var onChangeFrom = Props.onChangeFrom;
  var onChangeTo = Props.onChangeTo;
  var valFrom = Props.valFrom;
  var valTo = Props.valTo;
  var className = Props.className;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var intl = ReactIntl.useIntl();
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              item: true
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        style: {
                          fontWeight: "600"
                        },
                        children: intl.formatMessage(title)
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  item: true,
                  spacing: 1,
                  wrap: "nowrap"
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry.__1(onChangeFrom),
                            value: valFrom,
                            disabled: disabled,
                            className: className,
                            label: Messages_Flash_Search$DvmAdminFrontendFr.numberValFrom,
                            inputType: "number"
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry.__1(onChangeTo),
                            value: valTo,
                            disabled: disabled,
                            className: className,
                            label: Messages_Flash_Search$DvmAdminFrontendFr.numberValTo,
                            inputType: "number"
                          }),
                      item: true
                    })));
}

var make = Flash_Search_Modal_Detention_Box;

export {
  make ,
}
/* react Not a pure module */
