// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "@emotion/css";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../theme/Theme_Colors.bs.js";

var titleBox = Css.css({
      backgroundColor: "white",
      marginBottom: "39px",
      minHeight: "40px",
      padding: "19px 83px",
      boxShadow: "0 2px 6px 2px rgba(0, 0, 0, 0.05)"
    });

var detailBox = Css.css({
      borderLeft: "4px solid " + Theme_Colors$DvmAdminFrontendFr.primaryBrown + "",
      marginTop: "16px",
      minHeight: "40px",
      paddingLeft: "20px"
    });

var detailRow = Css.css(Object.assign({}, {
          minHeight: "40px",
          padding: "14px 0px"
        }, {
          "&:not(:last-child)": {
            borderBottom: "1px solid " + Theme_Colors$DvmAdminFrontendFr.grayLight3 + ""
          }
        }));

var eligibleCarBox = Css.css({
      backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight2,
      marginTop: "25px",
      padding: "10px 15px"
    });

export {
  titleBox ,
  detailBox ,
  detailRow ,
  eligibleCarBox ,
}
/* titleBox Not a pure module */
