// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function dealerEditsCorrection_encode(v) {
  return Js_dict.fromArray([[
                "customerDiscount",
                Decco.optionToJson(Decco.floatToJson, v.customerDiscount)
              ]]);
}

function dealerEditsCorrection_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var customerDiscount = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict._0, "customerDiscount"), null));
  if (customerDiscount.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              customerDiscount: customerDiscount._0
            }
          };
  }
  var e = customerDiscount._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".customerDiscount" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

export {
  dealerEditsCorrection_encode ,
  dealerEditsCorrection_decode ,
}
/* No side effect */
