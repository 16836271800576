// Generated by ReScript, PLEASE EDIT WITH CARE


var tutorialsTitle = {
  id: "d95a768b-acaa-4ae6-a9c0-fa1f7e6c6b0e",
  defaultMessage: "Tutoriels"
};

export {
  tutorialsTitle ,
}
/* No side effect */
