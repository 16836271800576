// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Button$DvmAdminFrontendFr from "../../../../../../../../common/components/button/App_Button.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../../../intl/messages/Messages_Common.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Flash_Edit_Version_Perf_New_Forms$DvmAdminFrontendFr from "./Flash_Edit_Version_Perf_New_Forms.bs.js";

function Flash_Edit_Version_Perf_New_Inputs(Props) {
  var selectedVersionsListFiltered = Props.selectedVersionsListFiltered;
  var selectedVersionsList = Props.selectedVersionsList;
  var setSelectedVersionsList = Props.setSelectedVersionsList;
  var flash = Props.flash;
  var intl = ReactIntl.useIntl();
  var numberOfFilled = Belt_List.keep(selectedVersionsList, (function (x) {
          if (Belt_Option.isSome(x.maxBonus)) {
            return Belt_Option.isSome(x.startDate);
          } else {
            return false;
          }
        }));
  var form = Flash_Edit_Version_Perf_New_Forms$DvmAdminFrontendFr.NewVersionsAddForm.useForm(Flash_Edit_Version_Perf_New_Forms$DvmAdminFrontendFr.initialStateNewVersionsAddForm, {
        flashStartDate: flash.orderStartDate,
        flashEndDate: flash.orderEndDate
      }, (function (param, submissionCallbacks) {
          var startDate = param.startDate;
          var amount = param.amount;
          Curry._1(setSelectedVersionsList, (function (selected) {
                  return Belt_List.map(selected, (function (element) {
                                return Belt_Option.mapWithDefault(Belt_List.getBy(selectedVersionsListFiltered, (function (y) {
                                                  return y.modelVersionID === element.modelVersionID;
                                                })), element, (function (x) {
                                              return {
                                                      modelVersionID: x.modelVersionID,
                                                      model: x.model,
                                                      modelName: x.modelName,
                                                      version: x.version,
                                                      versionName: x.versionName,
                                                      maxBonus: amount,
                                                      startDate: Caml_option.some(startDate),
                                                      endDate: x.endDate,
                                                      followingStartDate: x.followingStartDate,
                                                      previousEndDate: x.previousEndDate,
                                                      categoryID: x.categoryID,
                                                      actionID: x.actionID
                                                    };
                                            }));
                              }));
                }));
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
        }));
  var tmp = {
    onChange: Curry._1(form.updateAmount, (function (input, amount) {
            return {
                    amount: amount,
                    startDate: input.startDate
                  };
          })),
    value: form.input.amount,
    label: Messages_Common$DvmAdminFrontendFr.amount,
    InputProps: {
      startAdornment: React.createElement(Core.InputAdornment, {
            children: "€",
            position: "start"
          })
    },
    inputType: "number"
  };
  if (form.amountResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.amountResult);
  }
  var tmp$1 = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    amount: input.amount,
                    startDate: startDate
                  };
          }))
  };
  if (form.startDateResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  alignItems: "flex-start",
                  children: null,
                  container: true,
                  spacing: 3
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$1),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                            label: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.applyToResults),
                            color: "secondary",
                            size: "medium",
                            type_: "submit"
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                            children: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.remainingToEdit, {
                                  amount: Belt_List.length(numberOfFilled),
                                  total: Belt_List.length(selectedVersionsList)
                                }),
                            variant: "bodyBig"
                          }),
                      item: true
                    })));
}

var Form;

var Types;

var make = Flash_Edit_Version_Perf_New_Inputs;

export {
  Form ,
  Types ,
  make ,
}
/* react Not a pure module */
