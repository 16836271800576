// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as Contract_Errors_Api$DvmAdminFrontendFr from "./Contract_Errors_Api.bs.js";
import * as Contract_Errors_Router$DvmAdminFrontendFr from "./Contract_Errors_Router.bs.js";
import * as Messages_Contract_Errors$DvmAdminFrontendFr from "../../../intl/messages/contract/Messages_Contract_Errors.bs.js";

function Contract_Errors_Menu_Icon(Props) {
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match[1];
  var result = match[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  React.useEffect((function () {
          Contract_Errors_Api$DvmAdminFrontendFr.fetchAttachmentErrorsSearch(userManager, setResult, "?limit=1&offset=0", selectedRole);
        }), [url]);
  var tmp;
  if (typeof result === "number" || result.TAG !== /* Ok */0) {
    tmp = null;
  } else {
    var total = result._0.total;
    tmp = total > 0 ? React.createElement(Core.Tooltip, {
            arrow: true,
            children: React.createElement("div", undefined, React.createElement(Core.Button, {
                      onClick: (function (param) {
                          Contract_Errors_Router$DvmAdminFrontendFr.routeToPage(undefined);
                        }),
                      children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                            size: /* XLarge */4,
                            type_: /* HelpWarning */0,
                            color: "red"
                          })
                    })),
            classes: {
              tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
            },
            placement: "bottom",
            title: React.createElement("p", undefined, intl.formatMessage(Messages_Contract_Errors$DvmAdminFrontendFr.tooltipIconMessage, {
                      total: total
                    }))
          }) : null;
  }
  return React.createElement(Core.Box, {
              children: tmp
            });
}

var Api;

var make = Contract_Errors_Menu_Icon;

export {
  Api ,
  make ,
}
/* react Not a pure module */
