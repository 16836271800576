// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as App_Link$DvmAdminFrontendFr from "../../../common/components/link/App_Link.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../../context/router/Context_Router.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../common/components/App_LabeledText.bs.js";
import * as Grid_Edit_Modal$DvmAdminFrontendFr from "../edit/Grid_Edit_Modal.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as ModelVersion_Grid_Router$DvmAdminFrontendFr from "../modelVersionGrid/ModelVersion_Grid_Router.bs.js";
import * as Messages_ModelVersionGrid$DvmAdminFrontendFr from "../../../intl/messages/modelVersionGrid/Messages_ModelVersionGrid.bs.js";
import * as ModelVersion_Grid_Manage_Router$DvmAdminFrontendFr from "./ModelVersion_Grid_Manage_Router.bs.js";
import * as Messages_ModelVersionGrid_Manage$DvmAdminFrontendFr from "../../../intl/messages/modelVersionGrid/Messages_ModelVersionGrid_Manage.bs.js";

function ModelVersion_Grid_Manage_Detail(Props) {
  var intl = ReactIntl.useIntl();
  var match = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match[1];
  var modelVersionGridManage = match[0].modelVersionGridManage;
  var match$1 = React.useState(function () {
        return null;
      });
  var setModal = match$1[1];
  React.useEffect((function () {
          if (Belt_Option.isNone(modelVersionGridManage)) {
            ModelVersion_Grid_Manage_Router$DvmAdminFrontendFr.routeToMVGrid(undefined);
          }
          
        }), [modelVersionGridManage]);
  return React.createElement(React.Fragment, undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  gutterBottom: true,
                  children: intl.formatMessage(Messages_ModelVersionGrid_Manage$DvmAdminFrontendFr.manageTitle),
                  variant: "h3"
                }), modelVersionGridManage !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                        children: null,
                        container: true,
                        direction: "row",
                        style: {
                          backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight1,
                          padding: "25px 10px"
                        }
                      }, React.createElement(Core.Grid, {
                            children: null,
                            container: true,
                            item: true,
                            md: Grid$Mui.Md[6],
                            sm: Grid$Mui.Sm[12]
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                      gutterBottom: true,
                                      children: intl.formatMessage(Messages_ModelVersionGrid_Manage$DvmAdminFrontendFr.manageSubTitle),
                                      variant: "h3"
                                    }),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: null,
                                container: true,
                                item: true,
                                justifyContent: "space-evenly",
                                spacing: 1
                              }, React.createElement(Core.Grid, {
                                    children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                          label: Messages_Common$DvmAdminFrontendFr.budget,
                                          children: modelVersionGridManage.budgetType
                                        }),
                                    item: true
                                  }), React.createElement(Core.Grid, {
                                    children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                          label: Messages_ModelVersionGrid_Manage$DvmAdminFrontendFr.contractId,
                                          children: modelVersionGridManage.contractId
                                        }),
                                    item: true
                                  }), React.createElement(Core.Grid, {
                                    children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                          label: Messages_ModelVersionGrid$DvmAdminFrontendFr.labelContract,
                                          children: modelVersionGridManage.contractLabel
                                        }),
                                    item: true
                                  }))), React.createElement(Core.Grid, {
                            children: null,
                            container: true,
                            direction: "column",
                            item: true,
                            justifyContent: "space-evenly",
                            md: Grid$Mui.Md[6],
                            sm: Grid$Mui.Sm[12],
                            spacing: 1
                          }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                gutterBottom: true,
                                children: intl.formatMessage(Messages_ModelVersionGrid_Manage$DvmAdminFrontendFr.actions),
                                variant: "h3"
                              }), React.createElement(Core.Grid, {
                                alignItems: "center",
                                children: null,
                                container: true,
                                item: true,
                                spacing: 5
                              }, React.createElement(Core.Grid, {
                                    children: React.createElement(App_Link$DvmAdminFrontendFr.make, {
                                          onClick: (function (param) {
                                              Curry._1(dispatch, {
                                                    TAG: /* StoreModelVersionGridFilter */1,
                                                    _0: {
                                                      budget: modelVersionGridManage.budgetType,
                                                      contractId: modelVersionGridManage.contractId,
                                                      contractLabel: modelVersionGridManage.contractLabel
                                                    }
                                                  });
                                              ModelVersion_Grid_Router$DvmAdminFrontendFr.routeToModelVersionPage(undefined);
                                            }),
                                          label: intl.formatMessage(Messages_ModelVersionGrid_Manage$DvmAdminFrontendFr.viewGrid),
                                          icon: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                size: /* Large */3,
                                                type_: /* Show */59
                                              }),
                                          color: "blue"
                                        }),
                                    item: true
                                  }), React.createElement(Core.Grid, {
                                    children: React.createElement(App_Link$DvmAdminFrontendFr.make, {
                                          onClick: (function (param) {
                                              Curry._1(setModal, (function (param) {
                                                      return React.createElement(Grid_Edit_Modal$DvmAdminFrontendFr.make, {
                                                                  onClose: (function (param) {
                                                                      Curry._1(setModal, (function (param) {
                                                                              return null;
                                                                            }));
                                                                    }),
                                                                  whatTable: /* Model */0,
                                                                  whatGrid: /* ModelVersionGrid */0,
                                                                  budgetOrID: modelVersionGridManage.contractId,
                                                                  budget: modelVersionGridManage.budgetType
                                                                });
                                                    }));
                                            }),
                                          label: intl.formatMessage(Messages_ModelVersionGrid_Manage$DvmAdminFrontendFr.modifyGrid),
                                          icon: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                size: /* Large */3,
                                                type_: /* Edit */60
                                              })
                                        }),
                                    item: true
                                  }), React.createElement(Core.Grid, {
                                    children: React.createElement(App_Link$DvmAdminFrontendFr.make, {
                                          label: intl.formatMessage(Messages_ModelVersionGrid_Manage$DvmAdminFrontendFr.createGridJiraButton),
                                          icon: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                size: /* Large */3,
                                                type_: /* More */17
                                              }),
                                          target: "_blank",
                                          href: "https://jira.dt.renault.com/secure/RapidBoard.jspa?rapidView=16416&projectKey=IRN072019&view=detail&selectedIssue=IRN072019-249"
                                        }),
                                    item: true
                                  })))), match$1[0]) : null);
}

var make = ModelVersion_Grid_Manage_Detail;

export {
  make ,
}
/* react Not a pure module */
