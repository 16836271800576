// Generated by ReScript, PLEASE EDIT WITH CARE


var title = {
  id: "246daf36-4367-4a08-b6f7-ccc73ab26f2a",
  defaultMessage: "Gestion des catégories"
};

var createNewCategory = {
  id: "c9b3d4ee-dc07-46f4-b9a7-cba2b3c4b116",
  defaultMessage: "Créer Une Nouvelle Catégorie"
};

var searchCategoryHeader = {
  id: "b50b87fc-8913-4b69-b0b7-64f8712a2f8d",
  defaultMessage: "Rechercher une catégorie existante"
};

var listHeaderVersions = {
  id: "9ee78050-fa76-4aa7-81c9-050c453ac724",
  defaultMessage: "Versions"
};

var versions = {
  id: "e7de1c55-78f9-479a-9819-aa8e6644e583",
  defaultMessage: "{count, plural, =0 {0 versions} one {# version} two {# versions} few {# versions} many {# versions} other {# versions}}"
};

var editTitle = {
  id: "2c5e26bd-3797-425a-8b35-507a6abd6fa4",
  defaultMessage: "Informations Catégorie"
};

var categoryNameLabel = {
  id: "99291d39-0bf4-407d-997a-ca6754ee54f1",
  defaultMessage: "Nom de la catégorie"
};

var datesTitle = {
  id: "37894a36-d6bb-4ccd-b75c-a1c4905fff0b",
  defaultMessage: "Date d’application des versions de la catégorie"
};

var startDateLabel = {
  id: "25ca7a96-7a20-40cd-9851-67a5641e9c20",
  defaultMessage: "Date de début"
};

var endDateLabel = {
  id: "8baac8fe-1145-4004-aec4-75ab51948789",
  defaultMessage: "Date de fin"
};

var editVersionsTitle = {
  id: "4c9657ae-3daf-4171-b7f5-1f6d609fb005",
  defaultMessage: "Gérer les versions de la catégorie : {categoryCode} - {categoryName}"
};

var assignVersions = {
  id: "6c653cef-c203-4818-a74c-96e311f0346c",
  defaultMessage: "ajouter des versions"
};

var assignVersionsTitle = {
  id: "7fe6f3e2-602a-4ff8-a5c7-e2f44661b5b8",
  defaultMessage: "Définir les versions de la catégorie : {categoryCode} {categoryName}"
};

var selectVersionsTitle = {
  id: "cf77de7a-b86c-42c8-8015-f2446a8745ba",
  defaultMessage: "Sélectionner dans la tableau les versions {model} à associer à la catégorie."
};

var versionName = {
  id: "cf5f1728-a0c1-4f60-bc54-e4bc2a8988a6",
  defaultMessage: "Libellé commercial"
};

var motorisation = {
  id: "cabb8224-e812-4695-b1fc-eebbf29852aa",
  defaultMessage: "Energie"
};

var asCategory = {
  id: "05ffe5ce-af2c-461c-b460-cfc536db3125",
  defaultMessage: "même date que la catégorie"
};

var createCategory = {
  id: "e95370fb-4feb-41af-997b-7ebb7ad59dd7",
  defaultMessage: "Créer la catégorie"
};

var categoryCreated = {
  id: "51d32189-4650-4923-995d-c2ee5fddfd32",
  defaultMessage: "La catégorie a bien été créée "
};

var categoryNameAsModel = {
  id: "d23483f1-23b7-4422-bb45-ec90ee715f39",
  defaultMessage: "Code catégorie identique au code Modèle"
};

var backToCategoryList = {
  id: "45b8eb9f-c953-43f2-9144-fe3b35c971e5",
  defaultMessage: "RETOUR À LA LISTE"
};

var periodSeletion = {
  id: "299eaf2b-1985-4360-8747-b055f15e5a46",
  defaultMessage: "Selectionner une période disponible"
};

var sameAsCategory = {
  id: "85c54d21-7a53-4aae-a628-dfb4fcce578a",
  defaultMessage: "Identique à la catégorie"
};

var versionsToSave = {
  id: "c68e02a2-921e-403d-92b1-cf05836d2d70",
  defaultMessage: "{count} versions seront enregistrées"
};

var versionsTaken = {
  id: "9d5585f6-576a-4f5d-b724-88a2cd0360f3",
  defaultMessage: "Version prise en charge:"
};

var pageHasErrors = {
  id: "5000ccd1-151f-4431-9cd4-ffa2390f9e24",
  defaultMessage: "Certaines versions de cette page ont des erreurs"
};

var datesModifyError = {
  id: "ecda9e4c-3fee-441b-bdea-316c9dad45f2",
  defaultMessage: "Date postérieure à la date de début d’application"
};

var includeInactiveCategories = {
  id: "994207be-7225-4750-b484-976f6510cb6e",
  defaultMessage: "Inclure les anciennes catégories"
};

var applicationDates = {
  id: "4a70e5fa-3a33-4393-9078-7ce9aaf47082",
  defaultMessage: "Date d’applications"
};

export {
  title ,
  createNewCategory ,
  searchCategoryHeader ,
  listHeaderVersions ,
  versions ,
  editTitle ,
  categoryNameLabel ,
  datesTitle ,
  startDateLabel ,
  endDateLabel ,
  editVersionsTitle ,
  assignVersions ,
  assignVersionsTitle ,
  selectVersionsTitle ,
  versionName ,
  motorisation ,
  asCategory ,
  createCategory ,
  categoryCreated ,
  categoryNameAsModel ,
  backToCategoryList ,
  periodSeletion ,
  sameAsCategory ,
  versionsToSave ,
  versionsTaken ,
  pageHasErrors ,
  datesModifyError ,
  includeInactiveCategories ,
  applicationDates ,
}
/* No side effect */
