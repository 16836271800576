// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Core from "@material-ui/core";
import * as Messages_Flash_Search$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Search.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../common/components/input/App_DateInputFormField.bs.js";

function Flash_Search_Modal_Date_Box(Props) {
  var disabledOpt = Props.disabled;
  var onChangeFrom = Props.onChangeFrom;
  var onChangeTo = Props.onChangeTo;
  var dateFrom = Props.dateFrom;
  var dateTo = Props.dateTo;
  var className = Props.className;
  var startOrEnd = Props.startOrEnd;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  return React.createElement(Core.Grid, {
              children: React.createElement(Core.Grid, {
                    children: null,
                    container: true,
                    item: true,
                    spacing: 1,
                    wrap: "nowrap"
                  }, React.createElement(Core.Grid, {
                        children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, {
                              label: startOrEnd === "start" ? Messages_Flash_Search$DvmAdminFrontendFr.startDateFrom : Messages_Flash_Search$DvmAdminFrontendFr.endDateFrom,
                              value: dateFrom,
                              onChange: Curry.__1(onChangeFrom),
                              disabled: disabled,
                              className: className
                            }),
                        item: true
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, {
                              label: startOrEnd === "start" ? Messages_Flash_Search$DvmAdminFrontendFr.startDateTo : Messages_Flash_Search$DvmAdminFrontendFr.endDateTo,
                              value: dateTo,
                              onChange: Curry.__1(onChangeTo),
                              disabled: disabled,
                              className: className
                            }),
                        item: true
                      })),
              container: true,
              item: true
            });
}

var make = Flash_Search_Modal_Date_Box;

export {
  make ,
}
/* react Not a pure module */
