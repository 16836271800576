// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as Grid_Types$DvmAdminFrontendFr from "../Grid_Types.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../../../common/types/App_Types_Input.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Grid_Edit_Api_Check$DvmAdminFrontendFr from "./Grid_Edit_Api_Check.bs.js";

function getEndpoint(whatGrid, whatTable, budget, variant) {
  if (whatGrid) {
    var tmp;
    switch (whatTable) {
      case /* Model */0 :
          tmp = "/margins/model";
          break;
      case /* Version */1 :
          tmp = "/margins/modelversion";
          break;
      case /* GridID */2 :
          tmp = "/margins/avs";
          break;
      
    }
    return tmp + Belt_Option.mapWithDefault(variant, "", (function (x) {
                  return "/" + x;
                }));
  }
  var tmp$1;
  switch (whatTable) {
    case /* Model */0 :
        tmp$1 = "/" + Grid_Types$DvmAdminFrontendFr.modelGridEndpoint + "";
        break;
    case /* Version */1 :
        tmp$1 = "/" + Grid_Types$DvmAdminFrontendFr.versionGridEndpoint + "";
        break;
    case /* GridID */2 :
        tmp$1 = "";
        break;
    
  }
  return tmp$1 + Belt_Option.mapWithDefault(variant, "", (function (x) {
                return "/" + x;
              })) + ("/" + budget + "");
}

function queryForAdjacentDates(userManager, selectedRole, whatTable, whatGrid, budget, modelsTable, budgetOrID, submissionCallbacks, setResult) {
  RestApi$DvmAdminFrontendFr.call("POST", Grid_Types$DvmAdminFrontendFr.Margins.editList_decode, {
          TAG: /* Json */0,
          _0: Grid_Types$DvmAdminFrontendFr.Margins.editList_encode(Belt_List.map(modelsTable.content, (function (x) {
                      return {
                              budget: budget,
                              model: x.model,
                              version: x.version,
                              avsMarginGridID: x.avsMarginGridID,
                              categoryID: x.categoryID,
                              discount: x.discount,
                              margin: x.margin,
                              previousEndDate: x.previousEndDate,
                              startDate: x.startDate,
                              endDate: x.endDate,
                              hasExceptions: x.hasExceptions,
                              followingStartDate: x.followingStartDate,
                              acquisitionType: x.acquisitionType,
                              contractID: budgetOrID,
                              categoryName: x.categoryName
                            };
                    })))
        }, userManager, selectedRole, getEndpoint(whatGrid, whatTable, budget, "adjacent-dates")).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var grids = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: {
                                  content: Belt_List.map(grids, (function (x) {
                                          return {
                                                  budget: budget,
                                                  model: x.model,
                                                  version: x.version,
                                                  avsMarginGridID: x.avsMarginGridID,
                                                  categoryID: x.categoryID,
                                                  discount: x.discount,
                                                  margin: x.margin,
                                                  previousEndDate: x.previousEndDate,
                                                  startDate: x.startDate,
                                                  endDate: x.endDate,
                                                  hasExceptions: x.hasExceptions,
                                                  followingStartDate: x.followingStartDate,
                                                  acquisitionType: x.acquisitionType,
                                                  contractID: budgetOrID,
                                                  categoryName: x.categoryName
                                                };
                                        })),
                                  total: modelsTable.total
                                }
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchMarginSearch */0);
              }));
        return Curry._1(setResult, (function (param) {
                      return {
                              TAG: /* Error */1,
                              _0: error
                            };
                    }));
      });
}

function fetchSearch(submissionCallbacks, userManager, setResult, queryParams, whatTable, whatGrid, budget, budgetOrID, selectedRole, withClosedModels) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var tmp;
  if (whatGrid) {
    switch (whatTable) {
      case /* Model */0 :
          tmp = "/margins/model" + queryParams + "";
          break;
      case /* Version */1 :
          tmp = "/margins/modelversion" + queryParams + "";
          break;
      case /* GridID */2 :
          tmp = "/margins/avs" + queryParams + "";
          break;
      
    }
  } else {
    switch (whatTable) {
      case /* Model */0 :
          tmp = "/" + Grid_Types$DvmAdminFrontendFr.modelGridEndpoint + "/" + budget + "" + queryParams + "";
          break;
      case /* Version */1 :
          tmp = "/" + Grid_Types$DvmAdminFrontendFr.versionGridEndpoint + "/" + budget + "" + queryParams + "";
          break;
      case /* GridID */2 :
          tmp = "";
          break;
      
    }
  }
  RestApi$DvmAdminFrontendFr.call("GET", Grid_Types$DvmAdminFrontendFr.Margins.searchResult_decode, undefined, userManager, selectedRole, tmp + ("&withClosedModels=" + (
            withClosedModels ? "true" : "false"
          ) + "")).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return queryForAdjacentDates(userManager, selectedRole, whatTable, whatGrid, budget, result._0, budgetOrID, submissionCallbacks, setResult);
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchMarginSearch */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function overwriteMarginRows(alert, userManager, setResult, whatTable, whatGrid, budget, selectedRole, rows) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var onlyChangedNew = Belt_List.map(Grid_Edit_Api_Check$DvmAdminFrontendFr.getOnlyChangedRows(rows), (function (x) {
          return x.new;
        }));
  if (Belt_List.length(Grid_Edit_Api_Check$DvmAdminFrontendFr.getOnlyChangedRows(rows)) === 0) {
    return Curry._1(setResult, (function (param) {
                  return {
                          TAG: /* Ok */0,
                          _0: undefined
                        };
                }));
  } else if (Grid_Edit_Api_Check$DvmAdminFrontendFr.checkForOverlaps(rows)) {
    Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
              message: {
                TAG: /* RestError */2,
                _0: App_Types_Result$DvmAdminFrontendFr.Rest.makeError(undefined, undefined, undefined, "9ff6a009-181a-46a6-81de-e47e68753e69")
              }
            }));
    return Curry._1(setResult, (function (param) {
                  return {
                          TAG: /* Error */1,
                          _0: App_Types_Result$DvmAdminFrontendFr.Rest.makeError(undefined, undefined, undefined, "9ff6a009-181a-46a6-81de-e47e68753e69")
                        };
                }));
  } else {
    Promise.all(Belt_List.toArray(Belt_List.map(onlyChangedNew, (function (x) {
                      return RestApi$DvmAdminFrontendFr.call("GET", Grid_Types$DvmAdminFrontendFr.Margins.searchResult_decode, undefined, userManager, selectedRole, Grid_Edit_Api_Check$DvmAdminFrontendFr.getQuery(whatTable, whatGrid, {
                                      hd: x,
                                      tl: /* [] */0
                                    }));
                    })))).then(function (results) {
          var someError = Belt_List.getBy(Belt_List.fromArray(results), (function (result) {
                  if (result.TAG === /* OkResult */0) {
                    return false;
                  } else {
                    return true;
                  }
                }));
          var okResults = Belt_List.keepMap(Belt_List.fromArray(results), (function (result) {
                  if (result.TAG === /* OkResult */0) {
                    return result._0;
                  }
                  
                }));
          var numberOfOverlaps = Belt_List.reduce(okResults, 0, (function (acc, item) {
                  return acc + Belt_List.length(Grid_Edit_Api_Check$DvmAdminFrontendFr.removeOriginalsFromQuery(item.content, Belt_List.map(Grid_Edit_Api_Check$DvmAdminFrontendFr.getOnlyChangedRows(rows), (function (x) {
                                        return x.original;
                                      })))) | 0;
                }));
          Belt_Option.mapWithDefault(someError, numberOfOverlaps > 0 ? (Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                            message: {
                              TAG: /* RestError */2,
                              _0: App_Types_Result$DvmAdminFrontendFr.Rest.makeError(undefined, undefined, undefined, "9ff6a009-181a-46a6-81de-e47e68753e69")
                            }
                          })), Curry._1(setResult, (function (param) {
                          return {
                                  TAG: /* Error */1,
                                  _0: App_Types_Result$DvmAdminFrontendFr.Rest.makeError(undefined, undefined, undefined, "9ff6a009-181a-46a6-81de-e47e68753e69")
                                };
                        }))) : (RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", {
                          TAG: /* Json */0,
                          _0: Grid_Types$DvmAdminFrontendFr.Margins.editList_encode(Belt_List.map(Grid_Edit_Api_Check$DvmAdminFrontendFr.getOnlyChangedRows(rows), (function (x) {
                                      return x.original;
                                    })))
                        }, userManager, selectedRole, getEndpoint(whatGrid, whatTable, budget, undefined)).then(function (result) {
                        if (result.TAG === /* OkResult */0) {
                          RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
                                  TAG: /* Json */0,
                                  _0: Grid_Types$DvmAdminFrontendFr.Margins.editList_encode(Belt_List.map(Grid_Edit_Api_Check$DvmAdminFrontendFr.getOnlyChangedRows(rows), (function (x) {
                                              return x.new;
                                            })))
                                }, userManager, selectedRole, getEndpoint(whatGrid, whatTable, budget, undefined)).then(function (result) {
                                if (result.TAG === /* OkResult */0) {
                                  return Curry._1(setResult, (function (param) {
                                                return {
                                                        TAG: /* Ok */0,
                                                        _0: undefined
                                                      };
                                              }));
                                }
                                var error = result._0;
                                Curry._1(setResult, (function (param) {
                                        return {
                                                TAG: /* Error */1,
                                                _0: error
                                              };
                                      }));
                              });
                          return ;
                        }
                        var error = result._0;
                        Curry._1(setResult, (function (param) {
                                return {
                                        TAG: /* Error */1,
                                        _0: error
                                      };
                              }));
                      }), undefined), (function (x) {
                  if (x.TAG === /* OkResult */0) {
                    return Curry._1(setResult, (function (param) {
                                  return {
                                          TAG: /* Ok */0,
                                          _0: undefined
                                        };
                                }));
                  }
                  var err = x._0;
                  Curry._1(setResult, (function (param) {
                          return {
                                  TAG: /* Error */1,
                                  _0: err
                                };
                        }));
                }));
        });
    return ;
  }
}

function getModelsWithCategories(userManager, setModelsWithCategories, selectedRole) {
  Curry._1(setModelsWithCategories, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.stringList_decode, undefined, userManager, selectedRole, "/category/models").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var models = result._0;
          return Curry._1(setModelsWithCategories, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: models
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setModelsWithCategories, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

var ApiCheck;

export {
  Form ,
  ApiCheck ,
  getEndpoint ,
  queryForAdjacentDates ,
  fetchSearch ,
  overwriteMarginRows ,
  getModelsWithCategories ,
}
/* react Not a pure module */
