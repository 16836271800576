// Generated by ReScript, PLEASE EDIT WITH CARE


var dupicateModalTitle = {
  id: "29cd87a3-bafd-4c83-abf8-4f08f67a2619",
  defaultMessage: "Dupliquer la grille {duplicate}"
};

var duplicateInto = {
  id: "aa6b3ad3-f0c4-4302-8763-7168a1b99220",
  defaultMessage: "Dupliquer vers"
};

var newGrid = {
  id: "9fc69df6-9257-4e2d-8fe7-409fe58ce155",
  defaultMessage: "Nouvelle grille"
};

var existingGrid = {
  id: "130600da-bb43-4484-ad94-08f6dc8a9273",
  defaultMessage: "Grille existante"
};

var duplicateSuccess = {
  id: "8d3206ab-ecf7-41e3-8b7e-66fcacaf2a6c",
  defaultMessage: "La duplication a réussi"
};

var duplicateFail = {
  id: "58c28b46-8fec-4c8f-a9ac-937b0181db26",
  defaultMessage: "La duplication a échoué. Rafraichissez la page et réassayez"
};

var duplicationDates = {
  id: "32274362-83bd-4b4e-a464-61140ef5559d",
  defaultMessage: "Date d’application des marges sur la grille"
};

var periodSelection = {
  id: "0eb72ff2-e69c-49db-b0b3-9a9e19fecc4e",
  defaultMessage: "1. Sélectionner l’année et la période"
};

var duplicateTransferHeader = {
  id: "86aa24e7-9fb4-4ffe-aece-0699bb4451d1",
  defaultMessage: "2. Transverser les catégories à inclure"
};

var duplicateGridID = {
  id: "8b0fd1a8-aa1b-4eb6-9716-1f64d1c1cb82",
  defaultMessage: "Dupliquer {gridID}"
};

export {
  dupicateModalTitle ,
  duplicateInto ,
  newGrid ,
  existingGrid ,
  duplicateSuccess ,
  duplicateFail ,
  duplicationDates ,
  periodSelection ,
  duplicateTransferHeader ,
  duplicateGridID ,
}
/* No side effect */
