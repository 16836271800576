// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../../common/App_Api.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Types_Text$DvmAdminFrontendFr from "../../../../common/types/App_Types_Text.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../common/components/App_OverlaySpinner.bs.js";
import * as Flash_Detail_Styles$DvmAdminFrontendFr from "./Flash_Detail_Styles.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Messages_Flash_Detail$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Detail.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_TextFieldFormInput.bs.js";

var scrollStyle = Css.css(Object.assign({}, {
          maxHeight: "500px",
          overflow: "hidden auto",
          width: "fit-content"
        }, {
          "&::-webkit-scrollbar": {
            background: Theme_Colors$DvmAdminFrontendFr.primaryBrown2,
            width: "10px"
          },
          "&::-webkit-scrollbar-thumb": {
            background: Theme_Colors$DvmAdminFrontendFr.primaryBrown
          }
        }));

function Flash_Detail_Dealer_List(Props) {
  var flash = Props.flash;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var onlyDealers = function (param) {
    return Belt_List.length(Belt_Option.getWithDefault(flash.dealerWithAgents, /* [] */0)) === 0;
  };
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDealers = match$2[1];
  var dealers = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setAgents = match$3[1];
  var agents = match$3[0];
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var setMappedDealers = match$4[1];
  var mappedDealers = match$4[0];
  var match$5 = React.useState(function () {
        return /* [] */0;
      });
  var setMappedAgents = match$5[1];
  var match$6 = React.useState(function () {
        
      });
  var setSelectedDealer = match$6[1];
  var selectedDealer = match$6[0];
  var match$7 = React.useState(function () {
        return "";
      });
  var setDealerFilter = match$7[1];
  var dealerFilter = match$7[0];
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.queryForDealerNames(alert, userManager, setDealers, selectedRole, true, /* [] */0, /* [] */0, /* [] */0, /* [] */0);
          if (onlyDealers(undefined)) {
            
          } else {
            App_Api$DvmAdminFrontendFr.fetchDealersAndAgents(userManager, setAgents, selectedRole, /* [] */0);
          }
        }), []);
  var availableDealers = function (param) {
    return Belt_List.concat(Belt_Option.getWithDefault(flash.dealerIds, /* [] */0), Belt_List.map(Belt_Option.getWithDefault(flash.dealerWithAgents, /* [] */0), (function (x) {
                      return x.parent;
                    })));
  };
  React.useEffect((function () {
          if (typeof dealers !== "number" && dealers.TAG === /* Ok */0) {
            var dealers$1 = dealers._0;
            var mappedDealersList = Belt_List.map(availableDealers(undefined), (function (dealerId) {
                    return Belt_Option.mapWithDefault(Belt_List.getBy(dealers$1, (function (y) {
                                      return y.dealerId === dealerId;
                                    })), {
                                label: {
                                  TAG: /* String */3,
                                  _0: dealerId
                                },
                                value: dealerId
                              }, (function (found) {
                                  return {
                                          label: {
                                            TAG: /* String */3,
                                            _0: found.dealerId + Belt_Option.mapWithDefault(found.locationCommonName, "", (function (name) {
                                                    if (name.length > 0) {
                                                      return " - " + name;
                                                    } else {
                                                      return "";
                                                    }
                                                  }))
                                          },
                                          value: found.dealerId
                                        };
                                }));
                  }));
            Curry._1(setMappedDealers, (function (param) {
                    return mappedDealersList;
                  }));
            Belt_Option.mapWithDefault(Belt_List.get(mappedDealersList, 0), undefined, (function (x) {
                    Curry._1(setSelectedDealer, (function (param) {
                            return x.value;
                          }));
                  }));
          }
          
        }), [dealers]);
  React.useEffect((function () {
          if (!onlyDealers(undefined) && typeof agents !== "number" && agents.TAG === /* Ok */0) {
            var agents$1 = agents._0;
            Curry._1(setMappedAgents, (function (param) {
                    return Belt_Option.mapWithDefault(selectedDealer, /* [] */0, (function (selDealer) {
                                  return Belt_Option.mapWithDefault(Belt_List.get(Belt_List.keep(Belt_Option.getWithDefault(flash.dealerWithAgents, /* [] */0), (function (x) {
                                                        return x.parent === selDealer;
                                                      })), 0), /* [] */0, (function (parentContent) {
                                                return Belt_Option.mapWithDefault(Belt_List.getBy(agents$1, (function (x) {
                                                                  return x.parent === selDealer;
                                                                })), parentContent.child, (function (agents) {
                                                              return Belt_List.map(parentContent.child, (function (child) {
                                                                            return Belt_Option.mapWithDefault(Belt_List.getBy(agents.child, (function (x) {
                                                                                              return x.paramOne === child;
                                                                                            })), child, (function (x) {
                                                                                          return x.paramOne + "" + Belt_Option.mapWithDefault(x.paramTwo, "", (function (name) {
                                                                                                        if (name.length > 0) {
                                                                                                          return " - " + name;
                                                                                                        } else {
                                                                                                          return "";
                                                                                                        }
                                                                                                      }));
                                                                                        }));
                                                                          }));
                                                            }));
                                              }));
                                }));
                  }));
          }
          
        }), [
        selectedDealer,
        agents
      ]);
  var filteredDealers = function (param) {
    return Belt_List.keep(mappedDealers, (function (x) {
                  if (dealerFilter.length > 0) {
                    return App_Types_Text$DvmAdminFrontendFr.toString(intl, x.label).toLowerCase().includes(dealerFilter.toLowerCase());
                  } else {
                    return true;
                  }
                }));
  };
  return React.createElement(React.Fragment, undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  color: "primary",
                  children: intl.formatMessage(onlyDealers(undefined) ? Messages_Flash_Detail$DvmAdminFrontendFr.dealerInfo : Messages_Flash_Edit$DvmAdminFrontendFr.dealerAccordionTitle),
                  variant: "h3"
                }), React.createElement(Core.Grid, {
                  children: null,
                  className: Flash_Detail_Styles$DvmAdminFrontendFr.detailBox,
                  container: true,
                  direction: "column",
                  item: true
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: (function (val) {
                                Curry._1(setDealerFilter, (function (param) {
                                        return val;
                                      }));
                              }),
                            value: dealerFilter,
                            style: {
                              paddingBottom: "20px",
                              width: "50%"
                            },
                            label: Messages_Common$DvmAdminFrontendFr.dealer
                          }),
                      item: true
                    }), React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                      show: App_Types_Result$DvmAdminFrontendFr.isPending(dealers) || App_Types_Result$DvmAdminFrontendFr.isPending(agents),
                      children: onlyDealers(undefined) ? React.createElement(Core.Grid, {
                              children: React.createElement(Core.Grid, {
                                    children: Belt_List.toArray(Belt_List.map(filteredDealers(undefined), (function (dealer) {
                                                return React.createElement(Core.Grid, {
                                                            children: React.createElement(Core.Chip, {
                                                                  label: App_Types_Text$DvmAdminFrontendFr.toString(intl, dealer.label),
                                                                  size: "small"
                                                                }),
                                                            item: true
                                                          });
                                              }))),
                                    className: scrollStyle,
                                    container: true,
                                    direction: "column",
                                    item: true,
                                    spacing: 1,
                                    wrap: "nowrap",
                                    xs: Grid$Mui.Xs[6]
                                  }),
                              container: true,
                              item: true,
                              spacing: 3
                            }) : React.createElement(Core.Grid, {
                              children: React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                                        children: Belt_List.toArray(Belt_List.map(filteredDealers(undefined), (function (dealer) {
                                                    var tmp = {
                                                      textAlign: "left",
                                                      width: "100%",
                                                      justifyContent: "flex-start"
                                                    };
                                                    var tmp$1 = Belt_Option.mapWithDefault(selectedDealer, false, (function (x) {
                                                            return x === dealer.value;
                                                          })) ? "solid 5px " + Theme_Colors$DvmAdminFrontendFr.primaryBrown + "" : undefined;
                                                    if (tmp$1 !== undefined) {
                                                      tmp.borderBottom = tmp$1;
                                                    }
                                                    return React.createElement(Core.Grid, {
                                                                children: React.createElement(Core.Button, {
                                                                      onClick: (function (param) {
                                                                          Curry._1(setSelectedDealer, (function (param) {
                                                                                  return dealer.value;
                                                                                }));
                                                                        }),
                                                                      style: tmp,
                                                                      children: App_Types_Text$DvmAdminFrontendFr.toString(intl, dealer.label)
                                                                    }),
                                                                item: true
                                                              });
                                                  }))),
                                        className: scrollStyle,
                                        container: true,
                                        direction: "column",
                                        item: true,
                                        spacing: 1,
                                        wrap: "nowrap",
                                        xs: Grid$Mui.Xs[6]
                                      }), React.createElement(Core.Grid, {
                                        children: Belt_List.toArray(Belt_List.map(match$5[0], (function (agent) {
                                                    return React.createElement(Core.Grid, {
                                                                children: React.createElement(Core.Chip, {
                                                                      label: agent,
                                                                      size: "small"
                                                                    }),
                                                                item: true
                                                              });
                                                  }))),
                                        className: scrollStyle,
                                        container: true,
                                        direction: "column",
                                        item: true,
                                        spacing: 1,
                                        wrap: "nowrap",
                                        xs: Grid$Mui.Xs[6]
                                      })),
                              container: true,
                              item: true,
                              spacing: 3
                            })
                    })));
}

var make = Flash_Detail_Dealer_List;

export {
  scrollStyle ,
  make ,
}
/* scrollStyle Not a pure module */
