// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../common/utils/Utils_Form.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../intl/messages/Messages_Error_Inputs.bs.js";
import * as Messages_Contract_Manager_Edit$DvmAdminFrontendFr from "../../../intl/messages/contractManagers/Messages_Contract_Manager_Edit.bs.js";

var validators_managerID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.managerID);
    })
};

var validators_managerName = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.managerName);
    })
};

var validators_budgetRows = {
  collection: (function (param) {
      if (param.budgetRows.length !== 0) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: Messages_Contract_Manager_Edit$DvmAdminFrontendFr.editNoRowError
              };
      }
    }),
  fields: {
    endDate: {
      strategy: /* OnFirstChange */1,
      validate: (function (param, at) {
          var dateFields = Belt_Option.map(Belt_Array.get(param.budgetRows, at), (function (param) {
                  return [
                          param.startDate,
                          param.endDate
                        ];
                }));
          if (dateFields !== undefined) {
            return Utils_Form$DvmAdminFrontendFr.validateRequiredDateCompare(dateFields[0], dateFields[1], "larger", false, undefined, undefined);
          } else {
            return {
                    TAG: /* Error */1,
                    _0: Messages_Contract_Manager_Edit$DvmAdminFrontendFr.editNoRowError
                  };
          }
        })
    },
    startDate: {
      strategy: /* OnFirstChange */1,
      validate: (function (param, at) {
          var dateFields = Belt_Option.map(Belt_Array.get(param.budgetRows, at), (function (param) {
                  return param.startDate;
                }));
          if (dateFields !== undefined) {
            return Belt_Option.mapWithDefault(Caml_option.valFromOption(dateFields), {
                        TAG: /* Error */1,
                        _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
                      }, (function (date) {
                          if (App_Types_Date$DvmAdminFrontendFr.isValid(date)) {
                            return {
                                    TAG: /* Ok */0,
                                    _0: Caml_option.some(date)
                                  };
                          } else {
                            return {
                                    TAG: /* Error */1,
                                    _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                                  };
                          }
                        }));
          } else {
            return {
                    TAG: /* Error */1,
                    _0: Messages_Contract_Manager_Edit$DvmAdminFrontendFr.editNoRowError
                  };
          }
        })
    },
    budgets: {
      strategy: /* OnFirstSuccessOrFirstBlur */3,
      validate: (function (param, at) {
          var budget = Belt_Option.map(Belt_Array.get(param.budgetRows, at), (function (param) {
                  return param.budgets;
                }));
          return Belt_Option.mapWithDefault(budget, {
                      TAG: /* Error */1,
                      _0: Messages_Contract_Manager_Edit$DvmAdminFrontendFr.editNoRowError
                    }, Utils_Form$DvmAdminFrontendFr.validateListRequired);
        })
    }
  }
};

var validators = {
  managerID: validators_managerID,
  managerName: validators_managerName,
  budgetRows: validators_budgetRows
};

function initialFieldsStatuses(input) {
  return {
          managerID: /* Pristine */0,
          managerName: /* Pristine */0,
          budgetRows: Belt_Array.make(input.budgetRows.length, {
                endDate: /* Pristine */0,
                startDate: /* Pristine */0,
                budgets: /* Pristine */0
              })
        };
}

var initialCollectionsStatuses = {
  budgetRows: undefined
};

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: initialCollectionsStatuses,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.managerID;
  var match_0 = match ? match._0 : Curry._1(validators.managerID.validate, input);
  var match$1 = fieldsStatuses.managerName;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.managerName.validate, input);
  var match_0$2 = Curry._1(validators.budgetRows.collection, input);
  var match_1 = Belt_Array.reduceWithIndex(fieldsStatuses.budgetRows, [
        {
          TAG: /* Ok */0,
          _0: []
        },
        []
      ], (function (param, fieldStatus, index) {
          var statuses = param[1];
          var output = param[0];
          var match = fieldStatus.endDate;
          var match_0 = match ? match._0 : Curry._2(validators.budgetRows.fields.endDate.validate, input, index);
          var match$1 = fieldStatus.startDate;
          var match_0$1 = match$1 ? match$1._0 : Curry._2(validators.budgetRows.fields.startDate.validate, input, index);
          var match$2 = fieldStatus.budgets;
          var match_0$2 = match$2 ? match$2._0 : Curry._2(validators.budgetRows.fields.budgets.validate, input, index);
          if (output.TAG === /* Ok */0) {
            var endDateResult = match_0;
            var output$1 = output._0;
            if (endDateResult.TAG === /* Ok */0) {
              var startDateResult = match_0$1;
              if (startDateResult.TAG === /* Ok */0) {
                var budgetsResult = match_0$2;
                if (budgetsResult.TAG === /* Ok */0) {
                  output$1.push({
                        budgets: budgetsResult._0,
                        startDate: startDateResult._0,
                        endDate: endDateResult._0
                      });
                  statuses.push({
                        endDate: /* Dirty */{
                          _0: endDateResult,
                          _1: /* Shown */0
                        },
                        startDate: /* Dirty */{
                          _0: startDateResult,
                          _1: /* Shown */0
                        },
                        budgets: /* Dirty */{
                          _0: budgetsResult,
                          _1: /* Shown */0
                        }
                      });
                  return [
                          {
                            TAG: /* Ok */0,
                            _0: output$1
                          },
                          statuses
                        ];
                }
                
              }
              
            }
            
          }
          statuses.push({
                endDate: /* Dirty */{
                  _0: match_0,
                  _1: /* Shown */0
                },
                startDate: /* Dirty */{
                  _0: match_0$1,
                  _1: /* Shown */0
                },
                budgets: /* Dirty */{
                  _0: match_0$2,
                  _1: /* Shown */0
                }
              });
          return [
                  {
                    TAG: /* Error */1,
                    _0: undefined
                  },
                  statuses
                ];
        }));
  var managerIDResult = match_0;
  var managerIDResult$1;
  if (managerIDResult.TAG === /* Ok */0) {
    var managerNameResult = match_0$1;
    if (managerNameResult.TAG === /* Ok */0) {
      var budgetRowsCollectionResult = match_0$2;
      if (budgetRowsCollectionResult.TAG === /* Ok */0) {
        var match$2 = match_1;
        var budgetRows = match$2[0];
        if (budgetRows.TAG === /* Ok */0) {
          return {
                  TAG: /* Valid */0,
                  output: {
                    managerID: managerIDResult._0,
                    managerName: managerNameResult._0,
                    budgetRows: budgetRows._0
                  },
                  fieldsStatuses: {
                    managerID: /* Dirty */{
                      _0: managerIDResult,
                      _1: /* Shown */0
                    },
                    managerName: /* Dirty */{
                      _0: managerNameResult,
                      _1: /* Shown */0
                    },
                    budgetRows: match$2[1]
                  },
                  collectionsStatuses: {
                    budgetRows: budgetRowsCollectionResult
                  }
                };
        }
        managerIDResult$1 = managerIDResult;
      } else {
        managerIDResult$1 = managerIDResult;
      }
    } else {
      managerIDResult$1 = managerIDResult;
    }
  } else {
    managerIDResult$1 = managerIDResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            managerID: /* Dirty */{
              _0: managerIDResult$1,
              _1: /* Shown */0
            },
            managerName: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            budgetRows: match_1[1]
          },
          collectionsStatuses: {
            budgetRows: match_0$2
          }
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurManagerIDField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.managerID, validators_managerID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  managerID: status,
                                  managerName: init.managerName,
                                  budgetRows: init.budgetRows
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurManagerNameField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.managerName, validators_managerName, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  managerID: init.managerID,
                                  managerName: status,
                                  budgetRows: init.budgetRows
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */2 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */10,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */11,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */5);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */4);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */3 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */4 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */5 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateManagerIDField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.managerID, state.submissionStatus, validators_managerID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            managerID: status,
                                            managerName: init.managerName,
                                            budgetRows: init.budgetRows
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateManagerNameField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.managerName, state.submissionStatus, validators_managerName, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            managerID: init.managerID,
                                            managerName: status,
                                            budgetRows: init.budgetRows
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBudgetRowEndDateField */2 :
                  var index = action._1;
                  var nextInput$2 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses.contents.budgetRows, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$2, index$p, item.startDate, validators_budgetRows.fields.startDate, (function (status) {
                                  var init = nextFieldsStatuses.contents;
                                  return {
                                          managerID: init.managerID,
                                          managerName: init.managerName,
                                          budgetRows: Belt_Array.mapWithIndex(nextFieldsStatuses.contents.budgetRows, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDate: item.endDate,
                                                            startDate: status,
                                                            budgets: item.budgets
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$2, index, nextFieldsStatuses.contents.budgetRows[index].endDate, state.submissionStatus, validators_budgetRows.fields.endDate, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            managerID: init.managerID,
                                            managerName: init.managerName,
                                            budgetRows: Belt_Array.mapWithIndex(nextFieldsStatuses.contents.budgetRows, (function (idx_, item) {
                                                    if (idx_ === index) {
                                                      return {
                                                              endDate: status,
                                                              startDate: item.startDate,
                                                              budgets: item.budgets
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBudgetRowStartDateField */3 :
                  var index$1 = action._1;
                  var nextInput$3 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$1.contents.budgetRows, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$3, index$p, item.endDate, validators_budgetRows.fields.endDate, (function (status) {
                                  var init = nextFieldsStatuses$1.contents;
                                  return {
                                          managerID: init.managerID,
                                          managerName: init.managerName,
                                          budgetRows: Belt_Array.mapWithIndex(nextFieldsStatuses$1.contents.budgetRows, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDate: status,
                                                            startDate: item.startDate,
                                                            budgets: item.budgets
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$1.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$3, index$1, nextFieldsStatuses$1.contents.budgetRows[index$1].startDate, state.submissionStatus, validators_budgetRows.fields.startDate, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            managerID: init.managerID,
                                            managerName: init.managerName,
                                            budgetRows: Belt_Array.mapWithIndex(nextFieldsStatuses$1.contents.budgetRows, (function (idx_, item) {
                                                    if (idx_ === index$1) {
                                                      return {
                                                              endDate: item.endDate,
                                                              startDate: status,
                                                              budgets: item.budgets
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBudgetRowBudgetsField */4 :
                  var index$2 = action._1;
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$4, index$2, state.fieldsStatuses.budgetRows[index$2].budgets, state.submissionStatus, validators_budgetRows.fields.budgets, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            managerID: init.managerID,
                                            managerName: init.managerName,
                                            budgetRows: Belt_Array.mapWithIndex(state.fieldsStatuses.budgetRows, (function (index$p, item) {
                                                    if (index$p === index$2) {
                                                      return {
                                                              endDate: item.endDate,
                                                              startDate: item.startDate,
                                                              budgets: status
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* BlurBudgetRowEndDateField */5 :
                  var index$3 = action._0;
                  var result$2 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$3, state.fieldsStatuses.budgetRows[index$3].endDate, validators_budgetRows.fields.endDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  managerID: init.managerID,
                                  managerName: init.managerName,
                                  budgetRows: Belt_Array.mapWithIndex(state.fieldsStatuses.budgetRows, (function (index$p, item) {
                                          if (index$p === index$3) {
                                            return {
                                                    endDate: status,
                                                    startDate: item.startDate,
                                                    budgets: item.budgets
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBudgetRowStartDateField */6 :
                  var index$4 = action._0;
                  var result$3 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$4, state.fieldsStatuses.budgetRows[index$4].startDate, validators_budgetRows.fields.startDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  managerID: init.managerID,
                                  managerName: init.managerName,
                                  budgetRows: Belt_Array.mapWithIndex(state.fieldsStatuses.budgetRows, (function (index$p, item) {
                                          if (index$p === index$4) {
                                            return {
                                                    endDate: item.endDate,
                                                    startDate: status,
                                                    budgets: item.budgets
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBudgetRowBudgetsField */7 :
                  var index$5 = action._0;
                  var result$4 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$5, state.fieldsStatuses.budgetRows[index$5].budgets, validators_budgetRows.fields.budgets, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  managerID: init.managerID,
                                  managerName: init.managerName,
                                  budgetRows: Belt_Array.mapWithIndex(state.fieldsStatuses.budgetRows, (function (index$p, item) {
                                          if (index$p === index$5) {
                                            return {
                                                    endDate: item.endDate,
                                                    startDate: item.startDate,
                                                    budgets: status
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* AddBudgetRowEntry */8 :
                  var init = state.input;
                  var nextInput_managerID = init.managerID;
                  var nextInput_managerName = init.managerName;
                  var nextInput_budgetRows = Belt_Array.concat(state.input.budgetRows, [action._0]);
                  var nextInput$5 = {
                    managerID: nextInput_managerID,
                    managerName: nextInput_managerName,
                    budgetRows: nextInput_budgetRows
                  };
                  var init$1 = state.fieldsStatuses;
                  var nextFieldsStatuses$2 = {
                    contents: {
                      managerID: init$1.managerID,
                      managerName: init$1.managerName,
                      budgetRows: Belt_Array.concat(state.fieldsStatuses.budgetRows, [{
                              endDate: /* Pristine */0,
                              startDate: /* Pristine */0,
                              budgets: /* Pristine */0
                            }])
                    }
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$2.contents.budgetRows, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$5, index$p, item.endDate, validators_budgetRows.fields.endDate, (function (status) {
                                  var init = nextFieldsStatuses$2.contents;
                                  return {
                                          managerID: init.managerID,
                                          managerName: init.managerName,
                                          budgetRows: Belt_Array.mapWithIndex(nextFieldsStatuses$2.contents.budgetRows, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDate: status,
                                                            startDate: item.startDate,
                                                            budgets: item.budgets
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$2.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$2.contents.budgetRows, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$5, index$p, item.startDate, validators_budgetRows.fields.startDate, (function (status) {
                                  var init = nextFieldsStatuses$2.contents;
                                  return {
                                          managerID: init.managerID,
                                          managerName: init.managerName,
                                          budgetRows: Belt_Array.mapWithIndex(nextFieldsStatuses$2.contents.budgetRows, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDate: item.endDate,
                                                            startDate: status,
                                                            budgets: item.budgets
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$2.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: nextFieldsStatuses$2.contents,
                            collectionsStatuses: {
                              budgetRows: Curry._1(validators_budgetRows.collection, nextInput$5)
                            },
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* RemoveBudgetRowEntry */9 :
                  var index$6 = action._0;
                  var init$2 = state.input;
                  var nextInput_managerID$1 = init$2.managerID;
                  var nextInput_managerName$1 = init$2.managerName;
                  var nextInput_budgetRows$1 = Belt_Array.keepWithIndex(state.input.budgetRows, (function (param, i) {
                          return i !== index$6;
                        }));
                  var nextInput$6 = {
                    managerID: nextInput_managerID$1,
                    managerName: nextInput_managerName$1,
                    budgetRows: nextInput_budgetRows$1
                  };
                  var init$3 = state.fieldsStatuses;
                  var nextFieldsStatuses$3 = {
                    contents: {
                      managerID: init$3.managerID,
                      managerName: init$3.managerName,
                      budgetRows: Belt_Array.keepWithIndex(state.fieldsStatuses.budgetRows, (function (param, i) {
                              return i !== index$6;
                            }))
                    }
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$3.contents.budgetRows, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$6, index$p, item.endDate, validators_budgetRows.fields.endDate, (function (status) {
                                  var init = nextFieldsStatuses$3.contents;
                                  return {
                                          managerID: init.managerID,
                                          managerName: init.managerName,
                                          budgetRows: Belt_Array.mapWithIndex(nextFieldsStatuses$3.contents.budgetRows, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDate: status,
                                                            startDate: item.startDate,
                                                            budgets: item.budgets
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$3.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$3.contents.budgetRows, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$6, index$p, item.startDate, validators_budgetRows.fields.startDate, (function (status) {
                                  var init = nextFieldsStatuses$3.contents;
                                  return {
                                          managerID: init.managerID,
                                          managerName: init.managerName,
                                          budgetRows: Belt_Array.mapWithIndex(nextFieldsStatuses$3.contents.budgetRows, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            endDate: item.endDate,
                                                            startDate: status,
                                                            budgets: item.budgets
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$3.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: nextFieldsStatuses$3.contents,
                            collectionsStatuses: {
                              budgetRows: Curry._1(validators_budgetRows.collection, nextInput$6)
                            },
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */10 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */11 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */12 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateManagerID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateManagerIDField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateManagerName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateManagerNameField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBudgetRowEndDate: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBudgetRowEndDateField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateBudgetRowStartDate: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBudgetRowStartDateField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateBudgetRowBudgets: (function (index, nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBudgetRowBudgetsField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      }),
                    _1: index
                  });
            }),
          blurManagerID: (function (param) {
              Curry._1(dispatch, /* BlurManagerIDField */0);
            }),
          blurManagerName: (function (param) {
              Curry._1(dispatch, /* BlurManagerNameField */1);
            }),
          blurBudgetRowEndDate: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurBudgetRowEndDateField */5,
                    _0: index
                  });
            }),
          blurBudgetRowStartDate: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurBudgetRowStartDateField */6,
                    _0: index
                  });
            }),
          blurBudgetRowBudgets: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* BlurBudgetRowBudgetsField */7,
                    _0: index
                  });
            }),
          managerIDResult: Formality.exposeFieldResult(state.fieldsStatuses.managerID),
          managerNameResult: Formality.exposeFieldResult(state.fieldsStatuses.managerName),
          budgetRowEndDateResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.budgetRows[index].endDate);
            }),
          budgetRowStartDateResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.budgetRows[index].startDate);
            }),
          budgetRowBudgetsResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.budgetRows[index].budgets);
            }),
          addBudgetRow: (function (entry) {
              Curry._1(dispatch, {
                    TAG: /* AddBudgetRowEntry */8,
                    _0: entry
                  });
            }),
          removeBudgetRow: (function (index) {
              Curry._1(dispatch, {
                    TAG: /* RemoveBudgetRowEntry */9,
                    _0: index
                  });
            }),
          budgetRowsResult: state.collectionsStatuses.budgetRows,
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              if (Belt_Array.every(state.fieldsStatuses.budgetRows, (function (item) {
                        if (item.endDate || item.startDate || item.budgets) {
                          return true;
                        } else {
                          return false;
                        }
                      }))) {
                return true;
              }
              var match = state.fieldsStatuses;
              if (match.managerID || match.managerName) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */2);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */3);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */4);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */12,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */5);
            })
        };
}

var ContractManagerForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: initialCollectionsStatuses,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialInput_budgetRows = [];

var initialInput = {
  managerID: "",
  managerName: "",
  budgetRows: initialInput_budgetRows
};

var initialBudgetRowInput = {
  budgets: /* [] */0,
  startDate: undefined,
  endDate: undefined
};

export {
  ContractManagerForm ,
  initialInput ,
  initialBudgetRowInput ,
}
/* react Not a pure module */
