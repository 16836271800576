// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";

var Fields = {
  yearBudget: "yearBudget",
  budgetType: "budget",
  amount: "amount",
  startDate: "startDate",
  endDate: "endDate"
};

function marginMinimum_encode(v) {
  return Js_dict.fromArray([
              [
                "yearBudget",
                Decco.optionToJson(Decco.intToJson, v.yearBudget)
              ],
              [
                "budgetType",
                Decco.stringToJson(v.budgetType)
              ],
              [
                "amount",
                Decco.optionToJson(Decco.floatToJson, v.amount)
              ],
              [
                "startDate",
                Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function marginMinimum_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var yearBudget = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "yearBudget"), null));
  if (yearBudget.TAG === /* Ok */0) {
    var budgetType = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "budgetType"), null));
    if (budgetType.TAG === /* Ok */0) {
      var amount = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "amount"), null));
      if (amount.TAG === /* Ok */0) {
        var startDate = Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
        if (startDate.TAG === /* Ok */0) {
          var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
          if (endDate.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      yearBudget: yearBudget._0,
                      budgetType: budgetType._0,
                      amount: amount._0,
                      startDate: startDate._0,
                      endDate: endDate._0
                    }
                  };
          }
          var e = endDate._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".endDate" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = startDate._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".startDate" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = amount._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".amount" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = budgetType._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".budgetType" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = yearBudget._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".yearBudget" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(marginMinimum_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(marginMinimum_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function minimumsList_encode(v) {
  return Decco.listToJson(marginMinimum_encode, v);
}

function minimumsList_decode(v) {
  return Decco.listFromJson(marginMinimum_decode, v);
}

function editPair_encode(v) {
  return Js_dict.fromArray([
              [
                "original",
                marginMinimum_encode(v.original)
              ],
              [
                "new",
                marginMinimum_encode(v.new)
              ]
            ]);
}

function editPair_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var original = marginMinimum_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "original"), null));
  if (original.TAG === /* Ok */0) {
    var $$new = marginMinimum_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "new"), null));
    if ($$new.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                original: original._0,
                new: $$new._0
              }
            };
    }
    var e = $$new._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".new" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = original._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".original" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var Minimums = {
  marginMinimum_encode: marginMinimum_encode,
  marginMinimum_decode: marginMinimum_decode,
  searchResult_encode: searchResult_encode,
  searchResult_decode: searchResult_decode,
  minimumsList_encode: minimumsList_encode,
  minimumsList_decode: minimumsList_decode,
  editPair_encode: editPair_encode,
  editPair_decode: editPair_decode
};

export {
  Fields ,
  Minimums ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
