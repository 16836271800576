// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../../common/App_Api.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../../common/components/App_Checkbox.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../common/components/App_OverlaySpinner.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_SelectFormInput.bs.js";
import * as Messages_MarginGrid$DvmAdminFrontendFr from "../../../../intl/messages/marginGrid/Messages_MarginGrid.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as App_DoubleSelectFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_DoubleSelectFormInput.bs.js";

function Margin_Grid_Filters(Props) {
  var tableType = Props.tableType;
  var setTableType = Props.setTableType;
  var form = Props.form;
  var categories = Props.categories;
  var operation = Props.operation;
  var withClosedModels = Props.withClosedModels;
  var setWithClosedModels = Props.setWithClosedModels;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelVersions = match$2[1];
  var modelVersions = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setBudgets = match$3[1];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setCategoriesForFilter = match$4[1];
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchBudgets(userManager, setBudgets, selectedRole);
          App_Api$DvmAdminFrontendFr.fetchModelVersions(setModelVersions, userManager, selectedRole, withClosedModels, undefined, undefined);
          App_Api$DvmAdminFrontendFr.fetchAllCategories(userManager, setCategoriesForFilter, /* Today */0, selectedRole, undefined);
        }), []);
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchModelVersions(setModelVersions, userManager, selectedRole, withClosedModels, undefined, undefined);
        }), [withClosedModels]);
  React.useEffect((function () {
          if (tableType !== undefined) {
            if (tableType !== 0) {
              Curry._1(form.submit, undefined);
            } else {
              Curry._2(form.updateVersion, (function (input, param) {
                      return {
                              budget: input.budget,
                              model: input.model,
                              version: "",
                              avsMarginGridID: input.avsMarginGridID,
                              categoryID: input.categoryID,
                              categoryName: input.categoryName,
                              discount: input.discount,
                              year: input.year
                            };
                    }), "");
              Curry._1(form.submit, undefined);
            }
          }
          
        }), [tableType]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.forError(modelVersions, (function (error) {
                  Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                            message: {
                              TAG: /* RestError */2,
                              _0: error
                            }
                          }));
                }));
        }), [modelVersions]);
  var existingCategories = Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.flatten(Belt_List.map(categories, (function (x) {
                          return Belt_List.map(x.children, (function (x) {
                                        return x.data;
                                      }));
                        }))))));
  var existingGrids = Belt_List.map(categories, (function (x) {
          return x.data;
        }));
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.budget,
    options: Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$3[0], /* [] */0), (function (x) {
            return {
                    label: {
                      TAG: /* String */3,
                      _0: x
                    },
                    value: x
                  };
          })),
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.budget,
        onChange: Curry._1(form.updateBudget, (function (input, budget) {
                return {
                        budget: budget,
                        model: input.model,
                        version: input.version,
                        avsMarginGridID: input.avsMarginGridID,
                        categoryID: input.categoryID,
                        categoryName: input.categoryName,
                        discount: input.discount,
                        year: input.year
                      };
              }))
      }
    },
    id: "budget",
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
    required: /* OnlyLabel */1
  };
  if (form.budgetResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.budgetResult);
  }
  var match$5 = form.input.budget;
  var tmp$1;
  switch (match$5) {
    case "" :
        tmp$1 = null;
        break;
    case "AVS" :
        var tmp$2 = {
          label: Messages_MarginGrid$DvmAdminFrontendFr.gridID,
          value: {
            TAG: /* Single */0,
            _0: {
              value: form.input.avsMarginGridID,
              onChange: Curry._1(form.updateAvsMarginGridID, (function (input, avsMarginGridID) {
                      return {
                              budget: input.budget,
                              model: input.model,
                              version: input.version,
                              avsMarginGridID: avsMarginGridID,
                              categoryID: input.categoryID,
                              categoryName: input.categoryName,
                              discount: input.discount,
                              year: input.year
                            };
                    }))
            }
          },
          options: {
            TAG: /* Unlabeled */0,
            _0: existingGrids
          },
          className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
        };
        if (form.avsMarginGridIDResult !== undefined) {
          tmp$2.validationResult = Caml_option.valFromOption(form.avsMarginGridIDResult);
        }
        var tmp$3;
        if (operation === /* View */0) {
          var tmp$4 = {
            label: Messages_Common$DvmAdminFrontendFr.categoryCode,
            value: {
              TAG: /* Single */0,
              _0: {
                value: form.input.categoryID,
                onChange: Curry._1(form.updateCategoryID, (function (input, categoryID) {
                        return {
                                budget: input.budget,
                                model: input.model,
                                version: input.version,
                                avsMarginGridID: input.avsMarginGridID,
                                categoryID: categoryID,
                                categoryName: input.categoryName,
                                discount: input.discount,
                                year: input.year
                              };
                      }))
              }
            },
            options: {
              TAG: /* Unlabeled */0,
              _0: existingCategories
            },
            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
          };
          if (form.categoryIDResult !== undefined) {
            tmp$4.validationResult = Caml_option.valFromOption(form.categoryIDResult);
          }
          var tmp$5 = {
            label: Messages_Common$DvmAdminFrontendFr.year,
            value: form.input.year,
            onChange: Curry._1(form.updateYear, (function (input, year) {
                    return {
                            budget: input.budget,
                            model: input.model,
                            version: input.version,
                            avsMarginGridID: input.avsMarginGridID,
                            categoryID: input.categoryID,
                            categoryName: input.categoryName,
                            discount: input.discount,
                            year: year
                          };
                  })),
            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
            variant: /* YEAR */0
          };
          if (form.yearResult !== undefined) {
            tmp$5.validationResult = Caml_option.valFromOption(form.yearResult);
          }
          tmp$3 = React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                    children: null,
                    container: true,
                    direction: "column",
                    item: true,
                    style: {
                      width: "fit-content"
                    }
                  }, React.createElement(Core.Grid, {
                        children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$4),
                        item: true
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(Core.FormControlLabel, {
                              classes: {
                                label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                              },
                              control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                    checked: withClosedModels,
                                    onClick: (function ($$event) {
                                        Curry._1(setWithClosedModels, (function (param) {
                                                return $$event.target.checked;
                                              }));
                                      })
                                  }),
                              label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.includeClosedCars)
                            }),
                        item: true,
                        style: {
                          whiteSpace: "nowrap",
                          width: "1px"
                        }
                      })), React.createElement(Core.Grid, {
                    children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                          onChange: Curry._1(form.updateCategoryName, (function (input, categoryName) {
                                  return {
                                          budget: input.budget,
                                          model: input.model,
                                          version: input.version,
                                          avsMarginGridID: input.avsMarginGridID,
                                          categoryID: input.categoryID,
                                          categoryName: categoryName,
                                          discount: input.discount,
                                          year: input.year
                                        };
                                })),
                          value: form.input.categoryName,
                          className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
                          label: Messages_Common$DvmAdminFrontendFr.categoryLabel
                        }),
                    item: true
                  }), React.createElement(Core.Grid, {
                    children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                          onChange: Curry._1(form.updateDiscount, (function (input, discount) {
                                  return {
                                          budget: input.budget,
                                          model: input.model,
                                          version: input.version,
                                          avsMarginGridID: input.avsMarginGridID,
                                          categoryID: input.categoryID,
                                          categoryName: input.categoryName,
                                          discount: discount,
                                          year: input.year
                                        };
                                })),
                          value: form.input.discount,
                          className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
                          label: Messages_Common$DvmAdminFrontendFr.discount,
                          InputProps: {
                            startAdornment: React.createElement(Core.InputAdornment, {
                                  children: "%",
                                  position: "start"
                                })
                          },
                          inputType: "number"
                        }),
                    item: true
                  }), React.createElement(Core.Grid, {
                    children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$5),
                    item: true
                  }));
        } else {
          tmp$3 = null;
        }
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$2),
                  item: true
                }), tmp$3);
        break;
    default:
      if (operation === /* View */0) {
        var tmp$6 = {
          label: Messages_Common$DvmAdminFrontendFr.category,
          value: {
            TAG: /* Single */0,
            _0: {
              value: form.input.categoryID,
              onChange: Curry._1(form.updateCategoryID, (function (input, categoryID) {
                      return {
                              budget: input.budget,
                              model: input.model,
                              version: input.version,
                              avsMarginGridID: input.avsMarginGridID,
                              categoryID: categoryID,
                              categoryName: input.categoryName,
                              discount: input.discount,
                              year: input.year
                            };
                    }))
            }
          },
          options: {
            TAG: /* Labeled */1,
            _0: Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$4[0], /* [] */0), (function (x) {
                    return {
                            label: {
                              TAG: /* String */3,
                              _0: "" + x.categoryID + " " + Belt_Option.mapWithDefault(x.name, "", (function (x) {
                                      return "(" + x + ")";
                                    })) + ""
                            },
                            value: x.categoryID
                          };
                  }))
          },
          className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
        };
        if (form.categoryIDResult !== undefined) {
          tmp$6.validationResult = Caml_option.valFromOption(form.categoryIDResult);
        }
        var tmp$7 = {
          label: Messages_Common$DvmAdminFrontendFr.year,
          value: form.input.year,
          onChange: Curry._1(form.updateYear, (function (input, year) {
                  return {
                          budget: input.budget,
                          model: input.model,
                          version: input.version,
                          avsMarginGridID: input.avsMarginGridID,
                          categoryID: input.categoryID,
                          categoryName: input.categoryName,
                          discount: input.discount,
                          year: year
                        };
                })),
          className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
          variant: /* YEAR */0
        };
        if (form.yearResult !== undefined) {
          tmp$7.validationResult = Caml_option.valFromOption(form.yearResult);
        }
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$6),
                  item: true
                }), React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                  show: App_Types_Result$DvmAdminFrontendFr.isPending(modelVersions),
                  children: React.createElement(Core.Grid, {
                        children: null,
                        container: true,
                        direction: "column",
                        item: true,
                        style: {
                          width: "fit-content"
                        }
                      }, React.createElement(Core.Grid, {
                            children: React.createElement(App_DoubleSelectFormInput$DvmAdminFrontendFr.make, {
                                  labelParent: Messages_Common$DvmAdminFrontendFr.model,
                                  labelChild: Messages_Common$DvmAdminFrontendFr.version,
                                  valueParent: {
                                    TAG: /* Single */0,
                                    _0: {
                                      value: form.input.model,
                                      onChange: Curry._1(form.updateModel, (function (input, model) {
                                              return {
                                                      budget: input.budget,
                                                      model: model,
                                                      version: input.version,
                                                      avsMarginGridID: input.avsMarginGridID,
                                                      categoryID: input.categoryID,
                                                      categoryName: input.categoryName,
                                                      discount: input.discount,
                                                      year: input.year
                                                    };
                                            }))
                                    }
                                  },
                                  valueChild: {
                                    TAG: /* Single */0,
                                    _0: {
                                      value: form.input.version,
                                      onChange: Curry._1(form.updateVersion, (function (input, version) {
                                              return {
                                                      budget: input.budget,
                                                      model: input.model,
                                                      version: version,
                                                      avsMarginGridID: input.avsMarginGridID,
                                                      categoryID: input.categoryID,
                                                      categoryName: input.categoryName,
                                                      discount: input.discount,
                                                      year: input.year
                                                    };
                                            }))
                                    }
                                  },
                                  options: App_Types_Result$DvmAdminFrontendFr.getWithDefault(modelVersions, /* [] */0),
                                  classNameParent: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
                                  classNameChild: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                                }),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(Core.FormControlLabel, {
                                  classes: {
                                    label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                  },
                                  control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                        checked: withClosedModels,
                                        onClick: (function ($$event) {
                                            Curry._1(setWithClosedModels, (function (param) {
                                                    return $$event.target.checked;
                                                  }));
                                          })
                                      }),
                                  label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.includeClosedCars)
                                }),
                            item: true,
                            style: {
                              whiteSpace: "nowrap",
                              width: "1px"
                            }
                          }))
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                        onChange: Curry._1(form.updateDiscount, (function (input, discount) {
                                return {
                                        budget: input.budget,
                                        model: input.model,
                                        version: input.version,
                                        avsMarginGridID: input.avsMarginGridID,
                                        categoryID: input.categoryID,
                                        categoryName: input.categoryName,
                                        discount: discount,
                                        year: input.year
                                      };
                              })),
                        value: form.input.discount,
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
                        label: Messages_Common$DvmAdminFrontendFr.discount,
                        InputProps: {
                          startAdornment: React.createElement(Core.InputAdornment, {
                                children: "%",
                                position: "start"
                              })
                        },
                        inputType: "number"
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$7),
                  item: true
                }));
      } else {
        tmp$1 = null;
      }
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  var match = form.input.version;
                  var match$1 = form.input.categoryID;
                  var match$2 = form.input.budget;
                  var exit = 0;
                  if (match$2 === "AVS") {
                    Curry._1(setTableType, (function (param) {
                            return /* GridID */2;
                          }));
                  } else if (match === "" && match$1 === "") {
                    Curry._1(setTableType, (function (param) {
                            return /* Model */0;
                          }));
                  } else {
                    exit = 1;
                  }
                  if (exit === 1) {
                    Curry._1(setTableType, (function (param) {
                            return /* Version */1;
                          }));
                  }
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true,
                  item: true
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, tmp),
                      item: true
                    }), tmp$1, operation === /* View */0 ? React.createElement(Core.Grid, {
                        children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                              label: "OK",
                              type_: "submit"
                            }),
                        item: true
                      }) : null));
}

var Form;

var Api;

var Table;

var EditApi;

var InputStyles;

var make = Margin_Grid_Filters;

export {
  Form ,
  Api ,
  Table ,
  EditApi ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
