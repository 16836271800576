// Generated by ReScript, PLEASE EDIT WITH CARE


var mainTitleCreate = {
  id: "3071c973-6db7-474e-b688-a2b13d3fb5fd",
  defaultMessage: "Créer un intervenant"
};

var mainTitleModify = {
  id: "b01876e8-36fd-49c1-9d28-6089b8255b2d",
  defaultMessage: "Modifier un intervenant"
};

var infoTitle = {
  id: "8fd302c4-6ce6-4e41-9d69-a886457876db",
  defaultMessage: "Informations de l’intervenant"
};

var contractType = {
  id: "985f1146-5d6a-4a50-80e7-8e190cd7a51c",
  defaultMessage: "Type de contrat"
};

var createManagerButton = {
  id: "792a6699-92da-45fe-a774-af24878ce7e6",
  defaultMessage: "Créer l’intervenant"
};

var editManagerButton = {
  id: "8c71dd25-5685-4382-84b9-68012a7c0973",
  defaultMessage: "Modifier l'intervenant"
};

var successCreate = {
  id: "64acf342-3b3f-409e-bf11-0ec7eab55cf2",
  defaultMessage: "Création du ME effectuée"
};

var successEdit = {
  id: "4eef72b4-95f2-4ea8-8da5-11f309a5f919",
  defaultMessage: "Mise à jour du ME effectuée"
};

var backButton = {
  id: "1e14dc1d-5841-474e-92c0-30577617e22f",
  defaultMessage: "Retour à la liste des ME"
};

var notFoundError = {
  id: "14e0de5c-79a4-4aff-ad57-607058d5d99d",
  defaultMessage: "ME introuvable"
};

var editNoRowError = {
  id: "1b3ad369-34e3-4c32-8e56-a2fc463e52f8",
  defaultMessage: "Pas de ligne"
};

var resetChanges = {
  id: "7303115f-e727-4d32-be7f-11359787c2b6",
  defaultMessage: "Réinitialiser les changements"
};

export {
  mainTitleCreate ,
  mainTitleModify ,
  infoTitle ,
  contractType ,
  createManagerButton ,
  editManagerButton ,
  successCreate ,
  successEdit ,
  backButton ,
  notFoundError ,
  editNoRowError ,
  resetChanges ,
}
/* No side effect */
