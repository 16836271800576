// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as FinancialCode_Modal_Api$DvmAdminFrontendFr from "./FinancialCode_Modal_Api.bs.js";
import * as Messages_FinancialCodes$DvmAdminFrontendFr from "../../../intl/messages/easypack/Messages_FinancialCodes.bs.js";
import * as FinancialCode_Update_Form$DvmAdminFrontendFr from "./FinancialCode_Update_Form.bs.js";

function FinancialCode_Update_Modal(Props) {
  var onClose = Props.onClose;
  var row = Props.row;
  var onOk = Props.onOk;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var alert = ReactAlert.useAlert();
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match$2[1];
  var saveResult = match$2[0];
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(onOk, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  var form = FinancialCode_Update_Form$DvmAdminFrontendFr.Form.useForm({
        codeId: Belt_Option.mapWithDefault(row, "", (function (x) {
                return x.codeId;
              })),
        codeLabel: Belt_Option.mapWithDefault(row, "", (function (x) {
                return Belt_Option.getWithDefault(x.codeLabel, "");
              })),
        startDate: Belt_Option.mapWithDefault(row, undefined, (function (x) {
                return x.startDate;
              })),
        endDate: Belt_Option.mapWithDefault(row, undefined, (function (x) {
                return x.endDate;
              }))
      }, (function (param, submissionCallbacks) {
          FinancialCode_Modal_Api$DvmAdminFrontendFr.manageRow(userManager, setSaveResult, {
                codeId: param.codeId,
                codeLabel: param.codeLabel,
                codeType: "EASYPACK",
                orderType: "CL",
                creationDate: Belt_Option.mapWithDefault(row, Caml_option.some(new Date()), (function (x) {
                        return x.creationDate;
                      })),
                startDate: param.startDate,
                endDate: param.endDate
              }, submissionCallbacks, selectedRole, Belt_Option.isSome(row) ? "PUT" : "POST");
        }));
  var tmp = {
    onChange: Curry._1(form.updateCodeId, (function (input, codeId) {
            return {
                    codeId: codeId,
                    codeLabel: input.codeLabel,
                    startDate: input.startDate,
                    endDate: input.endDate
                  };
          })),
    value: form.input.codeId,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    label: Messages_FinancialCodes$DvmAdminFrontendFr.codeId,
    required: /* OnlyLabel */1
  };
  if (form.codeIdResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.codeIdResult);
  }
  var tmp$1 = {
    onChange: Curry._1(form.updateCodeLabel, (function (input, codeLabel) {
            return {
                    codeId: input.codeId,
                    codeLabel: codeLabel,
                    startDate: input.startDate,
                    endDate: input.endDate
                  };
          })),
    value: form.input.codeLabel,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    label: Messages_FinancialCodes$DvmAdminFrontendFr.codeLabel,
    required: /* OnlyLabel */1
  };
  if (form.codeLabelResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.codeLabelResult);
  }
  var tmp$2 = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    codeId: input.codeId,
                    codeLabel: input.codeLabel,
                    startDate: startDate,
                    endDate: input.endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    required: /* OnlyLabel */1
  };
  if (form.startDateResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  var tmp$3 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    codeId: input.codeId,
                    codeLabel: input.codeLabel,
                    startDate: input.startDate,
                    endDate: endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
  };
  if (form.endDateResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Belt_Option.isSome(row) ? Messages_FinancialCodes$DvmAdminFrontendFr.updateTitle : Messages_FinancialCodes$DvmAdminFrontendFr.newFinancialCode),
              minWidth: "700px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult),
                    children: React.createElement("form", {
                          onSubmit: (function ($$event) {
                              $$event.preventDefault();
                              Curry._1(form.submit, undefined);
                            })
                        }, React.createElement(Core.Grid, {
                              alignItems: "center",
                              children: null,
                              container: true,
                              direction: "column",
                              spacing: 4
                            }, React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  item: true,
                                  sm: Grid$Mui.Sm[12],
                                  spacing: 4
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Grid, {
                                            children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp),
                                            item: true
                                          }),
                                      item: true,
                                      sm: Grid$Mui.Sm[6]
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$1),
                                      item: true,
                                      sm: Grid$Mui.Sm[6]
                                    })), React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  item: true,
                                  sm: Grid$Mui.Sm[12],
                                  spacing: 4
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$2),
                                      item: true,
                                      sm: Grid$Mui.Sm[6]
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$3),
                                      item: true,
                                      sm: Grid$Mui.Sm[6]
                                    })), React.createElement(Core.Grid, {
                                  children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                        label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                        color: "primary",
                                        size: "medium",
                                        type_: "submit"
                                      }),
                                  item: true
                                })))
                  })
            });
}

var Api;

var Form;

var InputStyles;

var make = FinancialCode_Update_Modal;

export {
  Api ,
  Form ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
