// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Button.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../common/components/App_OverlaySpinner.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Messages_Action_Referential$DvmAdminFrontendFr from "../../../../intl/messages/actionReferential/Messages_Action_Referential.bs.js";
import * as Action_Referential_Modal_Api$DvmAdminFrontendFr from "./Action_Referential_Modal_Api.bs.js";
import * as Action_Referential_Fields_Api$DvmAdminFrontendFr from "../../fields/Action_Referential_Fields_Api.bs.js";
import * as Action_Referential_Update_Form$DvmAdminFrontendFr from "./Action_Referential_Update_Form.bs.js";

function Action_Referential_Update_Modal(Props) {
  var onClose = Props.onClose;
  var row = Props.row;
  var onOk = Props.onOk;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var alert = ReactAlert.useAlert();
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match$2[1];
  var saveResult = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setLocalActionTriggerResult = match$3[1];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setApplicationCorpChannelResult = match$4[1];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setBrandResult = match$5[1];
  var match$6 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDistributionModeResult = match$6[1];
  var match$7 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setApplicationClientChannelResult = match$7[1];
  var match$8 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setApplicationSubChannelResult = match$8[1];
  var match$9 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setMarketingCorpCategoryResult = match$9[1];
  var match$10 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setMarketingCorpTypeResult = match$10[1];
  var match$11 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setVmeDesitnationResult = match$11[1];
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* String */3,
                        _0: saveResult._0
                      }
                    }));
            Curry._1(onOk, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  React.useEffect((function () {
          Action_Referential_Fields_Api$DvmAdminFrontendFr.fetchActionReferentialsFields(userManager, setLocalActionTriggerResult, /* LocalActionTrigger */0, selectedRole);
          Action_Referential_Fields_Api$DvmAdminFrontendFr.fetchActionReferentialsFields(userManager, setApplicationCorpChannelResult, /* ApplicationCorpChannel */1, selectedRole);
          Action_Referential_Fields_Api$DvmAdminFrontendFr.fetchActionReferentialsFields(userManager, setBrandResult, /* Brand */2, selectedRole);
          Action_Referential_Fields_Api$DvmAdminFrontendFr.fetchActionReferentialsFields(userManager, setDistributionModeResult, /* DistributionMode */3, selectedRole);
          Action_Referential_Fields_Api$DvmAdminFrontendFr.fetchActionReferentialsFields(userManager, setApplicationClientChannelResult, /* ApplicationClientChannel */4, selectedRole);
          Action_Referential_Fields_Api$DvmAdminFrontendFr.fetchActionReferentialsFields(userManager, setApplicationSubChannelResult, /* ApplicationSubChannel */5, selectedRole);
          Action_Referential_Fields_Api$DvmAdminFrontendFr.fetchActionReferentialsFields(userManager, setMarketingCorpCategoryResult, /* MarketingCorpCategory */6, selectedRole);
          Action_Referential_Fields_Api$DvmAdminFrontendFr.fetchActionReferentialsFields(userManager, setMarketingCorpTypeResult, /* MarketingCorpType */7, selectedRole);
          Action_Referential_Fields_Api$DvmAdminFrontendFr.fetchActionReferentialsFields(userManager, setVmeDesitnationResult, /* VmeDesitnation */8, selectedRole);
        }), []);
  var form = Action_Referential_Update_Form$DvmAdminFrontendFr.Form.useForm(Belt_Option.mapWithDefault(row, Action_Referential_Update_Form$DvmAdminFrontendFr.initialState, (function (row) {
              return {
                      countryCode: row.countryCode,
                      localActionTypeID: row.localActionTypeID,
                      localActionTypeLabel: Belt_Option.getWithDefault(row.localActionTypeLabel, ""),
                      localActionDescription: Belt_Option.getWithDefault(row.localActionDescription, ""),
                      localActionTrigger: Belt_Option.getWithDefault(row.localActionTrigger, ""),
                      appCorpChannel: Belt_Option.getWithDefault(row.appCorpChannel, ""),
                      brand: Belt_Option.getWithDefault(row.brand, ""),
                      distributionMode: Belt_Option.getWithDefault(row.distributionMode, ""),
                      appClientChannel: Belt_Option.getWithDefault(row.appClientChannel, ""),
                      appSubChannel: Belt_Option.getWithDefault(row.appSubChannel, ""),
                      marketingCorpCategory: Belt_Option.getWithDefault(row.marketingCorpCategory, ""),
                      marketingCorpType: Belt_Option.getWithDefault(row.marketingCorpType, ""),
                      vmeDestination: Belt_Option.getWithDefault(row.vmeDestination, ""),
                      controllingCorpTypeID: Belt_Option.getWithDefault(row.controllingCorpTypeID, ""),
                      controllingCorpTypeLabel: Belt_Option.getWithDefault(row.controllingCorpTypeLabel, ""),
                      definition: Belt_Option.getWithDefault(row.definition, ""),
                      paidTo: Belt_Option.getWithDefault(row.paidTo, ""),
                      controllingAppStartDate: row.controllingAppStartDate,
                      controllingAppEndDate: row.controllingAppEndDate
                    };
            })), (function (output, submissionCallbacks) {
          Action_Referential_Modal_Api$DvmAdminFrontendFr.createModifyRow(userManager, setSaveResult, {
                countryCode: output.countryCode,
                localActionTypeID: output.localActionTypeID,
                localActionTypeLabel: output.localActionTypeLabel,
                localActionDescription: output.localActionDescription,
                localActionTrigger: output.localActionTrigger,
                appCorpChannel: output.appCorpChannel,
                brand: output.brand,
                distributionMode: output.distributionMode,
                appClientChannel: output.appClientChannel,
                appSubChannel: output.appSubChannel,
                marketingCorpCategory: output.marketingCorpCategory,
                marketingCorpType: output.marketingCorpType,
                vmeDestination: output.vmeDestination,
                controllingCorpTypeID: output.controllingCorpTypeID,
                controllingCorpTypeLabel: output.controllingCorpTypeLabel,
                definition: output.definition,
                paidTo: output.paidTo,
                controllingAppStartDate: output.controllingAppStartDate,
                controllingAppEndDate: output.controllingAppEndDate
              }, intl, submissionCallbacks, selectedRole, row);
        }));
  var tmp = {
    label: Messages_Action_Referential$DvmAdminFrontendFr.countryCode,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.countryCode,
        onChange: Curry._1(form.updateCountryCode, (function (input, countryCode) {
                return {
                        countryCode: countryCode,
                        localActionTypeID: input.localActionTypeID,
                        localActionTypeLabel: input.localActionTypeLabel,
                        localActionDescription: input.localActionDescription,
                        localActionTrigger: input.localActionTrigger,
                        appCorpChannel: input.appCorpChannel,
                        brand: input.brand,
                        distributionMode: input.distributionMode,
                        appClientChannel: input.appClientChannel,
                        appSubChannel: input.appSubChannel,
                        marketingCorpCategory: input.marketingCorpCategory,
                        marketingCorpType: input.marketingCorpType,
                        vmeDestination: input.vmeDestination,
                        controllingCorpTypeID: input.controllingCorpTypeID,
                        controllingCorpTypeLabel: input.controllingCorpTypeLabel,
                        definition: input.definition,
                        paidTo: input.paidTo,
                        controllingAppStartDate: input.controllingAppStartDate,
                        controllingAppEndDate: input.controllingAppEndDate
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: {
        hd: "FR",
        tl: {
          hd: "ES",
          tl: /* [] */0
        }
      }
    },
    required: /* OnlyLabel */1,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
  };
  if (form.countryCodeResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.countryCodeResult);
  }
  var tmp$1 = {
    onChange: Curry._1(form.updateLocalActionTypeID, (function (input, localActionTypeID) {
            return {
                    countryCode: input.countryCode,
                    localActionTypeID: localActionTypeID,
                    localActionTypeLabel: input.localActionTypeLabel,
                    localActionDescription: input.localActionDescription,
                    localActionTrigger: input.localActionTrigger,
                    appCorpChannel: input.appCorpChannel,
                    brand: input.brand,
                    distributionMode: input.distributionMode,
                    appClientChannel: input.appClientChannel,
                    appSubChannel: input.appSubChannel,
                    marketingCorpCategory: input.marketingCorpCategory,
                    marketingCorpType: input.marketingCorpType,
                    vmeDestination: input.vmeDestination,
                    controllingCorpTypeID: input.controllingCorpTypeID,
                    controllingCorpTypeLabel: input.controllingCorpTypeLabel,
                    definition: input.definition,
                    paidTo: input.paidTo,
                    controllingAppStartDate: input.controllingAppStartDate,
                    controllingAppEndDate: input.controllingAppEndDate
                  };
          })),
    value: form.input.localActionTypeID,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    label: Messages_Action_Referential$DvmAdminFrontendFr.localActionTypeID,
    required: /* OnlyLabel */1
  };
  if (form.localActionTypeIDResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.localActionTypeIDResult);
  }
  var tmp$2 = {
    label: Messages_Action_Referential$DvmAdminFrontendFr.controllingAppStartDate,
    value: form.input.controllingAppStartDate,
    onChange: Curry._1(form.updateControllingAppStartDate, (function (input, controllingAppStartDate) {
            return {
                    countryCode: input.countryCode,
                    localActionTypeID: input.localActionTypeID,
                    localActionTypeLabel: input.localActionTypeLabel,
                    localActionDescription: input.localActionDescription,
                    localActionTrigger: input.localActionTrigger,
                    appCorpChannel: input.appCorpChannel,
                    brand: input.brand,
                    distributionMode: input.distributionMode,
                    appClientChannel: input.appClientChannel,
                    appSubChannel: input.appSubChannel,
                    marketingCorpCategory: input.marketingCorpCategory,
                    marketingCorpType: input.marketingCorpType,
                    vmeDestination: input.vmeDestination,
                    controllingCorpTypeID: input.controllingCorpTypeID,
                    controllingCorpTypeLabel: input.controllingCorpTypeLabel,
                    definition: input.definition,
                    paidTo: input.paidTo,
                    controllingAppStartDate: controllingAppStartDate,
                    controllingAppEndDate: input.controllingAppEndDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
  };
  if (form.controllingAppStartDateResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.controllingAppStartDateResult);
  }
  var tmp$3 = {
    label: Messages_Action_Referential$DvmAdminFrontendFr.controllingAppEndDate,
    value: form.input.controllingAppEndDate,
    onChange: Curry._1(form.updateControllingAppEndDate, (function (input, controllingAppEndDate) {
            return {
                    countryCode: input.countryCode,
                    localActionTypeID: input.localActionTypeID,
                    localActionTypeLabel: input.localActionTypeLabel,
                    localActionDescription: input.localActionDescription,
                    localActionTrigger: input.localActionTrigger,
                    appCorpChannel: input.appCorpChannel,
                    brand: input.brand,
                    distributionMode: input.distributionMode,
                    appClientChannel: input.appClientChannel,
                    appSubChannel: input.appSubChannel,
                    marketingCorpCategory: input.marketingCorpCategory,
                    marketingCorpType: input.marketingCorpType,
                    vmeDestination: input.vmeDestination,
                    controllingCorpTypeID: input.controllingCorpTypeID,
                    controllingCorpTypeLabel: input.controllingCorpTypeLabel,
                    definition: input.definition,
                    paidTo: input.paidTo,
                    controllingAppStartDate: input.controllingAppStartDate,
                    controllingAppEndDate: controllingAppEndDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
  };
  if (form.controllingAppEndDateResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.controllingAppEndDateResult);
  }
  var tmp$4 = {
    onChange: Curry._1(form.updateDefinition, (function (input, definition) {
            return {
                    countryCode: input.countryCode,
                    localActionTypeID: input.localActionTypeID,
                    localActionTypeLabel: input.localActionTypeLabel,
                    localActionDescription: input.localActionDescription,
                    localActionTrigger: input.localActionTrigger,
                    appCorpChannel: input.appCorpChannel,
                    brand: input.brand,
                    distributionMode: input.distributionMode,
                    appClientChannel: input.appClientChannel,
                    appSubChannel: input.appSubChannel,
                    marketingCorpCategory: input.marketingCorpCategory,
                    marketingCorpType: input.marketingCorpType,
                    vmeDestination: input.vmeDestination,
                    controllingCorpTypeID: input.controllingCorpTypeID,
                    controllingCorpTypeLabel: input.controllingCorpTypeLabel,
                    definition: definition,
                    paidTo: input.paidTo,
                    controllingAppStartDate: input.controllingAppStartDate,
                    controllingAppEndDate: input.controllingAppEndDate
                  };
          })),
    value: form.input.definition,
    multiline: true,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    label: Messages_Action_Referential$DvmAdminFrontendFr.definition
  };
  if (form.definitionResult !== undefined) {
    tmp$4.validationResult = Caml_option.valFromOption(form.definitionResult);
  }
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Belt_Option.isSome(row) ? Messages_Action_Referential$DvmAdminFrontendFr.modifyReferential : Messages_Action_Referential$DvmAdminFrontendFr.createReferential),
              minWidth: "1100px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult),
                    children: React.createElement("form", {
                          onSubmit: (function ($$event) {
                              $$event.preventDefault();
                              Curry._1(form.submit, undefined);
                            })
                        }, React.createElement(Core.Grid, {
                              alignItems: "center",
                              children: null,
                              container: true,
                              direction: "column",
                              spacing: 4
                            }, React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  direction: "column",
                                  item: true,
                                  spacing: 2
                                }, React.createElement(Core.Grid, {
                                      children: null,
                                      container: true,
                                      item: true,
                                      spacing: 2
                                    }, React.createElement(Core.Grid, {
                                          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp),
                                          item: true,
                                          xs: Grid$Mui.Xs[4]
                                        }), React.createElement(Core.Grid, {
                                          children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$1),
                                          item: true,
                                          xs: Grid$Mui.Xs[4]
                                        }), React.createElement(Core.Grid, {
                                          children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                                onChange: Curry._1(form.updateLocalActionTypeLabel, (function (input, localActionTypeLabel) {
                                                        return {
                                                                countryCode: input.countryCode,
                                                                localActionTypeID: input.localActionTypeID,
                                                                localActionTypeLabel: localActionTypeLabel,
                                                                localActionDescription: input.localActionDescription,
                                                                localActionTrigger: input.localActionTrigger,
                                                                appCorpChannel: input.appCorpChannel,
                                                                brand: input.brand,
                                                                distributionMode: input.distributionMode,
                                                                appClientChannel: input.appClientChannel,
                                                                appSubChannel: input.appSubChannel,
                                                                marketingCorpCategory: input.marketingCorpCategory,
                                                                marketingCorpType: input.marketingCorpType,
                                                                vmeDestination: input.vmeDestination,
                                                                controllingCorpTypeID: input.controllingCorpTypeID,
                                                                controllingCorpTypeLabel: input.controllingCorpTypeLabel,
                                                                definition: input.definition,
                                                                paidTo: input.paidTo,
                                                                controllingAppStartDate: input.controllingAppStartDate,
                                                                controllingAppEndDate: input.controllingAppEndDate
                                                              };
                                                      })),
                                                value: form.input.localActionTypeLabel,
                                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                                                label: Messages_Action_Referential$DvmAdminFrontendFr.localActionTypeLabel
                                              }),
                                          item: true,
                                          xs: Grid$Mui.Xs[4]
                                        })), React.createElement(Core.Grid, {
                                      children: null,
                                      container: true,
                                      item: true,
                                      spacing: 2
                                    }, React.createElement(Core.Grid, {
                                          children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                                onChange: Curry._1(form.updateLocalActionDescription, (function (input, localActionDescription) {
                                                        return {
                                                                countryCode: input.countryCode,
                                                                localActionTypeID: input.localActionTypeID,
                                                                localActionTypeLabel: input.localActionTypeLabel,
                                                                localActionDescription: localActionDescription,
                                                                localActionTrigger: input.localActionTrigger,
                                                                appCorpChannel: input.appCorpChannel,
                                                                brand: input.brand,
                                                                distributionMode: input.distributionMode,
                                                                appClientChannel: input.appClientChannel,
                                                                appSubChannel: input.appSubChannel,
                                                                marketingCorpCategory: input.marketingCorpCategory,
                                                                marketingCorpType: input.marketingCorpType,
                                                                vmeDestination: input.vmeDestination,
                                                                controllingCorpTypeID: input.controllingCorpTypeID,
                                                                controllingCorpTypeLabel: input.controllingCorpTypeLabel,
                                                                definition: input.definition,
                                                                paidTo: input.paidTo,
                                                                controllingAppStartDate: input.controllingAppStartDate,
                                                                controllingAppEndDate: input.controllingAppEndDate
                                                              };
                                                      })),
                                                value: form.input.localActionDescription,
                                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                                                label: Messages_Action_Referential$DvmAdminFrontendFr.localActionDescription
                                              }),
                                          item: true,
                                          xs: Grid$Mui.Xs[4]
                                        }), React.createElement(Core.Grid, {
                                          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                                label: Messages_Action_Referential$DvmAdminFrontendFr.localActionTrigger,
                                                value: {
                                                  TAG: /* Single */0,
                                                  _0: {
                                                    value: form.input.localActionTrigger,
                                                    onChange: Curry._1(form.updateLocalActionTrigger, (function (input, localActionTrigger) {
                                                            return {
                                                                    countryCode: input.countryCode,
                                                                    localActionTypeID: input.localActionTypeID,
                                                                    localActionTypeLabel: input.localActionTypeLabel,
                                                                    localActionDescription: input.localActionDescription,
                                                                    localActionTrigger: localActionTrigger,
                                                                    appCorpChannel: input.appCorpChannel,
                                                                    brand: input.brand,
                                                                    distributionMode: input.distributionMode,
                                                                    appClientChannel: input.appClientChannel,
                                                                    appSubChannel: input.appSubChannel,
                                                                    marketingCorpCategory: input.marketingCorpCategory,
                                                                    marketingCorpType: input.marketingCorpType,
                                                                    vmeDestination: input.vmeDestination,
                                                                    controllingCorpTypeID: input.controllingCorpTypeID,
                                                                    controllingCorpTypeLabel: input.controllingCorpTypeLabel,
                                                                    definition: input.definition,
                                                                    paidTo: input.paidTo,
                                                                    controllingAppStartDate: input.controllingAppStartDate,
                                                                    controllingAppEndDate: input.controllingAppEndDate
                                                                  };
                                                          }))
                                                  }
                                                },
                                                options: {
                                                  TAG: /* Unlabeled */0,
                                                  _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$3[0], /* [] */0)
                                                },
                                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                                              }),
                                          item: true,
                                          xs: Grid$Mui.Xs[4]
                                        }), React.createElement(Core.Grid, {
                                          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                                label: Messages_Action_Referential$DvmAdminFrontendFr.appCorpChannel,
                                                value: {
                                                  TAG: /* Single */0,
                                                  _0: {
                                                    value: form.input.appCorpChannel,
                                                    onChange: Curry._1(form.updateAppCorpChannel, (function (input, appCorpChannel) {
                                                            return {
                                                                    countryCode: input.countryCode,
                                                                    localActionTypeID: input.localActionTypeID,
                                                                    localActionTypeLabel: input.localActionTypeLabel,
                                                                    localActionDescription: input.localActionDescription,
                                                                    localActionTrigger: input.localActionTrigger,
                                                                    appCorpChannel: appCorpChannel,
                                                                    brand: input.brand,
                                                                    distributionMode: input.distributionMode,
                                                                    appClientChannel: input.appClientChannel,
                                                                    appSubChannel: input.appSubChannel,
                                                                    marketingCorpCategory: input.marketingCorpCategory,
                                                                    marketingCorpType: input.marketingCorpType,
                                                                    vmeDestination: input.vmeDestination,
                                                                    controllingCorpTypeID: input.controllingCorpTypeID,
                                                                    controllingCorpTypeLabel: input.controllingCorpTypeLabel,
                                                                    definition: input.definition,
                                                                    paidTo: input.paidTo,
                                                                    controllingAppStartDate: input.controllingAppStartDate,
                                                                    controllingAppEndDate: input.controllingAppEndDate
                                                                  };
                                                          }))
                                                  }
                                                },
                                                options: {
                                                  TAG: /* Unlabeled */0,
                                                  _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$4[0], /* [] */0)
                                                },
                                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                                              }),
                                          item: true,
                                          xs: Grid$Mui.Xs[4]
                                        })), React.createElement(Core.Grid, {
                                      children: null,
                                      container: true,
                                      item: true,
                                      spacing: 2
                                    }, React.createElement(Core.Grid, {
                                          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                                label: Messages_Action_Referential$DvmAdminFrontendFr.brandNoFrench,
                                                value: {
                                                  TAG: /* Single */0,
                                                  _0: {
                                                    value: form.input.brand,
                                                    onChange: Curry._1(form.updateBrand, (function (input, brand) {
                                                            return {
                                                                    countryCode: input.countryCode,
                                                                    localActionTypeID: input.localActionTypeID,
                                                                    localActionTypeLabel: input.localActionTypeLabel,
                                                                    localActionDescription: input.localActionDescription,
                                                                    localActionTrigger: input.localActionTrigger,
                                                                    appCorpChannel: input.appCorpChannel,
                                                                    brand: brand,
                                                                    distributionMode: input.distributionMode,
                                                                    appClientChannel: input.appClientChannel,
                                                                    appSubChannel: input.appSubChannel,
                                                                    marketingCorpCategory: input.marketingCorpCategory,
                                                                    marketingCorpType: input.marketingCorpType,
                                                                    vmeDestination: input.vmeDestination,
                                                                    controllingCorpTypeID: input.controllingCorpTypeID,
                                                                    controllingCorpTypeLabel: input.controllingCorpTypeLabel,
                                                                    definition: input.definition,
                                                                    paidTo: input.paidTo,
                                                                    controllingAppStartDate: input.controllingAppStartDate,
                                                                    controllingAppEndDate: input.controllingAppEndDate
                                                                  };
                                                          }))
                                                  }
                                                },
                                                options: {
                                                  TAG: /* Unlabeled */0,
                                                  _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$5[0], /* [] */0)
                                                },
                                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                                              }),
                                          item: true,
                                          xs: Grid$Mui.Xs[4]
                                        }), React.createElement(Core.Grid, {
                                          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                                label: Messages_Action_Referential$DvmAdminFrontendFr.distributionMode,
                                                value: {
                                                  TAG: /* Single */0,
                                                  _0: {
                                                    value: form.input.distributionMode,
                                                    onChange: Curry._1(form.updateDistributionMode, (function (input, distributionMode) {
                                                            return {
                                                                    countryCode: input.countryCode,
                                                                    localActionTypeID: input.localActionTypeID,
                                                                    localActionTypeLabel: input.localActionTypeLabel,
                                                                    localActionDescription: input.localActionDescription,
                                                                    localActionTrigger: input.localActionTrigger,
                                                                    appCorpChannel: input.appCorpChannel,
                                                                    brand: input.brand,
                                                                    distributionMode: distributionMode,
                                                                    appClientChannel: input.appClientChannel,
                                                                    appSubChannel: input.appSubChannel,
                                                                    marketingCorpCategory: input.marketingCorpCategory,
                                                                    marketingCorpType: input.marketingCorpType,
                                                                    vmeDestination: input.vmeDestination,
                                                                    controllingCorpTypeID: input.controllingCorpTypeID,
                                                                    controllingCorpTypeLabel: input.controllingCorpTypeLabel,
                                                                    definition: input.definition,
                                                                    paidTo: input.paidTo,
                                                                    controllingAppStartDate: input.controllingAppStartDate,
                                                                    controllingAppEndDate: input.controllingAppEndDate
                                                                  };
                                                          }))
                                                  }
                                                },
                                                options: {
                                                  TAG: /* Unlabeled */0,
                                                  _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$6[0], /* [] */0)
                                                },
                                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                                              }),
                                          item: true,
                                          xs: Grid$Mui.Xs[4]
                                        }), React.createElement(Core.Grid, {
                                          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                                label: Messages_Action_Referential$DvmAdminFrontendFr.applicationClientChannel,
                                                value: {
                                                  TAG: /* Single */0,
                                                  _0: {
                                                    value: form.input.appClientChannel,
                                                    onChange: Curry._1(form.updateAppClientChannel, (function (input, appClientChannel) {
                                                            return {
                                                                    countryCode: input.countryCode,
                                                                    localActionTypeID: input.localActionTypeID,
                                                                    localActionTypeLabel: input.localActionTypeLabel,
                                                                    localActionDescription: input.localActionDescription,
                                                                    localActionTrigger: input.localActionTrigger,
                                                                    appCorpChannel: input.appCorpChannel,
                                                                    brand: input.brand,
                                                                    distributionMode: input.distributionMode,
                                                                    appClientChannel: appClientChannel,
                                                                    appSubChannel: input.appSubChannel,
                                                                    marketingCorpCategory: input.marketingCorpCategory,
                                                                    marketingCorpType: input.marketingCorpType,
                                                                    vmeDestination: input.vmeDestination,
                                                                    controllingCorpTypeID: input.controllingCorpTypeID,
                                                                    controllingCorpTypeLabel: input.controllingCorpTypeLabel,
                                                                    definition: input.definition,
                                                                    paidTo: input.paidTo,
                                                                    controllingAppStartDate: input.controllingAppStartDate,
                                                                    controllingAppEndDate: input.controllingAppEndDate
                                                                  };
                                                          }))
                                                  }
                                                },
                                                options: {
                                                  TAG: /* Unlabeled */0,
                                                  _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$7[0], /* [] */0)
                                                },
                                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                                              }),
                                          item: true,
                                          xs: Grid$Mui.Xs[4]
                                        })), React.createElement(Core.Grid, {
                                      children: null,
                                      container: true,
                                      item: true,
                                      spacing: 2
                                    }, React.createElement(Core.Grid, {
                                          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                                label: Messages_Action_Referential$DvmAdminFrontendFr.appSubChannel,
                                                value: {
                                                  TAG: /* Single */0,
                                                  _0: {
                                                    value: form.input.appSubChannel,
                                                    onChange: Curry._1(form.updateAppSubChannel, (function (input, appSubChannel) {
                                                            return {
                                                                    countryCode: input.countryCode,
                                                                    localActionTypeID: input.localActionTypeID,
                                                                    localActionTypeLabel: input.localActionTypeLabel,
                                                                    localActionDescription: input.localActionDescription,
                                                                    localActionTrigger: input.localActionTrigger,
                                                                    appCorpChannel: input.appCorpChannel,
                                                                    brand: input.brand,
                                                                    distributionMode: input.distributionMode,
                                                                    appClientChannel: input.appClientChannel,
                                                                    appSubChannel: appSubChannel,
                                                                    marketingCorpCategory: input.marketingCorpCategory,
                                                                    marketingCorpType: input.marketingCorpType,
                                                                    vmeDestination: input.vmeDestination,
                                                                    controllingCorpTypeID: input.controllingCorpTypeID,
                                                                    controllingCorpTypeLabel: input.controllingCorpTypeLabel,
                                                                    definition: input.definition,
                                                                    paidTo: input.paidTo,
                                                                    controllingAppStartDate: input.controllingAppStartDate,
                                                                    controllingAppEndDate: input.controllingAppEndDate
                                                                  };
                                                          }))
                                                  }
                                                },
                                                options: {
                                                  TAG: /* Unlabeled */0,
                                                  _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$8[0], /* [] */0)
                                                },
                                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                                              }),
                                          item: true,
                                          xs: Grid$Mui.Xs[4]
                                        }), React.createElement(Core.Grid, {
                                          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                                label: Messages_Action_Referential$DvmAdminFrontendFr.marketingCorpCategory,
                                                value: {
                                                  TAG: /* Single */0,
                                                  _0: {
                                                    value: form.input.marketingCorpCategory,
                                                    onChange: Curry._1(form.updateMarketingCorpCategory, (function (input, marketingCorpCategory) {
                                                            return {
                                                                    countryCode: input.countryCode,
                                                                    localActionTypeID: input.localActionTypeID,
                                                                    localActionTypeLabel: input.localActionTypeLabel,
                                                                    localActionDescription: input.localActionDescription,
                                                                    localActionTrigger: input.localActionTrigger,
                                                                    appCorpChannel: input.appCorpChannel,
                                                                    brand: input.brand,
                                                                    distributionMode: input.distributionMode,
                                                                    appClientChannel: input.appClientChannel,
                                                                    appSubChannel: input.appSubChannel,
                                                                    marketingCorpCategory: marketingCorpCategory,
                                                                    marketingCorpType: input.marketingCorpType,
                                                                    vmeDestination: input.vmeDestination,
                                                                    controllingCorpTypeID: input.controllingCorpTypeID,
                                                                    controllingCorpTypeLabel: input.controllingCorpTypeLabel,
                                                                    definition: input.definition,
                                                                    paidTo: input.paidTo,
                                                                    controllingAppStartDate: input.controllingAppStartDate,
                                                                    controllingAppEndDate: input.controllingAppEndDate
                                                                  };
                                                          }))
                                                  }
                                                },
                                                options: {
                                                  TAG: /* Unlabeled */0,
                                                  _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$9[0], /* [] */0)
                                                },
                                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                                              }),
                                          item: true,
                                          xs: Grid$Mui.Xs[4]
                                        }), React.createElement(Core.Grid, {
                                          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                                label: Messages_Action_Referential$DvmAdminFrontendFr.marketingCorpType,
                                                value: {
                                                  TAG: /* Single */0,
                                                  _0: {
                                                    value: form.input.marketingCorpType,
                                                    onChange: Curry._1(form.updateMarketingCorpType, (function (input, marketingCorpType) {
                                                            return {
                                                                    countryCode: input.countryCode,
                                                                    localActionTypeID: input.localActionTypeID,
                                                                    localActionTypeLabel: input.localActionTypeLabel,
                                                                    localActionDescription: input.localActionDescription,
                                                                    localActionTrigger: input.localActionTrigger,
                                                                    appCorpChannel: input.appCorpChannel,
                                                                    brand: input.brand,
                                                                    distributionMode: input.distributionMode,
                                                                    appClientChannel: input.appClientChannel,
                                                                    appSubChannel: input.appSubChannel,
                                                                    marketingCorpCategory: input.marketingCorpCategory,
                                                                    marketingCorpType: marketingCorpType,
                                                                    vmeDestination: input.vmeDestination,
                                                                    controllingCorpTypeID: input.controllingCorpTypeID,
                                                                    controllingCorpTypeLabel: input.controllingCorpTypeLabel,
                                                                    definition: input.definition,
                                                                    paidTo: input.paidTo,
                                                                    controllingAppStartDate: input.controllingAppStartDate,
                                                                    controllingAppEndDate: input.controllingAppEndDate
                                                                  };
                                                          }))
                                                  }
                                                },
                                                options: {
                                                  TAG: /* Unlabeled */0,
                                                  _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$10[0], /* [] */0)
                                                },
                                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                                              }),
                                          item: true,
                                          xs: Grid$Mui.Xs[4]
                                        })), React.createElement(Core.Grid, {
                                      children: null,
                                      container: true,
                                      item: true,
                                      spacing: 2
                                    }, React.createElement(Core.Grid, {
                                          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                                label: Messages_Action_Referential$DvmAdminFrontendFr.vmeDestination,
                                                value: {
                                                  TAG: /* Single */0,
                                                  _0: {
                                                    value: form.input.vmeDestination,
                                                    onChange: Curry._1(form.updateVmeDestination, (function (input, vmeDestination) {
                                                            return {
                                                                    countryCode: input.countryCode,
                                                                    localActionTypeID: input.localActionTypeID,
                                                                    localActionTypeLabel: input.localActionTypeLabel,
                                                                    localActionDescription: input.localActionDescription,
                                                                    localActionTrigger: input.localActionTrigger,
                                                                    appCorpChannel: input.appCorpChannel,
                                                                    brand: input.brand,
                                                                    distributionMode: input.distributionMode,
                                                                    appClientChannel: input.appClientChannel,
                                                                    appSubChannel: input.appSubChannel,
                                                                    marketingCorpCategory: input.marketingCorpCategory,
                                                                    marketingCorpType: input.marketingCorpType,
                                                                    vmeDestination: vmeDestination,
                                                                    controllingCorpTypeID: input.controllingCorpTypeID,
                                                                    controllingCorpTypeLabel: input.controllingCorpTypeLabel,
                                                                    definition: input.definition,
                                                                    paidTo: input.paidTo,
                                                                    controllingAppStartDate: input.controllingAppStartDate,
                                                                    controllingAppEndDate: input.controllingAppEndDate
                                                                  };
                                                          }))
                                                  }
                                                },
                                                options: {
                                                  TAG: /* Unlabeled */0,
                                                  _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$11[0], /* [] */0)
                                                },
                                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                                              }),
                                          item: true,
                                          xs: Grid$Mui.Xs[4]
                                        }), React.createElement(Core.Grid, {
                                          children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                                onChange: Curry._1(form.updateControllingCorpTypeID, (function (input, controllingCorpTypeID) {
                                                        return {
                                                                countryCode: input.countryCode,
                                                                localActionTypeID: input.localActionTypeID,
                                                                localActionTypeLabel: input.localActionTypeLabel,
                                                                localActionDescription: input.localActionDescription,
                                                                localActionTrigger: input.localActionTrigger,
                                                                appCorpChannel: input.appCorpChannel,
                                                                brand: input.brand,
                                                                distributionMode: input.distributionMode,
                                                                appClientChannel: input.appClientChannel,
                                                                appSubChannel: input.appSubChannel,
                                                                marketingCorpCategory: input.marketingCorpCategory,
                                                                marketingCorpType: input.marketingCorpType,
                                                                vmeDestination: input.vmeDestination,
                                                                controllingCorpTypeID: controllingCorpTypeID,
                                                                controllingCorpTypeLabel: input.controllingCorpTypeLabel,
                                                                definition: input.definition,
                                                                paidTo: input.paidTo,
                                                                controllingAppStartDate: input.controllingAppStartDate,
                                                                controllingAppEndDate: input.controllingAppEndDate
                                                              };
                                                      })),
                                                value: form.input.controllingCorpTypeID,
                                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                                                label: Messages_Action_Referential$DvmAdminFrontendFr.controllingCorpTypeID
                                              }),
                                          item: true,
                                          xs: Grid$Mui.Xs[4]
                                        }), React.createElement(Core.Grid, {
                                          children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                                onChange: Curry._1(form.updateControllingCorpTypeLabel, (function (input, controllingCorpTypeLabel) {
                                                        return {
                                                                countryCode: input.countryCode,
                                                                localActionTypeID: input.localActionTypeID,
                                                                localActionTypeLabel: input.localActionTypeLabel,
                                                                localActionDescription: input.localActionDescription,
                                                                localActionTrigger: input.localActionTrigger,
                                                                appCorpChannel: input.appCorpChannel,
                                                                brand: input.brand,
                                                                distributionMode: input.distributionMode,
                                                                appClientChannel: input.appClientChannel,
                                                                appSubChannel: input.appSubChannel,
                                                                marketingCorpCategory: input.marketingCorpCategory,
                                                                marketingCorpType: input.marketingCorpType,
                                                                vmeDestination: input.vmeDestination,
                                                                controllingCorpTypeID: input.controllingCorpTypeID,
                                                                controllingCorpTypeLabel: controllingCorpTypeLabel,
                                                                definition: input.definition,
                                                                paidTo: input.paidTo,
                                                                controllingAppStartDate: input.controllingAppStartDate,
                                                                controllingAppEndDate: input.controllingAppEndDate
                                                              };
                                                      })),
                                                value: form.input.controllingCorpTypeLabel,
                                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                                                label: Messages_Action_Referential$DvmAdminFrontendFr.controllingCorpTypeLabel
                                              }),
                                          item: true,
                                          xs: Grid$Mui.Xs[4]
                                        })), React.createElement(Core.Grid, {
                                      children: null,
                                      container: true,
                                      item: true,
                                      spacing: 2
                                    }, React.createElement(Core.Grid, {
                                          children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                                onChange: Curry._1(form.updatePaidTo, (function (input, paidTo) {
                                                        return {
                                                                countryCode: input.countryCode,
                                                                localActionTypeID: input.localActionTypeID,
                                                                localActionTypeLabel: input.localActionTypeLabel,
                                                                localActionDescription: input.localActionDescription,
                                                                localActionTrigger: input.localActionTrigger,
                                                                appCorpChannel: input.appCorpChannel,
                                                                brand: input.brand,
                                                                distributionMode: input.distributionMode,
                                                                appClientChannel: input.appClientChannel,
                                                                appSubChannel: input.appSubChannel,
                                                                marketingCorpCategory: input.marketingCorpCategory,
                                                                marketingCorpType: input.marketingCorpType,
                                                                vmeDestination: input.vmeDestination,
                                                                controllingCorpTypeID: input.controllingCorpTypeID,
                                                                controllingCorpTypeLabel: input.controllingCorpTypeLabel,
                                                                definition: input.definition,
                                                                paidTo: paidTo,
                                                                controllingAppStartDate: input.controllingAppStartDate,
                                                                controllingAppEndDate: input.controllingAppEndDate
                                                              };
                                                      })),
                                                value: form.input.paidTo,
                                                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
                                                label: Messages_Action_Referential$DvmAdminFrontendFr.paidTo
                                              }),
                                          item: true,
                                          xs: Grid$Mui.Xs[4]
                                        }), React.createElement(Core.Grid, {
                                          children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$2),
                                          item: true,
                                          xs: Grid$Mui.Xs[4]
                                        }), React.createElement(Core.Grid, {
                                          children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$3),
                                          item: true,
                                          xs: Grid$Mui.Xs[4]
                                        })), React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Grid, {
                                            children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$4),
                                            item: true,
                                            xs: Grid$Mui.Xs[12]
                                          }),
                                      container: true,
                                      item: true,
                                      spacing: 2
                                    })), React.createElement(Core.Grid, {
                                  children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                        label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                        color: "primary",
                                        size: "medium",
                                        type_: "submit"
                                      }),
                                  item: true
                                })))
                  })
            });
}

var Api;

var Form;

var InputStyles;

var make = Action_Referential_Update_Modal;

export {
  Api ,
  Form ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
