// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../common/utils/Utils_Date.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../common/types/App_Types_Date.bs.js";
import * as Company_Search_Types$DvmAdminFrontendFr from "./search/Company_Search_Types.bs.js";
import * as Messages_Company_List$DvmAdminFrontendFr from "../../intl/messages/company/Messages_Company_List.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../intl/messages/Messages_Error_Inputs.bs.js";

function endDateFormValidation(endDate, active) {
  var endDateStr = Belt_Option.mapWithDefault(endDate, undefined, (function (date) {
          if (!App_Types_Date$DvmAdminFrontendFr.isValid(date)) {
            return ;
          }
          var date$1 = Utils_Date$DvmAdminFrontendFr.toISODateString(Caml_option.some(date));
          if (date$1 !== undefined && date$1.length !== 0) {
            return date$1;
          }
          
        }));
  var endDateInvalid = Belt_Option.mapWithDefault(endDate, false, (function (date) {
          return !App_Types_Date$DvmAdminFrontendFr.isValid(date);
        }));
  var todayDate = new Date(Date.now());
  var todayDateStr = Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(Caml_option.some(todayDate)), "");
  var activeFalse = function (dateStr) {
    return "" + Company_Search_Types$DvmAdminFrontendFr.Fields.endDate + "=%5B%5C%2C" + dateStr + "%5D";
  };
  var activeTrue = function (dateStr) {
    return "" + Company_Search_Types$DvmAdminFrontendFr.Fields.endDate + "=%5B" + todayDateStr + "%5C%2C" + dateStr + "%5D;" + Company_Search_Types$DvmAdminFrontendFr.Fields.endDate + "=NULL";
  };
  switch (active) {
    case "FALSE" :
        if (endDateStr !== undefined) {
          return Belt_Option.mapWithDefault(endDate, {
                      TAG: /* Error */1,
                      _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                    }, (function (date) {
                        if (ReDate.isAfter(date, todayDate)) {
                          return {
                                  TAG: /* Error */1,
                                  _0: Messages_Company_List$DvmAdminFrontendFr.activeDateProblem
                                };
                        } else {
                          return {
                                  TAG: /* Ok */0,
                                  _0: activeFalse(endDateStr)
                                };
                        }
                      }));
        } else if (endDateInvalid) {
          return {
                  TAG: /* Error */1,
                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                };
        } else {
          return {
                  TAG: /* Ok */0,
                  _0: activeFalse(todayDateStr)
                };
        }
    case "TRUE" :
        if (endDateStr !== undefined) {
          return Belt_Option.mapWithDefault(endDate, {
                      TAG: /* Error */1,
                      _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                    }, (function (date) {
                        if (ReDate.isBefore(date, todayDate)) {
                          return {
                                  TAG: /* Error */1,
                                  _0: Messages_Company_List$DvmAdminFrontendFr.activeDateProblem
                                };
                        } else {
                          return {
                                  TAG: /* Ok */0,
                                  _0: activeTrue(endDateStr)
                                };
                        }
                      }));
        } else if (endDateInvalid) {
          return {
                  TAG: /* Error */1,
                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                };
        } else {
          return {
                  TAG: /* Ok */0,
                  _0: activeTrue("")
                };
        }
    default:
      if (endDateStr !== undefined) {
        return {
                TAG: /* Ok */0,
                _0: "" + Company_Search_Types$DvmAdminFrontendFr.Fields.endDate + "=" + endDateStr + ""
              };
      } else if (endDateInvalid) {
        return {
                TAG: /* Error */1,
                _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
  }
}

export {
  endDateFormValidation ,
}
/* Utils_Date-DvmAdminFrontendFr Not a pure module */
