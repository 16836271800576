// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../common/types/App_Types_Date.bs.js";

var Fields = {
  active: "active",
  dr: "dr",
  contractId: "contractId",
  vehicleType: "vehicleType",
  siret: "siret",
  name: "companyName",
  dealerId: "dealerID",
  volume: "volume",
  startDate: "attachmentStartDate",
  endDate: "attachmentEndDate"
};

function attachmentRow_encode(v) {
  return Js_dict.fromArray([
              [
                "contractId",
                Decco.stringToJson(v.contractId)
              ],
              [
                "dr",
                Decco.stringToJson(v.dr)
              ],
              [
                "vehicleType",
                Decco.stringToJson(v.vehicleType)
              ],
              [
                "quotaStartDate",
                Decco.stringToJson(v.quotaStartDate)
              ],
              [
                "siret",
                Decco.stringToJson(v.siret)
              ],
              [
                "companyName",
                Decco.optionToJson(Decco.stringToJson, v.companyName)
              ],
              [
                "dealerId",
                Decco.stringToJson(v.dealerId)
              ],
              [
                "volume",
                Decco.intToJson(v.volume)
              ],
              [
                "consumed",
                Decco.optionToJson(Decco.intToJson, v.consumed)
              ],
              [
                "attachmentStartDate",
                Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.attachmentStartDate)
              ],
              [
                "attachmentEndDate",
                Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.attachmentEndDate)
              ]
            ]);
}

function attachmentRow_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var contractId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractId"), null));
  if (contractId.TAG === /* Ok */0) {
    var dr = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "dr"), null));
    if (dr.TAG === /* Ok */0) {
      var vehicleType = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleType"), null));
      if (vehicleType.TAG === /* Ok */0) {
        var quotaStartDate = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "quotaStartDate"), null));
        if (quotaStartDate.TAG === /* Ok */0) {
          var siret = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "siret"), null));
          if (siret.TAG === /* Ok */0) {
            var companyName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "companyName"), null));
            if (companyName.TAG === /* Ok */0) {
              var dealerId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerId"), null));
              if (dealerId.TAG === /* Ok */0) {
                var volume = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "volume"), null));
                if (volume.TAG === /* Ok */0) {
                  var consumed = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "consumed"), null));
                  if (consumed.TAG === /* Ok */0) {
                    var attachmentStartDate = Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "attachmentStartDate"), null));
                    if (attachmentStartDate.TAG === /* Ok */0) {
                      var attachmentEndDate = Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "attachmentEndDate"), null));
                      if (attachmentEndDate.TAG === /* Ok */0) {
                        return {
                                TAG: /* Ok */0,
                                _0: {
                                  contractId: contractId._0,
                                  dr: dr._0,
                                  vehicleType: vehicleType._0,
                                  quotaStartDate: quotaStartDate._0,
                                  siret: siret._0,
                                  companyName: companyName._0,
                                  dealerId: dealerId._0,
                                  volume: volume._0,
                                  consumed: consumed._0,
                                  attachmentStartDate: attachmentStartDate._0,
                                  attachmentEndDate: attachmentEndDate._0
                                }
                              };
                      }
                      var e = attachmentEndDate._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".attachmentEndDate" + e.path,
                                message: e.message,
                                value: e.value
                              }
                            };
                    }
                    var e$1 = attachmentStartDate._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".attachmentStartDate" + e$1.path,
                              message: e$1.message,
                              value: e$1.value
                            }
                          };
                  }
                  var e$2 = consumed._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".consumed" + e$2.path,
                            message: e$2.message,
                            value: e$2.value
                          }
                        };
                }
                var e$3 = volume._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".volume" + e$3.path,
                          message: e$3.message,
                          value: e$3.value
                        }
                      };
              }
              var e$4 = dealerId._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".dealerId" + e$4.path,
                        message: e$4.message,
                        value: e$4.value
                      }
                    };
            }
            var e$5 = companyName._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".companyName" + e$5.path,
                      message: e$5.message,
                      value: e$5.value
                    }
                  };
          }
          var e$6 = siret._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".siret" + e$6.path,
                    message: e$6.message,
                    value: e$6.value
                  }
                };
        }
        var e$7 = quotaStartDate._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".quotaStartDate" + e$7.path,
                  message: e$7.message,
                  value: e$7.value
                }
              };
      }
      var e$8 = vehicleType._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".vehicleType" + e$8.path,
                message: e$8.message,
                value: e$8.value
              }
            };
    }
    var e$9 = dr._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".dr" + e$9.path,
              message: e$9.message,
              value: e$9.value
            }
          };
  }
  var e$10 = contractId._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".contractId" + e$10.path,
            message: e$10.message,
            value: e$10.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(attachmentRow_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(attachmentRow_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function attachmentsSaveRequest_encode(v) {
  return Decco.listToJson(attachmentRow_encode, v);
}

function attachmentsSaveRequest_decode(v) {
  return Decco.listFromJson(attachmentRow_decode, v);
}

function attachmentUpdate_encode(v) {
  return Js_dict.fromArray([
              [
                "attachmentOldStartDate",
                Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.attachmentOldStartDate)
              ],
              [
                "contractId",
                Decco.stringToJson(v.contractId)
              ],
              [
                "dr",
                Decco.stringToJson(v.dr)
              ],
              [
                "vehicleType",
                Decco.stringToJson(v.vehicleType)
              ],
              [
                "quotaStartDate",
                Decco.stringToJson(v.quotaStartDate)
              ],
              [
                "siret",
                Decco.stringToJson(v.siret)
              ],
              [
                "companyName",
                Decco.optionToJson(Decco.stringToJson, v.companyName)
              ],
              [
                "dealerId",
                Decco.stringToJson(v.dealerId)
              ],
              [
                "volume",
                Decco.intToJson(v.volume)
              ],
              [
                "attachmentStartDate",
                Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.attachmentStartDate)
              ],
              [
                "attachmentEndDate",
                Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.attachmentEndDate)
              ]
            ]);
}

function attachmentUpdate_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var attachmentOldStartDate = Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "attachmentOldStartDate"), null));
  if (attachmentOldStartDate.TAG === /* Ok */0) {
    var contractId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractId"), null));
    if (contractId.TAG === /* Ok */0) {
      var dr = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "dr"), null));
      if (dr.TAG === /* Ok */0) {
        var vehicleType = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleType"), null));
        if (vehicleType.TAG === /* Ok */0) {
          var quotaStartDate = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "quotaStartDate"), null));
          if (quotaStartDate.TAG === /* Ok */0) {
            var siret = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "siret"), null));
            if (siret.TAG === /* Ok */0) {
              var companyName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "companyName"), null));
              if (companyName.TAG === /* Ok */0) {
                var dealerId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerId"), null));
                if (dealerId.TAG === /* Ok */0) {
                  var volume = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "volume"), null));
                  if (volume.TAG === /* Ok */0) {
                    var attachmentStartDate = Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "attachmentStartDate"), null));
                    if (attachmentStartDate.TAG === /* Ok */0) {
                      var attachmentEndDate = Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "attachmentEndDate"), null));
                      if (attachmentEndDate.TAG === /* Ok */0) {
                        return {
                                TAG: /* Ok */0,
                                _0: {
                                  attachmentOldStartDate: attachmentOldStartDate._0,
                                  contractId: contractId._0,
                                  dr: dr._0,
                                  vehicleType: vehicleType._0,
                                  quotaStartDate: quotaStartDate._0,
                                  siret: siret._0,
                                  companyName: companyName._0,
                                  dealerId: dealerId._0,
                                  volume: volume._0,
                                  attachmentStartDate: attachmentStartDate._0,
                                  attachmentEndDate: attachmentEndDate._0
                                }
                              };
                      }
                      var e = attachmentEndDate._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".attachmentEndDate" + e.path,
                                message: e.message,
                                value: e.value
                              }
                            };
                    }
                    var e$1 = attachmentStartDate._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".attachmentStartDate" + e$1.path,
                              message: e$1.message,
                              value: e$1.value
                            }
                          };
                  }
                  var e$2 = volume._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".volume" + e$2.path,
                            message: e$2.message,
                            value: e$2.value
                          }
                        };
                }
                var e$3 = dealerId._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".dealerId" + e$3.path,
                          message: e$3.message,
                          value: e$3.value
                        }
                      };
              }
              var e$4 = companyName._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".companyName" + e$4.path,
                        message: e$4.message,
                        value: e$4.value
                      }
                    };
            }
            var e$5 = siret._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".siret" + e$5.path,
                      message: e$5.message,
                      value: e$5.value
                    }
                  };
          }
          var e$6 = quotaStartDate._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".quotaStartDate" + e$6.path,
                    message: e$6.message,
                    value: e$6.value
                  }
                };
        }
        var e$7 = vehicleType._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".vehicleType" + e$7.path,
                  message: e$7.message,
                  value: e$7.value
                }
              };
      }
      var e$8 = dr._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".dr" + e$8.path,
                message: e$8.message,
                value: e$8.value
              }
            };
    }
    var e$9 = contractId._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".contractId" + e$9.path,
              message: e$9.message,
              value: e$9.value
            }
          };
  }
  var e$10 = attachmentOldStartDate._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".attachmentOldStartDate" + e$10.path,
            message: e$10.message,
            value: e$10.value
          }
        };
}

function attachmentsUpdateRequest_encode(v) {
  return Decco.listToJson(attachmentUpdate_encode, v);
}

function attachmentsUpdateRequest_decode(v) {
  return Decco.listFromJson(attachmentUpdate_decode, v);
}

var DetailAttachment = {
  attachmentRow_encode: attachmentRow_encode,
  attachmentRow_decode: attachmentRow_decode,
  searchResult_encode: searchResult_encode,
  searchResult_decode: searchResult_decode,
  attachmentsSaveRequest_encode: attachmentsSaveRequest_encode,
  attachmentsSaveRequest_decode: attachmentsSaveRequest_decode,
  attachmentUpdate_encode: attachmentUpdate_encode,
  attachmentUpdate_decode: attachmentUpdate_decode,
  attachmentsUpdateRequest_encode: attachmentsUpdateRequest_encode,
  attachmentsUpdateRequest_decode: attachmentsUpdateRequest_decode
};

var volumeOfAttachments_encode = Decco.intToJson;

var volumeOfAttachments_decode = Decco.intFromJson;

export {
  Fields ,
  DetailAttachment ,
  volumeOfAttachments_encode ,
  volumeOfAttachments_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
