// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";

var link = Css.css(Object.assign({}, {
          color: Theme_Colors$DvmAdminFrontendFr.black,
          cursor: "pointer"
        }, {
          "&:hover": {
            color: Theme_Colors$DvmAdminFrontendFr.grayMain
          }
        }));

var iconStyle = Css.css({
      paddingRight: "5px"
    });

var secondary = Css.css({
      color: Theme_Colors$DvmAdminFrontendFr.primaryBrown
    });

var blue = Css.css({
      color: Theme_Colors$DvmAdminFrontendFr.secondaryBlueHover
    });

function App_Link(Props) {
  var onClick = Props.onClick;
  var label = Props.label;
  var icon = Props.icon;
  var color = Props.color;
  var style = Props.style;
  var target = Props.target;
  var href = Props.href;
  var tmp = {
    noWrap: true,
    children: React.createElement(Core.Box, {
          children: null,
          alignItems: "center",
          display: "flex"
        }, Belt_Option.mapWithDefault(icon, null, (function (i) {
                return React.createElement(Core.Box, {
                            className: Belt_Option.isSome(label) ? iconStyle : "",
                            children: i,
                            display: "flex"
                          });
              })), React.createElement(Core.Box, {
              children: Belt_Option.mapWithDefault(label, null, (function (l) {
                      return l;
                    }))
            })),
    className: Css.cx([
          link,
          Belt_Option.mapWithDefault(color, "", (function (c) {
                  if (c === "secondary") {
                    return secondary;
                  } else {
                    return blue;
                  }
                }))
        ]),
    underline: "none"
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (href !== undefined) {
    tmp.href = href;
  }
  if (target !== undefined) {
    tmp.target = target;
  }
  return React.createElement(Core.Link, tmp);
}

var make = App_Link;

export {
  link ,
  iconStyle ,
  secondary ,
  blue ,
  make ,
}
/* link Not a pure module */
