// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Button.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Result.bs.js";
import * as Flash_Detail_Api$DvmAdminFrontendFr from "../../../../../flash/detail/Flash_Detail_Api.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Orders_DealerRequest$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_DealerRequest.bs.js";
import * as Order_Detail_Bonus_Derogate_Order_Info$DvmAdminFrontendFr from "../../derogate/create/Order_Detail_Bonus_Derogate_Order_Info.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Inputs$DvmAdminFrontendFr from "./Order_Detail_Bonus_DealerRequest_Inputs.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Confirm$DvmAdminFrontendFr from "../confirm/Order_Detail_Bonus_DealerRequest_Confirm.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Refused$DvmAdminFrontendFr from "../confirm/Order_Detail_Bonus_DealerRequest_Refused.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Create_Api$DvmAdminFrontendFr from "./Order_Detail_Bonus_DealerRequest_Create_Api.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Action_Info$DvmAdminFrontendFr from "./Order_Detail_Bonus_DealerRequest_Action_Info.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Create_Form$DvmAdminFrontendFr from "./Order_Detail_Bonus_DealerRequest_Create_Form.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Create_Types$DvmAdminFrontendFr from "./Order_Detail_Bonus_DealerRequest_Create_Types.bs.js";

function Order_Detail_Bonus_DealerRequest_Create(Props) {
  var order = Props.order;
  var orderId = Props.orderId;
  var action = Props.action;
  var onClose = Props.onClose;
  var onOk = Props.onOk;
  var orderType = Props.orderType;
  var savedDocuments = Props.savedDocuments;
  var reloadPage = Props.reloadPage;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var alert = ReactAlert.useAlert();
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setActionIdOptions = match$2[1];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match$3[1];
  var saveResult = match$3[0];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDocumentTypes = match$5[1];
  var match$6 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setFetchedFlash = match$6[1];
  var fetchedFlash = match$6[0];
  var match$7 = React.useState(function () {
        return null;
      });
  var setModal = match$7[1];
  var match$8 = React.useState(function () {
        return /* [] */0;
      });
  var newDocuments = match$8[0];
  var match$9 = React.useState(function () {
        return savedDocuments;
      });
  var existingDocuments = match$9[0];
  var form = Order_Detail_Bonus_DealerRequest_Create_Form$DvmAdminFrontendFr.Form.useForm(action !== undefined ? ({
            actionId: action.actionId,
            amount: Belt_Option.mapWithDefault(action.amount, "", (function (prim) {
                    return String(prim);
                  })),
            perimeter: "",
            comment: Belt_Option.getWithDefault(action.comment, "")
          }) : (
          action !== undefined ? ({
                actionId: action.actionId,
                amount: Order_Detail_Bonus_DealerRequest_Create_Form$DvmAdminFrontendFr.initialState.amount,
                perimeter: Order_Detail_Bonus_DealerRequest_Create_Form$DvmAdminFrontendFr.initialState.perimeter,
                comment: Order_Detail_Bonus_DealerRequest_Create_Form$DvmAdminFrontendFr.initialState.comment
              }) : Order_Detail_Bonus_DealerRequest_Create_Form$DvmAdminFrontendFr.initialState
        ), (function (output, submissionCallbacks) {
          Order_Detail_Bonus_DealerRequest_Create_Api$DvmAdminFrontendFr.saveOrEditRequest(userManager, setSaveResult, orderId, submissionCallbacks, selectedRole, Belt_Option.isSome(action) ? /* Edit */0 : /* Create */1, output);
        }));
  React.useEffect((function () {
          Belt_Option.mapWithDefault(action, undefined, (function (action) {
                  Belt_Option.mapWithDefault(order.orderType, undefined, (function (orderType) {
                          if (orderType === /* CL */1) {
                            return Flash_Detail_Api$DvmAdminFrontendFr.fetchFlash(action.actionId, userManager, setFetchedFlash, selectedRole, /* NonFleet */1);
                          }
                          
                        }));
                }));
        }), []);
  React.useEffect((function () {
          Belt_Option.mapWithDefault(action, undefined, (function (param) {
                  Belt_Option.mapWithDefault(order.orderType, undefined, (function (orderType) {
                          if (orderType === /* CL */1 && !(typeof fetchedFlash === "number" || fetchedFlash.TAG !== /* Ok */0)) {
                            return Curry._2(form.updatePerimeter, (function (input, perimeter) {
                                          return {
                                                  actionId: input.actionId,
                                                  amount: input.amount,
                                                  perimeter: perimeter,
                                                  comment: input.comment
                                                };
                                        }), Belt_Option.getWithDefault(fetchedFlash._0.requestType, ""));
                          }
                          
                        }));
                }));
        }), [fetchedFlash]);
  React.useEffect((function () {
          if (form.input.perimeter.length > 0 || orderType === /* SO */0) {
            Order_Detail_Bonus_DealerRequest_Create_Api$DvmAdminFrontendFr.fetchActionSelect(alert, userManager, setActionIdOptions, selectedRole, orderType === /* SO */0 ? "PERF" : form.input.perimeter, orderId);
          }
          
        }), [form.input.perimeter]);
  var submit = function (param) {
    Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
    Curry._1(onOk, undefined);
  };
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            if (Belt_List.length(newDocuments) === 0) {
              submit(undefined);
            }
            
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  React.useEffect((function () {
          if (Belt_List.every(newDocuments, (function (x) {
                    return x.uploaded;
                  })) && Belt_List.length(newDocuments) > 0) {
            submit(undefined);
          }
          
        }), [newDocuments]);
  React.useEffect((function () {
          if (form.input.perimeter.length > 0) {
            Order_Detail_Bonus_DealerRequest_Create_Api$DvmAdminFrontendFr.fetchDocumentTypes(userManager, setDocumentTypes, selectedRole, form.input.perimeter);
          }
          
        }), [form.input.perimeter]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: (function (param) {
                  Curry._1(onClose, undefined);
                  if (Belt_List.length(existingDocuments) !== Belt_List.length(savedDocuments)) {
                    return Curry._1(reloadPage, undefined);
                  }
                  
                }),
              title: intl.formatMessage(Messages_Orders_DealerRequest$DvmAdminFrontendFr.dealerRequestTitle, {
                    id: order.orderNumberCustomer
                  }),
              minWidth: "1200px",
              children: null
            }, React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                  show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult) || App_Types_Result$DvmAdminFrontendFr.isPending(fetchedFlash),
                  children: React.createElement("form", {
                        onKeyDown: (function ($$event) {
                            if ($$event.key !== "Enter") {
                              return ;
                            } else {
                              $$event.preventDefault();
                              return ;
                            }
                          }),
                        onSubmit: (function ($$event) {
                            $$event.preventDefault();
                            Curry._1(setModal, (function (param) {
                                    if (Belt_Option.getWithDefault(Belt_Float.fromString(form.input.amount), 0.0) >= Order_Detail_Bonus_DealerRequest_Create_Types$DvmAdminFrontendFr.maxAmount) {
                                      return React.createElement(Order_Detail_Bonus_DealerRequest_Refused$DvmAdminFrontendFr.make, {
                                                  amount: Order_Detail_Bonus_DealerRequest_Create_Types$DvmAdminFrontendFr.maxAmount,
                                                  onClose: (function (param) {
                                                      Curry._1(setModal, (function (param) {
                                                              return null;
                                                            }));
                                                    })
                                                });
                                    } else {
                                      return React.createElement(Order_Detail_Bonus_DealerRequest_Confirm$DvmAdminFrontendFr.make, {
                                                  amount: Belt_Option.getWithDefault(Belt_Float.fromString(form.input.amount), 0.0),
                                                  onClose: (function (param) {
                                                      Curry._1(setModal, (function (param) {
                                                              return null;
                                                            }));
                                                    }),
                                                  onOk: (function (param) {
                                                      Curry._1(setModal, (function (param) {
                                                              return null;
                                                            }));
                                                      Curry._1(form.submit, undefined);
                                                    }),
                                                  modification: Belt_Option.isSome(action)
                                                });
                                    }
                                  }));
                          })
                      }, React.createElement(Core.Box, {
                            children: null,
                            marginX: 1
                          }, React.createElement(Core.Grid, {
                                children: null,
                                container: true,
                                direction: "column",
                                spacing: 3
                              }, React.createElement(Order_Detail_Bonus_Derogate_Order_Info$DvmAdminFrontendFr.make, {
                                    order: order
                                  }), React.createElement(Order_Detail_Bonus_DealerRequest_Action_Info$DvmAdminFrontendFr.make, {
                                    orderType: orderType,
                                    actionId: form.input.actionId,
                                    actionFetch: match$4[0],
                                    setActionFetch: match$4[1]
                                  })), React.createElement(Order_Detail_Bonus_DealerRequest_Inputs$DvmAdminFrontendFr.make, {
                                action: action,
                                form: form,
                                actionIdOptions: match$2[0],
                                order: order,
                                newDocuments: newDocuments,
                                setNewDocuments: match$8[1],
                                saveResult: saveResult,
                                orderId: orderId,
                                existingDocuments: existingDocuments,
                                setExistingDocuments: match$9[1],
                                documentTypes: match$5[0]
                              }), React.createElement(Core.Box, {
                                children: React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Grid, {
                                            children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                  label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                                  size: "medium",
                                                  type_: "submit"
                                                }),
                                            item: true
                                          }),
                                      container: true,
                                      justify: "flex-end"
                                    }),
                                mt: 2
                              })))
                }), match$7[0]);
}

var Form;

var Api;

var Types;

var make = Order_Detail_Bonus_DealerRequest_Create;

export {
  Form ,
  Api ,
  Types ,
  make ,
}
/* react Not a pure module */
