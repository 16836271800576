// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as RestApi$DvmAdminFrontendFr from "../../api/RestApi.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../common/components/icons/App_Icon.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../context/user/User_Context.bs.js";
import * as Messages_Energy$DvmAdminFrontendFr from "../../intl/messages/energy/Messages_Energy.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../common/components/App_OverlaySpinner.bs.js";

function dismissMessage(userManager, setResult, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", undefined, userManager, selectedRole, "/enginetypes/noted").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function isNoted_encode(v) {
  return Js_dict.fromArray([[
                "response",
                Decco.boolToJson(v.response)
              ]]);
}

function isNoted_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var response = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "response"), null));
  if (response.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              response: response._0
            }
          };
  }
  var e = response._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".response" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function fetchIfNoted(userManager, setResult, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", isNoted_decode, undefined, userManager, selectedRole, "/enginetypes/noted").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function Energy_New_Types_Menu_Icon(Props) {
  var alert = ReactAlert.useAlert();
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setNotedResult = match[1];
  var notedResult = match[0];
  var match$1 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDismissResult = match$1[1];
  var dismissResult = match$1[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match$2 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$3 = match$2[0];
  var userManager = match$3.userManager;
  var selectedRole = match$3.selectedRole;
  React.useEffect((function () {
          fetchIfNoted(userManager, setNotedResult, selectedRole);
        }), [url]);
  React.useEffect((function () {
          if (typeof dismissResult === "number") {
            dismissResult === /* NotStarted */0;
          } else if (dismissResult.TAG === /* Ok */0) {
            fetchIfNoted(userManager, setNotedResult, selectedRole);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: dismissResult._0
                      }
                    }));
          }
        }), [dismissResult]);
  if (typeof notedResult === "number" || !(notedResult.TAG === /* Ok */0 && !notedResult._0.response)) {
    return null;
  } else {
    return React.createElement(Core.Box, {
                children: React.createElement(Core.Tooltip, {
                      arrow: true,
                      children: React.createElement("div", undefined, React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                show: App_Types_Result$DvmAdminFrontendFr.isPending(dismissResult),
                                children: React.createElement(Core.Button, {
                                      onClick: (function (param) {
                                          dismissMessage(userManager, setDismissResult, selectedRole);
                                        }),
                                      children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                            size: /* XLarge */4,
                                            type_: /* CheckOil */69,
                                            color: "orange"
                                          })
                                    })
                              })),
                      classes: {
                        tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
                      },
                      placement: "bottom",
                      title: React.createElement("p", undefined, intl.formatMessage(Messages_Energy$DvmAdminFrontendFr.newEnergyTypesIconNotification))
                    })
              });
  }
}

var make = Energy_New_Types_Menu_Icon;

export {
  dismissMessage ,
  isNoted_encode ,
  isNoted_decode ,
  fetchIfNoted ,
  make ,
}
/* react Not a pure module */
