// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";

var Fields = {
  dr: "dr",
  dealerId: "dealerID",
  dealerName: "dealerName",
  entityLabel: "entityLabel",
  ipnKeyAccountManager: "ipnKeyAccountManager",
  keyAccountManagerName: "keyAccountManagerName",
  city: "city"
};

function row_encode(v) {
  return Js_dict.fromArray([
              [
                "dr",
                Decco.stringToJson(v.dr)
              ],
              [
                "dealerID",
                Decco.stringToJson(v.dealerID)
              ],
              [
                "dealerName",
                Decco.optionToJson(Decco.stringToJson, v.dealerName)
              ],
              [
                "entityLabel",
                Decco.optionToJson(Decco.stringToJson, v.entityLabel)
              ],
              [
                "ipnKeyAccountManager",
                Decco.optionToJson(Decco.stringToJson, v.ipnKeyAccountManager)
              ],
              [
                "keyAccountManagerName",
                Decco.optionToJson(Decco.stringToJson, v.keyAccountManagerName)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "city",
                Decco.optionToJson(Decco.stringToJson, v.city)
              ]
            ]);
}

function row_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var dr = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "dr"), null));
  if (dr.TAG === /* Ok */0) {
    var dealerID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerID"), null));
    if (dealerID.TAG === /* Ok */0) {
      var dealerName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerName"), null));
      if (dealerName.TAG === /* Ok */0) {
        var entityLabel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "entityLabel"), null));
        if (entityLabel.TAG === /* Ok */0) {
          var ipnKeyAccountManager = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "ipnKeyAccountManager"), null));
          if (ipnKeyAccountManager.TAG === /* Ok */0) {
            var keyAccountManagerName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "keyAccountManagerName"), null));
            if (keyAccountManagerName.TAG === /* Ok */0) {
              var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
              if (startDate.TAG === /* Ok */0) {
                var city = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "city"), null));
                if (city.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: {
                            dr: dr._0,
                            dealerID: dealerID._0,
                            dealerName: dealerName._0,
                            entityLabel: entityLabel._0,
                            ipnKeyAccountManager: ipnKeyAccountManager._0,
                            keyAccountManagerName: keyAccountManagerName._0,
                            startDate: startDate._0,
                            city: city._0
                          }
                        };
                }
                var e = city._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".city" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = startDate._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".startDate" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = keyAccountManagerName._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".keyAccountManagerName" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = ipnKeyAccountManager._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".ipnKeyAccountManager" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = entityLabel._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".entityLabel" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = dealerName._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".dealerName" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = dealerID._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".dealerID" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = dr._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".dr" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(row_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(row_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

export {
  Fields ,
  row_encode ,
  row_decode ,
  searchResult_encode ,
  searchResult_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
