// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as FileSaver from "file-saver";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../../../api/RestApi.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../../common/utils/Utils_Alert.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Types$DvmAdminFrontendFr from "../Order_Detail_Bonus_DealerRequest_Types.bs.js";

function fetchDocuments(userManager, setResult, selectedRole, orderId) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Order_Detail_Bonus_DealerRequest_Types$DvmAdminFrontendFr.documentList_decode, undefined, userManager, selectedRole, "/flash/document/" + orderId + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var documents = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: documents
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function getPdf(userManager, setResult, file, alert, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithBlobResponse.call("GET", undefined, userManager, selectedRole, "/flash/document/download/" + file.fileId + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          FileSaver.saveAs(result._0, Belt_Option.getWithDefault(file.fileName, "document.pdf"));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Types;

export {
  Types ,
  fetchDocuments ,
  getPdf ,
}
/* react Not a pure module */
