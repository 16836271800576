// Generated by ReScript, PLEASE EDIT WITH CARE


var actionPublishTitle = {
  id: "1ac939a3-0770-4004-9807-419f11964dab",
  defaultMessage: "Publier les actions commerciales"
};

var publishRunning = {
  id: "5b8f5edc-effe-4e1a-b57f-83aa32663461",
  defaultMessage: "Publication en cours"
};

var fileType = {
  id: "f388279b-e432-4fbb-9b80-de47d8231898",
  defaultMessage: "Type de fichier"
};

var start = {
  id: "31b99555-3b3a-447f-a934-8aee7e47d357",
  defaultMessage: "Début"
};

var end = {
  id: "61f52607-9ea8-4303-8bb5-b19b9d5aae88",
  defaultMessage: "Fin"
};

var batchResult = {
  id: "4b8b5c35-ecad-4473-a4c3-ef2126614eaf",
  defaultMessage: "Résultat"
};

var direction = {
  id: "0c25bc24-a9a3-488a-a662-7a1801260bb4",
  defaultMessage: "Fichiers d'entrée/sortie"
};

var application = {
  id: "3e6f137a-b83e-4e3f-a45a-796b8e66a43d",
  defaultMessage: "Application"
};

export {
  actionPublishTitle ,
  publishRunning ,
  fileType ,
  start ,
  end ,
  batchResult ,
  direction ,
  application ,
}
/* No side effect */
