// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../api/RestApi.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../../common/types/App_Types_Input.bs.js";
import * as Bonus_Anomalies_Search_Types$DvmAdminFrontendFr from "./Bonus_Anomalies_Search_Types.bs.js";

function fetchCompanySearch(submissionCallbacks, userManager, setResult, queryParams, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.searchResult_decode, undefined, userManager, selectedRole, "/anomalies" + queryParams + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchCompanySearch */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchAvailableReasons(userManager, setResult, selectedRole, budgetType, actionType) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var budgetJoined = encodeURIComponent(Belt_Array.joinWith(Belt_List.toArray(budgetType), "\\,", (function (x) {
              return x;
            })));
  var match = Belt_List.length(budgetType) > 0;
  var match$1 = actionType.length > 0;
  var filters = match ? (
      match$1 ? "&filter=orderBudgetType=" + budgetJoined + ";actionType=" + actionType + "" : "&filter=orderBudgetType=" + budgetJoined + ""
    ) : (
      match$1 ? "&filter=actionType=" + actionType + "" : ""
    );
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.stringList_decode, undefined, userManager, selectedRole, "/anomalies/dropdown?limit=100000&offset=0" + filters + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var reasons = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: reasons
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

export {
  Form ,
  fetchCompanySearch ,
  fetchAvailableReasons ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
