// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../common/types/App_Types_Date.bs.js";

var Fields = {
  model: "model",
  version: "version",
  versionName: "versionName",
  motorisation: "motorisation"
};

function ver_encode(v) {
  return Js_dict.fromArray([
              [
                "version",
                Decco.optionToJson(Decco.stringToJson, v.version)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function ver_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var version = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "version"), null));
  if (version.TAG === /* Ok */0) {
    var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
    if (startDate.TAG === /* Ok */0) {
      var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
      if (endDate.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  version: version._0,
                  startDate: startDate._0,
                  endDate: endDate._0
                }
              };
      }
      var e = endDate._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".endDate" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = startDate._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".startDate" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = version._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".version" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function t_encode(v) {
  return Js_dict.fromArray([
              [
                "categoryCode",
                Decco.stringToJson(v.categoryCode)
              ],
              [
                "categoryName",
                Decco.optionToJson(Decco.stringToJson, v.categoryName)
              ],
              [
                "numOfVersions",
                Decco.intToJson(v.numOfVersions)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ],
              [
                "versions",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(ver_encode, param);
                      }), v.versions)
              ]
            ]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var categoryCode = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "categoryCode"), null));
  if (categoryCode.TAG === /* Ok */0) {
    var categoryName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "categoryName"), null));
    if (categoryName.TAG === /* Ok */0) {
      var numOfVersions = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "numOfVersions"), null));
      if (numOfVersions.TAG === /* Ok */0) {
        var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
        if (startDate.TAG === /* Ok */0) {
          var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
          if (endDate.TAG === /* Ok */0) {
            var versions = Decco.optionFromJson((function (param) {
                    return Decco.listFromJson(ver_decode, param);
                  }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "versions"), null));
            if (versions.TAG === /* Ok */0) {
              return {
                      TAG: /* Ok */0,
                      _0: {
                        categoryCode: categoryCode._0,
                        categoryName: categoryName._0,
                        numOfVersions: numOfVersions._0,
                        startDate: startDate._0,
                        endDate: endDate._0,
                        versions: versions._0
                      }
                    };
            }
            var e = versions._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".versions" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = endDate._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".endDate" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = startDate._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".startDate" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = numOfVersions._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".numOfVersions" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = categoryName._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".categoryName" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = categoryCode._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".categoryCode" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(t_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var CategorySearch = {
  ver_encode: ver_encode,
  ver_decode: ver_decode,
  t_encode: t_encode,
  t_decode: t_decode,
  searchResult_encode: searchResult_encode,
  searchResult_decode: searchResult_decode
};

function version_encode(v) {
  return Js_dict.fromArray([
              [
                "version",
                Decco.stringToJson(v.version)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function version_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var version = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "version"), null));
  if (version.TAG === /* Ok */0) {
    var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
    if (startDate.TAG === /* Ok */0) {
      var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
      if (endDate.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  version: version._0,
                  startDate: startDate._0,
                  endDate: endDate._0
                }
              };
      }
      var e = endDate._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".endDate" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = startDate._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".startDate" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = version._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".version" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function t_encode$1(v) {
  return Js_dict.fromArray([
              [
                "modelID",
                Decco.stringToJson(v.modelID)
              ],
              [
                "categoryID",
                Decco.stringToJson(v.categoryID)
              ],
              [
                "categoryName",
                Decco.optionToJson(Decco.stringToJson, v.categoryName)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ],
              [
                "versions",
                Decco.listToJson(version_encode, v.versions)
              ]
            ]);
}

function t_decode$1(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var modelID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelID"), null));
  if (modelID.TAG === /* Ok */0) {
    var categoryID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "categoryID"), null));
    if (categoryID.TAG === /* Ok */0) {
      var categoryName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "categoryName"), null));
      if (categoryName.TAG === /* Ok */0) {
        var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
        if (startDate.TAG === /* Ok */0) {
          var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
          if (endDate.TAG === /* Ok */0) {
            var versions = Decco.listFromJson(version_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "versions"), null));
            if (versions.TAG === /* Ok */0) {
              return {
                      TAG: /* Ok */0,
                      _0: {
                        modelID: modelID._0,
                        categoryID: categoryID._0,
                        categoryName: categoryName._0,
                        startDate: startDate._0,
                        endDate: endDate._0,
                        versions: versions._0
                      }
                    };
            }
            var e = versions._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".versions" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = endDate._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".endDate" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = startDate._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".startDate" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = categoryName._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".categoryName" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = categoryID._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".categoryID" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = modelID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".modelID" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

var CategoryCreate = {
  version_encode: version_encode,
  version_decode: version_decode,
  t_encode: t_encode$1,
  t_decode: t_decode$1
};

function t_encode$2(v) {
  return Js_dict.fromArray([
              [
                "versionCode",
                Decco.stringToJson(v.versionCode)
              ],
              [
                "versionLabel",
                Decco.optionToJson(Decco.stringToJson, v.versionLabel)
              ],
              [
                "duplicated",
                Decco.optionToJson(Decco.boolToJson, v.duplicated)
              ]
            ]);
}

function t_decode$2(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var versionCode = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "versionCode"), null));
  if (versionCode.TAG === /* Ok */0) {
    var versionLabel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "versionLabel"), null));
    if (versionLabel.TAG === /* Ok */0) {
      var duplicated = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "duplicated"), null));
      if (duplicated.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  versionCode: versionCode._0,
                  versionLabel: versionLabel._0,
                  duplicated: duplicated._0
                }
              };
      }
      var e = duplicated._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".duplicated" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = versionLabel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".versionLabel" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = versionCode._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".versionCode" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function searchResult_encode$1(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(t_encode$2, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode$1(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(t_decode$2, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var VersionSearch = {
  t_encode: t_encode$2,
  t_decode: t_decode$2,
  searchResult_encode: searchResult_encode$1,
  searchResult_decode: searchResult_decode$1
};

function t_encode$3(v) {
  return Js_dict.fromArray([
              [
                "versionCode",
                Decco.stringToJson(v.versionCode)
              ],
              [
                "versionLabel",
                Decco.optionToJson(Decco.stringToJson, v.versionLabel)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function t_decode$3(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var versionCode = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "versionCode"), null));
  if (versionCode.TAG === /* Ok */0) {
    var versionLabel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "versionLabel"), null));
    if (versionLabel.TAG === /* Ok */0) {
      var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
      if (startDate.TAG === /* Ok */0) {
        var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
        if (endDate.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    versionCode: versionCode._0,
                    versionLabel: versionLabel._0,
                    startDate: startDate._0,
                    endDate: endDate._0
                  }
                };
        }
        var e = endDate._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".endDate" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = startDate._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".startDate" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = versionLabel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".versionLabel" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = versionCode._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".versionCode" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function versionEditList_encode(v) {
  return Decco.listToJson(t_encode$3, v);
}

function versionEditList_decode(v) {
  return Decco.listFromJson(t_decode$3, v);
}

var VersionEdit = {
  t_encode: t_encode$3,
  t_decode: t_decode$3,
  versionEditList_encode: versionEditList_encode,
  versionEditList_decode: versionEditList_decode
};

function t_encode$4(v) {
  return Js_dict.fromArray([
              [
                "version",
                Decco.stringToJson(v.version)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function t_decode$4(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var version = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "version"), null));
  if (version.TAG === /* Ok */0) {
    var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
    if (startDate.TAG === /* Ok */0) {
      var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
      if (endDate.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  version: version._0,
                  startDate: startDate._0,
                  endDate: endDate._0
                }
              };
      }
      var e = endDate._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".endDate" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = startDate._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".startDate" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = version._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".version" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function searchResult_encode$2(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(t_encode$4, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode$2(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(t_decode$4, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var TakenVersions = {
  t_encode: t_encode$4,
  t_decode: t_decode$4,
  searchResult_encode: searchResult_encode$2,
  searchResult_decode: searchResult_decode$2
};

export {
  Fields ,
  CategorySearch ,
  CategoryCreate ,
  VersionSearch ,
  VersionEdit ,
  TakenVersions ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
