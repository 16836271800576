// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../../route/Route_Paths.bs.js";

function routeToPage(orderId, param) {
  RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rView + Route_Paths$DvmAdminFrontendFr.rOrder + Belt_Option.mapWithDefault(orderId, "", (function (orderId) {
              return "/" + orderId;
            })));
}

function openNewTab(orderId, param) {
  window.open(Route_Paths$DvmAdminFrontendFr.rView + Route_Paths$DvmAdminFrontendFr.rOrder + Belt_Option.mapWithDefault(orderId, "", (function (orderId) {
              return "/" + orderId;
            })));
}

function routeToCorrectionPage(orderId, whichPage) {
  RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rView + Route_Paths$DvmAdminFrontendFr.rOrder + Belt_Option.mapWithDefault(orderId, "", (function (orderId) {
              return "/" + orderId;
            })) + (
        whichPage === "correction" ? Route_Paths$DvmAdminFrontendFr.rCorrect : Route_Paths$DvmAdminFrontendFr.rHistory
      ));
}

export {
  routeToPage ,
  openNewTab ,
  routeToCorrectionPage ,
}
/* RescriptReactRouter Not a pure module */
