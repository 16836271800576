// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Table$DvmAdminFrontendFr from "../../../common/components/table/App_Table.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Head.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../../context/router/Context_Router.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Pagination.bs.js";
import * as Messages_ModelVersionGrid$DvmAdminFrontendFr from "../../../intl/messages/modelVersionGrid/Messages_ModelVersionGrid.bs.js";
import * as ModelVersion_Grid_Manage_Types$DvmAdminFrontendFr from "./ModelVersion_Grid_Manage_Types.bs.js";
import * as ModelVersion_Grid_Manage_Router$DvmAdminFrontendFr from "./ModelVersion_Grid_Manage_Router.bs.js";
import * as Messages_ModelVersionGrid_Manage$DvmAdminFrontendFr from "../../../intl/messages/modelVersionGrid/Messages_ModelVersionGrid_Manage.bs.js";

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(ModelVersion_Grid_Manage_Types$DvmAdminFrontendFr.Columns.contractId, undefined, undefined, undefined, undefined, undefined, Messages_ModelVersionGrid_Manage$DvmAdminFrontendFr.contractId);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(ModelVersion_Grid_Manage_Types$DvmAdminFrontendFr.Columns.contractLabel, undefined, undefined, undefined, undefined, undefined, Messages_ModelVersionGrid$DvmAdminFrontendFr.labelContract),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(ModelVersion_Grid_Manage_Types$DvmAdminFrontendFr.Columns.budgetType, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.budget),
    tl: /* [] */0
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function ModelVersion_Grid_Manage_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var match = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match[1];
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (param) {
                                  var budgetType = param.budgetType;
                                  var contractLabel = param.contractLabel;
                                  var contractId = param.contractId;
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow((function (param) {
                                                Curry._1(dispatch, {
                                                      TAG: /* StoreModelVersionManageContract */0,
                                                      _0: {
                                                        budgetType: budgetType,
                                                        contractId: contractId,
                                                        contractLabel: Belt_Option.getWithDefault(contractLabel, "")
                                                      }
                                                    });
                                                ModelVersion_Grid_Manage_Router$DvmAdminFrontendFr.routeToEditMVGrid(undefined);
                                              }), undefined, undefined, Belt_List.mapWithIndex({
                                                  hd: contractId,
                                                  tl: {
                                                    hd: Belt_Option.mapWithDefault(contractLabel, "", (function (x) {
                                                            return x;
                                                          })),
                                                    tl: {
                                                      hd: budgetType,
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._2(fetchData, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, {
                  headers: fetchRequest.headers,
                  handleSorting: handleSorting,
                  tablePage: convertTable(tablePage),
                  paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined)
                }));
}

var make = ModelVersion_Grid_Manage_Table;

export {
  headersConfiguration ,
  make ,
}
/* headersConfiguration Not a pure module */
