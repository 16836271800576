// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Intl_Utils$DvmAdminFrontendFr from "./Intl_Utils.bs.js";
import * as Intl_Locale$DvmAdminFrontendFr from "./Intl_Locale.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../context/user/User_Context.bs.js";
import * as Intl_Translations$DvmAdminFrontendFr from "./Intl_Translations.bs.js";

var intlCache = ReactIntl.createIntlCache();

function intlConfig(locale) {
  return {
          locale: Intl_Locale$DvmAdminFrontendFr.toString(locale),
          timeZone: Belt_Option.getWithDefault(new (Intl.DateTimeFormat)([]).resolvedOptions().timeZone, Intl_Utils$DvmAdminFrontendFr.defaultTimeZone),
          messages: Intl_Translations$DvmAdminFrontendFr.getTranslation(locale),
          onError: (function (param) {
              
            })
        };
}

function createIntl(locale) {
  return ReactIntl.createIntl(intlConfig(locale), intlCache);
}

function Intl_Provider(Props) {
  var children = Props.children;
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  return React.createElement(ReactIntl.RawIntlProvider, {
              value: ReactIntl.createIntl(intlConfig(match[0].locale), intlCache),
              children: children
            });
}

var make = Intl_Provider;

export {
  intlCache ,
  intlConfig ,
  createIntl ,
  make ,
}
/* intlCache Not a pure module */
