// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Alert.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Date.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Button.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as Quota_Detail_Attachment_Api$DvmAdminFrontendFr from "../Quota_Detail_Attachment_Api.bs.js";
import * as Quota_Detail_Attachment_Add_Row$DvmAdminFrontendFr from "./Quota_Detail_Attachment_Add_Row.bs.js";
import * as Quota_Detail_Attachment_Add_Form$DvmAdminFrontendFr from "./Quota_Detail_Attachment_Add_Form.bs.js";
import * as Quota_Detail_Attachment_Add_Amount$DvmAdminFrontendFr from "./Quota_Detail_Attachment_Add_Amount.bs.js";

function Quota_Detail_Attachment_Add(Props) {
  var quota = Props.quota;
  var onClose = Props.onClose;
  var attachmentsUpdate = Props.attachmentsUpdate;
  var onOk = Props.onOk;
  var volumeOfAttachments = Props.volumeOfAttachments;
  var drAndDealers = Props.drAndDealers;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var update = Belt_Option.isSome(attachmentsUpdate);
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match[1];
  var saveResult = match[0];
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  var saveAttachments = function (param) {
    Quota_Detail_Attachment_Api$DvmAdminFrontendFr.saveAttachments(setSaveResult, userManager, selectedRole, Belt_List.fromArray(Belt_Array.map(param.attachments, (function (att) {
                    return {
                            contractId: quota.contractId,
                            dr: quota.dr,
                            vehicleType: quota.vehicleType,
                            quotaStartDate: App_Types_Date$DvmAdminFrontendFr.toString(quota.startDate),
                            siret: att.siret,
                            companyName: "",
                            dealerId: att.dealerId,
                            volume: att.volume,
                            consumed: undefined,
                            attachmentStartDate: Belt_Option.getExn(att.attachmentStartDate),
                            attachmentEndDate: Belt_Option.getExn(att.attachmentEndDate)
                          };
                  }))));
  };
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* String */3,
                        _0: "Lignes ajoutées avec succès"
                      }
                    }));
            Curry._1(onOk, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  var form = Quota_Detail_Attachment_Add_Form$DvmAdminFrontendFr.TableDetailAttachmentForm.useForm(Quota_Detail_Attachment_Add_Form$DvmAdminFrontendFr.initialInput, {
        rangeStartDate: Caml_option.some(quota.startDate),
        rangeEndDate: quota.endDate
      }, (function (output, param) {
          if (update) {
            
          } else {
            saveAttachments(output);
          }
          Curry._1(param.notifyOnSuccess, undefined);
        }));
  var handleAmountChange = function (amount) {
    for(var _for = 1; _for <= amount; ++_for){
      Curry._1(form.addAttachment, {
            volumeOrigin: Quota_Detail_Attachment_Add_Form$DvmAdminFrontendFr.initialState.volumeOrigin,
            siret: Quota_Detail_Attachment_Add_Form$DvmAdminFrontendFr.initialState.siret,
            companyName: Quota_Detail_Attachment_Add_Form$DvmAdminFrontendFr.initialState.companyName,
            dealerId: Quota_Detail_Attachment_Add_Form$DvmAdminFrontendFr.initialState.dealerId,
            volume: Quota_Detail_Attachment_Add_Form$DvmAdminFrontendFr.initialState.volume,
            attachmentStartDate: Caml_option.some(quota.startDate),
            attachmentOldStartDate: Quota_Detail_Attachment_Add_Form$DvmAdminFrontendFr.initialState.attachmentOldStartDate,
            attachmentEndDate: quota.endDate,
            dirty: Quota_Detail_Attachment_Add_Form$DvmAdminFrontendFr.initialState.dirty
          });
    }
  };
  React.useEffect((function () {
          if (attachmentsUpdate !== undefined) {
            App_Types_Result$DvmAdminFrontendFr.map(attachmentsUpdate, (function (param) {
                    Belt_List.forEach(param.content, (function (param) {
                            var attachmentStartDate = param.attachmentStartDate;
                            var volume = param.volume;
                            Curry._1(form.addAttachment, {
                                  volumeOrigin: volume,
                                  siret: param.siret,
                                  companyName: Belt_Option.getWithDefault(param.companyName, ""),
                                  dealerId: param.dealerId,
                                  volume: String(volume),
                                  attachmentStartDate: Caml_option.some(attachmentStartDate),
                                  attachmentOldStartDate: Caml_option.some(attachmentStartDate),
                                  attachmentEndDate: Caml_option.some(param.attachmentEndDate),
                                  dirty: false
                                });
                          }));
                  }));
          } else {
            handleAmountChange(1);
          }
        }), []);
  var getTotal = Belt_Option.getWithDefault(quota.volume, 0) - (App_Types_Result$DvmAdminFrontendFr.getWithDefault(volumeOfAttachments, 0) + Belt_Array.reduce(form.input.attachments, 0, (function (acc, value) {
            return acc + (Belt_Option.getWithDefault(Belt_Int.fromString(value.volume), 0) - value.volumeOrigin | 0) | 0;
          })) | 0) | 0;
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.attachmentModalTitle),
              minWidth: "900px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult) || App_Types_Result$DvmAdminFrontendFr.isPending(volumeOfAttachments),
                    children: React.createElement(Core.Grid, {
                          alignItems: "stretch",
                          children: null,
                          container: true,
                          direction: "column",
                          spacing: 2
                        }, React.createElement(Core.Grid, {
                              alignItems: "center",
                              children: null,
                              container: true,
                              item: true,
                              justifyContent: "space-between"
                            }, React.createElement(Core.Grid, {
                                  children: update ? null : React.createElement(Quota_Detail_Attachment_Add_Amount$DvmAdminFrontendFr.make, {
                                          handleAmountChange: handleAmountChange,
                                          formAttachments: form
                                        }),
                                  item: true
                                }), React.createElement(Core.Grid, {
                                  alignItems: "center",
                                  children: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.attachmentModalRemaining, {
                                        sum: getTotal,
                                        span: (function (chunk) {
                                            return React.createElement("span", {
                                                        style: {
                                                          color: getTotal < 0 ? Theme_Colors$DvmAdminFrontendFr.red : Theme_Colors$DvmAdminFrontendFr.secondaryBlueDark
                                                        }
                                                      }, chunk);
                                          }),
                                        total: quota.volume
                                      }),
                                  item: true
                                })), React.createElement("form", {
                              onSubmit: (function ($$event) {
                                  $$event.preventDefault();
                                  Curry._1(form.submit, undefined);
                                })
                            }, React.createElement(Core.Grid, {
                                  children: Belt_Array.mapWithIndex(form.input.attachments, (function (at, attachment) {
                                          return React.createElement(Quota_Detail_Attachment_Add_Row$DvmAdminFrontendFr.make, {
                                                      quota: quota,
                                                      attachment: attachment,
                                                      form: form,
                                                      at: at,
                                                      drAndDealers: drAndDealers,
                                                      key: "quota-attachment-item-" + String(at) + ""
                                                    });
                                        })),
                                  item: true
                                }), React.createElement(Core.Grid, {
                                  children: React.createElement(Core.Grid, {
                                        children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                              label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                              size: "medium",
                                              disabled: getTotal < 0,
                                              type_: "submit"
                                            }),
                                        item: true
                                      }),
                                  container: true,
                                  item: true,
                                  justifyContent: "flex-end",
                                  spacing: 2
                                })))
                  })
            });
}

var Form;

var Amount;

var Api;

var Attachment;

var make = Quota_Detail_Attachment_Add;

export {
  Form ,
  Amount ,
  Api ,
  Attachment ,
  make ,
}
/* react Not a pure module */
