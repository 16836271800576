// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Button.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Orders_DealerRequest$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_DealerRequest.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Create_Api$DvmAdminFrontendFr from "../create/Order_Detail_Bonus_DealerRequest_Create_Api.bs.js";

function Order_Detail_Bonus_DealerRequest_Delete(Props) {
  var onClose = Props.onClose;
  var orderId = Props.orderId;
  var actionId = Props.actionId;
  var reloadPage = Props.reloadPage;
  var filteredDocs = Props.filteredDocs;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$2[1];
  var result = match$2[0];
  var match$3 = React.useState(function () {
        return /* [] */0;
      });
  var setDocumentsDeleteResult = match$3[1];
  var documentsDeleteResult = match$3[0];
  var alert = ReactAlert.useAlert();
  React.useEffect((function () {
          if (typeof result === "number") {
            result === /* NotStarted */0;
          } else if (result.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(reloadPage, undefined);
            Curry._1(onClose, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: result._0
                      }
                    }));
          }
        }), [result]);
  var deleteRequest = function (param) {
    Order_Detail_Bonus_DealerRequest_Create_Api$DvmAdminFrontendFr.deleteRequest(userManager, selectedRole, orderId, Belt_Option.getWithDefault(actionId, ""), setResult);
  };
  React.useEffect((function () {
          var match = Belt_List.length(filteredDocs) > 0;
          var match$1 = Belt_List.length(documentsDeleteResult) > 0;
          if (match && match$1 && Belt_List.length(Belt_List.keep(documentsDeleteResult, App_Types_Result$DvmAdminFrontendFr.isSuccess)) === Belt_List.length(filteredDocs)) {
            deleteRequest(undefined);
          }
          
        }), [documentsDeleteResult]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: App_Types_Result$DvmAdminFrontendFr.isPending(result) || Belt_List.length(Belt_List.keep(documentsDeleteResult, App_Types_Result$DvmAdminFrontendFr.isPending)) > 0 ? (function (param) {
                    
                  }) : onClose,
              title: intl.formatMessage(Messages_Orders_DealerRequest$DvmAdminFrontendFr.dealerRequestConfirmDelete),
              minWidth: "600px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(result) || Belt_List.length(Belt_List.keep(documentsDeleteResult, App_Types_Result$DvmAdminFrontendFr.isPending)) > 0,
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          item: true,
                          justifyContent: "space-between"
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                    onClick: (function (param) {
                                        Curry._1(onClose, undefined);
                                      }),
                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.cancel),
                                    color: "primaryBorder",
                                    size: "medium"
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                    onClick: (function (param) {
                                        if (Belt_List.length(filteredDocs) > 0) {
                                          Belt_List.map(filteredDocs, (function (x) {
                                                  Order_Detail_Bonus_DealerRequest_Create_Api$DvmAdminFrontendFr.deleteFile(userManager, x, selectedRole, orderId, Belt_Option.getWithDefault(actionId, ""), (function (res) {
                                                          Curry._1(setDocumentsDeleteResult, (function (existing) {
                                                                  return Belt_List.add(existing, Curry._1(res, undefined));
                                                                }));
                                                        }));
                                                }));
                                          return ;
                                        } else {
                                          return deleteRequest(undefined);
                                        }
                                      }),
                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.$$delete),
                                    color: "delete",
                                    size: "medium"
                                  }),
                              item: true
                            }))
                  })
            });
}

var Api;

var make = Order_Detail_Bonus_DealerRequest_Delete;

export {
  Api ,
  make ,
}
/* react Not a pure module */
