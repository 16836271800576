// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../../common/App_Api.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Head.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Table_Simple$DvmAdminFrontendFr from "../../../../common/components/table/App_Table_Simple.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as Messages_Contr_Man_Search$DvmAdminFrontendFr from "../../../../intl/messages/contractManagers/Messages_Contr_Man_Search.bs.js";
import * as Contract_Manager_Edit_Form$DvmAdminFrontendFr from "../Contract_Manager_Edit_Form.bs.js";
import * as Contract_Manager_Search_Types$DvmAdminFrontendFr from "../../search/Contract_Manager_Search_Types.bs.js";
import * as Contract_Manager_Edit_Table_Row$DvmAdminFrontendFr from "./Contract_Manager_Edit_Table_Row.bs.js";

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(undefined, undefined, false, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.budget);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Contract_Manager_Search_Types$DvmAdminFrontendFr.Fields.startDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.creationDate),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Contract_Manager_Search_Types$DvmAdminFrontendFr.Fields.endDate, undefined, undefined, undefined, undefined, undefined, Messages_Contr_Man_Search$DvmAdminFrontendFr.endingDate),
    tl: /* [] */0
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function Contract_Manager_Edit_Table(Props) {
  var fetchRequest = Props.fetchRequest;
  var form = Props.form;
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setBudgets = match$2[1];
  var budgets = match$2[0];
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchBudgets(userManager, setBudgets, selectedRole);
        }), []);
  var convertTable = function (param) {
    return App_Types_Result$DvmAdminFrontendFr.map({
                TAG: /* Ok */0,
                _0: undefined
              }, (function (param) {
                  return Belt_List.fromArray(Belt_Array.mapWithIndex(form.input.budgetRows, (function (index, param) {
                                    return Contract_Manager_Edit_Table_Row$DvmAdminFrontendFr.makeRow(form, index, budgets, form.input.budgetRows.length > 1);
                                  })));
                }));
  };
  return React.createElement(App_Table_Simple$DvmAdminFrontendFr.make, {
              headers: fetchRequest.headers,
              content: convertTable(undefined),
              rowButton: {
                TAG: /* Right */1,
                _0: {
                  TAG: /* ReactElement */1,
                  _0: React.createElement(Core.Button, {
                        onClick: (function (param) {
                            Curry._1(form.addBudgetRow, Contract_Manager_Edit_Form$DvmAdminFrontendFr.initialBudgetRowInput);
                          }),
                        children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                              size: /* XLarge */4,
                              type_: /* More */17,
                              color: "white"
                            }),
                        key: "add"
                      })
                }
              },
              height: "55vh",
              rowPadding: "0px 0px 15px 0px"
            });
}

var Form;

var Row;

var make = Contract_Manager_Edit_Table;

export {
  Form ,
  Row ,
  headersConfiguration ,
  make ,
}
/* headersConfiguration Not a pure module */
