// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core from "@material-ui/core";
import * as Theme_Colors$DvmAdminFrontendFr from "../../theme/Theme_Colors.bs.js";
import * as App_Types_Json$DvmAdminFrontendFr from "./App_Types_Json.bs.js";
import * as Messages_Orders_Search$DvmAdminFrontendFr from "../../intl/messages/orders/Messages_Orders_Search.bs.js";

function toString(status) {
  switch (status) {
    case /* COMPLETE */0 :
        return "Complete";
    case /* INCOMPLETE */1 :
        return "Incomplete";
    case /* CANCELLED */2 :
        return "Annulee";
    case /* NOUSEDCAR */3 :
        return "Pas de VO";
    case /* REPARCELED */4 :
        return "Remembrement";
    case /* PENDING */5 :
        return "Attente entrée PARC";
    case /* NOTSET */6 :
        return "";
    
  }
}

function fromString(status) {
  switch (status) {
    case "Annulee" :
    case "CANCELLED" :
        return /* CANCELLED */2;
    case "COMPLETE" :
    case "Complete" :
        return /* COMPLETE */0;
    case "INCOMPLETE" :
    case "Incomplete" :
        return /* INCOMPLETE */1;
    case "NOUSEDCAR" :
    case "Pas de VO" :
        return /* NOUSEDCAR */3;
    case "Attente entrée PARC" :
    case "PENDING" :
    case "Pending" :
        return /* PENDING */5;
    case "Remembrement" :
        return /* REPARCELED */4;
    default:
      return /* NOTSET */6;
  }
}

var Decco = App_Types_Json$DvmAdminFrontendFr.MakeStringableJsonType({
      toString: toString,
      fromString: fromString
    });

function toChip(status, intl) {
  var makeChip = function (color, message) {
    return React.createElement(Core.Chip, {
                label: intl.formatMessage(message),
                size: "small",
                variant: "outlined",
                style: {
                  borderColor: color,
                  color: color,
                  minWidth: "100px",
                  pointerEvents: "none"
                }
              });
  };
  switch (status) {
    case /* COMPLETE */0 :
        return makeChip(Theme_Colors$DvmAdminFrontendFr.accentGreen, Messages_Orders_Search$DvmAdminFrontendFr.statusComplete);
    case /* INCOMPLETE */1 :
        return makeChip(Theme_Colors$DvmAdminFrontendFr.accentRed, Messages_Orders_Search$DvmAdminFrontendFr.statusIncomplete);
    case /* CANCELLED */2 :
        return makeChip(Theme_Colors$DvmAdminFrontendFr.grayDark1, Messages_Orders_Search$DvmAdminFrontendFr.statusCanceled);
    case /* NOUSEDCAR */3 :
        return makeChip(Theme_Colors$DvmAdminFrontendFr.grayDark1, Messages_Orders_Search$DvmAdminFrontendFr.statusNoVO);
    case /* REPARCELED */4 :
        return makeChip("orange", Messages_Orders_Search$DvmAdminFrontendFr.statusReparceled);
    case /* PENDING */5 :
        return makeChip(Theme_Colors$DvmAdminFrontendFr.primaryBrown, Messages_Orders_Search$DvmAdminFrontendFr.parcPending);
    case /* NOTSET */6 :
        return null;
    
  }
}

export {
  toString ,
  fromString ,
  Decco ,
  toChip ,
}
/* Decco Not a pure module */
