// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as RestApi$DvmAdminFrontendFr from "../../api/RestApi.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../common/components/icons/App_Icon.bs.js";
import * as Home_Types$DvmAdminFrontendFr from "./Home_Types.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../intl/Intl_Utils.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../common/utils/Utils_Alert.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../context/user/User_Context.bs.js";
import * as App_Plus_Button$DvmAdminFrontendFr from "../../common/components/button/App_Plus_Button.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../context/user/User_Types_Utils.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../common/components/App_OverlaySpinner.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../common/components/table/App_Table_Pagination.bs.js";
import * as Messages_Home_Message$DvmAdminFrontendFr from "../../intl/messages/home/Messages_Home_Message.bs.js";
import * as Home_Messages_Modal_New$DvmAdminFrontendFr from "./modal/Home_Messages_Modal_New.bs.js";
import * as Home_Messages_Modal_Update$DvmAdminFrontendFr from "./modal/Home_Messages_Modal_Update.bs.js";
import * as Home_Messages_Modal_Confirm$DvmAdminFrontendFr from "./modal/Home_Messages_Modal_Confirm.bs.js";

var box = Css.css(Object.assign({}, {
          borderBottom: "1px solid " + Theme_Colors$DvmAdminFrontendFr.grayHover + "",
          cursor: "pointer",
          minHeight: "40px"
        }, {
          "&:hover": {
            backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayHover2
          }
        }));

var noted = Css.css({
      borderLeft: "3px solid " + Theme_Colors$DvmAdminFrontendFr.grayBackground + "",
      color: Theme_Colors$DvmAdminFrontendFr.grayDark1
    });

var notNoted = Css.css({
      backgroundColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown2,
      borderLeft: "3px solid " + Theme_Colors$DvmAdminFrontendFr.primaryBrown + ""
    });

var cellDate = Css.css({
      margin: "0px 12px",
      width: "70px"
    });

var cell = Css.css({
      margin: "0px 12px"
    });

var emptyBox = Css.css({
      minHeight: "40px"
    });

var message = Css.css({
      color: Theme_Colors$DvmAdminFrontendFr.black,
      fontWeight: "bold"
    });

var tool = Css.css(Object.assign({}, {
          color: Theme_Colors$DvmAdminFrontendFr.black,
          padding: "7px",
          borderRadius: "100%"
        }, {
          "&:hover": {
            backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayHover
          }
        }));

var Styles = {
  box: box,
  noted: noted,
  notNoted: notNoted,
  cellDate: cellDate,
  cell: cell,
  emptyBox: emptyBox,
  message: message,
  tool: tool
};

function Home_Messages(Props) {
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return [];
      });
  var setMessages = match$2[1];
  var messages = match$2[0];
  var match$3 = React.useState(function () {
        return null;
      });
  var setModal = match$3[1];
  var match$4 = React.useState(function () {
        return 0;
      });
  var setTotal = match$4[1];
  var match$5 = React.useState(function () {
        return 0;
      });
  var setUnreadMsg = match$5[1];
  var unreadMsg = match$5[0];
  var match$6 = React.useState(function () {
        return 0;
      });
  var setPage = match$6[1];
  var page = match$6[0];
  var match$7 = React.useState(function () {
        return false;
      });
  var setLoading = match$7[1];
  var fetchIdRef = React.useRef(0);
  var fetchData = React.useCallback((function (pageNumber) {
          return function (limitValue) {
            fetchIdRef.current = fetchIdRef.current + 1 | 0;
            var fetchId = fetchIdRef.current;
            Curry._1(setLoading, (function (param) {
                    return true;
                  }));
            RestApi$DvmAdminFrontendFr.call("GET", Home_Types$DvmAdminFrontendFr.homeMessages_decode, undefined, userManager, selectedRole, "/dashboard/messages?limit=" + String(limitValue) + "&offset=" + String(Math.imul(limitValue, pageNumber)) + "").then(function (result) {
                  if (result.TAG === /* OkResult */0) {
                    if (fetchIdRef.current === fetchId) {
                      var data = result._0;
                      Curry._1(setMessages, (function (param) {
                              return data.content;
                            }));
                      Curry._1(setTotal, (function (param) {
                              return data.total;
                            }));
                      Curry._1(setUnreadMsg, (function (param) {
                              return data.unreadMsg;
                            }));
                    }
                    
                  } else {
                    Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                              message: {
                                TAG: /* RestError */2,
                                _0: result._0
                              }
                            }));
                  }
                  if (fetchIdRef.current === fetchId) {
                    return Curry._1(setLoading, (function (param) {
                                  return false;
                                }));
                  }
                  
                });
          };
        }), []);
  React.useEffect((function () {
          return Curry._2(fetchData, page, 3);
        }), [page]);
  var news = React.useMemo(function () {
        return React.createElement("div", {
                    className: emptyBox
                  }, Belt_Array.map(messages, (function (message$1) {
                          return React.createElement("div", {
                                      key: message$1.uuid,
                                      onClick: (function ($$event) {
                                          $$event.stopPropagation();
                                          Curry._1(setModal, (function (param) {
                                                  return React.createElement(Home_Messages_Modal_Confirm$DvmAdminFrontendFr.make, {
                                                              onClose: (function (param) {
                                                                  Curry._1(setModal, (function (param) {
                                                                          return null;
                                                                        }));
                                                                }),
                                                              message: message$1,
                                                              onOk: (function (param) {
                                                                  Curry._1(setModal, (function (param) {
                                                                          return null;
                                                                        }));
                                                                  Curry._2(fetchData, page, 3);
                                                                })
                                                            });
                                                }));
                                        })
                                    }, React.createElement(Core.Box, {
                                          className: Css.cx([
                                                box,
                                                message$1.noted ? noted : notNoted
                                              ]),
                                          children: null,
                                          alignItems: "center",
                                          display: "flex",
                                          flexWrap: "nowrap",
                                          justifyContent: "center"
                                        }, React.createElement(Core.Box, {
                                              className: cellDate,
                                              children: Intl_Utils$DvmAdminFrontendFr.formatStringDate(intl, message$1.createDate),
                                              flexShrink: 0
                                            }), React.createElement(Core.Box, {
                                              className: Css.cx([
                                                    cell,
                                                    message
                                                  ]),
                                              children: message$1.title,
                                              flexGrow: 1
                                            }), User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) ? React.createElement(Core.Box, {
                                                className: cell,
                                                children: React.createElement("div", {
                                                      onClick: (function ($$event) {
                                                          $$event.stopPropagation();
                                                          Curry._1(setModal, (function (param) {
                                                                  return React.createElement(Home_Messages_Modal_Update$DvmAdminFrontendFr.make, {
                                                                              onClose: (function (param) {
                                                                                  Curry._1(setModal, (function (param) {
                                                                                          return null;
                                                                                        }));
                                                                                }),
                                                                              onOk: (function (param) {
                                                                                  Curry._1(setModal, (function (param) {
                                                                                          return null;
                                                                                        }));
                                                                                  Curry._2(fetchData, page, 3);
                                                                                }),
                                                                              message: message$1
                                                                            });
                                                                }));
                                                        })
                                                    }, React.createElement(Core.Box, {
                                                          className: tool,
                                                          children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                size: /* Large */3,
                                                                type_: /* Edit */60
                                                              }),
                                                          alignItems: "center",
                                                          display: "flex"
                                                        }))
                                              }) : null));
                        })));
      });
  var _length = messages.length;
  return React.createElement("div", {
              style: {
                borderBottom: "1px solid " + Theme_Colors$DvmAdminFrontendFr.primaryBrown2 + "",
                paddingBottom: "20px"
              }
            }, match$3[0], React.createElement(Core.Box, {
                  children: null,
                  alignItems: "center",
                  display: "flex"
                }, React.createElement("h3", undefined, "" + intl.formatMessage(Messages_Home_Message$DvmAdminFrontendFr.messagesTitle) + "" + (
                      unreadMsg > 0 ? " (" + String(unreadMsg) + ")" : ""
                    ) + ""), User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) ? React.createElement(App_Plus_Button$DvmAdminFrontendFr.make, {
                        onClick: (function (param) {
                            Curry._1(setModal, (function (param) {
                                    return React.createElement(Home_Messages_Modal_New$DvmAdminFrontendFr.make, {
                                                onClose: (function (param) {
                                                    Curry._1(setModal, (function (param) {
                                                            return null;
                                                          }));
                                                  }),
                                                onOk: (function (param) {
                                                    Curry._1(setModal, (function (param) {
                                                            return null;
                                                          }));
                                                    Curry._2(fetchData, page, 3);
                                                  })
                                              });
                                  }));
                          })
                      }) : null), React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                  show: match$7[0],
                  children: _length !== 0 ? news : React.createElement("div", {
                          className: emptyBox
                        })
                }), React.createElement(App_Table_Pagination$DvmAdminFrontendFr.make, {
                  totalElements: match$4[0],
                  rowsPerPage: 3,
                  currentPage: page,
                  handleChangePage: (function (page) {
                      Curry._1(setPage, (function (param) {
                              return page;
                            }));
                    }),
                  handleRowsPerPageChange: (function (param) {
                      
                    }),
                  variant: /* TINY */2
                }));
}

var make = Home_Messages;

export {
  Styles ,
  make ,
}
/* box Not a pure module */
