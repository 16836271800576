// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as App_Modal$DvmAdminFrontendFr from "../../../common/components/App_Modal.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as Gemo_AddEdit_Api$DvmAdminFrontendFr from "./Gemo_AddEdit_Api.bs.js";
import * as Gemo_AddEdit_Form$DvmAdminFrontendFr from "./Gemo_AddEdit_Form.bs.js";
import * as Gemo_AddEdit_Inputs$DvmAdminFrontendFr from "./Gemo_AddEdit_Inputs.bs.js";

function Gemo_AddEdit_Modal(Props) {
  var onClose = Props.onClose;
  var operation = Props.operation;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match$2[1];
  var saveResult = match$2[0];
  var match$3 = React.useState(function () {
        return /* Empty */0;
      });
  var budgetOrAction = match$3[0];
  var tmp;
  if (operation) {
    var original = operation._0;
    tmp = {
      gemoCode: original.gemoCode,
      gemoLabel: Belt_Option.getWithDefault(original.gemoLabel, Gemo_AddEdit_Form$DvmAdminFrontendFr.initialInput.gemoLabel),
      budgetActionType: Belt_Option.getWithDefault(original.budgetActionType, Gemo_AddEdit_Form$DvmAdminFrontendFr.initialInput.budgetActionType),
      startDate: original.startDate,
      endDate: original.endDate,
      submarketCode: Belt_Option.getWithDefault(original.submarketCode, Gemo_AddEdit_Form$DvmAdminFrontendFr.initialInput.submarketCode),
      models: Belt_Option.mapWithDefault(original.modelId, Gemo_AddEdit_Form$DvmAdminFrontendFr.initialInput.models, (function (x) {
              return {
                      hd: x,
                      tl: /* [] */0
                    };
            }))
    };
  } else {
    tmp = Gemo_AddEdit_Form$DvmAdminFrontendFr.initialInput;
  }
  var form = Gemo_AddEdit_Form$DvmAdminFrontendFr.GemoEditForm.useForm(tmp, (function (output, submissionCallbacks) {
          var bonusType;
          bonusType = typeof budgetOrAction === "number" ? undefined : (
              budgetOrAction.TAG === /* Budget */0 ? "BUDGET" : "ACTION"
            );
          Gemo_AddEdit_Api$DvmAdminFrontendFr.saveGemo(operation, userManager, setSaveResult, submissionCallbacks, selectedRole, {
                gemoCode: output.gemoCode.toUpperCase(),
                gemoLabel: output.gemoLabel,
                modelId: operation ? Belt_List.get(output.models, 0) : undefined,
                models: operation ? undefined : output.models,
                bonusType: bonusType,
                submarketCode: output.submarketCode,
                startDate: output.startDate,
                endDate: output.endDate,
                budgetActionType: output.budgetActionType
              });
        }));
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._2(fetchData, undefined, fetchRequest);
            Curry._1(onClose, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(operation ? Messages_Common$DvmAdminFrontendFr.modifyRow : Messages_Common$DvmAdminFrontendFr.addNewRow),
              children: React.createElement(Gemo_AddEdit_Inputs$DvmAdminFrontendFr.make, {
                    operation: operation,
                    form: form,
                    onClose: onClose,
                    setBudgetOrAction: match$3[1],
                    saveResult: saveResult
                  })
            });
}

var Api;

var Types;

var Form;

var make = Gemo_AddEdit_Modal;

export {
  Api ,
  Types ,
  Form ,
  make ,
}
/* react Not a pure module */
