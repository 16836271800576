// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../api/RestApi.bs.js";
import * as Grid_Types$DvmAdminFrontendFr from "../../Grid_Types.bs.js";

function getEndpoint(whatGrid, whatTable, budget, variant) {
  if (whatGrid) {
    var tmp;
    switch (whatTable) {
      case /* Model */0 :
          tmp = "/margins/model";
          break;
      case /* Version */1 :
          tmp = "/margins/modelversion";
          break;
      case /* GridID */2 :
          tmp = "/margins/avs";
          break;
      
    }
    return tmp + Belt_Option.mapWithDefault(variant, "", (function (x) {
                  return "/" + x;
                }));
  }
  var tmp$1;
  switch (whatTable) {
    case /* Model */0 :
        tmp$1 = "/" + Grid_Types$DvmAdminFrontendFr.modelGridEndpoint + "";
        break;
    case /* Version */1 :
        tmp$1 = "/" + Grid_Types$DvmAdminFrontendFr.versionGridEndpoint + "";
        break;
    case /* GridID */2 :
        tmp$1 = "";
        break;
    
  }
  return tmp$1 + Belt_Option.mapWithDefault(variant, "", (function (x) {
                return "/" + x;
              })) + ("/" + budget + "");
}

function editOrDeleteMarginRows(userManager, setResult, whatTable, whatGrid, method, budget, selectedRole, rows) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call(method, {
          TAG: /* Json */0,
          _0: Grid_Types$DvmAdminFrontendFr.Margins.editList_encode(rows)
        }, userManager, selectedRole, getEndpoint(whatGrid, whatTable, budget, undefined)).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function replaceStartDate(userManager, setResult, whatTable, whatGrid, budget, selectedRole, oldRows, newRows) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", {
          TAG: /* Json */0,
          _0: Grid_Types$DvmAdminFrontendFr.Margins.editList_encode(oldRows)
        }, userManager, selectedRole, getEndpoint(whatGrid, whatTable, budget, undefined)).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
                  TAG: /* Json */0,
                  _0: Grid_Types$DvmAdminFrontendFr.Margins.editList_encode(newRows)
                }, userManager, selectedRole, getEndpoint(whatGrid, whatTable, budget, undefined)).then(function (result) {
                if (result.TAG === /* OkResult */0) {
                  return Curry._1(setResult, (function (param) {
                                return {
                                        TAG: /* Ok */0,
                                        _0: undefined
                                      };
                              }));
                }
                var error = result._0;
                Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Error */1,
                                _0: error
                              };
                      }));
              });
          return ;
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

var ApiCheck;

export {
  Form ,
  ApiCheck ,
  getEndpoint ,
  editOrDeleteMarginRows ,
  replaceStartDate ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
