// Generated by ReScript, PLEASE EDIT WITH CARE


var messagesTitle = {
  id: "f0af8f30-efc1-4f2f-9d66-794ee76743ab",
  defaultMessage: "Messages"
};

var messagesModalTitleNew = {
  id: "fc0ee07e-8b4c-4af4-86c0-5b6813994e87",
  defaultMessage: "Nouveau message"
};

var messagesModalTitleUpdate = {
  id: "9b0ac2f2-22c1-41a5-a742-b7a7db5185c0",
  defaultMessage: "Message de mise à jour"
};

var messagesModalObject = {
  id: "2ba10ddd-1b67-4845-8ab2-5a903c3c8998",
  defaultMessage: "Objet du message"
};

var messagesModalMessage = {
  id: "2ba10ddd-1b67-4845-8ab2-5a903c3c8997",
  defaultMessage: "Contenu du message"
};

var messagesModalGroup = {
  id: "db333c85-2703-4568-97c7-c9690f0cdbe4",
  defaultMessage: "Destinataires:"
};

var messagesGroupAll = {
  id: "1b333c85-2703-4568-97c7-c9690f0cdbe4",
  defaultMessage: "All"
};

var messagesGroupRenaultSas = {
  id: "2b333c85-2703-4568-97c7-c9690f0cdbe4",
  defaultMessage: "Renault en interne"
};

var messagesGroupNetwork = {
  id: "3b333c85-2703-4568-97c7-c9690f0cdbe4",
  defaultMessage: "Réseau Renault"
};

var messagesOwner = {
  id: "f7540a17-c3e9-48d8-a2c3-619b3c66d1af",
  defaultMessage: "Modifié {editDate} par {editorName}"
};

export {
  messagesTitle ,
  messagesModalTitleNew ,
  messagesModalTitleUpdate ,
  messagesModalObject ,
  messagesModalMessage ,
  messagesModalGroup ,
  messagesGroupAll ,
  messagesGroupRenaultSas ,
  messagesGroupNetwork ,
  messagesOwner ,
}
/* No side effect */
