// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Css from "@emotion/css";
import * as App_Icon$DvmAdminFrontendFr from "../icons/App_Icon.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";

var button = Css.css(Object.assign({}, {
          backgroundColor: Theme_Colors$DvmAdminFrontendFr.black,
          border: "none",
          color: Theme_Colors$DvmAdminFrontendFr.white,
          cursor: "pointer",
          display: "flex",
          height: "24px",
          marginLeft: "10px",
          width: "24px",
          alignItems: "center",
          justifyContent: "center"
        }, {
          "&:hover": {
            backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayMain
          }
        }));

var icon = Css.css({
      fontWeight: "bold"
    });

function App_Plus_Button(Props) {
  var onClick = Props.onClick;
  return React.createElement("button", {
              className: button,
              onClick: onClick
            }, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                  size: /* Small */1,
                  type_: /* More */17,
                  iconClassName: icon
                }));
}

var make = App_Plus_Button;

export {
  button ,
  icon ,
  make ,
}
/* button Not a pure module */
