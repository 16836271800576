// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Api.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Date.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Form.bs.js";
import * as Flash_Search_Types$DvmAdminFrontendFr from "../../Flash_Search_Types.bs.js";

var todayStr = Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(Caml_option.some(new Date())), "");

function boolFieldToEmpty(fieldId) {
  return {
          en: fieldId[0] + "=",
          fr: fieldId[1] + "="
        };
}

function datePairToQuery(from, to, fieldId) {
  return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(from), Caml_option.some(to), fieldId, "normal");
}

var validators_detentionMaxTo = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: undefined
            };
    })
};

var validators_detentionMaxFrom = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderType = param$1.orderType;
      return {
              TAG: /* Ok */0,
              _0: Belt_List.every(orderType, (function (x) {
                      switch (x) {
                        case "RC" :
                        case "RR" :
                        case "VE" :
                            return true;
                        default:
                          return false;
                      }
                    })) || Belt_List.length(orderType) === 0 ? Utils_Api$DvmAdminFrontendFr.stringPairToQueryParamBilingual(param.detentionMaxFrom, param.detentionMaxTo, Flash_Search_Types$DvmAdminFrontendFr.Fields.detentionMax) : undefined
            };
    })
};

var validators_detentionMinTo = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: undefined
            };
    })
};

var validators_detentionMinFrom = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderType = param$1.orderType;
      return {
              TAG: /* Ok */0,
              _0: Belt_List.every(orderType, (function (x) {
                      switch (x) {
                        case "RC" :
                        case "RR" :
                        case "VE" :
                            return true;
                        default:
                          return false;
                      }
                    })) || Belt_List.length(orderType) === 0 ? Utils_Api$DvmAdminFrontendFr.stringPairToQueryParamBilingual(param.detentionMinFrom, param.detentionMinTo, Flash_Search_Types$DvmAdminFrontendFr.Fields.detentionMin) : undefined
            };
    })
};

var validators_madaEndTo = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var madaEndFrom = param.madaEndFrom;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.madaEndTo, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(madaEndFrom), Caml_option.some(date), Flash_Search_Types$DvmAdminFrontendFr.Fields.madaDateEnd, "normal");
                  }));
    })
};

var validators_madaEndFrom = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var madaEndTo = param.madaEndTo;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.madaEndFrom, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(date), Caml_option.some(madaEndTo), Flash_Search_Types$DvmAdminFrontendFr.Fields.madaDateEnd, "normal");
                  }));
    })
};

var validators_madaStartTo = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var madaStartFrom = param.madaStartFrom;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.madaStartTo, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(madaStartFrom), Caml_option.some(date), Flash_Search_Types$DvmAdminFrontendFr.Fields.madaDateStart, "normal");
                  }));
    })
};

var validators_madaStartFrom = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var madaStartTo = param.madaStartTo;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.madaStartFrom, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(date), Caml_option.some(madaStartTo), Flash_Search_Types$DvmAdminFrontendFr.Fields.madaDateStart, "normal");
                  }));
    })
};

var validators_deliveryEndTo = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var deliveryEndFrom = param.deliveryEndFrom;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.deliveryEndTo, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(deliveryEndFrom), Caml_option.some(date), Flash_Search_Types$DvmAdminFrontendFr.Fields.deliveryDateEnd, "normal");
                  }));
    })
};

var validators_deliveryEndFrom = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var deliveryEndTo = param.deliveryEndTo;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.deliveryEndFrom, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(date), Caml_option.some(deliveryEndTo), Flash_Search_Types$DvmAdminFrontendFr.Fields.deliveryDateEnd, "normal");
                  }));
    })
};

var validators_deliveryStartTo = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var deliveryStartFrom = param.deliveryStartFrom;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.deliveryStartTo, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(deliveryStartFrom), Caml_option.some(date), Flash_Search_Types$DvmAdminFrontendFr.Fields.deliveryDateStart, "normal");
                  }));
    })
};

var validators_deliveryStartFrom = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var deliveryStartTo = param.deliveryStartTo;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.deliveryStartFrom, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(date), Caml_option.some(deliveryStartTo), Flash_Search_Types$DvmAdminFrontendFr.Fields.deliveryDateStart, "normal");
                  }));
    })
};

var validators_registrationEndTo = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var registrationEndFrom = param.registrationEndFrom;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.registrationEndTo, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(registrationEndFrom), Caml_option.some(date), Flash_Search_Types$DvmAdminFrontendFr.Fields.registrationDateEnd, "normal");
                  }));
    })
};

var validators_registrationEndFrom = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var registrationEndTo = param.registrationEndTo;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.registrationEndFrom, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(date), Caml_option.some(registrationEndTo), Flash_Search_Types$DvmAdminFrontendFr.Fields.registrationDateEnd, "normal");
                  }));
    })
};

var validators_registrationStartTo = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var registrationStartFrom = param.registrationStartFrom;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.registrationStartTo, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(registrationStartFrom), Caml_option.some(date), Flash_Search_Types$DvmAdminFrontendFr.Fields.registrationDateStart, "normal");
                  }));
    })
};

var validators_registrationStartFrom = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var registrationStartTo = param.registrationStartTo;
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(param.registrationStartFrom, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(date), Caml_option.some(registrationStartTo), Flash_Search_Types$DvmAdminFrontendFr.Fields.registrationDateStart, "normal");
                  }));
    })
};

var validators = {
  detentionMaxTo: validators_detentionMaxTo,
  detentionMaxFrom: validators_detentionMaxFrom,
  detentionMinTo: validators_detentionMinTo,
  detentionMinFrom: validators_detentionMinFrom,
  madaEndTo: validators_madaEndTo,
  madaEndFrom: validators_madaEndFrom,
  madaStartTo: validators_madaStartTo,
  madaStartFrom: validators_madaStartFrom,
  deliveryEndTo: validators_deliveryEndTo,
  deliveryEndFrom: validators_deliveryEndFrom,
  deliveryStartTo: validators_deliveryStartTo,
  deliveryStartFrom: validators_deliveryStartFrom,
  registrationEndTo: validators_registrationEndTo,
  registrationEndFrom: validators_registrationEndFrom,
  registrationStartTo: validators_registrationStartTo,
  registrationStartFrom: validators_registrationStartFrom
};

function initialFieldsStatuses(_input) {
  return {
          detentionMaxTo: /* Pristine */0,
          detentionMaxFrom: /* Pristine */0,
          detentionMinTo: /* Pristine */0,
          detentionMinFrom: /* Pristine */0,
          madaEndTo: /* Pristine */0,
          madaEndFrom: /* Pristine */0,
          madaStartTo: /* Pristine */0,
          madaStartFrom: /* Pristine */0,
          deliveryEndTo: /* Pristine */0,
          deliveryEndFrom: /* Pristine */0,
          deliveryStartTo: /* Pristine */0,
          deliveryStartFrom: /* Pristine */0,
          registrationEndTo: /* Pristine */0,
          registrationEndFrom: /* Pristine */0,
          registrationStartTo: /* Pristine */0,
          registrationStartFrom: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match = fieldsStatuses.detentionMaxTo;
  var match_0 = match ? match._0 : Curry._2(validators.detentionMaxTo.validate, input, metadata);
  var match$1 = fieldsStatuses.detentionMaxFrom;
  var match_0$1 = match$1 ? match$1._0 : Curry._2(validators.detentionMaxFrom.validate, input, metadata);
  var match$2 = fieldsStatuses.detentionMinTo;
  var match_0$2 = match$2 ? match$2._0 : Curry._2(validators.detentionMinTo.validate, input, metadata);
  var match$3 = fieldsStatuses.detentionMinFrom;
  var match_0$3 = match$3 ? match$3._0 : Curry._2(validators.detentionMinFrom.validate, input, metadata);
  var match$4 = fieldsStatuses.madaEndTo;
  var match_0$4 = match$4 ? match$4._0 : Curry._2(validators.madaEndTo.validate, input, metadata);
  var match$5 = fieldsStatuses.madaEndFrom;
  var match_0$5 = match$5 ? match$5._0 : Curry._2(validators.madaEndFrom.validate, input, metadata);
  var match$6 = fieldsStatuses.madaStartTo;
  var match_0$6 = match$6 ? match$6._0 : Curry._2(validators.madaStartTo.validate, input, metadata);
  var match$7 = fieldsStatuses.madaStartFrom;
  var match_0$7 = match$7 ? match$7._0 : Curry._2(validators.madaStartFrom.validate, input, metadata);
  var match$8 = fieldsStatuses.deliveryEndTo;
  var match_0$8 = match$8 ? match$8._0 : Curry._2(validators.deliveryEndTo.validate, input, metadata);
  var match$9 = fieldsStatuses.deliveryEndFrom;
  var match_0$9 = match$9 ? match$9._0 : Curry._2(validators.deliveryEndFrom.validate, input, metadata);
  var match$10 = fieldsStatuses.deliveryStartTo;
  var match_0$10 = match$10 ? match$10._0 : Curry._2(validators.deliveryStartTo.validate, input, metadata);
  var match$11 = fieldsStatuses.deliveryStartFrom;
  var match_0$11 = match$11 ? match$11._0 : Curry._2(validators.deliveryStartFrom.validate, input, metadata);
  var match$12 = fieldsStatuses.registrationEndTo;
  var match_0$12 = match$12 ? match$12._0 : Curry._2(validators.registrationEndTo.validate, input, metadata);
  var match$13 = fieldsStatuses.registrationEndFrom;
  var match_0$13 = match$13 ? match$13._0 : Curry._2(validators.registrationEndFrom.validate, input, metadata);
  var match$14 = fieldsStatuses.registrationStartTo;
  var match_0$14 = match$14 ? match$14._0 : Curry._2(validators.registrationStartTo.validate, input, metadata);
  var match$15 = fieldsStatuses.registrationStartFrom;
  var match_0$15 = match$15 ? match$15._0 : Curry._2(validators.registrationStartFrom.validate, input, metadata);
  var detentionMaxToResult = match_0;
  var detentionMaxToResult$1;
  if (detentionMaxToResult.TAG === /* Ok */0) {
    var detentionMaxFromResult = match_0$1;
    if (detentionMaxFromResult.TAG === /* Ok */0) {
      var detentionMinToResult = match_0$2;
      if (detentionMinToResult.TAG === /* Ok */0) {
        var detentionMinFromResult = match_0$3;
        if (detentionMinFromResult.TAG === /* Ok */0) {
          var madaEndToResult = match_0$4;
          if (madaEndToResult.TAG === /* Ok */0) {
            var madaEndFromResult = match_0$5;
            if (madaEndFromResult.TAG === /* Ok */0) {
              var madaStartToResult = match_0$6;
              if (madaStartToResult.TAG === /* Ok */0) {
                var madaStartFromResult = match_0$7;
                if (madaStartFromResult.TAG === /* Ok */0) {
                  var deliveryEndToResult = match_0$8;
                  if (deliveryEndToResult.TAG === /* Ok */0) {
                    var deliveryEndFromResult = match_0$9;
                    if (deliveryEndFromResult.TAG === /* Ok */0) {
                      var deliveryStartToResult = match_0$10;
                      if (deliveryStartToResult.TAG === /* Ok */0) {
                        var deliveryStartFromResult = match_0$11;
                        if (deliveryStartFromResult.TAG === /* Ok */0) {
                          var registrationEndToResult = match_0$12;
                          if (registrationEndToResult.TAG === /* Ok */0) {
                            var registrationEndFromResult = match_0$13;
                            if (registrationEndFromResult.TAG === /* Ok */0) {
                              var registrationStartToResult = match_0$14;
                              if (registrationStartToResult.TAG === /* Ok */0) {
                                var registrationStartFromResult = match_0$15;
                                if (registrationStartFromResult.TAG === /* Ok */0) {
                                  return {
                                          TAG: /* Valid */0,
                                          output: {
                                            registrationStartFrom: registrationStartFromResult._0,
                                            registrationStartTo: registrationStartToResult._0,
                                            registrationEndFrom: registrationEndFromResult._0,
                                            registrationEndTo: registrationEndToResult._0,
                                            deliveryStartFrom: deliveryStartFromResult._0,
                                            deliveryStartTo: deliveryStartToResult._0,
                                            deliveryEndFrom: deliveryEndFromResult._0,
                                            deliveryEndTo: deliveryEndToResult._0,
                                            madaStartFrom: madaStartFromResult._0,
                                            madaStartTo: madaStartToResult._0,
                                            madaEndFrom: madaEndFromResult._0,
                                            madaEndTo: madaEndToResult._0,
                                            detentionMinFrom: detentionMinFromResult._0,
                                            detentionMinTo: detentionMinToResult._0,
                                            detentionMaxFrom: detentionMaxFromResult._0,
                                            detentionMaxTo: detentionMaxToResult._0
                                          },
                                          fieldsStatuses: {
                                            detentionMaxTo: /* Dirty */{
                                              _0: detentionMaxToResult,
                                              _1: /* Shown */0
                                            },
                                            detentionMaxFrom: /* Dirty */{
                                              _0: detentionMaxFromResult,
                                              _1: /* Shown */0
                                            },
                                            detentionMinTo: /* Dirty */{
                                              _0: detentionMinToResult,
                                              _1: /* Shown */0
                                            },
                                            detentionMinFrom: /* Dirty */{
                                              _0: detentionMinFromResult,
                                              _1: /* Shown */0
                                            },
                                            madaEndTo: /* Dirty */{
                                              _0: madaEndToResult,
                                              _1: /* Shown */0
                                            },
                                            madaEndFrom: /* Dirty */{
                                              _0: madaEndFromResult,
                                              _1: /* Shown */0
                                            },
                                            madaStartTo: /* Dirty */{
                                              _0: madaStartToResult,
                                              _1: /* Shown */0
                                            },
                                            madaStartFrom: /* Dirty */{
                                              _0: madaStartFromResult,
                                              _1: /* Shown */0
                                            },
                                            deliveryEndTo: /* Dirty */{
                                              _0: deliveryEndToResult,
                                              _1: /* Shown */0
                                            },
                                            deliveryEndFrom: /* Dirty */{
                                              _0: deliveryEndFromResult,
                                              _1: /* Shown */0
                                            },
                                            deliveryStartTo: /* Dirty */{
                                              _0: deliveryStartToResult,
                                              _1: /* Shown */0
                                            },
                                            deliveryStartFrom: /* Dirty */{
                                              _0: deliveryStartFromResult,
                                              _1: /* Shown */0
                                            },
                                            registrationEndTo: /* Dirty */{
                                              _0: registrationEndToResult,
                                              _1: /* Shown */0
                                            },
                                            registrationEndFrom: /* Dirty */{
                                              _0: registrationEndFromResult,
                                              _1: /* Shown */0
                                            },
                                            registrationStartTo: /* Dirty */{
                                              _0: registrationStartToResult,
                                              _1: /* Shown */0
                                            },
                                            registrationStartFrom: /* Dirty */{
                                              _0: registrationStartFromResult,
                                              _1: /* Shown */0
                                            }
                                          },
                                          collectionsStatuses: undefined
                                        };
                                }
                                detentionMaxToResult$1 = detentionMaxToResult;
                              } else {
                                detentionMaxToResult$1 = detentionMaxToResult;
                              }
                            } else {
                              detentionMaxToResult$1 = detentionMaxToResult;
                            }
                          } else {
                            detentionMaxToResult$1 = detentionMaxToResult;
                          }
                        } else {
                          detentionMaxToResult$1 = detentionMaxToResult;
                        }
                      } else {
                        detentionMaxToResult$1 = detentionMaxToResult;
                      }
                    } else {
                      detentionMaxToResult$1 = detentionMaxToResult;
                    }
                  } else {
                    detentionMaxToResult$1 = detentionMaxToResult;
                  }
                } else {
                  detentionMaxToResult$1 = detentionMaxToResult;
                }
              } else {
                detentionMaxToResult$1 = detentionMaxToResult;
              }
            } else {
              detentionMaxToResult$1 = detentionMaxToResult;
            }
          } else {
            detentionMaxToResult$1 = detentionMaxToResult;
          }
        } else {
          detentionMaxToResult$1 = detentionMaxToResult;
        }
      } else {
        detentionMaxToResult$1 = detentionMaxToResult;
      }
    } else {
      detentionMaxToResult$1 = detentionMaxToResult;
    }
  } else {
    detentionMaxToResult$1 = detentionMaxToResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            detentionMaxTo: /* Dirty */{
              _0: detentionMaxToResult$1,
              _1: /* Shown */0
            },
            detentionMaxFrom: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            detentionMinTo: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            detentionMinFrom: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            madaEndTo: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            madaEndFrom: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            madaStartTo: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            madaStartFrom: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            },
            deliveryEndTo: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            },
            deliveryEndFrom: /* Dirty */{
              _0: match_0$9,
              _1: /* Shown */0
            },
            deliveryStartTo: /* Dirty */{
              _0: match_0$10,
              _1: /* Shown */0
            },
            deliveryStartFrom: /* Dirty */{
              _0: match_0$11,
              _1: /* Shown */0
            },
            registrationEndTo: /* Dirty */{
              _0: match_0$12,
              _1: /* Shown */0
            },
            registrationEndFrom: /* Dirty */{
              _0: match_0$13,
              _1: /* Shown */0
            },
            registrationStartTo: /* Dirty */{
              _0: match_0$14,
              _1: /* Shown */0
            },
            registrationStartFrom: /* Dirty */{
              _0: match_0$15,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurDetentionMaxToField */0 :
                  var result = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.detentionMaxTo, validators_detentionMaxTo, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: status,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDetentionMaxFromField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.detentionMaxFrom, validators_detentionMaxFrom, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: status,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDetentionMinToField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.detentionMinTo, validators_detentionMinTo, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: status,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDetentionMinFromField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.detentionMinFrom, validators_detentionMinFrom, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: status,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurMadaEndToField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.madaEndTo, validators_madaEndTo, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: status,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurMadaEndFromField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.madaEndFrom, validators_madaEndFrom, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: status,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurMadaStartToField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.madaStartTo, validators_madaStartTo, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: status,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurMadaStartFromField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.madaStartFrom, validators_madaStartFrom, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: status,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDeliveryEndToField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.deliveryEndTo, validators_deliveryEndTo, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: status,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDeliveryEndFromField */9 :
                  var result$9 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.deliveryEndFrom, validators_deliveryEndFrom, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: status,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDeliveryStartToField */10 :
                  var result$10 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.deliveryStartTo, validators_deliveryStartTo, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: status,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDeliveryStartFromField */11 :
                  var result$11 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.deliveryStartFrom, validators_deliveryStartFrom, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: status,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRegistrationEndToField */12 :
                  var result$12 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.registrationEndTo, validators_registrationEndTo, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: status,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$12 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$12,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRegistrationEndFromField */13 :
                  var result$13 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.registrationEndFrom, validators_registrationEndFrom, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: status,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$13 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$13,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRegistrationStartToField */14 :
                  var result$14 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.registrationStartTo, validators_registrationStartTo, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: status,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$14 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$14,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRegistrationStartFromField */15 :
                  var result$15 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.registrationStartFrom, validators_registrationStartFrom, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: status
                                };
                        }));
                  if (result$15 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$15,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */16 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */16,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */17,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */19);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */18);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */17 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */18 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */19 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateDetentionMaxToField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$16 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput, metadata, nextFieldsStatuses.contents.detentionMaxFrom, validators_detentionMaxFrom, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: status,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$16 !== undefined) {
                    nextFieldsStatuses.contents = result$16;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput, metadata, nextFieldsStatuses.contents.detentionMaxTo, state.submissionStatus, validators_detentionMaxTo, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            detentionMaxTo: status,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            madaEndTo: init.madaEndTo,
                                            madaEndFrom: init.madaEndFrom,
                                            madaStartTo: init.madaStartTo,
                                            madaStartFrom: init.madaStartFrom,
                                            deliveryEndTo: init.deliveryEndTo,
                                            deliveryEndFrom: init.deliveryEndFrom,
                                            deliveryStartTo: init.deliveryStartTo,
                                            deliveryStartFrom: init.deliveryStartFrom,
                                            registrationEndTo: init.registrationEndTo,
                                            registrationEndFrom: init.registrationEndFrom,
                                            registrationStartTo: init.registrationStartTo,
                                            registrationStartFrom: init.registrationStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDetentionMaxFromField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$17 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$1, metadata, nextFieldsStatuses$1.contents.detentionMaxTo, validators_detentionMaxTo, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  detentionMaxTo: status,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$17 !== undefined) {
                    nextFieldsStatuses$1.contents = result$17;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$1, metadata, nextFieldsStatuses$1.contents.detentionMaxFrom, state.submissionStatus, validators_detentionMaxFrom, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: status,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            madaEndTo: init.madaEndTo,
                                            madaEndFrom: init.madaEndFrom,
                                            madaStartTo: init.madaStartTo,
                                            madaStartFrom: init.madaStartFrom,
                                            deliveryEndTo: init.deliveryEndTo,
                                            deliveryEndFrom: init.deliveryEndFrom,
                                            deliveryStartTo: init.deliveryStartTo,
                                            deliveryStartFrom: init.deliveryStartFrom,
                                            registrationEndTo: init.registrationEndTo,
                                            registrationEndFrom: init.registrationEndFrom,
                                            registrationStartTo: init.registrationStartTo,
                                            registrationStartFrom: init.registrationStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDetentionMinToField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$2 = {
                    contents: state.fieldsStatuses
                  };
                  var result$18 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$2, metadata, nextFieldsStatuses$2.contents.detentionMinFrom, validators_detentionMinFrom, (function (status) {
                          var init = nextFieldsStatuses$2.contents;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: status,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$18 !== undefined) {
                    nextFieldsStatuses$2.contents = result$18;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$2, metadata, nextFieldsStatuses$2.contents.detentionMinTo, state.submissionStatus, validators_detentionMinTo, (function (status) {
                                    var init = nextFieldsStatuses$2.contents;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: status,
                                            detentionMinFrom: init.detentionMinFrom,
                                            madaEndTo: init.madaEndTo,
                                            madaEndFrom: init.madaEndFrom,
                                            madaStartTo: init.madaStartTo,
                                            madaStartFrom: init.madaStartFrom,
                                            deliveryEndTo: init.deliveryEndTo,
                                            deliveryEndFrom: init.deliveryEndFrom,
                                            deliveryStartTo: init.deliveryStartTo,
                                            deliveryStartFrom: init.deliveryStartFrom,
                                            registrationEndTo: init.registrationEndTo,
                                            registrationEndFrom: init.registrationEndFrom,
                                            registrationStartTo: init.registrationStartTo,
                                            registrationStartFrom: init.registrationStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDetentionMinFromField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$3 = {
                    contents: state.fieldsStatuses
                  };
                  var result$19 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$3, metadata, nextFieldsStatuses$3.contents.detentionMinTo, validators_detentionMinTo, (function (status) {
                          var init = nextFieldsStatuses$3.contents;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: status,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$19 !== undefined) {
                    nextFieldsStatuses$3.contents = result$19;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$3, metadata, nextFieldsStatuses$3.contents.detentionMinFrom, state.submissionStatus, validators_detentionMinFrom, (function (status) {
                                    var init = nextFieldsStatuses$3.contents;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: status,
                                            madaEndTo: init.madaEndTo,
                                            madaEndFrom: init.madaEndFrom,
                                            madaStartTo: init.madaStartTo,
                                            madaStartFrom: init.madaStartFrom,
                                            deliveryEndTo: init.deliveryEndTo,
                                            deliveryEndFrom: init.deliveryEndFrom,
                                            deliveryStartTo: init.deliveryStartTo,
                                            deliveryStartFrom: init.deliveryStartFrom,
                                            registrationEndTo: init.registrationEndTo,
                                            registrationEndFrom: init.registrationEndFrom,
                                            registrationStartTo: init.registrationStartTo,
                                            registrationStartFrom: init.registrationStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateMadaEndToField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$4 = {
                    contents: state.fieldsStatuses
                  };
                  var result$20 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$4, metadata, nextFieldsStatuses$4.contents.madaEndFrom, validators_madaEndFrom, (function (status) {
                          var init = nextFieldsStatuses$4.contents;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: status,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$20 !== undefined) {
                    nextFieldsStatuses$4.contents = result$20;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$4, metadata, nextFieldsStatuses$4.contents.madaEndTo, state.submissionStatus, validators_madaEndTo, (function (status) {
                                    var init = nextFieldsStatuses$4.contents;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            madaEndTo: status,
                                            madaEndFrom: init.madaEndFrom,
                                            madaStartTo: init.madaStartTo,
                                            madaStartFrom: init.madaStartFrom,
                                            deliveryEndTo: init.deliveryEndTo,
                                            deliveryEndFrom: init.deliveryEndFrom,
                                            deliveryStartTo: init.deliveryStartTo,
                                            deliveryStartFrom: init.deliveryStartFrom,
                                            registrationEndTo: init.registrationEndTo,
                                            registrationEndFrom: init.registrationEndFrom,
                                            registrationStartTo: init.registrationStartTo,
                                            registrationStartFrom: init.registrationStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateMadaEndFromField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$5 = {
                    contents: state.fieldsStatuses
                  };
                  var result$21 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$5, metadata, nextFieldsStatuses$5.contents.madaEndTo, validators_madaEndTo, (function (status) {
                          var init = nextFieldsStatuses$5.contents;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: status,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$21 !== undefined) {
                    nextFieldsStatuses$5.contents = result$21;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$5, metadata, nextFieldsStatuses$5.contents.madaEndFrom, state.submissionStatus, validators_madaEndFrom, (function (status) {
                                    var init = nextFieldsStatuses$5.contents;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            madaEndTo: init.madaEndTo,
                                            madaEndFrom: status,
                                            madaStartTo: init.madaStartTo,
                                            madaStartFrom: init.madaStartFrom,
                                            deliveryEndTo: init.deliveryEndTo,
                                            deliveryEndFrom: init.deliveryEndFrom,
                                            deliveryStartTo: init.deliveryStartTo,
                                            deliveryStartFrom: init.deliveryStartFrom,
                                            registrationEndTo: init.registrationEndTo,
                                            registrationEndFrom: init.registrationEndFrom,
                                            registrationStartTo: init.registrationStartTo,
                                            registrationStartFrom: init.registrationStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateMadaStartToField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$6 = {
                    contents: state.fieldsStatuses
                  };
                  var result$22 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$6, metadata, nextFieldsStatuses$6.contents.madaStartFrom, validators_madaStartFrom, (function (status) {
                          var init = nextFieldsStatuses$6.contents;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: status,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$22 !== undefined) {
                    nextFieldsStatuses$6.contents = result$22;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$6, metadata, nextFieldsStatuses$6.contents.madaStartTo, state.submissionStatus, validators_madaStartTo, (function (status) {
                                    var init = nextFieldsStatuses$6.contents;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            madaEndTo: init.madaEndTo,
                                            madaEndFrom: init.madaEndFrom,
                                            madaStartTo: status,
                                            madaStartFrom: init.madaStartFrom,
                                            deliveryEndTo: init.deliveryEndTo,
                                            deliveryEndFrom: init.deliveryEndFrom,
                                            deliveryStartTo: init.deliveryStartTo,
                                            deliveryStartFrom: init.deliveryStartFrom,
                                            registrationEndTo: init.registrationEndTo,
                                            registrationEndFrom: init.registrationEndFrom,
                                            registrationStartTo: init.registrationStartTo,
                                            registrationStartFrom: init.registrationStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateMadaStartFromField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$7 = {
                    contents: state.fieldsStatuses
                  };
                  var result$23 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$7, metadata, nextFieldsStatuses$7.contents.madaStartTo, validators_madaStartTo, (function (status) {
                          var init = nextFieldsStatuses$7.contents;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: status,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$23 !== undefined) {
                    nextFieldsStatuses$7.contents = result$23;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$7, metadata, nextFieldsStatuses$7.contents.madaStartFrom, state.submissionStatus, validators_madaStartFrom, (function (status) {
                                    var init = nextFieldsStatuses$7.contents;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            madaEndTo: init.madaEndTo,
                                            madaEndFrom: init.madaEndFrom,
                                            madaStartTo: init.madaStartTo,
                                            madaStartFrom: status,
                                            deliveryEndTo: init.deliveryEndTo,
                                            deliveryEndFrom: init.deliveryEndFrom,
                                            deliveryStartTo: init.deliveryStartTo,
                                            deliveryStartFrom: init.deliveryStartFrom,
                                            registrationEndTo: init.registrationEndTo,
                                            registrationEndFrom: init.registrationEndFrom,
                                            registrationStartTo: init.registrationStartTo,
                                            registrationStartFrom: init.registrationStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDeliveryEndToField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$8 = {
                    contents: state.fieldsStatuses
                  };
                  var result$24 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$8, metadata, nextFieldsStatuses$8.contents.deliveryEndFrom, validators_deliveryEndFrom, (function (status) {
                          var init = nextFieldsStatuses$8.contents;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: status,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$24 !== undefined) {
                    nextFieldsStatuses$8.contents = result$24;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$8, metadata, nextFieldsStatuses$8.contents.deliveryEndTo, state.submissionStatus, validators_deliveryEndTo, (function (status) {
                                    var init = nextFieldsStatuses$8.contents;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            madaEndTo: init.madaEndTo,
                                            madaEndFrom: init.madaEndFrom,
                                            madaStartTo: init.madaStartTo,
                                            madaStartFrom: init.madaStartFrom,
                                            deliveryEndTo: status,
                                            deliveryEndFrom: init.deliveryEndFrom,
                                            deliveryStartTo: init.deliveryStartTo,
                                            deliveryStartFrom: init.deliveryStartFrom,
                                            registrationEndTo: init.registrationEndTo,
                                            registrationEndFrom: init.registrationEndFrom,
                                            registrationStartTo: init.registrationStartTo,
                                            registrationStartFrom: init.registrationStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDeliveryEndFromField */9 :
                  var nextInput$9 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$9 = {
                    contents: state.fieldsStatuses
                  };
                  var result$25 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$9, metadata, nextFieldsStatuses$9.contents.deliveryEndTo, validators_deliveryEndTo, (function (status) {
                          var init = nextFieldsStatuses$9.contents;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: status,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$25 !== undefined) {
                    nextFieldsStatuses$9.contents = result$25;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$9, metadata, nextFieldsStatuses$9.contents.deliveryEndFrom, state.submissionStatus, validators_deliveryEndFrom, (function (status) {
                                    var init = nextFieldsStatuses$9.contents;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            madaEndTo: init.madaEndTo,
                                            madaEndFrom: init.madaEndFrom,
                                            madaStartTo: init.madaStartTo,
                                            madaStartFrom: init.madaStartFrom,
                                            deliveryEndTo: init.deliveryEndTo,
                                            deliveryEndFrom: status,
                                            deliveryStartTo: init.deliveryStartTo,
                                            deliveryStartFrom: init.deliveryStartFrom,
                                            registrationEndTo: init.registrationEndTo,
                                            registrationEndFrom: init.registrationEndFrom,
                                            registrationStartTo: init.registrationStartTo,
                                            registrationStartFrom: init.registrationStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDeliveryStartToField */10 :
                  var nextInput$10 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$10 = {
                    contents: state.fieldsStatuses
                  };
                  var result$26 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$10, metadata, nextFieldsStatuses$10.contents.deliveryStartFrom, validators_deliveryStartFrom, (function (status) {
                          var init = nextFieldsStatuses$10.contents;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: status,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$26 !== undefined) {
                    nextFieldsStatuses$10.contents = result$26;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$10, metadata, nextFieldsStatuses$10.contents.deliveryStartTo, state.submissionStatus, validators_deliveryStartTo, (function (status) {
                                    var init = nextFieldsStatuses$10.contents;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            madaEndTo: init.madaEndTo,
                                            madaEndFrom: init.madaEndFrom,
                                            madaStartTo: init.madaStartTo,
                                            madaStartFrom: init.madaStartFrom,
                                            deliveryEndTo: init.deliveryEndTo,
                                            deliveryEndFrom: init.deliveryEndFrom,
                                            deliveryStartTo: status,
                                            deliveryStartFrom: init.deliveryStartFrom,
                                            registrationEndTo: init.registrationEndTo,
                                            registrationEndFrom: init.registrationEndFrom,
                                            registrationStartTo: init.registrationStartTo,
                                            registrationStartFrom: init.registrationStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDeliveryStartFromField */11 :
                  var nextInput$11 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$11 = {
                    contents: state.fieldsStatuses
                  };
                  var result$27 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$11, metadata, nextFieldsStatuses$11.contents.deliveryStartTo, validators_deliveryStartTo, (function (status) {
                          var init = nextFieldsStatuses$11.contents;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: status,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$27 !== undefined) {
                    nextFieldsStatuses$11.contents = result$27;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$11, metadata, nextFieldsStatuses$11.contents.deliveryStartFrom, state.submissionStatus, validators_deliveryStartFrom, (function (status) {
                                    var init = nextFieldsStatuses$11.contents;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            madaEndTo: init.madaEndTo,
                                            madaEndFrom: init.madaEndFrom,
                                            madaStartTo: init.madaStartTo,
                                            madaStartFrom: init.madaStartFrom,
                                            deliveryEndTo: init.deliveryEndTo,
                                            deliveryEndFrom: init.deliveryEndFrom,
                                            deliveryStartTo: init.deliveryStartTo,
                                            deliveryStartFrom: status,
                                            registrationEndTo: init.registrationEndTo,
                                            registrationEndFrom: init.registrationEndFrom,
                                            registrationStartTo: init.registrationStartTo,
                                            registrationStartFrom: init.registrationStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRegistrationEndToField */12 :
                  var nextInput$12 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$12 = {
                    contents: state.fieldsStatuses
                  };
                  var result$28 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$12, metadata, nextFieldsStatuses$12.contents.registrationEndFrom, validators_registrationEndFrom, (function (status) {
                          var init = nextFieldsStatuses$12.contents;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: status,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$28 !== undefined) {
                    nextFieldsStatuses$12.contents = result$28;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$12, metadata, nextFieldsStatuses$12.contents.registrationEndTo, state.submissionStatus, validators_registrationEndTo, (function (status) {
                                    var init = nextFieldsStatuses$12.contents;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            madaEndTo: init.madaEndTo,
                                            madaEndFrom: init.madaEndFrom,
                                            madaStartTo: init.madaStartTo,
                                            madaStartFrom: init.madaStartFrom,
                                            deliveryEndTo: init.deliveryEndTo,
                                            deliveryEndFrom: init.deliveryEndFrom,
                                            deliveryStartTo: init.deliveryStartTo,
                                            deliveryStartFrom: init.deliveryStartFrom,
                                            registrationEndTo: status,
                                            registrationEndFrom: init.registrationEndFrom,
                                            registrationStartTo: init.registrationStartTo,
                                            registrationStartFrom: init.registrationStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRegistrationEndFromField */13 :
                  var nextInput$13 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$13 = {
                    contents: state.fieldsStatuses
                  };
                  var result$29 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$13, metadata, nextFieldsStatuses$13.contents.registrationEndTo, validators_registrationEndTo, (function (status) {
                          var init = nextFieldsStatuses$13.contents;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: status,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$29 !== undefined) {
                    nextFieldsStatuses$13.contents = result$29;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$13,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$13, metadata, nextFieldsStatuses$13.contents.registrationEndFrom, state.submissionStatus, validators_registrationEndFrom, (function (status) {
                                    var init = nextFieldsStatuses$13.contents;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            madaEndTo: init.madaEndTo,
                                            madaEndFrom: init.madaEndFrom,
                                            madaStartTo: init.madaStartTo,
                                            madaStartFrom: init.madaStartFrom,
                                            deliveryEndTo: init.deliveryEndTo,
                                            deliveryEndFrom: init.deliveryEndFrom,
                                            deliveryStartTo: init.deliveryStartTo,
                                            deliveryStartFrom: init.deliveryStartFrom,
                                            registrationEndTo: init.registrationEndTo,
                                            registrationEndFrom: status,
                                            registrationStartTo: init.registrationStartTo,
                                            registrationStartFrom: init.registrationStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRegistrationStartToField */14 :
                  var nextInput$14 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$14 = {
                    contents: state.fieldsStatuses
                  };
                  var result$30 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$14, metadata, nextFieldsStatuses$14.contents.registrationStartFrom, validators_registrationStartFrom, (function (status) {
                          var init = nextFieldsStatuses$14.contents;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: init.registrationStartTo,
                                  registrationStartFrom: status
                                };
                        }));
                  if (result$30 !== undefined) {
                    nextFieldsStatuses$14.contents = result$30;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$14,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$14, metadata, nextFieldsStatuses$14.contents.registrationStartTo, state.submissionStatus, validators_registrationStartTo, (function (status) {
                                    var init = nextFieldsStatuses$14.contents;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            madaEndTo: init.madaEndTo,
                                            madaEndFrom: init.madaEndFrom,
                                            madaStartTo: init.madaStartTo,
                                            madaStartFrom: init.madaStartFrom,
                                            deliveryEndTo: init.deliveryEndTo,
                                            deliveryEndFrom: init.deliveryEndFrom,
                                            deliveryStartTo: init.deliveryStartTo,
                                            deliveryStartFrom: init.deliveryStartFrom,
                                            registrationEndTo: init.registrationEndTo,
                                            registrationEndFrom: init.registrationEndFrom,
                                            registrationStartTo: status,
                                            registrationStartFrom: init.registrationStartFrom
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRegistrationStartFromField */15 :
                  var nextInput$15 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$15 = {
                    contents: state.fieldsStatuses
                  };
                  var result$31 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$15, metadata, nextFieldsStatuses$15.contents.registrationStartTo, validators_registrationStartTo, (function (status) {
                          var init = nextFieldsStatuses$15.contents;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  madaEndTo: init.madaEndTo,
                                  madaEndFrom: init.madaEndFrom,
                                  madaStartTo: init.madaStartTo,
                                  madaStartFrom: init.madaStartFrom,
                                  deliveryEndTo: init.deliveryEndTo,
                                  deliveryEndFrom: init.deliveryEndFrom,
                                  deliveryStartTo: init.deliveryStartTo,
                                  deliveryStartFrom: init.deliveryStartFrom,
                                  registrationEndTo: init.registrationEndTo,
                                  registrationEndFrom: init.registrationEndFrom,
                                  registrationStartTo: status,
                                  registrationStartFrom: init.registrationStartFrom
                                };
                        }));
                  if (result$31 !== undefined) {
                    nextFieldsStatuses$15.contents = result$31;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$15,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$15, metadata, nextFieldsStatuses$15.contents.registrationStartFrom, state.submissionStatus, validators_registrationStartFrom, (function (status) {
                                    var init = nextFieldsStatuses$15.contents;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            madaEndTo: init.madaEndTo,
                                            madaEndFrom: init.madaEndFrom,
                                            madaStartTo: init.madaStartTo,
                                            madaStartFrom: init.madaStartFrom,
                                            deliveryEndTo: init.deliveryEndTo,
                                            deliveryEndFrom: init.deliveryEndFrom,
                                            deliveryStartTo: init.deliveryStartTo,
                                            deliveryStartFrom: init.deliveryStartFrom,
                                            registrationEndTo: init.registrationEndTo,
                                            registrationEndFrom: init.registrationEndFrom,
                                            registrationStartTo: init.registrationStartTo,
                                            registrationStartFrom: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */16 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */17 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */18 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateDetentionMaxTo: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDetentionMaxToField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDetentionMaxFrom: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDetentionMaxFromField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDetentionMinTo: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDetentionMinToField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDetentionMinFrom: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDetentionMinFromField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateMadaEndTo: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateMadaEndToField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateMadaEndFrom: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateMadaEndFromField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateMadaStartTo: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateMadaStartToField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateMadaStartFrom: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateMadaStartFromField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDeliveryEndTo: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDeliveryEndToField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDeliveryEndFrom: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDeliveryEndFromField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDeliveryStartTo: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDeliveryStartToField */10,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDeliveryStartFrom: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDeliveryStartFromField */11,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateRegistrationEndTo: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRegistrationEndToField */12,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateRegistrationEndFrom: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRegistrationEndFromField */13,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateRegistrationStartTo: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRegistrationStartToField */14,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateRegistrationStartFrom: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRegistrationStartFromField */15,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurDetentionMaxTo: (function (param) {
              Curry._1(dispatch, /* BlurDetentionMaxToField */0);
            }),
          blurDetentionMaxFrom: (function (param) {
              Curry._1(dispatch, /* BlurDetentionMaxFromField */1);
            }),
          blurDetentionMinTo: (function (param) {
              Curry._1(dispatch, /* BlurDetentionMinToField */2);
            }),
          blurDetentionMinFrom: (function (param) {
              Curry._1(dispatch, /* BlurDetentionMinFromField */3);
            }),
          blurMadaEndTo: (function (param) {
              Curry._1(dispatch, /* BlurMadaEndToField */4);
            }),
          blurMadaEndFrom: (function (param) {
              Curry._1(dispatch, /* BlurMadaEndFromField */5);
            }),
          blurMadaStartTo: (function (param) {
              Curry._1(dispatch, /* BlurMadaStartToField */6);
            }),
          blurMadaStartFrom: (function (param) {
              Curry._1(dispatch, /* BlurMadaStartFromField */7);
            }),
          blurDeliveryEndTo: (function (param) {
              Curry._1(dispatch, /* BlurDeliveryEndToField */8);
            }),
          blurDeliveryEndFrom: (function (param) {
              Curry._1(dispatch, /* BlurDeliveryEndFromField */9);
            }),
          blurDeliveryStartTo: (function (param) {
              Curry._1(dispatch, /* BlurDeliveryStartToField */10);
            }),
          blurDeliveryStartFrom: (function (param) {
              Curry._1(dispatch, /* BlurDeliveryStartFromField */11);
            }),
          blurRegistrationEndTo: (function (param) {
              Curry._1(dispatch, /* BlurRegistrationEndToField */12);
            }),
          blurRegistrationEndFrom: (function (param) {
              Curry._1(dispatch, /* BlurRegistrationEndFromField */13);
            }),
          blurRegistrationStartTo: (function (param) {
              Curry._1(dispatch, /* BlurRegistrationStartToField */14);
            }),
          blurRegistrationStartFrom: (function (param) {
              Curry._1(dispatch, /* BlurRegistrationStartFromField */15);
            }),
          detentionMaxToResult: Formality.exposeFieldResult(state.fieldsStatuses.detentionMaxTo),
          detentionMaxFromResult: Formality.exposeFieldResult(state.fieldsStatuses.detentionMaxFrom),
          detentionMinToResult: Formality.exposeFieldResult(state.fieldsStatuses.detentionMinTo),
          detentionMinFromResult: Formality.exposeFieldResult(state.fieldsStatuses.detentionMinFrom),
          madaEndToResult: Formality.exposeFieldResult(state.fieldsStatuses.madaEndTo),
          madaEndFromResult: Formality.exposeFieldResult(state.fieldsStatuses.madaEndFrom),
          madaStartToResult: Formality.exposeFieldResult(state.fieldsStatuses.madaStartTo),
          madaStartFromResult: Formality.exposeFieldResult(state.fieldsStatuses.madaStartFrom),
          deliveryEndToResult: Formality.exposeFieldResult(state.fieldsStatuses.deliveryEndTo),
          deliveryEndFromResult: Formality.exposeFieldResult(state.fieldsStatuses.deliveryEndFrom),
          deliveryStartToResult: Formality.exposeFieldResult(state.fieldsStatuses.deliveryStartTo),
          deliveryStartFromResult: Formality.exposeFieldResult(state.fieldsStatuses.deliveryStartFrom),
          registrationEndToResult: Formality.exposeFieldResult(state.fieldsStatuses.registrationEndTo),
          registrationEndFromResult: Formality.exposeFieldResult(state.fieldsStatuses.registrationEndFrom),
          registrationStartToResult: Formality.exposeFieldResult(state.fieldsStatuses.registrationStartTo),
          registrationStartFromResult: Formality.exposeFieldResult(state.fieldsStatuses.registrationStartFrom),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              var exit = 0;
              if (match.detentionMaxTo || match.detentionMaxFrom || match.detentionMinTo || match.detentionMinFrom || match.madaEndTo || match.madaEndFrom || match.madaStartTo || match.madaStartFrom || match.deliveryEndTo || match.deliveryEndFrom || match.deliveryStartTo || match.deliveryStartFrom || match.registrationEndTo || match.registrationEndFrom || match.registrationStartTo) {
                exit = 1;
              } else {
                if (!match.registrationStartFrom) {
                  return false;
                }
                exit = 1;
              }
              if (exit === 1) {
                var exit$1 = 0;
                exit$1 = 2;
                if (exit$1 === 2) {
                  var exit$2 = 0;
                  exit$2 = 3;
                  if (exit$2 === 3) {
                    var exit$3 = 0;
                    exit$3 = 4;
                    if (exit$3 === 4) {
                      var exit$4 = 0;
                      exit$4 = 5;
                      if (exit$4 === 5) {
                        return true;
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */16);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */17);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */18);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */18,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */19);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  registrationStartFrom: undefined,
  registrationStartTo: undefined,
  registrationEndFrom: undefined,
  registrationEndTo: undefined,
  deliveryStartFrom: undefined,
  deliveryStartTo: undefined,
  deliveryEndFrom: undefined,
  deliveryEndTo: undefined,
  madaStartFrom: undefined,
  madaStartTo: undefined,
  madaEndFrom: undefined,
  madaEndTo: undefined,
  detentionMinFrom: "",
  detentionMinTo: "",
  detentionMaxFrom: "",
  detentionMaxTo: ""
};

export {
  todayStr ,
  boolFieldToEmpty ,
  datePairToQuery ,
  TableFilterForm ,
  initialState$1 as initialState,
}
/* todayStr Not a pure module */
