// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as App_Api$DvmAdminFrontendFr from "../../../../common/App_Api.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../common/utils/Utils_Form.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Error_Inputs.bs.js";

var validators_copyAttachments = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: param.copyAttachments
            };
    })
};

var validators_applyNewStartDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: param.applyNewStartDate
            };
    })
};

var validators_newStartDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      if (!param.applyNewStartDate) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      var newStartDate = param.newStartDate;
      if (Belt_Option.isNone(newStartDate)) {
        return {
                TAG: /* Error */1,
                _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
              };
      } else {
        return Utils_Form$DvmAdminFrontendFr.validateRequiredDateCompare(param.startDate, newStartDate, "larger", true, undefined, undefined);
      }
    })
};

var validators_referenceLLD = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, _metadata) {
      if (param.budget === "PROT") {
        return {
                TAG: /* Ok */0,
                _0: param.referenceLLD
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: ""
              };
      }
    })
};

var validators_endDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, _metadata) {
      var endDate = param.endDate;
      if (Belt_Option.isNone(endDate)) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      } else {
        return Utils_Form$DvmAdminFrontendFr.validateRequiredDateCompare(param.startDate, endDate, "larger", true, undefined, undefined);
      }
    })
};

var validators_startDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, _metadata) {
      var endDate = param.endDate;
      var startDate = param.startDate;
      if (!Belt_Option.isNone(endDate)) {
        return Utils_Form$DvmAdminFrontendFr.validateRequiredDateCompare(startDate, endDate, "smaller", true, undefined, undefined);
      }
      var date = Utils_Form$DvmAdminFrontendFr.validateRequiredDate(startDate, undefined);
      if (date.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: Caml_option.some(date._0)
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: date._0
              };
      }
    })
};

var validators_managerID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, _metadata) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.managerID);
    })
};

var validators_siren = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, _metadata) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.siren);
    }),
  validateAsync: (function (param) {
      var dispatch = param[2];
      var value = param[0];
      var validate = function (siren, param) {
        return App_Api$DvmAdminFrontendFr.fetchSirenSimple(param.userManager, param.setSirenName, param.selectedRole, siren);
      };
      Formality.Async.validateAsyncWithMetadata(value, param[1], validate, (function (res) {
              Curry._1(dispatch, {
                    TAG: /* ApplyAsyncResultForSirenField */11,
                    _0: value,
                    _1: res
                  });
            }));
    }),
  eq: (function (prim0, prim1) {
      return prim0 === prim1;
    })
};

var validators_contractLabel = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, _metadata) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.contractLabel);
    })
};

var validators_contractID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, _metadata) {
      return Utils_Form$DvmAdminFrontendFr.validateWithRegex(param.contractID, /^([A-Z]|[a-z]|[0-9]){1,4}$/);
    })
};

var validators_budget = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, _metadata) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.budget);
    })
};

var validators = {
  copyAttachments: validators_copyAttachments,
  applyNewStartDate: validators_applyNewStartDate,
  newStartDate: validators_newStartDate,
  referenceLLD: validators_referenceLLD,
  endDate: validators_endDate,
  startDate: validators_startDate,
  managerID: validators_managerID,
  siren: validators_siren,
  contractLabel: validators_contractLabel,
  contractID: validators_contractID,
  budget: validators_budget
};

function initialFieldsStatuses(_input) {
  return {
          copyAttachments: /* Pristine */0,
          applyNewStartDate: /* Pristine */0,
          newStartDate: /* Pristine */0,
          referenceLLD: /* Pristine */0,
          endDate: /* Pristine */0,
          startDate: /* Pristine */0,
          managerID: /* Pristine */0,
          siren: /* Pristine */0,
          contractLabel: /* Pristine */0,
          contractID: /* Pristine */0,
          budget: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match = fieldsStatuses.copyAttachments;
  var match_0 = match ? match._0 : Curry._2(validators.copyAttachments.validate, input, metadata);
  var match$1 = fieldsStatuses.applyNewStartDate;
  var match_0$1 = match$1 ? match$1._0 : Curry._2(validators.applyNewStartDate.validate, input, metadata);
  var match$2 = fieldsStatuses.newStartDate;
  var match_0$2 = match$2 ? match$2._0 : Curry._2(validators.newStartDate.validate, input, metadata);
  var match$3 = fieldsStatuses.referenceLLD;
  var match_0$3 = match$3 ? match$3._0 : Curry._2(validators.referenceLLD.validate, input, metadata);
  var match$4 = fieldsStatuses.endDate;
  var match_0$4 = match$4 ? match$4._0 : Curry._2(validators.endDate.validate, input, metadata);
  var match$5 = fieldsStatuses.startDate;
  var match_0$5 = match$5 ? match$5._0 : Curry._2(validators.startDate.validate, input, metadata);
  var match$6 = fieldsStatuses.managerID;
  var match_0$6 = match$6 ? match$6._0 : Curry._2(validators.managerID.validate, input, metadata);
  var value = fieldsStatuses.siren;
  var tmp;
  tmp = typeof value === "number" ? ({
        NAME: "Result",
        VAL: Curry._2(validators.siren.validate, input, metadata)
      }) : (
      value.TAG === /* Dirty */0 ? ({
            NAME: "Result",
            VAL: value._0
          }) : ({
            NAME: "Validating",
            VAL: value._0
          })
    );
  var match$7 = fieldsStatuses.contractLabel;
  var match_0$7 = match$7 ? match$7._0 : Curry._2(validators.contractLabel.validate, input, metadata);
  var match$8 = fieldsStatuses.contractID;
  var match_0$8 = match$8 ? match$8._0 : Curry._2(validators.contractID.validate, input, metadata);
  var match$9 = fieldsStatuses.budget;
  var match_0$9 = match$9 ? match$9._0 : Curry._2(validators.budget.validate, input, metadata);
  var copyAttachmentsResult = match_0;
  var exit = 0;
  var sirenResult = tmp;
  if (typeof sirenResult === "object") {
    if (sirenResult.NAME === "Validating") {
      return {
              TAG: /* Validating */2,
              fieldsStatuses: {
                copyAttachments: /* Dirty */{
                  _0: copyAttachmentsResult,
                  _1: /* Shown */0
                },
                applyNewStartDate: /* Dirty */{
                  _0: match_0$1,
                  _1: /* Shown */0
                },
                newStartDate: /* Dirty */{
                  _0: match_0$2,
                  _1: /* Shown */0
                },
                referenceLLD: /* Dirty */{
                  _0: match_0$3,
                  _1: /* Shown */0
                },
                endDate: /* Dirty */{
                  _0: match_0$4,
                  _1: /* Shown */0
                },
                startDate: /* Dirty */{
                  _0: match_0$5,
                  _1: /* Shown */0
                },
                managerID: /* Dirty */{
                  _0: match_0$6,
                  _1: /* Shown */0
                },
                siren: sirenResult.NAME === "Result" ? ({
                      TAG: /* Dirty */0,
                      _0: sirenResult.VAL,
                      _1: /* Shown */0
                    }) : ({
                      TAG: /* Validating */1,
                      _0: sirenResult.VAL
                    }),
                contractLabel: /* Dirty */{
                  _0: match_0$7,
                  _1: /* Shown */0
                },
                contractID: /* Dirty */{
                  _0: match_0$8,
                  _1: /* Shown */0
                },
                budget: /* Dirty */{
                  _0: match_0$9,
                  _1: /* Shown */0
                }
              },
              collectionsStatuses: undefined
            };
    }
    exit = 2;
  } else {
    exit = 2;
  }
  if (exit === 2 && copyAttachmentsResult.TAG === /* Ok */0) {
    var applyNewStartDateResult = match_0$1;
    if (applyNewStartDateResult.TAG === /* Ok */0) {
      var newStartDateResult = match_0$2;
      if (newStartDateResult.TAG === /* Ok */0) {
        var referenceLLDResult = match_0$3;
        if (referenceLLDResult.TAG === /* Ok */0) {
          var endDateResult = match_0$4;
          if (endDateResult.TAG === /* Ok */0) {
            var startDateResult = match_0$5;
            if (startDateResult.TAG === /* Ok */0) {
              var managerIDResult = match_0$6;
              if (managerIDResult.TAG === /* Ok */0) {
                var match$10 = tmp;
                if (typeof match$10 === "object" && match$10.NAME === "Result") {
                  var sirenResult$1 = match$10.VAL;
                  if (sirenResult$1.TAG === /* Ok */0) {
                    var budgetResult = match_0$9;
                    var contractIDResult = match_0$8;
                    var contractLabelResult = match_0$7;
                    return {
                            TAG: /* Valid */0,
                            output: {
                              budget: budgetResult._0,
                              contractID: contractIDResult._0,
                              contractLabel: contractLabelResult._0,
                              siren: sirenResult$1._0,
                              managerID: managerIDResult._0,
                              startDate: startDateResult._0,
                              endDate: endDateResult._0,
                              referenceLLD: referenceLLDResult._0,
                              newStartDate: newStartDateResult._0,
                              applyNewStartDate: applyNewStartDateResult._0,
                              copyAttachments: copyAttachmentsResult._0
                            },
                            fieldsStatuses: {
                              copyAttachments: /* Dirty */{
                                _0: copyAttachmentsResult,
                                _1: /* Shown */0
                              },
                              applyNewStartDate: /* Dirty */{
                                _0: applyNewStartDateResult,
                                _1: /* Shown */0
                              },
                              newStartDate: /* Dirty */{
                                _0: newStartDateResult,
                                _1: /* Shown */0
                              },
                              referenceLLD: /* Dirty */{
                                _0: referenceLLDResult,
                                _1: /* Shown */0
                              },
                              endDate: /* Dirty */{
                                _0: endDateResult,
                                _1: /* Shown */0
                              },
                              startDate: /* Dirty */{
                                _0: startDateResult,
                                _1: /* Shown */0
                              },
                              managerID: /* Dirty */{
                                _0: managerIDResult,
                                _1: /* Shown */0
                              },
                              siren: {
                                TAG: /* Dirty */0,
                                _0: sirenResult$1,
                                _1: /* Shown */0
                              },
                              contractLabel: /* Dirty */{
                                _0: contractLabelResult,
                                _1: /* Shown */0
                              },
                              contractID: /* Dirty */{
                                _0: contractIDResult,
                                _1: /* Shown */0
                              },
                              budget: /* Dirty */{
                                _0: budgetResult,
                                _1: /* Shown */0
                              }
                            },
                            collectionsStatuses: undefined
                          };
                  }
                  
                }
                
              }
              
            }
            
          }
          
        }
        
      }
      
    }
    
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            copyAttachments: /* Dirty */{
              _0: copyAttachmentsResult,
              _1: /* Shown */0
            },
            applyNewStartDate: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            newStartDate: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            referenceLLD: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            endDate: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            startDate: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            managerID: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            siren: {
              TAG: /* Dirty */0,
              _0: tmp.VAL,
              _1: /* Shown */0
            },
            contractLabel: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            },
            contractID: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            },
            budget: /* Dirty */{
              _0: match_0$9,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurCopyAttachmentsField */0 :
                  var result = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.copyAttachments, validators_copyAttachments, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  copyAttachments: status,
                                  applyNewStartDate: init.applyNewStartDate,
                                  newStartDate: init.newStartDate,
                                  referenceLLD: init.referenceLLD,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  managerID: init.managerID,
                                  siren: init.siren,
                                  contractLabel: init.contractLabel,
                                  contractID: init.contractID,
                                  budget: init.budget
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurApplyNewStartDateField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.applyNewStartDate, validators_applyNewStartDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  copyAttachments: init.copyAttachments,
                                  applyNewStartDate: status,
                                  newStartDate: init.newStartDate,
                                  referenceLLD: init.referenceLLD,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  managerID: init.managerID,
                                  siren: init.siren,
                                  contractLabel: init.contractLabel,
                                  contractID: init.contractID,
                                  budget: init.budget
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurNewStartDateField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.newStartDate, validators_newStartDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  copyAttachments: init.copyAttachments,
                                  applyNewStartDate: init.applyNewStartDate,
                                  newStartDate: status,
                                  referenceLLD: init.referenceLLD,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  managerID: init.managerID,
                                  siren: init.siren,
                                  contractLabel: init.contractLabel,
                                  contractID: init.contractID,
                                  budget: init.budget
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurReferenceLLDField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.referenceLLD, validators_referenceLLD, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  copyAttachments: init.copyAttachments,
                                  applyNewStartDate: init.applyNewStartDate,
                                  newStartDate: init.newStartDate,
                                  referenceLLD: status,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  managerID: init.managerID,
                                  siren: init.siren,
                                  contractLabel: init.contractLabel,
                                  contractID: init.contractID,
                                  budget: init.budget
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEndDateField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.endDate, validators_endDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  copyAttachments: init.copyAttachments,
                                  applyNewStartDate: init.applyNewStartDate,
                                  newStartDate: init.newStartDate,
                                  referenceLLD: init.referenceLLD,
                                  endDate: status,
                                  startDate: init.startDate,
                                  managerID: init.managerID,
                                  siren: init.siren,
                                  contractLabel: init.contractLabel,
                                  contractID: init.contractID,
                                  budget: init.budget
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurStartDateField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.startDate, validators_startDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  copyAttachments: init.copyAttachments,
                                  applyNewStartDate: init.applyNewStartDate,
                                  newStartDate: init.newStartDate,
                                  referenceLLD: init.referenceLLD,
                                  endDate: init.endDate,
                                  startDate: status,
                                  managerID: init.managerID,
                                  siren: init.siren,
                                  contractLabel: init.contractLabel,
                                  contractID: init.contractID,
                                  budget: init.budget
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurManagerIDField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.managerID, validators_managerID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  copyAttachments: init.copyAttachments,
                                  applyNewStartDate: init.applyNewStartDate,
                                  newStartDate: init.newStartDate,
                                  referenceLLD: init.referenceLLD,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  managerID: status,
                                  siren: init.siren,
                                  contractLabel: init.contractLabel,
                                  contractID: init.contractID,
                                  budget: init.budget
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSirenField */7 :
                  var result$7 = Formality.Async.validateFieldOfStringTypeOnBlurWithMetadata(state.input, metadata, state.fieldsStatuses.siren, validators_siren, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  copyAttachments: init.copyAttachments,
                                  applyNewStartDate: init.applyNewStartDate,
                                  newStartDate: init.newStartDate,
                                  referenceLLD: init.referenceLLD,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  managerID: init.managerID,
                                  siren: status,
                                  contractLabel: init.contractLabel,
                                  contractID: init.contractID,
                                  budget: init.budget
                                };
                        }));
                  if (result$7 === undefined) {
                    return /* NoUpdate */0;
                  }
                  var value = result$7.siren;
                  if (typeof value !== "number" && value.TAG !== /* Dirty */0) {
                    var value$1 = value._0;
                    return {
                            TAG: /* UpdateWithSideEffects */1,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            },
                            _1: (function (param) {
                                Curry._1(validators_siren.validateAsync, [
                                      value$1,
                                      metadata,
                                      param.dispatch
                                    ]);
                              })
                          };
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: result$7,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
                  break;
              case /* BlurContractLabelField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.contractLabel, validators_contractLabel, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  copyAttachments: init.copyAttachments,
                                  applyNewStartDate: init.applyNewStartDate,
                                  newStartDate: init.newStartDate,
                                  referenceLLD: init.referenceLLD,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  managerID: init.managerID,
                                  siren: init.siren,
                                  contractLabel: status,
                                  contractID: init.contractID,
                                  budget: init.budget
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurContractIDField */9 :
                  var result$9 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.contractID, validators_contractID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  copyAttachments: init.copyAttachments,
                                  applyNewStartDate: init.applyNewStartDate,
                                  newStartDate: init.newStartDate,
                                  referenceLLD: init.referenceLLD,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  managerID: init.managerID,
                                  siren: init.siren,
                                  contractLabel: init.contractLabel,
                                  contractID: status,
                                  budget: init.budget
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBudgetField */10 :
                  var result$10 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.budget, validators_budget, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  copyAttachments: init.copyAttachments,
                                  applyNewStartDate: init.applyNewStartDate,
                                  newStartDate: init.newStartDate,
                                  referenceLLD: init.referenceLLD,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  managerID: init.managerID,
                                  siren: init.siren,
                                  contractLabel: init.contractLabel,
                                  contractID: init.contractID,
                                  budget: status
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */11 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  switch (match$1.TAG | 0) {
                    case /* Valid */0 :
                        var output = match$1.output;
                        var error = state.formStatus;
                        var tmp;
                        tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                        return {
                                TAG: /* UpdateWithSideEffects */1,
                                _0: {
                                  input: state.input,
                                  fieldsStatuses: match$1.fieldsStatuses,
                                  collectionsStatuses: match$1.collectionsStatuses,
                                  formStatus: {
                                    TAG: /* Submitting */0,
                                    _0: tmp
                                  },
                                  submissionStatus: /* AttemptedToSubmit */1
                                },
                                _1: (function (param) {
                                    var dispatch = param.dispatch;
                                    Curry._2(onSubmit, output, {
                                          notifyOnSuccess: (function (input) {
                                              Curry._1(dispatch, {
                                                    TAG: /* SetSubmittedStatus */12,
                                                    _0: input
                                                  });
                                            }),
                                          notifyOnFailure: (function (error) {
                                              Curry._1(dispatch, {
                                                    TAG: /* SetSubmissionFailedStatus */13,
                                                    _0: error
                                                  });
                                            }),
                                          reset: (function (param) {
                                              Curry._1(dispatch, /* Reset */14);
                                            }),
                                          dismissSubmissionResult: (function (param) {
                                              Curry._1(dispatch, /* DismissSubmissionResult */13);
                                            })
                                        });
                                  })
                              };
                    case /* Invalid */1 :
                        return {
                                TAG: /* Update */0,
                                _0: {
                                  input: state.input,
                                  fieldsStatuses: match$1.fieldsStatuses,
                                  collectionsStatuses: match$1.collectionsStatuses,
                                  formStatus: /* Editing */0,
                                  submissionStatus: /* AttemptedToSubmit */1
                                }
                              };
                    case /* Validating */2 :
                        return {
                                TAG: /* Update */0,
                                _0: {
                                  input: state.input,
                                  fieldsStatuses: match$1.fieldsStatuses,
                                  collectionsStatuses: match$1.collectionsStatuses,
                                  formStatus: state.formStatus,
                                  submissionStatus: state.submissionStatus
                                }
                              };
                    
                  }
                  break;
              case /* DismissSubmissionError */12 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */13 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */14 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateCopyAttachmentsField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput, metadata, state.fieldsStatuses.copyAttachments, state.submissionStatus, validators_copyAttachments, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            copyAttachments: status,
                                            applyNewStartDate: init.applyNewStartDate,
                                            newStartDate: init.newStartDate,
                                            referenceLLD: init.referenceLLD,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            managerID: init.managerID,
                                            siren: init.siren,
                                            contractLabel: init.contractLabel,
                                            contractID: init.contractID,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateApplyNewStartDateField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$11 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$1, metadata, nextFieldsStatuses.contents.newStartDate, validators_newStartDate, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  copyAttachments: init.copyAttachments,
                                  applyNewStartDate: init.applyNewStartDate,
                                  newStartDate: status,
                                  referenceLLD: init.referenceLLD,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  managerID: init.managerID,
                                  siren: init.siren,
                                  contractLabel: init.contractLabel,
                                  contractID: init.contractID,
                                  budget: init.budget
                                };
                        }));
                  if (result$11 !== undefined) {
                    nextFieldsStatuses.contents = result$11;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$1, metadata, nextFieldsStatuses.contents.applyNewStartDate, state.submissionStatus, validators_applyNewStartDate, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            copyAttachments: init.copyAttachments,
                                            applyNewStartDate: status,
                                            newStartDate: init.newStartDate,
                                            referenceLLD: init.referenceLLD,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            managerID: init.managerID,
                                            siren: init.siren,
                                            contractLabel: init.contractLabel,
                                            contractID: init.contractID,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateNewStartDateField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$12 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$2, metadata, nextFieldsStatuses$1.contents.startDate, validators_startDate, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  copyAttachments: init.copyAttachments,
                                  applyNewStartDate: init.applyNewStartDate,
                                  newStartDate: init.newStartDate,
                                  referenceLLD: init.referenceLLD,
                                  endDate: init.endDate,
                                  startDate: status,
                                  managerID: init.managerID,
                                  siren: init.siren,
                                  contractLabel: init.contractLabel,
                                  contractID: init.contractID,
                                  budget: init.budget
                                };
                        }));
                  if (result$12 !== undefined) {
                    nextFieldsStatuses$1.contents = result$12;
                  }
                  var result$13 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$2, metadata, nextFieldsStatuses$1.contents.applyNewStartDate, validators_applyNewStartDate, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  copyAttachments: init.copyAttachments,
                                  applyNewStartDate: status,
                                  newStartDate: init.newStartDate,
                                  referenceLLD: init.referenceLLD,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  managerID: init.managerID,
                                  siren: init.siren,
                                  contractLabel: init.contractLabel,
                                  contractID: init.contractID,
                                  budget: init.budget
                                };
                        }));
                  if (result$13 !== undefined) {
                    nextFieldsStatuses$1.contents = result$13;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$2, metadata, nextFieldsStatuses$1.contents.newStartDate, state.submissionStatus, validators_newStartDate, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            copyAttachments: init.copyAttachments,
                                            applyNewStartDate: init.applyNewStartDate,
                                            newStartDate: status,
                                            referenceLLD: init.referenceLLD,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            managerID: init.managerID,
                                            siren: init.siren,
                                            contractLabel: init.contractLabel,
                                            contractID: init.contractID,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateReferenceLLDField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$3, metadata, state.fieldsStatuses.referenceLLD, state.submissionStatus, validators_referenceLLD, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            copyAttachments: init.copyAttachments,
                                            applyNewStartDate: init.applyNewStartDate,
                                            newStartDate: init.newStartDate,
                                            referenceLLD: status,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            managerID: init.managerID,
                                            siren: init.siren,
                                            contractLabel: init.contractLabel,
                                            contractID: init.contractID,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEndDateField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$2 = {
                    contents: state.fieldsStatuses
                  };
                  var result$14 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$4, metadata, nextFieldsStatuses$2.contents.startDate, validators_startDate, (function (status) {
                          var init = nextFieldsStatuses$2.contents;
                          return {
                                  copyAttachments: init.copyAttachments,
                                  applyNewStartDate: init.applyNewStartDate,
                                  newStartDate: init.newStartDate,
                                  referenceLLD: init.referenceLLD,
                                  endDate: init.endDate,
                                  startDate: status,
                                  managerID: init.managerID,
                                  siren: init.siren,
                                  contractLabel: init.contractLabel,
                                  contractID: init.contractID,
                                  budget: init.budget
                                };
                        }));
                  if (result$14 !== undefined) {
                    nextFieldsStatuses$2.contents = result$14;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$4, metadata, nextFieldsStatuses$2.contents.endDate, state.submissionStatus, validators_endDate, (function (status) {
                                    var init = nextFieldsStatuses$2.contents;
                                    return {
                                            copyAttachments: init.copyAttachments,
                                            applyNewStartDate: init.applyNewStartDate,
                                            newStartDate: init.newStartDate,
                                            referenceLLD: init.referenceLLD,
                                            endDate: status,
                                            startDate: init.startDate,
                                            managerID: init.managerID,
                                            siren: init.siren,
                                            contractLabel: init.contractLabel,
                                            contractID: init.contractID,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateStartDateField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$3 = {
                    contents: state.fieldsStatuses
                  };
                  var result$15 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$5, metadata, nextFieldsStatuses$3.contents.endDate, validators_endDate, (function (status) {
                          var init = nextFieldsStatuses$3.contents;
                          return {
                                  copyAttachments: init.copyAttachments,
                                  applyNewStartDate: init.applyNewStartDate,
                                  newStartDate: init.newStartDate,
                                  referenceLLD: init.referenceLLD,
                                  endDate: status,
                                  startDate: init.startDate,
                                  managerID: init.managerID,
                                  siren: init.siren,
                                  contractLabel: init.contractLabel,
                                  contractID: init.contractID,
                                  budget: init.budget
                                };
                        }));
                  if (result$15 !== undefined) {
                    nextFieldsStatuses$3.contents = result$15;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$5, metadata, nextFieldsStatuses$3.contents.startDate, state.submissionStatus, validators_startDate, (function (status) {
                                    var init = nextFieldsStatuses$3.contents;
                                    return {
                                            copyAttachments: init.copyAttachments,
                                            applyNewStartDate: init.applyNewStartDate,
                                            newStartDate: init.newStartDate,
                                            referenceLLD: init.referenceLLD,
                                            endDate: init.endDate,
                                            startDate: status,
                                            managerID: init.managerID,
                                            siren: init.siren,
                                            contractLabel: init.contractLabel,
                                            contractID: init.contractID,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateManagerIDField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$6, metadata, state.fieldsStatuses.managerID, state.submissionStatus, validators_managerID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            copyAttachments: init.copyAttachments,
                                            applyNewStartDate: init.applyNewStartDate,
                                            newStartDate: init.newStartDate,
                                            referenceLLD: init.referenceLLD,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            managerID: status,
                                            siren: init.siren,
                                            contractLabel: init.contractLabel,
                                            contractID: init.contractID,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSirenField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.Async.validateFieldOfStringTypeOnChangeInOnBlurModeWithMetadata(nextInput$7, metadata, state.fieldsStatuses.siren, state.submissionStatus, validators_siren, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            copyAttachments: init.copyAttachments,
                                            applyNewStartDate: init.applyNewStartDate,
                                            newStartDate: init.newStartDate,
                                            referenceLLD: init.referenceLLD,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            managerID: init.managerID,
                                            siren: status,
                                            contractLabel: init.contractLabel,
                                            contractID: init.contractID,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateContractLabelField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$8, metadata, state.fieldsStatuses.contractLabel, state.submissionStatus, validators_contractLabel, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            copyAttachments: init.copyAttachments,
                                            applyNewStartDate: init.applyNewStartDate,
                                            newStartDate: init.newStartDate,
                                            referenceLLD: init.referenceLLD,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            managerID: init.managerID,
                                            siren: init.siren,
                                            contractLabel: status,
                                            contractID: init.contractID,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateContractIDField */9 :
                  var nextInput$9 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$9, metadata, state.fieldsStatuses.contractID, state.submissionStatus, validators_contractID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            copyAttachments: init.copyAttachments,
                                            applyNewStartDate: init.applyNewStartDate,
                                            newStartDate: init.newStartDate,
                                            referenceLLD: init.referenceLLD,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            managerID: init.managerID,
                                            siren: init.siren,
                                            contractLabel: init.contractLabel,
                                            contractID: status,
                                            budget: init.budget
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBudgetField */10 :
                  var nextInput$10 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$10, metadata, state.fieldsStatuses.budget, state.submissionStatus, validators_budget, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            copyAttachments: init.copyAttachments,
                                            applyNewStartDate: init.applyNewStartDate,
                                            newStartDate: init.newStartDate,
                                            referenceLLD: init.referenceLLD,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            managerID: init.managerID,
                                            siren: init.siren,
                                            contractLabel: init.contractLabel,
                                            contractID: init.contractID,
                                            budget: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* ApplyAsyncResultForSirenField */11 :
                  var validator = validators_siren;
                  var x = state.fieldsStatuses.siren;
                  if (typeof x === "number") {
                    return /* NoUpdate */0;
                  }
                  if (x.TAG === /* Dirty */0) {
                    return /* NoUpdate */0;
                  }
                  if (!Curry._2(validator.eq, x._0, action._0)) {
                    return /* NoUpdate */0;
                  }
                  var init = state.fieldsStatuses;
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: {
                              copyAttachments: init.copyAttachments,
                              applyNewStartDate: init.applyNewStartDate,
                              newStartDate: init.newStartDate,
                              referenceLLD: init.referenceLLD,
                              endDate: init.endDate,
                              startDate: init.startDate,
                              managerID: init.managerID,
                              siren: {
                                TAG: /* Dirty */0,
                                _0: action._1,
                                _1: /* Shown */0
                              },
                              contractLabel: init.contractLabel,
                              contractID: init.contractID,
                              budget: init.budget
                            },
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */12 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */13 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */14 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateCopyAttachments: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCopyAttachmentsField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateApplyNewStartDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateApplyNewStartDateField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateNewStartDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateNewStartDateField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateReferenceLLD: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateReferenceLLDField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEndDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEndDateField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateStartDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateStartDateField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateManagerID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateManagerIDField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateSiren: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSirenField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateContractLabel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateContractLabelField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateContractID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateContractIDField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBudget: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBudgetField */10,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurCopyAttachments: (function (param) {
              Curry._1(dispatch, /* BlurCopyAttachmentsField */0);
            }),
          blurApplyNewStartDate: (function (param) {
              Curry._1(dispatch, /* BlurApplyNewStartDateField */1);
            }),
          blurNewStartDate: (function (param) {
              Curry._1(dispatch, /* BlurNewStartDateField */2);
            }),
          blurReferenceLLD: (function (param) {
              Curry._1(dispatch, /* BlurReferenceLLDField */3);
            }),
          blurEndDate: (function (param) {
              Curry._1(dispatch, /* BlurEndDateField */4);
            }),
          blurStartDate: (function (param) {
              Curry._1(dispatch, /* BlurStartDateField */5);
            }),
          blurManagerID: (function (param) {
              Curry._1(dispatch, /* BlurManagerIDField */6);
            }),
          blurSiren: (function (param) {
              Curry._1(dispatch, /* BlurSirenField */7);
            }),
          blurContractLabel: (function (param) {
              Curry._1(dispatch, /* BlurContractLabelField */8);
            }),
          blurContractID: (function (param) {
              Curry._1(dispatch, /* BlurContractIDField */9);
            }),
          blurBudget: (function (param) {
              Curry._1(dispatch, /* BlurBudgetField */10);
            }),
          copyAttachmentsResult: Formality.exposeFieldResult(state.fieldsStatuses.copyAttachments),
          applyNewStartDateResult: Formality.exposeFieldResult(state.fieldsStatuses.applyNewStartDate),
          newStartDateResult: Formality.exposeFieldResult(state.fieldsStatuses.newStartDate),
          referenceLLDResult: Formality.exposeFieldResult(state.fieldsStatuses.referenceLLD),
          endDateResult: Formality.exposeFieldResult(state.fieldsStatuses.endDate),
          startDateResult: Formality.exposeFieldResult(state.fieldsStatuses.startDate),
          managerIDResult: Formality.exposeFieldResult(state.fieldsStatuses.managerID),
          sirenResult: Formality.Async.exposeFieldResult(state.fieldsStatuses.siren),
          contractLabelResult: Formality.exposeFieldResult(state.fieldsStatuses.contractLabel),
          contractIDResult: Formality.exposeFieldResult(state.fieldsStatuses.contractID),
          budgetResult: Formality.exposeFieldResult(state.fieldsStatuses.budget),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.copyAttachments) {
                return true;
              }
              if (!match.applyNewStartDate && !match.newStartDate && !match.referenceLLD && !match.endDate && !match.startDate && !match.managerID && typeof match.siren === "number" && !match.contractLabel && !match.contractID && !match.budget) {
                return false;
              }
              return true;
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              switch (match.TAG | 0) {
                case /* Valid */0 :
                    return true;
                case /* Invalid */1 :
                    return false;
                case /* Validating */2 :
                    return ;
                
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */11);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */12);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */13);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */14,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */14);
            })
        };
}

var ContractForm = {
  debounceInterval: 700,
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var Types;

var Api;

var initialState$1 = {
  budget: "",
  contractID: "",
  contractLabel: "",
  siren: "",
  managerID: "",
  startDate: undefined,
  endDate: undefined,
  referenceLLD: "",
  newStartDate: undefined,
  applyNewStartDate: false,
  copyAttachments: false
};

export {
  Types ,
  Api ,
  ContractForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
