// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../../../../common/App_Api.bs.js";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../../common/components/App_Modal.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../../../common/utils/Utils_Api.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../common/App_Typography.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../../common/components/App_OverlaySpinner.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../../../common/components/table/App_Table_Pagination.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as Quota_View_Exclusion_Add_Api$DvmAdminFrontendFr from "./Quota_View_Exclusion_Add_Api.bs.js";
import * as Quota_View_Exclusion_Add_Filters$DvmAdminFrontendFr from "./Quota_View_Exclusion_Add_Filters.bs.js";
import * as Quota_View_Exclusion_Add_Selection$DvmAdminFrontendFr from "./Quota_View_Exclusion_Add_Selection.bs.js";
import * as Quota_View_Exclusion_Add_Primary_Inputs$DvmAdminFrontendFr from "./Quota_View_Exclusion_Add_Primary_Inputs.bs.js";

function Quota_View_Exclusion_Add(Props) {
  var onClose = Props.onClose;
  var onOk = Props.onOk;
  var tableType = Props.tableType;
  var contractOptions = Props.contractOptions;
  var alert = ReactAlert.useAlert();
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var saveResult = match[0];
  var match$1 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelsResult = match$1[1];
  var modelsResult = match$1[0];
  var match$2 = React.useState(function () {
        return /* [] */0;
      });
  var setFilteredOptions = match$2[1];
  var match$3 = React.useState(function () {
        return /* [] */0;
      });
  var setAllVersionOptions = match$3[1];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setData = match$4[1];
  var data = match$4[0];
  var match$5 = React.useState(function () {
        
      });
  var primaryOutputs = match$5[0];
  var match$6 = React.useState(function () {
        return false;
      });
  var match$7 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: Caml_array.get(App_Table_Pagination$DvmAdminFrontendFr.options, 0),
                headers: /* [] */0,
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$7[1];
  var match$8 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$9 = match$8[0];
  var userManager = match$9.userManager;
  var selectedRole = match$9.selectedRole;
  var match$10 = React.useState(function () {
        return false;
      });
  var withClosedModels = match$10[0];
  var match$11 = React.useState(function () {
        return /* [] */0;
      });
  var setSavedModelsOrVersions = match$11[1];
  var savedModelsOrVersions = match$11[0];
  var fetchData = function (submissionCallbacks, fetchRequest) {
    Quota_View_Exclusion_Add_Api$DvmAdminFrontendFr.fetchAllVersions(submissionCallbacks, userManager, setData, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
              page: fetchRequest.page,
              rowsPerPage: 2000,
              headers: fetchRequest.headers,
              filterQueryParams: fetchRequest.filterQueryParams,
              additionalQueryParams: fetchRequest.additionalQueryParams
            }), selectedRole);
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  React.useEffect((function () {
          
        }), []);
  React.useEffect((function () {
          Belt_Option.mapWithDefault(primaryOutputs, undefined, (function (primaryOutputs) {
                  Quota_View_Exclusion_Add_Api$DvmAdminFrontendFr.fetchModelsOrVersionsOfQuota(alert, userManager, setSavedModelsOrVersions, primaryOutputs, tableType, selectedRole);
                }));
          if (tableType === /* Model */0) {
            App_Api$DvmAdminFrontendFr.fetchModelVersions(setModelsResult, userManager, selectedRole, withClosedModels, undefined, undefined);
          }
          
        }), [primaryOutputs]);
  React.useEffect((function () {
          if (tableType === /* Model */0) {
            App_Api$DvmAdminFrontendFr.fetchModelVersions(setModelsResult, userManager, selectedRole, withClosedModels, undefined, undefined);
          }
          
        }), [withClosedModels]);
  React.useEffect((function () {
          if (typeof modelsResult === "number") {
            modelsResult === /* NotStarted */0;
          } else if (modelsResult.TAG === /* Ok */0) {
            var models = modelsResult._0;
            Curry._1(setFilteredOptions, (function (param) {
                    var savedModels = Belt_List.keepMap(savedModelsOrVersions, (function (x) {
                            return x.model;
                          }));
                    return Belt_List.map(Belt_List.keep(models, (function (x) {
                                      return !Belt_List.has(savedModels, x.parent, (function (a, b) {
                                                    return a === b;
                                                  }));
                                    })), (function (x) {
                                  return {
                                          modelVersionID: x.parent,
                                          model: "",
                                          version: "",
                                          versionName: undefined,
                                          categoryID: undefined,
                                          categoryName: undefined
                                        };
                                }));
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: modelsResult._0
                      }
                    }));
          }
        }), [modelsResult]);
  React.useEffect((function () {
          if (typeof data === "number") {
            data === /* NotStarted */0;
          } else if (data.TAG === /* Ok */0) {
            var table = data._0;
            Curry._1(setAllVersionOptions, (function (param) {
                    return Belt_List.keep(table.content, (function (ver) {
                                  return !Belt_List.some(savedModelsOrVersions, (function (saved) {
                                                return Belt_Option.getWithDefault(saved.modelVersion, "") === ver.modelVersionID;
                                              }));
                                }));
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: data._0
                      }
                    }));
          }
        }), [
        data,
        savedModelsOrVersions
      ]);
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(onOk, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.selectModalTitle),
              minWidth: "800px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult) || App_Types_Result$DvmAdminFrontendFr.isPending(modelsResult),
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          spacing: 1
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(Quota_View_Exclusion_Add_Primary_Inputs$DvmAdminFrontendFr.make, {
                                    contractOptions: contractOptions,
                                    setPrimaryOutputs: match$5[1],
                                    setPrimaryOutputsError: match$6[1]
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              direction: "column",
                              item: true,
                              spacing: 1
                            }, tableType === /* Model */0 ? React.createElement(Core.Grid, {
                                    children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                          style: {
                                            fontWeight: "600"
                                          },
                                          children: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.selectModel),
                                          variant: "h4"
                                        }),
                                    item: true
                                  }) : React.createElement(Core.Grid, {
                                    children: React.createElement(Quota_View_Exclusion_Add_Filters$DvmAdminFrontendFr.make, {
                                          allVersionOptions: match$3[0],
                                          fetchData: fetchData,
                                          fetchRequest: match$7[0],
                                          setFilteredOptions: setFilteredOptions
                                        }),
                                    item: true
                                  }), React.createElement(Core.Grid, {
                                  children: React.createElement(Quota_View_Exclusion_Add_Selection$DvmAdminFrontendFr.make, {
                                        filteredOptions: match$2[0],
                                        setSaveResult: match[1],
                                        tableType: tableType,
                                        primaryOutputs: primaryOutputs,
                                        primaryOutputsError: match$6[0],
                                        withClosedModels: withClosedModels,
                                        setWithClosedModels: match$10[1]
                                      }),
                                  item: true
                                })))
                  })
            });
}

var Filter;

var Api;

var $$Selection;

var make = Quota_View_Exclusion_Add;

export {
  Filter ,
  Api ,
  $$Selection ,
  make ,
}
/* react Not a pure module */
