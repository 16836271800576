// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReDate_utils from "@mobily/rescript-date/src/ReDate_utils.bs.js";

function doDatesOverlap(startDateContract, endDateContract, startDateManager, endDateManager) {
  if (startDateContract === undefined) {
    return true;
  }
  var startDateContract$1 = Caml_option.valFromOption(startDateContract);
  if (endDateContract !== undefined) {
    if (startDateManager === undefined) {
      return true;
    }
    var startDateManager$1 = Caml_option.valFromOption(startDateManager);
    var endDateContract$1 = Caml_option.valFromOption(endDateContract);
    if (endDateManager === undefined) {
      if (ReDate.isAfter(ReDate_utils.makeStartOfDayDate(endDateContract$1), ReDate_utils.makeStartOfDayDate(startDateManager$1))) {
        return true;
      } else {
        return ReDate.isEqual(ReDate_utils.makeStartOfDayDate(endDateContract$1), ReDate_utils.makeStartOfDayDate(startDateManager$1));
      }
    }
    var endDateManager$1 = Caml_option.valFromOption(endDateManager);
    if (ReDate.isBefore(ReDate_utils.makeStartOfDayDate(startDateContract$1), ReDate_utils.makeStartOfDayDate(endDateManager$1)) || ReDate.isEqual(ReDate_utils.makeStartOfDayDate(startDateContract$1), ReDate_utils.makeStartOfDayDate(endDateManager$1))) {
      if (ReDate.isAfter(ReDate_utils.makeStartOfDayDate(endDateContract$1), ReDate_utils.makeStartOfDayDate(startDateManager$1))) {
        return true;
      } else {
        return ReDate.isEqual(ReDate_utils.makeStartOfDayDate(endDateContract$1), ReDate_utils.makeStartOfDayDate(startDateManager$1));
      }
    } else {
      return false;
    }
  }
  if (startDateManager === undefined) {
    return true;
  }
  if (endDateManager === undefined) {
    return true;
  }
  var endDateManager$2 = Caml_option.valFromOption(endDateManager);
  if (ReDate.isBefore(ReDate_utils.makeStartOfDayDate(startDateContract$1), ReDate_utils.makeStartOfDayDate(endDateManager$2))) {
    return true;
  } else {
    return ReDate.isEqual(ReDate_utils.makeStartOfDayDate(startDateContract$1), ReDate_utils.makeStartOfDayDate(endDateManager$2));
  }
}

export {
  doDatesOverlap ,
}
/* No side effect */
