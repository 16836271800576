// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import ReactLoaderAdvanced from "react-loader-advanced";
import * as Theme_Colors$DvmAdminFrontendFr from "../../theme/Theme_Colors.bs.js";

var bottom = Css.css({
      color: Theme_Colors$DvmAdminFrontendFr.grayHover,
      position: "absolute"
    });

var top = Css.css({
      color: Theme_Colors$DvmAdminFrontendFr.primaryBrown
    });

var circle = Css.css(Object.assign({}, {}, {
          strokeLinecap: "round"
        }));

var background = Css.css({
      backgroundColor: Theme_Colors$DvmAdminFrontendFr.white
    });

function App_OverlaySpinner(Props) {
  var show = Props.show;
  var children = Props.children;
  var getLoader = function (param) {
    return React.createElement("div", undefined, React.createElement(Core.CircularProgress, {
                    className: bottom,
                    size: 40,
                    thickness: 4,
                    value: 100,
                    variant: "determinate"
                  }), React.createElement(Core.CircularProgress, {
                    classes: {
                      circle: circle
                    },
                    className: top,
                    disableShrink: true,
                    size: 40,
                    thickness: 4,
                    variant: "indeterminate"
                  }));
  };
  return React.createElement(ReactLoaderAdvanced, {
              backgroundStyle: {
                backgroundColor: "rgba(255, 255, 255, 0.2)"
              },
              children: children,
              contentBlur: 1,
              message: getLoader(undefined),
              show: show
            });
}

var make = App_OverlaySpinner;

export {
  bottom ,
  top ,
  circle ,
  background ,
  make ,
}
/* bottom Not a pure module */
