// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReDate_utils from "@mobily/rescript-date/src/ReDate_utils.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Date.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Error_Inputs.bs.js";

function validateRequiredDateCompare(smallerDate, largerDate, flashStartDate, flashEndDate, thisDate, startDateAsFlash, endDateAsFlash) {
  var match = thisDate === "smaller" ? [
      smallerDate,
      startDateAsFlash,
      largerDate,
      endDateAsFlash
    ] : [
      largerDate,
      endDateAsFlash,
      smallerDate,
      startDateAsFlash
    ];
  var thatAsFlash = match[3];
  var that = match[2];
  if (flashStartDate === undefined) {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.datesHaveIssue
          };
  }
  if (flashEndDate === undefined) {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.datesHaveIssue
          };
  }
  var end = Caml_option.valFromOption(flashEndDate);
  var start = Caml_option.valFromOption(flashStartDate);
  var match$1 = App_Types_Date$DvmAdminFrontendFr.isValid(start);
  var match$2 = App_Types_Date$DvmAdminFrontendFr.isValid(end);
  if (match$1 && match$2) {
    if (match[1]) {
      return {
              TAG: /* Ok */0,
              _0: undefined
            };
    } else {
      return Belt_Option.mapWithDefault(match[0], {
                  TAG: /* Ok */0,
                  _0: undefined
                }, (function ($$this) {
                    if (App_Types_Date$DvmAdminFrontendFr.isValid($$this)) {
                      if (ReDate_utils.makeStartOfDayDate(start).valueOf() <= ReDate_utils.makeStartOfDayDate($$this).valueOf() && ReDate_utils.makeStartOfDayDate(end).valueOf() >= ReDate_utils.makeStartOfDayDate($$this).valueOf()) {
                        if (thatAsFlash) {
                          return {
                                  TAG: /* Ok */0,
                                  _0: Caml_option.some($$this)
                                };
                        } else {
                          return Belt_Option.mapWithDefault(that, {
                                      TAG: /* Error */1,
                                      _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherDateNotValid
                                    }, (function (that) {
                                        if (App_Types_Date$DvmAdminFrontendFr.isValid(that)) {
                                          if (thisDate === "smaller") {
                                            if (ReDate.isAfter(that, $$this)) {
                                              return {
                                                      TAG: /* Ok */0,
                                                      _0: Caml_option.some($$this)
                                                    };
                                            } else {
                                              return {
                                                      TAG: /* Error */1,
                                                      _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateTooLarge
                                                    };
                                            }
                                          } else if (ReDate.isAfter($$this, that)) {
                                            return {
                                                    TAG: /* Ok */0,
                                                    _0: Caml_option.some($$this)
                                                  };
                                          } else {
                                            return {
                                                    TAG: /* Error */1,
                                                    _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateTooSmall
                                                  };
                                          }
                                        } else {
                                          return {
                                                  TAG: /* Error */1,
                                                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherDateNotValid
                                                };
                                        }
                                      }));
                        }
                      } else {
                        return {
                                TAG: /* Error */1,
                                _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateOutOfPeriod
                              };
                      }
                    } else {
                      return {
                              TAG: /* Error */1,
                              _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                            };
                    }
                  }));
    }
  } else {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.datesHaveIssue
          };
  }
}

export {
  validateRequiredDateCompare ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
