// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function mapToNewVehicle(output, newVehicle) {
  var customBonuses = Belt_List.fromArray(Belt_Array.map(Belt_Array.keep(output.versions, (function (x) {
                  return x.isCustomFEValue;
                })), (function (x) {
              return {
                      one: x.version,
                      two: x.customBonus
                    };
            })));
  return {
          actionStartDate: newVehicle.actionStartDate,
          actionEndDate: newVehicle.actionEndDate,
          customBonuses: customBonuses,
          engineTypes: newVehicle.engineTypes,
          modelPhases: newVehicle.modelPhases,
          mappingDirection: newVehicle.mappingDirection,
          modelVersions: newVehicle.modelVersions,
          brandsNewCar: newVehicle.brandsNewCar
        };
}

function cleanCustomBonus(x, flash) {
  var customBonus = Belt_Option.getWithDefault(x.customBonus, 0.0);
  if (customBonus > 0.0) {
    return customBonus;
  } else {
    return Belt_Option.mapWithDefault(flash.amount, 0.0, (function (x) {
                  return Belt_Option.getWithDefault(x.value, 0.0);
                }));
  }
}

function populateForm(versions, form, flash) {
  return Belt_List.mapWithIndex(versions, (function (index, x) {
                Curry._1(form.addVersion, {
                      index: index,
                      brand: x.brandDescription,
                      model: x.model,
                      version: x.version,
                      versionLabel: Belt_Option.getWithDefault(x.versionLabel, ""),
                      customBonus: String(cleanCustomBonus(x, flash)),
                      originalBonus: cleanCustomBonus(x, flash),
                      actionBonus: Belt_Option.mapWithDefault(flash.amount, 0.0, (function (x) {
                              return Belt_Option.getWithDefault(x.value, 0.0);
                            })),
                      isCustomFEValue: Belt_Option.getWithDefault(x.isCustomFEValue, false),
                      engineType: Belt_Option.getWithDefault(x.engineType, ""),
                      modelPhase: Belt_Option.getWithDefault(x.modelPhase, "")
                    });
              }));
}

function getChangedRowsAmount(form) {
  return Belt_List.length(Belt_List.keep(Belt_List.fromArray(form.input.versions), (function (x) {
                    return x.customBonus !== String(x.originalBonus);
                  })));
}

var Row;

var Form;

var Types;

var NewVehiclesTypes;

export {
  Row ,
  Form ,
  Types ,
  NewVehiclesTypes ,
  mapToNewVehicle ,
  cleanCustomBonus ,
  populateForm ,
  getChangedRowsAmount ,
}
/* No side effect */
