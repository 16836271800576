// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../../common/components/icons/App_Icon.bs.js";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../common/components/App_Modal.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Api.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../common/App_Typography.bs.js";
import * as Flash_Edit_Api$DvmAdminFrontendFr from "../../Flash_Edit_Api.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Button.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table_Pagination.bs.js";
import * as App_Clickable_Element$DvmAdminFrontendFr from "../../../../../common/components/button/App_Clickable_Element.bs.js";
import * as Flash_Edit_Fleet_Version_Table$DvmAdminFrontendFr from "./Flash_Edit_Fleet_Version_Table.bs.js";
import * as Flash_Edit_Fleet_Version_Filter$DvmAdminFrontendFr from "./Flash_Edit_Fleet_Version_Filter.bs.js";
import * as Flash_Edit_Fleet_Version_Table_Api$DvmAdminFrontendFr from "./Flash_Edit_Fleet_Version_Table_Api.bs.js";
import * as Flash_Edit_Version_Perf_Edit_Modal$DvmAdminFrontendFr from "./modalPerf/editAdd/perfEdit/Flash_Edit_Version_Perf_Edit_Modal.bs.js";

function Flash_Edit_Fleet_Version_Page(Props) {
  var flashId = Props.flashId;
  var flash = Props.flash;
  var actionType = Props.actionType;
  var fetchFlashModelVersionsForCumulables = Props.fetchFlashModelVersionsForCumulables;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match[1];
  var match$1 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: Caml_array.get(App_Table_Pagination$DvmAdminFrontendFr.options, 0),
                headers: /* [] */0,
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$1[1];
  var fetchRequest = match$1[0];
  var alert = ReactAlert.useAlert();
  var match$2 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$3 = match$2[0];
  var userManager = match$3.userManager;
  var selectedRole = match$3.selectedRole;
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setVersionsListResult = match$4[1];
  var versionsListResult = match$4[0];
  var match$5 = React.useState(function () {
        return /* [] */0;
      });
  var setActionDistinctVersions = match$5[1];
  var actionDistinctVersions = match$5[0];
  var match$6 = React.useState(function () {
        return /* [] */0;
      });
  var setActionAllVersions = match$6[1];
  var match$7 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDeleteResult = match$7[1];
  var deleteResult = match$7[0];
  var match$8 = React.useState(function () {
        return false;
      });
  var setWithClosedModels = match$8[1];
  var withClosedModels = match$8[0];
  var match$9 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelVersions = match$9[1];
  var match$10 = React.useState(function () {
        return null;
      });
  var setModal = match$10[1];
  var match$11 = React.useState(function () {
        return false;
      });
  var setPerfModal = match$11[1];
  var fetchData = function (submissionCallbacks, fetchRequest) {
    Flash_Edit_Fleet_Version_Table_Api$DvmAdminFrontendFr.fetchModelsTable(submissionCallbacks, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
              page: fetchRequest.page,
              rowsPerPage: 10,
              headers: fetchRequest.headers,
              filterQueryParams: fetchRequest.filterQueryParams,
              additionalQueryParams: fetchRequest.additionalQueryParams
            }), flashId, selectedRole, actionType === /* PERF */1, withClosedModels);
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  var refreshVersions = function (param) {
    Flash_Edit_Fleet_Version_Table_Api$DvmAdminFrontendFr.getFlashVersionsForDropdown(userManager, setVersionsListResult, flashId, actionType, selectedRole, withClosedModels);
  };
  var refreshAndFetch = function (param) {
    fetchData(undefined, fetchRequest);
    refreshVersions(undefined);
  };
  React.useEffect((function () {
          if (actionType === /* PERF */1) {
            fetchData(undefined, fetchRequest);
            Curry._1(setFetchRequest, (function (param) {
                    return {
                            page: fetchRequest.page,
                            rowsPerPage: fetchRequest.rowsPerPage,
                            headers: Flash_Edit_Fleet_Version_Table$DvmAdminFrontendFr.headersConfiguration(/* PERF */1),
                            filterQueryParams: fetchRequest.filterQueryParams,
                            additionalQueryParams: fetchRequest.additionalQueryParams
                          };
                  }));
            refreshVersions(undefined);
          } else {
            fetchData(undefined, fetchRequest);
            Curry._1(setFetchRequest, (function (param) {
                    return {
                            page: fetchRequest.page,
                            rowsPerPage: fetchRequest.rowsPerPage,
                            headers: Flash_Edit_Fleet_Version_Table$DvmAdminFrontendFr.headersConfiguration(actionType),
                            filterQueryParams: fetchRequest.filterQueryParams,
                            additionalQueryParams: fetchRequest.additionalQueryParams
                          };
                  }));
            refreshVersions(undefined);
          }
        }), [actionType]);
  React.useEffect((function () {
          if (typeof versionsListResult === "number") {
            versionsListResult === /* NotStarted */0;
          } else if (versionsListResult.TAG === /* Ok */0) {
            var versions = versionsListResult._0;
            Curry._1(setActionDistinctVersions, (function (param) {
                    return Belt_List.map(Belt_MapString.toList(Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(versions, (function (x) {
                                                  return [
                                                          x.modelVersionID,
                                                          x
                                                        ];
                                                }))))), (function (param) {
                                  return param[1];
                                }));
                  }));
            Curry._1(setActionAllVersions, (function (param) {
                    return versions;
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: versionsListResult._0
                      }
                    }));
          }
        }), [versionsListResult]);
  var fetchFlashModelVersionsForDropdown = function (param) {
    Flash_Edit_Api$DvmAdminFrontendFr.fetchFlashModelVersions(alert, userManager, setModelVersions, selectedRole, withClosedModels, flashId);
  };
  var fetchUpdatedModelsForDropdownAndCumulables = function (param) {
    fetchFlashModelVersionsForDropdown(undefined);
    Curry._1(fetchFlashModelVersionsForCumulables, flashId);
  };
  React.useEffect((function () {
          if (typeof deleteResult === "number") {
            deleteResult === /* NotStarted */0;
          } else if (deleteResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            refreshVersions(undefined);
            fetchData(undefined, fetchRequest);
            fetchFlashModelVersionsForDropdown(undefined);
            Curry._1(fetchFlashModelVersionsForCumulables, flashId);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: deleteResult._0
                      }
                    }));
          }
        }), [deleteResult]);
  React.useEffect((function () {
          fetchFlashModelVersionsForDropdown(undefined);
          if (actionType !== /* NOTSET */3) {
            refreshVersions(undefined);
          }
          
        }), [withClosedModels]);
  return React.createElement(React.Fragment, undefined, React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                  show: App_Types_Result$DvmAdminFrontendFr.isPending(versionsListResult),
                  children: React.createElement(Flash_Edit_Fleet_Version_Filter$DvmAdminFrontendFr.make, {
                        flash: flash,
                        fetchData: fetchData,
                        fetchRequest: fetchRequest,
                        refreshVersions: refreshVersions,
                        actionDistinctVersions: actionDistinctVersions,
                        actionType: actionType,
                        modelVersions: match$9[0],
                        fetchUpdatedModelsForDropdownAndCumulables: fetchUpdatedModelsForDropdownAndCumulables,
                        withClosedModels: withClosedModels,
                        setWithClosedModels: setWithClosedModels
                      })
                }), actionType === /* PERF */1 ? React.createElement(Core.Grid, {
                    children: React.createElement(App_Clickable_Element$DvmAdminFrontendFr.make, {
                          onClick: (function (param) {
                              Curry._1(setPerfModal, (function (param) {
                                      return true;
                                    }));
                            }),
                          children: React.createElement(Core.Grid, {
                                alignItems: "center",
                                children: null,
                                container: true,
                                spacing: 1
                              }, React.createElement(Core.Grid, {
                                    children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                          size: /* Regular */2,
                                          type_: /* Edit */60
                                        }),
                                    item: true
                                  }), React.createElement(Core.Grid, {
                                    children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                          style: {
                                            fontWeight: "600"
                                          },
                                          children: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.edit).toLocaleUpperCase(),
                                          variant: "bodyBig"
                                        }),
                                    item: true
                                  }))
                        }),
                    container: true,
                    justifyContent: "flex-end"
                  }) : null, React.createElement(Flash_Edit_Fleet_Version_Table$DvmAdminFrontendFr.make, {
                  flash: flash,
                  tablePage: match[0],
                  fetchRequest: fetchRequest,
                  fetchData: fetchData,
                  refreshVersions: refreshVersions,
                  fetchUpdatedModelsForDropdownAndCumulables: fetchUpdatedModelsForDropdownAndCumulables,
                  actionType: actionType
                }), Belt_List.length(actionDistinctVersions) === 0 ? null : React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(deleteResult),
                    children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                          onClick: (function (param) {
                              Curry._1(setModal, (function (param) {
                                      return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
                                                  open: true,
                                                  onClose: (function (param) {
                                                      Curry._1(setModal, (function (param) {
                                                              return null;
                                                            }));
                                                    }),
                                                  title: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.deleteAllTitle),
                                                  minWidth: "700px",
                                                  children: React.createElement(Core.Grid, {
                                                        children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                              onClick: (function (param) {
                                                                  Curry._1(setModal, (function (param) {
                                                                          return null;
                                                                        }));
                                                                  Flash_Edit_Fleet_Version_Table_Api$DvmAdminFrontendFr.deleteAllFleetVersions(userManager, setDeleteResult, flashId, actionType, selectedRole);
                                                                }),
                                                              label: "OK",
                                                              size: "medium"
                                                            }),
                                                        container: true,
                                                        justifyContent: "center"
                                                      })
                                                });
                                    }));
                            }),
                          label: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.deleteAllButton),
                          color: "delete",
                          size: "medium"
                        })
                  }), match$10[0], match$11[0] ? React.createElement(Flash_Edit_Version_Perf_Edit_Modal$DvmAdminFrontendFr.make, {
                    flash: flash,
                    onClose: (function (param) {
                        Curry._1(setPerfModal, (function (param) {
                                return false;
                              }));
                      }),
                    onOk: (function (param) {
                        Curry._1(setPerfModal, (function (param) {
                                return false;
                              }));
                        fetchData(undefined, fetchRequest);
                        refreshVersions(undefined);
                      }),
                    actionAllVersions: match$6[0],
                    refreshAndFetch: refreshAndFetch,
                    withClosedModels: withClosedModels,
                    setWithClosedModels: setWithClosedModels,
                    versionsListResult: versionsListResult
                  }) : null);
}

var Api;

var Table;

var Filter;

var Types;

var MainApi;

var make = Flash_Edit_Fleet_Version_Page;

export {
  Api ,
  Table ,
  Filter ,
  Types ,
  MainApi ,
  make ,
}
/* react Not a pure module */
