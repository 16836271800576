// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../common/App_Typography.bs.js";
import * as Messages_Orders_DealerRequest$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_DealerRequest.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Document_Row$DvmAdminFrontendFr from "./Order_Detail_Bonus_DealerRequest_Document_Row.bs.js";
import * as Order_Detail_Bonus_DealerRequest_FileUpload_Button$DvmAdminFrontendFr from "./Order_Detail_Bonus_DealerRequest_FileUpload_Button.bs.js";
import * as Order_Detail_Bonus_DealerRequest_Document_Row_Saved$DvmAdminFrontendFr from "./Order_Detail_Bonus_DealerRequest_Document_Row_Saved.bs.js";

function Order_Detail_Bonus_DealerRequest_Document_Selection(Props) {
  var newDocuments = Props.newDocuments;
  var setNewDocuments = Props.setNewDocuments;
  var saveResult = Props.saveResult;
  var actionId = Props.actionId;
  var orderId = Props.orderId;
  var existingDocuments = Props.existingDocuments;
  var setExistingDocuments = Props.setExistingDocuments;
  var documentTypes = Props.documentTypes;
  var intl = ReactIntl.useIntl();
  var addFile = function (file) {
    Curry._1(setNewDocuments, (function (x) {
            return Belt_List.concat(x, {
                        hd: {
                          document: file,
                          fileType: "",
                          comment: "",
                          uploaded: false
                        },
                        tl: /* [] */0
                      });
          }));
  };
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              direction: "column",
              item: true,
              spacing: 1
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        gutterBottom: true,
                        style: {
                          fontWeight: "600"
                        },
                        children: intl.formatMessage(Messages_Orders_DealerRequest$DvmAdminFrontendFr.documentsTitle),
                        variant: "h6"
                      }),
                  item: true,
                  xs: Grid$Mui.Xs[3]
                }), Belt_Array.mapWithIndex(Belt_List.toArray(existingDocuments), (function (index, item) {
                    return React.createElement(Order_Detail_Bonus_DealerRequest_Document_Row_Saved$DvmAdminFrontendFr.make, {
                                setExistingDocuments: setExistingDocuments,
                                index: index,
                                item: item,
                                orderId: orderId,
                                actionId: actionId,
                                documentTypes: documentTypes
                              });
                  })), Belt_Array.mapWithIndex(Belt_List.toArray(newDocuments), (function (index, item) {
                    return React.createElement(Order_Detail_Bonus_DealerRequest_Document_Row$DvmAdminFrontendFr.make, {
                                setNewDocuments: setNewDocuments,
                                index: index,
                                item: item,
                                saveResult: saveResult,
                                orderId: orderId,
                                actionId: actionId,
                                documentTypes: documentTypes
                              });
                  })), React.createElement(Core.Grid, {
                  children: React.createElement(Order_Detail_Bonus_DealerRequest_FileUpload_Button$DvmAdminFrontendFr.make, {
                        addFile: addFile
                      }),
                  item: true
                }));
}

var make = Order_Detail_Bonus_DealerRequest_Document_Selection;

export {
  make ,
}
/* react Not a pure module */
