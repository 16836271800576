// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../../common/components/App_Checkbox.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../../context/user/User_Types_Utils.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as Messages_Submarket$DvmAdminFrontendFr from "../../../../intl/messages/gemo/Messages_Submarket.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_SelectFormInput.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_TextFieldFormInput.bs.js";

function Submarket_AddEdit_Inputs(Props) {
  var form = Props.form;
  var original = Props.original;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  React.useEffect((function () {
          if (Belt_Option.isNone(original) && form.input.orderType.length > 0) {
            Curry._2(form.updateBudgetActionType, (function (input, budgetActionType) {
                    return {
                            submarketCode: input.submarketCode,
                            orderType: input.orderType,
                            budgetActionType: budgetActionType,
                            budgetActionDefault: input.budgetActionDefault
                          };
                  }), "");
          }
          
        }), [form.input.orderType]);
  var tmp = {
    label: Messages_Submarket$DvmAdminFrontendFr.orderType,
    options: User_Types_Utils$DvmAdminFrontendFr.whatCountry(match[0].selectedRole) === /* France */0 ? ({
          hd: {
            label: {
              TAG: /* String */3,
              _0: "SO"
            },
            value: "SO"
          },
          tl: {
            hd: {
              label: {
                TAG: /* String */3,
                _0: "CL"
              },
              value: "CL"
            },
            tl: {
              hd: {
                label: {
                  TAG: /* String */3,
                  _0: "VD"
                },
                value: "VD"
              },
              tl: {
                hd: {
                  label: {
                    TAG: /* String */3,
                    _0: "VE"
                  },
                  value: "VE"
                },
                tl: {
                  hd: {
                    label: {
                      TAG: /* String */3,
                      _0: "RR"
                    },
                    value: "RR"
                  },
                  tl: {
                    hd: {
                      label: {
                        TAG: /* String */3,
                        _0: "RC"
                      },
                      value: "RC"
                    },
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }) : ({
          hd: {
            label: {
              TAG: /* String */3,
              _0: "CL"
            },
            value: "CL"
          },
          tl: {
            hd: {
              label: {
                TAG: /* String */3,
                _0: "VD"
              },
              value: "VD"
            },
            tl: {
              hd: {
                label: {
                  TAG: /* String */3,
                  _0: "VE"
                },
                value: "VE"
              },
              tl: {
                hd: {
                  label: {
                    TAG: /* String */3,
                    _0: "RR"
                  },
                  value: "RR"
                },
                tl: {
                  hd: {
                    label: {
                      TAG: /* String */3,
                      _0: "RC"
                    },
                    value: "RC"
                  },
                  tl: /* [] */0
                }
              }
            }
          }
        }),
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.orderType,
        onChange: Curry._1(form.updateOrderType, (function (input, orderType) {
                return {
                        submarketCode: input.submarketCode,
                        orderType: orderType,
                        budgetActionType: input.budgetActionType,
                        budgetActionDefault: input.budgetActionDefault
                      };
              }))
      }
    },
    id: "orderType",
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
    disabled: Belt_Option.isSome(original),
    required: /* OnlyLabel */1
  };
  if (form.orderTypeResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.orderTypeResult);
  }
  var tmp$1;
  if (form.input.orderType === "SO") {
    var tmp$2 = {
      label: Messages_Submarket$DvmAdminFrontendFr.budgetActionType,
      options: {
        hd: {
          label: {
            TAG: /* String */3,
            _0: "LLD"
          },
          value: "LLD"
        },
        tl: {
          hd: {
            label: {
              TAG: /* String */3,
              _0: "LCD"
            },
            value: "LCD"
          },
          tl: {
            hd: {
              label: {
                TAG: /* String */3,
                _0: "PROT"
              },
              value: "PROT"
            },
            tl: {
              hd: {
                label: {
                  TAG: /* String */3,
                  _0: "AVS"
                },
                value: "AVS"
              },
              tl: {
                hd: {
                  label: {
                    TAG: /* String */3,
                    _0: "STOC"
                  },
                  value: "STOC"
                },
                tl: {
                  hd: {
                    label: {
                      TAG: /* String */3,
                      _0: "REPR"
                    },
                    value: "REPR"
                  },
                  tl: {
                    hd: {
                      label: {
                        TAG: /* String */3,
                        _0: "PERF"
                      },
                      value: "PERF"
                    },
                    tl: {
                      hd: {
                        label: {
                          TAG: /* String */3,
                          _0: "DERO"
                        },
                        value: "DERO"
                      },
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      },
      value: {
        TAG: /* Single */0,
        _0: {
          value: form.input.budgetActionType,
          onChange: Curry._1(form.updateBudgetActionType, (function (input, budgetActionType) {
                  return {
                          submarketCode: input.submarketCode,
                          orderType: input.orderType,
                          budgetActionType: budgetActionType,
                          budgetActionDefault: input.budgetActionDefault
                        };
                }))
        }
      },
      id: "budgetActionType",
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
      disabled: Belt_Option.isSome(original),
      required: /* OnlyLabel */1
    };
    if (form.budgetActionTypeResult !== undefined) {
      tmp$2.validationResult = Caml_option.valFromOption(form.budgetActionTypeResult);
    }
    tmp$1 = React.createElement(Core.Grid, {
          children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, tmp$2),
          item: true
        });
  } else {
    var tmp$3 = {
      onChange: (function (val) {
          Curry._2(form.updateBudgetActionType, (function (input, budgetActionType) {
                  return {
                          submarketCode: input.submarketCode,
                          orderType: input.orderType,
                          budgetActionType: budgetActionType,
                          budgetActionDefault: input.budgetActionDefault
                        };
                }), val.toUpperCase());
        }),
      value: form.input.budgetActionType,
      disabled: Belt_Option.isSome(original) || form.input.budgetActionDefault,
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
      label: Messages_Submarket$DvmAdminFrontendFr.budgetActionType,
      required: /* OnlyLabel */1
    };
    if (form.budgetActionTypeResult !== undefined) {
      tmp$3.validationResult = Caml_option.valFromOption(form.budgetActionTypeResult);
    }
    tmp$1 = React.createElement(Core.Grid, {
          children: null,
          container: true,
          direction: "column",
          item: true,
          style: {
            width: "fit-content"
          }
        }, React.createElement(Core.Grid, {
              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$3),
              item: true
            }), form.input.orderType === "CL" || form.input.orderType === "VD" ? React.createElement(Core.Grid, {
                children: React.createElement(Core.FormControlLabel, {
                      classes: {
                        label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                      },
                      control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                            checked: form.input.budgetActionDefault,
                            onClick: (function ($$event) {
                                Curry._2(form.updateBudgetActionDefault, (function (input, budgetActionDefault) {
                                        return {
                                                submarketCode: input.submarketCode,
                                                orderType: input.orderType,
                                                budgetActionType: input.budgetActionType,
                                                budgetActionDefault: budgetActionDefault
                                              };
                                      }), $$event.target.checked);
                              })
                          }),
                      disabled: Belt_Option.isSome(original),
                      label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.$$default)
                    }),
                item: true
              }) : null);
  }
  var tmp$4 = {
    onChange: (function (val) {
        Curry._2(form.updateSubmarketCode, (function (input, submarketCode) {
                return {
                        submarketCode: submarketCode,
                        orderType: input.orderType,
                        budgetActionType: input.budgetActionType,
                        budgetActionDefault: input.budgetActionDefault
                      };
              }), val.toUpperCase());
      }),
    value: form.input.submarketCode,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
    label: Messages_Submarket$DvmAdminFrontendFr.submarketCode,
    required: /* OnlyLabel */1
  };
  if (form.submarketCodeResult !== undefined) {
    tmp$4.validationResult = Caml_option.valFromOption(form.submarketCodeResult);
  }
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              item: true,
              spacing: 1
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, tmp),
                  item: true
                }), tmp$1, React.createElement(Core.Grid, {
                  children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$4),
                  item: true
                }));
}

var InputStyles;

var Api;

var Types;

var Form;

var make = Submarket_AddEdit_Inputs;

export {
  InputStyles ,
  Api ,
  Types ,
  Form ,
  make ,
}
/* react Not a pure module */
