// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../common/types/App_Types_Date.bs.js";

var FilterFields = {
  dr: "dr",
  dealerId: "dealerID",
  contractId: "contractID",
  contractLabel: "contractLabel",
  siret: "siret",
  quotaStartYear: "quotaStartYear",
  quotaEndYear: "quotaEndYear"
};

var Columns = {
  siret: "siret",
  name: "companyName",
  dealerId: "dealerID",
  remaining: "remaining",
  initial: "volumeInitial",
  corrected: "volumeCorrected",
  customerInvoiced: "customerInvoiced",
  customerNotInvoiced: "customerNotInvoiced",
  consumedTotal: "consumedTotal",
  exceeded: "exceeded"
};

function row_encode(v) {
  return Js_dict.fromArray([
              [
                "dr",
                Decco.stringToJson(v.dr)
              ],
              [
                "contractID",
                Decco.stringToJson(v.contractID)
              ],
              [
                "vehicleType",
                Decco.stringToJson(v.vehicleType)
              ],
              [
                "quotaStartDate",
                Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.quotaStartDate)
              ],
              [
                "siret",
                Decco.optionToJson(Decco.stringToJson, v.siret)
              ],
              [
                "companyName",
                Decco.optionToJson(Decco.stringToJson, v.companyName)
              ],
              [
                "dealerId",
                Decco.optionToJson(Decco.stringToJson, v.dealerId)
              ],
              [
                "remaining",
                Decco.optionToJson(Decco.intToJson, v.remaining)
              ],
              [
                "initial",
                Decco.optionToJson(Decco.intToJson, v.initial)
              ],
              [
                "corrected",
                Decco.optionToJson(Decco.intToJson, v.corrected)
              ],
              [
                "customerInvoiced",
                Decco.optionToJson(Decco.intToJson, v.customerInvoiced)
              ],
              [
                "customerNotInvoiced",
                Decco.optionToJson(Decco.intToJson, v.customerNotInvoiced)
              ],
              [
                "consumedTotal",
                Decco.optionToJson(Decco.intToJson, v.consumedTotal)
              ],
              [
                "exceeded",
                Decco.optionToJson(Decco.intToJson, v.exceeded)
              ]
            ]);
}

function row_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var dr = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "dr"), null));
  if (dr.TAG === /* Ok */0) {
    var contractID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractID"), null));
    if (contractID.TAG === /* Ok */0) {
      var vehicleType = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleType"), null));
      if (vehicleType.TAG === /* Ok */0) {
        var quotaStartDate = Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "quotaStartDate"), null));
        if (quotaStartDate.TAG === /* Ok */0) {
          var siret = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "siret"), null));
          if (siret.TAG === /* Ok */0) {
            var companyName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "companyName"), null));
            if (companyName.TAG === /* Ok */0) {
              var dealerId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerId"), null));
              if (dealerId.TAG === /* Ok */0) {
                var remaining = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "remaining"), null));
                if (remaining.TAG === /* Ok */0) {
                  var initial = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "initial"), null));
                  if (initial.TAG === /* Ok */0) {
                    var corrected = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "corrected"), null));
                    if (corrected.TAG === /* Ok */0) {
                      var customerInvoiced = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "customerInvoiced"), null));
                      if (customerInvoiced.TAG === /* Ok */0) {
                        var customerNotInvoiced = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "customerNotInvoiced"), null));
                        if (customerNotInvoiced.TAG === /* Ok */0) {
                          var consumedTotal = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "consumedTotal"), null));
                          if (consumedTotal.TAG === /* Ok */0) {
                            var exceeded = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "exceeded"), null));
                            if (exceeded.TAG === /* Ok */0) {
                              return {
                                      TAG: /* Ok */0,
                                      _0: {
                                        dr: dr._0,
                                        contractID: contractID._0,
                                        vehicleType: vehicleType._0,
                                        quotaStartDate: quotaStartDate._0,
                                        siret: siret._0,
                                        companyName: companyName._0,
                                        dealerId: dealerId._0,
                                        remaining: remaining._0,
                                        initial: initial._0,
                                        corrected: corrected._0,
                                        customerInvoiced: customerInvoiced._0,
                                        customerNotInvoiced: customerNotInvoiced._0,
                                        consumedTotal: consumedTotal._0,
                                        exceeded: exceeded._0
                                      }
                                    };
                            }
                            var e = exceeded._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".exceeded" + e.path,
                                      message: e.message,
                                      value: e.value
                                    }
                                  };
                          }
                          var e$1 = consumedTotal._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".consumedTotal" + e$1.path,
                                    message: e$1.message,
                                    value: e$1.value
                                  }
                                };
                        }
                        var e$2 = customerNotInvoiced._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".customerNotInvoiced" + e$2.path,
                                  message: e$2.message,
                                  value: e$2.value
                                }
                              };
                      }
                      var e$3 = customerInvoiced._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".customerInvoiced" + e$3.path,
                                message: e$3.message,
                                value: e$3.value
                              }
                            };
                    }
                    var e$4 = corrected._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".corrected" + e$4.path,
                              message: e$4.message,
                              value: e$4.value
                            }
                          };
                  }
                  var e$5 = initial._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".initial" + e$5.path,
                            message: e$5.message,
                            value: e$5.value
                          }
                        };
                }
                var e$6 = remaining._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".remaining" + e$6.path,
                          message: e$6.message,
                          value: e$6.value
                        }
                      };
              }
              var e$7 = dealerId._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".dealerId" + e$7.path,
                        message: e$7.message,
                        value: e$7.value
                      }
                    };
            }
            var e$8 = companyName._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".companyName" + e$8.path,
                      message: e$8.message,
                      value: e$8.value
                    }
                  };
          }
          var e$9 = siret._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".siret" + e$9.path,
                    message: e$9.message,
                    value: e$9.value
                  }
                };
        }
        var e$10 = quotaStartDate._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".quotaStartDate" + e$10.path,
                  message: e$10.message,
                  value: e$10.value
                }
              };
      }
      var e$11 = vehicleType._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".vehicleType" + e$11.path,
                message: e$11.message,
                value: e$11.value
              }
            };
    }
    var e$12 = contractID._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".contractID" + e$12.path,
              message: e$12.message,
              value: e$12.value
            }
          };
  }
  var e$13 = dr._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".dr" + e$13.path,
            message: e$13.message,
            value: e$13.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(row_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(row_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var Statistic = {
  row_encode: row_encode,
  row_decode: row_decode,
  searchResult_encode: searchResult_encode,
  searchResult_decode: searchResult_decode
};

function quotaDelete_encode(v) {
  return Js_dict.fromArray([
              [
                "contractId",
                Decco.stringToJson(v.contractId)
              ],
              [
                "dr",
                Decco.stringToJson(v.dr)
              ],
              [
                "vehicleType",
                Decco.stringToJson(v.vehicleType)
              ],
              [
                "startDate",
                Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ]
            ]);
}

function quotaDelete_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var contractId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractId"), null));
  if (contractId.TAG === /* Ok */0) {
    var dr = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "dr"), null));
    if (dr.TAG === /* Ok */0) {
      var vehicleType = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleType"), null));
      if (vehicleType.TAG === /* Ok */0) {
        var startDate = Curry._1(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
        if (startDate.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    contractId: contractId._0,
                    dr: dr._0,
                    vehicleType: vehicleType._0,
                    startDate: startDate._0
                  }
                };
        }
        var e = startDate._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".startDate" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = vehicleType._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".vehicleType" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = dr._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".dr" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = contractId._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".contractId" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function contracts_encode(v) {
  return Js_dict.fromArray([
              [
                "contractID",
                Decco.stringToJson(v.contractID)
              ],
              [
                "contractLabel",
                Decco.optionToJson(Decco.stringToJson, v.contractLabel)
              ]
            ]);
}

function contracts_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var contractID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractID"), null));
  if (contractID.TAG === /* Ok */0) {
    var contractLabel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractLabel"), null));
    if (contractLabel.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                contractID: contractID._0,
                contractLabel: contractLabel._0
              }
            };
    }
    var e = contractLabel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".contractLabel" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = contractID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".contractID" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function searchResult_encode$1(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(contracts_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode$1(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(contracts_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var ContractSelect = {
  contracts_encode: contracts_encode,
  contracts_decode: contracts_decode,
  searchResult_encode: searchResult_encode$1,
  searchResult_decode: searchResult_decode$1
};

export {
  FilterFields ,
  Columns ,
  Statistic ,
  quotaDelete_encode ,
  quotaDelete_decode ,
  ContractSelect ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
