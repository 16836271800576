// Generated by ReScript, PLEASE EDIT WITH CARE


var pageTitle = {
  id: "d7528915-9d6e-460d-b323-42aa0787d1ef",
  defaultMessage: "Modification des SIRET"
};

var editModalTitle = {
  id: "114414ff-cdb7-47ba-a435-99375e2bfa09",
  defaultMessage: "Modifier un SIRET"
};

var createTitle = {
  id: "a32e1b8d-6f92-4928-af2a-d5f2afb37a57",
  defaultMessage: "Créer un nouveau SIRET"
};

var street = {
  id: "06291c65-1b00-402e-b007-61aa0f831764",
  defaultMessage: "Rue"
};

var streetNbr = {
  id: "70fa774b-2458-4a9a-9a63-34d6cd2a6d11",
  defaultMessage: "Numéro de rue"
};

var additionalAddressInfo = {
  id: "8c49667d-f877-4f87-9a7a-99f7cb16dfed",
  defaultMessage: "Détails de l'adresse"
};

var zipCode = {
  id: "7d6c7767-0e4a-436b-8652-894b531f9e04",
  defaultMessage: "Code postal"
};

var countryCode = {
  id: "fd3c3aaa-d067-4d23-b0e9-9c9bd5e4cc68",
  defaultMessage: "Code du pays"
};

var deleteTitle = {
  id: "d0f53fd0-a745-46cb-9f90-5e719c3840c3",
  defaultMessage: "Supprimer {siret}?"
};

var codeAPE = {
  id: "ebea029c-122a-4836-94dd-8070270ccf89",
  defaultMessage: "Code activité (APE)"
};

var hierarchy = {
  id: "c3391c09-ec4c-49d2-be6e-574a8580e6d4",
  defaultMessage: "Hiérarchie"
};

var importDate = {
  id: "9c81137d-5c09-4947-9a9a-de70acaf95af",
  defaultMessage: "Date d'importation"
};

var hierarchyOne = {
  id: "8924ecbe-6035-49c6-aa33-fca4cd1d2a84",
  defaultMessage: "Siège"
};

var hierarchyTwo = {
  id: "02ec689d-d5d9-424b-889c-c8eafebcb865",
  defaultMessage: "Etablissement"
};

var hierarchyFromBCC = {
  id: "3f533081-1ff1-405d-b2f2-9c90f0ba763d",
  defaultMessage: "Importée depuis BCC"
};

export {
  pageTitle ,
  editModalTitle ,
  createTitle ,
  street ,
  streetNbr ,
  additionalAddressInfo ,
  zipCode ,
  countryCode ,
  deleteTitle ,
  codeAPE ,
  hierarchy ,
  importDate ,
  hierarchyOne ,
  hierarchyTwo ,
  hierarchyFromBCC ,
}
/* No side effect */
