// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Route_Paths$DvmAdminFrontendFr from "../../route/Route_Paths.bs.js";
import * as Messages_Energy$DvmAdminFrontendFr from "../../intl/messages/energy/Messages_Energy.bs.js";
import * as Messages_Quotas$DvmAdminFrontendFr from "../../intl/messages/quotas/Messages_Quotas.bs.js";
import * as Messages_TopMenu$DvmAdminFrontendFr from "../../intl/messages/topMenu/Messages_TopMenu.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../context/user/User_Types_Utils.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";

function options(intl, selectedRole) {
  var arr = [];
  var getOrderItem = function (param) {
    arr.push({
          uuid: "e8179b56-d5d9-4b2d-a507-4c4149613acd",
          label: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.order),
          redirect: Route_Paths$DvmAdminFrontendFr.rView + Route_Paths$DvmAdminFrontendFr.rOrder,
          location: /* View */0
        });
  };
  var getBonusesItem = function ($$location) {
    arr.push({
          uuid: $$location ? "602b0281-b048-47cb-9cd5-0c7b51aceba3" : "0c3e243d-b62a-4c3c-87e0-81b044024c18",
          label: intl.formatMessage($$location ? Messages_TopMenu$DvmAdminFrontendFr.bonusesEdit : Messages_TopMenu$DvmAdminFrontendFr.bonusesView),
          redirect: (
            $$location ? Route_Paths$DvmAdminFrontendFr.rManage : Route_Paths$DvmAdminFrontendFr.rView
          ) + Route_Paths$DvmAdminFrontendFr.rBonuses,
          location: $$location
        });
  };
  var getPCDAgreementsItem = function ($$location) {
    arr.push({
          uuid: $$location ? "bcd1911d-77e7-41c8-a539-d27be79ea75a" : "7688c097-f837-4a9b-903c-fd05cd9c9cc1",
          label: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.pcdAgreement),
          redirect: (
            $$location ? Route_Paths$DvmAdminFrontendFr.rManage : Route_Paths$DvmAdminFrontendFr.rView
          ) + Route_Paths$DvmAdminFrontendFr.rPCDAgreement,
          location: $$location
        });
  };
  var getModelVersionGridItem = function ($$location) {
    arr.push({
          uuid: $$location ? "7ccbcd6c-34aa-4e64-8598-b9c32771bb15" : "c87669f0-fa1f-4dfb-8c23-ce029b48036e",
          label: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.modelVersionGrid),
          redirect: (
            $$location ? Route_Paths$DvmAdminFrontendFr.rManage : Route_Paths$DvmAdminFrontendFr.rView
          ) + Route_Paths$DvmAdminFrontendFr.rModelVersionGrid,
          location: $$location
        });
  };
  var getMarginGridItem = function ($$location) {
    arr.push({
          uuid: $$location ? "f7de7ce5-f33e-412f-867d-52bb8d4ad1f8" : "a4136ba6-891d-4c9a-a63c-160034121427",
          label: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.margingrid),
          redirect: (
            $$location ? Route_Paths$DvmAdminFrontendFr.rManage : Route_Paths$DvmAdminFrontendFr.rView
          ) + Route_Paths$DvmAdminFrontendFr.rMarginGrid,
          location: $$location
        });
  };
  var getQuotaItem = function ($$location) {
    arr.push({
          uuid: $$location ? "e9ebd945-a40c-48be-932a-89edd7e77b10" : "84daefbc-ade1-47ad-9a64-0efb5ddf6972",
          label: intl.formatMessage($$location ? Messages_Quotas$DvmAdminFrontendFr.title : Messages_TopMenu$DvmAdminFrontendFr.quotas),
          redirect: (
            $$location ? Route_Paths$DvmAdminFrontendFr.rManage : Route_Paths$DvmAdminFrontendFr.rView
          ) + Route_Paths$DvmAdminFrontendFr.rQuota,
          location: $$location
        });
  };
  var getFlashItem = function ($$location) {
    arr.push({
          uuid: $$location ? "96c10ed2-3fc0-467e-abb0-2bbabd774fcb" : "95810a0d-6552-4489-b1d6-ac0e17d35f18",
          label: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.flash),
          redirect: (
            $$location ? Route_Paths$DvmAdminFrontendFr.rManage : Route_Paths$DvmAdminFrontendFr.rView
          ) + Route_Paths$DvmAdminFrontendFr.rFlash,
          location: $$location
        });
  };
  var getContractItem = function ($$location) {
    arr.push({
          uuid: $$location ? "46331720-1e35-4a41-abda-0373109b06ef" : "c9235ead-07a6-4cc6-a8dc-bf7f7aa2cc86",
          label: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.contracts),
          redirect: (
            $$location ? Route_Paths$DvmAdminFrontendFr.rManage : Route_Paths$DvmAdminFrontendFr.rView
          ) + Route_Paths$DvmAdminFrontendFr.rContract,
          location: $$location
        });
  };
  var getContractManagersItem = function ($$location) {
    arr.push({
          uuid: $$location ? "cb09fe89-7bda-48db-a804-d0815dd91dfb" : "ecf44e42-c913-43f8-9103-9b9147098705",
          label: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.contractManager),
          redirect: (
            $$location ? Route_Paths$DvmAdminFrontendFr.rManage : Route_Paths$DvmAdminFrontendFr.rView
          ) + Route_Paths$DvmAdminFrontendFr.rContractManager,
          location: $$location
        });
  };
  var getAttachmentItem = function (param) {
    arr.push({
          uuid: "05d7ae21-09dc-428b-baf1-ee7c3b3996ae",
          label: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.attachments),
          redirect: Route_Paths$DvmAdminFrontendFr.rView + Route_Paths$DvmAdminFrontendFr.rAttachment,
          location: /* View */0
        });
  };
  var getCompanyVirtualItem = function ($$location) {
    arr.push({
          uuid: $$location ? "be55bab6-9a1f-4831-ba8d-7c9ca86c0341" : "b30d4fa8-a08a-46fe-a2ae-b5ec5c86b835",
          label: intl.formatMessage($$location ? Messages_TopMenu$DvmAdminFrontendFr.companyVirtualEdit : Messages_TopMenu$DvmAdminFrontendFr.companyVirtualView),
          redirect: (
            $$location ? Route_Paths$DvmAdminFrontendFr.rManage : Route_Paths$DvmAdminFrontendFr.rView
          ) + Route_Paths$DvmAdminFrontendFr.rCompanyVirtual,
          location: $$location
        });
  };
  var getCompanyItem = function ($$location) {
    arr.push({
          uuid: $$location ? "66950058-bb1c-405d-a2c0-2ceab8e4fa56" : "1780f542-3405-42c6-88f1-e7a48f4c5d06",
          label: intl.formatMessage($$location ? Messages_TopMenu$DvmAdminFrontendFr.companyManage : Messages_TopMenu$DvmAdminFrontendFr.companyList),
          redirect: (
            $$location ? Route_Paths$DvmAdminFrontendFr.rManage : Route_Paths$DvmAdminFrontendFr.rView
          ) + Route_Paths$DvmAdminFrontendFr.rCompany,
          location: $$location
        });
  };
  var getCategoryItem = function ($$location) {
    arr.push({
          uuid: $$location ? "cc255551-62d4-4cef-b8b5-e15818e7bca4" : "af89198d-d054-455a-bb4d-c14d8cf47bed",
          label: intl.formatMessage($$location ? Messages_TopMenu$DvmAdminFrontendFr.createCategory : Messages_TopMenu$DvmAdminFrontendFr.categories),
          redirect: (
            $$location ? Route_Paths$DvmAdminFrontendFr.rManage : Route_Paths$DvmAdminFrontendFr.rView
          ) + Route_Paths$DvmAdminFrontendFr.rCategory,
          location: $$location
        });
  };
  var getModelVersionItem = function (param) {
    arr.push({
          uuid: "88d412be-182e-414c-9a2d-3ca19edd14d8",
          label: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.modelVersionList),
          redirect: Route_Paths$DvmAdminFrontendFr.rView + Route_Paths$DvmAdminFrontendFr.rModelVersionList,
          location: /* View */0
        });
  };
  var getDealerItem = function (param) {
    arr.push({
          uuid: "2d3f1596-db34-4560-a60a-eca7f2b66353",
          label: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.dealerList),
          redirect: Route_Paths$DvmAdminFrontendFr.rView + Route_Paths$DvmAdminFrontendFr.rDealer,
          location: /* View */0
        });
  };
  var getCreateQuotaItem = function (param) {
    arr.push({
          uuid: "fea54154-27e1-4190-9c05-16badf2c8d8d",
          label: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.createTitle),
          redirect: Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rQuotaDetailCreate,
          location: /* Manage */1
        });
  };
  var getQuotaExclusionsItem = function (param) {
    arr.push({
          uuid: "9724b5b4-6b3e-47a3-adeb-4dcc6b4da980",
          label: intl.formatMessage(Messages_Quotas_Detail$DvmAdminFrontendFr.exclusionTitle),
          redirect: Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rQuotaExclusions,
          location: /* Manage */1
        });
  };
  var getMarginMinimumItem = function (param) {
    arr.push({
          uuid: "46548669-f746-4e9d-a5a7-396aeeae25b4",
          label: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.marginMinimum),
          redirect: Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rMarginMinimum,
          location: /* Manage */1
        });
  };
  var getUploadItem = function (param) {
    arr.push({
          uuid: "f124fa70-e5ae-4754-8c37-e8c893b8cea8",
          label: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.upload),
          redirect: Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rUpload,
          location: /* Manage */1
        });
  };
  var getContractErrorsItem = function (param) {
    arr.push({
          uuid: "22c8db6e-4a12-41db-952e-79ab17926a3c",
          label: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.contractErrors),
          redirect: Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rContractErrors,
          location: /* Manage */1
        });
  };
  var getGemoItem = function (param) {
    arr.push({
          uuid: "ce4bc18b-016d-4341-9bdd-0eb6a65583dc",
          label: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.gemo),
          redirect: Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rGemo,
          location: /* Manage */1
        });
  };
  var getVisibilityProfilesItem = function (param) {
    arr.push({
          uuid: "29d60503-f115-4022-9b7f-78170b23fe94",
          label: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.visibilityProfiles),
          redirect: Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rVisibilityProfiles,
          location: /* Manage */1
        });
  };
  var getSubmarketItem = function (param) {
    arr.push({
          uuid: "5000abc1-18be-4007-b2c2-c848a8d4a000",
          label: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.submarkets),
          redirect: Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rSubmarket,
          location: /* Manage */1
        });
  };
  var getBrandsItem = function (param) {
    arr.push({
          uuid: "5ee50f32-654c-4020-bb21-2bc05ff0c026",
          label: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.brandsManagementPage),
          redirect: Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rBrands,
          location: /* Manage */1
        });
  };
  var getEnergiesItem = function (param) {
    arr.push({
          uuid: "4e7716da-4ad7-4e69-989e-b06890504e36",
          label: intl.formatMessage(Messages_Energy$DvmAdminFrontendFr.title),
          redirect: Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rEnergies,
          location: /* Manage */1
        });
  };
  getOrderItem(undefined);
  getBonusesItem(/* View */0);
  getFlashItem(/* View */0);
  if (User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0) {
    getPCDAgreementsItem(/* View */0);
    getModelVersionGridItem(/* View */0);
    getQuotaItem(/* View */0);
    getContractItem(/* View */0);
    getContractManagersItem(/* View */0);
    getAttachmentItem(undefined);
    getCompanyVirtualItem(/* View */0);
    getCompanyItem(/* View */0);
  }
  getCategoryItem(/* View */0);
  getModelVersionItem(undefined);
  getDealerItem(undefined);
  if (User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole)) {
    if (User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0) {
      getMarginGridItem(/* View */0);
      getMarginGridItem(/* Manage */1);
      getContractItem(/* Manage */1);
      getContractManagersItem(/* Manage */1);
      getModelVersionGridItem(/* Manage */1);
      getCompanyVirtualItem(/* Manage */1);
      getQuotaItem(/* Manage */1);
      getCreateQuotaItem(undefined);
      getQuotaExclusionsItem(undefined);
      getMarginMinimumItem(undefined);
      getContractErrorsItem(undefined);
      getPCDAgreementsItem(/* Manage */1);
      getUploadItem(undefined);
      getCategoryItem(/* Manage */1);
      getBonusesItem(/* Manage */1);
      getCompanyItem(/* Manage */1);
    }
    getSubmarketItem(undefined);
    getFlashItem(/* Manage */1);
    getVisibilityProfilesItem(undefined);
    getGemoItem(undefined);
    getBrandsItem(undefined);
    getEnergiesItem(undefined);
  }
  if (selectedRole === /* AcountManager */4) {
    getModelVersionGridItem(/* Manage */1);
  }
  if (selectedRole === /* CompanyManager */2 || selectedRole === /* LldManager */5 || selectedRole === /* BigAccountManager */6) {
    getBonusesItem(/* Manage */1);
  }
  return arr;
}

export {
  options ,
}
/* Route_Paths-DvmAdminFrontendFr Not a pure module */
