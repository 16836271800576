// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Container$Mui from "rescript-material-ui/src/Container.bs.js";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as App_Styles$DvmAdminFrontendFr from "../../../common/App_Styles.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../../route/Route_Paths.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as Messages_Error$DvmAdminFrontendFr from "../../../intl/messages/Messages_Error.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Contract_Edit_Api$DvmAdminFrontendFr from "./Contract_Edit_Api.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as Contract_Search_Api$DvmAdminFrontendFr from "../search/Contract_Search_Api.bs.js";
import * as Contract_Edit_Main_Info$DvmAdminFrontendFr from "./mainInfo/Contract_Edit_Main_Info.bs.js";
import * as Messages_Contract_Create$DvmAdminFrontendFr from "../../../intl/messages/contract/Messages_Contract_Create.bs.js";
import * as Contract_Attachments_Table$DvmAdminFrontendFr from "./attachment/Contract_Attachments_Table.bs.js";

function Contract_Edit_Page(Props) {
  var contractIdOpt = Props.contractId;
  var startDateOpt = Props.startDate;
  var operation = Props.operation;
  var contractId = contractIdOpt !== undefined ? Caml_option.valFromOption(contractIdOpt) : undefined;
  var startDate = startDateOpt !== undefined ? Caml_option.valFromOption(startDateOpt) : undefined;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setFetchedContract = match[1];
  var fetchedContract = match[0];
  var match$1 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setFetchedManagerIds = match$1[1];
  var fetchedManagerIds = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setContract = match$2[1];
  var contract = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setTableExpanded = match$3[1];
  var alert = ReactAlert.useAlert();
  var match$4 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$5 = match$4[0];
  var userManager = match$5.userManager;
  var selectedRole = match$5.selectedRole;
  var match$6 = React.useState(function () {
        return /* NotStarted */0;
      });
  var saveResult = match$6[0];
  var fetchContract = function (contractId, newStart) {
    Belt_Option.mapWithDefault(startDate, undefined, (function (start) {
            Contract_Edit_Api$DvmAdminFrontendFr.fetchContract(userManager, setFetchedContract, Belt_Option.getWithDefault(newStart, start), selectedRole, contractId);
          }));
  };
  React.useEffect((function () {
          Contract_Search_Api$DvmAdminFrontendFr.fetchManagerIds(userManager, setFetchedManagerIds, selectedRole);
          Belt_Option.forEach(contractId, (function (x) {
                  fetchContract(x, undefined);
                }));
        }), []);
  var reload = function (newStart) {
    if (App_Types_Result$DvmAdminFrontendFr.isPending(saveResult)) {
      return ;
    } else {
      return Belt_Option.forEach(contractId, (function (x) {
                    fetchContract(x, newStart);
                  }));
    }
  };
  React.useEffect((function () {
          if (typeof fetchedContract === "number") {
            fetchedContract === /* NotStarted */0;
          } else if (fetchedContract.TAG === /* Ok */0) {
            var contract = Belt_List.head(fetchedContract._0.content);
            if (contract !== undefined) {
              Curry._1(setContract, (function (param) {
                      return contract;
                    }));
            } else {
              Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                        message: {
                          TAG: /* Message */0,
                          _0: Messages_Error$DvmAdminFrontendFr.errorOccured
                        }
                      }));
            }
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: fetchedContract._0
                      }
                    }));
          }
        }), [fetchedContract]);
  React.useEffect((function () {
          if (typeof fetchedManagerIds !== "number" && fetchedManagerIds.TAG === /* Error */1) {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: fetchedManagerIds._0
                      }
                    }));
          }
          
        }), [fetchedManagerIds]);
  var tmp = {
    saveResult: saveResult,
    setSaveResult: match$6[1],
    reload: reload,
    operation: operation
  };
  if (contract !== undefined) {
    tmp.contract = Caml_option.valFromOption(contract);
  }
  var tmp$1 = App_Types_Result$DvmAdminFrontendFr.toOption(fetchedManagerIds);
  if (tmp$1 !== undefined) {
    tmp.managerIds = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Container, {
                  children: null,
                  maxWidth: Container$Mui.MaxWidth.md
                }, operation === /* Manage */1 ? React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        color: "primary",
                        gutterBottom: true,
                        children: intl.formatMessage(Belt_Option.mapWithDefault(contractId, Messages_Contract_Create$DvmAdminFrontendFr.titleCreate, (function (param) {
                                    return Messages_Contract_Create$DvmAdminFrontendFr.titleModify;
                                  }))),
                        variant: "h3"
                      }) : null, React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                      show: App_Types_Result$DvmAdminFrontendFr.isPending(fetchedContract) || App_Types_Result$DvmAdminFrontendFr.isPending(fetchedManagerIds),
                      children: React.createElement(Core.Grid, {
                            children: null,
                            container: true,
                            direction: "column",
                            spacing: 2
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(Core.Paper, {
                                      children: null,
                                      square: true,
                                      style: {
                                        backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight1,
                                        padding: "40px",
                                        width: "100%"
                                      }
                                    }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                          color: "primary",
                                          gutterBottom: true,
                                          children: intl.formatMessage(Messages_Contract_Create$DvmAdminFrontendFr.contractInformation),
                                          variant: "h3"
                                        }), React.createElement(Contract_Edit_Main_Info$DvmAdminFrontendFr.make, tmp)),
                                container: true,
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(Core.Accordion, {
                                      children: null,
                                      disabled: Belt_Option.isNone(contract),
                                      expanded: match$3[0],
                                      onChange: (function (param, expanded) {
                                          Curry._1(setTableExpanded, (function (param) {
                                                  return expanded;
                                                }));
                                        }),
                                      square: true,
                                      style: {
                                        backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight1,
                                        padding: "20px"
                                      }
                                    }, React.createElement(Core.AccordionSummary, {
                                          children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                color: "primary",
                                                children: intl.formatMessage(operation === /* Manage */1 ? Messages_Contract_Create$DvmAdminFrontendFr.editAttachments : Messages_Contract_Create$DvmAdminFrontendFr.showAttachments),
                                                variant: "h3"
                                              }),
                                          expandIcon: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                size: /* Large */3,
                                                type_: /* PadDown */11
                                              })
                                        }), React.createElement(Core.AccordionDetails, {
                                          children: contract !== undefined && !App_Types_Result$DvmAdminFrontendFr.isPending(fetchedContract) ? React.createElement(Contract_Attachments_Table$DvmAdminFrontendFr.make, {
                                                  contract: {
                                                    contractID: contract.contractID,
                                                    contractSIREN: contract.siren,
                                                    contractLabel: Belt_Option.getWithDefault(contract.contractLabel, ""),
                                                    startDate: contract.startDate,
                                                    endDate: contract.endDate,
                                                    budget: Belt_Option.getWithDefault(contract.budget, "")
                                                  },
                                                  className: App_Styles$DvmAdminFrontendFr.marginTopSm,
                                                  fetchedContract: fetchedContract,
                                                  operation: operation
                                                }) : null
                                        })),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(Core.Grid, {
                                      children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                            onClick: (function (param) {
                                                RescriptReactRouter.push(operation ? Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rContract : Route_Paths$DvmAdminFrontendFr.rView + Route_Paths$DvmAdminFrontendFr.rContract);
                                              }),
                                            label: intl.formatMessage(Messages_Contract_Create$DvmAdminFrontendFr.backToList),
                                            size: "medium"
                                          }),
                                      item: true
                                    }),
                                container: true,
                                item: true,
                                justifyContent: "flex-end"
                              }))
                    })));
}

var Api;

var ApiSearch;

var Types;

var make = Contract_Edit_Page;

export {
  Api ,
  ApiSearch ,
  Types ,
  make ,
}
/* react Not a pure module */
