// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as Order_Detail_Types$DvmAdminFrontendFr from "./Order_Detail_Types.bs.js";
import * as Order_Detail_Types_CL$DvmAdminFrontendFr from "./Order_Detail_Types_CL.bs.js";
import * as Order_Detail_Types_SO$DvmAdminFrontendFr from "./Order_Detail_Types_SO.bs.js";
import * as Order_Detail_Types_Order_Type$DvmAdminFrontendFr from "./Order_Detail_Types_Order_Type.bs.js";

function getOrder(userManager, setOrder, orderId, orderType, selectedRole) {
  Curry._1(setOrder, (function (param) {
          return /* Pending */1;
        }));
  if (orderType !== 0) {
    if (orderType >= 7) {
      return ;
    } else {
      RestApi$DvmAdminFrontendFr.call("GET", Order_Detail_Types_CL$DvmAdminFrontendFr.order_decode, undefined, userManager, selectedRole, "/orders/" + Order_Detail_Types_Order_Type$DvmAdminFrontendFr.toString(orderType) + "/" + orderId + "").then(function (result) {
            if (result.TAG === /* OkResult */0) {
              var order = result._0;
              return Curry._1(setOrder, (function (param) {
                            return {
                                    TAG: /* Ok */0,
                                    _0: {
                                      TAG: /* NonFleet */1,
                                      _0: order
                                    }
                                  };
                          }));
            }
            var error = result._0;
            Curry._1(setOrder, (function (param) {
                    return {
                            TAG: /* Error */1,
                            _0: error
                          };
                  }));
          });
      return ;
    }
  } else {
    RestApi$DvmAdminFrontendFr.call("GET", Order_Detail_Types_SO$DvmAdminFrontendFr.order_decode, undefined, userManager, selectedRole, "/orders/SO/" + orderId + "").then(function (result) {
          if (result.TAG === /* OkResult */0) {
            var order = result._0;
            return Curry._1(setOrder, (function (param) {
                          return {
                                  TAG: /* Ok */0,
                                  _0: {
                                    TAG: /* Fleet */0,
                                    _0: order
                                  }
                                };
                        }));
          }
          var error = result._0;
          Curry._1(setOrder, (function (param) {
                  return {
                          TAG: /* Error */1,
                          _0: error
                        };
                }));
        });
    return ;
  }
}

function getOrderType(userManager, setOrderTypeResult, orderId, selectedRole) {
  Curry._1(setOrderTypeResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Order_Detail_Types$DvmAdminFrontendFr.orderTypeResult_decode, undefined, userManager, selectedRole, "/orders/type?orderId=" + orderId + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var orderType = result._0;
          return Curry._1(setOrderTypeResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: orderType
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setOrderTypeResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

export {
  getOrder ,
  getOrderType ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
