// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../common/utils/Utils_Form.bs.js";

var validators_endDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequiredDateCompare(param.startDate, param.endDate, "larger", true, undefined, undefined);
    })
};

var validators_startDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequiredDateCompare(param.startDate, param.endDate, "smaller", true, undefined, undefined);
    })
};

var validators_volume = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var value = Utils_Form$DvmAdminFrontendFr.validateInteger(param.volume);
      if (value.TAG === /* Ok */0) {
        return Utils_Form$DvmAdminFrontendFr.validateIntegerRequired(value._0);
      } else {
        return {
                TAG: /* Error */1,
                _0: value._0
              };
      }
    })
};

var validators_engineType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.engineType
            };
    })
};

var validators_phase = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.phase
            };
    })
};

var validators_modelId = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.modelId
            };
    })
};

var validators_dealerId = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.dealerId);
    })
};

var validators_orderType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateRequired(param.orderType);
    })
};

var validators = {
  endDate: validators_endDate,
  startDate: validators_startDate,
  volume: validators_volume,
  engineType: validators_engineType,
  phase: validators_phase,
  modelId: validators_modelId,
  dealerId: validators_dealerId,
  orderType: validators_orderType
};

function initialFieldsStatuses(_input) {
  return {
          endDate: /* Pristine */0,
          startDate: /* Pristine */0,
          volume: /* Pristine */0,
          engineType: /* Pristine */0,
          phase: /* Pristine */0,
          modelId: /* Pristine */0,
          dealerId: /* Pristine */0,
          orderType: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.endDate;
  var match_0 = match ? match._0 : Curry._1(validators.endDate.validate, input);
  var match$1 = fieldsStatuses.startDate;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.startDate.validate, input);
  var match$2 = fieldsStatuses.volume;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.volume.validate, input);
  var match$3 = fieldsStatuses.engineType;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.engineType.validate, input);
  var match$4 = fieldsStatuses.phase;
  var match_0$4 = match$4 ? match$4._0 : Curry._1(validators.phase.validate, input);
  var match$5 = fieldsStatuses.modelId;
  var match_0$5 = match$5 ? match$5._0 : Curry._1(validators.modelId.validate, input);
  var match$6 = fieldsStatuses.dealerId;
  var match_0$6 = match$6 ? match$6._0 : Curry._1(validators.dealerId.validate, input);
  var match$7 = fieldsStatuses.orderType;
  var match_0$7 = match$7 ? match$7._0 : Curry._1(validators.orderType.validate, input);
  var endDateResult = match_0;
  var endDateResult$1;
  if (endDateResult.TAG === /* Ok */0) {
    var startDateResult = match_0$1;
    if (startDateResult.TAG === /* Ok */0) {
      var volumeResult = match_0$2;
      if (volumeResult.TAG === /* Ok */0) {
        var engineTypeResult = match_0$3;
        if (engineTypeResult.TAG === /* Ok */0) {
          var phaseResult = match_0$4;
          if (phaseResult.TAG === /* Ok */0) {
            var modelIdResult = match_0$5;
            if (modelIdResult.TAG === /* Ok */0) {
              var dealerIdResult = match_0$6;
              if (dealerIdResult.TAG === /* Ok */0) {
                var orderTypeResult = match_0$7;
                if (orderTypeResult.TAG === /* Ok */0) {
                  return {
                          TAG: /* Valid */0,
                          output: {
                            orderType: orderTypeResult._0,
                            dealerId: dealerIdResult._0,
                            modelId: modelIdResult._0,
                            phase: phaseResult._0,
                            engineType: engineTypeResult._0,
                            volume: volumeResult._0,
                            startDate: startDateResult._0,
                            endDate: endDateResult._0
                          },
                          fieldsStatuses: {
                            endDate: /* Dirty */{
                              _0: endDateResult,
                              _1: /* Shown */0
                            },
                            startDate: /* Dirty */{
                              _0: startDateResult,
                              _1: /* Shown */0
                            },
                            volume: /* Dirty */{
                              _0: volumeResult,
                              _1: /* Shown */0
                            },
                            engineType: /* Dirty */{
                              _0: engineTypeResult,
                              _1: /* Shown */0
                            },
                            phase: /* Dirty */{
                              _0: phaseResult,
                              _1: /* Shown */0
                            },
                            modelId: /* Dirty */{
                              _0: modelIdResult,
                              _1: /* Shown */0
                            },
                            dealerId: /* Dirty */{
                              _0: dealerIdResult,
                              _1: /* Shown */0
                            },
                            orderType: /* Dirty */{
                              _0: orderTypeResult,
                              _1: /* Shown */0
                            }
                          },
                          collectionsStatuses: undefined
                        };
                }
                endDateResult$1 = endDateResult;
              } else {
                endDateResult$1 = endDateResult;
              }
            } else {
              endDateResult$1 = endDateResult;
            }
          } else {
            endDateResult$1 = endDateResult;
          }
        } else {
          endDateResult$1 = endDateResult;
        }
      } else {
        endDateResult$1 = endDateResult;
      }
    } else {
      endDateResult$1 = endDateResult;
    }
  } else {
    endDateResult$1 = endDateResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            endDate: /* Dirty */{
              _0: endDateResult$1,
              _1: /* Shown */0
            },
            startDate: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            volume: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            engineType: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            phase: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            modelId: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            dealerId: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            orderType: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurEndDateField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.endDate, validators_endDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: status,
                                  startDate: init.startDate,
                                  volume: init.volume,
                                  engineType: init.engineType,
                                  phase: init.phase,
                                  modelId: init.modelId,
                                  dealerId: init.dealerId,
                                  orderType: init.orderType
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurStartDateField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.startDate, validators_startDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: init.endDate,
                                  startDate: status,
                                  volume: init.volume,
                                  engineType: init.engineType,
                                  phase: init.phase,
                                  modelId: init.modelId,
                                  dealerId: init.dealerId,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVolumeField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.volume, validators_volume, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  volume: status,
                                  engineType: init.engineType,
                                  phase: init.phase,
                                  modelId: init.modelId,
                                  dealerId: init.dealerId,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEngineTypeField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.engineType, validators_engineType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  volume: init.volume,
                                  engineType: status,
                                  phase: init.phase,
                                  modelId: init.modelId,
                                  dealerId: init.dealerId,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPhaseField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.phase, validators_phase, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  volume: init.volume,
                                  engineType: init.engineType,
                                  phase: status,
                                  modelId: init.modelId,
                                  dealerId: init.dealerId,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurModelIdField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.modelId, validators_modelId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  volume: init.volume,
                                  engineType: init.engineType,
                                  phase: init.phase,
                                  modelId: status,
                                  dealerId: init.dealerId,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDealerIdField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.dealerId, validators_dealerId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  volume: init.volume,
                                  engineType: init.engineType,
                                  phase: init.phase,
                                  modelId: init.modelId,
                                  dealerId: status,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurOrderTypeField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.orderType, validators_orderType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  volume: init.volume,
                                  engineType: init.engineType,
                                  phase: init.phase,
                                  modelId: init.modelId,
                                  dealerId: init.dealerId,
                                  orderType: status
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */8 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */8,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */9,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */11);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */10);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */9 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */10 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */11 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateEndDateField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$8 = Formality.validateDependentFieldOnChange(nextInput, nextFieldsStatuses.contents.startDate, validators_startDate, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  endDate: init.endDate,
                                  startDate: status,
                                  volume: init.volume,
                                  engineType: init.engineType,
                                  phase: init.phase,
                                  modelId: init.modelId,
                                  dealerId: init.dealerId,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$8 !== undefined) {
                    nextFieldsStatuses.contents = result$8;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, nextFieldsStatuses.contents.endDate, state.submissionStatus, validators_endDate, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            endDate: status,
                                            startDate: init.startDate,
                                            volume: init.volume,
                                            engineType: init.engineType,
                                            phase: init.phase,
                                            modelId: init.modelId,
                                            dealerId: init.dealerId,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateStartDateField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$9 = Formality.validateDependentFieldOnChange(nextInput$1, nextFieldsStatuses$1.contents.endDate, validators_endDate, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  endDate: status,
                                  startDate: init.startDate,
                                  volume: init.volume,
                                  engineType: init.engineType,
                                  phase: init.phase,
                                  modelId: init.modelId,
                                  dealerId: init.dealerId,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$9 !== undefined) {
                    nextFieldsStatuses$1.contents = result$9;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, nextFieldsStatuses$1.contents.startDate, state.submissionStatus, validators_startDate, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            endDate: init.endDate,
                                            startDate: status,
                                            volume: init.volume,
                                            engineType: init.engineType,
                                            phase: init.phase,
                                            modelId: init.modelId,
                                            dealerId: init.dealerId,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVolumeField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.volume, state.submissionStatus, validators_volume, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            volume: status,
                                            engineType: init.engineType,
                                            phase: init.phase,
                                            modelId: init.modelId,
                                            dealerId: init.dealerId,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEngineTypeField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.engineType, state.submissionStatus, validators_engineType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            volume: init.volume,
                                            engineType: status,
                                            phase: init.phase,
                                            modelId: init.modelId,
                                            dealerId: init.dealerId,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePhaseField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.phase, state.submissionStatus, validators_phase, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            volume: init.volume,
                                            engineType: init.engineType,
                                            phase: status,
                                            modelId: init.modelId,
                                            dealerId: init.dealerId,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateModelIdField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.modelId, state.submissionStatus, validators_modelId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            volume: init.volume,
                                            engineType: init.engineType,
                                            phase: init.phase,
                                            modelId: status,
                                            dealerId: init.dealerId,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDealerIdField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.dealerId, state.submissionStatus, validators_dealerId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            volume: init.volume,
                                            engineType: init.engineType,
                                            phase: init.phase,
                                            modelId: init.modelId,
                                            dealerId: status,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateOrderTypeField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$7, state.fieldsStatuses.orderType, state.submissionStatus, validators_orderType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            volume: init.volume,
                                            engineType: init.engineType,
                                            phase: init.phase,
                                            modelId: init.modelId,
                                            dealerId: init.dealerId,
                                            orderType: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */8 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */9 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */10 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateEndDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEndDateField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateStartDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateStartDateField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVolume: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVolumeField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEngineType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEngineTypeField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updatePhase: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePhaseField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateModelId: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelIdField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDealerId: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDealerIdField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateOrderType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrderTypeField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurEndDate: (function (param) {
              Curry._1(dispatch, /* BlurEndDateField */0);
            }),
          blurStartDate: (function (param) {
              Curry._1(dispatch, /* BlurStartDateField */1);
            }),
          blurVolume: (function (param) {
              Curry._1(dispatch, /* BlurVolumeField */2);
            }),
          blurEngineType: (function (param) {
              Curry._1(dispatch, /* BlurEngineTypeField */3);
            }),
          blurPhase: (function (param) {
              Curry._1(dispatch, /* BlurPhaseField */4);
            }),
          blurModelId: (function (param) {
              Curry._1(dispatch, /* BlurModelIdField */5);
            }),
          blurDealerId: (function (param) {
              Curry._1(dispatch, /* BlurDealerIdField */6);
            }),
          blurOrderType: (function (param) {
              Curry._1(dispatch, /* BlurOrderTypeField */7);
            }),
          endDateResult: Formality.exposeFieldResult(state.fieldsStatuses.endDate),
          startDateResult: Formality.exposeFieldResult(state.fieldsStatuses.startDate),
          volumeResult: Formality.exposeFieldResult(state.fieldsStatuses.volume),
          engineTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.engineType),
          phaseResult: Formality.exposeFieldResult(state.fieldsStatuses.phase),
          modelIdResult: Formality.exposeFieldResult(state.fieldsStatuses.modelId),
          dealerIdResult: Formality.exposeFieldResult(state.fieldsStatuses.dealerId),
          orderTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.orderType),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.endDate || match.startDate || match.volume || match.engineType || match.phase || match.modelId || match.dealerId || match.orderType) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */8);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */9);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */10);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */10,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */11);
            })
        };
}

var QuotaPARCEditForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialInput = {
  orderType: "",
  dealerId: "",
  modelId: "",
  phase: "",
  engineType: "",
  volume: "",
  startDate: undefined,
  endDate: undefined
};

export {
  QuotaPARCEditForm ,
  initialInput ,
}
/* react Not a pure module */
