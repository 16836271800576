// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as App_Table$DvmAdminFrontendFr from "../../../common/components/table/App_Table.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../intl/Intl_Utils.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Pagination.bs.js";
import * as App_Table_Export_Menu$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Export_Menu.bs.js";
import * as Company_Virtual_Modal$DvmAdminFrontendFr from "./modal/Company_Virtual_Modal.bs.js";
import * as Messages_Company_Manage$DvmAdminFrontendFr from "../../../intl/messages/company/Messages_Company_Manage.bs.js";
import * as Messages_Company_Virtual$DvmAdminFrontendFr from "../../../intl/messages/company/Messages_Company_Virtual.bs.js";
import * as Company_Virtual_Modal_Delete$DvmAdminFrontendFr from "./modal/Company_Virtual_Modal_Delete.bs.js";
import * as Company_Virtual_Search_Types$DvmAdminFrontendFr from "./Company_Virtual_Search_Types.bs.js";

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Company_Virtual_Search_Types$DvmAdminFrontendFr.Fields.siret, /* Desc */1, undefined, undefined, undefined, undefined, Messages_Company_Virtual$DvmAdminFrontendFr.virtualSiren);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Company_Virtual_Search_Types$DvmAdminFrontendFr.Fields.name, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.companyName),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Company_Virtual_Search_Types$DvmAdminFrontendFr.Fields.street, undefined, undefined, undefined, undefined, undefined, Messages_Company_Manage$DvmAdminFrontendFr.street),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Company_Virtual_Search_Types$DvmAdminFrontendFr.Fields.zipCode, undefined, undefined, undefined, undefined, undefined, Messages_Company_Manage$DvmAdminFrontendFr.zipCode),
      tl: {
        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Company_Virtual_Search_Types$DvmAdminFrontendFr.Fields.city, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.city),
        tl: {
          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Company_Virtual_Search_Types$DvmAdminFrontendFr.Fields.countryCode, undefined, undefined, undefined, undefined, undefined, Messages_Company_Manage$DvmAdminFrontendFr.countryCode),
          tl: {
            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Company_Virtual_Search_Types$DvmAdminFrontendFr.Fields.endDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.endDate),
            tl: /* [] */0
          }
        }
      }
    }
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function Company_Virtual_Search_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var refresh = Props.refresh;
  var edit = Props.edit;
  var createCompanyElement = Props.createCompanyElement;
  var match = React.useState(function () {
        return null;
      });
  var setModal = match[1];
  var intl = ReactIntl.useIntl();
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (row) {
                                  var street = Belt_Option.getWithDefault(row.streetNbr, "") + " " + Belt_Option.getWithDefault(row.street, "");
                                  var adressDetail = Belt_Option.getWithDefault(row.additionalAddressInfo, "");
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow((function (param) {
                                                RescriptReactRouter.push("/view/company/" + row.siret);
                                              }), edit ? Caml_option.some(React.createElement(Core.Grid, {
                                                        children: null,
                                                        container: true,
                                                        item: true,
                                                        spacing: 1,
                                                        wrap: "nowrap"
                                                      }, React.createElement(Core.Grid, {
                                                            children: React.createElement(Core.Button, {
                                                                  onClick: (function (param) {
                                                                      Curry._1(setModal, (function (param) {
                                                                              return React.createElement(Company_Virtual_Modal$DvmAdminFrontendFr.make, {
                                                                                          operation: /* Edit */1,
                                                                                          onClose: (function (param) {
                                                                                              Curry._1(setModal, (function (param) {
                                                                                                      return null;
                                                                                                    }));
                                                                                            }),
                                                                                          row: row,
                                                                                          refresh: refresh
                                                                                        });
                                                                            }));
                                                                    }),
                                                                  children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                        size: /* Large */3,
                                                                        type_: /* Edit */60
                                                                      }),
                                                                  key: "edit"
                                                                }),
                                                            item: true
                                                          }), React.createElement(Core.Grid, {
                                                            children: React.createElement(Core.Button, {
                                                                  onClick: (function (param) {
                                                                      Curry._1(setModal, (function (param) {
                                                                              return React.createElement(Company_Virtual_Modal_Delete$DvmAdminFrontendFr.make, {
                                                                                          onClose: (function (param) {
                                                                                              Curry._1(setModal, (function (param) {
                                                                                                      return null;
                                                                                                    }));
                                                                                            }),
                                                                                          siret: row.siret,
                                                                                          refresh: refresh
                                                                                        });
                                                                            }));
                                                                    }),
                                                                  children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                        size: /* Large */3,
                                                                        type_: /* Trash */38,
                                                                        color: "red"
                                                                      }),
                                                                  key: "trash"
                                                                }),
                                                            item: true
                                                          }))) : undefined, undefined, Belt_List.mapWithIndex({
                                                  hd: row.siret,
                                                  tl: {
                                                    hd: Belt_Option.getWithDefault(row.name, ""),
                                                    tl: {
                                                      hd: Belt_Array.joinWith(Belt_Array.keep([
                                                                street,
                                                                adressDetail
                                                              ], (function (x) {
                                                                  return x.trim().length > 0;
                                                                })), " , ", (function (x) {
                                                              return x;
                                                            })),
                                                      tl: {
                                                        hd: Belt_Option.getWithDefault(row.zipCode, ""),
                                                        tl: {
                                                          hd: Belt_Option.getWithDefault(row.city, ""),
                                                          tl: {
                                                            hd: Belt_Option.getWithDefault(row.countryCode, ""),
                                                            tl: {
                                                              hd: Belt_Option.mapWithDefault(row.endDate, "", (function (param) {
                                                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                    })),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._2(fetchData, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._2(fetchData, undefined, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var tmp;
  tmp = typeof tablePage === "number" || tablePage.TAG !== /* Ok */0 ? true : tablePage._0.total > 30000;
  var tmp$1 = {
    headers: fetchRequest.headers,
    handleSorting: handleSorting,
    tablePage: convertTable(tablePage),
    paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined),
    exportMenu: React.createElement(Core.Grid, {
          children: null,
          container: true,
          item: true,
          justifyContent: "flex-end",
          spacing: 1
        }, edit ? React.createElement(Core.Grid, {
                children: createCompanyElement,
                item: true
              }) : null, React.createElement(Core.Grid, {
              children: React.createElement(Core.Tooltip, {
                    arrow: true,
                    children: React.createElement("div", undefined, React.createElement(App_Table_Export_Menu$DvmAdminFrontendFr.make, {
                              query: Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                                    page: 0,
                                    rowsPerPage: 30000,
                                    headers: fetchRequest.headers,
                                    filterQueryParams: fetchRequest.filterQueryParams,
                                    additionalQueryParams: fetchRequest.additionalQueryParams
                                  }),
                              endpoint: "sirets/virtual",
                              disabled: tmp
                            })),
                    placement: "top",
                    title: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.maxLinesExport, {
                          count: "30 000"
                        })
                  }),
              item: true
            }))
  };
  var tmp$2 = edit ? ({
        TAG: /* Left */0,
        _0: /* EmptyCell */0
      }) : undefined;
  if (tmp$2 !== undefined) {
    tmp$1.rowButton = Caml_option.valFromOption(tmp$2);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, tmp$1), match[0]);
}

var Api;

var Modal;

var DeleteModal;

var make = Company_Virtual_Search_Table;

export {
  Api ,
  Modal ,
  DeleteModal ,
  headersConfiguration ,
  make ,
}
/* headersConfiguration Not a pure module */
