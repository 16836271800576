// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../../../common/utils/Utils_Form.bs.js";
import * as Flash_Edit_Version_Perf_Utils$DvmAdminFrontendFr from "./Flash_Edit_Version_Perf_Utils.bs.js";

var validators_maxBonus = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return Utils_Form$DvmAdminFrontendFr.validateFloatPositiveRequired(param.maxBonus);
    })
};

var validators_endDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return Flash_Edit_Version_Perf_Utils$DvmAdminFrontendFr.validateRequiredDateCompare(param.startDate, param.endDate, param$1.flashStartDate, param$1.flashEndDate, param$1.previousEndDate, param$1.followingStartDate, "larger", param.startDateAsFlash, param.endDateAsFlash);
    })
};

var validators_startDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return Flash_Edit_Version_Perf_Utils$DvmAdminFrontendFr.validateRequiredDateCompare(param.startDate, param.endDate, param$1.flashStartDate, param$1.flashEndDate, param$1.previousEndDate, param$1.followingStartDate, "smaller", param.startDateAsFlash, param.endDateAsFlash);
    })
};

var validators = {
  maxBonus: validators_maxBonus,
  endDateAsFlash: undefined,
  endDate: validators_endDate,
  startDateAsFlash: undefined,
  startDate: validators_startDate
};

function initialFieldsStatuses(_input) {
  return {
          maxBonus: /* Pristine */0,
          endDateAsFlash: /* Pristine */0,
          endDate: /* Pristine */0,
          startDateAsFlash: /* Pristine */0,
          startDate: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match = fieldsStatuses.maxBonus;
  var match_0 = match ? match._0 : Curry._2(validators.maxBonus.validate, input, metadata);
  var match_0$1 = {
    TAG: /* Ok */0,
    _0: input.endDateAsFlash
  };
  var match$1 = fieldsStatuses.endDate;
  var match_0$2 = match$1 ? match$1._0 : Curry._2(validators.endDate.validate, input, metadata);
  var match_0$3 = {
    TAG: /* Ok */0,
    _0: input.startDateAsFlash
  };
  var match$2 = fieldsStatuses.startDate;
  var match_0$4 = match$2 ? match$2._0 : Curry._2(validators.startDate.validate, input, metadata);
  var maxBonusResult = match_0;
  var maxBonusResult$1;
  if (maxBonusResult.TAG === /* Ok */0) {
    var endDateAsFlashResult = match_0$1;
    if (endDateAsFlashResult.TAG === /* Ok */0) {
      var endDateResult = match_0$2;
      if (endDateResult.TAG === /* Ok */0) {
        var startDateAsFlashResult = match_0$3;
        if (startDateAsFlashResult.TAG === /* Ok */0) {
          var startDateResult = match_0$4;
          if (startDateResult.TAG === /* Ok */0) {
            return {
                    TAG: /* Valid */0,
                    output: {
                      startDate: startDateResult._0,
                      startDateAsFlash: startDateAsFlashResult._0,
                      endDate: endDateResult._0,
                      endDateAsFlash: endDateAsFlashResult._0,
                      maxBonus: maxBonusResult._0
                    },
                    fieldsStatuses: {
                      maxBonus: /* Dirty */{
                        _0: maxBonusResult,
                        _1: /* Shown */0
                      },
                      endDateAsFlash: /* Dirty */{
                        _0: endDateAsFlashResult,
                        _1: /* Hidden */1
                      },
                      endDate: /* Dirty */{
                        _0: endDateResult,
                        _1: /* Shown */0
                      },
                      startDateAsFlash: /* Dirty */{
                        _0: startDateAsFlashResult,
                        _1: /* Hidden */1
                      },
                      startDate: /* Dirty */{
                        _0: startDateResult,
                        _1: /* Shown */0
                      }
                    },
                    collectionsStatuses: undefined
                  };
          }
          maxBonusResult$1 = maxBonusResult;
        } else {
          maxBonusResult$1 = maxBonusResult;
        }
      } else {
        maxBonusResult$1 = maxBonusResult;
      }
    } else {
      maxBonusResult$1 = maxBonusResult;
    }
  } else {
    maxBonusResult$1 = maxBonusResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            maxBonus: /* Dirty */{
              _0: maxBonusResult$1,
              _1: /* Shown */0
            },
            endDateAsFlash: /* Dirty */{
              _0: match_0$1,
              _1: /* Hidden */1
            },
            endDate: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            startDateAsFlash: /* Dirty */{
              _0: match_0$3,
              _1: /* Hidden */1
            },
            startDate: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurMaxBonusField */0 :
                  var result = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.maxBonus, validators_maxBonus, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  maxBonus: status,
                                  endDateAsFlash: init.endDateAsFlash,
                                  endDate: init.endDate,
                                  startDateAsFlash: init.startDateAsFlash,
                                  startDate: init.startDate
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEndDateAsFlashField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithoutValidator(state.input.endDateAsFlash, state.fieldsStatuses.endDateAsFlash, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  maxBonus: init.maxBonus,
                                  endDateAsFlash: status,
                                  endDate: init.endDate,
                                  startDateAsFlash: init.startDateAsFlash,
                                  startDate: init.startDate
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEndDateField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.endDate, validators_endDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  maxBonus: init.maxBonus,
                                  endDateAsFlash: init.endDateAsFlash,
                                  endDate: status,
                                  startDateAsFlash: init.startDateAsFlash,
                                  startDate: init.startDate
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurStartDateAsFlashField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithoutValidator(state.input.startDateAsFlash, state.fieldsStatuses.startDateAsFlash, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  maxBonus: init.maxBonus,
                                  endDateAsFlash: init.endDateAsFlash,
                                  endDate: init.endDate,
                                  startDateAsFlash: status,
                                  startDate: init.startDate
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurStartDateField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.startDate, validators_startDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  maxBonus: init.maxBonus,
                                  endDateAsFlash: init.endDateAsFlash,
                                  endDate: init.endDate,
                                  startDateAsFlash: init.startDateAsFlash,
                                  startDate: status
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */5 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */5,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */6,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */8);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */7);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */6 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */7 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */8 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateMaxBonusField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput, metadata, state.fieldsStatuses.maxBonus, state.submissionStatus, validators_maxBonus, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            maxBonus: status,
                                            endDateAsFlash: init.endDateAsFlash,
                                            endDate: init.endDate,
                                            startDateAsFlash: init.startDateAsFlash,
                                            startDate: init.startDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEndDateAsFlashField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$5 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$1, metadata, nextFieldsStatuses.contents.startDate, validators_startDate, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  maxBonus: init.maxBonus,
                                  endDateAsFlash: init.endDateAsFlash,
                                  endDate: init.endDate,
                                  startDateAsFlash: init.startDateAsFlash,
                                  startDate: status
                                };
                        }));
                  if (result$5 !== undefined) {
                    nextFieldsStatuses.contents = result$5;
                  }
                  var result$6 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$1, metadata, nextFieldsStatuses.contents.endDate, validators_endDate, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  maxBonus: init.maxBonus,
                                  endDateAsFlash: init.endDateAsFlash,
                                  endDate: status,
                                  startDateAsFlash: init.startDateAsFlash,
                                  startDate: init.startDate
                                };
                        }));
                  if (result$6 !== undefined) {
                    nextFieldsStatuses.contents = result$6;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$1.endDateAsFlash, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            maxBonus: init.maxBonus,
                                            endDateAsFlash: status,
                                            endDate: init.endDate,
                                            startDateAsFlash: init.startDateAsFlash,
                                            startDate: init.startDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEndDateField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$7 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$2, metadata, nextFieldsStatuses$1.contents.startDate, validators_startDate, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  maxBonus: init.maxBonus,
                                  endDateAsFlash: init.endDateAsFlash,
                                  endDate: init.endDate,
                                  startDateAsFlash: init.startDateAsFlash,
                                  startDate: status
                                };
                        }));
                  if (result$7 !== undefined) {
                    nextFieldsStatuses$1.contents = result$7;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$2, metadata, nextFieldsStatuses$1.contents.endDate, state.submissionStatus, validators_endDate, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            maxBonus: init.maxBonus,
                                            endDateAsFlash: init.endDateAsFlash,
                                            endDate: status,
                                            startDateAsFlash: init.startDateAsFlash,
                                            startDate: init.startDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateStartDateAsFlashField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$2 = {
                    contents: state.fieldsStatuses
                  };
                  var result$8 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$3, metadata, nextFieldsStatuses$2.contents.startDate, validators_startDate, (function (status) {
                          var init = nextFieldsStatuses$2.contents;
                          return {
                                  maxBonus: init.maxBonus,
                                  endDateAsFlash: init.endDateAsFlash,
                                  endDate: init.endDate,
                                  startDateAsFlash: init.startDateAsFlash,
                                  startDate: status
                                };
                        }));
                  if (result$8 !== undefined) {
                    nextFieldsStatuses$2.contents = result$8;
                  }
                  var result$9 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$3, metadata, nextFieldsStatuses$2.contents.endDate, validators_endDate, (function (status) {
                          var init = nextFieldsStatuses$2.contents;
                          return {
                                  maxBonus: init.maxBonus,
                                  endDateAsFlash: init.endDateAsFlash,
                                  endDate: status,
                                  startDateAsFlash: init.startDateAsFlash,
                                  startDate: init.startDate
                                };
                        }));
                  if (result$9 !== undefined) {
                    nextFieldsStatuses$2.contents = result$9;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$3.startDateAsFlash, (function (status) {
                                    var init = nextFieldsStatuses$2.contents;
                                    return {
                                            maxBonus: init.maxBonus,
                                            endDateAsFlash: init.endDateAsFlash,
                                            endDate: init.endDate,
                                            startDateAsFlash: status,
                                            startDate: init.startDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateStartDateField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$3 = {
                    contents: state.fieldsStatuses
                  };
                  var result$10 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$4, metadata, nextFieldsStatuses$3.contents.endDate, validators_endDate, (function (status) {
                          var init = nextFieldsStatuses$3.contents;
                          return {
                                  maxBonus: init.maxBonus,
                                  endDateAsFlash: init.endDateAsFlash,
                                  endDate: status,
                                  startDateAsFlash: init.startDateAsFlash,
                                  startDate: init.startDate
                                };
                        }));
                  if (result$10 !== undefined) {
                    nextFieldsStatuses$3.contents = result$10;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$4, metadata, nextFieldsStatuses$3.contents.startDate, state.submissionStatus, validators_startDate, (function (status) {
                                    var init = nextFieldsStatuses$3.contents;
                                    return {
                                            maxBonus: init.maxBonus,
                                            endDateAsFlash: init.endDateAsFlash,
                                            endDate: init.endDate,
                                            startDateAsFlash: init.startDateAsFlash,
                                            startDate: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */5 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */6 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */7 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateMaxBonus: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateMaxBonusField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEndDateAsFlash: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEndDateAsFlashField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEndDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEndDateField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateStartDateAsFlash: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateStartDateAsFlashField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateStartDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateStartDateField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurMaxBonus: (function (param) {
              Curry._1(dispatch, /* BlurMaxBonusField */0);
            }),
          blurEndDateAsFlash: (function (param) {
              Curry._1(dispatch, /* BlurEndDateAsFlashField */1);
            }),
          blurEndDate: (function (param) {
              Curry._1(dispatch, /* BlurEndDateField */2);
            }),
          blurStartDateAsFlash: (function (param) {
              Curry._1(dispatch, /* BlurStartDateAsFlashField */3);
            }),
          blurStartDate: (function (param) {
              Curry._1(dispatch, /* BlurStartDateField */4);
            }),
          maxBonusResult: Formality.exposeFieldResult(state.fieldsStatuses.maxBonus),
          endDateAsFlashResult: Formality.exposeFieldResult(state.fieldsStatuses.endDateAsFlash),
          endDateResult: Formality.exposeFieldResult(state.fieldsStatuses.endDate),
          startDateAsFlashResult: Formality.exposeFieldResult(state.fieldsStatuses.startDateAsFlash),
          startDateResult: Formality.exposeFieldResult(state.fieldsStatuses.startDate),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.maxBonus || match.endDateAsFlash || match.endDate || match.startDateAsFlash || match.startDate) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */5);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */6);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */7);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */7,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */8);
            })
        };
}

var VersionForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialInput = {
  startDate: undefined,
  startDateAsFlash: false,
  endDate: undefined,
  endDateAsFlash: false,
  maxBonus: ""
};

export {
  VersionForm ,
  initialInput ,
}
/* react Not a pure module */
