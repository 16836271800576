// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as Utils_Form$DvmAdminFrontendFr from "../../utils/Utils_Form.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../App_Checkbox.bs.js";
import * as App_Types_Text$DvmAdminFrontendFr from "../../types/App_Types_Text.bs.js";

var checkbox = Css.css({
      padding: "0px 9px"
    });

function App_CheckBoxFormInput(Props) {
  var label = Props.label;
  var values = Props.values;
  var options = Props.options;
  var validationResult = Props.validationResult;
  var onChange = Props.onChange;
  var className = Props.className;
  var disabled = Props.disabled;
  var rowOpt = Props.row;
  var row = rowOpt !== undefined ? rowOpt : true;
  var intl = ReactIntl.useIntl();
  var tmp = {
    children: null
  };
  if (className !== undefined) {
    tmp.className = className;
  }
  if (disabled !== undefined) {
    tmp.disabled = disabled;
  }
  var tmp$1 = Belt_Option.map(validationResult, Belt_Result.isError);
  if (tmp$1 !== undefined) {
    tmp.error = tmp$1;
  }
  return React.createElement(Core.FormControl, tmp, Belt_Option.mapWithDefault(label, null, (function (x) {
                    return React.createElement(Core.FormLabel, {
                                children: React.createElement(Core.Typography, {
                                      children: intl.formatMessage(x),
                                      variant: "subtitle1"
                                    })
                              });
                  })), React.createElement(Core.FormGroup, {
                  children: Belt_List.toArray(Belt_List.mapWithIndex(options, (function (index, option) {
                              return React.createElement(Core.FormControlLabel, {
                                          control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                                checked: Belt_List.has(values, option.value, (function (a, b) {
                                                        return a === b;
                                                      })),
                                                onClick: (function ($$event) {
                                                    if ($$event.target.checked) {
                                                      return Curry._1(onChange, Belt_List.add(values, $$event.target.value));
                                                    } else {
                                                      return Curry._1(onChange, Belt_List.keep(values, (function (x) {
                                                                        return x !== $$event.target.value;
                                                                      })));
                                                    }
                                                  }),
                                                className: checkbox,
                                                value: option.value
                                              }),
                                          label: App_Types_Text$DvmAdminFrontendFr.toString(intl, option.label),
                                          labelPlacement: "end",
                                          key: String(index)
                                        });
                            }))),
                  row: row
                }), Belt_Option.mapWithDefault(Utils_Form$DvmAdminFrontendFr.getErrorMessage(intl, validationResult), null, (function (errorMessage) {
                    return React.createElement(Core.FormHelperText, {
                                children: errorMessage
                              });
                  })));
}

var make = App_CheckBoxFormInput;

export {
  checkbox ,
  make ,
}
/* checkbox Not a pure module */
