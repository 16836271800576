// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../common/App_Api.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../../context/router/Context_Router.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Orders_Modal$DvmAdminFrontendFr from "../../../intl/messages/orders/Messages_Orders_Modal.bs.js";
import * as Bonuses_Search_Modal_Utils$DvmAdminFrontendFr from "./Bonuses_Search_Modal_Utils.bs.js";
import * as Bonuses_Search_Modal_Element$DvmAdminFrontendFr from "./Bonuses_Search_Modal_Element.bs.js";
import * as Bonuses_Search_Modal_VO_Filters$DvmAdminFrontendFr from "./filterPages/Bonuses_Search_Modal_VO_Filters.bs.js";
import * as Bonuses_Search_Modal_Car_Filters$DvmAdminFrontendFr from "./filterPages/Bonuses_Search_Modal_Car_Filters.bs.js";
import * as Bonuses_Search_Modal_Date_Filters$DvmAdminFrontendFr from "./filterPages/Bonuses_Search_Modal_Date_Filters.bs.js";
import * as Bonuses_Search_Modal_Page_Buttons$DvmAdminFrontendFr from "./Bonuses_Search_Modal_Page_Buttons.bs.js";
import * as Bonuses_Search_Modal_Dealer_Filters$DvmAdminFrontendFr from "./filterPages/Bonuses_Search_Modal_Dealer_Filters.bs.js";
import * as Bonuses_Search_Modal_Customer_Filters$DvmAdminFrontendFr from "./filterPages/Bonuses_Search_Modal_Customer_Filters.bs.js";

function Bonuses_Search_Modal(Props) {
  var onClose = Props.onClose;
  var submit = Props.submit;
  var orderType = Props.orderType;
  var form = Props.form;
  var formAdditional = Props.formAdditional;
  var formAdditionalSecond = Props.formAdditionalSecond;
  var formModalVO = Props.formModalVO;
  var dealerIds = Props.dealerIds;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match$2[1];
  var match$3 = React.useState(function () {
        return /* Date */0;
      });
  var filterPage = match$3[0];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResultCategoriesAll = match$4[1];
  var resultCategoriesAll = match$4[0];
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchAllCategories(userManager, setResultCategoriesAll, /* Date */{
                _0: {
                  from: form.input.orderDateStart,
                  to: form.input.orderDateEnd
                }
              }, selectedRole, undefined);
        }), []);
  React.useEffect((function () {
          if (typeof resultCategoriesAll !== "number" && resultCategoriesAll.TAG === /* Ok */0) {
            Curry._2(formAdditional.updateCategoryLabel, (function (input, categoryLabel) {
                    var newrecord = Caml_obj.obj_dup(input);
                    newrecord.categoryLabel = categoryLabel;
                    return newrecord;
                  }), Belt_List.keepMap(resultCategoriesAll._0, (function (x) {
                        return Belt_List.getBy(formAdditional.input.categoryLabel, (function (item) {
                                      return item === Belt_Option.getWithDefault(x.name, "");
                                    }));
                      })));
          }
          
        }), [resultCategoriesAll]);
  var refreshCategories = function (param) {
    App_Api$DvmAdminFrontendFr.fetchAllCategories(userManager, setResultCategoriesAll, /* Date */{
          _0: {
            from: form.input.orderDateStart,
            to: form.input.orderDateEnd
          }
        }, selectedRole, undefined);
  };
  return React.createElement(Bonuses_Search_Modal_Element$DvmAdminFrontendFr.make, {
              open: true,
              onClose: (function (param) {
                  Curry._1(onClose, undefined);
                }),
              title: intl.formatMessage(Messages_Orders_Modal$DvmAdminFrontendFr.title),
              children: React.createElement(Core.Container, {
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          direction: "column",
                          justifyContent: "space-between",
                          wrap: "nowrap",
                          style: {
                            height: "100%"
                          }
                        }, React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              item: true,
                              spacing: 2
                            }, React.createElement(Bonuses_Search_Modal_Page_Buttons$DvmAdminFrontendFr.make, {
                                  filterPage: filterPage,
                                  setFilterPage: match$3[1],
                                  form: form,
                                  formAdditional: formAdditional,
                                  formAdditionalSecond: formAdditionalSecond,
                                  formModalVO: formModalVO
                                }), React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  item: true
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Collapse, {
                                            children: React.createElement(Bonuses_Search_Modal_Date_Filters$DvmAdminFrontendFr.make, {
                                                  orderType: orderType,
                                                  form: form,
                                                  formAdditionalSecond: formAdditionalSecond,
                                                  refreshCategories: refreshCategories
                                                }),
                                            in: filterPage === /* Date */0
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Collapse, {
                                            children: React.createElement(Bonuses_Search_Modal_Customer_Filters$DvmAdminFrontendFr.make, {
                                                  formAdditional: formAdditional
                                                }),
                                            in: filterPage === /* Customer */1
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Collapse, {
                                            children: React.createElement(Bonuses_Search_Modal_Dealer_Filters$DvmAdminFrontendFr.make, {
                                                  form: form,
                                                  formAdditional: formAdditional,
                                                  dealerIds: dealerIds,
                                                  expanded: filterPage === /* Dealer */2
                                                }),
                                            in: filterPage === /* Dealer */2
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Collapse, {
                                            children: React.createElement(Bonuses_Search_Modal_Car_Filters$DvmAdminFrontendFr.make, {
                                                  form: formAdditional,
                                                  formAdditionalSecond: formAdditionalSecond,
                                                  resultCategoriesAll: resultCategoriesAll
                                                }),
                                            in: filterPage === /* Car */3
                                          }),
                                      item: true,
                                      style: {
                                        width: "100%"
                                      }
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Collapse, {
                                            children: React.createElement(Bonuses_Search_Modal_VO_Filters$DvmAdminFrontendFr.make, {
                                                  form: formModalVO
                                                }),
                                            in: filterPage === /* VO */4
                                          }),
                                      item: true
                                    }))), React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              item: true,
                              justifyContent: "flex-end",
                              spacing: 2
                            }, React.createElement(Core.Grid, {
                                  children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                        onClick: (function (param) {
                                            Curry._1(dispatch, {
                                                  TAG: /* StoreBonusesModalFilter */16,
                                                  _0: undefined
                                                });
                                            Curry._1(dispatch, {
                                                  TAG: /* StoreBonusesModalAdditionalFilter */17,
                                                  _0: undefined
                                                });
                                            Curry._1(dispatch, {
                                                  TAG: /* StoreBonusesModalAdditionalSecondFilter */18,
                                                  _0: undefined
                                                });
                                            Curry._1(dispatch, {
                                                  TAG: /* StoreBonusesModalVOFilter */19,
                                                  _0: undefined
                                                });
                                            Curry._1(form.reset, undefined);
                                            Curry._1(formAdditional.reset, undefined);
                                            Curry._1(formAdditionalSecond.reset, undefined);
                                            Curry._1(formModalVO.reset, undefined);
                                          }),
                                        label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.restart),
                                        color: "primaryBorder"
                                      }),
                                  item: true
                                }), React.createElement(Core.Grid, {
                                  children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                        onClick: (function (param) {
                                            Curry._1(submit, undefined);
                                          }),
                                        label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                        disabled: Bonuses_Search_Modal_Utils$DvmAdminFrontendFr.datesIncorrect(form, formModalVO)
                                      }),
                                  item: true
                                }))),
                    style: {
                      height: "100%",
                      width: "800px"
                    }
                  })
            });
}

var ModalUtils;

var make = Bonuses_Search_Modal;

export {
  ModalUtils ,
  make ,
}
/* react Not a pure module */
