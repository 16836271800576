// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";

function Flash_Edit_Value_Percentage_Check(Props) {
  var children = Props.children;
  var form = Props.form;
  var isStepOpen = Props.isStepOpen;
  var intl = ReactIntl.useIntl();
  var showTooltip = Belt_Option.getWithDefault(form.input.numberType, /* NOTSET */2) === /* PERCENT */0 && isStepOpen;
  return React.createElement(Core.Tooltip, {
              arrow: true,
              children: React.createElement("div", undefined, children),
              classes: {
                popper: Css.css({
                      zIndex: "1000"
                    }),
                tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
              },
              open: showTooltip,
              placement: "right",
              title: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.numberAsPercentageWarning)
            });
}

var Form;

var make = Flash_Edit_Value_Percentage_Check;

export {
  Form ,
  make ,
}
/* react Not a pure module */
