// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../../common/components/icons/App_Icon.bs.js";

function Grid_Edit_Modal_Collection_Row_ConfirmButtons(Props) {
  var form = Props.form;
  var index = Props.index;
  var tableRow = Props.tableRow;
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              wrap: "nowrap"
            }, React.createElement(Core.Grid, {
                  children: React.createElement(Core.Button, {
                        onClick: (function (param) {
                            Curry._3(form.updateSingleRowToSave, index, (function (param, toSave) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(param.marginRows, (function (indexRow, row) {
                                                    if (indexRow !== index) {
                                                      return row;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(row);
                                                    newrecord.toSave = toSave;
                                                    return newrecord;
                                                  }))
                                          };
                                  }), true);
                            Curry._1(form.submit, undefined);
                          }),
                        children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                              size: /* Large */3,
                              type_: /* Tick */1,
                              color: "green"
                            }),
                        key: "save"
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Core.Button, {
                        onClick: (function (param) {
                            Curry._3(form.updateSingleRowIsEdited, index, (function (param, isEdited) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(param.marginRows, (function (indexRow, row) {
                                                    if (indexRow !== index) {
                                                      return row;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(row);
                                                    newrecord.isEdited = isEdited;
                                                    return newrecord;
                                                  }))
                                          };
                                  }), false);
                            Curry._3(form.updateSingleRowToSave, index, (function (param, toSave) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(param.marginRows, (function (indexRow, row) {
                                                    if (indexRow !== index) {
                                                      return row;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(row);
                                                    newrecord.toSave = toSave;
                                                    return newrecord;
                                                  }))
                                          };
                                  }), false);
                            Curry._3(form.updateSingleRowAcquisitionType, index, (function (param, acquisitionType) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(param.marginRows, (function (indexRow, row) {
                                                    if (indexRow !== index) {
                                                      return row;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(row);
                                                    newrecord.acquisitionType = acquisitionType;
                                                    return newrecord;
                                                  }))
                                          };
                                  }), Belt_Option.getWithDefault(tableRow.acquisitionType, ""));
                            Curry._3(form.updateSingleRowDiscount, index, (function (param, discount) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(param.marginRows, (function (indexRow, row) {
                                                    if (indexRow !== index) {
                                                      return row;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(row);
                                                    newrecord.discount = discount;
                                                    return newrecord;
                                                  }))
                                          };
                                  }), tableRow.discount);
                            Curry._3(form.updateSingleRowMargin, index, (function (param, margin) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(param.marginRows, (function (indexRow, row) {
                                                    if (indexRow !== index) {
                                                      return row;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(row);
                                                    newrecord.margin = margin;
                                                    return newrecord;
                                                  }))
                                          };
                                  }), tableRow.margin);
                            Curry._3(form.updateSingleRowStartDate, index, (function (param, startDate) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(param.marginRows, (function (indexRow, row) {
                                                    if (indexRow !== index) {
                                                      return row;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(row);
                                                    newrecord.startDate = startDate;
                                                    return newrecord;
                                                  }))
                                          };
                                  }), Caml_option.some(tableRow.startDate));
                            Curry._3(form.updateSingleRowEndDate, index, (function (param, endDate) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(param.marginRows, (function (indexRow, row) {
                                                    if (indexRow !== index) {
                                                      return row;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(row);
                                                    newrecord.endDate = endDate;
                                                    return newrecord;
                                                  }))
                                          };
                                  }), tableRow.endDate);
                            Curry._3(form.updateSingleRowHasExceptions, index, (function (param, hasExceptions) {
                                    return {
                                            marginRows: Belt_Array.mapWithIndex(param.marginRows, (function (indexRow, row) {
                                                    if (indexRow !== index) {
                                                      return row;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(row);
                                                    newrecord.hasExceptions = hasExceptions;
                                                    return newrecord;
                                                  }))
                                          };
                                  }), Belt_Option.getWithDefault(tableRow.hasExceptions, false));
                          }),
                        children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                              size: /* Large */3,
                              type_: /* Cross */42
                            }),
                        key: "cancel"
                      }),
                  item: true
                }));
}

var Form;

var Types;

var make = Grid_Edit_Modal_Collection_Row_ConfirmButtons;

export {
  Form ,
  Types ,
  make ,
}
/* react Not a pure module */
