// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Styles from "@material-ui/core/styles";
import * as Theme_Colors$DvmAdminFrontendFr from "./Theme_Colors.bs.js";
import * as Theme_Typography$DvmAdminFrontendFr from "./Theme_Typography.bs.js";

var renaultGroupFont = "RenaultGroup";

var theme = Styles.createTheme({
      overrides: {
        MuiBadge: {
          badge: {
            fontSize: Theme_Typography$DvmAdminFrontendFr.FontSize.getRem("body2")
          }
        },
        MuiButton: {
          root: {
            fontFamily: renaultGroupFont,
            fontSize: "unset",
            fontWeight: renaultGroupFont,
            minWidth: "unset",
            borderRadius: "unset"
          }
        },
        MuiChip: {
          root: {
            fontSize: Theme_Typography$DvmAdminFrontendFr.FontSize.getRem("body2")
          }
        },
        MuiCssBaseline: {
          "@global": {
            "@font-face": renaultGroupFont,
            html: {
              "-webkit-box-sizing": "border-box",
              "-moz-box-sizing": "border-box",
              boxSizing: "border-box",
              fontSize: "62.5%",
              "-moz-osx-font-smoothing": "grayscale",
              "-webkit-font-smoothing": "antialiased",
              textRendering: "optimizeLegibility",
              textSizeAdjust: "100%"
            }
          }
        },
        MuiFilledInput: {
          root: Object.assign({}, {
                backgroundColor: "inherit",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px"
              }, {
                "&.Mui-focused": {
                  backgroundColor: "inherit"
                },
                "&:hover": {
                  backgroundColor: "inherit"
                }
              })
        },
        MuiFormControl: {
          marginDense: {
            marginTop: "0px",
            marginBottom: "0px"
          }
        },
        MuiIconButton: {
          root: {
            padding: "8px"
          }
        },
        MuiInputAdornment: {
          positionEnd: {
            marginLeft: "0px"
          }
        },
        MuiList: {
          padding: {
            paddingTop: "0px",
            paddingBottom: "0px"
          }
        },
        MuiPopover: {
          paper: {
            borderRadius: "0px"
          }
        },
        MuiSelect: {
          filled: Object.assign({}, {}, {
                "&.MuiSelect-filled": {
                  paddingRight: "0px"
                }
              }),
          select: Object.assign({}, {}, {
                "&:focus": {
                  backgroundColor: "inherit"
                }
              })
        },
        MuiSvgIcon: {
          root: {
            height: "20px",
            width: "20px"
          }
        },
        MuiTableRow: {
          root: Object.assign({}, {}, {
                "&:nth-child(even)": {
                  backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight1
                }
              })
        },
        MuiTableSortLabel: {
          active: Object.assign({}, {}, {
                "&:hover": {
                  color: Theme_Colors$DvmAdminFrontendFr.grayHover
                }
              }),
          root: Object.assign({}, {}, {
                "&:focus": {
                  color: Theme_Colors$DvmAdminFrontendFr.white
                },
                "&.MuiTableSortLabel-active": {
                  color: Theme_Colors$DvmAdminFrontendFr.white
                },
                "&:hover": {
                  color: Theme_Colors$DvmAdminFrontendFr.grayHover
                },
                "&.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon": {
                  color: Theme_Colors$DvmAdminFrontendFr.white
                }
              })
        },
        MuiTooltip: {
          tooltip: {
            backgroundColor: "transparent",
            color: Theme_Colors$DvmAdminFrontendFr.grayMain,
            fontSize: "14px"
          }
        },
        MuiTypography: {
          colorPrimary: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
          colorSecondary: Theme_Colors$DvmAdminFrontendFr.secondaryBlue
        }
      },
      palette: {
        background: {
          default: Theme_Colors$DvmAdminFrontendFr.whiteBackground
        },
        common: {
          black: Theme_Colors$DvmAdminFrontendFr.black,
          white: Theme_Colors$DvmAdminFrontendFr.white
        },
        error: {
          main: Theme_Colors$DvmAdminFrontendFr.accentRed
        },
        grey: {
          "50": Theme_Colors$DvmAdminFrontendFr.grayLight1,
          "100": Theme_Colors$DvmAdminFrontendFr.grayLight2,
          "200": Theme_Colors$DvmAdminFrontendFr.grayBackground,
          "400": Theme_Colors$DvmAdminFrontendFr.grayHover,
          "500": Theme_Colors$DvmAdminFrontendFr.grayMain,
          "600": Theme_Colors$DvmAdminFrontendFr.grayDark1,
          "700": Theme_Colors$DvmAdminFrontendFr.grayDark2,
          "800": Theme_Colors$DvmAdminFrontendFr.grayDark3,
          "900": Theme_Colors$DvmAdminFrontendFr.grayDark4
        },
        info: {
          main: Theme_Colors$DvmAdminFrontendFr.renaultGroupLight
        },
        primary: {
          main: Theme_Colors$DvmAdminFrontendFr.primaryBrown
        },
        secondary: {
          main: Theme_Colors$DvmAdminFrontendFr.black
        },
        success: {
          main: Theme_Colors$DvmAdminFrontendFr.accentGreen
        },
        warning: {
          main: Theme_Colors$DvmAdminFrontendFr.accentOrange
        }
      },
      props: {
        MuiSelect: {
          MenuProps: {
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            }
          }
        }
      },
      typography: {
        body1: {
          fontFamily: renaultGroupFont,
          fontWeight: "400",
          fontSize: Theme_Typography$DvmAdminFrontendFr.FontSize.getRem("body1")
        },
        body2: {
          fontFamily: renaultGroupFont,
          fontWeight: "400",
          fontSize: Theme_Typography$DvmAdminFrontendFr.FontSize.getRem("body2")
        },
        button: {
          fontFamily: renaultGroupFont,
          fontWeight: "600",
          fontSize: Theme_Typography$DvmAdminFrontendFr.FontSize.getRem("button")
        },
        caption: {
          fontFamily: renaultGroupFont,
          fontWeight: "400",
          fontSize: Theme_Typography$DvmAdminFrontendFr.FontSize.getRem("caption")
        },
        fontFamily: renaultGroupFont,
        h1: {
          fontFamily: renaultGroupFont,
          fontWeight: "700",
          fontSize: Theme_Typography$DvmAdminFrontendFr.FontSize.getRem("h1")
        },
        h2: {
          fontFamily: renaultGroupFont,
          fontWeight: "600",
          fontSize: Theme_Typography$DvmAdminFrontendFr.FontSize.getRem("h2")
        },
        h3: {
          fontFamily: renaultGroupFont,
          fontWeight: "600",
          fontSize: Theme_Typography$DvmAdminFrontendFr.FontSize.getRem("h3")
        },
        h4: {
          fontFamily: renaultGroupFont,
          fontWeight: "400",
          fontSize: Theme_Typography$DvmAdminFrontendFr.FontSize.getRem("h4")
        },
        h5: {
          fontFamily: renaultGroupFont,
          fontWeight: "400",
          fontSize: Theme_Typography$DvmAdminFrontendFr.FontSize.getRem("h5")
        },
        h6: {
          fontFamily: renaultGroupFont,
          fontWeight: "400",
          fontSize: Theme_Typography$DvmAdminFrontendFr.FontSize.getRem("h6")
        },
        subtitle1: {
          fontFamily: renaultGroupFont,
          fontWeight: "400",
          fontSize: Theme_Typography$DvmAdminFrontendFr.FontSize.getRem("subtitle1")
        },
        subtitle2: {
          fontFamily: renaultGroupFont,
          fontWeight: "400",
          fontSize: Theme_Typography$DvmAdminFrontendFr.FontSize.getRem("subtitle2")
        }
      }
    });

var Typography;

var Size;

var Color;

export {
  Typography ,
  Size ,
  Color ,
  renaultGroupFont ,
  theme ,
}
/* theme Not a pure module */
