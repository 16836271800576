// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as Company_Form_Utils$DvmAdminFrontendFr from "../Company_Form_Utils.bs.js";
import * as Company_Manage_Types$DvmAdminFrontendFr from "./Company_Manage_Types.bs.js";

var validators_hierarchy = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Company_Manage_Types$DvmAdminFrontendFr.Fields.hierarchy,
                    value: param.hierarchy
                  })
            };
    })
};

var validators_codeAPE = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Company_Manage_Types$DvmAdminFrontendFr.Fields.codeApe,
                    value: param.codeAPE
                  })
            };
    })
};

var validators_endDate = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Company_Form_Utils$DvmAdminFrontendFr.endDateFormValidation(param.endDate, param.active);
    })
};

var validators_city = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(undefined, {
                    fieldId: Company_Manage_Types$DvmAdminFrontendFr.Fields.city,
                    value: param.city
                  })
            };
    })
};

var validators_name = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(undefined, {
                    fieldId: Company_Manage_Types$DvmAdminFrontendFr.Fields.name,
                    value: param.name
                  })
            };
    })
};

var validators_siren = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Company_Manage_Types$DvmAdminFrontendFr.Fields.siren,
                    value: param.siren
                  })
            };
    })
};

var validators_siret = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Company_Manage_Types$DvmAdminFrontendFr.Fields.siret,
                    value: param.siret
                  })
            };
    })
};

var validators = {
  hierarchy: validators_hierarchy,
  codeAPE: validators_codeAPE,
  endDate: validators_endDate,
  active: undefined,
  city: validators_city,
  name: validators_name,
  siren: validators_siren,
  siret: validators_siret
};

function initialFieldsStatuses(_input) {
  return {
          hierarchy: /* Pristine */0,
          codeAPE: /* Pristine */0,
          endDate: /* Pristine */0,
          active: /* Pristine */0,
          city: /* Pristine */0,
          name: /* Pristine */0,
          siren: /* Pristine */0,
          siret: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.hierarchy;
  var match_0 = match ? match._0 : Curry._1(validators.hierarchy.validate, input);
  var match$1 = fieldsStatuses.codeAPE;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.codeAPE.validate, input);
  var match$2 = fieldsStatuses.endDate;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.endDate.validate, input);
  var match_0$3 = {
    TAG: /* Ok */0,
    _0: input.active
  };
  var match$3 = fieldsStatuses.city;
  var match_0$4 = match$3 ? match$3._0 : Curry._1(validators.city.validate, input);
  var match$4 = fieldsStatuses.name;
  var match_0$5 = match$4 ? match$4._0 : Curry._1(validators.name.validate, input);
  var match$5 = fieldsStatuses.siren;
  var match_0$6 = match$5 ? match$5._0 : Curry._1(validators.siren.validate, input);
  var match$6 = fieldsStatuses.siret;
  var match_0$7 = match$6 ? match$6._0 : Curry._1(validators.siret.validate, input);
  var hierarchyResult = match_0;
  var hierarchyResult$1;
  if (hierarchyResult.TAG === /* Ok */0) {
    var codeAPEResult = match_0$1;
    if (codeAPEResult.TAG === /* Ok */0) {
      var endDateResult = match_0$2;
      if (endDateResult.TAG === /* Ok */0) {
        var activeResult = match_0$3;
        if (activeResult.TAG === /* Ok */0) {
          var cityResult = match_0$4;
          if (cityResult.TAG === /* Ok */0) {
            var nameResult = match_0$5;
            if (nameResult.TAG === /* Ok */0) {
              var sirenResult = match_0$6;
              if (sirenResult.TAG === /* Ok */0) {
                var siretResult = match_0$7;
                if (siretResult.TAG === /* Ok */0) {
                  return {
                          TAG: /* Valid */0,
                          output: {
                            siret: siretResult._0,
                            siren: sirenResult._0,
                            name: nameResult._0,
                            city: cityResult._0,
                            active: activeResult._0,
                            endDate: endDateResult._0,
                            codeAPE: codeAPEResult._0,
                            hierarchy: hierarchyResult._0
                          },
                          fieldsStatuses: {
                            hierarchy: /* Dirty */{
                              _0: hierarchyResult,
                              _1: /* Shown */0
                            },
                            codeAPE: /* Dirty */{
                              _0: codeAPEResult,
                              _1: /* Shown */0
                            },
                            endDate: /* Dirty */{
                              _0: endDateResult,
                              _1: /* Shown */0
                            },
                            active: /* Dirty */{
                              _0: activeResult,
                              _1: /* Hidden */1
                            },
                            city: /* Dirty */{
                              _0: cityResult,
                              _1: /* Shown */0
                            },
                            name: /* Dirty */{
                              _0: nameResult,
                              _1: /* Shown */0
                            },
                            siren: /* Dirty */{
                              _0: sirenResult,
                              _1: /* Shown */0
                            },
                            siret: /* Dirty */{
                              _0: siretResult,
                              _1: /* Shown */0
                            }
                          },
                          collectionsStatuses: undefined
                        };
                }
                hierarchyResult$1 = hierarchyResult;
              } else {
                hierarchyResult$1 = hierarchyResult;
              }
            } else {
              hierarchyResult$1 = hierarchyResult;
            }
          } else {
            hierarchyResult$1 = hierarchyResult;
          }
        } else {
          hierarchyResult$1 = hierarchyResult;
        }
      } else {
        hierarchyResult$1 = hierarchyResult;
      }
    } else {
      hierarchyResult$1 = hierarchyResult;
    }
  } else {
    hierarchyResult$1 = hierarchyResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            hierarchy: /* Dirty */{
              _0: hierarchyResult$1,
              _1: /* Shown */0
            },
            codeAPE: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            endDate: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            active: /* Dirty */{
              _0: match_0$3,
              _1: /* Hidden */1
            },
            city: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            name: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            siren: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            siret: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurHierarchyField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.hierarchy, validators_hierarchy, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  hierarchy: status,
                                  codeAPE: init.codeAPE,
                                  endDate: init.endDate,
                                  active: init.active,
                                  city: init.city,
                                  name: init.name,
                                  siren: init.siren,
                                  siret: init.siret
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCodeAPEField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.codeAPE, validators_codeAPE, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  hierarchy: init.hierarchy,
                                  codeAPE: status,
                                  endDate: init.endDate,
                                  active: init.active,
                                  city: init.city,
                                  name: init.name,
                                  siren: init.siren,
                                  siret: init.siret
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEndDateField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.endDate, validators_endDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  hierarchy: init.hierarchy,
                                  codeAPE: init.codeAPE,
                                  endDate: status,
                                  active: init.active,
                                  city: init.city,
                                  name: init.name,
                                  siren: init.siren,
                                  siret: init.siret
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurActiveField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithoutValidator(state.input.active, state.fieldsStatuses.active, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  hierarchy: init.hierarchy,
                                  codeAPE: init.codeAPE,
                                  endDate: init.endDate,
                                  active: status,
                                  city: init.city,
                                  name: init.name,
                                  siren: init.siren,
                                  siret: init.siret
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCityField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.city, validators_city, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  hierarchy: init.hierarchy,
                                  codeAPE: init.codeAPE,
                                  endDate: init.endDate,
                                  active: init.active,
                                  city: status,
                                  name: init.name,
                                  siren: init.siren,
                                  siret: init.siret
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurNameField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.name, validators_name, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  hierarchy: init.hierarchy,
                                  codeAPE: init.codeAPE,
                                  endDate: init.endDate,
                                  active: init.active,
                                  city: init.city,
                                  name: status,
                                  siren: init.siren,
                                  siret: init.siret
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSirenField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.siren, validators_siren, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  hierarchy: init.hierarchy,
                                  codeAPE: init.codeAPE,
                                  endDate: init.endDate,
                                  active: init.active,
                                  city: init.city,
                                  name: init.name,
                                  siren: status,
                                  siret: init.siret
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSiretField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.siret, validators_siret, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  hierarchy: init.hierarchy,
                                  codeAPE: init.codeAPE,
                                  endDate: init.endDate,
                                  active: init.active,
                                  city: init.city,
                                  name: init.name,
                                  siren: init.siren,
                                  siret: status
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */8 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */8,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */9,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */11);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */10);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */9 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */10 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */11 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateHierarchyField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.hierarchy, state.submissionStatus, validators_hierarchy, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            hierarchy: status,
                                            codeAPE: init.codeAPE,
                                            endDate: init.endDate,
                                            active: init.active,
                                            city: init.city,
                                            name: init.name,
                                            siren: init.siren,
                                            siret: init.siret
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCodeAPEField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.codeAPE, state.submissionStatus, validators_codeAPE, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            hierarchy: init.hierarchy,
                                            codeAPE: status,
                                            endDate: init.endDate,
                                            active: init.active,
                                            city: init.city,
                                            name: init.name,
                                            siren: init.siren,
                                            siret: init.siret
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEndDateField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.endDate, state.submissionStatus, validators_endDate, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            hierarchy: init.hierarchy,
                                            codeAPE: init.codeAPE,
                                            endDate: status,
                                            active: init.active,
                                            city: init.city,
                                            name: init.name,
                                            siren: init.siren,
                                            siret: init.siret
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateActiveField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$8 = Formality.validateDependentFieldOnChange(nextInput$3, nextFieldsStatuses.contents.endDate, validators_endDate, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  hierarchy: init.hierarchy,
                                  codeAPE: init.codeAPE,
                                  endDate: status,
                                  active: init.active,
                                  city: init.city,
                                  name: init.name,
                                  siren: init.siren,
                                  siret: init.siret
                                };
                        }));
                  if (result$8 !== undefined) {
                    nextFieldsStatuses.contents = result$8;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$3.active, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            hierarchy: init.hierarchy,
                                            codeAPE: init.codeAPE,
                                            endDate: init.endDate,
                                            active: status,
                                            city: init.city,
                                            name: init.name,
                                            siren: init.siren,
                                            siret: init.siret
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCityField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.city, state.submissionStatus, validators_city, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            hierarchy: init.hierarchy,
                                            codeAPE: init.codeAPE,
                                            endDate: init.endDate,
                                            active: init.active,
                                            city: status,
                                            name: init.name,
                                            siren: init.siren,
                                            siret: init.siret
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateNameField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.name, state.submissionStatus, validators_name, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            hierarchy: init.hierarchy,
                                            codeAPE: init.codeAPE,
                                            endDate: init.endDate,
                                            active: init.active,
                                            city: init.city,
                                            name: status,
                                            siren: init.siren,
                                            siret: init.siret
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSirenField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.siren, state.submissionStatus, validators_siren, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            hierarchy: init.hierarchy,
                                            codeAPE: init.codeAPE,
                                            endDate: init.endDate,
                                            active: init.active,
                                            city: init.city,
                                            name: init.name,
                                            siren: status,
                                            siret: init.siret
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSiretField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$7, state.fieldsStatuses.siret, state.submissionStatus, validators_siret, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            hierarchy: init.hierarchy,
                                            codeAPE: init.codeAPE,
                                            endDate: init.endDate,
                                            active: init.active,
                                            city: init.city,
                                            name: init.name,
                                            siren: init.siren,
                                            siret: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */8 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */9 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */10 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateHierarchy: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateHierarchyField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCodeAPE: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCodeAPEField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEndDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEndDateField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateActive: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateActiveField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCity: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCityField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateNameField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateSiren: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSirenField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateSiret: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSiretField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurHierarchy: (function (param) {
              Curry._1(dispatch, /* BlurHierarchyField */0);
            }),
          blurCodeAPE: (function (param) {
              Curry._1(dispatch, /* BlurCodeAPEField */1);
            }),
          blurEndDate: (function (param) {
              Curry._1(dispatch, /* BlurEndDateField */2);
            }),
          blurActive: (function (param) {
              Curry._1(dispatch, /* BlurActiveField */3);
            }),
          blurCity: (function (param) {
              Curry._1(dispatch, /* BlurCityField */4);
            }),
          blurName: (function (param) {
              Curry._1(dispatch, /* BlurNameField */5);
            }),
          blurSiren: (function (param) {
              Curry._1(dispatch, /* BlurSirenField */6);
            }),
          blurSiret: (function (param) {
              Curry._1(dispatch, /* BlurSiretField */7);
            }),
          hierarchyResult: Formality.exposeFieldResult(state.fieldsStatuses.hierarchy),
          codeAPEResult: Formality.exposeFieldResult(state.fieldsStatuses.codeAPE),
          endDateResult: Formality.exposeFieldResult(state.fieldsStatuses.endDate),
          activeResult: Formality.exposeFieldResult(state.fieldsStatuses.active),
          cityResult: Formality.exposeFieldResult(state.fieldsStatuses.city),
          nameResult: Formality.exposeFieldResult(state.fieldsStatuses.name),
          sirenResult: Formality.exposeFieldResult(state.fieldsStatuses.siren),
          siretResult: Formality.exposeFieldResult(state.fieldsStatuses.siret),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.hierarchy || match.codeAPE || match.endDate || match.active || match.city || match.name || match.siren || match.siret) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */8);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */9);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */10);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */10,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */11);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  siret: "",
  siren: "",
  name: "",
  city: "",
  active: "",
  endDate: undefined,
  codeAPE: "",
  hierarchy: ""
};

export {
  TableFilterForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
