// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../../../api/RestApi.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../../common/utils/Utils_Alert.bs.js";
import * as Quota_View_Exclusion_Types$DvmAdminFrontendFr from "../../Quota_View_Exclusion_Types.bs.js";
import * as Quota_View_Exclusion_Add_Types$DvmAdminFrontendFr from "./Quota_View_Exclusion_Add_Types.bs.js";

function fetchAllVersions(submissionCallbacks, userManager, setResult, queryParams, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Quota_View_Exclusion_Add_Types$DvmAdminFrontendFr.AllVersions.searchResult_decode, undefined, userManager, selectedRole, "/dropdown/search/versions" + queryParams + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchVersionsSearch */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function editMultipleVersions(operation, userManager, setResult, submissionCallbacks, tableType, selectedRole, versions) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call(operation ? "DELETE" : "POST", {
          TAG: /* Json */0,
          _0: Quota_View_Exclusion_Types$DvmAdminFrontendFr.Exclusion.exclusionsSaveRequest_encode(versions)
        }, userManager, selectedRole, "/quotas/exclusions/" + (
          tableType === /* Model */0 ? "model" : "modelversion"
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: operation
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToSaveVersions */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchModelsOrVersionsOfQuota(alert, userManager, setResult, primaryOutputs, tableType, selectedRole) {
  var startDate = new Date(primaryOutputs.startDate.setUTCHours(0.0, 0.0, 0.0, 0.0)).toISOString();
  RestApi$DvmAdminFrontendFr.call("GET", Quota_View_Exclusion_Types$DvmAdminFrontendFr.Exclusion.searchResult_decode, undefined, userManager, selectedRole, "/quotas/exclusions/" + (
          tableType === /* Model */0 ? "model" : "modelversion"
        ) + "?filter=contractId=" + primaryOutputs.contractId + ";vehicleType=" + primaryOutputs.vehicleType + ";startDate=" + startDate + "&limit=2000&offset=0").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return searchTable.content;
                      }));
        }
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: result._0
                  }
                }));
        return Curry._1(setResult, (function (param) {
                      return /* [] */0;
                    }));
      });
}

var Form;

var Types;

export {
  Form ,
  Types ,
  fetchAllVersions ,
  editMultipleVersions ,
  fetchModelsOrVersionsOfQuota ,
}
/* react Not a pure module */
